import { Fragment, useEffect, useState } from 'react'

import { getCurrentUser, getIsLoading } from '@/reducers/selectors'

import StatusFilter from '../Utils/StatusFilter'
import ZoneCards from '../Utils/Cards'

import {
  RESOURCE_TYPE_ZONE,
  getPermissionsList,
  findPermissionById
} from '@/Util/PermissionUtils'

import { Flex, Text } from '@/primitives'
import { LineSeparator, SearchBar } from '@/elements'

import SubzoneHeader from './Header'
import UpdateZoneModal from '../UpsertModal'

import ZoneUtils from '@/Util/ZoneUtils'
import { getZoneSubzonesStrings } from '../../utils'
import useOrgLabel from '@/hooks/useOrgLabel'

export default function ZoneSubzones({
  selectedZone,
  onClickCard,
  setBreadcrumbs
}) {
  const coretexUser = getCurrentUser()
  const isLoading = getIsLoading()

  const [showModal, setShowModal] = useState(false)
  const [zonesList, setZonesList] = useState([])
  const [searchResults, setSearchResults] = useState(zonesList)
  const [searchText, setSearchText] = useState('')
  const orgLabels = useOrgLabel(['site', 'facility', 'room', 'zone', 'subzone'])

  const { heading, noLocationsText } = getZoneSubzonesStrings(
    selectedZone,
    orgLabels
  )
  const permissionsList = getPermissionsList(
    coretexUser,
    RESOURCE_TYPE_ZONE,
    'write'
  )
  const hasZoneEditPermission =
    findPermissionById(
      permissionsList,
      ZoneUtils.getPrimaryZoneFromParentPath(selectedZone?.parentPath)
    ) || findPermissionById(permissionsList)

  function onClickAddZone() {
    setShowModal(true)
  }
  const onUpdateSearchText = text => {
    setSearchText(text)
  }

  useEffect(() => {
    setSearchResults(
      zonesList.filter(zone =>
        zone?.name?.toLowerCase().includes(searchText.toLowerCase())
      )
    )
  }, [searchText, zonesList, selectedZone])

  return (
    <Fragment>
      {showModal && (
        <UpdateZoneModal
          showModal={showModal}
          setShowModal={setShowModal}
          addNewZone={true}
          setBreadcrumbs={setBreadcrumbs}
        />
      )}
      <SubzoneHeader
        buttonAction={onClickAddZone}
        selectedZone={selectedZone}
        permissions={hasZoneEditPermission}
      />
      <Flex axisGap={300}>
        <StatusFilter setZonesList={setZonesList} />
        {!isLoading && (
          <SearchBar
            isLoading={isLoading}
            onUpdateSearchText={onUpdateSearchText}
            placeholderText={`Search ${heading}`}
          />
        )}
      </Flex>

      <LineSeparator />

      {searchResults?.length > 0 ? (
        <ZoneCards zones={searchResults} />
      ) : (
        <Flex alignMainAxis='center' style={{ margin: '0.5em 0' }}>
          <Text as='p'>{`${noLocationsText} ${selectedZone.name}.`}</Text>
        </Flex>
      )}
    </Fragment>
  )
}

import { Fragment, useEffect, useState } from 'react'
import { I18n } from '@aws-amplify/core'
import { onAuthUIStateChange, AuthState } from '@aws-amplify/ui-components'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Shell, Header, Footer, Content, Page } from '@/components/UIShell'
import { GlobalNotification } from '@/components/UIShell/Notification'
import PrivacyPolicy from '../PrivacyPolicy'

import { useArticleSuggestion } from '@/algolia/context'

import { useAuth } from '../../contexts/auth-context'

import { setLang } from '@/slices/util'
import { getLang } from '@/reducers/selectors'

import { getLanguage } from '../../i18n/setup'

import AuthWrapper from './Wrapper'

const withAuthenticator = Component => {
  const AppWithAuthenticator = props => {
    const dispatch = useDispatch()
    const auth = useAuth()
    const location = useLocation()
    const lang = getLang()

    const [signedIn, setSignedIn] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    const { setSpecificQuery } = useArticleSuggestion()

    useEffect(() => {
      return checkUser()
    }, [])

    function checkUser() {
      setUser()

      return onAuthUIStateChange(authState => {
        if (authState === AuthState.SignedIn) {
          setSignedIn(true)
        } else if (authState === AuthState.SignedOut) {
          setSignedIn(false)
        }
      })
    }

    async function setUser() {
      const isLoggedIn = await auth.isAuthenticated()
      setIsLoading(false)
      if (isLoggedIn) {
        setSignedIn(true)
        const preferredLanguage = auth?.getCognitoPreferredLanguage()
        I18n.setLanguage(getLanguage(preferredLanguage))
        dispatch(setLang(preferredLanguage))
      }
    }

    useEffect(() => {
      if (!signedIn) {
        setSpecificQuery('accessing-the-platform')
      } else {
        setSpecificQuery(null)
        setUser()
      }
      return () => {
        setSpecificQuery(null)
      }
    }, [signedIn])

    const isPrivacyPolicy = location.pathname.includes('privacy-policy')

    if (!signedIn && isPrivacyPolicy) {
      return (
        <Shell key={lang}>
          <Content>
            <Page addXPadding={true} addYPadding={true}>
              <PrivacyPolicy />
            </Page>
          </Content>
          <Footer />
          <Header />
          <GlobalNotification />
        </Shell>
      )
    }

    return (
      <Fragment>
        {!signedIn && !isLoading && (
          <Shell key={lang}>
            <Content>
              <Page addXPadding={true} addYPadding={true}>
                <AuthWrapper />
              </Page>
            </Content>
            <Footer />
            <Header />
            <GlobalNotification />
          </Shell>
        )}
        {signedIn && !isLoading && <Component {...props} />}
      </Fragment>
    )
  }

  return AppWithAuthenticator
}

export default withAuthenticator

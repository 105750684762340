import { createSlice } from '@reduxjs/toolkit'

const utilSlice = createSlice({
  name: 'utilReducer',
  initialState: {
    isLoading: false,
    isLoadingSection: false,
    lang: 'en',
    banner: {
      show: false,
      message: '',
      type: ''
    }
  },
  reducers: {
    isLoading: (state, action) => ({ ...state, isLoading: action.payload }),
    isLoadingSection: (state, action) => ({
      ...state,
      isLoadingSection: action.payload
    }),
    setLang: (state, action) => ({ ...state, lang: action.payload }),
    showBanner: (state, action) => ({ ...state, banner: action.payload })
  }
})

export const { isLoading, isLoadingSection, setLang, showBanner } =
  utilSlice.actions

export default utilSlice.reducer

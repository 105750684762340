import { I18n } from 'aws-amplify'
import { object, string, number, date } from 'yup'

export const FIELDS = {
  id: 'id',
  organizationId: 'organizationId',
  storageFacilityId: 'storageFacilityId',
  packageUnitId: 'packageUnitId',
  supplierId: 'supplierId',
  currencyId: 'currencyId',
  unitPrice: 'unitPrice',
  tax: 'tax',
  quantity: 'quantity',
  orderDate: 'orderDate',
  receivedDate: 'receivedDate',
  status: 'status'
}

export const SCHEMA = () => {
  return object().shape({
    organizationId: string().required(I18n.get('An organization is required.')),
    storageFacilityId: string().required(
      I18n.get('A storage facility is required.')
    ),
    packageUnitId: string().required(I18n.get('A package unit is required.')),
    supplierId: string().required(I18n.get('A supplier is required.')),
    currencyId: string().required(I18n.get('A currency is required.')),
    unitPrice: number()
      .typeError(I18n.get('A unit price is required.'))
      .min(1, I18n.get('The unit price cannot be less than one.'))
      .required(I18n.get('A unit price is required.')),
    tax: number()
      .nullable()
      .transform(n => (isNaN(n) ? 0 : n))
      .min(0, I18n.get('The tax cannot be less than zero.')),
    quantity: number()
      .integer(I18n.get('A partial quantity is not allowed.'))
      .typeError(I18n.get('A purchase quantity is required.'))
      .min(1, I18n.get('The quantity purchased cannot be less than one.'))
      .required(I18n.get('An purchase quantity is required.')),
    orderDate: date()
      .typeError(I18n.get('Order date is required.'))
      .max(new Date(), I18n.get('Order date cannot be set in the future.'))
      .required(I18n.get('Order date is required.')),
    receivedDate: date()
      .nullable()
      .default(null)
      .typeError(I18n.get('Date received is invalid.'))
      .max(new Date(), I18n.get('Date received cannot be set in the future.')),
    status: string().required(I18n.get('Status is required'))
  })
}

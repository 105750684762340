import { useMemo } from 'react'

import { Text, Button, FlexV2, Icon } from '@/primitives'

import { useFleetManagement } from '../context'

import { getZone, getZonesHierarchy } from '@/reducers/selectors'
import { getDisplayNamePath } from '../../Utils/Zone'
import Strings from '../../../Strings'

function countConnectedDevices(devices) {
  let connectedCount = 0

  for (const device of devices) {
    if (device.status === 'connected') {
      connectedCount++
    }
  }

  return connectedCount
}

const DevicesListItem = ({ zoneIndex, groupCollapsed, toggleGroup }) => {
  const {
    state: { sensorsByZone }
  } = useFleetManagement()

  const zone = getZone()
  const zonesHierarchy = getZonesHierarchy()

  const device = sensorsByZone[zoneIndex]
  const { path, sensors } = device

  const { fleetManagementDevicesActive, fleetManagementSensorsActive } =
    Strings()

  const zonePathReadable = useMemo(
    () => getDisplayNamePath(zonesHierarchy[zone.id], path),
    [zonesHierarchy, zone.id, path]
  )

  return (
    <FlexV2
      className='ZonesList__Item'
      alignMainAxis='space-between'
      axisGap={500}
    >
      <FlexV2
        axisGap={400}
        alignCrossAxis='center'
      >
        <Icon name='domain' />
        <Text
          style={{ cursor: 'pointer' }}
          fontWeight={300}
          onClick={toggleGroup}
        >
          {zonePathReadable?.replaceAll('/', ' / ')}
        </Text>
      </FlexV2>
      <FlexV2 alignCrossAxis='center' axisGap={600}>
        <FlexV2 axisGap={700} direction='row'>
          <Text
            variant='page'
            fontWeight={300}
            tone={700}
            fontFamily='mono'
            style={{ fontSize: '0.9rem' }}
          >
            {`${fleetManagementDevicesActive}: ${device?.status === 'running' ? 1 : 0
              }/1`}
          </Text>
          <Text
            variant='page'
            fontWeight={300}
            tone={700}
            fontFamily='mono'
            style={{ fontSize: '0.9rem' }}
          >
            {`${fleetManagementSensorsActive}: ${countConnectedDevices(
              sensors
            )}/${sensors.length}`}
          </Text>
        </FlexV2>
        <Button
          iconBefore={groupCollapsed ? 'expand more' : 'expand less'}
          onClick={toggleGroup}
          className='CoreDevice__Expand__Button'
        />
      </FlexV2>
    </FlexV2>
  )
}

export default DevicesListItem

import { Fragment } from 'react'
import OrganizationsFilter from '../../../Shared/Filters/OrganizationsFilter'
import StorageFacilitiesFilter from '../../../Shared/Filters/StorageFacilitiesFilter'
import FertilizerFilter from '../../../Shared/Filters/FertilizerFilter'

function FertilizerPurchaseFilter({ state, dispatchState }) {
  return (
    <Fragment>
      <OrganizationsFilter state={state} dispatchState={dispatchState} />
      <StorageFacilitiesFilter state={state} dispatchState={dispatchState} />
      <FertilizerFilter state={state} dispatchState={dispatchState} />
    </Fragment>
  )
}

export default FertilizerPurchaseFilter

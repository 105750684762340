import { I18n } from 'aws-amplify'

import {
  DailyEnergyConsumptionWidget,
  DailyEnergyConsumptionQueryOptionsList
} from './DailyPowerConsumption'
import {
  DATASET_QUERY,
  HOURLY,
  LAST_7_DAYS,
  LINE_CHART
} from '../Shared/Dynamic/config'

export function resourcesTypes() {
  return {
    dailyEnergyConsumption: {
      widgetId: 'dailyEnergyConsumption',
      title: I18n.get('Daily Energy Consumption'),
      icon: 'battery charging full',
      measurement: 'daily_energy_consumption',
      component: DailyEnergyConsumptionWidget,
      queryOptionsList: DailyEnergyConsumptionQueryOptionsList,
      config: {
        query: DATASET_QUERY,
        chart: LINE_CHART,
        range: LAST_7_DAYS,
        interval: HOURLY
      }
    },
    energy: {
      widgetId: 'energy',
      title: I18n.get('Energy'),
      icon: 'electric bolt',
      measurement: 'energy'
    },
    energyKw: {
      widgetId: 'energyKw',
      title: I18n.get('Energy KW'),
      icon: 'electric bolt',
      measurement: 'energy_kw'
    }
  }
}

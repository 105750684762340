import React, { useState, useEffect, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import classnames from 'classnames'

import { Form, Button, Input, Slot, FlexV2, Text } from '@/primitives'
import { Dialog, LineSeparator, Select as ElementSelect } from '@/elements'

import { sendCreateRole, cleanRole } from '@/slices/management/role'
import { showBanner } from '@/slices/util'

import {
  getRoles,
  getShowBanner,
  getCurrentUserOrganizations,
  getRole
} from '@/reducers/selectors'

import AdminUtils from '@/Util/AdminUtils'

import Strings from '../Strings'

import './index.scss'

const initialState = {
  roleName: '',
  organizationIds: [],
  baseRole: null
}

function reducer(state, { type, value }) {
  switch (type) {
    case 'name':
      return { ...state, roleName: value }
    case 'organizations':
      return { ...state, organizationIds: value, baseRole: null }
    case 'base':
      return { ...state, baseRole: value }
    case 'reset':
      return initialState
    default:
      throw new Error()
  }
}

const AddRoleModal = ({ setShowAddRole, showAddRole }) => {
  const strings = Strings()
  const [state, dispatch] = useReducer(reducer, initialState)

  const [organizationsOptions, setOrganizationsOptions] = useState([])
  const [rolesOptions, setRolesOptions] = useState([])

  const reduxDispatch = useDispatch()

  const roles = getRoles()
  const banner = getShowBanner()
  const organizations = getCurrentUserOrganizations()
  const role = getRole()

  const {
    addRoleModal,
    roleName,
    baseRole,
    addRoleModalSubheading,
    cancel,
    createRoleButton,
    baseRolePlaceholder,
    orgFieldName,
    orgFieldPlaceholder
  } = strings

  const { newRoleSchema } = AdminUtils

  useEffect(() => {
    if (role?.name) setShowAddRole(false)
  }, [role?.name, setShowAddRole])

  useEffect(() => {
    if (roles.length > 0) {
      const rolesFromSameOrg = roles.filter(role => {
        const roleOrgIds = role.organizations.map(({ id }) => id)
        return (
          roleOrgIds.findIndex(orgId => state.organizationIds.includes(orgId)) >
          -1
        )
      })

      const options = rolesFromSameOrg.map(role => ({
        label: role.name,
        value: role.name
      }))

      setRolesOptions(options)
    }
  }, [roles, state.organizationIds])

  useEffect(() => {
    if (organizations.length > 0) {
      const orgOptions = organizations.map(org => ({
        label: org.name,
        value: org.id
      }))

      setOrganizationsOptions(orgOptions)
    }
  }, [organizations])

  useEffect(() => {
    if (!showAddRole) {
      dispatch({ type: 'reset' })
      reduxDispatch(cleanRole())
    }
  }, [showAddRole, reduxDispatch])

  const onChange = ({ target }) => {
    if (banner.show)
      reduxDispatch(showBanner({ show: false, message: '', type: null }))
    dispatch({ type: target.name, value: target.value })
  }

  const onChangeOrganizations = option => {
    if (banner.show)
      reduxDispatch(showBanner({ show: false, message: '', type: null }))

    const organizationIds = option.map(({ value }) => value)
    dispatch({ type: 'organizations', value: organizationIds })
  }

  const onChangeBaseRole = option => {
    if (banner.show)
      reduxDispatch(showBanner({ show: false, message: '', type: null }))
    dispatch({ type: 'base', value: option.value })
  }

  const onSubmitForm = async e => {
    e.preventDefault()
    const { organizationIds, ...rest } = state
    const params = { ...rest, organizationIds }

    try {
      await newRoleSchema(roles).validate(
        { name: params.roleName, organizationIds: params.organizationIds },
        { abortEarly: false }
      )
      reduxDispatch(sendCreateRole(params))
    } catch (err) {
      reduxDispatch(
        showBanner({ show: true, message: err.inner[0].message, type: 'error' })
      )
    }
  }

  const onClickClose = () => {
    setShowAddRole(false)
    reduxDispatch(showBanner({ show: false, message: '', type: null }))
  }

  return (
    <Dialog
      type='offcanvas'
      open={showAddRole}
      onOpenChange={onClickClose}
      className='add-role-modal'
    >
      <Slot name='title'>
        <Text size='400'>{addRoleModal}</Text>
      </Slot>
      <Slot name='content'>
        <Text size='200' tone='700' variant='page' fontWeight='300'>
          {addRoleModalSubheading}
        </Text>
        <LineSeparator />
        <Form onSubmit={onSubmitForm}>
          <FlexV2 direction='column' axisGap={500}>
            <FlexV2 direction='column' axisGap={300}>
              <Text size='200' tone='700' variant='page' fontWeight='300'>
                {roleName}
              </Text>
              <Input
                value={state.roleName}
                onChange={onChange}
                name='name'
                // error={banner.type === 'error'}
              />
            </FlexV2>
            <FlexV2 direction='column' axisGap={300}>
              <Text size='200' tone='700' variant='page' fontWeight='400'>
                {orgFieldName}
              </Text>
              <ElementSelect
                className={classnames({ placeholder: !state.organizationId })}
                placeholder={orgFieldPlaceholder}
                value={state.organizationIds}
                onChange={onChangeOrganizations}
                name='organizations'
                isMulti={true}
                isSearchable={true}
                options={organizationsOptions.map(({ value, label }) => ({
                  value,
                  label
                }))}
                style={{ paddingRight: '1rem' }}
              />
            </FlexV2>
            <FlexV2 direction='column' axisGap={300}>
              <Text size='200' tone='700' variant='page' fontWeight='400'>
                {baseRole}
              </Text>
              <ElementSelect
                placeholder={baseRolePlaceholder}
                className={classnames({ placeholder: !state.baseRole })}
                disabled={state?.organizationIds?.length === 0}
                onChange={onChangeBaseRole}
                value={state.baseRole}
                style={{ paddingRight: '1rem' }}
                options={rolesOptions.map(({ value, label }) => ({
                  value,
                  label
                }))}
              />
            </FlexV2>
          </FlexV2>
          <Slot name='actions'>
            <FlexV2
              alignMainAxis='flex-end'
              axisGap='400'
              className='modal-buttons'
            >
              <Button onClick={onClickClose}>{cancel}</Button>
              <Button
                type='submit'
                variant='primary'
                disabled={
                  !state.roleName || !state.organizationIds.length === 0
                }
              >
                {createRoleButton}
              </Button>
            </FlexV2>
          </Slot>
        </Form>
      </Slot>
    </Dialog>
  )
}

export default AddRoleModal

import { DateTime } from 'luxon'

import { Button, Cell, Row } from '../../../../../primitives'

import Strings from '../Strings'

import { WASTE_REASONS } from '../utils'

function TableRow({ item, onEdit, canEdit }) {
  const strings = Strings()

  const reasons = WASTE_REASONS(strings)

  const {
    id,
    storageFacility,
    produce,
    variety,
    wasteDate,
    totalWeight,
    reason
  } = item

  const handleEdit = () => {
    onEdit(id)
  }

  return (
    <Row>
      <Cell columnName={strings.wasteDate}>
        {DateTime.fromMillis(wasteDate).toFormat('DDD')}
      </Cell>
      <Cell columnName={strings.storageFacility}>{storageFacility.name}</Cell>
      <Cell columnName={strings.produce}>
        {produce.name}
        <span>{variety.name}</span>
      </Cell>
      <Cell columnName={strings.weight}>
        {totalWeight} {strings.kg}
        <span>{reasons[reason]}</span>
      </Cell>
      <Cell className='Operations__Table__Column__Actions'>
        {canEdit && (
          <Button
            variant='info'
            size='small'
            iconBefore='edit'
            onClick={handleEdit}
          />
        )}
      </Cell>
    </Row>
  )
}

export default TableRow

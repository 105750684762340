import { useState } from 'react'
import { Box, Flex, Icon } from '../'
import { getSlottedChildren } from '../utils'
import './index.scss'

/**
 * Collapse Primitive
 * @param {Object} props
 * @param {String | String[]} [props.className]
 * @param {import('react').CSSProperties} [props.css]
 * @param {Boolean} [props.defaultOpen = false] For UNCONTROLLED Component
 * @param {Boolean} [props.open] For CONTROLLED Component
 * @param {Boolean} [props.onOpenChange] For CONTROLLED Component
 * @param {Boolean} [props.disabled] Disable Interaction
 * @param {Boolean} [props.iconBefore = false] Display icon before text
 *
 * @example
 * ```jsx
 * <Collapse>
 *   <Slot name='trigger'>The Trigger Title</Slot>
 *   <Slot name='content'>The Content to Control</Slot>
 * </Collapse>
 * ```
 *
 * @slot trigger - The Trigger Surface.
 * @slot content - The Collapsible Content.
 */

const Collapse = ({
  defaultOpen = null,
  open = null,
  onOpenChange = null,
  disabled = false,
  children = null,
  className = '',
  iconBefore = false,
  ...rest
}) => {
  const { trigger, content } = getSlottedChildren(children, {
    trigger: null,
    content: null
  })

  const [collapseOpen, setCollapseOpen] = useState(defaultOpen || false)

  const handleCollapse = () => {
    setCollapseOpen(!collapseOpen)
  }

  return (
    <Box
      className={['Collapse', className]}
      open={open ?? collapseOpen}
      aria-disabled={disabled}
      {...rest}
    >
      <Flex
        className='Collapse__trigger'
        axisGap={400}
        alignMainAxis={!iconBefore ? 'space-between' : null}
        alignCrossAxis={'center'}
        onClick={onOpenChange ?? handleCollapse}
      >
        {iconBefore && (
          <Icon name={`expand ${open || collapseOpen ? 'less' : 'more'}`} />
        )}
        <Box className='Collapse__title'>{trigger || 'Toggle'}</Box>
        {!iconBefore && (
          <Icon name={`expand ${open || collapseOpen ? 'less' : 'more'}`} />
        )}
      </Flex>
      {content && (
        <Box
          className='Collapse__content'
          aria-hidden={!(open || collapseOpen)}
        >
          {content}
        </Box>
      )}
    </Box>
  )
}

export default Collapse

import { Text, FlexV2 } from '@/primitives'
import LineSeparator from '@/elements/LineSeparator'
import useMediaQuery from '@/hooks/useMediaQuery'

import { Strings } from './utils'
import CollapseFilter from './CollapseFilter'
import CollapseGeneral from './CollapseGeneral'

const DataboardSidebar = () => {
  const strings = Strings()
  const isAboveBreakpoint = useMediaQuery('min-width: 900px')

  return (
    <FlexV2
      direction='column'
      wrap='nowrap'
      axisGap={500}
      className={'Databoard__Sidebar'}
    >
      <Text variant={'page'} tone={900} size={200} fontWeight={500}>
        {strings.configureDataboard}
      </Text>
      <LineSeparator />
      <CollapseGeneral isAboveBreakpoint={isAboveBreakpoint} />
      <LineSeparator />
      <CollapseFilter isAboveBreakpoint={isAboveBreakpoint} />
    </FlexV2>
  )
}

export default DataboardSidebar

import { Fragment, useCallback, useEffect, useState } from 'react'
import { I18n } from 'aws-amplify'
import { useDispatch } from 'react-redux'

import { fetchProduceThresholds } from '@/slices/operations/produceThreshold'

import {
  getCurrentUser,
  getOperationsProduceThresholds,
  getOperationsProduceThresholdsCount,
  getOperationsProduceThresholdLoading,
  getMeasurements
} from '@/reducers/selectors'

import {
  Flex,
  Button,
  Cell,
  Text,
  Column,
  Row,
  Slot,
  Table,
  Loader
} from '@/primitives'

import { Pagination } from '@/elements'

import HeaderV2 from '@/components/Operations/Shared/HeaderV2'

import { nameLookup } from '@/Util/UnitUtils'

import history from '../../../../history'

import {
  hasEditPermissions,
  RESOURCE_TYPE_OPERATIONS,
  RESOURCE_TYPE_OPERATIONS_MANAGEMENT
} from '@/Util/PermissionUtils'

function ProduceThresholdTable({ state, dispatchState, modulePath }) {
  const dispatch = useDispatch()

  const currentUser = getCurrentUser()
  const produceThresholds = getOperationsProduceThresholds()
  const produceThresholdsCount = getOperationsProduceThresholdsCount()
  const loading = getOperationsProduceThresholdLoading()
  const allMeasurements = getMeasurements()

  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(9)
  const [tableState, setTableState] = useState({})
  const [canEdit, setCanEdit] = useState(false)
  const [fetchCalled, setFetchCalled] = useState(false)

  const requestProduceThresholds = useCallback(
    page => {
      dispatch(
        fetchProduceThresholds({
          limit: pageSize,
          offset: page - 1
        })
      )
      setFetchCalled(true)
    },
    [state, pageSize, dispatch]
  )

  const changePage = useCallback(
    newPage => {
      dispatchState({ page: newPage })
      setFetchCalled(false)
    },
    [dispatchState]
  )

  useEffect(() => {
    if (currentUser.userName) {
      setCanEdit(
        hasEditPermissions(
          currentUser,
          RESOURCE_TYPE_OPERATIONS,
          RESOURCE_TYPE_OPERATIONS_MANAGEMENT
        )
      )
    }
  }, [currentUser])

  useEffect(() => {
    if (tableState.page !== state.page) {
      if (!fetchCalled) {
        requestProduceThresholds(state.page)
      }
    }
  }, [state, tableState, requestProduceThresholds, fetchCalled])

  useEffect(() => {
    setTableState({ ...state })
  }, [state])

  const onCreate = () => history.push(`${modulePath}/create`)

  const onEdit = id => history.push(`${modulePath}/update/${id}`)

  return (
    <Fragment>
      <HeaderV2
        title={I18n.get('Manage Produce Threshold')}
        buttonIcon={'add'}
        buttonText={I18n.get('Add Produce Threshold')}
        buttonCallback={onCreate}
        showButton={canEdit}
      />
      <Loader isLoading={loading}>
        {produceThresholdsCount === 0 && (
          <Flex className={'Operations__Table__Empty'}>
            <Text>{I18n.get('No entries')}</Text>
          </Flex>
        )}
        {produceThresholdsCount > 0 && (
          <Table aria-colcount='3' className='Operations__Table'>
            <Slot name='head'>
              <Row>
                <Column>{I18n.get('Name')}</Column>
                <Column>{I18n.get('Measurements')}</Column>
                <Column />
              </Row>
            </Slot>
            <Slot name='body'>
              {produceThresholds.map(({ id, name, measurements }) => (
                <Row key={id}>
                  <Cell columnName={I18n.get('Name')}>{name}</Cell>
                  <Cell
                    columnName={I18n.get('Measurements')}
                    className='Operations__Table__Measurements_Cell'
                  >
                    {measurements.map(measurement => (
                      <Text
                        key={measurement.key}
                        className='Operations__Table__Tag'
                        tone='400'
                        variant='page'
                        size={100}
                        fontWeight={600}
                      >
                        {nameLookup(measurement.key, allMeasurements)}
                      </Text>
                    ))}
                  </Cell>
                  <Cell className='Operations__Table__Column__Actions'>
                    {canEdit && (
                      <Button
                        variant='info'
                        size='small'
                        iconBefore='edit'
                        onClick={() => onEdit(id)}
                      />
                    )}
                  </Cell>
                </Row>
              ))}
            </Slot>
          </Table>
        )}
      </Loader>
      {produceThresholdsCount > pageSize && (
        <Pagination
          type='simple'
          totalItems={produceThresholdsCount}
          totalPages={totalPages}
          setTotalPages={setTotalPages}
          page={tableState.page}
          setPage={changePage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </Fragment>
  )
}

export default ProduceThresholdTable

import { Fragment, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { requestProducts } from '@/actions/operations/product'
import { deleteReturnItem } from '@/actions/operations/return'

import {
  getCurrentUserOrganizations,
  getOperationsProducts
} from '@/reducers/selectors'

import { Text, Table, Column, Row, Cell, Slot, Button } from '@/primitives'

import { REMOVE_RETURN_ITEM } from '../state'
import { RETURN_ITEM_REASONS } from './utils'

import Strings from '../../Strings'

function ReturnItemFormTable({
  parent,
  returnItems,
  handleEdit,
  dispatchFormState
}) {
  const dispatch = useDispatch()

  const strings = Strings()

  const organizations = getCurrentUserOrganizations()
  const products = getOperationsProducts()

  const reasons = RETURN_ITEM_REASONS(strings)

  useEffect(() => {
    if (organizations.length > 0) {
      dispatch(
        requestProducts({
          organizationId: organizations.map(o => o.id)
        })
      )
    }
  }, [dispatch, organizations])

  const getProduct = productId => {
    if (productId) {
      const productIndex = products.findIndex(p => p.id === productId)
      if (productIndex >= 0) {
        return products[productIndex]
      }
    }
    return null
  }

  const onRemove = async returnItem => {
    if (parent.id) {
      let currentIndex = parent.returnItems.findIndex(
        item => item.id === returnItem.id
      )
      if (currentIndex >= 0) {
        dispatch(
          deleteReturnItem({
            returnItemId: returnItem.id
          })
        )
      }
    }
    dispatchFormState({
      type: REMOVE_RETURN_ITEM,
      returnItem: returnItem
    })
  }

  const calculateWeight = (product, quantityReturned) => {
    return Number.parseFloat(
      (product.weight * quantityReturned) / 1000,
      2
    ).toFixed(2)
  }

  const ProductCells = ({ returnItem }) => {
    let { productId, product } = returnItem
    if (!product) {
      product = getProduct(productId)
    }
    return (
      <Fragment>
        <Cell columnName={strings.product}>
          {product.name}&nbsp;
          <span>
            {product.weight}
            {strings.grams}
          </span>
        </Cell>
        <Cell columnName={strings.quantity}>
          {returnItem.quantityReturned}
          <span>
            {calculateWeight(product, returnItem.quantityReturned)}
            {strings.kilograms}
          </span>
        </Cell>
      </Fragment>
    )
  }

  if (!returnItems?.length) {
    return (
      <Text className={'Operations__Form__Empty'}>{strings.noReturnItems}</Text>
    )
  }

  return (
    <Table className='Operations__Table' aria-colcount='4'>
      <Slot name='head'>
        <Row>
          <Column>{strings.product}</Column>
          <Column>{strings.quantity}</Column>
          <Column>{strings.reason}</Column>
          <Column />
        </Row>
      </Slot>
      <Slot name='body'>
        {returnItems?.map(returnItem => (
          <Row key={returnItem.id}>
            <ProductCells returnItem={returnItem} />
            <Cell columnName={strings.reason}>
              {reasons[returnItem.reason]}
            </Cell>
            <Cell className={'Operations__Table__Column__Actions'}>
              <Button
                variant='info'
                size='small'
                iconBefore='edit'
                onClick={() => handleEdit(returnItem)}
                style={{ marginRight: '5px' }}
              />
              <Button
                variant='error'
                size='small'
                iconBefore='delete'
                onClick={() => onRemove(returnItem)}
              />
            </Cell>
          </Row>
        ))}
      </Slot>
    </Table>
  )
}

export default ReturnItemFormTable

import { nanoid } from 'nanoid'
import { useState, useEffect } from 'react'

import { Flex, Text, Card, Slot, Image, Button } from '../../../../primitives'

import { Dialog } from '../../../../elements'

import PhotoForm from './Form'

import { PHOTO_SUBJECT, PHOTO_STATUS } from './config'

import Strings from './Strings'

const initialFormState = {
  id: '',
  key: '',
  subject: '',
  status: '',
  dataURI: '',
  updatedAt: ''
}

export const getPhotoSrc = photo => {
  return photo.dataURI || photo.url
}

function Photos({ photos = [], updatePhoto, removePhoto }) {
  const strings = Strings()
  const plantSubject = PHOTO_SUBJECT()
  const plantStatus = PHOTO_STATUS()

  const [takePhoto, setTakePhoto] = useState(false)
  const [formState, setFormState] = useState(initialFormState)

  useEffect(() => {
    if (formState?.id) {
      setTakePhoto(true)
    }
  }, [formState])

  const cancelPhoto = () => {
    setTakePhoto(false)
    setFormState(initialFormState)
  }

  const deletePhoto = (e, photo) => {
    e.stopPropagation()
    removePhoto(photo)
  }

  const savePhoto = photo => {
    if (!photo.id) {
      photo.id = nanoid()
    }
    photo.updatedAt = Date.now()
    updatePhoto(photo)
    cancelPhoto()
  }

  return (
    <Flex axisGap={600} direction='column' className='Operations__Form__Photos'>
      <Flex alignMainAxis='space-between' alignCrossAxis='center' axisGap={400}>
        <Text variant='page' tone={700}>
          {strings.photos}
        </Text>
      </Flex>

      <Flex axisGap={500} direction='column'>
        <Flex alignMainAxis={'flex-end'}>
          <Button
            onClick={() => setTakePhoto(true)}
            size='small'
            name='add'
            variant='primary'
            iconBefore='add'
            className='Operations__Form__Weights__Add'
          >
            {strings.addPhoto}
          </Button>
        </Flex>

        {takePhoto && (
          <Dialog open={takePhoto} onOpenChange={cancelPhoto}>
            <Slot name='title'>
              <Flex direction='column' style={{ marginBottom: '1em' }}>
                <Text as='h5' style={{ marginBottom: '0.5em' }}>
                  {strings.newPhoto}
                </Text>
                <Text as='p' style={{ marginBottom: '0.5em' }}>
                  {strings.addPhoto}
                </Text>
              </Flex>
            </Slot>
            <Slot name='content'>
              <Flex>
                <PhotoForm
                  photo={formState}
                  cancelPhoto={cancelPhoto}
                  savePhoto={savePhoto}
                />
              </Flex>
            </Slot>
          </Dialog>
        )}

        <Flex
          className={'Operations__Form__Photos__List'}
          alignMainAxis={'flex-start'}
          alignCrossAxis={'space-between'}
          axisGap={600}
          direction='row'
        >
          {photos?.map(photo => (
            <Card
              key={photo.id}
              className='Operations__Form__Photos__List__Card'
              onClick={() => setFormState(photo)}
            >
              <Slot name='image'>
                <Button
                  iconBefore='close'
                  variant='text'
                  className={'Operations__Form__Photos__List__Card__Delete'}
                  onClick={e => deletePhoto(e, photo)}
                />
                <Image src={getPhotoSrc(photo)} alt={'Photo'} />
                <Flex className={'Operations__Form__Photos__Text'}>
                  <Flex direction={'column'} axisGap={100}>
                    <Text size={200}>
                      {plantSubject[photo.subject]} ({plantStatus[photo.status]}
                      )
                    </Text>
                  </Flex>
                </Flex>
              </Slot>
            </Card>
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Photos

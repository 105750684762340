import { Box } from '../'
import { getSlottedChildren } from '../utils'
import './index.scss'

/* -----------------------------------------------------------------------------
 * Manual Table
 * ---------------------------------------------------------------------------*/
/**
 * Table Primitive
 * @param {Object} props
 * @param {String} [props.className] Table Class
 * @param {Boolean} [props.sticky=false] A Sticky Table Header
 * @param {Number} [props.maxHeight=null] Limit Table Height
 * @param {String} [props.title=null] Accessible Table Title
 * @param {import('react').Children} [props.children=null] Table Slots
 * @param {import('react').CSSProperties} [props.css] IonCSS Styles
 *
 * @example
 * ```jsx
 * <Table>
 *  <Slot name='caption'>Slot Based Caption Data</Slot>
 *  <Slot name='head'>
 *    <Row>
 *      <Column>User</Column>
 *    </Row>
 *  </Slot>
 *  <Slot name='body'>
 *    <Row>
 *      <Cell columnName={'User'}>
 *        Cell Content
 *      </Cell>
 *    </Row>
 *  </Slot>
 *  <Slot name='foot'>
 *    <Row>
 *      <Cell>User</Cell>
 *    </Row>
 *  </Slot>
 * </Table>
 * ```
 */
const Table = ({
  children = null,
  className = '',
  sticky = false,
  maxHeight = null,
  title = null,
  ...rest
}) => {
  const slots = getSlottedChildren(children, {
    caption: null,
    head: null,
    body: null,
    foot: null
  })

  return (
    <Box
      className={[
        'TableWrap',
        { mod__sticky: sticky, mod__maxheight: maxHeight }
      ]}
      style={{ maxHeight }}
    >
      <Box
        as='table'
        className={['Table', className]}
        role='grid'
        aria-label={title}
        aria-rowcount={
          slots.body
            ? slots.body[0].props?.children?.props?.children?.length
            : -1
        }
        aria-colcount={
          slots.head
            ? slots.head[0].props?.children?.props?.children?.length
            : -1
        }
        {...rest}
      >
        {slots.caption && <Box as={'caption'}>{slots.caption}</Box>}
        {slots.head && <Box as={'thead'}>{slots.head}</Box>}
        {slots.body && <Box as={'tbody'}>{slots.body}</Box>}
        {slots.foot && <Box as={'tfoot'}>{slots.foot}</Box>}
      </Box>
    </Box>
  )
}

export default Table

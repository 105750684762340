import { Fragment } from 'react'
import { Button, Cell, Row } from '../../../../../primitives'

import Strings from '../Strings'

function TableRow({ item, onEdit, onDelete, canEdit }) {
  const strings = Strings()

  const {
    id,
    customer,
    product,
    currency,
    unitPrice,
    discount,
    articleCode,
    description,
    alternateDescription
  } = item

  const handleEdit = () => {
    onEdit(id)
  }

  const handleDelete = () => {
    onDelete(id)
  }

  return (
    <Row>
      <Cell columnName={strings.article}>
        {articleCode}
        <span>{customer.name}</span>
      </Cell>
      <Cell columnName={strings.description}>
        {description}
        <span>{alternateDescription}</span>
      </Cell>
      <Cell columnName={strings.product}>
        {product.name}
        <span>
          {product.weight}
          {strings.grams}
        </span>
      </Cell>
      <Cell columnName={strings.price}>
        {unitPrice} {currency.code}
        {discount && (
          <span>
            {discount}
            {strings.percent}&nbsp;{strings.discountLowerCase}
          </span>
        )}
      </Cell>
      <Cell className='Operations__Table__Column__Actions'>
        {canEdit && (
          <Fragment>
            <Button
              variant='info'
              size='small'
              iconBefore='edit'
              onClick={handleEdit}
            />
            <Button
              variant='error'
              size='small'
              iconBefore='delete'
              onClick={handleDelete}
            />
          </Fragment>
        )}
      </Cell>
    </Row>
  )
}

export default TableRow

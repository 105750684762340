import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { requestBranches } from '@/actions/operations/branch'

import {
  getCurrentUser,
  getOperationsBranches,
  getOperationsBranchesCount,
  getOperationsLoading
} from '@/reducers/selectors'

import { Flex, Box, Table, Slot, Column, Row, Text, Loader } from '@/primitives'

import { Pagination } from '@/elements'

import TableFilter from './TableFilter'
import TableRow from './TableRow'

import history from '../../../../../history'

import {
  hasEditPermissions,
  RESOURCE_TYPE_OPERATIONS,
  RESOURCE_TYPE_OPERATIONS_CUSTOMERS
} from '@/Util/PermissionUtils'

import Strings from '../Strings'
import HeaderV2 from '@/components/Operations/Shared/HeaderV2'

function BranchTable({ state, dispatchState, modulePath, backPath }) {
  const dispatch = useDispatch()

  const currentUser = getCurrentUser()
  const items = getOperationsBranches()
  const totalItems = getOperationsBranchesCount()
  const loading = getOperationsLoading()

  const strings = Strings()

  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(9)
  const [tableState, setTableState] = useState(state)
  const [canEdit, setCanEdit] = useState(false)

  useEffect(() => {
    if (currentUser.userName) {
      setCanEdit(
        hasEditPermissions(
          currentUser,
          RESOURCE_TYPE_OPERATIONS,
          RESOURCE_TYPE_OPERATIONS_CUSTOMERS
        )
      )
    }
  }, [currentUser])

  useEffect(() => {
    if (tableState.page !== state.page || items.length === 0) {
      setTableState({ ...state })
      fetchBranches(state.page)
    }
  }, [state.page])

  useEffect(() => {
    if (
      tableState.organizations !== state.organizations ||
      tableState.customers !== state.customers
    ) {
      setTableState({ ...state })
      fetchBranches(1)
      changePage(1)
    }
  }, [state.organizations, state.customers])

  const fetchBranches = page => {
    if (state.organizations.length > 0 && state.customers.length > 0) {
      dispatch(
        requestBranches({
          filter: {
            organizationId: state.organizations,
            customerId: state.customers
          },
          limit: pageSize,
          offset: page - 1
        })
      )
    }
  }

  const changePage = newPage => {
    dispatchState({
      page: newPage
    })
  }

  const onCreate = () => {
    history.push(`${modulePath}/create`)
  }

  const onEdit = id => {
    history.push(`${modulePath}/update/${id}`)
  }

  return (
    <Flex axisGap={400} direction='column'>
      <HeaderV2
        title={strings.manageBranches}
        buttonIcon={'add'}
        buttonText={strings.createBranch}
        buttonCallback={onCreate}
        showButton={canEdit}
        backPath={backPath}
        breakpointWidth={800}
      >
        <TableFilter state={state} dispatchState={dispatchState} />
      </HeaderV2>
      <Loader isLoading={loading}>
        {totalItems === 0 && (
          <Flex className={'Operations__Table__Empty'}>
            <Text>{strings.noBranches}</Text>
          </Flex>
        )}
        {totalItems > 0 && (
          <Table className='Operations__Table' aria-colcount='5'>
            <Slot name='head'>
              <Row>
                <Column>{strings.name}</Column>
                <Column>{strings.contact}</Column>
                <Column>{strings.location}</Column>
                <Column>{strings.status}</Column>
                <Column />
              </Row>
            </Slot>
            <Slot name='body'>
              {items?.map(item => (
                <TableRow
                  key={item.id}
                  onEdit={onEdit}
                  canEdit={canEdit}
                  item={item}
                />
              ))}
            </Slot>
          </Table>
        )}
        {totalItems > pageSize && (
          <Box className='pagination-menu'>
            <Pagination
              type='simple'
              totalItems={totalItems}
              totalPages={totalPages}
              setTotalPages={setTotalPages}
              page={tableState.page}
              setPage={changePage}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          </Box>
        )}
      </Loader>
    </Flex>
  )
}

export default BranchTable

import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Dialog, LineSeparator } from '@/elements'
import { Slot, Text, Flex, Input, Label, Button } from '@/primitives'

import {
  generateTextFromPrompt,
  cleanGeneratedText
} from '@/slices/fyi/helpText'
import {
  getGeneratedHelpText,
  getLoadingGeneratedText
} from '@/reducers/selectors'

import Strings from '../../Strings'

const strings = Strings()

const GenerateTextForm = ({ showForm, setHideForm, setHelpText }) => {
  const [prompt, setPrompt] = useState('')
  const reduxDispatch = useDispatch()
  const generatedHelpText = getGeneratedHelpText()
  const loading = getLoadingGeneratedText()

  useEffect(() => {
    if (!showForm && generatedHelpText?.length > 0) {
      setPrompt('')
      reduxDispatch(cleanGeneratedText())
    }
  }, [showForm, generatedHelpText?.length, reduxDispatch])

  function onChangeInput({ target }) {
    const { value } = target
    setPrompt(value)
  }

  function generateText() {
    reduxDispatch(generateTextFromPrompt({ prompt }))
  }

  function useGeneratedText() {
    setHelpText(generatedHelpText)
    setHideForm()
  }

  return (
    <Dialog open={showForm} onOpenChange={setHideForm}>
      <Slot name='title'>
        <Text size={400} fontWeight={400}>
          {strings.generateHelpTextHeading}
        </Text>
      </Slot>
      <Slot name='content'>
        <Text size={200} as='p'>
          {strings.generateHelpTextSubheading}
        </Text>
        <LineSeparator />
        <Label>
          <Text>{strings.prompt}</Text>
          <Input
            name='prompt'
            as='textarea'
            onChange={onChangeInput}
            value={prompt}
          />
          <Flex alignMainAxis='flex-end' style={{ marginTop: '0.6rem' }}>
            <Button
              size='small'
              variant='info'
              onClick={generateText}
              loading={loading}
              disabled={prompt?.length === 0}
            >
              <Text>{strings.generateTextBtn}</Text>
            </Button>
          </Flex>
        </Label>
        <Label>
          <Text>{strings.result}</Text>
          <Input
            disabled
            name='text'
            as='textarea'
            onChange={onChangeInput}
            value={generatedHelpText}
          />
        </Label>
      </Slot>
      <Slot name='actions'>
        <Flex alignMainAxis='space-between'>
          <Button size='small' variant='error' onClick={setHideForm}>
            {strings.cancel}
          </Button>
          <Button
            size='small'
            variant='primary'
            onClick={useGeneratedText}
            disabled={generatedHelpText?.length === 0}
          >
            {strings.useTextBtn}
          </Button>
        </Flex>
      </Slot>
    </Dialog>
  )
}

export default GenerateTextForm

import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { getCognitoData, makeGraphqlRequest } from '../utils'

/*
 * Queries
 */

export const getNextHourPrediction = async params => {
  try {
    const query = /* GraphQL */ `
      query getNextHourPrediction(
        $siteId: String!
        $zoneId: String!
        $measurementId: String!
        $valueType: String!
      ) {
        getNextHourPrediction(
          siteId: $siteId
          zoneId: $zoneId
          measurementId: $measurementId
          valueType: $valueType
        ) {
          siteId
          zoneId
          measurementId
          predictions {
            confidence
            value
          }
        }
      }
    `

    const { id, idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken,
      id
    )

    if (error) {
      return {
        prediction: null,
        error: `Prediction not fetched: ${error}`
      }
    }

    return { prediction: data?.getNextHourPrediction, error: null }
  } catch (err) {
    return { prediction: null, error: err.message }
  }
}

import { call, put, takeLatest } from 'redux-saga/effects'

import {
  getFertilizerPurchases,
  getFertilizerPurchase,
  createFertilizerPurchase,
  updateFertilizerPurchase
} from '@/api/operations/fertilizerPurchase'

import {
  REQUEST_FERTILIZER_PURCHASES,
  REQUEST_FERTILIZER_PURCHASE,
  CREATE_FERTILIZER_PURCHASE,
  UPDATE_FERTILIZER_PURCHASE,
  receiveFertilizerPurchases,
  receiveFertilizerPurchase,
  fertilizerPurchaseCreated,
  fertilizerPurchaseUpdated
} from '@/actions/operations/fertilizerPurchase'

import { displayBanner } from '../utils'

import Strings from './Strings'

const strings = Strings()

function* sendCreateFertilizerPurchase(action) {
  const { data, error = null } = yield call(
    createFertilizerPurchase,
    action.params
  )
  yield put(displayBanner(error, strings.fertilizerPurchaseCreated))
  yield put(fertilizerPurchaseCreated(data, error))
}

export function* watchCreateFertilizerPurchase() {
  yield takeLatest(CREATE_FERTILIZER_PURCHASE, sendCreateFertilizerPurchase)
}

function* sendUpdateFertilizerPurchase(action) {
  const { data, error = null } = yield call(
    updateFertilizerPurchase,
    action.params
  )
  yield put(displayBanner(error, strings.fertilizerPurchaseUpdated))
  yield put(fertilizerPurchaseUpdated(data, error))
}

export function* watchUpdateFertilizerPurchase() {
  yield takeLatest(UPDATE_FERTILIZER_PURCHASE, sendUpdateFertilizerPurchase)
}

function* sendRequestFertilizerPurchases(action) {
  const { data, error = null } = yield call(
    getFertilizerPurchases,
    action.params
  )
  const { fertilizerPurchases = [], count = 0 } = data
  yield put(receiveFertilizerPurchases(fertilizerPurchases, count, error))
}

export function* watchRequestFertilizerPurchases() {
  yield takeLatest(REQUEST_FERTILIZER_PURCHASES, sendRequestFertilizerPurchases)
}

function* sendRequestFertilizerPurchase(action) {
  const { data, error = null } = yield call(
    getFertilizerPurchase,
    action.params
  )
  yield put(receiveFertilizerPurchase(data, error))
}

export function* watchRequestFertilizerPurchase() {
  yield takeLatest(REQUEST_FERTILIZER_PURCHASE, sendRequestFertilizerPurchase)
}

import { Button, Cell, Row } from '../../../../../primitives'

import { formatDate, formatFloat } from '../../utils'
import { FERTILIZER_PURCHASE_STATUSES } from '../utils'

import Strings from '../Strings'

function TableRow({ item, onEdit, canEdit, allFertilizersMap }) {
  const strings = Strings()

  const statuses = FERTILIZER_PURCHASE_STATUSES(strings)

  const {
    id,
    storageFacility,
    fertilizerId,
    supplier,
    currency,
    quantity,
    unitPrice,
    unitWeight,
    tax,
    orderDate,
    receivedDate,
    status
  } = item

  const handleEdit = () => {
    onEdit(id)
  }

  const getPrice = () => {
    return formatFloat(unitPrice * quantity)
  }

  const fertilizer = allFertilizersMap[fertilizerId]

  return (
    <Row>
      <Cell columnName={strings.status}>
        {statuses[status]}
        <span>{supplier.name}</span>
      </Cell>
      <Cell columnName={strings.order}>
        {quantity}x {fertilizer.name} ({unitWeight}
        {strings.kg})<span>{formatDate(orderDate)}</span>
      </Cell>
      <Cell columnName={strings.price}>
        {getPrice()} {currency.code}
        <span>
          +{tax}% {strings.taxLowerCase}
        </span>
      </Cell>
      <Cell columnName={strings.delivery}>
        {storageFacility.name}
        <span>{formatDate(receivedDate)}</span>
      </Cell>
      <Cell className='Operations__Table__Column__Actions'>
        {canEdit && (
          <Button
            variant='info'
            size='small'
            iconBefore='edit'
            onClick={handleEdit}
          />
        )}
      </Cell>
    </Row>
  )
}

export default TableRow

import { Fragment, useRef } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { I18n } from 'aws-amplify'

import CropsBarTooltip from './CropsBarTooltip'
// import ChartExportModal from '../ChartExportModal'

import useMediaQuery from '@/hooks/useMediaQuery'
import {
  getDataboardCrops,
  getDataboardCropArgs,
  getDataboardSitesHierarchies
} from '@/reducers/selectors'
import { Flex, Button, Text } from '@/primitives'

import { getZoneName } from '@/Util/ZoneUtils'
import {
  BAR_GRAPH_THEME,
  createDownloadableChart,
  getStringsByContext
} from '../utils'

const ONE_DAY = 86400 * 1000
const CHART_MARGIN_TOP = 50
const BAR_GAPS = 0.05
const BAR_HEIGHT = 55
const CHART_VERTICAL_MARGIN_DESKTOP = 220
const CHART_VERTICAL_MARGIN_MOBILE = 70
const CHART_MARGIN_LEFT = 150
const SPACE_BETWEEN_TICKS = 70

const CropsBarChart = () => {
  // const [showDownload, setShowDownload] = useState(false)
  const chartRef = useRef(null)
  const isAboveBreakpoint = useMediaQuery('min-width: 769px')

  const crops = getDataboardCrops()
  const cropsDataArgs = getDataboardCropArgs()
  const zonesHierarchy = getDataboardSitesHierarchies()

  if (crops.length === 0 || !cropsDataArgs?.dates) {
    return null
  }

  const [startDate, endDate] = cropsDataArgs?.dates
  const startDateTs = new Date(startDate).valueOf()
  const endDateTs = new Date(endDate).valueOf()

  const keys = [
    'unplanned',
    'planned',
    'sowing',
    'transplanting',
    'harvesting',
    'harvested'
  ]

  const data = crops.map(crop => {
    let chartCrop = {}
    let daysCount = 0

    const {
      id,
      createdAt,
      sowDate,
      transplantDate,
      harvestStartDate,
      harvestEndDate
    } = crop

    const values = [
      createdAt,
      sowDate,
      transplantDate,
      harvestStartDate,
      harvestEndDate,
      endDateTs
    ]

    keys.forEach((key, index) => {
      const cropStatus = keys[index]

      if (!values[index]) {
        chartCrop[cropStatus] = 0
      }

      if (values[index] && values[index] - startDateTs > 0) {
        const numDays =
          Math.ceil((values[index] - startDateTs) / ONE_DAY) - daysCount
        if (numDays > 0) chartCrop[cropStatus] = numDays
        daysCount += numDays
      }
    })
    return { ...chartCrop, id }
  })

  const theme = BAR_GRAPH_THEME
  theme.axis.ticks = {
    ...theme.axis.ticks,
    line: { stroke: 'var(--ctx-theme-color-page-500)' }
  }
  theme.axis.domain = {
    ...theme.axis.domain,
    line: { stroke: 'var(--ctx-theme-color-page-500)' }
  }

  const colors = [
    '#9bfbe8',
    '#15f5ca',
    '#09d3ac',
    '#07a788',
    '#057a64',
    '#034e3f'
  ]

  const { downloadDocTitle } = getStringsByContext('crops')

  function getTicksCount(chartMarginRight) {
    if (chartRef?.current?.clientWidth) {
      const chartWidth =
        chartRef?.current?.clientWidth - (CHART_MARGIN_LEFT + chartMarginRight)
      return Math.floor(chartWidth / SPACE_BETWEEN_TICKS)
    }
  }

  const onDownloadHandler = async e => {
    e.preventDefault()
    const isoStartDate = new Date(cropsDataArgs.dates[0]).toISOString()
    const isoEndDate = new Date(cropsDataArgs.dates[1]).toISOString()
    const dates = `${isoStartDate.split('T')[0]} - ${isoEndDate.split('T')[0]}`
    const fileName = `crops_data_${dates.replaceAll(' ', '_')}`
    const title = { main: downloadDocTitle, sub: dates }
    await createDownloadableChart(chartRef, fileName, title)
    // setShowDownload(true)
  }

  const legendLabels = [
    { label: I18n.get('Not planned'), color: colors[0] },
    { label: I18n.get('Planned'), color: colors[1] },
    { label: I18n.get('Sowed'), color: colors[2] },
    { label: I18n.get('Transplanted'), color: colors[3] },
    { label: I18n.get('Harvesting'), color: colors[4] },
    { label: I18n.get('Harvest ended'), color: colors[5] }
  ]

  const chartMarginBottom = isAboveBreakpoint
    ? CHART_VERTICAL_MARGIN_MOBILE
    : CHART_VERTICAL_MARGIN_DESKTOP
  const verticalMargins =
    CHART_MARGIN_TOP + chartMarginBottom + BAR_GAPS * crops.length
  const containerBarHeight = BAR_HEIGHT * crops.length
  const chartMarginRight = isAboveBreakpoint ? 150 : 20
  const tickValues = getTicksCount(chartMarginRight)

  const nivoProps = {
    animate: false,
    data,
    keys,
    theme: BAR_GRAPH_THEME,
    indexBy: 'id',
    margin: {
      top: CHART_MARGIN_TOP,
      right: chartMarginRight,
      bottom: chartMarginBottom,
      left: CHART_MARGIN_LEFT
    },
    padding: BAR_GAPS,
    layout: 'horizontal',
    valueScale: { type: 'linear' },
    indexScale: { type: 'band', round: true },
    colors,
    tooltip: CropsBarTooltip,
    axisTop: null,
    axisRight: null,
    axisBottom: {
      tickValues,
      tickSize: 5,
      tickPadding: 5,
      tickRotation: -20,
      format: labelData => {
        const daysInMillis = startDateTs + ONE_DAY * labelData
        const displayDate = new Date(daysInMillis).toISOString().split('T')[0]
        return <tspan>{displayDate}</tspan>
      }
    },
    axisLeft: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      format: labelData => {
        const cropInfo = crops.find(({ id }) => id === labelData)
        const { zoneId, siteId, produce, variety } = cropInfo
        const zoneName = getZoneName(zonesHierarchy, siteId, zoneId)
        return (
          <Fragment>
            <tspan x='0' dy='-15px' style={{ fontSize: '11px' }} fillOpacity={0.75}>
              {zoneName ?? '-'}
            </tspan>
            <tspan style={{ fontSize: '12px' }} x='0' dy='15px'>
              {produce.name}
            </tspan>
            <tspan
              style={{ fontWeight: '800', fontSize: '12px' }}
              x='0'
              dy='15px'
            >
              {variety.name}
            </tspan>
          </Fragment>
        )
      }
    },
    enableGridY: false,
    enableLabel: false,
    labelSkipWidth: 12,
    labelSkipHeight: 12,
    labelTextColor: {
      from: 'color',
      modifiers: [['darker', 1.6]]
    },
    legends: [
      {
        dataFrom: 'keys',
        anchor: isAboveBreakpoint ? 'bottom-right' : 'bottom-left',
        direction: 'column',
        justify: false,
        translateX: isAboveBreakpoint ? 120 : 0,
        translateY: isAboveBreakpoint ? 0 : 200,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 20,
        data: legendLabels,
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1
            }
          }
        ]
      }
    ]
  }

  // const exportData = {
  //   fromDate: startDate,
  //   toDate: endDate,
  //   nivoProps
  // }

  return (
    <div
      ref={chartRef}
      style={{
        height: `${containerBarHeight + verticalMargins}px`,
        width: '99%',
        marginBottom: '4rem'
      }}
    >
      {/* <ChartExportModal
        showModal={showDownload}
        setShowModal={setShowDownload}
        exportData={exportData}
        height={containerBarHeight + verticalMargins}
      /> */}
      <Flex
        alignMainAxis='space-between'
        alignCrossAxis='flex-end'
        axisGap={300}
        wrap='nowrap'
      >
        <Text variant='page' tone={900} size={200}>
          {downloadDocTitle}
        </Text>
        <Button
          onClick={onDownloadHandler}
          iconBefore='download'
          variant='info'
          size='small'
        />
      </Flex>
      <ResponsiveBar {...nivoProps} />
    </div>
  )
}

export default CropsBarChart

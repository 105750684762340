import { useEffect } from 'react'
import { I18n } from 'aws-amplify'
import { useDispatch } from 'react-redux'

import { parseValue } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/utils'
import useThresholdStatus from '../../../Hooks/useThresholdStatus'
import { useLiveData } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Hooks/useLiveData'
import Value from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/Chart/Value'

import { cleanNextHourPrediction } from '@/slices/ml/prediction'

import { usePredictionData } from '../../../Hooks/usePredictionData'

import { handleMeasurementConversion } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/utils'

import { getCurrentUser, getRootZone } from '@/reducers/selectors'

export default function Live({
  config,
  unit = null,
  options,
  valueModifierCallback = null
}) {
  const prediction = usePredictionData(options?.measurement)
  const liveData = useLiveData({
    measurements: [options?.measurement]
  })
  const dispatch = useDispatch()

  const thresholdStatus = useThresholdStatus(options?.measurement)

  const currentUser = getCurrentUser()
  const rootZone = getRootZone()

  useEffect(() => {
    return () => {
      dispatch(cleanNextHourPrediction(options?.measurement))
    }
  }, [options?.measurement, dispatch])

  let data = liveData?.[options?.measurement] ?? {}

  const parseOptions = value => ({
    fixedFactor: options.fixedFactor,
    scaleFactor: options.scaleFactor,
    value: valueModifierCallback ? valueModifierCallback(value) : value
  })

  const conversion = handleMeasurementConversion({
    data,
    measurement: options?.measurement,
    unit,
    queryType: config.query,
    settings: currentUser?.settings,
    userId: currentUser?.userName,
    organizationId: rootZone?.organizationId
  })

  if (conversion) {
    data = conversion.data
    unit = conversion.unit
  }

  if (config.chart === 'value') {
    return (
      <Value
        thresholdStatus={thresholdStatus}
        data={{
          value: parseValue(parseOptions(data.value)),
          min: parseValue(parseOptions(data.min)),
          max: parseValue(parseOptions(data.max)),
          unit,
          status: data?.status,
          subtitle: I18n.get('Live')
        }}
        measurementId={options?.measurement}
        prediction={prediction}
      />
    )
  }

  return null
}

import { DateTime } from 'luxon'

const startDate = DateTime.now().minus({ months: 1 }).toJSDate()

const locationInitialState = {
  organizations: [],
  sites: [],
  zonePath: ''
}

export const dataItemInitialState = {
  organizations: [],
  produces: [],
  varieties: [],
  statuses: [],
  dateRange: [startDate, new Date()],
  page: 1,
  locations: [locationInitialState],
  warnings: []
}

const getWarnings = locations => {
  const allZonePaths = locations?.map(({ zonePath }) => zonePath)
  const zonePathsErrs = locations?.map(({ zonePath }) => {
    if (allZonePaths.filter(path => path === zonePath).length > 1) {
      return { type: 'repeated_zone' }
    }
    return { type: null }
  })
  return zonePathsErrs
}

export const dataReducer = (state, action) => {
  if (action.state) {
    return {
      ...state,
      ...action.state
    }
  } else if (action.page) {
    return {
      ...state,
      page: action.page
    }
  } else if (action.produces) {
    return {
      ...state,
      produces: action.produces
    }
  } else if (action.statuses) {
    return {
      ...state,
      statuses: action.statuses
    }
  } else if (action.varieties) {
    return {
      ...state,
      varieties: action.varieties
    }
  } else if (action.dateRange) {
    return {
      ...state,
      dateRange: action.dateRange
    }
  } else if (action.location) {
    const locations = [...state.locations]
    const { index, ...rest } = action.location
    const newLocation = { ...locations[index], ...rest }
    const organizations = [...state.organizations]

    if (Object.keys(rest).includes('organizations')) {
      const org = rest.organizations[0]
      if (!organizations.includes(org)) organizations.push(org)
    }

    locations[index] = newLocation
    const warnings = getWarnings(locations)

    return {
      ...state,
      organizations,
      locations,
      warnings
    }
  } else if (action.removeLocation) {
    const newLocations = [...state.locations]
    const { index } = action.removeLocation
    newLocations.splice(index, 1)
    const warnings = getWarnings(newLocations)
    return {
      ...state,
      locations: newLocations,
      warnings
    }
  } else if (action.newLocation) {
    const newLocations = [...state.locations]

    const { organizations, sites } = newLocations[0]
    newLocations.push({
      ...locationInitialState,
      organizations,
      sites,
      zonePath: `/${sites[0]}`
    })
    const warnings = getWarnings(newLocations)
    return {
      ...state,
      locations: newLocations,
      warnings
    }
  } else {
    return { ...state }
  }
}

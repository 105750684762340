import { Box } from '../'
import './index.scss'

const compressClass = name =>
  'fx_' +
  name
    .split('-')
    .map(string => string.charAt(0))
    .join('')
const negateNull = string => string !== null

const parseAxisGap = gapArray =>
  gapArray.length < 2
    ? [`fx_ag-${gapArray[0]}`]
    : [`fx_mag-${gapArray[0]}`, `fx_cag-${gapArray[1]}`]

/**
 * Flex Primitive
 * @param {Object} props
 * @param { 'flex' | 'inline-flex' } [props.display='flex']
 * @param { 'row' | 'row-reverse' | 'column' | 'column-reverse' } [props.direction]
 * @param { 'wrap' | 'nowrap' | 'wrap-reverse' } [props.wrap='wrap']
 * @param { 'baseline' | 'center' | 'flex-start' | 'flex-end' | 'stretch' | 'space-around'| 'space-between' | 'space-evenly' } [props.alignMainAxis]
 * @param { 'baseline' | 'center' | 'flex-start' | 'flex-end' | 'stretch' } [props.alignCrossAxis]
 * @param { [0 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 | 1000] } [props.axisGap]
 * @param {import('react').CSSProperties} [props.css]
 */

const Flex = ({
  children = null,
  display = 'flex',
  direction = null,
  wrap = 'wrap',
  alignMainAxis = null,
  alignCrossAxis = null,
  axisGap = null,
  className = '',
  ...rest
}) => {
  const compoundClass = [
    display,
    direction,
    wrap,
    alignMainAxis ? 'm-' + alignMainAxis : null,
    alignCrossAxis ? 'c-' + alignCrossAxis : null
  ]
    .filter(negateNull)
    .map(compressClass)

  return (
    <Box
      className={[
        ...compoundClass,
        axisGap && parseAxisGap([axisGap].flat()),
        className
      ]}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default Flex

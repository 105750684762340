import { I18n } from 'aws-amplify'
import { object, string, number } from 'yup'

export const FIELDS = {
  id: 'id',
  organizationId: 'organizationId',
  fertilizerInventoryId: 'fertilizerInventoryId',
  newTotalWeight: 'newTotalWeight',
  reason: 'reason'
}

export const SCHEMA = () => {
  return object().shape({
    organizationId: string().required(I18n.get('An organization is required.')),
    fertilizerInventoryId: string().required(
      I18n.get('Fertilizer inventory is required.')
    ),
    newTotalWeight: number()
      .typeError(I18n.get('New total weight is required.'))
      .min(0, I18n.get('New total weight cannot be less than zero.'))
      .required(I18n.get('New total weight is required.')),
    reason: string().required(I18n.get('A reason is required.'))
  })
}

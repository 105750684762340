import { useState } from 'react'
import { Button, Text } from '../../../primitives'
import Strings from '../Strings'

const Number = ({ value = 1, disabled = null, active = null }) => {
  const strings = Strings()
  const [ariaLabel] = useState(`${strings.goto} ${strings.page} ${value}`)

  return (
    <Button
      className={'Number'}
      disabled={disabled}
      aria-label={ariaLabel}
      title={ariaLabel}
      value={ariaLabel}
      tabIndex={0}
      data-goto={value}
      aria-current={active ? true : null}
      variant={active ? 'primary' : null}
    >
      <Text fontFamily='mono'>{value}</Text>
    </Button>
  )
}

export default Number

import { nanoid } from 'nanoid'

import TimeUtils from '@/Util/TimeUtils'

import { getDateRangeFilteredIntervals } from './Utils/FiltersUtils'
import { INTERVALS, COMPARISON_TYPES } from './Utils/config'

export const DEFAULT_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone

export const DATABOARD_ACTIONS = {
  // Generic
  RESET_FILTERS: 'RESET_FILTERS',
  SET_STATE_FROM_CACHE: 'SET_STATE_FROM_CACHE',
  // Chart Specific
  SET_DATASET: 'SET_DATASET',
  SET_LOCAL_STORAGE_ID: 'SET_LOCAL_STORAGE_ID',
  // Sidebar Specific
  SET_MEASUREMENTS: 'SET_MEASUREMENTS',
  UPDATE_COMPARISON_TYPE: 'UPDATE_COMPARISON_TYPE',
  UPDATE_SIDEBAR_FILTER_VALUES: 'UPDATE_SIDEBAR_FILTER_VALUES',
  SET_AVAILABLE_MEASUREMENTS: 'SET_AVAILABLE_MEASUREMENTS',
  // Date filters Specific
  UPDATE_DATE_FILTERS_TIMEZONE: 'UPDATE_DATE_FILTERS_TIMEZONE',
  UPDATE_DATE_FILTERS: 'UPDATE_DATE_FILTERS',
  UPDATE_DATE_TIME_INTERVAL: 'UPDATE_DATE_TIME_INTERVAL',
  UPDATE_TIME_INTERVALS_FILTERS: 'UPDATE_TIME_INTERVALS_FILTERS',
  ADD_DATE_FILTER: 'ADD_DATE_FILTER',
  REMOVE_DATE_FILTER: 'REMOVE_DATE_FILTER',
  UPDATE_DATE_FILTER_VALUE: 'UPDATE_DATE_FILTER_VALUE',
  // Zone filters Specific
  SET_ZONE_FILTERS: 'SET_ZONE_FILTERS',
  ADD_EMPTY_ZONE_FILTER: 'ADD_EMPTY_ZONE_FILTER',
  DUPLICATE_LAST_ZONE_FILTER: 'DUPLICATE_LAST_ZONE_FILTER',
  REMOVE_ZONE_FILTER: 'REMOVE_ZONE_FILTER',
  UPDATE_ZONE_FILTER_VALUE: 'UPDATE_ZONE_FILTER_VALUE',
  UPDATE_ACTIVE_COLUMNS: 'UPDATE_ACTIVE_COLUMNS',
  DELETE_COLUMN: 'DELETE_COLUMN'
}

export const DEFAULT_NEW_DATE_FILTER = (timezone = DEFAULT_TIMEZONE) => ({
  dateRange: 'last24hours',
  fromDate: TimeUtils.initStartDateT(timezone),
  toDate: TimeUtils.initEndDateT(timezone),
  timeInterval: 'hour',
  timeZone: timezone
})

export const initialState = {
  availableMeasurements: [],
  measurements: {
    // these relate directly to the selected comparison type
    [COMPARISON_TYPES.DATE_RANGES]: [],
    [COMPARISON_TYPES.LOCATION_RANGES]: []
  },
  zoneFilters: [],
  sidebarFilter: {
    // when comparison type is date ranges, the sidebar filter is going to handle location ranges and vice versa
    [COMPARISON_TYPES.DATE_RANGES]: DEFAULT_NEW_DATE_FILTER(),
    [COMPARISON_TYPES.LOCATION_RANGES]: {
      organizationId: null,
      siteId: null,
      facilityId: null,
      roomId: null,
      zoneId: null,
      subzoneId: null,
      sensorId: null
    }
  },
  comparisonType: COMPARISON_TYPES.LOCATION_RANGES,
  dateFilters: [DEFAULT_NEW_DATE_FILTER()],
  timeIntervalFilters: INTERVALS,
  localStorageId: null,
  dataset: null,
  datasetId: null,
  activeColumns: {}
}

const handleZoneFilterValues = (zoneFilter, key, value) => {
  const zoneFilterCopy = { ...zoneFilter }

  zoneFilterCopy[key] = value

  if (key !== 'sensorId') {
    const locations = [
      'organizationId',
      'siteId',
      'facilityId',
      'roomId',
      'zoneId',
      'subzoneId',
      'sensorId'
    ]
    // find the index of the current location and clear all locations after it
    const index = locations.findIndex(location => location === key)
    for (let i = index + 1; i < locations.length; i++) {
      zoneFilterCopy[locations[i]] = null
    }
  }

  return zoneFilterCopy
}

const genDatasetId = () => nanoid(6)

export const reducer = (state, action) => {
  const handlers = {
    [DATABOARD_ACTIONS.SET_AVAILABLE_MEASUREMENTS]: (state, action) => ({
      ...state,
      availableMeasurements: action.availableMeasurements
    }),
    [DATABOARD_ACTIONS.SET_LOCAL_STORAGE_ID]: (state, action) => ({
      ...state,
      localStorageId: action.localStorageId
    }),
    [DATABOARD_ACTIONS.UPDATE_SIDEBAR_FILTER_VALUES]: (state, action) => {
      let sidebarZoneFilter = {
        ...state.sidebarFilter[COMPARISON_TYPES.LOCATION_RANGES]
      }
      let sidebarDateFilter = {
        ...state.sidebarFilter[COMPARISON_TYPES.DATE_RANGES]
      }

      for (const [key, value] of Object.entries(action.params)) {
        //sidebar filter is always going to handle the opposite comparison type
        //if the comparison type is date ranges, the sidebar filter is going to handle location ranges and vice versa
        if (state.comparisonType === COMPARISON_TYPES.DATE_RANGES) {
          sidebarZoneFilter = handleZoneFilterValues(
            sidebarZoneFilter,
            key,
            value
          )
        } else {
          sidebarDateFilter[key] = value
        }
      }

      if (state.comparisonType === COMPARISON_TYPES.LOCATION_RANGES) {
        const newDateFiltersIntervals = getDateRangeFilteredIntervals([
          sidebarDateFilter
        ])

        if (!newDateFiltersIntervals.includes(sidebarDateFilter.timeInterval)) {
          sidebarDateFilter.timeInterval = newDateFiltersIntervals[0]
        }
      }

      return {
        ...state,
        sidebarFilter: {
          [COMPARISON_TYPES.DATE_RANGES]: sidebarDateFilter,
          [COMPARISON_TYPES.LOCATION_RANGES]: sidebarZoneFilter
        }
      }
    },
    [DATABOARD_ACTIONS.SET_STATE_FROM_CACHE]: (state, action) => {
      const {
        zoneFilters,
        dateFilters,
        sidebarFilter,
        comparisonType,
        measurements
      } = action.config

      return {
        ...state,
        zoneFilters,
        dateFilters,
        sidebarFilter,
        comparisonType,
        measurements
      }
    },
    [DATABOARD_ACTIONS.SET_DATASET]: (state, action) => ({
      ...state,
      dataset: action.dataset,
      datasetId: genDatasetId()
    }),
    [DATABOARD_ACTIONS.SET_MEASUREMENTS]: (state, action) => {
      let measurements = { ...state.measurements }

      const selectedMeasurments = [...action.measurements]

      const newSelectedMeasurements = selectedMeasurments.filter(measurement =>
        state.availableMeasurements.find(({ id }) => id === measurement)
      )

      measurements[state.comparisonType] = newSelectedMeasurements

      return { ...state, measurements }
    },
    [DATABOARD_ACTIONS.UPDATE_VALUE]: (state, action) => {
      const { type, value } = action.params

      return { ...state, [type]: value }
    },
    [DATABOARD_ACTIONS.UPDATE_COMPARISON_TYPE]: (state, action) => {
      const { comparisonType } = action.params
      const sidebarFilter = { ...state.sidebarFilter }

      if (comparisonType === COMPARISON_TYPES.DATE_RANGES) {
        if (!sidebarFilter[COMPARISON_TYPES.LOCATION_RANGES].organizationId) {
          sidebarFilter[COMPARISON_TYPES.LOCATION_RANGES] = {
            ...state.zoneFilters[0]
          }
        }
      }

      return {
        ...state,
        comparisonType,
        dataset: null,
        datasetId: genDatasetId(),
        sidebarFilter
      }
    },
    [DATABOARD_ACTIONS.RESET_FILTERS]: (state, action) => {
      const newDateFilter = DEFAULT_NEW_DATE_FILTER(action.timeZone)
      let dateFilters = [{ ...newDateFilter }]
      let zoneFilters = []

      // this will only reset one of the sidebar filters depending on the comparison type
      let sidebarFilter = { ...state.sidebarFilter }

      if (state.comparisonType === COMPARISON_TYPES.LOCATION_RANGES) {
        sidebarFilter[COMPARISON_TYPES.DATE_RANGES] = { ...newDateFilter }
        zoneFilters = [{ ...action.zoneFilter }]
      } else {
        sidebarFilter[COMPARISON_TYPES.LOCATION_RANGES] = {
          ...action.zoneFilter
        }
      }

      return {
        ...state,
        dateFilters,
        zoneFilters,
        sidebarFilter,
        dataset: null,
        measurements: {
          ...state.measurements,
          [state.comparisonType]: []
        },
        // localStorageId: null,
        intervalFilters: INTERVALS
      }
    },
    [DATABOARD_ACTIONS.UPDATE_DATE_FILTERS]: (state, action) => {
      const { data, index: filterIndex } = action.params

      let tempState = { ...state }
      let dateFilters = tempState.dateFilters

      dateFilters[filterIndex] = {
        ...dateFilters[filterIndex],
        ...data
      }

      const newDateFiltersIntervals = getDateRangeFilteredIntervals(dateFilters)

      // if the new intervals don't include the current time interval
      // change each date filter to the first available interval
      for (let dateFilter of dateFilters) {
        if (!newDateFiltersIntervals.includes(dateFilter.timeInterval)) {
          dateFilter.timeInterval = newDateFiltersIntervals[0]
        }
      }

      return {
        ...state,
        dateFilters: dateFilters,
        timeIntervalFilters: newDateFiltersIntervals
      }
    },
    [DATABOARD_ACTIONS.UPDATE_DATE_FILTERS_TIMEZONE]: (state, action) => {
      const { timeZone } = action
      return {
        ...state,
        dateFilters: state.dateFilters.map(dateFilter => ({
          ...dateFilter,
          timeZone
        }))
      }
    },
    [DATABOARD_ACTIONS.ADD_EMPTY_ZONE_FILTER]: (state, action) => {
      const copyFilters = [...state.zoneFilters]

      copyFilters.push({
        organizationId:
          state.zoneFilters.length >= 1
            ? state.zoneFilters[0].organizationId
            : action.organizationId,
        zoneId: null,
        siteId: null,
        sensorId: null
      })
      return { ...state, zoneFilters: copyFilters }
    },
    [DATABOARD_ACTIONS.DUPLICATE_LAST_ZONE_FILTER]: (state, action) => {
      const { activeColumns, zoneFilters } = { ...state }
      const newFilterData = { ...zoneFilters[zoneFilters?.length - 1] }
      const columns = activeColumns[zoneFilters?.length - 1]
      const populatedFields = []

      columns.forEach(column => {
        if (newFilterData[column]) populatedFields.push(column)
      })

      const fieldToClear = populatedFields.pop()
      newFilterData[fieldToClear] = null

      return { ...state, zoneFilters: [ ...zoneFilters, newFilterData ]}
    },
    [DATABOARD_ACTIONS.REMOVE_ZONE_FILTER]: (state, action) => {
      const zoneFilters = [...state.zoneFilters]
      zoneFilters.splice(action.params.index, 1)
      return { ...state, zoneFilters }
    },
    [DATABOARD_ACTIONS.UPDATE_ZONE_FILTER_VALUE]: (state, action) => {
      const { key, value, index } = action.params
      const zoneFilters = [...state.zoneFilters]

      zoneFilters[index] = handleZoneFilterValues(
        zoneFilters[index],
        key,
        value
      )

      return { ...state, zoneFilters }
    },

    [DATABOARD_ACTIONS.UPDATE_DATE_TIME_INTERVAL]: (state, action) => {
      const tempDateFilters = state.dateFilters

      const updatedDateFilters = tempDateFilters.map(dateFilter => {
        return { ...dateFilter, timeInterval: action.params.timeInterval }
      })

      return {
        ...state,
        dateFilters: updatedDateFilters
      }
    },

    [DATABOARD_ACTIONS.UPDATE_TIME_INTERVALS_FILTERS]: (state, action) => {
      return {
        ...state,
        timeIntervalFilters: action.params.timeIntervalFilters
      }
    },
    [DATABOARD_ACTIONS.SET_ZONE_FILTERS]: (state, action) => ({
      ...state,
      zoneFilters: action.zoneFilters,
      sidebarFilter: {
        ...state.sidebarFilter,
        [COMPARISON_TYPES.DATE_RANGES]: DEFAULT_NEW_DATE_FILTER(action.timeZone)
      }
    }),
    [DATABOARD_ACTIONS.ADD_DATE_FILTER]: (state, action) => {
      const newDateFilters = [...state.dateFilters]

      newDateFilters.push({
        ...DEFAULT_NEW_DATE_FILTER(),
        timeInterval: newDateFilters?.[0]?.timeInterval ?? 'hour'
      })

      const filteredIntervals = getDateRangeFilteredIntervals(newDateFilters)

      // if the new intervals don't include the current time interval
      // change each date filter to the first available interval
      for (let dateFilter of newDateFilters) {
        if (!filteredIntervals.includes(dateFilter.timeInterval)) {
          dateFilter.timeInterval = filteredIntervals[0]
        }
      }
      return {
        ...state,
        dateFilters: newDateFilters,
        timeIntervalFilters: filteredIntervals
      }
    },
    [DATABOARD_ACTIONS.REMOVE_DATE_FILTER]: (state, action) => {
      const dateFilters = [...state.dateFilters]
      dateFilters.splice(action.params.index, 1)
      const filteredIntervals = getDateRangeFilteredIntervals(dateFilters)

      // if the new intervals don't include the current time interval
      // change each date filter to the first available interval
      for (let dateFilter of dateFilters) {
        if (!filteredIntervals.includes(dateFilter.timeInterval)) {
          dateFilter.timeInterval = filteredIntervals[0]
        }
      }
      return {
        ...state,
        dateFilters,
        timeIntervalFilters: filteredIntervals
      }
    },
    [DATABOARD_ACTIONS.UPDATE_ACTIVE_COLUMNS]: (state, action) => {
      const { index, activeColumns } = action

      let tempState = { ...state }

      const newActiveColumns = (tempState.activeColumns = {
        ...tempState.activeColumns,
        [index]: activeColumns
      })

      return {
        ...state,
        activeColumns: newActiveColumns
      }
    },
    [DATABOARD_ACTIONS.DELETE_COLUMN]: (state, action) => {
      const tempState = { ...state }
      const { index } = action
      delete tempState.activeColumns[index]

      return {
        ...state,
        activeColumns: tempState.activeColumns
      }
    }
  }

  if (handlers[action.type] === undefined) {
    throw new Error(`Unhandled action type: ${action.type}`)
  }

  const nextState = handlers[action.type](state, action)

  // console.groupCollapsed(
  //   `%c${action.type}`,
  //   'color: #000; font-weight: lighter;'
  // )
  // console.log('%cPrevious State:', 'color: #9E9E9E; font-weight: bold;', state)
  // console.log('%cAction:', 'color: #00A7F7; font-weight: bold;', action)
  // console.log('%cNext State:', 'color: #47B04B; font-weight: bold;', nextState)
  // console.groupEnd()

  return nextState
}

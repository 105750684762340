export const SCREEN_SIZE_BREAKPOINTS = {
  md: 768, // tablet portrait
  lg: 1024, // tablet landscape
  xl: 1280 // desktop
}

export const TILE_SIZE = 70
export const TILE_MARGIN = 16
export const COLUMNS = {
  md: 8,
  lg: 8,
  xl: 8
}
export const CONTAINER_PADDING = [0, 0]
export const COMPACT_TYPE = 'vertical'

export const COLUMN_BREAKPOINTS = {
  md: COLUMNS.md,
  lg: COLUMNS.lg,
  xl: COLUMNS.xl
}

const inRange = (value, min, max) => {
  return value >= min && value <= max
}

export const getBreakpointFromScreenSize = screenSize => {
  const { md, lg, xl } = SCREEN_SIZE_BREAKPOINTS

  if (inRange(screenSize, md, lg)) {
    return 'md'
  }
  if (inRange(screenSize, lg, xl)) {
    return 'lg'
  }
  return 'xl'
}

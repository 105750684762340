import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {
  getFactorySuppliers,
  createFactorySupplier,
  getFactorySupplierUsers,
  createFactorySupplierUser,
  deleteFactorySupplierUser
} from '@/api/management/supplier'

export const fetchFactorySuppliers = createAsyncThunk(
  'fetchFactorySuppliers',
  async params => {
    return await getFactorySuppliers(params)
  }
)

export const sendCreateFactorySupplier = createAsyncThunk(
  'sendCreateFactorySupplier',
  async params => {
    return await createFactorySupplier(params)
  }
)

export const fetchFactorySupplierUsers = createAsyncThunk(
  'fetchFactorySupplierUsers',
  async params => {
    return await getFactorySupplierUsers(params)
  }
)

export const sendCreateFactorySupplierUser = createAsyncThunk(
  'sendCreateFactorySupplierUser',
  async params => {
    return await createFactorySupplierUser(params)
  }
)

export const sendDeleteFactorySupplierUser = createAsyncThunk(
  'sendDeleteFactorySupplierUser',
  async params => {
    return await deleteFactorySupplierUser(params)
  }
)

const managementSupplierSlice = createSlice({
  name: 'managementSupplierReducer',
  initialState: {
    suppliers: [],
    users: [],
    supplier: {},
    user: {},
    userDeleted: {},
    error: null,
    loading: false,
    saving: false
  },
  reducers: {
    clearSupplier: state => {
      state.supplier = {}
      state.error = null
    },
    clearUser: state => {
      state.user = {}
      state.userDeleted = {}
    },
    clearUsers: state => {
      state.users = []
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchFactorySuppliers.pending, (state, action) => {
        state.suppliers = []
        state.error = null
        state.loading = true
      })
      .addCase(fetchFactorySuppliers.rejected, (state, action) => {
        state.error = action?.error
        state.loading = false
      })
      .addCase(fetchFactorySuppliers.fulfilled, (state, action) => {
        state.suppliers = action?.payload.suppliers ?? []
        state.loading = false
      })
      .addCase(sendCreateFactorySupplier.pending, (state, action) => {
        state.supplier = {}
        state.error = null
        state.saving = true
      })
      .addCase(sendCreateFactorySupplier.rejected, (state, action) => {
        state.error = action?.error
        state.saving = false
      })
      .addCase(sendCreateFactorySupplier.fulfilled, (state, action) => {
        state.supplier = action?.payload.supplier ?? {}
        state.suppliers = action?.payload.supplier
          ? [...state.suppliers, action?.payload.supplier]
          : state.suppliers
        state.saving = false
      })
      .addCase(fetchFactorySupplierUsers.pending, (state, action) => {
        state.users = []
        state.error = null
        state.loading = true
      })
      .addCase(fetchFactorySupplierUsers.rejected, (state, action) => {
        state.error = action?.error
        state.loading = false
      })
      .addCase(fetchFactorySupplierUsers.fulfilled, (state, action) => {
        state.users = action?.payload.users ?? []
        state.loading = false
      })
      .addCase(sendCreateFactorySupplierUser.pending, (state, action) => {
        state.user = {}
        state.error = null
        state.saving = true
      })
      .addCase(sendCreateFactorySupplierUser.rejected, (state, action) => {
        state.error = action?.error
        state.saving = false
      })
      .addCase(sendCreateFactorySupplierUser.fulfilled, (state, action) => {
        state.user = action?.payload.user ?? {}
        state.users = action?.payload.user
          ? [...state.users, action?.payload.user]
          : state.users
        state.saving = false
      })
      .addCase(sendDeleteFactorySupplierUser.pending, (state, action) => {
        state.error = null
        state.saving = true
      })
      .addCase(sendDeleteFactorySupplierUser.rejected, (state, action) => {
        state.error = action?.error
        state.saving = false
      })
      .addCase(sendDeleteFactorySupplierUser.fulfilled, (state, action) => {
        state.userDeleted = action.payload.user ?? {}
        state.users = state.users.filter(
          user => user.userId !== action?.payload.user?.userId
        )
        state.saving = false
      })
  }
})

const { actions, reducer } = managementSupplierSlice

export const { clearSupplier, clearUser, clearUsers } = actions

export default reducer

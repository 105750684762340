import React, { useState, useEffect } from 'react'
import { DateTime } from 'luxon'

import { Text, Flex } from '@/primitives'
import Select from '@/elements/Select'

import {
  CANCEL_WIDGET_UPDATE,
  UPDATE_WIDGET
} from '@/components/DashboardPageV2/Desktop/state'
import { useDashboard } from '@/components/DashboardPageV2/Desktop/context'
import withEditableWidget from '@/components/DashboardPageV2/Desktop/Widgets/Shared/HOC/withEditableWidget'
import { WidgetDefinition } from '../../Shared/utils/WidgetRegistry'
import EditLayout from '@/components/DashboardPageV2/Desktop/Widgets/Shared/EditLayout'
import { reactSelectStyles } from '@/components/DashboardPageV2/Desktop/utils'
import { useEditResize } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/utils/useEditResize'

import { String } from './String'
import './index.scss'

const MIN_EDITING_WIDTH = 3
const MIN_EDITING_HEIGHT = 4

function TimezoneClockEdit(props) {
  const { setWidgetMode } = props
  const { state, dispatchState } = useDashboard()
  const [timeMode, setTimeMode] = useState(props.config.timeMode)

  const { hasBeenEditResized, oldPosition } = useEditResize(
    props.id,
    MIN_EDITING_WIDTH,
    MIN_EDITING_HEIGHT
  )

  const strings = String()

  const handleSelect = option => {
    setTimeMode(option.value)
  }

  const handleSave = () => {
    const { props: widgetProps } = state.widgets.get(props.id)

    dispatchState({
      type: UPDATE_WIDGET,
      payload: {
        ...widgetProps,
        position: hasBeenEditResized ? oldPosition : widgetProps.position,
        config: { timeMode }
      }
    })
    setWidgetMode('view')
  }

  const handleCancel = () => {
    const { props: widgetProps } = state.widgets.get(props.id)

    // if the widget has been resized, we need to reset the position
    dispatchState({
      type: CANCEL_WIDGET_UPDATE,
      payload: {
        id: props.id,
        position: hasBeenEditResized ? oldPosition : widgetProps.position
      }
    })

    setWidgetMode('view')
  }

  const options = [
    { label: strings['24h'], value: '24h' },
    { label: strings['12h'], value: '12h' }
  ]

  return (
    <EditLayout onCancel={handleCancel} onSave={handleSave}>
      <Flex
        alignCrossAxis='flex-start'
        direction='column'
        axisGap={200}
        wrap='nowrap'
      >
        <Text variant='page' tone={800}>
          {strings.selectTimeMode}
        </Text>
        <Select
          className='TimezoneClock__Select'
          style={{ width: '100%' }}
          options={options}
          onChange={handleSelect}
          value={timeMode}
          controlStyles={reactSelectStyles}
          menuListStyles={reactSelectStyles}
        />
      </Flex>
    </EditLayout>
  )
}

function TimezoneClockView(props) {
  const { id, config } = props

  const { state } = useDashboard()
  const timezone = state.hierarchy.timeZone

  const [time, setTime] = useState(DateTime.now().setZone(timezone))

  useEffect(() => {
    const now = DateTime.now().setZone(timezone)
    const nextMinute = now.plus({ minutes: 1 }).startOf('minute')
    const intervalDiff = nextMinute.diff(now, 'milliseconds')

    const timeoutId = setTimeout(() => {
      setTime(DateTime.now().setZone(timezone))
    }, intervalDiff)

    return () => clearTimeout(timeoutId)
  }, [timezone, time, config])

  const timeString =
    config.timeMode === '24h' ? time.toFormat('HH:mm') : time.toFormat('h:mm a')

  return (
    <Flex
      key={id}
      className='TimezoneClock__View'
      direction='column'
      alignMainAxis='center'
      alignCrossAxis='center'
    >
      <Text fontFamily={'mono'} size={600} fontWeight={700}>
        {timeString}
      </Text>
      <Text
        fontFamily={'mono'}
        size={200}
        textTransform={'uppercase'}
        variant='page'
        tone={900}
      >
        {timezone}
      </Text>
    </Flex>
  )
}

const TimezoneClockWidget = withEditableWidget(
  TimezoneClockView,
  TimezoneClockEdit
)

export const TimezoneClockDefinition = () => {
  const strings = String()
  return new WidgetDefinition({
    widgetId: 'timezoneClock',
    title: strings.title,
    icon: 'schedule',
    component: TimezoneClockWidget,
    defaultPosition: {
      md: [0, 0, 8, 3, 8, 3],
      lg: [0, 0, 4, 3, 4, 3],
      xl: [0, 0, 2, 3, 2, 3]
    },
    config: { timeMode: '24h' }
  })
}

export const RESOURCE_TYPE_DATA_API = 'data_api'
export const RESOURCE_TYPE_DATA_API_MANAGEMENT = 'management'

export const RESOURCE_TYPE_DEVICE = 'sensor'
export const RESOURCE_TYPE_DEVICE_MANAGEMENT = 'management'
export const RESOURCE_TYPE_DEVICE_CALIBRATION = 'calibration'
export const RESOURCE_TYPE_DEVICE_THRESHOLDS = 'thresholds'
export const RESOURCE_TYPE_DEVICE_CAMERAS = 'cameras'
export const RESOURCE_TYPE_DEVICE_CONTROL_RANGES = 'crm'

export const RESOURCE_TYPE_ORGANIZATION = 'organization'

export const RESOURCE_TYPE_OPERATIONS = 'operations'
export const RESOURCE_TYPE_OPERATIONS_MANAGEMENT = 'management'
export const RESOURCE_TYPE_OPERATIONS_PLANS = 'plans'
export const RESOURCE_TYPE_OPERATIONS_HARVEST = 'harvest'
export const RESOURCE_TYPE_OPERATIONS_WASTE = 'waste'
export const RESOURCE_TYPE_OPERATIONS_CUSTOMERS = 'customers'
export const RESOURCE_TYPE_OPERATIONS_SALES = 'sales'
export const RESOURCE_TYPE_OPERATIONS_RETURNS = 'returns'
export const RESOURCE_TYPE_OPERATIONS_INVENTORY = 'inventory'
export const RESOURCE_TYPE_OPERATIONS_FERTILIZER = 'fertilizer'

export const RESOURCE_TYPE_SYSTEMS = 'systems'
export const RESOURCE_TYPE_SYSTEMS_ENVIRONMENT = 'environment'
export const RESOURCE_TYPE_SYSTEMS_FERTIGATION = 'fertigation'

export const RESOURCE_TYPE_USER = 'user'
export const RESOURCE_TYPE_USER_MANAGEMENT = 'management'
export const RESOURCE_TYPE_USER_ROLES = 'roles'
export const RESOURCE_TYPE_USER_MANAGERS = 'managers'
export const RESOURCE_TYPE_USER_LOGS = 'logs'

export const RESOURCE_TYPE_ZONE = 'zone'

function checkPermissionById(resourceId, comparingResourceId) {
  return resourceId === comparingResourceId || resourceId === null
}

function checkPermissionByResource(resourceType, comparingResourceType) {
  return resourceType === comparingResourceType
}

function checkPermissionByOperation(operationType, comparingOperationType) {
  return operationType === comparingOperationType
}

function checkUserPermisssions(
  coretexUser,
  resourceType,
  operationType,
  resourceId = null
) {
  if (
    !coretexUser ||
    !coretexUser?.permissions ||
    coretexUser?.permissions?.length === 0
  ) {
    return false
  }

  return coretexUser?.permissions?.some(
    permission =>
      checkPermissionByResource(permission?.resourceType, resourceType) &&
      checkPermissionByOperation(permission?.operationType, operationType) &&
      checkPermissionById(permission?.resourceId, resourceId)
  )
}

function checkUserZonesPermissions(coretexUser, resourceType, operationType) {
  return coretexUser?.permissions?.some(
    permission =>
      checkPermissionByResource(permission?.resourceType, resourceType) &&
      checkPermissionByOperation(permission?.operationType, operationType)
  )
}

export function checkUserZonesDashboardPermissions(
  coretexUser,
  resourceType,
  resourceId
) {
  const hasAllZonesPermissions = coretexUser?.permissions?.some(
    permission =>
      checkPermissionByResource(permission?.resourceType, resourceType) &&
      checkPermissionByOperation(permission?.operationType, 'read') &&
      checkPermissionById(permission?.resourceId, null)
  )

  const hasZonePermission = coretexUser?.permissions?.some(
    permission =>
      checkPermissionByResource(permission?.resourceType, resourceType) &&
      checkPermissionByOperation(permission?.operationType, 'read') &&
      checkPermissionById(permission?.resourceId, resourceId)
  )

  return hasAllZonesPermissions || hasZonePermission
}

export function hasReadPermissions(
  coretexUser,
  resourceType,
  resourceId = null
) {
  if (resourceType === RESOURCE_TYPE_ZONE) {
    return checkUserZonesPermissions(coretexUser, resourceType, 'read')
  }

  return checkUserPermisssions(coretexUser, resourceType, 'read', resourceId)
}

export function hasEditPermissions(
  coretexUser,
  resourceType,
  resourceId = null
) {
  if (resourceType === RESOURCE_TYPE_ZONE) {
    return checkUserZonesPermissions(coretexUser, resourceType, 'write')
  }

  return checkUserPermisssions(coretexUser, resourceType, 'write', resourceId)
}

function hasOpsReadPermission(coretexUser, mainPermission, specificPermission) {
  return hasReadPermissions(coretexUser, mainPermission, specificPermission)
}

export function hasOpsReadPermissions(coretexUser) {
  const specificPermissions = [
    RESOURCE_TYPE_OPERATIONS_HARVEST,
    RESOURCE_TYPE_OPERATIONS_INVENTORY,
    RESOURCE_TYPE_OPERATIONS_SALES,
    RESOURCE_TYPE_OPERATIONS_WASTE,
    RESOURCE_TYPE_OPERATIONS_RETURNS,
    RESOURCE_TYPE_OPERATIONS_CUSTOMERS,
    RESOURCE_TYPE_OPERATIONS_MANAGEMENT,
    RESOURCE_TYPE_OPERATIONS_PLANS,
    RESOURCE_TYPE_OPERATIONS_FERTILIZER
  ]

  return specificPermissions.some(permission => {
    return hasOpsReadPermission(
      coretexUser,
      RESOURCE_TYPE_OPERATIONS,
      permission
    )
  })
}

export function getPermissionsList(currentUser, resourceType, operationType) {
  return currentUser.permissions.filter(permission => {
    return (
      permission.resourceType === resourceType &&
      permission.operationType === operationType
    )
  })
}

export function findPermissionById(permissionsList, resourceId = null) {
  return permissionsList.some(
    permission => permission.resourceId === resourceId
  )
}

export function findPermissionByPath(permissionsList, parentPath) {
  return permissionsList.some(permission =>
    parentPath.includes(permission.resourceId)
  )
}

export const getIsGodMode = coretexUser => {
  if (!coretexUser) {
    return false
  }

  if (Object.keys(coretexUser).length === 0) {
    return false
  }

  return coretexUser?.role === 'ModoDeus'
}

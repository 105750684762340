import { I18n } from 'aws-amplify'

const Strings = () => ({
  foundingPartners: I18n.get('Founding Partners'),
  errorOccurred: I18n.get('An error occurred'),
  errorDateRetrieval: I18n.get('Retrieving data failed with error:'),
  errorHas: I18n.get('has'),
  errorAlert: I18n.get('alert'),
  errorAlerts: I18n.get('alerts'),
  loading: I18n.get('Loading'),
  thresholdAbove: I18n.get('above'),
  thresholdBelow: I18n.get('below'),
  threshold: I18n.get('threshold'),
  thresholdsUpper: I18n.get('Thresholds'),
  thresholdMin: I18n.get('Min:'),
  thresholdMax: I18n.get('Max:'),
  noThresholds: I18n.get('No Thresholds set'),
  unitLight: I18n.get('Light'),
  unitTemperature: I18n.get('Temperature'),
  unitWaterTemperature: I18n.get('Water Temperature'),
  unitHumidity: I18n.get('Humidity'),
  unitPh: I18n.get('PH'),
  unitConductivity: I18n.get('Conductivity'),
  unitDissolvedOxygen: I18n.get('Dissolved Oxygen'),
  unitPressure: I18n.get('Pressure'),
  unitPar: I18n.get('PAR'),
  unitCarbonDioxide: I18n.get('Carbon Dioxide'),
  unitUvIndex: I18n.get('UV A'),
  unitPvCellOutputEnvirosense: I18n.get('Envirosense PV Power'),
  unitPvCurrent: I18n.get('PV Current'),
  unitPvVoltage: I18n.get('PV Voltage'),
  unitPvLoad_voltage: I18n.get('PV Load Voltage'),
  unitPvLoadCurrent: I18n.get('PV Load Current'),
  unitPvMaxPowerPointVoltage: I18n.get('PV VMP'),
  unitPvMaxOutputPower: I18n.get('PV Max Output Power'),
  unitPFanOuputPower: I18n.get('Photo Fan Output Power'),
  unitPFanMotorSpeed: I18n.get('Photo Fan Motor Speed'),
  pfanVfdOutputFrequency:I18n.get('Photo Fan VFD Output Freq'),
  sensorsText: I18n.get('Sensors'),
  select: I18n.get('Select'),
  sensor: I18n.get('sensor'),
  swNewVersion: I18n.get('A new version is available.'),
  swClickHere: I18n.get('Click here to update.'),

  // Release notes
  releaseNotes: I18n.get('Release notes'),
  releaseNotesSubheading: I18n.get('See the latest updates on SecondSky Data')
})

export default Strings

import { I18n } from 'aws-amplify'

const Strings = () => ({
  manageUnitPrices: I18n.get('Manage Unit Prices'),
  createUnitPrice: I18n.get('Create Unit Price'),
  createFormHeader: I18n.get('Create New Unit Price'),
  updateFormHeader: I18n.get('Update Existing Unit Price'),
  selectDefault: I18n.get('Please Select'),
  customer: I18n.get('Customer'),
  product: I18n.get('Product'),
  unit: I18n.get('Unit'),
  price: I18n.get('Price'),
  noUnitPrices: I18n.get('No Unit Prices'),
  grams: I18n.get('g'),
  saveUnitPrice: I18n.get('Save Unit Price'),
  unitPrice: I18n.get('Unit Price'),
  articleCode: I18n.get('Article Code'),
  description: I18n.get('Description'),
  alternateDescription: I18n.get('Alternate Description'),
  percent: I18n.get('%'),
  discount: I18n.get('Discount'),
  discountLowerCase: I18n.get('discount'),
  sellingPrice: I18n.get('Selling Price'),
  currency: I18n.get('Currency'),
  article: I18n.get('Article')
})

export default Strings

import { I18n } from 'aws-amplify'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {
  createReleaseNote,
  getReleaseNotes,
  createReleaseNoteSection,
  updateReleaseNoteSection,
  getReleaseNoteSections,
  updateReleaseNote,
  getReleaseNotesByLang,
  redoReleaseNoteTranslations
} from '@/api/fyi/releaseNotes'
import { showBanner } from '../util'

const feedbackStrings = {
  createReleaseNote: {
    success: I18n.get('Release note created'),
    error: I18n.get('Could not create release note')
  },
  updateReleaseNote: {
    success: I18n.get('Release note updated'),
    error: I18n.get('Could not update release note')
  },
  createReleaseNoteSection: {
    success: I18n.get('Release note section created'),
    error: I18n.get('Could not create release note section')
  },
  updateReleaseNoteSection: {
    success: I18n.get('Release note section updated'),
    error: I18n.get('Could not update release note section')
  },
  fetchInit: {
    error: I18n.get('Could not fetch all release notes data')
  },
  getReleaseNotesByLang: {
    error: I18n.get('Could not fetch release notes')
  },
  redoReleaseNoteTranslations: {
    success: I18n.get('Translations are being generated'),
    error: I18n.get('Translations could not be generated')
  }
}

function dispatchFeedback(dispatch, error, context) {
  const strings = feedbackStrings[context]
  const type = error ? 'error' : 'success'
  const message = error ? strings.error : strings.success

  dispatch(
    showBanner({
      type,
      show: true,
      message
    })
  )
}

export const sendCreateReleaseNote = createAsyncThunk(
  'sendCreateReleaseNote',
  async (params, { dispatch }) => {
    const response = await createReleaseNote(params)
    dispatchFeedback(dispatch, response.error, 'createReleaseNote')
    return response
  }
)

export const sendUpdateReleaseNote = createAsyncThunk(
  'sendUpdateReleaseNote',
  async (params, { dispatch }) => {
    const response = await updateReleaseNote(params)
    dispatchFeedback(dispatch, response.error, 'updateReleaseNote')
    return response
  }
)

export const sendCreateReleaseNotesSection = createAsyncThunk(
  'sendCreateReleaseNotesSection',
  async (params, { dispatch }) => {
    const response = await createReleaseNoteSection(params)
    dispatchFeedback(dispatch, response.error, 'createReleaseNoteSection')
    return response
  }
)

export const sendUpdateReleaseNotesSection = createAsyncThunk(
  'sendUpdateReleaseNotesSection',
  async (params, { dispatch }) => {
    const response = await updateReleaseNoteSection(params)
    dispatchFeedback(dispatch, response.error, 'updateReleaseNoteSection')
    return response
  }
)

export const sendFetchReleaseNotesInit = createAsyncThunk(
  'sendFetchReleaseNotesInit',
  async (params, { dispatch }) => {
    const { sections, error: sectionsErr } = await getReleaseNoteSections()
    const { releaseNotes, error: releaseNotesErr } = await getReleaseNotes()
    const error = sectionsErr ?? releaseNotesErr
    if (error) dispatchFeedback(dispatch, error, 'fetchInit')
    return { sections, releaseNotes, error }
  }
)

export const sendFetchReleaseNotesByLang = createAsyncThunk(
  'sendFetchReleaseNotesByLang',
  async (params, { dispatch }) => {
    const { releaseNotesByLang, error } = await getReleaseNotesByLang(params)
    if (error) dispatchFeedback(dispatch, error, 'getReleaseNotesByLang')
    return { releaseNotesByLang, error }
  }
)

export const sendRedoReleaseNoteTranslation = createAsyncThunk(
  'sendRedoReleaseNoteTranslation',
  async (params, { dispatch }) => {
    const response = await redoReleaseNoteTranslations(params)
    dispatchFeedback(dispatch, response.error, 'redoReleaseNoteTranslations')
    return response
  }
)

const fyiReleaseNotesSlice = createSlice({
  name: 'fyiReleaseNotesReducer',
  initialState: {
    releaseNotesByLang: [],
    releaseNotesByLangFetched: '',
    releaseNotes: [],
    releaseNoteUpserted: false,
    initFetched: false,
    sections: [],
    sectionCreated: null,
    error: null,
    loading: false,
    translationsRequested: ''
  },
  reducers: {
    cleanSectionCreated: state => {
      state.sectionCreated = null
    },
    cleanReleaseNoteUpserted: state => {
      state.releaseNoteUpserted = false
    },
    cleanTranslations: state => {
      state.translationsRequested = ''
    }
  },
  extraReducers: builder => {
    builder
      .addCase(sendCreateReleaseNote.pending, state => {
        state.error = null
        state.loading = true
      })
      .addCase(sendCreateReleaseNote.fulfilled, (state, action) => {
        if (!action?.payload?.error) {
          state.releaseNotes = [
            ...state.releaseNotes,
            action?.payload?.releaseNotes
          ]
          state.releaseNoteUpserted = true
        }
        state.error = action?.payload?.error ?? null
        state.loading = false
      })
      .addCase(sendUpdateReleaseNote.pending, state => {
        state.error = null
        state.loading = true
      })
      .addCase(sendUpdateReleaseNote.fulfilled, (state, action) => {
        if (!action?.payload?.error) {
          const releaseNotes = [...state.releaseNotes]
          const releaseNote = action.payload.releaseNote
          const index = releaseNotes.findIndex(
            ({ releaseNoteId }) => releaseNoteId === releaseNote.releaseNoteId
          )
          const updatedReleaseNote = { ...releaseNotes[index], ...releaseNote }
          releaseNotes.splice(index, 1, updatedReleaseNote)
          state.releaseNotes = releaseNotes
          state.releaseNoteUpserted = true
        }
        state.error = action?.payload?.error ?? null
        state.loading = false
      })
      .addCase(sendCreateReleaseNotesSection.pending, state => {
        state.error = null
        state.loading = true
      })
      .addCase(sendCreateReleaseNotesSection.fulfilled, (state, action) => {
        if (!action?.payload?.error) {
          state.sections = [...state.sections, action?.payload?.section]
          state.sectionCreated = action?.payload?.section
        }
        state.error = action?.payload?.error ?? null
        state.loading = false
      })
      .addCase(sendUpdateReleaseNotesSection.pending, state => {
        state.error = null
        state.loading = true
      })
      .addCase(sendUpdateReleaseNotesSection.fulfilled, (state, action) => {
        if (!action?.payload?.error) {
          const sections = [...state.sections]
          const { section } = action?.payload
          const index = sections.findIndex(
            ({ sectionId }) => sectionId === section?.sectionId
          )
          sections[index] = section
          state.sections = sections
          state.sectionCreated = action?.payload?.section
        }
        state.error = action?.payload?.error ?? null
        state.loading = false
      })
      .addCase(sendFetchReleaseNotesInit.pending, state => {
        state.error = null
        state.loading = true
      })
      .addCase(sendFetchReleaseNotesInit.fulfilled, (state, action) => {
        if (!action?.payload?.error) {
          state.sections = action?.payload?.sections
          state.releaseNotes = action?.payload?.releaseNotes
        }
        state.error = action?.payload?.error ?? null
        state.initFetched = true
        state.loading = false
      })
      .addCase(sendFetchReleaseNotesByLang.pending, state => {
        state.error = null
        state.loading = true
      })
      .addCase(sendFetchReleaseNotesByLang.fulfilled, (state, action) => {
        if (!action?.payload?.error) {
          state.releaseNotesByLang = action?.payload?.releaseNotesByLang
        }
        state.error = action?.payload?.error ?? null
        state.releaseNotesByLangFetched = action.meta.arg.lang
      })
      .addCase(sendRedoReleaseNoteTranslation.pending, state => {
        state.error = null
        state.loading = true
      })
      .addCase(sendRedoReleaseNoteTranslation.fulfilled, (state, action) => {
        if (!action?.payload?.error) {
          state.translationsRequested = action.payload.translationsRequested
        }
        state.error = action?.payload?.error ?? null
        state.loading = false
      })
  }
})

const { actions, reducer } = fyiReleaseNotesSlice
export const {
  cleanSectionCreated,
  cleanReleaseNoteUpserted,
  cleanTranslations
} = actions

export default reducer

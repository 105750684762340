import { useDispatch } from 'react-redux'
import Strings from '../../Strings'
import { Button, Flex, Slot, Text } from '@/primitives'
import { Dialog } from '@/elements'
import { fetchCoreDeviceProvisionInstaller } from '@/actions/management/greengrass'
import { getDeviceLoading } from '@/reducers/selectors'

const ProvisioningConfigModal = ({ selectedZone, showModal, setShowModal }) => {
  const strings = Strings()
  const isLoading = getDeviceLoading()

  const dispatch = useDispatch()

  const {
    close,
    provisioningConfigModalTitle,
    downloadProvisionInstaller,
    instructionsFirst,
    instructionsSecond,
    instructionsThird,
    instructionsFourth,
    instructionsFifth,
    instructionsNote
  } = strings

  const onClickClose = () => {
    setShowModal(false)
  }

  function onClickDownload() {
    dispatch(fetchCoreDeviceProvisionInstaller({ zoneId: selectedZone?.id }))
  }

  return (
    <Dialog
      open={showModal}
      onOpenChange={setShowModal}
      className='Dialog__Provisioning__Config'
    >
      <Slot name='title'>
        <Text size={300} fontWeight={700}>
          {provisioningConfigModalTitle}
        </Text>
      </Slot>

      <Slot name='content'>
        <Flex>
          <pre>
            <p>{instructionsFirst}</p>
            <p>{instructionsSecond}</p>
            <p>{instructionsThird}</p>
            <p>{instructionsFourth}</p>
            <p>{instructionsFifth}</p>
            <p>{instructionsNote}</p>
          </pre>
        </Flex>
      </Slot>

      <Slot name='actions'>
        <Flex axisGap={400} alignMainAxis={'space-between'}>
          <Button onClick={onClickClose}>{close}</Button>
          <Button
            variant='primary'
            onClick={onClickDownload}
            disabled={isLoading}
            loading={isLoading}
          >
            {downloadProvisionInstaller}
          </Button>
        </Flex>
      </Slot>
    </Dialog>
  )
}

export default ProvisioningConfigModal

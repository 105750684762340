import { makeActionCreator } from '@/Util/ReduxUtils'

export const CLEAR_FERTILIZER_INVENTORIES = 'CLEAR_FERTILIZER_INVENTORIES'
export const clearFertilizerInventories = makeActionCreator(
  CLEAR_FERTILIZER_INVENTORIES
)

export const REQUEST_FERTILIZER_INVENTORIES = 'REQUEST_FERTILIZER_INVENTORIES'
export const requestFertilizerInventories = makeActionCreator(
  REQUEST_FERTILIZER_INVENTORIES,
  'params'
)

export const RECEIVE_FERTILIZER_INVENTORIES = 'RECEIVE_FERTILIZER_INVENTORIES'
export const receiveFertilizerInventories = makeActionCreator(
  RECEIVE_FERTILIZER_INVENTORIES,
  'fertilizerInventories',
  'count',
  'error'
)

export const REQUEST_FERTILIZER_INVENTORY = 'REQUEST_FERTILIZER_INVENTORY'
export const requestFertilizerInventory = makeActionCreator(
  REQUEST_FERTILIZER_INVENTORY,
  'params'
)

export const RECEIVE_FERTILIZER_INVENTORY = 'RECEIVE_FERTILIZER_INVENTORY'
export const receiveFertilizerInventory = makeActionCreator(
  RECEIVE_FERTILIZER_INVENTORY,
  'fertilizerInventory',
  'error'
)

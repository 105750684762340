import { Fragment, useCallback, useEffect, useReducer, useState } from 'react'
import { useDispatch } from 'react-redux'

import Strings from '../../Strings'

import {
  fetchSupplierDevice,
  cleanSupplierDevice,
  sendEnrollSupplierDevice
} from '@/slices/management/device'
import { fetchLocation } from '@/slices/location'

import { Button, Flex, Input, Slot, Text } from '@/primitives'
import { Dialog, LineSeparator } from '@/elements'

import {
  getDeviceLoading,
  getLocation,
  getSupplierDevice
} from '@/reducers/selectors'

const initialState = {
  deviceId: '',
  name: '',
  tag: '',
  latitude: 0,
  longitude: 0
}

function reducer(state, action) {
  switch (action.type) {
    case 'location':
      return {
        ...state,
        latitude: action.latitude,
        longitude: action.longitude
      }
    default:
      return { ...state, [action.type]: action.value }
  }
}

const DeviceForm = ({ showModal, selectedZone, closeModal }) => {
  const strings = Strings()

  const reduxDispatch = useDispatch()

  const location = getLocation()
  const isLoading = getDeviceLoading()
  const supplierDevice = getSupplierDevice()

  const [state, dispatch] = useReducer(reducer, initialState)
  const [submitted, setSubmitted] = useState(false)

  const onCloseModal = useCallback(() => {
    closeModal()
    reduxDispatch(cleanSupplierDevice())
  }, [closeModal, reduxDispatch])

  useEffect(() => {
    reduxDispatch(fetchLocation())
  }, [reduxDispatch])

  useEffect(() => {
    dispatch({
      type: 'location',
      latitude: location?.latitude ?? 0,
      longitude: location?.longitude ?? 0
    })
  }, [location])

  useEffect(() => {
    if (supplierDevice?.deviceId) {
      dispatch({ type: 'name', value: supplierDevice.name })
    }
  }, [supplierDevice])

  useEffect(() => {
    if (submitted && !isLoading) {
      onCloseModal()
    }
  }, [isLoading, submitted, onCloseModal])

  function onChangeTag(e) {
    const value = e.target.value
    if (value.length < 255) {
      dispatch({ type: 'tag', value: e.target.value })
    }
  }

  function onChangeInput(e) {
    const { name, value } = e.target
    dispatch({ type: name, value })
  }

  function onClickFetchSupplierDevice(e) {
    e.preventDefault()
    if (state?.deviceId?.length > 0) {
      reduxDispatch(fetchSupplierDevice({ deviceId: state.deviceId }))
    }
  }

  async function onSubmitForm() {
    const { name, latitude, longitude, tag } = state

    const deviceData = {
      zoneId: selectedZone?.id,
      deviceId: supplierDevice?.deviceId,
      proofOfPossession: supplierDevice?.proofOfPossession,
      name,
      type: supplierDevice?.type,
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
      tag: tag?.slice(0, 255) ?? null,
      isIoT: true, //TODO: remove when suppliers support greengrass
      isGreengrass: false, //TODO: remove when suppliers support greengrass
      config: null,
      hardwareVersion: supplierDevice?.hardwareVersion ?? null,
      zonePath: selectedZone?.path
    }

    reduxDispatch(sendEnrollSupplierDevice(deviceData))
    setSubmitted(true)
  }

  return (
    <Fragment>
      <Dialog
        open={showModal}
        onOpenChange={onCloseModal}
        type='offcanvas'
        className='Devices__OffCanvas'
        style={{ zIndex: 3 }}
      >
        <Slot name='title'>
          <Text as={'h5'} size={300} fontWeight={700}>
            {strings.enrollDeviceModal}
          </Text>
          <Text as='p'>
            {strings.deviceFormSubtitle} {selectedZone.name}
          </Text>
        </Slot>
        <Slot name='content'>
          <Flex direction='column' axisGap={500}>
            <LineSeparator />
            <Flex direction='column' axisGap={300}>
              <Text variant='page' tone={900} size={100}>
                {strings.deviceFormLabelId}
              </Text>
              <Flex
                direction='row'
                alignMainAxis='flex-start'
                axisGap={300}
                alignCrossAxis='center'
              >
                {supplierDevice.deviceId ? (
                  <Text variant='page' tone={900} size={200}>
                    {state.deviceId}
                  </Text>
                ) : (
                  <Input
                    name='deviceId'
                    value={state.deviceId ?? ''}
                    style={{ width: 'auto', flexGrow: 1 }}
                    onChange={onChangeInput}
                  />
                )}
              </Flex>
            </Flex>

            {supplierDevice.deviceId && (
              <Fragment>
                <Flex direction='column' axisGap={300}>
                  <Text variant='page' tone={900} size={100}>
                    {strings.deviceFormLabelDeviceType}
                  </Text>
                  <Flex
                    direction='row'
                    alignMainAxis='flex-start'
                    axisGap={300}
                    alignCrossAxis='center'
                  >
                    <Text variant='page' tone={900} size={200}>
                      {supplierDevice.type}
                    </Text>
                  </Flex>
                </Flex>
                <Flex direction='column' axisGap={300}>
                  <Text variant='page' tone={900} size={100}>
                    {strings.deviceFormLabelName}
                  </Text>
                  <Flex
                    direction='row'
                    alignMainAxis='flex-start'
                    axisGap={300}
                    alignCrossAxis='center'
                  >
                    <Input
                      name='name'
                      value={state.name ?? ''}
                      style={{ width: 'auto', flexGrow: 1 }}
                      onChange={onChangeInput}
                    />
                  </Flex>
                </Flex>
              </Fragment>
            )}

            {supplierDevice.deviceId && (
              <Fragment>
                <Flex direction='column' axisGap={300}>
                  <Text variant='page' tone={900} size={100}>
                    {strings.deviceFormLabelTag}
                  </Text>
                  <Flex direction='column' axisGap={300}>
                    <Input
                      as='textarea'
                      name='tag'
                      value={state.tag ?? ''}
                      style={{ width: 'auto', flexGrow: 1 }}
                      onChange={onChangeTag}
                    />
                    <Flex direction='row' alignMainAxis='space-between'>
                      <Text variant='page' tone={900} size={100}>
                        {strings.tagFormText}
                      </Text>
                      <Text variant='page' tone={900} size={100}>
                        ({state?.tag?.length ?? 0})
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>

                <Flex
                  direction='row'
                  alignMainAxis='start'
                  axisGap={300}
                  wrap='nowrap'
                >
                  <Flex
                    direction='column'
                    axisGap={300}
                    style={{ width: '100%' }}
                  >
                    <Text variant='page' tone={900} size={100}>
                      {strings.locationFormLatitude}
                    </Text>
                    <Input
                      name='latitude'
                      value={state.latitude}
                      onChange={onChangeInput}
                    />
                  </Flex>
                  <Flex
                    direction='column'
                    axisGap={300}
                    style={{ width: '100%' }}
                  >
                    <Text variant='page' tone={900} size={100}>
                      {strings.locationFormLongitude}
                    </Text>
                    <Input
                      name='longitude'
                      value={state.longitude}
                      onChange={onChangeInput}
                    />
                  </Flex>
                </Flex>
              </Fragment>
            )}
          </Flex>
        </Slot>

        <Slot name='actions'>
          <Flex axisGap={400} alignMainAxis={'space-between'}>
            <Button variant='page' onClick={onCloseModal}>
              {strings.cancel}
            </Button>
            <Button
              variant='primary'
              onClick={
                supplierDevice?.deviceId
                  ? onSubmitForm
                  : onClickFetchSupplierDevice
              }
              loading={isLoading}
            >
              {supplierDevice?.deviceId
                ? strings.createDeviceButton
                : strings.fetchSupplierDeviceButton}
            </Button>
          </Flex>
        </Slot>
      </Dialog>
    </Fragment>
  )
}

export default DeviceForm

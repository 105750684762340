import { Children } from 'react'

const slottedChildren = (slots, child) => {
  if (
    child.type?.displayName === 'Slot' &&
    slots[child.props?.name] !== undefined
  ) {
    child.props.children &&
      (slots[child.props.name] = slots[child.props.name]
        ? slots[child.props.name]
        : []).push(child)
  } else {
    // prettier-ignore
    (slots.rest = slots.rest ? slots.rest : []).push(child)
  }
  return slots
}

export const getSlottedChildren = (children, slots = { rest: null }) =>
  Children.toArray(children).reduce(slottedChildren, slots)

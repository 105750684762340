import { useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Box, Text, Row, Cell } from '@/primitives'

const composeKey = (id, tag, idx) => [id, tag, idx].join('_')

/* -----------------------------------------------------------------------------
 * AutoTable
 * ---------------------------------------------------------------------------*/
/**
 * AutoTable Primitive
 * @param {Object} props
 * @param {String} [props.className] Table Class
 * @param {Boolean} [props.sticky=false] A Sticky Table Header
 * @param {Number} [props.maxHeight=null] Limit Table Height
 * @param {String} [props.title=null] Accessible Table Title
 * @param {import('react').CSSProperties} [props.css] IonCSS Styles
 * @param {Array} [props.caption] Table Caption
 * @param {Array} [props.columns] Table Header Data
 * @param {Array} [props.data] Table Body Data
 *
 * @example
 * ```jsx
 * const tabularColumns = ['Column A','Column B']
 *
 * const tabularData = [
 *   ['Cell A-1', 'Cell B-1'],
 *   ['Cell A-2', 'Cell B-2']
 * ]
 *
 * <AutoTable
 *   caption='Tabular Caption'
 *   data={tabularData}
 *   columns={tabularColumns}
 *   maxHeight={300}
 *   title={'A Table Title'}
 * />
 * ```
 */

export const AutoTable = ({
  className = '',
  data = null,
  columns = null,
  caption = null,
  sticky = false,
  maxHeight = null,
  title = null,
  ...rest
}) => {
  const { current: tableID } = useRef(
    ['TABLE', '_', uuidv4().split('-')[0]].join('')
  )

  return (
    <Box
      className={[
        'TableWrap',
        { mod__sticky: sticky, mod__maxheight: maxHeight }
      ]}
      style={{ maxHeight }}
    >
      <Box
        as='table'
        className={['Table', className]}
        role='grid'
        aria-label={title}
        aria-rowcount={data && data.length}
        aria-colcount={columns && columns.length}
        {...rest}
      >
        {caption && (
          <Box as={'caption'}>
            <Text fontFamily='sans' size={200} fontWeight={500}>
              {caption}
            </Text>
          </Box>
        )}

        {columns && (
          <Box as='thead'>
            <Row>
              {columns.map((text, index) => (
                <Box as='th' key={composeKey(tableID, 'th', index)}>
                  {text}
                </Box>
              ))}
            </Row>
          </Box>
        )}

        {data && (
          <Box as='tbody'>
            {data.map((row, rowIndex) => (
              <Row key={composeKey(tableID, 'tr', rowIndex)}>
                {row.map((cell, cellIndex) => (
                  <Cell
                    columnName={columns && columns[cellIndex]}
                    key={composeKey(tableID, 'td', cellIndex)}
                  >
                    {cell}
                  </Cell>
                ))}
              </Row>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default AutoTable

import { I18n } from 'aws-amplify'

const Strings = () => ({
  /* Site Header */
  sites: I18n.get('Sites'),
  zones: I18n.get('Zones'),
  harvest: I18n.get('Harvest'),
  operations: I18n.get('Operations'),
  admin: I18n.get('Admin'),
  docs: I18n.get('Docs'),
  about: I18n.get('About'),
  contact: I18n.get('Contact'),
  releaseNotes: I18n.get('Release notes'),

  /* Sections */
  devices: I18n.get('Devices'),
  environsense: I18n.get('Envirosense'),
  watersense: I18n.get('Watersense'),
  enrollDevice: I18n.get('Enroll a device'),
  userManagement: I18n.get('User management'),
  userRoles: I18n.get('User roles'),
  userOptions: I18n.get('User options'),
  systemThresholds: I18n.get('System thresholds'),
  home: I18n.get('Home'),
  profile: I18n.get('Profile'),
  notifications: I18n.get('Notifications'),
  
  /* Authentication */
  signIn: I18n.get('Sign in'),
  signOut: I18n.get('Sign out'),

  /* Service Worker Update */
  swNewVersion: I18n.get('A new version is available.'),
  swClickHere: I18n.get('Click here to update.'),

  /* Languages */
  AR: I18n.get('Arabic'),
  EN: I18n.get('English'),
  ES: I18n.get('Spanish'),

  /* Site Footer */
  foundingPartners: I18n.get('Founding Partners'),

  /* Data API */
  dataApi: I18n.get('Data API')

})

export default Strings
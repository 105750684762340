import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { getNextHourPrediction } from '@/api/ml/prediction'

export const cleanNextHourPrediction = createAsyncThunk(
  'cleanNextHourPrediction',
  () => {
    return {}
  }
)

export const fetchNextHourPrediction = createAsyncThunk(
  'fetchNextHourPrediction',
  async params => {
    const measurementId = params?.measurementId ?? ''
    const { prediction, error } = await getNextHourPrediction(params)
    return { prediction, measurementId, error }
  }
)

const mlPredictionSlice = createSlice({
  name: 'mlPredictionReducer',
  initialState: {
    nextHour: {},
    error: null
  },
  extraReducers: builder => {
    builder
      .addCase(cleanNextHourPrediction.fulfilled, (state, action) => {
        state.nextHour = {}
        state.error = null
      })
      .addCase(fetchNextHourPrediction.pending, (state, action) => {
        state.nextHour = {}
        state.error = null
      })
      .addCase(fetchNextHourPrediction.fulfilled, (state, action) => {
        if (!action?.payload?.error) {
          state.nextHour = {
            ...state.nextHour,
            [action?.payload?.measurementId]: action?.payload?.prediction
          }
        }

        state.error = action?.payload?.error ?? null
      })
  }
})

export default mlPredictionSlice.reducer

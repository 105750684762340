import { Link } from 'react-router-dom'
import { I18n } from 'aws-amplify'

import { Dropdown } from '../../elements'
import { Flex, Icon, Item, Text } from '../../primitives'
import useOrgLabel from '@/hooks/useOrgLabel'

import Strings from './Strings'

const NOTIFICATION = 1
const CAUTION = 2
const WARNING = 3

function iconColor(status) {
  switch (status) {
    case NOTIFICATION:
      return 'warning'
    case CAUTION:
      return 'danger'
    case WARNING:
      return 'error'
    default:
      return 'primary'
  }
}

function bellColor(status) {
  switch (status) {
    case NOTIFICATION:
      return 'var(--ctx-theme-color-warning-700)'
    case CAUTION:
      return 'var(--ctx-theme-color-danger-700)'
    case WARNING:
      return 'var(--ctx-theme-color-error-600)'
    default:
      return 'var(--ctx-theme-color-success-700)'
  }
}

const STATUSMAP = {
  warning: 3,
  caution: 2,
  notification: 1
}

function getHighestStatusForBell(zoneStatuses) {
  const zones = Object.keys(zoneStatuses)
  return zones.reduce((acc, zone) => {
    const status = zoneStatuses[zone].statuses.reduce((acc, status) => {
      return STATUSMAP[status.status.level] > acc
        ? STATUSMAP[status.status.level]
        : acc
    }, 0)
    return status > acc ? status : acc
  }, 0)
}

function getHighestStatus(statuses) {
  return statuses.reduce((acc, status) => {
    return STATUSMAP[status.status.level] > acc
      ? STATUSMAP[status.status.level]
      : acc
  }, 0)
}

export default function StatusMenu({
  parentId,
  hasStatuses,
  zoneStatuses,
  measurementStatuses,
  ...rest
}) {
  const orgLabels = useOrgLabel(['site', 'facility', 'room', 'zone', 'subzone'])
  const strings = Strings()

  const highestStatusForBell = getHighestStatusForBell(zoneStatuses)

  return (
    <Dropdown
      triggerIcon={'notifications'}
      style={{ color: bellColor(highestStatusForBell) }}
      {...rest}
    >
      {zoneStatuses &&
        Object.keys(zoneStatuses).map((key, index) => (
          <Item key={`${key}-${index}`}>
            <Link to={`/zones/${key}`}>
              <Flex axisGap={300} alignCrossAxis={'center'}>
                <Icon
                  name='warning circle'
                  variant={iconColor(
                    getHighestStatus(zoneStatuses[key].statuses)
                  )}
                  tone={500}
                />
                <Text>
                  {`${zoneStatuses[key]?.name} ${strings.errorHas} ${
                    zoneStatuses[key]?.statuses?.length
                  } ${
                    zoneStatuses[key]?.statuses?.length > 1
                      ? strings.errorAlerts
                      : strings.errorAlert
                  }.`}
                </Text>
              </Flex>
            </Link>
          </Item>
        ))}

      {!hasStatuses && (
        <Item>
          <Flex axisGap={300} alignCrossAxis={'center'}>
            <Icon name='check circle' variant='primary' tone={500} />
            {orgLabels.site.textPlural} {I18n.get('OK')}
          </Flex>
        </Item>
      )}
    </Dropdown>
  )
}

import { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { I18n } from 'aws-amplify'

import { Select } from '@/elements'

import { setUser, resendUserInvite } from '@/slices/management/user'

import { getCurrentUser } from '@/reducers/selectors'

import { getIsGodMode } from '@/Util/PermissionUtils'

const ACTION_EDIT = 'edit'
const ACTION_LOG = 'log'
const ACTION_RESEND = 'resend'
const ACTION_FEAUTURE_FLAGS = 'feature_flags'

const generateActions = (
  showEditOptions,
  showLogsOption,
  cognitoStatus,
  godMode = false
) => {
  const actions = [
    {
      label: I18n.get('Edit user'),
      value: ACTION_EDIT
    }
  ]

  if (showLogsOption) {
    actions.push({
      label: I18n.get('View logs'),
      value: ACTION_LOG
    })
  }

  const canResend = !cognitoStatus || cognitoStatus === 'FORCE_CHANGE_PASSWORD'
  if (showEditOptions && canResend) {
    actions.push({
      label: I18n.get('Send invitation'),
      value: ACTION_RESEND
    })
  }

  if (godMode) {
    actions.push({
      label: I18n.get('Feature flags'),
      value: ACTION_FEAUTURE_FLAGS
    })
  }

  return actions
}

const UserActions = ({
  user,
  showEditOptions,
  showLogsOption,
  onOpenFeatureFlagSidebar
}) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const currentUser = getCurrentUser()
  const isGodMode = getIsGodMode(currentUser)

  const { firstName, lastName, email, userName, role, cognitoStatus } = user

  const actions = generateActions(
    showEditOptions,
    showLogsOption,
    cognitoStatus,
    isGodMode
  )

  const onClickAudit = () => {
    history.push({
      pathname: `/admin/user-management/${userName}/audit`,
      state: { userId: userName }
    })
  }

  const onClickSendInvite = () => {
    dispatch(resendUserInvite({ username: userName }))
  }

  const onUserSelected = () => {
    const userDetails = {
      firstName,
      lastName,
      email,
      userName,
      role
    }
    dispatch(setUser(userDetails))
    history.push(`/admin/user-management/${userName}`)
  }

  function onSelectAction({ value }) {
    switch (value) {
      case ACTION_EDIT:
        onUserSelected()
        break
      case ACTION_LOG:
        onClickAudit()
        break
      case ACTION_RESEND:
        onClickSendInvite()
        break
      case ACTION_FEAUTURE_FLAGS:
        onOpenFeatureFlagSidebar(user.userName)
        break
      default:
        break
    }
  }

  return (
    <Fragment>
      {actions?.length > 0 && (
        <Select
          classNamePrefix='UserAction__Select'
          placeholder={I18n.get('Options')}
          onChange={onSelectAction}
          options={actions}
        />
      )}
    </Fragment>
  )
}

export default UserActions

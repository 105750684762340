import { I18n } from 'aws-amplify'

import { useMobileDashboard } from '../../context'
import LiveDataTile from './LiveDataTile'
import { Text, Box } from '../../../../../primitives'
import { getDeviceTypes } from '@/reducers/selectors'

import './index.scss'

export default function LiveData({ zoneMeasurements, zoneId, isRoom }) {
  const { state } = useMobileDashboard()
  const { devices } = state?.currentZone
  const deviceTypes = getDeviceTypes()

  let measurementsToShow = [...zoneMeasurements]

  const currentDevice = devices.find(({ id }) => id === zoneId)

  if (currentDevice && deviceTypes?.length > 0) {
    const { sensorType } = devices.find(({ id }) => id === zoneId)
    const deviceType = deviceTypes.find(type => type.id === sensorType)
    const { measurements } = deviceType
    measurementsToShow = zoneMeasurements.filter(id =>
      measurements.includes(id)
    )
  }

  if (measurementsToShow?.includes('temperature') && measurementsToShow?.includes('humidity') && !measurementsToShow?.includes('vapour_pressure_deficit')) {
    measurementsToShow.push('vapour_pressure_deficit')
  }

  if (measurementsToShow?.length === 0) {
    return (
      <Box style={{ margin: '0.8rem 0' }}>
        <Text tone={800} variant='page'>
          {I18n.get('No data available')}
        </Text>
      </Box>
    )
  }

  return (
    <div className='LiveDataWidget'>
      {measurementsToShow.map(measurementId => {
        return (
          <div className='LiveDataWidget__GridItem' key={measurementId}>
            <LiveDataTile
              measurementId={measurementId}
              zoneId={zoneId}
              isRoom={isRoom}
            />
          </div>
        )
      })}
    </div>
  )
}

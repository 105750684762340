import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'

import LogsTable from './LogsTable'

import {
  Button,
  FlexV2,
  Text,
  Input,
  Separator,
  Symbol,
  Label
} from '@/primitives'
import { Tabs } from '@/elements'

import { cleanCognitoLogs, fetchUserCognitoLogs } from '@/slices/audit/cognito'

import useMediaQuery from '@/hooks/useMediaQuery'

import Strings from '../../Strings'

import './index.scss'

export default function AuditCognitoLogs({ location, userName = '' }) {
  const dispatch = useDispatch()

  const isAboveSmallMobile = useMediaQuery('(min-width: 480px)')
  const isAboveLargeMobile = useMediaQuery('(min-width: 640px)')

  const [userId, setUserId] = useState(userName)
  const [tabIndex, setTabIndex] = useState(0)
  const [startAt, setStartAt] = useState({ value: '', valueAsNumber: 0 })
  const [endAt, setEndAt] = useState({ value: '', valueAsNumber: 0 })

  const strings = Strings()

  useEffect(() => {
    const { state, pathname } = location

    if (state?.userId) {
      setUserId(state?.userId)
    } else {
      const pathLevels = pathname.split('/')
      const userIdFromURL = pathLevels[pathLevels.length - 2]
      setUserId(userIdFromURL)
    }
  }, [location])

  useEffect(() => {
    return () => dispatch(cleanCognitoLogs())
  }, [dispatch])

  useEffect(() => {
    if (userId) {
      const dateNow = DateTime.now()
      const dateYesterday = DateTime.now().minus({ days: 1 }).startOf('day')

      const startAtMillis = dateYesterday.toMillis()
      const endAtMillis = dateNow.toMillis()

      setStartAt({
        value: dateYesterday.toISODate(),
        valueAsNumber: startAtMillis
      })
      setEndAt({ value: dateNow.toISODate(), valueAsNumber: endAtMillis })
      dispatch(
        fetchUserCognitoLogs({
          userId,
          startAt: startAtMillis,
          endAt: endAtMillis
        })
      )
    }
  }, [dispatch, userId])

  let tabConfigs = [
    {
      tabId: 'activeSessions',
      tabLabel: strings.logActiveSessions,
      renderTabContent: () => <LogsTable selectedTab='activeSessions' />
    },
    {
      tabId: 'successfulLogins',
      tabLabel: strings.logSuccessfulLogins,
      renderTabContent: () => <LogsTable selectedTab='successfulLogins' />
    },
    {
      tabId: 'unsuccessfulLogins',
      tabLabel: strings.logUnsuccessfulLogins,
      renderTabContent: () => <LogsTable selectedTab='unsuccessfulLogins' />
    }
  ]

  const onChooseDate = ({ target }) => {
    const { name, value, valueAsNumber } = target
    if (name === 'start') {
      setStartAt({ value, valueAsNumber })
    } else {
      const endOfDay = DateTime.fromMillis(valueAsNumber).endOf('day')
      setEndAt({ value, valueAsNumber: endOfDay.toMillis() })
    }
  }

  const onClickFetch = () => {
    dispatch(
      fetchUserCognitoLogs({
        userId,
        startAt: startAt.valueAsNumber,
        endAt: endAt.valueAsNumber
      })
    )
  }

  return (
    <FlexV2 direction='column' axisGap={400} className='AuditLogs'>
      <FlexV2 axisGap={400} alignCrossAxis='center'>
        <Link to='/admin/user-management'>
          <Button iconAfter='chevron left' size={'small'} />
        </Link>
        <FlexV2 direction='column'>
          <Text size={300}>{strings.logs}</Text>
          <Text variant='page' tone={700}>
            {strings.logsViewSubtitle} <strong>{userId}</strong>
          </Text>
        </FlexV2>
      </FlexV2>

      <Separator />

      <FlexV2
        direction={isAboveLargeMobile ? 'row' : 'column'}
        axisGap={400}
        alignCrossAxis={isAboveLargeMobile ? 'flex-end' : null}
      >
        <FlexV2
          direction={isAboveSmallMobile ? 'row' : 'column'}
          axisGap={400}
          alignCrossAxis={isAboveSmallMobile ? 'flex-end' : null}
        >
          <Label style={{ flexGrow: 1 }}>
            <Text variant='page' tone={700} size={100} fontWeight={700}>
              {strings.startDate}
            </Text>
            <Input
              type='date'
              name='start'
              onChange={onChooseDate}
              value={startAt.value}
            />
          </Label>
          {isAboveSmallMobile && <Symbol name='horizontal_rule' />}
          <Label style={{ flexGrow: 1 }}>
            <Text variant='page' tone={700} size={100} fontWeight={700}>
              {strings.endDate}
            </Text>
            <Input
              type='date'
              name='end'
              onChange={onChooseDate}
              value={endAt.value}
            />
          </Label>
        </FlexV2>
        <Button variant='info' onClick={onClickFetch}>
          {strings.logsFetch}
        </Button>
      </FlexV2>

      <Separator />

      <Tabs
        tabConfigs={tabConfigs}
        activeIndex={tabIndex}
        onSwitchTab={setTabIndex}
      />
    </FlexV2>
  )
}

import React, { useState, useMemo } from 'react'
import { createAutocomplete } from '@algolia/autocomplete-core'
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia'
import algoliasearch from 'algoliasearch/lite'
import { PoweredBy } from 'react-instantsearch'
import { I18n } from 'aws-amplify'

import { useArticleSuggestion } from '@/algolia/context'

import FlexV2 from '@/primitives/FlexV2'
import Text from '@/primitives/Text'
import Button from '@/primitives/Button'

import './RecommendedArticles.scss'

const searchClient = algoliasearch(
  window.ENV.ALGOLIA_APP_ID,
  window.ENV.ALGOLIA_SEARCH_KEY
)

const DOMAIN = window.ENV.ENV === 'dev' ? 'coretex.uk' : 'coretex.ag'
const BASE_URL = `https://help.${DOMAIN}`

const getQuery = state => {
  let query = state.keywords

  if (state.specificQuery) {
    return state.specificQuery
  }

  return query.join(' ')
}

export default function RecommendedArticles() {
  const { state } = useArticleSuggestion()
  let query = getQuery(state)

  const [autocompleteState, setAutocompleteState] = useState({
    collections: [],
    completion: null,
    context: {},
    query: '',
    activeItemId: null,
    status: 'idle'
  })

  useMemo(() => {
    const api = createAutocomplete({
      onStateChange({ state }) {
        setAutocompleteState(state)
      },
      getSources({ setContext }) {
        const getItems = () =>
          getAlgoliaResults({
            searchClient,
            queries: [
              {
                indexName: window.ENV.ALGOLIA_INDEX_NAME,
                query,
                params: {
                  hitsPerPage: 9,
                  advancedSyntax: true,
                  advancedSyntaxFeatures: ['excludeWords']
                }
              }
            ],
            transformResponse({ results, hits }) {
              setContext({
                nbHits: results[0].nbHits,
                hits: results[0].hits.length
              })
              return hits
            }
          })

        return [{ sourceId: 'articles', getItems }]
      },
      initialState: { ...autocompleteState, query }
    })
    api.refresh()
    return api
  }, [query])

  const getMoreResultsUrl = () => {
    const searchParams = new URLSearchParams()
    searchParams.set(
      `${window.ENV.ALGOLIA_INDEX_NAME}[query]`,
      autocompleteState.query
    )
    return `${BASE_URL}/s?${searchParams.toString()}`
  }

  const getResultsUrl = item =>
    `${BASE_URL}/a/${item.articleCategory}/${item.slug}`

  if (!autocompleteState.collections.length) return null

  return (
    <FlexV2
      direction='column'
      axisGap={400}
      style={{ flexGrow: 1, justifyContent: 'space-between' }}
    >
      <FlexV2 direction='column' axisGap={400}>
        <FlexV2
          alignMainAxis='space-between'
          className='RecommendedArticles__Header'
        >
          <Text variant='page' tone={900} fontWeight={500} size={300}>
            {I18n.get('Recommended Articles')}
          </Text>
          <PoweredBy className='RecommendedArticles__PoweredBy' theme='dark' />
        </FlexV2>
        <FlexV2 className='RecommendedArticles'>
          {autocompleteState.collections.map((collection, index) => {
            const { source, items } = collection

            return (
              <FlexV2
                key={source + index}
                direction='column'
                style={{
                  overflow: 'hidden'
                }}
                axisGap={300}
              >
                {items.length > 0 &&
                  items.map(item => {
                    return (
                      <a
                        className='RecommendedArticles__Link'
                        key={item.objectID}
                        href={getResultsUrl(item)}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <FlexV2
                          key={item.objectID}
                          className='RecommendedArticles__Item'
                        >
                          <FlexV2
                            direction='column'
                            className='RecommendedArticles__Content'
                          >
                            <Text
                              className='RecommendedArticles__Content__Title'
                              fontWeight={500}
                              variant='page'
                              size={200}
                              tone={700}
                            >
                              {item.title}
                            </Text>
                            <Text
                              className='RecommendedArticles__Content__Description'
                              variant='page'
                              fontWeight={400}
                              tone={900}
                            >
                              {item.description}
                            </Text>
                          </FlexV2>
                        </FlexV2>
                      </a>
                    )
                  })}
              </FlexV2>
            )
          })}
        </FlexV2>
      </FlexV2>
      <Text
        as='a'
        className='RecommendedArticles__SeeMoreLink'
        style={{
          display:
            autocompleteState.context.nbHits <= 9 ? 'none' : 'inline-block'
        }}
        href={getMoreResultsUrl()}
        target='_blank'
        rel='noopener noreferrer'
      >
        <Button className='RecommendedArticles__SeeMoreButton'>
          {I18n.get('See')}{' '}
          {autocompleteState.context.nbHits - autocompleteState.context.hits}{' '}
          {I18n.get('more articles')}
        </Button>
      </Text>
    </FlexV2>
  )
}

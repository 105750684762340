import { I18n } from 'aws-amplify'
import Symbol from '@/primitives/Symbol'
import { object, string } from 'yup'
import { HOURLY } from '../Shared/Dynamic/config'

const iconConfigs = [
  {
    min: 200,
    max: 233,
    icon: 'thunderstorm',
    variant: 'warning',
    tone: 900
  },
  {
    min: 300,
    max: 321,
    icon: 'rainy_light',
    variant: 'info',
    tone: 600
  },
  {
    min: 500,
    max: 531,
    icon: 'rainy_heavy',
    variant: 'info',
    tone: 600
  },
  {
    min: 600,
    max: 602,
    icon: 'weather_snowy',
    variant: 'info',
    tone: 900
  },
  {
    min: 610,
    max: 623,
    icon: 'rainy_snow',
    variant: 'info',
    tone: 900
  },
  {
    min: 700,
    max: 781,
    icon: 'foggy',
    variant: 'page',
    tone: 900
  },
  {
    min: 800,
    max: 800,
    icon: 'sunny',
    variant: 'danger',
    tone: 700,
    alt: {
      icon: 'clear_night',
      variant: 'warning',
      tone: 800
    }
  },
  {
    min: 801,
    max: 803,
    icon: 'partly_cloudy_day',
    variant: 'warning',
    tone: 500,
    alt: {
      icon: 'partly_cloudy_night',
      variant: 'warning',
      tone: 500
    }
  },
  {
    min: 804,
    max: 804,
    icon: 'cloudy',
    variant: 'page',
    tone: 900
  }
]

export const TODAY = 'today'

export const NEXT_7_DAYS = 'next-7-days'

export const HOURLY_WEATHER = 'hourlyForecast'
export const DAILY_WEATHER = 'dailyForecast'

export const WEATHER_RANGE = {
  [TODAY]: {
    label: I18n.get('Today'),
    value: TODAY,
    intervals: [HOURLY],
    query: [0, 'd']
  },
  [NEXT_7_DAYS]: {
    label: I18n.get('Next 7 days'),
    value: NEXT_7_DAYS,
    intervals: [DAILY_WEATHER, HOURLY_WEATHER],
    query: [7, 'd']
  }
}

export const WEATHER_INTERVALS = {
  [HOURLY_WEATHER]: {
    label: I18n.get('Hourly'),
    value: HOURLY_WEATHER,
    query: '1h',
    dateFormat: 'HH:mm'
  },
  [DAILY_WEATHER]: {
    label: I18n.get('Daily'),
    value: DAILY_WEATHER,
    query: '1d',
    dateFormat: 'dd/MM/yy'
  }
}

export const getWeatherIcon = (code, forecastTime, sunriseTime, sunsetTime) => {
  const iconDetails = iconConfigs.find(({ min, max }) => {
    return code >= min && code <= max
  })

  if (!iconDetails) {
    return (
      <Symbol name='horizontal_rule' variant='neutral' tone={600} size={400} />
    )
  }

  const isNight = forecastTime > sunsetTime || forecastTime < sunriseTime
  const hasAltIcon = iconDetails?.alt?.hasOwnProperty('icon')
  const { alt, ...rest } = iconDetails
  const iconVersion = isNight && hasAltIcon ? alt : rest
  return (
    <Symbol
      name={iconVersion?.icon}
      variant={iconVersion?.variant}
      tone={iconVersion?.tone}
      size={400}
    />
  )
}

export const getTileHeader = (timestamp, isHourly, lang, timeZone) => {
  const date = new Date(timestamp)
  if (isHourly) {
    const hour = date.toLocaleString([lang, 'en-GB'], {
      timeZone,
      hour: '2-digit',
      hour12: false
    })
    return `${hour}H`
  }

  const day = date.toLocaleString([lang, 'en-GB'], {
    timeZone,
    weekday: 'short'
  })
  return day.toUpperCase()
}

export const validateQueryOptions = () => {
  const shape = {
    range: string().required(I18n.get('Select a range is required'))
  }

  return object().shape(shape)
}

export const weatherEditTypes = {
  setAll: 'setAll',
  setRange: 'setRange',
  setInterval: 'setInterval'
}

export const weatherEditInitalState = {
  query: '',
  chart: '',
  range: '',
  interval: ''
}

export const weatherEditReducer = (state, action) => {
  switch (action.type) {
    case 'setAll':
      return {
        ...state,
        query: action.payload.query || '',
        chart: action.payload.chart || '',
        range: action.payload.range || '',
        interval: action.payload.interval || ''
      }
    case 'setRange':
      return {
        ...state,
        range: action.payload,
        interval: action.payload === TODAY ? HOURLY_WEATHER : DAILY_WEATHER
      }
    default:
      return state
  }
}

export const createWeatherRangeOptions = (
  ranges = [],
  defaultLabel = I18n.get('Select a value')
) => {
  let options = ranges.map(range => {
    const rangeValues = WEATHER_RANGE[range]
    return {
      value: rangeValues.value,
      label: rangeValues.label
    }
  })
  options.unshift({
    value: '',
    label: defaultLabel,
    isDisabled: true
  })
  return options
}

export const SET_STATE = 'SALE_ITEM_SET_STATE'
export const UPDATE_INPUT = 'SALE_ITEM_UPDATE_INPUT'

export const initialState = {
  id: '',
  productId: '',
  quantityOrdered: '',
  unitPrice: '',
  discount: '',
  tax: ''
}

export function reducer(state, action) {
  const handlers = {
    [SET_STATE]: (state, action) => {
      let newState = {
        ...initialState,
        ...action.state
      }
      return newState
    },
    [UPDATE_INPUT]: (state, action) => ({
      ...state,
      [action.name]: action.value
    })
  }

  if (handlers[action.type]) {
    return handlers[action.type](state, action)
  }

  return state
}

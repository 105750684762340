import React, { Children } from 'react'
import { Link } from 'react-router-dom'
import { Box, Text, Icon, Flex, Collapse, Slot } from '../'
import './index.scss'

/* -----------------------------------------------------------------------------
 * ListContent
 * ---------------------------------------------------------------------------*/

const ListContent = ({
  icon = null,
  primaryText = null,
  secondaryText = null,
  onClick = null
}) => (
  <Flex axisGap={400} alignCrossAxis={'center'} className='List__ItemContent'>
    {icon && <Icon name={icon} />}
    {(primaryText || secondaryText) && (
      <Box
        onClick={onClick ? onClick : () => {}}
        style={{ cursor: onClick ? 'pointer' : 'default' }}
      >
        {primaryText && <Text>{primaryText}</Text>}
        {secondaryText && (
          <Text as='div' size={100} variant='page' tone={800}>
            {secondaryText}
          </Text>
        )}
      </Box>
    )}
  </Flex>
)

ListContent.displayName = 'ListContent'

/* -----------------------------------------------------------------------------
 * ListItem
 * ---------------------------------------------------------------------------*/

const ListItem = (
  {
    key = null,
    props: {
      primaryText = null,
      secondaryText = null,
      children = null,
      icon = null,
      to = null,
      onClick = null
    } = {
      primaryText: null,
      secondaryText: null,
      children: null,
      icon: null,
      to: null
    }
  },
  index
) => (
  <Box as='li' key={key ?? '.' + index} className='List__Item'>
    {(primaryText || secondaryText || icon) &&
      (children ? (
        <Collapse>
          <Slot name='trigger'>
            {to !== null ? (
              <Link to={to}>
                {ListContent({ primaryText, secondaryText, icon })}
              </Link>
            ) : (
              ListContent({ primaryText, secondaryText, icon })
            )}
          </Slot>
          <Slot name='content'>{List({ children })}</Slot>
        </Collapse>
      ) : to !== null ? (
        <Link to={to}>{ListContent({ primaryText, secondaryText, icon })}</Link>
      ) : (
        ListContent({ primaryText, secondaryText, icon, onClick })
      ))}
  </Box>
)

ListContent.displayName = 'ListItem'

/* -----------------------------------------------------------------------------
 * ListTitle
 * ---------------------------------------------------------------------------*/

const ListTitle = ({ title }) => (
  <Box as='li' className='List__Title'>
    <Text as='strong' variant='page' tone={800}>
      {title}
    </Text>
  </Box>
)

ListContent.displayName = 'ListTitle'

/* -----------------------------------------------------------------------------
 * List
 * ---------------------------------------------------------------------------*/

/**
 * List Primitive
 * @param {Object} props
 * @param {Boolean} [props.compact=false] Reduce spacing inside Lists
 * @param {Boolean} [props.xSpacing=true] Remove X-axis spacing entirely
 * @param {Boolean} [props.ySpacing=true] Remove Y-axis spacing entirely
 * @param {Boolean} [props.standalone=true] Remove border decorations when false
 * @param {String} [props.title=null] Add a title to the list
 * @param {String | String[]} [props.className=''] Add a custom className
 * @param {React.ReactChildren} [props.children=null] List <Item />s
 * @param {import('react').CSSProperties} [props.css] IonCSS Styles
 *
 * @example
 * ```jsx
 * <List>
 *   <Item
 *     primaryText={'List Item Primary'}
 *     secondaryText={'List Item Secondary'}
 *     icon={'face'}
 *     to= {'/relative/path'}
 *   >
 *    <Item primaryText={'List Item Primary'}></Item>
 *   </Item>
 * </List>
 * ```
 */

const List = ({
  compact = false,
  xSpacing = true,
  ySpacing = true,
  standalone = true,
  title = null,
  className = '',
  children = null,
  ...rest
}) => {
  return (
    <Box
      as='ul'
      className={[
        'List',
        {
          mod__standalone: standalone,
          mod__compact: compact,
          mod__xspacing: xSpacing,
          mod__yspacing: ySpacing
        },
        className
      ]}
      {...rest}
    >
      {title && <ListTitle title={title} />}
      {children && Children.toArray(children).map(ListItem)}
    </Box>
  )
}

export default List

import { updateList } from '../util'

import {
  RECEIVE_PACKAGE_UNIT_INVENTORY_CALIBRATIONS,
  REQUEST_PACKAGE_UNIT_INVENTORY_CALIBRATIONS,
  CLEAR_PACKAGE_UNIT_INVENTORY_CALIBRATIONS,
  CREATE_PACKAGE_UNIT_INVENTORY_CALIBRATION,
  PACKAGE_UNIT_INVENTORY_CALIBRATION_CREATED
} from '@/actions/operations/packageUnitInventoryCalibration'

export const packageUnitInventoryCalibrationInitialState = {
  packageUnitInventoryCalibrations: [],
  packageUnitInventoryCalibrationsCount: 0,
  loading: false,
  error: null
}

const handlers = {
  [CLEAR_PACKAGE_UNIT_INVENTORY_CALIBRATIONS]: (state, action) => ({
    ...state,
    packageUnitInventoryCalibrations: [],
    packageUnitInventoryCalibrationsCount: 0,
    error: null
  }),
  [REQUEST_PACKAGE_UNIT_INVENTORY_CALIBRATIONS]: (state, action) => ({
    ...state,
    packageUnitInventoryCalibrations: [],
    packageUnitInventoryCalibrationsCount: 0,
    error: null,
    loading: true
  }),
  [RECEIVE_PACKAGE_UNIT_INVENTORY_CALIBRATIONS]: (state, action) => ({
    ...state,
    packageUnitInventoryCalibrations: !action.error
      ? action.packageUnitInventoryCalibrations
      : [],
    packageUnitInventoryCalibrationsCount: !action.error ? action.count : 0,
    error: action.error ?? null,
    loading: false
  }),
  [CREATE_PACKAGE_UNIT_INVENTORY_CALIBRATION]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [PACKAGE_UNIT_INVENTORY_CALIBRATION_CREATED]: (state, action) => ({
    ...state,
    packageUnitInventoryCalibration: !action.error
      ? {}
      : state.packageUnitInventoryCalibration,
    packageUnitInventoryCalibrations: updateList(
      action.error,
      state.packageUnitInventoryCalibrations,
      action.packageUnitInventoryCalibration,
      true
    ),
    packageUnitInventoryCalibrationsCount: !action.error
      ? state.packageUnitInventoryCalibrationsCount + 1
      : state.packageUnitInventoryCalibrationsCount,
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

import { I18n } from 'aws-amplify'

/**
 * Centralized location for all organization label strings.
 * Requires both text and textPlural to be defined.
 */
export const OrganizationLabelStrings = () => ({
  // Location
  site: {
    text: I18n.get('Site'),
    textPlural: I18n.get('Sites')
  },
  facility: {
    text: I18n.get('Facility'),
    textPlural: I18n.get('Facilities')
  },
  room: {
    text: I18n.get('Room'),
    textPlural: I18n.get('Rooms')
  },
  zone: {
    text: I18n.get('Zone'),
    textPlural: I18n.get('Zones')
  },
  subzone: {
    text: I18n.get('Subzone'),
    textPlural: I18n.get('Subzones')
  }
})

/** Get the label for an organization
 * @param {Object} params
 * @param {string} params.label - The label to get
 * @param {Object} params.fallbackLabels - The fallback labels to use if the label is not found
 * @param {string} params.fallbackLabels.text - The fallback label text
 * @param {string} params.fallbackLabels.textPlural - The fallback label plural text
 * @param {boolean} params.isPlural - Whether to get the plural version of the label
 * @param {Object} params.organizationLabels - The organization labels to use
 */
export function selectOrganizationLabel({
  label,
  fallbackLabels,
  isPlural = false,
  organizationLabels = {}
}) {
  if (!label) {
    throw new Error('No label passed to selectOrganizationLabel')
  }

  if (!fallbackLabels) {
    throw new Error('No fallback labels passed to selectOrganizationLabel')
  }

  const labelObj = organizationLabels[label.toLowerCase()]
  if (!labelObj) {
    return isPlural ? fallbackLabels.textPlural : fallbackLabels.text
  }
  return isPlural ? labelObj.textPlural : labelObj.text
}

/** Get plural and singular labels for an organization
 *
 * @param {Object} params
 * @param {string} params.label - The label to get
 * @param {Object} params.fallbackLabels - The fallback labels to use if the label is not found
 * @param {string} params.fallbackLabels.text - The fallback label text
 * @param {string} params.fallbackLabels.textPlural - The fallback label plural text
 * @param {Object} params.organizationLabels - The organization labels to use
 * @returns {{text: string, textPlural: string}} The label and plural label
 */
export function selectOrganizationLabels({
  label,
  fallbackLabels,
  organizationLabels = {}
}) {
  if (!label) {
    throw new Error('No label passed to selectOrganizationLabel')
  }

  if (!fallbackLabels) {
    throw new Error('No fallback labels passed to selectOrganizationLabel')
  }

  const labelObj = organizationLabels[label.toLowerCase()]
  if (!labelObj) {
    return {
      text: fallbackLabels.text,
      textPlural: fallbackLabels.textPlural
    }
  }
  return {
    text: labelObj.text,
    textPlural: labelObj.textPlural
  }
}

/**
 * This function is similar to {@link useOrgLabel}, but to be used in the context of a redux selector.
 * @param {Object} orgLabels - The organization labels to use, you get this from the redux store.
 * @param {string[]} labels - The labels to get the organization label version of.
 * @param {Object} currentUser - The current user, you get this from the redux store.
 * @returns {Object.<string, {text: string, textPlural: string}>} - An object with each key corresponding to an input label.
 * @throws Will throw an error if a label does not exist in {@link OrganizationLabelStrings}.
 */
export function getOrgLabels(orgLabels, labels) {
  const organizationLabelStrings = OrganizationLabelStrings()
  let orgLabelsToReturn = {}

  for (let label of labels) {
    if (!organizationLabelStrings[label]) {
      throw new Error(
        `Label ${label} does not exist in OrganizationLabelStrings. 
        Make sure the label is in all lowercase and is spelled correctly.
        Check 'src/Util/Strings/OrganizationLabels.js' for a list of valid labels.
        `
      )
    }

    orgLabelsToReturn[label] = selectOrganizationLabels({
      fallbackLabels: organizationLabelStrings[label],
      label,
      organizationLabels: orgLabels
    })
  }

  return orgLabelsToReturn
}

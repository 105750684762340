import { Fragment } from 'react'

import OrganizationsFilter from '../../../Shared/Filters/OrganizationsFilter'
import CustomersFilter from '../../../Shared/Filters/CustomersFilter'

const TableFilter = ({ state, dispatchState }) => {
  return (
    <Fragment>
      <OrganizationsFilter state={state} dispatchState={dispatchState} />
      <CustomersFilter state={state} dispatchState={dispatchState} />
    </Fragment>
  )
}

export default TableFilter

import { I18n } from 'aws-amplify'
import { array, date, object, string } from 'yup'

export const FIELDS = {
  id: 'id',
  organizationId: 'organizationId',
  returnDate: 'returnDate',
  referenceNumber: 'referenceNumber',
  customerId: 'customerId',
  branchId: 'branchId',
  returnItems: 'returnItems'
}

export const SCHEMA = () => {
  return object().shape({
    organizationId: string().required(
      I18n.get('An organization must be selected.')
    ),
    returnDate: date()
      .typeError('A return date must be provided.')
      .required(I18n.get('A return date must be selected.')),
    referenceNumber: string(),
    branchId: string().required(I18n.get('A branch must be selected')),
    customerId: string().required(I18n.get('A customer must be selected')),
    returnItems: array().min(
      1,
      I18n.get('At least one return item is required')
    )
  })
}

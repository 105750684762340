import { call, put, select, takeLatest } from 'redux-saga/effects'

import {
  getCities,
  getCity,
  createCity,
  updateCity
} from '@/api/operations/city'

import {
  REQUEST_ALL_CITIES,
  REQUEST_CITIES,
  REQUEST_CITY,
  CREATE_CITY,
  UPDATE_CITY,
  receiveCities,
  receiveAllCities,
  receiveCity,
  cityCreated,
  cityUpdated
} from '@/actions/operations/city'

import { displayBanner } from '../utils'
import { getOperationsAllCities } from './utils'

import Strings from './Strings'

const strings = Strings()

function* sendCreateCity(action) {
  const { data, error = null } = yield call(createCity, action.params)
  yield put(displayBanner(error, strings.cityCreated))
  yield put(cityCreated(data, error, true))
}

export function* watchCreateCity() {
  yield takeLatest(CREATE_CITY, sendCreateCity)
}

function* sendUpdateCity(action) {
  const { data, error = null } = yield call(updateCity, action.params)
  yield put(displayBanner(error, strings.cityUpdated))
  yield put(cityUpdated(data, error, true))
}

export function* watchUpdateCity() {
  yield takeLatest(UPDATE_CITY, sendUpdateCity)
}

function* sendRequestCities(action) {
  const { data, error = null } = yield call(getCities, action.params)
  const { cities = [], count = 0 } = data
  yield put(receiveCities(cities, count, error))
}

export function* watchRequestCities() {
  yield takeLatest(REQUEST_CITIES, sendRequestCities)
}

function* sendRequestCity(action) {
  const { data: city, error = null } = yield call(getCity, action.params)
  yield put(receiveCity(city, error))
}

export function* watchRequestCity() {
  yield takeLatest(REQUEST_CITY, sendRequestCity)
}

function* sendRequestAllCities(action) {
  const allCities = yield select(getOperationsAllCities)
  if (allCities.length > 0) {
    yield put(receiveAllCities(allCities, allCities.length, null))
  } else {
    const { data, error = null } = yield call(getCities, {})
    const { cities = [], count = 0 } = data
    yield put(receiveAllCities(cities, count, error))
  }
}

export function* watchRequestAllCities() {
  yield takeLatest(REQUEST_ALL_CITIES, sendRequestAllCities)
}

import withEditableWidget from '@/components/DashboardPageV2/Desktop/Widgets/Shared/HOC/withEditableWidget'
import { WidgetDefinition } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/utils/WidgetRegistry'
import {
  MeasurementEdit,
  MeasurementView
} from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Measurement'
import { resourcesTypes } from '@/components/DashboardPageV2/Desktop/Widgets/Resources/types'
import {
  LIVE_QUERY,
  VALUE_CHART
} from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'
import { defaultQueryOptions } from '../Shared/Dynamic/config/defaults'

const ResourcesWidget = withEditableWidget(MeasurementView, MeasurementEdit)

export class ResourcesWidgetDefinition extends WidgetDefinition {
  constructor({
    widgetId,
    title,
    icon,
    measurement,
    queryOptionsList = null,
    component = null,
    defaultPosition = null,
    options = null,
    config = null,
    permissionIds = null,
    denyVisibility = null
  }) {
    const defaultPositionDefault = {
      md: [0, 0, 2, 3, 2, 2]
    }
    const configDefault = {
      query: LIVE_QUERY,
      chart: VALUE_CHART,
      range: null,
      interval: null
    }
    const measurementOrError =
      options?.measurement ||
      measurement ||
      ResourcesWidgetDefinition.paramRequired('measurement')

    const optionsDefault = {
      fixedFactor: 1,
      scaleFactor: 1,
      measurement: measurementOrError,
      queryOptionsList: queryOptionsList || defaultQueryOptions
    }

    const superParams = {
      widgetId,
      title,
      icon,
      component: component ?? ResourcesWidget,
      defaultPosition: defaultPosition ?? defaultPositionDefault,
      config: config ?? configDefault,
      options: options ?? optionsDefault,
      permissionIds: permissionIds,
      visibility: denyVisibility
    }

    if (!superParams.options.queryOptionsList) {
      superParams.options.queryOptionsList = defaultQueryOptions
    }

    super(superParams)
  }

  static paramRequired(param) {
    throw new Error(`ResourcesWidgetDefinition: ${param} is required.`)
  }
}

export const ResourcesWidgetDefinitions = () => {
  const types = resourcesTypes()
  const environmentWidgets = Object.keys(types).map(key => {
    return new ResourcesWidgetDefinition({ ...types[key] })
  })

  return environmentWidgets
}

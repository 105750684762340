export const conversionLookup = {
  temperature: 1,
  water_temperature: 1,
  humidity: 1,
  light: 1,
  ph: 1,
  conductivity: 0.001,
  pfan_output_power: 0.1,
  pfan_vfd_output_frequency: 0.01
}

export const fixedLookup = {
  conductivity: 2,
  ph: 2
}

export const nameLookup = (name, measurements) => {
  return measurements.find(({ id }) => id === name)?.shortName ?? name
}

export const unitLookup = (name, measurements) => {
  return measurements.find(({ id }) => id === name)?.unit ?? ''
}

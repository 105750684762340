import withEditableWidget from '@/components/DashboardPageV2/Desktop/Widgets/Shared/HOC/withEditableWidget'
import { MeasurementEdit } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Measurement'

import { ChartOption, QueryOption } from '../../Shared/Dynamic/config/utils'
import {
  DATASET_QUERY,
  LINE_CHART,
  LAST_7_DAYS,
  HOURLY
} from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'
import { getMeasurements } from '@/reducers/selectors'

import DatasetLine from './DatasetLine'

const lineChart = new ChartOption({
  value: LINE_CHART,
  range: [LAST_7_DAYS],
  interval: [HOURLY]
})

const datasetLineQuery = new QueryOption({
  value: DATASET_QUERY,
  options: [lineChart]
})

export const DailyEnergyConsumptionQueryOptionsList = [datasetLineQuery]

function DailyEnergyConsumptionView(props) {
  const { config, options } = props

  const measurement = options.measurement
  const measurements = getMeasurements()

  const unit = measurements?.find(m => m.id === measurement)?.unit

  if (config.query === DATASET_QUERY) {
    return (
      <DatasetLine unit={unit} config={config} options={options} {...props} />
    )
  }
}

export const DailyEnergyConsumptionWidget = withEditableWidget(
  DailyEnergyConsumptionView,
  MeasurementEdit
)

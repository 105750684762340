export class WidgetRegistry {
  #widgets = {}
  initialized = false
  static staticWidgetDefinitions = []

  constructor(widgetDefinitions = []) {
    for (let widgetDefinition of widgetDefinitions) {
      const widgetDef = widgetDefinition()

      if (widgetDef?.length > 0) {
        for (let wd of widgetDef) {
          this.instantiateWidget(wd)
        }
      } else {
        this.instantiateWidget(widgetDef)
      }
    }
    WidgetRegistry.staticWidgetDefinitions = widgetDefinitions
    this.initialized = true
  }

  instantiateWidget(widgetDefinition) {
    const isInstanceOfWidgetDefinition =
      widgetDefinition instanceof WidgetDefinition

    if (!isInstanceOfWidgetDefinition) {
      throw new Error(
        'WidgetRegistry: widgetDefinition is not an instance of WidgetDefinition.',
        { cause: widgetDefinition }
      )
    }

    this.#widgets[widgetDefinition.widgetId] = widgetDefinition
  }

  get(id) {
    if (!this.#widgets[id]) {
      if (window.ENV.ENV === 'dev') {
        throw new Error(`WidgetRegistry: ${id} is not a registered widget.`)
      }
    }
    return this.#widgets[id]
  }

  reinstantiate() {
    for (let widgetDefinition of WidgetRegistry.staticWidgetDefinitions) {
      const widgetDef = widgetDefinition()

      if (widgetDef?.length > 0) {
        for (let wd of widgetDef) {
          this.instantiateWidget(wd)
        }
      } else {
        this.instantiateWidget(widgetDef)
      }
    }
  }
}

/**
 * Safe way to create a widget definition
 *
 * @param {string} widgetId Required
 * @param {string} title Required
 * @param {string} icon Required. https://fonts.google.com/icons
 * @param {React.Component} component Required
 * @param {string} defaultPosition Required
 * @param {Object} config Unique to each Widget. Optional.
 * @param {Object} options Unique to each type of value format. Optional
 * @param {string[]} permissionIds Optional.
 * @param {Object} visibility Optional.
 * @returns {WidgetDefinition}
 *
 * @throws {Error} if any of the required parameters are missing
 * @throws {Error} if widgetId is null or undefined
 * @throws {Error} if title is null or undefined
 * @throws {Error} if icon is null or undefined
 * @throws {Error} if component is null or undefined
 * @throws {Error} if defaultPosition is null or undefined
 *
 * @example
 * const widgetDefinition = WidgetDefinition({
 *  widgetId: 'temperature',
 *  title: 'Temperature',
 *  icon: 'thermostat',
 *  component: TemperatureWidget,
 *  defaultPosition: {
 *    md: [0, 0, 2, 3, 2, 2]
 *  },
 *  config: {
 *    query: 'live',
 *    chart: 'value',
 *    range: null,
 *    interval: null,
 *    unit: 'celsius'
 *  },
 *  options: {
 *    fixedFactor: 1,
 *    scaleFactor: 1,
 *    measurement: 'temperature',
 *   queryOptionsList: QueryOption[]
 *  },
 *  permissionIds: ['my-permission'],
 *  denyVisibility: []
 * })
 *
 */
export class WidgetDefinition {
  constructor({
    widgetId,
    title,
    icon,
    component,
    defaultPosition,
    config,
    options,
    permissionIds,
    denyVisibility
  }) {
    this.widgetId = widgetId || this.paramRequired('widgetId')
    this.type = 'widget'
    this.title = title || this.paramRequired('title')
    this.icon = icon || this.paramRequired('icon')
    this.component = component || this.paramRequired('component')
    this.defaultPosition =
      defaultPosition || this.paramRequired('defaultPosition')
    this.config = config || null
    this.options = options || null
    this.permissionIds = permissionIds || null
    this.denyVisibility = denyVisibility || null
  }

  paramRequired(param) {
    throw new Error(`WidgetDefinition: ${param} is required.`)
  }
}

import { getIsGodMode } from './PermissionUtils'

// Update this variable with feature flags added in the future
export const ENABLE_DELETE_SENSOR = 'enable_delete_sensor'
export const ENABLE_FLEET_MANAGEMENT = 'enable_fleet_management'
export const ENABLE_MAPS = 'enable_maps'
export const ENABLE_RECOMMENDATIONS_MANAGEMENT =
  'enable_recommendations_management'
export const ENABLE_REPORTS = 'enable_reports'
export const ENABLE_STATUS_PAGE = 'enable_status_page'
export const ENABLE_WHATSAPP = 'enable_whatsapp'
export const ENABLE_DIAGNOSTICS = 'enable_diagnostics'
export const ENABLE_SIMPLIFIED_DASHBOARD = 'enable_simplified_dashboard'

export const AVAILABLE_FEATURE_FLAGS = {
  [ENABLE_DELETE_SENSOR]: ENABLE_DELETE_SENSOR,
  [ENABLE_FLEET_MANAGEMENT]: ENABLE_FLEET_MANAGEMENT,
  [ENABLE_MAPS]: ENABLE_MAPS,
  [ENABLE_RECOMMENDATIONS_MANAGEMENT]: ENABLE_RECOMMENDATIONS_MANAGEMENT,
  [ENABLE_REPORTS]: ENABLE_REPORTS,
  [ENABLE_STATUS_PAGE]: ENABLE_STATUS_PAGE,
  [ENABLE_WHATSAPP]: ENABLE_WHATSAPP,
  [ENABLE_DIAGNOSTICS]: ENABLE_DIAGNOSTICS,
  [ENABLE_SIMPLIFIED_DASHBOARD]: ENABLE_SIMPLIFIED_DASHBOARD
}

export function hasFeatureFlagEnabled(
  currentUser,
  featureFlagId,
  organizationId = null,
  path = null
) {
  const isGodMode = getIsGodMode(currentUser)
  if (isGodMode) return true

  const { featureFlags, organizations } = currentUser
  const username = currentUser?.userName

  if (currentUser?.featureFlags?.length === 0) return false

  if (path === 'profile') {
    return currentUser?.featureFlags?.some(
      featureFlag => featureFlag.featureFlagId === featureFlagId
    )
  }

  if (organizationId) {
    const userBelongsToOrganization = organizations?.some(
      org => org.id === organizationId
    )

    const hasUserLevelPermission = featureFlags?.some(
      featureFlag =>
        featureFlag.featureFlagId === featureFlagId &&
        featureFlag.objectId === username
    )

    const hasOrganizationLevelPermission = featureFlags?.some(
      featureFlag =>
        featureFlag.featureFlagId === featureFlagId &&
        featureFlag.objectId === organizationId
    )

    if (userBelongsToOrganization && hasUserLevelPermission) return true

    if (hasOrganizationLevelPermission) return true

    return false
  }

  return featureFlags?.some(
    featureFlag => featureFlag.featureFlagId === featureFlagId
  )
}

import { I18n } from 'aws-amplify'
import { DateTime } from 'luxon'
import { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import cloneDeep from 'lodash/cloneDeep'

import { requestCrops } from '@/actions/operations/crop'

import { getOperationsCrops, getZonesHierarchy } from '@/reducers/selectors'

import { Box, Flex, Text } from '@/primitives'
import LineGraph from '@/elements/Nivo/Line'

import { graphSerieColors } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/utils'
import { useDashboard } from '@/components/DashboardPageV2/Desktop/context'
import {
  getIntervals,
  getRanges
} from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'
import { SET_DOWNLOADABLE_DATASET } from '@/components/DashboardPageV2/Desktop/state'

import ZoneUtils from '@/Util/ZoneUtils'

function processDliData(data) {
  const response = data.reduce(
    (acc, item, index) => {
      let calculatedData = acc.calculatedData
      const date = DateTime.fromJSDate(new Date(item.x)).toFormat('yyyy-MM-dd')

      if (index !== 0 && acc.date === date) {
        let y = item.y
        y = y + calculatedData[calculatedData.length - 1].y
        item.y = y
      }

      calculatedData.push(item)

      acc['calculatedData'] = calculatedData
      acc['date'] = date

      return acc
    },
    { calculatedData: [], date: null }
  )

  return response.calculatedData
}

function processDecData(data) {
  const response = data.reduce(
    (acc, item, index) => {
      let calculatedData = acc.calculatedData
      const date = DateTime.fromJSDate(new Date(item.x)).toFormat('yyyy-MM-dd')

      if (index !== 0 && acc.date === date) {
        let y = item.y
        y = y + calculatedData[calculatedData.length - 1].y
        item.y = y
      }

      calculatedData.push(item)

      acc['calculatedData'] = calculatedData
      acc['date'] = date

      return acc
    },
    { calculatedData: [], date: null }
  )

  return response.calculatedData
}

export default function Line({
  config,
  unit,
  data,
  queryZones,
  id,
  widgetId,
  calculateDli = false,
  calculateDce = false
}) {
  const params = useParams()
  const dispatch = useDispatch()

  const { state, dispatchState } = useDashboard()

  const crops = getOperationsCrops()
  const zonesHierarchy = getZonesHierarchy()

  const [graphData, setGraphData] = useState([])
  const [bounds, setBounds] = useState(null)

  const striningifiedData = JSON.stringify(data)
  const striningifiedQueryZones = JSON.stringify(queryZones)

  useEffect(() => {
    if (params?.zone?.length > 0) {
      const hierarchy = params?.zone?.split('/')
      dispatch(
        requestCrops({
          filter: {
            siteId: [hierarchy[0]],
            status: ['planned', 'sowed', 'transplanted', 'harvesting']
          }
        })
      )
    }
  }, [params])

  useEffect(() => {
    if (crops?.length > 0) {
      let crop = null
      let testPath = `/${params?.zone}`

      while (crop === null) {
        for (let i = 0; i < crops.length; i++) {
          const zone = ZoneUtils.getZone(
            zonesHierarchy,
            crops[i].siteId,
            crops[i].zoneId
          )
          if (zone?.parentPath === testPath) {
            if (crops[i]?.produceThreshold?.measurements?.length) {
              crop = crops[i]
              break
            }
          }
        }
        if (crop === null) {
          testPath = testPath.split('/').slice(0, -1).join('/')
          if (testPath.length === 0) {
            break
          }
        }
      }

      if (crop) {
        const measurementsLength = crop?.produceThreshold?.measurements?.length
        for (let j = 0; j < measurementsLength; j++) {
          const measurement = crop.produceThreshold.measurements[j]
          if (measurement.key === widgetId) {
            setBounds(measurement.bounds)
            return
          }
        }
      }
    }
  }, [crops])

  useEffect(() => {
    function formatSerieData(serieData) {
      serieData.sort((a, b) => {
        return new Date(a.x) - new Date(b.x)
      })

      if (calculateDli) {
        serieData = processDliData([...serieData])
      }

      if (calculateDce) {
        serieData = processDecData([...serieData])
      }

      return serieData.map(dataPoint => {
        return {
          x: new Date(dataPoint.x),
          y: dataPoint.y
        }
      })
    }

    if (Array.isArray(data)) {
      let graphData = data.map((serie, serieIndex) => ({
        ...serie,
        color: graphSerieColors[serieIndex],
        data: formatSerieData(serie.data)
      }))
      setGraphData(graphData)
    } else {
      const data = JSON.parse(striningifiedData)
      const queryZones = JSON.parse(striningifiedQueryZones)

      let index = 0
      const clonedData = cloneDeep(data)
      let resultData = []
      for (let zone in clonedData) {
        let queryZoneIndex = queryZones.findIndex(
          ({ zoneName }) => zoneName === zone
        )

        if (queryZoneIndex < 0 || queryZoneIndex >= graphSerieColors.length) {
          queryZoneIndex = index
        }

        const zoneData = {
          id: zone,
          color: graphSerieColors[queryZoneIndex],
          data: formatSerieData(clonedData[zone])
        }

        resultData.push(zoneData)
        index += 1

        setGraphData(resultData)
        if (resultData.length > 0) {
          dispatchState({
            type: SET_DOWNLOADABLE_DATASET,
            payload: { id, widgetId, data: resultData, unit }
          })
        }
      }
    }
  }, [
    striningifiedData,
    striningifiedQueryZones,
    calculateDce,
    calculateDli,
    dispatchState,
    id,
    widgetId,
    unit
  ])

  const INTERVAL_FORMATS = {
    minutely: 'hh:mm a',
    hourly: 'MM/dd ha',
    daily: 'MM/dd',
    weekly: 'MM/dd',
    monthly: 'MM/dd/yyyy'
  }

  const formatDate = date => {
    return DateTime.fromMillis(date.getTime(), {
      zone: state.hierarchy.timeZone
    }).toFormat(INTERVAL_FORMATS[config.interval])
  }

  const INTERVAL_PRECISION = {
    minutely: 'minute',
    hourly: 'hour',
    daily: 'day',
    weekly: 'day',
    monthly: 'month'
  }

  const getPrecision = () => {
    return INTERVAL_PRECISION[config.interval]
  }

  const SliceTooltip = ({ slice }) => {
    const groups = slice.points.reduce((acc, point, index) => {
      const groupIndex = Math.floor(index / 4)
      if (!acc[groupIndex]) {
        acc[groupIndex] = []
      }
      acc[groupIndex].push(point)
      return acc
    }, [])

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: 'min-content',
          background: '#192133',
          padding: '1.5em',
          border: '1px solid #394970',
          zIndex: 1,
          gap: '1em'
        }}
      >
        {groups.map((group, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '1em'
            }}
          >
            {group.map(point => (
              <div
                key={point.id}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  color: point.serieColor,
                  padding: '3px 1px'
                }}
              >
                <span
                  style={{
                    whiteSpace: 'nowrap',
                    fontSize: '0.7em'
                  }}
                >
                  {point.serieId}
                </span>
                <span
                  style={{
                    whiteSpace: 'nowrap'
                  }}
                >
                  {formatDate(point.data.x)}
                  {' - '}
                  <strong>
                    {point.data.yFormatted} {unit || ''}
                  </strong>
                </span>
              </div>
            ))}
          </div>
        ))}
      </div>
    )
  }

  const BackgroundLayerPath = ({
    startY,
    endY,
    innerWidth,
    innerHeight,
    fillColor
  }) => {
    if ((startY >= 0 || endY <= innerHeight) && endY > startY) {
      return (
        <path
          d={`
            M ${0} ${Math.max(0, startY)}
            L ${innerWidth} ${Math.max(0, startY)}
            L ${innerWidth} ${Math.min(innerHeight, endY)}
            L ${0} ${Math.min(innerHeight, endY)}
            Z
          `}
          fill={fillColor}
          stroke={'none'}
          opacity={0.15}
          style={{ pointerEvents: 'none' }}
        />
      )
    }
    return null
  }

  const BackgroundLayer = args => {
    if (bounds === null) {
      return
    }

    const { series, innerHeight, innerWidth } = args

    let minY = 100,
      maxY = 0

    series.forEach(s => {
      minY = s.data.reduce((min, next) => Math.min(min, next.data.y), minY)
      maxY = s.data.reduce((max, next) => Math.max(max, next.data.y), maxY)
    })

    let gapY = (maxY - minY) / innerHeight

    const boundColors = [
      [null, 0, '#E41A1D'],
      [0, 1, '#FF7F00'],
      [1, 4, '#59A14F'],
      [4, 5, '#FF7F00'],
      [5, null, '#E41A1D']
    ]

    const getStartY = i => {
      return i === null ? 0 : (maxY - Math.min(bounds[i], maxY)) / gapY
    }

    const getEndY = i => {
      return i === null
        ? innerHeight
        : (maxY - Math.max(bounds[i], minY)) / gapY
    }

    return (
      <Fragment>
        {boundColors.map((b, index) => (
          <BackgroundLayerPath
            key={index}
            startY={getStartY(b[1])}
            endY={getEndY(b[0])}
            innerWidth={innerWidth}
            innerHeight={innerHeight}
            fillColor={b[2]}
          />
        ))}
      </Fragment>
    )
  }

  const hasData = graphData.some(({ data }) => data.length > 0)

  return (
    <Fragment>
      <Flex
        className={'MosaicV2__Tile__Content__SubTitle'}
        alignMainAxis='space-between'
        axisGap={400}
      >
        <Text fontFamily={'mono'} size={200}>
          {getRanges(config.range)?.label} (
          {getIntervals(config.interval)?.label})
        </Text>
        <Text fontFamily={'mono'} size={200}>
          {unit || ''}
        </Text>
      </Flex>

      <Box className={'MosaicV2__Tile__Content__Graph'}>
        <Box className={'MosaicV2__Tile__Content__Chart'}>
          {graphData.length > 0 && hasData && (
            <LineGraph
              data={graphData}
              config={{
                xScale: {
                  type: 'time',
                  precision: getPrecision(),
                  min: 'auto',
                  max: 'auto'
                },
                axisBottom: {
                  tickValues: 6,
                  format: function (value) {
                    return formatDate(value)
                  }
                },
                enableSlices: 'x',
                sliceTooltip: ({ slice }) => {
                  return <SliceTooltip slice={slice} />
                },
                colors: series => series.color,
                layers: [
                  'grid',
                  'areas',
                  BackgroundLayer,
                  'markers',
                  'crosshair',
                  'lines',
                  'slices',
                  'axes',
                  'points',
                  'mesh',
                  'legends'
                ]
              }}
            />
          )}
          {(graphData.length === 0 || !hasData) && (
            <Flex
              alignCrossAxis='center'
              alignMainAxis='center'
              style={{ flex: 1 }}
            >
              <Flex
                direction={'column'}
                alignCrossAxis={'center'}
                style={{ flex: 1 }}
                axisGap={200}
              >
                <Text
                  fontFamily={'mono'}
                  variant={'primary'}
                  tone={500}
                  size={600}
                  fontWeight={700}
                >
                  {I18n.get('No Data')}
                </Text>
              </Flex>
            </Flex>
          )}
        </Box>
      </Box>
    </Fragment>
  )
}

import { I18n } from 'aws-amplify'

const Strings = () => ({
  formLabelId: I18n.get('Device ID'),
  formLabelName: I18n.get('Display name'),
  formLabelTag: I18n.get('Tag'),
  formLabelLatitude: I18n.get('Latitude'),
  formLabelLongitude: I18n.get('Longitude'),
  submitBtn: I18n.get('Enroll device'),
  backToRoom: I18n.get('Back to room'),
  location: I18n.get('Location'),
  enrollDevice: I18n.get('Enroll your device'),
  enrollDeviceSubheading: I18n.get('Confirm the details for your device below. Note that the device ID can not be edited. You can go back to the previous steps to edit the location details.'),
  locationFormLatitude: I18n.get('Latitude'),
  locationFormLongitude: I18n.get('Longitude'),
  cancel: I18n.get('Cancel'),
  currentCoords: I18n.get('Your current coordinates'),
  useCurrentCoords: I18n.get('Use current coordinates'),
  savedCoords: I18n.get('Saved coordinates'),
  updateDeviceCoords: I18n.get('Update device coordinates'),
  coordsDescriptionA: I18n.get('Use your current location to set updated coordinates for '),
  coordsDescriptionB: I18n.get('Stand as close to the device as you can for the most accurate results.'),
  locationDenied: I18n.get('SecondSky Data does not have permission to access to your location. Update your browser settings to enable this feature.'),
  unavailable: I18n.get('Unavailable')
})

export default Strings
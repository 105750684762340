import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { getCognitoData, makeGraphqlRequest } from '../utils'

export const createNvsDump = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createNvsDump(
        $sensorId: String!
        $userId: String!
        $isGreengrass: Boolean!
        $deviceType: String!
      ) {
        createNvsDump(
          sensorId: $sensorId
          userId: $userId
          isGreengrass: $isGreengrass
          deviceType: $deviceType
        ) {
          sensorId
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        device: {},
        error: `NVS dump not sent: ${error}`
      }
    }

    return { device: data?.createNvsDump, error }
  } catch (err) {
    return { device: {}, error: err.message }
  }
}

export const createBatchNvsDumpRequest = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createBatchNvsDumpRequest($devices: [NvsDumpInputType!]!) {
        createBatchNvsDumpRequest(devices: $devices) {
          sensorId
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        devices: [],
        error: `NVS dumps not requested: ${error}`
      }
    }

    return { devices: data?.createBatchNvsDumpRequest, error }
  } catch (err) {
    return { devices: {}, error: err.message }
  }
}

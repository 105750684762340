import { useState, useEffect, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import ReactSelect from 'react-select'

import {
  createReturnItem,
  updateReturnItem
} from '../../../../../../actions/operations/return'

import { getOperationsProducts } from '../../../../../../reducers/selectors'

import {
  Box,
  Button,
  Flex,
  Label,
  Text,
  Input,
  Separator,
  Select
} from '../../../../../../primitives'

import useDeviceSize from '../../../../../../hooks/useDeviceSize'
import { getValidationErrorMap } from '../../../../../../Util/GeneralUtils'
import InputError from '../../../../../../elements/InputError'

import { UPDATE_RETURN_ITEM } from '../state'
import { initialState, reducer, SET_STATE, UPDATE_INPUT } from './state'

import { RETURN_ITEM_REASONS } from './utils'
import { FIELDS, SCHEMA } from './config'
import Strings from '../../Strings'

function ReturnItemForm({
  parent,
  returnItem,
  closeForm,
  dispatchParentFormState
}) {
  const dispatch = useDispatch()
  const isMobile = useDeviceSize('mobile')

  const strings = Strings()

  const products = getOperationsProducts()

  const [errors, setErrors] = useState({})
  const [formState, formStateDispatch] = useReducer(reducer, initialState)
  const [organizationId, setOrganizationId] = useState(parent.organizationId)

  const reasons = RETURN_ITEM_REASONS(strings)

  useEffect(() => {
    formStateDispatch({
      type: SET_STATE,
      state: {
        ...returnItem
      }
    })
  }, [returnItem])

  useEffect(() => {
    if (parent.organizationId !== organizationId) {
      formStateDispatch({
        type: UPDATE_INPUT,
        name: 'productId',
        value: ''
      })
      setOrganizationId(parent.organizationId)
    }
  }, [parent.organizationId])

  const isNewItem = () => {
    let currentIndex = parent.returnItems.findIndex(
      item => item.id === returnItem.id
    )
    return currentIndex < 0
  }

  const handleInput = e => {
    const { name, value } = e.currentTarget
    formStateDispatch({ type: UPDATE_INPUT, name, value })
  }

  const handleNumericInput = e => {
    const { name, value } = e.currentTarget
    const newValue = value.length > 0 ? Number(value) : ''
    formStateDispatch({ type: UPDATE_INPUT, name, value: newValue })
  }

  const handleProductInput = option => {
    formStateDispatch({
      type: UPDATE_INPUT,
      name: 'productId',
      value: option.value
    })
  }

  const getProduct = productId => {
    if (productId) {
      const productIndex = products.findIndex(p => p.id === productId)
      if (productIndex >= 0) {
        return products[productIndex]
      }
    }
    return null
  }

  const getProductValue = () => {
    const product = getProduct(formState.productId)
    if (product) {
      const { id, name, weight } = product
      const label = `${name} (${weight}${strings.grams})`
      return {
        value: id,
        label: label
      }
    }
    return {
      label: strings.selectDefault,
      value: ''
    }
  }

  const filterProducts = () => {
    return products
      .filter(p => p.organizationId === parent.organizationId)
      .map(p => ({
        value: p.id,
        label: p.name
      }))
  }

  const createItem = payload => {
    dispatch(
      createReturnItem({
        ...payload,
        returnId: parent.id
      })
    )
  }

  const updateItem = payload => {
    dispatch(
      updateReturnItem({
        ...payload,
        returnItemId: returnItem.id,
        returnId: parent.id
      })
    )
  }

  const saveReturnItem = async () => {
    try {
      const validFormState = await SCHEMA.validate(formState, {
        abortEarly: false
      })

      if (parent?.id) {
        let currentIndex = parent.returnItems.findIndex(
          item => item.id === returnItem.id
        )
        if (currentIndex >= 0) {
          updateItem(validFormState)
        } else {
          createItem(validFormState)
        }
      }
      dispatchParentFormState({
        type: UPDATE_RETURN_ITEM,
        returnItem: validFormState
      })
      closeForm()
    } catch (error) {
      setErrors(getValidationErrorMap(error))
    }
  }

  const getHeader = () => {
    if (isNewItem()) {
      return strings.createFormHeader
    }
    return strings.updateFormHeader
  }

  return (
    <Box>
      <Separator className='Separator__Line' />
      <Text size={400} fontWeight={500} tone={600}>
        {getHeader()}
      </Text>
      <Flex
        axisGap={300}
        alignMainAxis='flex-start'
        direction={isMobile ? 'column' : 'row'}
        className='Operations__Fields'
      >
        <Label style={{ flex: '3 1 0' }}>
          <Flex axisGap={300} direction='column'>
            <Text variant='page' tone={700}>
              {strings.product}
            </Text>
            <ReactSelect
              className='Operations__Form__Select'
              classNamePrefix={'ReactSelect__Single'}
              name={FIELDS.productId}
              value={getProductValue()}
              onChange={handleProductInput}
              isMulti={false}
              placeholder={strings.selectDefault}
              options={filterProducts()}
              isSearchable={true}
            />
            <InputError error={errors?.productId} />
          </Flex>
        </Label>
        <Label style={{ flex: '1 1 0' }}>
          <Text variant='page' tone={700}>
            {strings.quantityReturned}
          </Text>
          <Input
            className='Operations__Input'
            type='number'
            name={FIELDS.quantityReturned}
            value={formState.quantityReturned}
            onChange={handleNumericInput}
          />
          <InputError error={errors?.quantityReturned} />
        </Label>
        <Label>
          <Flex axisGap={300} direction='column'>
            <Text variant='page' tone={700}>
              {strings.reason}
            </Text>
            <Select
              className='Operations__Select'
              name={FIELDS.reason}
              value={formState.reason}
              onChange={handleInput}
            >
              <option default value=''>
                {strings.selectDefault}
              </option>
              {Object.entries(reasons).map(([id, name]) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Select>
            <InputError error={errors?.reason} />
          </Flex>
        </Label>
      </Flex>
      <Flex alignMainAxis={'flex-end'} style={{ marginTop: '20px' }}>
        <Button
          variant='warning'
          size='small'
          iconBefore='cancel'
          onClick={closeForm}
          style={{ marginRight: '10px' }}
        >
          <Text fontWeight={700} textTransform='uppercase'>
            {strings.formCancelBtn}
          </Text>
        </Button>
        <Button
          variant='info'
          size='small'
          iconBefore='edit'
          onClick={saveReturnItem}
        >
          <Text fontWeight={700} textTransform='uppercase'>
            {strings.formSaveBtn}
          </Text>
        </Button>
      </Flex>
    </Box>
  )
}

export default ReturnItemForm

import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { getCognitoData, makeGraphqlRequest } from '../utils'

/*
 * Queries
 */

export const getThresholdsById = async params => {
  try {
    const query = /* GraphQL */ `
      query getThresholdsById($id: String!) {
        getThresholdsById(id: $id) {
          id
          rootId
          measurementId
          message
          interval
          type
          measurement {
            id
            description
            shortName
            unit
          }
          lower {
            notification
            caution
            warning
          }
          lowerMessage {
            notification
            caution
            warning
          }
          upper {
            notification
            caution
            warning
          }
          upperMessage {
            notification
            caution
            warning
          }
        }
      }
    `

    const { id, idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken,
      id
    )

    if (error) {
      return {
        thresholds: null,
        error: `Thresholds not fetched: ${error}`
      }
    }

    return { thresholds: data?.getThresholdsById, error: null }
  } catch (err) {
    return { thresholds: null, error: err.message }
  }
}

export const getThreshold = async params => {
  try {
    const query = /* GraphQL */ `
      query getThreshold($id: String!, $measurement: String!) {
        getThreshold(id: $id, measurement: $measurement) {
          id
          rootId
          measurementId
          message
          interval
          type
          measurement {
            id
            description
            unit
          }
          lower {
            notification
            caution
            warning
          }
          lowerMessage {
            notification
            caution
            warning
          }
          upper {
            notification
            caution
            warning
          }
          upperMessage {
            notification
            caution
            warning
          }
        }
      }
    `

    const { id, idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken,
      id
    )

    if (error) {
      return {
        threshold: null,
        error: `Threshold not fetched: ${error}`
      }
    }

    return { threshold: data?.getThreshold, error: null }
  } catch (err) {
    return { threshold: null, error: err.message }
  }
}

/*
 * Mutations
 */

export const createThreshold = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createThreshold(
        $id: String!
        $rootId: String!
        $measurementId: String!
        $message: String
        $interval: Int
        $type: String
        $lower: ThresholdLimitInput
        $lowerMessage: ThresholdLimitMessageInput
        $upper: ThresholdLimitInput
        $upperMessage: ThresholdLimitMessageInput
      ) {
        createThreshold(
          id: $id
          rootId: $rootId
          measurementId: $measurementId
          message: $message
          interval: $interval
          type: $type
          lower: $lower
          lowerMessage: $lowerMessage
          upper: $upper
          upperMessage: $upperMessage
        ) {
          id
          rootId
          measurementId
          interval
          type
          message
          measurement {
            id
            description
            unit
          }
          lower {
            notification
            caution
            warning
          }
          lowerMessage {
            notification
            caution
            warning
          }
          upper {
            notification
            caution
            warning
          }
          upperMessage {
            notification
            caution
            warning
          }
        }
      }
    `

    const { id, idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken,
      id
    )

    if (error) {
      return {
        threshold: null,
        error: `Threshold not created: ${error}`
      }
    }

    return { threshold: data?.createThreshold, error: null }
  } catch (err) {
    return { threshold: null, error: err.message }
  }
}

export const updateThreshold = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateThreshold(
        $id: String!
        $rootId: String!
        $measurementId: String!
        $message: String
        $interval: Int
        $type: String
        $lower: ThresholdLimitInput
        $lowerMessage: ThresholdLimitMessageInput
        $upper: ThresholdLimitInput
        $upperMessage: ThresholdLimitMessageInput
      ) {
        updateThreshold(
          id: $id
          rootId: $rootId
          measurementId: $measurementId
          message: $message
          interval: $interval
          type: $type
          lower: $lower
          lowerMessage: $lowerMessage
          upper: $upper
          upperMessage: $upperMessage
        ) {
          id
          rootId
          measurementId
          interval
          type
          message
          measurement {
            id
            description
            unit
          }
          lower {
            notification
            caution
            warning
          }
          lowerMessage {
            notification
            caution
            warning
          }
          upper {
            notification
            caution
            warning
          }
          upperMessage {
            notification
            caution
            warning
          }
        }
      }
    `

    const { id, idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken,
      id
    )

    if (error) {
      return {
        threshold: null,
        error: `Threshold not updated: ${error}`
      }
    }

    return { threshold: data?.updateThreshold, error: null }
  } catch (err) {
    return { threshold: null, error: err.message }
  }
}

export const deleteThreshold = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation deleteThreshold($id: String!, $measurementId: String!) {
        deleteThreshold(id: $id, measurementId: $measurementId) {
          id
          rootId
          measurementId
          interval
          type
          lower {
            notification
            caution
            warning
          }
          upper {
            notification
            caution
            warning
          }
        }
      }
    `

    const { id, idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken,
      id
    )

    if (error) {
      return {
        threshold: null,
        error: `Threshold not deleted: ${error}`
      }
    }

    return { threshold: data?.deleteThreshold, error: null }
  } catch (err) {
    return { threshold: null, error: err.message }
  }
}

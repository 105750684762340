import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  startCoreDeviceUpdates,
  clearUpdates
} from '@/actions/management/greengrass'
import {
  getDeviceUpdatesStarted,
  getDeviceUpdatesLoading
} from '@/reducers/selectors'

import { Flex, Button, Slot, Text, Box, Separator, Loader } from '@/primitives'
import { Dialog } from '@/elements'

import Strings from '../Strings'

import { useFleetManagement } from '../context'

const CoreDeviceUpdateModal = ({ showModal, toggleModal }) => {
  const dispatch = useDispatch()
  const strings = Strings()
  const updatesStarted = getDeviceUpdatesStarted()
  const loading = getDeviceUpdatesLoading()

  const {
    state: { sensorsByZone }
  } = useFleetManagement()

  useEffect(() => {
    if (updatesStarted) {
      toggleModal()
      dispatch(clearUpdates)
    }
  }, [updatesStarted, dispatch, toggleModal])

  const onClickClose = e => {
    e.preventDefault()
    toggleModal()
  }

  const zoneIds = sensorsByZone
    .filter(({ selected, type }) => type === 'greengrass' && selected)
    .map(({ id }) => id)

  function handleModalSubmit() {
    dispatch(
      startCoreDeviceUpdates({
        ids: zoneIds
      })
    )
  }

  return (
    <Dialog
      type='offcanvas'
      className='Devices__OffCanvas'
      open={showModal}
      onOpenChange={toggleModal}
      style={{ zIndex: 3 }}
    >
      <Slot name='title'>
        <Text size={300} fontWeight={700}>
          {strings.coreFormTitle}
        </Text>
      </Slot>
      <Slot name='content'>
        <Loader isLoading={loading}>
          <Flex axisGap={500} direction='column'>
            <Separator />
            <Flex axisGap={300} direction='column'>
              <Text variant='page' tone={900} size={100}>
                {strings.coreDevicesForUpdate}
              </Text>

              <Flex axisGap={100} direction='column'>
                {zoneIds?.map(id => (
                  <Box key={id}>{id}</Box>
                ))}
              </Flex>
            </Flex>
          </Flex>
        </Loader>
      </Slot>

      <Slot name='actions'>
        <Flex alignMainAxis='space-between'>
          <Button size='small' variant='page' onClick={onClickClose}>
            {strings.close}
          </Button>
          <Button
            loading={loading}
            size='small'
            variant='primary'
            onClick={handleModalSubmit}
          >
            {strings.submit}
          </Button>
        </Flex>
      </Slot>
    </Dialog>
  )
}

export default CoreDeviceUpdateModal

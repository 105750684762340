import { Column, Row, Slot, Table } from '@/primitives'

import ThresholdTableRow from './TableRow'
import Strings from '../../Strings'

const ThresholdTable = ({ basePath, thresholds, onDeleteThreshold }) => {
  const { tableActionsColumn, tableHeaderMeasurement, tableHeaderMessage } =
    Strings()

  return (
    <Table>
      <Slot name='head'>
        <Row>
          <Column>{tableHeaderMeasurement}</Column>
          <Column>{tableHeaderMessage}</Column>
          <Column>{tableActionsColumn}</Column>
        </Row>
      </Slot>
      <Slot name='body'>
        {thresholds?.length > 0 &&
          thresholds.map(threshold => (
            <ThresholdTableRow
              key={`${threshold.id}-${threshold.measurementId}`}
              basePath={basePath}
              threshold={threshold}
              onDeleteThreshold={onDeleteThreshold}
            />
          ))}
      </Slot>
    </Table>
  )
}

export default ThresholdTable

import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import {
  getCognitoData,
  getCognitoIdentityId,
  makeGraphqlRequest,
  makeGraphqlRequestV2
} from '../utils'

export const getDevice = async params => {
  try {
    const query = /* GraphQL */ `
      query getDevice($deviceId: String!) {
        getDevice(deviceId: $deviceId) {
          id
          name
          sensorType
          zoneId
          zonePath
          proofOfPossession
          latitude
          longitude
          tag
          createdTimestamp
          isIoT
          hardwareVersion
          isGreengrass
          config
          lastMoveAt
          notInUse
          sleepCycleMinutes
        }
      }
    `

    const operation = graphqlOperation(query, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)
    return data?.getDevice
  } catch (err) {
    return err.message
  }
}

// getDeviceFirmware is currently only used on the /sensor/envirosense page, not accessible through the site navigation

export const getDeviceFirmware = async params => {
  try {
    const { id, sensorType, buildType, version, flashScriptVersion } = params
    const query = /* GraphQL */ `
      query getDeviceFirmware(
        $deviceId: String!
        $deviceType: String!
        $buildType: String!
        $version: String!
        $flashScriptVersion: String
      ) {
        getDeviceFirmware(
          deviceId: $deviceId
          deviceType: $deviceType
          buildType: $buildType
          version: $version
          flashScriptVersion: $flashScriptVersion
        ) {
          fileName
          fileUrl
        }
      }
    `

    const payload = {
      deviceId: id,
      deviceType: sensorType,
      buildType,
      version,
      flashScriptVersion
    }

    const operation = graphqlOperation(query, payload)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.getDeviceFirmware
  } catch (err) {
    return err.message
  }
}

export const getSupplierDevice = async params => {
  try {
    const query = /* GraphQL */ `
      query getSupplierDevice($deviceId: String!) {
        getSupplierDevice(deviceId: $deviceId) {
          deviceId
          name
          type
          hardwareVersion
          firmwareVersion
          proofOfPossession
        }
      }
    `

    const operation = graphqlOperation(query, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.getSupplierDevice
  } catch (err) {
    throw err
  }
}

export const enrollSupplierDevice = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation enrollSupplierDevice(
        $zoneId: String!
        $deviceId: String!
        $proofOfPossession: String!
        $name: String!
        $type: String!
        $latitude: Float
        $longitude: Float
        $tag: String
        $isIoT: Boolean
        $isGreengrass: Boolean
        $hardwareVersion: String
        $config: GraphQLJSON
        $notInUse: Boolean
        $sleepCycleMinutes: Int
      ) {
        enrollSupplierDevice(
          zoneId: $zoneId
          deviceId: $deviceId
          proofOfPossession: $proofOfPossession
          name: $name
          type: $type
          latitude: $latitude
          longitude: $longitude
          tag: $tag
          isIoT: $isIoT
          isGreengrass: $isGreengrass
          hardwareVersion: $hardwareVersion
          config: $config
          notInUse: $notInUse
          sleepCycleMinutes: $sleepCycleMinutes
        ) {
          id
          name
          sensorType
          zoneId
          zonePath
          proofOfPossession
          latitude
          longitude
          tag
          createdTimestamp
          isIoT
          isGreengrass
          config
          hardwareVersion
          lastMoveAt
          notInUse
          sleepCycleMinutes
        }
      }
    `
    const operation = graphqlOperation(mutation, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.enrollSupplierDevice
  } catch (err) {
    throw err
  }
}

export const unenrollSupplierDevice = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation unenrollSupplierDevice($deviceId: String!) {
        unenrollSupplierDevice(deviceId: $deviceId) {
          id
          name
          sensorType
          zoneId
          zonePath
          proofOfPossession
          latitude
          longitude
          tag
          createdTimestamp
          isIoT
          isGreengrass
          config
          hardwareVersion
          lastMoveAt
          notInUse
          sleepCycleMinutes
        }
      }
    `

    const operation = graphqlOperation(mutation, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)
    return data?.unenrollSupplierDevice
  } catch (err) {
    throw err
  }
}

export const attachCalibrationPolicyToUser = async () => {
  try {
    const mutation = /* GraphQL */ `
      mutation attachCalibrationPolicyToUser($identityId: String!) {
        attachCalibrationPolicyToUser(identityId: $identityId) {
          name
          identityId
          message
        }
      }
    `

    const { id, idToken } = await getCognitoData()
    const identityId = await getCognitoIdentityId()
    const operation = graphqlOperation(mutation, { identityId })
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken,
      id
    )

    if (error) {
      return {
        policy: null,
        error: `IoT policy not attached: ${error}`
      }
    }

    return { policy: data?.attachPolicyToUser, error: null }
  } catch (err) {
    return { policy: null, error: err.message }
  }
}

export const createDevice = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createDevice(
        $zoneId: String!
        $id: String!
        $name: String!
        $type: String!
        $latitude: Float
        $longitude: Float
        $tag: String
        $isIoT: Boolean
        $isGreengrass: Boolean
        $config: GraphQLJSON
        $hardwareVersion: String
        $notInUse: Boolean
        $sleepCycleMinutes: Int
      ) {
        createDevice(
          zoneId: $zoneId
          id: $id
          name: $name
          type: $type
          latitude: $latitude
          longitude: $longitude
          tag: $tag
          isIoT: $isIoT
          isGreengrass: $isGreengrass
          config: $config
          hardwareVersion: $hardwareVersion
          notInUse: $notInUse
          sleepCycleMinutes: $sleepCycleMinutes
        ) {
          id
          name
          sensorType
          zoneId
          zonePath
          proofOfPossession
          latitude
          longitude
          tag
          createdTimestamp
          isIoT
          isGreengrass
          config
          hardwareVersion
          lastMoveAt
          notInUse
          sleepCycleMinutes
        }
      }
    `

    const operation = graphqlOperation(mutation, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.createDevice
  } catch (err) {
    return err.message
  }
}

export const updateDevice = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateDevice(
        $zoneId: String!
        $zonePath: String!
        $id: String!
        $name: String!
        $latitude: Float
        $longitude: Float
        $tag: String
        $type: String
        $hardwareVersion: String
        $notInUse: Boolean
        $sleepCycleMinutes: Int
      ) {
        updateDevice(
          zoneId: $zoneId
          zonePath: $zonePath
          id: $id
          name: $name
          latitude: $latitude
          longitude: $longitude
          tag: $tag
          type: $type
          hardwareVersion: $hardwareVersion
          notInUse: $notInUse
          sleepCycleMinutes: $sleepCycleMinutes
        ) {
          id
          name
          sensorType
          zoneId
          zonePath
          proofOfPossession
          latitude
          longitude
          tag
          createdTimestamp
          config
          hardwareVersion
          lastMoveAt
          notInUse
          sleepCycleMinutes
        }
      }
    `

    const operation = graphqlOperation(mutation, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.updateDevice
  } catch (err) {
    return err.message
  }
}

export const deleteDevice = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation deleteDevice($id: String!, $zoneId: String!) {
        deleteDevice(id: $id, zoneId: $zoneId) {
          id
        }
      }
    `

    const operation = graphqlOperation(mutation, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.deleteDevice
  } catch (err) {
    return err.message
  }
}

export const updateDeviceModbusConfig = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateDeviceModbusConfig(
        $deviceId: String!
        $config: GraphQLJSON
      ) {
        updateDeviceModbusConfig(deviceId: $deviceId, config: $config) {
          id
          config
        }
      }
    `

    const operation = graphqlOperation(mutation, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.updateDeviceModbusConfig
  } catch (err) {
    throw err.message
  }
}

export const regenerateDeviceFirmware = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation regenerateDeviceFirmware($deviceId: String!) {
        regenerateDeviceFirmware(deviceId: $deviceId) {
          id
        }
      }
    `

    const operation = graphqlOperation(mutation, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.regenerateDeviceFirmware
  } catch (err) {
    return err.message
  }
}

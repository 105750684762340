import { I18n } from 'aws-amplify'
import { Box, FlexV2, Text } from '@/primitives'

import { hashString } from '@/Util/GeneralUtils'

const STRINGS = () => ({
  title: I18n.get('Privacy Policy'),
  lastUpdated: I18n.get('Last Updated:'),
  intro: I18n.get(
    'Welcome to SecondSky Data, the agricultural data visualization platform. Your privacy is important to us. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information. This policy is designed to be compliant with the General Data Protection Regulation (GDPR) of the EU, relevant US federal and state privacy laws, and the Personal Data Protection Law of Saudi Arabia.'
  ),
  parts: [
    {
      title: I18n.get('1. Information Collection and Use'),
      texts: [
        I18n.get(
          'Personal Data: We collect personal data such as name, email address, and location when you register on SecondSky Data. This information is used to provide and improve our services.'
        ),
        I18n.get(
          'Agricultural Data: We collect agricultural data for visualization and analysis. This data may include geographic, environmental, crop, yield, and other operational information.'
        )
      ]
    },
    {
      title: I18n.get('2. Consent'),
      texts: [
        I18n.get(
          'By using SecondSky Data, you consent to the collection and use of your information as outlined in this policy. For users in the EU, we comply with GDPR requirements for explicit consent.'
        )
      ]
    },
    {
      title: I18n.get('3. Data Sharing and Disclosure'),
      texts: [
        I18n.get(
          'We do not sell your personal data. We may share your information with third parties for the purposes of improving our services, complying with the law, or in the event of a business transfer.'
        ),
        I18n.get(
          'Cross-Border Data Transfers: Your data may be stored and processed in any country where we have operations or where we engage service providers.'
        )
      ]
    },
    {
      title: I18n.get('4. Data Security'),
      texts: [
        I18n.get(
          'We implement appropriate technical and organizational measures to protect your data from unauthorized access, disclosure, alteration, and destruction.'
        )
      ]
    },
    {
      title: I18n.get('5. Your Rights'),
      texts: [
        I18n.get(
          'EU Users: Under the GDPR, you have the right to access, correct, delete, or restrict the processing of your personal data.'
        ),
        I18n.get(
          'US Users: Depending on your state, you may have specific rights regarding access to and control of your personal data.'
        ),
        I18n.get(
          'Saudi Arabian Users: In accordance with the Personal Data Protection Law, you have rights regarding the processing of your personal data.'
        )
      ]
    },
    {
      title: I18n.get('6. Data Retention'),
      texts: [
        I18n.get(
          'We retain your personal data only as long as necessary to provide you with our services and for legitimate and essential business purposes.'
        )
      ]
    },
    {
      title: I18n.get('7. Changes to This Policy'),
      texts: [
        I18n.get(
          'We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new policy on this page.'
        )
      ]
    },
    {
      title: I18n.get('8. Contact Us'),
      texts: [
        I18n.get(
          'If you have any questions about this Privacy Policy, please contact us at support@secondsky.com'
        )
      ]
    }
  ]
})

const LAST_UPDATED = '29/01/2024'

export default function PrivacyPolicy() {
  const strings = STRINGS()
  return (
    <FlexV2
      direction='column'
      axisGap={100}
      style={{ maxWidth: '800px', margin: '0 auto' }}
    >
      <Text as='h3' style={{ marginBottom: '0.2em' }}>
        {strings.title}
      </Text>
      <Text as='p' tone={800} variant={'page'}>
        {strings.lastUpdated} {LAST_UPDATED}
      </Text>
      <Box style={{ marginTop: '1em', marginBottom: '1.5em' }}>
        <Text>{strings.intro}</Text>
      </Box>
      {strings.parts.map(part => (
        <FlexV2
          direction='column'
          axisGap={200}
          style={{ marginBottom: '1.5em' }}
          key={hashString(part.title)}
        >
          <Text as='h5' style={{ marginBottom: '0.5em' }}>
            {part.title}
          </Text>
          {part.texts.map(text => (
            <Text key={hashString(text)}>{text}</Text>
          ))}
        </FlexV2>
      ))}
    </FlexV2>
  )
}

import { Fragment, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  Flex,
  Button,
  Cell,
  Text,
  Column,
  Row,
  Slot,
  Table,
  Loader
} from '@/primitives'

import { Pagination } from '@/elements'

import HeaderV2 from '@/components/Operations/Shared/HeaderV2'

import { requestStorageFacilities } from '@/actions/operations/storageFacility'

import {
  getCurrentUser,
  getOperationsStorageFacilities,
  getOperationsStorageFacilitiesCount,
  getOperationsLoading
} from '@/reducers/selectors'

import OrganizationsFilter from '../../Shared/Filters/OrganizationsFilter'

import history from '../../../../history'

import useMediaQuery from '@/hooks/useMediaQuery'

import {
  hasEditPermissions,
  RESOURCE_TYPE_OPERATIONS,
  RESOURCE_TYPE_OPERATIONS_MANAGEMENT
} from '@/Util/PermissionUtils'

import Strings from './strings'

function StorageFacilityTable({ state, dispatchState, modulePath }) {
  const dispatch = useDispatch()

  const currentUser = getCurrentUser()
  const storageFacilities = getOperationsStorageFacilities()
  const storageFacilitiesCount = getOperationsStorageFacilitiesCount()
  const loading = getOperationsLoading()

  const isAboveCustomBreakpoint = useMediaQuery('min-width: 620px')

  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(9)
  const [tableState, setTableState] = useState(state)
  const [canEdit, setCanEdit] = useState(false)
  const [fetchCalled, setFetchCalled] = useState(false)

  const strings = Strings()

  const fetchStorageFacilities = useCallback(
    page => {
      const { organizations } = state
      if (organizations.length > 0) {
        dispatch(
          requestStorageFacilities({
            filter: {
              organizationId: organizations
            },
            limit: pageSize,
            offset: page - 1
          })
        )
        setFetchCalled(true)
      }
    },
    [state, pageSize, dispatch]
  )

  const changePage = useCallback(
    newPage => {
      dispatchState({ page: newPage })
      setFetchCalled(false)
    },
    [dispatchState]
  )

  useEffect(() => {
    if (currentUser.userName) {
      setCanEdit(
        hasEditPermissions(
          currentUser,
          RESOURCE_TYPE_OPERATIONS,
          RESOURCE_TYPE_OPERATIONS_MANAGEMENT
        )
      )
    }
  }, [currentUser])

  useEffect(() => {
    if (
      tableState.organizations !== state.organizations ||
      tableState.page !== state.page
    ) {
      if (!fetchCalled) {
        fetchStorageFacilities(state.page)
      }
    }
  }, [state, tableState, fetchStorageFacilities, fetchCalled])

  useEffect(() => {
    setTableState({ ...state })
  }, [state])

  const onCreate = () => history.push(`${modulePath}/create`)
  const onEdit = id => history.push(`${modulePath}/update/${id}`)

  const onShippingMethods = (organizationId, id) => {
    dispatchState({
      state: {
        organizationId: organizationId,
        storageFacilityId: id
      }
    })
    history.push(`${modulePath}/shipping-methods`)
  }

  return (
    <Fragment>
      <HeaderV2
        title={strings.manageStorageFacilities}
        buttonText={strings.addStorageFacility}
        buttonCallback={onCreate}
        buttonIcon={'add'}
        showButton={canEdit}
      >
        <OrganizationsFilter state={state} dispatchState={dispatchState} />
      </HeaderV2>
      <Loader isLoading={loading}>
        {storageFacilitiesCount === 0 && (
          <Flex className={'Operations__Table__Empty'}>
            <Text>{strings.noEntries}</Text>
          </Flex>
        )}
        {storageFacilitiesCount > 0 && (
          <Table aria-colcount='3' className='Operations__Table'>
            <Slot name='head'>
              <Row>
                <Column>{strings.name}</Column>
                <Column>{strings.location}</Column>
                <Column />
              </Row>
            </Slot>
            <Slot name='body'>
              {storageFacilities.map(
                ({ id, organizationId, name, country, region, city }) => (
                  <Row key={id}>
                    <Cell columnName={strings.name}>{name}</Cell>
                    <Cell columnName={strings.location}>
                      {country.name}
                      <span>
                        {city.name} ({region.name})
                      </span>
                    </Cell>
                    <Cell className='Operations__Table__Column__Actions'>
                      {canEdit && (
                        <Button
                          variant='info'
                          size='small'
                          iconBefore='edit'
                          onClick={() => onEdit(id)}
                        />
                      )}
                      <Button
                        variant='info'
                        size='small'
                        iconBefore='edit_note'
                        onClick={() => onShippingMethods(organizationId, id)}
                      >
                        <Text fontWeight={700} textTransform='uppercase'>
                          {strings.shippingMethods}
                        </Text>
                      </Button>
                    </Cell>
                  </Row>
                )
              )}
            </Slot>
          </Table>
        )}
        {storageFacilitiesCount > pageSize && (
          <Pagination
            type={isAboveCustomBreakpoint ? 'simple' : 'compact'}
            showPageCount={isAboveCustomBreakpoint}
            totalItems={storageFacilitiesCount}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            page={tableState.page}
            setPage={changePage}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        )}
      </Loader>
    </Fragment>
  )
}

export default StorageFacilityTable

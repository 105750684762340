import { STATUS_HIERARCHY, getIconColorByStatus } from '../utils'

const ClusterRenderer = {
  render({ count, position, markers }, stats, map) {
    const statusIndex = markers.reduce((index, marker) => {
      const markerStatusIndex = STATUS_HIERARCHY.indexOf(marker.status)
      if (markerStatusIndex > index) {
        return markerStatusIndex
      }
      return index
    }, 0)

    const title = `Cluster of ${count} devices`
    const zIndex = Number(window.google.maps.Marker.MAX_ZINDEX) + count

    const color = getIconColorByStatus(STATUS_HIERARCHY[statusIndex])

    const div = document.createElement('div')
    div.classList.add('clusterMarker')
    div.style.color = color
    div.style.borderColor = color
    div.innerHTML = count

    return new window.google.maps.marker.AdvancedMarkerElement({
      map,
      position,
      zIndex,
      title,
      content: div
    })
  }
}

export default ClusterRenderer

export const convertToSalinity = ({ water_temperature, conductivity }) => {
  const r = Math.sqrt(
    conductivity /
      42914 /
      (0.6766097 +
        water_temperature *
          (0.0200564 +
            water_temperature *
              (0.0001104259 +
                water_temperature *
                  (-0.00000069698 + water_temperature * 0.0000000010031))))
  )
  const d =
    (0.0005 +
      r *
        (-0.0056 +
          r * (-0.0066 + r * (-0.0375 + r * (0.0636 + r * -0.0144))))) *
    ((water_temperature - 15.0) / (1.0 + 0.0162 * (water_temperature - 15.0)))
  return (
    0.008 +
    r * (-0.1692 + r * (25.3851 + r * (14.0941 + r * (-7.0261 + r * 2.7081)))) +
    d
  )
}

export const salinityMeasurements = ['water_temperature', 'conductivity']

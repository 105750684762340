import { makeOperationsApiCall } from './utils'

export const getSales = async params => {
  try {
    const query = /* GraphQL */ `
      query getSales(
        $filter: GetSalesFilter
        $order: OrderEnum
        $offset: Int
        $limit: Int
      ) {
        getSales(
          filter: $filter
          order: $order
          offset: $offset
          limit: $limit
        ) {
          sales {
            id
            organizationId
            userId
            orderDate
            purchaseOrder
            customer {
              id
              name
            }
            branch {
              id
              name
            }
            status
            currency {
              code
            }
            invoiceNumber
            paymentMethodId
            paymentTermId
            invoiceStatus
            invoiceTotal
            invoiceTax
            amountPaid
            usdRate
            saleShipments {
              id
              dueDate
              deliveryDate
              storageFacility {
                name
              }
            }
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getSales',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getSale = async params => {
  try {
    const query = /* GraphQL */ `
      query getSale($saleId: String!) {
        getSale(saleId: $saleId) {
          id
          organizationId
          userId
          orderDate
          purchaseOrder
          customerId
          branchId
          status
          currencyId
          currency {
            code
            usdRate
          }
          invoiceNumber
          invoiceTotal
          paymentMethodId
          paymentTermId
          invoiceStatus
          amountPaid
          usdRate
          payments {
            id
            dateReceived
            amount
            usdRate
          }
          saleItems {
            id
            saleId
            product {
              id
              name
              weight
            }
            quantityOrdered
            unitPrice
            discount
            tax
          }
          saleShipments {
            id
            saleId
            storageFacilityId
            shippingMethodId
            dueDate
            deliveryDate
            status
            price
            discount
            tax
            items {
              id
              saleItemId
              quantity
            }
          }
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getSale',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const createSale = async params => {
  try {
    const query = /* GraphQL */ `
      mutation createSale(
        $organizationId: String!
        $userId: String!
        $orderDate: GraphQLTimestamp!
        $purchaseOrder: String!
        $customerId: String!
        $branchId: String!
        $status: SaleStatus!
        $currencyId: String!
        $invoiceNumber: String!
        $paymentMethodId: String!
        $paymentTermId: String!
        $invoiceStatus: SaleInvoiceStatus!
        $usdRate: Float!
        $payments: [SalePaymentInput]
        $saleItems: [SaleItemInput]!
        $saleShipments: [SaleShipmentInput]!
      ) {
        createSale(
          organizationId: $organizationId
          userId: $userId
          orderDate: $orderDate
          purchaseOrder: $purchaseOrder
          customerId: $customerId
          branchId: $branchId
          status: $status
          currencyId: $currencyId
          invoiceNumber: $invoiceNumber
          paymentMethodId: $paymentMethodId
          paymentTermId: $paymentTermId
          invoiceStatus: $invoiceStatus
          usdRate: $usdRate
          payments: $payments
          saleItems: $saleItems
          saleShipments: $saleShipments
        ) {
          id
          organizationId
          userId
          orderDate
          purchaseOrder
          customer {
            id
            name
          }
          branch {
            id
            name
          }
          status
          currency {
            code
          }
          invoiceNumber
          paymentMethodId
          paymentTermId
          invoiceStatus
          invoiceTotal
          invoiceTax
          amountPaid
          usdRate
          saleShipments {
            id
            dueDate
            deliveryDate
            storageFacility {
              name
            }
          }
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'createSale',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updateSale = async params => {
  try {
    const query = /* GraphQL */ `
      mutation updateSale(
        $organizationId: String!
        $userId: String!
        $saleId: String!
        $orderDate: GraphQLTimestamp!
        $purchaseOrder: String!
        $customerId: String!
        $branchId: String!
        $status: SaleStatus!
        $currencyId: String!
        $invoiceNumber: String!
        $paymentMethodId: String!
        $paymentTermId: String!
        $invoiceStatus: SaleInvoiceStatus!
        $payments: [SalePaymentInput]
      ) {
        updateSale(
          saleId: $saleId
          organizationId: $organizationId
          userId: $userId
          orderDate: $orderDate
          purchaseOrder: $purchaseOrder
          customerId: $customerId
          branchId: $branchId
          status: $status
          currencyId: $currencyId
          invoiceNumber: $invoiceNumber
          paymentMethodId: $paymentMethodId
          paymentTermId: $paymentTermId
          invoiceStatus: $invoiceStatus
          payments: $payments
        ) {
          id
          organizationId
          userId
          orderDate
          purchaseOrder
          customer {
            id
            name
          }
          branch {
            id
            name
          }
          status
          currency {
            code
          }
          invoiceNumber
          paymentMethodId
          paymentTermId
          invoiceStatus
          invoiceTotal
          invoiceTax
          amountPaid
          usdRate
          saleShipments {
            id
            dueDate
            deliveryDate
            storageFacility {
              name
            }
          }
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'updateSale',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const deleteSaleChildren = async params => {
  try {
    const query = /* GraphQL */ `
      mutation deleteSaleChildren($saleId: String!) {
        deleteSaleChildren(saleId: $saleId) {
          id
          organizationId
          userId
          orderDate
          purchaseOrder
          customer {
            id
            name
          }
          branch {
            id
            name
          }
          status
          currency {
            code
          }
          invoiceNumber
          paymentMethodId
          paymentTermId
          invoiceStatus
          invoiceTotal
          invoiceTax
          amountPaid
          usdRate
          saleShipments {
            id
            dueDate
            deliveryDate
            storageFacility {
              name
            }
          }
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'deleteSaleChildren',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

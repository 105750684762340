import { makeOperationsApiCall } from './utils'

export const getPackageUnitPurchases = async params => {
  try {
    const query = /* GraphQL */ `
      query getPackageUnitPurchases(
        $filter: GetPackageUnitPurchasesFilter
        $order: OrderEnum
        $offset: Int
        $limit: Int
      ) {
        getPackageUnitPurchases(
          filter: $filter
          order: $order
          offset: $offset
          limit: $limit
        ) {
          packageUnitPurchases {
            id
            organizationId
            storageFacility {
              id
              name
            }
            packageUnit {
              id
              name
            }
            supplier {
              id
              name
            }
            currency {
              id
              code
            }
            unitPrice
            tax
            quantity
            orderDate
            receivedDate
            status
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getPackageUnitPurchases',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getPackageUnitPurchase = async params => {
  try {
    const query = /* GraphQL */ `
      query getPackageUnitPurchase($packageUnitPurchaseId: String!) {
        getPackageUnitPurchase(packageUnitPurchaseId: $packageUnitPurchaseId) {
          id
          organizationId
          storageFacilityId
          packageUnitId
          supplierId
          currencyId
          unitPrice
          tax
          quantity
          orderDate
          receivedDate
          status
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getPackageUnitPurchase',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const createPackageUnitPurchase = async params => {
  try {
    const query = /* GraphQL */ `
      mutation createPackageUnitPurchase(
        $organizationId: String!
        $storageFacilityId: String!
        $packageUnitId: String!
        $supplierId: String!
        $currencyId: String!
        $unitPrice: Float!
        $tax: Float
        $quantity: Int!
        $orderDate: GraphQLTimestamp!
        $receivedDate: GraphQLTimestamp
        $status: PackageUnitPurchaseStatus!
      ) {
        createPackageUnitPurchase(
          organizationId: $organizationId
          storageFacilityId: $storageFacilityId
          packageUnitId: $packageUnitId
          supplierId: $supplierId
          currencyId: $currencyId
          unitPrice: $unitPrice
          tax: $tax
          quantity: $quantity
          orderDate: $orderDate
          receivedDate: $receivedDate
          status: $status
        ) {
          id
          organizationId
          storageFacility {
            id
            name
          }
          packageUnit {
            id
            name
          }
          supplier {
            id
            name
          }
          currency {
            id
            code
          }
          unitPrice
          tax
          quantity
          orderDate
          receivedDate
          status
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'createPackageUnitPurchase',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updatePackageUnitPurchase = async params => {
  try {
    const query = /* GraphQL */ `
      mutation updatePackageUnitPurchase(
        $packageUnitPurchaseId: String!
        $organizationId: String!
        $storageFacilityId: String!
        $packageUnitId: String!
        $supplierId: String!
        $currencyId: String!
        $unitPrice: Float!
        $tax: Float
        $quantity: Int!
        $orderDate: GraphQLTimestamp!
        $receivedDate: GraphQLTimestamp
        $status: PackageUnitPurchaseStatus!
      ) {
        updatePackageUnitPurchase(
          packageUnitPurchaseId: $packageUnitPurchaseId
          organizationId: $organizationId
          storageFacilityId: $storageFacilityId
          packageUnitId: $packageUnitId
          supplierId: $supplierId
          currencyId: $currencyId
          unitPrice: $unitPrice
          tax: $tax
          quantity: $quantity
          orderDate: $orderDate
          receivedDate: $receivedDate
          status: $status
        ) {
          id
          organizationId
          storageFacility {
            id
            name
          }
          packageUnit {
            id
            name
          }
          supplier {
            id
            name
          }
          currency {
            id
            code
          }
          unitPrice
          tax
          quantity
          orderDate
          receivedDate
          status
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'updatePackageUnitPurchase',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

import { I18n } from 'aws-amplify'

export const ALL_SELECTED = 'ALL_SELECTED'
export const BULK_ACTION = 'BULK_ACTION'
export const SET_INIT = 'SET_INIT'
export const OTA_DESTINATION = 'OTA_DESTINATION'
export const RESET_SELECTED_COUNT = 'RESET_SELECTED_COUNT'
export const SELECT_ZONE_SENSORS = 'SELECT_ZONE_SENSORS'
export const TOGGLE_CORE_DEVICE = 'TOGGLE_CORE_DEVICE'
export const TAB_ID = 'TAB_ID'
export const TOGGLE_SENSOR = 'TOGGLE_SENSOR'
export const CLEAR_SELECTION = 'CLEAR_SELECTION'
export const UPDATE_STATUSES = 'UPDATE_STATUSES'
export const SELECT_ALL_IN_ZONE = 'SELECT_ALL_IN_ZONE'

export const initialState = {
  siteId: '',
  tabId: 'greengrass',
  sensorsByZone: [],
  count: {},
  bulkAction: { label: I18n.get('Core Device Update'), value: 'core' },
  otaDestination: { label: I18n.get('Communications Board'), value: 'comm' }
}

function getDevicesCount(sensorsByZone) {
  const countInitialState = {
    greengrassDevices: 0,
    greengrassSensors: 0,
    directConnectSensors: 0
  }

  return sensorsByZone.reduce((acc, zone) => {
    const sensorsCount = zone.sensors.length

    if (zone.type === 'greengrass') {
      acc.greengrassDevices++
      acc.greengrassSensors += sensorsCount
    }

    if (zone.type === 'direct') {
      acc.directConnectSensors += sensorsCount
    }

    return acc
  }, countInitialState)
}

export function reducer(state, action) {
  const handlers = {
    [BULK_ACTION]: (state, { payload: { bulkAction } }) => {
      let sensorsByZone = state.sensorsByZone
      if (bulkAction.value === 'ota_core') {
        const updatedSensorsByZone = sensorsByZone.map(zone => {
          const sensors = zone.sensors.map(sensor => ({
            ...sensor,
            selected: false
          }))
          return { ...zone, sensors }
        })
        sensorsByZone = updatedSensorsByZone
      }

      if (bulkAction.value === 'ota_sensor' || bulkAction.value === 'nvsdump') {
        const updatedSensorsByZone = sensorsByZone.map(zone => {
          return { ...zone, selected: false }
        })
        sensorsByZone = updatedSensorsByZone
      }

      if (bulkAction.cellularOnly) {
        const updatedSensorsByZone = sensorsByZone.map(zone => {
          const sensors = zone.sensors.map(sensor => {
            return {
              ...sensor,
              selected:
                sensor.selected && sensor.sensorType.includes('cellular')
            }
          })
          return { ...zone, sensors, selected: false }
        })
        sensorsByZone = updatedSensorsByZone
      }

      return {
        ...state,
        bulkAction,
        sensorsByZone
      }
    },
    [SET_INIT]: (state, action) => {
      const { sensorsByZone } = action.payload
      const count = getDevicesCount(sensorsByZone)

      return {
        ...state,
        ...action.payload,
        count
      }
    },
    [OTA_DESTINATION]: (state, { payload: { otaDestination } }) => ({
      ...state,
      otaDestination
    }),
    [SELECT_ZONE_SENSORS]: (state, { payload: { zoneIndex } }) => {
      const sensorsByZone = [...state.sensorsByZone]
      const sensors = sensorsByZone[zoneIndex].sensors.map(sensor => {
        return { ...sensor, selected: true }
      })

      sensorsByZone[zoneIndex].sensors = sensors
      sensorsByZone[zoneIndex].selected = false

      return {
        ...state,
        sensorsByZone
      }
    },
    [SELECT_ALL_IN_ZONE]: (state, { payload: { zoneIndex } }) => {
      const sensorsByZone = [...state.sensorsByZone]
      sensorsByZone[zoneIndex].selected = true

      const sensors = sensorsByZone[zoneIndex].sensors.map(sensor => {
        return { ...sensor, selected: true }
      })

      sensorsByZone[zoneIndex].sensors = sensors

      return {
        ...state,
        sensorsByZone
      }
    },
    [TOGGLE_CORE_DEVICE]: (
      state,
      { payload: { zoneIndex, setSelected = false, clearSensors = false } }
    ) => {
      const sensorsByZone = [...state.sensorsByZone]
      const newValue = setSelected ? true : !sensorsByZone[zoneIndex].selected
      sensorsByZone[zoneIndex].selected = newValue

      if (newValue && clearSensors) {
        const sensors = sensorsByZone[zoneIndex].sensors.map(sensor => {
          return { ...sensor, selected: false }
        })

        sensorsByZone[zoneIndex].sensors = sensors
      }

      return {
        ...state,
        sensorsByZone
      }
    },
    [TOGGLE_SENSOR]: (state, { payload: { zoneIndex, sensorIndex } }) => {
      const sensorsByZone = [...state.sensorsByZone]
      const sensors = sensorsByZone[zoneIndex].sensors
      const isSelected = !sensors[sensorIndex].selected

      sensors[sensorIndex].selected = isSelected
      sensorsByZone[zoneIndex].sensors = sensors

      return {
        ...state,
        sensorsByZone
      }
    },
    [UPDATE_STATUSES]: (state, { payload: { deviceStatuses, tabId } }) => {
      const sensorsByZone = [...state.sensorsByZone]

      deviceStatuses.forEach(({ id, status, updatedAt }) => {
        let isCoreDevice = false

        if (tabId === 'greengrass') {
          const deviceIndex = sensorsByZone.findIndex(
            zone => zone.id === id && zone.type === 'greengrass'
          )
          if (deviceIndex >= 0) {
            isCoreDevice = true
            sensorsByZone[deviceIndex].status = status
            sensorsByZone[deviceIndex].updatedAt = updatedAt
          }
        }

        if (!isCoreDevice) {
          for (let i = 0; i < sensorsByZone?.length; i++) {
            const { sensors } = sensorsByZone[i]
            const sensorIndex = sensors.findIndex(sensor => {
              return sensor.id === id
            })
            if (sensorIndex >= 0) {
              sensorsByZone[i].sensors[sensorIndex].status = status
              sensorsByZone[i].sensors[sensorIndex].updatedAt = updatedAt
            }
          }
        }
      })

      return {
        ...state,
        sensorsByZone
      }
    },
    [CLEAR_SELECTION]: state => {
      const sensorsByZone = [...state.sensorsByZone].map(zone => {
        const sensors = zone.sensors.map(sensor => ({
          ...sensor,
          selected: false
        }))
        return { ...zone, sensors, selected: false }
      })

      return {
        ...state,
        sensorsByZone
      }
    },
    [TAB_ID]: (state, { payload: { tabId } }) => ({
      ...state,
      tabId
    })
  }

  if (!handlers[action.type]) {
    throw new Error(`Unknown action type: ${action.type}`)
  }

  return handlers[action.type](state, action)
}

import InputError from '../../../../../elements/InputError'
import { Text, Label, Input, Flex } from '../../../../../primitives'

import Strings from '../Strings'

function Address({
  state,
  handleInput,
  errors,
  fields,
  className,
  stringType = 'street'
}) {
  const strings = Strings()
  return (
    <Flex
      className={className}
      axisGap={300}
      alignMainAxis='space-evenly'
      direction='column'
    >
      <Label>
        <Text variant='page' fontWeight={500} tone={700}>
          {stringType === 'bank'
            ? strings.branchAddress
            : strings.streetAddress}
        </Text>
        <Input
          value={state.streetAddress}
          name={fields.streetAddress}
          onChange={handleInput}
        />
        <InputError error={errors?.streetAddress} />
      </Label>
      <Flex className='inputGroup' axisGap={300}>
        <Label>
          <Text variant='page' fontWeight={500} tone={700}>
            {stringType === 'bank'
              ? strings.branchAddress2
              : strings.streetAddress2}
          </Text>
          <Input
            value={state.streetAddress2}
            name={fields.streetAddress2}
            onChange={handleInput}
          />
          <InputError error={errors?.streetAddress2} />
        </Label>
        <Label>
          <Text variant='page' fontWeight={500} tone={700}>
            {strings.zipCode}
          </Text>
          <Input
            value={state.zipCode}
            name={fields.zipCode}
            onChange={handleInput}
          />
          <InputError error={errors?.zipCode} />
        </Label>
      </Flex>
      <Flex className='inputGroup' axisGap={300}>
        <Label>
          <Text variant='page' fontWeight={500} tone={700}>
            {strings.city}
          </Text>
          <Input value={state.city} name={fields.city} onChange={handleInput} />
          <InputError error={errors?.city} />
        </Label>
        <Label>
          <Text variant='page' fontWeight={500} tone={700}>
            {strings.addressRegion}
          </Text>
          <Input
            value={state.region}
            name={fields.region}
            onChange={handleInput}
          />
          <InputError error={errors?.region} />
        </Label>
        <Label>
          <Text variant='page' fontWeight={500} tone={700}>
            {strings.country}
          </Text>
          <Input
            value={state.country}
            name={fields.country}
            onChange={handleInput}
          />
          <InputError error={errors?.country} />
        </Label>
      </Flex>
    </Flex>
  )
}

export default Address

import { makeActionCreator } from '@/Util/ReduxUtils'

export const CREATE_SUPPLIER = 'CREATE_SUPPLIER'
export const createSupplier = makeActionCreator(CREATE_SUPPLIER, 'params')

export const SUPPLIER_CREATED = 'SUPPLIER_CREATED'
export const supplierCreated = makeActionCreator(
  SUPPLIER_CREATED,
  'supplier',
  'error'
)

export const UPDATE_SUPPLIER = 'UPDATE_SUPPLIER'
export const updateSupplier = makeActionCreator(UPDATE_SUPPLIER, 'params')

export const SUPPLIER_UPDATED = 'SUPPLIER_UPDATED'
export const supplierUpdated = makeActionCreator(
  SUPPLIER_UPDATED,
  'supplier',
  'error'
)

export const REQUEST_SUPPLIERS = 'REQUEST_SUPPLIERS'
export const requestSuppliers = makeActionCreator(REQUEST_SUPPLIERS, 'params')

export const RECEIVE_SUPPLIERS = 'RECEIVE_SUPPLIERS'
export const receiveSuppliers = makeActionCreator(
  RECEIVE_SUPPLIERS,
  'suppliers',
  'count',
  'error'
)

export const REQUEST_SUPPLIER = 'REQUEST_SUPPLIER'
export const requestSupplier = makeActionCreator(REQUEST_SUPPLIER, 'params')

export const RECEIVE_SUPPLIER = 'RECEIVE_SUPPLIER'
export const receiveSupplier = makeActionCreator(
  RECEIVE_SUPPLIER,
  'supplier',
  'error'
)

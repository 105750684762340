import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { getCognitoData, makeGraphqlRequest } from '../utils'

/*
 * Queries
 */

export const getUserCognitoLogs = async params => {
  try {
    const { userId, startAt, endAt } = params
    const query = /* GraphQL */ `
      query getUserCognitoLogs(
        $userId: String!
        $startAt: GraphQLTimestamp
        $endAt: GraphQLTimestamp
      ) {
        getUserCognitoLogs(userId: $userId, startAt: $startAt, endAt: $endAt) {
          userId
          timestamp
          authFlow
          errorCode
          errorMessage
          sourceIPAddress
          userAgent
        }
      }
    `

    const { id, idToken } = await getCognitoData()
    const operation = graphqlOperation(query, { userId, startAt, endAt })
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken,
      id
    )
    if (error) {
      return {
        logs: null,
        error: `User logs not fetched: ${error}`
      }
    }

    return { logs: data?.getUserCognitoLogs, error: null }
  } catch (err) {
    return { logs: null, error: err.message }
  }
}

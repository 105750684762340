import { makeActionCreator } from '@/Util/ReduxUtils'

export const CREATE_HARVEST = 'CREATE_HARVEST'
export const createHarvest = makeActionCreator(CREATE_HARVEST, 'params')

export const HARVEST_CREATED = 'HARVEST_CREATED'
export const harvestCreated = makeActionCreator(
  HARVEST_CREATED,
  'harvest',
  'error'
)

export const UPDATE_HARVEST = 'UPDATE_HARVEST'
export const updateHarvest = makeActionCreator(UPDATE_HARVEST, 'params')

export const HARVEST_UPDATED = 'HARVEST_UPDATED'
export const harvestUpdated = makeActionCreator(
  HARVEST_UPDATED,
  'harvest',
  'error'
)

export const DELETE_HARVEST = 'DELETE_HARVEST'
export const deleteHarvest = makeActionCreator(DELETE_HARVEST, 'params')

export const HARVEST_DELETED = 'HARVEST_DELETED'
export const harvestDeleted = makeActionCreator(
  HARVEST_DELETED,
  'harvest',
  'error'
)

export const CLEAR_ALL_HARVESTS = 'CLEAR_ALL_HARVESTS'
export const clearAllHarvests = makeActionCreator(CLEAR_ALL_HARVESTS)

export const REQUEST_ALL_HARVESTS = 'REQUEST_ALL_HARVESTS'
export const requestAllHarvests = makeActionCreator(
  REQUEST_ALL_HARVESTS,
  'params'
)

export const RECEIVE_ALL_HARVESTS = 'RECEIVE_ALL_HARVESTS'
export const receiveAllHarvests = makeActionCreator(
  RECEIVE_ALL_HARVESTS,
  'harvests',
  'count',
  'error'
)

export const REQUEST_HARVESTS = 'REQUEST_HARVESTS'
export const requestHarvests = makeActionCreator(REQUEST_HARVESTS, 'params')

export const RECEIVE_HARVESTS = 'RECEIVE_HARVESTS'
export const receiveHarvests = makeActionCreator(
  RECEIVE_HARVESTS,
  'harvests',
  'count',
  'error'
)

export const REQUEST_HARVEST = 'REQUEST_HARVEST'
export const requestHarvest = makeActionCreator(REQUEST_HARVEST, 'params')

export const RECEIVE_HARVEST = 'RECEIVE_HARVEST'
export const receiveHarvest = makeActionCreator(
  RECEIVE_HARVEST,
  'harvest',
  'error'
)

/** DASHBOARD QUERIES */

export const REQUEST_TOTAL_HARVEST_PRODUCE = 'REQUEST_TOTAL_HARVEST_PRODUCE'
export const requestTotalHarvestProduce = makeActionCreator(
  REQUEST_TOTAL_HARVEST_PRODUCE,
  'params',
  'dashboardParams'
)

export const REQUEST_TOP_TEN_HARVEST_VARIETY = 'REQUEST_TOP_TEN_HARVEST_VARIETY'
export const requestTopTenHarvestVariety = makeActionCreator(
  REQUEST_TOP_TEN_HARVEST_VARIETY,
  'params',
  'dashboardParams'
)

export const REQUEST_PLAN_VS_HARVEST = 'REQUEST_PLAN_VS_HARVEST'
export const requestPlanVsHarvest = makeActionCreator(
  REQUEST_PLAN_VS_HARVEST,
  'params',
  'dashboardParams'
)

export const REQUEST_GET_ALL_VARIETIES_BY_HARVEST_DATE_AND_PRODUCE =
  'REQUEST_GET_ALL_VARIETIES_BY_HARVEST_DATE_AND_PRODUCE'
export const requestGetAllVarietiesByHarvestDateAndProduce = makeActionCreator(
  REQUEST_GET_ALL_VARIETIES_BY_HARVEST_DATE_AND_PRODUCE,
  'params',
  'dashboardParams'
)

export const REQUEST_GET_ALL_PRODUCES_BY_HARVEST_DATE =
  'REQUEST_GET_ALL_PRODUCES_BY_HARVEST_DATE'

export const requestGetAllProducesByHarvestDate = makeActionCreator(
  REQUEST_GET_ALL_PRODUCES_BY_HARVEST_DATE,
  'params',
  'dashboardParams'
)

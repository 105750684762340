import { call, put, takeLatest } from 'redux-saga/effects'

import {
  FETCH_CURRENT_AVE_MIN_MAX,
  currentAveMinMaxFetched
} from '@/actions/ts/current'
import { getCurrentAveMinMax } from '@/api/ts/current'
import { isLoading } from '@/slices/util'

export function* sendFetchCurrentAveMinMax(action) {
  yield put(isLoading(true))

  const { currentAverageMinMax, error = null } = yield call(
    getCurrentAveMinMax,
    action.params
  )
  yield put(currentAveMinMaxFetched(currentAverageMinMax, error))
  yield put(isLoading(false))
}

export function* watchSendFetchCurrentAveMinMax() {
  yield takeLatest(FETCH_CURRENT_AVE_MIN_MAX, sendFetchCurrentAveMinMax)
}

import { I18n } from 'aws-amplify'

const Strings = () => ({
  manageInventoryTransfers: I18n.get('Manage Packaging Inventory Transfers'),
  createInventoryTransfer: I18n.get('Transfer'),
  createFormHeader: I18n.get('Create New Transfer'),
  updateFormHeader: I18n.get('Update Existing Transfer'),
  noInventoryTransfers: I18n.get('No packaging inventory transfers'),
  saveInventoryTransfer: I18n.get('Save Transfer'),
  selectDefault: I18n.get('Please Select'),
  inventoryTransfer: I18n.get('Inventory Transfer'),
  transfer: I18n.get('Transfer'),
  inventory: I18n.get('Inventory'),
  packaging: I18n.get('Packaging'),
  packageUnit: I18n.get('Package Unit'),
  fromStorageFacility: I18n.get('Origin'),
  toStorageFacility: I18n.get('Destination'),
  shippingMethod: I18n.get('Shipping Method'),
  totalWeight: I18n.get('Total Weight'),
  status: I18n.get('Status'),
  shippedDate: I18n.get('Date Shipped'),
  deliveredDate: I18n.get('Date Delivered'),
  from: I18n.get('Origin'),
  to: I18n.get('Destination'),
  quantity: I18n.get('Quantity'),
  grams: I18n.get('g'),
  pending: I18n.get('Pending'),
  shipped: I18n.get('Shipped'),
  delivered: I18n.get('Delivered'),
  canceled: I18n.get('Canceled')
})

export default Strings

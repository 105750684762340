import { Fragment, Children, useMemo, useEffect } from 'react'
import { Box, Select, Text, FlexV2 } from '@/primitives'

import Strings from './Strings'
import Arrow from './parts/Arrow'
import Number from './parts/Number'

import { range } from './utils'
import './index.scss'

const INLINE_PAGE_LIMIT = 5

const NOOP = () => {}

/**
 * PaginationElement
 * @param {Object} props
 * @param {String | String[]} [props.className] Extra Class Name
 * @param {import('react').CSSProperties} [props.css]
 * @param { 'simple' | 'compact' | 'compound' | 'no-pages'} [props.type = 'simple'] Pagination Types
 *
 * @param {Number} [props.totalItems] How many items exist in the List / Table?
 *
 * @param {Number} [props.totalPages] State Based: How many pages in the current result set
 * @param {Number} [props.setTotalPages] totalPages State Setter
 *
 * @param {Number} [props.page = 1] State Based: Current Page
 * @param {Number} [props.setPage] page State Setter
 *
 * @param {Number} [props.pageSize = 10] State Based: How many items to display on this page?
 * @param {Number} [props.setPageSize] pageSize State Setter (for type='compound')
 *
 * @param {Number} [props.pageSizes = [5, 10, 25, 50, 100]] Page Size Options (for type='compound')
 *
 * @param {Boolean} [props.showPageCount = true] showPageCount Show "of X pages" (for type='compact')
 *
 * @example
 * ```jsx
 * // Tracked States
 * const totalItems = 1 // get this from your data
 * const [page, setPage] = useState(1)
 * const [pageSize, setPageSize] = useState(10)
 * const [totalPages, setTotalPages] = useState(null)
 *
 * // Component
 * <Pagination
 *   type={'simple'}
 *   totalItems={totalItems}
 *
 *   totalPages={totalPages}
 *   setTotalPages={setTotalPages}
 *
 *   page={page}
 *   setPage={setPage}
 *
 *   pageSize={pageSize}
 *   setPageSize={setPageSize}
 * />
 * ```
 *
 */

const Pagination = ({
  className = null,
  disabled = null,
  type = 'simple',

  totalItems = null,

  totalPages = null,
  setTotalPages = null,

  showPageCount = true,

  page = 1,
  setPage = null,

  pageSize = 10,
  setPageSize = () => {},

  pageSizes = [5, 10, 25, 50, 100],
  ...rest
}) => {
  const strings = Strings()

  const pageRange = useMemo(() => range(1, totalPages), [totalPages])

  const onInteraction = ({
    target: {
      dataset: { action, goto },
      value
    }
  }) => {
    goto = !isNaN(parseInt(goto)) ? parseInt(goto) : goto
    value = !isNaN(parseInt(value)) ? parseInt(value) : value

    if (goto) {
      setPage(goto)
    }
    if (action === 'select-page' && value) {
      setPage(value)
    }

    if (action === 'select-size' && value) {
      setPage(1)
      setPageSize(value)
    }

    if (action === 'next') {
      setPage(page + 1)
    }
    if (action === 'prev') {
      setPage(page - 1)
    }
    if (action === 'first') {
      setPage(1)
    }
    if (action === 'last') {
      setPage(totalPages)
    }
  }

  useEffect(() => {
    setTotalPages(Math.ceil(totalItems / pageSize))
  }, [pageSize, totalItems, setTotalPages])

  return (
    <FlexV2
      axisGap={300}
      className={['Pagination', type, className]}
      onClick={onInteraction}
      onChange={onInteraction}
      alignMainAxis='space-between'
      alignCrossAxis='center'
      role='navigation'
      aria-label={strings.paginationNavigation}
      as={'nav'}
      disabled={disabled}
      {...rest}
    >
      {type === 'simple' && totalPages <= INLINE_PAGE_LIMIT && (
        <Fragment>
          <FlexV2>
            <Arrow type='first' disabled={page === 1} />
            <Arrow type='prev' disabled={page === 1} />
          </FlexV2>
          <Box>
            {Children.toArray(
              pageRange.map(value => {
                return <Number active={value === page} value={value} />
              })
            )}
          </Box>
          <FlexV2>
            <Arrow type='next' disabled={page === totalPages} />
            <Arrow type='last' disabled={page === totalPages} />
          </FlexV2>
        </Fragment>
      )}

      {((type === 'simple' && totalPages > INLINE_PAGE_LIMIT) ||
        type === 'compact') && (
        <Fragment>
          <FlexV2>
            <Arrow type='first' disabled={page === 1} />
            <Arrow type='prev' disabled={page === 1} />
          </FlexV2>
          <FlexV2 axisGap={400} alignCrossAxis='center'>
            <Select data-action={'select-page'} value={page} onChange={NOOP}>
              {Children.toArray(
                pageRange.map(value => {
                  return <option value={value}>{value}</option>
                })
              )}
            </Select>
            {showPageCount && (
              <Text>
                {strings.of} {totalPages} {strings.pages}
              </Text>
            )}
          </FlexV2>
          <FlexV2>
            <Arrow type='next' disabled={page === totalPages} />
            <Arrow type='last' disabled={page === totalPages} />
          </FlexV2>
        </Fragment>
      )}

      {type === 'compound' && (
        <Fragment>
          <FlexV2 alignCrossAxis='center' axisGap={400}>
            <FlexV2 alignCrossAxis='center' axisGap={300}>
              {pageSizes.filter(size => size <= totalItems).length > 0 && (
                <Fragment>
                  <Select data-action={'select-size'} defaultValue={pageSize}>
                    {Children.toArray(
                      pageSizes
                        .filter(size => size <= totalItems)
                        .map(size => {
                          return <option value={size}>{size}</option>
                        })
                    )}
                  </Select>
                  <Text size={200} fontFamily='mono' variant='page' tone={700}>
                    {strings.items} / {strings.page}
                  </Text>
                </Fragment>
              )}
            </FlexV2>

            <FlexV2 axisGap={300}>
              <Text fontFamily='mono'>
                {pageSize * page - (pageSize - 1)} &mdash;{' '}
                {Math.min(pageSize * page, totalItems)}
              </Text>
              <Text fontFamily='mono' variant='page' tone={700}>
                {strings.of} {totalItems} {strings.items}
              </Text>
            </FlexV2>
          </FlexV2>
          <FlexV2>
            <FlexV2 axisGap={400} alignCrossAxis='center'>
              <Select data-action={'select-page'} value={page} onChange={NOOP}>
                {Children.toArray(
                  pageRange.map(value => {
                    return <option value={value}>{value}</option>
                  })
                )}
              </Select>
              <Text>
                {strings.of} {totalPages} {strings.pages}
              </Text>
            </FlexV2>
            <Arrow type='prev' disabled={page === 1} />
            <Arrow type='next' disabled={page === totalPages} />
          </FlexV2>
        </Fragment>
      )}

      {type === 'no-pages' && (
        <Fragment>
          <FlexV2>
            <Arrow type='prev' disabled={page === 1} />
          </FlexV2>
          <FlexV2>
            <Arrow type='next' disabled={page === totalPages} />
          </FlexV2>
        </Fragment>
      )}
    </FlexV2>
  )
}

export default Pagination

import { useMemo } from 'react'

import { getColorVariantFromViewData } from '@/components/StatusPage/utils'

import { TYPEICONMAP } from '@/components/StatusPage/constants'

/**
 * Renders an icon for a device type with optional status and open/closed state.
 *
 * @param {Object} props - The props to pass to the component.
 * @param {string} props.type - The type of the device. Default is 'envirosense'.
 * @param {string} props.status - The status of the device. Default is 'loading'.
 * @param {boolean} props.open - Whether the device is open or closed. Default is true.
 * @param {boolean} props.missingData - Whether the device has missing data. Default is false.
 * @param {boolean} props.notInUse - Whether the device is not in use. Default is false.
 *
 * @returns {React.ReactElement} A React component that displays an icon for the device type with optional status and open/closed state.
 */
export default function TypeIcon({
  type = 'envirosense',
  status = 'loading',
  open = true,
  missingData = false,
  notInUse = false
}) {
  const href = useMemo(() => {
    const pathArray = ['#ctx-icon-device']

    const isModbus = type.includes('modbus')
    const isKnownDeviceIcon = TYPEICONMAP.includes(type)

    if (!isModbus) {
      if (isKnownDeviceIcon) {
        pathArray.push(type)
        if (type === 'folder') {
          pathArray.push(open ? 'open' : 'closed')
        }
      } else {
        pathArray.push('generic')
      }
    } else {
      pathArray.push('modbus')
    }

    return pathArray.filter(Boolean).join('-')
  }, [open, type])

  const color = useMemo(() => {
    const variant = getColorVariantFromViewData({
      missingData,
      notInUse,
      status
    })
    return `var(--ctx-theme-color-${variant}-500)`
  }, [missingData, notInUse, status])

  return (
    <svg width={24} height={24} color={color}>
      <use href={href} />
    </svg>
  )
}

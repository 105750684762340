import { makeOperationsApiCall } from './utils'

export const getSuppliers = async params => {
  try {
    const query = /* GraphQL */ `
      query getSuppliers(
        $filter: GetSuppliersFilter
        $order: OrderEnum
        $offset: Int
        $limit: Int
      ) {
        getSuppliers(
          filter: $filter
          order: $order
          offset: $offset
          limit: $limit
        ) {
          suppliers {
            id
            organizationId
            name
            code
            category
            status
            supplyTypes
            onboardDate
            country {
              name
            }
            region {
              name
            }
            contactName
            contactNumber
            contactEmail
            website
            currency {
              id
              code
            }
            paymentMethodId
            paymentTermId
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getSuppliers',
      resultKey: 'data'
    })
    return { data: data ?? [], error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getSupplier = async params => {
  try {
    const query = /* GraphQL */ `
      query getSupplier($supplierId: String!) {
        getSupplier(supplierId: $supplierId) {
          id
          organizationId
          name
          code
          category
          status
          supplyTypes
          onboardDate
          countryId
          regionId
          contactName
          contactNumber
          contactEmail
          website
          currencyId
          paymentMethodId
          paymentTermId
          registrationNumber
          taxNumber
          address {
            streetAddress
            streetAddress2
            city
            region
            country
            zipCode
          }
          bankDetails {
            bankName
            branchName
            branchCode
            branchAddress {
              streetAddress
              streetAddress2
              city
              region
              country
              zipCode
            }
            swiftBic
            iban
          }
          bankDetailsS3Key
          contractS3Key
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getSupplier',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const createSupplier = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createSupplier(
        $organizationId: String!
        $name: String!
        $code: String!
        $category: SupplierCategory!
        $status: SupplierStatus!
        $supplyTypes: [String]!
        $onboardDate: GraphQLTimestamp!
        $countryId: String!
        $regionId: String
        $contactName: String!
        $contactNumber: String!
        $contactEmail: String!
        $website: String!
        $currencyId: String!
        $paymentMethodId: String!
        $paymentTermId: String!
        $registrationNumber: String
        $taxNumber: String
        $address: AddressInput
        $bankDetails: BankDetailsInput
        $bankDetailsS3Key: String
        $contractS3Key: String
      ) {
        createSupplier(
          organizationId: $organizationId
          name: $name
          code: $code
          category: $category
          status: $status
          supplyTypes: $supplyTypes
          onboardDate: $onboardDate
          regionId: $regionId
          contactName: $contactName
          contactNumber: $contactNumber
          contactEmail: $contactEmail
          website: $website
          countryId: $countryId
          currencyId: $currencyId
          paymentMethodId: $paymentMethodId
          paymentTermId: $paymentTermId
          registrationNumber: $registrationNumber
          taxNumber: $taxNumber
          address: $address
          bankDetails: $bankDetails
          bankDetailsS3Key: $bankDetailsS3Key
          contractS3Key: $contractS3Key
        ) {
          id
          organizationId
          name
          code
          status
          supplyTypes
          onboardDate
          country {
            name
          }
          region {
            name
          }
          contactName
          contactNumber
          contactEmail
          website
          currency {
            id
            code
          }
          paymentMethodId
          paymentTermId
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'createSupplier',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updateSupplier = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateSupplier(
        $organizationId: String!
        $supplierId: String!
        $name: String!
        $code: String!
        $category: SupplierCategory!
        $status: SupplierStatus!
        $supplyTypes: [String]!
        $onboardDate: GraphQLTimestamp!
        $countryId: String!
        $regionId: String
        $contactName: String!
        $contactNumber: String!
        $contactEmail: String!
        $website: String!
        $currencyId: String!
        $paymentMethodId: String!
        $paymentTermId: String!
        $registrationNumber: String
        $taxNumber: String
        $address: AddressInput
        $bankDetails: BankDetailsInput
        $bankDetailsS3Key: String
        $contractS3Key: String
      ) {
        updateSupplier(
          organizationId: $organizationId
          supplierId: $supplierId
          name: $name
          code: $code
          category: $category
          status: $status
          supplyTypes: $supplyTypes
          onboardDate: $onboardDate
          countryId: $countryId
          regionId: $regionId
          contactName: $contactName
          contactNumber: $contactNumber
          contactEmail: $contactEmail
          website: $website
          currencyId: $currencyId
          paymentMethodId: $paymentMethodId
          paymentTermId: $paymentTermId
          registrationNumber: $registrationNumber
          taxNumber: $taxNumber
          address: $address
          bankDetails: $bankDetails
          bankDetailsS3Key: $bankDetailsS3Key
          contractS3Key: $contractS3Key
        ) {
          id
          organizationId
          name
          code
          status
          supplyTypes
          onboardDate
          country {
            name
          }
          region {
            name
          }
          contactName
          contactNumber
          contactEmail
          website
          currency {
            id
            code
          }
          paymentMethodId
          paymentTermId
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'updateSupplier',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const tableInitialState = {
  organizations: [],
  page: 1
}

export const tableReducer = (state, action) => {
  if (action.page) {
    return {
      ...state,
      page: action.page
    }
  } else if (action.organizations) {
    return {
      ...state,
      organizations: action.organizations
    }
  }
  return state
}

export const formInitalState = {
  id: null,
  organizationId: '',
  name: '',
  termSheetDocument: {
    file: {},
    url: '',
    s3Key: ''
  },
  s3KeyToDelete: ''
}

export const formReducer = (state, action) => {
  if (action.type === 'update') {
    return { ...state, [action.name]: action.value }
  }
  if (action.type === 'set-state') {
    return action.state
  }
  if (action.type === 'set-file') {
    return {
      ...state,
      termSheetDocument: {
        ...state.termSheetDocument,
        file: action.file
      }
    }
  }
  if (action.type === 'reset-document') {
    return { ...state, termSheetDocument: formInitalState.termSheetDocument }
  }
  return state
}

import { I18n } from 'aws-amplify'
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'

import {
  getOrganizationLabels,
  getAllOrganizationLabels,
  getOrganizationLabel,
  createOrganizationLabel,
  updateOrganizationLabel,
  updateOrganizationLabelTranslations
} from '@/api/management/organizationLabels'

import { showBanner } from '../util'

export const fetchOrganizationLabels = createAsyncThunk(
  'fetchOrganizationLabels',
  async params => {
    return await getOrganizationLabels(params)
  }
)

export const fetchOrganizationLabel = createAsyncThunk(
  'fetchOrganizationLabel',
  async params => {
    return await getOrganizationLabel(params)
  }
)

export const fetchAllOrganizationLabels = createAsyncThunk(
  'fetchAllOrganizationLabels',
  async params => {
    return await getAllOrganizationLabels(params)
  }
)

export const sendCreateOrganizationLabel = createAsyncThunk(
  'sendCreateOrganizationLabel',
  async (params, { dispatch }) => {
    try {
      const create = await createOrganizationLabel(params)
      dispatch(
        showBanner({
          show: true,
          message: I18n.get('Organization label created successfully.'),
          type: 'success'
        })
      )
      return create
    } catch (error) {
      dispatch(
        showBanner({
          show: true,
          message: I18n.get(
            'Error creating the label. Please try again later.'
          ),
          type: 'error'
        })
      )
      throw error
    }
  }
)

export const sendUpdateOrganizationLabel = createAsyncThunk(
  'sendUpdateOrganizationLabel',
  async (params, { dispatch }) => {
    try {
      const update = await updateOrganizationLabel(params)
      dispatch(
        showBanner({
          show: true,
          message: I18n.get('Organization label updated successfully.'),
          type: 'success'
        })
      )
      return update
    } catch (error) {
      dispatch(
        showBanner({
          show: true,
          message: I18n.get(
            'Error updating the label. Please try again later.'
          ),
          type: 'error'
        })
      )
      throw error
    }
  }
)

export const sendUpdateOrganizationLabelTranslations = createAsyncThunk(
  'sendUpdateOrganizationLabelTranslations',
  async (params, { dispatch }) => {
    try {
      const update = await updateOrganizationLabelTranslations(params)
      dispatch(
        showBanner({
          show: true,
          message: I18n.get(
            'Organization label translations updated successfully.'
          ),
          type: 'success'
        })
      )
      return update
    } catch (error) {
      dispatch(
        showBanner({
          show: true,
          message: I18n.get(
            'Error updating the label translations. Please try again later.'
          ),
          type: 'error'
        })
      )
      throw error
    }
  }
)

function updateArray(array, newLabel) {
  const index = array.findIndex(({ labelId }) => labelId === newLabel.labelId)
  if (index === -1) {
    return [...array, newLabel]
  }
  return array.map(label =>
    label.labelId === newLabel.labelId ? newLabel : label
  )
}

function updateAllOrganizationLabels(state, action) {
  const { allOrganizationLabelsLang, allOrganizationLabelsOrgId } =
    current(state)

  if (action.meta.arg.organizationId === allOrganizationLabelsOrgId) {
    const translation = action.payload.translations.find(
      translation => translation.lang === allOrganizationLabelsLang
    )

    const updatedLabel = {
      text: translation.text,
      textPlural: translation.textPlural
    }

    state.allOrganizationLabels = {
      ...state.allOrganizationLabels,
      [action.payload.label]: updatedLabel
    }
  }

  return state.allOrganizationLabels
}

const organizationLabelsSlice = createSlice({
  name: 'managementOrganizationLabelsReducer',
  initialState: {
    allOrganizationLabelsOrgId: null,
    allOrganizationLabelsLang: null,
    organizationLabels: [],
    allOrganizationLabels: {},
    organizationLabel: {},
    loading: false,
    error: null
  },
  reducers: {
    setOrganizationLabel: (state, action) => {
      state.organizationLabel = action.payload
    },
    clearOrganizationLabel: state => {
      state.organizationLabel = {}
    },
    clearOrganizationLabels: state => {
      state.organizationLabels = []
    },
    clearAllOrganizationLabels: state => {
      state.allOrganizationLabels = {}
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchOrganizationLabels.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchOrganizationLabels.fulfilled, (state, action) => {
        state.organizationLabels = action.payload
        state.loading = false
      })
      .addCase(fetchOrganizationLabels.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })

      .addCase(fetchOrganizationLabel.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchOrganizationLabel.fulfilled, (state, action) => {
        state.organizationLabel = action.payload
        state.loading = false
      })
      .addCase(fetchOrganizationLabel.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })

      .addCase(fetchAllOrganizationLabels.pending, state => {
        state.allOrganizationLabelsLang = null
        state.allOrganizationLabelsOrgId = null
        state.allOrganizationLabels = {}
        state.loading = true
        state.error = null
      })
      .addCase(fetchAllOrganizationLabels.fulfilled, (state, action) => {
        state.allOrganizationLabelsLang = action.meta.arg.lang
        state.allOrganizationLabelsOrgId = action.payload.organizationId
        state.allOrganizationLabels = action.payload.labels
        state.loading = false
      })
      .addCase(fetchAllOrganizationLabels.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })

      .addCase(sendCreateOrganizationLabel.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(sendCreateOrganizationLabel.fulfilled, (state, action) => {
        state.organizationLabel = {}
        state.organizationLabels = updateArray(
          state.organizationLabels,
          action.payload
        )
        state.allOrganizationLabels = updateAllOrganizationLabels(state, action)
        state.loading = false
      })
      .addCase(sendCreateOrganizationLabel.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })

      .addCase(sendUpdateOrganizationLabel.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(sendUpdateOrganizationLabel.fulfilled, (state, action) => {
        state.organizationLabel = {}
        state.organizationLabels = updateArray(
          state.organizationLabels,
          action.payload
        )
        state.allOrganizationLabels = updateAllOrganizationLabels(state, action)
        state.loading = false
      })
      .addCase(sendUpdateOrganizationLabel.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })
      .addCase(sendUpdateOrganizationLabelTranslations.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(
        sendUpdateOrganizationLabelTranslations.fulfilled,
        (state, action) => {
          state.organizationLabel = action.payload
          state.organizationLabels = updateArray(
            state.organizationLabels,
            action.payload
          )
          state.loading = false
        }
      )
      .addCase(
        sendUpdateOrganizationLabelTranslations.rejected,
        (state, action) => {
          state.loading = false
          state.error = action.error
        }
      )
  }
})

const { reducer, actions } = organizationLabelsSlice

export const {
  setOrganizationLabel,
  clearOrganizationLabel,
  clearOrganizationLabels,
  clearAllOrganizationLabels
} = actions

export default reducer

import React, { useReducer, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { Link, useHistory } from 'react-router-dom'

import {
  Box,
  Button,
  FlexV2,
  Input,
  Select,
  Label,
  Text,
  Form,
  Icon
} from '@/primitives'

import { sendCreateUser, setUser } from '@/slices/management/user'
import { fetchRoles } from '@/slices/management/role'
import {
  getIsLoading,
  getRoles,
  getUserSliceError,
  getUser
} from '@/reducers/selectors'

import useMediaQuery from '@/hooks/useMediaQuery'

import AdminUtils from '@/Util/AdminUtils'

import {
  generateUserError,
  initialState,
  reducer,
  USER_FORM_ENUM
} from './utils'

import Strings from '../Strings'

const { newUserSchema } = AdminUtils

export default function AddUser() {
  const reduxDispatch = useDispatch()
  const history = useHistory()

  const isLoading = getIsLoading()
  const roles = getRoles()
  const user = getUser()
  const userError = getUserSliceError()

  const isAboveCustomBreakpoint = useMediaQuery('min-width: 680px')

  const [state, dispatch] = useReducer(reducer, initialState)
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const strings = Strings()

  useEffect(() => {
    if (roles.length === 0) {
      reduxDispatch(fetchRoles())
    }
  }, [reduxDispatch, roles.length])

  useEffect(() => {
    if (userError) {
      setHasError(true)
    }
  }, [userError])

  useEffect(() => {
    if (user?.userName) {
      history.replace('/admin/user-management', { action: 'create' })
    }
  }, [history, user?.userName])

  useEffect(() => {
    if (hasError) {
      const message = generateUserError(userError, state)
      setErrorMessage(message)
    }
  }, [state, hasError, userError])

  const onChangeInput = ({ target: { name, value } }) => {
    dispatch({ type: name, value })
  }

  const onSelectRole = ({ target: { value } }) => {
    dispatch({ type: USER_FORM_ENUM.ROLE, value })
  }

  const onChangePhoneNumber = value => {
    dispatch({ type: USER_FORM_ENUM.PHONE_NUMBER, value })
  }

  const resetFields = () => {
    dispatch({ type: USER_FORM_ENUM.CLEAR_ALL })
  }

  const dismissErrorMessage = () => {
    setHasError(false)
  }

  const saveUserDetails = async e => {
    e.preventDefault()

    const { firstName, lastName, email, userName, role } = state || {}
    const user = {
      userName: userName.value,
      email: email.value,
      firstName: firstName.value,
      lastName: lastName.value,
      role: role.value
    }

    let params = { user }

    try {
      if (hasError) setHasError(false)
      await newUserSchema().validate(user, { abortEarly: false, strict: true })

      const phoneNumber = state.phoneNumber.value

      const isValid = phoneNumber ? isValidPhoneNumber(phoneNumber) : true
      if (isValid) {
        if (phoneNumber) {
          params['user'][USER_FORM_ENUM.PHONE_NUMBER] = phoneNumber
        }

        reduxDispatch(sendCreateUser(params))
      } else {
        dispatch({ type: USER_FORM_ENUM.PHONE_NUMBER, hasError: true })
        setHasError(true)
      }
    } catch (error) {
      error.inner.forEach(err => {
        dispatch({ type: err.path, hasError: true, error: err.message })
      })
      setHasError(true)
    }
  }

  const goBack = () => {
    dispatch({ type: USER_FORM_ENUM.CLEAR_ALL })
    reduxDispatch(setUser({}))
  }

  return (
    <Form
      className={'AddUser__Form'}
      onReset={resetFields}
      onSubmit={saveUserDetails}
    >
      <FlexV2
        className='AddUser__FormHeader'
        direction={isAboveCustomBreakpoint ? 'row' : 'column'}
        alignMainAxis={isAboveCustomBreakpoint ? 'space-between' : null}
        alignCrossAxis={isAboveCustomBreakpoint ? 'center' : null}
        axisGap={400}
      >
        <FlexV2 axisGap={400} alignCrossAxis='center'>
          <Link to={'/admin/user-management'} onClick={goBack}>
            <Button iconAfter='chevron left' size={'small'} />
          </Link>

          <Box>
            <Text as='div' size={200}>
              {strings.addUserHeading}
            </Text>
            <Text variant={'page'} tone={700}>
              {strings.addUserSubheading}
            </Text>
          </Box>
        </FlexV2>

        <FlexV2
          axisGap={400}
          direction={isAboveCustomBreakpoint ? 'row' : 'column'}
        >
          <Button type='reset' size={'small'}>
            {strings.resetFields}
          </Button>
          <Button
            loading={isLoading}
            type='submit'
            variant={'primary'}
            size={'small'}
          >
            {strings.saveUserBtn}
          </Button>
        </FlexV2>
      </FlexV2>
      {hasError && (
        <FlexV2 onClick={dismissErrorMessage} className='AddUser__FormErrors'>
          <FlexV2>
            <Icon name='warning' />
            {errorMessage}
          </FlexV2>
          <Icon name='close' />
        </FlexV2>
      )}

      <FlexV2
        className='AddUser__FormContent'
        direction={'column'}
        alignMainAxis={'center'}
      >
        <Label>
          <Text variant={'page'} tone={700}>
            {strings.firstName} *
          </Text>
          <Input
            required
            disabled={isLoading}
            name={USER_FORM_ENUM.FIRST_NAME}
            value={state.firstName.value}
            onChange={onChangeInput}
          />
          {state.firstName.hasError && (
            <Text size='small' variant='error'>
              {state.firstName.error}
            </Text>
          )}
        </Label>

        <Label>
          <Text variant={'page'} tone={700}>
            {strings.lastName} *
          </Text>
          <Input
            required
            name={USER_FORM_ENUM.LAST_NAME}
            value={state.lastName.value}
            onChange={onChangeInput}
            disabled={isLoading}
          />
          {state.lastName.hasError && (
            <Text size='small' variant='error'>
              {state.lastName.error}
            </Text>
          )}
        </Label>

        <Label>
          <Text variant={'page'} tone={700}>
            {strings.userName} *
          </Text>
          <Input
            required
            name={USER_FORM_ENUM.USER_NAME}
            value={state.userName.value}
            onChange={onChangeInput}
            disabled={isLoading}
          />
          {state.userName.hasError && (
            <Text size='small' variant='error'>
              {state.userName.error}
            </Text>
          )}
        </Label>

        <Label>
          <Text variant={'page'} tone={700}>
            {strings.email} *
          </Text>
          <Input
            type='email'
            required
            name={USER_FORM_ENUM.EMAIL}
            value={state.email.value}
            onChange={onChangeInput}
            disabled={isLoading}
          />
          {state.email.hasError && (
            <Text size='small' variant='error'>
              {state.email.error}
            </Text>
          )}
        </Label>

        <Label>
          <Text variant={'page'} tone={700}>
            {strings.role} *
          </Text>
          <Select
            required
            value={state.role.value}
            onChange={onSelectRole}
            disabled={isLoading}
          >
            <option default value=''>
              {strings.userFormUserSelectPlaceholder}
            </option>
            {roles.map(({ name }) => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </Select>
          {state.role.hasError && (
            <Text size='small' variant='error'>
              {state.role.error}
            </Text>
          )}
        </Label>

        <Label>
          <Text variant={'page'} tone={700}>
            {strings.phoneNumber}
          </Text>
          <PhoneInput
            international
            value={state.phoneNumber.value}
            onChange={onChangePhoneNumber}
            disabled={isLoading}
          />
          {state.phoneNumber.hasError && (
            <Text size='small' variant='error'>
              {state.phoneNumber.error}
            </Text>
          )}
        </Label>
      </FlexV2>
    </Form>
  )
}

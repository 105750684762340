import { Box } from '../'
import './index.scss'

const truncateText = (children, truncate = null) => {
  if (truncate === null || children.length <= truncate) return children
  return children.slice(0, truncate - 1).trim() + '…'
}

/**
 * Text Primitive
 * @param { Object } props
 * @param { String } props.className
 * @param { Array } props.children
 * @param { 'underline' | 'overline' | 'line-through' } props.decoration
 * @param { 100 | 200 | 300 | 400 | 500 | 600 | 700 } props.fontWeight
 * @param { 'mono' | 'sans' | 'serif' | 'logo' } props.fontFamily
 * @param { 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 | 1000 } props.size
 * @param { 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'i' | 'u' | 'abbr' | 'cite' | 'del' | 'em' | 'ins' | 'kbd' | 'mark' | 's' | 'samp' | 'sub' | 'sup' | 'strong' } [props.as='span']
 * @param { 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 } [props.tone=500]
 * @param { Number } props.truncate
 * @param { 'capitalize' | 'uppercase' | 'lowercase' | 'none' } props.textTransform
 * @param { 'page' | 'primary' | 'success' | 'graph'| 'info' | 'error' | 'warning' | 'danger' | 'neutral' } props.variant
 */

const Text = ({
  as = 'span',
  className = '',
  children = null,
  decoration = null,
  fontWeight = null,
  fontFamily = null,
  size = null,
  tone = null,
  truncate = null,
  textTransform = null,
  variant = null,
  ...rest
}) => (
  <Box
    as={as}
    className={[
      'Text',
      {
        [`td-${decoration}`]: decoration,
        [`fw-${fontWeight}`]: fontWeight,
        [`ff-${fontFamily}`]: fontFamily,
        [`fs-${size}`]: size,
        [`tt-${textTransform}`]: textTransform,
        [`cv-${variant}`]: variant,
        [`tn-${tone}`]: tone
      },
      className
    ]}
    {...rest}
  >
    {truncateText(children, truncate)}
  </Box>
)

export default Text

import ZoneUtils from '../../../../../Util/ZoneUtils'
import { formatDate } from '../../utils'

import Strings from '../Strings'

const strings = Strings()

const getOrganizationName = (organizations, organizationId) => {
  for (let organization of organizations) {
    if (organization.id === organizationId) {
      return organization.name
    }
  }
  return null
}

const getZoneName = (zonesHierarchy, site, zone) => {
  return ZoneUtils.getZoneName(zonesHierarchy, site, zone)
}

const splitGrades = grades => {
  return grades.map(grade => grade.name)
}

const splitWeights = (weights, grades) => {
  return grades.map(grade => {
    for (let weight of weights) {
      if (weight.gradeId === grade.id) {
        return weight.totalKg
      }
    }
    return null
  })
}

export const DOWNLOAD_HEADERS = grades => [
  strings.createdAt,
  strings.updatedAt,
  strings.organization,
  strings.site,
  strings.zone,
  strings.harvestDate,
  strings.produce,
  strings.variety,
  strings.storageFacility,
  strings.totalWeightKg,
  strings.weights,
  ...splitGrades(grades)
]

export const DOWNLOAD_VALUES = (item, organizations, zones, grades) => {
  return [
    formatDate(item.createdAt, 'D TT'),
    formatDate(item.updatedAt, 'D TT'),
    getOrganizationName(organizations, item.organizationId),
    getZoneName(zones, item.siteId),
    getZoneName(zones, item.siteId, item.zoneId),
    formatDate(item.harvestDate, 'D'),
    item.produce.name,
    item.variety.name,
    item.storageFacility.name,
    item.totalWeight,
    ...splitWeights(item.weights, grades)
  ]
}

import { DateTime } from 'luxon'

import { Button, Cell, Row } from '../../../../../primitives'

import Strings from '../Strings'

function TableRow({ item, onEdit, canEdit }) {
  const strings = Strings()

  const { id, returnDate, referenceNumber, customer, branch } = item

  const handleEdit = () => {
    onEdit(id)
  }

  return (
    <Row>
      <Cell columnName={strings.return}>
        {DateTime.fromMillis(returnDate).toFormat('DDD')}
        <span>{referenceNumber}</span>
      </Cell>
      <Cell columnName={strings.customer}>
        {customer.name}
        <span>{branch.name}</span>
      </Cell>
      <Cell className='Operations__Table__Column__Actions'>
        {canEdit && (
          <Button
            variant='info'
            size='small'
            iconBefore='edit'
            onClick={handleEdit}
          />
        )}
      </Cell>
    </Row>
  )
}

export default TableRow

export const initialState = {
  organizations: [],
  customers: [],
  products: [],
  page: 1
}

const reducer = (state, action) => {
  if (action.state) {
    return {
      ...state,
      ...action.state
    }
  } else if (action.page) {
    return {
      ...state,
      page: action.page
    }
  } else if (action.organizations) {
    return {
      ...state,
      organizations: action.organizations,
      customers: []
    }
  } else if (action.customers) {
    return {
      ...state,
      customers: action.customers
    }
  } else if (action.products) {
    return {
      ...state,
      products: action.products
    }
  }
  return state
}

export default reducer

import { useReducer, useState, useEffect, Fragment, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { I18n } from 'aws-amplify'

import {
  getCurrentUserOrganizations,
  getIsLoading,
  getZonesHierarchy,
  getLocation,
  getCurrentUser
} from '@/reducers/selectors'

import { fetchLocation } from '@/slices/location'

import Strings from '@/components/AdminPage/Strings'
import { Button, Flex, Form, Input, Label, Text, Icon, Box } from '@/primitives'
import { Select } from '@/elements'

import timezones from '@/Util/timezones.json'
import AdminUtils from '@/Util/AdminUtils'
import { hasEditPermissions, RESOURCE_TYPE_ZONE } from '@/Util/PermissionUtils'
import useOrgLabel from '@/hooks/useOrgLabel'

const defaultValues = {
  name: '',
  status: 'active',
  code: '',
  parentId: null,
  latitude: '0',
  longitude: '0',
  timeZone: '',
  organizationId: '',
  id: null
}

const statusOptions = [
  {
    value: 'active',
    label: I18n.get('Active')
  },
  {
    value: 'inactive',
    label: I18n.get('Inactive')
  }
]

const timezoneOptions = timezones.map(name => {
  return {
    value: name,
    label: name
  }
})

const { siteEnrollmentSchema } = AdminUtils

function reducer(state, action) {
  const { type, value } = action
  const updatedState = { ...state }

  switch (type) {
    case 'CLEAR_ALL':
      return defaultValues
    case 'UPDATE_ALL':
      return { ...updatedState, ...value }
    default:
      updatedState[type] = value
      return updatedState
  }
}

const SiteForm = ({ site, onClickContinue }) => {
  const strings = Strings()

  const {
    longitudeCoords,
    latitudeCoords,
    timezoneName,
    statusName,
    selectTimezone,
    code
  } = strings

  const [errors, setErrors] = useState({})
  const [sitesOptions, setSitesOptions] = useState([])
  const [showAddSite, setShowAddSite] = useState(true)
  const [state, dispatch] = useReducer(reducer, defaultValues)
  const orgLabel = useOrgLabel(['site'])
  const reduxDispatch = useDispatch()

  const organizations = getCurrentUserOrganizations()
  const isLoading = getIsLoading()
  const zonesHierarchy = getZonesHierarchy()
  const location = getLocation()
  const currentUser = getCurrentUser()

  const userCanEdit = hasEditPermissions(currentUser, RESOURCE_TYPE_ZONE)

  const getAllFieldValues = useCallback(
    value => {
      const {
        code,
        id,
        latitude,
        longitude,
        name,
        organizationId,
        timeZone,
        status
      } = zonesHierarchy[value]
      return {
        code,
        id,
        latitude,
        longitude,
        name,
        organizationId,
        timeZone,
        status
      }
    },
    [zonesHierarchy]
  )

  useEffect(() => {
    if (site?.id) dispatch({ type: 'UPDATE_ALL', value: site })
    reduxDispatch(fetchLocation())
  }, [reduxDispatch, site])

  useEffect(() => {
    if (organizations?.length === 1) {
      dispatch({
        type: 'organization',
        value: organizations[0]
      })
    }
  }, [organizations])

  useEffect(() => {
    if (Object.keys(zonesHierarchy).length > 0) {
      const options = Object.keys(zonesHierarchy).map(siteId => {
        return { value: siteId, label: zonesHierarchy[siteId].name }
      })

      if (options?.length === 1) {
        const updateFields = getAllFieldValues(options[0].value)
        dispatch({ type: 'UPDATE_ALL', value: updateFields })
      }

      setSitesOptions(options)
      setShowAddSite(false)
    }
  }, [zonesHierarchy, getAllFieldValues])

  useEffect(() => {
    if (showAddSite) {
      dispatch({
        type: 'latitude',
        value: (location?.latitude ?? 0).toString()
      })
      dispatch({
        type: 'longitude',
        value: (location?.longitude ?? 0).toString()
      })
    }
  }, [location, showAddSite])

  const onChange = ({ target: { name, value } }) => {
    dispatch({ type: name, value })
  }

  const onSelect = ({ value }, { name: fieldName }) => {
    if (fieldName !== 'id') dispatch({ type: fieldName, value })
    if (fieldName === 'id') {
      const updateFields = getAllFieldValues(value)
      dispatch({ type: 'UPDATE_ALL', value: updateFields })
    }
  }

  const formatLongitudeLatitude = newValue => {
    if (typeof newValue !== 'string') return newValue
    return parseFloat(newValue.replace(',', '.'))
  }

  const onSubmitForm = async e => {
    const longitude = formatLongitudeLatitude(state.longitude)
    const latitude = formatLongitudeLatitude(state.latitude)
    const zoneDetails = {
      ...state,
      longitude,
      latitude
    }

    try {
      await siteEnrollmentSchema().validate(zoneDetails, {
        abortEarly: false
      })

      setErrors({})
      onClickContinue('site', zoneDetails)
    } catch (err) {
      if (err.inner) {
        let validationErrors = []
        err?.inner?.forEach(({ message, path }) => {
          validationErrors[path] = message
        })

        setErrors(validationErrors)
      }
    }
  }

  const toggleAddSite = () => {
    dispatch({ type: 'CLEAR_ALL' })
    setErrors({})

    if (showAddSite && sitesOptions?.length === 1) {
      const updateFields = getAllFieldValues(sitesOptions[0].value)
      dispatch({ type: 'UPDATE_ALL', value: updateFields })
    }
    setShowAddSite(!showAddSite)
  }

  const orgOptions = organizations.map(({ id, name }) => ({
    value: id,
    label: name
  }))

  const insertError = field => {
    if (!errors[field]) return null

    let styles = {}
    if (field === 'organizationId' || field === 'timeZone') {
      styles = { marginInlineStart: 'calc(100% - 3.2rem)' }
    }

    return (
      <Fragment>
        <Icon
          className='Enrollment__Form__InputErrorIcon'
          name='error'
          variant='error'
          tone={500}
          style={styles}
        />
        <Text className='Enrollment__Form__InputError' variant='error'>
          {errors[field]}
        </Text>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Box className='Heading'>
        <Text as='h4'>
          {showAddSite
            ? `${I18n.get('Create a new')} ${orgLabel.site.text}`
            : `${I18n.get('Choose a')} ${orgLabel.site.text}`}
        </Text>
        <Text
          size={100}
          variant='page'
          tone={900}
          style={{ textAlign: 'left', display: 'inline-block' }}
        >
          {I18n.get(
            'This is the physical location of your operation, pinpointed by a unique geographic coordinate. This coordinate may represent an expansive area that includes a variety of areas and sub-areas.'
          )}
        </Text>
      </Box>
      <Flex
        style={{
          marginTop: '3rem',
          maxWidth: '30rem',
          width: '100%',
          margin: 'auto'
        }}
        alignMainAxis='center'
      >
        <Form autoComplete='off' className='Enrollment__Form'>
          {!showAddSite && sitesOptions?.length > 0 && (
            <Fragment>
              <Label className={sitesOptions?.length === 1 ? 'disabled' : ''}>
                <Text variant='page' tone={700}>
                  {orgLabel.site.text}
                </Text>
                <Select
                  className='Enrollment__Select'
                  name='id'
                  value={state.id}
                  placeholder={`${I18n.get('Select an existing')} ${
                    orgLabel.site.text
                  }`}
                  options={sitesOptions}
                  onChange={onSelect}
                  controlStyles={{ borderRadius: '3px' }}
                  isDisabled={sitesOptions?.length === 1}
                />
              </Label>
              {userCanEdit && (
                <Button
                  onClick={toggleAddSite}
                  variant='text'
                  size='small'
                  iconBefore='add'
                  style={{
                    margin: '-3rem 0 0 0',
                    backgroundColor: 'transparent',
                    padding: '0',
                    height: 'auto',
                    color: 'var(--ctx-theme-color-page-800)'
                  }}
                >
                  <Text variant='page' tone={800}>
                    {I18n.get('Add new')} {orgLabel.site.text}
                  </Text>
                </Button>
              )}
            </Fragment>
          )}
          {sitesOptions?.length === 0 && !userCanEdit && (
            <Text
              as='p'
              variant='danger'
              tone={600}
              style={{ marginTop: '2rem', textAlign: 'center' }}
            >
              {I18n.get('You do not have permission to create')}{' '}
              {orgLabel.site.textPlural}.{' '}
              {I18n.get('Please talk to your administrator.')}
            </Text>
          )}
          {(showAddSite || sitesOptions?.length === 0) && userCanEdit && (
            <Fragment>
              <Label>
                <Text variant={'page'} tone={700}>
                  {orgLabel.site.text} {I18n.get('name')}
                </Text>
                <Input
                  name='name'
                  value={state.name}
                  onChange={onChange}
                  className={errors.name ? 'Error' : ''}
                />
              </Label>
              {insertError('name')}
              <Flex
                wrap='nowrap'
                direction='row'
                alignMainAxis='space-between'
                axisGap={300}
              >
                <Box>
                  <Label style={{ flexGrow: '1 ' }}>
                    <Text variant='page' tone={700}>
                      {latitudeCoords}
                    </Text>
                    <Input
                      name='latitude'
                      value={state.latitude}
                      onChange={onChange}
                      className={errors.latitude ? 'Error' : ''}
                    />
                  </Label>
                  {insertError('latitude')}
                </Box>
                <Box>
                  <Label style={{ flexGrow: '1 ' }}>
                    <Text variant='page' tone={700}>
                      {longitudeCoords}
                    </Text>
                    <Input
                      name='longitude'
                      value={state.longitude}
                      onChange={onChange}
                      className={errors.longitude ? 'Error' : ''}
                    />
                  </Label>
                  {insertError('longitude')}
                </Box>
              </Flex>
              <Label>
                <Text variant='page' tone={700}>
                  {timezoneName}
                </Text>
                <Select
                  isSearchable={true}
                  className='Enrollment__Select'
                  controlStyles={
                    errors.timeZone
                      ? { border: '1px solid var(--ctx-theme-color-error-500)' }
                      : {}
                  }
                  name='timeZone'
                  value={state.timeZone}
                  placeholder={selectTimezone}
                  options={timezoneOptions}
                  onChange={onSelect}
                />
              </Label>
              {insertError('timeZone')}
              {organizations?.length > 0 && (
                <Fragment>
                  <Label>
                    <Text variant={'page'} tone={700}>
                      {I18n.get('Organization')}
                    </Text>
                    <Select
                      className='Enrollment__Select'
                      controlStyles={
                        errors.organizationId
                          ? {
                              border:
                                '1px solid var(--ctx-theme-color-error-500)'
                            }
                          : {}
                      }
                      name='organizationId'
                      value={state.organizationId}
                      placeholder={I18n.get('Select an organization')}
                      options={orgOptions}
                      onChange={onSelect}
                    />
                  </Label>
                  {insertError('organizationId')}
                </Fragment>
              )}
              <Label>
                <Text variant='page' tone={700}>
                  {statusName}
                </Text>
                <Select
                  className='Enrollment__Select'
                  name='status'
                  value={state.status}
                  options={statusOptions}
                  onChange={onSelect}
                  isDisabled={true}
                />
              </Label>
              <Label>
                <Text variant={'page'} tone={700}>
                  {code}
                </Text>
                <Input name='code' value={state.code} onChange={onChange} />
              </Label>
            </Fragment>
          )}
        </Form>
        <Flex
          axisGap={400}
          alignMainAxis='space-between'
          className='Button__Container'
        >
          {sitesOptions?.length > 0 && showAddSite && (
            <Button
              variant='danger'
              size='small'
              onClick={toggleAddSite}
              loading={isLoading}
              className='Cancel__Button'
            >
              {I18n.get('Cancel')}
            </Button>
          )}
          <Button
            variant='primary'
            size='small'
            disabled={!state.name && !state.id}
            onClick={onSubmitForm}
            loading={isLoading}
            className='Continue__Button'
            style={
              showAddSite && sitesOptions?.length > 0
                ? {}
                : { width: '100%', flexGrow: '1' }
            }
          >
            {I18n.get('Continue')}
          </Button>
        </Flex>
      </Flex>
    </Fragment>
  )
}

export default SiteForm

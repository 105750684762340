import { useReducer, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import { Box, Loader } from '@/primitives'

import { fetchDevice } from '@/slices/management/device'
import { fetchRootZone } from '@/slices/management/zone'
import { fetchZoneHierarchy } from '@/slices/management/hierarchy'
import { setLocationSelector } from '@/slices/navigation'
import { fetchDashboardForecast } from '@/slices/weather/forecast'

import {
  getZoneHierarchy,
  getZoneHierarchyLoading,
  getMeasurements,
  getZonesHierarchy,
  getRootZone,
  getDeviceTypes,
  getLoadingWeatherForecast,
  getCurrentUser
} from '@/reducers/selectors'

import ZoneUtils from '@/Util/ZoneUtils'

import { MobileDashboardContext } from './context'
import {
  mobileDashboardInitialState,
  mobileDashboardReducer,
  SET_CURRENT_ZONE,
  SET_HIERARCHY,
  SET_IS_CURRENT_ZONE_404,
  SET_SITE_ID
} from './state'

import { useDashboardParams } from '../utils/useDashboardParams'

import { sortMeasurementsForDashboard } from '@/Util/MeasurementUtils'
import {
  getAllReadMeasurements,
  getAllReadMeasurementsForDevice
} from '@/Util/ZoneUtils'


import { cloneFlatZone, getSiteIdFromZonePath, sortMeasurementsByDeviceTypes } from '../utils'

import { useLiveDataFeed } from './Hooks/useLiveDataFeed'

import Header from './Header'
import Widgets from './Widgets'
import Zone404 from './Zone404'

import { buildNavigationSelector } from '@/Util/NavigationUtils'

const isReady = (
  state,
  zoneHierarchyLoading,
  allMeasurements,
  allDeviceTypes,
  weatherLoading
) => {
  const isZoneHierarchyReady = !zoneHierarchyLoading
  const isCurrentZoneReady = state.currentZone || state.isCurrentZone404
  const isHierarchyReady = state.hierarchy
  const isWeatherReady = !weatherLoading

  return (
    isZoneHierarchyReady &&
    isCurrentZoneReady &&
    isHierarchyReady &&
    allMeasurements.length > 0 &&
    allDeviceTypes.length > 0 &&
    isWeatherReady
  )
}

export default function MobileDashboard() {
  const zoneHierarchy = getZoneHierarchy()
  const zoneHierarchyLoading = getZoneHierarchyLoading()
  const weatherLoading = getLoadingWeatherForecast()

  const params = useDashboardParams()
  const dispatch = useDispatch()

  const zonesHierarchy = getZonesHierarchy()
  const allMeasurements = getMeasurements()
  const allDeviceTypes = getDeviceTypes()
  const rootZone = getRootZone()
  const currentUser = getCurrentUser()

  const [state, dispatchState] = useReducer(
    mobileDashboardReducer,
    mobileDashboardInitialState
  )

  const [prevCurrentZone, setPrevCurrentZone] = useState(null)
  const [availableMeasurements, setAvailableMeasurements] = useState([])

  // Websocket
  useLiveDataFeed(state)

  const setIsCurrentZone404 = is404 => {
    dispatchState({
      type: SET_IS_CURRENT_ZONE_404,
      payload: { is404 }
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (state.currentZone?.id && state.hierarchy) {
      const zoneOrg = currentUser.organizations.find(
        org => org.id === state.hierarchy?.organizationId
      )

      const navSelector = buildNavigationSelector(
        state.hierarchy,
        state.currentZone.parentPath,
        zoneOrg
      )

      dispatch(setLocationSelector(navSelector))
    }
  }, [
    state.currentZone,
    state.hierarchy,
    currentUser.organizations,
    dispatch
  ])

  // fetches device on params.sensorId change
  useEffect(() => {
    if (params.sensorId) {
      dispatch(
        fetchDevice({
          deviceId: params.sensorId
        })
      )
    }
  }, [params.sensorId, dispatch])

  useEffect(() => {
    if (zonesHierarchy && allMeasurements && allDeviceTypes && rootZone) {
      let allReadMeasurements = []
      const sensorId = params?.sensorId ?? null

      if (state.siteId && sensorId) {
        allReadMeasurements = getAllReadMeasurementsForDevice(
          zonesHierarchy,
          rootZone?.id,
          sensorId,
          allDeviceTypes
        )
      } else if (state.siteId && state?.currentZone?.id) {
        if (zonesHierarchy[state.siteId]) {
          allReadMeasurements = getAllReadMeasurements(
            zonesHierarchy,
            rootZone?.id,
            state?.currentZone?.id,
            allDeviceTypes
          )
        }
      }

      if (allReadMeasurements?.length > 0) {
        const sortedMeasurementsId = sortMeasurementsForDashboard(
          allMeasurements,
          allReadMeasurements
        ).map(measurement => measurement.id)

        const sortedMeasurementsIdByDeviceTypes = sortMeasurementsByDeviceTypes(
          sortedMeasurementsId,
          allDeviceTypes
        )

        setAvailableMeasurements(sortedMeasurementsIdByDeviceTypes)
      }
    }
  }, [
    zonesHierarchy,
    state?.currentZone?.id,
    params?.sensorId,
    allMeasurements,
    allDeviceTypes,
    rootZone,
    state.siteId
  ])

  useEffect(() => {
    // Fetches zoneHierachy from url params & sets siteId
    if (params.zone) {
      const siteId = getSiteIdFromZonePath(params.zone)

      if (state.siteId !== siteId) {
        dispatchState({
          type: SET_SITE_ID,
          payload: { siteId }
        })

        if (!zoneHierarchyLoading) {
          dispatch(
            fetchZoneHierarchy({
              zoneId: siteId
            })
          )
        }
      }
    }
  }, [params.zone, state.siteId, dispatch, zoneHierarchyLoading])

  useEffect(() => {
    if (state?.siteId) {
      dispatch(fetchRootZone({ zoneId: state.siteId }))
      dispatch(fetchDashboardForecast({ siteId: state.siteId, period: 'h12' }))
    }
  }, [state?.siteId, dispatch])

  // Sets Hierarchy to state once zoneHierachy has been fetched
  if (!zoneHierarchyLoading && state.siteId && !state.hierarchy) {
    if (!isEmpty(zoneHierarchy)) {
      setIsCurrentZone404(false)
      dispatchState({
        type: SET_HIERARCHY,
        payload: { hierarchy: zoneHierarchy[state.siteId] }
      })
    } else if (!state.isCurrentZone404) {
      setIsCurrentZone404(true)
    }
  }

  // Sets current zone to state once hierarchy has been set
  useEffect(() => {
    if (
      params.zone &&
      state.siteId &&
      state.hierarchy &&
      Object.keys(state.hierarchy).length > 0 &&
      !state.isCurrentZone404
    ) {
      const zone = ZoneUtils.getZoneHierarchyValueByPath(
        { ...state.hierarchy },
        params.zone
      )
      if (!zone) {
        setIsCurrentZone404(true)
      }
      if (!isEmpty(zone) && zone.id !== prevCurrentZone?.id) {
        setAvailableMeasurements([])
        setIsCurrentZone404(false)
        const clonedZone = cloneFlatZone({ ...zone })
        dispatchState({
          type: SET_CURRENT_ZONE,
          payload: { zone: clonedZone }
        })
        setPrevCurrentZone(zone)
      }
    }
  }, [
    params.zone,
    state.siteId,
    state.hierarchy,
    state.isCurrentZone404,
    prevCurrentZone?.id
  ])

  // Mobile dashboard context
  const mobileDashboardContext = {
    state,
    dispatchState
  }

  const isLoading = !isReady(
    state,
    zoneHierarchyLoading,
    allMeasurements,
    allDeviceTypes,
    weatherLoading
  )

  return (
    <Box className='MobileDashboard'>
      <MobileDashboardContext.Provider value={mobileDashboardContext}>
        <Loader isLoading={isLoading && !state.isCurrentZone404}>
          {state.isCurrentZone404 && <Zone404 />}
          {!isLoading && !state.isCurrentZone404 && (
            <Box className='MobileDashboard__Content'>
              <Header />
              <Widgets measurements={availableMeasurements} />
            </Box>
          )}
        </Loader>
      </MobileDashboardContext.Provider>
    </Box>
  )
}

import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { getCognitoData, makeGraphqlRequest } from '../utils'

export const getDirectConnectDevicesStatus = async params => {
  try {
    const query = /* GraphQL */ `
      query startPingQueryDirectConnect(
        $userId: String!
        $siteId: String!
        $devices: [DeviceInput]!
      ) {
        startPingQueryDirectConnect(
          userId: $userId
          siteId: $siteId
          devices: $devices
        ) {
          devices {
            deviceId
            status
            updatedAt
          }
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        devices: null,
        error: `Failed to fetch Device Status: ${error}`
      }
    }

    return { devices: data?.startPingQueryDirectConnect?.devices, error }
  } catch (err) {
    return { devices: null, error: err.message }
  }
}

export const getGreengrassDevicesStatus = async params => {
  try {
    const query = /* GraphQL */ `
      query startPingQueryGreengrass(
        $userId: String!
        $siteId: String!
        $coreDevices: [GreengrassPingDevice]!
      ) {
        startPingQueryGreengrass(
          userId: $userId
          siteId: $siteId
          coreDevices: $coreDevices
        ) {
          devices {
            deviceId
            status
            updatedAt
          }
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        devices: null,
        error: `Failed to fetch Device Status: ${error}`
      }
    }

    return { devices: data?.startPingQueryGreengrass?.devices, error }
  } catch (err) {
    return { devices: null, error: err.message }
  }
}

export const getPingStatuses = async params => {
  try {
    const query = /* GraphQL */ `
      query getPingStatuses($deviceIds: [String]!) {
        getPingStatuses(deviceIds: $deviceIds) {
          devices {
            deviceId
            status
            updatedAt
          }
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        deviceStatuses: null,
        error: `Failed to fetch device statuses: ${error}`
      }
    }

    return { deviceStatuses: data?.getPingStatuses?.devices, error }
  } catch (err) {
    return { deviceStatuses: null, error: err.message }
  }
}

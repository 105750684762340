import { I18n } from 'aws-amplify'
import { call, put, takeLatest } from 'redux-saga/effects'

import {
  FETCH_CORE_DEVICE_PROVISION_INSTALLER,
  START_CORE_DEVICE_UPDATES,
  START_GREENGRASS_CORE_DEVICE_DEFAULT_DEPLOYMENT,
  coreDeviceProvisionInstallerFetched,
  coreDeviceUpdatesStarted,
  greengrassCoreDeviceDefaultDeploymentStarted
} from '@/actions/management/greengrass'

import { showBanner } from '@/slices/util'

import {
  buildProvisionInstaller,
  startCoreDeviceUpdates,
  startGreengrassCoreDeviceDefaultDeployment
} from '@/api/management/greengrass'

export function* sendStartGreengrassCoreDeviceDefaultDeployment(action) {
  const { message = {}, error } = yield call(
    startGreengrassCoreDeviceDefaultDeployment,
    action.params
  )
  yield put(greengrassCoreDeviceDefaultDeploymentStarted(message, error))

  if (!error) {
    yield put(
      showBanner({
        type: 'success',
        show: true,
        message: I18n.get('Start creation of default deployment successfully.')
      })
    )
  }
}

export function* watchStartGreengrassCoreDeviceDefaultDeployment() {
  yield takeLatest(
    START_GREENGRASS_CORE_DEVICE_DEFAULT_DEPLOYMENT,
    sendStartGreengrassCoreDeviceDefaultDeployment
  )
}

export function* sendStartCoreDeviceUpdates(action) {
  const { message = {}, error } = yield call(
    startCoreDeviceUpdates,
    action.params
  )

  yield put(coreDeviceUpdatesStarted(message, error))

  if (!error) {
    yield put(
      showBanner({
        type: 'success',
        show: true,
        message: I18n.get('Started core device updates successfully')
      })
    )
  } else {
    yield put(
      showBanner({
        type: 'error',
        show: true,
        message: error
      })
    )
  }
}

export function* watchSendStartCoreDeviceUpdates() {
  yield takeLatest(START_CORE_DEVICE_UPDATES, sendStartCoreDeviceUpdates)
}

export function* sendFetchCoreDeviceProvisionInstaller(action) {
  try {
    const installer = yield call(buildProvisionInstaller, action.params)
    let link = document.createElement('a')
    link.href = installer.fileUrl
    link.download = installer.fileUrl
    document.body.appendChild(link)
    link.click()
    link.remove()
    yield put(coreDeviceProvisionInstallerFetched(null))
  } catch (error) {
    yield put(
      showBanner({
        type: 'error',
        show: true,
        message: I18n.get('Error fetching core device provision installer')
      })
    )
    yield put(coreDeviceProvisionInstallerFetched(error))
  }
}

export function* watchFetchCoreDeviceProvisionInstaller() {
  yield takeLatest(
    FETCH_CORE_DEVICE_PROVISION_INSTALLER,
    sendFetchCoreDeviceProvisionInstaller
  )
}

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {
  getUsagePlanByOrg,
  createUsagePlan,
  updateUsagePlan,
  getApiKeys,
  regenerateApiKey
} from '@/api/ts/usagePlan'

export const cleanUsagePlan = createAsyncThunk('cleanUsagePlan', () => {
  return {}
})

export const fetchUsagePlan = createAsyncThunk(
  'fetchUsagePlan',
  async params => {
    const { usagePlan, error } = await getUsagePlanByOrg(params)
    return { usagePlan, error }
  }
)

export const sendCreateUsagePlan = createAsyncThunk(
  'sendCreateUsagePlan',
  async params => {
    const { usagePlan, error } = await createUsagePlan(params)
    return { usagePlan, error }
  }
)

export const sendUpdateUsagePlan = createAsyncThunk(
  'sendUpdateUsagePlan',
  async params => {
    const { usagePlan, error } = await updateUsagePlan(params)
    return { usagePlan, error }
  }
)

export const cleanApiKey = createAsyncThunk('cleanApiKey', () => {
  return {}
})

export const fetchApiKeys = createAsyncThunk('fetchApiKeys', async params => {
  const { apiKeys, error } = await getApiKeys(params)
  return { apiKeys, error }
})

export const sendRegenerateApiKey = createAsyncThunk(
  'sendRegenerateApiKey',
  async params => {
    const { apiKey, error } = await regenerateApiKey(params)
    return { apiKey, error }
  }
)

const timestreamUsagePlanSlice = createSlice({
  name: 'timestreamUsagePlanReducer',
  initialState: {
    usagePlan: {},
    apiKeys: [],
    apiKey: {},
    loading: false,
    regenerating: false
  },
  extraReducers: builder => {
    builder
      .addCase(cleanUsagePlan.fulfilled, (state, action) => {
        state.error = null
        state.usagePlan = {}
      })
      .addCase(fetchUsagePlan.pending, (state, action) => {
        state.error = null
        state.loading = true
        state.usagePlan = {}
      })
      .addCase(fetchUsagePlan.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.loading = false
        state.usagePlan = action?.payload?.usagePlan ?? {}
      })
      .addCase(sendCreateUsagePlan.pending, (state, action) => {
        state.error = null
        state.loading = true
        state.usagePlan = {}
      })
      .addCase(sendCreateUsagePlan.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.loading = false
        state.usagePlan = action?.payload?.usagePlan ?? {}
      })
      .addCase(sendUpdateUsagePlan.pending, (state, action) => {
        state.error = null
        state.loading = true
      })
      .addCase(sendUpdateUsagePlan.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.loading = false
        state.usagePlan = action?.payload?.usagePlan ?? {}
      })
      .addCase(cleanApiKey.fulfilled, (state, action) => {
        state.error = null
        state.apiKey = {}
      })
      .addCase(fetchApiKeys.pending, (state, action) => {
        state.error = null
        state.loading = true
      })
      .addCase(fetchApiKeys.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.loading = false
        state.apiKeys = action?.payload?.apiKeys ?? []
      })
      .addCase(sendRegenerateApiKey.pending, (state, action) => {
        state.error = null
        state.regenerating = true
        state.apiKey = {}
      })
      .addCase(sendRegenerateApiKey.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.regenerating = false
        state.apiKey = action?.payload?.apiKey ?? {}
        state.apiKeys = state.apiKeys.map(item => {
          if (item.name === action?.payload?.apiKey?.name) {
            return action?.payload?.apiKey
          }
          return item
        })
      })
  }
})

export default timestreamUsagePlanSlice.reducer

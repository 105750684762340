export const SALE_STATUS_TYPE = strings => ({
  processing: strings.processing,
  production: strings.production,
  delayed: strings.delayed,
  transit: strings.transit,
  partial: strings.partiallyDelivered,
  delivered: strings.delivered,
  canceled: strings.canceled
})

export const INVOICE_STATUS_TYPE = strings => ({
  pending: strings.pending,
  issued: strings.issued,
  submitted: strings.submitted,
  approval: strings.awaitingApproval,
  payment: strings.awaitingPayment,
  paid: strings.paid
})

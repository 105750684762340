import { I18n } from 'aws-amplify'

export function getMeasurementStrings(measurementId) {
  const measurements = {
    environment: {
      shortName: I18n.get('Environment')
    },
    temperature: {
      shortName: I18n.get('Temperature')
    },
    absolute_humidity: { shortName: I18n.get('Absolute Humidity') },
    air_pressure: { shortName: I18n.get('Air Pressure') },
    saturation_vapor_pressure: {
      shortName: I18n.get('SVP')
    },
    fan_speed: { shortName: I18n.get('Fan Speed') },
    airflow: { shortName: I18n.get('Airflow') },
    carbon_dioxide: { shortName: I18n.get('Carbon Dioxide') },
    uv_index: { shortName: I18n.get('UV Index') },
    daily_light_integral: { shortName: I18n.get('DLI') },
    solar_irradiance: { shortName: I18n.get('Solar Irradiance') },
    vpd: { shortName: I18n.get('VPD') },
    humidity: { shortName: I18n.get('Relative Humidity') },
    fertigation: { shortName: I18n.get('Fertigation') },
    water_temperature: { shortName: I18n.get('Water Temperature') },
    conductivity: { shortName: I18n.get('Conductivity') },
    ph: { shortName: I18n.get('pH') },
    dissolved_oxygen: { shortName: I18n.get('Dissolved Oxygen') },
    dissolved_oxygen_percentage: {
      shortName: I18n.get('Dissolved Oxygen Percentage')
    },
    salinity: { shortName: I18n.get('Salinity') },
    liquid_flow: { shortName: I18n.get('Liquid Flow') },
    soil_moisture: { shortName: I18n.get('Soil Moisture') },
    soil_temperature: { shortName: I18n.get('Soil Temperature') },
    soil_ph: { shortName: I18n.get('Soil pH') },
    soil_ec: { shortName: I18n.get('Soil Conductivity') },
    soil_phosphorus: { shortName: I18n.get('Soil P Content') },
    soil_potassium: { shortName: I18n.get('Soil K Content') },
    soil_nitrogen: { shortName: I18n.get('Soil N Content') },
    plant_science: { shortName: I18n.get('Plant Science') },
    wet_bulb_temperature: { shortName: I18n.get('Wet Bulb Temperature') },
    resources: { shortName: I18n.get('Resources') },
    energy: { shortName: I18n.get('Energy') },
    par: { shortName: I18n.get('Photosynthetically Active Radiation') },
    power_consumed: { shortName: I18n.get('Power Consumed') },
    actuator_state: { shortName: I18n.get('Actuator State') },
    ion_concentration: { shortName: I18n.get('Ion Concentration') },
    power_generation: { shortName: I18n.get('Power Generation') },
    motor_speed: { shortName: I18n.get('Motor Speed') },
    power_consumption: { shortName: I18n.get('Power Consumption') },
    line_voltage: { shortName: I18n.get('Line Voltage') },
    vapour_pressure_deficit: {
      shortName: I18n.get('Vapour Pressure Deficit')
    },
    wind_speed: { shortName: I18n.get('Wind Speed') },
    energy_kw: { shortName: I18n.get('Energy (kW)') },
    raditation_temperature: { shortName: I18n.get('RTR') },
    co2_flow_rate: { shortName: I18n.get('CO2 Flow Rate') },
    total_dissolved_solids: { shortName: I18n.get('TDS') },
    vfd_frequency: { shortName: I18n.get('VFD') },
    uv_c_intensity: { shortName: I18n.get('UV-C Intensity') },
    liquid_level: { shortName: I18n.get('Liquid Level') },
    growing_degree_hours: { shortName: I18n.get('Growing Degree Hours') }
  }

  return measurements[measurementId] ?? {}
}

export const ORIGINAL_SUPPORTED_MEASUREMENTS = [
  'carbon_dioxide',
  'temperature',
  'pressure',
  'humidity',
  'par',
  'uv_index',
  'conductivity',
  'ph',
  'water_temperature',
  'dissolved_oxygen'
]

export const CONVERSION_TEMPERATURE_MEASUREMENTS = [
  'temperature',
  'water_temperature',
  'soil_temperature',
  'wet_bulb_temperature'
]

export const HIDDEN_WIDGET_TYPES_FOR_SITE = ['live']

export function findMeasurement(measurements, measurementId) {
  const measurement = measurements.find(({ id }) => id === measurementId)
  if (measurement) {
    return measurement
  }

  return null
}

export function findMeasurementName(
  measurements,
  measurementId,
  field = 'description'
) {
  const measurement = findMeasurement(measurements, measurementId)
  if (measurement) {
    return measurement[field]
  }

  return null
}

export function sortMeasurementsForDashboard(
  measurements,
  filterMeasurements = ORIGINAL_SUPPORTED_MEASUREMENTS
) {
  // TODO: remove hardcoded ORIGINAL_SUPPORTED_MEASUREMENTS filter array
  return (
    [...measurements]
      .sort((a, b) => {
        if (a.id < b.id) return -1
        if (a.id > b.id) return 1
        return 0
      })
      .filter(({ id }) => filterMeasurements.includes(id)) ?? []
  )
}

/*
 * Vector measurements
 */

export const convertToDLI = ({
  par,
  hours,
  interval = '1h',
  useNew = false
}) => {
  if (par === 0) {
    return 0
  }

  if (useNew) {
    if (interval === '1h' || interval === 'hourly') {
      return par * 0.0036
    }

    return 0
  }

  return (par / hours) * 0.0864
}

export const convertCelciusToFahrenheit = temperature => {
  return (temperature * 9) / 5 + 32
}

export const convertFahrenheitToCelsius = temperature => {
  return (temperature - 32) * (5 / 9)
}

export const FAHRENHEIT_UNIT = '°F'

export const convertMSToKMH = ms => {
  return ms * 3.6
}

export const convertKMHToMS = kmh => {
  return kmh * 0.28
}

export const KMH_UNIT = 'km/h'

export const convertToDCE = ({ energy_kw }) => {
  return energy_kw
}

export const convertToAbsoluteHumidity = ({ temperature, humidity }) => {
  return (
    (6.112 *
      Math.pow(Math.E, (17.67 * temperature) / (temperature + 243.5)) *
      humidity *
      2.1674) /
    (273.15 + temperature)
  )
}

export const convertToSVP = ({ temperature, humidity }) => {
  return (
    (0.61078 *
      Math.pow(Math.E, (17.27 * temperature) / (temperature + 237.3)) *
      humidity) /
    100
  )
}

export const convertParToSumRadiation = ({ par }) => {
  return ((par * 24 * 0.219) / 10000) * (60 * 60 * 24)
}

export const convertToWetBulbTemperature = ({ temperature, humidity }) => {
  return (
    temperature * Math.atan(0.151977 * Math.pow(humidity + 8.313659, 1 / 2)) +
    Math.atan(temperature + humidity) -
    Math.atan(humidity - 1.676331) +
    0.00391838 * Math.pow(humidity, 3 / 2) * Math.atan(0.023101 * humidity) -
    4.686035
  )
}

export const convertToSalinity = ({ water_temperature, conductivity }) => {
  const r = Math.sqrt(
    conductivity /
      42914 /
      (0.6766097 +
        water_temperature *
          (0.0200564 +
            water_temperature *
              (0.0001104259 +
                water_temperature *
                  (-0.00000069698 + water_temperature * 0.0000000010031))))
  )
  const d =
    (0.0005 +
      r *
        (-0.0056 +
          r * (-0.0066 + r * (-0.0375 + r * (0.0636 + r * -0.0144))))) *
    ((water_temperature - 15.0) / (1.0 + 0.0162 * (water_temperature - 15.0)))
  return (
    0.008 +
    r * (-0.1692 + r * (25.3851 + r * (14.0941 + r * (-7.0261 + r * 2.7081)))) +
    d
  )
}

export const convertToVPD = ({ temperature, humidity }) => {
  // Since we do not have leaf temperature, we assume it is 2 degrees lower than air temperature.
  const leafTemperature = temperature - 2

  // Calculate saturation vapor pressure for air and leaf
  const vapourPressureSaturationAir =
    0.6108 * Math.exp((17.27 * temperature) / (temperature + 237.3))

  const vapourPressureSaturationLeaf =
    0.6108 * Math.exp((17.27 * leafTemperature) / (leafTemperature + 237.3))

  // Calculate actual vapor pressure for air
  const vapurPressureAir = (humidity / 100) * vapourPressureSaturationAir

  // Calculate VPD
  const vpd = vapourPressureSaturationLeaf - vapurPressureAir

  return vpd
}

export const convertToGDH = ({ temperature }) => {
  //4.5 is the standard base degree value for GDH.
  const degreeTemperature = temperature - 4.5
  const gdh = degreeTemperature * 24

  return gdh
}

export const convertToTotalDissolvedSolids = ({ conductivity }) => {
  return 0.67 * conductivity
}

export const requiredVectorMeasurements = {
  absolute_humidity: ['temperature', 'humidity'],
  daily_energy_consumption: ['energy_kw'],
  daily_light_integral: ['par'],
  growing_degree_hours: ['temperature'],
  radiation_temperature_ratio: ['temperature', 'par'],
  salinity: ['water_temperature', 'conductivity'],
  saturation_vapour_pressure: ['temperature', 'humidity'],
  total_dissolved_solids: ['conductivity'],
  vapour_pressure_deficit: ['temperature', 'humidity'],
  wet_bulb_temperature: ['temperature', 'humidity']
}

export const conversionVectorFunctions = {
  daily_energy_consumption: convertToDCE,
  daily_light_integral: convertToDLI,
  absolute_humidity: convertToAbsoluteHumidity,
  saturation_vapour_pressure: convertToSVP,
  sum_radiation: convertParToSumRadiation,
  wet_bulb_temperature: convertToWetBulbTemperature,
  salinity: convertToSalinity,
  vapour_pressure_deficit: convertToVPD,
  growing_degree_hours: convertToGDH,
  total_dissolved_solids: convertToTotalDissolvedSolids
}

export const getRequiredVectorMeasurements = vector => {
  return requiredVectorMeasurements[vector] ?? []
}

import { Button, Flex, Slot, Text } from '@/primitives'
import { Dialog } from '@/elements'

import Strings from '../../Strings'

const DeleteConfigModal = ({
  showModal,
  controlDeviceConfig,
  onAcceptDelete,
  onCancelDelete
}) => {
  const { removeConfig, cancel, remove, confirmRemoveConfig } = Strings()

  const onClickAccept = e => {
    e.preventDefault()
    onAcceptDelete(controlDeviceConfig)
  }

  const onClickClose = e => {
    e.preventDefault()
    onCancelDelete()
  }

  return (
    <Dialog open={showModal} onOpenChange={onCancelDelete}>
      <Slot name='title'>
        <Text size={300} fontWeight={700}>
          {removeConfig}
        </Text>
      </Slot>
      <Slot name='content'>
        <Text as='p'>{confirmRemoveConfig}</Text>
      </Slot>
      <Slot name='actions'>
        <Flex axisGap={400} alignMainAxis={'space-between'}>
          <Button variant='page' size={'small'} onClick={onClickClose}>
            {cancel}
          </Button>
          <Button variant='error' size={'small'} onClick={onClickAccept}>
            {remove}
          </Button>
        </Flex>
      </Slot>
    </Dialog>
  )
}

export default DeleteConfigModal

import { DateTime } from 'luxon'

import { getCurrentUserOrganizations } from '../../../../../reducers/selectors'

import { Button, Cell, Row } from '../../../../../primitives'

import { SALE_STATUS_TYPE, INVOICE_STATUS_TYPE } from '../utils'

import Strings from '../Strings'

function SalesTableRow({ item, onEdit, canEdit, showOrganization }) {
  const organizations = getCurrentUserOrganizations()
  const strings = Strings()

  const saleStatusTypes = SALE_STATUS_TYPE(strings)
  const invoiceStatusTypes = INVOICE_STATUS_TYPE(strings)

  const {
    id,
    organizationId,
    customer,
    branch,
    currency,
    orderDate,
    purchaseOrder,
    invoiceNumber,
    invoiceStatus,
    invoiceTotal,
    amountPaid,
    status
  } = item

  const getOrganizationName = organizationId => {
    for (let organization of organizations) {
      if (organization.id === organizationId) {
        return organization.name
      }
    }
    return null
  }

  const handleEdit = () => {
    onEdit(id)
  }

  return (
    <Row>
      {showOrganization && (
        <Cell columnName={strings.organization}>
          {getOrganizationName(organizationId)}
        </Cell>
      )}
      <Cell columnName={`${strings.tableHeaderOrder}`}>
        {orderDate && DateTime.fromMillis(orderDate).toFormat('DD')}
        <span>{purchaseOrder}</span>
      </Cell>
      <Cell columnName={strings.customer}>
        {customer.name}
        <span>{branch.name}</span>
      </Cell>
      <Cell columnName={strings.status}>{saleStatusTypes[status]}</Cell>
      <Cell columnName={`${strings.tableHeaderInvoice}`}>
        {invoiceTotal} {currency.code}
        <span>{invoiceNumber}</span>
      </Cell>
      <Cell columnName={`${strings.tableHeaderPayment}`}>
        {amountPaid} {currency.code}
        <span>{invoiceStatusTypes[invoiceStatus]}</span>
      </Cell>
      <Cell className={'Operations__Table__Column__Actions'}>
        {canEdit && (
          <Button
            variant='info'
            size='small'
            iconBefore='edit'
            onClick={handleEdit}
          />
        )}
      </Cell>
    </Row>
  )
}

export default SalesTableRow

import { I18n } from 'aws-amplify'
import { object, string } from 'yup'
import { useEffect, useState, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  requestShippingMethod,
  createShippingMethod,
  updateShippingMethod
} from '@/actions/operations/shippingMethod'

import { requestAllStorageFacilities } from '@/actions/operations/storageFacility'

import {
  getOperationsShippingMethod,
  getOperationsAllStorageFacilities,
  getOperationsLoading,
  getOperationsError
} from '@/reducers/selectors'

import { Flex, Form, Input, Label, Select, Text, Loader } from '@/primitives'

import InputError from '@/elements/InputError'
import HeaderV2 from '@/components/Operations/Shared/HeaderV2'
import OrganizationInput from '../../Shared/Organization/Input'

import { getValidationErrorMap } from '@/Util/GeneralUtils'
import history from '../../../../history'

import { formInitalState, formReducer } from './state'

const SCHEMA = object({
  organizationId: string().required(
    I18n.get('An organization is required field.')
  ),
  name: string().required(I18n.get('Name is required.')),
  storageFacilityId: string().required(
    I18n.get('A storage facility must be selected.')
  )
})

function ShippingMethodForm({ state, modulePath }) {
  const dispatch = useDispatch()
  const { itemId } = useParams()

  const shippingMethod = getOperationsShippingMethod()
  const storageFacilities = getOperationsAllStorageFacilities()
  const loading = getOperationsLoading()
  const error = getOperationsError()

  const [formState, dispatchFormState] = useReducer(
    formReducer,
    formInitalState
  )

  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)

  useEffect(() => {
    dispatch(requestAllStorageFacilities())
  }, [dispatch])

  useEffect(() => {
    if (itemId && shippingMethod.id !== itemId) {
      dispatch(requestShippingMethod({ shippingMethodId: itemId }))
    }
  }, [itemId])

  useEffect(() => {
    if (shippingMethod?.id && shippingMethod.id === itemId) {
      dispatchFormState({ type: 'set-state', state: shippingMethod })
    }
  }, [shippingMethod])

  useEffect(() => {
    if (state.organizations.length === 1) {
      dispatchFormState({
        type: 'update',
        name: 'organizationId',
        value: state.organizations[0]
      })
    }
  }, [state.organizations])

  useEffect(() => {
    if (state.storageFacilities.length === 1) {
      dispatchFormState({
        type: 'update',
        name: 'storageFacilityId',
        value: state.storageFacilities[0]
      })
    }
  }, [state.storageFacilities])

  useEffect(() => {
    if (waiting && !loading && !error) {
      history.replace(modulePath)
    }
  }, [loading])

  const onSubmit = async e => {
    e?.preventDefault()
    try {
      await SCHEMA.validate(formState, { abortEarly: false })
      setWaiting(true)

      if (itemId) {
        formState.shippingMethodId = itemId
        dispatch(updateShippingMethod(formState))
      } else {
        dispatch(createShippingMethod(formState))
      }
    } catch (err) {
      setErrors(getValidationErrorMap(err))
    }
  }

  const onChange = e => {
    let { name, value } = e.currentTarget
    dispatchFormState({ type: 'update', name, value })
  }

  const handleOrganizationInput = organizationId => {
    dispatchFormState({
      type: 'set-state',
      state: {
        ...formState,
        organizationId,
        storageFacilityId: ''
      }
    })
  }

  const filterStorageFacilities = () => {
    return storageFacilities.filter(facility => {
      return facility.organizationId === formState.organizationId
    })
  }

  return (
    <Form className='Operations__Form'>
      <HeaderV2
        title={I18n.get('Manage Shipping Method')}
        backPath={modulePath}
        buttonText={I18n.get('Save Shipping Method')}
        buttonCallback={onSubmit}
        buttonIcon={'save'}
      />
      <Loader isLoading={loading}>
        <OrganizationInput
          fieldName={'organizationId'}
          organizationId={formState.organizationId}
          handleInput={handleOrganizationInput}
          errors={errors}
        />
        <Flex axisGap={300} className='Operations__Form__Fields'>
          <Label>
            <Text variant='page' tone={700}>
              {I18n.get('Storage Facility')}
            </Text>
            <Select
              className='Operations__Form__Select'
              name='storageFacilityId'
              value={formState.storageFacilityId}
              onChange={onChange}
            >
              <option default value=''>
                {I18n.get('Please select')}
              </option>
              {filterStorageFacilities().map(({ name, id }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Select>
            <InputError error={errors?.storageFacilityId} />
          </Label>
        </Flex>
        <Flex axisGap={300} className='Operations__Form__Fields'>
          <Label>
            <Text variant='page' tone={700}>
              {I18n.get('Name')}
            </Text>
            <Input value={formState.name} name='name' onChange={onChange} />
            <InputError error={errors?.name} />
          </Label>
        </Flex>
      </Loader>
    </Form>
  )
}

export default ShippingMethodForm

import { Fragment, useReducer, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import Container from './Container'

import { Button, Input, Form, Text, Flex, Loader, Label } from '@/primitives'

import { showBanner } from '@/slices/util'

import { useAuth } from '@/contexts/auth-context'

import { getInputClassName, insertError } from './Utils'

import {
  forgotPasswordSendSchema,
  forgotPasswordSubmitSchema
} from '../../validations'

import Strings from './Strings'

import './SignIn.scss'

const initialState = {
  username: '',
  code: '',
  password: '',
  confirmPassword: '',
  loading: false,
  errors: {}
}

const reducer = (state, action) => {
  if (action.type === 'update') {
    if (
      action.name !== 'errors' &&
      state?.errors?.hasOwnProperty(action.name)
    ) {
      const errors = { ...state.errors }
      delete errors[action.name]
      return { ...state, errors, [action.name]: action.value }
    }

    return { ...state, [action.name]: action.value }
  }
  return state
}

const strings = Strings()

function ForgotPassword({ hubData, setHubData }) {
  const reduxDispatch = useDispatch()
  const auth = useAuth()
  const [hasSentEmail, setHasSentEmail] = useState(false)
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (hubData?.username?.length > 0 && state.username?.length === 0) {
      dispatch({ type: 'update', name: 'username', value: hubData.username })
    }
  }, [dispatch, hubData?.username, state.username])

  const onSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    await onResetPassword()
  }

  const onResetPassword = async () => {
    try {
      if (!hasSentEmail) {
        await forgotPasswordSendSchema.validate(state, { abortEarly: false })
        await auth.forgotPassword(state.username)
        setHasSentEmail(true)
        setErrors()
        setLoading(false)
      }

      if (hasSentEmail) {
        await forgotPasswordSubmitSchema.validate(state, { abortEarly: false })
        await auth.forgotPasswordSubmit(
          state.username,
          state.code.trim(),
          state.password
        )
      }
    } catch (err) {
      let errors = {}

      if (err?.inner) {
        err.inner.forEach(innerErr => {
          errors[innerErr.path] = innerErr.message
        })
      }

      if (!err.inner && err.message) {
        reduxDispatch(
          showBanner({
            type: 'error',
            message: err.message,
            show: true
          })
        )
      }
      setErrors(errors)
      setLoading(false)
    }
  }

  const setErrors = (errors = {}) => {
    dispatch({ type: 'update', name: 'errors', value: errors })
  }

  const setLoading = loading => {
    dispatch({ type: 'update', name: 'loading', value: loading })
  }

  const onChangeInput = e => {
    const { name, value } = e.currentTarget
    dispatch({ type: 'update', name, value })
  }

  const goToSignIn = async e => {
    e.preventDefault()
    setHubData({ username: state.username, component: 'signIn' })
  }

  return (
    <Loader isLoading={state.loading}>
      <Container title={strings.forgotPasswordTitle}>
        <Form className='Auth__Form' onSubmit={onSubmit}>
          <Flex direction='column' axisGap={400}>
            {!hasSentEmail && (
              <Flex direction='column'>
                <Label className='Auth__Form__Label'>{strings.username}</Label>
                <Input
                  id='username'
                  className={getInputClassName('username', state.errors)}
                  type='text'
                  name='username'
                  value={state.username}
                  disabled={state.loading}
                  onChange={onChangeInput}
                />
                {insertError(state?.errors?.username)}
              </Flex>
            )}
            {hasSentEmail && (
              <Fragment>
                <Text
                  size={100}
                  variant='page'
                  tone={900}
                  style={{ marginBottom: '2rem' }}
                >
                  {strings.passwordPolicy}
                </Text>
                <Flex direction='column'>
                  <Label className='Auth__Form__Label'>{strings.code}</Label>
                  <Input
                    id='code'
                    type='text'
                    name='code'
                    value={state.code}
                    disabled={state.loading}
                    onChange={onChangeInput}
                    className={getInputClassName('code', state.errors)}
                  />
                  {insertError(state?.errors?.code)}
                </Flex>
                <Flex direction='column'>
                  <Label className='Auth__Form__Label'>
                    {strings.newPassword}
                  </Label>
                  <Input
                    type='password'
                    name='password'
                    value={state.password}
                    disabled={state.loading}
                    onChange={onChangeInput}
                    className={getInputClassName('password', state.errors)}
                  />
                  {insertError(state?.errors?.password)}
                </Flex>
                <Flex direction='column'>
                  <Label className='Auth__Form__Label'>
                    {strings.reEnterNewPassword}
                  </Label>
                  <Input
                    type='password'
                    name='confirmPassword'
                    disabled={state.loading}
                    value={state.confirmPassword}
                    onChange={onChangeInput}
                    className={getInputClassName(
                      'confirmPassword',
                      state.errors
                    )}
                  />
                  {insertError(state?.errors?.confirmPassword)}
                </Flex>
              </Fragment>
            )}
          </Flex>
          <Button
            loading={state.loading}
            variant='primary'
            type='submit'
            disabled={state.loading}
            className='Auth__Form__PrimaryButton'
          >
            <Text textTransform='capitalize'>
              {!hasSentEmail
                ? strings.changePasswordBtn.toLocaleUpperCase()
                : strings.submitBtn.toLocaleUpperCase()}
            </Text>
          </Button>
        </Form>
        <Flex
          alignMainAxis='center'
          style={{ marginTop: '1rem', maxWidth: '100%' }}
        >
          <Button
            variant='text'
            className='Auth__Form__GoToPageButton'
            onClick={goToSignIn}
          >
            <Text as='p' size={100} fontWeight={600}>
              {strings.backSignIn}
            </Text>
          </Button>
        </Flex>
      </Container>
    </Loader>
  )
}

export default ForgotPassword

import { I18n } from 'aws-amplify'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { requestAreas } from '@/actions/operations/area'
import {
  getCurrentUser,
  getCurrentUserOrganizations,
  getOperationsAreas,
  getOperationsAreasCount,
  getOperationsLoading
} from '@/reducers/selectors'

import {
  Flex,
  Button,
  Cell,
  Column,
  Row,
  Slot,
  Table,
  Loader,
  Text
} from '@/primitives'

import { Pagination } from '@/elements'

import HeaderV2 from '@/components/Operations/Shared/HeaderV2'

import TableFilter from './TableFilter'

import history from '../../../../history'

import {
  hasEditPermissions,
  RESOURCE_TYPE_OPERATIONS,
  RESOURCE_TYPE_OPERATIONS_MANAGEMENT
} from '@/Util/PermissionUtils'

function CityTable({ state, dispatchState, modulePath, backPath }) {
  const dispatch = useDispatch()
  const currentUser = getCurrentUser()
  const organizations = getCurrentUserOrganizations()
  const areas = getOperationsAreas()
  const areasCount = getOperationsAreasCount()
  const loading = getOperationsLoading()

  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(9)
  const [tableState, setTableState] = useState(state)
  const [canEdit, setCanEdit] = useState(false)
  const [showOrganization, setShowOrganization] = useState(false)
  const [fetchAreasCalled, setFetchAreasCalled] = useState(false)

  const fetchAreas = useCallback(
    page => {
      const { organizations, countries, regions, cities } = state
      if (
        organizations.length > 0 &&
        countries.length > 0 &&
        regions.length > 0 &&
        cities.length > 0
      ) {
        dispatch(
          requestAreas({
            filter: {
              organizationId: organizations,
              countryId: countries,
              regionId: regions,
              cityId: cities
            },
            limit: pageSize,
            offset: page - 1
          })
        )
      }
      setFetchAreasCalled(true)
    },
    [state, pageSize, dispatch]
  )

  const changePage = useCallback(
    newPage => {
      dispatchState({ page: newPage })
      setFetchAreasCalled(false)
    },
    [dispatchState]
  )

  useEffect(() => {
    if (currentUser.userName) {
      setCanEdit(
        hasEditPermissions(
          currentUser,
          RESOURCE_TYPE_OPERATIONS,
          RESOURCE_TYPE_OPERATIONS_MANAGEMENT
        )
      )
    }
    if (organizations.length > 1) {
      setShowOrganization(true)
    }
  }, [currentUser, organizations])

  useEffect(() => {
    if (
      tableState.organizations !== state.organizations ||
      tableState.countries !== state.countries ||
      tableState.regions !== state.regions ||
      tableState.cities !== state.cities ||
      tableState.page !== state.page
    ) {
      if (!fetchAreasCalled) {
        fetchAreas(state.page)
      }
    }
  }, [fetchAreas, state, tableState, fetchAreasCalled])

  useEffect(() => {
    setTableState({ ...state })
  }, [state])

  const onCreate = () => history.push(`${modulePath}/create`)
  const onEdit = id => history.push(`${modulePath}/update/${id}`)

  const getOrganizationName = organizationId => {
    const organization = organizations.find(org => org.id === organizationId)
    return organization ? organization.name : null
  }

  return (
    <Fragment>
      <HeaderV2
        title={I18n.get('Manage Areas')}
        buttonText={I18n.get('Add Area')}
        buttonCallback={onCreate}
        buttonIcon={'add'}
        showButton={canEdit}
        backPath={backPath}
      >
        <TableFilter state={state} dispatchState={dispatchState} />
      </HeaderV2>
      <Loader isLoading={loading}>
        {areasCount === 0 && (
          <Flex className={'Operations__Table__Empty'}>
            <Text>{I18n.get('No regions.')}</Text>
          </Flex>
        )}
        {areasCount > 0 && (
          <Table aria-colcount='5' className='Operations__Table'>
            <Slot name='head'>
              <Row>
                {showOrganization && (
                  <Column>{I18n.get('Organization')}</Column>
                )}
                <Column>{I18n.get('Region')}</Column>
                <Column>{I18n.get('City')}</Column>
                <Column>{I18n.get('Area')}</Column>
                <Column></Column>
              </Row>
            </Slot>
            <Slot name='body'>
              {areas.map(
                ({ id, organizationId, name, country, region, city }) => (
                  <Row key={id}>
                    {showOrganization && (
                      <Cell columnName={I18n.get('Organization')}>
                        {getOrganizationName(organizationId)}
                      </Cell>
                    )}
                    <Cell columnName={I18n.get('Region')}>
                      {region.name}
                      <span>{country.name}</span>
                    </Cell>
                    <Cell columnName={I18n.get('City')}>{city.name}</Cell>
                    <Cell columnName={I18n.get('Area')}>{name}</Cell>
                    <Cell className='Operations__Table__Column__Actions'>
                      {canEdit && (
                        <Button
                          variant='info'
                          size='small'
                          iconBefore='edit'
                          onClick={() => onEdit(id)}
                        />
                      )}
                    </Cell>
                  </Row>
                )
              )}
            </Slot>
          </Table>
        )}
        {areasCount > pageSize && (
          <Pagination
            type='simple'
            totalItems={areasCount}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            page={tableState.page}
            setPage={changePage}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        )}
      </Loader>
    </Fragment>
  )
}

export default CityTable

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {
  createOrganization,
  updateOrganization,
  getOrganizations,
  getOrganization
} from '@/api/management/organization'

export const cleanUpdatedOrganization = createAsyncThunk(
  'cleanUpdatedOrganization',
  () => {
    return {}
  }
)

export const sendCreateOrganization = createAsyncThunk(
  'sendCreateOrganization',
  async params => {
    const { organization, error } = await createOrganization(params)
    return { organization, error }
  }
)

export const sendUpdateOrganization = createAsyncThunk(
  'sendUpdateOrganization',
  async params => {
    const { payload, selectedOrg } = params
    if (payload.name === selectedOrg.name) {
      return { organization: selectedOrg, error: null }
    }

    const { organization, error } = await updateOrganization(payload)
    return { organization, error }
  }
)

export const setOrganization = createAsyncThunk(
  'setOrganization',
  organization => {
    return organization
  }
)

export const fetchOrganizations = createAsyncThunk(
  'fetchOrganizations',
  async params => {
    const { organizations, error } = await getOrganizations(params)
    return { organizations, error }
  }
)

export const fetchOrganization = createAsyncThunk(
  'fetchOrganization',
  async params => {
    const { organization, error } = await getOrganization(params)
    return { organization, error }
  }
)

const managementOrganizationSlice = createSlice({
  name: 'managementOrganizationReducer',
  initialState: {
    selectedOrganization: {},
    organizations: {},
    updatedOrganization: {},
    error: null,
    loading: false
  },
  extraReducers: builder => {
    builder
      .addCase(cleanUpdatedOrganization.pending, (state, action) => {
        state.updatedOrganization = {}
      })
      .addCase(sendCreateOrganization.pending, (state, action) => {
        state.error = null
        state.loading = true
      })
      .addCase(sendCreateOrganization.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.loading = false
        state.updatedOrganization = action?.payload?.organization ?? {}
        state.organizations = {
          ...state.organizations,
          result: [...state.organizations.result, action?.payload?.organization]
        }
      })
      .addCase(sendUpdateOrganization.pending, (state, action) => {
        state.error = null
        state.loading = true
      })
      .addCase(sendUpdateOrganization.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.loading = false
        state.updatedOrganization = action?.payload?.organization ?? {}
        state.organizations = {
          ...state.organizations,
          result: state.organizations.result.map(org =>
            org.id === action?.payload?.organization?.id
              ? action?.payload?.organization
              : org
          )
        }
      })
      .addCase(setOrganization.pending, (state, action) => {
        state.error = null
        state.loading = true
      })
      .addCase(setOrganization.fulfilled, (state, action) => {
        state.loading = false
        state.selectedOrganization = action.payload
      })
      .addCase(fetchOrganizations.pending, (state, action) => {
        state.error = null
        state.loading = true
      })
      .addCase(fetchOrganizations.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.loading = false
        state.organizations = action?.payload?.organizations ?? {}
      })
      .addCase(fetchOrganization.pending, (state, action) => {
        state.error = null
        state.loading = true
      })
      .addCase(fetchOrganization.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.loading = false
        state.selectedOrganization = action?.payload?.organization ?? {}
      })
  }
})

export default managementOrganizationSlice.reducer

import { nanoid } from 'nanoid'
import { Fragment, useEffect, useReducer, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Dialog, LineSeparator } from '@/elements'
import { Button, FlexV2, Slot, Text, Toggle } from '@/primitives'
import {
  getDevice,
  getDeviceLoading,
  getLocation,
  getZone,
  getZoneHierarchy
} from '@/reducers/selectors'
import { getValidationErrorMap } from '@/Util/GeneralUtils'
import {
  sendCreateDevice,
  sendUpdateDevice,
  setDevice
} from '@/slices/management/device'
import { fetchLocation } from '@/slices/location'

import Strings from '../../../Strings'
import { cleanModbusJSON } from './utils'

import {
  SET_DEVICE_STATE,
  SET_SHOW_DEVICE_MOVER_CONFIRMATION_MODAL,
  UPDATE_DEVICE_FIELD,
  createInitialState,
  formReducer
} from './state'
import { getZonePath, sanitizeCoordinateInput } from './utils'
import { deviceSchema } from './validate'

import NameInput from './NameInput'
import FormInput from './FormInput'
import DeviceMover from './DeviceMover'
import LocationInput from './DeviceMover/LocationInput'
import ConfirmationModal from './DeviceMover/ConfirmationModal'
import DeviceConfig from './DeviceConfig'
import ModbusConfigEditor from './ModbusConfigEditor'

import style from './index.module.scss'
import WarningBanner from './WarningBanner'
import UpdateCoordinatesForm from './UpdateCoordinatesForm'
import {
  cleanSensorSimDetails,
  fetchSensorSimDetails
} from '@/slices/diagnostics/sensorSim'
import SensorSimConfig from './SensorSimConfig'

export default function DeviceFormV2({ showModal, setShowDeviceFormModal }) {
  const strings = Strings()
  const dispatch = useDispatch()
  const isDeviceLoading = getDeviceLoading()
  const device = getDevice()
  const zone = getZone()
  const zoneHierarchy = getZoneHierarchy()
  const location = getLocation()

  const [state, stateDispatch] = useReducer(formReducer, createInitialState())
  const [errors, setErrors] = useState({})
  const [showCoordinatesForm, setShowCoordinatesForm] = useState(false)

  const locationUpdated =
    state?.device?.zoneId?.length > 0 &&
    device?.zoneId !== state?.device?.zoneId

  const isDeviceBadlyConfigured =
    state.device.isIoT === false && state.device.isGreengrass === false

  useEffect(() => {
    if (locationUpdated) {
      setShowCoordinatesForm(true)
    }
  }, [locationUpdated, state?.device?.zoneId])

  useEffect(() => {
    dispatch(
      fetchLocation({
        enableHighAccuracy: true,
        timeout: 15000, // 15 seconds
        maximumAge: 1000 * 60 * 60 * 24 // 24 hours
      })
    )
    return () => {
      dispatch(setDevice({}))
      dispatch(cleanSensorSimDetails())
    }
  }, [dispatch])

  useEffect(() => {
    // this should ensure any updates to the device are reflected in the form
    // it will also populate the form when editing a device
    const isMultiReadRegister = device?.config?.read_register_count?.length

    let copyDevice = JSON.parse(JSON.stringify(device))
    if (copyDevice.config) {
      copyDevice.config = cleanModbusJSON(
        copyDevice.config,
        isMultiReadRegister
      )
    }

    stateDispatch({
      type: SET_DEVICE_STATE,
      payload: copyDevice
    })
  }, [device])

  function setCoordinates(lat, long) {
    handleCoordinatesChange({
      target: {
        name: 'latitude',
        value: String(lat)
      }
    })
    handleCoordinatesChange({
      target: {
        name: 'longitude',
        value: String(long)
      }
    })
  }

  useEffect(() => {
    if (location && !device?.id) {
      setCoordinates(location.latitude, location.longitude)
    }
  }, [location, device])

  useEffect(() => {
    if (device.id && device.sensorType.includes('cellular')) {
      dispatch(fetchSensorSimDetails({ sensorId: device.id }))
    }
  }, [device.id])

  function handleFormChange(e) {
    const { name, value } = e.target
    stateDispatch({
      type: UPDATE_DEVICE_FIELD,
      payload: {
        field: name,
        value
      }
    })
  }

  function onToggleNotInUseFlag(event) {
    const { name, checked } = event.target
    stateDispatch({
      type: UPDATE_DEVICE_FIELD,
      payload: {
        field: name,
        value: checked
      }
    })
  }

  function handleCoordinatesChange(e) {
    const { name, value } = e.target

    const sanitizedValue = sanitizeCoordinateInput(value)

    if (sanitizedValue !== null) {
      stateDispatch({
        type: UPDATE_DEVICE_FIELD,
        payload: {
          field: name,
          value: sanitizedValue
        }
      })
    }
  }

  function onCloseModal() {
    setShowDeviceFormModal(false)
  }

  function onEditDevice() {
    if (state.selectedZoneToMoveTo) {
      stateDispatch({
        type: SET_SHOW_DEVICE_MOVER_CONFIRMATION_MODAL,
        payload: true
      })
    } else {
      onSubmit()
    }
  }

  async function onSubmit(acknowledgeDeviceMove = false) {
    if (state.selectedZoneToMoveTo && !acknowledgeDeviceMove) {
      return
    }

    const payload = {
      id: device.id || nanoid(), // TODO: nanoid should be handled by the API,
      name: state.device.name ?? undefined,
      type: state.device.sensorType ?? undefined, // TODO: API expects type and not sensorType. It should be changed in the future
      zoneId: state.device.id
        ? state.device.zoneId
        : state.selectedZoneToMoveTo?.id || zone.id,
      zonePath: state.device.id
        ? state.device.zonePath
        : state.selectedZone?.path || getZonePath(zone),
      latitude: parseFloat(state.device.latitude),
      longitude: parseFloat(state.device.longitude),
      tag: state.device.tag,
      hardwareVersion: state.device.hardwareVersion || null,
      isIoT: state.device.isIoT,
      isGreengrass: state.device.isGreengrass,
      config: state.device.config,
      notInUse: state.device.notInUse,
      sleepCycleMinutes: parseInt(state.device.sleepCycleMinutes, 10)
    }

    try {
      await deviceSchema.validate(payload, { abortEarly: false })
    } catch (err) {
      setErrors(getValidationErrorMap(err))
      return
    }

    if (device.id) {
      const site = Object.values(zoneHierarchy)[0]
      const isSiteActive = site.status === 'active'
      const deviceMove = {
        srcPath: device.zonePath,
        destPath: state.selectedZoneToMoveTo?.parentPath,
        isSiteActive
      }

      dispatch(
        sendUpdateDevice({
          device: payload,
          deviceMove: state.selectedZoneToMoveTo ? deviceMove : null,
          prevConfig: device.config
        })
      )
    } else {
      dispatch(
        sendCreateDevice({
          device: payload,
          prevConfig: device.config
        })
      )
    }

    onCloseModal()
  }

  function onHideCoordinatesForm() {
    setShowCoordinatesForm(false)
  }

  function onClickGetCoordinaates() {
    setShowCoordinatesForm(true)
  }

  return (
    <Fragment>
      <Dialog
        open={showModal}
        onOpenChange={setShowDeviceFormModal}
        type='offcanvas'
        className='Devices__OffCanvas'
        style={{ zIndex: 3 }}
      >
        <Slot name='title'>
          <Text as={'h5'} size={300} fontWeight={700}>
            {!state.device.id
              ? strings.addDeviceModal
              : strings.editDeviceModal}
          </Text>
          <Text as='p'>
            {!state.device.id
              ? strings.deviceFormSubtitle
              : strings.deviceFormEditSubtitle}{' '}
            {zone.name}
          </Text>
        </Slot>
        <Slot name='content'>
          <FlexV2 direction='column' axisGap={500}>
            <LineSeparator />
            <NameInput
              disabled={isDeviceBadlyConfigured}
              value={state.device.name}
              onChange={handleFormChange}
              canRefreshName={!!state.device.id}
              error={errors?.name}
            />
            <FlexV2 direction='column' axisGap={300}>
              <FormInput
                disabled={isDeviceBadlyConfigured}
                name='tag'
                label={strings.deviceFormLabelTag}
                value={state.device.tag}
                onChange={handleFormChange}
                as='textarea'
              />
              <FlexV2 direction='row' alignMainAxis='space-between'>
                <Text variant='page' tone={900} size={100}>
                  {strings.tagFormText}
                </Text>
                <Text variant='page' tone={900} size={100}>
                  ({state?.device.tag?.length ?? 0})
                </Text>
              </FlexV2>
            </FlexV2>
            {state?.device.id && (
              <LocationInput
                stateDispatch={stateDispatch}
                state={state}
                isDeviceBadlyConfigured={isDeviceBadlyConfigured}
              />
            )}
            <FlexV2
              direction='row'
              axisGap={300}
              wrap='wrap'
              alignCrossAxis='center'
            >
              <FlexV2
                direction='row'
                axisGap={300}
                style={{ minWidth: '8rem', flexGrow: '1' }}
              >
                <FormInput
                  disabled={isDeviceBadlyConfigured}
                  className={style.coordinatesInput}
                  name='latitude'
                  label={strings.locationFormLatitude}
                  value={String(state.device.latitude)}
                  onChange={handleCoordinatesChange}
                  error={errors?.latitude}
                />
                <FormInput
                  disabled={isDeviceBadlyConfigured}
                  className={style.coordinatesInput}
                  name='longitude'
                  label={strings.locationFormLongitude}
                  value={String(state.device.longitude)}
                  onChange={handleCoordinatesChange}
                  error={errors?.longitude}
                />
              </FlexV2>
              <Button
                variant='primary'
                size='small'
                style={{ marginTop: '-7px', flexGrow: '1' }}
                onClick={onClickGetCoordinaates}
              >
                {strings.getCoordinates}
              </Button>
            </FlexV2>
            {isDeviceBadlyConfigured ? (
              <WarningBanner />
            ) : (
              <DeviceConfig
                state={state}
                stateDispatch={stateDispatch}
                errors={errors}
                isDeviceBadlyConfigured={isDeviceBadlyConfigured}
              />
            )}
          </FlexV2>
          <FlexV2
            direction='row'
            alignMainAxis='space-between'
            style={{ marginTop: '1em' }}
          >
            <Text variant='page' tone={900} size={100}>
              {strings.notInUseText}
            </Text>
            <Toggle
              name={'notInUse'}
              onChange={onToggleNotInUseFlag}
              checked={state.device.notInUse}
            />
          </FlexV2>
        </Slot>
        <Slot name='actions'>
          <FlexV2 axisGap={400} alignMainAxis={'space-between'}>
            <Button variant='page' onClick={onCloseModal}>
              {strings.cancel}
            </Button>
            {!device.id && (
              <Button
                variant='primary'
                onClick={onSubmit}
                loading={isDeviceLoading}
              >
                {strings.createDeviceButton}
              </Button>
            )}
            {device.id && (
              <Button
                variant='primary'
                onClick={onEditDevice}
                loading={isDeviceLoading}
                disabled={isDeviceBadlyConfigured}
              >
                {strings.editDeviceButton}
              </Button>
            )}
          </FlexV2>
        </Slot>
      </Dialog>
      <UpdateCoordinatesForm
        isVisible={showCoordinatesForm}
        hideForm={onHideCoordinatesForm}
        setCoordinates={setCoordinates}
        locationUpdated={locationUpdated}
        zoneId={state?.device?.zoneId}
      />
      {state.device.id && state.showDeviceMoverView && (
        <DeviceMover state={state} stateDispatch={stateDispatch} />
      )}
      {state.showDeviceMoverConfirmationModal && (
        <ConfirmationModal
          state={state}
          stateDispatch={stateDispatch}
          onSubmit={onSubmit}
        />
      )}
      {state.showModbusConfigEditorView && (
        <ModbusConfigEditor state={state} stateDispatch={stateDispatch} />
      )}
      {state.showSimConfigView && (
        <SensorSimConfig state={state} stateDispatch={stateDispatch} />
      )}
    </Fragment>
  )
}

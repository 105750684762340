export const initialState = {
  organizations: [],
  storageFacilities: [],
  page: 1
}

const reducer = (state, action) => {
  if (action.page) {
    return {
      ...state,
      page: action.page
    }
  } else if (action.organizations) {
    return {
      ...state,
      organizations: action.organizations,
      storageFacilities: []
    }
  } else if (action.storageFacilities) {
    return {
      ...state,
      storageFacilities: action.storageFacilities
    }
  }
  return state
}

export default reducer

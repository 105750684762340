import { I18n } from 'aws-amplify'
import { string, object } from 'yup'

const lowercaseRequired = () => I18n.get('Only lowercase letters are allowed')

export const signInSchema = object().shape({
  username: string().required(I18n.get('Username/email is required')),
  password: string().required(I18n.get('Password is required'))
})

export const forgotPasswordSendSchema = object().shape({
  username: string().required(I18n.get('Username/email is required'))
})

export const forgotPasswordSubmitSchema = object().shape({
  username: string().required(I18n.get('Username/email is required')),
  code: string().required(I18n.get('Code is required')),
  password: string().required(I18n.get('Password is required')),
  confirmPassword: string()
    .required(I18n.get('Confirm password is required'))
    .test(
      'passwords-match',
      I18n.get('Passwords do not match'),
      (value, testContext) => value === testContext.parent.password
    )
})

export const requireNewPasswordSubmitSchema = object().shape({
  password: string().required(I18n.get('Password is required')),
  confirmPassword: string()
    .required(I18n.get('Confirm password is required'))
    .test(
      'passwords-match',
      I18n.get('Passwords do not match'),
      (value, testContext) => value === testContext.parent.password
    )
})

export const verifyAccountCodeSchema = object().shape({
  code: string().required(I18n.get('Code is required'))
})

export const signUpSchema = object().shape({
  organization: string().required(I18n.get('Organization name is required')),
  email: string()
    .email(I18n.get('Email format is incorrect'))
    .required(I18n.get('Email is required')),
  firstName: string().required(I18n.get('First name is required')),
  lastName: string().required(I18n.get('Last name is required')),
  username: string()
    .lowercase(lowercaseRequired())
    .required(I18n.get('Username is required')),
  password: string().required(I18n.get('Password is required')),
  confirmPassword: string()
    .required(I18n.get('Password is required'))
    .test(
      'passwords-match',
      I18n.get('Passwords do not match'),
      (value, testContext) => value === testContext.parent.password
    )
})

export const mfaCodeSchema = object().shape({
  code: string()
    .required(I18n.get('Code is required'))
    .matches(/^[0-9]+$/, 'Only numbers are accepted')
})

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { getAllMeasurements } from '@/api/deviceManagement/measurement'
import {
  getAllDeviceTypes,
  getAllDeviceTypeTypes
} from '@/api/deviceManagement/deviceType'
import { getAllFlashScripts } from '@/api/deviceManagement/flashScript'
import { isLoading } from '@/slices/util'

export const fetchMeasurementsAndDeviceTypes = createAsyncThunk(
  'fetchMeasurementsAndDeviceTypes',
  async (params, { dispatch }) => {
    dispatch(isLoading(true))
    const measurementsResponse = await getAllMeasurements()
    const deviceTypesResponse = await getAllDeviceTypes()
    dispatch(isLoading(false))

    return {
      measurements: measurementsResponse.measurements,
      measurementsError: measurementsResponse.error,
      deviceTypes: deviceTypesResponse.deviceTypes,
      deviceTypesError: deviceTypesResponse.error
    }
  }
)

export const fetchDeviceManagementOptions = createAsyncThunk(
  'fetchDeviceManagementOptions',
  async (params, { dispatch }) => {
    dispatch(isLoading(true))
    const measurementsResponse = await getAllMeasurements()
    const { measurements } = measurementsResponse
    const measurementsError = measurementsResponse.error

    const deviceTypesResponse = await getAllDeviceTypes()
    const { deviceTypes } = deviceTypesResponse
    const deviceTypesError = deviceTypesResponse.error

    const deviceTypeTypesResponse = await getAllDeviceTypeTypes()
    const { deviceTypeTypes } = deviceTypeTypesResponse
    const deviceTypeTypesError = deviceTypeTypesResponse.error

    const flashScriptsResponse = await getAllFlashScripts()
    const { flashScripts } = flashScriptsResponse
    const flashScriptsError = flashScriptsResponse.error

    dispatch(isLoading(false))

    return {
      measurements,
      measurementsError,
      deviceTypes,
      deviceTypesError,
      deviceTypeTypes,
      deviceTypeTypesError,
      flashScripts,
      flashScriptsError
    }
  }
)

const deviceManagementInitSlice = createSlice({
  name: 'deviceManagementInitReducer',
  initialState: {
    loading: false
  },
  extraReducers: builder => {
    builder
      .addCase(fetchDeviceManagementOptions.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchDeviceManagementOptions.fulfilled, (state, action) => {
        state.loading = false
      })
  }
})

export default deviceManagementInitSlice.reducer

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import cloneDeep from 'lodash/cloneDeep'

import {
  getZoneHierarchy,
  getZonesHierarchy,
  getZonesHierarchyDevices
} from '@/api/management/hierarchy'

import {
  addDeviceToHierarchy,
  moverAddDeviceToHierarchy,
  moverRemoveDeviceFromHierarchy,
  stackHierarchyPaths
} from './utils'

import { chunkArray } from '@/Util/ArrayUtils'

async function fetchDevicesFromChunkedZoneIds(zoneIds) {
  const chunkedZoneIds = chunkArray(zoneIds, 50)
  let devices = []

  for (let i = 0; i < chunkedZoneIds.length; i++) {
    const zoneIds = chunkedZoneIds[i]
    const chunkDevices = await getZonesHierarchyDevices({ zoneIds })
    devices = [...devices, ...chunkDevices]
  }

  return devices
}

export const fetchSiteHierarchy = createAsyncThunk(
  'fetchSiteHierarchy',
  async params => {
    const zones = await getZoneHierarchy(params)
    const zoneIds = zones?.map(zone => zone.id) ?? []
    const devices = await getZonesHierarchyDevices({
      zoneIds
    })

    return { zones, devices }
  }
)

export const fetchZoneHierarchy = createAsyncThunk(
  'fetchZoneHierarchy',
  async params => {
    const zones = await getZoneHierarchy(params)
    const zoneIds = zones?.map(zone => zone.id) ?? []
    const devices = await getZonesHierarchyDevices({
      zoneIds
    })

    return { zones, devices }
  }
)

export const fetchZonesHierarchy = createAsyncThunk(
  'fetchZonesHierarchy',
  async () => {
    return await getZonesHierarchy()
  }
)

export const fetchZonesHierarchyWithDevices = createAsyncThunk(
  'fetchZonesHierarchyWithDevices',
  async () => {
    const zones = await getZonesHierarchy()
    const zoneIds = zones?.map(zone => zone.id) ?? []
    const devices = await fetchDevicesFromChunkedZoneIds(zoneIds)

    return { zones, devices }
  }
)

export const fetchZonesHierarchyDevices = createAsyncThunk(
  'fetchZonesHierarchyDevices',
  async params => {
    const { zoneIds } = params
    return await fetchDevicesFromChunkedZoneIds(zoneIds)
  }
)

const managementZoneSlice = createSlice({
  name: 'managementHierarchyReducer',
  initialState: {
    zoneHierarchy: {},
    zonesHierarchy: {},
    zonesHierarchyIds: [],
    siteIds: [],
    error: null,
    loadingZoneHierarchy: false,
    loadingZonesHierarchy: true,
    loadingZonesHierarchyDevices: true,
    zonesHierarchyFetched: false
  },
  reducers: {
    cleanZonesHierarchy: (state, action) => {
      state.zoneHierarchy = {}
      state.zonesHierarchy = {}
      state.zonesHierarchyIds = []
      state.siteIds = []
      state.error = null
      state.loadingZoneHierarchy = false
      state.loadingZonesHierarchy = true
      state.loadingZonesHierarchyDevices = true
      state.zonesHierarchyFetched = false
    },
    moveZoneDevice: (state, action) => {
      let zonesHierarchy = cloneDeep(state.zonesHierarchy)
      let zoneHierarchy = cloneDeep(state.zoneHierarchy)

      const { device, srcPath, destPath, isSiteActive } = action.payload

      // if the site is inactive then the zone to which the device belongs to is not loaded
      if (isSiteActive) {
        moverRemoveDeviceFromHierarchy(device, zonesHierarchy, srcPath)
        moverAddDeviceToHierarchy(device, zonesHierarchy, destPath)
      }

      state.zonesHierarchy = zonesHierarchy
      state.zoneHierarchy = zoneHierarchy
    },
    updateZonesHierarchyAfterDeviceCreate: (state, action) => {
      let hierarchy = cloneDeep(state.zonesHierarchy)
      const device = { ...action.payload } ?? {}
      hierarchy = addDeviceToHierarchy(device, hierarchy)
      state.zonesHierarchy = hierarchy
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSiteHierarchy.pending, (state, action) => {
        state.error = null
      })
      .addCase(fetchSiteHierarchy.fulfilled, (state, action) => {
        let hierarchy = {}
        const { zones, devices } = action?.payload ?? {}

        zones?.sort((a, b) => a.level - b.level)
        zones?.forEach(zone => {
          const path = zone?.parentPath.split('/').slice(1)
          hierarchy = stackHierarchyPaths(hierarchy, path, zone, true)
        })

        devices?.forEach(device => {
          hierarchy = addDeviceToHierarchy(device, hierarchy)
        })

        const activeHierarchy = Object.assign(
          {},
          state.zonesHierarchy,
          hierarchy
        )

        state.zonesHierarchy = activeHierarchy
      })
      .addCase(fetchSiteHierarchy.rejected, (state, action) => {
        state.error = action.error
      })
      .addCase(fetchZoneHierarchy.pending, (state, action) => {
        state.error = null
        state.loadingZoneHierarchy = true
      })
      .addCase(fetchZoneHierarchy.fulfilled, (state, action) => {
        let hierarchy = { ...state.zoneHierarchy }
        const { zones, devices } = action?.payload ?? {}

        zones?.sort((a, b) => a.level - b.level)
        zones?.forEach(zone => {
          const path = zone?.parentPath.split('/').slice(1)
          hierarchy = stackHierarchyPaths(hierarchy, path, zone, true)
        })

        devices?.forEach(device => {
          hierarchy = addDeviceToHierarchy(device, hierarchy)
        })

        state.zoneHierarchy = hierarchy
        state.loadingZoneHierarchy = false
      })
      .addCase(fetchZoneHierarchy.rejected, (state, action) => {
        state.error = action.error
        state.loadingZoneHierarchy = false
      })
      .addCase(fetchZonesHierarchy.pending, (state, action) => {
        state.error = null
        state.loadingZonesHierarchy = true
        state.zonesHierarchyFetched = false
      })
      .addCase(fetchZonesHierarchy.fulfilled, (state, action) => {
        let hierarchy = {}
        let zones = [...action.payload]

        zones?.sort((a, b) => a.level - b.level)
        zones?.forEach(zone => {
          const path = zone?.parentPath.split('/').slice(1)
          hierarchy = stackHierarchyPaths(hierarchy, path, zone, true)
        })

        state.zonesHierarchy = hierarchy
        state.zonesHierarchyIds = zones?.map(zone => zone.id) ?? []
        state.siteIds =
          zones?.filter(zone => !zone.parentId)?.map(zone => zone.id) ?? []
        state.loadingZonesHierarchy = false
        state.zonesHierarchyFetched = true
      })
      .addCase(fetchZonesHierarchy.rejected, (state, action) => {
        state.error = action.error
        state.loadingZonesHierarchy = false
        state.zonesHierarchyFetched = true
      })
      .addCase(fetchZonesHierarchyWithDevices.pending, (state, action) => {
        state.error = null
        state.loadingZonesHierarchy = true
      })
      .addCase(fetchZonesHierarchyWithDevices.fulfilled, (state, action) => {
        let hierarchy = {}
        let zones = [...action?.payload?.zones]
        const devices = [...action?.payload?.devices] ?? []

        zones?.sort((a, b) => a.level - b.level)
        zones?.forEach(zone => {
          const path = zone?.parentPath.split('/').slice(1)
          hierarchy = stackHierarchyPaths(hierarchy, path, zone, true)
        })

        devices?.forEach(device => {
          hierarchy = addDeviceToHierarchy(device, hierarchy)
        })

        state.zonesHierarchy = hierarchy
        state.zonesHierarchyIds = zones?.map(zone => zone.id) ?? []
        state.siteIds =
          zones?.filter(zone => !zone.parentId)?.map(zone => zone.id) ?? []
        state.loadingZonesHierarchy = false
      })
      .addCase(fetchZonesHierarchyWithDevices.rejected, (state, action) => {
        state.error = action.error
        state.loadingZonesHierarchy = false
      })
      .addCase(fetchZonesHierarchyDevices.pending, (state, action) => {
        state.error = null
        state.loadingZonesHierarchyDevices = true
      })
      .addCase(fetchZonesHierarchyDevices.fulfilled, (state, action) => {
        let hierarchy = { ...state.zonesHierarchy }
        const devices = [...action.payload] ?? []

        devices?.forEach(device => {
          hierarchy = addDeviceToHierarchy(device, hierarchy)
        })

        state.zonesHierarchy = hierarchy
        state.loadingZonesHierarchyDevices = false
      })
      .addCase(fetchZonesHierarchyDevices.rejected, (state, action) => {
        state.error = action.error
        state.loadingZonesHierarchyDevices = false
      })
  }
})

export const {
  cleanZonesHierarchy,
  moveZoneDevice,
  updateZonesHierarchyAfterDeviceCreate
} = managementZoneSlice.actions

export default managementZoneSlice.reducer

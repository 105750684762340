import { I18n } from 'aws-amplify'

import { widgetRegistry } from '@/components/DashboardPageV2/Desktop/Widgets'

import { TOOLBOX_TYPES, ToolboxItem } from './utils'

/*
  SECTION
*/

export const miscellaneousSection = () =>
  new ToolboxItem({
    id: 'miscellaneousSection',
    type: TOOLBOX_TYPES.section,
    title: I18n.get('Miscellaneous'),
    icon: 'diversity_2',
    items: [
      widgetRegistry.get('timezoneClock'),
      widgetRegistry.get('weather'),
      widgetRegistry.get('batterySoc')
    ]
  })

import { updateList } from '../util'

import {
  RECEIVE_PACKAGE_UNIT_INVENTORY_TRANSFERS,
  REQUEST_PACKAGE_UNIT_INVENTORY_TRANSFERS,
  CLEAR_PACKAGE_UNIT_INVENTORY_TRANSFERS,
  CLEAR_PACKAGE_UNIT_INVENTORY_TRANSFER,
  REQUEST_PACKAGE_UNIT_INVENTORY_TRANSFER,
  RECEIVE_PACKAGE_UNIT_INVENTORY_TRANSFER,
  CREATE_PACKAGE_UNIT_INVENTORY_TRANSFER,
  PACKAGE_UNIT_INVENTORY_TRANSFER_CREATED,
  UPDATE_PACKAGE_UNIT_INVENTORY_TRANSFER,
  PACKAGE_UNIT_INVENTORY_TRANSFER_UPDATED,
  SET_PACKAGE_UNIT_INVENTORY_TRANSFER
} from '@/actions/operations/packageUnitInventoryTransfer'

export const packageUnitInventoryTransferInitialState = {
  packageUnitInventoryTransfers: [],
  packageUnitInventoryTransfer: {},
  packageUnitInventoryTransfersCount: 0,
  loading: false,
  error: null
}

const handlers = {
  [CLEAR_PACKAGE_UNIT_INVENTORY_TRANSFER]: (state, action) => ({
    ...state,
    packageUnitInventoryTransfer: {},
    error: null
  }),
  [CLEAR_PACKAGE_UNIT_INVENTORY_TRANSFERS]: (state, action) => ({
    ...state,
    packageUnitInventoryTransfers: [],
    packageUnitInventoryTransfersCount: 0,
    error: null
  }),
  [REQUEST_PACKAGE_UNIT_INVENTORY_TRANSFER]: (state, action) => ({
    ...state,
    packageUnitInventoryTransfer: {},
    error: null,
    loading: true
  }),
  [RECEIVE_PACKAGE_UNIT_INVENTORY_TRANSFER]: (state, action) => ({
    ...state,
    packageUnitInventoryTransfer: !action.error
      ? action.packageUnitInventoryTransfer
      : state.packageUnitInventoryTransfer,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_PACKAGE_UNIT_INVENTORY_TRANSFERS]: (state, action) => ({
    ...state,
    packageUnitInventoryTransfers: [],
    packageUnitInventoryTransfersCount: 0,
    error: null,
    loading: true
  }),
  [RECEIVE_PACKAGE_UNIT_INVENTORY_TRANSFERS]: (state, action) => ({
    ...state,
    packageUnitInventoryTransfers: !action.error
      ? action.packageUnitInventoryTransfers
      : [],
    packageUnitInventoryTransfersCount: !action.error ? action.count : 0,
    error: action.error ?? null,
    loading: false
  }),
  [SET_PACKAGE_UNIT_INVENTORY_TRANSFER]: (state, action) => ({
    ...state,
    packageUnitInventoryTransfer: action.packageUnitInventoryTransfer
  }),
  [CREATE_PACKAGE_UNIT_INVENTORY_TRANSFER]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [PACKAGE_UNIT_INVENTORY_TRANSFER_CREATED]: (state, action) => ({
    ...state,
    packageUnitInventoryTransfer: !action.error
      ? {}
      : state.packageUnitInventoryTransfer,
    packageUnitInventoryTransfers: updateList(
      action.error,
      state.packageUnitInventoryTransfers,
      action.packageUnitInventoryTransfer,
      true
    ),
    packageUnitInventoryTransfersCount: !action.error
      ? state.packageUnitInventoryTransfersCount + 1
      : state.packageUnitInventoryTransfersCount,
    error: action.error ?? null,
    loading: false
  }),
  [UPDATE_PACKAGE_UNIT_INVENTORY_TRANSFER]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [PACKAGE_UNIT_INVENTORY_TRANSFER_UPDATED]: (state, action) => ({
    ...state,
    packageUnitInventoryTransfer: !action.error
      ? {}
      : state.packageUnitInventoryTransfer,
    packageUnitInventoryTransfers: updateList(
      action.error,
      state.packageUnitInventoryTransfers,
      action.packageUnitInventoryTransfer,
      false
    ),
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

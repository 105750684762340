import { makeActionCreator } from '@/Util/ReduxUtils'

export const CLEAR_TIMEFRAME_GRAPH_AVERAGES = 'CLEAR_TIMEFRAME_GRAPH_AVERAGES'
export const clearTimeframeGraphAverages = makeActionCreator(
  CLEAR_TIMEFRAME_GRAPH_AVERAGES
)

export const FETCH_TIMEFRAME_GRAPH_AVERAGES = 'FETCH_TIMEFRAME_GRAPH_AVERAGES'
export const fetchTimeframeGraphAverages = makeActionCreator(
  FETCH_TIMEFRAME_GRAPH_AVERAGES,
  'params'
)

export const TIMEFRAME_GRAPH_AVERAGES_FETCHED =
  'TIMEFRAME_GRAPH_AVERAGES_FETCHED'
export const timeframeGraphAveragesFetched = makeActionCreator(
  TIMEFRAME_GRAPH_AVERAGES_FETCHED,
  'timeframeGraphAverages',
  'params'
)

import { Flex } from '@/primitives'

import {
  getNavigationPanelPrimaryLock,
  getNavigationPanelPrimaryOpen,
  getShowBanner
} from '@/reducers/selectors'

import { useAuth } from '@/contexts/auth-context'

import './index.scss'

const Shell = ({ children }) => {
  const auth = useAuth()
  const { show } = getShowBanner()

  const navigationPanelPrimaryLock = getNavigationPanelPrimaryLock()
  const navigationPanelPrimaryOpen = getNavigationPanelPrimaryOpen()

  return (
    <Flex
      direction='column'
      className={'Shell'}
      wrap='nowrap'
      open={navigationPanelPrimaryOpen}
      data-notification={show ? show.toString() : null}
      data-lock={
        navigationPanelPrimaryLock
          ? navigationPanelPrimaryLock.toString()
          : null
      }
      data-navigation={auth?.cognitoUser?.username ? true : null}
    >
      {children}
    </Flex>
  )
}

export default Shell

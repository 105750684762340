import { call, put, takeLatest } from 'redux-saga/effects'

import {
  createInventoryCalibration,
  getInventoryCalibrations
} from '@/api/operations/inventoryCalibration'

import {
  REQUEST_INVENTORY_CALIBRATIONS,
  CREATE_INVENTORY_CALIBRATION,
  receiveInventoryCalibrations,
  inventoryCalibrationCreated
} from '@/actions/operations/inventoryCalibration'

import { displayBanner } from '../utils'

import Strings from './Strings'

const strings = Strings()

function* sendCreateInventoryCalibration(action) {
  const { data, error = null } = yield call(
    createInventoryCalibration,
    action.params
  )
  yield put(displayBanner(error, strings.inventoryCalibrationCreated))
  yield put(inventoryCalibrationCreated(data, error))
}

export function* watchCreateInventoryCalibration() {
  yield takeLatest(CREATE_INVENTORY_CALIBRATION, sendCreateInventoryCalibration)
}

function* sendRequestInventoryCalibrations(action) {
  const { data, error = null } = yield call(
    getInventoryCalibrations,
    action.params
  )
  const { inventoryCalibrations = [], count = 0 } = data
  yield put(receiveInventoryCalibrations(inventoryCalibrations, count, error))
}

export function* watchRequestInventoryCalibrations() {
  yield takeLatest(
    REQUEST_INVENTORY_CALIBRATIONS,
    sendRequestInventoryCalibrations
  )
}

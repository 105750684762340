import { parseValue } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/utils'

import Value from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/Chart/Value'
import useAverageData from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Hooks/useAverageData'
import { getRanges } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'
import {
  convertToTotalDissolvedSolids,
  requiredVectorMeasurements
} from '@/Util/MeasurementUtils'

export default function AverageValue({ unit, options, config }) {
  const data = useAverageData({
    measurements: requiredVectorMeasurements[options.measurement],
    range: config.range
  })

  const parseOptions = conductivity => ({
    fixedFactor: options.fixedFactor,
    scaleFactor: options.scaleFactor,
    value: convertToTotalDissolvedSolids({ conductivity })
  })

  return (
    <Value
      data={{
        value: parseValue(parseOptions(data?.conductivity?.value)),
        min: parseValue(parseOptions(data?.conductivity?.min)),
        max: parseValue(parseOptions(data?.conductivity?.max)),
        unit,
        status: data?.conductivity?.status,
        subtitle: getRanges(config.range)?.label
      }}
    />
  )
}

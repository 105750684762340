import { useState, Fragment } from 'react'
import { useDispatch } from 'react-redux'

import { Text, Loader } from '@/primitives'
import { Tabs } from '@/elements'

import HelpText from './HelpText'
import ReleaseNotes from './ReleaseNotes'
import Tags from './Tags'

import { fetchTags } from '@/slices/fyi/tags'
import { getFyiTagsRequested, getFyiTagsLoading } from '@/reducers/selectors'

import Strings from '../Strings'

const strings = Strings()

let tabConfigs = [
  {
    tabId: 'helpText',
    tabLabel: strings.helpText,
    renderTabContent: () => <HelpText />
  },
  {
    tabId: 'releaseNotes',
    tabLabel: strings.releaseNotes,
    renderTabContent: () => <ReleaseNotes />
  },
  {
    tabId: 'tags',
    tabLabel: strings.tags,
    renderTabContent: () => <Tags />
  }
]

const FyiPage = () => {
  const [tabIndex, setTabIndex] = useState(0)

  const dispatch = useDispatch()
  const tagsRequested = getFyiTagsRequested()
  const loading = getFyiTagsLoading()

  if (!tagsRequested) dispatch(fetchTags())

  return (
    <Fragment>
      <Text as='h3'>{strings.fyiPageHeader}</Text>
      <Loader isLoading={loading}>
        <Tabs
          tabConfigs={tabConfigs}
          activeIndex={tabIndex}
          onSwitchTab={setTabIndex}
          style={{
            paddingInlineStart: '0px',
            borderTop: '1px solid var(--ctx-theme-color-page-500)'
          }}
        />
      </Loader>
    </Fragment>
  )
}

export default FyiPage

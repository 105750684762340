import Strings from './strings'

const strings = Strings()

export const STORE_TYPES = [
  ['cold', strings.coldStorage],
  ['general', strings.generalStore],
  ['chemical', strings.chemicalStore],
  ['fertilizer', strings.fertilizerStore],
  ['seed', strings.seedStore],
  ['maintenance', strings.maintenanceSupplies],
  ['flammable', strings.cagedStorage]
]

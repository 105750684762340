import { useReducer, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import DatePicker from 'react-datepicker'

import useDeviceSize from '@/hooks/useDeviceSize'

import { requestAllProduces } from '@/actions/operations/produce'
import { requestAllVarieties } from '@/actions/operations/variety'
import { requestAllGrades } from '@/actions/operations/grade'
import { requestAllStorageFacilities } from '@/actions/operations/storageFacility'

import {
  createHarvest,
  requestHarvest,
  updateHarvest
} from '@/actions/operations/harvest'

import {
  getOperationsAllProduces,
  getOperationsAllVarieties,
  getOperationsAllStorageFacilities,
  getOperationsHarvest,
  getOperationsLoading,
  getOperationsError
} from '@/reducers/selectors'

import { Box, Button, Flex, Label, Select, Text, Loader } from '@/primitives'

import InputError from '@/elements/InputError'
import OrganizationInput from '../../../Shared/Organization/Input'
import ZoneNavigator from '../../../Shared/ZoneNavigator'
import Weights from '../../../Shared/Weights'
import Photos from '../../../Shared/Photos'

import { getValidationErrorMap } from '@/Util/GeneralUtils'

import { FIELDS, SCHEMA } from './config'

import {
  initialState,
  reducer,
  SET_STATE,
  UPDATE_INPUT,
  UPDATE_SITE_ID,
  UPDATE_WEIGHT,
  REMOVE_WEIGHT,
  UPDATE_PHOTO,
  REMOVE_PHOTO
} from './state'

import history from '../../../../../history'
import Strings from '../Strings'
import HeaderV2 from '@/components/Operations/Shared/HeaderV2'

function HarvestForm({ state, modulePath }) {
  const dispatch = useDispatch()
  const isMobile = useDeviceSize('mobile')
  const isLaptop = useDeviceSize()

  const { itemId } = useParams()
  const strings = Strings()

  const produces = getOperationsAllProduces()
  const varieties = getOperationsAllVarieties()
  const storageFacilities = getOperationsAllStorageFacilities()
  const harvest = getOperationsHarvest()
  const loading = getOperationsLoading()
  const error = getOperationsError()

  const [formState, dispatchFormState] = useReducer(reducer, initialState)
  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)

  useEffect(() => {
    dispatch(requestAllProduces())
    dispatch(requestAllVarieties())
    dispatch(requestAllGrades())
    dispatch(requestAllStorageFacilities())
  }, [dispatch])

  useEffect(() => {
    if (itemId && harvest.id !== itemId) {
      dispatch(requestHarvest({ harvestId: itemId }))
    }
  }, [itemId])

  useEffect(() => {
    if (harvest?.id && harvest.id === itemId) {
      dispatchFormState({
        type: SET_STATE,
        state: { ...harvest }
      })
    }
  }, [harvest])

  useEffect(() => {
    if (!itemId && state.organizations.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'organizationId',
        value: state.organizations[0]
      })
    }
  }, [state.organizations])

  useEffect(() => {
    if (!itemId && state.sites.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'siteId',
        value: state.sites[0]
      })
    }
  }, [state])

  useEffect(() => {
    if (waiting && !loading && !error) {
      history.replace(modulePath)
    }
  }, [loading])

  const onSubmit = async e => {
    try {
      const payload = await SCHEMA().validate(formState, { abortEarly: false })
      setWaiting(true)

      if (itemId) {
        payload.harvestId = itemId
        dispatch(updateHarvest(payload))
      } else {
        dispatch(createHarvest(payload))
      }
    } catch (error) {
      setErrors(getValidationErrorMap(error))
    }
  }

  const handleInput = e => {
    const { name, value } = e.currentTarget
    dispatchFormState({ type: UPDATE_INPUT, name, value })
  }

  const handleDateInput = (date, name) => {
    dispatchFormState({ type: UPDATE_INPUT, name, value: date })
  }

  const handleOrganizationInput = organizationId => {
    const newWeights = formState.weights.map(weight => {
      return { ...weight, gradeId: '' }
    })
    dispatchFormState({
      type: SET_STATE,
      state: {
        ...formState,
        organizationId,
        siteId: '',
        zoneId: '',
        storageFacilityId: '',
        produceId: '',
        varietyId: '',
        weights: newWeights
      }
    })
  }

  const onSelectZone = ({ id, parentId }) => {
    if (parentId) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'zoneId',
        value: id
      })
    } else {
      dispatchFormState({
        type: UPDATE_SITE_ID,
        siteId: id
      })
    }
  }

  const updateWeight = weight => {
    dispatchFormState({
      type: UPDATE_WEIGHT,
      weight
    })
  }

  const removeWeight = weight => {
    dispatchFormState({
      type: REMOVE_WEIGHT,
      weight
    })
  }

  const updatePhoto = photo => {
    dispatchFormState({
      type: UPDATE_PHOTO,
      photo
    })
  }

  const removePhoto = photo => {
    dispatchFormState({
      type: REMOVE_PHOTO,
      photo
    })
  }

  const filterProduces = () => {
    return produces.filter(p => p.organizationId === formState.organizationId)
  }

  const filterVarieties = () => {
    return varieties.filter(v => v.produce.id === formState.produceId)
  }

  const filterStorageFacilities = () => {
    return storageFacilities.filter(
      s => s.organizationId === formState.organizationId
    )
  }

  const getHeader = () => {
    if (itemId) {
      return strings.updateFormHeader
    }
    return strings.createFormHeader
  }

  return (
    <Flex
      as='form'
      axisGap={400}
      direction='column'
      className='Operations__Form'
    >
      <HeaderV2
        title={getHeader()}
        backPath={modulePath}
        buttonIcon={'save'}
        buttonText={strings.saveHarvest}
        buttonCallback={onSubmit}
      />
      <Loader isLoading={loading}>
        <Flex axisGap={800} direction={isLaptop ? 'column' : null}>
          <Flex
            axisGap={400}
            direction='column'
            className='Operations__Form__Fields'
          >
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.location}
              </Text>
              <OrganizationInput
                fieldName={FIELDS.organizationId}
                organizationId={formState.organizationId}
                handleInput={handleOrganizationInput}
                errors={errors}
              />
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                className='Operations__Form__Fields'
                direction='column'
              >
                <Label>
                  <Text variant='page' tone={700}>
                    {strings.zone}
                  </Text>
                  <ZoneNavigator
                    organizationId={formState.organizationId}
                    siteId={formState.siteId}
                    zoneId={formState.zoneId}
                    onSelectZone={onSelectZone}
                  />
                  <InputError error={errors?.zoneId} />
                </Label>
              </Flex>
            </Box>
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.yield}
              </Text>
              <Flex
                axisGap={300}
                className='Operations__Form__Fields'
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.produce}
                  </Text>
                  <Select
                    className='Operations__Form__Select'
                    name={FIELDS.produceId}
                    value={formState.produceId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterProduces().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.produceId} />
                </Label>
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.variety}
                  </Text>
                  <Select
                    className='Operations__Form__Select'
                    name={FIELDS.varietyId}
                    value={formState.varietyId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterVarieties().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.varietyId} />
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                className='Operations__Form__Fields'
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.harvestDate}
                    </Text>
                    <DatePicker
                      maxDate={new Date()}
                      selected={formState.harvestDate}
                      className='Operations__Form__Select'
                      onChange={date =>
                        handleDateInput(date, FIELDS.harvestDate)
                      }
                    />
                    <InputError error={errors?.harvestDate} />
                  </Flex>
                </Label>
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.storageFacility}
                  </Text>
                  <Select
                    className='Harvest__Select'
                    name={FIELDS.storageFacilityId}
                    value={formState.storageFacilityId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterStorageFacilities().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.storageFacilityId} />
                </Label>
              </Flex>
            </Box>
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.weights}
              </Text>
              <Flex
                axisGap={300}
                direction='column'
                className='Operations__Form__Weights'
              >
                <Weights
                  organizationId={formState.organizationId}
                  updateWeight={updateWeight}
                  removeWeight={removeWeight}
                  weights={formState.weights}
                  errors={errors}
                />
                <InputError error={errors.weights} />
                <Flex alignMainAxis={'flex-end'}>
                  <Button
                    onClick={() => updateWeight()}
                    size='small'
                    name='add'
                    variant='primary'
                    iconBefore='add'
                    className='Operations__Form__Weights__Add'
                  >
                    {strings.addWeight}
                  </Button>
                </Flex>
              </Flex>
            </Box>
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.photos}
              </Text>
              <Flex
                axisGap={300}
                direction='column'
                className='Operations__Form__Weights'
              >
                <Photos
                  photos={formState.photos}
                  updatePhoto={updatePhoto}
                  removePhoto={removePhoto}
                />
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Loader>
    </Flex>
  )
}

export default HarvestForm

import { call, put, select, takeLatest } from 'redux-saga/effects'

import {
  createPaymentMethod,
  getPaymentMethod,
  getPaymentMethods,
  updatePaymentMethod
} from '@/api/operations/paymentMethod'

import {
  REQUEST_ALL_PAYMENT_METHODS,
  REQUEST_PAYMENT_METHODS,
  REQUEST_PAYMENT_METHOD,
  CREATE_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD,
  receiveAllPaymentMethods,
  receivePaymentMethods,
  receivePaymentMethod,
  paymentMethodCreated,
  paymentMethodUpdated
} from '@/actions/operations/paymentMethod'

import { getCurrentUserOrganizations, displayBanner } from '../utils'
import { getOperationsAllPaymentMethods } from './utils'

import Strings from './Strings'

const strings = Strings()

function* sendCreatePaymentMethod(action) {
  const { data, error = null } = yield call(createPaymentMethod, action.params)
  yield put(displayBanner(error, strings.paymentMethodCreated))
  yield put(paymentMethodCreated(data, error))
}

export function* watchCreatePaymentMethod() {
  yield takeLatest(CREATE_PAYMENT_METHOD, sendCreatePaymentMethod)
}

function* sendUpdatePaymentMethod(action) {
  const { data, error = null } = yield call(updatePaymentMethod, action.params)
  yield put(displayBanner(error, strings.paymentMethodUpdated))
  yield put(paymentMethodUpdated(data, error))
}

export function* watchUpdatePaymentMethod() {
  yield takeLatest(UPDATE_PAYMENT_METHOD, sendUpdatePaymentMethod)
}

function* sendRequestPaymentMethods(action) {
  const { data, error = null } = yield call(getPaymentMethods, action.params)
  const { paymentMethods = [], count = 0 } = data
  yield put(receivePaymentMethods(paymentMethods, count, error))
}

export function* watchRequestPaymentMethods() {
  yield takeLatest(REQUEST_PAYMENT_METHODS, sendRequestPaymentMethods)
}

function* sendRequestPaymentMethod(action) {
  const { data: paymentMethod, error = null } = yield call(
    getPaymentMethod,
    action.params
  )
  yield put(receivePaymentMethod(paymentMethod, error))
}

export function* watchRequestPaymentMethod() {
  yield takeLatest(REQUEST_PAYMENT_METHOD, sendRequestPaymentMethod)
}

function* sendRequestAllPaymentMethods(action) {
  const allPaymentMethods = yield select(getOperationsAllPaymentMethods)
  if (allPaymentMethods.length > 0) {
    yield put(
      receiveAllPaymentMethods(
        allPaymentMethods,
        allPaymentMethods.length,
        null
      )
    )
  } else {
    const organizations = yield select(getCurrentUserOrganizations)
    const params = {
      filter: {
        organizationId: organizations.map(o => o.id)
      }
    }
    const { data, error = null } = yield call(getPaymentMethods, params)
    const { paymentMethods = [], count = 0 } = data
    yield put(receiveAllPaymentMethods(paymentMethods, count, error))
  }
}

export function* watchRequestAllPaymentMethods() {
  yield takeLatest(REQUEST_ALL_PAYMENT_METHODS, sendRequestAllPaymentMethods)
}

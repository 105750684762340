import { I18n } from 'aws-amplify'

const Strings = () => ({
  coldStorage: I18n.get('Cold Storage (Produce)'),
  generalStore: I18n.get('General Store (Packaging)'),
  chemicalStore: I18n.get('Chemical Store'),
  fertilizerStore: I18n.get('Fertilizer Store'),
  seedStore: I18n.get('Refrigerated Seed Store'),
  maintenanceSupplies: I18n.get('Maintenance Supplies'),
  cagedStorage: I18n.get('Flammable Caged Storage'),
  manageStorageFacilities: I18n.get('Manage Storage Facilities'),
  addStorageFacility: I18n.get('Add Storage Facility'),
  noEntries: I18n.get('No entries'),
  name: I18n.get('Name'),
  location: I18n.get('Location'),
  shippingMethods: I18n.get('Shipping Methods')
})

export default Strings
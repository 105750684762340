import { useReducer, Fragment, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import TranslationLanguageCard from './TranslationLangCard'
import { Dialog, LineSeparator } from '@/elements'
import { Slot, Text, Flex, Button, Box } from '@/primitives'

import LANGUAGES from '@/Util/languages.json'
import {
  generateHelpTextTranslations,
  cleanHelpText
} from '@/slices/fyi/helpText'
import { getHelpTextTranslationRequested } from '@/reducers/selectors'
import Strings from '../../Strings'

const strings = Strings()
const languageOptions = LANGUAGES.enabled.filter(lang => lang !== 'en')

const initialState = {
  helpTextId: '',
  selectedLanguages: []
}

function reducer(state, action) {
  if (action.type === 'updateTranslations') {
    if (state.selectedLanguages.includes(action.name)) {
      const selectedLanguages = [...state.selectedLanguages].filter(
        lang => lang !== action.name
      )
      return { ...state, selectedLanguages }
    }
    return {
      ...state,
      selectedLanguages: [...state.selectedLanguages, action.name]
    }
  }

  if (action.type === 'setInit') {
    const selectedLanguages = languageOptions.filter(lang => {
      const { titleTranslations = [] } = action.value
      return !titleTranslations?.some(item => item.lang === lang)
    })
    return { ...state, ...action.value, selectedLanguages }
  }

  if (action.type === 'reset') {
    return { ...state, ...initialState }
  }
}

const TranslationsForm = ({ showForm, setHideForm, itemToEdit }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const reduxDispatch = useDispatch()
  const translationRequested = getHelpTextTranslationRequested()

  useEffect(() => {
    if (translationRequested === itemToEdit?.helpTextId) {
      setHideForm()
      reduxDispatch(cleanHelpText())
    }
  }, [translationRequested, itemToEdit?.helpTextId, reduxDispatch, setHideForm])

  if (
    showForm &&
    state.helpTextId?.length === 0 &&
    itemToEdit?.helpTextId?.length > 0
  ) {
    dispatch({ type: 'setInit', value: itemToEdit })
  }

  if (!showForm && state.helpTextId?.length > 0) {
    dispatch({ type: 'reset' })
  }

  if (!itemToEdit?.textTranslations) return null

  function onChangeCheckbox({ target }) {
    const { checked, name } = target
    dispatch({ type: 'updateTranslations', value: checked, name })
  }

  function onSubmitForm() {
    reduxDispatch(
      generateHelpTextTranslations({
        helpTextId: state.helpTextId,
        targetLangs: state.selectedLanguages
      })
    )
  }

  function insertLanguageRow(lang) {
    const currentTitleTranslation = itemToEdit?.titleTranslations?.find(
      translation => {
        return translation.lang === lang
      }
    )?.text
    const currentTextTranslation = itemToEdit?.textTranslations?.find(
      translation => {
        return translation.lang === lang
      }
    )?.text

    const checked = state.selectedLanguages.includes(lang)

    return (
      <TranslationLanguageCard
        key={lang}
        title={currentTitleTranslation}
        text={currentTextTranslation}
        onChangeCheckbox={onChangeCheckbox}
        checked={checked}
        lang={lang}
      />
    )
  }

  return (
    <Fragment>
      <Dialog
        className='ZoneDetails__OffCanvas'
        type='offcanvas'
        open={showForm}
        onOpenChange={setHideForm}
      >
        <Slot name='title'>
          <Text size={400} fontWeight={700}>
            {strings.generateTranslations}
          </Text>
        </Slot>
        <Slot name='content'>
          <Text size={200} as='p' variant='page'>
            {strings.translationsFormSubheading}
          </Text>
          <Box>
            <LineSeparator />
          </Box>
          <Text as='h5' style={{ margin: '1rem 0 1.2rem' }}>
            {strings.helpTextEng}
          </Text>
          <TranslationLanguageCard
            title={itemToEdit?.title}
            text={itemToEdit?.text}
            lang='en'
          />
          <Text as='h5' style={{ margin: '2.5rem 0 1.2rem' }}>
            {strings.translations}
          </Text>
          <Flex axisGap={400} direction='column'>
            {itemToEdit && languageOptions.map(lang => insertLanguageRow(lang))}
          </Flex>
        </Slot>
        <Slot name='actions'>
          <Flex alignMainAxis='space-between'>
            <Button size='small' variant='error' onClick={setHideForm}>
              {strings.cancel}
            </Button>
            <Button
              size='small'
              variant='primary'
              onClick={onSubmitForm}
              disabled={state.selectedLanguages?.length === 0}
            >
              {strings.generateTranslations}
            </Button>
          </Flex>
        </Slot>
      </Dialog>
    </Fragment>
  )
}

export default TranslationsForm

import { I18n } from 'aws-amplify'

import { getIsGodMode } from '@/Util/PermissionUtils'

export const Strings = () => ({
  dateRanges: I18n.get('Add Date Filter'),
  locationRanges: I18n.get('Add Location Filter'),
  duplicateLocation: I18n.get('Duplicate Last Location Filter'),
  presetTimeRange: I18n.get('Preset Time Range'),
  startDate: I18n.get('Start Date'),
  endDate: I18n.get('End Date'),
  graphInterval: I18n.get('Graph Interval'),
  location: I18n.get('Location'),
  dateRange: I18n.get('Date Range')
})

export const dateFilterHeaders = [
  'emptySpace',
  'presetTimeRange',
  'startDate',
  'endDate',
  'graphInterval',
  'emptySpace'
]

export const zoneFilterHeaders = ['emptySpace']

const userHasOrgAccess = (orgId, currentUser) => {
  const orgIdIncludedInUser = currentUser.organizationIds?.includes(orgId)
  const isGodMode = getIsGodMode(currentUser)

  return isGodMode || orgIdIncludedInUser
}

export const groupHierarchyByOrgId = (zoneHierarchy, currentUser) => {
  const zoneHierarchyValues = Object.values(zoneHierarchy)
  const organizations = {}

  for (let site of zoneHierarchyValues) {
    const orgId = site.organizationId

    const isOrgAccessibleByUser = userHasOrgAccess(orgId, currentUser)

    if (isOrgAccessibleByUser) {
      if (!organizations[orgId]) {
        organizations[orgId] = {}
      }
      organizations[orgId][site.id] = site
    }
  }

  return organizations
}
export const getFilteredOrganizations = (organizations, currentUser) => {
  let filteredOrganizations = []
  for (let org of organizations) {
    const isOrgAccessibleByUser = userHasOrgAccess(org.id, currentUser)

    if (isOrgAccessibleByUser) {
      filteredOrganizations.push({ value: org.id, label: org.name })
    }
  }

  return filteredOrganizations
}

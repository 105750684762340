import { combineReducers } from 'redux'

// Old Redux reducers
import operationsReducer from './operations'
import dashboardOperationsReducer from './operations/dashboard'
import tsCurrentReducer from './ts/current'
import dashboardReducer from './ts/dashboard'
import tsTimeframeReducer from './ts/timeframe'
import greengrassReducer from './management/greengrass'

// Slices
import auditCognitoReducer from '@/slices/audit/cognito'
import auditLogReducer from '@/slices/audit/log'
import calibrationDeviceReducer from '@/slices/calibration/device'
import calibrationNvsSlice from '@/slices/calibration/nvs'
import ccControlDeviceConfigReducer from '@/slices/control/controlDeviceConfig'
import deviceManagementInitReducer from '@/slices/deviceManagement'
import deviceManagementDeviceTypeReducer from '@/slices/deviceManagement/deviceType'
import deviceManagementFlashScriptReducer from '@/slices/deviceManagement/flashScript'
import deviceManagementMeasurementReducer from '@/slices/deviceManagement/measurement'
import diagnosticsDeviceIsOfflineReducer from '@/slices/diagnostics/deviceIsOffline'
import diagnosticsSensorReducer from '@/slices/diagnostics/sensor'
import diagnosticsSensorSimReducer from '@/slices/diagnostics/sensorSim'
import fertilizerReducer from '@/slices/fertigation/fertilizer'
import fyiHelpTextReducer from '@/slices/fyi/helpText'
import fyiReleaseNotesReducer from '@/slices/fyi/releaseNotes'
import fyiTagsReducer from '@/slices/fyi/tags'
import locationReducer from '@/slices/location'
import managementDashboardReducer from '@/slices/management/dashboard'
import managementDeviceReducer from '@/slices/management/device'
import managementDeviceEnrollmentReducer from '@/slices/management/device/deviceEnrollment'
import managementDeviceSupplierReducer from '@/slices/management/device/deviceSupplier'
import managementFeatureFlagReducer from '@/slices/management/featureFlag'
import managementHierarchyReducer from '@/slices/management/hierarchy'
import managementOrganizationReducer from '@/slices/management/organization'
import managementOrganizationLabelsReducer from '@/slices/management/organizationLabels'
import managementUserReducer from '@/slices/management/user'
import managementRoleReducer from '@/slices/management/role'
import managementPermissionReducer from '@/slices/management/permission'
import managementSettingsReducer from '@/slices/management/settings'
import managementSupplierReducer from '@/slices/management/supplier'
import managementWhatAppReducer from '@/slices/management/whatsApp'
import managementZoneReducer from '@/slices/management/zone'
import managerReducer from '@/slices/manager'
import mlPredictionReducer from '@/slices/ml/prediction'
import mlSiteTrackerReducer from '@/slices/ml/siteTracker'
import navigationReducer from '@/slices/navigation'
import otaReducer from '@/slices/ota'
import pingerReducer from '@/slices/pinger'
import reportReducer from '@/slices/report'
import serviceWorkerReducer from '@/slices/serviceWorker'
import supplierEnrollmentReducer from '@/slices/supplier/enrollment'
import supportReducer from '@/slices/support'
import thresholdReducer from '@/slices/threshold'
import thresholdSensorThresholdStatusReducer from '@/slices/threshold/sensorThresholdStatus'
import thresholdSensorThresholdUserAckReducer from '@/slices/threshold/sensorThresholdUserAck'
import thresholdSensorThresholdUserSnooze from '@/slices/threshold/sensorThresholdUserSnooze'
import timestreamIngestionFrequencyReducer from '@/slices/timestream/ingestionFrequency'
import timestreamUsagePlanReducer from '@/slices/timestream/usagePlan'
import utilReducer from '@/slices/util'
import videoStreamReducer from '@/slices/video/stream'
import weatherForecastReducer from '@/slices/weather/forecast'
import operationsProduceThresholdReducer from '@/slices/operations/produceThreshold'
import operationsProduceThresholdMessageReducer from '@/slices/operations/produceThresholdMessage'

export default combineReducers({
  // deviceReducer,
  utilReducer,
  operationsReducer,
  tsCurrentReducer,
  tsTimeframeReducer,
  greengrassReducer,
  dashboardReducer,
  dashboardOperationsReducer,
  auditCognitoReducer,
  auditLogReducer,
  calibrationDeviceReducer,
  calibrationNvsSlice,
  ccControlDeviceConfigReducer,
  deviceManagementInitReducer,
  deviceManagementDeviceTypeReducer,
  deviceManagementFlashScriptReducer,
  deviceManagementMeasurementReducer,
  diagnosticsDeviceIsOfflineReducer,
  diagnosticsSensorReducer,
  diagnosticsSensorSimReducer,
  fertilizerReducer,
  fyiHelpTextReducer,
  fyiReleaseNotesReducer,
  fyiTagsReducer,
  locationReducer,
  managementDashboardReducer,
  managementDeviceReducer,
  managementDeviceEnrollmentReducer,
  managementDeviceSupplierReducer,
  managementFeatureFlagReducer,
  managementHierarchyReducer,
  managementOrganizationReducer,
  managementOrganizationLabelsReducer,
  managementPermissionReducer,
  managementRoleReducer,
  managementSettingsReducer,
  managementSupplierReducer,
  managementUserReducer,
  managementWhatAppReducer,
  managementZoneReducer,
  managerReducer,
  mlPredictionReducer,
  mlSiteTrackerReducer,
  navigationReducer,
  otaReducer,
  pingerReducer,
  reportReducer,
  serviceWorkerReducer,
  supplierEnrollmentReducer,
  supportReducer,
  thresholdReducer,
  thresholdSensorThresholdStatusReducer,
  thresholdSensorThresholdUserAckReducer,
  thresholdSensorThresholdUserSnooze,
  timestreamIngestionFrequencyReducer,
  timestreamUsagePlanReducer,
  videoStreamReducer,
  weatherForecastReducer,
  operationsProduceThresholdReducer,
  operationsProduceThresholdMessageReducer
})

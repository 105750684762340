import { makeOperationsApiCall } from './utils'

export const getShippingMethods = async params => {
  try {
    const query = /* GraphQL */ `
      query getShippingMethods(
        $filter: GetShippingMethodsFilter
        $order: OrderEnum
        $offset: Int
        $limit: Int
      ) {
        getShippingMethods(
          filter: $filter
          order: $order
          offset: $offset
          limit: $limit
        ) {
          shippingMethods {
            id
            organizationId
            name
            storageFacility {
              id
              name
            }
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getShippingMethods',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getShippingMethod = async params => {
  try {
    const query = /* GraphQL */ `
      query getShippingMethod($shippingMethodId: String!) {
        getShippingMethod(shippingMethodId: $shippingMethodId) {
          id
          organizationId
          storageFacilityId
          name
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getShippingMethod',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const createShippingMethod = async params => {
  try {
    const query = /* GraphQL */ `
      mutation createShippingMethod(
        $organizationId: String!
        $name: String!
        $storageFacilityId: String!
      ) {
        createShippingMethod(
          organizationId: $organizationId
          name: $name
          storageFacilityId: $storageFacilityId
        ) {
          id
          organizationId
          name
          storageFacility {
            id
            name
          }
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'createShippingMethod',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updateShippingMethod = async params => {
  try {
    const query = /* GraphQL */ `
      mutation updateShippingMethod(
        $shippingMethodId: String!
        $organizationId: String!
        $name: String!
        $storageFacilityId: String!
      ) {
        updateShippingMethod(
          shippingMethodId: $shippingMethodId
          organizationId: $organizationId
          name: $name
          storageFacilityId: $storageFacilityId
        ) {
          id
          organizationId
          name
          storageFacility {
            id
            name
          }
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'updateShippingMethod',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

/**
 *
 * @param {string} parentPath
 * @param {Map} coreDeviceMap
 * @returns
 */
export function isZoneCoreDeviceLineage(parentPath, coreDeviceMap) {
  // check if parentPath is a key in coreDeviceMap
  if (coreDeviceMap.has(parentPath)) {
    return true
  }
  for (let coreDevicePath of coreDeviceMap.keys()) {
    // partial match of parentPath with coreDeviceMap key means that parentPath is a child of a coreDevice
    if (parentPath.startsWith(coreDevicePath)) {
      return true
    }
  }
  return false
}

/**
 * Returns a map of core device branch paths and their corresponding core device id
 * @param {Object} sourceNode
 * @param {Object} tree
 * @returns
 */
export function getCoreDevicePathsAndType(sourceNode, tree) {
  let connectionMethod = null
  let coreDevicePaths = new Map()

  // traverse path of source device and check if any of the nodes in path has core device (use tree to traverse)
  let path = sourceNode.parentPath.split('/').slice(1)

  for (let i = 0; i < path.length; i++) {
    let node = tree
    for (let j = 1; j <= i; j++) {
      node = node?.children[path[j]]
    }
    if (node?.hasCoreDevice) {
      connectionMethod = 'core'
      break
    }
  }

  // if node has core device then set connectionMethod to 'core', else set it to 'standalone'
  if (connectionMethod === null) {
    connectionMethod = 'standalone'
  }

  // traverse tree from root node (skip source node path) and check if any of the nodes has core device
  function traverse(node, path) {
    if (node?.hasCoreDevice) {
      coreDevicePaths.set(path, 'core')
      return
    }
    for (let childPath in node?.children) {
      let child = node?.children[childPath]
      traverse(child, path + '/' + childPath)
    }
  }
  traverse(tree, tree.parentPath)

  return { coreDevicePaths, connectionMethod }
}

import { Fragment, useCallback, useEffect, useState } from 'react'
import { I18n } from 'aws-amplify'
import { useDispatch } from 'react-redux'

import { requestRegions } from '@/actions/operations/region'

import {
  getCurrentUser,
  getCurrentUserOrganizations,
  getOperationsRegions,
  getOperationsRegionsCount,
  getOperationsLoading
} from '@/reducers/selectors'

import {
  Flex,
  Button,
  Cell,
  Text,
  Column,
  Row,
  Slot,
  Table,
  Loader
} from '@/primitives'

import { Pagination } from '@/elements'

import HeaderV2 from '@/components/Operations/Shared/HeaderV2'
import TableFilter from './TableFilter'

import history from '../../../../history'

import {
  hasEditPermissions,
  RESOURCE_TYPE_OPERATIONS,
  RESOURCE_TYPE_OPERATIONS_MANAGEMENT
} from '@/Util/PermissionUtils'

function RegionTable({ state, dispatchState, modulePath, backPath }) {
  const dispatch = useDispatch()

  const currentUser = getCurrentUser()
  const organizations = getCurrentUserOrganizations()
  const regions = getOperationsRegions()
  const regionsCount = getOperationsRegionsCount()
  const loading = getOperationsLoading()

  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(9)
  const [tableState, setTableState] = useState(state)
  const [canEdit, setCanEdit] = useState(false)
  const [showOrganization, setShowOrganization] = useState(false)

  const fetchRegions = useCallback(
    page => {
      const { organizations, countries } = state
      if (organizations.length > 0 && countries.length > 0) {
        dispatch(
          requestRegions({
            filter: {
              organizationId: organizations,
              countryId: countries
            },
            limit: pageSize,
            offset: page - 1
          })
        )
      }
    },
    [state, pageSize, dispatch]
  )

  const changePage = useCallback(
    newPage => {
      dispatchState({ page: newPage })
    },
    [dispatchState]
  )

  useEffect(() => {
    if (currentUser.userName) {
      setCanEdit(
        hasEditPermissions(
          currentUser,
          RESOURCE_TYPE_OPERATIONS,
          RESOURCE_TYPE_OPERATIONS_MANAGEMENT
        )
      )
    }
    if (organizations.length > 1) {
      setShowOrganization(true)
    }
  }, [currentUser, organizations])

  useEffect(() => {
    if (
      tableState.organizations !== state.organizations ||
      tableState.countries !== state.countries ||
      tableState.page !== state.page
    ) {
      fetchRegions(state.page)
    }
  }, [state, tableState, fetchRegions])

  useEffect(() => {
    setTableState({ ...state })
  }, [state])

  const onCreate = () => history.push(`${modulePath}/create`)

  const onEdit = id => history.push(`${modulePath}/update/${id}`)

  const onCities = (organizationId, countryId, id) => {
    dispatchState({
      state: {
        organizationId,
        countryId,
        regionId: id
      }
    })
    history.push(`${modulePath}/cities`)
  }

  const getOrganizationName = organizationId => {
    const organization = organizations.find(org => org.id === organizationId)
    return organization ? organization.name : null
  }

  return (
    <Fragment>
      <HeaderV2
        title={I18n.get('Manage Regions')}
        buttonIcon={'add'}
        buttonText={I18n.get('Add Region')}
        buttonCallback={onCreate}
        showButton={canEdit}
        backPath={backPath}
      >
        <TableFilter state={state} dispatchState={dispatchState} />
      </HeaderV2>
      <Loader isLoading={loading}>
        {regionsCount === 0 && (
          <Flex className={'Operations__Table__Empty'}>
            <Text>{I18n.get('No regions.')}</Text>
          </Flex>
        )}
        {regionsCount > 0 && (
          <Table aria-colcount='3' className='Operations__Table'>
            <Slot name='head'>
              <Row>
                {showOrganization && (
                  <Column>{I18n.get('Organization')}</Column>
                )}
                <Column>{I18n.get('Country')}</Column>
                <Column>{I18n.get('Region')}</Column>
                <Column></Column>
              </Row>
            </Slot>
            <Slot name='body'>
              {regions.map(
                ({ id, organizationId, name, countryId, country }) => (
                  <Row key={id}>
                    {showOrganization && (
                      <Cell columnName={I18n.get('Organization')}>
                        {getOrganizationName(organizationId)}
                      </Cell>
                    )}
                    <Cell columnName={I18n.get('Country')}>{country.name}</Cell>
                    <Cell columnName={I18n.get('Region')}>{name}</Cell>
                    <Cell className='Operations__Table__Column__Actions'>
                      {canEdit && (
                        <Button
                          variant='info'
                          size='small'
                          iconBefore='edit'
                          onClick={() => onEdit(id)}
                        />
                      )}
                      <Button
                        variant='info'
                        size='small'
                        iconBefore='edit_note'
                        onClick={() => onCities(organizationId, countryId, id)}
                      >
                        <Text fontWeight={700} textTransform='uppercase'>
                          {I18n.get('Cities')}
                        </Text>
                      </Button>
                    </Cell>
                  </Row>
                )
              )}
            </Slot>
          </Table>
        )}
      </Loader>
      {regionsCount > pageSize && (
        <Pagination
          type='simple'
          totalItems={regionsCount}
          totalPages={totalPages}
          setTotalPages={setTotalPages}
          page={tableState.page}
          setPage={changePage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </Fragment>
  )
}

export default RegionTable

import React from 'react'
import { Flex, Text } from '../../primitives'

import Strings from './Strings'

const NotFoundPage = () => {
  const strings = Strings()

  return (
    <Flex alignCrossAxis={'center'} alignMainAxis={'center'}>
      <Text as={'h2'}>{strings.notFoundPageTxt}</Text>
    </Flex>
  )
}

export default NotFoundPage

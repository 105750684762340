import { I18n } from 'aws-amplify'

import ZoneSelector from './ZoneSelector'
import OrganizationsFilter from '@/components/Operations/Shared/Filters/DataboardOrgsFilter'
import SitesFilter from '@/components/Operations/Shared/Filters/DataboardSitesFilter'
import AddRemoveButton from './AddRemoveButton'

import { Flex, Box, Icon, Text } from '@/primitives'

const warningStrings = { repeated_zone: I18n.get('Repeated') }

const MultiRowSelector = ({ state, dispatchState }) => {
  const addRow = () => {
    dispatchState({ newLocation: {} })
  }

  const removeRow = index => {
    dispatchState({ removeLocation: { index } })
  }

  let rows = []
  for (let i = 0; i < state.locations.length; i++) {
    const rowDispatchState = newValue => {
      dispatchState({ location: { ...newValue, index: i } })
    }
    rows.push(
      <Flex
        direction='row'
        axisGap={300}
        style={{
          marginBottom: '0.5rem',
          paddingBottom: '0.5rem',
          borderBottom: '1px solid var(--ctx-theme-color-page-400)'
        }}
        alignMainAxis='space-between'
        alignCrossAxis='flex-start'
        key={`filter-row-${i}`}
        wrap='nowrap'
      >
        <Flex direction='row' axisGap={300} style={{ flexGrow: '1' }}>
          <OrganizationsFilter
            stateOrgs={state.locations[i]?.organizations}
            dispatchState={rowDispatchState}
            disabled={i === 0}
            style={{ flexGrow: '1' }}
          />
          <SitesFilter
            stateSites={state.locations[i]?.sites}
            dispatchState={rowDispatchState}
            disabled={i === 0}
          />
          <ZoneSelector
            state={state.locations[i]}
            dispatchState={rowDispatchState}
            key={`row-${i}`}
            index={i}
          />
          {state.warnings[i]?.type && (
            <Box style={{ marginTop: '0.4rem' }}>
              <Icon
                name='error'
                variant='danger'
                tone={500}
                style={{ verticalAlign: 'bottom' }}
              />
              <Text
                size={100}
                variant='danger'
                tone={500}
                style={{ marginInlineStart: '0.3rem' }}
              >
                {warningStrings[state.warnings[i]?.type]}
              </Text>
            </Box>
          )}
        </Flex>
        <AddRemoveButton index={i} addRow={addRow} removeRow={removeRow} />
      </Flex>
    )
  }

  return <Box style={{ marginBottom: '2rem' }}>{rows}</Box>
}

export default MultiRowSelector

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { getAuditLogs } from '@/api/audit/logs'

import { cleanAuditLogNextToken } from './utils'

export const fetchAuditLogs = createAsyncThunk(
  'fetchAuditLogs',
  async params => {
    const response = await getAuditLogs(params)
    return response
  }
)

export const fetchNextTokenAuditLogs = createAsyncThunk(
  'fetchNextTokenAuditLogs',
  async params => {
    const response = await getAuditLogs(params)
    return response
  }
)

const auditLogSlice = createSlice({
  name: 'auditLogReducer',
  initialState: {
    logs: [],
    error: null,
    loading: false,
    count: 0,
    scannedCount: 0,
    nextToken: null,
    nextTokenCount: 0
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAuditLogs.pending, (state, action) => {
        state.error = null
        state.loading = true
        state.logs = []
        state.nextTokenCount = 0
      })
      .addCase(fetchAuditLogs.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.loading = false
        state.logs = action?.payload?.logs ?? []
        state.count = action?.payload?.count ?? 0
        state.scannedCount = action?.payload?.scannedCount ?? 0
        state.nextToken =
          cleanAuditLogNextToken(action?.payload?.nextToken) ?? null
      })
      .addCase(fetchNextTokenAuditLogs.pending, (state, action) => {
        state.error = null
        state.loading = true
      })
      .addCase(fetchNextTokenAuditLogs.fulfilled, (state, action) => {
        let totalCount = 0

        if (action?.payload?.logs?.length > 0) {
          totalCount = 1
        }

        state.error = action?.payload?.error ?? null
        state.loading = false
        state.nextTokenCount = totalCount
      })
  }
})

export default auditLogSlice.reducer

/**
 * isWidgetConfigurable checks if a widget is configurable.
 * @param {object} widgetConfig
 * @returns {boolean}
 */
export const isWidgetConfigurable = widgetConfig => {
  if (typeof widgetConfig === 'object' && widgetConfig != null) {
    if (Object.keys(widgetConfig).length === 0) {
      throw new Error(
        'Empty config object is not allowed. Set to null to disable configurability or add properties to the config object.'
      )
    }
    return true
  }
  return false
}

/**
 * Helper function to get string representation of position and size of a widget.
 * @param {Object} dataGrid
 * @returns {string}
 */
export const getDataGridString = ({ x, y, w, h, minW, minH }) =>
  `x${x}-y${y}-w${w}-h${h}-minW${minW}-minH${minH}`

export const getWidgetStartingMode = widgetProps => {
  // Add all rules for widgets that have to start in edit mode here
  const { widgetId } = widgetProps

  // start in edit mode for topTenHarvestVariety widget if produceId is not set
  if (widgetId === 'topTenHarvestVariety' && !widgetProps.config.produceId) {
    return 'edit'
  }

  return 'view'
}

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Switch, Route, Link } from 'react-router-dom'

import Strings from '../Strings'
import OrganizationsList from './OrganizationsList'
import UpsertOrganizationForm from './UpsertOrganizationForm'
import UsagePlanModal from './UsagePlanModal'

import { Flex, Text, Button } from '@/primitives'

import { LineSeparator } from '@/elements'

import { showBanner } from '@/slices/util'

import { getOrganizationError } from '@/reducers/selectors'

export default function OrganizationsPage() {
  const dispatch = useDispatch()
  const error = getOrganizationError()

  const strings = Strings()
  const showEditOptions = true //TODO: check edit permissions dynamically

  useEffect(() => {
    if (error)
      dispatch(showBanner({ show: true, message: error, type: 'error' }))
  }, [dispatch, error])

  return (
    <div>
      <Flex alignMainAxis='space-between' alignCrossAxis='center'>
        <Text size='300' variant='page' tone='800' fontWeight='300'>
          {strings.orgsTab}
        </Text>
        {showEditOptions && (
          <Button
            variant='primary'
            size='small'
            as={Link}
            to={{
              pathname: '/admin/organizations/add'
            }}
          >
            {strings.orgAddButton}
          </Button>
        )}
      </Flex>
      <LineSeparator />
      <OrganizationsList />
      <Switch>
        <Route path='/admin/organizations/add'>
          <UpsertOrganizationForm />
        </Route>
        <Route path='/admin/organizations/:organizationId/edit'>
          <UpsertOrganizationForm />
        </Route>
        <Route path='/admin/organizations/:organizationId/usage-plan'>
          <UsagePlanModal />
        </Route>
      </Switch>
    </div>
  )
}

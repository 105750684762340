import { createRandomDeviceName, getBaseDeviceTypeConfig } from './utils'

export const UPDATE_DEVICE_FIELD = 'UPDATE_DEVICE_FIELD'
export const UPDATE_MODBUS_CONFIG_FIELD = 'UPDATE_MODBUS_CONFIG_FIELD'
export const UPDATE_MODBUS_CONFIG_MEASUREMENTS =
  'UPDATE_MODBUS_CONFIG_MEASUREMENTS'
export const TOGGLE_MODBUS_CONFIG_MULTI_READ = 'TOGGLE_MODBUS_CONFIG_MULTI_READ'
export const SET_MODBUS_CONFIG = 'SET_MODBUS_CONFIG'
export const SET_DEVICE_TYPE_MEASUREMENTS = 'SET_DEVICE_TYPE_MEASUREMENTS'
export const UPDATE_CONNECTION_FIELDS = 'UPDATE_CONNECTION_FIELDS'
export const SET_DEVICE_STATE = 'SET_DEVICE_STATE'
export const SET_SITE_HIERARCHY = 'SET_SITE_HIERARCHY'
export const SET_DISPLAY_PATH = 'SET_DISPLAY_PATH'
export const SET_SHOW_DEVICE_MOVER_VIEW = 'SET_SHOW_DEVICE_MOVER_VIEW'
export const SET_SELECTED_ZONE_TO_MOVE_TO = 'SET_SELECTED_ZONE_TO_MOVE_TO'
export const CONFIRM_DEVICE_MOVE = 'CONFIRM_DEVICE_MOVE'
export const CANCEL_DEVICE_MOVE = 'CANCEL_DEVICE_MOVE'
export const SET_SHOW_DEVICE_MOVER_CONFIRMATION_MODAL =
  'SET_SHOW_DEVICE_MOVER_CONFIRMATION_MODAL'
export const SET_SHOW_MODBUS_CONFIG_EDITOR_VIEW =
  'SET_SHOW_MODBUS_CONFIG_EDITOR_VIEW'
export const SET_SHOW_SIM_CONFIG_VIEW = 'SET_SHOW_SIM_CONFIG_VIEW'

export function createInitialState() {
  return {
    siteHierarchy: null,
    displayPath: null,
    showDeviceMoverView: false,
    selectedZoneToMoveTo: null,
    showDeviceMoverConfirmationModal: false,
    showModbusConfigEditorView: false,
    showSimConfigView: false,
    deviceTypeMeasurementsMap: {},
    device: {
      id: '',
      name: createRandomDeviceName(),
      sensorType: null,
      zoneId: null,
      zonePath: null,
      proofOfPossession: null,
      hardwareVersion: null,
      latitude: '0',
      longitude: '0',
      tag: '',
      createdTimestamp: null,
      isIoT: true,
      isGreengrass: false,
      config: null,
      lastMoveAt: null,
      notInUse: false,
      sleepCycleMinutes: 5,
      supplierDevice: {
        deviceId: null,
        name: null,
        type: null,
        enrolled: null
      }
    }
  }
}

export function formReducer(state, action) {
  const handlers = {
    [SET_DEVICE_TYPE_MEASUREMENTS]: (state, action) => {
      const deviceTypeMeasurements = action.payload
      const deviceTypeMeasurementsMap = deviceTypeMeasurements.reduce(
        (acc, obj) => {
          acc[obj.key] = obj
          return acc
        },
        {}
      )

      const isModbus = state.device.isGreengrass && !state.device.isIoT

      return {
        ...state,
        deviceTypeMeasurementsMap,
        device: {
          ...state.device,
          config: isModbus
            ? getBaseDeviceTypeConfig(deviceTypeMeasurements, state)
            : null
        }
      }
    },
    [SET_SHOW_SIM_CONFIG_VIEW]: (state, action) => {
      return {
        ...state,
        showSimConfigView: action.payload
      }
    },
    [SET_SHOW_MODBUS_CONFIG_EDITOR_VIEW]: (state, action) => {
      return {
        ...state,
        showModbusConfigEditorView: action.payload
      }
    },
    [SET_SHOW_DEVICE_MOVER_CONFIRMATION_MODAL]: (state, action) => {
      return {
        ...state,
        showDeviceMoverConfirmationModal: action.payload
      }
    },
    [CANCEL_DEVICE_MOVE]: (state, action) => {
      return {
        ...state,
        showDeviceMoverView: false,
        selectedZoneToMoveTo: null
      }
    },
    [CONFIRM_DEVICE_MOVE]: (state, action) => {
      return {
        ...state,
        device: {
          ...state.device,
          zoneId: action.payload.zoneId,
          zonePath: action.payload.zonePath
        },
        displayPath: action.payload.displayPath,
        showDeviceMoverView: false
      }
    },
    [SET_MODBUS_CONFIG]: (state, action) => {
      return {
        ...state,
        device: {
          ...state.device,
          config: action.payload
        }
      }
    },
    [SET_SELECTED_ZONE_TO_MOVE_TO]: (state, action) => {
      return {
        ...state,
        selectedZoneToMoveTo: action.payload
      }
    },
    [SET_SHOW_DEVICE_MOVER_VIEW]: (state, action) => {
      return {
        ...state,
        showDeviceMoverView: action.payload
      }
    },
    [SET_SITE_HIERARCHY]: (state, action) => {
      return {
        ...state,
        siteHierarchy: action.payload
      }
    },
    [SET_DISPLAY_PATH]: (state, action) => {
      return {
        ...state,
        displayPath: action.payload
      }
    },
    [SET_DEVICE_STATE]: (state, action) => {
      return {
        ...state,
        device: {
          ...state.device,
          ...action.payload,
          config: action.payload.config ?? null
        }
      }
    },
    [UPDATE_DEVICE_FIELD]: (state, action) => {
      const { field, value } = action.payload
      return {
        ...state,
        device: {
          ...state.device,
          [field]: value
        }
      }
    },
    [UPDATE_CONNECTION_FIELDS]: (state, action) => {
      return {
        ...state,
        device: {
          ...state.device,
          isIoT: action.payload.isIoT,
          isGreengrass: action.payload.isGreengrass,
          config: action.payload.config ?? null
        }
      }
    },
    [UPDATE_MODBUS_CONFIG_FIELD]: (state, action) => {
      const { field, value } = action.payload
      return {
        ...state,
        device: {
          ...state.device,
          config: {
            ...state.device.config,
            [field]: value
          }
        }
      }
    },
    [UPDATE_MODBUS_CONFIG_MEASUREMENTS]: (state, action) => {
      let read_measurementType = state.device.config.read_measurementType
      let read_measurementUnit = state.device.config.read_measurementUnit
      let read_registers = state.device.config.read_registers

      if (action.payload.userAction === 'delete') {
        read_measurementType.splice(action.payload.index, 1)
        read_measurementUnit.splice(action.payload.index, 1)
        read_registers.splice(action.payload.index, 1)
      } else if (action.payload.userAction === 'add') {
        let measurementType =
          state.deviceTypeMeasurementsMap[action.payload.measurementType]
        read_measurementType.push(measurementType.value)
        read_measurementUnit.push(measurementType.unit)
        read_registers.push(measurementType.register)
      }

      return {
        ...state,
        device: {
          ...state.device,
          config: {
            ...state.device.config,
            read_measurementType,
            read_measurementUnit,
            read_registers
          }
        }
      }
    },
    [TOGGLE_MODBUS_CONFIG_MULTI_READ]: (state, action) => {
      const config = JSON.parse(JSON.stringify(state.device.config))
      let isMultiRead = config?.read_register_count

      if (isMultiRead) {
        delete config.read_register_count
      } else {
        config.read_register_count = Array(
          config.read_measurementType.length
        ).fill(2)
      }

      return {
        ...state,
        device: {
          ...state.device,
          config
        }
      }
    }
  }

  if (handlers[action.type] === undefined) {
    throw new Error(`Unhandled action type: ${action.type}`)
  }

  const nextState = handlers[action.type](state, action)

  // console.groupCollapsed(
  //   `%c${action.type}`,
  //   'color: #000; font-weight: lighter;'
  // )
  // console.log('%cPrevious State:', 'color: #9E9E9E; font-weight: bold;', state)
  // console.log('%cAction:', 'color: #00A7F7; font-weight: bold;', action)
  // console.log('%cNext State:', 'color: #47B04B; font-weight: bold;', nextState)
  // console.groupEnd()

  return nextState
}

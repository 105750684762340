export function userIdToName(userId, zoneUsers) {
  const user = getUser(userId, zoneUsers)
  if (user) {
    if (user?.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`
    }

    return user.userName
  }

  return userId
}

export function userIdEmail(userId, zoneUsers) {
  const user = getUser(userId, zoneUsers)
  if (user) {
    return user.email
  }

  return ''
}

function getUser(userId, zoneUsers) {
  return zoneUsers.find(({ userName }) => userName === userId)
}

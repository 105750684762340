import { I18n } from 'aws-amplify'

const Strings = () => ({
  manageBranches: I18n.get('Manage Branches'),
  createBranch: I18n.get('Create Branch'),
  createFormHeader: I18n.get('Create New Branch'),
  updateFormHeader: I18n.get('Update Existing Branch'),
  saveBranch: I18n.get('Save Branch'),
  name: I18n.get('Name'),
  company: I18n.get('Company'),
  contact: I18n.get('Contact'),
  location: I18n.get('Location'),
  status: I18n.get('Status'),
  selectDefault: I18n.get('Please Select'),
  noBranches: I18n.get('No branches'),
  active: I18n.get('Active'),
  inactive: I18n.get('Inactive'),
  closed: I18n.get('Closed'),
  banned: I18n.get('Banned'),
  branch: I18n.get('Branch'),
  customer: I18n.get('Customer'),
  code: I18n.get('Code'),
  onboardDate: I18n.get('Onboarding Date'),
  contactName: I18n.get('Contact Name'),
  contactNumber: I18n.get('Contact Number'),
  contactEmail: I18n.get('Contact Email'),
  country: I18n.get('Country'),
  region: I18n.get('Region'),
  city: I18n.get('City'),
  area: I18n.get('Area'),
  payment: I18n.get('Payment'),
  paymentMethod: I18n.get('Payment Method'),
  paymentTerms: I18n.get('Payment Terms'),
  currency: I18n.get('Currency')
})

export default Strings

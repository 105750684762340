import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { getCognitoData, makeGraphqlRequest } from '../utils'

/*
 * Queries
 */

export const getAllDeviceTypes = async () => {
  try {
    const query = /* GraphQL */ `
      query getAllDeviceTypes {
        getAllDeviceTypes {
          id
          name
          type
          measurements
          supportsGreengrass
          hasFirmware
          hardwareVersions {
            hardwareVersion
            firmwareVersions {
              date
              version
            }
            flashScriptVersion
            active
          }
          createdAt
          updatedAt
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, {})
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        deviceTypes: null,
        error: `Device types not fetched: ${error}`
      }
    }

    return { deviceTypes: data?.getAllDeviceTypes, error: null }
  } catch (err) {
    return { deviceTypes: null, error: err.message }
  }
}

export const getAllDeviceTypeTypes = async () => {
  try {
    const query = /* GraphQL */ `
      query getDeviceTypeTypes {
        getDeviceTypeTypes
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, {})
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        deviceTypeTypes: null,
        error: `Device type types not fetched: ${error}`
      }
    }

    return { deviceTypeTypes: data?.getDeviceTypeTypes, error: null }
  } catch (err) {
    return { deviceTypeTypes: null, error: err.message }
  }
}

export const getDeviceFirmwareVersions = async params => {
  try {
    const query = /* GraphQL */ `
      query getDeviceFirmwareVersions($deviceId: String!) {
        getDeviceFirmwareVersions(deviceId: $deviceId) {
          firmwareVersions {
            version
            date
          }
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        firmwareVersions: [],
        error: `Device firmware not fetched: ${error}`
      }
    }

    return {
      firmwareVersions: data?.getDeviceFirmwareVersions?.firmwareVersions,
      error: null
    }
  } catch (err) {
    return { firmwareVersions: null, error: err.message }
  }
}

/*
 * Mutations
 */

export const createDeviceType = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createDeviceType(
        $id: String!
        $name: String!
        $type: String!
        $measurements: [String]
        $supportsGreengrass: Boolean
        $hasFirmware: Boolean
        $hardwareVersions: [HardwareVersionInput]
      ) {
        createDeviceType(
          id: $id
          name: $name
          type: $type
          measurements: $measurements
          supportsGreengrass: $supportsGreengrass
          hasFirmware: $hasFirmware
          hardwareVersions: $hardwareVersions
        ) {
          id
          name
          type
          measurements
          supportsGreengrass
          hasFirmware
          hardwareVersions {
            hardwareVersion
            firmwareVersions {
              date
              version
            }
            flashScriptVersion
            active
          }
          createdAt
          updatedAt
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        deviceType: null,
        error: `Device type not created: ${error}`
      }
    }

    return { deviceType: data?.createDeviceType, error: null }
  } catch (err) {
    return { deviceType: null, error: err.message }
  }
}

export const updateDeviceType = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateDeviceType(
        $id: String!
        $name: String!
        $type: String!
        $measurements: [String]
        $hasFirmware: Boolean
        $supportsGreengrass: Boolean
        $hardwareVersions: [HardwareVersionInput]
      ) {
        updateDeviceType(
          id: $id
          name: $name
          type: $type
          measurements: $measurements
          supportsGreengrass: $supportsGreengrass
          hasFirmware: $hasFirmware
          hardwareVersions: $hardwareVersions
        ) {
          id
          name
          type
          measurements
          supportsGreengrass
          hasFirmware
          hardwareVersions {
            hardwareVersion
            firmwareVersions {
              date
              version
            }
            flashScriptVersion
            active
          }
          createdAt
          updatedAt
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        deviceType: null,
        error: `Device type not updated: ${error}`
      }
    }

    return { deviceType: data?.updateDeviceType, error: null }
  } catch (err) {
    return { deviceType: null, error: err.message }
  }
}

import { ResponsiveHeatMap } from '@nivo/heatmap'
import { Loader } from '@/primitives'
import theme from '../theme.json'

/*const mockData = [
  {
    id: 'Japan',
    data: [
      {
        x: 'Train',
        y: -57946
      },
      {
        x: 'Subway',
        y: -3561
      },
      {
        x: 'Bus',
        y: 72469
      },
      {
        x: 'Car',
        y: -81116
      },
      {
        x: 'Boat',
        y: 7407
      },
      {
        x: 'Moto',
        y: 71221
      },
      {
        x: 'Moped',
        y: 46926
      },
      {
        x: 'Bicycle',
        y: 79485
      },
      {
        x: 'Others',
        y: -49140
      }
    ]
  },
  {
    id: 'France',
    data: [
      {
        x: 'Train',
        y: -94662
      },
      {
        x: 'Subway',
        y: -92325
      },
      {
        x: 'Bus',
        y: 98148
      },
      {
        x: 'Car',
        y: 42457
      },
      {
        x: 'Boat',
        y: 95062
      },
      {
        x: 'Moto',
        y: -34750
      },
      {
        x: 'Moped',
        y: -1067
      },
      {
        x: 'Bicycle',
        y: 38171
      },
      {
        x: 'Others',
        y: -59496
      }
    ]
  },
  {
    id: 'US',
    data: [
      {
        x: 'Train',
        y: 26397
      },
      {
        x: 'Subway',
        y: -63733
      },
      {
        x: 'Bus',
        y: 22303
      },
      {
        x: 'Car',
        y: -32787
      },
      {
        x: 'Boat',
        y: -14576
      },
      {
        x: 'Moto',
        y: 65531
      },
      {
        x: 'Moped',
        y: -75450
      },
      {
        x: 'Bicycle',
        y: -96253
      },
      {
        x: 'Others',
        y: 61926
      }
    ]
  },
  {
    id: 'Germany',
    data: [
      {
        x: 'Train',
        y: -83074
      },
      {
        x: 'Subway',
        y: 99344
      },
      {
        x: 'Bus',
        y: 31314
      },
      {
        x: 'Car',
        y: 54987
      },
      {
        x: 'Boat',
        y: -39293
      },
      {
        x: 'Moto',
        y: 72428
      },
      {
        x: 'Moped',
        y: 7848
      },
      {
        x: 'Bicycle',
        y: 6480
      },
      {
        x: 'Others',
        y: 64727
      }
    ]
  },
  {
    id: 'Norway',
    data: [
      {
        x: 'Train',
        y: -37829
      },
      {
        x: 'Subway',
        y: -31935
      },
      {
        x: 'Bus',
        y: 64043
      },
      {
        x: 'Car',
        y: -55852
      },
      {
        x: 'Boat',
        y: 7628
      },
      {
        x: 'Moto',
        y: 37527
      },
      {
        x: 'Moped',
        y: 43505
      },
      {
        x: 'Bicycle',
        y: -67497
      },
      {
        x: 'Others',
        y: 59506
      }
    ]
  },
  {
    id: 'Iceland',
    data: [
      {
        x: 'Train',
        y: 8737
      },
      {
        x: 'Subway',
        y: -23449
      },
      {
        x: 'Bus',
        y: 31302
      },
      {
        x: 'Car',
        y: -16959
      },
      {
        x: 'Boat',
        y: -54035
      },
      {
        x: 'Moto',
        y: -2117
      },
      {
        x: 'Moped',
        y: -95034
      },
      {
        x: 'Bicycle',
        y: -19773
      },
      {
        x: 'Others',
        y: 79606
      }
    ]
  },
  {
    id: 'UK',
    data: [
      {
        x: 'Train',
        y: 77589
      },
      {
        x: 'Subway',
        y: -68486
      },
      {
        x: 'Bus',
        y: -35763
      },
      {
        x: 'Car',
        y: 12805
      },
      {
        x: 'Boat',
        y: 66875
      },
      {
        x: 'Moto',
        y: 82233
      },
      {
        x: 'Moped',
        y: -20703
      },
      {
        x: 'Bicycle',
        y: -44732
      },
      {
        x: 'Others',
        y: 45978
      }
    ]
  },
  {
    id: 'Vietnam',
    data: [
      {
        x: 'Train',
        y: 17361
      },
      {
        x: 'Subway',
        y: 61542
      },
      {
        x: 'Bus',
        y: 48681
      },
      {
        x: 'Car',
        y: -55275
      },
      {
        x: 'Boat',
        y: 1168
      },
      {
        x: 'Moto',
        y: -15745
      },
      {
        x: 'Moped',
        y: -82184
      },
      {
        x: 'Bicycle',
        y: -94856
      },
      {
        x: 'Others',
        y: 42111
      }
    ]
  }
]*/

const CHAR_WIDTH = 6
const DEFAULT_WIDTH = 60
const DEFAULT_HEIGHT = 48

const calcMarginFromData = data => {
  if (!data.length) {
    return DEFAULT_WIDTH
  }

  const sortDataByIdLength = data.sort((a, b) => b.id.length - a.id.length)
  const margin = sortDataByIdLength[0].id.length * CHAR_WIDTH
  return margin > DEFAULT_WIDTH ? margin : DEFAULT_WIDTH
}

const calcTopMarginFromData = data => {
  if (!data.length) {
    return DEFAULT_HEIGHT
  }
  const { x } = data[0].data[0]
  const margin = x.length * CHAR_WIDTH
  return margin > DEFAULT_HEIGHT ? margin : DEFAULT_HEIGHT
}

const Heatmap = ({ data, thresholds }) => {
  return (
    <Loader isLoading={data.length < 1}>
      <ResponsiveHeatMap
        data={data}
        theme={theme}
        axisTop={{
          tickRotation: -45
        }}
        margin={{
          top: Math.floor(calcTopMarginFromData(data)),
          right: 48,
          bottom: 48,
          left: Math.floor(calcMarginFromData(data))
        }}
        valueFormat='>-.2s'
        colors={{
          type: 'diverging',
          scheme: 'turbo',
          divergeAt: 0.5,
          minValue: thresholds[0],
          maxValue: thresholds[1]
        }}
        emptyColor='#555555'
        legends={[
          {
            anchor: 'bottom',
            translateX: 0,
            translateY: 30,
            length: 400,
            thickness: 8,
            direction: 'row',
            tickPosition: 'after',
            tickSize: 3,
            tickSpacing: 4,
            tickOverlap: false,
            tickFormat: '>-.2s',
            title: '',
            titleAlign: 'start',
            titleOffset: 4
          }
        ]}
      />
    </Loader>
  )
}

export default Heatmap

import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { makeGraphqlRequestV2 } from '../utils'

/*
 * Mutations
 */

export const configureControlDeviceSpec = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation configureControlDeviceSpec(
        $coreDeviceId: String!
        $controlDeviceId: String!
        $inputs: [ControlDeviceSpecInputType]
      ) {
        configureControlDeviceSpec(
          coreDeviceId: $coreDeviceId
          controlDeviceId: $controlDeviceId
          inputs: $inputs
        ) {
          controlDeviceId
          relayId
          sensorId
          measurementId
          conditions {
            minValue
            maxValue
          }
        }
      }
    `

    const operation = graphqlOperation(mutation, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)
    return data?.configureControlDeviceSpec
  } catch (err) {
    throw err
  }
}

export const updateControlDeviceSpec = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateControlDeviceSpec(
        $coreDeviceId: String!
        $controlDeviceId: String!
        $inputs: [ControlDeviceSpecInputType]
      ) {
        updateControlDeviceSpec(
          coreDeviceId: $coreDeviceId
          controlDeviceId: $controlDeviceId
          inputs: $inputs
        ) {
          controlDeviceId
          relayId
          sensorId
          measurementId
          conditions {
            minValue
            maxValue
          }
        }
      }
    `

    const operation = graphqlOperation(mutation, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)
    return data?.updateControlDeviceSpec
  } catch (err) {
    throw err
  }
}

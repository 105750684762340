import { useRef, useEffect, useState } from 'react'

import { Box, Text } from '@/primitives'
import { Tooltip } from '@/elements'

export default function SensorTag({ tag, maxWidth = 120 }) {
  const tipRef = useRef(null)
  const [isOverflow, setIsOverflow] = useState(false)

  useEffect(() => {
    if (tipRef.current) {
      setIsOverflow(tipRef.current.offsetWidth > maxWidth)
    }
  }, [maxWidth, tipRef?.current])

  return (
    <Box
      style={{ display: tag ? 'block' : 'none', maxWidth }}
      className='Breadcrumb__Tag'
    >
      {tag && (
        <Text as='div' tone={500} variant='primary' fontWeight={600}>
          <Tooltip
            tipRef={tipRef}
            className='Breadcrumb__Tag__Text'
            tip={tag}
            showTooltip={isOverflow}
          >
            {tag}
          </Tooltip>
        </Text>
      )}
    </Box>
  )
}

import { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Column, Row, Slot, Table } from '@/primitives'
import { Select } from '@/elements'
import { getDeviceTypes, getCurrentUser } from '@/reducers/selectors'
import { setDevice } from '@/slices/management/device'
import {
  hasEditPermissions,
  RESOURCE_TYPE_DEVICE,
  RESOURCE_TYPE_DEVICE_MANAGEMENT
} from '@/Util/PermissionUtils'

import Strings from '../../Strings'
import ThresholdTableRow from './TableRow'

export default function DevicesTable({
  basePath,
  devices,
  setShowDeviceFormModal
}) {
  const dispatch = useDispatch()
  const {
    tableHeaderDeviceName,
    tableHeaderDeviceType,
    tableHeaderDeviceTag,
    tableHeaderDeviceCreated,
    tableHeaderDeviceSensorType,
    tableHeaderDeviceActions,
    tableHeaderDeviceNotInUse,
    deviceSelectDeviceType
  } = Strings()
  const deviceTypes = getDeviceTypes()

  const coretexUser = getCurrentUser()

  const [selectedOption, setSelectedOption] = useState(null)
  const [filteredDevices, setFilteredDevices] = useState([])

  const showEditOptions = hasEditPermissions(
    coretexUser,
    RESOURCE_TYPE_DEVICE,
    RESOURCE_TYPE_DEVICE_MANAGEMENT
  )

  useEffect(() => {
    dispatch(setDevice({}))
  }, [])

  useEffect(() => {
    setFilteredDevices(devices)
  }, [devices])

  useEffect(() => {
    if (!selectedOption) {
      setFilteredDevices(devices)
    }

    if (selectedOption) {
      setFilteredDevices(
        devices.filter(({ sensorType }) => sensorType === selectedOption)
      )
    }
  }, [selectedOption, devices])

  function onSelectOption(option) {
    setSelectedOption(option?.value)
  }

  return (
    <Fragment>
      <Select
        isClearable={true}
        value={selectedOption}
        placeholder={deviceSelectDeviceType}
        options={deviceTypes?.map(({ id, name }) => {
          return { value: id, label: name }
        })}
        onChange={onSelectOption}
      />

      <Table className={'Devices__DevicesTable'}>
        <Slot name='head'>
          <Row>
            <Column>{tableHeaderDeviceName}</Column>
            <Column>{tableHeaderDeviceTag}</Column>
            <Column>{tableHeaderDeviceType}</Column>
            <Column>{tableHeaderDeviceSensorType}</Column>
            <Column>{tableHeaderDeviceNotInUse}</Column>
            <Column>{tableHeaderDeviceCreated}</Column>
            {showEditOptions && <Column>{tableHeaderDeviceActions}</Column>}
          </Row>
        </Slot>
        <Slot name='body'>
          {filteredDevices?.length > 0 &&
            filteredDevices.map(filteredDevice => (
              <ThresholdTableRow
                setShowDeviceFormModal={setShowDeviceFormModal}
                key={filteredDevice.name}
                basePath={basePath}
                device={filteredDevice}
                showEditOptions={showEditOptions || false}
                columnNames={[
                  tableHeaderDeviceName,
                  tableHeaderDeviceTag,
                  tableHeaderDeviceType,
                  tableHeaderDeviceSensorType,
                  tableHeaderDeviceNotInUse,
                  tableHeaderDeviceCreated,
                  tableHeaderDeviceActions
                ]}
              />
            ))}
        </Slot>
      </Table>
    </Fragment>
  )
}

import React, { useState, useEffect } from 'react'
import { DateTime } from 'luxon'

import { Box, Slot, Cell, Column, Loader, Row, Table } from '@/primitives'

import { Pagination, LineSeparator } from '@/elements'

import {
  getUserCognitoLogs,
  getUserCognitoLogsLoading
} from '@/reducers/selectors'

import useMediaQuery from '@/hooks/useMediaQuery'

import Strings from '../../Strings'

const getDate = timestamp => {
  const dateTime = DateTime.fromMillis(timestamp)
  return dateTime.toLocaleString(DateTime.DATE_FULL)
}

const getTime = timestamp => {
  const dateTime = DateTime.fromMillis(timestamp)
  return dateTime.toLocaleString(DateTime.TIME_WITH_SECONDS)
}

export default function LogsTable({ selectedTab }) {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalPages, setTotalPages] = useState(null)

  const isAboveBreakPoint = useMediaQuery('min-width: 560px')

  const logs = getUserCognitoLogs()
  const isLoading = getUserCognitoLogsLoading()

  const strings = Strings()

  useEffect(() => {
    setPage(1)
  }, [selectedTab])

  return (
    <Box>
      <LineSeparator />
      <Loader isLoading={isLoading} text={strings.logsLoading}>
        <Table className='UsersList__Table'>
          <Slot name='head'>
            <Row>
              <Column>{strings.logDate}</Column>
              <Column>{strings.logTime}</Column>
            </Row>
          </Slot>
          <Slot name='body'>
            {logs[selectedTab]?.length > 0 &&
              logs[selectedTab]
                .slice((page - 1) * pageSize, pageSize * page)
                .map(({ timestamp }) => (
                  <Row key={timestamp}>
                    <Cell columnName={`${strings.logDate}: `}>
                      {getDate(timestamp)}
                    </Cell>
                    <Cell columnName={`${strings.logTime}: `}>
                      {getTime(timestamp)}
                    </Cell>
                  </Row>
                ))}
            {logs[selectedTab]?.length === 0 && (
              <Row>
                <Cell colSpan={2}>{strings.logsNoEntries}</Cell>
              </Row>
            )}
          </Slot>
        </Table>
      </Loader>
      {Object.keys(logs).length > 0 && logs[selectedTab]?.length > 0 && (
        <Box className='pagination-menu'>
          <Pagination
            type='simple'
            totalItems={logs[selectedTab]?.length}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            showPageCount={isAboveBreakPoint}
          />
        </Box>
      )}
    </Box>
  )
}

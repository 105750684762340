import { Fragment, useState } from 'react'
import { I18n } from 'aws-amplify'

import { getHelpTextsByLang } from '@/reducers/selectors'

import { Tooltip, Dialog } from '@/elements'
import { Icon, Text, FlexV2, Button, Slot, Box } from '@/primitives'

export default function InfoTooltip({ children, tipId }) {
  const [showTooltipSidebar, setShowTooltipSidebar] = useState(false)
  const onToggleTooltip = () => {
    setShowTooltipSidebar(!showTooltipSidebar)
  }

  const helpTexts = getHelpTextsByLang()
  const currentTip = helpTexts?.find(({ helpTextId }) => helpTextId === tipId)

  return (
    <Fragment>
      <Dialog
        variant='info'
        open={showTooltipSidebar}
        onOpenChange={onToggleTooltip}
        type='offcanvas'
      >
        <Slot name='title'>
          {currentTip?.title?.length > 0 && (
            <Text as='h3' style={{ marginBottom: '0' }}>
              {currentTip?.title}
            </Text>
          )}
        </Slot>
        <Slot name='content'>
          <FlexV2 axisGap={300} style={{ marginBottom: '1.3rem' }}>
            {currentTip.tags.map(tag => (
              <Box
                key={tag}
                style={{
                  backgroundColor: 'var(--ctx-theme-color-info-500)',
                  borderRadius: '10px',
                  padding: '0.1rem 0.8rem 0.15rem',
                  lineHeight: '1rem'
                }}
              >
                <Text size={100}>{tag}</Text>
              </Box>
            ))}
          </FlexV2>
          <Text variant='page' tone={900}>
            {currentTip?.text}
          </Text>
        </Slot>
        <Slot name='actions'>
          <Button onClick={onToggleTooltip}>{I18n.get('Close')}</Button>
        </Slot>
      </Dialog>
      <FlexV2 onClick={onToggleTooltip} axisGap={300}>
        {children}
        {!showTooltipSidebar && currentTip?.helpTextId && (
          <Tooltip tip={currentTip?.text}>
            <Icon name='info' variant='info' tone={600} size={300} />
          </Tooltip>
        )}
      </FlexV2>
    </Fragment>
  )
}

export const SET_STATE = 'SALE_SHIPMENT_SET_STATE'
export const UPDATE_INPUT = 'SALE_SHIPMENT_UPDATE_INPUT'
export const UPDATE_ITEM = 'SALE_SHIPMENT_UPDATE_ITEM'
export const REMOVE_ITEM = 'SALE_SHIPMENT_REMOVE_ITEM'

export const initialState = {
  id: '',
  shippingMethodId: '',
  dueDate: null,
  status: '',
  deliveryDate: null,
  price: '',
  discount: '',
  tax: '',
  items: []
}

export function reducer(state, action) {
  const handlers = {
    [SET_STATE]: (state, action) => {
      let newState = {
        ...initialState,
        ...action.state
      }
      return newState
    },
    [UPDATE_INPUT]: (state, action) => ({
      ...state,
      [action.name]: action.value
    }),
    [UPDATE_ITEM]: (state, action) => {
      let items = state.items
      const itemIndex = items.findIndex(item => item.id === action.item.id)
      if (itemIndex >= 0) {
        items[itemIndex] = action.item
      } else {
        items.push(action.item)
      }
      return {
        ...state,
        items
      }
    },
    [REMOVE_ITEM]: (state, action) => {
      let items = state.items
      const itemIndex = items.findIndex(item => item.id === action.item.id)
      if (itemIndex >= 0) {
        items.splice(itemIndex, 1)
      }
      return {
        ...state,
        items
      }
    }
  }

  if (handlers[action.type]) {
    return handlers[action.type](state, action)
  }

  return state
}

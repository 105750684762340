import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { makeGraphqlRequestV2 } from '../utils'

/*
 * Queries
 */

export const getControlDevicesConfig = async params => {
  try {
    const query = /* GraphQL */ `
      query getControlDevicesConfig($coreDeviceId: String!) {
        getControlDevicesConfig(coreDeviceId: $coreDeviceId) {
          coreDeviceId
          controlDeviceId
          address
          slaveAddress
          baudrate
          relayConfiguration {
            relayId
            registerNumber
          }
          specifications {
            controlDeviceId
            relayId
            sensorId
            measurementId
            conditions {
              minValue
              maxValue
            }
          }
          createdAt
          updatedAt
        }
      }
    `

    const operation = graphqlOperation(query, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)
    return data?.getControlDevicesConfig
  } catch (err) {
    throw err
  }
}

/*
 * Mutations
 */

export const configureControlDeviceConfig = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation configureControlDeviceConfig(
        $coreDeviceId: String!
        $inputs: [ControlDeviceConfigInput]
      ) {
        configureControlDeviceConfig(
          coreDeviceId: $coreDeviceId
          inputs: $inputs
        ) {
          coreDeviceId
          controlDeviceId
          address
          slaveAddress
          baudrate
          relayConfiguration {
            relayId
            registerNumber
          }
          specifications {
            controlDeviceId
            relayId
            sensorId
            measurementId
            conditions {
              minValue
              maxValue
            }
          }
          createdAt
          updatedAt
        }
      }
    `

    const operation = graphqlOperation(mutation, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)
    return data?.configureControlDeviceConfig
  } catch (err) {
    throw err
  }
}

export const updateControlDeviceConfig = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateControlDeviceConfig(
        $coreDeviceId: String!
        $inputs: [ControlDeviceConfigInput]
      ) {
        updateControlDeviceConfig(
          coreDeviceId: $coreDeviceId
          inputs: $inputs
        ) {
          coreDeviceId
          controlDeviceId
          address
          slaveAddress
          baudrate
          relayConfiguration {
            relayId
            registerNumber
          }
          specifications {
            controlDeviceId
            relayId
            sensorId
            measurementId
            conditions {
              minValue
              maxValue
            }
          }
          createdAt
          updatedAt
        }
      }
    `

    const operation = graphqlOperation(mutation, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)
    return data?.updateControlDeviceConfig
  } catch (err) {
    throw err
  }
}

export const deleteControlDeviceConfig = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation deleteControlDeviceConfig(
        $coreDeviceId: String!
        $controlDeviceId: String!
      ) {
        deleteControlDeviceConfig(
          coreDeviceId: $coreDeviceId
          controlDeviceId: $controlDeviceId
        ) {
          coreDeviceId
          controlDeviceId
          address
          slaveAddress
          baudrate
          relayConfiguration {
            relayId
            registerNumber
          }
          createdAt
          updatedAt
        }
      }
    `

    const operation = graphqlOperation(mutation, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)
    return data?.deleteControlDeviceConfig
  } catch (err) {
    throw err
  }
}

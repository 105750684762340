import { I18n } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { useDispatch } from 'react-redux'

import { Button, FlexV2, Loader, Separator, Text } from '@/primitives'
import { Select } from '@/elements'

import {
  getMeasurements,
  getSensorThresholdStatusesLoading,
  getSensorThresholdUserAckLoading,
  getCurrentUser,
  getSensorThresholdUserSnoozes,
  getSensorThresholdUserSnoozeCreateError,
  getSensorThresholdUserSnoozeLoading
} from '@/reducers/selectors'
import {
  sendCreateSensorThresholdUserSnooze,
  sendDeleteSensorThresholdUserSnooze
} from '@/slices/threshold/sensorThresholdUserSnooze'
import MeasurementStrings from '@/Util/Strings/measurements'
import { getMeasurementsName } from '@/components/DataboardPageV2/Utils/DateUtils'
import { reactSelectStyles } from '@/components/DashboardPageV2/utils'

import { getSnoozeDurationOptions, getSnoozeTime } from '../utils'

import style from '../Card.module.scss'
import ThreholdSensor from './ThresholdSensor'

const getSensorOptions = (
  sensors,
  selectedSensors,
  snoozedItems,
  sensorsMap
) => {
  let options = sensors.map(sensor => ({
    label: sensor.sensorName,
    value: sensor.sensorId
  }))

  if (selectedSensors.length > 0) {
    const selectedSensor = selectedSensors[0]
    const status = sensorsMap[selectedSensor]
    const key = `${status.sensorId}-${status.measurementId}`
    const isSnoozed = snoozedItems?.[key]?.timeInMin > 0

    const arr = options.filter(option => {
      const status = sensorsMap[option.value]
      const key = `${status.sensorId}-${status.measurementId}`

      if (isSnoozed) {
        return snoozedItems?.[key]?.timeInMin > 0
      }

      return !snoozedItems?.[key]?.timeInMin > 0
    })
    return arr
  } else {
    return options
  }
}

export default function MeasurementsCard({ item, measurementId }) {
  const measurementStrings = MeasurementStrings()
  const measurements = getMeasurements()

  const dispatch = useDispatch()

  const [selectedSensors, setSelectedSensors] = useState([])
  const [selectedDuration, setSelectedDuration] = useState(0)
  const [snoozedItems, setSnoozedItems] = useState({})

  const notificationSnoozes = getSensorThresholdUserSnoozes()
  const createSnoozeError = getSensorThresholdUserSnoozeCreateError()

  const isLoadingSnooze = getSensorThresholdUserSnoozeLoading()
  const isLoadingAck = getSensorThresholdUserAckLoading()
  const isLoadingStatuses = getSensorThresholdStatusesLoading()

  const isLoading = isLoadingSnooze || isLoadingAck || isLoadingStatuses

  const currentUser = getCurrentUser()
  const someSelectedSensorsSnoozed = selectedSensors.some(sensorId => {
    const key = `${sensorId}-${measurementId}`
    return snoozedItems?.[key]?.timeInMin > 0
  })

  const isManager = Object.values(item).some(sensor =>
    currentUser?.managers.some(manager =>
      sensor?.thresholdZonePath?.includes(manager.zoneId)
    )
  )

  let sensors = Object.values(item)
  const measurementName =
    measurementStrings[measurementId] ??
    getMeasurementsName(measurements, measurementId)

  const snoozeDurationOptions = getSnoozeDurationOptions()
  const sensorOptions = getSensorOptions(
    sensors,
    selectedSensors,
    snoozedItems,
    item
  )

  sensors.sort((a, b) => {
    if (a.sensorName < b.sensorName) {
      return -1
    }
    if (a.sensorName > b.sensorName) {
      return 1
    }
    return 0
  })

  useEffect(() => {
    if (isManager) {
      const nextSnoozedItems = notificationSnoozes.reduce((acc, snooze) => {
        const { sensorId, expiresAt, measurementId } = snooze
        if (expiresAt) {
          const timeInMin = getSnoozeTime(expiresAt)
          const key = `${sensorId}-${measurementId}`
          acc[key] = { timeInMin, expiresAt }
        }
        return acc
      }, {})

      setSnoozedItems(nextSnoozedItems)
    }
  }, [isManager, notificationSnoozes, measurementId])

  useEffect(() => {
    const hasSnoozeError = createSnoozeError?.hasOwnProperty('errorMsg')

    if (hasSnoozeError && createSnoozeError?.snooze) {
      const { sensorId, measurementId } = createSnoozeError.snooze
      const key = `${sensorId}-${measurementId}`

      const nextSnoozedItems = { ...snoozedItems }
      delete nextSnoozedItems[key]
      setSnoozedItems(nextSnoozedItems)
    }
  }, [createSnoozeError?.errorMsg, createSnoozeError, measurementId])

  const onHandleSensorChange = selected => {
    let arr = []
    let isAllSelected = selected.some(item => item.value === 'all')

    if (selected && !isAllSelected) {
      arr = selected.map(item => item.value)
    }
    if (isAllSelected) {
      arr = sensorOptions.map(item => item.value).filter(item => item !== 'all')
    }
    setSelectedSensors(arr)
  }

  const onHandleDurationChange = selected => {
    setSelectedDuration(selected.index)
  }

  const onToggleSnooze = e => {
    e.preventDefault()
    const snoozeDetails = snoozeDurationOptions[selectedDuration]
    const userId = currentUser?.userName

    const isCreate = !someSelectedSensorsSnoozed

    for (let selectedSensorId of selectedSensors) {
      const { sensorId, zoneId, rootId } = item[selectedSensorId]
      if (isCreate) {
        const { value, timeInMin } = snoozeDetails
        const currentTime = DateTime.now().toSeconds()
        const expiresAt = Math.round(currentTime) + value
        const key = `${sensorId}-${measurementId}`
        setSnoozedItems(state => ({
          ...state,
          [key]: { timeInMin, expiresAt }
        }))
        dispatch(
          sendCreateSensorThresholdUserSnooze({
            userId,
            sensorId,
            measurementId,
            zoneId,
            rootId,
            expiresAt
          })
        )
      } else {
        dispatch(
          sendDeleteSensorThresholdUserSnooze({
            userId,
            sensorId,
            measurementId,
            zoneId,
            rootId
          })
        )
      }
    }
  }

  const hasSelectedSensors = selectedSensors.length > 0

  return (
    <FlexV2 direction='column' axisGap='300' className={style.card}>
      <FlexV2 direction='column' axisGap='100'>
        <Text>
          {measurementName} {I18n.get('threshold is')}{' '}
        </Text>
        <FlexV2
          direction='column'
          axisGap='100'
          className={style.listWithIcons}
        >
          {sensors.map((item, index) => {
            const snoozeKey = `${item.sensorId}-${measurementId}`
            return (
              <ThreholdSensor
                item={item}
                key={index}
                snooze={snoozedItems?.[snoozeKey] ?? null}
                isLoading={isLoading}
              />
            )
          })}
        </FlexV2>
      </FlexV2>

      {isManager && (
        <Loader isLoading={isLoading}>
          <Separator />
          <FlexV2 direction='column' axisGap='200'>
            <Text variant='page' tone={800}>
              {I18n.get('Snooze Alert')}
            </Text>
            <Select
              value={selectedSensors}
              onChange={onHandleSensorChange}
              options={sensorOptions}
              placeholder={I18n.get('Select Sensor')}
              controlStyles={reactSelectStyles}
              menuListStyles={reactSelectStyles}
              isMulti={true}
              valueContainerStyles={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                height: '100%',
                input: {
                  height: 0
                }
              }}
              formatOptionLabel={({ value }) => {
                const status = item[value]
                const snoozedItem = snoozedItems?.[`${value}-${measurementId}`]

                return (
                  <ThreholdSensor
                    item={status}
                    isLoading={isLoading}
                    snooze={snoozedItem}
                    useLink={false}
                    useMessage={false}
                  />
                )
              }}
            />
            {hasSelectedSensors && (
              <Select
                value={snoozeDurationOptions[selectedDuration].value}
                onChange={onHandleDurationChange}
                options={snoozeDurationOptions}
                controlStyles={reactSelectStyles}
                menuListStyles={reactSelectStyles}
              />
            )}
            {hasSelectedSensors && (
              <Button
                disabled={false}
                onClick={onToggleSnooze}
                size='small'
                variant={someSelectedSensorsSnoozed ? 'info' : 'primary'}
                iconBefore={someSelectedSensorsSnoozed ? 'close' : 'snooze'}
              >
                {I18n.get(someSelectedSensorsSnoozed ? 'Cancel' : 'Snooze')}{' '}
                {item[selectedSensors]?.sensorName}
              </Button>
            )}
          </FlexV2>
        </Loader>
      )}
    </FlexV2>
  )
}

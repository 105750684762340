import { makeOperationsApiCall } from './utils'

/* MUTATIONS */

export const createProduce = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createProduce(
        $organizationId: String!
        $name: String!
        $code: String!
        $produceThresholdId: String
      ) {
        createProduce(
          organizationId: $organizationId
          name: $name
          code: $code
          produceThresholdId: $produceThresholdId
        ) {
          id
          organizationId
          name
          code
          produceThresholdId
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'createProduce',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updateProduce = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateProduce(
        $produceId: String!
        $organizationId: String!
        $name: String!
        $code: String!
        $produceThresholdId: String
      ) {
        updateProduce(
          produceId: $produceId
          organizationId: $organizationId
          name: $name
          code: $code
          produceThresholdId: $produceThresholdId
        ) {
          id
          organizationId
          name
          code
          produceThresholdId
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'updateProduce',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

/* QUERIES */

export const getProduces = async params => {
  try {
    const query = /* GraphQL */ `
      query getProduces(
        $filter: GetProducesFilter
        $limit: Int
        $offset: Int
        $order: OrderEnum
      ) {
        getProduces(
          filter: $filter
          limit: $limit
          offset: $offset
          order: $order
        ) {
          produces {
            id
            organizationId
            name
            code
            produceThresholdId
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getProduces',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getProduce = async params => {
  try {
    const query = /* GraphQL */ `
      query getProduce($produceId: String!) {
        getProduce(produceId: $produceId) {
          id
          organizationId
          name
          code
          produceThresholdId
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getProduce',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

import { Link } from 'react-router-dom'
import { Grid, Item } from '@/primitives'
import ZoneCard from './Card'

import './index.scss'
import { useDispatch } from 'react-redux'
import { cleanZonesList, setZone } from '@/slices/management/zone'

const ZoneCards = ({ zones }) => {
  const dispatch = useDispatch()

  const onClick = zone => {
    dispatch(setZone(zone))
    dispatch(cleanZonesList())
  }

  return (
    <Grid gap={400} className={'ZoneCards'}>
      {zones.map(zone => (
        <Item key={`zonelist__${zone.id}`}>
          <Link
            to={`/admin/zones${zone.parentPath}/details`}
            className={'ZoneCards__CardLink'}
            onClick={() => onClick(zone)}
          >
            <ZoneCard zone={zone} />
          </Link>
        </Item>
      ))}
    </Grid>
  )
}

export default ZoneCards

import { I18n } from 'aws-amplify'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {
  getDirectConnectDevicesStatus,
  getGreengrassDevicesStatus,
  getPingStatuses
} from '@/api/pinger/queries'

export const startPingDeviceQueryDirectConnect = createAsyncThunk(
  'startPingDeviceQueryDirectConnect',
  async params => {
    const response = await getDirectConnectDevicesStatus(params)
    return response
  }
)

export const startPingDeviceQueryGreengrass = createAsyncThunk(
  'startPingDeviceQueryGreengrass',
  async params => {
    const response = await getGreengrassDevicesStatus(params)
    return response
  }
)

export const fetchPingStatuses = createAsyncThunk(
  'fetchPingStatuses',
  async params => {
    const response = await getPingStatuses(params)
    return response
  }
)

function updateDevicesStatus(deviceStatuses, devices) {
  if (!deviceStatuses || deviceStatuses?.length === 0) return null
  const copyDevices = [...devices]
  let valuesHaveChanged = false

  const updatedDevices = deviceStatuses?.map(deviceStatus => {
    const { deviceId, status, updatedAt } = deviceStatus
    const matchedDeviceIndex = copyDevices.findIndex(
      device => device.id === deviceId
    )

    if (matchedDeviceIndex !== -1) {
      const matchedDevice = copyDevices[matchedDeviceIndex]
      copyDevices.splice(matchedDeviceIndex, 1)
      if (
        status === matchedDevice.status &&
        updatedAt === matchedDevice.updatedAt
      ) {
        return deviceStatus
      }

      if (valuesHaveChanged === false) valuesHaveChanged = true
      return {
        ...matchedDevice,
        status,
        updatedAt
      }
    }

    if (valuesHaveChanged === false) valuesHaveChanged = true
    return {
      id: deviceId,
      status,
      updatedAt
    }
  })

  return valuesHaveChanged ? [...copyDevices, ...updatedDevices] : null
}

const pingerSlice = createSlice({
  name: 'pingerReducer',
  initialState: {
    devices: [],
    successMsg: null,
    error: null,
    loading: false
  },
  reducers: {
    updateDeviceStatus: (state, action) => {
      const updatedDeviceStatuses = updateDevicesStatus(
        action.payload,
        state.devices
      )
      if (updatedDeviceStatuses) state.devices = updatedDeviceStatuses
    },
    clearFeedback: state => {
      state.error = null
      state.successMsg = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(startPingDeviceQueryDirectConnect.pending, state => {
        state.error = null
        state.successMsg = null
        state.loading = true
      })
      .addCase(startPingDeviceQueryDirectConnect.fulfilled, (state, action) => {
        const updatedDeviceStatuses = updateDevicesStatus(
          action.payload.devices,
          state.devices
        )
        if (updatedDeviceStatuses) state.devices = updatedDeviceStatuses
        state.error = action?.payload?.error ?? null
        state.successMsg = action?.payload?.error
          ? null
          : I18n.get('Device statuses fetched')
        state.loading = false
      })
      .addCase(startPingDeviceQueryGreengrass.pending, state => {
        state.error = null
        state.successMsg = null
        state.loading = true
      })
      .addCase(startPingDeviceQueryGreengrass.fulfilled, (state, action) => {
        const updatedDeviceStatuses = updateDevicesStatus(
          action.payload.devices,
          state.devices
        )
        if (updatedDeviceStatuses) state.devices = updatedDeviceStatuses
        state.error = action?.payload?.error ?? null
        state.successMsg = action?.payload?.error
          ? null
          : I18n.get('Device statuses fetched')
        state.loading = false
      })
      .addCase(fetchPingStatuses.pending, state => {
        state.error = null
        state.loading = true
      })
      .addCase(fetchPingStatuses.fulfilled, (state, action) => {
        const updatedDeviceStatuses = updateDevicesStatus(
          action.payload.deviceStatuses,
          state.devices
        )
        if (updatedDeviceStatuses) state.devices = updatedDeviceStatuses
        state.error = action?.payload?.error ?? null
        state.loading = false
      })
  }
})

const { actions, reducer } = pingerSlice

export const { updateDeviceStatus, clearFeedback } = actions

export default reducer

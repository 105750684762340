import { useEffect, useState } from 'react'
import { I18n } from 'aws-amplify'
import { useDispatch } from 'react-redux'

import { Select } from '@/primitives'

import { requestAllVarieties } from '@/actions/operations/variety'

import { getOperationsAllVarieties } from '@/reducers/selectors'

const VarietiesFilter = ({ state, dispatchState }) => {
  const dispatch = useDispatch()

  const varieties = getOperationsAllVarieties()

  const [varietyFilter, setVarietyFilter] = useState(
    state.varieties.length === 1 ? state.varieties[0] : 'all'
  )
  const [lastFilter, setLastFilter] = useState(varietyFilter)

  useEffect(() => {
    dispatch(requestAllVarieties())
  }, [dispatch])

  useEffect(() => {
    if (varietyFilter === 'all') {
      if (state.varieties.length === 0 && varieties.length > 0) {
        dispatchChange()
      } else if (state.varieties.length === 1 && varieties.length > 0) {
        setLastFilter(state.varieties[0])
        setVarietyFilter(state.varieties[0])
      }
    } else if (state.varieties.length === 0) {
      setVarietyFilter('all')
    }
  }, [state.varieties, varieties])

  useEffect(() => {
    if (varieties.length > 0) {
      if (varietyFilter !== lastFilter) {
        setLastFilter(varietyFilter)
        dispatchChange()
      }
    }
  }, [varietyFilter, varieties])

  const getVarietyFilter = () => {
    return varietyFilter === 'all' ? varieties.map(i => i.id) : [varietyFilter]
  }

  const dispatchChange = () => {
    dispatchState({
      varieties: getVarietyFilter()
    })
  }

  const onChangeFilter = e => {
    setVarietyFilter(e.currentTarget.value)
  }

  const filterVarieties = () => {
    if (state.produces.length === 1) {
      return varieties.reduce((validVarieties, variety) => {
        for (let produce of state.produces) {
          if (variety.produce.id === produce) {
            validVarieties.push(variety)
            return validVarieties
          }
        }
        return validVarieties
      }, [])
    }
    return []
  }

  const options = filterVarieties()

  return (
    <Select
      className='Operations__Header__Select'
      name='varietyId'
      value={varietyFilter}
      onChange={onChangeFilter}
      disabled={options.length === 0}
    >
      <option default value='all'>
        {I18n.get('All Varieties')}
      </option>
      {options.map(({ name, id }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </Select>
  )
}

export default VarietiesFilter

import { DateTime } from 'luxon'

import {
  COMPARISON_TYPES,
  LINE_GRAPH_THEME,
  INTERVAL_PRECISION,
  INTERVAL_TICKS,
  INTERVAL_WEIGHT,
  RANGE_WEIGHTS,
  INTERVAL_STEPS_CHART_LABEL
} from './config'

import { CONVERSION_TEMPERATURE_MEASUREMENTS } from '@/Util/MeasurementUtils'
import { schemeDark2 } from '@/Util/SchemeUtils'

const getLargestWeight = (items, weights) => {
  const sortedKeys = items.sort((a, b) => weights[b] - weights[a])
  return sortedKeys[0]
}

export function getChartDateParams(state) {
  if (state.comparisonType === COMPARISON_TYPES.DATE_RANGES) {
    let minFromDate = null
    let maxToDate = null
    let timeIntervals = []
    let dateRanges = []
    let timeZone = null

    for (let dateFilter of state.dateFilters) {
      const {
        fromDate,
        toDate,
        timeInterval,
        dateRange,
        timeZone: dateTimeZone
      } = dateFilter

      if (!minFromDate || fromDate < minFromDate) {
        minFromDate = fromDate
      }
      if (!maxToDate || toDate > maxToDate) {
        maxToDate = toDate
      }
      if (timeInterval) {
        timeIntervals.push(timeInterval)
      }
      if (dateRange) {
        dateRanges.push(dateRange)
      }
      if (!timeZone && dateTimeZone) {
        timeZone = dateTimeZone
      }
    }

    return {
      fromDate: minFromDate, // Return the smallest fromDate
      toDate: maxToDate, // Return the largest toDate
      timeInterval: getLargestWeight(timeIntervals, INTERVAL_WEIGHT),
      dateRange: getLargestWeight(dateRanges, RANGE_WEIGHTS),
      timeZone
    }
  }

  const dateSidebarFilter = state.sidebarFilter[COMPARISON_TYPES.DATE_RANGES]

  return {
    fromDate: dateSidebarFilter.fromDate,
    toDate: dateSidebarFilter.toDate,
    timeInterval: dateSidebarFilter.timeInterval,
    dateRange: dateSidebarFilter.dateRange,
    timeZone: dateSidebarFilter.timeZone
  }
}

export const getPrecision = interval => {
  return INTERVAL_PRECISION[interval] ?? interval
}

export const getTicks = (
  interval,
  rangeFormat,
  isMobile = false,
  isBelowBreakpoint = false
) => {
  const ticks = INTERVAL_TICKS[interval]
  if (isMobile && rangeFormat === 'MM/dd') {
    return Math.floor(ticks / 3)
  }

  if (isMobile) {
    return Math.floor(ticks / 2)
  }

  if (isBelowBreakpoint && rangeFormat === 'MM/dd') {
    return Math.floor(ticks / 1.5)
  }

  return ticks
}

export const getColor = series => series.color

export const formatDate = (date, timezone, format) =>
  DateTime.fromMillis(date.getTime(), { zone: timezone }).toFormat(format)

export const calculateSpacing = (data, comparisonType) => {
  if (comparisonType === COMPARISON_TYPES.DATE_RANGES) {
    return data[0]?.id?.length * 5
  }
  const sortedData = data.sort((a, b) => b?.id?.length - a?.id?.length)
  return sortedData[0]?.id?.length * 4
}

export function getChartProps({
  id = 'DataboardPageChart',
  colors,
  data,
  precision,
  tickValues,
  formatFunction,
  legend,
  splitLegendsTwoRows,
  sliceTooltip,
  itemsSpacing,
  xScaleType = 'time'
}) {
  const legendsProps = {
    anchor: 'bottom',
    direction: 'row',
    justify: false,
    translateX: 0,
    translateY: 55,
    itemWidth: 100,
    itemHeight: 20,
    itemsSpacing,
    symbolSize: 12,
    symbolShape: 'circle',
    itemDirection: 'left-to-right',
    effects: [
      {
        on: 'hover',
        style: {
          itemBackground: 'rgba(0, 0, 0, .03)',
          itemOpacity: 1
        }
      }
    ]
  }

  let legends = [legendsProps]

  if (splitLegendsTwoRows) {
    let legendRowOne = {
      ...legendsProps,
      translateY: legendsProps.translateY,
      translateX: -100,
      symbolSize: 14,
      itemHeight: 25,
      data: data.slice(0, Math.ceil(data.length / 2)).map((item, idx) => ({
        id: item.id,
        label: item.id,
        color: schemeDark2.slice(0, Math.ceil(data.length / 2))[idx]
      }))
    }
    let legendRowTwo = {
      ...legendsProps,
      translateY: legendsProps.translateY + 25,
      translateX: -100,
      symbolSize: 14,
      itemHeight: 25,
      data: data.slice(Math.ceil(data.length / 2)).map((item, idx) => ({
        id: item.id,
        label: item.id,
        color: schemeDark2.slice(Math.ceil(data.length / 2))[idx]
      }))
    }

    legends = [legendRowOne, legendRowTwo]
  }

  return {
    id,
    animate: false,
    pointLabelYOffset: 0,
    margin: {
      top: 40,
      right: 20,
      bottom: splitLegendsTwoRows ? 90 : 60,
      left: 60
    },
    style: { textColor: 'var(--ctx-theme-contrast-page-200)' },
    theme: LINE_GRAPH_THEME,
    colors,
    data,
    xScale: {
      type: xScaleType,
      precision,
      min: 'auto',
      max: 'auto'
    },
    yScale: {
      type: 'linear',
      min: 'auto',
      max: 'auto',
      stacked: false,
      reverse: false
    },
    yFormat: ' >-.2f',
    axisTop: null,
    axisRight: null,
    axisBottom: {
      tickValues,
      format: formatFunction
    },
    axisLeft: {
      tickValues: 6,
      legend: legend ?? '',
      legendOffset: -57,
      legendPosition: 'middle'
    },
    enableSlices: 'x',
    sliceTooltip: sliceTooltip ?? null,
    useMesh: true,
    enableGridX: false,
    enablePoints: true,
    pointSize: 3,
    pointColor: { theme: 'background' },
    pointBorderWidth: 3,
    pointBorderColor: { from: 'serieColor' },
    enableArea: false,
    curve: 'monotoneX',
    legends
  }
}

export function getMeasurementUnit(measurement, enableFahrenheit) {
  const isChangeUnit = CONVERSION_TEMPERATURE_MEASUREMENTS.includes(
    measurement.id
  )
  if (isChangeUnit && enableFahrenheit) {
    return '°F'
  }

  return measurement.unit
}

export function formatXLabel(value, state, rangeFormat, timeZone) {
  if (state.comparisonType === COMPARISON_TYPES.DATE_RANGES) {
    const labels = INTERVAL_STEPS_CHART_LABEL()
    const selectedInterval = state.dateFilters[0]?.timeInterval
    return `${labels[selectedInterval]} ${value}`
  }
  return formatDate(value, timeZone, rangeFormat)
}

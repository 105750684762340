import { Button, Cell, Row } from '../../../../../primitives'

import { CUSTOMER_STATUSES, CUSTOMER_CATEGORIES } from '../utils'

import history from '../../../../../history'
import Strings from '../Strings'

function TableRow({ item, onEdit, canEdit, dispatchState, modulePath }) {
  const strings = Strings()

  const customerCategories = CUSTOMER_CATEGORIES(strings)
  const customerStatuses = CUSTOMER_STATUSES(strings)

  const {
    id,
    organizationId,
    name,
    code,
    category,
    status,
    contactName,
    contactEmail,
    country,
    city,
    region
  } = item

  const handleEdit = () => {
    onEdit(id)
  }

  const goToChild = childPath => {
    dispatchState({
      state: {
        organizationId: organizationId,
        customerId: id
      }
    })
    history.push(`${modulePath}/${childPath}`)
  }

  const onBranches = () => {
    goToChild('branches')
  }

  const onUnitPrices = () => {
    goToChild('unit-prices')
  }

  return (
    <Row>
      <Cell columnName={strings.company}>
        {name}
        <span>{code}</span>
      </Cell>
      <Cell columnName={strings.contact}>
        {contactName}
        <span>{contactEmail}</span>
      </Cell>
      <Cell columnName={strings.location}>
        {city.name}
        <span>
          {region.name} - {country.name}
        </span>
      </Cell>
      <Cell columnName={strings.status}>
        {customerStatuses[status]}
        <span>{customerCategories[category]}</span>
      </Cell>
      <Cell className='Operations__Table__Column__Actions'>
        {canEdit && (
          <Button
            variant='info'
            size='small'
            iconBefore='edit'
            onClick={handleEdit}
          />
        )}
        <Button
          variant='info'
          size='small'
          iconBefore='store'
          onClick={onBranches}
        />
        <Button
          variant='info'
          size='small'
          iconBefore='point_of_sale'
          onClick={onUnitPrices}
        />
      </Cell>
    </Row>
  )
}

export default TableRow

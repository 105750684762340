import { Fragment, useEffect, useMemo } from 'react'
import { I18n } from 'aws-amplify'

import {
  AVAILABLE_FEATURE_FLAGS,
  ENABLE_DIAGNOSTICS,
  hasFeatureFlagEnabled
} from '@/Util/FeatureFlagsUtils'

import { Text, FlexV2, Button } from '@/primitives'
import Select from '@/elements/Select'
import Strings from '@/components/AdminPage/Strings'
import {
  getZone,
  getZoneHierarchy,
  getCurrentUser,
  getDeviceTypes,
  getMeasurements,
  getSensorSimDiagnosticLoading
} from '@/reducers/selectors'
import { getIsGodMode } from '@/Util/PermissionUtils'
import ZoneUtils from '@/Util/ZoneUtils'

import { DEFAULT_MODBUS_CONFIG } from '../../config'
import { getHardwareVersions } from '../../utils'

import { sanitizePositiveIntegerInput } from '../utils'
import {
  SET_DEVICE_TYPE_MEASUREMENTS,
  SET_SHOW_MODBUS_CONFIG_EDITOR_VIEW,
  SET_SHOW_SIM_CONFIG_VIEW,
  UPDATE_CONNECTION_FIELDS,
  UPDATE_DEVICE_FIELD
} from '../state'
import FormInput from '../FormInput'

import {
  getConnectionMethod,
  getConnectionOptions,
  getConnectionType,
  getDeviceTypeMeasurements,
  getFilteredDeviceTypes
} from './utils'

export default function DeviceConfig({ state, stateDispatch, errors }) {
  const strings = Strings()
  const zoneHierarchy = getZoneHierarchy()
  const zone = getZone()
  const currentUser = getCurrentUser()
  const isGodMode = getIsGodMode(currentUser)
  const deviceTypes = getDeviceTypes()
  const measurements = getMeasurements()
  const connectionMethod = getConnectionMethod(state.device)
  const connectionType = getConnectionType(state.device)
  const sensorSimLoading = getSensorSimDiagnosticLoading()

  const enableDiagnostics = hasFeatureFlagEnabled(
    currentUser,
    AVAILABLE_FEATURE_FLAGS[ENABLE_DIAGNOSTICS],
    zone?.organizationId ?? null
  )

  const formDeviceTypes = useMemo(() => {
    return getFilteredDeviceTypes(deviceTypes, connectionMethod, connectionType)
  }, [deviceTypes, connectionMethod, connectionType, state.device.sensorType])

  const { connectionTypeOptions, connectionMethodOptions } = useMemo(() => {
    const hasCoreDevice = ZoneUtils.hasCoreDeviceAbove(zoneHierarchy, zone)
    return getConnectionOptions(hasCoreDevice, zone)
  }, [zoneHierarchy, zone])

  const hardwareVersionsOptions = useMemo(() => {
    return getHardwareVersions(state.device.sensorType, formDeviceTypes) ?? []
  }, [state.type, formDeviceTypes])

  useEffect(() => {
    stateDispatch({
      type: SET_DEVICE_TYPE_MEASUREMENTS,
      payload: getDeviceTypeMeasurements(
        measurements,
        deviceTypes,
        state.device.sensorType
      )
    })
  }, [deviceTypes, measurements, state.device.sensorType])

  function onChangeConnectionMethod({ value }) {
    if (value === 'direct') {
      stateDispatch({
        type: UPDATE_CONNECTION_FIELDS,
        payload: {
          isIoT: true,
          isGreengrass: false
        }
      })
    } else if (value === 'greengrass') {
      stateDispatch({
        type: UPDATE_CONNECTION_FIELDS,
        payload: {
          isIoT: true,
          isGreengrass: true
        }
      })
    }
  }

  function onChangeConnectionType({ value }) {
    if (value === 'modbus') {
      stateDispatch({
        type: UPDATE_CONNECTION_FIELDS,
        payload: {
          isGreengrass: true,
          isIoT: false,
          config: DEFAULT_MODBUS_CONFIG
        }
      })
    } else if (value === 'iot') {
      stateDispatch({
        type: UPDATE_CONNECTION_FIELDS,
        payload: {
          isGreengrass: true,
          isIoT: true
        }
      })
    }
  }

  function onChangeType({ value }) {
    stateDispatch({
      type: UPDATE_DEVICE_FIELD,
      payload: {
        field: 'sensorType',
        value
      }
    })
  }

  function onChangeHardwareVersion({ value }) {
    stateDispatch({
      type: UPDATE_DEVICE_FIELD,
      payload: {
        field: 'hardwareVersion',
        value
      }
    })
  }

  function onChangeSleepCycleMinutes(e) {
    const value = sanitizePositiveIntegerInput(e.target.value)

    if (value == null) {
      return
    }

    stateDispatch({
      type: UPDATE_DEVICE_FIELD,
      payload: {
        field: 'sleepCycleMinutes',
        value
      }
    })
  }

  function onShowModbusConfigEditorModal() {
    stateDispatch({
      type: SET_SHOW_MODBUS_CONFIG_EDITOR_VIEW,
      payload: true
    })
  }

  function onShowSimConfigModal() {
    stateDispatch({
      type: SET_SHOW_SIM_CONFIG_VIEW,
      payload: true
    })
  }

  const CAN_USE_SIM_DETAILS =
    enableDiagnostics &&
    state.device?.id &&
    state.device?.sensorType?.includes('cellular')

  return (
    <Fragment>
      <FlexV2 axisGap={100}>
        <FlexV2 style={{ flex: 1 }} direction='column' axisGap={300}>
          <Text variant='page' tone={900} size={100}>
            {strings.deviceFormLabelConnectionMethod}
          </Text>

          <Select
            value={connectionMethod}
            placeholder={strings.deviceFormTypeSelectPlaceholder}
            options={connectionMethodOptions}
            isDisabled={state.device.id}
            onChange={onChangeConnectionMethod}
          />
        </FlexV2>

        {connectionMethod === 'greengrass' && (
          <FlexV2 style={{ flex: 1 }} direction='column' axisGap={500}>
            <FlexV2 direction='column' axisGap={300}>
              <Text variant='page' tone={900} size={100}>
                {strings.deviceFormLabelConnectionType}
              </Text>
              <Select
                value={connectionType}
                placeholder={strings.deviceFormTypeSelectPlaceholder}
                options={connectionTypeOptions}
                isDisabled={state.device.id}
                onChange={onChangeConnectionType}
              />
            </FlexV2>
          </FlexV2>
        )}
      </FlexV2>
      <FlexV2 axisGap={300}>
        <FlexV2 style={{ flex: 1 }} direction='column' axisGap={300}>
          <Text variant='page' tone={900} size={100}>
            {strings.deviceFormLabelDeviceType}
          </Text>
          <Select
            value={state.device?.sensorType}
            placeholder={strings.deviceFormTypeSelectPlaceholder}
            options={formDeviceTypes?.map(({ id, name }) => ({
              value: id,
              label: name
            }))}
            onChange={onChangeType}
            isDisabled={state.device.id && !isGodMode}
          />
          <Text
            className='FormInput__Error'
            variant='error'
            size={100}
            tone={500}
            style={{ height: '1.5rem' }}
            as='p'
          >
            {errors?.type || ''}
          </Text>
        </FlexV2>

        {hardwareVersionsOptions?.length > 0 && (
          <FlexV2 style={{ flex: 1 }} direction='column' axisGap={300}>
            <Text variant='page' tone={900} size={100}>
              {strings.deviceFormLabelDeviceVersion}
            </Text>
            <Select
              value={state.device.hardwareVersion}
              placeholder={strings.deviceFormVersionSelectPlaceholder}
              options={hardwareVersionsOptions}
              onChange={onChangeHardwareVersion}
            />
          </FlexV2>
        )}
        {CAN_USE_SIM_DETAILS && (
          <FormInput
            label={I18n.get('Sleep Cycle (minutes)')}
            name='sleepCycleMinutes'
            value={state.device.sleepCycleMinutes}
            onChange={onChangeSleepCycleMinutes}
            error={errors?.sleepCycleMinutes}
          />
        )}
      </FlexV2>
      {connectionMethod === 'greengrass' && connectionType === 'modbus' && (
        <Button
          onClick={onShowModbusConfigEditorModal}
          disabled={!state.device.sensorType}
        >
          {I18n.get('Edit Modbus Configuration')}
        </Button>
      )}

      {CAN_USE_SIM_DETAILS && (
        <Button
          loading={sensorSimLoading}
          onClick={onShowSimConfigModal}
          disabled={sensorSimLoading}
        >
          {I18n.get('Configure SIM')}
        </Button>
      )}
    </Fragment>
  )
}

import { I18n } from 'aws-amplify'

const Strings = () => ({
  noItems: I18n.get('No Items'),
  saleItem: I18n.get('Sale Item'),
  quantity: I18n.get('Quantity'),
  selectDefault: I18n.get('Please select'),
  grams: I18n.get('g'),
  product: I18n.get('Product')
})

export default Strings

import { I18n } from 'aws-amplify'
import { useEffect, useState, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { object, string } from 'yup'

import { FlexV2, Form, Input, Label, Text, Loader, Select } from '@/primitives'

import InputError from '@/elements/InputError'

import HeaderV2 from '../../Shared/HeaderV2'
import OrganizationInput from '../../Shared/Organization/Input'

import {
  requestPackageUnit,
  createPackageUnit,
  updatePackageUnit
} from '@/actions/operations/packageUnit'

import {
  getOperationsPackageUnit,
  getOperationsLoading,
  getOperationsError
} from '@/reducers/selectors'

import history from '../../../../history'

import { getValidationErrorMap } from '@/Util/GeneralUtils'

import useMediaQuery from '@/hooks/useMediaQuery'

import Strings from './Strings'

import { PACKAGE_UNIT_CATEGORIES, PACKAGE_UNIT_STATUSES } from './utils'

import { formInitalState, formReducer } from './state'

const SCHEMA = object({
  organizationId: string().required(I18n.get('An organization is required.')),
  category: string().required(I18n.get('A category is required.')),
  status: string().required(I18n.get('A status is required.')),
  name: string().required(I18n.get('Name is required.'))
})

function PackageUnitForm({ state, modulePath }) {
  const dispatch = useDispatch()
  const { itemId } = useParams()

  const packageUnit = getOperationsPackageUnit()
  const loading = getOperationsLoading()
  const error = getOperationsError()

  const isAboveCustomBreakpoint = useMediaQuery('min-width: 640px')

  const strings = Strings()

  const [formState, dispatchFormState] = useReducer(
    formReducer,
    formInitalState
  )

  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)

  const packageUnitCategories = PACKAGE_UNIT_CATEGORIES(strings)
  const packageUnitStatuses = PACKAGE_UNIT_STATUSES(strings)

  useEffect(() => {
    if (itemId && packageUnit.id !== itemId) {
      dispatch(requestPackageUnit({ packageUnitId: itemId }))
    }
  }, [dispatch, packageUnit?.id, itemId])

  useEffect(() => {
    if (packageUnit?.id && packageUnit.id === itemId) {
      dispatchFormState({ type: 'set-state', state: packageUnit })
    }
  }, [packageUnit, itemId])

  useEffect(() => {
    if (!itemId && state.organizations.length === 1) {
      dispatchFormState({
        type: 'update',
        name: 'organizationId',
        value: state.organizations[0]
      })
    }
  }, [state.organizations, itemId])

  useEffect(() => {
    if (!itemId && state.categories.length === 1) {
      dispatchFormState({
        type: 'update',
        name: 'category',
        value: state.categories[0]
      })
    }
  }, [state.categories, itemId])

  useEffect(() => {
    if (waiting && !loading && !error) {
      history.replace(modulePath)
    }
  }, [waiting, loading, error, modulePath])

  const onSubmit = async e => {
    e?.preventDefault()
    try {
      await SCHEMA.validate(formState, { abortEarly: false })
      setWaiting(true)

      if (itemId) {
        formState.packageUnitId = itemId
        dispatch(updatePackageUnit(formState))
      } else {
        dispatch(createPackageUnit(formState))
      }
    } catch (err) {
      setErrors(getValidationErrorMap(err))
    }
  }

  const handleInput = e => {
    let { name, value } = e.currentTarget
    dispatchFormState({ type: 'update', name, value })
  }

  const handleOrganizationInput = organizationId => {
    dispatchFormState({
      type: 'update',
      name: 'organizationId',
      value: organizationId
    })
  }

  return (
    <Form className='Operations__Form'>
      <HeaderV2
        title={I18n.get('Manage Package Unit')}
        buttonIcon={'save'}
        buttonText={I18n.get('Save Package Unit')}
        buttonCallback={onSubmit}
        backPath={modulePath}
      />
      <Loader isLoading={loading}>
        <FlexV2 direction='column' axisGap={400}>
          <OrganizationInput
            fieldName={'organizationId'}
            organizationId={formState.organizationId}
            handleInput={handleOrganizationInput}
            errors={errors}
          />

          <FlexV2
            axisGap={400}
            direction={isAboveCustomBreakpoint ? 'row' : 'column'}
          >
            <Label style={{ flexGrow: 1 }}>
              <Text variant='page' tone={700}>
                {strings.category}
              </Text>
              <Select
                name={'category'}
                value={formState.category}
                onChange={handleInput}
              >
                <option default value=''>
                  {strings.selectDefault}
                </option>
                {Object.entries(packageUnitCategories).map(([id, name]) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </Select>
              <InputError error={errors?.category} />
            </Label>

            <Label style={{ flexGrow: 1, marginBlockStart: 0 }}>
              <Text variant='page' tone={700}>
                {strings.status}
              </Text>
              <Select
                name={'status'}
                value={formState.status}
                onChange={handleInput}
              >
                <option default value=''>
                  {strings.selectDefault}
                </option>
                {Object.entries(packageUnitStatuses).map(([id, name]) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </Select>
              <InputError error={errors?.status} />
            </Label>
          </FlexV2>

          <Label>
            <Text variant='page' tone={700}>
              {strings.name}
            </Text>
            <Input value={formState.name} name='name' onChange={handleInput} />
            <InputError error={errors?.name} />
          </Label>
        </FlexV2>
      </Loader>
    </Form>
  )
}

export default PackageUnitForm

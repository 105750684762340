import classNames from 'classnames'
import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { Box, Flex, Text, Symbol } from '@/primitives'

import { useDashboard } from '../../context'
import {
  REMOVE_DOWNLOADABLE_DATASET,
  REMOVE_WIDGET,
  TOGGLE_EDITABLE,
  SET_WIDGET_MODE
} from '../../state'
import {
  getDataGridString,
  getWidgetStartingMode,
  isWidgetConfigurable
} from './utils'
import { CHARTS } from '../../Widgets/Shared/Dynamic/config'
import { widgetRegistry } from '../../Widgets'

import './index.scss'

/**
 * MosaicTile is a wrapper around the widget component that provides a title, move & widget controls.
 *
 * @param {Object} props
 * @param {string} props.id
 * @returns {JSX.Element}
 */
function MosaicTile({ id, widgetId, dataGrid }) {
  const definition = widgetRegistry.get(widgetId)
  const { state, dispatchState } = useDashboard()

  const tileRef = useRef(null)
  const prevDashboardId = useRef(state?.currentDashboard?.id)
  const [glowState, setGlowState] = useState('off')
  const [chartSvg, setChartSvg] = useState(null)

  // state comparators
  const [prevNewWidgetId, setPrevNewWidgetId] = useState(null)

  const { component: Widget, props: widgetProps } = state.widgets.get(id)
  const { config, widgetMode } = widgetProps

  const isSensorDashboard = state.currentDashboard.context === 'sensor'
  const isConfigurable = !isSensorDashboard && isWidgetConfigurable(config)

  // Use custom title if provided, otherwise use the widget's title
  const title = config?.title || definition.title

  const setWidgetMode = useCallback(
    mode => {
      dispatchState({
        type: SET_WIDGET_MODE,
        payload: {
          id,
          widgetMode: mode
        }
      })
    },
    [dispatchState, id]
  )

  useEffect(() => {
    if (state?.currentDashboard?.id !== prevDashboardId.current) {
      setWidgetMode(getWidgetStartingMode(widgetProps))
      prevDashboardId.current = state?.currentDashboard?.id
    }
  }, [state?.currentDashboard?.id, widgetProps, setWidgetMode])

  if (
    glowState === 'off' &&
    state.widgetId !== prevNewWidgetId &&
    state.newWidgetId === id
  ) {
    setGlowState('on')
    setPrevNewWidgetId(state.newWidgetId)
    setTimeout(() => {
      setGlowState('locked_off')
      setPrevNewWidgetId(null)
    }, 3000)
  }

  if (glowState === 'on' && tileRef.current) {
    tileRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    })
  }

  const removeWidget = e => {
    e.preventDefault()
    dispatchState({ type: REMOVE_WIDGET, payload: { id } })
    dispatchState({
      type: REMOVE_DOWNLOADABLE_DATASET,
      payload: { id }
    })
  }

  const setEditMode = e => {
    e.preventDefault()
    if (isConfigurable && widgetMode === 'view') {
      setWidgetMode('edit')
    }
  }

  // This turns off editable mode when user clicks on the drag icon but doesn't drag the widget.
  // Note the event used is onMouseUp, not onClick.
  const onDragHandler = e => {
    if (state.editable) {
      dispatchState({ type: TOGGLE_EDITABLE, payload: { editable: false } })
    }
  }

  const isDownloadable = !!(
    widgetMode === 'view' &&
    definition?.options?.measurement &&
    widgetProps?.config?.chart &&
    widgetProps?.config?.chart !== 'value' &&
    CHARTS[widgetProps?.config?.chart] &&
    state.downloadableDatasets.has(id)
  )

  const onDownloadHandler = async e => {
    e.preventDefault()
    if (widgetMode === 'view' && isDownloadable) {
      // const unit = measurements?.find(
      //   m => m.id === definition.options.measurement
      // )?.unit
      // await createDownloadableChart(tileRef, widgetProps, definition, unit)
      const chart = tileRef.current.querySelector('svg')
      setChartSvg(chart.cloneNode(true))
      setWidgetMode('export')
    }
  }

  return (
    <Box
      key={id}
      className={classNames(
        'MosaicV2__Tile',
        widgetMode === 'edit' && 'MosaicV2__Tile__Editable'
      )}
      data-grid={getDataGridString(dataGrid)} // not necessary, but useful for debugging
      data-id={id}
      ref={tileRef}
    >
      <Box className={glowState === 'on' && 'MosaicV2__Tile__Glow'} />
      <Fragment>
        <Flex
          alignMainAxis='space-between'
          axisGap={400}
          className='MosaicV2__Tile__Header'
        >
          {!isSensorDashboard && (
            <Box className='MosaicV2__Tile__Move'>
              <Symbol
                onMouseUp={onDragHandler}
                variant='page'
                tone={900}
                size={350}
                name='drag_indicator'
              />
            </Box>
          )}
          <Box className='MosaicV2__Tile__Title'>
            <Text fontWeight={500} fontFamily='mono'>
              {typeof title === 'function' ? title() : title}
            </Text>
          </Box>
          <Flex className='MosaicV2__Tile__Controls' axisGap={200}>
            {isDownloadable && (
              <Symbol
                variant='page'
                tone={900}
                size={350}
                name='download'
                disabled={widgetMode === 'edit'}
                onClick={onDownloadHandler}
              />
            )}
            {isConfigurable && (
              <Symbol
                variant='page'
                tone={900}
                size={350}
                name='edit note'
                disabled={widgetMode === 'edit'}
                onClick={setEditMode}
              />
            )}
            {!isSensorDashboard && (
              <Symbol
                variant='page'
                tone={900}
                size={350}
                name='delete'
                onClick={removeWidget}
              />
            )}
          </Flex>
        </Flex>

        <Flex
          direction='column'
          className={classNames(
            'MosaicV2__Tile__Content',
            definition?.config?.overflow && 'MosaicV2__Tile__Scroll'
          )}
          wrap='nowrap'
        >
          <Widget
            {...widgetProps}
            chartSvg={chartSvg}
            widgetMode={widgetMode}
            setWidgetMode={setWidgetMode}
          />
        </Flex>
      </Fragment>
    </Box>
  )
}

export default MosaicTile

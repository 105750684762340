import { I18n } from 'aws-amplify'
import { number, object, string, array } from 'yup'

export const specificationSchema = () => {
  return object().shape({
    relayId: string().required(I18n.get('Please select a relay')),
    sensorId: string().required(I18n.get('Please select a sensor')),
    measurementId: string().required(I18n.get('Please select a measurement')),
    min: number()
      .typeError('Only numbers are supported.')
      .required(I18n.get('Mininum value is required')),
    max: number()
      .typeError('Only numbers are supported.')
      .required(I18n.get('Maximum value is required'))
  })
}

// TODO: add checks for the following
// max is greater than min
// relayId is unique
// sensorId is unique

export const controlDeviceSpecSchema = object().shape({
  specs: array()
    .of(specificationSchema())
    .min(1, I18n.get('You must include at least one specification.'))
})

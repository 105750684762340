import { Fragment } from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import './index.scss'

export default function Tooltip({
  children,
  tip,
  tipRef,
  place = 'bottom',
  showTooltip = true
}) {
  return (
    <Fragment>
      <span ref={tipRef} className='react-tooltip-anchor'>
        {children}
      </span>
      {showTooltip && (
        <ReactTooltip
          className='Tooltip__Content'
          float={true}
          variant='light'
          anchorSelect='.react-tooltip-anchor'
          style={{ zIndex: 99 }}
          place={place}
        >
          {tip}
        </ReactTooltip>
      )}
    </Fragment>
  )
}

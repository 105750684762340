import { Fragment, useEffect, useReducer, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Flex, Text, Form, Label, Input, Button, Icon, Box } from '@/primitives'

import {
  sendEnrollSupplierDevice,
  fetchSupplierDevice
} from '@/slices/management/device'

import { fetchLocation } from '@/slices/location'
import {
  getLocation,
  getSupplierDevice,
  getDeviceLoading
} from '@/reducers/selectors'

import Strings from './Strings'
import AdminUtils from '@/Util/AdminUtils'

import './index.scss'

const initialState = {
  name: '',
  tag: '',
  latitude: '',
  longitude: ''
}

function reducer(state, action) {
  if (action.type === 'location') {
    return { ...state, latitude: action.latitude, longitude: action.longitude }
  }

  if (action.type === 'set') {
    return {
      ...state,
      name: action.value
    }
  }

  return {
    ...state,
    [action.type]: action.value
  }
}

const { deviceEnrollmentSchema } = AdminUtils

const DeviceForm = ({ onClickBack, zoneDetails }) => {
  const { zoneId, zonePath } = zoneDetails

  const reduxDispatch = useDispatch()
  const { deviceId } = useParams()
  const [state, dispatch] = useReducer(reducer, initialState)
  const [deviceFetched, setDeviceFetched] = useState(false)
  const [errors, setErrors] = useState({})

  const location = getLocation()
  const supplierDevice = getSupplierDevice()
  const isLoading = getDeviceLoading()

  const strings = Strings()

  useEffect(() => {
    reduxDispatch(fetchLocation())
  }, [reduxDispatch])

  useEffect(() => {
    if (!supplierDevice?.deviceId && deviceId && !deviceFetched) {
      reduxDispatch(fetchSupplierDevice({ deviceId }))
      setDeviceFetched(true)
    }
  }, [supplierDevice, deviceId, setDeviceFetched, deviceFetched, reduxDispatch])

  useEffect(() => {
    if (supplierDevice?.deviceId) {
      dispatch({ type: 'set', value: supplierDevice.name })
    }
  }, [supplierDevice])

  useEffect(() => {
    dispatch({
      type: 'location',
      latitude: location?.latitude ?? zoneDetails.latitude ?? 0,
      longitude: location?.longitude ?? zoneDetails.longitude ?? 0
    })
  }, [location, zoneDetails.latitude, zoneDetails.longitude])

  function onChangeInput(e) {
    const { name, value } = e.target
    dispatch({ type: name, value })
  }

  async function onSubmit(e) {
    e.preventDefault()

    const { name, tag, latitude, longitude } = state

    const deviceData = {
      zoneId,
      deviceId: supplierDevice?.deviceId,
      proofOfPossession: supplierDevice?.proofOfPossession,
      name,
      type: supplierDevice?.type,
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
      tag: tag?.slice(0, 255) ?? null,
      isIoT: true, //TODO: remove when suppliers support greengrass
      isGreengrass: false, //TODO: remove when suppliers support greengrass
      config: null,
      hardwareVersion: supplierDevice?.hardwareVersion ?? null
    }

    try {
      await deviceEnrollmentSchema().validate(deviceData, {
        abortEarly: false
      })
      setErrors({})
      reduxDispatch(sendEnrollSupplierDevice(deviceData))
    } catch (err) {
      if (err.inner) {
        let validationErrors = []
        err?.inner?.forEach(({ message, path }) => {
          validationErrors[path] = message
        })

        setErrors(validationErrors)
      }
    }
  }

  function goBack() {
    onClickBack('room')
  }

  function insertError(field) {
    if (!errors[field]) return null

    return (
      <Fragment>
        <Icon
          className='Enrollment__Form__InputErrorIcon'
          name='error'
          variant='error'
          tone={500}
        />
        <Text className='Enrollment__Form__InputError' variant='error'>
          {errors[field]}
        </Text>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Box className='Heading'>
        <Text as='h4'>{strings.enrollDevice}</Text>
        <Text
          size={100}
          variant='page'
          tone={900}
          style={{ textAlign: 'left', display: 'inline-block' }}
        >
          {strings.enrollDeviceSubheading}
        </Text>
      </Box>
      <Flex
        style={{
          marginTop: '3rem',
          maxWidth: '30rem',
          width: '100%',
          margin: 'auto'
        }}
        alignMainAxis='center'
      >
        <Form className='Enrollment__Form' onSubmit={onSubmit}>
          <Label>
            <Text variant='page' tone={700}>
              {strings.formLabelId}
            </Text>
            <Input disabled={true} value={supplierDevice?.deviceId} />
          </Label>
          <Label>
            <Text variant='page' tone={700}>
              {strings.formLabelName}
            </Text>
            <Input
              name={'name'}
              value={state.name}
              onChange={onChangeInput}
              type={'text'}
              className={errors.name ? 'Error' : ''}
            />
          </Label>
          {insertError('name')}
          <Label>
            <Text variant='page' tone={700}>
              {strings.formLabelTag}
            </Text>
            <Input
              name={'tag'}
              value={state.tag}
              onChange={onChangeInput}
              type={'text'}
              className={errors.tag ? 'Error' : ''}
            />
          </Label>
          {insertError('tag')}
          <Label>
            <Text variant='page' tone={700}>
              {strings.location}
            </Text>
            <Input
              name='location'
              value={zonePath}
              disabled={true}
              type='text'
            />
          </Label>
          <Flex
            wrap='nowrap'
            direction='row'
            alignMainAxis='space-between'
            axisGap={300}
          >
            <Box>
              <Label>
                <Text variant='page' tone={700}>
                  {strings.formLabelLatitude}
                </Text>
                <Input
                  name={'latitude'}
                  value={state.latitude}
                  onChange={onChangeInput}
                  type={'text'}
                  className={errors.latitude ? 'Error' : ''}
                />
              </Label>
              {insertError('latitude')}
            </Box>
            <Box>
              <Label>
                <Text variant='page' tone={700}>
                  {strings.formLabelLongitude}
                </Text>
                <Input
                  name={'longitude'}
                  value={state.longitude}
                  onChange={onChangeInput}
                  type={'text'}
                  className={errors.longitude ? 'Error' : ''}
                />
              </Label>
              {insertError('longitude')}
            </Box>
          </Flex>
        </Form>
        <Flex
          axisGap={400}
          alignMainAxis='space-between'
          className='Button__Container'
        >
          <Button
            variant='neutral'
            size='small'
            onClick={goBack}
            className='Cancel__Button'
          >
            {strings.backToRoom}
          </Button>
          <Button
            onClick={onSubmit}
            size='small'
            type='submit'
            variant='primary'
            className='Continue__Button'
            loading={isLoading}
          >
            {strings.submitBtn}
          </Button>
        </Flex>
      </Flex>
    </Fragment>
  )
}

export default DeviceForm

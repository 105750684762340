import {
  CLEAR_DATABOARD,
  REQUEST_DATABOARD_HARVESTS,
  RECEIVE_DATABOARD_HARVESTS,
  REQUEST_DATABOARD_CROPS,
  RECEIVE_DATABOARD_CROPS,
  REQUEST_DATABOARD_SITE_HIERARCHY,
  RECEIVE_DATABOARD_SITE_HIERARCHY
} from '@/actions/operations/databoard'

export const databoardInitialState = {
  databoardHarvests: [],
  databoardHarvestArgs: {},
  databoardCrops: [],
  databoardCropArgs: {},
  sitesHierarchies: {},
  loading: false,
  error: null
}

const getUniqueHarvests = harvests => {
  const uniqueValues = harvests.reduce((acc, harvest) => {
    if (harvest) {
      const isDuplicate = acc.some(({ id }) => id === harvest.id)
      if (!isDuplicate) acc.push(harvest)
    }
    return acc
  }, [])
  return uniqueValues
}

const handlers = {
  [CLEAR_DATABOARD]: state => ({
    ...state,
    databoardHarvests: [],
    databoardHarvestArgs: {},
    databoardCrops: [],
    databoardCropArgs: {},
    loading: false,
    error: null
  }),
  [REQUEST_DATABOARD_HARVESTS]: (state, action) => ({
    ...state,
    databoardHarvests: [],
    databoardHarvestArgs: {},
    error: null,
    loading: true
  }),
  [RECEIVE_DATABOARD_HARVESTS]: (state, action) => ({
    ...state,
    databoardHarvests: !action.error ? getUniqueHarvests(action.harvests) : [],
    databoardHarvestArgs: !action.error ? action.harvestArgs : {},
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_DATABOARD_CROPS]: (state, action) => ({
    ...state,
    databoardCrops: [],
    databoardCropArgs: {},
    error: null,
    loading: true
  }),
  [RECEIVE_DATABOARD_CROPS]: (state, action) => ({
    ...state,
    databoardCrops: !action.error ? getUniqueHarvests(action.crops) : [],
    databoardCropArgs: !action.error ? action.cropArgs : {},
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_DATABOARD_SITE_HIERARCHY]: (state, action) => ({
    ...state,
    error: null,
    loading: true
  }),
  [RECEIVE_DATABOARD_SITE_HIERARCHY]: (state, { zones, error }) => {
    let hierarchy = {}
    zones.sort((a, b) => a.level - b.level)
    zones.forEach(zone => {
      const path = zone.parentPath.split('/').slice(1)
      hierarchy = stackPaths(hierarchy, path, zone, true)
    })

    return {
      ...state,
      sitesHierarchies: !error
        ? { ...state.sitesHierarchies, ...hierarchy }
        : state.sitesHierarchies,
      error: error ?? null,
      loading: false
    }
  }
}

export default handlers

const stackPaths = (hierarchy, path, zone) => {
  if (path.length === 1 && !hierarchy[path[0]]) {
    hierarchy[path[0]] = { ...zone, children: {}, devices: [] }
  }
  if (hierarchy[path[0]]) {
    let children = hierarchy[path[0]].children
    const nextPath = path.slice(1)
    if (nextPath.length > 0) {
      hierarchy[path[0]].children = stackPaths(children, nextPath, zone)
    }
  }
  return hierarchy
}

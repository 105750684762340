import { I18n } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import LiveDataTile from './LiveDataTile'
import { Text, Box } from '@/primitives'

import { getDeviceTypes, getZonesHierarchy, getMeasurements, getDevice } from '@/reducers/selectors'
import {
  getAllReadMeasurements,
  getAllReadMeasurementsForDevice
} from '@/Util/ZoneUtils'
import { sortMeasurementsForDashboard } from '@/Util/MeasurementUtils'
import { sortMeasurementsByDeviceTypes } from '../../utils'
import { getZone } from '@/Util/ZoneUtils'
import { useLiveDataFeedSimplified } from '../Hooks/useLiveDataFeedSimplified'
import { useLiveDataSimplified } from '../Hooks/useLiveDataSimplified'

import './index.scss'

export default function LiveData() {
  const params = useParams()
  const [availableMeasurements, setAvailableMeasurements] = useState([])

  const zonePath = params?.zone?.split('/')
  const siteId = zonePath[0]
  const currentZoneId = zonePath[zonePath?.length - 1]
  const zonesHierarchy = getZonesHierarchy()
  const measurements = getMeasurements()
  const deviceTypes = getDeviceTypes()
  const device = getDevice()

  const zone = getZone(zonesHierarchy, siteId, currentZoneId)
  const liveDataParams = {
    zoneId: currentZoneId,
    sensorId: params?.sensorId,
    hierarchy: zonesHierarchy[siteId],
    siteId
  }

  useLiveDataFeedSimplified(liveDataParams)
  useLiveDataSimplified({ measurements: availableMeasurements, state: liveDataParams })

  useEffect(() => {
    if (zonesHierarchy && measurements && deviceTypes && siteId) {
      let allReadMeasurements = []

      if (zonesHierarchy[siteId] && device?.id) {
        allReadMeasurements = getAllReadMeasurementsForDevice(
          zonesHierarchy,
          siteId,
          device?.id,
          deviceTypes
        )
      }

      if (zonesHierarchy[siteId] && zone?.id && !params?.sensorId) {
        allReadMeasurements = getAllReadMeasurements(
          zonesHierarchy,
          siteId,
          zone?.id,
          deviceTypes
        )
      }

      if (allReadMeasurements?.length > 0) {
        const sortedMeasurementsId = sortMeasurementsForDashboard(
          measurements,
          allReadMeasurements
        ).map(measurement => measurement.id)

        const sortedMeasurementsIdByDeviceTypes = sortMeasurementsByDeviceTypes(
          sortedMeasurementsId,
          deviceTypes
        )

        if (sortedMeasurementsIdByDeviceTypes?.includes('temperature') && sortedMeasurementsIdByDeviceTypes?.includes('humidity')) {
          sortedMeasurementsIdByDeviceTypes.push('vapour_pressure_deficit')
        }
        setAvailableMeasurements(sortedMeasurementsIdByDeviceTypes)
      }
    }
  }, [
    zonesHierarchy,
    zone?.id,
    device?.id,
    measurements,
    deviceTypes,
    siteId
  ])

  return (
    <div className='SimplifiedDashboard__LiveData'>
      <Text size={300} fontWeight={600} style={{ marginBottom: '1rem', display: 'block' }}>{I18n.get('Live data')}</Text>
      <div className='LiveDataWidget'>
        {availableMeasurements?.length === 0 && (
          <Box style={{ margin: '0.2rem 0' }}>
            <Text tone={800} variant='page'>
              {I18n.get('No data available')}
            </Text>
          </Box>
        )}
        {availableMeasurements.map(measurementId => {
          return (
            <div className='LiveDataWidget__GridItem' key={measurementId}>
              <LiveDataTile
                measurementId={measurementId}
                zoneId={zone?.id}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

import { I18n } from 'aws-amplify'

const Strings = () => ({
  weight: I18n.get('Weight'),
  unitWeight: I18n.get('Unit Weight'),
  totalWeight: I18n.get('Total Weight'),
  quantity: I18n.get('Quantity'),
  kg: I18n.get('kg'),
  noWeights: I18n.get('No weights')
})

export default Strings

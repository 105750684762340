import { I18n } from 'aws-amplify'
import { useLiveData } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Hooks/useLiveData'
import useThresholdStatus from '../../Shared/Hooks/useThresholdStatus'
import { parseValue } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/utils'
import Value from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/Chart/Value'

import {
  convertToSalinity,
  salinityMeasurements
} from '@/components/DashboardPageV2/Desktop/Widgets/Fertigation/Salinity/utils'

export default function LiveValue({ unit, options }) {
  const data = useLiveData({
    measurements: salinityMeasurements
  })
  const thresholdStatus = useThresholdStatus(options.measurements)

  const parseOptions = (water_temperature, conductivity) => ({
    fixedFactor: options.fixedFactor,
    scaleFactor: options.scaleFactor,
    value: convertToSalinity({ water_temperature, conductivity })
  })

  return (
    <Value
      thresholdStatus={thresholdStatus}
      data={{
        value: parseValue(
          parseOptions(
            data?.water_temperature?.value,
            data?.conductivity?.value
          )
        ),
        min: parseValue(
          parseOptions(data?.water_temperature?.min, data?.conductivity?.min)
        ),
        max: parseValue(
          parseOptions(data?.water_temperature?.max, data?.conductivity?.max)
        ),
        unit,
        status: data?.water_temperature?.status || data?.conductivity?.status,
        subtitle: I18n.get('Live')
      }}
    />
  )
}

import { I18n } from 'aws-amplify'
import { useEffect, useState } from 'react'

import { getCurrentUserOrganizations, getNavigationLocationSelector } from '../../../../reducers/selectors'

import { Select, Input } from '@/primitives'

const OrganizationsFilter = ({ state, dispatchState }) => {
  const allOrganizations = getCurrentUserOrganizations()

  const locationSelector = getNavigationLocationSelector()

  const [organizations, setOrganizations] = useState([])

  const [filter, setFilter] = useState(
    state.organizations.length === 1 ? state.organizations[0] : 'all'
  )
  const [lastFilter, setLastFilter] = useState(filter)

  useEffect(() => {
    if (allOrganizations.length > 0) {
      let orgs = allOrganizations
      setOrganizations(orgs)
    }
  }, [allOrganizations])

  useEffect(() => {
    if (locationSelector.get('organization')) {
      setFilter(locationSelector.get('organization').id)
    }
  }, [locationSelector.get('organization')])

  useEffect(() => {
    if (filter === 'all') {
      if (state.organizations.length === 0 && organizations.length > 0) {
        dispatchChange()
      } else if (state.organizations.length === 1 && organizations.length > 0) {
        setLastFilter(state.organizations[0])
        setFilter(state.organizations[0])
      }
    } else if (state.organizations.length === 0) {
      setFilter('all')
    }
  }, [state.organizations, organizations])

  useEffect(() => {
    if (organizations.length > 0) {
      if (filter !== lastFilter) {
        setLastFilter(filter)
        dispatchChange()
      }
    }
  }, [filter, organizations])

  const getOrganizationFilter = () => {
    return filter === 'all' ? organizations.map(i => i.id) : [filter]
  }

  const dispatchChange = () => {
    dispatchState({
      organizations: getOrganizationFilter()
    })
  }

  const onChangeFilter = e => {
    setFilter(e.currentTarget.value)
  }

  if (organizations.length === 1) {
    return (
      <Input
        type='hidden'
        name={'organizationFilter'}
        value={organizations[0]}
      />
    )
  }

  return (
    <Select
      className='Operations__Header__Select'
      name='organizationFilter'
      value={filter}
      onChange={onChangeFilter}
      style={{ visibility: 'hidden', position: 'absolute', zIndex: -1 }}
    >
      <option default value='all'>
        {I18n.get('All Organizations')}
      </option>
      {organizations.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </Select>
  )
}

export default OrganizationsFilter

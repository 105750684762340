import { useEffect } from 'react'
import { I18n } from 'aws-amplify'
import { useDispatch } from 'react-redux'

import { convertToAbsoluteHumidity } from '@/components/DashboardPageV2/Desktop/Widgets/Environment/AbsoluteHumidity/utils'
import { useLiveData } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Hooks/useLiveData'
import { parseValue } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/utils'

import { cleanNextHourPrediction } from '@/slices/ml/prediction'

import { usePredictionData } from '../../Shared/Hooks/usePredictionData'
import useThresholdStatus from '../../Shared/Hooks/useThresholdStatus'

import Value from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/Chart/Value'

export default function LiveValue({ unit, options }) {
  const prediction = usePredictionData('absolute_humidity')
  const data = useLiveData({
    measurements: ['temperature', 'humidity']
  })
  const dispatch = useDispatch()
  const thresholdStatus = useThresholdStatus(options?.measurement)
  useEffect(() => {
    return () => {
      dispatch(cleanNextHourPrediction('absolute_humidity'))
    }
  }, [dispatch])

  const parseOptions = (temperature, humidity) => ({
    fixedFactor: options.fixedFactor,
    scaleFactor: options.scaleFactor,
    value: convertToAbsoluteHumidity({ temperature, humidity })
  })

  return (
    <Value
      thresholdStatus={thresholdStatus}
      data={{
        value: parseValue(
          parseOptions(data?.temperature?.value, data?.humidity?.value)
        ),
        min: parseValue(
          parseOptions(data?.temperature?.min, data?.humidity?.min)
        ),
        max: parseValue(
          parseOptions(data?.temperature?.max, data?.humidity?.max)
        ),
        unit,
        status: data?.temperature?.status || data?.humidity?.status,
        subtitle: I18n.get('Live')
      }}
      prediction={prediction}
    />
  )
}

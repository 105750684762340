import { getOperationsAllCurrencies } from '../../../../../../reducers/selectors'

import {
  Text,
  Table,
  Column,
  Row,
  Cell,
  Slot,
  Button
} from '../../../../../../primitives'

import { getFieldValue } from '../../../utils'

import Strings from './Strings'

function SaleItemFormTable({ sale, saleItems, handleEdit, handleRemove }) {
  const strings = Strings()

  const currencies = getOperationsAllCurrencies()

  const calculateValue = item => {
    return (
      (item.unitPrice / 100) *
      (100 - item.discount) *
      item.quantityOrdered
    ).toFixed(2)
  }

  if (!saleItems?.length) {
    return (
      <Text className={'Operations__Form__Empty'}>{strings.noSaleItems}</Text>
    )
  }

  return (
    <Table className='Operations__Table' aria-colcount='4'>
      <Slot name='head'>
        <Row>
          <Column>{strings.product}</Column>
          <Column>{strings.units}</Column>
          <Column>{strings.value}</Column>
          <Column />
        </Row>
      </Slot>
      <Slot name='body'>
        {saleItems?.map(saleItem => (
          <Row key={saleItem.id}>
            <Cell columnName={strings.product}>
              {saleItem.product.name}
              <span>
                {saleItem.product.weight}
                {strings.grams}
              </span>
            </Cell>
            <Cell columnName={strings.units}>{saleItem.quantityOrdered}</Cell>
            <Cell columnName={strings.value}>
              {calculateValue(saleItem)}&nbsp;
              {getFieldValue(sale.currencyId, currencies, 'code')}
              <span>
                +{saleItem.tax}% {strings.taxLowerCase}
              </span>
            </Cell>
            <Cell className={'Operations__Table__Column__Actions'}>
              <Button
                variant='info'
                size='small'
                iconBefore='edit'
                onClick={() => handleEdit(saleItem)}
                style={{ marginRight: '5px' }}
              />
              <Button
                variant='error'
                size='small'
                iconBefore='delete'
                onClick={() => handleRemove(saleItem)}
              />
            </Cell>
          </Row>
        ))}
      </Slot>
    </Table>
  )
}

export default SaleItemFormTable

import { Auth } from 'aws-amplify'
import axios from 'axios'

const CANCEL_REQUEST_TIMEOUT = 10000 //10 seconds
const CANCELLABLE_OPERATIONS = ['getZonesHierarchy']

export async function getCognitoData() {
  const cognitoUser = await Auth.currentAuthenticatedUser()
  const { sub } = cognitoUser?.attributes
  const jwtToken = cognitoUser?.signInUserSession?.idToken?.jwtToken
  return { id: sub, idToken: jwtToken }
}

export async function getCognitoIdentityId() {
  const info = await Auth.currentCredentials()
  return info.identityId
}

export function handleHttpError(err, key) {
  if (err.response && err.response.data) {
    return { error: err.response.data.errMessage, [key]: null }
  }

  return { error: err.message, [key]: null }
}

export function handleHttpStatusCodeError(status, key) {
  return { [key]: null, error: `Request failed with status ${status}` }
}

export function handleGraphqlResponse(response, key) {
  const { data, error } = response
  return { [key]: data ? data[key] : null, error }
}

export function handleGraphqError(err, key) {
  return { errors: [err.message], [key]: null }
}

export async function makeHttpRequest(url, method, authorization, data = null) {
  try {
    let params = {
      url,
      method,
      headers: {
        authorization
      }
    }

    if (data) params.data = data
    return await axios(params)
  } catch (err) {
    throw err
  }
}

export async function makeGraphqlRequest(
  url,
  operation,
  authorization,
  token = null,
  cacheOperation = true // For now specific to Timestream API
) {
  try {
    const isCancellableOperation = CANCELLABLE_OPERATIONS.some(
      cancellableOperation => {
        return operation?.query?.includes(cancellableOperation)
      }
    )

    const controller = isCancellableOperation ? new AbortController() : null

    let timeout = null
    if (isCancellableOperation) {
      timeout = setTimeout(() => {
        controller.abort()
      }, [CANCEL_REQUEST_TIMEOUT])
    }

    const { data, status } = await axios({
      url,
      method: 'post',
      data: { ...operation, token, cacheOperation },
      headers: {
        authorization
      },
      signal: controller?.signal ?? null
    })

    if (timeout) {
      clearTimeout(timeout)
    }

    return {
      data: data.data,
      error: data.errors ? data.errors[0]?.message : null,
      status
    }
  } catch (err) {
    throw err
  }
}

/**
 * Makes a graphql request to the API
 * This differs from makeGraphqlRequest in that it throws an error if the request fails
 * Only to be used with asyncThunk as it will be caught by caught by slice in extraReducers
 * The error is then to be handled inside the case.rejected of the asyncThunk
 * @param {string} url
 * @param {string} operation
 * @param {boolean} cacheOperation
 * @returns {Promise<*>}
 * @throws {Error} - If request fails, if response contains errors or if status is not 200
 */
export async function makeGraphqlRequestV2(
  url,
  operation,
  cacheOperation = true // For now specific to Timestream API
) {
  try {
    const isCancellableOperation = CANCELLABLE_OPERATIONS.some(
      cancellableOperation => {
        return operation?.query?.includes(cancellableOperation)
      }
    )

    const controller = isCancellableOperation ? new AbortController() : null

    let timeout = null
    if (isCancellableOperation) {
      timeout = setTimeout(() => {
        controller.abort()
      }, [CANCEL_REQUEST_TIMEOUT])
    }

    const { id: token, idToken: authorization } = await getCognitoData()

    const { data, status } = await axios({
      url,
      method: 'post',
      data: { ...operation, token, cacheOperation },
      headers: {
        authorization
      },
      signal: controller?.signal ?? null
    })

    if (timeout) {
      clearTimeout(timeout)
    }

    if (data?.errors || data?.errors?.length > 0) {
      throw data.errors[0]?.message
    }

    if (status !== 200) {
      throw new Error(`Request failed with status ${status}`)
    }

    return data.data
  } catch (err) {
    if (!(err instanceof Error)) {
      throw new Error(err)
    }
    throw err
  }
}

import { call, put, takeLatest } from 'redux-saga/effects'

import {
  createInventoryTransfer,
  getInventoryTransfer,
  getInventoryTransfers,
  updateInventoryTransfer
} from '@/api/operations/inventoryTransfer'

import {
  REQUEST_INVENTORY_TRANSFERS,
  REQUEST_INVENTORY_TRANSFER,
  CREATE_INVENTORY_TRANSFER,
  UPDATE_INVENTORY_TRANSFER,
  receiveInventoryTransfers,
  receiveInventoryTransfer,
  inventoryTransferCreated,
  inventoryTransferUpdated
} from '@/actions/operations/inventoryTransfer'

import { displayBanner } from '../utils'

import Strings from './Strings'

const strings = Strings()

function* sendCreateInventoryTransfer(action) {
  const { data, error = null } = yield call(
    createInventoryTransfer,
    action.params
  )
  yield put(displayBanner(error, strings.inventoryTransferCreated))
  yield put(inventoryTransferCreated(data, error))
}

export function* watchCreateInventoryTransfer() {
  yield takeLatest(CREATE_INVENTORY_TRANSFER, sendCreateInventoryTransfer)
}

function* sendUpdateInventoryTransfer(action) {
  const { data, error = null } = yield call(
    updateInventoryTransfer,
    action.params
  )
  yield put(displayBanner(error, strings.inventoryTransferUpdated))
  yield put(inventoryTransferUpdated(data, error))
}

export function* watchUpdateInventoryTransfer() {
  yield takeLatest(UPDATE_INVENTORY_TRANSFER, sendUpdateInventoryTransfer)
}

function* sendRequestInventoryTransfers(action) {
  const { data, error = null } = yield call(
    getInventoryTransfers,
    action.params
  )
  const { inventoryTransfers = [], count = 0 } = data
  yield put(receiveInventoryTransfers(inventoryTransfers, count, error))
}

export function* watchRequestInventoryTransfers() {
  yield takeLatest(REQUEST_INVENTORY_TRANSFERS, sendRequestInventoryTransfers)
}

function* sendRequestInventoryTransfer(action) {
  const { data: inventoryTransfer, error = null } = yield call(
    getInventoryTransfer,
    action.params
  )
  yield put(receiveInventoryTransfer(inventoryTransfer, error))
}

export function* watchRequestInventoryTransfer() {
  yield takeLatest(REQUEST_INVENTORY_TRANSFER, sendRequestInventoryTransfer)
}

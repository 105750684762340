import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { getCognitoData, makeGraphqlRequest } from '../utils'

/*
 * Queries
 */

export const getSiteTrackingStatus = async siteId => {
  try {
    const query = /* GraphQL */ `
      query getSiteTrackingStatus($siteId: String!) {
        getSiteTrackingStatus(siteId: $siteId) {
          siteId
          tracking
        }
      }
    `

    const { id, idToken } = await getCognitoData()
    const operation = graphqlOperation(query, { siteId })
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken,
      id
    )

    if (error) {
      return {
        status: null,
        error: `Site tracking status not fetched: ${error}`
      }
    }

    return { status: data?.getSiteTrackingStatus, error: null }
  } catch (err) {
    return { status: null, error: err.message }
  }
}

/*
 * Mutations
 */

export const updateSiteTrackingStatus = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateSiteTrackingStatus($siteId: String!, $tracking: Boolean!) {
        updateSiteTrackingStatus(siteId: $siteId, tracking: $tracking) {
          siteId
          tracking
        }
      }
    `

    const { id, idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken,
      id
    )

    if (error) {
      return {
        status: null,
        error: `Site tracking status not fetched: ${error}`
      }
    }

    return { status: data?.updateSiteTrackingStatus, error: null }
  } catch (err) {
    return { status: null, error: err.message }
  }
}

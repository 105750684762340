import { makeActionCreator } from '@/Util/ReduxUtils'

export const RECEIVE_OPERATIONS_DASHBOARD_DATA =
  'RECEIVE_OPERATIONS_DASHBOARD_DATA'
export const receiveOperationsDashboardData = makeActionCreator(
  RECEIVE_OPERATIONS_DASHBOARD_DATA,
  'data',
  'params',
  'error'
)

export const CLEAN_OPERATIONS_DASHBOARD_DATA = 'CLEAN_OPERATIONS_DASHBOARD_DATA'
export const cleanOperationsDashboardData = makeActionCreator(
  CLEAN_OPERATIONS_DASHBOARD_DATA,
  'data'
)

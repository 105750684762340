import { useEffect, useReducer, useState } from 'react'
import { useDispatch } from 'react-redux'

import AuditLogTable from './Table'
import LogInspector from './LogInspector'
import AuditFilters from './Filter'

import { Text, FlexV2, Separator } from '@/primitives'

import { fetchZonesList } from '@/slices/management/zone'

import {
  getAuditLogNextToken,
  getAuditLogs,
  getAuditLogNextTokenCount,
  getZonesList
} from '@/reducers/selectors'

import { fetchAuditLogs, fetchNextTokenAuditLogs } from '@/slices/audit/log'
import { fetchAllUsers } from '@/slices/management/user'

import useMediaQuery from '@/hooks/useMediaQuery'

import { AuditStateContext } from './context'

import {
  SET_CURRENT_AUDIT_TRAIL_LOGS,
  SET_TOTAL_PAGES,
  UPDATE_NEXT_TOKEN,
  initialState,
  reducer
} from './state'

import Strings from './Strings'

import './index.scss'

const AuditPage = () => {
  const dispatch = useDispatch()

  const strings = Strings()

  const zonesList = getZonesList()
  const auditLogsData = getAuditLogs()
  const nextToken = getAuditLogNextToken()
  const nextTokenCount = getAuditLogNextTokenCount()

  const [state, dispatchState] = useReducer(reducer, initialState)

  const [showModal, setShowModal] = useState(false)
  const [selectedLog, setSelectedLog] = useState(null)
  const [initialFetch, setInitalFetch] = useState(false)

  if (!initialFetch) {
    if (auditLogsData?.length === 0) {
      dispatch(
        fetchAuditLogs({
          filters: state.filters,
          limit: state.limit
        })
      )
    }
    if (zonesList && zonesList?.length === 0) {
      dispatch(fetchZonesList())
    }
    dispatch(fetchAllUsers())
    setInitalFetch(true)
  }

  const { zoneMap, zoneOptions } = zonesList.reduce(
    (acc, zone) => {
      acc.zoneMap[zone.id] = zone.name

      if (!zone.parentId) {
        acc.zoneOptions.push({
          value: zone.id,
          label: zone.name
        })
      }

      return acc
    },
    {
      zoneMap: {},
      zoneOptions: []
    }
  )

  useEffect(() => {
    if (auditLogsData?.length > 0) {
      dispatchState({
        type: SET_CURRENT_AUDIT_TRAIL_LOGS,
        logs: auditLogsData
      })
    }
  }, [auditLogsData])

  useEffect(() => {
    if (auditLogsData.length > 0 && nextToken && !state.skipFetch) {
      dispatchState({
        type: UPDATE_NEXT_TOKEN,
        nextToken
      })

      dispatch(
        fetchNextTokenAuditLogs({
          filters: state.filters,
          limit: 1,
          nextToken
        })
      )
    }
  }, [
    dispatch,
    state?.filters,
    state?.limit,
    nextToken,
    auditLogsData,
    state.skipFetch
  ])

  useEffect(() => {
    if (auditLogsData.length > 0) {
      const currentPage = state?.currentPage

      let newTotalPages = currentPage + nextTokenCount

      dispatchState({
        type: SET_TOTAL_PAGES,
        totalPages: newTotalPages
      })
    }
  }, [state?.currentPage, auditLogsData, nextTokenCount, nextToken])

  const onInspect = auditLog => {
    setShowModal(true)
    setSelectedLog(auditLog)
  }

  const onClose = () => {
    setShowModal(false)
    setSelectedLog(null)
  }

  const isAboveBreakpoint = useMediaQuery('min-width: 1024px')

  return (
    <AuditStateContext.Provider value={{ state, dispatchState }}>
      <FlexV2
        direction={'column'}
        axisGap={500}
        className={'AuditLogs__Content'}
      >
        <FlexV2 direction={'column'}>
          <Text size={300} fontWeight={600}>
            {strings?.auditTab}
          </Text>
          <Text variant='page' tone={900}>
            {strings?.auditLogSubtitle}
          </Text>
        </FlexV2>

        <Separator />

        <FlexV2 axisGap={500} direction={isAboveBreakpoint ? null : 'column'}>
          <AuditFilters
            zoneOptions={zoneOptions}
            isAboveBreakpoint={isAboveBreakpoint}
          />
          <AuditLogTable
            onInspect={onInspect}
            zoneMap={zoneMap}
            zoneOptions={zoneOptions}
          />
        </FlexV2>
      </FlexV2>

      {showModal && selectedLog && (
        <LogInspector
          auditLog={selectedLog}
          showModal={showModal}
          onClose={onClose}
        />
      )}
    </AuditStateContext.Provider>
  )
}

export default AuditPage

import { I18n } from 'aws-amplify'
import { object, string } from 'yup'
import { useEffect, useState, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  createPaymentMethod,
  requestPaymentMethod,
  updatePaymentMethod
} from '@/actions/operations/paymentMethod'

import {
  getOperationsPaymentMethod,
  getOperationsLoading,
  getOperationsError
} from '@/reducers/selectors'

import { Flex, Form, Input, Label, Text, Loader } from '@/primitives'

import InputError from '@/elements/InputError'

import HeaderV2 from '@/components/Operations/Shared/HeaderV2'
import OrganizationInput from '../../Shared/Organization/Input'

import { getValidationErrorMap } from '@/Util/GeneralUtils'
import history from '../../../../history'

import { formInitalState, formReducer } from './state'

const SCHEMA = object({
  organizationId: string().required(I18n.get('An organization is required.')),
  name: string().required(I18n.get('Name is required.'))
})

function PaymentMethodForm({ state, modulePath }) {
  const dispatch = useDispatch()
  const { itemId } = useParams()

  const paymentMethod = getOperationsPaymentMethod()
  const loading = getOperationsLoading()
  const error = getOperationsError()

  const [formState, dispatchFormState] = useReducer(
    formReducer,
    formInitalState
  )

  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)
  const [fetchCalled, setFetchCalled] = useState(false)

  useEffect(() => {
    if (itemId && paymentMethod.id !== itemId && !fetchCalled) {
      dispatch(requestPaymentMethod({ paymentMethodId: itemId }))
      setFetchCalled(true)
    }
  }, [dispatch, paymentMethod?.id, itemId, fetchCalled])

  useEffect(() => {
    if (paymentMethod?.id && paymentMethod.id === itemId) {
      dispatchFormState({ type: 'set-state', state: paymentMethod })
    }
  }, [paymentMethod, itemId])

  useEffect(() => {
    if (!itemId && state.organizations.length === 1) {
      dispatchFormState({
        type: 'update',
        name: 'organizationId',
        value: state.organizations[0]
      })
    }
  }, [state, itemId])

  useEffect(() => {
    if (waiting && !loading && !error) {
      history.replace(modulePath)
    }
  }, [waiting, loading, error, modulePath])

  const onSubmit = async e => {
    e?.preventDefault()
    try {
      await SCHEMA.validate(formState, { abortEarly: false })
      setWaiting(true)

      if (itemId) {
        formState.paymentMethodId = itemId
        dispatch(updatePaymentMethod(formState))
      } else {
        dispatch(createPaymentMethod(formState))
      }
    } catch (error) {
      setErrors(getValidationErrorMap(error))
    }
  }

  const onChange = e => {
    let { name, value } = e.currentTarget
    dispatchFormState({ type: 'update', name, value })
  }

  const handleOrganizationInput = organizationId => {
    dispatchFormState({
      type: 'update',
      name: 'organizationId',
      value: organizationId
    })
  }

  return (
    <Form className='Operations__Form'>
      <HeaderV2
        title={I18n.get('Manage Payment Method')}
        backPath={modulePath}
        buttonText={I18n.get('Save Payment Method')}
        buttonCallback={onSubmit}
        buttonIcon={'save'}
      />
      <Loader isLoading={loading}>
        <OrganizationInput
          fieldName={'organizationId'}
          organizationId={formState.organizationId}
          handleInput={handleOrganizationInput}
          errors={errors}
        />
        <Flex axisGap={300} className='Operations__Form__Fields'>
          <Label>
            <Text variant='page' tone={700}>
              {I18n.get('Name')}
            </Text>
            <Input value={formState.name} name='name' onChange={onChange} />
            <InputError error={errors?.name} />
          </Label>
        </Flex>
      </Loader>
    </Form>
  )
}

export default PaymentMethodForm

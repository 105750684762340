import { I18n } from 'aws-amplify'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Select } from '@/primitives'

import { requestProducts } from '@/actions/operations/product'

import {
  getCurrentUserOrganizations,
  getOperationsProducts
} from '@/reducers/selectors'

const ProductsFilter = ({ state, dispatchState }) => {
  const dispatch = useDispatch()

  const organizations = getCurrentUserOrganizations()
  const products = getOperationsProducts()

  const [productFilter, setProductFilter] = useState(
    state.products.length === 1 ? state.products[0] : 'all'
  )
  const [lastFilter, setLastFilter] = useState(productFilter)

  const getProductFilter = useCallback(() => {
    return productFilter === 'all' ? products.map(i => i.id) : [productFilter]
  }, [productFilter, products])

  const dispatchChange = useCallback(() => {
    dispatchState({
      products: getProductFilter()
    })
  }, [dispatchState, getProductFilter])

  useEffect(() => {
    if (organizations.length > 0) {
      dispatch(
        requestProducts({
          filter: {
            organizationId: organizations.map(o => o.id)
          }
        })
      )
    }
  }, [dispatch, organizations])

  useEffect(() => {
    if (
      productFilter === 'all' &&
      state.products.length === 0 &&
      products.length > 0
    ) {
      dispatchChange()
    }
  }, [state.products, products, productFilter, dispatchChange])

  useEffect(() => {
    if (products.length > 0) {
      if (productFilter !== lastFilter) {
        setLastFilter(productFilter)
        dispatchChange()
      }
    }
  }, [productFilter, products, lastFilter, dispatchChange])

  const onChangeFilter = e => {
    setProductFilter(e.currentTarget.value)
  }

  const filterProducts = () => {
    if (state.organizations.length === 1) {
      return products.filter(product =>
        state.organizations.includes(product.organizationId)
      )
    }
    return []
  }

  const options = filterProducts()

  return (
    <Select
      className='Operations__Header__Select'
      name='productId'
      value={productFilter}
      onChange={onChangeFilter}
      disabled={options.length === 0}
    >
      <option default value='all'>
        {I18n.get('All Products')}
      </option>
      {options.map(({ id, name, weight }) => (
        <option key={id} value={id}>
          {name} {weight}
          {I18n.get('g')}
        </option>
      ))}
    </Select>
  )
}

export default ProductsFilter

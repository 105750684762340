import { Children, useState, useMemo, useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Box } from '@/primitives'

import ActionLink from '../ActionLink'
import Link from '../Link'

import { menuData } from '../MenuData'

import { setSelectedLevel } from '@/slices/navigation'

import { getCurrentUser, getNavigationSelectedLevel } from '@/reducers/selectors'

import { byVisible } from '../../Utils'

import './index.scss'

const GroupElement = ({ data, level, layer = 0 }) => {

  const dispatch = useDispatch()
  
  const navigationSelectedLevel = getNavigationSelectedLevel()

  const [open, setOpen] = useState(
    layer === 0 ? navigationSelectedLevel === level : false
  )

  const handleOpen = open => {
    if (layer !== 0) {
      setOpen(open)
    } else {
      if (level !== navigationSelectedLevel) {
        dispatch(setSelectedLevel(level))

        setOpen(false)
      } else {
        setOpen(open)
      }
    }
  }

  useLayoutEffect(() => {
    if (layer === 0) {
      setOpen(navigationSelectedLevel === level)
    }
  }, [navigationSelectedLevel, setOpen, layer, level])

  if (!data || data.visible === false) {
    return null
  }

  const { to, text, title, icon, routes } = data

  return (
    <Box className={'Group'} open={open}>
      <ActionLink
        className={'Group__Trigger'}
        open={open}
        setOpen={handleOpen}
        to={to}
        text={text}
        title={title}
        icon={icon}
        level={level}
      />
      {routes?.length ? (
        <Box className={'Group__Wrapper'}>
          <Box className={'Group__Content'}>
            {Children.toArray(
              routes
                .filter(byVisible)
                .map(({ to, text, icon, routes, exact }, index) => {
                  if (!to && !text) return null

                  if (!routes) {
                    return (
                      <Link
                        to={to}
                        text={text}
                        icon={icon}
                        level={level}
                        exact={exact}
                      />
                    )
                  }
                  return (
                    <GroupElement
                      data={{ to, text, icon, exact, routes }}
                      level={level}
                      layer={index + 1}
                    />
                  )
                })
            )}
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}

const Group = ({ level, locationSelector, permissions, strings }) => {
  const currentUser = getCurrentUser()

  const data = useMemo(
    () =>
      menuData({ level, locationSelector, permissions, strings, currentUser }),
    [locationSelector, permissions, level, strings, currentUser]
  )

  if (data?.visible === false) return null

  return <GroupElement data={data} level={level} />
}

export default Group

import { Canvg } from 'canvg'
import { I18n } from 'aws-amplify'

import Strings from './Strings'

const strings = Strings()

export const BAR_GRAPH_THEME = {
  background: 'transparent',
  fontFamily: 'IBM Plex Mono, monospace',
  fontSize: 12,
  textColor: 'var(--ctx-theme-contrast-page-200)',
  axis: {
    legend: {
      text: {
        fontSize: 12,
        color: 'var(--ctx-theme-contrast-page-200)'
      }
    }
  },
  grid: {
    line: {
      stroke: 'var(--ctx-theme-color-page-400)',
      strokeWidth: 1
    }
  },
  tooltip: {
    container: {
      background: 'var(--ctx-theme-color-page-300)',
      color: 'var(--ctx-theme-contrast-page-300)',
      fontSize: 'inherit',
      borderRadius: '2px',
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
      padding: '5px 9px'
    }
  }
}

const pageThemeVariableCandidates = [
  '--ctx-theme-color-page-100',
  '--ctx-theme-color-page-200',
  '--ctx-theme-color-page-300',
  '--ctx-theme-color-page-400',
  '--ctx-theme-color-page-500',
  '--ctx-theme-color-page-600',
  '--ctx-theme-color-page-700',
  '--ctx-theme-color-page-800',
  '--ctx-theme-color-page-900'
]

export const createDownloadableChart = async (
  chartRef,
  fileName,
  title,
  unit = null
) => {

  // create local theme
  const cssVariables = window.getComputedStyle(document.documentElement)

  // fetch svg inside ref
  const chartSvg = chartRef.current.querySelector('svg')

  // local string of the chartSVG (querySelector can't select in style attributes... so we have to use strings)
  let svg = chartSvg.outerHTML

  // create a canvas element
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  const padding = 50 // Padding size

  // Update canvas dimensions
  canvas.width = Number(chartSvg.getAttribute('width'))
  canvas.height = Number(chartSvg.getAttribute('height')) + padding

  /* Start Render Here */
  
  // Render the SVG on the canvas
  ctx.fillStyle = cssVariables.getPropertyValue('--ctx-theme-color-page-200')
  ctx.fillRect(0, 0, canvas.width, canvas.height)

  // Set a default fillColor after canvas clear
  ctx.fillStyle = cssVariables.getPropertyValue('--ctx-theme-contrast-page-200')

  // Process CSS Variables inside SVG (Canvg does not lookup css variables)
  pageThemeVariableCandidates.forEach((cssVariable)=>{
    const regxp = new RegExp(`var\\(${cssVariable}\\)`, 'gm')
    svg = svg.replace(regxp, cssVariables.getPropertyValue(cssVariable))
  })

  if(svg.length){
    const v = Canvg.fromString(ctx, svg, {
      ignoreDimensions: true,
      ignoreClear: true,
      offsetX: 0,
      offsetY: padding,
      scaleWidth: canvas.width,
      scaleHeight: canvas.height - padding
    })
  
    v.start()
    v.stop()
  }

  // Add title at the top-left
  ctx.font = '18px Inter, system-ui, sans-serif'
  ctx.fillStyle = cssVariables.getPropertyValue('--ctx-theme-contrast-page-200')

  const textHorizontalPadding = 35

  // Add unit at the top-right
  if (unit) {
    let unitTextWidth = ctx.measureText(unit).width
    let unitPosition = canvas.width - unitTextWidth - textHorizontalPadding
    ctx.fillText(unit, unitPosition, 30)
  }

  ctx.fillText(title.main, textHorizontalPadding, 30)
  ctx.font = '12px Inter, system-ui, sans-serif'
  ctx.fillText(title.sub, textHorizontalPadding, 50)

  /* End Render Here */

  const img = canvas.toDataURL('image/jpeg')
  var link = document.createElement('a')

  link.download = fileName + '.jpg'
  link.href = img
  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
}

export const getStringsByContext = (section, useCustomBar = false) => {
  const stringsGrouped = {
    harvest: {
      customBarText: strings.harvestCustomBarButton,
      downloadDocTitle: useCustomBar
        ? I18n.get('Produce weight by grade and location')
        : I18n.get('Produce weight by location'),
      pageHeading: strings.harvestData,
      chartHeading: I18n.get('Bar chart of produce by location'),
      tableHeading: I18n.get('Table of harvest entries'),
      yAxisLabel: strings.weight,
      yAxisUnit: strings.kilograms,
      searchPlaceholder: strings.searchHarvests
    },
    crops: {
      customBarText: '',
      downloadDocTitle: I18n.get('Crops status changes over time by location'),
      pageHeading: strings.cropsData,
      chartHeading: I18n.get('Crops timelines'),
      tableHeading: I18n.get('Table of crops entries'),
      searchPlaceholder: strings.searchCrops
    }
  }

  return stringsGrouped[section]
}

export const cropDataValues = {
  0: I18n.get('Unplanned'),
  10: I18n.get('Planned'),
  20: I18n.get('Sowed'),
  30: I18n.get('Transplanted'),
  40: I18n.get('Harvest started'),
  50: I18n.get('Harvest ended')
}
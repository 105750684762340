import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { getCognitoData, makeGraphqlRequest } from '../utils'

export const getRole = async params => {
  try {
    const query = /* GraphQL */ `
      query getRole($roleName: String!) {
        getRole(roleName: $roleName) {
          name
          organizations {
            id
            name
          }
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return { roles: null, error }
    }

    return { role: data?.getRole, error: null }
  } catch (err) {
    return { role: null, error: err?.response?.data?.message ?? err.message }
  }
}

export const getRoles = async () => {
  try {
    const query = /* GraphQL */ `
      query getAllRoles {
        getAllRoles {
          name
          organizations {
            id
            name
          }
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, {})
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return { roles: null, error }
    }

    return { roles: data?.getAllRoles, error: null }
  } catch (err) {
    return { roles: null, error: err?.response?.data?.message ?? err.message }
  }
}

export const getRolesByOrganizationId = async params => {
  try {
    const query = /* GraphQL */ `
      query getRolesByOrganizationId($organizationId: String!) {
        getRolesByOrganizationId(organizationId: $organizationId) {
          name
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return { roles: null, error }
    }

    return { roles: data?.getRolesByOrganizationId, error: null }
  } catch (err) {
    return { roles: null, error: err?.response?.data?.message ?? err.message }
  }
}

export const getRolePermissions = async params => {
  try {
    const query = /* GraphQL */ `
      query getRolePermissions($roleName: String!) {
        getRolePermissions(roleName: $roleName) {
          id
          operationType
          resourceType
          resourceId
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return { permissions: null, error }
    }

    return { permissions: data?.getRolePermissions, error: null }
  } catch (err) {
    return {
      permissions: null,
      error: err?.response?.data?.message ?? err.message
    }
  }
}

export const getRoleUsers = async params => {
  try {
    const query = /* GraphQL */ `
      query getRoleUsers($roleName: String!) {
        getRoleUsers(roleName: $roleName) {
          firstName
          lastName
          userName
          email
          phoneNumber
          sub
          cognitoId
          role
          createdTimestamp
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return { users: null, error }
    }

    return { users: data?.getRoleUsers, error: null }
  } catch (err) {
    return { users: null, error: err?.response?.data?.message ?? err.message }
  }
}

export const createRole = async params => {
  try {
    const { roleName, organizationIds, baseRole = null } = params
    const mutation = /* GraphQL */ `
      mutation createRole(
        $roleName: String!
        $organizationIds: [String]
        $baseRole: String
      ) {
        createRole(
          roleName: $roleName
          organizationIds: $organizationIds
          baseRole: $baseRole
        ) {
          name
          organizations {
            id
            name
          }
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, {
      roleName,
      organizationIds,
      baseRole
    })

    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return { role: null, error }
    }

    return { role: data?.createRole, error: null }
  } catch (err) {
    return { role: null, error: err?.response?.data?.message ?? err.message }
  }
}

export const addRolePermission = async params => {
  try {
    const { role, permission } = params
    const mutation = /* GraphQL */ `
      mutation addPermissionToRole($roleName: String!, $permissionId: Int!) {
        addPermissionToRole(roleName: $roleName, permissionId: $permissionId) {
          name
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, {
      roleName: role.roleName,
      permissionId: permission.id
    })
    const { error } = await makeGraphqlRequest(GRAPHQL_URL, operation, idToken)

    if (error) {
      return { error }
    }

    return { error: null }
  } catch (err) {
    return { error: err?.response?.data?.message ?? err.message }
  }
}

export const removeRolePermission = async params => {
  try {
    const { role, permission } = params
    const mutation = /* GraphQL */ `
      mutation removePermissionFromRole(
        $roleName: String!
        $permissionId: Int!
      ) {
        removePermissionFromRole(
          roleName: $roleName
          permissionId: $permissionId
        ) {
          name
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, {
      roleName: role.roleName,
      permissionId: permission.id
    })
    const { error } = await makeGraphqlRequest(GRAPHQL_URL, operation, idToken)

    if (error) {
      return { error }
    }

    return { error: null }
  } catch (err) {
    return { error: err?.response?.data?.message ?? err.message }
  }
}

export const deleteRole = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation deleteRole($roleName: String!) {
        deleteRole(roleName: $roleName) {
          name
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return { role: null, error }
    }

    return { role: data?.deleteRole, error: null }
  } catch (err) {
    return { role: null, error: err?.response?.data?.message ?? err.message }
  }
}

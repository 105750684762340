export const getOperationsAllProduces = state =>
  state.rootReducer.operationsReducer.allProduces

export const getOperationsAllVarieties = state =>
  state.rootReducer.operationsReducer.allVarieties

export const getOperationsAllGrades = state =>
  state.rootReducer.operationsReducer.allGrades

export const getOperationsAllCountries = state =>
  state.rootReducer.operationsReducer.allCountries

export const getOperationsAllRegions = state =>
  state.rootReducer.operationsReducer.allRegions

export const getOperationsAllCities = state =>
  state.rootReducer.operationsReducer.allCities

export const getOperationsAllAreas = state =>
  state.rootReducer.operationsReducer.allAreas

export const getOperationsAllCurrencies = state =>
  state.rootReducer.operationsReducer.allCurrencies

export const getOperationsAllPackageUnits = state =>
  state.rootReducer.operationsReducer.allPackageUnits

export const getOperationsAllStorageFacilities = state =>
  state.rootReducer.operationsReducer.allStorageFacilities

export const getOperationsAllShippingMethods = state =>
  state.rootReducer.operationsReducer.allShippingMethods

export const getOperationsAllPaymentMethods = state =>
  state.rootReducer.operationsReducer.allPaymentMethods

export const getOperationsAllPaymentTerms = state =>
  state.rootReducer.operationsReducer.allPaymentTerms

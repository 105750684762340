import { useReducer, useState, Fragment, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import GenerateTextForm from './GenerateTextForm'
import { Dialog, Select, LineSeparator } from '@/elements'
import { Slot, Text, Flex, Input, Label, Button, Box } from '@/primitives'

import { showBanner } from '@/slices/util'
import {
  createTooltipHelpText,
  cleanHelpText,
  updateTooltipHelpText
} from '@/slices/fyi/helpText'
import LANGUAGES from '@/Util/languages.json'
import {
  getHelpTextUpserted,
  getFyiTags,
  getShowBanner
} from '@/reducers/selectors'
import { helpTextSchema } from '../config'
import Strings from '../../Strings'
import { getTagOptions } from '../util'

const strings = Strings()

const languageOptions = LANGUAGES.enabled.filter(lang => lang !== 'en')

const initialState = {
  helpTextId: '',
  title: '',
  titleTranslations: [],
  text: '',
  textTranslations: [],
  tags: [],
  translations: []
}

function reducer(state, action) {
  if (action.type === 'updateField') {
    return { ...state, [action.name]: action.value }
  }

  if (action.type === 'updateSelect') {
    const newArr = action.value.map(({ value }) => value)
    return { ...state, [action.name]: newArr }
  }

  if (action.type === 'updateTranslations') {
    if (state.translations.includes(action.name)) {
      const translations = [...state.translations].filter(
        lang => lang !== action.name
      )
      return { ...state, translations }
    }

    return { ...state, translations: [...state.translations, action.name] }
  }

  if (action.type === 'setInit') {
    if (action.value?.textTranslations) {
      const translations = [...action.value?.textTranslations].map(
        ({ lang }) => lang
      )

      return { ...state, ...action.value, translations }
    }
    return { ...state, ...action.value }
  }

  if (action.type === 'reset') {
    return { ...state, ...initialState }
  }
}

const UpsertHelpTextForm = ({ showForm, setHideForm, itemToEdit }) => {
  const [showGenerateForm, setShowGenerateForm] = useState(false)
  const [tagsOptions, setTagsOptions] = useState([])

  const [state, dispatch] = useReducer(reducer, initialState)
  const reduxDispatch = useDispatch()

  const isEditForm = itemToEdit?.hasOwnProperty('helpTextId') && showForm
  const helpTextUpserted = getHelpTextUpserted()
  const tags = getFyiTags()
  const banner = getShowBanner()

  useEffect(() => {
    if (helpTextUpserted?.hasOwnProperty('helpTextId')) {
      reduxDispatch(cleanHelpText())
      setHideForm()
    }
  }, [helpTextUpserted, setHideForm, reduxDispatch])

  useEffect(() => {
    if (tags?.length > 0) {
      const formattedTagOptions = getTagOptions(tags)
      setTagsOptions(formattedTagOptions)
    }
  }, [tags?.length, tags])

  if (isEditForm && !state.helpTextId) {
    dispatch({ type: 'setInit', value: itemToEdit })
  }

  if (!showForm && state.title) {
    dispatch({ type: 'reset' })
  }

  function setOpenGenerateForm() {
    setShowGenerateForm(true)
  }
  function setCloseGenerateForm() {
    setShowGenerateForm(false)
  }

  function onChangeInput({ target }) {
    const { value, name } = target
    dispatch({ type: 'updateField', value, name })

    if (banner.show) {
      reduxDispatch(showBanner({ show: false, message: '', type: '' }))
    }
  }

  function onChangeSelect(selectedItems, { name }) {
    dispatch({ type: 'updateSelect', value: selectedItems, name })
  }

  function onChangeCheckbox({ target }) {
    const { checked, name } = target
    dispatch({ type: 'updateTranslations', value: checked, name })
  }

  function setHelpText(newText) {
    dispatch({ type: 'updateField', value: newText, name: 'text' })
  }

  async function onSubmitForm() {
    try {
      await helpTextSchema.validate({ ...state }, { abortEarly: true })
      if (!isEditForm) {
        reduxDispatch(
          createTooltipHelpText({
            title: state.title,
            text: state.text,
            tags: state.tags,
            targetLangs: state.translations
          })
        )
      }

      if (isEditForm) {
        reduxDispatch(
          updateTooltipHelpText({
            helpTextId: state.helpTextId,
            title: state.title,
            text: state.text,
            tags: state.tags
          })
        )
      }
    } catch (err) {
      reduxDispatch(
        showBanner({ show: true, message: err.message, type: 'error' })
      )
    }
  }

  return (
    <Fragment>
      <Dialog
        className='ZoneDetails__OffCanvas'
        type='offcanvas'
        open={showForm}
        onOpenChange={setHideForm}
      >
        <Slot name='title'>
          <Text size={400} fontWeight={700}>
            {isEditForm ? strings.editHelpText : strings.addHelpText}
          </Text>
        </Slot>
        <Slot name='content'>
          <Text size={200} as='p' variant='page'>
            {isEditForm
              ? strings.editHelpTextSubheading
              : strings.addHelpTextSubheading}
          </Text>
          <Box>
            <LineSeparator />
          </Box>
          {isEditForm && (
            <Label>
              <Text>{strings.tooltipIdColHeader}</Text>
              <Input
                name='helpTextId'
                onChange={onChangeInput}
                value={state.helpTextId}
                disabled
              />
            </Label>
          )}
          <Label>
            <Text>{strings.title}</Text>
            <Input name='title' onChange={onChangeInput} value={state.title} />
          </Label>
          <Label>
            <Text>{strings.helpTextEng}</Text>
            <Input
              name='text'
              as='textarea'
              onChange={onChangeInput}
              value={state.text}
            />
            <Flex alignMainAxis='flex-end' style={{ marginTop: '0.6rem' }}>
              <Button size='small' variant='info' onClick={setOpenGenerateForm}>
                <Text>{strings.generateFromPrompt}</Text>
              </Button>
            </Flex>
          </Label>
          <Label>
            <Text>{strings.tags}</Text>
            <Select
              name='tags'
              value={state.tags}
              options={tagsOptions}
              placeholder={strings.selectTags}
              controlStyles={{ marginTop: '0.5rem' }}
              onChange={onChangeSelect}
              isMulti={true}
            />
          </Label>
          {!isEditForm && (
            <Fragment>
              <Text style={{ margin: '2rem 0 0.7rem' }}>
                {strings.generateTranslations}
              </Text>
              <Flex axisGap={700}>
                {languageOptions.map(lang => (
                  <Label key={lang} style={{ marginBlockStart: '0' }}>
                    <Input
                      name={lang}
                      type='checkbox'
                      onChange={onChangeCheckbox}
                      checked={state.translations.includes(lang)}
                    />
                    <Text fontWeight={300}>{strings[lang]}</Text>
                  </Label>
                ))}
              </Flex>
            </Fragment>
          )}
          {isEditForm && state.translations?.length > 0 && (
            <Fragment>
              <Text
                size={100}
                variant='page'
                tone={800}
                style={{ margin: '2rem 0 0.7rem' }}
              >
                {strings.translationsToBeUpdated}
              </Text>
              {state.translations.map(lang => (
                <Text
                  key={lang}
                  fontWeight={300}
                  style={{ marginBottom: '0.3rem' }}
                >
                  {strings[lang]}
                </Text>
              ))}
            </Fragment>
          )}
        </Slot>
        <Slot name='actions'>
          <Flex alignMainAxis='space-between'>
            <Button size='small' variant='error' onClick={setHideForm}>
              {strings.cancel}
            </Button>
            <Button size='small' variant='primary' onClick={onSubmitForm}>
              {strings.submit}
            </Button>
          </Flex>
        </Slot>
      </Dialog>
      <GenerateTextForm
        showForm={showGenerateForm}
        setHideForm={setCloseGenerateForm}
        setHelpText={setHelpText}
      />
    </Fragment>
  )
}

export default UpsertHelpTextForm

import { call, put, takeLatest } from 'redux-saga/effects'

import {
  createFertilizerInventoryCalibration,
  getFertilizerInventoryCalibrations
} from '@/api/operations/fertilizerInventoryCalibration'

import {
  REQUEST_FERTILIZER_INVENTORY_CALIBRATIONS,
  CREATE_FERTILIZER_INVENTORY_CALIBRATION,
  receiveFertilizerInventoryCalibrations,
  fertilizerInventoryCalibrationCreated
} from '@/actions/operations/fertilizerInventoryCalibration'

import { displayBanner } from '../utils'

import Strings from './Strings'

const strings = Strings()

function* sendCreateFertilizerInventoryCalibration(action) {
  const { data, error = null } = yield call(
    createFertilizerInventoryCalibration,
    action.params
  )
  yield put(displayBanner(error, strings.fertilizerInventoryCalibrationCreated))
  yield put(fertilizerInventoryCalibrationCreated(data, error))
}

export function* watchCreateFertilizerInventoryCalibration() {
  yield takeLatest(
    CREATE_FERTILIZER_INVENTORY_CALIBRATION,
    sendCreateFertilizerInventoryCalibration
  )
}

function* sendRequestFertilizerInventoryCalibrations(action) {
  const { data, error = null } = yield call(
    getFertilizerInventoryCalibrations,
    action.params
  )
  const { fertilizerInventoryCalibrations = [], count = 0 } = data
  yield put(
    receiveFertilizerInventoryCalibrations(
      fertilizerInventoryCalibrations,
      count,
      error
    )
  )
}

export function* watchRequestFertilizerInventoryCalibrations() {
  yield takeLatest(
    REQUEST_FERTILIZER_INVENTORY_CALIBRATIONS,
    sendRequestFertilizerInventoryCalibrations
  )
}

import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { I18n } from 'aws-amplify'

import { Text, Box, Grid, Item } from '@/primitives'

import { setUseSimplifiedByLocation } from '@/slices/management/dashboard'

import './index.scss'

export default function DesktopToggle({ useSimplified }) {
  const [disabled, setDisabled] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  const toggleDashboardView = () => {
    if (!disabled) {
      const id = history.location.pathname.split('/').pop()
      dispatch(setUseSimplifiedByLocation({ id, value: !useSimplified }))
      setDisabled(true)
      setTimeout(() => {
        setDisabled(false)
      }, 2000)
    }
  }

  return (
    <Box
      className={`Toggle__Dashboard ${disabled ? 'disabled' : ''}`}
      wrap='nowrap'
      direction={useSimplified ? 'row-reverse' : 'row'}
      onClick={toggleDashboardView}
    >
      <Box style={{ width: '100%', position: 'relative' }}>
        <Box
          className='Toggle__Box'
          style={{ marginInlineStart: useSimplified ? '0.1rem' : '50%' }}
        ></Box>
      </Box>
      <Grid templateColumns='1fr 1fr'>
        <Item>
          <Text tone={useSimplified ? 300 : 800} variant='page'>
            {I18n.get('Simple')}
          </Text>
        </Item>
        <Item>
          <Text tone={useSimplified ? 800 : 300} variant='page'>
            {I18n.get('Custom')}
          </Text>
        </Item>
      </Grid>
    </Box>
  )
}

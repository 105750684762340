import { makeActionCreator } from '@/Util/ReduxUtils'

export const REQUEST_ALL_GRADES = 'REQUEST_ALL_GRADES'
export const requestAllGrades = makeActionCreator(REQUEST_ALL_GRADES)

export const RECEIVE_ALL_GRADES = 'RECEIVE_ALL_GRADES'
export const receiveAllGrades = makeActionCreator(
  RECEIVE_ALL_GRADES,
  'grades',
  'count',
  'error'
)

export const REQUEST_GRADES = 'REQUEST_GRADES'
export const requestGrades = makeActionCreator(REQUEST_GRADES, 'params')

export const RECEIVE_GRADES = 'RECEIVE_GRADES'
export const receiveGrades = makeActionCreator(
  RECEIVE_GRADES,
  'grades',
  'count',
  'error'
)

export const CREATE_GRADE = 'CREATE_GRADE'
export const createGrade = makeActionCreator(CREATE_GRADE, 'params')

export const GRADE_CREATED = 'GRADE_CREATED'
export const gradeCreated = makeActionCreator(GRADE_CREATED, 'grade', 'error')

export const UPDATE_GRADE = 'UPDATE_GRADE'
export const updateGrade = makeActionCreator(UPDATE_GRADE, 'params')

export const GRADE_UPDATED = 'GRADE_UPDATED'
export const gradeUpdated = makeActionCreator(GRADE_UPDATED, 'grade', 'error')

export const REQUEST_GRADE = 'REQUEST_GRADE'
export const requestGrade = makeActionCreator(REQUEST_GRADE, 'params')

export const RECEIVE_GRADE = 'RECEIVE_GRADE'
export const receiveGrade = makeActionCreator(RECEIVE_GRADE, 'grade', 'error')

export const CLEAR_GRADE = 'CLEAR_GRADE'
export const clearGrade = makeActionCreator(CLEAR_GRADE)

export const CLEAR_GRADES = 'CLEAR_GRADES'
export const clearGrades = makeActionCreator(CLEAR_GRADES)

export const SET_GRADE = 'SET_GRADE'
export const setGrade = makeActionCreator(SET_GRADE, 'grade')

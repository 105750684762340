import { updateList } from '../util'

import {
  REQUEST_ALL_CURRENCIES,
  RECEIVE_ALL_CURRENCIES,
  REQUEST_CURRENCIES,
  RECEIVE_CURRENCIES,
  CLEAR_CURRENCIES,
  CLEAR_CURRENCY,
  REQUEST_CURRENCY,
  RECEIVE_CURRENCY,
  CREATE_CURRENCY,
  CURRENCY_CREATED,
  UPDATE_CURRENCY,
  CURRENCY_UPDATED,
  SET_CURRENCY
} from '@/actions/operations/currency'

export const currencyInitialState = {
  allCurrencies: [],
  currencies: [],
  currency: {},
  currenciesCount: 0,
  loading: false,
  error: null
}

const handlers = {
  [SET_CURRENCY]: (state, action) => ({ ...state, currency: action.currency }),
  [CLEAR_CURRENCY]: (state, action) => ({
    ...state,
    currency: {},
    error: null
  }),
  [CLEAR_CURRENCIES]: (state, action) => ({
    ...state,
    currencies: [],
    error: null
  }),
  [REQUEST_ALL_CURRENCIES]: state => ({
    ...state,
    error: null,
    loading: true
  }),
  [RECEIVE_ALL_CURRENCIES]: (state, action) => ({
    ...state,
    allCurrencies: !action.error ? action.currencies : [],
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_CURRENCY]: (state, action) => ({
    ...state,
    currency: {},
    error: null,
    loading: true
  }),
  [RECEIVE_CURRENCY]: (state, action) => ({
    ...state,
    currency: !action.error ? action.currency : state.currency,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_CURRENCIES]: (state, action) => ({
    ...state,
    currencies: [],
    error: null,
    loading: true
  }),
  [RECEIVE_CURRENCIES]: (state, action) => ({
    ...state,
    currencies: !action.error ? action.currencies : [],
    currenciesCount: !action.error ? action.count : 0,
    error: action.error ?? null,
    loading: false
  }),
  [CREATE_CURRENCY]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [CURRENCY_CREATED]: (state, action) => ({
    ...state,
    currency: !action.error ? action.currency : state.currency,
    currencies: updateList(
      action.error,
      state.currencies,
      action.currency,
      true
    ),
    allCurrencies: updateList(
      action.error,
      state.allCurrencies,
      action.currency,
      true
    ),
    currenciesCount: !action.error
      ? state.currenciesCount + 1
      : state.currenciesCount,
    error: action.error ?? null,
    loading: false
  }),
  [UPDATE_CURRENCY]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [CURRENCY_UPDATED]: (state, action) => ({
    ...state,
    currency: !action.error ? action.currency : state.currency,
    currencies: updateList(
      action.error,
      state.currencies,
      action.currency,
      false
    ),
    allCurrencies: updateList(
      action.error,
      state.allCurrencies,
      action.currency,
      false
    ),
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

import { useParams } from 'react-router-dom'

import DetailsContainer from '../Details/Container'

import ZoneUtils from '@/Util/ZoneUtils'

export default function ZoneDetails({ selectedZone, setBreadcrumbs }) {
  const params = useParams()
  const basePath = ZoneUtils.getZoneBasePath(params)
  return (
    <DetailsContainer
      basePath={basePath}
      selectedZone={selectedZone}
      setBreadcrumbs={setBreadcrumbs}
    />
  )
}

import { useEffect, useState, Children } from 'react'

import UpsertDeviceType from './UpsertDeviceType'

import {
  Card,
  Grid,
  Item,
  Slot,
  Text,
  FlexV2,
  Button,
  Symbol,
  Box,
  Separator,
  Loader
} from '@/primitives'

import { Select, SearchBar } from '@/elements'

import {
  getDeviceTypes,
  getCurrentUser,
  getDeviceTypeTypes,
  getDeviceManagementInitLoading
} from '@/reducers/selectors'

import useMediaQuery from '@/hooks/useMediaQuery'

import { byName } from './utils'
import {
  hasDeviEditPermissions,
  generateTypesOptions,
  deviceTypesTranslations
} from '../utils'

import Strings from '../../Strings'

import './index.scss'

const DeviceTypes = () => {
  const coretexUser = getCurrentUser()
  const deviceTypes = getDeviceTypes()
  const deviceTypeTypes = getDeviceTypeTypes()
  const isLoading = getDeviceManagementInitLoading()

  const isAboveBreakpoint = useMediaQuery('min-width: 600px')

  const strings = Strings()

  const [hasEditPermissions, setHasEditPermissions] = useState(false)
  const [showUpsertModal, setShowUpsertModal] = useState(false)
  const [selectedDeviceType, setSelectedDeviceType] = useState({})
  const [typeFilter, setTypeFilter] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [filteredDeviceTypes, setFilteredDeviceTypes] = useState([])
  const [typesOptions, setTypesOptions] = useState([])

  const onToggleModal = () => {
    if (showUpsertModal) setSelectedDeviceType({})
    setShowUpsertModal(!showUpsertModal)
  }

  const onClickEdit = e => {
    setSelectedDeviceType(filteredDeviceTypes[e.target.value])
    onToggleModal()
  }

  const onSelectOption = option => {
    setTypeFilter(option?.value)
  }

  const onUpdateSearchText = text => {
    setSearchText(text.toLowerCase())
  }

  useEffect(() => {
    if (deviceTypeTypes) {
      const options = generateTypesOptions(deviceTypeTypes)
      setTypesOptions(options)
    }
  }, [deviceTypeTypes])

  useEffect(() => {
    if (coretexUser) {
      const canEdit = hasDeviEditPermissions(coretexUser)
      setHasEditPermissions(canEdit)
    }
  }, [coretexUser])

  useEffect(() => {
    let newDeviceTypes = [...deviceTypes]

    if (searchText) {
      const filteredBySearch = deviceTypes?.filter(deviceType => {
        const { name, id } = deviceType

        const searchedName =
          name?.toLowerCase().includes(searchText) ||
          id?.toLowerCase().includes(searchText)
        return deviceTypesTranslations[searchedName] || searchedName
      })
      newDeviceTypes = filteredBySearch
    }

    if (typeFilter) {
      const filteredByType = newDeviceTypes?.filter(
        ({ type }) => type === typeFilter
      )
      newDeviceTypes = filteredByType
    }

    setFilteredDeviceTypes(newDeviceTypes)
  }, [typeFilter, deviceTypes, searchText])

  return (
    <Loader isLoading={isLoading}>
      <FlexV2 direction='column' axisGap={400} className={'DeviceTypes'}>
        <FlexV2
          direction={isAboveBreakpoint ? 'row' : 'column'}
          axisGap={400}
          alignCrossAxis={isAboveBreakpoint ? 'center' : 'stretch'}
          alignMainAxis={isAboveBreakpoint ? 'space-between' : null}
        >
          <Box>
            <Text size={300} fontWeight={600}>
              {strings?.deviceTypesTab}
            </Text>
            <br />
            <Text variant='page' tone={900}>
              {strings?.deviceTypesTabSubheading}
            </Text>
          </Box>
          {hasEditPermissions && (
            <Button onClick={onToggleModal} variant='primary' size='small'>
              {strings?.addDeviceType}
            </Button>
          )}
        </FlexV2>

        <Separator />

        <FlexV2 direction={isAboveBreakpoint ? 'row' : 'column'} axisGap={400}>
          <FlexV2 axisGap={400} alignCrossAxis='center'>
            {isAboveBreakpoint && <Symbol name='filter_list' />}
            <Box style={{ flexGrow: 1 }}>
              <Select
                isClearable={true}
                value={typeFilter}
                placeholder={strings?.filterByType}
                options={typesOptions}
                onChange={onSelectOption}
              />
            </Box>
          </FlexV2>

          <FlexV2 axisGap={400} alignCrossAxis='center' style={{ flexGrow: 1 }}>
            {isAboveBreakpoint && <Symbol name='search' />}
            <Box style={{ flexGrow: 1 }}>
              <SearchBar
                onUpdateSearchText={onUpdateSearchText}
                placeholderText={strings?.searchByName}
              />
            </Box>
          </FlexV2>
        </FlexV2>

        <Separator />

        {filteredDeviceTypes?.length > 0 ? (
          <Grid gap={400}>
            {filteredDeviceTypes
              ?.sort(byName)
              .map(({ name, type, measurements, id }, index) => {
                return (
                  <Item key={id}>
                    <Card>
                      <Slot name='body'>
                        <FlexV2
                          direction='column'
                          axisGap={400}
                          alignMainAxis='space-between'
                        >
                          <Box>
                            <Text
                              fontFamily='mono'
                              textTransform='capitalize'
                              tone={700}
                              variant='page'
                              size={100}
                              fontWeight={500}
                            >
                              {type}
                            </Text>
                            <br />
                            <Text
                              truncate={20}
                              variant='page'
                              size={300}
                              fontWeight={500}
                            >
                              {name}
                            </Text>
                          </Box>
                          {measurements?.length > 0 && (
                            <Box className='StatList'>
                              <FlexV2 axisGap={200} wrap='wrap'>
                                {Children.toArray(
                                  measurements
                                    .filter(measurement => !!measurement)
                                    .sort()
                                    .map(measurement => (
                                      <Text
                                        size={100}
                                        textTransform='capitalize'
                                        tone={800}
                                        variant='page'
                                        className='StatList__Item'
                                      >
                                        {measurement.replace(/_/g, ' ')}
                                      </Text>
                                    ))
                                )}
                              </FlexV2>
                            </Box>
                          )}
                          {hasEditPermissions && (
                            <Box>
                              <Button
                                size='small'
                                value={index}
                                variant='primary'
                                onClick={onClickEdit}
                                className='edit-device-type-btn'
                              >
                                {strings?.editButton}
                              </Button>
                            </Box>
                          )}
                        </FlexV2>
                      </Slot>
                    </Card>
                  </Item>
                )
              })}
          </Grid>
        ) : (
          <FlexV2 axisGap={400}>
            <Symbol name='shelves' variant='page' tone={700} />
            <Text size={200} fontWeight={600} variant='page' tone={700}>
              {strings?.noDeviceTypes}
            </Text>
          </FlexV2>
        )}

        {showUpsertModal && (
          <UpsertDeviceType
            showUpsertModal={showUpsertModal || false}
            onToggleModal={onToggleModal}
            selectedDeviceType={selectedDeviceType}
          />
        )}
      </FlexV2>
    </Loader>
  )
}

export default DeviceTypes

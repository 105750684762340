import { nanoid } from 'nanoid'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'

import { fetchDataset } from '@/actions/ts/dashboard'
import { getDashboardData, getZoneHierarchyLoading } from '@/reducers/selectors'
import TimeUtils from '@/Util/TimeUtils'

import { useDashboard } from '@/components/DashboardPageV2/Desktop/context'
import { useDashboardParams } from '@/components/DashboardPageV2/utils/useDashboardParams'
import {
  getIntervals,
  getRanges
} from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'

const normalizeZones = zones => {
  return zones.map(({ id, name }) => ({ zoneId: id, zoneName: name }))
}

export default function useDatasetData({
  measurements = null,
  range = null,
  interval = null,
  queryZones = null,
  includeSensor = false,
  requestParams = null
}) {
  const stringifiedMeasurements = JSON.stringify(measurements)
  const stringifiedRequestParams = JSON.stringify(requestParams)

  if (!measurements || !range || !interval) {
    throw new Error('useDatasetData requires measurements, range, and interval')
  }

  const dispatch = useDispatch()
  const dashboardData = getDashboardData()
  const { state } = useDashboard()
  const dashboardParams = useDashboardParams()
  const zoneHierarchyLoading = getZoneHierarchyLoading()
  const { currentZone, siteId, hierarchy } = state

  const [localQueryZones, setLocalQueryZones] = useState(
    normalizeZones(queryZones ?? [])
  )
  const [datasetData, setDatasetData] = useState(null)
  const [shouldFetch, setShouldFetch] = useState(false)

  useEffect(() => {
    if (
      currentZone &&
      siteId &&
      hierarchy &&
      !zoneHierarchyLoading &&
      shouldFetch
    ) {
      if (localQueryZones.length > 0) {
        const requestParams = JSON.parse(stringifiedRequestParams)
        const measurements = JSON.parse(stringifiedMeasurements)
        const timeZone = hierarchy?.timeZone ?? 'UTC'
        const fromDate = TimeUtils.initStartDateTVariant(
          timeZone,
          getRanges(range)?.query[0],
          getRanges(range)?.query[1]
        )
        const toDate = TimeUtils.initEndDateT(timeZone)

        const params = {
          organizationId: hierarchy.organizationId,
          siteId: hierarchy.id,
          table: siteId,
          zones: localQueryZones.map(zone => zone.zoneId),
          measurements: measurements,
          fromDate: TimeUtils.formatToUTC(fromDate),
          toDate: TimeUtils.formatToUTC(toDate),
          interval: getIntervals(interval)?.query,
          rangeFilter: range,
          intervalFilter: interval,
          ...requestParams // this will override any of the above params
        }

        if (includeSensor) {
          params.sensorId = dashboardParams.sensorId || currentZone.id
        }

        const zoneArr = dashboardParams.zone.split('/')
        const zoneId =
          zoneArr[zoneArr.length - 1] === '' ? zoneArr.at(-2) : zoneArr.at(-1)
        if (currentZone.id === zoneId && shouldFetch) {
          dispatch(fetchDataset(params, nanoid(5)))
          setShouldFetch(false)
        }
      }
    }
  }, [
    currentZone,
    siteId,
    hierarchy,
    localQueryZones,
    zoneHierarchyLoading,
    dispatch,
    includeSensor,
    interval,
    stringifiedMeasurements,
    range,
    stringifiedRequestParams,
    shouldFetch,
    dashboardParams.zone
  ])

  useEffect(() => {
    const innerZones = Object.values(currentZone.children).filter(
      ({ status }) => status === 'active'
    )
    const zoneArr = dashboardParams.zone.split('/')
    const zoneId =
      zoneArr[zoneArr.length - 1] === '' ? zoneArr.at(-2) : zoneArr.at(-1)
    if (currentZone.id === zoneId) {
      if (innerZones.length > 0) {
        setLocalQueryZones(normalizeZones(innerZones))
      } else {
        setLocalQueryZones(normalizeZones([currentZone]))
      }
      setShouldFetch(true)
    }
  }, [
    currentZone,
    currentZone.children,
    currentZone.id,
    queryZones,
    dashboardParams
  ])

  // Adjust the state directly during rendering

  useEffect(() => {
    if (dashboardData) {
      const measurements = JSON.parse(stringifiedMeasurements)
      const clonedDashboardData = cloneDeep(dashboardData)

      let data = {}
      for (let measurement of measurements) {
        if (!data[measurement]) {
          data[measurement] = {}
        }
        for (let zone of localQueryZones) {
          let zoneData =
            clonedDashboardData?.[zone.zoneId]?.[measurement]?.dataset?.[
              range
            ]?.[interval]

          if (zoneData?.length > 0) {
            data[measurement][zone.zoneName] = zoneData
          }
        }

        if (dashboardParams.sensorId) {
          let sensorData =
            clonedDashboardData?.[dashboardParams.sensorId]?.[measurement]
              ?.dataset?.[range]?.[interval]
          if (sensorData?.length > 0) {
            data[measurement][dashboardParams.sensorId] = sensorData
          }
        }
      }

      setDatasetData(data)
    }
  }, [dashboardData, interval, localQueryZones, stringifiedMeasurements, range])

  return {
    datasetData,
    queryZones: localQueryZones,
    sensorId: dashboardParams.sensorId
  }
}

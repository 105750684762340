import { call, put, select, takeLatest } from 'redux-saga/effects'

import {
  createShippingMethod,
  getShippingMethod,
  getShippingMethods,
  updateShippingMethod
} from '@/api/operations/shippingMethod'

import {
  REQUEST_ALL_SHIPPING_METHODS,
  REQUEST_SHIPPING_METHODS,
  REQUEST_SHIPPING_METHOD,
  CREATE_SHIPPING_METHOD,
  UPDATE_SHIPPING_METHOD,
  receiveAllShippingMethods,
  receiveShippingMethods,
  receiveShippingMethod,
  shippingMethodCreated,
  shippingMethodUpdated
} from '@/actions/operations/shippingMethod'

import { displayBanner } from '../utils'
import { getOperationsAllShippingMethods } from './utils'

import Strings from './Strings'

const strings = Strings()

function* sendCreateShippingMethod(action) {
  const { data, error = null } = yield call(createShippingMethod, action.params)
  yield put(displayBanner(error, strings.shippingMethodCreated))
  yield put(shippingMethodCreated(data, error))
}

export function* watchCreateShippingMethod() {
  yield takeLatest(CREATE_SHIPPING_METHOD, sendCreateShippingMethod)
}

function* sendUpdateShippingMethod(action) {
  const { data, error = null } = yield call(updateShippingMethod, action.params)
  yield put(displayBanner(error, strings.shippingMethodUpdated))
  yield put(shippingMethodUpdated(data, error))
}

export function* watchUpdateShippingMethod() {
  yield takeLatest(UPDATE_SHIPPING_METHOD, sendUpdateShippingMethod)
}

function* sendRequestShippingMethods(action) {
  const { data, error = null } = yield call(getShippingMethods, action.params)
  const { shippingMethods = [], count = 0 } = data
  yield put(receiveShippingMethods(shippingMethods, count, error))
}

export function* watchRequestShippingMethods() {
  yield takeLatest(REQUEST_SHIPPING_METHODS, sendRequestShippingMethods)
}

function* sendRequestShippingMethod(action) {
  const { data: shippingMethod, error = null } = yield call(
    getShippingMethod,
    action.params
  )
  yield put(receiveShippingMethod(shippingMethod, error))
}

export function* watchRequestShippingMethod() {
  yield takeLatest(REQUEST_SHIPPING_METHOD, sendRequestShippingMethod)
}

function* sendRequestAllShippingMethods(action) {
  const allShippingMethods = yield select(getOperationsAllShippingMethods)
  if (allShippingMethods.length > 0) {
    yield put(
      receiveAllShippingMethods(
        allShippingMethods,
        allShippingMethods.length,
        null
      )
    )
  } else {
    const { data, error = null } = yield call(getShippingMethods, {})
    const { shippingMethods = [], count = 0 } = data
    yield put(receiveAllShippingMethods(shippingMethods, count, error))
  }
}

export function* watchRequestAllShippingMethods() {
  yield takeLatest(REQUEST_ALL_SHIPPING_METHODS, sendRequestAllShippingMethods)
}

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { isLoading } from '@/slices/util'
import { getPermissions } from '@/api/management/permissions'

export const fetchPermissions = createAsyncThunk(
  'fetchPermissions',
  async (params, { dispatch }) => {
    dispatch(isLoading(true))
    const { permissions = [], error = null } = await getPermissions()
    dispatch(isLoading(false))

    return { permissions, error }
  }
)

const managementPermissionSlice = createSlice({
  name: 'managementPermissionReducer',
  initialState: {
    permissions: [],
    permissionsError: null
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPermissions.pending, state => {
        state.permissionsError = null
      })
      .addCase(fetchPermissions.fulfilled, (state, action) => {
        const { permissions, error } = action?.payload
        state.permissions = error ? state.permissions : permissions
        state.permissionsError = error
      })
  }
})

const { reducer } = managementPermissionSlice

export default reducer

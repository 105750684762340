import { useParams } from 'react-router-dom'

import DevicesContainer from '../Devices/Container'

import ZoneUtils from '@/Util/ZoneUtils'

export default function ZoneDevices({ selectedZone }) {
  const params = useParams()
  const basePath = ZoneUtils.getZoneBasePath(params)
  return <DevicesContainer basePath={basePath} selectedZone={selectedZone} />
}

import { makeActionCreator } from '@/Util/ReduxUtils'

export const REQUEST_ALL_CURRENCIES = 'REQUEST_ALL_CURRENCIES'
export const requestAllCurrencies = makeActionCreator(REQUEST_ALL_CURRENCIES)

export const RECEIVE_ALL_CURRENCIES = 'RECEIVE_ALL_CURRENCIES'
export const receiveAllCurrencies = makeActionCreator(
  RECEIVE_ALL_CURRENCIES,
  'currencies',
  'count',
  'error'
)

export const REQUEST_CURRENCIES = 'REQUEST_CURRENCIES'
export const requestCurrencies = makeActionCreator(REQUEST_CURRENCIES, 'params')

export const RECEIVE_CURRENCIES = 'RECEIVE_CURRENCIES'
export const receiveCurrencies = makeActionCreator(
  RECEIVE_CURRENCIES,
  'currencies',
  'count',
  'error'
)

export const CREATE_CURRENCY = 'CREATE_CURRENCY'
export const createCurrency = makeActionCreator(CREATE_CURRENCY, 'params')

export const CURRENCY_CREATED = 'CURRENCY_CREATED'
export const currencyCreated = makeActionCreator(
  CURRENCY_CREATED,
  'currency',
  'error'
)

export const UPDATE_CURRENCY = 'UPDATE_CURRENCY'
export const updateCurrency = makeActionCreator(UPDATE_CURRENCY, 'params')

export const CURRENCY_UPDATED = 'CURRENCY_UPDATED'
export const currencyUpdated = makeActionCreator(
  CURRENCY_UPDATED,
  'currency',
  'error'
)

export const REQUEST_CURRENCY = 'REQUEST_CURRENCY'
export const requestCurrency = makeActionCreator(REQUEST_CURRENCY, 'params')

export const RECEIVE_CURRENCY = 'RECEIVE_CURRENCY'
export const receiveCurrency = makeActionCreator(
  RECEIVE_CURRENCY,
  'currency',
  'error'
)

export const CLEAR_CURRENCY = 'CLEAR_CURRENCY'
export const clearCurrency = makeActionCreator(CLEAR_CURRENCY)

export const CLEAR_CURRENCIES = 'CLEAR_CURRENCIES'
export const clearCurrencies = makeActionCreator(CLEAR_CURRENCIES)

export const SET_CURRENCY = 'SET_CURRENCY'
export const setCurrency = makeActionCreator(SET_CURRENCY, 'currency')

import { useState, useReducer, useEffect, useCallback, Fragment } from 'react'
import { I18n } from 'aws-amplify'
import { useDispatch } from 'react-redux'

import { Button, Flex, Form, Input, Slot, Text, Loader } from '@/primitives'

import { Dialog, Select } from '@/elements'

import {
  sendCreateFlashScriptsUploadUrls,
  sendCreateFlashScript,
  sendUpdateFlashScript,
  cleanFlashScript
} from '@/slices/deviceManagement/flashScript'
import { showBanner } from '@/slices/util'

import {
  getDeviceTypes,
  getShowBanner,
  getFlashScriptUpdated,
  getFlashScriptUploadData,
  getFlashScripts,
  getIsLoading
} from '@/reducers/selectors'

import AdminUtils from '@/Util/AdminUtils'

import Strings from '../../Strings'

import './index.scss'

const { newFlashScriptSchema } = AdminUtils

const initialState = {
  deviceType: 'envirosense',
  version: '',
  description: '',
  flash: {
    debug: {
      blob: null,
      key: null
    },
    release: {
      blob: null,
      key: null
    },
    releaseDebug: {
      blob: null,
      key: null
    }
  },
  reflash: {
    debug: {
      blob: null,
      key: null
    },
    release: {
      blob: null,
      key: null
    },
    releaseDebug: {
      blob: null,
      key: null
    }
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'input':
      let version = state.version
      if (action.name === 'name' && action.isAdd) {
        version = action.value.trim().replaceAll(' ', '_')
        return { ...state, [action.name]: action.value, version }
      }
      if (action.name === 'description') {
        return { ...state, [action.name]: action.value.slice(0, 255) }
      }
      return { ...state, [action.name]: action.value }
    case 'file':
      const { name, value } = action
      const [mode, type, blob] = name.split('-')
      let options = { ...state[mode] }
      if (!options[type]?.hasOwnProperty(blob)) {
        options[type] = { ...options[type], [blob]: null }
      }

      options[type][blob] = value
      return { ...state, [mode]: options }
    case 'select':
      return { ...state, [action.name]: action.value }
    case 'set-all':
      const flashScript = { ...action.value }
      return { ...state, ...flashScript }
    case 'reset':
      return { ...initialState }
    default:
      return { ...state }
  }
}

const UpsertDeviceType = ({
  showUpsertModal,
  onToggleModal,
  selectedFlashScript
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [isAdd, setIsAdd] = useState(false)

  const reduxDispatch = useDispatch()
  const deviceTypes = getDeviceTypes()
  const banner = getShowBanner()
  const flashScripts = getFlashScripts()
  const updatedFlashScript = getFlashScriptUpdated()
  const uploadData = getFlashScriptUploadData()
  const isLoading = getIsLoading()

  const strings = Strings()

  const cleanBanner = useCallback(() => {
    if (banner.show && banner.type === 'error')
      reduxDispatch(showBanner({ show: false, message: '', type: null }))
  }, [reduxDispatch, banner])

  useEffect(() => {
    reduxDispatch(sendCreateFlashScriptsUploadUrls())
  }, [reduxDispatch])

  useEffect(() => {
    if (selectedFlashScript?.hasOwnProperty('version')) {
      setIsAdd(false)
      dispatch({ type: 'set-all', value: selectedFlashScript })
    } else {
      setIsAdd(true)
    }
  }, [selectedFlashScript])

  useEffect(() => {
    return () => {
      cleanBanner()
    }
  }, [cleanBanner, banner.show])

  useEffect(() => {
    if (updatedFlashScript?.deviceType && updatedFlashScript?.version) {
      reduxDispatch(cleanFlashScript())
      const message = isAdd
        ? strings.flashScriptCreatedSuccessfully
        : strings.flashScriptUpdatedSuccessfully
      reduxDispatch(showBanner({ show: true, message, type: 'success' }))
      onToggleModal()
    }
  }, [reduxDispatch, onToggleModal, updatedFlashScript])

  const onSubmitForm = async e => {
    e.preventDefault()

    const params = {
      ...state,
      version: state.version.trim(),
      description: state.description.trim(),
      uploadData
    }

    try {
      if (isAdd) {
        await newFlashScriptSchema(flashScripts).validate(params, {
          abortEarly: true
        })
        reduxDispatch(sendCreateFlashScript(params))
      } else {
        reduxDispatch(sendUpdateFlashScript(params))
      }
    } catch (err) {
      const errorMessage = err?.message ?? err
      reduxDispatch(
        showBanner({
          show: true,
          message: errorMessage,
          type: 'error'
        })
      )
    }
  }

  const onChangeInput = ({ target }) => {
    const { name, type } = target
    const value = type === 'checkbox' ? target.checked : target.value
    dispatch({ type: 'input', name, value, isAdd })
    cleanBanner()
  }

  const onChangeFile = ({ target }) => {
    const { name } = target
    const value = target.files[0]
    dispatch({ type: 'file', name, value })
    cleanBanner()
  }

  const onChangeSelect = ({ name, value }) => {
    dispatch({ type: 'select', name, value })
    cleanBanner()
  }

  return (
    <Dialog
      open={showUpsertModal}
      onOpenChange={onToggleModal}
      type='offcanvas'
      className='flash-script'
      style={{ zIndex: 3 }}
    >
      <Slot name='title'>
        <Flex direction='column' style={{ marginBottom: '1em' }}>
          <Text as='h5' style={{ marginBottom: '0.5em' }}>
            {isAdd ? strings.addFlashScript : strings.editFlashScript}
          </Text>
          <Text as='p' size={100}>
            {isAdd ? strings.addFlashScriptSub : strings.editFlashScriptSub}
          </Text>
        </Flex>
      </Slot>
      <Slot name='content'>
        <Loader isLoading={isLoading}>
          <Form onSubmit={onSubmitForm}>
            <Flex direction='column' axisGap={500}>
              <Flex direction='column' axisGap={300}>
                <Text variant='page' tone={900} size={100}>
                  {strings.flashScriptDeviceType}
                </Text>
                <Select
                  name='deviceType'
                  value={state?.deviceType}
                  onChange={onChangeSelect}
                  isDisabled={!isAdd}
                  options={deviceTypes
                    .filter(({ hasFirmware }) => hasFirmware)
                    .map(type => ({
                      value: type.id,
                      label: type.name,
                      name: 'deviceType'
                    }))}
                />
              </Flex>
              <Flex direction='column' axisGap={300}>
                <Text variant='page' tone={900} size={100}>
                  {strings.flashScriptVersion}
                </Text>
                {isAdd && (
                  <Fragment>
                    <Input
                      name='name'
                      value={state?.version}
                      onChange={onChangeInput}
                    />
                    <Text
                      variant='warning'
                      tone={900}
                      size={100}
                      fontWeight={500}
                    >
                      {I18n.get('This cannot be changed later.')}
                    </Text>
                  </Fragment>
                )}
                {!isAdd && (
                  <Text variant='page' size={300}>
                    {state?.version}
                  </Text>
                )}
              </Flex>
              <Flex direction='column' axisGap={300}>
                <Text variant='page' tone={900} size={100}>
                  {strings.flashScriptDescription}
                </Text>
                <Input
                  as='textarea'
                  name='description'
                  value={state?.description}
                  onChange={onChangeInput}
                />
                <Text variant='page' tone={900} size={100}>
                  {I18n.get('Characters left: ')}(
                  {255 - state?.description.length})
                </Text>
              </Flex>
              <Flex direction='column' axisGap={300}>
                <Text variant='page' tone={900} size={100}>
                  {strings.flashScriptUploadFlashDebugFile}
                </Text>
                {selectedFlashScript?.flash?.debug?.url && (
                  <Flex>
                    <a href={selectedFlashScript?.flash?.debug?.url} download>
                      {selectedFlashScript?.flash?.debug?.key}
                    </a>
                  </Flex>
                )}
                <input
                  id='flash-debug-blob'
                  name='flash-debug-blob'
                  type='file'
                  multiple={false}
                  onChange={onChangeFile}
                />
              </Flex>
              <Flex direction='column' axisGap={300}>
                <Text variant='page' tone={900} size={100}>
                  {strings.flashScriptUploadFlashReleaseFile}
                </Text>
                {selectedFlashScript?.flash?.release?.url && (
                  <Flex>
                    <a href={selectedFlashScript?.flash?.release?.url} download>
                      {selectedFlashScript?.flash?.release?.key}
                    </a>
                  </Flex>
                )}
                <input
                  id='flash-release-blob'
                  name='flash-release-blob'
                  type='file'
                  multiple={false}
                  onChange={onChangeFile}
                />
              </Flex>
              <Flex direction='column' axisGap={300}>
                <Text variant='page' tone={900} size={100}>
                  {strings.flashScriptUploadFlashReleaseDebugFile}
                </Text>
                {selectedFlashScript?.flash?.releaseDebug?.url && (
                  <Flex>
                    <a
                      href={selectedFlashScript?.flash?.releaseDebug?.url}
                      download
                    >
                      {selectedFlashScript?.flash?.releaseDebug?.key}
                    </a>
                  </Flex>
                )}
                <input
                  id='flash-releaseDebug-blob'
                  name='flash-releaseDebug-blob'
                  type='file'
                  multiple={false}
                  onChange={onChangeFile}
                />
              </Flex>
            </Flex>

            <Flex alignMainAxis='space-between' className='device-type-buttons'>
              <Button variant='neutral' onClick={onToggleModal}>
                {strings.cancel}
              </Button>
              <Button variant='primary' type='submit'>
                {strings.save}
              </Button>
            </Flex>
          </Form>
        </Loader>
      </Slot>
    </Dialog>
  )
}

export default UpsertDeviceType

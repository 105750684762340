import { call, put, takeLatest, takeEvery } from 'redux-saga/effects'

import { receiveOperationsDashboardData } from '@/actions/operations/dashboard'

import {
  getHarvests,
  getHarvest,
  createHarvest,
  updateHarvest,
  deleteHarvest,
  getTotalHarvestProduce,
  getTotalHarvestVarietyByProduce,
  getAllVarietiesByHarvestDateAndProduce,
  getAllProducesByHarvestDate
} from '@/api/operations/harvest'

import {
  REQUEST_ALL_HARVESTS,
  REQUEST_HARVESTS,
  REQUEST_HARVEST,
  CREATE_HARVEST,
  UPDATE_HARVEST,
  DELETE_HARVEST,
  receiveAllHarvests,
  receiveHarvests,
  receiveHarvest,
  harvestCreated,
  harvestUpdated,
  harvestDeleted,
  REQUEST_TOTAL_HARVEST_PRODUCE,
  REQUEST_TOP_TEN_HARVEST_VARIETY,
  REQUEST_GET_ALL_PRODUCES_BY_HARVEST_DATE,
  REQUEST_GET_ALL_VARIETIES_BY_HARVEST_DATE_AND_PRODUCE
} from '@/actions/operations/harvest'

import { displayBanner } from '../utils'

import Strings from './Strings'
import { isLoading } from '@/slices/util'

const strings = Strings()

function* sendCreateHarvest(action) {
  const { data, error = null } = yield call(createHarvest, action.params)
  yield put(displayBanner(error, strings.harvestCreated))
  yield put(harvestCreated(data, error))
}

export function* watchCreateHarvest() {
  yield takeLatest(CREATE_HARVEST, sendCreateHarvest)
}

function* sendUpdateHarvest(action) {
  const { data, error = null } = yield call(updateHarvest, action.params)
  yield put(displayBanner(error, strings.harvestUpdated))
  yield put(harvestUpdated(data, error))
}

export function* watchUpdateHarvest() {
  yield takeLatest(UPDATE_HARVEST, sendUpdateHarvest)
}

function* sendDeleteHarvest(action) {
  const { data, error = null } = yield call(deleteHarvest, action.params)
  yield put(displayBanner(error, strings.harvestDeleted))
  yield put(harvestDeleted(data, error))
}

export function* watchDeleteHarvest() {
  yield takeLatest(DELETE_HARVEST, sendDeleteHarvest)
}

function* sendRequestAllHarvests(action) {
  const { data, error = null } = yield call(getHarvests, action.params)
  const { harvests = [], count = 0 } = data
  yield put(receiveAllHarvests(harvests, count, error))
}

export function* watchRequestAllHarvests() {
  yield takeLatest(REQUEST_ALL_HARVESTS, sendRequestAllHarvests)
}

function* sendRequestHarvests(action) {
  const { data, error = null } = yield call(getHarvests, action.params)
  const { harvests = [], count = 0 } = data
  yield put(receiveHarvests(harvests, count, error))
}

export function* watchRequestHarvests() {
  yield takeLatest(REQUEST_HARVESTS, sendRequestHarvests)
}

function* sendRequestHarvest(action) {
  const { data, error = null } = yield call(getHarvest, action.params)
  yield put(receiveHarvest(data, error))
}

export function* watchRequestHarvest() {
  yield takeLatest(REQUEST_HARVEST, sendRequestHarvest)
}

function* sendRequestTotalHarvestProduce(action) {
  yield put(isLoading(true))

  const { data, error } = yield call(getTotalHarvestProduce, action.params)
  yield put(receiveOperationsDashboardData(data, action.dashboardParams, error))
  yield put(isLoading(false))
}

export function* watchRequestTotalHarvestProduce() {
  yield takeEvery(REQUEST_TOTAL_HARVEST_PRODUCE, sendRequestTotalHarvestProduce)
}

function* sendRequestTopTenHarvestVariety(action) {
  yield put(isLoading(true))

  const { data, error } = yield call(
    getTotalHarvestVarietyByProduce,
    action.params
  )
  yield put(receiveOperationsDashboardData(data, action.dashboardParams, error))
  yield put(isLoading(false))
}

export function* watchRequestTopTenHarvestVariety() {
  yield takeEvery(
    REQUEST_TOP_TEN_HARVEST_VARIETY,
    sendRequestTopTenHarvestVariety
  )
}

function* sendRequestGetAllProducesByHarvestDate(action) {
  yield put(isLoading(true))

  const { data, error } = yield call(getAllProducesByHarvestDate, action.params)
  yield put(receiveOperationsDashboardData(data, action.dashboardParams, error))
  yield put(isLoading(false))
}

export function* watchRequestGetAllProducesByHarvestDate() {
  yield takeEvery(
    REQUEST_GET_ALL_PRODUCES_BY_HARVEST_DATE,
    sendRequestGetAllProducesByHarvestDate
  )
}

function* sendRequestGetAllVarietiesByHarvestDateAndProduce(action) {
  yield put(isLoading(true))

  const { data, error } = yield call(
    getAllVarietiesByHarvestDateAndProduce,
    action.params
  )
  yield put(receiveOperationsDashboardData(data, action.dashboardParams, error))
  yield put(isLoading(false))
}

export function* watchRequestGetAllVarietiesByHarvestDateAndProduce() {
  yield takeEvery(
    REQUEST_GET_ALL_VARIETIES_BY_HARVEST_DATE_AND_PRODUCE,
    sendRequestGetAllVarietiesByHarvestDateAndProduce
  )
}

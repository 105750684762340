import { call, put, takeLatest } from 'redux-saga/effects'

import {
  createSale,
  getSale,
  getSales,
  updateSale,
  deleteSaleChildren
} from '@/api/operations/sale'

import {
  createSaleShipment,
  updateSaleShipment,
  deleteSaleShipment
} from '@/api/operations/saleShipment'

import {
  getSaleItems,
  createSaleItem,
  updateSaleItem,
  deleteSaleItem
} from '@/api/operations/saleItem'

import {
  REQUEST_SALES,
  REQUEST_SALE,
  CREATE_SALE,
  UPDATE_SALE,
  DELETE_SALE_CHILDREN,
  CREATE_SALE_SHIPMENT,
  UPDATE_SALE_SHIPMENT,
  DELETE_SALE_SHIPMENT,
  CREATE_SALE_ITEM,
  UPDATE_SALE_ITEM,
  DELETE_SALE_ITEM,
  REQUEST_SALE_ITEMS,
  receiveSales,
  receiveSale,
  saleCreated,
  saleUpdated,
  saleChildrenDeleted,
  updateSaleInvoice,
  saleItemDeleted,
  saleShipmentDeleted,
  receiveSaleItems
} from '@/actions/operations/sale'

import { displayBanner } from '../utils'

import Strings from './Strings'

const strings = Strings()

function* sendCreateSale(action) {
  const { data, error = null } = yield call(createSale, action.params)
  yield put(displayBanner(error, strings.saleCreated))
  yield put(saleCreated(data, error))
}

export function* watchCreateSale() {
  yield takeLatest(CREATE_SALE, sendCreateSale)
}

function* sendUpdateSale(action) {
  const { data, error = null } = yield call(updateSale, action.params)
  yield put(displayBanner(error, strings.saleUpdated))
  yield put(saleUpdated(data, error))
}

export function* watchUpdateSale() {
  yield takeLatest(UPDATE_SALE, sendUpdateSale)
}

function* sendRequestSales(action) {
  const { data, error = null } = yield call(getSales, action.params)
  const { sales = [], count = 0 } = data
  yield put(receiveSales(sales, count, error))
}

export function* watchRequestSales() {
  yield takeLatest(REQUEST_SALES, sendRequestSales)
}

function* sendRequestSale(action) {
  const { data, error = null } = yield call(getSale, action.params)
  yield put(receiveSale(data, error))
}

export function* watchRequestSale() {
  yield takeLatest(REQUEST_SALE, sendRequestSale)
}

function* sendDeleteSaleChildren(action) {
  const { data, error = null } = yield call(deleteSaleChildren, action.params)
  yield put(displayBanner(error))
  yield put(saleChildrenDeleted(data, error))
}

export function* watchDeleteSaleChildren() {
  yield takeLatest(DELETE_SALE_CHILDREN, sendDeleteSaleChildren)
}

function* sendCreateSaleShipment(action) {
  const { data, error = null } = yield call(createSaleShipment, action.params)
  if (!error) yield put(updateSaleInvoice(data.sale))
  yield put(displayBanner(error))
}

export function* watchCreateSaleShipment() {
  yield takeLatest(CREATE_SALE_SHIPMENT, sendCreateSaleShipment)
}

function* sendUpdateSaleShipment(action) {
  const { data, error = null } = yield call(updateSaleShipment, action.params)
  if (!error) yield put(updateSaleInvoice(data.sale))
  yield put(displayBanner(error))
}

export function* watchUpdateSaleShipment() {
  yield takeLatest(UPDATE_SALE_SHIPMENT, sendUpdateSaleShipment)
}

function* sendDeleteSaleShipment(action) {
  const { data, error = null } = yield call(deleteSaleShipment, action.params)
  if (!error) yield put(updateSaleInvoice(data.sale))
  yield put(displayBanner(error))
  yield put(saleShipmentDeleted(data, error))
}

export function* watchDeleteSaleShipment() {
  yield takeLatest(DELETE_SALE_SHIPMENT, sendDeleteSaleShipment)
}

function* sendRequestSaleItems(action) {
  const { data, error = null } = yield call(getSaleItems, action.params)
  const { saleItems = [], count = 0 } = data
  yield put(receiveSaleItems(saleItems, count, error))
}

export function* watchRequestSaleItems() {
  yield takeLatest(REQUEST_SALE_ITEMS, sendRequestSaleItems)
}

function* sendCreateSaleItem(action) {
  const { data, error = null } = yield call(createSaleItem, action.params)
  if (!error) yield put(updateSaleInvoice(data.sale))
  yield put(displayBanner(error))
}

export function* watchCreateSaleItem() {
  yield takeLatest(CREATE_SALE_ITEM, sendCreateSaleItem)
}

function* sendUpdateSaleItem(action) {
  const { data, error = null } = yield call(updateSaleItem, action.params)
  if (!error) yield put(updateSaleInvoice(data.sale))
  yield put(displayBanner(error))
}

export function* watchUpdateSaleItem() {
  yield takeLatest(UPDATE_SALE_ITEM, sendUpdateSaleItem)
}

function* sendDeleteSaleItem(action) {
  const { data, error = null } = yield call(deleteSaleItem, action.params)
  if (!error) yield put(updateSaleInvoice(data.sale))
  yield put(displayBanner(error))
  yield put(saleItemDeleted(data, error))
}

export function* watchDeleteSaleItem() {
  yield takeLatest(DELETE_SALE_ITEM, sendDeleteSaleItem)
}

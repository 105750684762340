import { Text, Icon, FlexV2 } from '@/primitives'
import { getSensorTypeIcon } from '@/Util/DeviceUtils'

// the data prop comes from react-select Option component
export default function SensorFilterOption({ innerProps, data }) {
  const { sensorType, label, tag } = data

  return (
    <FlexV2
      className='DataboardFilters__ComparisonRow__Select--Sensor__Label'
      axisGap={300}
      direction='column'
      {...innerProps}
    >
      <FlexV2 alignCrossAxis='center' axisGap={200}>
        <Icon size={200} name={getSensorTypeIcon(sensorType)} />
        <Text className='DataboardFilters__ComparisonRow__Select--Sensor__Label__Text'>
          {label}
        </Text>
      </FlexV2>
      {tag && (
        <FlexV2
          className='DataboardFilters__ComparisonRow__Select--Sensor__Label__Tag'
          alignCrossAxis='center'
        >
          <Text
            className='DataboardFilters__ComparisonRow__Select--Sensor__Label__Tag__Text'
            tone={900}
            variant='page'
            size={100}
            fontWeight={600}
          >
            {tag}
          </Text>
        </FlexV2>
      )}
    </FlexV2>
  )
}

import isEqual from 'lodash/isEqual'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { I18n } from 'aws-amplify'
import history from '../../../history'
import {
  getDevice,
  createDevice,
  updateDevice,
  updateDeviceModbusConfig,
  deleteDevice,
  getDeviceFirmware,
  regenerateDeviceFirmware,
  getSupplierDevice,
  enrollSupplierDevice,
  unenrollSupplierDevice
} from '@/api/management/devices'
import { getZoneDevices } from '@/api/management/zones'
import {
  moveZoneDevice,
  updateZonesHierarchyAfterDeviceCreate
} from '@/slices/management/hierarchy'
import { showBanner } from '@/slices/util'
import Strings from '@/components/AdminPage/Strings'

import ArrayUtils from '@/Util/ArrayUtils'
import { deviceEnrolled } from '@/slices/supplier/enrollment'

export const fetchZoneDevices = createAsyncThunk(
  'fetchZoneDevices',
  async (params, { dispatch }) => {
    try {
      return await getZoneDevices(params)
    } catch (error) {
      throw error
    }
  }
)

export const fetchDevice = createAsyncThunk(
  'fetchDevice',
  async (params, { dispatch }) => {
    try {
      return await getDevice(params)
    } catch (error) {
      throw error
    }
  }
)

export const fetchSupplierDevice = createAsyncThunk(
  'fetchSupplierDevice',
  async (params, { dispatch }) => {
    try {
      return await getSupplierDevice(params)
    } catch (err) {
      dispatch(
        showBanner({
          type: 'error',
          show: true,
          message: err.message
        })
      )
      throw err
    }
  }
)

export const fetchDeviceFirmware = createAsyncThunk(
  'fetchDeviceFirmware',
  async params => {
    const firmware = await getDeviceFirmware(params)

    let link = document.createElement('a')
    link.href = firmware?.fileUrl
    link.download = firmware?.fileUrl
    document.body.appendChild(link)
    link.click()
    link.remove()
  }
)

export const sendRegenerateDeviceFirmware = createAsyncThunk(
  'regenerateDeviceFirmware',
  async (params, { dispatch }) => {
    try {
      await regenerateDeviceFirmware(params)
      dispatch(
        showBanner({
          type: 'success',
          show: true,
          message: I18n.get(
            'Regeneration of device firmware started successfully.'
          )
        })
      )
    } catch (error) {
      dispatch(
        showBanner({
          type: 'error',
          show: true,
          message: error
        })
      )
    }
  }
)

export const sendUpdateModbusConfig = createAsyncThunk(
  'sendUpdateModbusConfig',
  async (params, { dispatch }) => {
    try {
      return await updateDeviceModbusConfig(params)
    } catch (error) {
      dispatch(
        showBanner({
          type: 'error',
          show: true,
          message: error
        })
      )
      throw error
    }
  }
)

export const sendDeleteDevice = createAsyncThunk(
  'sendDeleteDevice',
  async (params, { dispatch }) => {
    const device = await deleteDevice(params)
    dispatch(
      showBanner({
        type: 'success',
        show: true,
        message: Strings().deviceDeleted
      })
    )
    return device
  }
)

export const sendEnrollSupplierDevice = createAsyncThunk(
  'sendEnrollSupplierDevice',
  async (params, { dispatch }) => {
    try {
      const device = await enrollSupplierDevice(params)
      dispatch(addDeviceToArray(device))
      dispatch(setDevice(device))
      // This runs after returning the value from the try block
      await handleDeviceUpdate({
        type: 'create',
        updatedDevice: device,
        params: { device: params },
        dispatch
      })

      dispatch(updateZonesHierarchyAfterDeviceCreate(device))
      dispatch(deviceEnrolled({ verified: true, enrolled: true, error: null }))
    } catch (err) {
      dispatch(
        showBanner({
          type: 'error',
          show: true,
          message: err?.message
        })
      )
      throw err
    }
  }
)

export const sendUnenrollSupplierDevice = createAsyncThunk(
  'sendUnenrollSupplierDevice',
  async (params, { dispatch, fulfillWithValue }) => {
    try {
      const device = await unenrollSupplierDevice(params)
      dispatch(deviceEnrolled({ verified: true, enrolled: false, error: null }))
      history.replace(`/enroll-device/${device.id}`)
    } catch (err) {
      dispatch(
        showBanner({
          type: 'error',
          show: true,
          message: err?.message
        })
      )
      throw err
    }
  }
)

export const sendCreateDevice = createAsyncThunk(
  'sendCreateDevice',
  async (params, { dispatch }) => {
    try {
      const device = await createDevice(params.device)

      dispatch(addDeviceToArray(device))
      dispatch(setDevice(device))

      await handleDeviceUpdate({
        type: 'create',
        updatedDevice: device,
        params,
        dispatch
      })

      dispatch(updateZonesHierarchyAfterDeviceCreate(device))
    } catch (err) {
      dispatch(
        showBanner({
          type: 'error',
          show: true,
          message: err
        })
      )
      throw err
    } finally {
    }
  }
)

export const sendUpdateDevice = createAsyncThunk(
  'sendUpdateDevice',
  async (params, { dispatch }) => {
    try {
      const device = await updateDevice(params.device)
      dispatch(updateDeviceInArray(device))
      dispatch(setDevice(device))
      await handleDeviceUpdate({
        type: 'update',
        updatedDevice: device,
        params,
        dispatch
      })
    } catch (err) {
      dispatch(
        showBanner({
          type: 'error',
          show: true,
          message: err
        })
      )
      throw err
    }
  }
)

const handleDeviceUpdate = async ({
  type,
  updatedDevice,
  params,
  dispatch
}) => {
  const isIot = params.device.isIoT
  const prevConfig = params.prevConfig
  const newConfig = params.device.config

  if (!isIot && !isEqual(prevConfig, newConfig)) {
    await dispatch(
      sendUpdateModbusConfig({
        deviceId: params.device.id,
        config: newConfig
      })
    )
  }

  if (params.deviceMove) {
    dispatch(moveZoneDevice({ ...params.deviceMove, device: updatedDevice }))
    dispatch(removeDeviceFromZoneDevices(updatedDevice))
  }

  const strings = Strings()

  dispatch(
    showBanner({
      type: 'success',
      show: true,
      message:
        type === 'create'
          ? strings.deviceCreatedSuccessfully
          : strings.deviceUpdatedSuccessfully
    })
  )
}

const updateDeviceArray = (devices, device, updateConfig) => {
  const devicesCopy = [...devices]
  if (device?.id) {
    const index = devices.findIndex(({ id }) => device.id === id)
    if (index > -1) {
      devicesCopy[index] = { ...devices[index], ...device }
      if (updateConfig) {
        devicesCopy[index]['config'] = device.config
      }
    }
  }
  return devicesCopy
}

const managementDevice = createSlice({
  name: 'managementDevice',
  initialState: {
    devices: [],
    device: {},
    supplierDevice: {},
    loading: false,
    error: null
  },
  reducers: {
    setDevice: (state, action) => {
      state.device = action.payload
    },
    updateDeviceInArray: (state, action) => {
      const device = action.payload
      state.devices = updateDeviceArray(state.devices, device)
    },
    addDeviceToArray: (state, action) => {
      const device = action.payload
      state.devices = [...state.devices, device]
    },
    removeDeviceFromZoneDevices: (state, action) => {
      const devices = [...state.devices]
      const index = devices.findIndex(({ id }) => action.payload.id === id)
      if (index > -1) {
        devices.splice(index, 1)
      }
      state.devices = devices
    },
    cleanSupplierDevice: (state, action) => {
      state.supplierDevice = {}
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchZoneDevices.pending, state => {
        state.loading = true
        state.devices = []
        state.error = null
      })
      .addCase(fetchZoneDevices.fulfilled, (state, action) => {
        state.devices = ArrayUtils.sortArrayByObjectKey(action.payload, 'name')
        state.loading = false
        state.error = null
      })
      .addCase(fetchZoneDevices.rejected, (state, action) => {
        state.devices = []
        state.loading = false
        state.error = action.error
      })

      .addCase(fetchSupplierDevice.pending, state => {
        state.loading = true
        state.supplierDevice = {}
        state.error = null
      })
      .addCase(fetchSupplierDevice.fulfilled, (state, action) => {
        state.supplierDevice = action.payload
        state.loading = false
        state.error = null
      })
      .addCase(fetchSupplierDevice.rejected, (state, action) => {
        state.supplierDevice = {}
        state.loading = false
        state.error = action.error
      })

      .addCase(sendCreateDevice.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(sendCreateDevice.fulfilled, (state, action) => {
        // The device gets updated manually using the updateDeviceInArray for a better flow control of the state
        state.loading = false
        state.error = null
      })
      .addCase(sendCreateDevice.rejected, (state, action) => {
        state.device = {}
        state.loading = false
        state.error = action.error
      })
      .addCase(fetchDevice.pending, state => {
        state.loading = true
        state.device = {}
        state.error = null
      })
      .addCase(fetchDevice.fulfilled, (state, action) => {
        state.device = action.payload
        state.loading = false
        state.error = null
      })
      .addCase(fetchDevice.rejected, (state, action) => {
        state.device = {}
        state.loading = false
        state.error = action.error
      })
      .addCase(fetchDeviceFirmware.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchDeviceFirmware.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
      })
      .addCase(fetchDeviceFirmware.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })
      .addCase(sendRegenerateDeviceFirmware.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(sendRegenerateDeviceFirmware.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
      })
      .addCase(sendRegenerateDeviceFirmware.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })
      .addCase(sendUpdateDevice.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(sendUpdateDevice.fulfilled, (state, action) => {
        // The device gets updated manually using the updateDeviceInArray for a better flow control of the state
        state.loading = false
        state.error = null
      })
      .addCase(sendUpdateDevice.rejected, (state, action) => {
        state.device = {}
        state.loading = false
        state.error = action.error
      })
      .addCase(sendUpdateModbusConfig.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(sendUpdateModbusConfig.fulfilled, (state, action) => {
        const updatedDevice = action.payload

        state.devices = updateDeviceArray(state.devices, updatedDevice, true)
        state.device = updatedDevice
        state.loading = false
        state.error = null
      })
      .addCase(sendUpdateModbusConfig.rejected, (state, action) => {
        state.device = {}
        state.loading = false
        state.error = action.error
      })
      .addCase(sendDeleteDevice.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(sendDeleteDevice.fulfilled, (state, action) => {
        state.device = {}
        state.devices = state.devices.filter(
          ({ id }) => id !== action.payload.id
        )
        state.loading = false
        state.error = null
      })
      .addCase(sendDeleteDevice.rejected, (state, action) => {
        state.device = {}
        state.loading = false
        state.error = action.error
      })
  }
})

export const {
  setDevice,
  removeDeviceFromZoneDevices,
  updateDeviceInArray,
  addDeviceToArray,
  cleanSupplierDevice
} = managementDevice.actions
export default managementDevice.reducer

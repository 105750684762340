import { useEffect, useReducer, useState } from 'react'

import { Text, Flex } from '@/primitives'
import Select from '@/elements/Select'

import {
  UPDATE_WIDGET,
  CANCEL_WIDGET_UPDATE
} from '@/components/DashboardPageV2/Desktop/state'
import { useDashboard } from '@/components/DashboardPageV2/Desktop/context'
import { getWidgetPositionByChart } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config/utils'
import EditLayout from '@/components/DashboardPageV2/Desktop/Widgets/Shared/EditLayout'
import { getValidationErrorMap } from '@/Util/GeneralUtils'
import { reactSelectStyles } from '@/components/DashboardPageV2/utils'
import {
  weatherEditTypes,
  validateQueryOptions,
  weatherEditInitalState,
  weatherEditReducer,
  createWeatherRangeOptions
} from './utils'
import { Strings } from '@/components/DashboardPageV2/Desktop/Widgets/Strings'

const SelectLabel = ({ label, error }) => {
  const text = error ? error : label
  const variant = error ? 'error' : 'page'
  const tone = error ? 500 : 800

  return (
    <Text variant={variant} tone={tone}>
      {text}
    </Text>
  )
}

/**
 * Default edit component for Measurement widgets
 * @param {Object} config - widget config
 * @param {String} config.range - range config
 * @param {String} config.interval - interval config
 * @param {Function} setWidgetMode - widget mode dispatcher
 * @param {Object} validationSchema - validation schema
 * @param {String} id - id
 * @param {String} widgetId - widgetId
 */
export function WeatherEditComponent({
  setWidgetMode,
  validationSchema = null,
  options,
  config,
  ...props
}) {
  const { state, dispatchState } = useDashboard()
  const [reducerInit, setReducerInit] = useState(false)
  const [errors, setErrors] = useState({})

  const [formState, dispatchLocalState] = useReducer(
    weatherEditReducer,
    weatherEditInitalState
  )

  const stringifiedConfig = JSON.stringify(config)

  useEffect(() => {
    const config = JSON.parse(stringifiedConfig)
    if (config && !reducerInit) {
      dispatchLocalState({
        type: weatherEditTypes.setAll,
        payload: {
          range: config.range,
          interval: config.interval
        }
      })
      setReducerInit(true)
    }
  }, [props, stringifiedConfig, reducerInit])

  const handleSave = async () => {
    try {
      const { props: widgetProps } = state.widgets.get(props.id)

      const newConfig = {
        ...widgetProps.config,
        ...formState
      }

      const nextProps = {
        ...widgetProps,
        config: newConfig
      }

      if (validationSchema) {
        await validationSchema.validate(newConfig, { abortEarly: false })
      } else {
        await validateQueryOptions().validate(newConfig, {
          abortEarly: false
        })
      }

      dispatchState({
        type: UPDATE_WIDGET,
        payload: {
          ...nextProps,
          position: getWidgetPositionByChart(
            nextProps,
            state.currentBreakpoint,
            widgetProps.config.chart
          )
        }
      })
      setWidgetMode('view')
    } catch (error) {
      setErrors(getValidationErrorMap(error))
    }
  }

  const handleCancel = () => {
    const { props: widgetProps } = state.widgets.get(props.id)

    dispatchState({
      type: CANCEL_WIDGET_UPDATE,
      payload: {
        id: props.id,
        position: getWidgetPositionByChart(widgetProps, state.currentBreakpoint)
      }
    })

    setWidgetMode('view')
  }

  const onSelectRange = option => {
    dispatchLocalState({
      type: weatherEditTypes.setRange,
      payload: option.value
    })
  }

  const strings = Strings()

  let rangeOptions = []

  rangeOptions = createWeatherRangeOptions(options?.range, strings.selectRange)

  const handleSelectRange = option => {
    onSelectRange(option)
    if (errors?.range || errors?.interval) {
      setErrors({ ...errors, range: '', interval: '' })
    }
  }

  return (
    <EditLayout id={props.id} onSave={handleSave} onCancel={handleCancel}>
      <Flex
        className='Dynamic_DefaultEdit'
        direction='column'
        alignCrossAxis='flex-start'
        alignMainAxis='space-around'
        wrap='nowrap'
        axisGap={300}
      >
        <Flex direction='column' axisGap={200}>
          <SelectLabel label={strings.selectRange} error={errors?.range} />
          <Select
            className='Dynamic_DefaultEdit__Select'
            style={{ width: '100%' }}
            options={rangeOptions}
            onChange={handleSelectRange}
            value={formState.range}
            controlStyles={reactSelectStyles}
            menuListStyles={reactSelectStyles}
          />
        </Flex>
      </Flex>
    </EditLayout>
  )
}

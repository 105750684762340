import React, { useContext, useState } from 'react'

const BreadCrumbsContext = React.createContext()

function BreadCrumbProvider(props) {
  const [breadCrumbs, setBreadCrumbs] = useState()

  return (
    <BreadCrumbsContext.Provider
      value={{ breadCrumbs, setBreadCrumbs }}
      {...props}
    />
  )
}

function useBreadCrumbs() {
  const context = useContext(BreadCrumbsContext)

  if (!context) {
    throw new Error('useBreadCrumbs must be used within a BreadCrumbProvider')
  }
  return context
}

export { BreadCrumbProvider, useBreadCrumbs }

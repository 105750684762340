import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { makeGraphqlRequestV2 } from '../utils'

export const getZonesHierarchy = async (params, timesRepeated = 0) => {
  try {
    // Adding greengrassCoreDevice to the query causes the query to occasionally timeout
    const query = /* GraphQL */ `
      query getZonesHierarchy {
        getZonesHierarchy {
          id
          name
          status
          code
          organizationId
          parentId
          parentPath
          latitude
          longitude
          timeZone
          level
          maintenanceMode
        }
      }
    `

    const operation = graphqlOperation(query, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.getZonesHierarchy
  } catch (err) {
    if (err.message === 'canceled' && timesRepeated < 5) {
      return await getZonesHierarchy(params, timesRepeated + 1)
    }

    return err.message
  }
}

export const getZoneHierarchy = async params => {
  try {
    const query = /* GraphQL */ `
      query getZoneHierarchy($zoneId: String!) {
        getZoneHierarchy(zoneId: $zoneId) {
          id
          name
          status
          code
          organizationId
          parentId
          parentPath
          latitude
          longitude
          timeZone
          level
          hasCoreDevice
          maintenanceMode
        }
      }
    `

    const operation = graphqlOperation(query, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.getZoneHierarchy
  } catch (err) {
    return err.message
  }
}

export const getZonesHierarchyDevices = async params => {
  try {
    const query = /* GraphQL */ `
      query getZonesHierarchyDevices($zoneIds: [String]!) {
        getZonesHierarchyDevices(zoneIds: $zoneIds) {
          id
          name
          sensorType
          zoneId
          zonePath
          proofOfPossession
          hardwareVersion
          latitude
          longitude
          createdTimestamp
          isIoT
          isGreengrass
          tag
          config
          lastMoveAt
          notInUse
          sleepCycleMinutes
        }
      }
    `

    const operation = graphqlOperation(query, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.getZonesHierarchyDevices
  } catch (err) {
    return err.message
  }
}

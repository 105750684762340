import { I18n } from 'aws-amplify'

import ZoneUtils from '@/Util/ZoneUtils'
import { sortMeasurementsForDashboard } from '@/Util/MeasurementUtils'

import { addVectorMeasurements } from '../Utils/DateUtils'

export const Strings = () => ({
  dateRangeFilters: I18n.get('Time Range & Interval'),
  locationFilters: I18n.get('Location of Interest'),
  general: I18n.get('General'),
  measurements: I18n.get('Measurements'),
  configureDataboard: I18n.get('Configure Databoard'),
  selectMeasurements: I18n.get('Select Measurements'),
  timeZoneFetchInfo: I18n.get(
    'The date fields time values are set in the time zone specified above.'
  )
})

const intersectMeasurements = (
  availableMeasurements,
  intersectionMeasurements
) => {
  // returns a new set with the intersection of the two sets
  if (availableMeasurements.size === 0) {
    return intersectionMeasurements
  }

  const intersection = new Set()
  for (let measurement of intersectionMeasurements) {
    if (availableMeasurements.has(measurement)) {
      intersection.add(measurement)
    }
  }

  return intersection
}

export const getDataboardAvailableMeasurements = (
  zoneFilters,
  zonesHierarchy,
  allDeviceTypes,
  allMeasurements,
  organizationId,
  interval
) => {
  let availableMeasurementsSet = new Set()

  for (let zoneFilter of zoneFilters) {
    if (zoneFilter.sensorId) {
      let sensorMeasurements = new Set(
        ZoneUtils.getAllReadMeasurementsForDevice(
          zonesHierarchy,
          zoneFilter.siteId,
          zoneFilter.sensorId,
          allDeviceTypes
        )
      )

      availableMeasurementsSet = intersectMeasurements(
        availableMeasurementsSet,
        sensorMeasurements
      )
    } else if (zoneFilter.zoneId) {
      let zoneMeasurements = new Set(
        ZoneUtils.getAllReadMeasurements(
          zonesHierarchy,
          zoneFilter.siteId,
          zoneFilter.zoneId,
          allDeviceTypes
        )
      )
      availableMeasurementsSet = intersectMeasurements(
        availableMeasurementsSet,
        zoneMeasurements
      )
    } else if (!zoneFilter.zoneId && zoneFilter.organizationId) {
      let orgMeasurements = new Set(
        ZoneUtils.getAllReadMeasurementsForOrganization(
          zonesHierarchy,
          organizationId,
          allDeviceTypes
        )
      )
      availableMeasurementsSet = intersectMeasurements(
        availableMeasurementsSet,
        orgMeasurements
      )
    }
  }

  let sortedAvailableMeasurements = []

  if (availableMeasurementsSet.size > 0) {
    let availableMeasurementsArray = addVectorMeasurements(
      Array.from(availableMeasurementsSet)
    )

    if (interval !== 'hour') {
      const indexDli = availableMeasurementsArray.indexOf(
        'daily_light_integral'
      )
      if (indexDli > -1) {
        availableMeasurementsArray.splice(indexDli, 1)
      }

      const indexDce = availableMeasurementsArray.indexOf(
        'daily_energy_consumption'
      )
      if (indexDce > -1) {
        availableMeasurementsArray.splice(indexDce, 1)
      }
    }

    sortedAvailableMeasurements = sortMeasurementsForDashboard(
      allMeasurements,
      availableMeasurementsArray
    )
  }

  return sortedAvailableMeasurements
}

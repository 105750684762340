import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { updateCurrentDashboardData } from '@/actions/ts/dashboard'
import {
  getDeviceTypes,
  getMeasurements,
  getZoneHierarchyLoading
} from '@/reducers/selectors'
import { useDashboardParams } from '../../utils/useDashboardParams'

import { useAuth } from '@/contexts/auth-context'
import { getAvailableMeasurements } from './measurements'

// Only allow one socket connection at a time
export const useLiveDataFeed = state => {
  const dispatch = useDispatch()
  const auth = useAuth()
  const params = useDashboardParams()

  const allMeasurements = getMeasurements()
  const allDeviceTypes = getDeviceTypes()
  const zoneHierarchyLoading = getZoneHierarchyLoading()

  const socketRef = useRef(null)
  const zoneIdRef = useRef(null)

  const authToken = auth.getCognitoAuthToken()

  useEffect(() => {
    const zoneId = params?.sensorId || state?.currentZone?.id

    if (
      zoneId &&
      state?.currentDashboard?.dashboardId &&
      authToken &&
      state.hierarchy &&
      !zoneHierarchyLoading &&
      allMeasurements
    ) {
      if (zoneId !== zoneIdRef.current) {
        if (socketRef.current) {
          socketRef.current.close()
          socketRef.current = null
        }

        const baseUrl = `${window.ENV.WEB_SOCKET_URL}/ts/?zone=${zoneId}`
        const availableMeasurements = getAvailableMeasurements(
          state.hierarchy,
          state?.currentZone,
          state?.siteId,
          allDeviceTypes,
          allMeasurements,
          params
        )

        const measurementsUri = `measurements=${availableMeasurements.join(
          ','
        )}`
        const authUri = `authorization=${authToken}`
        const userId = `userId=${auth.getCognitoUsername()}`
        const websocketUrl = `${baseUrl}&${measurementsUri}&${userId}&${authUri}`

        const socket = new WebSocket(websocketUrl)

        socket.onmessage = event => {
          const data = JSON.parse(event.data)
          if (data) {
            dispatch(updateCurrentDashboardData(data))
          }
        }

        socket.onclose = () => {
          if (socketRef.current === socket) {
            socketRef.current = null
          }
        }

        socketRef.current = socket
        zoneIdRef.current = zoneId
      }
    }
  }, [
    params,
    state?.currentZone?.id,
    state?.currentDashboard?.dashboardId,
    authToken,
    state.hierarchy,
    zoneHierarchyLoading,
    allMeasurements,
    allDeviceTypes,
    dispatch,
    state?.siteId,
    state?.currentZone
  ])

  useEffect(() => {
    return () => {
      if (socketRef.current) {
        socketRef.current.close()
        socketRef.current = null
      }
    }
  }, [])
}

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import ViewReleaseNote from './ReleaseNoteCard'
import { Box, Text } from '@/primitives'
import { LineSeparator } from '@/elements'

import { sendFetchReleaseNotesByLang } from '@/slices/fyi/releaseNotes'
import {
  getReleaseNotesByLangFetched,
  getLang,
  getReleaseNotesByLang
} from '@/reducers/selectors'
import Strings from '../Shared/Strings'

const strings = Strings()

const ReleaseNotes = () => {
  const dispatch = useDispatch()
  const lang = getLang()
  const fetched = getReleaseNotesByLangFetched()
  const releaseNotes = getReleaseNotesByLang()
  const sortedReleaseNotes =
    releaseNotes?.length > 0
      ? [...releaseNotes]?.sort((a, b) => b.publishDate - a.publishDate)
      : []

  useEffect(() => {
    if (fetched !== lang) dispatch(sendFetchReleaseNotesByLang({ lang }))
  }, [fetched, lang, dispatch])

  return (
    <Box>
      <Box style={{ marginBottom: '1rem' }}>
        <Box style={{ margin: '2.5rem 0', textAlign: 'center' }}>
          <Text as='h1' style={{ margin: '1rem 0 0.5rem' }}>
            {strings.releaseNotes}
          </Text>
          <Text as='p' variant='page' tone={800}>
            {strings.releaseNotesSubheading}
          </Text>
        </Box>
        <LineSeparator />
      </Box>
      {sortedReleaseNotes.map((releaseNote, index) => (
        <ViewReleaseNote
          key={`releaseNote-${index}`}
          releaseNote={releaseNote}
        />
      ))}
    </Box>
  )
}

export default ReleaseNotes

import ZoneUtils from '../../../../../Util/ZoneUtils'
import { formatDate } from '../../utils'

import {
  CROP_STATUSES,
  CROP_GROWTH_MEDIUMS,
  CROP_HYDRATION_METHODS
} from '../utils'

import Strings from '../Strings'

const strings = Strings()

const statuses = CROP_STATUSES(strings)
const growthMediums = CROP_GROWTH_MEDIUMS(strings)
const hydrationMethods = CROP_HYDRATION_METHODS(strings)

const getOrganizationName = (organizations, organizationId) => {
  for (let organization of organizations) {
    if (organization.id === organizationId) {
      return organization.name
    }
  }
  return null
}

const getZoneName = (zonesHierarchy, site, zone) => {
  return ZoneUtils.getZoneName(zonesHierarchy, site, zone)
}

const combineGrowthMediums = growthMedium => {
  return growthMedium.map(gm => growthMediums[gm]).join()
}

export const DOWNLOAD_HEADERS = () => [
  strings.createdAt,
  strings.updatedAt,
  strings.organization,
  strings.site,
  strings.zone,
  strings.produce,
  strings.variety,
  strings.status,
  strings.plantingAreaM2,
  strings.growthMedium,
  strings.hydrationMethod,
  strings.seedQuantity,
  strings.estimatedSowDate,
  strings.sowDate,
  strings.estimatedTransplantDate,
  strings.transplantDate,
  strings.estimatedHarvestStartDate,
  strings.harvestStartDate,
  strings.estimatedHarvestEndDate,
  strings.harvestEndDate
]

export const DOWNLOAD_VALUES = (item, organizations, zones) => {
  return [
    formatDate(item.createdAt, 'D TT'),
    formatDate(item.updatedAt, 'D TT'),
    getOrganizationName(organizations, item.organizationId),
    getZoneName(zones, item.siteId),
    getZoneName(zones, item.siteId, item.zoneId),
    item.produce.name,
    item.variety.name,
    statuses[item.status],
    item.plantingArea,
    combineGrowthMediums(item.growthMedium),
    hydrationMethods[item.hydrationMethod],
    item.seedQuantity,
    formatDate(item.estimatedSowDate, 'D'),
    formatDate(item.sowDate, 'D'),
    formatDate(item.estimatedTransplantDate, 'D'),
    formatDate(item.transplantDate, 'D'),
    formatDate(item.estimatedHarvestStartDate, 'D'),
    formatDate(item.harvestStartDate, 'D'),
    formatDate(item.estimatedHarvestEndDate, 'D'),
    formatDate(item.harvestEndDate, 'D')
  ]
}

import { useState, useEffect, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { Switch, Route, useParams } from 'react-router-dom'

import { clearRegions, clearRegion } from '@/actions/operations/region'

import { tableReducer, tableInitialState } from './state'

import City from '../City'

import Table from './Table'
import Form from './Form'

const MODULE_PATH = '/operations/:zone+/management/countries/regions'

function RegionManagement({ organizationId, countryId, backPath }) {
  const dispatch = useDispatch()

  const params = useParams()

  const [state, dispatchState] = useReducer(tableReducer, tableInitialState)

  const [modulePath, setModulePath] = useState(MODULE_PATH)

  useEffect(() => {
    return () => {
      dispatch(clearRegion())
      dispatch(clearRegions())
    }
  }, [dispatch])

  useEffect(() => {
    if (params?.zone) {
      setModulePath(MODULE_PATH.replace(':zone+', params.zone))
    }
  }, [params?.zone])

  useEffect(() => {
    if (organizationId) {
      dispatchState({
        state: {
          organizations: [organizationId],
          countries: []
        }
      })
    }
  }, [organizationId])

  return (
    <Switch>
      <Route path={`${MODULE_PATH}/cities`}>
        <City
          organizationId={state.organizationId}
          countryId={state.countryId}
          regionId={state.regionId}
          backPath={modulePath}
        />
      </Route>
      <Route
        path={[`${MODULE_PATH}/create`, `${MODULE_PATH}/update/:itemId`]}
        exact={true}
      >
        <Form
          state={state}
          dispatchState={dispatchState}
          modulePath={modulePath}
        />
      </Route>
      <Route path={MODULE_PATH} exact={true}>
        <Table
          state={state}
          dispatchState={dispatchState}
          modulePath={modulePath}
          backPath={backPath}
        />
      </Route>
    </Switch>
  )
}

export default RegionManagement

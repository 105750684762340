import Strings from '../Strings'

const {
  errorInvalidPhoneNumber,
  errorMessage,
  firstNameRequired,
  lastNameRequired,
  userNameRequired,
  emailRequired,
  roleRequired
} = Strings()

export function onlyPhoneNumberError(userState) {
  return Object.keys(userState).every(key => {
    if (key !== 'phoneNumber' && userState[key].hasError) {
      return false
    }

    return true
  })
}

export const generateUserError = (error, userState) => {
  if (error) {
    return error
  } else {
    const onlyPhoneError = onlyPhoneNumberError(userState)
    return onlyPhoneError ? errorInvalidPhoneNumber : errorMessage
  }
}

export const USER_FORM_ENUM = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  USER_NAME: 'userName',
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',
  ROLE: 'role',
  CLEAR_ALL: 'clearAll',
  UPDATE_ALL: 'updateAll'
}

/** Reducer and State for Admin User Forms */
export const initialState = {
  firstName: { value: '', error: firstNameRequired, hasError: false },
  lastName: { value: '', error: lastNameRequired, hasError: false },
  userName: { value: '', error: userNameRequired, hasError: false },
  email: { value: '', error: emailRequired, hasError: false },
  phoneNumber: {
    value: undefined,
    error: errorInvalidPhoneNumber,
    hasError: false
  },
  role: { value: '', error: roleRequired, hasError: false }
}

export const reducer = (state, action) => {
  const actionTypeKey = action.type

  if (actionTypeKey === USER_FORM_ENUM.CLEAR_ALL) {
    return initialState
  }

  if (actionTypeKey === USER_FORM_ENUM.UPDATE_ALL) {
    const { firstName, lastName, email, phoneNumber, userName, role } =
      action.value
    const updatedUser = {
      firstName: {
        value: firstName,
        error: firstNameRequired,
        hasError: false
      },
      lastName: { value: lastName, error: lastNameRequired, hasError: false },
      userName: { value: userName, eerror: userNameRequired, hasError: false },
      email: { value: email, error: emailRequired, hasError: false },
      phoneNumber: {
        value: phoneNumber ?? undefined,
        hasError: false,
        error: errorInvalidPhoneNumber
      },
      role: { value: role, error: roleRequired, hasError: false }
    }

    return updatedUser
  }

  const value = action.hasOwnProperty('value')
    ? action.value
    : state[actionTypeKey].value
  const hasError = action.hasOwnProperty('hasError') ? action.hasError : false
  const error = action.hasOwnProperty('error') ? action.error : null

  const currentUserObj = state[actionTypeKey]
  return {
    ...state,
    [actionTypeKey]: { ...currentUserObj, value, hasError, error }
  }
}

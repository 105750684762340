import { nanoid } from 'nanoid'
import clone from 'lodash/clone'
import cloneDeep from 'lodash/cloneDeep'

import { findNextAvailablePosition } from './utils/findAvailableSpace'
import { getDeferableCommit, prepareWidgetToAdd } from './utils'
import { COLUMNS } from './config'
import { createDownloadableDataset } from './Widgets/Shared/utils/dataset'

export const INITIALIZE_DASHBOARD = 'INITIALIZE_DASHBOARD'
export const SET_CURRENT_DASHBOARD = 'SET_CURRENT_DASHBOARD'
export const SET_WIDGETS = 'SET_WIDGETS'
export const ADD_WIDGET = 'ADD_WIDGET'
export const UPDATE_WIDGET = 'UPSERT_WIDGET'
export const REMOVE_WIDGET = 'REMOVE_WIDGET'
export const UPDATE_LAYOUTS = 'UPDATE_LAYOUTS'
export const SET_CURRENT_BREAKPOINT = 'SET_CURRENT_BREAKPOINT'
export const SET_EDITABLE = 'SET_EDITABLE'
export const TOGGLE_EDITABLE = 'TOGGLE_EDITABLE'
export const SET_HIERARCHY = 'SET_HIERARCHY'
export const SET_SITE_ID = 'SET_SITE_ID'
export const SET_CURRENT_ZONE = 'SET_CURRENT_ZONE'
export const SET_IS_404 = 'SET_IS_404'
export const SET_IS_TOOLBOX_OPEN = 'SET_IS_TOOLBOX_OPEN'
export const CANCEL_WIDGET_UPDATE = 'CANCEL_WIDGET_UPDATE'
export const WIDGET_EDIT_RESIZE = 'WIDGET_EDIT_RESIZE'
export const SET_DOWNLOADABLE_DATASET = 'SET_DOWNLOADABLE_DATASET'
export const REMOVE_DOWNLOADABLE_DATASET = 'REMOVE_DOWNLOADABLE_DATASET'
export const CLEAR_DOWNLOADABLE_DATASETS = 'CLEAR_DOWNLOADABLE_DATASETS'
export const SET_WIDGET_MODE = 'SET_WIDGET_MODE'
export const DEFER_COMMIT = 'DEFER_COMMIT'

// Threshold Integration
export const ADD_DETAILED_SENSOR_STATUSES = 'ADD_DETAILED_SENSOR_STATUSES'
export const CLEAR_DETAILED_SENSOR_STATUSES = 'CLEAR_DETAILED_SENSOR_STATUSES'
export const SET_DASHBOARD_STATUSES = 'SET_DASHBOARD_STATUSES'

export const dashboardInitialState = {
  widgets: new Map(),
  widgetsStateId: null,
  initialized: false,
  currentBreakpoint: null,
  editable: false,
  siteId: null,
  hierarchy: null,
  currentZone: null,
  is404: {
    zone: false,
    site: false,
    device: false
  },
  isToolboxOpen: false,
  newWidgetId: null,
  currentDashboard: null,
  nextActionDeferCommit: true,
  nextActionIgnoreLayoutChange: false,
  nonCommitableWidgets: new Set(),
  downloadableDatasets: new Map(),
  detailedSensorStatuses: new Map(),
  dashboardStatuses: {}
}

export function dashboardReducer(state, action) {
  const handlers = {
    [DEFER_COMMIT]: (state, action) => {
      return { ...state, nextActionDeferCommit: true }
    },
    [INITIALIZE_DASHBOARD]: (state, action) => {
      const { widgets, currentBreakpoint, currentDashboard } = action.payload
      return {
        ...state,
        widgetsStateId: nanoid(),
        widgets,
        currentBreakpoint,
        currentDashboard,
        initialized: true,
        nextActionDeferCommit: false
      }
    },
    [SET_CURRENT_DASHBOARD]: (state, action) => {
      const { currentDashboard, widgets } = action.payload
      return {
        ...state,
        currentDashboard,
        widgets,
        newWidgetId: null,
        nextActionDeferCommit: true,
        downloadableDatasets: new Map()
      }
    },
    [SET_WIDGETS]: (state, action) => {
      const { widgets } = action.payload
      return { ...state, widgets }
    },
    [UPDATE_LAYOUTS]: (state, action) => {
      const { layouts } = action.payload
      const widgets = cloneDeep(state.widgets)

      let nextActionIgnoreLayoutChange = state.nextActionIgnoreLayoutChange
      let nextActionDeferCommit = state.nextActionDeferCommit

      if (action.payload.nextActionDeferCommit != null) {
        nextActionDeferCommit = action.payload.nextActionDeferCommit
      }

      for (let layout of layouts) {
        const widget = widgets.get(layout.i)
        if (widget) {
          const { x, y, w, h, minH, minW } = layout
          const position = [x, y, w, h, minW, minH]
          widget.props.position[state.currentBreakpoint] = position
          widgets.set(layout.i, widget)
          if (layout.i === state.newWidgetId) {
            nextActionIgnoreLayoutChange = getDeferableCommit(widget.props)
            nextActionDeferCommit = getDeferableCommit(widget.props)
          }
        }
      }

      if (nextActionIgnoreLayoutChange || nextActionDeferCommit) {
        return {
          ...state,
          nextActionIgnoreLayoutChange: false,
          nextActionDeferCommit: false
        }
      }

      // check if we need to ignore this layout update

      // whenever a layout changes it means that the user is editing the dashboard
      // or that we are navigating to a new dashboard. this can cause unintended updates
      // so here we check if this state change is to be committed or not
      // and then we set the flag to false so that the next state change is committed
      let widgetsStateId = nextActionDeferCommit
        ? state.widgetsStateId
        : nanoid()

      return {
        ...state,
        widgets,
        widgetsStateId,
        nextActionDeferCommit: false
      }
    },
    [ADD_WIDGET]: (state, action) => {
      const widgets = cloneDeep(state.widgets)
      const { component, ...widgetDefinition } = action.payload.widget

      const id = nanoid(4) // 4 is enough for testing

      let { widgetId, config = null, options = null } = widgetDefinition
      const preparedWidget = prepareWidgetToAdd(options, state, config)

      const foundPosition = findNextAvailablePosition({
        preparedWidget: {
          ...preparedWidget,
          defaultPosition: widgetDefinition.defaultPosition
        },
        widgets,
        breakpoint: state.currentBreakpoint,
        columns: COLUMNS[state.currentBreakpoint]
      })

      const widgetProps = {
        id,
        widgetId,
        config: preparedWidget.config,
        options: preparedWidget.options,
        widgetMode: 'view',
        position: {
          [state.currentBreakpoint]: foundPosition
        }
      }

      widgets.set(id, {
        component,
        props: widgetProps
      })

      const isIgnoreOrDeferred = getDeferableCommit(widgetProps)

      return {
        ...state,
        widgets,
        newWidgetId: id,
        widgetsStateId: isIgnoreOrDeferred ? state.widgetsStateId : nanoid(),
        nextActionDeferCommit: isIgnoreOrDeferred,
        nextActionIgnoreLayoutChange: true,
        isToolboxOpen: false
      }
    },
    [UPDATE_WIDGET]: (state, action) => {
      const widgets = cloneDeep(state.widgets)
      const widget = widgets.get(action.payload.id)
      widget.props = action.payload
      widgets.set(widget.props.id, widget)

      // Whenever we update a widget and we do not want to save the state to the db
      // Simply pass deferDbUpdate: true in the action object
      let widgetsStateId = action?.deferDbUpdate
        ? state.widgetsStateId
        : nanoid()

      return {
        ...state,
        widgets,
        widgetsStateId,
        nextActionDeferCommit: false,
        nextActionIgnoreLayoutChange: getDeferableCommit(widget.props)
      }
    },
    [REMOVE_WIDGET]: (state, action) => {
      const { id } = action.payload
      const widgets = cloneDeep(state.widgets)
      widgets.delete(id)
      return {
        ...state,
        widgets,
        widgetsStateId: nanoid(),
        nextActionDeferCommit: false,
        nextActionIgnoreLayoutChange: true
      }
    },
    [SET_CURRENT_BREAKPOINT]: (state, action) => {
      const { breakpoint } = action.payload
      return { ...state, currentBreakpoint: breakpoint }
    },
    [SET_EDITABLE]: (state, action) => {
      const { editable } = action.payload
      return { ...state, editable }
    },
    [TOGGLE_EDITABLE]: (state, action) => {
      return { ...state, editable: !state.editable }
    },
    [SET_HIERARCHY]: (state, action) => {
      const { hierarchy } = action.payload
      return { ...state, hierarchy }
    },
    [SET_SITE_ID]: (state, action) => {
      const { siteId } = action.payload
      return { ...state, siteId, hierarchy: null }
    },
    [SET_CURRENT_ZONE]: (state, action) => {
      const { zone } = action.payload
      return { ...state, currentZone: zone }
    },
    [SET_IS_404]: (state, action) => {
      let is404Copy = clone(state.is404)
      const { is404, type } = action.payload

      if (type === 'all') {
        is404Copy = { site: is404, zone: is404, device: is404 }
      } else {
        is404Copy[type] = is404
      }

      return { ...state, is404: is404Copy }
    },
    [SET_IS_TOOLBOX_OPEN]: (state, action) => {
      const { isOpen } = action.payload
      return { ...state, isToolboxOpen: isOpen }
    },
    [CANCEL_WIDGET_UPDATE]: (state, action) => {
      const widgets = cloneDeep(state.widgets)

      const widget = widgets.get(action.payload.id)
      widget.props.position = action.payload.position

      return { ...state, widgets, nextActionDeferCommit: true }
    },
    [WIDGET_EDIT_RESIZE]: (state, action) => {
      const widgets = cloneDeep(state.widgets)

      const widget = widgets.get(action.payload.id)
      widget.props.position = action.payload.position

      return { ...state, widgets, nextActionDeferCommit: true }
    },
    [SET_DOWNLOADABLE_DATASET]: (state, action) => {
      const { id, data, widgetId, unit } = action.payload
      const downloadableDatasets = cloneDeep(state.downloadableDatasets)

      const downloadableDataset = createDownloadableDataset(
        id,
        data,
        widgetId,
        unit
      )

      return {
        ...state,
        downloadableDatasets: downloadableDatasets.set(id, downloadableDataset)
      }
    },
    [REMOVE_DOWNLOADABLE_DATASET]: (state, action) => {
      const { id } = action.payload
      const downloadableDatasets = cloneDeep(state.downloadableDatasets)

      downloadableDatasets.delete(id)

      return { ...state, downloadableDatasets }
    },
    [CLEAR_DOWNLOADABLE_DATASETS]: (state, action) => ({
      ...state,
      downloadableDatasets: new Map()
    }),
    [ADD_DETAILED_SENSOR_STATUSES]: (state, action) => {
      const detailedSensorStatuses = cloneDeep(state.detailedSensorStatuses)

      detailedSensorStatuses.set(action.payload.measurementId, action.payload)

      return { ...state, detailedSensorStatuses }
    },
    [CLEAR_DETAILED_SENSOR_STATUSES]: (state, action) => ({
      ...state,
      detailedSensorStatuses: new Map()
    }),
    [SET_DASHBOARD_STATUSES]: (state, action) => {
      // for each key in payload create a map item
      const dashboardStatuses = {}
      for (let path in action.payload) {
        dashboardStatuses[path] = action.payload[path]
      }

      return { ...state, dashboardStatuses }
    },
    [SET_WIDGET_MODE]: (state, action) => {
      const { id, widgetMode } = action.payload
      const widgets = cloneDeep(state.widgets)

      const widget = widgets.get(id)
      widget.props.widgetMode = widgetMode

      return { ...state, widgets }
    }
  }

  if (!handlers[action.type]) {
    throw new Error(`Unknown action type: ${action.type}`)
  }

  const nextState = handlers[action.type](state, action)

  // console.groupCollapsed(
  //   `%c${action.type}`,
  //   'color: #000; font-weight: lighter;'
  // )
  // console.log('%cPrevious State:', 'color: #9E9E9E; font-weight: bold;', state)
  // console.log('%cAction:', 'color: #00A7F7; font-weight: bold;', action)
  // console.log('%cNext State:', 'color: #47B04B; font-weight: bold;', nextState)
  // console.groupEnd()

  return nextState
}

import { I18n } from 'aws-amplify'
import { object, string } from 'yup'

export const FIELDS = {
  fullName: 'fullName',
  email: 'email',
  supportSubject: 'supportSubject',
  supportType: 'supportType',
  describeProblem: 'describeProblem'
}

export const SUPPORT_TYPES = [
  { value: 'general', label: 'General Query' },
  { value: 'bug', label: 'Bug Report' },
  { value: 'other', label: 'Other' }
]

export const SCHEMA = () => {
  return object().shape({
    fullName: string()
      .required(I18n.get('Please provide a name'))
      .min(1, I18n.get('Name cannot be empty'))
      .test(
        'notEmptySpaces',
        I18n.get('Name cannot consist of only spaces'),
        value => !/^\s+$/.test(value)
      ),
    email: string()
      .email(I18n.get('Please provide a valid email address'))
      .required(I18n.get('Please provide a valid email address')),
    supportSubject: string().required(I18n.get('Please provide a subject')),
    describeProblem: string()
      .max(255, I18n.get('Description must be no more than 255 characters'))
      .required(I18n.get('Please provide a description'))
  })
}

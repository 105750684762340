import { ICON_TYPES } from '../utils'

const Icon = ({
  deviceType,
  size = 23,
  className,
  style,
  fill = 'currentColor'
}) => {
  const getIconType = type => {
    if (ICON_TYPES.indexOf(type) >= 0) {
      return type
    }
    return 'generic'
  }

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height={size}
      width={size}
      className={className}
      style={style}
    >
      <use
        href={'#ctx-icon-device-' + getIconType(deviceType.toLowerCase())}
        width={size}
        height={size}
        fill={fill}
      />
    </svg>
  )
}

export default Icon

import { Column, Row, Slot, Table } from '@/primitives'

import ManagerTableRow from './TableRow'
import Strings from '../../Strings'
import { getZoneUsers } from '@/reducers/selectors'

function filterManagers(managers, users, searchText) {
  if (searchText.length === 0) {
    return managers
  }

  return managers.filter(({ userId }) => {
    const user = users.find(({ userName }) => userName === userId)
    if (user) {
      const username = user.userName.toLowerCase()
      const firstName = user.firstName.toLowerCase()
      const lastName = user.lastName.toLowerCase()
      const email = user.email.toLowerCase()
      return (
        username.includes(searchText) ||
        firstName.includes(searchText) ||
        lastName.includes(searchText) ||
        email.includes(searchText)
      )
    }

    return false
  })
}

const ManagersTable = ({
  managers,
  isLoading,
  searchText,
  onEditManager,
  onDeleteManager
}) => {
  const users = getZoneUsers()
  const {
    tableActionsColumn,
    tableHeaderUser,
    tableHeaderUserEmail,
    tableHeaderSettings
  } = Strings()

  return (
    <Table>
      <Slot name='head'>
        <Row>
          <Column>{tableHeaderUser}</Column>
          <Column>{tableHeaderUserEmail}</Column>
          <Column>{tableHeaderSettings}</Column>
          <Column>{tableActionsColumn}</Column>
        </Row>
      </Slot>
      <Slot name='body'>
        {managers?.length > 0 &&
          filterManagers(managers, users, searchText.toLowerCase()).map(
            manager => (
              <ManagerTableRow
                key={`${manager.id}-${manager.userId}`}
                manager={manager}
                isLoading={isLoading}
                onEditManager={onEditManager}
                onDeleteManager={onDeleteManager}
              />
            )
          )}
      </Slot>
    </Table>
  )
}

export default ManagersTable

import { Fragment, useState, useEffect } from 'react'

import { Card, Flex, Icon, Text } from '@/primitives'

import {
  CONVERSION_TEMPERATURE_MEASUREMENTS,
  FAHRENHEIT_UNIT,
  convertCelciusToFahrenheit
} from '@/Util/MeasurementUtils'

import { conversionLookup, nameLookup, unitLookup } from '@/Util/UnitUtils'
import { DECIMAL_ACCURACY, applyConversion } from '../../utils'

import Strings from '../../Strings'

const Widget = ({
  name,
  value,
  min,
  max,
  direction,
  measurements,
  alerts,
  useFahrenheit
}) => {
  const strings = Strings()

  const [status, setStatus] = useState('normal')

  const measurementId = name.toLowerCase()

  const convertToFarenheit = value => {
    if (useFahrenheit) {
      if (CONVERSION_TEMPERATURE_MEASUREMENTS.indexOf(measurementId) >= 0) {
        return convertCelciusToFahrenheit(value)
      }
    }
    return value
  }

  useEffect(() => {
    for (let status in alerts) {
      const alert = alerts[status]
      if (alert.measurementId) {
        if (alert.measurementId.toLowerCase() === measurementId) {
          setStatus(alert.status)
          break
        }
      } else if (alert.measurements) {
        if (alert.measurements.indexOf(measurementId) >= 0) {
          setStatus(alert.status)
          break
        }
      }
    }
  }, [alerts])

  const getDirection = () => {
    if (direction !== 'equal') {
      return direction === 'increase' ? 'arrow_drop_up' : 'arrow_drop_down'
    }
    return 'remove'
  }

  const getUnit = () => {
    if (measurementId) {
      if (useFahrenheit) {
        if (CONVERSION_TEMPERATURE_MEASUREMENTS.indexOf(measurementId) >= 0) {
          return FAHRENHEIT_UNIT
        }
      }
      return unitLookup(measurementId, measurements)
    }
    return null
  }

  return (
    <Card className={`widget ${status}`} style={{ marginTop: '1em' }}>
      <Flex
        direction='row'
        alignMainAxis='space-between'
        alignCrossAxis='center'
      >
        <Icon size='500' tone='500' variant='primary' name={getDirection()} />
        <Text as='p'>{getUnit()}</Text>
      </Flex>
      <Flex direction='column' alignCrossAxis='center' alignMainAxis='center'>
        <Text
          as='p'
          size={600}
          fontWeight={700}
          variant='primary'
          tone={500}
          className='value'
        >
          {value !== null && measurementId
            ? applyConversion(
                measurementId,
                convertToFarenheit(value),
                conversionLookup
              ).toFixed(DECIMAL_ACCURACY)
            : strings.noData}
        </Text>
        <Text size={100} fontWeight={700} textTransform='uppercase'>
          {name && nameLookup(measurementId, measurements)}
        </Text>
      </Flex>
      <Flex
        direction='row'
        alignMainAxis='space-between'
        alignCrossAxis='center'
        style={{ marginTop: '1em' }}
      >
        {min != null && max != null && (
          <Fragment>
            <Text fontFamily='mono' size={100}>
              {strings.min}{' '}
              {applyConversion(
                measurementId,
                convertToFarenheit(min),
                conversionLookup
              ).toFixed(DECIMAL_ACCURACY)}
            </Text>
            <Text fontFamily='mono' size={100}>
              {strings.max}{' '}
              {applyConversion(
                measurementId,
                convertToFarenheit(max),
                conversionLookup
              ).toFixed(DECIMAL_ACCURACY)}
            </Text>
          </Fragment>
        )}
      </Flex>
    </Card>
  )
}

export default Widget

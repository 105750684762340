import cloneDeep from 'lodash/cloneDeep'

import {
  findDuplicateDateFilterIndices,
  findDuplicateZoneFilterIndices
} from '../../Utils/FiltersUtils'
import { COMPARISON_TYPES } from '../../Utils/config'

export const canFetch = state => {
  let containsDuplicates = false
  let isSidebarFilterValid = false
  let comparisonFiltersValid = false

  if (state.comparisonType === COMPARISON_TYPES.LOCATION_RANGES) {
    containsDuplicates =
      findDuplicateZoneFilterIndices(state.zoneFilters).length > 0

    const locationSpecificity = [
      'subzoneId',
      'zoneId',
      'roomId',
      'facilityId',
      'siteId'
    ]

    comparisonFiltersValid = state.zoneFilters.every(filter => {
      // Find the most specific key that exists in the filter
      const index = locationSpecificity.findIndex(key => filter[key])
      if (index > -1) {
        return locationSpecificity.slice(index + 1).every(key => filter[key])
      }

      return false
    })

    let dateSidebarFilter = state.sidebarFilter[COMPARISON_TYPES.DATE_RANGES]

    isSidebarFilterValid =
      dateSidebarFilter?.fromDate &&
      dateSidebarFilter?.toDate &&
      dateSidebarFilter?.timeInterval
  } else {
    containsDuplicates =
      findDuplicateDateFilterIndices(state.dateFilters).length > 0

    isSidebarFilterValid =
      state.sidebarFilter[COMPARISON_TYPES.LOCATION_RANGES].siteId
    // since there are no extra checks for comparison filters in the case of DATE_RANGES then we can set this to true
    // should be changed if we add more checks
    comparisonFiltersValid = true
  }

  return (
    !containsDuplicates &&
    isSidebarFilterValid &&
    comparisonFiltersValid &&
    state.measurements[state.comparisonType].length > 0
  )
}

export const cacheConfig = state => {
  const measurements = cloneDeep(state?.measurements)
  const zoneFilters = cloneDeep(state?.zoneFilters)
  const dateFilters = cloneDeep(state?.dateFilters)
  const sidebarFilter = cloneDeep(state?.sidebarFilter)

  window.localStorage.setItem(
    state.localStorageId,
    JSON.stringify({
      version: 2,
      measurements,
      dateFilters,
      sidebarFilter,
      zoneFilters,
      comparisonType: state.comparisonType
    })
  )
}

import { I18n } from 'aws-amplify'

import withEditableWidget from '@/components/DashboardPageV2/Desktop/Widgets/Shared/HOC/withEditableWidget'
import { MeasurementEdit } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Measurement'
import {
  AVERAGE_QUERY,
  DATASET_QUERY,
  LIVE_QUERY
} from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'

import { WidgetDefinition } from '../../Shared/utils/WidgetRegistry'
import {
  datasetLineQuery,
  defaultAverageValueQuery,
  defaultLiveValueQuery
} from '../../Shared/Dynamic/config/defaults'

import { getMeasurements } from '@/reducers/selectors'

import LiveValue from './LiveValue'
import DatasetLine from './DatasetLine'
import AverageValue from './AverageValue'

function BatterySocView(props) {
  const { config, options } = props

  const measurement = options.measurement
  const measurements = getMeasurements()

  const unit = measurements?.find(m => m.id === measurement)?.unit

  if (config.query === LIVE_QUERY) {
    return <LiveValue unit={unit} config={config} options={options} />
  }
  if (config.query === DATASET_QUERY) {
    return (
      <DatasetLine unit={unit} config={config} options={options} {...props} />
    )
  }
  if (config.query === AVERAGE_QUERY) {
    return <AverageValue unit={unit} config={config} options={options} />
  }
}

export const BatterySocViewWidget = withEditableWidget(
  BatterySocView,
  MeasurementEdit
)

export const BatterySocDefinition = () => {
  return new WidgetDefinition({
    widgetId: 'batterySoc',
    title: I18n.get('Battery SoC'),
    icon: 'battery_charging_60',
    component: BatterySocViewWidget,
    defaultPosition: {
      md: [0, 0, 2, 3, 2, 2]
    },
    config: {
      query: 'live',
      chart: 'value',
      range: null,
      interval: null
    },
    options: {
      fixedFactor: 1,
      scaleFactor: 1,
      measurement: 'battery_soc',
      queryOptionsList: [
        defaultLiveValueQuery,
        defaultAverageValueQuery,
        datasetLineQuery
      ]
    }
  })
}

import { makeActionCreator } from '@/Util/ReduxUtils'

export const REQUEST_ALL_CITIES = 'REQUEST_ALL_CITIES'
export const requestAllCities = makeActionCreator(REQUEST_ALL_CITIES)

export const RECEIVE_ALL_CITIES = 'RECEIVE_ALL_CITIES'
export const receiveAllCities = makeActionCreator(
  RECEIVE_ALL_CITIES,
  'cities',
  'count',
  'error'
)

export const REQUEST_CITIES = 'REQUEST_CITIES'
export const requestCities = makeActionCreator(REQUEST_CITIES, 'params')

export const RECEIVE_CITIES = 'RECEIVE_CITIES'
export const receiveCities = makeActionCreator(
  RECEIVE_CITIES,
  'cities',
  'count',
  'error'
)

export const CREATE_CITY = 'CREATE_CITY'
export const createCity = makeActionCreator(CREATE_CITY, 'params')

export const CITY_CREATED = 'CITY_CREATED'
export const cityCreated = makeActionCreator(CITY_CREATED, 'city', 'error')

export const UPDATE_CITY = 'UPDATE_CITY'
export const updateCity = makeActionCreator(UPDATE_CITY, 'params')

export const CITY_UPDATED = 'CITY_UPDATED'
export const cityUpdated = makeActionCreator(CITY_UPDATED, 'city', 'error')

export const REQUEST_CITY = 'REQUEST_CITY'
export const requestCity = makeActionCreator(REQUEST_CITY, 'params')

export const RECEIVE_CITY = 'RECEIVE_CITY'
export const receiveCity = makeActionCreator(RECEIVE_CITY, 'city', 'error')

export const CLEAR_CITY = 'CLEAR_CITY'
export const clearCity = makeActionCreator(CLEAR_CITY)

export const CLEAR_CITIES = 'CLEAR_CITIES'
export const clearCities = makeActionCreator(CLEAR_CITIES)

export const SET_CITY = 'SET_CITY'
export const setCity = makeActionCreator(SET_CITY, 'city')

import { I18n } from 'aws-amplify'
import { TOOLBOX_TYPES, ToolboxItem } from './utils'
import { widgetRegistry } from '../../Widgets'

/*
  CATEGORIES
*/

const planCategory = () =>
  new ToolboxItem({
    id: 'planCategory',
    type: TOOLBOX_TYPES.category,
    title: I18n.get('Plan'),
    icon: 'timeline',
    items: [widgetRegistry.get('planVsHarvest')]
  })

const harvestCategory = () =>
  new ToolboxItem({
    id: 'harvestCategory',
    type: TOOLBOX_TYPES.category,
    title: I18n.get('Harvest'),
    icon: 'yard',
    items: [
      widgetRegistry.get('totalProduce'),
      widgetRegistry.get('topTenHarvestVariety')
    ]
  })

/*
  SECTION
*/

export const operationsSection = () =>
  new ToolboxItem({
    id: 'operationsSection',
    type: TOOLBOX_TYPES.section,
    title: I18n.get('Operations'),
    icon: 'agriculture',
    items: [planCategory(), harvestCategory(), widgetRegistry.get('crops')]
  })

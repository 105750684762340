export const tableInitialState = {
  organizations: [],
  countries: [],
  regions: [],
  cities: [],
  page: 1
}

export const tableReducer = (state, action) => {
  if (action.state) {
    return {
      ...state,
      ...action.state
    }
  } else if (action.page) {
    return {
      ...state,
      page: action.page
    }
  } else if (action.organizations) {
    return {
      ...state,
      organizations: action.organizations
    }
  } else if (action.countries) {
    return {
      ...state,
      countries: action.countries,
      regions: [],
      cities: []
    }
  } else if (action.regions) {
    return {
      ...state,
      regions: action.regions,
      cities: []
    }
  } else if (action.cities) {
    return {
      ...state,
      cities: action.cities
    }
  }
  return state
}

export const formInitalState = {
  id: null,
  name: '',
  countryId: '',
  regionId: '',
  cityId: ''
}

export const formReducer = (state, action) => {
  if (action.type === 'update') {
    return { ...state, [action.name]: action.value }
  }
  if (action.type === 'set-state') {
    return action.state
  }
  return state
}

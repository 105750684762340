import { DateTime } from 'luxon'

function formatToUTC(date) {
  return DateTime.fromMillis(date).toUTC().toString()
}

function generateDateWithSetTimes(
  currentDate,
  timeDate,
  inUnix = true,
  inSeconds = false
) {
  const date = DateTime.fromJSDate(currentDate)
    .set({ hour: timeDate.getHours() })
    .set({ minute: timeDate.getMinutes() })

  if (inUnix) return date.toMillis()
  if (inSeconds) return date.toSeconds()
  return date
}

function getLastDay(timezone) {
  const startDate = DateTime.now()
    .setZone(timezone)
    .startOf('day')
    .toUTC()
    .toString()

  const endDate = DateTime.now()
    .setZone(timezone)
    .endOf('day')
    .toUTC()
    .toString()

  return { startDate, endDate }
}

function getYesterday(timezone) {
  const date = DateTime.now()
  const dateInTimeZone = date.setZone(timezone)
  const yesterday = dateInTimeZone.minus({ days: 1 })
  const startDate = yesterday.startOf('day').toMillis()
  const endDate = yesterday.endOf('day').toMillis()
  return { startDate, endDate }
}

function getInterval(period) {
  switch (period) {
    case 'today':
      return 'minute'
    case 'lastweek':
      return 'hour'
    case 'lastmonth':
      return 'day'
    default:
      return 'minute'
  }
}

function getDatesDiff(startDateT, endDateT) {
  return DateTime.fromMillis(endDateT)
    .diff(DateTime.fromMillis(startDateT), 'days')
    .toObject().days
}

function getCognitoExpirationDiff(startDateT, endDateT) {
  return DateTime.fromSeconds(endDateT)
    .diff(DateTime.fromSeconds(startDateT), 'minutes')
    .toObject().minutes
}

function initStartDateT(timezone, days = 1) {
  return DateTime.now()
    .setZone(timezone)
    .set({ second: 0, millisecond: 0 })
    .minus({ days })
    .toMillis()
}

function initStartDateTVariant(timezone, period = 1, variant = 'd') {
  let key = 'day'
  if (variant === 'm') {
    key = 'minutes'
  } else if (variant === 'h') {
    key = 'hours'
  }

  return DateTime.now()
    .setZone(timezone)
    .minus({ [key]: period })
    .startOf('hour')
    .toMillis()
}

function initEndDateT(timezone) {
  return DateTime.now().setZone(timezone).startOf('hour').toMillis()
}

const TimeUtils = {
  formatToUTC,
  generateDateWithSetTimes,
  getLastDay,
  getYesterday,
  getInterval,
  getDatesDiff,
  getCognitoExpirationDiff,
  initStartDateT,
  initStartDateTVariant,
  initEndDateT
}

export default TimeUtils

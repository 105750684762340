import { useEffect, useState } from 'react'
import { getAllFertilizers } from '../../../../reducers/selectors'

export default function useAllFertilizersMap() {
  const allFertilizers = getAllFertilizers()
  const [allFertilizersMap, setAllFerilizersMap] = useState({})

  useEffect(() => {
    setAllFerilizersMap(
      allFertilizers.reduce((obj, item) => {
        obj[item.id] = item
        return obj
      }, {})
    )
  }, [allFertilizers])

  return allFertilizersMap
}

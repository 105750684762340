
import { Fragment, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import Header from './Header'
import WeatherForecast from './WeatherForecast'
import LiveData from './LiveData'

import { fetchDashboardForecast } from '@/slices/weather/forecast'
import { getWeatherForecastRequested } from '@/reducers/selectors'

import './index.scss'

export default function DesktopDashboardSimplified() {
  const dispatch = useDispatch()
  const params = useParams()

  const weatherForecastRequested = getWeatherForecastRequested()
  const zonePath = params?.zone?.split('/')
  const siteId = zonePath[0]

  useEffect(() => {
    if (siteId && (siteId !== weatherForecastRequested)) {
      dispatch(fetchDashboardForecast({ siteId, period: 'h12' }))
    }
  }, [siteId, dispatch, weatherForecastRequested])

  return (
    <Fragment>
      <Header />
      <WeatherForecast />
      <LiveData />
    </Fragment>
  )
}
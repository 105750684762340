import { I18n } from 'aws-amplify'
import { useLiveData } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Hooks/useLiveData'
import useThresholdStatus from '../../Shared/Hooks/useThresholdStatus'
import { parseValue } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/utils'
import Value from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/Chart/Value'
import { handleMeasurementConversion } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/utils'

import {
  convertToWetBulbTemperature,
  wetBulbTemperatureMeasurements
} from './utils'

import { getCurrentUser, getRootZone } from '@/reducers/selectors'

export default function LiveValue({ unit, options }) {
  const data = useLiveData({
    measurements: wetBulbTemperatureMeasurements
  })
  const thresholdStatus = useThresholdStatus(options.measurements)

  const currentUser = getCurrentUser()
  const rootZone = getRootZone()

  let wetBulbTemperatureData = {
    max: convertToWetBulbTemperature({
      temperature: data?.temperature?.max,
      humidity: data?.humidity?.max
    }),
    min: convertToWetBulbTemperature({
      temperature: data?.temperature?.min,
      humidity: data?.humidity?.min
    }),
    value: convertToWetBulbTemperature({
      temperature: data?.temperature?.value,
      humidity: data?.humidity?.value
    }),
    status: data?.temperature?.status || data?.humidity?.status
  }

  const parseOptions = value => ({
    fixedFactor: options.fixedFactor,
    scaleFactor: options.scaleFactor,
    value
  })

  const conversion = handleMeasurementConversion({
    data: wetBulbTemperatureData,
    measurement: options?.measurement,
    unit,
    queryType: 'live',
    settings: currentUser?.settings,
    userId: currentUser?.userName,
    organizationId: rootZone?.organizationId
  })

  if (conversion) {
    wetBulbTemperatureData = conversion.data
    unit = conversion.unit
  }

  return (
    <Value
      thresholdStatus={thresholdStatus}
      data={{
        value: parseValue(parseOptions(wetBulbTemperatureData?.value)),
        min: parseValue(parseOptions(wetBulbTemperatureData?.min)),
        max: parseValue(parseOptions(wetBulbTemperatureData?.max)),
        unit,
        status: wetBulbTemperatureData?.status,
        subtitle: I18n.get('Live')
      }}
    />
  )
}

import { useEffect, useReducer, useState } from 'react'
import { I18n } from 'aws-amplify'

import { FlexV2, Text, Select } from '@/primitives'
import { SearchBar } from '@/elements'

import {
  getCurrentUserOrganizations,
  getNavigationLocationSelector,
  getZonesList
} from '@/reducers/selectors'

import useMediaQuery from '@/hooks/useMediaQuery'

import Strings from './Strings'

const initialState = {
  active: true,
  inactive: true
}

function reducer(state, action) {
  const newState = { ...state }
  newState[action.type] = action.value
  return newState
}

const ZoneFilter = ({
  setZonesList,
  isLoading,
  onUpdateSearchText,
  placeholderText
}) => {
  const locationSelector = getNavigationLocationSelector()

  const isAboveCustomBreakpoint = useMediaQuery('min-width: 620px')

  const [state] = useReducer(reducer, initialState)

  const organizations = getCurrentUserOrganizations()
  const zones = getZonesList()

  const strings = Strings()

  const [selectedOrg, setSelectedOrg] = useState('all')
  const [organizationOptions, setOrganizationOptions] = useState()

  useEffect(() => {
    if (locationSelector.size) {
      const organization = locationSelector.get('organization')
      if (organization?.id) {
        setSelectedOrg(organization.id)
      }
    }
  }, [locationSelector])

  useEffect(() => {
    let nextOrganizationOptions = organizations?.map(({ id, name }) => {
      return { value: id, text: name }
    })

    nextOrganizationOptions.unshift({ value: 'all', text: I18n.get('All') })
    setOrganizationOptions(nextOrganizationOptions)
  }, [organizations])

  useEffect(() => {
    if (zones.length !== 0) {
      const { active, inactive } = state

      const stateFilterOptions = [
        active ? 'active' : null,
        inactive ? 'inactive' : null
      ]

      /* We're always filtering some variation of active / inactive */
      let filteredList = zones.filter(zone =>
        stateFilterOptions.includes(zone.status)
      )

      if (selectedOrg !== 'all') {
        /* organizationId comes through as null sometimes */
        filteredList = filteredList.filter(
          ({ organizationId }) =>
            organizationId === selectedOrg || organizationId === null
        )
      }
      setZonesList(filteredList)
    }
  }, [selectedOrg, setZonesList, zones, state])

  function onChangeOrg(e) {
    setSelectedOrg(e.target.value)
  }

  return (
    <FlexV2
      axisGap={400}
      direction={isAboveCustomBreakpoint ? 'row' : 'column'}
      alignCrossAxis={isAboveCustomBreakpoint ? 'center' : null}
    >
      <Text variant='page' tone={700} style={{textWrap: 'nowrap'}}>
        {strings.organizations}
      </Text>
      <Select
        name='selectedOrg'
        value={selectedOrg}
        onChange={onChangeOrg}
      >
        {organizationOptions?.map(({ value, text }) => (
          <option key={value} value={value}>
            {text}
          </option>
        ))}
      </Select>
      <SearchBar
        isLoading={isLoading}
        onUpdateSearchText={onUpdateSearchText}
        placeholderText={placeholderText}
      />
    </FlexV2>
  )
}

export default ZoneFilter

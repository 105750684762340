import { useState, useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import LiveData from './LiveData'
import WeatherForecast from './WeatherForecast'
import DataGraphs from './DataGraphs'
import { useMobileDashboard } from '../context'

import { getDevice } from '@/reducers/selectors'

import { Box, Button, Symbol, Flex } from '../../../../primitives'
import { Select } from '../../../../elements'
import { useLiveData } from '../Hooks/useLiveData'
import { graphTimeframeOptions, getLiveDataOptions } from './utils'
import { getChildIds } from '../../utils'

import ZoneUtils from '@/Util/ZoneUtils'

import './index.scss'

const showGraphViewOption = true

export default function Widgets({ measurements }) {
  const { state } = useMobileDashboard()
  const params = useParams()
  const history = useHistory()

  const device = getDevice()

  const [selectedZoneId, setSelectedZoneId] = useState(null)
  const [selectedRange, setSelectedRange] = useState(
    graphTimeframeOptions()[0]?.value
  )
  const [isLiveView, setIsLiveView] = useState(true)
  const [currentZoneId, setCurrentZoneId] = useState(null)

  const { currentZone } = state
  const childIds = getChildIds(currentZone?.children)
  const zoneHasChildren = childIds?.length > 0

  const groupedOptions = getLiveDataOptions(currentZone, params, device)

  const tileIds = useMemo(() => {
    return zoneHasChildren && !params?.sensorId
      ? childIds
      : [params?.sensorId ?? currentZone?.id]
  }, [zoneHasChildren, params?.sensorId, currentZone?.id, childIds])

  useEffect(() => {
    const hasSelectedZoneId = childIds?.some(id => id === selectedZoneId)

    const hasDeviceSelected =
      currentZone?.devices?.length > 0
        ? currentZone?.devices.some(({ id }) => id === selectedZoneId)
        : false

    if (
      (selectedZoneId === null && !hasSelectedZoneId && !hasDeviceSelected) ||
      !currentZoneId ||
      currentZoneId !== currentZone?.id
    ) {
      setSelectedZoneId(tileIds[0])
    }

    setCurrentZoneId(currentZone?.id)
  }, [
    params?.sensorId,
    currentZone?.id,
    tileIds,
    selectedZoneId,
    childIds,
    currentZone?.devices,
    currentZoneId
  ])

  const getBasePath = () => {
    return `${ZoneUtils.getZoneBasePath(params)}`
  }

  const onSelectZone = option => {
    setSelectedZoneId(option?.value)
  }

  const onSelectRange = option => {
    setSelectedRange(option?.value)
  }

  const onClickToggleView = () => {
    setIsLiveView(!isLiveView)
  }

  const onClickCalibration = () => {
    let uri = `${getBasePath()}/sensor/${params.sensorType}/${params.sensorId
      }/calibration`
    history.push(uri)
  }

  useLiveData({ measurements })

  let selectedZoneIsRoom = false
  if (selectedZoneId === currentZone?.id) {
    selectedZoneIsRoom = currentZone?.level === 3
  } else {
    selectedZoneIsRoom =
      selectedZoneId && currentZone?.children?.[selectedZoneId]?.level === 3
  }

  return (
    <Box className='WidgetContainer'>
      {isLiveView && <WeatherForecast />}
      <Flex
        axisGap={300}
        alignMainAxis='stretch'
        className='WidgetContainer__Config'
      >
        {isLiveView && (
          <Select
            name='zones'
            className='WidgetContainer__ZoneSelector'
            value={params?.sensorId ? device?.id : selectedZoneId}
            onChange={onSelectZone}
            options={groupedOptions}
            isDisabled={
              (!zoneHasChildren && currentZone?.devices?.length === 0) ||
              params?.sensorId
            }
            classNamePrefix='MobileLiveData'
          />
        )}
        {!isLiveView && (
          <Select
            name='timeframes'
            className='WidgetContainer__ZoneSelector'
            value={selectedRange}
            onChange={onSelectRange}
            options={graphTimeframeOptions()}
          />
        )}
        {showGraphViewOption && (
          <Button
            onClick={params?.sensorId ? onClickCalibration : onClickToggleView}
            className='WidgetContainer__ViewSelector'
          >
            {params?.sensorId && <Symbol name={'tune'} />}

            {!params?.sensorId && (
              <Symbol name={isLiveView ? 'query_stats' : 'dashboard'} />
            )}
          </Button>
        )}
      </Flex>
      {isLiveView && (
        <LiveData
          zoneMeasurements={measurements}
          zoneId={selectedZoneId}
          isRoom={selectedZoneIsRoom}
        />
      )}
      {!isLiveView && (
        <DataGraphs measurements={measurements} rangeId={selectedRange} />
      )}
    </Box>
  )
}

import { Button, Cell, Row } from '../../../../../primitives'

import history from '../../../../../history'
import Strings from '../Strings'

function TableRow({ item, dispatchState, modulePath }) {
  const strings = Strings()

  const { id, storageFacility, produce, variety, grade, totalWeight } = item

  const onCalibration = () => {
    dispatchState({
      state: {
        inventoryId: id
      }
    })
    history.push(`${modulePath}/calibration`)
  }

  return (
    <Row>
      <Cell columnName={strings.storageFacility}>{storageFacility.name}</Cell>
      <Cell columnName={strings.produce}>
        {produce.name}
        <span>
          {variety.name} ({grade.name})
        </span>
      </Cell>
      <Cell columnName={strings.weight}>
        {totalWeight} {strings.kg}
      </Cell>
      <Cell className='Operations__Table__Column__Actions'>
        <Button
          variant='info'
          size='small'
          iconBefore='scale'
          onClick={onCalibration}
        />
      </Cell>
    </Row>
  )
}

export default TableRow

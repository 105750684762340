import { call, put, select, takeLatest } from 'redux-saga/effects'

import {
  REQUEST_ALL_STORAGE_FACILITIES,
  REQUEST_STORAGE_FACILITIES,
  REQUEST_STORAGE_FACILITY,
  CREATE_STORAGE_FACILITY,
  UPDATE_STORAGE_FACILITY,
  receiveAllStorageFacilities,
  receiveStorageFacilities,
  receiveStorageFacility,
  storageFacilityCreated,
  storageFacilityUpdated
} from '@/actions/operations/storageFacility'

import {
  getStorageFacilities,
  getStorageFacility,
  createStorageFacility,
  updateStorageFacility
} from '@/api/operations/storageFacility'

import { getCurrentUserOrganizations, displayBanner } from '../utils'
import { getOperationsAllStorageFacilities } from './utils'

import Strings from './Strings'

const strings = Strings()

function* sendRequestStorageFacilities(action) {
  const { data, error = null } = yield call(getStorageFacilities, action.params)
  const { storageFacilities = [], count = 0 } = data
  yield put(receiveStorageFacilities(storageFacilities, count, error))
}

export function* watchRequestStorageFacilities() {
  yield takeLatest(REQUEST_STORAGE_FACILITIES, sendRequestStorageFacilities)
}

function* sendRequestStorageFacility(action) {
  const { data: storageFacility, error = null } = yield call(
    getStorageFacility,
    action.params
  )
  yield put(receiveStorageFacility(storageFacility, error))
}

export function* watchRequestStorageFacility() {
  yield takeLatest(REQUEST_STORAGE_FACILITY, sendRequestStorageFacility)
}

function* sendCreateStorageFacility(action) {
  const { data, error = null } = yield call(
    createStorageFacility,
    action.params
  )
  yield put(displayBanner(error, strings.storageFacilityCreated))
  yield put(storageFacilityCreated(data, error))
}

export function* watchCreateStorageFacility() {
  yield takeLatest(CREATE_STORAGE_FACILITY, sendCreateStorageFacility)
}

function* sendUpdateStorageFacility(action) {
  const { data, error = null } = yield call(
    updateStorageFacility,
    action.params
  )
  yield put(displayBanner(error, strings.storageFacilityUpdated))
  yield put(storageFacilityUpdated(data, error))
}

export function* watchUpdateStorageFacility() {
  yield takeLatest(UPDATE_STORAGE_FACILITY, sendUpdateStorageFacility)
}

function* sendRequestAllStorageFacilities(action) {
  const allStorageFacilities = yield select(getOperationsAllStorageFacilities)
  if (allStorageFacilities.length > 0) {
    yield put(
      receiveAllStorageFacilities(
        allStorageFacilities,
        allStorageFacilities.length,
        null
      )
    )
  } else {
    const organizations = yield select(getCurrentUserOrganizations)
    const params = {
      filter: {
        organizationId: organizations.map(o => o.id)
      }
    }
    const { data, error = null } = yield call(getStorageFacilities, params)
    const { storageFacilities = [], count = 0 } = data
    yield put(receiveAllStorageFacilities(storageFacilities, count, error))
  }
}

export function* watchRequestAllStorageFacilities() {
  yield takeLatest(
    REQUEST_ALL_STORAGE_FACILITIES,
    sendRequestAllStorageFacilities
  )
}

export const LEGEND_POSITION = {
  MIDDLE: 'middle'
}

export const defaultLeftAxisOptions = {
  tickSize: 5,
  tickPadding: 5,
  tickRotation: 0,
  legend: '',
  legendPosition: LEGEND_POSITION.MIDDLE,
  legendOffset: -40
}

export const defaultBottomAxisOptions = {
  tickSize: 5,
  tickPadding: 5,
  tickRotation: 0,
  legend: '',
  legendPosition: LEGEND_POSITION.MIDDLE,
  legendOffset: 32
}

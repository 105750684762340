import { I18n } from 'aws-amplify'

const Strings = () => ({
  addSaleItem: I18n.get('Add Item'),
  createFormHeader: I18n.get('Add New Item'),
  updateFormHeader: I18n.get('Update Item'),
  product: I18n.get('Product'),
  units: I18n.get('Units'),
  value: I18n.get('Value'),
  produce: I18n.get('Produce'),
  variety: I18n.get('Variety'),
  grade: I18n.get('Grade'),
  packageUnit: I18n.get('Package Unit'),
  unitPrice: I18n.get('Unit Price'),
  recommended: I18n.get('Recommended'),
  apply: I18n.get('Apply'),
  discount: I18n.get('Discount'),
  tax: I18n.get('Tax'),
  quantityOrdered: I18n.get('Quantity Ordered'),
  selectDefault: I18n.get('Please Select'),
  noSaleItems: I18n.get('No items'),
  formSaveBtn: I18n.get('Save Item'),
  formCancelBtn: I18n.get('Cancel'),
  grams: I18n.get('g'),
  taxLowerCase: I18n.get('tax'),
  saleItemConstraintError: I18n.get('Unable to remove item referenced by shipment.')
})

export default Strings

import useDatasetData from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Hooks/useDatasetData'
import Line from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/Chart/Line'
import {
  convertToTotalDissolvedSolids,
  requiredVectorMeasurements
} from '@/Util/MeasurementUtils'

export default function DatasetLine({ unit, config, options, ...props }) {
  const { datasetData, queryZones } = useDatasetData({
    measurements: requiredVectorMeasurements[options.measurement],
    range: config.range,
    interval: config.interval
  })

  let chartData = {}

  if (datasetData) {
    for (let { zoneName } of queryZones) {
      const conductivityData = datasetData?.['conductivity']?.[zoneName] || []
      chartData[zoneName] = []

      for (let dataPoint of conductivityData) {
        chartData[zoneName].push({
          x: dataPoint.x,
          y: convertToTotalDissolvedSolids({ conductivity: dataPoint.y })
        })
      }
    }
  }

  return (
    <Line
      {...props}
      data={chartData}
      unit={unit}
      config={config}
      queryZones={queryZones}
    />
  )
}

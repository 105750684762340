import { I18n } from 'aws-amplify'

const Strings = () => ({
  manageSales: I18n.get('Manage Sales'),
  createSale: I18n.get('Create Sale'),
  createFormHeader: I18n.get('Create New Sale'),
  updateFormHeader: I18n.get('Update Existing Sale'),
  saveSale: I18n.get('Save Sale'),
  organization: I18n.get('Organization'),
  customer: I18n.get('Customer'),
  purchaseOrder: I18n.get('Purchase Order'),
  tableHeaderInvoice: I18n.get('Invoice'),
  tableHeaderOrder: I18n.get('Order'),
  status: I18n.get('Status'),
  tableHeaderPayment: I18n.get('Payment'),
  tableHeaderActions: I18n.get('Actions'),
  tableNoEntries: I18n.get('No entries'),
  fieldsetSale: I18n.get('Sale'),
  fieldsetInvoice: I18n.get('Invoice'),
  fieldsetDelivery: I18n.get('Delivery'),
  fieldsetPayments: I18n.get('Payments'),
  fieldsetSaleShipments: I18n.get('Shipments'),
  fieldOrderDate: I18n.get('Order Date'),
  fieldBranch: I18n.get('Branch'),
  fieldPurchaseOrder: I18n.get('Customer Purchase Order'),
  fieldCurrency: I18n.get('Currency'),
  fieldInvoiceStatus: I18n.get('Invoice Status'),
  fieldInvoiceNumber: I18n.get('Invoice Number'),
  fieldPaymentMethod: I18n.get('Payment Method'),
  fieldPaymentTerm: I18n.get('Payment Terms'),
  fieldPaymentsNoEntries: I18n.get('No payments'),
  selectInputDefault: I18n.get('Please select'),
  buttonAddPayment: I18n.get('Add Payment'),
  fieldsetSaleItems: I18n.get('Items'),
  noSales: I18n.get('No sales'),
  processing: I18n.get('In Processing'),
  production: I18n.get('In Production'),
  delayed: I18n.get('Delayed'),
  transit: I18n.get('In Transit'),
  partiallyDelivered: I18n.get('Partially Delivered'),
  delivered: I18n.get('Delivered'),
  canceled: I18n.get('Canceled'),
  pending: I18n.get('Pending'),
  issued: I18n.get('Issued'),
  submitted: I18n.get('Submitted'),
  awaitingApproval: I18n.get('Awaiting Approval'),
  awaitingPayment: I18n.get('Awaiting Payment'),
  paid: I18n.get('Paid'),
  including: I18n.get('inc.'),
  tax: I18n.get('tax'),
  traceability: I18n.get('Traceability'),
  areYouSure: I18n.get('Are you sure?'),
  clickContinue: I18n.get('Click continue to confirm.'),
  changeOrganizationWarning: I18n.get('Changing the organization will delete all items and shipments. This cannot be undone.'),
  cancel: I18n.get('Cancel'),
  continue: I18n.get('Continue')
})

export default Strings

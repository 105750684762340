import { I18n } from 'aws-amplify'

const Strings = () => ({
  fieldAmount: I18n.get('Amount'),
  fieldDateReceived: I18n.get('Date Received'),
  formSaveBtn: I18n.get('Save'),
  formCancelBtn: I18n.get('Discard'),
  fieldPaymentsNoEntries: I18n.get('No payments')
})

export default Strings

import {
  NumberDictionary,
  adjectives,
  animals,
  uniqueNamesGenerator
} from 'unique-names-generator'

export function createRandomDeviceName() {
  const numbers = NumberDictionary.generate({ min: 0, max: 9, length: 1 })

  const config = {
    dictionaries: [adjectives, animals, numbers],
    separator: '-',
    length: 3,
    style: 'lowercase'
  }

  return uniqueNamesGenerator(config)
}

export function sanitizeCoordinateInput(value) {
  // Don't allow more than one decimal point or minus sign
  if (
    value.split('.').length > 2 ||
    value.split('-').length > 2 ||
    value.split(',').length > 2
  ) {
    return
  }

  // Remove any non-numeric characters except for decimal points and minus signs
  value = value.replace(/[^0-9.-]/g, '')

  // Ensure minus sign is only at the beginning
  value =
    value.charAt(0) === '-'
      ? '-' + value.slice(1).replace('-', '')
      : value.replace('-', '')

  // Replace all commas with decimal points for consistency
  value = value.replace(/,/g, '.')

  // If the first character is a decimal point, add a leading zero
  if (value === '.') {
    value = '0.'
  }

  // abs value of value
  const absNewValue = value.replace('-', '')

  // If the first character is a zero and the second character is not a decimal point set to 0.x
  if (
    absNewValue.length === 2 &&
    absNewValue.charAt(0) === '0' &&
    absNewValue !== '0.'
  ) {
    const isFirstCharNegative = value.charAt(0) === '-'
    const firstZeroIndex = isFirstCharNegative ? 1 : 0
    value = value[firstZeroIndex] + '.' + value.charAt(1 + firstZeroIndex)
    value = isFirstCharNegative ? '-' + value : value
  }

  return value
}

export function sanitizePositiveIntegerInput(value) {
  let nextValue = value
  // if the value is empty, set it to 0

  if (value === '') {
    nextValue = 0
  }

  // allow only 0-9 and nothing else
  const regex = new RegExp(/^[0-9]+$/)
  if (!regex.test(nextValue)) {
    return null
  }

  // convert to number
  const numericValue = Number(nextValue || 0)

  // reduntant isNaN check
  if (isNaN(numericValue)) {
    return null
  }

  // allow only integers and 0
  if (numericValue % 1 !== 0) {
    return null
  }

  // allow only positive integers
  if (numericValue < 0) {
    return null
  }

  return numericValue
}

export function getDeviceTypeMeasurementOptions(
  deviceTypeMeasurementsMap,
  currentMeasurementTypes
) {
  const measurementTypeSet = new Set(currentMeasurementTypes)
  return Object.values(deviceTypeMeasurementsMap)
    .map(({ value, label }) => ({
      label,
      value
    }))
    .filter(({ value }) => !measurementTypeSet.has(value))
}

function getReadRegisters(deviceTypeMeasurements, oldReadRegisters) {
  let readRegisters = deviceTypeMeasurements.map(({ register }) => register)

  // get the old read register values and add them to the new read registers
  let index = 0
  while (index < readRegisters.length) {
    if (oldReadRegisters[index] === undefined) {
      break
    }
    readRegisters[index] = oldReadRegisters[index]
    index += 1
  }

  return readRegisters
}

export function getBaseDeviceTypeConfig(deviceTypeMeasurements, state) {
  let read_register_count = []
  let read_registers = []
  let read_measurementType = []
  let read_measurementUnit = []

  for (const measurement of deviceTypeMeasurements) {
    if (measurement) {
      const { register_count, register, value, unit } = measurement
      read_register_count.push(register_count)
      read_registers.push(register)
      read_measurementType.push(value)
      read_measurementUnit.push(unit)
    }
  }

  let nextReadRegisters = read_registers

  if (state.device.id) {
    // if the device already exists, we need to make sure that the read registers are the same
    // however if a user changes the sensor type, we need to update the read registers
    nextReadRegisters = getReadRegisters(
      deviceTypeMeasurements,
      state.device.config?.read_registers
    )
  }

  const newConfig = {
    ...state.device.config,
    read_registers: nextReadRegisters,
    read_measurementType,
    read_measurementUnit
  }

  if (state?.device?.config?.read_register_count?.length > 0) {
    newConfig['read_register_count'] = read_register_count
  }
  return newConfig
}

export function getZonePath(zone) {
  if (!zone.parentPath) {
    return `/${zone.id}`
  }
  return zone.parentPath
}

function handleCleanString(string) {
  let newString = string
  if (typeof newString === 'string') {
    newString.trim()
  }
  return newString
}

function handleCleanStringArray(string) {
  let cleanType = string

  if (typeof cleanType === 'string') {
    cleanType = cleanType.split(',')
    if (Array.isArray(cleanType)) {
      let newArray = []
      for (let value of cleanType) {
        if (typeof value === 'string') newArray.push(value.trim())
      }
      cleanType = newArray
    }
  }

  return cleanType
}

function handleCleanInt(string) {
  const parsedInt = parseInt(string, 10)
  return Number.isNaN(parsedInt) ? string : parsedInt
}

const handleCleanRegisters = string => {
  let cleanRegisters = string

  if (typeof cleanRegisters === 'string') {
    cleanRegisters = cleanRegisters.split(',')
    if (Array.isArray(cleanRegisters)) {
      let newArray = []
      for (let value of cleanRegisters) {
        const parsedInt = parseInt(value.trim(), 10)
        if (!Number.isNaN(parsedInt)) {
          newArray.push(parsedInt)
        }
      }
      cleanRegisters = newArray
    }
  }

  return cleanRegisters
}

export function cleanModbusJSON(modbusConfig, isMulti) {
  let newConfig = { ...modbusConfig }

  newConfig = {
    ...newConfig,
    port_address: handleCleanString(newConfig['port_address']),
    slave_address: handleCleanInt(newConfig['slave_address']),
    baudrate: handleCleanInt(newConfig['baudrate']),
    bytesize: handleCleanInt(newConfig['bytesize']),
    stopbits: handleCleanInt(newConfig['stopbits']),
    timeout: handleCleanInt(newConfig['timeout']),
    read_registers: handleCleanRegisters(newConfig['read_registers']),
    read_measurementType: handleCleanStringArray(
      newConfig['read_measurementType']
    ),
    read_measurementUnit: handleCleanStringArray(
      newConfig['read_measurementUnit']
    ),
    function_code: handleCleanInt(newConfig['function_code']) ?? 3
  }

  if (isMulti) {
    newConfig['read_register_count'] = handleCleanStringArray(
      newConfig['read_register_count']
    )
  } else {
    delete newConfig['read_register_count']
  }

  return newConfig
}

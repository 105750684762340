import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { getCognitoData, makeGraphqlRequest } from '../utils'

export const createReleaseNote = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createReleaseNote(
        $title: String!
        $items: [ReleaseNoteItemInput]
        $tags: [String]
        $targetLangs: [String]
      ) {
        createReleaseNote(
          title: $title
          items: $items
          tags: $tags
          targetLangs: $targetLangs
        ) {
          releaseNoteId
          title
          publishDate
          visible
          items {
            itemId
            sectionId
            section {
              name
            }
            text
          }
          tags
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    return { releaseNotes: data?.createReleaseNote, error }
  } catch (err) {
    return { releaseNotes: null, error: err.message }
  }
}

export const updateReleaseNote = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateReleaseNote(
        $releaseNoteId: String!
        $title: String!
        $publishDate: Int
        $visible: Boolean
        $items: [ReleaseNoteItemInput]
        $tags: [String]
      ) {
        updateReleaseNote(
          releaseNoteId: $releaseNoteId
          title: $title
          publishDate: $publishDate
          visible: $visible
          items: $items
          tags: $tags
        ) {
          releaseNoteId
          title
          publishDate
          visible
          items {
            itemId
            sectionId
            section {
              name
            }
            text
          }
          tags
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    return { releaseNote: data?.updateReleaseNote, error }
  } catch (err) {
    return { releaseNote: null, error: err.message }
  }
}

export const getReleaseNotes = async () => {
  try {
    const query = /* GraphQL */ `
      query getReleaseNotes {
        getReleaseNotes {
          releaseNoteId
          title
          titleTranslations {
            lang
            text
          }
          publishDate
          visible
          items {
            itemId
            sectionId
            section {
              name
            }
            text
            textTranslations {
              lang
              text
            }
          }
          tags
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    return { releaseNotes: data?.getReleaseNotes, error }
  } catch (err) {
    return { releaseNotes: null, error: err.message }
  }
}

export const getReleaseNotesByLang = async params => {
  try {
    const query = /* GraphQL */ `
      query getReleaseNotesByLang($lang: String!) {
        getReleaseNotesByLang(lang: $lang) {
          releaseNoteId
          title
          publishDate
          visible
          items {
            itemId
            sectionId
            section {
              name
            }
            text
          }
          tags
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    return { releaseNotesByLang: data?.getReleaseNotesByLang, error }
  } catch (err) {
    return { releaseNotesByLang: null, error: err.message }
  }
}

export const createReleaseNoteSection = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createReleaseNoteSection($name: String!) {
        createReleaseNoteSection(name: $name) {
          sectionId
          name
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    return { section: data?.createReleaseNoteSection, error }
  } catch (err) {
    return { section: null, error: err.message }
  }
}

export const updateReleaseNoteSection = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateReleaseNoteSection($name: String!, $sectionId: String!) {
        updateReleaseNoteSection(name: $name, sectionId: $sectionId) {
          sectionId
          name
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    return { section: data?.updateReleaseNoteSection, error }
  } catch (err) {
    return { section: null, error: err.message }
  }
}

export const getReleaseNoteSections = async () => {
  try {
    const query = /* GraphQL */ `
      query getReleaseNoteSections {
        getReleaseNoteSections {
          sectionId
          name
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    return { sections: data?.getReleaseNoteSections, error }
  } catch (err) {
    return { sections: null, error: err.message }
  }
}

export const redoReleaseNoteTranslations = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation redoReleaseNoteTranslations(
        $releaseNoteId: String!
        $targetLangs: [String]
      ) {
        redoReleaseNoteTranslations(
          releaseNoteId: $releaseNoteId
          targetLangs: $targetLangs
        ) {
          releaseNoteId
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    return {
      translationsRequested: data?.redoReleaseNoteTranslations?.releaseNoteId,
      error
    }
  } catch (err) {
    return { translationsRequested: null, error: err.message }
  }
}

import React, { Fragment, useEffect, useReducer, useState } from 'react'
import { useDispatch } from 'react-redux'
import { I18n } from 'aws-amplify'

import {
  Button,
  FlexV2,
  Input,
  Text,
  Slot,
  Label,
  Separator
} from '@/primitives'
import { Dialog, InputError, Select } from '@/elements'
import { getValidationErrorMap } from '@/Util/GeneralUtils'
import {
  getSupportEmailSent,
  getSupportEmailError,
  getShowBanner,
  getSupportEmailSending
} from '@/reducers/selectors'

import { reducer } from './reducer'
import { initialState } from './state'
import { FIELDS, SUPPORT_TYPES, SCHEMA } from './config'

import Strings from './Strings'
import { sendEmail, clearEmail } from '@/slices/support'
import { showBanner } from '@/slices/util'

import RecommendedArticles from './RecommendedArticles'

import { useAuth } from '@/contexts/auth-context'

import './Form.scss'

const HELP_TLD = window.ENV.ENV === 'dev' ? '.uk' : '.ag'

export default function SupportForm({ toggle, formOpen }) {
  const strings = Strings()

  const reduxDispatch = useDispatch()
  const auth = useAuth()

  const [state, dispatch] = useReducer(reducer, initialState)
  const [showForm, setShowForm] = useState(false)
  const [errors, setErrors] = useState({})

  const emailSent = getSupportEmailSent()
  const emailError = getSupportEmailError()
  const banner = getShowBanner()
  const sending = getSupportEmailSending()

  const cognitoEmail = auth.getCognitoEmail()
  const maxDescriptionLength = 255

  useEffect(() => {
    if (emailSent?.status === 200) {
      const bannerDetails = {
        show: true,
        message: I18n.get('Support request successfully sent.'),
        type: 'success'
      }
      reduxDispatch(showBanner(bannerDetails))
      reduxDispatch(clearEmail())
      toggle()
    }

    if (emailError) {
      const bannerDetails = {
        show: true,
        message: I18n.get('Email could not be sent.'),
        type: 'error'
      }
      reduxDispatch(showBanner(bannerDetails))
      reduxDispatch(clearEmail())
    }
  }, [emailError, emailSent?.status, reduxDispatch])

  useEffect(() => {
    dispatch({ type: 'email', value: cognitoEmail })
  }, [cognitoEmail])

  const onChangeInput = ({ target: { name, value } }) => {
    setErrors({ ...errors, [name]: null })
    dispatch({ type: name, value })
  }

  const onChangeDescription = ({ target: { name, value } }) => {
    setErrors({ ...errors, [name]: null })
    if (value.length <= maxDescriptionLength) {
      dispatch({ type: name, value })
    }
  }

  const onChangeSupportType = selectedOption => {
    dispatch({ type: FIELDS.supportType, value: selectedOption.value })
  }

  const onSubmit = async event => {
    event.preventDefault()

    try {
      setErrors({})
      await SCHEMA().validate(state, { abortEarly: false })

      const payload = {
        ...state,
        supportType: SUPPORT_TYPES.find(
          type => type.value === state.supportType
        ).label,
        userId: auth.getCognitoUsername() ?? 'anonymous'
      }

      reduxDispatch(sendEmail(payload))
    } catch (error) {
      setErrors(getValidationErrorMap(error))
    }
  }

  const onToggleForm = () => {
    setShowForm(s => !s)
    if (banner.show) {
      const bannerDetails = { show: false, message: '', type: '' }
      reduxDispatch(showBanner(bannerDetails))
    }
  }

  const goBack = e => {
    e.preventDefault()
    setShowForm(false)
    if (banner.show) {
      const bannerDetails = { show: false, message: '', type: '' }
      reduxDispatch(showBanner(bannerDetails))
    }
  }

  return (
    <Dialog
      closeOutside={true}
      lockScroll={true}
      type='offcanvas'
      open={formOpen}
      onOpenChange={toggle}
    >
      <Slot name='title'>
        <FlexV2 direction='column'>
          <Text
            variant='page'
            tone={900}
            style={{
              fontSize: '18px'
            }}
          >
            {strings.title}
          </Text>
          <Text
            variant='page'
            tone={600}
            style={{ opacity: 0, height: 0, lineHeight: 1.2 }}
          >
            {strings.visit} {strings.helpDesk} {strings.fillForm}
          </Text>
        </FlexV2>
      </Slot>
      <Slot name='content'>
        <Separator style={{ marginBottom: '1.5em' }} />
        {showForm && (
          <FlexV2 direction='column' axisGap={400}>
            <FlexV2 direction='column' axisGap={400}>
              <Label style={{ margin: 0 }}>
                <Text variant='page' tone={600} size={100} fontWeight={700}>
                  {strings.fullName}
                </Text>
                <Input
                  name={FIELDS.fullName}
                  value={state.fullName}
                  placeholder={strings.fullNamePlaceholder}
                  onChange={onChangeInput}
                  required
                />
                <InputError error={errors?.fullName} />
              </Label>

              <Label style={{ margin: 0 }}>
                <Text variant='page' tone={600} size={100} fontWeight={700}>
                  {strings.emailAddress}
                </Text>
                <Input
                  name={FIELDS.email}
                  value={state.email}
                  placeholder={strings.emailAddressPlaceholder}
                  onChange={onChangeInput}
                  disabled={cognitoEmail}
                  required
                />
                <InputError error={errors?.email} />
              </Label>

              <Separator />

              <Text variant='page' tone={900} size={300} fontWeight={500}>
                {strings.supportDetails}
              </Text>

              <Label style={{ margin: 0 }}>
                <FlexV2 direction={'column'} axisGap={300}>
                  <Text variant='page' tone={600} size={100} fontWeight={700}>
                    {strings.supportType}
                  </Text>
                  <Select
                    style={{ marginTop: '6px' }}
                    name={FIELDS.supportType}
                    value={state.supportType}
                    placeholder={strings.supportTypePlaceholder}
                    onChange={onChangeSupportType}
                    options={SUPPORT_TYPES?.map(({ value, label }) => ({
                      value,
                      label
                    }))}
                  />
                </FlexV2>
              </Label>

              <Label style={{ margin: 0 }}>
                <Text variant='page' tone={600} size={100} fontWeight={700}>
                  {strings.supportSubject}
                </Text>
                <Input
                  name={FIELDS.supportSubject}
                  value={state.supportSubject}
                  placeholder={strings.supportSubjectPlaceholder}
                  onChange={onChangeInput}
                  required
                />
                <InputError error={errors?.supportSubject} />
              </Label>

              <Label style={{ margin: 0 }}>
                <FlexV2 direction='column' axisGap={300}>
                  <Text variant='page' tone={600} size={100} fontWeight={700}>
                    {strings.describeProblem}
                  </Text>
                  <Input
                    as='textarea'
                    name={FIELDS.describeProblem}
                    value={state.describeProblem}
                    placeholder={strings.describeProblemPlaceholder}
                    onChange={onChangeDescription}
                  />
                  <FlexV2 alignCrossAxis='center' alignMainAxis='space-between'>
                    <InputError error={errors?.describeProblem} />
                    <Text variant='page' tone={600} size={100} fontWeight={700}>
                      {state.describeProblem.length}/{maxDescriptionLength}
                    </Text>
                  </FlexV2>
                </FlexV2>
              </Label>
            </FlexV2>
          </FlexV2>
        )}

        {!showForm && (
          <Fragment>
            <RecommendedArticles />
          </Fragment>
        )}
      </Slot>
      <Slot name='actions'>
        <FlexV2 alignCrossAxis='center' alignMainAxis='space-between'>
          {showForm && (
            <Fragment>
              <Button onClick={goBack} disabled={sending}>
                {strings.back}
              </Button>
              <Button
                onClick={onSubmit}
                type='submit'
                variant='primary'
                loading={sending}
                style={{ minWidth: '10rem' }}
              >
                {strings.submitRequest}
              </Button>
            </Fragment>
          )}
          {!showForm && (
            <Text>
              <Text variant='page' tone={600} style={{ lineHeight: 1.2 }}>
                {I18n.get("Can't find what you're looking for? Visit the ")}
              </Text>
              <Text
                as='a'
                href={`https://help.coretex${HELP_TLD}`}
                target='_blank'
                rel='noreferrer'
              >
                {I18n.get('Help Centre')}
              </Text>{' '}
              <Text variant='page' tone={600} style={{ lineHeight: 1.2 }}>
                {I18n.get('or')}
              </Text>{' '}
              <Text
                onClick={onToggleForm}
                variant='primary'
                tone={700}
                className='SendMessageLink'
              >
                {I18n.get('Send us a Message')}
              </Text>
            </Text>
          )}
        </FlexV2>
      </Slot>
    </Dialog>
  )
}

import { Fragment } from 'react'

import { FlexV2, Separator } from '@/primitives'

import HeaderControls from './Controls'
import LayoutManager from './LayoutManager'

import { useDashboardParams } from '../../utils/useDashboardParams'
import { useDashboard } from '../context'

import { isSome404 } from '../utils'

import './index.scss'

export default function Header() {
  const { state } = useDashboard()
  const params = useDashboardParams()

  const has404 = isSome404(state.is404)

  const canUseLayoutManager = !params?.sensorId

  return (
    <FlexV2 className='Dashboard__Header' direction='column' style={{ marginTop: '1rem' }}>
      {state.hierarchy && (
        <Fragment>
          {state.currentZone && state.currentDashboard && state.hierarchy && (
            <FlexV2
              className='Dashboard__Header__Container'
              alignMainAxis='space-between'
              style={!canUseLayoutManager ? { alignSelf: 'end' } : null}
            >
              {canUseLayoutManager && !has404 && <LayoutManager />}
              {!has404 && <HeaderControls />}
            </FlexV2>
          )}
          <Separator style={{ marginBottom: 0, marginTop: '1em' }} />
        </Fragment>
      )}
    </FlexV2>
  )
}

import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { updateCurrentMobileDashboardData } from '@/actions/ts/dashboard'
import {
  getDeviceTypes,
  getMeasurements,
  getZoneHierarchyLoading
} from '@/reducers/selectors'

import { useDashboardParams } from '../../utils/useDashboardParams'
import { sortMeasurementsForDashboard } from '@/Util/MeasurementUtils'
import {
  getAllReadMeasurements,
  getAllReadMeasurementsForDevice
} from '@/Util/ZoneUtils'
import { getChildIds } from '../../utils'

import { useAuth } from '@/contexts/auth-context'

export const BASE_MEASUREMENTS = [
  'temperature',
  'pressure',
  'humidity',
  'carbon_dioxide',
  'par',
  'uv_index',
  'conductivity',
  'ph',
  'water_temperature',
  'dissolved_oxygen'
]

// Only allow one socket connection at a time
export const useLiveDataFeed = state => {
  const dispatch = useDispatch()
  const auth = useAuth()
  const params = useDashboardParams()

  const allMeasurements = getMeasurements()
  const allDeviceTypes = getDeviceTypes()
  const zoneHierarchyLoading = getZoneHierarchyLoading()

  const socketRef = useRef(null)
  const zoneIdRef = useRef(null)

  const authToken = auth.getCognitoAuthToken()

  useEffect(() => {
    const zoneId = params?.sensorId || state?.currentZone?.id

    if (
      zoneId &&
      authToken &&
      state.hierarchy &&
      !zoneHierarchyLoading &&
      allMeasurements
    ) {
      if (zoneId !== zoneIdRef.current) {
        if (socketRef.current) {
          socketRef.current.close()
          socketRef.current = null
        }

        if (zoneId) {
          const baseUrl = createBaseUrl(zoneId, state?.currentZone)
          const availableMeasurements = getAvailableMeasurements(
            state.hierarchy,
            state?.currentZone,
            state?.siteId,
            allDeviceTypes,
            allMeasurements,
            params
          )

          const measurementsUri = `measurements=${availableMeasurements.join(
            ','
          )}`
          const authUri = `authorization=${authToken}`
          const userId = `userId=${auth.getCognitoUsername()}`
          const websocketUrl = `${baseUrl}&${measurementsUri}&${userId}&${authUri}`

          const socket = new WebSocket(websocketUrl)

          socket.onmessage = event => {
            const data = JSON.parse(event.data)
            if (data) {
              dispatch(updateCurrentMobileDashboardData(data))
            }
          }

          socket.onclose = () => {
            if (socketRef.current === socket) {
              socketRef.current = null
            }
          }

          socketRef.current = socket
          zoneIdRef.current = zoneId
        }
      }
    }
  }, [
    params,
    state?.currentZone?.id,
    authToken,
    state.hierarchy,
    zoneHierarchyLoading,
    allMeasurements,
    allDeviceTypes,
    dispatch,
    state?.currentZone,
    state?.siteId
  ])

  useEffect(() => {
    return () => {
      if (socketRef.current) {
        socketRef.current.close()
        socketRef.current = null
      }
    }
  }, [])
}

const createBaseUrl = (zoneId, currentZone) => {
  let allIds = []
  if (!currentZone) {
    allIds = [zoneId]
  }

  if (currentZone) {
    const childIds = getChildIds(currentZone?.children)
    const sensorIds = currentZone?.devices?.map(({ id }) => id)
    allIds = [...childIds, ...sensorIds, zoneId]
  }

  const allZonesAndSensors = `&zones=${allIds.join(',')}`
  return `${window.ENV.WEB_SOCKET_URL}/ts/?zone=${zoneId}${allZonesAndSensors}`
}

const getAvailableMeasurements = (
  hierarchy,
  currentZone,
  siteId,
  allDeviceTypes,
  allMeasurements,
  params
) => {
  let allReadMeasurements = []
  const sensorId = params?.sensorId ?? null
  const zoneHierarchy = { [siteId]: hierarchy }
  if (hierarchy?.id && sensorId) {
    allReadMeasurements = getAllReadMeasurementsForDevice(
      zoneHierarchy,
      siteId,
      sensorId,
      allDeviceTypes
    )
  } else if (hierarchy?.id && currentZone?.id) {
    allReadMeasurements = getAllReadMeasurements(
      zoneHierarchy,
      siteId,
      currentZone?.id,
      allDeviceTypes
    )
  }
  if (allReadMeasurements?.length > 0) {
    return sortMeasurementsForDashboard(
      allMeasurements,
      allReadMeasurements
    ).map(measurement => measurement.id)
  }

  return BASE_MEASUREMENTS
}

import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import createSagaMiddleware, { END } from 'redux-saga'
import thunkMiddleware from 'redux-thunk'

import rootReducer from './reducers'
import rootSaga from './sagas'

const sagaMiddleware = createSagaMiddleware()

let store = null

const getStore = async () => {
  let loggerMiddleware = null
  const ENV = import.meta.env.MODE

  if (ENV === 'development') {
    const { createLogger } = await import('redux-logger')
    loggerMiddleware = createLogger()
  }

  const middleware = [thunkMiddleware, sagaMiddleware, loggerMiddleware].filter(
    Boolean
  )

  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose

  const enhancer = composeEnhancers(applyMiddleware(...middleware))

  store = createStore(
    combineReducers({
      rootReducer
    }),
    enhancer
    //applyMiddleware(...middleware)
  )

  sagaMiddleware.run(rootSaga)
  store.close = () => store.dispatch(END)

  return store
}

export default getStore

import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { resetZoneDataStatusMenu } from '@/slices/management/zone'

import {
  getSensorThresholdStatuses,
  getZonesHierarchy
} from '@/reducers/selectors'

import { getZoneName } from '@/Util/ZoneUtils'

function hasStatuses(rootStatuses, isRoot, parentId) {
  if (isRoot) {
    return rootStatuses?.length > 0
  }

  if (parentId) {
    return rootStatuses?.filter(({ rootId }) => rootId === parentId)?.length > 0
  }

  return false
}

const withStatus = Component => {
  const WithStatus = ({ isRoot = false, parentId, ...props }) => {
    const dispatch = useDispatch()
    const location = useLocation()

    const rootStatuses = getSensorThresholdStatuses()
    const zonesHierarchy = getZonesHierarchy()

    const [zoneStatuses, setZoneStatuses] = useState({})

    useEffect(() => {
      return () => {
        if (isRoot) {
          const locationParts = location?.pathname?.split('/')
          if (locationParts?.includes('zones') && locationParts?.length === 2) {
            dispatch(resetZoneDataStatusMenu())
          }
        }
      }
    }, [dispatch, isRoot, location?.pathname])

    useEffect(() => {
      if (isRoot && Object.keys(zonesHierarchy)?.length > 0) {
        let newZoneStatuses = {}
        rootStatuses?.forEach(status => {
          if (!newZoneStatuses.hasOwnProperty(status.rootId)) {
            newZoneStatuses[status.rootId] = {
              name: getZoneName(zonesHierarchy, status.rootId),
              statuses: []
            }
          }

          newZoneStatuses[status.rootId].statuses.push(status)
        })

        setZoneStatuses(newZoneStatuses)
      }
    }, [rootStatuses, isRoot, zonesHierarchy])

    useEffect(() => {
      if (parentId && Object.keys(zonesHierarchy)?.length > 0) {
        let newZoneStatuses = {}
        rootStatuses?.forEach(status => {
          if (status.rootId === parentId) {
            if (!newZoneStatuses.hasOwnProperty(status.rootId)) {
              newZoneStatuses[status.rootId] = {
                name: getZoneName(zonesHierarchy, status.rootId),
                statuses: []
              }
            }

            newZoneStatuses[status.rootId].statuses.push(status)
          }
        })

        setZoneStatuses(newZoneStatuses)
      }
    }, [rootStatuses, parentId, zonesHierarchy])

    return (
      <Component
        parentId={parentId}
        hasStatuses={hasStatuses(rootStatuses, isRoot, parentId)}
        measurementStatuses={[]}
        zoneStatuses={zoneStatuses}
        {...props}
      />
    )
  }

  WithStatus.displayName = `WithStatus(${
    Component.displayName || Component.name || 'Component'
  })`

  return WithStatus
}

export default withStatus

import { I18n } from 'aws-amplify'

const Strings = () => ({
  dataApiKeys: I18n.get('Data API keys'),
  apiKey: I18n.get('API key'),
  organizationName: I18n.get('Organization name'),
  organizationId: I18n.get('Organization ID'),
  contactSupport: I18n.get('Please contact support'),
  loading: I18n.get('Loading...'),
  apiDocsLink: I18n.get('Data API Reference'),
  delete: I18n.get('delete'),
  cancel: I18n.get('Cancel'),
  regenerate: I18n.get('Regenerate'),
  regenerateConfirmButton: I18n.get('Regenerate API key'),
  regenerateConfirmFragment1: I18n.get('To proceed, enter'),
  regenerateConfirmFragment2: I18n.get('below, then click'),
  generateNewKey: I18n.get('Generate new API key'),
  generateNewKeySubtitle: I18n.get('Create a new API key for'),
  inputDeletePlaceholder: I18n.get('Type the word'),
  warning: I18n.get('Warning'),
  warningDescription: I18n.get('Regenerating the API key will invalidate the old key. Any applications currently using the old key will break. This action cannot be undone.'),
  buttonApiDescription: I18n.get('Check the API Reference for more information on how to use the Data API')
})

export default Strings

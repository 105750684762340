import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { getCognitoData, makeGraphqlRequest } from '../utils'

// Queries

export const getCurrentAveMinMax = async params => {
  try {
    const query = /* GraphQL */ `
      query currentAverageMinMaxQuery(
        $organizationId: String
        $siteId: String
        $table: String!
        $sensorId: String!
        $measurements: [String]
        $isCellular: Boolean
      ) {
        currentAverageMinMaxQuery(
          organizationId: $organizationId
          siteId: $siteId
          table: $table
          sensorId: $sensorId
          measurements: $measurements
          isCellular: $isCellular
        ) {
          rows
          columns
          types
        }
      }
    `

    const { id, idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken,
      id
    )

    if (error) {
      return {
        currentAverageMinMax: null,
        error: `Current data not fetched: ${error}`
      }
    }

    return {
      currentAverageMinMax: data?.currentAverageMinMaxQuery,
      error: null
    }
  } catch (err) {
    return { currentAverageMinMax: null, error: err.message }
  }
}

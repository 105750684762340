import { Fragment } from 'react'
import classNames from 'classnames'

import history from '../../../history'
import { FlexV2, Symbol } from '@/primitives'

import CrumbTitle from './CrumbTitle'
import CrumbTag from './CrumbTag'

export default function Crumb({
  crumb,
  to,
  index,
  onClickCrumb,
  lastItem = false,
  hasOptions = false,
  showTag = false,
  device = null
}) {
  const goToPage = () => {
    if (!lastItem) {
      if (onClickCrumb) {
        onClickCrumb(crumb)
      }
      if (to) {
        history.push(to)
      }
    }
  }

  return (
    <Fragment>
      <FlexV2
        className={classNames(
          'SlimNavBreadcrumb__Crumb',
          lastItem && 'SlimNavBreadcrumb__Crumb--lastItem'
        )}
        direction='column'
        axisGap={100}
        onClick={goToPage}
      >
        <CrumbTitle
          index={index}
          title={crumb.name}
          to={to}
          lastItem={lastItem}
        />
        {showTag && <CrumbTag device={device} isSensor={crumb.isDevice} />}
      </FlexV2>

      {((lastItem && hasOptions) || !lastItem) && (
        <FlexV2 className='SlimNavBreadcrumb__Crumb__Item__Divider'>
          <Symbol
            name='arrow_forward_ios'
            variant='page'
            tone={400}
            size={200}
          />
        </FlexV2>
      )}
    </Fragment>
  )
}

import { useCallback, useEffect, useState } from 'react'

import { Select } from '../../../../primitives'

const EntriesFilter = ({
  state,
  dispatchState,
  stateKey,
  entries,
  defaultText = 'All'
}) => {
  const [entryFilter, setEntryFilter] = useState(
    state[stateKey].length === 1 ? state[stateKey][0] : 'all'
  )
  const [lastFilter, setLastFilter] = useState(entryFilter)

  const getEntriesFilter = useCallback(() => {
    return entryFilter === 'all' ? entries.map(i => i[0]) : [entryFilter]
  }, [entryFilter, entries])

  const dispatchChange = useCallback(() => {
    dispatchState({
      [stateKey]: getEntriesFilter()
    })
  }, [dispatchState, stateKey, getEntriesFilter])

  useEffect(() => {
    if (
      entryFilter === 'all' &&
      state[stateKey].length === 0 &&
      entries.length > 0
    ) {
      dispatchChange()
    }
  }, [state, stateKey, entries, dispatchChange, entryFilter])

  useEffect(() => {
    if (entries.length > 0) {
      if (entryFilter !== lastFilter) {
        setLastFilter(entryFilter)
        dispatchChange()
      }
    }
  }, [entryFilter, entries, dispatchChange, lastFilter])

  const onChangeFilter = e => {
    setEntryFilter(e.currentTarget.value)
  }

  return (
    <Select
      className='Operations__Header__Select'
      name={`${stateKey}Filter`}
      value={entryFilter}
      onChange={onChangeFilter}
    >
      <option default value='all'>
        {defaultText}
      </option>
      {entries.map(([id, name]) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </Select>
  )
}

export default EntriesFilter

import { makeActionCreator } from '@/Util/ReduxUtils'

export const REQUEST_ALL_AREAS = 'REQUEST_ALL_AREAS'
export const requestAllAreas = makeActionCreator(REQUEST_ALL_AREAS)

export const RECEIVE_ALL_AREAS = 'RECEIVE_ALL_AREAS'
export const receiveAllAreas = makeActionCreator(
  RECEIVE_ALL_AREAS,
  'areas',
  'count',
  'error'
)

export const REQUEST_AREAS = 'REQUEST_AREAS'
export const requestAreas = makeActionCreator(REQUEST_AREAS, 'params')

export const RECEIVE_AREAS = 'RECEIVE_AREAS'
export const receiveAreas = makeActionCreator(
  RECEIVE_AREAS,
  'areas',
  'count',
  'error'
)

export const CREATE_AREA = 'CREATE_AREA'
export const createArea = makeActionCreator(CREATE_AREA, 'params')

export const AREA_CREATED = 'AREA_CREATED'
export const areaCreated = makeActionCreator(AREA_CREATED, 'area', 'error')

export const UPDATE_AREA = 'UPDATE_AREA'
export const updateArea = makeActionCreator(UPDATE_AREA, 'params')

export const AREA_UPDATED = 'AREA_UPDATED'
export const areaUpdated = makeActionCreator(AREA_UPDATED, 'area', 'error')

export const REQUEST_AREA = 'REQUEST_AREA'
export const requestArea = makeActionCreator(REQUEST_AREA, 'params')

export const RECEIVE_AREA = 'RECEIVE_AREA'
export const receiveArea = makeActionCreator(RECEIVE_AREA, 'area', 'error')

export const CLEAR_AREA = 'CLEAR_AREA'
export const clearArea = makeActionCreator(CLEAR_AREA)

export const CLEAR_AREAS = 'CLEAR_AREAS'
export const clearAreas = makeActionCreator(CLEAR_AREAS)

export const SET_AREA = 'SET_AREA'
export const setArea = makeActionCreator(SET_AREA, 'area')

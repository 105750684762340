import withEditableWidget from '@/components/DashboardPageV2/Desktop/Widgets/Shared/HOC/withEditableWidget'
import { MeasurementEdit } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Measurement'
import {
  DATASET_QUERY,
  LIVE_QUERY
} from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'
import { getMeasurements } from '@/reducers/selectors'

import LiveValue from './LiveValue'
import DatasetLine from './DatasetLine'

function VapourPressureDeficitView(props) {
  const { config, options } = props

  const measurement = options.measurement
  const measurements = getMeasurements()

  const unit = measurements?.find(m => m.id === measurement)?.unit

  if (config.query === LIVE_QUERY) {
    return <LiveValue unit={unit} config={config} options={options} />
  }
  if (config.query === DATASET_QUERY) {
    return (
      <DatasetLine unit={unit} config={config} options={options} {...props} />
    )
  }
}

export const VapourPressureDeficitWidget = withEditableWidget(
  VapourPressureDeficitView,
  MeasurementEdit
)

import Live from '../Query/Live'
import {
  AVERAGE_QUERY,
  DATASET_QUERY,
  LIVE_QUERY
} from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'
import Average from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/Query/Average'
import Dataset from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/Query/Dataset'

export default function DefaultDynamicView({
  config,
  unit,
  valueModifierCallback,
  ...props
}) {
  if (config.query === LIVE_QUERY) {
    return (
      <Live
        {...props}
        config={config}
        unit={unit}
        valueModifierCallback={valueModifierCallback}
      />
    )
  }

  if (config.query === AVERAGE_QUERY) {
    return (
      <Average
        {...props}
        config={config}
        unit={unit}
        valueModifierCallback={valueModifierCallback}
      />
    )
  }

  if (config.query === DATASET_QUERY) {
    return (
      <Dataset
        {...props}
        config={config}
        unit={unit}
        valueModifierCallback={valueModifierCallback}
      />
    )
  }

  throw Error('Invalid query type')
}

import { useState, useEffect } from 'react'

import {
  Box,
  Button,
  Flex,
  Input,
  Row,
  Cell,
  Select
} from '../../../../../../../primitives'

import InputError from '../../../../../../../elements/InputError'

import { FIELDS } from './config'
import Strings from './Strings'

import { REMOVE_ITEM, UPDATE_ITEM } from '../state'

const initialFormState = {
  id: '',
  saleItemId: '',
  quantity: ''
}

function SaleShipmentItemsForm({
  sale,
  saleShipment,
  item,
  errors,
  index,
  dispatchFormState
}) {
  const strings = Strings()

  const [formState, setFormState] = useState(initialFormState)

  const saleItemIdError = errors?.[`items[${index}].saleItemId`] ?? null
  const quantityError = errors?.[`items[${index}].quantity`] ?? null

  useEffect(() => {
    setFormState({
      ...initialFormState,
      ...item
    })
  }, [item])

  const handleInput = e => {
    const { name, value } = e.currentTarget
    let newFormState = { ...formState, [name]: value }
    dispatchFormState({ type: UPDATE_ITEM, item: newFormState })
  }

  const handleNumericInput = e => {
    const { name, value } = e.currentTarget
    const newValue = value.length > 0 ? Number(value) : ''
    let newFormState = { ...formState, [name]: newValue }
    dispatchFormState({ type: UPDATE_ITEM, item: newFormState })
  }

  const removeItem = e => {
    e.preventDefault()
    dispatchFormState({ type: REMOVE_ITEM, item })
  }

  const filterSaleItems = () => {
    return sale.saleItems.filter(saleItem => {
      if (saleItem.id === item.saleItemId) {
        return true
      } else {
        for (let shipmentItem of saleShipment.items) {
          if (shipmentItem.saleItemId === saleItem.id) {
            return false
          }
        }
      }
      return true
    })
  }

  return (
    <Row>
      <Cell columnName={strings.product}>
        <Flex axisGap={300} direction='column'>
          <Select
            className='Operations__Select'
            name={FIELDS.saleItemId}
            value={formState.saleItemId}
            onChange={handleInput}
          >
            <option default value=''>
              {strings.selectDefault}
            </option>
            {filterSaleItems().map(({ id, product }) => (
              <option key={id} value={id}>
                {product.name} ({product.weight}
                {strings.grams})
              </option>
            ))}
          </Select>
          <InputError error={saleItemIdError} />
        </Flex>
      </Cell>
      <Cell columnName={strings.quantity}>
        <Flex axisGap={300} direction='column'>
          <Input
            className='Operations__Input'
            type='number'
            name={FIELDS.quantity}
            value={formState.quantity}
            onChange={handleNumericInput}
          />
          <InputError error={quantityError} />
        </Flex>
      </Cell>
      <Cell style={{ flex: '0 1 0', flexShrink: 1 }}>
        <Box style={{ marginTop: '0.2em', textAlign: 'right' }}>
          <Button
            variant='error'
            size='small'
            iconBefore='cancel'
            onClick={removeItem}
          />
        </Box>
      </Cell>
    </Row>
  )
}

export default SaleShipmentItemsForm

import { makeOperationsApiCall } from './utils'

/* MUTATIONS */

export const createGrade = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createGrade($organizationId: String!, $name: String!) {
        createGrade(organizationId: $organizationId, name: $name) {
          id
          organizationId
          name
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'createGrade',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updateGrade = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateGrade(
        $gradeId: String!
        $organizationId: String!
        $name: String!
      ) {
        updateGrade(
          gradeId: $gradeId
          organizationId: $organizationId
          name: $name
        ) {
          id
          organizationId
          name
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'updateGrade',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

/* QUERIES */

export const getGrades = async params => {
  try {
    const query = /* GraphQL */ `
      query getGrades(
        $filter: GetGradesFilter
        $limit: Int
        $offset: Int
        $order: OrderEnum
      ) {
        getGrades(
          filter: $filter
          limit: $limit
          offset: $offset
          order: $order
        ) {
          grades {
            id
            organizationId
            name
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getGrades',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getGrade = async params => {
  try {
    const query = /* GraphQL */ `
      query getGrade($gradeId: String!) {
        getGrade(gradeId: $gradeId) {
          id
          organizationId
          name
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getGrade',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

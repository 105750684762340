import { DateTime } from 'luxon'
import { useEffect, useState, Fragment, useCallback } from 'react'
import DatePicker from 'react-datepicker'

import { Flex, Text, Icon } from '../../../../primitives'

const DateFilter = ({
  state,
  dispatchState,
  stateKey,
  isDataboard = false
}) => {
  const [dateFilter, setDateFilter] = useState(state[stateKey])
  const [lastFilter, setLastFilter] = useState(dateFilter)
  const [onHover, setOnHover] = useState(false)

  const onMouseLeave = () => {
    setOnHover(false)
  }

  const dispatchChange = useCallback(() => {
    dispatchState({
      [stateKey]: dateFilter
    })
    onMouseLeave()
  }, [stateKey, dateFilter, dispatchState, onMouseLeave])

  useEffect(() => {
    if (dateFilter[0] && dateFilter[1]) {
      if (dateFilter !== lastFilter) {
        setLastFilter(dateFilter)
        dispatchChange()
      }
    }
  }, [dateFilter, lastFilter, dispatchChange])

  const formatDate = date => {
    if (date) {
      return DateTime.fromJSDate(date).toFormat('DD')
    }
    return null
  }

  const onMouseEnter = () => {
    setOnHover(true)
  }

  return (
    <Flex
      direction='row'
      axisGap={200}
      className='Operations__Filter__Dates'
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {onHover && !isDataboard && (
        <Fragment>
          <DatePicker
            selected={dateFilter[0]}
            className='Operations__Form__Select'
            onChange={value => {
              setDateFilter([value, dateFilter[1]])
            }}
            placeholderText={'From'}
            style={{ flexGrow: '1', maxWidth: '20rem' }}
          />
          <DatePicker
            selected={dateFilter[1]}
            className='Operations__Form__Select'
            onChange={value => {
              setDateFilter([dateFilter[0], value])
            }}
            placeholderText={'To'}
          />
        </Fragment>
      )}
      {!onHover && !isDataboard && (
        <Text className='Operations__Filter__Dates__Display'>
          {formatDate(dateFilter[0])} - {formatDate(dateFilter[1])}
          <Icon name='arrow_drop_down' />
        </Text>
      )}
      {isDataboard && (
        <Flex
          alignMainAxis='space-between'
          alignCrossAxis='center'
          direction='row'
          wrap='nowrap'
          axisGap={100}
        >
          <DatePicker
            selected={dateFilter[0]}
            className='Operations__Form__Select'
            onChange={value => {
              setDateFilter([value, dateFilter[1]])
            }}
            placeholderText={'From'}
            dateFormat='d MMM yyyy'
          />
          <Icon name='remove' />
          <DatePicker
            selected={dateFilter[1]}
            className='Operations__Form__Select'
            onChange={value => {
              setDateFilter([dateFilter[0], value])
            }}
            placeholderText={'To'}
            dateFormat='d MMM yyyy'
          />
        </Flex>
      )}
    </Flex>
  )
}

export default DateFilter

import { AdvancedMarker, useAdvancedMarkerRef } from '@vis.gl/react-google-maps'

import { Fragment, useEffect } from 'react'

import Pin from './Pin'

const CustomMarker = ({ device, setDevice, addMarker }) => {
  const [markerRef, marker] = useAdvancedMarkerRef()

  useEffect(() => {
    if (marker) {
      marker.id = device.id
      marker.status = device.status
      addMarker(marker)
    }
  }, [marker])

  const onClick = () => {
    setDevice(device)
  }

  return (
    <Fragment>
      <AdvancedMarker
        ref={markerRef}
        position={{ lat: device.lat, lng: device.lng }}
        onClick={onClick}
      >
        <Pin device={device} />
      </AdvancedMarker>
    </Fragment>
  )
}

export default CustomMarker

import { I18n } from 'aws-amplify'

const Strings = () => {
  return {
    absolute_humidity: I18n.get('Absolute Humidity'),
    actuator_state: I18n.get('Actuator State'),
    airflow: I18n.get('Airflow'),
    carbon_dioxide: I18n.get('Carbon Dioxide'),
    co2_flow_rate: I18n.get('CO2 Flow Rate'),
    conductivity: I18n.get('Conductivity'),
    current: I18n.get('Current (instantaneous) '),
    daily_light_integral: I18n.get('DLI'),
    dissolved_oxygen: I18n.get('Dissolved Oxygen'),
    dissolved_oxygen_percentage: I18n.get('Dissolved Oxygen Percentage'),
    energy: I18n.get('Energy'),
    energy_kw: I18n.get('Energy (kW)'),
    fan_speed: I18n.get('Fan Speed'),
    growing_degree_hours: I18n.get('GDH'),
    humidity: I18n.get('Relative Humidity'),
    ion_concentration: I18n.get('Ion Concentration'),
    line_frequency: I18n.get('Line Frequency'),
    line_voltage: I18n.get('Line Voltage'),
    liquidflow: I18n.get('Liquid Flow'),
    liquidflow_lm: I18n.get('Liquid Flow (L/m)'),
    liquid_level: I18n.get('Liquid Level'),
    motor_speed: I18n.get('Motor Speed'),
    par: I18n.get('PAR'),
    ph: I18n.get('pH'),
    ppfd: I18n.get('PPFD'),
    power_consumption: I18n.get('Power Consumption (instantaneous)'),
    power_generation: I18n.get('Power Generation (instantaneous)'),
    power_consumed: I18n.get('Power Consumed'),
    power_generated: I18n.get('Power Generated'),
    pressure: I18n.get('Air Pressure'),
    pump_speed: I18n.get('Pump Speed'),
    radiation_temperature_ratio: I18n.get('RTR'),
    salinity: I18n.get('Salinity'),
    soil_ec: I18n.get('Soil Conductivity'),
    soil_moisture: I18n.get('Soil Moisture'),
    soil_nitrogen: I18n.get('Soil N content'),
    soil_potassium: I18n.get('Soil K content'),
    soil_ph: I18n.get('Soil pH'),
    soil_phosphorus: I18n.get('Soil P content'),
    soil_temperature: I18n.get('Soil Temperature'),
    solar_irradiance: I18n.get('Solar Irradiance'),
    saturation_vapour_pressure: I18n.get('SVP'),
    temperature: I18n.get('Temperature'),
    total_dissolved_solids: I18n.get('TDS'),
    uv_c_intensity: I18n.get('UV-C Intensity'),
    uv_index: I18n.get('UV Index'),
    vapour_pressure_deficit: I18n.get('VPD'),
    vfd_frequency: I18n.get('VFD'),
    water_temperature: I18n.get('Water Temperature'),
    wet_bulb_temperature: I18n.get('Wet Bulb Temperature'),
    wind_direction: I18n.get('Wind Direction'),
    wind_speed: I18n.get('Wind Speed')
  }
}

export default Strings

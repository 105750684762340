import { I18n } from '@aws-amplify/core'

const Strings = () => ({
  goto: I18n.get('go to'),
  next: I18n.get('next'),
  last: I18n.get('last'),
  prev: I18n.get('previous'),
  first: I18n.get('first'),
  paginationNavigation: I18n.get('Pagination Navigation'),
  of: I18n.get('of'),
  items: I18n.get('items'),
  pages: I18n.get('pages'),
  page: I18n.get('page')
})

export default Strings
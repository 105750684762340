import { Box } from '../'
import './index.scss'

const toUnderBarCase = string =>
  string.trim().toLowerCase().split(' ').join('_')

/**
 * Icon Primitive
 * @param {String} props.name Any https://fonts.google.com/icons Icon Name
 * @param { 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 } props.size Icon size
 * @param { 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 } props.tone = Shade / Tint / Tone of the Variant
 * @param { 'page' | 'primary' | 'success' | 'graph'| 'info' | 'error' | 'warning' | 'danger' | 'neutral' } props.variant The Color of the icon
 */

const Icon = ({
  name = 'circle',
  size = null,
  variant = null,
  tone = null,

  ...rest
}) => {
  return (
    <Box
      as='span'
      aria-hidden='true'
      aria-roledescription={variant}
      className={[
        'Icon',
        'material-icons-outlined',
        {
          [`fs-${size}`]: size,
          [`cv-${variant}`]: variant,
          [`tn-${tone}`]: tone
        }
      ]}
      {...rest}
    >
      {toUnderBarCase(name)}
    </Box>
  )
}

export default Icon

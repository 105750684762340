import {
  getSensorThresholdStatusesByRootIds,
  deleteSensorThresholdStatus
} from '@/api/threshold/sensorThresholdStatus'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const fetchSensorThresholdStatusesByRoot = createAsyncThunk(
  'fetchSensorThresholdStatusesByRoot',
  async params => {
    return await getSensorThresholdStatusesByRootIds(params)
  }
)

export const sendDeleteSensorThresholdStatus = createAsyncThunk(
  'sendDeleteSensorThresholdStatus',
  async params => {
    return await deleteSensorThresholdStatus(params)
  }
)

const sensorThresholdStatus = createSlice({
  name: 'sensorThresholdStatus',
  initialState: {
    sensorThresholdStatusesByRoot: [],
    isLoading: false,
    error: null
  },
  reducers: {
    cleanSensorThresholdStatuses: state => {
      state.sensorThresholdStatusesByRoot = []
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSensorThresholdStatusesByRoot.pending, (state, action) => {
        state.error = null
        state.isLoading = true
      })
      .addCase(
        fetchSensorThresholdStatusesByRoot.fulfilled,
        (state, action) => {
          state.sensorThresholdStatusesByRoot = action.payload
          state.isLoading = false
        }
      )
      .addCase(fetchSensorThresholdStatusesByRoot.rejected, (state, action) => {
        state.error = action.error
        state.isLoading = false
      })
      .addCase(sendDeleteSensorThresholdStatus.pending, (state, action) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(sendDeleteSensorThresholdStatus.fulfilled, (state, action) => {
        const status = action.payload

        const nextSensorThresholdStatusesByRoot = [
          ...state.sensorThresholdStatusesByRoot
        ]

        const index = state.sensorThresholdStatusesByRoot.findIndex(
          ({ sensorId, measurementIdAndZoneId }) =>
            sensorId === status.sensorId &&
            measurementIdAndZoneId === status.measurementIdAndZoneId
        )

        if (index > -1) {
          nextSensorThresholdStatusesByRoot.splice(index, 1)
        }

        state.sensorThresholdStatusesByRoot = nextSensorThresholdStatusesByRoot
        state.isLoading = false
      })
      .addCase(sendDeleteSensorThresholdStatus.rejected, (state, action) => {
        state.error = action.error
        state.isLoading = false
      })
  }
})

export const { cleanSensorThresholdStatuses } = sensorThresholdStatus.actions

export default sensorThresholdStatus.reducer

import { useReducer, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import ReactSelect from 'react-select'

import useDeviceSize from '@/hooks/useDeviceSize'

import { requestAllProduces } from '@/actions/operations/produce'
import { requestAllVarieties } from '@/actions/operations/variety'
import { requestAllGrades } from '@/actions/operations/grade'
import { requestAllPackageUnits } from '@/actions/operations/packageUnit'

import {
  createProduct,
  requestProduct,
  updateProduct
} from '@/actions/operations/product'

import {
  getOperationsAllProduces,
  getOperationsAllVarieties,
  getOperationsAllGrades,
  getOperationsAllPackageUnits,
  getOperationsProduct,
  getOperationsLoading,
  getOperationsError
} from '@/reducers/selectors'

import { Box, Flex, Label, Select, Text, Input, Loader } from '@/primitives'

import InputError from '@/elements/InputError'
import HeaderV2 from '@/components/Operations/Shared/HeaderV2'
import OrganizationInput from '../../../Shared/Organization/Input'

import { getValidationErrorMap } from '@/Util/GeneralUtils'
import history from '../../../../../history'
import Strings from '../Strings'

import { initialState, reducer, UPDATE_INPUT, SET_STATE } from './state'

import { PRODUCT_STATUSES } from '../utils'
import { FIELDS, SCHEMA } from './config'

function ProductForm({ state, modulePath }) {
  const dispatch = useDispatch()
  const isMobile = useDeviceSize('mobile')
  const isLaptop = useDeviceSize()
  const { itemId } = useParams()

  const strings = Strings()

  const produces = getOperationsAllProduces()
  const varieties = getOperationsAllVarieties()
  const grades = getOperationsAllGrades()
  const packageUnits = getOperationsAllPackageUnits()
  const product = getOperationsProduct()
  const loading = getOperationsLoading()
  const error = getOperationsError()

  const productStatuses = PRODUCT_STATUSES(strings)

  const [formState, dispatchFormState] = useReducer(reducer, initialState)

  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)

  useEffect(() => {
    dispatch(requestAllProduces())
    dispatch(requestAllVarieties())
    dispatch(requestAllGrades())
    dispatch(requestAllPackageUnits())
  }, [dispatch])

  useEffect(() => {
    if (itemId && product.id !== itemId) {
      dispatch(requestProduct({ productId: itemId }))
    }
  }, [itemId])

  useEffect(() => {
    if (product?.id && product.id === itemId) {
      dispatchFormState({
        type: SET_STATE,
        state: { ...product }
      })
    }
  }, [product])

  useEffect(() => {
    if (!itemId && state.organizations.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'organizationId',
        value: state.organizations[0]
      })
    }
  }, [state.organizations])

  useEffect(() => {
    if (!itemId && state.produces.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'produceId',
        value: state.produces[0]
      })
    }
  }, [state.produces])

  useEffect(() => {
    if (!itemId && state.varieties.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'varietyId',
        value: state.varieties[0]
      })
    }
  }, [state.varieties])

  useEffect(() => {
    if (waiting && !loading && !error) {
      history.replace(modulePath)
    }
  }, [loading])

  const onSubmit = async e => {
    try {
      const payload = await SCHEMA().validate(formState, { abortEarly: false })
      setWaiting(true)

      if (itemId) {
        payload.productId = itemId
        dispatch(updateProduct(payload))
      } else {
        dispatch(createProduct(payload))
      }
    } catch (error) {
      setErrors(getValidationErrorMap(error))
    }
  }

  const handleInput = e => {
    const { name, value } = e.currentTarget
    dispatchFormState({ type: UPDATE_INPUT, name, value })
  }

  const handleNumericInput = e => {
    const { name, value } = e.currentTarget
    const newValue = value.length > 0 ? Number(value) : ''
    dispatchFormState({ type: UPDATE_INPUT, name, value: newValue })
  }

  const handleProduceInput = e => {
    dispatchFormState({
      type: SET_STATE,
      state: {
        ...formState,
        produceId: e.currentTarget.value,
        varietyId: ''
      }
    })
  }

  const handleOrganizationInput = organizationId => {
    dispatchFormState({
      type: SET_STATE,
      state: {
        ...formState,
        organizationId,
        produceId: '',
        varietyId: '',
        gradeId: '',
        packageUnits: []
      }
    })
  }

  const handlePackageUnitsInput = options => {
    const newValues = options.map(o => o.value)
    dispatchFormState({
      type: UPDATE_INPUT,
      name: 'packageUnits',
      value: newValues
    })
  }

  const filterProduces = () => {
    return produces.filter(p => p.organizationId === formState.organizationId)
  }

  const filterVarieties = () => {
    return varieties.filter(v => v.produce.id === formState.produceId)
  }

  const filterGrades = () => {
    return grades.filter(g => g.organizationId === formState.organizationId)
  }

  const getPackageUnitsValue = () => {
    return packageUnits
      .filter(p => formState.packageUnits.indexOf(p.id) >= 0)
      .map(p => ({
        value: p.id,
        label: p.name
      }))
  }

  const filterPackageUnits = () => {
    return packageUnits.map(p => ({
      value: p.id,
      label: p.name
    }))
  }

  const getHeader = () => {
    if (itemId) {
      return strings.updateFormHeader
    }
    return strings.createFormHeader
  }

  return (
    <Flex
      as='form'
      axisGap={400}
      direction='column'
      className='Operations__Form'
    >
      <HeaderV2
        title={getHeader()}
        backPath={modulePath}
        buttonIcon={'save'}
        buttonText={strings.saveProduct}
        buttonCallback={onSubmit}
      />
      <Loader isLoading={loading}>
        <Flex axisGap={800} direction={isLaptop ? 'column' : null}>
          <Flex
            axisGap={400}
            direction='column'
            className='Operations__Form__Fields'
          >
            <Box className='Product'>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.product}
              </Text>
              <OrganizationInput
                fieldName={FIELDS.organizationId}
                organizationId={formState.organizationId}
                handleInput={handleOrganizationInput}
                errors={errors}
              />
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: 2 }}>
                  <Text variant='page' tone={700}>
                    {strings.name}
                  </Text>
                  <Input
                    value={formState.name}
                    name={FIELDS.name}
                    onChange={handleInput}
                  />
                  <InputError error={errors?.name} />
                </Label>
                <Label style={{ flex: 1 }}>
                  <Text variant='page' tone={700}>
                    {strings.code}
                  </Text>
                  <Input
                    value={formState.code}
                    name={FIELDS.code}
                    onChange={handleInput}
                  />
                  <InputError error={errors?.code} />
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                alignMainAxis='flex-start'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flexGrow: 0 }}>
                  <Text variant='page' tone={700}>
                    {strings.status}
                  </Text>
                  <Select
                    className='Operations__Select'
                    name={FIELDS.status}
                    value={formState.status}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {Object.entries(productStatuses).map(([id, name]) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.status} />
                </Label>
              </Flex>
            </Box>
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.produce}
              </Text>
              <Flex
                axisGap={300}
                className='Operations__Form__Fields'
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.produce}
                  </Text>
                  <Select
                    className='Operations__Form__Select'
                    name={FIELDS.produceId}
                    value={formState.produceId}
                    onChange={handleProduceInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterProduces().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.produceId} />
                </Label>
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.variety}
                  </Text>
                  <Select
                    className='Operations__Form__Select'
                    name={FIELDS.varietyId}
                    value={formState.varietyId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterVarieties().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.varietyId} />
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                className='Operations__Form__Fields'
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.grade}
                  </Text>
                  <Select
                    className='Operations__Form__Select'
                    name={FIELDS.gradeId}
                    value={formState.gradeId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterGrades().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.gradeId} />
                </Label>
                <Label style={{ flexGrow: 0 }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.weight}
                    </Text>
                    <Box className='Operations__Input__Container'>
                      <Input
                        className='Operations__Input'
                        type='number'
                        name={FIELDS.weight}
                        value={formState.weight}
                        onChange={handleNumericInput}
                      />
                      <span className='Operations__Input__Suffix'>
                        {strings.grams}
                      </span>
                    </Box>
                    <InputError error={errors?.weight} />
                  </Flex>
                </Label>
              </Flex>
            </Box>
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.packageUnits}
              </Text>
              <Flex
                axisGap={300}
                className='Operations__Form__Fields'
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.packageUnits}
                    </Text>
                    <ReactSelect
                      className='Operations__Form__Select'
                      classNamePrefix={'ReactSelect ReactSelect__Multi'}
                      name={FIELDS.packageUnits}
                      value={getPackageUnitsValue()}
                      onChange={handlePackageUnitsInput}
                      isMulti={true}
                      menuPlacement={'top'}
                      options={filterPackageUnits()}
                    />
                    <InputError error={errors?.packageUnits} />
                  </Flex>
                </Label>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Loader>
    </Flex>
  )
}

export default ProductForm

import { I18n } from 'aws-amplify'

const Strings = () => ({
  areaCreated: I18n.get('Area created successfully.'),
  areaUpdated: I18n.get('Area updated successfully.'),
  planCreated: I18n.get('Plan created successfully.'),
  planUpdated: I18n.get('Plan updated successfully.'),
  harvestCreated: I18n.get('Harvest created successfully.'),
  harvestUpdated: I18n.get('Harvest updated successfully.'),
  harvestDeleted: I18n.get('Harvest deleted successfully.'),
  saleCreated: I18n.get('Sale created successfully.'),
  saleUpdated: I18n.get('Sale updated successfully.'),
  saleItemCreated: I18n.get('Sale item created successfully.'),
  saleItemUpdated: I18n.get('Sale item updated successfully.'),
  saleItemDeleteForeignKeyConstraintError: I18n.get('Unable to remove item referenced by existing return.'),
  saleShipmentCreated: I18n.get('Sale Shipment created successfully.'),
  saleShipmentUpdated: I18n.get('Sale Shipment successfully.'),
  saleShipmentDeleteForeignKeyConstraintError: I18n.get('Unable to remove shipment referenced by existing return.'),
  wasteCreated: I18n.get('Waste created successfully.'),
  wasteUpdated: I18n.get('Waste updated successfully.'),
  returnCreated: I18n.get('Return created successfully.'),
  returnUpdated: I18n.get('Return updated successfully.'),
  customerCreated: I18n.get('Customer created successfully.'),
  customerUpdated: I18n.get('Customer updated successfully.'),
  branchCreated: I18n.get('Branch created successfully.'),
  branchUpdated: I18n.get('Branch updated successfully.'),
  unitPriceCreated: I18n.get('Unit price created successfully.'),
  unitPriceUpdated: I18n.get('Unit price updated successfully.'),
  unitPriceDeleted: I18n.get('Unit price deleted successfully.'),
  unitPriceCreateUniqueConstraintError: I18n.get('Unable to create duplicate unit price.'),
  unitPriceUpdateUniqueConstraintError: I18n.get('Unable to save duplicate unit price.'),
  cityCreated: I18n.get('City created successfully.'),
  cityUpdated: I18n.get('City updated successfully.'),
  countryCreated: I18n.get('Country created successfully.'),
  countryUpdated: I18n.get('Country updated successfully.'),
  currencyCreated: I18n.get('Currency created successfully.'),
  currencyUpdated: I18n.get('Currency updated successfully.'),
  gradeCreated: I18n.get('Grade created successfully.'),
  gradeUpdated: I18n.get('Grade updated successfully.'),
  packageUnitCreated: I18n.get('Package unit created successfully.'),
  packageUnitUpdated: I18n.get('Package unit updated successfully.'),
  paymentMethodCreated: I18n.get('Payment method created successfully.'),
  paymentMethodUpdated: I18n.get('Payment method updated successfully.'),
  paymentTermCreated: I18n.get('Payment terms created successfully.'),
  paymentTermUpdated: I18n.get('Payment terms updated successfully.'),
  produceCreated: I18n.get('Produce created successfully.'),
  produceUpdated: I18n.get('Produce updated successfully.'),
  regionCreated: I18n.get('Region created successfully.'),
  regionUpdated: I18n.get('Region updated successfully.'),
  shippingMethodCreated: I18n.get('Shipping method created successfully.'),
  shippingMethodUpdated: I18n.get('Shipping method updated successfully.'),
  storageFacilityCreated: I18n.get('Storage facility created successfully.'),
  storageFacilityUpdated: I18n.get('Storage facility updated successfully.'),
  varietyCreated: I18n.get('Variety created successfully.'),
  varietyUpdated: I18n.get('Variety updated successfully.'),
  inventoryCalibrationCreated: I18n.get('Inventory calibration created successfully.'),
  inventoryTransferCreated: I18n.get('Inventory transfer created successfully.'),
  inventoryTransferUpdated: I18n.get('Inventory transfer updated successfully.'),
  packageUnitPurchaseCreated: I18n.get('Package unit purchase created successfully.'),
  packageUnitPurchaseUpdated: I18n.get('Package unit purchase updated successfully.'),
  packageUnitInventoryCalibrationCreated: I18n.get('Packaging inventory calibration created successfully.'),
  packageUnitInventoryTransferCreated: I18n.get('Packaging inventory transfer created successfully.'),
  packageUnitInventoryTransferUpdated: I18n.get('Packaging inventory transfer updated successfully.'),
  productCreated: I18n.get('Product created successfully.'),
  productUpdated: I18n.get('Product updated successfully.'),
  cropCreated: I18n.get('Crop created successfully.'),
  cropUpdated: I18n.get('Crop updated successfully.'),
  supplierCreated: I18n.get('Supplier created successfully.'),
  supplierUpdated: I18n.get('Supplier updated successfully.'),
  fertilizerPurchaseCreated: I18n.get('Fertilizer purchase created successfully.'),
  fertilizerPurchaseUpdated: I18n.get('Fertilizer purchase updated successfully.'),
  fertilizerInventoryCalibrationCreated: I18n.get('Fertilizer inventory calibration created successfully.'),
  fertilizerInventoryTransferCreated: I18n.get('Fertilizer inventory transfer created successfully.'),
  fertilizerInventoryTransferUpdated: I18n.get('Fertilizer inventory transfer updated successfully.'),

  // Databoard
  harvestNotFetched: I18n.get('Harvest data not fetched'),
  cropsNotFetched: I18n.get('Crops data not fetched')
})

export default Strings
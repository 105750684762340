import { updateList } from '../util'

import {
  REQUEST_ALL_STORAGE_FACILITIES,
  RECEIVE_ALL_STORAGE_FACILITIES,
  REQUEST_STORAGE_FACILITIES,
  RECEIVE_STORAGE_FACILITIES,
  CLEAR_STORAGE_FACILITY,
  CLEAR_STORAGE_FACILITIES,
  REQUEST_STORAGE_FACILITY,
  RECEIVE_STORAGE_FACILITY,
  CREATE_STORAGE_FACILITY,
  STORAGE_FACILITY_CREATED,
  UPDATE_STORAGE_FACILITY,
  STORAGE_FACILITY_UPDATED,
  SET_STORAGE_FACILITY
} from '@/actions/operations/storageFacility'

export const storageFacilityInitialState = {
  allStorageFacilities: [],
  storageFacilities: [],
  storageFacility: {},
  storageFacilitiesCount: 0,
  loading: false,
  error: null
}

const handlers = {
  [SET_STORAGE_FACILITY]: (state, action) => ({
    ...state,
    storageFacility: action.storageFacility
  }),
  [CLEAR_STORAGE_FACILITY]: (state, action) => ({
    ...state,
    storageFacility: {},
    error: null
  }),
  [CLEAR_STORAGE_FACILITIES]: (state, action) => ({
    ...state,
    storageFacilities: [],
    error: null
  }),
  [REQUEST_ALL_STORAGE_FACILITIES]: state => ({
    ...state,
    error: null,
    loading: true
  }),
  [RECEIVE_ALL_STORAGE_FACILITIES]: (state, action) => ({
    ...state,
    allStorageFacilities: !action.error ? action.storageFacilities : [],
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_STORAGE_FACILITIES]: (state, action) => ({
    ...state,
    storageFacilities: [],
    error: null,
    loading: true
  }),
  [RECEIVE_STORAGE_FACILITIES]: (state, action) => ({
    ...state,
    storageFacilities: !action.error ? action.storageFacilities : [],
    storageFacilitiesCount: !action.error ? action.count : 0,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_STORAGE_FACILITY]: (state, action) => ({
    ...state,
    storageFacility: {},
    error: null,
    loading: true
  }),
  [RECEIVE_STORAGE_FACILITY]: (state, action) => ({
    ...state,
    storageFacility: !action.error
      ? action.storageFacility
      : state.storageFacility,
    error: action.error ?? null,
    loading: false
  }),
  [CREATE_STORAGE_FACILITY]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [STORAGE_FACILITY_CREATED]: (state, action) => ({
    ...state,
    storageFacility: !action.error ? {} : state.storageFacility,
    storageFacilities: updateList(
      action.error,
      state.storageFacilities,
      action.storageFacility,
      true
    ),
    allStorageFacilities: updateList(
      action.error,
      state.allStorageFacilities,
      action.storageFacility,
      true
    ),
    storageFacilitiesCount: !action.error
      ? state.storageFacilitiesCount + 1
      : state.storageFacilitiesCount,
    error: action.error ?? null,
    loading: false
  }),
  [UPDATE_STORAGE_FACILITY]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [STORAGE_FACILITY_UPDATED]: (state, action) => ({
    ...state,
    storageFacility: !action.error ? {} : state.storageFacility,
    storageFacilities: updateList(
      action.error,
      state.storageFacilities,
      action.storageFacility,
      false
    ),
    allStorageFacilities: updateList(
      action.error,
      state.allStorageFacilities,
      action.storageFacility,
      false
    ),
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

import { createElement, useEffect, useState } from 'react'

import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'

import Auth from './Auth'

const Wrapper = () => {
  const [authState, setAuthState] = useState('signin')

  useEffect(() => {
    return onAuthUIStateChange(nextAuthState => {
      setAuthState(nextAuthState)
    })
  }, [])

  if (authState === AuthState.SignedIn) {
    return null
  }

  return createElement(Auth)
}

export default Wrapper

import { DateTime } from 'luxon'

import { Button, Cell, Row } from '../../../../../primitives'

import { INVENTORY_TRANSFER_STATUSES } from '../utils'

import Strings from '../Strings'

function TableRow({ item, onEdit, canEdit }) {
  const strings = Strings()

  const inventoryTransferStatuses = INVENTORY_TRANSFER_STATUSES(strings)

  const {
    id,
    packageUnitInventory,
    shippingMethod,
    storageFacility,
    status,
    quantity,
    shippedDate,
    deliveredDate
  } = item

  const handleEdit = () => {
    onEdit(id)
  }

  const formatDate = date => {
    if (date) {
      return DateTime.fromMillis(date).toFormat('DDD')
    }
    return null
  }

  return (
    <Row>
      <Cell columnName={strings.from}>
        {packageUnitInventory.storageFacility.name} ({shippingMethod.name})
        <span>{formatDate(shippedDate)}</span>
      </Cell>
      <Cell columnName={strings.to}>
        {storageFacility.name}
        <span>{formatDate(deliveredDate)}</span>
      </Cell>
      <Cell columnName={strings.packageUnit}>
        {packageUnitInventory.packageUnit.name}
      </Cell>
      <Cell columnName={strings.quantity}>{quantity}</Cell>
      <Cell columnName={strings.status}>
        {inventoryTransferStatuses[status]}
      </Cell>
      <Cell className='Operations__Table__Column__Actions'>
        {canEdit && (
          <Button
            variant='info'
            size='small'
            iconBefore='edit'
            onClick={handleEdit}
          />
        )}
      </Cell>
    </Row>
  )
}

export default TableRow

import { updateList } from '../util'

import {
  REQUEST_PACKAGE_UNIT_PURCHASES,
  RECEIVE_PACKAGE_UNIT_PURCHASES,
  REQUEST_PACKAGE_UNIT_PURCHASE,
  RECEIVE_PACKAGE_UNIT_PURCHASE,
  CREATE_PACKAGE_UNIT_PURCHASE,
  PACKAGE_UNIT_PURCHASE_CREATED,
  UPDATE_PACKAGE_UNIT_PURCHASE,
  PACKAGE_UNIT_PURCHASE_UPDATED
} from '@/actions/operations/packageUnitPurchase'

export const packageUnitPurchaseInitialState = {
  packageUnitPurchases: [],
  packageUnitPurchasesCount: 0,
  packageUnitPurchase: {},
  loading: false,
  error: null
}

const handlers = {
  [REQUEST_PACKAGE_UNIT_PURCHASES]: (state, action) => ({
    ...state,
    packageUnitPurchases: [],
    packageUnitPurchasesCount: 0,
    error: null,
    loading: true
  }),
  [RECEIVE_PACKAGE_UNIT_PURCHASES]: (state, action) => ({
    ...state,
    packageUnitPurchases: !action.error ? action.packageUnitPurchases : [],
    packageUnitPurchasesCount: action?.count ?? 0,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_PACKAGE_UNIT_PURCHASE]: state => ({
    ...state,
    packageUnitPurchase: {},
    error: null,
    loading: true
  }),
  [RECEIVE_PACKAGE_UNIT_PURCHASE]: (state, action) => ({
    ...state,
    packageUnitPurchase: !action.error
      ? action.packageUnitPurchase
      : state.packageUnitPurchase,
    error: action.error ?? null,
    loading: false
  }),
  [CREATE_PACKAGE_UNIT_PURCHASE]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [PACKAGE_UNIT_PURCHASE_CREATED]: (state, action) => ({
    ...state,
    packageUnitPurchase: !action.error ? {} : state.packageUnitPurchase,
    packageUnitPurchases: updateList(
      action.error,
      state.packageUnitPurchases,
      action.packageUnitPurchase,
      true
    ),
    packageUnitPurchasesCount: !action.error
      ? state.packageUnitPurchasesCount + 1
      : state.packageUnitPurchasesCount,
    error: action.error ?? null,
    loading: false
  }),
  [UPDATE_PACKAGE_UNIT_PURCHASE]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [PACKAGE_UNIT_PURCHASE_UPDATED]: (state, action) => ({
    ...state,
    packageUnitPurchase: !action.error ? {} : state.packageUnitPurchase,
    packageUnitPurchases: updateList(
      action.error,
      state.packageUnitPurchases,
      action.packageUnitPurchase,
      false
    ),
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

import { I18n } from 'aws-amplify'
import { Link } from 'react-router-dom'

import './index.scss'

const PACKAGE_VERSION = window.ENV.PACKAGE_VERSION

/**
 * React component for the website footer
 * @function
 * @name Footer
 * @returns {JSX.Element} JSX footer element
 */

const Footer = () => {
  return (
    <footer className='Footer'>
      <ul>
        <li>
          <span style={{ margin: '0 0.5em' }}>{PACKAGE_VERSION}</span>
        </li>
        <li>
          <Link to='/privacy-policy' target='_blank'>
            {I18n.get('Privacy Policy')}
          </Link>
        </li>
      </ul>
      <ul>
        <li>
          <a
            href='https://iyris.com'
            target='_blank'
            hrefLang='en-GB'
            rel='noreferrer'
            aria-label='iyris.com'
          >
            <svg viewBox='0 0 160 80' height={20} width={50}>
              <path
                d='M1,17h8.1v41.8H1V17z M1,5c0-1.1,0.4-2,1.2-2.8C3,1.4,3.9,1,5,1s2.1,0.4,2.8,1.2C8.7,3,9.1,3.9,9.1,5S8.7,7,7.9,7.8
		S6.1,9,5,9S3,8.6,2.2,7.8C1.4,7,1,6.1,1,5zM109,17h8.1v41.8H109V17z M109,5c0-1.1,0.4-2,1.2-2.8C111,1.4,111.9,1,113,1s2.1,0.4,2.8,1.2c0.8,0.8,1.2,1.7,1.2,2.8
		s-0.4,2-1.2,2.8C115.1,8.6,114.1,9,113,9s-2.1-0.4-2.8-1.2C109.4,7,109,6.1,109,5zM61.3,72.6c-1.2,0-2.3-0.2-3.2-0.5c-0.9-0.3-1.6-0.8-2.2-1.4c-0.6-0.6-1.1-1.3-1.6-2.2c-0.4-0.8-0.8-1.8-1.2-2.8l-2.6-6.6
		L68,17h-9.3L46.1,49.6h-0.2l-7.7-20.8l0,0c0-0.1-0.1-0.2-0.1-0.3L36.8,25c-1.7-4.5-5.9-7.6-10.7-7.9c-0.6-0.1-1.3-0.1-2-0.1
		c-1.2,0-2.3,0.1-3.5,0.2c-1.2,0.1-2.3,0.4-3.4,0.9l1,7.1c1.6-0.6,3.1-0.9,4.7-0.9c1.2,0,2.2,0.2,3.1,0.5c0.8,0.3,1.6,0.8,2.2,1.4
		c0.6,0.6,1.1,1.3,1.5,2.1c0.2,0.3,0.3,0.7,0.5,1.1l15.4,38.6c0.7,1.8,1.5,3.5,2.2,5c0.8,1.5,1.7,2.8,2.8,3.8
		c1.1,1.1,2.4,1.9,3.9,2.5c1.5,0.6,3.4,0.9,5.6,0.9c1.2,0,2.4-0.1,3.6-0.2c1.2-0.1,2.4-0.5,3.5-0.9l-1-7.2
		C64.5,72.3,62.9,72.6,61.3,72.6zM75.1,17h8.1v6.4h0.2c0.5-1.1,1.3-2.1,2.2-3c0.9-0.9,1.9-1.7,3-2.3c1.1-0.6,2.3-1.2,3.6-1.5c1.3-0.4,2.6-0.6,4-0.6
		c1.3,0,2.5,0.2,3.6,0.5L99.4,25c-0.7-0.2-1.3-0.3-2-0.4c-0.7-0.1-1.3-0.2-2-0.2c-4,0-7,1.1-9.1,3.3c-2.1,2.2-3.1,5.6-3.1,10.1v21
		h-8.1V17zM152.5,27.5c-1-1.2-2.1-2.2-3.5-3c-1.4-0.8-3.1-1.2-5.2-1.2c-1.9,0-3.6,0.4-5,1.2c-1.4,0.8-2.1,2-2.1,3.5
		c0,1.2,0.4,2.2,1.2,3c0.8,0.8,1.8,1.4,2.9,1.9c1.1,0.5,2.3,0.8,3.5,1.1c1.3,0.2,2.3,0.4,3.2,0.6c1.7,0.4,3.4,0.9,4.9,1.5
		c1.5,0.6,2.8,1.4,4,2.3c1.1,0.9,2,2.1,2.6,3.4c0.6,1.4,0.9,3,0.9,4.9c0,2.4-0.5,4.4-1.5,6c-1,1.7-2.3,3-4,4.1s-3.5,1.8-5.5,2.3
		c-2.1,0.5-4.1,0.7-6.2,0.7c-3.5,0-6.6-0.5-9.3-1.6c-2.7-1.1-5-3-7.1-5.8l6.1-4.9c1.3,1.3,2.8,2.4,4.4,3.4c1.6,1,3.6,1.5,5.9,1.5
		c1,0,2.1-0.1,3.1-0.3c1-0.2,2-0.5,2.8-1c0.8-0.4,1.5-1,2-1.7c0.5-0.7,0.8-1.5,0.8-2.3c0-1.2-0.4-2.1-1.1-2.9
		c-0.7-0.8-1.6-1.4-2.7-1.8c-1-0.4-2.2-0.8-3.3-1.1c-1.2-0.3-2.2-0.5-3.1-0.7c-1.7-0.4-3.4-0.9-4.9-1.4c-1.6-0.5-2.9-1.2-4.1-2.1
		c-1.2-0.9-2.2-2-2.9-3.4c-0.7-1.4-1.1-3-1.1-5c0-2.2,0.5-4.1,1.4-5.7c0.9-1.6,2.2-3,3.7-4c1.5-1.1,3.3-1.9,5.2-2.4
		c1.9-0.5,3.9-0.8,5.9-0.8c2.9,0,5.6,0.5,8.3,1.6c2.6,1.1,4.7,2.8,6.3,5.3L152.5,27.5z'
              />
            </svg>
          </a>
        </li>
        <li>&copy; 2020</li>
      </ul>
    </footer>
  )
}

export default Footer

import withEditableWidget from '@/components/DashboardPageV2/Desktop/Widgets/Shared/HOC/withEditableWidget'
import {
  LINE_CHART,
  PIE_CHART
} from '@/components/DashboardPageV2/Desktop/Widgets/Shared//Dynamic/config'
import { OperationEdit } from '../OperationEdit'
import DatasetPie from '../Dataset/DatasetPie'
import DatasetLine from '../Dataset/DatasetLine'
import { harvestChartQueryOptionsList } from '../utils'

function TotalProduceEdit(props) {
  const options = {
    queryOptionsList: harvestChartQueryOptionsList
  }

  return <OperationEdit {...props} options={options} />
}

function TotalProduceView(props) {
  const { config, options } = props

  switch (config.chart) {
    case LINE_CHART:
      return <DatasetLine config={config} options={options} {...props} />
    case PIE_CHART:
    default:
      return <DatasetPie config={config} options={options} />
  }
}

export const TotalProduceWidget = withEditableWidget(
  TotalProduceView,
  TotalProduceEdit
)

import { createContext, useContext } from 'react'

export const AuditStateContext = createContext({})

export function useAuditTrail() {
  const context = useContext(AuditStateContext)

  if (!context) {
    throw new Error('useAuditTrail must be used within AuditStateContext')
  }

  return context
}

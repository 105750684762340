import { call, put, takeLatest } from 'redux-saga/effects'

import {
  createPackageUnitInventoryTransfer,
  getPackageUnitInventoryTransfer,
  getPackageUnitInventoryTransfers,
  updatePackageUnitInventoryTransfer
} from '@/api/operations/packageUnitInventoryTransfer'

import {
  REQUEST_PACKAGE_UNIT_INVENTORY_TRANSFERS,
  REQUEST_PACKAGE_UNIT_INVENTORY_TRANSFER,
  CREATE_PACKAGE_UNIT_INVENTORY_TRANSFER,
  UPDATE_PACKAGE_UNIT_INVENTORY_TRANSFER,
  receivePackageUnitInventoryTransfers,
  receivePackageUnitInventoryTransfer,
  packageUnitInventoryTransferCreated,
  packageUnitInventoryTransferUpdated
} from '@/actions/operations/packageUnitInventoryTransfer'

import { displayBanner } from '../utils'

import Strings from './Strings'

const strings = Strings()

function* sendCreatePackageUnitInventoryTransfer(action) {
  const { data, error = null } = yield call(
    createPackageUnitInventoryTransfer,
    action.params
  )
  yield put(displayBanner(error, strings.packageUnitInventoryTransferCreated))
  yield put(packageUnitInventoryTransferCreated(data, error))
}

export function* watchCreatePackageUnitInventoryTransfer() {
  yield takeLatest(
    CREATE_PACKAGE_UNIT_INVENTORY_TRANSFER,
    sendCreatePackageUnitInventoryTransfer
  )
}

function* sendUpdatePackageUnitInventoryTransfer(action) {
  const { data, error = null } = yield call(
    updatePackageUnitInventoryTransfer,
    action.params
  )
  yield put(displayBanner(error, strings.packageUnitInventoryTransferUpdated))
  yield put(packageUnitInventoryTransferUpdated(data, error))
}

export function* watchUpdatePackageUnitInventoryTransfer() {
  yield takeLatest(
    UPDATE_PACKAGE_UNIT_INVENTORY_TRANSFER,
    sendUpdatePackageUnitInventoryTransfer
  )
}

function* sendRequestPackageUnitInventoryTransfers(action) {
  const { data, error = null } = yield call(
    getPackageUnitInventoryTransfers,
    action.params
  )
  const { packageUnitInventoryTransfers = [], count = 0 } = data
  yield put(
    receivePackageUnitInventoryTransfers(
      packageUnitInventoryTransfers,
      count,
      error
    )
  )
}

export function* watchRequestPackageUnitInventoryTransfers() {
  yield takeLatest(
    REQUEST_PACKAGE_UNIT_INVENTORY_TRANSFERS,
    sendRequestPackageUnitInventoryTransfers
  )
}

function* sendRequestPackageUnitInventoryTransfer(action) {
  const { data: packageUnitInventoryTransfer, error = null } = yield call(
    getPackageUnitInventoryTransfer,
    action.params
  )
  yield put(
    receivePackageUnitInventoryTransfer(packageUnitInventoryTransfer, error)
  )
}

export function* watchRequestPackageUnitInventoryTransfer() {
  yield takeLatest(
    REQUEST_PACKAGE_UNIT_INVENTORY_TRANSFER,
    sendRequestPackageUnitInventoryTransfer
  )
}

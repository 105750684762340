import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { showBanner } from '../util'
import { I18n } from 'aws-amplify'
import {
  getSensorDiagnosticInfo,
  restartSensors
} from '@/api/diagnostics/sensor'

export const fetchSensorsDiagnosticInfo = createAsyncThunk(
  'fetchSensorsDiagnosticInfo',
  async (params, { dispatch }) => {
    try {
      const result = await getSensorDiagnosticInfo(params)
      dispatch(
        showBanner({
          show: true,
          message: I18n.get(
            'Diagnostics request sent succesfully. You will receive an email with the results.'
          ),
          type: 'success'
        })
      )
      return result
    } catch (error) {
      throw error
    }
  }
)

export const sendRestartSensors = createAsyncThunk(
  'sendRestartSensors',
  async (params, { dispatch }) => {
    try {
      const result = await restartSensors(params)
      dispatch(
        showBanner({
          show: true,
          message: I18n.get(
            'Sensor restart has been scheduled for the next wake up cycle.'
          ),
          type: 'success'
        })
      )
      return result
    } catch (error) {
      throw error
    }
  }
)

const diagnosticsSensor = createSlice({
  name: 'diagnosticsSensorReducer',
  initialState: {
    requestedDiagnostics: [],
    requestedPings: [],
    error: null,
    loading: false
  },
  reducers: {
    cleanRequestedSensorDiagnostics: (state, action) => {
      state.requestedDiagnostics = []
    },
    cleanRequestedSensorPings: (state, action) => {
      state.requestedPings = []
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSensorsDiagnosticInfo.pending, state => {
        state.error = null
        state.loading = true
      })
      .addCase(fetchSensorsDiagnosticInfo.fulfilled, (state, action) => {
        state.requestedDiagnostics = action.payload
        state.devices = action.payload
        state.loading = false
      })
      .addCase(fetchSensorsDiagnosticInfo.rejected, (state, action) => {
        state.error = action.error
        state.loading = false
      })
  }
})

const { actions, reducer } = diagnosticsSensor

export const { cleanRequestedSensorDiagnostics, cleanRequestedSensorPings } =
  actions

export default reducer

import withEditableWidget from '@/components/DashboardPageV2/Desktop/Widgets/Shared/HOC/withEditableWidget'

import { getMeasurements } from '@/reducers/selectors'
import { ChartOption, QueryOption } from '../../Shared/Dynamic/config/utils'
import {
  DATASET_QUERY,
  SCATTERPLOT_CHART
} from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'
import { MeasurementEdit } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Measurement'
import { DAILY } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'
import { LAST_7_DAYS } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'
import { LAST_30_DAYS } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'

import DatasetScatterplot from './DatasetScatterplot'

const scatterPlotChart = new ChartOption({
  value: SCATTERPLOT_CHART,
  interval: [DAILY],
  range: [LAST_7_DAYS, LAST_30_DAYS]
})

const datasetScatterplotQuery = new QueryOption({
  value: DATASET_QUERY,
  options: [scatterPlotChart]
})

export const RadiationTemperatureRatioQueryOptionsList = [
  datasetScatterplotQuery
]

function RadiationTemperatureRatioView(props) {
  const { config, options } = props

  const measurement = options.measurement
  const measurements = getMeasurements()

  const unit = measurements?.find(m => m.id === measurement)?.unit

  if (config.query === DATASET_QUERY) {
    return <DatasetScatterplot unit={unit} config={config} options={options} />
  }
}

export const RadiationTemperatureRatioWidget = withEditableWidget(
  RadiationTemperatureRatioView,
  MeasurementEdit
)

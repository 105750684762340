import { useEffect, useState } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { useDispatch } from 'react-redux'

import {
  Slot,
  Button,
  Collapse,
  FlexV2,
  Input,
  Label,
  Text,
  Separator
} from '@/primitives'

import Select from '@/elements/Select'

import {
  getCurrentUserOrganizations,
  getAllUsers,
  getAuditLogs,
  getCurrentUser
} from '@/reducers/selectors'

import { fetchAuditLogs } from '@/slices/audit/log'
import useOrgLabel from '@/hooks/useOrgLabel'
import { getIsGodMode } from '@/Util/PermissionUtils'

import { useAuditTrail } from './context'

import {
  RESET_FILTERS,
  SET_CURRENT_AUDIT_TRAIL_LOGS,
  UPDATE_FILTERS,
  initialState
} from './state'

import {
  ACTION_TYPES_ARRAY,
  ACTOR_TYPES_ARRAY,
  getOrganizationsOptions
} from './utils'

import Strings, { ServiceStrings } from './Strings'

const getServiceOptions = () => {
  const servicesStrings = ServiceStrings()

  return [
    { label: servicesStrings.operations, value: 'operations' },
    { label: servicesStrings.management, value: 'management' },
    { label: servicesStrings.thresholds, value: 'thresholds' },
    { label: servicesStrings['ts-data'], value: 'ts-data' },
    { label: servicesStrings.device, value: 'device' },
    { label: servicesStrings.calibration, value: 'calibration' },
    { label: servicesStrings.video, value: 'video' },
    { label: servicesStrings.weather, value: 'weather' },
    { label: servicesStrings.manager, value: 'manager' },
    { label: servicesStrings['device-management'], value: 'device-management' }
  ]
}

const getActorOptions = users => {
  return users?.map(user => {
    return {
      label: `${user.firstName} ${user.lastName}`,
      value: user.userName
    }
  })
}

const AuditFilters = ({ zoneOptions, isAboveBreakpoint }) => {
  const dispatch = useDispatch()
  const strings = Strings()
  const organizations = getCurrentUserOrganizations()
  const currentUser = getCurrentUser()
  const isGodMode = getIsGodMode(currentUser)
  const serviceOptions = getServiceOptions()
  const users = getAllUsers()
  const auditLogs = getAuditLogs()

  const [mainFilter, setMainFilter] = useState(null)
  const { site } = useOrgLabel(['site'])
  const { state, dispatchState } = useAuditTrail()
  const [organizationsOptions, setOrganizationsOptions] = useState([])

  useEffect(() => {
    const orgOptions = getOrganizationsOptions(organizations, currentUser)
    setOrganizationsOptions(orgOptions)
    let orgId = null
    if (orgOptions?.length > 0 && currentUser?.role) {
      if (isGodMode) {
        setFilterState('organizationId', null)
      } else {
        orgId = orgOptions[0]?.value
        setFilterState('organizationId', orgId)
      }
    }
  }, [currentUser, isGodMode])

  const onSelect = (option, actionMeta) => {
    setFilterState(actionMeta.name, option?.value ?? null)
  }

  const setFilterState = (filterKey, value) => {
    const filters = { ...state.filters }

    filters[filterKey] = value

    const otherFilters = Object.keys(filters).filter(
      filter =>
        filter !== filterKey && filter !== 'actorType' && !!filters[filter]
    )

    if (otherFilters.length === 1) {
      setMainFilter(otherFilters[0])
    }

    // if filter is deselected, use the original auditLogs
    let filteredAuditTrailLogs = cloneDeep(
      !value ? auditLogs : state.currentAuditTrailLogs
    )

    // reapply otherFilters when a filter is deselected
    if (!value) {
      for (let otherFilter of otherFilters) {
        filteredAuditTrailLogs = filteredAuditTrailLogs.filter(log =>
          log[otherFilter]?.includes(filters[otherFilter])
        )
      }
    }

    if (otherFilters.length > 0) {
      filteredAuditTrailLogs = filteredAuditTrailLogs.filter(
        log => !value || log[filterKey]?.includes(value)
      )

      dispatchState({
        type: SET_CURRENT_AUDIT_TRAIL_LOGS,
        logs: filteredAuditTrailLogs
      })
    } else {
      dispatch(
        fetchAuditLogs({
          filters,
          limit: state.limit
        })
      )
    }

    if (!value && filterKey === mainFilter) {
      dispatch(
        fetchAuditLogs({
          filters: initialState.filters,
          limit: initialState.limit
        })
      )
      dispatchState({
        type: RESET_FILTERS
      })
      setMainFilter(null)
    } else {
      dispatchState({
        type: UPDATE_FILTERS,
        filterKey,
        value,
        skipFetch: otherFilters.length > 0
      })
    }
  }

  const onSearchContext = e => {
    setFilterState('context', e.target.value)
  }

  const onRefetch = e => {
    e.preventDefault()
    dispatch(
      fetchAuditLogs({
        filters: state?.filters,
        limit: state.limit
      })
    )
  }

  const onClearFilters = e => {
    e.preventDefault()
    dispatchState({
      type: RESET_FILTERS
    })
    dispatch(
      fetchAuditLogs({
        filters: initialState.filters,
        limit: initialState.limit
      })
    )
  }

  return (
    <FlexV2 direction={'column'} axisGap={400}>
      <Text variant={'page'} tone={900} size={300} fontWeight={700}>
        {strings.filters}
      </Text>
      <Separator />
      <Collapse defaultOpen={isAboveBreakpoint}>
        <Slot name='trigger'>
          <Text variant={'page'} tone={900} fontWeight={700}>
            {strings.general}
          </Text>
        </Slot>
        <Slot name='content'>
          <FlexV2 direction={'column'}>
            <Label>
              <Text variant={'page'} tone={900} size={100} fontWeight={700}>
                {strings.actor}
              </Text>
              <Select
                isClearable={true}
                name='actorId'
                placeholder={strings?.filterByActor}
                value={state?.filters?.actorId}
                options={getActorOptions(users)}
                isSearchable={true}
                onChange={onSelect}
              />
            </Label>

            <Label>
              <Text variant={'page'} tone={900} size={100} fontWeight={700}>
                {strings.actorType}
              </Text>
              <Select
                name='actorType'
                value={state?.filters?.actorType}
                placeholder={strings?.filterByActorType}
                options={ACTOR_TYPES_ARRAY}
                onChange={onSelect}
              />
            </Label>

            <Label>
              <Text variant={'page'} tone={900} size={100} fontWeight={700}>
                {strings.action}
              </Text>
              <Select
                isClearable={true}
                name='action'
                placeholder={strings?.filterByAction}
                value={state?.filters?.action}
                options={ACTION_TYPES_ARRAY}
                onChange={onSelect}
              />
            </Label>

            <Label>
              <Text variant={'page'} tone={900} size={100} fontWeight={700}>
                {strings.service}
              </Text>
              <Select
                isClearable={true}
                name='service'
                placeholder={strings?.filterByService}
                value={state?.filters?.service}
                options={serviceOptions}
                onChange={onSelect}
                isSearchable={true}
              />
            </Label>
          </FlexV2>
        </Slot>
      </Collapse>
      <Separator />

      <Collapse defaultOpen={isAboveBreakpoint}>
        <Slot name='trigger'>
          <Text variant={'page'} tone={900} fontWeight={700}>
            {strings.locationAndService}
          </Text>
        </Slot>
        <Slot name='content'>
          <Label>
            <Text variant={'page'} tone={900} size={100} fontWeight={700}>
              {strings.organization}
            </Text>
            <Select
              isClearable={organizations?.length > 1}
              name='organizationId'
              placeholder={strings?.filterByOrganization}
              value={state?.filters?.organizationId}
              options={organizationsOptions}
              onChange={onSelect}
              isSearchable={organizations?.length > 1}
            />
          </Label>

          <Label>
            <Text variant={'page'} tone={900} size={100} fontWeight={700}>
              {site.text}
            </Text>
            <Select
              isClearable={true}
              isSearchable={true}
              name='siteId'
              placeholder={`${strings?.filterBy} ${site.text}`}
              value={state?.filters?.siteId}
              options={zoneOptions}
              onChange={onSelect}
            />
          </Label>

          <Label>
            <Text variant={'page'} tone={900} size={100} fontWeight={700}>
              {strings.context}
            </Text>
            <Input
              name='context'
              placeholder={strings?.searchContext}
              value={state?.filters?.context}
              onChange={onSearchContext}
            />
          </Label>
        </Slot>
      </Collapse>
      <Separator />

      <FlexV2 axisGap={400} wrap={'wrap'}>
        <Button
          size='small'
          variant={'error'}
          iconBefore='delete_sweep'
          onClick={onClearFilters}
        >
          {strings.resetAllFields}
        </Button>

        <Button
          size='small'
          variant={'info'}
          iconBefore='sync'
          onClick={onRefetch}
        >
          {strings.refreshData}
        </Button>
      </FlexV2>
    </FlexV2>
  )
}

export default AuditFilters

import {
  LAST_24_HOURS,
  LAST_30_DAYS,
  LAST_7_DAYS,
  LAST_HOUR,
  VALUE_CHART,
  LINE_CHART,
  USE_RANGE_INTERVAL,
  MATRIX_CHART,
  PIE_CHART,
  LAST_3_MONTHS,
  CURRENT_YEAR,
  BAR_CHART,
  LIVE_QUERY,
  AVERAGE_QUERY,
  DATASET_QUERY
} from '.'
import { ChartOption, QueryOption } from './utils'

// Default Chart Options
export const defaultValueChart = new ChartOption({
  value: VALUE_CHART,
  range: [LAST_HOUR, LAST_24_HOURS, LAST_7_DAYS, LAST_30_DAYS],
  interval: [USE_RANGE_INTERVAL]
})

export const defaultLineChart = new ChartOption({
  value: LINE_CHART,
  range: [LAST_HOUR, LAST_24_HOURS, LAST_7_DAYS, LAST_30_DAYS],
  interval: [USE_RANGE_INTERVAL]
})

export const defaultMatrixChart = new ChartOption({
  value: MATRIX_CHART,
  range: [LAST_HOUR, LAST_24_HOURS, LAST_7_DAYS, LAST_30_DAYS],
  interval: [USE_RANGE_INTERVAL]
})

export const defaultPieChart = new ChartOption({
  value: PIE_CHART,
  range: [LAST_30_DAYS, LAST_3_MONTHS, CURRENT_YEAR],
  interval: null
})

export const defaultBarChart = new ChartOption({
  value: BAR_CHART,
  range: [LAST_30_DAYS, LAST_3_MONTHS, CURRENT_YEAR],
  interval: null
})

// Default Query Options

export const defaultLiveValueQuery = new QueryOption({
  value: LIVE_QUERY,
  options: [
    new ChartOption({ value: VALUE_CHART, range: null, interval: null })
  ]
})

export const defaultAverageValueQuery = new QueryOption({
  value: AVERAGE_QUERY,
  options: [
    new ChartOption({
      value: VALUE_CHART,
      range: [LAST_HOUR, LAST_24_HOURS, LAST_7_DAYS, LAST_30_DAYS],
      interval: null
    })
  ]
})

export const defaultDatasetQuery = new QueryOption({
  value: DATASET_QUERY,
  options: [defaultLineChart, defaultMatrixChart]
})

export const datasetLineQuery = new QueryOption({
  value: DATASET_QUERY,
  options: [defaultLineChart]
})

// Most measurement widgets will use these default query options
export const defaultQueryOptions = [
  defaultLiveValueQuery,
  defaultAverageValueQuery,
  defaultDatasetQuery
]

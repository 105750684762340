import axios from 'axios'

const convertedVapidKey = urlBase64ToUint8Array(window.ENV.VAPID_KEY)

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  // eslint-disable-next-line
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

function arrayBufferToBase64(buffer) {
  var binary = ''
  var bytes = new Uint8Array(buffer)
  var len = bytes.byteLength
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

async function sendSubscription(subscription, idToken, user) {
  try {
    await axios.post(
      `${window.ENV.HTTP_URL}/subscriptions/webpush`,
      {
        endpoint: subscription.endpoint,
        p256dh: arrayBufferToBase64(subscription.getKey('p256dh')),
        auth: arrayBufferToBase64(subscription.getKey('auth')),
        userId: user
      },
      {
        headers: {
          authorization: idToken
        }
      }
    )
  } catch (e) {
    // eslint-disable-next-line
    console.log(e)
  }
}

export async function subscribeUser(idToken, user) {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.ready
      if (!registration.pushManager) {
        return
      }

      const existingSubscription =
        await registration.pushManager.getSubscription()

      if (existingSubscription === null) {
        const newSubscription = await registration.pushManager.subscribe({
          applicationServerKey: convertedVapidKey,
          userVisibleOnly: true
        })

        await sendSubscription(newSubscription, idToken, user)
      } else {
        await sendSubscription(existingSubscription, idToken, user)
      }
    } catch (e) {
      // eslint-disable-next-line
      console.log(e)
    }
  }
}

import { I18n } from 'aws-amplify'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  getSensorSimDiagnosticLoading,
  getSensorSimDiagnosticPings
} from '@/reducers/selectors'

import {
  Button,
  Slot,
  Text,
  Loader,
  FlexV2,
  Table,
  Row,
  Column,
  Cell
} from '@/primitives'
import { Dialog, LineSeparator } from '@/elements'
import {
  cleanRequestedSensorSimPings,
  fetchSimDiagnosticPing
} from '@/slices/diagnostics/sensorSim'

import Strings from '../Strings'
import { useFleetManagement } from '../context'
import { getSelectedSensors } from '../utils'

import './SimPingModal.scss'

export default function SimPingModal({ showModal, toggleModal }) {
  const { state } = useFleetManagement()
  const dispatch = useDispatch()
  const strings = Strings()
  const loading = getSensorSimDiagnosticLoading()
  const pings = getSensorSimDiagnosticPings()
  const [requestedPings, setRequestedPings] = useState(new Set())

  useEffect(() => {
    return () => {
      dispatch(cleanRequestedSensorSimPings())
    }
  }, [dispatch])

  const selectedSensors = useMemo(
    () => getSelectedSensors(state.sensorsByZone),
    [state.sensorsByZone]
  )

  const pingsMap = useMemo(() => {
    const map = new Map()
    for (const ping of pings) {
      map.set(ping.sensorId, ping)
    }
    return map
  }, [pings])

  const onClickClose = e => {
    e.preventDefault()
    toggleModal()
  }

  function handleModalSubmit() {
    const sensorIds = selectedSensors.map(sensor => sensor.id)
    setRequestedPings(new Set(sensorIds))
    dispatch(fetchSimDiagnosticPing({ sensorIds }))
  }

  return (
    <Dialog
      type='offcanvas'
      className='SimPingModal'
      open={showModal}
      onOpenChange={toggleModal}
      style={{ zIndex: 3 }}
    >
      <Slot name='title'>
        <Text size={300} fontWeight={700}>
          {I18n.get('Ping Sensor SIM')}
        </Text>
      </Slot>
      <Slot name='content'>
        <LineSeparator />
        <Loader isLoading={loading}>
          <FlexV2
            axisGap={500}
            direction='column'
            alignMainAxis='space-between'
            style={{ height: '100%' }}
          >
            <Table>
              <Slot name='head'>
                <Row>
                  <Column>{I18n.get('Name')}</Column>
                  <Column>{I18n.get('Success')}</Column>
                  <Column>{I18n.get('Status')}</Column>
                  <Column>{I18n.get('RTT')}</Column>
                </Row>
              </Slot>
              <Slot name='body'>
                {selectedSensors.map(sensor => {
                  const ping = pingsMap.get(sensor.id) ?? {}
                  const isRequested = requestedPings.has(sensor.id)
                  const status = ping.status ?? I18n.get('Unknown')
                  let rtt = ping.rtt
                  if (rtt === '-1' || !rtt) {
                    rtt = I18n.get('Unknown')
                  }
                  const success = ping.success
                    ? I18n.get('Yes')
                    : I18n.get('No')

                  return (
                    <Row key={sensor.id}>
                      <Cell>{sensor.name}</Cell>
                      <Cell>{!isRequested ? '-' : success}</Cell>
                      <Cell>{!isRequested ? '-' : status}</Cell>
                      <Cell>{!isRequested ? '-' : rtt}</Cell>
                    </Row>
                  )
                })}
              </Slot>
            </Table>
          </FlexV2>
        </Loader>
      </Slot>

      <Slot name='actions'>
        <FlexV2 alignMainAxis='space-between'>
          <Button size='small' variant='page' onClick={onClickClose}>
            {strings.close}
          </Button>
          <Button
            loading={loading}
            size='small'
            variant='primary'
            onClick={handleModalSubmit}
          >
            {I18n.get("Ping Sensor's SIM")}
          </Button>
        </FlexV2>
      </Slot>
    </Dialog>
  )
}

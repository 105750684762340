import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { requestFertilizerInventoryTransfers } from '@/actions/operations/fertilizerInventoryTransfer'

import {
  getCurrentUser,
  getOperationsFertilizerInventoryTransfers,
  getOperationsFertilizerInventoryTransfersCount,
  getOperationsLoading
} from '@/reducers/selectors'

import { Flex, Box, Table, Slot, Column, Row, Text, Loader } from '@/primitives'

import { Pagination } from '@/elements'

import TableFilter from './TableFilter'
import TableRow from './TableRow'

import history from '../../../../../history'

import {
  hasEditPermissions,
  RESOURCE_TYPE_OPERATIONS,
  RESOURCE_TYPE_OPERATIONS_INVENTORY
} from '@/Util/PermissionUtils'

import Strings from '../Strings'
import useAllFertilizersMap from '../../../Shared/Hooks/useFertilizersMap'
import { isEmpty } from '@aws-amplify/core'
import HeaderV2 from '@/components/Operations/Shared/HeaderV2'

function FertilizerInventoryTransferTable({
  state,
  dispatchState,
  modulePath,
  backPath
}) {
  const dispatch = useDispatch()

  const currentUser = getCurrentUser()
  const items = getOperationsFertilizerInventoryTransfers()
  const totalItems = getOperationsFertilizerInventoryTransfersCount()
  const loading = getOperationsLoading()
  const allFertilizersMap = useAllFertilizersMap()

  const strings = Strings()

  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(9)
  const [tableState, setTableState] = useState(state)
  const [canEdit, setCanEdit] = useState(false)

  useEffect(() => {
    if (currentUser.userName) {
      setCanEdit(
        hasEditPermissions(
          currentUser,
          RESOURCE_TYPE_OPERATIONS,
          RESOURCE_TYPE_OPERATIONS_INVENTORY
        )
      )
    }
  }, [currentUser])

  useEffect(() => {
    if (tableState.page !== state.page || items.length === 0) {
      setTableState({ ...state })
      fetchInventoryTransfers(state.page)
    }
  }, [state.page])

  useEffect(() => {
    if (
      tableState.organizations !== state.organizations ||
      tableState.fromStorageFacilities !== state.fromStorageFacilities ||
      tableState.storageFacilities !== state.storageFacilities
    ) {
      setTableState({ ...state })
      fetchInventoryTransfers(1)
      changePage(1)
    }
  }, [
    state.organizations,
    state.fromStorageFacilities,
    state.storageFacilities
  ])

  const fetchInventoryTransfers = page => {
    if (
      state.organizations.length > 0 &&
      state.fromStorageFacilities.length > 0 &&
      state.storageFacilities.length > 0
    ) {
      dispatch(
        requestFertilizerInventoryTransfers({
          filter: {
            organizationId: state.organizations,
            storageFacilityId: state.storageFacilities,
            fertilizerInventoryFilter: {
              storageFacilityId: state.fromStorageFacilities
            }
          },
          limit: pageSize,
          offset: page - 1
        })
      )
    }
  }

  const changePage = newPage => {
    dispatchState({
      page: newPage
    })
  }

  const onCreate = () => {
    history.push(`${modulePath}/create`)
  }

  const onEdit = id => {
    history.push(`${modulePath}/update/${id}`)
  }

  return (
    <Flex axisGap={400} direction='column'>
      <HeaderV2
        title={strings.manageInventoryTransfers}
        buttonIcon={'add'}
        buttonText={strings.createInventoryTransfer}
        buttonCallback={onCreate}
        showButton={canEdit}
        backPath={backPath}
      >
        <TableFilter state={state} dispatchState={dispatchState} />
      </HeaderV2>
      <Loader isLoading={loading}>
        {totalItems === 0 && (
          <Flex className={'Operations__Table__Empty'}>
            <Text>{strings.noInventoryTransfers}</Text>
          </Flex>
        )}
        {totalItems > 0 && !isEmpty(allFertilizersMap) && (
          <Table className='Operations__Table' aria-colcount='6'>
            <Slot name='head'>
              <Row>
                <Column>{strings.from}</Column>
                <Column>{strings.to}</Column>
                <Column>{strings.fertilizer}</Column>
                <Column>{strings.totalWeight}</Column>
                <Column>{strings.status}</Column>
                <Column />
              </Row>
            </Slot>
            <Slot name='body'>
              {items?.map(item => (
                <TableRow
                  key={item.id}
                  onEdit={onEdit}
                  canEdit={canEdit}
                  item={item}
                  allFertilizersMap={allFertilizersMap}
                />
              ))}
            </Slot>
          </Table>
        )}
        {totalItems > pageSize && (
          <Box className='pagination-menu'>
            <Pagination
              type='simple'
              totalItems={totalItems}
              totalPages={totalPages}
              setTotalPages={setTotalPages}
              page={tableState.page}
              setPage={changePage}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          </Box>
        )}
      </Loader>
    </Flex>
  )
}

export default FertilizerInventoryTransferTable

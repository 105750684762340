import { useReducer, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import useDeviceSize from '@/hooks/useDeviceSize'

import { clearPackageUnitInventories } from '@/actions/operations/packageUnitInventory'

import { createPackageUnitInventoryCalibration } from '@/actions/operations/packageUnitInventoryCalibration'

import {
  getOperationsPackageUnitInventory,
  getOperationsLoading,
  getOperationsError
} from '@/reducers/selectors'

import { Box, Flex, Label, Text, Input, Loader } from '@/primitives'

import InputError from '@/elements/InputError'
import HeaderV2 from '@/components/Operations/Shared/HeaderV2'

import { getValidationErrorMap } from '@/Util/GeneralUtils'

import history from '../../../../../history'
import Strings from '../Strings'

import { initialState, reducer, UPDATE_INPUT, SET_STATE } from './state'

import { FIELDS, SCHEMA } from './config'

function PackageUnitInventoryCalibrationForm({
  state,
  modulePath,
  packageUnitInventoryId
}) {
  const dispatch = useDispatch()
  const isMobile = useDeviceSize('mobile')
  const isLaptop = useDeviceSize()

  const strings = Strings()

  const packageUnitInventory = getOperationsPackageUnitInventory()
  const loading = getOperationsLoading()
  const error = getOperationsError()

  const [formState, dispatchFormState] = useReducer(reducer, initialState)

  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)

  useEffect(() => {
    if (!packageUnitInventoryId) {
      history.push('/operations/inventory/packaging')
    }
  }, [packageUnitInventoryId])

  useEffect(() => {
    if (packageUnitInventory.id === packageUnitInventoryId) {
      dispatchFormState({
        type: SET_STATE,
        state: {
          organizationId: packageUnitInventory.organizationId,
          packageUnitInventoryId: packageUnitInventory.id
        }
      })
    }
  }, [packageUnitInventory])

  useEffect(() => {
    if (waiting && !loading && !error) {
      history.replace(modulePath)
    }
  }, [loading])

  const onSubmit = async e => {
    try {
      const payload = await SCHEMA().validate(formState, { abortEarly: false })
      setWaiting(true)

      dispatch(createPackageUnitInventoryCalibration(payload))
      dispatch(clearPackageUnitInventories())
    } catch (error) {
      setErrors(getValidationErrorMap(error))
    }
  }

  const handleInput = e => {
    const { name, value } = e.currentTarget
    dispatchFormState({ type: UPDATE_INPUT, name, value })
  }

  const handleNumericInput = e => {
    const { name, value } = e.currentTarget
    const newValue = value.length > 0 ? Number(value) : ''
    dispatchFormState({ type: UPDATE_INPUT, name, value: newValue })
  }

  const getHeader = () => {
    return strings.createFormHeader
  }

  return (
    <Flex
      as='form'
      axisGap={400}
      direction='column'
      className='Operations__Form'
    >
      <HeaderV2
        title={getHeader()}
        backPath={modulePath}
        buttonIcon={'save'}
        buttonText={strings.savePackageUnitInventoryCalibration}
        buttonCallback={onSubmit}
      />
      <Loader isLoading={loading}>
        <Flex axisGap={800} direction={isLaptop ? 'column' : null}>
          <Flex
            axisGap={400}
            direction='column'
            className='Operations__Form__Fields'
          >
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.packageUnitInventoryCalibration}
              </Text>
              {packageUnitInventory.id && (
                <Flex
                  axisGap={300}
                  alignMainAxis='space-evenly'
                  direction={isMobile ? 'column' : 'row'}
                >
                  <Label style={{ flex: '1 1 0' }}>
                    <Text variant='page' tone={700}>
                      {strings.packageUnitInventory}
                    </Text>
                    <Text
                      variant='page'
                      tone={700}
                      className='Operations__Form__Fields__Text'
                    >
                      {packageUnitInventory.storageFacility.name}&nbsp;-&nbsp;
                      {packageUnitInventory.packageUnit.name}&nbsp; (
                      {packageUnitInventory.packageUnit.weight}
                      {strings.grams})
                    </Text>
                  </Label>
                </Flex>
              )}
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.currentQuantity}
                  </Text>
                  <Text
                    variant='page'
                    tone={700}
                    className='Operations__Form__Fields__Text'
                  >
                    {packageUnitInventory.quantity}
                  </Text>
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.newQuantity}
                  </Text>
                  <Input
                    className='Operations__Input'
                    type='number'
                    name={FIELDS.newQuantity}
                    value={formState.newQuantity}
                    onChange={handleNumericInput}
                  />
                  <InputError error={errors?.newQuantity} />
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.reason}
                  </Text>
                  <Input
                    className='Operations__Input'
                    type='text'
                    name={FIELDS.reason}
                    value={formState.reason}
                    onChange={handleInput}
                  />
                  <InputError error={errors?.reason} />
                </Label>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Loader>
    </Flex>
  )
}

export default PackageUnitInventoryCalibrationForm

const WIDGETS_WITHOUT_MEASUREMENTS = ['timezoneClock', 'weather', 'crops']

export function filterToolboxByAvailableMeasurements(
  toolboxSections,
  availableMeasurements
) {
  function filterItems(items, parentChain = []) {
    let result = []

    for (let item of items) {
      if (item.type === 'widget') {
        if (
          !item.options ||
          availableMeasurements.has(item.options.measurement) ||
          WIDGETS_WITHOUT_MEASUREMENTS.includes(item.widgetId)
        ) {
          // include the parent chain along with the widget
          let newItem = { ...item, parentChain: [...parentChain] }
          result.push(newItem)
        }
      } else if (item.items && Array.isArray(item.items)) {
        // update the parent chain and pass it down
        let newParentChain = [
          ...parentChain,
          { id: item.id, title: item.title, type: item.type }
        ]
        let children = filterItems(item.items, newParentChain)

        if (children.length > 0) {
          // include this item if it has matching children
          let newItem = { ...item, items: children }
          result.push(newItem)
        }
      }
    }

    return result
  }

  return filterItems(toolboxSections)
}

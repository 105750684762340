import { Fragment } from 'react'

import { Text, Flex, Label, Input, Box, Button, Card, Slot } from '@/primitives'
import { Select } from '@/elements'

import { getZoneDevices } from '@/reducers/selectors'

import Strings from '../../../Strings'
import './index.scss'

export default function SpecSettings({
  sensorOptions,
  relayOptions,
  measurementOptions,
  spec,
  removeSpec,
  updateSpec,
  index,
  errors,
  showDetails,
  setShowDetails
}) {
  const strings = Strings()
  const { relayId, sensorId, measurementId, min, max } = spec

  const sensors = getZoneDevices()

  function getCurrentSensorName() {
    if (sensors?.length === 0 || sensorId?.length === 0) return ''
    const sensor = sensors?.find(({ id }) => id === sensorId)
    return sensor?.name ?? sensorId
  }

  function onSelectItem({ value }, { name }) {
    updateSpec(name, value, index)
  }

  function onChangeInput({ target }) {
    const { value, name } = target
    updateSpec(name, value, index)
  }

  function onToggleShow() {
    const newValue = showDetails ? null : index
    setShowDetails(newValue)
  }

  function getClassname(fieldName) {
    const errorPath = `specs[${index}].${fieldName}`
    if (errors?.hasOwnProperty(errorPath)) {
      return 'Error'
    }
    return 'Default'
  }

  function getSelectBorderStyle(fieldName) {
    const errorPath = `specs[${index}].${fieldName}`
    if (errors?.hasOwnProperty(errorPath)) {
      return { border: '1px solid var(--ctx-theme-color-error-600)' }
    }
    return {}
  }

  function getErrorText(fieldName) {
    const errorPath = `specs[${index}].${fieldName}`
    if (errors?.hasOwnProperty(errorPath)) {
      return (
        <Text
          size={100}
          variant='error'
          tone={700}
          style={{ margin: '-0.4rem 0 0.4rem', display: 'block' }}
        >
          {errors[errorPath]}
        </Text>
      )
    }
    return null
  }

  return (
    <Fragment>
      <Card>
        <Slot name='header'>
          <Flex alignMainAxis='space-between' alignCrossAxis='center'>
            <Text fontWeight={600}>{`Specification for ${relayId}`}</Text>
            <Box>
              <Button
                size='small'
                variant='error'
                value={relayId}
                onClick={removeSpec}
              >
                {strings.removeButtonControl}
              </Button>
              <Button
                size='small'
                variant='text'
                iconBefore={!showDetails ? 'expand_more' : 'expand_less'}
                onClick={onToggleShow}
              ></Button>
            </Box>
          </Flex>
        </Slot>
        {showDetails && (
          <Slot name='body'>
            <Label>
              <Text>{strings.relayId}</Text>
              <Select
                classNamePrefix='ControlDeviceSpec__Select'
                controlStyles={getSelectBorderStyle('relayId')}
                onChange={onSelectItem}
                value={relayId}
                name='relayId'
                options={[
                  ...relayOptions,
                  {
                    value: relayId,
                    label: relayId,
                    isDisabled: true,
                    className: 'hidden'
                  }
                ]}
              />
            </Label>
            {getErrorText('relayId')}
            <Label>
              <Text>{strings.sensor}</Text>
              <Select
                value={sensorId}
                onChange={onSelectItem}
                name='sensorId'
                placeholder={strings.sensorPlaceholder}
                controlStyles={getSelectBorderStyle('sensorId')}
                options={
                  sensorId?.length > 0
                    ? [
                        ...sensorOptions,
                        {
                          value: sensorId,
                          label: getCurrentSensorName(),
                          isDisabled: true,
                          className: 'hidden'
                        }
                      ]
                    : sensorOptions
                }
              />
            </Label>
            {getErrorText('sensorId')}
            <Label>
              <Text>{strings.measurement}</Text>
              <Select
                onChange={onSelectItem}
                value={measurementId}
                name='measurementId'
                placeholder={strings.measurementPlaceholder}
                controlStyles={getSelectBorderStyle('measurementId')}
                options={measurementOptions}
              />
            </Label>
            {getErrorText('measurementId')}
            <Text variant='page' tone={800} className='ConfigConditions__Text'>
              {strings.configConditions}
            </Text>
            <Box className='Conditions'>
              <Flex direction='row' wrap='nowrap' axisGap={300}>
                <Box>
                  <Label>
                    <Text>{strings.min}</Text>
                    <Input
                      name='min'
                      value={min}
                      onChange={onChangeInput}
                      className={getClassname('min')}
                      placeholder={strings.minPlaceholder}
                    />
                  </Label>
                  {getErrorText('min')}
                </Box>
                <Box>
                  <Label style={{ marginBlockStart: '0' }}>
                    <Text>{strings.max}</Text>
                    <Input
                      name='max'
                      value={max}
                      onChange={onChangeInput}
                      className={getClassname('max')}
                      placeholder={strings.maxPlaceholder}
                    />
                  </Label>
                  {getErrorText('max')}
                </Box>
              </Flex>
            </Box>
          </Slot>
        )}
      </Card>
    </Fragment>
  )
}

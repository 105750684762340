import { I18n } from 'aws-amplify'


// @todo: Clean unused strings, perhaps compartmentalize into smaller files
const Strings = () => ({
  addPhoto: I18n.get('Add Photo'),
  newPhoto: I18n.get('New Photo'),
  subject: I18n.get('Subject'),
  status: I18n.get('Status'),
  photos: I18n.get('Photos'),
  retakePhoto: I18n.get('Retake'),
  cancel: I18n.get('Cancel'),
  photo: I18n.get('Photo'),
  takePhoto: I18n.get('Take Photo'),
  save: I18n.get('Save'),
  cameraPermissionError: I18n.get('Please allow use of camera'),
  selectSubject: I18n.get('Select Subject'),
  subjectNone: I18n.get('None'),
  subjectPlant: I18n.get('Plant'),
  subjectLeaf: I18n.get('Leaf'),
  subjectFruit: I18n.get('Fruit'),
  subjectSubstrate: I18n.get('Substrate'),
  selectStatus: I18n.get('Select Status'),
  statusNone: I18n.get('None'),
  statusHealthy: I18n.get('Healthy'),
  statusDiseased: I18n.get('Diseased'),
  statusDamaged: I18n.get('Damaged'),
  statusDeformed: I18n.get('Deformed')
})

export default Strings

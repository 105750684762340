import { I18n } from 'aws-amplify'
import { Fragment, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import classNames from 'classnames'

import { Text, Button, Flex } from '@/primitives'

import './index.scss'

function DocumentUploader({
  name,
  file = {},
  fileUrl = '',
  fileName = '',
  onSetFile,
  onDeselectFile,
  setErrors
}) {
  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0]
        onSetFile(name, file)
        setErrors(err => ({ ...err, [name]: null }))
      }
      if (rejectedFiles.length > 0) {
        setErrors(err => ({
          ...err,
          [name]: I18n.get('The document must be a pdf file.')
        }))
      }
    },
    [name, onSetFile, setErrors]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'application/pdf': ['.pdf']
    }
  })

  const onPreview = () => {
    let url = fileUrl
    if (!fileUrl) {
      const blob = new Blob([file], { type: 'application/pdf' })
      url = URL.createObjectURL(blob)
    }
    window.open(url)
  }

  const onDeselect = () => {
    onDeselectFile(name)
  }

  // console.log(fileName)

  return (
    <div
      {...getRootProps()}
      className={classNames('DocumentUploader', {
        'drag-disabled': !!file?.name || fileName
      })}
    >
      <input {...getInputProps()} disabled={!!file?.name || fileName} />
      {file?.name || fileName ? (
        <Flex axisGap={500} alignCrossAxis='center'>
          <Text>{file.name || fileName}</Text>
          <Flex axisGap={200}>
            <Button
              variant='text'
              iconBefore='visibility'
              size='small'
              onClick={onPreview}
            />
            <Button
              variant='text'
              iconBefore='close'
              size='small'
              onClick={onDeselect}
            />
          </Flex>
        </Flex>
      ) : (
        <Fragment>
          {isDragActive ? (
            <Text>{I18n.get('Drop the file here')}</Text>
          ) : (
            <Text>
              {I18n.get("Click to chose a file or drag'n'drop it here")}
            </Text>
          )}
        </Fragment>
      )}
    </div>
  )
}
export default DocumentUploader

import { I18n } from 'aws-amplify'
import { useEffect, useState } from 'react'

import { getNavigationLocationSelector, getZonesHierarchy } from '../../../../reducers/selectors'

import { Select } from '../../../../primitives'

import ZoneUtils from '../../../../Util/ZoneUtils'

const SitesFilter = ({ state, dispatchState }) => {
  const zonesHierarchy = getZonesHierarchy()

  const locationSelector = getNavigationLocationSelector()

  const [sites, setSites] = useState([])

  const [siteFilter, setSiteFilter] = useState(
    state.sites.length === 1 ? state.sites[0] : 'all'
  )
  const [lastFilter, setLastFilter] = useState(siteFilter)

  useEffect(() => {
    if (Object.keys(zonesHierarchy)?.length > 0) {
      setSites(ZoneUtils.getRootZonesFromHierarchy(zonesHierarchy))
    }
  }, [zonesHierarchy])

  useEffect(() => {
    if (locationSelector.get('site')) {
      setSiteFilter(locationSelector.get('site').id)
    }
  }, [locationSelector.get('site')])

  useEffect(() => {
    if (siteFilter === 'all') {
      if (state.sites.length === 0 && sites.length > 0) {
        dispatchChange()
      } else if (state.sites.length === 1 && sites.length > 0) {
        setLastFilter(state.sites[0])
        setSiteFilter(state.sites[0])
      }
    } else if (state.sites.length === 0) {
      setSiteFilter('all')
    }
  }, [state.sites, sites])

  useEffect(() => {
    if (sites.length > 0) {
      if (siteFilter !== lastFilter) {
        setLastFilter(siteFilter)
        dispatchChange()
      }
    }
  }, [siteFilter, sites])

  const getSiteFilter = () => {
    return siteFilter === 'all' ? sites.map(i => i.id) : [siteFilter]
  }

  const dispatchChange = () => {
    dispatchState({
      sites: getSiteFilter()
    })
  }

  const onChangeFilter = e => {
    setSiteFilter(e.currentTarget.value)
  }

  const filterSites = () => {
    if (state.organizations.length === 1) {
      return sites.filter(site =>
        state.organizations.includes(site.organizationId)
      )
    }
    return []
  }

  const options = filterSites()

  return (
    <Select
      className='Operations__Header__Select'
      name='siteFilter'
      value={siteFilter}
      onChange={onChangeFilter}
      disabled={options.length === 0}
      style={{ visibility: 'hidden', position: 'absolute', zIndex: -1 }}
    >
      <option default value='all'>
        {I18n.get('All Sites')}
      </option>
      {options.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </Select>
  )
}

export default SitesFilter

import { Fragment } from 'react'
import { useHistory } from 'react-router-dom'

import { Select } from '@/elements'

import { getDeviceOptions } from './utils'
import ZoneUtils from '@/Util/ZoneUtils'
import Strings from '../Strings'

export const getChildIds = children => {
  return Object.keys(children).filter(id => {
    const { status } = children[id]
    return status === 'active'
  })
}

export default function LocationSelector({ zone }) {
  const history = useHistory()
  const strings = Strings()

  if (!zone?.id) return null

  const childZoneLevelName = ZoneUtils.getZoneDepthIdentifier(zone, 1)
  const childIds = getChildIds(zone?.children)
  let zoneOptions = []
  const zoneHasChildren = childIds.length > 0
  const zoneHasDevices = zone?.devices?.length > 0

  const onSelectZone = ({ value, isZone }) => {
    let path = '/zones'
    if (isZone) path = `/zones${zone.children[value].parentPath}`
    if (!isZone) {
      const deviceDetails = zone.devices.find(({ id }) => id === value)
      const { zonePath, sensorType } = deviceDetails
      const isCameraType = sensorType === 'camera'
      path = isCameraType ? `/zones${zonePath}/video-feeds` : `/zones${zonePath}/sensor/${sensorType}/${value}`
    }
    history.push(path)
  }

  if (zoneHasChildren || zoneHasDevices) {
    const zoneChildrenOptions = childIds.map(id => {
      const { name } = zone?.children[id]
      return {
        value: id,
        label: name,
        isZone: true
      }
    })

    const childrenGroup = {
      label: childZoneLevelName,
      options: zoneChildrenOptions
    }

    const devicesGroup = {
      label: strings.sensorsText,
      options: getDeviceOptions(zone?.devices)
    }

    zoneOptions = [childrenGroup, devicesGroup]
  }

  const getPlaceholderText = () => {
    let baseString = `${strings.select} `
    if (zoneHasChildren) baseString += childZoneLevelName.toLowerCase()
    if (zoneHasChildren && zoneHasDevices) baseString += '/'
    if (zoneHasDevices) baseString += strings.sensor
    return baseString
  }

  return (
    <Fragment>
      {(zoneHasChildren || zoneHasDevices) && (
        <Select
          className='Breadcrumbs__LocationSelector'
          controlStyles={{
            minHeight: '1.6rem',
            padding: '0',
            minWidth: '10rem'
          }}
          name='subzones'
          value={null}
          onChange={onSelectZone}
          options={zoneOptions}
          classNamePrefix='Breadcrumbs__LocationSelector'
          placeholder={getPlaceholderText()}
        />
      )}
    </Fragment>
  )
}
import { Fragment, useCallback, useEffect, useState } from 'react'
import { I18n } from 'aws-amplify'
import { useDispatch } from 'react-redux'

import { requestGrades } from '@/actions/operations/grade'

import {
  getCurrentUser,
  getCurrentUserOrganizations,
  getOperationsGrades,
  getOperationsGradesCount,
  getOperationsLoading
} from '@/reducers/selectors'

import {
  Flex,
  Button,
  Cell,
  Text,
  Column,
  Row,
  Slot,
  Table,
  Loader
} from '@/primitives'

import { Pagination } from '@/elements'

import OrganizationsFilter from '../../Shared/Filters/OrganizationsFilter'
import HeaderV2 from '@/components/Operations/Shared/HeaderV2'

import history from '../../../../history'

import {
  hasEditPermissions,
  RESOURCE_TYPE_OPERATIONS,
  RESOURCE_TYPE_OPERATIONS_MANAGEMENT
} from '@/Util/PermissionUtils'

function GradeTable({ state, dispatchState, modulePath }) {
  const dispatch = useDispatch()

  const currentUser = getCurrentUser()
  const organizations = getCurrentUserOrganizations()
  const grades = getOperationsGrades()
  const gradesCount = getOperationsGradesCount()
  const loading = getOperationsLoading()

  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(9)
  const [tableState, setTableState] = useState(state)
  const [canEdit, setCanEdit] = useState(false)
  const [showOrganization, setShowOrganization] = useState(false)
  const [fetchCalled, setFetchCalled] = useState(false)

  const fetchGrades = useCallback(
    page => {
      const { organizations } = state
      if (organizations.length > 0) {
        dispatch(
          requestGrades({
            filter: {
              organizationId: organizations
            },
            limit: pageSize,
            offset: page - 1
          })
        )
        setFetchCalled(true)
      }
    },
    [state, pageSize, dispatch]
  )

  const changePage = useCallback(
    newPage => {
      dispatchState({ page: newPage })
      setFetchCalled(false)
    },
    [dispatchState]
  )

  useEffect(() => {
    if (currentUser.userName) {
      setCanEdit(
        hasEditPermissions(
          currentUser,
          RESOURCE_TYPE_OPERATIONS,
          RESOURCE_TYPE_OPERATIONS_MANAGEMENT
        )
      )
    }
    if (organizations.length > 1) {
      setShowOrganization(true)
    }
  }, [currentUser, organizations])

  useEffect(() => {
    if (
      tableState.organizations !== state.organizations ||
      tableState.page !== state.page
    ) {
      if (!fetchCalled) {
        fetchGrades(state.page)
      }
    }
  }, [state, tableState, fetchGrades, fetchCalled])

  useEffect(() => {
    setTableState({ ...state })
  }, [state])

  const onCreate = () => history.push(`${modulePath}/create`)

  const onEdit = id => history.push(`${modulePath}/update/${id}`)

  const getOrganizationName = organizationId => {
    const organization = organizations.find(org => org.id === organizationId)
    return organization ? organization.name : null
  }

  return (
    <Fragment>
      <HeaderV2
        title={I18n.get('Manage Grades')}
        buttonIcon={'add'}
        buttonText={I18n.get('Add Grade')}
        buttonCallback={onCreate}
        showButton={canEdit}
      >
        <OrganizationsFilter state={state} dispatchState={dispatchState} />
      </HeaderV2>
      <Loader isLoading={loading}>
        {gradesCount === 0 && (
          <Flex className={'Operations__Table__Empty'}>
            <Text>{I18n.get('No entries')}</Text>
          </Flex>
        )}
        {gradesCount > 0 && (
          <Table aria-colcount='3' className='Operations__Table'>
            <Slot name='head'>
              <Row>
                {showOrganization && (
                  <Column>{I18n.get('Organization')}</Column>
                )}
                <Column>{I18n.get('Grade')}</Column>
                <Column></Column>
              </Row>
            </Slot>
            <Slot name='body'>
              {grades.map(({ id, organizationId, name }) => (
                <Row key={id}>
                  {showOrganization && (
                    <Cell columnName={I18n.get('Organization')}>
                      {getOrganizationName(organizationId)}
                    </Cell>
                  )}
                  <Cell columnName={I18n.get('Grade')}>{name}</Cell>
                  <Cell className='Operations__Table__Column__Actions'>
                    {canEdit && (
                      <Button
                        variant='info'
                        size='small'
                        iconBefore='edit'
                        onClick={() => onEdit(id)}
                      />
                    )}
                  </Cell>
                </Row>
              ))}
            </Slot>
          </Table>
        )}
        {gradesCount > pageSize && (
          <Pagination
            type='simple'
            totalItems={gradesCount}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            page={tableState.page}
            setPage={changePage}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        )}
      </Loader>
    </Fragment>
  )
}

export default GradeTable

import { CHARTS } from '../Widgets/Shared/Dynamic/config'
import { getBreakpointPositionWithFallback } from '../utils'

// Helper function to calculate the width of an available space
function calculateWidth(input, x, y) {
  let width = 0
  for (let w = x; w < input[y].length && input[y][w] === 0; w++) {
    width++
  }
  return width
}

// Helper function to calculate the height of an available space
function calculateHeight(input, x, y) {
  let height = 0
  for (let h = y; h < input.length && input[h][x] === 0; h++) {
    height++
  }
  return height
}

// Helper function to check if two spaces are adjacent
function areSpacesAdjacent(space1, space2) {
  // Check if space2 is adjacent to space1 on the right and has the same height
  const isAdjacentOnRight =
    space1.y === space2.y && // Check if the spaces are on the same row
    space1.x + space1.width === space2.x && // Check if space2 is adjacent to space1 on the right
    space1.height === space2.height // Check if the spaces have the same height

  // Check if space2 is adjacent to space1 below it and has the same width
  const isAdjacentBelow =
    space1.x === space2.x && // Check if the spaces are on the same column
    space1.y + space1.height === space2.y && // Check if space2 is adjacent to space1 below it
    space1.width === space2.width // Check if the spaces have the same width

  return isAdjacentOnRight || isAdjacentBelow
}

function checkCells(input, x, y, width, height) {
  // Loop through each row in the rectangle defined by (x,y,width,height)
  for (let h = y; h < y + height; h++) {
    // Loop through each cell in the current row
    for (let w = x; w < x + width; w++) {
      // Check if the current cell is not 0
      if (input[h][w] !== 0) {
        // If the cell is not 0, adjust the width and height of the rectangle
        // to exclude this cell and any cells to the right or below it
        width = Math.min(width, w - x)
        height = Math.min(height, h - y)
      }
    }
  }
  // Return the adjusted width and height of the rectangle
  return [width, height]
}

function markVisitedCells(visited, x, y, width, height) {
  // Create a copy of the visited array
  const newVisited = visited.map(row => [...row])

  // Loop through each row in the rectangle defined by (x,y,width,height)
  for (let h = y; h < y + height; h++) {
    // Loop through each cell in the current row
    for (let w = x; w < x + width; w++) {
      // Mark the current cell as visited
      newVisited[h][w] = true
    }
  }

  // Return the updated visited array
  return newVisited
}

function addToOutput(output, x, y, width, height) {
  // Create a copy of the output array
  const newOutput = [...output]
  // Initialize merged flag to false
  let merged = false

  // Loop through each space in the output array
  for (const space of newOutput) {
    // Check if the new space is adjacent to the current space
    if (
      areSpacesAdjacent(space, { x: x, y: y, width: width, height: height })
    ) {
      // If the new space is adjacent on the right, increase the width of the current space
      if (space.y === y) {
        space.width += width
      } else {
        // If the new space is adjacent below, increase the height of the current space
        space.height += height
      }
      // Set merged flag to true and break out of loop
      merged = true
      break
    }
  }

  // If the new space was not merged with an existing space,
  // add it to the output array
  if (!merged) {
    const newSpace = {
      x: x,
      y: y,
      width: width,
      height: height
    }
    newOutput.push(newSpace)
  }

  // Return the updated output array
  return newOutput
}

function calculateAvailableSpaces(input) {
  // Initialize output array
  const output = []
  // Create a 2D array to keep track of visited cells
  const visited = input.map(row => row.map(() => false))

  // Loop through each row in the input array
  for (let y = 0; y < input.length; y++) {
    // Loop through each cell in the current row
    for (let x = 0; x < input[y].length; x++) {
      // Check if cell is 0 and has not been visited
      if (input[y][x] === 0 && !visited[y][x]) {
        // Calculate width and height of available space
        let width = calculateWidth(input, x, y)
        let height = calculateHeight(input, x, y)

        // Check if all cells in the rectangle are 0s and adjust width and height if necessary
        const [newWidth, newHeight] = checkCells(input, x, y, width, height)
        width = newWidth
        height = newHeight

        // Mark visited cells
        const newVisited = markVisitedCells(visited, x, y, width, height)
        visited.splice(0, visited.length, ...newVisited)

        // Add to output and merge with adjacent spaces if necessary
        const newOutput = addToOutput(output, x, y, width, height)
        output.splice(0, output.length, ...newOutput)
      }
    }
  }

  // Return the final output array
  return output.map(space => {
    const key = `${space.width}x${space.height}`
    return { [key]: { x: space.x, y: space.y } }
  })
}

/**
 * Find the next available position for a widget
 * This function will create a 2D array of the current layout and
 * find the next available position for the widget by finding the
 * largest available space in the layout
 * @param {Object} param
 */
export const findNextAvailablePosition = ({
  preparedWidget,
  widgets,
  breakpoint,
  columns
}) => {
  const adjacencyList = []

  // Calculate maxY for the adjacency list
  let maxY = 0
  for (let widget of widgets.values()) {
    // eslint-disable-next-line no-unused-vars
    const [_x, y, _w, h] =
      widget.props.position[breakpoint] ||
      Object.values(widget.props.position)[0]
    const bottomY = y + h
    if (bottomY > maxY) maxY = bottomY
  }

  // Initialize the adjacency list with 0s
  for (let y = 0; y <= maxY - 1; y++) {
    const row = []
    for (let x = 0; x < columns; x++) {
      row.push(0)
    }
    adjacencyList.push(row)
  }

  for (let widget of widgets.values()) {
    const [x, y, w, h] =
      widget.props.position[breakpoint] ||
      Object.values(widget.props.position)[0]

    for (let i = 0; i < h; i++) {
      for (let j = 0; j < w; j++) {
        // Check if the row exists
        if (!adjacencyList[y + i]) {
          // If the row doesn't exist, initialize it with 0s
          adjacencyList[y + i] = new Array(columns).fill(0)
        }
        adjacencyList[y + i][x + j] = 1
      }
    }
  }

  const availableSpaces = calculateAvailableSpaces(adjacencyList)
  // Find the first available space that is large enough to fit the new widget
  // eslint-disable-next-line no-unused-vars
  const [_x, _y, widgetWidth, widgetHeight, minW, minH] = getWidgetPosition(
    preparedWidget,
    breakpoint
  )

  for (let space of availableSpaces) {
    const [width, height] = Object.keys(space)[0].split('x')
    if (width >= widgetWidth && height >= widgetHeight) {
      const { x, y } = space[`${width}x${height}`]

      return [x, y, widgetWidth, widgetHeight, minW, minH]
    }
  }

  // If no space is found, return the position below the last row
  return [0, maxY + 1, widgetWidth, widgetHeight, minW, minH]
}

export const getWidgetPosition = (widget, breakpoint) => {
  const WIDTH_INDEX = 2
  const HEIGHT_INDEX = 3

  const chart = widget?.config?.chart

  let [cX, cY, cW, cH, cMinW, cMinH] = getBreakpointPositionWithFallback(
    widget.defaultPosition,
    breakpoint
  )

  if (chart) {
    const chartSize =
      CHARTS?.[chart]?.minSize?.[breakpoint] ||
      Object.values(CHARTS?.[chart]?.minSize || {})?.[0] // fallback to first breakpoint

    const { minW: chartMinW, minH: chartMinH } = chartSize
    let chartAccountedPosition = [cX, cY, cW, cH, chartMinW, chartMinH]

    if (cW < chartMinW) {
      chartAccountedPosition[WIDTH_INDEX] = chartMinW
    }

    if (cH < chartMinH) {
      chartAccountedPosition[HEIGHT_INDEX] = chartMinH
    }

    return chartAccountedPosition
  }

  return [cX, cY, cW, cH, cMinW, cMinH]
}

import { DateTime } from 'luxon'

export const getFirmwareLabel = (version, unixTimestamp) => {
  const dt = DateTime.fromSeconds(unixTimestamp)
  const dtStr = dt.toFormat('EEE MMM dd HH:mm:ss yyyy')
  const label = `${version} - ${dtStr}`
  return label
}

// sort by name
export const byName = (a, b) => {
  const nameA = a.name.toUpperCase()
  const nameB = b.name.toUpperCase()
  if (nameA < nameB) return -1
  if (nameA > nameB) return 1
  return 0
}

import { I18n } from 'aws-amplify'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Select } from '@/primitives'

import { fetchAllFertilizers } from '@/slices/fertigation/fertilizer'

import { getAllFertilizers } from '@/reducers/selectors'

const FertilizerFilter = ({
  state,
  dispatchState,
  stateKey = 'fertilizers',
  defaultLabel = 'All Fertilizers'
}) => {
  const dispatch = useDispatch()

  const allFertilizers = getAllFertilizers()

  const [fertilizerFilter, setFertilizerFilter] = useState(
    state[stateKey].length === 1 ? state[stateKey][0] : 'all'
  )
  const [lastFilter, setLastFilter] = useState(fertilizerFilter)

  const getFertilizerFilter = useCallback(() => {
    return fertilizerFilter === 'all'
      ? allFertilizers.map(i => i.id)
      : [fertilizerFilter]
  }, [allFertilizers, fertilizerFilter])

  const dispatchChange = useCallback(() => {
    dispatchState({
      [stateKey]: getFertilizerFilter()
    })
  }, [dispatchState, getFertilizerFilter, stateKey])

  useEffect(() => {
    dispatch(fetchAllFertilizers())
  }, [dispatch])

  useEffect(() => {
    if (
      fertilizerFilter === 'all' &&
      state[stateKey].length === 0 &&
      allFertilizers.length > 0
    ) {
      dispatchChange()
    }
  }, [state, stateKey, allFertilizers, fertilizerFilter, dispatchChange])

  useEffect(() => {
    if (allFertilizers.length > 0) {
      if (fertilizerFilter !== lastFilter) {
        setLastFilter(fertilizerFilter)
        dispatchChange()
      }
    }
  }, [fertilizerFilter, allFertilizers, lastFilter, dispatchChange])

  const onChangeFilter = e => {
    setFertilizerFilter(e.currentTarget.value)
  }

  return (
    <Select
      className='Operations__Header__Select'
      name={stateKey}
      value={fertilizerFilter}
      onChange={onChangeFilter}
      disabled={allFertilizers.length === 0}
    >
      <option default value='all'>
        {I18n.get(defaultLabel)}
      </option>
      {allFertilizers.map(({ name, id }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </Select>
  )
}

export default FertilizerFilter

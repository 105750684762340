import { useRef, useEffect, useState } from 'react'
import classNames from 'classnames'

import { Box, Text } from '@/primitives'
import { Tooltip } from '@/elements'

export default function CrumbTag({ isSensor, device, maxWidth = 150 }) {
  const tipRef = useRef(null)
  const [isOverflow, setIsOverflow] = useState(false)

  useEffect(() => {
    if (tipRef.current) {
      setIsOverflow(tipRef.current.offsetWidth > maxWidth)
    }
  }, [maxWidth, tipRef?.current])

  return (
    <Box
      style={{ display: isSensor ? 'block' : 'none', maxWidth }}
      className={classNames(
        'SlimNavBreadcrumb__Tag',
        (!isSensor || !device?.tag) && 'SlimNavBreadcrumb__Tag--no-tag'
      )}
    >
      {isSensor && device?.tag && (
        <Text as='div' tone={500} variant='primary' size={100} fontWeight={600}>
          <Tooltip
            tipRef={tipRef}
            className='SlimNavBreadcrumb__Tag__Text'
            tip={device.tag}
            showTooltip={isOverflow}
          >
            {device.tag}
          </Tooltip>
        </Text>
      )}
    </Box>
  )
}

import { useReducer, useEffect } from 'react'

import { Dialog, Select, LineSeparator } from '@/elements'
import { Slot, Text, Flex, Input, Label, Button, Box } from '@/primitives'

import { getReleaseNotesSections } from '@/reducers/selectors'

import Strings from '../../Strings'
const strings = Strings()

const initialState = {
  text: '',
  sectionId: ''
}

function reducer(state, action) {
  if (action.type === 'setInit') {
    return { ...state, ...action.value }
  }

  if (action.type === 'updateField') {
    return { ...state, [action.name]: action.value }
  }

  if (action.type === 'reset') {
    return { ...state, ...initialState }
  }

  if (action.type === 'updateSelect') {
    return { ...state, [action.name]: action.value }
  }
}

const ContentItemForm = ({
  showForm,
  setHideForm,
  upsertItem,
  contentToEdit
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const sections = getReleaseNotesSections()
  const isEditView = Object.keys(contentToEdit)?.length > 0
  const disableSubmission =
    state.sectionId.length === 0 && state.text.length === 0
  const hasValues = state.sectionId.length > 0 || state.text.length > 0

  useEffect(() => {
    if (showForm && isEditView) {
      const { text, sectionId } = contentToEdit
      dispatch({ type: 'setInit', value: { text, sectionId } })
    }
  }, [showForm, isEditView, contentToEdit])

  function onChangeInput({ target }) {
    const { value, name } = target
    dispatch({ type: 'updateField', value, name })
  }

  function onChangeSelect({ value }, { name }) {
    dispatch({ type: 'updateSelect', value, name })
  }

  if (!showForm && hasValues) {
    dispatch({ type: 'reset' })
  }

  function onSubmit() {
    const type = isEditView ? 'editItem' : 'addItem'
    const details = { ...state }
    if (isEditView) details['index'] = contentToEdit?.index
    upsertItem(details, type)
    setHideForm()
  }

  return (
    <Dialog className='ContentItem' open={showForm} onOpenChange={setHideForm}>
      <Slot name='title'>
        <Text size={300} fontWeight={600}>
          {isEditView ? strings.editContentItem : strings.addNewContentItem}
        </Text>
      </Slot>
      <Slot name='content'>
        <Text size={200} as='p' variant='page'>
          {isEditView
            ? strings.editReleaseNoteItem
            : strings.createReleaseNoteItem}
        </Text>
        <Box>
          <LineSeparator />
        </Box>
        <Label>
          <Text>{strings.text}</Text>
          <Input
            as='textarea'
            name='text'
            onChange={onChangeInput}
            value={state.text}
          />
        </Label>
        <Label>
          <Text>{strings.releaseNotesSection}</Text>
          <Select
            name='sectionId'
            value={state.sectionId}
            options={sections?.map(({ name, sectionId }) => ({
              value: sectionId,
              label: name
            }))}
            placeholder={strings.selectSectionPrompt}
            controlStyles={{ marginTop: '0.5rem' }}
            onChange={onChangeSelect}
          />
        </Label>
      </Slot>
      <Slot name='actions'>
        <Flex alignMainAxis='space-between'>
          <Button size='small' variant='error' onClick={setHideForm}>
            {strings.cancel}
          </Button>
          <Button
            size='small'
            variant='primary'
            onClick={onSubmit}
            disabled={disableSubmission}
          >
            {strings.submit}
          </Button>
        </Flex>
      </Slot>
    </Dialog>
  )
}

export default ContentItemForm

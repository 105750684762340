import { call, put, select, takeLatest } from 'redux-saga/effects'

import {
  getAreas,
  getArea,
  createArea,
  updateArea
} from '@/api/operations/area'

import {
  REQUEST_ALL_AREAS,
  REQUEST_AREAS,
  REQUEST_AREA,
  CREATE_AREA,
  UPDATE_AREA,
  receiveAreas,
  receiveAllAreas,
  receiveArea,
  areaCreated,
  areaUpdated
} from '@/actions/operations/area'

import { displayBanner } from '../utils'
import { getOperationsAllAreas } from './utils'

import Strings from './Strings'

const strings = Strings()

function* sendCreateArea(action) {
  const { data, error = null } = yield call(createArea, action.params)
  yield put(displayBanner(error, strings.areaCreated))
  yield put(areaCreated(data, error, true))
}

export function* watchCreateArea() {
  yield takeLatest(CREATE_AREA, sendCreateArea)
}

function* sendUpdateArea(action) {
  const { data, error = null } = yield call(updateArea, action.params)
  yield put(displayBanner(error, strings.areaUpdated))
  yield put(areaUpdated(data, error, true))
}

export function* watchUpdateArea() {
  yield takeLatest(UPDATE_AREA, sendUpdateArea)
}

function* sendRequestAreas(action) {
  const { data, error = null } = yield call(getAreas, action.params)
  const { areas = [], count = 0 } = data
  yield put(receiveAreas(areas, count, error))
}

export function* watchRequestAreas() {
  yield takeLatest(REQUEST_AREAS, sendRequestAreas)
}

function* sendRequestArea(action) {
  const { data: area, error = null } = yield call(getArea, action.params)
  yield put(receiveArea(area, error))
}

export function* watchRequestArea() {
  yield takeLatest(REQUEST_AREA, sendRequestArea)
}

function* sendRequestAllAreas(action) {
  const allAreas = yield select(getOperationsAllAreas)
  if (allAreas.length > 0) {
    yield put(receiveAllAreas(allAreas, allAreas.length, null))
  } else {
    const { data, error = null } = yield call(getAreas, {})
    const { areas = [], count = 0 } = data
    yield put(receiveAllAreas(areas, count, error))
  }
}

export function* watchRequestAllAreas() {
  yield takeLatest(REQUEST_ALL_AREAS, sendRequestAllAreas)
}

import { useReducer, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import DatePicker from 'react-datepicker'

import useDeviceSize from '@/hooks/useDeviceSize'

import { requestAllStorageFacilities } from '@/actions/operations/storageFacility'
import { requestAllShippingMethods } from '@/actions/operations/shippingMethod'
import { requestAllProduces } from '@/actions/operations/produce'
import { requestAllVarieties } from '@/actions/operations/variety'
import { requestAllGrades } from '@/actions/operations/grade'

import {
  clearInventories,
  requestInventories
} from '@/actions/operations/inventory'

import {
  createInventoryTransfer,
  requestInventoryTransfer,
  updateInventoryTransfer
} from '@/actions/operations/inventoryTransfer'

import {
  getOperationsAllStorageFacilities,
  getOperationsAllShippingMethods,
  getOperationsAllProduces,
  getOperationsAllVarieties,
  getOperationsAllGrades,
  getOperationsInventories,
  getOperationsInventoryTransfer,
  getOperationsLoading,
  getOperationsError
} from '@/reducers/selectors'

import { Box, Flex, Label, Select, Text, Input, Loader } from '@/primitives'

import InputError from '@/elements/InputError'
import OrganizationInput from '../../../Shared/Organization/Input'

import { getValidationErrorMap } from '@/Util/GeneralUtils'
import HeaderV2 from '@/components/Operations/Shared/HeaderV2'

import history from '../../../../../history'
import Strings from '../Strings'

import { initialState, reducer, UPDATE_INPUT, SET_STATE } from './state'

import { INVENTORY_TRANSFER_STATUSES } from '../utils'
import { FIELDS, SCHEMA } from './config'

function InventoryTransferForm({ state, modulePath }) {
  const dispatch = useDispatch()
  const isMobile = useDeviceSize('mobile')
  const isLaptop = useDeviceSize()
  const { itemId } = useParams()

  const strings = Strings()

  const storageFacilities = getOperationsAllStorageFacilities()
  const shippingMethods = getOperationsAllShippingMethods()
  const produces = getOperationsAllProduces()
  const varieties = getOperationsAllVarieties()
  const grades = getOperationsAllGrades()
  const inventories = getOperationsInventories()
  const inventoryTransfer = getOperationsInventoryTransfer()

  const loading = getOperationsLoading()
  const error = getOperationsError()

  const [formState, dispatchFormState] = useReducer(reducer, initialState)

  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)

  const inventoryTransferStatuses = INVENTORY_TRANSFER_STATUSES(strings)

  useEffect(() => {
    dispatch(clearInventories())
  }, [dispatch])

  useEffect(() => {
    dispatch(requestAllStorageFacilities())
    dispatch(requestAllShippingMethods())
    dispatch(requestAllProduces())
    dispatch(requestAllVarieties())
    dispatch(requestAllGrades())
  }, [dispatch])

  useEffect(() => {
    if (formState.organizationId && formState.fromStorageFacilityId) {
      dispatch(
        requestInventories({
          filter: {
            organizationId: [formState.organizationId],
            storageFacilityId: [formState.fromStorageFacilityId]
          }
        })
      )
    }
  }, [formState.organizationId, formState.fromStorageFacilityId])

  useEffect(() => {
    if (itemId && inventoryTransfer.id !== itemId) {
      dispatch(requestInventoryTransfer({ inventoryTransferId: itemId }))
    }
  }, [itemId])

  useEffect(() => {
    if (inventoryTransfer?.id && inventoryTransfer.id === itemId) {
      const { inventory, ...transfer } = inventoryTransfer
      dispatchFormState({
        type: SET_STATE,
        state: {
          ...transfer,
          fromStorageFacilityId: inventory.storageFacilityId,
          produceId: inventory.produceId,
          varietyId: inventory.varietyId,
          gradeId: inventory.gradeId
        }
      })
    }
  }, [inventoryTransfer])

  useEffect(() => {
    if (!itemId && state.organizations.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'organizationId',
        value: state.organizations[0]
      })
    }
  }, [state.organizations])

  useEffect(() => {
    if (!itemId && state.fromStorageFacilities.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'fromStorageFacilityId',
        value: state.fromStorageFacilities[0]
      })
    }
  }, [state.storageFacilities])

  useEffect(() => {
    if (!itemId && state.storageFacilities.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'storageFacilityId',
        value: state.storageFacilities[0]
      })
    }
  }, [state.storageFacilities])

  useEffect(() => {
    if (waiting && !loading && !error) {
      dispatch(clearInventories())
      history.replace(modulePath)
    }
  }, [loading])

  useEffect(() => {
    if (formState.produceId && formState.varietyId && formState.gradeId) {
      for (const inventory of inventories) {
        if (
          inventory.produce.id === formState.produceId &&
          inventory.variety.id === formState.varietyId &&
          inventory.grade.id === formState.gradeId
        ) {
          dispatchFormState({
            type: UPDATE_INPUT,
            name: 'inventoryId',
            value: inventory.id
          })
          return true
        }
      }
    }
    dispatchFormState({
      type: UPDATE_INPUT,
      name: 'inventoryId',
      value: null
    })
  }, [formState.produceId, formState.varietyId, formState.gradeId, inventories])

  const onSubmit = async e => {
    try {
      const payload = await SCHEMA().validate(formState, { abortEarly: false })
      setWaiting(true)

      if (itemId) {
        payload.inventoryTransferId = itemId
        dispatch(updateInventoryTransfer(payload))
      } else {
        dispatch(createInventoryTransfer(payload))
      }
    } catch (error) {
      setErrors(getValidationErrorMap(error))
    }
  }

  const handleInput = e => {
    const { name, value } = e.currentTarget
    dispatchFormState({ type: UPDATE_INPUT, name, value })
  }

  const handleNumericInput = e => {
    const { name, value } = e.currentTarget
    const newValue = value.length > 0 ? Number(value) : ''
    dispatchFormState({ type: UPDATE_INPUT, name, value: newValue })
  }

  const handleDateInput = (date, name) => {
    dispatchFormState({ type: UPDATE_INPUT, name, value: date })
  }

  const handleFromStorageFacility = e => {
    dispatchFormState({
      type: SET_STATE,
      state: {
        ...formState,
        fromStorageFacilityId: e.currentTarget.value,
        storageFacilityId: '',
        inventoryId: '',
        shippingMethodId: '',
        produceId: '',
        varietyId: '',
        gradeId: ''
      }
    })
  }

  const handleOrganizationInput = organizationId => {
    dispatchFormState({
      type: SET_STATE,
      state: {
        ...formState,
        organizationId,
        inventoryId: '',
        fromStorageFacilityId: '',
        shippingMethodId: '',
        storageFacilityId: '',
        produceId: '',
        varietyId: '',
        gradeId: ''
      }
    })
  }

  const filterFromStorageFacilities = () => {
    return storageFacilities.filter(
      p => p.organizationId === formState.organizationId
    )
  }

  const filterStorageFacilities = () => {
    return storageFacilities.filter(
      s =>
        s.organizationId === formState.organizationId &&
        s.id !== formState.fromStorageFacilityId
    )
  }

  const filterShippingMethods = () => {
    return shippingMethods.filter(
      p => p.storageFacility.id === formState.fromStorageFacilityId
    )
  }

  const filterProduces = () => {
    return produces.filter(produce => {
      for (const inventory of inventories) {
        if (inventory.produce.id === produce.id) {
          return true
        }
      }
      return false
    })
  }

  const filterVarieties = () => {
    return varieties.filter(variety => {
      for (const inventory of inventories) {
        if (
          inventory.produce.id === formState.produceId &&
          inventory.variety.id === variety.id
        ) {
          return true
        }
      }
      return false
    })
  }

  const filterGrades = () => {
    return grades.filter(grade => {
      for (const inventory of inventories) {
        if (
          inventory.produce.id === formState.produceId &&
          inventory.variety.id === formState.varietyId &&
          inventory.grade.id === grade.id
        ) {
          return true
        }
      }
      return false
    })
  }

  const getHeader = () => {
    if (itemId) {
      return strings.updateFormHeader
    }
    return strings.createFormHeader
  }

  return (
    <Flex
      as='form'
      axisGap={400}
      direction='column'
      className='Operations__Form'
    >
      <HeaderV2
        title={getHeader()}
        backPath={modulePath}
        buttonIcon={'save'}
        buttonText={strings.saveInventoryTransfer}
        buttonCallback={onSubmit}
      />
      <Loader isLoading={loading}>
        <Flex axisGap={800} direction={isLaptop ? 'column' : null}>
          <Flex
            axisGap={400}
            direction='column'
            className='Operations__Form__Fields'
          >
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.transfer}
              </Text>
              <OrganizationInput
                fieldName={FIELDS.organizationId}
                organizationId={formState.organizationId}
                handleInput={handleOrganizationInput}
                errors={errors}
              />
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.fromStorageFacility}
                  </Text>
                  <Select
                    className='Operations__Select'
                    name={FIELDS.fromStorageFacilityId}
                    value={formState.fromStorageFacilityId}
                    onChange={handleFromStorageFacility}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterFromStorageFacilities().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.fromStorageFacilityId} />
                </Label>
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.shippingMethod}
                  </Text>
                  <Select
                    className='Operations__Select'
                    name={FIELDS.shippingMethodId}
                    value={formState.shippingMethodId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterShippingMethods().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.shippingMethodId} />
                </Label>
                <Label style={{ flex: '1 1 0' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.shippedDate}
                    </Text>
                    <DatePicker
                      maxDate={new Date()}
                      selected={formState.shippedDate}
                      className='Operations__Form__Select'
                      onChange={date =>
                        handleDateInput(date, FIELDS.shippedDate)
                      }
                    />
                    <InputError error={errors?.shippedDate} />
                  </Flex>
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '1' }}>
                  <Text variant='page' tone={700}>
                    {strings.toStorageFacility}
                  </Text>
                  <Select
                    className='Operations__Select'
                    name={FIELDS.storageFacilityId}
                    value={formState.storageFacilityId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterStorageFacilities().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.storageFacilityId} />
                </Label>
                <Label style={{ flex: '1' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.status}
                    </Text>
                    <Select
                      className='Operations__Select'
                      name={FIELDS.status}
                      value={formState.status}
                      onChange={handleInput}
                    >
                      <option default value=''>
                        {strings.selectDefault}
                      </option>
                      {Object.entries(inventoryTransferStatuses).map(
                        ([id, name]) => (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        )
                      )}
                    </Select>
                    <InputError error={errors?.status} />
                  </Flex>
                </Label>
                <Label style={{ flex: '1' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.deliveredDate}
                    </Text>
                    <DatePicker
                      maxDate={new Date()}
                      selected={formState.deliveredDate}
                      className='Operations__Form__Select'
                      onChange={date =>
                        handleDateInput(date, FIELDS.deliveredDate)
                      }
                    />
                    <InputError error={errors?.deliveredDate} />
                  </Flex>
                </Label>
              </Flex>
            </Box>
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.produce}
              </Text>
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.produce}
                  </Text>
                  <Select
                    className='Operations__Select'
                    name={FIELDS.produceId}
                    value={formState.produceId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterProduces().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.produceId} />
                </Label>
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.variety}
                  </Text>
                  <Select
                    className='Operations__Select'
                    name={FIELDS.varietyId}
                    value={formState.varietyId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterVarieties().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.varietyId} />
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.grade}
                  </Text>
                  <Select
                    className='Operations__Select'
                    name={FIELDS.gradeId}
                    value={formState.gradeId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterGrades().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.gradeId} />
                </Label>
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.totalWeight}
                  </Text>
                  <Box className='Operations__Input__Container'>
                    <Input
                      className='Operations__Input'
                      type='number'
                      name={FIELDS.totalWeight}
                      value={formState.totalWeight}
                      onChange={handleNumericInput}
                    />
                    <span className='Operations__Input__Suffix'>
                      {strings.kilograms}
                    </span>
                  </Box>
                  <InputError error={errors?.totalWeight} />
                </Label>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Loader>
    </Flex>
  )
}

export default InventoryTransferForm

import { useState, useEffect, useReducer } from 'react'
import { Switch, Route, useParams } from 'react-router-dom'

import { Flex } from '../../../../primitives'

import Table from './Table'
import Form from './Form'
import Databoard from './Databoard'

import reducer, { initialState } from './state'

const MODULE_PATH = '/operations/:zone+/crops'

function Crop() {
  const params = useParams()

  const [state, dispatchState] = useReducer(reducer, initialState)

  const [modulePath, setModulePath] = useState(MODULE_PATH)

  useEffect(() => {
    if (params?.zone) {
      setModulePath(MODULE_PATH.replace(':zone+', params.zone))
    }
  }, [params?.zone])

  return (
    <Flex axisGap={400} direction='column'>
      <Switch>
        <Route
          path={[`${MODULE_PATH}/create`, `${MODULE_PATH}/update/:itemId`]}
          exact={true}
        >
          <Form
            state={state}
            dispatchState={dispatchState}
            modulePath={modulePath}
          />
        </Route>
        <Route path={`${MODULE_PATH}/data`} exact={true}>
          <Databoard />
        </Route>
        <Route path={MODULE_PATH} exact={true}>
          <Table
            state={state}
            dispatchState={dispatchState}
            modulePath={modulePath}
          />
        </Route>
      </Switch>
    </Flex>
  )
}

export default Crop

export const tableInitialState = {
  organizations: [],
  storageFacilities: [],
  packageUnits: [],
  page: 1
}

export const tableReducer = (state, action) => {
  if (action.state) {
    return {
      ...state,
      ...action.state
    }
  } else if (action.page) {
    return {
      ...state,
      page: action.page
    }
  } else if (action.organizations) {
    return {
      ...state,
      organizations: action.organizations,
      storageFacilities: [],
      packageUnits: []
    }
  } else if (action.storageFacilities) {
    return {
      ...state,
      storageFacilities: action.storageFacilities
    }
  } else if (action.packageUnits) {
    return {
      ...state,
      packageUnits: action.packageUnits
    }
  }
  return state
}

import { makeOperationsApiCall } from './utils'

export const getFertilizerInventories = async params => {
  try {
    const query = /* GraphQL */ `
      query getFertilizerInventories(
        $filter: GetFertilizerInventoriesFilter
        $order: OrderEnum
        $offset: Int
        $limit: Int
      ) {
        getFertilizerInventories(
          filter: $filter
          order: $order
          offset: $offset
          limit: $limit
        ) {
          fertilizerInventories {
            id
            organizationId
            storageFacility {
              id
              name
            }
            fertilizerId
            totalWeight
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getFertilizerInventories',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getFertilizerInventory = async params => {
  try {
    const query = /* GraphQL */ `
      query getFertilizerInventory($fertilizerInventoryId: String!) {
        getFertilizerInventory(fertilizerInventoryId: $fertilizerInventoryId) {
          id
          organizationId
          storageFacility {
            id
            name
          }
          fertilizerId
          totalWeight
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getFertilizerInventory',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

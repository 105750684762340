import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { I18n } from 'aws-amplify'

import { FlexV2, Slot, Text, Toggle, Loader } from '@/primitives'
import { Dialog } from '@/elements'

import {
  getCurrentUser,
  getFeatureFlags,
  getFeatureFlagsByObject,
  getFeatureFlagsIsFetching,
  getFeatureFlagsIsSaving
} from '@/reducers/selectors'

import {
  fetchFeatureFlags,
  addFeatureFlag,
  deleteFeatureFlag
} from '@/slices/management/featureFlag'

import { getIsGodMode } from '@/Util/PermissionUtils'

import './index.scss'

export default function FeatureFlags({
  objectId,
  type,
  openSidebar,
  onCloseSidebar
}) {
  const dispatch = useDispatch()

  const currentUser = getCurrentUser()
  const isGodMode = getIsGodMode(currentUser)
  const featureFlags = getFeatureFlags()
  const attachments = getFeatureFlagsByObject()
  const isFetching = getFeatureFlagsIsFetching()
  const isSaving = getFeatureFlagsIsSaving()

  const [availableFeatureFlags, setAvailableFeatureFlags] = useState([])

  useEffect(() => {
    if (isGodMode) {
      dispatch(fetchFeatureFlags({ objectId }))
    }
  }, [isGodMode])

  useEffect(() => {
    if (featureFlags.length) {
      setAvailableFeatureFlags(
        featureFlags.filter(flag => flag.type === type || flag.type === 'all')
      )
    }
  }, [featureFlags.length])

  function onToggleFeatureFlag(event) {
    const { name, checked } = event.target
    if (checked) {
      dispatch(addFeatureFlag({ objectId, featureFlagId: name }))
    } else {
      dispatch(deleteFeatureFlag({ objectId, featureFlagId: name }))
    }
  }

  return (
    <Dialog
      open={openSidebar}
      onOpenChange={onCloseSidebar}
      type='offcanvas'
      className='FeatureFlags__OffCanvas'
      style={{ zIndex: 3 }}
    >
      <Slot name='title'>
        <Text size={300} fontWeight={700}>
          {I18n.get('Feature Flags')}
        </Text>
      </Slot>
      <Slot name='content'>
        <FlexV2 direction='column' axisGap={100}>
          <Loader isLoading={isFetching}>
            {availableFeatureFlags?.map(({ featureFlagId, name }) => {
              return (
                <FlexV2
                  direction='row'
                  alignMainAxis='space-between'
                  axisGap={200}
                  key={featureFlagId}
                >
                  <Text
                    variant='page'
                    tone={900}
                    size={200}
                    style={{ marginBottom: '0.2em' }}
                  >
                    {name}
                  </Text>
                  <Toggle
                    name={featureFlagId}
                    onChange={onToggleFeatureFlag}
                    checked={attachments?.some(
                      ({ featureFlagId: id }) => id === featureFlagId
                    )}
                    disabled={isSaving}
                  />
                </FlexV2>
              )
            })}
          </Loader>
        </FlexV2>
      </Slot>
    </Dialog>
  )
}

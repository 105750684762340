import { useMemo } from 'react'
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter'
import prettier from 'prettier/standalone'
import parserBABEL from 'prettier/parser-babel'
import parserHTML from 'prettier/parser-html'
import './index.scss'

const prettierFormatting = {
  trailingComma: 'none',
  tabWidth: 2,
  semi: false,
  singleQuote: true,
  jsxSingleQuote: true,
  bracketSpacing: true,
  bracketSameLine: false,
  arrowParens: 'avoid',
  printWidth: 80
}

const parserLookup = {
  html: 'html',
  json: 'json',
  js: 'babel',
  jsx: 'babel'
}

const prettify = (_data, lang) => {
  try {
    return prettier
      .format(_data, {
        parser: parserLookup[lang],
        plugins: [parserBABEL, parserHTML],
        ...prettierFormatting
      })
      .trim()
  } catch (error) {
    throw new Error(error)
  }
}

/**
 * Code Primitive
 * @param {Object} props
 * @param { 'jsx' | 'js' | 'html' | 'json' } [props.language='html']
 * @param {Boolean} [props.showLineNumbers=false]
 */

const Code = ({
  children = null,
  language = 'html',
  showLineNumbers = false
}) => {
  const data = useMemo(() => prettify(children, language), [children, language])

  return (
    <SyntaxHighlighter
      language={language}
      className={['codeblock', showLineNumbers ? 'addLines' : ''].join(' ')}
      children={data}
      useInlineStyles={false}
      wrapLines={true}
      wrapLongLines={true}
      data-type={language}
    />
  )
}

export default Code

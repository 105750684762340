import useDatasetData from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Hooks/useDatasetData'
import Line from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/Chart/Line'
import { handleMeasurementConversion } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/utils'
import {
  wetBulbTemperatureMeasurements,
  convertToWetBulbTemperature
} from './utils'

import { getCurrentUser, getRootZone } from '@/reducers/selectors'

export default function DatasetLine({ unit, config, ...props }) {
  const { datasetData, queryZones } = useDatasetData({
    measurements: wetBulbTemperatureMeasurements,
    range: config.range,
    interval: config.interval
  })

  const currentUser = getCurrentUser()
  const rootZone = getRootZone()

  let chartData = {}

  if (datasetData) {
    for (let { zoneName } of queryZones) {
      const temperatureData = datasetData?.['temperature']?.[zoneName] || []
      const humidityData = datasetData?.['humidity']?.[zoneName] || []

      chartData[zoneName] = temperatureData.reduce(
        (result, temperature, index) => {
          const humidityDataPoint = humidityData[index]
          if (humidityDataPoint && temperature.x === humidityDataPoint.x) {
            result.push({
              x: temperature.x,
              y: convertToWetBulbTemperature({
                temperature: temperature.y,
                humidity: humidityDataPoint.y
              })
            })
          }

          return result
        },
        []
      )
    }
  }

  const conversion = handleMeasurementConversion({
    data: chartData,
    measurement: props?.options?.measurement,
    unit,
    queryType: config.query,
    settings: currentUser?.settings,
    userId: currentUser?.userName,
    organizationId: rootZone?.organizationId
  })

  if (conversion) {
    chartData = conversion.data
    unit = conversion.unit
  }

  return (
    <Line
      {...props}
      data={chartData}
      unit={unit}
      config={config}
      queryZones={queryZones}
    />
  )
}

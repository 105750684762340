import { makeOperationsApiCall } from './utils'

export const getCustomers = async params => {
  try {
    const query = /* GraphQL */ `
      query getCustomers(
        $filter: GetCustomersFilter
        $order: OrderEnum
        $offset: Int
        $limit: Int
      ) {
        getCustomers(
          filter: $filter
          order: $order
          offset: $offset
          limit: $limit
        ) {
          customers {
            id
            organizationId
            name
            code
            category
            status
            onboardDate
            country {
              name
            }
            city {
              name
            }
            region {
              name
            }
            contactName
            contactNumber
            contactEmail
            currencyId
            paymentMethodId
            paymentTermId
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getCustomers',
      resultKey: 'data'
    })
    return { data: data ?? [], error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getCustomer = async params => {
  try {
    const query = /* GraphQL */ `
      query getCustomer($customerId: String!) {
        getCustomer(customerId: $customerId) {
          id
          organizationId
          name
          code
          category
          status
          onboardDate
          countryId
          regionId
          cityId
          contactName
          contactNumber
          contactEmail
          currencyId
          paymentMethodId
          paymentTermId
          registrationNumber
          registrationS3Key
          taxNumber
          taxS3Key
          nationalAddress {
            streetAddress
            streetAddress2
            city
            region
            country
            zipCode
          }
          nationalAddressS3Key
          bankDetails {
            bankName
            branchName
            branchCode
            branchAddress {
              streetAddress
              streetAddress2
              city
              region
              country
              zipCode
            }
            swiftBic
            iban
          }
          bankDetailsS3Key
          contractS3Key
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getCustomer',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const createCustomer = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createCustomer(
        $organizationId: String!
        $name: String!
        $code: String!
        $category: CustomerCategory!
        $status: CustomerStatus!
        $onboardDate: GraphQLTimestamp!
        $countryId: String!
        $cityId: String!
        $regionId: String!
        $contactName: String!
        $contactNumber: String!
        $contactEmail: String!
        $currencyId: String!
        $paymentMethodId: String!
        $paymentTermId: String!
        $registrationNumber: String
        $registrationS3Key: String
        $taxNumber: String
        $taxS3Key: String
        $nationalAddress: AddressInput
        $nationalAddressS3Key: String
        $bankDetails: BankDetailsInput
        $bankDetailsS3Key: String
        $contractS3Key: String
      ) {
        createCustomer(
          organizationId: $organizationId
          name: $name
          code: $code
          category: $category
          status: $status
          onboardDate: $onboardDate
          cityId: $cityId
          regionId: $regionId
          contactName: $contactName
          contactNumber: $contactNumber
          contactEmail: $contactEmail
          countryId: $countryId
          currencyId: $currencyId
          paymentMethodId: $paymentMethodId
          paymentTermId: $paymentTermId
          registrationNumber: $registrationNumber
          registrationS3Key: $registrationS3Key
          taxNumber: $taxNumber
          taxS3Key: $taxS3Key
          nationalAddress: $nationalAddress
          nationalAddressS3Key: $nationalAddressS3Key
          bankDetails: $bankDetails
          bankDetailsS3Key: $bankDetailsS3Key
          contractS3Key: $contractS3Key
        ) {
          id
          name
          code
          category
          status
          onboardDate
          country {
            name
          }
          region {
            name
          }
          city {
            name
          }
          contactName
          contactNumber
          contactEmail
          currencyId
          paymentMethodId
          paymentTermId
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'createCustomer',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updateCustomer = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateCustomer(
        $organizationId: String!
        $customerId: String!
        $name: String!
        $code: String!
        $category: CustomerCategory!
        $status: CustomerStatus!
        $onboardDate: GraphQLTimestamp!
        $countryId: String!
        $regionId: String!
        $cityId: String!
        $contactName: String!
        $contactNumber: String!
        $contactEmail: String!
        $currencyId: String!
        $paymentMethodId: String!
        $paymentTermId: String!
        $registrationNumber: String
        $registrationS3Key: String
        $taxNumber: String
        $taxS3Key: String
        $nationalAddress: AddressInput
        $nationalAddressS3Key: String
        $bankDetails: BankDetailsInput
        $bankDetailsS3Key: String
        $contractS3Key: String
      ) {
        updateCustomer(
          organizationId: $organizationId
          customerId: $customerId
          name: $name
          code: $code
          category: $category
          status: $status
          onboardDate: $onboardDate
          countryId: $countryId
          regionId: $regionId
          cityId: $cityId
          contactName: $contactName
          contactNumber: $contactNumber
          contactEmail: $contactEmail
          currencyId: $currencyId
          paymentMethodId: $paymentMethodId
          paymentTermId: $paymentTermId
          registrationNumber: $registrationNumber
          registrationS3Key: $registrationS3Key
          taxNumber: $taxNumber
          taxS3Key: $taxS3Key
          nationalAddress: $nationalAddress
          nationalAddressS3Key: $nationalAddressS3Key
          bankDetails: $bankDetails
          bankDetailsS3Key: $bankDetailsS3Key
          contractS3Key: $contractS3Key
        ) {
          id
          name
          code
          category
          status
          onboardDate
          country {
            name
          }
          region {
            name
          }
          city {
            name
          }
          contactName
          contactNumber
          contactEmail
          currencyId
          paymentMethodId
          paymentTermId
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'updateCustomer',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

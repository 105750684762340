import { makeActionCreator } from '@/Util/ReduxUtils'

export const REQUEST_ALL_COUNTRIES = 'REQUEST_ALL_COUNTRIES'
export const requestAllCountries = makeActionCreator(REQUEST_ALL_COUNTRIES)

export const RECEIVE_ALL_COUNTRIES = 'RECEIVE_ALL_COUNTRIES'
export const receiveAllCountries = makeActionCreator(
  RECEIVE_ALL_COUNTRIES,
  'countries',
  'count',
  'error'
)

export const REQUEST_COUNTRIES = 'REQUEST_COUNTRIES'
export const requestCountries = makeActionCreator(REQUEST_COUNTRIES, 'params')

export const RECEIVE_COUNTRIES = 'RECEIVE_COUNTRIES'
export const receiveCountries = makeActionCreator(
  RECEIVE_COUNTRIES,
  'countries',
  'count',
  'error'
)

export const CREATE_COUNTRY = 'CREATE_COUNTRY'
export const createCountry = makeActionCreator(CREATE_COUNTRY, 'params')

export const COUNTRY_CREATED = 'COUNTRY_CREATED'
export const countryCreated = makeActionCreator(
  COUNTRY_CREATED,
  'country',
  'error'
)

export const UPDATE_COUNTRY = 'UPDATE_COUNTRY'
export const updateCountry = makeActionCreator(UPDATE_COUNTRY, 'params')

export const COUNTRY_UPDATED = 'COUNTRY_UPDATED'
export const countryUpdated = makeActionCreator(
  COUNTRY_UPDATED,
  'country',
  'error'
)

export const REQUEST_COUNTRY = 'REQUEST_COUNTRY'
export const requestCountry = makeActionCreator(REQUEST_COUNTRY, 'params')

export const RECEIVE_COUNTRY = 'RECEIVE_COUNTRY'
export const receiveCountry = makeActionCreator(
  RECEIVE_COUNTRY,
  'country',
  'error'
)

export const CLEAR_COUNTRY = 'CLEAR_COUNTRY'
export const clearCountry = makeActionCreator(CLEAR_COUNTRY)

export const CLEAR_COUNTRIES = 'CLEAR_COUNTRIES'
export const clearCountries = makeActionCreator(CLEAR_COUNTRIES)

export const SET_COUNTRY = 'SET_COUNTRY'
export const setCountry = makeActionCreator(SET_COUNTRY, 'country')

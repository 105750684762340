import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { requestWastes } from '@/actions/operations/waste'

import {
  getCurrentUser,
  getOperationsWastes,
  getOperationsWastesCount,
  getOperationsLoading
} from '@/reducers/selectors'

import { Flex, Box, Table, Slot, Column, Row, Text, Loader } from '@/primitives'

import { Pagination } from '@/elements'

import HeaderV2 from '@/components/Operations/Shared/HeaderV2'
import TableFilter from './TableFilter'
import TableRow from './TableRow'

import history from '../../../../../history'

import {
  hasEditPermissions,
  RESOURCE_TYPE_OPERATIONS,
  RESOURCE_TYPE_OPERATIONS_WASTE
} from '@/Util/PermissionUtils'

import Strings from '../Strings'

function WasteTable({ state, dispatchState, modulePath }) {
  const dispatch = useDispatch()

  const currentUser = getCurrentUser()
  const items = getOperationsWastes()
  const totalItems = getOperationsWastesCount()
  const loading = getOperationsLoading()

  const strings = Strings()

  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(9)
  const [tableState, setTableState] = useState(state)
  const [canEdit, setCanEdit] = useState(false)

  useEffect(() => {
    if (currentUser.userName) {
      setCanEdit(
        hasEditPermissions(
          currentUser,
          RESOURCE_TYPE_OPERATIONS,
          RESOURCE_TYPE_OPERATIONS_WASTE
        )
      )
    }
  }, [currentUser])

  useEffect(() => {
    if (tableState.page !== state.page || items.length === 0) {
      setTableState({ ...state })
      fetchWastes(state.page)
    }
  }, [state.page])

  useEffect(() => {
    if (
      tableState.organizations !== state.organizations ||
      tableState.storageFacilities !== state.storageFacilities
    ) {
      setTableState({ ...state })
      fetchWastes(1)
      changePage(1)
    }
  }, [state.organizations, state.storageFacilities])

  const fetchWastes = page => {
    if (state.organizations.length > 0 && state.storageFacilities.length > 0) {
      dispatch(
        requestWastes({
          filter: {
            organizationId: state.organizations,
            storageFacilityId: state.storageFacilities
          },
          limit: pageSize,
          offset: page - 1
        })
      )
    }
  }

  const changePage = newPage => {
    dispatchState({
      page: newPage
    })
  }

  const onCreate = () => {
    history.push(`${modulePath}/create`)
  }

  const onEdit = id => {
    history.push(`${modulePath}/update/${id}`)
  }

  return (
    <Flex axisGap={400} direction='column'>
      <HeaderV2
        title={strings.manageWaste}
        buttonIcon={'add'}
        buttonText={strings.createWaste}
        buttonCallback={onCreate}
        showButton={canEdit}
      >
        <TableFilter state={state} dispatchState={dispatchState} />
      </HeaderV2>
      <Loader isLoading={loading}>
        {totalItems === 0 && (
          <Flex className={'Operations__Table__Empty'}>
            <Text>{strings.noWaste}</Text>
          </Flex>
        )}
        {totalItems > 0 && (
          <Table className='Operations__Table' aria-colcount='5'>
            <Slot name='head'>
              <Row>
                <Column>{strings.wasteDate}</Column>
                <Column>{strings.storageFacility}</Column>
                <Column>{strings.produce}</Column>
                <Column>{strings.weight}</Column>
                <Column />
              </Row>
            </Slot>
            <Slot name='body'>
              {items?.map(item => (
                <TableRow
                  key={item.id}
                  onEdit={onEdit}
                  canEdit={canEdit}
                  item={item}
                />
              ))}
            </Slot>
          </Table>
        )}
        {totalItems > pageSize && (
          <Box className='pagination-menu'>
            <Pagination
              type='simple'
              totalItems={totalItems}
              totalPages={totalPages}
              setTotalPages={setTotalPages}
              page={tableState.page}
              setPage={changePage}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          </Box>
        )}
      </Loader>
    </Flex>
  )
}

export default WasteTable

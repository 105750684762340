import { I18n } from 'aws-amplify'
import { Fragment } from 'react'

import ActionBar from './ActionBar'
import DeviceSensorsTable from './DeviceSensorsTable'

import { Flex, Text, FlexV2 } from '@/primitives'

import Strings from '../../../Strings'

import { useFleetManagement } from '../context'

import { getBulkActionHeading } from '../utils'

const DevicesList = () => {
  const {
    fleetManagementActionSubheading,
    fleetManagementActionDevicesOnly,
    fleetManagementActionSensorsOnly
  } = Strings()

  const {
    state: { tabId, bulkAction, sensorsByZone }
  } = useFleetManagement()

  const zonesCount = sensorsByZone.length

  function getSubheading(bulkActionValue) {
    if (bulkActionValue === 'ota_core') return fleetManagementActionDevicesOnly
    if (bulkActionValue === 'ota_sensor')
      return fleetManagementActionSensorsOnly
    return fleetManagementActionSubheading
  }

  return (
    <Fragment>
      <FlexV2 direction='column' axisGap={500}>
        <FlexV2 direction='column' axisGap={100}>
          <Text size={300} fontWeight={700}>
            {getBulkActionHeading(bulkAction)}
          </Text>
          <Text fontFamily='mono' variant='page' tone={900} size={200}>
            {getSubheading(bulkAction.value)}
          </Text>
        </FlexV2>
      </FlexV2>
      <ActionBar />
      <FlexV2 direction='column' axisGap={400}>
        {sensorsByZone.map((zone, index) => (
          <DeviceSensorsTable
            key={`${zone?.id}-${zone.type}`}
            zoneIndex={index}
          />
        ))}
      </FlexV2>
      {zonesCount === 0 && (
        <Flex
          alignCrossAxis='center'
          alignMainAxis='center'
          style={{ flex: 1 }}
        >
          <Flex
            direction={'column'}
            alignCrossAxis={'center'}
            style={{ flex: 1 }}
            axisGap={200}
          >
            <Text
              fontFamily={'mono'}
              variant={'primary'}
              tone={500}
              size={600}
              fontWeight={700}
            >
              {`${tabId === 'greengrass'
                  ? I18n.get('No Greengrass Devices')
                  : I18n.get('No Direct Connect Sensors')
                }`}
            </Text>
          </Flex>
        </Flex>
      )}
    </Fragment>
  )
}

export default DevicesList

import { I18n } from 'aws-amplify'
import { nanoid } from 'nanoid'
import { number, object } from 'yup'

export const inventoryWeightSchema = object().shape({
  unitWeight: number()
    .typeError('Weight is required.')
    .min(0.1, I18n.get('Weight must be at least 0.1kg.'))
    .required(I18n.get('Weight is required.')),
  quantity: number()
    .typeError('Quantity is required.')
    .min(1, I18n.get('Quantity must be at least 1.'))
    .required(I18n.get('Quantity is required.'))
})

const getTotalInventoryWeight = weights => {
  return weights.reduce(
    (acc, obj) => acc + parseInt(obj.unitWeight) * parseInt(obj.quantity),
    0
  )
}

/**
 * Adds or updates a Inventory Weight object.
 * If the action param contains an `id`, it will only update that weight (if found). If it does not have any id or
 * no Weight with that id is found, a new weight will be added
 *
 * @param {Object} state
 * @param {object} action
 * @returns
 */
export function updateInventoryWeight(state, action) {
  let weights = [...state.weights]
  if (action?.weight?.id) {
    const index = weights.findIndex(({ id }) => id === action.weight.id)
    if (index > -1) {
      weights[index] = action.weight
    }
  } else {
    weights.push({
      id: nanoid(),
      unitWeight: 0,
      quantity: 0
    })
  }
  return {
    ...state,
    weights,
    totalWeight: getTotalInventoryWeight(weights)
  }
}

export function removeInventoryWeight(state, action) {
  let weights = [...state.weights]
  if (action.weight.id) {
    const index = weights.findIndex(({ id }) => id === action.weight.id)
    if (index > -1) {
      weights.splice(index, 1)
    }
  }
  return {
    ...state,
    weights,
    totalWeight: getTotalInventoryWeight(weights)
  }
}

export const resizeImage = async imageUrl => {
  const maxWidth = 1024
  const maxHeight = 768
  const jpgQuality = 0.8

  const img = new Image()
  await new Promise((resolve, reject) => {
    img.onload = () => {
      resolve()
    }
    img.src = imageUrl
  })

  const sw = img.width
  const sh = img.height

  let w = maxWidth
  let h = (w / sw) * sh
  if (h > maxHeight) {
    h = maxHeight
    w = (h / sh) * sw
  }

  if (w > sw || h > sh) {
    w = sw
    h = sh
  }

  const elem = document.createElement('canvas')
  elem.width = w
  elem.height = h
  const ctx = elem.getContext('2d')
  ctx.drawImage(img, 0, 0, w, h)
  const newImageUrl = elem.toDataURL('image/jpeg', jpgQuality)
  return newImageUrl
}

import React from 'react'

import useDeviceSize from '../../../../hooks/useDeviceSize'

import { Text, Table, Slot, Row, Cell } from '../../../../primitives'

import WeightForm from './Form'
import Strings from './Strings'

function WeightFormTable({
  organizationId,
  weights,
  errors,
  updateWeight,
  removeWeight
}) {
  const isMobile = useDeviceSize('mobile')
  const strings = Strings()

  if (!weights?.length) {
    return (
      <Text className={'Operations__Form__Table__Empty'}>
        {strings.noWeights}
      </Text>
    )
  }

  return (
    <Table className='Operations__Form__Table' aria-colcount='3'>
      <Slot name='body'>
        {!isMobile && (
          <Row>
            <Cell>
              <Text variant='page' tone={700}>
                {strings.weight}
              </Text>
            </Cell>
            <Cell>
              <Text variant='page' tone={700}>
                {strings.grade}
              </Text>
            </Cell>
          </Row>
        )}
        {weights.map((weight, index) => (
          <WeightForm
            key={weight.id}
            organizationId={organizationId}
            weight={weight}
            weights={weights}
            updateWeight={updateWeight}
            removeWeight={removeWeight}
            errors={errors}
            index={index}
          />
        ))}
      </Slot>
    </Table>
  )
}

export default WeightFormTable

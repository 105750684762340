import FlexV2 from '@/primitives/FlexV2'
import FormInput from '../FormInput'
import { I18n } from 'aws-amplify'
import { UPDATE_MODBUS_CONFIG_FIELD } from '../state'
import MeasurementRegisterForm from './MeasurementRegisterForm'
import { sanitizePositiveIntegerInput } from '../utils'

export default function ConfigForm({ state, stateDispatch, errors }) {
  function onInputChange(e) {
    const { name, value } = e.target
    stateDispatch({
      type: UPDATE_MODBUS_CONFIG_FIELD,
      payload: {
        field: name,
        value
      }
    })
  }

  function onInputIntChange(e) {
    const { name, value } = e.target

    const sanitizedValue = sanitizePositiveIntegerInput(value)

    if (sanitizedValue !== null) {
      stateDispatch({
        type: UPDATE_MODBUS_CONFIG_FIELD,
        payload: {
          field: name,
          value: sanitizedValue
        }
      })
    }
  }

  return (
    <FlexV2 direction='column' axisGap={300}>
      <FormInput
        label={I18n.get('Port Address')}
        name='port_address'
        value={String(state.device.config.port_address)}
        onChange={onInputChange}
        error={errors.port_address}
      />
      <FormInput
        label={I18n.get('Slave Address')}
        name='slave_address'
        value={String(state.device.config.slave_address)}
        onChange={onInputIntChange}
        error={errors.slave_address}
      />
      <FormInput
        label={I18n.get('Baud Rate')}
        name='baudrate'
        value={String(state.device.config.baudrate)}
        onChange={onInputIntChange}
        error={errors.baudrate}
      />
      <FormInput
        label={I18n.get('Byte Size')}
        name='bytesize'
        value={String(state.device.config.bytesize)}
        onChange={onInputIntChange}
        error={errors.bytesize}
      />
      <FormInput
        label={I18n.get('Stop Bits')}
        name='stopbits'
        value={String(state.device.config.stopbits)}
        onChange={onInputIntChange}
        error={errors.stopbits}
      />
      <FormInput
        label={I18n.get('Timeout')}
        name='timeout'
        value={String(state.device.config.timeout)}
        onChange={onInputIntChange}
        error={errors.timeout}
      />
      <FormInput
        label={I18n.get('Function Code')}
        name='function_code'
        value={String(state.device.config.function_code)}
        onChange={onInputIntChange}
        error={errors.function_code}
      />
      <MeasurementRegisterForm state={state} stateDispatch={stateDispatch} />
    </FlexV2>
  )
}

import { makeOperationsApiCall } from './utils'

/* MUTATIONS */

export const createArea = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createArea(
        $organizationId: String!
        $countryId: String!
        $regionId: String!
        $cityId: String!
        $name: String!
      ) {
        createArea(
          organizationId: $organizationId
          countryId: $countryId
          regionId: $regionId
          cityId: $cityId
          name: $name
        ) {
          id
          organizationId
          name
          countryId
          country {
            name
          }
          regionId
          region {
            name
          }
          cityId
          city {
            name
          }
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'createArea',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updateArea = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateArea(
        $areaId: String!
        $organizationId: String!
        $countryId: String!
        $regionId: String!
        $cityId: String!
        $name: String!
      ) {
        updateArea(
          areaId: $areaId
          organizationId: $organizationId
          regionId: $regionId
          countryId: $countryId
          cityId: $cityId
          name: $name
        ) {
          id
          organizationId
          name
          countryId
          country {
            name
          }
          regionId
          region {
            name
          }
          cityId
          city {
            name
          }
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'updateArea',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

/* QUERIES */

export const getAreas = async params => {
  try {
    const query = /* GraphQL */ `
      query getAreas(
        $limit: Int
        $offset: Int
        $order: OrderEnum
        $filter: GetAreasFilter
      ) {
        getAreas(
          limit: $limit
          offset: $offset
          order: $order
          filter: $filter
        ) {
          areas {
            id
            organizationId
            name
            countryId
            country {
              name
            }
            regionId
            region {
              name
            }
            cityId
            city {
              name
            }
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getAreas',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getArea = async params => {
  try {
    const query = /* GraphQL */ `
      query getArea($areaId: String!) {
        getArea(areaId: $areaId) {
          id
          organizationId
          name
          countryId
          regionId
          cityId
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getArea',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

import { Fragment, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { I18n } from 'aws-amplify'
import Loader from '@/primitives/Loader'
import { requestProduces } from '@/actions/operations/produce'
import {
  getOperationsLoading,
  getOperationsProduces
} from '@/reducers/selectors'
import withEditableWidget from '@/components/DashboardPageV2/Desktop/Widgets/Shared/HOC/withEditableWidget'
import { useDashboard } from '@/components/DashboardPageV2/Desktop/context'
import { OperationEdit } from '../OperationEdit'
import {
  LINE_CHART,
  PIE_CHART
} from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'
import DatasetPie from '../Dataset/DatasetPie'
import DatasetLine from '../Dataset/DatasetLine'
import { harvestChartQueryOptionsList } from '../utils'

function TopTenHarvestVarietyEdit(props) {
  const dispatch = useDispatch()

  const produces = getOperationsProduces()

  const operationsLoading = getOperationsLoading()

  const {
    state: {
      hierarchy: { organizationId }
    }
  } = useDashboard()

  useEffect(() => {
    if (organizationId) {
      dispatch(
        requestProduces({
          filter: {
            organizationId
          }
        })
      )
    }
  }, [organizationId, dispatch])

  const options = {
    queryOptionsList: harvestChartQueryOptionsList,
    produces
  }

  return (
    <Fragment>
      <Loader isLoading={operationsLoading} text={I18n.get('Loading produces')}>
        <OperationEdit {...props} options={options} />
      </Loader>
    </Fragment>
  )
}

function TopTenHarvestVarietyView(props) {
  const { config, options } = props

  switch (config.chart) {
    case LINE_CHART:
      return <DatasetLine config={config} options={options} {...props} />
    case PIE_CHART:
    default:
      return <DatasetPie config={config} options={options} />
  }
}

export const TopTenHarvestVarietyWidget = withEditableWidget(
  TopTenHarvestVarietyView,
  TopTenHarvestVarietyEdit
)

import { useDispatch } from 'react-redux'

import { Button, Flex } from '@/primitives'
import { LineSeparator } from '@/elements'

import Strings from '../../../Strings'

import { fetchDeviceFirmware } from '@/slices/management/device'
import { getDeviceTypes } from '@/reducers/selectors'

import { getLatestFirmwareVersion } from '../utils'

const DownloadForm = ({ device }) => {
  const dispatch = useDispatch()
  const deviceTypes = getDeviceTypes()
  const deviceTypesId = deviceTypes.map(({ id }) => id)

  const {
    deviceFirmwareRelease,
    deviceFirmwareReleaseDebug,
    deviceFirmwareDebug
  } = Strings()

  const onDownloadFirmware = buildType => {
    const { id, sensorType, hardwareVersion } = device
    const { firmwareVersion, flashScriptVersion } = getLatestFirmwareVersion(
      hardwareVersion,
      deviceTypes,
      sensorType
    )

    dispatch(
      fetchDeviceFirmware({
        id,
        sensorType,
        buildType,
        version: firmwareVersion,
        flashScriptVersion
      })
    )
  }

  const isGreengrass = device?.isGreengrass

  return (
    <Flex>
      <LineSeparator />
      {deviceTypesId.includes(device?.sensorType) && (
        <Flex direction='column' axisGap={400} style={{ flex: 1 }}>
          <Flex direction='column' axisGap={400}>
            <Button
              onClick={() =>
                onDownloadFirmware(
                  'releasedebug' + (isGreengrass ? '-greengrass' : '')
                )
              }
            >
              {deviceFirmwareReleaseDebug}
            </Button>
            <Button
              onClick={() =>
                onDownloadFirmware(
                  'release' + (isGreengrass ? '-greengrass' : '')
                )
              }
            >
              {deviceFirmwareRelease}
            </Button>
            <Button
              onClick={() =>
                onDownloadFirmware(
                  'debug' + (isGreengrass ? '-greengrass' : '')
                )
              }
            >
              {deviceFirmwareDebug}
            </Button>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

export default DownloadForm

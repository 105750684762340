import { I18n } from 'aws-amplify'
import { getIntervals, USE_RANGE_INTERVAL, getRanges } from '../config'

const DEFAULT_LABEL = I18n.get('Select a value')

export function prepareOptions(
  hashedOptions = [],
  defaultLabel = DEFAULT_LABEL
) {
  let options = Object.values(hashedOptions).map(option => {
    return {
      value: option.value,
      label: option.label()
    }
  })
  options.unshift({
    value: '',
    label: defaultLabel,
    isDisabled: true
  })

  return options
}
export function hashOptions(json) {
  const hashEntity = entity => {
    const options =
      entity.type === 'query' && entity.options
        ? entity.options.reduce((acc, option) => {
            acc[option.value] = hashEntity(option)
            return acc
          }, {})
        : null

    const obj = { ...entity }

    if (options) {
      obj.options = options
    }

    return obj
  }

  return json.reduce((acc, item) => {
    acc[item.value] = hashEntity(item)
    return acc
  }, {})
}

export function createRangeOptions(ranges = [], defaultLabel = DEFAULT_LABEL) {
  let options = ranges.map(range => {
    const rangeValues = getRanges(range) ?? {}
    return {
      value: rangeValues.value,
      label: rangeValues.label
    }
  })
  options.unshift({
    value: '',
    label: defaultLabel,
    isDisabled: true
  })
  return options
}

export function createIntervalOptions(
  intervals = [],
  range,
  defaultLabel = DEFAULT_LABEL
) {
  let options = []
  const useRangeInterval =
    intervals.length === 1 && intervals[0] === USE_RANGE_INTERVAL

  if (useRangeInterval) {
    for (let interval of getRanges(range)?.intervals) {
      options.push({
        value: getIntervals(interval)?.value,
        label: getIntervals(interval)?.label
      })
    }
  } else {
    options = intervals.map(interval => {
      return {
        value: getIntervals(interval)?.value,
        label: getIntervals(interval)?.label
      }
    })
  }
  options.unshift({
    value: '',
    label: defaultLabel,
    isDisabled: true
  })
  return options
}

export function getHasRange(hashedOptions, formState) {
  return (
    hashedOptions[formState.query]?.options[formState.chart]?.range !== null
  )
}

export function getHasInterval(hashedOptions, formState) {
  return !!hashedOptions[formState.query]?.options[formState.chart]?.interval
}

export function normalizeWidgetConfig(widgetConfig) {
  const { query, chart, range, interval } = widgetConfig
  return {
    query: query || null,
    chart: chart || null,
    range: range || null,
    interval: interval || null
  }
}

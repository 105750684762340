import { DateTime } from 'luxon'

import { Cell, Row } from '../../../../../primitives'

import Strings from '../Strings'

function TableRow({ item }) {
  const strings = Strings()

  const { oldWeight, newWeight, reason, createdAt } = item

  const formatDate = date => {
    if (date) {
      return DateTime.fromMillis(date).toFormat('DDD')
    }
    return null
  }

  const weightChange = newWeight - oldWeight

  return (
    <Row>
      <Cell columnName={strings.date}>{formatDate(createdAt)}</Cell>
      <Cell columnName={strings.oldWeight}>
        {oldWeight}
        {strings.kilograms}
      </Cell>
      <Cell columnName={strings.newWeight}>
        {newWeight}
        {strings.kilograms}
        <span>
          {weightChange > 0 ? '+' : '-'}
          {Math.abs(weightChange)}
          {strings.kilograms}
        </span>
      </Cell>
      <Cell columnName={strings.reason}>{reason}</Cell>
    </Row>
  )
}

export default TableRow

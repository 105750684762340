import cloneDeep from 'lodash/cloneDeep'
import { Fragment } from 'react'
import { DateTime } from 'luxon'
import { I18n } from 'aws-amplify'

import { Box, Flex, Text } from '@/primitives'
import MatrixGraph from '@/elements/Nivo/Heatmap'

import { getMeasurements } from '@/reducers/selectors'

import {
  getIntervals,
  getRanges
} from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'

export default function Matrix({ config, options, data, unit }) {
  const measurements = getMeasurements()

  const getThresholds = () => {
    const index = measurements.findIndex(m => m.id === options.measurement)
    if (measurements?.[index]?.thresholds) {
      return measurements[index].thresholds
    }
    return [0, 100]
  }

  const clonedData = cloneDeep(data)
  const graphData = []

  for (let zone in clonedData) {
    const zoneData = {
      id: zone,
      data: []
    }
    clonedData[zone].sort((a, b) => {
      return DateTime.fromISO(a.x).toMillis() - DateTime.fromISO(b.x).toMillis()
    })

    for (let dataPoint of clonedData[zone]) {
      const xDataPoint = DateTime.fromISO(dataPoint.x, {
        setZone: true
      }).toFormat(getIntervals(config.interval)?.dateFormat)
      zoneData.data.push({
        x: xDataPoint,
        y: dataPoint.y
      })
    }

    graphData.push(zoneData)
  }

  const hasData = graphData.some(({ data }) => data.length > 0)

  return (
    <Fragment>
      <Flex
        className={'MosaicV2__Tile__Content__SubTitle'}
        alignMainAxis='space-between'
        axisGap={400}
      >
        <Text fontFamily={'mono'} size={200}>
          {getRanges(config.range)?.label}
        </Text>
        <Text fontFamily={'mono'} size={200}>
          {unit || ''}
        </Text>
      </Flex>

      <Box className={'MosaicV2__Tile__Content__Graph'}>
        <Box className={'MosaicV2__Tile__Content__Chart'}>
          {graphData.length > 0 && hasData && (
            <MatrixGraph data={graphData} thresholds={getThresholds()} />
          )}
          {(graphData.length === 0 || !hasData) && (
            <Flex
              alignCrossAxis='center'
              alignMainAxis='center'
              style={{ flex: 1, height: '100%' }}
            >
              <Text
                fontFamily={'mono'}
                variant={'primary'}
                tone={500}
                size={600}
                fontWeight={700}
              >
                {I18n.get('No data')}
              </Text>
            </Flex>
          )}
        </Box>
      </Box>
    </Fragment>
  )
}

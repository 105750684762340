import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import {
  getCognitoData,
  getCognitoIdentityId,
  makeGraphqlRequest
} from '../utils'

/*
 * Mutations
 */

export const attachPolicyToUser = async () => {
  try {
    const mutation = /* GraphQL */ `
      mutation attachPolicyToUser($identityId: String!) {
        attachPolicyToUser(identityId: $identityId) {
          name
          identityId
          message
        }
      }
    `

    const { id, idToken } = await getCognitoData()
    const identityId = await getCognitoIdentityId()
    const operation = graphqlOperation(mutation, { identityId })
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken,
      id
    )

    if (error) {
      return {
        policy: null,
        error: `IoT policy not attached: ${error}`
      }
    }

    return { policy: data?.attachPolicyToUser, error: null }
  } catch (err) {
    return { policy: null, error: err.message }
  }
}

import { isEmpty } from '@aws-amplify/core'

import { fixDate } from '../../utils'

export const UPDATE_INPUT = 'UPDATE_INPUT'
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS'
export const UPDATE_BANK_DETAILS = 'UPDATE_BANK_DETAILS'
export const SET_STATE = 'SET_STATE'
export const DESELECT_FILE = 'DESELECT_FILE'
export const SELECT_FILE = 'SELECT_FILE'
export const RESET_SECTION = 'RESET_SECTION'

export const initialState = {
  organizationId: '',
  name: '',
  code: '',
  category: '',
  status: '',
  supplyTypes: [],
  onboardDate: null,
  contactName: '',
  contactNumber: '',
  contactEmail: '',
  website: '',
  countryId: '',
  regionId: null,
  cityId: '',
  currencyId: '',
  paymentMethodId: '',
  paymentTermId: '',
  registrationNumber: '',
  taxNumber: '',
  address: {
    streetAddress: '',
    streetAddress2: '',
    city: '',
    region: '',
    country: '',
    zipCode: ''
  },
  bankDetails: {
    bankName: '',
    branchName: '',
    branchCode: '',
    branchAddress: {
      streetAddress: '',
      streetAddress2: '',
      city: '',
      region: '',
      country: '',
      zipCode: ''
    },
    swiftBic: '',
    iban: ''
  },
  bankDetailsDocument: {
    file: {},
    url: '',
    s3Key: ''
  },
  contractDocument: {
    file: {},
    url: '',
    s3Key: ''
  },
  s3KeysToDelete: []
}

export function reducer(state, action) {
  const handlers = {
    [SET_STATE]: (state, action) => setState(state, action),
    [UPDATE_INPUT]: (state, action) => ({
      ...state,
      [action.name]: action.value
    }),
    [UPDATE_ADDRESS]: (state, action) => ({
      ...state,
      address: {
        ...state.address,
        [action.name]: action.value
      }
    }),
    [UPDATE_BANK_DETAILS]: (state, action) => {
      const name = action.isBranchAddress ? 'branchAddress' : action.name
      const value = action.isBranchAddress
        ? { ...state.bankDetails.branchAddress, [action.name]: action.value }
        : action.value

      return {
        ...state,
        bankDetails: {
          ...state.bankDetails,
          [name]: value
        }
      }
    },
    [SELECT_FILE]: (state, action) => {
      let stateCopy = { ...state }
      stateCopy[action.name] = {
        file: action.file,
        url: '',
        s3Key: ''
      }

      return stateCopy
    },
    [DESELECT_FILE]: (state, action) => {
      let stateCopy = { ...state }
      const s3KeysToDelete = [...state.s3KeysToDelete]
      const s3KeyToDelete = stateCopy[action.name].s3Key
      if (s3KeyToDelete && !s3KeysToDelete.includes(s3KeyToDelete)) {
        s3KeysToDelete.push(s3KeyToDelete)
      }
      stateCopy[action.name] = {
        file: {},
        url: '',
        s3Key: ''
      }

      return { ...stateCopy, s3KeysToDelete }
    },
    [RESET_SECTION]: (state, action) => resetSection(state, action)
  }
  return handlers[action.type](state, action) || state
}

function resetSection(state, action) {
  const stateCopy = { ...state }
  const { section } = action
  if (section === 'address') {
    stateCopy.addressDocument = initialState.addressDocument
    stateCopy.address = initialState.address
  }
  if (section === 'bankDetails') {
    stateCopy.bankDetailsDocument = initialState.bankDetailsDocument
    stateCopy.bankDetails = initialState.bankDetails
  }
  return { ...stateCopy }
}

function setState(state, action) {
  let actionState = { ...initialState, ...action.state }

  actionState.onboardDate = fixDate(actionState.onboardDate)

  if (!actionState.address || isEmpty(actionState.address)) {
    actionState.address = initialState.address
  }

  if (!actionState.bankDetails || isEmpty(actionState.bankDetails)) {
    actionState.bankDetails = initialState.bankDetails
  }

  return actionState
}

import { Text } from '@/primitives'

import { STATUSMAP } from '../constants'

/**
 * A component that displays a text with a status-based styling.
 *
 * @param {object} props - The component props.
 * @param {string} props.status - The status of the text.
 * @param {string} props.text - The text to display. Defaults to an empty string.
 * @param {number} props.size - The size of the text. Defaults to 200.
 * @return {JSX.Element} The rendered StatusText component.
 */
export default function StatusText({ status, text = '', size = 200 }) {
  return (
    <Text
      size={size}
      fontWeight={700}
      variant={(STATUSMAP[status] || STATUSMAP.loading).variant}
      tone={500}
    >
      {text}
    </Text>
  )
}

import { useEffect, useState } from 'react'
import { Card, Slot, Text } from '@/primitives'
import { cleanDeployment } from './utils'

import Strings from '../../Strings'

const DeploymentCard = ({ deployment }) => {
  const [cleanedDeployment, setCleanedDeployment] = useState({})
  const strings = Strings()
  const {
    greengrassDeploymentId,
    greengrassDeploymentName,
    greengrassDeploymentStatus,
    greengrassDeploymentReason,
    greengrassDeploymentTimestamp
  } = strings

  useEffect(() => {
    if (deployment?.coreDeviceExecutionStatus) {
      setCleanedDeployment(cleanDeployment(deployment))
    }
  }, [deployment])

  const {
    deploymentId,
    deploymentName,
    coreDeviceExecutionStatus,
    reason,
    modifiedTimestamp
  } = cleanedDeployment

  return (
    <Card>
      <Slot slot='body'>
        <Text as='p'>
          <strong>{greengrassDeploymentId}</strong>: {deploymentId}
        </Text>
        <Text as='p'>
          <strong>{greengrassDeploymentName}</strong>: {deploymentName}
        </Text>
        <Text as='p'>
          <strong>{greengrassDeploymentStatus}</strong>:{' '}
          {coreDeviceExecutionStatus}
        </Text>
        <Text as='p'>
          <strong>{greengrassDeploymentReason}</strong>: {reason}
        </Text>
        <Text as='p'>
          <strong>{greengrassDeploymentTimestamp}</strong>:{' '}
          {new Date(modifiedTimestamp).toString()}
        </Text>
      </Slot>
    </Card>
  )
}

export default DeploymentCard

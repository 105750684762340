import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  getDashboards,
  copyDashboard,
  updateDashboard,
  resetDashboard,
  resetAllDashboards,
  upsertDashboardConfig
} from '@/api/management/dashboards'

import { findIndexDashboardInArray } from '@/slices/management/utils'

export const cleanReInit = createAsyncThunk('cleanReInit', () => {
  return {}
})

export const fetchDashboards = createAsyncThunk('fetchDashboards', async () => {
  return await getDashboards()
})

export const sendCopyDashboard = createAsyncThunk(
  'copyDashboard',
  async params => {
    return await copyDashboard(params)
  }
)

export const sendUpsertDashboardConfig = createAsyncThunk(
  'upsetDashboardConfig',
  async params => {
    return await upsertDashboardConfig(params)
  }
)

export const sendResetDashboard = createAsyncThunk(
  'resetDashboard',
  async params => {
    return await resetDashboard(params)
  }
)

export const sendResetAllDashboards = createAsyncThunk(
  'sendResetAllDashboards',
  async () => {
    return await resetAllDashboards()
  }
)

export const sendUpdateDashboard = createAsyncThunk(
  'sendUpdateDashboard',
  async params => {
    return await updateDashboard(params)
  }
)

const insertDashboard = (dashboards, dashboard) => {
  let newDashboards = JSON.parse(JSON.stringify(dashboards))
  const index = findIndexDashboardInArray(dashboard, newDashboards)
  if (index > -1) {
    newDashboards[index] = dashboard
  } else {
    newDashboards.push(dashboard)
  }
  return newDashboards
}

const dashboardSlice = createSlice({
  name: 'dashboardReducer',
  initialState: {
    dashboards: [],
    dashboard: {},
    error: null,
    resetting: false,
    reInit: false,
    useSimplifiedDefault: true,
    useSimplifiedByLocation: {},
    dashboardDefaultSettingUpdated: false
  },
  reducers: {
    setUseSimplifiedDefault: (state, action) => {
      state.useSimplifiedDefault = action.payload
      state.dashboardDefaultSettingUpdated = true
    },
    setUseSimplifiedByLocation: (state, action) => {
      const { id, value } = action.payload
      const useSimplifiedByLocation = state.useSimplifiedByLocation
      useSimplifiedByLocation[id] = value
      state.useSimplifiedByLocation = useSimplifiedByLocation
    }
  },
  extraReducers: builder => {
    builder
      .addCase(cleanReInit.fulfilled, (state, action) => {
        state.reInit = false
      })
      .addCase(fetchDashboards.pending, (state, action) => {
        state.dashboards = []
        state.dashboard = {}
        state.error = null
      })
      .addCase(fetchDashboards.rejected, (state, action) => {
        state.error = action?.error
      })
      .addCase(fetchDashboards.fulfilled, (state, action) => {
        state.dashboards = action?.payload.dashboards ?? []
      })
      .addCase(sendCopyDashboard.pending, (state, action) => {
        state.error = null
        state.resetting = true
      })
      .addCase(sendCopyDashboard.rejected, (state, action) => {
        state.error = action?.error
      })
      .addCase(sendCopyDashboard.fulfilled, (state, action) => {
        const { dashboard } = action.payload

        state.dashboards = insertDashboard(state.dashboards, dashboard)
        state.dashboard = dashboard
        state.resetting = false
        state.reInit = true
      })
      .addCase(sendUpsertDashboardConfig.pending, (state, action) => {
        state.error = null
      })
      .addCase(sendUpsertDashboardConfig.fulfilled, (state, action) => {
        const { dashboard } = action.payload
        state.dashboards = insertDashboard(state.dashboards, dashboard)
        state.dashboard = dashboard
        state.reInit = true
      })
      .addCase(sendUpsertDashboardConfig.rejected, (state, action) => {
        state.error = action?.error
      })
      .addCase(sendResetDashboard.pending, (state, action) => {
        state.error = null
        state.resetting = true
      })
      .addCase(sendResetDashboard.rejected, (state, action) => {
        state.error = action?.error
      })
      .addCase(sendResetDashboard.fulfilled, (state, action) => {
        const { dashboard } = action.payload

        state.dashboards = insertDashboard(state.dashboards, dashboard)
        state.dashboard = dashboard
        state.resetting = false
        state.reInit = true
      })
      .addCase(sendResetAllDashboards.pending, (state, action) => {
        state.dashboards = []
        state.error = null
        state.resetting = true
      })
      .addCase(sendResetAllDashboards.rejected, (state, action) => {
        state.error = action?.error
        state.resetting = false
      })
      .addCase(sendResetAllDashboards.fulfilled, (state, action) => {
        state.dashboards = action?.payload.dashboards ?? []
        state.resetting = false
      })
      .addCase(sendUpdateDashboard.pending, (state, action) => {
        state.error = null
      })
      .addCase(sendUpdateDashboard.rejected, (state, action) => {
        state.error = action?.error
      })
      .addCase(sendUpdateDashboard.fulfilled, (state, action) => {
        const { dashboard } = action.payload

        state.dashboards = insertDashboard(state.dashboards, dashboard)
        state.dashboard = dashboard
      })
  }
})


export const {
  setUseSimplifiedDefault,
  setUseSimplifiedByLocation
} = dashboardSlice.actions

export default dashboardSlice.reducer

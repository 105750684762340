import { APIProvider, Map } from '@vis.gl/react-google-maps'

import { useRef, useState } from 'react'

import { FlexV2 } from '@/primitives'

import Sensors from './Sensors'
import Aside from './Aside'
import Cluster from './Cluster'
import Icons from './Icons'

import { MAX_ZOOM, getMaxZoomAtLatLng } from './utils'

import './index.scss'

// Great pyramid of Giza
const START_POSITION = {
  lat: 29.979369381773516,
  lng: 31.134163191996148
}

const GoogleMap = ({
  devices,
  filteredDevices,
  currentCluster,
  setCurrentCluster,
  currentDevice,
  setCurrentDevice,
  isMobile
}) => {
  const mapRef = useRef()

  const [maxZoom, setMaxZoom] = useState(MAX_ZOOM)
  const [currentZoom, setCurrentZoom] = useState(0)
  const [zoomingOut, setZoomingOut] = useState(false)

  const onZoomChanged = async event => {
    const { map } = event
    const newMaxZoom = await getMaxZoomAtLatLng(map.getCenter())
    setCurrentZoom(map.getZoom())
    setMaxZoom(newMaxZoom)
  }

  // Cloud-based Map Styling does not work for
  // https://issuetracker.google.com/u/1/issues/180554122

  return (
    <APIProvider apiKey={window.ENV.GOOGLE_MAPS_KEY}>
      <FlexV2 style={{ flex: 1 }} className='map'>
        <div ref={mapRef} className='map-container'>
          <Map
            mapId={'coretex'}
            mapTypeId={'satellite'}
            disableDefaultUI={true}
            center={START_POSITION}
            backgroundColor='transparent'
            minZoom={2}
            maxZoom={maxZoom}
            onZoomChanged={onZoomChanged}
            onCameraChanged={onZoomChanged}
            onBoundsChanged={onZoomChanged}
          >
            {devices.length > 0 && (
              <Sensors
                devices={devices}
                filteredDevices={filteredDevices}
                currentCluster={currentCluster}
                setCurrentCluster={setCurrentCluster}
                currentDevice={currentDevice}
                setCurrentDevice={setCurrentDevice}
                maxZoom={maxZoom}
                currentZoom={currentZoom}
                setZoomingOut={setZoomingOut}
                onZoomChanged={onZoomChanged}
              />
            )}
          </Map>
          <Icons />
        </div>
        <Aside
          devices={filteredDevices}
          currentCluster={currentCluster}
          setCurrentCluster={setCurrentCluster}
          currentDevice={currentDevice}
          setCurrentDevice={setCurrentDevice}
          setZoomingOut={setZoomingOut}
          isMobile={isMobile}
        />
        <Cluster
          devices={filteredDevices}
          currentCluster={currentCluster}
          setCurrentCluster={setCurrentCluster}
          currentDevice={currentDevice}
          setCurrentDevice={setCurrentDevice}
          maxZoom={maxZoom}
          currentZoom={currentZoom}
          zoomingOut={zoomingOut}
          setZoomingOut={setZoomingOut}
          isMobile={isMobile}
          mapRef={mapRef}
        />
      </FlexV2>
    </APIProvider>
  )
}

export default GoogleMap

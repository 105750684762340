import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { createNvsDump, createBatchNvsDumpRequest } from '@/api/calibration/nvs'

import { showBanner } from '@/slices/util'

export const sendCreateNvsDump = createAsyncThunk(
  'sendCreateNvsDump',
  async (params, { dispatch }) => {
    const { error } = await createNvsDump(params)

    if (!error) {
      dispatch(
        showBanner({
          type: 'success',
          show: true,
          message: `NVS dump request for ${params.sensorId} was sent. Check email for request details.`
        })
      )
    } else {
      dispatch(
        showBanner({
          type: 'error',
          show: true,
          message: error
        })
      )
    }

    return { error }
  }
)

export const sendCreateBatchNvsDumpRequest = createAsyncThunk(
  'sendCreateBatchNvsDumpRequest',
  async (params, { dispatch }) => {
    const { error } = await createBatchNvsDumpRequest(params)

    if (!error) {
      dispatch(
        showBanner({
          type: 'success',
          show: true,
          message: `NVS dump request for ${params.devices.length} devices was sent. Check email for request details.`
        })
      )
    } else {
      dispatch(
        showBanner({
          type: 'error',
          show: true,
          message: error
        })
      )
    }

    return { error }
  }
)

const calibrationNvsSlice = createSlice({
  name: 'calibrationNvsReducer',
  initialState: {
    loading: false,
    error: null
  },
  extraReducers: builder => {
    builder
      .addCase(sendCreateNvsDump.pending, (state, action) => {
        state.loading = true
        state.error = null
      })
      .addCase(sendCreateNvsDump.fulfilled, (state, action) => {
        state.loading = false
        state.error = action.payload.error
      })
      .addCase(sendCreateBatchNvsDumpRequest.pending, (state, action) => {
        state.loading = true
        state.error = null
      })
      .addCase(sendCreateBatchNvsDumpRequest.fulfilled, (state, action) => {
        state.loading = false
        state.error = action.payload.error
      })
  }
})

const { reducer } = calibrationNvsSlice

export default reducer

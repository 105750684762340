import { I18n } from 'aws-amplify'
import { Text, Symbol, FlexV2 } from '@/primitives'

import style from './WarningBanner.module.scss'

export default function WarningBanner() {
  return (
    <FlexV2
      axisGap={400}
      className={style.warningBanner}
      alignCrossAxis='center'
    >
      <Symbol name='warning' size={500} style={{ width: '100%' }} />
      <Text>
        {I18n.get(
          'There seems to be an issue with the device configuration and it cannot be updated anymore. Please contact support.'
        )}
      </Text>
    </FlexV2>
  )
}

import { useReducer, Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ReactDatePicker from 'react-datepicker'

import ContentItemForm from './ContentItemForm'
import { Dialog, Select, LineSeparator } from '@/elements'
import { Slot, Text, Flex, Input, Label, Button, Box } from '@/primitives'

import LANGUAGES from '@/Util/languages.json'

import {
  sendCreateReleaseNote,
  cleanReleaseNoteUpserted,
  sendUpdateReleaseNote
} from '@/slices/fyi/releaseNotes'
import {
  getReleaseNotesSections,
  getReleaseNoteUpserted,
  getFyiTags
} from '@/reducers/selectors'
import { getTagOptions } from '../util'

import Strings from '../../Strings'

const strings = Strings()
const languageOptions = LANGUAGES.enabled.filter(lang => lang !== 'en')

const initialState = {
  releaseNoteId: '',
  title: '',
  items: [],
  tags: [],
  publishDate: null,
  visible: false,
  targetLangs: languageOptions
}

function reducer(state, action) {
  if (action.type === 'updateField') {
    return { ...state, [action.name]: action.value }
  }

  if (action.type === 'updateSelect') {
    const newArr = action.value.map(({ value }) => value)
    return { ...state, [action.name]: newArr }
  }

  if (action.type === 'addItem') {
    return { ...state, items: [...state.items, action.value] }
  }

  if (action.type === 'editItem') {
    const { index, text, sectionId } = action.value
    const items = [...state.items]
    items[index] = { text, sectionId }

    return { ...state, items }
  }

  if (action.type === 'removeItem') {
    const index = action.value
    const items = [...state.items]
    items.splice(index, 1)
    return { ...state, items }
  }

  if (action.type === 'setInit') {
    const { titleTranslations = null, ...rest } = action?.value
    if (titleTranslations) {
      const targetLangs = titleTranslations.map(({ lang }) => lang)

      return { ...state, ...rest, targetLangs }
    }

    return { ...state, ...rest, targetLangs: [] }
  }

  if (action.type === 'reset') {
    return { ...state, ...initialState }
  }

  if (action.type === 'updateTranslations') {
    if (state.targetLangs.includes(action.name)) {
      const targetLangs = [...state.targetLangs].filter(
        lang => lang !== action.name
      )
      return { ...state, targetLangs }
    }

    return { ...state, targetLangs: [...state.targetLangs, action.name] }
  }
}

const UpsertReleaseNotesForm = ({
  showForm,
  setHideForm,
  itemToEdit = null
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [showContentItemForm, setShowContentItemForm] = useState(false)
  const [contentToEdit, setContentToEdit] = useState({})
  const [tagsOptions, setTagsOptions] = useState([])

  const reduxDispatch = useDispatch()
  const created = getReleaseNoteUpserted()
  const tags = getFyiTags()

  const isEditForm = itemToEdit?.hasOwnProperty('releaseNoteId') && showForm
  const sections = getReleaseNotesSections()
  const date = state.publishDate ? state.publishDate * 1000 : Date.now()
  const selectedUTCDate = new Date(date)

  useEffect(() => {
    if (created && showForm) {
      reduxDispatch(cleanReleaseNoteUpserted())
      setHideForm()
    }
  }, [created, showForm, reduxDispatch, setHideForm])

  useEffect(() => {
    if (tags?.length > 0) {
      const formattedTagOptions = getTagOptions(tags)
      setTagsOptions(formattedTagOptions)
    }
  }, [tags?.length, tags])

  if (isEditForm && !state.releaseNoteId) {
    dispatch({ type: 'setInit', value: itemToEdit })
  }

  if (!showForm && JSON.stringify(state) !== JSON.stringify(initialState)) {
    dispatch({ type: 'reset' })
  }

  function onChangeInput({ target }) {
    const { value, name } = target
    dispatch({ type: 'updateField', value, name })
  }

  function onChangeSelect(selectedItems, { name }) {
    dispatch({ type: 'updateSelect', value: selectedItems, name })
  }

  function onSelectDate(date) {
    const dateTs = new Date(date).valueOf() / 1000
    dispatch({ type: 'updateField', value: dateTs, name: 'publishDate' })
  }

  function onChangeCheckbox({ target }) {
    const { checked, name } = target
    dispatch({ type: 'updateField', value: checked, name })
  }

  function onChangeTranslationsCheckbox({ target }) {
    const { checked, name } = target
    dispatch({ type: 'updateTranslations', value: checked, name })
  }

  function onSubmitForm() {
    const { title, tags, publishDate, visible, targetLangs } = state
    if (!isEditForm) {
      reduxDispatch(
        sendCreateReleaseNote({
          title,
          items: state.items,
          tags,
          publishDate,
          visible,
          targetLangs
        })
      )
    }

    if (isEditForm) {
      const items = [...state.items].map(({ itemId, sectionId, text }) => {
        const updatedItem = { sectionId, text }
        if (itemId) updatedItem['itemId'] = itemId
        return updatedItem
      })

      reduxDispatch(
        sendUpdateReleaseNote({
          releaseNoteId: state.releaseNoteId,
          title,
          items,
          tags,
          publishDate,
          visible
        })
      )
    }
  }

  function insertItemsBySection(items, sectionId) {
    return (
      <ul>
        {items.map(({ text, index }) => {
          function onClickRemoveItem() {
            dispatch({ type: 'removeItem', value: index })
          }

          function onClickEditItem() {
            setContentToEdit({ text, index, sectionId })
            setShowContentItemForm(true)
          }

          return (
            <li key={`releaseNotesItem-${index}`}>
              <Flex wrap='nowrap' alignMainAxis='space-between'>
                <Text>{text}</Text>
                <Flex wrap='nowrap'>
                  <Button
                    className='ReleaseNotesItem__Button__Inline'
                    onClick={onClickEditItem}
                    variant='text'
                    size='small'
                    iconBefore='edit'
                  />
                  <Button
                    className='ReleaseNotesItem__Button__Inline'
                    onClick={onClickRemoveItem}
                    variant='text'
                    size='small'
                    iconBefore='delete'
                  />
                </Flex>
              </Flex>
            </li>
          )
        })}
      </ul>
    )
  }

  function insertReleaseNoteSections() {
    const releaseNotesContent = state.items.reduce(
      (acc, { text, sectionId }, index) => {
        if (!acc[sectionId]) {
          acc[sectionId] = [{ text, index }]
        } else {
          acc[sectionId].push({ text, index })
        }

        return acc
      },
      {}
    )

    return (
      <Fragment>
        {sections?.map(({ sectionId, name }) => {
          const items = releaseNotesContent[sectionId]
          if (releaseNotesContent?.hasOwnProperty(sectionId)) {
            return (
              <Box
                key={sectionId}
                style={{ margin: '0.5rem 0 0 0', display: 'block' }}
              >
                <Text fontWeight={700}>{name}</Text>
                {insertItemsBySection(items, sectionId)}
              </Box>
            )
          }
          return null
        })}
      </Fragment>
    )
  }

  function upsertItem(details, type = 'addItem') {
    dispatch({ type, value: details })
    if (type === 'editItem') setContentToEdit({})
  }

  function onClickAddItem() {
    setShowContentItemForm(true)
  }

  function closeAddItemForm() {
    setShowContentItemForm(false)
    setContentToEdit({})
  }

  return (
    <Fragment>
      <Dialog
        className='ZoneDetails__OffCanvas'
        type='offcanvas'
        open={showForm}
        onOpenChange={setHideForm}
      >
        <Slot name='title'>
          <Text size={400} fontWeight={700}>
            {isEditForm ? strings.editReleaseNote : strings.addReleaseNote}
          </Text>
        </Slot>
        <Slot name='content'>
          <Text size={200} as='p' variant='page'>
            {isEditForm
              ? strings.editReleaseNoteSubheading
              : strings.addReleaseNoteSubheading}
          </Text>
          <Box>
            <LineSeparator />
          </Box>
          {isEditForm && (
            <Label>
              <Text>{strings.tooltipIdColHeader}</Text>
              <Input
                name='releaseNoteId'
                onChange={onChangeInput}
                value={state.releaseNoteId}
                disabled
              />
            </Label>
          )}
          <Label>
            <Text>{strings.title}</Text>
            <Input name='title' onChange={onChangeInput} value={state.title} />
          </Label>
          <Flex
            direction='row'
            wrap='nowrap'
            alignCrossAxis='flex-end'
            axisGap={300}
            style={{ margin: '1.5rem 0' }}
          >
            <Label style={{ width: '50%' }}>
              <Text>{strings.publishDate}</Text>
              <Box style={{ marginTop: '0.5rem' }}>
                <ReactDatePicker
                  dateFormat='d MMM yyyy'
                  selected={selectedUTCDate}
                  onChange={onSelectDate}
                />
              </Box>
            </Label>
            <Label style={{ width: '50%', marginTop: '0' }}>
              <Text>{strings.published}</Text>
              <Box style={{ marginTop: '0.5rem', height: '2.2rem' }}>
                <Input
                  name='visible'
                  type='checkbox'
                  onChange={onChangeCheckbox}
                  checked={state.visible ?? false}
                />
                <Text size={100} variant='page' tone={700}>
                  {strings.visibleToUsers}
                </Text>
              </Box>
            </Label>
          </Flex>
          <Label>
            <Text>{strings.tags}</Text>
            <Select
              name='tags'
              value={state.tags}
              options={tagsOptions}
              placeholder={strings.selectTags}
              controlStyles={{
                marginTop: '0.5rem',
                borderColor: 'var(--ctx-theme-color-page-300) !important'
              }}
              onChange={onChangeSelect}
              isMulti={true}
            />
          </Label>
          <Flex
            alignMainAxis='space-between'
            alignCrossAxis='centre'
            style={{ marginTop: '1.5rem' }}
          >
            <Text>{strings.content}</Text>
            <Button
              iconBefore='add'
              size='small'
              variant='primary'
              onClick={onClickAddItem}
            >
              {strings.add}
            </Button>
          </Flex>
          {state.items.length === 0 && (
            <Text as='p'>{strings.noContentAdded}</Text>
          )}
          {state.items.length > 0 && (
            <Box
              style={{
                border: '1px solid var(--ctx-theme-color-page-300)',
                padding: '0.2rem 0.8rem',
                margin: '0.5rem 0',
                borderRadius: '5px'
              }}
            >
              {insertReleaseNoteSections()}
            </Box>
          )}
          {!isEditForm && (
            <Fragment>
              <Text style={{ margin: '2rem 0 0.7rem' }}>
                {strings.generateTranslations}
              </Text>
              <Flex axisGap={700}>
                {languageOptions.map(lang => (
                  <Label key={lang} style={{ marginBlockStart: '0' }}>
                    <Input
                      name={lang}
                      type='checkbox'
                      onChange={onChangeTranslationsCheckbox}
                      checked={state.targetLangs.includes(lang)}
                    />
                    <Text fontWeight={300}>{strings[lang]}</Text>
                  </Label>
                ))}
              </Flex>
            </Fragment>
          )}
          {isEditForm && state.targetLangs?.length > 0 && (
            <Fragment>
              <Text
                size={100}
                variant='page'
                tone={800}
                style={{ margin: '2rem 0 0.7rem' }}
              >
                {strings.translationsToBeUpdated}
              </Text>
              {state.targetLangs.map(lang => (
                <Text
                  key={lang}
                  fontWeight={300}
                  style={{ marginBottom: '0.3rem' }}
                >
                  {strings[lang]}
                </Text>
              ))}
            </Fragment>
          )}
        </Slot>
        <Slot name='actions'>
          <Flex alignMainAxis='space-between'>
            <Button size='small' variant='error' onClick={setHideForm}>
              {strings.cancel}
            </Button>
            <Button size='small' variant='primary' onClick={onSubmitForm}>
              {strings.submit}
            </Button>
          </Flex>
        </Slot>
      </Dialog>
      <ContentItemForm
        showForm={showContentItemForm}
        setHideForm={closeAddItemForm}
        upsertItem={upsertItem}
        contentToEdit={contentToEdit}
      />
    </Fragment>
  )
}

export default UpsertReleaseNotesForm

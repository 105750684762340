import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import TableRow from './TableRow'

import {
  FlexV2,
  Table,
  Slot,
  Column,
  Row,
  Text,
  Loader,
  Box
} from '@/primitives'

import Pagination from '@/elements/Pagination'

import {
  getCurrentUserOrganizations,
  getAuditLogsCount
} from '@/reducers/selectors'

import { fetchAuditLogs } from '@/slices/audit/log'
import useOrgLabel from '@/hooks/useOrgLabel'

import { useAuditTrail } from './context'

import { SET_CURRENT_PAGE, SET_TOTAL_PAGES } from './state'

import Strings from './Strings'

function AuditLogTable({ onInspect, zoneMap }) {
  const dispatch = useDispatch()
  const strings = Strings()

  const auditLogsCount = getAuditLogsCount()
  const organizations = getCurrentUserOrganizations()

  const [pageSize, setPageSize] = useState(9)
  const { site } = useOrgLabel(['site'])
  const { state, dispatchState } = useAuditTrail()

  const organizationMap = organizations.reduce((acc, org) => {
    acc[org.id] = org.name
    return acc
  }, {})

  const changePage = newPage => {
    if (newPage > 1) {
      const stateNextToken = state.nextTokenArr[newPage - 2]
      dispatch(
        fetchAuditLogs({
          filters: state?.filters,
          limit: state?.limit,
          nextToken: stateNextToken
        })
      )
    }

    if (newPage === 1) {
      dispatch(
        fetchAuditLogs({
          filters: state?.filters,
          limit: state?.limit
        })
      )
    }

    dispatchState({
      type: SET_CURRENT_PAGE,
      currentPage: newPage
    })
  }

  const setTotalPages = useCallback(
    totalPages => {
      dispatchState({
        type: SET_TOTAL_PAGES,
        totalPages
      })
    },
    [dispatchState]
  )

  return (
    <FlexV2 axisGap={400} direction='column' style={{ flexGrow: 1 }}>
      <Loader isLoading={!state?.currentAuditTrailLogs}>
        {state?.currentAuditTrailLogs?.length === 0 && (
          <FlexV2>
            <Text>{strings.noAuditLogs}</Text>
          </FlexV2>
        )}
        {state?.currentAuditTrailLogs?.length > 0 && (
          <Table aria-colcount='9'>
            <Slot name='head'>
              <Row>
                <Column>{strings.actor}</Column>
                <Column>{strings.actorType}</Column>
                <Column>{strings.action}</Column>
                <Column>{strings.service}</Column>

                <Column>{strings.date}</Column>
                <Column>{strings.organization}</Column>
                <Column>{site.text}</Column>
                <Column>{strings.context}</Column>
                <Column>{strings.details}</Column>
              </Row>
            </Slot>
            <Slot name='body'>
              {state?.currentAuditTrailLogs?.map(item => (
                <TableRow
                  key={`${item.actorId}-${item.timestamp}`}
                  auditLog={item}
                  onInspect={onInspect}
                  organization={organizationMap[item.organizationId]}
                  zone={zoneMap[item.siteId]}
                />
              ))}
            </Slot>
          </Table>
        )}
        {state?.currentAuditTrailLogs?.length > 0 && (
          <Box className='pagination-menu'>
            <Pagination
              type='no-pages'
              totalItems={auditLogsCount}
              showPageCount={false}
              totalPages={state?.totalPages}
              setTotalPages={setTotalPages}
              page={state?.currentPage}
              setPage={changePage}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          </Box>
        )}
      </Loader>
    </FlexV2>
  )
}

export default AuditLogTable

import { useEffect, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { Switch, Route } from 'react-router-dom'

import {
  clearProduceThresholdMessage,
  clearProduceThresholdMessages
} from '@/slices/operations/produceThresholdMessage'

import { tableReducer, tableInitialState } from './state'

import Table from './Table'
import Form from './Form'

const MODULE_PATH = '/operations/admin/produce-threshold-message'

const ProduceThresholdMessageAdmin = () => {
  const dispatch = useDispatch()

  const [state, dispatchState] = useReducer(tableReducer, tableInitialState)

  useEffect(() => {
    return () => {
      dispatch(clearProduceThresholdMessage())
      dispatch(clearProduceThresholdMessages())
    }
  }, [dispatch])

  return (
    <Switch>
      <Route
        path={[`${MODULE_PATH}/create`, `${MODULE_PATH}/update/:itemId`]}
        exact={true}
      >
        <Form
          state={state}
          dispatchState={dispatchState}
          modulePath={MODULE_PATH}
        />
      </Route>
      <Route path={MODULE_PATH} exact={true}>
        <Table
          state={state}
          dispatchState={dispatchState}
          modulePath={MODULE_PATH}
        />
      </Route>
    </Switch>
  )
}

export default ProduceThresholdMessageAdmin

import { ICON_TYPES, getIconColorByStatus } from '../utils'

const SIZE = 32

const Pin = ({ device, style = {}, onClick = null }) => {
  const getIconType = deviceType => {
    if (ICON_TYPES.indexOf(deviceType) >= 0) {
      return deviceType
    }
    return 'generic'
  }

  const getStyle = () => {
    return Object.assign({}, style, {
      borderColor: getIconColorByStatus(device.status)
    })
  }

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height={SIZE}
      width={SIZE}
      style={getStyle()}
      className='pin'
      onClick={onClick}
    >
      <use
        href={'#ctx-icon-device-' + getIconType(device.type.toLowerCase())}
        x={1.5}
        y={1.5}
        width={SIZE * 0.75}
        height={SIZE * 0.75}
        fill={getIconColorByStatus(device.status)}
      />
    </svg>
  )
}

export default Pin

import { I18n } from 'aws-amplify'
import { Fragment, useState, useEffect, Children, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import EditRoleSection from './EditRoleSection'

import { Button, FlexV2, Text, Separator } from '@/primitives'

import { fetchRole, fetchRolePermissions } from '@/slices/management/role'

import {
  getRolePermissions,
  getPermissions,
  getZonesList,
  getCurrentUser
} from '@/reducers/selectors'

import { getIsGodMode } from '@/Util/PermissionUtils'

import Strings from '../Strings'

const getCanAccessAllOrgs = roleOrganizations => {
  return roleOrganizations.length === 0
}

const initialOrderedPermissionsTypes = [
  {
    label: I18n.get('Location'),
    value: 'zone',
    sectionName: 'zone'
  },
  {
    label: I18n.get('Sensor'),
    value: 'sensor',
    sectionName: 'sensor'
  },
  {
    label: I18n.get('User'),
    value: 'user',
    sectionName: 'user'
  },
  {
    label: I18n.get('Operations'),
    value: 'operations',
    sectionName: 'operations'
  },
  {
    label: I18n.get('Systems'),
    value: 'systems',
    sectionName: 'systems'
  },
  {
    label: I18n.get('Data API'),
    value: 'data_api',
    sectionName: 'data_api'
  }
]

export default function EditRole({ match }) {
  const strings = Strings()
  const { editRoleHeading, editRoleSubheading } = strings

  const history = useHistory()
  const role = getRolePermissions()
  const reduxDispatch = useDispatch()
  const permissions = getPermissions()
  const zones = getZonesList()
  const currentUser = getCurrentUser()
  const isGodMode = getIsGodMode(currentUser)

  const [sortedPermissions, setSortedPermissions] = useState({})
  const [filteredPermissions, setFilteredPermissions] = useState({})
  const [orderedPermissionsTypes, setOrderedPermissionsTypes] = useState(
    initialOrderedPermissionsTypes
  )
  const [roleCanAccessAllOrgs, setRoleCanAccessAllOrgs] = useState(false)

  const sortByType = useCallback(
    (permissions, enabledPermissions, isAdmin = false) => {
      let sorted = {}

      const isEnabled = permissionId => {
        if (enabledPermissions?.length === 0) return false
        return enabledPermissions?.some(({ id }) => permissionId === id)
      }

      permissions.forEach(permission => {
        const { resourceType } = permission

        if (resourceType !== 'organization' || isAdmin) {
          if (!sorted.hasOwnProperty(resourceType)) {
            sorted[resourceType] = []
          }

          const updatedPermission = {
            ...permission,
            enabled: isEnabled(permission.id)
          }

          sorted[resourceType].push(updatedPermission)
        }
      })

      return sorted
    },
    []
  )

  useEffect(() => {
    if (!role?.organizations?.length > 0) {
      reduxDispatch(fetchRole({ roleName: match.params.id }))
    }
  }, [role?.organizations, reduxDispatch, match.params.id])

  useEffect(() => {
    if (role?.organizations?.length > 0) {
      const allOrgsAccess = getCanAccessAllOrgs(role?.organizations)
      setRoleCanAccessAllOrgs(allOrgsAccess)
    }
  }, [role?.organizations])

  useEffect(() => {
    if (
      role?.name &&
      !role?.permissions?.length > 0 &&
      permissions.length > 0
    ) {
      reduxDispatch(fetchRolePermissions({ roleName: match.params.id }))
    }
  }, [
    role?.name,
    role?.permissions,
    reduxDispatch,
    match.params.id,
    permissions
  ])

  useEffect(() => {
    if (permissions?.length > 0 && role?.permissions?.length > 0) {
      if (isGodMode) {
        const orderedPermissionsTypesTemp = [...orderedPermissionsTypes]

        const hasDocsPermissions = orderedPermissionsTypesTemp.some(
          perm => perm.value === 'docs' && perm.sectionName === 'docs'
        )
        const hasOrganizationsPermissions = orderedPermissionsTypesTemp.some(
          perm =>
            perm.value === 'organization' && perm.sectionName === 'organization'
        )

        if (!hasDocsPermissions && !hasOrganizationsPermissions) {
          const newOrderedPermissionsTypes = [
            ...orderedPermissionsTypesTemp,
            { label: I18n.get('docs'), value: 'docs', sectionName: 'docs' }
          ]

          newOrderedPermissionsTypes.unshift({
            label: I18n.get('organization'),
            value: 'organization',
            sectionName: 'organization'
          })

          const newOrderedPermissionsTypesSet = [
            ...new Set(newOrderedPermissionsTypes)
          ]

          setOrderedPermissionsTypes(newOrderedPermissionsTypesSet)
        }
      }

      const sortedRolePermissions = sortByType(
        permissions,
        role.permissions,
        currentUser?.organizationIds?.length === 0
      )
      setSortedPermissions(sortedRolePermissions)
    }
  }, [
    permissions,
    role?.permissions,
    isGodMode,
    currentUser?.organizationIds,
    orderedPermissionsTypes,
    sortByType
  ])

  useEffect(() => {
    const sortedPermissionsKeys = Object.keys(sortedPermissions)

    if (sortedPermissionsKeys.length > 0) {
      if (roleCanAccessAllOrgs) {
        setFilteredPermissions(sortedPermissions)
      }

      if (!roleCanAccessAllOrgs) {
        const orgIds = role?.organizations.map(org => org.id)

        const zoneIds = zones.reduce((filteredZoneIds, currentZone) => {
          const { organizationId, id } = currentZone
          if (orgIds?.includes(organizationId)) {
            return [...filteredZoneIds, id]
          }
          return filteredZoneIds
        }, [])

        const filteredZones = sortedPermissions.zone.reduce(
          (acc, currentZone) => {
            if (
              zoneIds.includes(currentZone.resourceId) ||
              currentZone.resourceId === null
            ) {
              const zoneDetails = zones.find(({ id, organizationId }) => {
                return currentZone.resourceId === id
              })
              currentZone.organizationId = zoneDetails?.organizationId
              return [...acc, currentZone]
            }
            return acc
          },
          []
        )

        setFilteredPermissions({ ...sortedPermissions, zone: filteredZones })
      }
    }
  }, [sortedPermissions, zones, roleCanAccessAllOrgs, role?.organizations])

  const handleBackButton = () => {
    history.push({ pathname: '/admin/user-roles' })
  }

  return (
    <FlexV2
      direction={'column'}
      axisGap={400}
      className={'Role__Permission_Settings'}
    >
      <FlexV2 wrap='wrap' axisGap={400} alignCrossAxis='center'>
        <Button
          size={'small'}
          iconBefore='arrow_back_ios'
          onClick={handleBackButton}
        />
        <FlexV2 direction='column'>
          <Text size={300}>
            {role?.name} {editRoleHeading}
          </Text>
          <Text variant='page' tone={700}>
            {editRoleSubheading}
          </Text>
        </FlexV2>
      </FlexV2>
      <Separator />
      {role?.hasOwnProperty('permissions') && (
        <Fragment>
          <FlexV2 axisGap={300} wrap={'wrap'} alignCrossAxis='center'>
            <Text size={200} variant='page' tone={800}>
              {strings.roleOrgs}:
            </Text>

            {roleCanAccessAllOrgs && (
              <Text
                size={200}
                variant='page'
                tone={800}
                className='organization-name'
              >
                {strings.allOrgs}
              </Text>
            )}

            {!roleCanAccessAllOrgs &&
              role?.organizations?.map(({ name }) => (
                <Text
                  key={name}
                  size={200}
                  fontWeight={700}
                  className='organization-name'
                >
                  {name}
                </Text>
              ))}
          </FlexV2>
          <Separator />
          <FlexV2 direction='column' axisGap={400}>
            {Object.keys(filteredPermissions).length > 0 && (
              <Fragment>
                {Children.toArray(
                  orderedPermissionsTypes.map(
                    ({ label, sectionName, value }, index) => (
                      <Fragment>
                        <EditRoleSection
                          key={`${value}-${index}`}
                          allPermissions={filteredPermissions[value]}
                          label={label}
                          sectionName={sectionName}
                          enabledOrgs={role?.organizations}
                        />
                        <Separator />
                      </Fragment>
                    )
                  )
                )}
              </Fragment>
            )}
          </FlexV2>
        </Fragment>
      )}
    </FlexV2>
  )
}

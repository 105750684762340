import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {
  createProduceThresholdMessage,
  updateProduceThresholdMessage,
  deleteProduceThresholdMessage,
  getProduceThresholdMessages,
  getProduceThresholdMessage
} from '@/api/operations/produceThresholdMessage'

export const sendCreateProduceThresholdMessage = createAsyncThunk(
  'sendCreateProduceThresholdMessage',
  async params => {
    const { produceThresholdMessage, error } =
      await createProduceThresholdMessage(params)
    return { produceThresholdMessage, error }
  }
)

export const sendUpdateProduceThresholdMessage = createAsyncThunk(
  'sendUpdateProduceThresholdMessage',
  async params => {
    const { produceThresholdMessage, error } =
      await updateProduceThresholdMessage(params)
    return { produceThresholdMessage, error }
  }
)

export const sendDeleteProduceThresholdMessage = createAsyncThunk(
  'sendDeleteProduceThresholdMessage',
  async params => {
    const { produceThresholdMessage, error } =
      await deleteProduceThresholdMessage(params)
    return { produceThresholdMessage, error }
  }
)

export const fetchProduceThresholdMessages = createAsyncThunk(
  'fetchProduceThresholdMessages',
  async params => {
    const { produceThresholdMessages, error } =
      await getProduceThresholdMessages(params)
    return { produceThresholdMessages, error }
  }
)

export const fetchProduceThresholdMessage = createAsyncThunk(
  'fetchProduceThresholdMessage',
  async params => {
    const { produceThresholdMessage, error } = await getProduceThresholdMessage(
      params
    )
    return { produceThresholdMessage, error }
  }
)

const operationsProduceThresholdMessageSlice = createSlice({
  name: 'operationsProduceThresholdMessageReducer',
  initialState: {
    produceThresholdMessage: {},
    produceThresholdMessages: [],
    produceThresholdMessagesCount: 0,
    updatedProduceThresholdMessage: {},
    error: null,
    loading: false
  },
  reducers: {
    clearProduceThresholdMessage: state => {
      state.produceThresholdMessage = {}
    },
    clearProduceThresholdMessages: state => {
      state.produceThresholdMessages = []
    },
    cleanUpdatedProduceThresholdMessage: state => {
      state.updatedProduceThresholdMessage = {}
    },
    setProduceThresholdMessage: (state, action) => {
      state.produceThresholdMessage = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(sendCreateProduceThresholdMessage.pending, (state, action) => {
        state.error = null
        state.loading = true
      })
      .addCase(sendCreateProduceThresholdMessage.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.loading = false
        state.produceThresholdMessage =
          action?.payload?.produceThresholdMessage ?? {}
      })
      .addCase(sendUpdateProduceThresholdMessage.pending, (state, action) => {
        state.error = null
        state.loading = true
      })
      .addCase(sendUpdateProduceThresholdMessage.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.loading = false
        state.produceThresholdMessage =
          action?.payload?.produceThresholdMessage ?? {}
      })
      .addCase(sendDeleteProduceThresholdMessage.pending, (state, action) => {
        state.error = null
        state.loading = true
      })
      .addCase(sendDeleteProduceThresholdMessage.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.loading = false
        state.produceThresholdMessage =
          action?.payload?.produceThresholdMessage ?? {}
        if (state.error === null && state.produceThresholdMessage?.id) {
          const itemIndex = state.produceThresholdMessages.findIndex(
            m => m.id === state.produceThresholdMessage.id
          )
          if (itemIndex >= 0) {
            state.produceThresholdMessages.splice(itemIndex, 1)
            state.produceThresholdMessagesCount -= 1
          }
        }
      })
      .addCase(fetchProduceThresholdMessages.pending, (state, action) => {
        state.error = null
        state.loading = true
      })
      .addCase(fetchProduceThresholdMessages.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.loading = false
        state.produceThresholdMessages =
          action?.payload?.produceThresholdMessages ?? {}
        state.produceThresholdMessagesCount =
          action?.payload?.produceThresholdMessages?.length ?? 0
      })
      .addCase(fetchProduceThresholdMessage.pending, (state, action) => {
        state.error = null
        state.loading = true
      })
      .addCase(fetchProduceThresholdMessage.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.loading = false
        state.produceThresholdMessage =
          action?.payload?.produceThresholdMessage ?? {}
      })
  }
})

export const {
  clearProduceThresholdMessage,
  clearProduceThresholdMessages,
  cleanUpdatedProduceThresholdMessage,
  setProduceThresholdMessage
} = operationsProduceThresholdMessageSlice.actions

export default operationsProduceThresholdMessageSlice.reducer

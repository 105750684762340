import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { I18n } from 'aws-amplify'

import { Button, Flex, Loader, Text } from '@/primitives'
import { LineSeparator, SearchBar } from '@/elements'
import useOrgLabel from '@/hooks/useOrgLabel'
import { hasEditPermissions, RESOURCE_TYPE_ZONE } from '@/Util/PermissionUtils'

import {
  cleanZone,
  cleanZonesList,
  fetchZonesList
} from '@/slices/management/zone'
import { cleanThresholds } from '@/slices/threshold'
import { getCurrentUser, getZonesListLoading } from '@/reducers/selectors'

import ZoneCards from './Utils/Cards'
import StatusFilter from './Utils/StatusFilter'
import UpdateZoneModal from './UpsertModal'

export default function ZonesList({ history }) {
  const dispatch = useDispatch()

  const coretexUser = getCurrentUser()
  const isLoading = getZonesListLoading()

  const [showModal, setShowModal] = useState(false)
  const [selectedZone, setSelectedZone] = useState({})
  const [zonesList, setZonesList] = useState([])
  const [searchResults, setSearchResults] = useState(zonesList)
  const [searchText, setSearchText] = useState('')
  const { site } = useOrgLabel(['site'])

  const showEditOptions = hasEditPermissions(coretexUser, RESOURCE_TYPE_ZONE)

  useEffect(() => {
    dispatch(fetchZonesList())
    dispatch(cleanZone())
  }, [dispatch])

  function goToUrl(path = null) {
    const zonesPath = '/admin/zones'
    if (path) {
      history.push(`${zonesPath}/${path}`)
    } else {
      history.push(zonesPath)
    }
  }

  const onClickAddZone = () => {
    setShowModal(true)
  }

  const onClickCard = zone => {
    setSelectedZone(zone)
    dispatch(cleanThresholds())
    dispatch(cleanZonesList())
    goToUrl(zone.id)
  }

  const onUpdateSearchText = text => {
    setSearchText(text)
  }

  useEffect(() => {
    setSearchResults(
      zonesList.filter(zone =>
        zone?.name?.toLowerCase().includes(searchText.toLowerCase())
      )
    )
  }, [searchText, zonesList])

  return (
    <Flex direction='column'>
      {showModal && (
        <UpdateZoneModal
          showModal={showModal}
          setShowModal={setShowModal}
          addNewZone={true}
        />
      )}
      <Loader
        text={`${I18n.get('Loading')} ${site.textPlural}`}
        isLoading={isLoading}
      >
        <Flex axisGap={400} direction='column' wrap='nowrap'>
          <Flex
            alignMainAxis={selectedZone?.id ? 'flex-start' : 'space-between'}
            alignCrossAxis='center'
            axisGap={200}
          >
            <Text size='300' variant='page' tone='800' fontWeight='300'>
              {site.textPlural}
            </Text>
            {showEditOptions && (
              <Button onClick={onClickAddZone} variant='primary' size='small'>
                {I18n.get('Add')} {site.text}
              </Button>
            )}
          </Flex>
          <Flex direction='column' axisGap={400}>
            <StatusFilter setZonesList={setZonesList} />
            <SearchBar
              isLoading={isLoading}
              onUpdateSearchText={onUpdateSearchText}
              placeholderText={`${I18n.get('Search')} ${site.textPlural}`}
            />
          </Flex>
          <LineSeparator />
          {!isLoading && searchResults?.length > 0 && !selectedZone?.id && (
            <ZoneCards zones={searchResults} onClickCard={onClickCard} />
          )}
        </Flex>
      </Loader>
    </Flex>
  )
}

import { sortDevicesByStatus, maxDeviceStatus } from './utils'

export const SET_DEVICES = 'MAP_SET_DEVICES'
export const SET_ALERTS = 'MAP_SET_ALERTS'
export const SET_OFFLINE_DEVICES = 'MAP_SET_OFFLINE_DEVICES'
export const SET_CURRENT_CLUSTER = 'SET_CURRENT_CLUSTER'
export const SET_CURRENT_DEVICE = 'SET_CURRENT_DEVICE'
export const SET_CURRENT_ZOOM = 'SET_CURRENT_ZOOM'
export const SET_ZOOM_TO_MAX = 'ZOOM_TO_MAX'
export const RESET_ALL = 'RESET_ALL'

export const initialState = {
  devices: [],
  alerts: [],
  offlineDevices: [],
  filteredDevices: [],
  currentCluster: null,
  clusterStack: [],
  currentDevice: null,
  currentZoom: 0,
  zoomToMax: false
}

const reducer = (state, action) => {
  let newState = { ...state }
  if (action.type === SET_DEVICES) {
    let newDevices = action.devices
    sortDevicesByStatus(newDevices)
    newState.devices = newDevices
    newState.filteredDevices = newDevices
  } else if (action.type === SET_ALERTS) {
    let newDevices = state.devices.map(device => {
      if (action.alerts[device.id]) {
        device.status = maxDeviceStatus(device.status, action.alerts[device.id])
      }
      return device
    })
    sortDevicesByStatus(newDevices)
    newState.devices = newDevices
    newState.filteredDevices = newDevices
    newState.alerts = action.alerts
  } else if (action.type === SET_OFFLINE_DEVICES) {
    let newDevices = state.devices.map(device => {
      if (action.offlineDevices[device.id]) {
        device.status = maxDeviceStatus(
          device.status,
          action.offlineDevices[device.id].status
        )
      }
      return device
    })
    sortDevicesByStatus(newDevices)
    newState.devices = newDevices
    newState.filteredDevices = newDevices
    newState.offlineDevices = action.offlineDevices
  } else if (action.type === SET_CURRENT_CLUSTER) {
    let newFilteredDevices = []
    let nextCluster = action.cluster
    if (nextCluster?.length > 0) {
      if (state.currentCluster !== null) {
        let isRepeatCluster = false
        if (state.clusterStack.length > 0) {
          const testCluster = JSON.stringify(state.currentCluster)
          const checkCluster = JSON.stringify(nextCluster)
          if (testCluster === checkCluster) {
            isRepeatCluster = true
          }
        }
        if (!isRepeatCluster) {
          newState.clusterStack.push(state.currentCluster)
        }
      }
    } else {
      if (state.clusterStack.length > 0) {
        let newClusterStack = [...state.clusterStack]
        nextCluster = newClusterStack.pop()
        newState.clusterStack = newClusterStack
      }
    }
    if (nextCluster !== null) {
      for (let clusteredDeviceId of nextCluster) {
        for (let device of state.devices) {
          if (device.id === clusteredDeviceId) {
            newFilteredDevices.push(device)
            break
          }
        }
      }
    } else {
      newFilteredDevices = [...state.devices]
    }
    sortDevicesByStatus(newFilteredDevices)
    newState.filteredDevices = newFilteredDevices
    newState.currentCluster = nextCluster
    newState.currentDevice = null
  } else if (action.type === SET_CURRENT_DEVICE) {
    newState.currentDevice = action.device
  } else if (action.type === SET_CURRENT_ZOOM) {
    newState.currentZoom = action.zoom
  } else if (action.type === SET_ZOOM_TO_MAX) {
    newState.zoomToMax = action.zoomToMax
  } else if (action.type === RESET_ALL) {
    newState = initialState
  }
  return newState
}

export default reducer

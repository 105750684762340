import { DateTime } from 'luxon'

import { Button } from '@/primitives'

import Strings from '../../Strings'

const {
  deviceStatusActive,
  deviceStatusPending,
  deviceStatusUnreachable,
  deviceStatusRunning,
  deviceStatusUnknown
} = Strings()

export function getReadableDateTime(timestamp) {
  if (!timestamp) return ''
  const date = DateTime.fromSeconds(timestamp)
  const formattedDate = date.toLocaleString(DateTime.DATETIME_MED)
  return formattedDate
}

export function getAllDevices(deviceData) {
  let devices = []

  if (deviceData?.devices?.length > 0) {
    const { id, parentPath, hasCoreDevice } = deviceData

    const iotSensors = deviceData.devices.filter(({ isIoT }) => isIoT)

    if (iotSensors?.length > 0) {
      const ggSensors = []
      const dcSensors = []

      iotSensors.forEach(sensor => {
        const date = DateTime.fromSeconds(sensor.createdTimestamp)
        const formattedDate = date.toFormat('dd/MM/yyyy')
        const updatedSensor = {
          ...sensor,
          selected: false,
          formattedDate,
          status: null,
          updatedAt: null
        }
        if (hasCoreDevice && sensor.isGreengrass) {
          ggSensors.push(updatedSensor)
        } else {
          dcSensors.push(updatedSensor)
        }
      })

      const deviceDetails = {
        id,
        path: parentPath,
        status: null,
        updatedAt: null,
        selected: false
      }

      if (ggSensors?.length > 0) {
        devices.push({
          ...deviceDetails,
          type: 'greengrass',
          sensors: ggSensors
        })
      }

      if (dcSensors?.length > 0) {
        devices.push({
          ...deviceDetails,
          type: 'direct',
          sensors: dcSensors
        })
      }
    }
  }

  const childZoneIds = Object.keys(deviceData?.children)
  if (childZoneIds?.length > 0) {
    for (const zoneId of childZoneIds) {
      const child = deviceData.children[zoneId]
      devices = devices.concat(getAllDevices(child))
    }
  }

  return devices
}

export function getUpdateIds(tabId, sensorsByZone, siteId) {
  const sensorIds = []
  const sensorNames = []
  const zoneIds = []

  sensorsByZone.forEach(zone => {
    let hasSelectedSensors = false

    if (!zone.selected && zone.sensors?.length > 0) {
      const selectedSensors = zone.sensors.filter(({ selected }) => selected)
      if (selectedSensors?.length > 0) {
        hasSelectedSensors = true
        selectedSensors.forEach(({ id, name }) => {
          sensorIds.push(id)
          sensorNames.push(name)
        })
      }
    }

    if ((zone.selected || hasSelectedSensors) && tabId === 'greengrass') {
      zoneIds.push(zone.id)
    }
  })

  if (tabId === 'direct') zoneIds.push(siteId)

  return { sensorIds, sensorNames, zoneIds }
}

export const getGreengrassPayload = (siteId, userId, sensorsByZone) => {
  const coreDevices = sensorsByZone.reduce((acc, zone) => {
    if (!zone.type === 'greengrass') return acc

    const params = {
      deviceIds: [],
      coreDeviceId: zone.id,
      action: 'pingSensors'
    }

    const selectedSensors = zone.sensors
      .filter(({ selected, isGreengrass }) => selected && isGreengrass)
      .map(({ id }) => id)
    params.deviceIds = selectedSensors

    if (zone.selected) {
      params.action = selectedSensors?.length > 0 ? 'pingAll' : 'pingCore'
    }

    if (params?.deviceIds?.length > 0 || zone.selected) acc.push(params)
    return acc
  }, [])

  return {
    siteId,
    userId,
    coreDevices
  }
}

export const getDirectConnectPayload = (siteId, userId, sensorsByZone) => {
  const devices = sensorsByZone.reduce((acc, zone) => {
    if (zone.type === 'direct') {
      const selectedSensors = zone.sensors
        .filter(({ selected, isGreengrass }) => selected && !isGreengrass)
        .map(({ id, sensorType }) => ({
          deviceId: id,
          deviceType: sensorType
        }))
      return [...acc, ...selectedSensors]
    }

    return acc
  }, [])

  return {
    siteId,
    userId,
    devices
  }
}

export function countSensors(availableSensors) {
  const sensorCounts = {
    totalSensors: 0,
    totalGroups: 0
  }

  availableSensors?.forEach(device => {
    const { id, zoneId } = device

    if (!sensorCounts[id]) {
      sensorCounts[id] = 1
      sensorCounts.totalSensors += 1
    } else {
      sensorCounts[id]++
    }

    if (!sensorCounts[zoneId]) {
      sensorCounts[zoneId] = {
        count: 1,
        sensors: [id]
      }
      sensorCounts.totalGroups += 1
    } else {
      sensorCounts[zoneId].count++
      sensorCounts[zoneId].sensors.push(id)
    }
  })

  return sensorCounts
}

export const getBulkActionHeading = ({ value }) => {
  const {
    fleetManagementOtaSensorHeading,
    fleetManagementOtaCoreDeviceHeading,
    fleetManagementCoreHeading,
    fleetManagementNvsHeading,
    fleetManagementPingerHeading,
    fleetManagementSimDiagnosticHeading,
    fleetManagementSimPingHeading
  } = Strings()

  switch (value) {
    case 'ota_sensor':
      return fleetManagementOtaSensorHeading
    case 'ota_core':
      return fleetManagementOtaCoreDeviceHeading
    case 'core':
      return fleetManagementCoreHeading
    case 'nvsdump':
      return fleetManagementNvsHeading
    case 'ping':
      return fleetManagementPingerHeading
    case 'sim_diagnostic_info':
      return fleetManagementSimDiagnosticHeading
    case 'sim_diagnostic_ping':
      return fleetManagementSimPingHeading
    default:
      return ''
  }
}

export const getStatusIcon = status => {
  const buttonSettings = {
    icon: 'help',
    variant: 'neutral',
    text: deviceStatusUnknown
  }

  switch (status) {
    case 'connected':
      buttonSettings.icon = 'check circle'
      buttonSettings.variant = 'success'
      buttonSettings.text = deviceStatusActive
      break
    case 'pending':
      buttonSettings.icon = 'hourglass empty'
      buttonSettings.variant = 'info'
      buttonSettings.text = deviceStatusPending
      break
    case 'unreachable':
      buttonSettings.icon = 'error'
      buttonSettings.variant = 'error'
      buttonSettings.text = deviceStatusUnreachable
      break
    case 'running':
      buttonSettings.icon = 'check circle'
      buttonSettings.variant = 'success'
      buttonSettings.text = deviceStatusRunning
      break
    default:
      break
  }

  return (
    <Button
      iconBefore={buttonSettings.icon}
      size='small'
      variant={buttonSettings.variant}
      className='Status__Icon'
    >
      {buttonSettings.text}
    </Button>
  )
}

export function getSelectedSensors(sensorsByZone) {
  return sensorsByZone.reduce((acc, zone) => {
    const sensorsArgs = zone.sensors.filter(
      ({ selected, sensorType }) => selected && sensorType.includes('cellular')
    )
    return [...acc, ...sensorsArgs]
  }, [])
}

import { fixDate } from '../../utils'

export const UPDATE_INPUT = 'UPDATE_INPUT'
export const SET_STATE = 'SET_STATE'

export const initialState = {
  id: null,
  organizationId: '',
  name: '',
  code: '',
  status: '',
  onboardDate: null,
  contactName: '',
  contactNumber: '',
  contactEmail: '',
  customerId: '',
  countryId: '',
  cityId: '',
  areaId: '',
  regionId: '',
  currencyId: '',
  paymentMethodId: '',
  paymentTermId: ''
}

export function reducer(state, action) {
  const handlers = {
    [SET_STATE]: (state, action) => {
      const newState = { ...action.state }
      newState.onboardDate = fixDate(newState.onboardDate)
      return {
        ...initialState,
        ...newState
      }
    },
    [UPDATE_INPUT]: (state, action) => ({
      ...state,
      [action.name]: action.value
    })
  }
  return handlers[action.type](state, action) || state
}

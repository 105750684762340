import { Router } from 'react-router-dom'

export const capitalizeWord = str => {
  if (str) return str[0].toUpperCase() + str.slice(1)
  return str
}

// https://redux-saga.js.org/docs/introduction/BeginnerTutorial.html
export const delay = (ms = 1000) => new Promise(res => setTimeout(res, ms))

export const getCurrentOS = () => {
  return window.navigator.platform.toLowerCase()
}

export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const generateDownloadCsvLink = (csv, title) => {
  const encodedUri = encodeURI(csv)
  let link = document.createElement('a')
  link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodedUri)
  link.setAttribute('download', title)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const hasSensorsPermissions = (coretexUser, type = 'read') => {
  if (!coretexUser || !coretexUser.permissions) {
    return false
  }

  return coretexUser.permissions.some(
    _ => _.resourceType === 'sensor' && _.operationType === type
  )
}

export function isNotNullOrUndefined(obj) {
  return obj !== null && obj !== undefined
}

export const getValidationErrorMap = error => {
  let errors = {}
  if (error?.inner && error?.inner?.length > 0) {
    error.inner.forEach(innerErr => {
      errors[innerErr.path] = innerErr.message
    })
  }

  return errors
}

/**
 * Logs routing actions
 * @example
 * <DebugRouter history={history}>
 *   <App />
 * </DebugRouter>
 *
 */
export class DebugRouter extends Router {
  constructor(props) {
    /* eslint-disable no-console */
    super(props)
    console.log('initial history is: ', JSON.stringify(props.history, null, 2))
    props.history.listen((location, action) => {
      console.log(
        `The current URL is ${location.pathname}${location.search}${location.hash}`
      )
      console.log(
        `The last navigation action was ${action}`,
        JSON.stringify(props.history, null, 2)
      )
    })
    /* eslint-enable no-console */
  }
}

export const hashString = string => {
  let h
  const sl = string.length
  for (let i = 0; i < sl; i++) {
    h = (Math.imul(31, h) + string.charCodeAt(i)) | 0
  }
  return Math.abs(h).toString(36)
}

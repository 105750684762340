import {
  CLEAR_PACKAGE_UNIT_INVENTORIES,
  REQUEST_PACKAGE_UNIT_INVENTORIES,
  RECEIVE_PACKAGE_UNIT_INVENTORIES,
  REQUEST_PACKAGE_UNIT_INVENTORY,
  RECEIVE_PACKAGE_UNIT_INVENTORY
} from '@/actions/operations/packageUnitInventory'

export const packageUnitInventoryInitialState = {
  packageUnitInventories: [],
  packageUnitInventoriesCount: 0,
  packageUnitInventory: {},
  loading: false,
  error: null
}

const handlers = {
  [CLEAR_PACKAGE_UNIT_INVENTORIES]: (state, action) => ({
    ...state,
    packageUnitInventories: [],
    packageUnitInventoriesCount: 0,
    error: null
  }),
  [REQUEST_PACKAGE_UNIT_INVENTORIES]: (state, action) => ({
    ...state,
    packageUnitInventories: [],
    packageUnitInventoriesCount: 0,
    error: null,
    loading: true
  }),
  [RECEIVE_PACKAGE_UNIT_INVENTORIES]: (state, action) => ({
    ...state,
    packageUnitInventories: !action.error ? action.packageUnitInventories : [],
    packageUnitInventoriesCount: action?.count ?? 0,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_PACKAGE_UNIT_INVENTORY]: (state, action) => ({
    ...state,
    packageUnitInventory: {},
    error: null,
    loading: true
  }),
  [RECEIVE_PACKAGE_UNIT_INVENTORY]: (state, action) => ({
    ...state,
    packageUnitInventory: !action.error ? action.packageUnitInventory : {},
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

import { I18n } from 'aws-amplify'

import Strings from '../Strings'

const strings = Strings()
const gmtString = I18n.get('GMT')

export const getZoneIdsFromPath = zonePath => {
  if (zonePath?.length > 0) {
    const idsArr = zonePath.split('/')
    idsArr.splice(0, 2)
    return idsArr
  }

  return []
}

export const getOrganizationName = (organizations, organizationId) => {
  for (let organization of organizations) {
    if (organization.id === organizationId) {
      return organization.name
    }
  }
  return null
}

export const getColumnNames = keys => {
  return keys.map(key => {
    if (key.includes('weightGrade')) {
      const grade = key.split('-')[1]
      return `${strings.grade} ${grade}`
    }

    return strings[key] ?? key
  })
}

export const generateZoneIds = (allZoneIds, zoneHierarchy) => {
  allZoneIds.push(zoneHierarchy.id)
  Object.keys(zoneHierarchy?.children).forEach(key => {
    const childZone = zoneHierarchy?.children[key]
    generateZoneIds(allZoneIds, childZone)
  })
}

export const generateDate = value => {
  if (value) {
    const [date, time] = value.toISOString().split('T')
    return `${date} (${time.split('.')[0]} ${gmtString})`
  }
}

const oneDay = 86400 * 1000
const chartCategories = [
  {
    statusDate: 'createdAt',
    value: 10
  },
  {
    statusDate: 'sowDate',
    value: 20
  },
  {
    statusDate: 'transplantDate',
    value: 30
  },
  {
    statusDate: 'harvestStartDate',
    value: 40
  },
  {
    statusDate: 'harvestEndDate',
    value: 50
  }
]

const getStatusValue = (currentDayStart, crop) => {
  let yValue = 0

  chartCategories.forEach(({ statusDate, value }) => {
    if (statusDate && crop[statusDate]) {
      const applyStatus = crop[statusDate] - currentDayStart < oneDay
      if (applyStatus) yValue = value
    }
  })

  return yValue
}

export const createRowCells = (crop, cropArgs) => {
  const [startDate, endDate] = [...cropArgs?.dates]
  const dates = [new Date(startDate).valueOf(), new Date(endDate).valueOf()]
  const daysCount = Math.floor((dates[1] - dates[0]) / oneDay)

  let row = []
  let currentDayStart = dates[0]

  for (let i = 0; i <= daysCount; i++) {
    const yValue = getStatusValue(currentDayStart, crop)
    const cell = { x: new Date(currentDayStart).toDateString(), y: yValue }
    row.push(cell)
    currentDayStart += oneDay
  }

  return row
}

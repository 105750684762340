import { makeFertigationApiCall } from './utils'

/* QUERIES */

export const getFertilizers = async params => {
  try {
    const query = /* GraphQL */ `
      query getFertilizers(
        $limit: Int
        $offset: Int
        $order: OrderEnum
        $orderBy: String
      ) {
        getFertilizers(
          limit: $limit
          offset: $offset
          order: $order
          orderBy: $orderBy
        ) {
          fertilizers {
            id
            name
            nutrients
            additives
          }
          count
        }
      }
    `

    const { data, error } = await makeFertigationApiCall({
      query,
      params,
      queryKey: 'getFertilizers',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

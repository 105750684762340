import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {
  getManagersByZoneId,
  createManager,
  updateManager,
  deleteManager
} from '@/api/manager'

export const setManager = createAsyncThunk('setManager', manager => {
  return { manager }
})

export const fetchManagersByZoneId = createAsyncThunk(
  'fetchManagersByZoneId',
  async params => {
    const { managers, error } = await getManagersByZoneId(params)
    return { managers, error }
  }
)

export const sendCreateManager = createAsyncThunk(
  'sendCreateManager',
  async params => {
    const { manager, error } = await createManager(params)
    return { manager, error }
  }
)

export const sendUpdateManager = createAsyncThunk(
  'sendUpdateManager',
  async params => {
    const { manager, error } = await updateManager(params)
    return { manager, error }
  }
)

export const sendDeleteManager = createAsyncThunk(
  'sendDeleteManager',
  async params => {
    const { manager, error } = await deleteManager(params)
    return { manager, error }
  }
)

const managerSlice = createSlice({
  name: 'managerReducer',
  initialState: {
    managers: [],
    manager: {},
    error: null,
    loading: false,
    saving: false
  },
  extraReducers: builder => {
    builder
      .addCase(setManager.fulfilled, (state, action) => {
        state.error = null
        state.manager = action?.payload?.manager ?? {}
      })
      .addCase(fetchManagersByZoneId.pending, (state, action) => {
        state.error = null
        state.loading = true
        state.managers = []
      })
      .addCase(fetchManagersByZoneId.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.loading = false
        state.managers = action?.payload?.managers
      })
      .addCase(sendCreateManager.pending, (state, action) => {
        state.error = null
        state.saving = true
        state.manager = {}
      })
      .addCase(sendCreateManager.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.saving = false
        state.manager = action?.payload?.manager
        state.managers = action?.payload?.error
          ? state.managers
          : [...state.managers, action?.payload?.manager]
      })
      .addCase(sendUpdateManager.pending, (state, action) => {
        state.error = null
        state.saving = true
      })
      .addCase(sendUpdateManager.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.saving = false
        state.manager = action?.payload?.manager
        state.managers = action?.payload?.error
          ? state.managers
          : state.managers.map(manager => {
              if (manager.userId === action?.payload?.manager?.userId) {
                return action?.payload?.manager
              }

              return manager
            })
      })
      .addCase(sendDeleteManager.pending, (state, action) => {
        state.error = null
        state.saving = true
      })
      .addCase(sendDeleteManager.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.saving = false
        state.manager = action?.payload?.manager ?? state.manager
        state.managers = action?.payload?.error
          ? state.managers
          : state.managers.filter(
              ({ userId }) => userId !== action?.payload?.manager?.userId
            )
      })
  }
})

export default managerSlice.reducer

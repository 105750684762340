import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { getSiteOfflineDevices } from '@/api/diagnostics/device_is_offline'

export const fetchSiteOfflineDevices = createAsyncThunk(
  'fetchSiteOfflineDevices',
  async (params, { dispatch }) => {
    let response = {}
    const { siteIds } = params
    for (const siteId of siteIds) {
      const devices = await getSiteOfflineDevices({ siteId })
      response[siteId] = devices
    }

    return response
  }
)

const diagnosticsDeviceIsOfflineSlice = createSlice({
  name: 'diagnosticsDeviceIsOfflineReducer',
  initialState: {
    devices: {},
    loading: false
  },
  reducers: {
    cleanOfflineDevices: (state, action) => {
      state.devices = {}
      state.loading = false
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSiteOfflineDevices.pending, state => {
        state.loading = true
      })
      .addCase(fetchSiteOfflineDevices.fulfilled, (state, action) => {
        state.devices = action.payload
        state.loading = false
      })
  }
})

const { actions, reducer } = diagnosticsDeviceIsOfflineSlice

export const { cleanOfflineDevices } = actions

export default reducer

import { makeOperationsApiCall } from './utils'

export const getBranches = async params => {
  try {
    const query = /* GraphQL */ `
      query getBranches(
        $filter: GetBranchesFilter
        $order: OrderEnum
        $offset: Int
        $limit: Int
      ) {
        getBranches(
          filter: $filter
          order: $order
          offset: $offset
          limit: $limit
        ) {
          branches {
            id
            organizationId
            name
            code
            status
            onboardDate
            customerId
            customer {
              name
            }
            country {
              name
            }
            region {
              name
            }
            city {
              name
            }
            area {
              name
            }
            contactName
            contactNumber
            contactEmail
            currencyId
            paymentMethodId
            paymentTermId
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getBranches',
      resultKey: 'data'
    })
    return { data: data ?? [], error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getBranch = async params => {
  try {
    const query = /* GraphQL */ `
      query getBranch($branchId: String!) {
        getBranch(branchId: $branchId) {
          id
          organizationId
          name
          code
          status
          onboardDate
          customerId
          countryId
          regionId
          cityId
          areaId
          contactName
          contactNumber
          contactEmail
          currencyId
          paymentMethodId
          paymentTermId
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getBranch',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const createBranch = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createBranch(
        $name: String!
        $code: String!
        $status: BranchStatus!
        $onboardDate: GraphQLTimestamp!
        $organizationId: String!
        $customerId: String!
        $countryId: String!
        $regionId: String!
        $cityId: String!
        $areaId: String!
        $contactName: String!
        $contactNumber: String!
        $contactEmail: String!
        $currencyId: String!
        $paymentMethodId: String!
        $paymentTermId: String!
      ) {
        createBranch(
          name: $name
          code: $code
          status: $status
          onboardDate: $onboardDate
          organizationId: $organizationId
          customerId: $customerId
          countryId: $countryId
          regionId: $regionId
          cityId: $cityId
          areaId: $areaId
          contactName: $contactName
          contactNumber: $contactNumber
          contactEmail: $contactEmail
          currencyId: $currencyId
          paymentMethodId: $paymentMethodId
          paymentTermId: $paymentTermId
        ) {
          id
          organizationId
          name
          code
          status
          onboardDate
          customer {
            name
          }
          country {
            name
          }
          region {
            name
          }
          city {
            name
          }
          area {
            name
          }
          contactName
          contactNumber
          contactEmail
          currencyId
          paymentMethodId
          paymentTermId
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'createBranch',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updateBranch = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateBranch(
        $branchId: String!
        $name: String!
        $code: String!
        $status: BranchStatus!
        $onboardDate: GraphQLTimestamp!
        $organizationId: String!
        $customerId: String!
        $countryId: String!
        $regionId: String!
        $cityId: String!
        $areaId: String!
        $contactName: String!
        $contactNumber: String!
        $contactEmail: String!
        $currencyId: String!
        $paymentMethodId: String!
        $paymentTermId: String!
      ) {
        updateBranch(
          branchId: $branchId
          name: $name
          code: $code
          status: $status
          onboardDate: $onboardDate
          organizationId: $organizationId
          customerId: $customerId
          countryId: $countryId
          regionId: $regionId
          cityId: $cityId
          areaId: $areaId
          contactName: $contactName
          contactNumber: $contactNumber
          contactEmail: $contactEmail
          currencyId: $currencyId
          paymentMethodId: $paymentMethodId
          paymentTermId: $paymentTermId
        ) {
          id
          organizationId
          name
          code
          status
          onboardDate
          customer {
            name
          }
          country {
            name
          }
          region {
            name
          }
          city {
            name
          }
          area {
            name
          }
          contactName
          contactNumber
          contactEmail
          currencyId
          paymentMethodId
          paymentTermId
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'updateBranch',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

import { I18n } from 'aws-amplify'
import { object, string, date, array, number } from 'yup'
import { weightSchema } from '../../../Shared/Weights/utils'

export const FIELDS = {
  id: 'id',
  organizationId: 'organizationId',
  weights: 'weights',
  totalWeight: 'totalWeight',
  harvestStartDate: 'harvestStartDate',
  harvestEndDate: 'harvestEndDate',
  produceId: 'produceId',
  varietyId: 'varietyId',
  currencyId: 'currencyId',
  targetPrice: 'targetPrice',
  status: 'status'
}

export const SCHEMA = () => {
  return object().shape({
    organizationId: string().required(
      I18n.get('An organization must be selected.')
    ),
    zoneId: string()
      .nullable()
      .required(I18n.get('A location must be selected.')),
    produceId: string().required(I18n.get('Produce is required.')),
    varietyId: string().required(I18n.get('Variety is required.')),
    status: string().required('Please select a status.'),
    harvestStartDate: date()
      .typeError('An estimated harvest start date is required.')
      .required(I18n.get('An estimated harvest start date is required.')),
    harvestEndDate: date()
      .typeError('An estimated harvest end date is required.')
      .required(I18n.get('An estimated harvest end date is required.')),
    weights: array()
      .of(weightSchema)
      .min(1, I18n.get('At least one estimated weight is required.')),
    currencyId: string().required(I18n.get('Currency is required.')),
    targetPrice: number()
      .typeError('A target price is required.')
      .min(0.01, I18n.get('The target price must be more than zero.'))
      .required(I18n.get('A target price is required.'))
  })
}

import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { fetchCurrentData } from '@/actions/ts/dashboard'
import {
  getDashboardDataSeed,
  getDashboardData,
  getZoneHierarchyLoading
} from '@/reducers/selectors'

import { useDashboard } from '@/components/DashboardPageV2/Desktop/context'
import { useDashboardParams } from '@/components/DashboardPageV2/utils/useDashboardParams'

export function useLiveData({
  measurements,
  includeSensor = true,
  includeOrganizationId = true
}) {
  if (!measurements) {
    throw new Error('useLiveData requires a measurements array')
  }

  const stringifiedMeasurements = JSON.stringify(measurements)

  const dispatch = useDispatch()
  const dashboardData = getDashboardData()
  const dashboardDataSeed = getDashboardDataSeed()
  const params = useDashboardParams()
  const { state } = useDashboard()
  const zoneHierarchyLoading = getZoneHierarchyLoading()

  const [liveData, setLiveData] = useState({})

  const [prevDashboardDataSeed, setPrevDashboardDataSeed] = useState(null)

  const { currentZone, siteId, hierarchy } = state

  useEffect(() => {
    // Fetches current data for the measurement
    // Backend decides if it uses cached data or not
    if (currentZone.id && siteId && hierarchy && !zoneHierarchyLoading) {
      const measurements = JSON.parse(stringifiedMeasurements)

      const payload = {
        table: siteId,
        sensorId: null,
        measurements
      }

      if (includeSensor) {
        payload.sensorId = params?.sensorId || currentZone.id
      }

      if (includeOrganizationId) {
        payload.organizationId = hierarchy.organizationId
      }

      const currentDevices = currentZone?.devices || []
      const device = currentDevices.find(({ id }) => id === params?.sensorId)
      if (device && device?.sensorType?.includes('cellular')) {
        payload.isCellular = true
      }

      dispatch(fetchCurrentData(payload))
    }
  }, [
    currentZone.id,
    siteId,
    hierarchy,
    params.sensorId,
    zoneHierarchyLoading,
    stringifiedMeasurements,
    includeSensor,
    includeOrganizationId,
    currentZone?.devices,
    dispatch
  ])

  if (dashboardDataSeed !== prevDashboardDataSeed) {
    setPrevDashboardDataSeed(dashboardDataSeed)

    let data = {}
    const dataId = params?.sensorId || currentZone.id
    for (const measurement of measurements) {
      data[measurement] =
        dashboardData?.[dataId]?.[measurement]?.current || null
    }
    setLiveData(data)
  }

  return liveData
}

import { makeActionCreator } from '@/Util/ReduxUtils'

export const CLEAR_RETURN = 'CLEAR_RETURN'
export const clearReturn = makeActionCreator(CLEAR_RETURN)

export const CREATE_RETURN = 'CREATE_RETURN'
export const createReturn = makeActionCreator(CREATE_RETURN, 'params')

export const RETURN_CREATED = 'RETURN_CREATED'
export const returnCreated = makeActionCreator(
  RETURN_CREATED,
  'return',
  'error'
)

export const UPDATE_RETURN = 'UPDATE_RETURN'
export const updateReturn = makeActionCreator(UPDATE_RETURN, 'params')

export const RETURN_UPDATED = 'RETURN_UPDATED'
export const returnUpdated = makeActionCreator(
  RETURN_UPDATED,
  'return',
  'error'
)

export const REQUEST_RETURNS = 'REQUEST_RETURNS'
export const requestReturns = makeActionCreator(REQUEST_RETURNS, 'params')

export const RECEIVE_RETURNS = 'RECEIVE_RETURNS'
export const receiveReturns = makeActionCreator(
  RECEIVE_RETURNS,
  'returns',
  'count',
  'error'
)

export const REQUEST_RETURN = 'REQUEST_RETURN'
export const requestReturn = makeActionCreator(REQUEST_RETURN, 'params')

export const RECEIVE_RETURN = 'RECEIVE_RETURN'
export const receiveReturn = makeActionCreator(
  RECEIVE_RETURN,
  'return',
  'error'
)

export const REQUEST_RETURN_ITEMS = 'REQUEST_RETURN_ITEMS'
export const requestReturnItems = makeActionCreator(
  REQUEST_RETURN_ITEMS,
  'params'
)

export const RECEIVE_RETURN_ITEMS = 'RECEIVE_RETURN_ITEMS'
export const receiveReturnItems = makeActionCreator(
  RECEIVE_RETURN_ITEMS,
  'returnItems',
  'count',
  'error'
)

export const CREATE_RETURN_ITEM = 'CREATE_RETURN_ITEM'
export const createReturnItem = makeActionCreator(CREATE_RETURN_ITEM, 'params')

export const UPDATE_RETURN_ITEM = 'UPDATE_RETURN_ITEM'
export const updateReturnItem = makeActionCreator(UPDATE_RETURN_ITEM, 'params')

export const DELETE_RETURN_ITEM = 'DELETE_RETURN_ITEM'
export const deleteReturnItem = makeActionCreator(DELETE_RETURN_ITEM, 'params')

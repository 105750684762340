const styleCache = new Set()

/**
 * @param {CSSStyleSheet} sheet
 */
const updateStyleCache = sheet => {
  if (sheet?.cssRules?.length) {
    const rules = [...sheet.cssRules]
    rules.forEach(rule => {
      if (rule.cssRules) {
        updateStyleCache(rule)
      } else {
        styleCache.add(rule.selectorText)
      }
    })
  }
}

/**
 * @param {CSSStyleSheet} sheet
 * @param {Object} styles
 */
const sync = (sheet, styles) => {
  if (sheet?.cssRules && styles?.length) {
    updateStyleCache(sheet)
    styles.forEach(style => {
      if (!styleCache.has(style.selectorText)) {
        sheet.insertRule(style.cssText, sheet.cssRules.length)
        styleCache.add(style.selectorText)
      }
    })
  }
}

/**
 * @param {CSSStyleSheet} sheet
 * @param {Object} styles
 */

const stylesync = (sheet, styles) => {
  if (sheet && styles) {
    requestAnimationFrame(() => {
      sync(sheet, styles)
    })
  }
}
export default stylesync

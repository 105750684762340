import { DateTime } from 'luxon'
import { Canvg } from 'canvg'
import {
  CURRENT_YEAR,
  LAST_24_HOURS,
  getRanges,
  TODAY
} from '../Dynamic/config'

import { widgetRegistry } from '@/components/DashboardPageV2/Desktop/Widgets'

export function formatDate(date, timezone) {
  return DateTime.fromJSDate(date, { zone: timezone }).toFormat(
    'yyyy/MM/dd HH:mm'
  )
}

export function createDownloadableDataset(id, graphData, widgetId, unit) {
  const defintion = widgetRegistry.get(widgetId)
  let title = defintion.title
  if (typeof title === 'function') {
    title = title()
  }
  let measurementTitle = `${title} (${unit})`

  return {
    id,
    measurement: defintion.options.measurement,
    graphData,
    measurementTitle
  }
}

const rangeLuxonMap = {
  h: 'hours',
  d: 'days',
  M: 'months',
  y: 'years',
  m: 'minutes',
  w: 'weeks'
}

const pageThemeVariableCandidates = [
  '--ctx-theme-color-page-100',
  '--ctx-theme-color-page-200',
  '--ctx-theme-color-page-300',
  '--ctx-theme-color-page-400',
  '--ctx-theme-color-page-500',
  '--ctx-theme-color-page-600',
  '--ctx-theme-color-page-700',
  '--ctx-theme-color-page-800',
  '--ctx-theme-color-page-900'
]

export const createDownloadableFilename = (range, interval, measurement) => {
  const [hour, unit] = getRanges(range)?.query ?? []
  const luxonKey = rangeLuxonMap[unit]

  const now = DateTime.local()
  if (range === CURRENT_YEAR) {
    const start = now.startOf('year')
    const end = now

    const startString = start.toFormat('yyyy-MM-dd')
    const endString = end.toFormat('yyyy-MM-dd')

    return `${measurement}_${startString}_${endString}_${interval}-${range}`
  }

  if (range === TODAY) {
    const start = now.startOf('day')
    const end = now

    const startString = start.toFormat('yyyy-MM-dd')
    const endString = end.toFormat('yyyy-MM-dd')

    return `${measurement}_${startString}_${endString}_${interval}-${range}`
  }

  if (range === LAST_24_HOURS) {
    const start = now.minus({ hours: 24 })
    const end = now

    const startString = start.toFormat('yyyy-MM-dd-HH-mm')
    const endString = end.toFormat('yyyy-MM-dd-HH-mm')

    return `${measurement}_${startString}_${endString}_${interval}-${range}`
  }

  const format = luxonKey === 'hours' ? 'yyyy-MM-dd-HH-mm' : 'yyyy-MM-dd'

  const start = now.minus({ [luxonKey]: hour })
  const end = now

  const startString = start.toFormat(format)
  const endString = end.toFormat(format)

  return `${measurement}_${startString}_${endString}_${interval}-${range}`
}

export const createDownloadableImage = async (
  widgetProps,
  definition,
  unit
) => {
  const { config, chartSvg } = widgetProps

  // create local theme
  const cssVariables = window.getComputedStyle(document.documentElement)

  // local string of the chartSVG (querySelector can't select in style attributes... so we have to use strings)
  let svg = chartSvg.outerHTML

  // create a canvas element
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  const padding = 50 // Padding size

  // Update canvas dimensions
  canvas.width = Number(chartSvg.getAttribute('width'))
  canvas.height = Number(chartSvg.getAttribute('height')) + padding

  // Render the SVG on the canvas
  ctx.fillStyle = cssVariables.getPropertyValue('--ctx-theme-color-page-200')
  ctx.fillRect(0, 0, canvas.width, canvas.height)

  // Set a default fillColor after canvas clear
  ctx.fillStyle = cssVariables.getPropertyValue('--ctx-theme-contrast-page-200')

  // Process CSS Variables inside SVG (Canvg does not lookup css variables)
  pageThemeVariableCandidates.forEach((cssVariable)=>{
    const regxp = new RegExp(`var\\(${cssVariable}\\)`, 'gm')
    svg = svg.replace(regxp, cssVariables.getPropertyValue(cssVariable))
  })

  if(svg.length){
    const v = Canvg.fromString(ctx, svg, {
      ignoreDimensions: true,
      ignoreClear: true,
      offsetX: 0,
      offsetY: padding,
      scaleWidth: canvas.width,
      scaleHeight: canvas.height - padding
    })
  
    v.start()
    v.stop()
  }


  // Add title at the top-left
  ctx.fillStyle = cssVariables.getPropertyValue('--ctx-theme-contrast-page-200')
  ctx.font = '18px Inter, system-ui, sans-serif'

  let title = config?.title || definition.title

  if (typeof title === 'function') {
    title = title()
  }

  const textHorizontalPadding = 35
  ctx.fillText(title, textHorizontalPadding, 30)

  // Add unit at the top-right
  let unitTextWidth = ctx.measureText(unit).width
  let unitPosition = canvas.width - unitTextWidth - textHorizontalPadding
  ctx.fillText(unit, unitPosition, 30)

  const img = canvas.toDataURL()

  return img
}

export const createDownloadableCsv = (dataset, timezone, siteName) => {
  const headers = ['Date']
  const headersIndex = {}
  const measurementRow = [timezone]
  const measurementRowIndex = {}
  const rows = {}

  const { graphData, measurementTitle } = dataset

  for (let data of graphData) {
    const { id: zoneName, data: values } = data
    const headerId = `${siteName}/${zoneName}`

    headers.push(headerId)
    headersIndex[headerId] = headers.length - 1

    measurementRow.push(measurementTitle)
    measurementRowIndex[measurementTitle] = measurementRow.length - 1

    for (let value of values) {
      const formattedDate = formatDate(new Date(value.x), timezone)
      const row = rows[formattedDate] || new Array(headers.length - 1).fill('')

      const headerId = `${siteName}/${zoneName}`
      const columnIndex = headersIndex[headerId]
      row[columnIndex - 1] = value.y

      rows[formattedDate] = row
    }
  }

  for (let date in rows) {
    while (rows[date].length < headers.length - 1) {
      rows[date].push('')
    }
  }

  const csvContent = [
    headers.join(','),
    measurementRow.join(','),
    ...Object.entries(rows)
      .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))
      .map(([date, values]) => [date, ...values].join(','))
  ].join('\n')

  return csvContent
}

export function promptDownload(href, fileName) {
  const element = document.createElement('a')
  element.href = href
  element.download = fileName
  document.body.appendChild(element)
  element.click()
}

import { useState } from 'react'
import { I18n } from 'aws-amplify'

import { getWeatherForecast, getLang, getRootZone, getCurrentUser } from '@/reducers/selectors'
import { Flex, Box, Text } from '@/primitives'
import { getWeatherIcon, getTileHeader } from '../../../utils'
import {
  SETTING_CELCIUS_TO_FAHRENHEIT,
  processSettings
} from '@/Util/SettingsUtils'
import { convertCelciusToFahrenheit, FAHRENHEIT_UNIT } from '@/Util/MeasurementUtils'

import './index.scss'

const intervals = { hourly: 'hourlyForecast', daily: 'dailyForecast' }

const WeatherForecast = () => {
  const weatherData = getWeatherForecast()
  const lang = getLang()
  const rootZone = getRootZone()
  const currentUser = getCurrentUser()

  const { hourly, daily } = intervals
  const [timeInterval, setTimeInterval] = useState(hourly)
  const hasWeatherData = weatherData[timeInterval]?.length > 0

  const onClickTodayTab = () => {
    if (timeInterval !== hourly) setTimeInterval(hourly)
  }

  const onClickWeekTab = () => {
    if (timeInterval !== daily) setTimeInterval(daily)
  }

  const enableFahrenheit = processSettings(
    currentUser?.settings,
    SETTING_CELCIUS_TO_FAHRENHEIT,
    currentUser.userName,
    rootZone?.organizationId
  )

  function getTemp(tempCelsius) {
    const temperature = enableFahrenheit ? convertCelciusToFahrenheit(tempCelsius) : tempCelsius
    return Math.round(temperature)
  }

  return (
    <Box className='WeatherWidget'>
      <div className='WeatherWidget__TabsContainer'>
        <div
          onClick={onClickTodayTab}
          className={`WeatherWidget__TabHeader ${
            timeInterval === hourly && 'Selected'
          }`}
        >
          {I18n.get('Today').toUpperCase()}
        </div>
        <div
          onClick={onClickWeekTab}
          className={`WeatherWidget__TabHeader ${
            timeInterval === daily && 'Selected'
          }`}
        >
          {I18n.get('This week').toUpperCase()}
        </div>
      </div>
      {hasWeatherData && (
        <Flex axisGap={300} wrap='nowrap' alignMainAxis='stretch'>
          {weatherData[timeInterval]?.map(
            (
              { weatherAt, temperature, conditionCode, sunriseAt, sunsetAt },
              index
            ) => {
              if (index > 6) return null
              return (
                <Flex
                  axisGap={500}
                  alignMainAxis='center'
                  className='WeatherWidget__Tile'
                  key={weatherAt}
                >
                  <Text>
                    {getTileHeader(
                      weatherAt,
                      timeInterval === hourly,
                      lang,
                      rootZone?.timeZone
                    )}
                  </Text>
                  {getWeatherIcon(
                    conditionCode,
                    weatherAt,
                    sunriseAt,
                    sunsetAt
                  )}
                  <Text className='WeatherWidget__Tile__Temperature'>
                  {`${getTemp(temperature)} ${enableFahrenheit ? FAHRENHEIT_UNIT : '°C'}`}
                  </Text>
                </Flex>
              )
            }
          )}
        </Flex>
      )}
      {!hasWeatherData && <Text>{I18n.get('No data available')}</Text>}
    </Box>
  )
}

export default WeatherForecast

import { I18n } from 'aws-amplify'
import { object, string, number, date, array } from 'yup'
import { inventoryWeightSchema } from '../../../Shared/InventoryWeights/utils'

export const FIELDS = {
  id: 'id',
  organizationId: 'organizationId',
  fertilizerInventoryId: 'fertilizerInventoryId',
  fromStorageFacilityId: 'fromStorageFacilityId',
  shippingMethodId: 'shippingMethodId',
  storageFacilityId: 'storageFacilityId',
  fertilizerId: 'fertilizerId',
  status: 'status',
  totalWeight: 'totalWeight',
  shippedDate: 'shippedDate',
  deliveredDate: 'deliveredDate'
}

export const SCHEMA = () => {
  return object().shape({
    organizationId: string().required(I18n.get('An organization is required.')),
    fromStorageFacilityId: string().required(
      I18n.get('Please select the origin inventory.')
    ),
    fertilizerId: string().required(I18n.get('Please select a fertilizer.')),
    shippingMethodId: string().required(
      I18n.get('Please select an origin shipping method.')
    ),
    storageFacilityId: string().required(
      I18n.get('Please select a destination storage facility.')
    ),
    status: string().required(I18n.get('Please select a status.')),
    totalWeight: number()
      .typeError(I18n.get('Total Weight is required.'))
      .min(1, I18n.get('Total Weight cannot be less than one.'))
      .required(I18n.get('Total Weight is required.')),
    weights: array()
      .of(inventoryWeightSchema)
      .min(1, I18n.get('At least one weight is required.')),
    shippedDate: date()
      .nullable()
      .default(null)
      .typeError(I18n.get('Shipped date is invalid.'))
      .max(
        new Date(),
        I18n.get('The shipped date cannot be set in the future.')
      ),
    deliveredDate: date()
      .nullable()
      .default(null)
      .typeError(I18n.get('Delivered date is invalid.'))
      .max(
        new Date(),
        I18n.get('The delivered date cannot be set in the future.')
      )
  })
}

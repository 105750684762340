import withEditableWidget from '../Shared/HOC/withEditableWidget'
import { WidgetDefinition } from '../Shared/utils/WidgetRegistry'

import { I18n } from 'aws-amplify'
import { WeatherEditComponent } from './WeatherEdit'
import { WeatherViewComponent } from './WeatherView'
import { HOURLY_WEATHER, NEXT_7_DAYS, TODAY } from './utils'

function WeatherView(props) {
  return <WeatherViewComponent {...props} />
}

function WeatherEdit(props) {
  return <WeatherEditComponent {...props} />
}

const WeatherWidget = withEditableWidget(WeatherView, WeatherEdit)

export const WeatherDefinition = () => {
  return new WidgetDefinition({
    widgetId: 'weather',
    title: I18n.get('Weather'),
    icon: 'clear_day',
    component: WeatherWidget,
    defaultPosition: {
      md: [0, 0, 8, 4, 8, 4],
      lg: [0, 0, 4, 4, 4, 4],
      xl: [0, 0, 4, 4, 4, 4]
    },
    options: {
      range: [TODAY, NEXT_7_DAYS]
    },
    config: {
      range: TODAY,
      interval: HOURLY_WEATHER
    }
  })
}

import { makeActionCreator } from '@/Util/ReduxUtils'

export const CREATE_CUSTOMER = 'CREATE_CUSTOMER'
export const createCustomer = makeActionCreator(CREATE_CUSTOMER, 'params')

export const CUSTOMER_CREATED = 'CUSTOMER_CREATED'
export const customerCreated = makeActionCreator(
  CUSTOMER_CREATED,
  'customer',
  'error'
)

export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER'
export const updateCustomer = makeActionCreator(UPDATE_CUSTOMER, 'params')

export const CUSTOMER_UPDATED = 'CUSTOMER_UPDATED'
export const customerUpdated = makeActionCreator(
  CUSTOMER_UPDATED,
  'customer',
  'error'
)

export const REQUEST_CUSTOMERS = 'REQUEST_CUSTOMERS'
export const requestCustomers = makeActionCreator(REQUEST_CUSTOMERS, 'params')

export const RECEIVE_CUSTOMERS = 'RECEIVE_CUSTOMERS'
export const receiveCustomers = makeActionCreator(
  RECEIVE_CUSTOMERS,
  'customers',
  'count',
  'error'
)

export const REQUEST_CUSTOMER = 'REQUEST_CUSTOMER'
export const requestCustomer = makeActionCreator(REQUEST_CUSTOMER, 'params')

export const RECEIVE_CUSTOMER = 'RECEIVE_CUSTOMER'
export const receiveCustomer = makeActionCreator(
  RECEIVE_CUSTOMER,
  'customer',
  'error'
)

function getLogType({ authFlow, errorCode }) {
  if (authFlow === 'SIGN_IN') {
    return errorCode ? 'unsuccessfulLogins' : 'successfulLogins'
  }

  return 'activeSessions'
}

export function sortCognitoLogs(logs) {
  logs.sort((a, b) => {
    return a.timestamp < b.timestamp ? 1 : -1
  })

  const initialValue = {
    successfulLogins: [],
    unsuccessfulLogins: [],
    activeSessions: []
  }

  return logs.reduce((logsObject, currentLog) => {
    const logType = getLogType(currentLog)
    const newLogsObject = { ...logsObject }
    newLogsObject[logType].push(currentLog)
    return newLogsObject
  }, initialValue)
}

export function cleanAuditLogNextToken(nextToken) {
  for (let key in nextToken) {
    if (nextToken[key] === null) {
      delete nextToken[key]
    }
  }

  return nextToken
}

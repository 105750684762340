import { makeOperationsApiCall } from './utils'

/* MUTATIONS */

export const createVariety = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createVariety(
        $organizationId: String!
        $produceId: String!
        $name: String!
        $code: String!
      ) {
        createVariety(
          organizationId: $organizationId
          produceId: $produceId
          name: $name
          code: $code
        ) {
          id
          organizationId
          name
          code
          produce {
            id
            name
          }
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'createVariety',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updateVariety = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateVariety(
        $varietyId: String!
        $organizationId: String!
        $produceId: String!
        $name: String!
        $code: String!
      ) {
        updateVariety(
          varietyId: $varietyId
          organizationId: $organizationId
          produceId: $produceId
          name: $name
          code: $code
        ) {
          id
          organizationId
          name
          code
          produce {
            id
            name
          }
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'updateVariety',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

/* QUERIES */

export const getVarieties = async params => {
  try {
    const query = /* GraphQL */ `
      query getVarieties(
        $filter: GetVarietiesFilter
        $limit: Int
        $offset: Int
        $order: OrderEnum
      ) {
        getVarieties(
          filter: $filter
          limit: $limit
          offset: $offset
          order: $order
        ) {
          varieties {
            id
            organizationId
            name
            code
            produce {
              id
              name
            }
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getVarieties',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getVariety = async params => {
  try {
    const query = /* GraphQL */ `
      query getVariety($varietyId: String!) {
        getVariety(varietyId: $varietyId) {
          id
          organizationId
          produceId
          name
          code
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getVariety',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

import { useEffect, useState } from 'react'
import { I18n } from 'aws-amplify'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { string, object } from 'yup'

import {
  Text,
  Slot,
  List,
  Item,
  Loader,
  Form,
  Flex,
  Label,
  Button,
  Input
} from '@/primitives'
import { Dialog } from '@/elements'

import {
  fetchFactorySupplierUsers,
  sendCreateFactorySupplierUser,
  sendDeleteFactorySupplierUser,
  clearUsers,
  clearUser
} from '@/slices/management/supplier'

import { showBanner } from '@/slices/util'

import {
  getFactorySupplierUsers,
  getFactorySupplierUser,
  getFactorySupplierUserDeleted,
  getFactorySuppliersLoading,
  getFactorySuppliersSaving
} from '@/reducers/selectors'

import Strings from './Strings'

import './modal-users.scss'

const userSchema = object().shape({
  email: string().trim().required(I18n.get('Please enter an email'))
})

const ModalUsers = () => {
  const strings = Strings()

  const history = useHistory()
  const dispatch = useDispatch()
  const { supplierId } = useParams()
  const [email, setEmail] = useState('')

  const users = getFactorySupplierUsers()
  const user = getFactorySupplierUser()
  const userDeleted = getFactorySupplierUserDeleted()
  const isLoading = getFactorySuppliersLoading()
  const saving = getFactorySuppliersSaving()

  useEffect(() => {
    if (supplierId) {
      dispatch(fetchFactorySupplierUsers({ supplierId }))
    }
  }, [supplierId, dispatch])

  useEffect(() => {
    if (user?.userId) {
      dispatch(clearUser())
      dispatch(
        showBanner({
          show: true,
          message: strings.supplierUserCreated,
          type: 'success'
        })
      )
    }
  }, [user, dispatch])

  useEffect(() => {
    if (userDeleted?.userId) {
      dispatch(clearUser())
      dispatch(
        showBanner({
          show: true,
          message: strings.supplierUserDeleted,
          type: 'success'
        })
      )
    }
  }, [userDeleted, dispatch])

  function onChangeInput(e) {
    setEmail(e.target.value)
  }

  function onClickClose() {
    dispatch(clearUsers())
    history.push('/admin/suppliers')
  }

  async function onSubmitCreate(e) {
    e.preventDefault()

    try {
      const response = await userSchema.validate(
        { email },
        { abortEarly: true }
      )

      dispatch(sendCreateFactorySupplierUser({ ...response, supplierId }))
      setEmail('')
    } catch (err) {
      dispatch(showBanner({ show: true, message: err.message, type: 'error' }))
    }
  }

  async function onDeleteUser(userId) {
    dispatch(sendDeleteFactorySupplierUser({ userId, supplierId }))
  }

  return (
    <Dialog
      type='offcanvas'
      open={true}
      className='supplier-users'
      onOpenChange={onClickClose}
    >
      <Slot name='title'>
        <Text size='300' fontWeight={700}>
          {strings.users}
        </Text>
      </Slot>
      <Slot name='content'>
        <Loader isLoading={isLoading}>
          <Form onSubmit={onSubmitCreate}>
            <Flex axisGap={200} direction='column' alignMainAxis='center'>
              <Label>
                <Text variant='page' tone={700}>
                  {strings.email}
                </Text>
                <Input
                  required
                  type='email'
                  value={email}
                  onChange={onChangeInput}
                />
              </Label>
            </Flex>
            <Flex alignMainAxis='flex-end'>
              <Button
                size='small'
                type='submit'
                variant='primary'
                disabled={saving}
                loading={saving}
              >
                {strings.save}
              </Button>
            </Flex>
          </Form>
          <List compact={true}>
            {users &&
              users.map(({ userId, email }) => (
                <Item key={userId} primaryText={email}>
                  <Item
                    primaryText={strings.delete}
                    onClick={() => onDeleteUser(userId)}
                  />
                </Item>
              ))}
          </List>
        </Loader>
      </Slot>
    </Dialog>
  )
}

export default ModalUsers

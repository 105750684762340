import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { getCognitoData, makeGraphqlRequest } from '../utils'

export const getFactorySuppliers = async () => {
  try {
    const query = /* GraphQL */ `
      query getFactorySuppliers {
        getFactorySuppliers {
          supplierId
          name
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, {})
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      throw Error('Unable to fetch suppliers')
    }

    const suppliers = data?.getFactorySuppliers ?? []
    return { suppliers, error }
  } catch (err) {
    throw err
  }
}

export const createFactorySupplier = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createFactorySupplier($name: String!) {
        createFactorySupplier(name: $name) {
          supplierId
          name
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      throw Error('Unable to create supplier')
    }

    const supplier = data?.createFactorySupplier ?? {}
    return { supplier, error }
  } catch (err) {
    throw err
  }
}

export const getFactorySupplierUsers = async params => {
  try {
    const query = /* GraphQL */ `
      query getFactorySupplierUsers($supplierId: String!) {
        getFactorySupplierUsers(supplierId: $supplierId) {
          supplierId
          userId
          email
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      throw Error('Unable to fetch users')
    }

    const users = data?.getFactorySupplierUsers ?? []
    return { users, error }
  } catch (err) {
    throw err
  }
}

export const createFactorySupplierUser = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createFactorySupplierUser(
        $supplierId: String!
        $email: String!
      ) {
        createFactorySupplierUser(supplierId: $supplierId, email: $email) {
          supplierId
          userId
          email
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      throw Error('Unable to create user')
    }

    const user = data?.createFactorySupplierUser ?? {}
    return { user, error }
  } catch (err) {
    throw err
  }
}

export const deleteFactorySupplierUser = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation deleteFactorySupplierUser(
        $supplierId: String!
        $userId: String!
      ) {
        deleteFactorySupplierUser(supplierId: $supplierId, userId: $userId) {
          supplierId
          userId
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      throw Error('Unable to delete user')
    }

    const user = data?.deleteFactorySupplierUser ?? {}
    return { user, error }
  } catch (err) {
    throw err
  }
}

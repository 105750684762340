import { getZonePathFromLocationSelector } from '../../../Utils'

import {
  AVAILABLE_FEATURE_FLAGS,
  ENABLE_STATUS_PAGE,
  hasFeatureFlagEnabled
} from '@/Util/FeatureFlagsUtils'

export const zone = ({
  level,
  locationSelector,
  permissions,
  strings,
  currentUser
}) => {
  const zonePath = getZonePathFromLocationSelector({ level, locationSelector })
  const zone = locationSelector.get(level)
  const organization = locationSelector.get('organization')

  const enableStatusPage = hasFeatureFlagEnabled(
    currentUser,
    AVAILABLE_FEATURE_FLAGS[ENABLE_STATUS_PAGE],
    organization.id
  )

  const routes = [
    {
      to: `/zones/${zonePath}`,
      text: strings.dashboard,
      icon: 'dashboard',
      activeOnly: true
    },
    {
      to: `/zones/${zonePath}/data`,
      text: strings.databoard,
      icon: 'insights',
      activeOnly: true
    },
    {
      to: `/zones/${zonePath}/status`,
      text: strings.status,
      icon: 'traffic',
      activeOnly: true,
      exact: false,
      visible: enableStatusPage
    },
    {
      to: `/admin/zones/${zonePath}/details`,
      text: strings.settings,
      icon: 'settings'
    },
    {
      to: `/admin/zones/${zonePath}/devices`,
      text: strings.devices,
      icon: 'important devices',
      visible: permissions.sensor && permissions.sensor_management
    },
    {
      to: `/admin/zones/${zonePath}/managers`,
      text: strings.managers,
      icon: 'supervisor account',
      visible: permissions.user && permissions.user_management
    },
    {
      to: `/admin/zones/${zonePath}/thresholds`,
      text: strings.thresholds,
      icon: 'data thresholding',
      visible: permissions.sensor && permissions.sensor_thresholds
    },
    {
      to: `/zones/${zonePath}/video-feeds`,
      text: strings.video_feeds,
      icon: 'smart display',
      visible: permissions.sensor_cameras,
      activeOnly: true
    },
    {
      to: '/blueprint',
      text: strings.blueprint,
      icon: 'border inner',
      visible: false
    },
    {
      to: `/operations/${zonePath}/crops`,
      text: strings.crops,
      icon: 'psychiatry',
      visible: true,
      activeOnly: true
    },
    {
      to: `/operations/${zonePath}/harvest`,
      text: strings.harvest,
      icon: 'compost',
      _visible: permissions.operations_harvest,
      visible: false,
      activeOnly: true,
      routes: [
        {
          to: `/operations/${zonePath}/harvest/plans`,
          text: strings.plans,
          icon: 'pending actions',
          visible: permissions.operations_plans
        }
      ]
    },
    {
      to: '/fertigation',
      text: strings.fertigation,
      icon: 'fertile',
      _visible: permissions.operations_fertilizer,
      visible: false,
      activeOnly: true
    }
  ]

  return {
    text: locationSelector.get(level).name,
    title: strings[level],
    icon: 'activity zone',
    routes: routes.filter(r => !r.activeOnly || zone.status === 'active')
  }
}

import { DateTime } from 'luxon'
import MeasurementStrings from '@/Util/Strings/measurements'
import Strings from './Strings'

export const STATUS_SYMBOL_COLOR = {
  notification: 'warning',
  caution: 'danger',
  warning: 'error'
}

export const getSnoozeTime = expiresAt => {
  const currentTime = DateTime.now().toSeconds()
  const timeLeft = expiresAt - Math.round(currentTime)
  return timeLeft >= 0 ? Math.ceil(timeLeft / 60) : 0
}

export const getSnoozeDurationOptions = () => {
  const strings = Strings()
  return [
    {
      label: `15 ${strings.minutes}`,
      timeInMin: 15,
      value: 900,
      index: 0
    },
    {
      label: `30 ${strings.minutes}`,
      timeInMin: 30,
      value: 1800,
      index: 1
    },
    {
      label: `60 ${strings.minutes}`,
      timeInMin: 60,
      value: 3600,
      index: 2
    }
  ]
}

export const generateKey = displayStatus => {
  const { measurementId, zoneId, sensorId } = displayStatus

  return `${sensorId}-${measurementId}-${zoneId}`
}

const findThresholdZonePath = (zoneHierarchy, zoneId) => {
  let zonePath = null

  if (zoneHierarchy) {
    const { id, parentPath, children } = zoneHierarchy
    if (id === zoneId) {
      return parentPath
    }

    const childKeys = Object.keys(children)
    for (let i = 0; i < childKeys.length; i++) {
      const currentChild = children[childKeys[i]]
      zonePath = findThresholdZonePath(currentChild, zoneId)
      if (zonePath) {
        return zonePath
      }
    }
  }

  return zonePath
}

const getMeasurement = (measurements, measurementId) => {
  const measurement = measurements.find(({ id }) => measurementId === id)
  return measurement
}

export const getStatusesEntries = statuses => {
  return Object.entries(statuses ?? {})
}

export const getFilteredStatuses = ({
  statuses,
  hierarchy,
  hierarchyDevices,
  params,
  measurements
}) => {
  let parentStatuses = null
  let sensorStatuses = null
  let noDataStatuses = null
  let detailedSensorStatuses = null

  const measurementStrings = MeasurementStrings()

  for (let status of statuses) {
    const [measurementId, zoneId] =
      status?.measurementIdAndZoneId?.split('#') ?? null
    const thresholdZonePath = findThresholdZonePath(hierarchy, zoneId)

    const device = hierarchyDevices?.[status?.sensorId]
    if (!device) {
      continue
    }
    const currentZone = params.zone?.split('/').at(-1)

    const isDeviceInPath = device.zonePath.includes(params.zone)
    const isDeviceInCurrentZone = device?.zoneId === currentZone

    if (!isDeviceInPath && !isDeviceInCurrentZone) {
      continue
    }

    const measurement = getMeasurement(measurements, measurementId)

    const displayStatus = {
      sensorId: status.sensorId,
      sensorName: device.name,
      sensorZonePath: device.zonePath,
      sensorType: device.sensorType,
      measurementId,
      measurementName:
        measurementStrings[measurementId] ??
        measurement?.shortName ??
        measurement?.description ??
        measurement?.id,
      measurementUnit: measurement?.unit ?? '',
      rootId: status.rootId,
      zoneId,
      thresholdZonePath,
      statusInfo: {
        value: status.value,
        lastChecked: status.lastChecked,
        sinceWhen: status.sinceWhen,
        status: status.status,
        upperMessage: status.upperMessage,
        lowerMessage: status.lowerMessage
      }
    }

    // group by zonePath
    if (isDeviceInPath && !isDeviceInCurrentZone) {
      if (!parentStatuses) {
        parentStatuses = {}
      }
      if (!parentStatuses[device.zonePath]) {
        parentStatuses[device.zonePath] = {}
      }

      if (!parentStatuses[device.zonePath][measurementId]) {
        parentStatuses[device.zonePath][measurementId] = displayStatus
      }

      // group by measurement
    } else if (isDeviceInPath && isDeviceInCurrentZone) {
      if (params.sensorId && params.sensorId === status.sensorId) {
        if (!detailedSensorStatuses) {
          detailedSensorStatuses = {}
        }

        if (!detailedSensorStatuses[displayStatus.sensorId]) {
          detailedSensorStatuses[displayStatus.sensorId] = {}
        }

        if (!detailedSensorStatuses[displayStatus.sensorId][measurementId]) {
          detailedSensorStatuses[displayStatus.sensorId][measurementId] =
            displayStatus
        }

        if (detailedSensorStatuses[displayStatus.sensorId][measurementId]) {
          const isNextThresholdMoreSpecific =
            displayStatus.thresholdZonePath.length >
            detailedSensorStatuses[displayStatus.sensorId][measurementId]
              .thresholdZonePath.length

          if (isNextThresholdMoreSpecific) {
            detailedSensorStatuses[displayStatus.sensorId][measurementId] =
              displayStatus
          }
        }
      } else if (!params.sensorId) {
        if (!sensorStatuses) {
          sensorStatuses = {}
        }
        if (!sensorStatuses[measurementId]) {
          sensorStatuses[measurementId] = {}
        }

        if (!sensorStatuses[measurementId][status.sensorId]) {
          sensorStatuses[measurementId][status.sensorId] = displayStatus
        }

        if (sensorStatuses[measurementId][status.sensorId]) {
          const isNextThresholdMoreSpecific =
            displayStatus.thresholdZonePath.length >
            sensorStatuses[measurementId][status.sensorId].thresholdZonePath
              .length

          if (isNextThresholdMoreSpecific) {
            sensorStatuses[measurementId][status.sensorId] = displayStatus
          }
        }
      }
    }
  }

  return {
    detailedSensorStatuses,
    noDataStatuses,
    sensorStatuses,
    parentStatuses
  }
}

import { I18n } from 'aws-amplify'

export const AVAILABLE_MEASUREMENTS = [
  'temperature',
  'humidity',
  'par',
  'carbon_dioxide',
  'wind_speed'
]

export const AVAILABLE_EXPRESSIONS = [
  ['more', I18n.get('More than or equal to')],
  ['less', I18n.get('Less than or equal to')]
]

export const AVAILABLE_BOUNDS = [
  [I18n.get('Stress Lower')],
  [I18n.get('Bad Lower')],
  [I18n.get('Good Lower')],
  [I18n.get('Good Upper')],
  [I18n.get('Bad Upper')],
  [I18n.get('Stress Upper')]
]

export const AVAILABLE_PRIORITIES = [
  ['high', I18n.get('High')],
  ['medium', I18n.get('Medium')],
  ['low', I18n.get('Low')]
]

export const getPriorityDisplayName = key => {
  const priorityIndex = AVAILABLE_PRIORITIES.findIndex(p => p[0] === key)
  if (priorityIndex >= 0) {
    return AVAILABLE_PRIORITIES[priorityIndex][1]
  }
  return key
}

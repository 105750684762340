import { nanoid } from 'nanoid'
import { all, call, put, takeLatest } from 'redux-saga/effects'

import {
  getCustomers,
  getCustomer,
  createCustomer,
  updateCustomer
} from '@/api/operations/customer'

import {
  REQUEST_CUSTOMERS,
  REQUEST_CUSTOMER,
  CREATE_CUSTOMER,
  UPDATE_CUSTOMER,
  receiveCustomers,
  receiveCustomer,
  customerCreated,
  customerUpdated
} from '@/actions/operations/customer'

import { displayBanner } from '../utils'

import Strings from './Strings'

const strings = Strings()

function* sendCreateCustomer(action) {
  const {
    uploadedKeys,
    params,
    error: uploadError
  } = yield uploadDocuments(action.params)

  let customerData = {}
  let error = uploadError ?? null

  if (!uploadError) {
    const { data, error: apiError = null } = yield call(createCustomer, params)
    error = apiError ?? null
    customerData = data ?? {}
  }

  if (error && uploadedKeys.length > 0) {
    yield deleteDocuments(uploadedKeys)
  }

  yield put(displayBanner(error, strings.customerCreated))
  yield put(customerCreated(customerData, error))
}

export function* watchCreateCustomer() {
  yield takeLatest(CREATE_CUSTOMER, sendCreateCustomer)
}

function* sendUpdateCustomer(action) {
  const {
    uploadedKeys,
    params,
    error: uploadError
  } = yield uploadDocuments(action.params)

  let customerData = {}
  let error = uploadError ?? null

  if (!uploadError) {
    const { data, error: apiError } = yield call(updateCustomer, params)
    if (!apiError && params.s3KeysToDelete.length > 0) {
      yield deleteDocuments(params.s3KeysToDelete)
    }
    error = apiError ?? null
    customerData = data ?? {}
  }

  if (error && uploadedKeys.length > 0) {
    yield deleteDocuments(uploadedKeys)
  }

  yield put(displayBanner(error, strings.customerUpdated))
  yield put(customerUpdated(customerData, error))
}

export function* watchUpdateCustomer() {
  yield takeLatest(UPDATE_CUSTOMER, sendUpdateCustomer)
}

function* sendRequestCustomers(action) {
  const { data, error = null } = yield call(getCustomers, action.params)
  const { customers = [], count = 0 } = data
  yield put(receiveCustomers(customers, count, error))
}

export function* watchRequestCustomers() {
  yield takeLatest(REQUEST_CUSTOMERS, sendRequestCustomers)
}

function* sendRequestCustomer(action) {
  let { data, error = null } = yield call(getCustomer, action.params)

  data.registrationDocument = yield createFileObject(data.registrationS3Key)
  delete data.registrationS3Key

  data.taxDocument = yield createFileObject(data.taxS3Key)
  delete data.taxS3Key

  data.nationalAddressDocument = yield createFileObject(
    data.nationalAddressS3Key
  )
  delete data.nationalAddressS3Key

  data.bankDetailsDocument = yield createFileObject(data.bankDetailsS3Key)
  delete data.bankDetailsS3Key

  data.contractDocument = yield createFileObject(data.contractS3Key)
  delete data.contractS3Key

  yield put(receiveCustomer(data, error))
}

export function* watchRequestCustomer() {
  yield takeLatest(REQUEST_CUSTOMER, sendRequestCustomer)
}

const createFileObject = async s3Key => {
  return {
    file: {},
    url: s3Key ? await Storage.get(s3Key) : '',
    s3Key: s3Key ?? ''
  }
}

const uploadDocument = async (documentName, document, customerName) => {
  let docCopy = { ...document }
  if (docCopy?.file?.name) {
    docCopy.s3Key = generateS3Key(documentName, customerName)
    await Storage.put(docCopy.s3Key, docCopy.file, {
      contentType: 'application/pdf'
    })
  }
  return docCopy.s3Key ?? ''
}

const uploadDocuments = async params => {
  let uploadedKeys = []
  try {
    let paramsCopy = { ...params }
    let {
      registrationDocument,
      taxDocument,
      nationalAddressDocument,
      bankDetailsDocument,
      contractDocument
    } = paramsCopy
    // Registration Document
    paramsCopy.registrationS3Key = await uploadDocument(
      'registration',
      registrationDocument,
      paramsCopy.name
    )
    if (paramsCopy.registrationS3Key && registrationDocument?.file?.name) {
      uploadedKeys.push(paramsCopy.registrationS3Key)
    }
    // Tax Document
    paramsCopy.taxS3Key = await uploadDocument(
      'tax',
      taxDocument,
      paramsCopy.name
    )
    if (paramsCopy.taxS3Key && taxDocument?.file?.name) {
      uploadedKeys.push(paramsCopy.taxS3Key)
    }
    // National Address Document
    paramsCopy.nationalAddressS3Key = await uploadDocument(
      'nationalAddress',
      nationalAddressDocument,
      paramsCopy.name
    )
    if (
      paramsCopy.nationalAddressS3Key &&
      nationalAddressDocument?.file?.name
    ) {
      uploadedKeys.push(paramsCopy.nationalAddressS3Key)
    }
    // Bank Details Document
    paramsCopy.bankDetailsS3Key = await uploadDocument(
      'bankDetails',
      bankDetailsDocument,
      paramsCopy.name
    )
    if (paramsCopy.bankDetailsS3Key && bankDetailsDocument?.file?.name) {
      uploadedKeys.push(paramsCopy.bankDetailsS3Key)
    }
    // Contract Document
    paramsCopy.contractS3Key = await uploadDocument(
      'contract',
      contractDocument,
      paramsCopy.name
    )
    if (paramsCopy.contractS3Key && contractDocument?.file?.name) {
      uploadedKeys.push(paramsCopy.contractS3Key)
    }

    return { uploadedKeys, params: paramsCopy, error: null }
  } catch (error) {
    return { uploadedKeys, params, error: null }
  }
}

const generateS3Key = (type, name) => {
  if (!type || !name) {
    throw Error('generateS3Key needs both type & name')
  }
  const cleanName = name.replace(/\s+/g, '-').toLowerCase()
  return `customer/${cleanName}_${type}_${nanoid()}.pdf`
}

const deleteDocuments = keys =>
  all(keys.map(key => call(async () => await Storage.remove(key))))

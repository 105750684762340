import { makeOperationsApiCall } from './utils'

/* MUTATIONS */

export const createCurrency = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createCurrency(
        $organizationId: String!
        $name: String!
        $code: String!
        $usdRate: Float!
      ) {
        createCurrency(
          organizationId: $organizationId
          name: $name
          code: $code
          usdRate: $usdRate
        ) {
          id
          organizationId
          name
          code
          usdRate
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'createCurrency',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updateCurrency = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateCurrency(
        $currencyId: String!
        $organizationId: String!
        $name: String!
        $code: String!
        $usdRate: Float!
      ) {
        updateCurrency(
          currencyId: $currencyId
          organizationId: $organizationId
          name: $name
          code: $code
          usdRate: $usdRate
        ) {
          id
          organizationId
          name
          code
          usdRate
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'updateCurrency',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

/* QUERIES */

export const getCurrencies = async params => {
  try {
    const query = /* GraphQL */ `
      query getCurrencies(
        $filter: GetCurrenciesFilter
        $limit: Int
        $offset: Int
        $order: OrderEnum
      ) {
        getCurrencies(
          filter: $filter
          limit: $limit
          offset: $offset
          order: $order
        ) {
          currencies {
            id
            organizationId
            name
            code
            usdRate
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getCurrencies',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getCurrency = async params => {
  try {
    const query = /* GraphQL */ `
      query getCurrency($currencyId: String!) {
        getCurrency(currencyId: $currencyId) {
          id
          organizationId
          name
          code
          usdRate
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getCurrency',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

import { I18n } from 'aws-amplify'

export const strings = {
  s_t_a: I18n.get('10cm'),
  s_t_b: I18n.get('20cm'),
  s_t_c: I18n.get('30cm'),
  s_t_d: I18n.get('40cm'),
  s_t_e: I18n.get('50cm'),
  s_m_a: I18n.get('10cm'),
  s_m_b: I18n.get('20cm'),
  s_m_c: I18n.get('30cm'),
  s_m_d: I18n.get('40cm'),
  s_m_e: I18n.get('50cm')
}
import { createAsyncThunk } from '@reduxjs/toolkit'
import { DateTime } from 'luxon'
import { unparse } from 'papaparse'
import cloneDeep from 'lodash/cloneDeep'

import {
  SETTING_CELCIUS_TO_FAHRENHEIT,
  processSettings
} from '@/Util/SettingsUtils'

import { generateDownloadCsvLink } from '@/Util/GeneralUtils'
import {
  CONVERSION_TEMPERATURE_MEASUREMENTS,
  findMeasurement
} from '@/Util/MeasurementUtils'
import { conversionLookup } from '@/Util/UnitUtils'
import ZoneUtils from '@/Util/ZoneUtils'

import Strings from '../Strings'

import { getChartDateParams } from './ChartUtils'
import {
  processDliData,
  processDceData,
  processTemperatureConversion,
  processValue,
  splitDataset
} from './DataUtils'
import { adjustDateByInterval } from './DateUtils'
import { COMPARISON_TYPES, INTERVAL_STEPS } from './config'

const DEFAULT_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone

export const onDownloadCSVComparison = (
  state,
  allMeasurements,
  zonesHierarchy,
  user,
  site
) => {
  const { userName, settings } = user
  const splitData = splitDataset(state)
  const data = cloneDeep(splitData)
  const timezone =
    state.comparisonType === COMPARISON_TYPES.LOCATION_RANGES
      ? state.sidebarFilter[COMPARISON_TYPES.DATE_RANGES].timeZone ??
        DEFAULT_TIMEZONE
      : state?.dateFilters[0]?.timeZone ?? DEFAULT_TIMEZONE

  downloadCSVComparison({
    data,
    measurements: allMeasurements,
    timeZone: timezone,
    state,
    zonesHierarchy,
    comparisonType: state.comparisonType,
    settings,
    userId: userName,
    organizationId: site?.organizationId
  })
}

function printTemperatureUnit(shouldOverwrite = false) {
  if (shouldOverwrite) {
    return '°F'
  }

  return '°C'
}

export function downloadCSVComparison({
  data,
  measurements,
  timeZone,
  state,
  zonesHierarchy,
  comparisonType,
  settings,
  userId,
  organizationId
}) {
  const strings = Strings()
  const { toDate, fromDate, timeInterval } = getChartDateParams(state)

  let zoneName = null
  let enableFahrenheit = false

  for (let i = 0; i < data.length; i++) {
    const item = data[i]
    if (item?.length > 0) {
      for (let j = 0; j < item.length; j++) {
        const measurement = item[j]?.measurement ?? ''
        if (measurement === 'daily_light_integral') {
          let dliData = [...data[i][j].series[0].data]
          data[i][j].series[0].data = processDliData(dliData)
        }

        if (measurement === 'daily_energy_consumption') {
          let dceData = [...data[i][j].series[0].data]
          data[i][j].series[0].data = processDceData(dceData)
        }

        if (CONVERSION_TEMPERATURE_MEASUREMENTS.includes(measurement)) {
          enableFahrenheit = processSettings(
            settings,
            SETTING_CELCIUS_TO_FAHRENHEIT,
            userId,
            organizationId
          )

          if (enableFahrenheit) {
            let temperatureData = [...data[i][j].series[0].data]
            data[i][j].series[0].data =
              processTemperatureConversion(temperatureData)
          }
        }
      }
    }
  }

  let row = []
  let rows = [[strings.date], [timeZone]]
  let testDate = null

  const step = { [INTERVAL_STEPS[timeInterval]]: 1 }

  // find the smallest fromDate from the data because weekly data can start before the fromDate
  let smallestFromDate = fromDate

  for (let item of data) {
    for (let chart of item) {
      for (let series of chart.series) {
        const firstDate = DateTime.fromISO(series.data[0].x)
          .setZone(timeZone)
          .toMillis()
        if (firstDate < smallestFromDate) {
          smallestFromDate = firstDate
        }
      }
    }
  }

  const endDate = DateTime.fromMillis(toDate).setZone(timeZone)
  let nextDate = DateTime.fromMillis(smallestFromDate).setZone(timeZone)

  switch (timeInterval) {
    case 'hour':
      nextDate = nextDate.set({ minutes: 0 })
      break
    case 'day':
      nextDate = nextDate.set({ hours: 0, minutes: 0 })
      break
    default:
      nextDate = nextDate.set({ seconds: 0, milliseconds: 0 })
      break
  }

  nextDate = nextDate.toMillis()

  let maxLength = 0

  for (let a = 0; a < data.length; a++) {
    maxLength = Math.max(maxLength, data[a].length)
  }

  while (nextDate <= endDate) {
    row = [
      DateTime.fromMillis(nextDate)
        .setZone(timeZone)
        .toFormat('yyyy/MM/dd HH:mm')
    ]

    for (let a = 0; a < data.length; a++) {
      // measurements
      for (let b = 0; b < data[a].length; b++) {
        // zones

        const dateRange = strings[data[a][b].dateRange]

        if (rows.length === 2) {
          if (comparisonType === COMPARISON_TYPES.DATE_RANGES) {
            rows[0].push(dateRange)
            zoneName = getZoneHeader(
              zonesHierarchy,
              data[a][b].siteId,
              data[a][b].zoneId,
              data[a][b].sensorId
            )
          }

          if (comparisonType === COMPARISON_TYPES.LOCATION_RANGES) {
            rows[0].push(
              getZoneHeader(
                zonesHierarchy,
                data[a][b].siteId,
                data[a][b].zoneId,
                data[a][b].sensorId
              )
            )
          }

          const measurement = findMeasurement(
            measurements,
            data[a][b].measurement
          )

          rows[1].push(
            `${measurement.shortName} (${
              CONVERSION_TEMPERATURE_MEASUREMENTS.includes(measurement.id)
                ? printTemperatureUnit(enableFahrenheit)
                : measurement.unit
            })`
          )
        }

        let hasValue = false
        let dataLength = data[a][b].series[0].data.length

        for (let j = 0; j < dataLength; j++) {
          // data
          testDate = DateTime.fromISO(data[a][b].series[0].data[j].x).setZone(
            timeZone
          )

          switch (timeInterval) {
            case 'hour':
              testDate = testDate.set({ minutes: 0 })
              break
            case 'day':
              testDate = testDate.set({ hours: 0, minutes: 0 })
              break
            default:
              testDate = testDate.set({ seconds: 0, milliseconds: 0 })
              break
          }

          testDate = testDate.toMillis()

          if (testDate === nextDate) {
            const valueY = data[a][b].series[0].data[j].y
            const scaledY =
              valueY * (conversionLookup[data[a][b].measurement] ?? 1)
            row.push(scaledY)

            data[a][b].series[0].data.splice(j, 1)

            dataLength--
            j--

            hasValue = true

            break
          }
        }

        if (!hasValue) {
          row.push(null)
        }
        hasValue = false
      }
    }
    if (row[1] !== null) {
      rows.push(row)
    }

    nextDate = DateTime.fromMillis(nextDate).plus(step).toMillis()
  }

  const csv = unparse({ data: rows })

  const fileName = parseFileName(fromDate, toDate, timeInterval, zoneName)

  generateDownloadCsvLink(csv, fileName)
}

const getZoneHeader = (zonesHierarchy, siteId, zoneId, sensorId) => {
  const siteName = ZoneUtils.getZoneName(zonesHierarchy, siteId)
  const zoneName = ZoneUtils.getZoneName(zonesHierarchy, siteId, zoneId)
  const sensorName = ZoneUtils.getSensorName(
    zonesHierarchy,
    siteId,
    zoneId,
    sensorId
  )
  let name = `${siteName}/${zoneName}`
  if (sensorName) {
    name += `/${sensorName}`
  }
  return name
}

function parseFileName(fromDate, toDate, timeInterval, zoneName = null) {
  const formattedFromDate = formatDateCsv(fromDate)

  const formattedToDate = formatDateCsv(toDate)

  const dates = `${formattedFromDate}-${formattedToDate}`

  let fileName = `${dates}-${timeInterval}.csv`

  if (zoneName) {
    fileName = `${dates}-${zoneName}-${timeInterval}.csv`
  }

  return fileName
}

function formatDateCsv(date) {
  return DateTime.fromMillis(date).toFormat('yyyy-MM-dd-HH-mm')
}

export const thunkDownloadCsvDateComparison = createAsyncThunk(
  'csvDownload',
  async (params, { dispatch }) => {
    const { csv, fileName } = await onDownloadCSVDateComparison(params)
    generateDownloadCsvLink(csv, fileName)
  }
)

export async function onDownloadCSVDateComparison({
  state,
  allMeasurements,
  zonesHierarchy,
  user,
  site
}) {
  const timezone =
    state.comparisonType === COMPARISON_TYPES.LOCATION_RANGES
      ? state.sidebarFilter[COMPARISON_TYPES.DATE_RANGES].timeZone ??
        DEFAULT_TIMEZONE
      : state?.dateFilters[0]?.timeZone ?? DEFAULT_TIMEZONE

  const enableFahrenheit = processSettings(
    user.settings,
    SETTING_CELCIUS_TO_FAHRENHEIT,
    user.userName,
    site?.organizationId
  )

  return new Promise((resolve, reject) => {
    resolve(
      createCsvDateComparison(
        state,
        timezone,
        allMeasurements,
        zonesHierarchy,
        enableFahrenheit
      )
    )
  })
}

/**
 * Get the path of the zone from the location filter
 * @param {object} locationFilter
 * @returns {object} - the path of the zone and the sensorId
 */
function getZonePathFromLocationFilter(locationFilter) {
  let order = ['siteId', 'facilityId', 'roomId', 'zoneId', 'subzoneId']
  let path = ''
  // add all the locations in the order if they exist
  for (let key of order) {
    if (locationFilter[key]) {
      path += `${locationFilter[key]}/`
    }
  }
  // remove the last slash if it exists
  if (path.endsWith('/')) {
    path = path.slice(0, -1)
  }

  return { path, sensorId: locationFilter.sensorId }
}

/**
 * Get the zone file name from the path and the sensorId
 * @param {string} path
 * @param {string} sensorId
 * @param {object} zonesHierarchy
 * @returns {string} - the zone name for the csv file
 */
function getZoneFileNameFromPath(path, sensorId, zonesHierarchy) {
  // if path has sensor/:sensorId, remove it and store it in a variable
  const siteId = path.split('/')[0]
  const site = zonesHierarchy[siteId]
  const zone = ZoneUtils.getZoneHierarchyValueByPath(
    zonesHierarchy[siteId],
    path
  )
  let zoneName = `${site.name}-${zone.name}`
  if (sensorId) {
    let device = zone.devices.find(device => device.id === sensorId)
    if (device) {
      zoneName += `-${device.name}`
    }
  }

  return zoneName
}

/**
 * Create the csv file for the date comparison and prompt the download
 * @param {object} state
 * @param {string} timezone
 * @param {Array} allMeasurements
 * @param {object} zonesHierarchy
 * @param {boolean} enableFahrenheit
 */
export function createCsvDateComparison(
  state,
  timezone,
  allMeasurements,
  zonesHierarchy,
  enableFahrenheit = false
) {
  const strings = Strings()
  const {
    dataset,
    sidebarFilter: { locationRanges }
  } = state

  const { toDate, fromDate, timeInterval } = getChartDateParams(state)
  const smallestFromDate = DateTime.fromMillis(fromDate)
  const largestToDate = DateTime.fromMillis(toDate)
  const { path, sensorId } = getZonePathFromLocationFilter(locationRanges)
  const zoneName = getZoneFileNameFromPath(path, sensorId, zonesHierarchy)

  let valueRowsMap = {}

  const measurementsMap = new Map(allMeasurements.map(m => [m.id, m]))

  const measurementHeaderIds = new Set(dataset.map(item => item.measurement))
  const measurementHeaderNames = Array.from(measurementHeaderIds).map(
    measurementId => {
      const measurement = measurementsMap.get(measurementId)
      return `${measurement.shortName} (${
        CONVERSION_TEMPERATURE_MEASUREMENTS.includes(measurement.id)
          ? printTemperatureUnit(enableFahrenheit)
          : measurement.unit
      })`
    }
  )

  let dataLength = parseInt(
    largestToDate.diff(smallestFromDate, timeInterval).as(timeInterval)
  )

  let idx = 0

  while (idx < dataLength) {
    const date = adjustDateByInterval(
      smallestFromDate.plus({ [timeInterval]: idx }),
      timeInterval
    )
    valueRowsMap[date] = new Array(measurementHeaderIds.size).fill(null)
    idx += 1
  }

  let header = [`Date (${timezone})`, ...measurementHeaderNames]

  for (let item of dataset) {
    for (let serie of item.series) {
      for (let dataPoint of serie.data) {
        let rowId = adjustDateByInterval(
          DateTime.fromISO(dataPoint.x),
          timeInterval
        )

        const measurementIdx = Array.from(measurementHeaderIds).indexOf(
          item.measurement
        )

        // sometimes the data point is not in the interval range because of we package data with timestream
        if (!valueRowsMap[rowId]) {
          valueRowsMap[rowId] = new Array(measurementHeaderIds.size).fill(null)
        }

        valueRowsMap[rowId][measurementIdx] = processValue(
          dataPoint.y,
          item.measurement,
          enableFahrenheit
        )
      }
    }
  }

  let valueRows = Object.entries(valueRowsMap).map(([key, value]) => {
    return [key, ...value]
  })

  valueRows = valueRows.sort((a, b) => a[0] - b[0])

  const csv = unparse({ data: [header, ...valueRows] })
  const fileName = parseFileName(
    fromDate,
    toDate,
    strings[timeInterval],
    zoneName
  )

  return { csv, fileName }
}

import useDatasetData from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Hooks/useDatasetData'
import Line from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/Chart/Line'
import { convertToDLI } from '@/Util/MeasurementUtils'

export default function DatasetLine({ unit, config, ...props }) {
  const requiredMeasurements = ['par']
  const { datasetData, queryZones } = useDatasetData({
    measurements: requiredMeasurements,
    range: config.range,
    interval: config.interval,
    includeSensor: false
  })

  let chartData = {}

  if (datasetData) {
    for (let { zoneName } of queryZones) {
      const data = datasetData?.['par']?.[zoneName] || []
      chartData[zoneName] = []

      for (let dataPoint of data) {
        const dataPointY = parseFloat(dataPoint.y)

        chartData[zoneName].push({
          x: dataPoint.x,
          y: convertToDLI({
            par: dataPointY,
            useNew: true,
            interval: config.interval
          })
        })
      }
    }
  }

  return (
    <Line
      {...props}
      data={chartData}
      unit={unit}
      config={config}
      queryZones={queryZones}
      calculateDli={true}
    />
  )
}

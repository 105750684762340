import { Fragment, useReducer, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import DeleteRelayModal from './DeleteRelayModal'
import { Slot, Text, Input, Label, Button, Flex, Box } from '@/primitives'
import { Dialog } from '@/elements'

import {
  cleanNewConfigs,
  sendConfigureControlDeviceConfig,
  sendUpdateControlDeviceConfig
} from '@/slices/control/controlDeviceConfig'

import {
  getControlDeviceSaving,
  getControlDeviceNewConfigs
} from '@/reducers/selectors'

import Strings from '../../../Strings'
import { controlDeviceConfigSchema } from './utils'
import './index.scss'

const relayConfigDefault = {
  relayId: 'relay-1',
  registerNumber: '0'
}

const initialState = {
  address: '/dev/ttyUSB0',
  slaveAddress: '1',
  baudrate: '9600',
  relayConfiguration: [relayConfigDefault],
  errors: {}
}

function reducer(state, action) {
  switch (action.type) {
    case 'set':
      if (!action.value) return { ...state, ...initialState }

      const { address, slaveAddress, baudrate, relayConfiguration } =
        action.value

      return {
        ...state,
        address,
        slaveAddress: slaveAddress.toString(),
        baudrate: baudrate.toString(),
        relayConfiguration: relayConfiguration.map(
          ({ relayId, registerNumber }) => ({
            relayId: relayId.toString(),
            registerNumber: registerNumber.toString()
          })
        )
      }
    case 'updateField':
      if (action.name === 'errors') {
        return { ...state, [action.name]: action.value }
      }
      const updatedErrors = { ...state.errors }
      if (updatedErrors.hasOwnProperty(action.name)) {
        delete updatedErrors[action.name]
      }
      return { ...state, [action.name]: action.value, errors: updatedErrors }
    case 'updateRelayConfig':
      const { name, value, index } = action
      const updatedRelayErrors = { ...state.errors }
      const errorPath = `relayConfiguration[${index}]`
      if (updatedRelayErrors.hasOwnProperty(errorPath)) {
        delete updatedRelayErrors[errorPath]
      }

      let updateRelayConfiguration = [...state.relayConfiguration]
      updateRelayConfiguration[index][name] = value
      return {
        ...state,
        relayConfiguration: updateRelayConfiguration,
        errors: updatedRelayErrors
      }
    case 'addRelayConfig':
      let addRelayConfiguration = [...state.relayConfiguration]
      addRelayConfiguration.push({
        relayId: `relay-${state.relayConfiguration?.length + 1}`,
        registerNumber: '0'
      })
      return { ...state, relayConfiguration: addRelayConfiguration }
    case 'removeRelayConfig':
      let deleteRelayConfiguration = [...state.relayConfiguration]
      deleteRelayConfiguration.pop()
      return { ...state, relayConfiguration: deleteRelayConfiguration }
    case 'cleanFields':
      return { ...initialState }
    default:
      return { ...state }
  }
}

export default function FormConfig({
  coreDeviceId,
  controlDeviceConfig,
  showForm,
  setShowForm
}) {
  const strings = Strings()

  const reduxDispatch = useDispatch()
  const saving = getControlDeviceSaving()
  const newConfigs = getControlDeviceNewConfigs()

  const [state, dispatch] = useReducer(reducer, initialState)

  const [isEditing, setIsEditing] = useState(false)
  const [showDeleteRelayModal, setShowDeleteRelayModal] = useState(false)

  useEffect(() => {
    return () => {
      dispatch({ type: 'cleanFields' })
    }
  }, [])

  useEffect(() => {
    setIsEditing(
      controlDeviceConfig !== null &&
        controlDeviceConfig?.controlDeviceId !== null
    )

    dispatch({ type: 'set', value: controlDeviceConfig })
  }, [controlDeviceConfig])

  useEffect(() => {
    if (newConfigs.length > 0 && !saving) {
      reduxDispatch(cleanNewConfigs())
      setShowForm(false)
      dispatch({ type: 'cleanFields' })
    }
  }, [newConfigs, saving, reduxDispatch, setShowForm])

  useEffect(() => {
    if (!showForm) {
      dispatch({ type: 'cleanFields' })
    }
  }, [showForm])

  const onChangeInput = e => {
    const { name, value } = e.target
    dispatch({ type: 'updateField', name, value })
  }

  const onChangeRelayInput = e => {
    const { value } = e.target
    const [name, index] = e.target.name.split('-')
    dispatch({ type: 'updateRelayConfig', name, value, index })
  }

  const onAddRelayInput = e => {
    e.preventDefault()
    dispatch({ type: 'addRelayConfig' })
  }

  const onRemoveRelayInput = e => {
    e.preventDefault()

    const relayConfiguration = state.relayConfiguration
    const lastRelayConfig = relayConfiguration[relayConfiguration.length - 1]
    const specifications = controlDeviceConfig.specifications
    const isRelayUsed = specifications.some(
      spec => spec.relayId === lastRelayConfig.relayId
    )

    if (!isRelayUsed) {
      dispatch({ type: 'removeRelayConfig' })
    } else {
      setShowDeleteRelayModal(true)
    }
  }

  const onSubmit = async () => {
    try {
      dispatch({ type: 'updateField', name: 'errors', value: {} })
      await controlDeviceConfigSchema.validate(
        { ...state },
        { abortEarly: false }
      )

      if (isEditing) {
        reduxDispatch(
          sendUpdateControlDeviceConfig({
            ...state,
            coreDeviceId,
            controlDeviceId: controlDeviceConfig.controlDeviceId
          })
        )
      } else {
        reduxDispatch(
          sendConfigureControlDeviceConfig({ ...state, coreDeviceId })
        )
      }
    } catch (err) {
      if (err.inner) {
        let errors = {}
        for (let i = 0; i < err.inner.length; i++) {
          const { message, path } = err.inner[i]
          if (path.includes('relayConfiguration')) {
            const [fieldName] = path.split('.')
            errors[fieldName] = message
          } else {
            errors[path] = message
          }
        }
        dispatch({ type: 'updateField', name: 'errors', value: errors })
      }
    }
  }

  function onAcceptDeleteRelay() {
    dispatch({ type: 'removeRelayConfig' })
    setShowDeleteRelayModal(false)
  }

  function onCancelDeleteRelay() {
    setShowDeleteRelayModal(false)
  }

  function getClassname(fieldName) {
    if (state?.errors?.hasOwnProperty(fieldName)) {
      return 'Error'
    }
    return 'Default'
  }

  function getErrorText(fieldName) {
    if (state?.errors?.hasOwnProperty(fieldName)) {
      return (
        <Text
          size={100}
          variant='error'
          tone={700}
          style={{ margin: '-0.4rem 0 0.4rem' }}
        >
          {state.errors[fieldName]}
        </Text>
      )
    }
    return null
  }

  function closeDialog() {
    setShowForm(false)
  }

  return (
    <Fragment>
      {showForm && (
        <Dialog
          open={showForm}
          onOpenChange={setShowForm}
          type='offcanvas'
          className='ZoneDetails__OffCanvas FormConfig'
          style={{ zIndex: 3 }}
        >
          <Slot name='title'>
            <Text size={300} fontWeight={700}>
              {isEditing
                ? strings.formEditControlDeviceConfig
                : strings.formAddControlDeviceConfig}
            </Text>
          </Slot>
          <Slot name='content'>
            <Label>
              <Text>{strings.address}</Text>
              <Input
                name='address'
                value={state.address}
                onChange={onChangeInput}
                className={getClassname('address')}
              />
            </Label>
            {getErrorText('address')}
            <Label>
              <Text>{strings.slaveAddressControl}</Text>
              <Input
                name='slaveAddress'
                value={state.slaveAddress}
                onChange={onChangeInput}
                className={getClassname('slaveAddress')}
              />
            </Label>
            {getErrorText('slaveAddress')}
            <Label>
              <Text>{strings.baudrate}</Text>
              <Input
                name='baudrate'
                value={state.baudrate}
                onChange={onChangeInput}
                className={getClassname('baudrate')}
              />
            </Label>
            {getErrorText('baudrate')}
            <Label>
              <Text>{strings.relayConfig}</Text>
            </Label>
            <Box className='RelayConfiguration'>
              <Flex direction='row' wrap='nowrap' axisGap={300}>
                <Box>
                  <Label style={{ width: '8rem' }}>
                    <Text variant='page' tone={800} fontWeight={400}>
                      {strings.relayId}
                    </Text>
                  </Label>
                </Box>
                <Box>
                  <Label>
                    <Text variant='page' tone={800} fontWeight={400}>
                      {strings.registerNumber}
                    </Text>
                  </Label>
                </Box>
              </Flex>
              {state?.relayConfiguration?.map(
                ({ relayId, registerNumber }, index) => (
                  <Fragment key={relayId}>
                    <Flex
                      key={`relay-config-${index}`}
                      direction='row'
                      wrap='nowrap'
                      axisGap={300}
                      className='RelayConfig__Row'
                    >
                      <Input
                        name='relayId'
                        value={relayId}
                        disabled={true}
                        style={{
                          width: '8rem',
                          borderColor: 'var(--ctx-theme-color-page-400)'
                        }}
                      />
                      <Input
                        name={`registerNumber-${index}`}
                        value={registerNumber}
                        onChange={onChangeRelayInput}
                        index={index}
                        className={getClassname(`relayConfiguration[${index}]`)}
                      />
                    </Flex>
                    <Box style={{ marginInlineStart: '8.5rem' }}>
                      {getErrorText(`relayConfiguration[${index}]`)}
                    </Box>
                  </Fragment>
                )
              )}
              <Flex
                direction='row'
                axisGap={300}
                alignMainAxis='flex-end'
                style={{ marginTop: '1rem' }}
              >
                <Button
                  size='small'
                  variant='primary'
                  onClick={onAddRelayInput}
                  disabled={saving}
                >
                  {strings.addButton}
                </Button>
                <Button
                  size='small'
                  onClick={onRemoveRelayInput}
                  disabled={state.relayConfiguration?.length === 1 || saving}
                  variant='error'
                >
                  {strings.removeButtonControl}
                </Button>
              </Flex>
            </Box>
          </Slot>
          <Slot name='actions'>
            <Flex axisGap={300} alignMainAxis='space-between'>
              <Button variant='neutral' onClick={closeDialog} disabled={saving}>
                {strings.cancel}
              </Button>
              <Button
                variant='primary'
                onClick={onSubmit}
                disabled={saving}
                loading={saving}
              >
                {strings.submit}
              </Button>
            </Flex>
          </Slot>
        </Dialog>
      )}
      {showDeleteRelayModal && (
        <DeleteRelayModal
          showModal={showDeleteRelayModal}
          controlDeviceConfig={controlDeviceConfig}
          onAcceptDelete={onAcceptDeleteRelay}
          onCancelDelete={onCancelDeleteRelay}
        />
      )}
    </Fragment>
  )
}

import { createSlice } from '@reduxjs/toolkit'

const name = 'navigationReducer'

const initialState = {
  selectedLevel: 'organization',
  locationSelector: new Map(),
  permissions: {},
  optionsRoute: [],
  optionsSensor: [],
  panelPrimaryOpen: false,
  panelPrimaryLock: false,
  panelLocationSelectorOpen: false
}

const reducers = {
  resetNavigation: state => initialState,
  setLocationSelector: (state, action) => {
    if (action?.payload instanceof Map) {
      state.locationSelector = action.payload
    } else {
      throw new Error(
        '[TYPE MISMATCH]: setLocationSelector expected a <Map> as value'
      )
    }
  },
  setOptionsRoute: (state, action) => {
    if (Array.isArray(action?.payload)) {
      state.optionsRoute = action.payload
    } else {
      throw new Error(
        '[TYPE MISMATCH]: setOptionsRoute expected an <Array> as value'
      )
    }
  },
  setOptionsSensor: (state, action) => {
    if (Array.isArray(action?.payload)) {
      state.optionsSensor = action.payload
    } else {
      throw new Error(
        '[TYPE MISMATCH]: setOptionsSensor expected an <Array> as value'
      )
    }
  },
  setPanelPrimaryOpen: (state, action) => {
    if (action?.payload !== undefined && typeof action?.payload === 'boolean') {
      state.panelPrimaryOpen = action?.payload
    } else {
      throw new Error(
        '[TYPE MISMATCH]: setPanelPrimaryOpen expected a <Boolean> as value'
      )
    }
  },
  setPermissions: (state, action) => {
    if (action?.payload) {
      state.permissions = action.payload
    } else {
      throw new Error(
        '[TYPE MISMATCH]: setPermissions expected a <Object> as value'
      )
    }
  },
  setSelectedLevel: (state, action) => {
    if (action?.payload?.length) {
      state.selectedLevel = action.payload
    } else {
      throw new Error(
        '[TYPE MISMATCH]: setSelectedLevel expected a <String> as value'
      )
    }
  },
  togglePanelPrimaryOpen: state => {
    state.panelPrimaryOpen = !state.panelPrimaryOpen
  },
  togglePanelPrimaryLock: state => {
    state.panelPrimaryLock = !state.panelPrimaryLock
  },
  togglePanelLocationSelectorOpen: state => {
    state.panelLocationSelectorOpen = !state.panelLocationSelectorOpen
  }
}

const navigationSlice = createSlice({ name, initialState, reducers })

const { actions, reducer } = navigationSlice

export const {
  resetNavigation,
  setLocationSelector,
  setOptionsRoute,
  setOptionsSensor,
  setPanelPrimaryOpen,
  setPermissions,
  setSelectedLevel,
  togglePanelLocationSelectorOpen,
  togglePanelPrimaryLock,
  togglePanelPrimaryOpen
} = actions

export default reducer

import React, { Fragment } from 'react'

import { useDispatch } from 'react-redux'

import { Symbol, Toggle } from '@/primitives'

import {
  sendAddRolePermission,
  sendRemoveRolePermission
} from '@/slices/management/role'

import { getRolePermissions } from '@/reducers/selectors'

const PermissionToggle = ({
  checked,
  permissionId,
  sectionName,
  dispatch,
  indeterminate = false,
  disabled = false
}) => {
  const reduxDispatch = useDispatch()

  const role = getRolePermissions()

  const onClickPermissionToggle = () => {
    if (disabled || !permissionId) return null

    dispatch({
      type: 'togglePermission',
      params: {
        permissionId,
        isEnabled: !checked
      }
    })

    const params = {
      sectionName,
      role: {
        roleName: role.name
      },
      permission: {
        id: permissionId,
        enabled: checked
      }
    }

    checked
      ? reduxDispatch(sendRemoveRolePermission(params))
      : reduxDispatch(sendAddRolePermission(params))
  }

  return (
    <Fragment>
      {!permissionId && <Symbol name='block' variant='page' tone='500' />}
      {permissionId && (
        <Toggle
          checked={checked}
          disabled={disabled}
          indeterminate={indeterminate}
          onChange={onClickPermissionToggle}
        />
      )}
    </Fragment>
  )
}

export default PermissionToggle

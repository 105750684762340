import { DateTime } from 'luxon'

import { COMPARISON_TYPES, INTERVAL_TIMESTREAM_MAP } from './config'
import { getMostSpecificZoneFilter } from './FiltersUtils'

const formatToUTC = (date, timezone) => {
  const newDate = DateTime.fromMillis(date).setZone(timezone).toISO()
  return DateTime.fromISO(newDate).setZone(timezone).toUTC().toString()
}

export function parseDateFilters(state) {
  if (state.comparisonType === COMPARISON_TYPES.LOCATION_RANGES) {
    // sidebar filter represents a date filter
    const { fromDate, toDate, timeInterval, timeZone } =
      state.sidebarFilter[COMPARISON_TYPES.DATE_RANGES]
    return [
      {
        ...state.sidebarFilter[COMPARISON_TYPES.DATE_RANGES],
        fromDate: formatToUTC(fromDate, timeZone),
        toDate: formatToUTC(toDate, timeZone),
        timeInterval: INTERVAL_TIMESTREAM_MAP[timeInterval]
      }
    ]
  }

  return state.dateFilters.map(filter => ({
    dateRange: filter.dateRange,
    fromDate: formatToUTC(filter.fromDate, filter.timeZone),
    toDate: formatToUTC(filter.toDate, filter.timeZone),
    timeInterval: INTERVAL_TIMESTREAM_MAP[filter.timeInterval],
    timeZone: filter.timeZone
  }))
}

export function parseZoneFilters(state) {
  if (state.comparisonType === COMPARISON_TYPES.DATE_RANGES) {
    // sidebar filter represents a zone filter
    return [
      {
        ...state.sidebarFilter[COMPARISON_TYPES.LOCATION_RANGES],
        sensorId: getMostSpecificZoneFilter(
          state.sidebarFilter[COMPARISON_TYPES.LOCATION_RANGES]
        )
      }
    ]
  }

  const { fromDate, toDate, timeInterval, timeZone, dateRange } =
    state.sidebarFilter[COMPARISON_TYPES.DATE_RANGES]

  return state.zoneFilters.map(filter => ({
    organizationId: filter.organizationId,
    siteId: filter.siteId,
    zoneId: getMostSpecificZoneFilter(filter),
    sensorId: filter.sensorId,
    fromDate: formatToUTC(fromDate, timeZone),
    toDate: formatToUTC(toDate, timeZone),
    interval: INTERVAL_TIMESTREAM_MAP[timeInterval],
    timeZone,
    dateRange
  }))
}

import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import { Button, FlexV2, Separator, Text } from '@/primitives'
import { Tooltip } from '@/elements'

import StatusIcon from '../../shared/StatusIcon'
import StatusText from '../../shared/StatusText'

import { getMeasurements } from '@/reducers/selectors'

import Strings from '../../Strings'

/**
 * Renders a card for an alert in the StatusPage/Alerts page.
 *
 * @param {Object} props - The props to pass to the component.
 * @param {string} props.name - The name of the alert.
 * @param {Object} props.viewData - The view data of the alert.
 * @param {string} props.id - The ID of the alert.
 * @param {string} props.sensorType - The type of the sensor associated with the alert.
 * @param {string} props.hardwareVersion - The hardware version of the sensor associated with the alert.
 * @param {string} props.zoneId - The ID of the zone associated with the alert.
 * @param {boolean} props.expandAll - Whether the alert should be expanded or not.
 * @returns {React.ReactElement} A React component that displays a card for an alert in the StatusPage component.
 */
export default function Card({
  name,
  viewData,
  id,
  sensorType,
  hardwareVersion,
  zoneId,
  expandAll
}) {
  const tipRef = useRef(null)
  const [open, setOpen] = useState(expandAll)
  const [showTooltip, setShowTooltip] = useState(false)

  const measurements = getMeasurements()

  const handleClick = () => {
    setOpen(!open)
  }

  useEffect(() => {
    setOpen(expandAll)
  }, [expandAll])

  const strings = Strings()

  const { missingData, reasons } = viewData
  const missingDataReasons = missingData ? reasons['missingData'] ?? [] : []

  function renderMeasurementName(id) {
    const measurement = measurements.find(measurement => measurement.id === id)
    return measurement?.shortName ?? id
  }

  function onMouseEnter() {
    setShowTooltip(true)
  }

  function onMouseLeave() {
    setShowTooltip(false)
  }

  return (
    <FlexV2
      direction='column'
      className='StatusPage__Content__Alerts__Card'
      axisGap={400}
      key={id}
      aria-expanded={open}
    >
      <FlexV2
        axisGap={200}
        alignCrossAxis='center'
        alignMainAxis='space-between'
        className='StatusPage__Content__Alerts__Card_Header'
      >
        <FlexV2 alignCrossAxis='center' axisGap={200}>
          <StatusIcon status={viewData.status} />
          <StatusText
            status={viewData.status}
            text={strings[viewData.status]}
          />
        </FlexV2>
        <Button
          size={'small'}
          iconBefore={open ? 'unfold_less' : 'unfold_more'}
          onClick={handleClick}
        >
          {strings[open ? 'collapse' : 'expand']}
        </Button>
      </FlexV2>

      <FlexV2
        axisGap={500}
        className='StatusPage__Content__Alerts__Card_Values'
        wrap='wrap'
      >
        {/* ------------------------------------------------------------ Who */}
        <FlexV2 direction='column' className={'fullwidth'}>
          <Text
            size={100}
            fontFamily='mono'
            truncate={20}
            variant='page'
            tone={800}
          >
            {strings.name}
          </Text>
          <Text size={200} fontFamily='mono'>
            {name}
          </Text>
        </FlexV2>

        <FlexV2 direction='column' className={'fullwidth'}>
          <Text
            size={100}
            fontFamily='mono'
            truncate={20}
            variant='page'
            tone={800}
          >
            {strings.sensorType}
          </Text>
          <Text size={200} fontFamily='mono'>
            {sensorType}
          </Text>
        </FlexV2>

        <FlexV2 direction='column'>
          <Text
            size={100}
            fontFamily='mono'
            truncate={20}
            variant='page'
            tone={800}
          >
            {strings.sensorId}
          </Text>
          <Text size={200} fontFamily='mono'>
            {id}
          </Text>
        </FlexV2>

        <FlexV2 direction='column'>
          <Text
            size={100}
            fontFamily='mono'
            truncate={20}
            variant='page'
            tone={800}
          >
            {strings.zoneId}
          </Text>
          <Text size={200} fontFamily='mono'>
            {zoneId}
          </Text>
        </FlexV2>

        <FlexV2 direction='column'>
          <Text
            size={100}
            fontFamily='mono'
            truncate={20}
            variant='page'
            tone={800}
          >
            {strings.hardwareVersion}
          </Text>
          <Text size={200} fontFamily='mono'>
            {hardwareVersion || strings.unknown}
          </Text>
        </FlexV2>

        {/* ----------------------------------------------------------- What */}
        <Separator />

        <FlexV2 direction='column' className={'fullwidth'}>
          <Text
            size={100}
            fontFamily='mono'
            truncate={20}
            variant='page'
            tone={800}
          >
            {strings.thresholdTrigger}
          </Text>
          <Text size={200} fontFamily='mono' textTransform='capitalize'>
            {viewData.thresholdTrigger}
          </Text>
        </FlexV2>

        <FlexV2 direction='column'>
          <Text
            size={100}
            fontFamily='mono'
            truncate={20}
            variant='page'
            tone={800}
          >
            {strings.thresholdValue}
          </Text>
          <Text size={200} fontFamily='mono'>
            {viewData.thresholdValue}
          </Text>
        </FlexV2>

        <FlexV2 direction='column'>
          <Text
            size={100}
            fontFamily='mono'
            truncate={20}
            variant='page'
            tone={800}
          >
            {strings.lastValue}
          </Text>
          <Text size={200} fontFamily='mono'>
            {viewData.lastValue}
          </Text>
        </FlexV2>

        <FlexV2
          direction='column'
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <Text
            size={100}
            fontFamily='mono'
            truncate={20}
            variant='page'
            tone={800}
          >
            {strings.missingData}
          </Text>
          <Tooltip
            tip={missingDataReasons.map(renderMeasurementName).join(', ')}
            tipRef={tipRef}
            place='top'
            showTooltip={showTooltip}
          >
            <Text size={200} fontFamily='mono' textTransform='capitalize'>
              {viewData.missingData.toString()}
            </Text>
          </Tooltip>
        </FlexV2>

        <FlexV2 direction='column'>
          <Text
            size={100}
            fontFamily='mono'
            truncate={20}
            variant='page'
            tone={800}
          >
            {strings.isInUse}
          </Text>
          <Text size={200} fontFamily='mono' textTransform='capitalize'>
            {(!viewData.notInUse).toString()}
          </Text>
        </FlexV2>

        {/* ----------------------------------------------------------- When */}
        <Separator />

        <FlexV2 direction='column'>
          <Text
            size={100}
            fontFamily='mono'
            truncate={20}
            variant='page'
            tone={800}
          >
            {strings.lastSending}
          </Text>
          <Text size={200} fontFamily='mono'>
            {viewData.lastSending}
          </Text>
        </FlexV2>

        <FlexV2 direction='column'>
          <Text
            size={100}
            fontFamily='mono'
            truncate={20}
            variant='page'
            tone={800}
          >
            {strings.lastChecked}
          </Text>
          <Text size={200} fontFamily='mono'>
            {viewData.lastChecked}
          </Text>
        </FlexV2>
      </FlexV2>

      <Link to={viewData.sensorDashboardURL}>
        {strings.goToSensorDashboard}
      </Link>
    </FlexV2>
  )
}

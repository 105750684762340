import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { getCognitoData, makeGraphqlRequest } from '../utils'

/*
 * Queries
 */

export const getCurrentUser = async () => {
  try {
    const query = /* GraphQL */ `
      query getCurrentUser {
        getCurrentUser {
          firstName
          lastName
          userName
          email
          phoneNumber
          sub
          cognitoId
          organizationIds
          role
          featureFlags {
            featureFlagId
            objectId
            type
          }
          settings {
            objectId
            type
            settings {
              id
              name
              value
            }
          }
          permissions {
            id
            operationType
            resourceId
            resourceType
          }
          createdTimestamp
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, {})
    const { data } = await makeGraphqlRequest(GRAPHQL_URL, operation, idToken)

    if (!data?.getCurrentUser?.userName) {
      return {
        currentUser: null,
        error: 'Unable to fetch current user'
      }
    }

    return { currentUser: data?.getCurrentUser, error: null }
  } catch (err) {
    return { currentUser: null, error: err.message }
  }
}

export const getUsers = async params => {
  try {
    const { page, pageSize, search = null, roleName } = params
    const query = /* GraphQL */ `
      query getAllUsers(
        $page: Int
        $pageSize: Int
        $search: String
        $roleName: String
      ) {
        getAllUsers(
          page: $page
          pageSize: $pageSize
          search: $search
          roleName: $roleName
        ) {
          page
          pageSize
          search
          totalItems
          result {
            firstName
            lastName
            userName
            email
            phoneNumber
            sub
            cognitoId
            role
            createdTimestamp
            cognitoStatus
          }
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, {
      page,
      pageSize,
      search,
      roleName
    })
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        users: null,
        error: `Users could not be fetched: ${error}`
      }
    }

    return { users: data?.getAllUsers, error }
  } catch (err) {
    return { users: null, error: err.message }
  }
}

export const getAllUsers = async (params = {}) => {
  try {
    const { search = '', roleName } = params
    const query = /* GraphQL */ `
      query getAllUsers(
        $search: String
        $roleName: String
        $noPagination: Boolean
      ) {
        getAllUsers(
          search: $search
          roleName: $roleName
          noPagination: $noPagination
        ) {
          search
          totalItems
          result {
            firstName
            lastName
            userName
            email
            phoneNumber
            sub
            cognitoId
            role
            createdTimestamp
          }
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, {
      search,
      roleName,
      noPagination: true
    })
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        users: null,
        error: `All users could not be fetched: ${error}`
      }
    }

    return { users: data?.getAllUsers, error }
  } catch (err) {
    return { users: null, error: err.message }
  }
}

export const getUser = async params => {
  try {
    const { userName } = params
    const query = /* GraphQL */ `
      query getUser($username: String!) {
        getUser(username: $username) {
          firstName
          lastName
          userName
          email
          phoneNumber
          sub
          cognitoId
          role
          createdTimestamp
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, { username: userName })
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )
    if (error) {
      return {
        user: null,
        error: `User could not be fetched: ${error}`
      }
    }

    return { user: data?.getUser, error: null }
  } catch (err) {
    return { user: null, error: err.message }
  }
}

/*
 * Mutations
 */

export const createUser = async params => {
  try {
    const { user } = params
    const mutation = /* GraphQL */ `
      mutation createUser(
        $username: String!
        $email: String!
        $firstName: String!
        $lastName: String!
        $role: String!
        $phoneNumber: String
        $sub: String
        $cognitoId: String
      ) {
        createUser(
          username: $username
          email: $email
          firstName: $firstName
          lastName: $lastName
          role: $role
          phoneNumber: $phoneNumber
          sub: $sub
          cognitoId: $cognitoId
        ) {
          firstName
          lastName
          userName
          email
          phoneNumber
          sub
          cognitoId
          role
          createdTimestamp
        }
      }
    `

    const { idToken } = await getCognitoData()
    const payload = Object.assign(user, {
      username: user.userName.toLowerCase()
    })
    const operation = graphqlOperation(mutation, payload)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        user: null,
        error: `Request failed with error: ${error}`
      }
    }

    return { user: data?.createUser, error: null }
  } catch (err) {
    if (err.response && err.response.data) {
      return { user: null, error: err.response.data.errMessage }
    }
    return { user: null, error: err.message }
  }
}

export const updateUser = async params => {
  try {
    const { user } = params
    const mutation = /* GraphQL */ `
      mutation updateUser(
        $username: String!
        $email: String!
        $firstName: String!
        $lastName: String!
        $role: String!
        $phoneNumber: String
        $sub: String
        $cognitoId: String
      ) {
        updateUser(
          username: $username
          email: $email
          firstName: $firstName
          lastName: $lastName
          role: $role
          phoneNumber: $phoneNumber
          sub: $sub
          cognitoId: $cognitoId
        ) {
          firstName
          lastName
          userName
          email
          phoneNumber
          sub
          cognitoId
          role
          createdTimestamp
        }
      }
    `

    const { idToken } = await getCognitoData()
    const payload = Object.assign(user, { username: user.userName })
    const operation = graphqlOperation(mutation, payload)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        user: null,
        error: `Request failed with error: ${error}`
      }
    }

    return { user: data?.updateUser, error: null }
  } catch (err) {
    return { user: null, error: err.message }
  }
}

export const deleteUser = async params => {
  try {
    const { userName } = params
    const mutation = /* GraphQL */ `
      mutation deleteUser($username: String!) {
        deleteUser(username: $username) {
          userName
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, { username: userName })
    const { error } = await makeGraphqlRequest(GRAPHQL_URL, operation, idToken)

    if (error) {
      return { error: `Request failed with error: ${error}` }
    }

    return { error: null }
  } catch (err) {
    return { error: err.message }
  }
}

export const sendUserInvite = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation resendUserInvite($username: String!) {
        resendUserInvite(username: $username) {
          firstName
          lastName
          userName
          email
          phoneNumber
          sub
          cognitoId
          role
          createdTimestamp
          cognitoStatus
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        user: null,
        error: `Request failed with error: ${error}`
      }
    }

    return { user: data?.resendUserInvite, error: null }
  } catch (err) {
    return { user: null, error: err.message }
  }
}

import { I18n } from 'aws-amplify'
import { object, string, date } from 'yup'

export const FIELDS = {
  id: 'id',
  organizationId: 'organizationId',
  name: 'name',
  code: 'code',
  category: 'category',
  status: 'status',
  onboardDate: 'onboardDate',
  contactName: 'contactName',
  contactNumber: 'contactNumber',
  contactEmail: 'contactEmail',
  countryId: 'countryId',
  regionId: 'regionId',
  cityId: 'cityId',
  currencyId: 'currencyId',
  paymentMethodId: 'paymentMethodId',
  paymentTermId: 'paymentTermId',
  registrationNumber: 'registrationNumber',
  registrationS3Key: 'registrationS3Key',
  taxNumber: 'taxNumber',
  taxS3Key: 'taxS3Key',
  nationalAddress: {
    streetAddress: 'streetAddress',
    streetAddress2: 'streetAddress2',
    city: 'city',
    region: 'region',
    country: 'country',
    zipCode: 'zipCode'
  },
  nationalAddressS3Key: 'nationalAddressS3Key',
  bankDetails: {
    bankName: 'bankName',
    branchName: 'branchName',
    branchCode: 'branchCode',
    branchAddress: {
      streetAddress: 'streetAddress',
      streetAddress2: 'streetAddress2',
      city: 'city',
      region: 'region',
      country: 'country',
      zipCode: 'zipCode'
    },
    swiftBic: 'swiftBic',
    iban: 'iban'
  },
  bankDetailsS3Key: 'bankDetailsS3Key',
  contractS3Key: 'contractS3Key'
}

export const customerSchema = () => {
  return object().shape({
    organizationId: string().required(
      I18n.get('An organization must be selected.')
    ),
    name: string().required(I18n.get('Name is required.')),
    code: string().required(I18n.get('Please provide a code.')),
    category: string().required('Category is required field.'),
    status: string().required('Status is required field.'),
    onboardDate: date()
      .typeError('An onboarding date is required.')
      .max(
        new Date(),
        I18n.get('The onboarding date cannot be set in the future.')
      )
      .required(I18n.get('An onboarding date is required.')),
    contactName: string().required(I18n.get('Contact Name is required.')),
    contactNumber: string().required(I18n.get('Contact Number is required.')),
    contactEmail: string()
      .email('Contact Email is invalid')
      .required(I18n.get('Contact Email is required.')),
    countryId: string().required(I18n.get('A country must be selected.')),
    regionId: string().required(I18n.get('A region must be selected.')),
    cityId: string().required(I18n.get('A city must be selected.')),
    currencyId: string().required(I18n.get('A currency must be selected.')),
    paymentMethodId: string().required(
      I18n.get('A payment method must be selected.')
    ),
    paymentTermId: string().required(
      I18n.get('A payment term must be selected.')
    )
  })
}

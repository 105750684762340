import React, { Fragment, useEffect, useState } from 'react'

import useDeviceSize from '@/hooks/useDeviceSize'

import { Text, Table, Slot, Row, Cell } from '@/primitives'

import MeasurementForm from './Form'

import { REQUIRED_MEASUREMENTS } from '../utils'

import Strings from '../../Strings'

function ProduceThresholdMeasurements({
  produceThresholdId,
  formState,
  errors,
  dispatchFormState,
  useFahrenheit
}) {
  const isMobile = useDeviceSize('mobile')
  const strings = Strings()

  const [measurements, setMeasurements] = useState(null)

  useEffect(() => {
    if (!produceThresholdId || formState?.name) {
      if (measurements === null) {
        let newMeasurements = JSON.parse(JSON.stringify(formState.measurements))
        if (newMeasurements.length !== REQUIRED_MEASUREMENTS.length) {
          for (let measurement of REQUIRED_MEASUREMENTS) {
            if (newMeasurements.findIndex(nm => nm.key === measurement) < 0) {
              newMeasurements.push({
                key: measurement,
                bounds: []
              })
            }
          }
        }
        setMeasurements(newMeasurements)
      }
    }
  }, [formState])

  return (
    <Table aria-colcount='3'>
      <Slot name='body'>
        {!isMobile && (
          <Fragment>
            <Row>
              <Cell>
                <Text variant='page' tone={700}>
                  {strings.fieldMeasurement}
                </Text>
              </Cell>
              <Cell colSpan='3'>
                <Text variant='page' tone={700}>
                  {strings.fieldsetLower}
                </Text>
              </Cell>
              <Cell colSpan='3'>
                <Text variant='page' tone={700}>
                  {strings.fieldsetUpper}
                </Text>
              </Cell>
              <Cell>
                <Text variant='page' tone={700}>
                  {strings.fieldUnit}
                </Text>
              </Cell>
            </Row>
            <Row>
              <Cell />
              <Cell>
                <Text variant='page' tone={700}>
                  {strings.fieldStress}
                </Text>
              </Cell>
              <Cell>
                <Text variant='page' tone={700}>
                  {strings.fieldBad}
                </Text>
              </Cell>
              <Cell>
                <Text variant='page' tone={700}>
                  {strings.fieldGood}
                </Text>
              </Cell>
              <Cell>
                <Text variant='page' tone={700}>
                  {strings.fieldGood}
                </Text>
              </Cell>
              <Cell>
                <Text variant='page' tone={700}>
                  {strings.fieldBad}
                </Text>
              </Cell>
              <Cell>
                <Text variant='page' tone={700}>
                  {strings.fieldStress}
                </Text>
              </Cell>
              <Cell />
            </Row>
          </Fragment>
        )}
        {REQUIRED_MEASUREMENTS.map((measurement, index) => (
          <MeasurementForm
            key={measurement}
            measurements={measurements}
            measurementKey={measurement}
            errors={errors}
            index={index}
            dispatchFormState={dispatchFormState}
            useFahrenheit={useFahrenheit}
          />
        ))}
      </Slot>
    </Table>
  )
}

export default ProduceThresholdMeasurements

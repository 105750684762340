import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { makeGraphqlRequestV2 } from '../utils'

/*
 * Queries
 */

export const getRootZone = async params => {
  try {
    const query = /* GraphQL */ `
      query getZone($zoneId: String!) {
        getZone(zoneId: $zoneId) {
          id
          name
          status
          code
          organizationId
          parentId
          parentPath
          latitude
          longitude
          timeZone
          provisioningConfig
          greengrassCoreDevice
          maintenanceMode
        }
      }
    `

    const operation = graphqlOperation(query, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.getZone
  } catch (err) {
    throw err.message
  }
}

export const createZone = async params => {
  try {
    const { zone } = params
    const mutation = /* GraphQL */ `
      mutation createZone(
        $organizationId: String
        $parentId: String
        $status: ZoneStatusEnum
        $code: String
        $name: String!
        $timeZone: String!
        $latitude: Float
        $longitude: Float
      ) {
        createZone(
          organizationId: $organizationId
          parentId: $parentId
          status: $status
          code: $code
          name: $name
          timeZone: $timeZone
          latitude: $latitude
          longitude: $longitude
        ) {
          id
          name
          status
          code
          organizationId
          parentId
          parentPath
          latitude
          longitude
          timeZone
          provisioningConfig
          greengrassCoreDevice
          hasCoreDevice
          maintenanceMode
        }
      }
    `

    const operation = graphqlOperation(mutation, zone)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data.createZone
  } catch (err) {
    return err.message
  }
}

export const updateZone = async params => {
  try {
    const { zone } = params
    const mutation = /* GraphQL */ `
      mutation updateZone(
        $organizationId: String
        $parentId: String
        $status: ZoneStatusEnum
        $code: String
        $id: String!
        $name: String!
        $timeZone: String!
        $latitude: Float
        $longitude: Float
      ) {
        updateZone(
          organizationId: $organizationId
          parentId: $parentId
          status: $status
          code: $code
          id: $id
          name: $name
          timeZone: $timeZone
          latitude: $latitude
          longitude: $longitude
        ) {
          id
          name
          status
          code
          organizationId
          parentId
          parentPath
          latitude
          longitude
          timeZone
          provisioningConfig
          greengrassCoreDevice
          hasCoreDevice
          maintenanceMode
        }
      }
    `

    const operation = graphqlOperation(mutation, zone)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.updateZone
  } catch (err) {
    return err.message
  }
}

export const getZone = async params => {
  try {
    const { id } = params
    const query = /* GraphQL */ `
      query getZone($zoneId: String!) {
        getZone(zoneId: $zoneId) {
          id
          name
          status
          code
          organizationId
          parentId
          parentPath
          latitude
          longitude
          timeZone
          provisioningConfig
          greengrassCoreDevice
          hasCoreDevice
          maintenanceMode
        }
      }
    `

    const operation = graphqlOperation(query, { zoneId: id })
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.getZone
  } catch (err) {
    return err.message
  }
}

export const getZonesList = async params => {
  try {
    const query = /* GraphQL */ `
      query getZones($parentId: String) {
        getZones(parentId: $parentId) {
          id
          name
          status
          code
          organizationId
          parentId
          parentPath
          latitude
          longitude
          timeZone
          maintenanceMode
          provisioningConfig
          greengrassCoreDevice
          hasCoreDevice
        }
      }
    `

    const operation = graphqlOperation(query, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.getZones ?? []
  } catch (err) {
    throw err.message
  }
}

export const getZoneDevices = async params => {
  try {
    const query = /* GraphQL */ `
      query getZoneDevices($zoneId: String!) {
        getZoneDevices(zoneId: $zoneId) {
          id
          name
          sensorType
          zoneId
          zonePath
          proofOfPossession
          hardwareVersion
          latitude
          longitude
          tag
          createdTimestamp
          isIoT
          isGreengrass
          config
          lastMoveAt
          notInUse
          sleepCycleMinutes
          supplierDevice {
            deviceId
            name
            type
            enrolled
          }
        }
      }
    `

    const operation = graphqlOperation(query, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.getZoneDevices ?? []
  } catch (err) {
    throw err.message
  }
}

export const getZoneUsers = async params => {
  try {
    const query = /* GraphQL */ `
      query getZoneUsers($zoneId: String!) {
        getZoneUsers(zoneId: $zoneId) {
          firstName
          lastName
          userName
          email
          phoneNumber
          sub
          cognitoId
          role
          createdTimestamp
        }
      }
    `

    const operation = graphqlOperation(query, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)
    return data?.getZoneUsers
  } catch (err) {
    throw err.message
  }
}

export async function getZonesData(parent) {
  try {
    const queryZones = /* GraphQL */ `
      query getZones($parentId: String) {
        getZones(parentId: $parentId) {
          id
          name
          status
          organizationId
          parentId
          parentPath
          latitude
          longitude
          timeZone
          maintenanceMode
        }
      }
    `

    const operation = graphqlOperation(queryZones, { parentId: parent })
    const dataZones = await makeGraphqlRequestV2(GRAPHQL_URL, operation)
    const zonesResponse = dataZones?.getZones ?? []

    let sensorsResponse = null

    if (parent) {
      const queryDevices = /* GraphQL */ `
        query getZoneDevices($zoneId: String!) {
          getZoneDevices(zoneId: $zoneId) {
            id
            name
            sensorType
            zoneId
            zonePath
            proofOfPossession
            hardwareVersion
            tag
            latitude
            longitude
            createdTimestamp
            lastMoveAt
            notInUse
            sleepCycleMinutes
          }
        }
      `

      const operation = graphqlOperation(queryDevices, {
        zoneId: parent
      })
      const dataDevices = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

      sensorsResponse = dataDevices?.getZoneDevices ?? []
    }

    const result = {
      zones: zonesResponse.map(
        ({
          id,
          name,
          organizationId,
          latitude,
          longitude,
          timeZone,
          status,
          sleepCycleMinutes
        }) => ({
          id,
          name,
          organizationId,
          latitude,
          longitude,
          timeZone,
          status,
          sleepCycleMinutes
        })
      ),
      sensors:
        sensorsResponse &&
        sensorsResponse.map(({ id, name, sensorType, tag }) => ({
          thingName: id,
          thingTypeName: sensorType,
          attributes: { displayName: name, tag }
        }))
    }

    return result
  } catch (err) {
    throw err.message
  }
}

/*
 * Mutations
 */

export const updateZoneMaintenanceMode = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateZoneMaintenanceMode(
        $id: String!
        $maintenanceMode: Boolean
      ) {
        updateZoneMaintenanceMode(id: $id, maintenanceMode: $maintenanceMode) {
          id
          name
          status
          code
          organizationId
          parentId
          parentPath
          latitude
          longitude
          timeZone
          provisioningConfig
          greengrassCoreDevice
          hasCoreDevice
          maintenanceMode
        }
      }
    `

    const operation = graphqlOperation(mutation, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.updateZoneMaintenanceMode
  } catch (err) {
    return err.message
  }
}

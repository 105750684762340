import { updateList } from '../util'

import {
  RECEIVE_INVENTORY_TRANSFERS,
  REQUEST_INVENTORY_TRANSFERS,
  CLEAR_INVENTORY_TRANSFERS,
  CLEAR_INVENTORY_TRANSFER,
  REQUEST_INVENTORY_TRANSFER,
  RECEIVE_INVENTORY_TRANSFER,
  CREATE_INVENTORY_TRANSFER,
  INVENTORY_TRANSFER_CREATED,
  UPDATE_INVENTORY_TRANSFER,
  INVENTORY_TRANSFER_UPDATED,
  SET_INVENTORY_TRANSFER
} from '@/actions/operations/inventoryTransfer'

export const inventoryTransferInitialState = {
  inventoryTransfers: [],
  inventoryTransfer: {},
  inventoryTransfersCount: 0,
  loading: false,
  error: null
}

const handlers = {
  [CLEAR_INVENTORY_TRANSFER]: (state, action) => ({
    ...state,
    inventoryTransfer: {},
    error: null
  }),
  [CLEAR_INVENTORY_TRANSFERS]: (state, action) => ({
    ...state,
    inventoryTransfers: [],
    inventoryTransfersCount: 0,
    error: null
  }),
  [REQUEST_INVENTORY_TRANSFER]: (state, action) => ({
    ...state,
    inventoryTransfer: {},
    error: null,
    loading: true
  }),
  [RECEIVE_INVENTORY_TRANSFER]: (state, action) => ({
    ...state,
    inventoryTransfer: !action.error
      ? action.inventoryTransfer
      : state.inventoryTransfer,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_INVENTORY_TRANSFERS]: (state, action) => ({
    ...state,
    inventoryTransfers: [],
    inventoryTransfersCount: 0,
    error: null,
    loading: true
  }),
  [RECEIVE_INVENTORY_TRANSFERS]: (state, action) => ({
    ...state,
    inventoryTransfers: !action.error ? action.inventoryTransfers : [],
    inventoryTransfersCount: !action.error ? action.count : 0,
    error: action.error ?? null,
    loading: false
  }),
  [SET_INVENTORY_TRANSFER]: (state, action) => ({
    ...state,
    inventoryTransfer: action.inventoryTransfer
  }),
  [CREATE_INVENTORY_TRANSFER]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [INVENTORY_TRANSFER_CREATED]: (state, action) => ({
    ...state,
    inventoryTransfer: !action.error ? {} : state.inventoryTransfer,
    inventoryTransfers: updateList(
      action.error,
      state.inventoryTransfers,
      action.inventoryTransfer,
      true
    ),
    inventoryTransfersCount: !action.error
      ? state.inventoryTransfersCount + 1
      : state.inventoryTransfersCount,
    error: action.error ?? null,
    loading: false
  }),
  [UPDATE_INVENTORY_TRANSFER]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [INVENTORY_TRANSFER_UPDATED]: (state, action) => ({
    ...state,
    inventoryTransfer: !action.error ? {} : state.inventoryTransfer,
    inventoryTransfers: updateList(
      action.error,
      state.inventoryTransfers,
      action.inventoryTransfer,
      false
    ),
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

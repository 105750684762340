import withEditableWidget from '@/components/DashboardPageV2/Desktop/Widgets/Shared/HOC/withEditableWidget'
import { MeasurementEdit } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Measurement'
import { getMeasurements } from '@/reducers/selectors'
import {
  AVERAGE_QUERY,
  DATASET_QUERY,
  LIVE_QUERY
} from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'
import LiveValue from '@/components/DashboardPageV2/Desktop/Widgets/Fertigation/TotalDissolvedSolids/LiveValue'
import DatasetLine from '@/components/DashboardPageV2/Desktop/Widgets/Fertigation/TotalDissolvedSolids/DatasetLine'
import AverageValue from '@/components/DashboardPageV2/Desktop/Widgets/Fertigation/TotalDissolvedSolids/AverageValue'

function TotalDissovledSolidsView(props) {
  const { config, options } = props

  const measurement = options.measurement
  const measurements = getMeasurements()

  const unit = measurements?.find(m => m.id === measurement)?.unit

  if (config.query === LIVE_QUERY) {
    return <LiveValue unit={unit} config={config} options={options} />
  }
  if (config.query === DATASET_QUERY) {
    return (
      <DatasetLine unit={unit} config={config} options={options} {...props} />
    )
  }
  if (config.query === AVERAGE_QUERY) {
    return <AverageValue unit={unit} config={config} options={options} />
  }
}

export const TotalDissovledSolids = withEditableWidget(
  TotalDissovledSolidsView,
  MeasurementEdit
)

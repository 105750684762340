import { I18n } from 'aws-amplify'
import { useReducer, useEffect, useCallback, useRef } from 'react'
import { Link } from 'react-router-dom'
import { debounce } from 'lodash'

import { FlexV2, Icon, Text } from '@/primitives'
import { STATUS_SYMBOL_COLOR } from '../utils'

// Initial state setup
const initialState = {
  icon: 'pending',
  variant: 'primary',
  tone: 800,
  message: ''
}
function reducer(state, payload) {
  return { ...state, ...payload }
}

export default function ThreholdSensor({
  item,
  snooze,
  useLink = true,
  useMessage = true,
  isLoading = false
}) {
  const [options, dispatch] = useReducer(reducer, initialState)

  const isInitialCall = useRef(true)

  const debounceDispatch = useCallback(args => {
    if (isInitialCall.current) {
      dispatch(args)
      isInitialCall.current = false
    } else {
      const debounced = debounce(dispatch, 500)
      debounced(args)
    }
  }, [])

  useEffect(() => {
    let icon, variant, tone
    let message =
      item.statusInfo.status.boundType === 'upper'
        ? I18n.get('Above on')
        : I18n.get('Below on')

    if (isLoading) {
      icon = 'pending'
      variant = 'primary'
      tone = 800
    } else {
      const isSnoozed = snooze?.timeInMin > 0
      if (isSnoozed) {
        icon = 'snooze'
        variant = 'info'
        tone = 800
      } else {
        icon = 'warning'
        variant =
          STATUS_SYMBOL_COLOR?.[item.statusInfo.status.level] ?? 'primary'
        tone = 500
      }
    }

    debounceDispatch({ icon, variant, tone, message })
  }, [isLoading, item, snooze])

  const { sensorZonePath, sensorName, sensorType, sensorId } = item
  const { icon, variant, tone, message } = options

  return (
    <FlexV2 axisGap={300} alignCrossAxis='center'>
      <Icon
        name={icon}
        size={300}
        style={{ flexShrink: 0, cursor: 'default' }}
        variant={variant}
        tone={tone}
      />

      <Text>
        {useMessage && message}{' '}
        {useLink ? (
          <Link
            style={{
              padding: 0,
              margin: 0
            }}
            to={`/zones${sensorZonePath}/sensor/${sensorType}/${sensorId}`}
          >
            {sensorName}
          </Link>
        ) : (
          sensorName
        )}
      </Text>
    </FlexV2>
  )
}

import { makeActionCreator } from '@/Util/ReduxUtils'

export const REQUEST_ALL_PACKAGE_UNITS = 'REQUEST_ALL_PACKAGE_UNITS'
export const requestAllPackageUnits = makeActionCreator(
  REQUEST_ALL_PACKAGE_UNITS
)

export const RECEIVE_ALL_PACKAGE_UNITS = 'RECEIVE_ALL_PACKAGE_UNITS'
export const receiveAllPackageUnits = makeActionCreator(
  RECEIVE_ALL_PACKAGE_UNITS,
  'packageUnits',
  'count',
  'error'
)

export const REQUEST_PACKAGE_UNITS = 'REQUEST_PACKAGE_UNITS'
export const requestPackageUnits = makeActionCreator(
  REQUEST_PACKAGE_UNITS,
  'params'
)

export const RECEIVE_PACKAGE_UNITS = 'RECEIVE_PACKAGE_UNITS'
export const receivePackageUnits = makeActionCreator(
  RECEIVE_PACKAGE_UNITS,
  'packageUnits',
  'count',
  'error'
)

export const CREATE_PACKAGE_UNIT = 'CREATE_PACKAGE_UNIT'
export const createPackageUnit = makeActionCreator(
  CREATE_PACKAGE_UNIT,
  'params'
)

export const PACKAGE_UNIT_CREATED = 'PACKAGE_UNIT_CREATED'
export const packageUnitCreated = makeActionCreator(
  PACKAGE_UNIT_CREATED,
  'packageUnit',
  'error'
)

export const UPDATE_PACKAGE_UNIT = 'UPDATE_PACKAGE_UNIT'
export const updatePackageUnit = makeActionCreator(
  UPDATE_PACKAGE_UNIT,
  'params'
)

export const PACKAGE_UNIT_UPDATED = 'PACKAGE_UNIT_UPDATED'
export const packageUnitUpdated = makeActionCreator(
  PACKAGE_UNIT_UPDATED,
  'packageUnit',
  'error'
)

export const REQUEST_PACKAGE_UNIT = 'REQUEST_PACKAGE_UNIT'
export const requestPackageUnit = makeActionCreator(
  REQUEST_PACKAGE_UNIT,
  'params'
)

export const RECEIVE_PACKAGE_UNIT = 'RECEIVE_PACKAGE_UNIT'
export const receivePackageUnit = makeActionCreator(
  RECEIVE_PACKAGE_UNIT,
  'packageUnit',
  'error'
)

export const CLEAR_PACKAGE_UNIT = 'CLEAR_PACKAGE_UNIT'
export const clearPackageUnit = makeActionCreator(CLEAR_PACKAGE_UNIT)

export const CLEAR_PACKAGE_UNITS = 'CLEAR_PACKAGE_UNITS'
export const clearPackageUnits = makeActionCreator(CLEAR_PACKAGE_UNITS)

export const SET_PACKAGE_UNIT = 'SET_PACKAGE_UNIT'
export const setPackageUnit = makeActionCreator(SET_PACKAGE_UNIT, 'packageUnit')

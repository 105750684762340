export const CROP_STATUSES = strings => {
  return {
    planned: strings.planned,
    sowed: strings.sowed,
    transplanted: strings.transplanted,
    harvesting: strings.harvesting,
    harvested: strings.harvested,
    failed: strings.failed,
    canceled: strings.canceled
  }
}

export const CROP_GROWTH_MEDIUMS = strings => {
  return {
    perlite: strings.perlite,
    vermiculite: strings.vermiculite,
    volcanic: strings.volcanic,
    rockwool: strings.rockwool,
    soil: strings.soil,
    coconut: strings.coconut,
    clay: strings.clay,
    sand: strings.sand,
    peat: strings.peat
  }
}

export const CROP_HYDRATION_METHODS = strings => {
  return {
    dwc: strings.dwc,
    dutch: strings.dutch,
    gutter: strings.gutter,
    nft: strings.nft,
    aeroponics: strings.aeroponics,
    drip: strings.drip,
    wicking: strings.wicking,
    irrigation: strings.irrigation
  }
}

export const CROP_ROOF_COVERS = strings => {
  return {
    secondsky: strings.secondsky,
    plastic: strings.plastic,
    open_field: strings.open_field
  }
}

export const CROP_SECONDSKY_VARIANTS = strings => {
  return {
    polyfilm: strings.polyfilm,
    polycarbonate: strings.polycarbonate,
    net: strings.net
  }
}

export const CROP_BLOCKING_RATIOS = strings => {
  return {
    low: strings.low,
    medium: strings.medium,
    high: strings.high
  }
}

export const CROP_NET_TYPES = strings => {
  return {
    twenty: strings.twenty,
    sixteen: strings.sixteen,
    tricot: strings.tricot,
    rashel: strings.rashel
  }
}

export const getCropStatusDate = (status, crop) => {
  switch (status) {
    case 'planned':
      return crop.estimatedSowDate
    case 'sowed':
      return crop.sowDate
    case 'transplanted':
      return crop.transplantDate
    case 'harvesting':
      return crop.harvestStartDate
    case 'harvested':
      return crop.harvestEndDate
    default:
      return crop.updatedAt
  }
}

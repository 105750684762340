import { graphqlOperation } from 'aws-amplify'
import axios from 'axios'

import { GRAPHQL_URL } from './config'
import { getCognitoData, makeGraphqlRequest } from '../utils'

/*
 * Queries
 */

export const getAllFlashScripts = async () => {
  try {
    const query = /* GraphQL */ `
      query getAllFlashScripts {
        getAllFlashScripts {
          deviceType
          version
          description
          flash {
            debug {
              key
              url
            }
            release {
              key
              url
            }
            releaseDebug {
              key
              url
            }
          }
          reflash {
            debug {
              key
              url
            }
            release {
              key
              url
            }
            releaseDebug {
              key
              url
            }
          }
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, {})
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        flashScripts: null,
        error: `Flash scripts not fetched: ${error}`
      }
    }

    return { flashScripts: data?.getAllFlashScripts, error: null }
  } catch (err) {
    return { flashScripts: null, error: err.message }
  }
}

/*
 * Mutations
 */

export const createFlashScriptsUploadUrls = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createFlashScriptsUploadUrls {
        createFlashScriptsUploadUrls {
          flash {
            debug {
              key
              url
            }
            release {
              key
              url
            }
            releaseDebug {
              key
              url
            }
          }
          reflash {
            debug {
              key
              url
            }
            release {
              key
              url
            }
            releaseDebug {
              key
              url
            }
          }
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        data: null,
        error: `Flash scripts url not created: ${error}`
      }
    }

    return { data: data?.createFlashScriptsUploadUrls, error: null }
  } catch (err) {
    return { data: null, error: err.message }
  }
}

export const createFlashScript = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createFlashScript(
        $deviceType: String!
        $version: String!
        $description: String!
        $flashDebugKey: String
        $flashReleaseKey: String
        $flashReleaseDebugKey: String
        $reflashDebugKey: String
        $reflashReleaseKey: String
        $reflashReleaseDebugKey: String
      ) {
        createFlashScript(
          deviceType: $deviceType
          version: $version
          description: $description
          flashDebugKey: $flashDebugKey
          flashReleaseKey: $flashReleaseKey
          flashReleaseDebugKey: $flashReleaseDebugKey
          reflashDebugKey: $reflashDebugKey
          reflashReleaseKey: $reflashReleaseKey
          reflashReleaseDebugKey: $reflashReleaseDebugKey
        ) {
          deviceType
          version
          description
          flash {
            debug {
              key
              url
            }
            release {
              key
              url
            }
            releaseDebug {
              key
              url
            }
          }
          reflash {
            debug {
              key
              url
            }
            release {
              key
              url
            }
            releaseDebug {
              key
              url
            }
          }
        }
      }
    `

    const { flash, reflash, uploadData, ...rest } = params

    let payload = { ...rest }

    // Flash scripts
    if (flash?.debug?.blob) {
      await axios.put(uploadData.flash.debug.url, flash?.debug?.blob)
      payload = { ...payload, flashDebugKey: uploadData.flash.debug.key }
    }

    if (flash?.release?.blob) {
      await axios.put(uploadData.flash.release.url, flash?.release?.blob)
      payload = { ...payload, flashReleaseKey: uploadData.flash.release.key }
    }

    if (flash?.releaseDebug?.blob) {
      await axios.put(
        uploadData.flash.releaseDebug.url,
        flash?.releaseDebug?.blob
      )
      payload = {
        ...payload,
        flashReleaseDebugKey: uploadData.flash.releaseDebug.key
      }
    }

    // Reflash scripts
    if (reflash?.debug?.blob) {
      await axios.put(uploadData.reflash.debug.url, reflash?.debug?.blob)
      payload = { ...payload, reflashDebugKey: uploadData.reflash.debug.key }
    }

    if (reflash?.release?.blob) {
      await axios.put(uploadData.reflash.release.url, reflash?.release?.blob)
      payload = {
        ...payload,
        reflashReleaseKey: uploadData.reflash.release.key
      }
    }

    if (reflash?.releaseDebug?.blob) {
      await axios.put(
        uploadData.reflash.releaseDebug.url,
        reflash?.releaseDebug?.blob
      )
      payload = {
        ...payload,
        reflashDebugReleaseKey: uploadData.reflash.releaseDebug.key
      }
    }

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, payload)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        flashScript: null,
        error: `Flash script not created: ${error}`
      }
    }

    return { flashScript: data?.createFlashScript, error: null }
  } catch (err) {
    return { flashScript: null, error: err.message }
  }
}

export const updateFlashScript = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateFlashScript(
        $deviceType: String!
        $version: String!
        $description: String!
        $flashDebugKey: String
        $flashReleaseKey: String
        $flashReleaseDebugKey: String
        $reflashDebugKey: String
        $reflashReleaseKey: String
        $reflashReleaseDebugKey: String
      ) {
        updateFlashScript(
          deviceType: $deviceType
          version: $version
          description: $description
          flashDebugKey: $flashDebugKey
          flashReleaseKey: $flashReleaseKey
          flashReleaseDebugKey: $flashReleaseDebugKey
          reflashDebugKey: $reflashDebugKey
          reflashReleaseKey: $reflashReleaseKey
          reflashReleaseDebugKey: $reflashReleaseDebugKey
        ) {
          deviceType
          version
          description
          flash {
            debug {
              key
              url
            }
            release {
              key
              url
            }
            releaseDebug {
              key
              url
            }
          }
          reflash {
            debug {
              key
              url
            }
            release {
              key
              url
            }
            releaseDebug {
              key
              url
            }
          }
        }
      }
    `

    const { flash, reflash, uploadData, ...rest } = params

    let payload = { ...rest }

    // Flash scripts
    if (flash?.debug?.blob) {
      await axios.put(uploadData.flash.debug.url, flash?.debug?.blob)
      payload = { ...payload, flashDebugKey: uploadData.flash.debug.key }
    } else if (flash?.debug?.key) {
      payload = { ...payload, flashDebugKey: flash.debug.key }
    }

    if (flash?.release?.blob) {
      await axios.put(uploadData.flash.release.url, flash?.release?.blob)
      payload = { ...payload, flashReleaseKey: uploadData.flash.release.key }
    } else {
      payload = { ...payload, flashReleaseKey: flash.release.key }
    }

    if (flash?.releaseDebug?.blob) {
      await axios.put(
        uploadData.flash.releaseDebug.url,
        flash?.releaseDebug?.blob
      )
      payload = {
        ...payload,
        flashReleaseDebugKey: uploadData.flash.releaseDebug.key
      }
    } else if (flash?.releaseDebug?.key) {
      payload = {
        ...payload,
        flashReleaseDebugKey: flash.releaseDebug.key
      }
    }

    // Reflash scripts
    if (reflash?.debug?.blob) {
      await axios.put(uploadData.reflash.debug.url, reflash?.debug?.blob)
      payload = { ...payload, reflashDebugKey: uploadData.reflash.debug.key }
    } else if (reflash?.debug?.key) {
      payload = { ...payload, reflashDebugKey: reflash.debug.key }
    }

    if (reflash?.release?.blob) {
      await axios.put(uploadData.reflash.release.url, reflash?.release?.blob)
      payload = {
        ...payload,
        reflashReleaseKey: uploadData.reflash.release.key
      }
    } else if (reflash?.release?.key) {
      payload = {
        ...payload,
        reflashReleaseKey: reflash.release.key
      }
    }

    if (reflash?.releaseDebug?.blob) {
      await axios.put(
        uploadData.reflash.releaseDebug.url,
        reflash?.releaseDebug?.blob
      )
      payload = {
        ...payload,
        reflashReleaseDebugKey: uploadData.reflash.releaseDebug.key
      }
    } else if (reflash?.releaseDebug?.key) {
      payload = {
        ...payload,
        reflashReleaseDebugKey: reflash.releaseDebug.key
      }
    }

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, payload)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        flashScript: null,
        error: `Flash script not updated: ${error}`
      }
    }

    return { flashScript: data?.updateFlashScript, error: null }
  } catch (err) {
    return { flashScript: null, error: err.message }
  }
}

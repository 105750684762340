import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {
  getRole,
  getRolePermissions,
  createRole,
  deleteRole,
  getRoleUsers,
  getRoles,
  addRolePermission,
  removeRolePermission
} from '@/api/management/userRoles'
import { isLoading, showBanner, isLoadingSection } from '@/slices/util'

import Strings from '@/components/AdminPage/Strings'

const strings = Strings()

const getUpdatedRolesList = (roles, newRole, add = true) => {
  if (!newRole) return roles
  if (add) return [...roles, newRole]

  return roles.filter(({ name }) => name !== newRole.name)
}

export const fetchRoles = createAsyncThunk(
  'fetchRoles',
  async (params, { dispatch }) => {
    dispatch(isLoading(true))
    const { roles = [], error = null } = await getRoles()
    dispatch(isLoading(false))

    if (error) {
      const bannerDetails = {
        show: true,
        message: `${strings['rolesFetchedError']} ${error}`,
        type: 'error'
      }
      dispatch(showBanner(bannerDetails))
    }
    return { roles, error }
  }
)

export const fetchRolePermissions = createAsyncThunk(
  'fetchRolePermissions',
  async (params, { dispatch }) => {
    const { permissions = [], error = null } = await getRolePermissions(params)

    if (error) {
      const bannerDetails = {
        show: true,
        message: `${strings['rolePermissionsFetchedError']} ${error}`,
        type: 'error'
      }
      dispatch(showBanner(bannerDetails))
    }
    return { permissions, error }
  }
)

export const fetchRole = createAsyncThunk(
  'fetchRole',
  async (params, { dispatch }) => {
    dispatch(isLoading(true))
    const { role, error } = await getRole(params)
    dispatch(isLoading(false))

    if (error) {
      const bannerDetails = {
        show: true,
        message: `${strings['roleFetchedError']} ${error}`,
        type: 'error'
      }
      dispatch(showBanner(bannerDetails))
    }

    return { role, error }
  }
)

export const sendCreateRole = createAsyncThunk(
  'sendCreateRole',
  async (params, { dispatch }) => {
    dispatch(isLoading(true))
    const { role = {}, error = null } = await createRole(params)

    let bannerDetails = {}

    if (!error && role) {
      bannerDetails = {
        show: true,
        message: strings['roleCreated'],
        type: 'success'
      }
    } else {
      bannerDetails = {
        show: true,
        message: `${strings['roleCreatedError']} ${error}`,
        type: 'error'
      }
    }

    dispatch(isLoading(false))
    dispatch(showBanner(bannerDetails))
    return { role, error }
  }
)

export const sendDeleteRole = createAsyncThunk(
  'sendDeleteRole',
  async (params, { dispatch }) => {
    dispatch(isLoading(true))
    const { role = {}, error = null } = await deleteRole(params)

    let bannerDetails = {}

    if (!error) {
      bannerDetails = {
        show: true,
        message: strings['roleDeleted'],
        type: 'success'
      }
    } else {
      bannerDetails = {
        show: true,
        message: `${strings['roleDeletedError']} ${error}`,
        type: 'error'
      }
    }

    dispatch(isLoading(false))
    dispatch(showBanner(bannerDetails))
    return { role, error }
  }
)

export const fetchRoleUsers = createAsyncThunk(
  'fetchRoleUsers',
  async (params, { dispatch }) => {
    dispatch(isLoadingSection(true))
    const { users = [], error = null } = await getRoleUsers(params)
    dispatch(isLoadingSection(false))

    if (error) {
      const bannerDetails = {
        show: true,
        message: `${strings['roleUsersFetchedError']} ${error}`,
        type: 'error'
      }
      dispatch(showBanner(bannerDetails))
    }

    return { users, error }
  }
)

export const sendAddRolePermission = createAsyncThunk(
  'sendAddRolePermission',
  async (params, { dispatch }) => {
    dispatch(isLoadingSection(true))
    const { sectionName, ...rest } = params
    const { error = null } = await addRolePermission(rest)
    dispatch(isLoadingSection(false))

    if (error) {
      const bannerDetails = {
        show: true,
        message: `${strings['rolePermissionAddError']} ${error}`,
        type: 'error'
      }
      dispatch(showBanner(bannerDetails))
    }

    if (!error && sectionName === 'organization') {
      const bannerDetails = {
        show: true,
        message: strings['pageWillRefresh'],
        type: 'success'
      }
      dispatch(showBanner(bannerDetails))

      setTimeout(() => {
        window.location.reload()
      }, '3000')
    }

    return { error }
  }
)

export const sendRemoveRolePermission = createAsyncThunk(
  'sendRemoveRolePermission',
  async (params, { dispatch }) => {
    dispatch(isLoadingSection(true))
    const { sectionName, ...rest } = params
    const { error = null } = await removeRolePermission(rest)
    dispatch(isLoadingSection(false))

    if (error) {
      const bannerDetails = {
        show: true,
        message: `${strings['rolePermissionRemoveError']} ${error}`,
        type: 'error'
      }
      dispatch(showBanner(bannerDetails))
    }

    if (!error && sectionName === 'organization') {
      const bannerDetails = {
        show: true,
        message: strings['pageWillRefresh'],
        type: 'success'
      }
      dispatch(showBanner(bannerDetails))

      setTimeout(() => {
        window.location.reload()
      }, '3000')
    }

    return { error }
  }
)

const managementRoleSlice = createSlice({
  name: 'managementRoleReducer',
  initialState: {
    role: {},
    roleUsers: [],
    roleUsersFetched: false,
    roles: [],
    rolePermission: {},
    error: null
  },
  reducers: {
    setRole: (state, action) => {
      state.role = action?.payload
      state.error = null
    },
    cleanRole: state => {
      state.role = {}
      state.roleUsers = []
      state.roleUsersFetched = false
      state.error = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchRoles.pending, state => {
        state.error = null
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        const { roles, error } = action?.payload
        state.roles = error ? state.roles : roles
        state.error = error
      })
      .addCase(fetchRole.pending, state => {
        state.role = {}
        state.error = null
      })
      .addCase(fetchRole.fulfilled, (state, action) => {
        const { role, error = null } = action?.payload
        state.role = error ? state.role : role
        state.error = error
      })
      .addCase(fetchRolePermissions.pending, state => {
        state.error = null
      })
      .addCase(fetchRolePermissions.fulfilled, (state, action) => {
        const { permissions, error = null } = action?.payload
        state.role = error ? state.role : { ...state?.role, permissions }
        state.error = error
      })
      .addCase(sendCreateRole.pending, state => {
        state.error = null
      })
      .addCase(sendCreateRole.fulfilled, (state, action) => {
        const { role, error } = action?.payload
        state.role = error ? state.role : role
        state.roles = getUpdatedRolesList(state.roles, role, true)
        state.error = error
      })
      .addCase(sendDeleteRole.pending, state => {
        state.error = null
      })
      .addCase(sendDeleteRole.fulfilled, (state, action) => {
        const { role, error } = action?.payload
        state.role = error ? state.role : role
        state.roles = getUpdatedRolesList(state.roles, role, false)
        state.error = error
      })
      .addCase(fetchRoleUsers.pending, state => {
        state.error = null
      })
      .addCase(fetchRoleUsers.fulfilled, (state, action) => {
        const { users, error } = action?.payload
        state.roleUsers = error ? [] : users
        state.roleUsersFetched = true
        state.error = error
      })
      .addCase(sendAddRolePermission.pending, (state, action) => {
        state.rolePermission = action?.meta?.arg?.permission
        state.error = null
      })
      .addCase(sendAddRolePermission.fulfilled, (state, action) => {
        state.error = action?.payload?.error
      })
      .addCase(sendRemoveRolePermission.pending, (state, action) => {
        state.rolePermission = action?.meta?.arg?.permission
        state.error = null
      })
      .addCase(sendRemoveRolePermission.fulfilled, (state, action) => {
        state.error = action?.payload?.error
      })
  }
})

const { actions, reducer } = managementRoleSlice

export const { setRole, cleanRole } = actions

export default reducer

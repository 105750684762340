import { ResponsiveLine } from '@nivo/line'

import { Loader } from '@/primitives'
import theme from '../theme.json'

const calculateSpacing = data => {
  const sortedData = data.sort((a, b) => b?.id?.length - a?.id?.length)
  return sortedData[0]?.id?.length * 2
}

const Line = ({ data, config }) => {
  let axisLeftCount = 0
  let axisBottomCount = 0

  let sortedData = [...data]
  sortedData.sort((a, b) => {
    if (a?.id < b?.id) {
      return -1
    }
    if (a?.id > b?.id) {
      return 1
    }
    return 0
  })

  const middleDataIndex = Math.floor(sortedData.length / 2)
  const bottomLegends = sortedData.toSpliced(0, middleDataIndex)
  const topLegends = sortedData.toSpliced(middleDataIndex)

  const legendsArray =
    sortedData.length < 5 ? [sortedData] : [topLegends, bottomLegends]

  return (
    <Loader isLoading={sortedData.length < 1}>
      <ResponsiveLine
        theme={theme}
        colors={config?.colors ?? { scheme: 'dark2' }}
        data={sortedData}
        margin={{ top: 8, right: 36, bottom: 75, left: 56 }}
        xScale={
          config?.xScale ?? {
            type: 'point'
          }
        }
        yScale={
          config?.yScale ?? {
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: false,
            reverse: false
          }
        }
        yFormat=' >-.2f'
        axisTop={null}
        axisRight={null}
        axisLeft={
          config?.axisLeft ?? {
            format: function (value) {
              axisLeftCount++
              if (axisLeftCount % 2 === 0) {
                return value
              }
              return ''
            }
          }
        }
        axisBottom={
          config?.axisBottom ?? {
            format: function (value) {
              axisBottomCount++
              if (axisBottomCount % 2 === 0) {
                return value
              }
              return ''
            }
          }
        }
        enablePoints={true}
        pointSize={3}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={3}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        enableSlices={config?.enableSlices ?? false}
        sliceTooltip={config?.sliceTooltip ?? null}
        layers={config?.layers ?? null}
        legends={
          legendsArray.map((data, index) => {
            return {
              data: data.map(cur => {
                return {
                  id: cur.id,
                  label: cur.id,
                  color: cur.color
                }
              }),
              anchor: 'bottom',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: index === 0 ? 46 : 66,
              itemWidth: 100,
              itemHeight: 12,
              itemsSpacing: calculateSpacing(sortedData) + 10,
              symbolSize: 12,
              symbolShape: 'circle',
              itemDirection: 'left-to-right',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1
                  }
                }
              ]
            }
          }) ?? []
        }
      />
    </Loader>
  )
}

export default Line

import { DateTime } from 'luxon'

import React, { memo, useEffect, useState } from 'react'
import { ResponsiveLine } from '@nivo/line'

import { colorScheme } from './utils'

import { getMeasurements, getZoneHierarchy } from '@/reducers/selectors'

import { Card, Flex, Text, Box } from '@/primitives'

import { conversionLookup } from '@/Util/UnitUtils'
import ZoneUtils from '@/Util/ZoneUtils'
import MeasurementStrings from '@/Util/Strings/measurements'

import { LINE_GRAPH_THEME } from './config'
import Strings from '../../../Strings'

import './index.scss'

function findMeasurement(measurements, id) {
  return measurements.find(measurement => measurement.id === id) ?? null
}

const SERIES = ['average']

const applyConverion = (key, values) => {
  const scaleFactor = conversionLookup[key] ?? 1
  return values.map(v => ({
    x: new Date(v.x),
    y: v.y * scaleFactor
  }))
}

const getNoDataFullText = (strings, noDataZones, zoneHierarchy) => {
  let string = `${strings.noDataForZones} `
  noDataZones.forEach(({ siteId, zoneId }, i) => {
    const zoneName = ZoneUtils.getZoneName(zoneHierarchy, siteId, zoneId)
    const toAppend = `${zoneName}${i < noDataZones.length - 1 ? ', ' : ''}`
    string = `${string}${toAppend}`
  })
  return string
}

const Chart = ({ measurementId, data, interval, zoneFilters, timeZone }) => {
  const measurementStrings = MeasurementStrings()
  const zoneHierarchy = getZoneHierarchy()

  const zoneIds = zoneFilters.map(({ zoneId, siteId }) => ({
    zoneId,
    siteId
  }))
  const noDataZones = zoneIds.filter(
    ({ zoneId }) => !data.some(data => data.zoneId === zoneId)
  )
  const measurements = getMeasurements()
  const strings = Strings()

  const [measurement, setMeasurement] = useState(null)

  useEffect(() => {
    if (measurementId && measurements.length > 0) {
      const m = findMeasurement(measurements, measurementId)
      if (m) {
        setMeasurement(m)
      }
    }
  }, [measurements, measurementId])

  const getSeriesColor = data => {
    const filterIndex = zoneFilters.findIndex(filter => {
      if (data.sensorId) {
        if (data.sensorId === filter.sensorId) {
          return true
        }
      } else if (data.zoneId === filter.zoneId) {
        return true
      }
      return false
    })
    // TODO: check if index is greater than length & generate colour if needed
    return colorScheme[filterIndex]
  }

  const getColor = series => {
    return series.color
  }

  const INTERVAL_FORMATS = {
    hour: 'ha',
    quarterDay: 'MM/dd',
    day: 'MM/dd',
    alternateDays: 'MM/dd'
  }

  const formatDate = date => {
    return DateTime.fromMillis(date.getTime(), { zone: timeZone }).toFormat(
      INTERVAL_FORMATS[interval]
    )
  }

  const INTERVAL_PRECISION = {
    hour: 'hour',
    quarterDay: 'hour',
    day: 'day',
    alternateDays: 'day'
  }

  const getPrecision = () => {
    return INTERVAL_PRECISION[interval]
  }

  const INTERVAL_TICKS = {
    hour: 4,
    quarterDay: 7,
    day: 7,
    alternateDays: 6
  }

  const getTicks = () => {
    return INTERVAL_TICKS[interval]
  }

  const filteredData = []
  for (let i = 0; i < data.length; i++) {
    for (let j = 0; j < data[i].series.length; j++) {
      if (SERIES.includes(data[i].series[j].id)) {
        filteredData.push({
          id: data[i].sensorId ?? data[i].zoneId,
          data: applyConverion(data[i].measurement, data[i].series[j].data),
          color: getSeriesColor(data[i])
        })
      }
    }
  }

  const someData = filteredData.some(d => d.data.length !== 0)

  return (
    <Card className='Historic__Chart__Card'>
      <Flex
        alignMainAxis='space-between'
        alignCrossAxis='center'
        className='Historic__Chart__Card--Title'
        direction='row'
      >
        <Text as='p' size={200}>
          {measurementStrings[measurement?.id] ?? measurement?.shortName}
        </Text>
        <Text as='p' style={{ margin: '0' }}>
          {measurement?.unit}
        </Text>
      </Flex>
      {!someData && (
        <Flex style={{ marginBottom: '2em' }}>
          <Text
            as='p'
            size={300}
            tone={700}
            variant='page'
            className='Historic__Chart__Card__NoData'
          >
            {strings.noData}
          </Text>
        </Flex>
      )}
      {data && someData && (
        <Flex className='Historic__Chart__Card--Container'>
          <ResponsiveLine
            animate={false}
            margin={{
              top: 20,
              right: 20,
              bottom: 30,
              left: 55
            }}
            theme={LINE_GRAPH_THEME}
            colors={getColor}
            data={filteredData}
            xScale={{
              type: 'time',
              precision: getPrecision(),
              min: 'auto',
              max: 'auto'
            }}
            yScale={{
              type: 'linear',
              min: 'auto',
              max: 'auto',
              stacked: false,
              reverse: false
            }}
            yFormat=' >-.2f'
            axisTop={null}
            axisRight={null}
            axisLeft={{
              tickValues: 6
            }}
            axisBottom={{
              tickValues: getTicks(),
              format: function (value) {
                return formatDate(value)
              }
            }}
            isInteractive={false}
            useMesh={false}
            enablePoints={false}
            enableArea={false}
            curve='monotoneX'
          />
        </Flex>
      )}
      <Flex axisGap={300} style={{ padding: '1rem 0.7rem' }}>
        {data.map(({ siteId, zoneId }, i) => (
          <Box key={i} style={{ padding: '0 0.5rem' }}>
            <Box
              style={{
                borderRadius: '5px',
                width: '10px',
                height: '10px',
                backgroundColor: getSeriesColor(data[i]),
                display: 'inline-block',
                marginRight: '0.4rem'
              }}
            ></Box>
            <Text size={100} style={{ display: 'inline-block' }}>
              {ZoneUtils.getZoneName(zoneHierarchy, siteId, zoneId)}
            </Text>
          </Box>
        ))}
      </Flex>
      {noDataZones?.length > 0 && someData && (
        <Box style={{ padding: '0 1rem 1rem 1rem', lineHeight: '1.1rem' }}>
          <Text
            size={100}
            variant='page'
            tone={700}
            style={{ paddingRight: '0.3rem' }}
          >
            {getNoDataFullText(strings, noDataZones, zoneHierarchy)}
          </Text>
        </Box>
      )}
    </Card>
  )
}

export default memo(Chart)

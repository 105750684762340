import {
  removeInventoryWeight,
  updateInventoryWeight
} from '../../../Shared/InventoryWeights/utils'
import { fixDate } from '../../utils'

export const UPDATE_INPUT = 'UPDATE_INPUT'
export const SET_STATE = 'SET_STATE'
export const ADD_WEIGHT = 'ADD_WEIGHT'
export const REMOVE_WEIGHT = 'REMOVE_WEIGHT'
export const UPDATE_WEIGHT = 'UPDATE_WEIGHT'

export const initialState = {
  id: null,
  organizationId: '',
  fertilizerInventoryId: null,
  fromStorageFacilityId: '',
  shippingMethodId: '',
  storageFacilityId: '',
  fertilizerId: '',
  status: '',
  weights: [],
  totalWeight: 0,
  shippedDate: null,
  deliveredDate: null
}

export function reducer(state, action) {
  const handlers = {
    [SET_STATE]: (state, action) => {
      const newState = { ...action.state }
      newState.shippedDate = fixDate(newState.shippedDate)
      newState.deliveredDate = fixDate(newState.deliveredDate)
      return {
        ...newState
      }
    },
    [UPDATE_INPUT]: (state, action) => ({
      ...state,
      [action.name]: action.value
    }),
    [UPDATE_WEIGHT]: (state, action) => updateInventoryWeight(state, action),
    [REMOVE_WEIGHT]: (state, action) => removeInventoryWeight(state, action)
  }
  return handlers[action.type](state, action) || state
}

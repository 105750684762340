import { useMemo } from 'react'
import { Link, useParams, useRouteMatch } from 'react-router-dom'

import { FlexV2 } from '@/primitives'

import StatusText from './StatusText'
import StatusIcon from './StatusIcon'

import Strings from '../Strings'

/**
 * Returns a Link component to navigate to the next page based on the current page and URL.
 *
 * @param {Object} props - The props to pass to the Link component.
 * @param {string} props.page - The current page.
 * @param {string} props.url - The current URL.
 * @param {Object} props.strings - The strings object to use for text labels.
 *
 * @returns {React.ReactElement} A Link component to navigate to the next page.
 */
function nextLink({ page, url, strings }) {
  if (!url) return null

  const nextURL = page ? url.replace('/alerts', '') : url + '/alerts'
  const nextText = strings[page ? 'viewOverview' : 'viewAlerts']

  return <Link to={nextURL}>{nextText}</Link>
}

/**
 * Renders the header for the StatusPage component.
 *
 * @param {Object} props - The props to pass to the component.
 * @param {string} props.status - The status of the page.
 * @param {number} props.alertCount - The number of alerts on the page.
 *
 * @returns {React.ReactElement} A React component that displays the header for the StatusPage.
 */
export default function Header({ status = 'loading', alertCount = 0 }) {
  const { url } = useRouteMatch()
  const { page } = useParams()
  const strings = Strings()

  const text = useMemo(() => {
    if (status === 'loading') return strings.loading

    if (alertCount === 0) return strings.active

    const alertText = alertCount === 1 ? strings.alert : strings.alerts
    return [alertCount, alertText].join(' ')
  }, [alertCount, strings, status])

  return (
    <FlexV2
      direction='column'
      alignCrossAxis='center'
      alignMainAxis='center'
      className='StatusPage__Header'
    >
      <FlexV2 direction='column' alignCrossAxis='center' axisGap={200}>
        <StatusIcon size={1000} status={status} />
        <StatusText size={400} status={status} text={text} />
      </FlexV2>
      {nextLink({ page, url, strings })}
    </FlexV2>
  )
}

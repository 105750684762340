import { nanoid } from 'nanoid'
import React, { Fragment, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { deleteSaleItem } from '@/actions/operations/sale'
import { showBanner } from '@/slices/util'

import { getOperationsLoading, getOperationsError } from '@/reducers/selectors'

import { Button, Flex } from '@/primitives'

import Table from './Table'
import Form from './Form'

import { REMOVE_SALE_ITEM } from '../state'

import Strings from './Strings'

function SaleItems({ sale, dispatchFormState }) {
  const dispatch = useDispatch()
  const strings = Strings()

  const loading = getOperationsLoading()
  const error = getOperationsError()

  const [saleItem, setSaleItem] = useState({})
  const [deleteItemId, setDeleteItemId] = useState(null)
  const [waiting, setWaiting] = useState(false)

  useEffect(() => {
    if (waiting && !loading) {
      if (!error) {
        removeItemFromSale(deleteItemId)
      }
      setDeleteItemId(null)
      setWaiting(false)
    }
  }, [loading])

  useEffect(() => {
    if (sale.id) {
      if (deleteItemId) {
        dispatch(
          deleteSaleItem({
            saleItemId: deleteItemId
          })
        )
      }
    } else {
      removeItemFromSale(deleteItemId)
    }
  }, [deleteItemId])

  const removeItemFromSale = saleItemId => {
    if (saleItemId === saleItem.id) {
      onCloseForm()
    }
    dispatchFormState({
      type: REMOVE_SALE_ITEM,
      saleItemId: saleItemId
    })
  }

  const onRemove = saleItem => {
    for (let shipment of sale.saleShipments) {
      for (let item of shipment.items) {
        if (item.saleItemId === saleItem.id) {
          dispatch(
            showBanner({
              type: 'error',
              message: strings.saleItemConstraintError,
              show: true
            })
          )
          return false
        }
      }
    }
    setDeleteItemId(saleItem.id)
    setWaiting(true)
  }

  const onCreate = () => {
    setSaleItem({
      id: nanoid()
    })
  }

  const onCloseForm = () => {
    setSaleItem({})
  }

  return (
    <Fragment>
      <Table
        sale={sale}
        saleItems={sale.saleItems}
        handleEdit={setSaleItem}
        handleRemove={onRemove}
        dispatchFormState={dispatchFormState}
      />
      {saleItem?.id && (
        <Form
          sale={sale}
          saleItem={saleItem}
          closeForm={onCloseForm}
          dispatchFormState={dispatchFormState}
        />
      )}
      {!saleItem?.id && (
        <Flex alignMainAxis={'flex-end'}>
          <Button onClick={onCreate} size='small' name='add' variant='primary'>
            {strings.addSaleItem}
          </Button>
        </Flex>
      )}
    </Fragment>
  )
}

export default SaleItems

import { call, put, select, takeLatest } from 'redux-saga/effects'

import {
  getCurrencies,
  getCurrency,
  createCurrency,
  updateCurrency
} from '@/api/operations/currency'

import {
  REQUEST_ALL_CURRENCIES,
  REQUEST_CURRENCIES,
  REQUEST_CURRENCY,
  CREATE_CURRENCY,
  UPDATE_CURRENCY,
  receiveAllCurrencies,
  receiveCurrencies,
  receiveCurrency,
  currencyCreated,
  currencyUpdated
} from '@/actions/operations/currency'

import { displayBanner } from '../utils'
import { getOperationsAllCurrencies } from './utils'

import Strings from './Strings'

const strings = Strings()

function* sendCreateCurrency(action) {
  const { data, error = null } = yield call(createCurrency, action.params)
  yield put(displayBanner(error, strings.currencyCreated))
  yield put(currencyCreated(data, error))
}

export function* watchCreateCurrency() {
  yield takeLatest(CREATE_CURRENCY, sendCreateCurrency)
}

function* sendUpdateCurrency(action) {
  const { data, error = null } = yield call(updateCurrency, action.params)
  yield put(displayBanner(error, strings.currencyUpdated))
  yield put(currencyUpdated(data, error))
}

export function* watchUpdateCurrency() {
  yield takeLatest(UPDATE_CURRENCY, sendUpdateCurrency)
}

function* sendRequestCurrencies(action) {
  const { data, error = null } = yield call(getCurrencies, action.params)
  const { currencies = [], count = 0 } = data
  yield put(receiveCurrencies(currencies, count, error))
}

export function* watchRequestCurrencies() {
  yield takeLatest(REQUEST_CURRENCIES, sendRequestCurrencies)
}

function* sendRequestCurrency(action) {
  const { data: currency, error = null } = yield call(
    getCurrency,
    action.params
  )
  yield put(receiveCurrency(currency, error))
}

export function* watchRequestCurrency() {
  yield takeLatest(REQUEST_CURRENCY, sendRequestCurrency)
}

function* sendRequestAllCurrencies(action) {
  const allCurrencies = yield select(getOperationsAllCurrencies)
  if (allCurrencies.length > 0) {
    yield put(receiveAllCurrencies(allCurrencies, allCurrencies.length, null))
  } else {
    const { data, error = null } = yield call(getCurrencies, {})
    const { currencies = [], count = 0 } = data
    yield put(receiveAllCurrencies(currencies, count, error))
  }
}

export function* watchRequestAllCurrencies() {
  yield takeLatest(REQUEST_ALL_CURRENCIES, sendRequestAllCurrencies)
}

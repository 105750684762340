// REGEX
export const UNION = /&/

// DOM INTERFACE
export const RULE = {
  type: 'style',
  cssText: '',
  className: '',
  selectorText: ''
}

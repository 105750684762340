import { updateList } from '../util'

import {
  RECEIVE_ALL_PAYMENT_TERMS,
  REQUEST_ALL_PAYMENT_TERMS,
  RECEIVE_PAYMENT_TERMS,
  REQUEST_PAYMENT_TERMS,
  CLEAR_PAYMENT_TERMS,
  CLEAR_PAYMENT_TERM,
  REQUEST_PAYMENT_TERM,
  RECEIVE_PAYMENT_TERM,
  CREATE_PAYMENT_TERM,
  PAYMENT_TERM_CREATED,
  UPDATE_PAYMENT_TERM,
  PAYMENT_TERM_UPDATED,
  SET_PAYMENT_TERM
} from '@/actions/operations/paymentTerm'

export const paymentTermInitialState = {
  allPaymentTerms: [],
  paymentTerms: [],
  paymentTerm: {},
  paymentTermsCount: 0,
  loading: false,
  error: null
}

const handlers = {
  [CLEAR_PAYMENT_TERM]: (state, action) => ({
    ...state,
    paymentTerm: {},
    error: null
  }),
  [CLEAR_PAYMENT_TERMS]: (state, action) => ({
    ...state,
    paymentTerms: [],
    error: null
  }),
  [REQUEST_ALL_PAYMENT_TERMS]: state => ({
    ...state,
    error: null,
    loading: true
  }),
  [RECEIVE_ALL_PAYMENT_TERMS]: (state, action) => ({
    ...state,
    allPaymentTerms: !action.error ? action.paymentTerms : [],
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_PAYMENT_TERM]: (state, action) => ({
    ...state,
    paymentTerm: {},
    error: null,
    loading: true
  }),
  [RECEIVE_PAYMENT_TERM]: (state, action) => ({
    ...state,
    paymentTerm: !action.error ? action.paymentTerm : state.paymentTerm,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_PAYMENT_TERMS]: (state, action) => ({
    ...state,
    paymentTerms: [],
    error: null,
    loading: true
  }),
  [RECEIVE_PAYMENT_TERMS]: (state, action) => ({
    ...state,
    paymentTerms: !action.error ? action.paymentTerms : [],
    paymentTermsCount: !action.error ? action.count : 0,
    error: action.error ?? null,
    loading: false
  }),
  [SET_PAYMENT_TERM]: (state, action) => ({
    ...state,
    paymentTerm: action.paymentTerm
  }),
  [CREATE_PAYMENT_TERM]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [PAYMENT_TERM_CREATED]: (state, action) => ({
    ...state,
    paymentTerm: !action.error ? {} : state.paymentTerm,
    paymentTerms: updateList(
      action.error,
      state.paymentTerms,
      action.paymentTerm,
      true
    ),
    allPaymentTerms: updateList(
      action.error,
      state.allPaymentTerms,
      action.paymentTerm,
      true
    ),
    paymentTermsCount: !action.error
      ? state.paymentTermsCount + 1
      : state.paymentTermsCount,
    error: action.error ?? null,
    loading: false
  }),
  [UPDATE_PAYMENT_TERM]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [PAYMENT_TERM_UPDATED]: (state, action) => ({
    ...state,
    paymentTerm: !action.error ? {} : state.paymentTerm,
    paymentTerms: updateList(
      action.error,
      state.paymentTerms,
      action.paymentTerm,
      false
    ),
    allPaymentTerms: updateList(
      action.error,
      state.allPaymentTerms,
      action.paymentTerm,
      false
    ),
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

import { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { Flex, Icon, Button } from '@/primitives'

import { showBanner } from '@/slices/util'

import { getShowBanner } from '@/reducers/selectors'

const GlobalNotification = () => {
  const dispatch = useDispatch()
  const banner = getShowBanner()

  const timer = useRef(null)

  const hideBanner = useCallback(() => {
    dispatch(showBanner({ show: false, message: '', type: '' }))
  }, [dispatch])

  useEffect(() => {
    if (banner?.type === 'success') {
      timer.current = setTimeout(hideBanner, 3000)

      return () => {
        clearTimeout(timer.current)
      }
    }
  }, [banner, hideBanner])

  useEffect(() => {
    dispatch(
      showBanner({
        show: false,
        message: null,
        type: null
      })
    )
  }, [dispatch])

  if (!banner?.show) {
    return null
  }

  return (
    <Flex
      className={['GlobalNotification', banner.type]}
      role='alert'
      alignCrossAxis='center'
      alignMainAxis='space-between'
    >
      <Flex
        className='GlobalNotification__Message'
        alignMainAxis='center'
        alignCrossAxis='center'
        axisGap={400}
      >
        <Icon name={banner.type === 'success' ? 'check' : 'report'} />
        <span>{banner.message}</span>
      </Flex>

      <Button
        variant='text'
        iconBefore='close'
        size={'small'}
        onClick={hideBanner}
      />
    </Flex>
  )
}

export default GlobalNotification

import { I18n } from 'aws-amplify'
import { object, string, number } from 'yup'

export const FIELDS = {
  id: 'id',
  organizationId: 'organizationId',
  packageUnitInventoryId: 'packageUnitInventoryId',
  newQuantity: 'newQuantity',
  reason: 'reason'
}

export const SCHEMA = () => {
  return object().shape({
    organizationId: string().required(I18n.get('An organization is required.')),
    packageUnitInventoryId: string().required(
      I18n.get('Package unit inventory is required.')
    ),
    newQuantity: number()
      .integer(I18n.get('A partial quantity is not allowed.'))
      .typeError(I18n.get('New quantity is required.'))
      .min(0, I18n.get('New quantity cannot be less than zero.'))
      .required(I18n.get('New quantity is required.')),
    reason: string().required(I18n.get('A reason is required.'))
  })
}

import { useMap } from '@vis.gl/react-google-maps'

import { Fragment, useRef, useState, useEffect, useCallback } from 'react'

import Pin from './Pin'

const Cluster = ({
  devices,
  currentCluster,
  setCurrentCluster,
  currentDevice,
  setCurrentDevice,
  maxZoom,
  currentZoom,
  zoomingOut,
  setZoomingOut,
  isMobile,
  mapRef
}) => {
  const map = useMap()
  const clusterRef = useRef()
  const lightboxRef = useRef()

  const [show, setShow] = useState(false)
  const [closeClicked, setCloseClicked] = useState(false)

  const getRadius = () => {
    const multiplier = currentCluster.length + 5
    return Math.max(currentCluster.length * multiplier, 130)
  }

  const showCluster = useCallback(async () => {
    if (!zoomingOut && currentCluster?.length > 0 && currentZoom >= maxZoom) {
      const radius = getRadius()
      clusterRef.current.style.width = radius + 'px'
      clusterRef.current.style.height = radius + 'px'

      const mapBounds = mapRef.current.getBoundingClientRect()
      const mapWidth = mapRef.current.clientWidth
      const mapHeight = mapRef.current.clientHeight

      clusterRef.current.style.left =
        mapBounds.left + mapWidth / 2 - radius / 2 + 'px'
      clusterRef.current.style.top =
        mapBounds.top + mapHeight / 2 - radius / 2 + 'px'

      lightboxRef.current.style.left = mapBounds.left + 'px'
      lightboxRef.current.style.top = mapBounds.top + 'px'
      lightboxRef.current.style.width = mapWidth + 'px'
      lightboxRef.current.style.height = mapHeight + 'px'

      setCloseClicked(false)
      setShow(true)
    } else if (show) {
      setShow(false)
    }
  }, [
    map,
    mapRef,
    clusterRef,
    currentZoom,
    maxZoom,
    currentCluster,
    setCloseClicked,
    setShow
  ])

  useEffect(() => {
    if (currentCluster) {
      showCluster()
    } else {
      setShow(false)
    }
  }, [map, clusterRef, currentCluster])

  useEffect(() => {
    if (isMobile) {
      if (show && currentDevice) {
        setShow(false)
      } else if (
        !show &&
        !closeClicked &&
        !currentDevice &&
        currentCluster &&
        currentZoom >= maxZoom
      ) {
        setShow(true)
      }
    }
  }, [isMobile, currentDevice, currentCluster, show])

  useEffect(() => {
    if (closeClicked) {
      setZoomingOut(true)
      setCurrentCluster(null)
      setShow(false)
    }
  }, [closeClicked])

  const handleClose = () => {
    setCloseClicked(true)
  }

  const getCountStyle = () => {
    if (currentCluster) {
      const middle = getRadius() / 2 - 24
      return {
        left: middle + 'px',
        top: middle - 4 + 'px'
      }
    }
    return null
  }

  const getPinStyle = index => {
    const radius = getRadius()
    const adjustment = currentCluster.length / 2 - 10
    const angle = index * ((2 * Math.PI) / currentCluster.length)
    const x = Math.round(
      radius / 2 + ((radius + adjustment) / 3) * Math.cos(angle) - 18
    )
    const y = Math.round(
      radius / 2 + ((radius + adjustment) / 3) * Math.sin(angle) - 18
    )
    return {
      position: 'absolute',
      left: x + 'px',
      top: y + 'px'
    }
  }

  return (
    <Fragment>
      <div
        ref={clusterRef}
        className='cluster'
        style={{ display: show ? 'block' : 'none' }}
      >
        <button className='close' onClick={handleClose}>
          X
        </button>
        <div className='count' style={getCountStyle()}>
          {currentCluster?.length}
        </div>
        {currentCluster &&
          devices.map((device, index) => (
            <Pin
              key={device.id}
              device={device}
              setDevice={setCurrentDevice}
              style={getPinStyle(index, device)}
            />
          ))}
      </div>
      <div
        ref={lightboxRef}
        className='cluster-lightbox'
        style={{ display: show ? 'block' : 'none' }}
      />
    </Fragment>
  )
}

export default Cluster

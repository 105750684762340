import { Button, Flex, Text } from '@/primitives'
import ZoneUtils from '@/Util/ZoneUtils'

import { useDashboardParams } from '../../utils/useDashboardParams'
import { Strings } from './string'
import history from '../../../../history'
import { useMobileDashboard } from '../context'
import { get404String } from '../../Desktop/Zone404/string'
import useOrgLabel from '@/hooks/useOrgLabel'

export default function Zone404() {
  const params = useDashboardParams()
  const { state } = useMobileDashboard()
  const orgLabel = useOrgLabel(['site', 'facility', 'room', 'zone', 'subzone'])

  const strings = Strings()

  const depth = params.zone.split('/').length - 1
  const zoneDepthString = ZoneUtils.getZoneDepthIdentifierFromDepth(depth)
  const zoneString = get404String(orgLabel[zoneDepthString]?.text)

  const handleGoBack = e => {
    e.preventDefault()
    if (!state.hierarchy) {
      history.push('/zones')
    } else {
      history.goBack()
    }
  }

  return (
    <Flex
      direction='column'
      alignCrossAxis='center'
      alignMainAxis='center'
      style={{ width: '100%', flexGrow: 1 }}
    >
      <Text as='p' style={{ marginBottom: '2rem' }}>
        {params.sensorId && strings.device404}
        {!params.sensorId && zoneString}
      </Text>
      <Button variant='primary' size='small' tone={500} onClick={handleGoBack}>
        {strings.goBack}
      </Button>
    </Flex>
  )
}

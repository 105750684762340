import { COMPARISON_TYPES } from '@/components/DataboardPageV2/Utils/config'
import { DateTime } from 'luxon'

export function parseDataItemDate(date, timeZone) {
  const dateWithT = `${date.split('.')[0].replace(' ', 'T')}Z`
  const dateInUtc = DateTime.fromISO(dateWithT).toUTC()
  const dateInTimeZone = dateInUtc.setZone(timeZone)
  return dateInTimeZone.toISO()
}

const generateDuration = (fromDate, toDate) => {
  const startDate = new Date(fromDate).getTime()
  const endDate = new Date(toDate).getTime()
  return endDate - startDate
}

export const PARAMS_TYPE = {
  [COMPARISON_TYPES.LOCATION_RANGES]: 'zoneParams',
  [COMPARISON_TYPES.DATE_RANGES]: 'dateParams'
}

export const PARSE_DATA_BY_TYPE = {
  [COMPARISON_TYPES.LOCATION_RANGES]: parseZoneParams,
  [COMPARISON_TYPES.DATE_RANGES]: parseDateParams
}

export function getErrorId(params, type) {
  switch (type) {
    case COMPARISON_TYPES.DATE_RANGES:
      let id = params.dateRange
      if (params.dateRange === 'customRange') {
        id = `${params.dateRange}-${params.fromDate}-${params.toDate}`
      }
      return id
    case COMPARISON_TYPES.LOCATION_RANGES:
    default:
      return params?.sensorId ?? params?.zoneId
  }
}

function parseDateParams(params, timeframeGraphAverages) {
  return params?.dateParams?.reduce((dataArr, dateParam) => {
    let dateId = dateParam.dateRange
    if (dateParam.dateRange === 'customRange') {
      dateId = `${dateParam.dateRange}-${dateParam.fromDate}-${dateParam.toDate}`
    }

    const data = timeframeGraphAverages[dateId]
    const parsedData = parseTsGraphData(
      data,
      dateParam.fromDate,
      dateParam.toDate,
      params?.zoneParams[0]?.siteId,
      params?.zoneParams[0]?.zoneId,
      params?.zoneParams[0]?.sensorId,
      dateParam.timeInterval,
      dateParam.timeZone,
      dateParam.dateRange
    )

    return [...dataArr, ...parsedData]
  }, [])
}

function parseZoneParams(params, timeframeGraphAverages) {
  return params?.zoneParams?.reduce((dataArr, zoneParam) => {
    const sensorId = zoneParam.sensorId ?? zoneParam.zoneId
    const data = timeframeGraphAverages[sensorId]
    const parsedData = parseTsGraphData(
      data,
      zoneParam.fromDate,
      zoneParam.toDate,
      zoneParam.siteId,
      zoneParam.zoneId,
      zoneParam.sensorId,
      zoneParam.interval,
      zoneParam.timeZone,
      zoneParam.dateRange
    )

    return [...dataArr, ...parsedData]
  }, [])
}

export const parseTsGraphData = (
  data,
  fromDate,
  toDate,
  siteId,
  zoneId,
  sensorId,
  interval,
  timeZone,
  dateRange
) => {
  const duration = generateDuration(fromDate, toDate)
  return data.reduce((result, { datasetAverage }) => {
    if (datasetAverage === null) {
      return result
    }

    const { rows, columns } = datasetAverage

    if (!rows || rows?.length === 0) {
      return result
    }

    const averageIndex = columns.indexOf('average')
    const timestampIndex = columns.indexOf('timestamp')
    const measurementIndex = columns.indexOf('measure_name')

    let tsData = rows.map(row => {
      return {
        x: parseDataItemDate(row[timestampIndex], timeZone),
        y: parseFloat(row[averageIndex])
      }
    })

    tsData.sort((a, b) => {
      return DateTime.fromISO(a.x).toMillis() - DateTime.fromISO(b.x).toMillis()
    })

    result.push({
      zoneId: zoneId,
      siteId: siteId,
      sensorId: sensorId,
      measurement: rows[0][measurementIndex],
      duration,
      interval,
      dateRange,
      series: [
        {
          data: tsData,
          id: 'average'
        }
      ],
      toDate,
      fromDate
    })

    return result
  }, [])
}

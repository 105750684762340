export default function Logomark() {
  return (
    <svg
      className='Logo__Icon'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 128 128'
      width={32}
      height={32}
      fill='var(--ctx-theme-color-primary-700)'
    >
      <path d='m56 13.2-9-8.5-3.7 13 9.2 8z' />
      <path d='m82.6 17-3-12-10 9 3.2 11.7z' />
      <path d='m106 20.7-13 3.6-2 12 12.4-3.6z' />
      <path d='m34 36.4-3-12.6-12-3.2 3 13z' />
      <path d='m24 54.4-9-9.4-12 3.3 9 10z' />
      <path d='m114.3 57.2 9-10-11.8-3.3-8.8 9.4z' />
      <path d='m44.5 110.3 3.7 13 9-8.5-3.5-12.4z' />
      <path d='m70.8 114.3 10.3 8.7 2.7-12-9.7-8.5z' />
      <path d='m92.5 91.6 2 12 13 3.6-2.5-12z' />
      <path d='m20.5 107.4 12-3.3 3-12.6-12 2.7z' />
      <path d='m13.7 70-9 10 11.8 3.3 8.8-9.4z' />
      <path d='m115.6 70.8-11.6 3.8 8.8 9.4 12-3.3z' />
      <path d='m92 86 12.4 2.3-7.4-10.7c1-2.7 2-5.5 2.3-8.4l12.6-5.8-12.6-4.7c-.5-3-1.3-5.9-2.5-8.7l7.6-11-13 2.3c-1.6-2-3.5-3.8-5.5-5.4l.5-14.6-10 9.3c-2.5-.9-5-1.6-7.8-2l-6.2-13.4-5 13.6c-2.4.4-4.7 1-6.9 2l-11.6-9 1.8 14.8c-1.5 1.3-2.9 2.7-4.2 4.2l-16.3-1 10 11.3c-.9 2.3-1.5 4.6-2 7l-13.8 5 13.8 6.4c.4 2.3 1 4.5 1.7 6.7l-10 11 15.8-.9c1.4 1.7 3 3.3 4.7 4.7l-1.8 14.3 11-8.6c2.5 1 5 1.8 7.8 2.3l4.8 12.9 5.9-12.7c3-.4 5.9-1.2 8.7-2.2l9.4 8.8-.5-14c2.2-1.8 4.2-3.8 6-6zm-58.9-22c0-17 13.8-31 31-31s31 13.8 31 31-13.8 31-31 31-31-13.8-31-31z' />
    </svg>
  )
}
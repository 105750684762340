import InputError from '../../../../elements/InputError'

import {
  Button,
  Flex,
  Input,
  Box,
  Row,
  Cell,
  Text
} from '../../../../primitives'

import Strings from './Strings'
import { Fragment } from 'react'

function InventoryWeight({
  weight,
  updateWeight,
  removeWeight,
  errors = {},
  index = 0
}) {
  const strings = Strings()

  const unitWeightError = errors?.[`weights[${index}].unitWeight`] ?? null
  const quantityError = errors?.[`weights[${index}].quantity`] ?? null

  const updateUnitWeight = e => {
    const { value } = e.currentTarget
    const unitWeight = value.length > 0 ? Number(value) : ''
    const newWeight = { ...weight, unitWeight }
    updateWeight(newWeight)
  }

  const updateQuantity = e => {
    const { value } = e.currentTarget
    const unitQuantity = value.length > 0 ? Number(value) : ''
    const newWeight = { ...weight, quantity: unitQuantity }
    updateWeight(newWeight)
  }

  return (
    <Fragment>
      <Row>
        <Cell>
          <Flex axisGap={600} alignCrossAxis='center'>
            <Flex axisGap={300} direction='column'>
              <Box
                className='Operations__Input__Container'
                style={{ width: '125px' }}
              >
                <Input
                  id={weight.id}
                  type='number'
                  name='unitWeight'
                  value={weight.unitWeight.toString()}
                  onChange={updateUnitWeight}
                />
                <span className='Operations__Input__Suffix'>{strings.kg}</span>
              </Box>
            </Flex>
            <Flex direction='column' axisGap={300}>
              <Text as='p' tone={400} size={200}>
                {' x '}
              </Text>
            </Flex>

            <Flex axisGap={300} direction='column'>
              <Box
                className='Operations__Input__Container'
                style={{ width: '125px', align: 'left' }}
              >
                <Input
                  id={weight.id}
                  type='number'
                  name='quantity'
                  value={weight.quantity.toString()}
                  onChange={updateQuantity}
                />
              </Box>
            </Flex>

            <Flex direction='column' axisGap={300}>
              <Text as='p' tone={400} size={200}>
                {' = '}
                {weight.unitWeight * weight.quantity} {strings.kg}
              </Text>
            </Flex>
          </Flex>
          <Flex axisGap={600} alignCrossAxis='center'>
            <Flex axisGap={200} direction='column' className='Errors'>
              <InputError error={unitWeightError} />
              <InputError error={quantityError} />
            </Flex>
          </Flex>
        </Cell>
        <Cell>
          <Button
            className='InventoryWeight__Remove'
            variant='error'
            size='small'
            iconBefore='cancel'
            onClick={() => removeWeight(weight)}
          />
        </Cell>
      </Row>
    </Fragment>
  )
}

export default InventoryWeight

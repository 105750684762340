import { nanoid } from 'nanoid'
import { useEffect, useReducer, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import DatePicker from 'react-datepicker'

import { requestCustomers } from '@/actions/operations/customer'
import { requestBranches } from '@/actions/operations/branch'

import {
  clearReturn,
  createReturn,
  requestReturn,
  updateReturn
} from '@/actions/operations/return'

import {
  getCurrentUserOrganizations,
  getOperationsBranches,
  getOperationsCustomers,
  getOperationsReturn,
  getOperationsLoading,
  getOperationsError
} from '@/reducers/selectors'

import {
  Button,
  Flex,
  Box,
  Label,
  Select,
  Separator,
  Text,
  Loader,
  Input
} from '@/primitives'

import InputError from '@/elements/InputError'

import HeaderV2 from '@/components/Operations/Shared/HeaderV2'
import OrganizationInput from '../../../Shared/Organization/Input'

import ReturnItemForm from './ReturnItem/Form'
import ReturnItemsTable from './ReturnItem/Table'

import useDeviceSize from '@/hooks/useDeviceSize'
import { getValidationErrorMap } from '@/Util/GeneralUtils'

import history from '../../../../../history'
import Strings from '../Strings'

import { FIELDS, SCHEMA } from './config'

import { initialState, reducer, SET_STATE, UPDATE_INPUT } from './state'

function ReturnForm({ state, modulePath }) {
  const dispatch = useDispatch()
  const isMobile = useDeviceSize('mobile')
  const { itemId } = useParams()

  const strings = Strings()

  const organizations = getCurrentUserOrganizations()
  const customers = getOperationsCustomers()
  const branches = getOperationsBranches()
  const item = getOperationsReturn()
  const loading = getOperationsLoading()
  const error = getOperationsError()

  const [formState, dispatchFormState] = useReducer(reducer, {
    ...initialState,
    returnItems: []
  })

  const [returnItem, setReturnItem] = useState({})
  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)

  useEffect(() => {
    return () => {
      dispatch(clearReturn())
    }
  }, [])

  useEffect(() => {
    if (organizations.length > 0) {
      dispatch(
        requestCustomers({
          filter: {
            organizationId: organizations.map(o => o.id)
          }
        })
      )
    }
  }, [dispatch, organizations])

  useEffect(() => {
    if (formState.organizationId && formState.customerId) {
      dispatch(
        requestBranches({
          filter: {
            organizationId: [formState.organizationId],
            customerId: [formState.customerId]
          }
        })
      )
    }
  }, [formState.organizationId, formState.customerId])

  useEffect(() => {
    if (itemId && item.id !== itemId) {
      dispatch(requestReturn({ returnId: itemId }))
    }
  }, [itemId])

  useEffect(() => {
    if (item?.id && item.id === itemId) {
      dispatchFormState({
        type: SET_STATE,
        state: { ...item }
      })
    }
  }, [item])

  useEffect(() => {
    if (!itemId && state.organizations.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'organizationId',
        value: state.organizations[0]
      })
    }
  }, [state.organizations])

  useEffect(() => {
    if (!itemId && state.customers.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'customerId',
        value: state.customers[0]
      })
    }
  }, [state.customers])

  useEffect(() => {
    if (waiting && !loading && !error) {
      history.replace(modulePath)
    }
  }, [loading])

  const onSubmit = async () => {
    try {
      const payload = await SCHEMA().validate(formState, { abortEarly: false })
      setWaiting(true)

      if (itemId) {
        payload.returnId = itemId
        dispatch(updateReturn(payload))
      } else {
        dispatch(createReturn(payload))
      }
    } catch (error) {
      setErrors(getValidationErrorMap(error))
    }
  }

  const handleInput = e => {
    const { name, value } = e.currentTarget
    dispatchFormState({ type: UPDATE_INPUT, name, value })
  }

  const handleDateInput = (value, name) => {
    dispatchFormState({ type: UPDATE_INPUT, name, value })
  }

  const handleCustomerInput = e => {
    dispatchFormState({
      type: SET_STATE,
      state: {
        ...formState,
        customerId: e.currentTarget.value,
        branchId: ''
      }
    })
  }

  const handleBranchInput = e => {
    dispatchFormState({
      type: SET_STATE,
      state: {
        ...formState,
        branchId: e.currentTarget.value
      }
    })
  }

  const handleOrganizationInput = organizationId => {
    dispatchFormState({
      type: SET_STATE,
      state: {
        ...formState,
        organizationId,
        customerId: '',
        branchId: ''
      }
    })
  }

  const createReturnItem = () => {
    setReturnItem({
      id: nanoid()
    })
  }

  const closeReturnItemForm = () => {
    setReturnItem({})
  }

  const filterCustomers = () => {
    return customers.filter(c => c.organizationId === formState.organizationId)
  }

  const filterBranches = () => {
    return branches.filter(b => b.customerId === formState.customerId)
  }

  const getHeader = () => {
    if (itemId) {
      return strings.updateFormHeader
    }
    return strings.createFormHeader
  }

  return (
    <Flex
      as='form'
      axisgap={400}
      direction='column'
      className='Operations__Form'
    >
      <HeaderV2
        title={getHeader()}
        backPath={modulePath}
        buttonIcon={'save'}
        buttonText={strings.saveReturn}
        buttonCallback={onSubmit}
      />
      <Loader isLoading={loading}>
        <Flex axisGap={800}>
          <Flex
            axisGap={400}
            direction='column'
            className='Operations__Form__Fields'
          >
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.return}
              </Text>
              <OrganizationInput
                fieldName={FIELDS.organizationId}
                organizationId={formState.organizationId}
                handleInput={handleOrganizationInput}
                errors={errors}
              />
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
                className='Operations__Fields'
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.customer}
                    </Text>
                    <Select
                      className='Operations__Select'
                      name={FIELDS.customerId}
                      value={formState.customerId}
                      onChange={handleCustomerInput}
                    >
                      <option default value=''>
                        {strings.selectDefault}
                      </option>
                      {filterCustomers().map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </Select>
                    <InputError error={errors?.customerId} />
                  </Flex>
                </Label>
                <Label style={{ flex: '1 1 0' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.branch}
                    </Text>
                    <Select
                      className='Operations__Select'
                      name={FIELDS.branchId}
                      value={formState.branchId}
                      onChange={handleBranchInput}
                    >
                      <option default value=''>
                        {strings.selectDefault}
                      </option>
                      {filterBranches().map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </Select>
                    <InputError error={errors?.branchId} />
                  </Flex>
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                alignMainAxis='flex-start'
                direction={isMobile ? 'column' : 'row'}
                className='Operations__Fields'
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.returnDate}
                    </Text>
                    <DatePicker
                      maxDate={new Date()}
                      selected={formState.returnDate}
                      className='Operations__Select'
                      onChange={returnDate =>
                        handleDateInput(returnDate, FIELDS.returnDate)
                      }
                    />
                    <InputError error={errors?.returnDate} />
                  </Flex>
                </Label>
                <Label style={{ flex: '1 1 0' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.referenceNumber}
                    </Text>
                    <Input
                      className='Operations__Input'
                      type='string'
                      name={FIELDS.referenceNumber}
                      value={formState.referenceNumber}
                      onChange={handleInput}
                    />
                    <InputError error={errors?.referenceNumber} />
                  </Flex>
                </Label>
              </Flex>
            </Box>
            <Separator className='Separator__Line' />
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.returnItems}
              </Text>
              <Flex
                axisGap={300}
                direction='column'
                style={{ marginTop: '1em' }}
              >
                <ReturnItemsTable
                  parent={formState}
                  returnItems={formState.returnItems}
                  handleEdit={setReturnItem}
                  dispatchFormState={dispatchFormState}
                />
                <InputError error={errors?.returnItems} />
                {returnItem?.id && (
                  <ReturnItemForm
                    parent={formState}
                    returnItem={returnItem}
                    closeForm={closeReturnItemForm}
                    dispatchParentFormState={dispatchFormState}
                  />
                )}
                {!returnItem?.id && (
                  <Flex alignMainAxis={'flex-end'}>
                    <Button
                      onClick={createReturnItem}
                      size='small'
                      name='add'
                      variant='primary'
                      iconBefore='add'
                      className='Operations__Form__Add'
                    >
                      {strings.addReturnItem}
                    </Button>
                  </Flex>
                )}
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Loader>
    </Flex>
  )
}

export default ReturnForm

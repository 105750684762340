import { call, put, select, takeLatest } from 'redux-saga/effects'

import {
  REQUEST_ALL_COUNTRIES,
  REQUEST_COUNTRIES,
  REQUEST_COUNTRY,
  CREATE_COUNTRY,
  UPDATE_COUNTRY,
  receiveCountries,
  receiveAllCountries,
  receiveCountry,
  countryCreated,
  countryUpdated
} from '@/actions/operations/country'

import {
  getCountries,
  getCountry,
  createCountry,
  updateCountry
} from '@/api/operations/country'

import { displayBanner } from '../utils'
import { getOperationsAllCountries } from './utils'

import Strings from './Strings'

const strings = Strings()

function* sendCreateCountry(action) {
  const { data, error = null } = yield call(createCountry, action.params)
  yield put(displayBanner(error, strings.countryCreated))
  yield put(countryCreated(data, error))
}

export function* watchCreateCountry() {
  yield takeLatest(CREATE_COUNTRY, sendCreateCountry)
}

function* sendUpdateCountry(action) {
  const { data, error = null } = yield call(updateCountry, action.params)
  yield put(displayBanner(error, strings.countryUpdated))
  yield put(countryUpdated(data, error))
}

export function* watchUpdateCountry() {
  yield takeLatest(UPDATE_COUNTRY, sendUpdateCountry)
}

function* sendRequestCountries(action) {
  const { data, error = null } = yield call(getCountries, action.params)
  const { countries = [], count = 0 } = data
  yield put(receiveCountries(countries, count, error))
}

export function* watchRequestCountries() {
  yield takeLatest(REQUEST_COUNTRIES, sendRequestCountries)
}

function* sendRequestAllCountries(action) {
  const allCountries = yield select(getOperationsAllCountries)
  if (allCountries.length > 0) {
    yield put(receiveAllCountries(allCountries, allCountries.length, null))
  } else {
    const { data, error = null } = yield call(getCountries, {})
    const { countries = [], count = 0 } = data
    yield put(receiveAllCountries(countries, count, error))
  }
}

export function* watchRequestAllCountries() {
  yield takeLatest(REQUEST_ALL_COUNTRIES, sendRequestAllCountries)
}

function* sendRequestCountry(action) {
  const { data: country, error = null } = yield call(getCountry, action.params)
  yield put(receiveCountry(country, error))
}

export function* watchRequestCountry() {
  yield takeLatest(REQUEST_COUNTRY, sendRequestCountry)
}

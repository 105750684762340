import { makeOperationsApiCall } from './utils'

/*
 MUTATIONS
 */

export const createHarvest = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createHarvest(
        $organizationId: String!
        $siteId: String!
        $zoneId: String!
        $produceId: String!
        $varietyId: String!
        $storageFacilityId: String!
        $harvestDate: GraphQLTimestamp!
        $weights: [WeightInput]!
        $totalWeight: Int!
        $photos: GraphQLJSON
      ) {
        createHarvest(
          organizationId: $organizationId
          siteId: $siteId
          zoneId: $zoneId
          produceId: $produceId
          varietyId: $varietyId
          storageFacilityId: $storageFacilityId
          harvestDate: $harvestDate
          weights: $weights
          totalWeight: $totalWeight
          photos: $photos
        ) {
          id
          organizationId
          siteId
          zoneId
          totalWeight
          harvestDate
          produce {
            id
            name
          }
          variety {
            id
            name
          }
          storageFacility {
            id
            name
          }
          weights {
            id
            gradeId
            totalKg
          }
          createdAt
          updatedAt
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'createHarvest',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updateHarvest = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateHarvest(
        $harvestId: String!
        $organizationId: String!
        $siteId: String!
        $zoneId: String!
        $produceId: String!
        $varietyId: String!
        $storageFacilityId: String!
        $harvestDate: GraphQLTimestamp!
        $weights: [WeightInput]!
        $totalWeight: Int!
        $photos: GraphQLJSON
      ) {
        updateHarvest(
          harvestId: $harvestId
          organizationId: $organizationId
          siteId: $siteId
          zoneId: $zoneId
          produceId: $produceId
          varietyId: $varietyId
          storageFacilityId: $storageFacilityId
          harvestDate: $harvestDate
          weights: $weights
          totalWeight: $totalWeight
          photos: $photos
        ) {
          id
          organizationId
          siteId
          zoneId
          totalWeight
          harvestDate
          produce {
            id
            name
          }
          variety {
            id
            name
          }
          storageFacility {
            id
            name
          }
          weights {
            id
            gradeId
            totalKg
          }
          createdAt
          updatedAt
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'updateHarvest',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const deleteHarvest = async params => {
  try {
    const query = /* GraphQL */ `
      mutation deleteHarvest($harvestId: String!) {
        deleteHarvest(harvestId: $harvestId) {
          id
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'deleteHarvest',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

/*
 QUERIES
 */

export const getHarvests = async params => {
  try {
    const query = /* GraphQL */ `
      query getHarvests(
        $filter: GetHarvestsFilter
        $order: OrderEnum
        $offset: Int
        $limit: Int
      ) {
        getHarvests(
          filter: $filter
          order: $order
          offset: $offset
          limit: $limit
        ) {
          harvests {
            id
            organizationId
            siteId
            zoneId
            totalWeight
            harvestDate
            produce {
              id
              name
            }
            variety {
              id
              name
            }
            storageFacility {
              id
              name
            }
            weights {
              id
              gradeId
              totalKg
            }
            createdAt
            updatedAt
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getHarvests',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getHarvest = async params => {
  try {
    const query = /* GraphQL */ `
      query getHarvest($harvestId: String!) {
        getHarvest(harvestId: $harvestId) {
          id
          organizationId
          siteId
          zoneId
          produceId
          varietyId
          storageFacilityId
          harvestDate
          weights {
            id
            totalKg
            gradeId
          }
          totalWeight
          photos {
            id
            key
            url
            subject
            status
            updatedAt
          }
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getHarvest',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getTotalHarvestProduce = async params => {
  try {
    const query = /* GraphQL */ `
      query getTotalHarvestProduce(
        $range: String!
        $organizationId: [String]!
        $zoneId: [String]!
      ) {
        getTotalHarvestProduce(
          range: $range
          organizationId: $organizationId
          zoneId: $zoneId
        ) {
          id
          label
          value
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getTotalHarvestProduce',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getTotalHarvestVarietyByProduce = async params => {
  try {
    const query = /* GraphQL */ `
      query getTotalHarvestVarietyByProduce(
        $range: String!
        $organizationId: [String]!
        $zoneId: [String]!
        $produceId: String!
        $limit: Int
      ) {
        getTotalHarvestVarietyByProduce(
          range: $range
          organizationId: $organizationId
          zoneId: $zoneId
          produceId: $produceId
          limit: $limit
        ) {
          id
          label
          value
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getTotalHarvestVarietyByProduce',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getAllProducesByHarvestDate = async params => {
  try {
    const query = /* GraphQL */ `
      query getAllProducesByHarvestDate(
        $zoneId: [String]!
        $organizationId: [String]!
        $range: String!
      ) {
        getAllProducesByHarvestDate(
          zoneId: $zoneId
          organizationId: $organizationId
          range: $range
        ) {
          label
          value
          harvestDate
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getAllProducesByHarvestDate',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getAllVarietiesByHarvestDateAndProduce = async params => {
  try {
    const query = /* GraphQL */ `
      query getAllVarietiesByHarvestDateAndProduce(
        $zoneId: [String]!
        $organizationId: [String]!
        $produceId: String!
        $range: String!
        $limit: Int
      ) {
        getAllVarietiesByHarvestDateAndProduce(
          zoneId: $zoneId
          organizationId: $organizationId
          produceId: $produceId
          range: $range
          limit: $limit
        ) {
          label
          value
          harvestDate
          produceName
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getAllVarietiesByHarvestDateAndProduce',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

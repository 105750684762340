export const SET_HIERARCHY = 'SET_HIERARCHY'
export const SET_SITE_ID = 'SET_SITE_ID'
export const SET_CURRENT_ZONE = 'SET_CURRENT_ZONE'
export const SET_IS_CURRENT_ZONE_404 = 'SET_IS_CURRENT_ZONE_404'

export const mobileDashboardInitialState = {
  siteId: null,
  hierarchy: null,
  currentZone: null,
  isCurrentZone404: false
}

export function mobileDashboardReducer(state, action) {
  const handlers = {
    [SET_HIERARCHY]: (state, action) => {
      const { hierarchy } = action.payload
      return { ...state, hierarchy }
    },
    [SET_SITE_ID]: (state, action) => {
      const { siteId } = action.payload
      return { ...state, siteId, hierarchy: null }
    },
    [SET_CURRENT_ZONE]: (state, action) => {
      const { zone } = action.payload
      return {
        ...state,
        currentZone: zone
      }
    },
    [SET_IS_CURRENT_ZONE_404]: (state, action) => {
      const { is404 } = action.payload
      return { ...state, isCurrentZone404: is404 }
    }
  }

  if (!handlers[action.type]) {
    throw new Error(`Unknown action type: ${action.type}`)
  }

  return handlers[action.type](state, action)
}

import { DateTime } from 'luxon'

export const calculateTotalWeight = weights => {
  if (!weights) return 0
  return weights.reduce((total, weight) => {
    total = total += parseFloat(weight.kg)
    return total
  }, 0)
}

export const getZone = (zones, zoneId) => {
  const zoneIndex = zones.findIndex(zone => {
    return zone.id === zoneId
  })
  if (zoneIndex >= 0) {
    return zones[zoneIndex]
  }
  return null
}

export const fixDate = value => {
  if (value) {
    return new Date(value)
  }
  return null
}

export const formatDate = (date, format = 'DD') => {
  if (date) {
    return DateTime.fromMillis(date).toFormat(format)
  }
  return null
}

export const formatFloat = number => {
  if (number) {
    return Number.parseFloat(number).toFixed(2)
  }
  return null
}

export const formatNumber = number => {
  return new Intl.NumberFormat().format(number)
}

export const getFieldValue = (id, items, field = 'name') => {
  const itemIndex = items.findIndex(i => i.id === id)
  if (itemIndex >= 0) {
    return items[itemIndex][field]
  }
  return null
}

export const hasDataboardWarnings = warnings => {
  return warnings.filter(({ type }) => type).length > 0
}

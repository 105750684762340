import { I18n } from 'aws-amplify'

const Strings = () => ({
  traceability: I18n.get('Traceability'),
  selectDefault: I18n.get('Please Select'),
  location: I18n.get('Location'),
  zone: I18n.get('Zone'),
  harvestDate: I18n.get('Harvest Date'),
  produce: I18n.get('Produce'),
  variety: I18n.get('Variety'),
  packageDate: I18n.get('Package Date'),
  customer: I18n.get('Customer'),
  generateCode: I18n.get('Generate Code'),
  batch: I18n.get('Batch#')
})

export default Strings

import { Cell, FlexV2, Input, Row, Text } from '@/primitives'

import { TOGGLE_SENSOR } from '../state'
import { useFleetManagement } from '../context'
import { getStatusIcon, getReadableDateTime } from '../utils'

import Strings from '../../../Strings'

function isSelectDisabled(bulkAction, sensor) {
  if (bulkAction.value === 'ota_core') {
    return true
  } else if (bulkAction.cellularOnly) {
    return !sensor.sensorType.includes('cellular')
  }
  return false
}

const SensorRow = ({ zoneIndex, sensorIndex }) => {
  const { state, dispatchState } = useFleetManagement()
  const { sensorsByZone, bulkAction } = state
  const {
    tableHeaderDeviceType,
    tableHeaderDeviceHardwareVersion,
    tableHeaderDeviceStatus,
    tableHeaderDeviceLastUpdated
  } = Strings()

  const sensor = sensorsByZone[zoneIndex].sensors[sensorIndex]
  const { id, name, selected, sensorType, hardwareVersion, status } = sensor

  const onToggleSensor = () => {
    dispatchState({
      type: TOGGLE_SENSOR,
      payload: {
        zoneIndex,
        sensorIndex
      }
    })
  }

  return (
    <Row key={id} className='Sensor__Row'>
      <Cell>
        <FlexV2 axisGap={300}>
          <Input
            type='checkbox'
            checked={selected}
            onChange={onToggleSensor}
            disabled={isSelectDisabled(bulkAction, sensor)}
          />
          <Text fontWeight={500}>{name}</Text>
        </FlexV2>
      </Cell>
      <Cell columnName={tableHeaderDeviceType}>
        <Text fontWeight={300}>{sensorType}</Text>
      </Cell>
      <Cell columnName={tableHeaderDeviceHardwareVersion}>
        <Text fontWeight={300}>{hardwareVersion}</Text>
      </Cell>
      <Cell columnName={tableHeaderDeviceStatus}>{getStatusIcon(status)}</Cell>
      <Cell colSpan='2' columnName={tableHeaderDeviceLastUpdated}>
        {getReadableDateTime(sensor.updatedAt)}
      </Cell>
    </Row>
  )
}

export default SensorRow

import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'

import ZoneUtils from '@/Util/ZoneUtils'
import { Flex, Symbol, Text } from '@/primitives'
import { getDeviceTypes, getMeasurements } from '@/reducers/selectors'
import useIsRtl from '@/hooks/useIsRtl'
import { getAvailableMeasurements } from '../../utils/measurements'
import { useDashboard } from '../../context'

import { filterToolboxByAvailableMeasurements } from '../utils'
import { TOOLBOX_SECTIONS } from '../types/index'

import './index.scss'

const PADDING = 0.8

const getTreePaddingStyle = (level, isRtl, multiplier = 1) => {
  const padding = `${level * PADDING * multiplier}em`
  return isRtl ? { paddingRight: padding } : { paddingLeft: padding }
}

const buildNodePath = (path, part) => `${path}/${part}`

const getNodeId = node => node.id || node.widgetId

const TreeWidget = ({ data, level, setSelected, selected }) => {
  const isRtl = useIsRtl()
  const isSelected = selected && selected.widgetId === data.widgetId

  const handleAddWidget = e => {
    e.preventDefault()
    setSelected(isSelected ? null : data)
  }

  const variantToneProps = isSelected
    ? { variant: 'primary', tone: 500 }
    : { variant: 'page', tone: 900 }

  return (
    <Flex
      className={'ToolboxTree__Widget'}
      axisGap={300}
      alignMainAxis='space-between'
      alignCrossAxis='center'
      style={getTreePaddingStyle(level, isRtl, 1.5)}
      wrap='nowrap'
      onClick={handleAddWidget}
    >
      <Flex wrap='nowrap' axisGap={300} alignCrossAxis='center'>
        <Symbol name={data.icon} {...variantToneProps} />
        <Text size={200} {...variantToneProps}>
          {typeof data.title === 'function' ? data.title() : data.title}
        </Text>
      </Flex>
    </Flex>
  )
}

const ToolboxTreeNode = ({
  data,
  level,
  nodePath,
  expanded,
  setExpanded,
  selected,
  setSelected
}) => {
  const { state } = useDashboard()
  const isRtl = useIsRtl()

  const zoneDepth = state.currentZone.parentPath.split('/').length - 2
  const zoneDepthId = ZoneUtils.getZoneDepthIdentifierFromDepth(zoneDepth)

  if (data.denyVisibility && data.denyVisibility.includes(zoneDepthId)) {
    return null
  }

  const toggleExpand = () => {
    if (expanded && expanded.includes(nodePath)) {
      setExpanded(previousNodePath => {
        if (previousNodePath === nodePath) {
          if (nodePath.includes('/')) {
            return nodePath.split('/').slice(0, -1).join('/') // remove last element
          }
          return null
        } else if (previousNodePath.includes(nodePath)) {
          return buildNodePath(previousNodePath, data.id)
        }
      })
    } else {
      setExpanded(nodePath)
    }
  }

  const isExpanded = expanded && expanded.includes(nodePath)
  if (data.type === 'widget') {
    return (
      <TreeWidget
        data={data}
        level={level + 1}
        selected={selected}
        setSelected={setSelected}
      />
    )
  }

  const isNestedCategory = nodePath !== data.id && nodePath.includes(data.id)
  const nestedLevel = isNestedCategory ? level + 1 : level

  return (
    <Flex
      className={classNames(
        'ToolboxTree__Category',
        isNestedCategory && 'nested'
      )}
      direction='column'
      alignCrossAxis='flex-start'
    >
      <Flex
        className={classNames(
          'ToolboxTree__Category__Expand',
          isNestedCategory && 'nested'
        )}
        onClick={toggleExpand}
        wrap='nowrap'
        alignCrossAxis='center'
        alignMainAxis='space-between'
      >
        <Flex
          wrap='nowrap'
          axisGap={300}
          alignCrossAxis='center'
          style={getTreePaddingStyle(nestedLevel, isRtl)}
        >
          <Symbol name={data.icon} variant='page' tone={900} />
          <Text size={200} variant='page' tone={900}>
            {typeof data.title === 'function' ? data.title() : data.title}
          </Text>
        </Flex>
        <Symbol name={isExpanded ? 'expand less' : 'expand more'} />
      </Flex>
      {isExpanded && (
        <Flex className='ToolboxTree__Nested' direction='column'>
          {data?.items?.map(item => (
            <ToolboxTreeNode
              key={getNodeId(item)}
              nodePath={buildNodePath(nodePath, item.id)}
              data={item}
              level={level + 1}
              expanded={expanded}
              setExpanded={setExpanded}
              selected={selected}
              setSelected={setSelected}
            />
          ))}
        </Flex>
      )}
    </Flex>
  )
}

const ToolboxTree = ({ selected, setSelected }) => {
  const [expanded, setExpanded] = useState(false)
  const { state } = useDashboard()
  const params = useParams()
  const allDeviceTypes = getDeviceTypes()
  const allMeasurements = getMeasurements()

  const availableMeasurements = new Set(
    getAvailableMeasurements(
      state.hierarchy,
      state?.currentZone,
      state?.siteId,
      allDeviceTypes,
      allMeasurements,
      params,
      true
    )
  )

  const toolbox = useMemo(
    () =>
      filterToolboxByAvailableMeasurements(
        TOOLBOX_SECTIONS(),
        availableMeasurements
      ),
    [availableMeasurements]
  )

  return (
    <Flex className='ToolboxTree' direction='column'>
      {toolbox.map(item => {
        return (
          <ToolboxTreeNode
            key={getNodeId(item)}
            data={item}
            nodePath={item.id}
            level={0}
            expanded={expanded}
            setExpanded={setExpanded}
            selected={selected}
            setSelected={setSelected}
          />
        )
      })}
    </Flex>
  )
}

export default ToolboxTree

import { Fragment, useEffect } from 'react'
import { Switch, Route, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'

import Strings from './Strings'

import SupplierModalForm from './ModalForm'
import SupplierModalUsers from './ModalUsers'
import SupplierTable from './Table'
import { Text, FlexV2, Separator, Button } from '@/primitives'

import { fetchFactorySuppliers } from '@/slices/management/supplier'
import { getCurrentUser } from '@/reducers/selectors'

import { getIsGodMode } from '@/Util/PermissionUtils'

import useMediaQuery from '@/hooks/useMediaQuery'

const Suppliers = () => {
  const strings = Strings()

  const dispatch = useDispatch()

  const currentUser = getCurrentUser()

  const isGodMode = getIsGodMode(currentUser)
  const isAboveBreakpoint = useMediaQuery('min-width: 1024px')

  useEffect(() => {
    if (currentUser?.userName) {
      if (isGodMode) {
        dispatch(fetchFactorySuppliers())
      }
    }
  }, [dispatch, currentUser, isGodMode])

  if (!isGodMode) {
    return <Redirect to='/zones' />
  }

  return (
    <Fragment>
      <FlexV2 direction={'column'} axisGap={500}>
        <FlexV2
          direction={'row'}
          alignMainAxis='space-between'
          alignCrossAxis='center'
        >
          <FlexV2 direction={'column'}>
            <Text size={300} fontWeight={600}>
              {strings?.title}
            </Text>
            <Text variant='page' tone={900}>
              {strings?.subtitle}
            </Text>
          </FlexV2>
          <Button
            variant='primary'
            size='small'
            as={Link}
            to={{
              pathname: '/admin/suppliers/add'
            }}
          >
            {strings.addSupplier}
          </Button>
        </FlexV2>

        <Separator />

        <FlexV2 axisGap={500} direction={isAboveBreakpoint ? null : 'column'}>
          <SupplierTable />
        </FlexV2>
      </FlexV2>
      <Switch>
        <Route path='/admin/suppliers/:supplierId/users'>
          <SupplierModalUsers />
        </Route>
        <Route path='/admin/suppliers/add'>
          <SupplierModalForm />
        </Route>
      </Switch>
    </Fragment>
  )
}

export default Suppliers

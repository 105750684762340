import { updateList } from '../util'

import {
  RECEIVE_ALL_GRADES,
  REQUEST_ALL_GRADES,
  RECEIVE_GRADES,
  REQUEST_GRADES,
  CLEAR_GRADES,
  CLEAR_GRADE,
  REQUEST_GRADE,
  RECEIVE_GRADE,
  CREATE_GRADE,
  GRADE_CREATED,
  UPDATE_GRADE,
  GRADE_UPDATED,
  SET_GRADE
} from '@/actions/operations/grade'

export const gradeInitialState = {
  allGrades: [],
  grades: [],
  grade: {},
  gradesCount: 0,
  loading: false,
  error: null
}

const handlers = {
  [SET_GRADE]: (state, action) => ({
    ...state,
    grade: action.grade
  }),
  [CLEAR_GRADE]: state => ({
    ...state,
    grade: {},
    error: null
  }),
  [CLEAR_GRADES]: state => ({
    ...state,
    grades: [],
    error: null
  }),
  [REQUEST_ALL_GRADES]: state => ({
    ...state,
    error: null,
    loading: true
  }),
  [RECEIVE_ALL_GRADES]: (state, action) => ({
    ...state,
    allGrades: !action.error ? action.grades : [],
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_GRADES]: state => ({
    ...state,
    grades: [],
    gradesCount: 0,
    error: null,
    loading: true
  }),
  [RECEIVE_GRADES]: (state, action) => ({
    ...state,
    grades: !action.error ? action.grades : [],
    gradesCount: !action.error ? action.count : 0,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_GRADE]: state => ({
    ...state,
    grade: {},
    error: null,
    loading: true
  }),
  [RECEIVE_GRADE]: (state, action) => ({
    ...state,
    grade: !action.error ? action.grade : state.grade,
    error: action.error ?? null,
    loading: false
  }),
  [CREATE_GRADE]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [GRADE_CREATED]: (state, action) => ({
    ...state,
    grade: !action.error ? {} : state.grade,
    grades: updateList(action.error, state.grades, action.grade, true),
    allGrades: updateList(action.error, state.allGrades, action.grade, true),
    gradesCount: !action.error ? state.gradesCount + 1 : state.gradesCount,
    error: action.error ?? null,
    loading: false
  }),
  [UPDATE_GRADE]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [GRADE_UPDATED]: (state, action) => ({
    ...state,
    grade: !action.error ? {} : state.grade,
    grades: updateList(action.error, state.grades, action.grade, false),
    allGrades: updateList(action.error, state.allGrades, action.grade, false),
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

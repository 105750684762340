import { I18n } from 'aws-amplify'
import { number, object, string } from 'yup'

export const FIELDS = {
  id: 'id',
  productId: 'productId',
  quantityReturned: 'quantityReturned',
  reason: 'reason'
}

export const SCHEMA = object().shape({
  productId: string().required(I18n.get('A product must be selected.')),
  quantityReturned: number()
    .integer(I18n.get('A partial quantity is not allowed.'))
    .typeError(I18n.get('A returned quantity is required.'))
    .min(1, I18n.get('Returned quantity cannot be less than 1'))
    .required(I18n.get('A quantity returned must be filled')),
  reason: string().required(I18n.get('A reason is required.'))
})

import { DateTime } from 'luxon'

const startDate = DateTime.now().minus({ months: 1 }).toJSDate()

export const tableInitialState = {
  organizations: [],
  customers: [],
  orderDates: [startDate, new Date()],
  page: 1
}

export const tableReducer = (state, action) => {
  if (action.state) {
    return {
      ...state,
      ...action.state
    }
  } else if (action.page) {
    return {
      ...state,
      page: action.page
    }
  } else if (action.organizations) {
    return {
      ...state,
      organizations: action.organizations,
      customers: []
    }
  } else if (action.customers) {
    return {
      ...state,
      customers: action.customers
    }
  } else if (action.orderDates) {
    return {
      ...state,
      orderDates: action.orderDates
    }
  }
  return state
}

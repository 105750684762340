import { makeActionCreator } from '@/Util/ReduxUtils'

export const CREATE_WASTE = 'CREATE_WASTE'
export const createWaste = makeActionCreator(CREATE_WASTE, 'params')

export const WASTE_CREATED = 'WASTE_CREATED'
export const wasteCreated = makeActionCreator(WASTE_CREATED, 'waste', 'error')

export const UPDATE_WASTE = 'UPDATE_WASTE'
export const updateWaste = makeActionCreator(UPDATE_WASTE, 'params')

export const WASTE_UPDATED = 'WASTE_UPDATED'
export const wasteUpdated = makeActionCreator(WASTE_UPDATED, 'waste', 'error')

export const REQUEST_WASTES = 'REQUEST_WASTES'
export const requestWastes = makeActionCreator(REQUEST_WASTES, 'params')

export const RECEIVE_WASTES = 'RECEIVE_WASTES'
export const receiveWastes = makeActionCreator(
  RECEIVE_WASTES,
  'wastes',
  'count',
  'error'
)

export const REQUEST_WASTE = 'REQUEST_WASTE'
export const requestWaste = makeActionCreator(REQUEST_WASTE, 'params')

export const RECEIVE_WASTE = 'RECEIVE_WASTE'
export const receiveWaste = makeActionCreator(RECEIVE_WASTE, 'waste', 'error')

import { useEffect } from 'react'

import { FlexV2, Label, Text, Select } from '@/primitives'
import InputError from '@/elements/InputError'

import { getCurrentUserOrganizations } from '@/reducers/selectors'

import Strings from './Strings'

//TODO when fixing the ESlint warnings for the Operations/Pipelines and Operations/Management
//update the handleInput to useCallback
const OrganizationInput = ({
  fieldName,
  organizationId,
  handleInput,
  errors,
  disabled = false
}) => {
  const organizations = getCurrentUserOrganizations()

  const strings = Strings()

  useEffect(() => {
    if (organizations.length === 1) {
      if (organizations[0]?.id && organizationId !== organizations[0].id) {
        handleInput(organizations[0].id)
      }
    }
  }, [organizationId, organizations])

  if (organizations.length === 1) {
    return null
  }

  const handleChange = e => {
    handleInput(e.currentTarget.value)
  }

  return (
    <FlexV2 axisGap={400} direction='column'>
      <Label>
        <Text variant='page' tone={700}>
          {strings.organization}
        </Text>
        <Select
          className='Operations__Form__Select'
          name={fieldName}
          value={organizationId}
          onChange={handleChange}
          disabled={disabled}
        >
          <option default value=''>
            {strings.selectDefault}
          </option>
          {organizations.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </Select>
        <InputError error={errors?.organizationId} />
      </Label>
    </FlexV2>
  )
}

export default OrganizationInput

import { useEffect, useReducer, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { FlexV2, Form, Input, Label, Text, Button, Box } from '@/primitives'

import {
  sendUpdateSiteIngestionFrequencyConfig,
  cleanSiteIngestionFrequencyUpdated
} from '@/slices/timestream/ingestionFrequency'
import { showBanner } from '@/slices/util'

import {
  getSiteIngestionFrequency,
  getSiteIngestionFrequencySaving,
  getSiteIngestionFrequencyUpdated
} from '@/reducers/selectors'

import Strings from '../../Strings'
import { updateIngestionFrequencySchema } from './utils'

const initialState = {
  siteId: null,
  config: [
    {
      deviceType: 'envirosense',
      frequency: 1
    },
    {
      deviceType: 'watersense',
      frequency: 1
    },
    {
      deviceType: 'soilsense',
      frequency: 1
    },
    {
      deviceType: 'cellular_envirosense',
      frequency: 5
    },
    {
      deviceType: 'cellular_watersense',
      frequency: 5
    },
    {
      deviceType: 'cellular_soilsense',
      frequency: 5
    }
  ],
  errors: {}
}

function reducer(state, action) {
  switch (action.type) {
    case 'setConfig':
      const { siteId, config } = action.payload
      const nextConfig = [...state.config].map(configItem => {
        const item = config.find(
          item => item.deviceType === configItem.deviceType
        )
        if (item) return item
        return configItem
      })
      return {
        ...state,
        siteId,
        errors: {},
        config: nextConfig
      }
    case 'setErrors':
      return { ...state, errors: action.payload }
    default:
      return state
  }
}

// const getValuesHaveChanged = (initialValues, newValues) => {
//   return newValues.some(({ deviceType, frequency }) => {
//     const initialValue = initialValues.find(
//       item => item.deviceType === deviceType
//     )
//     return initialValue.frequency !== frequency
//   })
// }

const IngestionFrequency = () => {
  const strings = Strings()

  const reduxDispatch = useDispatch()

  const ingestionFrequency = getSiteIngestionFrequency()
  const saving = getSiteIngestionFrequencySaving()
  const updated = getSiteIngestionFrequencyUpdated()

  const [state, dispatch] = useReducer(reducer, initialState)

  const showSuccessBanner = useCallback(() => {
    reduxDispatch(
      showBanner({
        show: true,
        message: strings.ingestionFrequencyUpdated,
        type: 'success'
      })
    )
    reduxDispatch(cleanSiteIngestionFrequencyUpdated())
  }, [reduxDispatch, strings.ingestionFrequencyUpdated])

  useEffect(() => {
    if (updated) showSuccessBanner()
  }, [updated, showSuccessBanner])

  useEffect(() => {
    if (ingestionFrequency?.config?.length > 0) {
      dispatch({ type: 'setConfig', payload: ingestionFrequency })
    }
  }, [ingestionFrequency?.config, ingestionFrequency])

  function onChange(e) {
    const { name, value } = e.target
    let newState = { ...state }
    let config = [...state.config]
    const index = config.findIndex(c => c.deviceType === name)
    if (index > -1) {
      let item = { ...config[index] }
      item.frequency = parseInt(value)
      config[index] = item
      newState.config = config
      dispatch({ type: 'setConfig', payload: newState })
    }
  }

  async function onSubmitForm(e) {
    e.preventDefault()
    try {
      await updateIngestionFrequencySchema().validate(state, {
        abortEarly: false
      })
      const { siteId, config } = state
      reduxDispatch(sendUpdateSiteIngestionFrequencyConfig({ siteId, config }))
    } catch (err) {
      let errors = {}
      err.inner.forEach(({ path, message }) => (errors[path] = message))
      dispatch({ type: 'setErrors', payload: errors })
    }
  }

  if (ingestionFrequency?.config?.length === 0) {
    return null
  }

  return (
    <FlexV2 direction='column' style={{ marginTop: '1.5rem' }}>
      <Text as='p' size={300} fontWeight='700'>
        {strings.ingestionFrequecy}
      </Text>
      <Form onSubmit={onSubmitForm} className='IngestionFrequency__Form'>
        {state?.config?.map(({ deviceType, frequency }, index) => (
          <Label key={deviceType}>
            <Text variant={'page'} tone={700}>
              {deviceType}
            </Text>
            <Input
              type='number'
              name={deviceType}
              value={frequency}
              onChange={onChange}
              style={
                state.errors.hasOwnProperty(`config[${index}].frequency`)
                  ? { border: '1px solid var(--ctx-theme-color-error-600)' }
                  : {}
              }
            />
            {state.errors.hasOwnProperty(`config[${index}].frequency`) && (
              <Text variant='error' tone={700} fontWeight={300}>
                {state.errors[`config[${index}].frequency`]}
              </Text>
            )}
          </Label>
        ))}
        {state.errors.general && (
          <Box>
            <Text variant='error' tone={700} fontWeight={300}>
              {state.errors.general}
            </Text>
          </Box>
        )}
        <Button
          type='submit'
          variant={'primary'}
          size='small'
          disabled={saving}
          loading={saving}
          onClick={onSubmitForm}
          style={{ width: '9rem', marginTop: '1em' }}
        >
          {strings.submit}
        </Button>
      </Form>
    </FlexV2>
  )
}

export default IngestionFrequency

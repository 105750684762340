import useOperationsData from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Hooks/useOperationsData'
import Line from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/Chart/Line'
import { DateTime } from 'luxon'

export default function DatasetLine({ config, options, ...props }) {
  const { unit, segmentId } = options

  const { produceId } = config

  const { datasetData, queryZones } = useOperationsData({
    range: config.range,
    segmentId,
    filters: {
      interval: config.interval,
      produceId,
      range: config.range
    },
    queryType: config.query,
    tileType: config.chart,
    opsParams: { produceId }
  })

  let chartData = {}

  if (datasetData?.length) {
    for (let { zoneName } of queryZones) {
      chartData[zoneName] = datasetData?.reduce((result, dataPoint) => {
        const formattedDate = DateTime.fromMillis(
          dataPoint.harvestDate
        ).toISODate()
        result.push({
          x: formattedDate,
          y: dataPoint.value
        })

        return result
      }, [])
    }
  }

  return (
    <Line
      {...props}
      data={chartData ?? {}}
      unit={unit}
      config={config}
      queryZones={queryZones}
    />
  )
}

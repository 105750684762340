import { getOperationsAllGrades } from '../../../../reducers/selectors'

import InputError from '../../../../elements/InputError'

import {
  Button,
  Flex,
  Input,
  Select,
  Box,
  Row,
  Cell
} from '../../../../primitives'

import Strings from './Strings'

function Weight({
  organizationId,
  weight,
  weights,
  updateWeight,
  removeWeight,
  errors = {},
  index = 0
}) {
  const strings = Strings()

  const grades = getOperationsAllGrades()

  const kgError = errors?.[`weights[${index}].totalKg`] ?? null
  const gradeError = errors?.[`weights[${index}].gradeId`] ?? null

  const updateTotalKg = e => {
    const { value } = e.currentTarget
    const totalKg = value.length > 0 ? Number(value) : ''
    const newWeight = { ...weight, totalKg: totalKg }
    updateWeight(newWeight)
  }

  const updateGrade = e => {
    const { value } = e.currentTarget
    const newWeight = { ...weight, gradeId: value }
    updateWeight(newWeight)
  }

  const filterGrades = () => {
    return grades
      .filter(g => g.organizationId === organizationId)
      .filter(g => {
        const weightIndex = weights.findIndex(w => w.gradeId === g.id)
        return weightIndex < 0 || weights[weightIndex].id === weight.id
      })
  }

  return (
    <Row>
      <Cell columnName={strings.weightKg}>
        <Flex axisGap={300} direction='column'>
          <Box className='Operations__Input__Container'>
            <Input
              id={weight.id}
              type='number'
              name='totalKg'
              value={weight.totalKg}
              onChange={updateTotalKg}
            />
            <span className='Operations__Input__Suffix'>{strings.kg}</span>
          </Box>
          <InputError error={kgError} />
        </Flex>
      </Cell>
      <Cell columnName={strings.grade}>
        <Flex axisGap={300} direction='column'>
          <Select
            id={weight.id}
            value={weight.gradeId}
            name='gradeId'
            onChange={updateGrade}
          >
            <option default value=''>
              {strings.selectGrade}
            </option>
            {filterGrades().map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </Select>
          <InputError error={gradeError} />
        </Flex>
      </Cell>
      <Cell style={{ flex: '0 1 0', flexShrink: 1 }}>
        <Box style={{ marginTop: '0.2em', textAlign: 'right' }}>
          <Button
            variant='error'
            size='small'
            iconBefore='cancel'
            onClick={() => removeWeight(weight)}
          />
        </Box>
      </Cell>
    </Row>
  )
}

export default Weight

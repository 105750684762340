import { Fragment, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import ZonesFilter from '@/components/Operations/Shared/Filters/ZonesFilter'

import { requestDataboardSiteHierarchy } from '@/actions/operations/databoard'
import { getDataboardSitesHierarchies } from '@/reducers/selectors'

import ZoneUtils from '@/Util/ZoneUtils'
import { getZoneIdsFromPath } from '../utils'

const ZoneSelector = ({ state, dispatchState, index }) => {
  const zoneIds = getZoneIdsFromPath(state?.zonePath)
  const zonesHierarchy = getDataboardSitesHierarchies()

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (state.sites[0] && !zonesHierarchy.hasOwnProperty(state.sites[0])) {
      dispatch(requestDataboardSiteHierarchy({ siteId: state.sites[0] }))
    }
  }, [state.sites[0]])

  useEffect(() => {
    if (index === 0) {
      const path = history?.location?.pathname
        .replace('/operations', '')
        .replace('/harvest/data', '')

      dispatchState({
        zonePath: path
      })
    }
  }, [history.location.pathname])

  const zoneHasChildren = zoneId => {
    const zone = ZoneUtils.getZoneFromHierarchy(zonesHierarchy, zoneId)
    return zone ? Object.keys(zone?.children)?.length > 0 : false
  }

  let parentId = ''
  let hasChildren = false

  if (zoneIds?.length > 0) {
    const depth = zoneIds.length - 1
    parentId = zoneIds[depth]
  } else {
    parentId = state.sites[0]
  }

  if (parentId) hasChildren = zoneHasChildren(parentId)

  return (
    <Fragment>
      {zoneIds.map((id, zoneIndex) => (
        <ZonesFilter
          key={`depth-${id}`}
          state={state}
          dispatchState={dispatchState}
          parentId={zoneIndex > 0 ? zoneIds[zoneIndex - 1] : state?.sites[0]}
          zoneId={id}
          disabled={index === 0 && history?.location?.pathname.includes(id)}
        />
      ))}
      {hasChildren && (
        <ZonesFilter
          key={`depth-${zoneIds.length}`}
          state={state}
          dispatchState={dispatchState}
          parentId={parentId}
          zoneId={null}
        />
      )}
    </Fragment>
  )
}

export default ZoneSelector

import { parseValue } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/utils'

import Value from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/Chart/Value'
import useAverageData from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Hooks/useAverageData'
import { getRanges } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'

export default function AverageValue({ unit, options, config }) {
  const data = useAverageData({
    measurements: [options?.measurement],
    range: config.range
  })

  const parseOptions = value => ({
    fixedFactor: options.fixedFactor,
    scaleFactor: options.scaleFactor,
    value
  })

  return (
    <Value
      data={{
        value: parseValue(parseOptions(data?.battery_soc?.value)),
        min: parseValue(parseOptions(data?.battery_soc?.min)),
        max: parseValue(parseOptions(data?.battery_soc?.max)),
        unit,
        status: data?.battery_soc?.status,
        subtitle: getRanges(config.range)?.label ?? ''
      }}
    />
  )
}

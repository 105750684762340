import { useEffect, useState, useReducer } from 'react'
import { I18n } from 'aws-amplify'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { object, string } from 'yup'

import {
  requestGrade,
  createGrade,
  updateGrade
} from '@/actions/operations/grade'

import {
  getOperationsGrade,
  getOperationsLoading,
  getOperationsError
} from '@/reducers/selectors'

import { Flex, Form, Input, Label, Text, Loader } from '@/primitives'

import InputError from '@/elements/InputError'

import OrganizationInput from '../../Shared/Organization/Input'
import HeaderV2 from '@/components/Operations/Shared/HeaderV2'

import { getValidationErrorMap } from '@/Util/GeneralUtils'
import history from '../../../../history'

import { formInitalState, formReducer } from './state'

const SCHEMA = object({
  organizationId: string().required(I18n.get('An organization is required.')),
  name: string().required(I18n.get('Name is required.'))
})

function GradeForm({ state, modulePath }) {
  const dispatch = useDispatch()
  const { itemId } = useParams()

  const grade = getOperationsGrade()
  const loading = getOperationsLoading()
  const error = getOperationsError()

  const [formState, dispatchFormState] = useReducer(
    formReducer,
    formInitalState
  )

  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)
  const [fetchCalled, setFetchCalled] = useState(false)

  useEffect(() => {
    if (itemId && grade.id !== itemId) {
      if (!fetchCalled) {
        dispatch(requestGrade({ gradeId: itemId }))
        setFetchCalled(true)
      }
    }
  }, [dispatch, grade?.id, itemId, fetchCalled])

  useEffect(() => {
    if (grade?.id && grade.id === itemId) {
      dispatchFormState({ type: 'set-state', state: grade })
    }
  }, [grade, itemId])

  useEffect(() => {
    if (!itemId && state.organizations.length === 1) {
      dispatchFormState({
        type: 'update',
        name: 'organizationId',
        value: state.organizations[0]
      })
    }
  }, [state.organizations, itemId])

  useEffect(() => {
    if (waiting && !loading && !error) {
      history.replace(modulePath)
    }
  }, [waiting, loading, error, modulePath])

  const onSubmit = async e => {
    e?.preventDefault()
    try {
      await SCHEMA.validate(formState, { abortEarly: false })
      setWaiting(true)

      if (itemId) {
        formState.gradeId = itemId
        dispatch(updateGrade(formState))
      } else {
        dispatch(createGrade(formState))
      }
    } catch (err) {
      setErrors(getValidationErrorMap(err))
    }
  }

  const handleOrganizationInput = organizationId => {
    dispatchFormState({
      type: 'set-state',
      state: {
        ...formState,
        organizationId,
        produceId: ''
      }
    })
  }

  const onChange = e => {
    let { name, value } = e.currentTarget
    dispatchFormState({ type: 'update', name, value })
  }

  return (
    <Form className='Operations__Form'>
      <HeaderV2
        title={I18n.get('Manage Grades')}
        buttonIcon={'save'}
        buttonText={I18n.get('Save Grade')}
        buttonCallback={onSubmit}
        backPath={modulePath}
      />
      <Loader isLoading={loading}>
        <OrganizationInput
          fieldName={'organizationId'}
          organizationId={formState.organizationId}
          handleInput={handleOrganizationInput}
          errors={errors}
        />
        <Flex axisGap={300} className='Operations__Form__Fields'>
          <Label>
            <Text variant='page' tone={700}>
              {I18n.get('Name')}
            </Text>
            <Input value={formState.name} name='name' onChange={onChange} />
            <InputError error={errors?.name} />
          </Label>
        </Flex>
      </Loader>
    </Form>
  )
}

export default GradeForm

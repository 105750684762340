import { I18n } from 'aws-amplify'

export function getConnectionOptions(hasCoreDevice, zone) {
  const isRoomOrBelow = zone.parentPath.split('/').length >= 3

  let connectionTypeOptions = [{ value: 'modbus', label: I18n.get('Modbus') }]
  let connectionMethodOptions = [
    { value: 'direct', label: I18n.get('Direct Connect') }
  ]

  if (hasCoreDevice && isRoomOrBelow) {
    connectionTypeOptions.push({
      value: 'iot',
      label: I18n.get('IoT')
    })
    connectionMethodOptions.push({
      value: 'greengrass',
      label: I18n.get('Greengrass')
    })
  }

  return {
    connectionTypeOptions,
    connectionMethodOptions
  }
}

export function getConnectionMethod(device) {
  const { isIoT, isGreengrass } = device
  if (isGreengrass) {
    return 'greengrass'
  }

  if (isIoT) {
    return 'direct'
  }

  return null
}

export function getConnectionType(device) {
  const { isIoT, isGreengrass } = device
  if (!isIoT && isGreengrass) {
    return 'modbus'
  } else if (isIoT && isGreengrass) {
    return 'iot'
  }
  return null
}

export function getFilteredDeviceTypes(
  deviceTypes,
  connectionMethod,
  connectionType
) {
  return deviceTypes?.filter(deviceType => {
    if (deviceType.id === 'camera') {
      return false
    }
    if (connectionMethod === 'direct') {
      return deviceType.hasFirmware
    }
    if (connectionMethod === 'greengrass' && connectionType === 'iot') {
      return deviceType.supportsGreengrass && deviceType.hasFirmware
    }
    if (connectionMethod === 'greengrass' && connectionType === 'modbus') {
      return deviceType.supportsGreengrass && !deviceType.hasFirmware
    }
    return false
  })
}

export function getDeviceTypeMeasurements(
  measurements,
  deviceTypes,
  currentDeviceType
) {
  return measurements.reduce((acc, measurement) => {
    const deviceType = deviceTypes.find(({ id }) => id === currentDeviceType)
    if (deviceType?.measurements?.includes(measurement.id)) {
      acc.push({
        value: measurement.id,
        key: measurement.id,
        label: measurement.shortName,
        unit: measurement.unit,
        register: 100,
        register_count: 2
      })
    }
    return acc
  }, [])
}

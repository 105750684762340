import { useReducer, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import useDeviceSize from '@/hooks/useDeviceSize'

import { clearFertilizerInventories } from '@/actions/operations/fertilizerInventory'

import { createFertilizerInventoryCalibration } from '@/actions/operations/fertilizerInventoryCalibration'

import {
  getOperationsFertilizerInventory,
  getOperationsLoading,
  getOperationsError
} from '@/reducers/selectors'

import { Box, Flex, Label, Text, Input, Loader } from '@/primitives'

import InputError from '@/elements/InputError'

import { getValidationErrorMap } from '@/Util/GeneralUtils'

import history from '../../../../../history'
import Strings from '../Strings'

import { initialState, reducer, UPDATE_INPUT, SET_STATE } from './state'

import { FIELDS, SCHEMA } from './config'
import useAllFertilizersMap from '../../../Shared/Hooks/useFertilizersMap'
import { isEmpty } from '@aws-amplify/core'
import HeaderV2 from '@/components/Operations/Shared/HeaderV2'

function FertilizerInventoryCalibrationForm({
  state,
  modulePath,
  fertilizerInventoryId
}) {
  const dispatch = useDispatch()
  const isMobile = useDeviceSize('mobile')
  const isLaptop = useDeviceSize()
  const allFertilizersMap = useAllFertilizersMap()

  const strings = Strings()

  const fertilizerInventory = getOperationsFertilizerInventory()
  const loading = getOperationsLoading()
  const error = getOperationsError()

  const [formState, dispatchFormState] = useReducer(reducer, initialState)

  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)

  useEffect(() => {
    if (!fertilizerInventoryId) {
      history.push('/operations/inventory/fertilizer')
    }
  }, [fertilizerInventoryId])

  useEffect(() => {
    if (fertilizerInventory.id === fertilizerInventoryId) {
      dispatchFormState({
        type: SET_STATE,
        state: {
          organizationId: fertilizerInventory.organizationId,
          fertilizerInventoryId: fertilizerInventory.id
        }
      })
    }
  }, [fertilizerInventory])

  useEffect(() => {
    if (waiting && !loading && !error) {
      history.replace(modulePath)
    }
  }, [loading])

  const onSubmit = async e => {
    try {
      const payload = await SCHEMA().validate(formState, { abortEarly: false })
      setWaiting(true)

      dispatch(createFertilizerInventoryCalibration(payload))
      dispatch(clearFertilizerInventories())
    } catch (error) {
      setErrors(getValidationErrorMap(error))
    }
  }

  const handleInput = e => {
    const { name, value } = e.currentTarget
    dispatchFormState({ type: UPDATE_INPUT, name, value })
  }

  const handleNumericInput = e => {
    const { name, value } = e.currentTarget
    const newValue = value.length < 0 ? 0 : Number(value)
    dispatchFormState({ type: UPDATE_INPUT, name, value: newValue })
  }

  const getHeader = () => {
    return strings.createFormHeader
  }

  return (
    <Flex
      as='form'
      axisGap={400}
      direction='column'
      className='Operations__Form'
    >
      <HeaderV2
        title={getHeader()}
        backPath={modulePath}
        buttonIcon={'save'}
        buttonText={strings.saveFertilizerInventoryCalibration}
        buttonCallback={onSubmit}
      />
      <Loader isLoading={loading}>
        <Flex axisGap={800} direction={isLaptop ? 'column' : null}>
          <Flex
            axisGap={400}
            direction='column'
            className='Operations__Form__Fields'
          >
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.fertilizerInventoryCalibration}
              </Text>
              {fertilizerInventory.id && !isEmpty(allFertilizersMap) && (
                <Flex
                  axisGap={300}
                  alignMainAxis='space-evenly'
                  direction={isMobile ? 'column' : 'row'}
                >
                  <Label style={{ flex: '1 1 0' }}>
                    <Text variant='page' tone={700}>
                      {strings.fertilizerInventory}
                    </Text>
                    <Text
                      variant='page'
                      tone={700}
                      className='Operations__Form__Fields__Text'
                    >
                      {fertilizerInventory.storageFacility.name}&nbsp;-&nbsp;
                      {allFertilizersMap[fertilizerInventory.fertilizerId].name}
                    </Text>
                  </Label>
                </Flex>
              )}
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.currentTotalWeight}
                  </Text>
                  <Text
                    variant='page'
                    tone={700}
                    className='Operations__Form__Fields__Text'
                  >
                    {fertilizerInventory.totalWeight} {strings.kg}
                  </Text>
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.newTotalWeight}
                  </Text>
                  <Input
                    className='Operations__Input'
                    type='number'
                    name={FIELDS.newTotalWeight}
                    value={formState.newTotalWeight}
                    onChange={handleNumericInput}
                  />
                  <InputError error={errors?.newTotalWeight} />
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.reason}
                  </Text>
                  <Input
                    className='Operations__Input'
                    type='text'
                    name={FIELDS.reason}
                    value={formState.reason}
                    onChange={handleInput}
                  />
                  <InputError error={errors?.reason} />
                </Label>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Loader>
    </Flex>
  )
}

export default FertilizerInventoryCalibrationForm

export const UPDATE_INPUT = 'UPDATE_INPUT'
export const SET_STATE = 'SET_STATE'

export const initialState = {
  id: null,
  organizationId: '',
  inventoryId: null,
  fromStorageFacilityId: '',
  shippingMethodId: '',
  storageFacilityId: '',
  produceId: '',
  varietyId: '',
  gradeId: '',
  status: '',
  totalWeight: '',
  shippedDate: null,
  deliveredDate: null
}

const fixDate = value => {
  if (value) {
    return new Date(value)
  }
  return null
}

export function reducer(state, action) {
  const handlers = {
    [SET_STATE]: (state, action) => {
      const newState = { ...action.state }
      newState.shippedDate = fixDate(newState.shippedDate)
      newState.deliveredDate = fixDate(newState.deliveredDate)
      return {
        ...newState
      }
    },
    [UPDATE_INPUT]: (state, action) => ({
      ...state,
      [action.name]: action.value
    })
  }
  return handlers[action.type](state, action) || state
}

import { I18n } from 'aws-amplify'
import { object, string, number, ref, array, boolean } from 'yup'

import {
  RESOURCE_TYPE_USER,
  RESOURCE_TYPE_USER_MANAGEMENT,
  RESOURCE_TYPE_USER_ROLES,
  RESOURCE_TYPE_ZONE,
  hasReadPermissions
} from './PermissionUtils'

const lowercaseRequired = () => I18n.get('Only lowercase letters are allowed')
const emailRequired = () => I18n.get('Email is a required field')
const firstNameRequired = () => I18n.get('First name is a required field')
const lastNameRequired = () => I18n.get('Last name is a required field')
const userNameRequired = () => I18n.get('Username is a required field')
const roleRequired = () => I18n.get('Please select a role')
const roleNameRequired = () => I18n.get('Please enter a role name')
const roleNameDuplicate = () => I18n.get('Role name must be unique')
const roleNameCharacters = () =>
  I18n.get(
    'Spaces and special characters other than hyphen (-) and underscore (_) are not supported'
  )
const zoneNameRequired = () => I18n.get('Please enter a location name')
const zoneNameCharacters = () =>
  I18n.get('Hyphens (-) are the only special characters allowed in the name')
const zoneTimezoneRequired = () => I18n.get('Please select a timezone')

const zoneLongitudeRequired = () => I18n.get('Longitude can not be blank')
const zoneLatitudeRequired = () => I18n.get('Latitude can not be blank')
const zoneOrganizationIdRequired = () => I18n.get('Organization ID is required')
const zoneOrganizationRequired = () => I18n.get('Organization is required')
const zoneLongitudeRange = () =>
  I18n.get('Longitude must be between -180 and 180')
const zoneLatitudeRange = () => I18n.get('Latitude must be between -90 and 90')
const numberTypeError = () => I18n.get('Please enter a number')
const vendorNameRequired = () => I18n.get('Please enter the vendor name')
const vendorNameDuplicate = () =>
  I18n.get('A vendor with this name already exists')

const measurementIdRequired = () => I18n.get('Please enter an ID')
const measurementIdFormat = () =>
  I18n.get('Only letters and underscores are allowed for ID')
const measurementTypeRequired = () => I18n.get('Please select a type')
const measurementIdDuplicate = () =>
  I18n.get('A measurement with this ID already exists')
const measurementDescriptionRequired = () =>
  I18n.get('Please enter a description')
const measurementShortNameRequired = () => I18n.get('Please enter a short name')
const measurementUnitRequired = () => I18n.get('Please enter a unit')
const measurementThresholds = () =>
  I18n.get('Lower threshold can not be greater than upper threshold')
const measurementLowerThreshold = () => I18n.get('Please add a lower threshold')
const measurementUpperThreshold = () =>
  I18n.get('Please add an upper threshold')

const typeVendorRequired = () => I18n.get('Please select a vendor')
const typeNameRequired = () => I18n.get('Please enter a name')

const typeInputNameRequired = () => I18n.get('Type input must have a name')
const typeInputMeasurementRequired = () =>
  I18n.get('Type input must have a measurement')
const typeInputMeasurementDuplicate = () =>
  I18n.get('Type inputs can not use the same measurement')

const organizationRequired = () => I18n.get('Please select an organization')

// Modbus Configuration
const portAddressRequired = () => I18n.get('Port Address is a required field')
const slaveAddressRequired = () => I18n.get('Slave Address is a required field')
const baudrateRequired = () => I18n.get('Baud Rate is a required field')
const bytesizeRequired = () => I18n.get('Bytesize is a required field')
const stopbitsRequired = () => I18n.get('Stop Bits is a required field')
const timeoutRequired = () => I18n.get('Timeout is a required field')
const read_registerCountRequired = () =>
  I18n.get('Read Register count is a required field')
const read_registersRequired = () =>
  I18n.get('Read Register is a required field')
const read_measurementTypeRequired = () =>
  I18n.get('Read Measurement Type is a required field')
const read_measurementUnitRequired = () =>
  I18n.get('Read Measurement Unit is a required field')

const deviceTypeNameRequired = () => I18n.get('Please enter a name')
const deviceTypeDuplicate = () => I18n.get('The selected ID is already in use')
const deviceTypeTypeRequired = () => I18n.get('Please select a type')
const flashScriptVersionRequired = () => I18n.get('Please enter a version')
const flashScriptDuplicate = () =>
  I18n.get('The flash script version already exists')

export const getAdminDefaultUrl = coretexUser => {
  // Add more tabs depending on how the admin section grows, eg. thresholds
  const adminTabs = [
    {
      type: RESOURCE_TYPE_USER,
      id: RESOURCE_TYPE_USER_MANAGEMENT,
      url: 'user-management'
    },
    {
      type: RESOURCE_TYPE_USER,
      id: RESOURCE_TYPE_USER_ROLES,
      url: 'user-roles'
    },
    {
      type: RESOURCE_TYPE_ZONE,
      id: null,
      url: 'zones'
    }
  ]

  const defaultTab = adminTabs.find(tab => {
    return hasReadPermissions(coretexUser, tab.type, tab.id)
  })

  return defaultTab ? `/admin/${defaultTab?.url}` : null
}

const newUserSchema = () =>
  object().shape({
    userName: string()
      .lowercase(lowercaseRequired())
      .required(userNameRequired()),
    email: string().email().required(emailRequired()),
    firstName: string().required(firstNameRequired()),
    lastName: string().required(lastNameRequired()),
    role: string().required(roleRequired())
  })

const updateUserSchema = () =>
  object().shape({
    email: string().email().required(emailRequired()),
    firstName: string().required(firstNameRequired()),
    lastName: string().required(lastNameRequired())
  })

const newRoleSchema = existingRoles =>
  object().shape({
    name: string()
      .required(roleNameRequired())
      .matches(/^[0-9a-zA-Z_-]+$/, roleNameCharacters)
      .notOneOf(existingRoles, roleNameDuplicate),
    organizationIds: array()
      .of(string().required(organizationRequired))
      .min(1, organizationRequired)
  })

const newZoneSchema = (existingZones, zoneDetails) => {
  const isSite = isZoneSite(zoneDetails)
  return object().shape({
    name: string()
      .required(zoneNameRequired)
      .matches('^[^<>\'"\\;`%!@#$%^&*()_{}[]*$', zoneNameCharacters),
    // .notOneOf(existingZones, zoneNameDuplicate),
    longitude: number()
      .strict()
      .min(-180, zoneLongitudeRange)
      .max(180, zoneLongitudeRange)
      .required(zoneLongitudeRequired),
    latitude: number()
      .strict()
      .min(-90, zoneLatitudeRange)
      .max(90, zoneLatitudeRange)
      .required(zoneLatitudeRequired),
    timeZone: string().required(zoneTimezoneRequired),
    organizationId:
      isSite && string().nullable().required(zoneOrganizationIdRequired)
  })
}

const siteEnrollmentSchema = () => {
  return object().shape({
    name: string()
      .required(zoneNameRequired)
      .matches('^[^<>\'"\\;`%!@#$%^&*()_{}[]*$', zoneNameCharacters),
    longitude: number()
      .strict()
      .min(-180, zoneLongitudeRange)
      .max(180, zoneLongitudeRange)
      .required(zoneLongitudeRequired),
    latitude: number()
      .strict()
      .min(-90, zoneLatitudeRange)
      .max(90, zoneLatitudeRange)
      .required(zoneLatitudeRequired),
    timeZone: string().required(zoneTimezoneRequired),
    organizationId: string().required(zoneOrganizationRequired)
  })
}

const zoneNameSchema = () => {
  return object().shape({
    name: string()
      .required(zoneNameRequired)
      .matches('^[^<>\'"\\;`%!@#$%^&*()_{}[]*$', zoneNameCharacters)
  })
}

const newVendorSchema = existingVendors =>
  object().shape({
    name: string()
      .required(vendorNameRequired())
      .lowercase()
      .notOneOf(existingVendors, vendorNameDuplicate)
  })

const deviceEnrollmentSchema = () => {
  return object().shape({
    name: string()
      .required(zoneNameRequired)
      .matches('^[^<>\'"\\;`%!@#$%^&*()_{}[]*$', zoneNameCharacters),
    longitude: number()
      .strict()
      .typeError(numberTypeError)
      .min(-180, zoneLongitudeRange)
      .max(180, zoneLongitudeRange)
      .required(zoneLongitudeRequired),
    latitude: number()
      .strict()
      .typeError(numberTypeError)
      .min(-90, zoneLatitudeRange)
      .max(90, zoneLatitudeRange)
      .required(zoneLatitudeRequired),
    tag: string().max(255)
  })
}

const newMeasurementSchema = existingIds =>
  object().shape({
    id: string()
      .required(measurementIdRequired())
      .matches(/^[a-zA-Z_]+$/, measurementIdFormat())
      .notOneOf(existingIds, measurementIdDuplicate),
    type: string()
      .required(measurementTypeRequired())
      .oneOf(['hardware', 'calculation', 'software']),
    description: string().required(measurementDescriptionRequired()),
    shortName: string().required(measurementShortNameRequired()),
    unit: string().required(measurementUnitRequired())
  })

const newMeasurementThresholdsSchema = () =>
  object().shape({
    lowerThreshold: number()
      .required(measurementLowerThreshold)
      .lessThan(ref('upperThreshold'), measurementThresholds),
    upperThreshold: number().required(measurementUpperThreshold)
  })

const newTypeSchema = () =>
  object().shape({
    vendorId: string().required(typeVendorRequired()),
    name: string().required(typeNameRequired()),
    model: string(),
    modelVersion: string(),
    firmwareVersion: string(),
    communicationsProtocol: array().of(string())
  })

// ---- TO DO: HANDLE ON FRONTEND
const newModbusSchema = () =>
  object().shape({
    port_address: string().required(portAddressRequired()),
    slave_address: number().required(slaveAddressRequired()),
    baudrate: number().required(baudrateRequired()),
    bytesize: number().required(bytesizeRequired()),
    stopbits: number().required(stopbitsRequired()),
    timeout: number().required(timeoutRequired()),
    read_register_count: array()
      .of(number())
      .required(read_registerCountRequired()),
    read_registers: array().of(number()).required(read_registersRequired()),
    read_measurementType: array()
      .of(string())
      .required(read_measurementTypeRequired()),
    read_measurementUnit: array()
      .of(string())
      .required(read_measurementUnitRequired())
  })

const checkForDuplicates = ({ measurementId }, { parent }) => {
  const numOfMatches = parent.filter(
    item => item.measurementId === measurementId
  ).length
  return numOfMatches === 1
}

const typeInputSchema = existingMeasurementIds =>
  array(
    object()
      .shape({
        name: string().required(typeInputNameRequired()),
        measurementId: string().required(typeInputMeasurementRequired()),
        thresholds: array()
      })
      .test(
        'Check for measurement duplicates',
        typeInputMeasurementDuplicate,
        checkForDuplicates
      )
  )

const newDeviceTypeSchema = existingDeviceTypeIds =>
  object()
    .shape({
      id: string().required(),
      name: string().trim().required(deviceTypeNameRequired()),
      type: string().required(deviceTypeTypeRequired()),
      measurements: array(),
      hasFirmware: boolean()
    })
    .test({
      name: 'Check for device type duplicates',
      message: deviceTypeDuplicate,
      test: value => !existingDeviceTypeIds.includes(value?.id)
    })

const newFlashScriptSchema = existingFlashScripts =>
  object()
    .shape({
      deviceType: string().required(),
      version: string().trim().required(flashScriptVersionRequired()),
      description: string()
    })
    .test({
      name: 'Check for flash scripts duplicates',
      message: flashScriptDuplicate,
      test: value => {
        const found = existingFlashScripts.find(({ deviceType, version }) => {
          return deviceType === value?.deviceType && version === value?.version
        })

        return found ? false : true
      }
    })

export function getZoneDepth(zone) {
  if (!zone?.parentPath) {
    return 0
  }

  const depth = zone?.parentPath?.split('/')
  return depth.length - 1
}

export function getZoneSubNameV2(depth, orgLabels) {
  if (depth === 0) return orgLabels.site.text
  if (depth === 1) return orgLabels.facility.text
  if (depth === 2) return orgLabels.room.text
  return orgLabels.zone.text
}

export function isZoneRoom(zone) {
  const depth = getZoneDepth(zone)
  return depth === 3
}

export function isZoneRoomOrBelow(zone) {
  const depth = getZoneDepth(zone)
  return depth >= 3
}

export function isZoneSite(zone, newZone = false) {
  let nextZone = { ...zone }
  if (newZone) {
    nextZone.parentPath = `${zone.parentPath}/${zone.id}`
  }

  const depth = getZoneDepth(nextZone)
  return depth === 1
}

export const getOrgNameById = (organizationId, organizations) => {
  const organization = organizations?.find(org => org.id === organizationId)
  return organization?.name || ''
}

// Get zone object from zoneHierarchy Object
function walkZoneHierarchy(obj, keys) {
  let current = obj
  for (let index = 0; index < keys.length; index++) {
    if (current.hasOwnProperty(keys[index])) {
      if (index !== keys.length - 1) {
        current = current[keys[index]].children
      } else {
        current = current[keys[index]]
      }
    } else {
      return undefined // Return undefined if the key doesn't exist in the object
    }
  }
  return current
}

export const getZoneObjectFromZoneHierarchy = (zoneHierarchy, zone) => {
  if (!Object.keys(zoneHierarchy).length || !Object.keys(zone).length) {
    return undefined
  }

  return walkZoneHierarchy(
    zoneHierarchy,
    zone.parentPath.split('/').filter(item => item.length)
  )
}

const AdminUtils = {
  deviceEnrollmentSchema,
  getZoneObjectFromZoneHierarchy,
  isZoneRoom,
  isZoneRoomOrBelow,
  newDeviceTypeSchema,
  newMeasurementSchema,
  newMeasurementThresholdsSchema,
  newModbusSchema,
  newRoleSchema,
  newTypeSchema,
  newUserSchema,
  newVendorSchema,
  newZoneSchema,
  newFlashScriptSchema,
  siteEnrollmentSchema,
  typeInputSchema,
  updateUserSchema,
  zoneNameSchema
}

export default AdminUtils

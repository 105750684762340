import { Fragment, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import UpsertHelpTextForm from './UpsertHelpTextForm'
import TranslationsForm from './TranslationsForm'
import {
  Table,
  Slot,
  Row,
  Column,
  Cell,
  Text,
  Button,
  Flex
} from '@/primitives'
import { Select, Pagination } from '@/elements'

import './index.scss'
import Strings from '../../Strings'

import { fetchFyiTooltips } from '@/slices/fyi/helpText'
import {
  getFyiTooltips,
  getFyiTooltipsRequested,
  getFyiTags
} from '@/reducers/selectors'
import { getTagOptions } from '../util'

const strings = Strings()
const PAGE_SIZE = 10

const HelpText = () => {
  const [showUpsertForm, setShowUpsertForm] = useState(false)
  const [showTranslationsForm, setShowTranslationsForm] = useState(false)
  const [itemToEdit, setItemToEdit] = useState(null)
  const [copying, setCopying] = useState(null)
  const [selectedTags, setSelectedTags] = useState([])
  const [page, setPage] = useState(1)
  const [filteredHelpText, setFilteredHelpText] = useState([])

  const dispatch = useDispatch()

  const helpTextEntries = getFyiTooltips()
  const helpTextFetched = getFyiTooltipsRequested()
  const tags = getFyiTags()

  if (!helpTextFetched) {
    dispatch(fetchFyiTooltips())
  }

  useEffect(() => {
    if (helpTextEntries?.length > 0) {
      setFilteredHelpText(helpTextEntries)
    }
  }, [helpTextEntries, setFilteredHelpText])

  const setShowAddForm = () => setShowUpsertForm(true)
  const setHideForm = () => {
    if (showUpsertForm) setShowUpsertForm(false)
    if (showTranslationsForm) setShowTranslationsForm(false)
    if (itemToEdit) setItemToEdit(null)
  }

  function onChangeSelect(selectedItems) {
    const tagIds = selectedItems?.map(({ value }) => value)
    setSelectedTags(tagIds)

    if (tagIds?.length > 0) {
      const filteredByTags = helpTextEntries.reduce((acc, helpText) => {
        const show = helpText.tags.some(tag => tagIds.includes(tag))
        if (show) {
          return [...acc, helpText]
        }
        return acc
      }, [])
      setFilteredHelpText(filteredByTags)
    }

    if (tagIds?.length === 0) {
      setFilteredHelpText(helpTextEntries)
    }

    setPage(1)
  }

  function getHelpTextByPage() {
    const startIndex = page * PAGE_SIZE - PAGE_SIZE
    const endIndex = page * PAGE_SIZE
    const pageItems = filteredHelpText.slice(startIndex, endIndex)
    return pageItems
  }

  function insertTableRow(helpItem) {
    function onClickEdit() {
      setItemToEdit(helpItem)
      setShowUpsertForm(true)
    }

    function onClickTranslations() {
      setItemToEdit(helpItem)
      setShowTranslationsForm(true)
    }

    function insertCopyButton(id) {
      const copyToClipboard = () => {
        navigator.clipboard.writeText(id)
        setCopying(id)
        setTimeout(() => {
          setCopying(null)
        }, '2000')
      }

      return (
        <Button
          onClick={copyToClipboard}
          variant='text'
          iconBefore={copying === id ? 'check' : 'content_copy'}
          size='small'
          style={{ margin: '0 0.5rem', padding: '0 0.5rem' }}
        />
      )
    }

    return (
      <Row key={helpItem?.helpTextId}>
        <Cell columnName={strings.tooltipIdColHeader}>
          <Text as='p' style={{ textWrap: 'nowrap' }}>
            {helpItem.helpTextId}
          </Text>
          {insertCopyButton(helpItem.helpTextId)}
        </Cell>
        <Cell columnName={strings.defaultTextColHeader}>
          <Text as='h6'>{helpItem.title}</Text>
          <Text>{helpItem.text}</Text>
        </Cell>
        <Cell columnName={strings.tableActionColumn}>
          <Flex axisGap={300}>
            <Button size='small' variant='primary' onClick={onClickEdit}>
              {strings.editButtonSentence}
            </Button>
            <Button
              size='small'
              variant='primary'
              onClick={onClickTranslations}
            >
              {strings.translations}
            </Button>
          </Flex>
        </Cell>
      </Row>
    )
  }

  return (
    <Fragment>
      <Flex alignMainAxis='space-between' alignCrossAxis='center'>
        <Text as='p'>{strings.helpTextSubheading}</Text>
        <Button size='small' variant='primary' onClick={setShowAddForm}>
          {strings.addNewEntry}
        </Button>
      </Flex>
      <UpsertHelpTextForm
        itemToEdit={itemToEdit}
        showForm={showUpsertForm}
        setHideForm={setHideForm}
      />
      <TranslationsForm
        itemToEdit={itemToEdit}
        showForm={showTranslationsForm}
        setHideForm={setHideForm}
      />
      {tags?.length > 0 && (
        <Select
          name='tags'
          value={selectedTags}
          options={getTagOptions(tags)}
          placeholder={strings.filterByTags}
          controlStyles={{ marginTop: '1.5rem' }}
          onChange={onChangeSelect}
          isMulti={true}
        />
      )}
      <Table style={{ marginTop: '1rem' }}>
        <Slot name='head'>
          <Row>
            <Column>{strings.tooltipIdColHeader}</Column>
            <Column>{strings.defaultTextColHeader}</Column>
            <Column>{strings.tableActionColumn}</Column>
          </Row>
        </Slot>
        <Slot name='body'>
          {filteredHelpText?.length > 0 &&
            getHelpTextByPage().map(helpItem => insertTableRow(helpItem))}
        </Slot>
      </Table>
      {filteredHelpText?.length > 0 && (
        <Pagination
          type='simple'
          totalItems={filteredHelpText?.length}
          totalPages={Math.ceil(filteredHelpText?.length / PAGE_SIZE)}
          setTotalPages={() => {}}
          page={page}
          setPage={setPage}
          pageSize={PAGE_SIZE}
        />
      )}
    </Fragment>
  )
}

export default HelpText

export const tableInitialState = {
  organizations: [],
  sites: [],
  page: 1
}

export const tableReducer = (state, action) => {
  if (action.state) {
    return {
      ...state,
      ...action.state
    }
  } else if (action.page) {
    return {
      ...state,
      page: action.page
    }
  } else if (action.organizations) {
    return {
      ...state,
      organizations: action.organizations,
      sites: []
    }
  } else if (action.sites) {
    return {
      ...state,
      sites: action.sites
    }
  }
  return state
}

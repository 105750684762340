import { Fragment, useState } from 'react'
import { I18n } from 'aws-amplify'
import classNames from 'classnames'
import { downloadZip } from 'client-zip'
import { DateTime } from 'luxon'

import { FlexV2, Button, Slot, Text, Form, Box, Loader } from '@/primitives'

import Dialog from '@/elements/Dialog'

import { getIsGodMode } from '@/Util/PermissionUtils'

import { getCurrentUser, getDevice } from '@/reducers/selectors'

import {
  createDownloadableCsv,
  createDownloadableFilename,
  promptDownload
} from '../Widgets/Shared/utils/dataset'

import ZoneUtils from '@/Util/ZoneUtils'

import {
  RESOURCE_TYPE_DEVICE,
  RESOURCE_TYPE_DEVICE_CALIBRATION,
  RESOURCE_TYPE_DEVICE_CAMERAS,
  hasReadPermissions,
  hasEditPermissions
} from '@/Util/PermissionUtils'
import { useDashboardParams } from '../../utils/useDashboardParams'

import { useDashboard } from '../context'
import { SET_IS_TOOLBOX_OPEN } from '../state'
import { Strings } from '../string'

import history from '../../../../history'

import { TYPES_CARD_CONFIGURATIONS } from '@/Util/CalibrationUtils'

export default function HeaderControls() {
  const strings = Strings()
  const params = useDashboardParams()
  const coretexUser = getCurrentUser()
  const isGodMode = getIsGodMode(coretexUser)
  const device = getDevice()

  const cardConfigurations = Object.keys(TYPES_CARD_CONFIGURATIONS).reduce(
    (acc, key) => {
      //TODO: remove if when enable to all users
      if (key === 'cellular_envirosense' && !isGodMode) return acc
      acc[key] = TYPES_CARD_CONFIGURATIONS[key]
      return acc
    },
    {}
  )

  const { state, dispatchState } = useDashboard()
  const { isToolboxOpen } = state

  const [showModal, setShowModal] = useState(false)
  const [isZipping, setIsZipping] = useState(false)

  const canViewCameras = hasReadPermissions(
    coretexUser,
    RESOURCE_TYPE_DEVICE,
    RESOURCE_TYPE_DEVICE_CAMERAS
  )

  const canEditCalibration = hasEditPermissions(
    coretexUser,
    RESOURCE_TYPE_DEVICE,
    RESOURCE_TYPE_DEVICE_CALIBRATION
  )

  const handleAddWidget = () => {
    dispatchState({
      type: SET_IS_TOOLBOX_OPEN,
      payload: { isOpen: !isToolboxOpen }
    })
  }

  const getBasePath = () => {
    return `${ZoneUtils.getZoneBasePath(params)}`
  }

  const goToVideoFeeds = () => {
    history.push(`${getBasePath()}/video-feeds`)
  }
  const goToDataboard = () => {
    let uri = getBasePath()
    if (params.sensorId) {
      uri = `${uri}/sensor/${params.sensorType}/${params.sensorId}/data`
    } else {
      uri = `${uri}/data`
    }
    history.push(uri)
  }

  const goToCalibration = () => {
    let uri = `${getBasePath()}/sensor/${params.sensorType}/${
      params.sensorId
    }/calibration`
    history.push(uri)
  }

  const onDownloadDataset = e => {
    e?.preventDefault()
    setShowModal(true)
  }

  const onClickClose = () => {
    if (isZipping) return
    setShowModal(false)
    setIsZipping(false)
  }

  const onConfirmDownload = async e => {
    e?.preventDefault()
    const { name, timeZone } = state.hierarchy
    setIsZipping(true)

    let csvs = []
    for (let dataset of state.downloadableDatasets.values()) {
      const { props: widgetProps } = state.widgets.get(dataset.id)
      const fileName = createDownloadableFilename(
        widgetProps.config.range,
        widgetProps.config.interval,
        widgetProps.options.measurement
      )
      const csv = createDownloadableCsv(dataset, timeZone, name)
      const file = new Blob([csv], { type: 'text/csv' })
      csvs.push({ name: fileName + '.csv', input: file })
    }

    const date = DateTime.now().setZone(timeZone).toFormat('yyyy-MM-dd-HH-mm')
    const fileName = `dashboard_${date}.zip`

    const blob = await downloadZip(csvs).blob()
    const href = URL.createObjectURL(blob)
    promptDownload(href, fileName)

    setIsZipping(false)
    setShowModal(false)
  }

  return (
    <Fragment>
      {showModal && (
        <Dialog
          open={showModal}
          onOpenChange={onClickClose}
          className={classNames(isZipping && 'ConfirmModal-zipping')}
        >
          <Slot name='title'>
            <FlexV2 direction='column'>
              <Text as='h5' style={{ marginBottom: '0.5em' }}>
                {I18n.get('Download Datasets')}
              </Text>
              <Text as='sub' variant='page' tone={800}>
                {I18n.get('Download all dashboard line chart datasets as CSV')}
              </Text>
            </FlexV2>
          </Slot>
          <Slot name='content' style={{ marginTop: '1em' }}>
            <Loader
              isLoading={isZipping}
              variant='page'
              text={I18n.get(
                'Zipping data... Do not close or refresh the page.'
              )}
            >
              <Box style={{ paddingTop: '1em' }}>
                <Text as='p' variant='page' tone={1000}>
                  {I18n.get('You are about to download a zip file containing')}{' '}
                  {state.downloadableDatasets.size}{' '}
                  {I18n.get('line chart datasets.')}
                </Text>
                <Text
                  as='p'
                  variant='danger'
                  tone={500}
                  style={{ marginBottom: '0' }}
                >
                  {I18n.get(
                    'This may take a while depending on the number of datasets.'
                  )}
                </Text>
              </Box>
            </Loader>
          </Slot>
          <Slot name='actions'>
            <Form onSubmit={onConfirmDownload} style={{ margin: 0 }}>
              <FlexV2 alignMainAxis='space-between'>
                <Button onClick={onClickClose} disabled={isZipping}>
                  {I18n.get('Cancel')}
                </Button>
                <Button type='submit' variant='info' disabled={isZipping}>
                  {I18n.get('Download')}
                </Button>
              </FlexV2>
            </Form>
          </Slot>
        </Dialog>
      )}
      <FlexV2 className='Dashboard__Header__Controls' axisGap={300}>
        <FlexV2 axisGap={400}>
          {!params?.sensorId && (
            <Button
              size='small'
              className='Dashboard__Header__Controls__Download'
              iconBefore='file download'
              onClick={onDownloadDataset}
              disabled={state.downloadableDatasets.size === 0}
            />
          )}

          {!params?.sensorId && canViewCameras && (
            <Button
              size='small'
              iconBefore='ondemand_video'
              onClick={goToVideoFeeds}
            />
          )}

          <Button
            size='small'
            iconBefore='query_stats'
            onClick={goToDataboard}
          />

          {/* Remove soilsense check once implemented*/}

          {params?.sensorId &&
            device?.isIoT &&
            canEditCalibration &&
            Object.keys(cardConfigurations).includes(device?.sensorType) && (
              <Button
                size='small'
                iconBefore='tune'
                onClick={goToCalibration}
              />
            )}

          {!params.sensorId && (
            <Button
              size='small'
              iconBefore='add'
              variant='info'
              onClick={handleAddWidget}
              disabled={isToolboxOpen}
            >
              {state.currentBreakpoint !== 'md' ? strings.addWidget : null}
            </Button>
          )}
        </FlexV2>
      </FlexV2>
    </Fragment>
  )
}

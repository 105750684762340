import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {
  createMeasurement,
  updateMeasurement
} from '@/api/deviceManagement/measurement'

import { isLoading } from '@/slices/util'

import {
  fetchMeasurementsAndDeviceTypes,
  fetchDeviceManagementOptions
} from './index'

export const sendCreateMeasurement = createAsyncThunk(
  'sendCreateMeasurement',
  async (params, { dispatch }) => {
    dispatch(isLoading(true))
    const { measurement, error } = await createMeasurement(params)
    dispatch(isLoading(false))
    return { measurement, error }
  }
)

export const sendUpdateMeasurement = createAsyncThunk(
  'sendUpdateMeasurement',
  async (params, { dispatch }) => {
    dispatch(isLoading(true))
    const { measurement, error } = await updateMeasurement(params)
    dispatch(isLoading(false))
    return { measurement, error }
  }
)

const deviceManagementMeasurementSlice = createSlice({
  name: 'deviceManagementMeasurementReducer',
  initialState: {
    measurements: [],
    newMeasurement: null,
    newMeasurementError: null,
    updatedMeasurement: null,
    updateMeasurementError: null,
    selectedMeasurement: {},
    isLoadingMeasurements: false,
    isSaving: false,
    error: null
  },
  reducers: {
    cleanMeasurement: state => {
      state.newMeasurement = null
      state.newMeasurementError = null
      state.updatedMeasurement = null
      state.updateMeasurementError = null
      state.selectedMeasurement = {}
    },
    setMeasurement: (state, action) => {
      state.selectedMeasurement = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchMeasurementsAndDeviceTypes.pending, (state, action) => {
        state.error = null
      })
      .addCase(fetchMeasurementsAndDeviceTypes.fulfilled, (state, action) => {
        const { measurements, measurementsError } = action?.payload
        state.measurements = measurementsError
          ? state.measurements
          : measurements
      })
      .addCase(fetchDeviceManagementOptions.pending, (state, action) => {
        state.error = null
        state.isLoadingMeasurements = true
      })
      .addCase(fetchDeviceManagementOptions.fulfilled, (state, action) => {
        const { measurements, measurementsError } = action?.payload
        state.measurements = measurementsError
          ? state.measurements
          : measurements
        state.error = measurementsError
        state.isLoadingMeasurements = false
      })
      .addCase(sendCreateMeasurement.pending, (state, action) => {
        state.newMeasurementError = null
        state.newMeasurement = null
        state.isSaving = true
      })
      .addCase(sendCreateMeasurement.fulfilled, (state, action) => {
        const { measurement, error } = action?.payload
        state.newMeasurement = error ? state.newMeasurement : measurement
        state.measurements = error
          ? state.measurements
          : [...state.measurements, measurement]
        state.newMeasurementError = error
        state.isSaving = false
      })
      .addCase(sendUpdateMeasurement.pending, (state, action) => {
        state.updateMeasurementError = null
        state.updatedMeasurement = null
        state.isSaving = true
      })
      .addCase(sendUpdateMeasurement.fulfilled, (state, action) => {
        const { measurement, error } = action?.payload
        state.updatedMeasurement = error
          ? state.updatedMeasurement
          : measurement
        state.measurements = error
          ? state.measurements
          : state.measurements.map(m => {
              if (m.id === measurement.id) {
                return measurement
              }
              return m
            })
        state.updateMeasurementError = error
        state.isSaving = false
      })
  }
})

export const { cleanMeasurement, setMeasurement } =
  deviceManagementMeasurementSlice.actions

export default deviceManagementMeasurementSlice.reducer

import { useEffect, useReducer, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import DatePicker from 'react-datepicker'

import useDeviceSize from '@/hooks/useDeviceSize'

import { requestAllStorageFacilities } from '@/actions/operations/storageFacility'
import { requestAllProduces } from '@/actions/operations/produce'
import { requestAllVarieties } from '@/actions/operations/variety'
import { requestAllGrades } from '@/actions/operations/grade'

import {
  createWaste,
  requestWaste,
  updateWaste
} from '@/actions/operations/waste'

import {
  getOperationsAllStorageFacilities,
  getOperationsAllProduces,
  getOperationsAllVarieties,
  getOperationsWaste,
  getOperationsLoading,
  getOperationsError
} from '@/reducers/selectors'

import InputError from '@/elements/InputError'

import { Box, Button, Flex, Label, Text, Select, Loader } from '@/primitives'

import HeaderV2 from '@/components/Operations/Shared/HeaderV2'
import OrganizationInput from '../../../Shared/Organization/Input'
import Weights from '../../../Shared/Weights'

import { getValidationErrorMap } from '@/Util/GeneralUtils'

import { FIELDS, SCHEMA } from './config'

import history from '../../../../../history'
import Strings from '../Strings'

import { WASTE_REASONS } from '../utils'

import {
  reducer,
  initialState,
  SET_STATE,
  UPDATE_INPUT,
  REMOVE_WEIGHT,
  UPDATE_WEIGHT
} from './state'

function WasteForm({ state, modulePath }) {
  const dispatch = useDispatch()
  const isMobile = useDeviceSize('mobile')
  const { itemId } = useParams()

  const strings = Strings()

  const storageFacilities = getOperationsAllStorageFacilities()
  const produces = getOperationsAllProduces()
  const varieties = getOperationsAllVarieties()
  const waste = getOperationsWaste()
  const loading = getOperationsLoading()
  const error = getOperationsError()

  const [formState, dispatchFormState] = useReducer(reducer, initialState)

  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)

  const reasons = WASTE_REASONS(strings)

  useEffect(() => {
    dispatch(requestAllStorageFacilities())
    dispatch(requestAllProduces())
    dispatch(requestAllVarieties())
    dispatch(requestAllGrades())
  }, [dispatch])

  useEffect(() => {
    if (itemId && waste.id !== itemId) {
      dispatch(requestWaste({ wasteId: itemId }))
    }
  }, [itemId])

  useEffect(() => {
    if (waste?.id && waste.id === itemId) {
      dispatchFormState({
        type: SET_STATE,
        state: { ...waste }
      })
    }
  }, [waste])

  useEffect(() => {
    if (!itemId && state.storageFacilities.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'storageFacilityId',
        value: state.storageFacilities[0]
      })
    }
  }, [state])

  useEffect(() => {
    if (waiting && !loading && !error) {
      history.replace(modulePath)
    }
  }, [loading])

  const onSubmit = async e => {
    try {
      const payload = await SCHEMA().validate(formState, { abortEarly: false })
      setWaiting(true)

      if (itemId) {
        payload.wasteId = itemId
        dispatch(updateWaste(payload))
      } else {
        dispatch(createWaste(payload))
      }
    } catch (error) {
      setErrors(getValidationErrorMap(error))
    }
  }

  const handleInput = e => {
    const { name, value } = e.currentTarget
    dispatchFormState({ type: UPDATE_INPUT, name, value })
  }

  const handleDateInput = (date, name) => {
    dispatchFormState({ type: UPDATE_INPUT, name, value: date })
  }

  const handleOrganizationInput = organizationId => {
    const newWeights = formState.weights.map(weight => {
      return { ...weight, gradeId: '' }
    })
    dispatchFormState({
      type: SET_STATE,
      state: {
        ...formState,
        organizationId,
        produceId: '',
        varietyId: '',
        weights: newWeights
      }
    })
  }

  const updateWeight = weight => {
    dispatchFormState({
      type: UPDATE_WEIGHT,
      weight
    })
  }

  const removeWeight = weight => {
    dispatchFormState({
      type: REMOVE_WEIGHT,
      weight
    })
  }

  const filterProduces = () => {
    return produces.filter(p => p.organizationId === formState.organizationId)
  }

  const filterVarieties = () => {
    return varieties.filter(v => v.produce.id === formState.produceId)
  }

  const filterStorageFacilities = () => {
    return storageFacilities.filter(
      s => s.organizationId === formState.organizationId
    )
  }

  const getHeader = () => {
    if (itemId) {
      return strings.updateFormHeader
    }
    return strings.createFormHeader
  }

  return (
    <Flex
      as='form'
      axisGap={400}
      className='Operations__Form'
      direction='column'
    >
      <HeaderV2
        title={getHeader()}
        backPath={modulePath}
        buttonIcon={'save'}
        buttonText={strings.saveWaste}
        buttonCallback={onSubmit}
      />
      <Loader isLoading={loading}>
        <Flex axisGap={800} direction={'column'}>
          <Flex axisGap={400} direction={'column'}>
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.waste}
              </Text>
              <OrganizationInput
                fieldName={FIELDS.organizationId}
                organizationId={formState.organizationId}
                handleInput={handleOrganizationInput}
                errors={errors}
              />
              <Flex
                axisGap={300}
                className='Operations__Form__Fields'
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.wasteDate}
                    </Text>
                    <DatePicker
                      maxDate={new Date()}
                      selected={formState.wasteDate}
                      className='Operations__Form__Select'
                      onChange={date => handleDateInput(date, FIELDS.wasteDate)}
                    />
                    <InputError error={errors?.wasteDate} />
                  </Flex>
                </Label>
                <Label>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.storageFacility}
                    </Text>
                    <Select
                      className='Operations__Form__Select'
                      name={FIELDS.storageFacilityId}
                      value={formState.storageFacilityId}
                      onChange={handleInput}
                    >
                      <option default value=''>
                        {strings.selectDefault}
                      </option>
                      {filterStorageFacilities().map(({ id, name }) => (
                        <option value={id} key={id}>
                          {name}
                        </option>
                      ))}
                    </Select>
                    <InputError error={errors?.storageFacilityId} />
                  </Flex>
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                className='Operations__Form__Fields'
                alignMainAxis='flex-start'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flexGrow: 0 }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.reason}
                    </Text>
                    <Select
                      className='Operations__Select'
                      name={FIELDS.reason}
                      value={formState.reason}
                      onChange={handleInput}
                    >
                      <option default value=''>
                        {strings.selectDefault}
                      </option>
                      {Object.entries(reasons).map(([id, name]) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </Select>
                    <InputError error={errors?.reason} />
                  </Flex>
                </Label>
              </Flex>
            </Box>
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.produce}
              </Text>
              <Flex
                axisGap={300}
                className='Operations__Form__Fields'
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.produce}
                    </Text>
                    <Select
                      className='Operations__Form__Select'
                      name={FIELDS.produceId}
                      value={formState.produceId}
                      onChange={handleInput}
                    >
                      <option default value=''>
                        {strings.selectDefault}
                      </option>
                      {filterProduces().map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </Select>
                    <InputError error={errors?.produceId} />
                  </Flex>
                </Label>
                <Label>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.variety}
                    </Text>
                    <Select
                      className='Operations__Form__Select'
                      name={FIELDS.varietyId}
                      value={formState.varietyId}
                      onChange={handleInput}
                    >
                      <option default value=''>
                        {strings.selectDefault}
                      </option>
                      {filterVarieties().map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </Select>
                    <InputError error={errors?.varietyId} />
                  </Flex>
                </Label>
              </Flex>
            </Box>
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.weights}
              </Text>
              <Flex
                axisGap={300}
                direction='column'
                className='Operations__Form__Weights'
              >
                <Weights
                  organizationId={formState.organizationId}
                  updateWeight={updateWeight}
                  removeWeight={removeWeight}
                  weights={formState.weights}
                  errors={errors}
                />
                <InputError error={errors.weights} />
                <Flex alignMainAxis={'flex-end'}>
                  <Button
                    onClick={() => updateWeight()}
                    size='small'
                    name='add'
                    variant='primary'
                    iconBefore='add'
                    className='Operations__Form__Weights__Add'
                  >
                    {strings.addWeight}
                  </Button>
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Loader>
    </Flex>
  )
}

export default WasteForm

import { I18n } from 'aws-amplify'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { requestAllPackageUnits } from '@/actions/operations/packageUnit'

import { getOperationsAllPackageUnits } from '@/reducers/selectors'

import { Select } from '@/primitives'

const PackageUnitsFilter = ({
  state,
  dispatchState,
  stateKey = 'packageUnits',
  defaultLabel = I18n.get('All Package Units')
}) => {
  const dispatch = useDispatch()

  const packageUnits = getOperationsAllPackageUnits()

  const [packageUnitFilter, setPackageUnitFilter] = useState(
    state[stateKey].length === 1 ? state[stateKey][0] : 'all'
  )
  const [lastFilter, setLastFilter] = useState(packageUnitFilter)

  const getPackageUnitFilter = useCallback(() => {
    return packageUnitFilter === 'all'
      ? packageUnits.map(i => i.id)
      : [packageUnitFilter]
  }, [packageUnitFilter, packageUnits])

  const dispatchChange = useCallback(() => {
    dispatchState({
      [stateKey]: getPackageUnitFilter()
    })
  }, [dispatchState, stateKey, getPackageUnitFilter])

  useEffect(() => {
    dispatch(requestAllPackageUnits())
  }, [dispatch])

  useEffect(() => {
    if (
      packageUnitFilter === 'all' &&
      state[stateKey].length === 0 &&
      packageUnits.length > 0
    ) {
      dispatchChange()
    }
  }, [state, stateKey, packageUnits, packageUnitFilter, dispatchChange])

  useEffect(() => {
    if (packageUnits.length > 0) {
      if (packageUnitFilter !== lastFilter) {
        setLastFilter(packageUnitFilter)
        dispatchChange()
      }
    }
  }, [packageUnitFilter, packageUnits, lastFilter, dispatchChange])

  const onChangeFilter = e => {
    setPackageUnitFilter(e.currentTarget.value)
  }

  const filterPackageUnits = () => {
    if (state.organizations.length === 1) {
      return packageUnits.filter(facility => {
        for (let organization of state.organizations) {
          if (facility.organizationId === organization) {
            return true
          }
        }
        return false
      })
    }
    return []
  }

  const options = filterPackageUnits()

  return (
    <Select
      className='Operations__Header__Select'
      name={stateKey}
      value={packageUnitFilter}
      onChange={onChangeFilter}
      disabled={options.length === 0}
    >
      <option default value='all'>
        {defaultLabel}
      </option>
      {options.map(({ name, id }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </Select>
  )
}

export default PackageUnitsFilter

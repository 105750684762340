import { useReducer, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import useDeviceSize from '@/hooks/useDeviceSize'

import { clearInventories } from '@/actions/operations/inventory'

import { createInventoryCalibration } from '@/actions/operations/inventoryCalibration'

import {
  getOperationsInventory,
  getOperationsLoading,
  getOperationsError
} from '@/reducers/selectors'

import { Box, Flex, Label, Text, Input, Loader } from '@/primitives'

import InputError from '@/elements/InputError'

import { getValidationErrorMap } from '@/Util/GeneralUtils'

import history from '../../../../../history'
import Strings from '../Strings'

import { initialState, reducer, UPDATE_INPUT, SET_STATE } from './state'

import { FIELDS, SCHEMA } from './config'
import HeaderV2 from '@/components/Operations/Shared/HeaderV2'

function InventoryCalibrationForm({ state, modulePath, inventoryId }) {
  const dispatch = useDispatch()
  const isMobile = useDeviceSize('mobile')
  const isLaptop = useDeviceSize()

  const strings = Strings()

  const inventory = getOperationsInventory()
  const loading = getOperationsLoading()
  const error = getOperationsError()

  const [formState, dispatchFormState] = useReducer(reducer, initialState)

  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)

  useEffect(() => {
    if (!inventoryId) {
      history.push('/operations/inventory')
    }
  }, [inventoryId])

  useEffect(() => {
    if (inventory.id === inventoryId) {
      dispatchFormState({
        type: SET_STATE,
        state: {
          organizationId: inventory.organizationId,
          inventoryId: inventory.id
        }
      })
    }
  }, [inventory])

  useEffect(() => {
    if (waiting && !loading && !error) {
      history.replace(modulePath)
    }
  }, [loading])

  const onSubmit = async e => {
    try {
      const payload = await SCHEMA().validate(formState, { abortEarly: false })
      setWaiting(true)

      dispatch(createInventoryCalibration(payload))
      dispatch(clearInventories())
    } catch (error) {
      setErrors(getValidationErrorMap(error))
    }
  }

  const handleInput = e => {
    const { name, value } = e.currentTarget
    dispatchFormState({ type: UPDATE_INPUT, name, value })
  }

  const handleNumericInput = e => {
    const { name, value } = e.currentTarget
    const newValue = value.length > 0 ? Number(value) : ''
    dispatchFormState({ type: UPDATE_INPUT, name, value: newValue })
  }

  const getHeader = () => {
    return strings.createFormHeader
  }

  return (
    <Flex
      as='form'
      axisGap={400}
      direction='column'
      className='Operations__Form'
    >
      <HeaderV2
        title={getHeader()}
        backPath={modulePath}
        buttonIcon={'save'}
        buttonText={strings.saveInventoryCalibration}
        buttonCallback={onSubmit}
      />
      <Loader isLoading={loading}>
        <Flex axisGap={800} direction={isLaptop ? 'column' : null}>
          <Flex
            axisGap={400}
            direction='column'
            className='Operations__Form__Fields'
          >
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.inventoryCalibration}
              </Text>
              {inventory.id && (
                <Flex
                  axisGap={300}
                  alignMainAxis='space-evenly'
                  direction={isMobile ? 'column' : 'row'}
                >
                  <Label style={{ flex: '1 1 0' }}>
                    <Text variant='page' tone={700}>
                      {strings.inventory}
                    </Text>
                    <Text
                      variant='page'
                      tone={700}
                      className='Operations__Form__Fields__Text'
                    >
                      {inventory.storageFacility.name}&nbsp;-&nbsp;
                      {inventory.produce.name}&nbsp;-&nbsp;
                      {inventory.variety.name} ({inventory.grade.name})
                    </Text>
                  </Label>
                </Flex>
              )}
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.currentWeight}
                  </Text>
                  <Text
                    variant='page'
                    tone={700}
                    className='Operations__Form__Fields__Text'
                  >
                    {inventory.totalWeight}
                    {strings.kilograms}
                  </Text>
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.newWeight}
                  </Text>
                  <Box className='Operations__Input__Container'>
                    <Input
                      className='Operations__Input'
                      type='number'
                      name={FIELDS.newWeight}
                      value={formState.newWeight}
                      onChange={handleNumericInput}
                    />
                    <span className='Operations__Input__Suffix'>
                      {strings.kilograms}
                    </span>
                  </Box>
                  <InputError error={errors?.newWeight} />
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.reason}
                  </Text>
                  <Input
                    className='Operations__Input'
                    type='text'
                    name={FIELDS.reason}
                    value={formState.reason}
                    onChange={handleInput}
                  />
                  <InputError error={errors?.reason} />
                </Label>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Loader>
    </Flex>
  )
}

export default InventoryCalibrationForm

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import useDeviceSize from '@/hooks/useDeviceSize'

import { requestAllProduces } from '@/actions/operations/produce'
import { requestAllVarieties } from '@/actions/operations/variety'
import { requestAllGrades } from '@/actions/operations/grade'
import { requestAllPackageUnits } from '@/actions/operations/packageUnit'

import { Box, Text, Table, Slot, Row, Cell } from '@/primitives'

import ItemForm from './Form'
import Strings from './Strings'

function SaleShipmentItems({ sale, saleShipment, errors, dispatchFormState }) {
  const dispatch = useDispatch()
  const isMobile = useDeviceSize('mobile')

  const strings = Strings()

  useEffect(() => {
    dispatch(requestAllProduces())
    dispatch(requestAllVarieties())
    dispatch(requestAllGrades())
    dispatch(requestAllPackageUnits())
  }, [dispatch])

  if (!sale?.saleItems?.length || !saleShipment?.items?.length) {
    return (
      <Box>
        <Text className={'Operations__Form__Empty'}>{strings.noItems}</Text>
      </Box>
    )
  }

  return (
    <Table
      className='Operations__Form__Table__Form'
      aria-colcount='3'
      style={{ marginTop: '8px' }}
    >
      <Slot name='body'>
        {!isMobile && (
          <Row>
            <Cell>
              <Text variant='page' tone={700}>
                {strings.saleItem}
              </Text>
            </Cell>
            <Cell>
              <Text variant='page' tone={700}>
                {strings.quantity}
              </Text>
            </Cell>
            <Cell />
          </Row>
        )}
        {saleShipment.items.map((item, index) => (
          <ItemForm
            key={item.id}
            sale={sale}
            saleShipment={saleShipment}
            item={item}
            errors={errors}
            index={index}
            dispatchFormState={dispatchFormState}
          />
        ))}
      </Slot>
    </Table>
  )
}

export default SaleShipmentItems

import { I18n } from 'aws-amplify'
import { cloneDeep } from 'lodash'

import StringUtils from './StringUtils'
import ZoneUtils from './ZoneUtils'

function createBreadCrumbs(pathItems) {
  return pathItems.reduce(
    (a, v, i) => ({
      ...a,
      [StringUtils.capitalizeFirstLetter(siteCrumb(v) || 'Home')]: `/${pathItems
        .slice(1, i + 1)
        .join('/')}`
    }),
    {}
  )
}

function generateDashboardBreadcrumbs(
  organization,
  zone,
  sensorId,
  zonesHierarchy,
  basePath,
  isMobile,
  pathSuffix
) {
  const isDevice = sensorId !== null && sensorId !== undefined
  let device = null

  const devices = ZoneUtils.getZoneHierarchyValueByPath(
    zonesHierarchy,
    basePath,
    'devices'
  )

  if (sensorId) device = devices?.find(({ id }) => id === sensorId)

  const zoneDeviceObject = isDevice ? device : zone?.id ? zone : null

  const getOrganizationPath = () => {
    let path = `/zones/${organization.id}`
    if (pathSuffix) {
      path = `${path}${pathSuffix}`
    }
    return path
  }

  if (zoneDeviceObject) {
    const parentPathKey = !isDevice ? 'parentPath' : 'zonePath'
    const ids = zoneDeviceObject[parentPathKey].split('/')?.slice(1)
    if (isDevice) ids.push(zoneDeviceObject.id)

    const siteId = ids[0]

    const breadcrumbs = ids.map((id, index, { length }) => {
      const backupName = id === zoneDeviceObject.id ? zoneDeviceObject.name : id
      const nameIsDevice = isDevice && index === length - 1
      const name = !nameIsDevice
        ? ZoneUtils.getZoneName(
            zonesHierarchy,
            siteId,
            siteId === id ? null : id
          ) ?? backupName
        : zoneDeviceObject.name

      const parentPath = `/zones/${ids.slice(0, index + 1).join('/')}`

      return { id, name, parentPath, isDevice: nameIsDevice }
    })

    if (isMobile) {
      return [
        {
          id: 'home',
          name: I18n.get('Home'),
          parentPath: '/',
          isDevice: false,
          noSuffix: true
        }
      ].concat([breadcrumbs.at(-1)])
    }

    return [
      {
        id: 'home',
        name: I18n.get('Home'),
        parentPath: '/',
        isDevice: false,
        noSuffix: true
      },
      {
        id: 'organization',
        name: organization?.name,
        parentPath: getOrganizationPath(),
        isDevice: false,
        noSuffix: true
      }
    ].concat(...breadcrumbs)
  } else if (organization) {
    return [
      {
        id: 'home',
        name: I18n.get('Home'),
        parentPath: '/',
        isDevice: false,
        noSuffix: true
      },
      {
        id: 'organization',
        name: organization?.name,
        parentPath: getOrganizationPath(),
        isDevice: false,
        noSuffix: true
      }
    ]
  }

  return []
}

function generateAdminBreadcrumbs(zone, hierarchy, isMobile = false) {
  let ids = zone?.parentPath.split('/').slice(1)

  const traverseHierarchy = (node, ids, breadcrumbs = []) => {
    if (ids?.length === 0) {
      return breadcrumbs
    }

    const currentId = ids?.shift()
    const currentZone = node?.children?.[currentId] || node?.[currentId]

    if (currentZone) {
      breadcrumbs.push({
        id: currentZone.id,
        name: currentZone.name,
        parentPath: '/admin/zones' + currentZone?.parentPath
      })

      return traverseHierarchy(currentZone, ids, breadcrumbs)
    }

    return breadcrumbs
  }

  let initialBreadcrumbs = !isMobile
    ? [{ id: 'sites', name: 'Sites', parentPath: '/admin/zones' }]
    : []

  if (!ids || ids.length === 0 || !hierarchy) {
    return initialBreadcrumbs
  }

  const breadcrumbs = traverseHierarchy(
    cloneDeep(hierarchy),
    ids,
    initialBreadcrumbs
  )

  return breadcrumbs
}

function siteCrumb(crumb) {
  if (crumb === 'zones') return 'sites'
  return crumb
}

const BreadcrumbUtils = {
  createBreadCrumbs,
  generateDashboardBreadcrumbs,
  generateAdminBreadcrumbs
}

export default BreadcrumbUtils

import { useReducer } from 'react'
import { I18n } from 'aws-amplify'

import Container from './Container'

import { Flex, Input, Label, Text, Button, Loader } from '@/primitives'

import { useAuth } from '@/contexts/auth-context'

import './SignUp.scss'

const initialState = {
  code: '',
  loading: false,
  errors: {}
}

const reducer = (state, action) => {
  if (action.type === 'update') {
    return { ...state, [action.name]: action.value }
  }
  return state
}

const ConfirmSignUp = ({ hubData }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const auth = useAuth()

  function onChangeInput(e) {
    const { name, value } = e?.target
    dispatch({ type: 'update', name, value })
  }

  const onConfirmSignUp = async e => {
    e.preventDefault()
    try {
      setLoading(true)
      setErrors()

      await auth.confirmSignUp({ ...hubData.details, code: state.code })
    } catch (err) {
      let errors = {}

      if (err?.inner) {
        err.inner.forEach(innerErr => {
          errors[innerErr.path] = innerErr.message
        })
        setErrors(errors)
      }

      setLoading(false)
    }
  }

  const setErrors = (errors = {}) => {
    dispatch({ type: 'update', name: 'errors', value: errors })
  }

  const setLoading = loading => {
    dispatch({ type: 'update', name: 'loading', value: loading })
  }

  return (
    <Loader isLoading={state.loading}>
      <Container title={I18n.get('Check your mailbox')} progressStep={2}>
        <Flex direction='column'>
          <Text
            variant='page'
            tone={800}
            size={100}
            style={{ marginBottom: '2rem' }}
          >
            {I18n.get(
              'A verification code was sent to the email address you provided. Enter the code into the field below to activate your account. Do not leave this page until you have completed this step.'
            )}
          </Text>
          <Label className='SignUp__Form__Label'>
            {I18n.get('Verification code')}
          </Label>
          <Input
            className='SignUp__Form__Input'
            name='code'
            value={state.code}
            onChange={onChangeInput}
          />
          <Button
            variant='primary'
            className='SignUp__Form__SignUpButton'
            onClick={onConfirmSignUp}
          >
            <Text textTransform='capitalize'>{I18n.get('Continue')}</Text>
          </Button>
        </Flex>
      </Container>
    </Loader>
  )
}

export default ConfirmSignUp

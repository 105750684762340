import { I18n } from 'aws-amplify'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { showBanner } from '@/slices/util'
import { Dialog, LineSeparator } from '@/elements'
import { Button, FlexV2, Input, Label, Loader, Slot, Text } from '@/primitives'

import { createExportChart, validateDimensions, formatDatePng } from './utils'

export default function ExportModal({ showModal, setShowModal, exportData }) {
  const dispatch = useDispatch()
  const [isExporting, setIsExporting] = useState(false)
  const [dimensions, setDimensions] = useState({
    width: 1280,
    height: 720
  })
  const [errors, setErrors] = useState({
    width: null,
    height: null
  })

  const onClickExport = async () => {
    const { nivoProps, measurement, toDate, fromDate, timeZone } = exportData
    setIsExporting(true)

    try {
      await validateDimensions.validate(dimensions, { abortEarly: false })
      const result = await createExportChart({
        width: parseInt(dimensions.width),
        height: parseInt(dimensions.height),
        nivoProps,
        measurement
      })

      if (result) {
        const element = document.createElement('a')
        element.href = result
        const fromFormatedDate = formatDatePng(fromDate, timeZone)
        const toFormatedDate = formatDatePng(toDate, timeZone)
        element.download = `${measurement?.id}-${fromFormatedDate}-${toFormatedDate}.png`
        document.body.appendChild(element)
        element.click()
      } else {
        dispatch(
          showBanner({
            type: 'error',
            show: true,
            message: I18n.get('Something went wrong. Please try again.')
          })
        )
      }
      setErrors({ width: null, height: null })
      setShowModal(false)
    } catch (error) {
      const newErrors = error.inner.reduce((acc, curr) => {
        acc[curr.path] = curr.message
        return acc
      }, {})
      setErrors(newErrors)
    } finally {
      setIsExporting(false)
    }
  }

  const onClickClose = () => {
    setShowModal(false)
  }

  const onDimensionChange = e => {
    const { name, value } = e.target
    const aspectRatio = 1920 / 1080

    setErrors({ width: null, height: null })

    setDimensions(prev => {
      let newDimensions = { ...prev, [name]: value }

      if (name === 'width') {
        let newHeight = Math.round(value / aspectRatio)
        newDimensions = {
          ...newDimensions,
          height: newHeight > 1080 ? 1080 : newHeight
        }
      } else if (name === 'height') {
        let newWidth = Math.round(value * aspectRatio)

        newDimensions = {
          ...newDimensions,
          width: newWidth > 1920 ? 1920 : newWidth
        }
      }

      return newDimensions
    })
  }

  return (
    <Dialog open={showModal} onOpenChange={onClickClose} type='offcanvas'>
      <Slot name='title'>
        <FlexV2 direction='column'>
          <Text as='h5' style={{ marginBottom: '0.5em' }}>
            {I18n.get('Export Chart')}
          </Text>
          <Text variant='page' tone={800}>
            {I18n.get('Customize your graph export dimensions')}
          </Text>
        </FlexV2>
      </Slot>
      <Slot name='content'>
        <Loader
          isLoading={isExporting}
          variant='page'
          text={I18n.get(
            'Exporting chart... Do not close or refresh the page.'
          )}
        >
          <LineSeparator />
          <Label style={{ marginTop: '2rem' }}>
            <Text tone='700' variant='page'>
              {I18n.get('Width')}
            </Text>
            <Input
              placeholder={I18n.get('Width')}
              name='width'
              type='number'
              value={dimensions.width}
              onChange={onDimensionChange}
            />
            <Text variant='error' tone={500}>
              {errors.width}
            </Text>
          </Label>
          <Label>
            <Text tone='700' variant='page'>
              {I18n.get('Height')}
            </Text>
            <Input
              placeholder={I18n.get('Height')}
              name='height'
              type='number'
              value={dimensions.height}
              onChange={onDimensionChange}
            />
            <Text variant='error' tone={500}>
              {errors.height}
            </Text>
          </Label>
        </Loader>
      </Slot>
      <Slot name='actions'>
        <FlexV2 alignMainAxis='space-between'>
          <Button onClick={onClickClose} disabled={isExporting}>
            {I18n.get('Cancel')}
          </Button>
          <Button
            onClick={onClickExport}
            variant='primary'
            disabled={isExporting || errors.width || errors.height}
          >
            {I18n.get('Export')}
          </Button>
        </FlexV2>
      </Slot>
    </Dialog>
  )
}

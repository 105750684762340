import { createElement } from 'react'
import Box from '@/primitives/Box'

export const mapZonesToSelectOption = (zones, defaultLabel = null) => {
  const values = Object.values(zones)
  let options = values.map(zone => ({ value: zone.id, label: zone.name }))
  if (defaultLabel) {
    options = [{ value: '', label: defaultLabel }, ...options]
  }
  return options
}

export const mapSensorsToSelectOption = sensors => {
  const values = Object.values(sensors)
  let options = values.map(sensor => ({
    value: sensor.id,
    label: sensor.name,
    sensorType: sensor.sensorType,
    tag: sensor.tag
  }))
  return options
}

export const getSites = (zoneFilter, organizationZonesHierarchy) => {
  const parentsExist = organizationZonesHierarchy?.[zoneFilter.organizationId]

  return zoneFilter?.organizationId && parentsExist
    ? mapZonesToSelectOption(
        organizationZonesHierarchy[zoneFilter?.organizationId] || {}
      )
    : []
}

export const getFacilities = (zoneFilter, organizationZonesHierarchy) => {
  const parentsExist =
    organizationZonesHierarchy?.[zoneFilter.organizationId]?.[zoneFilter.siteId]

  return zoneFilter.siteId && parentsExist
    ? mapZonesToSelectOption(
        organizationZonesHierarchy[zoneFilter.organizationId][zoneFilter.siteId]
          .children
      )
    : []
}

export const getRooms = (zoneFilter, organizationZonesHierarchy) => {
  const parentsExist =
    organizationZonesHierarchy?.[zoneFilter.organizationId]?.[zoneFilter.siteId]
      ?.children[zoneFilter.facilityId]

  return zoneFilter.facilityId && parentsExist
    ? mapZonesToSelectOption(
        organizationZonesHierarchy[zoneFilter.organizationId][zoneFilter.siteId]
          .children[zoneFilter.facilityId].children
      )
    : []
}

export const getZones = (zoneFilter, organizationZonesHierarchy) => {
  const parentsExist =
    organizationZonesHierarchy?.[zoneFilter.organizationId]?.[zoneFilter.siteId]
      ?.children[zoneFilter.facilityId]?.children[zoneFilter.roomId]

  return zoneFilter.roomId && parentsExist
    ? mapZonesToSelectOption(
        organizationZonesHierarchy[zoneFilter.organizationId][zoneFilter.siteId]
          .children[zoneFilter.facilityId].children[zoneFilter.roomId].children
      )
    : []
}

export const getSubzones = (zoneFilter, organizationZonesHierarchy) => {
  const parentsExist =
    organizationZonesHierarchy?.[zoneFilter.organizationId]?.[zoneFilter.siteId]
      ?.children[zoneFilter.facilityId]?.children[zoneFilter.roomId]?.children[
      zoneFilter.zoneId
    ]

  return zoneFilter.zoneId && parentsExist
    ? mapZonesToSelectOption(
        organizationZonesHierarchy[zoneFilter.organizationId][zoneFilter.siteId]
          .children[zoneFilter.facilityId].children[zoneFilter.roomId].children[
          zoneFilter.zoneId
        ].children
      )
    : []
}

export const getSensors = (zoneFilter, organizationZonesHierarchy) => {
  if (zoneFilter.subzoneId) {
    const parentExist =
      organizationZonesHierarchy?.[zoneFilter.organizationId]?.[
        zoneFilter.siteId
      ]?.children[zoneFilter.facilityId]?.children[zoneFilter.roomId]?.children[
        zoneFilter.zoneId
      ]?.children?.[zoneFilter.subzoneId]

    return parentExist
      ? mapSensorsToSelectOption(
          organizationZonesHierarchy[zoneFilter.organizationId][
            zoneFilter.siteId
          ].children[zoneFilter.facilityId].children[zoneFilter.roomId]
            .children[zoneFilter.zoneId].children[zoneFilter.subzoneId].devices
        )
      : []
  }

  if (zoneFilter.zoneId) {
    const parentExist =
      organizationZonesHierarchy?.[zoneFilter.organizationId]?.[
        zoneFilter.siteId
      ]?.children[zoneFilter.facilityId]?.children[zoneFilter.roomId]?.children[
        zoneFilter.zoneId
      ]

    return parentExist
      ? mapSensorsToSelectOption(
          organizationZonesHierarchy[zoneFilter.organizationId][
            zoneFilter.siteId
          ].children[zoneFilter.facilityId].children[zoneFilter.roomId]
            .children[zoneFilter.zoneId].devices
        )
      : []
  }

  if (zoneFilter.roomId) {
    const parentExist =
      organizationZonesHierarchy?.[zoneFilter.organizationId]?.[
        zoneFilter.siteId
      ]?.children[zoneFilter.facilityId]?.children[zoneFilter.roomId]

    return parentExist
      ? mapSensorsToSelectOption(
          organizationZonesHierarchy[zoneFilter.organizationId][
            zoneFilter.siteId
          ].children[zoneFilter.facilityId].children[zoneFilter.roomId].devices
        )
      : []
  }

  if (zoneFilter.facilityId) {
    const parentExist =
      organizationZonesHierarchy?.[zoneFilter.organizationId]?.[
        zoneFilter.siteId
      ]?.children[zoneFilter.facilityId]

    return parentExist
      ? mapSensorsToSelectOption(
          organizationZonesHierarchy[zoneFilter.organizationId][
            zoneFilter.siteId
          ].children[zoneFilter.facilityId].devices
        )
      : []
  }

  if (zoneFilter.siteId) {
    const parentExist =
      organizationZonesHierarchy?.[zoneFilter.organizationId]?.[
        zoneFilter.siteId
      ]
    return parentExist
      ? mapSensorsToSelectOption(
          organizationZonesHierarchy[zoneFilter.organizationId][
            zoneFilter.siteId
          ].devices
        )
      : []
  }

  return []
}

export const getCombinedActiveColumns = (activeColumns, returnSet = false) => {
  const predefinedOrder = [
    'siteId',
    'facilityId',
    'roomId',
    'zoneId',
    'subzoneId',
    'sensorId'
  ]

  const combinedActiveColumns = Object.values(activeColumns).reduce(
    (acc, rowSet) => new Set([...acc, ...rowSet]),
    new Set()
  )

  if (returnSet) {
    return combinedActiveColumns
  }

  return Array.from(combinedActiveColumns).sort(
    (a, b) => predefinedOrder.indexOf(a) - predefinedOrder.indexOf(b)
  )
}

export const getActiveColumns = ({
  siteOptions,
  facilityOptions,
  roomOptions,
  zoneOptions,
  subzoneOptions,
  sensorOptions
}) => {
  const activeColumns = new Set()
  if (siteOptions.length > 0) {
    activeColumns.add('siteId')
  }

  if (facilityOptions.length > 0) {
    activeColumns.add('facilityId')
  }

  if (roomOptions.length > 0) {
    activeColumns.add('roomId')
  }

  if (zoneOptions.length > 0) {
    activeColumns.add('zoneId')
  }

  if (subzoneOptions.length > 0) {
    activeColumns.add('subzoneId')
  }

  if (sensorOptions.length > 0) {
    activeColumns.add('sensorId')
  }

  return activeColumns
}

export const createEmptySpaces = count => {
  return Array.from({ length: count }, (_, i) => {
    return createElement(Box, {
      key: 'empty_box_' + i,
      className: 'DataboardFilters__ComparisonRow__EmptySpace'
    })
  })
}

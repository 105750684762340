import { parseValue } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/utils'

import Value from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/Chart/Value'
import useAverageData from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Hooks/useAverageData'
import { getRanges } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'
import {
  convertToSalinity,
  salinityMeasurements
} from '@/components/DashboardPageV2/Desktop/Widgets/Fertigation/Salinity/utils'

export default function AverageValue({ unit, options, config }) {
  const data = useAverageData({
    measurements: salinityMeasurements,
    range: config.range
  })

  const parseOptions = (water_temperature, conductivity) => ({
    fixedFactor: options.fixedFactor,
    scaleFactor: options.scaleFactor,
    value: convertToSalinity({ water_temperature, conductivity })
  })

  return (
    <Value
      data={{
        value: parseValue(
          parseOptions(
            data?.water_temperature?.value,
            data?.conductivity?.value
          )
        ),
        min: parseValue(
          parseOptions(data?.water_temperature?.min, data?.conductivity?.min)
        ),
        max: parseValue(
          parseOptions(data?.water_temperature?.max, data?.conductivity?.max)
        ),
        unit,
        status: data?.water_temperature?.status || data?.conductivity?.status,
        subtitle: getRanges(config.range)?.label
      }}
    />
  )
}

import { Fragment, useEffect, useState, useReducer } from 'react'
import { I18n } from 'aws-amplify'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  Text,
  Slot,
  Form,
  Button,
  Flex,
  Grid,
  Item,
  Loader,
  Box,
  Input
} from '@/primitives'

import { Dialog, Select } from '@/elements'

import Strings from '../Strings'

import { showBanner } from '@/slices/util'

import {
  getUsagePlan,
  getOrganizations,
  getUsagePlanSliceLoading
} from '@/reducers/selectors'

import {
  cleanUsagePlan,
  fetchUsagePlan,
  sendCreateUsagePlan,
  sendUpdateUsagePlan
} from '@/slices/timestream/usagePlan'

import { updateUsagePlanSchema } from './utils'

function reducer(state, action) {
  if (action.type === 'init') {
    return { ...action.value }
  }

  if (action.type === 'update') {
    const [section, field] = action.field.split('/')
    const newSection = { ...state[section] }
    newSection[field] = action.value
    return {
      ...state,
      [section]: newSection
    }
  }
}

const periodValues = ['DAY', 'WEEK', 'MONTH']
const periodSelectOptions = periodValues.map(value => ({
  value,
  label: I18n.get(value)
}))

const UsagePlanModal = () => {
  const reduxDispatch = useDispatch()
  const history = useHistory()

  const usagePlan = getUsagePlan()
  const organizations = getOrganizations()
  const isLoading = getUsagePlanSliceLoading()

  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [state, dispatch] = useReducer(reducer, usagePlan)
  const strings = Strings()

  const pathItems = history.location.pathname.split('/')
  const index = pathItems.length - 2
  const organizationId = pathItems[index]

  const hasUsagePlan = usagePlan?.id

  useEffect(() => {
    if (!usagePlan?.hasOwnProperty('id') && !isLoading) {
      // Add !isLoading condition
      reduxDispatch(fetchUsagePlan({ organizationId }))
    }

    return () => {
      reduxDispatch(showBanner({ show: false, message: '', type: null }))
      if (!isLoading) {
        reduxDispatch(cleanUsagePlan())
      }
    }
  }, [organizationId, reduxDispatch, usagePlan, isLoading])

  useEffect(() => {
    if (!usagePlan?.hasOwnProperty('id') && showConfirmModal === true) {
      setShowConfirmModal(false)
    }
  }, [showConfirmModal, usagePlan])

  useEffect(() => {
    if (usagePlan?.hasOwnProperty('id')) {
      dispatch({ type: 'init', value: usagePlan })
    }
  }, [usagePlan])

  const onClickClose = () => {
    history.push('/admin/organizations')
  }

  const onSubmitForm = e => {
    e.preventDefault()
  }

  const toggleConfirmModal = () => {
    setShowConfirmModal(!showConfirmModal)
  }

  const getOrganizationName = organizationId => {
    for (let organization of organizations?.result) {
      if (organization.id === organizationId) {
        return organization.name
      }
    }
    return null
  }

  const clickCreateUsagePlan = () => {
    if (!usagePlan?.id) {
      reduxDispatch(sendCreateUsagePlan({ organizationId }))
    }
  }

  const onClickEdit = () => {
    setIsEditing(true)
  }

  const onChangeInput = e => {
    const { value, name } = e.target
    const valueToUse = value ? parseInt(value) : ''
    if (!isNaN(valueToUse)) {
      dispatch({ type: 'update', field: name, value: valueToUse })
    }
  }

  const onSelectPeriod = ({ value }) => {
    dispatch({ type: 'update', field: 'quota/period', value })
  }

  const onClickSaveChanges = async () => {
    try {
      const { name, throttle, quota } = state
      await updateUsagePlanSchema.validate(
        { throttle, quota },
        { abortEarly: true, context: { currentUsagePlan: usagePlan } }
      )

      reduxDispatch(
        sendUpdateUsagePlan({ organizationId: name, throttle, quota })
      )
    } catch (err) {
      reduxDispatch(
        showBanner({ show: true, message: err.message, type: 'error' })
      )
    }
  }

  return (
    <Fragment>
      <Dialog
        open={showConfirmModal}
        onOpenChange={toggleConfirmModal}
        style={{ zIndex: '4' }}
      >
        <Slot name='title'>
          <Text as='h5' style={{ marginBottom: '0.5em' }}>
            {strings.confirmAddUsagePlanHeader}
          </Text>
          <Text as='p' size={100} style={{ marginBottom: '1.5em' }}>
            {organizations?.result?.length > 0 &&
              getOrganizationName(organizationId)}
          </Text>
        </Slot>
        <Slot name='content'>
          <Text>{strings.confirmAddUsagePlan}</Text>
        </Slot>
        <Slot name='actions'>
          <Flex alignMainAxis='space-between'>
            <Button variant='neutral' onClick={toggleConfirmModal}>
              {strings.cancel}
            </Button>
            <Button
              variant='primary'
              style={{ marginInlineStart: '1rem' }}
              onClick={clickCreateUsagePlan}
            >
              {strings.confirmButton}
            </Button>
          </Flex>
        </Slot>
      </Dialog>
      <Dialog open={true} onOpenChange={onClickClose}>
        <Slot name='title'>
          <Flex direction='column' style={{ marginBottom: '1em' }}>
            <Text as='h5' style={{ marginBottom: '0.5em' }}>
              {strings.usagePlanHeader}
            </Text>
            <Text as='p' size={100}>
              {organizations?.result?.length > 0 &&
                getOrganizationName(organizationId)}
            </Text>
          </Flex>
        </Slot>
        <Slot name='content'>
          <Loader isLoading={isLoading}>
            {!isLoading && usagePlan?.hasOwnProperty('id') && (
              <Fragment>
                {!hasUsagePlan && (
                  <Box style={{ height: '2rem' }}>
                    <Text>{strings.noUsagePlan}</Text>
                  </Box>
                )}
                {hasUsagePlan && (
                  <Grid gap={400} templateColumns='1fr 2fr'>
                    <Item>
                      <Text variant='page' tone={800}>
                        {strings.id}
                      </Text>
                    </Item>
                    <Item>
                      <Text>{usagePlan?.id}</Text>
                    </Item>
                    <Item>
                      <Text variant='page' tone={800}>
                        {strings.name}
                      </Text>
                    </Item>
                    <Item>
                      <Text>{usagePlan?.name}</Text>
                    </Item>
                    <Item>
                      <Text variant='page' tone={800}>
                        {strings.quota}
                      </Text>
                    </Item>
                    <Item>
                      {state?.quota && hasUsagePlan && (
                        <Flex>
                          {Object.keys(state?.quota)?.map(key => {
                            return (
                              <Box
                                style={{ width: '95%', marginBottom: '1rem' }}
                                key={key}
                              >
                                <Box key={key}>
                                  <Text variant='page' tone={800}>
                                    {strings[key]}
                                  </Text>
                                </Box>
                                <Box>
                                  {key === 'period' && (
                                    <Select
                                      name='period'
                                      value={state?.quota?.period}
                                      onChange={onSelectPeriod}
                                      options={periodSelectOptions}
                                      isDisabled={!isEditing}
                                    />
                                  )}
                                  {key !== 'period' && (
                                    <Input
                                      pattern='[0-9]*'
                                      name={`quota/${key}`}
                                      value={state.quota[key].toString()}
                                      disabled={!isEditing}
                                      onChange={onChangeInput}
                                    />
                                  )}
                                </Box>
                              </Box>
                            )
                          })}
                        </Flex>
                      )}
                    </Item>
                    <Item>
                      <Text variant='page' tone={800}>
                        {strings.throttle}
                      </Text>
                    </Item>
                    <Item>
                      {state?.throttle && hasUsagePlan && (
                        <Flex>
                          {Object.keys(state?.throttle)?.map(key => {
                            return (
                              <Box
                                style={{ width: '95%', marginBottom: '1rem' }}
                                key={key}
                              >
                                <Box key={key}>
                                  <Text variant='page' tone={800}>
                                    {strings[key]}
                                  </Text>
                                </Box>
                                <Box>
                                  <Input
                                    name={`throttle/${key}`}
                                    value={state.throttle[key]}
                                    disabled={!isEditing}
                                    onChange={onChangeInput}
                                  />
                                </Box>
                              </Box>
                            )
                          })}
                        </Flex>
                      )}
                    </Item>
                  </Grid>
                )}
              </Fragment>
            )}
          </Loader>
        </Slot>
        <Slot name='actions'>
          <Form onSubmit={onSubmitForm}>
            <Flex alignMainAxis='space-between'>
              <Button variant='neutral' onClick={onClickClose}>
                {strings.cancel}
              </Button>
              {hasUsagePlan && !isLoading && (
                <Button
                  variant='primary'
                  style={{ marginInlineStart: '1rem' }}
                  onClick={isEditing ? onClickSaveChanges : onClickEdit}
                >
                  {isEditing ? strings.saveChanges : strings.editUsagePlan}
                </Button>
              )}
              {!hasUsagePlan && !isLoading && (
                <Button
                  variant='primary'
                  style={{ marginInlineStart: '1rem' }}
                  onClick={toggleConfirmModal}
                >
                  {strings.createUsagePlan}
                </Button>
              )}
            </Flex>
          </Form>
        </Slot>
      </Dialog>
    </Fragment>
  )
}

export default UsagePlanModal

import SectionHeaderWrapper from '../Utils/SectionHeaderWrapper'
import Strings from '../../Strings'

import { Button, FlexV2, Text } from '@/primitives'

import { getControlDeviceConfigs } from '@/reducers/selectors'

const Header = ({ selectedZone, setShowConfigForm }) => {
  const strings = Strings()
  const { controlHeading, controlSubheading, addControlConfig } = strings

  const configs = getControlDeviceConfigs()

  function onClickAddControlConfig(e) {
    e.preventDefault()
    setShowConfigForm(true)
  }

  return (
    <SectionHeaderWrapper>
      <FlexV2 direction='column' axisGap={100}>
        <Text size={300} fontWeight={700}>
          {controlHeading}
        </Text>
        <Text fontFamily='mono' variant='page' tone={900} size={200}>
          {`${controlSubheading} ${selectedZone?.name}.`}
        </Text>
      </FlexV2>

      {configs.length < 2 && (
        <Button
          variant='primary'
          size='small'
          onClick={onClickAddControlConfig}
        >
          {addControlConfig}
        </Button>
      )}
    </SectionHeaderWrapper>
  )
}

export default Header

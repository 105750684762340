import { string, object, number } from 'yup'

import Strings from '../Strings'

const strings = Strings()

const {
  burstLimit,
  rateLimit,
  limit,
  offset,
  period,
  isRequired,
  mustBeNumber,
  noValuesHaveChanged
} = strings

const updateThrottleSchema = object().shape({
  burstLimit: number()
    .typeError(`${burstLimit} ${mustBeNumber}`)
    .required(`${burstLimit} ${isRequired}`),
  rateLimit: number()
    .typeError(`${rateLimit} ${mustBeNumber}`)
    .required(`${rateLimit} ${isRequired}`)
})

const updateQuotaSchema = object().shape({
  limit: number()
    .typeError(`${limit} ${mustBeNumber}`)
    .required(`${limit} ${isRequired}`),
  offset: number()
    .typeError(`${offset} ${mustBeNumber}`)
    .required(`${offset} ${isRequired}`),
  period: string()
    .oneOf(['DAY', 'WEEK', 'MONTH'])
    .required(`${period} ${isRequired}`)
})

export const updateUsagePlanSchema = object()
  .shape({
    throttle: updateThrottleSchema,
    quota: updateQuotaSchema
  })
  .test({
    name: 'fieldsHaveChanged',
    message: noValuesHaveChanged,
    test: (value, { options }) => {
      const { currentUsagePlan } = options.context

      const quotaHasChanges = Object.keys(currentUsagePlan.quota).some(key => {
        return value['quota'][key] !== currentUsagePlan['quota'][key]
      })

      const throttleHasChanges = Object.keys(currentUsagePlan.throttle).some(
        key => {
          return value['throttle'][key] !== currentUsagePlan['throttle'][key]
        }
      )

      return quotaHasChanges || throttleHasChanges
    }
  })

import { makeOperationsApiCall } from './utils'

export const getCrops = async params => {
  try {
    const query = /* GraphQL */ `
      query getCrops(
        $filter: GetCropsFilter
        $order: OrderEnum
        $offset: Int
        $limit: Int
      ) {
        getCrops(
          filter: $filter
          order: $order
          offset: $offset
          limit: $limit
        ) {
          crops {
            id
            organizationId
            siteId
            zoneId
            produce {
              id
              name
            }
            variety {
              id
              name
            }
            status
            plantingArea
            growthMedium
            hydrationMethod
            roofCover
            secondskyVariant
            blockingRatio
            netType
            secondskyInformation
            seedQuantity
            estimatedSowDate
            sowDate
            estimatedTransplantDate
            transplantDate
            estimatedHarvestStartDate
            harvestStartDate
            estimatedHarvestEndDate
            harvestEndDate
            produceThresholdId
            produceThreshold {
              id
              name
              measurements
            }
            createdAt
            updatedAt
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getCrops',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getCrop = async params => {
  try {
    const query = /* GraphQL */ `
      query getCrop($cropId: String!) {
        getCrop(cropId: $cropId) {
          id
          organizationId
          siteId
          zoneId
          produceId
          varietyId
          status
          plantingArea
          growthMedium
          hydrationMethod
          roofCover
          secondskyVariant
          blockingRatio
          netType
          secondskyInformation
          seedQuantity
          estimatedSowDate
          sowDate
          estimatedTransplantDate
          transplantDate
          estimatedHarvestStartDate
          harvestStartDate
          estimatedHarvestEndDate
          harvestEndDate
          produceThresholdId
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getCrop',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const createCrop = async params => {
  try {
    const query = /* GraphQL */ `
      mutation createCrop(
        $organizationId: String!
        $siteId: String!
        $zoneId: String!
        $produceId: String!
        $varietyId: String!
        $status: CropStatus!
        $plantingArea: Int
        $growthMedium: [CropGrowthMedium]
        $hydrationMethod: CropHydrationMethod
        $roofCover: CropRoofCover
        $secondskyVariant: CropSecondskyVariant
        $blockingRatio: CropBlockingRatio
        $netType: CropNetType
        $secondskyInformation: String
        $seedQuantity: Int
        $estimatedSowDate: GraphQLTimestamp
        $sowDate: GraphQLTimestamp
        $estimatedTransplantDate: GraphQLTimestamp
        $transplantDate: GraphQLTimestamp
        $estimatedHarvestStartDate: GraphQLTimestamp
        $harvestStartDate: GraphQLTimestamp
        $estimatedHarvestEndDate: GraphQLTimestamp
        $harvestEndDate: GraphQLTimestamp
        $produceThresholdId: String
      ) {
        createCrop(
          organizationId: $organizationId
          siteId: $siteId
          zoneId: $zoneId
          produceId: $produceId
          varietyId: $varietyId
          status: $status
          plantingArea: $plantingArea
          growthMedium: $growthMedium
          hydrationMethod: $hydrationMethod
          roofCover: $roofCover
          secondskyVariant: $secondskyVariant
          blockingRatio: $blockingRatio
          netType: $netType
          secondskyInformation: $secondskyInformation
          seedQuantity: $seedQuantity
          estimatedSowDate: $estimatedSowDate
          sowDate: $sowDate
          estimatedTransplantDate: $estimatedTransplantDate
          transplantDate: $transplantDate
          estimatedHarvestStartDate: $estimatedHarvestStartDate
          harvestStartDate: $harvestStartDate
          estimatedHarvestEndDate: $estimatedHarvestEndDate
          harvestEndDate: $harvestEndDate
          produceThresholdId: $produceThresholdId
        ) {
          id
          organizationId
          siteId
          zoneId
          produce {
            id
            name
          }
          variety {
            id
            name
          }
          status
          plantingArea
          growthMedium
          hydrationMethod
          roofCover
          secondskyVariant
          blockingRatio
          netType
          secondskyInformation
          seedQuantity
          estimatedSowDate
          sowDate
          estimatedTransplantDate
          transplantDate
          estimatedHarvestStartDate
          harvestStartDate
          estimatedHarvestEndDate
          harvestEndDate
          produceThresholdId
          createdAt
          updatedAt
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'createCrop',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updateCrop = async params => {
  try {
    const query = /* GraphQL */ `
      mutation updateCrop(
        $cropId: String!
        $organizationId: String!
        $siteId: String!
        $zoneId: String!
        $produceId: String!
        $varietyId: String!
        $status: CropStatus!
        $plantingArea: Int
        $growthMedium: [CropGrowthMedium]
        $hydrationMethod: CropHydrationMethod
        $roofCover: CropRoofCover
        $secondskyVariant: CropSecondskyVariant
        $blockingRatio: CropBlockingRatio
        $netType: CropNetType
        $secondskyInformation: String
        $seedQuantity: Int
        $estimatedSowDate: GraphQLTimestamp
        $sowDate: GraphQLTimestamp
        $estimatedTransplantDate: GraphQLTimestamp
        $transplantDate: GraphQLTimestamp
        $estimatedHarvestStartDate: GraphQLTimestamp
        $harvestStartDate: GraphQLTimestamp
        $estimatedHarvestEndDate: GraphQLTimestamp
        $harvestEndDate: GraphQLTimestamp
        $produceThresholdId: String
      ) {
        updateCrop(
          cropId: $cropId
          organizationId: $organizationId
          siteId: $siteId
          zoneId: $zoneId
          produceId: $produceId
          varietyId: $varietyId
          status: $status
          plantingArea: $plantingArea
          growthMedium: $growthMedium
          hydrationMethod: $hydrationMethod
          roofCover: $roofCover
          secondskyVariant: $secondskyVariant
          blockingRatio: $blockingRatio
          netType: $netType
          secondskyInformation: $secondskyInformation
          seedQuantity: $seedQuantity
          estimatedSowDate: $estimatedSowDate
          sowDate: $sowDate
          estimatedTransplantDate: $estimatedTransplantDate
          transplantDate: $transplantDate
          estimatedHarvestStartDate: $estimatedHarvestStartDate
          harvestStartDate: $harvestStartDate
          estimatedHarvestEndDate: $estimatedHarvestEndDate
          harvestEndDate: $harvestEndDate
          produceThresholdId: $produceThresholdId
        ) {
          id
          organizationId
          siteId
          zoneId
          produce {
            id
            name
          }
          variety {
            id
            name
          }
          status
          plantingArea
          growthMedium
          hydrationMethod
          roofCover
          secondskyVariant
          blockingRatio
          netType
          secondskyInformation
          seedQuantity
          estimatedSowDate
          sowDate
          estimatedTransplantDate
          transplantDate
          estimatedHarvestStartDate
          harvestStartDate
          estimatedHarvestEndDate
          harvestEndDate
          produceThresholdId
          createdAt
          updatedAt
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'updateCrop',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

import useOrgLabel from '@/hooks/useOrgLabel'
import { Button, FlexV2, Text } from '@/primitives'

import Strings from '../../Strings'
import { getZoneDetailsText } from '../../utils'

import SectionHeaderWrapper from '../Utils/SectionHeaderWrapper'

import SettingsModal from './SettingsModal'

const Header = ({ selectedZone, buttonAction, permissions }) => {
  const strings = Strings()
  const { zoneDetailsHeading } = strings
  const orgLabels = useOrgLabel(['site', 'facility', 'room', 'zone', 'subzone'])
  const { buttonText, detailsSubheading } = getZoneDetailsText(
    selectedZone,
    orgLabels
  )

  return (
    <SectionHeaderWrapper>
      <FlexV2 direction='column' axisGap={100}>
        <Text size={300} fontWeight={700}>
          {zoneDetailsHeading}
        </Text>
        <Text fontFamily='mono' variant='page' tone={900} size={200}>
          {detailsSubheading}
        </Text>
      </FlexV2>
      <FlexV2 direction='row' axisGap={300}>
        <SettingsModal selectedZone={selectedZone} />
        {permissions && (
          <Button variant='primary' size='small' onClick={buttonAction}>
            {buttonText}
          </Button>
        )}
      </FlexV2>
    </SectionHeaderWrapper>
  )
}

export default Header

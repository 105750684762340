import { useState } from 'react'
import { I18n } from 'aws-amplify'

import { getWeatherForecast, getLang, getRootZone, getCurrentUser } from '@/reducers/selectors'
import { Flex, Text, Grid, Item } from '@/primitives'

import { convertCelciusToFahrenheit, FAHRENHEIT_UNIT } from '@/Util/MeasurementUtils'
import { getWeatherIcon, getTileHeader } from '../../utils'
import useDeviceSize from '@/hooks/useDeviceSize'
import {
  SETTING_CELCIUS_TO_FAHRENHEIT,
  processSettings
} from '@/Util/SettingsUtils'

import './index.scss'

const intervals = { hourly: 'hourlyForecast', daily: 'dailyForecast' }

const WeatherForecast = () => {
  const weatherData = getWeatherForecast()
  const lang = getLang()
  const rootZone = getRootZone()
  const isTablet = useDeviceSize()
  const currentUser = getCurrentUser()

  const { hourly, daily } = intervals
  const [timeInterval, setTimeInterval] = useState(hourly)
  const hasWeatherData = weatherData[timeInterval]?.length > 0

  const onClickTodayTab = () => {
    if (timeInterval !== hourly) setTimeInterval(hourly)
  }

  const onClickWeekTab = () => {
    if (timeInterval !== daily) setTimeInterval(daily)
  }

  const enableFahrenheit = processSettings(
    currentUser?.settings,
    SETTING_CELCIUS_TO_FAHRENHEIT,
    currentUser.userName,
    rootZone?.organizationId
  )

  function getTemp(tempCelsius) {
    const temperature = enableFahrenheit ? convertCelciusToFahrenheit(tempCelsius) : tempCelsius
    return Math.round(temperature)
  }

  return (
    <Flex className='WeatherWidget__Desktop' axisGap={400} direction='column'>
      <Flex alignMainAxis='space-between' alignCrossAxis='center' style={{ flexGrow: 1 }}>
        <Text size={300} fontWeight={600}>{I18n.get('Weather forecast')}</Text>
        <div className='WeatherWidget__Desktop__TabsContainer'>
          <div
            onClick={onClickTodayTab}
            className={`WeatherWidget__Desktop__TabHeader ${timeInterval === hourly && 'Selected'
              }`}
          >
            {I18n.get('Today').toUpperCase()}
          </div>
          <div
            onClick={onClickWeekTab}
            className={`WeatherWidget__Desktop__TabHeader ${timeInterval === daily && 'Selected'
              }`}
          >
            {I18n.get('This week').toUpperCase()}
          </div>
        </div>
      </Flex>
      {hasWeatherData && (
        <Grid gap={300} templateColumns={'1fr '.repeat(7)} templateRows='1fr' style={{ flexGrow: 1 }}>
          {weatherData[timeInterval]?.map(
            (
              { weatherAt, temperature, conditionCode, sunriseAt, sunsetAt },
              index
            ) => {
              if (index > 6) return null
              return (
                <Item key={`weather-tile-${index}`}>
                  <Flex
                    axisGap={isTablet ? 500 : 400}
                    alignMainAxis={isTablet ? 'space-between' : 'center'}
                    alignCrossAxis='center'
                    className='WeatherWidget__Desktop__Tile'
                    key={weatherAt}
                    direction={isTablet ? 'column' : 'row'}
                  >
                    <Text
                      style={{ width: '100%' }}
                    >
                      {getTileHeader(
                        weatherAt,
                        timeInterval === hourly,
                        lang,
                        rootZone?.timeZone,
                        isTablet
                      )}
                    </Text>
                    {getWeatherIcon(
                      conditionCode,
                      weatherAt,
                      sunriseAt,
                      sunsetAt
                    )}
                    <Text className='WeatherWidget__Desktop__Tile__Temperature'>
                      {`${getTemp(temperature)} ${enableFahrenheit ? FAHRENHEIT_UNIT : '°C'}`}
                    </Text>
                  </Flex>
                </Item>
              )
            }
          )}
        </Grid>
      )}
      {!hasWeatherData && <Text>{I18n.get('No data available')}</Text>}
    </Flex>
  )
}

export default WeatherForecast

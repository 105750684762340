import { updateList } from '../util'

import {
  REQUEST_FERTILIZER_PURCHASES,
  RECEIVE_FERTILIZER_PURCHASES,
  REQUEST_FERTILIZER_PURCHASE,
  RECEIVE_FERTILIZER_PURCHASE,
  CREATE_FERTILIZER_PURCHASE,
  FERTILIZER_PURCHASE_CREATED,
  UPDATE_FERTILIZER_PURCHASE,
  FERTILIZER_PURCHASE_UPDATED
} from '@/actions/operations/fertilizerPurchase'

export const fertilizerPurchaseInitialState = {
  fertilizerPurchases: [],
  fertilizerPurchasesCount: 0,
  fertilizerPurchase: {},
  loading: false,
  error: null
}

const handlers = {
  [REQUEST_FERTILIZER_PURCHASES]: (state, action) => ({
    ...state,
    fertilizerPurchases: [],
    fertilizerPurchasesCount: 0,
    error: null,
    loading: true
  }),
  [RECEIVE_FERTILIZER_PURCHASES]: (state, action) => ({
    ...state,
    fertilizerPurchases: !action.error ? action.fertilizerPurchases : [],
    fertilizerPurchasesCount: action?.count ?? 0,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_FERTILIZER_PURCHASE]: state => ({
    ...state,
    fertilizerPurchase: {},
    error: null,
    loading: true
  }),
  [RECEIVE_FERTILIZER_PURCHASE]: (state, action) => ({
    ...state,
    fertilizerPurchase: !action.error
      ? action.fertilizerPurchase
      : state.fertilizerPurchase,
    error: action.error ?? null,
    loading: false
  }),
  [CREATE_FERTILIZER_PURCHASE]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [FERTILIZER_PURCHASE_CREATED]: (state, action) => ({
    ...state,
    fertilizerPurchase: !action.error ? {} : state.fertilizerPurchase,
    fertilizerPurchases: updateList(
      action.error,
      state.fertilizerPurchases,
      action.fertilizerPurchase,
      true
    ),
    fertilizerPurchasesCount: !action.error
      ? state.fertilizerPurchasesCount + 1
      : state.fertilizerPurchasesCount,
    error: action.error ?? null,
    loading: false
  }),
  [UPDATE_FERTILIZER_PURCHASE]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [FERTILIZER_PURCHASE_UPDATED]: (state, action) => ({
    ...state,
    fertilizerPurchase: !action.error ? {} : state.fertilizerPurchase,
    fertilizerPurchases: updateList(
      action.error,
      state.fertilizerPurchases,
      action.fertilizerPurchase,
      false
    ),
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

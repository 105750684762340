export const initialState = {
  organizations: [],
  produces: [],
  varieties: [],
  statuses: ['available'],
  page: 1
}

const reducer = (state, action) => {
  if (action.state) {
    return {
      ...state,
      ...action.state
    }
  } else if (action.page) {
    return {
      ...state,
      page: action.page
    }
  } else if (action.organizations) {
    return {
      ...state,
      organizations: action.organizations,
      storageFacilities: []
    }
  } else if (action.produces) {
    return {
      ...state,
      produces: action.produces,
      varieties: []
    }
  } else if (action.varieties) {
    return {
      ...state,
      varieties: action.varieties
    }
  } else if (action.statuses) {
    return {
      ...state,
      statuses: action.statuses
    }
  }
  return state
}

export default reducer

import { makeOperationsApiCall } from './utils'

/* MUTATIONS */

export const createCountry = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createCountry(
        $organizationId: String!
        $currencyId: String!
        $name: String!
        $isoCode: String!
        $dialingCode: Int!
      ) {
        createCountry(
          organizationId: $organizationId
          currencyId: $currencyId
          name: $name
          isoCode: $isoCode
          dialingCode: $dialingCode
        ) {
          id
          organizationId
          name
          isoCode
          dialingCode
          currency {
            id
            name
            code
          }
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'createCountry',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updateCountry = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateCountry(
        $countryId: String!
        $organizationId: String!
        $currencyId: String!
        $dialingCode: Int!
        $isoCode: String!
        $name: String!
      ) {
        updateCountry(
          countryId: $countryId
          organizationId: $organizationId
          currencyId: $currencyId
          isoCode: $isoCode
          dialingCode: $dialingCode
          name: $name
        ) {
          id
          organizationId
          name
          isoCode
          dialingCode
          currency {
            id
            name
            code
          }
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'updateCountry',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

/* QUERIES */

export const getCountries = async params => {
  try {
    const query = /* GraphQL */ `
      query getCountries(
        $filter: GetCountriesFilter
        $limit: Int
        $offset: Int
        $order: OrderEnum
      ) {
        getCountries(
          filter: $filter
          limit: $limit
          offset: $offset
          order: $order
        ) {
          countries {
            id
            organizationId
            name
            isoCode
            dialingCode
            currency {
              id
              name
              code
            }
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getCountries',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getCountry = async params => {
  try {
    const query = /* GraphQL */ `
      query getCountry($countryId: String!) {
        getCountry(countryId: $countryId) {
          id
          organizationId
          name
          isoCode
          dialingCode
          currencyId
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getCountry',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

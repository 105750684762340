import { Flex, Icon, Text, Box } from '@/primitives'
import { getSensorTypeIcon } from '@/Util/DeviceUtils'

export function getDeviceOptions(devices) {
  return devices.map(device => {
    const { sensorType, name, tag = null, id } = device

    return {
      value: id,
      label: (
        <Flex wrap='nowrap' direction='row' axisGap={300}>
          <Icon
            name={getSensorTypeIcon(sensorType)}
            style={{ overflow: 'visible' }}
          />
          <Box className='SensorDetails'>
            <Text className='SensorDetails__Name'>{name}</Text>
            {tag && (
              <Text
                className='SensorDetails__Tag'
                tone='400'
                variant='page'
                size={100}
                fontWeight={600}
              >
                {tag}
              </Text>
            )}
          </Box>
        </Flex>
      )
    }
  })
}

import { graphqlOperation } from 'aws-amplify'
import { getCognitoData, makeGraphqlRequest } from '../utils'
import { GRAPHQL_URL } from './config'

export const getAuditLogs = async params => {
  try {
    const query = /* GraphQL */ `
      query getAuditLogs(
        $filters: GetAuditLogsFilters!
        $startAt: GraphQLTimestamp
        $endAt: GraphQLTimestamp
        $nextToken: GraphQLJSON
        $limit: Int
      ) {
        getAuditLogs(
          filters: $filters
          startAt: $startAt
          endAt: $endAt
          nextToken: $nextToken
          limit: $limit
        ) {
          auditLogs {
            actorId
            actorType
            action
            service
            context
            timestamp
            event
            organizationId
            siteId
          }
          count
          nextToken {
            actorId
            actorType
            action
            service
            context
            timestamp
            event
            organizationId
            siteId
          }
          scannedCount
        }
      }
    `

    const { idToken } = await getCognitoData()

    const operation = graphqlOperation(query, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        logs: null,
        error: `Failed to fetch Audit Logs: ${error}`
      }
    }

    return {
      logs: data?.getAuditLogs?.auditLogs,
      count: data?.getAuditLogs?.count,
      scannedCount: data?.getAuditLogs?.scannedCount,
      nextToken: data?.getAuditLogs?.nextToken,
      error: null
    }
  } catch (error) {
    return { logs: null, error: error.message }
  }
}

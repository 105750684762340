import { call, put, takeLatest } from 'redux-saga/effects'

import {
  getPackageUnitPurchases,
  getPackageUnitPurchase,
  createPackageUnitPurchase,
  updatePackageUnitPurchase
} from '@/api/operations/packageUnitPurchase'

import {
  REQUEST_PACKAGE_UNIT_PURCHASES,
  REQUEST_PACKAGE_UNIT_PURCHASE,
  CREATE_PACKAGE_UNIT_PURCHASE,
  UPDATE_PACKAGE_UNIT_PURCHASE,
  receivePackageUnitPurchases,
  receivePackageUnitPurchase,
  packageUnitPurchaseCreated,
  packageUnitPurchaseUpdated
} from '@/actions/operations/packageUnitPurchase'

import { displayBanner } from '../utils'

import Strings from './Strings'

const strings = Strings()

function* sendCreatePackageUnitPurchase(action) {
  const { data, error = null } = yield call(
    createPackageUnitPurchase,
    action.params
  )
  yield put(displayBanner(error, strings.packageUnitPurchaseCreated))
  yield put(packageUnitPurchaseCreated(data, error))
}

export function* watchCreatePackageUnitPurchase() {
  yield takeLatest(CREATE_PACKAGE_UNIT_PURCHASE, sendCreatePackageUnitPurchase)
}

function* sendUpdatePackageUnitPurchase(action) {
  const { data, error = null } = yield call(
    updatePackageUnitPurchase,
    action.params
  )
  yield put(displayBanner(error, strings.packageUnitPurchaseUpdated))
  yield put(packageUnitPurchaseUpdated(data, error))
}

export function* watchUpdatePackageUnitPurchase() {
  yield takeLatest(UPDATE_PACKAGE_UNIT_PURCHASE, sendUpdatePackageUnitPurchase)
}

function* sendRequestPackageUnitPurchases(action) {
  const { data, error = null } = yield call(
    getPackageUnitPurchases,
    action.params
  )
  const { packageUnitPurchases = [], count = 0 } = data
  yield put(receivePackageUnitPurchases(packageUnitPurchases, count, error))
}

export function* watchRequestPackageUnitPurchases() {
  yield takeLatest(
    REQUEST_PACKAGE_UNIT_PURCHASES,
    sendRequestPackageUnitPurchases
  )
}

function* sendRequestPackageUnitPurchase(action) {
  const { data, error = null } = yield call(
    getPackageUnitPurchase,
    action.params
  )
  yield put(receivePackageUnitPurchase(data, error))
}

export function* watchRequestPackageUnitPurchase() {
  yield takeLatest(
    REQUEST_PACKAGE_UNIT_PURCHASE,
    sendRequestPackageUnitPurchase
  )
}

import { I18n } from 'aws-amplify'
import { useEffect, useState } from 'react'

import { getDataboardSitesHierarchies } from '@/reducers/selectors'

import ZoneUtils from '../../../../Util/ZoneUtils'
import { Select } from '@/elements'

const ZonesFilter = ({
  state,
  dispatchState,
  zoneId = null,
  parentId = null,
  disabled = false
}) => {
  const zonesHierarchy = getDataboardSitesHierarchies()

  const [zones, setZones] = useState([])
  const [zoneFilter, setZoneFilter] = useState(zoneId)

  const zoneDetails = ZoneUtils.getZoneFromHierarchy(zonesHierarchy, parentId)
  const zoneString = ZoneUtils.getZoneDepthString(zoneDetails, 1)

  useEffect(() => {
    if (Object.keys(zonesHierarchy)?.length > 0 && state?.sites?.length > 0) {
      const zone = ZoneUtils.getZoneFromHierarchy(zonesHierarchy, parentId)

      if (zone?.children) {
        const zonesList = Object.keys(zone.children).map(
          id => zone.children[id]
        )
        setZones(zonesList)
      }
    }
  }, [JSON.stringify(zonesHierarchy), JSON.stringify(state), parentId])

  const dispatchChange = newZone => {
    let newPath = ''
    if (newZone && zones.length > 0) {
      newPath = zones?.find(({ id }) => id === newZone)?.parentPath
    }

    if (!newZone && zoneId) {
      newPath = state?.zonePath?.split(`/${zoneId}`)[0]
    }

    dispatchState({
      zonePath: newPath
    })
  }

  const onChangeFilter = e => {
    const newZone = e?.value ?? null
    setZoneFilter(newZone)
    dispatchChange(newZone)
  }

  const options = zones.map(({ id, name }) => ({
    value: id,
    label: name
  }))

  return (
    <Select
      isClearable={true}
      value={zoneFilter}
      placeholder={`${I18n.get('Select')} ${zoneString?.toLowerCase()}`}
      options={options}
      onChange={onChangeFilter}
      isDisabled={disabled}
      className='Operations__Databoard__Filter'
      classNamePrefix='Operations__Databoard__Filter'
    />
  )
}

export default ZonesFilter

export const TOOLBOX_TYPES = {
  category: 'category',
  section: 'section'
}

export class ToolboxItem {
  constructor({ id, type, title, icon, items, denyVisibility }) {
    this.id = id || this.paramRequired('id')
    this.type = type || this.paramRequired('type')
    this.title = title || this.paramRequired('title')
    this.icon = icon || this.paramRequired('icon')
    this.items = items || this.paramRequired('items')
    this.denyVisibility = denyVisibility || null

    const isValidType = Object.values(TOOLBOX_TYPES).some(
      toolboxType => this.type === toolboxType
    )

    let itemsKeyHash = {}

    for (let item of items) {
      const key = item?.widgetId || item?.id
      if (itemsKeyHash[key]) {
        throw new Error(
          `ToolboxItem: ${itemsKeyHash[key]} is duplicated in ${this.title}`
        )
      }
      itemsKeyHash[key] = item.title
    }

    if (!isValidType) {
      throw new Error(`ToolboxItem: ${type} is not a valid type`)
    }
  }

  paramRequired(param) {
    throw new Error(`ToolboxItem: ${param} is required`)
  }
}

import { I18n } from 'aws-amplify'
import ZoneUtils from '@/Util/ZoneUtils'
import { LocationStrings } from './Strings'

export function generateRoutes(components) {
  return components.map(({ route, text, component, visible, onClick }) => {
    const routeConfig = {
      to: `/admin/${route}`,
      text,
      visible,
      component
    }

    if (onClick) {
      routeConfig.onClick = onClick
    }

    return routeConfig
  })
}

export function getZoneDetailsText(zone, orgLabels) {
  const zoneDepthId = ZoneUtils.getZoneDepthIdentifier(zone)
  const locationLabel = orgLabels[zoneDepthId]
  const strings = LocationStrings(locationLabel)

  return {
    buttonText: strings.editLocationDetails,
    detailsSubheading: strings.locationDetailsSubheading
  }
}

export function getZoneModalStrings(zone, addNewZone, orgLabels) {
  const modifier = addNewZone ? 1 : 0
  const zoneDepthId = ZoneUtils.getZoneDepthIdentifier(zone, modifier)
  const zoneLabel = orgLabels[zoneDepthId]

  const strings = LocationStrings(zoneLabel)

  let modalStrings = {
    modalTitle: addNewZone
      ? strings.addLocationModal
      : strings.updateLocationModal,
    modalSubtitle: addNewZone
      ? strings.addLocationModalSubheading
      : strings.updateLocationModalSubheading,
    submitButtonText: strings.createLocationButton,
    name: strings.locationName,
    id: strings.locationId
  }

  if (!addNewZone) {
    modalStrings['submitButtonText'] = I18n.get('Save')
  }

  return modalStrings
}

export function getZoneSubzonesStrings(zone, orgLabels) {
  const zoneDepthId = ZoneUtils.getZoneDepthIdentifier(zone, 1)
  const locationLabel = orgLabels[zoneDepthId]

  const strings = LocationStrings(locationLabel)

  return {
    heading: strings.locationsHeading,
    subheading: strings.locationsSubheading,
    buttonText: strings.addLocation,
    noLocationsText: strings.noLocations
  }
}

export function getZoneSubzonesTabName(zone, orgLabels) {
  const zoneDepthId = ZoneUtils.getZoneDepthIdentifier(zone, 1)
  const locationLabel = orgLabels[zoneDepthId]
  const strings = LocationStrings(locationLabel)

  return strings.locationsHeading
}

import { Fragment, useEffect, useState } from 'react'
import { nanoid } from 'nanoid'

import { Flex, Box, Button } from '@/primitives'
import { getSlottedChildren } from '@/primitives/utils'

import './index.scss'

const defaultAlignment = {
  modal: {
    x: 'center',
    y: 'center'
  },
  drawer: {
    x: 'center',
    y: 'start'
  },
  snackbar: {
    x: 'center',
    y: 'start'
  },
  offcanvas: {
    x: 'end',
    y: 'start'
  }
}

const prependFlex = word => (word !== 'center' ? `flex-${word}` : word)

/**
 * Dialog Element
 * @param {Object} props
 * @param { 'info' | 'primary' | 'success' | 'neutral' | 'warning' | 'danger' | 'error' | 'graph' | 'text' } [props.variant='default']
 * @param {'modal' | 'drawer' | 'snackbar' | 'offcanvas'} [props.type='modal']
 * @param {'start' | 'center' | 'end' } [props.alignX='center']
 * @param {'start' | 'center' | 'end' } [props.alignY='center']
 * @param {Boolean} [props.open = false] For CONTROLLED Component
 * @param {Boolean} [props.splitActions = true] For forms and if you don't need a split
 * @param {Boolean} [props.closeOutside = false] Can the user close the dialog by clicking outside the box?
 * @param {Boolean} [props.onOpenChange] For CONTROLLED Component
 * @param {Boolean} [props.lockScroll = false] Stop the document from scrolling
 * @param {String | String[]} [props.className]
 * @param {import('react').CSSProperties} [props.css]
 * @example
 * ```jsx
 * <Dialog type='modal'>
 *   <Slot name='title'>The title of the Dialog</Slot>
 *   <Slot name='content'>The Dialog Content</Slot>
 *   <Slot name='actions'>The Dialog Actions</Slot>
 * </Dialog>
 * ```
 *
 * @example
 * ```jsx
 * <Dialog type='drawer'>
 *   <Slot name='title'>The title of the Dialog</Slot>
 *   <Slot name='content'>The Dialog Content</Slot>
 *   <Slot name='actions'>The Dialog Actions</Slot>
 * </Dialog>
 * ```
 *
 * @example
 * ```jsx
 * <Dialog type='snackbar'>
 *   <Slot name='title'>Snackbar Title</Slot>
 * </Dialog>
 * ```
 *
 * @example
 * ```jsx
 * <Dialog type='offcanvas'>
 *   <Slot name='title'>The title of the Dialog</Slot>
 *   <Slot name='content'>The Dialog Content</Slot>
 *   <Slot name='actions'>The Dialog Actions</Slot>
 * </Dialog>
 * ```
 */

const Dialog = ({
  open = false,
  variant = 'default',
  type = 'modal',
  children = null,
  onOpenChange = null,
  className = null,
  lockScroll = false,
  splitActions = true,
  closeOutside = false,
  alignX = null,
  alignY = null,
  ...rest
}) => {
  const [formId, setFormId] = useState(null)
  const { title, content, actions } = getSlottedChildren(children, {
    title: null,
    content: null,
    actions: null
  })

  useEffect(() => {
    if (!formId) {
      const id = nanoid()
      setFormId(id)
    }
  }, [])

  useEffect(() => {
    if (open) {
      const dialogElement = document.getElementById(formId)
      dialogElement?.focus()
    }
  }, [formId, open])

  useEffect(() => {
    if (lockScroll === true || type === 'offcanvas') {
      document.documentElement.classList.toggle('hide-overflow', open)
    }

    return () => {
      document.documentElement.classList.toggle('hide-overflow', false)
    }
  }, [open, lockScroll, type])

  const handleDialogEvent = event => {
    const {
      key,
      target: {
        dataset: { action },
        nodeName
      }
    } = event

    if (onOpenChange) {
      if (closeOutside) {
        if (
          (nodeName === 'DIALOG' || nodeName === 'BUTTON') &&
          action === 'close'
        ) {
          onOpenChange(!open)
        }
      }

      if (!closeOutside) {
        if (nodeName === 'BUTTON' && action === 'close') {
          onOpenChange(!open)
        }
      }

      if (key === 'Escape') {
        const matches = document.querySelectorAll('.Dialog__Box')
        const count = matches?.length
        const lastItem = matches[count - 1]

        if (lastItem?.id === formId) {
          if (count > 1) {
            const prevItem = matches[count - 2]
            prevItem.focus()
          }
          onOpenChange(false)
        }
      }
    }
  }

  if (!open) {
    return null
  }

  return (
    <Flex
      className={['Dialog', className]}
      as={'dialog'}
      open={open}
      data-type={type}
      data-variant={variant}
      alignMainAxis={prependFlex(
        alignX ?? (defaultAlignment[type]?.x || 'center')
      )}
      alignCrossAxis={prependFlex(
        alignY ?? (defaultAlignment[type]?.y || 'center')
      )}
      aria-hidden={!open}
      data-action={closeOutside ? 'close' : null}
      onKeyDown={handleDialogEvent}
      {...rest}
    >
      <Flex
        role='document'
        className={'Dialog__Box'}
        id={formId}
        direction={'column'}
        axisGap={400}
        tabIndex={open ? -1 : 0}
      >
        <Flex
          className={'Dialog__Box__Title'}
          alignMainAxis={'space-between'}
          alignCrossAxis={'center'}
          wrap='nowrap'
          axisGap={type === 'snackbar' ? 0 : 400}
        >
          {type === 'snackbar' && <Box />}
          <Box>{title}</Box>
          <Button
            iconBefore='close'
            variant='text'
            className={'Dialog__Box__Title_Close'}
            data-action={'close'}
            onClick={handleDialogEvent}
          />
        </Flex>
        {type !== 'snackbar' && (
          <Fragment>
            {content && (
              <Box className={'Dialog__Box__Body'}>
                <Box className={'Dialog__Box__Content'}>{content}</Box>
              </Box>
            )}
            {actions && (
              <Fragment>
                {splitActions && (
                  <Box as={'hr'} className='Dialog__Box__Separator' />
                )}
                <Box className={'Dialog__Box__Actions'}>{actions}</Box>
              </Fragment>
            )}
          </Fragment>
        )}
      </Flex>
    </Flex>
  )
}

export default Dialog

import { useReducer, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import DatePicker from 'react-datepicker'

import useDeviceSize from '@/hooks/useDeviceSize'

import { requestAllStorageFacilities } from '@/actions/operations/storageFacility'
import { requestAllShippingMethods } from '@/actions/operations/shippingMethod'
import { fetchAllFertilizers } from '@/slices/fertigation/fertilizer'

import {
  clearFertilizerInventories,
  requestFertilizerInventories
} from '@/actions/operations/fertilizerInventory'

import {
  createFertilizerInventoryTransfer,
  requestFertilizerInventoryTransfer,
  updateFertilizerInventoryTransfer
} from '@/actions/operations/fertilizerInventoryTransfer'

import {
  getOperationsAllStorageFacilities,
  getOperationsAllShippingMethods,
  getOperationsFertilizerInventories,
  getOperationsFertilizerInventoryTransfer,
  getOperationsLoading,
  getOperationsError,
  getAllFertilizers
} from '@/reducers/selectors'

import { Box, Flex, Label, Select, Text, Loader, Button } from '@/primitives'

import InputError from '@/elements/InputError'
import OrganizationInput from '../../../Shared/Organization/Input'

import { getValidationErrorMap } from '@/Util/GeneralUtils'

import history from '../../../../../history'
import Strings from '../Strings'

import {
  initialState,
  reducer,
  UPDATE_INPUT,
  SET_STATE,
  UPDATE_WEIGHT,
  REMOVE_WEIGHT
} from './state'

import { INVENTORY_TRANSFER_STATUSES } from '../utils'
import { FIELDS, SCHEMA } from './config'
import InventoryWeightFormTable from '../../../Shared/InventoryWeights'
import HeaderV2 from '@/components/Operations/Shared/HeaderV2'

function FertilizerInventoryTransferForm({ state, modulePath }) {
  const dispatch = useDispatch()
  const isMobile = useDeviceSize('mobile')
  const isLaptop = useDeviceSize()
  const { itemId } = useParams()

  const strings = Strings()

  const storageFacilities = getOperationsAllStorageFacilities()
  const shippingMethods = getOperationsAllShippingMethods()
  const fertilizers = getAllFertilizers()
  const fertilizerInventories = getOperationsFertilizerInventories()
  const fertilizerInventoryTransfer = getOperationsFertilizerInventoryTransfer()

  const loading = getOperationsLoading()
  const error = getOperationsError()

  const [formState, dispatchFormState] = useReducer(reducer, initialState)

  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)

  const inventoryTransferStatuses = INVENTORY_TRANSFER_STATUSES(strings)

  useEffect(() => {
    dispatch(clearFertilizerInventories())
  }, [])

  useEffect(() => {
    dispatch(requestAllStorageFacilities())
    dispatch(requestAllShippingMethods())
    dispatch(fetchAllFertilizers())
  }, [dispatch])

  useEffect(() => {
    if (formState.organizationId && formState.fromStorageFacilityId) {
      dispatch(
        requestFertilizerInventories({
          filter: {
            organizationId: [formState.organizationId],
            storageFacilityId: [formState.fromStorageFacilityId]
          }
        })
      )
    }
  }, [formState.organizationId, formState.fromStorageFacilityId, dispatch])

  useEffect(() => {
    if (itemId && fertilizerInventoryTransfer.id !== itemId) {
      dispatch(
        requestFertilizerInventoryTransfer({
          fertilizerInventoryTransferId: itemId
        })
      )
    }
  }, [itemId])

  useEffect(() => {
    if (
      fertilizerInventoryTransfer?.id &&
      fertilizerInventoryTransfer.id === itemId
    ) {
      const { fertilizerInventory, ...transfer } = fertilizerInventoryTransfer
      dispatchFormState({
        type: SET_STATE,
        state: {
          ...transfer,
          fromStorageFacilityId: fertilizerInventory.storageFacilityId,
          fertilizerId: fertilizerInventory.fertilizerId
        }
      })
    }
  }, [fertilizerInventoryTransfer])

  useEffect(() => {
    if (!itemId && state.organizations.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'organizationId',
        value: state.organizations[0]
      })
    }
  }, [state.organizations])

  useEffect(() => {
    if (!itemId && state.fromStorageFacilities.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'fromStorageFacilityId',
        value: state.fromStorageFacilities[0]
      })
    }
  }, [state.storageFacilities])

  useEffect(() => {
    if (!itemId && state.storageFacilities.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'storageFacilityId',
        value: state.storageFacilities[0]
      })
    }
  }, [state.storageFacilities])

  useEffect(() => {
    if (waiting && !loading && !error) {
      dispatch(clearFertilizerInventories())
      history.replace(modulePath)
    }
  }, [loading])

  useEffect(() => {
    if (formState.fertilizerId) {
      for (const fertilizerInventory of fertilizerInventories) {
        if (fertilizerInventory.fertilizerId === formState.fertilizerId) {
          dispatchFormState({
            type: UPDATE_INPUT,
            name: 'fertilizerInventoryId',
            value: fertilizerInventory.id
          })
          return true
        }
      }
    }
    dispatchFormState({
      type: UPDATE_INPUT,
      name: 'fertilizerInventoryId',
      value: null
    })
  }, [formState.fertilizerId, fertilizerInventories])

  const onSubmit = async e => {
    try {
      setErrors({})
      const payload = await SCHEMA().validate(formState, { abortEarly: false })
      setWaiting(true)

      if (itemId) {
        payload.fertilizerInventoryTransferId = itemId
        dispatch(updateFertilizerInventoryTransfer(payload))
      } else {
        dispatch(createFertilizerInventoryTransfer(payload))
      }
    } catch (error) {
      setErrors(getValidationErrorMap(error))
    }
  }

  const handleInput = e => {
    const { name, value } = e.currentTarget
    dispatchFormState({ type: UPDATE_INPUT, name, value })
  }

  const handleDateInput = (date, name) => {
    dispatchFormState({ type: UPDATE_INPUT, name, value: date })
  }

  const handleFromStorageFacility = e => {
    dispatchFormState({
      type: SET_STATE,
      state: {
        ...formState,
        fromStorageFacilityId: e.currentTarget.value,
        storageFacilityId: '',
        fertilizerInventoryId: '',
        shippingMethodId: '',
        fertilizerId: ''
      }
    })
  }

  const handleOrganizationInput = organizationId => {
    dispatchFormState({
      type: SET_STATE,
      state: {
        ...formState,
        organizationId,
        fertilizerInventoryId: '',
        fromStorageFacilityId: '',
        shippingMethodId: '',
        storageFacilityId: '',
        fertilizerId: ''
      }
    })
  }

  const filterFromStorageFacilities = () => {
    return storageFacilities.filter(
      p => p.organizationId === formState.organizationId
    )
  }

  const filterStorageFacilities = () => {
    return storageFacilities.filter(
      s =>
        s.organizationId === formState.organizationId &&
        s.id !== formState.fromStorageFacilityId
    )
  }

  const filterShippingMethods = () => {
    return shippingMethods.filter(
      p => p.storageFacility.id === formState.fromStorageFacilityId
    )
  }

  const filterFertilizers = () => {
    const result = fertilizers.filter(fertilizer => {
      for (const fertilizerInventory of fertilizerInventories) {
        if (fertilizerInventory.fertilizerId === fertilizer.id) {
          return true
        }
      }
      return false
    })
    return result
  }

  const getHeader = () => {
    if (itemId) {
      return strings.updateFormHeader
    }
    return strings.createFormHeader
  }

  const updateWeight = weight => {
    dispatchFormState({
      type: UPDATE_WEIGHT,
      weight
    })
  }

  const removeWeight = weight => {
    dispatchFormState({
      type: REMOVE_WEIGHT,
      weight
    })
  }
  return (
    <Flex
      as='form'
      axisGap={400}
      direction='column'
      className='Operations__Form'
    >
      <HeaderV2
        title={getHeader()}
        backPath={modulePath}
        buttonIcon={'save'}
        buttonText={strings.saveInventoryTransfer}
        buttonCallback={onSubmit}
      />
      <Loader isLoading={loading}>
        <Flex axisGap={800} direction={isLaptop ? 'column' : null}>
          <Flex
            axisGap={400}
            direction='column'
            className='Operations__Form__Fields'
          >
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.transfer}
              </Text>
              <OrganizationInput
                fieldName={FIELDS.organizationId}
                organizationId={formState.organizationId}
                handleInput={handleOrganizationInput}
                errors={errors}
              />
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.fromStorageFacility}
                  </Text>
                  <Select
                    className='Operations__Select'
                    name={FIELDS.fromStorageFacilityId}
                    value={formState.fromStorageFacilityId}
                    onChange={handleFromStorageFacility}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterFromStorageFacilities().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.fromStorageFacilityId} />
                </Label>
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.shippingMethod}
                  </Text>
                  <Select
                    className='Operations__Select'
                    name={FIELDS.shippingMethodId}
                    value={formState.shippingMethodId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterShippingMethods().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.shippingMethodId} />
                </Label>
                <Label style={{ flex: '1 1 0' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.shippedDate}
                    </Text>
                    <DatePicker
                      maxDate={new Date()}
                      selected={formState.shippedDate}
                      className='Operations__Form__Select'
                      onChange={date =>
                        handleDateInput(date, FIELDS.shippedDate)
                      }
                    />
                    <InputError error={errors?.shippedDate} />
                  </Flex>
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '1' }}>
                  <Text variant='page' tone={700}>
                    {strings.toStorageFacility}
                  </Text>
                  <Select
                    className='Operations__Select'
                    name={FIELDS.storageFacilityId}
                    value={formState.storageFacilityId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterStorageFacilities().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.storageFacilityId} />
                </Label>
                <Label style={{ flex: '1' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.status}
                    </Text>
                    <Select
                      className='Operations__Select'
                      name={FIELDS.status}
                      value={formState.status}
                      onChange={handleInput}
                    >
                      <option default value=''>
                        {strings.selectDefault}
                      </option>
                      {Object.entries(inventoryTransferStatuses).map(
                        ([id, name]) => (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        )
                      )}
                    </Select>
                    <InputError error={errors?.status} />
                  </Flex>
                </Label>
                <Label style={{ flex: '1' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.deliveredDate}
                    </Text>
                    <DatePicker
                      maxDate={new Date()}
                      selected={formState.deliveredDate}
                      className='Operations__Form__Select'
                      onChange={date =>
                        handleDateInput(date, FIELDS.deliveredDate)
                      }
                    />
                    <InputError error={errors?.deliveredDate} />
                  </Flex>
                </Label>
              </Flex>
            </Box>
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.fertilizer}
              </Text>
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.fertilizer}
                  </Text>
                  <Select
                    className='Operations__Select'
                    name={FIELDS.fertilizerId}
                    value={formState.fertilizerId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterFertilizers().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.fertilizerId} />
                </Label>
              </Flex>
            </Box>
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.fertilizerUnits}
              </Text>
              <Flex
                axisGap={300}
                direction='column'
                className='Operations__Form__Weights'
              >
                <InventoryWeightFormTable
                  organizationId={formState.organizationId}
                  updateWeight={updateWeight}
                  removeWeight={removeWeight}
                  weights={formState.weights}
                  totalWeight={formState.totalWeight}
                  errors={errors}
                />
                <InputError error={errors.weights} />
                <Flex alignMainAxis={'flex-end'}>
                  <Button
                    onClick={() => updateWeight()}
                    size='small'
                    name='add'
                    variant='primary'
                    iconBefore='add'
                    className='Operations__Form__Weights__Add'
                  >
                    {strings.addWeight}
                  </Button>
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Loader>
    </Flex>
  )
}

export default FertilizerInventoryTransferForm

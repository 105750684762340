import {
  START_CORE_DEVICE_UPDATES,
  CORE_DEVICE_UPDATES_STARTED,
  CLEAR_UPDATES,
  FETCH_CORE_DEVICE_PROVISION_INSTALLER,
  CORE_DEVICE_PROVISION_INSTALLER_FETCHED
} from '@/actions/management/greengrass'

import { createReducer } from '@/Util/ReduxUtils'

export const initialState = {
  updatesStarted: false,
  loading: false,
  error: null
}

const handlers = {
  [CLEAR_UPDATES]: state => ({
    ...state,
    updatesStarted: false
  }),
  [START_CORE_DEVICE_UPDATES]: state => ({
    ...state,
    updatesStarted: false,
    loading: true,
    error: null
  }),
  [CORE_DEVICE_UPDATES_STARTED]: (state, action) => ({
    ...state,
    updatesStarted: action.error ? false : true,
    loading: false,
    error: action.error ?? null
  }),
  [FETCH_CORE_DEVICE_PROVISION_INSTALLER]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [CORE_DEVICE_PROVISION_INSTALLER_FETCHED]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error ?? null
  })
}

export default createReducer(handlers, initialState)

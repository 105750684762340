import { makeOperationsApiCall } from './utils'

export const getStorageFacilities = async params => {
  try {
    const query = /* GraphQL */ `
      query getStorageFacilities(
        $filter: GetStorageFacilitiesFilter
        $order: OrderEnum
        $offset: Int
        $limit: Int
      ) {
        getStorageFacilities(
          filter: $filter
          order: $order
          offset: $offset
          limit: $limit
        ) {
          storageFacilities {
            id
            organizationId
            name
            country {
              id
              name
            }
            region {
              id
              name
            }
            city {
              id
              name
            }
            storeTypes
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getStorageFacilities',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getStorageFacility = async params => {
  try {
    const query = /* GraphQL */ `
      query getStorageFacility($storageFacilityId: String!) {
        getStorageFacility(storageFacilityId: $storageFacilityId) {
          id
          organizationId
          name
          countryId
          regionId
          cityId
          storeTypes
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getStorageFacility',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const createStorageFacility = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createStorageFacility(
        $organizationId: String!
        $name: String!
        $countryId: String!
        $regionId: String!
        $cityId: String!
        $storeTypes: [String]!
      ) {
        createStorageFacility(
          organizationId: $organizationId
          name: $name
          countryId: $countryId
          regionId: $regionId
          cityId: $cityId
          storeTypes: $storeTypes
        ) {
          id
          organizationId
          name
          country {
            id
            name
          }
          region {
            id
            name
          }
          city {
            id
            name
          }
          storeTypes
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'createStorageFacility',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updateStorageFacility = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateStorageFacility(
        $storageFacilityId: String!
        $organizationId: String!
        $name: String!
        $countryId: String!
        $regionId: String!
        $cityId: String!
        $storeTypes: [String]!
      ) {
        updateStorageFacility(
          storageFacilityId: $storageFacilityId
          organizationId: $organizationId
          name: $name
          countryId: $countryId
          regionId: $regionId
          cityId: $cityId
          storeTypes: $storeTypes
        ) {
          id
          organizationId
          name
          country {
            id
            name
          }
          region {
            id
            name
          }
          city {
            id
            name
          }
          storeTypes
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'updateStorageFacility',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

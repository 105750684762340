import { I18n } from 'aws-amplify'
import { useLiveData } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Hooks/useLiveData'
import useThresholdStatus from '../../Shared/Hooks/useThresholdStatus'
import { parseValue } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/utils'
import Value from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/Chart/Value'
import {
  convertToTotalDissolvedSolids,
  requiredVectorMeasurements
} from '@/Util/MeasurementUtils'

export default function LiveValue({ unit, options }) {
  const data = useLiveData({
    measurements: requiredVectorMeasurements[options.measurement]
  })
  const thresholdStatus = useThresholdStatus(options.measurements)

  const parseOptions = conductivity => ({
    fixedFactor: options.fixedFactor,
    scaleFactor: options.scaleFactor,
    value: convertToTotalDissolvedSolids({ conductivity })
  })

  return (
    <Value
      thresholdStatus={thresholdStatus}
      data={{
        value: parseValue(parseOptions(data?.conductivity?.value)),
        min: parseValue(parseOptions(data?.conductivity?.min)),
        max: parseValue(parseOptions(data?.conductivity?.max)),
        unit,
        status: data?.conductivity?.status,
        subtitle: I18n.get('Live')
      }}
    />
  )
}

import { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Box, Button, Flex, Grid, Item, Text } from '@/primitives'
import { LineSeparator } from '@/elements'

import DetailsHeader from './Header'
import ProvisioningConfigModal from './ProvisioningConfigModal'
import DeploymentsModal from './DeploymentsModal'
import UpdateZoneModal from '../UpsertModal'

import { startGreengrassCoreDeviceDefaultDeployment } from '@/actions/management/greengrass'
import {
  fetchSiteTrackingStatus,
  cleanSiteTrackingStatus
} from '@/slices/ml/siteTracker'
import { fetchZone } from '@/slices/management/zone'
import { fetchSiteIngestionFrequency } from '@/slices/timestream/ingestionFrequency'

import {
  getCurrentUser,
  getCurrentUserOrganizations
} from '@/reducers/selectors'

import { isZoneSite, isZoneRoom, getOrgNameById } from '@/Util/AdminUtils'

import {
  RESOURCE_TYPE_ZONE,
  getPermissionsList,
  findPermissionById,
  getIsGodMode
} from '@/Util/PermissionUtils'

import Strings from '../../Strings'

import ZoneUtils from '@/Util/ZoneUtils'

import './index.scss'

const insertZoneDetailsItem = (text, value) => {
  return (
    <Item className='ZoneDetails__Grid__Item'>
      <Flex direction='column' axisGap={300}>
        <Text size={100} tone={700} variant='page'>
          {text}
        </Text>
        <Text size={200} tone={900} variant='page'>
          {value}
        </Text>
      </Flex>
    </Item>
  )
}

const ZoneDetails = ({ selectedZone, setBreadcrumbs }) => {
  const {
    zoneDetailsLatitude,
    zoneDetailsLongitude,
    zoneDetailsTimezone,
    showProvisioningConfigButton,
    greengrassDeviceTitle,
    greengrassDeviceStatus,
    noGreengrassDevice,
    noGreengrassDeviceDeployments,
    greengrassCreateDefaultDeployment,
    greengrassDeploymentsModalButton,
    greengrassRefetchCoreDevice,
    active,
    inactive,
    statusName,
    organization,
    code
  } = Strings()

  const statusOptions = { active, inactive }

  const coretexUser = getCurrentUser()
  const organizations = getCurrentUserOrganizations()
  const isGodMode = getIsGodMode(coretexUser)

  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const [showProvisioningConfigModal, setShowProvisioningConfigModal] =
    useState(false)
  const [showDeploymentsModal, setShowDeploymentsModal] = useState(false)
  const [showCreateDeploymentButton, setShowCreateDeploymentButton] =
    useState(false)
  const [showDeploymentsButton, setShowDeploymentsButton] = useState(false)
  const [isSite, setIsSite] = useState(false)
  const [isRoom, setIsRoom] = useState(false)
  const [provisioningConfig, setProvisioningConfig] = useState(null)
  const [greengrassCoreDevice, setGreengrassCoreDevice] = useState(null)

  useEffect(() => {
    return () => dispatch(cleanSiteTrackingStatus())
  }, [dispatch])

  useEffect(() => {
    setIsSite(isZoneSite(selectedZone))
    setIsRoom(isZoneRoom(selectedZone))
    setProvisioningConfig(null)
  }, [selectedZone])

  useEffect(() => {
    if (isSite && isGodMode) {
      dispatch(fetchSiteTrackingStatus(selectedZone.id))
    }
  }, [isSite, isGodMode, dispatch, selectedZone.id])

  useEffect(() => {
    if (isSite && isGodMode) {
      dispatch(fetchSiteIngestionFrequency(selectedZone.id))
    }
  }, [isSite, isGodMode, dispatch, selectedZone.id])

  useEffect(() => {
    if (selectedZone?.provisioningConfig?.value) {
      setProvisioningConfig(selectedZone?.provisioningConfig?.value)
    }
  }, [isRoom, selectedZone?.provisioningConfig])

  useEffect(() => {
    if (selectedZone?.greengrassCoreDevice?.value) {
      setGreengrassCoreDevice(selectedZone?.greengrassCoreDevice?.value)
    }
  }, [isRoom, selectedZone?.greengrassCoreDevice])

  useEffect(() => {
    if (greengrassCoreDevice) {
      const { effectiveDeployments, coreDeviceThingName } = greengrassCoreDevice
      const noDeployments = effectiveDeployments?.length === 0
      const hasOwnDeployment =
        effectiveDeployments.findIndex(({ deploymentName }) =>
          deploymentName.includes(coreDeviceThingName)
        ) > -1
      const hasSystemDeployment =
        effectiveDeployments.findIndex(
          ({ deploymentName, coreDeviceExecutionStatus, reason }) =>
            deploymentName.includes('system-group-deployment') &&
            coreDeviceExecutionStatus === 'SUCCEEDED' &&
            reason === 'SUCCESSFUL'
        ) > -1

      setShowCreateDeploymentButton(!hasOwnDeployment && hasSystemDeployment)
      setShowDeploymentsButton(!noDeployments)
    }
  }, [greengrassCoreDevice])

  function onClickEditZone() {
    setShowModal(true)
  }

  function onClickProvisioningConfig() {
    setShowProvisioningConfigModal(true)
  }

  function onClickDeployentsModal() {
    setShowDeploymentsModal(true)
  }

  function onRefetchCoreDevice() {
    dispatch(fetchZone({ id: selectedZone.id }))
  }

  function onStartDefaultDeployment(e) {
    e.preventDefault()
    dispatch(
      startGreengrassCoreDeviceDefaultDeployment({ id: selectedZone.id })
    )
  }

  const permissionsList = getPermissionsList(
    coretexUser,
    RESOURCE_TYPE_ZONE,
    'write'
  )
  const hasZoneEditPermission =
    findPermissionById(
      permissionsList,
      ZoneUtils.getPrimaryZoneFromParentPath(selectedZone?.parentPath)
    ) || findPermissionById(permissionsList)

  return (
    <Flex axisGap={300} direction={'column'} className={'ZoneDetails'}>
      {showModal && (
        <UpdateZoneModal
          showModal={showModal}
          setShowModal={setShowModal}
          addNewZone={false}
          setBreadcrumbs={setBreadcrumbs}
        />
      )}
      <DetailsHeader
        selectedZone={selectedZone}
        buttonAction={onClickEditZone}
        permissions={hasZoneEditPermission}
      />

      <LineSeparator style={{ marginBottom: 0 }} />
      <Grid
        templateColumns={`1fr 1fr ${
          selectedZone.organizationId ? '2fr' : '2fr 2fr'
        }`}
        className='ZoneDetails__Grid'
      >
        {insertZoneDetailsItem(zoneDetailsLatitude, selectedZone.latitude)}
        {insertZoneDetailsItem(zoneDetailsLongitude, selectedZone.longitude)}
        {insertZoneDetailsItem(zoneDetailsTimezone, selectedZone.timeZone)}
        {!selectedZone.organizationId &&
          !selectedZone.code &&
          insertZoneDetailsItem(statusName, statusOptions[selectedZone.status])}
      </Grid>
      {selectedZone.organizationId && (
        <Fragment>
          <LineSeparator style={{ marginBottom: 0 }} />
          <Grid
            templateColumns={
              selectedZone.organizationId && selectedZone.code
                ? '2fr 1fr 1fr'
                : '1fr 1fr'
            }
            className='ZoneDetails__Grid'
          >
            {insertZoneDetailsItem(
              organization,
              getOrgNameById(selectedZone.organizationId, organizations)
            )}
            {insertZoneDetailsItem(
              statusName,
              statusOptions[selectedZone.status]
            )}
            {selectedZone.code &&
              insertZoneDetailsItem(code, selectedZone.code)}
          </Grid>
        </Fragment>
      )}
      {isRoom && (
        <Flex
          direction='column'
          style={{ marginTop: '2em' }}
          alignMainAxis='flex-start'
          axisGap={300}
        >
          <Text as='p' size={300} fontWeight='700'>
            {greengrassDeviceTitle}
          </Text>
          {!greengrassCoreDevice && (
            <Box>
              <Text as='p'>{noGreengrassDevice}</Text>
            </Box>
          )}
          {greengrassCoreDevice && (
            <Fragment>
              <Box>
                <Text>
                  {greengrassDeviceStatus}: {greengrassCoreDevice.status}
                </Text>
              </Box>
              {greengrassCoreDevice?.effectiveDeployments?.length === 0 && (
                <Box>
                  <Text as='p'>{noGreengrassDeviceDeployments}</Text>
                </Box>
              )}
            </Fragment>
          )}
          <Flex axisGap={300} style={{ marginTop: '1em' }}>
            {!greengrassCoreDevice && (
              <Button
                size='tiny'
                variant='primary'
                onClick={onRefetchCoreDevice}
              >
                {greengrassRefetchCoreDevice}
              </Button>
            )}
            <Button
              size='tiny'
              variant='primary'
              onClick={onClickProvisioningConfig}
            >
              {showProvisioningConfigButton}
            </Button>

            {showCreateDeploymentButton && (
              <Button
                size='tiny'
                variant='primary'
                onClick={onStartDefaultDeployment}
              >
                {greengrassCreateDefaultDeployment}
              </Button>
            )}

            {showDeploymentsButton && (
              <Button
                size='tiny'
                variant='primary'
                onClick={onClickDeployentsModal}
              >
                {greengrassDeploymentsModalButton}
              </Button>
            )}
          </Flex>
        </Flex>
      )}
      <ProvisioningConfigModal
        selectedZone={selectedZone}
        provisioningConfig={provisioningConfig}
        showModal={showProvisioningConfigModal}
        setShowModal={setShowProvisioningConfigModal}
      />
      <DeploymentsModal
        deployments={greengrassCoreDevice?.effectiveDeployments}
        showModal={showDeploymentsModal}
        setShowModal={setShowDeploymentsModal}
      />
    </Flex>
  )
}

export default ZoneDetails

import { useState, useEffect, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { Switch, Route } from 'react-router-dom'

import {
  clearProduceThreshold,
  clearProduceThresholds
} from '@/slices/operations/produceThreshold'

import { getCurrentUser } from '@/reducers/selectors'

import {
  AVAILABLE_FEATURE_FLAGS,
  ENABLE_RECOMMENDATIONS_MANAGEMENT,
  hasFeatureFlagEnabled
} from '@/Util/FeatureFlagsUtils'

import { tableReducer, tableInitialState } from './state'

import Table from './Table'
import Form from './Form'

const MODULE_PATH = '/operations/admin/produce-threshold'

function ProduceThresholdManagement() {
  const dispatch = useDispatch()

  const currentUser = getCurrentUser()

  const [state, dispatchState] = useReducer(tableReducer, tableInitialState)

  const [userAllowed, setUserAllowed] = useState(false)

  useEffect(() => {
    return () => {
      dispatch(clearProduceThreshold())
      dispatch(clearProduceThresholds())
    }
  }, [dispatch])

  useEffect(() => {
    if (currentUser?.userName) {
      const enableRecommendationsManagement = hasFeatureFlagEnabled(
        currentUser,
        AVAILABLE_FEATURE_FLAGS[ENABLE_RECOMMENDATIONS_MANAGEMENT],
        null
      )
      setUserAllowed(enableRecommendationsManagement)
    }
  }, [currentUser])

  if (!userAllowed) {
    return null
  }

  return (
    <Switch>
      <Route
        path={[`${MODULE_PATH}/create`, `${MODULE_PATH}/update/:itemId`]}
        exact={true}
      >
        <Form
          state={state}
          dispatchState={dispatchState}
          modulePath={MODULE_PATH}
        />
      </Route>
      <Route path={MODULE_PATH} exact={true}>
        <Table
          state={state}
          dispatchState={dispatchState}
          modulePath={MODULE_PATH}
        />
      </Route>
    </Switch>
  )
}

export default ProduceThresholdManagement

import { useDispatch } from 'react-redux'
import { Fragment, useEffect } from 'react'
import { I18n } from 'aws-amplify'

import Flex from '@/primitives/Flex'
import Text from '@/primitives/Text'
import Box from '@/primitives/Box'

import {
  HOURLY_WEATHER,
  WEATHER_INTERVALS,
  WEATHER_RANGE,
  getTileHeader,
  getWeatherIcon
} from './utils'

import { fetchDashboardForecast } from '@/slices/weather/forecast'

import {
  getCurrentUser,
  getLang,
  getRootZone,
  getWeatherForecast
} from '@/reducers/selectors'

import { useDashboard } from '../../context'

import { convertCelciusToFahrenheit } from '@/Util/MeasurementUtils'

import {
  SETTING_CELCIUS_TO_FAHRENHEIT,
  processSettings
} from '@/Util/SettingsUtils'

export function WeatherViewComponent({ ...props }) {
  const dispatch = useDispatch()

  const currentUser = getCurrentUser()
  const rootZone = getRootZone()

  const { config } = props

  let weatherData = getWeatherForecast()
  const hasWeatherData = weatherData[config.interval]?.length > 0

  const enableFahrenheit = processSettings(
    currentUser?.settings,
    SETTING_CELCIUS_TO_FAHRENHEIT,
    currentUser?.userName,
    rootZone?.organizationId
  )

  if (enableFahrenheit && weatherData && Object.keys(weatherData).length > 0) {
    weatherData = Object.keys(weatherData).reduce((acc, key) => {
      acc[key] = weatherData[key]?.map(item => {
        return {
          ...item,
          temperature: convertCelciusToFahrenheit(item.temperature)
        }
      })
      return acc
    }, {})
  }

  const lang = getLang()

  const {
    state: {
      siteId,
      hierarchy: { timeZone }
    }
  } = useDashboard()

  useEffect(() => {
    if (siteId) {
      dispatch(fetchDashboardForecast({ siteId, period: 'h12' }))
    }
  }, [siteId, dispatch])

  return (
    <Fragment>
      <Flex
        className={'MosaicV2__Tile__Content__SubTitle'}
        alignMainAxis='space-between'
        axisGap={400}
      >
        <Text fontFamily={'mono'} size={200}>
          {WEATHER_RANGE[config.range].label} (
          {WEATHER_INTERVALS[config.interval].label})
        </Text>
        <Text fontFamily={'mono'} size={200}>
          {enableFahrenheit ? I18n.get('ºF') : I18n.get('ºC')}
        </Text>
      </Flex>

      <Box className={'MosaicV2__Tile__Content__Graph'}>
        <Box className={'MosaicV2__Tile__Content__Chart'}>
          {hasWeatherData && (
            <Flex
              direction={'row'}
              alignMainAxis={'space-evenly'}
              alignCrossAxis={'center'}
              wrap={'nowrap'}
            >
              {weatherData[config.interval]?.map(
                (
                  {
                    weatherAt,
                    temperature,
                    conditionCode,
                    sunriseAt,
                    sunsetAt
                  },
                  index
                ) => {
                  if (index > 6) return null
                  return (
                    <Flex
                      direction={'column'}
                      alignCrossAxis={'center'}
                      axisGap={500}
                      wrap={'nowrap'}
                      key={weatherAt}
                    >
                      <Text>
                        {getTileHeader(
                          weatherAt,
                          config.interval === HOURLY_WEATHER,
                          lang,
                          timeZone
                        )}
                      </Text>
                      {getWeatherIcon(
                        conditionCode,
                        weatherAt,
                        sunriseAt,
                        sunsetAt
                      )}
                      <Text>{Math.round(temperature)}</Text>
                    </Flex>
                  )
                }
              )}
            </Flex>
          )}
          {!hasWeatherData && (
            <Flex
              alignCrossAxis={'center'}
              alignMainAxis={'center'}
              style={{ flex: 1 }}
            >
              <Flex
                direction={'column'}
                alignCrossAxis={'center'}
                style={{ flex: 1 }}
                axisGap={200}
              >
                <Text
                  fontFamily={'mono'}
                  variant={'primary'}
                  tone={500}
                  size={600}
                  fontWeight={700}
                >
                  {I18n.get('No Data')}
                </Text>
              </Flex>
            </Flex>
          )}
        </Box>
      </Box>
    </Fragment>
  )
}

import { useEffect, useState } from 'react'
import ReactSelect from 'react-select'

import './index.scss'

const Select = ({
  value,
  options,
  name,
  className = '',
  isClearable = false,
  isDisabled = false,
  isLoading = false,
  isMulti = false,
  isRtl = false,
  isSearchable = false,
  placeholder = '',
  menuPlacement,
  menuPosition,
  onChange = () => {},
  controlStyles = {},
  menuListStyles = {},
  menuStyles = {},
  inputStyles = {},
  placeholderStyles = {},
  valueContainerStyles = {},
  dropdownIndicatorStyles = {},
  classNamePrefix = null,
  components = null,
  formatOptionLabel = null
}) => {
  const [currentValue, setCurrentValue] = useState(null)

  useEffect(() => {
    if (value === null) {
      setCurrentValue(null)
    } else if (isMulti) {
      setCurrentValue(
        options?.filter(option => {
          return (
            value?.findIndex(singleValue => {
              return singleValue === option?.value
            }) > -1
          )
        })
      )
    } else {
      const option = findSelectedOption(options, value)
      setCurrentValue(option)
    }
  }, [value, options, isMulti])

  return (
    <ReactSelect
      name={name}
      className={className}
      value={currentValue}
      options={options}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isMulti={isMulti}
      isRtl={isRtl}
      isSearchable={isSearchable}
      placeholder={placeholder}
      menuPlacement={menuPlacement}
      menuPosition={menuPosition}
      components={components}
      styles={{
        container: provided => ({
          ...provided,
          opacity: isDisabled ? 0.5 : 1,
          pointerEvents: 'all'
        }),
        valueContainer: provided => ({
          ...provided,
          ...valueContainerStyles // will override the above styles
        }),
        control: provided => ({
          ...provided,
          backgroundColor: 'transparent',
          border: '1px solid var(--ctx-theme-color-page-500)',
          borderRadius: '5px',
          cursor: isDisabled ? 'not-allowed' : 'auto',
          ...controlStyles // will override the above styles
        }),
        menu: provided => ({
          ...provided,
          ...menuStyles // will override the above styles
        }),
        menuList: provided => ({
          ...provided,
          backgroundColor: 'var(--ctx-theme-color-page-300)',
          ...menuListStyles // will override the above styles
        }),
        option: () => ({
          padding: '0.2rem 0.6rem',
          ':hover': {
            backgroundColor: 'var(--ctx-theme-color-page-500)'
          }
        }),
        singleValue: provided => ({
          ...provided,
          color: 'var(--ctx-theme-contrast-primary-100)'
        }),
        indicatorSeparator: () => ({
          display: 'none'
        }),
        dropdownIndicator: provided => ({
          ...provided,
          ':hover': {
            color: 'var(--ctx-theme-contrast-primary-100)'
          },
          ...dropdownIndicatorStyles
        }),
        multiValue: provided => ({
          ...provided,
          backgroundColor: 'var(--ctx-theme-color-page-300)',
          border: '1px solid var(--ctx-theme-color-page-300)'
        }),
        multiValueLabel: provided => ({
          ...provided,
          color: 'var(--ctx-theme-contrast-primary-100)'
        }),
        placeholder: provided => ({
          ...provided,
          ...placeholderStyles
        }),
        input: provided => ({
          ...provided,
          opacity: '0',
          color: 'var(--ctx-theme-contrast-primary-100)',
          paddingInlineStart: '0.2rem',
          ':focus-within': {
            opacity: '1'
          },
          ...inputStyles
        })
      }}
      classNamePrefix={classNamePrefix}
      onChange={onChange}
      formatOptionLabel={formatOptionLabel}
    />
  )
}

export default Select

const findSelectedOption = (options, value) => {
  for (let option of options) {
    const isGrouped = option.hasOwnProperty('options')
    if (isGrouped) {
      const selected = option.options.find(option => option.value === value)
      if (selected) return selected
    }
    if (!isGrouped) {
      if (option.value === value) {
        return option
      }
    }
  }
  return null
}

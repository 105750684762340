import { I18n } from 'aws-amplify'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { requestVarieties } from '@/actions/operations/variety'

import {
  getCurrentUser,
  getCurrentUserOrganizations,
  getOperationsVarieties,
  getOperationsVarietiesCount,
  getOperationsLoading
} from '@/reducers/selectors'

import {
  Flex,
  Button,
  Cell,
  Text,
  Column,
  Row,
  Slot,
  Table,
  Loader
} from '@/primitives'

import { Pagination } from '@/elements'

import HeaderV2 from '@/components/Operations/Shared/HeaderV2'
import TableFilter from './TableFilter'

import history from '../../../../history'

import {
  hasEditPermissions,
  RESOURCE_TYPE_OPERATIONS,
  RESOURCE_TYPE_OPERATIONS_MANAGEMENT
} from '@/Util/PermissionUtils'

function VarietyTable({ state, dispatchState, modulePath, backPath }) {
  const dispatch = useDispatch()

  const currentUser = getCurrentUser()
  const organizations = getCurrentUserOrganizations()
  const varieties = getOperationsVarieties()
  const varietiesCount = getOperationsVarietiesCount()
  const loading = getOperationsLoading()

  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(9)
  const [tableState, setTableState] = useState(state)
  const [canEdit, setCanEdit] = useState(false)
  const [showOrganization, setShowOrganization] = useState(false)

  useEffect(() => {
    if (currentUser.userName) {
      setCanEdit(
        hasEditPermissions(
          currentUser,
          RESOURCE_TYPE_OPERATIONS,
          RESOURCE_TYPE_OPERATIONS_MANAGEMENT
        )
      )
    }
  }, [currentUser])

  useEffect(() => {
    if (organizations.length > 1) {
      setShowOrganization(true)
    }
  }, [organizations])

  useEffect(() => {
    if (tableState.page !== state.page || varieties.length === 0) {
      setTableState({ ...state })
      fetchVarieties(state.page)
    }
  }, [state.page])

  useEffect(() => {
    if (
      tableState.produces !== state.produces ||
      tableState.organizations !== state.organizations
    ) {
      setTableState({ ...state })
      fetchVarieties(1)
      changePage(1)
    }
  }, [state.produces, state.organizations])

  const fetchVarieties = page => {
    if (state.produces.length > 0 && state.organizations.length > 0) {
      dispatch(
        requestVarieties({
          filter: {
            organizationId: state.organizations,
            produceId: state.produces
          },
          limit: pageSize,
          offset: page - 1
        })
      )
    }
  }

  const changePage = newPage => {
    dispatchState({
      page: newPage
    })
  }

  const onCreate = () => {
    history.push(`${modulePath}/create`)
  }

  const onEdit = id => {
    history.push(`${modulePath}/update/${id}`)
  }

  const getOrganizationName = organizationId => {
    for (let organization of organizations) {
      if (organization.id === organizationId) {
        return organization.name
      }
    }
    return null
  }

  return (
    <Fragment>
      <HeaderV2
        title={I18n.get('Manage Varieties')}
        buttonIcon={'add'}
        buttonText={I18n.get('Add Variety')}
        buttonCallback={onCreate}
        showButton={canEdit}
        backPath={backPath}
      >
        <TableFilter state={state} dispatchState={dispatchState} />
      </HeaderV2>
      <Loader isLoading={loading}>
        {varietiesCount === 0 && (
          <Flex className={'Operations__Table__Empty'}>
            <Text>{I18n.get('No entries')}</Text>
          </Flex>
        )}
        {varietiesCount > 0 && (
          <Table aria-colcount='5' className='Operations__Table'>
            <Slot name='head'>
              <Row>
                {showOrganization && (
                  <Column>{I18n.get('Organization')}</Column>
                )}
                <Column>{I18n.get('Produce')}</Column>
                <Column>{I18n.get('Variety')}</Column>
                <Column>{I18n.get('Code')}</Column>
                <Column></Column>
              </Row>
            </Slot>
            <Slot name='body'>
              {varieties.map(({ id, organizationId, name, code, produce }) => (
                <Row key={id}>
                  {showOrganization && (
                    <Cell columnName={I18n.get('Organization')}>
                      {getOrganizationName(organizationId)}
                    </Cell>
                  )}
                  <Cell columnName={I18n.get('Produce')}>{produce.name}</Cell>
                  <Cell columnName={I18n.get('Variety')}>{name}</Cell>
                  <Cell columnName={I18n.get('Code')}>{code}</Cell>
                  <Cell className='Operations__Table__Column__Actions'>
                    {canEdit && (
                      <Button
                        variant='info'
                        size='small'
                        iconBefore='edit'
                        onClick={() => onEdit(id)}
                      />
                    )}
                  </Cell>
                </Row>
              ))}
            </Slot>
          </Table>
        )}
        {varietiesCount > pageSize && (
          <Pagination
            type='simple'
            totalItems={varietiesCount}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            page={tableState.page}
            setPage={changePage}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        )}
      </Loader>
    </Fragment>
  )
}

export default VarietyTable

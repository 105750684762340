import { useState, useEffect, useReducer } from 'react'
import { Route, useParams, Switch } from 'react-router-dom'

import FertilizerInventoryTransfer from '../FertilizerInventoryTransfer'
import FertilizerPurchase from '../FertilizerPurchase'
import FertilizerInventoryCalibration from '../FertilizerInventoryCalibration'
import Table from './Table'

import reducer, { initialState } from './state'

const MODULE_PATH = '/operations/:zone+/inventory/fertilizer'

function FertilizerInventory() {
  const params = useParams()

  const [state, dispatchState] = useReducer(reducer, initialState)

  const [modulePath, setModulePath] = useState(MODULE_PATH)

  useEffect(() => {
    if (params?.zone) {
      setModulePath(MODULE_PATH.replace(':zone+', params.zone))
    }
  }, [params?.zone])

  return (
    <Switch>
      <Route path={`${MODULE_PATH}/transfers`}>
        <FertilizerInventoryTransfer
          organizationId={state.organizationId}
          storageFacilityId={state.storageFacilityId}
          fertilizerId={state.fertilizerId}
          backPath={modulePath}
        />
      </Route>
      <Route path={`${MODULE_PATH}/purchases`}>
        <FertilizerPurchase
          organizationId={state.organizationId}
          storageFacilityId={state.storageFacilityId}
          fertilizerId={state.fertilizerId}
          backPath={modulePath}
        />
      </Route>
      <Route path={`${MODULE_PATH}/calibration`}>
        <FertilizerInventoryCalibration
          fertilizerInventoryId={state.fertilizerInventoryId}
          backPath={modulePath}
        />
      </Route>
      <Route path={MODULE_PATH} exact={true}>
        <Table
          state={state}
          dispatchState={dispatchState}
          modulePath={modulePath}
        />
      </Route>
    </Switch>
  )
}

export default FertilizerInventory

import Options from './Options'
import { FlexV2, Separator } from '@/primitives'

export default function Header() {
  return (
    <FlexV2 direction='column' axisGap={300}>
      <Options />
      <Separator />
    </FlexV2>
  )
}

import { useState, Fragment, useReducer, useEffect } from 'react'
import { I18n } from 'aws-amplify'

import { Text, Flex, Form, Button, Input, Label, Icon, Box } from '@/primitives'
import { Select } from '@/elements'

import { getZonesHierarchy, getCurrentUser } from '@/reducers/selectors'

import { hasEditPermissions, RESOURCE_TYPE_ZONE } from '@/Util/PermissionUtils'
import { getZone } from '@/Util/ZoneUtils'
import AdminUtils from '@/Util/AdminUtils'
import useOrgLabel from '@/hooks/useOrgLabel'

const { zoneNameSchema } = AdminUtils

const stringsByLevel = label => {
  return {
    selectZone: label,
    selectZonePlaceholder: `${I18n.get(
      'Select an existing'
    )} ${label.toLowerCase()}`,
    addZoneButton: `${I18n.get('Add new')} ${label.toLowerCase()}`,
    zoneName: `${label} ${I18n.get('name')}`,
    parentLevel: label.toLowerCase()
  }
}

const defaultValues = {
  name: '',
  id: null
}

function reducer(state, action) {
  const { type, value } = action
  const updatedState = { ...state }

  switch (type) {
    case 'CLEAR_ALL':
      return defaultValues
    case 'UPDATE_ALL':
      return { ...updatedState, ...value }
    default:
      updatedState[type] = value
      return updatedState
  }
}

function getOptionsFromZone(parentZone) {
  return Object.keys(parentZone.children).map(zoneId => {
    const zoneOption = parentZone.children[zoneId]
    return { value: zoneId, label: zoneOption.name }
  })
}

const ZoneForm = ({ parentState, onClickContinue, onClickBack }) => {
  const { site, facility, room, currentView: level } = parentState

  const [showAddZone, setShowAddZone] = useState(false)
  const [zoneOptions, setZoneOptions] = useState([])
  const [errors, setErrors] = useState({})
  const [state, dispatch] = useReducer(reducer, defaultValues)

  const zonesHierarchy = getZonesHierarchy()
  const currentUser = getCurrentUser()
  const orgLabels = useOrgLabel(['site', 'facility', 'room'])
  const userCanEdit = hasEditPermissions(currentUser, RESOURCE_TYPE_ZONE)

  useEffect(() => {
    let options = []

    if (site.id && level === 'facility') {
      if (facility?.id) dispatch({ type: 'UPDATE_ALL', value: facility })
      const siteHierarchy = getZone(zonesHierarchy, site.id)

      if (siteHierarchy) {
        options = getOptionsFromZone(siteHierarchy)
        if (options?.length === 1) {
          const newValues = {
            id: options[0].value,
            name: options[0].label
          }
          dispatch({ type: 'UPDATE_ALL', value: newValues })
        }
        setZoneOptions(options)
      }
    }

    if (facility?.id && level === 'room') {
      if (room?.id) dispatch({ type: 'UPDATE_ALL', value: room })
      const facilityHierarchy = getZone(zonesHierarchy, site.id, facility.id)

      if (facilityHierarchy) {
        options = getOptionsFromZone(facilityHierarchy)
        if (options?.length === 1) {
          const newValues = {
            id: options[0].value,
            name: options[0].label
          }
          dispatch({ type: 'UPDATE_ALL', value: newValues })
        }
        setZoneOptions(options)
      }
    }

    const hasOptions = options.length > 0
    setShowAddZone(!hasOptions)
  }, [level, zonesHierarchy, facility, room, site.id])

  const onChange = ({ target: { name, value } }) => {
    dispatch({ type: name, value })
  }

  const onSelect = ({ value, label }) => {
    const newValues = {
      id: value,
      name: label
    }
    dispatch({ type: 'UPDATE_ALL', value: newValues })
  }

  const toggleAddZone = () => {
    dispatch({ type: 'CLEAR_ALL' })
    setErrors({})

    if (showAddZone && zoneOptions?.length === 1) {
      const newValues = {
        id: zoneOptions[0].value,
        name: zoneOptions[0].label
      }
      dispatch({ type: 'UPDATE_ALL', value: newValues })
    }
    setShowAddZone(!showAddZone)
  }

  const onSubmitForm = async e => {
    e.preventDefault()

    try {
      await zoneNameSchema().validate(state, {
        abortEarly: false
      })

      setErrors({})
      setZoneOptions([])
      setShowAddZone(!showAddZone)
      dispatch({ type: 'CLEAR_ALL' })
      onClickContinue(level, state)
    } catch (err) {
      if (err.inner) {
        let validationErrors = []
        err?.inner?.forEach(({ message, path }) => {
          validationErrors[path] = message
        })
        setErrors(validationErrors)
      }
    }
  }

  const goBack = () => {
    const prevLevel = level === 'facility' ? 'site' : 'facility'
    onClickBack(prevLevel)
  }

  const getHeadingText = () => {
    let introText = ''
    let locationPath = ''

    const { facility: facilityLabel, room: roomLabel } = orgLabels

    if (level === 'facility') {
      introText =
        showAddZone || zoneOptions?.length === 0
          ? // prettier-ignore
            `${I18n.get('Create a new')} ${facilityLabel.text} ${I18n.get('in')}`
          : `${I18n.get('Choose a')} ${facilityLabel.text} ${I18n.get('in')}`

      locationPath = ` ${site.name}`
    }

    if (level === 'room') {
      introText =
        showAddZone || zoneOptions?.length === 0
          ? `${I18n.get('Create a new')} ${roomLabel.text} ${I18n.get('at')}`
          : `${I18n.get('Choose a')} ${roomLabel.text} ${I18n.get('at')}`

      locationPath = ` ${site.name} / ${facility.name}`
    }

    return (
      <Box>
        <Text>{introText}</Text>
        <Text variant='primary' tone={600} fontWeight={900}>
          {locationPath}
        </Text>
      </Box>
    )
  }

  const getDescriptionText = () => {
    const message = I18n.get('This is a designated area inside of a')
    if (level === 'facility') {
      return `${message} ${orgLabels.site.text}`
    }

    if (level === 'room') {
      return `${message} ${orgLabels.facility.text}`
    }
  }

  const strings = stringsByLevel(orgLabels[level]?.text)

  return (
    <Fragment>
      <Box className='Heading'>
        <Text as='h4'>{getHeadingText()}</Text>
        <Text
          size={100}
          variant='page'
          tone={900}
          style={{ textAlign: 'left', display: 'inline-block' }}
        >
          {getDescriptionText()}
        </Text>
      </Box>
      <Flex
        style={{
          marginTop: '3rem',
          maxWidth: '30rem',
          width: '100%',
          margin: 'auto'
        }}
        alignMainAxis='center'
      >
        <Form autoComplete='off' className='Enrollment__Form'>
          {!showAddZone && (
            <Fragment>
              <Label className={zoneOptions?.length === 1 ? 'disabled' : ''}>
                <Text variant='page' tone={700}>
                  {strings.selectZone}
                </Text>
                <Select
                  className='Enrollment__Select'
                  name='id'
                  value={state.id}
                  placeholder={strings.selectZonePlaceholder}
                  options={zoneOptions}
                  onChange={onSelect}
                  isDisabled={zoneOptions?.length === 1}
                />
              </Label>
              {userCanEdit && (
                <Button
                  onClick={toggleAddZone}
                  variant='text'
                  size='small'
                  iconBefore='add'
                  style={{
                    margin: '-3rem 0 0 0',
                    backgroundColor: 'transparent',
                    padding: '0',
                    height: 'auto',
                    color: 'var(--ctx-theme-color-page-800)'
                  }}
                >
                  <Text variant='page' tone={800}>
                    {strings.addZoneButton}
                  </Text>
                </Button>
              )}
            </Fragment>
          )}
          {showAddZone && !userCanEdit && (
            <Text
              as='p'
              variant='danger'
              tone={600}
              style={{ marginTop: '2rem', textAlign: 'center' }}
            >
              {I18n.get(
                'You do not have permission to create zones. Please talk to your administrator.'
              )}
            </Text>
          )}
          {showAddZone && userCanEdit && (
            <Label>
              <Text variant={'page'} tone={700}>
                {strings.zoneName}
              </Text>
              <Input
                name='name'
                value={state.name}
                onChange={onChange}
                className={errors.name ? 'Error' : ''}
              />
            </Label>
          )}
          {errors.name && (
            <Fragment>
              <Icon
                className='Enrollment__Form__InputErrorIcon'
                name='error'
                variant='error'
                tone={500}
              />
              <Text className='Enrollment__Form__InputError' variant='error'>
                {errors.name}
              </Text>
            </Fragment>
          )}
        </Form>
        <Flex
          axisGap={400}
          alignMainAxis='space-between'
          className='Button__Container'
        >
          {((showAddZone && zoneOptions?.length === 0) || !showAddZone) && (
            <Button
              variant='neutral'
              size='small'
              onClick={goBack}
              className='Cancel__Button'
            >
              {`${I18n.get('Back to')} ${strings.parentLevel}`}
            </Button>
          )}
          {showAddZone && zoneOptions?.length > 0 && (
            <Button
              variant='danger'
              size='small'
              onClick={toggleAddZone}
              className='Cancel__Button'
            >
              {I18n.get('Cancel')}
            </Button>
          )}
          <Button
            variant='primary'
            size='small'
            disabled={!state.name && !state.id}
            onClick={onSubmitForm}
            className='Continue__Button'
          >
            {I18n.get('Continue')}
          </Button>
        </Flex>
      </Flex>
    </Fragment>
  )
}

export default ZoneForm

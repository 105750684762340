import { graphqlOperation } from 'aws-amplify'
import { getCognitoData, makeGraphqlRequest } from '../utils'

// const FERTIGATION_GRAPHQL_URL = 'http://localhost:4011/fertigation/graphql'
const FERTIGATION_GRAPHQL_URL = `${window.ENV.HTTP_URL}/fertigation/graphql`

export async function makeFertigationApiCall({
  query,
  mutation,
  params = {},
  queryKey = null,
  resultKey = null
}) {
  const { id, idToken } = await getCognitoData()
  const operation = graphqlOperation(query || mutation, params)

  const { data, error } = await makeGraphqlRequest(
    FERTIGATION_GRAPHQL_URL,
    operation,
    idToken,
    id
  )
  if (queryKey && resultKey) {
    return { [resultKey]: data?.[queryKey], error }
  }
  return { data, error }
}

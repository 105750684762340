import { makeOperationsApiCall } from './utils'

/* MUTATIONS */

export const createCity = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createCity(
        $organizationId: String!
        $countryId: String!
        $regionId: String!
        $name: String!
      ) {
        createCity(
          organizationId: $organizationId
          countryId: $countryId
          regionId: $regionId
          name: $name
        ) {
          id
          organizationId
          name
          countryId
          country {
            name
          }
          regionId
          region {
            name
          }
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'createCity',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updateCity = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateCity(
        $cityId: String!
        $organizationId: String!
        $countryId: String!
        $regionId: String!
        $name: String!
      ) {
        updateCity(
          cityId: $cityId
          organizationId: $organizationId
          countryId: $countryId
          regionId: $regionId
          name: $name
        ) {
          id
          organizationId
          name
          countryId
          country {
            name
          }
          regionId
          region {
            name
          }
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'updateCity',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

/* QUERIES */

export const getCities = async params => {
  try {
    const query = /* GraphQL */ `
      query getCities(
        $limit: Int
        $offset: Int
        $order: OrderEnum
        $filter: GetCitiesFilter
      ) {
        getCities(
          limit: $limit
          offset: $offset
          order: $order
          filter: $filter
        ) {
          cities {
            id
            organizationId
            name
            countryId
            country {
              name
            }
            regionId
            region {
              name
            }
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getCities',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getCity = async params => {
  try {
    const query = /* GraphQL */ `
      query getCity($cityId: String!) {
        getCity(cityId: $cityId) {
          id
          organizationId
          name
          countryId
          regionId
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getCity',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

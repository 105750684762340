import { Fragment, useState, useEffect, createElement } from 'react'
import { AuthState } from '@aws-amplify/ui-components'

import SignIn from './SignIn'
import SignUp from './SignUp'
import ConfirmSignUp from './ConfirmSignUp'
import ForgotPassword from './ForgotPassword'
import RequireNewPassword from './RequireNewPassword'
import VerifyContact from './VerifyContact'
import ConfirmSignIn from './ConfirmSignIn'

import useAuthChannel from '@/hooks/useAuthChannel'

const SignedIn = () => {
  // temporary fix to prevent the sign in page from displaying
  return <Fragment />
}

const components = {
  signedIn: SignedIn,
  signIn: SignIn,
  forgotPassword: ForgotPassword,
  signUp: SignUp,
  confirmSignUp: ConfirmSignUp,
  requireNewPassword: RequireNewPassword,
  verifyContact: VerifyContact,
  confirmSignIn: ConfirmSignIn
}

function Auth() {
  const [hubData, setHubData] = useState(null)
  const [authChannel, hubListenerCancel] = useAuthChannel()

  useEffect(() => {
    return () => hubListenerCancel()
  }, [hubListenerCancel])

  useEffect(() => {
    switch (authChannel.message) {
      case AuthState.SignedIn:
        setHubData({ component: 'signedIn', ...authChannel.data })
        break
      case AuthState.ConfirmSignIn:
        setHubData({ component: 'confirmSignIn', ...authChannel.data })
        break
      case 'requireNewPassword':
        setHubData({ component: 'requireNewPassword', ...authChannel.data })
        break
      case AuthState.VerifyContact:
        setHubData({ component: 'verifyContact', ...authChannel.data })
        break
      case AuthState.SignUp:
        setHubData({ component: 'signUp', ...authChannel.data })
        break
      case AuthState.ConfirmSignUp:
        setHubData({ component: 'confirmSignUp', ...authChannel.data })
        break
      case AuthState.ResetPassword:
        setHubData({ component: 'forgotPassword', ...authChannel.data })
        break
      case 'success':
        setHubData({ component: 'signIn', ...authChannel.data })
        break
      default:
        setHubData({ component: 'signIn', ...authChannel.data })
        break
    }
  }, [authChannel])

  const showComponent =
    hubData?.component && Object.keys(components).includes(hubData?.component)

  return (
    <Fragment>
      <div>
        {showComponent &&
          createElement(components[hubData.component], { hubData, setHubData })}
      </div>
    </Fragment>
  )
}

export default Auth

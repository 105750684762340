export const tableInitialState = {
  organizationId: null,
  countryId: null,
  regionId: null,
  cityId: null,
  organizations: [],
  countries: [],
  regions: [],
  page: 1
}

export const tableReducer = (state, action) => {
  if (action.state) {
    return {
      ...state,
      ...action.state
    }
  } else if (action.page) {
    return {
      ...state,
      page: action.page
    }
  } else if (action.organizations) {
    return {
      ...state,
      organizations: action.organizations
    }
  } else if (action.countries) {
    return {
      ...state,
      countries: action.countries,
      regions: []
    }
  } else if (action.regions) {
    return {
      ...state,
      regions: action.regions
    }
  }
  return state
}

export const formInitalState = {
  id: null,
  organizationId: '',
  countryId: '',
  regionId: '',
  name: ''
}

export const formReducer = (state, action) => {
  if (action.type === 'update') {
    return { ...state, [action.name]: action.value }
  }
  if (action.type === 'set-state') {
    return action.state
  }
  return state
}

import { updateList } from '../util'

import {
  RECEIVE_ALL_REGIONS,
  REQUEST_ALL_REGIONS,
  RECEIVE_REGIONS,
  REQUEST_REGIONS,
  CLEAR_REGIONS,
  CLEAR_REGION,
  REQUEST_REGION,
  RECEIVE_REGION,
  CREATE_REGION,
  REGION_CREATED,
  UPDATE_REGION,
  REGION_UPDATED,
  SET_REGION
} from '@/actions/operations/region'

export const regionInitialState = {
  allRegions: [],
  regions: [],
  region: {},
  regionsCount: 0,
  loading: false,
  error: null
}

const handlers = {
  [SET_REGION]: (state, action) => ({
    ...state,
    region: action.region
  }),
  [CLEAR_REGION]: state => ({
    ...state,
    region: {},
    error: null
  }),
  [CLEAR_REGIONS]: state => ({
    ...state,
    regions: [],
    error: null
  }),
  [REQUEST_ALL_REGIONS]: state => ({
    ...state,
    error: null,
    loading: true
  }),
  [RECEIVE_ALL_REGIONS]: (state, action) => ({
    ...state,
    allRegions: !action.error ? action.regions : [],
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_REGION]: state => ({
    ...state,
    region: {},
    error: null,
    loading: true
  }),
  [RECEIVE_REGION]: (state, action) => ({
    ...state,
    region: !action.error ? action.region : state.region,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_REGIONS]: state => ({
    ...state,
    regions: [],
    error: null,
    loading: true
  }),
  [RECEIVE_REGIONS]: (state, action) => ({
    ...state,
    regions: !action.error ? action.regions : [],
    regionsCount: !action.error ? action.count : 0,
    error: action.error ?? null,
    loading: false
  }),
  [CREATE_REGION]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [REGION_CREATED]: (state, action) => ({
    ...state,
    region: !action.error ? {} : state.region,
    regions: updateList(action.error, state.regions, action.region, true),
    allRegions: updateList(action.error, state.allRegions, action.region, true),
    regionsCount: !action.error ? state.regionsCount + 1 : state.regionsCount,
    error: action.error ?? null,
    loading: false
  }),
  [UPDATE_REGION]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [REGION_UPDATED]: (state, action) => ({
    ...state,
    region: !action.error ? {} : state.region,
    regions: updateList(action.error, state.regions, action.region, false),
    allRegions: updateList(
      action.error,
      state.allRegions,
      action.region,
      false
    ),
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

import { useReducer } from 'react'
import { useDispatch } from 'react-redux'

import Container from './Container'

import { Flex, Form, Input, Button, Text, Loader, Label } from '@/primitives'

import { showBanner } from '@/slices/util'

import { useAuth } from '@/contexts/auth-context'

import { getInputClassName, insertError } from './Utils'

import { requireNewPasswordSubmitSchema } from '../../validations'

import Strings from './Strings'

const initialState = {
  password: '',
  confirmPassword: '',
  loading: false,
  errors: {}
}

const objectWithProperties = (obj, keys) => {
  const target = {}
  for (const key in obj) {
    if (keys.indexOf(key) === -1) {
      continue
    }
    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
      continue
    }
    target[key] = obj[key]
  }

  return target
}

const reducer = (state, action) => {
  if (action.type === 'update') {
    if (action.name !== 'errors' && state.errors.hasOwnProperty(action.name)) {
      const errors = { ...state.errors }
      delete errors[action.name]
      return { ...state, errors, [action.name]: action.value }
    }

    return { ...state, [action.name]: action.value }
  }
  return state
}

const RequireNewPassword = ({ hubData }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const auth = useAuth()
  const reduxDispatch = useDispatch()
  const strings = Strings()

  function onChangeInput(e) {
    const { name, value } = e.target
    dispatch({ type: 'update', name, value })
  }

  const onChangePassword = async e => {
    e.preventDefault()
    setErrors()

    try {
      setLoading(true)
      const user = hubData
      const { password, confirmPassword } = state
      await requireNewPasswordSubmitSchema.validate(
        { password, confirmPassword },
        { abortEarly: false }
      )
      setErrors()
      const { requiredAttributes } = user.challengeParam
      const attrs = objectWithProperties(state, requiredAttributes)
      await auth.completeNewPassword(password, attrs)
    } catch (err) {
      let errors = {}

      if (err?.inner) {
        err.inner.forEach(innerErr => {
          errors[innerErr.path] = innerErr.message
        })
      }

      if (!err.inner && err.message) {
        reduxDispatch(
          showBanner({
            type: 'error',
            message: err.message,
            show: true
          })
        )
      }
      setErrors(errors)
      setLoading(false)
    }
  }

  const setErrors = (errors = {}) => {
    dispatch({ type: 'update', name: 'errors', value: errors })
  }

  const setLoading = loading => {
    dispatch({ type: 'update', name: 'loading', value: loading })
  }

  return (
    <Loader isLoading={state.loading}>
      <Container title={strings.requireNewPasswordTitle}>
        <Text
          size={100}
          variant='page'
          tone={900}
          style={{ marginBottom: '2rem' }}
        >
          {strings.passwordPolicy}
        </Text>
        <Form className='Auth__Form' onSubmit={onChangePassword}>
          <Flex axisGap={400} direction='column'>
            <Flex direction='column'>
              <Label className='Auth__Form__Label'>{strings.newPassword}</Label>
              <Input
                type='password'
                name='password'
                value={state.password}
                onChange={onChangeInput}
                className={getInputClassName('password', state.errors)}
              />
              {insertError(state?.errors?.password)}
            </Flex>
            <Flex direction='column'>
              <Label className='Auth__Form__Label'>
                {strings.reEnterNewPassword}
              </Label>
              <Input
                type='password'
                name='confirmPassword'
                value={state.confirmPassword}
                onChange={onChangeInput}
                className={getInputClassName('confirmPassword', state.errors)}
              />
              {insertError(state?.errors?.confirmPassword)}
            </Flex>
            <Button
              variant='primary'
              type='submit'
              className='Auth__Form__PrimaryButton'
            >
              {strings.changePasswordBtn}
            </Button>
          </Flex>
        </Form>
      </Container>
    </Loader>
  )
}

export default RequireNewPassword

import { Fragment, useState } from 'react'
import { I18n } from 'aws-amplify'
import { Link } from 'react-router-dom'

import { Button, FlexV2, Symbol, Text } from '@/primitives'

import DeleteNotificationModal from '../DeleteNotificationModal'
import style from '../Card.module.scss'
import { getCurrentUser } from '@/reducers/selectors'

export default function NoDataCard({ item }) {
  const currentUser = getCurrentUser()

  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const onClickDeleteNotification = e => {
    e.preventDefault()
    setShowDeleteModal(true)
  }

  let measurements = Object.values(item)
  let sensor = Object.values(item)[0]
  const isManager = currentUser?.managers.some(manager =>
    sensor?.thresholdZonePath?.includes(manager.zoneId)
  )

  const { sensorName, sensorType, sensorId, sensorZonePath } = sensor
  return (
    <FlexV2
      direction='column'
      alignMainAxis='space-between'
      className={style.card}
      axisGap={400}
    >
      <FlexV2 alignCrossAxis='center' axisGap={300}>
        <Text>
          {I18n.get('Sensor')}{' '}
          <Link to={`/zones${sensorZonePath}/sensor/${sensorType}/${sensorId}`}>
            {sensorName}
          </Link>{' '}
          {I18n.get('is not sending data for:')}
          <FlexV2 direction='column' axisGap={100} style={{ padding: '0.5em' }}>
            {measurements.map((measurement, index) => {
              return (
                <FlexV2
                  key={sensorName + measurement.measurementName + index}
                  axisGap={300}
                >
                  <Symbol
                    name='signal_disconnected'
                    size={300}
                    style={{ flexShrink: 0 }}
                    variant={'danger'}
                    tone={500}
                  />
                  <Text>{measurement.measurementName}</Text>
                </FlexV2>
              )
            })}
          </FlexV2>
        </Text>
      </FlexV2>

      {isManager && (
        <Fragment>
          <Button
            style={{ width: 'min-content' }}
            disabled={false}
            onClick={onClickDeleteNotification}
            variant={'danger'}
            iconBefore={'delete'}
            size='small'
          >
            {I18n.get('Delete Notification')}
          </Button>
          {showDeleteModal && (
            <DeleteNotificationModal
              statuses={measurements}
              sensor={sensor}
              show={showDeleteModal}
              setShow={setShowDeleteModal}
            />
          )}
        </Fragment>
      )}
    </FlexV2>
  )
}

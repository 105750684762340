import { makeOperationsApiCall } from './utils'

export const createReturnItem = async params => {
  try {
    const query = /* GraphQL */ `
      mutation createReturnItem(
        $id: String!
        $returnId: String!
        $productId: String!
        $quantityReturned: Int!
        $reason: ReturnItemReason!
      ) {
        createReturnItem(
          id: $id
          returnId: $returnId
          productId: $productId
          quantityReturned: $quantityReturned
          reason: $reason
        ) {
          id
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'createReturnItem',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updateReturnItem = async params => {
  try {
    const query = /* GraphQL */ `
      mutation updateReturnItem(
        $returnItemId: String!
        $returnId: String!
        $productId: String!
        $quantityReturned: Int!
        $reason: ReturnItemReason!
      ) {
        updateReturnItem(
          returnItemId: $returnItemId
          returnId: $returnId
          productId: $productId
          quantityReturned: $quantityReturned
          reason: $reason
        ) {
          id
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'updateReturnItem',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const deleteReturnItem = async params => {
  try {
    const query = /* GraphQL */ `
      mutation deleteReturnItem($returnItemId: String!) {
        deleteReturnItem(returnItemId: $returnItemId) {
          id
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'deleteReturnItem',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

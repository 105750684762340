import { I18n } from 'aws-amplify'
import { object, string, date, array } from 'yup'

import { SCHEMA as paymentsSchema } from './Payments/config'

export const FIELDS = {
  id: 'id',
  organizationId: 'organizationId',
  orderDate: 'orderDate',
  purchaseOrder: 'purchaseOrder',
  customerId: 'customerId',
  branchId: 'branchId',
  status: 'status',
  currencyId: 'currencyId',
  invoiceNumber: 'invoiceNumber',
  paymentMethodId: 'paymentMethodId',
  paymentTermId: 'paymentTermId',
  invoiceStatus: 'invoiceStatus',
  payments: 'payments',
  saleItems: 'saleItems',
  saleShipments: 'saleShipments'
}

export const SCHEMA = orderDate => {
  return object().shape({
    organizationId: string().required(
      I18n.get('An organization must be selected.')
    ),
    orderDate: date()
      .typeError('An order date must be provided.')
      .max(new Date(), I18n.get('Order date cannot be set in the future.'))
      .required(I18n.get('An order date must be selected.')),
    purchaseOrder: string().required(I18n.get('A customer PO is required.')),
    customerId: string().required(I18n.get('Please select a customer.')),
    branchId: string().required(I18n.get('Please select a branch.')),
    status: string().required('Please select a status.'),
    currencyId: string().required(I18n.get('Please select a currency.')),
    invoiceNumber: string(),
    paymentMethodId: string().required(
      I18n.get('Please select a payment method.')
    ),
    paymentTermId: string().required(
      I18n.get('Please select the payment terms.')
    ),
    invoiceStatus: string().required('Please select an invoice status.'),
    payments: array().of(paymentsSchema(orderDate)),
    saleItems: array().min(1, I18n.get('At least one item is required.'))
  })
}

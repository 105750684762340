import { I18n } from 'aws-amplify'

import ProduceThreshold from './ProduceThreshold'
import ProduceThresholdMessage from './ProduceThresholdMessage'

const routes = [
  {
    path: '/operations/admin/produce-threshold',
    text: I18n.get('Produce Thresholds'),
    Component: ProduceThreshold
  },
  {
    path: '/operations/admin/produce-threshold-message',
    text: I18n.get('Produce Threshold Messages'),
    Component: ProduceThresholdMessage
  }
]

export default routes

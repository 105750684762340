import { call, put, select, takeLatest } from 'redux-saga/effects'

import {
  getRegions,
  getRegion,
  createRegion,
  updateRegion
} from '@/api/operations/region'

import {
  REQUEST_ALL_REGIONS,
  REQUEST_REGIONS,
  REQUEST_REGION,
  CREATE_REGION,
  UPDATE_REGION,
  receiveAllRegions,
  receiveRegions,
  receiveRegion,
  regionCreated,
  regionUpdated
} from '@/actions/operations/region'

import { displayBanner } from '../utils'
import { getOperationsAllRegions } from './utils'

import Strings from './Strings'

const strings = Strings()

function* sendCreateRegion(action) {
  const { data, error = null } = yield call(createRegion, action.params)
  yield put(displayBanner(error, strings.regionCreated))
  yield put(regionCreated(data, error))
}

export function* watchCreateRegion() {
  yield takeLatest(CREATE_REGION, sendCreateRegion)
}

function* sendUpdateRegion(action) {
  const { data, error = null } = yield call(updateRegion, action.params)
  yield put(displayBanner(error, strings.regionUpdated))
  yield put(regionUpdated(data, error))
}

export function* watchUpdateRegion() {
  yield takeLatest(UPDATE_REGION, sendUpdateRegion)
}

function* sendRequestRegion(action) {
  const { data: region, error = null } = yield call(getRegion, action.params)
  yield put(receiveRegion(region, error))
}

export function* watchRequestRegion() {
  yield takeLatest(REQUEST_REGION, sendRequestRegion)
}

function* sendRequestRegions(action) {
  const { data, error = null } = yield call(getRegions, action.params)
  const { regions = [], count = 0 } = data
  yield put(receiveRegions(regions, count, error))
}

export function* watchRequestRegions() {
  yield takeLatest(REQUEST_REGIONS, sendRequestRegions)
}

function* sendRequestAllRegions() {
  const allRegions = yield select(getOperationsAllRegions)
  if (allRegions.length > 0) {
    yield put(receiveAllRegions(allRegions, allRegions.length, null))
  } else {
    const { data, error = null } = yield call(getRegions, {})
    const { regions = [], count = 0 } = data
    yield put(receiveAllRegions(regions, count, error))
  }
}

export function* watchRequestAllRegions() {
  yield takeLatest(REQUEST_ALL_REGIONS, sendRequestAllRegions)
}

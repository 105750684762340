import React, { Fragment, memo, useEffect, useMemo, useState } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { ResponsiveLine } from '@nivo/line'

import { Card, FlexV2, Text, Button } from '@/primitives'
import { getZonesHierarchy, getMeasurements } from '@/reducers/selectors'
import useMediaQuery from '@/hooks/useMediaQuery'

import {
  COMPARISON_TYPES,
  RANGE_FORMATS,
  RANGE_FORMAT_LIMITS
} from '../Utils/config'
import {
  calculateSpacing,
  formatXLabel,
  getChartDateParams,
  getChartProps,
  getColor,
  getPrecision,
  getTicks,
  getMeasurementUnit
} from '../Utils/ChartUtils'
import {
  getProcessedChartData,
  getProcessedDateFilterChartData
} from '../Utils/DataUtils'

import Strings from '../Strings'
import SliceTooltip from './SliceTooltip'
import NoDataCard from './Cards/NoDataCard'

import { getCurrentUser, getRootZone } from '@/reducers/selectors'
import {
  SETTING_CELCIUS_TO_FAHRENHEIT,
  processSettings
} from '@/Util/SettingsUtils'

import theme from '@/elements/Nivo/theme.json'

const Chart = ({ measurementId, data, onExportChart, chartState }) => {
  const strings = Strings()

  const zonesHierarchy = getZonesHierarchy()
  const measurements = getMeasurements()

  const [rangeFormat, setRangeFormat] = useState('MM/dd')

  const isAboveTabletBreakpoint = useMediaQuery('min-width: 770px')
  const isAboveLaptopBreakpoint = useMediaQuery('min-width: 1450px')

  const currentUser = getCurrentUser()
  const rootZone = getRootZone()

  const enableFahrenheit = processSettings(
    currentUser?.settings,
    SETTING_CELCIUS_TO_FAHRENHEIT,
    currentUser.userName,
    rootZone?.organizationId
  )

  const measurement = useMemo(() => {
    if (measurementId && measurements.length > 0) {
      return measurements.find(measurement => measurement.id === measurementId)
    }
  }, [measurements, measurementId])
  const measurementUnit = getMeasurementUnit(measurement, enableFahrenheit)

  const xScaleType =
    chartState.comparisonType === COMPARISON_TYPES.DATE_RANGES
      ? 'linear'
      : 'time'

  const { fromDate, toDate, timeInterval, dateRange, timeZone } =
    getChartDateParams(chartState)

  useEffect(() => {
    if (dateRange && RANGE_FORMATS[dateRange]) {
      setRangeFormat(RANGE_FORMATS[dateRange])
    } else {
      const dateDiff = toDate - fromDate
      const rangeFormats = Object.keys(RANGE_FORMAT_LIMITS)
      for (const format of rangeFormats) {
        if (dateDiff <= RANGE_FORMAT_LIMITS[format]) {
          setRangeFormat(format)
          break
        }
      }
    }
  }, [dateRange, fromDate, toDate])

  let { processedData, hasData } =
    chartState.comparisonType === COMPARISON_TYPES.LOCATION_RANGES
      ? getProcessedChartData(
          data,
          chartState,
          zonesHierarchy,
          measurementId,
          enableFahrenheit
        )
      : getProcessedDateFilterChartData(data, chartState, enableFahrenheit)

  function onExport(e) {
    e.preventDefault()

    const newNivoProps = getChartProps({
      colors: getColor,
      data: processedData,
      precision: getPrecision(timeInterval),
      tickValues: getTicks(
        timeInterval,
        rangeFormat,
        !isAboveTabletBreakpoint,
        !isAboveLaptopBreakpoint
      ),
      xScaleType,
      formatFunction: value =>
        formatXLabel(value, chartState, rangeFormat, timeZone),
      legend: measurementUnit ?? '',
      sliceTooltip: ({ slice }) => (
        <SliceTooltip
          slice={slice}
          timezone={timeZone}
          measurementUnit={measurementUnit}
          interval={timeInterval}
          comparisonType={chartState.comparisonType}
        />
      ),
      splitLegendsTwoRows: processedData.length > 4,
      itemsSpacing: calculateSpacing(processedData, chartState.comparisonType)
    })

    onExportChart(
      cloneDeep({
        measurement: {
          ...measurement,
          unit: measurementUnit
        },
        nivoProps: newNivoProps,
        toDate,
        fromDate,
        timeZone
      })
    )
  }

  let nivoProps = {}

  if (hasData) {
    nivoProps = getChartProps({
      colors: getColor,
      data: processedData,
      precision: getPrecision(timeInterval),
      tickValues: getTicks(
        timeInterval,
        rangeFormat,
        !isAboveTabletBreakpoint,
        !isAboveLaptopBreakpoint
      ),
      xScaleType,
      formatFunction: value =>
        formatXLabel(value, chartState, rangeFormat, timeZone),
      legend: measurementUnit ?? '',
      sliceTooltip: ({ slice }) => (
        <SliceTooltip
          slice={slice}
          timezone={timeZone}
          measurementUnit={measurementUnit}
          interval={timeInterval}
          comparisonType={chartState.comparisonType}
        />
      ),
      splitLegendsTwoRows: processedData.length > 4,
      itemsSpacing: calculateSpacing(processedData, chartState.comparisonType)
    })
  }

  // console.log('processedData', processedData)

  return (
    <Card className='Historic__Chart__Card' style={{ flex: '1 1 40%' }}>
      <FlexV2
        alignMainAxis='space-between'
        alignCrossAxis='center'
        className='Historic__Chart__Card--Title'
        direction='row'
      >
        <Text as='p' fontWeight={700} size={300}>
          {measurement?.shortName}
        </Text>
        <Text as='p'>{measurementUnit}</Text>
      </FlexV2>

      {!hasData && <NoDataCard />}
      {hasData && (
        <Fragment>
          <FlexV2
            className='Historic__Chart__Card--Container'
            id={`${measurementId}-${timeInterval}`}
          >
            <ResponsiveLine {...nivoProps} theme={theme} />
          </FlexV2>
          <FlexV2 style={{ margin: '0 1em 1em 1em' }} alignMainAxis='flex-end'>
            <Button
              size='small'
              type='button'
              variant='primary'
              onClick={onExport}
            >
              {strings.downloadGraph}
            </Button>
          </FlexV2>
        </Fragment>
      )}
    </Card>
  )
}

export default memo(Chart)

import { Button, Cell, Row } from '../../../../../primitives'

import history from '../../../../../history'
import Strings from '../Strings'

function TableRow({ item, dispatchState, modulePath }) {
  const strings = Strings()

  const { id, storageFacility, packageUnit, quantity } = item

  const onCalibration = () => {
    dispatchState({
      state: {
        packageUnitInventoryId: id
      }
    })
    history.push(`${modulePath}/calibration`)
  }

  return (
    <Row>
      <Cell columnName={strings.storageFacility}>{storageFacility.name}</Cell>
      <Cell columnName={strings.packageUnit}>{packageUnit.name}</Cell>
      <Cell columnName={strings.quantity}>{quantity}</Cell>
      <Cell className='Operations__Table__Column__Actions'>
        <Button
          variant='info'
          size='small'
          iconBefore='scale'
          onClick={onCalibration}
        />
      </Cell>
    </Row>
  )
}

export default TableRow

import { makeActionCreator } from '@/Util/ReduxUtils'

export const CLEAR_DATABOARD = 'CLEAR_DATABOARD'
export const clearDataboard = makeActionCreator(CLEAR_DATABOARD)

export const REQUEST_DATABOARD_HARVESTS = 'REQUEST_DATABOARD_HARVESTS'
export const requestDataboardHarvests = makeActionCreator(
  REQUEST_DATABOARD_HARVESTS,
  'params'
)

export const RECEIVE_DATABOARD_HARVESTS = 'RECEIVE_DATABOARD_HARVESTS'
export const receiveDataboardHarvests = makeActionCreator(
  RECEIVE_DATABOARD_HARVESTS,
  'harvests',
  'harvestArgs',
  'error'
)

export const REQUEST_DATABOARD_CROPS = 'REQUEST_DATABOARD_CROPS'
export const requestDataboardCrops = makeActionCreator(
  REQUEST_DATABOARD_CROPS,
  'params'
)

export const RECEIVE_DATABOARD_CROPS = 'RECEIVE_DATABOARD_CROPS'
export const receiveDataboardCrops = makeActionCreator(
  RECEIVE_DATABOARD_CROPS,
  'crops',
  'cropArgs',
  'error'
)

export const REQUEST_DATABOARD_SITE_HIERARCHY =
  'REQUEST_DATABOARD_SITE_HIERARCHY'
export const requestDataboardSiteHierarchy = makeActionCreator(
  REQUEST_DATABOARD_SITE_HIERARCHY,
  'params'
)

export const RECEIVE_DATABOARD_SITE_HIERARCHY =
  'RECEIVE_DATABOARD_SITE_HIERARCHY'
export const receiveDataboardSiteHierarchy = makeActionCreator(
  RECEIVE_DATABOARD_SITE_HIERARCHY,
  'zones',
  'error'
)

import { I18n } from 'aws-amplify'

const Strings = () => ({
  actor: I18n.get('Actor'),
  actorType: I18n.get('Actor Type'),
  date: I18n.get('Date'),
  event: I18n.get('Event'),
  service: I18n.get('Service'),
  context: I18n.get('Context'),
  action: I18n.get('Action'),
  details: I18n.get('Details'),
  filters: I18n.get('Filters'),
  general: I18n.get('General'),
  organization: I18n.get('Organization'),
  inspect: I18n.get('Inspect'),
  notApplicable: I18n.get('N/A'),
  modalTitle: I18n.get('Inspect Logs'),
  modalSubtitle: I18n.get('View details of the selected log'),
  noAuditLogs: I18n.get('No audit logs found'),
  updatedAttributes: I18n.get('Updated Attributes'),
  auditTab: I18n.get('Audit Logs'),
  auditLogSubtitle: I18n.get('View audit logs'),
  filterByActorType: I18n.get('Filter by actor type'),
  filterByOrganization: I18n.get('Filter by organization'),
  filterByAction: I18n.get('Filter by action'),
  filterByService: I18n.get('Filter by service'),
  searchContext: I18n.get('Search context'),
  filterByActor: I18n.get('Filter by actor'),
  filterBy: I18n.get('Filter by'),
  resetAllFields: I18n.get('Reset all fields'),
  refreshData: I18n.get('Refresh data'),
  locationAndService: I18n.get('Location & Service')
})

export const ServiceStrings = () => ({
  operations: I18n.get('Operations'),
  management: I18n.get('Management'),
  thresholds: I18n.get('Thresholds'),
  'ts-data': I18n.get('Timestream Data'),
  device: I18n.get('Device'),
  calibration: I18n.get('Calibration'),
  video: I18n.get('Video'),
  weather: I18n.get('Weather'),
  manager: I18n.get('Manager'),
  'device-management': I18n.get('Device Management')
})

export const ActionStrings = () => ({
  create: I18n.get('Create'),
  update: I18n.get('Update'),
  delete: I18n.get('Delete')
})

export const ActorTypeStrings = () => ({
  user: I18n.get('User'),
  system: I18n.get('System')
})

export default Strings

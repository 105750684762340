import { makeActionCreator } from '@/Util/ReduxUtils'

export const CLEAR_SALE = 'CLEAR_SALE'
export const clearSale = makeActionCreator(CLEAR_SALE)

export const CREATE_SALE = 'CREATE_SALE'
export const createSale = makeActionCreator(CREATE_SALE, 'params')

export const SALE_CREATED = 'SALE_CREATED'
export const saleCreated = makeActionCreator(SALE_CREATED, 'sale', 'error')

export const UPDATE_SALE = 'UPDATE_SALE'
export const updateSale = makeActionCreator(UPDATE_SALE, 'params')

export const SALE_UPDATED = 'SALE_UPDATED'
export const saleUpdated = makeActionCreator(SALE_UPDATED, 'sale', 'error')

export const UPDATE_SALE_INVOICE = 'UPDATE_SALE_INVOICE'
export const updateSaleInvoice = makeActionCreator(UPDATE_SALE_INVOICE, 'sale')

export const REQUEST_SALES = 'REQUEST_SALES'
export const requestSales = makeActionCreator(REQUEST_SALES, 'params')

export const RECEIVE_SALES = 'RECEIVE_SALES'
export const receiveSales = makeActionCreator(
  RECEIVE_SALES,
  'sales',
  'count',
  'error'
)

export const REQUEST_SALE = 'REQUEST_SALE'
export const requestSale = makeActionCreator(REQUEST_SALE, 'params')

export const RECEIVE_SALE = 'RECEIVE_SALE'
export const receiveSale = makeActionCreator(RECEIVE_SALE, 'sale', 'error')

export const DELETE_SALE_CHILDREN = 'DELETE_SALE_CHILDREN'
export const deleteSaleChildren = makeActionCreator(
  DELETE_SALE_CHILDREN,
  'params'
)

export const SALE_CHILDREN_DELETED = 'SALE_CHILDREN_DELETED'
export const saleChildrenDeleted = makeActionCreator(
  SALE_CHILDREN_DELETED,
  'sale',
  'error'
)

export const CREATE_SALE_SHIPMENT = 'CREATE_SALE_SHIPMENT'
export const createSaleShipment = makeActionCreator(
  CREATE_SALE_SHIPMENT,
  'params'
)

export const UPDATE_SALE_SHIPMENT = 'UPDATE_SALE_SHIPMENT'
export const updateSaleShipment = makeActionCreator(
  UPDATE_SALE_SHIPMENT,
  'params'
)

export const DELETE_SALE_SHIPMENT = 'DELETE_SALE_SHIPMENT'
export const deleteSaleShipment = makeActionCreator(
  DELETE_SALE_SHIPMENT,
  'params'
)

export const SALE_SHIPMENT_DELETED = 'SALE_SHIPMENT_DELETED'
export const saleShipmentDeleted = makeActionCreator(
  SALE_SHIPMENT_DELETED,
  'saleShipment',
  'error'
)

export const CREATE_SALE_ITEM = 'CREATE_SALE_ITEM'
export const createSaleItem = makeActionCreator(CREATE_SALE_ITEM, 'params')

export const UPDATE_SALE_ITEM = 'UPDATE_SALE_ITEM'
export const updateSaleItem = makeActionCreator(UPDATE_SALE_ITEM, 'params')

export const DELETE_SALE_ITEM = 'DELETE_SALE_ITEM'
export const deleteSaleItem = makeActionCreator(DELETE_SALE_ITEM, 'params')

export const SALE_ITEM_DELETED = 'SALE_ITEM_DELETED'
export const saleItemDeleted = makeActionCreator(
  SALE_ITEM_DELETED,
  'saleItem',
  'error'
)

export const REQUEST_SALE_ITEMS = 'REQUEST_SALE_ITEMS'
export const requestSaleItems = makeActionCreator(REQUEST_SALE_ITEMS, 'params')

export const RECEIVE_SALE_ITEMS = 'RECEIVE_SALE_ITEMS'
export const receiveSaleItems = makeActionCreator(
  RECEIVE_SALE_ITEMS,
  'saleItems',
  'count',
  'error'
)

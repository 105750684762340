export const tableInitialState = {
  organizationId: null,
  countryId: null,
  regionId: null,
  organizations: [],
  countries: [],
  page: 1
}

export const tableReducer = (state, action) => {
  if (action.state) {
    return {
      ...state,
      ...action.state
    }
  } else if (action.page) {
    return {
      ...state,
      page: action.page
    }
  } else if (action.regionId) {
    return {
      ...state,
      regionId: action.regionId
    }
  } else if (action.countries) {
    return {
      ...state,
      countries: action.countries
    }
  } else if (action.organizations) {
    return {
      ...state,
      organizations: action.organizations,
      countries: []
    }
  }
  return state
}

export const formInitalState = {
  id: null,
  organizationId: '',
  countryId: '',
  name: ''
}

export const formReducer = (state, action) => {
  if (action.type === 'update') {
    return { ...state, [action.name]: action.value }
  }
  if (action.type === 'set-state') {
    return action.state
  }
  return state
}

import { I18n } from 'aws-amplify'
import { array, number, object, string, date } from 'yup'

import { weightSchema } from '../../../Shared/Weights/utils'

export const FIELDS = {
  id: 'id',
  organizationId: 'organizationId',
  storageFacilityId: 'storageFacilityId',
  produceId: 'produceId',
  varietyId: 'varietyId',
  wasteDate: 'wasteDate',
  totalWeight: 'totalWeight',
  weights: 'weights',
  reason: 'reason'
}

export const SCHEMA = () => {
  return object().shape({
    organizationId: string().required(I18n.get('An organization is required.')),
    storageFacilityId: string().required(
      I18n.get('A storage facility is required.')
    ),
    produceId: string().required(I18n.get('A produce must be selected.')),
    varietyId: string().required(I18n.get('A variety must be selected.')),
    wasteDate: date()
      .typeError('A waste date is required.')
      .max(new Date(), I18n.get('The waste date cannot be set in the future.'))
      .required(I18n.get('A waste date is required.')),
    weights: array()
      .of(weightSchema)
      .min(1, I18n.get('Weights field must have at least 1 item.')),
    totalWeight: number(),
    reason: string().required(I18n.get('A reason is required.'))
  })
}

import { makeActionCreator } from '@/Util/ReduxUtils'

export const CREATE_PACKAGE_UNIT_PURCHASE = 'CREATE_PACKAGE_UNIT_PURCHASE'
export const createPackageUnitPurchase = makeActionCreator(
  CREATE_PACKAGE_UNIT_PURCHASE,
  'params'
)

export const PACKAGE_UNIT_PURCHASE_CREATED = 'PACKAGE_UNIT_PURCHASE_CREATED'
export const packageUnitPurchaseCreated = makeActionCreator(
  PACKAGE_UNIT_PURCHASE_CREATED,
  'packageUnitPurchase',
  'error'
)

export const UPDATE_PACKAGE_UNIT_PURCHASE = 'UPDATE_PACKAGE_UNIT_PURCHASE'
export const updatePackageUnitPurchase = makeActionCreator(
  UPDATE_PACKAGE_UNIT_PURCHASE,
  'params'
)

export const PACKAGE_UNIT_PURCHASE_UPDATED = 'PACKAGE_UNIT_PURCHASE_UPDATED'
export const packageUnitPurchaseUpdated = makeActionCreator(
  PACKAGE_UNIT_PURCHASE_UPDATED,
  'packageUnitPurchase',
  'error'
)

export const REQUEST_PACKAGE_UNIT_PURCHASES = 'REQUEST_PACKAGE_UNIT_PURCHASES'
export const requestPackageUnitPurchases = makeActionCreator(
  REQUEST_PACKAGE_UNIT_PURCHASES,
  'params'
)

export const RECEIVE_PACKAGE_UNIT_PURCHASES = 'RECEIVE_PACKAGE_UNIT_PURCHASES'
export const receivePackageUnitPurchases = makeActionCreator(
  RECEIVE_PACKAGE_UNIT_PURCHASES,
  'packageUnitPurchases',
  'count',
  'error'
)

export const REQUEST_PACKAGE_UNIT_PURCHASE = 'REQUEST_PACKAGE_UNIT_PURCHASE'
export const requestPackageUnitPurchase = makeActionCreator(
  REQUEST_PACKAGE_UNIT_PURCHASE,
  'params'
)

export const RECEIVE_PACKAGE_UNIT_PURCHASE = 'RECEIVE_PACKAGE_UNIT_PURCHASE'
export const receivePackageUnitPurchase = makeActionCreator(
  RECEIVE_PACKAGE_UNIT_PURCHASE,
  'packageUnitPurchase',
  'error'
)

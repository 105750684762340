import withEditableWidget from '@/components/DashboardPageV2/Desktop/Widgets/Shared/HOC/withEditableWidget'
import { WidgetDefinition } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/utils/WidgetRegistry'
import {
  MeasurementEdit,
  MeasurementView
} from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Measurement'
import {
  DATASET_QUERY,
  MULTI_LINE_CHART,
  LAST_7_DAYS,
  HOURLY
} from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'
import { defaultQueryOptions } from '../Shared/Dynamic/config/defaults'
import { tubularSoilSenseTypes } from '@/components/DashboardPageV2/Desktop/Widgets/TubularSoilsense/types'

const TubularSoilsenseWidget = withEditableWidget(
  MeasurementView,
  MeasurementEdit
)

export class TubularSoilsenseWidgetDefinition extends WidgetDefinition {
  constructor({
    widgetId,
    title,
    icon,
    measurement,
    queryOptionsList = null,
    component = null,
    defaultPosition = {
      md: [0, 0, 0, 0, 0, 0]
    },
    options = null,
    config = {
      query: DATASET_QUERY,
      chart: MULTI_LINE_CHART,
      range: LAST_7_DAYS,
      interval: HOURLY
    },
    permissionIds = null,
    denyVisibility = null
  }) {
    const measurementOrError =
      options?.measurement ||
      measurement ||
      TubularSoilsenseWidgetDefinition.paramRequired('measurement')

    const optionsDefault = {
      fixedFactor: 1,
      scaleFactor: 1,
      measurement: measurementOrError,
      queryOptionsList: queryOptionsList || defaultQueryOptions
    }

    const superParams = {
      widgetId,
      title,
      icon,
      component: component ?? TubularSoilsenseWidget,
      defaultPosition,
      config: config,
      options: options ?? optionsDefault,
      permissionIds: permissionIds,
      visibility: denyVisibility
    }

    if (!superParams.options.queryOptionsList) {
      superParams.options.queryOptionsList = defaultQueryOptions
    }

    super(superParams)
  }

  static paramRequired(param) {
    throw new Error(`TubularSoilsenseWidgetDefinition: ${param} is required.`)
  }
}

export const TubularSoilsenseWidgetDefinitions = () => {
  const types = tubularSoilSenseTypes()
  const tubularSoilsenseWidgets = Object.keys(types).map(key => {
    return new TubularSoilsenseWidgetDefinition({ ...types[key] })
  })

  return tubularSoilsenseWidgets
}

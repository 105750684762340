import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useAuth } from '@/contexts/auth-context'

export default function Root() {
  const history = useHistory()
  const auth = useAuth()

  useEffect(() => {
    if (auth?.cognitoUser?.username) {
      history.push('/zones')
    }
  }, [auth?.cognitoUser?.username])

  return null
}

import { useParams } from 'react-router-dom'
import { Fragment, useEffect, useState } from 'react'
import classNames from 'classnames'

import { Box, Button, Text, Flex } from '@/primitives'

import {
  getCurrentUserOrganizations,
  getCurrentUser,
  getZonesHierarchy,
  getZonesHierarchyLoading,
  getNavigationLocationSelector
} from '@/reducers/selectors'

import { useDataboard } from '../context'
import { DATABOARD_ACTIONS } from '../state'
import { COMPARISON_TYPES } from '../Utils/config'
import {
  findDuplicateDateFilterIndices,
  findDuplicateZoneFilterIndices
} from '../Utils/FiltersUtils'

import {
  Strings,
  dateFilterHeaders,
  zoneFilterHeaders,
  getFilteredOrganizations,
  groupHierarchyByOrgId
} from './Utils/FiltersUtils'
import ZoneFilters from './ZoneFilters'
import DateFilters from './DateFilters'
import FilterHeader from './HeaderTitle'
import DynamicHeader from './ZoneFilters/DynamicHeader'

import './index.scss'
import useMediaQuery from '@/hooks/useMediaQuery'
import { useWindowSize } from '@/hooks/useWindowSize'

const MAX_FILTERS = 8
const minWidthByCol = {
  3: 1024,
  4: 1400,
  5: 1600,
  6: 1800,
  7: 2000,
  8: 2200
}

export default function FiltersContainer({ isSidebar = false }) {
  const strings = Strings()
  
  const locationSelector = getNavigationLocationSelector()
  
  const { id: organizationId } = locationSelector?.get('organization') ?? {}
  const { state, dispatchState } = useDataboard()
  const { sensorId = null } = useParams()
  const isBelowBreakpoint = useMediaQuery('max-width: 1024px')

  const zonesHierarchy = getZonesHierarchy()
  const zonesHierarchyLoading = getZonesHierarchyLoading()
  const currentUser = getCurrentUser()
  const organizations = getCurrentUserOrganizations()

  const [organizationsZonesHierarchy, setOrganizationsZonesHierarchy] =
    useState({})
  const [filteredOrganizations, setFilteredOrganizations] = useState([])
  const [isMultiRow, setIsMultiRow] = useState(false)

  const isLocationRange =
    state.comparisonType === COMPARISON_TYPES.LOCATION_RANGES

  const headers = isLocationRange ? zoneFilterHeaders : dateFilterHeaders

  const filters = isLocationRange ? state.zoneFilters : state.dateFilters

  const columnsCount = Object.keys(state.activeColumns).reduce((acc, index) => {
    const columnsInRow = state.activeColumns[index].size + 1
    return acc > columnsInRow ? acc : columnsInRow
  }, 1)

  const windowSize = useWindowSize()


  const onAddRow = e => {
    if (isLocationRange) {
      e.preventDefault()
      dispatchState({
        type: DATABOARD_ACTIONS.ADD_EMPTY_ZONE_FILTER,
        params: { organizationId, sensorId }
      })
    } else {
      dispatchState({ type: DATABOARD_ACTIONS.ADD_DATE_FILTER })
    }
  }

  function onDuplicateLastRow(e) {
    e.preventDefault()
    dispatchState({ type: DATABOARD_ACTIONS.DUPLICATE_LAST_ZONE_FILTER })
  }

  useEffect(() => {
    if (!zonesHierarchyLoading && currentUser?.userName && organizations) {
      const tempOrganizationsZonesHierarchy = groupHierarchyByOrgId(
        zonesHierarchy,
        currentUser
      )
      const tempFilteredOrganizations = getFilteredOrganizations(
        organizations,
        currentUser
      )
      setOrganizationsZonesHierarchy(tempOrganizationsZonesHierarchy)
      setFilteredOrganizations(tempFilteredOrganizations)
    }
  }, [
    zonesHierarchyLoading,
    currentUser?.userName,
    organizations,
    currentUser,
    zonesHierarchy
  ])

  useEffect(() => {
    const { width } = windowSize
    setIsMultiRow(width < minWidthByCol[columnsCount])
  }, [windowSize.width, columnsCount, isBelowBreakpoint, setIsMultiRow])

  const duplicateIndices = isLocationRange
    ? findDuplicateZoneFilterIndices(state.zoneFilters)
    : findDuplicateDateFilterIndices(state.dateFilters)

  const hasNoCommonMeasurements =
    state.comparisonType === COMPARISON_TYPES.LOCATION_RANGES &&
    state.availableMeasurements.length === 0 &&
    state.zoneFilters.length > 1

  return (
    <Box className='DataboardFilters'>
      {!isSidebar && (
        <Box className='DataboardFilters__ComparisonType'>
          <Text size={300} fontWeight={500} fontFamily={'sans'}>
            {isLocationRange ? strings.location : strings.dateRange}
          </Text>
        </Box>
      )}

      <Box
        className={classNames(
          'DataboardFilters__Grid',
          isSidebar && 'DataboardFilters__Grid--sidebar'
        )}
      >
        {!isBelowBreakpoint && !isSidebar && !isMultiRow && (
          <Fragment>
            {isLocationRange ? (
              <DynamicHeader activeColumns={state?.activeColumns} />
            ) : (
              <Box className='DataboardFilters__Grid__HeaderGrid'>
                {headers.map((header, index) => (
                  <FilterHeader
                    key={header + index}
                    title={strings[header] ?? null}
                    isEmptySpace={header === 'emptySpace'}
                  />
                ))}
              </Box>
            )}
          </Fragment>
        )}
        {!isSidebar &&
          filters.map((_, index) => {
            const key = `filter-${index}`
            if (isLocationRange && organizationsZonesHierarchy) {
              return (
                <ZoneFilters
                  key={key}
                  index={index}
                  isDuplicateFilter={duplicateIndices.includes(index)}
                  isBelowBreakpoint={isBelowBreakpoint}
                  isMultiRow={isMultiRow}
                  organizationZonesHierarchy={organizationsZonesHierarchy}
                  organizations={filteredOrganizations}
                  isSidebar={isSidebar}
                  activeColumns={state?.activeColumns}
                  hasNoCommonMeasurements={hasNoCommonMeasurements}
                />
              )
            }

            return (
              <DateFilters
                key={key}
                isBelowBreakpoint={isBelowBreakpoint}
                index={index}
                isDuplicateFilter={duplicateIndices.includes(index)}
                isSidebar={isSidebar}
              />
            )
          })}
        {isSidebar && !isLocationRange && (
          <ZoneFilters
            index={0}
            isBelowBreakpoint={isBelowBreakpoint}
            isSidebar={isSidebar}
            organizationId={organizationId}
            organizationZonesHierarchy={organizationsZonesHierarchy}
            organizations={filteredOrganizations}
          />
        )}
        {isSidebar && isLocationRange && (
          <DateFilters
            isBelowBreakpoint={isBelowBreakpoint}
            index={0}
            isSidebar={isSidebar}
          />
        )}
      </Box>
      {!isSidebar && (
        <Flex direction='row' axisGap={300}>
          <Button
            variant='text'
            iconBefore='add_chart'
            className='DataboardFilters__AddButton'
            onClick={onAddRow}
            disabled={filters.length >= MAX_FILTERS}
          >
            {strings[state.comparisonType]}
          </Button>
          {isLocationRange && (
            <Button
              variant='text'
              iconBefore='content_copy'
              className='DataboardFilters__AddButton'
              onClick={onDuplicateLastRow}
              disabled={filters.length >= MAX_FILTERS}
            >
              {strings.duplicateLocation}
            </Button>
          )}
       </Flex>
      )}
    </Box>
  )
}

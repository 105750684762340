import { useState, useEffect, useReducer } from 'react'
import { Route, useParams, Switch } from 'react-router-dom'

import Table from './Table'
import Form from './Form'

import reducer, { initialState } from './state'

const MODULE_PATH = '/operations/:zone+/inventory/suppliers'

function Supplier() {
  const params = useParams()

  const [state, dispatchState] = useReducer(reducer, initialState)

  const [modulePath, setModulePath] = useState(MODULE_PATH)

  useEffect(() => {
    if (params?.zone) {
      setModulePath(MODULE_PATH.replace(':zone+', params.zone))
    }
  }, [params?.zone])

  return (
    <Switch>
      <Route
        path={[`${MODULE_PATH}/create`, `${MODULE_PATH}/update/:itemId`]}
        exact={true}
      >
        <Form
          state={state}
          dispatchState={dispatchState}
          modulePath={modulePath}
        />
      </Route>
      <Route path={MODULE_PATH} exact={true}>
        <Table
          state={state}
          dispatchState={dispatchState}
          modulePath={modulePath}
        />
      </Route>
    </Switch>
  )
}

export default Supplier

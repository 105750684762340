import { createElement, useEffect, useState, forwardRef, memo } from 'react'
import { isEqual } from 'lodash'
import cx from 'classnames'

import ion from '../Ion/react'

const formatClassName = (className, css) => {
  const _className = cx(className, css ? ion.create(css) : css).trim()
  if (_className.length) return _className
  return null
}

const Box = memo(
  forwardRef(
    (
      { as = 'div', css = null, children = null, className = null, ...rest },
      ref
    ) => {
      /*
        How this works
        1st pass - full render with a local visibility of hidden
        ion(css) parses the incoming styles, and hands back classes
        2nd render pass - now with cached classes
      */

      const [localProps, setLocalProps] = useState({
        'data-hidden': true,
        className: className ? cx(className) : null,
        ...rest
      })

      useEffect(() => {
        const nextProps = {
          ...localProps,
          className: formatClassName(className, css),
          'data-hidden': null
        }

        // had to add this in cause eslint wont allow inherited localProps
        // no way around this
        if (!isEqual(nextProps, localProps)) {
          setLocalProps(nextProps)
        }
      }, [className, css, children, localProps])

      return createElement(
        as,
        {
          ref,
          ...localProps,
          ...rest
        },
        children
      )
    }
  )
)

Box.displayName = 'Box'

export default Box

import { Fragment, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  AutoTable,
  Loader,
  Button,
  FlexV2,
  Text,
  Select,
  Symbol
} from '@/primitives'
import { LineSeparator, SearchBar } from '@/elements'

import AddRoleModal from './AddRoleModal'
import DeleteRoleModal from './DeleteRoleModal'

import { setRole } from '@/slices/management/role'

import { getRoles, getIsLoading, getCurrentUser } from '@/reducers/selectors'

import useMediaQuery from '@/hooks/useMediaQuery'

import {
  hasEditPermissions,
  RESOURCE_TYPE_USER,
  RESOURCE_TYPE_USER_ROLES
} from '@/Util/PermissionUtils'

import Strings from '../Strings'

export default function RolesList({ location }) {
  const coretexUser = getCurrentUser()
  const roles = getRoles()
  const strings = Strings()
  const isLoading = getIsLoading()

  const history = useHistory()
  const dispatch = useDispatch()
  const isAboveCustomBreakpoint = useMediaQuery('min-width: 620px')

  const [showAddRole, setShowAddRole] = useState(false)
  const [showDeleteRole, setShowDeleteRole] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [selectedOrganization, setSelectedOrganization] = useState('all')

  const showEditOptions = hasEditPermissions(
    coretexUser,
    RESOURCE_TYPE_USER,
    RESOURCE_TYPE_USER_ROLES
  )

  const {
    editButton,
    viewButton,
    userRolesTab,
    addRoleButton,
    roleHeader,
    roleOrganizationsHeader,
    tableActionsColumn,
    loadingRoles,
    deleteButton
  } = strings

  const onRoleSelected = roleDetails => {
    dispatch(setRole(roleDetails))
  }

  const onClickAddRole = () => {
    setShowAddRole(true)
  }

  const onClickDeleteRole = roleDetails => {
    dispatch(setRole(roleDetails))
    setShowDeleteRole(true)
  }

  const onUpdateSearchText = useCallback(text => {
    setSearchText(text)
  }, [])

  function onSelectOrganization(e) {
    setSelectedOrganization(e.target.value)
  }

  const filteredRoles = roles?.filter(({ name, organizations }) => {
    return (
      name.toLowerCase().includes(searchText.toLowerCase()) &&
      (selectedOrganization === 'all' ||
        organizations?.some(({ name }) => name === selectedOrganization))
    )
  })

  return (
    <Fragment>
      <AddRoleModal setShowAddRole={setShowAddRole} showAddRole={showAddRole} />
      <DeleteRoleModal
        showDeleteRole={showDeleteRole}
        setShowDeleteRole={setShowDeleteRole}
      />
      <FlexV2 alignMainAxis='space-between' alignCrossAxis='center'>
        <Text size='300' variant='page' tone='800' fontWeight='300'>
          {userRolesTab}
        </Text>
        {showEditOptions && (
          <Button variant='primary' size='small' onClick={onClickAddRole}>
            {addRoleButton}
          </Button>
        )}
      </FlexV2>
      <LineSeparator />
      <FlexV2 direction='column' axisGap={500}>
        <FlexV2
          axisGap={500}
          direction={isAboveCustomBreakpoint ? 'row' : 'column'}
          alignCrossAxis={isAboveCustomBreakpoint ? 'center' : null}
          alignMainAxis='space-between'
        >
          <FlexV2 axisGap={400} alignCrossAxis='center' css={{ flexGrow: 1 }}>
            <SearchBar
              isLoading={isLoading}
              onUpdateSearchText={onUpdateSearchText}
              placeholderText={strings.searchByRoleName}
            />
          </FlexV2>
          <FlexV2 axisGap={400} alignCrossAxis='center'>
            <Select
              required
              value={selectedOrganization}
              onChange={onSelectOrganization}
              disabled={isLoading}
            >
              <option value={'all'}>{strings.selectAllOrganizations}</option>
              {coretexUser?.organizations?.map(({ id, name }) => (
                <option key={id} value={name}>
                  {name}
                </option>
              ))}
            </Select>
          </FlexV2>
        </FlexV2>
        <Loader isLoading={isLoading} text={loadingRoles}>
          {roles?.length > 0 && (
            <AutoTable
              columns={[
                roleHeader,
                roleOrganizationsHeader,
                tableActionsColumn
              ]}
              data={filteredRoles.map(({ name, organizations }) => {
                return [
                  <FlexV2 axisGap={200}>
                    <Symbol name='circle' variant='primary' tone='700' />
                    {name}
                  </FlexV2>,
                  organizations?.length
                    ? organizations?.map(({ name }) => name).join(', ')
                    : '-',
                  <FlexV2 axisGap={300}>
                    {showEditOptions && (
                      <Button
                        variant='error'
                        size='small'
                        onClick={() =>
                          onClickDeleteRole({ name, organizations })
                        }
                      >
                        {deleteButton}
                      </Button>
                    )}
                    <Button
                      variant='primary'
                      size='small'
                      onClick={() => {
                        onRoleSelected({ name, organizations })
                        history.push({
                          pathname: `/admin/user-roles/${name}`,
                          state: { prevPath: location.pathname }
                        })
                      }}
                    >
                      {showEditOptions ? editButton : viewButton}
                    </Button>
                  </FlexV2>
                ]
              })}
              title={userRolesTab}
            />
          )}
        </Loader>
      </FlexV2>
    </Fragment>
  )
}

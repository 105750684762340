import React, { useState, useEffect, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  getCurrentUser,
  getZone,
  getRootZone,
  getZoneLoading,
  getRootZoneRequested,
  getNavigationLocationSelector
} from '@/reducers/selectors'
import { fetchZone } from '@/slices/management/zone'

import {
  fetchRootZone,
  cleanRootZone,
  setRootZone,
  fetchZoneData,
  resetZoneData
} from '@/slices/management/zone'

import { FlexV2 } from '@/primitives'
import LineSeparator from '@/elements/LineSeparator'
import useMediaQuery from '@/hooks/useMediaQuery'

import {
  RESOURCE_TYPE_ZONE,
  checkUserZonesDashboardPermissions
} from '@/Util/PermissionUtils'
import ZoneUtils from '@/Util/ZoneUtils'

import history from '../../history'

import { DATABOARD_ACTIONS, initialState, reducer } from './state'
import { DataboardContext } from './context'
import DataboardChart from './Chart'
import DataboardSidebar from './Sidebar'
import DataboardHeader from './Header'
import DataboardFilters from './Filters'

import './index.scss'

export default function DataPage() {
  const dispatch = useDispatch()
  const params = useParams()

  const isAboveBreakpoint = useMediaQuery('min-width: 900px')

  const locationSelector = getNavigationLocationSelector()

  const locationOrganization = locationSelector.get('organization')
  const organizationId = locationOrganization?.id

  const [state, dispatchState] = useReducer(reducer, initialState)

  const coretexUser = getCurrentUser()
  const rootZone = getRootZone()
  const rootZoneRequested = getRootZoneRequested()
  const zone = getZone()
  const zoneLoading = getZoneLoading()
  const canViewAllZones = checkUserZonesDashboardPermissions(
    coretexUser,
    RESOURCE_TYPE_ZONE,
    null
  )

  const canViewZone = checkUserZonesDashboardPermissions(
    coretexUser,
    RESOURCE_TYPE_ZONE,
    ZoneUtils.getPrimaryZone(params)
  )

  const [zoneId, setZoneId] = useState(null)
  const [zoneWasLoading, setZoneWasLoading] = useState(false)

  useEffect(() => {
    return () => {
      dispatch(resetZoneData())
      dispatch(cleanRootZone())
    }
  }, [dispatch])

  useEffect(() => {
    if (coretexUser?.userName) {
      const primaryZoneId = ZoneUtils.getPrimaryZone(params)
      if (!canViewAllZones && !canViewZone) {
        if (organizationId !== primaryZoneId) {
          history.replace('/zones')
        }
      }
    }
  }, [
    coretexUser?.userName,
    canViewAllZones,
    canViewZone,
    organizationId,
    params
  ])

  useEffect(() => {
    if (coretexUser?.userName) {
      const sensorId = params?.sensorId
      const zoneId = ZoneUtils.getPrimaryZone(params)

      if (sensorId && zoneId !== rootZoneRequested) {
        dispatch(fetchRootZone({ zoneId }))
      }

      if (zone?.id) {
        if (!zone?.parentId) {
          if (rootZone?.id !== zone?.id) dispatch(setRootZone(zone))
        } else if (zone?.id !== rootZone?.id) {
          if (!rootZone?.id || !zone?.parentPath.includes(rootZone?.id)) {
            const zoneId = ZoneUtils.getPrimaryZone(params)
            if (rootZoneRequested !== zoneId) {
              dispatch(fetchRootZone({ zoneId }))
            }
          }
        }
      }
    }
  }, [
    coretexUser?.userName,
    zone?.id,
    dispatch,
    params,
    rootZone?.id,
    zone,
    rootZoneRequested
  ])

  useEffect(() => {
    const zoneId = ZoneUtils.getCurrentZone(params)
    setZoneId(zoneId)
  }, [params])

  useEffect(() => {
    if (zoneId) {
      dispatch(resetZoneData())
      dispatch(fetchZone({ id: zoneId }))
    }
  }, [zoneId, dispatch])

  useEffect(() => {
    if (organizationId !== zoneId) {
      if (!zoneLoading && zoneWasLoading) {
        dispatch(fetchZoneData(zoneId))
      }
      setZoneWasLoading(zoneLoading)
    }
  }, [zoneLoading, dispatch, organizationId, zoneId, zoneWasLoading])

  useEffect(() => {
    if (!zoneLoading && rootZone?.id && zone?.id) {
      dispatchState({
        type: DATABOARD_ACTIONS.SET_LOCAL_STORAGE_ID,
        localStorageId: `${rootZone.id}_${zone.id}_databoard_config`
      })
    }
  }, [zoneLoading, rootZone?.id, zone?.id])

  return (
    <DataboardContext.Provider value={{ state, dispatchState }}>
      <FlexV2 axisGap={500} className='Databoard'>
        <DataboardSidebar />
        {!isAboveBreakpoint && <LineSeparator />}
        <FlexV2 className='Databoard__Content' axisGap={500}>
          <DataboardHeader />
          <LineSeparator />
          <DataboardFilters />
          <DataboardChart />
        </FlexV2>
      </FlexV2>
    </DataboardContext.Provider>
  )
}

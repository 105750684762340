import { Text, Flex } from '@/primitives'
import Select from '@/elements/Select'

import {
  prepareOptions,
  createIntervalOptions,
  createRangeOptions,
  getHasInterval,
  getHasRange,
  hashOptions
} from './utils'
import { reactSelectStyles } from '@/components/DashboardPageV2/Desktop/utils'

import { LIVE_QUERY } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'
import { defaultQueryOptions } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config/defaults'

import './index.scss'
import { Strings } from '@/components/DashboardPageV2/Desktop/Widgets/Strings'

export const defaultDynamicEditTypes = {
  setAll: 'setAll',
  setQuery: 'setQuery',
  setChart: 'setChart',
  setRange: 'setRange',
  setInterval: 'setInterval'
}

export const defaultDynamicEditInitalState = {
  query: '',
  chart: '',
  range: '',
  interval: ''
}

export const defaultDynamicEditReducer = (state, action) => {
  switch (action.type) {
    case 'setAll':
      return {
        ...state,
        query: action.payload.query || '',
        chart: action.payload.chart || '',
        range: action.payload.range || '',
        interval: action.payload.interval || ''
      }
    case 'setQuery':
      return {
        ...state,
        query: action.payload,
        chart: '',
        range: '',
        interval: ''
      }
    case 'setChart':
      return { ...state, chart: action.payload, range: '', interval: '' }
    case 'setRange':
      return { ...state, range: action.payload, interval: '' }
    case 'setInterval':
      return { ...state, interval: action.payload }
    default:
      return state
  }
}

const SelectLabel = ({ label, error }) => {
  const text = error ? error : label
  const variant = error ? 'error' : 'page'
  const tone = error ? 500 : 800

  return (
    <Text variant={variant} tone={tone}>
      {text}
    </Text>
  )
}

export default function DefaultDynamicEdit({
  formState,
  onSelectQuery,
  errors,
  setErrors,
  onSelectChart,
  onSelectRange,
  onSelectInterval,
  queryOptionsList = defaultQueryOptions
}) {
  const strings = Strings()

  const hashedOptions = hashOptions(queryOptionsList)
  const queryOptions = prepareOptions(hashedOptions, strings.selectQuery)
  const chartOptions = prepareOptions(
    hashedOptions[formState?.query]?.options,
    strings.selectChart
  )

  const hasRange = getHasRange(hashedOptions, formState)
  const hasInterval = getHasInterval(hashedOptions, formState)

  let rangeOptions = []

  if (hasRange && formState.chart) {
    rangeOptions = createRangeOptions(
      hashedOptions[formState.query]?.options[formState.chart]?.range,
      strings.selectRange
    )
  }

  let intervalOptions = []
  if (hasRange && hasInterval && formState.range) {
    intervalOptions = createIntervalOptions(
      hashedOptions[formState.query]?.options[formState.chart]?.interval,
      formState.range,
      strings.selectInterval
    )
  }

  const handleSelectQuery = option => {
    onSelectQuery(option)
    if (option.value === LIVE_QUERY) {
      onSelectChart({ value: 'value' })
    }
    if (errors?.query || errors?.chart || errors?.range || errors?.interval) {
      setErrors({ ...errors, query: '', chart: '', range: '', interval: '' })
    }
  }

  const handleSelectChart = option => {
    onSelectChart(option)
    if (errors?.chart || errors?.range || errors?.interval) {
      setErrors({ ...errors, chart: '', range: '', interval: '' })
    }
  }

  const handleSelectRange = option => {
    onSelectRange(option)
    if (errors?.range || errors?.interval) {
      setErrors({ ...errors, range: '', interval: '' })
    }
  }

  const handleSelectInterval = option => {
    onSelectInterval(option)
    if (errors?.interval) {
      setErrors({ ...errors, interval: '' })
    }
  }

  return (
    <Flex
      className='Dynamic_DefaultEdit'
      direction='column'
      alignCrossAxis='flex-start'
      alignMainAxis='space-around'
      wrap='nowrap'
      axisGap={300}
    >
      <Flex direction='column' axisGap={200}>
        <SelectLabel label={strings.selectQuery} error={errors?.query} />
        <Select
          className='Dynamic_DefaultEdit__Select'
          style={{ width: '100%' }}
          options={queryOptions}
          onChange={handleSelectQuery}
          value={formState.query}
          controlStyles={reactSelectStyles}
          menuListStyles={reactSelectStyles}
        />
      </Flex>

      {formState.query && formState.query !== LIVE_QUERY && (
        <Flex direction='column' axisGap={200}>
          <SelectLabel label={strings.selectChart} error={errors?.chart} />
          <Select
            className='Dynamic_DefaultEdit__Select'
            style={{ width: '100%' }}
            options={chartOptions}
            onChange={handleSelectChart}
            value={formState.chart}
            controlStyles={reactSelectStyles}
            menuListStyles={reactSelectStyles}
          />
        </Flex>
      )}
      {formState.chart && hasRange && (
        <Flex direction='column' axisGap={200}>
          <SelectLabel label={strings.selectRange} error={errors?.range} />
          <Select
            className='Dynamic_DefaultEdit__Select'
            style={{ width: '100%' }}
            options={rangeOptions}
            onChange={handleSelectRange}
            value={formState.range}
            controlStyles={reactSelectStyles}
            menuListStyles={reactSelectStyles}
          />
        </Flex>
      )}

      {formState.range && hasRange && hasInterval && (
        <Flex direction='column' axisGap={200}>
          <SelectLabel
            label={strings.selectInterval}
            error={errors?.interval}
          />
          <Select
            className='Dynamic_DefaultEdit__Select'
            style={{ width: '100%' }}
            options={intervalOptions}
            onChange={handleSelectInterval}
            value={formState.interval}
            controlStyles={reactSelectStyles}
            menuListStyles={reactSelectStyles}
          />
        </Flex>
      )}
    </Flex>
  )
}

import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'
import { I18n } from 'aws-amplify'
import { Canvg } from 'canvg'
import { ResponsiveLine } from '@nivo/line'
import { number, object } from 'yup'
import { DateTime } from 'luxon'

import { LINE_GRAPH_THEME } from '../../Utils/config'

const EXPORT_CONSTANTS = {
  topPadding: 30,
  padding: 15,
  textVerticalMultiplier: 1.2,
  titleFontSize: 20,
  backgroundColor: '#2a3552',
  titleColor: '#c3c5cb',
  textHorizontalPadding: 40
}

const MAX_WIDTH = 1920
const MIN_WIDTH = 512
const MAX_HEIGHT = 1080
const MIN_HEIGHT = 288

const pageThemeVariableCandidates = [
  '--ctx-theme-color-page-100',
  '--ctx-theme-color-page-200',
  '--ctx-theme-color-page-300',
  '--ctx-theme-color-page-400',
  '--ctx-theme-color-page-500',
  '--ctx-theme-color-page-600',
  '--ctx-theme-color-page-700',
  '--ctx-theme-color-page-800',
  '--ctx-theme-color-page-900'
]

// https://react.dev/blog/2022/03/08/react-18-upgrade-guide
function TmpAppWithCallbackAfterRender({
  padlessHeight,
  padlessWidth,
  nivoProps,
  themeOverrides,
  generateImage,
  resolve
}) {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    setLoaded(true)
  }, [])

  useEffect(() => {
    if (loaded) {
      generateImage(resolve)
    }
  }, [loaded, generateImage, resolve])

  return (
    <div style={{ height: padlessHeight, width: padlessWidth }}>
      <ResponsiveLine {...nivoProps} theme={themeOverrides} />
    </div>
  )
}

/**
 * Asynchronously get the SVG node from a Nivo chart.
 * It will retry until it finds the SVG node or the timeout is reached.
 * @param {HTMLElement} container The container of the chart
 * @param {number} startTime The time when the function was called
 * @param {number} timeoutMs The timeout in milliseconds
 * @returns {Promise<SVGElement>} The SVG node
 */
export function getSvg(container, startTime, timeoutMs = 2000) {
  return new Promise(resolve => {
    const svgNode = container.querySelector('svg')
    if (svgNode) {
      resolve(svgNode)
    } else if (performance.now() - startTime > timeoutMs) {
      resolve(null)
    } else {
      setTimeout(() => getSvg(container, startTime).then(resolve))
    }
  })
}

export async function createExportChart({
  height,
  width,
  nivoProps,
  measurement
}) {
  let exportOverrides = JSON.parse(JSON.stringify(EXPORT_CONSTANTS))
  let themeOverrides = JSON.parse(JSON.stringify(LINE_GRAPH_THEME))

  // create a temporary container to render the chart to
  const tmpContainer = document.createElement('div')
  // hide the container and make it non-interactive
  tmpContainer.style.visibility = 'hidden'
  tmpContainer.style.pointerEvents = 'none'

  // Append the container to the body
  document.body.appendChild(tmpContainer)

  themeOverrides.axis.domain.line.strokeWidth = 1
  themeOverrides.axis.domain.line.stroke = EXPORT_CONSTANTS.titleColor

  if (width >= 512 && width < 1200 && height >= 288 && height < 675) {
    // between 512x288 and 1200x675
    exportOverrides.titleFontSize = 15
    themeOverrides.fontSize = 11
    themeOverrides.axis.legend.text.fontSize = 13
    nivoProps.axisLeft.legendOffset = -52
    // nivoProps.pointSize = 6
    nivoProps.legends[0].translateY = 65
  } else {
    // greater than 1200x675
    themeOverrides.fontSize = 13
    themeOverrides.axis.legend.text.fontSize = 18
    // nivoProps.pointSize = 8
    nivoProps.axisLeft.legendOffset = -68
    nivoProps.legends[0].translateY = 78
    exportOverrides.padding = 60
    exportOverrides.topPadding = 60
    exportOverrides.textVerticalMultiplier = 0.9
  }

  const padlessHeight =
    height - exportOverrides.topPadding - exportOverrides.padding
  const padlessWidth = width - 2 * exportOverrides.padding

  async function generateImage(resolve, reject) {
    const svgNode = await getSvg(tmpContainer, performance.now())
    
    if (!svgNode) {
      reject(null)
      throw new Error('Could not get SVG node')
    }

    const cssVariables = window.getComputedStyle(document.documentElement)

    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    // we add back the padding to the width and height because the chart is rendered with additional padding
    canvas.width = svgNode.width.baseVal.value + 2 * exportOverrides.padding
    canvas.height =
      svgNode.height.baseVal.value +
      exportOverrides.topPadding +
      exportOverrides.padding

    // fill the canvas with a background color
    ctx.fillStyle = cssVariables.getPropertyValue('--ctx-theme-color-page-200')
    ctx.fillRect(0, 0, canvas.width, canvas.height)

    // reset fillStyle
    ctx.fillStyle = cssVariables.getPropertyValue('--ctx-theme-contrast-page-200')

    // draw graph
    let svg = svgNode.outerHTML

    pageThemeVariableCandidates.forEach((cssVariable)=>{
      const regxp = new RegExp(`var\\(${cssVariable}\\)`, 'gm')
      svg = svg.replace(regxp, cssVariables.getPropertyValue(cssVariable))
    })

    if(svg.length){
      const v = Canvg.fromString(ctx, svg, {
        ignoreDimensions: true,
        ignoreClear: true,
        offsetX: exportOverrides.padding / 2, // equal padding on both sides
        offsetY: exportOverrides.topPadding,
        scaleWidth: padlessWidth,
        scaleHeight: padlessHeight
      })
  
      v.start()
      v.stop()
    }

    // Add titles

    ctx.fillStyle = cssVariables.getPropertyValue('--ctx-theme-contrast-page-200')
    ctx.font = `${exportOverrides.titleFontSize}px ${themeOverrides.fontFamily}`
    let measurementSize = ctx.measureText(measurement.shortName)
    let measurementVerticalPos =
      exportOverrides.topPadding * exportOverrides.textVerticalMultiplier +
      measurementSize.actualBoundingBoxAscent / 2
    ctx.fillText(
      measurement.shortName,
      exportOverrides.textHorizontalPadding + exportOverrides.padding,
      measurementVerticalPos
    )

    // Adding Unit
    let textSize = ctx.measureText(measurement.unit)
    let unitPosition =
      canvas.width -
      textSize.width -
      exportOverrides.textHorizontalPadding -
      exportOverrides.padding
    let unitVerticalPos =
      exportOverrides.topPadding * exportOverrides.textVerticalMultiplier +
      textSize.actualBoundingBoxAscent / 2
    ctx.fillText(measurement.unit, unitPosition, unitVerticalPos)

    const img = canvas.toDataURL('image/jpeg')

    resolve(img)
  }

  const renderPromise = new Promise(resolve => {
    const root = ReactDOM.createRoot(tmpContainer)
    root.render(
      <TmpAppWithCallbackAfterRender
        padlessHeight={padlessHeight}
        padlessWidth={padlessWidth}
        nivoProps={nivoProps}
        themeOverrides={themeOverrides}
        generateImage={generateImage}
        resolve={resolve}
      />
    )
  })

  try {
    const img = await renderPromise
    return img
  } catch (error) {
    return null
  }
}

export const validateDimensions = object().shape({
  width: number()
    .min(MIN_WIDTH, I18n.get('Width must be at least 512px'))
    .max(MAX_WIDTH, I18n.get('Width must be less than 1920px')),
  height: number()
    .min(MIN_HEIGHT, I18n.get('Height must be at least 288px'))
    .max(MAX_HEIGHT, I18n.get('Height must be less than 1080px'))
})

export const formatDatePng = (date, timezone) => {
  return DateTime.fromMillis(date, { zone: timezone }).toFormat(
    'MM-dd-yyyy-HHmm'
  )
}

import { makeActionCreator } from '@/Util/ReduxUtils'

export const REQUEST_ALL_PRODUCES = 'REQUEST_ALL_PRODUCES'
export const requestAllProduces = makeActionCreator(REQUEST_ALL_PRODUCES)

export const RECEIVE_ALL_PRODUCES = 'RECEIVE_ALL_PRODUCES'
export const receiveAllProduces = makeActionCreator(
  RECEIVE_ALL_PRODUCES,
  'produces',
  'count',
  'error'
)

export const REQUEST_PRODUCES = 'REQUEST_PRODUCES'
export const requestProduces = makeActionCreator(REQUEST_PRODUCES, 'params')

export const RECEIVE_PRODUCES = 'RECEIVE_PRODUCES'
export const receiveProduces = makeActionCreator(
  RECEIVE_PRODUCES,
  'produces',
  'count',
  'error'
)

export const CREATE_PRODUCE = 'CREATE_PRODUCE'
export const createProduce = makeActionCreator(CREATE_PRODUCE, 'params')

export const PRODUCE_CREATED = 'PRODUCE_CREATED'
export const produceCreated = makeActionCreator(
  PRODUCE_CREATED,
  'produce',
  'error'
)

export const UPDATE_PRODUCE = 'UPDATE_PRODUCE'
export const updateProduce = makeActionCreator(UPDATE_PRODUCE, 'params')

export const PRODUCE_UPDATED = 'PRODUCE_UPDATED'
export const produceUpdated = makeActionCreator(
  PRODUCE_UPDATED,
  'produce',
  'error'
)

export const REQUEST_PRODUCE = 'REQUEST_PRODUCE'
export const requestProduce = makeActionCreator(REQUEST_PRODUCE, 'params')

export const RECEIVE_PRODUCE = 'RECEIVE_PRODUCE'
export const receiveProduce = makeActionCreator(
  RECEIVE_PRODUCE,
  'produce',
  'error'
)

export const CLEAR_PRODUCE = 'CLEAR_PRODUCE'
export const clearProduce = makeActionCreator(CLEAR_PRODUCE)

export const CLEAR_PRODUCES = 'CLEAR_PRODUCES'
export const clearProduces = makeActionCreator(CLEAR_PRODUCES)

export const SET_PRODUCE = 'SET_PRODUCE'
export const setProduce = makeActionCreator(SET_PRODUCE, 'produce')

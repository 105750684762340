import { Fragment, useEffect, useState } from 'react'
import { isEmpty } from '@aws-amplify/core'
import { I18n } from 'aws-amplify'
import classNames from 'classnames'

import { Box, Flex, Icon, Label, Text } from '@/primitives'
import { getZoneSubNameV2 } from '@/Util/AdminUtils'
import ZoneUtils from '@/Util/ZoneUtils'
import useOrgLabel from '@/hooks/useOrgLabel'

import { getCoreDevicePathsAndType, isZoneCoreDeviceLineage } from './utils'

import './Selector.scss'

function HierarchyOption({
  hierarchyNode,
  onSelectZone,
  selectedZoneId,
  moveInfo,
  state
}) {
  const orgLabels = useOrgLabel(['site', 'facility', 'room', 'zone', 'subzone'])
  const { coreDevicePaths, connectionMethod } = moveInfo
  const hasChlidren = Object.keys(hierarchyNode?.children || {}).length > 0
  const isPathInCoreDeviceBranch = isZoneCoreDeviceLineage(
    hierarchyNode.parentPath,
    coreDevicePaths
  )
  const isSameZone = state.zoneId === hierarchyNode.id
  const isDisabled =
    connectionMethod === 'core'
      ? !isPathInCoreDeviceBranch
      : isPathInCoreDeviceBranch

  const handleOnSelectZone = e => {
    e.preventDefault()
    if (!isDisabled) {
      onSelectZone(hierarchyNode)
    }
  }

  return (
    <Flex
      key={hierarchyNode.id}
      axisGap={300}
      onClick={handleOnSelectZone}
      className={classNames(
        'HierarchyOption',
        (isSameZone || isDisabled) && 'unselectable',
        selectedZoneId === hierarchyNode.id && 'selected-zone'
      )}
      disabled={isSameZone || isDisabled}
    >
      <Box style={{ width: (hierarchyNode.level - 1) * 20 }}></Box>
      <Icon
        name={hasChlidren > 0 ? 'subdirectory_arrow_right ' : 'arrow_right_alt'}
      />
      <Text>{hierarchyNode.name}</Text> (
      {getZoneSubNameV2(hierarchyNode.level - 1, orgLabels)})
      <Text>{state.zoneId === hierarchyNode.id && ' - ' + state.name}</Text>
    </Flex>
  )
}

function HierarchyOptions({
  hierarchy,
  onSelectZone,
  selectedZoneId,
  state,
  moveInfo
}) {
  if (!hierarchy || isEmpty(hierarchy)) {
    return null
  }

  return (
    <Fragment>
      <HierarchyOption
        state={state}
        hierarchyNode={hierarchy}
        onSelectZone={onSelectZone}
        selectedZoneId={selectedZoneId}
        moveInfo={moveInfo}
      />
      {Object.keys(hierarchy.children).map(childId => (
        <HierarchyOptions
          key={childId}
          state={state}
          hierarchy={hierarchy.children[childId]}
          onSelectZone={onSelectZone}
          selectedZoneId={selectedZoneId}
          moveInfo={moveInfo}
        />
      ))}
    </Fragment>
  )
}

export default function Selector({
  state,
  currentZone,
  selectedZoneToMoveTo,
  setSelectedZoneToMoveTo,
  siteHierarchy
}) {
  const orgLabels = useOrgLabel(['site', 'facility', 'room', 'zone', 'subzone'])
  const [moveInfo, setMoveInfo] = useState(null)
  const stateDepth = ZoneUtils.getZoneHierarchyDepthByPath(
    siteHierarchy,
    state.parentPath
  )

  useEffect(() => {
    if (state.zonePath) {
      setMoveInfo(
        getCoreDevicePathsAndType({ parentPath: state.zonePath }, siteHierarchy)
      )
    }
  }, [siteHierarchy, state])

  const onSelectZone = zone => {
    setSelectedZoneToMoveTo(zone)
  }

  return (
    <Flex direction='column' className='HierarchyOptions' axisGap={400}>
      <Label>
        <Text variant='page' tone={700}>
          {I18n.get('Select a new location to move the selected device')}
        </Text>
      </Label>
      {Object.keys(siteHierarchy).length > 0 && moveInfo && (
        <Flex direction='column' axisGap={200} className='list'>
          <Box className='overflow-container'>
            <HierarchyOptions
              state={{ ...state, depth: stateDepth }}
              onSelectZone={onSelectZone}
              hierarchy={siteHierarchy}
              moveInfo={moveInfo}
              selectedZoneId={selectedZoneToMoveTo?.id}
            />
          </Box>
        </Flex>
      )}
      <Flex>
        <Text as='p' size={200}>
          <Text variant='primary' tone={500}>
            {state.name}
          </Text>{' '}
          <Text as='span'>{I18n.get('in')}</Text>{' '}
          <Text variant='primary' tone={500}>
            {currentZone.name}
          </Text>{' '}
          ({getZoneSubNameV2(stateDepth, orgLabels)}){' '}
          {I18n.get('is being moved to')}{' '}
          <Text variant='primary' tone={500}>
            {selectedZoneToMoveTo?.name ?? ''}
          </Text>{' '}
          {selectedZoneToMoveTo?.level &&
            `(${getZoneSubNameV2(selectedZoneToMoveTo.level - 1, orgLabels)})`}
        </Text>
      </Flex>
    </Flex>
  )
}

import isEqual from 'lodash/isEqual'

export const UPDATE_FILTERS = 'UPDATE_FILTERS'
export const RESET_FILTERS = 'RESET_FILTERS'
export const UPDATE_NEXT_TOKEN = 'UPDATE_NEXT_TOKEN'
export const SET_TOTAL_PAGES = 'SET_TOTAL_PAGES'
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
export const SET_CURRENT_AUDIT_TRAIL_LOGS = 'SET_CURRENT_AUDIT_TRAIL_LOGS'

export const initialState = {
  filters: {
    actorType: 'user',
    organizationId: null,
    action: null,
    service: null,
    context: null,
    actorId: null,
    siteId: null
  },
  limit: 10,
  nextTokenArr: [],
  totalPages: 1,
  currentPage: 1,
  currentAuditTrailLogs: [],
  skipFetch: false
}

export const reducer = (state, action) => {
  const handlers = {
    [UPDATE_FILTERS]: (state, action) => {
      const { filterKey, value, skipFetch } = action
      const newFilters = { ...state.filters }
      newFilters[filterKey] = value

      return {
        ...state,
        filters: newFilters,
        nextTokenArr: [],
        totalPages: 1,
        currentPage: 1,
        currentAuditTrailLogs: action.skipFetch
          ? state.currentAuditTrailLogs
          : [],
        limit: 10,
        skipFetch
      }
    },
    [UPDATE_NEXT_TOKEN]: (state, action) => {
      const tempNextTokenArr = state.nextTokenArr

      const hasRecord = tempNextTokenArr.some(token =>
        isEqual(token, action.nextToken)
      )

      if (!hasRecord) {
        tempNextTokenArr.push(action.nextToken)
      }

      return {
        ...state,
        nextTokenArr: tempNextTokenArr
      }
    },
    [SET_TOTAL_PAGES]: (state, action) => ({
      ...state,
      totalPages: action.totalPages
    }),
    [SET_CURRENT_PAGE]: (state, action) => ({
      ...state,
      currentPage: action.currentPage
    }),
    [SET_CURRENT_AUDIT_TRAIL_LOGS]: (state, action) => ({
      ...state,
      currentAuditTrailLogs: action.logs
    }),
    [RESET_FILTERS]: (state, action) => ({
      ...state,
      filters: initialState.filters,
      nextTokenArr: [],
      totalPages: 1,
      currentPage: 1,
      currentAuditTrailLogs: [],
      limit: 10,
      skipFetch: false
    })
  }

  if (!handlers[action.type]) {
    throw new Error(`Unkown action type: ${action.type}`)
  }

  return handlers[action.type](state, action)
}

import { I18n } from 'aws-amplify'
import { useDispatch } from 'react-redux'

import { Row, Cell, Button } from '@/primitives/index'
import { setOrganizationLabel } from '@/slices/management/organizationLabels'
import history from '@/history'

import { TRANSLATION_LANGUAGES_MAP } from './config'

export default function LabelRow({ labelItem }) {
  const {
    label,
    replacementLabel,
    replacementLabelPlural,
    replacementLabelLang,
    labelId,
    organizationId
  } = labelItem

  const dispatch = useDispatch()

  function onEditLabel() {
    dispatch(setOrganizationLabel(labelItem))
    history.push(
      `/admin/organizations/${organizationId}/labels/${labelId}/edit`
    )
  }

  return (
    <Row key={`${organizationId}:${labelId}`}>
      <Cell columnName={I18n.get('Label')}> {label}</Cell>
      <Cell columnName={I18n.get('Replacement Label')}>{replacementLabel}</Cell>
      <Cell columnName={I18n.get('Replacement Label Plural')}>
        {replacementLabelPlural || '-'}
      </Cell>
      <Cell columnName={I18n.get('Replacement Label Language')}>
        {TRANSLATION_LANGUAGES_MAP[replacementLabelLang]}
      </Cell>
      <Cell>
        <Button variant='primary' size='small' onClick={onEditLabel}>
          {I18n.get('Edit')}
        </Button>
      </Cell>
    </Row>
  )
}

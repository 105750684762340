import { Box, Icon } from '../'
import './index.scss'

/**
 * Button Primitive
 * @param {Object} props
 * @param { 'info' | 'primary' | 'success' | 'neutral' | 'warning' | 'danger' | 'error' | 'graph' | 'text' } [props.variant='default']
 * @param {'small' | 'medium' | 'large'} [props.size='medium']
 * @param {Boolean} [props.caret=false]
 * @param {Boolean} [props.disabled=false]
 * @param {Boolean} [props.loading=false]
 * @param {Boolean} [props.outline=false]
 * @param {Boolean} [props.pill=false]
 * @param {Boolean} [props.circle=false]
 * @param {Boolean} [props.fullWidth=false]
 * @param { 'button' | 'reset' | 'submit' } [props.type='button']
 * @param {String} [props.id]
 * @param {String} [props.name]
 * @param {String} [props.value]
 * @param {String} [props.href]
 * @param {'_blank' | '_parent' | '_self' | '_top'} [props.target]
 * @param {String} [props.download]
 * @param {String} [props.iconBefore]
 * @param {String} [props.iconAfter]
 * @param {String | String[]} [props.className]
 * @param {import('react').CSSProperties} [props.css]
 */

const Button = ({
  as = 'button',
  id = null,
  variant = 'default',
  type = 'button',
  size = 'medium',
  caret = null,
  disabled = null,
  loading = null,
  outline = false,
  pill = false,
  circle = false,
  fullWidth = false,
  name = null,
  value = null,
  href = null,
  target = '_self',
  download = null,
  iconBefore = null,
  iconAfter = null,
  className = null,
  children = null,
  ...rest
}) => (
  <Box
    as={href ? 'a' : as}
    id={id}
    role='button'
    aria-busy={loading}
    aria-roledescription={variant}
    disabled={disabled}
    aria-disabled={disabled}
    type={!href ? type : null}
    download={href ? download : null}
    name={name}
    value={!href ? value : null}
    href={href}
    rel={href ? 'noreferrer noopener' : null}
    target={href ? target : null}
    tabIndex={disabled ? -1 : null}
    className={[
      'Button',
      className,
      {
        mod__block: fullWidth,
        mod__pill: pill,
        mod__circle: circle,
        mod__outline: outline,
        size__small: size === 'small',
        size__large: size === 'large'
      }
    ]}
    {...rest}
  >
    {iconBefore && Icon({ name: iconBefore })}
    {!circle && !loading && (
      <Box as='span' aria-label={value || children}>
        {children}
      </Box>
    )}
    {iconAfter && Icon({ name: iconAfter })}
    {caret && Icon({ name: 'expand more' })}
  </Box>
)

export default Button

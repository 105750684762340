import { FlexV2 } from '@/primitives'

import OrganizationsFilter from '../../Shared/Filters/OrganizationsFilter'
import CountriesFilter from '../../Shared/Filters/CountriesFilter'
import RegionsFilter from '../../Shared/Filters/RegionsFilter'
import CitiesFilter from '../../Shared/Filters/CitiesFilter'

import useMediaQuery from '@/hooks/useMediaQuery'

const AreaFilter = ({ state, dispatchState }) => {
  const isAboveBreakpoint = useMediaQuery('min-width: 640px')
  return (
    <FlexV2
      direction={isAboveBreakpoint ? 'row' : 'column'}
      axisGap='300'
      style={{ whiteSpace: 'nowrap' }}
    >
      <OrganizationsFilter state={state} dispatchState={dispatchState} />
      <CountriesFilter state={state} dispatchState={dispatchState} />
      <RegionsFilter state={state} dispatchState={dispatchState} />
      <CitiesFilter state={state} dispatchState={dispatchState} />
    </FlexV2>
  )
}

export default AreaFilter

import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { getCognitoData, makeGraphqlRequest } from '../utils'

// Queries

export const getOrganizations = async params => {
  try {
    const { page, pageSize, search = null } = params
    const query = /* GraphQL */ `
      query getOrganizations($page: Int, $pageSize: Int, $search: String) {
        getOrganizations(page: $page, pageSize: $pageSize, search: $search) {
          page
          pageSize
          search
          totalItems
          result {
            name
            id
            assets {
              logoPrimary
              logoSecondary
            }
          }
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, { page, pageSize, search })
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        organizations: null,
        error: `Organizations could not be fetched: ${error}`
      }
    }

    return { organizations: data?.getOrganizations, error }
  } catch (err) {
    return { organizations: null, error: err.message }
  }
}

export const getAllOrganizations = async () => {
  try {
    const query = /* GraphQL */ `
      query getAllOrganizations {
        getAllOrganizations {
          name
          id
          assets {
            logoPrimary
            logoSecondary
            logoMark
          }
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        organizations: null,
        error: `Organizations could not be fetched: ${error}`
      }
    }

    return { organizations: data?.getAllOrganizations, error }
  } catch (err) {
    return { organizations: null, error: err.message }
  }
}

export const getOrganization = async params => {
  try {
    const query = /* GraphQL */ `
      query getOrganization($organizationId: String!) {
        getOrganization(organizationId: $organizationId) {
          name
          id
          assets {
            logoPrimary
            logoSecondary
          }
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        organization: null,
        error: `Organization could not be fetched: ${error}`
      }
    }

    return { organization: data?.getOrganization, error }
  } catch (err) {
    return { organization: null, error: err.message }
  }
}

// Mutations

export const createOrganization = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createOrganization($name: String!) {
        createOrganization(name: $name) {
          name
          id
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        organization: null,
        error: `Organization not created: ${error}`
      }
    }

    return { organization: data?.createOrganization, error: null }
  } catch (err) {
    if (err.response && err.response.data) {
      return { organization: null, error: err.response.data.errMessage }
    }
    return { organization: null, error: err.message }
  }
}

export const updateOrganization = async params => {
  try {
    const { name, id } = params
    const mutation = /* GraphQL */ `
      mutation updateOrganization($name: String!, $organizationId: String!) {
        updateOrganization(name: $name, organizationId: $organizationId) {
          name
          id
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, { name, organizationId: id })
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        organization: null,
        error: `Organization not updated: ${error}`
      }
    }

    return { organization: data?.updateOrganization, error: null }
  } catch (err) {
    return { organization: null, error: err.message }
  }
}

import { Fragment, useReducer, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import SpecSettings from './SpecSettings'
import { Slot, Text, Button, Flex } from '@/primitives'
import { Dialog } from '@/elements'

import {
  cleanNewConfigs,
  sendConfigureControlDeviceSpec,
  sendUpdateControlDeviceSpec
} from '@/slices/control/controlDeviceConfig'
import { controlDeviceSpecSchema } from './utils'

import {
  getZoneDevices,
  getControlDeviceConfigs,
  getControlDeviceSaving,
  getControlDeviceNewConfigs,
  getMeasurements,
  getDeviceTypes
} from '@/reducers/selectors'

import Strings from '../../../Strings'
import './index.scss'

const specInitialState = {
  relayId: '',
  sensorId: '',
  measurementId: '',
  min: '',
  max: ''
}

const initialState = {
  specs: [],
  initIsSet: false,
  expandedSpec: 0,
  errors: {}
}

function getSensorName(sensors, sensorId) {
  if (sensors?.length === 0 || sensorId?.length === 0) return ''
  const sensor = sensors?.find(({ id }) => id === sensorId)
  if (!sensor?.name) return sensorId
  return sensor.name
}

function reducer(state, action) {
  switch (action.type) {
    case 'set':
      if (!action.value) return { ...state, ...initialState }

      const specifications = action.value

      return {
        ...state,
        initIsSet: true,
        specs: specifications.map(spec => {
          const { measurementId, sensorId, relayId, conditions } = spec
          return {
            measurementId,
            sensorId,
            relayId,
            max: conditions[0]?.maxValue.toString(),
            min: conditions[0]?.minValue.toString()
          }
        })
      }
    case 'update':
      const { value, name, index } = action
      const updatedErrors = { ...state.errors }
      const errorPath = `specs[${index}].${name}`
      if (updatedErrors.hasOwnProperty(errorPath)) {
        delete updatedErrors[errorPath]
      }

      const updatedSpecs = [...state.specs]
      updatedSpecs[index][name] = value
      return { ...state, specs: updatedSpecs, errors: updatedErrors }
    case 'add':
      const indexOfNewSpec = state.specs.length
      return {
        ...state,
        specs: [...state.specs, action.value],
        expandedSpec: indexOfNewSpec
      }
    case 'remove':
      const { specs, expandedSpec } = state
      const specIndex = specs.findIndex(({ relayId }) => {
        return relayId === action.value
      })
      const newExpandedSpec = specIndex === expandedSpec ? null : expandedSpec
      const filteredSpecs = specs.toSpliced(specIndex, 1)
      return { ...state, specs: filteredSpecs, expandedSpec: newExpandedSpec }
    case 'cleanFields':
      return { ...state, ...initialState }
    case 'generalUpdate':
      return { ...state, [action.name]: action.value }
    default:
      return { ...state }
  }
}

function getFilteredRelayOptions(relayConfigs, specs) {
  const specsRelayIds = specs.map(({ relayId }) => relayId)
  const relayOptionsFormatted = relayConfigs
    .filter(({ relayId }) => !specsRelayIds.includes(relayId))
    .map(({ relayId }) => {
      return {
        value: relayId,
        label: relayId
      }
    })

  return relayOptionsFormatted
}

export default function FormSpec({
  coreDeviceId,
  controlDeviceConfig,
  showForm,
  setShowForm
}) {
  const strings = Strings()

  const reduxDispatch = useDispatch()

  const devices = getZoneDevices()
  const deviceTypes = getDeviceTypes()
  const measurements = getMeasurements()
  const configs = getControlDeviceConfigs()
  const saving = getControlDeviceSaving()
  const newConfigs = getControlDeviceNewConfigs()

  const [state, dispatch] = useReducer(reducer, initialState)

  const [relayOptions, setRelayOptions] = useState([])
  const [sensorOptions, setSensorOptions] = useState([])
  const [measurementOptions, setMeasurementOptions] = useState([])

  useEffect(() => {
    if (newConfigs.length > 0 && !saving) {
      reduxDispatch(cleanNewConfigs())
      setShowForm(false)
      dispatch({ type: 'cleanFields' })
    }
  }, [newConfigs, saving])

  useEffect(() => {
    if (
      showForm &&
      controlDeviceConfig?.relayConfiguration &&
      state.specs.length > 0 &&
      relayOptions?.length === 0
    ) {
      const filteredOptions = getFilteredRelayOptions(
        controlDeviceConfig?.relayConfiguration,
        state.specs
      )

      if (filteredOptions?.length > 0) setRelayOptions(filteredOptions)
    }

    if (!showForm && relayOptions?.length > 0) setRelayOptions([])
  }, [
    controlDeviceConfig?.relayConfiguration,
    state.specs,
    relayOptions,
    showForm
  ])

  useEffect(() => {
    if (
      state.initIsSet &&
      showForm &&
      devices?.length > 0 &&
      state.specs.length > 0 &&
      sensorOptions?.length === 0
    ) {
      const sensorsIdsFromOtherConfigs = configs.reduce((acc, config) => {
        if (config.controlDeviceId !== controlDeviceConfig.controlDeviceId) {
          config?.specifications.forEach(spec => {
            acc.push(spec.sensorId)
          })
        }
        return acc
      }, [])

      const usedSensorIds = state.specs
        .filter(({ sensorId }) => sensorId)
        .map(({ sensorId }) => sensorId)

      const filteredOptions = devices
        .filter(
          ({ sensorType, id }) =>
            sensorType === 'watersense' &&
            !usedSensorIds.includes(id) &&
            !sensorsIdsFromOtherConfigs.includes(id)
        )
        .map(({ id, name }) => {
          return {
            value: id,
            label: name
          }
        })

      if (filteredOptions?.length > 0) setSensorOptions(filteredOptions)
    }

    if (!showForm && sensorOptions?.length > 0) setSensorOptions([])
  }, [
    devices,
    state.specs,
    sensorOptions,
    showForm,
    configs,
    controlDeviceConfig,
    state.initIsSet
  ])

  useEffect(() => {
    if (measurements?.length > 0) {
      // TODO: add more device types
      const watersense = deviceTypes?.find(({ id }) => id === 'watersense')
      const filteredOptions = measurements
        .filter(({ id }) => watersense?.measurements?.includes(id))
        .map(({ id, description }) => {
          return {
            value: id,
            label: description
          }
        })
      setMeasurementOptions(filteredOptions)
    }
  }, [measurements, deviceTypes])

  useEffect(() => {
    if (!showForm) {
      dispatch({ type: 'cleanFields' })
    }
  }, [showForm])

  useEffect(() => {
    if (!state.initIsSet && showForm && devices?.length > 0) {
      const { specifications, relayConfiguration } = controlDeviceConfig

      if (specifications?.length > 0) {
        const deviceIds = devices.map(({ id }) => id)
        const specsList = [...controlDeviceConfig?.specifications]
        const specsWithExistingSensors = specsList.map(spec => {
          if (deviceIds.includes(spec.sensorId)) return spec
          return { ...spec, sensorId: '' }
        })

        dispatch({ type: 'set', value: specsWithExistingSensors })
      }

      if (specifications?.length === 0 && relayConfiguration?.length > 0) {
        const { sensorId, min, max, measurementId } = specInitialState
        const conditions = [
          {
            minValue: min,
            maxValue: max
          }
        ]
        const newSpec = {
          sensorId,
          measurementId,
          conditions,
          relayId: relayConfiguration[0].relayId
        }
        dispatch({ type: 'set', value: [newSpec] })
      }
    }
  }, [
    controlDeviceConfig?.specifications,
    controlDeviceConfig?.relayConfiguration?.length,
    showForm,
    devices
  ])

  const onSubmit = async () => {
    try {
      await controlDeviceSpecSchema.validate(
        { ...state },
        { abortEarly: false }
      )

      const controlDeviceId = controlDeviceConfig.controlDeviceId
      const inputs = state.specs.map(spec => {
        const { relayId, sensorId, measurementId, max, min } = spec
        return {
          relayId,
          sensorId,
          measurementId,
          conditions: [
            {
              minValue: parseFloat(min),
              maxValue: parseFloat(max)
            }
          ]
        }
      })

      if (controlDeviceConfig.specifications.length === 0) {
        reduxDispatch(
          sendConfigureControlDeviceSpec({
            coreDeviceId,
            controlDeviceId,
            inputs
          })
        )
      }

      if (controlDeviceConfig.specifications.length > 0) {
        reduxDispatch(
          sendUpdateControlDeviceSpec({
            coreDeviceId,
            controlDeviceId,
            inputs
          })
        )
      }
    } catch (err) {
      if (err.inner) {
        let errors = {}
        for (let i = 0; i < err.inner.length; i++) {
          const { message, path } = err.inner[i]
          errors[path] = message
        }
        dispatch({ type: 'generalUpdate', name: 'errors', value: errors })
      }
    }
  }

  function closeDialog() {
    setShowForm(false)
  }

  const addSpec = () => {
    const usedRelayIds = state.specs.map(({ relayId }) => relayId)
    const { relayId } = controlDeviceConfig?.relayConfiguration?.find(
      ({ relayId }) => !usedRelayIds.includes(relayId)
    )

    const optionIndexToRemove = relayOptions.findIndex(
      option => option.value === relayId
    )
    const newRelayOptions = [...relayOptions]
    newRelayOptions?.splice(optionIndexToRemove, 1)
    setRelayOptions(newRelayOptions)
    dispatch({ type: 'add', value: { ...specInitialState, relayId } })
  }

  const removeSpec = ({ target }) => {
    const { value } = target
    setRelayOptions([...relayOptions, { value, label: value }])
    const removedSpec = state.specs.find(spec => spec.relayId === value)

    if (removedSpec?.sensorId?.length > 0) {
      setSensorOptions([
        ...sensorOptions,
        {
          value: removedSpec.sensorId,
          label: getSensorName(devices, removedSpec.sensorId)
        }
      ])
    }
    dispatch({ type: 'remove', value })
  }

  const updateSpec = (name, value, index) => {
    if (name === 'relayId') {
      const removedRelayId = state.specs[index]['relayId']
      const indexInOptions = relayOptions.findIndex(
        ({ value }) => value === removedRelayId
      )
      const newRelayOptions = [...relayOptions]
      newRelayOptions.splice(indexInOptions, 1, {
        value: removedRelayId,
        label: removedRelayId
      })
      setRelayOptions(newRelayOptions)
    }

    if (name === 'sensorId') {
      const updatedSpec = { ...state.specs[index] }
      const indexInOptions = sensorOptions.findIndex(
        sensor => sensor.value === value
      )

      const newSensorOptions = [...sensorOptions]
      newSensorOptions.splice(indexInOptions, 1)

      if (updatedSpec?.sensorId?.length > 0) {
        newSensorOptions.push({
          value: updatedSpec?.sensorId,
          label: getSensorName(devices, updatedSpec?.sensorId)
        })
      }
      setSensorOptions(newSensorOptions)
    }
    dispatch({ type: 'update', name, value, index })
  }

  const showAddButton =
    state.specs.length < controlDeviceConfig?.relayConfiguration?.length &&
    state.specs.length < devices?.length

  function setShowDetails(index) {
    dispatch({ type: 'generalUpdate', name: 'expandedSpec', value: index })
  }

  return (
    <Fragment>
      {showForm && (
        <Dialog
          open={showForm}
          onOpenChange={setShowForm}
          type='offcanvas'
          className='ZoneDetails__OffCanvas FormSpec'
          style={{ zIndex: 3 }}
        >
          <Slot name='title'>
            <Text size={300} fontWeight={700}>
              {strings.formControlDeviceSpecification}
            </Text>
          </Slot>
          <Slot name='content'>
            {state.specs.map(({ relayId }, index) => (
              <SpecSettings
                key={relayId ?? `spec-${index}`}
                spec={state.specs[index]}
                sensorOptions={sensorOptions}
                relayOptions={relayOptions}
                measurementOptions={measurementOptions}
                removeSpec={removeSpec}
                updateSpec={updateSpec}
                index={index}
                errors={state?.errors}
                showDetails={state.expandedSpec === index}
                setShowDetails={setShowDetails}
              />
            ))}
            {showAddButton && (
              <Button variant='primary' size='small' onClick={addSpec}>
                {strings.addSpecButton}
              </Button>
            )}
          </Slot>
          <Slot name='actions'>
            <Flex axisGap={300} alignMainAxis='space-between'>
              <Button variant='neutral' onClick={closeDialog} disabled={saving}>
                {strings.cancel}
              </Button>
              <Button
                variant='primary'
                onClick={onSubmit}
                disabled={saving}
                loading={saving}
              >
                {strings.submit}
              </Button>
            </Flex>
          </Slot>
        </Dialog>
      )}
    </Fragment>
  )
}

import { call, put, takeLatest } from 'redux-saga/effects'

import {
  createPackageUnitInventoryCalibration,
  getPackageUnitInventoryCalibrations
} from '@/api/operations/packageUnitInventoryCalibration'

import {
  REQUEST_PACKAGE_UNIT_INVENTORY_CALIBRATIONS,
  CREATE_PACKAGE_UNIT_INVENTORY_CALIBRATION,
  receivePackageUnitInventoryCalibrations,
  packageUnitInventoryCalibrationCreated
} from '@/actions/operations/packageUnitInventoryCalibration'

import { displayBanner } from '../utils'

import Strings from './Strings'

const strings = Strings()

function* sendCreatePackageUnitInventoryCalibration(action) {
  const { data, error = null } = yield call(
    createPackageUnitInventoryCalibration,
    action.params
  )
  yield put(
    displayBanner(error, strings.packageUnitInventoryCalibrationCreated)
  )
  yield put(packageUnitInventoryCalibrationCreated(data, error))
}

export function* watchCreatePackageUnitInventoryCalibration() {
  yield takeLatest(
    CREATE_PACKAGE_UNIT_INVENTORY_CALIBRATION,
    sendCreatePackageUnitInventoryCalibration
  )
}

function* sendRequestPackageUnitInventoryCalibrations(action) {
  const { data, error = null } = yield call(
    getPackageUnitInventoryCalibrations,
    action.params
  )
  const { packageUnitInventoryCalibrations = [], count = 0 } = data
  yield put(
    receivePackageUnitInventoryCalibrations(
      packageUnitInventoryCalibrations,
      count,
      error
    )
  )
}

export function* watchRequestPackageUnitInventoryCalibrations() {
  yield takeLatest(
    REQUEST_PACKAGE_UNIT_INVENTORY_CALIBRATIONS,
    sendRequestPackageUnitInventoryCalibrations
  )
}

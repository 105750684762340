import { useEffect, useReducer, Fragment, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'

import HarvestChart from './HarvestChart'
// import CropsChart from './CropsChart'
import CropsBarChart from './CropsBarChart'

import useMediaQuery from '@/hooks/useMediaQuery'

import { clearDataboard } from '@/actions/operations/databoard'
import { getIsLoading } from '@/reducers/selectors'

import {
  Button,
  Box,
  Flex,
  Table,
  Slot,
  Row,
  Column,
  Text,
  Cell,
  Loader,
  Icon
} from '@/primitives'
import { Pagination, SearchBarWithDebounce } from '@/elements'

import Strings from './Strings'
import { getStringsByContext } from './utils'

function reducer(state, action) {
  if (action.type) {
    return {
      ...state,
      [action.type]: action.value
    }
  }
}

const initialArgs = {
  totalPages: 1,
  page: 1,
  searchText: '',
  pageItems: [],
  filteredData: []
}

const pageSize = 10
const strings = Strings()

const OperationsDataboard = ({
  section = 'harvest',
  keys = [],
  data = [],
  children,
  graphKeys,
  graphData,
  graphConfig,
  gradesData,
  onDownloadCSV
}) => {
  const location = useLocation()
  const history = useHistory()
  const reduxDispatch = useDispatch()
  const isAboveCustomBreakpoint = useMediaQuery('min-width: 620px')
  const isLoading = getIsLoading()

  const [state, dispatch] = useReducer(reducer, initialArgs)
  // const [graphView, setGraphView] = useState(false)

  useEffect(() => {
    return () => reduxDispatch(clearDataboard())
  }, [reduxDispatch])

  useEffect(() => {
    if (data.length > 0) {
      const filteredData = data.filter(data => {
        const dataString = JSON.stringify(data).toLowerCase()
        return dataString.includes(state.searchText)
      })
      const offset = pageSize * (state.page - 1)
      const currentData = [...filteredData].splice(offset, pageSize)
      dispatch({ type: 'pageItems', value: currentData })
      dispatch({ type: 'filteredData', value: filteredData })
    }
  }, [data, state.page, state.searchText])

  const onManage = () => {
    history.push(location.pathname.replace('/data', ''))
  }

  const setTotalPages = useCallback(value => {
    dispatch({ type: 'totalPages', value })
  }, [])

  const setPage = useCallback(value => {
    dispatch({ type: 'page', value })
  }, [])

  const setSearchText = useCallback(value => {
    dispatch({ type: 'searchText', value: value.toLowerCase() })
    dispatch({ type: 'page', value: 1 })
  }, [])

  // const onToggleGraphView = () => {
  //   setGraphView(!graphView)
  // }

  const { pageHeading, chartHeading, tableHeading, searchPlaceholder } =
    getStringsByContext(section)

  return (
    <Box>
      <Flex
        alignMainAxis='space-between'
        alignCrossAxis='center'
        style={{ paddingTop: '1rem', width: '100%' }}
      >
        <Text
          as='p'
          variant='page'
          tone={800}
          style={{ margin: '0.5rem 0', flexGrow: '1' }}
        >
          {pageHeading}
        </Text>
        <Flex
          alignMainAxis='flex-end'
          alignCrossAxis='flex-start'
          axisGap={300}
          direction='row'
          wrap='nowrap'
          style={{
            width: isAboveCustomBreakpoint ? 'auto' : '100%',
            flexGrow: 1
          }}
        >
          {children?.length > 0 && children.find(child => child.key === 'main')}
          <Button
            size='small'
            iconBefore='format_list_bulleted'
            name='toggleBackButton'
            onClick={onManage}
          />
        </Flex>
        <hr
          style={{
            width: '100%',
            marginTop: '2rem',
            marginBottom: '0.5rem',
            borderColor: 'var(--ctx-theme-color-page-400)'
          }}
        />
      </Flex>
      {children?.length > 0 && children.find(child => child.key === 'sub')}
      <Loader isLoading={isLoading}>
        {data?.length === 0 && (
          <Text as='p' variant='page' tone={900}>
            {strings.noData}
          </Text>
        )}
        {graphData?.length > 0 && (
          <Text as='h5' style={{ marginBottom: '0.5rem' }}>
            {chartHeading}
          </Text>
        )}
        {graphKeys?.length > 0 && graphData?.length > 0 && (
          <HarvestChart
            keys={graphKeys}
            data={graphData}
            config={graphConfig}
            gradesData={gradesData}
            section={section}
          />
        )}
        {section === 'crops' && <CropsBarChart />}
        {/* // <CropsChart data={graphData} />} */}
        {keys?.length > 0 && data?.length > 0 && (
          <Box>
            {/* <Flex alignMainAxis='flex-end' style={{ height: '3rem' }}>
              <Button
                size='small'
                iconBefore={graphView ? 'table_chart' : 'bar_chart'}
                name='toggleViewButton'
                onClick={onToggleGraphView}
                variant='info'
              />
            </Flex> */}
            <Text as='h5'>{tableHeading}</Text>
            <Fragment>
              <Flex alignMainAxis='space-between' wrap='nowrap' axisGap={300}>
                <SearchBarWithDebounce
                  onUpdateSearchText={setSearchText}
                  placeholderText={searchPlaceholder}
                />
                <Button
                  size='small'
                  iconBefore='download'
                  variant='info'
                  name='downloadButton'
                  onClick={onDownloadCSV}
                />
              </Flex>
              <Table style={{ marginTop: '1rem' }}>
                <Slot name='head'>
                  <Row>
                    {keys.map(key => {
                      return (
                        <Column key={`head-${key}`}>
                          {strings[key] ?? ''}
                        </Column>
                      )
                    })}
                  </Row>
                </Slot>
                <Slot name='body'>
                  {state?.pageItems?.map((item, index) => (
                    <Row key={`row-${index}`}>
                      {keys.map(key => {
                        return (
                          <Cell key={`row-${index}-${key}`}>
                            {item[key] ?? <Icon name='remove' />}
                          </Cell>
                        )
                      })}
                    </Row>
                  ))}
                </Slot>
              </Table>
            </Fragment>
          </Box>
        )}
      </Loader>
      {state?.filteredData?.length > pageSize && (
        <Box className='pagination-menu'>
          <Pagination
            type={isAboveCustomBreakpoint ? 'simple' : 'compact'}
            showPageCount={isAboveCustomBreakpoint}
            totalItems={state.filteredData.length}
            totalPages={Math.ceil(state.filteredData.length / pageSize)}
            setTotalPages={setTotalPages}
            page={state.page}
            setPage={setPage}
            pageSize={pageSize}
          />
        </Box>
      )}
    </Box>
  )
}

export default OperationsDataboard

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  getSettingsForObject,
  turnOnOffSetting
} from '@/api/management/settings'

import { fetchCurrentUser } from './user'

export const fetchSettings = createAsyncThunk('fetchSettings', async params => {
  const { objectSettings } = await getSettingsForObject(params)
  return { objectSettings }
})

export const sendTurnOnOffSetting = createAsyncThunk(
  'sendTurnOnOffSetting',
  async (params, { dispatch }) => {
    const { objectSettings } = await turnOnOffSetting(params)
    if (params.type === 'user') {
      dispatch(fetchCurrentUser({ avoidReload: true }))
    }

    return { objectSettings }
  }
)

const settingsSlice = createSlice({
  name: 'settingsReducer',
  initialState: {
    objectId: null,
    type: null,
    settings: [],
    isFetching: false,
    isSaving: false
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSettings.pending, (state, action) => {
        state.objectId = null
        state.type = null
        state.settings = []
        state.isFetching = true
      })
      .addCase(fetchSettings.rejected, (state, action) => {
        state.isFetching = false
      })
      .addCase(fetchSettings.fulfilled, (state, action) => {
        state.objectId = action?.payload?.objectSettings?.objectId ?? null
        state.type = action?.payload?.objectSettings?.type ?? null
        state.settings = action?.payload?.objectSettings?.settings ?? []
        state.isFetching = false
      })
      .addCase(sendTurnOnOffSetting.pending, (state, action) => {
        state.isSaving = true
      })
      .addCase(sendTurnOnOffSetting.rejected, (state, action) => {
        state.isSaving = false
      })
      .addCase(sendTurnOnOffSetting.fulfilled, (state, action) => {
        state.objectId = action?.payload?.objectSettings?.objectId ?? null
        state.type = action?.payload?.objectSettings?.type ?? null
        state.settings = action?.payload?.objectSettings?.settings ?? []
        state.isSaving = false
      })
  }
})

export default settingsSlice.reducer

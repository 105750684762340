import { makeOperationsApiCall } from './utils'

export const getFertilizerInventoryTransfers = async params => {
  try {
    const query = /* GraphQL */ `
      query getFertilizerInventoryTransfers(
        $filter: GetFertilizerInventoryTransfersFilter
        $order: OrderEnum
        $offset: Int
        $limit: Int
      ) {
        getFertilizerInventoryTransfers(
          filter: $filter
          order: $order
          offset: $offset
          limit: $limit
        ) {
          fertilizerInventoryTransfers {
            id
            organizationId
            fertilizerInventory {
              id
              fertilizerId
              storageFacility {
                id
                name
              }
            }
            shippingMethod {
              id
              name
            }
            storageFacility {
              id
              name
            }
            status
            totalWeight
            weights {
              id
              unitWeight
              quantity
            }
            shippedDate
            deliveredDate
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getFertilizerInventoryTransfers',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getFertilizerInventoryTransfer = async params => {
  try {
    const query = /* GraphQL */ `
      query getFertilizerInventoryTransfer(
        $fertilizerInventoryTransferId: String!
      ) {
        getFertilizerInventoryTransfer(
          fertilizerInventoryTransferId: $fertilizerInventoryTransferId
        ) {
          id
          organizationId
          fertilizerInventoryId
          fertilizerInventory {
            storageFacilityId
            fertilizerId
          }
          shippingMethodId
          storageFacilityId
          status
          totalWeight
          weights {
            id
            unitWeight
            quantity
          }
          shippedDate
          deliveredDate
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getFertilizerInventoryTransfer',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const createFertilizerInventoryTransfer = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createFertilizerInventoryTransfer(
        $organizationId: String!
        $fertilizerInventoryId: String!
        $shippingMethodId: String!
        $storageFacilityId: String!
        $status: FertilizerInventoryTransferStatus!
        $weights: [InventoryWeightInput]!
        $shippedDate: GraphQLTimestamp
        $deliveredDate: GraphQLTimestamp
      ) {
        createFertilizerInventoryTransfer(
          organizationId: $organizationId
          fertilizerInventoryId: $fertilizerInventoryId
          shippingMethodId: $shippingMethodId
          storageFacilityId: $storageFacilityId
          status: $status
          weights: $weights
          shippedDate: $shippedDate
          deliveredDate: $deliveredDate
        ) {
          id
          organizationId
          fertilizerInventory {
            id
            fertilizerId
            storageFacility {
              id
              name
            }
          }
          shippingMethod {
            id
            name
          }
          storageFacility {
            id
            name
          }
          status
          totalWeight
          weights {
            id
            unitWeight
            quantity
          }
          shippedDate
          deliveredDate
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'createFertilizerInventoryTransfer',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updateFertilizerInventoryTransfer = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateFertilizerInventoryTransfer(
        $fertilizerInventoryTransferId: String!
        $organizationId: String!
        $fertilizerInventoryId: String!
        $shippingMethodId: String!
        $storageFacilityId: String!
        $status: FertilizerInventoryTransferStatus!
        $weights: [InventoryWeightInput]!
        $shippedDate: GraphQLTimestamp
        $deliveredDate: GraphQLTimestamp
      ) {
        updateFertilizerInventoryTransfer(
          fertilizerInventoryTransferId: $fertilizerInventoryTransferId
          organizationId: $organizationId
          fertilizerInventoryId: $fertilizerInventoryId
          shippingMethodId: $shippingMethodId
          storageFacilityId: $storageFacilityId
          status: $status
          weights: $weights
          shippedDate: $shippedDate
          deliveredDate: $deliveredDate
        ) {
          id
          organizationId
          fertilizerInventory {
            id
            storageFacility {
              id
              name
            }
            fertilizerId
          }
          shippingMethod {
            id
            name
          }
          storageFacility {
            id
            name
          }
          totalWeight
          weights {
            id
            unitWeight
            quantity
          }
          shippedDate
          deliveredDate
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'updateFertilizerInventoryTransfer',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

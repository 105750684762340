import Box from '@/primitives/Box'
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer'

const darkTheme = {
  // normal
  diffViewerBackground: '#131926',
  diffViewerColor: '#b4b5ce',
  gutterBackground: '#181e2b',
  gutterColor: '#ccc',
  // added
  addedBackground: '#1d3d2f',
  addedColor: '#b4cec1',
  addedGutterBackground: '#193529',
  wordAddedBackground: '#234e3c',
  // removed
  removedColor: '#ceb4b4'
}

/**
 * A diff viewer for jsondiffpatch deltas
 * @param {Object} props
 * @param {Object} props.diff
 * @param {String} [props.className=null]
 * @param {Boolean} [props.showDiffOnly=false]
 * @param {Boolean} [props.splitView=false]
 * @param {Boolean} [props.useDarkTheme=true]
 * @param {Boolean} [props.hideLineNumbers=true]
 * @param {String} [props.compareMethod=DiffMethod.WORDS]
 * @param {Object} [props.styles=null]
 * @returns {React.ReactElement}
 */
const DiffViewer = ({
  diff,
  className = null,
  showDiffOnly = false,
  splitView = false,
  useDarkTheme = false,
  hideLineNumbers = true,
  compareMethod = DiffMethod.WORDS,
  styles = null
}) => {
  let oldValue = {}
  let newValue = {}

  for (const key in diff) {
    oldValue[key] = diff[key][0]
    newValue[key] = diff[key][1]
  }

  const defaultStyles = {
    variables: {
      dark: darkTheme
    }
  }

  return (
    <Box className={className}>
      <ReactDiffViewer
        showDiffOnly={showDiffOnly}
        splitView={splitView}
        useDarkTheme={useDarkTheme}
        styles={styles || defaultStyles}
        hideLineNumbers={hideLineNumbers}
        compareMethod={compareMethod}
        oldValue={JSON.stringify(oldValue, null, 2)}
        newValue={JSON.stringify(newValue, null, 2)}
      />
    </Box>
  )
}

export default DiffViewer

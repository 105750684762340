import { I18n } from 'aws-amplify'

const Strings = () => ({
  databoardTitle: I18n.get('Databoard'),
  databoardSubtitle: I18n.get('Please select a time range, measurements and locations to view the associated data.'),
  dateRangeLabel: I18n.get('Preset Time Range'),
  graphIntervalLabel: I18n.get('Graph Interval'),
  startDateLabel: I18n.get('Start date'),
  endDateLabel: I18n.get('End date'),
  selectSensor: I18n.get('Select Sensor'),
  sidebarGeneral: I18n.get('General'),
  configureSidebar: I18n.get('Configure Databoard'),
  measurementsLabel: I18n.get('Measurements'),
  dateRangeFilters: I18n.get('Time Range & Interval'),
  pleaseWait: I18n.get('Please wait while we fetch the requested data'),
  selectZone: I18n.get('Select a location'),
  selectMeasurements: I18n.get('Select Measurement(s)'),
  selectMeasurementsLimits: I18n.get('1-4'),
  selectMeasurementsPlaceholder: I18n.get('Select measurements'),
  selectZonePlaceholder: I18n.get('Select a zone'),
  selectInterval: I18n.get('Select an interval'),
  downloadData: I18n.get('Download Data'),
  downloadGraph: I18n.get('Download graph'),
  fetchData: I18n.get('Fetch Data'),
  date: I18n.get('Date'),
  dateRange: I18n.get('Date range'),
  minute: I18n.get('Minutely'),
  hour: I18n.get('Hourly'),
  day: I18n.get('Daily'),
  week: I18n.get('Weekly'),
  month: I18n.get('Monthly'),
  noData: I18n.get('No Data'),
  historicDataTitle: I18n.get('Historical Data'),
  errorOccurred: I18n.get('An error occurred'),
  customRange: I18n.get('Custom Range'),
  lasthour: I18n.get('Last hour'),
  today: I18n.get('Today'),
  last24hours: I18n.get('Last 24 hours'),
  yesterday: I18n.get('Yesterday'),
  last7days: I18n.get('Last 7 days'),
  last30days: I18n.get('Last 30 days'),
  lastmonth: I18n.get('Last month'),
  thisyear: I18n.get('This year'),
  last12months: I18n.get('Last 12 months'),
  tooMuchData: I18n.get('Too much data to display'),
  resetFields: I18n.get('Reset all fields'),
  locationFilters: I18n.get('Location of Interest'),
  organizationLabel: I18n.get('Organization'),
  sensorLabel: I18n.get('Sensor'),
  comparisonOptions: I18n.get('Compare data by:'),
  locationOption: I18n.get('Location'),
  dateOption: I18n.get('Date Ranges'),
  locationsTitle: I18n.get('Locations'),
  addDateRange: I18n.get('Add new date range'),
  duplicateZoneFilters: I18n.get('You cannot compare a location to itself, please remove the duplicate locations.'),
  duplicateDateFilters: I18n.get('You cannot compare a date range to itself, please remove the duplicate date ranges.'),
  noCommonMeasurements: I18n.get('The selected locations do not have any common measurements.')
})

export default Strings

import { updateList } from '../util'

import {
  REQUEST_CUSTOMERS,
  RECEIVE_CUSTOMERS,
  REQUEST_CUSTOMER,
  RECEIVE_CUSTOMER,
  CREATE_CUSTOMER,
  CUSTOMER_CREATED,
  UPDATE_CUSTOMER,
  CUSTOMER_UPDATED
} from '@/actions/operations/customer'

export const customerInitialState = {
  customers: [],
  customersCount: 0,
  customer: {},
  loading: false,
  error: null
}

const handlers = {
  [REQUEST_CUSTOMERS]: (state, action) => ({
    ...state,
    customers: [],
    customersCount: 0,
    error: null,
    loading: true
  }),
  [RECEIVE_CUSTOMERS]: (state, action) => ({
    ...state,
    customers: !action.error ? action.customers : [],
    customersCount: action?.count ?? 0,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_CUSTOMER]: state => ({
    ...state,
    customer: {},
    error: null,
    loading: true
  }),
  [RECEIVE_CUSTOMER]: (state, action) => ({
    ...state,
    customer: !action.error ? action.customer : state.customer,
    error: action.error ?? null,
    loading: false
  }),
  [CREATE_CUSTOMER]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [CUSTOMER_CREATED]: (state, action) => ({
    ...state,
    customer: !action.error ? {} : state.customer,
    customers: updateList(action.error, state.customers, action.customer, true),
    customersCount: !action.error
      ? state.customersCount + 1
      : state.customersCount,
    error: action.error ?? null,
    loading: false
  }),
  [UPDATE_CUSTOMER]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [CUSTOMER_UPDATED]: (state, action) => ({
    ...state,
    customer: !action.error ? {} : state.customer,
    customers: updateList(
      action.error,
      state.customers,
      action.customer,
      false
    ),
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

import { useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { I18n } from 'aws-amplify'
import { unparse } from 'papaparse'

import { Flex, Button, Box } from '@/primitives'

import OperationsDataboard from '@/components/Operations/Shared/Databoard'

import DateFilter from '../../../Shared/Filters/DateFilter'
import ProducesFilter from '../../../Shared/Filters/ProducesFilter'
import VarietiesFilter from '../../../Shared/Filters/VarietiesFilter'
import EntriesFilter from '../../../Shared/Filters/EntriesFilter'
import MultiRowSelector from './MultiRowSelector'

import useMediaQuery from '@/hooks/useMediaQuery'
import { requestDataboardCrops } from '@/actions/operations/databoard'
import {
  getDataboardSitesHierarchies,
  getDataboardCrops,
  getCurrentUserOrganizations,
  getDataboardCropArgs,
  getDataboardLoading
} from '@/reducers/selectors'

import { dataReducer, dataItemInitialState } from './state'
import { CROP_STATUSES, getCropStatusDate } from '../utils'
import {
  getZoneIdsFromPath,
  generateZoneIds,
  getOrganizationName,
  createRowCells
} from './utils'
import { formatDate, hasDataboardWarnings } from '../../utils'
import ZoneUtils, { getZoneName } from '@/Util/ZoneUtils'
import { generateDownloadCsvLink } from '@/Util/GeneralUtils'
import Strings from '../Strings'

const CropsDataboard = () => {
  const reduxDispatch = useDispatch()
  const isAboveMobileBreakpoint = useMediaQuery('min-width: 376px')

  const [state, dispatchState] = useReducer(dataReducer, {
    ...dataItemInitialState
  })

  const loading = getDataboardLoading()
  const zonesHierarchy = getDataboardSitesHierarchies()
  const strings = Strings()
  const statuses = Object.entries(CROP_STATUSES(strings))
  const statusStrings = CROP_STATUSES(strings)
  const crops = getDataboardCrops()
  const cropArgs = getDataboardCropArgs()
  const organizations = getCurrentUserOrganizations()

  const cropsTableKeys = [
    'organization',
    'site',
    'zone',
    'produce',
    'variety',
    'plantingArea',
    'seedQuantity',
    'status'
  ]

  const cropsTableRows = crops?.map(crop => {
    const {
      organizationId,
      siteId,
      zoneId,
      produce,
      variety,
      plantingArea,
      seedQuantity,
      status
    } = crop

    const statusDate = formatDate(getCropStatusDate(status, crop))

    return {
      organization: getOrganizationName(organizations, organizationId),
      site: getZoneName(zonesHierarchy, siteId),
      zone: zoneId ? getZoneName(zonesHierarchy, siteId, zoneId) : null,
      produce: produce.name,
      variety: variety.name,
      plantingArea,
      seedQuantity,
      status: `${statusStrings[status]} ${statusDate ? `(${statusDate})` : ''}`
    }
  })

  const onDownloadCSV = () => {
    if (crops.length > 0) {
      const rows = cropsTableRows.map((row, index) => {
        const currentCrop = { ...crops[index] }
        const { organization, site, zone, ...rest } = row

        Object.keys(currentCrop).forEach(key => {
          if (key.includes('Date') || key.includes('At')) {
            if (currentCrop[key]) {
              currentCrop[key] = new Date(currentCrop[key]).toISOString()
            }
          }

          if (key.toLowerCase().includes('id')) {
            delete currentCrop[key]
          }
        })

        return {
          organization,
          site,
          zone,
          ...currentCrop,
          ...rest,
          status: statusStrings[currentCrop.status]
        }
      })

      const keys = Object.keys(rows[0])
      const keyStrings = keys.map(key => {
        if (key === 'plantingArea') return strings.plantingAreaM2
        return strings[key]
      })

      const rowValues = rows.map((row, index) => {
        return keys.map(key => {
          return row[key]
        })
      })

      const data = [keyStrings, ...rowValues]
      const csv = unparse({ data })
      const { dates } = cropArgs
      const startDate = new Date(dates[0]).toISOString().split('T')[0]
      const endDate = new Date(dates[1]).toISOString().split('T')[0]
      generateDownloadCsvLink(csv, `crops-data_${startDate}_${endDate}.csv`)
    }
  }

  const onClickFetch = () => {
    const selectedZones = []
    const baseParams = {
      produceId: state.produces,
      varietyId: state.varieties,
      status: state.statuses,
      dateRange: state.dateRange
    }
    const filterParams = state.locations.map(location => {
      const { organizations, sites, zonePath } = location
      const params = {
        organizationId: organizations,
        siteId: sites,
        ...baseParams
      }

      const zoneIds = getZoneIdsFromPath(zonePath)
      if (zoneIds?.length === 0) {
        if (!selectedZones.includes(sites[0]))
          selectedZones.push({
            name: getZoneName(zonesHierarchy, sites[0]),
            zonePath
          })
      }

      if (zoneIds?.length > 0) {
        const depth = zoneIds.length - 1
        const selectedZoneId = zoneIds[depth]
        selectedZones.push({
          name: getZoneName(zonesHierarchy, params.siteId, selectedZoneId),
          zonePath
        })

        const allZoneIds = []
        const zoneDetails = ZoneUtils.getZoneFromHierarchy(
          zonesHierarchy,
          selectedZoneId
        )
        generateZoneIds(allZoneIds, zoneDetails)

        params['zoneId'] = allZoneIds
      }

      return params
    })

    reduxDispatch(
      requestDataboardCrops({
        filterRef: { zones: selectedZones, dates: state.dateRange },
        filters: filterParams
      })
    )
  }

  let graphData = []

  if (cropArgs?.dates?.length > 1) {
    graphData = crops.map(crop => {
      const rowData = createRowCells(crop, cropArgs)

      return {
        id: `${getZoneName(zonesHierarchy, crop.siteId, crop.zoneId)}.${
          crop?.produce?.name
        }.${crop?.variety?.name}`,
        data: rowData
      }
    })
  }

  return (
    <OperationsDataboard
      section='crops'
      keys={cropsTableKeys}
      data={cropsTableRows}
      graphData={graphData}
      onDownloadCSV={onDownloadCSV}
    >
      <Flex
        key='main'
        direction={isAboveMobileBreakpoint ? 'row' : 'column'}
        axisGap='300'
        alignMainAxis='stretch'
      >
        <Box style={{ flexGrow: '1' }}>
          <ProducesFilter
            state={state}
            dispatchState={dispatchState}
            supportMultiOrg={true}
          />
        </Box>
        <Box style={{ flexGrow: '1' }}>
          <VarietiesFilter state={state} dispatchState={dispatchState} />
        </Box>
        <Box style={{ flexGrow: '1' }}>
          <EntriesFilter
            state={state}
            dispatchState={dispatchState}
            stateKey='statuses'
            entries={statuses}
            defaultText={I18n.get('All Statuses')}
          />
        </Box>
        <Box style={{ flexGrow: '1' }}>
          <DateFilter
            state={state}
            dispatchState={dispatchState}
            stateKey='dateRange'
            isDataboard={true}
          />
        </Box>
        <Button
          onClick={onClickFetch}
          variant='primary'
          size='small'
          disabled={hasDataboardWarnings(state.warnings) || loading}
          loading={loading}
          style={{ width: '6rem' }}
        >
          {I18n.get('Fetch')}
        </Button>
      </Flex>
      <MultiRowSelector state={state} dispatchState={dispatchState} key='sub' />
    </OperationsDataboard>
  )
}

export default CropsDataboard

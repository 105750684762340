import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { nanoid } from 'nanoid'

import { fetchAverageData } from '@/actions/ts/dashboard'
import {
  getDashboardDataSeed,
  getDashboardData,
  getZoneHierarchyLoading
} from '@/reducers/selectors'
import TimeUtils from '@/Util/TimeUtils'

import { useDashboard } from '@/components/DashboardPageV2/Desktop/context'
import { getRanges } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'

export default function useAverageData({ measurements, range }) {
  if (!measurements || !range) {
    throw new Error('useAverageData requires measurements and range')
  }

  const stringifiedMeasurements = JSON.stringify(measurements)

  const dispatch = useDispatch()
  const dashboardData = getDashboardData()
  const dashboardDataSeed = getDashboardDataSeed()
  const zoneHierarchyLoading = getZoneHierarchyLoading()

  const { state } = useDashboard()

  const [averageData, setAverageData] = useState({})

  const [prevDashboardDataSeed, setPrevDashboardDataSeed] =
    useState(dashboardDataSeed)

  const { currentZone, siteId, hierarchy } = state

  useEffect(() => {
    if (currentZone && siteId && hierarchy && !zoneHierarchyLoading) {
      const timeZone = hierarchy?.timeZone ?? 'UTC'
      const measurements = JSON.parse(stringifiedMeasurements)
      const fromDate = TimeUtils.initStartDateTVariant(
        timeZone,
        getRanges(range)?.query[0],
        getRanges(range)?.query[1]
      )
      const toDate = TimeUtils.initEndDateT(timeZone)
      const params = {
        organizationId: hierarchy.organizationId,
        siteId: siteId,
        table: siteId,
        sensorIds: [currentZone.id],
        measurements,
        fromDate: TimeUtils.formatToUTC(fromDate),
        toDate: TimeUtils.formatToUTC(toDate),
        rangeFilter: range
      }

      dispatch(fetchAverageData(params, nanoid(5)))
    }
  }, [
    currentZone.id,
    siteId,
    hierarchy,
    zoneHierarchyLoading,
    currentZone,
    range,
    dispatch,
    stringifiedMeasurements
  ])

  // Adjust the state directly during rendering

  if (currentZone && dashboardDataSeed !== prevDashboardDataSeed) {
    setPrevDashboardDataSeed(dashboardDataSeed)

    let data = {}
    for (const measurement of measurements) {
      const averageRange =
        dashboardData?.[currentZone.id]?.[measurement]?.average?.[range]
      data[measurement] = averageRange
    }

    setAverageData(data)
  }

  return averageData
}

import { DateTime } from 'luxon'
import { I18n } from 'aws-amplify'
import { useEffect, useState } from 'react'

import { FlexV2, Icon, Text } from '@/primitives'
import { STATUS_SYMBOL_COLOR } from '../utils'
import { useDashboard } from '../../context'
import { ADD_DETAILED_SENSOR_STATUSES } from '../../state'

import { getZoneHierarchy, getCurrentUser } from '@/reducers/selectors'
import {
  convertCelciusToFahrenheit,
  CONVERSION_TEMPERATURE_MEASUREMENTS,
  FAHRENHEIT_UNIT
} from '@/Util/MeasurementUtils'
import {
  SETTING_CELCIUS_TO_FAHRENHEIT,
  processSettings
} from '@/Util/SettingsUtils'

import style from '../Card.module.scss'

export function DetailedCard({ item }) {
  const {
    measurementName,
    measurementUnit,
    statusInfo,
    rootId,
    measurementId
  } = item

  const { dispatchState } = useDashboard()
  const [useFahrenheit, setUseFahrenheit] = useState(false)

  const hierarchy = getZoneHierarchy()
  const coretexUser = getCurrentUser()

  useEffect(() => {
    dispatchState({
      type: ADD_DETAILED_SENSOR_STATUSES,
      payload: item
    })
  }, [item])

  useEffect(() => {
    if (coretexUser?.userName && Object.keys(hierarchy)?.length > 0) {
      const organizationId = hierarchy[rootId]?.organizationId

      const enableFahrenheit = processSettings(
        coretexUser?.settings,
        SETTING_CELCIUS_TO_FAHRENHEIT,
        coretexUser.userName,
        organizationId
      )

      if (
        enableFahrenheit &&
        CONVERSION_TEMPERATURE_MEASUREMENTS.includes(measurementId)
      ) {
        setUseFahrenheit(true)
      }
    }
  }, [coretexUser, hierarchy, useFahrenheit, measurementId, rootId])

  const message =
    statusInfo.status.boundType === 'upper'
      ? I18n.get('is above')
      : I18n.get('is below')

  function insertValue(value) {
    if (useFahrenheit) {
      return convertCelciusToFahrenheit(value).toFixed(2)
    }

    return value.toFixed(2)
  }

  return (
    <FlexV2 className={style.card} axisGap={400}>
      <Icon
        size={500}
        tone={500}
        variant={STATUS_SYMBOL_COLOR[statusInfo.status.level]}
        style={{
          alignSelf: 'center'
        }}
        name='warning'
      />
      <FlexV2 direction='column' axisGap={300}>
        <FlexV2 direction='column'>
          <Text variant='page' tone={800}>
            {measurementName} {message}:{' '}
            <Text>
              {insertValue(statusInfo.status.thresholdValue)}{' '}
              {useFahrenheit ? FAHRENHEIT_UNIT : measurementUnit}
            </Text>
          </Text>
          <Text variant='page' tone={800} style={{ marginTop: '1em' }}>
            {I18n.get('Exceeded on')}:{' '}
            <Text>
              {DateTime.fromMillis(statusInfo.sinceWhen).toFormat(
                "LLL dd yyyy 'at' hh:mm"
              )}
            </Text>
          </Text>
        </FlexV2>
      </FlexV2>
    </FlexV2>
  )
}

import { Switch, Route } from 'react-router-dom'
import OrganizationsPage from './OrganizationPage'
import OrganizationLabels from './OrganizationLabels'

export default function Organizations() {
  return (
    <Switch>
      <Route path='/admin/organizations/:organizationId/labels'>
        <OrganizationLabels />
      </Route>

      <Route path='/admin/organizations'>
        <OrganizationsPage />
      </Route>
    </Switch>
  )
}

import { Fragment, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  Flex,
  Button,
  Cell,
  Text,
  Column,
  Row,
  Slot,
  Table,
  Loader
} from '@/primitives'

import { Pagination } from '@/elements'

import HeaderV2 from '../../Shared/HeaderV2'
import TableFilter from './TableFilter'

import { requestPackageUnits } from '@/actions/operations/packageUnit'

import {
  getCurrentUser,
  getOperationsPackageUnits,
  getOperationsPackageUnitsCount,
  getOperationsLoading
} from '@/reducers/selectors'

import history from '../../../../history'

import useMediaQuery from '@/hooks/useMediaQuery'

import Strings from './Strings'

import { PACKAGE_UNIT_CATEGORIES, PACKAGE_UNIT_STATUSES } from './utils'

import {
  hasEditPermissions,
  RESOURCE_TYPE_OPERATIONS,
  RESOURCE_TYPE_OPERATIONS_MANAGEMENT
} from '@/Util/PermissionUtils'

function PackageUnitTable({ state, dispatchState, modulePath }) {
  const dispatch = useDispatch()

  const currentUser = getCurrentUser()
  const packageUnits = getOperationsPackageUnits()
  const packageUnitsCount = getOperationsPackageUnitsCount()
  const loading = getOperationsLoading()

  const strings = Strings()

  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(9)
  const [tableState, setTableState] = useState(state)
  const [canEdit, setCanEdit] = useState(false)
  const [fetchCalled, setFetchCalled] = useState(false)

  const packageUnitCategories = PACKAGE_UNIT_CATEGORIES(strings)
  const packageUnitStatuses = PACKAGE_UNIT_STATUSES(strings)

  const isAboveCustomBreakpoint = useMediaQuery('min-width: 620px')

  const fetchPackageUnits = useCallback(
    page => {
      const { organizations, categories, statuses } = state
      if (
        organizations.length > 0 &&
        categories.length > 0 &&
        statuses.length > 0
      ) {
        dispatch(
          requestPackageUnits({
            filter: {
              organizationId: organizations,
              category: categories,
              status: statuses
            },
            limit: pageSize,
            offset: page - 1
          })
        )
        setFetchCalled(true)
      }
    },
    [state, pageSize, dispatch]
  )

  const changePage = useCallback(
    newPage => {
      dispatchState({ page: newPage })
      setFetchCalled(false)
    },
    [dispatchState]
  )

  useEffect(() => {
    if (currentUser.userName) {
      setCanEdit(
        hasEditPermissions(
          currentUser,
          RESOURCE_TYPE_OPERATIONS,
          RESOURCE_TYPE_OPERATIONS_MANAGEMENT
        )
      )
    }
  }, [currentUser])

  useEffect(() => {
    if (
      (tableState.organizations !== state.organizations ||
        tableState.page !== state.page) &&
      !fetchCalled
    ) {
      fetchPackageUnits(state.page)
    } else if (
      tableState.statuses !== state.statuses ||
      tableState.categories !== state.categories
    ) {
      fetchPackageUnits(1)
    }
  }, [state, tableState, fetchPackageUnits, fetchCalled])

  useEffect(() => {
    setTableState({ ...state })
  }, [state])

  const onCreate = () => history.push(`${modulePath}/create`)

  const onEdit = id => history.push(`${modulePath}/update/${id}`)

  return (
    <Fragment>
      <HeaderV2
        title={strings.managePackageUnits}
        buttonIcon={'add'}
        buttonText={strings.addPackageUnit}
        buttonCallback={onCreate}
        showButton={canEdit}
        breakpointWidth={800}
      >
        <TableFilter state={state} dispatchState={dispatchState} />
      </HeaderV2>
      <Loader isLoading={loading}>
        {packageUnitsCount === 0 && (
          <Flex className={'Operations__Table__Empty'}>
            <Text>{strings.noPackageUnits}</Text>
          </Flex>
        )}
        {packageUnitsCount > 0 && (
          <Table aria-colcount='4' className='Operations__Table'>
            <Slot name='head'>
              <Row>
                <Column>{strings.category}</Column>
                <Column>{strings.unit}</Column>
                <Column>{strings.status}</Column>
                <Column></Column>
              </Row>
            </Slot>
            <Slot name='body'>
              {packageUnits.map(({ id, category, status, name }) => (
                <Row key={id}>
                  <Cell columnName={strings.category}>
                    {packageUnitCategories[category]}
                  </Cell>
                  <Cell columnName={strings.unit}>{name}</Cell>
                  <Cell columnName={strings.status}>
                    {packageUnitStatuses[status]}
                  </Cell>
                  <Cell className='Operations__Table__Column__Actions'>
                    {canEdit && (
                      <Button
                        variant='info'
                        size='small'
                        iconBefore='edit'
                        onClick={() => onEdit(id)}
                      />
                    )}
                  </Cell>
                </Row>
              ))}
            </Slot>
          </Table>
        )}
        {packageUnitsCount > pageSize && (
          <Pagination
            type={isAboveCustomBreakpoint ? 'simple' : 'compact'}
            showPageCount={isAboveCustomBreakpoint}
            totalItems={packageUnitsCount}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            page={tableState.page}
            setPage={changePage}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        )}
      </Loader>
    </Fragment>
  )
}

export default PackageUnitTable

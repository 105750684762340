import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { fetchDevice } from '@/slices/management/device'
import { fetchCurrentData } from '@/actions/ts/dashboard'

import {
  getZonesHierarchy,
  getDevice,
  getMeasurements,
  getDashboardData,
  getDeviceTypes,
  getSensorThresholdStatuses,
  getOfflineDevices,
  getCurrentUser,
  getNavigationLocationSelector
} from '@/reducers/selectors'

import {
  RESOURCE_TYPE_DEVICE,
  RESOURCE_TYPE_DEVICE_CALIBRATION,
  hasEditPermissions
} from '@/Util/PermissionUtils'

import {
  SETTING_CELCIUS_TO_FAHRENHEIT,
  processSettings
} from '@/Util/SettingsUtils'

import ZoneUtils from '@/Util/ZoneUtils'

import { Button } from '@/primitives'
import { LineSeparator } from '@/elements'

import { displayType } from '../../utils'

import Icon from '../Icon'
import Alert from './Alert'
import Widget from './Widget'

import history from '../../../../history'
import useLiveData from '../../useLiveData'

const Device = ({ deviceId }) => {
  const dispatch = useDispatch()
  
  const locationSelector = getNavigationLocationSelector()

  const currentUser = getCurrentUser()
  const zonesHierarchy = getZonesHierarchy()
  const allMeasurements = getMeasurements()
  const allDeviceTypes = getDeviceTypes()
  const rootStatuses = getSensorThresholdStatuses()
  const offlineDevices = getOfflineDevices()
  const data = getDashboardData()

  const device = getDevice()

  const [measurements, setMeasurements] = useState([])
  const [values, setValues] = useState([])
  const [alerts, setAlerts] = useState({})
  const [useFahrenheit, setUseFahrenheit] = useState(false)

  const organization = locationSelector.get('organization')

  const canEditCalibration = hasEditPermissions(
    currentUser,
    RESOURCE_TYPE_DEVICE,
    RESOURCE_TYPE_DEVICE_CALIBRATION
  )

  useLiveData({
    deviceId,
    device,
    measurements
  })

  useEffect(() => {
    if (organization?.id) {
      const enableFahrenheit = processSettings(
        currentUser?.settings,
        SETTING_CELCIUS_TO_FAHRENHEIT,
        currentUser.userName,
        organization.id
      )
      setUseFahrenheit(enableFahrenheit)
    }
  }, [organization])

  useEffect(() => {
    if (deviceId) {
      dispatch(fetchDevice({ deviceId: deviceId }))
    }
  }, [deviceId])

  useEffect(() => {
    if (device?.id === deviceId) {
      if (device?.config?.read_measurementType?.length > 0) {
        setMeasurements(device.config.read_measurementType)
      } else {
        for (let deviceType of allDeviceTypes) {
          if (deviceType.id === device.sensorType) {
            setMeasurements(deviceType.measurements)
            break
          }
        }
      }
    }
  }, [deviceId, device?.id])

  useEffect(() => {
    const newAlerts = {}
    if (device?.id === deviceId && rootStatuses?.length > 0) {
      for (let status of rootStatuses) {
        if (status.sensorId === device.id) {
          const [measurementId] = status.measurementIdAndZoneId.split('#')
          newAlerts[status.status.level] = {
            status: status.status.level,
            sendingData: status.sendingData,
            measurementId: measurementId.toLowerCase(),
            thresholdValue: status.status.thresholdValue,
            boundType: status.status.boundType,
            time: status.sinceWhen
          }
        }
      }
    }
    if (device?.id === deviceId && Object.keys(offlineDevices)?.length > 0) {
      const siteId = getSiteId(device.zonePath)
      if (offlineDevices?.[siteId]?.length > 0) {
        for (let status of offlineDevices[siteId]) {
          if (status.deviceId === device.id) {
            const measurements =
              status.status === 'missingData' ? status.reasons.missingData : []
            newAlerts[status.status] = {
              status: status.status,
              sendingData: false,
              measurements: measurements,
              reasons: status.reasons,
              time: status.lastChecked * 1000
            }
          }
        }
      }
    }
    setAlerts(newAlerts)
  }, [deviceId, device?.id, rootStatuses, offlineDevices])

  const getSiteId = path => {
    return path.substr(1).split('/')[0]
  }

  useEffect(() => {}, [deviceId, device?.id, offlineDevices])

  useEffect(() => {
    if (device?.id === deviceId) {
      if (measurements.length > 0) {
        const payload = {
          table: getSiteId(device.zonePath),
          sensorId: device.id,
          measurements: measurements
        }
        if (device?.sensorType?.includes('cellular')) {
          payload.isCellular = true
        }
        dispatch(fetchCurrentData(payload))
      }
    }
  }, [measurements, deviceId, device?.id])

  useEffect(() => {
    const newValues = []
    measurements.forEach(m => {
      if (data?.[deviceId]?.[m]?.current) {
        newValues.push({
          measurement: m,
          current: data[deviceId][m]?.current?.value,
          min: data[deviceId][m]?.current?.min,
          max: data[deviceId][m]?.current?.max
        })
      } else {
        newValues.push({
          measurement: m,
          current: null,
          min: null,
          max: null
        })
      }
    })
    setValues(newValues)
  }, [data, measurements])

  const getSiteName = () => {
    const siteId = getSiteId(device.zonePath)
    return zonesHierarchy[siteId]?.name ?? siteId
  }

  const getZoneName = () => {
    const siteId = getSiteId(device.zonePath)
    const zone = ZoneUtils.getZoneFromHierarchy(
      zonesHierarchy[siteId].children,
      device.zoneId
    )
    return zone?.name ?? device.zoneId
  }

  const goToDashboard = () => {
    history.push(
      `/zones${device.zonePath}/sensor/${device.sensorType}/${device.id}`
    )
  }

  const goToDataboard = () => {
    history.push(
      `/zones${device.zonePath}/sensor/${device.sensorType}/${device.id}/data`
    )
  }

  const goToCalibration = () => {
    history.push(
      `/zones${device.zonePath}/sensor/${device.sensorType}/${device.id}/calibration`
    )
  }

  // @todo the device endpoint will need to be implemented
  /*const goToSettings = () => {
    history.push(`/zones${device.zonePath}/devices/${device.id}`)
  }*/

  if (device?.id === deviceId) {
    return (
      <div className='device'>
        <div>
          <div>
            <div className='zones'>
              <Link to={`/zones/${getSiteId(device.zonePath)}`} target='blank'>
                {getSiteName()}
              </Link>
              <span className='separator'>/</span>
              <Link to={`/zones${device.zonePath}`} target='blank'>
                {getZoneName()}
              </Link>
            </div>
            <div className='name'>
              <Icon deviceType={device.sensorType} className='icon' />
              {device.name}
            </div>
            {device?.tag && <div className='tag'>{device.tag}</div>}
            <div className='type'>{displayType(device.sensorType)}</div>
          </div>
        </div>
        <LineSeparator />
        <div>
          {Object.keys(alerts)?.map(status => (
            <Alert
              key={status}
              alert={alerts[status]}
              measurements={allMeasurements}
              useFahrenheit={useFahrenheit}
            />
          ))}
        </div>
        <div>
          {values.map(value => (
            <Widget
              key={value.measurement}
              name={value.measurement}
              value={value.current}
              min={value.min}
              max={value.max}
              direction={'equal'} // @todo handle this properly
              measurements={allMeasurements}
              alerts={alerts}
              useFahrenheit={useFahrenheit}
            />
          ))}
        </div>
        <div className='actions'>
          <Button
            variant='info'
            size='small'
            iconBefore='dashboard'
            onClick={goToDashboard}
          />
          <Button
            variant='info'
            size='small'
            iconBefore='query_stats'
            onClick={goToDataboard}
          />
          {device?.isIoT && canEditCalibration && (
            <Button
              variant='info'
              size='small'
              iconBefore='tune'
              onClick={goToCalibration}
            />
          )}
          {
            // @todo implement endpoint
            /*<Button
            variant='info'
            size='small'
            iconBefore='settings'
            onClick={goToSettings}
          />*/
          }
        </div>
      </div>
    )
  }

  return null
}

export default Device

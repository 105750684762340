import { makeActionCreator } from '@/Util/ReduxUtils'

export const CREATE_UNIT_PRICE = 'CREATE_UNIT_PRICE'
export const createUnitPrice = makeActionCreator(CREATE_UNIT_PRICE, 'params')

export const UNIT_PRICE_CREATED = 'UNIT_PRICE_CREATED'
export const unitPriceCreated = makeActionCreator(
  UNIT_PRICE_CREATED,
  'unitPrice',
  'error'
)

export const UPDATE_UNIT_PRICE = 'UPDATE_UNIT_PRICE'
export const updateUnitPrice = makeActionCreator(UPDATE_UNIT_PRICE, 'params')

export const UNIT_PRICE_UPDATED = 'UNIT_PRICE_UPDATED'
export const unitPriceUpdated = makeActionCreator(
  UNIT_PRICE_UPDATED,
  'unitPrice',
  'error'
)

export const REQUEST_UNIT_PRICES = 'REQUEST_UNIT_PRICES'
export const requestUnitPrices = makeActionCreator(
  REQUEST_UNIT_PRICES,
  'params'
)

export const RECEIVE_UNIT_PRICES = 'RECEIVE_UNIT_PRICES'
export const receiveUnitPrices = makeActionCreator(
  RECEIVE_UNIT_PRICES,
  'unitPrices',
  'count',
  'error'
)

export const REQUEST_UNIT_PRICE = 'REQUEST_UNIT_PRICE'
export const requestUnitPrice = makeActionCreator(REQUEST_UNIT_PRICE, 'params')

export const RECEIVE_UNIT_PRICE = 'RECEIVE_UNIT_PRICE'
export const receiveUnitPrice = makeActionCreator(
  RECEIVE_UNIT_PRICE,
  'unitPrice',
  'error'
)

export const DELETE_UNIT_PRICE = 'DELETE_UNIT_PRICE'
export const deleteUnitPrice = makeActionCreator(DELETE_UNIT_PRICE, 'params')

export const UNIT_PRICE_DELETED = 'UNIT_PRICE_DELETED'
export const unitPriceDeleted = makeActionCreator(
  UNIT_PRICE_DELETED,
  'unitPrice',
  'error'
)

export const CLEAR_UNIT_PRICES = 'CLEAR_UNIT_PRICES'
export const clearUnitPrices = makeActionCreator(CLEAR_UNIT_PRICES)

import { I18n } from 'aws-amplify'

const Strings = () => ({
  manageReturns: I18n.get('Manage Returns'),
  createReturn: I18n.get('Create Return'),
  createFormHeader: I18n.get('Create New Return'),
  updateFormHeader: I18n.get('Update Existing Return'),
  return: I18n.get('Return'),
  returnDate: I18n.get('Return Date'),
  referenceNumber: I18n.get('Reference Number'),
  branch: I18n.get('Branch'),
  customer: I18n.get('Customer'),
  product: I18n.get('Product'),
  noReturns: I18n.get('No returns'),
  noReturnItems: I18n.get('No items'),
  units: I18n.get('Units'),
  selectDefault: I18n.get('Please Select'),
  saveReturn: I18n.get('Save Return'),
  addReturnItem: I18n.get('Add Return Item'),
  returnItems: I18n.get('Items'),
  returnItem: I18n.get('Return Item'),
  saleItemAmount: I18n.get('Amount'),
  formSaveBtn: I18n.get('Save'),
  formCancelBtn: I18n.get('Discard'),
  quantityReturned: I18n.get('Quantity Returned'),
  quantity: I18n.get('Quantity'),
  grams: I18n.get('g'),
  kilograms: I18n.get('kg'),
  reason: I18n.get('Reason'),
  invalid: I18n.get('Unordered'),
  unsold: I18n.get('Unsold'),
  damaged: I18n.get('Damaged'),
  quality: I18n.get('Low Quality')
})

export default Strings

import { I18n } from 'aws-amplify'
import Dialog from '@/elements/Dialog'
import { Button, FlexV2, Slot, Text } from '@/primitives'
import { getDeviceLoading, getZone } from '@/reducers/selectors'

import Strings from '../../../../Strings'
import { getDisplayNamePath, getMovedParentPath } from '../../../Utils/Zone'
import {
  CANCEL_DEVICE_MOVE,
  CONFIRM_DEVICE_MOVE,
  SET_SELECTED_ZONE_TO_MOVE_TO,
  SET_SHOW_DEVICE_MOVER_VIEW
} from '../state'
import Selector from './Selector'

export default function DeviceMover({ state, stateDispatch }) {
  const strings = Strings()
  const isLoading = getDeviceLoading()
  const zone = getZone()

  function onShowDeviceMover(value) {
    stateDispatch({
      type: SET_SHOW_DEVICE_MOVER_VIEW,
      payload: value
    })
  }

  function onCancelZoneMover() {
    stateDispatch({ type: CANCEL_DEVICE_MOVE })
  }

  function setSelectedZoneToMoveTo(zone) {
    stateDispatch({
      type: SET_SELECTED_ZONE_TO_MOVE_TO,
      payload: zone
    })
  }

  const onConfirmZoneMover = () => {
    if (state.selectedZoneToMoveTo) {
      const targetMove = {
        id: state.device.id,
        name: state.device.name,
        path: getMovedParentPath('', state.selectedZoneToMoveTo.parentPath)
      }
      const displayPath = getDisplayNamePath(
        state.siteHierarchy,
        state.selectedZoneToMoveTo.parentPath,
        targetMove
      )

      stateDispatch({
        type: CONFIRM_DEVICE_MOVE,
        payload: {
          displayPath,
          zoneId: state.selectedZoneToMoveTo.id,
          zonePath: targetMove.path
        }
      })
    }
  }

  return (
    <Dialog
      open={state.showDeviceMoverView}
      onOpenChange={onShowDeviceMover}
      type='offcanvas'
      className='ZoneDetails__OffCanvas'
      style={{ zIndex: 3 }}
    >
      <Slot name='title'>
        <Text size={300} fontWeight={700}>
          {I18n.get('Move Device')}
        </Text>
      </Slot>
      <Slot name='content'>
        <Selector
          state={state.device}
          currentZone={zone}
          selectedZoneToMoveTo={state.selectedZoneToMoveTo}
          setSelectedZoneToMoveTo={setSelectedZoneToMoveTo}
          siteHierarchy={state.siteHierarchy}
        />
      </Slot>
      <Slot name='actions'>
        <FlexV2 axisGap={400} alignMainAxis={'space-between'}>
          <Button onClick={onCancelZoneMover}>{strings.cancel}</Button>
          <Button
            variant='primary'
            disabled={!state.selectedZoneToMoveTo}
            onClick={onConfirmZoneMover}
            loading={isLoading}
            style={{ width: '9rem' }}
          >
            {I18n.get('Confirm move')}
          </Button>
        </FlexV2>
      </Slot>
    </Dialog>
  )
}

import { Box } from '../..'

/* -----------------------------------------------------------------------------
 * Column
 * ---------------------------------------------------------------------------*/

/**
 * Column Primitive
 * @param {Object} props
 * @param {import('react').Children} [props.children=null] Column Content
 * @param {import('react').CSSProperties} [props.css] IonCSS Styles
 *
 * @example
 * ```jsx
 * <Table>
 *   <Slot name='head'>
 *    <Row>
 *      <Column />
 *      <Column>User</Column>
 *    </Row>
 *  </Slot>
 * </Table>
 * ```
 */

const Column = ({ children, ...rest }) => (
  <Box as='th' {...rest}>
    {children}
  </Box>
)

export default Column

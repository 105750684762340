import { useCallback, useEffect } from 'react'

import { FlexV2, Input } from '@/primitives'

import Strings from '../Strings'

/**
 * A search component for searching sensors by name.
 *
 * @param {object} props - The component props.
 * @param {object} props.state - The state of the search component.
 * @param {Function} props.dispatchState - A function to dispatch state updates.
 * @return {JSX.Element} The rendered Search component.
 */
export default function Search({ state, dispatchState }) {
  const strings = Strings()

  const handleChange = useCallback(
    ({ target: { value } }) => {
      if (value !== undefined) {
        dispatchState({
          type: 'SET_SEARCH_VALUE',
          payload: value
        })
      }
    },
    [dispatchState]
  )

  useEffect(() => {
    dispatchState({ type: 'CLEAR_SEARCH_VALUE' })
  }, [dispatchState])

  return (
    <FlexV2 as='form' className='StatusPage__Search' autoComplete='off'>
      <Input
        className='Input'
        name='search'
        type='search'
        placeholder={strings.searchBySensorName}
        value={state.searchValue}
        onChange={handleChange}
      />
    </FlexV2>
  )
}

import { updateList } from '../util'

import {
  CLEAR_RETURN,
  REQUEST_RETURNS,
  RECEIVE_RETURNS,
  REQUEST_RETURN,
  RECEIVE_RETURN,
  CREATE_RETURN,
  RETURN_CREATED,
  UPDATE_RETURN,
  RETURN_UPDATED
} from '@/actions/operations/return'

export const returnInitialState = {
  returns: [],
  returnsCount: 0,
  return: {},
  loading: false,
  error: null
}

const handlers = {
  [CLEAR_RETURN]: state => ({
    ...state,
    return: {}
  }),
  [REQUEST_RETURNS]: (state, action) => ({
    ...state,
    returns: [],
    returnsCount: 0,
    error: null,
    loading: true
  }),
  [RECEIVE_RETURNS]: (state, action) => ({
    ...state,
    returns: !action.error ? action.returns : [],
    returnsCount: action?.count ?? 0,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_RETURN]: state => ({
    ...state,
    return: {},
    error: null,
    loading: true
  }),
  [RECEIVE_RETURN]: (state, action) => ({
    ...state,
    return: !action.error ? action.return : state.return,
    error: action.error ?? null,
    loading: false
  }),
  [CREATE_RETURN]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [RETURN_CREATED]: (state, action) => ({
    ...state,
    return: !action.error ? {} : state.return,
    returns: updateList(action.error, state.returns, action.return, true),
    returnsCount: !action.error ? state.returnsCount + 1 : state.returnsCount,
    error: action.error ?? null,
    loading: false
  }),
  [UPDATE_RETURN]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [RETURN_UPDATED]: (state, action) => ({
    ...state,
    return: !action.error ? {} : state.return,
    returns: updateList(action.error, state.returns, action.return, false),
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

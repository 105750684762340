import * as templates from './templates'

export const menuData = ({
  level,
  locationSelector,
  permissions,
  strings,
  currentUser
}) => {
  return templates[level]
    ? templates[level]({
        level,
        locationSelector,
        permissions,
        strings,
        currentUser
      })
    : null
}

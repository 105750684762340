import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {
  createDeviceType,
  getDeviceFirmwareVersions,
  updateDeviceType
} from '@/api/deviceManagement/deviceType'

import { isLoading } from '@/slices/util'

import {
  fetchMeasurementsAndDeviceTypes,
  fetchDeviceManagementOptions
} from './index'

const getSortedDeviceTypes = deviceTypes => {
  const updatedDeviceTypes = [...deviceTypes]
  updatedDeviceTypes.sort((a, b) => {
    return a.name.localeCompare(b.name)
  })
  return updatedDeviceTypes
}

export const fetchDeviceFirmwareVersions = createAsyncThunk(
  'fetchDeviceFirmwareVersions',
  async (params, { dispatch }) => {
    dispatch(isLoading(true))
    const { firmwareVersions, error } = await getDeviceFirmwareVersions(params)
    dispatch(isLoading(false))
    return { firmwareVersions, error }
  }
)

export const sendCreateDeviceType = createAsyncThunk(
  'sendCreateDeviceType',
  async (params, { dispatch }) => {
    dispatch(isLoading(true))
    const { deviceType, error } = await createDeviceType(params)
    dispatch(isLoading(false))
    return { deviceType, error }
  }
)

export const sendUpdateDeviceType = createAsyncThunk(
  'sendUpdateDeviceType',
  async (params, { dispatch }) => {
    dispatch(isLoading(true))
    const { deviceType, error } = await updateDeviceType(params)
    dispatch(isLoading(false))
    return { deviceType, error }
  }
)

const deviceManagementDeviceTypeSLice = createSlice({
  name: 'deviceManagementDeviceTypeReducer',
  initialState: {
    deviceTypes: [],
    updatedDeviceType: {},
    deviceTypeTypes: [],
    firmwareVersions: [],
    error: null,
    isSaving: false
  },
  reducers: {
    cleanDeviceType: state => {
      state.updatedDeviceType = {}
    },
    setDeviceType: (state, action) => {
      state.updatedDeviceType = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchMeasurementsAndDeviceTypes.pending, (state, action) => {
        state.error = null
      })
      .addCase(fetchMeasurementsAndDeviceTypes.fulfilled, (state, action) => {
        const { deviceTypes, deviceTypesError } = action?.payload
        state.deviceTypes = deviceTypesError
          ? state.deviceTypes
          : getSortedDeviceTypes(deviceTypes)
      })
      .addCase(fetchDeviceManagementOptions.pending, (state, action) => {
        state.error = null
      })
      .addCase(fetchDeviceManagementOptions.fulfilled, (state, action) => {
        const {
          deviceTypes,
          deviceTypesError,
          deviceTypeTypes,
          deviceTypeTypesError
        } = action?.payload
        state.deviceTypes = deviceTypesError
          ? state.deviceTypes
          : getSortedDeviceTypes(deviceTypes)
        state.deviceTypeTypes = deviceTypeTypesError
          ? state.deviceTypeTypes
          : deviceTypeTypes
      })
      .addCase(fetchDeviceFirmwareVersions.pending, (state, action) => {
        state.error = null
      })
      .addCase(fetchDeviceFirmwareVersions.fulfilled, (state, action) => {
        const { firmwareVersions, error } = action?.payload
        state.firmwareVersions = error
          ? state.firmwareVersions
          : firmwareVersions
        state.error = error ?? null
      })
      .addCase(sendCreateDeviceType.pending, (state, action) => {
        state.error = null
        state.isSaving = true
        state.updatedDeviceType = {}
      })
      .addCase(sendCreateDeviceType.fulfilled, (state, action) => {
        const { deviceType, error } = action?.payload
        state.deviceTypes = error
          ? state.deviceTypes
          : getSortedDeviceTypes([...state.deviceTypes, deviceType])
        state.updatedDeviceType = error ? state.updatedDeviceType : deviceType
        state.error = error ?? null
        state.isSaving = false
      })
      .addCase(sendUpdateDeviceType.pending, (state, action) => {
        state.error = null
        state.isSaving = true
        state.updatedDeviceType = {}
      })
      .addCase(sendUpdateDeviceType.fulfilled, (state, action) => {
        const { deviceType, error } = action?.payload
        state.deviceTypes = error
          ? state.deviceTypes
          : getSortedDeviceTypes(
              state.deviceTypes.map(dt => {
                if (dt.id === deviceType.id) {
                  return deviceType
                }
                return dt
              })
            )
        state.updatedDeviceType = error ? state.updatedDeviceType : deviceType
        state.error = error ?? null
        state.isSaving = false
      })
  }
})

export const { cleanDeviceType, setDeviceType } =
  deviceManagementDeviceTypeSLice.actions

export default deviceManagementDeviceTypeSLice.reducer

import { I18n } from 'aws-amplify'
import { number, object, string, array, date } from 'yup'

import { weightSchema } from '../../../Shared/Weights/utils'

export const FIELDS = {
  id: 'id',
  organizationId: 'organizationId',
  produceId: 'produceId',
  varietyId: 'varietyId',
  storageFacilityId: 'storageFacilityId',
  weights: 'weights',
  totalWeight: 'totalWeight',
  harvestDate: 'harvestDate'
}

export const SCHEMA = () => {
  return object().shape({
    organizationId: string().required(
      I18n.get('An organization must be selected.')
    ),
    zoneId: string().required(I18n.get('A location must be selected.')),
    produceId: string().required(I18n.get('Produce is required.')),
    varietyId: string().required(I18n.get('Variety is required.')),
    storageFacilityId: string().required(
      I18n.get('A storage facility is required.')
    ),
    harvestDate: date()
      .typeError('A harvest date is required.')
      .max(
        new Date(),
        I18n.get('The harvest date cannot be set in the future.')
      )
      .required(I18n.get('A harvest date is required.')),
    weights: array()
      .of(weightSchema)
      .min(1, I18n.get('At least one weight is required.')),
    totalWeight: number(),
    photos: array()
  })
}

import { unparse } from 'papaparse'
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  requestHarvests,
  requestAllHarvests,
  clearAllHarvests,
  deleteHarvest
} from '@/actions/operations/harvest'

import { requestAllGrades } from '@/actions/operations/grade'

import {
  getCurrentUser,
  getCurrentUserOrganizations,
  getZonesHierarchy,
  getOperationsHarvests,
  getOperationsHarvestsCount,
  getOperationsAllHarvests,
  getOperationsAllGrades,
  getOperationsLoading
} from '@/reducers/selectors'

import {
  Flex,
  Box,
  Table,
  Slot,
  Column,
  Row,
  Text,
  Loader,
  Button,
  FlexV2
} from '@/primitives'

import { Pagination, Dialog } from '@/elements'

import HeaderV2 from '@/components/Operations/Shared/HeaderV2'
import TableFilter from './TableFilter'
import TableRow from './TableRow'

import {
  hasReadPermissions,
  hasEditPermissions,
  RESOURCE_TYPE_OPERATIONS,
  RESOURCE_TYPE_OPERATIONS_HARVEST,
  RESOURCE_TYPE_OPERATIONS_PLANS
} from '@/Util/PermissionUtils'

import { generateDownloadCsvLink } from '@/Util/GeneralUtils'
import { DOWNLOAD_HEADERS, DOWNLOAD_VALUES } from './config'

import history from '../../../../../history'
import Strings from '../Strings'
import useMediaQuery from '@/hooks/useMediaQuery'

function HarvestTable({ state, dispatchState, modulePath }) {
  const dispatch = useDispatch()

  const isAboveBreakpoint = useMediaQuery('min-width: 1024px')

  const currentUser = getCurrentUser()
  const organizations = getCurrentUserOrganizations()
  const zonesHierarchy = getZonesHierarchy()
  const items = getOperationsHarvests()
  const totalItems = getOperationsHarvestsCount()
  const allItems = getOperationsAllHarvests()
  const grades = getOperationsAllGrades()
  const loading = getOperationsLoading()

  const strings = Strings()

  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(9)
  const [tableState, setTableState] = useState(state)
  const [canEdit, setCanEdit] = useState(false)
  const [canReadPlans, setCanReadPlans] = useState(false)
  const [requestDownload, setRequestDownload] = useState(false)

  const [dialog, setDialog] = useState({
    show: false,
    callback: null
  })

  useEffect(() => {
    if (currentUser.userName) {
      setCanEdit(
        hasEditPermissions(
          currentUser,
          RESOURCE_TYPE_OPERATIONS,
          RESOURCE_TYPE_OPERATIONS_HARVEST
        )
      )
    }
  }, [currentUser])

  useEffect(() => {
    if (currentUser.userName) {
      setCanReadPlans(
        hasReadPermissions(
          currentUser,
          RESOURCE_TYPE_OPERATIONS,
          RESOURCE_TYPE_OPERATIONS_PLANS
        )
      )
    }
  }, [currentUser])

  useEffect(() => {
    dispatch(requestAllGrades())
  }, [dispatch])

  useEffect(() => {
    if (tableState.page !== state.page || items.length === 0) {
      setTableState({ ...state })
      fetchHarvests(state.page)
    }
  }, [state.page])

  useEffect(() => {
    if (
      tableState.organizations !== state.organizations ||
      tableState.sites !== state.sites ||
      tableState.produces !== state.produces ||
      tableState.varieties !== state.varieties ||
      tableState.harvestDate !== state.harvestDate
    ) {
      setTableState({ ...state })
      fetchHarvests(1)
      changePage(1)
    }
  }, [state.sites, state.produces, state.varieties, state.harvestDate])

  useEffect(() => {
    if (requestDownload) {
      dispatch(
        requestAllHarvests({
          filter: {
            organizationId: state.organizations,
            siteId: state.sites,
            produceId: state.produces,
            varietyId: state.varieties,
            harvestDate: state.harvestDate
          }
        })
      )
    }
  }, [requestDownload])

  useEffect(() => {
    if (requestDownload && allItems.length > 0) {
      const headers = DOWNLOAD_HEADERS(grades)
      const data = [headers]
      for (const item of allItems) {
        const values = DOWNLOAD_VALUES(
          item,
          organizations,
          zonesHierarchy,
          grades
        )
        data.push(values)
      }
      const csv = unparse({ data })
      generateDownloadCsvLink(csv, 'harvests.csv')
      dispatch(clearAllHarvests())
      setRequestDownload(false)
    }
  }, [allItems])

  const fetchHarvests = page => {
    if (
      state.organizations.length > 0 &&
      state.sites.length > 0 &&
      state.produces.length > 0 &&
      state.varieties.length > 0 &&
      state.harvestDate.length > 0
    ) {
      dispatch(
        requestHarvests({
          filter: {
            organizationId: state.organizations,
            siteId: state.sites,
            produceId: state.produces,
            varietyId: state.varieties,
            harvestDate: state.harvestDate
          },
          limit: pageSize,
          offset: page - 1
        })
      )
    }
  }

  const changePage = newPage => {
    dispatchState({
      page: newPage
    })
  }

  const onCreate = () => {
    history.push(`${modulePath}/create`)
  }

  const onEdit = id => {
    history.push(`${modulePath}/update/${id}`)
  }

  const onDelete = id => {
    setDialog({
      show: true,
      callback: () => {
        dispatch(deleteHarvest({ harvestId: id }))
      }
    })
  }

  const onDialogClose = () => {
    setDialog({
      show: false,
      callback: null
    })
  }

  const onDialogContinue = () => {
    if (dialog.callback) {
      dialog.callback()
    }
    onDialogClose()
  }

  const onPlans = () => {
    history.push(`${modulePath}/plans`)
  }

  const onDownload = () => {
    setRequestDownload(true)
  }

  const onDataboard = () => {
    history.push(`${modulePath}/data`)
  }

  return (
    <Flex axisGap={400} direction='column'>
      <HeaderV2
        title={strings.manageHarvests}
        buttonCallback={onCreate}
        buttonText={strings.createHarvest}
        showButton={canEdit}
        breakpointWidth={1024}
      >
        <TableFilter state={state} dispatchState={dispatchState} />
        <FlexV2
          direction={isAboveBreakpoint ? 'row' : 'column'}
          alignCrossAxis={isAboveBreakpoint ? 'center' : null}
          axisGap={300}
        >
          {canReadPlans && (
            <Button
              size='small'
              iconBefore={'playlist_add_check'}
              variant='primary'
              name={'transfersButton'}
              onClick={onPlans}
            >
              <Text fontWeight={500}>{strings.plans}</Text>
            </Button>
          )}
          <Button
            size='small'
            iconBefore={'download'}
            variant='info'
            name={'downloadButton'}
            onClick={onDownload}
          >
            {strings.download}
          </Button>
          <Button size='small' name='databoardButton' onClick={onDataboard}>
            {strings.queryData}
          </Button>
        </FlexV2>
      </HeaderV2>
      <Loader isLoading={loading}>
        {totalItems === 0 && (
          <Flex className={'Operations__Table__Empty'}>
            <Text>{strings.noHarvests}</Text>
          </Flex>
        )}
        {totalItems > 0 && (
          <Table className='Operations__Table' aria-colcount='5'>
            <Slot name='head'>
              <Row>
                <Column>{strings.harvestDate}</Column>
                <Column>{strings.zone}</Column>
                <Column>{strings.produce}</Column>
                <Column>{strings.weight}</Column>
                <Column />
              </Row>
            </Slot>
            <Slot name='body'>
              {items?.map(item => (
                <TableRow
                  key={item.id}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  canEdit={canEdit}
                  item={item}
                />
              ))}
            </Slot>
          </Table>
        )}
        {totalItems > pageSize && (
          <Box className='pagination-menu'>
            <Pagination
              type='simple'
              totalItems={totalItems}
              totalPages={totalPages}
              setTotalPages={setTotalPages}
              page={tableState.page}
              setPage={changePage}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          </Box>
        )}
      </Loader>
      <Dialog open={dialog.show} onOpenChange={onDialogClose}>
        <Slot name='title'>
          <Flex direction='column' style={{ marginBottom: '1em' }}>
            <Text as='h5' style={{ marginBottom: '0.5em' }}>
              {strings.areYouSure}
            </Text>
            <Text as='p' size={100}>
              {strings.clickContinue}
            </Text>
          </Flex>
        </Slot>
        <Slot name='content'>
          <Text as='p' style={{ marginBottom: '2em' }}>
            {strings.deleteHarvestWarning}
          </Text>
          <Flex alignMainAxis='flex-end' axisGap='300'>
            <Button variant='error' size='small' onClick={onDialogClose}>
              {strings.cancel}
            </Button>
            <Button variant='info' size='small' onClick={onDialogContinue}>
              {strings.continue}
            </Button>
          </Flex>
        </Slot>
      </Dialog>
    </Flex>
  )
}

export default HarvestTable

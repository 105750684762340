import { NavLink } from 'react-router-dom'

import { Flex, Text, Symbol } from '@/primitives'
import { isExact } from '@/components/UIShell/utils'

import './index.scss'

const Link = ({
  text = null,
  to = null,
  icon = null,
  title = null,
  exact = null,
  level = 'organization'
}) => {
  if (!to && !text) return null

  return (
    <NavLink to={to} data-level={level} exact={isExact({ to, exact })}>
      <Flex alignCrossAxis={'center'}>
        {icon ? <Symbol name={icon} /> : <span className='Spacer' />}
        <Flex direction='column' axisGap={200} className={'Link__Text'}>
          {title && (
            <Text size={100} textTransform={'uppercase'} fontWeight={700}>
              {title}
            </Text>
          )}
          {text && <Text>{text}</Text>}
        </Flex>
      </Flex>
    </NavLink>
  )
}

export default Link

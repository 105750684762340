import { I18n } from 'aws-amplify'

import { useLiveData } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Hooks/useLiveData'
import { parseValue } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/utils'

import useThresholdStatus from '../../Shared/Hooks/useThresholdStatus'

import Value from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/Chart/Value'

export default function LiveValue({ unit, options }) {
  const data = useLiveData({
    measurements: [options?.measurement]
  })

  const parseOptions = value => ({
    fixedFactor: options.fixedFactor,
    scaleFactor: options.scaleFactor,
    value
  })

  const thresholdStatus = useThresholdStatus(options?.measurement)

  return (
    <Value
      thresholdStatus={thresholdStatus}
      data={{
        value: parseValue(parseOptions(data?.battery_soc?.value)),
        min: parseValue(parseOptions(data?.battery_soc?.min)),
        max: parseValue(parseOptions(data?.battery_soc?.max)),
        unit,
        status: data?.battery_soc?.status,
        subtitle: I18n.get('Live')
      }}
    />
  )
}

import { I18n } from 'aws-amplify'

const Strings = () => ({
  today: I18n.get('Today'),
  '24hours': I18n.get('24 hours'),
  '7days': I18n.get('7 days'),
  '30days': I18n.get('30 days'),
  loadingData: I18n.get('Fetching graph data'),
  noData: I18n.get('No data available'),
  noDataForZones: I18n.get('No data for:'),
  selectedZoneText: I18n.get('Current'),
  zonesText: I18n.get('Zones'),
  sensorsText: I18n.get('Sensors'),
  select: I18n.get('Select'),
  sensor: I18n.get('sensor')
})

export default Strings

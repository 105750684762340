import { DateTime } from 'luxon'

import {
  CONVERSION_TEMPERATURE_MEASUREMENTS,
  convertCelciusToFahrenheit
} from '@/Util/MeasurementUtils'
import { conversionLookup } from '@/Util/UnitUtils'
import { schemeDark2 } from '@/Util/SchemeUtils'
import ZoneUtils from '@/Util/ZoneUtils'

import Strings from '../Strings'
import { COMPARISON_TYPES, DATE_RANGE_ID_FORMAT } from './config'
import { getMostSpecificZoneFilter } from './FiltersUtils'

const SERIES = ['average']

export const splitDataset = state => {
  if (state?.dataset) {
    return state.measurements[state.comparisonType].map(measurement => {
      return state?.dataset.reduce((result, data) => {
        if (data.measurement === measurement) {
          return result.concat(data)
        }
        return result
      }, [])
    })
  }
  return []
}

const applyConverion = (key, values) => {
  const scaleFactor = conversionLookup[key] ?? 1
  return values.map(v => ({
    x: new Date(v.x),
    y: v.y * scaleFactor
  }))
}

export function processDliData(data) {
  const response = data.reduce(
    (acc, item, index) => {
      let calculatedData = acc.calculatedData
      const date = DateTime.fromJSDate(new Date(item.x)).toFormat('yyyy-MM-dd')

      if (index !== 0 && acc.date === date) {
        let y = item.y
        y = y + calculatedData[calculatedData.length - 1].y
        item.y = y
      }

      calculatedData.push(item)

      acc['calculatedData'] = calculatedData
      acc['date'] = date

      return acc
    },
    { calculatedData: [], date: null }
  )

  return response.calculatedData
}

export function processDceData(data) {
  const response = data.reduce(
    (acc, item, index) => {
      let calculatedData = acc.calculatedData
      const date = DateTime.fromJSDate(new Date(item.x)).toFormat('yyyy-MM-dd')

      if (index !== 0 && acc.date === date) {
        let y = item.y
        y = y + calculatedData[calculatedData.length - 1].y
        item.y = y
      }

      calculatedData.push(item)

      acc['calculatedData'] = calculatedData
      acc['date'] = date

      return acc
    },
    { calculatedData: [], date: null }
  )

  return response.calculatedData
}

export function processTemperatureConversion(data) {
  return data.map(item => {
    return {
      ...item,
      y: convertCelciusToFahrenheit(item.y)
    }
  })
}

/**
 * Processes the value based on the measurement and the enableFahrenheit flag
 * @param {number} value
 * @param {string} measurement
 * @param {boolean} enableFahrenheit
 * @returns {number} The processed value
 */
export function processValue(value, measurement, enableFahrenheit) {
  if (measurement === 'daily_light_integral') {
    return processDliData(value)
  }
  if (measurement === 'daily_energy_consumption') {
    return processDceData(value)
  }
  if (
    CONVERSION_TEMPERATURE_MEASUREMENTS.includes(measurement) &&
    enableFahrenheit
  ) {
    return convertCelciusToFahrenheit(value)
  }
  return value
}

function transformIdToName(currentDataPoint, zoneFilters, zonesHierarchy) {
  let zoneId = currentDataPoint.zoneId
  let sensorId = currentDataPoint.sensorId ?? null

  const filter = zoneFilters.find(filter => {
    if (sensorId) {
      return filter.sensorId === sensorId
    }
    return getMostSpecificZoneFilter(filter) === zoneId
  })

  // if is a sensor, get the sensor name
  if (filter && sensorId) {
    const sensorName = ZoneUtils.getSensorName(
      zonesHierarchy,
      filter.siteId,
      zoneId,
      sensorId
    )
    // if no sensor name is found, use the sensor id
    return sensorName ?? sensorId
  }
  // if is a zone, get the zone name
  const zoneName = ZoneUtils.getZoneName(
    zonesHierarchy,
    filter.siteId,
    getMostSpecificZoneFilter(filter)
  )
  // if no zone name is found, use the zone id
  return zoneName ?? zoneId
}

function transformDateToId(data) {
  if (data.dateRange !== 'customRange') {
    const strings = Strings()
    return strings[data.dateRange]
  }

  const fromDate = DateTime.fromISO(data.fromDate).toFormat('MM-dd')
  const toDate = DateTime.fromISO(data.toDate).toFormat('MM-dd')

  return `${fromDate}/${toDate}`
}

const getSeriesColor = (data, zoneFilters) => {
  const filterIndex = zoneFilters.findIndex(filter => {
    if (data.sensorId) {
      if (filter.sensorId === data.sensorId) {
        return true
      }
    } else if (getMostSpecificZoneFilter(filter) === data.zoneId) {
      return true
    }
    return false
  })

  return schemeDark2[filterIndex]
}

const getSeriesDateColor = (data, dateFilters) => {
  const fromDate = DateTime.fromISO(data.fromDate).toMillis()
  const toDate = DateTime.fromISO(data.toDate).toMillis()

  const filterIndex = dateFilters.findIndex(filter => {
    const offsetFromDate = DateTime.fromMillis(filter.fromDate)
      .setZone(filter.timeZone)
      .toUTC()
      .toMillis()

    const offsetToDate = DateTime.fromMillis(filter.toDate)
      .setZone(filter.timeZone)
      .toUTC()
      .toMillis()

    return (
      data.dateRange === filter.dateRange &&
      fromDate === offsetFromDate &&
      toDate === offsetToDate
    )
  })

  return schemeDark2[filterIndex]
}

function createDataPointObject({
  calculatedData,
  currentDataPoint,
  state,
  zonesHierarchy
}) {
  const { dateFilters, comparisonType, zoneFilters } = state

  const id =
    comparisonType === COMPARISON_TYPES.LOCATION_RANGES
      ? transformIdToName(currentDataPoint, zoneFilters, zonesHierarchy)
      : transformDateToId(currentDataPoint)

  const color =
    comparisonType === COMPARISON_TYPES.LOCATION_RANGES
      ? getSeriesColor(currentDataPoint, zoneFilters)
      : getSeriesDateColor(currentDataPoint, dateFilters)

  return {
    id,
    data: calculatedData,
    color
  }
}

export function getProcessedChartData(
  data,
  state,
  zonesHierarchy,
  measurementId = null,
  enableFahrenheit = false
) {
  let processedData = []
  let hasData = false

  if (data?.length) {
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].series.length; j++) {
        if (SERIES.includes(data[i].series[j].id)) {
          let processedSeriesData = applyConverion(
            data[i].measurement,
            data[i].series[j].data
          )

          if (
            (CONVERSION_TEMPERATURE_MEASUREMENTS.includes(measurementId) ||
              CONVERSION_TEMPERATURE_MEASUREMENTS.includes(
                data[i].measurement
              )) &&
            enableFahrenheit
          ) {
            processedSeriesData = processTemperatureConversion([
              ...processedSeriesData
            ])
          }

          let calculatedData = [...processedSeriesData]

          if (measurementId === 'daily_light_integral') {
            calculatedData = processDliData([...processedSeriesData])
          }

          if (measurementId === 'daily_energy_consumption') {
            calculatedData = processDceData([...processedSeriesData])
          }

          processedData.push(
            createDataPointObject({
              calculatedData,
              currentDataPoint: data[i],
              state,
              zonesHierarchy
            })
          )

          if (!hasData && calculatedData.length > 0) {
            hasData = true
          }
        }
      }
    }
  }

  return { processedData, hasData }
}

function generateDateRangeId(fromDate, toDate) {
  const fromDateObj = DateTime.fromISO(fromDate)
  const toDateObj = DateTime.fromISO(toDate)

  return `${fromDateObj.toFormat(DATE_RANGE_ID_FORMAT)} - ${toDateObj.toFormat(
    DATE_RANGE_ID_FORMAT
  )}`
}

export function getDateRangeFromId(id) {
  const dates = id.split(' - ')
  return {
    fromDate: DateTime.fromFormat(dates[0], DATE_RANGE_ID_FORMAT).toISO(),
    toDate: DateTime.fromFormat(dates[1], DATE_RANGE_ID_FORMAT).toISO()
  }
}

export function getProcessedDateFilterChartData(
  dataset,
  state,
  enableFahrenheit
) {
  let dataByDateRange = {}
  let hasData = false

  const timeInterval = state.dateFilters[0].timeInterval

  let colorIdx = 0
  for (let item of dataset) {
    const fromDate = DateTime.fromISO(item.fromDate)
    const toDate = DateTime.fromISO(item.toDate)
    const id = generateDateRangeId(item.fromDate, item.toDate)

    // I seperate the data by date range to simplify the algorithm
    if (!dataByDateRange[id]) {
      dataByDateRange[id] = {
        fromDate: item.fromDate,
        toDate: item.toDate,
        id,
        data: [],
        color: schemeDark2[colorIdx]
      }
      colorIdx += 1
    }

    for (let serie of item.series) {
      // firstly I'm calculating the length of the data array based on date range
      // essentially calculating how many interval steps an ideal data array would contain
      let dataLength = parseInt(
        DateTime.fromISO(toDate)
          .diff(DateTime.fromISO(fromDate))
          .as(timeInterval)
      )

      // then I'm filling the data array with null values to the length of the data array
      dataByDateRange[id].data = Array.from({ length: dataLength }, (_, i) => {
        return {
          x: i + 1,
          y: null
        }
      })

      // now I iterate over the actual data points
      for (let dataPoint of serie.data) {
        hasData = true
        // I calculate where the data should be placed in the data array
        // relative to the fromDate, calculating at which interval step the data point should be placed
        // Doing this because data can have gaps in the interval steps
        let intervalIndex = parseInt(
          DateTime.fromISO(dataPoint.x).diff(fromDate).as(timeInterval)
        )

        dataByDateRange[id].data[intervalIndex] = {
          x: intervalIndex + 1, // set x to the interval step
          y: processValue(dataPoint.y, item.measurement, enableFahrenheit)
        }
      }
    }
  }

  return { processedData: Object.values(dataByDateRange), hasData }
}

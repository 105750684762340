import { Fragment, useCallback, useEffect, useState } from 'react'
import { I18n } from 'aws-amplify'
import { useDispatch } from 'react-redux'

import {
  fetchProduceThresholdMessages,
  sendDeleteProduceThresholdMessage
} from '@/slices/operations/produceThresholdMessage'

import {
  getCurrentUser,
  getOperationsProduceThresholdMessages,
  getOperationsProduceThresholdMessagesCount,
  getOperationsProduceThresholdMessageLoading,
  getOperationsProduceThresholdMessageError,
  getMeasurements
} from '@/reducers/selectors'

import {
  Flex,
  Cell,
  Text,
  Column,
  Row,
  Slot,
  Table,
  Loader
} from '@/primitives'

import { Pagination } from '@/elements'

import HeaderV2 from '@/components/Operations/Shared/HeaderV2'

import { showBanner } from '@/slices/util'

import { nameLookup } from '@/Util/UnitUtils'

import { getPriorityDisplayName } from '../utils'

import history from '../../../../../history'

import Strings from '../Strings'

import {
  hasEditPermissions,
  RESOURCE_TYPE_OPERATIONS,
  RESOURCE_TYPE_OPERATIONS_MANAGEMENT
} from '@/Util/PermissionUtils'

import Actions from './Actions'

function ProduceThresholdMessageTable({ state, dispatchState, modulePath }) {
  const dispatch = useDispatch()

  const currentUser = getCurrentUser()
  const allMeasurements = getMeasurements()
  const produceThresholdMessages = getOperationsProduceThresholdMessages()
  const produceThresholdMessagesCount =
    getOperationsProduceThresholdMessagesCount()
  const loading = getOperationsProduceThresholdMessageLoading()
  const error = getOperationsProduceThresholdMessageError()

  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(9)
  const [tableState, setTableState] = useState({})
  const [canEdit, setCanEdit] = useState(false)
  const [fetchCalled, setFetchCalled] = useState(false)
  const [waiting, setWaiting] = useState(false)

  const strings = Strings()

  const requestProduceThresholdMessages = useCallback(
    page => {
      dispatch(
        fetchProduceThresholdMessages({
          limit: pageSize,
          offset: page - 1
        })
      )
      setFetchCalled(true)
    },
    [state, pageSize, dispatch]
  )

  const changePage = useCallback(
    newPage => {
      dispatchState({ page: newPage })
      setFetchCalled(false)
    },
    [dispatchState]
  )

  useEffect(() => {
    if (currentUser.userName) {
      setCanEdit(
        hasEditPermissions(
          currentUser,
          RESOURCE_TYPE_OPERATIONS,
          RESOURCE_TYPE_OPERATIONS_MANAGEMENT
        )
      )
    }
  }, [currentUser])

  useEffect(() => {
    if (tableState.page !== state.page) {
      if (!fetchCalled) {
        requestProduceThresholdMessages(state.page)
      }
    }
  }, [state, tableState, requestProduceThresholdMessages, fetchCalled])

  useEffect(() => {
    setTableState({ ...state })
  }, [state])

  useEffect(() => {
    if (waiting && !loading && !error) {
      const message = strings.produceThresholdMessageDeleted
      dispatch(
        showBanner({
          type: 'success',
          show: true,
          message: message
        })
      )
      setWaiting(false)
    }
  }, [waiting, loading, error])

  const onCreate = () => history.push(`${modulePath}/create`)

  const onEdit = id => history.push(`${modulePath}/update/${id}`)

  const onDelete = id => {
    dispatch(
      sendDeleteProduceThresholdMessage({
        produceThresholdMessageId: id
      })
    )
    setWaiting(true)
  }

  return (
    <Fragment>
      <HeaderV2
        title={I18n.get('Manage Produce Threshold Message')}
        buttonIcon={'add'}
        buttonText={I18n.get('Add Produce Threshold Message')}
        buttonCallback={onCreate}
        showButton={canEdit}
      />
      <Loader isLoading={loading}>
        {produceThresholdMessagesCount === 0 && (
          <Flex className={'Operations__Table__Empty'}>
            <Text>{I18n.get('No messages')}</Text>
          </Flex>
        )}
        {produceThresholdMessagesCount > 0 && (
          <Table aria-colcount='3' className='Operations__Table'>
            <Slot name='head'>
              <Row>
                <Column>{I18n.get('Name')}</Column>
                <Column>{I18n.get('Priority')}</Column>
                <Column>{I18n.get('Measurements')}</Column>
                <Column />
              </Row>
            </Slot>
            <Slot name='body'>
              {produceThresholdMessages.map(
                ({ id, name, priority, config }) => (
                  <Row key={id}>
                    <Cell columnName={I18n.get('Name')}>{name}</Cell>
                    <Cell columnName={I18n.get('Priority')}>
                      {getPriorityDisplayName(priority)}
                    </Cell>
                    <Cell
                      columnName={I18n.get('Measurements')}
                      className='Operations__Table__Measurements_Cell'
                    >
                      {config.map(condition => (
                        <Text
                          key={condition.measurement}
                          className='Operations__Table__Tag'
                          tone='400'
                          variant='page'
                          size={100}
                          fontWeight={600}
                        >
                          {nameLookup(condition.measurement, allMeasurements)}
                        </Text>
                      ))}
                    </Cell>
                    <Cell className='Operations__Table__Column__Actions'>
                      <Actions
                        id={id}
                        canEdit={canEdit}
                        onEdit={onEdit}
                        onDelete={onDelete}
                      />
                    </Cell>
                  </Row>
                )
              )}
            </Slot>
          </Table>
        )}
      </Loader>
      {produceThresholdMessagesCount > pageSize && (
        <Pagination
          type='simple'
          totalItems={produceThresholdMessagesCount}
          totalPages={totalPages}
          setTotalPages={setTotalPages}
          page={tableState.page}
          setPage={changePage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </Fragment>
  )
}

export default ProduceThresholdMessageTable

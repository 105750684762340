import { I18n } from 'aws-amplify'
import { number, object, date } from 'yup'

export const FIELDS = {
  id: 'id',
  dateReceived: 'dateReceived',
  amount: 'amount'
}

export const SCHEMA = orderDate => {
  return object().shape({
    dateReceived: date()
      .typeError('The received date is required.')
      .min(
        orderDate,
        I18n.get('The received date cannot be older than the order date.')
      )
      .required(I18n.get('The received date is required.')),
    amount: number()
      .typeError('An amount is required.')
      .min(1, I18n.get('The amount cannot be less than one.'))
      .required(I18n.get('An amount is required.'))
  })
}

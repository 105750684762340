import { Fragment, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, Switch, Route } from 'react-router-dom'

import { Flex, Text } from '@/primitives'
import { LineSeparator } from '@/elements'

import DeleteThresholdModal from './DeleteModal'
import ThresholdsHeader from './Header'
import ThresholdsTable from './Table'
import ThresholdForm from './Form'

import {
  cleanThresholdCreated,
  cleanThresholdUpdated,
  cleanThresholdDeleted,
  setThreshold,
  sendDeleteThreshold
} from '@/slices/threshold'
import { showBanner } from '@/slices/util'
import {
  getThresholds,
  getThresholdError,
  getThresholdCreated,
  getThresholdUpdated,
  getThresholdDeleted
} from '@/reducers/selectors'

import history from '../../../../history'
import Strings from '../../Strings'

const ThresholdContainer = ({ basePath, selectedZone }) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const thresholds = getThresholds()
  const thresholdError = getThresholdError()
  const thresholdCreated = getThresholdCreated()
  const thresholdUpdated = getThresholdUpdated()
  const thresholdDeleted = getThresholdDeleted()

  const strings = Strings()

  const [showDeleteModal, setShowDeleteModal] = useState(false)

  useEffect(() => {
    if (location?.state?.action === 'create' && thresholdCreated?.id) {
      const successDetails = {
        show: true,
        message: strings.thresholdCreated,
        type: 'success'
      }
      dispatch(showBanner(successDetails))
      dispatch(cleanThresholdCreated())
    }
  }, [location, dispatch, thresholdCreated?.id])

  useEffect(() => {
    if (location?.state?.action === 'update' && thresholdUpdated?.id) {
      const successDetails = {
        show: true,
        message: strings.thresholdUpdated,
        type: 'success'
      }
      dispatch(showBanner(successDetails))
      dispatch(cleanThresholdUpdated())
    }
  }, [location, dispatch, thresholdUpdated?.id])

  useEffect(() => {
    if (thresholdDeleted?.id) {
      setShowDeleteModal(false)
      const successDetails = {
        show: true,
        message: strings.thresholdDeleted,
        type: 'success'
      }
      dispatch(showBanner(successDetails))
      dispatch(cleanThresholdDeleted())
      dispatch(setThreshold({}))
    }
  }, [dispatch, thresholdDeleted?.id])

  useEffect(() => {
    if (thresholdError) {
      dispatch(
        showBanner({
          show: true,
          message: thresholdError,
          type: 'error'
        })
      )
    }
  }, [dispatch, thresholdError])

  function onCreateThreshold(e) {
    e.preventDefault()
    history.push(`/admin${basePath}/thresholds/add`)
  }

  function onDeleteThreshold(threshold) {
    dispatch(setThreshold(threshold))
    setShowDeleteModal(true)
  }

  function onAcceptDeleteThreshold(threshold) {
    dispatch(sendDeleteThreshold(threshold))
  }

  function onCancelDeleteThreshold() {
    setShowDeleteModal(false)
    dispatch(setThreshold({}))
    const clearDetails = {
      show: false,
      message: '',
      type: ''
    }
    dispatch(showBanner(clearDetails))
  }

  return (
    <Fragment>
      <ThresholdsHeader
        selectedZone={selectedZone}
        onCreateThreshold={onCreateThreshold}
      />
      <LineSeparator />
      {thresholds.length === 0 && (
        <Flex alignMainAxis='center' style={{ margin: '0.5em 0' }}>
          <Text as='p'>{`${strings.tableNoThresholds}`}</Text>
        </Flex>
      )}

      {thresholds.length > 0 && (
        <ThresholdsTable
          basePath={basePath}
          thresholds={thresholds}
          onDeleteThreshold={onDeleteThreshold}
        />
      )}

      {showDeleteModal && (
        <DeleteThresholdModal
          showModal={showDeleteModal}
          onAcceptDeleteThreshold={onAcceptDeleteThreshold}
          onCancelDeleteThreshold={onCancelDeleteThreshold}
        />
      )}
      <Switch>
        <Route
          exact
          path='/admin/zones/:zone+/thresholds/add'
          component={ThresholdForm}
        />
        <Route
          exact
          path='/admin/zones/:zone+/thresholds/:measurementId'
          component={ThresholdForm}
        />
      </Switch>
    </Fragment>
  )
}

export default ThresholdContainer

import { getMeasurements } from '@/reducers/selectors'
import withEditableWidget from '@/components/DashboardPageV2/Desktop/Widgets/Shared/HOC/withEditableWidget'
import { MeasurementEdit } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Measurement'

import DatasetLine from './DatasetLine'

function MeasureByDepthView(props) {
  const { config, options } = props

  const measurement = options.measurement
  const measurements = getMeasurements()

  const unit = measurements?.find(m => m.id === measurement)?.unit

  return (
    <DatasetLine unit={unit} config={config} options={options} {...props} />
  )
}

export const MeasureByDepthWidget = withEditableWidget(
  MeasureByDepthView,
  MeasurementEdit
)

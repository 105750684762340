import { Box, Button } from '@/primitives'

export default function RemoveButton({ onClick }) {
  return (
    <Box className='DataboardFilters__ComparisonRow__RemoveButton'>
      <Button
        size='small'
        variant='error'
        iconBefore='close'
        onClick={onClick}
      />
    </Box>
  )
}

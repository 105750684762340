import { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { FlexV2, Separator, Text } from '@/primitives'
import { SearchBar } from '@/elements'
import { getIsGodMode } from '@/Util/PermissionUtils'
import { setDevice } from '@/slices/management/device'
import {
  getDeviceLoading,
  getZoneDevices,
  getCurrentUser
} from '@/reducers/selectors'

import Strings from '../../Strings'

import DevicesHeader from './Header'
import DevicesTable from './Table'
import EnrollmentForm from './EnrollmentForm'
import DeviceFormV2 from './DeviceFormV2'
import './index.scss'

const Container = ({ basePath, selectedZone }) => {
  const coretexUser = getCurrentUser()
  const isGodMode = getIsGodMode(coretexUser)
  const devices = getZoneDevices()
  const isLoading = getDeviceLoading()
  const [searchResults, setSearchResults] = useState(devices)
  const [searchText, setSearchText] = useState('')

  const { tableNoDevices, searchDevices } = Strings()

  const dispatch = useDispatch()
  const [showDeviceFormModal, setShowDeviceFormModal] = useState(false)
  const [showEnrollModal, setShowEnrollModal] = useState(false)

  function closeEnrollModal() {
    setShowEnrollModal(false)
  }

  function onCreateDevice(e) {
    e.preventDefault()
    dispatch(setDevice({}))
    setShowDeviceFormModal(true)
  }

  function onEnrollDevice(e) {
    e.preventDefault()
    setShowEnrollModal(isGodMode)
  }

  function onUpdateSearchText(text) {
    setSearchText(text)
  }

  useEffect(() => {
    // clean up device state on mount and unmount
    dispatch(setDevice({}))
    return () => {
      dispatch(setDevice({}))
    }
  }, [dispatch])

  useEffect(() => {
    setSearchResults(
      devices.filter(device =>
        device?.name?.toLowerCase().includes(searchText.toLowerCase())
      )
    )
  }, [searchText, devices])

  return (
    <Fragment>
      <FlexV2 axisGap={500} direction='column'>
        <DevicesHeader
          isLoading={isLoading}
          selectedZone={selectedZone}
          onCreateDevice={onCreateDevice}
          onEnrollDevice={onEnrollDevice}
        />
        <SearchBar
          isLoading={isLoading}
          onUpdateSearchText={onUpdateSearchText}
          placeholderText={searchDevices}
        />
      </FlexV2>

      <Separator />

      {devices.length === 0 && <Text as='p'>{tableNoDevices}</Text>}

      {devices.length > 0 && (
        <DevicesTable
          basePath={basePath}
          devices={searchResults}
          setShowDeviceFormModal={setShowDeviceFormModal}
        />
      )}

      {showDeviceFormModal && (
        <DeviceFormV2
          showModal={showDeviceFormModal}
          setShowDeviceFormModal={setShowDeviceFormModal}
        />
      )}

      {showEnrollModal && (
        <EnrollmentForm
          showModal={showEnrollModal}
          selectedZone={selectedZone}
          closeModal={closeEnrollModal}
        />
      )}
    </Fragment>
  )
}

export default Container

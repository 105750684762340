import { updateList } from '../util'

import {
  CLEAR_ALL_HARVESTS,
  REQUEST_ALL_HARVESTS,
  RECEIVE_ALL_HARVESTS,
  REQUEST_HARVESTS,
  RECEIVE_HARVESTS,
  REQUEST_HARVEST,
  RECEIVE_HARVEST,
  CREATE_HARVEST,
  HARVEST_CREATED,
  UPDATE_HARVEST,
  HARVEST_UPDATED,
  DELETE_HARVEST,
  HARVEST_DELETED
} from '@/actions/operations/harvest'

export const harvestInitialState = {
  allHarvests: [],
  harvests: [],
  harvestsCount: 0,
  harvest: {},
  loading: false,
  error: null
}

const handlers = {
  [CLEAR_ALL_HARVESTS]: state => ({
    ...state,
    allHarvests: []
  }),
  [REQUEST_ALL_HARVESTS]: (state, action) => ({
    ...state,
    allHarvests: [],
    error: null,
    loading: true
  }),
  [RECEIVE_ALL_HARVESTS]: (state, action) => ({
    ...state,
    allHarvests: !action.error ? action.harvests : [],
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_HARVESTS]: (state, action) => ({
    ...state,
    harvests: [],
    harvestsCount: 0,
    error: null,
    loading: true
  }),
  [RECEIVE_HARVESTS]: (state, action) => ({
    ...state,
    harvests: !action.error ? action.harvests : [],
    harvestsCount: action?.count ?? 0,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_HARVEST]: state => ({
    ...state,
    harvest: {},
    error: null,
    loading: true
  }),
  [RECEIVE_HARVEST]: (state, action) => ({
    ...state,
    harvest: !action.error ? action.harvest : state.harvest,
    error: action.error ?? null,
    loading: false
  }),
  [CREATE_HARVEST]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [HARVEST_CREATED]: (state, action) => ({
    ...state,
    harvest: !action.error ? {} : state.harvest,
    harvests: updateList(action.error, state.harvests, action.harvest, true),
    harvestsCount: !action.error
      ? state.harvestsCount + 1
      : state.harvestsCount,
    error: action.error ?? null,
    loading: false
  }),
  [UPDATE_HARVEST]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [HARVEST_UPDATED]: (state, action) => ({
    ...state,
    harvest: !action.error ? {} : state.harvest,
    harvests: updateList(action.error, state.harvests, action.harvest, false),
    error: action.error ?? null,
    loading: false
  }),
  [DELETE_HARVEST]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [HARVEST_DELETED]: (state, action) => {
    if (!action?.error) {
      let harvests = state.harvests
      const harvestIndex = harvests.findIndex(
        harvest => harvest.id === action.harvest.id
      )
      if (harvestIndex >= 0) {
        harvests.splice(harvestIndex, 1)
      }
      return {
        ...state,
        harvests: harvests,
        harvestsCount: state.harvestsCount - 1,
        error: null,
        loading: false
      }
    } else {
      return {
        ...state,
        error: action.error,
        loading: false
      }
    }
  }
}

export default handlers

import { useState, useEffect } from 'react'
import { Input } from '../../primitives'

import './index.scss'

/**
 * SearchBarWithDebounce Element
 * @param {Object} props
 * @param {String | String[]} [props.className] Extra Class Name at top level
 *
 * @param {Strings} [props.value=''] The original searchText
 * @param {Boolean} [props.isLoading=false] Search bar is disabled while loading
 * @param {Function} [props.onUpdateSearchText] The callback function triggered when the search text is updated
 * @param {String} [props.placeholderText=''] Prompt that displays in the search bar field
 *
 *  * @example
 * ```jsx
 *  <SearchBar
 *    value={value}
 *    isLoading={isLoading}
 *    onUpdateSearchText={onUpdateSearchText}
 *    placeholderText={I18n.get('Search by name')}
 *  />
 * ```
 */

const SearchBar = ({
  value = '',
  isLoading = false,
  onUpdateSearchText = () => {},
  placeholderText = '',
  ...rest
}) => {
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    onUpdateSearchText(searchText)
  }, [searchText, onUpdateSearchText])

  useEffect(() => {
    setSearchText(value)
  }, [value])

  function onChangeText({ target: { value } }) {
    setSearchText(value)
  }

  return (
    <Input
      {...rest}
      className='Searchbar'
      name='search'
      value={searchText}
      onChange={onChangeText}
      readOnly={isLoading}
      placeholder={placeholderText}
    />
  )
}

export default SearchBar

import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { getCognitoData, makeGraphqlRequest } from '../utils'

// Queries

export const getAverageMinxMax = async params => {
  try {
    const query = /* GraphQL */ `
      query averageMinMaxQuery(
        $organizationId: String
        $siteId: String
        $table: String!
        $sensorIds: [String]
        $measurements: [String]
        $fromDate: String!
        $toDate: String!
      ) {
        averageMinMaxQuery(
          organizationId: $organizationId
          siteId: $siteId
          table: $table
          sensorIds: $sensorIds
          measurements: $measurements
          fromDate: $fromDate
          toDate: $toDate
        ) {
          rows
          columns
          types
        }
      }
    `

    const { id, idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken,
      id
    )

    if (error) {
      return {
        averageMinMax: null,
        error: `Average data not fetched: ${error}`
      }
    }

    return {
      averageMinMax: data?.averageMinMaxQuery,
      error: null
    }
  } catch (err) {
    return { averageMinMax: null, error: err.message }
  }
}

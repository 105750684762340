import { makeActionCreator } from '@/Util/ReduxUtils'

export const REQUEST_ALL_VARIETIES = 'REQUEST_ALL_VARIETIES'
export const requestAllVarieties = makeActionCreator(REQUEST_ALL_VARIETIES)

export const RECEIVE_ALL_VARIETIES = 'RECEIVE_ALL_VARIETIES'
export const receiveAllVarieties = makeActionCreator(
  RECEIVE_ALL_VARIETIES,
  'varieties',
  'count',
  'error'
)

export const REQUEST_VARIETIES = 'REQUEST_VARIETIES'
export const requestVarieties = makeActionCreator(REQUEST_VARIETIES, 'params')

export const RECEIVE_VARIETIES = 'RECEIVE_VARIETIES'
export const receieveVarieties = makeActionCreator(
  RECEIVE_VARIETIES,
  'varieties',
  'count',
  'error'
)

export const CREATE_VARIETY = 'CREATE_VARIETY'
export const createVariety = makeActionCreator(CREATE_VARIETY, 'params')

export const VARIETY_CREATED = 'VARIETY_CREATED'
export const varietyCreated = makeActionCreator(
  VARIETY_CREATED,
  'variety',
  'error'
)

export const UPDATE_VARIETY = 'UPDATE_VARIETY'
export const updateVariety = makeActionCreator(UPDATE_VARIETY, 'params')

export const VARIETY_UPDATED = 'VARIETY_UPDATED'
export const varietyUpdated = makeActionCreator(
  VARIETY_UPDATED,
  'variety',
  'error'
)

export const REQUEST_VARIETY = 'REQUEST_VARIETY'
export const requestVariety = makeActionCreator(REQUEST_VARIETY, 'params')

export const RECEIVE_VARIETY = 'RECEIVE_VARIETY'
export const receiveVariety = makeActionCreator(
  RECEIVE_VARIETY,
  'variety',
  'error'
)

export const CLEAR_VARIETY = 'CLEAR_VARIETY'
export const clearVariety = makeActionCreator(CLEAR_VARIETY)

export const CLEAR_VARIETIES = 'CLEAR_VARIETIES'
export const clearVarieties = makeActionCreator(CLEAR_VARIETIES)

export const SET_VARIETY = 'SET_VARIETY'
export const setVariety = makeActionCreator(SET_VARIETY, 'variety')

/**
 * Builds a navigation selector from a site hierarchy and a parent path
 * @param {object} siteHierarchy - Site hierarchy object
 * @param {string} parentPath - Parent path of the location
 * @param {object} organization - Organization object {id, name}
 * @returns {Map} - Map of location selectors
 */
export function buildNavigationSelector(
  siteHierarchy,
  parentPath,
  organization
) {
  const locationSelector = new Map()
  locationSelector.set('organization', organization)
  const levelKeys = ['site', 'facility', 'room', 'zone', 'subzone']

  const pathIds = parentPath.split('/').filter(id => id)
  function traverse(currentNode, currentLevel) {
    if (!currentNode || currentLevel === levelKeys.length) return

    const { id, name, status } = currentNode
    if (levelKeys[currentLevel]) {
      locationSelector.set(levelKeys[currentLevel], { id, name, status })
    }

    if (currentNode.children && pathIds[currentLevel]) {
      traverse(
        currentNode.children[pathIds[currentLevel + 1]],
        currentLevel + 1
      )
    }
  }

  traverse(siteHierarchy, 0)

  return locationSelector
}

import { makeOperationsApiCall } from './utils'

/* MUTATIONS */

export const createRegion = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createRegion(
        $organizationId: String!
        $countryId: String!
        $name: String!
      ) {
        createRegion(
          organizationId: $organizationId
          countryId: $countryId
          name: $name
        ) {
          id
          organizationId
          name
          countryId
          country {
            name
          }
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'createRegion',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updateRegion = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateRegion(
        $regionId: String!
        $organizationId: String!
        $countryId: String!
        $name: String!
      ) {
        updateRegion(
          regionId: $regionId
          organizationId: $organizationId
          countryId: $countryId
          name: $name
        ) {
          id
          organizationId
          name
          countryId
          country {
            name
          }
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'updateRegion',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

/* QUERIES */

export const getRegions = async params => {
  try {
    const query = /* GraphQL */ `
      query getRegions(
        $filter: GetRegionsFilter
        $limit: Int
        $offset: Int
        $order: OrderEnum
      ) {
        getRegions(
          filter: $filter
          limit: $limit
          offset: $offset
          order: $order
        ) {
          regions {
            id
            organizationId
            name
            countryId
            country {
              name
            }
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getRegions',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getRegion = async params => {
  try {
    const query = /* GraphQL */ `
      query getRegion($regionId: String!) {
        getRegion(regionId: $regionId) {
          id
          organizationId
          name
          countryId
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getRegion',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { makeGraphqlRequestV2 } from '../utils'

export const getSensorDiagnosticInfo = async params => {
  try {
    const query = /* GraphQL */ `
      query getSensorDiagnosticInfo($sensorIds: [String]!) {
        getSensorDiagnosticInfo(sensorIds: $sensorIds) {
          sensorId
          status
        }
      }
    `

    const operation = graphqlOperation(query, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)
    return data?.getSensorDiagnosticInfo
  } catch (err) {
    throw err
  }
}

export const restartSensors = async params => {
  try {
    const query = /* GraphQL */ `
      mutation restartSensors($sensorIds: [String]!) {
        restartSensors(sensorIds: $sensorIds) {
          sensorId
          status
        }
      }
    `

    const operation = graphqlOperation(query, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)
    return data?.restartSensors
  } catch (err) {
    throw err
  }
}

import { I18n } from 'aws-amplify'

const Strings = () => ({
  managePackageUnitInventoryCalibrations: I18n.get('Manage Packaging Inventory Calibrations'),
  createPackageUnitInventoryCalibration: I18n.get('Calibrate'),
  createFormHeader: I18n.get('Create New Calibration'),
  noPackageUnitInventoryCalibrations: I18n.get('No packaging inventory calibrations'),
  savePackageUnitInventoryCalibration: I18n.get('Save Calibration'),
  selectDefault: I18n.get('Please Select'),
  packageUnitInventoryCalibration: I18n.get('Inventory Calibration'),
  calibration: I18n.get('Calibration'),
  inventory: I18n.get('Inventory'),
  packageUnit: I18n.get('Package Unit'),
  newQuantity: I18n.get('New Quantity'),
  oldQuantity: I18n.get('Old Quantity'),
  currentQuantity: I18n.get('Current Quantity'),
  quantity: I18n.get('Quantity'),
  change: I18n.get('Change'),
  reason: I18n.get('Reason'),
  date: I18n.get('Date'),
  grams: I18n.get('g')
})

export default Strings

import { I18n } from 'aws-amplify'

const Strings = () => ({
  manageProducts: I18n.get('Manage Products'),
  createProduct: I18n.get('Create Product'),
  noProducts: I18n.get('No products'),
  createFormHeader: I18n.get('Create New Product'),
  updateFormHeader: I18n.get('Update Existing Product'),
  saveProduct: I18n.get('Save Product'),
  product: I18n.get('Product'),
  produce: I18n.get('Produce'),
  variety: I18n.get('Variety'),
  grade: I18n.get('Grade'),
  name: I18n.get('Name'),
  code: I18n.get('Code'),
  status: I18n.get('Status'),
  weight: I18n.get('Weight'),
  packageUnits: I18n.get('Packaging'),
  selectDefault: I18n.get('Please Select'),
  available: I18n.get('Available'),
  unavailable: I18n.get('Unavailable'),
  discontinued: I18n.get('Discontinued'),
  grams: I18n.get('g'),
  allStatuses: I18n.get('Any Status')
})

export default Strings

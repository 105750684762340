import { useReducer, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import DatePicker from 'react-datepicker'

import {
  Box,
  FlexV2,
  Label,
  Select,
  Text,
  Separator,
  Input,
  Button,
  Loader
} from '@/primitives'

import InputError from '@/elements/InputError'
import DocumentUploader from '@/elements/DocumentUploader'
import HeaderV2 from '@/components/Operations/Shared/HeaderV2'

import OrganizationInput from '../../../Shared/Organization/Input'
import Address from './Address'
import BankDetails from './BankDetails'

import { requestAllCountries } from '@/actions/operations/country'
import { requestAllCities } from '@/actions/operations/city'
import { requestAllCurrencies } from '@/actions/operations/currency'
import { requestAllPaymentTerms } from '@/actions/operations/paymentTerm'
import { requestAllPaymentMethods } from '@/actions/operations/paymentMethod'
import { requestAllRegions } from '@/actions/operations/region'

import {
  createCustomer,
  requestCustomer,
  updateCustomer
} from '@/actions/operations/customer'

import {
  getOperationsAllCountries,
  getOperationsAllRegions,
  getOperationsAllCities,
  getOperationsAllCurrencies,
  getOperationsAllPaymentMethods,
  getOperationsAllPaymentTerms,
  getOperationsCustomer,
  getOperationsLoading,
  getOperationsError
} from '@/reducers/selectors'

import { getValidationErrorMap } from '@/Util/GeneralUtils'
import { cleanS3Key } from '@/Util/StorageUtils'

import history from '../../../../../history'
import Strings from '../Strings'

import useDeviceSize from '@/hooks/useDeviceSize'

import { CUSTOMER_STATUSES, CUSTOMER_CATEGORIES } from '../utils'

import { FIELDS, customerSchema } from './config'

import {
  initialState,
  reducer,
  UPDATE_INPUT,
  SET_STATE,
  DESELECT_FILE,
  SELECT_FILE,
  UPDATE_ADDRESS,
  UPDATE_BANK_DETAILS,
  RESET_SECTION
} from './state'

function CustomersForm({ state, modulePath }) {
  const dispatch = useDispatch()
  const isMobile = useDeviceSize('mobile')
  const isLaptop = useDeviceSize()
  const { itemId } = useParams()

  const strings = Strings()

  const countries = getOperationsAllCountries()
  const regions = getOperationsAllRegions()
  const cities = getOperationsAllCities()
  const paymentMethods = getOperationsAllPaymentMethods()
  const paymentTerms = getOperationsAllPaymentTerms()
  const currencies = getOperationsAllCurrencies()
  const customer = getOperationsCustomer()
  const loading = getOperationsLoading()
  const error = getOperationsError()

  const customerCategories = CUSTOMER_CATEGORIES(strings)
  const customerStatuses = CUSTOMER_STATUSES(strings)

  const [formState, dispatchFormState] = useReducer(reducer, initialState)
  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)

  const [showRegistration, setShowRegistration] = useState(false)
  const [showTax, setShowTax] = useState(false)
  const [showNationalAddress, setShowNationalAddress] = useState(false)
  const [showBankDetails, setShowBankDetails] = useState(false)

  useEffect(() => {
    dispatch(requestAllCountries())
    dispatch(requestAllCities())
    dispatch(requestAllPaymentMethods())
    dispatch(requestAllPaymentTerms())
    dispatch(requestAllCurrencies())
    dispatch(requestAllRegions())
  }, [dispatch])

  useEffect(() => {
    if (itemId && customer.id !== itemId) {
      dispatch(requestCustomer({ customerId: itemId }))
    }
  }, [itemId])

  useEffect(() => {
    if (customer?.id && customer.id === itemId) {
      dispatchFormState({
        type: SET_STATE,
        state: { ...customer }
      })
    }
  }, [customer])

  useEffect(() => {
    if (!itemId && state.organizations.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: FIELDS.organizationId,
        value: state.organizations[0]
      })
    }
  }, [state])

  useEffect(() => {
    if (
      !!formState.registrationNumber ||
      formState.registrationDocument?.s3Key
    ) {
      setShowRegistration(true)
    }
  }, [formState.registrationNumber, formState.registrationDocument?.s3Key])

  useEffect(() => {
    if (!!formState.taxNumber || formState.taxDocument?.s3Key) {
      setShowTax(true)
    }
  }, [formState.taxNumber, formState.taxDocument?.s3Key])

  useEffect(() => {
    if (
      !!formState.nationalAddress.streetAddress ||
      formState.nationalAddressDocument?.s3Key
    ) {
      setShowNationalAddress(true)
    }
  }, [formState.nationalAddress, formState.nationalAddressDocument?.s3Key])

  useEffect(() => {
    if (
      !!formState.bankDetails.bankName ||
      formState.bankDetailsDocument?.s3Key
    ) {
      setShowBankDetails(true)
    }
  }, [formState.bankDetails, formState.bankDetailsDocument?.s3Key])

  useEffect(() => {
    if (waiting && !loading && !error) {
      history.replace(modulePath)
    }
  }, [loading])

  const onSubmit = async e => {
    try {
      const payload = await customerSchema().validate(formState, {
        abortEarly: false
      })
      setWaiting(true)

      if (itemId) {
        payload.customerId = itemId
        dispatch(updateCustomer(payload))
      } else {
        dispatch(createCustomer(payload))
      }
    } catch (error) {
      setErrors(getValidationErrorMap(error))
    }
  }

  const handleInput = e => {
    const { name, value } = e.currentTarget
    dispatchFormState({ type: UPDATE_INPUT, name, value })
  }

  const handleDateInput = (date, name) => {
    dispatchFormState({ type: UPDATE_INPUT, name, value: date })
  }

  const handleOrganizationInput = organizationId => {
    dispatchFormState({
      type: SET_STATE,
      state: {
        ...formState,
        organizationId,
        countryId: '',
        regionId: '',
        cityId: '',
        currencyId: ''
      }
    })
  }

  const filterCountries = () => {
    return countries.filter(c => c.organizationId === formState.organizationId)
  }

  const filterRegions = () => {
    return regions.filter(r => r.countryId === formState.countryId)
  }

  const filterCities = () => {
    return cities.filter(c => c.regionId === formState.regionId)
  }

  const onSetFile = (name, file) => {
    dispatchFormState({ type: SELECT_FILE, name, file })
  }

  const onDeselectFile = name => {
    dispatchFormState({ type: DESELECT_FILE, name })
  }

  const onHandleAddress = e => {
    const { name, value } = e.currentTarget
    dispatchFormState({ type: UPDATE_ADDRESS, name, value })
  }

  const onHandleBankDetails = (e, isBranchAddress) => {
    const { name, value } = e.currentTarget
    dispatchFormState({
      type: UPDATE_BANK_DETAILS,
      name,
      value,
      isBranchAddress
    })
  }

  const handleSection = e => {
    const {
      name,
      dataset: { action }
    } = e.currentTarget

    const isShow = action === 'add'

    if (name === 'registration') {
      setShowRegistration(isShow)
    }
    if (name === 'tax') {
      setShowTax(isShow)
    }
    if (name === 'nationalAddress') {
      setShowNationalAddress(isShow)
    }
    if (name === 'bankDetails') {
      setShowBankDetails(isShow)
    }
    dispatchFormState({ type: RESET_SECTION, section: name })
  }

  const getHeader = () => {
    if (itemId) {
      return strings.updateFormHeader
    }
    return strings.createFormHeader
  }

  const filterCurrencies = () => {
    return currencies.filter(c => c.organizationId === formState.organizationId)
  }

  const SectionButton = ({ show, name, addString, deleteString }) => {
    return (
      <FlexV2 alignMainAxis='flex-end'>
        <Button
          className='SectionButton'
          onClick={handleSection}
          size='small'
          name={name}
          data-action={!show ? 'add' : 'delete'}
          type='button'
          variant={!show ? 'primary' : 'error'}
        >
          {!show ? addString : deleteString}
        </Button>
      </FlexV2>
    )
  }

  return (
    <FlexV2
      as='form'
      axisGap={400}
      direction='column'
      className='Operations__Form'
    >
      <HeaderV2
        title={getHeader()}
        backPath={modulePath}
        buttonIcon={'save'}
        buttonText={strings.saveCustomer}
        buttonCallback={onSubmit}
      />
      <Loader isLoading={loading}>
        <FlexV2 axisGap={800} direction={isLaptop ? 'column' : null}>
          <FlexV2
            axisGap={400}
            direction='column'
            className='Operations__Form__Fields'
          >
            <Box className='customers'>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.customer}
              </Text>
              <OrganizationInput
                fieldName={FIELDS.organizationId}
                organizationId={formState.organizationId}
                handleInput={handleOrganizationInput}
                errors={errors}
              />
              <FlexV2
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: 3 }}>
                  <Text variant='page' tone={700}>
                    {strings.name}
                  </Text>
                  <Input
                    value={formState.name}
                    name={FIELDS.name}
                    onChange={handleInput}
                  />
                  <InputError error={errors?.name} />
                </Label>
                <Label style={{ flex: 1 }}>
                  <Text variant='page' tone={700}>
                    {strings.code}
                  </Text>
                  <Input
                    value={formState.code}
                    name={FIELDS.code}
                    onChange={handleInput}
                  />
                  <InputError error={errors?.code} />
                </Label>
              </FlexV2>
              <FlexV2
                axisGap={300}
                alignMainAxis='space-evenly'
                className='Section__Filters'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: 1 }}>
                  <Text variant='page' tone={700}>
                    {strings.category}
                  </Text>
                  <Select
                    className='Operations__Form__Select'
                    name={FIELDS.category}
                    value={formState.category}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {Object.entries(customerCategories).map(([id, name]) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.category} />
                </Label>
                <Label style={{ flex: 1 }}>
                  <Text variant='page' tone={700}>
                    {strings.status}
                  </Text>
                  <Select
                    className='Operations__Form__Select'
                    name={FIELDS.status}
                    value={formState.status}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {Object.entries(customerStatuses).map(([id, name]) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.status} />
                </Label>
                <Label style={{ flex: '1 1 0' }}>
                  <FlexV2 axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.onboardDate}
                    </Text>
                    <DatePicker
                      maxDate={new Date()}
                      selected={formState.onboardDate}
                      className='Operations__Form__Select'
                      onChange={date =>
                        handleDateInput(date, FIELDS.onboardDate)
                      }
                    />
                    <InputError error={errors?.onboardDate} />
                  </FlexV2>
                </Label>
              </FlexV2>
              <FlexV2
                axisGap={300}
                alignMainAxis='space-evenly'
                className='Section__Filters'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: 1 }}>
                  <Text variant='page' tone={700}>
                    {strings.country}
                  </Text>
                  <Select
                    className='Operations__Form__Select'
                    name={FIELDS.countryId}
                    value={formState.countryId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterCountries().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.countryId} />
                </Label>
                <Label style={{ flex: 1 }}>
                  <Text variant='page' tone={700}>
                    {strings.region}
                  </Text>
                  <Select
                    className='Operations__Form__Select'
                    name={FIELDS.regionId}
                    value={formState.regionId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterRegions().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.regionId} />
                </Label>
                <Label style={{ flex: 1 }}>
                  <Text variant='page' tone={700}>
                    {strings.city}
                  </Text>
                  <Select
                    className='Operations__Form__Select'
                    name={FIELDS.cityId}
                    value={formState.cityId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterCities().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.cityId} />
                </Label>
              </FlexV2>
            </Box>
            <Box className='contact'>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.contact}
              </Text>
              <FlexV2
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: 1 }}>
                  <Text variant='page' tone={700}>
                    {strings.contactName}
                  </Text>
                  <Input
                    value={formState.contactName}
                    name={FIELDS.contactName}
                    onChange={handleInput}
                  />
                  <InputError error={errors?.contactName} />
                </Label>
                <Label style={{ flex: 1 }}>
                  <Text variant='page' tone={700}>
                    {strings.contactNumber}
                  </Text>
                  <Input
                    value={formState.contactNumber}
                    name={FIELDS.contactNumber}
                    onChange={handleInput}
                  />
                  <InputError error={errors?.contactNumber} />
                </Label>
                <Label style={{ flex: 1 }}>
                  <Text variant='page' tone={700}>
                    {strings.contactEmail}
                  </Text>
                  <Input
                    value={formState.contactEmail}
                    name={FIELDS.contactEmail}
                    onChange={handleInput}
                    type='email'
                  />
                  <InputError error={errors?.contactEmail} />
                </Label>
              </FlexV2>
            </Box>
            <Box className='payment'>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.payment}
              </Text>
              <FlexV2
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: 1 }}>
                  <Text variant='page' tone={700}>
                    {strings.paymentMethod}
                  </Text>
                  <Select
                    className='Operations__Form__Select'
                    name={FIELDS.paymentMethodId}
                    value={formState.paymentMethodId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {paymentMethods.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.paymentMethodId} />
                </Label>
                <Label style={{ flex: 1 }}>
                  <Text variant='page' tone={700}>
                    {strings.paymentTerm}
                  </Text>
                  <Select
                    className='Operations__Form__Select'
                    name={FIELDS.paymentTermId}
                    value={formState.paymentTermId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {paymentTerms.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.paymentTermId} />
                </Label>
                <Label style={{ flex: 1 }}>
                  <Text variant='page' tone={700}>
                    {strings.currency}
                  </Text>
                  <Select
                    className='Operations__Form__Select'
                    name={FIELDS.currencyId}
                    value={formState.currencyId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterCurrencies().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.currencyId} />
                </Label>
              </FlexV2>
            </Box>
            <Separator />
            <Box className='contract'>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.contract}
              </Text>
              <FlexV2
                axisGap={300}
                direction='column'
                style={{ marginTop: '1em' }}
              >
                <FlexV2
                  axisGap={300}
                  alignMainAxis='space-evenly'
                  direction='column'
                >
                  <Box>
                    <Text variant='page' fontWeight={500} tone={700}>
                      {strings.contractDocument}
                    </Text>
                    <DocumentUploader
                      name='contractDocument'
                      file={formState.contractDocument.file}
                      fileUrl={formState.contractDocument.url}
                      fileName={cleanS3Key(formState.contractDocument.s3Key)}
                      onSetFile={onSetFile}
                      onDeselectFile={onDeselectFile}
                      setErrors={setErrors}
                    />
                    <InputError error={errors?.registrationDocument} />
                  </Box>
                </FlexV2>
              </FlexV2>
            </Box>
            <Separator />
            <Box className='registration'>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.registration}
              </Text>
              <FlexV2
                axisGap={300}
                direction='column'
                style={{ marginTop: '1em' }}
              >
                {showRegistration && (
                  <FlexV2
                    axisGap={300}
                    alignMainAxis='space-evenly'
                    direction='column'
                  >
                    <Label>
                      <Text variant='page' fontWeight={500} tone={700}>
                        {strings.registrationNumber}
                      </Text>

                      <Input
                        value={formState.registrationNumber}
                        name={FIELDS.registrationNumber}
                        onChange={handleInput}
                      />
                      <InputError error={errors?.registrationNumber} />
                    </Label>
                    <Box>
                      <Text variant='page' fontWeight={500} tone={700}>
                        {strings.registrationDocument}
                      </Text>
                      <DocumentUploader
                        name='registrationDocument'
                        file={formState.registrationDocument.file}
                        fileUrl={formState.registrationDocument.url}
                        fileName={cleanS3Key(
                          formState.registrationDocument.s3Key
                        )}
                        onSetFile={onSetFile}
                        onDeselectFile={onDeselectFile}
                        setErrors={setErrors}
                      />
                      <InputError error={errors?.registrationDocument} />
                    </Box>
                  </FlexV2>
                )}
                <SectionButton
                  name='registration'
                  show={showRegistration}
                  addString={strings.addRegistration}
                  deleteString={strings.deleteRegistration}
                />
              </FlexV2>
            </Box>
            <Separator />
            <Box className='tax'>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.taxInfo}
              </Text>
              {showTax && (
                <FlexV2
                  axisGap={300}
                  alignMainAxis='space-evenly'
                  direction='column'
                >
                  <Label>
                    <Text variant='page' fontWeight={500} tone={700}>
                      {strings.taxNumber}
                    </Text>
                    <Input
                      value={formState.taxNumber}
                      name={FIELDS.taxNumber}
                      onChange={handleInput}
                    />
                    <InputError error={errors?.taxNumber} />
                  </Label>
                  <Box>
                    <Text variant='page' fontWeight={500} tone={700}>
                      {strings.taxDocument}
                    </Text>
                    <DocumentUploader
                      name='taxDocument'
                      file={formState.taxDocument.file}
                      fileUrl={formState.taxDocument.url}
                      fileName={cleanS3Key(formState.taxDocument.s3Key, 'tax')}
                      onSetFile={onSetFile}
                      onDeselectFile={onDeselectFile}
                      setErrors={setErrors}
                    />
                    <InputError error={errors?.taxDocument} />
                  </Box>
                </FlexV2>
              )}
              <SectionButton
                name='tax'
                show={showTax}
                addString={strings.addTaxInfo}
                deleteString={strings.deleteTaxInfo}
              />
            </Box>
            <Separator />
            <Box className='nationalAddress'>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.nationalAddress}
              </Text>
              {showNationalAddress && (
                <FlexV2
                  axisGap={400}
                  alignMainAxis='space-between'
                  direction='column'
                >
                  <Address
                    state={formState.nationalAddress}
                    fields={FIELDS.nationalAddress}
                    errors={errors?.nationalAddress}
                    handleInput={onHandleAddress}
                  />
                  <Box>
                    <Text variant='page' fontWeight={500} tone={700}>
                      {strings.nationalAddressDocument}
                    </Text>
                    <DocumentUploader
                      name='nationalAddressDocument'
                      file={formState.nationalAddressDocument.file}
                      fileUrl={formState.nationalAddressDocument.url}
                      fileName={cleanS3Key(
                        formState.nationalAddressDocument.s3Key
                      )}
                      onSetFile={onSetFile}
                      onDeselectFile={onDeselectFile}
                      setErrors={setErrors}
                    />
                    <InputError error={errors?.nationalAddressDocument} />
                  </Box>
                </FlexV2>
              )}
              <SectionButton
                name='nationalAddress'
                show={showNationalAddress}
                addString={strings.addNationalAddress}
                deleteString={strings.deleteNationalAddress}
              />
            </Box>
            <Separator />
            <Box className='bankDetails'>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.bankDetails}
              </Text>
              {showBankDetails && (
                <FlexV2 direction='column'>
                  <BankDetails
                    state={formState.bankDetails}
                    fields={FIELDS.bankDetails}
                    errors={errors?.bankDetails}
                    handleInput={onHandleBankDetails}
                  />
                  <Box>
                    <Text variant='page' fontWeight={500} tone={700}>
                      {strings.bankDetailsDocument}
                    </Text>
                    <DocumentUploader
                      name='bankDetailsDocument'
                      file={formState.bankDetailsDocument.file}
                      fileUrl={formState.bankDetailsDocument.url}
                      fileName={cleanS3Key(formState.bankDetailsDocument.s3Key)}
                      onSetFile={onSetFile}
                      onDeselectFile={onDeselectFile}
                      setErrors={setErrors}
                    />
                    <InputError error={errors?.bankDetailsDocument} />
                  </Box>
                </FlexV2>
              )}
              <SectionButton
                name='bankDetails'
                show={showBankDetails}
                addString={strings.addBankDetails}
                deleteString={strings.deleteBankDetails}
              />
            </Box>
          </FlexV2>
        </FlexV2>
      </Loader>
    </FlexV2>
  )
}
export default CustomersForm

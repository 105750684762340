function sortArrayOfObjectsAsc(array, key) {
  array.sort((a, b) => {
    if (a[key] > b[key]) return 1
    return -1
  })
}

const sortArrayByObjectKey = (array = [], key = '') => {
  return array.sort((a, b) =>
    a[key].toLowerCase().localeCompare(b[key].toLowerCase(), 'en', {
      numeric: true
    })
  )
}

export function chunkArray(array, size) {
  const chunks = []
  let index = 0

  while (index < array.length) {
    chunks.push(array.slice(index, index + size))
    index += size
  }

  return chunks
}

const ArrayUtils = {
  sortArrayOfObjectsAsc,
  sortArrayByObjectKey,
  chunkArray
}

export default ArrayUtils

import { DateTime } from 'luxon'

import TimeUtils from '@/Util/TimeUtils'
import { requiredVectorMeasurements } from '@/Util/MeasurementUtils'

export const getDateRangeOptionBounds = (key, timeZone) => {
  const now = DateTime.now().set({ second: 0, millisecond: 0 })

  const today = now.set({ hour: 0, minute: 0 })

  const options = {
    lasthour: [now.minus({ hours: 1 }), now],
    today: [today, now],
    last24hours: [now.minus({ hours: 24 }), now],
    yesterday: [
      today.minus({ days: 1 }),
      today.minus({ days: 1 }).set({ hour: 23, minute: 59 })
    ],
    last7days: [now.minus({ days: 7 }), now],
    last30days: [now.minus({ days: 30 }), now],
    lastmonth: [
      today.minus({ months: 1 }).set({ day: 1 }),
      today.set({ day: 1, hour: 23, minute: 59 }).minus({ days: 1 })
    ],
    thisyear: [today.set({ month: 1, day: 1 }), today],
    last12months: [today.minus({ year: 1 }), today]
  }

  if (options[key]) {
    return options[key]
  }

  return options['last24hours']
}

export const buildTimestreamHistoricPayload = (
  sensors,
  table,
  measurements,
  interval,
  fromDate,
  toDate
) => {
  const payload = {
    sensors,
    table,
    interval,
    period: '1',
    fromDate,
    toDate,
    measurements
  }

  const dateDiff = TimeUtils.getDatesDiff(fromDate, toDate)
  if (dateDiff > 1 && dateDiff < 7 && payload.interval === 'm') {
    payload['period'] = '5'
  }

  if (dateDiff > 7 && payload.interval === 'm') {
    payload['period'] = '10'
  }

  return payload
}

export function getMeasurementsName(measurements, apiMeasurements) {
  return measurements.length > 0
    ? measurements
    : apiMeasurements.map(measurement => measurement.id)
}

export const getZones = (zone, selectedZone, innerZones) => {
  if (selectedZone === zone && innerZones.length > 0) {
    return innerZones
  }
  return [selectedZone]
}

export function addVectorMeasurements(measurements) {
  //TODO: remove hardcoded vector measurements when all measurements are available
  const hardcodedVectorMeasurements = [
    'absolute_humidity',
    'saturation_vapour_pressure',
    'wet_bulb_temperature',
    'vapour_pressure_deficit',
    'salinity',
    'growing_degree_hours',
    'total_dissolved_solids',
    'daily_light_integral',
    'daily_energy_consumption'
  ]

  const vectorMeasurements = Object.keys(requiredVectorMeasurements).filter(
    measurement => hardcodedVectorMeasurements.includes(measurement)
  )
  vectorMeasurements.forEach(measurement => {
    const measurementsRequired = requiredVectorMeasurements[measurement]

    if (
      measurementsRequired.every(measurement =>
        measurements.includes(measurement)
      )
    ) {
      measurements.push(measurement)
    }
  })

  return measurements
}

export function getMaxDateRange(dateFilters) {
  const RANGE_WEIGHTS = {
    lasthour: 0,
    today: 1,
    last24hours: 2,
    yesterday: 3,
    last7days: 4,
    last30days: 5,
    lastmonth: 6,
    thisyear: 7,
    last12months: 8,
    customRange: 9
  }

  const rangeIntervals = dateFilters?.map(filter => filter?.dateRange)
  const sortedDateRangeIntervals = rangeIntervals?.sort(
    (a, b) => RANGE_WEIGHTS[b] - RANGE_WEIGHTS[a]
  )

  if (sortedDateRangeIntervals?.length) {
    return sortedDateRangeIntervals[0]
  }

  return dateFilters[0]?.dateRange ?? 'last24hours'
}

/**
 * Adjusts the date to the start of the interval based on the timeInterval
 * @param {DateTime} date
 * @param {string} timeInterval
 * @returns {number} - the adjusted date in milliseconds
 */
export function adjustDateByInterval(date, timeInterval) {
  switch (timeInterval) {
    case 'hour':
      return date.set({ minutes: 0 }).toMillis()
    case 'day':
      return date.set({ hours: 0, minutes: 0 }).toMillis()
    default:
      return date.set({ seconds: 0, milliseconds: 0 }).toMillis()
  }
}

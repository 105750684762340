import { I18n } from 'aws-amplify'

import { Flex, Box, Text } from '@/primitives'
import useOrgLabel from '@/hooks/useOrgLabel'

const ProgressIndicator = ({ state, steps }) => {
  const { site, facility, room } = useOrgLabel(['site', 'facility', 'room'])

  const strings = {
    site: site.text,
    facility: facility.text,
    room: room.text,
    device: I18n.get('Device')
  }

  const currentViewIndex = steps.findIndex(step => step === state.currentView)

  function insertItem(step, index) {
    let style = {}
    if (index > currentViewIndex) {
      style = {
        opacity: '0.7',
        color: 'var(--ctx-theme-color-page-700)',
        borderColor: 'var(--ctx-theme-color-page-700)'
      }
    }

    return (
      <Box style={style} key={step}>
        {index > 0 && <Box style={style} className='ProgressIndicator__Line' />}
        <Box style={style} className='ProgressIndicator__Number'>
          {index + 1}
        </Box>
        <Text className='ProgressIndicator__Text'>{strings[step]}</Text>
      </Box>
    )
  }

  return (
    <Flex
      alignCrossAxis='center'
      alignMainAxis='center'
      className='ProgressIndicator'
    >
      {steps.map((step, index) => insertItem(step, index))}
    </Flex>
  )
}

export default ProgressIndicator

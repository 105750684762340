import { Text, FlexV2, Separator } from '@/primitives'
import PermissionToggle from './PermissionToggle'

import {
  getCurrentUser,
  getCurrentUserOrganizations,
  getZonesList
} from '@/reducers/selectors'

import {
  hasEditPermissions,
  RESOURCE_TYPE_USER,
  RESOURCE_TYPE_USER_ROLES
} from '@/Util/PermissionUtils'

import Strings from '../Strings'

const EditRoleSectionItem = ({
  permissionsIndexes,
  resourceId,
  sectionName,
  dispatch,
  state
}) => {
  const { readState, writeState, permissions } = state
  const coretexUser = getCurrentUser()
  const strings = Strings()
  const organizations = getCurrentUserOrganizations()
  const zones = getZonesList()

  function disableTogglePermission(isChecked) {
    if (sectionName === 'organization' && isChecked) {
      const enableOrganizations = permissions.filter(({ enabled }) => enabled)
      return enableOrganizations.length < 2
    }

    return false
  }

  const showEditOptions = hasEditPermissions(
    coretexUser,
    RESOURCE_TYPE_USER,
    RESOURCE_TYPE_USER_ROLES
  )

  function parseResourceId(resourceId, sectionName = 'organization') {
    if (sectionName === 'organization') {
      return organizations?.find(org => org.id === resourceId)?.name ?? ''
    }

    if (sectionName === 'zone') {
      return zones?.find(zone => zone.id === resourceId)?.name ?? ''
    }

    return resourceId
  }

  const isEnabled = ({ read, write }) => {
    return (
      permissions[read]?.enabled ||
      permissions[write]?.enabled ||
      readState === 'enabled' ||
      writeState === 'enabled'
    )
  }

  const isChecked = (type, permissionsIndexes) => {
    if (type === 'read') {
      return (
        readState === 'enabled' ||
        permissions[permissionsIndexes?.read]?.enabled
      )
    }

    if (type === 'write') {
      return (
        writeState === 'enabled' ||
        permissions[permissionsIndexes?.write]?.enabled
      )
    }
  }

  return (
    <FlexV2
      className={isEnabled(permissionsIndexes) ? 'enabled' : 'disabled'}
      alignCrossAxis='center'
      axisGap={400}
    >
      <Separator />
      <Text
        className='specific-permission'
        variant={isEnabled(permissionsIndexes) ? 'primary' : 'page'}
        tone='800'
      >{`${parseResourceId(resourceId, sectionName) || strings.general}`}</Text>
      <Separator />

      <FlexV2 axisGap={400}>
        <FlexV2
          direction={'column'}
          alignCrossAxis='center'
          className={'Control'}
        >
          <Text
            variant={
              !isChecked('write', permissionsIndexes) ? 'page' : 'primary'
            }
            tone='700'
          >
            {strings.editButtonSentence}
          </Text>
          <PermissionToggle
            checked={isChecked('read', permissionsIndexes)}
            permissionId={permissions[permissionsIndexes?.read]?.id}
            sectionName={sectionName}
            dispatch={dispatch}
            disabled={
              readState === 'enabled' ||
              !showEditOptions ||
              disableTogglePermission(isChecked('read', permissionsIndexes))
            }
          />
        </FlexV2>
        <FlexV2
          direction={'column'}
          alignCrossAxis='center'
          className={'Control'}
        >
          <Text
            variant={
              !isChecked('read', permissionsIndexes) ? 'page' : 'primary'
            }
            tone='700'
          >
            {strings.viewButtonSentence}
          </Text>
          <PermissionToggle
            checked={isChecked('write', permissionsIndexes)}
            permissionId={
              sectionName === 'organization'
                ? null
                : permissions[permissionsIndexes?.write]?.id
            }
            sectionName={sectionName}
            dispatch={dispatch}
            disabled={
              writeState === 'enabled' ||
              !showEditOptions ||
              sectionName === 'organization'
            }
          />
        </FlexV2>
      </FlexV2>
    </FlexV2>
  )
}

export default EditRoleSectionItem

import { FlexV2 } from '@/primitives'

import useMediaQuery from '@/hooks/useMediaQuery'
import OrganizationsFilter from '../../Shared/Filters/OrganizationsFilter'
import CountriesFilter from '../../Shared/Filters/CountriesFilter'

const TableFilter = ({ state, dispatchState }) => {
  const isAboveBreakpoint = useMediaQuery('min-width: 640px')

  return (
    <FlexV2
      direction={isAboveBreakpoint ? 'row' : 'column'}
      axisGap='300'
      style={{ whiteSpace: 'nowrap' }}
    >
      <OrganizationsFilter state={state} dispatchState={dispatchState} />
      <CountriesFilter state={state} dispatchState={dispatchState} />
    </FlexV2>
  )
}

export default TableFilter

import { I18n } from 'aws-amplify'
import ZoneUtils from '@/Util/ZoneUtils'

export const filterLayoutOptions = (layoutOptions, currentContext) => {
  if (currentContext === 'site') {
    return layoutOptions.filter(({ value }) => !value.includes('live'))
  }
  // add other cases here
  return layoutOptions
}

const LOCATION_LAYOUT_HEADER = {
  label: I18n.get('Location layouts'),
  value: 'header',
  symbol: 'explore',
  disabled: true
}

const MEASUREMENT_LAYOUT_HEADER = {
  label: I18n.get('Measurement layouts'),
  value: 'header',
  symbol: 'sensors',
  disabled: true
}

export const RESET_ZONE_LAYOUT = (zoneDepthString = 0) => ({
  label: `${I18n.get(
    'Reset to'
  )} ${zoneDepthString.toLocaleLowerCase()} ${I18n.get('default layout')}`,
  value: 'reset-zone-default',
  symbol: 'restart_alt'
})

export const CREATE_LAYOUT_FROM_CURRENT = {
  label: I18n.get('Create layout from current'),
  value: 'create-layout-from-current',
  symbol: 'dashboard_customize'
}

export const CLEAR_CURRENT_LAYOUT = {
  label: I18n.get('Clear current layout'),
  value: 'clear-current-layout',
  symbol: 'delete_sweep'
}

export const getLayoutTools = (
  currentDashboard,
  zoneDepth,
  allowCreate = true
) => {
  const zoneDepthString = ZoneUtils.getZoneDepthStringFromDepth(zoneDepth)
  let baseOptions = [CLEAR_CURRENT_LAYOUT, RESET_ZONE_LAYOUT(zoneDepthString)]

  if (currentDashboard?.isPreset) {
    if (allowCreate) {
      return [CREATE_LAYOUT_FROM_CURRENT]
    }
    return []
  } else if (!currentDashboard.zonePath) {
    if (allowCreate) {
      baseOptions.unshift(CREATE_LAYOUT_FROM_CURRENT)
    }
  }
  return [...baseOptions]
}

export const measurementLayoutOptions = [
  {
    label: I18n.get('Real-time'),
    value: 'live#value'
  },
  {
    label: I18n.get('Average Value (Last 24 Hours - Hourly)'),
    value: 'average#value#last-24-hours#hourly'
  },
  {
    label: I18n.get('Dataset Line (Last 24 Hours - Hourly)'),
    value: 'dataset#line#last-24-hours#hourly'
  },
  {
    label: I18n.get('Dataset Line (Last 7 Days - Daily)'),
    value: 'dataset#line#last-7-days#daily'
  },
  {
    label: I18n.get('Dataset Line (Last 7 Days - Hourly)'),
    value: 'dataset#line#last-7-days#hourly'
  }
]

export const getLayoutId = (isDefault, dashboardId, isPreset) => {
  return isPreset
    ? dashboardId
    : isDefault
    ? `default#${dashboardId}`
    : `config#${dashboardId}`
}

export const getLayoutOptions = ({
  dashboards,
  zonesHierarchy,
  siteHierarchy,
  zoneDepth,
  currentDashboardId
}) => {
  const organizationId = siteHierarchy.organizationId
  // zoneDepthId is the non-localized version of the zone depth string
  const zoneDepthId = ZoneUtils.getZoneDepthIdentifierFromDepth(zoneDepth)
  // zoneDepthString is the localized version of the zone depth string
  const zoneDepthString = ZoneUtils.getZoneDepthStringFromDepth(zoneDepth)
  let options = [
    MEASUREMENT_LAYOUT_HEADER,
    ...filterLayoutOptions(measurementLayoutOptions, zoneDepthId)
  ]

  const { filteredDashboards, dashboardsToFix } = dashboards.reduce(
    (acc, dashboard) => {
      const { dashboardId, context, zonePath, isDefault } = dashboard

      if (zonePath && !isDefault) {
        const pathSegements = zonePath.split('/').slice(1)
        const siteId = pathSegements.at(0)
        const zone = ZoneUtils.getZoneHierarchyValueByPath(
          zonesHierarchy[siteId],
          zonePath
        )
        // This might happen if a zone gets moved to a different location
        // Inter-site zone moves are not currently supported so they are within the same site
        // This means the first part and the last part of the path will be the same
        // If one day we support inter-site zone moves, we will need to update this logic as well
        if (!zone) {
          acc.dashboardsToFix.push(dashboard)
        } else if (
          context === zoneDepthId &&
          zonesHierarchy[siteId].organizationId === organizationId
        ) {
          const isSite = zonesHierarchy[siteId].id === zone.id
          const siteName = !isSite ? `${zonesHierarchy[siteId]?.name} > ` : ''
          acc.filteredDashboards.push({
            label: `${siteName} ${zone.name} ${zoneDepthString}`,
            value: getLayoutId(false, dashboardId),
            isCurrent: dashboardId === currentDashboardId
          })
        }
      }
      return acc
    },
    {
      filteredDashboards: [],
      dashboardsToFix: []
    }
  )

  if (filteredDashboards.length > 0) {
    options = [LOCATION_LAYOUT_HEADER, ...filteredDashboards, ...options]
  }

  return {
    layoutOptions: options,
    dashboardsToFix
  }
}

import { convertToAbsoluteHumidity } from '@/components/DashboardPageV2/Desktop/Widgets/Environment/AbsoluteHumidity/utils'
import { parseValue } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/utils'

import Value from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/Chart/Value'
import useAverageData from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Hooks/useAverageData'
import { getRanges } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'

export default function AverageValue({ unit, options, config }) {
  const data = useAverageData({
    measurements: ['temperature', 'humidity'],
    range: config.range
  })

  const parseOptions = (temperature, humidity) => ({
    fixedFactor: options.fixedFactor,
    scaleFactor: options.scaleFactor,
    value: convertToAbsoluteHumidity({ temperature, humidity })
  })

  return (
    <Value
      data={{
        value: parseValue(
          parseOptions(data?.temperature?.value, data?.humidity?.value)
        ),
        min: parseValue(
          parseOptions(data?.temperature?.min, data?.humidity?.min)
        ),
        max: parseValue(
          parseOptions(data?.temperature?.max, data?.humidity?.max)
        ),
        unit,
        status: data?.temperature?.status || data?.humidity?.status,
        subtitle: getRanges(config.range)?.label ?? ''
      }}
    />
  )
}

import { makeOperationsApiCall } from './utils'

export const getPackageUnitInventoryTransfers = async ({
  cognitoUser,
  ...params
}) => {
  try {
    const query = /* GraphQL */ `
      query getPackageUnitInventoryTransfers(
        $filter: GetPackageUnitInventoryTransfersFilter
        $order: OrderEnum
        $offset: Int
        $limit: Int
      ) {
        getPackageUnitInventoryTransfers(
          filter: $filter
          order: $order
          offset: $offset
          limit: $limit
        ) {
          packageUnitInventoryTransfers {
            id
            organizationId
            packageUnitInventory {
              id
              storageFacility {
                id
                name
              }
              packageUnit {
                id
                name
              }
            }
            shippingMethod {
              id
              name
            }
            storageFacility {
              id
              name
            }
            status
            quantity
            shippedDate
            deliveredDate
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getPackageUnitInventoryTransfers',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getPackageUnitInventoryTransfer = async ({
  cognitoUser,
  ...params
}) => {
  try {
    const query = /* GraphQL */ `
      query getPackageUnitInventoryTransfer(
        $packageUnitInventoryTransferId: String!
      ) {
        getPackageUnitInventoryTransfer(
          packageUnitInventoryTransferId: $packageUnitInventoryTransferId
        ) {
          id
          organizationId
          packageUnitInventoryId
          packageUnitInventory {
            storageFacilityId
            packageUnitId
          }
          shippingMethodId
          storageFacilityId
          status
          quantity
          shippedDate
          deliveredDate
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getPackageUnitInventoryTransfer',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const createPackageUnitInventoryTransfer = async ({
  cognitoUser,
  ...params
}) => {
  try {
    const query = /* GraphQL */ `
      mutation createPackageUnitInventoryTransfer(
        $organizationId: String!
        $packageUnitInventoryId: String!
        $shippingMethodId: String!
        $storageFacilityId: String!
        $status: PackageUnitInventoryTransferStatus!
        $quantity: Int!
        $shippedDate: GraphQLTimestamp
        $deliveredDate: GraphQLTimestamp
      ) {
        createPackageUnitInventoryTransfer(
          organizationId: $organizationId
          packageUnitInventoryId: $packageUnitInventoryId
          shippingMethodId: $shippingMethodId
          storageFacilityId: $storageFacilityId
          status: $status
          quantity: $quantity
          shippedDate: $shippedDate
          deliveredDate: $deliveredDate
        ) {
          id
          organizationId
          packageUnitInventory {
            id
            storageFacility {
              id
              name
            }
            packageUnit {
              id
              name
            }
          }
          shippingMethod {
            id
            name
          }
          storageFacility {
            id
            name
          }
          status
          quantity
          shippedDate
          deliveredDate
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'createPackageUnitInventoryTransfer',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updatePackageUnitInventoryTransfer = async ({
  cognitoUser,
  ...params
}) => {
  try {
    const query = /* GraphQL */ `
      mutation updatePackageUnitInventoryTransfer(
        $packageUnitInventoryTransferId: String!
        $organizationId: String!
        $packageUnitInventoryId: String!
        $shippingMethodId: String!
        $storageFacilityId: String!
        $status: PackageUnitInventoryTransferStatus!
        $quantity: Int!
        $shippedDate: GraphQLTimestamp
        $deliveredDate: GraphQLTimestamp
      ) {
        updatePackageUnitInventoryTransfer(
          packageUnitInventoryTransferId: $packageUnitInventoryTransferId
          organizationId: $organizationId
          packageUnitInventoryId: $packageUnitInventoryId
          shippingMethodId: $shippingMethodId
          storageFacilityId: $storageFacilityId
          status: $status
          quantity: $quantity
          shippedDate: $shippedDate
          deliveredDate: $deliveredDate
        ) {
          id
          organizationId
          packageUnitInventory {
            id
            storageFacility {
              id
              name
            }
            packageUnit {
              id
              name
            }
          }
          shippingMethod {
            id
            name
          }
          storageFacility {
            id
            name
          }
          status
          quantity
          shippedDate
          deliveredDate
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'updatePackageUnitInventoryTransfer',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

import { call, put, takeLatest } from 'redux-saga/effects'

import {
  REQUEST_FERTILIZER_INVENTORIES,
  REQUEST_FERTILIZER_INVENTORY,
  receiveFertilizerInventories,
  receiveFertilizerInventory
} from '@/actions/operations/fertilizerInventory'

import {
  getFertilizerInventories,
  getFertilizerInventory
} from '@/api/operations/fertilizerInventory'

function* sendRequestFertilizerInventories(action) {
  const { data, error = null } = yield call(
    getFertilizerInventories,
    action.params
  )
  const { fertilizerInventories = [], count = 0 } = data
  yield put(receiveFertilizerInventories(fertilizerInventories, count, error))
}

export function* watchRequestFertilizerInventories() {
  yield takeLatest(
    REQUEST_FERTILIZER_INVENTORIES,
    sendRequestFertilizerInventories
  )
}

function* sendRequestFertilizerInventory(action) {
  const { data: fertilizerInventory, error = null } = yield call(
    getFertilizerInventory,
    action.params
  )
  yield put(receiveFertilizerInventory(fertilizerInventory, error))
}

export function* watchRequestFertilizerInventory() {
  yield takeLatest(REQUEST_FERTILIZER_INVENTORY, sendRequestFertilizerInventory)
}

import { Button, Cell, Row } from '../../../../../primitives'

import { SUPPLIER_STATUSES, SUPPLIER_CATEGORIES } from '../utils'

import Strings from '../Strings'

function TableRow({ item, onEdit, canEdit }) {
  const strings = Strings()

  const categories = SUPPLIER_CATEGORIES(strings)
  const statuses = SUPPLIER_STATUSES(strings)

  const {
    id,
    name,
    code,
    category,
    status,
    contactName,
    contactEmail,
    country,
    region
  } = item

  const handleEdit = () => {
    onEdit(id)
  }

  return (
    <Row>
      <Cell columnName={strings.company}>
        {name}
        <span>{code}</span>
      </Cell>
      <Cell columnName={strings.status}>
        {statuses[status]}
        <span>{categories[category]}</span>
      </Cell>
      <Cell columnName={strings.contact}>
        {contactName}
        <span>{contactEmail}</span>
      </Cell>
      <Cell columnName={strings.location}>
        {country.name}
        <span>{region ? region.name : strings.allRegions}</span>
      </Cell>
      <Cell className='Operations__Table__Column__Actions'>
        {canEdit && (
          <Button
            variant='info'
            size='small'
            iconBefore='edit'
            onClick={handleEdit}
          />
        )}
      </Cell>
    </Row>
  )
}

export default TableRow

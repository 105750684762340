import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {
  getSiteTrackingStatus,
  updateSiteTrackingStatus
} from '@/api/ml/siteTracker'

export const cleanSiteTrackingStatus = createAsyncThunk(
  'cleanSiteTrackingStatus',
  () => {
    return {}
  }
)

export const fetchSiteTrackingStatus = createAsyncThunk(
  'fetchSiteTrackingStatus',
  async params => {
    const { status, error } = await getSiteTrackingStatus(params)
    return { status, error }
  }
)

export const sendUpdateSiteTrackingStatus = createAsyncThunk(
  'sendUpdateSiteTrackingStatus',
  async params => {
    const { status, error } = await updateSiteTrackingStatus(params)
    return { status, error }
  }
)

const mlSiteTrackerSlice = createSlice({
  name: 'mlSiteTrackerReducer',
  initialState: {
    status: {},
    error: null
  },
  extraReducers: builder => {
    builder
      .addCase(cleanSiteTrackingStatus.fulfilled, (state, action) => {
        state.status = {}
        state.error = null
      })
      .addCase(fetchSiteTrackingStatus.pending, (state, action) => {
        state.status = {}
        state.error = null
      })
      .addCase(fetchSiteTrackingStatus.fulfilled, (state, action) => {
        if (!action?.payload?.error) {
          state.status = action?.payload?.status ?? {}
        }

        state.error = action?.payload?.error ?? null
      })
      .addCase(sendUpdateSiteTrackingStatus.pending, (state, action) => {
        state.error = null
      })
      .addCase(sendUpdateSiteTrackingStatus.fulfilled, (state, action) => {
        if (!action?.payload?.error) {
          state.status = action?.payload?.status ?? {}
        }

        state.error = action?.payload?.error ?? null
      })
  }
})

export default mlSiteTrackerSlice.reducer

import {
  CLEAR_INVENTORIES,
  CLEAR_INVENTORY,
  REQUEST_INVENTORIES,
  RECEIVE_INVENTORIES,
  REQUEST_INVENTORY,
  RECEIVE_INVENTORY
} from '@/actions/operations/inventory'

export const inventoryInitialState = {
  inventories: [],
  inventoriesCount: 0,
  inventory: {},
  loading: false,
  error: null
}

const handlers = {
  [CLEAR_INVENTORIES]: (state, action) => ({
    ...state,
    inventories: [],
    inventoriesCount: 0,
    error: null
  }),
  [CLEAR_INVENTORY]: (state, action) => ({
    ...state,
    inventory: [],
    error: null
  }),
  [REQUEST_INVENTORIES]: (state, action) => ({
    ...state,
    inventories: [],
    inventoriesCount: 0,
    error: null,
    loading: true
  }),
  [RECEIVE_INVENTORIES]: (state, action) => ({
    ...state,
    inventories: !action.error ? action.inventories : [],
    inventoriesCount: action?.count ?? 0,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_INVENTORY]: (state, action) => ({
    ...state,
    inventory: [],
    error: null,
    loading: true
  }),
  [RECEIVE_INVENTORY]: (state, action) => ({
    ...state,
    inventory: !action.error ? action.inventory : {},
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

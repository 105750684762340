import { Fragment, useState } from 'react'

import RegenerateApiKeyConfirm from './RegenerateApiKeyConfirm'

import { Box, Text, Card, Slot, Icon, Flex, Button } from '@/primitives'

import {
  getCurrentUserOrganizations,
  getApiKeys,
  getUsagePlanSliceLoading
} from '@/reducers/selectors'

import Strings from './Strings'

const getOrgName = (id, allOrgs) => {
  const { name } = allOrgs?.find(org => org.id === id)
  return name
}

const getApiKey = (id, apiKeys) => {
  if (apiKeys?.length === 0) return null
  const currentApi = apiKeys.find(({ name }) => name === id)
  return currentApi?.value ?? null
}

const OrgApiCard = ({ id, writePermissions }) => {
  const strings = Strings()

  const allOrgs = getCurrentUserOrganizations()
  const apiKeys = getApiKeys()
  const currentApiKey = getApiKey(id, apiKeys)
  const loadingApiKeys = getUsagePlanSliceLoading()

  const [apiKeyVisible, setApiKeyVisible] = useState(false)
  const [apiKeyCopied, setApiKeyCopied] = useState(false)
  const [orgIdCopied, setOrgIdCopied] = useState(false)
  const [showRegenerateModal, setShowRegenerateModal] = useState(false)

  const orgName = getOrgName(id, allOrgs)

  const onClickCopyOrgId = () => {
    navigator.clipboard.writeText(id)
    setOrgIdCopied(true)
    setTimeout(() => {
      setOrgIdCopied(false)
    }, '1000')
  }

  const onClickCopyApiKey = () => {
    navigator.clipboard.writeText(currentApiKey)
    setApiKeyCopied(true)
    setTimeout(() => {
      setApiKeyCopied(false)
    }, '1000')
  }

  const onClickShowHideApiKey = () => {
    setApiKeyVisible(!apiKeyVisible)
  }

  const getApiKeyText = () => {
    if (!loadingApiKeys) {
      if (currentApiKey) {
        const placeholder = '*'.repeat(15)
        return apiKeyVisible ? currentApiKey : placeholder
      }

      if (!currentApiKey) return strings.contactSupport
    }

    return strings.loading
  }

  const onClickRegenerate = () => {
    setShowRegenerateModal(true)
  }

  return (
    <Fragment>
      <Card style={{ height: '100%' }}>
        <Slot name='header'>
          <Flex alignMainAxis='space-between' wrap='nowrap' axisGap={400}>
            <Text size={300} fontWeight={600}>
              {orgName}
            </Text>
            {writePermissions && currentApiKey && (
              <Button
                onClick={onClickRegenerate}
                style={{ marginInlineStart: 'auto', display: 'inline-block' }}
                variant='danger'
                size='small'
              >
                {strings.regenerate}
              </Button>
            )}
          </Flex>
        </Slot>
        <Slot name='body'>
          <Box>
            <Text
              variant='page'
              tone={700}
              fontWeight={400}
              size={200}
              as='span'
            >
              {strings.organizationId}
            </Text>
            <Flex alignMainAxis='space-between' wrap='nowrap' axisGap={400}>
              <Text
                fontWeight={400}
                size={200}
                as='p'
                style={{ marginTop: '0.4rem' }}
              >
                {id}
              </Text>
              <Button
                onClick={onClickCopyOrgId}
                style={{ width: '2.4rem', paddingTop: '0.2rem' }}
                variant='info'
                size='small'
              >
                <Icon name={orgIdCopied ? 'done' : 'copy'} />
              </Button>
            </Flex>
          </Box>
          <Box style={{ marginTop: '1rem' }}>
            <Text
              variant='page'
              tone={700}
              fontWeight={400}
              size={200}
              as='span'
            >
              {strings.apiKey}
            </Text>
            <Flex alignMainAxis='space-between' wrap='nowrap' axisGap={400}>
              <Text
                fontWeight={400}
                size={200}
                as='p'
                style={{ marginTop: '0.4rem', height: '3.3rem' }}
              >
                {getApiKeyText()}
              </Text>
              {currentApiKey && (
                <Flex alignMainAxis='space-between' wrap='nowrap' axisGap={400}>
                  <Button
                    onClick={onClickShowHideApiKey}
                    style={{
                      width: '2.4rem',
                      paddingTop: '0.2rem',
                      fontSize: '0.9rem'
                    }}
                    variant='text'
                    size='small'
                  >
                    <Icon
                      name={apiKeyVisible ? 'visibility' : 'visibility_off'}
                    />
                  </Button>
                  <Button
                    onClick={onClickCopyApiKey}
                    style={{ width: '2.4rem', paddingTop: '0.2rem' }}
                    variant='info'
                    size='small'
                  >
                    <Icon name={apiKeyCopied ? 'done' : 'copy'} />
                  </Button>
                </Flex>
              )}
            </Flex>
          </Box>
        </Slot>
      </Card>
      {showRegenerateModal && (
        <RegenerateApiKeyConfirm
          show={showRegenerateModal}
          setShow={setShowRegenerateModal}
          organizationId={id}
          orgName={orgName}
        />
      )}
    </Fragment>
  )
}

export default OrgApiCard

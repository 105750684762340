import { isObject } from '../../../Util/ObjectUtils'

export const hash = string => {
  let h
  const sl = string.length
  for (let i = 0; i < sl; i++) {
    h = (Math.imul(31, h) + string.charCodeAt(i)) | 0
  }
  return Math.abs(h).toString(36)
}

export const toDashCase = string =>
  string
    .split(/(?=[A-Z])/)
    .map(part => part.replace(/[A-Z]/g, match => '-' + match))
    .join('')
    .toLowerCase()

export const deepEntriesAncestorRef = (object = {}, parent) =>
  Object.keys(object).reduce((list, key) => {
    if (isObject(object[key])) {
      return list.concat(
        deepEntriesAncestorRef(object[key], parent ? parent.concat(key) : [key])
      )
    }
    return (
      list.push(parent ? [key, object[key], parent] : [key, object[key]]) &&
      list
    )
  }, [])

export const extend = (target = {}, source = {}) => ({ ...target, ...source })

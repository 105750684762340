import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  FlexV2,
  Box,
  Table,
  Slot,
  Column,
  Row,
  Text,
  Loader
} from '@/primitives'

import { Pagination } from '@/elements'

import HeaderV2 from '@/components/Operations/Shared/HeaderV2'
import TableFilter from './TableFilter'
import TableRow from './TableRow'

import { requestSuppliers } from '@/actions/operations/supplier'

import {
  getCurrentUser,
  getOperationsSuppliers,
  getOperationsSuppliersCount,
  getOperationsLoading
} from '@/reducers/selectors'

import history from '../../../../../history'

import {
  hasEditPermissions,
  RESOURCE_TYPE_OPERATIONS,
  RESOURCE_TYPE_OPERATIONS_CUSTOMERS
} from '@/Util/PermissionUtils'

import useMediaQuery from '@/hooks/useMediaQuery'

import Strings from '../Strings'

function SuppliersTable({ state, dispatchState, modulePath }) {
  const dispatch = useDispatch()

  const currentUser = getCurrentUser()
  const items = getOperationsSuppliers()
  const totalItems = getOperationsSuppliersCount()
  const loading = getOperationsLoading()

  const isAboveCustomBreakpoint = useMediaQuery('min-width: 620px')

  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(9)
  const [tableState, setTableState] = useState(state)
  const [canEdit, setCanEdit] = useState(false)

  const strings = Strings()

  useEffect(() => {
    if (currentUser.userName) {
      setCanEdit(
        hasEditPermissions(
          currentUser,
          RESOURCE_TYPE_OPERATIONS,
          RESOURCE_TYPE_OPERATIONS_CUSTOMERS
        )
      )
    }
  }, [currentUser])

  useEffect(() => {
    if (tableState.page !== state.page || items.length === 0) {
      setTableState({ ...state })
      fetchSuppliers(state.page)
    }
  }, [state.page])

  useEffect(() => {
    if (
      tableState.organizations !== state.organizations ||
      tableState.statuses !== state.statuses
    ) {
      setTableState({ ...state })
      fetchSuppliers(1)
      changePage(1)
    }
  }, [state.organizations, state.statuses])

  const fetchSuppliers = page => {
    if (state.organizations.length > 0 && state.statuses.length > 0) {
      dispatch(
        requestSuppliers({
          filter: {
            organizationId: state.organizations,
            status: state.statuses
          },
          limit: pageSize,
          offset: page - 1
        })
      )
    }
  }

  const changePage = newPage => {
    dispatchState({
      page: newPage
    })
  }

  const onCreate = () => {
    history.push(`${modulePath}/create`)
  }

  const onEdit = id => {
    history.push(`${modulePath}/update/${id}`)
  }

  return (
    <FlexV2 axisGap={400} direction='column'>
      <HeaderV2
        title={strings.manageSuppliers}
        buttonIcon={'add'}
        buttonText={strings.createSupplier}
        buttonCallback={onCreate}
        showButton={canEdit}
      >
        <TableFilter state={state} dispatchState={dispatchState} />
      </HeaderV2>
      <Loader isLoading={loading}>
        {totalItems === 0 && (
          <FlexV2 className={'Operations__Table__Empty'}>
            <Text>{strings.noSuppliers}</Text>
          </FlexV2>
        )}
        {totalItems > 0 && (
          <Table className='Operations__Table' aria-colcount='5'>
            <Slot name='head'>
              <Row>
                <Column>{strings.company}</Column>
                <Column>{strings.status}</Column>
                <Column>{strings.contact}</Column>
                <Column>{strings.location}</Column>
                <Column />
              </Row>
            </Slot>
            <Slot name='body'>
              {items?.map(item => (
                <TableRow
                  key={item.id}
                  onEdit={onEdit}
                  canEdit={canEdit}
                  item={item}
                  dispatchState={dispatchState}
                />
              ))}
            </Slot>
          </Table>
        )}
        {totalItems > pageSize && (
          <Box className='pagination-menu'>
            <Pagination
              type={isAboveCustomBreakpoint ? 'simple' : 'compact'}
              showPageCount={isAboveCustomBreakpoint}
              totalItems={totalItems}
              totalPages={totalPages}
              setTotalPages={setTotalPages}
              page={tableState.page}
              setPage={changePage}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          </Box>
        )}
      </Loader>
    </FlexV2>
  )
}

export default SuppliersTable

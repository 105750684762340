import BarChart from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/Chart/Bar'
import { LEGEND_POSITION } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/Chart/Bar/utils'
import useOperationsData from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Hooks/useOperationsData'

export default function DatasetBar({ config, produceId, options }) {
  const { legend, unit, keys, segmentId } = options

  const { datasetData } = useOperationsData({
    range: config.range,
    segmentId,
    filters: {
      interval: config.interval,
      produceId,
      range: config.range
    },
    queryType: config.query,
    tileType: config.chart
  })

  const legendOptions = {
    leftAxis: {
      legendPosition: LEGEND_POSITION.MIDDLE,
      legend: unit
    },
    bottomAxis: {
      legendPosition: LEGEND_POSITION.MIDDLE,
      legend
    },
    keys
  }

  return (
    <BarChart
      config={config}
      options={legendOptions}
      data={datasetData ?? []}
      unit={unit}
    />
  )
}

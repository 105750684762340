import { I18n } from 'aws-amplify'
import { number, object, string, date, array } from 'yup'

import { SCHEMA as itemsSchema } from './Items/config'

export const FIELDS = {
  id: 'id',
  saleId: 'saleId',
  storageFacilityId: 'storageFacilityId',
  shippingMethodId: 'shippingMethodId',
  dueDate: 'dueDate',
  status: 'status',
  deliveryDate: 'deliveryDate',
  price: 'price',
  discount: 'discount',
  tax: 'tax',
  items: 'items'
}

export const SCHEMA = orderDate => {
  return object().shape({
    status: string().required('Please select a status.'),
    storageFacilityId: string().required(
      I18n.get('Please select a storage facility.')
    ),
    shippingMethodId: string().required(
      I18n.get('Please select a shipping method.')
    ),
    price: number()
      .nullable()
      .transform(n => (isNaN(n) ? 0 : n))
      .min(0, I18n.get('The price cannot be less than zero.')),
    discount: number()
      .nullable()
      .transform(n => (isNaN(n) ? 0 : n))
      .min(0, I18n.get('The discount cannot be less than zero.')),
    tax: number()
      .nullable()
      .transform(n => (isNaN(n) ? 0 : n))
      .min(0, I18n.get('The tax cannot be less than zero.')),
    dueDate: date()
      .typeError('A due date must be provided.')
      .min(
        orderDate,
        I18n.get('The due date cannot be older than the order date.')
      )
      .required(I18n.get('A due date must be selected.')),
    deliveryDate: date()
      .nullable()
      .default(null)
      .typeError('A delivery date must be provided.')
      .min(
        orderDate,
        I18n.get('The delivery date cannot be older than the order date.')
      ),
    items: array()
      .of(itemsSchema())
      .min(1, I18n.get('You must include at least one item.'))
  })
}

import { I18n } from 'aws-amplify'

export const Strings = () => ({
  device404: I18n.get('The device you are looking for does not exist.'),
  goBack: I18n.get('Go back')
})

export const get404String = label => {
  return `${I18n.get('The')} ${label} ${I18n.get(
    'you are looking for does not exist.'
  )}`
}

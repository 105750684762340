import { useReducer, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import ReactSelect from 'react-select'

import useDeviceSize from '@/hooks/useDeviceSize'

import { requestCustomers } from '@/actions/operations/customer'
import { requestProducts } from '@/actions/operations/product'
import { requestAllCurrencies } from '@/actions/operations/currency'

import {
  createUnitPrice,
  requestUnitPrice,
  updateUnitPrice
} from '@/actions/operations/unitPrice'

import {
  getCurrentUserOrganizations,
  getOperationsCustomers,
  getOperationsProducts,
  getOperationsAllCurrencies,
  getOperationsUnitPrice,
  getOperationsLoading,
  getOperationsError
} from '@/reducers/selectors'

import { Box, Flex, Label, Select, Text, Input, Loader } from '@/primitives'

import InputError from '@/elements/InputError'
import HeaderV2 from '@/components/Operations/Shared/HeaderV2'
import OrganizationInput from '../../../Shared/Organization/Input'

import { getValidationErrorMap } from '@/Util/GeneralUtils'

import history from '../../../../../history'
import Strings from '../Strings'

import { initialState, reducer, UPDATE_INPUT, SET_STATE } from './state'

import { FIELDS, SCHEMA } from './config'

function UnitPriceForm({ state, modulePath }) {
  const dispatch = useDispatch()
  const isMobile = useDeviceSize('mobile')
  const isLaptop = useDeviceSize()
  const { itemId } = useParams()

  const strings = Strings()

  const organizations = getCurrentUserOrganizations()
  const customers = getOperationsCustomers()
  const products = getOperationsProducts()
  const currencies = getOperationsAllCurrencies()
  const unitPrice = getOperationsUnitPrice()
  const loading = getOperationsLoading()
  const error = getOperationsError()

  const [formState, dispatchFormState] = useReducer(reducer, initialState)

  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)

  useEffect(() => {
    dispatch(requestAllCurrencies())
  }, [dispatch])

  useEffect(() => {
    if (organizations.length > 0) {
      const organizationIds = organizations.map(o => o.id)
      dispatch(
        requestCustomers({
          filter: {
            organizationId: organizationIds
          }
        })
      )
      dispatch(
        requestProducts({
          filter: {
            organizationId: organizationIds
          }
        })
      )
    }
  }, [dispatch, organizations])

  useEffect(() => {
    if (itemId && unitPrice.id !== itemId) {
      dispatch(requestUnitPrice({ unitPriceId: itemId }))
    }
  }, [itemId])

  useEffect(() => {
    if (unitPrice?.id && unitPrice.id === itemId) {
      dispatchFormState({
        type: SET_STATE,
        state: { ...unitPrice }
      })
    }
  }, [unitPrice])

  useEffect(() => {
    if (!itemId && state.organizations.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'organizationId',
        value: state.organizations[0]
      })
    }
  }, [state.organizations])

  useEffect(() => {
    if (!itemId && state.customers.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'customerId',
        value: state.customers[0]
      })
    }
  }, [state.customers])

  useEffect(() => {
    if (!itemId && state.products.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'productId',
        value: state.products[0]
      })
    }
  }, [state.products])

  useEffect(() => {
    if (waiting && !loading && !error) {
      history.replace(modulePath)
    }
  }, [loading])

  const onSubmit = async e => {
    try {
      const payload = await SCHEMA().validate(formState, { abortEarly: false })
      setWaiting(true)

      if (itemId) {
        payload.unitPriceId = itemId
        dispatch(updateUnitPrice(payload))
      } else {
        dispatch(createUnitPrice(payload))
      }
    } catch (error) {
      setErrors(getValidationErrorMap(error))
    }
  }

  const handleInput = e => {
    const { name, value } = e.currentTarget
    dispatchFormState({ type: UPDATE_INPUT, name, value })
  }

  const handleNumericInput = e => {
    const { name, value } = e.currentTarget
    const newValue = value.length > 0 ? Number(value) : ''
    dispatchFormState({ type: UPDATE_INPUT, name, value: newValue })
  }

  const handleOrganizationInput = organizationId => {
    dispatchFormState({
      type: SET_STATE,
      state: {
        ...formState,
        organizationId,
        customerId: '',
        productId: '',
        currencyId: ''
      }
    })
  }

  const handleProductInput = option => {
    dispatchFormState({
      type: UPDATE_INPUT,
      name: 'productId',
      value: option.value
    })
  }

  const getProductValue = () => {
    if (formState.productId) {
      const productIndex = products.findIndex(
        p =>
          p.id === formState.productId &&
          p.organizationId === formState.organizationId
      )
      if (productIndex >= 0) {
        const { id, name, weight } = products[productIndex]
        const label = `${name} (${weight}${strings.grams})`
        return {
          value: id,
          label: label
        }
      }
    }
    return {
      label: strings.selectDefault,
      value: ''
    }
  }

  const filterProducts = () => {
    return products
      .filter(p => p.organizationId === formState.organizationId)
      .map(p => ({
        value: p.id,
        label: p.name
      }))
  }

  const filterCustomers = () => {
    return customers.filter(c => c.organizationId === formState.organizationId)
  }

  const filterCurrencies = () => {
    return currencies.filter(c => c.organizationId === formState.organizationId)
  }

  const getCurrencyCode = () => {
    if (formState.currencyId) {
      for (let currency of currencies) {
        if (currency.id === formState.currencyId) {
          return currency.code
        }
      }
    }
    return null
  }

  const getHeader = () => {
    if (itemId) {
      return strings.updateFormHeader
    }
    return strings.createFormHeader
  }

  return (
    <Flex
      as='form'
      axisGap={400}
      direction='column'
      className='Operations__Form'
    >
      <HeaderV2
        title={getHeader()}
        backPath={modulePath}
        buttonIcon={'save'}
        buttonText={strings.saveUnitPrice}
        buttonCallback={onSubmit}
      />
      <Loader isLoading={loading}>
        <Flex axisGap={800} direction={isLaptop ? 'column' : null}>
          <Flex
            axisGap={400}
            direction='column'
            className='Operations__Form__Fields'
          >
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.customer}
              </Text>
              <OrganizationInput
                fieldName={FIELDS.organizationId}
                organizationId={formState.organizationId}
                handleInput={handleOrganizationInput}
                errors={errors}
              />
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.customer}
                  </Text>
                  <Select
                    className='Operations__Select'
                    name={FIELDS.customerId}
                    value={formState.customerId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterCustomers().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.customerId} />
                </Label>
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.articleCode}
                  </Text>
                  <Input
                    className='Operations__Input'
                    type='text'
                    name={FIELDS.articleCode}
                    value={formState.articleCode}
                    onChange={handleInput}
                  />
                  <InputError error={errors?.articleCode} />
                </Label>
              </Flex>
            </Box>
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.product}
              </Text>
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.product}
                  </Text>
                  <ReactSelect
                    className='Operations__Form__Select'
                    classNamePrefix={'ReactSelect__Single'}
                    name={FIELDS.productId}
                    value={getProductValue()}
                    onChange={handleProductInput}
                    isMulti={false}
                    placeholder={strings.selectDefault}
                    options={filterProducts()}
                    isSearchable={true}
                  />
                  <InputError error={errors?.productId} />
                </Label>
              </Flex>
            </Box>
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.unit}
              </Text>
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.currency}
                    </Text>
                    <Select
                      className='Operations__Select'
                      name={FIELDS.currencyId}
                      value={formState.currencyId}
                      onChange={handleInput}
                    >
                      <option default value=''>
                        {strings.selectDefault}
                      </option>
                      {filterCurrencies().map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </Select>
                    <InputError error={errors?.currencyId} />
                  </Flex>
                </Label>
                <Label style={{ flex: '1 1 0' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.unitPrice}
                    </Text>
                    <Box className='Operations__Input__Container'>
                      <Input
                        className='Operations__Input'
                        type='number'
                        name={FIELDS.unitPrice}
                        value={formState.unitPrice}
                        onChange={handleNumericInput}
                      />
                      <span className='Operations__Input__Suffix'>
                        {getCurrencyCode()}
                      </span>
                    </Box>
                    <InputError error={errors?.unitPrice} />
                  </Flex>
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.discount}
                    </Text>
                    <Box className='Operations__Input__Container'>
                      <Input
                        className='Operations__Input'
                        type='number'
                        name={FIELDS.discount}
                        value={formState.discount}
                        onChange={handleNumericInput}
                      />
                      <span className='Operations__Input__Suffix'>
                        {strings.percent}
                      </span>
                    </Box>
                    <InputError error={errors?.discount} />
                  </Flex>
                </Label>
                <Label style={{ flex: '1 1 0' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.sellingPrice}
                    </Text>
                    <Box className='Operations__Input__Container'>
                      <Input
                        className='Operations__Input'
                        type='number'
                        name={FIELDS.sellingPrice}
                        value={formState.sellingPrice}
                        onChange={handleNumericInput}
                      />
                      <span className='Operations__Input__Suffix'>
                        {getCurrencyCode()}
                      </span>
                    </Box>
                    <InputError error={errors?.sellingPrice} />
                  </Flex>
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.description}
                    </Text>
                    <Input
                      className='Operations__Input'
                      type='text'
                      name={FIELDS.description}
                      value={formState.description}
                      onChange={handleInput}
                    />
                    <InputError error={errors?.description} />
                  </Flex>
                </Label>
                <Label style={{ flex: '1 1 0' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.alternateDescription}
                    </Text>
                    <Input
                      className='Operations__Input'
                      type='text'
                      name={FIELDS.alternateDescription}
                      value={formState.alternateDescription}
                      onChange={handleInput}
                    />
                    <InputError error={errors?.alternateDescription} />
                  </Flex>
                </Label>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Loader>
    </Flex>
  )
}

export default UnitPriceForm

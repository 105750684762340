import { makeOperationsApiCall } from './utils'

export const getInventories = async params => {
  try {
    const query = /* GraphQL */ `
      query getInventories(
        $filter: GetInventoriesFilter
        $order: OrderEnum
        $offset: Int
        $limit: Int
      ) {
        getInventories(
          filter: $filter
          order: $order
          offset: $offset
          limit: $limit
        ) {
          inventories {
            id
            organizationId
            storageFacility {
              id
              name
            }
            produce {
              id
              name
            }
            variety {
              id
              name
            }
            grade {
              id
              name
            }
            totalWeight
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getInventories',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getInventory = async params => {
  try {
    const query = /* GraphQL */ `
      query getInventory($inventoryId: String!) {
        getInventory(inventoryId: $inventoryId) {
          id
          organizationId
          storageFacility {
            id
            name
          }
          produce {
            id
            name
          }
          variety {
            id
            name
          }
          grade {
            id
            name
          }
          totalWeight
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getInventory',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

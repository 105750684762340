import { I18n } from 'aws-amplify'
import { object, string, number, date } from 'yup'

export const FIELDS = {
  id: 'id',
  organizationId: 'organizationId',
  inventoryId: 'inventoryId',
  fromStorageFacilityId: 'fromStorageFacilityId',
  shippingMethodId: 'shippingMethodId',
  storageFacilityId: 'storageFacilityId',
  produceId: 'produceId',
  varietyId: 'varietyId',
  gradeId: 'gradeId',
  status: 'status',
  totalWeight: 'totalWeight',
  shippedDate: 'shippedDate',
  deliveredDate: 'deliveredDate'
}

export const SCHEMA = () => {
  return object().shape({
    organizationId: string().required(I18n.get('An organization is required.')),
    inventoryId: string().required(
      I18n.get('Please select the origin inventory.')
    ),
    shippingMethodId: string().required(
      I18n.get('Please select an origin shipping method.')
    ),
    storageFacilityId: string().required(
      I18n.get('Please select a destination storage facility.')
    ),
    status: string().required(I18n.get('Please select a status.')),
    totalWeight: number()
      .typeError('Total weight is required.')
      .min(1, I18n.get('Total weight cannot be less than 1kg.'))
      .required(I18n.get('Total weight is required.')),
    shippedDate: date()
      .nullable()
      .default(null)
      .typeError('Shipped date is invalid.')
      .max(
        new Date(),
        I18n.get('The shipped date cannot be set in the future.')
      ),
    deliveredDate: date()
      .nullable()
      .default(null)
      .typeError('Delivered date is invalid.')
      .max(
        new Date(),
        I18n.get('The delivered date cannot be set in the future.')
      )
  })
}

import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { I18n } from 'aws-amplify'

import Header from './Header'
import FormConfig from './FormConfig'
import FormSpec from './FormSpec'
import DeleteConfigModal from './DeleteConfigModal'
import {
  Loader,
  Flex,
  Text,
  Grid,
  Item,
  Card,
  FlexV2,
  Button,
  Slot
} from '@/primitives'
import { LineSeparator } from '@/elements'

import {
  cleanConfigs,
  fetchControlDevicesConfig,
  sendDeleteControlDeviceConfig
} from '@/slices/control/controlDeviceConfig'

import {
  getControlDeviceConfigs,
  getControlDeviceLoading
} from '@/reducers/selectors'

import Strings from '../../Strings'

export default function Container({ selectedZone }) {
  const dispatch = useDispatch()

  const configs = getControlDeviceConfigs()
  const loading = getControlDeviceLoading()

  const [showConfigForm, setShowConfigForm] = useState(false)
  const [showSpecForm, setShowSpecForm] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [coreDeviceId, setCoreDeviceId] = useState(null)
  const [coreDeviceSet, setCoreDeviceSet] = useState(false)
  const [controlDeviceConfig, setControlDeviceConfig] = useState(null)

  const strings = Strings()

  useEffect(() => {
    return () => {
      dispatch(cleanConfigs())
    }
  }, [])

  useEffect(() => {
    if (selectedZone?.greengrassCoreDevice) {
      setCoreDeviceId(
        selectedZone.greengrassCoreDevice?.value?.coreDeviceThingName
      )
    }
  }, [selectedZone?.greengrassCoreDevice, dispatch])

  useEffect(() => {
    if (coreDeviceId) {
      dispatch(fetchControlDevicesConfig({ coreDeviceId }))
      setCoreDeviceSet(true)
    }
  }, [coreDeviceId, dispatch])

  useEffect(() => {
    if (!showConfigForm) {
      setControlDeviceConfig(null)
    }
  }, [showConfigForm])

  useEffect(() => {
    if (!showSpecForm) {
      setControlDeviceConfig(null)
    }
  }, [showSpecForm])

  useEffect(() => {
    if (!showDeleteModal) {
      setControlDeviceConfig(null)
    }
  }, [showDeleteModal])

  function handleEditConfig(config) {
    setControlDeviceConfig(config)
    setShowConfigForm(true)
  }

  function handleEditSpec(config) {
    setControlDeviceConfig(config)
    setShowSpecForm(true)
  }

  function handleDeleteConfig(config) {
    setControlDeviceConfig(config)
    setShowDeleteModal(true)
  }

  function onAcceptDeleteConfig(config) {
    dispatch(sendDeleteControlDeviceConfig(config))
    setShowDeleteModal(false)
  }

  function onCancelDeleteConfig() {
    setShowDeleteModal(false)
  }

  return (
    <Loader isLoading={loading}>
      <Header
        selectedZone={selectedZone}
        setShowConfigForm={setShowConfigForm}
      />
      <LineSeparator />
      {!coreDeviceId && coreDeviceSet && (
        <Flex alignMainAxis='center' style={{ margin: '0.5em 0' }}>
          <Text as='p'>{`${strings.noGreengrassDevice}`}</Text>
        </Flex>
      )}

      {coreDeviceSet && coreDeviceId && !loading && configs.length === 0 && (
        <Flex alignMainAxis='center' style={{ margin: '0.5em 0' }}>
          <Text as='p'>{`${strings.noControlDevicesConfig}`}</Text>
        </Flex>
      )}

      {coreDeviceId && !loading && configs.length > 0 && (
        <Grid
          gap={400}
          style={{
            gridTemplateColumns: 'repeat(auto-fill, minmax(360px, 1fr))'
          }}
        >
          {configs.map((config, index) => (
            <Item key={config.controlDeviceId}>
              <Card>
                <Slot name='body'>
                  <FlexV2 direction='column' axisGap={200}>
                    <FlexV2 axisGap={300} direction='column'>
                      <FlexV2
                        axisGap={300}
                        direction='row'
                        alignMainAxis='space-between'
                      >
                        <Text
                          fontWeight={700}
                          size={300}
                          tone={900}
                          variant='page'
                        >
                          {`${I18n.get('Configuration')} ${index + 1}`}
                        </Text>
                        <FlexV2 axisGap={300}>
                          <Button
                            variant='primary'
                            iconBefore='edit'
                            size='small'
                            onClick={() => handleEditConfig(config)}
                          />
                          <Button
                            variant='error'
                            iconBefore='delete'
                            size='small'
                            onClick={() => handleDeleteConfig(config)}
                          />
                        </FlexV2>
                      </FlexV2>
                      <LineSeparator />
                      <FlexV2 direction='row'>
                        <FlexV2 direction='column'>
                          <Text size={100} variant='page' tone={700}>
                            {I18n.get('Unique ID')}
                          </Text>
                          <Text size={200} variant='page' tone={900}>
                            {config.controlDeviceId}
                          </Text>
                        </FlexV2>
                      </FlexV2>
                      <FlexV2 direction='row' alignMainAxis='space-between'>
                        <FlexV2 direction='column'>
                          <Text size={100} variant='page' tone={700}>
                            {I18n.get('Address')}
                          </Text>
                          <Text size={200} variant='page' tone={900}>
                            {config.address}
                          </Text>
                        </FlexV2>
                        <FlexV2 direction='column'>
                          <Text size={100} variant='page' tone={700}>
                            {I18n.get('Relays')}
                          </Text>
                          <Text size={200} variant='page' tone={900}>
                            {config?.relayConfiguration?.length}
                          </Text>
                        </FlexV2>
                      </FlexV2>
                      <FlexV2
                        axisGap={300}
                        style={{ marginTop: '1em' }}
                        direction='column'
                      >
                        <Button
                          variant='info'
                          size='small'
                          onClick={() => handleEditSpec(config)}
                        >
                          {strings.viewControlSpec}
                        </Button>
                      </FlexV2>
                    </FlexV2>
                  </FlexV2>
                </Slot>
              </Card>
            </Item>
          ))}
        </Grid>
      )}
      <FormConfig
        coreDeviceId={coreDeviceId}
        controlDeviceConfig={controlDeviceConfig}
        showForm={showConfigForm}
        setShowForm={setShowConfigForm}
      />
      <FormSpec
        coreDeviceId={coreDeviceId}
        controlDeviceConfig={controlDeviceConfig}
        showForm={showSpecForm}
        setShowForm={setShowSpecForm}
      />
      <DeleteConfigModal
        showModal={showDeleteModal}
        controlDeviceConfig={controlDeviceConfig}
        onAcceptDelete={onAcceptDeleteConfig}
        onCancelDelete={onCancelDeleteConfig}
      />
    </Loader>
  )
}

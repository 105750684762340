import { makeOperationsApiCall } from './utils'

export const getInventoryCalibrations = async params => {
  try {
    const query = /* GraphQL */ `
      query getInventoryCalibrations(
        $filter: GetInventoryCalibrationsFilter
        $order: OrderEnum
        $offset: Int
        $limit: Int
      ) {
        getInventoryCalibrations(
          filter: $filter
          order: $order
          offset: $offset
          limit: $limit
        ) {
          inventoryCalibrations {
            id
            organizationId
            inventory {
              id
              storageFacility {
                id
                name
              }
              produce {
                id
                name
              }
              variety {
                id
                name
              }
              grade {
                id
                name
              }
            }
            oldWeight
            newWeight
            reason
            createdAt
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getInventoryCalibrations',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const createInventoryCalibration = async ({
  cognitoUser,
  ...params
}) => {
  try {
    const query = /* GraphQL */ `
      mutation createInventoryCalibration(
        $organizationId: String!
        $inventoryId: String!
        $newWeight: Float!
        $reason: String!
      ) {
        createInventoryCalibration(
          organizationId: $organizationId
          inventoryId: $inventoryId
          newWeight: $newWeight
          reason: $reason
        ) {
          id
          organizationId
          inventory {
            id
            storageFacility {
              id
              name
            }
            produce {
              id
              name
            }
            variety {
              id
              name
            }
            grade {
              id
              name
            }
          }
          oldWeight
          newWeight
          reason
          createdAt
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'createInventoryCalibration',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

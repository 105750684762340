import { I18n } from 'aws-amplify'
import { TRANSLATION_LANGUAGES_MAP } from './config'
import {
  Button,
  Column,
  Row,
  Slot,
  Table,
  Text,
  FlexV2,
  Loader
} from '@/primitives/index'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { sendUpdateOrganizationLabelTranslations } from '@/slices/management/organizationLabels'
import { getOrganizationLabelsLoading } from '@/reducers/selectors'

export default function Translations({ translations }) {
  const params = useParams()
  const dispatch = useDispatch()
  const isLoading = getOrganizationLabelsLoading()

  function onRedoTranslations() {
    dispatch(
      sendUpdateOrganizationLabelTranslations({
        organizationId: params.organizationId,
        labelId: params.labelId
      })
    )
  }

  let hasPlural = translations.some(({ textPlural }) => textPlural)

  return (
    <FlexV2 direction='column' axisGap={300}>
      <Text variant='page' tone={900} size={100}>
        {I18n.get('Translations')}
      </Text>
      <Loader isLoading={isLoading}>
        <Table>
          <Slot name='head'>
            <Row>
              <Column>{I18n.get('Language')}</Column>
              <Column>{I18n.get('Singular')}</Column>
              {hasPlural && <Column>{I18n.get('Plural')}</Column>}
            </Row>
          </Slot>
          <Slot name='body'>
            {translations.map(({ lang, text, textPlural }) => {
              return (
                <Row key={lang}>
                  <Column>{TRANSLATION_LANGUAGES_MAP[lang]}</Column>
                  <Column>{text}</Column>
                  {hasPlural && <Column>{textPlural}</Column>}
                </Row>
              )
            })}
          </Slot>
        </Table>
      </Loader>
      <Button
        onClick={onRedoTranslations}
        disabled={isLoading}
        variant='primary'
        size='small'
      >
        {I18n.get('Redo Translations')}
      </Button>
    </FlexV2>
  )
}

import { makeOperationsApiCall } from './utils'

/* MUTATIONS */

export const createProduceThresholdMessage = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createProduceThresholdMessage(
        $name: String!
        $priority: ProduceThresholdMessagePriority!
        $config: GraphQLJSON!
        $message: String!
      ) {
        createProduceThresholdMessage(
          name: $name
          priority: $priority
          config: $config
          message: $message
        ) {
          id
          name
          priority
          config
          message
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'createProduceThresholdMessage',
      resultKey: 'data'
    })
    return { produceThresholdMessage: data ?? {}, error: error || null }
  } catch (err) {
    return { produceThresholdMessage: {}, error: err.message }
  }
}

export const updateProduceThresholdMessage = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateProduceThresholdMessage(
        $produceThresholdMessageId: String!
        $name: String!
        $priority: ProduceThresholdMessagePriority!
        $config: GraphQLJSON!
        $message: String!
      ) {
        updateProduceThresholdMessage(
          produceThresholdMessageId: $produceThresholdMessageId
          name: $name
          priority: $priority
          config: $config
          message: $message
        ) {
          id
          name
          priority
          config
          message
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'updateProduceThresholdMessage',
      resultKey: 'data'
    })
    return { produceThresholdMessage: data ?? {}, error: error || null }
  } catch (err) {
    return { produceThresholdMessage: {}, error: err.message }
  }
}

/* QUERIES */

export const getProduceThresholdMessages = async params => {
  try {
    const query = /* GraphQL */ `
      query getProduceThresholdMessages(
        $limit: Int
        $offset: Int
        $order: OrderEnum
      ) {
        getProduceThresholdMessages(
          limit: $limit
          offset: $offset
          order: $order
        ) {
          produceThresholdMessages {
            id
            name
            priority
            config
            message
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getProduceThresholdMessages',
      resultKey: 'data'
    })
    return {
      produceThresholdMessages: data?.produceThresholdMessages ?? [],
      error: error || null
    }
  } catch (err) {
    return { produceThresholdMessages: [], error: err.message }
  }
}

export const deleteProduceThresholdMessage = async params => {
  try {
    const query = /* GraphQL */ `
      mutation deleteProduceThresholdMessage(
        $produceThresholdMessageId: String!
      ) {
        deleteProduceThresholdMessage(
          produceThresholdMessageId: $produceThresholdMessageId
        ) {
          id
          name
          priority
          config
          message
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'deleteProduceThresholdMessage',
      resultKey: 'data'
    })

    return { produceThresholdMessage: data ?? {}, error: error ?? null }
  } catch (err) {
    return { produceThresholdMessage: {}, error: err.message }
  }
}

export const getProduceThresholdMessage = async params => {
  try {
    const query = /* GraphQL */ `
      query getProduceThresholdMessage($produceThresholdMessageId: String!) {
        getProduceThresholdMessage(
          produceThresholdMessageId: $produceThresholdMessageId
        ) {
          id
          name
          priority
          config
          message
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getProduceThresholdMessage',
      resultKey: 'data'
    })
    return { produceThresholdMessage: data ?? {}, error: error || null }
  } catch (err) {
    return { produceThresholdMessage: {}, error: err.message }
  }
}

import { useEffect, useReducer, useState } from 'react'
import { I18n } from 'aws-amplify'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Text, Input, Slot, FlexV2, Button, Symbol, Loader } from '@/primitives'
import { Dialog, Select } from '@/elements'
import history from '@/history'
import { getValidationErrorMap } from '@/Util/GeneralUtils'
import {
  fetchOrganizationLabel,
  sendCreateOrganizationLabel,
  sendUpdateOrganizationLabel,
  clearOrganizationLabel
} from '@/slices/management/organizationLabels'
import {
  getLang,
  getOrganizationLabel,
  getOrganizationLabelsLoading
} from '@/reducers/selectors'

import { TRANSLATION_LANGUAGES, labelSchema } from './config'
import Translations from './Translations'
import './UpsertLabelForm.scss'
import { OrganizationLabelStrings } from '@/Util/OrganizationLabels'

const initialState = {
  organizationId: null,
  labelId: null,
  label: '',
  replacementLabel: '',
  replacementLabelPlural: '',
  replacementLabelLang: '',
  translations: []
}

function reducer(state, action) {
  const handlers = {
    'update-field': () => ({
      ...state,
      [action.name]: action.value
    }),
    'set-state': () => action.state
  }
  if (!handlers[action.type]) {
    throw new Error(`Unknown action type: ${action.type}`)
  }
  return handlers[action.type]()
}

const getConfigurableLabelOptions = () => {
  const organizationLabelStrings = OrganizationLabelStrings()
  return Object.keys(organizationLabelStrings).map(label => ({
    label: organizationLabelStrings[label].text,
    value: label
  }))
}

export default function UpsertLabelFrom() {
  const params = useParams()
  const dispatch = useDispatch()

  const lang = getLang()
  const organizationLabel = getOrganizationLabel()
  const organizationLabelLoading = getOrganizationLabelsLoading()

  const [state, stateDispatch] = useReducer(reducer, initialState)
  const [error, setError] = useState({})

  const configurableLabelOptions = getConfigurableLabelOptions()

  useEffect(() => {
    return () => {
      dispatch(clearOrganizationLabel({}))
    }
  }, [])

  useEffect(() => {
    // request the label if it isn't already in state (in case of refresh or direct link)
    if (
      (!organizationLabel.labelId && params.labelId) ||
      organizationLabel.labelId !== params.labelId
    ) {
      dispatch(
        fetchOrganizationLabel({
          labelId: params.labelId,
          organizationId: params.organizationId
        })
      )
    }
  }, [dispatch, organizationLabel, params])

  useEffect(() => {
    // set the state to the label if it is in state
    if (organizationLabel.labelId) {
      stateDispatch({ type: 'set-state', state: organizationLabel })
    }
  }, [organizationLabel])

  useEffect(() => {
    // by default, set the replacement label language to the current language
    if (!params.labelId) {
      stateDispatch({
        type: 'update-field',
        name: 'replacementLabelLang',
        value: lang
      })
    }
  }, [lang, params.labelId])

  function onClose() {
    history.push(`/admin/organizations/${params.organizationId}/labels`)
  }

  function onChange(e) {
    const { name, value } = e.target
    stateDispatch({ type: 'update-field', name, value })
    setError({ ...error, [name]: '' })
  }

  function onSelect(selected, meta) {
    const { name } = meta
    stateDispatch({ type: 'update-field', name, value: selected.value })
    setError({ ...error, [name]: '' })
  }

  async function onSubmit() {
    let payload = {
      organizationId: params.organizationId,
      labelId: params.labelId,
      label: state.label,
      replacementLabel: state.replacementLabel,
      replacementLabelPlural: state.replacementLabelPlural,
      replacementLabelLang: state.replacementLabelLang
    }

    try {
      setError({})
      let schemaType = params.labelId ? 'update' : 'create'

      await labelSchema(schemaType).validate(payload, {
        abortEarly: false
      })
      if (!params.labelId) {
        dispatch(sendCreateOrganizationLabel(payload))
      } else {
        dispatch(sendUpdateOrganizationLabel(payload))
      }
      onClose()
    } catch (error) {
      setError(getValidationErrorMap(error))
    }
  }

  return (
    <Dialog
      type='offcanvas'
      open={true}
      onOpenChange={onClose}
      className='UpsertLabelForm'
    >
      <Slot name='title'>
        {params.labelId ? I18n.get('Edit Label') : I18n.get('Add Label')}
      </Slot>
      <Slot name='content'>
        <Loader isLoading={organizationLabelLoading}>
          <FlexV2 direction='column'>
            <Text variant='page' tone={900} size={100}>
              {I18n.get('Label')}
            </Text>
            <Select
              name='label'
              options={configurableLabelOptions}
              onChange={onSelect}
              value={state.label}
              placeholder={I18n.get('Select a label to replace')}
            />
            <Text
              variant='error'
              size={100}
              tone={500}
              style={{ height: '1.5rem' }}
              as='p'
            >
              {error.label || ''}
            </Text>
          </FlexV2>
          <FlexV2 direction='column'>
            <Text variant='page' tone={900} size={100}>
              {I18n.get('Replacement Label Language')}
            </Text>
            <Select
              name='replacementLabelLang'
              options={TRANSLATION_LANGUAGES}
              onChange={onSelect}
              value={state.replacementLabelLang}
            />
            <Text
              variant='error'
              size={100}
              tone={500}
              style={{ height: '1.5rem' }}
              as='p'
            >
              {error.replacementLabelLang || ''}
            </Text>
          </FlexV2>
          <FlexV2 direction='column'>
            <Text variant='page' tone={900} size={100}>
              {I18n.get('Replacement Label')}
            </Text>
            <Input
              name='replacementLabel'
              value={state.replacementLabel}
              onChange={onChange}
              placeholder={I18n.get('City, Section, Row, Seat, etc.')}
            />
            <Text
              variant='error'
              size={100}
              tone={500}
              style={{ height: '1.5rem' }}
              as='p'
            >
              {error.replacementLabel || ''}
            </Text>
          </FlexV2>

          <FlexV2 direction='column'>
            <Text variant='page' tone={900} size={100}>
              {I18n.get('Replacement Label Plural')}
            </Text>
            <Input
              name='replacementLabelPlural'
              value={state.replacementLabelPlural}
              onChange={onChange}
              placeholder={I18n.get('Cities, Sections, Rows, Seats, etc.')}
            />
            <Text
              variant='error'
              size={100}
              tone={500}
              style={{ height: '1.5rem' }}
              as='p'
            >
              {error.replacementLabelPlural || ''}
            </Text>
          </FlexV2>

          {state.translations.length > 0 && (
            <Translations translations={state.translations} />
          )}
          {!state.labelId && state.translations.length === 0 && (
            <FlexV2
              axisGap={300}
              alignCrossAxis='center'
              style={{ paddingTop: '0.5em', marginBottom: '1em' }}
            >
              <Symbol
                name='info'
                style={{ overflow: 'unset' }}
                variant='page'
                tone={900}
              />
              <Text variant='page' tone={900} size={100}>
                {I18n.get(
                  'After creating the label, we will automatically translate it into the other languages.'
                )}
              </Text>
            </FlexV2>
          )}
        </Loader>
      </Slot>
      <Slot name='actions'>
        <FlexV2 direction='row' alignMainAxis='space-between'>
          <Button onClick={onClose}>{I18n.get('Cancel')}</Button>
          <Button onClick={onSubmit} variant='primary'>
            {state.labelId
              ? I18n.get('Update Label')
              : I18n.get('Create Label')}
          </Button>
        </FlexV2>
      </Slot>
    </Dialog>
  )
}

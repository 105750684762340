import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { I18n } from 'aws-amplify'

import { showBanner } from '@/slices/util'
import {
  getControlDevicesConfig,
  configureControlDeviceConfig,
  updateControlDeviceConfig,
  deleteControlDeviceConfig
} from '@/api/control/deviceConfig'

import {
  configureControlDeviceSpec,
  updateControlDeviceSpec
} from '@/api/control/deviceSpec'

export const fetchControlDevicesConfig = createAsyncThunk(
  'fetchControlDevicesConfig',
  async (params, { dispatch }) => {
    try {
      return await getControlDevicesConfig(params)
    } catch (err) {
      dispatch(
        showBanner({
          show: true,
          type: 'error',
          message: err
        })
      )
    }

    throw Error(I18n.get('Error fetching control device config'))
  }
)

export const sendConfigureControlDeviceConfig = createAsyncThunk(
  'sendConfigureControlDeviceConfig',
  async (params, { dispatch }) => {
    try {
      const {
        coreDeviceId,
        address,
        slaveAddress,
        baudrate,
        relayConfiguration
      } = params

      const payload = {
        coreDeviceId,
        inputs: [
          {
            address,
            slaveAddress: parseInt(slaveAddress),
            baudrate: parseInt(baudrate),
            relayConfiguration: relayConfiguration.map(relay => ({
              relayId: relay.relayId,
              registerNumber: parseInt(relay.registerNumber)
            }))
          }
        ]
      }

      const configs = await configureControlDeviceConfig(payload)

      dispatch(
        showBanner({
          show: true,
          type: 'success',
          message: I18n.get('Control device config saved successfully')
        })
      )

      return configs
    } catch (err) {
      dispatch(
        showBanner({
          show: true,
          type: 'error',
          message: err
        })
      )

      throw err
    }
  }
)

export const sendUpdateControlDeviceConfig = createAsyncThunk(
  'sendUpdateControlDeviceConfig',
  async (params, { dispatch }) => {
    try {
      const {
        coreDeviceId,
        controlDeviceId,
        address,
        slaveAddress,
        baudrate,
        relayConfiguration
      } = params

      const payload = {
        coreDeviceId,
        inputs: [
          {
            controlDeviceId,
            address,
            slaveAddress: parseInt(slaveAddress),
            baudrate: parseInt(baudrate),
            relayConfiguration: relayConfiguration.map(relay => ({
              relayId: relay.relayId,
              registerNumber: parseInt(relay.registerNumber)
            }))
          }
        ]
      }

      const configs = await updateControlDeviceConfig(payload)

      dispatch(
        showBanner({
          show: true,
          type: 'success',
          message: I18n.get('Control device config updated successfully')
        })
      )

      return configs
    } catch (err) {
      dispatch(
        showBanner({
          show: true,
          type: 'error',
          message: err
        })
      )

      throw err
    }
  }
)

export const sendDeleteControlDeviceConfig = createAsyncThunk(
  'sendDeleteControlDeviceConfig',
  async (params, { dispatch }) => {
    try {
      const configs = await deleteControlDeviceConfig(params)

      dispatch(
        showBanner({
          show: true,
          type: 'success',
          message: I18n.get('Control device config deleted successfully')
        })
      )

      return configs
    } catch (err) {
      dispatch(
        showBanner({
          show: true,
          type: 'error',
          message: err
        })
      )

      throw err
    }
  }
)

export const sendConfigureControlDeviceSpec = createAsyncThunk(
  'sendConfigureControlDeviceSpec',
  async (params, { dispatch }) => {
    try {
      const specs = await configureControlDeviceSpec(params)

      dispatch(
        showBanner({
          show: true,
          type: 'success',
          message: I18n.get('Control device spec saved successfully')
        })
      )

      return specs
    } catch (err) {
      dispatch(
        showBanner({
          show: true,
          type: 'error',
          message: err
        })
      )

      throw err
    }
  }
)

export const sendUpdateControlDeviceSpec = createAsyncThunk(
  'sendUpdateControlDeviceSpec',
  async (params, { dispatch }) => {
    try {
      const specs = await updateControlDeviceSpec(params)

      dispatch(
        showBanner({
          show: true,
          type: 'success',
          message: I18n.get('Control device spec updated successfully')
        })
      )

      return specs
    } catch (err) {
      dispatch(
        showBanner({
          show: true,
          type: 'error',
          message: err
        })
      )

      throw err
    }
  }
)

function sortConfigs(configs) {
  configs.sort((a, b) => a.createdAt - b.createdAt)
  return configs
}

const ccControlDeviceConfigSlice = createSlice({
  name: 'ccControlDeviceConfigReducer',
  initialState: {
    configs: [],
    newConfigs: [],
    error: null,
    loading: false,
    saving: false
  },
  reducers: {
    cleanConfigs: state => {
      state.configs = []
    },
    cleanNewConfigs: state => {
      state.newConfigs = []
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchControlDevicesConfig.pending, (state, action) => {
        state.error = null
        state.loading = true
      })
      .addCase(fetchControlDevicesConfig.rejected, (state, action) => {
        state.error = action?.error
        state.loading = false
      })
      .addCase(fetchControlDevicesConfig.fulfilled, (state, action) => {
        const configs = sortConfigs(action?.payload ?? [])
        state.configs = configs
        state.loading = false
      })
      .addCase(sendConfigureControlDeviceConfig.pending, (state, action) => {
        state.error = null
        state.saving = true
      })
      .addCase(sendConfigureControlDeviceConfig.rejected, (state, action) => {
        state.error = action?.error
        state.saving = false
      })
      .addCase(sendConfigureControlDeviceConfig.fulfilled, (state, action) => {
        const configs = sortConfigs(action?.payload ?? [])
        state.configs = configs
        state.newConfigs = configs
        state.saving = false
      })
      .addCase(sendUpdateControlDeviceConfig.pending, (state, action) => {
        state.error = null
        state.saving = true
      })
      .addCase(sendUpdateControlDeviceConfig.rejected, (state, action) => {
        state.error = action?.error
        state.saving = false
      })
      .addCase(sendUpdateControlDeviceConfig.fulfilled, (state, action) => {
        const configs = sortConfigs(action?.payload ?? [])
        state.configs = configs
        state.newConfigs = configs
        state.saving = false
      })
      .addCase(sendDeleteControlDeviceConfig.pending, (state, action) => {
        state.error = null
        state.saving = true
      })
      .addCase(sendDeleteControlDeviceConfig.rejected, (state, action) => {
        state.error = action?.error
        state.saving = false
      })
      .addCase(sendDeleteControlDeviceConfig.fulfilled, (state, action) => {
        const configs = sortConfigs(action?.payload ?? [])
        state.configs = configs
        state.saving = false
      })
      .addCase(sendConfigureControlDeviceSpec.pending, (state, action) => {
        state.error = null
        state.saving = true
      })
      .addCase(sendConfigureControlDeviceSpec.rejected, (state, action) => {
        state.error = action?.error
        state.saving = false
      })
      .addCase(sendConfigureControlDeviceSpec.fulfilled, (state, action) => {
        let configs = state.configs.map(config => {
          if (config.controlDeviceId === action.payload[0].controlDeviceId) {
            config.specifications = action.payload
          }

          return config
        })

        configs = sortConfigs(configs)

        state.configs = configs
        state.newConfigs = configs
        state.saving = false
      })
      .addCase(sendUpdateControlDeviceSpec.pending, (state, action) => {
        state.error = null
        state.saving = true
      })
      .addCase(sendUpdateControlDeviceSpec.rejected, (state, action) => {
        state.error = action?.error
        state.saving = false
      })
      .addCase(sendUpdateControlDeviceSpec.fulfilled, (state, action) => {
        let configs = state.configs.map(config => {
          if (config.controlDeviceId === action.payload[0].controlDeviceId) {
            config.specifications = action.payload
          }

          return config
        })

        configs = sortConfigs(configs)

        state.configs = configs
        state.newConfigs = configs
        state.saving = false
      })
  }
})

const { actions, reducer } = ccControlDeviceConfigSlice

export const { cleanConfigs, cleanNewConfigs } = actions

export default reducer

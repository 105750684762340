import { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'

import { getOperationsAllCurrencies } from '../../../../../../reducers/selectors'

import {
  Box,
  Button,
  Flex,
  Input,
  Row,
  Cell
} from '../../../../../../primitives'

import InputError from '../../../../../../elements/InputError'

import { FIELDS } from './config'
import Strings from './Strings'

import { REMOVE_PAYMENT, UPDATE_PAYMENT } from '../state'

const initialFormState = {
  id: '',
  dateReceived: null,
  amount: ''
}

function SalePaymentForm({ sale, payment, errors, index, dispatchFormState }) {
  const currencies = getOperationsAllCurrencies()

  const strings = Strings()

  const [formState, setFormState] = useState(initialFormState)
  const [orderDate, setOrderDate] = useState(null)

  const dateReceivedError = errors?.[`payments[${index}].dateReceived`] ?? null
  const amountError = errors?.[`payments[${index}].amount`] ?? null

  useEffect(() => {
    setOrderDate(sale?.orderDate ? new Date(sale?.orderDate) : new Date())
  }, [sale])

  useEffect(() => {
    setFormState({
      ...initialFormState,
      ...payment
    })
  }, [payment])

  const handleDateInput = (value, name) => {
    let newFormState = { ...formState, [name]: new Date(value) }
    dispatchFormState({ type: UPDATE_PAYMENT, payment: newFormState })
  }

  const handleNumericInput = e => {
    const { name, value } = e.currentTarget
    const newValue = value.length > 0 ? Number(value) : ''
    let newFormState = { ...formState, [name]: newValue }
    dispatchFormState({ type: UPDATE_PAYMENT, payment: newFormState })
  }

  const removePayment = () => {
    dispatchFormState({ type: REMOVE_PAYMENT, payment })
  }

  const getFieldValue = (id, items, field = 'name') => {
    const itemIndex = items.findIndex(i => i.id === id)
    if (itemIndex >= 0) {
      return items[itemIndex][field]
    }
    return null
  }

  return (
    <Row className='Operations__Form__Table'>
      <Cell columnName={strings.fieldDateReceived}>
        <Flex axisGap={300} direction='column'>
          <DatePicker
            minDate={orderDate}
            selected={formState.dateReceived}
            className='Operations__Select'
            onChange={dateReceived =>
              handleDateInput(dateReceived, FIELDS.dateReceived)
            }
          />
          <InputError error={dateReceivedError} />
        </Flex>
      </Cell>
      <Cell columnName={strings.fieldAmount}>
        <Flex
          axisGap={300}
          direction='column'
          className='Operations__Input__Container'
        >
          <Input
            className='Operations__Input'
            type='number'
            name={FIELDS.amount}
            value={formState.amount}
            onChange={handleNumericInput}
          />
          <span className='Operations__Input__Suffix'>
            {getFieldValue(sale.currencyId, currencies, 'code')}
          </span>
          <InputError error={amountError} />
        </Flex>
      </Cell>
      <Cell style={{ flex: '0 1 0', flexShrink: 1 }}>
        <Box style={{ marginTop: '0.2em', textAlign: 'right' }}>
          <Button
            variant='error'
            size='small'
            iconBefore='cancel'
            onClick={removePayment}
          />
        </Box>
      </Cell>
    </Row>
  )
}

export default SalePaymentForm

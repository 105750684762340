import { Button, Flex, Slot, Text } from '@/primitives'
import { Dialog } from '@/elements'

import { getDevice } from '@/reducers/selectors'

import Strings from '../../Strings'

const UnenrollModal = ({ showModal, onAccept, onCancel }) => {
  const { removeUnenroll, cancel, unenroll, confirmRemoveUnenroll } = Strings()
  const device = getDevice()

  function onClickAccept(e) {
    e.preventDefault()
    onAccept(device)
  }

  function onClickClose(e) {
    e.preventDefault()
    onCancel()
  }

  return (
    <Dialog open={showModal} onOpenChange={onCancel}>
      <Slot name='title'>
        <Text size={300} fontWeight={700}>
          {removeUnenroll}
        </Text>
      </Slot>
      <Slot name='content'>
        <Text as='p'>{confirmRemoveUnenroll}</Text>
      </Slot>
      <Slot name='actions'>
        <Flex axisGap={400} alignMainAxis={'space-between'}>
          <Button variant='page' size={'small'} onClick={onClickClose}>
            {cancel}
          </Button>
          <Button variant='danger' size={'small'} onClick={onClickAccept}>
            {unenroll}
          </Button>
        </Flex>
      </Slot>
    </Dialog>
  )
}

export default UnenrollModal

import { updateWeight, removeWeight } from '../../../Shared/Weights/utils'

export const UPDATE_INPUT = 'UPDATE_INPUT'
export const SET_STATE = 'SET_STATE'
export const UPDATE_SITE_ID = 'UPDATE_SITE_ID'
export const REMOVE_WEIGHT = 'REMOVE_WEIGHT'
export const UPDATE_WEIGHT = 'UPDATE_WEIGHT'

export const initialState = {
  id: null,
  organizationId: null,
  siteId: null,
  zoneId: null,
  totalWeight: 0,
  weights: [],
  produceId: '',
  varietyId: '',
  harvestStartDate: null,
  harvestEndDate: null,
  currencyId: '',
  targetPrice: '',
  status: ''
}

const fixDate = value => {
  if (value) {
    return new Date(value)
  }
  return null
}

export function reducer(state, action) {
  const handlers = {
    [SET_STATE]: (state, action) => {
      const newState = { ...action.state }
      newState.harvestStartDate = fixDate(newState.harvestStartDate)
      newState.harvestEndDate = fixDate(newState.harvestEndDate)
      return {
        ...newState
      }
    },
    [UPDATE_INPUT]: (state, action) => ({
      ...state,
      [action.name]: action.value
    }),
    [[UPDATE_SITE_ID]]: (state, action) => ({
      ...state,
      siteId: action.siteId,
      zoneId: null
    }),
    [REMOVE_WEIGHT]: (state, action) => removeWeight(state, action),
    [UPDATE_WEIGHT]: (state, action) => updateWeight(state, action)
  }
  return handlers[action.type](state, action) || state
}

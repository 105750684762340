import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import {
  getPlans,
  getPlan,
  createPlan,
  updatePlan,
  getPlanVsHarvest
} from '@/api/operations/plan'

import { REQUEST_PLAN_VS_HARVEST } from '@/actions/operations/harvest'
import { receiveOperationsDashboardData } from '@/actions/operations/dashboard'

import {
  REQUEST_ALL_PLANS,
  REQUEST_PLANS,
  REQUEST_PLAN,
  CREATE_PLAN,
  UPDATE_PLAN,
  receiveAllPlans,
  receivePlans,
  receivePlan,
  planCreated,
  planUpdated
} from '@/actions/operations/plan'

import { isLoading } from '@/slices/util'
import { displayBanner } from '../utils'

import Strings from './Strings'

const strings = Strings()

function* sendCreatePlan(action) {
  const { data, error = null } = yield call(createPlan, action.params)
  yield put(displayBanner(error, strings.planCreated))
  yield put(planCreated(data, error))
}

export function* watchCreatePlan() {
  yield takeLatest(CREATE_PLAN, sendCreatePlan)
}

function* sendUpdatePlan(action) {
  const { data, error = null } = yield call(updatePlan, action.params)
  yield put(displayBanner(error, strings.planUpdated))
  yield put(planUpdated(data, error))
}

export function* watchUpdatePlan() {
  yield takeLatest(UPDATE_PLAN, sendUpdatePlan)
}

function* sendRequestAllPlans(action) {
  const { data, error = null } = yield call(getPlans, action.params)
  const { plans = [], count = 0 } = data
  yield put(receiveAllPlans(plans, count, error))
}

export function* watchRequestAllPlans() {
  yield takeLatest(REQUEST_ALL_PLANS, sendRequestAllPlans)
}

function* sendRequestPlans(action) {
  const { data, error = null } = yield call(getPlans, action.params)
  const { plans = [], count = 0 } = data
  yield put(receivePlans(plans, count, error))
}

export function* watchRequestPlans() {
  yield takeLatest(REQUEST_PLANS, sendRequestPlans)
}

function* sendRequestPlan(action) {
  const { data, error = null } = yield call(getPlan, action.params)
  yield put(receivePlan(data, error))
}

export function* watchRequestPlan() {
  yield takeLatest(REQUEST_PLAN, sendRequestPlan)
}

function* sendRequestPlanVsHarvest(action) {
  yield put(isLoading(true))

  const { data, error } = yield call(getPlanVsHarvest, action.params)

  yield put(receiveOperationsDashboardData(data, action.dashboardParams, error))
  yield put(isLoading(false))
}

export function* watchRequestPlanVsHarvest() {
  yield takeEvery(REQUEST_PLAN_VS_HARVEST, sendRequestPlanVsHarvest)
}

import { I18n } from 'aws-amplify'
import { useEffect, useState, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { object, string } from 'yup'

import {
  createVariety,
  requestVariety,
  updateVariety
} from '@/actions/operations/variety'

import { requestAllProduces } from '@/actions/operations/produce'

import {
  getOperationsAllProduces,
  getOperationsVariety,
  getOperationsLoading,
  getOperationsError
} from '@/reducers/selectors'

import { Flex, Form, Input, Label, Select, Text, Loader } from '@/primitives'

import InputError from '@/elements/InputError'

import OrganizationInput from '../../Shared/Organization/Input'
import HeaderV2 from '@/components/Operations/Shared/HeaderV2'

import { getValidationErrorMap } from '@/Util/GeneralUtils'
import history from '../../../../history'

import { formInitalState, formReducer } from './state'

const SCHEMA = object({
  organizationId: string().required(I18n.get('An organization is required.')),
  produceId: string().required(I18n.get('A Produce must be selected.')),
  name: string().required(I18n.get('Name is required.'))
})

function VarietyForm({ state, dispatchState, modulePath }) {
  const dispatch = useDispatch()
  const { itemId } = useParams()

  const variety = getOperationsVariety()
  const produces = getOperationsAllProduces()
  const loading = getOperationsLoading()
  const error = getOperationsError()

  const [formState, dispatchFormState] = useReducer(
    formReducer,
    formInitalState
  )

  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)

  useEffect(() => {
    dispatch(requestAllProduces())
  }, [dispatch])

  useEffect(() => {
    if (itemId && variety.id !== itemId) {
      dispatch(requestVariety({ varietyId: itemId }))
    }
  }, [itemId])

  useEffect(() => {
    if (variety?.id && variety.id === itemId) {
      dispatchFormState({ type: 'set-state', state: variety })
    }
  }, [variety])

  useEffect(() => {
    if (!itemId && state.organizations.length === 1) {
      dispatchFormState({
        type: 'update',
        name: 'organizationId',
        value: state.organizations[0]
      })
    }
  }, [state.organizations])

  useEffect(() => {
    if (!itemId && state.produces.length === 1) {
      dispatchFormState({
        type: 'update',
        name: 'produceId',
        value: state.produces[0]
      })
    }
  }, [state.produces])

  useEffect(() => {
    if (waiting && !loading && !error) {
      history.replace(modulePath)
    }
  }, [loading])

  const onSubmit = async e => {
    e?.preventDefault()
    try {
      await SCHEMA.validate(formState, { abortEarly: false })
      setWaiting(true)

      if (itemId) {
        formState.varietyId = itemId
        dispatch(updateVariety(formState))
      } else {
        dispatch(createVariety(formState))
      }
    } catch (error) {
      setErrors(getValidationErrorMap(error))
    }
  }

  const handleOrganizationInput = organizationId => {
    dispatchFormState({
      type: 'set-state',
      state: {
        ...formState,
        organizationId,
        produceId: ''
      }
    })
  }

  const onChange = e => {
    const { name, value } = e.currentTarget
    dispatchFormState({ type: 'update', name, value })
  }

  return (
    <Form className='Operations__Form'>
      <HeaderV2
        title={I18n.get('Manage Variety')}
        buttonIcon={'save'}
        buttonText={I18n.get('Save Variety')}
        buttonCallback={onSubmit}
        backPath={modulePath}
      />
      <Loader isLoading={loading}>
        <OrganizationInput
          fieldName={'organizationId'}
          organizationId={formState.organizationId}
          handleInput={handleOrganizationInput}
          errors={errors}
        />
        <Flex axisGap={300} className='Operations__Form__Fields'>
          <Label>
            <Text variant='page' tone={700}>
              {I18n.get('Produce')}
            </Text>
            <Select
              className='Operations__Form__Select'
              name='produceId'
              value={formState.produceId}
              onChange={onChange}
            >
              <option default value=''>
                {I18n.get('Please select')}
              </option>
              {produces.map(({ name, id }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Select>
            <InputError error={errors?.produceId} />
          </Label>
        </Flex>
        <Flex axisGap={300} className='Operations__Form__Fields'>
          <Label>
            <Text variant='page' tone={700}>
              {I18n.get('Name')}
            </Text>
            <Input value={formState.name} name='name' onChange={onChange} />
            <InputError error={errors?.name} />
          </Label>
          <Label>
            <Text variant='page' tone={700}>
              {I18n.get('Code')}
            </Text>
            <Input value={formState.code} name='code' onChange={onChange} />
            <InputError error={errors?.code} />
          </Label>
        </Flex>
      </Loader>
    </Form>
  )
}

export default VarietyForm

import { I18n } from 'aws-amplify'

const Strings = () => ({
  managePackageUnits: I18n.get('Manage Package Units'),
  addPackageUnit: I18n.get('Add Package Unit'),
  noPackageUnits: I18n.get('No package units'),
  unit: I18n.get('Unit'),
  name: I18n.get('Name'),
  category: I18n.get('Category'),
  status: I18n.get('Status'),
  selectDefault: I18n.get('Please Select'),
  grams: I18n.get('g'),
  available: I18n.get('Available'),
  archived: I18n.get('Archived'),
  box: I18n.get('Box'),
  bag: I18n.get('Bag'),
  felin: I18n.get('Felin'),
  clamshell: I18n.get('Clamshell'),
  cup: I18n.get('Cup'),
  tray: I18n.get('Tray'),
  lid: I18n.get('Lid'),
  pack: I18n.get('Pack'),
  carton: I18n.get('Carton'),
  sleeve: I18n.get('Sleeve'),
  sticker: I18n.get('Sticker'),
  allCategories: I18n.get('All Categories'),
  allStatuses: I18n.get('Any Status')
})

export default Strings

import { Fragment } from 'react'

import OrganizationsFilter from '../../Shared/Filters/OrganizationsFilter'
import EntriesFilter from '../../Shared/Filters/EntriesFilter'

import { PACKAGE_UNIT_CATEGORIES, PACKAGE_UNIT_STATUSES } from './utils'

import Strings from './Strings'

const PackageUnitFilter = ({ state, dispatchState }) => {
  const strings = Strings()

  const categories = Object.entries(PACKAGE_UNIT_CATEGORIES(strings))
  const statuses = Object.entries(PACKAGE_UNIT_STATUSES(strings))

  return (
    <Fragment>
      <OrganizationsFilter state={state} dispatchState={dispatchState} />
      <EntriesFilter
        state={state}
        dispatchState={dispatchState}
        stateKey='categories'
        entries={categories}
        defaultValue='all'
        defaultText={strings.allCategories}
      />
      <EntriesFilter
        state={state}
        dispatchState={dispatchState}
        stateKey='statuses'
        entries={statuses}
        defaultValue='available'
        defaultText={strings.allStatuses}
      />
    </Fragment>
  )
}

export default PackageUnitFilter

import { call, put, select, takeLatest } from 'redux-saga/effects'

import {
  createPackageUnit,
  updatePackageUnit,
  getPackageUnits,
  getPackageUnit
} from '@/api/operations/packageUnit'

import {
  REQUEST_ALL_PACKAGE_UNITS,
  REQUEST_PACKAGE_UNITS,
  REQUEST_PACKAGE_UNIT,
  CREATE_PACKAGE_UNIT,
  UPDATE_PACKAGE_UNIT,
  receiveAllPackageUnits,
  receivePackageUnits,
  receivePackageUnit,
  packageUnitCreated,
  packageUnitUpdated
} from '@/actions/operations/packageUnit'

import { getCurrentUserOrganizations, displayBanner } from '../utils'
import { getOperationsAllPackageUnits } from './utils'

import Strings from './Strings'

const strings = Strings()

function* sendCreatePackageUnit(action) {
  const { data, error = null } = yield call(createPackageUnit, action.params)
  yield put(displayBanner(error, strings.packageUnitCreated))
  yield put(packageUnitCreated(data, error))
}

export function* watchCreatePackageUnit() {
  yield takeLatest(CREATE_PACKAGE_UNIT, sendCreatePackageUnit)
}

function* sendUpdatePackageUnit(action) {
  const { data, error = null } = yield call(updatePackageUnit, action.params)
  yield put(displayBanner(error, strings.packageUnitUpdated))
  yield put(packageUnitUpdated(data, error))
}

export function* watchUpdatePackageUnit() {
  yield takeLatest(UPDATE_PACKAGE_UNIT, sendUpdatePackageUnit)
}

function* sendRequestPackageUnits(action) {
  const { data, error = null } = yield call(getPackageUnits, action.params)
  const { packageUnits = [], count = 0 } = data
  yield put(receivePackageUnits(packageUnits, count, error))
}

export function* watchRequestPackageUnits() {
  yield takeLatest(REQUEST_PACKAGE_UNITS, sendRequestPackageUnits)
}

function* sendRequestPackageUnit(action) {
  const { data: packageUnit, error = null } = yield call(
    getPackageUnit,
    action.params
  )
  yield put(receivePackageUnit(packageUnit, error))
}

export function* watchRequestPackageUnit() {
  yield takeLatest(REQUEST_PACKAGE_UNIT, sendRequestPackageUnit)
}

function* sendRequestAllPackageUnits(action) {
  const allPackageUnits = yield select(getOperationsAllPackageUnits)
  if (allPackageUnits.length > 0) {
    yield put(
      receiveAllPackageUnits(allPackageUnits, allPackageUnits.length, null)
    )
  } else {
    const organizations = yield select(getCurrentUserOrganizations)
    const params = {
      filter: {
        organizationId: organizations.map(o => o.id)
      }
    }
    const { data, error = null } = yield call(getPackageUnits, params)
    const { packageUnits = [], count = 0 } = data
    yield put(receiveAllPackageUnits(packageUnits, count, error))
  }
}

export function* watchRequestAllPackageUnits() {
  yield takeLatest(REQUEST_ALL_PACKAGE_UNITS, sendRequestAllPackageUnits)
}

import { removeWeight, updateWeight } from '../../../Shared/Weights/utils'

export const SET_STATE = 'SET_STATE'
export const UPDATE_INPUT = 'UPDATE_INPUT'
export const UPDATE_SITE_ID = 'UPDATE_SITE_ID'

export const REMOVE_WEIGHT = 'REMOVE_WEIGHT'
export const UPDATE_WEIGHT = 'UPDATE_WEIGHT'

export const initialState = {
  id: null,
  organizationId: '',
  storageFacilityId: '',
  produceId: '',
  varietyId: '',
  wasteDate: null,
  weights: [],
  totalWeight: '',
  reason: ''
}

const fixDate = value => {
  if (value) {
    return new Date(value)
  }
  return null
}

export function reducer(state, action) {
  const handlers = {
    [SET_STATE]: (state, action) => {
      const newState = { ...action.state }
      newState.wasteDate = fixDate(newState.wasteDate)
      return {
        ...newState
      }
    },
    [UPDATE_INPUT]: (state, action) => ({
      ...state,
      [action.name]: action.value
    }),
    [UPDATE_WEIGHT]: (state, action) => updateWeight(state, action),
    [REMOVE_WEIGHT]: (state, action) => removeWeight(state, action)
  }
  return handlers[action.type](state, action) || state
}

import { Fragment, useEffect, useReducer, useState } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { FlexV2, Loader } from '@/primitives'

import ExtraIcons from './shared/ExtraIcons'
import NoResults from './shared/NoResults'
import Header from './shared/Header'
import Search from './shared/Search'
import Actions from './shared/Actions'

import OverviewTree from './sections/OverviewTree'
import AlertsList from './sections/AlertsList'

import { fetchRootZone } from '@/slices/management/zone'

import {
  getSensorThresholdStatuses,
  getSensorThresholdStatusesLoading,
  getRootZone,
  getRootZoneLoading,
  getZonesHierarchy,
  getZonesHierarchyDevicesLoading,
  getOfflineDevices,
  getOfflineDevicesLoading
} from '@/reducers/selectors'

import { stateReducer, initialState } from './utils'

import './index.scss'

/**
 * Renders the StatusPage and subsequent widgets
 *
 * @returns {React.ReactElement} A React component that displays the Status Page
 */
export default function StatusPage() {
  const dispatch = useDispatch()

  const params = useParams()

  const zonesHierarchy = getZonesHierarchy()
  const zonesHierarchyDevicesLoading = getZonesHierarchyDevicesLoading()

  const rootZone = getRootZone()
  const rootZoneLoading = getRootZoneLoading()

  const statuses = getSensorThresholdStatuses()
  const statusesLoading = getSensorThresholdStatusesLoading()

  const offlineDevices = getOfflineDevices()
  const offlineDevicesLoading = getOfflineDevicesLoading()

  const [state, dispatchState] = useReducer(stateReducer, initialState)

  const [localLoading, setLocalLoading] = useState(true)

  const [treeExpandAll, setTreeExpandAll] = useState(true)
  const [alertsExpandAll, setAlertsExpandAll] = useState(false)

  useEffect(() => {
    dispatchState({
      type: 'SET_HEADER',
      payload: state.alertsList
    })
  }, [state.alertsList, dispatchState])

  useEffect(() => {
    dispatchState({ type: 'CLEAR_SEARCH_VALUE' })

    if (Object.keys(state?.viewHierarchy || {}).length > 1) {
      dispatchState({
        type: 'SET_ALERTS_LIST',
        payload: state.viewHierarchy
      })
    }
  }, [state.viewHierarchy, dispatchState])

  useEffect(() => {
    if (
      !zonesHierarchyDevicesLoading &&
      !statusesLoading &&
      !rootZoneLoading &&
      !offlineDevicesLoading
    ) {
      if (
        zonesHierarchy[rootZone?.id] &&
        statuses &&
        params?.zone &&
        offlineDevices[rootZone?.id]
      ) {
        const zone = params.zone
        const siteOfflineDevices = offlineDevices[rootZone?.id]

        dispatchState({
          type: 'SET_VIEW_HIERARCHY',
          payload: { zonesHierarchy, statuses, zone, siteOfflineDevices }
        })

        setLocalLoading(false)
      }
    }
  }, [
    dispatchState,
    offlineDevices,
    offlineDevicesLoading,
    params?.zone,
    rootZone,
    rootZoneLoading,
    statuses,
    statusesLoading,
    zonesHierarchy,
    zonesHierarchyDevicesLoading
  ])

  useEffect(() => {
    const zoneId = params.zone.split('/').filter(Boolean)[0]
    if(zonesHierarchy[zoneId]){
      if (!rootZoneLoading && rootZone?.id !== zoneId) {
        setLocalLoading(true)
        dispatch(fetchRootZone({ zoneId }))
      }
    }

  }, [rootZone, rootZoneLoading, params?.zone, dispatch, zonesHierarchy])

  function updateSelectedDeviceType(deviceType) {
    dispatchState({ type: 'UPDATE_SELECTED_DEVICE_TYPE', payload: deviceType })
  }

  function updateSelectedStatus(status) {
    dispatchState({ type: 'UPDATE_SELECTED_STATUS', payload: status })
  }

  function filterAlertsBySelectedFilters(alertsList, deviceType, status) {
    let filteredAlertsList = [...alertsList]

    if (deviceType !== null) {
      filteredAlertsList = filteredAlertsList.filter(
        alert => alert.sensorType === deviceType
      )
    }

    if (status !== null) {
      filteredAlertsList = filteredAlertsList.filter(alert => {
        return alert.viewData.status === status || alert.viewData[status]
      })
    }

    return filteredAlertsList
  }

  function filterHierarchyByDeviceType(hierarchy, deviceType, status) {
    let nextHierarhcy = { ...hierarchy }

    if (deviceType !== null) {
      let devices = nextHierarhcy.devices.filter(
        ({ sensorType }) => sensorType === deviceType
      )
      nextHierarhcy.devices = devices
    }

    if (status !== null) {
      nextHierarhcy.devices = nextHierarhcy.devices.filter(({ viewData }) => {
        return viewData.status === status || viewData[status]
      })
    }

    if (Object.keys(nextHierarhcy.children).length > 0) {
      nextHierarhcy.children = Object.values(nextHierarhcy.children).map(
        child => filterHierarchyByDeviceType(child, deviceType, status)
      )
    }

    return nextHierarhcy
  }

  return (
    <Loader
      isLoading={
        zonesHierarchyDevicesLoading || localLoading || statusesLoading
      }
    >
      <ExtraIcons />
      <FlexV2 direction='column' axisGap={400} className='StatusPage'>
        <Switch>
          <Route path={`/zones/${params.zone}/status/:page(alerts)`}>
            <Header
              status={state.header.status}
              alertCount={state.header.alertCount}
            />
            {state.alertsList?.length ? (
              <Fragment>
                <Search state={state} dispatchState={dispatchState} />
                <Actions
                  isAlerts={true}
                  expandAll={alertsExpandAll}
                  setExpandAll={setAlertsExpandAll}
                  selectedDeviceType={state.selectedDeviceType}
                  selectedStatus={state.selectedStatus}
                  updateSelectedDeviceType={updateSelectedDeviceType}
                  updateSelectedStatus={updateSelectedStatus}
                />
                <AlertsList
                  data={filterAlertsBySelectedFilters(
                    state.alertsList,
                    state.selectedDeviceType,
                    state.selectedStatus
                  )}
                  searchValue={state.searchValue}
                  expandAll={alertsExpandAll}
                />
              </Fragment>
            ) : (
              <NoResults />
            )}
          </Route>
          <Route path='*'>
            {state.viewHierarchy?.id ? (
              <Fragment>
                <Header
                  status={state.header.status}
                  alertCount={state.header.alertCount}
                />
                <Search state={state} dispatchState={dispatchState} />
                <Actions
                  isAlerts={false}
                  expandAll={treeExpandAll}
                  setExpandAll={setTreeExpandAll}
                  selectedDeviceType={state.selectedDeviceType}
                  selectedStatus={state.selectedStatus}
                  updateSelectedDeviceType={updateSelectedDeviceType}
                  updateSelectedStatus={updateSelectedStatus}
                />
                <OverviewTree
                  data={filterHierarchyByDeviceType(
                    state.viewHierarchy,
                    state.selectedDeviceType,
                    state.selectedStatus
                  )}
                  searchValue={state.searchValue}
                  expandAll={treeExpandAll}
                />
              </Fragment>
            ) : (
              <NoResults />
            )}
          </Route>
        </Switch>
      </FlexV2>
    </Loader>
  )
}

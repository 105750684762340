import { createContext, useContext } from 'react'

import { AuthService } from './authService'

const AuthContext = createContext()
const authService = new AuthService()

const AuthProvider = props => {
  return <AuthContext.Provider value={authService} {...props} />
}

const useAuth = () => {
  const context = useContext(AuthContext)
  return context
}

export { AuthProvider, useAuth }

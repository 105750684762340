export const UPDATE_INPUT = 'UPDATE_INPUT'
export const SET_STATE = 'SET_STATE'

export const initialState = {
  id: null,
  organizationId: '',
  fertilizerInventoryId: null,
  newTotalWeight: '',
  reason: ''
}

export function reducer(state, action) {
  const handlers = {
    [SET_STATE]: (state, action) => ({
      ...initialState,
      ...action.state
    }),
    [UPDATE_INPUT]: (state, action) => ({
      ...state,
      [action.name]: action.value
    })
  }
  return handlers[action.type](state, action) || state
}

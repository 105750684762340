import React, { Fragment } from 'react'

import { Text, Table, Slot, Row, Cell } from '@/primitives'

import ConditionForm from './Form'

import Strings from '../../Strings'

function ProduceThresholdMessageConfig({
  formState,
  errors,
  dispatchFormState,
  removeCondition
}) {
  const strings = Strings()

  return (
    <Table aria-colcount='3' style={{ width: '100%' }}>
      <Slot name='body'>
        {formState?.config?.length > 0 && (
          <Fragment>
            <Row>
              <Cell>
                <Text variant='page' tone={700}>
                  {strings.fieldMeasurement}
                </Text>
              </Cell>
              <Cell>
                <Text variant='page' tone={700}>
                  {strings.fieldExpression}
                </Text>
              </Cell>
              <Cell>
                <Text variant='page' tone={700}>
                  {strings.fieldBound}
                </Text>
              </Cell>
              <Cell />
            </Row>
          </Fragment>
        )}
        {formState?.config?.map((condition, index) => (
          <ConditionForm
            key={index}
            config={formState.config}
            condition={condition}
            errors={errors}
            index={index}
            dispatchFormState={dispatchFormState}
            removeCondition={removeCondition}
          />
        ))}
      </Slot>
    </Table>
  )
}

export default ProduceThresholdMessageConfig

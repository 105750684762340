import { I18n } from 'aws-amplify'
import { SalinityWidget } from '@/components/DashboardPageV2/Desktop/Widgets/Fertigation/Salinity'
import { TotalDissovledSolids } from '@/components/DashboardPageV2/Desktop/Widgets/Fertigation/TotalDissolvedSolids'
import {
  datasetLineQuery,
  defaultAverageValueQuery,
  defaultLiveValueQuery
} from '../Shared/Dynamic/config/defaults'

export const fertigationTypes = {
  water_temperature: {
    widgetId: 'waterTemperature',
    title: I18n.get('Water Temperature'),
    icon: 'thermostat',
    measurement: 'water_temperature'
  },
  conductivity: {
    widgetId: 'conductivity',
    title: I18n.get('Conductivity'),
    icon: 'electric_bolt',
    options: {
      fixedFactor: 2,
      scaleFactor: 0.001,
      measurement: 'conductivity'
    }
  },
  total_dissolved_solids: {
    widgetId: 'totalDissolvedSolids',
    title: I18n.get('Total Dissolved Solids'),
    icon: 'water_do',
    measurement: 'total_dissolved_solids',
    component: TotalDissovledSolids,
    queryOptionsList: [
      defaultLiveValueQuery,
      defaultAverageValueQuery,
      datasetLineQuery
    ]
  },
  ph: {
    widgetId: 'ph',
    title: I18n.get('pH'),
    icon: 'science',
    options: {
      fixedFactor: 2,
      scaleFactor: 1,
      measurement: 'ph'
    }
  },
  dissolved_oxygen: {
    widgetId: 'dissolvedOxygen',
    title: I18n.get('Dissolved Oxygen'),
    icon: 'bubble_chart',
    measurement: 'dissolved_oxygen'
  },
  dissolved_oxygen_percentage: {
    widgetId: 'dissolvedOxygenPercentage',
    title: I18n.get('Dissolved Oxygen Percentage'),
    icon: 'percent',
    measurement: 'dissolved_oxygen_percentage'
  },
  salinity: {
    widgetId: 'salinity',
    title: I18n.get('Salinity'),
    icon: 'opacity',
    measurement: 'salinity',
    component: SalinityWidget,
    queryOptionsList: [
      defaultLiveValueQuery,
      defaultAverageValueQuery,
      datasetLineQuery
    ]
  },
  liquidflow: {
    widgetId: 'liquidflow',
    title: I18n.get('Liquid Flow'),
    icon: 'water',
    measurement: 'liquidflow'
  },
  soil_moisture: {
    widgetId: 'soilMoisture',
    title: I18n.get('Soil Moisture'),
    icon: 'water',
    measurement: 'soil_moisture'
  },
  soil_temperature: {
    widgetId: 'soilTemperature',
    title: I18n.get('Soil Temperature'),
    icon: 'thermostat',
    measurement: 'soil_temperature'
  },
  soil_ph: {
    widgetId: 'soilPh',
    title: I18n.get('Soil pH'),
    icon: 'science',
    measurement: 'soil_ph'
  },
  soil_ec: {
    widgetId: 'soilEc',
    title: I18n.get('Soil Conductivity'),
    icon: 'electric_bolt',
    measurement: 'soil_ec'
  },
  soil_phosphorus: {
    widgetId: 'soilPhosphorus',
    title: I18n.get('Soil Phosphorus Content'),
    icon: 'biotech',
    measurement: 'soil_phosphorus'
  },
  soil_potassium: {
    widgetId: 'soilPotassium',
    title: I18n.get('Soil Potassium Content'),
    icon: 'biotech',
    measurement: 'soil_potassium'
  },
  soil_nitrogen: {
    widgetId: 'soilNitrogen',
    title: I18n.get('Soil Nitrogen Content'),
    icon: 'biotech',
    measurement: 'soil_nitrogen'
  }
}

import { nanoid } from 'nanoid'
import { useReducer, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import DatePicker from 'react-datepicker'

import useDeviceSize from '@/hooks/useDeviceSize'

import {
  createSale,
  requestSale,
  updateSale,
  deleteSaleChildren,
  clearSale
} from '@/actions/operations/sale'

import { requestCustomers } from '@/actions/operations/customer'
import { requestBranches } from '@/actions/operations/branch'
import { requestAllCurrencies } from '@/actions/operations/currency'
import { requestAllPaymentMethods } from '@/actions/operations/paymentMethod'
import { requestAllPaymentTerms } from '@/actions/operations/paymentTerm'

import {
  getCurrentUserOrganizations,
  getOperationsCustomers,
  getOperationsBranches,
  getOperationsAllCurrencies,
  getOperationsAllPaymentMethods,
  getOperationsAllPaymentTerms,
  getOperationsSale,
  getOperationsLoading,
  getOperationsError
} from '@/reducers/selectors'

import {
  Box,
  Button,
  Flex,
  Label,
  Select,
  Text,
  Separator,
  Input,
  Loader,
  Slot
} from '@/primitives'

import { Dialog, InputError } from '@/elements'
import HeaderV2 from '@/components/Operations/Shared/HeaderV2'
import OrganizationInput from '../../../Shared/Organization/Input'

import Payments from './Payments'
import SaleItems from './SaleItem'
import SaleShipments from './SaleShipment'

import { getValidationErrorMap } from '@/Util/GeneralUtils'

import { SALE_STATUS_TYPE, INVOICE_STATUS_TYPE } from '../utils'
import { FIELDS, SCHEMA } from './config'

import history from '../../../../../history'
import Strings from '../Strings'

import {
  initialState,
  reducer,
  UPDATE_INPUT,
  RESET_STATE,
  SET_STATE,
  UPDATE_PAYMENT
} from './state'

import { useAuth } from '@/contexts/auth-context'

function PipelineForm({ state, modulePath }) {
  const dispatch = useDispatch()
  const auth = useAuth()

  const isMobile = useDeviceSize('mobile')
  const isLaptop = useDeviceSize()

  const { itemId } = useParams()
  const strings = Strings()

  const userId = auth.getCognitoId()
  const organizations = getCurrentUserOrganizations()
  const customers = getOperationsCustomers()
  const branches = getOperationsBranches()
  const currencies = getOperationsAllCurrencies()
  const paymentMethods = getOperationsAllPaymentMethods()
  const paymentTerms = getOperationsAllPaymentTerms()
  const sale = getOperationsSale()
  const loading = getOperationsLoading()
  const error = getOperationsError()

  const [formState, dispatchFormState] = useReducer(reducer, {
    ...initialState,
    payments: [],
    saleItems: [],
    saleShipments: []
  })
  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)
  const [dialog, setDialog] = useState({
    show: false,
    callback: null
  })

  const saleStatusTypes = SALE_STATUS_TYPE(strings)
  const invoiceStatusTypes = INVOICE_STATUS_TYPE(strings)

  useEffect(() => {
    dispatchFormState({
      type: RESET_STATE
    })
    return () => {
      dispatch(clearSale())
    }
  }, [])

  useEffect(() => {
    dispatch(requestAllCurrencies())
    dispatch(requestAllPaymentMethods())
    dispatch(requestAllPaymentTerms())
  }, [dispatch])

  useEffect(() => {
    dispatch(
      requestCustomers({
        filter: {
          organizationId: organizations.map(o => o.id)
        }
      })
    )
  }, [organizations])

  useEffect(() => {
    if (itemId && sale.id !== itemId) {
      dispatch(requestSale({ saleId: itemId }))
    }
  }, [itemId])

  useEffect(() => {
    if (sale?.id && sale.id === itemId) {
      dispatchFormState({
        type: SET_STATE,
        state: { ...sale }
      })
    }
  }, [sale])

  useEffect(() => {
    if (!itemId && userId) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'userId',
        value: userId
      })
    }
  }, [userId, itemId])

  useEffect(() => {
    if (!itemId && state.organizations.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'organizationId',
        value: state.organizations[0]
      })
    }
  }, [state.organizations])

  useEffect(() => {
    if (!itemId && state.customers.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'customerId',
        value: state.customers[0]
      })
    }
  }, [state.customers])

  useEffect(() => {
    if (waiting && !loading && !error) {
      history.replace(modulePath)
    }
  }, [loading])

  useEffect(() => {
    if (formState.customerId) {
      dispatch(
        requestBranches({
          filter: { customerId: [formState.customerId] }
        })
      )
    }
  }, [formState.customerId])

  const handleInput = e => {
    const { name, value } = e.currentTarget
    dispatchFormState({ type: UPDATE_INPUT, name, value })
  }

  const handleDateInput = (value, name) => {
    dispatchFormState({ type: UPDATE_INPUT, name, value })
  }

  const handleCustomerInput = e => {
    dispatchFormState({
      type: SET_STATE,
      state: {
        ...formState,
        customerId: e.currentTarget.value,
        branchId: ''
      }
    })
  }

  const deleteAllSaleChildren = () => {
    if (itemId) {
      if (
        formState.saleItems.length > 0 ||
        formState.saleShipments.length > 0
      ) {
        dispatch(
          deleteSaleChildren({
            saleId: itemId
          })
        )
      }
    }
  }

  const updateOrganization = organizationId => {
    deleteAllSaleChildren()
    dispatchFormState({
      type: SET_STATE,
      state: {
        ...formState,
        organizationId,
        customerId: '',
        branchId: '',
        saleItems: [],
        saleShipments: [],
        currencyId: ''
      }
    })
  }

  const handleOrganizationInput = organizationId => {
    if (formState.saleItems.length > 0 || formState.saleShipments.length > 0) {
      setDialog({
        show: true,
        callback: () => {
          updateOrganization(organizationId)
        }
      })
    } else {
      updateOrganization(organizationId)
    }
  }

  const addPayment = () => {
    dispatchFormState({
      type: UPDATE_PAYMENT,
      payment: {
        id: nanoid(),
        dateReceived: null,
        amount: ''
      }
    })
  }

  const getUsdRate = () => {
    const currencyIndex = currencies.findIndex(
      c => c.id === formState.currencyId
    )
    if (currencyIndex >= 0) {
      return currencies[currencyIndex].usdRate
    }
    return null
  }

  const onSubmit = async () => {
    try {
      let payload = { ...formState }
      const orderDate = payload.orderDate ?? new Date()
      await SCHEMA(orderDate).validate(payload, { abortEarly: false })

      const usdRate = getUsdRate()
      payload.payments.forEach((payment, index) => {
        if (!payment?.usdRate) {
          payload.payments[index].usdRate = usdRate
        }
      })

      payload.saleItems = payload.saleItems.map(
        ({ product, ...item }, index) => {
          return { ...item, productId: product.id }
        }
      )

      setWaiting(true)

      if (itemId) {
        payload.saleId = itemId
        dispatch(updateSale(payload))
      } else {
        payload.usdRate = usdRate
        dispatch(createSale(payload))
      }
    } catch (error) {
      setErrors(getValidationErrorMap(error))
    }
  }

  const filterCustomers = () => {
    return customers.filter(c => c.organizationId === formState.organizationId)
  }

  const filterBranches = () => {
    return branches.filter(b => b.customerId === formState.customerId)
  }

  const filterPaymentMethods = () => {
    return paymentMethods.filter(
      p => p.organizationId === formState.organizationId
    )
  }

  const filterPaymentTerms = () => {
    return paymentTerms.filter(
      p => p.organizationId === formState.organizationId
    )
  }

  const filterCurrencies = () => {
    return currencies.filter(c => c.organizationId === formState.organizationId)
  }

  const onDialogClose = () => {
    setDialog({
      show: false,
      callback: null
    })
  }

  const onDialogContinue = () => {
    if (dialog.callback) {
      dialog.callback()
    }
    onDialogClose()
  }

  const getHeader = () => {
    if (itemId) {
      return strings.updateFormHeader
    }
    return strings.createFormHeader
  }

  return (
    <Flex
      as='form'
      axisGap={400}
      direction='column'
      className='Operations__Form'
    >
      <HeaderV2
        title={getHeader()}
        backPath={modulePath}
        buttonIcon={'save'}
        buttonText={strings.saveSale}
        buttonCallback={onSubmit}
      />
      <Loader isLoading={loading}>
        <Flex axisGap={800} direction={isLaptop ? 'column' : null}>
          <Flex
            axisGap={400}
            direction='column'
            className='Operations__Form__Fields'
          >
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.fieldsetSale}
              </Text>
              <OrganizationInput
                fieldName={FIELDS.organizationId}
                organizationId={formState.organizationId}
                handleInput={handleOrganizationInput}
                errors={errors}
              />
              <Flex
                axisGap={300}
                alignMainAxis='flex-start'
                direction={isMobile ? 'column' : 'row'}
                className='Operations__Fields'
              >
                <Label>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.fieldOrderDate}
                    </Text>
                    <DatePicker
                      maxDate={new Date()}
                      selected={formState.orderDate}
                      className='Operations__Select'
                      onChange={orderDate =>
                        handleDateInput(orderDate, FIELDS.orderDate)
                      }
                    />
                    <InputError error={errors?.orderDate} />
                  </Flex>
                </Label>
                <Label>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.status}
                    </Text>
                    <Select
                      className='Operations__Select'
                      name={FIELDS.status}
                      value={formState.status}
                      onChange={handleInput}
                    >
                      <option default value=''>
                        {strings.selectInputDefault}
                      </option>
                      {Object.entries(saleStatusTypes).map(([id, name]) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </Select>
                    <InputError error={errors?.status} />
                  </Flex>
                </Label>
              </Flex>
            </Box>
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.customer}
              </Text>
              <Flex
                axisGap={300}
                alignMainAxis='flex-start'
                direction={isMobile ? 'column' : 'row'}
                className='Operations__Fields'
              >
                <Label>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.customer}
                    </Text>
                    <Select
                      className='Operations__Select'
                      name={FIELDS.customerId}
                      value={formState.customerId}
                      onChange={handleCustomerInput}
                    >
                      <option default value=''>
                        {strings.selectInputDefault}
                      </option>
                      {filterCustomers().map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </Select>
                    <InputError error={errors?.customerId} />
                  </Flex>
                </Label>
                <Label>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.fieldBranch}
                    </Text>
                    <Select
                      className='Operations__Select'
                      name={FIELDS.branchId}
                      value={formState.branchId}
                      onChange={handleInput}
                    >
                      <option default value=''>
                        {strings.selectInputDefault}
                      </option>
                      {filterBranches().map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </Select>
                    <InputError error={errors?.branchId} />
                  </Flex>
                </Label>
                <Label>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.fieldPurchaseOrder}
                    </Text>
                    <Input
                      className='Operations__Input'
                      type='string'
                      name='purchaseOrder'
                      value={formState.purchaseOrder}
                      onChange={handleInput}
                    />
                    <InputError error={errors?.purchaseOrder} />
                  </Flex>
                </Label>
              </Flex>
            </Box>
            <Box className='Invoice'>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.fieldsetInvoice}
              </Text>
              <Flex
                axisGap={300}
                alignMainAxis='flex-start'
                direction={isMobile ? 'column' : 'row'}
                className='Operations__Fields'
              >
                <Label>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.fieldInvoiceStatus}
                    </Text>
                    <Select
                      className='Operations__Select'
                      name={FIELDS.invoiceStatus}
                      value={formState.invoiceStatus}
                      onChange={handleInput}
                    >
                      <option default value=''>
                        {strings.selectInputDefault}
                      </option>
                      {Object.entries(invoiceStatusTypes).map(([id, name]) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </Select>
                    <InputError error={errors?.invoiceStatus} />
                  </Flex>
                </Label>
                <Label>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.fieldInvoiceNumber}
                    </Text>
                    <Input
                      className='Operations__Input'
                      type='string'
                      name={FIELDS.invoiceNumber}
                      value={formState.invoiceNumber}
                      onChange={handleInput}
                    />
                    <InputError error={errors?.invoiceNumber} />
                  </Flex>
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                alignMainAxis='flex-start'
                direction={isMobile ? 'column' : 'row'}
                className='Operations__Fields'
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.fieldCurrency}
                    </Text>
                    <Select
                      className='Operations__Select'
                      name={FIELDS.currencyId}
                      value={formState.currencyId}
                      onChange={handleInput}
                    >
                      <option default value=''>
                        {strings.selectInputDefault}
                      </option>
                      {filterCurrencies().map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </Select>
                    <InputError error={errors?.currencyId} />
                  </Flex>
                </Label>
                <Label style={{ flex: '1 1 0' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.fieldPaymentMethod}
                    </Text>
                    <Select
                      className='Operations__Select'
                      name={FIELDS.paymentMethodId}
                      value={formState.paymentMethodId}
                      onChange={handleInput}
                    >
                      <option default value=''>
                        {strings.selectInputDefault}
                      </option>
                      {filterPaymentMethods().map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </Select>
                    <InputError error={errors?.paymentMethodId} />
                  </Flex>
                </Label>
                <Label style={{ flex: '1 1 0' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.fieldPaymentTerm}
                    </Text>
                    <Select
                      className='Operations__Select'
                      name={FIELDS.paymentTermId}
                      value={formState.paymentTermId}
                      onChange={handleInput}
                    >
                      <option default value=''>
                        {strings.selectInputDefault}
                      </option>
                      {filterPaymentTerms().map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </Select>
                    <InputError error={errors?.paymentTermId} />
                  </Flex>
                </Label>
              </Flex>
            </Box>
            <Separator className='Separator__Line' />
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.fieldsetSaleItems}
              </Text>
              <Flex
                axisGap={300}
                direction='column'
                style={{ marginTop: '1em' }}
              >
                <SaleItems
                  sale={formState}
                  dispatchFormState={dispatchFormState}
                />
                <InputError error={errors.saleItems} />
              </Flex>
            </Box>
            <Separator className='Separator__Line' />
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.fieldsetSaleShipments}
              </Text>
              <Flex
                axisGap={300}
                direction='column'
                style={{ marginTop: '1em' }}
              >
                <SaleShipments
                  sale={formState}
                  dispatchFormState={dispatchFormState}
                />
                <InputError error={errors.saleShipments} />
              </Flex>
            </Box>
            <Separator className='Separator__Line' />
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.fieldsetPayments}
              </Text>
              <Flex
                axisGap={300}
                direction='column'
                style={{ marginTop: '1em' }}
              >
                <Payments
                  sale={formState}
                  errors={errors}
                  dispatchFormState={dispatchFormState}
                />
                <Flex alignMainAxis={'flex-end'}>
                  <Button
                    onClick={addPayment}
                    size='small'
                    name='add'
                    variant='primary'
                  >
                    {strings.buttonAddPayment}
                  </Button>
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Loader>
      <Dialog open={dialog.show} onOpenChange={onDialogClose}>
        <Slot name='title'>
          <Flex direction='column' style={{ marginBottom: '1em' }}>
            <Text as='h5' style={{ marginBottom: '0.5em' }}>
              {strings.areYouSure}
            </Text>
            <Text as='p' size={100}>
              {strings.clickContinue}
            </Text>
          </Flex>
        </Slot>
        <Slot name='content'>
          <Text as='p' style={{ marginBottom: '2em' }}>
            {strings.changeOrganizationWarning}
          </Text>
          <Flex alignMainAxis='flex-end' axisGap='300'>
            <Button variant='error' size='small' onClick={onDialogClose}>
              {strings.cancel}
            </Button>
            <Button variant='info' size='small' onClick={onDialogContinue}>
              {strings.continue}
            </Button>
          </Flex>
        </Slot>
      </Dialog>
    </Flex>
  )
}

export default PipelineForm

import { updateList } from '../util'

import {
  RECEIVE_ALL_AREAS,
  REQUEST_ALL_AREAS,
  RECEIVE_AREAS,
  REQUEST_AREAS,
  CLEAR_AREAS,
  CLEAR_AREA,
  REQUEST_AREA,
  RECEIVE_AREA,
  CREATE_AREA,
  AREA_CREATED,
  UPDATE_AREA,
  AREA_UPDATED,
  SET_AREA
} from '@/actions/operations/area'

export const areaInitialState = {
  allAreas: [],
  areas: [],
  area: {},
  areasCount: 0,
  loading: false,
  error: null
}

const handlers = {
  [SET_AREA]: (state, action) => ({
    ...state,
    area: action.area
  }),
  [CLEAR_AREA]: state => ({
    ...state,
    area: {},
    error: null
  }),
  [CLEAR_AREAS]: state => ({
    ...state,
    areas: [],
    error: null
  }),
  [REQUEST_ALL_AREAS]: state => ({
    ...state,
    error: null,
    loading: true
  }),
  [RECEIVE_ALL_AREAS]: (state, action) => ({
    ...state,
    allAreas: !action.error ? action.areas : [],
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_AREA]: state => ({
    ...state,
    area: {},
    error: null,
    loading: true
  }),
  [RECEIVE_AREA]: (state, action) => ({
    ...state,
    area: !action.error ? action.area : state.area,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_AREAS]: state => ({
    ...state,
    areas: [],
    loading: true,
    error: null
  }),
  [RECEIVE_AREAS]: (state, action) => ({
    ...state,
    areas: !action.error ? action.areas : [],
    areasCount: !action.error ? action.count : 0,
    error: action.error ?? null,
    loading: false
  }),
  [CREATE_AREA]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [AREA_CREATED]: (state, action) => ({
    ...state,
    area: !action.error ? {} : state.area,
    areas: updateList(action.error, state.areas, action.area, true),
    allAreas: updateList(action.error, state.allAreas, action.area, true),
    areasCount: !action.error ? state.areasCount + 1 : state.areasCount,
    error: action.error ?? null,
    loading: false
  }),
  [UPDATE_AREA]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [AREA_UPDATED]: (state, action) => ({
    ...state,
    area: !action.error ? {} : state.area,
    areas: updateList(action.error, state.areas, action.area, false),
    allAreas: updateList(action.error, state.allAreas, action.area, false),
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

import { updateList } from '../util'

import {
  CLEAR_ALL_PLANS,
  REQUEST_ALL_PLANS,
  RECEIVE_ALL_PLANS,
  REQUEST_PLANS,
  RECEIVE_PLANS,
  REQUEST_PLAN,
  RECEIVE_PLAN,
  CREATE_PLAN,
  PLAN_CREATED,
  UPDATE_PLAN,
  PLAN_UPDATED
} from '@/actions/operations/plan'

export const planInitialState = {
  allPlans: [],
  plans: [],
  plansCount: 0,
  plan: {},
  loading: false,
  error: null
}

const handlers = {
  [CLEAR_ALL_PLANS]: state => ({
    ...state,
    allPlans: []
  }),
  [REQUEST_ALL_PLANS]: (state, action) => ({
    ...state,
    allPlans: [],
    error: null,
    loading: true
  }),
  [RECEIVE_ALL_PLANS]: (state, action) => ({
    ...state,
    allPlans: !action.error ? action.plans : [],
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_PLANS]: (state, action) => ({
    ...state,
    plans: [],
    plansCount: 0,
    error: null,
    loading: true
  }),
  [RECEIVE_PLANS]: (state, action) => ({
    ...state,
    plans: !action.error ? action.plans : [],
    plansCount: action?.count ?? 0,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_PLAN]: state => ({
    ...state,
    plan: {},
    error: null,
    loading: true
  }),
  [RECEIVE_PLAN]: (state, action) => ({
    ...state,
    plan: !action.error ? action.plan : state.plan,
    error: action.error ?? null,
    loading: false
  }),
  [CREATE_PLAN]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [PLAN_CREATED]: (state, action) => ({
    ...state,
    plan: !action.error ? {} : state.plan,
    plans: updateList(action.error, state.plans, action.plan, true),
    plansCount: !action.error ? state.plansCount + 1 : state.plansCount,
    error: action.error ?? null,
    loading: false
  }),
  [UPDATE_PLAN]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [PLAN_UPDATED]: (state, action) => ({
    ...state,
    plan: !action.error ? {} : state.plan,
    plans: updateList(action.error, state.plans, action.plan, false),
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

import { I18n } from 'aws-amplify'

import ZoneUtils from '@/Util/ZoneUtils'
import Strings from '../Strings'

const strings = Strings()
const gmtString = I18n.get('GMT')

export const getZoneIdsFromPath = zonePath => {
  if (zonePath?.length > 0) {
    const idsArr = zonePath.split('/')
    idsArr.splice(0, 2)
    return idsArr
  }

  return []
}

export const getOrganizationName = (organizations, organizationId) => {
  for (let organization of organizations) {
    if (organization.id === organizationId) {
      return organization.name
    }
  }
  return null
}

export const getColumnNames = keys => {
  return keys.map(key => {
    if (key.includes('weightGrade')) {
      const grade = key.split('-')[1]
      return `${strings.grade} ${grade}`
    }

    return strings[key] ?? key
  })
}

export const generateZoneIds = (allZoneIds, zoneHierarchy) => {
  allZoneIds.push(zoneHierarchy.id)
  Object.keys(zoneHierarchy?.children).forEach(key => {
    const childZone = zoneHierarchy?.children[key]
    generateZoneIds(allZoneIds, childZone)
  })
}

export const generateDate = value => {
  if (value) {
    const [date, time] = value.toISOString().split('T')
    return `${date} (${time.split('.')[0]} ${gmtString})`
  }
}

export const getChartData = (
  harvestArgs,
  databoardHarvests,
  zonesHierarchy,
  grades
) => {
  const graphKeys = []
  const graphData = []
  const weightsByGrade = []
  let gradeWeights = {}

  databoardHarvests.forEach(harvest => {
    const { zoneId, produce, variety, totalWeight, weights } = harvest
    const produceName = `${produce.name} - ${variety.name}`
    if (!graphKeys.includes(produceName)) graphKeys.push(produceName)

    const zoneDetails = ZoneUtils.getZoneFromHierarchy(zonesHierarchy, zoneId)
    const zonesToUpdate = harvestArgs?.zones?.filter(({ zonePath }) => {
      return zoneDetails.parentPath.includes(zonePath)
    })

    zonesToUpdate?.forEach(zone => {
      if (!gradeWeights[zone.name]) {
        gradeWeights[zone.name] = {}
      }

      if (!gradeWeights[zone.name][produceName]) {
        gradeWeights[zone.name][produceName] = {}
      }

      const index = graphData.findIndex(item => item.zone === zone.name)

      grades.forEach(({ id, name }) => {
        const weight = weights.find(({ gradeId }) => gradeId === id)
        const currentWeightValue =
          gradeWeights[zone.name][produceName][name] ?? 0

        if (weight) {
          const newValue = 0 + currentWeightValue
          gradeWeights[zone.name][produceName][name] = newValue
        }

        const weightValue = weight ? weight?.totalKg : 0
        if (gradeWeights[zone.name][produceName][name]) {
          gradeWeights[zone.name][produceName][name] += weightValue
        } else {
          gradeWeights[zone.name][produceName][name] = weightValue
        }
      })

      if (index >= 0) {
        const currentWeight = graphData[index][produceName] ?? 0
        graphData[index][produceName] = currentWeight + totalWeight
        weightsByGrade[index][produceName] =
          gradeWeights[zone.name][produceName]
      } else {
        graphData.push({
          zone: zone.name,
          [produceName]: totalWeight
        })

        weightsByGrade.push({
          [produceName]: gradeWeights[zone.name][produceName]
        })
      }
    })
  })

  graphKeys.sort().reverse()

  return { graphData, graphKeys, weightsByGrade }
}

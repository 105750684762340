import { I18n } from 'aws-amplify'
import { object, string } from 'yup'
import { useEffect, useState, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  createRegion,
  requestRegion,
  updateRegion
} from '@/actions/operations/region'

import { requestAllCountries } from '@/actions/operations/country'

import {
  getOperationsRegion,
  getOperationsAllCountries,
  getOperationsLoading,
  getOperationsError
} from '@/reducers/selectors'

import { Flex, Form, Input, Label, Select, Text, Loader } from '@/primitives'

import InputError from '@/elements/InputError'
import HeaderV2 from '@/components/Operations/Shared/HeaderV2'

import OrganizationInput from '../../Shared/Organization/Input'

import { getValidationErrorMap } from '@/Util/GeneralUtils'
import history from '../../../../history'

import { formInitalState, formReducer } from './state'

const SCHEMA = object({
  organizationId: string().required(I18n.get('An organization is required.')),
  countryId: string().required(I18n.get('A Country must be selected.')),
  name: string().required(I18n.get('Name is required.'))
})

function RegionForm({ state, dispatchState, modulePath }) {
  const dispatch = useDispatch()
  const { itemId } = useParams()

  const region = getOperationsRegion()
  const countries = getOperationsAllCountries()
  const loading = getOperationsLoading()
  const error = getOperationsError()

  const [formState, dispatchFormState] = useReducer(
    formReducer,
    formInitalState
  )

  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)

  useEffect(() => {
    dispatch(requestAllCountries())
  }, [dispatch])

  useEffect(() => {
    if (itemId && region.id !== itemId) {
      dispatch(requestRegion({ regionId: itemId }))
    }
  }, [dispatch, region?.id, itemId])

  useEffect(() => {
    if (region?.id && region.id === itemId) {
      dispatchFormState({ type: 'set-state', state: region })
    }
  }, [region, itemId])

  useEffect(() => {
    if (!itemId && state.organizations.length === 1) {
      dispatchFormState({
        type: 'update',
        name: 'organizationId',
        value: state.organizations[0]
      })
    }
  }, [state.organizations, itemId])

  useEffect(() => {
    if (!itemId && state.countries.length === 1) {
      dispatchFormState({
        type: 'update',
        name: 'countryId',
        value: state.countries[0]
      })
    }
  }, [state.countries, itemId])

  useEffect(() => {
    if (waiting && !loading && !error) {
      history.replace(modulePath)
    }
  }, [waiting, loading, error, modulePath])

  const onSubmit = async e => {
    e?.preventDefault()
    try {
      const payload = await SCHEMA.validate(formState, { abortEarly: false })
      setWaiting(true)

      if (itemId) {
        payload.regionId = itemId
        dispatch(updateRegion(payload))
      } else {
        dispatch(createRegion(payload))
      }
    } catch (err) {
      setErrors(getValidationErrorMap(err))
    }
  }

  const handleOrganizationInput = organizationId => {
    dispatchFormState({
      type: 'set-state',
      state: {
        ...formState,
        organizationId,
        countryId: ''
      }
    })
  }

  const onChange = e => {
    let { name, value } = e.currentTarget
    dispatchFormState({ type: 'update', name, value })
  }

  const filterCountries = () => {
    return countries.filter(c => c.organizationId === formState.organizationId)
  }

  return (
    <Form className='Operations__Form'>
      <HeaderV2
        title={I18n.get('Manage Region')}
        buttonIcon={'save'}
        buttonText={I18n.get('Save Region')}
        buttonCallback={onSubmit}
        backPath={modulePath}
      />
      <Loader isLoading={loading}>
        <OrganizationInput
          fieldName={'organizationId'}
          organizationId={formState.organizationId}
          handleInput={handleOrganizationInput}
          errors={errors}
        />
        <Flex axisGap={300} className='Operations__Form__Fields'>
          <Label>
            <Text variant='page' tone={700}>
              {I18n.get('Country')}
            </Text>
            <Select
              className='Operations__Form__Select'
              name='countryId'
              value={formState.countryId}
              onChange={onChange}
            >
              <option default value=''>
                {I18n.get('Please select')}
              </option>
              {filterCountries().map(({ name, id }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Select>
            <InputError error={errors?.countryId} />
          </Label>
          <Label>
            <Text variant='page' tone={700}>
              {I18n.get('Name')}
            </Text>
            <Input value={formState.name} name='name' onChange={onChange} />
            <InputError error={errors?.name} />
          </Label>
        </Flex>
      </Loader>
    </Form>
  )
}

export default RegionForm

import { Flex } from '../../../../primitives'

import OrganizationsFilter from '../../Shared/Filters/OrganizationsFilter'
import StorageFacilitiesFilter from '../../Shared/Filters/StorageFacilitiesFilter'

function ShippingMethodFilter({ state, dispatchState }) {
  return (
    <Flex direction='row' axisGap='300' style={{ whiteSpace: 'nowrap' }}>
      <OrganizationsFilter state={state} dispatchState={dispatchState} />
      <StorageFacilitiesFilter state={state} dispatchState={dispatchState} />
    </Flex>
  )
}

export default ShippingMethodFilter

import {
  selectOrganizationLabels,
  OrganizationLabelStrings
} from '@/Util/OrganizationLabels'
import { getAllOrganizationLabels } from '@/reducers/selectors'

/**
 * Get organization label version of strings.
 * @example
 * const labels = useOrgLabel(['site', 'facility'])
 * // labels:
 * // {
 * //   site: {
 * //     text: 'Site',
 * //     textPlural: 'Sites'
 * //   },
 * //   facility: {
 * //     text: 'Facility',
 * //     textPlural: 'Facilities'
 * //   }
 * // }
 *
 * @param {string[]} labels - The labels to get the organization label version of.
 * @returns {Object.<string, {text: string, textPlural: string}>} - An object with each key corresponding to an input label.
 * @throws Will throw an error if a label does not exist in {@link OrganizationLabelStrings}.
 */
export default function useOrgLabel(labels) {
  const organizationLabels = getAllOrganizationLabels()
  const organizationLabelStrings = OrganizationLabelStrings()

  let orgLabels = {}

  for (let label of labels) {
    if (!organizationLabelStrings[label]) {
      throw new Error(
        `Label ${label} does not exist in OrganizationLabelStrings. 
        Make sure the label is in all lowercase and is spelled correctly.
        Check 'src/Util/Strings/OrganizationLabels.js' for a list of valid labels.
        `
      )
    }

    orgLabels[label] = selectOrganizationLabels({
      fallbackLabels: organizationLabelStrings[label],
      label,
      organizationLabels
    })
  }

  return orgLabels
}

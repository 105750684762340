export const PACKAGE_UNIT_STATUSES = strings => {
  return {
    available: strings.available,
    archived: strings.archived
  }
}

export const PACKAGE_UNIT_CATEGORIES = strings => {
  return {
    box: strings.box,
    bag: strings.bag,
    felin: strings.felin,
    clamshell: strings.clamshell,
    cup: strings.cup,
    tray: strings.tray,
    lid: strings.lid,
    pack: strings.pack,
    carton: strings.carton,
    sleeve: strings.sleeve,
    sticker: strings.sticker
  }
}

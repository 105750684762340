import { makeOperationsApiCall } from './utils'

export const getPackageUnits = async params => {
  try {
    const query = /* GraphQL */ `
      query getPackageUnits(
        $filter: GetPackageUnitsFilter
        $order: OrderEnum
        $offset: Int
        $limit: Int
      ) {
        getPackageUnits(
          filter: $filter
          order: $order
          offset: $offset
          limit: $limit
        ) {
          packageUnits {
            id
            organizationId
            category
            status
            name
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getPackageUnits',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getPackageUnit = async params => {
  try {
    const query = /* GraphQL */ `
      query getPackageUnit($packageUnitId: String!) {
        getPackageUnit(packageUnitId: $packageUnitId) {
          id
          organizationId
          category
          status
          name
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getPackageUnit',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const createPackageUnit = async params => {
  try {
    const query = /* GraphQL */ `
      mutation createPackageUnit(
        $organizationId: String!
        $category: PackageUnitCategory!
        $status: PackageUnitStatus!
        $name: String!
      ) {
        createPackageUnit(
          organizationId: $organizationId
          category: $category
          status: $status
          name: $name
        ) {
          id
          organizationId
          category
          status
          name
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'createPackageUnit',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updatePackageUnit = async params => {
  try {
    const query = /* GraphQL */ `
      mutation updatePackageUnit(
        $packageUnitId: String!
        $organizationId: String!
        $category: PackageUnitCategory!
        $status: PackageUnitStatus!
        $name: String!
      ) {
        updatePackageUnit(
          packageUnitId: $packageUnitId
          organizationId: $organizationId
          category: $category
          status: $status
          name: $name
        ) {
          id
          organizationId
          category
          status
          name
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'updatePackageUnit',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

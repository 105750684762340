import { I18n } from 'aws-amplify'

const Strings = () => ({
  errorTitle: I18n.get('An Error Occurred'),
  backBtn: I18n.get('Back'),
  min: I18n.get('Min:'),
  max: I18n.get('Max:'),
  noData: I18n.get('No Data'),
  isAbove: I18n.get('is above'),
  isBelow: I18n.get('is below'),
  missingData: I18n.get('Missing data'),
  notInUse: I18n.get('Not in use')
})

export default Strings


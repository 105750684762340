const routeKeywords = {
  '/': ['locations'],
  '/profile': ['mfa'],
  '/operations': ['operations'],
  '/zones': ['navigating-the-coretex-application'],
  '/zones/:zone+/video-feeds': ['managing'],
  '/zones/:zone+/reports': ['managing'],
  '/zones/:zone+/sensor/:sensorType/:sensorId/calibration/:measurement?': [
    'calibration'
  ],
  '/zones/:zone+/sensor/:sensorType/:sensorId/data': ['working-with-your-data'],
  '/zones/:zone+/data': ['working-with-your-data'],
  '/zones/:zone+/sensor/:sensorType/:sensorId': ['working-with-your-data'],
  '/zones/:zone+': ['working-with-your-data'],
  '/admin': ['managing'],
  '/enroll-device/:deviceId': ['managing-sensors']
}

export const fallbackKeywords = ['navigating-the-coretex-application']

export default routeKeywords

import { FlexV2 } from '@/primitives'

import OrganizationsFilter from '../../../Shared/Filters/OrganizationsFilter'
import CustomersFilter from '../../../Shared/Filters/CustomersFilter'
import ProductsFilter from '../../../Shared/Filters/ProductsFilter'

const UnitPriceFilter = ({ state, dispatchState }) => {
  return (
    <FlexV2 axisGap={400}>
      <OrganizationsFilter state={state} dispatchState={dispatchState} />
      <CustomersFilter state={state} dispatchState={dispatchState} />
      <ProductsFilter state={state} dispatchState={dispatchState} />
    </FlexV2>
  )
}

export default UnitPriceFilter

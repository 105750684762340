import { makeOperationsApiCall } from './utils'

/* MUTATIONS */

export const createProduceThreshold = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createProduceThreshold(
        $name: String!
        $measurements: GraphQLJSON!
      ) {
        createProduceThreshold(name: $name, measurements: $measurements) {
          id
          name
          measurements
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'createProduceThreshold',
      resultKey: 'data'
    })
    return { produceThreshold: data ?? {}, error: error || null }
  } catch (err) {
    return { produceThreshold: {}, error: err.message }
  }
}

export const updateProduceThreshold = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateProduceThreshold(
        $produceThresholdId: String!
        $name: String!
        $measurements: GraphQLJSON!
      ) {
        updateProduceThreshold(
          produceThresholdId: $produceThresholdId
          name: $name
          measurements: $measurements
        ) {
          id
          name
          measurements
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'updateProduceThreshold',
      resultKey: 'data'
    })
    return { produceThreshold: data ?? {}, error: error || null }
  } catch (err) {
    return { produceThreshold: {}, error: err.message }
  }
}

/* QUERIES */

export const getProduceThresholds = async params => {
  try {
    const query = /* GraphQL */ `
      query getProduceThresholds($limit: Int, $offset: Int, $order: OrderEnum) {
        getProduceThresholds(limit: $limit, offset: $offset, order: $order) {
          produceThresholds {
            id
            name
            measurements
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getProduceThresholds',
      resultKey: 'data'
    })
    return {
      produceThresholds: data?.produceThresholds ?? [],
      error: error || null
    }
  } catch (err) {
    return { produceThresholds: [], error: err.message }
  }
}

export const getProduceThreshold = async params => {
  try {
    const query = /* GraphQL */ `
      query getProduceThreshold($produceThresholdId: String!) {
        getProduceThreshold(produceThresholdId: $produceThresholdId) {
          id
          name
          measurements
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getProduceThreshold',
      resultKey: 'data'
    })
    return { produceThreshold: data ?? {}, error: error || null }
  } catch (err) {
    return { produceThreshold: {}, error: err.message }
  }
}

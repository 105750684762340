import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { I18n } from 'aws-amplify'

import { FlexV2, Slot, Text, Toggle, Loader } from '@/primitives'
import { Dialog } from '@/elements'

import {
  getCurrentUser,
  getSettings,
  getSettingsIsFetching,
  getSettingsIsSaving
} from '@/reducers/selectors'

import {
  fetchSettings,
  sendTurnOnOffSetting
} from '@/slices/management/settings'

import { getIsGodMode } from '@/Util/PermissionUtils'

import './index.scss'

export default function Settings({
  objectId,
  type,
  openSidebar,
  onCloseSidebar
}) {
  const dispatch = useDispatch()

  const currentUser = getCurrentUser()
  const isGodMode = getIsGodMode(currentUser)
  const settings = getSettings()

  const isFetching = getSettingsIsFetching()
  const isSaving = getSettingsIsSaving()

  useEffect(() => {
    if (isGodMode) {
      dispatch(fetchSettings({ objectId, type }))
    }
  }, [isGodMode])

  function onToggleSetting(event) {
    const { name } = event.target
    dispatch(sendTurnOnOffSetting({ objectId, type, setting: name }))
  }

  return (
    <Dialog
      open={openSidebar}
      onOpenChange={onCloseSidebar}
      type='offcanvas'
      className='Settings__OffCanvas'
      style={{ zIndex: 3 }}
    >
      <Slot name='title'>
        <Text size={300} fontWeight={700}>
          {I18n.get('Settings')}
        </Text>
      </Slot>
      <Slot name='content'>
        <FlexV2 direction='column' axisGap={100}>
          <Loader isLoading={isFetching}>
            {settings?.map(({ id, name, value }) => {
              return (
                <FlexV2
                  direction='row'
                  alignMainAxis='space-between'
                  axisGap={200}
                  key={id}
                >
                  <Text
                    variant='page'
                    tone={900}
                    size={200}
                    style={{ marginBottom: '0.2em' }}
                  >
                    {name}
                  </Text>
                  <Toggle
                    name={id}
                    onChange={onToggleSetting}
                    checked={value}
                    disabled={isSaving}
                  />
                </FlexV2>
              )
            })}
          </Loader>
        </FlexV2>
      </Slot>
    </Dialog>
  )
}

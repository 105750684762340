import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { makeGraphqlRequestV2 } from '../utils'

// Queries

export const getSiteIngestionFrequencyConfig = async siteId => {
  try {
    const query = /* GraphQL */ `
      query getSiteIngestionFrequencyConfig($siteId: String!) {
        getSiteIngestionFrequencyConfig(siteId: $siteId) {
          siteId
          config {
            deviceType
            frequency
          }
        }
      }
    `

    const operation = graphqlOperation(query, { siteId })
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)
    return data?.getSiteIngestionFrequencyConfig
  } catch (err) {
    throw err
  }
}

// Mutations

export const updateSiteIngestionFrequencyConfig = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateSiteIngestionFrequencyConfig(
        $siteId: String!
        $config: [IngestionFrequencyConfigInput]
      ) {
        updateSiteIngestionFrequencyConfig(siteId: $siteId, config: $config) {
          siteId
          config {
            deviceType
            frequency
          }
        }
      }
    `

    const operation = graphqlOperation(mutation, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)
    return data?.updateSiteIngestionFrequencyConfig
  } catch (err) {
    throw err
  }
}

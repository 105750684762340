import { makeActionCreator } from '@/Util/ReduxUtils'

export const CLEAR_PACKAGE_UNIT_INVENTORIES = 'CLEAR_PACKAGE_UNIT_INVENTORIES'
export const clearPackageUnitInventories = makeActionCreator(
  CLEAR_PACKAGE_UNIT_INVENTORIES
)

export const REQUEST_PACKAGE_UNIT_INVENTORIES =
  'REQUEST_PACKAGE_UNIT_INVENTORIES'
export const requestPackageUnitInventories = makeActionCreator(
  REQUEST_PACKAGE_UNIT_INVENTORIES,
  'params'
)

export const RECEIVE_PACKAGE_UNIT_INVENTORIES =
  'RECEIVE_PACKAGE_UNIT_INVENTORIES'
export const receivePackageUnitInventories = makeActionCreator(
  RECEIVE_PACKAGE_UNIT_INVENTORIES,
  'packageUnitInventories',
  'count',
  'error'
)

export const REQUEST_PACKAGE_UNIT_INVENTORY = 'REQUEST_PACKAGE_UNIT_INVENTORY'
export const requestPackageUnitInventory = makeActionCreator(
  REQUEST_PACKAGE_UNIT_INVENTORY,
  'params'
)

export const RECEIVE_PACKAGE_UNIT_INVENTORY = 'RECEIVE_PACKAGE_UNIT_INVENTORY'
export const receivePackageUnitInventory = makeActionCreator(
  RECEIVE_PACKAGE_UNIT_INVENTORY,
  'packageUnitInventory',
  'error'
)

import { I18n } from 'aws-amplify'

const Strings = () => ({
  weight: I18n.get('Weight'),
  weightKg: I18n.get('Weight (kg)'),
  kg: I18n.get('kg'),
  grade: I18n.get('Grade'),
  selectGrade: I18n.get('Select Grade'),
  noWeights: I18n.get('No weights')
})

export default Strings

import { createContext, useContext, useReducer } from 'react'

export const ArticleSuggestionContext = createContext()

/**
 * Hook to access the ArticleSuggestionContext
 * @returns { { state: { keywords: string[], specificQuery: string }, setKeywords: (keywords: string[]) => void, setSpecificQuery: (query: string) => void } }
 */
export const useArticleSuggestion = () => {
  const context = useContext(ArticleSuggestionContext)

  if (!context) {
    throw new Error(
      'useArticleSuggestion must be used within a ArticleSuggestionContextProvider'
    )
  }

  return context
}

const SET_KEYWORDS = 'SET_KEYWORDS'
const SET_SPECIFIC_QUERY = 'SET_SPECIFIC_QUERY'

const initalState = {
  keywords: [],
  specificQuery: ''
}

const handlers = {
  SET_KEYWORDS: (state, action) => ({
    ...state,
    keywords: action.payload
  }),
  SET_SPECIFIC_QUERY: (state, action) => ({
    ...state,
    specificQuery: action.payload
  })
}

const reducer = (state, action) => {
  return handlers[action.type](state, action)
}

export function ArticleSuggestionProvider({ children }) {
  const [state, dispatchState] = useReducer(reducer, initalState)

  const setKeywords = keywords => {
    dispatchState({ type: SET_KEYWORDS, payload: keywords })
  }

  const setSpecificQuery = query => {
    dispatchState({ type: SET_SPECIFIC_QUERY, payload: query })
  }

  return (
    <ArticleSuggestionContext.Provider
      value={{ state, setKeywords, setSpecificQuery }}
    >
      {children}
    </ArticleSuggestionContext.Provider>
  )
}

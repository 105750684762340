import { makeOperationsApiCall } from './utils'

export const getInventoryTransfers = async params => {
  try {
    const query = /* GraphQL */ `
      query getInventoryTransfers(
        $filter: GetInventoryTransfersFilter
        $order: OrderEnum
        $offset: Int
        $limit: Int
      ) {
        getInventoryTransfers(
          filter: $filter
          order: $order
          offset: $offset
          limit: $limit
        ) {
          inventoryTransfers {
            id
            organizationId
            inventory {
              id
              storageFacility {
                id
                name
              }
              produce {
                id
                name
              }
              variety {
                id
                name
              }
              grade {
                id
                name
              }
            }
            shippingMethod {
              id
              name
            }
            storageFacility {
              id
              name
            }
            status
            totalWeight
            shippedDate
            deliveredDate
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getInventoryTransfers',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getInventoryTransfer = async params => {
  try {
    const query = /* GraphQL */ `
      query getInventoryTransfer($inventoryTransferId: String!) {
        getInventoryTransfer(inventoryTransferId: $inventoryTransferId) {
          id
          organizationId
          inventoryId
          inventory {
            storageFacilityId
            produceId
            varietyId
            gradeId
          }
          shippingMethodId
          storageFacilityId
          status
          totalWeight
          shippedDate
          deliveredDate
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getInventoryTransfer',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const createInventoryTransfer = async params => {
  try {
    const query = /* GraphQL */ `
      mutation createInventoryTransfer(
        $organizationId: String!
        $inventoryId: String!
        $shippingMethodId: String!
        $storageFacilityId: String!
        $status: InventoryTransferStatus!
        $totalWeight: Float!
        $shippedDate: GraphQLTimestamp
        $deliveredDate: GraphQLTimestamp
      ) {
        createInventoryTransfer(
          organizationId: $organizationId
          inventoryId: $inventoryId
          shippingMethodId: $shippingMethodId
          storageFacilityId: $storageFacilityId
          status: $status
          totalWeight: $totalWeight
          shippedDate: $shippedDate
          deliveredDate: $deliveredDate
        ) {
          id
          organizationId
          inventory {
            id
            storageFacility {
              id
              name
            }
            produce {
              id
              name
            }
            variety {
              id
              name
            }
            grade {
              id
              name
            }
          }
          shippingMethod {
            id
            name
          }
          storageFacility {
            id
            name
          }
          status
          totalWeight
          shippedDate
          deliveredDate
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'createInventoryTransfer',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updateInventoryTransfer = async params => {
  try {
    const query = /* GraphQL */ `
      mutation updateInventoryTransfer(
        $inventoryTransferId: String!
        $organizationId: String!
        $inventoryId: String!
        $shippingMethodId: String!
        $storageFacilityId: String!
        $status: InventoryTransferStatus!
        $totalWeight: Float!
        $shippedDate: GraphQLTimestamp
        $deliveredDate: GraphQLTimestamp
      ) {
        updateInventoryTransfer(
          inventoryTransferId: $inventoryTransferId
          organizationId: $organizationId
          inventoryId: $inventoryId
          shippingMethodId: $shippingMethodId
          storageFacilityId: $storageFacilityId
          status: $status
          totalWeight: $totalWeight
          shippedDate: $shippedDate
          deliveredDate: $deliveredDate
        ) {
          id
          organizationId
          inventory {
            id
            storageFacility {
              id
              name
            }
            produce {
              id
              name
            }
            variety {
              id
              name
            }
            grade {
              id
              name
            }
          }
          shippingMethod {
            id
            name
          }
          storageFacility {
            id
            name
          }
          status
          totalWeight
          shippedDate
          deliveredDate
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'updateInventoryTransfer',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

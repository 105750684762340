export const initialState = {
  organizationId: null,
  inventoryId: null,
  organizations: [],
  storageFacilities: [],
  produces: [],
  varieties: [],
  page: 1
}

const reducer = (state, action) => {
  if (action.state) {
    return {
      ...state,
      ...action.state
    }
  } else if (action.page) {
    return {
      ...state,
      page: action.page
    }
  } else if (action.organizations) {
    return {
      ...state,
      organizations: action.organizations,
      storageFacilities: []
    }
  } else if (action.storageFacilities) {
    return {
      ...state,
      storageFacilities: action.storageFacilities
    }
  } else if (action.produces) {
    return {
      ...state,
      produces: action.produces,
      varieties: []
    }
  } else if (action.varieties) {
    return {
      ...state,
      varieties: action.varieties
    }
  }
  return state
}

export default reducer

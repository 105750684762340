import { getSWRegistration } from '../../../reducers/selectors'
import { Flex, Icon, Button } from '@/primitives'
import Strings from '../Strings'

const ServiceNotification = () => {
  const swRegistration = getSWRegistration()

  const strings = Strings()

  /* ServiceWorker Update */
  const updateServiceWorker = () => {
    const registrationWaiting = swRegistration?.waiting
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' })
      registrationWaiting.addEventListener('statechange', e => {
        window.location.reload()
      })

      setTimeout(() => {
        window.location.reload()
      }, 150)
    }
  }

  return (
    <Flex
      className='ServiceNotification'
      role='alert'
      alignCrossAxis='center'
      alignMainAxis='space-between'
    >
      <Flex
        className='GlobalNotification__Message'
        alignMainAxis='center'
        alignCrossAxis='center'
        axisGap={400}
      >
        <Icon name='sync problem' />
        <span>{strings.swNewVersion}</span>
        <Button variant='warning' size='small' onClick={updateServiceWorker}>
          {strings.swClickHere}
        </Button>
      </Flex>
    </Flex>
  )
}

export default ServiceNotification

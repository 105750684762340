import { Button, Cell, Row, Text } from '@/primitives'
import useOrgLabel from '@/hooks/useOrgLabel'

import Strings, {
  ActionStrings,
  ActorTypeStrings,
  ServiceStrings
} from './Strings'

import { formatAuditTimestamp } from './utils'

function TableRow({ auditLog, onInspect, organization, zone }) {
  const strings = Strings()
  const serviceStrings = ServiceStrings()
  const actionStrings = ActionStrings()
  const actorTypeStrings = ActorTypeStrings()
  const { site } = useOrgLabel(['site'])
  const { actorId, actorType, timestamp, service, context, action } = auditLog

  const handleInspectLog = () => {
    onInspect({ ...auditLog, organization, zone })
  }

  return (
    <Row>
      <Cell columnName={strings.actor}>
        <Text variant='page' tone={1000}>
          {actorId}
        </Text>
      </Cell>
      <Cell columnName={strings.actorType}>
        <Text variant='page' tone={1000}>
          {actorTypeStrings[actorType]}
        </Text>
      </Cell>
      <Cell columnName={strings.action}>
        <Text variant='page' tone={900}>
          {actionStrings[action]}
        </Text>
      </Cell>
      <Cell columnName={strings.service}>
        <Text variant='page' tone={900}>
          {serviceStrings[service]}
        </Text>
      </Cell>
      <Cell columnName={strings.date}>
        <Text variant='page' tone={800}>
          {formatAuditTimestamp(timestamp)}
        </Text>
      </Cell>
      <Cell columnName={strings.organization}>
        <Text variant='page' tone={700}>
          {organization || strings.notApplicable}
        </Text>
      </Cell>
      <Cell columnName={site.text}>
        <Text variant='page' tone={700}>
          {zone || strings.notApplicable}
        </Text>
      </Cell>
      <Cell columnName={strings.context}>
        <Text variant='page' tone={700}>
          {context}
        </Text>
      </Cell>
      <Cell className='Audit_Log_Inspect' columnName={strings.details}>
        <Button onClick={handleInspectLog} iconBefore='search' />
      </Cell>
    </Row>
  )
}

export default TableRow

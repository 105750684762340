export const STATUSMAP = {
  warning: {
    icon: 'error',
    variant: 'error',
    value: 3
  },
  caution: {
    icon: 'warning',
    variant: 'danger',
    value: 2
  },
  notification: {
    icon: 'notification_important',
    variant: 'warning',
    value: 1
  },
  active: {
    icon: 'check_circle',
    variant: 'success',
    value: 0
  },
  loading: {
    icon: 'pending',
    variant: 'page',
    value: -1
  },
  missingData: {
    icon: 'leak_remove',
    variant: 'graph',
    value: -2
  },
  notInUse: {
    icon: 'sensors_off',
    variant: 'page',
    value: -3
  }
}

export const TYPEICONMAP = [
  'folder',
  'envirosense',
  'cellular_envirosense',
  'soilsense',
  'cellular_soilsense',
  'watersense',
  'cellular_watersense',
  'dissolved_oxygen',
  'energy_monitor',
  'airflow_meter',
  'modbus',
  'notInUse',
  'missingData',
  'generic'
]

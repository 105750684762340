import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { requestPackageUnitInventories } from '@/actions/operations/packageUnitInventory'

import {
  FlexV2,
  Box,
  Table,
  Slot,
  Column,
  Row,
  Text,
  Loader,
  Button
} from '@/primitives'

import { Pagination } from '@/elements'

import HeaderV2 from '@/components/Operations/Shared/HeaderV2'
import TableFilter from './TableFilter'
import TableRow from './TableRow'

import {
  getCurrentUser,
  getOperationsPackageUnitInventories,
  getOperationsPackageUnitInventoriesCount,
  getOperationsLoading
} from '@/reducers/selectors'

import history from '../../../../../history'
import Strings from '../Strings'

import useMediaQuery from '@/hooks/useMediaQuery'

import {
  hasEditPermissions,
  RESOURCE_TYPE_OPERATIONS,
  RESOURCE_TYPE_OPERATIONS_INVENTORY
} from '@/Util/PermissionUtils'

function PackageUnitInventoryTable({ state, dispatchState, modulePath }) {
  const dispatch = useDispatch()

  const currentUser = getCurrentUser()
  const items = getOperationsPackageUnitInventories()
  const totalItems = getOperationsPackageUnitInventoriesCount()
  const loading = getOperationsLoading()

  const strings = Strings()

  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(9)
  const [tableState, setTableState] = useState(state)
  const [canEdit, setCanEdit] = useState(false)

  const isAboveCustomBreakpoint = useMediaQuery('min-width: 620px')

  useEffect(() => {
    if (currentUser.userName) {
      setCanEdit(
        hasEditPermissions(
          currentUser,
          RESOURCE_TYPE_OPERATIONS,
          RESOURCE_TYPE_OPERATIONS_INVENTORY
        )
      )
    }
  }, [currentUser])

  useEffect(() => {
    if (tableState.page !== state.page || items.length === 0) {
      setTableState({ ...state })
      fetchPackageUnitInventories(state.page)
    }
  }, [state.page])

  useEffect(() => {
    if (
      tableState.organizations !== state.organizations ||
      tableState.storageFacilities !== state.storageFacilities ||
      tableState.packageUnits !== state.packageUnits
    ) {
      setTableState({ ...state })
      fetchPackageUnitInventories(1)
      changePage(1)
    }
  }, [state.organizations, state.storageFacilities, state.packageUnits])

  const fetchPackageUnitInventories = page => {
    if (
      state.organizations.length > 0 &&
      state.storageFacilities.length > 0 &&
      state.packageUnits.length > 0
    ) {
      dispatch(
        requestPackageUnitInventories({
          filter: {
            organizationId: state.organizations,
            storageFacilityId: state.storageFacilities,
            packageUnitId: state.packageUnits
          },
          limit: pageSize,
          offset: page - 1
        })
      )
    }
  }

  const changePage = newPage => {
    dispatchState({
      page: newPage
    })
  }

  const onPurchases = () => {
    history.push(`${modulePath}/purchases`)
  }

  const onTransfers = () => {
    history.push(`${modulePath}/transfers`)
  }

  return (
    <FlexV2 axisGap={400} direction='column'>
      <HeaderV2
        title={strings.managePackageUnitInventory}
        buttonIcon={'shopping_cart'}
        buttonText={strings.purchases}
        buttonCallback={onPurchases}
        showButton={canEdit}
        breakpointWidth={1024}
      >
        <TableFilter state={state} dispatchState={dispatchState} />

        <Button
          size='small'
          iconBefore={'sync_alt'}
          variant='primary'
          name={'transfersButton'}
          onClick={onTransfers}
        >
          <Text fontWeight={700} textTransform='uppercase'>
            {strings.transfers}
          </Text>
        </Button>
      </HeaderV2>

      <Loader isLoading={loading}>
        {totalItems === 0 && (
          <FlexV2 className={'Operations__Table__Empty'}>
            <Text>{strings.noPackageUnitInventory}</Text>
          </FlexV2>
        )}

        {totalItems > 0 && (
          <Table className='Operations__Table' aria-colcount='4'>
            <Slot name='head'>
              <Row>
                <Column>{strings.storageFacility}</Column>
                <Column>{strings.packageUnit}</Column>
                <Column>{strings.quantity}</Column>
                <Column />
              </Row>
            </Slot>
            <Slot name='body'>
              {items?.map(item => (
                <TableRow
                  key={item.id}
                  item={item}
                  dispatchState={dispatchState}
                  modulePath={modulePath}
                />
              ))}
            </Slot>
          </Table>
        )}

        {totalItems > pageSize && (
          <Box className='pagination-menu'>
            <Pagination
              type={isAboveCustomBreakpoint ? 'simple' : 'compact'}
              showPageCount={isAboveCustomBreakpoint}
              totalItems={totalItems}
              totalPages={totalPages}
              setTotalPages={setTotalPages}
              page={tableState.page}
              setPage={changePage}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          </Box>
        )}
      </Loader>
    </FlexV2>
  )
}

export default PackageUnitInventoryTable

import {
  RESOURCE_TYPE_DATA_API,
  RESOURCE_TYPE_DATA_API_MANAGEMENT,
  RESOURCE_TYPE_DEVICE,
  RESOURCE_TYPE_DEVICE_MANAGEMENT,
  RESOURCE_TYPE_DEVICE_CALIBRATION,
  RESOURCE_TYPE_DEVICE_THRESHOLDS,
  RESOURCE_TYPE_DEVICE_CAMERAS,
  RESOURCE_TYPE_DEVICE_CONTROL_RANGES,
  RESOURCE_TYPE_ORGANIZATION,
  RESOURCE_TYPE_OPERATIONS,
  RESOURCE_TYPE_OPERATIONS_MANAGEMENT,
  RESOURCE_TYPE_OPERATIONS_PLANS,
  RESOURCE_TYPE_OPERATIONS_HARVEST,
  RESOURCE_TYPE_OPERATIONS_WASTE,
  RESOURCE_TYPE_OPERATIONS_CUSTOMERS,
  RESOURCE_TYPE_OPERATIONS_SALES,
  RESOURCE_TYPE_OPERATIONS_RETURNS,
  RESOURCE_TYPE_OPERATIONS_INVENTORY,
  RESOURCE_TYPE_OPERATIONS_FERTILIZER,
  RESOURCE_TYPE_SYSTEMS,
  RESOURCE_TYPE_SYSTEMS_ENVIRONMENT,
  RESOURCE_TYPE_SYSTEMS_FERTIGATION,
  RESOURCE_TYPE_USER,
  RESOURCE_TYPE_USER_MANAGEMENT,
  RESOURCE_TYPE_USER_ROLES,
  RESOURCE_TYPE_USER_MANAGERS,
  RESOURCE_TYPE_USER_LOGS,
  RESOURCE_TYPE_ZONE,
  hasReadPermissions
} from '../../../Util/PermissionUtils'

// Pay attention to how the query is composed.
/**
 * List of composed permissions for reading resources
 * @type {Array<Array<String> | String>}
 */
const composedPermissions = [
  RESOURCE_TYPE_DATA_API,
  [RESOURCE_TYPE_DATA_API, RESOURCE_TYPE_DATA_API_MANAGEMENT],

  RESOURCE_TYPE_DEVICE,
  [RESOURCE_TYPE_DEVICE, RESOURCE_TYPE_DEVICE_MANAGEMENT],
  [RESOURCE_TYPE_DEVICE, RESOURCE_TYPE_DEVICE_CALIBRATION],
  [RESOURCE_TYPE_DEVICE, RESOURCE_TYPE_DEVICE_THRESHOLDS],
  [RESOURCE_TYPE_DEVICE, RESOURCE_TYPE_DEVICE_CAMERAS],
  [RESOURCE_TYPE_DEVICE, RESOURCE_TYPE_DEVICE_CONTROL_RANGES],

  RESOURCE_TYPE_ORGANIZATION,

  RESOURCE_TYPE_OPERATIONS,
  [RESOURCE_TYPE_OPERATIONS, RESOURCE_TYPE_OPERATIONS_MANAGEMENT],
  [RESOURCE_TYPE_OPERATIONS, RESOURCE_TYPE_OPERATIONS_PLANS],
  [RESOURCE_TYPE_OPERATIONS, RESOURCE_TYPE_OPERATIONS_HARVEST],
  [RESOURCE_TYPE_OPERATIONS, RESOURCE_TYPE_OPERATIONS_WASTE],
  [RESOURCE_TYPE_OPERATIONS, RESOURCE_TYPE_OPERATIONS_CUSTOMERS],
  [RESOURCE_TYPE_OPERATIONS, RESOURCE_TYPE_OPERATIONS_SALES],
  [RESOURCE_TYPE_OPERATIONS, RESOURCE_TYPE_OPERATIONS_RETURNS],
  [RESOURCE_TYPE_OPERATIONS, RESOURCE_TYPE_OPERATIONS_INVENTORY],
  [RESOURCE_TYPE_OPERATIONS, RESOURCE_TYPE_OPERATIONS_FERTILIZER],

  RESOURCE_TYPE_SYSTEMS,
  [RESOURCE_TYPE_SYSTEMS, RESOURCE_TYPE_SYSTEMS_ENVIRONMENT],
  [RESOURCE_TYPE_SYSTEMS, RESOURCE_TYPE_SYSTEMS_FERTIGATION],

  RESOURCE_TYPE_USER,
  [RESOURCE_TYPE_USER, RESOURCE_TYPE_USER_MANAGEMENT],
  [RESOURCE_TYPE_USER, RESOURCE_TYPE_USER_ROLES],
  [RESOURCE_TYPE_USER, RESOURCE_TYPE_USER_MANAGERS],
  [RESOURCE_TYPE_USER, RESOURCE_TYPE_USER_LOGS],

  RESOURCE_TYPE_ZONE
]

/**
 * @name getReadPermissions
 *
 * Returns a dictionary with read permissions for each resource type
 * @param {Object} coretexUser - User object from SecondSky Data system
 * @return {Object} Dictionary with keys as resource names and values as boolean indicating if user has read permission
 */
export const getReadPermissions = coretexUser => {
  return composedPermissions.reduce((permissionDict, permissionItem) => {
    const resourceName = [permissionItem].flat().join('_')
    const argList = [permissionItem].flat()
    permissionDict[resourceName] = hasReadPermissions(coretexUser, ...argList)
    return permissionDict
  }, {})
}

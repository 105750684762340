import {
  requestGetAllProducesByHarvestDate,
  requestGetAllVarietiesByHarvestDateAndProduce,
  requestPlanVsHarvest,
  requestTopTenHarvestVariety,
  requestTotalHarvestProduce
} from '@/actions/operations/harvest'
import {
  BAR_CHART,
  PIE_CHART,
  LINE_CHART
} from '@/components/DashboardPageV2/Desktop/Widgets/Shared//Dynamic/config'
import MD5 from 'crypto-js/md5'

export const normalizeZones = zones => {
  return zones.map(({ id, name }) => ({ zoneId: id, zoneName: name }))
}

export const getFiltersHash = filters => {
  return `${filters.range}#${filters.interval || 'no-interval'}#${
    filters.produceId || 'no-produce-id'
  }`
}

export const getQueryZonesHash = queryZones => {
  // MD5 is being used instead of concat strings to make a hash is because of long key names
  // Performance is not an issue. Testing it averages out at around 0.09ms
  return MD5(queryZones.join()).toString()
}

/**
 *
 * @param {String} type
 * @param {String} chartType
 * @param {Object} params
 * @param {Object} dashboardParams
 * @returns
 */
export const getOperationsDispatchFunctions = (
  type,
  chartType,
  params,
  dashboardParams
) => {
  const dispatchFunc = {
    totalProduce: {
      [PIE_CHART]: requestTotalHarvestProduce(params, dashboardParams),
      [LINE_CHART]: requestGetAllProducesByHarvestDate(params, dashboardParams)
    },

    topTenHarvestVariety: {
      [PIE_CHART]: requestTopTenHarvestVariety(params, dashboardParams),
      [LINE_CHART]: requestGetAllVarietiesByHarvestDateAndProduce(
        params,
        dashboardParams
      )
    },
    planVsHarvest: {
      [BAR_CHART]: requestPlanVsHarvest(params, dashboardParams)
    }
  }

  return dispatchFunc[type][chartType]
}

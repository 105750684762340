import React from 'react'

import useDeviceSize from '../../../../../../hooks/useDeviceSize'

import { Text, Table, Slot, Row, Cell } from '../../../../../../primitives'

import PaymentForm from './Form'
import Strings from './Strings'

function SalePayments({ sale, errors, dispatchFormState }) {
  const isMobile = useDeviceSize('mobile')
  const strings = Strings()

  if (!sale?.payments?.length) {
    return (
      <Text className={'Operations__Form__Empty'}>
        {strings.fieldPaymentsNoEntries}
      </Text>
    )
  }

  return (
    <Table aria-colcount='3'>
      <Slot name='body'>
        {!isMobile && (
          <Row>
            <Cell>
              <Text variant='page' tone={700}>
                {strings.fieldDateReceived}
              </Text>
            </Cell>
            <Cell>
              <Text variant='page' tone={700}>
                {strings.fieldAmount}
              </Text>
            </Cell>
            <Cell />
          </Row>
        )}
        {sale.payments.map((payment, index) => (
          <PaymentForm
            key={payment.id}
            sale={sale}
            payment={payment}
            errors={errors}
            index={index}
            dispatchFormState={dispatchFormState}
          />
        ))}
      </Slot>
    </Table>
  )
}

export default SalePayments

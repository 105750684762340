import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { isEmpty } from '@aws-amplify/core'

import { requestFertilizerInventory } from '@/actions/operations/fertilizerInventory'

import { requestFertilizerInventoryCalibrations } from '@/actions/operations/fertilizerInventoryCalibration'

import {
  getCurrentUser,
  getOperationsFertilizerInventory,
  getOperationsFertilizerInventoryCalibrations,
  getOperationsFertilizerInventoryCalibrationsCount,
  getOperationsLoading
} from '@/reducers/selectors'

import { Flex, Box, Table, Slot, Column, Row, Text, Loader } from '@/primitives'

import { Pagination } from '@/elements'
import HeaderV2 from '@/components/Operations/Shared/HeaderV2'

import TableRow from './TableRow'

import history from '../../../../../history'

import {
  hasEditPermissions,
  RESOURCE_TYPE_OPERATIONS,
  RESOURCE_TYPE_OPERATIONS_INVENTORY
} from '@/Util/PermissionUtils'

import Strings from '../Strings'
import useAllFertilizersMap from '../../../Shared/Hooks/useFertilizersMap'

function FertilizerInventoryCalibrationTable({
  state,
  dispatchState,
  modulePath,
  backPath,
  fertilizerInventoryId
}) {
  const dispatch = useDispatch()

  const currentUser = getCurrentUser()
  const fertilizerInventory = getOperationsFertilizerInventory()
  const allFertilizersMap = useAllFertilizersMap()
  const items = getOperationsFertilizerInventoryCalibrations()
  const totalItems = getOperationsFertilizerInventoryCalibrationsCount()
  const loading = getOperationsLoading()

  const strings = Strings()

  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(9)
  const [tableState, setTableState] = useState(state)
  const [canEdit, setCanEdit] = useState(false)

  useEffect(() => {
    if (currentUser.userName) {
      setCanEdit(
        hasEditPermissions(
          currentUser,
          RESOURCE_TYPE_OPERATIONS,
          RESOURCE_TYPE_OPERATIONS_INVENTORY
        )
      )
    }
  }, [currentUser])

  useEffect(() => {
    if (!fertilizerInventoryId) {
      history.push('/operations/inventory/fertilizer')
    } else {
      dispatch(
        requestFertilizerInventory({
          fertilizerInventoryId
        })
      )
    }
  }, [fertilizerInventoryId, dispatch])

  useEffect(() => {
    if (tableState.page !== state.page || items.length === 0) {
      setTableState({ ...state })
      fetchFertilizerInventoryCalibrations(state.page)
    }
  }, [state.page])

  const fetchFertilizerInventoryCalibrations = page => {
    dispatch(
      requestFertilizerInventoryCalibrations({
        filter: {
          fertilizerInventoryId: [fertilizerInventoryId]
        },
        limit: pageSize,
        offset: page - 1
      })
    )
  }

  const getHeader = () => {
    if (fertilizerInventory.id && !isEmpty(allFertilizersMap)) {
      return (
        <Box>
          {strings.manageFertilizerInventoryCalibrations}
          <span className='Operations__Header__Subheader'>
            {fertilizerInventory.storageFacility.name}&nbsp;-&nbsp;
            {allFertilizersMap[fertilizerInventory.fertilizerId].name} (
            {fertilizerInventory.totalWeight}
            {strings.kg})
          </span>
        </Box>
      )
    }
    return `${strings.manageFertilizerInventoryCalibrations}`
  }

  const changePage = newPage => {
    dispatchState({
      page: newPage
    })
  }

  const onCreate = () => {
    history.push(`${modulePath}/create`)
  }

  return (
    <Flex axisGap={400} direction='column'>
      <HeaderV2
        title={getHeader()}
        buttonIcon={'add'}
        buttonText={strings.createFertilizerInventoryCalibration}
        buttonCallback={onCreate}
        showButton={canEdit}
        backPath={backPath}
      />
      <Loader isLoading={loading}>
        {totalItems === 0 && (
          <Flex className={'Operations__Table__Empty'}>
            <Text>{strings.noFertilizerInventoryCalibrations}</Text>
          </Flex>
        )}
        {totalItems > 0 && (
          <Table className='Operations__Table' aria-colcount='6'>
            <Slot name='head'>
              <Row>
                <Column>{strings.date}</Column>
                <Column>{strings.oldTotalWeight}</Column>
                <Column>{strings.newTotalWeight}</Column>
                <Column>{strings.reason}</Column>
              </Row>
            </Slot>
            <Slot name='body'>
              {items?.map(item => (
                <TableRow key={item.id} item={item} />
              ))}
            </Slot>
          </Table>
        )}
        {totalItems > pageSize && (
          <Box className='pagination-menu'>
            <Pagination
              type='simple'
              totalItems={totalItems}
              totalPages={totalPages}
              setTotalPages={setTotalPages}
              page={tableState.page}
              setPage={changePage}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          </Box>
        )}
      </Loader>
    </Flex>
  )
}

export default FertilizerInventoryCalibrationTable

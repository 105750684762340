import { I18n } from 'aws-amplify'
import { object, string, date, number, array } from 'yup'

export const FIELDS = {
  id: 'id',
  organizationId: 'organizationId',
  siteId: 'siteId',
  zoneId: 'zoneId',
  produceId: 'produceId',
  varietyId: 'varietyId',
  status: 'status',
  plantingArea: 'plantingArea',
  growthMedium: 'growthMedium',
  hydrationMethod: 'hydrationMethod',
  roofCover: 'roofCover',
  secondskyVariant: 'secondskyVariant',
  blockingRatio: 'blockingRatio',
  netType: 'netType',
  secondskyInformation: 'secondskyInformation',
  seedQuantity: 'seedQuantity',
  estimatedSowDate: 'estimatedSowDate',
  sowDate: 'sowDate',
  estimatedTransplantDate: 'estimatedTransplantDate',
  transplantDate: 'transplantDate',
  estimatedHarvestStartDate: 'estimatedHarvestStartDate',
  harvestStartDate: 'harvestStartDate',
  estimatedHarvestEndDate: 'estimatedHarvestEndDate',
  harvestEndDate: 'harvestEndDate',
  produceThresholdId: 'produceThresholdId'
}

export const SCHEMA = (roofCover, secondskyVariant) => {
  return object().shape({
    organizationId: string().required(I18n.get('An organization is required.')),
    produceId: string().required(I18n.get('Produce is required.')),
    varietyId: string().required(I18n.get('A variety is required.')),
    status: string().required('Status is required'),
    plantingArea: number()
      .min(1, I18n.get('Planting area must be at least 1m2.'))
      .integer('A partial planting area is not allowed.')
      .typeError('Invalid planting area.')
      .nullable(true)
      .default(null)
      .transform((_, n) => (n === Number(n) ? n : null)),
    growthMedium: array()
      .nullable(false)
      .default([])
      .typeError('Invalid growth medium.')
      .transform((_, n) => (n?.length > 0 ? n : [])),
    hydrationMethod: string()
      .nullable(true)
      .default(null)
      .transform((_, n) => (n?.length > 0 ? n : null)),
    roofCover: string()
      .nullable(true)
      .default(null)
      .transform((_, n) => (n?.length > 0 ? n : null))
      .test('roofCoverRequired', 'Please select a roof cover.', value => {
        if (value === null) {
          return false
        }
        return true
      }),
    secondskyVariant: string()
      .nullable(true)
      .default(null)
      .transform((_, n) => (n?.length > 0 ? n : null))
      .test('secondskyVariantRoofCover', 'Please select a variant.', value => {
        if (roofCover === 'secondsky') {
          if (value === null) {
            return false
          }
        }
        return true
      }),
    blockingRatio: string()
      .nullable(true)
      .default(null)
      .transform((_, n) => (n?.length > 0 ? n : null))
      .test(
        'blockingRatioRoofCover',
        'Please select a blocking ratio.',
        value => {
          if (roofCover === 'secondsky') {
            if (value === null) {
              return false
            }
          }
          return true
        }
      ),
    netType: string()
      .nullable(true)
      .default(null)
      .transform((_, n) => (n?.length > 0 ? n : null))
      .test('netTypeSecondskyVariant', 'Please select a net type.', value => {
        if (secondskyVariant === 'net') {
          if (value === null) {
            return false
          }
        }
        return true
      }),
    secondskyInformation: string().nullable(true).default(null),
    seedQuantity: number()
      .nullable(true)
      .default(null)
      .min(1, I18n.get('Seed quantity cannot be less than 1.'))
      .integer('A partial seed quantity is not allowed.')
      .typeError('Invalid seed quantity.')
      .transform((_, n) => (n === Number(n) ? n : null)),
    estimatedSowDate: date()
      .nullable(true)
      .default(null)
      .typeError('Invalid estimated sow date.'),
    sowDate: date().nullable(true).default(null).typeError('Invalid sow date.'),
    estimatedTransplantDate: date()
      .nullable(true)
      .default(null)
      .typeError('Invalid estimated transplant date.'),
    transplantDate: date()
      .nullable(true)
      .default(null)
      .typeError('Invalid transplant date.'),
    estimatedHarvestStartDate: date()
      .nullable(true)
      .default(null)
      .typeError('Invalid estimated harvest start date.'),
    harvestStartDate: date()
      .nullable(true)
      .default(null)
      .typeError('Invalid harvest start date.'),
    estimatedHarvestEndDate: date()
      .nullable(true)
      .default(null)
      .typeError('Invalid estimated harvest end date.'),
    harvestEndDate: date()
      .nullable(true)
      .default(null)
      .typeError('Invalid harvest end date.')
  })
}

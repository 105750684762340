import { makeActionCreator } from '@/Util/ReduxUtils'

export const CREATE_CROP = 'CREATE_CROP'
export const createCrop = makeActionCreator(CREATE_CROP, 'params')

export const CROP_CREATED = 'CROP_CREATED'
export const cropCreated = makeActionCreator(CROP_CREATED, 'crop', 'error')

export const UPDATE_CROP = 'UPDATE_CROP'
export const updateCrop = makeActionCreator(UPDATE_CROP, 'params')

export const CROP_UPDATED = 'CROP_UPDATED'
export const cropUpdated = makeActionCreator(CROP_UPDATED, 'crop', 'error')

export const REQUEST_CROPS = 'REQUEST_CROPS'
export const requestCrops = makeActionCreator(REQUEST_CROPS, 'params')

export const RECEIVE_CROPS = 'RECEIVE_CROPS'
export const receiveCrops = makeActionCreator(
  RECEIVE_CROPS,
  'crops',
  'count',
  'error'
)

export const REQUEST_CROP = 'REQUEST_CROP'
export const requestCrop = makeActionCreator(REQUEST_CROP, 'params')

export const RECEIVE_CROP = 'RECEIVE_CROP'
export const receiveCrop = makeActionCreator(RECEIVE_CROP, 'crop', 'error')

export const CLEAR_ALL_CROPS = 'CLEAR_ALL_CROPS'
export const clearAllCrops = makeActionCreator(CLEAR_ALL_CROPS)

export const REQUEST_ALL_CROPS = 'REQUEST_ALL_CROPS'
export const requestAllCrops = makeActionCreator(REQUEST_ALL_CROPS, 'params')

export const RECEIVE_ALL_CROPS = 'RECEIVE_ALL_CROPS'
export const receiveAllCrops = makeActionCreator(
  RECEIVE_ALL_CROPS,
  'crops',
  'count',
  'error'
)

import ZoneUtils from '@/Util/ZoneUtils'

export function getDisplayNamePath(
  hierarchy,
  path,
  targetMove = {
    id: null,
    path: null,
    name: null
  }
) {
  if (!hierarchy || !path) return path
  const nameMap = ZoneUtils.getZoneHierarchyDiplayNameMapByPath(
    hierarchy,
    path,
    targetMove.id ? { [targetMove.id]: targetMove.name } : {}
  )

  if (!nameMap) return path

  let parts = targetMove.path
    ? targetMove.path.split('/').slice(1)
    : path.split('/').slice(1)

  let displayPath = '/'

  if (targetMove.id) {
    displayPath += parts
      .map(part => (part === targetMove.id ? targetMove.name : nameMap[part]))
      .join('/')
  } else {
    displayPath += parts.map(part => nameMap[part]).join('/')
  }
  return displayPath
}

export function getMovedParentPath(zoneId, targetNodePath) {
  let parts = targetNodePath.split('/').slice(1)
  // check if parent is moving to a child node
  const index = parts.includes(zoneId)
  if (index > -1) {
    parts.splice(0, index)
  }

  parts.push(zoneId)
  return '/' + parts.join('/')
}

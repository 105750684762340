import { I18n } from 'aws-amplify'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, Switch, Route } from 'react-router-dom'

import history from '@/history'
import { Button, FlexV2, Text } from '@/primitives'
import LineSeparator from '@/elements/LineSeparator'
import { fetchOrganizationLabels } from '@/slices/management/organizationLabels'
import { fetchOrganization } from '@/slices/management/organization'
import { getOrganization } from '@/reducers/selectors'

import UpsertLabelFrom from './UpsertLabelForm'
import LabelTable from './LabelTable'

export default function OrganzationLabels() {
  const dispatch = useDispatch()
  const params = useParams()
  const organization = getOrganization()

  useEffect(() => {
    if (!organization?.id || organization?.id !== params.organizationId) {
      dispatch(
        fetchOrganization({
          organizationId: params.organizationId
        })
      )
    }
  }, [dispatch, organization?.id, params.organizationId])

  useEffect(() => {
    dispatch(
      fetchOrganizationLabels({
        organizationId: params.organizationId
      })
    )
  }, [params.organizationId])

  function onAddLabel(e) {
    e.preventDefault()
    history.push(`/admin/organizations/${params.organizationId}/labels/add`)
  }

  function onGoBack(e) {
    e.preventDefault()
    history.push('/admin/organizations')
  }

  return (
    <FlexV2 direction='column'>
      <FlexV2 alignMainAxis='space-between' alignCrossAxis='center'>
        <FlexV2 axisGap={400} alignMainAxis='start' alignCrossAxis='center'>
          <Button
            size='small'
            tone={500}
            iconBefore='chevron left'
            onClick={onGoBack}
          />

          <Text size='300' variant='page' tone='800' fontWeight='300'>
            {I18n.get('Manage')}{' '}
            {organization?.name ? organization.name : I18n.get('Oganization')}{' '}
            {I18n.get('Labels')}
          </Text>
        </FlexV2>
        <Button variant='primary' size='small' onClick={onAddLabel}>
          {I18n.get('Add Label')}
        </Button>
      </FlexV2>
      <LineSeparator />
      <LabelTable />
      <Switch>
        <Route path='/admin/organizations/:organizationId/labels/add'>
          <UpsertLabelFrom />
        </Route>
        <Route path='/admin/organizations/:organizationId/labels/:labelId/edit'>
          <UpsertLabelFrom />
        </Route>
      </Switch>
    </FlexV2>
  )
}

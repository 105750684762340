import { updateList } from '../util'

import {
  RECEIVE_ALL_PRODUCES,
  REQUEST_ALL_PRODUCES,
  REQUEST_PRODUCES,
  RECEIVE_PRODUCES,
  CLEAR_PRODUCES,
  CLEAR_PRODUCE,
  RECEIVE_PRODUCE,
  REQUEST_PRODUCE,
  CREATE_PRODUCE,
  PRODUCE_CREATED,
  UPDATE_PRODUCE,
  PRODUCE_UPDATED,
  SET_PRODUCE
} from '@/actions/operations/produce'

export const produceInitialState = {
  allProduces: [],
  produces: [],
  produce: {},
  producesCount: 0,
  loading: false,
  error: null
}

const handlers = {
  [SET_PRODUCE]: (state, action) => ({
    ...state,
    produce: action.produce
  }),
  [CLEAR_PRODUCE]: (state, action) => ({
    ...state,
    produce: {},
    error: null
  }),
  [CLEAR_PRODUCES]: (state, action) => ({
    ...state,
    produces: [],
    error: null
  }),
  [REQUEST_ALL_PRODUCES]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [RECEIVE_ALL_PRODUCES]: (state, action) => ({
    ...state,
    allProduces: !action.error ? action.produces : [],
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_PRODUCE]: (state, action) => ({
    ...state,
    produce: {},
    error: null,
    loading: true
  }),
  [RECEIVE_PRODUCE]: (state, action) => ({
    ...state,
    produce: !action.error ? action.produce : state.produce,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_PRODUCES]: (state, action) => ({
    ...state,
    produces: [],
    error: null,
    loading: true
  }),
  [RECEIVE_PRODUCES]: (state, action) => ({
    ...state,
    produces: !action.error ? action.produces : [],
    producesCount: !action.error ? action.count : 0,
    error: action.error ?? null,
    loading: false
  }),
  [CREATE_PRODUCE]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [PRODUCE_CREATED]: (state, action) => ({
    ...state,
    produce: !action.error ? {} : state.produce,
    produces: updateList(action.error, state.produces, action.produce, true),
    allProduces: updateList(
      action.error,
      state.allProduces,
      action.produce,
      true
    ),
    producesCount: !action.error
      ? state.producesCount + 1
      : state.producesCount,
    error: action.error ?? null,
    loading: false
  }),
  [UPDATE_PRODUCE]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [PRODUCE_UPDATED]: (state, action) => ({
    ...state,
    produce: !action.error ? {} : state.produce,
    produces: updateList(action.error, state.produces, action.produce, false),
    allProduces: updateList(
      action.error,
      state.allProduces,
      action.produce,
      false
    ),
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

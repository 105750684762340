import { ResponsiveScatterPlot } from '@nivo/scatterplot'
import theme from '../theme.json'

/*const mockData = [
  {
    id: 'group A',
    data: [
      {
        x: 1,
        y: 100
      },
      {
        x: 56,
        y: 51
      },
      {
        x: 60,
        y: 118
      },
      {
        x: 83,
        y: 97
      },
      {
        x: 22,
        y: 64
      },
      {
        x: 72,
        y: 44
      },
      {
        x: 85,
        y: 28
      },
      {
        x: 97,
        y: 21
      },
      {
        x: 95,
        y: 88
      },
      {
        x: 36,
        y: 5
      },
      {
        x: 9,
        y: 22
      },
      {
        x: 50,
        y: 32
      },
      {
        x: 18,
        y: 40
      },
      {
        x: 95,
        y: 22
      },
      {
        x: 58,
        y: 28
      },
      {
        x: 66,
        y: 99
      },
      {
        x: 99,
        y: 74
      },
      {
        x: 87,
        y: 13
      },
      {
        x: 18,
        y: 14
      },
      {
        x: 89,
        y: 21
      },
      {
        x: 33,
        y: 83
      },
      {
        x: 94,
        y: 109
      },
      {
        x: 62,
        y: 51
      },
      {
        x: 90,
        y: 111
      },
      {
        x: 78,
        y: 69
      },
      {
        x: 94,
        y: 51
      },
      {
        x: 21,
        y: 107
      },
      {
        x: 17,
        y: 71
      },
      {
        x: 60,
        y: 43
      },
      {
        x: 25,
        y: 108
      },
      {
        x: 8,
        y: 12
      },
      {
        x: 51,
        y: 31
      },
      {
        x: 86,
        y: 97
      },
      {
        x: 98,
        y: 48
      },
      {
        x: 57,
        y: 27
      },
      {
        x: 82,
        y: 20
      },
      {
        x: 23,
        y: 24
      },
      {
        x: 90,
        y: 47
      },
      {
        x: 32,
        y: 84
      },
      {
        x: 42,
        y: 68
      },
      {
        x: 28,
        y: 94
      },
      {
        x: 74,
        y: 30
      },
      {
        x: 73,
        y: 79
      },
      {
        x: 72,
        y: 87
      },
      {
        x: 45,
        y: 26
      },
      {
        x: 82,
        y: 3
      },
      {
        x: 9,
        y: 74
      },
      {
        x: 34,
        y: 105
      },
      {
        x: 91,
        y: 87
      },
      {
        x: 16,
        y: 113
      }
    ]
  },
  {
    id: 'group B',
    data: [
      {
        x: 28,
        y: 100
      },
      {
        x: 85,
        y: 31
      },
      {
        x: 23,
        y: 26
      },
      {
        x: 39,
        y: 10
      },
      {
        x: 93,
        y: 12
      },
      {
        x: 16,
        y: 102
      },
      {
        x: 83,
        y: 85
      },
      {
        x: 97,
        y: 108
      },
      {
        x: 51,
        y: 19
      },
      {
        x: 86,
        y: 44
      },
      {
        x: 37,
        y: 94
      },
      {
        x: 43,
        y: 118
      },
      {
        x: 34,
        y: 21
      },
      {
        x: 91,
        y: 108
      },
      {
        x: 8,
        y: 29
      },
      {
        x: 36,
        y: 100
      },
      {
        x: 28,
        y: 90
      },
      {
        x: 64,
        y: 27
      },
      {
        x: 47,
        y: 7
      },
      {
        x: 69,
        y: 73
      },
      {
        x: 8,
        y: 31
      },
      {
        x: 51,
        y: 53
      },
      {
        x: 77,
        y: 61
      },
      {
        x: 45,
        y: 43
      },
      {
        x: 78,
        y: 87
      },
      {
        x: 56,
        y: 26
      },
      {
        x: 86,
        y: 31
      },
      {
        x: 54,
        y: 39
      },
      {
        x: 45,
        y: 114
      },
      {
        x: 34,
        y: 19
      },
      {
        x: 4,
        y: 10
      },
      {
        x: 9,
        y: 51
      },
      {
        x: 37,
        y: 35
      },
      {
        x: 44,
        y: 107
      },
      {
        x: 45,
        y: 64
      },
      {
        x: 57,
        y: 4
      },
      {
        x: 31,
        y: 6
      },
      {
        x: 47,
        y: 33
      },
      {
        x: 64,
        y: 13
      },
      {
        x: 60,
        y: 40
      },
      {
        x: 74,
        y: 5
      },
      {
        x: 49,
        y: 115
      },
      {
        x: 5,
        y: 34
      },
      {
        x: 15,
        y: 51
      },
      {
        x: 35,
        y: 56
      },
      {
        x: 39,
        y: 68
      },
      {
        x: 17,
        y: 67
      },
      {
        x: 28,
        y: 96
      },
      {
        x: 28,
        y: 81
      },
      {
        x: 40,
        y: 77
      }
    ]
  },
  {
    id: 'group C',
    data: [
      {
        x: 71,
        y: 51
      },
      {
        x: 84,
        y: 104
      },
      {
        x: 39,
        y: 85
      },
      {
        x: 65,
        y: 22
      },
      {
        x: 56,
        y: 90
      },
      {
        x: 36,
        y: 37
      },
      {
        x: 26,
        y: 84
      },
      {
        x: 77,
        y: 14
      },
      {
        x: 2,
        y: 16
      },
      {
        x: 64,
        y: 81
      },
      {
        x: 11,
        y: 68
      },
      {
        x: 15,
        y: 75
      },
      {
        x: 88,
        y: 20
      },
      {
        x: 40,
        y: 23
      },
      {
        x: 79,
        y: 23
      },
      {
        x: 33,
        y: 58
      },
      {
        x: 18,
        y: 45
      },
      {
        x: 66,
        y: 45
      },
      {
        x: 38,
        y: 60
      },
      {
        x: 93,
        y: 71
      },
      {
        x: 19,
        y: 118
      },
      {
        x: 44,
        y: 33
      },
      {
        x: 15,
        y: 29
      },
      {
        x: 45,
        y: 60
      },
      {
        x: 18,
        y: 113
      },
      {
        x: 48,
        y: 108
      },
      {
        x: 25,
        y: 46
      },
      {
        x: 5,
        y: 106
      },
      {
        x: 50,
        y: 19
      },
      {
        x: 84,
        y: 47
      },
      {
        x: 63,
        y: 22
      },
      {
        x: 44,
        y: 97
      },
      {
        x: 40,
        y: 33
      },
      {
        x: 86,
        y: 65
      },
      {
        x: 20,
        y: 55
      },
      {
        x: 31,
        y: 114
      },
      {
        x: 36,
        y: 21
      },
      {
        x: 46,
        y: 110
      },
      {
        x: 51,
        y: 53
      },
      {
        x: 40,
        y: 100
      },
      {
        x: 17,
        y: 50
      },
      {
        x: 20,
        y: 39
      },
      {
        x: 88,
        y: 73
      },
      {
        x: 41,
        y: 64
      },
      {
        x: 76,
        y: 107
      },
      {
        x: 11,
        y: 73
      },
      {
        x: 36,
        y: 25
      },
      {
        x: 75,
        y: 104
      },
      {
        x: 87,
        y: 64
      },
      {
        x: 46,
        y: 113
      }
    ]
  },
  {
    id: 'group D',
    data: [
      {
        x: 55,
        y: 17
      },
      {
        x: 58,
        y: 116
      },
      {
        x: 4,
        y: 7
      },
      {
        x: 6,
        y: 16
      },
      {
        x: 44,
        y: 32
      },
      {
        x: 75,
        y: 86
      },
      {
        x: 48,
        y: 61
      },
      {
        x: 78,
        y: 33
      },
      {
        x: 9,
        y: 110
      },
      {
        x: 83,
        y: 100
      },
      {
        x: 9,
        y: 66
      },
      {
        x: 46,
        y: 76
      },
      {
        x: 92,
        y: 39
      },
      {
        x: 14,
        y: 32
      },
      {
        x: 25,
        y: 109
      },
      {
        x: 11,
        y: 87
      },
      {
        x: 19,
        y: 116
      },
      {
        x: 21,
        y: 65
      },
      {
        x: 9,
        y: 64
      },
      {
        x: 7,
        y: 95
      },
      {
        x: 47,
        y: 103
      },
      {
        x: 39,
        y: 21
      },
      {
        x: 6,
        y: 50
      },
      {
        x: 83,
        y: 24
      },
      {
        x: 91,
        y: 17
      },
      {
        x: 22,
        y: 25
      },
      {
        x: 89,
        y: 49
      },
      {
        x: 15,
        y: 32
      },
      {
        x: 60,
        y: 7
      },
      {
        x: 42,
        y: 120
      },
      {
        x: 25,
        y: 33
      },
      {
        x: 43,
        y: 15
      },
      {
        x: 33,
        y: 104
      },
      {
        x: 52,
        y: 23
      },
      {
        x: 83,
        y: 34
      },
      {
        x: 74,
        y: 29
      },
      {
        x: 82,
        y: 16
      },
      {
        x: 2,
        y: 7
      },
      {
        x: 57,
        y: 111
      },
      {
        x: 79,
        y: 21
      },
      {
        x: 48,
        y: 116
      },
      {
        x: 74,
        y: 30
      },
      {
        x: 10,
        y: 105
      },
      {
        x: 13,
        y: 86
      },
      {
        x: 80,
        y: 109
      },
      {
        x: 53,
        y: 89
      },
      {
        x: 26,
        y: 65
      },
      {
        x: 87,
        y: 115
      },
      {
        x: 98,
        y: 16
      },
      {
        x: 54,
        y: 102
      }
    ]
  },
  {
    id: 'group E',
    data: [
      {
        x: 5,
        y: 110
      },
      {
        x: 75,
        y: 98
      },
      {
        x: 7,
        y: 94
      },
      {
        x: 36,
        y: 49
      },
      {
        x: 45,
        y: 13
      },
      {
        x: 76,
        y: 80
      },
      {
        x: 42,
        y: 88
      },
      {
        x: 68,
        y: 120
      },
      {
        x: 83,
        y: 57
      },
      {
        x: 86,
        y: 78
      },
      {
        x: 28,
        y: 7
      },
      {
        x: 33,
        y: 22
      },
      {
        x: 9,
        y: 46
      },
      {
        x: 94,
        y: 72
      },
      {
        x: 14,
        y: 19
      },
      {
        x: 83,
        y: 83
      },
      {
        x: 16,
        y: 113
      },
      {
        x: 37,
        y: 28
      },
      {
        x: 43,
        y: 50
      },
      {
        x: 94,
        y: 12
      },
      {
        x: 95,
        y: 10
      },
      {
        x: 13,
        y: 2
      },
      {
        x: 86,
        y: 56
      },
      {
        x: 80,
        y: 41
      },
      {
        x: 88,
        y: 40
      },
      {
        x: 72,
        y: 33
      },
      {
        x: 59,
        y: 115
      },
      {
        x: 74,
        y: 47
      },
      {
        x: 46,
        y: 63
      },
      {
        x: 24,
        y: 8
      },
      {
        x: 44,
        y: 68
      },
      {
        x: 72,
        y: 89
      },
      {
        x: 54,
        y: 118
      },
      {
        x: 92,
        y: 61
      },
      {
        x: 56,
        y: 27
      },
      {
        x: 42,
        y: 100
      },
      {
        x: 84,
        y: 37
      },
      {
        x: 32,
        y: 115
      },
      {
        x: 30,
        y: 25
      },
      {
        x: 47,
        y: 58
      },
      {
        x: 59,
        y: 24
      },
      {
        x: 10,
        y: 112
      },
      {
        x: 46,
        y: 7
      },
      {
        x: 59,
        y: 72
      },
      {
        x: 23,
        y: 47
      },
      {
        x: 63,
        y: 96
      },
      {
        x: 91,
        y: 5
      },
      {
        x: 23,
        y: 22
      },
      {
        x: 5,
        y: 102
      },
      {
        x: 70,
        y: 7
      }
    ]
  }
]*/

const ScatterPlot = ({ data, config }) => {
  let axisLeftCount = 0
  let axisBottomCount = 0

  return (
    <ResponsiveScatterPlot
      data={data}
      theme={theme}
      colors={config?.colors ?? { scheme: 'dark2' }}
      margin={{ top: 8, right: 36, bottom: 64, left: 56 }}
      xScale={{
        type: config?.xScale?.type ?? 'linear',
        min: 'auto',
        max: 'auto'
      }}
      xFormat='>-.2f'
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto'
      }}
      yFormat='>-.2f'
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        format: function (value) {
          axisBottomCount++
          if (axisBottomCount % 2 === 0) {
            return value
          }
          return ''
        }
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        format: function (value) {
          axisLeftCount++
          if (axisLeftCount % 2 === 0) {
            return value
          }
          return ''
        }
      }}
      enableCrosshair={true}
      legends={[
        {
          anchor: 'bottom',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: 56,
          itemWidth: 100,
          itemHeight: 12,
          itemsSpacing: 4,
          symbolSize: 12,
          symbolShape: 'circle',
          itemDirection: 'left-to-right',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
      layers={
        config?.layers ?? [
          'grid',
          'axes',
          'nodes',
          'markers',
          'mesh',
          'legends',
          'annotations'
        ]
      }
    />
  )
}

export default ScatterPlot

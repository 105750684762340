import { Fragment } from 'react'
import { I18n } from 'aws-amplify'

import { Box, Flex, Text } from '@/primitives'
import PieGraph from '@/elements/Nivo/Pie'
import { getRanges } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'

export default function Pie({ data, config, unit }) {
  const hasData = data.some(({ data }) => data.length > 0)

  return (
    <Fragment>
      <Flex
        className={'MosaicV2__Tile__Content__SubTitle'}
        alignMainAxis='space-between'
        axisGap={400}
      >
        <Text fontFamily={'mono'} size={200}>
          {getRanges(config.range)?.label}
        </Text>
        <Text fontFamily={'mono'} size={200}>
          {unit || ''}
        </Text>
      </Flex>

      <Box className={'MosaicV2__Tile__Content__Graph'}>
        <Box className={'MosaicV2__Tile__Content__Chart'}>
          {data.length > 0 && hasData && <PieGraph data={data} legends={[]} />}
          {(data.length === 0 || !hasData) && (
            <Flex
              alignCrossAxis='center'
              alignMainAxis='center'
              style={{ flex: 1, height: '100%' }}
            >
              <Text
                fontFamily={'mono'}
                variant={'primary'}
                tone={500}
                size={600}
                fontWeight={700}
              >
                {I18n.get('No data')}
              </Text>
            </Flex>
          )}
        </Box>
      </Box>
    </Fragment>
  )
}

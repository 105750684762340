import { Box, Text } from '@/primitives'

export default function CrumbTitle({ title, to, lastItem }) {
  return (
    <Box>
      <Text
        className='SlimNavBreadcrumb__CrumbTitle'
        size={200}
        variant='page'
        tone={lastItem ? 700 : 600}
        fontWeight={lastItem ? 700 : 300}
      >
        {title}
      </Text>
    </Box>
  )
}

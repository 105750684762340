/* This is specifically to handle data and dash */
export const isExact = ({ to, exact }) => {
  if (exact !== null) {
    return exact
  }
  if (to.includes('zones') && !to.includes('admin')) {
    return true
  }
  return false
}

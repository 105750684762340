import { I18n } from 'aws-amplify'

const Strings = () => ({
  manageInventoryCalibrations: I18n.get('Manage Inventory Calibrations'),
  createInventoryCalibration: I18n.get('Calibrate'),
  createFormHeader: I18n.get('Create New Calibration'),
  noInventoryCalibrations: I18n.get('No inventory calibrations'),
  saveInventoryCalibration: I18n.get('Save Calibration'),
  selectDefault: I18n.get('Please Select'),
  inventoryCalibration: I18n.get('Inventory Calibration'),
  calibration: I18n.get('Calibration'),
  inventory: I18n.get('Inventory'),
  produce: I18n.get('Produce'),
  variety: I18n.get('Variety'),
  grade: I18n.get('Grade'),
  newWeight: I18n.get('New Weight'),
  oldWeight: I18n.get('Old Weight'),
  currentWeight: I18n.get('Current Weight'),
  weight: I18n.get('Weight'),
  kilograms: I18n.get('kg'),
  change: I18n.get('Change'),
  reason: I18n.get('Reason'),
  date: I18n.get('Date')
})

export default Strings

import { widgetRegistry } from './index'
import { SCREEN_SIZE_BREAKPOINTS } from '../config'
import { CHARTS } from './Shared/Dynamic/config'

/**
 * Converts a position key and widget props to an object containing position information.
 * - If a widget does not have a position for the specified breakpoint, it will use the first defined position.
 * @param {Object} widgetProps - Object containing widget properties.
 * @param {string} widgetProps.id - The ID of the widget.
 * @param {Object} widgetProps.position - An object containing the widget's position information.
 * @param {Object} widgetProps.defaultPosition - An object containing the widget's default position information.
 * @param {string} breakpointKey - The breakpoint key to use.
 * @returns {Object} - An object containing the widget ID and position information at the specified position key.
 */
function getGridLayoutObject(widgetProps, breakpointKey) {
  const X_INDEX = 0
  const Y_INDEX = 1
  const WIDTH_INDEX = 2
  const HEIGHT_INDEX = 3

  const { id, position, defaultPosition, config, widgetMode } = widgetProps

  const chartMinSize = CHARTS?.[config?.chart]?.minSize?.[breakpointKey]

  let positionArray =
    position?.[breakpointKey] ??
    defaultPosition?.[breakpointKey] ??
    Object.values(position)[0]

  if (chartMinSize && widgetMode !== 'edit') {
    let chartW =
      positionArray[WIDTH_INDEX] < chartMinSize.minW ||
      !position?.[breakpointKey]
        ? chartMinSize.minW
        : positionArray[WIDTH_INDEX]

    let chartH =
      positionArray[HEIGHT_INDEX] < chartMinSize.minH ||
      !position?.[breakpointKey]
        ? chartMinSize.minH
        : positionArray[HEIGHT_INDEX]

    positionArray = [
      positionArray[X_INDEX],
      positionArray[Y_INDEX],
      chartW,
      chartH,
      chartMinSize.minW,
      chartMinSize.minH
    ]
  }

  const [x, y, w, h, minW, minH] = positionArray
  return { i: id, x, y, w, h, minH, minW }
}

/**
 * Converts a Map of widgets to a layout object for use with react-grid-layout.
 * @param {Map} widgets - A Map of widgets.
 * @returns {Object} - An object containing the layout for mobile and desktop.
 */
export function widgetsToLayouts(widgets) {
  const widgetsArray = Array.from(widgets.values())
  let layouts = {}

  for (const breakpointKey in SCREEN_SIZE_BREAKPOINTS) {
    layouts[breakpointKey] = []
    for (let widget of widgetsArray) {
      const definition = widgetRegistry.get(widget.props.widgetId)
      layouts[breakpointKey].push(
        getGridLayoutObject(
          { ...widget.props, defaultPosition: definition.defaultPosition },
          breakpointKey
        )
      )
    }
  }

  return layouts
}

/**
 * Get the grid position for a tile used by ResponsiveReactGridLayout.
 * - If a tile does not have a position for the specified breakpoint, it will use the first defined position.
 * @param {Object} position - An object containing the tile's position information.
 * @param {string} breakpointKey - The breakpoint key to use.
 * @returns {Object} - An object containing the tile's position information.
 */
export function getGridPosition(widgetId, id, position, breakpointKey) {
  const { defaultPosition } = widgetRegistry.get(widgetId)
  let positionArray =
    position?.[breakpointKey] ??
    defaultPosition?.[breakpointKey] ??
    Object.values(position)[0]

  const [x, y, w, h, minW, minH] = positionArray
  return { x, y, w, h, minH, minW, i: id }
}

/**
 * Reverse the x values of widgets horizontally with respect to the maximum number of columns.
 * @param {Array} widgets - An array of widgets.
 * @param {number} maxColumns - The maximum number of columns.
 * @returns {Array} - An array of widgets with their x values reversed.
 */
export function reverseXValuesHorizontally(widgets, maxColumns) {
  return widgets.map(widget => {
    const newX = maxColumns - widget.w - widget.x
    return {
      ...widget,
      x: newX
    }
  })
}

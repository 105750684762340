import { useEffect, useReducer, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import Header from './Header'

import { FlexV2, Separator, Loader } from '@/primitives'
import { Tabs } from '@/elements'

import { fetchPingStatuses } from '@/slices/pinger'

import {
  getCurrentUser,
  getZoneHierarchy,
  getDeviceStatuses,
  getDeviceStatusesLoading
} from '@/reducers/selectors'

import {
  RESOURCE_TYPE_DEVICE,
  RESOURCE_TYPE_DEVICE_MANAGEMENT,
  hasEditPermissions,
  getIsGodMode
} from '@/Util/PermissionUtils'
import {
  AVAILABLE_FEATURE_FLAGS,
  ENABLE_FLEET_MANAGEMENT,
  hasFeatureFlagEnabled
} from '@/Util/FeatureFlagsUtils'
import ZoneUtils from '@/Util/ZoneUtils'
import { getAllDevices } from './utils'
import { BULK_ACTION_OPTIONS, DIAGNOSTICS_ACTIONS } from './config'

import { useAuth } from '@/contexts/auth-context'
import { FleetManagementContext } from './context'

import {
  reducer,
  initialState,
  SET_INIT,
  TAB_ID,
  UPDATE_STATUSES,
  CLEAR_SELECTION,
  BULK_ACTION
} from './state'
import { TAB_CONFIGS } from './config'

import './index.scss'

const Container = ({ selectedZone }) => {
  const dispatch = useDispatch()
  const auth = useAuth()

  const params = useParams()
  const history = useHistory()

  const userId = auth.getCognitoId()
  const coretexUser = getCurrentUser()
  const loading = getDeviceStatusesLoading()
  const hierarchy = getZoneHierarchy()
  const deviceStatuses = getDeviceStatuses()
  const isGodMode = getIsGodMode(coretexUser)

  const [state, dispatchState] = useReducer(reducer, initialState)
  const [tabIndex, setTabIndex] = useState(0)
  const [deviceStatusesUpdated, setDeviceStatusesUpdated] = useState(false)

  const { tabId } = TAB_CONFIGS[tabIndex]

  const enableFleetManagement = hasFeatureFlagEnabled(
    coretexUser,
    AVAILABLE_FEATURE_FLAGS[ENABLE_FLEET_MANAGEMENT],
    Object.keys(hierarchy).length > 0 ? hierarchy.organizationId : null
  )

  const getShowFleetManagement = coretexUser => {
    const hasFleetEditPermissions = hasEditPermissions(
      coretexUser,
      RESOURCE_TYPE_DEVICE,
      RESOURCE_TYPE_DEVICE_MANAGEMENT
    )

    return (hasFleetEditPermissions && enableFleetManagement) || isGodMode
  }

  const displayFleetManagement = getShowFleetManagement(coretexUser)

  useEffect(() => {
    if (coretexUser?.userName && !displayFleetManagement) {
      history.replace(`/admin${ZoneUtils.getZoneBasePath(params)}/details`)
    }
  }, [coretexUser?.userName, displayFleetManagement, history, params])

  useEffect(() => {
    const siteId = selectedZone?.id
    if (hierarchy.hasOwnProperty(siteId)) {
      const sensorsByZone = getAllDevices(hierarchy[siteId])
      const deviceIds = sensorsByZone.reduce((acc, zone) => {
        const { id, type, sensors } = zone
        if (type === 'greengrass') {
          acc.push(id)
        }

        const sensorIds = sensors.map(({ id }) => id)
        return [...acc, ...sensorIds]
      }, [])

      dispatch(fetchPingStatuses({ deviceIds }))
      dispatchState({
        type: SET_INIT,
        payload: {
          siteId,
          tabId: 'greengrass',
          sensorsByZone
        }
      })
    }
  }, [dispatch, userId, hierarchy, selectedZone])

  useEffect(() => {
    if (deviceStatuses?.length > 0 && !deviceStatusesUpdated) {
      dispatchState({
        type: UPDATE_STATUSES,
        payload: {
          deviceStatuses,
          tabId
        }
      })
      setDeviceStatusesUpdated(true)
    }
  }, [deviceStatuses, deviceStatusesUpdated, tabId])

  const onSwitchTab = index => {
    setTabIndex(index)
    const newTabId = TAB_CONFIGS[index].tabId
    dispatchState({
      type: TAB_ID,
      payload: {
        tabId: newTabId
      }
    })
    dispatchState({
      type: CLEAR_SELECTION
    })

    const options = BULK_ACTION_OPTIONS.concat(DIAGNOSTICS_ACTIONS)

    const bulkAction = options.find(({ value }) => {
      const valueToCompare = newTabId === 'greengrass' ? 'core' : 'ota_sensor'
      return value === valueToCompare
    })

    dispatchState({ type: BULK_ACTION, payload: { bulkAction } })
  }

  return (
    <FleetManagementContext.Provider value={{ state, dispatchState }}>
      <Loader isLoading={loading} style={{ overflow: 'visible' }}>
        <FlexV2 direction='column' axisGap={300}>
          <Header selectedZone={selectedZone} />
          <Separator />
          <div className='FleetManagement__Tabs'>
            <Tabs
              activeIndex={tabIndex}
              onSwitchTab={onSwitchTab}
              tabConfigs={TAB_CONFIGS}
            />
          </div>
        </FlexV2>
      </Loader>
    </FleetManagementContext.Provider>
  )
}

export default Container

import { Fragment } from 'react'
import OrganizationsFilter from '../../../Shared/Filters/OrganizationsFilter'
import SitesFilter from '../../../Shared/Filters/SitesFilter'
import ProducesFilter from '../../../Shared/Filters/ProducesFilter'
import VarietiesFilter from '../../../Shared/Filters/VarietiesFilter'
import EntriesFilter from '../../../Shared/Filters/EntriesFilter'

import { CROP_STATUSES } from '../utils'

import Strings from '../Strings'

const ProductFilter = ({ state, dispatchState }) => {
  const strings = Strings()
  const statuses = Object.entries(CROP_STATUSES(strings))

  return (
    <Fragment>
      <OrganizationsFilter state={state} dispatchState={dispatchState} />
      <SitesFilter state={state} dispatchState={dispatchState} />
      <ProducesFilter state={state} dispatchState={dispatchState} />
      <VarietiesFilter state={state} dispatchState={dispatchState} />
      <EntriesFilter
        state={state}
        dispatchState={dispatchState}
        stateKey='statuses'
        entries={statuses}
        defaultText={strings.allStatuses}
      />
    </Fragment>
  )
}

export default ProductFilter

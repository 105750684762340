import { Box, Text, Button } from '@/primitives'

import Strings from '../../Strings'

export default function TooMuchDataCard({ onClickDownload }) {
  const strings = Strings()

  return (
    <Box className='Historic__Chart__Card' style={{ marginTop: '1em' }}>
      <Text as='h3' fontWeight={700}>
        {strings.tooMuchData}
        <Button
          iconBefore='download'
          variant='info'
          size='small'
          onClick={onClickDownload}
          style={{ marginLeft: '2em' }}
        >
          {strings.downloadData}
        </Button>
      </Text>
    </Box>
  )
}

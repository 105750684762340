import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { getCognitoData, makeGraphqlRequest } from '../utils'

/*
 * Queries
 */

export const getFeatureFlags = async (params = {}) => {
  try {
    const query = /* GraphQL */ `
      query getFeatureFlags {
        getFeatureFlags {
          featureFlagId
          name
          type
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)

    const { data } = await makeGraphqlRequest(GRAPHQL_URL, operation, idToken)

    if (!data?.getFeatureFlags) {
      throw Error('Unable to fetch feature flags')
    }

    return { featureFlags: data?.getFeatureFlags, error: null }
  } catch (err) {
    throw err
  }
}

export const getFeatureFlagsForObject = async (params = {}) => {
  try {
    const query = /* GraphQL */ `
      query getFeatureFlagsForObject($objectId: String!) {
        getFeatureFlagsForObject(objectId: $objectId) {
          featureFlagId
          objectId
          type
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)

    const { data } = await makeGraphqlRequest(GRAPHQL_URL, operation, idToken)

    if (!data?.getFeatureFlagsForObject) {
      throw Error('Unable to fetch feature flags')
    }

    return { attachments: data?.getFeatureFlagsForObject, error: null }
  } catch (err) {
    throw err
  }
}

/*
 * Mutations
 */

export const attachFeatureFlag = async (params = {}) => {
  try {
    const mutation = /* GraphQL */ `
      mutation attachFeatureFlag($objectId: String!, $featureFlagId: String!) {
        attachFeatureFlag(objectId: $objectId, featureFlagId: $featureFlagId) {
          featureFlagId
          objectId
          type
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)

    const { data } = await makeGraphqlRequest(GRAPHQL_URL, operation, idToken)

    if (!data?.attachFeatureFlag) {
      throw Error('Unable to attach feature flag')
    }

    return { attachment: data?.attachFeatureFlag, error: null }
  } catch (err) {
    throw err
  }
}

export const removeFeatureFlag = async (params = {}) => {
  try {
    const mutation = /* GraphQL */ `
      mutation removeFeatureFlag($objectId: String!, $featureFlagId: String!) {
        removeFeatureFlag(objectId: $objectId, featureFlagId: $featureFlagId) {
          featureFlagId
          objectId
          type
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)

    const { data } = await makeGraphqlRequest(GRAPHQL_URL, operation, idToken)

    if (!data?.removeFeatureFlag) {
      throw Error('Unable to remove feature flag')
    }

    return { attachment: data?.removeFeatureFlag, error: null }
  } catch (err) {
    throw err
  }
}

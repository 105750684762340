import { environmentSection } from './environment'
import { fertigationSection } from './fertigation'
import { operationsSection } from './operations'
import { miscellaneousSection } from './miscellaneous'
import { resourcesSection } from './resources'
import { plantScienceSection } from './plantScience'

export const TOOLBOX_SECTIONS = () => [
  environmentSection(),
  fertigationSection(),
  plantScienceSection(),
  resourcesSection(),
  operationsSection(),
  miscellaneousSection()
]

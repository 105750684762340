import { Button } from '@/primitives'

const AddRemoveButton = ({ index, addRow, removeRow }) => {
  const isFirstRow = index === 0

  const onClickRemove = () => removeRow(index)

  return (
    <Button
      size='small'
      iconBefore={isFirstRow ? 'add' : 'remove'}
      variant={isFirstRow ? 'primary' : 'error'}
      name={isFirstRow ? 'add' : 'remove'}
      onClick={isFirstRow ? addRow : onClickRemove}
    />
  )
}

export default AddRemoveButton

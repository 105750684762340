import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Box, Text, Slot, Flex, Button, Input, Loader } from '@/primitives'
import { Dialog } from '@/elements'

import {
  sendRegenerateApiKey,
  cleanApiKey
} from '@/slices/timestream/usagePlan'

import { getApiKey, getApiKeyRegenerating } from '@/reducers/selectors'

import Strings from './Strings'

const RegenerateApiKeyConfirm = ({
  show,
  setShow,
  organizationId,
  orgName
}) => {
  const strings = Strings()

  const dispatch = useDispatch()

  const newApiKey = getApiKey()
  const loading = getApiKeyRegenerating()

  const [deleteText, setDeleteText] = useState('')

  useEffect(() => {
    return () => {
      setDeleteText('')
      dispatch(cleanApiKey())
    }
  }, [dispatch])

  useEffect(() => {
    if (newApiKey?.value) {
      setDeleteText('')
      setShow(false)
    }
  }, [newApiKey?.value])

  const onClickClose = () => {
    setDeleteText('')
    setShow(false)
  }

  const onChangeDeleteInput = e => {
    setDeleteText(e.target.value.toLowerCase())
  }

  const onClickRegenerate = () => {
    dispatch(sendRegenerateApiKey({ organizationId }))
  }

  return (
    <Dialog open={show} onOpenChange={onClickClose}>
      <Slot name='title'>
        <Flex direction='column' style={{ marginBottom: '1em' }}>
          <Text as='h5' style={{ marginBottom: '0.5em' }}>
            {strings.generateNewKey}
          </Text>
          <Text as='p' size={100}>
            {`${strings.generateNewKeySubtitle} ${orgName}`}
          </Text>
        </Flex>
      </Slot>
      <Slot name='content'>
        <Box style={{ marginBottom: '2rem' }}>
          <Text size={200} variant='danger' tone={600} fontWeight={700}>
            {`${strings.warning}: `}
          </Text>

          <Text size={200} variant='danger' tone={600}>
            {strings.warningDescription}
          </Text>
        </Box>
        <Text>{`${strings.regenerateConfirmFragment1} `}</Text>
        <Text fontWeight={600} variant='danger' tone={600}>
          {strings.delete}
        </Text>
        <Text>{` ${strings.regenerateConfirmFragment2} `}</Text>
        <Text fontWeight={600} variant='danger' tone={600}>
          {strings.regenerateConfirmButton}
        </Text>
        <Text>.</Text>
        <Input
          style={{
            borderColor: 'var(--ctx-theme-color-page-400',
            marginTop: '1rem'
          }}
          placeholder={`${strings.inputDeletePlaceholder} '${strings.delete}'`}
          name='delete'
          value={deleteText}
          onChange={onChangeDeleteInput}
        />
      </Slot>
      <Slot name='actions'>
        <Loader isLoading={loading}>
          <Flex alignMainAxis='space-between'>
            <Button variant='neutral' onClick={onClickClose}>
              {strings.cancel}
            </Button>
            <Button
              type='submit'
              variant='danger'
              disabled={strings.delete.toLowerCase() !== deleteText}
              onClick={onClickRegenerate}
            >
              {strings.regenerateConfirmButton}
            </Button>
          </Flex>
        </Loader>
      </Slot>
    </Dialog>
  )
}

export default RegenerateApiKeyConfirm

import { call, put, select, takeLatest } from 'redux-saga/effects'

import {
  getProduces,
  getProduce,
  createProduce,
  updateProduce
} from '@/api/operations/produce'

import {
  REQUEST_ALL_PRODUCES,
  REQUEST_PRODUCES,
  REQUEST_PRODUCE,
  CREATE_PRODUCE,
  UPDATE_PRODUCE,
  receiveAllProduces,
  receiveProduces,
  receiveProduce,
  produceCreated,
  produceUpdated
} from '@/actions/operations/produce'

import { getCurrentUserOrganizations, displayBanner } from '../utils'
import { getOperationsAllProduces } from './utils'

import Strings from './Strings'

const strings = Strings()

function* sendRequestAllProduces(action) {
  const allProduces = yield select(getOperationsAllProduces)
  if (allProduces.length > 0) {
    yield put(receiveAllProduces(allProduces, allProduces.length, null))
  } else {
    const organizations = yield select(getCurrentUserOrganizations)
    const params = {
      filter: {
        organizationId: organizations.map(o => o.id)
      }
    }
    const { data, error = null } = yield call(getProduces, params)
    const { produces = [], count = 0 } = data
    yield put(receiveAllProduces(produces, count, error))
  }
}

export function* watchRequestAllProduces() {
  yield takeLatest(REQUEST_ALL_PRODUCES, sendRequestAllProduces)
}

function* sendCreateProduce(action) {
  const { data, error = null } = yield call(createProduce, action.params)
  yield put(displayBanner(error, strings.produceCreated))
  yield put(produceCreated(data, error))
}

export function* watchCreateProduce() {
  yield takeLatest(CREATE_PRODUCE, sendCreateProduce)
}

function* sendUpdateProduce(action) {
  const { data, error = null } = yield call(updateProduce, action.params)
  yield put(displayBanner(error, strings.produceUpdated))
  yield put(produceUpdated(data, error))
}

export function* watchUpdateProduce() {
  yield takeLatest(UPDATE_PRODUCE, sendUpdateProduce)
}

function* sendRequestProduce(action) {
  const { data: produce, error = null } = yield call(getProduce, action.params)
  yield put(receiveProduce(produce, error))
}

export function* watchRequestProduce() {
  yield takeLatest(REQUEST_PRODUCE, sendRequestProduce)
}

function* sendRequestProduces(action) {
  const { data, error = null } = yield call(getProduces, action.params)
  const { produces = [], count = 0 } = data
  yield put(receiveProduces(produces, count, error))
}

export function* watchRequestProduces() {
  yield takeLatest(REQUEST_PRODUCES, sendRequestProduces)
}

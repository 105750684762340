import { Button, Slot, Text } from '@/primitives'
import { Dialog } from '@/elements'
import DownloadForm from '../DownloadForm'
import Strings from '../../../Strings'

const DownloadModal = ({ device, showModal, toggleModal }) => {
  const onClickClose = e => {
    e.preventDefault()
    toggleModal()
  }
  const { deviceFirmwareGreengrass, deviceFirmwareDirectConnect, close } =
    Strings()

  return (
    <Dialog open={showModal} onOpenChange={toggleModal}>
      <Slot name='title'>
        <Text size={300} fontWeight={700}>
          {device?.isGreengrass
            ? deviceFirmwareGreengrass
            : deviceFirmwareDirectConnect}
        </Text>
      </Slot>
      <Slot name='content'>
        <DownloadForm device={device} />
      </Slot>
      <Slot name='actions'>
        <Button variant='page' onClick={onClickClose}>
          {close}
        </Button>
      </Slot>
    </Dialog>
  )
}

export default DownloadModal

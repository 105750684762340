import { useReducer } from 'react'
import { useDispatch } from 'react-redux'

import Container from './Container'

import { Button, Form, Input, Text, Loader, Label, Flex } from '@/primitives'

import { showBanner } from '@/slices/util'

import { useAuth } from '@/contexts/auth-context'

import { insertError, getInputClassName } from './Utils'

import { mfaCodeSchema } from '../../validations'

import Strings from './Strings'

const initialState = {
  code: '',
  loading: false,
  errors: {}
}

const reducer = (state, action) => {
  if (action.type === 'update') {
    if (action.name !== 'errors' && state.errors.hasOwnProperty(action.name)) {
      const errors = { ...state.errors }
      delete errors[action.name]
      return { ...state, errors, [action.name]: action.value }
    }

    return { ...state, [action.name]: action.value }
  }

  return state
}

const ConfirmSignIn = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const auth = useAuth()
  const reduxDispatch = useDispatch()
  const strings = Strings()

  function onChangeInput(e) {
    const { name, value } = e.target
    dispatch({ type: 'update', name, value })
  }

  const onConfirm = async e => {
    e.preventDefault()
    try {
      setLoading(true)
      await mfaCodeSchema.validate(state, { abortEarly: false })
      await auth.confirmSignIn(state.code)
    } catch (err) {
      let errors = {}

      if (err?.inner) {
        err.inner.forEach(innerErr => {
          errors[innerErr.path] = innerErr.message
        })
      }

      if (!err.inner && err.message) {
        reduxDispatch(
          showBanner({
            type: 'error',
            message: err.message,
            show: true
          })
        )
      }
      setLoading(false)
      dispatch({ type: 'update', name: 'code', value: initialState.code })
      setErrors(errors)
    }
  }

  const setErrors = (errors = {}) => {
    dispatch({ type: 'update', name: 'errors', value: errors })
  }

  const setLoading = loading => {
    dispatch({ type: 'update', name: 'loading', value: loading })
  }

  return (
    <Loader isLoading={state.loading}>
      <Container title={strings.confirmSignInTitle}>
        <Text
          size={100}
          variant='page'
          tone={900}
          style={{ marginBottom: '2rem' }}
        >
          {strings.confirmSignInMessage}
        </Text>
        <Form onSubmit={onConfirm} className='Auth__Form'>
          <Flex direction='column'>
            <Label className='Auth__Form__Label'>{strings.code}</Label>
            <Input
              className={getInputClassName('code', state.errors)}
              type='text'
              name='code'
              value={state.code}
              onChange={onChangeInput}
            />
            {insertError(state?.errors?.code)}
          </Flex>
          <Button
            type='submit'
            variant='primary'
            className='Auth__Form__PrimaryButton'
          >
            {strings.submitBtn}
          </Button>
        </Form>
      </Container>
    </Loader>
  )
}

export default ConfirmSignIn

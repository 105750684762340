import { I18n } from 'aws-amplify'
import { object, string, date } from 'yup'

export const FIELDS = {
  id: 'id',
  organizationId: 'organizationId',
  name: 'name',
  code: 'code',
  status: 'status',
  onboardDate: 'onboardDate',
  contactName: 'contactName',
  contactNumber: 'contactNumber',
  contactEmail: 'contactEmail',
  customerId: 'customerId',
  countryId: 'countryId',
  regionId: 'regionId',
  cityId: 'cityId',
  areaId: 'areaId',
  currencyId: 'currencyId',
  paymentMethodId: 'paymentMethodId',
  paymentTermId: 'paymentTermId'
}

export const SCHEMA = () => {
  return object().shape({
    organizationId: string().required(I18n.get('An organization is required.')),
    name: string().required(I18n.get('Name is required.')),
    code: string().required(I18n.get('Please provide a code.')),
    status: string().required('Status is required'),
    onboardDate: date()
      .typeError('An onboarding date is required.')
      .max(
        new Date(),
        I18n.get('The onboarding date cannot be set in the future.')
      )
      .required(I18n.get('An onboarding date is required.')),
    contactName: string().required(I18n.get('Contact name is required.')),
    contactNumber: string().required(I18n.get('Contact number is required.')),
    contactEmail: string()
      .email('Contact email is invalid')
      .required(I18n.get('Contact email is required.')),
    customerId: string().required(I18n.get('A customer must be selected.')),
    countryId: string().required(I18n.get('A country must be selected.')),
    regionId: string().required(I18n.get('A region must be selected.')),
    cityId: string().required(I18n.get('A city must be selected.')),
    areaId: string().required(I18n.get('An area must be selected.')),
    currencyId: string().required(I18n.get('A currency must be selected.')),
    paymentMethodId: string().required(
      I18n.get('A payment method must be selected.')
    ),
    paymentTermId: string().required(
      I18n.get('A payment term must be selected.')
    )
  })
}

import { Fragment } from 'react'
import { I18n } from 'aws-amplify'

import { Box, Flex, Text } from '@/primitives'
import Bar from '@/elements/Nivo/Bar'
import { getRanges } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'
import { defaultBottomAxisOptions, defaultLeftAxisOptions } from './utils'

export default function BarChart({ options, data, unit, config }) {
  const { bottomAxis, leftAxis, keys } = options

  const axisBottom = Object.assign({}, defaultBottomAxisOptions, bottomAxis)
  const axisLeft = Object.assign({}, defaultLeftAxisOptions, leftAxis)

  const hasData = data.some(({ data }) => data.length > 0)

  return (
    <Fragment>
      <Flex
        className={'MosaicV2__Tile__Content__SubTitle'}
        alignMainAxis='space-between'
        axisGap={400}
      >
        <Text fontFamily={'mono'} size={200}>
          {getRanges(config.range)?.label}
        </Text>
        <Text fontFamily={'mono'} size={200}>
          {unit || ''}
        </Text>
      </Flex>

      <Box className={'MosaicV2__Tile__Content__Graph'}>
        <Box className={'MosaicV2__Tile__Content__Chart'}>
          {data.length > 0 && hasData && (
            <Bar //wrapper around @nivo/bar
              data={data}
              keys={keys}
              axisBottom={axisBottom}
              axisLeft={axisLeft}
            />
          )}
          {(data.length === 0 || !hasData) && (
            <Flex
              alignCrossAxis='center'
              alignMainAxis='center'
              style={{ flex: 1, height: '100%' }}
            >
              <Text
                fontFamily={'mono'}
                variant={'primary'}
                tone={500}
                size={600}
                fontWeight={700}
              >
                {I18n.get('No data')}
              </Text>
            </Flex>
          )}
        </Box>
      </Box>
    </Fragment>
  )
}

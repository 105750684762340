import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { getVideoStream } from '@/api/video/stream'

export const cleanVideoStream = createAsyncThunk(
  'cleanVideoStream',
  streamName => {
    return { streamName }
  }
)

export const fetchVideoStream = createAsyncThunk(
  'fetchVideoStream',
  async params => {
    const { url, error } = await getVideoStream(params)
    const { streamName } = params
    return { streamName, url, error }
  }
)

const videoStreamSlice = createSlice({
  name: 'videoStreamReducer',
  initialState: {
    streams: [],
    error: null,
    loading: false
  },
  extraReducers: builder => {
    builder
      .addCase(cleanVideoStream.fulfilled, (state, action) => {
        state.error = null
        state.loading = false
        state.streams = state.streams.filter(
          ({ streamName }) => streamName !== action.payload.streamName
        )
      })
      .addCase(fetchVideoStream.pending, state => {
        state.error = null
        state.loading = true
      })
      .addCase(fetchVideoStream.fulfilled, (state, action) => {
        let streams = [...state.streams]

        const stream = {
          streamName: action.payload.streamName,
          url: action?.payload?.url ?? null,
          error: action?.payload?.error ?? null
        }

        const index = streams.findIndex(
          ({ streamName }) => streamName === action.payload.streamName
        )

        if (index > -1) {
          streams[index] = stream
        } else {
          streams.push(stream)
        }

        state.streams = streams
        state.error = action?.payload?.error ?? null
        state.loading = false
      })
  }
})

export default videoStreamSlice.reducer

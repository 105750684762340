import { I18n } from 'aws-amplify'

const Strings = () => ({
  cancelText: I18n.get('Cancel'),
  deleteText: I18n.get('Delete'),
  deleteTitleText: I18n.get('Delete notification for'),
  deleteSubtitleText: I18n.get('This action cannot be undone.'),
  lower: I18n.get('is below threshold'),
  upper: I18n.get('is above threshold'),
  for: I18n.get('for'),
  in: I18n.get('in'),
  notification: I18n.get('notification'),
  caution: I18n.get('caution'),
  warning: I18n.get('warning'),
  snooze: I18n.get('Snooze'),
  snoozedText: I18n.get('Snoozed'),
  minute: I18n.get('min'),
  minutes: I18n.get('mins')
})

export default Strings
import { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import LocationSelector from './LocationSelector'
import SensorTag from './SensorTag'

import { getDevice, getCurrentUserOrganizations, getZonesHierarchy } from '@/reducers/selectors'
import { Flex, Icon, Text } from '@/primitives'
import { getZone, getZoneName } from '@/Util/ZoneUtils'

import './index.scss'
import { setDevice } from '@/slices/management/device'

export default function NavigationBreadcrumbsV2() {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()

  const [breadcrumbs, setBreadcrumbs] = useState([])

  const sensorId = params?.sensorId ?? null
  const zonePath = params?.zone

  const userOrganizations = getCurrentUserOrganizations()
  const zonesHierarchy = getZonesHierarchy() // Note: currently only supports active zones
  const device = getDevice()

  const pathItems = zonePath.split('/')
  const siteId = pathItems[0]
  const currentLocationId = pathItems[pathItems.length - 1]
  const zone = getZone(zonesHierarchy, siteId, currentLocationId)

  const org = userOrganizations?.find(({id }) => id === zonesHierarchy[siteId]?.organizationId)
  const showSelector = !sensorId && (Object.keys(zone?.children ?? {})?.length > 0 || zone?.devices?.length > 0)

  useEffect(() => {
    if (sensorId && (device?.id !== sensorId) && zone?.devices?.length > 0) {
      const sensor = zone?.devices?.find(({ id }) => id === sensorId)
      dispatch(setDevice(sensor))
    }
  }, [sensorId, device?.id, dispatch, zone?.devices?.length])

  useEffect(() => {
    if (Object?.keys(zonesHierarchy)?.length > 0) {
      const orgBreadcrumb = { id: org?.id, name: org?.name }
      const zoneBreadcrumbs = pathItems?.map(item => (
        {
          id: item,
          name: getZoneName(zonesHierarchy, siteId, item)
        }
      ))

      let allBreadcrumbs = [ orgBreadcrumb, ...zoneBreadcrumbs ]

      if (sensorId && (device?.id === sensorId)) {
        const sensorBreadcrumb = { id: device?.id, name: device?.name }
        allBreadcrumbs = [ ...allBreadcrumbs, sensorBreadcrumb]
      }

      const notFound = allBreadcrumbs?.findIndex(({ name }) => !name)
      const validBreadcrumbs = notFound > -1 ? allBreadcrumbs?.slice(0, notFound) : allBreadcrumbs
      setBreadcrumbs(validBreadcrumbs)
    }
  }, [zonesHierarchy, org, device, zone?.id, sensorId])

  function insertBreadcrumb(id, name) {
    if (!id || !name) return null

    const hideChevron = sensorId ? (id === device?.id) : (id === currentLocationId)
    const isLastItem = id === breadcrumbs[breadcrumbs?.length - 1]?.id
    const index = pathItems.indexOf(id)
    const currentPathItems = index > -1 ? pathItems.slice(0, index + 1) : []
    let linkTo = '/zones'
    currentPathItems.forEach(item => linkTo = `${linkTo}/${item}`)

    function onClick() {
      if (!isLastItem) {
        history.push(linkTo)
      }
    }

    return (
      <Flex key={`breadcrumb-${id}`} axisGap={300}>
        <Flex direction='column'>
          <Text className={isLastItem ? '' : 'BreadcrumbLink'} onClick={onClick} variant='page' tone={isLastItem ? 700 : 600} fontWeight={isLastItem ? 700 : 300}>{name}</Text>
           {id === device?.id && <SensorTag tag={device?.tag}/>}
        </Flex>
        {(!hideChevron || showSelector) && <Icon name='chevron_right' variant='page' tone={400}/>}
      </Flex>
    )
  }

  function getLastValidZone() {
    if (Object?.keys(zonesHierarchy)?.length > 0) {
      if (zone?.id) return zone
      if (device?.id) return {}

      const parentZoneId = breadcrumbs[breadcrumbs?.length - 1]?.id
      const parentZone = getZone(zonesHierarchy, siteId, parentZoneId)
      return parentZone
    }
  }

  return (
    <Flex axisGap={300} style={{ margin: '0.4rem 0', minHeight: '2.6rem'}}>
      {breadcrumbs.map(({ id, name }) => insertBreadcrumb(id, name))}
      {!sensorId && <LocationSelector zone={getLastValidZone()} />}
    </Flex>
  )
}


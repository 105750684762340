import { I18n } from 'aws-amplify'
import { Button, Flex, Text } from '@/primitives'

import './index.scss'

const Strings = () => ({
  title: I18n.get('Edit Widget'),
  subtitle: I18n.get('Configure the widget settings.'),
  save: I18n.get('Save'),
  cancel: I18n.get('Cancel')
})

export default function EditLayout({
  title = null,
  subtitle = null,
  children = null,
  onSave = () => {},
  onCancel = () => {},
  disableCancel = false
}) {
  const handleSave = e => {
    e.preventDefault()
    onSave()
  }

  const handleCancel = e => {
    e.preventDefault()
    onCancel()
  }

  const strings = Strings()
  return (
    <Flex className='WidgetEdit' direction='column' wrap='nowrap' axisGap={500}>
      <Flex alignMainAxis='space-between' wrap='nowrap'>
        <Flex direction='column' wrap='nowrap'>
          <Text className='WidgetEdit__Title'>{title || strings.title}</Text>
          <Text className='WidgetEdit__Subtitle' variant='page' tone={800}>
            {subtitle || strings.subtitle}
          </Text>
        </Flex>
        <Flex axisGap={300} wrap='nowrap'>
          <Button
            size='small'
            variant='error'
            onClick={handleCancel}
            disabled={disableCancel}
          >
            {strings.cancel}
          </Button>
          <Button size='small' variant='primary' onClick={handleSave}>
            {strings.save}
          </Button>
        </Flex>
      </Flex>
      {children}
    </Flex>
  )
}

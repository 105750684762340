import { I18n } from 'aws-amplify'
import { object, string, array } from 'yup'
import { useEffect, useState, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  fetchProduceThreshold,
  sendCreateProduceThreshold,
  sendUpdateProduceThreshold
} from '@/slices/operations/produceThreshold'

import {
  getCurrentUser,
  getOperationsProduceThreshold,
  getOperationsProduceThresholdLoading,
  getOperationsProduceThresholdError
} from '@/reducers/selectors'

import {
  Flex,
  Box,
  Form,
  Input,
  Label,
  Text,
  Loader,
  Separator
} from '@/primitives'

import InputError from '@/elements/InputError'

import HeaderV2 from '@/components/Operations/Shared/HeaderV2'

import { showBanner } from '@/slices/util'
import { getValidationErrorMap } from '@/Util/GeneralUtils'

import {
  SETTING_CELCIUS_TO_FAHRENHEIT,
  processSettings
} from '@/Util/SettingsUtils'

import history from '../../../../../history'

import Measurements from './Measurements'

import { REQUIRED_MEASUREMENTS } from './utils'

import { UPDATE, SET_STATE, formReducer, formInitalState } from './state'

import Strings from '../Strings'

const SCHEMA = object({
  name: string().required(I18n.get('A name is required.')),
  measurements: array()
    .min(
      REQUIRED_MEASUREMENTS.length,
      I18n.get('All measurements are required.')
    )
    .required(I18n.get('All measurements are required.'))
    .test(
      'valid-bounds',
      I18n.get('All bounds are required.'),
      measurements => {
        if (measurements?.length === REQUIRED_MEASUREMENTS.length) {
          for (let m of measurements) {
            if (m.bounds.length < 6) {
              return false
            }
          }
          return true
        }
        return false
      }
    )
})

function ProduceThresholdForm({ state, modulePath }) {
  const dispatch = useDispatch()
  const { itemId } = useParams()

  const strings = Strings()

  const currentUser = getCurrentUser()
  const produceThreshold = getOperationsProduceThreshold()
  const loading = getOperationsProduceThresholdLoading()
  const error = getOperationsProduceThresholdError()

  const [formState, dispatchFormState] = useReducer(
    formReducer,
    formInitalState
  )

  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)
  const [useFahrenheit, setUseFahrenheit] = useState(false)

  useEffect(() => {
    const enableFahrenheit = processSettings(
      currentUser?.settings,
      SETTING_CELCIUS_TO_FAHRENHEIT,
      currentUser.userName
    )
    setUseFahrenheit(enableFahrenheit)
  }, [])

  useEffect(() => {
    if (itemId && produceThreshold.id !== itemId) {
      dispatch(fetchProduceThreshold({ produceThresholdId: itemId }))
    }
  }, [dispatch, produceThreshold?.id, itemId])

  useEffect(() => {
    if (produceThreshold?.id && produceThreshold.id === itemId) {
      dispatchFormState({ type: SET_STATE, state: produceThreshold })
    }
  }, [produceThreshold, itemId])

  useEffect(() => {
    if (waiting && !loading && !error) {
      const message = itemId
        ? strings.produceThresholdUpdated
        : strings.produceThresholdCreated
      dispatch(
        showBanner({
          type: 'success',
          show: true,
          message: message
        })
      )
      history.replace(modulePath)
    }
  }, [waiting, loading, error, modulePath])

  const onSubmit = async e => {
    e?.preventDefault()
    try {
      const payload = JSON.parse(JSON.stringify(formState))
      await SCHEMA.validate(payload, { abortEarly: false })

      if (useFahrenheit) {
        for (let i = 0; i < payload.measurements.length; i++) {
          if (payload.measurements[i].key === 'temperature') {
            for (let j = 0; j < payload.measurements[i].bounds.length; j++) {
              payload.measurements[i].bounds[j] = Math.round(
                payload.measurements[i].bounds[j]
              )
            }
          }
        }
      }

      setWaiting(true)

      if (itemId) {
        payload.produceThresholdId = itemId
        dispatch(sendUpdateProduceThreshold(payload))
      } else {
        dispatch(sendCreateProduceThreshold(payload))
      }
    } catch (err) {
      setErrors(getValidationErrorMap(err))
    }
  }

  const onChange = e => {
    const { name, value } = e.currentTarget
    dispatchFormState({ type: UPDATE, name, value })
  }

  return (
    <Form className='Operations__Form ProduceThresholds'>
      <HeaderV2
        title={I18n.get('Manage Produce Threshold')}
        buttonIcon={'save'}
        buttonText={I18n.get('Save Produce Threshold')}
        buttonCallback={onSubmit}
        backPath={modulePath}
      />
      <Loader isLoading={loading}>
        <Flex axisGap={300} className='Operations__Form__Fields'>
          <Box>
            <Label>
              <Text variant='page' tone={700}>
                {I18n.get('Name')}
              </Text>
              <Input value={formState.name} name='name' onChange={onChange} />
              <InputError error={errors?.name} />
            </Label>
          </Box>
          <Separator className='Separator__Line' />
          <Box>
            <Text size={400} fontWeight={500} tone={600}>
              {strings.fieldsetMeasurements}
            </Text>
            <InputError error={errors?.measurements} />
            <Flex axisGap={300} direction='column' style={{ marginTop: '1em' }}>
              <Measurements
                produceThresholdId={itemId}
                formState={formState}
                errors={errors}
                dispatchFormState={dispatchFormState}
                useFahrenheit={useFahrenheit}
              />
            </Flex>
          </Box>
          {useFahrenheit && (
            <Text variant='page' tone={700}>
              {strings.fahrenheitWarning}
            </Text>
          )}
        </Flex>
      </Loader>
    </Form>
  )
}

export default ProduceThresholdForm

import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { getCognitoData, makeGraphqlRequest } from '../utils'

/*
 * Queries
 */

export const downloadReport = async params => {
  try {
    const query = /* GraphQL */ `
      query downloadReport($reportId: String!) {
        downloadReport(reportId: $reportId) {
          siteId
          zoneId
          reportId
          s3Path
          signedDownloadURL
          timestamp
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)

    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        report: null,
        error: `Failed to download report: ${error}`
      }
    }

    let link = document.createElement('a')
    link.href = data?.downloadReport?.signedDownloadURL
    link.download = data?.downloadReport?.s3Path
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    link.remove()

    return { report: data?.downloadReport, error: null }
  } catch (err) {
    return { report: null, error: err.message }
  }
}

export const getReports = async params => {
  try {
    const query = /* GraphQL */ `
      query getReports(
        $zoneId: String
        $fromDate: GraphQLTimestamp
        $toDate: GraphQLTimestamp
        $nextToken: GraphQLJSON
        $limit: Int
      ) {
        getReports(
          zoneId: $zoneId
          fromDate: $fromDate
          toDate: $toDate
          nextToken: $nextToken
          limit: $limit
        ) {
          reports {
            reportId
            zoneId
            s3Path
            timestamp
          }
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        reports: null,
        error: `Failed to fetch Reports: ${error}`
      }
    }

    return { reports: data?.getReports?.reports, error }
  } catch (err) {
    return { reports: null, error: err.message }
  }
}

/*
 * Mutations
 */

export const generateReport = async params => {
  try {
    const query = /* GraphQL */ `
      mutation generateReport(
        $userId: String!
        $siteId: String!
        $zoneId: String!
        $organizationId: String!
        $measurements: [String!]!
        $table: String!
        $sensorIds: [String!]!
        $fromDate: String!
        $toDate: String!
        $interval: String!
        $lang: String!
        $regenerate: Boolean
      ) {
        generateReport(
          userId: $userId
          siteId: $siteId
          zoneId: $zoneId
          organizationId: $organizationId
          measurements: $measurements
          table: $table
          sensorIds: $sensorIds
          fromDate: $fromDate
          toDate: $toDate
          interval: $interval
          lang: $lang
          regenerate: $regenerate
        ) {
          reportId
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        reportId: null,
        error: `Failed to generate report: ${error}`
      }
    }

    return { reportId: data?.generateReport?.reportId, error }
  } catch (err) {
    return { reportId: null, error: err.message }
  }
}

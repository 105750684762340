import { makeActionCreator } from '@/Util/ReduxUtils'

export const START_GREENGRASS_CORE_DEVICE_DEFAULT_DEPLOYMENT =
  'START_GREENGRASS_CORE_DEVICE_DEFAULT_DEPLOYMENT'
export const GREENGRASS_CORE_DEVICE_DEFAULT_DEPLOYMENT_STARTED =
  'GREENGRASS_CORE_DEVICE_DEFAULT_DEPLOYMENT_STARTED'
export const startGreengrassCoreDeviceDefaultDeployment = makeActionCreator(
  START_GREENGRASS_CORE_DEVICE_DEFAULT_DEPLOYMENT,
  'params'
)
export const greengrassCoreDeviceDefaultDeploymentStarted = makeActionCreator(
  GREENGRASS_CORE_DEVICE_DEFAULT_DEPLOYMENT_STARTED,
  'message',
  'error'
)

export const START_CORE_DEVICE_UPDATES = 'START_CORE_DEVICE_UPDATES'
export const CORE_DEVICE_UPDATES_STARTED = 'CORE_DEVICE_UPDATES_STARTED'
export const startCoreDeviceUpdates = makeActionCreator(
  START_CORE_DEVICE_UPDATES,
  'params'
)
export const coreDeviceUpdatesStarted = makeActionCreator(
  CORE_DEVICE_UPDATES_STARTED,
  'message',
  'error'
)

export const FETCH_CORE_DEVICE_PROVISION_INSTALLER =
  'FETCH_CORE_DEVICE_PROVISION_INSTALLER'
export const CORE_DEVICE_PROVISION_INSTALLER_FETCHED =
  'CORE_DEVICE_PROVISION_INSTALLER_FETCHED'

export const fetchCoreDeviceProvisionInstaller = makeActionCreator(
  FETCH_CORE_DEVICE_PROVISION_INSTALLER,
  'params'
)

export const coreDeviceProvisionInstallerFetched = makeActionCreator(
  CORE_DEVICE_PROVISION_INSTALLER_FETCHED,
  'error'
)
export const CLEAR_UPDATES = 'CLEAR_UPDATES'
export const clearUpdates = makeActionCreator(CLEAR_UPDATES)

import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { getCognitoData, makeGraphqlRequest } from '../utils'

// Queries

export const getUsagePlanByOrg = async params => {
  try {
    const query = /* GraphQL */ `
      query getUsagePlanByOrg($organizationId: String!) {
        getUsagePlanByOrg(organizationId: $organizationId) {
          id
          name
          throttle {
            burstLimit
            rateLimit
          }
          quota {
            limit
            offset
            period
          }
        }
      }
    `

    const { id, idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken,
      id
    )

    if (error) {
      return {
        usagePlan: null,
        error: `Usage plan not fetched: ${error}`
      }
    }

    return {
      usagePlan: data?.getUsagePlanByOrg,
      error: null
    }
  } catch (err) {
    return { usagePlan: null, error: err.message }
  }
}

// Mutations

export const createUsagePlan = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createOrgUsagePlan($organizationId: String!) {
        createOrgUsagePlan(organizationId: $organizationId) {
          id
          name
          throttle {
            burstLimit
            rateLimit
          }
          quota {
            limit
            offset
            period
          }
        }
      }
    `

    const { id, idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken,
      id
    )

    if (error) {
      return {
        usagePlan: null,
        error: `Usage plan not created: ${error}`
      }
    }

    return {
      usagePlan: data?.createOrgUsagePlan,
      error: null
    }
  } catch (err) {
    return { usagePlan: null, error: err.message }
  }
}

export const updateUsagePlan = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateUsagePlanSettings(
        $organizationId: String!
        $throttle: UsagePlanUpdateThrottleInput!
        $quota: UsagePlanUpdateQuotaInput!
      ) {
        updateUsagePlanSettings(
          organizationId: $organizationId
          throttle: $throttle
          quota: $quota
        ) {
          id
          name
          throttle {
            burstLimit
            rateLimit
          }
          quota {
            limit
            offset
            period
          }
        }
      }
    `

    const { id, idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken,
      id
    )

    if (error) {
      return {
        usagePlan: null,
        error: `Usage plan not updated: ${error}`
      }
    }

    return {
      usagePlan: data?.updateUsagePlanSettings,
      error: null
    }
  } catch (err) {
    return { usagePlan: null, error: err.message }
  }
}

export const getApiKeys = async params => {
  try {
    const query = /* GraphQL */ `
      query getApiKeysByOrg($organizationIds: [String!]) {
        getApiKeysByOrg(organizationIds: $organizationIds) {
          value
          name
        }
      }
    `

    const { id, idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken,
      id
    )

    if (error) {
      return {
        apiKeys: null,
        error: `API key not fetched: ${error}`
      }
    }

    return {
      apiKeys: data?.getApiKeysByOrg,
      error: null
    }
  } catch (err) {
    return { apiKeys: null, error: err.message }
  }
}

export const regenerateApiKey = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation regenerateApiKey($organizationId: String!) {
        regenerateApiKey(organizationId: $organizationId) {
          value
          name
        }
      }
    `

    const { id, idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken,
      id
    )

    if (error) {
      return {
        apiKey: null,
        error: `API key not recreated: ${error}`
      }
    }

    return {
      apiKey: data?.regenerateApiKey,
      error: null
    }
  } catch (err) {
    return { apiKey: null, error: err.message }
  }
}

import useDatasetData from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Hooks/useDatasetData'

import Line from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/Chart/Line'
import {
  CELLULAR_TUBULAR_SOILSENSE_MOISTURE,
  CELLULAR_TUBULAR_SOILSENSE_TEMPERATURE
} from '../types'
import { strings } from '../strings'

export default function DatasetLine({ options, config, unit, ...props }) {
  let measureId

  if (options.measurement === CELLULAR_TUBULAR_SOILSENSE_TEMPERATURE) {
    measureId = 't'
  } else if (options.measurement === CELLULAR_TUBULAR_SOILSENSE_MOISTURE) {
    measureId = 'm'
  } else {
    throw Error(`Widget implementation for ${options.measurement} not found.`)
  }

  const depths = ['a', 'b', 'c', 'd', 'e']

  const measurements = depths.map(d => `s_${measureId}_${d}`)

  const { datasetData, sensorId } = useDatasetData({
    measurements,
    range: config.range,
    interval: config.interval,
    includeSensor: true
  })

  let chartData = []

  if (datasetData) {
    for (const measure of measurements) {
      chartData.push({
        id: `${strings[measure]}`,
        data: datasetData?.[measure]?.[sensorId] || []
      })
    }
  }

  return <Line {...props} data={chartData} unit={unit} config={config} />
}

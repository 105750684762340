import { useEffect, useReducer, useState } from 'react'
import { object, string } from 'yup'
import { I18n } from 'aws-amplify'

import {
  UPDATE_WIDGET,
  CANCEL_WIDGET_UPDATE
} from '@/components/DashboardPageV2/Desktop/state'
import { useDashboard } from '@/components/DashboardPageV2/Desktop/context'

import DefaultDynamicView from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/DefaultDynamicView'
import DefaultDynamicEdit, {
  defaultDynamicEditInitalState,
  defaultDynamicEditReducer,
  defaultDynamicEditTypes
} from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/DefaultDynamicEdit'
import { getWidgetPositionByChart } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config/utils'
import { useEditResize } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/utils/useEditResize'
import EditLayout from '@/components/DashboardPageV2/Desktop/Widgets/Shared/EditLayout'
import { getMeasurements } from '@/reducers/selectors'
import { getValidationErrorMap } from '@/Util/GeneralUtils'
import {
  AVERAGE_QUERY,
  LIVE_QUERY
} from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'

const validateQueryOptions = ({
  validateRange = true,
  validateInterval = true
}) => {
  const shape = {
    query: string().required(I18n.get('Select a query is required')),
    chart: string().required(I18n.get('Select a chart is required'))
  }

  if (validateRange) {
    shape.range = string().required(I18n.get('Select a range is required'))
  }
  if (validateInterval) {
    shape.interval = string().required(
      I18n.get('Select an interval is required')
    )
  }

  return object().shape(shape)
}

function validateSchemaByType(queryType) {
  let params = {
    validateRange: true,
    validateInterval: true
  }

  switch (queryType) {
    case LIVE_QUERY:
      params.validateRange = false
      params.validateInterval = false
      break
    case AVERAGE_QUERY:
      params.validateInterval = false
      break
    default:
      break
  }
  return params
}

/**
 * Default edit component for Measurement widgets
 * Will not work with non-measurement widgets
 * @param {Object} props
 * @param {Array} props.queryOptionsList [queryOptionsList] - list of query options
 */
export function MeasurementEdit({
  setWidgetMode,
  validationSchema = null,
  options,
  ...props
}) {
  const { state, dispatchState } = useDashboard()
  const [reducerInit, setReducerInit] = useState(false)
  const [errors, setErrors] = useState({})

  const [formState, dispatchLocalState] = useReducer(
    defaultDynamicEditReducer,
    defaultDynamicEditInitalState
  )
  const { hasBeenEditResized, oldPosition } = useEditResize(props.id)

  useEffect(() => {
    if (props.config && !reducerInit) {
      dispatchLocalState({
        type: defaultDynamicEditTypes.setAll,
        payload: {
          query: props.config.query,
          chart: props.config.chart,
          range: props.config.range,
          interval: props.config.interval
        }
      })
      setReducerInit(true)
    }
  }, [props, reducerInit])

  const handleSave = async () => {
    // get widget props from state to avoid passing non widget props
    // not necessary in this case, but good practice
    // we parse the props in the end before saving

    try {
      const { props: widgetProps } = state.widgets.get(props.id)

      const newConfig = {
        ...widgetProps.config,
        ...formState
      }

      const nextProps = {
        ...widgetProps,
        config: newConfig
      }

      if (validationSchema) {
        await validationSchema.validate(newConfig, { abortEarly: false })
      } else {
        const params = validateSchemaByType(newConfig.query)
        await validateQueryOptions(params).validate(newConfig, {
          abortEarly: false
        })
      }

      dispatchState({
        type: UPDATE_WIDGET,
        payload: {
          ...nextProps,
          position: getWidgetPositionByChart(
            nextProps,
            state.currentBreakpoint,
            widgetProps.config.chart,
            hasBeenEditResized,
            oldPosition
          )
        }
      })
      setWidgetMode('view')
    } catch (error) {
      setErrors(getValidationErrorMap(error))
    }
  }

  const handleCancel = () => {
    const { props: widgetProps } = state.widgets.get(props.id)

    // if the widget has been resized, we need to reset the position
    dispatchState({
      type: CANCEL_WIDGET_UPDATE,
      payload: {
        id: props.id,
        position: getWidgetPositionByChart(
          widgetProps,
          state.currentBreakpoint,
          formState.chart,
          hasBeenEditResized,
          oldPosition
        )
      }
    })

    setWidgetMode('view')
  }

  const onSelectQuery = option => {
    dispatchLocalState({
      type: defaultDynamicEditTypes.setQuery,
      payload: option.value
    })
  }

  const onSelectChart = option => {
    dispatchLocalState({
      type: defaultDynamicEditTypes.setChart,
      payload: option.value
    })
  }

  const onSelectRange = option => {
    dispatchLocalState({
      type: defaultDynamicEditTypes.setRange,
      payload: option.value
    })
  }

  const onSelectInterval = option => {
    dispatchLocalState({
      type: defaultDynamicEditTypes.setInterval,
      payload: option.value
    })
  }

  return (
    <EditLayout id={props.id} onSave={handleSave} onCancel={handleCancel}>
      <DefaultDynamicEdit
        formState={formState}
        errors={errors}
        setErrors={setErrors}
        onSelectChart={onSelectChart}
        onSelectInterval={onSelectInterval}
        onSelectRange={onSelectRange}
        onSelectQuery={onSelectQuery}
        queryOptionsList={options.queryOptionsList}
      />
    </EditLayout>
  )
}

/**
 * Default view component for Measurement widgets
 * The measurement widget needs to be in the Measurements reducer to map the right unit by measurement key
 * @param {Object} props
 */
export function MeasurementView(props) {
  const measurement = props.options.measurement
  const measurements = getMeasurements()

  const unit = measurements?.find(m => m.id === measurement)?.unit

  return <DefaultDynamicView {...props} unit={unit} />
}

import { I18n } from 'aws-amplify'
import { object, string, number } from 'yup'

export const FIELDS = {
  id: 'id',
  organizationId: 'organizationId',
  customerId: 'customerId',
  productId: 'productId',
  currencyId: 'currencyId',
  unitPrice: 'unitPrice',
  discount: 'discount',
  sellingPrice: 'sellingPrice',
  articleCode: 'articleCode',
  description: 'description',
  alternateDescription: 'alternateDescription'
}

export const SCHEMA = () => {
  return object().shape({
    organizationId: string().required(I18n.get('An organization is required.')),
    customerId: string().required(I18n.get('Please select customer.')),
    productId: string().required(I18n.get('Please select a product.')),
    currencyId: string().required(I18n.get('Please select a currency.')),
    unitPrice: number()
      .typeError('A unit price is required.')
      .min(1, I18n.get('The unit price cannot be less than one.'))
      .required(I18n.get('A unit price is required.')),
    discount: number()
      .nullable()
      .transform(n => (isNaN(n) ? 0 : n))
      .min(0, I18n.get('The discount cannot be less than zero.')),
    sellingPrice: number()
      .nullable()
      .transform(n => (isNaN(n) ? 0 : n))
      .min(0, I18n.get('The selling price cannot be less than zero.')),
    articleCode: string(),
    description: string(),
    alternateDescription: string()
  })
}

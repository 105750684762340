import { Fragment } from 'react'

import { getZonesHierarchy } from '../../../../../reducers/selectors'

import { Button, Cell, Row } from '../../../../../primitives'

import { formatDate } from '../../utils'

import ZoneUtils from '../../../../../Util/ZoneUtils'

import Strings from '../Strings'

function TableRow({ item, onEdit, onDelete, canEdit }) {
  const zonesHierarchy = getZonesHierarchy()
  const strings = Strings()

  const {
    id,
    siteId,
    zoneId,
    produce,
    variety,
    harvestDate,
    totalWeight,
    createdAt
  } = item

  const canDelete = () => {
    const timestamp = parseInt(new Date(createdAt).getTime())
    return timestamp > Date.now() - 1000 * 60 * 60
  }

  const getZoneName = (site, zone) => {
    return ZoneUtils.getZoneName(zonesHierarchy, site, zone)
  }

  const handleEdit = () => {
    onEdit(id)
  }

  const handleDelete = () => {
    onDelete(id)
  }

  return (
    <Row>
      <Cell columnName={strings.date}>{formatDate(harvestDate)}</Cell>
      <Cell columnName={strings.zone}>
        {getZoneName(siteId)}
        <span>{getZoneName(siteId, zoneId)}</span>
      </Cell>
      <Cell columnName={strings.produce}>
        {produce.name}
        <span>{variety.name}</span>
      </Cell>
      <Cell columnName={strings.weight}>
        {totalWeight} {strings.kg}
      </Cell>
      <Cell className='Operations__Table__Column__Actions'>
        {canEdit && (
          <Fragment>
            {canDelete() && (
              <Button
                variant='error'
                size='small'
                iconBefore='delete'
                onClick={handleDelete}
              />
            )}
            <Button
              variant='info'
              size='small'
              iconBefore='edit'
              onClick={handleEdit}
            />
          </Fragment>
        )}
      </Cell>
    </Row>
  )
}

export default TableRow

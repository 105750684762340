import { makeActionCreator } from '@/Util/ReduxUtils'

export const REQUEST_INVENTORY_CALIBRATIONS = 'REQUEST_INVENTORY_CALIBRATIONS'
export const requestInventoryCalibrations = makeActionCreator(
  REQUEST_INVENTORY_CALIBRATIONS,
  'params'
)

export const RECEIVE_INVENTORY_CALIBRATIONS = 'RECEIVE_INVENTORY_CALIBRATIONS'
export const receiveInventoryCalibrations = makeActionCreator(
  RECEIVE_INVENTORY_CALIBRATIONS,
  'inventoryCalibrations',
  'count',
  'error'
)

export const CREATE_INVENTORY_CALIBRATION = 'CREATE_INVENTORY_CALIBRATION'
export const createInventoryCalibration = makeActionCreator(
  CREATE_INVENTORY_CALIBRATION,
  'params'
)

export const INVENTORY_CALIBRATION_CREATED = 'INVENTORY_CALIBRATION_CREATED'
export const inventoryCalibrationCreated = makeActionCreator(
  INVENTORY_CALIBRATION_CREATED,
  'inventoryCalibration',
  'error'
)

export const CLEAR_INVENTORY_CALIBRATIONS = 'CLEAR_INVENTORY_CALIBRATIONS'
export const clearInventoryCalibrations = makeActionCreator(
  CLEAR_INVENTORY_CALIBRATIONS
)

import { Cell, Row } from '../../../../../primitives'

import { formatDate } from '../../utils'
import Strings from '../Strings'

function TableRow({ item }) {
  const strings = Strings()

  const { oldQuantity, newQuantity, reason, createdAt } = item
  const quantityChange = newQuantity - oldQuantity

  return (
    <Row>
      <Cell columnName={strings.date}>{formatDate(createdAt)}</Cell>
      <Cell columnName={strings.oldQuantity}>{oldQuantity}</Cell>
      <Cell columnName={strings.newQuantity}>
        {newQuantity}
        <span>
          {quantityChange > 0 ? '+' : '-'}
          {Math.abs(quantityChange)}
        </span>
      </Cell>
      <Cell columnName={strings.reason}>{reason}</Cell>
    </Row>
  )
}

export default TableRow

import { nanoid } from 'nanoid'
import { Storage } from 'aws-amplify'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import {
  getPaymentTerms,
  createPaymentTerm,
  updatePaymentTerm,
  getPaymentTerm
} from '@/api/operations/paymentTerm'

import {
  REQUEST_ALL_PAYMENT_TERMS,
  REQUEST_PAYMENT_TERMS,
  REQUEST_PAYMENT_TERM,
  CREATE_PAYMENT_TERM,
  UPDATE_PAYMENT_TERM,
  receiveAllPaymentTerms,
  receivePaymentTerms,
  receivePaymentTerm,
  paymentTermCreated,
  paymentTermUpdated
} from '@/actions/operations/paymentTerm'

import { getCurrentUserOrganizations, displayBanner } from '../utils'
import { getOperationsAllPaymentTerms } from './utils'

import Strings from './Strings'

const strings = Strings()

function* sendCreatePaymentTerm(action) {
  const { s3Key, error: uploadError } = yield uploadDocument(
    action.params.termSheetDocument,
    action.params.name
  )

  let error = uploadError ?? null
  let data = null

  if (!uploadError) {
    const payload = { ...action.params, termSheetS3Key: s3Key }
    const { data: paymentTerm, error: apiError } = yield call(
      createPaymentTerm,
      payload
    )
    error = apiError ?? null
    data = paymentTerm

    data.termSheetDocument = yield createFileObject(s3Key)
    delete data.termSheetS3Key
  }

  if (error && s3Key) {
    yield Storage.remove(s3Key)
  }

  yield put(displayBanner(error, strings.paymentTermCreated))
  yield put(paymentTermCreated(data, error))
}

export function* watchCreatePaymentTerm() {
  yield takeLatest(CREATE_PAYMENT_TERM, sendCreatePaymentTerm)
}

function* sendUpdatePaymentTerm(action) {
  const { s3Key, error: uploadError } = yield uploadDocument(
    action.params.termSheetDocument,
    action.params.name
  )

  let error = uploadError ?? null
  let data = null

  if (!uploadError) {
    const payload = { ...action.params, termSheetS3Key: s3Key }
    const { data: paymentTerm, error: apiError } = yield call(
      updatePaymentTerm,
      payload
    )
    error = apiError ?? null
    data = paymentTerm

    if (!apiError && action.params.s3KeyToDelete) {
      yield Storage.remove(s3Key)
    }
    data.termSheetDocument = yield createFileObject(s3Key)
    delete data.termSheetS3Key
  }

  if (error && s3Key) {
    yield Storage.remove(s3Key)
  }

  yield put(displayBanner(error, strings.paymentTermUpdated))
  yield put(paymentTermUpdated(data, error))
}

export function* watchUpdatePaymentTerm() {
  yield takeLatest(UPDATE_PAYMENT_TERM, sendUpdatePaymentTerm)
}

function* sendRequestPaymentTerms(action) {
  const { data, error = null } = yield call(getPaymentTerms, action.params)
  let { paymentTerms = [], count = 0 } = data

  const calls = paymentTerms.map(item =>
    call(async () => ({
      ...item,
      termSheetDocument: await createFileObject(item.termSheetS3Key)
    }))
  )

  paymentTerms = yield all(calls)
  yield put(receivePaymentTerms(paymentTerms, count, error))
}

export function* watchRequestPaymentTerms() {
  yield takeLatest(REQUEST_PAYMENT_TERMS, sendRequestPaymentTerms)
}

function* sendRequestPaymentTerm(action) {
  let { data, error = null } = yield call(getPaymentTerm, action.params)
  data.termSheetDocument = yield createFileObject(data.termSheetS3Key)
  delete data.termSheetS3Key
  yield put(receivePaymentTerm(data, error))
}

export function* watchRequestPaymentTerm() {
  yield takeLatest(REQUEST_PAYMENT_TERM, sendRequestPaymentTerm)
}

function* sendRequestAllPaymentTerms(action) {
  const allPaymentTerms = yield select(getOperationsAllPaymentTerms)
  if (allPaymentTerms.length > 0) {
    yield put(
      receiveAllPaymentTerms(allPaymentTerms, allPaymentTerms.length, null)
    )
  } else {
    const organizations = yield select(getCurrentUserOrganizations)
    const params = {
      filter: {
        organizationId: organizations.map(o => o.id)
      }
    }
    const { data, error = null } = yield call(getPaymentTerms, params)
    const { paymentTerms = [], count = 0 } = data
    yield put(receiveAllPaymentTerms(paymentTerms, count, error))
  }
}

export function* watchRequestAllPaymentTerms() {
  yield takeLatest(REQUEST_ALL_PAYMENT_TERMS, sendRequestAllPaymentTerms)
}

const createFileObject = async s3Key => {
  return {
    file: {},
    url: s3Key ? await Storage.get(s3Key) : '',
    s3Key: s3Key ?? ''
  }
}

const uploadDocument = async (document, paymentTermName) => {
  try {
    let docCopy = { ...document }
    if (docCopy?.file?.name) {
      docCopy.s3Key = generateS3Key(paymentTermName)
      await Storage.put(docCopy.s3Key, docCopy.file, {
        contentType: 'application/pdf'
      })
    }
    return { s3Key: docCopy.s3Key ?? '', error: null }
  } catch (error) {
    return { s3Key: null, error }
  }
}

const generateS3Key = name => {
  if (!name) {
    throw Error('generateS3Key needs name')
  }
  const cleanName = name.replace(/\s+/g, '-').toLowerCase()
  return `paymentTerm/${cleanName}_termSheet_${nanoid()}.pdf`
}

import { call, put, takeLatest } from 'redux-saga/effects'

import {
  REQUEST_INVENTORIES,
  REQUEST_INVENTORY,
  receiveInventories,
  receiveInventory
} from '@/actions/operations/inventory'

import { getInventories, getInventory } from '@/api/operations/inventory'

function* sendRequestInventories(action) {
  const { data, error = null } = yield call(getInventories, action.params)
  const { inventories = [], count = 0 } = data
  yield put(receiveInventories(inventories, count, error))
}

export function* watchRequestInventories() {
  yield takeLatest(REQUEST_INVENTORIES, sendRequestInventories)
}

function* sendRequestInventory(action) {
  const { data: inventory, error = null } = yield call(
    getInventory,
    action.params
  )
  yield put(receiveInventory(inventory, error))
}

export function* watchRequestInventory() {
  yield takeLatest(REQUEST_INVENTORY, sendRequestInventory)
}

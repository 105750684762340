import { useContext, createContext, useState, useEffect } from 'react'

const ThemeContext = createContext({})

const cssNamespace = 'ctx'

const createFallbackSheet = () => {
  const styleSheet = document.createElement('style')
  styleSheet.textContent = `:root{
    --${cssNamespace}-theme-color-black: #000;
    --${cssNamespace}-theme-color-white: #FFF
  }`
  document.head.appendChild(styleSheet)
  return styleSheet.sheet
}

const internalSheetsOnly = sheet =>
  sheet.href?.includes(window.location.origin) || !sheet.href

const nonEmptySheetsOnly = sheet => sheet.cssRules.length && !sheet.disable

const themeSheetOnly = sheet =>
  [...sheet.cssRules].filter(({ cssText }) =>
    cssText.includes(`--${cssNamespace}-theme`)
  ).length

const extractCSSStyles = ({ style }) =>
  style?.length
    ? [...style].filter(decl => decl.includes(`--${cssNamespace}-theme`))
    : []

const treeBuilder = (tree = {}, item = '') => {
  tree[item.replace(`--${cssNamespace}-theme-`, '')] = `var(${item})`
  return tree
}

const expandDashNotation = (obj = {}) => {
  const result = {}

  for (const path in obj) {
    const parts = path.replace(/--/g, '-').split('-')

    let target = result

    while (parts.length > 1) {
      const part = parts.shift()
      target = target[part] = target[part] || {}
    }
    target[parts[0]] = obj[path]
  }

  return result
}

const parseCSSVariables = () => {
  const theme = {}

  // find the theme sheet
  const sheet =
    [...document.styleSheets]
      .filter(internalSheetsOnly)
      .filter(nonEmptySheetsOnly)
      .filter(themeSheetOnly)[0] || createFallbackSheet()

  // add a reference non-enumerable reference
  Object.defineProperty(theme, 'sheet', { value: sheet })

  // Add the public variables
  Object.assign(
    theme,
    expandDashNotation(
      [...sheet.cssRules].map(extractCSSStyles).flat().reduce(treeBuilder, {})
    )
  )

  return theme
}

const ThemeProvider = props => {
  const [theme, setTheme] = useState({})

  useEffect(() => {
    setTheme(parseCSSVariables())
  }, [])

  return <ThemeContext.Provider value={theme} {...props} />
}
/**
 * @typedef {{ 100, 200, 300, 400, 500, 600, 700, 800, 900 }} ColorSet
 * @typedef {{ 0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000 }} UtilitySet
 * @typedef {{ 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000 }} FontSizeSet
 * @typedef {{ 100, 200, 300, 400, 500, 600, 700 }} FontWeightSet
 *
 * @typedef {Object} ColorRange
 * @property {String} black
 * @property {String} white
 * @property {ColorSet} page
 * @property {ColorSet} error
 * @property {ColorSet} primary
 * @property {ColorSet} success
 * @property {ColorSet} info
 * @property {ColorSet} graph
 * @property {ColorSet} warning
 * @property {ColorSet} danger
 * @property {ColorSet} neutral
 */

/**
 * @typedef {Object} Theme
 * @property {ColorRange} color
 * @property {ColorRange} contrast
 * @property {UtilitySet} opacity
 * @property {UtilitySet} shadow
 * @property {UtilitySet} spacing
 * @property {Object} font
 * @property {{sans, mono}} font.family
 * @property {FontSizeSet} font.size
 * @property {{heading, body}} font.lineheight
 * @property {FontWeightSet} font.weight
 */
/**
 * useTheme
 * @returns {Theme} theme - The current {@link Theme} parsed from CSS Variables
 */
const useTheme = () => {
  const context = useContext(ThemeContext)

  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider')
  }

  return context
}

export { ThemeProvider, useTheme }

import { useReducer, Fragment, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import TranslationLanguageCard from './TranslationLangCard'
import { Dialog, LineSeparator } from '@/elements'
import { Slot, Text, Flex, Button, Box } from '@/primitives'

import LANGUAGES from '@/Util/languages.json'
import {
  sendRedoReleaseNoteTranslation,
  cleanTranslations
} from '@/slices/fyi/releaseNotes'
import { getReleaseNoteTranslationsRequested } from '@/reducers/selectors'
import Strings from '../../Strings'

const strings = Strings()
const languageOptions = LANGUAGES.enabled.filter(lang => lang !== 'en')

const initialState = {
  releaseNoteId: '',
  targetLangs: []
}

function reducer(state, action) {
  if (action.type === 'updateTranslations') {
    if (state.targetLangs.includes(action.name)) {
      const targetLangs = [...state.targetLangs].filter(
        lang => lang !== action.name
      )
      return { ...state, targetLangs }
    }
    return {
      ...state,
      targetLangs: [...state.targetLangs, action.name]
    }
  }

  if (action.type === 'setInit') {
    const targetLangs = languageOptions.filter(lang => {
      const { titleTranslations = [] } = action.value
      return !titleTranslations?.some(item => item.lang === lang)
    })
    return { ...state, ...action.value, targetLangs }
  }

  if (action.type === 'reset') {
    return { ...state, ...initialState }
  }
}

const TranslationsForm = ({ showForm, setHideForm, itemToEdit }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const reduxDispatch = useDispatch()
  const translationsRequested = getReleaseNoteTranslationsRequested()

  useEffect(() => {
    if (translationsRequested === itemToEdit?.releaseNoteId) {
      setHideForm()
      reduxDispatch(cleanTranslations())
    }
  }, [
    translationsRequested,
    itemToEdit?.releaseNoteId,
    setHideForm,
    reduxDispatch
  ])

  if (
    showForm &&
    state.releaseNoteId?.length === 0 &&
    itemToEdit?.releaseNoteId?.length > 0
  ) {
    dispatch({ type: 'setInit', value: itemToEdit })
  }

  if (!showForm && state.releaseNoteId?.length > 0) {
    dispatch({ type: 'reset' })
  }

  if (!itemToEdit?.releaseNoteId) return null

  function onChangeCheckbox({ target }) {
    const { checked, name } = target
    dispatch({ type: 'updateTranslations', value: checked, name })
  }

  function onSubmitForm() {
    reduxDispatch(
      sendRedoReleaseNoteTranslation({
        releaseNoteId: state.releaseNoteId,
        targetLangs: state.targetLangs
      })
    )
  }

  function insertLanguageRow(lang) {
    const currentTitleTranslation = itemToEdit?.titleTranslations?.find(
      translation => translation.lang === lang
    )?.text

    const itemsTranslations = itemToEdit.items.map(item =>
      item?.textTranslations?.find(translation => translation.lang === lang)
    )

    const checked = state.targetLangs.includes(lang)

    return (
      <TranslationLanguageCard
        key={lang}
        title={currentTitleTranslation}
        itemsTranslations={itemsTranslations}
        onChangeCheckbox={onChangeCheckbox}
        checked={checked}
        lang={lang}
      />
    )
  }

  return (
    <Fragment>
      <Dialog
        className='ZoneDetails__OffCanvas'
        type='offcanvas'
        open={showForm}
        onOpenChange={setHideForm}
      >
        <Slot name='title'>
          <Text size={400} fontWeight={700}>
            {strings.generateTranslations}
          </Text>
        </Slot>
        <Slot name='content'>
          <Text size={200} as='p' variant='page'>
            {strings.translationsFormSubheading}
          </Text>
          <Box>
            <LineSeparator />
          </Box>
          <Text as='h5' style={{ margin: '1rem 0 1.2rem' }}>
            {strings.releaseNotesEng}
          </Text>
          <TranslationLanguageCard
            title={itemToEdit?.title}
            itemsTranslations={itemToEdit.items}
            lang='en'
          />
          <Text as='h5' style={{ margin: '2.5rem 0 1.2rem' }}>
            {strings.translations}
          </Text>
          <Flex axisGap={400} direction='column'>
            {itemToEdit && languageOptions.map(lang => insertLanguageRow(lang))}
          </Flex>
        </Slot>
        <Slot name='actions'>
          <Flex alignMainAxis='space-between'>
            <Button size='small' variant='error' onClick={setHideForm}>
              {strings.cancel}
            </Button>
            <Button
              size='small'
              variant='primary'
              onClick={onSubmitForm}
              disabled={state.targetLangs?.length === 0}
            >
              {strings.generateTranslations}
            </Button>
          </Flex>
        </Slot>
      </Dialog>
    </Fragment>
  )
}

export default TranslationsForm

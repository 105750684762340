import { I18n } from 'aws-amplify'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {
  getSensorSimDetails,
  getSimDiagnosticInfo,
  getSimDiagnosticPing,
  updateSensorSimStatus
} from '@/api/diagnostics/sensorSim'
import { showBanner } from '../util'

export const sendUpdateSensorSimStatus = createAsyncThunk(
  'sendUpdateSensorSimStatus',
  async (params, { dispatch }) => {
    try {
      // for now we are not using the response not even in the reducer
      const result = await updateSensorSimStatus(params)
      dispatch(
        showBanner({
          show: true,
          message: I18n.get('SIM status updated successfully.'),
          type: 'success'
        })
      )
      return result
    } catch (error) {
      dispatch(
        showBanner({
          show: true,
          message: I18n.get(
            'Could not update the SIM status. Our team has been notified and will investigate.'
          ),
          type: 'error'
        })
      )
      throw error
    }
  }
)

export const fetchSensorSimDetails = createAsyncThunk(
  'fetchSensorSimDetails',
  async (params, { dispatch }) => {
    try {
      return await getSensorSimDetails(params)
    } catch (error) {
      throw error
    }
  }
)

export const fetchSimDiagnosticInfo = createAsyncThunk(
  'fetchSimDiagnosticInfo',
  async (params, { dispatch }) => {
    try {
      const result = await getSimDiagnosticInfo(params)
      dispatch(
        showBanner({
          show: true,
          message: I18n.get(
            'Diagnostics request sent succesfully. You will receive an email with the results.'
          ),
          type: 'success'
        })
      )
      return result
    } catch (error) {
      throw error
    }
  }
)

export const fetchSimDiagnosticPing = createAsyncThunk(
  'fetchSimDiagnosticPing',
  async (params, { dispatch }) => {
    try {
      const result = await getSimDiagnosticPing(params)
      const someHaveFailed = result.some(ping => ping.status === 'failed')
      if (someHaveFailed) {
        dispatch(
          showBanner({
            show: true,
            message: I18n.get(
              'Some of the SIM pings failed. Our team has been notified and will investigate.'
            ),
            type: 'error'
          })
        )
      }
      return result
    } catch (error) {
      throw error
    }
  }
)

const diagnosticsSensorSim = createSlice({
  name: 'diagnosticsSensorSimReducer',
  initialState: {
    requestedDiagnostics: [],
    requestedPings: [],
    sensorSimDetails: {},
    error: null,
    loading: false
  },
  reducers: {
    cleanRequestedSensorSimDiagnostics: (state, action) => {
      state.requestedDiagnostics = []
    },
    cleanRequestedSensorSimPings: (state, action) => {
      state.requestedPings = []
    },
    cleanSensorSimDetails: (state, action) => {
      state.sensorSimDetails = {}
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSimDiagnosticInfo.pending, state => {
        state.error = null
        state.loading = true
      })
      .addCase(fetchSimDiagnosticInfo.fulfilled, (state, action) => {
        state.requestedDiagnostics = action.payload
        state.devices = action.payload
        state.loading = false
      })
      .addCase(fetchSimDiagnosticInfo.rejected, (state, action) => {
        state.error = action.error
        state.loading = false
      })
      .addCase(fetchSimDiagnosticPing.pending, state => {
        state.error = null
        state.loading = true
      })
      .addCase(fetchSimDiagnosticPing.fulfilled, (state, action) => {
        state.requestedPings = action.payload
        state.loading = false
      })
      .addCase(fetchSimDiagnosticPing.rejected, (state, action) => {
        state.error = action.error
        state.loading = false
      })
      .addCase(fetchSensorSimDetails.pending, state => {
        state.sensorSimDetails = {}
        state.error = null
        state.loading = true
      })
      .addCase(fetchSensorSimDetails.fulfilled, (state, action) => {
        state.sensorSimDetails = action.payload
        state.loading = false
      })
      .addCase(fetchSensorSimDetails.rejected, (state, action) => {
        state.sensorSimDetails = {}
        state.error = action.error
        state.loading = false
      })
      .addCase(sendUpdateSensorSimStatus.pending, state => {
        state.error = null
        state.loading = true
      })
      .addCase(sendUpdateSensorSimStatus.fulfilled, (state, action) => {
        if (state.sensorSimDetails?.status) {
          state.sensorSimDetails.status = action.payload.status
        }
        state.loading = false
      })
      .addCase(sendUpdateSensorSimStatus.rejected, (state, action) => {
        state.error = action.error
        state.loading = false
      })
  }
})

const { actions, reducer } = diagnosticsSensorSim

export const {
  cleanRequestedSensorSimDiagnostics,
  cleanRequestedSensorSimPings,
  cleanSensorSimDetails
} = actions
export default reducer

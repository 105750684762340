import { useEffect, useState } from 'react'

import UpsertFlashScript from './UpsertFlashScript'

import {
  Card,
  Grid,
  Item,
  Slot,
  Text,
  FlexV2,
  Button,
  Symbol,
  Box,
  Separator,
  Loader
} from '@/primitives'

import { Select, SearchBar } from '@/elements'

import {
  getDeviceTypes,
  getFlashScripts,
  getCurrentUser,
  getDeviceManagementInitLoading
} from '@/reducers/selectors'

import useMediaQuery from '@/hooks/useMediaQuery'

import { hasDeviEditPermissions } from '../utils'

import Strings from '../../Strings'

import './index.scss'

const DeviceTypes = () => {
  const coretexUser = getCurrentUser()
  const flashScripts = getFlashScripts()
  const deviceTypes = getDeviceTypes()
  const isLoading = getDeviceManagementInitLoading()

  const isAboveBreakpoint = useMediaQuery('min-width: 600px')

  const strings = Strings()

  const [hasEditPermissions, setHasEditPermissions] = useState(false)
  const [showUpsertModal, setShowUpsertModal] = useState(false)
  const [selectedFlashScript, setSelectedFlashScript] = useState({})
  const [typeFilter, setTypeFilter] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [filteredFlashScripts, setFilteredFlashScripts] = useState([])

  const onToggleModal = () => {
    if (showUpsertModal) setSelectedFlashScript({})
    setShowUpsertModal(!showUpsertModal)
  }

  const onClickEdit = e => {
    setSelectedFlashScript(filteredFlashScripts[e.target.value])
    onToggleModal()
  }

  const onSelectOption = option => {
    setTypeFilter(option?.value)
  }

  const onUpdateSearchText = text => {
    setSearchText(text.toLowerCase())
  }

  useEffect(() => {
    if (coretexUser) {
      const canEdit = hasDeviEditPermissions(coretexUser)
      setHasEditPermissions(canEdit)
    }
  }, [coretexUser])

  useEffect(() => {
    let newFlasScripts = [...flashScripts]

    if (searchText) {
      const filteredBySearch = flashScripts?.filter(
        ({ version, description }) => {
          const searchedName =
            version?.toLowerCase().includes(searchText) ||
            description?.toLowerCase().includes(searchText)
          return searchedName
        }
      )
      newFlasScripts = filteredBySearch
    }

    if (typeFilter) {
      const filteredByType = newFlasScripts?.filter(
        ({ deviceType }) => deviceType === typeFilter
      )
      newFlasScripts = filteredByType
    }

    setFilteredFlashScripts(newFlasScripts)
  }, [typeFilter, flashScripts, searchText])

  return (
    <Loader isLoading={isLoading}>
      <FlexV2 direction='column' axisGap={400} className={'FlashScripts'}>
        <FlexV2
          direction={isAboveBreakpoint ? 'row' : 'column'}
          axisGap={400}
          alignCrossAxis={isAboveBreakpoint ? 'center' : 'stretch'}
          alignMainAxis={isAboveBreakpoint ? 'space-between' : null}
        >
          <Box>
            <Text size={300} fontWeight={600}>
              {strings?.flashScriptsTab}
            </Text>
            <br />
            <Text variant='page' tone={900}>
              {strings?.flashScriptsSubheading}
            </Text>
          </Box>
          {hasEditPermissions && (
            <Button onClick={onToggleModal} variant='primary' size='small'>
              {strings?.addFlashScript}
            </Button>
          )}
        </FlexV2>

        <Separator />

        <FlexV2 direction={isAboveBreakpoint ? 'row' : 'column'} axisGap={400}>
          <FlexV2 axisGap={400} alignCrossAxis='center'>
            {isAboveBreakpoint && <Symbol name='filter_list' />}
            <Box style={{ flexGrow: 1 }}>
              <Select
                isClearable={true}
                value={typeFilter}
                placeholder={strings?.filterByType}
                options={deviceTypes
                  .filter(({ hasFirmware }) => hasFirmware)
                  .map(type => ({
                    value: type.id,
                    label: type.name,
                    name: 'type'
                  }))}
                onChange={onSelectOption}
              />
            </Box>
          </FlexV2>

          <FlexV2 axisGap={400} alignCrossAxis='center' style={{ flexGrow: 1 }}>
            {isAboveBreakpoint && <Symbol name='search' />}
            <Box style={{ flexGrow: 1 }}>
              <SearchBar
                onUpdateSearchText={onUpdateSearchText}
                placeholderText={strings?.searchByName}
              />
            </Box>
          </FlexV2>
        </FlexV2>

        <Separator />

        {filteredFlashScripts?.length > 0 ? (
          <Grid gap={400}>
            {filteredFlashScripts?.map(
              ({ deviceType, version, description }, index) => {
                return (
                  <Item key={`${deviceType}-${version}`}>
                    <Card>
                      <Slot name='body'>
                        <FlexV2
                          direction='column'
                          axisGap={400}
                          alignMainAxis='space-between'
                        >
                          <Box>
                            <Text
                              fontFamily='mono'
                              textTransform='capitalize'
                              tone={700}
                              variant='page'
                              size={100}
                              fontWeight={500}
                            >
                              {deviceType}
                            </Text>
                            <br />
                            <Text
                              truncate={20}
                              variant='page'
                              size={300}
                              fontWeight={500}
                            >
                              {version}
                            </Text>
                            <br />
                            <Text truncate={255} variant='page' size={100}>
                              {description}
                            </Text>
                          </Box>
                          {hasEditPermissions && (
                            <Box>
                              <Button
                                size='small'
                                value={index}
                                variant='primary'
                                onClick={onClickEdit}
                                className='edit-device-type-btn'
                              >
                                {strings?.editButton}
                              </Button>
                            </Box>
                          )}
                        </FlexV2>
                      </Slot>
                    </Card>
                  </Item>
                )
              }
            )}
          </Grid>
        ) : (
          <FlexV2 axisGap={400}>
            <Symbol name='shelves' variant='page' tone={700} />
            <Text size={200} fontWeight={600} variant='page' tone={700}>
              {strings?.noFlashScripts}
            </Text>
          </FlexV2>
        )}

        {showUpsertModal && (
          <UpsertFlashScript
            showUpsertModal={showUpsertModal || false}
            onToggleModal={onToggleModal}
            selectedFlashScript={selectedFlashScript}
          />
        )}
      </FlexV2>
    </Loader>
  )
}

export default DeviceTypes

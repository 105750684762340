import { useReducer, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import DatePicker from 'react-datepicker'

import useDeviceSize from '@/hooks/useDeviceSize'

import { requestAllProduces } from '@/actions/operations/produce'
import { requestAllVarieties } from '@/actions/operations/variety'
import { requestAllGrades } from '@/actions/operations/grade'
import { requestAllCurrencies } from '@/actions/operations/currency'

import { createPlan, requestPlan, updatePlan } from '@/actions/operations/plan'

import {
  getOperationsAllProduces,
  getOperationsAllVarieties,
  getOperationsAllCurrencies,
  getOperationsPlan,
  getOperationsLoading,
  getOperationsError
} from '@/reducers/selectors'

import {
  Box,
  Button,
  Flex,
  Label,
  Select,
  Input,
  Text,
  Loader
} from '@/primitives'

import InputError from '@/elements/InputError'
import OrganizationInput from '../../../Shared/Organization/Input'
import ZoneNavigator from '../../../Shared/ZoneNavigator'
import Weights from '../../../Shared/Weights'

import HeaderV2 from '@/components/Operations/Shared/HeaderV2'
import { getValidationErrorMap } from '@/Util/GeneralUtils'

import { PLAN_STATUS_TYPE } from '../utils'
import { FIELDS, SCHEMA } from './config'

import {
  initialState,
  reducer,
  SET_STATE,
  UPDATE_INPUT,
  UPDATE_SITE_ID,
  REMOVE_WEIGHT,
  UPDATE_WEIGHT
} from './state'

import history from '../../../../../history'
import Strings from '../Strings'

function PlanForm({ state, modulePath }) {
  const dispatch = useDispatch()
  const isMobile = useDeviceSize('mobile')
  const isLaptop = useDeviceSize()

  const { itemId } = useParams()
  const strings = Strings()

  const produces = getOperationsAllProduces()
  const varieties = getOperationsAllVarieties()
  const currencies = getOperationsAllCurrencies()
  const plan = getOperationsPlan()
  const loading = getOperationsLoading()
  const error = getOperationsError()

  const planStatus = PLAN_STATUS_TYPE(strings)

  const [formState, dispatchFormState] = useReducer(reducer, initialState)

  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)

  useEffect(() => {
    dispatch(requestAllProduces())
    dispatch(requestAllVarieties())
    dispatch(requestAllGrades())
    dispatch(requestAllCurrencies())
  }, [dispatch])

  useEffect(() => {
    if (itemId && plan.id !== itemId) {
      dispatch(requestPlan({ planId: itemId }))
    }
  }, [itemId])

  useEffect(() => {
    if (plan?.id && plan.id === itemId) {
      dispatchFormState({
        type: SET_STATE,
        state: { ...plan }
      })
    }
  }, [plan])

  useEffect(() => {
    if (!itemId && state.organizations.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'organizationId',
        value: state.organizations[0]
      })
    }
  }, [state.organizations])

  useEffect(() => {
    if (!itemId && state.sites.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'siteId',
        value: state.sites[0]
      })
    }
  }, [state.sites])

  useEffect(() => {
    if (waiting && !loading && !error) {
      history.replace(modulePath)
    }
  }, [loading])

  const handleInput = e => {
    const { name, value } = e.currentTarget
    dispatchFormState({ type: UPDATE_INPUT, name, value })
  }

  const handleNumericInput = e => {
    const { name, value } = e.currentTarget
    const newValue = value.length > 0 ? Number(value) : ''
    dispatchFormState({ type: UPDATE_INPUT, name, value: newValue })
  }

  const handleDateInput = (date, name) => {
    dispatchFormState({ type: UPDATE_INPUT, name, value: date })
  }

  const handleOrganizationInput = organizationId => {
    const newWeights = formState.weights.map(weight => {
      return { ...weight, gradeId: '' }
    })
    dispatchFormState({
      type: SET_STATE,
      state: {
        ...formState,
        organizationId,
        siteId: '',
        zoneId: '',
        produceId: '',
        varietyId: '',
        weights: newWeights,
        currencyId: ''
      }
    })
  }

  const updateWeight = weight => {
    dispatchFormState({
      type: UPDATE_WEIGHT,
      weight
    })
  }

  const removeWeight = weight => {
    dispatchFormState({
      type: REMOVE_WEIGHT,
      weight
    })
  }

  const onSelectZone = ({ id, parentId }) => {
    if (parentId) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'zoneId',
        value: id
      })
    } else {
      dispatchFormState({
        type: UPDATE_SITE_ID,
        siteId: id
      })
    }
  }

  const onSubmit = async e => {
    try {
      const payload = await SCHEMA().validate(formState, { abortEarly: false })
      setWaiting(true)

      if (itemId) {
        payload.planId = itemId
        dispatch(updatePlan(payload))
      } else {
        dispatch(createPlan(payload))
      }
    } catch (error) {
      setErrors(getValidationErrorMap(error))
    }
  }

  const filterProduces = () => {
    return produces.filter(p => p.organizationId === formState.organizationId)
  }

  const filterVarieties = () => {
    return varieties.filter(v => v.produce.id === formState.produceId)
  }

  const filterCurrencies = () => {
    return currencies.filter(c => c.organizationId === formState.organizationId)
  }

  const getHeader = () => {
    if (itemId) {
      return strings.updateFormHeader
    }
    return strings.createFormHeader
  }

  return (
    <Flex
      as='form'
      axisGap={400}
      direction='column'
      className='Operations__Form'
    >
      <HeaderV2
        title={getHeader()}
        backPath={modulePath}
        buttonIcon={'save'}
        buttonText={strings.savePlan}
        buttonCallback={onSubmit}
      />
      <Loader isLoading={loading}>
        <Flex axisGap={800} direction={isLaptop ? 'column' : null}>
          <Flex axisGap={400} direction='column' style={{ flex: 1 }}>
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.location}
              </Text>
              <OrganizationInput
                fieldName={FIELDS.organizationId}
                organizationId={formState.organizationId}
                handleInput={handleOrganizationInput}
                errors={errors}
              />
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                className='Operations__Form__Fields'
                direction='column'
              >
                <Label>
                  <Text variant='page' tone={700}>
                    {strings.zone}
                  </Text>
                  <ZoneNavigator
                    organizationId={formState.organizationId}
                    siteId={formState.siteId}
                    zoneId={formState.zoneId}
                    onSelectZone={onSelectZone}
                  />
                  <InputError error={errors?.zoneId} />
                </Label>
              </Flex>
            </Box>
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.plan}
              </Text>
              <Flex
                axisGap={300}
                className='Operations__Form__Fields'
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label>
                  <Text variant='page' tone={700}>
                    {strings.produce}
                  </Text>
                  <Select
                    className='Operations__Form__Select'
                    name={FIELDS.produceId}
                    value={formState.produceId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterProduces().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.produceId} />
                </Label>
                <Label>
                  <Text variant='page' tone={700}>
                    {strings.variety}
                  </Text>

                  <Select
                    className='Operations__Form__Select'
                    name={FIELDS.varietyId}
                    value={formState.varietyId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterVarieties().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.varietyId} />
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
                className='Operations__Form__Fields'
              >
                <Label>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.harvestStartDate}
                    </Text>
                    <DatePicker
                      selected={formState.harvestStartDate}
                      name={FIELDS.harvestStartDate}
                      className='Operations__Form__Select'
                      onChange={date =>
                        handleDateInput(date, FIELDS.harvestStartDate)
                      }
                    />
                    <InputError error={errors?.harvestStartDate} />
                  </Flex>
                </Label>
                <Label>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.harvestEndDate}
                    </Text>
                    <DatePicker
                      selected={formState.harvestEndDate}
                      name={FIELDS.harvestEndDate}
                      className='Operations__Form__Select'
                      onChange={date =>
                        handleDateInput(date, FIELDS.harvestEndDate)
                      }
                    />
                    <InputError error={errors?.harvestEndDate} />
                  </Flex>
                </Label>
                <Label>
                  <Text variant='page' tone={700}>
                    {strings.status}
                  </Text>
                  <Select
                    className='Operations__Form__Select'
                    name={FIELDS.status}
                    value={formState.status}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {Object.entries(planStatus).map(([id, name]) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.status} />
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
                className='Operations__Form__Fields'
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.currency}
                  </Text>
                  <Select
                    className='Operations__Select'
                    name={FIELDS.currencyId}
                    value={formState.currencyId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterCurrencies().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.currencyId} />
                </Label>
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.targetPrice}
                  </Text>
                  <Input
                    className='Operations__Input'
                    type='number'
                    name={FIELDS.targetPrice}
                    value={formState.targetPrice}
                    onChange={handleNumericInput}
                  />
                  <InputError error={errors?.targetPrice} />
                </Label>
              </Flex>
            </Box>
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.estimatedWeights}
              </Text>
              <Flex
                axisGap={300}
                direction='column'
                className='Operations__Form__Weights'
              >
                <Weights
                  organizationId={formState.organizationId}
                  updateWeight={updateWeight}
                  removeWeight={removeWeight}
                  weights={formState.weights}
                  errors={errors}
                />
                <InputError error={errors.weights} />
                <Flex alignMainAxis={'flex-end'}>
                  <Button
                    onClick={() => updateWeight()}
                    size='small'
                    name='add'
                    variant='primary'
                    className='Operations__Form__Weights__Add'
                  >
                    {strings.addWeight}
                  </Button>
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Loader>
    </Flex>
  )
}

export default PlanForm

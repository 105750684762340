/**
 * @name subsetRouteSetDataToObjectRoutes
 * Convert a subset of routeSetData to an object containing all routes
 *
 * @param {Object} children - A subset of routeSetData children
 * @param {Object} rest - A rest parameter containing all properties except "id"
 * @returns {Object} - The converted subset of routeSetData as an object
 */

const subsetRouteSetDataToObjectRoutes = (children, { id, ...rest }) =>
  (children[id] = { id, ...rest }) && children

/**
 * @name byStatus
 * Check if a route status is "active"
 *
 * @param {Object} route - The route object to check for "active" status
 * @returns {boolean} - True if the route status is "active", false otherwise
 */
const byStatus = ({ status }) => status === 'active'

/**
 * Convert a routeSetData object to an array of route objects
 *
 * @param {Object} routeSetData - The routeSetData object to convert to an array
 * @returns {Array} - An array of route objects
 */

const convertRouteSetData = routeSetData =>
  Object.values(routeSetData).map(({ children, ...rest }) => ({
    ...rest,
    children: convertRouteSetData(children)
  }))

/**
 * Normalize the routeSetData to an array of route objects
 *
 * @param {Object} param - The parameter object containing allOrganizations and zonesHierarchy
 * @param {Array} param.allOrganizations - An array of objects containing organization information
 * @param {Object} param.zonesHierarchy - An object containing the zones hierarchy
 * @returns {Array} - An array of normalized route objects
 */
export const normalizeRouteSetData = ({ allOrganizations, zonesHierarchy }) =>
  convertRouteSetData(
    allOrganizations
      .map(({ name, id }) => ({
        name,
        id,
        level: 0,
        children: zonesHierarchy
          .filter(child => child.organizationId === id)
          .filter(byStatus)
          .reduce(subsetRouteSetDataToObjectRoutes, {})
      }))
      .reduce(subsetRouteSetDataToObjectRoutes, {})
  )

/**
 * Convert the locationSelector array to the current locationSelector in the dataset
 *
 * @param {Array} dataset - The dataset array to update
 * @param {Object} selectedId - The selected ID object
 * @param {number} index - The current index
 * @returns {Array} - The updated dataset array
 */
const toCurrentLocationSelector = (dataset, selectedId, index) => {
  selectedId = selectedId.id
  if (dataset[index]) {
    const selectedChild = dataset[index].find(({ id }) => id === selectedId)
    if (selectedChild) dataset.push(selectedChild.children)
  }
  return dataset
}

/**
 * Extract the current route set based on the locationSelector array
 *
 * @param {Object} param - The parameter object containing data and locationSelector
 * @param {Object} param.data - The data object to extract the current route set from
 * @param {Array} param.locationSelector - The locationSelector array to extract the current route set from
 * @returns {Array} - The current route set as an array
 */
export const extractCurrentRouteSet = ({ data, locationSelector }) =>
  locationSelector.reduce(toCurrentLocationSelector, [data])

/**
 * Extracts the current sensor set based on the provided locationSelector.
 *
 * @param {Object} param - The parameter object.
 * @param {Object} param.data - The data to extract from.
 * @param {Map<string, any>} param.locationSelector - The locationSelector.
 * @returns {Array<Object>} The current sensor set.
 */
export const extractCurrentSensorSet = ({ data, locationSelector }) => {
  if (!locationSelector?.length) return []
  const edgeId = locationSelector[locationSelector.length - 1].id
  return (
    data[locationSelector.length - 1].find(({ id }) => edgeId === id).devices ||
    []
  )
}

/**
 * Filters by visibility.
 *
 * @param {Object} param - The parameter object.
 * @param {boolean} [param.visible=true] - Whether it is visible.
 * @returns {boolean} Whether the item is visible.
 */
export const byVisible = ({ visible = true }) => visible

/**
 * Compares two items based on their text.
 *
 * @param {Object} a - The first item to compare.
 * @param {string} a.text - The text of the first item.
 * @param {Object} b - The second item to compare.
 * @param {string} b.text - The text of the second item.
 * @returns {number} -1 if a < b, 1 if a > b, and 0 if a = b.
 */
export const byText = ({ text: textA }, { text: textB }) => {
  if (textA < textB) return -1
  if (textA > textB) return 1
  return 0
}

/**
 * Compares two items based on their label.
 *
 * @param {Object} a - The first item to compare.
 * @param {string} a.name - The name of the first item.
 * @param {Object} b - The second item to compare.
 * @param {string} b.name - The name of the second item.
 * @returns {number} -1 if a < b, 1 if a > b, and 0 if a = b.
 */
export const byLabel = ({ label: labelA }, { label: labelB }) => {
  if (labelA < labelB) return -1
  if (labelA > labelB) return 1
  return 0
}

/**
 * Serializes levels to path.
 *
 * @param {Object} param - The parameter object.
 * @param {Array<string>} param.level_names - The level names.
 * @param {string} param.level - The level.
 * @param {Map<string, any>} param.locationSelector - The locationSelector.
 * @returns {string} The serialized path.
 */
export const serializeLevelsToPath = ({
  level_names,
  level,
  locationSelector
}) => {
  const levelIndex = level_names.indexOf(level)
  return `/${level_names
    .slice(0, levelIndex + 1)
    .map(level => `${level}/${locationSelector.get(level).id}`)
    .join('/')}`
}

/**
 * Converts locationSelector to path.
 *
 * @param {Object} param - The parameter object.
 * @param {Map<string, any>} param.locationSelector - The locationSelector.
 * @returns {string} The path.
 */
export const locationSelectorToPath = ({ locationSelector }) => {
  const nextPath = []
  locationSelector.forEach((value, key) => {
    if (key === 'sensor') {
      nextPath.push(`${key}/${value.type}/${value.id}`)
    } else {
      nextPath.push(`${key}/${value.id}`)
    }
  })
  nextPath.push('dashboard')
  return '/' + nextPath.join('/')
}

/**
 * getZonePathFromLocationSelector
 *
 * Retrieves a zone path from a location selector Map up to a specified level.
 * @param {Object} options - The options object.
 * @param {Map} options.locationSelector - The location selector Map.
 * @param {string} options.level - The key representing the level to reduce the Map to.
 * @returns {string} - The zone path joined with '/'.
 */
const ignoreSet = new Set(['organization', 'sensor'])

export function getZonePathFromLocationSelector({ locationSelector, level }) {
  const workingArray = Array.from(locationSelector.entries())
  const levelIndex = workingArray.findIndex(([key]) => key === level)

  return workingArray
    .slice(0, levelIndex + 1)
    .filter(([key]) => !ignoreSet.has(key))
    .map(([key, value]) => value.id)
    .join('/')
}

export function compareLocationSelectors({
  localLocationSelector,
  locationSelector
}) {
  if (localLocationSelector.size !== locationSelector.size) {
    return false
  }

  let isEquals = true

  localLocationSelector.forEach((value, key) => {
    if (
      !locationSelector.has(key) ||
      locationSelector.get(key)?.id !== value?.id
    ) {
      isEquals = false
    }
  })

  return isEquals
}

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {
  createFlashScriptsUploadUrls,
  createFlashScript,
  updateFlashScript
} from '@/api/deviceManagement/flashScript'

import { isLoading } from '@/slices/util'

import { fetchDeviceManagementOptions } from './index'

export const sendCreateFlashScriptsUploadUrls = createAsyncThunk(
  'sendCreateFlashScriptsUploadUrls',
  async (params, { dispatch }) => {
    dispatch(isLoading(true))
    const { data, error } = await createFlashScriptsUploadUrls()
    dispatch(isLoading(false))
    return { data, error }
  }
)

export const sendCreateFlashScript = createAsyncThunk(
  'sendCreateFlashScript',
  async (params, { dispatch }) => {
    dispatch(isLoading(true))
    const { flashScript, error } = await createFlashScript(params)
    dispatch(isLoading(false))
    return { flashScript, error }
  }
)

export const sendUpdateFlashScript = createAsyncThunk(
  'sendUpdateFlashScript',
  async (params, { dispatch }) => {
    dispatch(isLoading(true))
    const { flashScript, error } = await updateFlashScript(params)
    dispatch(isLoading(false))
    return { flashScript, error }
  }
)

const getUpdatedFlashScripts = (itemsList, newItem) => {
  if (newItem) {
    const index = itemsList.findIndex(
      item =>
        item.version === newItem.version &&
        item.deviceType === newItem.deviceType
    )
    const newList = [...itemsList]
    if (index > -1) {
      newList[index] = newItem
    }
    return newList
  }

  return itemsList
}

const deviceManagementFlashScriptSlice = createSlice({
  name: 'deviceManagementFlashScriptReducer',
  initialState: {
    flashScripts: [],
    flashScriptsError: null,
    uploadData: null,
    updatedFlashScript: {},
    error: null,
    isSaving: false
  },
  reducers: {
    cleanFlashScript: state => {
      state.updatedFlashScript = {}
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchDeviceManagementOptions.pending, state => {
        state.error = null
      })
      .addCase(fetchDeviceManagementOptions.fulfilled, (state, action) => {
        const { flashScripts, flashScriptsError } = action?.payload
        state.flashScripts = flashScriptsError
          ? state.flashScripts
          : flashScripts
      })
      .addCase(sendCreateFlashScriptsUploadUrls.pending, (state, action) => {
        state.uploadData = null
      })
      .addCase(sendCreateFlashScriptsUploadUrls.fulfilled, (state, action) => {
        const { data, error } = action?.payload
        state.uploadData = error ? null : data
      })
      .addCase(sendCreateFlashScript.pending, (state, action) => {
        state.updatedFlashScript = {}
        state.isSaving = true
        state.error = null
      })
      .addCase(sendCreateFlashScript.fulfilled, (state, action) => {
        const { flashScript, error } = action?.payload
        state.updatedFlashScript = error ? null : flashScript
        state.flashScripts = error
          ? state.flashScripts
          : [...state.flashScripts, flashScript]
        state.isSaving = false
        state.error = error
      })
      .addCase(sendUpdateFlashScript.pending, (state, action) => {
        state.updatedFlashScript = {}
        state.isSaving = true
        state.error = null
      })
      .addCase(sendUpdateFlashScript.fulfilled, (state, action) => {
        const { flashScript, error } = action?.payload
        state.updatedFlashScript = error ? null : flashScript
        state.flashScripts = error
          ? state.flashScripts
          : getUpdatedFlashScripts(state.flashScripts, flashScript)
        state.isSaving = false
        state.error = error
      })
  }
})

export const { cleanFlashScript } = deviceManagementFlashScriptSlice.actions

export default deviceManagementFlashScriptSlice.reducer

import { createSlice } from '@reduxjs/toolkit'

const serviceWorkerSlice = createSlice({
  name: 'serviceWorkerReducer',
  initialState: {
    swInit: false,
    swUpdated: false,
    swRegistration: null
  },
  reducers: {
    initSw: (state, action) => {
      return { ...state, swInit: !state.swInit }
    },
    updateSw: (state, action) => {
      return {
        ...state,
        swUpdated: !state.swUpdated,
        swRegistration: action.payload
      }
    }
  }
})

export const { initSw, updateSw } = serviceWorkerSlice.actions

export default serviceWorkerSlice.reducer

import SectionHeaderWrapper from '../Utils/SectionHeaderWrapper'
import { Button, FlexV2, Text } from '@/primitives'

import Strings from '../../Strings'
import { getCurrentUser } from '@/reducers/selectors'
import {
  RESOURCE_TYPE_USER,
  RESOURCE_TYPE_USER_MANAGERS,
  hasEditPermissions
} from '@/Util/PermissionUtils'

const Header = ({ loading, selectedZone, onCreateManager }) => {
  const coretexUser = getCurrentUser()
  const { addManagerButton, managersHeading, managersSubheading } = Strings()

  const showEditOptions = hasEditPermissions(
    coretexUser,
    RESOURCE_TYPE_USER,
    RESOURCE_TYPE_USER_MANAGERS
  )

  return (
    <SectionHeaderWrapper>
      <FlexV2 direction='column' axisGap={100}>
        <Text size={300} fontWeight={700}>
          {managersHeading}
        </Text>
        <Text fontFamily='mono' variant='page' tone={900} size={200}>
          {managersSubheading} {selectedZone?.name}.
        </Text>
      </FlexV2>
      {showEditOptions && (
        <Button
          variant='primary'
          size='small'
          onClick={onCreateManager}
          disabled={loading}
          loading={loading}
        >
          {addManagerButton}
        </Button>
      )}
    </SectionHeaderWrapper>
  )
}

export default Header

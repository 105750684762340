import { useReducer, Fragment, useEffect } from 'react'
import { I18n } from 'aws-amplify'
import { useDispatch } from 'react-redux'

import SiteForm from './SiteForm'
import ZoneForm from './ZoneForm'
import DeviceForm from './DeviceForm'
import ProgressIndicator from './ProgessIndicator'
import DeviceEnrolled from './DeviceEnrolled'
import { Text, Flex, Box, Logo, Loader } from '@/primitives'

import { sendCreateZone } from '@/slices/management/zone'
import { fetchZonesHierarchyWithDevices } from '@/slices/management/hierarchy'
import { fetchDevice, fetchSupplierDevice } from '@/slices/management/device'
import { fetchIsDeviceVerified } from '@/slices/supplier/enrollment'

import {
  getDeviceVerified,
  getDeviceEnrolled,
  getTargetZone,
  getCurrentUser,
  getDeviceFetched
} from '@/reducers/selectors'

import './index.scss'

const SITE = 'site'
const FACILITY = 'facility'
const ROOM = 'room'
const DEVICE = 'device'

const hierarchyOrder = [SITE, FACILITY, ROOM, DEVICE]

const initialState = {
  site: {},
  facility: {},
  room: {},
  currentView: SITE
}

function reducer(state, action) {
  const { type, value } = action
  const updatedState = { ...state }

  if (type === 'reset') {
    return { ...initialState }
  }

  if (type === 'currentView') updatedState[type] = value

  if (updatedState[type]?.id !== value?.id) {
    updatedState[type] = value

    if (type === SITE) {
      return { ...updatedState, facility: {}, room: {} }
    }

    if (type === FACILITY) {
      return { ...updatedState, room: {} }
    }
  }

  if (type === 'updateZoneDetails') {
    const { hierarchyOrder, level, createdZoneId } = value
    const zoneLevelKey = hierarchyOrder[level]
    const zoneDetails = updatedState[zoneLevelKey]
    return {
      ...updatedState,
      currentView: hierarchyOrder[level + 1],
      [zoneLevelKey]: {
        ...zoneDetails,
        id: createdZoneId
      }
    }
  }

  return updatedState
}

const DeviceEnrollment = () => {
  const verified = getDeviceVerified()
  const enrolled = getDeviceEnrolled()
  const createdZone = getTargetZone()
  const currentUser = getCurrentUser()
  const fetched = getDeviceFetched()

  const reduxDispatch = useDispatch()
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const deviceId = window.location.pathname.replace('/enroll-device/', '')
    if (!verified) {
      reduxDispatch(fetchIsDeviceVerified(deviceId))
    }
    if (verified && !enrolled) reduxDispatch(fetchSupplierDevice({ deviceId }))
    if (enrolled) reduxDispatch(fetchDevice({ deviceId }))
  }, [verified, enrolled, reduxDispatch])

  useEffect(() => {
    if (createdZone?.id) {
      const level = createdZone?.parentPath?.split('/').length - 2

      dispatch({
        type: 'updateZoneDetails',
        value: {
          hierarchyOrder,
          level,
          createdZoneId: createdZone.id
        }
      })
    }
  }, [createdZone?.id, createdZone?.parentPath])

  function resetStateOnUneroll() {
    dispatch({ type: 'reset' })
  }

  const onClickContinue = (level, details) => {
    dispatch({ type: level, value: details })

    if (!details.id) {
      const siteDetails = level === SITE ? details : state.site
      const { status, code, latitude, longitude, timeZone, organizationId } =
        siteDetails

      let parentId = null
      if (level === FACILITY) parentId = state.site.id
      if (level === ROOM) parentId = state.facility.id

      const zoneParams = {
        status,
        code,
        latitude,
        longitude,
        timeZone,
        organizationId,
        name: details.name,
        parentId
      }

      reduxDispatch(sendCreateZone({ zone: zoneParams }))
      reduxDispatch(fetchZonesHierarchyWithDevices())
    }

    if (details.id) {
      let nextLevel = FACILITY
      if (level === FACILITY) nextLevel = ROOM
      if (level === ROOM) nextLevel = DEVICE
      dispatch({ type: 'currentView', value: nextLevel })
    }
  }

  const onClickBack = prevLevel => {
    dispatch({ type: 'currentView', value: prevLevel })
  }

  const deviceZoneDetails = {
    zoneId: state?.room?.id,
    zonePath: `${state?.site?.name} / ${state?.facility?.name} / ${state?.room?.name}`,
    latitude: state.latitude,
    longitude: state.longitude
  }

  if (!currentUser?.userName) return <Loader isLoading={true} />

  return (
    <Box>
      <Box>
        <Box
          style={{
            margin: '0 auto',
            width: '100%',
            padding: '0.5rem',
            height: '100%'
          }}
        >
          <Flex
            alignMainAxis='center'
            alignCrossAxis='center'
            axisGap={300}
            style={{ margin: '3rem 0' }}
          >
            <Logo size={500} />
          </Flex>
        </Box>
        <Box className='Enrollment__Container'>
          <Loader isLoading={!fetched}>
            <Fragment>
              {verified && !enrolled && (
                <Fragment>
                  <ProgressIndicator state={state} steps={hierarchyOrder} />
                  {state.currentView === SITE && (
                    <SiteForm
                      site={state.site}
                      onClickContinue={onClickContinue}
                    />
                  )}
                  {[FACILITY, ROOM].includes(state.currentView) && (
                    <ZoneForm
                      parentState={state}
                      onClickContinue={onClickContinue}
                      onClickBack={onClickBack}
                    />
                  )}
                  {state.currentView === DEVICE && (
                    <DeviceForm
                      zoneDetails={deviceZoneDetails}
                      onClickBack={onClickBack}
                    />
                  )}
                </Fragment>
              )}
              {fetched && !verified && (
                <Text as='p' style={{ width: '100%', textAlign: 'center' }}>
                  {I18n.get(
                    'Device ID is not valid. Try scanning the device again or contact your supplier.'
                  )}
                </Text>
              )}
              {enrolled && (
                <DeviceEnrolled resetStateOnUneroll={resetStateOnUneroll} />
              )}
            </Fragment>
          </Loader>
        </Box>
      </Box>
    </Box>
  )
}

export default DeviceEnrollment

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import Measurements from './Measurements'
import DeviceTypes from './DeviceTypes'
import FlashScripts from './FlashScripts'

import { fetchDeviceManagementOptions } from '@/slices/deviceManagement'

import { getCurrentUser } from '@/reducers/selectors'

const DeviceManagementPage = () => {
  const dispatch = useDispatch()
  const coretexUser = getCurrentUser()

  useEffect(() => {
    if (coretexUser?.userName) {
      dispatch(fetchDeviceManagementOptions())
    }
  }, [dispatch, coretexUser?.userName])

  const MODULE_PATH = '/admin/device-management'

  return (
    <Switch>
      <Route
        path={`${MODULE_PATH}/device-types`}
        exact={true}
        component={DeviceTypes}
      />
      <Route
        path={[MODULE_PATH, `${MODULE_PATH}/flash-scripts`]}
        exact={true}
        component={FlashScripts}
      />
      <Route
        path={[MODULE_PATH, `${MODULE_PATH}/measurements`]}
        exact={true}
        component={Measurements}
      />
    </Switch>
  )
}

export default DeviceManagementPage

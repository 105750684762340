import { Fragment } from 'react'
import { Card, FlexV2, Symbol, Slot, Text } from '@/primitives'

import Strings from '../../Strings'

import { isNotNullOrUndefined } from '@/Util/GeneralUtils'

/* Default accuracy to the closest 100m */
const roundToSpecificDecimalPlace = (numberToRound, decimalPlaces = 3) =>
  Number(Math.round(numberToRound + 'e' + decimalPlaces) + 'e-' + decimalPlaces)

export default function ZoneCard({ zone }) {
  const { name, latitude, longitude, timeZone, status } = zone
  const { coordinates, coordsUndefined, active, inactive } = Strings()
  const isActive = status === 'active'

  return (
    <Card className={'ZoneCards__Card'}>
      <Slot name='body'>
        <FlexV2 direction='column' axisGap={200}>
          <FlexV2 axisGap={300} alignCrossAxis='center'>
            <Symbol name='explore' tone={500} variant='primary' />
            <Text fontWeight={700} size={300} truncate={14}>
              {name}
            </Text>
            <Symbol
              name={isActive ? 'bolt' : 'offline_bolt'}
              tone={500}
              variant={isActive ? 'success' : 'neutral'}
              title={isActive ? active : inactive}
            />
          </FlexV2>
          <Text as='p' size={100} fontWeight={200} variant='page' tone={900}>
            {isNotNullOrUndefined(latitude) &&
            isNotNullOrUndefined(longitude) ? (
              <Fragment>
                {coordinates}
                {roundToSpecificDecimalPlace(latitude)},{' '}
                {roundToSpecificDecimalPlace(longitude)}
              </Fragment>
            ) : (
              coordsUndefined
            )}
          </Text>
          <Text as='strong' size={200} variant='page' tone={900}>
            {isNotNullOrUndefined(timeZone) && timeZone}
          </Text>
        </FlexV2>
      </Slot>
    </Card>
  )
}

import { I18n } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { requestCrops } from '@/actions/operations/crop'
import { getOperationsCrops, getZonesHierarchy } from '@/reducers/selectors'

import { useDashboard } from '@/components/DashboardPageV2/Desktop/context'
import withEditableWidget from '@/components/DashboardPageV2/Desktop/Widgets/Shared/HOC/withEditableWidget'
import EditLayout from '@/components/DashboardPageV2/Desktop/Widgets/Shared/EditLayout'
import { useEditResize } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/utils/useEditResize'

import { Flex, Text } from '@/primitives'

import { WidgetDefinition } from '../../Shared/utils/WidgetRegistry'
import ZoneUtils from '@/Util/ZoneUtils'

import {
  //UPDATE_WIDGET,
  CANCEL_WIDGET_UPDATE
} from '@/components/DashboardPageV2/Desktop/state'

import Crop from './Crop'

const MIN_EDITING_WIDTH = 2
const MIN_EDITING_HEIGHT = 3

function CropEdit(props) {
  const { setWidgetMode } = props
  const { state, dispatchState } = useDashboard()

  const { hasBeenEditResized, oldPosition } = useEditResize(
    props.id,
    MIN_EDITING_WIDTH,
    MIN_EDITING_HEIGHT
  )

  const handleSave = () => {
    /*const { props: widgetProps } = state.widgets.get(props.id)

    dispatchState({
      type: UPDATE_WIDGET,
      payload: {
        ...widgetProps,
        position: hasBeenEditResized ? oldPosition : widgetProps.position,
        config: { timeMode }
      }
    })*/
    setWidgetMode('view')
  }

  const handleCancel = () => {
    const { props: widgetProps } = state.widgets.get(props.id)

    // if the widget has been resized, we need to reset the position
    dispatchState({
      type: CANCEL_WIDGET_UPDATE,
      payload: {
        id: props.id,
        position: hasBeenEditResized ? oldPosition : widgetProps.position
      }
    })

    setWidgetMode('view')
  }

  return (
    <EditLayout onCancel={handleCancel} onSave={handleSave}>
      <Flex
        alignCrossAxis='flex-start'
        direction='column'
        axisGap={200}
        wrap='nowrap'
      ></Flex>
    </EditLayout>
  )
}

function CropView() {
  const dispatch = useDispatch()
  const { state } = useDashboard()

  const zonesHierarchy = getZonesHierarchy()
  const crops = getOperationsCrops()

  const [displayCrops, setDisplayCrops] = useState([])
  const [currentCrop, setCurrentCrop] = useState(null)

  const { siteId, currentZone } = state

  useEffect(() => {
    if (siteId) {
      dispatch(
        requestCrops({
          filter: {
            siteId: [siteId],
            status: ['planned', 'sowed', 'transplanted', 'harvesting']
          }
        })
      )
    }
  }, [siteId])

  useEffect(() => {
    if (crops.length > 0) {
      let filteredCrops = [...crops]
      filteredCrops = filteredCrops.filter(c => {
        const zone = ZoneUtils.getZone(zonesHierarchy, c.siteId, c.zoneId)
        if (zone?.parentPath) {
          return zone.parentPath.includes(currentZone.parentPath)
        }
        return true
      })
      setDisplayCrops(filteredCrops)
    }
  }, [crops])

  useEffect(() => {
    if (displayCrops.length === 1) {
      setCurrentCrop(displayCrops[0])
    }
  }, [displayCrops])

  if (crops.length === 0) {
    return (
      <Flex
        alignCrossAxis='center'
        alignMainAxis='center'
        style={{ flex: 1, height: '100%' }}
      >
        <Text
          fontFamily={'mono'}
          variant={'primary'}
          tone={500}
          size={600}
          fontWeight={700}
        >
          {I18n.get('No crops')}
        </Text>
      </Flex>
    )
  }

  const backToList = () => {
    setCurrentCrop(null)
  }

  if (currentCrop?.id) {
    return (
      <Crop
        crop={currentCrop}
        backToList={backToList}
        allowBack={crops.length > 1}
        zonesHierarchy={zonesHierarchy}
      />
    )
  }

  const getZonePath = (siteId, zoneId) => {
    const zone = ZoneUtils.getZone(zonesHierarchy, siteId, zoneId)
    if (zone) {
      if (zone?.parentPath) {
        return zone.parentPath
          .substring(1)
          .split('/')
          .map(z => ZoneUtils.getZoneName(zonesHierarchy, siteId, z))
          .join('/')
      }
      return zone.name
    }
    return siteId
  }

  return (
    <Flex
      className='Crop__View'
      direction='column'
      alignMainAxis='left'
      alignCrossAxis='left'
      style={{ padding: '1em', flex: 1 }}
    >
      {displayCrops.map(crop => (
        <div
          key={crop.id}
          style={{ marginBottom: '0.5em', cursor: 'pointer' }}
          onClick={() => setCurrentCrop(crop)}
        >
          <Text
            fontFamily={'mono'}
            size={200}
            fontWeight={700}
            style={{ display: 'block' }}
          >
            {crop.produce.name} ({crop.variety.name})
            <Text
              fontFamily={'mono'}
              size={100}
              fontWeight={400}
              style={{ float: 'right' }}
            >
              {crop.status}
            </Text>
          </Text>
          <Text fontFamily={'mono'} size={100} fontWeight={400}>
            {getZonePath(crop.siteId, crop.zoneId)}
          </Text>
        </div>
      ))}
    </Flex>
  )
}

export const CropsViewWidget = withEditableWidget(CropView, CropEdit)

export const CropDefinition = () => {
  return new WidgetDefinition({
    widgetId: 'crops',
    title: I18n.get('Crops'),
    icon: 'potted_plant',
    component: CropsViewWidget,
    defaultPosition: {
      md: [0, 0, 2, 3, 2, 3],
      lg: [0, 0, 2, 3, 2, 3],
      xl: [0, 0, 2, 3, 2, 3]
    },
    config: {},
    options: {
      fixedFactor: 1,
      scaleFactor: 1
    },
    denyVisibility: false
  })
}

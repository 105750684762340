import { useDispatch } from 'react-redux'
import { Button, Flex, Form, Slot, Text } from '@/primitives'
import { Dialog } from '@/elements'
import { sendDeleteSensorThresholdStatus } from '@/slices/threshold/sensorThresholdStatus'

import Strings from './Strings'

const DeleteNotificationModal = ({ statuses, sensor, show, setShow }) => {
  const strings = Strings()
  const dispatch = useDispatch()

  const onClickClose = () => {
    setShow(false)
  }

  const onSubmitForm = e => {
    e.preventDefault()
    for (let status of statuses) {
      const { sensorId, measurementId, zoneId } = status
      dispatch(
        sendDeleteSensorThresholdStatus({ sensorId, zoneId, measurementId })
      )
    }
    setShow(false)
  }

  return (
    <Dialog open={show} onOpenChange={onClickClose}>
      <Slot name='title'>
        <Flex direction='column' style={{ marginBottom: '1em' }}>
          <Text
            as='h5'
            style={{
              marginBottom: '0.5em',
              whiteSpace: 'nowrap',
              fontWeight: 400
            }}
          >
            {strings.deleteTitleText}{' '}
            <Text tone={500} variant='danger' style={{ fontWeight: 600 }}>
              {sensor.sensorName}
            </Text>
          </Text>
          <Text as='p' size={100}>
            {strings.deleteSubtitleText}
          </Text>
        </Flex>
      </Slot>
      <Slot name='content'>
        <Form onSubmit={onSubmitForm}>
          <Flex alignMainAxis='space-between'>
            <Button variant='neutral' onClick={onClickClose}>
              {strings.cancelText}
            </Button>
            <Button type='submit' variant='danger'>
              {strings.deleteText}
            </Button>
          </Flex>
        </Form>
      </Slot>
    </Dialog>
  )
}

export default DeleteNotificationModal

import { call, put, takeLatest, all } from 'redux-saga/effects'

import { getHarvests } from '@/api/operations/harvest'
import { getCrops } from '@/api/operations/crop'
import { getZoneHierarchy } from '@/api/management/hierarchy'

import {
  REQUEST_DATABOARD_HARVESTS,
  receiveDataboardHarvests,
  REQUEST_DATABOARD_CROPS,
  receiveDataboardCrops,
  REQUEST_DATABOARD_SITE_HIERARCHY,
  receiveDataboardSiteHierarchy
} from '@/actions/operations/databoard'

import { displayBanner } from '../utils'

import { isLoading } from '@/slices/util'
import Strings from './Strings'

const strings = Strings()

function* sendRequestDataboardCrops(action) {
  yield put(isLoading(true))

  const { filters, filterRef } = action.params
  const zonesBySite = getZonesGroupedBySite(filters)
  const { produceId, varietyId, status, dateRange } = filters[0]

  const cropCalls = Object.keys(zonesBySite).map(siteId => {
    const { zones, organizationId } = zonesBySite[siteId]
    const filter = {
      produceId,
      varietyId,
      status,
      organizationId,
      siteId: [siteId],
      dateRange
    }

    if (!zones.includes('all')) {
      const uniqueZones = new Set(zones)
      filter['zoneId'] = [...uniqueZones]
    }

    return call(getCrops, { filter })
  })

  const responses = yield all(cropCalls)
  const crops = responses.map(({ data }) => data.crops)
  const cropWithError = responses.find(({ error }) => error)
  const error = cropWithError?.error ?? null

  yield put(receiveDataboardCrops(crops.flat(), filterRef, error))
  yield put(isLoading(false))
  if (error) yield put(displayBanner(`${strings.cropsNotFetched}: ${error}`))
}

export function* watchRequestDataboardCrops() {
  yield takeLatest(REQUEST_DATABOARD_CROPS, sendRequestDataboardCrops)
}

function* sendRequestDataboardHarvests(action) {
  yield put(isLoading(true))

  const { filters, filterRef } = action.params

  const zonesBySite = getZonesGroupedBySite(filters)
  const { harvestDate, produceId, varietyId } = filters[0]

  const harvestCalls = Object.keys(zonesBySite).map(siteId => {
    const { zones, organizationId } = zonesBySite[siteId]
    const filter = {
      harvestDate,
      produceId,
      varietyId,
      organizationId,
      siteId: [siteId]
    }

    if (!zones.includes('all')) {
      const uniqueZones = new Set(zones)
      filter['zoneId'] = [...uniqueZones]
    }

    return call(getHarvests, { filter })
  })

  const responses = yield all(harvestCalls)
  const harvests = responses.map(({ data }) => data.harvests)
  const harvestWithError = responses.find(({ error }) => error)
  const error = harvestWithError?.error ?? null

  yield put(receiveDataboardHarvests(harvests.flat(), filterRef, error))
  yield put(isLoading(false))
  if (error) yield put(displayBanner(`${strings.harvestNotFetched}: ${error}`))
}

export function* watchRequestDataboardHarvests() {
  yield takeLatest(REQUEST_DATABOARD_HARVESTS, sendRequestDataboardHarvests)
}

const getZonesGroupedBySite = filters => {
  const zonesBySite = {}

  filters.forEach(filter => {
    const { organizationId, siteId, zoneId = null } = filter
    const newZones = zoneId ? [...zoneId] : ['all']
    const currentSiteId = siteId[0]
    if (!zonesBySite.hasOwnProperty(currentSiteId)) {
      zonesBySite[currentSiteId] = { zones: [], organizationId }
    }
    zonesBySite[currentSiteId].zones = [
      ...zonesBySite[currentSiteId].zones,
      ...newZones
    ]
  })

  return zonesBySite
}

function* sendRequestDataboardSiteHierarchy(action) {
  yield put(isLoading(true))

  const { siteId } = action.params
  let zones = []
  let error = null
  try {
    zones = yield call(getZoneHierarchy, {
      zoneId: siteId
    })
  } catch (err) {
    error = err
  }
  yield put(receiveDataboardSiteHierarchy(zones, error))
  yield put(isLoading(false))
}

export function* watchRequestDataboardSiteHierarchy() {
  yield takeLatest(
    REQUEST_DATABOARD_SITE_HIERARCHY,
    sendRequestDataboardSiteHierarchy
  )
}

import { Auth, Hub, I18n } from 'aws-amplify'
import {
  AuthState,
  AUTH_STATE_CHANGE_EVENT,
  UI_AUTH_CHANNEL
} from '@aws-amplify/ui-components'
import { isEmpty } from '@aws-amplify/core'

function dispatchAuthChannel(message, data = {}) {
  Hub.dispatch(UI_AUTH_CHANNEL, {
    event: AUTH_STATE_CHANGE_EVENT,
    message,
    data
  })
}

export class AuthService {
  cognitoUser = null

  getCognitoId() {
    return this.cognitoUser?.attributes?.sub
  }

  getCognitoEmail() {
    return this.cognitoUser?.attributes?.email
  }

  getCognitoPhoneNumber() {
    return this.cognitoUser?.attributes?.phone_number
  }

  getCognitoWhatsAppNumber() {
    return this.cognitoUser?.attributes?.['custom:whatsapp_number']
  }

  getCognitoPreferredLanguage() {
    return this.cognitoUser?.attributes?.['custom:preferred_language'] ?? 'en'
  }

  getCognitoOpenSidebar() {
    const openSidebar =
      this.cognitoUser?.attributes?.['custom:open_sidebar'] ?? 'no'
    return openSidebar === 'yes'
  }

  getCognitoColorScheme() {
    return this.cognitoUser?.attributes?.['custom:color_scheme'] ?? 'system'
  }

  getCognitoPreferredMFA() {
    return this.cognitoUser?.preferredMFA
  }

  getCognitoUsername() {
    const preferred_username = this?.cognitoUser?.attributes?.preferred_username
    const username = this?.cognitoUser?.username
    return preferred_username?.length > 0 ? preferred_username : username
  }

  getCognitoAuthToken() {
    return this.cognitoUser?.signInUserSession?.accessToken?.jwtToken
  }

  getCognitoIdToken() {
    return this.cognitoUser?.signInUserSession?.idToken?.jwtToken
  }

  async currentUser() {
    try {
      this.cognitoUser = await Auth.currentAuthenticatedUser({
        bypassCache: true
      })
      dispatchAuthChannel(AuthState.SignedIn, this.cognitoUser)
    } catch (error) {
      this.cognitoUser = null
    }
  }

  async isAuthenticated() {
    try {
      this.cognitoUser = await Auth.currentAuthenticatedUser({
        bypassCache: true
      })
      return true
    } catch (error) {
      this.cognitoUser = null
      return false
    }
  }

  async verifyContact() {
    try {
      const data = await Auth.verifiedContact(this.cognitoUser)
      if (data.unverified.email) {
        dispatchAuthChannel(AuthState.VerifyContact, this.cognitoUser)
      } else if (!isEmpty(data.verified)) {
        dispatchAuthChannel(AuthState.SignedIn, this.cognitoUser)
      } else {
        await this.signOut()
      }
    } catch (error) {
      throw error
    }
  }

  async signIn(username, password) {
    try {
      this.cognitoUser = await Auth.signIn(username, password)
      const user = this.cognitoUser
      const { challengeName } = user

      if (
        challengeName === 'SMS_MFA' ||
        challengeName === 'SOFTWARE_TOKEN_MFA'
      ) {
        dispatchAuthChannel(AuthState.ConfirmSignIn, user)
      } else if (challengeName === 'NEW_PASSWORD_REQUIRED') {
        dispatchAuthChannel('requireNewPassword', user)
      } else if (challengeName === 'MFA_SETUP') {
        dispatchAuthChannel('TOTPSetup', user)
      } else {
        await this.verifyContact()
      }
    } catch (error) {
      if (error.code === 'PasswordResetRequiredException') {
        this.forgotPassword(username, 'PasswordResetRequiredException')
      }
      if (error.code === 'NotAuthorizedException') {
        const isDisabled = error.message
          .toLocaleLowerCase()
          .includes('disabled')

        dispatchAuthChannel('error', {
          code: isDisabled ? 'UserDisabled' : 'NotAuthorizedException'
        })
      }
      throw error
    }
  }

  async forgotPassword(username, type = 'UserForgotPassword') {
    try {
      const { CodeDeliveryDetails } = await Auth.forgotPassword(username)
      dispatchAuthChannel(AuthState.ResetPassword, {
        type,
        deliveryDetails: CodeDeliveryDetails,
        username
      })
    } catch (error) {
      if (error.code === 'LimitExceededException') {
        dispatchAuthChannel('error', { code: 'LimitExceededException' })
      }
      throw error
    }
  }

  async forgotPasswordSubmit(username, code, password) {
    try {
      await Auth.forgotPasswordSubmit(username, code, password)
      dispatchAuthChannel('success', {
        type: 'UserForgotPasswordSuccess',
        username
      })
    } catch (error) {
      throw error
    }
  }

  async completeNewPassword(newPassword, attrs) {
    try {
      const user = this.cognitoUser
      await Auth.completeNewPassword(user, newPassword, attrs)
      if (user.challengeName === 'SMS_MFA') {
        dispatchAuthChannel(AuthState.ConfirmSignIn, user)
      } else if (user.challengeName === 'MFA_SETUP') {
        dispatchAuthChannel('TOTPSetup', user)
      } else {
        await this.verifyContact()
      }
    } catch (error) {
      throw error
    }
  }

  async changePassword(oldPassword, newPassword) {
    try {
      await Auth.changePassword(this.cognitoUser, oldPassword, newPassword)
      dispatchAuthChannel('success', {
        type: 'UserChangePasswordSuccess'
      })
    } catch (error) {
      throw error
    }
  }

  async verifyUserAttribute(attr) {
    try {
      await Auth.verifyUserAttribute(this.cognitoUser, attr)
    } catch (error) {
      dispatchAuthChannel('error', {
        code: error.code
      })
      throw error
    }
  }

  async verifyCurrentUserAttributeSubmit(attr, code) {
    try {
      await Auth.verifyCurrentUserAttributeSubmit(attr, code)
      await this.verifyContact()
    } catch (error) {
      throw error
    }
  }

  async signUp({
    username,
    password,
    attributes,
    firstName,
    lastName,
    organization
  }) {
    try {
      await Auth.signUp({
        username: username.toLowerCase(),
        password,
        attributes,
        clientMetadata: { organization }
      })
      const details = {
        username,
        password,
        firstName,
        lastName,
        organization,
        email: attributes.email
      }

      dispatchAuthChannel(AuthState.ConfirmSignUp, {
        details
      })
    } catch (error) {
      throw error
    }
  }

  async confirmSignUp(params) {
    try {
      const { username, code, ...rest } = params
      await Auth.confirmSignUp(username, code, {
        forceAliasCreation: false,
        clientMetadata: { ...rest }
      })

      await Auth.signIn(username, params.password)
      dispatchAuthChannel(AuthState.SignedIn)
    } catch (error) {
      const errors = {
        general: I18n.get('Could not create user. Please try again.')
      }
      dispatchAuthChannel(AuthState.SignUp, { details: { ...params, errors } })
    }
  }

  async confirmSignIn(code) {
    try {
      const user = this.cognitoUser
      const mfaType =
        user.challengeName === 'SOFTWARE_TOKEN_MFA'
          ? 'SOFTWARE_TOKEN_MFA'
          : null
      await Auth.confirmSignIn(user, code, mfaType)
      await this.verifyContact()
    } catch (error) {
      if (error.message.includes('session is expired')) {
        await this.signOut()
      }
      throw error
    }
  }

  async signOut() {
    try {
      await Auth.signOut()
      dispatchAuthChannel(AuthState.SignedOut)
      this.cognitoUser = null
    } catch (error) {
      throw error
    }
  }
}

import { useState, useEffect, Fragment } from 'react'
import { useDispatch } from 'react-redux'

import {
  Button,
  Text,
  Flex,
  Card,
  Slot,
  Grid,
  Item,
  Loader
} from '@/primitives'
import TagUpsertForm from './TagUpsertForm'

import { cleanUpsertedTag } from '@/slices/fyi/tags'
import {
  getFyiTags,
  getFyiTagUpserted,
  getFyiTagsLoading
} from '@/reducers/selectors'

import Strings from '../../Strings'

const strings = Strings()

const Tags = () => {
  const [showUpsertForm, setShowUpsertForm] = useState(false)
  const [selectedTag, setSelectedTag] = useState(null)

  const tags = getFyiTags()
  const dispatch = useDispatch()
  const tagUpserted = getFyiTagUpserted()
  const loading = getFyiTagsLoading()

  const setShowForm = () => setShowUpsertForm(true)
  const setHideForm = () => setShowUpsertForm(false)

  useEffect(() => {
    if (tagUpserted?.name?.length > 0) {
      setHideForm()
      setSelectedTag(null)
      dispatch(cleanUpsertedTag())
    }
  }, [tagUpserted?.name, dispatch])

  function insertTag({ name, tagId }) {
    function onSelectTag() {
      setSelectedTag({ name, tagId })
      setShowUpsertForm(true)
    }

    return (
      <Item key={tagId}>
        <Card>
          <Slot name='body'>
            <Flex wrap='nowrap' alignMainAxis='space-between' axisGap={500}>
              <Text fontWeight={700} size={300}>
                {name}
              </Text>
              <Button
                size='small'
                variant='primary'
                onClick={onSelectTag}
                iconBefore='edit'
                style={{ padding: '0.5rem' }}
              />
            </Flex>
          </Slot>
        </Card>
      </Item>
    )
  }

  return (
    <Fragment>
      <Flex alignMainAxis='space-between' alignCrossAxis='center'>
        <Text as='p'>{strings.manageTags}</Text>
        <Flex axisGap={300}>
          <Button
            size='small'
            variant='primary'
            onClick={setShowForm}
            iconBefore='add'
          >
            {strings.addTag}
          </Button>
        </Flex>
      </Flex>
      <Loader isLoading={loading}>
        <Grid
          gap={400}
          style={{
            marginTop: '1.5rem',
            gridTemplateColumns: 'repeat(auto-fill, minmax(288px, 1fr))'
          }}
        >
          {tags.map(tag => insertTag(tag))}
        </Grid>
      </Loader>
      <TagUpsertForm
        showModal={showUpsertForm}
        setHideForm={setHideForm}
        tagToEdit={selectedTag}
      />
    </Fragment>
  )
}

export default Tags

import { Button, Flex, Slot, Text } from '@/primitives'
import { Dialog } from '@/elements'

import Strings from '../../Strings'
import DeploymentCard from './DeploymentCard'

const DeploymentsModal = ({ deployments, showModal, setShowModal }) => {
  const strings = Strings()
  const { close, greengrassDeploymentsModalTitle } = strings

  const onClickClose = () => {
    setShowModal('')
  }

  return (
    <Dialog
      open={showModal}
      onOpenChange={setShowModal}
      // className='Dialog__Deployments__Modal'
    >
      <Slot name='title'>
        <Text size={300} fontWeight={700}>
          {greengrassDeploymentsModalTitle}
        </Text>
      </Slot>

      <Slot name='content'>
        <Flex direction='column' axisGap={300}>
          {deployments?.map(deployment => (
            <DeploymentCard
              key={deployment?.deploymentId}
              deployment={deployment}
            />
          ))}
        </Flex>
      </Slot>

      <Slot name='actions'>
        <Flex axisGap={400} alignMainAxis={'space-between'}>
          <Button onClick={onClickClose}>{close}</Button>
        </Flex>
      </Slot>
    </Dialog>
  )
}

export default DeploymentsModal

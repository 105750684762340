import { Symbol, FlexV2, Text } from '@/primitives'

import Strings from '../Strings'

/**
 * Renders a "No Results" message for the StatusPage component.
 *
 * @returns {React.ReactElement} A React component that displays a "No Results" message.
 */
export default function NoResults() {
  const strings = Strings()

  return (
    <FlexV2
      className='StatusPage__Content__NoResults'
      direction={'column'}
      alignCrossAxis='center'
      alignMainAxis='center'
    >
      <Symbol size={900} name='layers' />
      <Text>{strings.noResults}</Text>
    </FlexV2>
  )
}

import { I18n } from 'aws-amplify'

import Produce from './Produce'
import Grade from './Grade'
import Country from './Country'
import Currency from './Currency'

const routes = [
  {
    path: '/operations/:zone+/management/produce',
    text: I18n.get('Produce'),
    Component: Produce
  },
  {
    path: '/operations/:zone+/management/grades',
    text: I18n.get('Grades'),
    Component: Grade
  },
  {
    path: '/operations/:zone+/management/countries',
    text: I18n.get('Countries'),
    Component: Country
  },
  {
    path: '/operations/:zone+/management/currencies',
    text: I18n.get('Currencies'),
    Component: Currency
  }
]

export default routes

import LEVEL_NAMES from '../level_names.json'

function walkLocationPathToRouteArray(level, children, idArray, strings) {
  let nextFields = [
    {
      name: strings[LEVEL_NAMES[level]],
      value: idArray[level]
        ? { value: idArray[level].id, label: idArray[level].name, level }
        : null,
      options: children.map(({ id: value, name: label, status }) => ({
        value,
        label,
        level,
        status
      }))
    }
  ]
  if (idArray[level]) {
    const selectedIndex = children.findIndex(
      zone => zone.id === idArray[level].id
    )
    if (selectedIndex >= 0) {
      nextFields[0].devices = children[selectedIndex].devices
      if (children[selectedIndex]?.children?.length) {
        nextFields = nextFields.concat(
          walkLocationPathToRouteArray(
            level + 1,
            children[selectedIndex].children,
            idArray,
            strings
          )
        )
      }
    }
  }
  return nextFields
}

function resolveSensorValue({ localLocationSelector, data }) {
  const selectedID = localLocationSelector.get('sensor')?.id
  const type = localLocationSelector.get('sensor')?.type
  const { id: value, name: label } = data.find(
    ({ id }) => id === selectedID
  ) || { id: null, value: null }
  return !!value && !!type && !!label
    ? {
        label,
        type,
        level: -1,
        value
      }
    : null
}

export function generateSelectFieldsFromLocationSelector({
  localLocationSelector,
  route,
  strings
}) {
  const idArray = localLocationSelector.size
    ? LEVEL_NAMES.map(level => localLocationSelector.get(level) || null)
    : []

  let nextFields = [
    {
      name: strings[LEVEL_NAMES[0]],
      value: idArray[0]
        ? { value: idArray[0].id, label: idArray[0].name, level: 0 }
        : null,
      options: route.map(({ id: value, name: label }) => ({
        value,
        label,
        level: 0
      }))
    }
  ]

  if (idArray[0]) {
    const selectedIndex = route.findIndex(org => org.id === idArray[0].id)
    if (selectedIndex >= 0) {
      nextFields = nextFields.concat(
        walkLocationPathToRouteArray(
          1,
          route[selectedIndex].children,
          idArray,
          strings
        )
      )
    }
  }

  const lastFieldIndex = idArray.findLastIndex(v => v !== null)

  if (nextFields[lastFieldIndex]?.devices?.length) {
    nextFields.push({
      name: strings.sensor,
      value: resolveSensorValue({
        localLocationSelector,
        data: nextFields[lastFieldIndex].devices
      }),
      options: nextFields[lastFieldIndex].devices.map(
        ({ id: value, name: label, sensorType }) => ({
          value,
          label,
          type: sensorType,
          level: -1,
          status: 'active'
        })
      )
    })
  }

  return nextFields
}

import { updateList } from '../util'

import {
  RECEIVE_ALL_CITIES,
  REQUEST_ALL_CITIES,
  RECEIVE_CITIES,
  REQUEST_CITIES,
  CLEAR_CITIES,
  CLEAR_CITY,
  REQUEST_CITY,
  RECEIVE_CITY,
  CREATE_CITY,
  CITY_CREATED,
  UPDATE_CITY,
  CITY_UPDATED,
  SET_CITY
} from '@/actions/operations/city'

export const cityInitialState = {
  allCities: [],
  cities: [],
  city: {},
  citiesCount: 0,
  loading: false,
  error: null
}

const handlers = {
  [SET_CITY]: (state, action) => ({
    ...state,
    city: action.city
  }),
  [CLEAR_CITY]: state => ({
    ...state,
    city: {},
    error: null
  }),
  [CLEAR_CITIES]: state => ({
    ...state,
    cities: [],
    error: null
  }),
  [REQUEST_ALL_CITIES]: state => ({
    ...state,
    error: null,
    loading: true
  }),
  [RECEIVE_ALL_CITIES]: (state, action) => ({
    ...state,
    allCities: !action.error ? action.cities : [],
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_CITY]: state => ({
    ...state,
    city: {},
    error: null,
    loading: true
  }),
  [RECEIVE_CITY]: (state, action) => ({
    ...state,
    city: !action.error ? action.city : state.city,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_CITIES]: state => ({
    ...state,
    cities: [],
    loading: true,
    error: null
  }),
  [RECEIVE_CITIES]: (state, action) => ({
    ...state,
    cities: !action.error ? action.cities : [],
    citiesCount: !action.error ? action.count : 0,
    error: action.error ?? null,
    loading: false
  }),
  [CREATE_CITY]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [CITY_CREATED]: (state, action) => ({
    ...state,
    city: !action.error ? {} : state.city,
    cities: updateList(action.error, state.cities, action.city, true),
    allCities: updateList(action.error, state.allCities, action.city, true),
    citiesCount: !action.error ? state.citiesCount + 1 : state.citiesCount,
    error: action.error ?? null,
    loading: false
  }),
  [UPDATE_CITY]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [CITY_UPDATED]: (state, action) => ({
    ...state,
    city: !action.error ? {} : state.city,
    cities: updateList(action.error, state.cities, action.city, false),
    allCities: updateList(action.error, state.allCities, action.city, false),
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

import { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { I18n } from 'aws-amplify'
import { Box, Text, Icon, Button, Flex } from '@/primitives'
import { LineSeparator } from '@/elements'
import UpdateCoordinatesForm from './UpdateCoordinatesForm'

import { sendUnenrollSupplierDevice } from '@/slices/management/device'

import {
  getDevice,
  getDeviceLoading,
  getCurrentUser,
  getZonesHierarchy
} from '@/reducers/selectors'
import {
  getIsGodMode,
  hasEditPermissions,
  hasReadPermissions
} from '@/Util/PermissionUtils'

const DeviceEnrolled = ({ resetStateOnUneroll }) => {
  const [showCoordinatesForm, setShowCoordinatesForm] = useState(false)

  const device = getDevice()
  const isLoading = getDeviceLoading()
  const history = useHistory()
  const dispatch = useDispatch()
  const user = getCurrentUser()
  const zonesHierarchy = getZonesHierarchy()

  function onClickDeviceDashboard() {
    const path = `/zones${device.zonePath}/sensor/${device.sensorType}/${device.id}`
    history.push(path)
  }

  function onClickUnenrollDevice() {
    dispatch(sendUnenrollSupplierDevice({ deviceId: device.id }))
    resetStateOnUneroll()
  }

  function onClickUpdateCoordinates() {
    setShowCoordinatesForm(true)
  }

  function onHideUpdateCoordinates() {
    setShowCoordinatesForm(false)
  }

  const siteId = device?.zonePath?.split('/')[1]
  const isGodMode = getIsGodMode(user)
  const userHasZoneAccess = zonesHierarchy.hasOwnProperty(siteId)
  const editPermission = hasEditPermissions(user, 'sensor', 'management')
  const viewPermission = hasReadPermissions(user, 'sensor', 'management')
  const userCanEdit =
    isGodMode || (viewPermission && editPermission && userHasZoneAccess)
  const userCanView = isGodMode || (viewPermission && userHasZoneAccess)

  return (
    <Box style={{ textAlign: 'center', maxWidth: '30rem', margin: 'auto' }}>
      {(!device?.id || (!userCanEdit && !userCanView)) && !isLoading && (
        <Fragment>
          <Icon variant='danger' tone={600} size={600} name='warning' />
          <Text
            as='p'
            variant='danger'
            tone={700}
            style={{ width: '100%', margin: '1rem 0' }}
          >
            {I18n.get(
              'Device with the provided ID does not exist or you do not have permission to view it. Please contact support.'
            )}
          </Text>
        </Fragment>
      )}
      {device?.id && userCanView && (
        <Fragment>
          {showCoordinatesForm && (
            <UpdateCoordinatesForm
              isVisible={showCoordinatesForm}
              hideForm={onHideUpdateCoordinates}
              device={device}
            />
          )}
          <div style={{ marginBottom: '2em' }}>
            <Icon variant='success' tone={600} size={900} name='verified' />
            <Text as='h5' style={{ width: '100%', margin: '1rem 0 2rem' }}>
              {I18n.get('The device')}
              <Text variant='primary' tone={600} style={{ margin: '0 0.5rem' }}>
                {device.name}
              </Text>
              {I18n.get('is enrolled')}
            </Text>
            <Text
              as='p'
              variant='page'
              tone={900}
              style={{ width: '100%', margin: '2rem 0' }}
            >
              {I18n.get(
                'The device must be connected and powered on for you to see data.'
              )}
            </Text>
            <Flex axisGap={400} alignMainAxis='center'>
              <Button
                variant='primary'
                disabled={!device?.id}
                onClick={onClickDeviceDashboard}
              >
                {I18n.get('Go to device dashboard')}
              </Button>
              {userCanEdit && (
                <Button
                  variant='primary'
                  disabled={!device?.id}
                  onClick={onClickUpdateCoordinates}
                >
                  {I18n.get('Update coordinates')}
                </Button>
              )}
            </Flex>
          </div>
          {userCanEdit && (
            <Fragment>
              <LineSeparator style={{ marginTop: '1em' }} />
              <Text
                as='p'
                variant='page'
                tone={900}
                style={{ width: '100%', margin: '2rem 0' }}
              >
                {I18n.get(
                  'Made a mistake when enrolling the device? Unenroll it and start the process again.'
                )}
              </Text>
              <Button
                variant='danger'
                disabled={!device?.id}
                onClick={onClickUnenrollDevice}
              >
                {I18n.get('Unenroll device')}
              </Button>
            </Fragment>
          )}
        </Fragment>
      )}
    </Box>
  )
}

export default DeviceEnrolled

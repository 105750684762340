import { makeOperationsApiCall } from './utils'

/*
 MUTATIONS
 */

export const createPlan = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createPlan(
        $organizationId: String!
        $siteId: String!
        $zoneId: String!
        $produceId: String!
        $varietyId: String!
        $currencyId: String!
        $totalWeight: Int!
        $weights: [WeightInput]!
        $status: PlanStatus!
        $harvestStartDate: GraphQLTimestamp!
        $harvestEndDate: GraphQLTimestamp!
        $targetPrice: Float!
      ) {
        createPlan(
          organizationId: $organizationId
          siteId: $siteId
          zoneId: $zoneId
          produceId: $produceId
          varietyId: $varietyId
          currencyId: $currencyId
          totalWeight: $totalWeight
          weights: $weights
          harvestStartDate: $harvestStartDate
          harvestEndDate: $harvestEndDate
          targetPrice: $targetPrice
          status: $status
        ) {
          id
          organizationId
          siteId
          zoneId
          produce {
            id
            name
          }
          variety {
            id
            name
          }
          currency {
            id
            code
          }
          weights {
            id
            totalKg
            gradeId
          }
          totalWeight
          harvestStartDate
          harvestEndDate
          status
          targetPrice
          createdAt
          updatedAt
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'createPlan',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updatePlan = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updatePlan(
        $planId: String!
        $organizationId: String!
        $siteId: String!
        $zoneId: String!
        $produceId: String!
        $varietyId: String!
        $currencyId: String!
        $totalWeight: Int!
        $weights: [WeightInput]!
        $status: PlanStatus!
        $harvestStartDate: GraphQLTimestamp!
        $harvestEndDate: GraphQLTimestamp!
        $targetPrice: Float!
      ) {
        updatePlan(
          planId: $planId
          organizationId: $organizationId
          siteId: $siteId
          zoneId: $zoneId
          produceId: $produceId
          varietyId: $varietyId
          currencyId: $currencyId
          totalWeight: $totalWeight
          weights: $weights
          harvestStartDate: $harvestStartDate
          harvestEndDate: $harvestEndDate
          targetPrice: $targetPrice
          status: $status
        ) {
          id
          organizationId
          siteId
          zoneId
          produce {
            id
            name
          }
          variety {
            id
            name
          }
          currency {
            id
            code
          }
          weights {
            id
            totalKg
            gradeId
          }
          totalWeight
          harvestStartDate
          harvestEndDate
          status
          targetPrice
          createdAt
          updatedAt
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'updatePlan',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

/*
 QUERIES
 */

export const getPlans = async params => {
  try {
    const query = /* GraphQL */ `
      query getPlans(
        $filter: GetPlansFilter
        $order: OrderEnum
        $offset: Int
        $limit: Int
      ) {
        getPlans(
          filter: $filter
          order: $order
          offset: $offset
          limit: $limit
        ) {
          plans {
            id
            organizationId
            siteId
            zoneId
            produce {
              id
              name
            }
            variety {
              id
              name
            }
            currency {
              id
              code
            }
            weights {
              id
              totalKg
              gradeId
            }
            totalWeight
            harvestStartDate
            harvestEndDate
            status
            targetPrice
            createdAt
            updatedAt
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getPlans',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getPlan = async params => {
  try {
    const query = /* GraphQL */ `
      query getPlan($planId: String!) {
        getPlan(planId: $planId) {
          id
          organizationId
          siteId
          zoneId
          produceId
          varietyId
          currencyId
          weights {
            id
            totalKg
            gradeId
          }
          totalWeight
          harvestStartDate
          harvestEndDate
          status
          targetPrice
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getPlan',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getPlanVsHarvest = async params => {
  try {
    const query = /* GraphQL */ `
      query getPlanVsHarvest(
        $range: String!
        $organizationId: [String]!
        $zoneId: [String]!
      ) {
        getPlanVsHarvest(
          range: $range
          organizationId: $organizationId
          zoneId: $zoneId
        ) {
          name
          Plan
          Harvest
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getPlanVsHarvest',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

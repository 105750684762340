export const initialState = {
  organizations: [],
  statuses: ['active'],
  page: 1
}

const reducer = (state, action) => {
  if (action.state) {
    return {
      ...state,
      ...action.state
    }
  } else if (action.page) {
    return {
      ...state,
      page: action.page
    }
  } else if (action.organizations) {
    return {
      ...state,
      organizations: action.organizations
    }
  } else if (action.statuses) {
    return {
      ...state,
      statuses: action.statuses
    }
  }
  return state
}

export default reducer

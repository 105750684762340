import { Button, FlexV2, Text } from '@/primitives'

import SectionHeaderWrapper from '../Utils/SectionHeaderWrapper'

import { getCurrentUser } from '@/reducers/selectors'

import {
  hasEditPermissions,
  RESOURCE_TYPE_DEVICE,
  RESOURCE_TYPE_DEVICE_MANAGEMENT,
  getIsGodMode
} from '@/Util/PermissionUtils'

import Strings from '../../Strings'

const Header = ({
  isLoading,
  selectedZone,
  onCreateDevice,
  onEnrollDevice
}) => {
  const coretexUser = getCurrentUser()
  const {
    addDeviceButton,
    devicesHeading,
    devicesSubheading,
    enrollDeviceButton
  } = Strings()

  const showEditOptions = hasEditPermissions(
    coretexUser,
    RESOURCE_TYPE_DEVICE,
    RESOURCE_TYPE_DEVICE_MANAGEMENT
  )

  const isGodMode = getIsGodMode(coretexUser)

  return (
    <SectionHeaderWrapper>
      <FlexV2 direction='column' axisGap={100}>
        <Text size={300} fontWeight={700}>
          {devicesHeading}
        </Text>
        <Text
          fontFamily='mono'
          variant='page'
          tone={900}
          size={200}
        >{`${devicesSubheading} ${selectedZone?.name}.`}</Text>
      </FlexV2>
      <FlexV2 direction='row' axisGap={300}>
        {showEditOptions && (
          <Button
            variant='primary'
            size='small'
            onClick={onCreateDevice}
            disabled={isLoading}
            loading={isLoading}
          >
            {addDeviceButton}
          </Button>
        )}
        {isGodMode && (
          <Button
            variant='primary'
            size='small'
            onClick={onEnrollDevice}
            disabled={isLoading}
            loading={isLoading}
          >
            {enrollDeviceButton}
          </Button>
        )}
      </FlexV2>
    </SectionHeaderWrapper>
  )
}

export default Header

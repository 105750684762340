import { nanoid } from 'nanoid'
import React, { Fragment, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { deleteSaleShipment } from '../../../../../../actions/operations/sale'

import {
  getOperationsLoading,
  getOperationsError
} from '../../../../../../reducers/selectors'

import { Button, Flex } from '../../../../../../primitives'

import Table from './Table'
import Form from './Form'

import { REMOVE_SALE_SHIPMENT } from '../state'

import Strings from './Strings'

function SaleShipments({ sale, dispatchFormState }) {
  const dispatch = useDispatch()
  const strings = Strings()

  const loading = getOperationsLoading()
  const error = getOperationsError()

  const [saleShipment, setSaleShipment] = useState({})
  const [deleteShipmentId, setDeleteShipmentId] = useState(null)
  const [waiting, setWaiting] = useState(false)

  useEffect(() => {
    if (waiting && !loading) {
      if (!error) {
        removeShipmentFromSale(deleteShipmentId)
      }
      setDeleteShipmentId(null)
      setWaiting(false)
    }
  }, [loading])

  useEffect(() => {
    if (sale.id) {
      if (deleteShipmentId) {
        dispatch(
          deleteSaleShipment({
            saleShipmentId: deleteShipmentId
          })
        )
      }
    } else {
      removeShipmentFromSale(deleteShipmentId)
    }
  }, [deleteShipmentId])

  const removeShipmentFromSale = saleShipmentId => {
    if (saleShipmentId === saleShipment.id) {
      onCloseForm()
    }
    dispatchFormState({
      type: REMOVE_SALE_SHIPMENT,
      saleShipmentId: saleShipmentId
    })
  }

  const onRemove = saleShipment => {
    setDeleteShipmentId(saleShipment.id)
    setWaiting(true)
  }

  const onCreate = () => {
    setSaleShipment({
      id: nanoid()
    })
  }

  const onCloseForm = () => {
    setSaleShipment({})
  }

  return (
    <Fragment>
      <Table
        sale={sale}
        saleShipments={sale.saleShipments}
        handleEdit={setSaleShipment}
        handleRemove={onRemove}
        dispatchFormState={dispatchFormState}
      />
      {saleShipment?.id && (
        <Form
          sale={sale}
          saleShipment={saleShipment}
          closeForm={onCloseForm}
          dispatchFormState={dispatchFormState}
        />
      )}
      {!saleShipment?.id && (
        <Flex alignMainAxis={'flex-end'}>
          <Button onClick={onCreate} size='small' name='add' variant='primary'>
            {strings.addSaleShipment}
          </Button>
        </Flex>
      )}
    </Fragment>
  )
}

export default SaleShipments

import { call, put, takeLatest } from 'redux-saga/effects'

import {
  getProducts,
  getProduct,
  createProduct,
  updateProduct
} from '@/api/operations/product'

import {
  REQUEST_PRODUCTS,
  REQUEST_PRODUCT,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  receiveProducts,
  receiveProduct,
  productCreated,
  productUpdated
} from '@/actions/operations/product'

import { displayBanner } from '../utils'

import Strings from './Strings'

const strings = Strings()

function* sendCreateProduct(action) {
  const { data, error = null } = yield call(createProduct, action.params)
  yield put(displayBanner(error, strings.productCreated))
  yield put(productCreated(data, error))
}

export function* watchCreateProduct() {
  yield takeLatest(CREATE_PRODUCT, sendCreateProduct)
}

function* sendUpdateProduct(action) {
  const { data, error = null } = yield call(updateProduct, action.params)
  yield put(displayBanner(error, strings.productUpdated))
  yield put(productUpdated(data, error))
}

export function* watchUpdateProduct() {
  yield takeLatest(UPDATE_PRODUCT, sendUpdateProduct)
}

function* sendRequestProduct(action) {
  const { data: product, error = null } = yield call(getProduct, action.params)
  yield put(receiveProduct(product, error))
}

export function* watchRequestProduct() {
  yield takeLatest(REQUEST_PRODUCT, sendRequestProduct)
}

function* sendRequestProducts(action) {
  const { data, error = null } = yield call(getProducts, action.params)
  const { products = [], count = 0 } = data
  yield put(receiveProducts(products, count, error))
}

export function* watchRequestProducts() {
  yield takeLatest(REQUEST_PRODUCTS, sendRequestProducts)
}

import { I18n } from 'aws-amplify'

export const Strings = () => ({
  title: I18n.get('Edit Widget'),
  subtitle: I18n.get('Edit the widget settings'),
  selectQuery: I18n.get('Select a query'),
  selectChart: I18n.get('Select a chart'),
  selectRange: I18n.get('Select a range'),
  selectInterval: I18n.get('Select an interval'),
  selectProduce: I18n.get('Select a produce')
})

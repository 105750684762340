import { useState, useEffect, Fragment } from 'react'
import { I18n } from 'aws-amplify'
import { useDispatch } from 'react-redux'

import UpsertMeasurement from './UpsertMeasurement'

import {
  Button,
  Card,
  FlexV2,
  Grid,
  Item,
  Slot,
  Text,
  Loader,
  Box,
  Icon,
  Separator
} from '@/primitives'

import { Select, SearchBar } from '@/elements'

import { setMeasurement } from '@/slices/deviceManagement/measurement'

import {
  getMeasurements,
  getCurrentUser,
  getDeviceManagementInitLoading
} from '@/reducers/selectors'

import {
  RESOURCE_TYPE_DEVICE,
  RESOURCE_TYPE_DEVICE_MANAGEMENT,
  hasEditPermissions
} from '@/Util/PermissionUtils'

import useMediaQuery from '@/hooks/useMediaQuery'

import Strings from '../../Strings'

const Types = () => {
  const isAboveBreakpoint = useMediaQuery('min-width: 600px')
  const dispatch = useDispatch()
  const [showTemplateModal, setShowTemplateModal] = useState(false)
  const [typeFilter, setTypeFilter] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [filteredMeasurements, setFilteredMeasurements] = useState([])
  const measurements = getMeasurements()
  const isLoading = getDeviceManagementInitLoading()
  const strings = Strings()
  const {
    measurementsTabSubtitle,
    measurementsTab,
    addMeasurement,
    editButton
  } = strings

  const coretexUser = getCurrentUser()
  const showEditOptions = hasEditPermissions(
    coretexUser,
    RESOURCE_TYPE_DEVICE,
    RESOURCE_TYPE_DEVICE_MANAGEMENT
  )

  let MEASUREMENT_TYPE_OPTIONS = [
    { label: I18n.get('Hardware'), value: 'hardware' },
    { label: I18n.get('Software'), value: 'software' },
    { label: I18n.get('Calculation'), value: 'calculation' }
  ]

  const onClickAdd = () => {
    setShowTemplateModal(true)
  }

  const onClickEdit = e => {
    dispatch(setMeasurement(filteredMeasurements[e.target.value]))
    setShowTemplateModal(true)
  }

  const onSelectOption = option => {
    setTypeFilter(option?.value)
  }

  const onUpdateSearchText = text => {
    setSearchText(text.toLowerCase())
  }

  useEffect(() => {
    let newMeasurements = [...measurements]

    if (searchText) {
      const filteredBySearch = measurements?.filter(measurement => {
        const { description, shortName, id } = measurement
        return (
          description?.toLowerCase().includes(searchText) ||
          shortName?.toLowerCase().includes(searchText) ||
          id?.toLowerCase().includes(searchText)
        )
      })
      newMeasurements = filteredBySearch
    }

    if (typeFilter) {
      const filteredByType = newMeasurements?.filter(measurement => {
        return measurement?.type === typeFilter
      })
      newMeasurements = filteredByType
    }

    setFilteredMeasurements(newMeasurements)
  }, [typeFilter, measurements, searchText])

  return (
    <Loader isLoading={isLoading}>
      {showTemplateModal && (
        <UpsertMeasurement
          showModal={showTemplateModal}
          setShowTemplateModal={setShowTemplateModal}
        />
      )}
      <FlexV2 direction='column' axisGap={400}>
        <FlexV2
          direction={isAboveBreakpoint ? 'row' : 'column'}
          axisGap={400}
          alignCrossAxis={isAboveBreakpoint ? 'center' : 'stretch'}
          alignMainAxis={isAboveBreakpoint ? 'space-between' : null}
        >
          <Box>
            <Text size={300} fontWeight={600}>
              {measurementsTab}
            </Text>
            <br />
            <Text variant='page' tone={900}>
              {measurementsTabSubtitle}
            </Text>
          </Box>
          {showEditOptions && (
            <Button onClick={onClickAdd} variant='primary' size='small'>
              {addMeasurement}
            </Button>
          )}
        </FlexV2>

        <Separator />

        <FlexV2 direction={isAboveBreakpoint ? 'row' : 'column'} axisGap={400}>
          <FlexV2 axisGap={400} alignCrossAxis='center'>
            {isAboveBreakpoint && <Icon name='filter_list' />}
            <Box style={{ flexGrow: 1 }}>
              <Select
                isClearable={true}
                value={typeFilter}
                placeholder={strings?.filterByType}
                options={MEASUREMENT_TYPE_OPTIONS}
                onChange={onSelectOption}
              />
            </Box>
          </FlexV2>

          <FlexV2 axisGap={400} alignCrossAxis='center' style={{ flexGrow: 1 }}>
            {isAboveBreakpoint && <Icon name='search' />}
            <Box style={{ flexGrow: 1 }}>
              <SearchBar
                isLoading={isLoading}
                onUpdateSearchText={onUpdateSearchText}
                placeholderText={strings?.searchByName}
              />
            </Box>
          </FlexV2>
        </FlexV2>

        <Separator />
        <Box>
          <Grid
            gap={400}
            templateColumns='repeat(auto-fill, minmax(288px, 1fr))'
          >
            {filteredMeasurements?.length > 0 &&
              !isLoading &&
              filteredMeasurements?.map(
                ({ shortName, type, unit, thresholds, id }, index) => {
                  return (
                    <Item key={id}>
                      <Card>
                        <Slot name='body'>
                          <FlexV2
                            direction='column'
                            gap={400}
                            alignMainAxis='space-between'
                            style={{ minHeight: '160px' }}
                          >
                            <Box>
                              <Text
                                fontFamily='mono'
                                textTransform='capitalize'
                                tone={700}
                                variant='page'
                                size={100}
                                fontWeight={500}
                              >
                                {type}
                              </Text>
                              <br />
                              <Text variant='page' size={300} fontWeight={500}>
                                {shortName}
                              </Text>
                              <br />
                              {thresholds?.length > 0 && (
                                <Fragment>
                                  <Text
                                    tone={800}
                                    variant='page'
                                    fontFamily='mono'
                                    size={200}
                                  >{`${thresholds[0]} - ${thresholds[1]} ${unit}`}</Text>
                                  <br />
                                </Fragment>
                              )}
                            </Box>
                            {showEditOptions && (
                              <Box>
                                <Button
                                  size='small'
                                  value={index}
                                  variant='primary'
                                  onClick={onClickEdit}
                                >
                                  {editButton}
                                </Button>
                              </Box>
                            )}
                          </FlexV2>
                        </Slot>
                      </Card>
                    </Item>
                  )
                }
              )}
          </Grid>
        </Box>
      </FlexV2>
    </Loader>
  )
}

export default Types

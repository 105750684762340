import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { updateCurrentDashboardData } from '@/actions/ts/dashboard'

import { getZoneHierarchyLoading } from '@/reducers/selectors'

import { useAuth } from '@/contexts/auth-context'

// Only allow one socket connection at a time
const useLiveData = ({ deviceId, device, measurements }) => {
  const dispatch = useDispatch()
  const auth = useAuth()
  const zoneHierarchyLoading = getZoneHierarchyLoading()

  const socketRef = useRef(null)
  const deviceRef = useRef(null)

  const authToken = auth.getCognitoAuthToken()

  useEffect(() => {
    return () => {
      if (socketRef.current) {
        deviceRef.current = null
        socketRef.current.close()
        socketRef.current = null
      }
    }
  }, [])

  useEffect(() => {
    if (
      authToken &&
      !zoneHierarchyLoading &&
      deviceId &&
      device?.id === deviceId &&
      measurements.length > 0
    ) {
      if (deviceId !== deviceRef.current) {
        if (socketRef.current) {
          socketRef.current.close()
          socketRef.current = null
        }

        const baseUrl = `${window.ENV.WEB_SOCKET_URL}/ts/?zone=${deviceId}`
        const measurementsUri = `measurements=${measurements.join(',')}`
        const authUri = `authorization=${authToken}`
        const userId = `userId=${auth.getCognitoUsername()}`
        const websocketUrl = `${baseUrl}&${measurementsUri}&${userId}&${authUri}`

        const socket = new WebSocket(websocketUrl)

        socket.onmessage = event => {
          const data = JSON.parse(event.data)
          if (data) {
            dispatch(updateCurrentDashboardData(data))
          }
        }

        socket.onclose = () => {
          if (socketRef.current === socket) {
            socketRef.current = null
          }
        }

        deviceRef.current = deviceId
        socketRef.current = socket
      }
    }
  }, [
    deviceId,
    device,
    measurements,
    authToken,
    zoneHierarchyLoading,
    dispatch
  ])

  useEffect(() => {
    return () => {
      if (socketRef.current) {
        socketRef.current.close()
        socketRef.current = null
      }
    }
  }, [])
}

export default useLiveData

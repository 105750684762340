import { Fragment, useEffect, useReducer, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import Strings from '../../Strings'

import { Box, Button, Flex, Text, Slot } from '@/primitives'
import { Select, Dialog } from '@/elements'

import ThresholdBoundaries from './FormBoundaries'

import { fetchZone } from '@/slices/management/zone'
import { showBanner } from '@/slices/util'
import {
  cleanThreshold,
  fetchThreshold,
  sendCreateThreshold,
  sendUpdateThreshold
} from '@/slices/threshold'
import {
  getMeasurements,
  getThresholdLoading,
  getThreshold,
  getThresholdSetting,
  getThresholdSaving,
  getThresholdCreated,
  getThresholdUpdated,
  getThresholdError,
  getZone,
  getCurrentUser,
  getZoneHierarchy
} from '@/reducers/selectors'

import {
  hasEditPermissions,
  RESOURCE_TYPE_DEVICE,
  RESOURCE_TYPE_DEVICE_THRESHOLDS
} from '@/Util/PermissionUtils'
import {
  sortMeasurementsForDashboard,
  getMeasurementStrings,
  convertCelciusToFahrenheit,
  convertFahrenheitToCelsius,
  CONVERSION_TEMPERATURE_MEASUREMENTS,
  FAHRENHEIT_UNIT
} from '@/Util/MeasurementUtils'
import ZoneUtils from '@/Util/ZoneUtils'
import {
  SETTING_CELCIUS_TO_FAHRENHEIT,
  processSettings
} from '@/Util/SettingsUtils'
import useOrgLabel from '@/hooks/useOrgLabel'

import history from '../../../../history'

const THRESHOLD_TYPES = ['notification', 'caution', 'warning']

const initialState = {
  id: null,
  rootId: null,
  measurementId: '',
  message: '',
  interval: 5,
  type: 'zone',
  lower: {
    notification: '0.0',
    caution: '0.0',
    warning: '0.0'
  },
  lowerMessage: {
    notification: '',
    caution: '',
    warning: ''
  },
  upper: {
    notification: '0.0',
    caution: '0.0',
    warning: '0.0'
  },
  upperMessage: {
    notification: '',
    caution: '',
    warning: ''
  }
}

const reducer = (state, action) => {
  if (action.type === 'SET_FULL') {
    const parsedValues = { ...action.threshold }

    if (!parsedValues.lowerMessage) {
      parsedValues.lowerMessage = initialState.lowerMessage
    }

    if (!parsedValues.upperMessage) {
      parsedValues.upperMessage = initialState.upperMessage
    }

    return parsedValues
  }

  if (action.type === 'SET_PARTIAL') {
    return { ...state, ...action.params }
  }

  const threshold = { ...state }
  threshold[action.type] = action.value
  return threshold
}

const isEditing = threshold => {
  return threshold?.id !== null && threshold?.id !== undefined
}

function convertBoundsTemps(bounds, convert) {
  const types = Object.keys(bounds)
  const newValues = { ...bounds }

  types.forEach(type => {
    const value = parseFloat(newValues[type])
    newValues[type] = `${convert(value).toFixed(1)}`
  })
  return newValues
}

function ThresholdForm() {
  const strings = Strings()
  const dispatch = useDispatch()

  const isLoading = getThresholdLoading()
  const zone = getZone()
  const threshold = getThreshold()
  const thresholdCreated = getThresholdCreated()
  const thresholdUpdated = getThresholdUpdated()
  const setting = getThresholdSetting()
  const saving = getThresholdSaving()
  const error = getThresholdError()
  const coretexUser = getCurrentUser()
  const hierarchy = getZoneHierarchy()

  const canEdit = hasEditPermissions(
    coretexUser,
    RESOURCE_TYPE_DEVICE,
    RESOURCE_TYPE_DEVICE_THRESHOLDS
  )

  const measurements = sortMeasurementsForDashboard(getMeasurements())

  const [state, reducerDispatch] = useReducer(reducer, initialState)
  const [thresholdIsSet, setThresholdIsSet] = useState(false)
  const [useFahrenheit, setUseFahrenheit] = useState(false)

  const orgLabels = useOrgLabel(['site', 'facility', 'room', 'zone', 'subzone'])
  const zoneDepthId = ZoneUtils.getZoneDepthIdentifier(zone)
  const params = useParams()
  const basePath = ZoneUtils.getZoneBasePath(params)
  const firstMeasurementId = measurements[0]?.id

  const {
    addThresholdHeading,
    editThresholdHeading,
    saveThresholdBtn,
    thresholdMeasurementLabel
  } = strings

  useEffect(() => {
    const sites = Object.keys(hierarchy)
    if (coretexUser?.userName && sites?.length > 0) {
      const siteId = sites[0]
      const organizationId = hierarchy[siteId]?.organizationId

      const enableFahrenheit = processSettings(
        coretexUser?.settings,
        SETTING_CELCIUS_TO_FAHRENHEIT,
        coretexUser.userName,
        organizationId
      )

      if (
        enableFahrenheit &&
        CONVERSION_TEMPERATURE_MEASUREMENTS.includes(state.measurementId)
      ) {
        setUseFahrenheit(true)
      } else {
        setUseFahrenheit(false)
      }
    }
  }, [coretexUser, hierarchy, setUseFahrenheit, state.measurementId])

  useEffect(() => {
    return () => {
      dispatch(cleanThreshold())
    }
  }, [dispatch])

  useEffect(() => {
    if (thresholdCreated?.id) {
      history.replace(`/admin${basePath}/thresholds`, { action: 'create' })
    }
  }, [thresholdCreated?.id])

  useEffect(() => {
    if (thresholdUpdated?.id) {
      history.replace(`/admin${basePath}/thresholds`, { action: 'update' })
    }
  }, [thresholdUpdated?.id])

  useEffect(() => {
    if (!zone?.id) {
      const itemsInPath = params.zone.split('/')
      const zoneToFetch = itemsInPath[itemsInPath.length - 1]
      dispatch(fetchZone({ id: zoneToFetch }))
    }
  }, [dispatch, zone?.id, params])

  useEffect(() => {
    if (!threshold?.id && !setting) {
      const { zone, measurementId } = params
      const zonesArr = zone.split('/')
      const currentZone = [...zonesArr].pop()
      if (currentZone && measurementId) {
        dispatch(
          fetchThreshold({ id: currentZone, measurement: measurementId })
        )
      }
    }
  }, [dispatch, threshold?.id, params, setting])

  useEffect(() => {
    if (error) {
      dispatch(
        showBanner({
          show: true,
          message: error,
          type: 'error'
        })
      )
    }
  }, [dispatch, error])

  useEffect(() => {
    if (threshold?.id) {
      let { lower, upper } = threshold

      if (useFahrenheit) {
        lower = convertBoundsTemps(lower, convertCelciusToFahrenheit)
        upper = convertBoundsTemps(upper, convertCelciusToFahrenheit)
      }

      reducerDispatch({
        type: 'SET_FULL',
        threshold: { ...threshold, lower, upper }
      })
    }

    if (!threshold?.id && !thresholdIsSet) {
      reducerDispatch({
        type: 'SET_PARTIAL',
        params: {
          id: zone?.id,
          rootId: zone?.parentId ? zone.parentPath.split('/')[1] : zone.id,
          measurementId: firstMeasurementId
        }
      })
    }

    setThresholdIsSet(true)
  }, [
    threshold,
    measurements?.length,
    zone,
    firstMeasurementId,
    useFahrenheit,
    thresholdIsSet
  ])

  const onChangeMeasurement = option => {
    reducerDispatch({ type: 'measurementId', value: option?.value })
  }

  const onUpdateBounds = (key, bounds) => {
    let value = { ...bounds }
    reducerDispatch({ type: key, value })
  }

  const saveThresholdDetails = e => {
    e.preventDefault()
    const notificationTypes = Object.keys(state.lower)

    let lower = { ...state.lower }
    let upper = { ...state.upper }

    if (useFahrenheit) {
      lower = convertBoundsTemps(lower, convertFahrenheitToCelsius)
      upper = convertBoundsTemps(upper, convertFahrenheitToCelsius)
    }

    notificationTypes.forEach(type => {
      lower[type] = parseFloat(lower[type])
      upper[type] = parseFloat(upper[type])
    })

    let latestValues = { ...state, lower, upper }

    if (threshold?.id) {
      dispatch(sendUpdateThreshold(latestValues))
    } else {
      dispatch(sendCreateThreshold(latestValues))
    }
  }

  function goBack() {
    const url = history.location.pathname.split('/').slice(0, -1).join('/')
    if (url) {
      history.push(url)
    } else {
      history.push('/admin/zones')
    }
  }

  const measurement = measurements?.find(
    ({ id }) => id === state?.measurementId
  )

  return (
    <Dialog
      type='modal'
      open={true}
      onOpenChange={goBack}
      className='Threshold__Upsert'
    >
      <Slot name='title'>
        <Flex direction='column' alignMainAxis='flex-start' axisGap={200}>
          <Text as='h5' style={{ marginBottom: '0' }} variant='page' tone={900}>
            {isEditing(threshold) ? editThresholdHeading : addThresholdHeading}
          </Text>
          <Text as='p' size={100} variant='page' tone={900}>
            {orgLabels[zoneDepthId].text}: {zone.name}
          </Text>
        </Flex>
      </Slot>
      <Slot name='content' style={{ flexGrow: 1 }}>
        <Flex direction='column'>
          {thresholdIsSet && (
            <Fragment>
              <Flex direction='column' axisGap={300}>
                <Text as='p'>{thresholdMeasurementLabel}</Text>
                <Box style={{ maxWidth: '300px' }}>
                  <Select
                    isDisabled={isEditing(threshold) || isLoading}
                    isSearchable={true}
                    value={state.measurementId}
                    options={measurements.map(({ id, shortName }) => ({
                      value: id,
                      label: getMeasurementStrings(id)?.shortName ?? shortName
                    }))}
                    onChange={onChangeMeasurement}
                  />
                </Box>
              </Flex>
              {THRESHOLD_TYPES.map(type => (
                <ThresholdBoundaries
                  key={type}
                  type={type}
                  measurementId={state.measurementId}
                  allBounds={{
                    lower: state.lower,
                    upper: state.upper
                  }}
                  allBoundsMessages={{
                    lowerMessage: state.lowerMessage,
                    upperMessage: state.upperMessage
                  }}
                  canEdit={canEdit}
                  onUpdateBounds={onUpdateBounds}
                  unit={useFahrenheit ? FAHRENHEIT_UNIT : measurement?.unit}
                />
              ))}
            </Fragment>
          )}
        </Flex>
      </Slot>
      <Slot name='actions'>
        <Flex
          direction='row'
          alignMainAxis='space-between'
          alignCrossAxis='center'
        >
          <Flex direction='row' alignMainAxis='flex-start' axisGap={400}>
            <Button variant='error' size='small' onClick={goBack}>
              {strings.cancel}
            </Button>
          </Flex>
          {canEdit && (
            <Button
              variant='primary'
              size='small'
              loading={isLoading || saving}
              disabled={saving}
              onClick={saveThresholdDetails}
            >
              {saveThresholdBtn}
            </Button>
          )}
        </Flex>
      </Slot>
    </Dialog>
  )
}

export default ThresholdForm

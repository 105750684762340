export const updateList = (error, array, item, isCreate) => {
  if (!error) {
    let arrCopy = [...array]
    if (isCreate) {
      arrCopy.unshift(item)
    } else if (array.length > 0) {
      const index = arrCopy.findIndex(i => i.id === item.id)
      if (index >= 0) {
        arrCopy[index] = item
      }
    }
    return arrCopy
  }
  return array
}

export const upsertItem = (array, item, isCreate) => {
  let arrCopy = [...array]
  if (isCreate) {
    arrCopy.unshift(item)
  } else if (array.length > 0) {
    const index = arrCopy.findIndex(i => i.id === item.id)
    if (index >= 0) {
      arrCopy[index] = item
    }
  }
  return arrCopy
}

import { I18n } from 'aws-amplify'

import { widgetRegistry } from '@/components/DashboardPageV2/Desktop/Widgets'

import { TOOLBOX_TYPES, ToolboxItem } from './utils'

/*
  SECTION
*/

export const resourcesSection = () =>
  new ToolboxItem({
    id: 'resourcesSection',
    type: TOOLBOX_TYPES.section,
    title: I18n.get('Resources'),
    icon: 'electric meter',
    items: [
      // widgetRegistry.get('energy'),
      widgetRegistry.get('energyKw'),
      widgetRegistry.get('dailyEnergyConsumption')
    ]
  })

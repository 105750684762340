import React from 'react'

import useDeviceSize from '../../../../hooks/useDeviceSize'

import { Text, Table, Slot, Row, Cell, Flex } from '../../../../primitives'

import WeightForm from './Form'
import Strings from './Strings'

import './index.scss'

function InventoryWeightFormTable({
  organizationId,
  weights,
  totalWeight,
  errors,
  updateWeight,
  removeWeight
}) {
  const isMobile = useDeviceSize('mobile')
  const strings = Strings()

  if (!weights?.length) {
    return (
      <Text className={'Operations__Form__Table__Empty'}>
        {strings.noWeights}
      </Text>
    )
  }

  return (
    <Table
      className='Operations__Form__Table InventoryWeight'
      aria-colcount='4'
    >
      <Slot name='body'>
        {!isMobile && (
          <Row>
            <Cell>
              <Flex axisGap={900}>
                <Text variant='page' tone={700}>
                  {strings.unitWeight}
                </Text>
                <Text variant='page' tone={700}>
                  {strings.quantity}
                </Text>
              </Flex>
            </Cell>
            <Cell />
          </Row>
        )}
        {weights.map((weight, index) => (
          <WeightForm
            key={weight.id}
            organizationId={organizationId}
            weight={weight}
            weights={weights}
            updateWeight={updateWeight}
            removeWeight={removeWeight}
            errors={errors}
            index={index}
          />
        ))}
        <Row>
          <Cell colSpan='100%'>
            <Text as='p' variant='page' fontFamily='sans' tone={800} size={200}>
              {strings.totalWeight}: {totalWeight} {strings.kg}
            </Text>
          </Cell>
        </Row>
      </Slot>
    </Table>
  )
}

export default InventoryWeightFormTable

import { I18n } from 'aws-amplify'
import { object, string, array, number } from 'yup'

export const FIELDS = {
  id: 'id',
  organizationId: 'organizationId',
  name: 'name',
  code: 'code',
  status: 'status',
  weight: 'weight',
  produceId: 'produceId',
  varietyId: 'varietyId',
  gradeId: 'gradeId',
  packageUnits: 'packageUnits'
}

export const SCHEMA = () => {
  return object().shape({
    organizationId: string().required(I18n.get('An organization is required.')),
    name: string().required(I18n.get('Name is required.')),
    code: string().required(I18n.get('A code is required.')),
    status: string().required('Status is required'),
    weight: number()
      .integer('A partial weight is not allowed.')
      .typeError('Weight is required.')
      .min(1, I18n.get('Weight must be at least 1g.'))
      .required(I18n.get('Weight is required.')),
    produceId: string().required(I18n.get('Produce is required.')),
    varietyId: string().required(I18n.get('A variety is required.')),
    gradeId: string().required(I18n.get('A grade is required.')),
    packageUnits: array().min(
      1,
      I18n.get('At least one package unit is required.')
    )
  })
}

import { I18n } from 'aws-amplify'

/* Support Form Strings */

const Strings = () => ({
	title: I18n.get('Running into problems?'),
	visit: I18n.get('Visit our'),
	helpDesk: I18n.get('helpdesk'),
	fillForm: I18n.get('or fill out the form to contact support.'),
	fullName: I18n.get('Name'),
	fullNamePlaceholder: I18n.get('Enter your full name here'),
	emailAddress: I18n.get('Email Address'),
	emailAddressPlaceholder: I18n.get('Enter your email address here'),
	supportDetails: I18n.get('Support Details'),
	supportType: I18n.get('Support Type'),
	supportTypePlaceholder: I18n.get('Select support type'),
	supportSubject: I18n.get('Title/Subject'),
	supportSubjectPlaceholder: I18n.get('Provide a title or subject'),
	describeProblem: I18n.get('Describe your problem'),
	describeProblemPlaceholder: I18n.get('Please describe your problem or difficulty here'),
	submitRequest: I18n.get('Submit Request'),
	cancel: I18n.get('Cancel'),
	back: I18n.get('Back')
})

export default Strings
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Flex, Button, Slot, Text, Box, Separator } from '@/primitives'
import { Dialog, Select } from '@/elements'

import { showBanner } from '@/slices/util'
import { cleanExecutionId, sendStartOTAUpdateQuery } from '@/slices/ota'
import {
  getZone,
  getOtaExecutionId,
  getOtaStarting,
  getOtaError,
  getZoneHierarchy
} from '@/reducers/selectors'

import { getUpdateIds } from '../utils'
import Strings from '../Strings'

import { useAuth } from '@/contexts/auth-context'
import { useFleetManagement } from '../context'
import { OTA_DESTINATION } from '../state'
import { OTA_UPDATE_DESTINATIONS } from '../config'
import { getZoneName } from '@/Util/ZoneUtils'

const OTAModal = ({ showModal, toggleModal }) => {
  const dispatch = useDispatch()
  const auth = useAuth()

  const strings = Strings()
  const zone = getZone()
  const zoneHierarchy = getZoneHierarchy()
  const executionId = getOtaExecutionId()
  const starting = getOtaStarting()
  const error = getOtaError()

  const userId = auth.getCognitoId()

  const {
    state: { sensorsByZone, otaDestination, tabId, siteId },
    dispatchState
  } = useFleetManagement()

  useEffect(() => {
    return () => {
      dispatch(cleanExecutionId())
    }
  }, [dispatch])

  useEffect(() => {
    if (executionId) {
      dispatch(cleanExecutionId())
      dispatch(
        showBanner({
          message: strings.otaSuccessMessage,
          type: 'success',
          show: true
        })
      )
      toggleModal()
    }
  }, [executionId, dispatch, strings.otaSuccessMessage, toggleModal])

  useEffect(() => {
    if (error) {
      dispatch(
        showBanner({
          message: error,
          type: 'error',
          show: true
        })
      )
    }
  }, [error, dispatch])

  const onClickClose = e => {
    e.preventDefault()
    toggleModal()
  }

  const { sensorIds, sensorNames, zoneIds } = getUpdateIds(
    tabId,
    sensorsByZone,
    siteId
  )

  function handleModalSubmit() {
    dispatch(
      sendStartOTAUpdateQuery({
        siteId: zone?.id,
        sensorIds,
        zoneIds,
        destination: otaDestination.value,
        userId
      })
    )
  }

  function onChangeOTADestination(option) {
    dispatchState({
      type: OTA_DESTINATION,
      payload: { otaDestination: option }
    })
  }

  return (
    <Dialog
      type='offcanvas'
      className='Devices__OffCanvas'
      open={showModal}
      onOpenChange={toggleModal}
      style={{ zIndex: 3 }}
    >
      <Slot name='title'>
        <Text size={300} fontWeight={700}>
          {strings.otaFormTitle}
        </Text>
      </Slot>
      <Slot name='content'>
        <Flex axisGap={500} direction='column'>
          <Separator />
          <Flex axisGap={300} direction='column'>
            <Text variant='page' tone={900} size={100}>
              {strings.destination}
            </Text>
            <Box style={{ width: '100%' }}>
              <Select
                style={{ width: '600px' }}
                value={otaDestination.value}
                options={OTA_UPDATE_DESTINATIONS.map(({ label, value }) => ({
                  label,
                  value
                }))}
                onChange={onChangeOTADestination}
              />
            </Box>
          </Flex>

          {sensorIds?.length === 0 && (
            <Flex axisGap={300} direction='column'>
              <Text variant='page' tone={900} size={100}>
                {strings.devicesForUpdate}
              </Text>
              <Flex axisGap={100} direction='column'>
                {zoneIds.map(id => (
                  <Box key={id}>{`${getZoneName(
                    zoneHierarchy,
                    siteId,
                    id
                  )} (${id})`}</Box>
                ))}
              </Flex>
              <Text style={{ marginTop: '1.5rem' }} variant='page' tone={800}>
                {strings.devicesUpdateNote}
              </Text>
            </Flex>
          )}

          {sensorIds?.length > 0 && (
            <Flex axisGap={300} direction='column'>
              <Text variant='page' tone={900} size={100}>
                {strings.sensorsForUpdate}
              </Text>
              <Flex axisGap={100} direction='column'>
                {sensorNames.map((name, index) => (
                  <Box key={sensorIds[index]}>{name}</Box>
                ))}
              </Flex>
            </Flex>
          )}
        </Flex>
      </Slot>

      <Slot name='actions'>
        <Flex alignMainAxis='space-between'>
          <Button
            size='small'
            variant='page'
            onClick={onClickClose}
            disabled={starting}
          >
            {strings.close}
          </Button>
          <Button
            size='small'
            variant='primary'
            onClick={handleModalSubmit}
            loading={starting}
          >
            {strings.submit}
          </Button>
        </Flex>
      </Slot>
    </Dialog>
  )
}

export default OTAModal

import { useEffect, useState, Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import DesktopDashboard from './Desktop'
import MobileDashboard from './Mobile'
import DesktopDashboardSimplified from './DesktopSimplified'
import NavigationBreadcrumbsV2 from '../Shared/NavigationBreadcrumbsV2'
import DesktopToggle from './Shared/DesktopToggle'

import useDeviceSize from '@/hooks/useDeviceSize'
import {
  getCurrentUser,
  getUseSimplifiedDashboard,
  getUseSimplifiedDashboardByLocation,
  getZonesHierarchy,
  getDashboardDefaultSettingUpdated
} from '@/reducers/selectors'
import { setUseSimplifiedDefault } from '@/slices/management/dashboard'
import { FlexV2, Separator, Box } from '@/primitives'

import {
  AVAILABLE_FEATURE_FLAGS,
  ENABLE_SIMPLIFIED_DASHBOARD,
  hasFeatureFlagEnabled
} from '@/Util/FeatureFlagsUtils'

export default function DashboardPageV2() {
  const [useSimplified, setUseSimplified] = useState(null)
  const [useSimplifiedToggle, setUseSimplifiedToggle] = useState(true)
  const [opacity, setOpacity] = useState(1)
  const isMobile = useDeviceSize('mobile')
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()

  const simplifiedByDefault = getUseSimplifiedDashboard()
  const simplifiedByLocation = getUseSimplifiedDashboardByLocation()
  const dashboardDefaultUpdated = getDashboardDefaultSettingUpdated()

  const currentUser = getCurrentUser()
  const settings = currentUser?.settings[currentUser?.userName]?.options
  const zonesHierarchy = getZonesHierarchy()

  const id = history?.location?.pathname.split('/').pop()

  useEffect(() => {
    if (settings?.length > 0) {
      const permission = settings.find(
        ({ id }) => id === 'use_simplified_dashboard'
      )
      if (permission?.id) {
        dispatch(setUseSimplifiedDefault(permission?.value))
      } else {
        dispatch(setUseSimplifiedDefault(false))
      }
    }
  }, [settings?.length])

  useEffect(() => {
    const hasLocationSetting = simplifiedByLocation?.hasOwnProperty(id)
    const newSimplifiedSetting = hasLocationSetting
      ? simplifiedByLocation[id]
      : simplifiedByDefault

    if (useSimplified?.id?.length > 0 && dashboardDefaultUpdated) {
      if (
        useSimplified?.id === id &&
        useSimplified?.value !== newSimplifiedSetting
      ) {
        setOpacity(0)
        setUseSimplifiedToggle(newSimplifiedSetting)
        setTimeout(() => {
          setUseSimplified({ id, value: newSimplifiedSetting })
          setOpacity(1)
        }, [500])
      }

      if (useSimplified?.id !== id) {
        setUseSimplifiedToggle(newSimplifiedSetting)
        setUseSimplified({ id, value: newSimplifiedSetting })
      }
    }

    if (!useSimplified && dashboardDefaultUpdated) {
      setUseSimplified({ id, value: newSimplifiedSetting })
      setUseSimplifiedToggle(newSimplifiedSetting)
    }
  }, [
    simplifiedByDefault,
    simplifiedByLocation[id],
    history?.location?.pathname,
    useSimplified?.value,
    useSimplified?.id,
    dashboardDefaultUpdated
  ])

  if (isMobile) return <MobileDashboard />

  const siteId = params?.zone?.split('/')[0]

  const enableSimplifiedDashboard = hasFeatureFlagEnabled(
    currentUser,
    AVAILABLE_FEATURE_FLAGS[ENABLE_SIMPLIFIED_DASHBOARD],
    zonesHierarchy[siteId]?.organizationId
  )

  return (
    <Fragment>
      <FlexV2 alignCrossAxis='baseline' alignMainAxis='space-between'>
        <NavigationBreadcrumbsV2 />
        {useSimplified?.hasOwnProperty('id') && enableSimplifiedDashboard && (
          <DesktopToggle useSimplified={useSimplifiedToggle} />
        )}
      </FlexV2>
      <Separator style={{ marginTop: '0' }} />
      <Box style={{ transition: 'opacity 500ms ease-in', opacity }}>
        {useSimplified?.value && enableSimplifiedDashboard ? (
          <DesktopDashboardSimplified />
        ) : (
          <DesktopDashboard />
        )}
      </Box>
    </Fragment>
  )
}

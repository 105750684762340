import { makeActionCreator } from '@/Util/ReduxUtils'

export const REQUEST_FERTILIZER_INVENTORY_CALIBRATIONS =
  'REQUEST_FERTILIZER_INVENTORY_CALIBRATIONS'
export const requestFertilizerInventoryCalibrations = makeActionCreator(
  REQUEST_FERTILIZER_INVENTORY_CALIBRATIONS,
  'params'
)

export const RECEIVE_FERTILIZER_INVENTORY_CALIBRATIONS =
  'RECEIVE_FERTILIZER_INVENTORY_CALIBRATIONS'
export const receiveFertilizerInventoryCalibrations = makeActionCreator(
  RECEIVE_FERTILIZER_INVENTORY_CALIBRATIONS,
  'fertilizerInventoryCalibrations',
  'count',
  'error'
)

export const CREATE_FERTILIZER_INVENTORY_CALIBRATION =
  'CREATE_FERTILIZER_INVENTORY_CALIBRATION'
export const createFertilizerInventoryCalibration = makeActionCreator(
  CREATE_FERTILIZER_INVENTORY_CALIBRATION,
  'params'
)

export const FERTILIZER_INVENTORY_CALIBRATION_CREATED =
  'FERTILIZER_INVENTORY_CALIBRATION_CREATED'
export const fertilizerInventoryCalibrationCreated = makeActionCreator(
  FERTILIZER_INVENTORY_CALIBRATION_CREATED,
  'fertilizerInventoryCalibration',
  'error'
)

export const CLEAR_FERTILIZER_INVENTORY_CALIBRATIONS =
  'CLEAR_FERTILIZER_INVENTORY_CALIBRATIONS'
export const clearFertilizerInventoryCalibrations = makeActionCreator(
  CLEAR_FERTILIZER_INVENTORY_CALIBRATIONS
)

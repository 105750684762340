import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { isEmpty } from '@aws-amplify/core'
import {
  getOperationsDashboardData,
  getZoneHierarchyLoading
} from '@/reducers/selectors'
import { getRanges } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'
import { useDashboard } from '@/components/DashboardPageV2/Desktop/context'

import {
  getFiltersHash,
  getOperationsDispatchFunctions,
  getQueryZonesHash,
  normalizeZones
} from './utils'

function getAllZoneChildrenIds(parent) {
  if (isEmpty(parent.children)) {
    return [parent.id]
  }

  let children = []
  for (let zoneId in parent.children) {
    var childValues = getAllZoneChildrenIds(parent.children[zoneId])
    children = [...children, ...childValues]
  }
  return children
}

export default function useOperationsData({
  range,
  segmentId,
  queryZones = null,
  filters,
  queryType,
  tileType,
  opsParams = {}
}) {
  const dispatch = useDispatch()

  const stringifiedFilters = JSON.stringify(filters)
  const stringifiedQueryType = JSON.stringify(queryType)
  const stringifiedOpsParams = JSON.stringify(opsParams)

  const dashboardData = getOperationsDashboardData()
  const zoneHierarchyLoading = getZoneHierarchyLoading()

  const { state } = useDashboard()
  const { currentZone, siteId, hierarchy } = state

  const filterHash = getFiltersHash(filters)

  const [localQueryZones, setLocalQueryZones] = useState([])
  const [datasetData, setDatasetData] = useState(null)

  useEffect(() => {
    if (currentZone && siteId && hierarchy && !zoneHierarchyLoading) {
      if (localQueryZones.length > 0) {
        const filters = JSON.parse(stringifiedFilters)
        const queryType = JSON.parse(stringifiedQueryType)
        const opsParams = JSON.parse(stringifiedOpsParams)

        const zones = getAllZoneChildrenIds(hierarchy)

        const params = {
          range: getRanges(range)?.query?.join(''),
          organizationId: [hierarchy.organizationId],
          zoneId: zones,
          ...opsParams
        }

        const dashboardParams = {
          segment: segmentId ?? '',
          queryType,
          tileType,
          filters,
          queryZonesHash: getQueryZonesHash(
            currentZone.id === siteId ? [siteId] : localQueryZones
          )
        }

        dispatch(
          getOperationsDispatchFunctions(
            segmentId,
            tileType,
            params,
            dashboardParams
          )
        )
      }
    }
  }, [
    currentZone,
    siteId,
    hierarchy,
    localQueryZones,
    segmentId,
    range,
    zoneHierarchyLoading,
    dispatch,
    stringifiedFilters,
    stringifiedOpsParams,
    stringifiedQueryType,
    tileType
  ])

  useEffect(() => {
    const innerZones = Object.values(currentZone.children).filter(
      ({ status }) => status === 'active'
    )
    if (!queryZones) {
      setLocalQueryZones(normalizeZones(innerZones))
    } else {
      setLocalQueryZones(normalizeZones(queryZones))
    }
  }, [currentZone.children, currentZone.id, queryZones])

  useEffect(() => {
    if (dashboardData) {
      const queryZonesHash = getQueryZonesHash(
        currentZone.id === siteId ? [siteId] : localQueryZones
      )

      const widgetData =
        dashboardData?.[queryZonesHash]?.[segmentId]?.[queryType]?.[tileType]?.[
          filterHash
        ]

      setDatasetData(widgetData)
    }
  }, [
    dashboardData,
    currentZone.id,
    filterHash,
    localQueryZones,
    queryType,
    segmentId,
    siteId,
    tileType
  ])

  return { datasetData, queryZones: localQueryZones }
}

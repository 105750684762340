import { useState, Fragment } from 'react'

import { Slot, Text, Flex, Input, Label, Card, Icon } from '@/primitives'

import Strings from '../../Strings'

const strings = Strings()

const TranslationLanguageCard = ({
  lang = 'en',
  title,
  text,
  onChangeCheckbox = null,
  checked = false
}) => {
  const expandedInit = lang === 'en'
  const [expanded, setExpanded] = useState(expandedInit)

  function handleCheckboxClick(e) {
    if (onChangeCheckbox) onChangeCheckbox(e)
  }

  function toggleExpanded() {
    setExpanded(!expanded)
  }

  return (
    <Card className='Translation__Box'>
      <Slot name='header'>
        <Flex alignMainAxis='space-between'>
          <Flex axisGap={100}>
            <Icon
              variant='page'
              tone={800}
              name={expanded ? 'expand_more' : 'chevron_right'}
              onClick={toggleExpanded}
            />
            <Text fontWeight={700}>{strings[lang] ?? lang}</Text>
          </Flex>
          {lang !== 'en' && (
            <Label style={{ marginBlockStart: '0', marginBottom: '0rem' }}>
              <Input
                name={lang}
                type='checkbox'
                onChange={handleCheckboxClick}
                checked={checked}
              />
            </Label>
          )}
        </Flex>
      </Slot>
      {expanded && (
        <Slot name='body'>
          {title?.length > 0 ? (
            <Fragment>
              <Text
                className='Lang__Title'
                variant='page'
                tone={900}
                size={200}
              >
                {title}
              </Text>
              <Text className='Lang__Text' variant='page' tone={800} size={100}>
                {text}
              </Text>
            </Fragment>
          ) : (
            <Text className='Lang__Text' variant='page' tone={800} size={100}>
              {strings.noTranslationAvailable}
            </Text>
          )}
        </Slot>
      )}
    </Card>
  )
}

export default TranslationLanguageCard

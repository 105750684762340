import { useState, useLayoutEffect, useCallback, useMemo } from 'react'

const eventOptions = { passive: true }
const CHANGE = 'change'

/* Micro MatchMedia Polyfill */
if (!window.matchMedia) {
  window.matchMedia = function (query) {
    return {
      matches: false,
      query,
      addEventListener: function () {
        return false
      },
      removeEventListener: function () {
        return false
      }
    }
  }
}

/**
 * A custom hook for handling media queries.
 * @param {string} query - The media query string in format property:value
 * @returns {boolean} - Indicates whether the media query matches
 *
 * @example
 * const isTablet = useMediaQuery('min-width: 768px')
 * const isTinyMobile = useMediaQuery('min-width: 360px')
 * const is4KScreen = useMediaQuery('min-width: 3840px')
 * const isSmallerThan800 = useMediaQuery('max-width: 800px')
 * const isLandscape = useMediaQuery('orientation: landscape')
 */

const useMediaQuery = query => {
  const mediaQuery = useMemo(
    () => window.matchMedia(`screen and (${query})`),
    [query]
  )

  const [matches, setMatches] = useState(() => mediaQuery.matches)

  const handleMediaQueryChange = useCallback(({ matches }) => {
    setMatches(matches)
  }, [])

  useLayoutEffect(() => {
    mediaQuery.addEventListener(CHANGE, handleMediaQueryChange, eventOptions)

    return () => {
      mediaQuery.removeEventListener(
        CHANGE,
        handleMediaQueryChange,
        eventOptions
      )
    }
  }, [handleMediaQueryChange, mediaQuery])

  return matches
}

export default useMediaQuery

import { I18n } from 'aws-amplify'
import { string, boolean, object, mixed, number, array } from 'yup'

const isRequired = I18n.get('is a required field.')

const isRequiredMsg = field => `${field} ${isRequired}`

const deviceTypeRequired = isRequiredMsg(I18n.get('Device Type'))

const MIN_MAX_SLEEP_CYCLE = [1, 99]

// Some of the fields are not directly editable by the user, but are required by the backend.
export const deviceSchema = object().shape({
  id: string().required(), // remove this later once id is generated by the backend
  name: string().required(isRequiredMsg(I18n.get('Name'))),
  type: string().required(deviceTypeRequired), // this needs to be renamed once the backend is updated
  zoneId: string().required(),
  zonePath: string().required(),
  latitude: mixed().test(
    'latitude-validation',
    I18n.get('Latitude must be a number between -90 and 90.'),
    value =>
      value === undefined ||
      value === '' ||
      (!isNaN(value) && value >= -90 && value <= 90)
  ),

  longitude: mixed().test(
    'longitude-validation',
    I18n.get('Longitude must be a number between -180 and 180.'),
    value =>
      value === undefined ||
      value === '' ||
      (!isNaN(value) && value >= -180 && value <= 180)
  ),

  tag: string().max(255),
  hardwareVersion: string().nullable(),
  isIoT: boolean().required(),
  isGreengrass: boolean().required(),
  config: object().nullable(),
  sleepCycleMinutes: number()
    .required()
    .min(MIN_MAX_SLEEP_CYCLE[0])
    .max(MIN_MAX_SLEEP_CYCLE[1])
})

export const modbusSchema = object().shape({
  port_address: string().required(isRequiredMsg(I18n.get('Port Address'))),
  slave_address: number().required(isRequiredMsg(I18n.get('Slave Address'))),
  baudrate: number().required(isRequiredMsg(I18n.get('Baudrate'))),
  bytesize: number().required(isRequiredMsg(I18n.get('Bytesize'))),
  stopbits: number().required(isRequiredMsg(I18n.get('Stopbits'))),
  timeout: number().required(isRequiredMsg(I18n.get('Timeout'))),
  read_registers: array()
    .of(number())
    .required(isRequiredMsg(I18n.get('Read Registers')))
    .min(1),
  read_measurementType: array()
    .of(string())
    .required(isRequiredMsg(I18n.get('Read Measurement Type')))
    .min(1),
  read_measurementUnit: array()
    .of(string())
    .required(isRequiredMsg(I18n.get('Read Measurement Unit')))
    .min(1),
  function_code: number()
    .oneOf([3, 4], I18n.get('Function Code must be 3 or 4'))
    .required(isRequiredMsg(I18n.get('Function Code')))
})

import { fixDate } from '../../utils'

export const UPDATE_INPUT = 'UPDATE_INPUT'
export const SET_STATE = 'SET_STATE'

export const initialState = {
  id: null,
  organizationId: '',
  storageFacilityId: '',
  fertilizerId: '',
  supplierId: '',
  currencyId: '',
  unitPrice: '',
  unitWeight: '',
  tax: '',
  quantity: '',
  orderDate: null,
  receivedDate: null,
  status: ''
}

export function reducer(state, action) {
  const handlers = {
    [SET_STATE]: (state, action) => {
      const newState = { ...action.state }
      newState.orderDate = fixDate(newState.orderDate)
      newState.receivedDate = fixDate(newState.receivedDate)
      return {
        ...newState
      }
    },
    [UPDATE_INPUT]: (state, action) => ({
      ...state,
      [action.name]: action.value
    })
  }
  return handlers[action.type](state, action) || state
}

import { makeActionCreator } from '@/Util/ReduxUtils'

export const FETCH_CURRENT_DATA = 'FETCH_CURRENT_DATA'
export const CURRENT_DATA_FETCHED = 'CURRENT_DATA_FETCHED'
export const fetchCurrentData = makeActionCreator(FETCH_CURRENT_DATA, 'params')
export const currentDataFetched = makeActionCreator(
  CURRENT_DATA_FETCHED,
  'currentAverageMinMax',
  'error'
)

export const FETCH_CURRENT_MOBILE_DATA = 'FETCH_CURRENT_MOBILE_DATA'
export const CURRENT_MOBILE_DATA_FETCHED = 'CURRENT_MOBILE_DATA_FETCHED'
export const fetchCurrentMobileData = makeActionCreator(
  FETCH_CURRENT_MOBILE_DATA,
  'params'
)
export const currentMobileDataFetched = makeActionCreator(
  CURRENT_MOBILE_DATA_FETCHED,
  'currentAverageMinMax',
  'error'
)

export const FETCH_AVERAGE_DATA = 'FETCH_AVERAGE_DATA'
export const AVERAGE_DATA_FETCHED = 'AVERAGE_DATA_FETCHED'
export const fetchAverageData = makeActionCreator(
  FETCH_AVERAGE_DATA,
  'params',
  'seed'
)
export const averageDataFetched = makeActionCreator(
  AVERAGE_DATA_FETCHED,
  'averageMinMax',
  'params',
  'seed',
  'error'
)

export const FETCH_DATASET = 'FETCH_DATASET'
export const DATASET_FETCHED = 'DATASET_FETCHED'
export const fetchDataset = makeActionCreator(FETCH_DATASET, 'params', 'seed')
export const datasetFetched = makeActionCreator(
  DATASET_FETCHED,
  'datasetAverages',
  'params',
  'seed',
  'timeZone'
)

export const UPDATE_CURRENT_DASHBOARD_DATA = 'UPDATE_CURRENT_DASHBOARD_DATA'
export const updateCurrentDashboardData = makeActionCreator(
  UPDATE_CURRENT_DASHBOARD_DATA,
  'data'
)

export const UPDATE_CURRENT_MOBILE_DASHBOARD_DATA =
  'UPDATE_CURRENT_MOBILE_DASHBOARD_DATA'
export const updateCurrentMobileDashboardData = makeActionCreator(
  UPDATE_CURRENT_MOBILE_DASHBOARD_DATA,
  'data'
)

export const FETCH_CURRENT_SIMPLE_DASHBOARD_DATA = 'FETCH_CURRENT_SIMPLE_DASHBOARD_DATA'
export const CURRENT_SIMPLE_DASHBOARD_DATA_FETCHED = 'CURRENT_SIMPLE_DASHBOARD_DATA_FETCHED'
export const fetchCurrentSimpleDashboardData = makeActionCreator(
  FETCH_CURRENT_SIMPLE_DASHBOARD_DATA,
  'params'
)
export const currentSimpleDashboardDataFetched = makeActionCreator(
  CURRENT_SIMPLE_DASHBOARD_DATA_FETCHED,
  'currentAverageMinMax',
  'error'
)

export const UPDATE_CURRENT_SIMPLE_DASHBOARD_DATA =
  'UPDATE_CURRENT_SIMPLE_DASHBOARD_DATA'
export const updateCurrentSimpleDashboardData = makeActionCreator(
  UPDATE_CURRENT_SIMPLE_DASHBOARD_DATA,
  'data'
)
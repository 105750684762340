import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { I18n } from 'aws-amplify'

import {
  createSensorThresholdUserSnooze,
  deleteSensorThresholdUserSnooze,
  getSensorThresholdUserSnooze,
  getSensorThresholdUserSnoozes
} from '@/api/threshold/sensorThresholdUserSnooze'

import { showBanner } from '@/slices/util'

export const fetchSensorThresholdUserSnooze = createAsyncThunk(
  'fetchSensorThresholdUserSnooze',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      return await getSensorThresholdUserSnooze(params)
    } catch (error) {
      dispatch(
        showBanner({
          type: 'error',
          show: true,
          message: I18n.get('Snooze not fetched')
        })
      )
      rejectWithValue({
        error: error.message,
        snooze: params
      })
    }
  }
)

export const fetchSensorThresholdUserSnoozes = createAsyncThunk(
  'fetchSensorThresholdUserSnoozes',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      return await getSensorThresholdUserSnoozes(params)
    } catch (error) {
      dispatch(
        showBanner({
          type: 'error',
          show: true,
          message: I18n.get('Snoozes not fetched')
        })
      )
      rejectWithValue({
        error: error.message,
        snoozes: params
      })
    }
  }
)

export const sendCreateSensorThresholdUserSnooze = createAsyncThunk(
  'sendCreateSensorThresholdUserSnooze',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const snooze = await createSensorThresholdUserSnooze(params)
      return {
        snoozeDetails: params,
        snooze
      }
    } catch (error) {
      dispatch(
        showBanner({
          type: 'error',
          show: true,
          message: I18n.get('Snooze not created')
        })
      )
      throw rejectWithValue({
        error: error,
        snooze: params
      })
    }
  }
)

export const sendDeleteSensorThresholdUserSnooze = createAsyncThunk(
  'sendDeleteSensorThresholdUserSnooze',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const snooze = await deleteSensorThresholdUserSnooze(params)
      return {
        snoozeDetails: params,
        snooze
      }
    } catch (error) {
      dispatch(
        showBanner({
          type: 'error',
          show: true,
          message: I18n.get('Snooze not deleted')
        })
      )
      throw new Error({
        error: error.message,
        snooze: params
      })
    }
  }
)

const getUpdatedSnoozeList = (snoozeList, snoozeDetails, updatedSnooze) => {
  const { sensorId, measurementId, userId } = snoozeDetails
  const snoozeIndex = snoozeList.findIndex(snooze => {
    return (
      snooze.sensorId === sensorId &&
      snooze.measurementId === measurementId &&
      snooze.userId === userId
    )
  })

  const newSnoozeList = [...snoozeList]

  if (snoozeIndex > -1) {
    if (updatedSnooze) {
      newSnoozeList[snoozeIndex] = updatedSnooze
    } else {
      newSnoozeList.splice(snoozeIndex, 1)
    }
  } else {
    newSnoozeList.push(updatedSnooze)
  }

  return newSnoozeList
}

const sensorThresholdUserSnooze = createSlice({
  name: 'sensorThresholdUserSnooze',
  initialState: {
    notificationSnoozes: [],
    isLoading: false,
    errors: [],
    snoozeCreateError: {},
    snoozeDeleteError: {}
  },
  reducers: {
    cleanSensorThresholdNotificationSnoozes: state => {
      state.notificationSnoozes = []
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSensorThresholdUserSnooze.pending, (state, action) => {
        state.isLoading = true
        state.errors = []
      })
      .addCase(fetchSensorThresholdUserSnooze.fulfilled, (state, action) => {
        if (action.payload) {
          state.notificationSnoozes = [
            ...state.notificationSnoozes,
            action.payload
          ]
        }
        state.isLoading = false
      })
      .addCase(fetchSensorThresholdUserSnooze.rejected, (state, action) => {
        state.errors = [
          ...state.errors,
          { errorMsg: action.payload.error, snooze: action.payload.snooze }
        ]
        state.isLoading = false
      })
      .addCase(fetchSensorThresholdUserSnoozes.pending, (state, action) => {
        state.isLoading = true
        state.errors = []
        state.notificationSnoozes = []
      })
      .addCase(fetchSensorThresholdUserSnoozes.fulfilled, (state, action) => {
        state.notificationSnoozes = action.payload
        state.isLoading = false
      })
      .addCase(fetchSensorThresholdUserSnoozes.rejected, (state, action) => {
        state.errors = [
          {
            errorMsg: action.payload.error,
            snoozes: action.payload.snoozes
          }
        ]
        state.isLoading = false
      })
      .addCase(sendCreateSensorThresholdUserSnooze.pending, (state, action) => {
        state.isLoading = true
        state.snoozeCreateError = {}
      })
      .addCase(
        sendCreateSensorThresholdUserSnooze.fulfilled,

        (state, action) => {
          const { snooze, snoozeDetails } = action.payload

          state.isLoading = false
          state.notificationSnoozes = getUpdatedSnoozeList(
            state.notificationSnoozes,
            snoozeDetails,
            snooze
          )
        }
      )
      .addCase(
        sendCreateSensorThresholdUserSnooze.rejected,
        (state, action) => {
          state.isLoading = false
          state.snoozeCreateError = {
            errorMsg: action.payload.error,
            snooze: action.payload.snooze
          }
        }
      )
      .addCase(sendDeleteSensorThresholdUserSnooze.pending, (state, action) => {
        state.isLoading = true
        state.snoozeDeleteError = {}
      })
      .addCase(
        sendDeleteSensorThresholdUserSnooze.fulfilled,
        (state, action) => {
          const { snooze, snoozeDetails } = action.payload

          state.isLoading = false
          state.notificationSnoozes = getUpdatedSnoozeList(
            state.notificationSnoozes,
            snoozeDetails,
            snooze
          )
        }
      )
      .addCase(
        sendDeleteSensorThresholdUserSnooze.rejected,
        (state, action) => {
          state.isLoading = false
          state.snoozeDeleteError = {
            errorMsg: action.payload.error,
            snooze: action.payload.snooze
          }
        }
      )
  }
})

export const { cleanSensorThresholdNotificationSnoozes } =
  sensorThresholdUserSnooze.actions

export default sensorThresholdUserSnooze.reducer

export const getLanguage = (preferredLanguage = null) => {
  if (preferredLanguage) {
    const dir = preferredLanguage === 'ar' ? 'rtl' : 'ltr'
    document.documentElement.setAttribute('dir', dir)
    document.documentElement.setAttribute('lang', preferredLanguage)

    return preferredLanguage
  }

  let lang = 'en'
  if (navigator.userLanguage) {
    lang = navigator.userLanguage
  } else if (navigator.language) {
    lang = navigator.language
  } else if (navigator.languages && navigator.languages[0]) {
    lang = navigator.languages[0]
  }

  lang = lang.toLowerCase()
  if (lang.includes('en')) {
    lang = 'en'
  }

  const dir = lang === 'ar' ? 'rtl' : 'ltr'
  document.documentElement.setAttribute('dir', dir)
  document.documentElement.setAttribute('lang', lang)

  return lang
}

export const tableInitialState = {
  organizations: [],
  storageFacilities: [],
  page: 1
}

export const tableReducer = (state, action) => {
  if (action.state) {
    return {
      ...state,
      ...action.state
    }
  } else if (action.page) {
    return {
      ...state,
      page: action.page
    }
  } else if (action.organizations) {
    return {
      ...state,
      organizations: action.organizations,
      storageFacilities: []
    }
  } else if (action.storageFacilities) {
    return {
      ...state,
      storageFacilities: action.storageFacilities
    }
  }
  return state
}

export const formInitalState = {
  id: null,
  organizationId: '',
  storageFacilityId: '',
  name: ''
}

export const formReducer = (state, action) => {
  if (action.type === 'update') {
    return { ...state, [action.name]: action.value }
  }
  if (action.type === 'set-state') {
    return action.state
  }
  return state
}

import { useEffect, useReducer, useState } from 'react'
import { I18n } from 'aws-amplify'

import { Box, Flex, Text, Input, Select } from '@/primitives'

import Strings from '../../Strings'

import {
  getCurrentUserOrganizations,
  getZonesList,
  getZone,
  getNavigationLocationSelector
} from '@/reducers/selectors'

const initialState = {
  active: true,
  inactive: true
}

function reducer(state, action) {
  const newState = { ...state }
  newState[action.type] = action.value
  return newState
}

const StatusFilter = ({ setZonesList }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const locationSelector = getNavigationLocationSelector()

  const organizations = getCurrentUserOrganizations()
  const zones = getZonesList()
  const zone = getZone()

  const { active, inactive, orgsTab } = Strings()

  const [selectedOrg, setSelectedOrg] = useState('all')
  const [organizationOptions, setOrganizationOptions] = useState()

  useEffect(() => {
    if (locationSelector.size) {
      const organization = locationSelector.get('organization')
      if (organization?.id) {
        setSelectedOrg(organization.id)
      }
    }
  }, [locationSelector])

  useEffect(() => {
    let nextOrganizationOptions = organizations?.map(({ id, name }) => {
      return { value: id, text: name }
    })

    nextOrganizationOptions.unshift({ value: 'all', text: I18n.get('All') })
    setOrganizationOptions(nextOrganizationOptions)
  }, [organizations])

  useEffect(() => {
    if (zones.length !== 0) {
      const stateFilterOptions = [
        state.active ? 'active' : null,
        state.inactive ? 'inactive' : null
      ]

      /* We're always filtering some variation of active / inactive */
      let filteredList = zones.filter(zone =>
        stateFilterOptions.includes(zone.status)
      )

      if (selectedOrg !== 'all') {
        /* organizationId comes through as null sometimes */
        filteredList = filteredList.filter(
          ({ organizationId, parentId }) =>
            organizationId === selectedOrg ||
            (organizationId === null && parentId !== null)
        )
      }

      setZonesList(filteredList)
    }
  }, [state.active, state.inactive, zones, selectedOrg, setZonesList])

  function onChangeOrg(e) {
    setSelectedOrg(e.target.value)
  }

  const onToggleFilter = ({ target }) => {
    dispatch({ type: target.name, value: !target.value })
  }

  return (
    <Flex axisGap={300} alignCrossAxis='center'>
      <Flex
        as='label'
        alignCrossAxis='center'
        axisGap={200}
        css={{ marginBlock: 0 }}
      >
        <Text variant='page' tone={700}>
          {active}
        </Text>
        <Input
          name='active'
          type='checkbox'
          checked={state.active}
          value={state.active}
          onChange={onToggleFilter}
          css={{ marginInlineStart: '0.5rem' }}
        />
      </Flex>

      <Flex
        as='label'
        alignCrossAxis='center'
        axisGap={200}
        css={{ marginBlock: 0 }}
      >
        <Text variant='page' tone={700}>
          {inactive}
        </Text>
        <Input
          name='inactive'
          type='checkbox'
          checked={state.inactive}
          value={state.inactive}
          onChange={onToggleFilter}
          css={{ marginInlineStart: '0.5rem' }}
        />
      </Flex>

      {!zone?.id && (
        <Box
          css={{
            marginBlock: 0,
            display: 'flex',
            alignItems: 'center',
            gap: '1rem'
          }}
        >
          <Text variant='page' tone={700}>
            {orgsTab}
          </Text>
          <Select
            css={{ flex: '1 1 0' }}
            name='selectedOrg'
            value={selectedOrg}
            onChange={onChangeOrg}
          >
            {organizationOptions?.map(({ value, text }) => (
              <option key={value} value={value}>
                {text}
              </option>
            ))}
          </Select>
        </Box>
      )}
    </Flex>
  )
}

export default StatusFilter

import { makeActionCreator } from '@/Util/ReduxUtils'

export const REQUEST_PACKAGE_UNIT_INVENTORY_CALIBRATIONS =
  'REQUEST_PACKAGE_UNIT_INVENTORY_CALIBRATIONS'
export const requestPackageUnitInventoryCalibrations = makeActionCreator(
  REQUEST_PACKAGE_UNIT_INVENTORY_CALIBRATIONS,
  'params'
)

export const RECEIVE_PACKAGE_UNIT_INVENTORY_CALIBRATIONS =
  'RECEIVE_PACKAGE_UNIT_INVENTORY_CALIBRATIONS'
export const receivePackageUnitInventoryCalibrations = makeActionCreator(
  RECEIVE_PACKAGE_UNIT_INVENTORY_CALIBRATIONS,
  'packageUnitInventoryCalibrations',
  'count',
  'error'
)

export const CREATE_PACKAGE_UNIT_INVENTORY_CALIBRATION =
  'CREATE_PACKAGE_UNIT_INVENTORY_CALIBRATION'
export const createPackageUnitInventoryCalibration = makeActionCreator(
  CREATE_PACKAGE_UNIT_INVENTORY_CALIBRATION,
  'params'
)

export const PACKAGE_UNIT_INVENTORY_CALIBRATION_CREATED =
  'PACKAGE_UNIT_INVENTORY_CALIBRATION_CREATED'
export const packageUnitInventoryCalibrationCreated = makeActionCreator(
  PACKAGE_UNIT_INVENTORY_CALIBRATION_CREATED,
  'packageUnitInventoryCalibration',
  'error'
)

export const CLEAR_PACKAGE_UNIT_INVENTORY_CALIBRATIONS =
  'CLEAR_PACKAGE_UNIT_INVENTORY_CALIBRATIONS'
export const clearPackageUnitInventoryCalibrations = makeActionCreator(
  CLEAR_PACKAGE_UNIT_INVENTORY_CALIBRATIONS
)

import React, { useEffect, Fragment } from 'react'
import { useDispatch } from 'react-redux'

import {
  Button,
  Column,
  Cell,
  FlexV2,
  Form,
  Loader,
  Row,
  Slot,
  Table,
  Text
} from '@/primitives'

import { Dialog } from '@/elements'

import {
  sendDeleteRole,
  fetchRoleUsers,
  cleanRole
} from '@/slices/management/role'

import {
  getRolePermissions,
  getRoleUsers,
  getRoles,
  getIsLoadingSection,
  getRolesError,
  getRoleUsersFetched
} from '@/reducers/selectors'

import Strings from '../Strings'
import { showBanner } from '@/slices/util'

const DeleteRoleModal = ({ showDeleteRole, setShowDeleteRole }) => {
  const strings = Strings()

  const role = getRolePermissions()
  const roles = getRoles()
  const roleUsers = getRoleUsers()
  const roleUsersFetched = getRoleUsersFetched()
  const isLoadingSection = getIsLoadingSection()
  const error = getRolesError()

  const dispatch = useDispatch()

  useEffect(() => {
    if (role?.name?.length > 0) {
      dispatch(fetchRoleUsers({ roleName: role?.name }))
    }
  }, [role?.name, dispatch])

  useEffect(() => {
    const isDeleted = !roles?.some(({ name }) => name === role?.name)
    if (isDeleted && showDeleteRole) {
      setShowDeleteRole(false)
      dispatch(cleanRole())
    }
  }, [roles, role, setShowDeleteRole, showDeleteRole, dispatch])

  const {
    removeRoleHeadingPart1,
    removeRoleHeadingPart2,
    removeRoleSubheading,
    affectedUsers,
    cancel,
    deleteRoleButton,
    noAffectedUsers,
    listAffectedUsers
  } = strings

  const onClickClose = () => {
    setShowDeleteRole(false)
    dispatch(showBanner({ show: false, message: '', type: null }))
  }

  const onSubmitForm = e => {
    e.preventDefault()
    dispatch(sendDeleteRole({ roleName: role?.name }))
  }

  return (
    <Dialog open={showDeleteRole} onOpenChange={onClickClose}>
      <Slot name='title'>
        <FlexV2 direction='column' style={{ marginBottom: '1em' }}>
          <Text
            as='h5'
            style={{ marginBottom: '0.5em' }}
          >{`${removeRoleHeadingPart1} ${role?.name} ${removeRoleHeadingPart2}`}</Text>
          <Text as='p' size={100}>
            {removeRoleSubheading}
          </Text>
        </FlexV2>
      </Slot>
      <Slot name='content'>
        <Loader isLoading={!roleUsersFetched || isLoadingSection}>
          <Form onSubmit={onSubmitForm}>
            {roleUsers.length > 0 && (
              <Fragment>
                {/*<h2>{fallbackRole}</h2>
              <Form.Select
                icon='angle down'
                options={mockRoles}
                placeholder={fallbackRolePlaceholder}
                disabled
              />
              <hr />*/}
                <FlexV2 direction='column' style={{ marginBottom: '1em' }}>
                  <Text as='h4' style={{ marginBottom: '0.5em' }}>
                    {affectedUsers}
                  </Text>
                  <Text as='p' size={200}>
                    {listAffectedUsers}
                  </Text>
                </FlexV2>
                <Table>
                  <Slot name='head'>
                    <Row>
                      <Column>{strings.tableUserColumn}</Column>
                      <Column>{strings.tableUsernameColumn}</Column>
                    </Row>
                  </Slot>
                  <Slot name='body'>
                    {roleUsers.map(({ firstName, lastName, userName }) => (
                      <Row key={userName}>
                        <Cell>{`${firstName} ${lastName}`}</Cell>
                        <Cell>{userName}</Cell>
                      </Row>
                    ))}
                  </Slot>
                </Table>
              </Fragment>
            )}
            {roleUsers.length === 0 && (
              <FlexV2 direction='column' style={{ marginBottom: '1em' }}>
                <Text as='h4' style={{ marginBottom: '0.5em' }}>
                  {affectedUsers}
                </Text>
                <Text as='p' size={200}>
                  {roleUsersFetched && !error && noAffectedUsers}
                </Text>
              </FlexV2>
            )}
            <FlexV2 alignMainAxis='space-between'>
              <Button variant='neutral' onClick={onClickClose}>
                {cancel}
              </Button>
              <Button
                type='submit'
                variant='danger'
                disabled={roleUsers.length > 0 || error || !roleUsersFetched}
              >
                {deleteRoleButton}
              </Button>
            </FlexV2>
          </Form>
        </Loader>
      </Slot>
    </Dialog>
  )
}

export default DeleteRoleModal

export const SUPPLIER_STATUSES = strings => {
  return {
    active: strings.active,
    inactive: strings.inactive,
    canceled: strings.canceled,
    banned: strings.banned
  }
}

export const SUPPLIER_CATEGORIES = strings => {
  return {
    retail: strings.retail,
    wholesale: strings.wholesale,
    service: strings.service
  }
}

export const SUPPLY_TYPES = strings => {
  return {
    packaging: strings.packaging,
    branding: strings.branding,
    seeds: strings.seeds,
    fertilizer: strings.fertilizer,
    substrate: strings.substrate,
    technology: strings.technology,
    construction: strings.construction,
    telecommunications: strings.telecommunications,
    energy: strings.energy
  }
}

import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

import FlexV2 from '@/primitives/FlexV2'
import LineSeparator from '@/elements/LineSeparator'
import { fetchTimeframeGraphAverages } from '@/actions/ts/timeframe'

import { useDataboard } from '../context'
import { DATABOARD_ACTIONS } from '../state'
import {
  getCurrentUser,
  getMeasurements,
  getTimeframeGraphAverages,
  getTimeframeGraphAveragesError,
  getTimeframeGraphAveragesIsLoading,
  getZonesHierarchy,
  getRootZone
} from '@/reducers/selectors'
import {
  findDuplicateDateFilterIndices,
  findDuplicateZoneFilterIndices
} from '../Utils/FiltersUtils'
import { parseDateFilters, parseZoneFilters } from '../Utils/FetchUtils'
import { cacheConfig, canFetch } from './Utils/ActionUtils'
import {
  onDownloadCSVComparison,
  thunkDownloadCsvDateComparison
} from '../Utils/DownloadUtils'
import { COMPARISON_TYPES } from '../Utils/config'

import DataboardActions from './Actions'
import FiltersContainer from './FilterContainer'

export default function DataboardFilters() {
  const dispatch = useDispatch()
  const { state, dispatchState } = useDataboard()

  const allMeasurements = getMeasurements()
  const zonesHierarchy = getZonesHierarchy()
  const currentUser = getCurrentUser()
  const rootZone = getRootZone()

  const timeframeGraphAverages = getTimeframeGraphAverages()
  const timeframeGraphAveragesError = getTimeframeGraphAveragesError()
  const timegrameGraphAveragesLoading = getTimeframeGraphAveragesIsLoading()

  const onClickFetch = () => {
    if (state.localStorageId) {
      try {
        cacheConfig(state)
      } catch (e) {
        localStorage.clear()
        cacheConfig(state)
      }
    }
    dispatchState({
      type: DATABOARD_ACTIONS.SET_DATASET,
      dataset: null
    })

    const hasCustomRange = state.dateFilters.some(
      filter => filter.dateRange === 'customRange'
    )

    dispatch(
      fetchTimeframeGraphAverages({
        measurements: state?.measurements[state.comparisonType],
        zoneParams: parseZoneFilters(state),
        dateParams: parseDateFilters(state),
        cacheOperation: !hasCustomRange,
        type: state.comparisonType,
        view: 'databoard',
        userHadClickedInFectch: true
      })
    )
  }

  useEffect(() => {
    if (!timeframeGraphAveragesError && !timegrameGraphAveragesLoading) {
      dispatchState({
        type: DATABOARD_ACTIONS.SET_DATASET,
        dataset: timeframeGraphAverages
      })
    }
  }, [
    timeframeGraphAverages,
    timeframeGraphAveragesError,
    timegrameGraphAveragesLoading,
    dispatchState
  ])

  const zoneDuplicateIndices = findDuplicateZoneFilterIndices(state.zoneFilters)
  const dateDuplicateIndicies = findDuplicateDateFilterIndices(
    state.dateFilters
  )

  const onClickDownload = e => {
    e.preventDefault()
    if (state.comparisonType === COMPARISON_TYPES.DATE_RANGES) {
      dispatch(
        thunkDownloadCsvDateComparison({
          state,
          allMeasurements,
          zonesHierarchy,
          user: currentUser,
          site: rootZone
        })
      )
    }
    if (state.comparisonType === COMPARISON_TYPES.LOCATION_RANGES) {
      onDownloadCSVComparison(
        state,
        allMeasurements,
        zonesHierarchy,
        currentUser,
        rootZone
      )
    }
  }

  return (
    <FlexV2 direction='column' axisGap={400}>
      <FiltersContainer />
      <DataboardActions
        duplicateIndices={
          state.comparisonType === COMPARISON_TYPES.DATE_RANGES
            ? dateDuplicateIndicies
            : zoneDuplicateIndices
        }
        comparisonType={state.comparisonType}
        canDownload={state?.dataset && state?.dataset?.length > 0}
        canFetch={canFetch(state)}
        onClickFetch={onClickFetch}
        onClickDownload={onClickDownload}
      />
      <LineSeparator />
    </FlexV2>
  )
}

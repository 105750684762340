import { FlexV2, Text } from '../index'

export default function Logo({ size = 'small', brand, byline }) {
 return (
  <FlexV2 direction={'column'} className={'Logo'} alignMainAxis='center'>
    <Text
      size={size}
      fontFamily='logo'
      fontWeight={600}
      className={'Logo__Brand'}
    >
      {brand}
    </Text>
    {byline && (
      <Text fontFamily='logo' fontWeight={400} className={'Logo__Byline'}>
        {byline}
      </Text>
    )}
  </FlexV2>
 )
}
import { getAllOrganizationLabels } from '@/reducers/selectors'
import clone from 'lodash/clone'
import {
  OrganizationLabelStrings,
  selectOrganizationLabel
} from './OrganizationLabels'

function getZoneBasePathItems(params) {
  return getZoneBasePath(params.zone).split('/')
}

function getZoneBasePath(params) {
  if (params?.zone) {
    return `/zones/${params.zone}`
  }
  return '/zones'
}

function getCurrentZone(params) {
  if (!params.zone) {
    return []
  }
  const zones = params.zone.split('/')
  return zones[zones.length - 1]
}

function getPrimaryZone(params) {
  if (!params.zone) {
    return null
  }
  const zones = params.zone.split('/')
  return zones[0]
}

function getPrimaryZoneFromParentPath(parentPath) {
  if (!parentPath) {
    return null
  }
  const parts = parentPath.split('/')
  if (parts.length > 1) {
    return parts[1]
  }

  return null
}

function getZoneDepth(zone) {
  return zone?.parentId ? (zone?.parentPath?.split('/').length ?? 2) - 2 : 0
}

function getZoneDepthIdentifier(zone, modifier = 0) {
  const depth = Math.max(getZoneDepth(zone) + modifier, 0)
  return getZoneDepthIdentifierFromDepth(depth)
}

function getZoneDepthString(zone, modifier = 0, plural = false) {
  const id = getZoneDepthIdentifier(zone, modifier)
  return getZoneDepthStringFromIdentfier(id, plural)
}

function getZoneDepthStringFromDepth(depth, plural = false, labels = null) {
  const id = getZoneDepthIdentifierFromDepth(depth)
  return getZoneDepthStringFromIdentfier(id, plural, labels)
}

function getZoneDepthStringFromIdentfier(id, plural = false, labels = null) {
  const orgLabels = labels !== null ? labels : getAllOrganizationLabels()
  const organizationLabelStrings = OrganizationLabelStrings()

  return selectOrganizationLabel({
    label: id,
    fallbackLabels: organizationLabelStrings[id],
    isPlural: plural,
    organizationLabels: orgLabels
  })
}

function getZoneDepthIdentifierFromDepth(depth) {
  switch (depth) {
    case 0:
      return 'site'
    case 1:
      return 'facility'
    case 2:
      return 'room'
    case 3:
      return 'zone'
    default:
      return 'subzone'
  }
}

function getRootZonesFromHierarchy(zonesHierarchy) {
  const rootZoneIds = Object.keys(zonesHierarchy)
  const rootZones = rootZoneIds.map(zoneId => zonesHierarchy[zoneId])
  rootZones.sort((a, b) => {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  })

  return rootZones
}

/**
 * This will return the value of the propertyKey or node from the zoneHierarchy
 * @param {string} path
 * @param {Object} zoneHierarchy
 * @param {string} propertyKey
 * @throws  If propertyKey does not exist
 * @returns value of propertyKey or node
 */
function getZoneHierarchyValueByPath(zoneHierarchy, path, propertyKey = null) {
  // if zoneHierarchy is empty or is null, return null
  if (!zoneHierarchy || Object.keys(zoneHierarchy).length === 0) {
    return null
  }

  const ids = path[0] === '/' ? path.slice(1).split('/') : path.split('/')

  if (ids.length === 1) {
    if (propertyKey === null) {
      return zoneHierarchy
    }
    const value = zoneHierarchy?.[propertyKey]
    if (!value) {
      throw Error('Property does not exist. Please check the propertyKey')
    }
    return value
  }

  const currentId = ids[1]

  if (zoneHierarchy?.children?.[currentId] || zoneHierarchy?.[currentId]) {
    return getZoneHierarchyValueByPath(
      ids[0] === 'zones'
        ? zoneHierarchy?.[currentId]
        : zoneHierarchy.children[currentId],
      ids.slice(1).join('/'),
      propertyKey
    )
  }

  return null // return null when path doesn't exist since should always have devices array
}

function getZoneHierarchyDepthByPath(hierarchy, path, depth = 0) {
  if (!hierarchy || !hierarchy.children) return depth

  for (let childKey in hierarchy.children) {
    if (hierarchy.children[childKey].parentPath === path) {
      return depth + 1
    } else {
      return getZoneHierarchyDepthByPath(
        hierarchy.children[childKey],
        path,
        depth + 1
      )
    }
  }

  return depth
}

/**
 * This will traverse the zoneHierarchy with the path and return the nodes in an array. The nodes will be in the order of the path
 * @param {Object} params - params object
 * @param {Object} params.zonesHierarchy - zoneHierarchy object
 * @param {string} params.path - path to traverse
 * @param {function} params.zoneModifier - function to modify the zone object
 * @param {Array} zones - array of zones
 * @returns
 */
const flattenZoneHierarchyPath = (
  { zonesHierarchy, path, zoneModifier },
  zones = []
) => {
  if (!zonesHierarchy || Object.values(zonesHierarchy).length === 0) {
    return null
  }

  const ids = path[0] === '/' ? path.slice(1).split('/') : path.split('/')

  if (ids.length === 1) {
    return zones
  }

  const currentId = ids[1]
  if (zonesHierarchy?.children?.[currentId]) {
    let zone = clone(zonesHierarchy?.children[currentId])
    if (zoneModifier) {
      zone = zoneModifier(zone)
    }
    zones.push(zone)
    const nextNode =
      ids[0] === 'zones'
        ? zonesHierarchy?.[currentId]
        : zonesHierarchy.children[currentId]

    return flattenZoneHierarchyPath(
      {
        zonesHierarchy: nextNode,
        path: ids.slice(1).join('/'),
        zoneModifier
      },
      zones
    )
  }

  return null
}

const getZoneHierarchyDiplayNameMapByPath = (
  zoneHierarchy,
  path,
  parts = {}
) => {
  if (!zoneHierarchy || Object.values(zoneHierarchy).length === 0) {
    return null
  }

  if (!zoneHierarchy.parentId) {
    parts = { [zoneHierarchy.id]: zoneHierarchy.name }
  }

  const ids = path[0] === '/' ? path.slice(1).split('/') : path.split('/')

  if (ids.length === 1) {
    return parts
  }

  const currentId = ids[1]
  if (zoneHierarchy?.children?.[currentId]) {
    return getZoneHierarchyDiplayNameMapByPath(
      ids[0] === 'zones'
        ? zoneHierarchy?.[currentId]
        : zoneHierarchy.children[currentId],
      ids.slice(1).join('/'),
      { ...parts, [currentId]: zoneHierarchy.children[currentId].name }
    )
  }

  return null
}

const searchZoneChildren = (children, zoneId) => {
  if (children[zoneId]) {
    return children[zoneId]
  } else {
    for (let childId of Object.keys(children)) {
      if (Object.keys(children[childId].children).length > 0) {
        const zone = searchZoneChildren(children[childId].children, zoneId)
        if (zone) {
          return zone
        }
      }
    }
  }
  return null
}

export const getSensorName = (zonesHierarchy, siteId, zoneId, sensorId) => {
  if (sensorId) {
    const zone = getZone(zonesHierarchy, siteId, zoneId)
    if (zone?.devices.length > 0) {
      for (let device of zone.devices) {
        if (device.id === sensorId) {
          return device.name
        }
      }
    }
  }
  return null
}

export const getZoneName = (zonesHierarchy, siteId, zoneId = null) => {
  const zone = getZone(zonesHierarchy, siteId, zoneId)
  if (zone?.name) {
    return zone.name
  }
  return null
}

export const getZone = (zonesHierarchy, siteId, zoneId = null) => {
  if (zonesHierarchy[siteId]) {
    if (zoneId && zoneId !== siteId) {
      return searchZoneChildren(zonesHierarchy[siteId].children, zoneId)
    } else {
      return zonesHierarchy[siteId]
    }
  }
  return null
}

/**
 * This function is used to search for the core device in the hierarchy tree
 * - it will search all the branches of the tree and return the core device node
 * - it will return early if the core device is found
 * @param {object} branch
 * @returns
 */
function searchCoreDeviceInBranch(branch) {
  if (branch?.hasCoreDevice) {
    return branch
  }

  for (const childId in branch.children) {
    const child = branch.children[childId]
    const coreDevice = searchCoreDeviceInBranch(child)
    if (coreDevice) {
      return child
    }
  }

  return null
}

/**
 * Checks if a zone or any of its parent zones have a core device.
 * @param {Object} zone - The zone object to check.
 * @param {Object} hierarchy - The hierarchy object to search in.
 * @returns {boolean} - Returns true if a core device is found, otherwise returns false.
 */
const hasCoreDeviceAbove = (hierarchy, zone) => {
  return zone
    ? zone.hasCoreDevice ||
        hasCoreDeviceAbove(
          hierarchy,
          getZoneFromHierarchy(hierarchy, zone.parentId)
        )
    : false
}

/**
 * This function will explore the zone tree and check if it has at least one device
 * - it will return early if it finds a device
 * - it will return false if it doesn't find any device
 * @param {object} zone
 * @returns {boolean}
 **/
function zoneHasDevicesInBranch(zone) {
  if (zone?.devices.length > 0) {
    return true
  }

  for (const childId in zone.children) {
    const child = zone.children[childId]
    const hasDevices = zoneHasDevicesInBranch(child)
    if (hasDevices) {
      return true
    }
  }

  return false
}

export const getZoneFromHierarchy = (children, zoneId) => {
  if (children[zoneId]) {
    return children[zoneId]
  } else {
    for (let childId of Object.keys(children)) {
      if (Object.keys(children[childId].children).length > 0) {
        const zone = getZoneFromHierarchy(children[childId].children, zoneId)
        if (zone) {
          return zone
        }
      }
    }
  }
  return null
}

export const filterHierarchyByOrganization = (children, organizationId) => {
  let newHierarchy = {}
  for (let childId of Object.keys(children)) {
    if (children[childId].organizationId === organizationId) {
      newHierarchy[childId] = children[childId]
    }
  }
  return newHierarchy
}

const getDeviceReadMeasurements = (readMeasurements, zone, allDeviceTypes) => {
  if (zone?.devices.length > 0) {
    readMeasurements = readMeasurements.concat(
      zone.devices.reduce((resultMeasurements, device) => {
        if (device?.config?.read_measurementType?.length > 0) {
          return resultMeasurements.concat(device.config.read_measurementType)
        } else {
          const deviceType = allDeviceTypes.find(
            d => d.id === device.sensorType
          )
          if (deviceType?.measurements) {
            return resultMeasurements.concat(deviceType.measurements)
          }
          return resultMeasurements
        }
      }, readMeasurements)
    )
  }
  if (zone?.children && Object.keys(zone.children).length > 0) {
    for (let childZone of Object.values(zone.children)) {
      readMeasurements = getDeviceReadMeasurements(
        readMeasurements,
        childZone,
        allDeviceTypes
      )
    }
  }
  return readMeasurements.filter((v, i, s) => s.indexOf(v) === i)
}

export const getAllReadMeasurements = (
  zonesHierarchy,
  siteId,
  zoneId = null,
  allDeviceTypes = []
) => {
  if (zonesHierarchy[siteId]) {
    if (zoneId && zoneId !== siteId) {
      const zoneFromHierarchy = getZoneFromHierarchy(
        zonesHierarchy[siteId].children,
        zoneId
      )
      return getDeviceReadMeasurements([], zoneFromHierarchy, allDeviceTypes)
    }
    return getDeviceReadMeasurements([], zonesHierarchy[siteId], allDeviceTypes)
  }
  return []
}

export const getAllReadMeasurementsForOrganization = (
  zonesHierarchy,
  organizationId,
  allDeviceTypes
) => {
  return Object.values(zonesHierarchy).reduce((resultMeasurements, site) => {
    if (site.organizationId === organizationId) {
      resultMeasurements = resultMeasurements.concat(
        getAllReadMeasurements(zonesHierarchy, site.id, null, allDeviceTypes)
      )
    }
    return resultMeasurements.filter((v, i, s) => s.indexOf(v) === i)
  }, [])
}

const getDeviceMeasurementsFromHierarchy = (zone, deviceId, allDeviceTypes) => {
  for (let device of zone.devices) {
    if (device.id === deviceId) {
      if (device?.config?.read_measurementType?.length > 0) {
        return device.config.read_measurementType
      } else {
        const deviceType = allDeviceTypes.find(d => d.id === device.sensorType)
        return deviceType?.measurements
      }
    }
  }
  if (zone?.children && Object.keys(zone.children).length > 0) {
    for (let childZone of Object.values(zone.children)) {
      let measurements = getDeviceMeasurementsFromHierarchy(
        childZone,
        deviceId,
        allDeviceTypes
      )
      if (measurements.length > 0) {
        return measurements
      }
    }
  }
  return []
}

export const getAllReadMeasurementsForDevice = (
  zonesHierarchy,
  siteId,
  deviceId,
  allDeviceTypes = []
) => {
  if (zonesHierarchy[siteId]) {
    return getDeviceMeasurementsFromHierarchy(
      zonesHierarchy[siteId],
      deviceId,
      allDeviceTypes
    )
  }
  return []
}

const getAllDevicesInHierarchy = (hierarchy, devices = {}) => {
  if (!hierarchy) {
    return devices
  }

  if (hierarchy?.devices?.length > 0) {
    hierarchy.devices.forEach(device => {
      devices[device.id] = device
    })
  }
  if (hierarchy?.children && Object.keys(hierarchy.children).length > 0) {
    for (let childZone of Object.values(hierarchy.children)) {
      devices = getAllDevicesInHierarchy(childZone, devices)
    }
  }
  return devices
}

const ZoneUtils = {
  getZoneBasePath,
  getZoneBasePathItems,
  getCurrentZone,
  getPrimaryZone,
  getPrimaryZoneFromParentPath,
  getZoneDepthIdentifier,
  getZoneDepthString,
  getZoneDepthStringFromDepth,
  getZoneDepthIdentifierFromDepth,
  getZoneFromHierarchy,
  getRootZonesFromHierarchy,
  getZoneHierarchyValueByPath,
  getZoneHierarchyDepthByPath,
  getZoneHierarchyDiplayNameMapByPath,
  hasCoreDeviceAbove,
  searchCoreDeviceInBranch,
  zoneHasDevicesInBranch,
  getSensorName,
  getZoneName,
  getZone,
  getAllReadMeasurements,
  getAllReadMeasurementsForOrganization,
  getAllReadMeasurementsForDevice,
  flattenZoneHierarchyPath,
  getAllDevicesInHierarchy
}

export default ZoneUtils

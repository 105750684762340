import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { getCognitoData, makeGraphqlRequest } from '../utils'

/*
 * Queries
 */

export const getWeatherForecast = async params => {
  try {
    const query = /* GraphQL */ `
      query getWeatherForecast($input: GetWeatherForecastInput!) {
        getWeatherForecast(input: $input) {
          siteId
          weatherAt
          conditionCode
          temperature
          sunriseAt
          sunsetAt
        }
      }
    `

    const { id, idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken,
      id
    )

    if (error) {
      return {
        forecast: null,
        error: `Weather forecast not fetched: ${error}`
      }
    }

    return { forecast: data?.getWeatherForecast, error: null }
  } catch (err) {
    return { forecast: null, error: err.message }
  }
}

export const getDailyForecast = async params => {
  try {
    const query = /* GraphQL */ `
      query getDailyForecast($input: GetDailyForecastInput!) {
        getDailyForecast(input: $input) {
          siteId
          weatherAt
          conditionCode
          temperature
          sunriseAt
          sunsetAt
        }
      }
    `

    const { id, idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken,
      id
    )

    if (error) {
      return {
        forecast: null,
        error: `Daily forecast not fetched: ${error}`
      }
    }

    return { forecast: data?.getDailyForecast, error: null }
  } catch (err) {
    return { forecast: null, error: err.message }
  }
}

import { Card, FlexV2, Symbol, Slot, Text } from '@/primitives'

const roundToSpecificDecimalPlace = (numberToRound, decimalPlaces = 6) =>
  Number(Math.round(numberToRound + 'e' + decimalPlaces) + 'e-' + decimalPlaces)

export default function ZoneCard({ zone }) {
  const { name, latitude, longitude, timeZone } = zone

  return (
    <Card className={'ZoneCards__Card'}>
      <Slot name='body'>
        <FlexV2 direction='column' axisGap={200}>
          <FlexV2 axisGap={300} alignCrossAxis='center'>
            <Symbol name='explore' tone={500} variant='primary' />
            <Text fontWeight={700} size={300} truncate={18}>
              {name}
            </Text>
          </FlexV2>
          <Text as='p' size={100} fontWeight={300}>
            {roundToSpecificDecimalPlace(latitude)},{' '}
            {roundToSpecificDecimalPlace(longitude)}
          </Text>
          <Text as='strong' size={200} variant='page' tone={900}>
            {timeZone}
          </Text>
        </FlexV2>
      </Slot>
    </Card>
  )
}

import { I18n } from 'aws-amplify'

import {
  RESOURCE_TYPE_OPERATIONS_PLANS,
  RESOURCE_TYPE_OPERATIONS_HARVEST,
  RESOURCE_TYPE_OPERATIONS_INVENTORY,
  RESOURCE_TYPE_OPERATIONS_SALES,
  RESOURCE_TYPE_OPERATIONS_WASTE,
  RESOURCE_TYPE_OPERATIONS_RETURNS,
  RESOURCE_TYPE_OPERATIONS_CUSTOMERS,
  RESOURCE_TYPE_OPERATIONS_MANAGEMENT,
  RESOURCE_TYPE_OPERATIONS_FERTILIZER
} from '../../Util/PermissionUtils'

import Crop from './Pipelines/Crop'
import Harvest from './Pipelines/Harvest'
import Inventory from './Pipelines/Inventory'
import Sale from './Pipelines/Sale'
import Waste from './Pipelines/Waste'
import Return from './Pipelines/Return'
import Product from './Pipelines/Product'
import Customer from './Pipelines/Customer'
import Supplier from './Pipelines/Supplier'
import PackageUnitInventory from './Pipelines/PackageUnitInventory'
import FertilizerInventory from './Pipelines/FertilizerInventory'
import Management from './Management'
import Admin from './Admin'

import PackageUnit from './Management/PackageUnit'
import StorageFacility from './Management/StorageFacility'
import PaymentMethod from './Management/PaymentMethod'
import PaymentTerm from './Management/PaymentTerm'

const routes = [
  {
    path: '/operations/:zone+/crops',
    text: I18n.get('Crops'),
    resource: RESOURCE_TYPE_OPERATIONS_PLANS,
    Component: Crop
  },
  {
    path: '/operations/:zone+/harvest',
    text: I18n.get('Harvest'),
    resource: RESOURCE_TYPE_OPERATIONS_HARVEST,
    Component: Harvest
  },
  {
    path: '/operations/:zone+/inventory/packaging/package-units',
    text: I18n.get('Package Units'),
    Component: PackageUnit
  },
  {
    path: '/operations/:zone+/inventory/storage-facilities',
    text: I18n.get('Storage Facilities'),
    Component: StorageFacility
  },
  {
    path: '/operations/:zone+/inventory/packaging',
    text: I18n.get('Packaging'),
    resource: RESOURCE_TYPE_OPERATIONS_INVENTORY,
    Component: PackageUnitInventory
  },
  {
    path: '/operations/:zone+/inventory/suppliers',
    text: I18n.get('Suppliers'),
    resource: RESOURCE_TYPE_OPERATIONS_INVENTORY,
    Component: Supplier
  },
  {
    path: '/operations/:zone+/inventory/fertilizer',
    text: I18n.get('Fertilizer'),
    resource: RESOURCE_TYPE_OPERATIONS_FERTILIZER,
    Component: FertilizerInventory
  },
  {
    path: '/operations/:zone+/inventory',
    text: I18n.get('Inventory'),
    resource: RESOURCE_TYPE_OPERATIONS_INVENTORY,
    Component: Inventory
  },
  {
    path: '/operations/:zone+/sales/returns',
    text: I18n.get('Returns'),
    resource: RESOURCE_TYPE_OPERATIONS_RETURNS,
    Component: Return
  },
  {
    path: '/operations/:zone+/sales/payment-methods',
    text: I18n.get('Payment Methods'),
    Component: PaymentMethod
  },
  {
    path: '/operations/:zone+/sales/payment-terms',
    text: I18n.get('Payment Terms'),
    Component: PaymentTerm
  },
  {
    path: '/operations/:zone+/sales',
    text: I18n.get('Sales'),
    resource: RESOURCE_TYPE_OPERATIONS_SALES,
    Component: Sale
  },
  {
    path: '/operations/:zone+/waste',
    text: I18n.get('Waste'),
    resource: RESOURCE_TYPE_OPERATIONS_WASTE,
    Component: Waste
  },
  {
    path: '/operations/:zone+/products',
    text: I18n.get('Products'),
    resource: RESOURCE_TYPE_OPERATIONS_SALES,
    Component: Product
  },
  {
    path: '/operations/:zone+/customers',
    text: I18n.get('Customers'),
    resource: RESOURCE_TYPE_OPERATIONS_CUSTOMERS,
    Component: Customer
  },
  {
    path: '/operations/:zone+/management',
    text: I18n.get('Management'),
    resource: RESOURCE_TYPE_OPERATIONS_MANAGEMENT,
    Component: Management
  },
  {
    path: '/operations/admin',
    text: I18n.get('Admin'),
    resource: RESOURCE_TYPE_OPERATIONS_MANAGEMENT,
    Component: Admin
  }
]

export default routes

import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { getCognitoData, makeGraphqlRequest } from '../utils'

/*
 * Queries
 */

export const getDashboards = async (params = {}) => {
  try {
    const query = /* GraphQL */ `
      query getDashboardsByUser {
        getDashboardsByUser {
          userId
          dashboardId
          zonePath
          context
          isDefault
          config
          version
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)

    const { data } = await makeGraphqlRequest(GRAPHQL_URL, operation, idToken)

    if (!data?.getDashboardsByUser) {
      throw Error('Unable to fetch dashboards')
    }

    return { dashboards: data?.getDashboardsByUser, error: null }
  } catch (err) {
    throw err
  }
}

/*
 * Mutation
 */

export const copyDashboard = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation copyDashboard(
        $originDashboardId: String!
        $dashboardId: String!
        $zonePath: String!
        $zoneId: String!
      ) {
        copyDashboard(
          originDashboardId: $originDashboardId
          dashboardId: $dashboardId
          zonePath: $zonePath
          zoneId: $zoneId
        ) {
          userId
          dashboardId
          zonePath
          context
          isDefault
          config
          version
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, { ...params, zoneId: '' })
    const { data } = await makeGraphqlRequest(GRAPHQL_URL, operation, idToken)

    if (!data?.copyDashboard) {
      throw Error('Unable to update dashboard')
    }

    return { dashboard: data?.copyDashboard, error: null }
  } catch (err) {
    throw err
  }
}

export const upsertDashboardConfig = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation upsertDashboardConfig(
        $dashboardId: String!
        $zonePath: String!
        $zoneId: String!
        $config: [GraphQLJSON]!
        $isFixingZonePath: Boolean
      ) {
        upsertDashboardConfig(
          zonePath: $zonePath
          zoneId: $zoneId
          dashboardId: $dashboardId
          config: $config
          isFixingZonePath: $isFixingZonePath
        ) {
          userId
          dashboardId
          zonePath
          context
          isDefault
          config
          version
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, { ...params, zoneId: '' })
    const { data } = await makeGraphqlRequest(GRAPHQL_URL, operation, idToken)

    if (!data?.upsertDashboardConfig) {
      throw Error('Unable to upsert dashboard config')
    }

    return { dashboard: data?.upsertDashboardConfig, error: null }
  } catch (err) {
    throw err
  }
}

export const updateDashboard = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateDashboard(
        $dashboardId: String!
        $zonePath: String
        $zoneId: String!
        $context: String!
        $isDefault: Boolean!
        $config: [GraphQLJSON]!
        $version: Int!
      ) {
        updateDashboard(
          dashboardId: $dashboardId
          zonePath: $zonePath
          zoneId: $zoneId
          context: $context
          isDefault: $isDefault
          config: $config
          version: $version
        ) {
          userId
          dashboardId
          zonePath
          context
          isDefault
          config
          version
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, { ...params, zoneId: '' })
    const { data } = await makeGraphqlRequest(GRAPHQL_URL, operation, idToken)

    if (!data?.updateDashboard) {
      throw Error('Unable to update dashboard')
    }

    return { dashboard: data?.updateDashboard, error: null }
  } catch (err) {
    throw err
  }
}

export const resetDashboard = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation resetDashboard(
        $dashboardId: String!
        $zonePath: String
        $zoneId: String!
        $context: String!
        $isDefault: Boolean!
      ) {
        resetDashboard(
          dashboardId: $dashboardId
          zonePath: $zonePath
          zoneId: $zoneId
          context: $context
          isDefault: $isDefault
        ) {
          userId
          dashboardId
          zonePath
          context
          isDefault
          config
          version
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, { ...params, zoneId: '' })
    const { data } = await makeGraphqlRequest(GRAPHQL_URL, operation, idToken)

    if (!data?.resetDashboard) {
      throw Error('Unable to reset dashboard')
    }

    return { dashboard: data?.resetDashboard, error: null }
  } catch (err) {
    throw err
  }
}

export const resetAllDashboards = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation resetAllDashboards {
        resetAllDashboards {
          userId
          dashboardId
          zonePath
          context
          isDefault
          config
          version
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data } = await makeGraphqlRequest(GRAPHQL_URL, operation, idToken)

    if (!data?.resetAllDashboards) {
      throw Error('Unable to reset all dashboards')
    }

    return { dashboards: data?.resetAllDashboards, error: null }
  } catch (err) {
    throw err
  }
}

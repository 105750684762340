import {
  CLEAR_FERTILIZER_INVENTORIES,
  REQUEST_FERTILIZER_INVENTORIES,
  RECEIVE_FERTILIZER_INVENTORIES,
  REQUEST_FERTILIZER_INVENTORY,
  RECEIVE_FERTILIZER_INVENTORY
} from '@/actions/operations/fertilizerInventory'

export const fertilizerInventoryInitialState = {
  fertilizerInventories: [],
  fertilizerInventoriesCount: 0,
  fertilizerInventory: {},
  loading: false,
  error: null
}

const handlers = {
  [CLEAR_FERTILIZER_INVENTORIES]: (state, action) => ({
    ...state,
    fertilizerInventories: [],
    fertilizerInventoriesCount: 0,
    error: null
  }),
  [REQUEST_FERTILIZER_INVENTORIES]: (state, action) => ({
    ...state,
    fertilizerInventories: [],
    fertilizerInventoriesCount: 0,
    error: null,
    loading: true
  }),
  [RECEIVE_FERTILIZER_INVENTORIES]: (state, action) => ({
    ...state,
    fertilizerInventories: !action.error ? action.fertilizerInventories : [],
    fertilizerInventoriesCount: action?.count ?? 0,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_FERTILIZER_INVENTORY]: (state, action) => ({
    ...state,
    fertilizerInventory: {},
    error: null,
    loading: true
  }),
  [RECEIVE_FERTILIZER_INVENTORY]: (state, action) => ({
    ...state,
    fertilizerInventory: !action.error ? action.fertilizerInventory : {},
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

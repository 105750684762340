import { Fragment } from 'react'
import { I18n } from 'aws-amplify'

import { Flex, Text } from '@/primitives'

import Strings from '@/components/Operations/Pipelines/Crop/Strings'
import ZoneUtils from '@/Util/ZoneUtils'

const CropWidget = ({ crop, backToList, allowBack, zonesHierarchy }) => {
  const strings = Strings()

  const getZonePath = (siteId, zoneId) => {
    const zone = ZoneUtils.getZone(zonesHierarchy, siteId, zoneId)
    if (zone) {
      if (zone?.parentPath) {
        return zone.parentPath
          .substring(1)
          .split('/')
          .map(z => ZoneUtils.getZoneName(zonesHierarchy, siteId, z))
          .join('/')
      }
      return zone.name
    }
    return siteId
  }

  return (
    <Flex
      className='Crop__View'
      direction='column'
      alignMainAxis='left'
      alignCrossAxis='left'
      style={{ padding: '1em' }}
    >
      <div key={crop.id} style={{ marginBottom: '0.5em' }}>
        <Text
          fontFamily={'mono'}
          size={200}
          fontWeight={700}
          style={{ display: 'block' }}
        >
          {crop.produce.name} ({crop.variety.name})
          <Text
            fontFamily={'mono'}
            size={100}
            fontWeight={400}
            style={{ float: 'right' }}
          >
            {strings[crop.status]}
          </Text>
        </Text>
        <Text
          fontFamily={'mono'}
          size={100}
          fontWeight={400}
          style={{ display: 'block', marginBottom: '1em' }}
        >
          {getZonePath(crop.siteId, crop.zoneId)}
        </Text>
        {crop.produceThresholdId && (
          <Text
            fontFamily={'mono'}
            size={100}
            fontWeight={400}
            style={{ display: 'block' }}
          >
            {'Threshold'}: <strong>{crop.produceThreshold.name}</strong>
          </Text>
        )}
        <Text
          fontFamily={'mono'}
          size={100}
          fontWeight={400}
          style={{ display: 'block' }}
        >
          {'Roof Cover'}: <strong>{strings[crop.roofCover]}</strong>
        </Text>
        {crop.roofCover === 'secondsky' && (
          <Fragment>
            <Text
              fontFamily={'mono'}
              size={100}
              fontWeight={400}
              style={{ display: 'block' }}
            >
              {'SecondSky Variant'}:{' '}
              <strong>{strings[crop.secondskyVariant]}</strong>
            </Text>
            {crop.secondskyVariant === 'net' && (
              <Text
                fontFamily={'mono'}
                size={100}
                fontWeight={400}
                style={{ display: 'block' }}
              >
                {'Net Type'}: <strong>{strings[crop.netType]}</strong>
              </Text>
            )}
            <Text
              fontFamily={'mono'}
              size={100}
              fontWeight={400}
              style={{ display: 'block' }}
            >
              {'Blocking Ratio'}:{' '}
              <strong>
                {crop.blockingRatio
                  ? strings[crop.blockingRatio]
                  : I18n.get('Not applicable')}
              </strong>
            </Text>
            {crop.secondskyInformation?.length > 0 && (
              <Text
                fontFamily={'mono'}
                size={100}
                fontWeight={400}
                style={{ display: 'block' }}
              >
                {'SecondSky Info'}: <strong>{crop.secondskyInformation}</strong>
              </Text>
            )}
          </Fragment>
        )}
        {crop.plantingArea && (
          <Text
            fontFamily={'mono'}
            size={100}
            fontWeight={400}
            style={{ display: 'block' }}
          >
            {'Planting Area'}: <strong>{crop.plantingArea}m&sup2;</strong>
          </Text>
        )}
        {strings?.[crop.hydrationMethod] && (
          <Text
            fontFamily={'mono'}
            size={100}
            fontWeight={400}
            style={{ display: 'block' }}
          >
            {'Hydration Method'}:{' '}
            <strong>{strings[crop.hydrationMethod]}</strong>
          </Text>
        )}
        {crop.growthMedium?.length > 0 && (
          <Text
            fontFamily={'mono'}
            size={100}
            fontWeight={400}
            style={{ display: 'block' }}
          >
            {'Growth Medium'}:{' '}
            {crop.growthMedium.map((m, index) => (
              <strong key={m}>
                {strings[m]}
                {index < crop.growthMedium.length - 1 ? ',' : ''}
              </strong>
            ))}
          </Text>
        )}
        {crop.seedQuantity && (
          <Text
            fontFamily={'mono'}
            size={100}
            fontWeight={400}
            style={{ display: 'block' }}
          >
            {'Seed Quantity'}: <strong>{crop.seedQuantity}</strong>
          </Text>
        )}
      </div>
      {allowBack && (
        <Text
          fontFamily={'mono'}
          size={90}
          fontWeight={400}
          onClick={backToList}
          style={{
            display: 'block',
            position: 'absolute',
            bottom: '2.5em',
            right: '2.5em',
            textDecoration: 'underline',
            cursor: 'pointer'
          }}
        >
          &larr; Back
        </Text>
      )}
    </Flex>
  )
}

export default CropWidget

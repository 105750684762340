import { I18n } from 'aws-amplify'
import { widgetRegistry } from '@/components/DashboardPageV2/Desktop/Widgets'
import { TOOLBOX_TYPES, ToolboxItem } from './utils'

const fertigationItems = [
  widgetRegistry.get('waterTemperature'),
  widgetRegistry.get('conductivity'),
  widgetRegistry.get('totalDissolvedSolids'),
  widgetRegistry.get('ph'),
  widgetRegistry.get('dissolvedOxygen'),
  widgetRegistry.get('dissolvedOxygenPercentage'),
  widgetRegistry.get('salinity'),
  widgetRegistry.get('liquidflow'),
  widgetRegistry.get('soilMoisture'),
  widgetRegistry.get('soilTemperature'),
  widgetRegistry.get('soilPh'),
  widgetRegistry.get('soilEc'),
  widgetRegistry.get('soilPhosphorus'),
  widgetRegistry.get('soilPotassium'),
  widgetRegistry.get('soilNitrogen')
]

export const fertigationSection = () =>
  new ToolboxItem({
    id: 'fertigationSection',
    type: TOOLBOX_TYPES.section,
    title: I18n.get('Fertigation'),
    icon: 'water',
    items: fertigationItems
  })

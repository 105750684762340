import { createContext, useContext } from 'react'

export const MobileDashboardContext = createContext({})

/**
 * Access the mobile dashbord context and return the state and dispatch
 * Needs to be used within provider
 * @returns {{ state: object, dispatchState: function }}
 */
export function useMobileDashboard() {
  const context = useContext(MobileDashboardContext)

  if (context === undefined) {
    throw new Error(
      'useMobileDashboard must be used within a MobileDashboardProvider'
    )
  }
  return context
}

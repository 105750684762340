import React, { Fragment, useEffect, useRef, useState } from 'react'

import { Flex, Select, Button, Label } from '../../../../../primitives'

import { getCurrentOS } from '../../../../../Util/GeneralUtils'
import { resizeImage } from '../../../../../Util/ImageUtils'
import { PHOTO_SUBJECT, PHOTO_STATUS } from '../config'

import Strings from '../Strings'

export const getPhotoSrc = photo => {
  return photo.dataURI || photo.url || ''
}

const PhotoCapture = ({ photo, savePhoto, cancelPhoto }) => {
  const os = getCurrentOS()
  const strings = Strings()
  const videoRef = useRef()

  const photoSubjects = PHOTO_SUBJECT()
  const photoStatuses = PHOTO_STATUS()

  const [loading, setLoading] = useState(true)
  const [useUpload, setUseUpload] = useState(false)
  const [stream, setStream] = useState(null)
  const [permissionsError, setPermissionsError] = useState(null)
  const [state, setState] = useState({})
  const [photoSrc, setPhotoSrc] = useState(null)

  useEffect(() => {
    if (os.includes('iphone') || os.includes('ipod') || os.includes('ipad')) {
      setUseUpload(true)
    }
    setLoading(false)
  }, [os])

  useEffect(() => {
    if (!loading) {
      if (!useUpload) {
        startCamera()
      }
    }
    return () => {
      stopCamera()
    }
  }, [loading])

  useEffect(() => {
    setState({
      ...photo
    })
  }, [photo])

  useEffect(() => {
    setPhotoSrc(getPhotoSrc(state))
  }, [state])

  useEffect(() => {
    if (stream && videoRef?.current) {
      videoRef.current.srcObject = stream
    } else if (stream) {
      stopCamera()
    }
  }, [stream])

  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach(track => {
        track.stop()
      })
    }
  }

  const startCamera = async () => {
    try {
      const constraints = { video: { facingMode: 'environment' } }
      const stream = await navigator.mediaDevices.getUserMedia(constraints)
      setStream(stream)
    } catch (err) {
      setPermissionsError(strings.cameraPermissionError)
    }
  }

  const captureImage = async e => {
    try {
      let dataURI = null
      let imageFile = null

      if (useUpload) {
        imageFile = e.target.files[0]
      } else {
        const imageCapture = new ImageCapture(stream.getVideoTracks()[0])
        const blob = await imageCapture.takePhoto()
        imageFile = blob
      }

      stopCamera()

      await new Promise(resolve => {
        const reader = new FileReader()
        reader.addEventListener('load', () => {
          dataURI = reader.result
          resolve()
        })
        reader.readAsDataURL(imageFile)
      })

      dataURI = await resizeImage(dataURI)

      setState({
        ...state,
        dataURI: dataURI
      })
    } catch (err) {
      //console.log(err)
    }
  }

  const onCancel = () => {
    if (photoSrc) {
      setState({
        ...state,
        dataURI: '',
        url: ''
      })

      if (!useUpload) {
        startCamera()
      }
    } else {
      stopCamera()
      cancelPhoto()
    }
  }

  const handleInput = e => {
    const { name, value } = e.currentTarget
    setState({
      ...state,
      [name]: value
    })
  }

  const onSave = () => {
    savePhoto(state)
  }

  return (
    <Flex alignMainAxis={'flex-start'}>
      {permissionsError && (
        <Flex alignMainAxis={'flex-start'}>{permissionsError}</Flex>
      )}

      {!permissionsError && (
        <Fragment>
          {photoSrc && (
            <Flex
              direction={'column'}
              className={'Operations__Form__Photos__Form'}
            >
              <img src={photoSrc} alt={strings.photo} />
              <Flex
                direction={'column'}
                axisGap={100}
                className={'Operations__Form__Photos__Form__Fields'}
              >
                <Flex direction={'column'}>
                  <Label>
                    <Select
                      name='subject'
                      className={'Harvest__Select'}
                      value={state.subject}
                      onChange={handleInput}
                    >
                      <option default value=''>
                        {strings.selectSubject}
                      </option>
                      {Object.keys(photoSubjects).map(value => (
                        <option key={value} value={value}>
                          {photoSubjects[value]}
                        </option>
                      ))}
                    </Select>
                  </Label>
                </Flex>
                <Flex direction={'column'}>
                  <Label>
                    <Select
                      name='status'
                      className={'Harvest__Select'}
                      value={state.status}
                      onChange={handleInput}
                    >
                      <option default value=''>
                        {strings.selectStatus}
                      </option>
                      {Object.keys(photoStatuses).map(value => (
                        <option key={value} value={value}>
                          {photoStatuses[value]}
                        </option>
                      ))}
                    </Select>
                  </Label>
                </Flex>
              </Flex>
              <Flex
                alignMainAxis={'flex-end'}
                axisGap={300}
                className={'Operations__Form__Photos__Capture__Actions'}
              >
                <Button onClick={onSave} variant={'primary'} size={'small'}>
                  {strings.save}
                </Button>
                <Button onClick={onCancel} variant={'warning'} size={'small'}>
                  {strings.retakePhoto}
                </Button>
              </Flex>
            </Flex>
          )}

          {useUpload && (
            <Flex
              direction={'column'}
              axisGap={300}
              className={'Operations__Form__Photos__Capture'}
            >
              {!photoSrc && (
                <input
                  type='file'
                  accept='image/*'
                  capture='environment'
                  onChange={captureImage}
                />
              )}
              <Flex
                alignMainAxis={'flex-end'}
                axisGap={300}
                className={'Operations__Form__Photos__Capture__Actions'}
              >
                <Button onClick={onCancel} variant={'primary'} size={'small'}>
                  {strings.cancel}
                </Button>
              </Flex>
            </Flex>
          )}

          {!photoSrc && !useUpload && (
            <Flex className={'Operations__Form__Photos__Capture'}>
              <video autoPlay={true} ref={videoRef} />
              <Flex
                alignMainAxis={'flex-end'}
                axisGap={300}
                className={'Operations__Form__Photos__Capture__Actions'}
              >
                {!photoSrc && (
                  <Button
                    onClick={captureImage}
                    variant={'primary'}
                    size={'small'}
                  >
                    {strings.takePhoto}
                  </Button>
                )}
                <Button onClick={onCancel} variant={'warning'} size={'small'}>
                  {strings.cancel}
                </Button>
              </Flex>
            </Flex>
          )}
        </Fragment>
      )}
    </Flex>
  )
}

export default PhotoCapture

import { useState, useEffect, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { Switch, Route, useParams } from 'react-router-dom'

import { clearAreas, clearArea } from '@/actions/operations/area'

import { tableReducer, tableInitialState } from './state'

import Table from './Table'
import Form from './Form'

const MODULE_PATH =
  '/operations/:zone+/management/countries/regions/cities/areas'

function AreaManagement({
  organizationId,
  countryId,
  regionId,
  cityId,
  backPath
}) {
  const dispatch = useDispatch()

  const params = useParams()

  const [state, dispatchState] = useReducer(tableReducer, tableInitialState)

  const [modulePath, setModulePath] = useState(MODULE_PATH)

  useEffect(() => {
    return () => {
      dispatch(clearAreas())
      dispatch(clearArea())
    }
  }, [dispatch])

  useEffect(() => {
    if (params?.zone) {
      setModulePath(MODULE_PATH.replace(':zone+', params.zone))
    }
  }, [params?.zone])

  useEffect(() => {
    if (organizationId && countryId && regionId && cityId) {
      dispatchState({
        state: {
          organizations: [organizationId],
          countries: [countryId],
          regions: [regionId],
          cities: [cityId]
        }
      })
    }
  }, [organizationId, countryId, regionId, cityId])

  return (
    <Switch>
      <Route
        path={[`${MODULE_PATH}/create`, `${MODULE_PATH}/update/:itemId`]}
        exact={true}
      >
        <Form
          state={state}
          dispatchState={dispatchState}
          modulePath={modulePath}
        />
      </Route>
      <Route path={MODULE_PATH} exact={true}>
        <Table
          state={state}
          dispatchState={dispatchState}
          modulePath={modulePath}
          backPath={backPath}
        />
      </Route>
    </Switch>
  )
}

export default AreaManagement

import { call, put, select, takeLatest } from 'redux-saga/effects'

import {
  REQUEST_ALL_GRADES,
  REQUEST_GRADES,
  REQUEST_GRADE,
  CREATE_GRADE,
  UPDATE_GRADE,
  receiveGrades,
  receiveAllGrades,
  receiveGrade,
  gradeCreated,
  gradeUpdated
} from '@/actions/operations/grade'

import {
  getGrades,
  getGrade,
  createGrade,
  updateGrade
} from '@/api/operations/grade'

import { getCurrentUserOrganizations, displayBanner } from '../utils'
import { getOperationsAllGrades } from './utils'

import Strings from './Strings'

const strings = Strings()

function* sendCreateGrade(action) {
  const { data, error = null } = yield call(createGrade, action.params)
  yield put(displayBanner(error, strings.gradeCreated))
  yield put(gradeCreated(data, error))
}

export function* watchCreateGrade() {
  yield takeLatest(CREATE_GRADE, sendCreateGrade)
}

function* sendUpdateGrade(action) {
  const { data, error = null } = yield call(updateGrade, action.params)
  yield put(displayBanner(error, strings.gradeUpdated))
  yield put(gradeUpdated(data, error))
}

export function* watchUpdateGrade() {
  yield takeLatest(UPDATE_GRADE, sendUpdateGrade)
}

function* sendRequestGrades(action) {
  const { data, error = null } = yield call(getGrades, action.params)
  const { grades = [], count = 0 } = data
  yield put(receiveGrades(grades, count, error))
}

export function* watchRequestGrades() {
  yield takeLatest(REQUEST_GRADES, sendRequestGrades)
}

function* sendRequestAllGrades(action) {
  const allGrades = yield select(getOperationsAllGrades)
  if (allGrades.length > 0) {
    yield put(receiveAllGrades(allGrades, allGrades.length, null))
  } else {
    const organizations = yield select(getCurrentUserOrganizations)
    const params = {
      filter: {
        organizationId: organizations.map(o => o.id)
      }
    }
    const { data, error = null } = yield call(getGrades, params)
    const { grades = [], count = 0 } = data
    yield put(receiveAllGrades(grades, count, error))
  }
}

export function* watchRequestAllGrades() {
  yield takeLatest(REQUEST_ALL_GRADES, sendRequestAllGrades)
}

function* sendRequestGrade(action) {
  const { data: grade, error = null } = yield call(getGrade, action.params)
  yield put(receiveGrade(grade, error))
}

export function* watchRequestGrade() {
  yield takeLatest(REQUEST_GRADE, sendRequestGrade)
}

import { I18n } from 'aws-amplify'
import cloneDeep from 'lodash/cloneDeep'
import { DateTime } from 'luxon'

import { getIsGodMode } from '@/Util/PermissionUtils'

export const ACTOR_TYPES_ARRAY = [
  { label: I18n.get('User'), value: 'user', name: 'actorType' },
  { label: I18n.get('System'), value: 'system', name: 'actorType' }
]

export const ACTION_TYPES_ARRAY = [
  { label: I18n.get('Create'), value: 'create', name: 'action' },
  { label: I18n.get('Update'), value: 'update', name: 'action' },
  { label: I18n.get('Delete'), value: 'delete', name: 'action' }
]

export const getOrganizationsOptions = (organizations, currentUser) => {
  const isGodMode = getIsGodMode(currentUser)

  let filteredOrgs = cloneDeep(organizations)

  if (!isGodMode) {
    filteredOrgs = filteredOrgs.filter(organization => {
      return currentUser.organizationIds.includes(organization.id)
    })
  }

  return filteredOrgs.map(organization => {
    return {
      label: organization.name,
      value: organization.id,
      name: 'organizationId'
    }
  })
}

export function formatAuditTimestamp(timestamp) {
  return DateTime.fromMillis(timestamp).toFormat('MM/dd/yyyy - h:mm a')
}

/**
 * If items is less than or equal to itemsPerPage, return items.
 * Otherwise, return a slice of items starting at the startIndex and ending at the endIndex.
 *
 * @param {Number} pageNumber
 * @param {Number} itemsPerPage
 * @param {Array} items
 * @returns
 */
export function getItemsByPage(pageNumber, itemsPerPage, items) {
  if (items.length <= itemsPerPage) return items
  const startIndex = (pageNumber - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  return items.slice(startIndex, endIndex)
}

export function getAuditTrailsByAction(state, value) {
  let tempAuditLogs = state?.currentAuditTrailLogs

  if (state.actionAuditTrailLogs.length > 0) {
    tempAuditLogs = state?.actionAuditTrailLogs
  }

  let auditTrailLogs = tempAuditLogs

  if (value) {
    const filteredAuditLogs = tempAuditLogs.filter(log => log.action === value)

    auditTrailLogs = filteredAuditLogs
  }

  return {
    actionAuditTrailLogs: tempAuditLogs,
    currentAuditTrailLogs: auditTrailLogs
  }
}

import { I18n } from 'aws-amplify'

const Strings = () => ({
  manageSuppliers: I18n.get('Manage Suppliers'),
  createSupplier: I18n.get('Create Supplier'),
  loading: I18n.get('Loading'),
  supplier: I18n.get('Supplier'),
  createFormHeader: I18n.get('Create New Supplier'),
  updateFormHeader: I18n.get('Update Existing Supplier'),
  saveSupplier: I18n.get('Save Supplier'),
  selectDefault: I18n.get('Please Select'),
  company: I18n.get('Company'),
  location: I18n.get('Location'),
  noSuppliers: I18n.get('No suppliers'),
  name: I18n.get('Name'),
  code: I18n.get('Code'),
  category: I18n.get('Category'),
  status: I18n.get('Status'),
  allStatuses: I18n.get('Any Status'),
  supplyTypes: I18n.get('Supply Types'),
  onboardDate: I18n.get('Onboarding Date'),
  contact: I18n.get('Contact'),
  contactName: I18n.get('Contact Person'),
  contactNumber: I18n.get('Phone Number'),
  contactEmail: I18n.get('Email Address'),
  website: I18n.get('Website'),
  country: I18n.get('Country'),
  region: I18n.get('Region'),
  allRegions: I18n.get('All Regions'),
  city: I18n.get('City'),
  payment: I18n.get('Payment'),
  paymentMethod: I18n.get('Payment Method'),
  paymentTerm: I18n.get('Payment Terms'),
  currency: I18n.get('Currency'),
  // Contract
  contract:  I18n.get('Contract'),
  contractDocument: I18n.get('Contract Document'),
  // Registration
  registrationNumber: I18n.get('Registration Number'),
  // Tax Information
  taxNumber: I18n.get('Tax Number'),
  // Address
  address: I18n.get('Address'),
  streetAddress: I18n.get('Street Address'),
  streetAddress2: I18n.get('Street Address 2'),
  addressRegion: I18n.get('Region'),
  zipCode: I18n.get('Zip Code'),
  addAddress: I18n.get('Add Address'),
  deleteAddress: I18n.get('Delete Address'),
  // Bank Details
  bankDetails: I18n.get('Bank Details'),
  bankDetailsDocument: I18n.get('Bank Details Document'),
  bankName: I18n.get('Bank Name'),
  branchName: I18n.get('Branch Name'),
  branchCode: I18n.get('Branch Code'),
  branchAddress:I18n.get('Branch Address'),
  branchAddress2: I18n.get('Branch Address 2'),
  swiftBic: I18n.get('Swift/Bic'),
  iban: I18n.get('IBAN'),
  addBankDetails: I18n.get('Add Bank Details'),
  deleteBankDetails: I18n.get('Delete Bank Details'),
  // Enums
  active: I18n.get('Active'),
  inactive: I18n.get('Inactive'),
  canceled: I18n.get('Canceled'),
  banned: I18n.get('Banned'),
  retail: I18n.get('Retail'),
  wholesale: I18n.get('Wholesale'),
  service: I18n.get('Service'),
  packaging: I18n.get('Packaging'),
  branding: I18n.get('Branding'),
  seeds: I18n.get('Seeds'),
  fertilizer: I18n.get('Fertilizer'),
  substrate: I18n.get('Substrate'),
  technology: I18n.get('Technology'),
  construction: I18n.get('Construction'),
  telecommunications: I18n.get('Telecommunications'),
  energy: I18n.get('Energy')
})

export default Strings

import { makeActionCreator } from '@/Util/ReduxUtils'

export const REQUEST_ALL_SHIPPING_METHODS = 'REQUEST_ALL_SHIPPING_METHODS'
export const requestAllShippingMethods = makeActionCreator(
  REQUEST_ALL_SHIPPING_METHODS
)

export const RECEIVE_ALL_SHIPPING_METHODS = 'RECEIVE_ALL_SHIPPING_METHODS'
export const receiveAllShippingMethods = makeActionCreator(
  RECEIVE_ALL_SHIPPING_METHODS,
  'shippingMethods',
  'count',
  'error'
)

export const REQUEST_SHIPPING_METHODS = 'REQUEST_SHIPPING_METHODS'
export const requestShippingMethods = makeActionCreator(
  REQUEST_SHIPPING_METHODS,
  'params'
)

export const RECEIVE_SHIPPING_METHODS = 'RECEIVE_SHIPPING_METHODS'
export const receiveShippingMethods = makeActionCreator(
  RECEIVE_SHIPPING_METHODS,
  'shippingMethods',
  'count',
  'error'
)

export const CREATE_SHIPPING_METHOD = 'CREATE_SHIPPING_METHOD'
export const createShippingMethod = makeActionCreator(
  CREATE_SHIPPING_METHOD,
  'params'
)

export const SHIPPING_METHOD_CREATED = 'SHIPPING_METHOD_CREATED'
export const shippingMethodCreated = makeActionCreator(
  SHIPPING_METHOD_CREATED,
  'shippingMethod',
  'error'
)

export const UPDATE_SHIPPING_METHOD = 'UPDATE_SHIPPING_METHOD'
export const updateShippingMethod = makeActionCreator(
  UPDATE_SHIPPING_METHOD,
  'params'
)

export const SHIPPING_METHOD_UPDATED = 'SHIPPING_METHOD_UPDATED'
export const shippingMethodUpdated = makeActionCreator(
  SHIPPING_METHOD_UPDATED,
  'shippingMethod',
  'error'
)

export const REQUEST_SHIPPING_METHOD = 'REQUEST_SHIPPING_METHOD'
export const requestShippingMethod = makeActionCreator(
  REQUEST_SHIPPING_METHOD,
  'params'
)

export const RECEIVE_SHIPPING_METHOD = 'RECEIVE_SHIPPING_METHOD'
export const receiveShippingMethod = makeActionCreator(
  RECEIVE_SHIPPING_METHOD,
  'shippingMethod',
  'error'
)

export const CLEAR_SHIPPING_METHOD = 'CLEAR_SHIPPING_METHOD'
export const clearShippingMethod = makeActionCreator(CLEAR_SHIPPING_METHOD)

export const CLEAR_SHIPPING_METHODS = 'CLEAR_SHIPPING_METHODS'
export const clearShippingMethods = makeActionCreator(CLEAR_SHIPPING_METHODS)

export const SET_SHIPPING_METHOD = 'SET_SHIPPING_METHOD'
export const setShippingMethod = makeActionCreator(
  SET_SHIPPING_METHOD,
  'shippingMethod'
)

import { updateList } from '../util'

import {
  RECEIVE_FERTILIZER_INVENTORY_TRANSFERS,
  REQUEST_FERTILIZER_INVENTORY_TRANSFERS,
  CLEAR_FERTILIZER_INVENTORY_TRANSFERS,
  CLEAR_FERTILIZER_INVENTORY_TRANSFER,
  REQUEST_FERTILIZER_INVENTORY_TRANSFER,
  RECEIVE_FERTILIZER_INVENTORY_TRANSFER,
  CREATE_FERTILIZER_INVENTORY_TRANSFER,
  FERTILIZER_INVENTORY_TRANSFER_CREATED,
  UPDATE_FERTILIZER_INVENTORY_TRANSFER,
  FERTILIZER_INVENTORY_TRANSFER_UPDATED,
  SET_FERTILIZER_INVENTORY_TRANSFER
} from '@/actions/operations/fertilizerInventoryTransfer'

export const fertilizerInventoryTransferInitialState = {
  fertilizerInventoryTransfers: [],
  fertilizerInventoryTransfer: {},
  fertilizerInventoryTransfersCount: 0,
  loading: false,
  error: null
}

const handlers = {
  [CLEAR_FERTILIZER_INVENTORY_TRANSFER]: (state, action) => ({
    ...state,
    fertilizerInventoryTransfer: {},
    error: null
  }),
  [CLEAR_FERTILIZER_INVENTORY_TRANSFERS]: (state, action) => ({
    ...state,
    fertilizerInventoryTransfers: [],
    fertilizerInventoryTransfersCount: 0,
    error: null
  }),
  [REQUEST_FERTILIZER_INVENTORY_TRANSFER]: (state, action) => ({
    ...state,
    fertilizerInventoryTransfer: {},
    error: null,
    loading: true
  }),
  [RECEIVE_FERTILIZER_INVENTORY_TRANSFER]: (state, action) => ({
    ...state,
    fertilizerInventoryTransfer: !action.error
      ? action.fertilizerInventoryTransfer
      : state.fertilizerInventoryTransfer,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_FERTILIZER_INVENTORY_TRANSFERS]: (state, action) => ({
    ...state,
    fertilizerInventoryTransfers: [],
    fertilizerInventoryTransfersCount: 0,
    error: null,
    loading: true
  }),
  [RECEIVE_FERTILIZER_INVENTORY_TRANSFERS]: (state, action) => ({
    ...state,
    fertilizerInventoryTransfers: !action.error
      ? action.fertilizerInventoryTransfers
      : [],
    fertilizerInventoryTransfersCount: !action.error ? action.count : 0,
    error: action.error ?? null,
    loading: false
  }),
  [SET_FERTILIZER_INVENTORY_TRANSFER]: (state, action) => ({
    ...state,
    fertilizerInventoryTransfer: action.fertilizerInventoryTransfer
  }),
  [CREATE_FERTILIZER_INVENTORY_TRANSFER]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [FERTILIZER_INVENTORY_TRANSFER_CREATED]: (state, action) => ({
    ...state,
    fertilizerInventoryTransfer: !action.error
      ? {}
      : state.fertilizerInventoryTransfer,
    fertilizerInventoryTransfers: updateList(
      action.error,
      state.fertilizerInventoryTransfers,
      action.fertilizerInventoryTransfer,
      true
    ),
    fertilizerInventoryTransfersCount: !action.error
      ? state.fertilizerInventoryTransfersCount + 1
      : state.fertilizerInventoryTransfersCount,
    error: action.error ?? null,
    loading: false
  }),
  [UPDATE_FERTILIZER_INVENTORY_TRANSFER]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [FERTILIZER_INVENTORY_TRANSFER_UPDATED]: (state, action) => ({
    ...state,
    fertilizerInventoryTransfer: !action.error
      ? {}
      : state.fertilizerInventoryTransfer,
    fertilizerInventoryTransfers: updateList(
      action.error,
      state.fertilizerInventoryTransfers,
      action.fertilizerInventoryTransfer,
      false
    ),
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

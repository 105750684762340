import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  getLinkdUserIdWhatsApp,
  linkUserIdToWhatsApp,
  unlinkUserIdFromWhatsApp
} from '@/api/management/whatsApp'

import { showBanner } from '@/slices/util'

import Strings from '@/components/Profile/Strings'

const strings = Strings()

export const fetchLinkdUserIdWhatsApp = createAsyncThunk(
  'fetchLinkdUserIdWhatsApp',
  async params => {
    return await getLinkdUserIdWhatsApp(params)
  }
)

export const sendLinkUserIdToWhatsApp = createAsyncThunk(
  'sendLinkUserIdToWhatsApp',
  async (params, { dispatch }) => {
    const { profileWhatsAppSuccessFeedback, profileWhatsAppErrorFeedback } =
      strings

    try {
      const link = await linkUserIdToWhatsApp(params)
      const bannerDetails = {
        show: true,
        message: profileWhatsAppSuccessFeedback,
        type: 'success'
      }
      dispatch(showBanner(bannerDetails))
      return link
    } catch (err) {
      const bannerDetails = {
        show: true,
        message: profileWhatsAppErrorFeedback,
        type: 'error'
      }
      dispatch(showBanner(bannerDetails))
      throw err
    }
  }
)

export const removeLinkUserIdFromWhatsApp = createAsyncThunk(
  'removeLinkUserIdFromWhatsApp',
  async (params, { dispatch }) => {
    const {
      profileWhatsAppDeleteSuccessFeedback,
      profileWhatsAppDeleteErrorFeedback
    } = strings

    try {
      const link = await unlinkUserIdFromWhatsApp(params)
      const bannerDetails = {
        show: true,
        message: profileWhatsAppDeleteSuccessFeedback,
        type: 'success'
      }
      dispatch(showBanner(bannerDetails))
      return link
    } catch (err) {
      const bannerDetails = {
        show: true,
        message: profileWhatsAppDeleteErrorFeedback,
        type: 'error'
      }
      dispatch(showBanner(bannerDetails))
      throw err
    }
  }
)

const whatsAppSlice = createSlice({
  name: 'whatsAppReducer',
  initialState: {
    link: null,
    error: null,
    isSaving: false,
    isAdding: false,
    isRemoving: false
  },
  reducers: {
    updateLinkFromWebsocket: (state, action) => {
      state.link = action.payload
    },
    resetState: state => {
      state.link = null
      state.error = null
      state.isSaving = false
      state.isAdding = false
      state.isRemoving = false
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchLinkdUserIdWhatsApp.pending, (state, action) => {
        state.isSaving = true
        state.isAdding = false
        state.error = null
        state.link = null
      })
      .addCase(fetchLinkdUserIdWhatsApp.rejected, (state, action) => {
        state.isSaving = false
        state.error = action.error.message
        state.link = null
      })
      .addCase(fetchLinkdUserIdWhatsApp.fulfilled, (state, action) => {
        state.link = action.payload
        state.isSaving = false
        state.error = null
      })
      .addCase(sendLinkUserIdToWhatsApp.pending, (state, action) => {
        state.isAdding = false
        state.isSaving = true
        state.error = null
        state.link = null
      })
      .addCase(sendLinkUserIdToWhatsApp.rejected, (state, action) => {
        state.isSaving = false
        state.error = action.error.message
        state.link = null
      })
      .addCase(sendLinkUserIdToWhatsApp.fulfilled, (state, action) => {
        state.link = action.payload
        state.isSaving = false
        state.isAdding = true
        state.error = null
      })
      .addCase(removeLinkUserIdFromWhatsApp.pending, (state, action) => {
        state.isSaving = true
        state.isRemoving = false
        state.error = null
        state.link = null
      })
      .addCase(removeLinkUserIdFromWhatsApp.rejected, (state, action) => {
        state.isSaving = false
        state.error = action.error.message
        state.link = null
      })
      .addCase(removeLinkUserIdFromWhatsApp.fulfilled, (state, action) => {
        state.link = null
        state.isSaving = false
        state.isRemoving = true
        state.error = null
      })
  }
})

export const { updateLinkFromWebsocket, resetState } = whatsAppSlice.actions

export default whatsAppSlice.reducer

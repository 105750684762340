import { getWidgetColorByStatus, displayType } from '../utils'
import Icon from './Icon'

const Widget = ({ device, setCurrentDevice }) => {
  const setDevice = () => {
    setCurrentDevice(device)
  }

  return (
    <div
      key={device.id}
      className={`item ${device.status}`}
      onClick={setDevice}
    >
      <div className='device'>
        <div className='name'>
          <Icon
            deviceType={device.type}
            className='icon'
            fill={getWidgetColorByStatus(device.status)}
          />
          {device.name}
        </div>
        {device?.tag && <div className='tag'>{device.tag}</div>}
        <div className='type'>{displayType(device.type)}</div>
      </div>
    </div>
  )
}

export default Widget

import { I18n } from 'aws-amplify'
import { number, object, string } from 'yup'

export const FIELDS = {
  id: 'id',
  saleItemId: 'saleItemId',
  quantity: 'quantity'
}

export const SCHEMA = () => {
  return object().shape({
    saleItemId: string().required(I18n.get('Please select a sale item.')),
    quantity: number()
      .typeError('An quantity is required.')
      .min(1, I18n.get('The quantity cannot be less than one.'))
      .required(I18n.get('An quantity is required.'))
  })
}

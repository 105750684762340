import { DateTime } from 'luxon'
import classNames from 'classnames'
import { useCallback, useEffect, useState } from 'react'
import { I18n } from 'aws-amplify'

import { Box, Button, FlexV2, Text } from '@/primitives'
import { schemeDark2 } from '@/Util/SchemeUtils'

import { getNavigationLocationSelector, getZonesHierarchy } from '@/reducers/selectors'

import { useDataboard } from '../../context'
import { DATABOARD_ACTIONS, DEFAULT_TIMEZONE } from '../../state'
import Strings from '../../Strings'
import { COMPARISON_TYPES, DATE_RANGES, INTERVALS } from '../../Utils/config'
import { getDateRangeOptionBounds } from '../../Utils/DateUtils'
import {
  getDateRangeFilteredIntervals,
  getSidebarZoneFilterFromLocationSelector
} from '../../Utils/FiltersUtils'

import DatePicker from './DatePicker'
import FilterSelect from '../Shared/FilterSelect'
import RemoveButton from '../Shared/RemoveButton'

const millisToTimeZoneMillis = (date, timeZone) => {
  const timeZoned = DateTime.fromMillis(date).setZone(timeZone)
  const offset = timeZoned.offset
  const adjustedTime = timeZoned.plus({ minutes: offset })
  return adjustedTime.toMillis()
}

export default function DateFilters({
  isBelowBreakpoint,
  index,
  isDuplicateFilter,
  isSidebar
}) {
  const strings = Strings()
  const zonesHierarchy = getZonesHierarchy()

  const { state, dispatchState } = useDataboard()
  
  const locationSelector = getNavigationLocationSelector()
  
  const dateFilter = isSidebar
    ? state.sidebarFilter[COMPARISON_TYPES.DATE_RANGES]
    : state.dateFilters[index]
  const maxDate = DateTime.now().setZone(dateFilter.timeZone)

  const [sidebarIntervalFilters, setSidebarIntervalFilters] =
    useState(INTERVALS)
  const intervalFilters = isSidebar
    ? sidebarIntervalFilters
    : state.timeIntervalFilters

  const [isExpanded, setIsExpanded] = useState(true)

  useEffect(() => {
    if (dateFilter?.dateRange !== 'customRange' && dateFilter?.timeZone) {
      const bounds = getDateRangeOptionBounds(
        dateFilter.dateRange,
        dateFilter.timeZone ?? DEFAULT_TIMEZONE
      )
      const newFromDate = bounds[0].toMillis()
      const newToDate = bounds[1].toMillis()

      if (isSidebar) {
        dispatchState({
          type: DATABOARD_ACTIONS.UPDATE_SIDEBAR_FILTER_VALUES,
          params: {
            fromDate: newFromDate,
            toDate: newToDate
          }
        })
      } else {
        dispatchState({
          type: DATABOARD_ACTIONS.UPDATE_DATE_FILTERS,
          params: {
            data: {
              fromDate: newFromDate,
              toDate: newToDate
            },
            index
          }
        })
      }
    }
  }, [
    isSidebar,
    dateFilter.dateRange,
    dateFilter.timeZone,
    dispatchState,
    index
  ])

  const updateSidebarFilter = useCallback(
    params => {
      dispatchState({
        type: DATABOARD_ACTIONS.UPDATE_SIDEBAR_FILTER_VALUES,
        params
      })
    },
    [dispatchState]
  )

  useEffect(() => {
    if (isSidebar) {
      const filteredIntervals = getDateRangeFilteredIntervals([dateFilter])

      setSidebarIntervalFilters(filteredIntervals)
      if (!filteredIntervals.includes(dateFilter.timeInterval)) {
        updateSidebarFilter({
          timeInterval: filteredIntervals[0]
        })
      }
    }
  }, [dateFilter, isSidebar, updateSidebarFilter])

  const onExpand = e => {
    e.preventDefault()
    setIsExpanded(s => !s)
  }

  const onRemoveDateRange = e => {
    e.preventDefault()
    if (index === 0 && state.dateFilters.length === 1) {
      const zoneFilter =
        getSidebarZoneFilterFromLocationSelector(locationSelector)
      const timeZone = zonesHierarchy[zoneFilter.siteId].timeZone
      dispatchState({
        type: DATABOARD_ACTIONS.RESET_FILTERS,
        zoneFilter: getSidebarZoneFilterFromLocationSelector(locationSelector),
        timeZone
      })
    } else {
      dispatchState({
        type: DATABOARD_ACTIONS.REMOVE_DATE_FILTER,
        params: { index }
      })
    }
  }

  const onChangeDateFilter = (selectedItem, meta) => {
    if (isSidebar) {
      updateSidebarFilter({
        [meta.name]: selectedItem?.value
      })
      return null
    }
    if (meta.name === 'timeInterval') {
      dispatchState({
        type: DATABOARD_ACTIONS.UPDATE_DATE_TIME_INTERVAL,
        params: {
          timeInterval: selectedItem?.value
        }
      })
    } else {
      dispatchState({
        type: DATABOARD_ACTIONS.UPDATE_DATE_FILTERS,
        params: {
          data: {
            [meta.name]: selectedItem?.value
          },
          index
        }
      })
    }
  }

  const onChangeFromDate = date => {
    setDate(date, 'fromDate')

    const diff = DateTime.fromJSDate(date).diff(
      DateTime.fromMillis(dateFilter.toDate),
      ['milliseconds', 'minutes']
    )
    // if the new fromDate is after or equal the toDate, update the toDate to be after the fromDate
    // we use the previous difference between the two dates to calculate the new toDate

    if (diff.values.minutes >= 0) {
      // in some cases, the diff is 0, so we set a default of 15 minutes
      // this is to avoid the toDate being the same as the fromDate
      const plus = diff.values.milliseconds > 0 ? diff.values : { minutes: 15 }

      let newToDate = DateTime.fromJSDate(date).plus(plus).toJSDate()
      const maxDateStartDateDiff = maxDate.diff(
        DateTime.fromJSDate(date),
        'minutes'
      )

      // if the new toDate is within 30 minutes of the maxDate, round it to the nearest 15 minutes
      if (maxDateStartDateDiff.values.minutes <= 30) {
        const remainder = maxDate.minute % 15
        newToDate = maxDate.minus({ minutes: remainder }).toJSDate()
      }

      setDate(newToDate, 'toDate')
    }
  }

  const onChangeToDate = date => {
    setDate(date, 'toDate')
  }

  const setDate = (date, name) => {
    let normalizedDate = DateTime.fromJSDate(date).startOf('minute').toMillis()
    const timeZoned = DateTime.fromMillis(normalizedDate).setZone(
      dateFilter.timeZone
    )
    const offset = timeZoned.offset
    const adjustedTime = timeZoned.minus({ minutes: offset }).toMillis()

    if (isSidebar) {
      updateSidebarFilter({
        [name]: adjustedTime,
        dateRange: 'customRange'
      })
      return null
    }
    dispatchState({
      type: DATABOARD_ACTIONS.UPDATE_DATE_FILTERS,
      params: {
        data: {
          [name]: adjustedTime,
          dateRange: 'customRange'
        },
        index
      }
    })
  }

  const showSelectLabel = isBelowBreakpoint || isSidebar

  return (
    <Box
      className={classNames(
        'DataboardFilters__ComparisonRow',
        isDuplicateFilter && 'DataboardFilters__ComparisonRow--Error',
        isSidebar && 'DataboardFilters__ComparisonRow--Sidebar'
      )}
    >
      {!isSidebar && (
        <Box className='DataboardFilters__ComparisonRow__LegendBox'>
          {isBelowBreakpoint && state.dateFilters.length > 1 && (
            <RemoveButton onClick={onRemoveDateRange} />
          )}
          <FlexV2 axisGap={400} alignCrossAxis='center'>
            <Box
              className='DataboardFilters__ComparisonRow__LegendBox__Color'
              style={{ backgroundColor: schemeDark2[index] }}
            />
            {isBelowBreakpoint && (
              <Text as='p' size={150} variant='page' tone={600}>
                {I18n.get('Date Range')} #{index + 1}
              </Text>
            )}
          </FlexV2>

          {isBelowBreakpoint && (
            <Button
              variant='text'
              iconBefore={
                isBelowBreakpoint && isExpanded ? 'expand_less' : 'expand_more'
              }
              className='DataboardFilters__ComparisonRow__LegendBox__ExpandButton'
              onClick={onExpand}
            />
          )}
        </Box>
      )}
      <Box
        className={
          isSidebar
            ? 'DataboardFilters__ComparisonRow__Content--Sidebar'
            : 'DataboardFilters__ComparisonRow__Content--Date'
        }
        style={!isBelowBreakpoint || isExpanded ? {} : { display: 'none' }}
      >
        <FilterSelect
          name='dateRange'
          value={dateFilter.dateRange}
          onChange={onChangeDateFilter}
          placeholder={strings.customRange}
          options={DATE_RANGES.map(range => ({
            value: range,
            label: strings[range]
          }))}
          label={showSelectLabel && strings.dateRangeLabel}
          isSidebar={isSidebar}
          isClearable={false}
          isSearchable={false}
        />
        <DatePicker
          type='from'
          startDate={millisToTimeZoneMillis(
            dateFilter.fromDate,
            dateFilter.timeZone
          )}
          endDate={millisToTimeZoneMillis(
            dateFilter.toDate,
            dateFilter.timeZone
          )}
          onChange={onChangeFromDate}
          selected={millisToTimeZoneMillis(
            dateFilter.fromDate,
            dateFilter.timeZone
          )}
          label={showSelectLabel && strings.startDateLabel}
          isSidebar={isSidebar}
          maxDate={maxDate}
          timeZone={dateFilter.timeZone}
        />
        <DatePicker
          type='to'
          startDate={millisToTimeZoneMillis(
            dateFilter.fromDate,
            dateFilter.timeZone
          )}
          endDate={millisToTimeZoneMillis(
            dateFilter.toDate,
            dateFilter.timeZone
          )}
          onChange={onChangeToDate}
          selected={millisToTimeZoneMillis(
            dateFilter.toDate,
            dateFilter.timeZone
          )}
          maxDate={maxDate}
          label={showSelectLabel && strings.endDateLabel}
          isSidebar={isSidebar}
          timeZone={dateFilter.timeZone}
        />
        <FilterSelect
          name='timeInterval'
          value={dateFilter.timeInterval}
          onChange={onChangeDateFilter}
          className='Filter__Select'
          label={showSelectLabel && strings.graphIntervalLabel}
          isSidebar={isSidebar}
          options={intervalFilters.map(interval => ({
            value: interval,
            label: strings[interval]
          }))}
          isClearable={false}
          isSearchable={false}
        />
      </Box>
      {!isSidebar && !isBelowBreakpoint && state.dateFilters.length > 1 && (
        <RemoveButton onClick={onRemoveDateRange} />
      )}
    </Box>
  )
}

import { I18n } from 'aws-amplify'

const Strings = () => ({
  fieldsetConfiguration: I18n.get('Conditions'),
  buttonAddCondition: I18n.get('Add Condition'),
  fieldConditionsNoEntries: I18n.get('No conditions have been set'),
  fieldCondition: I18n.get('Condition'),
  fieldMeasurement: I18n.get('Measurement'),
  fieldExpression: I18n.get('Expression'),
  fieldBound: I18n.get('Bound'),
  fieldUnit: I18n.get('Unit'),
  fieldsetLower: I18n.get('Lower Bounds'),
  fieldsetUpper: I18n.get('Upper Bounds'),
  fieldStress: I18n.get('Stress'),
  fieldBad: I18n.get('Bad'),
  fieldGood: I18n.get('Good'),
  pleaseSelect: I18n.get('Please select'),
  produceThresholdMessageCreated: I18n.get('Produce threshold message created successfully.'),
  produceThresholdMessageUpdated: I18n.get('Produce threshold message updated successfully.'),
  produceThresholdMessageDeleted: I18n.get('Produce threshold message deleted successfully.')
})

export default Strings

import { DateTime } from 'luxon'

import { conversionLookup, nameLookup, unitLookup } from '@/Util/UnitUtils'

import {
  CONVERSION_TEMPERATURE_MEASUREMENTS,
  FAHRENHEIT_UNIT,
  convertCelciusToFahrenheit
} from '@/Util/MeasurementUtils'

import {
  DECIMAL_ACCURACY,
  STATUS_SYMBOL_COLOR,
  applyConversion
} from '../../utils'

import { FlexV2, Icon, Text } from '@/primitives'

import Strings from '../../Strings'

const Alert = ({ alert, measurements, useFahrenheit }) => {
  const strings = Strings()

  const getMeasurementName = () => {
    return nameLookup(alert.measurementId, measurements)
  }

  const getUnit = () => {
    if (alert.measurementId) {
      if (useFahrenheit) {
        if (
          CONVERSION_TEMPERATURE_MEASUREMENTS.indexOf(alert.measurementId) >= 0
        ) {
          return FAHRENHEIT_UNIT
        }
      }
      return unitLookup(alert.measurementId, measurements)
    }
    return null
  }

  const convertToFarenheit = value => {
    if (useFahrenheit) {
      if (
        CONVERSION_TEMPERATURE_MEASUREMENTS.indexOf(alert.measurementId) >= 0
      ) {
        return convertCelciusToFahrenheit(value)
      }
    }
    return value
  }

  const getConvertedValue = () => {
    return applyConversion(
      alert.measurementId,
      convertToFarenheit(alert.thresholdValue),
      conversionLookup
    ).toFixed(DECIMAL_ACCURACY)
  }

  const getMessage = () => {
    return alert.boundType === 'upper' ? strings.isAbove : strings.isBelow
  }

  return (
    <FlexV2 className='alert' axisGap={300}>
      <Icon
        size={600}
        tone={500}
        variant={STATUS_SYMBOL_COLOR[alert.status]}
        style={{
          alignSelf: 'center'
        }}
        name='warning'
      />
      <FlexV2 direction='column'>
        <FlexV2 direction='column'>
          {alert.sendingData && (
            <Text variant={STATUS_SYMBOL_COLOR[alert.status]} tone={800}>
              {getMeasurementName()} {getMessage()}{' '}
              <Text>
                {getConvertedValue()}
                {getUnit()}
              </Text>
            </Text>
          )}
          {alert.status === 'missingData' && (
            <Text variant='graph' tone={800}>
              {strings.missingData}
            </Text>
          )}
          {alert.status === 'notInUse' && (
            <Text variant='neutral' tone={800}>
              {strings.notInUse}
            </Text>
          )}
          <Text variant='page' tone={800} style={{ marginTop: '0' }}>
            <Text style={{ fontSize: '0.9em' }}>
              {DateTime.fromMillis(alert.time).toFormat(
                "LLL dd yyyy 'at' hh:mm"
              )}
            </Text>
          </Text>
        </FlexV2>
      </FlexV2>
    </FlexV2>
  )
}

export default Alert

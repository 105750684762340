import { I18n } from 'aws-amplify'

const Strings = () => ({
  managePackageUnitPurchases: I18n.get('Manage Packaging Purchases'),
  createPackageUnitPurchase: I18n.get('Add Purchase'),
  createFormHeader: I18n.get('Create Purchase'),
  updateFormHeader: I18n.get('Update Existing Purchase'),
  savePackageUnitPurchase: I18n.get('Save Purchase'),
  noPackageUnitPurchases: I18n.get('No purchases'),
  purchase: I18n.get('Purchase'),
  storageFacility: I18n.get('Storage Facility'),
  packageUnit: I18n.get('Package Unit'),
  supplier: I18n.get('Supplier'),
  grams: I18n.get('g'),
  quantity: I18n.get('Quantity'),
  currency: I18n.get('Currency'),
  unitPrice: I18n.get('Unit Price'),
  price: I18n.get('Price'),
  order: I18n.get('Order'),
  delivery: I18n.get('Delivery'),
  status: I18n.get('Status'),
  tax: I18n.get('Tax'),
  taxLowerCase: I18n.get('tax'),
  orderDate: I18n.get('Date Ordered'),
  receivedDate: I18n.get('Date Received'),
  selectDefault: I18n.get('Please Select'),
  pending: I18n.get('Pending'),
  ordered: I18n.get('Ordered'),
  received: I18n.get('Received'),
  canceled: I18n.get('Canceled')
})

export default Strings

import { call, put, select, takeLatest } from 'redux-saga/effects'
import cloneDeep from 'lodash/cloneDeep'

import {
  FETCH_TIMEFRAME_GRAPH_AVERAGES,
  timeframeGraphAveragesFetched
} from '@/actions/ts/timeframe'

import { getDatasetAverage, getDatasetMobileAverage } from '@/api/ts/dataset'
import { isLoading } from '@/slices/util'
import { getRootZone } from '../utils'
import { makeUniqueLocalStorageKey, cacheResult } from './utils'

import {
  requiredVectorMeasurements,
  conversionVectorFunctions
} from '@/Util/MeasurementUtils'
import { COMPARISON_TYPES } from '@/components/DataboardPageV2/Utils/config'

/*
 * Applicable for absolute humidity, SVP, wet bulb temperature and VPD
 */

function temperatureHumidityConversionParams(vectorRows, index) {
  if (
    vectorRows[0][index] !== undefined &&
    vectorRows[1][index] !== undefined
  ) {
    return {
      temperature: parseFloat(vectorRows[0][index][2]),
      humidity: parseFloat(vectorRows[1][index][2])
    }
  }

  return null
}

function salinityConversionParams(vectorRows, index) {
  if (
    vectorRows[0][index] !== undefined &&
    vectorRows[1][index] !== undefined
  ) {
    return {
      water_temperature: parseFloat(vectorRows[0][index][2]),
      conductivity: parseFloat(vectorRows[1][index][2])
    }
  }

  return null
}

function gdhConversionParams(vectorRows, index) {
  if (vectorRows[0][index] !== undefined) {
    return {
      temperature: parseFloat(vectorRows[0][index][2])
    }
  }

  return null
}

function tdsConversionParams(vectorRows, index) {
  if (vectorRows[0][index] !== undefined) {
    return {
      conductivity: parseFloat(vectorRows[0][index][2])
    }
  }

  return null
}

function dliConversionParams(vectorRows, index, interval) {
  if (vectorRows[0][index] !== undefined && interval === '1h') {
    return {
      par: parseFloat(vectorRows[0][index][2]),
      useNew: true,
      interval
    }
  }

  return null
}

function dceConversionParams(vectorRows, index, interval) {
  if (vectorRows[0][index] !== undefined && interval === '1h') {
    return {
      energy_kw: parseFloat(vectorRows[0][index][2])
    }
  }

  return null
}

function* mobileDatasetAverages(action, sensorId, index) {
  const {
    measurements,
    zoneParams,
    interval,
    range,
    cacheOperation = false
  } = action.params

  let response = []

  const key = makeUniqueLocalStorageKey(
    zoneParams[index],
    measurements,
    interval,
    range,
    new Date(zoneParams[index].fromDate).getTime(),
    new Date(zoneParams[index].toDate).getTime(),
    sensorId
  )

  const cacheResponse = window.localStorage.getItem(key)
  const parseCacheResponse = JSON.parse(cacheResponse)

  let measurementData = null

  if (
    parseCacheResponse &&
    !parseCacheResponse?.data?.errror &&
    parseCacheResponse.timestamp > Date.now() &&
    cacheOperation
  ) {
    measurementData = parseCacheResponse.data
  } else {
    measurementData = yield call(getDatasetMobileAverage, {
      organizationId: zoneParams[index].organizationId,
      siteId: zoneParams[index].siteId,
      table: zoneParams[index].siteId,
      measurements,
      interval: interval,
      sensorId: sensorId,
      fromDate: zoneParams[index].fromDate,
      toDate: zoneParams[index].toDate,
      cacheOperation: false
    })

    if (cacheOperation && !measurementData?.error) {
      cacheResult(key, measurementData)
    }
  }

  const columns = measurementData?.datasetAverage?.columns
  const measurementIndex = columns.indexOf('measure_name')

  const rowsPerMeasurement = {
    ...measurementData
  }?.datasetAverage?.rows?.reduce((acc, row) => {
    const measurement = row[measurementIndex]
    if (!acc.hasOwnProperty(measurement)) {
      acc[measurement] = []
    }

    acc[measurement].push(row)
    return acc
  }, {})

  Object.keys(rowsPerMeasurement).forEach(measurement => {
    let dataMeasurement = cloneDeep(measurementData)
    const rows = cloneDeep(rowsPerMeasurement[measurement])
    dataMeasurement.datasetAverage.rows = rows
    dataMeasurement['measurement'] = measurement
    response.push(dataMeasurement)
  })

  return response
}

function* databoardsDatasetAverages(
  measurements,
  zoneParam,
  dateParam,
  sensorId,
  cacheOperation = false,
  type = COMPARISON_TYPES.LOCATION_RANGES
) {
  const { timeInterval, dateRange } = dateParam
  const { siteId, organizationId } = zoneParam

  let response = []

  const fromDate =
    type === COMPARISON_TYPES.LOCATION_RANGES
      ? zoneParam.fromDate
      : dateParam.fromDate
  const toDate =
    type === COMPARISON_TYPES.LOCATION_RANGES
      ? zoneParam.toDate
      : dateParam.toDate

  for (let j = 0; j < measurements.length; j++) {
    const key = makeUniqueLocalStorageKey(
      zoneParam,
      [measurements[j]],
      timeInterval,
      dateRange,
      new Date(fromDate).getTime(),
      new Date(toDate).getTime(),
      sensorId
    )

    const cacheResponse = window.localStorage.getItem(key)
    const parseCacheResponse = JSON.parse(cacheResponse)
    if (
      parseCacheResponse &&
      !parseCacheResponse?.data?.errror &&
      parseCacheResponse.timestamp > Date.now() &&
      cacheOperation
    ) {
      response.push(parseCacheResponse.data)
    } else {
      if (Object.keys(requiredVectorMeasurements).includes(measurements[j])) {
        // Vector measurements
        const measurement = measurements[j]
        const requiredMeasurements = requiredVectorMeasurements[measurement]

        let vectorData = {}
        let vectorRows = []

        for (let k = 0; k < requiredMeasurements.length; k++) {
          const measurementData = yield call(getDatasetAverage, {
            organizationId: organizationId,
            siteId: siteId,
            table: siteId,
            measurement: requiredMeasurements[k],
            interval: timeInterval,
            sensorId: sensorId,
            fromDate: fromDate,
            toDate: toDate,
            cacheOperation: false
          })

          vectorData['datasetAverage'] = {
            columns: measurementData?.datasetAverage?.columns,
            measurement,
            rows: [],
            sensorId: sensorId,
            types: measurementData?.datasetAverage?.types
          }

          if (measurementData?.datasetAverage?.rows) {
            vectorRows.push(measurementData?.datasetAverage?.rows)
          }
        }

        let largestVectorIndex = { index: -1, length: -1 }
        vectorRows.forEach((row, index) => {
          if (row?.length > largestVectorIndex.length) {
            largestVectorIndex = { index, length: row.length }
          }
        })

        const convertedRows = vectorRows[largestVectorIndex?.index]?.reduce(
          (acc, row, index) => {
            const conversionParams = {
              absolute_humidity: temperatureHumidityConversionParams,
              saturation_vapour_pressure: temperatureHumidityConversionParams,
              wet_bulb_temperature: temperatureHumidityConversionParams,
              vapour_pressure_deficit: temperatureHumidityConversionParams,
              salinity: salinityConversionParams,
              growing_degree_hours: gdhConversionParams,
              total_dissolved_solids: tdsConversionParams,
              daily_light_integral: dliConversionParams,
              daily_energy_consumption: dceConversionParams
            }

            if (conversionParams) {
              const params = conversionParams[measurement](
                vectorRows,
                index,
                timeInterval
              )

              if (params) {
                const newValue = conversionVectorFunctions[measurement](params)
                let newRow = [...row]
                newRow[1] = measurements[j]
                newRow[2] = newValue
                acc.push(newRow)
              }
            }

            return acc
          },
          []
        )

        vectorData['datasetAverage']['rows'] = convertedRows

        response.push(vectorData)
        if (cacheOperation && !vectorData?.error) {
          cacheResult(key, vectorData)
        }
      } else {
        // Non-vector measurements
        const measurementData = yield call(getDatasetAverage, {
          organizationId: organizationId,
          siteId: siteId,
          table: siteId,
          measurement: measurements[j],
          interval: timeInterval,
          sensorId: sensorId,
          fromDate: fromDate,
          toDate: toDate,
          cacheOperation: false
        })

        response.push(measurementData)
        if (cacheOperation && !measurementData?.error) {
          cacheResult(key, measurementData)
        }
      }
    }
  }

  return response
}

export function* sendFetchTimeframeGraphAverages(action) {
  const rootZone = yield select(getRootZone)
  const {
    zoneParams,
    isMobile = false,
    type = 'locationRanges',
    dateParams,
    measurements,
    cacheOperation = false,
    view
  } = action.params

  let response = {}

  if (type === COMPARISON_TYPES.DATE_RANGES) {
    for (let i = 0; i < dateParams.length; i++) {
      let dateId = dateParams[i].dateRange
      if (dateParams[i].dateRange === 'customRange') {
        dateId = `${dateParams[i].dateRange}-${dateParams[i].fromDate}-${dateParams[i].toDate}`
      }

      const sensorId = zoneParams[0].sensorId ?? zoneParams[0].zoneId

      response[dateId] = yield call(
        databoardsDatasetAverages,
        measurements,
        zoneParams[0],
        dateParams[i],
        sensorId,
        cacheOperation,
        type
      )
    }
  } else {
    for (let i = 0; i < zoneParams.length; i++) {
      const sensorId = zoneParams[i].sensorId ?? zoneParams[i].zoneId
      response[sensorId] = []

      if (isMobile) {
        response[sensorId] = yield call(
          mobileDatasetAverages,
          action,
          sensorId,
          i
        )
      }

      if (!isMobile) {
        response[sensorId] = yield call(
          databoardsDatasetAverages,
          measurements,
          zoneParams[i],
          dateParams[0],
          sensorId,
          cacheOperation
        )
      }
    }
  }
  if (view === 'databoard') {
    // if type is date ranges we get the timezone from the first date range
    // if type is location range we get the timezone from the first zoneFilter
    action.params['timeZone'] =
      type === COMPARISON_TYPES.DATE_RANGES
        ? dateParams[0].timeZone
        : zoneParams[0].timeZone
  }
  if (view === 'dashboard') {
    action.params['timeZone'] = rootZone?.timeZone ?? 'UTC'
  }

  yield put(timeframeGraphAveragesFetched(response, action.params))
  yield put(isLoading(false))
}

export function* watchSendFetchTimeframeGraphAverages() {
  yield takeLatest(
    FETCH_TIMEFRAME_GRAPH_AVERAGES,
    sendFetchTimeframeGraphAverages
  )
}

import { useCallback, useMemo } from 'react'
import { Tooltip } from 'react-tooltip'

import { FlexV2, Symbol } from '@/primitives'

import useColorScheme from '@/hooks/useColorScheme'

import Strings from './Strings'

import './index.scss'
import useIsRtl from '@/hooks/useIsRtl'

const THEME_DATA = {
  system: {
    name: 'systemMode',
    icon: 'contrast',
    key: 'system'
  },
  light: {
    name: 'lightMode',
    icon: 'light_mode',
    key: 'light'
  },
  dark: {
    name: 'darkMode',
    icon: 'dark_mode',
    key: 'dark'
  }
}

const THEME_DATA_KEYS_ARRAY = Object.keys(THEME_DATA)

export default function ColorSchemeToggle() {
  const { colorScheme, setColorScheme } = useColorScheme()

  const isRTL = useIsRtl()

  const strings = Strings()

  const theme = useMemo(
    () => THEME_DATA[colorScheme] || THEME_DATA.system,
    [colorScheme]
  )

  const handleChange = useCallback(() => {
    const currentIndex = THEME_DATA_KEYS_ARRAY.indexOf(colorScheme)
    const nextIndex =
      THEME_DATA_KEYS_ARRAY[(currentIndex + 1) % THEME_DATA_KEYS_ARRAY.length]
    setColorScheme(THEME_DATA[nextIndex].key)
  }, [colorScheme])

  return (
    <FlexV2 axisGap={400} alignCrossAxis='center' className='ColorSchemeToggle'>
      <Tooltip id='colorscheme-toggle-tip' place={isRTL ? 'right' : 'left'}>
        {strings.toggle} {strings[theme.name]}
      </Tooltip>
      <Symbol
        name={theme.icon}
        size={300}
        variant={'page'}
        tone={900}
        onClick={handleChange}
        data-tooltip-id='colorscheme-toggle-tip'
      />
    </FlexV2>
  )
}

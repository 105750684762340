import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { requestInventory } from '@/actions/operations/inventory'

import {
  clearInventoryCalibrations,
  requestInventoryCalibrations
} from '@/actions/operations/inventoryCalibration'

import {
  getCurrentUser,
  getOperationsInventory,
  getOperationsInventoryCalibrations,
  getOperationsInventoryCalibrationsCount,
  getOperationsLoading
} from '@/reducers/selectors'

import { Flex, Box, Table, Slot, Column, Row, Text, Loader } from '@/primitives'

import { Pagination } from '@/elements'

import TableRow from './TableRow'

import history from '../../../../../history'

import {
  hasEditPermissions,
  RESOURCE_TYPE_OPERATIONS,
  RESOURCE_TYPE_OPERATIONS_INVENTORY
} from '@/Util/PermissionUtils'

import Strings from '../Strings'
import HeaderV2 from '@/components/Operations/Shared/HeaderV2'

function InventoryCalibrationTable({
  state,
  dispatchState,
  modulePath,
  backPath,
  inventoryId
}) {
  const dispatch = useDispatch()

  const currentUser = getCurrentUser()
  const inventory = getOperationsInventory()
  const items = getOperationsInventoryCalibrations()
  const totalItems = getOperationsInventoryCalibrationsCount()
  const loading = getOperationsLoading()

  const strings = Strings()

  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(9)
  const [tableState, setTableState] = useState(state)
  const [canEdit, setCanEdit] = useState(false)

  useEffect(() => {
    return () => {
      dispatch(clearInventoryCalibrations())
    }
  }, [dispatch])

  useEffect(() => {
    if (currentUser.userName) {
      setCanEdit(
        hasEditPermissions(
          currentUser,
          RESOURCE_TYPE_OPERATIONS,
          RESOURCE_TYPE_OPERATIONS_INVENTORY
        )
      )
    }
  }, [currentUser])

  useEffect(() => {
    if (!inventoryId) {
      history.push('/operations/inventory')
    } else {
      dispatch(
        requestInventory({
          inventoryId
        })
      )
    }
  }, [inventoryId])

  useEffect(() => {
    if (tableState.page !== state.page || items.length === 0) {
      setTableState({ ...state })
      fetchInventoryCalibrations(state.page)
    }
  }, [state.page])

  const fetchInventoryCalibrations = page => {
    dispatch(
      requestInventoryCalibrations({
        filter: {
          inventoryId: [inventoryId]
        },
        limit: pageSize,
        offset: page - 1
      })
    )
  }

  const getHeader = () => {
    if (inventory.id) {
      return (
        <Box>
          {strings.manageInventoryCalibrations}
          <span className='Operations__Header__Subheader'>
            {inventory.storageFacility.name}&nbsp;-&nbsp;
            {inventory.produce.name}&nbsp;-&nbsp;
            {inventory.variety.name} ({inventory.grade.name})
          </span>
        </Box>
      )
    }
    return `${strings.manageInventoryCalibrations}`
  }

  const changePage = newPage => {
    dispatchState({
      page: newPage
    })
  }

  const onCreate = () => {
    history.push(`${modulePath}/create`)
  }

  return (
    <Flex axisGap={400} direction='column'>
      <HeaderV2
        title={getHeader()}
        buttonIcon={'add'}
        buttonText={strings.createInventoryCalibration}
        buttonCallback={onCreate}
        showButton={canEdit}
        backPath={backPath}
      />
      <Loader isLoading={loading}>
        {totalItems === 0 && (
          <Flex className={'Operations__Table__Empty'}>
            <Text>{strings.noInventoryCalibrations}</Text>
          </Flex>
        )}
        {totalItems > 0 && (
          <Table className='Operations__Table' aria-colcount='6'>
            <Slot name='head'>
              <Row>
                <Column>{strings.date}</Column>
                <Column>{strings.oldWeight}</Column>
                <Column>{strings.newWeight}</Column>
                <Column>{strings.reason}</Column>
              </Row>
            </Slot>
            <Slot name='body'>
              {items?.map(item => (
                <TableRow key={item.id} item={item} />
              ))}
            </Slot>
          </Table>
        )}
        {totalItems > pageSize && (
          <Box className='pagination-menu'>
            <Pagination
              type='simple'
              totalItems={totalItems}
              totalPages={totalPages}
              setTotalPages={setTotalPages}
              page={tableState.page}
              setPage={changePage}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          </Box>
        )}
      </Loader>
    </Flex>
  )
}

export default InventoryCalibrationTable

import { makeOperationsApiCall } from './utils'

/**
 * QUERIES
 */
export const getReturns = async params => {
  try {
    const query = /* GraphQL */ `
      query getReturns(
        $filter: GetReturnsFilter
        $order: OrderEnum
        $offset: Int
        $limit: Int
      ) {
        getReturns(
          filter: $filter
          order: $order
          offset: $offset
          limit: $limit
        ) {
          returns {
            id
            returnDate
            referenceNumber
            customer {
              name
            }
            branch {
              name
            }
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getReturns',
      resultKey: 'data'
    })
    return { data: data ?? [], error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getReturn = async params => {
  try {
    const query = /* GraphQL */ `
      query getReturn($returnId: String!) {
        getReturn(returnId: $returnId) {
          id
          organizationId
          returnDate
          referenceNumber
          customerId
          branchId
          returnItems {
            id
            returnId
            productId
            product {
              id
              name
              weight
            }
            quantityReturned
            reason
          }
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getReturn',
      resultKey: 'data'
    })
    return { data: data ?? [], error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

/**
 * MUTATIONS
 */
export const createReturn = async params => {
  try {
    const query = /* GraphQL */ `
      mutation createReturn(
        $organizationId: String!
        $returnDate: GraphQLTimestamp!
        $referenceNumber: String
        $customerId: String!
        $branchId: String!
        $returnItems: [ReturnItemInput]!
      ) {
        createReturn(
          organizationId: $organizationId
          returnDate: $returnDate
          referenceNumber: $referenceNumber
          customerId: $customerId
          branchId: $branchId
          returnItems: $returnItems
        ) {
          id
          returnDate
          referenceNumber
          customer {
            name
          }
          branch {
            name
          }
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'createReturn',
      resultKey: 'data'
    })
    return { data: data ?? [], error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updateReturn = async params => {
  try {
    const query = /* GraphQL */ `
      mutation updateReturn(
        $returnId: String!
        $organizationId: String!
        $returnDate: GraphQLTimestamp!
        $referenceNumber: String
        $customerId: String!
        $branchId: String!
      ) {
        updateReturn(
          returnId: $returnId
          organizationId: $organizationId
          returnDate: $returnDate
          referenceNumber: $referenceNumber
          customerId: $customerId
          branchId: $branchId
        ) {
          id
          returnDate
          referenceNumber
          customer {
            name
          }
          branch {
            name
          }
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'updateReturn',
      resultKey: 'data'
    })
    return { data: data ?? [], error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

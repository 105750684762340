import FlexV2 from '@/primitives/FlexV2'

import DateFilter from '../../../Shared/Filters/DateFilter'
import OrganizationsFilter from '../../../Shared/Filters/OrganizationsFilter'
import SitesFilter from '../../../Shared/Filters/SitesFilter'
import ProducesFilter from '../../../Shared/Filters/ProducesFilter'
import VarietiesFilter from '../../../Shared/Filters/VarietiesFilter'

import './index.scss'

const HarvestFilter = ({ state, dispatchState }) => {
  return (
    <FlexV2 axisGap={300} className='Harvest__Table__Filter__Container'>
      <DateFilter
        state={state}
        dispatchState={dispatchState}
        stateKey={'harvestDate'}
      />
      <FlexV2 axisGap={300} className='Harvest__Table__Filter__Select'>
        <OrganizationsFilter state={state} dispatchState={dispatchState} />
        <SitesFilter state={state} dispatchState={dispatchState} />
        <ProducesFilter state={state} dispatchState={dispatchState} />
        <VarietiesFilter state={state} dispatchState={dispatchState} />
      </FlexV2>
    </FlexV2>
  )
}

export default HarvestFilter

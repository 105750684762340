import { schemeDark2 } from '@/Util/SchemeUtils'
import {
  CONVERSION_TEMPERATURE_MEASUREMENTS,
  convertCelciusToFahrenheit
} from '@/Util/MeasurementUtils'
import {
  SETTING_CELCIUS_TO_FAHRENHEIT,
  processSettings
} from '@/Util/SettingsUtils'

export const schemeNivo = ['#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf']

export const graphSerieColors = schemeNivo.concat(schemeDark2)

const applyFixed = (fixedFactor, value) => {
  if (fixedFactor && fixedFactor !== 1) {
    return value.toFixed(fixedFactor)
  }
  return value.toFixed(1)
}

const applyConversion = (scaleFactor, value) => {
  if (scaleFactor && scaleFactor !== 1) {
    return value * scaleFactor
  }
  return value
}

export function normalizeNaN (value) {
  return isNaN(value) ? null : value
}

export function parseValue ({ value, fixedFactor, scaleFactor }) {
  if (value === null || value === undefined) {
    return null
  }
  return normalizeNaN(
    applyFixed(fixedFactor, applyConversion(scaleFactor, value))
  )
}

export function handleMeasurementConversion ({
  data,
  measurement,
  unit,
  queryType,
  settings,
  userId = null,
  organizationId = null
}) {
  if (CONVERSION_TEMPERATURE_MEASUREMENTS.includes(measurement)) {
    try {
      return handleTemperatureConversion({
        data,
        unit,
        queryType,
        settings,
        userId,
        organizationId
      })
    } catch (err) {
      // If anthing fails, just return the data as is
      return { data, unit }
    }
  }

  return { data, unit }
}

function handleTemperatureConversion ({
  data,
  unit,
  queryType,
  settings,
  userId = null,
  organizationId = null
}) {
  const enableFahrenheit = processSettings(
    settings,
    SETTING_CELCIUS_TO_FAHRENHEIT,
    userId,
    organizationId
  )

  if (!enableFahrenheit) {
    return { data, unit }
  }

  if (queryType === 'live' || queryType === 'average') {
    data = {
      ...data,
      value: convertCelciusToFahrenheit(data?.value),
      min: convertCelciusToFahrenheit(data?.min),
      max: convertCelciusToFahrenheit(data?.max)
    }

    unit = '°F'
  }

  if (queryType === 'dataset') {
    data = Object.keys(data).reduce((acc, zone) => {
      acc[zone] = data[zone].map(datapoint => {
        return {
          ...datapoint,
          y: convertCelciusToFahrenheit(datapoint.y)
        }
      })
      return acc
    }, {})

    unit = '°F'
  }

  return { data, unit }
}

import { Box } from '../'
import './index.scss'

const deriveProps = ({ defaultValue, value, onChange }) => {
  if (onChange) return { value: value || '', onChange }
  return { defaultValue: defaultValue || value || '' }
}

const Select = ({
  value = null,
  defaultValue = null,
  onChange = null,
  children = null,
  className = '',
  ...rest
}) => {
  return (
    <Box
      as='select'
      {...deriveProps({ defaultValue, value, onChange })}
      className={['Select', className]}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default Select

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { getWeatherForecast, getDailyForecast } from '@/api/weather/forecast'

export const fetchDashboardForecast = createAsyncThunk(
  'fetchDashboardForecast',
  async params => {
    const { siteId, period } = params
    const hourlyForecast = await getWeatherForecast({
      input: { siteId, period }
    })

    const dailyForecast = await getDailyForecast({
      input: { siteId }
    })

    const forecast = {
      hourlyForecast: hourlyForecast?.forecast,
      dailyForecast: dailyForecast?.forecast
    }

    const error = hourlyForecast?.error ?? dailyForecast?.error

    return { forecast, error }
  }
)

const weatherForecastSlice = createSlice({
  name: 'weatherForecastReducer',
  initialState: {
    forecast: {},
    forecastRequested: null,
    loading: false,
    error: null
  },
  extraReducers: builder => {
    builder
      .addCase(fetchDashboardForecast.pending, (state, action) => {
        state.error = null
        state.loading = true
        state.forecast = {}
        state.forecastRequested = action?.meta?.arg?.siteId
      })
      .addCase(fetchDashboardForecast.fulfilled, (state, action) => {
        state.forecast = action?.payload?.forecast ?? {}
        state.error = action?.payload?.error ?? null
        state.loading = false
      })
  }
})

export default weatherForecastSlice.reducer

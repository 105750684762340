import { I18n } from 'aws-amplify'
import { number, object, string } from 'yup'

export const FIELDS = {
  id: 'id',
  saleId: 'saleId',
  productId: 'productId',
  quantityOrdered: 'quantityOrdered',
  unitPrice: 'unitPrice',
  discount: 'discount',
  tax: 'tax'
}

export const SCHEMA = object().shape({
  productId: string().required(I18n.get('Please select a product.')),
  quantityOrdered: number()
    .integer(I18n.get('A partial quantity is not allowed.'))
    .typeError(I18n.get('An order quantity is required.'))
    .min(1, I18n.get('The quantity ordered cannot be less than one.'))
    .required(I18n.get('An order quantity is required.')),
  unitPrice: number()
    .typeError(I18n.get('A unit price is required.'))
    .min(1, I18n.get('The unit price cannot be less than one.'))
    .required(I18n.get('A unit price is required.')),
  discount: number()
    .nullable()
    .transform(n => (isNaN(n) ? 0 : n))
    .min(0, I18n.get('The discount cannot be less than zero.')),
  tax: number()
    .nullable()
    .transform(n => (isNaN(n) ? 0 : n))
    .min(0, I18n.get('The tax cannot be less than zero.'))
})

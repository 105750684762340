import { useEffect, useState } from 'react'
import { I18n } from 'aws-amplify'

import { Button, FlexV2 } from '@/primitives'
import { Select } from '@/elements'

import Strings from '../Strings'

import { getDeviceTypes } from '@/reducers/selectors'

import useMediaQuery from '@/hooks/useMediaQuery'

const STATUSES_TREE = [
  {
    label: I18n.get('Notification'),
    value: 'notification'
  },
  {
    label: I18n.get('Caution'),
    value: 'caution'
  },
  {
    label: I18n.get('Warning'),
    value: 'warning'
  },
  {
    label: I18n.get('Not in use'),
    value: 'notInUse'
  },
  {
    label: I18n.get('Missing data'),
    value: 'missingData'
  }
]

const STATUSES_ALERTS = [
  {
    label: I18n.get('Notification'),
    value: 'notification'
  },
  {
    label: I18n.get('Caution'),
    value: 'caution'
  },
  {
    label: I18n.get('Warning'),
    value: 'warning'
  }
]

/**
 * Renders the actions for the StatusPage component.
 *
 * @param {Object} props - The props to pass to the component.
 * @param {boolean} props.expandAll - The current expand all state.
 * @param {function} props.setExpandAll - The function to update the expand all state.
 *
 * @returns {React.ReactElement} A React component that displays the actions for the StatusPage.
 */
export default function Actions({
  isAlerts,
  expandAll,
  setExpandAll,
  selectedDeviceType,
  selectedStatus,
  updateSelectedDeviceType,
  updateSelectedStatus
}) {
  const strings = Strings()

  const deviceTypes = getDeviceTypes()

  const isAboveBreakpoint = useMediaQuery('min-width: 440px')

  const [show, setShow] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 250)
  }, [])

  const toggleDisplayState = () => {
    setExpandAll(!expandAll)
  }

  function getDeviceTypeOptions() {
    return deviceTypes.map(deviceType => ({
      label: deviceType.name,
      value: deviceType.id
    }))
  }

  function onSelectDeviceType(option) {
    if (!option) {
      updateSelectedDeviceType(null)
    } else {
      updateSelectedDeviceType(option.value)
    }
  }

  function onSelectStatus(option) {
    if (!option) {
      updateSelectedStatus(null)
    } else {
      updateSelectedStatus(option.value)
    }
  }

  return (
    <FlexV2
      axisGap={400}
      className='StatusPage__Actions'
      direction={isAboveBreakpoint ? null : 'column'}
      alignMainAxis='justify-content'
      data-show={show}
    >
      <Select
        className='StatusPage__Actions__Select'
        controlStyles={{ height: '100%' }}
        isClearable={true}
        name='deviceType'
        placeholder={I18n.get('Select a device type')}
        value={selectedDeviceType}
        options={getDeviceTypeOptions()}
        isSearchable={true}
        onChange={onSelectDeviceType}
      />
      <Select
        className='StatusPage__Actions__Select'
        controlStyles={{ height: '100%' }}
        isClearable={true}
        name='status'
        placeholder={I18n.get('Select a status')}
        value={selectedStatus}
        options={isAlerts ? STATUSES_ALERTS : STATUSES_TREE}
        isSearchable={true}
        onChange={onSelectStatus}
      />
      <Button
        iconBefore={expandAll ? 'unfold_less' : 'unfold_more'}
        onClick={toggleDisplayState}
      >
        {expandAll ? strings.collapseAll : strings.expandAll}
      </Button>
    </FlexV2>
  )
}

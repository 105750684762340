import { useEffect, useRef, useState } from 'react'
import { I18n } from 'aws-amplify'

import {
  getDataboardHarvestArgs,
  getDataboardSitesHierarchies
} from '@/reducers/selectors'

import { Box, Text, Flex } from '@/primitives'
import { getZoneName } from '@/Util/ZoneUtils'
import Strings from '../Strings'

const Tooltip = props => {
  const { data, keys } = props
  const { data: tooltipData, id, color, grades, grade } = data
  const strings = Strings()

  const harvestArgs = getDataboardHarvestArgs()
  const zonesHierarchy = getDataboardSitesHierarchies()

  const tooltipRef = useRef(null)
  const [offset, setOffset] = useState({ top: 0 })

  const { zone, ...rest } = tooltipData

  const insertGradeRow = (key, value, customStyles = {}) => {
    let rowStyle = { ...customStyles }

    if (key === id) {
      rowStyle = { color, fontWeight: '600' }
    }

    const locationGrades = grades[data.index]
    if (!locationGrades[key]) return null

    return (
      <Flex style={rowStyle} key={key}>
        <Text>{key}:&nbsp;</Text>
        <Text>{`${locationGrades[key][grade]?.toLocaleString('en-US')} ${
          strings.kilograms
        }`}</Text>
      </Flex>
    )
  }

  const insertRow = (key, value, customStyles = {}) => {
    let rowStyle = { ...customStyles }

    if (key === id) {
      rowStyle = { color, fontWeight: '600' }
    }
    return (
      <Flex style={rowStyle} key={key}>
        <Text>{key}:&nbsp;</Text>
        <Text>
          {value
            ? `${value.toLocaleString('en-US')} ${strings.kilograms}`
            : '-'}
        </Text>
      </Flex>
    )
  }

  useEffect(() => {
    const tooltipRect = tooltipRef.current.getBoundingClientRect()
    const viewHeight = window.innerHeight
    const { height: tooltipHeight, top, bottom } = tooltipRect
    if (viewHeight < bottom && top > tooltipHeight)
      setOffset({ top: 'auto', bottom: 0 })
    if (top < 0) setOffset({ top: 0, bottom: 'auto' })
  }, [data])

  const insertHeader = () => {
    const currentZone = harvestArgs?.zones?.find(({ name }) => name === zone)
    const zoneIds = currentZone?.zonePath?.split('/')
    const readablePath = zoneIds.reduce((acc, currentZoneId, index) => {
      if (index === 0) return acc
      const siteId = zoneIds[1]
      const zoneId = index === 1 ? null : currentZoneId
      return `${acc}/${getZoneName(zonesHierarchy, siteId, zoneId)}`
    }, '')

    return (
      <Box>
        <Text as='h5' style={{ marginBottom: '0.5rem' }}>
          {zone}
          {grades && (
            <Text
              fontWeight={300}
              size={200}
              style={{ marginBottom: '0.5rem' }}
            >
              {` - ${I18n.get('Grade')} ${grade}`}
            </Text>
          )}
        </Text>
        <Text
          as='p'
          size={100}
          variant='page'
          tone={800}
          style={{ marginBottom: '1rem' }}
        >
          {readablePath}
        </Text>
      </Box>
    )
  }

  const getTotal = () => {
    if (!grades) {
      const total = keys.reduce(
        (acc, key) => acc + parseFloat(rest[key] || 0),
        0
      )
      return parseFloat(Math.round(total * 100) / 100)
    }

    const locationGrades = grades[data.index]
    return keys.reduce((acc, produceName) => {
      if (locationGrades[produceName]) {
        acc += locationGrades[produceName][grade]
      }
      return acc
    }, 0)
  }

  return (
    <Box
      style={{
        ...offset,
        position: 'absolute',
        backgroundColor: 'var(--ctx-theme-color-page-200)',
        padding: '1rem',
        border: '1px solid var(--ctx-theme-color-page-300)',
        borderRadius: '5px',
        minWidth: '20rem'
      }}
      ref={tooltipRef}
    >
      {insertHeader()}
      {[...keys].sort().map(key => {
        return !grades
          ? insertRow(key, tooltipData[key], { fontWeight: '300' })
          : insertGradeRow(key, tooltipData[key], { fontWeight: '300' })
      })}
      <hr className='mt-4 mb-2' />
      {insertRow(I18n.get('Total weight'), getTotal(), {
        fontWeight: '600'
      })}
    </Box>
  )
}

export default Tooltip

import axios from 'axios'

import { VIDEO_STREAM_URL } from './config'
import { getCognitoData } from '../utils'

export const getVideoStream = async params => {
  try {
    const { idToken } = await getCognitoData()
    const response = await axios({
      url: VIDEO_STREAM_URL,
      method: 'post',
      data: params,
      headers: {
        authorization: idToken
      }
    })

    return { url: response.data.hlsStreamingSessionUrl, error: null }
  } catch (err) {
    if (err.response && err.response.data) {
      return { error: err.response.data.errMessage, url: null }
    }
    return { error: err.message, url: null }
  }
}

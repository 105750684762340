import { createReducer } from '@/Util/ReduxUtils'

import produceHandlers, { produceInitialState } from './produce'
import varietyHandlers, { varietyInitialState } from './variety'
import gradeHandlers, { gradeInitialState } from './grade'
import currencyHandlers, { currencyInitialState } from './currency'
import countryHandlers, { countryInitialState } from './country'
import regionHandlers, { regionInitialState } from './region'
import cityHandlers, { cityInitialState } from './city'
import areaHandlers, { areaInitialState } from './area'
import packageUnitHandlers, { packageUnitInitialState } from './packageUnit'

import paymentMethodHandlers, {
  paymentMethodInitialState
} from './paymentMethod'

import paymentTermHandlers, { paymentTermInitialState } from './paymentTerm'

import storageFacilityHandlers, {
  storageFacilityInitialState
} from './storageFacility'

import shippingMethodHandlers, {
  shippingMethodInitialState
} from './shippingMethod'

import planHandlers, { planInitialState } from './plan'
import harvestHandlers, { harvestInitialState } from './harvest'
import wasteHandlers, { wasteInitialState } from './waste'
import saleHandlers, { saleInitialState } from './sale'
import returnHandlers, { returnInitialState } from './return'
import inventoryHandlers, { inventoryInitialState } from './inventory'

import inventoryCalibrationHandlers, {
  inventoryCalibrationInitialState
} from './inventoryCalibration'

import inventoryTransferHandlers, {
  inventoryTransferInitialState
} from './inventoryTransfer'

import customerHandlers, { customerInitialState } from './customer'
import branchHandlers, { branchInitialState } from './branch'
import unitPriceHandlers, { unitPriceInitialState } from './unitPrice'

import packageUnitInventoryHandlers, {
  packageUnitInventoryInitialState
} from './packageUnitInventory'

import packageUnitInventoryCalibrationHandlers, {
  packageUnitInventoryCalibrationInitialState
} from './packageUnitInventoryCalibration'

import packageUnitInventoryTransferHandlers, {
  packageUnitInventoryTransferInitialState
} from './packageUnitInventoryTransfer'

import packageUnitPurchaseHandlers, {
  packageUnitPurchaseInitialState
} from './packageUnitPurchase'

import productHandlers, { productInitialState } from './product'
import cropHandlers, { cropInitialState } from './crop'
import supplierHandlers, { supplierInitialState } from './supplier'

import { RESET_STATE } from '@/actions/operations'
import fertilizerInventoryHandlers, {
  fertilizerInventoryInitialState
} from './fertilizerInventory'

import fertilizerInventoryCalibrationHandlers, {
  fertilizerInventoryCalibrationInitialState
} from './fertilizerInventoryCalibration'
import fertilizerInventoryTransferHandlers, {
  fertilizerInventoryTransferInitialState
} from './fertilizerInventoryTransfer'

import fertilizerPurchaseHandlers, {
  fertilizerPurchaseInitialState
} from './fertilizerPurchase'

import databoardHandlers, { databoardInitialState } from './databoard'

export const initialState = {
  loading: false,
  error: null,
  ...produceInitialState,
  ...varietyInitialState,
  ...gradeInitialState,
  ...currencyInitialState,
  ...countryInitialState,
  ...regionInitialState,
  ...cityInitialState,
  ...areaInitialState,
  ...packageUnitInitialState,
  ...paymentMethodInitialState,
  ...paymentTermInitialState,
  ...storageFacilityInitialState,
  ...shippingMethodInitialState,
  ...planInitialState,
  ...harvestInitialState,
  ...wasteInitialState,
  ...saleInitialState,
  ...returnInitialState,
  ...inventoryInitialState,
  ...inventoryCalibrationInitialState,
  ...inventoryTransferInitialState,
  ...customerInitialState,
  ...branchInitialState,
  ...unitPriceInitialState,
  ...packageUnitInventoryInitialState,
  ...packageUnitInventoryCalibrationInitialState,
  ...packageUnitInventoryTransferInitialState,
  ...packageUnitPurchaseInitialState,
  ...productInitialState,
  ...cropInitialState,
  ...supplierInitialState,
  ...fertilizerInventoryInitialState,
  ...fertilizerInventoryCalibrationInitialState,
  ...fertilizerInventoryTransferInitialState,
  ...fertilizerPurchaseInitialState,
  ...databoardInitialState
}

const handlers = {
  [RESET_STATE]: () => ({
    ...initialState
  }),
  ...produceHandlers,
  ...varietyHandlers,
  ...gradeHandlers,
  ...currencyHandlers,
  ...countryHandlers,
  ...regionHandlers,
  ...cityHandlers,
  ...areaHandlers,
  ...packageUnitHandlers,
  ...paymentMethodHandlers,
  ...paymentTermHandlers,
  ...storageFacilityHandlers,
  ...shippingMethodHandlers,
  ...planHandlers,
  ...harvestHandlers,
  ...wasteHandlers,
  ...saleHandlers,
  ...returnHandlers,
  ...inventoryHandlers,
  ...inventoryCalibrationHandlers,
  ...inventoryTransferHandlers,
  ...customerHandlers,
  ...branchHandlers,
  ...unitPriceHandlers,
  ...packageUnitInventoryHandlers,
  ...packageUnitInventoryCalibrationHandlers,
  ...packageUnitInventoryTransferHandlers,
  ...packageUnitPurchaseHandlers,
  ...productHandlers,
  ...cropHandlers,
  ...supplierHandlers,
  ...fertilizerInventoryHandlers,
  ...fertilizerInventoryCalibrationHandlers,
  ...fertilizerInventoryTransferHandlers,
  ...fertilizerPurchaseHandlers,
  ...databoardHandlers
}

export default createReducer(handlers, initialState)

import { useEffect } from 'react'
import { I18n } from 'aws-amplify'
import { DateTime } from 'luxon'
import { FlexV2, Text, Input, Button, Loader } from '@/primitives'
import { getZoneHierarchy } from '@/reducers/selectors'
import { getDisplayNamePath } from '../../../Utils/Zone'
import {
  SET_DISPLAY_PATH,
  SET_SHOW_DEVICE_MOVER_VIEW,
  SET_SITE_HIERARCHY
} from '../state'

const DEVICE_MOVE_COOLDOWN_MINUTES = 15

function getDeviceMoveAvailability(lastMoveAt) {
  if (!lastMoveAt) {
    return { moveLocked: false, moveUnlockAt: null }
  }

  const lastMoveDate = DateTime.fromSeconds(lastMoveAt)
  const currentTime = DateTime.utc()

  return {
    moveLocked:
      currentTime.diff(lastMoveDate, 'minutes').minutes <
      DEVICE_MOVE_COOLDOWN_MINUTES,
    moveUnlockAt: lastMoveDate.plus({ minutes: DEVICE_MOVE_COOLDOWN_MINUTES })
  }
}

function formatMoveUnlockAt(moveUnlockAt) {
  const relativeDay = moveUnlockAt.toRelativeCalendar()
  const time = moveUnlockAt.toLocaleString(DateTime.TIME_SIMPLE)

  return `${relativeDay} at ${time}`
}

export default function LocationInput({
  state,
  stateDispatch,
  isDeviceBadlyConfigured
}) {
  const zoneHierarchy = getZoneHierarchy()
  const { moveLocked, moveUnlockAt } = getDeviceMoveAvailability(
    state.device.lastMoveAt
  )
  useEffect(() => {
    if (
      state.device.id &&
      Object.values(zoneHierarchy).length > 0 &&
      !state.displayPath
    ) {
      const hierarchy =
        zoneHierarchy?.[state?.device.zonePath.split('/')?.[1]] ?? null
      stateDispatch({
        type: SET_SITE_HIERARCHY,
        payload: hierarchy
      })
      stateDispatch({
        type: SET_DISPLAY_PATH,
        payload: getDisplayNamePath(hierarchy || null, state.device.zonePath)
      })
    }
  }, [zoneHierarchy, state.device.id, state.displayPath, state.device.zonePath])

  function onShowZoneMover(e) {
    e.preventDefault()
    stateDispatch({
      type: SET_SHOW_DEVICE_MOVER_VIEW,
      payload: true
    })
  }

  return (
    <FlexV2 direction='column' axisGap={300}>
      <Text variant='page' tone={900} size={100}>
        {I18n.get('Location')}
      </Text>
      <Loader isLoading={false}>
        <FlexV2 axisGap={400} direction='column'>
          <Input
            value={state.displayPath}
            readOnly={true}
            disabled={isDeviceBadlyConfigured}
          />
          {state.device.zoneId && (
            <Button
              onClick={onShowZoneMover}
              className='ZoneDetails__MoveButton'
              iconBefore={moveLocked ? 'lock' : 'edit_location'}
              disabled={isDeviceBadlyConfigured || moveLocked}
            >
              {moveLocked
                ? `${I18n.get(
                    'Move device will be unlocked'
                  )} ${formatMoveUnlockAt(moveUnlockAt)}`
                : I18n.get('Move device')}
            </Button>
          )}
        </FlexV2>
      </Loader>
    </FlexV2>
  )
}

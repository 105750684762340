import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  getFeatureFlags,
  getFeatureFlagsForObject,
  attachFeatureFlag,
  removeFeatureFlag
} from '@/api/management/featureFlag'

export const fetchFeatureFlags = createAsyncThunk(
  'fetchFeatureFlags',
  async params => {
    const { featureFlags } = await getFeatureFlags()
    const { attachments } = await getFeatureFlagsForObject(params)
    return { featureFlags, attachments }
  }
)

export const addFeatureFlag = createAsyncThunk(
  'addFeatureFlag',
  async params => {
    return await attachFeatureFlag(params)
  }
)

export const deleteFeatureFlag = createAsyncThunk(
  'deleteFeatureFlag',
  async params => {
    return await removeFeatureFlag(params)
  }
)

const featureFlagSlice = createSlice({
  name: 'featureFlagReducer',
  initialState: {
    featureFlags: [],
    attachments: [],
    isFetching: false,
    isSaving: false
  },
  extraReducers: builder => {
    builder
      .addCase(fetchFeatureFlags.pending, (state, action) => {
        state.featureFlags = []
        state.attachments = []
        state.isFetching = true
      })
      .addCase(fetchFeatureFlags.rejected, (state, action) => {
        state.isFetching = false
      })
      .addCase(fetchFeatureFlags.fulfilled, (state, action) => {
        state.featureFlags = action.payload.featureFlags
        state.attachments = action.payload.attachments
        state.isFetching = false
      })
      .addCase(addFeatureFlag.pending, (state, action) => {
        state.isSaving = true
      })
      .addCase(addFeatureFlag.rejected, (state, action) => {
        state.isSaving = false
      })
      .addCase(addFeatureFlag.fulfilled, (state, action) => {
        state.attachments.push(action.payload.attachment)
        state.isSaving = false
      })
      .addCase(deleteFeatureFlag.pending, (state, action) => {
        state.isSaving = true
      })
      .addCase(deleteFeatureFlag.rejected, (state, action) => {
        state.isSaving = false
      })
      .addCase(deleteFeatureFlag.fulfilled, (state, action) => {
        const { featureFlagId } = action.payload.attachment
        state.attachments = state.attachments.filter(
          attachment => attachment.featureFlagId !== featureFlagId
        )
        state.isSaving = false
      })
  }
})

export default featureFlagSlice.reducer

import { I18n } from 'aws-amplify'
import { object, string } from 'yup'
import { useEffect, useState, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  createPaymentTerm,
  requestPaymentTerm,
  updatePaymentTerm
} from '@/actions/operations/paymentTerm'

import {
  getOperationsPaymentTerm,
  getOperationsLoading,
  getOperationsError
} from '@/reducers/selectors'

import { Box, Flex, Form, Input, Label, Text, Loader } from '@/primitives'

import DocumentUploader from '@/elements/DocumentUploader'
import InputError from '@/elements/InputError'

import HeaderV2 from '@/components/Operations/Shared/HeaderV2'
import OrganizationInput from '../../Shared/Organization/Input'

import { getValidationErrorMap } from '@/Util/GeneralUtils'
import { cleanS3Key } from '@/Util/StorageUtils'

import history from '../../../../history'

import { formReducer, formInitalState } from './state'

const SCHEMA = object({
  organizationId: string().required(I18n.get('An organization is required.')),
  name: string().required(I18n.get('Name is required.'))
})

function PaymentTermForm({ state, modulePath }) {
  const dispatch = useDispatch()
  const { itemId } = useParams()

  const paymentTerm = getOperationsPaymentTerm()
  const loading = getOperationsLoading()
  const error = getOperationsError()

  const [formState, dispatchFormState] = useReducer(
    formReducer,
    formInitalState
  )

  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)

  useEffect(() => {
    if (itemId && paymentTerm.id !== itemId) {
      dispatch(requestPaymentTerm({ paymentTermId: itemId }))
    }
  }, [dispatch, paymentTerm?.id, itemId])

  useEffect(() => {
    if (paymentTerm?.id && paymentTerm.id === itemId) {
      dispatchFormState({ type: 'set-state', state: paymentTerm })
    }
  }, [paymentTerm, itemId])

  useEffect(() => {
    if (!itemId && state.organizations.length === 1) {
      dispatchFormState({
        type: 'update',
        name: 'organizationId',
        value: state.organizations[0]
      })
    }
  }, [state, itemId])

  useEffect(() => {
    if (waiting && !loading && !error) {
      history.replace(modulePath)
    }
  }, [waiting, loading, error, modulePath])

  const onSubmit = async e => {
    e?.preventDefault()
    try {
      await SCHEMA.validate(formState, { abortEarly: false })
      setWaiting(true)

      if (itemId) {
        formState.paymentTermId = itemId
        dispatch(updatePaymentTerm(formState))
      } else {
        dispatch(createPaymentTerm(formState))
      }
    } catch (error) {
      setErrors(getValidationErrorMap(error))
    }
  }

  const onChange = e => {
    let { name, value } = e.currentTarget
    dispatchFormState({ type: 'update', name, value })
  }

  const handleOrganizationInput = organizationId => {
    dispatchFormState({
      type: 'update',
      name: 'organizationId',
      value: organizationId
    })
  }

  const onSetFile = (_, file) => {
    dispatchFormState({ type: 'set-file', file })
  }

  const onDeselectFile = () => {
    dispatchFormState({ type: 'reset-document' })
  }

  return (
    <Form className='Operations__Form'>
      <HeaderV2
        title={I18n.get('Manage Payment Terms')}
        backPath={modulePath}
        buttonText={I18n.get('Save Payment Terms')}
        buttonCallback={onSubmit}
        buttonIcon={'save'}
      />
      <Loader isLoading={loading}>
        <OrganizationInput
          fieldName={'organizationId'}
          organizationId={formState.organizationId}
          handleInput={handleOrganizationInput}
          errors={errors}
        />
        <Flex axisGap={300} className='Operations__Form__Fields'>
          <Label>
            <Text variant='page' tone={700}>
              {I18n.get('Name')}
            </Text>
            <Input value={formState.name} name='name' onChange={onChange} />
            <InputError error={errors?.name} />
          </Label>
        </Flex>
        <Flex
          axisGap={300}
          direction='column'
          className='Operations__Form__Fields'
        >
          <Text variant='page' tone={700}>
            {I18n.get('Termsheet')}
          </Text>
          <Box>
            <DocumentUploader
              name='termsheet'
              onDeselectFile={onDeselectFile}
              onSetFile={onSetFile}
              setErrors={setErrors}
              file={formState.termSheetDocument.file}
              fileName={cleanS3Key(formState.termSheetDocument.s3Key)}
              fileUrl={formState.termSheetDocument.url}
            />
          </Box>
          <InputError error={errors?.termSheet} />
        </Flex>
      </Loader>
    </Form>
  )
}

export default PaymentTermForm

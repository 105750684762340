import { I18n } from 'aws-amplify'

// Query Types
export const LIVE_QUERY = 'live'
export const AVERAGE_QUERY = 'average'
export const DATASET_QUERY = 'dataset'

export const QUERIES = {
  [LIVE_QUERY]: {
    label: () => I18n.get('Live'),
    value: LIVE_QUERY
  },
  [AVERAGE_QUERY]: {
    label: () => I18n.get('Average'),
    value: AVERAGE_QUERY
  },
  [DATASET_QUERY]: {
    label: () => I18n.get('Dataset'),
    value: DATASET_QUERY
  }
}

// Chart Types
export const VALUE_CHART = 'value'
export const LINE_CHART = 'line'
export const MULTI_LINE_CHART = 'multi_line'
export const SCATTERPLOT_CHART = 'scatterplot'
export const PIE_CHART = 'pie'
export const BAR_CHART = 'bar'
export const MATRIX_CHART = 'matrix'

export const CHARTS = {
  [VALUE_CHART]: {
    label: () => I18n.get('Value'),
    value: VALUE_CHART,
    minSize: {
      md: { minW: 8, minH: 3 },
      lg: { minW: 4, minH: 3 },
      xl: { minW: 2, minH: 3 }
    }
  },
  [LINE_CHART]: {
    label: () => I18n.get('Line'),
    value: LINE_CHART,
    minSize: {
      md: { minW: 8, minH: 4 },
      lg: { minW: 4, minH: 4 },
      xl: { minW: 4, minH: 4 }
    }
  },
  [MULTI_LINE_CHART]: {
    label: () => I18n.get('Line'),
    value: MULTI_LINE_CHART,
    minSize: {
      md: { minW: 8, minH: 6 },
      lg: { minW: 8, minH: 6 },
      xl: { minW: 8, minH: 6 }
    }
  },
  [SCATTERPLOT_CHART]: {
    label: () => I18n.get('Scatterplot'),
    value: SCATTERPLOT_CHART,
    minSize: {
      md: { minW: 8, minH: 4 },
      lg: { minW: 4, minH: 4 },
      xl: { minW: 4, minH: 4 }
    }
  },
  [PIE_CHART]: {
    label: () => I18n.get('Pie'),
    value: PIE_CHART,
    minSize: {
      md: { minW: 8, minH: 3 },
      lg: { minW: 4, minH: 3 },
      xl: { minW: 2, minH: 3 }
    }
  },
  [BAR_CHART]: {
    label: () => I18n.get('Bar'),
    value: BAR_CHART,
    minSize: {
      md: { minW: 8, minH: 4 },
      lg: { minW: 4, minH: 4 },
      xl: { minW: 4, minH: 4 }
    }
  },
  [MATRIX_CHART]: {
    label: () => I18n.get('Matrix'),
    value: MATRIX_CHART,
    minSize: {
      md: { minW: 8, minH: 4 },
      lg: { minW: 4, minH: 4 },
      xl: { minW: 4, minH: 4 }
    }
  }
}

// Ranges
export const LAST_HOUR = 'last-hour'
export const TODAY = 'today'
export const LAST_24_HOURS = 'last-24-hours'
export const LAST_7_DAYS = 'last-7-days'
export const LAST_30_DAYS = 'last-30-days'
export const LAST_3_MONTHS = 'last-3-months'
export const CURRENT_YEAR = 'current-year'
export const LAST_YEAR = 'last-year'

// Intervals
export const USE_RANGE_INTERVAL = 'use-range-interval'
export const MINUTELY = 'minutely'
export const HOURLY = 'hourly'
export const DAILY = 'daily'
export const WEEKLY = 'weekly'
export const MONTHLY = 'monthly'

export function getRanges(range) {
  const currentRange = { ...RANGES[range] }
  if (currentRange) {
    const nextRange = { ...currentRange, label: currentRange.label() }
    return nextRange
  }

  return {}
}

export function getIntervals(interval) {
  const currentInterval = { ...INTERVALS[interval] }
  if (currentInterval) {
    const nextInterval = { ...currentInterval, label: currentInterval.label() }
    return nextInterval
  }

  return {}
}

export const RANGES = {
  [LAST_HOUR]: {
    label: () => I18n.get('Last hour'),
    value: LAST_HOUR,
    intervals: [MINUTELY],
    query: [1, 'h']
  },
  [TODAY]: {
    label: () => I18n.get('Today'),
    value: TODAY,
    intervals: [HOURLY],
    query: [0, 'd']
  },
  [LAST_24_HOURS]: {
    label: () => I18n.get('Last 24 hours'),
    value: LAST_24_HOURS,
    intervals: [HOURLY],
    query: [1, 'd']
  },
  [LAST_7_DAYS]: {
    label: () => I18n.get('Last 7 days'),
    value: LAST_7_DAYS,
    intervals: [DAILY, HOURLY],
    query: [7, 'd']
  },
  [LAST_30_DAYS]: {
    label: () => I18n.get('Last 30 days'),
    value: LAST_30_DAYS,
    intervals: [DAILY, WEEKLY],
    query: [30, 'd']
  },
  [LAST_3_MONTHS]: {
    label: () => I18n.get('Last 3 months'),
    value: LAST_3_MONTHS,
    intervals: [MONTHLY, WEEKLY],
    query: [3, 'M']
  },
  [CURRENT_YEAR]: {
    label: () => I18n.get('Current year'),
    value: CURRENT_YEAR,
    intervals: [MONTHLY, WEEKLY],
    // 0y means current year because it starts in january of the current year
    query: [0, 'y']
  },
  [LAST_YEAR]: {
    label: () => I18n.get('Last year'),
    value: LAST_YEAR,
    intervals: [MONTHLY, WEEKLY],
    // 1y means last year and starts 1 year ago counting from the current month
    query: [1, 'y']
  }
}

export const INTERVALS = {
  [USE_RANGE_INTERVAL]: {
    label: () => I18n.get('Use range interval'),
    value: USE_RANGE_INTERVAL,
    query: null,
    dateFormat: null
  },
  [MINUTELY]: {
    label: () => I18n.get('Minutely'),
    value: MINUTELY,
    query: '1m',
    dateFormat: 'HH:mm'
  },
  [HOURLY]: {
    label: () => I18n.get('Hourly'),
    value: HOURLY,
    query: '1h',
    dateFormat: 'HH:mm'
  },
  [DAILY]: {
    label: () => I18n.get('Daily'),
    value: DAILY,
    query: '1d',
    dateFormat: 'dd/MM/yy'
  },
  [WEEKLY]: {
    label: () => I18n.get('Weekly'),
    value: WEEKLY,
    query: '7d',
    dateFormat: 'dd/MM/yy'
  },
  [MONTHLY]: {
    label: () => I18n.get('Monthly'),
    value: MONTHLY,
    query: '1M',
    dateFormat: 'dd/MM/yy'
  }
}

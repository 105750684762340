import { makeActionCreator } from '@/Util/ReduxUtils'

export const REQUEST_FERTILIZER_INVENTORY_TRANSFERS =
  'REQUEST_FERTILIZER_INVENTORY_TRANSFERS'
export const requestFertilizerInventoryTransfers = makeActionCreator(
  REQUEST_FERTILIZER_INVENTORY_TRANSFERS,
  'params'
)

export const RECEIVE_FERTILIZER_INVENTORY_TRANSFERS =
  'RECEIVE_FERTILIZER_INVENTORY_TRANSFERS'
export const receiveFertilizerInventoryTransfers = makeActionCreator(
  RECEIVE_FERTILIZER_INVENTORY_TRANSFERS,
  'fertilizerInventoryTransfers',
  'count',
  'error'
)

export const CREATE_FERTILIZER_INVENTORY_TRANSFER =
  'CREATE_FERTILIZER_INVENTORY_TRANSFER'
export const createFertilizerInventoryTransfer = makeActionCreator(
  CREATE_FERTILIZER_INVENTORY_TRANSFER,
  'params'
)

export const FERTILIZER_INVENTORY_TRANSFER_CREATED =
  'FERTILIZER_INVENTORY_TRANSFER_CREATED'
export const fertilizerInventoryTransferCreated = makeActionCreator(
  FERTILIZER_INVENTORY_TRANSFER_CREATED,
  'fertilizerInventoryTransfer',
  'error'
)

export const UPDATE_FERTILIZER_INVENTORY_TRANSFER =
  'UPDATE_FERTILIZER_INVENTORY_TRANSFER'
export const updateFertilizerInventoryTransfer = makeActionCreator(
  UPDATE_FERTILIZER_INVENTORY_TRANSFER,
  'params'
)

export const FERTILIZER_INVENTORY_TRANSFER_UPDATED =
  'FERTILIZER_INVENTORY_TRANSFER_UPDATED'
export const fertilizerInventoryTransferUpdated = makeActionCreator(
  FERTILIZER_INVENTORY_TRANSFER_UPDATED,
  'fertilizerInventoryTransfer',
  'error'
)

export const REQUEST_FERTILIZER_INVENTORY_TRANSFER =
  'REQUEST_FERTILIZER_INVENTORY_TRANSFER'
export const requestFertilizerInventoryTransfer = makeActionCreator(
  REQUEST_FERTILIZER_INVENTORY_TRANSFER,
  'params'
)

export const RECEIVE_FERTILIZER_INVENTORY_TRANSFER =
  'RECEIVE_FERTILIZER_INVENTORY_TRANSFER'
export const receiveFertilizerInventoryTransfer = makeActionCreator(
  RECEIVE_FERTILIZER_INVENTORY_TRANSFER,
  'fertilizerInventoryTransfer',
  'error'
)

export const CLEAR_FERTILIZER_INVENTORY_TRANSFER =
  'CLEAR_FERTILIZER_INVENTORY_TRANSFER'
export const clearFertilizerInventoryTransfer = makeActionCreator(
  CLEAR_FERTILIZER_INVENTORY_TRANSFER
)

export const CLEAR_FERTILIZER_INVENTORY_TRANSFERS =
  'CLEAR_FERTILIZER_INVENTORY_TRANSFERS'
export const clearFertilizerInventoryTransfers = makeActionCreator(
  CLEAR_FERTILIZER_INVENTORY_TRANSFERS
)

export const SET_FERTILIZER_INVENTORY_TRANSFER =
  'SET_FERTILIZER_INVENTORY_TRANSFER'
export const setFertilizerInventoryTransfer = makeActionCreator(
  SET_FERTILIZER_INVENTORY_TRANSFER,
  'fertilizerInventoryTransfer'
)

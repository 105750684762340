import { FlexV2 } from '@/primitives'

import DateFilter from '../../../Shared/Filters/DateFilter'
import OrganizationsFilter from '../../../Shared/Filters/OrganizationsFilter'
import CustomersFilter from '../../../Shared/Filters/CustomersFilter'
import useMediaQuery from '@/hooks/useMediaQuery'

const SaleFilter = ({ state, dispatchState }) => {
  const isAboveBreakpoint = useMediaQuery('min-width: 1280px')
  return (
    <FlexV2 direction={isAboveBreakpoint ? 'row' : 'column'} axisGap='300'>
      <DateFilter
        state={state}
        dispatchState={dispatchState}
        stateKey={'orderDates'}
      />
      <OrganizationsFilter state={state} dispatchState={dispatchState} />
      <CustomersFilter state={state} dispatchState={dispatchState} />
    </FlexV2>
  )
}

export default SaleFilter

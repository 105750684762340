import { I18n } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import useDeviceSize from '@/hooks/useDeviceSize'
import { Box, Flex, Text } from '@/primitives'

import {
  getZonesListLoading,
  getZonesList,
  getZoneHierarchy,
  getZone,
  getOrganizationLabels,
  getLang
} from '@/reducers/selectors'

import { fetchZoneHierarchy } from '@/slices/management/hierarchy'
import { fetchOrganizationLabels } from '@/slices/management/organizationLabels'

import {
  cleanZone,
  cleanZonesList,
  fetchZonesList
} from '@/slices/management/zone'

import { fetchZone } from '@/slices/management/zone'

import ZoneUtils from '@/Util/ZoneUtils'

import Navigator from './Navigator'

function ZoneNavigator({
  organizationId,
  siteId,
  zoneId,
  onSelectZone,
  isEditable = true,
  isDisabled = false,
  style
}) {
  const dispatch = useDispatch()
  const isMobile = useDeviceSize('mobile')
  const zonesList = getZonesList()
  const zone = getZone()
  const zoneHierarchy = getZoneHierarchy()
  const isLoading = getZonesListLoading()
  const organizationLabels = getOrganizationLabels()
  const lang = getLang()

  const [sites, setSites] = useState([])
  const [zoneTiers, setZoneTiers] = useState([])
  const [selectedOrganizationId, setSelectedOrganizationId] =
    useState(organizationId)
  const [selected, setSelected] = useState([])

  useEffect(() => {
    dispatch(cleanZonesList())
    return () => {
      dispatch(cleanZonesList())
    }
  }, [])

  useEffect(() => {
    dispatch(fetchZonesList())
  }, [dispatch])

  useEffect(() => {
    //TODO: Review this, having the zoneHierarchy should be enough and the extra fetchZone should not be needed
    if (siteId) {
      dispatch(fetchZoneHierarchy({ zoneId: siteId }))
      if (!zoneId) {
        dispatch(fetchZone({ id: siteId }))
      }
    }
    if (zoneId) {
      dispatch(fetchZone({ id: zoneId }))
    }
  }, [dispatch, siteId, zoneId])

  useEffect(() => {
    if (organizationId !== selectedOrganizationId) {
      setSelectedOrganizationId(organizationId)
      dispatch(
        fetchOrganizationLabels({
          organizationId,
          lang: lang || 'en'
        })
      )
    }
  }, [organizationId])

  useEffect(() => {
    if (zonesList.length > 0) {
      const newSites = zonesList.filter(
        z => z.organizationId === organizationId && z.status === 'active'
      )
      if (organizationId !== selectedOrganizationId) {
        dispatch(cleanZone())
        setSelected([])
      }
      setZoneTiers([newSites])
      setSites(newSites)
    }
  }, [zonesList, organizationId])

  useEffect(() => {
    if (sites.length > 0 && zoneHierarchy[siteId] && zone?.id) {
      const newZoneTiers = [sites]
      const path = zone.parentPath.split('/').slice(1)
      let nextZone = zoneHierarchy[path[0]]
      path.slice(1).forEach((nextZoneId, index) => {
        if (nextZone?.children?.length > 0) {
          nextZone.children = nextZone?.children?.filter(
            z => z.status === 'active'
          )
        }
        if (nextZone?.children?.[nextZoneId]) {
          newZoneTiers[index + 1] = Object.values(nextZone.children)
          nextZone = nextZone.children[nextZoneId]
        }
      })
      if (nextZone?.children) {
        let children = Object.values(nextZone.children).filter(
          z => z.status === 'active'
        )
        if (children && children.length > 0) {
          newZoneTiers[newZoneTiers.length] = children
        }
      }
      setZoneTiers(newZoneTiers)
      setSelected(path)
    }
  }, [zone, zoneHierarchy, sites])

  const onChange = (e, index) => {
    const selectedZoneId = e.currentTarget.value
    const selectedZoneIndex = zoneTiers[index].findIndex(
      z => z.id === selectedZoneId
    )
    if (selectedZoneIndex >= 0) {
      const { id, parentId } = zoneTiers[index][selectedZoneIndex]
      dispatch(cleanZone())
      onSelectZone({ id, parentId })
    }
    const newZoneTiers = zoneTiers.splice(0, index + 1)
    setZoneTiers(newZoneTiers)
    const newSelected = selected.splice(0, index + 1)
    newSelected[index] = selectedZoneId
    setSelected(newSelected)
  }

  const getDefaultSelectString = index => {
    const prefix = I18n.get('Select')
    if (organizationLabels && Object.keys(organizationLabels).length > 0) {
      const suffix = ZoneUtils.getZoneDepthStringFromDepth(
        index,
        false,
        organizationLabels
      )
      return `${prefix} ${suffix}`
    }
    return prefix
  }

  return (
    <Flex alignCrossAxis='center' axisGap={400} style={style}>
      <Box className={'Operations__Zone__Navigator'}>
        <Flex alignCrossAxis={'center'} axisGap={400}>
          {zoneTiers.map((zones, index) => (
            <Flex key={index} direction={'row'} wrap='nowrap'>
              <Navigator
                options={zones}
                value={selected[index]}
                defaultString={getDefaultSelectString(index)}
                onChange={e => onChange(e, index)}
                isEditable={isEditable}
                isDisabled={isDisabled}
              />
              {!isMobile && index < zoneTiers.length - (isEditable ? 1 : 2) && (
                <Box
                  as='span'
                  style={{
                    marginLeft: '1em',
                    lineHeight: isEditable ? '2.5em' : 'auto'
                  }}
                >
                  /
                </Box>
              )}
            </Flex>
          ))}
        </Flex>
      </Box>
      {isLoading && <Text aria-busy='true' variant='info' tone={700} />}
    </Flex>
  )
}

export default ZoneNavigator

import React, { useEffect, useReducer, useState } from 'react'
import { I18n } from 'aws-amplify'
import { useDispatch } from 'react-redux'

import { Button, Flex, Form, Input, Label, Slot, Text } from '@/primitives'

import { Dialog, Select } from '@/elements'

import {
  sendCreateMeasurement,
  sendUpdateMeasurement,
  cleanMeasurement
} from '@/slices/deviceManagement/measurement'
import { showBanner } from '@/slices/util'

import {
  getShowBanner,
  getMeasurements,
  getNewMeasurement,
  getNewMeasurementError,
  getSelectedMeasurement,
  getUpdatedMeasurement,
  getUpdateMeasurementError,
  getMeasurementIsSaving
} from '@/reducers/selectors'

import AdminUtils from '@/Util/AdminUtils'

import { validateThresholds } from '../utils'

import Strings from '../../Strings'

const { newMeasurementSchema } = AdminUtils

const initialState = {
  id: '',
  type: 'hardware',
  description: '',
  unit: '',
  lowerThreshold: '0',
  upperThreshold: '0',
  shortName: ''
}

const reducer = (state, action) => {
  if (action?.type === 'all') {
    const { thresholds, ...rest } = action.params

    const [
      lowerThreshold = state.lowerThreshold,
      upperThreshold = state.upperThreshold
    ] = thresholds

    const newState = {
      lowerThreshold,
      upperThreshold,
      ...rest
    }
    return newState
  } else if (action?.type === 'reset') {
    return initialState
  } else {
    const { name, value } = action.params
    const newState = { ...state }
    newState[name] = value
    return newState
  }
}

const UpsertMeasurement = ({ showModal, setShowTemplateModal }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [addThresholds, setAddThresholds] = useState(false)
  const [isNew, setIsNew] = useState(true)
  const [isLoadingForm, setIsLoadingForm] = useState(true)
  const strings = Strings()
  const reduxDispatch = useDispatch()
  const banner = getShowBanner()
  const newMeasurement = getNewMeasurement()
  const newMeasurementError = getNewMeasurementError()
  const measurements = getMeasurements()
  const selectedMeasurement = getSelectedMeasurement()
  const updatedMeasurement = getUpdatedMeasurement()
  const updateMeasurementError = getUpdateMeasurementError()
  const isSaving = getMeasurementIsSaving()

  const {
    addMeasurement,
    addMeasurementSubheading,
    cancel,
    saveMeasurementDetails,
    measurementFormId,
    measurementFormType,
    measurementFormDescription,
    measurementFormShortName,
    measurementFormUnit,
    measurementFormThreshold,
    measurementFormUpperThreshold,
    measurementFormLowerThreshold,
    updateMeasurementHeading,
    updateMeasurementSubheading,
    measurementCreated,
    measurementUpdated,
    measurementFormSelectType
  } = strings

  useEffect(() => {
    if (!showModal) {
      reduxDispatch(cleanMeasurement())
    }
  }, [reduxDispatch, showModal])

  useEffect(() => {
    return () => {
      reduxDispatch(cleanMeasurement())
      if (banner.show) {
        reduxDispatch(showBanner({ show: false, message: '', type: null }))
      }
    }
  }, [reduxDispatch, banner.show])

  useEffect(() => {
    if (newMeasurementError || updateMeasurementError) {
      const errMessage = newMeasurementError ?? updateMeasurementError
      reduxDispatch(
        showBanner({ show: true, message: errMessage, type: 'error' })
      )
    }
  }, [reduxDispatch, newMeasurementError, updateMeasurementError])

  useEffect(() => {
    if (newMeasurement || updatedMeasurement) {
      setShowTemplateModal(false)

      const message = newMeasurement ? measurementCreated : measurementUpdated
      reduxDispatch(showBanner({ show: true, message, type: 'success' }))
    }
  }, [
    reduxDispatch,
    newMeasurement,
    updatedMeasurement,
    measurementCreated,
    measurementUpdated,
    setShowTemplateModal
  ])

  useEffect(() => {
    if (selectedMeasurement?.id) {
      dispatch({ type: 'all', params: selectedMeasurement })
      if (selectedMeasurement.thresholds.length > 0) {
        setAddThresholds(true)
      }
    } else {
      dispatch({ type: 'reset' })
    }

    setIsNew(selectedMeasurement?.id ? false : true)
    setIsLoadingForm(false)
  }, [selectedMeasurement])

  const onChange = e => {
    if (banner.show)
      reduxDispatch(showBanner({ show: false, message: '', type: null }))
    const { name, value } = e.target
    const params = { name, value: name === 'id' ? value.toLowerCase() : value }
    dispatch({ type: 'single', params })
  }

  function onChangeType({ value }) {
    if (banner.show)
      reduxDispatch(showBanner({ show: false, message: '', type: null }))
    const params = { name: 'type', value }
    dispatch({ type: 'single', params })
  }

  const getFieldValue = name => {
    return state[name] ?? ''
  }

  const getThresholdsArray = () => {
    const lower = getFieldValue('lowerThreshold')
    const upper = getFieldValue('upperThreshold')
    return [parseFloat(lower), parseFloat(upper)]
  }

  const onSubmitForm = async e => {
    e.preventDefault()

    const params = {
      id: getFieldValue('id').trim().toLowerCase(),
      type: getFieldValue('type').trim(),
      description: getFieldValue('description').trim(),
      unit: getFieldValue('unit').trim(),
      thresholds: addThresholds ? getThresholdsArray() : [],
      shortName: getFieldValue('shortName').trim()
    }

    const measurementsIds = measurements
      .filter(({ id }) => id !== selectedMeasurement.id)
      .map(({ id }) => id)

    try {
      await newMeasurementSchema(measurementsIds).validate(
        {
          id: params?.id,
          type: params?.type,
          description: params?.description,
          unit: params?.unit,
          shortName: params?.shortName
        },
        { abortEarly: false }
      )

      await validateThresholds(params)
      if (isNew) {
        reduxDispatch(sendCreateMeasurement(params))
      } else {
        reduxDispatch(sendUpdateMeasurement(params))
      }
    } catch (err) {
      reduxDispatch(
        showBanner({
          show: true,
          message: err?.inner[0].message,
          type: 'error'
        })
      )
    }
  }

  const onClickClose = () => {
    setShowTemplateModal(false)
  }

  const onClickAddThresholds = e => {
    setAddThresholds(!addThresholds)
  }

  return (
    <Dialog open={showModal && !isLoadingForm} onOpenChange={onClickClose}>
      <Slot name='title'>
        <Flex direction='column' style={{ marginBottom: '1em' }}>
          <Text as='h5' style={{ marginBottom: '0.5em' }}>
            {isNew ? addMeasurement : updateMeasurementHeading}
          </Text>
          <Text as='p' size={100}>
            {isNew ? addMeasurementSubheading : updateMeasurementSubheading}
          </Text>
        </Flex>
      </Slot>
      <Slot name='content'>
        <Form onSubmit={onSubmitForm}>
          <Label>
            <Text variant='page' tone={700}>
              {measurementFormId}
            </Text>
            <Input
              name='id'
              value={getFieldValue('id')}
              onChange={onChange}
              disabled={!isNew}
            />
          </Label>
          <Label>
            <Text tone='700' variant='page'>
              {measurementFormType}
            </Text>
            <Select
              placeholder={measurementFormSelectType}
              value={getFieldValue('type')}
              options={[
                { value: 'hardware', label: I18n.get('Hardware') },
                { value: 'calculation', label: I18n.get('Calculation') },
                { value: 'software', label: I18n.get('Software') }
              ]}
              onChange={onChangeType}
              isDisabled={!isNew}
            />
          </Label>
          <Label>
            <Text variant='page' tone={700}>
              {measurementFormDescription}
            </Text>
            <Input
              name='description'
              value={getFieldValue('description')}
              onChange={onChange}
            />
          </Label>
          <Label>
            <Text variant='page' tone={700}>
              {measurementFormShortName}
            </Text>
            <Input
              name='shortName'
              value={getFieldValue('shortName')}
              onChange={onChange}
            />
          </Label>
          <Label>
            <Text variant='page' tone={700}>
              {measurementFormUnit}
            </Text>
            <Input
              name='unit'
              value={getFieldValue('unit')}
              onChange={onChange}
            />
          </Label>
          <Label>
            <Text variant='page' tone={700}>
              {measurementFormThreshold}
            </Text>
            <Input
              type='checkbox'
              checked={addThresholds}
              onChange={onClickAddThresholds}
            />
          </Label>
          {addThresholds && (
            <Flex
              direction='row'
              alignMainAxis='space-between'
              alignCrossAxis='center'
              axisGap={300}
            >
              <Label>
                <Text variant='page' tone={700}>
                  {measurementFormLowerThreshold}
                </Text>
                <Input
                  type='number'
                  name='lowerThreshold'
                  value={getFieldValue('lowerThreshold')}
                  onChange={onChange}
                  placeholder='0'
                  step='any'
                />
              </Label>
              <Label style={{ marginBlockStart: 0 }}>
                <Text variant='page' tone={700}>
                  {measurementFormUpperThreshold}
                </Text>
                <Input
                  type='number'
                  name='upperThreshold'
                  value={getFieldValue('upperThreshold')}
                  onChange={onChange}
                  placeholder='0'
                  step='any'
                />
              </Label>
            </Flex>
          )}
          <Flex alignMainAxis='space-between'>
            <Button
              variant='neutral'
              onClick={onClickClose}
              disabled={isSaving}
            >
              {cancel}
            </Button>
            <Button
              type='submit'
              variant='primary'
              disabled={isSaving}
              loading={isSaving}
            >
              {saveMeasurementDetails}
            </Button>
          </Flex>
        </Form>
      </Slot>
    </Dialog>
  )
}

export default UpsertMeasurement

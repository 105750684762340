import { nanoid } from 'nanoid'
import cloneDeep from 'lodash/cloneDeep'
import clone from 'lodash/clone'

import { widgetRegistry } from '../Widgets'
import { defaultQueryOptions } from '../Widgets/Shared/Dynamic/config/defaults'
import { QueryOption } from '../Widgets/Shared/Dynamic/config/utils'
import {
  CHARTS,
  LIVE_QUERY,
  USE_RANGE_INTERVAL,
  getRanges
} from '../Widgets/Shared/Dynamic/config'

export const reactSelectStyles = {
  backgroundColor: 'var(--ctx-theme-color-page-200)',
  border: '1px solid var(--ctx-theme-color-page-400)',
  borderRadius: '2px',
  boxShadow: '0px 3px 6px var(--ctx-theme-color-info-700)'
}

/**
 * Gets the site id from a zone path
 * @param {string} zonePath
 * @returns
 */
export const getSiteIdFromZonePath = zonePath => {
  return zonePath.split('/')[0]
}

export const getMinSizeOfWidget = widgetProps => {
  const { config, defaultPosition } = widgetProps
  const chartWidget = CHARTS?.[config?.chart]
  if (chartWidget) {
    return chartWidget.minSize
  }
  let minSize = {
    xl: { minW: null, minH: null },
    lg: { minW: null, minH: null },
    md: { minW: null, minH: null }
  }
  for (let breakpoint in defaultPosition) {
    const [minW, minH] = defaultPosition[breakpoint].slice(4)
    minSize[breakpoint] = { minW, minH }
  }
  return minSize
}

export const dashboardConfigToWidgetMapper = config => {
  const widgets = new Map()

  for (let placedWidget of config) {
    const { component, options, ...widgetProps } = widgetRegistry.get(
      placedWidget.widgetId
    )
    const minSize = getMinSizeOfWidget(widgetProps)

    const position = { ...placedWidget.position }
    for (let breakpoint in position) {
      position[breakpoint][4] = minSize[breakpoint].minW
      position[breakpoint][5] = minSize[breakpoint].minH
    }

    widgets.set(placedWidget.id, {
      component: component,
      props: { ...placedWidget, options, widgetMode: 'view' }
    })
  }
  return widgets
}

export const widgetMapToDashboardConfig = widgetMap => {
  let config = []
  const widgetArray = Array.from(cloneDeep(widgetMap).values())
  for (let widget of widgetArray) {
    // options shouldn't be saved in the db
    delete widget.props.options

    config.push(widget.props)
  }
  return config
}

/**
 * Clones a zone and keeps the children property but not children's children
 * @param {Object} zone
 * @returns
 */
export const cloneFlatZone = zone => {
  let clonedZone = clone(zone)
  return clonedZone
}

export const getBreakpointPositionWithFallback = (
  positionObject,
  breakpoint
) => {
  return positionObject[breakpoint] || Object.values(positionObject)[0]
}

export const prepareWidgetToAdd = (options, state, config) => {
  const stateCopy = clone(state)
  let optionsCopy = clone(options)
  let configCopy = clone(config)
  // Prepare measurement widget
  if (optionsCopy && optionsCopy.measurement) {
    if (!optionsCopy.queryOptionsList) {
      // eslint-disable-next-line no-console
      console.error(
        "'Options.measurement' is set, but 'queryOptionsList' is missing. Please add it."
      )
      optionsCopy.queryOptionsList = defaultQueryOptions
    }
    if (stateCopy.siteId === stateCopy.currentZone.id) {
      const option0 = optionsCopy.queryOptionsList[0]

      if (option0 instanceof QueryOption === false) {
        throw Error(
          `queryOptionsList should be an array of QueryOption. 
          This usually happens when doing [queryOptionsList] instead of [liveQuery, datasetQuery].`
        )
      }
      const filteredQueryOptionsList = optionsCopy.queryOptionsList.filter(
        q => q.value !== LIVE_QUERY
      )

      if (filteredQueryOptionsList.length === 0) {
        throw Error(
          'Seems the widget is live only. Consider adding "site" to visibility.'
        )
      }

      const query = filteredQueryOptionsList[0].value
      const chart = filteredQueryOptionsList[0].options[0].value
      const range = filteredQueryOptionsList[0].options[0].range[0]
      let interval = filteredQueryOptionsList[0].options[0].interval
      if (interval?.length === 1 && interval[0] === USE_RANGE_INTERVAL) {
        interval = getRanges(range)?.intervals[0]
      } else {
        interval = interval && interval.length > 0 ? interval[0] : null
      }
      configCopy = { ...configCopy, query, chart, range, interval }
      optionsCopy = {
        ...optionsCopy,
        queryOptionsList: filteredQueryOptionsList
      }
    }
  }

  return {
    options: optionsCopy,
    config: configCopy
  }
}

export const getDeferableCommit = widget => {
  const { widgetId, config } = widget
  // Add rules for widgets that shouldn't be saved on add

  if (widgetId === 'topTenHarvestVariety' && !config.produceId) {
    return true
  }

  return false
}

const buildSensorWidgets = (device, deviceTypes, currentBreakpoint) => {
  if (!widgetRegistry.initialized) {
    throw Error('Widget registry not initialized')
  }
  let measurements = device?.config?.read_measurementType ?? []

  if (measurements.length === 0) {
    const deviceType = deviceTypes.find(
      deviceType => deviceType.id === device.sensorType
    )
    if (!deviceType?.measurements) {
      // eslint-disable-next-line no-console
      console.error('No measurements found for device type', deviceType)
      return []
    }
    measurements = deviceType.measurements
  }

  const widgets = new Map()
  let lastX = 0
  let lastY = 0
  let widgetIdx = 0
  for (let measurement of measurements) {
    const id = measurement.replace(/(_\w)/g, str => str[1].toUpperCase())
    let widget = null
    try {
      widget = widgetRegistry.get(id)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Widget not found', id)
    }

    if (widget) {
      let position =
        widget.defaultPosition?.[currentBreakpoint] ??
        Object.values(widget.defaultPosition)[0]

      const chartMinSize =
        CHARTS?.[widget.config?.chart]?.minSize?.[currentBreakpoint]

      if (chartMinSize) {
        position = [
          position[0],
          position[1],
          chartMinSize.minW,
          chartMinSize.minH,
          chartMinSize.minW,
          chartMinSize.minH
        ]
      }

      const [w, h, minW, minH] = position.slice(2)

      lastX = widgetIdx === 0 ? 0 : lastX + w

      // this is also a column
      if (lastX >= 8) {
        lastX = 0
        lastY += 1
      }

      const sensorWidget = {
        id: nanoid(4),
        widgetId: widget.widgetId,
        config: cloneDeep(widget.config),
        options: cloneDeep(widget.options),
        position: {
          [currentBreakpoint]: [lastX, lastY, w, h, minW, minH]
        }
      }

      widgets.set(sensorWidget.id, {
        component: widget.component,
        props: sensorWidget
      })
      widgetIdx += 1
    }
  }

  return widgets
}

export const createSensorDashboard = (
  userId,
  device,
  deviceTypes,
  currentBreakpoint
) => {
  const sensorDashboard = {
    userId,
    dashboardId: nanoid(),
    context: 'sensor',
    isDefault: true,
    config: [],
    version: 0
  }

  const payload = {
    currentDashboard: sensorDashboard,
    widgets: buildSensorWidgets(device, deviceTypes, currentBreakpoint)
  }

  return payload
}

export const isSome404 = is404 => {
  return Object.values(is404).some(is404 => is404)
}

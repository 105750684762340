export const SET_STATE = 'produce-threshold-set-state'
export const UPDATE = 'produce-threshold-update'
export const UPDATE_MEASUREMENTS = 'produce-threshold-update-measurements'

export const formInitalState = {
  name: '',
  measurements: []
}

export const formReducer = (state, action) => {
  if (action.type === UPDATE) {
    return { ...state, [action.name]: action.value }
  } else if (action.type === SET_STATE) {
    return action.state
  } else if (action.type === UPDATE_MEASUREMENTS) {
    return { ...state, measurements: action.measurements }
  }
  return state
}

import { I18n } from 'aws-amplify'
import { object, string } from 'yup'
import { useEffect, useState, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { createArea, requestArea, updateArea } from '@/actions/operations/area'

import { requestAllCountries } from '@/actions/operations/country'
import { requestAllRegions } from '@/actions/operations/region'
import { requestAllCities } from '@/actions/operations/city'

import {
  getOperationsArea,
  getOperationsAllCountries,
  getOperationsAllRegions,
  getOperationsAllCities,
  getOperationsLoading,
  getOperationsError
} from '@/reducers/selectors'

import { Flex, Form, Input, Label, Select, Text, Loader } from '@/primitives'

import InputError from '@/elements/InputError'

import OrganizationInput from '../../Shared/Organization/Input'

import { getValidationErrorMap } from '@/Util/GeneralUtils'
import HeaderV2 from '@/components/Operations/Shared/HeaderV2'
import history from '../../../../history'

import { formInitalState, formReducer } from './state'

const SCHEMA = object({
  organizationId: string().required(I18n.get('An organization is required.')),
  name: string().required(I18n.get('Name is required.')),
  countryId: string().required(I18n.get('A country must be selected.')),
  regionId: string().required(I18n.get('A region must be selected.')),
  cityId: string().required(I18n.get('A city must be selected.'))
})

function AreaForm({ state, dispatchState, modulePath }) {
  const dispatch = useDispatch()
  const { itemId } = useParams()

  const area = getOperationsArea()
  const countries = getOperationsAllCountries()
  const regions = getOperationsAllRegions()
  const cities = getOperationsAllCities()
  const loading = getOperationsLoading()
  const error = getOperationsError()

  const [formState, dispatchFormState] = useReducer(
    formReducer,
    formInitalState
  )

  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)

  useEffect(() => {
    dispatch(requestAllCountries())
    dispatch(requestAllRegions())
    dispatch(requestAllCities())
  }, [dispatch])

  useEffect(() => {
    if (itemId && area.id !== itemId) {
      dispatch(requestArea({ areaId: itemId }))
    }
  }, [dispatch, itemId, area?.id])

  useEffect(() => {
    if (area?.id && area.id === itemId) {
      dispatchFormState({ type: 'set-state', state: area })
    }
  }, [area, itemId])

  useEffect(() => {
    if (!itemId && state.organizations.length === 1) {
      dispatchFormState({
        type: 'update',
        name: 'organizationId',
        value: state.organizations[0]
      })
    }
  }, [state.organizations, itemId])

  useEffect(() => {
    if (!itemId && state.countries.length === 1) {
      dispatchFormState({
        type: 'update',
        name: 'countryId',
        value: state.countries[0]
      })
    }
  }, [state.countries, itemId])

  useEffect(() => {
    if (!itemId && state.regions.length === 1) {
      dispatchFormState({
        type: 'update',
        name: 'regionId',
        value: state.regions[0]
      })
    }
  }, [state.regions, itemId])

  useEffect(() => {
    if (!itemId && state.cities.length === 1) {
      dispatchFormState({
        type: 'update',
        name: 'cityId',
        value: state.cities[0]
      })
    }
  }, [state.cities, itemId])

  useEffect(() => {
    if (waiting && !loading && !error) {
      history.replace(modulePath)
    }
  }, [waiting, loading, error, modulePath])

  const onSubmit = async e => {
    e?.preventDefault()
    try {
      const payload = await SCHEMA.validate(formState, { abortEarly: false })
      setWaiting(true)

      if (itemId) {
        payload.areaId = itemId
        dispatch(updateArea(payload))
      } else {
        dispatch(createArea(payload))
      }
    } catch (err) {
      setErrors(getValidationErrorMap(err))
    }
  }

  const onChange = e => {
    let { name, value } = e.currentTarget
    dispatchFormState({ type: 'update', name, value })
  }

  const handleOrganizationInput = organizationId => {
    dispatchFormState({
      type: 'set-state',
      state: {
        ...formState,
        organizationId,
        countryId: '',
        regionId: '',
        cityId: ''
      }
    })
  }

  const onChangeCountry = e => {
    dispatchFormState({
      type: 'set-state',
      state: {
        ...formState,
        countryId: e.currentTarget.value,
        regionId: '',
        cityId: ''
      }
    })
  }

  const onChangeRegion = e => {
    dispatchFormState({
      type: 'set-state',
      state: {
        ...formState,
        regionId: e.currentTarget.value,
        cityId: ''
      }
    })
  }

  const filterCountries = () => {
    return countries.filter(c => c.organizationId === formState.organizationId)
  }

  const filterRegions = () => {
    return regions.filter(r => r.countryId === formState.countryId)
  }

  const filterCities = () => {
    return cities.filter(c => c.regionId === formState.regionId)
  }

  const regionOptions = filterRegions()
  const cityOptions = filterCities()

  return (
    <Form className='Operations__Form'>
      <HeaderV2
        title={I18n.get('Manage Area')}
        backPath={modulePath}
        buttonText={I18n.get('Save Area')}
        buttonCallback={onSubmit}
        buttonIcon={'save'}
      />
      <Loader isLoading={loading}>
        <OrganizationInput
          fieldName={'organizationId'}
          organizationId={formState.organizationId}
          handleInput={handleOrganizationInput}
          errors={errors}
        />
        <Flex axisGap={300} className='Operations__Form__Fields'>
          <Label>
            <Text variant='page' tone={700}>
              {I18n.get('Country')}
            </Text>
            <Select
              className='Operations__Form__Select'
              name='countryId'
              value={formState.countryId}
              onChange={onChangeCountry}
            >
              <option default value=''>
                {I18n.get('Please select')}
              </option>
              {filterCountries().map(({ name, id }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Select>
            <InputError error={errors?.countryId} />
          </Label>
          <Label>
            <Text variant='page' tone={700}>
              {I18n.get('Region')}
            </Text>
            <Select
              className='Operations__Form__Select'
              name='regionId'
              value={formState.regionId}
              onChange={onChangeRegion}
              disabled={regionOptions.length === 0}
            >
              <option default value=''>
                {I18n.get('Please select')}
              </option>
              {regionOptions.map(({ name, id }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Select>
            <InputError error={errors?.regionId} />
          </Label>
          <Label>
            <Text variant='page' tone={700}>
              {I18n.get('City')}
            </Text>
            <Select
              className='Operations__Form__Select'
              name='cityId'
              value={formState.cityId}
              onChange={onChange}
              disabled={cityOptions.length === 0}
            >
              <option default value=''>
                {I18n.get('Please select')}
              </option>
              {cityOptions.map(({ name, id }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Select>
            <InputError error={errors?.cityId} />
          </Label>
        </Flex>
        <Flex axisGap={300} className='Operations__Form__Fields'>
          <Label>
            <Text variant='page' tone={700}>
              {I18n.get('Name')}
            </Text>
            <Input value={formState.name} name='name' onChange={onChange} />
            <InputError error={errors?.name} />
          </Label>
        </Flex>
      </Loader>
    </Form>
  )
}

export default AreaForm

import { Children, useState } from 'react'
import { DateTime } from 'luxon'
import { useDispatch } from 'react-redux'
import { I18n } from 'aws-amplify'

import { Cell, FlexV2, Symbol, Item, Row, Text, Button } from '@/primitives'
import { Dropdown } from '@/elements'
import {
  sendDeleteDevice,
  sendRegenerateDeviceFirmware,
  setDevice,
  sendUnenrollSupplierDevice
} from '@/slices/management/device'
import { sendCreateNvsDump } from '@/slices/calibration/nvs'

import {
  getCurrentUser,
  getDeviceTypes,
  getZoneHierarchy
} from '@/reducers/selectors'

import { useAuth } from '@/contexts/auth-context'

import { getIsGodMode } from '@/Util/PermissionUtils'
import { getSensorTypeIcon } from '@/Util/DeviceUtils'
import {
  AVAILABLE_FEATURE_FLAGS,
  ENABLE_DELETE_SENSOR,
  hasFeatureFlagEnabled
} from '@/Util/FeatureFlagsUtils'

import Strings from '../../Strings'

import DownloadModal from './DownloadModal'
import DeleteDeviceModal from './DeleteModal'
import UnenrollModal from './UnenrollModal'

function findDeviceTypeName(deviceTypes, type) {
  return deviceTypes?.find(({ id }) => id === type)?.name ?? type
}

const ThresholdTableRow = ({
  basePath,
  device,
  setShowDeviceFormModal,
  columnNames,
  showEditOptions
}) => {
  const {
    deviceEdit,
    deviceRemove,
    deviceUnenroll,
    tableRowSensorTypeModbus,
    tableRowSensorTypeIoT,
    deviceRegenerate,
    firmwareDownload,
    requestNvsDump,
    noTimestamp,
    noTagSet
  } = Strings()

  const dispatch = useDispatch()
  const auth = useAuth()

  const currentUser = getCurrentUser()
  const deviceTypes = getDeviceTypes()
  const zoneHierarchy = getZoneHierarchy()
  const isGodMode = getIsGodMode(currentUser)

  const [modalOpen, setModalOpen] = useState(false)
  const [modalUnenrollOpen, setModalUnenrollOpen] = useState(false)
  const [showDownloadModal, setShowDownloadModal] = useState(false)

  function onDeviceRegenerate(e) {
    e.preventDefault()
    dispatch(sendRegenerateDeviceFirmware({ deviceId: device.id }))
  }

  function onDeviceEdit(e) {
    e.preventDefault()
    dispatch(setDevice(device))
    setShowDeviceFormModal(true)
  }

  function onRequestNvsDump(e) {
    e.preventDefault()
    dispatch(
      sendCreateNvsDump({
        sensorId: device.id,
        userId: auth.getCognitoId(),
        isGreengrass: device.isGreengrass,
        deviceType: device.sensorType
      })
    )
  }

  function triggerModal(e) {
    e?.preventDefault()
    setModalOpen(!modalOpen)
  }

  function onAcceptDeleteDevice() {
    dispatch(
      sendDeleteDevice({
        id: device.id,
        zoneId: device.zoneId
      })
    )
    triggerModal()
  }

  function onCancelDeleteDevice() {
    triggerModal()
  }

  function toggleDownloadModal() {
    setShowDownloadModal(!showDownloadModal)
  }

  function triggerModalUnenroll(e) {
    e?.preventDefault()
    setModalUnenrollOpen(!modalUnenrollOpen)
  }

  function onAcceptUnenrollDevice() {
    dispatch(
      sendUnenrollSupplierDevice({
        deviceId: device.id
      })
    )
    triggerModalUnenroll()
  }

  function onCancelUnenrollDevice() {
    triggerModalUnenroll()
  }

  const iotOptions = [
    { name: requestNvsDump, handleClick: onRequestNvsDump },
    { name: deviceRegenerate, handleClick: onDeviceRegenerate },
    { name: firmwareDownload, handleClick: toggleDownloadModal }
  ]

  const enableDeleteSensor = hasFeatureFlagEnabled(
    currentUser,
    AVAILABLE_FEATURE_FLAGS[ENABLE_DELETE_SENSOR],
    Object.keys(zoneHierarchy).length > 0 ? zoneHierarchy.organizationId : null
  )

  return (
    <Row>
      <Cell columnName={columnNames[0]}>
        {modalOpen && (
          <DeleteDeviceModal
            showModal={modalOpen}
            onAccept={onAcceptDeleteDevice}
            onCancel={onCancelDeleteDevice}
          />
        )}
        {modalUnenrollOpen && (
          <UnenrollModal
            showModal={modalUnenrollOpen}
            onAccept={onAcceptUnenrollDevice}
            onCancel={onCancelUnenrollDevice}
          />
        )}
        <FlexV2 axisGap={400}>
          <Symbol
            tone={900}
            variant={'page'}
            name={getSensorTypeIcon(device.sensorType)}
          />{' '}
          <Text variant='page' tone={900}>
            {device.name}
          </Text>
        </FlexV2>
      </Cell>
      <Cell columnName={columnNames[1]}>
        <Text truncate={40} variant='page' tone={device?.tag ? 900 : 600}>
          {device?.tag?.length > 0 ? device?.tag : noTagSet}
        </Text>
      </Cell>
      <Cell columnName={columnNames[2]}>
        <Text variant='page' tone={900}>
          {findDeviceTypeName(deviceTypes, device.sensorType)}
        </Text>
      </Cell>
      <Cell columnName={columnNames[3]}>
        <Text variant='page' tone={900}>
          {device.isIoT ? tableRowSensorTypeIoT : tableRowSensorTypeModbus}
        </Text>
      </Cell>

      <Cell columnName={columnNames[4]}>
        <Text variant='page' tone={900}>
          {device.notInUse ? I18n.get('No') : I18n.get('Yes')}
        </Text>
      </Cell>

      <Cell columnName={columnNames[5]}>
        <Text variant='page' tone={900}>
          {device.createdTimestamp
            ? DateTime.fromSeconds(device.createdTimestamp).toLocaleString()
            : noTimestamp}
        </Text>
      </Cell>

      {showEditOptions && (
        <Cell columnName={columnNames[5]}>
          <FlexV2 axisGap={400} wrap='wrap' alignCrossAxis='center'>
            {(enableDeleteSensor || isGodMode) && (
              <Button variant={'error'} size={'small'} onClick={triggerModal}>
                {deviceRemove}
              </Button>
            )}

            {device?.supplierDevice?.enrolled && (
              <Button
                variant={'danger'}
                size={'small'}
                onClick={triggerModalUnenroll}
              >
                {deviceUnenroll}
              </Button>
            )}

            <Button variant={'page'} size={'small'} onClick={onDeviceEdit}>
              {deviceEdit}
            </Button>

            {device?.isIoT && iotOptions.length > 0 && (
              <Dropdown
                menuAlign={'end'}
                triggerIcon='settings'
                cursorActivated={true}
              >
                {Children.toArray(
                  iotOptions.map(({ name, handleClick }) => (
                    <Item onClick={handleClick}>{name}</Item>
                  ))
                )}
              </Dropdown>
            )}
          </FlexV2>
          <DownloadModal
            device={device}
            showModal={showDownloadModal}
            toggleModal={toggleDownloadModal}
          />
        </Cell>
      )}
    </Row>
  )
}

export default ThresholdTableRow

import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'

import { getMaxDateRange } from './DateUtils'
import { INTERVALS, INTERVAL_MAX_LIMITS } from './config'

export const getMostSpecificZoneFilter = zoneFilter =>
  zoneFilter.sensorId ??
  zoneFilter.subzoneId ??
  zoneFilter.zoneId ??
  zoneFilter.roomId ??
  zoneFilter.facilityId ??
  zoneFilter.siteId

export const findDuplicateZoneFilterIndices = arr => {
  const duplicateIndices = []
  const observedItems = []

  for (let i = 0; i < arr.length; i++) {
    const item = arr[i]
    const relevantProperties = pick(item, [
      'siteId',
      'facilityId',
      'roomId',
      'zoneId',
      'subzoneId',
      'sensorId',
      'organizationId'
    ])

    const duplicateIndex = observedItems.findIndex(observedItem =>
      isEqual(observedItem, relevantProperties)
    )

    if (duplicateIndex !== -1) {
      duplicateIndices.push(duplicateIndex, i)
    }

    observedItems.push(relevantProperties)
  }

  // Remove duplicate indices if any
  return [...new Set(duplicateIndices)]
}

export const findDuplicateDateFilterIndices = arr => {
  const duplicateIndices = []
  const observedItems = []

  for (let i = 0; i < arr.length; i++) {
    const item = arr[i]

    const relevantProperties = pick(item, ['fromDate', 'toDate'])

    const duplicateIndex = observedItems.findIndex(observedItem =>
      isEqual(observedItem, relevantProperties)
    )

    if (duplicateIndex !== -1) {
      duplicateIndices.push(duplicateIndex, i)
    }

    observedItems.push(relevantProperties)
  }

  // Remove duplicate indices if any
  return [...new Set(duplicateIndices)]
}

export function getFilteredIntervals(data) {
  const dateDiff = data.toDate - data.fromDate
  return INTERVALS.filter(i => {
    return dateDiff <= INTERVAL_MAX_LIMITS[i]
  })
}

export function getDateRangeFilteredIntervals(dateFilters) {
  const maxDateRange = getMaxDateRange(dateFilters)

  const dateFilter = dateFilters.filter(
    item => item.dateRange === maxDateRange
  )[0]

  const filteredIntervals = getFilteredIntervals(dateFilter)

  return filteredIntervals
}

export const createZoneFilterObject = zoneFilter => ({
  organizationId: zoneFilter.organizationId ?? null,
  siteId: zoneFilter.siteId ?? null,
  facilityId: zoneFilter.facilityId ?? null,
  roomId: zoneFilter.roomId ?? null,
  zoneId: zoneFilter.zoneId ?? null,
  subzoneId: zoneFilter.subzoneId ?? null,
  sensorId: zoneFilter.sensorId ?? null
})

export const buildZoneFilterFromHierarchy = (
  zonesHierarchy,
  zoneId,
  path = []
) => {
  if (path.length === 0 && zonesHierarchy?.organizationId) {
    path.push(zonesHierarchy.organizationId)
  }

  if (zonesHierarchy?.id === zoneId) {
    let levels = [
      'organizationId',
      'siteId',
      'facilityId',
      'roomId',
      'zoneId',
      'subzoneId'
    ]

    let result = {}

    path.push(zoneId)
    path.forEach((id, index) => {
      if (levels[index]) {
        result[levels[index]] = id
      }
    })

    return createZoneFilterObject(result)
  }

  if (!zonesHierarchy?.children) {
    return null
  }

  for (const childId in zonesHierarchy?.children) {
    const child = zonesHierarchy.children[childId]
    const newPath = [...path, zonesHierarchy.id]
    const result = buildZoneFilterFromHierarchy(child, zoneId, newPath)

    if (result) {
      return createZoneFilterObject(result)
    }
  }

  return null
}

export const getSidebarZoneFilterFromLocationSelector = locationSelector => {
  const zoneFilter = {
    organizationId: locationSelector.get('organization')?.id || null,
    siteId: locationSelector.get('site')?.id || null,
    facilityId: locationSelector.get('facility')?.id || null,
    roomId: locationSelector.get('room')?.id || null,
    zoneId: locationSelector.get('zone')?.id || null,
    subzoneId: locationSelector.get('subzone')?.id || null,
    sensorId: locationSelector.get('device')?.id || null
  }
  return zoneFilter
}

import { Flex } from '../../../../../primitives'

import OrganizationsFilter from '../../../Shared/Filters/OrganizationsFilter'
import EntriesFilter from '../../../Shared/Filters/EntriesFilter'

import { SUPPLIER_STATUSES } from '../utils'

import Strings from '../Strings'

const TableFilter = ({ state, dispatchState }) => {
  const strings = Strings()
  const statuses = Object.entries(SUPPLIER_STATUSES(strings))

  return (
    <Flex direction='row' axisGap='300' style={{ whiteSpace: 'nowrap' }}>
      <OrganizationsFilter state={state} dispatchState={dispatchState} />
      <EntriesFilter
        state={state}
        dispatchState={dispatchState}
        stateKey='statuses'
        entries={statuses}
        defaultText={strings.allStatuses}
      />
    </Flex>
  )
}

export default TableFilter

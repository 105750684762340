import { useState } from 'react'
import { I18n } from 'aws-amplify'
import classNames from 'classnames'

import useIsRtl from '@/hooks/useIsRtl'
import { Button, Flex, Slot, Text } from '@/primitives'
import Dialog from '@/elements/Dialog'

import { useDashboard } from '../context'
import { ADD_WIDGET, SET_IS_TOOLBOX_OPEN } from '../state'
import { Strings } from '../string'

import ToolboxTree from './Tree'

import './index.scss'

const getAddWidgetButtonLabel = selected => {
  if (!selected) {
    return I18n.get('Select a widget')
  }
  let title = selected.title

  if (typeof selected.title === 'function') {
    title = selected.title()
  }

  return `${I18n.get('Add')} ${title}`
}

export default function Toolbox() {
  const isRtl = useIsRtl()
  const { state, dispatchState } = useDashboard()
  const strings = Strings()

  const [selected, setSelected] = useState(null)

  const handleDialogClose = () => {
    dispatchState({
      type: SET_IS_TOOLBOX_OPEN,
      payload: { isOpen: false }
    })
  }

  const handleAddWidget = () => {
    if (selected) {
      dispatchState({
        type: ADD_WIDGET,
        payload: { widget: selected }
      })
    }
  }

  return (
    <Dialog
      className={classNames('DesktopDashboard__Toolbox', isRtl ? 'rtl' : null)}
      type='offcanvas'
      closeOutside={false}
      open={state.isToolboxOpen}
      onOpenChange={handleDialogClose}
    >
      <Slot name='title'>
        <Flex
          className='DesktopDashboard__Toolbox__Title'
          direction='column'
          axisGap={200}
        >
          <Text size={400} fontWeight={400}>
            {strings.toolbox}
          </Text>
          <Text size={200} as='sub' variant='page' tone={800}>
            {strings.chooseToolboxItem}
          </Text>
        </Flex>
      </Slot>
      <Slot name='content'>
        <ToolboxTree selected={selected} setSelected={setSelected} />
      </Slot>
      <Slot name='actions'>
        <Flex alignMainAxis='space-between' className='Actions'>
          <Button
            variant='page'
            className='Actions__Cancel'
            size='small'
            onClick={handleDialogClose}
          >
            {I18n.get('Cancel')}
          </Button>
          <Button
            variant='primary'
            size='small'
            disabled={!selected?.widgetId}
            onClick={handleAddWidget}
          >
            {getAddWidgetButtonLabel(selected)}
          </Button>
        </Flex>
      </Slot>
    </Dialog>
  )
}

import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Dialog } from '@/elements'
import { Slot, Button, Text, Flex, Box, Loader, Icon } from '@/primitives'

import { fetchLocation } from '@/slices/location'
import { getLocation, getFetchingLocation } from '@/reducers/selectors'
import Strings from '../../../Strings'

const strings = Strings()

async function handlePermission(permission, setPermission) {
  const response = await navigator.permissions.query({ name: 'geolocation' })
  if (permission !== response?.state) setPermission(response?.state)
}

const UpdateCoordinatesForm = ({
  isVisible,
  hideForm,
  setCoordinates,
  locationUpdated,
  zoneId
}) => {
  const dispatch = useDispatch()

  const fetchingLocation = getFetchingLocation()
  const location = getLocation() ?? {}

  const [latitude, setLatitude] = useState(null)
  const [longitude, setLongitude] = useState(null)
  const [permission, setPermission] = useState(null)
  const [requireUpdate, setRequireUpdate] = useState(false)

  useEffect(() => {
    if (locationUpdated) setRequireUpdate(true)
  }, [locationUpdated, zoneId])

  handlePermission(permission, setPermission)

  if (!fetchingLocation && location) {
    if (location?.latitude !== latitude) setLatitude(location?.latitude)
    if (location?.longitude !== longitude) setLongitude(location?.longitude)
  }

  function onClickRefetch() {
    dispatch(
      fetchLocation({
        enableHighAccuracy: true,
        timeout: 15000, // 15 seconds
        maximumAge: 0
      })
    )
  }

  function onClickApply() {
    setCoordinates(latitude, longitude)
    setRequireUpdate(false)
    hideForm()
  }

  function insertNote(text) {
    return (
      <Flex direction='row' wrap='nowrap' style={{ marginTop: '1.2rem' }}>
        <Text variant='danger' tone={800}>
          <Icon
            name='warning'
            variant='danger'
            tone={800}
            size={300}
            style={{ marginBottom: '-4px', marginInlineEnd: '0.4rem' }}
          />
          {text}
        </Text>
      </Flex>
    )
  }

  return (
    <Dialog open={isVisible} onOpenChange={hideForm} type='modal'>
      <Slot name='title'>
        <Text as='h4' style={{ marginBottom: '0' }}>
          {strings.getCoordinates}
        </Text>
      </Slot>
      <Slot name='content'>
        <Text variant='page' tone={800}>
          {strings.getCoordinatesDescription}
        </Text>
        {requireUpdate && insertNote(strings.locationChanged)}
        {permission &&
          permission === 'denied' &&
          insertNote(strings.locationDenied)}
        <Loader isLoading={fetchingLocation}>
          <Flex axisGap={500} style={{ marginTop: '2rem' }}>
            <Box style={{ flexGrow: '1', flexBasis: '0' }}>
              <Text>{strings.locationFormLatitude}</Text>
              <Box style={{ paddingTop: '0.5rem' }}>
                <Text size={300} fontWeight={500}>
                  {latitude ?? strings.unavailable}
                </Text>
              </Box>
            </Box>
            <Box style={{ flexGrow: '1', flexBasis: '0' }}>
              <Text>{strings.locationFormLongitude}</Text>
              <Box style={{ paddingTop: '0.5rem' }}>
                <Text size={300} fontWeight={500}>
                  {longitude ?? strings.unavailable}
                </Text>
              </Box>
            </Box>
          </Flex>
          <Button
            iconBefore='refresh'
            onClick={onClickRefetch}
            disabled={fetchingLocation}
            variant='info'
            style={{ margin: '2.5rem 0 0' }}
          >
            {strings.refetchLocation}
          </Button>
        </Loader>
      </Slot>
      <Slot name='actions'>
        <Flex axisGap={400} alignMainAxis='space-between'>
          <Button variant='error' onClick={hideForm}>
            {strings.cancel}
          </Button>
          <Button onClick={onClickApply} variant='primary'>
            {strings.useCoordinates}
          </Button>
        </Flex>
      </Slot>
    </Dialog>
  )
}

export default UpdateCoordinatesForm

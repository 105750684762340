import { Fragment, useCallback, useEffect, useState } from 'react'
import { I18n } from 'aws-amplify'
import { useDispatch } from 'react-redux'

import { requestCountries } from '@/actions/operations/country'

import {
  getCurrentUser,
  getCurrentUserOrganizations,
  getOperationsCountries,
  getOperationsCountriesCount,
  getOperationsLoading
} from '@/reducers/selectors'

import {
  Flex,
  Button,
  Cell,
  Text,
  Column,
  Row,
  Slot,
  Table,
  Loader
} from '@/primitives'

import { Pagination } from '@/elements'

import OrganizationsFilter from '../../Shared/Filters/OrganizationsFilter'
import HeaderV2 from '@/components/Operations/Shared/HeaderV2'

import history from '../../../../history'

import {
  hasEditPermissions,
  RESOURCE_TYPE_OPERATIONS,
  RESOURCE_TYPE_OPERATIONS_MANAGEMENT
} from '@/Util/PermissionUtils'

function CountryTable({ state, dispatchState, modulePath }) {
  const dispatch = useDispatch()

  const currentUser = getCurrentUser()
  const organizations = getCurrentUserOrganizations()
  const countries = getOperationsCountries()
  const countriesCount = getOperationsCountriesCount()
  const loading = getOperationsLoading()

  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(9)
  const [tableState, setTableState] = useState(state)
  const [canEdit, setCanEdit] = useState(false)
  const [showOrganization, setShowOrganization] = useState(false)
  const [fetchCalled, setFetchCalled] = useState(false)

  const fetchCountries = useCallback(
    page => {
      if (state.organizations.length > 0) {
        dispatch(
          requestCountries({
            filter: {
              organizationId: state.organizations
            },
            limit: pageSize,
            offset: page - 1
          })
        )
      }
      setFetchCalled(true)
    },
    [state, pageSize, dispatch]
  )

  const changePage = useCallback(
    newPage => {
      dispatchState({ page: newPage })
      setFetchCalled(false)
    },
    [dispatchState]
  )

  useEffect(() => {
    if (currentUser.userName) {
      setCanEdit(
        hasEditPermissions(
          currentUser,
          RESOURCE_TYPE_OPERATIONS,
          RESOURCE_TYPE_OPERATIONS_MANAGEMENT
        )
      )
    }
    if (organizations.length > 1) {
      setShowOrganization(true)
    }
  }, [currentUser, organizations])

  useEffect(() => {
    if (
      tableState.organizations !== state.organizations ||
      tableState.page !== state.page
    ) {
      if (!fetchCalled) {
        fetchCountries(state.page)
      }
    }
  }, [fetchCountries, state, tableState, fetchCalled])

  useEffect(() => {
    setTableState({ ...state })
  }, [state])

  const onCreate = () => history.push(`${modulePath}/create`)

  const onEdit = id => history.push(`${modulePath}/update/${id}`)

  const onRegions = (organizationId, id) => {
    dispatchState({
      state: {
        organizationId: organizationId,
        countryId: id
      }
    })
    history.push(`${modulePath}/regions`)
  }

  const getOrganizationName = organizationId => {
    const organization = organizations.find(org => org.id === organizationId)
    return organization ? organization.name : null
  }

  return (
    <Fragment>
      <HeaderV2
        title={I18n.get('Manage Countries')}
        buttonIcon={'add'}
        buttonText={I18n.get('Add Country')}
        buttonCallback={onCreate}
        showButton={canEdit}
      >
        <OrganizationsFilter state={state} dispatchState={dispatchState} />
      </HeaderV2>
      <Loader isLoading={loading}>
        {countriesCount === 0 && (
          <Flex className={'Operations__Table__Empty'}>
            <Text>{I18n.get('No entries')}</Text>
          </Flex>
        )}
        {countriesCount > 0 && (
          <Table aria-colcount='2' className='Operations__Table'>
            <Slot name='head'>
              <Row>
                {showOrganization && (
                  <Column>{I18n.get('Organization')}</Column>
                )}
                <Column>{I18n.get('Name')}</Column>
                <Column>{I18n.get('Dialing Code')}</Column>
                <Column>{I18n.get('Currency')}</Column>
                <Column></Column>
              </Row>
            </Slot>
            <Slot name='body'>
              {countries.map(
                ({
                  id,
                  organizationId,
                  name,
                  currency,
                  isoCode,
                  dialingCode
                }) => (
                  <Row key={id}>
                    {showOrganization && (
                      <Cell columnName={I18n.get('Organization')}>
                        {getOrganizationName(organizationId)}
                      </Cell>
                    )}
                    <Cell columnName={I18n.get('Name')}>
                      {name}
                      <span>{isoCode}</span>
                    </Cell>
                    <Cell columnName={I18n.get('Dialing Code')}>
                      {'+'}
                      {dialingCode}
                    </Cell>
                    <Cell columnName={I18n.get('Currency')}>
                      {currency.name} {`(${currency.code})`}
                    </Cell>
                    <Cell className='Operations__Table__Column__Actions'>
                      {canEdit && (
                        <Button
                          variant='info'
                          size='small'
                          iconBefore='edit'
                          onClick={() => onEdit(id)}
                        />
                      )}
                      <Button
                        variant='info'
                        size='small'
                        iconBefore='edit_note'
                        onClick={() => onRegions(organizationId, id)}
                      >
                        <Text fontWeight={700} textTransform='uppercase'>
                          {I18n.get('Regions')}
                        </Text>
                      </Button>
                    </Cell>
                  </Row>
                )
              )}
            </Slot>
          </Table>
        )}
        {countriesCount > pageSize && (
          <Pagination
            type='simple'
            totalItems={countriesCount}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            page={tableState.page}
            setPage={changePage}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        )}
      </Loader>
    </Fragment>
  )
}

export default CountryTable

import { Fragment, useEffect } from 'react'

import { Code, Flex, Text, Slot, Box, Input, Label } from '@/primitives'

import { Dialog, DiffViewer } from '@/elements'
import useOrgLabel from '@/hooks/useOrgLabel'

import Strings, {
  ActionStrings,
  ActorTypeStrings,
  ServiceStrings
} from '../Strings'

import { formatAuditTimestamp } from '../utils'

import './index.scss'
import useColorScheme from '@/hooks/useColorScheme'

const LogLabel = ({ label, value }) => (
  <Label>
    <Text tone='700' variant='page'>
      {label}:
    </Text>
    <Input readOnly={true} disabled={true} value={value} />
  </Label>
)

export default function LogInspector({ auditLog, showModal, onClose }) {
  const serviceStrings = ServiceStrings()
  const actionStrings = ActionStrings()
  const actorTypeStrings = ActorTypeStrings()
  const strings = Strings()
  const { site } = useOrgLabel(['site'])

  const {colorScheme} = useColorScheme()

  const {
    actorId,
    timestamp,
    service,
    context,
    action,
    event,
    organization,
    zone
  } = auditLog

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden'
    }

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  // separate diff from event if it exists
  let copiedEvent = JSON.parse(JSON.stringify(event))
  let diff = null
  if (copiedEvent && copiedEvent.diff) {
    diff = copiedEvent.diff
    delete copiedEvent.diff
  }

  return (
    <Box className>
      <Dialog
        open={showModal}
        onOpenChange={onClose}
        className={'LogInspector__Dialog'}
      >
        <Slot name='title'>
          <Flex direction='column' axisGap={400}>
            <Text size={400} fontWeight={400}>
              {strings.modalTitle}
            </Text>
            <Text size={200} as='sub' variant='page' tone={800}>
              {strings.modalSubtitle}
            </Text>
          </Flex>
        </Slot>
        <Slot name='content'>
          <Flex
            direction='column'
            className='LogInspector__Content'
            axisGap={300}
          >
            <Flex direction='column'>
              <LogLabel label={strings.actor} value={actorId} />
              <LogLabel
                label={strings.actorType}
                value={actorTypeStrings[auditLog.actorType]}
              />
              <LogLabel label={strings.action} value={actionStrings[action]} />

              <LogLabel
                label={strings.service}
                value={serviceStrings[service]}
              />
              <LogLabel label={strings.context} value={context} />

              <LogLabel
                label={strings.organization}
                value={organization || strings.notApplicable}
              />
              <LogLabel
                label={site.text}
                value={zone || strings.notApplicable}
              />
              <LogLabel
                label={strings.date}
                value={formatAuditTimestamp(timestamp)}
              />
            </Flex>

            {copiedEvent && (
              <Fragment>
                {diff && (
                  <Box>
                    <Label>
                      <Text tone='700' variant='page'>
                        {strings.updatedAttributes}:
                      </Text>
                      {diff && (
                        <DiffViewer
                          className='LogInspector__Diff'
                          diff={diff}
                          useDarkTheme={colorScheme === 'dark'}
                        />
                      )}
                    </Label>
                  </Box>
                )}
                <Box className='LogInspector__Event'>
                  <Label>
                    <Text tone='700' variant='page'>
                      {strings.event}:
                    </Text>
                    <Code language='json'>
                      {JSON.stringify(copiedEvent, null, 2)}
                    </Code>
                  </Label>
                </Box>
              </Fragment>
            )}
          </Flex>
        </Slot>
      </Dialog>
    </Box>
  )
}

import { I18n } from 'aws-amplify'

import { Column, Row, Table, Slot, Loader } from '@/primitives/index'
import {
  getOrganizationLabels,
  getOrganizationLabelsLoading
} from '@/reducers/selectors'

import LabelRow from './LabelRow'

export default function LabelTable() {
  const organizationLabels = getOrganizationLabels()
  const isLoading = getOrganizationLabelsLoading()

  return (
    <Loader isLoading={isLoading}>
      <Table style={{ marginTop: '1rem' }}>
        <Slot name='head'>
          <Row>
            <Column>{I18n.get('Label')}</Column>
            <Column>{I18n.get('Replacement Label')}</Column>
            <Column>{I18n.get('Replacement Label Plural')}</Column>
            <Column>{I18n.get('Replacement Label Language')}</Column>
            <Column>{I18n.get('Actions')}</Column>
          </Row>
        </Slot>
        <Slot name='body'>
          {organizationLabels.map(labelItem => (
            <LabelRow key={labelItem.labelId} labelItem={labelItem} />
          ))}
        </Slot>
      </Table>
    </Loader>
  )
}

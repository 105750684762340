import { I18n } from 'aws-amplify'

const Strings = () => ({
  addSaleShipment: I18n.get('Add Shipment'),
  createFormHeader: I18n.get('Add New Shipment'),
  updateFormHeader: I18n.get('Update Shipment'),
  fieldStatus: I18n.get('Status'),
  fieldDueDate: I18n.get('Due Date'),
  fieldDeliveryDate: I18n.get('Date Delivered'),
  fieldStorageFacility: I18n.get('Storage Facility'),
  fieldShippingMethod: I18n.get('Shipping Method'),
  fieldPrice: I18n.get('Price'),
  fieldDiscount: I18n.get('Discount'),
  fieldTax: I18n.get('Tax'),
  selectInputDefault: I18n.get('Please select'),
  tableNoEntries: I18n.get('No shipments'),
  tableHeaderDue: I18n.get('Due'),
  tableHeaderShipping: I18n.get('Shipping From'),
  tableHeaderStatus: I18n.get('Status'),
  tableHeaderValue: I18n.get('Value'),
  formSaveBtn: I18n.get('Save Shipment'),
  formCancelBtn: I18n.get('Cancel'),
  pending: I18n.get('Pending'),
  delayed: I18n.get('Delayed'),
  shipped: I18n.get('Shipped'),
  delivered: I18n.get('Delivered'),
  canceled: I18n.get('Canceled'),
  addItem: I18n.get('Add Item'),
  items: I18n.get('Items')
})

export default Strings

import { useHistory } from 'react-router-dom'

import { FlexV2, Button } from '@/primitives'

import { getIsGodMode } from '@/Util/PermissionUtils'
import { getCurrentUser, getDevice } from '@/reducers/selectors'

import {
  RESOURCE_TYPE_DEVICE,
  RESOURCE_TYPE_DEVICE_CALIBRATION,
  hasEditPermissions
} from '@/Util/PermissionUtils'
import { TYPES_CARD_CONFIGURATIONS } from '@/Util/CalibrationUtils'

export default function HeaderControls() {
  const history = useHistory()

  const coretexUser = getCurrentUser()
  const isGodMode = getIsGodMode(coretexUser)
  const device = getDevice()

  const pathname = history?.location?.pathname

  const cardConfigurations = Object.keys(TYPES_CARD_CONFIGURATIONS).reduce(
    (acc, key) => {
      if (key === 'cellular_envirosense' && !isGodMode) return acc
      acc[key] = TYPES_CARD_CONFIGURATIONS[key]
      return acc
    },
    {}
  )

  const canEditCalibration = hasEditPermissions(
    coretexUser,
    RESOURCE_TYPE_DEVICE,
    RESOURCE_TYPE_DEVICE_CALIBRATION
  )

  const goToDataboard = () => {
    const uri = `${pathname}/data`
    history.push(uri)
  }

  const goToCalibration = () => {
    const uri = `${pathname}/calibration`
    history.push(uri)
  }

  return (
    <FlexV2 axisGap={300} direction='row' alignMainAxis='flex-end' style={{ marginTop: '0.5rem'}}>
      <Button
        size='small'
        iconBefore='query_stats'
        onClick={goToDataboard}
      />
      {device?.id && pathname?.includes(device?.id) &&
        device?.isIoT &&
        canEditCalibration &&
        Object.keys(cardConfigurations).includes(device?.sensorType) && (
          <Button
            size='small'
            iconBefore='tune'
            onClick={goToCalibration}
          />
        )}
    </FlexV2>
  )
}

export const TYPES_CARD_CONFIGURATIONS = {
  watersense: [
    { id: 'water_temperature', icon: 'thermostat' },
    { id: 'conductivity', icon: 'electric_bolt' },
    { id: 'ph', icon: 'science' },
    { id: 'dissolved_oxygen', icon: 'bubble_chart' }
    // { id: 'dissolved_oxygen_percentage', icon: 'percent' }
  ],
  envirosense: [
    { id: 'temperature', icon: 'thermostat' },
    { id: 'humidity', icon: 'opacity' },
    { id: 'par', icon: 'flare' },
    { id: 'carbon_dioxide', icon: 'co2' }
  ],
  cellular_envirosense: [
    { id: 'temperature', icon: 'thermostat' },
    { id: 'humidity', icon: 'opacity' },
    { id: 'par', icon: 'flare' },
    { id: 'carbon_dioxide', icon: 'co2' }
  ]
}

import FlexV2 from '@/primitives/FlexV2'
import Text from '@/primitives/Text'

import Strings from '../../Strings'

export default function FetchErrorCard({ error }) {
  const strings = Strings()
  return (
    <FlexV2
      alignMainAxis='space-between'
      alignCrossAxis='center'
      className='Historic__Errors'
      direction='column'
    >
      <Text as='h6' style={{ marginBottom: '0.5em' }}>
        {strings.errorOccurred}
      </Text>
      <Text as='p'>{error}</Text>
    </FlexV2>
  )
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  getSiteIngestionFrequencyConfig,
  updateSiteIngestionFrequencyConfig
} from '@/api/ts/ingestionFrequency'

export const fetchSiteIngestionFrequency = createAsyncThunk(
  'fetchSiteIngestionFrequency',
  async params => {
    return await getSiteIngestionFrequencyConfig(params)
  }
)

export const sendUpdateSiteIngestionFrequencyConfig = createAsyncThunk(
  'sendUpdateSiteIngestionFrequencyConfig',
  async params => {
    return await updateSiteIngestionFrequencyConfig(params)
  }
)

const timestreamIngestionFrequencySlice = createSlice({
  name: 'timestreamIngestionFrequencyReducer',
  initialState: {
    config: {},
    configUpdated: false,
    isLoading: false,
    saving: false,
    error: null
  },
  reducers: {
    cleanSiteIngestionFrequencyUpdated: (state, action) => {
      state.configUpdated = false
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSiteIngestionFrequency.pending, (state, action) => {
        state.error = null
        state.isLoading = true
      })
      .addCase(fetchSiteIngestionFrequency.rejected, (state, action) => {
        state.error = action.error
        state.isLoading = false
      })
      .addCase(fetchSiteIngestionFrequency.fulfilled, (state, action) => {
        state.config = action.payload
        state.isLoading = false
      })
      .addCase(
        sendUpdateSiteIngestionFrequencyConfig.pending,
        (state, action) => {
          state.error = null
          state.saving = true
          state.configUpdated = true
        }
      )
      .addCase(
        sendUpdateSiteIngestionFrequencyConfig.rejected,
        (state, action) => {
          state.error = action.error
          state.saving = false
        }
      )
      .addCase(
        sendUpdateSiteIngestionFrequencyConfig.fulfilled,
        (state, action) => {
          state.config = action.payload
          state.saving = false
        }
      )
  }
})

export const { cleanSiteIngestionFrequencyUpdated } =
  timestreamIngestionFrequencySlice.actions

export default timestreamIngestionFrequencySlice.reducer

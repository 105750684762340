import { deepEntriesAncestorRef } from './utils'
import tokenize from './tokenize'

function toCSSText() {
  return this.data.map(({ cssText }) => cssText).join('')
}

function toJSON() {
  return JSON.stringify(this.data)
}

function toClassList() {
  return this.data.map(({ className }) => className)
}

export default function compile(styleObject) {
  return {
    data: deepEntriesAncestorRef(styleObject).map(tokenize),
    toCSSText,
    toJSON,
    toClassList
  }
}

import { useReducer } from 'react'
import { useDispatch } from 'react-redux'

import Container from './Container'

import { Button, Form, Input, Text, Loader, Label, Flex } from '@/primitives'

import { showBanner } from '@/slices/util'

import { useAuth } from '@/contexts/auth-context'

import { getInputClassName, insertError } from './Utils'

import Strings from './Strings'

import { verifyAccountCodeSchema } from '../../validations'

const initialState = {
  code: '',
  loading: false,
  verifyAttr: null,
  errors: {}
}

const reducer = (state, action) => {
  if (action.type === 'update') {
    if (action.name !== 'errors' && state.errors.hasOwnProperty(action.name)) {
      const errors = { ...state.errors }
      delete errors[action.name]
      return { ...state, errors, [action.name]: action.value }
    }

    return { ...state, [action.name]: action.value }
  }
  return state
}

const VerifyContact = ({ hubData }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const auth = useAuth()
  const reduxDispatch = useDispatch()
  const strings = Strings()

  function onChangeInput(e) {
    const { name, value } = e.target
    dispatch({ type: 'update', name, value })
  }

  const onVerify = async e => {
    e.preventDefault()
    try {
      setLoading(true)
      setErrors()
      await auth.verifyUserAttribute('email')
      dispatch({ type: 'update', name: 'verifyAttr', value: 'email' })
      setLoading(false)
    } catch (err) {
      if (err.message) {
        setErrors({ general: err.message })
      }
      setLoading(false)
    }
  }

  const onSubmit = async e => {
    e.preventDefault()
    const { code, verifyAttr } = state
    try {
      setErrors()
      setLoading(true)
      await verifyAccountCodeSchema.validate({ code }, { abortEarly: false })
      await auth.verifyCurrentUserAttributeSubmit(verifyAttr, code)
      dispatch({ type: 'update', name: 'verifyAttr', value: null })
      setLoading(false)
    } catch (err) {
      let errors = {}

      if (err?.inner) {
        err.inner.forEach(innerErr => {
          errors[innerErr.path] = innerErr.message
        })
      }

      if (!err.inner && err.message) {
        reduxDispatch(
          showBanner({
            type: 'error',
            message: err.message,
            show: true
          })
        )
      }
      setErrors(errors)
      setLoading(false)
    }
  }

  const setErrors = (errors = {}) => {
    dispatch({ type: 'update', name: 'errors', value: errors })
  }

  const setLoading = loading => {
    dispatch({ type: 'update', name: 'loading', value: loading })
  }

  function submitView() {
    const verifyPrompt = `${strings.verifyEmail} ${hubData?.attributes?.email}`

    if (!state.verifyAttr) {
      return (
        <Text
          size={100}
          variant='page'
          tone={900}
          style={{ marginBottom: '2rem' }}
        >
          {verifyPrompt}
        </Text>
      )
    }

    return (
      <Flex direction='column'>
        <Text
          size={100}
          variant='page'
          tone={900}
          style={{ marginBottom: '2rem' }}
        >
          {strings.verificationCodeMsg}
        </Text>
        <Label className='Auth__Form__Label'>{strings.code}</Label>
        <Input
          className={getInputClassName('code', state.errors)}
          type='text'
          name='code'
          value={state.code}
          onChange={onChangeInput}
        />
        {insertError(state?.errors?.code)}
      </Flex>
    )
  }

  const onSkip = async () => {
    await auth.currentUser()
  }

  return (
    <Loader isLoading={state.loading}>
      <Container title={strings.verifyAccountTitle}>
        <Form
          onSubmit={state.verifyAttr ? onSubmit : onVerify}
          className='Auth__Form'
        >
          {submitView()}
          <Button
            type='submit'
            variant='primary'
            className='Auth__Form__PrimaryButton'
          >
            {state.verifyAttr ? strings.submitBtn : strings.verifyBtn}
          </Button>
        </Form>
        <div style={{ marginTop: '1em', marginBottom: '0.5em' }}>
          <Button
            variant='text'
            className='Auth__Form__GoToPageButton'
            onClick={onSkip}
          >
            {strings.skipVerification}
          </Button>
        </div>
      </Container>
    </Loader>
  )
}

export default VerifyContact

import { parseValue } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/utils'
import Value from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/Chart/Value'
import {
  getRanges,
  VALUE_CHART
} from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'
import useAverageData from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Hooks/useAverageData'
import { handleMeasurementConversion } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/utils'

import { getCurrentUser, getRootZone } from '@/reducers/selectors'

export default function Average({
  options,
  config,
  unit = null,
  valueModifierCallback = null
}) {
  const avgData = useAverageData({
    measurements: [options.measurement],
    range: config.range,
    interval: config.interval
  })

  const currentUser = getCurrentUser()
  const rootZone = getRootZone()

  let data = avgData?.[options?.measurement] ?? {}

  const parseOptions = value => ({
    fixedFactor: options.fixedFactor,
    scaleFactor: options.scaleFactor,
    value: valueModifierCallback ? valueModifierCallback(value) : value
  })

  const conversion = handleMeasurementConversion({
    data,
    measurement: options?.measurement,
    unit,
    queryType: config.query,
    settings: currentUser?.settings,
    userId: currentUser?.userName,
    organizationId: rootZone?.organizationId
  })

  if (conversion) {
    data = conversion.data
    unit = conversion.unit
  }

  if (config.chart === VALUE_CHART) {
    return (
      <Value
        data={{
          value: parseValue(parseOptions(data.value)),
          min: parseValue(parseOptions(data.min)),
          max: parseValue(parseOptions(data.max)),
          unit,
          measurement: options.measurement,
          status: data?.status,
          subtitle: getRanges(config.range)?.label
        }}
      />
    )
  }

  return null
}

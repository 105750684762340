import { I18n } from 'aws-amplify'

import { Flex, Box, Text } from '@/primitives'

import './ProgressIndicator.scss'

const ProgressIndicator = ({ currentStep }) => {
  const strings = {
    userDetails: I18n.get('Enter user details'),
    verifyEmail: I18n.get('Verify email address')
  }
  const steps = [strings.userDetails, strings.verifyEmail]

  function insertItem(step, index) {
    const stepNumber = index + 1
    let style = {}
    if (stepNumber > currentStep) {
      style = {
        opacity: '0.7',
        color: 'var(--ctx-theme-color-page-700)',
        borderColor: 'var(--ctx-theme-color-page-700)'
      }
    }

    return (
      <Box style={style} key={step}>
        {index > 0 && <Box style={style} className='ProgressIndicator__Line' />}
        <Box style={style} className='ProgressIndicator__Number'>
          {stepNumber}
        </Box>
        <Text className='ProgressIndicator__Text'>{steps[index]}</Text>
      </Box>
    )
  }

  return (
    <Flex
      alignCrossAxis='center'
      alignMainAxis='center'
      className='ProgressIndicator'
    >
      {steps.map((step, index) => insertItem(step, index))}
    </Flex>
  )
}

export default ProgressIndicator

import { useReducer, useState, useEffect, Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

import {
  Box,
  Button,
  FlexV2,
  Form,
  Input,
  Label,
  Select,
  Slot,
  Text,
  Icon
} from '@/primitives'

import { Dialog } from '@/elements'

import {
  fetchUser,
  sendUpdateUser,
  sendDeleteUser,
  setUser
} from '@/slices/management/user'
import { fetchRoles } from '@/slices/management/role'

import {
  getIsLoading,
  getUser,
  getUpdatedUser,
  getDeletedUser,
  getUserSliceError,
  getCurrentUser,
  getRoles
} from '@/reducers/selectors'

import useMediaQuery from '@/hooks/useMediaQuery'

import AdminUtils from '@/Util/AdminUtils'
import {
  RESOURCE_TYPE_USER,
  RESOURCE_TYPE_USER_MANAGEMENT,
  hasEditPermissions
} from '@/Util/PermissionUtils'

import {
  generateUserError,
  initialState,
  reducer,
  USER_FORM_ENUM
} from './utils'

import Strings from '../Strings'

const { updateUserSchema } = AdminUtils

export default function EditUser({ match }) {
  const strings = Strings()

  const reduxDispatch = useDispatch()
  const history = useHistory()

  const coretexUser = getCurrentUser()
  const isLoading = getIsLoading()
  const user = getUser()
  const updatedUser = getUpdatedUser()
  const deletedUser = getDeletedUser()
  const userError = getUserSliceError()
  const roles = getRoles()

  const isAboveCustomBreakpoint = useMediaQuery('min-width: 680px')

  const showEditOptions = hasEditPermissions(
    coretexUser,
    RESOURCE_TYPE_USER,
    RESOURCE_TYPE_USER_MANAGEMENT
  )

  const [hasValidationError, setHasValidationError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showDeleteUserDialog, setShowDeleteUserDialog] = useState(false)

  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (!user.userName) {
      const params = { userName: match.params.id }
      reduxDispatch(fetchUser(params))
    }
  }, [reduxDispatch, match.params.id, user?.userName])

  useEffect(() => {
    if (updatedUser?.userName) {
      history.replace('/admin/user-management', { action: 'update' })
    }
  }, [history, updatedUser?.userName])

  useEffect(() => {
    if (deletedUser?.userName) {
      history.replace('/admin/user-management', { action: 'delete' })
    }
  }, [history, deletedUser?.userName])

  useEffect(() => {
    if (user?.userName && !state.userName.value) {
      dispatch({
        type: USER_FORM_ENUM.UPDATE_ALL,
        value: { ...user }
      })
    }
  }, [dispatch, user, state?.userName?.value])

  useEffect(() => {
    if (roles.length < 1) reduxDispatch(fetchRoles())
  }, [reduxDispatch, roles.length, coretexUser?.userName])

  useEffect(() => {
    if (user?.phoneNumber && !state.phoneNumber.value) {
      dispatch({
        type: USER_FORM_ENUM.UPDATE_ALL,
        value: { ...user }
      })
    }
  }, [dispatch, state?.phoneNumber?.value, user])

  useEffect(() => {
    if (userError) {
      setHasValidationError(true)
    }
  }, [userError])

  useEffect(() => {
    if (hasValidationError) {
      const message = generateUserError(userError, state)
      setErrorMessage(message)
    }
  }, [state, userError, hasValidationError])

  const onChangeInput = ({ target: { name, value } }) => {
    dispatch({ type: name, value })
  }

  const onChangePhoneNumber = value => {
    dispatch({ type: USER_FORM_ENUM.PHONE_NUMBER, value })
  }

  const onSelectRole = ({ target: { value } }) => {
    dispatch({ type: USER_FORM_ENUM.ROLE, value })
  }

  const dismissErrorMessage = () => {
    setHasValidationError(false)
  }

  const onSaveUserDetails = async event => {
    event.preventDefault()
    const userValues = {
      email: state.email.value,
      firstName: state.firstName.value,
      lastName: state.lastName.value,
      role: state.role.value
    }

    const updatedUser = {
      ...user,
      ...userValues
    }

    let params = { user: updatedUser }

    const { firstName, lastName, email } = updatedUser

    try {
      if (hasValidationError) setHasValidationError(false)
      await updateUserSchema().validate(
        { email, firstName, lastName },
        { abortEarly: false }
      )

      const phoneNumber = state.phoneNumber.value
      const isValid = phoneNumber ? isValidPhoneNumber(phoneNumber) : true
      if (isValid) {
        if (phoneNumber) {
          params['user'][USER_FORM_ENUM.PHONE_NUMBER] = phoneNumber
        }
        reduxDispatch(sendUpdateUser(params))
      } else {
        dispatch({ type: USER_FORM_ENUM.PHONE_NUMBER, hasError: true })
        setHasValidationError(true)
      }
    } catch (error) {
      error.inner.forEach(err => {
        dispatch({ type: err.path, hasError: true })
      })
      setHasValidationError(true)
    }
  }

  const goBack = () => {
    dispatch({ type: USER_FORM_ENUM.CLEAR_ALL })
    reduxDispatch(setUser({}))
  }

  const onClickClose = () => {
    setShowDeleteUserDialog(false)
  }

  const onDeleteUser = () => {
    reduxDispatch(sendDeleteUser({ userName: user.userName }))
    setShowDeleteUserDialog(false)
  }

  return (
    <Fragment>
      <Form className={'EditUser__Form'} onSubmit={onSaveUserDetails}>
        <FlexV2
          className='EditUser__FormHeader'
          axisGap={400}
          direction={isAboveCustomBreakpoint ? 'row' : 'column'}
          alignMainAxis={isAboveCustomBreakpoint ? 'space-between' : null}
          alignCrossAxis={isAboveCustomBreakpoint ? 'center' : null}
        >
          <FlexV2 axisGap={400} alignCrossAxis='center'>
            <Link to={'/admin/user-management'} onClick={goBack}>
              <Button iconAfter='chevron left' size={'small'} />
            </Link>
            <Box>
              <Text as='div' size={300}>
                {showEditOptions
                  ? strings.editUserHeading
                  : strings.viewUserHeading}
              </Text>
              <Text variant={'page'} tone={700} size={200}>
                {showEditOptions
                  ? strings.editUserSubheading
                  : strings.viewUserSubheading}
              </Text>
            </Box>
          </FlexV2>
          {showEditOptions && (
            <FlexV2
              axisGap={400}
              direction={isAboveCustomBreakpoint ? 'row' : 'column'}
            >
              <Button
                variant='danger'
                onClick={() => setShowDeleteUserDialog(true)}
                size={'small'}
              >
                {strings.removeButton}
              </Button>
              <Button
                loading={isLoading}
                type='submit'
                variant={'primary'}
                size={'small'}
              >
                {strings.saveUserBtn}
              </Button>
            </FlexV2>
          )}
        </FlexV2>
        {hasValidationError && (
          <FlexV2
            onClick={dismissErrorMessage}
            className='EditUser__FormErrors'
            alignCrossAxis='center'
            alignMainAxis='space-between'
          >
            <FlexV2 axisGap={400}>
              <Icon name='warning' />
              {errorMessage}
            </FlexV2>
            <Icon name='close' />
          </FlexV2>
        )}
        <FlexV2
          className='EditUser__FormContent'
          direction={'column'}
          alignMainAxis={'center'}
        >
          <Label>
            <Text variant={'page'} tone={700}>
              {strings.firstName} *
            </Text>
            <Input
              disabled={isLoading}
              name={USER_FORM_ENUM.FIRST_NAME}
              value={state.firstName.value}
              onChange={onChangeInput}
            />
            {state.firstName.hasError && (
              <Text size='small' variant='error'>
                {state.firstName.error}
              </Text>
            )}
          </Label>

          <Label>
            <Text variant={'page'} tone={700}>
              {strings.lastName} *
            </Text>
            <Input
              required
              name={USER_FORM_ENUM.LAST_NAME}
              value={state.lastName.value}
              onChange={onChangeInput}
              disabled={isLoading}
            />
            {state.lastName.hasError && (
              <Text size='small' variant='error'>
                {state.lastName.error}
              </Text>
            )}
          </Label>

          <Label>
            <Text variant={'page'} tone={700}>
              {strings.userName}
            </Text>
            <Input
              name={USER_FORM_ENUM.USER_NAME}
              value={state.userName.value}
              disabled={true}
            />
            {state.userName.hasError && (
              <Text size='small' variant='error'>
                {state.userName.error}
              </Text>
            )}
          </Label>
          <Label>
            <Text variant={'page'} tone={700}>
              {strings.email} *
            </Text>
            <Input
              type='email'
              required
              name={USER_FORM_ENUM.EMAIL}
              value={state.email.value}
              onChange={onChangeInput}
              disabled={isLoading}
            />
            {state.email.hasError && (
              <Text size='small' variant='error'>
                {state.email.error}
              </Text>
            )}
          </Label>
          <Label>
            <Text variant={'page'} tone={700}>
              {strings.role} *
            </Text>
            <Select
              required
              value={state.role.value}
              onChange={onSelectRole}
              disabled={isLoading}
            >
              <option default value=''>
                {strings.userFormUserSelectPlaceholder}
              </option>
              {roles.map(({ name }) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </Select>
            {state.role.hasError && (
              <Text size='small' variant='error'>
                {state.role.error}
              </Text>
            )}
          </Label>
          <Label>
            <Text variant={'page'} tone={700}>
              {strings.phoneNumber}
            </Text>
            <PhoneInput
              international
              value={state.phoneNumber.value}
              onChange={onChangePhoneNumber}
              disabled={isLoading}
            />
            {state.phoneNumber.hasError && (
              <Text size='small' variant='error'>
                {state.phoneNumber.error}
              </Text>
            )}
          </Label>
        </FlexV2>
      </Form>

      <Dialog open={showDeleteUserDialog} onOpenChange={onClickClose}>
        <Slot name='title'>
          <Text size={300} fontWeight={700} variant={'danger'} tone={700}>
            {strings.removeUserHeadingPart1} {user.userName}
          </Text>
        </Slot>
        <Slot name='content'>
          <Box>{strings.removeUserSubheading}</Box>
        </Slot>
        <Slot name='actions'>
          <FlexV2 axisGap={400} alignMainAxis='space-between'>
            <Button variant='page' onClick={onClickClose}>
              {strings.cancel}
            </Button>
            <Button variant='danger' onClick={onDeleteUser}>
              {strings.deleteUserButton}
            </Button>
          </FlexV2>
        </Slot>
      </Dialog>
    </Fragment>
  )
}

import { makeOperationsApiCall } from './utils'

export const getFertilizerInventoryCalibrations = async params => {
  try {
    const query = /* GraphQL */ `
      query getFertilizerInventoryCalibrations(
        $filter: GetFertilizerInventoryCalibrationsFilter
        $order: OrderEnum
        $offset: Int
        $limit: Int
      ) {
        getFertilizerInventoryCalibrations(
          filter: $filter
          order: $order
          offset: $offset
          limit: $limit
        ) {
          fertilizerInventoryCalibrations {
            id
            organizationId
            fertilizerInventory {
              id
              fertilizerId
              storageFacility {
                id
                name
              }
            }
            oldTotalWeight
            newTotalWeight
            reason
            createdAt
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getFertilizerInventoryCalibrations',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const createFertilizerInventoryCalibration = async params => {
  try {
    const query = /* GraphQL */ `
      mutation createFertilizerInventoryCalibration(
        $organizationId: String!
        $fertilizerInventoryId: String!
        $newTotalWeight: Int!
        $reason: String!
      ) {
        createFertilizerInventoryCalibration(
          organizationId: $organizationId
          fertilizerInventoryId: $fertilizerInventoryId
          newTotalWeight: $newTotalWeight
          reason: $reason
        ) {
          id
          organizationId
          fertilizerInventory {
            id
            storageFacility {
              id
              name
            }
            fertilizerId
          }
          oldTotalWeight
          newTotalWeight
          reason
          createdAt
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'createFertilizerInventoryCalibration',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

import { Fragment, useReducer, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { I18n } from 'aws-amplify'

import PhoneInput from 'react-phone-number-input'
import Container from './Container'

import {
  Button,
  Input,
  Form,
  Text,
  Flex,
  Label,
  Icon,
  Loader
} from '@/primitives'

import { showBanner } from '@/slices/util'

import { useAuth } from '@/contexts/auth-context'

import { signUpSchema } from '../../validations'

import Strings from './Strings'

import './SignUp.scss'

const strings = Strings()

const fieldsConfig = {
  organization: {
    label: I18n.get('Organization'),
    type: 'text'
  },
  username: {
    label: I18n.get('Username'),
    type: 'text'
  },
  email: {
    label: I18n.get('Email'),
    type: 'email'
  },
  firstName: {
    label: I18n.get('First name'),
    type: 'text'
  },
  lastName: {
    label: I18n.get('Last name'),
    type: 'text'
  },
  password: {
    label: I18n.get('Password'),
    type: 'password'
  },
  confirmPassword: {
    label: I18n.get('Confirm password'),
    type: 'password'
  },
  phone: {
    label: I18n.get('Phone number'),
    type: 'tel'
  }
}

const initialState = {
  loading: false,
  errors: {}
}

Object.keys(fieldsConfig).forEach(field => (initialState[field] = ''))

const reducer = (state, action) => {
  if (action.type === 'update') {
    if (
      action.name !== 'errors' &&
      state?.errors?.hasOwnProperty(action.name)
    ) {
      const errors = { ...state.errors }
      delete errors[action.name]
      return { ...state, errors, [action.name]: action.value }
    }

    return { ...state, [action.name]: action.value }
  }

  if (action.type === 'updateBulk') {
    const newValues = { ...action.value }
    return { ...state, ...newValues, errors: {} }
  }

  return state
}

function SignUp({ hubData, setHubData }) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const auth = useAuth()
  const reduxDispatch = useDispatch()

  const hubDataDetails = hubData?.details ?? null

  useEffect(() => {
    if (hubDataDetails) {
      dispatch({ type: 'updateBulk', value: hubDataDetails })
      if (hubDataDetails?.errors?.general) {
        reduxDispatch(
          showBanner({
            type: 'error',
            message: hubDataDetails?.errors?.general,
            show: true
          })
        )
      }
    }
  }, [hubDataDetails, reduxDispatch])

  const onSignUp = async e => {
    e.preventDefault()
    try {
      setLoading(true)
      await signUpSchema.validate(state, { abortEarly: false, strict: true })
      setErrors()
      const {
        username,
        password,
        email,
        phone,
        firstName,
        lastName,
        organization
      } = state

      const params = {
        username,
        password,
        attributes: {
          email,
          phone_number: phone
        },
        firstName,
        lastName,
        organization
      }

      await auth.signUp(params)
    } catch (err) {
      let errors = {}

      if (err?.inner) {
        err.inner.forEach(innerErr => {
          errors[innerErr.path] = innerErr.message
        })
      }

      if (!err.inner && err.message) {
        reduxDispatch(
          showBanner({
            type: 'error',
            message: err.message,
            show: true
          })
        )
      }
      setErrors(errors)
      setLoading(false)
    }
  }

  function insertField(field, errors) {
    const fieldConfig = fieldsConfig[field]
    const hasError = errors?.hasOwnProperty(field)

    let inputStyles = {}
    if (hasError)
      inputStyles['borderColor'] = 'var(--ctx-theme-color-error-500)'

    return (
      <Flex direction='column' key={field}>
        <Label className='SignUp__Form__Label'>{fieldConfig.label}</Label>
        {field !== 'phone' && (
          <Input
            className='SignUp__Form__Input'
            style={inputStyles}
            name={field}
            value={state[field]}
            onChange={onChangeInput}
            type={fieldConfig.type}
          />
        )}
        {field === 'phone' && (
          <PhoneInput
            className='SignUp__Form__Input'
            style={inputStyles}
            name={field}
            international={true}
            value={state[field]}
            onChange={onChangeInput}
          />
        )}
        {hasError && (
          <Fragment>
            <Icon
              className='SignUp__Form__InputErrorIcon'
              name='error'
              variant='error'
              tone={500}
            />
            <Text className='SignUp__Form__InputError' variant='error'>
              {errors[field]}
            </Text>
          </Fragment>
        )}
      </Flex>
    )
  }

  const setErrors = (errors = {}) => {
    dispatch({ type: 'update', name: 'errors', value: errors })
  }

  const setLoading = loading => {
    dispatch({ type: 'update', name: 'loading', value: loading })
  }

  function onChangeInput(e) {
    const target = e?.target ?? e ?? ''
    const name = target.name
    const value = target.value
    dispatch({ type: 'update', name, value })
  }

  const goToSignIn = async e => {
    e.preventDefault()
    setHubData({ component: 'signIn' })
  }

  return (
    <Loader isLoading={state.loading}>
      <Container title={strings.signUpTitle} progressStep={1}>
        <Fragment>
          <Text size={100} variant='page' tone={900}>
            {I18n.get(
              'This will create a new organization. To join an existing organization, you must be invited by a user in that organization.'
            )}
          </Text>
          <Form className='SignUp__Form' onSubmit={onSignUp}>
            {Object.keys(fieldsConfig)?.map(field =>
              insertField(field, state.errors)
            )}
            <Button
              loading={state.loading}
              variant='primary'
              type='submit'
              className='SignUp__Form__SignUpButton'
              disabled={Object.keys(state.errors).length > 0}
            >
              <Text textTransform='capitalize'>{I18n.get('Continue')}</Text>
            </Button>
          </Form>
          <Flex>
            <Button
              variant='text'
              onClick={goToSignIn}
              className='SignUp__Form__SignInLink'
            >
              <Text as='p' size={100}>
                {strings.backSignIn}
              </Text>
            </Button>
          </Flex>
        </Fragment>
      </Container>
    </Loader>
  )
}

export default SignUp

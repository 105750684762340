export const DEFAULT_MODBUS_CONFIG = {
  port_address: '/dev/ttyS0',
  slave_address: 1,
  baudrate: 4800,
  bytesize: 8,
  stopbits: 1,
  timeout: 1,
  read_registers: [0, 1, 100, 100, 100, 100, 100, 100, 100, 100],
  read_measurementType: [
    'humidity',
    'temperature',
    'water_temperature',
    'uv_index',
    'pressure',
    'ph',
    'par',
    'conductivity',
    'dissolved_oxygen',
    'carbon_dioxide'
  ],
  read_measurementUnit: [
    '%',
    'C',
    'C',
    'N/A',
    'mBar',
    'pH',
    'μmol/m2/s',
    'mS/cm',
    'mg/L',
    'ppm'
  ],
  function_code: 3
}

import { Fragment, useState } from 'react'
import { I18n } from 'aws-amplify'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import FeatureFlags from '@/components/FeatureFlags'
import Settings from '@/components/Settings'

import { Cell, Row } from '@/primitives'
import { Select } from '@/elements'

import { getCurrentUser } from '@/reducers/selectors'

import { setOrganization } from '@/slices/management/organization'

import {
  RESOURCE_TYPE_ORGANIZATION,
  getIsGodMode,
  hasReadPermissions
} from '@/Util/PermissionUtils'

import Strings from '../Strings'

const ACTION_SELECT_ORGANIZATION = 'select_organization'
const ACTION_VIEW_USAGE_PLAN = 'view_usage_plan'
const ACTION_FEAUTURE_FLAGS = 'feature_flags'
const ACTION_MANAGE_LABELS = 'manage_labels'
const ACTION_SETTINGS = 'settings'

const OrganizationsListitem = ({ name, id }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [openFeatureFlagsSidebar, setOpenFeatureFlagsSidebar] = useState(false)
  const [openSettingsSidebar, setOpenSettingsSidebar] = useState(false)

  const coretexUser = getCurrentUser()
  const isGodMode = getIsGodMode(coretexUser)

  const strings = Strings()
  const { orgNameColumn, orgIdColumn } = strings

  const showEditOptions = hasReadPermissions(
    coretexUser,
    RESOURCE_TYPE_ORGANIZATION
  )

  let actions = [
    {
      label: showEditOptions ? strings.editButton : strings.viewButton,
      value: ACTION_SELECT_ORGANIZATION
    }
  ]

  if (isGodMode) {
    actions.push({
      label: strings.viewUsagePlan,
      value: ACTION_VIEW_USAGE_PLAN
    })

    actions.push({
      label: strings.featureFlags,
      value: ACTION_FEAUTURE_FLAGS
    })

    actions.push({
      label: strings.manageLabels,
      value: ACTION_MANAGE_LABELS
    })

    actions.push({
      label: strings.settings,
      value: ACTION_SETTINGS
    })
  }

  function setSelectedOrganization() {
    dispatch(setOrganization({ name, id }))
    history.push(`/admin/organizations/${id}/edit`)
  }

  function viewUsagePlan() {
    history.push(`/admin/organizations/${id}/usage-plan`)
  }

  function onOpenFeatureFlagSidebar() {
    setOpenFeatureFlagsSidebar(true)
  }

  function onCloseFeatureFlagSidebar() {
    setOpenFeatureFlagsSidebar(false)
  }

  function manageLabels() {
    history.push(`/admin/organizations/${id}/labels`)
  }

  function onOpenSettingsSidebar() {
    setOpenSettingsSidebar(true)
  }

  function onCloseSettingsSidebar() {
    setOpenSettingsSidebar(false)
  }

  function onSelectAction({ value }) {
    switch (value) {
      case ACTION_SELECT_ORGANIZATION:
        setSelectedOrganization()
        break
      case ACTION_VIEW_USAGE_PLAN:
        viewUsagePlan()
        break
      case ACTION_FEAUTURE_FLAGS:
        onOpenFeatureFlagSidebar()
        break
      case ACTION_MANAGE_LABELS:
        manageLabels()
        break
      case ACTION_SETTINGS:
        onOpenSettingsSidebar()
        break
      default:
        break
    }
  }

  return (
    <Fragment>
      <Row key={id}>
        <Cell columnName={orgIdColumn}>{id}</Cell>
        <Cell columnName={orgNameColumn}>{name}</Cell>
        <Cell>
          <Select
            classNamePrefix='UserAction__Select'
            placeholder={I18n.get('Options')}
            onChange={onSelectAction}
            options={actions}
          />
        </Cell>
      </Row>
      {isGodMode && openFeatureFlagsSidebar && (
        <FeatureFlags
          objectId={id}
          type={'organization'}
          openSidebar={openFeatureFlagsSidebar}
          onCloseSidebar={onCloseFeatureFlagSidebar}
        />
      )}
      {isGodMode && openSettingsSidebar && (
        <Settings
          objectId={id}
          type='organization'
          openSidebar={openSettingsSidebar}
          onCloseSidebar={onCloseSettingsSidebar}
        />
      )}
    </Fragment>
  )
}

export default OrganizationsListitem

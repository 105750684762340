import { Fragment } from 'react'
import {
  Button,
  Flex,
  Icon,
  Input,
  Text,
  Box,
  Card,
  Slot,
  Label
} from '@/primitives'

import Strings from '../../Strings'

import { getMeasurements } from '@/reducers/selectors'

import { capitalizeWord } from '@/Util/GeneralUtils'
import {
  findMeasurementName,
  getMeasurementStrings
} from '@/Util/MeasurementUtils'

import './FormBoundaries.scss'

const THRESHOLD_TYPE_COLORS = {
  notification: 'warning',
  caution: 'danger',
  warning: 'error'
}

const getNewValue = (currentValue, isAdd = true) => {
  let number = parseFloat(currentValue)
  isAdd ? number++ : number--
  return parseFloat(number).toFixed(1)
}

const strings = Strings()

const {
  thresholdIsAboveText,
  thresholdIsBelowText,
  thresholdUpperBound,
  thresholdLowerBound
} = strings

const typeDefinitionStrings = {
  notification: strings.notificationDefinition,
  caution: strings.cautionDefinition,
  warning: strings.warningDefinition
}

function ThresholdBounds({
  type,
  measurementId,
  allBounds,
  allBoundsMessages,
  canEdit,
  onUpdateBounds,
  unit
}) {
  const { lower, upper } = allBounds
  const { lowerMessage, upperMessage } = allBoundsMessages

  return (
    <Fragment>
      <div className={`ThresholdType ${type}`} style={{ width: '100%' }}>
        <Box>
          <Flex direction='row' axisGap={300} alignCrossAxis='center'>
            <Icon
              name='warning sign'
              variant={THRESHOLD_TYPE_COLORS[type]}
              tone={500}
            />
            <Text size={300} fontWeight={600}>
              {capitalizeWord(type)}
            </Text>
          </Flex>
          <Text
            style={{
              marginTop: '0.4rem',
              display: 'inline-block',
              maxWidth: '45rem'
            }}
            variant='page'
            tone={900}
          >
            {typeDefinitionStrings[type]}
          </Text>
        </Box>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          columnGap: '2rem'
        }}
        className='Grid'
      >
        <div>
          <ThresholdBound
            key={`lower-${type}`}
            measurementId={measurementId}
            type={type}
            bounds={lower}
            boundsMessage={lowerMessage}
            canEdit={canEdit}
            onUpdateBounds={onUpdateBounds}
            isUpper={false}
            unit={unit}
          />
        </div>
        <div>
          <ThresholdBound
            key={`upper-${type}`}
            measurementId={measurementId}
            type={type}
            bounds={upper}
            boundsMessage={upperMessage}
            canEdit={canEdit}
            onUpdateBounds={onUpdateBounds}
            isUpper={true}
            unit={unit}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default ThresholdBounds

function ThresholdBound({
  measurementId,
  type,
  bounds,
  boundsMessage,
  canEdit,
  onUpdateBounds,
  isUpper = true,
  unit
}) {
  const measurements = getMeasurements()

  function onChangeValue(e) {
    const value = e.target.value
    let newBounds = { ...bounds }
    newBounds[type] = value
    onUpdateBounds(isUpper ? 'upper' : 'lower', newBounds)
  }

  function onChangeMessage(e) {
    const value = e.target.value
    if (value.length <= 255) {
      let newBoundsMessage = { ...boundsMessage }
      newBoundsMessage[type] = value
      onUpdateBounds(
        isUpper ? 'upperMessage' : 'lowerMessage',
        newBoundsMessage
      )
    }
  }

  function onPressPlus(e) {
    e.preventDefault()
    let newBounds = { ...bounds }
    let currentValue = newBounds[type]
    const newValue = getNewValue(currentValue)

    newBounds[type] = newValue
    onUpdateBounds(isUpper ? 'upper' : 'lower', newBounds)
  }

  function onPressMinus(e) {
    e.preventDefault()
    let newBounds = { ...bounds }
    let currentValue = newBounds[type]
    const newValue = getNewValue(currentValue, false)

    newBounds[type] = newValue
    onUpdateBounds(isUpper ? 'upper' : 'lower', newBounds)
  }

  return (
    <Flex direction='column' className={`FormBoundary ${type}`} axisGap={400}>
      <Card>
        <Slot name='header'>
          <Flex alignMainAxis='space-between'>
            <Text size={100} fontWeight={600}>
              {`${
                isUpper
                  ? thresholdUpperBound.toUpperCase()
                  : thresholdLowerBound.toUpperCase()
              }`}
            </Text>
            <Text size={100} fontWeight={400} variant='page' tone='800'>
              {`${
                getMeasurementStrings(
                  measurementId
                )?.shortName?.toUpperCase() ??
                findMeasurementName(measurements, measurementId)?.toUpperCase()
              }`}
            </Text>
          </Flex>
        </Slot>
        <Slot name='body'>
          <Flex direction='row' axisGap={300} alignCrossAxis='center'>
            <Label>
              <Text variant='page' tone={800}>
                {isUpper ? thresholdIsAboveText : thresholdIsBelowText}
              </Text>
              <Flex wrap='nowrap' style={{ marginTop: '0.5rem' }}>
                <Button
                  size='small'
                  iconBefore='remove'
                  onClick={onPressMinus}
                  disabled={!canEdit}
                  variant='info'
                />
                <Input
                  type='text'
                  value={bounds[type]}
                  style={{
                    width: 'auto',
                    flexGrow: 1,
                    border: '2px solid var(--ctx-theme-color-page-400)',
                    boxShadow: 'none',
                    height: '36px'
                  }}
                  onChange={onChangeValue}
                />
                <Button
                  size='small'
                  iconBefore='add'
                  onClick={onPressPlus}
                  disabled={!canEdit}
                  variant='info'
                />
                <Text as='p' style={{ padding: '0.3rem 0.8rem' }}>
                  {unit}
                </Text>
              </Flex>
            </Label>
            <Label style={{ width: '100%' }}>
              <Text variant='page' tone={800}>
                Show message
              </Text>
              <Input
                as='textarea'
                style={{
                  margin: '0.5em 0',
                  border: '2px solid var(--ctx-theme-color-page-400)',
                  boxShadow: 'none'
                }}
                name='message'
                value={boundsMessage[type]}
                onChange={onChangeMessage}
              />
            </Label>
          </Flex>
        </Slot>
      </Card>
    </Flex>
  )
}

import { makeOperationsApiCall } from './utils'

export const getSaleItems = async params => {
  try {
    const query = /* GraphQL */ `
      query getSaleItems($saleId: String!) {
        getSaleItems(saleId: $saleId) {
          saleItems {
            id
            saleId
            productId
            quantityOrdered
          }
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getSaleItems',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const createSaleItem = async params => {
  try {
    const query = /* GraphQL */ `
      mutation createSaleItem(
        $id: String!
        $saleId: String!
        $productId: String!
        $quantityOrdered: Int!
        $unitPrice: Float!
        $discount: Float
        $tax: Float
      ) {
        createSaleItem(
          id: $id
          saleId: $saleId
          productId: $productId
          quantityOrdered: $quantityOrdered
          unitPrice: $unitPrice
          discount: $discount
          tax: $tax
        ) {
          id
          sale {
            id
            invoiceTotal
            invoiceTax
          }
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'createSaleItem',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updateSaleItem = async params => {
  try {
    const query = /* GraphQL */ `
      mutation updateSaleItem(
        $saleItemId: String!
        $saleId: String!
        $productId: String!
        $quantityOrdered: Int!
        $unitPrice: Float!
        $discount: Float
        $tax: Float
      ) {
        updateSaleItem(
          saleItemId: $saleItemId
          saleId: $saleId
          productId: $productId
          quantityOrdered: $quantityOrdered
          unitPrice: $unitPrice
          discount: $discount
          tax: $tax
        ) {
          id
          sale {
            id
            invoiceTotal
            invoiceTax
          }
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'updateSaleItem',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const deleteSaleItem = async params => {
  try {
    const query = /* GraphQL */ `
      mutation deleteSaleItem($saleItemId: String!) {
        deleteSaleItem(saleItemId: $saleItemId) {
          id
          sale {
            id
            invoiceTotal
            invoiceTax
          }
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'deleteSaleItem',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

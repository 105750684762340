import { Fragment } from 'react'
import { Button, Flex, Symbol, Text, Box } from '@/primitives'

import Link from '../Link'

import './index.scss'

const ActionButton = ({ icon, title, text, open }) => (
  <Box as={'a'}>
    <Flex alignCrossAxis={'center'}>
      {icon ? <Symbol name={icon} /> : <span className='Spacer' />}
      <Flex direction='column' axisGap={200} className={'Link__Text'}>
        {title && (
          <Text size={100} textTransform={'uppercase'} fontWeight={700}>
            {title}
          </Text>
        )}
        {text && <Text truncate={20}>{text}</Text>}
      </Flex>
      <Symbol name={`expand ${open ? 'less' : 'more'}`} />
    </Flex>
  </Box>
)

const ActionLink = ({
  to,
  text,
  title,
  icon,
  exact,
  level = 'organization',
  open = null,
  setOpen = null,
  className = null,
  openOnNavigate = true
}) => {
  if (!text) return null
  const handleClick = ({ target: { href } }) => {
    if (setOpen && (!href || (href && !open && openOnNavigate)))
      setOpen(!open, level)
  }

  return (
    <Flex
      className={[to ? 'ActionLink' : 'ActionButton', className]}
      axisGap={200}
      alignCrossAxis={'center'}
      onClick={handleClick}
    >
      {to ? (
        <Fragment>
          <Link
            to={to}
            text={text}
            icon={icon}
            title={title}
            level={level}
            exact={exact}
          />
          <Button iconBefore={`expand ${open ? 'less' : 'more'}`} />
        </Fragment>
      ) : (
        <ActionButton text={text} title={title} icon={icon} open={open} />
      )}
    </Flex>
  )
}

export default ActionLink

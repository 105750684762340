import { I18n } from 'aws-amplify'
import DevicesList from './Section/DevicesList'

export const TAB_CONFIGS = [
  {
    tabId: 'greengrass',
    tabLabel: I18n.get('Greengrass'),
    renderTabContent: () => <DevicesList />
  },
  {
    tabId: 'direct',
    tabLabel: I18n.get('Direct Connect'),
    renderTabContent: () => <DevicesList />
  }
]

export const BULK_ACTION_OPTIONS = [
  {
    value: 'core',
    label: I18n.get('Core Device Update')
  },
  {
    value: 'ota_sensor',
    label: I18n.get('OTA Update by Sensor')
  },
  {
    value: 'ota_core',
    label: I18n.get('OTA Update by Core Device')
  },
  {
    value: 'nvsdump',
    label: I18n.get('Request NVS Dump')
  },
  {
    value: 'ping',
    label: I18n.get('Request Device Status')
  }
  // {
  //   value: 'regenerate',
  //   label: 'Regenerate Firmware',
  //   icon: 'settings_backup_restore'
  // },
  // {
  //   value: 'download',
  //   label: 'Download Firmware',
  //   icon: 'cloud_download'
  // },
  // {
  //   value: 'relocate',
  //   label: 'Relocate Device',
  //   icon: 'edit_location'
  // },
  // {
  //   value: 'ping',
  //   label: 'Ping Device',
  //   icon: 'wifi_tethering'
  // }
]

export const DIAGNOSTICS_ACTIONS = [
  {
    value: 'sim_diagnostic_info',
    label: I18n.get('Request SIM Diagnostic Data'),
    cellularOnly: true
  },
  {
    value: 'sim_diagnostic_ping',
    label: I18n.get('Request SIM Diagnostic Ping'),
    cellularOnly: true
  },
  {
    value: 'sensor_diagnostic_info',
    label: I18n.get('Request Sensor Diagnostic Data'),
    cellularOnly: true
  },
  {
    value: 'sensor_restart',
    label: I18n.get('Restart Sensor'),
    cellularOnly: true
  }
]

export const OTA_UPDATE_DESTINATIONS = [
  // {
  //   value: 'sensor',
  //   label: I18n.get('Sensor')
  // },
  {
    value: 'comm',
    label: I18n.get('Communications Board')
  }
]

const ROW_GROUP_OPTIONS = [
  {
    value: 'sensors',
    label: I18n.get('Select all sensors')
  },
  {
    value: 'device',
    label: I18n.get('Select only the device')
  },
  {
    value: 'all',
    label: I18n.get('Select all sensors and device')
  }
]

export const getActions = (isGreengrass, bulkActionValue) => {
  if (
    !isGreengrass ||
    bulkActionValue === 'ota_sensor' ||
    bulkActionValue === 'nvsdump'
  ) {
    return [ROW_GROUP_OPTIONS[0]]
  }

  if (bulkActionValue === 'ota_core') {
    return [ROW_GROUP_OPTIONS[1]]
  }

  return ROW_GROUP_OPTIONS
}

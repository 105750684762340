import { call, takeLatest } from 'redux-saga/effects'

import { TIMESTREAM_WARM_UP } from '@/actions/ts/utils'
import { timestreamWarmUp } from '@/api/ts/utils'

export function* sendTimestreamWarmUp(action) {
  yield call(timestreamWarmUp, action.params)
}

export function* watchTimestreamWarmUp() {
  yield takeLatest(TIMESTREAM_WARM_UP, sendTimestreamWarmUp)
}

const CACHELOG_KEY = 'sendFetchTimeframeGraphAverages_cachelog'

export const makeUniqueLocalStorageKey = (
  zoneParams,
  measurements,
  interval,
  range,
  fromDate,
  toDate,
  sensorId
) => {
  const prefix = `${zoneParams.siteId}-${sensorId}-${measurements.join(
    '-'
  )}-${interval}`

  if (range && range !== 'customRange') {
    return `${prefix}-${range}`
  }
  return `${prefix}-${fromDate}-${toDate}`
}

const getCacheLog = () => {
  let cacheLog = localStorage.getItem(CACHELOG_KEY)
  if (!cacheLog) {
    cacheLog = JSON.stringify([])
  }
  return JSON.parse(cacheLog)
}

const updateCacheLog = key => {
  const cacheLog = getCacheLog()
  const cacheLogIndex = cacheLog.findIndex(l => l[0] === key)
  if (cacheLogIndex >= 0) {
    cacheLog.splice(cacheLogIndex, 1)
  }
  cacheLog.push([key, Date.now()])
  window.localStorage.setItem(CACHELOG_KEY, JSON.stringify(cacheLog))
}

const updateCache = (key, measurementData) => {
  window.localStorage.removeItem(key)
  window.localStorage.setItem(
    key,
    JSON.stringify({
      timestamp: Date.now() + 1000 * 60 * 60,
      data: measurementData
    })
  )
}

export const cacheResult = (key, measurementData) => {
  try {
    updateCache(key, measurementData)
  } catch (e) {
    // Probably QuotaExceededError
    const cacheLog = getCacheLog()
    if (cacheLog.length === 0) {
      // there is no cacheLog so cache is old
      window.localStorage.clear()
    }
    for (let i = cacheLog.length - 1; i >= 0; i--) {
      window.localStorage.removeItem(cacheLog[i][0])
      cacheLog.pop()
    }
    window.localStorage.setItem(CACHELOG_KEY, JSON.stringify(cacheLog))
    updateCache(key, measurementData)
  }
  updateCacheLog(key)
}

import { I18n } from 'aws-amplify'
import { Flex, Text, Icon } from '@/primitives'

const String = () => ({
  noData: I18n.get('No Data'),
  subtitleDefault: I18n.get('Subtitle')
})

export default function Value({
  data: {
    value = null,
    unit = null,
    status = null,
    min = null,
    max = null,
    subtitle = null
  },
  thresholdStatus = null, // only used in live query
  prediction = null // only used in live query
}) {
  const strings = String()
  const getStatusIcon = status => {
    if (status === 'increase') {
      return 'arrow_drop_up'
    } else if (status === 'decrease') {
      return 'arrow_drop_down'
    }
    return 'drag_handle'
  }

  const threshold_color = {
    notification: 'warning',
    caution: 'danger',
    warning: 'error'
  }

  let variant = 'primary'
  let tone = 500

  if (thresholdStatus && value) {
    variant =
      threshold_color[thresholdStatus.statusInfo.status.level] || 'primary'
    tone = 600
  }

  return (
    <Flex
      direction='column'
      axisGap={200}
      style={{ height: '100%' }}
      className={'MosaicV2__Tile__Content__Static'}
    >
      <Flex alignMainAxis='space-between' css={{ margin: '0 0.5em' }}>
        {status && (
          <Icon
            size='500'
            tone={tone}
            variant={variant}
            name={getStatusIcon(status)}
          />
        )}

        {!status && <Text fontFamily={'mono'} size={200}></Text>}

        <Text fontFamily={'mono'} size={200}>
          {unit || ''}
        </Text>
      </Flex>

      <Flex
        alignCrossAxis='center'
        alignMainAxis='space-around'
        style={{ flex: 1 }}
      >
        <Flex direction={'column'} alignCrossAxis={'center'}>
          <Text
            fontFamily={'mono'}
            variant={variant}
            tone={tone}
            size={600}
            fontWeight={700}
          >
            {value || strings.noData}
          </Text>
          <Text
            fontFamily={'mono'}
            size={200}
            textTransform={'uppercase'}
            variant='page'
            tone={900}
          >
            {subtitle || strings.subtitleDefault}
          </Text>
        </Flex>
        {prediction?.value && (
          <Flex direction={'column'} alignCrossAxis={'center'}>
            <Text
              fontFamily={'mono'}
              variant={'success'}
              tone={500}
              size={600}
              fontWeight={700}
            >
              {prediction.value}
            </Text>
            <Text
              fontFamily='mono'
              size={200}
              textTransform='uppercase'
              variant={'success'}
              tone={500}
            >
              {I18n.get('+1 hour')}
            </Text>
          </Flex>
        )}
      </Flex>
      <Flex
        alignMainAxis='space-between'
        axisGap={400}
        css={{ margin: '0 0.5em', padding: '8px' }}
      >
        <Text fontFamily={'mono'} size={200} fontWeight={700}>
          {min}
        </Text>
        <Text fontFamily={'mono'} size={200} fontWeight={700}>
          {max}
        </Text>
      </Flex>
    </Flex>
  )
}

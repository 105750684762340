import { useReducer, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import DatePicker from 'react-datepicker'

import useDeviceSize from '@/hooks/useDeviceSize'

import { requestCustomers } from '@/actions/operations/customer'
import { requestAllCountries } from '@/actions/operations/country'
import { requestAllCities } from '@/actions/operations/city'
import { requestAllAreas } from '@/actions/operations/area'
import { requestAllCurrencies } from '@/actions/operations/currency'
import { requestAllPaymentTerms } from '@/actions/operations/paymentTerm'
import { requestAllPaymentMethods } from '@/actions/operations/paymentMethod'
import { requestAllRegions } from '@/actions/operations/region'

import {
  createBranch,
  requestBranch,
  updateBranch
} from '@/actions/operations/branch'

import {
  getCurrentUserOrganizations,
  getOperationsCustomers,
  getOperationsAllCountries,
  getOperationsAllRegions,
  getOperationsAllCities,
  getOperationsAllAreas,
  getOperationsAllCurrencies,
  getOperationsAllPaymentMethods,
  getOperationsAllPaymentTerms,
  getOperationsBranch,
  getOperationsLoading,
  getOperationsError
} from '@/reducers/selectors'

import { Box, Flex, Label, Select, Text, Input, Loader } from '@/primitives'

import InputError from '@/elements/InputError'
import OrganizationInput from '../../../Shared/Organization/Input'

import { getValidationErrorMap } from '@/Util/GeneralUtils'
import history from '../../../../../history'
import Strings from '../Strings'

import { initialState, reducer, UPDATE_INPUT, SET_STATE } from './state'

import { BRANCH_STATUS_TYPE } from '../utils'
import { FIELDS, SCHEMA } from './config'
import HeaderV2 from '@/components/Operations/Shared/HeaderV2'

function BranchForm({ state, modulePath }) {
  const dispatch = useDispatch()
  const isMobile = useDeviceSize('mobile')
  const isLaptop = useDeviceSize()
  const { itemId } = useParams()

  const strings = Strings()

  const organizations = getCurrentUserOrganizations()
  const countries = getOperationsAllCountries()
  const regions = getOperationsAllRegions()
  const cities = getOperationsAllCities()
  const areas = getOperationsAllAreas()
  const paymentMethods = getOperationsAllPaymentMethods()
  const paymentTerms = getOperationsAllPaymentTerms()
  const currencies = getOperationsAllCurrencies()
  const customers = getOperationsCustomers()
  const branch = getOperationsBranch()
  const loading = getOperationsLoading()
  const error = getOperationsError()

  const branchStatus = BRANCH_STATUS_TYPE(strings)

  const [formState, dispatchFormState] = useReducer(reducer, initialState)

  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)

  useEffect(() => {
    dispatch(requestAllCountries())
    dispatch(requestAllRegions())
    dispatch(requestAllCities())
    dispatch(requestAllAreas())
    dispatch(requestAllPaymentMethods())
    dispatch(requestAllPaymentTerms())
    dispatch(requestAllCurrencies())
  }, [dispatch])

  useEffect(() => {
    dispatch(
      requestCustomers({
        filter: {
          organizationId: organizations.map(o => o.id)
        }
      })
    )
  }, [organizations, dispatch])

  useEffect(() => {
    if (itemId && branch.id !== itemId) {
      dispatch(requestBranch({ branchId: itemId }))
    }
  }, [itemId])

  useEffect(() => {
    if (branch?.id && branch.id === itemId) {
      dispatchFormState({
        type: SET_STATE,
        state: { ...branch }
      })
    }
  }, [branch])

  useEffect(() => {
    if (!itemId && state.organizations.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'organizationId',
        value: state.organizations[0]
      })
    }
  }, [state.organizations])

  useEffect(() => {
    if (!itemId && state.customers.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'customerId',
        value: state.customers[0]
      })
    }
  }, [state.customers])

  useEffect(() => {
    if (waiting && !loading && !error) {
      history.replace(modulePath)
    }
  }, [loading])

  const onSubmit = async e => {
    try {
      const payload = await SCHEMA().validate(formState, { abortEarly: false })
      setWaiting(true)

      if (itemId) {
        payload.branchId = itemId
        dispatch(updateBranch(payload))
      } else {
        dispatch(createBranch(payload))
      }
    } catch (error) {
      setErrors(getValidationErrorMap(error))
    }
  }

  const handleInput = e => {
    const { name, value } = e.currentTarget
    dispatchFormState({ type: UPDATE_INPUT, name, value })
  }

  const handleDateInput = (date, name) => {
    dispatchFormState({ type: UPDATE_INPUT, name, value: date })
  }

  const handleOrganizationInput = organizationId => {
    dispatchFormState({
      type: SET_STATE,
      state: {
        ...formState,
        organizationId,
        customerId: '',
        countryId: '',
        regionId: '',
        cityId: '',
        areaId: '',
        currencyId: ''
      }
    })
  }

  const filterCountries = () => {
    return countries.filter(c => c.organizationId === formState.organizationId)
  }

  const filterRegions = () => {
    return regions.filter(r => r.countryId === formState.countryId)
  }

  const filterCities = () => {
    return cities.filter(c => c.regionId === formState.regionId)
  }

  const filterAreas = () => {
    return areas.filter(a => a.cityId === formState.cityId)
  }

  const filterCurrencies = () => {
    return currencies.filter(c => c.organizationId === formState.organizationId)
  }

  const getHeader = () => {
    if (itemId) {
      return strings.updateFormHeader
    }
    return strings.createFormHeader
  }

  return (
    <Flex
      as='form'
      axisGap={400}
      direction='column'
      className='Operations__Form'
    >
      <HeaderV2
        title={getHeader()}
        backPath={modulePath}
        buttonIcon={'save'}
        buttonText={strings.saveBranch}
        buttonCallback={onSubmit}
      />
      <Loader isLoading={loading}>
        <Flex axisGap={800} direction={isLaptop ? 'column' : null}>
          <Flex
            axisGap={400}
            direction='column'
            className='Operations__Form__Fields'
          >
            <Box className='Branch'>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.branch}
              </Text>
              <OrganizationInput
                fieldName={FIELDS.organizationId}
                organizationId={formState.organizationId}
                handleInput={handleOrganizationInput}
                errors={errors}
              />
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label>
                  <Text variant='page' tone={700}>
                    {strings.customer}
                  </Text>
                  <Select
                    className='Operations__Select'
                    name={FIELDS.customerId}
                    value={formState.customerId}
                    onChange={handleInput}
                    style={{
                      display: 'block',
                      width: 'auto'
                    }}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {customers.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.customerId} />
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: 2 }}>
                  <Text variant='page' tone={700}>
                    {strings.name}
                  </Text>
                  <Input
                    value={formState.name}
                    name={FIELDS.name}
                    onChange={handleInput}
                  />
                  <InputError error={errors?.name} />
                </Label>
                <Label style={{ flex: 1 }}>
                  <Text variant='page' tone={700}>
                    {strings.code}
                  </Text>
                  <Input
                    value={formState.code}
                    name={FIELDS.code}
                    onChange={handleInput}
                  />
                  <InputError error={errors?.code} />
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label>
                  <Text variant='page' tone={700}>
                    {strings.status}
                  </Text>
                  <Select
                    className='Operations__Select'
                    name={FIELDS.status}
                    value={formState.status}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {Object.entries(branchStatus).map(([id, name]) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.status} />
                </Label>
                <Label>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.onboardDate}
                    </Text>
                    <DatePicker
                      maxDate={new Date()}
                      selected={formState.onboardDate}
                      className='Operations__Form__Select'
                      onChange={date =>
                        handleDateInput(date, FIELDS.onboardDate)
                      }
                    />
                    <InputError error={errors?.onboardDate} />
                  </Flex>
                </Label>
              </Flex>
            </Box>
            <Box className='Contact'>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.contact}
              </Text>
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label>
                  <Text variant='page' tone={700}>
                    {strings.contactName}
                  </Text>
                  <Input
                    value={formState.contactName}
                    name={FIELDS.contactName}
                    onChange={handleInput}
                  />
                  <InputError error={errors?.contactName} />
                </Label>
                <Label>
                  <Text variant='page' tone={700}>
                    {strings.contactNumber}
                  </Text>
                  <Input
                    value={formState.contactNumber}
                    name={FIELDS.contactNumber}
                    onChange={handleInput}
                  />
                  <InputError error={errors?.contactNumber} />
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label>
                  <Text variant='page' tone={700}>
                    {strings.contactEmail}
                  </Text>
                  <Input
                    value={formState.contactEmail}
                    name={FIELDS.contactEmail}
                    onChange={handleInput}
                    type='email'
                  />
                  <InputError error={errors?.contactEmail} />
                </Label>
              </Flex>
            </Box>
            <Box className='Location'>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.entryLocation}
              </Text>
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label>
                  <Text variant='page' tone={700}>
                    {strings.country}
                  </Text>
                  <Select
                    className='Operations__Select'
                    name={FIELDS.countryId}
                    value={formState.countryId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterCountries().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.countryId} />
                </Label>
                <Label>
                  <Text variant='page' tone={700}>
                    {strings.region}
                  </Text>
                  <Select
                    className='Operations__Select'
                    name={FIELDS.regionId}
                    value={formState.regionId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterRegions().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.regionId} />
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label>
                  <Text variant='page' tone={700}>
                    {strings.city}
                  </Text>
                  <Select
                    className='Operations__Select'
                    name={FIELDS.cityId}
                    value={formState.cityId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterCities().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.cityId} />
                </Label>
                <Label>
                  <Text variant='page' tone={700}>
                    {strings.area}
                  </Text>
                  <Select
                    className='Operations__Select'
                    name={FIELDS.areaId}
                    value={formState.areaId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterAreas().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.areaId} />
                </Label>
              </Flex>
            </Box>
            <Box className='Payment'>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.payment}
              </Text>
              <Flex axisGap={300} alignMainAxis='space-evenly'>
                <Label>
                  <Text variant='page' tone={700}>
                    {strings.currency}
                  </Text>
                  <Select
                    className='Operations__Select'
                    name={FIELDS.currencyId}
                    value={formState.currencyId}
                    onChange={handleInput}
                    style={{
                      display: 'block',
                      width: 'auto'
                    }}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterCurrencies().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.currencyId} />
                </Label>
              </Flex>
              <Flex axisGap={300} alignMainAxis='space-evenly'>
                <Label>
                  <Text variant='page' tone={700}>
                    {strings.paymentMethod}
                  </Text>
                  <Select
                    className='Operations__Select'
                    name={FIELDS.paymentMethodId}
                    value={formState.paymentMethodId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {paymentMethods.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.paymentMethodId} />
                </Label>
                <Label>
                  <Text variant='page' tone={700}>
                    {strings.paymentTerms}
                  </Text>
                  <Select
                    className='Operations__Select'
                    name={FIELDS.paymentTermId}
                    value={formState.paymentTermId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {paymentTerms.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.paymentTermId} />
                </Label>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Loader>
    </Flex>
  )
}

export default BranchForm

import { DateTime } from 'luxon'

import useDatasetData from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Hooks/useDatasetData'
import Line from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/Chart/Line'
import { convertToGDH } from './utils'
import TimeUtils from '@/Util/TimeUtils'
import { useDashboard } from '../../../context'
import { HOURLY, getRanges } from '../../Shared/Dynamic/config'

export default function DatasetLine({ unit, config, ...props }) {
  const { state } = useDashboard()
  const rangeQuery = getRanges(config.range)?.query
  const fromDate = TimeUtils.initStartDateTVariant(
    state.currentZone.timeZone,
    rangeQuery[0],
    rangeQuery[1]
  )

  const toDate = TimeUtils.getYesterday(state.currentZone.timeZone).endDate

  const { datasetData, queryZones } = useDatasetData({
    measurements: ['temperature'],
    range: config.range,
    interval: HOURLY, // It's hourly here even though it's daily in the config because we want to get the hourly data for the day.
    requestParams: {
      timeZone: state.currentZone.timeZone,
      fromDate: TimeUtils.formatToUTC(fromDate),
      toDate: TimeUtils.formatToUTC(toDate)
    }
  })

  let chartData = {}

  if (datasetData) {
    for (let { zoneName } of queryZones) {
      const temperatureData = datasetData['temperature'][zoneName] || []

      let aggregateData = {}

      for (let dataPoint of temperatureData) {
        let nextDay = DateTime.fromISO(dataPoint.x).toFormat('yyyy-MM-dd')

        if (!aggregateData[nextDay]) {
          aggregateData[nextDay] = {
            value: 0,
            count: 0
          }
        }

        aggregateData[nextDay].value += dataPoint.y
        aggregateData[nextDay].count++
      }

      if (Object.keys(aggregateData).length > 0) {
        chartData[zoneName] = []

        for (let [key, data] of Object.entries(aggregateData)) {
          const avgDailyTemp = parseFloat(data.value / data.count)

          chartData[zoneName].push({
            x: key,
            y: convertToGDH({
              temperature: avgDailyTemp
            })
          })
        }
      }
    }
  }

  return (
    <Line
      {...props}
      data={chartData}
      unit={unit}
      config={config}
      queryZones={queryZones}
    />
  )
}

export function getSensorTypeIcon(type) {
  const ICONS = {
    envirosense: 'light_mode',
    watersense: 'water_drop',
    soilsense: 'grass',
    camera: 'videocam',
    dissolved_oxygen: 'bubble_chart',
    energy_monitor: 'electric_meter',
    water_flow_meter: 'water',
    pyranometer: 'bolt',
    weather_station: 'wind_power',
    airflow_meter: 'air_purifier_gen',
    cellular_envirosense: 'light_mode',
    cellular_watersense: 'water_drop',
    cellular_soilsense: 'grass',
    cellular_camera: 'videocam',
    cellular_dissolved_oxygen: 'bubble_chart',
    cellular_energy_monitor: 'electric_meter',
    cellular_water_flow_meter: 'water',
    cellular_pyranometer: 'bolt',
    cellular_weather_station: 'wind_power',
    cellular_airflow_meter: 'air_purifier_gen'
  }

  return ICONS[type] ? ICONS[type] : 'developer board'
}

import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { getCognitoData, makeGraphqlRequest } from '../utils'

/*
 * Queries
 */

export const requestCalibrationHistory = async params => {
  try {
    const query = /* GraphQL */ `
      query requestCalibrationHistory($sensorId: String!, $limit: Int) {
        requestCalibrationHistory(sensorId: $sensorId, limit: $limit) {
          correlationId
          createdAt
          inputType
          inputValue
          measurementType
          measurementValue
          sensorId
          status
          userId
          user {
            username
          }
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        history: [],
        error: `Calibration history not fetched: ${error}`
      }
    }

    return { history: data?.requestCalibrationHistory, error }
  } catch (err) {
    return { history: [], error: err.message }
  }
}

/*
 * Mutations
 */

export const calibrateDevice = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation calibrateDevice(
        $action: String
        $sensorType: String!
        $userId: String!
        $sensorId: String!
        $inputType: String!
        $inputValue: Float!
        $inputValue2: Float
        $measurementType: String!
        $measurementValue: Float!
        $isGreengrass: Boolean!
        $isLegacy: Boolean
        $userCoefficient: UserCoefficientInput
        $capCoefficient: CapCoefficientInput
      ) {
        calibrateDevice(
          action: $action
          sensorType: $sensorType
          userId: $userId
          sensorId: $sensorId
          inputType: $inputType
          inputValue: $inputValue
          inputValue2: $inputValue2
          measurementType: $measurementType
          measurementValue: $measurementValue
          isGreengrass: $isGreengrass
          isLegacy: $isLegacy
          userCoefficient: $userCoefficient
          capCoefficient: $capCoefficient
        ) {
          sensorId
          measurementType
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        device: {},
        error: `Device calibration unsuccessful: ${error}`
      }
    }

    return { device: data?.calibrateDevice, error }
  } catch (err) {
    return { device: {}, error: err.message }
  }
}

import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { makeGraphqlRequestV2 } from '../utils'

export const getSiteOfflineDevices = async params => {
  try {
    const query = /* GraphQL */ `
      query getSiteOfflineDevices($siteId: String!) {
        getSiteOfflineDevices(siteId: $siteId) {
          deviceId
          siteId
          status
          reasons {
            missingData
          }
          lastChecked
        }
      }
    `

    const operation = graphqlOperation(query, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)
    return data?.getSiteOfflineDevices
  } catch (err) {
    throw err
  }
}

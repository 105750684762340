import { Text } from '../../primitives'

function InputError({ error = null, variant = 'error', tone = 600, props }) {
  return (
    <Text as='p' className='FormError' variant={variant} tone={tone} {...props}>
      {error}
    </Text>
  )
}

export default InputError

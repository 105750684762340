export const tableInitialState = {
  organizations: [],
  categories: [],
  statuses: ['available'],
  page: 1
}

export const tableReducer = (state, action) => {
  if (action.page) {
    return {
      ...state,
      page: action.page
    }
  } else if (action.organizations) {
    return {
      ...state,
      organizations: action.organizations
    }
  } else if (action.categories) {
    return {
      ...state,
      categories: action.categories
    }
  } else if (action.statuses) {
    return {
      ...state,
      statuses: action.statuses
    }
  }
  return state
}

export const formInitalState = {
  id: null,
  organizationId: '',
  category: '',
  status: '',
  name: ''
}

export const formReducer = (state, action) => {
  if (action.type === 'update') {
    return { ...state, [action.name]: action.value }
  }
  if (action.type === 'set-state') {
    return action.state
  }
  return state
}

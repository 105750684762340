import { FlexV2, Text, Input } from '@/primitives'

export default function FormInput({
  error,
  name,
  label,
  value,
  onChange,
  as = 'input', // 'input' | 'textarea'
  className = '',
  disabled = false,
  readOnly = false
}) {
  return (
    <FlexV2 className={className} direction='column' axisGap={300}>
      <Text className='FormInput__Label' variant='page' tone={900} size={100}>
        {label}
      </Text>
      <Input
        className='FormInput__Input'
        as={as}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
      />
      <Text
        className='FormInput__Error'
        variant='error'
        size={100}
        tone={500}
        style={{ height: '1.5rem' }}
        as='p'
      >
        {error || ''}
      </Text>
    </FlexV2>
  )
}

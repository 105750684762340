import { I18n } from 'aws-amplify'
import withEditableWidget from '@/components/DashboardPageV2/Desktop/Widgets/Shared/HOC/withEditableWidget'
import { WidgetDefinition } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/utils/WidgetRegistry'
import {
  BAR_CHART,
  LAST_3_MONTHS,
  DATASET_QUERY,
  PIE_CHART,
  LINE_CHART
} from '@/components/DashboardPageV2/Desktop/Widgets/Shared//Dynamic/config'
import { operationTypes } from './types'

import DatasetBar from './Dataset/DatasetBar'
import { OperationEdit } from './OperationEdit'
import DatasetPie from './Dataset/DatasetPie'
import DatasetLine from './Dataset/DatasetLine'
import { defaultOperationQueryOptions } from './utils'

function DefaultOperationView(props) {
  const { config, options } = props

  if (config.query === DATASET_QUERY) {
    switch (config.chart) {
      case BAR_CHART:
      default:
        return <DatasetBar config={config} options={options} />
      case PIE_CHART:
        return <DatasetPie config={config} options={options} />
      case LINE_CHART:
        return <DatasetLine config={config} options={options} {...props} />
    }
  }
}

export function DefaultOperationEdit(props) {
  const options = {
    queryOptionsList: defaultOperationQueryOptions
  }

  return <OperationEdit {...props} options={options} />
}

const OperationWidget = withEditableWidget(
  DefaultOperationView,
  DefaultOperationEdit
)
class OperationWidgetDefinition extends WidgetDefinition {
  constructor({
    widgetId,
    title,
    icon,
    component = null,
    queryOptionsList = null,
    defaultPosition = null,
    config = null,
    options = {},
    permissionIds = null,
    denyVisibility = null
  }) {
    const defaultPositionDefault = {
      lg: [0, 0, 5, 5, 5, 5]
    }
    const defaultConfig = {
      query: DATASET_QUERY,
      chart: BAR_CHART,
      range: LAST_3_MONTHS,
      interval: null
    }

    const defaultOptions = {
      unit: I18n.get('Kg'),
      keys: ['Crops', 'Plan', 'Harvest'],
      legend: I18n.get('Produce'),
      segmentId: widgetId,
      queryOptionsList: queryOptionsList || defaultOperationQueryOptions
    }

    const widgetOptions = { ...defaultOptions, ...options }

    const widgetConfig = { ...defaultConfig, ...config }

    const superParams = {
      widgetId,
      title,
      icon,
      component: component ?? OperationWidget,
      defaultPosition: defaultPosition ?? defaultPositionDefault,
      config: widgetConfig,
      options: widgetOptions,
      permissionIds: permissionIds,
      visibility: denyVisibility
    }

    super(superParams)
  }
}

export const OperationWidgetDefinitions = () => {
  const operationWidgets = Object.keys(operationTypes).map(key => {
    return new OperationWidgetDefinition({ ...operationTypes[key] })
  })

  return operationWidgets
}

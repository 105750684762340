import { makeOperationsApiCall } from './utils'

export const getPaymentMethods = async params => {
  try {
    const query = /* GraphQL */ `
      query getPaymentMethods(
        $filter: GetPaymentMethodsFilter
        $order: OrderEnum
        $offset: Int
        $limit: Int
      ) {
        getPaymentMethods(
          filter: $filter
          order: $order
          offset: $offset
          limit: $limit
        ) {
          paymentMethods {
            id
            organizationId
            name
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getPaymentMethods',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getPaymentMethod = async params => {
  try {
    const query = /* GraphQL */ `
      query getPaymentMethod($paymentMethodId: String!) {
        getPaymentMethod(paymentMethodId: $paymentMethodId) {
          id
          organizationId
          name
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getPaymentMethod',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const createPaymentMethod = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createPaymentMethod($organizationId: String!, $name: String!) {
        createPaymentMethod(organizationId: $organizationId, name: $name) {
          id
          organizationId
          name
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'createPaymentMethod',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updatePaymentMethod = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updatePaymentMethod(
        $paymentMethodId: String!
        $organizationId: String!
        $name: String!
      ) {
        updatePaymentMethod(
          paymentMethodId: $paymentMethodId
          organizationId: $organizationId
          name: $name
        ) {
          id
          organizationId
          name
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'updatePaymentMethod',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

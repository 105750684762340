import { useEffect, useState } from 'react'
import { I18n } from 'aws-amplify'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { number, object, string } from 'yup'

import {
  createCountry,
  requestCountry,
  updateCountry
} from '@/actions/operations/country'

import { requestAllCurrencies } from '@/actions/operations/currency'

import {
  getOperationsCountry,
  getOperationsAllCurrencies,
  getOperationsLoading,
  getOperationsError
} from '@/reducers/selectors'

import { Flex, Form, Input, Label, Select, Text, Loader } from '@/primitives'

import InputError from '@/elements/InputError'

import HeaderV2 from '@/components/Operations/Shared/HeaderV2'
import OrganizationInput from '../../Shared/Organization/Input'

import { getValidationErrorMap } from '@/Util/GeneralUtils'
import history from '../../../../history'
import { useReducer } from 'react'
import { formInitalState, formReducer } from './state'

const SCHEMA = object({
  organizationId: string().required(I18n.get('An organization is required.')),
  name: string().required(I18n.get('Name is required.')),
  isoCode: string().required(I18n.get('ISO Code a required field.')),
  dialingCode: number().required(I18n.get('Dialing Code a required field.')),
  currencyId: string().required(I18n.get('A Currency must be selected.'))
})

function CountryForm({ state, modulePath }) {
  const dispatch = useDispatch()
  const { itemId } = useParams()

  const country = getOperationsCountry()
  const currencies = getOperationsAllCurrencies()
  const loading = getOperationsLoading()
  const error = getOperationsError()

  const [formState, dispatchFormState] = useReducer(
    formReducer,
    formInitalState
  )

  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)

  useEffect(() => {
    dispatch(requestAllCurrencies())
  }, [dispatch])

  useEffect(() => {
    if (itemId && country.id !== itemId) {
      dispatch(requestCountry({ countryId: itemId }))
    }
  }, [dispatch, country?.id, itemId])

  useEffect(() => {
    if (country?.id && country.id === itemId) {
      dispatchFormState({ type: 'set-state', state: country })
    }
  }, [country, itemId])

  useEffect(() => {
    if (!itemId && state.organizations.length === 1) {
      dispatchFormState({
        type: 'update',
        name: 'organizationId',
        value: state.organizations[0]
      })
    }
  }, [state.organizations, itemId])

  useEffect(() => {
    if (waiting && !loading && !error) {
      history.replace(modulePath)
    }
  }, [waiting, loading, error, modulePath])

  const onSubmit = async e => {
    e?.preventDefault()
    try {
      await SCHEMA.validate(formState, { abortEarly: false })
      setWaiting(true)

      if (itemId) {
        formState.countryId = itemId
        dispatch(updateCountry(formState))
      } else {
        dispatch(createCountry(formState))
      }
    } catch (err) {
      setErrors(getValidationErrorMap(err))
    }
  }

  const handleOrganizationInput = organizationId => {
    dispatchFormState({
      type: 'update',
      name: 'organizationId',
      value: organizationId
    })
  }

  const onChange = e => {
    let { name, value } = e.currentTarget
    if (name === 'dialingCode') {
      value = Number(value)
    }
    dispatchFormState({ type: 'update', name, value })
  }

  const filterCurrencies = () => {
    return currencies.filter(c => c.organizationId === formState.organizationId)
  }

  return (
    <Form className='Operations__Form'>
      <HeaderV2
        title={I18n.get('Manage Country')}
        buttonIcon={'save'}
        buttonText={I18n.get('Save Country')}
        buttonCallback={onSubmit}
        backPath={modulePath}
      />
      <Loader isLoading={loading}>
        <OrganizationInput
          fieldName={'organizationId'}
          organizationId={formState.organizationId}
          handleInput={handleOrganizationInput}
          errors={errors}
        />
        <Flex axisGap={300} className='Operations__Form__Fields'>
          <Label>
            <Text variant='page' tone={700}>
              {I18n.get('Name')}
            </Text>
            <Input value={formState.name} name='name' onChange={onChange} />
            <InputError error={errors?.name} />
          </Label>
          <Label>
            <Text variant='page' tone={700}>
              {I18n.get('Currency')}
            </Text>
            <Select
              className='Operations__Form__Select'
              name='currencyId'
              value={formState.currencyId}
              onChange={onChange}
            >
              <option default value=''>
                {I18n.get('Please select')}
              </option>
              {filterCurrencies().map(({ name, id }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Select>
            <InputError error={errors?.currencyId} />
          </Label>
        </Flex>
        <Flex axisGap={300} className='Operations__Form__Fields'>
          <Label>
            <Text variant='page' tone={700}>
              {I18n.get('Dialing Code')}
            </Text>
            <Input
              value={formState.dialingCode}
              name='dialingCode'
              onChange={onChange}
            />
            <InputError error={errors?.dialingCode} />
          </Label>
          <Label>
            <Text variant='page' tone={700}>
              {I18n.get('ISO Code')}
            </Text>
            <Input
              value={formState.isoCode}
              name='isoCode'
              onChange={onChange}
            />
            <InputError error={errors?.isoCode} />
          </Label>
        </Flex>
      </Loader>
    </Form>
  )
}

export default CountryForm

import { Symbol } from '@/primitives'

import { STATUSMAP } from '../constants'

/**
 * A component that displays an icon with a status-based styling.
 *
 * @param {object} props - The component props.
 * @param {number} props.size - The size of the icon. Defaults to 300.
 * @param {string} props.status - The status of the icon.
 * @return {JSX.Element} The rendered StatusIcon component.
 */
export default function StatusIcon({ size = 300, status }) {
  return (
    <Symbol
      size={size}
      name={(STATUSMAP[status] || STATUSMAP.loading).icon}
      variant={(STATUSMAP[status] || STATUSMAP.loading).variant}
      tone={500}
    />
  )
}

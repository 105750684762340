import { Flex, Text, Button, Label } from '@/primitives'
import { getMeasurements } from '@/reducers/selectors'
import { widgetRegistry } from '../..'
import Select from '@/elements/Select'
import { useState } from 'react'
import {
  createDownloadableCsv,
  createDownloadableFilename,
  createDownloadableImage,
  promptDownload
} from '../utils/dataset'
import { useDashboard } from '../../../context'
import { LINE_CHART } from '../Dynamic/config'

const Strings = () => ({
  title: 'Export Data',
  subtitle: 'Export data from this widget',
  cancel: 'Cancel',
  export: 'Export',
  selectFormat: 'Select an export format'
})

const getFormatOptions = chart => {
  let PNG = { label: 'PNG', value: 'png' }
  let CSV = { label: 'CSV', value: 'csv' }

  if (chart !== LINE_CHART) {
    return [PNG]
  }
  return [PNG, CSV]
}

export default function ExportData({
  id,
  widgetId,
  setWidgetMode,
  ...widgetProps
}) {
  const definition = widgetRegistry.get(widgetId)
  const strings = Strings()
  const measurements = getMeasurements()
  const { state } = useDashboard()
  const formatOptions = getFormatOptions(widgetProps.config.chart)

  const [selectedFormat, setSelectedFormat] = useState(formatOptions[0].value)

  const handleCancel = e => {
    e.preventDefault()
    setWidgetMode('view')
  }

  const onExportHandler = async e => {
    const { config } = widgetProps
    const fileName = createDownloadableFilename(
      config.range,
      config.interval,
      definition.options.measurement
    )

    if (selectedFormat === 'png') {
      const unit = measurements?.find(
        m => m.id === definition.options.measurement
      )?.unit

      const image = await createDownloadableImage(widgetProps, definition, unit)

      promptDownload(image, fileName + '.png')
    }

    if (selectedFormat === 'csv') {
      const dataset = state.downloadableDatasets.get(id)
      if (dataset) {
        const csv = createDownloadableCsv(
          dataset,
          state.hierarchy.timeZone,
          state.hierarchy.name
        )

        const file = new Blob([csv], { type: 'text/csv' })
        const href = URL.createObjectURL(file)
        promptDownload(href, fileName + '.csv')
      }
    }
  }

  const handleFormatChange = e => {
    setSelectedFormat(e.value)
  }

  return (
    <Flex className='WidgetEdit' direction='column' wrap='nowrap' axisGap={500}>
      <Flex alignMainAxis='space-between' wrap='nowrap'>
        <Flex direction='column' wrap='nowrap'>
          <Text className='WidgetEdit__Title'>{strings.title}</Text>
          <Text className='WidgetEdit__Subtitle' variant='page' tone={800}>
            {strings.subtitle}
          </Text>
        </Flex>
        <Flex axisGap={300} wrap='nowrap'>
          <Button size='small' variant='error' onClick={handleCancel}>
            {strings.cancel}
          </Button>
          <Button size='small' variant='primary' onClick={onExportHandler}>
            {strings.export}
          </Button>
        </Flex>
      </Flex>
      <Flex>
        <Label>
          <Text variant='page' tone={800}>
            {strings.selectFormat}
          </Text>
          <Select
            className='DashboardHeader__Controls__Select'
            onChange={handleFormatChange}
            options={formatOptions}
            value={selectedFormat}
          />
        </Label>
      </Flex>
    </Flex>
  )
}

import { I18n } from 'aws-amplify'

const Strings = () => ({
  fieldsetMeasurements: I18n.get('Measurements'),
  buttonAddMeasurement: I18n.get('Add Measurement'),
  fieldMeasurementsNoEntries: I18n.get('No measurements'),
  fieldMeasurement: I18n.get('Measurement'),
  fieldUnit: I18n.get('Unit'),
  fieldsetLower: I18n.get('Lower Bounds'),
  fieldsetUpper: I18n.get('Upper Bounds'),
  fieldStress: I18n.get('Stress'),
  fieldBad: I18n.get('Bad'),
  fieldGood: I18n.get('Good'),
  produceThresholdCreated: I18n.get('Produce threshold created successfully.'),
  produceThresholdUpdated: I18n.get('Produce threshold updated successfully.'),
  fahrenheitWarning: I18n.get('Please note that temperatures are stored in Celsius and may change when converted.')
})

export default Strings

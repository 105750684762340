import { I18n } from 'aws-amplify'

const Strings = () => ({
  // Harvest
  harvest: I18n.get('Harvest'),
  organization: I18n.get('Organization'),
  produce: I18n.get('Produce'),
  variety: I18n.get('Variety'),
  totalWeight: I18n.get('Total Weight'),
  harvestDate: I18n.get('Harvest date'),
  harvestData: I18n.get('Harvest data'),
  noData: I18n.get('No data to display'),
  searchHarvests: I18n.get('Search for harvests'),
  harvestCustomBarButton: I18n.get('View weight by grade'),
  noDataToShow: I18n.get('No data to show for'),
  weight: I18n.get('Weight'),
  kilograms: I18n.get('kg'),

  // Crops
  cropsData: I18n.get('Crops data'),
  searchCrops: I18n.get('Search for crops'),
  plantingArea: (
    <span>
      {I18n.get('Planting area m')}
      <sup>2</sup>
    </span>
  ),
  seedQuantity: I18n.get('Seed quantity'),
  status: I18n.get('Status')
})

export default Strings

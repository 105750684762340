import axios from 'axios'
import { SUPPORT_URL } from './config'

export const sendSupportEmail = async payload => {
  try {
    const result = await axios.post(SUPPORT_URL, payload)
    return {
      result
    }
  } catch (error) {
    return { error }
  }
}

import { updateList } from '../util'

import {
  REQUEST_WASTES,
  RECEIVE_WASTES,
  REQUEST_WASTE,
  RECEIVE_WASTE,
  CREATE_WASTE,
  WASTE_CREATED,
  UPDATE_WASTE,
  WASTE_UPDATED
} from '@/actions/operations/waste'

export const wasteInitialState = {
  wastes: [],
  wastesCount: 0,
  waste: {},
  loading: false,
  error: null
}

const handlers = {
  [REQUEST_WASTES]: (state, action) => ({
    ...state,
    wastes: [],
    wastesCount: 0,
    error: null,
    loading: true
  }),
  [RECEIVE_WASTES]: (state, action) => ({
    ...state,
    wastes: !action.error ? action.wastes : [],
    wastesCount: action?.count ?? 0,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_WASTE]: state => ({
    ...state,
    waste: {},
    error: null,
    loading: true
  }),
  [RECEIVE_WASTE]: (state, action) => ({
    ...state,
    waste: !action.error ? action.waste : state.waste,
    error: action.error ?? null,
    loading: false
  }),
  [CREATE_WASTE]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [WASTE_CREATED]: (state, action) => ({
    ...state,
    waste: !action.error ? {} : state.waste,
    wastes: updateList(action.error, state.wastes, action.waste, true),
    wastesCount: !action.error ? state.wastesCount + 1 : state.wastesCount,
    error: action.error ?? null,
    loading: false
  }),
  [UPDATE_WASTE]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [WASTE_UPDATED]: (state, action) => ({
    ...state,
    waste: !action.error ? {} : state.waste,
    wastes: updateList(action.error, state.wastes, action.waste, false),
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

import { DateTime } from 'luxon'

/**
 * Retrieves the hardware versions for a given device type.
 * @param {string} type - The device type.
 * @param {Array} deviceTypes - The array of device types.
 * @returns {Array} - The array of hardware versions.
 */
export const getHardwareVersions = (type, deviceTypes) => {
  const hardwareVersions =
    deviceTypes.find(({ id }) => id === type)?.hardwareVersions || []

  return hardwareVersions.map(({ hardwareVersion }) => ({
    value: hardwareVersion,
    label: hardwareVersion
  }))
}

/**
 * Retrieves the folder name of the latest firmware version for a given hardware version.
 * @param {string} hardwareVersion - The hardware version.
 * @param {Array} deviceTypes - The array of device types.
 * @returns {string|null} - The folder name of the latest firmware version or null if not found.
 */
export function getLatestFirmwareVersion(
  hardwareVersion,
  deviceTypes,
  sensorType
) {
  const sensor = deviceTypes
    .filter(deviceType => deviceType.id === sensorType)
    .find(sensor => {
      return sensor?.hardwareVersions?.some(
        hw => hw.hardwareVersion === hardwareVersion
      )
    })

  if (sensor) {
    const targetHardwareVersion = sensor?.hardwareVersions.find(
      hw => hw.hardwareVersion === hardwareVersion
    )

    if (targetHardwareVersion) {
      const { flashScriptVersion } = targetHardwareVersion
      const sortedFirmwareVersions = [
        ...(targetHardwareVersion?.firmwareVersions ?? [])
      ]

      sortedFirmwareVersions.sort((a, b) => b.date - a.date)

      if (sortedFirmwareVersions?.length > 0) {
        const firmwareVersion =
          convertToFirmwareVersionFolderName(sortedFirmwareVersions?.[0]) ||
          null
        return { firmwareVersion, flashScriptVersion }
      }
    }
  }

  return { firmwareVersion: 'latest', flashScriptVersion: 'latest' }
}

/**
 * Converts a UNIX timestamp to the format 'yyyy-MM-dd-hhmmss'.
 * @param {object} timestamp - The timestamp object containing the 'date' property.
 * @returns {string} - The formatted date and time string.
 */
export function convertToFirmwareVersionFolderName({ date }) {
  const dateTime = DateTime.fromMillis(date * 1000).setZone('UTC')
  const formattedDateTime = dateTime.toFormat('yyyy-MM-dd-HHmmss')
  return formattedDateTime
}

import SectionHeaderWrapper from '../Utils/SectionHeaderWrapper'

import { FlexV2, Text } from '@/primitives'

import Strings from '../../Strings'

const Header = ({ selectedZone }) => {
  const { fleetManagementHeading, fleetManagementSubheading } = Strings()

  return (
    <SectionHeaderWrapper>
      <FlexV2 direction='column' axisGap={100}>
        <Text size={300} fontWeight={700}>
          {fleetManagementHeading}
        </Text>
        <Text fontFamily='mono' variant='page' tone={900} size={200}>
          {`${fleetManagementSubheading} ${selectedZone?.name}.`}
        </Text>
      </FlexV2>
    </SectionHeaderWrapper>
  )
}

export default Header

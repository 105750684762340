import { Fragment } from 'react'

import {
  Row,
  Slot,
  Table,
  Input,
  Flex,
  Button,
  Column,
  Cell,
  Text
} from '@/primitives'

import { Select } from '@/elements'

import Strings from '../../../Strings'

const VersionManager = ({
  loading,
  hardwareVersions,
  firmwareVersions,
  validFlashScripts,
  setHardwareVersions,
  updateHardwareVersions: update
}) => {
  const strings = Strings()

  const onAddHardwareVersion = () => {
    const latestFirmwareVersion = firmwareVersions[0]
    const newVersion = {
      hardwareVersion: `version_${hardwareVersions?.length + 1}`,
      firmwareVersions: [latestFirmwareVersion],
      active: true
    }
    update([...hardwareVersions, newVersion])
  }

  const onRemoveVersion = indexToRemove => {
    const versions = hardwareVersions.filter(
      (_, index) => index !== indexToRemove
    )
    update(versions)
  }

  const onChangeHardware = (value, index) => {
    setHardwareVersions(prevVersions => {
      const versions = [...prevVersions]
      versions[index] = { ...versions[index], hardwareVersion: value }
      update(versions)
      return versions
    })
  }

  const onChangeFirmware = (values, index) => {
    setHardwareVersions(prevVersions => {
      const versions = [...prevVersions]
      versions[index] = {
        ...versions[index],
        firmwareVersions: values.map(({ value, label }) => ({
          key: value,
          value,
          label
        }))
      }
      update(versions)
      return versions
    })
  }

  const onChangeFlashScript = (value, index) => {
    setHardwareVersions(prevVersions => {
      const versions = [...prevVersions]
      versions[index] = {
        ...versions[index],
        flashScriptVersion: value?.value
      }
      update(versions)
      return versions
    })
  }

  const onToggleActive = index => {
    setHardwareVersions(prevVersions => {
      const versions = [...prevVersions]
      versions[index] = {
        ...versions[index],
        active: !versions[index].active
      }
      update(versions)
      return versions
    })
  }

  return (
    <Fragment>
      {firmwareVersions?.length > 0 && (
        <Flex axisGap={300} direction='column'>
          <Table style={{ marginBottom: '10px' }} aria-colcount='5'>
            <Slot name='head'>
              <Row>
                <Column>{strings.hardwareVersion}</Column>
                <Column style={{ width: '360px' }}>
                  {strings.firmwareVersions}
                </Column>
                <Column>{strings.flashScriptVersionTable}</Column>
                <Column></Column>
                <Column></Column>
                <Column></Column>
              </Row>
            </Slot>

            <Slot name='body'>
              {hardwareVersions?.map((version, index) => (
                <Row key={index}>
                  <Cell
                    columnName='Hardware Version'
                    style={{ padding: '16px' }}
                  >
                    <Input
                      placeholder={strings.enterVersion}
                      name='hardwareVersion'
                      value={version.hardwareVersion}
                      onChange={e => onChangeHardware(e.target.value, index)}
                    />
                  </Cell>
                  <Cell
                    columnName={strings.hardwareVersion}
                    style={{ padding: '16px' }}
                  >
                    <Select
                      placeholder={strings.selectVersions}
                      value={version.firmwareVersions.map(
                        ({ value, version }) => version ?? value
                      )}
                      onChange={value => onChangeFirmware(value, index)}
                      name='firmwareVersions'
                      isMulti
                      isSearchable
                      options={firmwareVersions.map(({ value, label }) => ({
                        key: value,
                        value,
                        label
                      }))}
                    />
                  </Cell>

                  <Cell
                    columnName={strings.flashScriptVersionTable}
                    style={{ padding: '16px' }}
                  >
                    <Select
                      placeholder={strings.selectVersion}
                      value={version.flashScriptVersion}
                      onChange={value => onChangeFlashScript(value, index)}
                      name='flashScriptVersion'
                      isSearchable
                      options={validFlashScripts.map(({ version }) => ({
                        key: version,
                        value: version,
                        label: version
                      }))}
                    />
                  </Cell>

                  <Cell style={{ padding: '16px' }}>
                    <Flex alignMainAxis='center'>
                      <Input
                        type='checkbox'
                        checked={version.active}
                        onChange={() => onToggleActive(index)}
                        disabled
                      />
                    </Flex>
                  </Cell>

                  <Cell style={{ padding: '16px' }}>
                    <Flex alignMainAxis='center'>
                      <Button
                        onClick={() => onRemoveVersion(index)}
                        variant='error'
                        size='small'
                        iconBefore='close'
                      />
                    </Flex>
                  </Cell>
                </Row>
              ))}
            </Slot>
          </Table>
          <Button onClick={onAddHardwareVersion}>
            {strings.addHardwareVersion}
          </Button>
        </Flex>
      )}

      {firmwareVersions?.length === 0 && !loading && (
        <Flex
          alignCrossAxis='center'
          alignMainAxis='center'
          style={{ flex: 1 }}
        >
          <Flex
            direction={'column'}
            alignCrossAxis={'center'}
            style={{ flex: 1 }}
            axisGap={200}
          >
            <Text
              fontFamily={'mono'}
              variant={'primary'}
              tone={500}
              size={300}
              fontWeight={700}
            >
              {strings.noFirmwareVersions}
            </Text>
          </Flex>
        </Flex>
      )}
    </Fragment>
  )
}

export default VersionManager

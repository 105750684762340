import {
  defaultBarChart,
  defaultLineChart,
  defaultPieChart
} from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config/defaults'
import { DATASET_QUERY } from '@/components/DashboardPageV2/Desktop/Widgets/Shared//Dynamic/config'
import { QueryOption } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config/utils'

export const harvestChartQueryOptionsList = [
  new QueryOption({
    value: DATASET_QUERY,
    options: [defaultPieChart, defaultLineChart]
  })
]

export const defaultOperationQueryOptions = [
  new QueryOption({
    value: DATASET_QUERY,
    options: [defaultBarChart]
  })
]

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { startOTAUpdateQuery } from '@/api/ota'

export const sendStartOTAUpdateQuery = createAsyncThunk(
  'sendStartOTAUpdateQuery',
  async params => {
    const { executionId, error } = await startOTAUpdateQuery(params)
    return { executionId, error }
  }
)

const otaSlice = createSlice({
  name: 'otaReducer',
  initialState: {
    executionId: null,
    error: null,
    starting: false
  },
  reducers: {
    cleanExecutionId: (state, action) => {
      state.executionId = null
      state.error = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(sendStartOTAUpdateQuery.pending, (state, action) => {
        state.error = null
        state.starting = true
      })
      .addCase(sendStartOTAUpdateQuery.fulfilled, (state, action) => {
        state.executionId = action?.payload?.executionId
        state.error = action?.payload?.error ?? null
        state.starting = false
      })
  }
})

const { actions, reducer } = otaSlice

export const { cleanExecutionId } = actions

export default reducer

import classNames from 'classnames'

import { Box, Text } from '@/primitives'
import Select from '@/elements/Select'

export const reactSelectStyles = {
  backgroundColor: 'var(--ctx-theme-color-page-200)',
  border: '1px solid var(--ctx-theme-color-page-400)',
  color: 'var(--ctx-theme-contrast-page-400)',
  borderRadius: '2px',
  ':hover': {
    borderColor: 'var(--ctx-theme-color-info-700)'
  },
  ':focus-within': {
    boxShadow: '0 0 0 1px var(--ctx-theme-color-info-700)'
  }
}

export default function FilterSelect({
  options,
  onChange,
  name,
  value,
  placeholder,
  label,
  isZoneFilter = false,
  isSidebar = false,
  components = null,
  isActive = true,
  isBelowBreakpoint = false,
  isClearable = true,
  isSearchable = true
}) {
  // If the column is inactive and has no options, don't display anything.
  // The empty spaces will be filled in by the empty boxes at the end of the row.
  if (
    (!isActive && options.length === 0) ||
    (isSidebar && isActive && options.length === 0)
  ) {
    return null
  }

  // If the column is active but has no options, display an empty box inline.
  if (!isSidebar && !isBelowBreakpoint && isActive && options.length === 0) {
    return <Box className='DataboardFilters__ComparisonRow__EmptySpace'> </Box>
  }

  return (
    <Box
      className={classNames(
        'DataboardFilters__ComparisonRow__Select',
        isZoneFilter && 'DataboardFilters__ComparisonRow__Select--ZoneFilter'
      )}
    >
      {label && (
        <Text
          as={isSidebar ? 'span' : 'p'}
          variant='page'
          tone={600}
          className={'DataboardFilters__ComparisonRow__TabletLabel'}
        >
          {label}
        </Text>
      )}
      <Select
        className={
          name === 'sensorId' &&
          'DataboardFilters__ComparisonRow__Select--Sensor'
        }
        classNamePrefix='FilterSelect'
        options={options}
        onChange={onChange}
        name={name}
        value={value}
        placeholder={placeholder}
        isClearable={isClearable}
        isSearchable={isSearchable}
        components={components}
        controlStyles={reactSelectStyles}
        menuStyles={reactSelectStyles}
        placeholderStyles={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
        inputStyles={{ color: '#EEF0F6' }}
      />
    </Box>
  )
}

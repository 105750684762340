import { nanoid } from 'nanoid'
import { widgetRegistry } from '../../../Widgets'
import { CHARTS, QUERIES, RANGES } from '../../../Widgets/Shared/Dynamic/config'

import { cloneDeep } from 'lodash'

/**
 * This function checks if the layout is valid for the widget
 * It's to help the developer to know if the layout is valid or not
 * @param {object} widget
 * @param {string} layoutId
 * @returns
 */
const checkLayoutValidity = (widget, layoutId) => {
  const [query, chart, range, interval] = layoutId.split('#')
  const partsFound = []

  const isValid = widget.options.queryOptionsList.find(queryOption => {
    if (queryOption.value !== query) {
      return false
    }
    partsFound.push('query')

    const chartOption = queryOption.options.find(
      chartOption => chartOption.value === chart
    )

    if (!chartOption) {
      return false
    }
    partsFound.push('chart')

    if (queryOption.value === QUERIES.live.value) {
      // For live queries we only need to ensure if the chart is valid
      // Range and interval are not options for real-time data for obvious reasons
      return true
    }

    if (!chartOption.range?.includes(range)) {
      return false
    }
    partsFound.push('range')

    if (!RANGES[range].intervals?.includes(interval)) {
      return false
    }
    partsFound.push('interval')

    return true
  })

  if (!isValid) {
    throw Error(
      `Layout ${layoutId} is not valid for widget ${widget.widgetId}.
      Parts found: ${partsFound.join(', ')}`
    )
  }

  return true
}

const EDGE_CASES = new Set(['daily_energy_consumption', 'daily_light_integral'])

const getEdgeCaseLayoutId = widget => {
  const { query, chart, range, interval } = widget.config
  return `${query}#${chart}#${range}#${interval}`
}
export default function generateMeasurementLayout(
  layoutId,
  availableMeasurements,
  currentBreakpoint
) {
  let widgets = []
  let lastX = 0
  let lastY = 0
  let widgetIdx = 0

  for (let measurement of availableMeasurements) {
    const id = measurement.replace(/(_\w)/g, str => str[1].toUpperCase())
    let safeLayoutId = layoutId

    let widget = null
    try {
      widget = widgetRegistry.get(id)
      if (EDGE_CASES.has(measurement)) {
        safeLayoutId = getEdgeCaseLayoutId(widget)
      }
      const [query, chart, range, interval] = safeLayoutId.split('#')
      checkLayoutValidity(widget, safeLayoutId)
      widget.config = {
        query,
        chart,
        range: range ?? null,
        interval: interval ?? null
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message)
      continue
    }

    if (widget) {
      let position =
        widget.defaultPosition?.[currentBreakpoint] ??
        Object.values(widget.defaultPosition)[0]

      const chartMinSize =
        CHARTS[widget.config.chart].minSize[currentBreakpoint]

      position = [
        position[0],
        position[1],
        chartMinSize.minW,
        chartMinSize.minH,
        chartMinSize.minW,
        chartMinSize.minH
      ]

      const [w, h, minW, minH] = position.slice(2)

      lastX = widgetIdx === 0 ? 0 : lastX + w

      // this is also a column
      if (lastX >= 8) {
        lastX = 0
        lastY += 1
      }
      const widgetConfig = {
        id: nanoid(4),
        widgetId: widget.widgetId,
        config: cloneDeep(widget.config),
        options: cloneDeep(widget.options),
        position: {
          [currentBreakpoint]: [lastX, lastY, w, h, minW, minH]
        }
      }
      delete widgetConfig.options.queryOptionsList
      widgets.push(widgetConfig)
      widgetIdx += 1
    }
  }

  return widgets
}

import { Box } from '../..'

/* -----------------------------------------------------------------------------
 * Cell
 * ---------------------------------------------------------------------------*/
/**
 * Cell Primitive
 * @param {Object} props
 * @param { 'start' | 'end' | 'center' | 'left' | 'right' } [props.align] Align the Cell Content
 * @param {String} [props.columnName] Column Name for Mobile View
 * @param {import('react').Children} [props.children=null] Cell Content
 * @param {import('react').CSSProperties} [props.css] IonCSS Styles
 *
 * @example
 * ```jsx
 * <Table>
 *   <Slot name='body'>
 *    <Row>
 *      <Cell>Cell 1</Cell>
 *      <Cell>Cell 2</Cell>
 *    </Row>
 *  </Slot>
 * </Table>
 * ```
 */
const Cell = ({ children, align = null, columnName = null, ...rest }) => (
  <Box as='td' data-column={columnName} {...rest} align={align}>
    {children}
  </Box>
)

export default Cell

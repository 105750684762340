import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {
  calibrateDevice,
  requestCalibrationHistory
} from '@/api/calibration/device'

import { showBanner } from '@/slices/util'

import { capitalizeWord } from '@/Util/GeneralUtils'

export const sendCalibrateDevice = createAsyncThunk(
  'sendCalibrateDevice',
  async (params, { dispatch }) => {
    const { device, error } = await calibrateDevice(params)

    if (!error) {
      const { sensorId, measurementType } = device
      dispatch(
        showBanner({
          type: 'success',
          show: true,
          message: `${capitalizeWord(
            measurementType
          )} calibration request for ${sensorId} was sent. Check email for status.`
        })
      )
    } else {
      dispatch(
        showBanner({
          type: 'error',
          show: true,
          message: error
        })
      )
    }

    const calibratedDevice = {
      ...params,
      userId: params?.userName,
      createdAt: Math.floor(new Date().getTime() / 1000),
      status: 'PENDING'
    }

    return calibratedDevice
  }
)

export const fetchCalibrationHistory = createAsyncThunk(
  'fetchCalibrationHistory',
  async (params, { dispatch }) => {
    const { history, error } = await requestCalibrationHistory(params)

    if (error) {
      dispatch(
        showBanner({
          type: 'error',
          show: true,
          message: error
        })
      )
    }

    return history
  }
)

const calibrationDeviceSlice = createSlice({
  name: 'calibrationDeviceReducer',
  initialState: {
    history: [],
    calibrationInFlight: false,
    loading: false,
    error: null
  },
  reducers: {
    resetGreengrassCalibration: state => {
      state.calibrationInFlight = false
      state.error = null
    },
    resetCalibrationHistory: state => {
      state.history = []
      state.error = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(sendCalibrateDevice.pending, (state, action) => {
        state.loading = true
        state.calibrationInFlight = false
      })
      .addCase(sendCalibrateDevice.fulfilled, (state, action) => {
        state.loading = false
        state.calibrationInFlight = true
        state.history = [...state.history, action.payload]
      })
      .addCase(fetchCalibrationHistory.pending, (state, action) => {
        state.history = []
        state.error = null
      })
      .addCase(fetchCalibrationHistory.fulfilled, (state, action) => {
        state.history = action.payload
      })
  }
})

const { actions, reducer } = calibrationDeviceSlice

export const { resetGreengrassCalibration, resetCalibrationHistory } = actions

export default reducer

import { getMeasurements } from '@/reducers/selectors'
import { nameLookup } from '@/Util/UnitUtils'

import { Flex, Row, Cell, Box, Button, Select } from '@/primitives'

import {
  AVAILABLE_MEASUREMENTS,
  AVAILABLE_EXPRESSIONS,
  AVAILABLE_BOUNDS
} from '../../utils'
import { UPDATE } from '../state'

import Strings from '../../Strings'

function ProduceThresholdMessageConditionForm({
  config,
  condition,
  index,
  dispatchFormState,
  removeCondition
}) {
  const strings = Strings()
  const allMeasurements = getMeasurements()

  const handleRemoveCondition = () => {
    removeCondition(index)
  }

  const onChangeValue = e => {
    const { name, value } = e.currentTarget
    const newConfig = [...config]
    newConfig[index][name] = value
    dispatchFormState({
      type: UPDATE,
      name: 'config',
      value: newConfig
    })
  }

  const filterMeasurements = () => {
    return AVAILABLE_MEASUREMENTS.map(measurement => ({
      name: nameLookup(measurement, allMeasurements),
      key: measurement
    }))
  }

  const filterExpressions = () => {
    return AVAILABLE_EXPRESSIONS.map(expression => ({
      name: expression[1],
      key: expression[0]
    }))
  }

  const filterBounds = () => {
    return AVAILABLE_BOUNDS.map((bound, index) => ({
      name: bound,
      key: index
    }))
  }

  return (
    <Row className='Operations__Form__Table'>
      <Cell
        columnName={strings.fieldCondition}
        style={{ lineHeight: '2em', whiteSpace: 'nowrap' }}
      >
        <Flex axisGap={300} direction='column'>
          <Select
            className='Operations__Form__Select'
            name='measurement'
            value={condition.measurement}
            onChange={onChangeValue}
          >
            <option default value=''>
              {strings.pleaseSelect}
            </option>
            {filterMeasurements().map(({ key, name }) => (
              <option key={key} value={key}>
                {name}
              </option>
            ))}
          </Select>
        </Flex>
      </Cell>
      <Cell columnName={strings.fieldUnit} style={{ lineHeight: '2em' }}>
        <Flex axisGap={300} direction='column'>
          <Select
            className='Operations__Form__Select'
            name='expression'
            value={condition.expression}
            onChange={onChangeValue}
          >
            <option default value=''>
              {strings.pleaseSelect}
            </option>
            {filterExpressions().map(({ key, name }) => (
              <option key={key} value={key}>
                {name}
              </option>
            ))}
          </Select>
        </Flex>
      </Cell>
      <Cell columnName={strings.fieldUnit} style={{ lineHeight: '2em' }}>
        <Flex axisGap={300} direction='column'>
          <Select
            className='Operations__Form__Select'
            name='bound'
            value={condition.bound}
            onChange={onChangeValue}
          >
            <option default value=''>
              {strings.pleaseSelect}
            </option>
            {filterBounds().map(({ key, name }) => (
              <option key={key} value={key}>
                {name}
              </option>
            ))}
          </Select>
        </Flex>
      </Cell>
      <Cell style={{ flex: '0 1 0', flexShrink: 1 }}>
        <Box style={{ marginTop: '0.2em', textAlign: 'right' }}>
          <Button
            variant='error'
            size='small'
            iconBefore='cancel'
            onClick={handleRemoveCondition}
          />
        </Box>
      </Cell>
    </Row>
  )
}

export default ProduceThresholdMessageConditionForm

import { useEffect, useState } from 'react'
import { I18n } from 'aws-amplify'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { string, object } from 'yup'

import {
  sendCreateFactorySupplier,
  clearSupplier
} from '@/slices/management/supplier'
import { showBanner } from '@/slices/util'

import { Button, Flex, Input, Label, Text, Form, Slot } from '@/primitives'
import { Dialog } from '@/elements'

import {
  getFactorySupplier,
  getFactorySuppliersSaving
} from '@/reducers/selectors'

import Strings from './Strings'

const supplierSchema = object().shape({
  name: string().trim().required(I18n.get('Please enter an organization name'))
})

const ModalForm = () => {
  const strings = Strings()
  const history = useHistory()
  const dispatch = useDispatch()

  const supplier = getFactorySupplier()
  const saving = getFactorySuppliersSaving()

  const [name, setName] = useState('')

  useEffect(() => {
    if (supplier?.supplierId) {
      dispatch(clearSupplier())
      dispatch(
        showBanner({
          show: true,
          message: strings.supplierCreated,
          type: 'success'
        })
      )
      history.push('/admin/suppliers')
    }
  }, [supplier, dispatch])

  function onClickClose() {
    history.push('/admin/suppliers')
  }

  function onChangeInput(e) {
    setName(e.target.value)
  }

  async function onSubmitCreate(e) {
    e.preventDefault()

    try {
      const response = await supplierSchema.validate(
        { name },
        { abortEarly: true }
      )

      dispatch(sendCreateFactorySupplier(response))
    } catch (err) {
      dispatch(showBanner({ show: true, message: err.message, type: 'error' }))
    }
  }

  return (
    <Dialog type='modal' open={true} onOpenChange={onClickClose}>
      <Slot name='title'>
        <Text size='300' fontWeight={700}>
          {strings.addSupplier}
        </Text>
      </Slot>
      <Slot name='content'>
        <Form onSubmit={onSubmitCreate}>
          <Flex axisGap={200} direction='column' alignMainAxis='center'>
            <Label>
              <Text variant='page' tone={700}>
                {strings.tableRowName}
              </Text>
              <Input required value={name} onChange={onChangeInput} />
            </Label>
          </Flex>
          <Flex alignMainAxis='flex-end'>
            <Button
              size='small'
              type='submit'
              variant='primary'
              disabled={saving}
              loading={saving}
            >
              {strings.save}
            </Button>
          </Flex>
        </Form>
      </Slot>
    </Dialog>
  )
}

export default ModalForm

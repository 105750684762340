import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { I18n } from 'aws-amplify'

import { useBreadCrumbs } from '@/contexts/breadcrumb-context'
import useDeviceSize from '@/hooks/useDeviceSize'
import Select from '@/elements/Select'
import { FlexV2 } from '@/primitives'
import history from '../../../history'

import Crumb from './Crumb'

import {
  getZonesHierarchy,
  getCurrentUserOrganizations
} from '@/reducers/selectors'

import BreadcrumbUtils from '@/Util/BreadcrumbUtils'
import ZoneUtils from '@/Util/ZoneUtils'

import './index.scss'

export default function NavigationBreadcrumbs({
  organizationId,
  zone,
  onClickCrumb,
  uriSuffix = '',
  showTag = true,
  breadCrumbs
}) {
  const { breadCrumbs: localBreadCrumbs, setBreadCrumbs: setLocalBreadCrumbs } =
    useBreadCrumbs()
  const params = useParams()
  const isMobile = useDeviceSize('mobile')

  const basePath = ZoneUtils.getZoneBasePath(params)
  const zonesHierarchy = getZonesHierarchy()
  const organizations = getCurrentUserOrganizations()
  const [childrenOptions, setChildrenOptions] = useState([])

  const [organization, setOrganization] = useState(null)

  const devices =
    ZoneUtils.getZoneHierarchyValueByPath(
      zonesHierarchy,
      basePath,
      'devices'
    ) ?? []
  const device = devices?.find(device => device.id === params?.sensorId) ?? {}

  // 1. Stringified for static checking in useEffect
  const stringifiedZonesHierarchy = JSON.stringify(zonesHierarchy)

  useEffect(() => {
    if (organizationId && organizations.length > 0) {
      const orgIndex = organizations.findIndex(o => o.id === organizationId)
      if (orgIndex >= 0) {
        setOrganization(organizations[orgIndex])
      }
    }
  }, [organizationId, organizations])

  useEffect(() => {
    // 2. Parsed after static checking
    const nextZonesHierarchy = JSON.parse(stringifiedZonesHierarchy)
    if (
      (zone?.id || params?.sensorId || organization) &&
      Object.keys(nextZonesHierarchy).length > 0 &&
      basePath &&
      setLocalBreadCrumbs
    ) {
      if (!breadCrumbs && organization) {
        const nextBreadcrumbs = BreadcrumbUtils.generateDashboardBreadcrumbs(
          organization,
          zone,
          params?.sensorId,
          nextZonesHierarchy,
          basePath,
          isMobile,
          uriSuffix
        )

        setLocalBreadCrumbs(nextBreadcrumbs)
      }
    }
  }, [
    breadCrumbs,
    organization,
    zone,
    params?.sensorId,
    stringifiedZonesHierarchy,
    basePath,
    setLocalBreadCrumbs,
    isMobile,
    uriSuffix
  ])

  const breadCrumbsToUse = breadCrumbs ?? localBreadCrumbs

  useEffect(() => {
    if (breadCrumbsToUse?.length > 0) {
      const lastCrumb = breadCrumbsToUse?.[breadCrumbsToUse.length - 1]

      const cleanParentPath = lastCrumb.parentPath.replace('/zones/', '')
      const [site] = cleanParentPath.split('/')

      const lastItem = ZoneUtils.getZoneHierarchyValueByPath(
        zonesHierarchy[site],
        cleanParentPath
      )
      if (lastItem && lastItem.children) {
        let options = []

        for (const zoneId in lastItem.children) {
          options.push({
            value: lastItem.children[zoneId].parentPath,
            label: lastItem.children[zoneId].name
          })
        }
        setChildrenOptions(options)
      }
    }
  }, [breadCrumbsToUse])

  const getTo = crumb => {
    let to = crumb.parentPath
    if (!crumb.noSuffix) {
      to = to + uriSuffix
    }
    return to
  }

  const handleSelect = selected => {
    history.push(`/zones${selected.value}`)
    setChildrenOptions([])
  }

  const zoneDepthString = ZoneUtils.getZoneDepthStringFromDepth(
    params.zone.split('/').length
  )

  return (
    <FlexV2
      as='nav'
      aria-label='breadcrumbs'
      className='SlimNavBreadcrumb__Crumb__Container'
      alignCrossAxis='center'
      axisGap={300}
    >
      <FlexV2 className='SlimNavBreadcrumb__Crumb__Item' axisGap={300}>
        {breadCrumbsToUse?.length > 0 &&
          breadCrumbsToUse.map((crumb, index, { length }) => (
            <Crumb
              key={crumb.id}
              crumb={crumb}
              to={getTo(crumb)}
              index={index}
              showTag={showTag}
              device={device}
              onClickCrumb={onClickCrumb}
              lastItem={index + 1 === length}
              hasOptions={childrenOptions.length > 0}
            />
          ))}
      </FlexV2>
      {childrenOptions.length > 0 && (
        <Select
          className='SlimNavBreadcrumb__Crumb_Select'
          options={childrenOptions}
          onChange={handleSelect}
          value={null}
          placeholder={`${I18n.get('Select a')} ${zoneDepthString}`}
          controlStyles={{
            fontSize: '0.9rem',
            minHeight: 'min-content',
            height: 'min-content',
            padding: '2px 5px'
          }}
          dropdownIndicatorStyles={{
            padding: 0,
            paddingRight: '5px'
          }}
          placeholderStyles={{
            color: 'var(--ctx-theme-color-page-600)'
          }}
        />
      )}
    </FlexV2>
  )
}

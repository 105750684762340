import { Children, Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { I18n } from 'aws-amplify'
import { truncate } from 'lodash'

import { FlexV2, Button, Select, Item, Symbol } from '@/primitives'
import { ColorSchemeToggle, Dropdown } from '@/elements'

import withStatus from '@/components/HOC/withStatus'
import StatusMenu from '@/components/Shared/StatusMenu'

import { cleanSensorThresholdStatuses } from '@/slices/threshold/sensorThresholdStatus'
import { cleanCurrentUser } from '@/slices/management/user'
import { cleanZonesHierarchy } from '@/slices/management/hierarchy'
import { cleanOperations } from '@/actions/operations'
import { cleanZonesList } from '@/slices/management/zone'
import { cleanOfflineDevices } from '@/slices/diagnostics/deviceIsOffline'
import { setLang } from '@/slices/util'

import { getLang, getZonesHierarchy } from '@/reducers/selectors'

import useMediaQuery from '@/hooks/useMediaQuery'

import { useAuth } from '@/contexts/auth-context'

import Strings from '../../Strings'

import LANGUAGES from '@/Util/languages.json'
import SupportForm from '../Support/Form'

const WithStatusMenu = withStatus(StatusMenu)

const UserControls = () => {
  const dispatch = useDispatch()
  const auth = useAuth()

  const strings = Strings()

  const lang = getLang()

  const isAboveCustomBreakpoint = useMediaQuery('min-width: 414px')

  const [displayLanguage, setDisplayLanguage] = useState(lang)
  const [supportFormOpen, setSupportFormOpen] = useState(false)

  const idToken = auth.getCognitoIdToken()

  const zonesHierarchy = getZonesHierarchy()

  useEffect(() => {
    setDisplayLanguage(lang)
  }, [lang])

  /* Language */
  const handleLanguageChange = ({ target: { selectedOptions } }) => {
    const { value, dir } = selectedOptions[0]

    if (value && dir) {
      I18n.setLanguage(value)
      dispatch(setLang(value))

      document.documentElement.setAttribute('lang', value)
      document.documentElement.setAttribute('dir', dir)

      setDisplayLanguage(value)
    }
  }

  /* Handle Signout */
  const handleSignOut = async () => {
    dispatch(cleanCurrentUser())
    dispatch(cleanSensorThresholdStatuses())
    dispatch(cleanZonesHierarchy())
    dispatch(cleanOperations())
    dispatch(cleanZonesList())
    dispatch(cleanOfflineDevices())

    await auth.signOut()
  }

  /* Handle Feedback Form */
  const toggleSupportForm = () => {
    setSupportFormOpen(!supportFormOpen)
  }

  return (
    <FlexV2 className='UserMenu' axisGap={300} alignCrossAxis={'center'}>
      <ColorSchemeToggle />
      <Select value={displayLanguage} onChange={handleLanguageChange}>
        {Children.toArray(
          LANGUAGES.enabled.sort().map(enabledLanguage => {
            const languageDefinition = LANGUAGES.dictionary[enabledLanguage]

            if (!languageDefinition?.hasOwnProperty('name')) {
              return null
            }

            const { name, alpha2, dir } = languageDefinition
            const optionName = isAboveCustomBreakpoint
              ? name
              : truncate(name, { length: 7 })

            return (
              <option key={`languageOption__${name}`} value={alpha2} dir={dir}>
                {optionName}
              </option>
            )
          })
        )}
      </Select>

      {idToken ? (
        <Fragment>
          {Object.keys(zonesHierarchy)?.length > 0 && (
            <WithStatusMenu
              isRoot={true}
              triggerIcon='notifications'
              triggerText={null}
              menuAlign='end'
              cursorActivated={true}
            />
          )}

          <Dropdown
            triggerIcon='account circle'
            triggerText={null}
            menuAlign='end'
            cursorActivated={true}
          >
            <Item>
              <Link to={'/profile'}>{I18n.get('User profile')}</Link>
            </Item>
            <Item>
              <Link to={'/release-notes'}>{strings.releaseNotes}</Link>
            </Item>
            <Item>
              <Link to={'/'} onClick={handleSignOut}>
                {strings.signOut}
              </Link>
            </Item>
          </Dropdown>
        </Fragment>
      ) : (
        <Button as={Link} to='/zones' size='small' variant='primary' outline>
          {strings.signIn}
        </Button>
      )}

      <FlexV2 alignCrossAxis='center' className='feedback_icon'>
        <Button
          variant='text'
          style={{ padding: 0, width: 24, lineHeight: 1, borderWidth: 0 }}
          onClick={toggleSupportForm}
        >
          <Symbol name='help' variant={'info'} tone={700} />
        </Button>
      </FlexV2>

      {supportFormOpen && (
        <SupportForm toggle={toggleSupportForm} formOpen={supportFormOpen} />
      )}
    </FlexV2>
  )
}

export default UserControls

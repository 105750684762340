import { I18n } from 'aws-amplify'

const Strings = () => ({
  // Shared
  noResults: I18n.get('No results'),
  
  // Header
  loading: I18n.get('Loading'),
  viewAlerts: I18n.get('View alerts'),
  viewOverview: I18n.get('View overview'),
  alerts: I18n.get('Alerts'),

  // Search
  searchBySensorName: I18n.get('Search by sensor name'),

  // Filters
  envirosense: I18n.get('Envirosense'),
  watersense: I18n.get('Watersense'),
  soilsense: I18n.get('Soilsense'),
  cellular_envirosense: I18n.get('Cellular Envirosense'),
  cellular_watersense: I18n.get('Cellular Watersense'),
  cellular_soilsense: I18n.get('Cellular Soilsense'),
  dissolved_oxygen: I18n.get('Dissolved Oxygen'),
  energy_monitor: I18n.get('Energy Monitor'),
  airflow_meter: I18n.get('Airflow Meter'),
  modbus: I18n.get('Modbus'),

  // Device Status
  active: I18n.get('Active'),
  notification: I18n.get('Notification'),
  warning: I18n.get('Warning'),
  caution: I18n.get('Caution'),
  offline: I18n.get('Offline'),
  notInUse: I18n.get('Not in use'),
  isInUse: I18n.get('Is in use'),
  missingData: I18n.get('Missing data'),
  threshold: I18n.get('Threshold'),

  // toggleCollapse
  expandAll: I18n.get('Expand all'),
  collapseAll: I18n.get('Collapse all'),

  // toggle individual
  expand: I18n.get('Expand'),
  collapse: I18n.get('Collapse'),

  // exportCSV
  exportCSV: I18n.get('Export CSV'),

  // resetAll
  resetAll: I18n.get('Reset all'),

  // Message Card
  name: I18n.get('Name'),
  zoneId: I18n.get('Zone ID'),
  sensorId: I18n.get('Sensor ID'),
  sensorType: I18n.get('Sensor type'),
  hardwareVersion: I18n.get('Hardware version'),
  unknown: I18n.get('Unknown'),
  thresholdValue: I18n.get('Threshold value'),
  lastValue: I18n.get('Last value'),
  sendingData: I18n.get('Sending data'),
  thresholdTrigger: I18n.get('Threshold trigger'),
  lastSending: I18n.get('Last sending'),
  lastChecked: I18n.get('Last checked'),
  alert: I18n.get('Alert'),
  longitude: I18n.get('Longitude'),
  latitude: I18n.get('Latitude'),
  goToSensorDashboard: I18n.get('Go to sensor dashboard')
})

export default Strings
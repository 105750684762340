import React from 'react'
import ReactDOM from 'react-dom/client'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import ReactGA from 'react-ga4'
import { Amplify } from 'aws-amplify'
import { AWSIoTProvider } from '@aws-amplify/pubsub'

import App from './components/App'

import { ArticleSuggestionProvider } from './algolia/context'
import { initSw, updateSw } from '@/slices/serviceWorker'
import configureStore from './store'
import history from './history'
import * as serviceWorker from './serviceWorker'

import { ThemeProvider } from './contexts/theme-context'
import { BreadCrumbProvider } from './contexts/breadcrumb-context'
import { AuthProvider } from './contexts/auth-context'

import './i18n'

import 'react-phone-number-input/style.css'
import 'react-datepicker/dist/react-datepicker.css'

import './styles/react-datepicker.css'
import './styles/base/index.scss'
import './styles/theme/index.scss'

const host = window.location.hostname

const getNewHref = newHost => {
  return window.location.href.replace(window.location.host, newHost)
}

if (host === 'coretex.ag') {
  window.location.replace(getNewHref('data.secondsky.com'))
} else if (host === 'coretex.uk') {
  window.location.replace(getNewHref('dev.data.secondsky.com'))
}

ReactGA.initialize(window.ENV.GOOGLE_TRACKING_ID)

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: window.ENV.REGION,
    userPoolId: window.ENV.USER_POOL_ID,
    userPoolWebClientId: window.ENV.APP_CLIENT_ID,
    identityPoolId: window.ENV.IDENTITY_POOL_ID,
    cookieStorage: {
      domain: window.ENV.DOMAIN,
      path: '/',
      expires: 365,
      secure: false
    },
    authenticationFlowType: 'USER_PASSWORD_AUTH' //'USER_SRP_AUTH'
  },
  Storage: {
    AWSS3: {
      bucket: window.ENV.OPERATIONS_BUCKET_NAME,
      region: window.ENV.REGION
    }
  }
})

const iotProvider = new AWSIoTProvider({
  aws_pubsub_endpoint: `wss://${window.ENV.MQTT_HOST}/mqtt`,
  aws_pubsub_region: window.ENV.REGION
})

Amplify.addPluggable(iotProvider)

const startup = async () => {
  const store = await configureStore()

  const root = ReactDOM.createRoot(document.getElementById('root'))

  root.render(
    <Provider store={store}>
      <ArticleSuggestionProvider>
        <AuthProvider>
          <ThemeProvider>
            <BreadCrumbProvider>
              <Router history={history}>
                <App />
              </Router>
            </BreadCrumbProvider>
          </ThemeProvider>
        </AuthProvider>
      </ArticleSuggestionProvider>
    </Provider>
  )

  serviceWorker.register({
    onSuccess: registration => store.dispatch(initSw()),
    onUpdate: registration => store.dispatch(updateSw(registration))
  })
}

startup()

// vite will exclude this from the production build
if (import.meta.env.MODE === 'development') {
  if (import.meta.hot) {
    import.meta.hot.on('ignore-hmr', ({ type, name, filePath }) => {
      let message = 'The following file is excluded from HMR:\n'

      if (type === 'dir') {
        message = `The following ${name} file was updated:\n`
      }

      // eslint-disable-next-line no-console
      console.log(
        '%c! Manual Refresh Required !\n\n%c' + message + '%c' + filePath,
        'color: black; font-weight: bold; font-size: 20px;', // Style for the title
        'font-weight: normal;', // Style for the message
        'font-weight: bold;' // Style for the file path
      )
    })
  }
}

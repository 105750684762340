import { Flex, Input, Cell } from '@/primitives'

const BoundInputCell = ({
  measurementKey,
  columnName,
  name,
  value,
  handleInput
}) => {
  return (
    <Cell columnName={columnName} style={{ paddingRight: 0 }}>
      <Flex
        axisGap={300}
        direction='column'
        className='Operations__Input__Container'
      >
        <Input
          id={measurementKey + name}
          className='Operations__Input'
          type='number'
          name={name}
          value={value}
          onChange={handleInput}
          style={{
            minWidth: 'auto'
          }}
        />
      </Flex>
    </Cell>
  )
}

export default BoundInputCell

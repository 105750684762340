import React from 'react'
import { Link } from 'react-router-dom'
import { I18n } from 'aws-amplify'

import { Flex, Logo, Text } from '@/primitives'

import ProgressIndicator from './Utils/ProgressIndicator'

import useMediaQuery from '@/hooks/useMediaQuery'

import './index.scss'

const AuthContainer = ({ children, progressStep = null }) => {
  const isLargeLaptop = useMediaQuery('min-width: 1366px')
  return (
    <Flex
      direction='column'
      alignCrossAxis='center'
      alignMainAxis='center'
      axisGap={400}
      className='AuthPage'
      wrap='nowrap'
    >
      <Flex alignCrossAxis='center' axisGap={300}>
        <Logo size={isLargeLaptop ? 900 : 700} useDefault={true} />
      </Flex>
      <Flex
        direction='column'
        alignCrossAxis='center'
        alignMainAxis='center'
        style={{ width: '100%', maxWidth: '450px' }}
      >
        {progressStep && <ProgressIndicator currentStep={progressStep} />}
        {children}
        <Text
          as='p'
          size={100}
          style={{ marginTop: '2em', textAlign: 'center' }}
        >
          {I18n.get("By continuing you agree to SecondSky Data's ")}
          <Link to='privacy-policy' target='_blank'>
            {I18n.get('Privacy Policy')}
          </Link>
        </Text>
      </Flex>
    </Flex>
  )
}

export default AuthContainer

import { makeActionCreator } from '@/Util/ReduxUtils'

export const REQUEST_PACKAGE_UNIT_INVENTORY_TRANSFERS =
  'REQUEST_PACKAGE_UNIT_INVENTORY_TRANSFERS'
export const requestPackageUnitInventoryTransfers = makeActionCreator(
  REQUEST_PACKAGE_UNIT_INVENTORY_TRANSFERS,
  'params'
)

export const RECEIVE_PACKAGE_UNIT_INVENTORY_TRANSFERS =
  'RECEIVE_PACKAGE_UNIT_INVENTORY_TRANSFERS'
export const receivePackageUnitInventoryTransfers = makeActionCreator(
  RECEIVE_PACKAGE_UNIT_INVENTORY_TRANSFERS,
  'packageUnitInventoryTransfers',
  'count',
  'error'
)

export const CREATE_PACKAGE_UNIT_INVENTORY_TRANSFER =
  'CREATE_PACKAGE_UNIT_INVENTORY_TRANSFER'
export const createPackageUnitInventoryTransfer = makeActionCreator(
  CREATE_PACKAGE_UNIT_INVENTORY_TRANSFER,
  'params'
)

export const PACKAGE_UNIT_INVENTORY_TRANSFER_CREATED =
  'PACKAGE_UNIT_INVENTORY_TRANSFER_CREATED'
export const packageUnitInventoryTransferCreated = makeActionCreator(
  PACKAGE_UNIT_INVENTORY_TRANSFER_CREATED,
  'packageUnitInventoryTransfer',
  'error'
)

export const UPDATE_PACKAGE_UNIT_INVENTORY_TRANSFER =
  'UPDATE_PACKAGE_UNIT_INVENTORY_TRANSFER'
export const updatePackageUnitInventoryTransfer = makeActionCreator(
  UPDATE_PACKAGE_UNIT_INVENTORY_TRANSFER,
  'params'
)

export const PACKAGE_UNIT_INVENTORY_TRANSFER_UPDATED =
  'PACKAGE_UNIT_INVENTORY_TRANSFER_UPDATED'
export const packageUnitInventoryTransferUpdated = makeActionCreator(
  PACKAGE_UNIT_INVENTORY_TRANSFER_UPDATED,
  'packageUnitInventoryTransfer',
  'error'
)

export const REQUEST_PACKAGE_UNIT_INVENTORY_TRANSFER =
  'REQUEST_PACKAGE_UNIT_INVENTORY_TRANSFER'
export const requestPackageUnitInventoryTransfer = makeActionCreator(
  REQUEST_PACKAGE_UNIT_INVENTORY_TRANSFER,
  'params'
)

export const RECEIVE_PACKAGE_UNIT_INVENTORY_TRANSFER =
  'RECEIVE_PACKAGE_UNIT_INVENTORY_TRANSFER'
export const receivePackageUnitInventoryTransfer = makeActionCreator(
  RECEIVE_PACKAGE_UNIT_INVENTORY_TRANSFER,
  'packageUnitInventoryTransfer',
  'error'
)

export const CLEAR_PACKAGE_UNIT_INVENTORY_TRANSFER =
  'CLEAR_PACKAGE_UNIT_INVENTORY_TRANSFER'
export const clearPackageUnitInventoryTransfer = makeActionCreator(
  CLEAR_PACKAGE_UNIT_INVENTORY_TRANSFER
)

export const CLEAR_PACKAGE_UNIT_INVENTORY_TRANSFERS =
  'CLEAR_PACKAGE_UNIT_INVENTORY_TRANSFERS'
export const clearPackageUnitInventoryTransfers = makeActionCreator(
  CLEAR_PACKAGE_UNIT_INVENTORY_TRANSFERS
)

export const SET_PACKAGE_UNIT_INVENTORY_TRANSFER =
  'SET_PACKAGE_UNIT_INVENTORY_TRANSFER'
export const setPackageUnitInventoryTransfer = makeActionCreator(
  SET_PACKAGE_UNIT_INVENTORY_TRANSFER,
  'packageUnitInventoryTransfer'
)

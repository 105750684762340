import { Cell, Row } from '../../../../../primitives'

import { formatDate } from '../../utils'
import Strings from '../Strings'

function TableRow({ item }) {
  const strings = Strings()

  const { oldTotalWeight, newTotalWeight, reason, createdAt } = item
  const quantityChange = newTotalWeight - oldTotalWeight

  return (
    <Row>
      <Cell columnName={strings.date}>{formatDate(createdAt)}</Cell>
      <Cell columnName={strings.oldTotalWeight}>{oldTotalWeight}</Cell>
      <Cell columnName={strings.newTotalWeight}>
        {newTotalWeight}
        <span>
          {quantityChange > 0 ? '+' : '-'}
          {Math.abs(quantityChange)}
        </span>
      </Cell>
      <Cell columnName={strings.reason}>{reason}</Cell>
    </Row>
  )
}

export default TableRow

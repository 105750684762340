import { useDispatch } from 'react-redux'
import { Box } from '@/primitives'

import { togglePanelPrimaryOpen } from '@/slices/navigation'
import {
  getNavigationPanelPrimaryLock,
  getNavigationPanelPrimaryOpen
} from '@/reducers/selectors'

import './index.scss'

/**
 * Content Wrapper
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The content to be rendered.
 * @returns {ReactNode} The rendered component.
 */
const Content = ({ children }) => {
  const dispatch = useDispatch()

  const navigationPanelPrimaryLock = getNavigationPanelPrimaryLock()
  const navigationPanelPrimaryOpen = getNavigationPanelPrimaryOpen()

  /**
   * Event handler for handling click events.
   */
  const handleClick = () => {
    if (navigationPanelPrimaryOpen && !navigationPanelPrimaryLock) {
      dispatch(togglePanelPrimaryOpen())
    }
  }

  return (
    <Box className={'Content'} onClick={handleClick}>
      {children}
    </Box>
  )
}

export default Content

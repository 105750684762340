import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const fetchLocation = createAsyncThunk('fetchLocation', async params => {
  try {
    const { coords } = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, params)
    })
    return coords
  } catch (err) {
    return null
  }
})

const locationSlice = createSlice({
  name: 'locationReducer',
  initialState: {
    location: null,
    fetchingLocation: false
  },
  reducers: {
    cleanLocation: (state, action) => {
      state.location = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchLocation.pending, (state, action) => {
        state.location = null
        state.fetchingLocation = true
      })
      .addCase(fetchLocation.fulfilled, (state, action) => {
        state.location = action?.payload
        state.fetchingLocation = false
      })
  }
})

const { actions, reducer } = locationSlice

export const { cleanLocation } = actions

export default reducer

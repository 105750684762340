import { useState, useReducer, useEffect, useCallback } from 'react'
import isEqual from 'lodash/isEqual'
import { useDispatch } from 'react-redux'

import VersionManager from './VersionManager'

import { Button, Flex, Form, Input, Loader, Slot, Text } from '@/primitives'

import { Dialog, Select } from '@/elements'

import {
  sendCreateDeviceType,
  sendUpdateDeviceType,
  fetchDeviceFirmwareVersions,
  cleanDeviceType
} from '@/slices/deviceManagement/deviceType'

import { showBanner } from '@/slices/util'

import {
  getMeasurements,
  getDeviceTypes,
  getShowBanner,
  getDeviceTypeUpdated,
  getDeviceTypeTypes,
  getDeviceFirmwareVersions,
  getFlashScripts,
  getDeviceTypeIsSaving
} from '@/reducers/selectors'

import AdminUtils from '@/Util/AdminUtils'

import { getFirmwareLabel } from './utils'
import { addDateAndVersionToLabels, generateTypesOptions } from '../utils'

import Strings from '../../Strings'

import './index.scss'

const { newDeviceTypeSchema } = AdminUtils

const multiSelectOptions = {
  'select-option': 'multiselect-add',
  'remove-value': 'multiselect-remove'
}

const initialState = {
  id: '',
  type: '',
  name: '',
  hasFirmware: true,
  supportsGreengrass: false,
  measurements: []
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'input':
      let id = state.id
      if (action.name === 'name' && action.isAdd) {
        id = action.value.trim().toLowerCase().replaceAll(' ', '_')
        return { ...state, [action.name]: action.value, id }
      }

      return { ...state, [action.name]: action.value }
    case 'select':
      return { ...state, [action.name]: action.value }
    case 'multiselect-add':
      let addMultiArray = [...state[action.name]]
      addMultiArray.push(action.value)
      return { ...state, [action.name]: addMultiArray }
    case 'multiselect-remove':
      let removeMultiArray = [...state[action.name]]
      const index = removeMultiArray.findIndex(item => item === action.value)
      removeMultiArray.splice(index, 1)
      return { ...state, [action.name]: removeMultiArray }
    case 'hardware':
      return { ...state, [action.name]: action.value }
    case 'set-all':
      const deviceType = { ...action.value }
      return { ...state, ...deviceType }
    case 'reset':
      return { ...initialState }
    default:
      return { ...state }
  }
}

const UpsertDeviceType = ({
  showUpsertModal,
  onToggleModal,
  selectedDeviceType
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [isLoading, setIsLoading] = useState(true)
  const [measurementOptions, setMeasurementOptions] = useState([])
  const [hardwareVersions, setHardwareVersions] = useState([])
  const [firmwareVersions, setFirmwareVersions] = useState(null)
  const [typesOptions, setTypesOptions] = useState([])
  const [isAdd, setIsAdd] = useState(false)
  const [validFlashScripts, setValidFlashScripts] = useState([])

  const reduxDispatch = useDispatch()
  const measurements = getMeasurements()
  const deviceTypes = getDeviceTypes()
  const deviceFirmwareVersions = getDeviceFirmwareVersions()
  const banner = getShowBanner()
  const updatedDeviceType = getDeviceTypeUpdated()
  const flashScripts = getFlashScripts()
  const isSaving = getDeviceTypeIsSaving()

  const strings = Strings()
  const deviceTypeTypes = getDeviceTypeTypes()

  const cleanBanner = useCallback(() => {
    if (banner.show)
      reduxDispatch(showBanner({ show: false, message: '', type: null }))
  }, [reduxDispatch, banner.show])

  useEffect(() => {
    const validScripts = flashScripts?.filter(({ deviceType }) => {
      return deviceType === state?.id
    })
    setValidFlashScripts(validScripts)
  }, [state.id, flashScripts])

  useEffect(() => {
    if (deviceTypeTypes) {
      const options = generateTypesOptions(deviceTypeTypes)
      setTypesOptions(options)
    }
  }, [deviceTypeTypes])

  useEffect(() => {
    if (selectedDeviceType?.hasOwnProperty('id')) {
      setIsAdd(false)
      dispatch({ type: 'set-all', value: selectedDeviceType })
    } else {
      setIsAdd(true)
    }
  }, [selectedDeviceType])

  useEffect(() => {
    return () => {
      cleanBanner()
    }
  }, [cleanBanner, banner.show])

  useEffect(() => {
    if (measurements?.length > 0) {
      const hwMeasurements = measurements
        .filter(({ type }) => type === 'hardware')
        .sort((a, b) => (a.shortName < b.shortName ? -1 : 1))
      if (hwMeasurements?.length > 0) {
        const measurementsOptionsList = hwMeasurements?.map(
          ({ shortName, id }) => ({
            value: id,
            label: shortName
          })
        )
        setMeasurementOptions(measurementsOptionsList)
      }
    }
  }, [measurements])

  useEffect(() => {
    if (updatedDeviceType?.hasOwnProperty('id')) {
      reduxDispatch(cleanDeviceType())
      onToggleModal()
      const message = isAdd
        ? strings.deviceTypeCreatedSuccessfully
        : strings.deviceTypeUpdatedSuccessfully
      reduxDispatch(showBanner({ show: true, message, type: 'success' }))
    }
  }, [reduxDispatch, updatedDeviceType, onToggleModal])

  useEffect(() => {
    updateHardwareVersions(selectedDeviceType?.hardwareVersions ?? [])
    if (selectedDeviceType?.id) {
      reduxDispatch(
        fetchDeviceFirmwareVersions({ deviceId: selectedDeviceType.id })
      )
    }
  }, [reduxDispatch, selectedDeviceType])

  useEffect(() => {
    const uniqueVersions = Array.from(
      new Set(deviceFirmwareVersions.map(({ version }) => version))
    )

    setFirmwareVersions(
      uniqueVersions.map(version => ({
        value: version,
        label: getFirmwareLabel(
          version,
          deviceFirmwareVersions.find(({ version: v }) => v === version).date
        )
      }))
    )
  }, [deviceFirmwareVersions])

  useEffect(() => {
    if (firmwareVersions) {
      setTimeout(() => {
        setIsLoading(false)
      }, 500)
    }
  }, [firmwareVersions])

  const onSubmitForm = async e => {
    e.preventDefault()
    const shouldUpdate = isAdd || !isEqual(selectedDeviceType, state)

    if (shouldUpdate) {
      const params = {
        ...state,
        name: state.name.trim(),
        hardwareVersions: state.hasFirmware
          ? addDateAndVersionToLabels(hardwareVersions)
          : []
      }

      const deviceTypeIds = deviceTypes
        .filter(({ id }) => id !== selectedDeviceType?.id)
        .map(({ id }) => id)

      try {
        await newDeviceTypeSchema(deviceTypeIds).validate(params, {
          abortEarly: true
        })

        if (isAdd) {
          reduxDispatch(sendCreateDeviceType(params))
        } else {
          reduxDispatch(sendUpdateDeviceType(params))
        }
      } catch (err) {
        const errorMessage = err?.message ?? err
        reduxDispatch(
          showBanner({
            show: true,
            message: errorMessage,
            type: 'error'
          })
        )
      }
    } else {
      onToggleModal()
      const bannerParams = {
        show: true,
        message: strings.deviceTypeUpdatedSuccessfully,
        type: 'success'
      }
      reduxDispatch(showBanner(bannerParams))
    }
  }

  const onChangeInput = ({ target }) => {
    const { name, type } = target
    const value = type === 'checkbox' ? target.checked : target.value
    dispatch({ type: 'input', name, value, isAdd })
    cleanBanner()
  }

  const onChangeSelect = ({ name, value }) => {
    dispatch({ type: 'select', name, value })
    cleanBanner()
  }

  const onChangeMultiSelect = (e, { action, option, removedValue }) => {
    let value = option?.value || removedValue?.value
    dispatch({ type: multiSelectOptions[action], name: 'measurements', value })
    cleanBanner()
  }

  const updateHardwareVersions = versions => {
    setHardwareVersions(versions)
    dispatch({
      type: 'hardware',
      name: 'hardwareVersions',
      value: versions
    })
  }

  return (
    <Dialog
      open={showUpsertModal}
      onOpenChange={onToggleModal}
      type='offcanvas'
      className='device-type'
      style={{ zIndex: 3 }}
    >
      <Slot name='title'>
        <Flex direction='column' style={{ marginBottom: '1em' }}>
          <Text as='h5' style={{ marginBottom: '0.5em' }}>
            {isAdd ? strings.addDeviceType : strings.editDeviceType}
          </Text>
          <Text as='p' size={100}>
            {isAdd ? strings.addDeviceTypeSub : strings.editDeviceTypeSub}
          </Text>
        </Flex>
      </Slot>
      <Slot name='content'>
        {/* <Loader isLoading={isLoadingMeasurement}> */}
        <Form onSubmit={onSubmitForm}>
          <Flex direction='column' axisGap={500}>
            <Flex direction='column' axisGap={300}>
              <Text variant='page' tone={900} size={100}>
                {strings.deviceFormName}
              </Text>
              <Input name='name' value={state?.name} onChange={onChangeInput} />
            </Flex>

            <Flex direction='column' axisGap={300}>
              <Text variant='page' tone={900} size={100}>
                {strings.deviceFormId}
              </Text>
              <Input
                disabled
                name='id'
                value={state?.id}
                onChange={onChangeInput}
              />
            </Flex>

            <Flex direction='column' axisGap={300}>
              <Text variant='page' tone={900} size={100}>
                {strings.deviceFormType}
              </Text>
              <Select
                name='type'
                value={state?.type}
                onChange={onChangeSelect}
                options={typesOptions}
              />
            </Flex>

            {measurementOptions?.length > 0 && (
              <Flex direction='column' axisGap={300}>
                <Text variant='page' tone={900} size={100}>
                  {strings.deviceFormMeasurements}
                </Text>
                <Select
                  isMulti={true}
                  name='measurements'
                  value={state?.measurements}
                  onChange={onChangeMultiSelect}
                  options={measurementOptions}
                  isSearchable={true}
                />
              </Flex>
            )}

            <Flex direction='column' axisGap={500}>
              <Flex>
                <Input
                  type='checkbox'
                  name='supportsGreengrass'
                  checked={state?.supportsGreengrass}
                  onChange={onChangeInput}
                />
                <Text variant='page' tone={700}>
                  {strings.deviceFormSupportsGreengrass}
                </Text>
              </Flex>

              <Flex>
                <Input
                  type='checkbox'
                  name='hasFirmware'
                  checked={state?.hasFirmware}
                  onChange={onChangeInput}
                />
                <Text variant='page' tone={700}>
                  {strings.deviceFormHasFirmware}
                </Text>
              </Flex>
            </Flex>

            {state?.hasFirmware && (
              <Loader isLoading={isLoading} text={'Loading firmware versions'}>
                <VersionManager
                  loading={isLoading}
                  firmwareVersions={firmwareVersions}
                  hardwareVersions={hardwareVersions}
                  validFlashScripts={validFlashScripts}
                  setHardwareVersions={setHardwareVersions}
                  updateHardwareVersions={updateHardwareVersions}
                />
              </Loader>
            )}
          </Flex>

          <Flex alignMainAxis='space-between' className='device-type-buttons'>
            <Button
              variant='neutral'
              onClick={onToggleModal}
              disabled={isSaving}
            >
              {strings.cancel}
            </Button>
            <Button
              variant='primary'
              type='submit'
              disabled={isSaving}
              loading={isSaving}
            >
              {strings.save}
            </Button>
          </Flex>
        </Form>
      </Slot>
    </Dialog>
  )
}

export default UpsertDeviceType

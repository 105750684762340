import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Button, Flex, Slot, Text } from '@/primitives'
import { Dialog } from '@/elements'

import { sendDeleteManager } from '@/slices/manager'
import { userIdToName } from './utils'
import {
  getManager,
  getManagers,
  getZoneUsers,
  getManagerSaving
} from '@/reducers/selectors'

import Strings from '../../Strings'

const DeleteManagerModal = ({
  showModal,
  onManagerDeleted,
  onCancelDeleteManager
}) => {
  const strings = Strings()
  const manager = getManager()
  const managers = getManagers()
  const users = getZoneUsers()
  const isSaving = getManagerSaving()

  const dispatch = useDispatch()

  const { removeManagerHeading, cancel, deleteManagerButton } = strings

  useEffect(() => {
    const managerIds = managers?.map(({ userId }) => userId)
    if (manager?.userId && !managerIds.includes(manager?.userId)) {
      onManagerDeleted()
    }
  }, [managers, managers?.length, manager?.userId, onManagerDeleted])

  const onClickAccept = e => {
    e.preventDefault()
    dispatch(sendDeleteManager(manager))
  }

  const onClickClose = e => {
    e.preventDefault()
    onCancelDeleteManager()
  }

  return (
    <Dialog
      open={showModal}
      onOpenChange={isSaving ? null : onCancelDeleteManager}
    >
      <Slot name='title'>
        <Text size={300} fontWeight={700}>
          {removeManagerHeading}
        </Text>
      </Slot>
      <Slot name='content'>
        <Text as='p'>{userIdToName(manager?.userId, users)}</Text>
      </Slot>
      <Slot name='actions'>
        <Flex axisGap={400} alignMainAxis={'space-between'}>
          <Button variant='page' onClick={onClickClose} disabled={isSaving}>
            {cancel}
          </Button>
          <Button
            variant='error'
            onClick={onClickAccept}
            loading={isSaving}
            style={{ minWidth: '8rem' }}
          >
            {deleteManagerButton}
          </Button>
        </Flex>
      </Slot>
    </Dialog>
  )
}

export default DeleteManagerModal

import { I18n } from 'aws-amplify'

import { Box } from '@/primitives'
import FilterHeader from '../HeaderTitle'
import { getCombinedActiveColumns } from '../Utils/ZoneFilterUtils'
import useOrgLabel from '@/hooks/useOrgLabel'

const getLabelFromId = (id, labels) => {
  if (id === 'orgId') {
    return I18n.get('Organization')
  }
  if (id === 'sensorId') {
    return I18n.get('Sensor')
  }
  let idString = id.replace('Id', '')
  return labels[idString.toLowerCase()].text
}

export default function DynamicHeader({ activeColumns }) {
  const orgLabels = useOrgLabel(['site', 'facility', 'room', 'zone', 'subzone'])
  if (Object.keys(activeColumns).length === 0) {
    return null
  }

  return (
    <Box className='DataboardFilters__Grid__HeaderGrid--ZoneFilter'>
      <FilterHeader isEmptySpace={true} />
      <FilterHeader id='orgId' title={I18n.get('Organization')} />
      {getCombinedActiveColumns(activeColumns).map(id => (
        <FilterHeader key={id} title={getLabelFromId(id, orgLabels)} />
      ))}
      <FilterHeader isEmptySpace={true} />
    </Box>
  )
}

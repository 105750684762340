import { makeOperationsApiCall } from './utils'

export const getProducts = async params => {
  try {
    const query = /* GraphQL */ `
      query getProducts(
        $filter: GetProductsFilter
        $order: OrderEnum
        $offset: Int
        $limit: Int
      ) {
        getProducts(
          filter: $filter
          order: $order
          offset: $offset
          limit: $limit
        ) {
          products {
            id
            organizationId
            name
            code
            status
            weight
            produce {
              id
              name
            }
            variety {
              id
              name
            }
            grade {
              id
              name
            }
            packageUnits
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getProducts',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getProduct = async params => {
  try {
    const query = /* GraphQL */ `
      query getProduct($productId: String!) {
        getProduct(productId: $productId) {
          id
          organizationId
          name
          code
          status
          weight
          produceId
          varietyId
          gradeId
          packageUnits
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getProduct',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const createProduct = async params => {
  try {
    const query = /* GraphQL */ `
      mutation createProduct(
        $organizationId: String!
        $name: String!
        $code: String!
        $status: ProductStatus!
        $weight: Int!
        $produceId: String!
        $varietyId: String!
        $gradeId: String!
        $packageUnits: [String]!
      ) {
        createProduct(
          organizationId: $organizationId
          name: $name
          code: $code
          status: $status
          weight: $weight
          produceId: $produceId
          varietyId: $varietyId
          gradeId: $gradeId
          packageUnits: $packageUnits
        ) {
          id
          organizationId
          name
          code
          status
          weight
          produce {
            id
            name
          }
          variety {
            id
            name
          }
          grade {
            id
            name
          }
          packageUnits
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'createProduct',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updateProduct = async params => {
  try {
    const query = /* GraphQL */ `
      mutation updateProduct(
        $productId: String!
        $organizationId: String!
        $name: String!
        $code: String!
        $status: ProductStatus!
        $weight: Int!
        $produceId: String!
        $varietyId: String!
        $gradeId: String!
        $packageUnits: [String]!
      ) {
        updateProduct(
          productId: $productId
          organizationId: $organizationId
          name: $name
          code: $code
          status: $status
          weight: $weight
          produceId: $produceId
          varietyId: $varietyId
          gradeId: $gradeId
          packageUnits: $packageUnits
        ) {
          id
          organizationId
          name
          code
          status
          weight
          produce {
            id
            name
          }
          variety {
            id
            name
          }
          grade {
            id
            name
          }
          packageUnits
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'updateProduct',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

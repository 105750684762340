import { Box } from '../'
import './index.scss'

const deriveProps = ({ defaultValue, value, onChange }) => {
  if (onChange) return { value: value ?? '', onChange }
  return { defaultValue: defaultValue ?? value ?? '' }
}

const Input = ({
  type = 'text',
  value = null,
  defaultValue = null,
  onChange = null,
  children = null,
  className = '',
  ...rest
}) => {
  return (
    <Box
      as='input'
      type={type}
      {...deriveProps({ defaultValue, value, onChange })}
      className={['Input', className]}
      {...rest}
    />
  )
}

export default Input

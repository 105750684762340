import { I18n } from 'aws-amplify'
import { DateTime } from 'luxon'

import AdminUtils from '@/Util/AdminUtils'
import {
  RESOURCE_TYPE_DEVICE,
  RESOURCE_TYPE_DEVICE_MANAGEMENT,
  hasEditPermissions
} from '@/Util/PermissionUtils'

const { newMeasurementThresholdsSchema } = AdminUtils

export const validateThresholds = async params => {
  const { thresholds = [] } = params
  try {
    return thresholds.length === 2
      ? await newMeasurementThresholdsSchema().validate(
          {
            lowerThreshold: thresholds[0],
            upperThreshold: thresholds[1]
          },
          { abortEarly: false }
        )
      : null
  } catch (err) {
    throw err
  }
}

export const hasDeviEditPermissions = coretexUser => {
  return hasEditPermissions(
    coretexUser,
    RESOURCE_TYPE_DEVICE,
    RESOURCE_TYPE_DEVICE_MANAGEMENT
  )
}

const typeTranslations = {
  sensor: I18n.get('Sensor'),
  camera: I18n.get('Camera'),
  motor: I18n.get('Motor'),
  pump: I18n.get('Pump'),
  valve: I18n.get('Valve'),
  switch: I18n.get('Switch'),
  core: I18n.get('Core')
}

export const deviceTypesTranslations = {
  pyranometer: I18n.get('Pyranometer'),
  energy_monitor: I18n.get('Energy Monitor'),
  weather_station: I18n.get('Weather Station'),
  envirosense: I18n.get('Envirosense'),
  soilsense: I18n.get('Soilsense'),
  dissolved_oxygen: I18n.get('Dissolved Oxygen'),
  envirosense_modbus: I18n.get('Envirosense Modbus'),
  water_flow_meter: I18n.get('Water Flow Meter'),
  watersense: I18n.get('Watersense'),
  airflow_meter: I18n.get('Airflow Meter'),
  camera: I18n.get('Camera')
}

export const generateTypesOptions = types => {
  return types.map(type => ({
    value: type,
    label: typeTranslations[type] ?? type,
    name: 'type'
  }))
}

export const addDateAndVersionToLabels = hardwareVersions => {
  const newHardwareVersions = hardwareVersions.map(hardwareVersion => {
    const newFirmwareVersions = hardwareVersion.firmwareVersions.map(
      firmwareVersion => {
        if (firmwareVersion.label) {
          const [version, dateStr] = firmwareVersion.label.split(' - ')
          const date = Math.floor(
            DateTime.fromFormat(dateStr, 'ccc MMM dd HH:mm:ss yyyy').toSeconds()
          )

          return {
            version,
            date
          }
        } else {
          return {
            version: firmwareVersion.version,
            date: firmwareVersion.date
          }
        }
      }
    )

    return {
      ...hardwareVersion,
      firmwareVersions: newFirmwareVersions
    }
  })
  return [...newHardwareVersions]
}

import { updateList } from '../util'

import {
  RECEIVE_ALL_PACKAGE_UNITS,
  REQUEST_ALL_PACKAGE_UNITS,
  RECEIVE_PACKAGE_UNITS,
  REQUEST_PACKAGE_UNITS,
  CLEAR_PACKAGE_UNITS,
  CLEAR_PACKAGE_UNIT,
  REQUEST_PACKAGE_UNIT,
  RECEIVE_PACKAGE_UNIT,
  CREATE_PACKAGE_UNIT,
  PACKAGE_UNIT_CREATED,
  UPDATE_PACKAGE_UNIT,
  PACKAGE_UNIT_UPDATED,
  SET_PACKAGE_UNIT
} from '@/actions/operations/packageUnit'

export const packageUnitInitialState = {
  allPackageUnits: [],
  packageUnits: [],
  packageUnit: {},
  packageUnitsCount: 0,
  loading: false,
  error: null
}

const handlers = {
  [SET_PACKAGE_UNIT]: (state, action) => ({
    ...state,
    packageUnit: action.packageUnit
  }),
  [CLEAR_PACKAGE_UNIT]: (state, action) => ({
    ...state,
    packageUnit: {},
    error: null
  }),
  [CLEAR_PACKAGE_UNITS]: (state, action) => ({
    ...state,
    packageUnits: [],
    error: null
  }),
  [REQUEST_ALL_PACKAGE_UNITS]: state => ({
    ...state,
    error: null,
    loading: true
  }),
  [RECEIVE_ALL_PACKAGE_UNITS]: (state, action) => ({
    ...state,
    allPackageUnits: !action.error ? action.packageUnits : [],
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_PACKAGE_UNIT]: (state, action) => ({
    ...state,
    packageUnit: {},
    error: null,
    loading: true
  }),
  [RECEIVE_PACKAGE_UNIT]: (state, action) => ({
    ...state,
    packageUnit: !action.error ? action.packageUnit : state.packageUnit,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_PACKAGE_UNITS]: (state, action) => ({
    ...state,
    packageUnits: [],
    packageUnitsCount: 0,
    error: null,
    loading: true
  }),
  [RECEIVE_PACKAGE_UNITS]: (state, action) => ({
    ...state,
    packageUnits: !action.error ? action.packageUnits : [],
    packageUnitsCount: !action.error ? action.count : 0,
    error: action.error ?? null,
    loading: false
  }),
  [CREATE_PACKAGE_UNIT]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [PACKAGE_UNIT_CREATED]: (state, action) => ({
    ...state,
    packageUnit: !action.error ? {} : state.packageUnit,
    packageUnits: updateList(
      action.error,
      state.packageUnits,
      action.packageUnit,
      true
    ),
    allPackageUnits: updateList(
      action.error,
      state.allPackageUnits,
      action.packageUnit,
      true
    ),
    packageUnitsCount: !action.error
      ? state.packageUnitsCount + 1
      : state.packageUnitsCount,
    error: action.error ?? null,
    loading: false
  }),
  [UPDATE_PACKAGE_UNIT]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [PACKAGE_UNIT_UPDATED]: (state, action) => ({
    ...state,
    packageUnit: !action.error ? {} : state.packageUnit,
    packageUnits: updateList(
      action.error,
      state.packageUnits,
      action.packageUnit,
      false
    ),
    allPackageUnits: updateList(
      action.error,
      state.allPackageUnits,
      action.packageUnit,
      false
    ),
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

import { Box } from '../'
import './index.scss'

const Form = ({ children = null, className = '', ...rest }) => {
  return (
    <Box as='form' className={['Form', className]} {...rest}>
      {children}
    </Box>
  )
}

export default Form

import { Fragment } from 'react'

import OrganizationsFilter from '../../../Shared/Filters/OrganizationsFilter'
import StorageFacilitiesFilter from '../../../Shared/Filters/StorageFacilitiesFilter'
import ProducesFilter from '../../../Shared/Filters/ProducesFilter'
import VarietiesFilter from '../../../Shared/Filters/VarietiesFilter'

function InventoryFilter({ state, dispatchState }) {
  return (
    <Fragment>
      <OrganizationsFilter state={state} dispatchState={dispatchState} />
      <StorageFacilitiesFilter state={state} dispatchState={dispatchState} />
      <ProducesFilter state={state} dispatchState={dispatchState} />
      <VarietiesFilter state={state} dispatchState={dispatchState} />
    </Fragment>
  )
}

export default InventoryFilter

import { I18n } from 'aws-amplify'
import { useEffect, useState } from 'react'

import {
  getZonesHierarchy,
  getCurrentUserOrganizations,
  getNavigationLocationSelector
} from '../../../../reducers/selectors'

import ZoneUtils from '../../../../Util/ZoneUtils'
import { Select } from '@/elements'

const filterOrganizations = (zonesHierarchy, organizations) => {
  if (organizations?.length > 0) {
    return organizations.filter(o => {
      const sites = Object.values(zonesHierarchy)
      const siteIndex = sites.findIndex(s => s.organizationId === o.id)
      return siteIndex >= 0
    })
  }
  return []
}

const OrganizationsFilter = ({
  stateOrgs = [],
  dispatchState,
  disabled = false
}) => {
  const orgId = stateOrgs[0] ?? null
  const zonesHierarchy = getZonesHierarchy()
  
  const locationSelector = getNavigationLocationSelector()

  const [organizations, setOrganizations] = useState([])
  const [orgFilter, setOrgFilter] = useState(orgId)
  const allOrgs = getCurrentUserOrganizations()
  const orgString = I18n.get('organization')

  useEffect(() => {
    if (locationSelector.get('organization')) {
      const newOrgId = locationSelector.get('organization').id
      setOrgFilter(newOrgId)
      dispatchState({
        organizations: [newOrgId]
      })
    }
  }, [locationSelector.get('organization')])

  useEffect(() => {
    setOrgFilter(orgId)
  }, [orgId])

  useEffect(() => {
    if (Object.keys(zonesHierarchy)?.length > 0 && allOrgs?.length > 0) {
      const orgsWithSites = filterOrganizations(zonesHierarchy, allOrgs)
      setOrganizations(orgsWithSites)
    }
  }, [JSON.stringify(zonesHierarchy), allOrgs])

  const dispatchChange = newOrg => {
    const sites = ZoneUtils.getRootZonesFromHierarchy(zonesHierarchy)
    const site = sites.find(({ organizationId }) => organizationId === newOrg)

    dispatchState({
      organizations: [newOrg],
      sites: [site.id],
      zonePath: `/${site.id}`
    })
  }

  const onChangeFilter = e => {
    const newOrg = e?.value ?? null
    setOrgFilter(newOrg)
    dispatchChange(newOrg)
  }

  const options = organizations.map(({ id, name }) => ({
    value: id,
    label: name
  }))

  return (
    <Select
      value={orgFilter}
      placeholder={`${I18n.get('Select')} ${orgString}`}
      options={options}
      onChange={onChangeFilter}
      isDisabled={disabled}
      className='Operations__Databoard__Filter'
      classNamePrefix='Operations__Databoard__Filter'
    />
  )
}

export default OrganizationsFilter

import { I18n } from 'aws-amplify'
import { object, number, array } from 'yup'

export const cleanDeployment = deployment => {
  let { coreDeviceExecutionStatus, reason } = deployment

  const complete = coreDeviceExecutionStatus === 'COMPLETED'
  const successful = reason?.includes('SUCCESSFUL:')

  if (complete && successful) {
    deployment['reason'] = reason.split(':')[0]
  }

  return deployment
}

const fieldIsRequired = () => I18n.get('Please provide a value')
const fieldMustBeNumber = () => I18n.get('Only numbers are supported')
const minOfOne = () => I18n.get('Frequency can not be less than one')

const configSchema = () =>
  object().shape({
    frequency: number()
      .min(1, minOfOne)
      .typeError(fieldMustBeNumber)
      .required(fieldIsRequired)
  })

export const updateIngestionFrequencySchema = () => {
  return object().shape({
    config: array().of(configSchema()).required()
  })
}

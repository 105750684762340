import { Children, Fragment, useEffect, useMemo, useState } from 'react'
import {
  useHistory,
  useLocation,
  Route,
  NavLink,
  Switch,
  useParams
} from 'react-router-dom'

import { useDispatch } from 'react-redux'

import { FlexV2, Separator, Text, Button } from '@/primitives'
import NavigationBreadcrumbs from '@/components/Shared/NavigationBreadcrumbs'

import {
  getZone,
  getCurrentUser,
  getZoneHierarchyLoading,
  getZoneHierarchy
} from '@/reducers/selectors'

import { getZoneDepth, getZoneObjectFromZoneHierarchy } from '@/Util/AdminUtils'

import BreadcrumbUtils from '@/Util/BreadcrumbUtils'

import {
  RESOURCE_TYPE_DEVICE,
  RESOURCE_TYPE_DEVICE_MANAGEMENT,
  RESOURCE_TYPE_DEVICE_THRESHOLDS,
  RESOURCE_TYPE_USER,
  RESOURCE_TYPE_USER_MANAGERS,
  hasEditPermissions,
  hasReadPermissions,
  getIsGodMode
} from '@/Util/PermissionUtils'

import {
  AVAILABLE_FEATURE_FLAGS,
  ENABLE_FLEET_MANAGEMENT,
  hasFeatureFlagEnabled
} from '@/Util/FeatureFlagsUtils'

import { buildNavigationSelector } from '@/Util/NavigationUtils'

import useOrgLabel from '@/hooks/useOrgLabel'

import { fetchZone, fetchZoneManagementData, cleanZonesList } from '@/slices/management/zone'
import { fetchManagersByZoneId } from '@/slices/manager'
import { fetchZoneHierarchy } from '@/slices/management/hierarchy'
import { setLocationSelector } from '@/slices/navigation'
import { cleanThresholds, fetchThresholdsByZoneId } from '@/slices/threshold'

import useDeviceSize from '@/hooks/useDeviceSize'

import { getZoneSubzonesTabName } from '../../utils'

import {
  ZoneDetails,
  ZoneSubzones,
  ZoneDevices,
  ZoneManagers,
  ZoneThresholds,
  ZoneFleetManagement,
  ZoneControl
} from '../Tabs'

import Strings from '../../Strings'

import { redirectToDetails } from './utils'

import './index.scss'

const MODULE_PATH = '/admin/zones'

const ALL_ROUTE_OPTIONS = [
  ['details', ZoneDetails],
  ['subzones', ZoneSubzones],
  ['devices', ZoneDevices],
  ['managers', ZoneManagers],
  ['thresholds', ZoneThresholds],
  ['fleet-management', ZoneFleetManagement],
  ['control', ZoneControl]
]

const tabRoutes = ALL_ROUTE_OPTIONS.map(item => item[0])

function getZonesFromRoute(path) {
  const currentSection = tabRoutes?.find(route => path.includes(route))
  const zonesPath = path?.split(currentSection)[0]
  return zonesPath
}

const getTargetRouteDataFromPath = matchedZonePath => {
  const zonesPath = getZonesFromRoute(matchedZonePath)
  const pathArray = zonesPath.split('/').filter(item => item.length)
  const id = pathArray.pop()

  if (ALL_ROUTE_OPTIONS.map(([endpoint]) => endpoint).includes(id)) {
    return pathArray[pathArray.length - 2]
  }

  return id
}

export default function Zone() {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const params = useParams()

  const zone = getZone()
  const coretexUser = getCurrentUser()
  const isGodMode = getIsGodMode(coretexUser)
  const zoneHierarchy = getZoneHierarchy()
  const zoneHierarchyLoading = getZoneHierarchyLoading()

  const isMobile = useDeviceSize()
  const [breadcrumbs, setBreadcrumbs] = useState([])
  const [hasFetchedZoneHierarchy, setHasFetchedZoneHierarchy] = useState(false)
  const orgLabels = useOrgLabel(['site', 'facility', 'room', 'zone', 'subzone'])
  const strings = Strings()

  const showDevicesOption = hasReadPermissions(
    coretexUser,
    RESOURCE_TYPE_DEVICE,
    RESOURCE_TYPE_DEVICE_MANAGEMENT
  )
  const showManagersOption = hasReadPermissions(
    coretexUser,
    RESOURCE_TYPE_USER,
    RESOURCE_TYPE_USER_MANAGERS
  )
  const showThresholdsOption = hasReadPermissions(
    coretexUser,
    RESOURCE_TYPE_DEVICE,
    RESOURCE_TYPE_DEVICE_THRESHOLDS
  )

  const hasFleetEditPermissions = hasEditPermissions(
    coretexUser,
    RESOURCE_TYPE_DEVICE,
    RESOURCE_TYPE_DEVICE_MANAGEMENT
  )

  const enableFleetManagement = hasFeatureFlagEnabled(
    coretexUser,
    AVAILABLE_FEATURE_FLAGS[ENABLE_FLEET_MANAGEMENT],
    Object.keys(zoneHierarchy).length > 0 ? zoneHierarchy.organizationId : null
  )

  const showFleetManagementOption =
    ((enableFleetManagement && hasFleetEditPermissions) || isGodMode) &&
    getZoneDepth(zone) === 1

  const showControlOption = isGodMode && getZoneDepth(zone) === 3

  const subzonesTabHeading = getZoneSubzonesTabName(zone, orgLabels)

  const zoneId = params.zone.split('/')[0]

  const inPageRoutes = useMemo(() => {
    return ALL_ROUTE_OPTIONS.map(([endpoint, Component]) => {
      if (
        (endpoint === 'devices' && !showDevicesOption) ||
        (endpoint === 'managers' && !showManagersOption) ||
        (endpoint === 'thresholds' && !showThresholdsOption) ||
        (endpoint === 'fleet-management' && !showFleetManagementOption) ||
        (endpoint === 'control' && !showControlOption)
      ) {
        return null
      }

      /* Skip rendering the subzones
         tab for anything below the first subzones, that do not already contain children
      */

      if (endpoint === 'subzones' && getZoneDepth(zone) > 4) {
        const currentZoneChildren = getZoneObjectFromZoneHierarchy(
          zoneHierarchy,
          zone
        )?.children
        if (
          currentZoneChildren &&
          Object.keys(currentZoneChildren).length === 0
        ) {
          return null
        }
      }

      const title =
        strings[`${endpoint}Heading`] ||
        (endpoint === 'fleet-management'
          ? strings.fleetManagementHeading
          : subzonesTabHeading)

      const zonesPath = getZonesFromRoute(params.zone)

      return {
        navTo: `${MODULE_PATH}/${zonesPath}${endpoint}`,
        routeTo: `${MODULE_PATH}/:zone+/${endpoint}`,
        Component,
        title
      }
    }).filter(route => route)
  }, [
    params.zone,
    showDevicesOption,
    showManagersOption,
    showThresholdsOption,
    showFleetManagementOption,
    zone,
    zoneHierarchy,
    strings,
    subzonesTabHeading
  ])

  useEffect(() => {
    if (zoneId) {
      dispatch(fetchZone({ id: zoneId }))
      dispatch(fetchZoneHierarchy({ zoneId }))
      setHasFetchedZoneHierarchy(true)
    }
  }, [dispatch, zoneId, setHasFetchedZoneHierarchy])

  useEffect(() => {
    if (zone?.id && zoneHierarchy?.organizationId) {
      const siteId = zone?.parentPath.split('/').pop()
      const siteHierarchy = zoneHierarchy[siteId]

      const zoneOrg = coretexUser.organizations.find(
        org => org.id === siteHierarchy?.organizationId
      )

      const navSelector = buildNavigationSelector(
        siteHierarchy,
        zone.parentPath,
        zoneOrg
      )

      dispatch(setLocationSelector(navSelector))
    }
  }, [zone, zoneHierarchy, coretexUser.organizations, dispatch])

  useEffect(() => {
    const id = getTargetRouteDataFromPath(location.pathname)
    if (zone?.id && id !== zone?.id) {
      dispatch(fetchZone({ id }))
      if (!Object.keys(zoneHierarchy).length && !zoneHierarchyLoading) {
        dispatch(fetchZoneHierarchy({ zoneId }))
      }
    }
  }, [
    location.pathname,
    dispatch,
    zoneId,
    zone?.id,
    zoneHierarchy,
    zoneHierarchyLoading
  ])

  useEffect(() => {
    dispatch(cleanThresholds())
    dispatch(cleanZonesList())

    if (zone?.id) {
      dispatch(fetchZoneManagementData({ zoneId: zone.id }))
      dispatch(fetchManagersByZoneId({ zoneId: zone.id }))
      dispatch(fetchThresholdsByZoneId({ id: zone.id }))
    }
  }, [zone?.id, dispatch])

  useEffect(() => {
    if (zone?.id) {
      /* Altering this here so that we don't disturb other breadcrumbs */
      const newBreadcrumbs = BreadcrumbUtils.generateAdminBreadcrumbs(
        zone,
        zoneHierarchy,
        isMobile
      ).map(({ id, name, parentPath }) => ({
        id,
        name,
        parentPath:
          parentPath !== '/admin/zones' ? parentPath + '/details' : parentPath
      }))
      setBreadcrumbs(newBreadcrumbs)
    }
  }, [zone, zone?.hasCoreDevice, zoneHierarchy, isMobile])

  useEffect(() => {
    if (!showFleetManagementOption && coretexUser?.userName && !isGodMode) {
      redirectToDetails(history, location?.pathname, params)
    }
  }, [
    isGodMode,
    coretexUser?.userName,
    showFleetManagementOption,
    history,
    location?.pathname,
    params
  ])

  if (!zone?.id && !zoneHierarchyLoading && hasFetchedZoneHierarchy) {
    return (
      <FlexV2
        direction='column'
        alignCrossAxis='center'
        alignMainAxis='center'
        style={{ width: '100%', flexGrow: 1 }}
      >
        <Text as='h2'>{strings.zoneDoesNotExist}</Text>
        <Button
          variant='primary'
          tone={500}
          onClick={() => history.push('/admin/zones')}
        >
          {strings.goBack}
        </Button>
      </FlexV2>
    )
  }

  return (
    <FlexV2 direction='column' axisGap={500}>
      {zone?.id && (
        <Fragment>
          <NavigationBreadcrumbs
            breadCrumbs={breadcrumbs}
            zone={zone}
            showTag={false}
          />

          <Separator />

          <Text as='h3' style={{ marginBottom: 0 }}>
            {zone.name}
          </Text>

          <FlexV2 className='AdminZones__Tabs'>
            {Children.toArray(
              inPageRoutes.map(({ navTo, title }) => (
                <NavLink to={navTo}>{title}</NavLink>
              ))
            )}
          </FlexV2>
          <Switch>
            {Children.toArray(
              inPageRoutes.map(({ routeTo, Component }) => (
                <Route path={routeTo}>
                  <Component
                    selectedZone={zone}
                    setBreadcrumbs={setBreadcrumbs}
                  />
                </Route>
              ))
            )}
          </Switch>
        </Fragment>
      )}
    </FlexV2>
  )
}

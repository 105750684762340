import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Dialog } from '@/elements'
import { Slot, Button, Text, Flex, Box, Loader, Icon } from '@/primitives'

import { fetchLocation } from '@/slices/location'
import { sendUpdateDevice } from '@/slices/management/device'
import { getLocation, getFetchingLocation } from '@/reducers/selectors'
import Strings from './Strings'

const strings = Strings()

async function handlePermission(permission, setPermission) {
  const response = await navigator.permissions.query({ name: 'geolocation' })
  if (permission !== response?.state) setPermission(response?.state)
}

async function onSubmit(device, latitude, longitude, dispatch) {
  if (latitude && longitude) {
    const payload = {
      id: device.id,
      name: device.name,
      type: device.sensorType,
      zoneId: device.zoneId,
      zonePath: device.zonePath,
      latitude,
      longitude,
      tag: device.tag,
      hardwareVersion: device.hardwareVersion,
      isIoT: device.isIoT,
      isGreengrass: device.isGreengrass,
      config: device.config
    }

    dispatch(
      sendUpdateDevice({
        device: payload,
        deviceMove: null,
        prevConfig: device.config
      })
    )
  }
}

const UpdateCoordinatesForm = ({ isVisible, hideForm, device }) => {
  const dispatch = useDispatch()

  const fetchingLocation = getFetchingLocation()
  const location = getLocation() ?? {}

  const [latitude, setLatitude] = useState(null)
  const [longitude, setLongitude] = useState(null)
  const [permission, setPermission] = useState(null)
  const [formSubmitted, setFormSubmitted] = useState(false)

  const noCoordinatesSet = !latitude && !longitude
  const noLocationData = !location?.latitude && !location?.longitude
  const permissionDenied = permission === 'denied'

  useEffect(() => {
    if (
      !fetchingLocation &&
      noLocationData &&
      !permissionDenied &&
      noCoordinatesSet
    ) {
      dispatch(
        fetchLocation({
          enableHighAccuracy: true,
          timeout: 15000, // 15 seconds
          maximumAge: 0
        })
      )
    }
  }, [fetchingLocation, permissionDenied, noCoordinatesSet, noLocationData])

  useEffect(() => {
    if (!noLocationData) {
      if (location?.latitude !== latitude) setLatitude(location?.latitude)
      if (location?.longitude !== longitude) setLongitude(location?.longitude)
    }
  }, [noLocationData, location, noCoordinatesSet])

  useEffect(() => {
    if (
      formSubmitted &&
      device?.latitude === latitude &&
      device?.longitude === longitude
    ) {
      hideForm()
      setFormSubmitted(false)
    }
  }, [
    formSubmitted && device?.latitude,
    device?.longtitude,
    latitude,
    longitude
  ])

  handlePermission(permission, setPermission)

  function onClickRefetch() {
    dispatch(
      fetchLocation({
        enableHighAccuracy: true,
        timeout: 15000, // 15 seconds
        maximumAge: 0
      })
    )
  }

  function onClickApply() {
    onSubmit(device, latitude, longitude, dispatch)
    setFormSubmitted(true)
  }

  function insertNote(text) {
    return (
      <Flex direction='row' wrap='nowrap' style={{ margin: '1rem 0 1.4rem' }}>
        <Text variant='danger' tone={800}>
          <Icon
            name='warning'
            variant='danger'
            tone={800}
            size={300}
            style={{ marginBottom: '-4px', marginInlineEnd: '0.4rem' }}
          />
          {text}
        </Text>
      </Flex>
    )
  }

  return (
    <Dialog
      open={isVisible}
      onOpenChange={hideForm}
      type='modal'
      style={{ textAlign: 'start' }}
    >
      <Slot name='title'>
        <Text as='h4' style={{ marginBottom: '0.5rem', lineHeight: '2rem' }}>
          {strings.updateDeviceCoords}
        </Text>
      </Slot>
      <Slot name='content'>
        <Text variant='page' tone={800}>
          {strings.coordsDescriptionA}
          <Text variant='primary' tone={600}>
            {device.name}
          </Text>
          {`. ${strings.coordsDescriptionB}`}
        </Text>
        <Box className='Coordinates__Box'>
          <Text as='p' style={{ margin: '0 0 1rem' }}>
            {strings.savedCoords}
          </Text>
          <Flex axisGap={500}>
            <Box style={{ flexGrow: '1', flexBasis: '0' }}>
              <Text variant='page' tone={800}>
                {strings.locationFormLatitude}
              </Text>
              <Box style={{ paddingTop: '0rem' }}>
                <Text size={300} fontWeight={500}>
                  {device.latitude}
                </Text>
              </Box>
            </Box>
            <Box style={{ flexGrow: '1', flexBasis: '0' }}>
              <Text variant='page' tone={800}>
                {strings.locationFormLongitude}
              </Text>
              <Box style={{ paddingTop: '0rem' }}>
                <Text size={300} fontWeight={500}>
                  {device.longitude}
                </Text>
              </Box>
            </Box>
          </Flex>
        </Box>

        <Box className='Coordinates__Box'>
          <Flex
            alignMainAxis='space-between'
            alignCrossAxis='flex-start'
            axisGap={300}
            wrap='nowrap'
            style={{ marginBottom: '0.5rem' }}
          >
            <Text as='p' style={{ margin: '0', lineHeight: '1.5rem' }}>
              {strings.currentCoords}
            </Text>
            <Button
              size='small'
              iconBefore='refresh'
              onClick={onClickRefetch}
              variant='info'
              style={{ padding: '0.6rem' }}
              disabled={fetchingLocation || permissionDenied}
            />
          </Flex>
          {permission && permissionDenied && insertNote(strings.locationDenied)}
          <Loader isLoading={fetchingLocation} text={'Fetching coordinates'}>
            <Flex axisGap={500}>
              <Box style={{ flexGrow: '1', flexBasis: '0' }}>
                <Text variant='page' tone={800}>
                  {strings.locationFormLatitude}
                </Text>
                <Box style={{ paddingTop: '0.3rem' }}>
                  <Text size={300} fontWeight={500}>
                    {latitude ?? strings.unavailable}
                  </Text>
                </Box>
              </Box>
              <Box style={{ flexGrow: '1', flexBasis: '0' }}>
                <Text variant='page' tone={800}>
                  {strings.locationFormLongitude}
                </Text>
                <Box style={{ paddingTop: '0.3rem' }}>
                  <Text size={300} fontWeight={500}>
                    {longitude ?? strings.unavailable}
                  </Text>
                </Box>
              </Box>
            </Flex>
          </Loader>
        </Box>
      </Slot>
      <Slot name='actions'>
        <Flex axisGap={400} alignMainAxis='space-between'>
          <Button variant='error' onClick={hideForm}>
            {strings.cancel}
          </Button>
          <Button
            onClick={onClickApply}
            variant='primary'
            disabled={!latitude || !longitude}
          >
            {strings.useCurrentCoords}
          </Button>
        </Flex>
      </Slot>
    </Dialog>
  )
}

export default UpdateCoordinatesForm

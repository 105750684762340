import { Children } from 'react'
import { Box } from '../'
import './index.scss'

const composeCSS = styles =>
  Object.fromEntries(Object.entries(styles).filter(entry => entry[1] !== null))

/**
 * GridItem Child
 * @param {Object} props
 * @param { String } [props.className]
 * @param { 'start' | 'center' | 'end' | 'stretch' } [props.alignSelf]
 * @param { 'start' | 'center' | 'end' | 'stretch' } [props.justifySelf]
 * @param { String } [props.column]
 * @param { String } [props.columnStart]
 * @param { String } [props.columnEnd]
 * @param { String } [props.row]
 * @param { String } [props.rowStart]
 * @param { String } [props.rowEnd]
 * @param {import('react').CSSProperties} [props.css]
 */
const GridItem = item => {
  if (!item.props) {
    return item
  }

  const {
    key,
    props: {
      className = null,
      children = null,
      column = null,
      columnStart = null,
      columnEnd = null,
      row = null,
      rowStart = null,
      rowEnd = null,
      alignSelf = null,
      justifySelf = null,
      css = {},
      ...rest
    }
  } = item

  return (
    <Box
      className={[
        'GridItem',
        {
          [`js_${justifySelf}`]: justifySelf,
          [`as_${alignSelf}`]: alignSelf
        },
        className
      ]}
      key={key}
      css={Object.assign(
        css,
        composeCSS({
          gridColumn: column,
          gridColumnStart: columnStart,
          gridColumnEnd: columnEnd,
          gridRow: row,
          gridRowStart: rowStart,
          gridRowEnd: rowEnd
        })
      )}
      {...rest}
    >
      {children}
    </Box>
  )
}

/**
 * Grid Primitive
 * @param {Object} props
 * @param { 'start' | 'center' | 'end' | 'stretch' | 'space-around'| 'space-between' | 'space-evenly' } [props.alignContent]
 * @param { 'start' | 'center' | 'end' | 'stretch' } [props.alignItems]
 * @param { 'row' | 'column' | 'dense' } [props.autoFlow]
 * @param { String } [props.autoColumns]
 * @param { String } [props.className]
 * @param { 0 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 | 1000 } [props.gap]
 * @param { 'start' | 'center' | 'end' | 'stretch' | 'space-around'| 'space-between' | 'space-evenly' } [props.justifyContent]
 * @param { 'start' | 'center' | 'end' | 'stretch' } [props.justifyItems]
 * @param { String } [props.templateColumns]
 * @param { String } [props.templateRows]
 * @param {import('react').CSSProperties} [props.css]
 */

const Grid = ({
  alignContent = null,
  alignItems = null,
  autoFlow = null,
  autoColumns = null,
  className = null,
  children = null,
  gap = null,
  justifyContent = null,
  justifyItems = null,
  templateColumns = null,
  templateRows = null,
  css = {},
  ...rest
}) => {
  return (
    <Box
      className={[
        'Grid',
        {
          [`gap_${gap}`]: gap,
          [`ac_${alignContent}`]: alignContent,
          [`ai_${alignItems}`]: alignItems,
          [`af_${autoFlow}`]: autoFlow,
          [`jc_${justifyContent}`]: justifyContent,
          [`ji_${justifyItems}`]: justifyItems
        },
        className
      ]}
      css={Object.assign(
        css,
        composeCSS({
          gridAutoColumns: autoColumns,
          gridTemplateColumns: templateColumns,
          gridTemplateRows: templateRows
        })
      )}
      {...rest}
    >
      {Children.toArray(children).map(GridItem)}
    </Box>
  )
}

export default Grid

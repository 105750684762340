import { call, put, takeLatest } from 'redux-saga/effects'

import {
  getUnitPrices,
  getUnitPrice,
  createUnitPrice,
  updateUnitPrice,
  deleteUnitPrice
} from '@/api/operations/unitPrice'

import {
  REQUEST_UNIT_PRICES,
  REQUEST_UNIT_PRICE,
  CREATE_UNIT_PRICE,
  UPDATE_UNIT_PRICE,
  DELETE_UNIT_PRICE,
  receiveUnitPrices,
  receiveUnitPrice,
  unitPriceCreated,
  unitPriceUpdated,
  unitPriceDeleted
} from '@/actions/operations/unitPrice'

import { displayBanner } from '../utils'

import Strings from './Strings'

const strings = Strings()

function* sendCreateUnitPrice(action) {
  const { data, error = null } = yield call(createUnitPrice, action.params)
  yield put(displayBanner(error, strings.unitPriceCreated))
  yield put(unitPriceCreated(data, error))
}

export function* watchCreateUnitPrice() {
  yield takeLatest(CREATE_UNIT_PRICE, sendCreateUnitPrice)
}

function* sendUpdateUnitPrice(action) {
  const { data, error = null } = yield call(updateUnitPrice, action.params)
  yield put(displayBanner(error, strings.unitPriceUpdated))
  yield put(unitPriceUpdated(data, error))
}

export function* watchUpdateUnitPrice() {
  yield takeLatest(UPDATE_UNIT_PRICE, sendUpdateUnitPrice)
}

function* sendRequestUnitPrices(action) {
  const { data, error = null } = yield call(getUnitPrices, action.params)
  const { unitPrices = [], count = 0 } = data
  yield put(receiveUnitPrices(unitPrices, count, error))
}

export function* watchRequestUnitPrices() {
  yield takeLatest(REQUEST_UNIT_PRICES, sendRequestUnitPrices)
}

function* sendRequestUnitPrice(action) {
  const { data, error = null } = yield call(getUnitPrice, action.params)
  yield put(receiveUnitPrice(data, error))
}

export function* watchRequestUnitPrice() {
  yield takeLatest(REQUEST_UNIT_PRICE, sendRequestUnitPrice)
}

function* sendDeleteUnitPrice(action) {
  const { data, error = null } = yield call(deleteUnitPrice, action.params)
  yield put(displayBanner(error, strings.unitPriceDeleted))
  yield put(unitPriceDeleted(data, error))
}

export function* watchDeleteUnitPrice() {
  yield takeLatest(DELETE_UNIT_PRICE, sendDeleteUnitPrice)
}

import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { makeGraphqlRequestV2 } from '../utils'

/*
 * Queries
 */

export const getSensorThresholdUserAck = async params => {
  try {
    const query = /* GraphQL */ `
      query getSensorThresholdUserAck(
        $userId: String!
        $sensorId: String!
        $measurementId: String!
        $zoneId: String!
      ) {
        getSensorThresholdUserAck(
          userId: $userId
          sensorId: $sensorId
          zoneId: $zoneId
          measurementId: $measurementId
        ) {
          userId
          sensorId
          zoneId
          measurementId
          createdAt
        }
      }
    `

    const operation = graphqlOperation(query, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.getSensorThresholdUserAck
  } catch (err) {
    throw err.message
  }
}

export const getSensorThresholdUserAcks = async params => {
  try {
    const query = /* GraphQL */ `
      query getSensorThresholdUserAcks(
        $userAcks: [UserAckRequest!]
        $rootId: String
        $upsertAck: Boolean
      ) {
        getSensorThresholdUserAcks(
          userAcks: $userAcks
          rootId: $rootId
          upsertAck: $upsertAck
        ) {
          userId
          sensorId
          zoneId
          measurementId
          createdAt
        }
      }
    `

    const operation = graphqlOperation(query, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)
    return data?.getSensorThresholdUserAcks
  } catch (err) {
    throw err.message
  }
}

/*
 * Mutations
 */

export const createSensorThresholdUserAck = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createSensorThresholdUserAck(
        $userId: String!
        $sensorId: String!
        $measurementId: String!
        $zoneId: String!
        $rootId: String!
      ) {
        createSensorThresholdUserAck(
          userId: $userId
          sensorId: $sensorId
          zoneId: $zoneId
          measurementId: $measurementId
          rootId: $rootId
        ) {
          userId
          sensorId
          zoneId
          measurementId
          createdAt
        }
      }
    `

    const operation = graphqlOperation(mutation, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.createSensorThresholdUserAck
  } catch (err) {
    throw err.message
  }
}

import FlexV2 from '@/primitives/FlexV2'
import Strings from '../Strings'
import Text from '@/primitives/Text'
import PermissionToggle from './PermissionToggle'

const ControlTrigger = ({
  sectionState,
  label,
  readState,
  sectionName,
  readId,
  indexesByResource,
  dispatch,
  disabled,
  writeId,
  writeState
}) => {
  const strings = Strings()

  return (
    <FlexV2 alignMainAxis='space-between' alignCrossAxis='center' axisGap={400}>
      {/* ----------------------------------------- */}
      <Text
        variant={sectionState === 'enabled' ? 'primary' : 'page'}
        tone='800'
        size={300}
        fontWeight={400}
      >
        {strings[sectionName] || label}
      </Text>
      {/* ----------------------------------------- */}

      {/* ----------------------------------------- */}
      <FlexV2 axisGap={400}>
        <FlexV2
          direction={'column'}
          axisGap={200}
          alignCrossAxis='center'
          className={'Control'}
        >
          <Text variant={'page'} tone={800}>
            {strings.viewButtonSentence}
          </Text>
          <PermissionToggle
            checked={readState === 'enabled'}
            permissionId={sectionName === 'organization' ? null : readId}
            sectionName={sectionName}
            dispatch={dispatch}
            indeterminate={readState === 'indeterminate'}
            supportsIndeterminate={indexesByResource?.sub?.length > 1}
            disabled={disabled}
          />
        </FlexV2>
        {/* ----------------------------------------- */}

        {/* ----------------------------------------- */}
        <FlexV2
          direction={'column'}
          axisGap={200}
          alignCrossAxis='center'
          className={'Control'}
        >
          <Text variant={'page'} tone={800}>
            {strings.editButtonSentence}
          </Text>
          <PermissionToggle
            checked={writeState === 'enabled'}
            permissionId={sectionName === 'organization' ? null : writeId}
            sectionName={sectionName}
            dispatch={dispatch}
            indeterminate={writeState === 'indeterminate'}
            supportsIndeterminate={indexesByResource.sub?.length > 1}
            disabled={disabled}
          />
        </FlexV2>
        {/* ----------------------------------------- */}
      </FlexV2>
    </FlexV2>
  )
}

export default ControlTrigger

import { call, put, all, takeEvery, select } from 'redux-saga/effects'
import { nanoid } from 'nanoid'

import {
  FETCH_CURRENT_DATA,
  currentDataFetched,
  FETCH_CURRENT_MOBILE_DATA,
  currentMobileDataFetched,
  FETCH_AVERAGE_DATA,
  averageDataFetched,
  FETCH_DATASET,
  datasetFetched,
  FETCH_CURRENT_SIMPLE_DASHBOARD_DATA,
  currentSimpleDashboardDataFetched
} from '@/actions/ts/dashboard'

import { getAverageMinxMax } from '@/api/ts/average'
import { getCurrentAveMinMax } from '@/api/ts/current'
import { getDatasetAverage } from '@/api/ts/dataset'
import { getRootZone, takeCombined } from '../utils'

import { getDeviceTypes } from '../utils'

// Live (current)

export function* sendFetchCurrentData(action) {
  const { currentAverageMinMax, error = null } = yield call(
    getCurrentAveMinMax,
    action.params
  )
  yield put(currentDataFetched(currentAverageMinMax, error))
}

function* sendFetchCurrentCombinedData(combinedParams) {
  const params = combinedParams[0].params

  const measurements = new Set()

  for (let { params } of combinedParams) {
    for (let measurement of params.measurements) {
      measurements.add(measurement)
    }
  }

  params.measurements = Array.from(measurements)

  const { currentAverageMinMax, error = null } = yield call(
    getCurrentAveMinMax,
    params
  )
  yield put(currentDataFetched(currentAverageMinMax, error))
}

export function* watchSendFetchCurrentData() {
  yield takeCombined(FETCH_CURRENT_DATA, sendFetchCurrentCombinedData)
}

const generateCalls = paramsPerSensor => {
  return paramsPerSensor.map(params => call(getCurrentAveMinMax, params))
}

export function* sendFetchCurrentMobileData(action) {
  const deviceTypes = yield select(getDeviceTypes)

  const { zoneIds, measurements, devices, ...rest } = action.params

  const paramsPerDevice = devices.map(device => {
    const { id, sensorType } = device
    const deviceType = deviceTypes.find(type => type.id === sensorType)
    return {
      sensorId: id,
      measurements: deviceType.measurements,
      isCellular: sensorType?.includes('cellular'),
      ...rest
    }
  })

  const paramsPerZone = zoneIds.map(sensorId => {
    return {
      sensorId,
      measurements,
      ...rest
    }
  })

  const paramsPerSensor = [...paramsPerDevice, ...paramsPerZone]

  const response = yield all(generateCalls(paramsPerSensor))
  const data = response.map(({ currentAverageMinMax }) => currentAverageMinMax)
  // TODO return errors
  yield put(currentMobileDataFetched(data))
}

export function* watchSendFetchCurrentMobileData() {
  yield takeEvery(FETCH_CURRENT_MOBILE_DATA, sendFetchCurrentMobileData)
}

// Averages

// export function* sendFetchAverageData(action) {
//   const { averageMinMax, error = null } = yield call(
//     getAverageMinxMax,
//     action.params
//   )
//   yield put(
//     averageDataFetched(averageMinMax, action.params, action.seed, error)
//   )
// }

function* sendFetchAverageCombinedData(combinedParams) {
  const rangeMeasurements = {}

  let params = combinedParams[0].params

  for (let { params } of combinedParams) {
    if (!rangeMeasurements[params.rangeFilter]) {
      rangeMeasurements[params.rangeFilter] = new Set()
    }

    for (let measurement of params.measurements) {
      rangeMeasurements[params.rangeFilter].add(measurement)
    }
  }

  for (let rangeFilter of Object.keys(rangeMeasurements)) {
    params.measurements = Array.from(rangeMeasurements[rangeFilter])
    params.rangeFilter = rangeFilter

    const { averageMinMax, error = null } = yield call(
      getAverageMinxMax,
      params
    )

    yield put(averageDataFetched(averageMinMax, params, nanoid(), error))
  }
}

export function* watchSendFetchAverageData() {
  yield takeCombined(FETCH_AVERAGE_DATA, sendFetchAverageCombinedData)
}

// Datasets

export function* sendFetchDataset(action) {
  const rootZone = yield select(getRootZone)

  const { measurements, zones, ...rest } = action.params
  let response = {}

  for (let i = 0; i < zones.length; i++) {
    const measurementsCalls = measurements.map(measurement =>
      call(getDatasetAverage, { measurement, sensorId: zones[i], ...rest })
    )

    const responseByZone = yield all(measurementsCalls)
    response[zones[i]] = responseByZone
  }

  yield put(
    datasetFetched(
      response,
      action.params,
      action.seed,
      rootZone?.timeZone ?? 'UTC'
    )
  )
}

export function* watchSendFetchDataset() {
  yield takeEvery(FETCH_DATASET, sendFetchDataset)
}

// Simplified dashboard

export function* sendFetchCurrentSimpleDashboardData(action) {
  const { id, ...rest } = action.params
  const response = yield call(getCurrentAveMinMax, { sensorId: id, ...rest })
  yield put(currentSimpleDashboardDataFetched(response?.currentAverageMinMax))
}

export function* watchSendFetchCurrentSimpleData() {
  yield takeEvery(FETCH_CURRENT_SIMPLE_DASHBOARD_DATA, sendFetchCurrentSimpleDashboardData)
}
import { UNION, RULE } from './constants'
import { extend, hash, toDashCase } from './utils'

export default function tokenize([property, value, ancestors]) {
  // explicity quote content values
  if (property === 'content') value = `'${value}'`
  const cssHash = '_' + hash(property + value)
  const dotHash = '.' + cssHash
  const dashProp = toDashCase(property)
  const declarationBody = `{${dashProp}:${value};}`
  const firstAncestorHash = ancestors ? hash(ancestors[0]) : ''
  const firstAncestorSelector = ancestors ? ancestors[0].replace(/&/, '') : ''
  const allAncestorsHash = ancestors ? hash(ancestors.join('')) : ''
  const pseudoSelector = ancestors
    ? ancestors
        .filter(a => a.match(UNION))
        .join('')
        .replace(UNION, '')
    : ''

  if (ancestors) {
    if (ancestors[0].match(UNION)) {
      return extend(RULE, {
        cssText:
          dotHash +
          '_' +
          firstAncestorHash +
          firstAncestorSelector +
          declarationBody,
        selectorText: dotHash + '_' + firstAncestorHash + firstAncestorSelector,
        className: cssHash + '_' + firstAncestorHash
      })
    } else {
      return extend(RULE, {
        type: 'group',
        cssText: [
          ancestors.filter(anc => !anc.match(UNION)).join('{'),
          '{',
          dotHash,
          '_',
          allAncestorsHash,
          pseudoSelector,
          declarationBody,
          ancestors.map(a => '').join('}')
        ].join(''),
        selectorText: dotHash + '_' + allAncestorsHash + pseudoSelector,
        className: cssHash + '_' + allAncestorsHash
      })
    }
  }

  return extend(RULE, {
    cssText: dotHash + declarationBody,
    selectorText: dotHash,
    className: cssHash
  })
}

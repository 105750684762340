export function isObject(item) {
  return (
    item && typeof item === 'object' && !Array.isArray(item) && item != null
  )
}
export function isObjectEmpty(obj) {
  if (obj) {
    return (
      Object.keys(obj).length === 0 &&
      Object.getPrototypeOf(obj) === Object.prototype &&
      obj.constructor === Object
    )
  }
  return true
}

import { I18n } from 'aws-amplify'
import { nanoid } from 'nanoid'
import { number, object, string } from 'yup'

export const weightSchema = object().shape({
  totalKg: number()
    .integer('A partial weight is not allowed.')
    .typeError('Weight is required.')
    .min(1, I18n.get('Weight must be at least 1kg.'))
    .required(I18n.get('Weight is required.')),
  gradeId: string().required('A grade must be selected.')
})

const getTotalWeight = weights => {
  return weights.reduce((acc, obj) => acc + parseInt(obj.totalKg), 0)
}

/**
 * Adds or updates a Weight object.
 * If the action param contains an `id`, it will only update that weight (if found). If it does not have any id or
 * no Weight with that id is found, a new weight will be added
 *
 * @param {Object} state
 * @param {object} action
 * @returns
 */
export function updateWeight(state, action) {
  let weights = [...state.weights]
  if (action?.weight?.id) {
    const index = weights.findIndex(({ id }) => id === action.weight.id)
    if (index > -1) {
      weights[index] = action.weight
    }
  } else {
    weights.push({
      id: nanoid(),
      totalKg: '',
      gradeId: ''
    })
  }
  return {
    ...state,
    weights,
    totalWeight: getTotalWeight(weights)
  }
}

export function removeWeight(state, action) {
  let weights = [...state.weights]
  if (action.weight.id) {
    const index = weights.findIndex(({ id }) => id === action.weight.id)
    if (index > -1) {
      weights.splice(index, 1)
    }
  }
  return {
    ...state,
    weights,
    totalWeight: getTotalWeight(weights)
  }
}

import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { getCognitoData, makeGraphqlRequest } from '../utils'

/*
 * Queries
 */

export const getSettingsForObject = async (params = {}) => {
  try {
    const query = /* GraphQL */ `
      query getSettingsForObject($objectId: String!, $type: String!) {
        getSettingsForObject(objectId: $objectId, type: $type) {
          objectId
          type
          settings {
            id
            name
            value
          }
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)

    const { data } = await makeGraphqlRequest(GRAPHQL_URL, operation, idToken)

    if (!data?.getSettingsForObject) {
      throw Error('Unable to fetch settings')
    }

    return { objectSettings: data?.getSettingsForObject, error: null }
  } catch (err) {
    throw err
  }
}

/*
 * Mutations
 */

export const turnOnOffSetting = async (params = {}) => {
  try {
    const mutation = /* GraphQL */ `
      mutation turnOnOffSetting(
        $objectId: String!
        $type: String!
        $setting: String!
      ) {
        turnOnOffSetting(objectId: $objectId, type: $type, setting: $setting) {
          objectId
          type
          settings {
            id
            name
            value
          }
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)

    const { data } = await makeGraphqlRequest(GRAPHQL_URL, operation, idToken)

    if (!data?.turnOnOffSetting) {
      throw Error('Unable to update setting')
    }

    return { objectSettings: data?.turnOnOffSetting, error: null }
  } catch (err) {
    throw err
  }
}

import ExportData from '../ExportData'

export default function withEditableWidget(ViewComponent, EditComponent) {
  return function EditableWidget(props) {
    const { widgetMode } = props

    if (widgetMode === 'export') {
      return <ExportData {...props} />
    }

    return widgetMode === 'edit' ? (
      <EditComponent {...props} />
    ) : (
      <ViewComponent {...props} />
    )
  }
}

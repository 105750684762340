import { useEffect, useRef, useState } from 'react'
import { I18n } from 'aws-amplify'

import { Dialog, LineSeparator } from '@/elements'
import { Button, Code, FlexV2, Slot, Text } from '@/primitives'
import { getValidationErrorMap } from '@/Util/GeneralUtils'

import { SET_MODBUS_CONFIG, SET_SHOW_MODBUS_CONFIG_EDITOR_VIEW } from '../state'
import ConfigForm from './ConfigForm'
import { modbusSchema } from '../validate'

export default function ModbusConfigEditor({ state, stateDispatch }) {
  const initialConfig = useRef(null)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    // Save the intial config in case of cancel
    if (!initialConfig.current) {
      initialConfig.current = state.device.config
    }
  }, [state.device.config])

  async function onSaveChanges() {
    try {
      await modbusSchema.validate(state.device.config, { abortEarly: false })
      onShowDeviceMover(false)
    } catch (err) {
      setErrors(getValidationErrorMap(err))
    }
  }

  function onCancel() {
    stateDispatch({
      type: SET_MODBUS_CONFIG,
      payload: initialConfig.current
    })
    onShowDeviceMover(false)
  }

  function onShowDeviceMover(value) {
    stateDispatch({
      type: SET_SHOW_MODBUS_CONFIG_EDITOR_VIEW,
      payload: value
    })
  }

  return (
    <Dialog
      open={state.showModbusConfigEditorView}
      onOpenChange={onCancel}
      type='offcanvas'
      className='Devices__OffCanvas'
      style={{ zIndex: 3 }}
    >
      <Slot name='title'>
        <Text as={'h5'} size={300} fontWeight={700}>
          {I18n.get('Edit Modbus Configuration')}
        </Text>
        <Text as='p'>
          {I18n.get('Edit the Modbus configuration for this device.')}
        </Text>
      </Slot>
      <Slot name='content'>
        <FlexV2 direction='column'>
          <LineSeparator />
        </FlexV2>
        <FlexV2 direction='column' axisGap={500}>
          <ConfigForm
            state={state}
            stateDispatch={stateDispatch}
            errors={errors}
          />
          <Text variant='page' tone={900} size={300}>
            {I18n.get('Modbus Configuration JSON')}
          </Text>
          <Code language='json'>
            {JSON.stringify(state.device.config, null, 2)}
          </Code>
        </FlexV2>
      </Slot>
      <Slot name='actions'>
        <FlexV2 axisGap={400} alignMainAxis={'space-between'}>
          <Button onClick={onCancel}>{I18n.get('Cancel')}</Button>
          <Button
            variant='primary'
            onClick={onSaveChanges}
            style={{ width: '9rem' }}
          >
            {I18n.get('Save Changes')}
          </Button>
        </FlexV2>
      </Slot>
    </Dialog>
  )
}

import { useCallback, useEffect, useState, Children } from 'react'

import { Box, Icon, Flex } from '../../primitives'

import useDebounce from '@/hooks/useDebounce'

import './index.scss'

const DropdownItem = ({ key = null, props: { children, ...rest } }, index) => (
  <Box as='li' key={key || index} {...rest}>
    {children}
  </Box>
)

/**
 * Dropdown Element
 * @param {Object} props
 * @param {String | String[]} [props.className] Extra Class Name at top level
 * @param {import('react').CSSProperties} [props.css] Inline Ion Classes
 *
 * @param {Boolean} [props.open=false] Render Open or Closed by default
 * @param {'start' | 'center' | 'end'} [props.menuAlign='start'] Align dropdown menu relative to trigger
 * @param {import('react').Children} [props.children=null] The Items to display in the list
 *
 * @param {String} [props.triggerText=null] Text to display in the trigger area
 * @param {String} [props.triggerIcon='more horiz'] The icon to display in the trigger area
 * @param {100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900} [props.triggerSize=200] The size of the trigger content (Icon and Text)
 * @param {Function} [props.onOpenChange=null] The callback function when the open state changes
 * @param {Boolean} [props.cursorActivated=false] Should this Dropdown respond to mouse events? (Inclusive of clicks)
 *
 *  * @example
 * ```jsx
 * <Dropdown>
 *  <Item><Link>A link to a place</Link></Item>
 * </Dropdown>
 *
 * <Dropdown open={true}>
 *  <Item><Link>Open by default</Link></Item>
 * </Dropdown>
 *
 * <Dropdown triggerText={'Text'}>
 *  <Item><Link>Text and Icon</Link></Item>
 * </Dropdown>
 *
 * <Dropdown triggerIcon={'circle'}>
 *  <Item><Link>Change the Icon</Link></Item>
 * </Dropdown>
 *
 * <Dropdown triggerSize={300}>
 *  <Item><Link>Scale the Trigger Icon and Text</Link></Item>
 * </Dropdown>
 *
 * <Dropdown onOpenChange={(open)=>{console.log('is open', open)}}>
 *  <Item><Link>Open / Close Event Callback</Link></Item>
 * </Dropdown>
 *
 * <Dropdown cursorActivated={true}>
 *  <Item><Link>Make Dropdown open on mouse interaction</Link></Item>
 * </Dropdown>
 * ```
 */
const Dropdown = ({
  open = false,
  menuAlign = 'start',
  children = null,
  triggerText = null,
  triggerIcon = 'more horiz',
  triggerSize = 200,
  onOpenChange = null,
  className = null,
  cursorActivated = false,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(open)
  const debouncedOpen = useDebounce(isOpen, 100)

  useEffect(() => {
    if (onOpenChange) onOpenChange(debouncedOpen)
  }, [debouncedOpen, onOpenChange])

  const handleMouseCursor = useCallback(({ type }) => {
    if (type === 'mouseenter') {
      setIsOpen(true)
    }
    if (type === 'mouseleave') {
      setIsOpen(false)
    }
    return false
  }, [])

  const handleClick = event => {
    event.preventDefault()
    setIsOpen(!debouncedOpen)
    return false
  }

  return (
    <Box
      as={'details'}
      className={['Dropdown', `mod__menuAlign__${menuAlign}`, className]}
      open={debouncedOpen}
      onMouseEnter={cursorActivated ? handleMouseCursor : null}
      onMouseLeave={cursorActivated ? handleMouseCursor : null}
      onClick={handleClick}
      {...rest}
    >
      <Flex
        as={'summary'}
        className={['Dropdown__Trigger', `mod__size__${triggerSize}`]}
        alignCrossAxis={'center'}
        axisGap={200}
      >
        {triggerIcon && <Icon name={triggerIcon} />}
        {triggerText}
      </Flex>
      <Box as='ul' className={'Dropdown__List'}>
        {Children.toArray(children).map(DropdownItem)}
      </Box>
    </Box>
  )
}

export default Dropdown

import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Flex } from '@/primitives'
import { Select } from '../../../../elements'

import { useMobileDashboard } from '../context'
import { useDashboardParams } from '../../utils/useDashboardParams'

import { buildDashboardBreadcrumbs } from '@/components/DashboardPageV2/utils/index'
import Breadcrumb from './Breadcrumb'

import {
  getCurrentUserOrganizations,
  getDevice,
  getNavigationLocationSelector
} from '@/reducers/selectors'

import { getChildIds, getZoneLevelName } from '../../utils'

import Strings from '../Strings'

import './index.scss'

export default function Header() {
  const { state } = useMobileDashboard()
  const params = useDashboardParams()
  const history = useHistory()

  const locationSelector = getNavigationLocationSelector()

  const organizations = getCurrentUserOrganizations()
  const device = getDevice()
  const locationOrganization = locationSelector.get('organization')
  const [organization, setOrganization] = useState(null)

  const strings = Strings()

  useEffect(() => {
    if (locationOrganization?.id && organizations.length > 0) {
      const orgIndex = organizations.findIndex(
        o => o.id === locationOrganization?.id
      )
      if (orgIndex >= 0) {
        setOrganization(organizations[orgIndex])
      }
    }
  }, [locationOrganization?.id, organizations])

  let zone = state.currentZone || state.hierarchy
  const childIds = getChildIds(zone?.children)
  let zoneOptions = []
  const zoneHasChildren = childIds.length > 0
  const zoneHasDevices = zone?.devices?.length > 0

  const breadcrumbs = buildDashboardBreadcrumbs(
    organization,
    state.hierarchy,
    params,
    device
  )

  const lastBreadcrumb = breadcrumbs?.length - 1
  const isDeviceView = breadcrumbs[lastBreadcrumb].isDevice
  const { childZoneLevelName } = getZoneLevelName(zone)

  const onSelectZone = ({ value, isZone }) => {
    let path = '/zones'
    if (isZone) path = `/zones${zone.children[value].parentPath}`
    if (!isZone) {
      const deviceDetails = zone.devices.find(({ id }) => id === value)
      const { zonePath, sensorType } = deviceDetails
      path = `/zones${zonePath}/sensor/${sensorType}/${value}`
    }
    history.push(path)
  }

  if (zoneHasChildren || zoneHasDevices) {
    const zoneChildrenOptions = childIds.map(id => {
      const { name } = zone?.children[id]
      return {
        value: id,
        label: name,
        isZone: true
      }
    })

    const zoneDeviceOptions = zone?.devices.map(({ id, name }) => {
      return {
        value: id,
        label: name,
        isZone: false
      }
    })

    const childrenGroup = {
      label: childZoneLevelName,
      options: zoneChildrenOptions
    }

    const devicesGroup = {
      label: strings.sensorsText,
      options: zoneDeviceOptions
    }

    zoneOptions = [childrenGroup, devicesGroup]
  }

  const getPlaceholderText = () => {
    let baseString = `${strings.select} `
    if (zoneHasChildren) baseString += childZoneLevelName.toLowerCase()
    if (zoneHasChildren && zoneHasDevices) baseString += '/'
    if (zoneHasDevices) baseString += strings.sensor
    return baseString
  }

  return (
    <Flex className='MobileDashboardHeader' alignCrossAxis='center'>
      {breadcrumbs.map((breadcrumb, index) => (
        <Breadcrumb
          key={breadcrumb.id}
          breadcrumb={breadcrumb}
          isLastBreadcrumb={lastBreadcrumb === index}
          hasChildren={(zoneHasChildren || zoneHasDevices) && !isDeviceView}
        />
      ))}
      {(zoneHasChildren || zoneHasDevices) && !isDeviceView && (
        <Select
          className='MobileDashboardHeader__Select'
          controlStyles={{
            minHeight: '1.6rem',
            height: '1.6rem',
            padding: '0'
          }}
          name='subzones'
          value={null}
          onChange={onSelectZone}
          options={zoneOptions}
          placeholder={getPlaceholderText()}
          classNamePrefix='MobileDashboardHeader__Select'
        />
      )}
    </Flex>
  )
}

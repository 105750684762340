import { createContext, useMemo, useReducer } from 'react'

export const AccordionContext = createContext(null)

const clone = data => JSON.parse(JSON.stringify(data))

const actions = {}

actions.SET_MULTIFOCUS = state => ({ ...state, multifocus: true })

actions.CREATE_ITEM = (state, action) => ({ ...state, ...action.value })

actions.UPDATE_ITEM = (state, action) => {
  if (!state.multifocus) {
    Object.keys(state)
      .filter(key => key !== 'multifocus')
      .filter(key => key !== action.value)
      .forEach(key => {
        state[key] = false
      })
  }

  return {
    ...state,
    [action.value]: !(state[action.value] ?? false)
  }
}

actions.RESET = () => defaultState

const stateReducer = (state, action) =>
  clone(actions[action.type] ? actions[action.type](state, action) : state)

const defaultState = {
  multifocus: false
}

export const AccordionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(stateReducer, defaultState)
  // make it a little more performant
  const cachedContextValue = useMemo(
    () => ({ state, dispatch }),
    [state, dispatch]
  )
  return (
    <AccordionContext.Provider value={cachedContextValue}>
      {children}
    </AccordionContext.Provider>
  )
}

import { createSlice } from '@reduxjs/toolkit'

const managementDeviceSupplierSlice = createSlice({
  name: 'managementDeviceSupplier',
  initialState: {
    supplierDevice: {},
    error: null,
    loading: false
  }
})

export default managementDeviceSupplierSlice.reducer

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { getFertilizers } from '@/api/fertigation/fertilizer'

export const fetchAllFertilizers = createAsyncThunk(
  'fetchAllFertilizers',
  async params => {
    const { data, error } = await getFertilizers(params)
    return { data, error }
  }
)

const fertilizerSlice = createSlice({
  name: 'fertilizerReducer',
  initialState: {
    allFertilizers: [],
    loading: false,
    error: null
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAllFertilizers.pending, state => {
        state.error = null
        state.loading = true
      })
      .addCase(fetchAllFertilizers.fulfilled, (state, action) => {
        state.allFertilizers = action?.payload?.data?.fertilizers ?? []
        state.error = action?.payload?.error ?? null
        state.loading = false
      })
  }
})

export default fertilizerSlice.reducer

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  cleanNextHourPrediction,
  fetchNextHourPrediction
} from '@/slices/ml/prediction'
import { getNextHourPrediction } from '@/reducers/selectors'

import { useDashboard } from '../../../context'

const VALID_FORECAST_MEASUREMENTS = [
  'temperature',
  'humidity',
  'absolute_humidity',
  'pressure',
  'par'
]

export function usePredictionData(measurementId) {
  const dispatch = useDispatch()
  const { state } = useDashboard()
  const nextHourPrediction = getNextHourPrediction(measurementId)

  const { currentZone, siteId } = state

  const isRoom = currentZone.level === 3
  const zoneId = currentZone.id

  useEffect(() => {
    if (isRoom && VALID_FORECAST_MEASUREMENTS.includes(measurementId)) {
      dispatch(cleanNextHourPrediction(measurementId))
      const params = {
        siteId: state.siteId,
        zoneId,
        measurementId,
        valueType: 'avg'
      }

      dispatch(fetchNextHourPrediction(params))
    }
  }, [zoneId, measurementId, isRoom, dispatch, state.siteId])

  let prediction = null

  if (
    nextHourPrediction?.siteId === siteId &&
    nextHourPrediction?.zoneId === zoneId &&
    nextHourPrediction?.measurementId === measurementId &&
    state?.currentDashboard?.context !== 'sensor'
  ) {
    prediction =
      nextHourPrediction?.predictions[
        nextHourPrediction?.predictions.length - 1
      ]
  }

  return prediction
}

import { cloneDeep } from 'lodash'
import { STATUSMAP } from './constants'
import { DateTime } from 'luxon'

/**
 * Sorts an array of `alertsListItem` objects based on their status level.
 *
 * @param {object} alertsListItemA - The first `alertsListItem` object to compare.
 * @param {object} alertsListItemB - The second `alertsListItem` object to compare.
 * @return {number} The difference in status level between the two `alertsListItem` objects.
 */
export function byStatusLevel(alertsListItemA, alertsListItemB) {
  if (
    !STATUSMAP[alertsListItemA.viewData.status] ||
    !STATUSMAP[alertsListItemB.viewData.status]
  ) {
    return 0
  }
  return (
    STATUSMAP[alertsListItemB.viewData.status].value -
    STATUSMAP[alertsListItemA.viewData.status].value
  )
}

/**
 * Check if the `referenceString` includes the `searchString` case-insensitively.
 *
 * @param {string} referenceString - The string to search in.
 * @param {string} searchString - The string to search for.
 * @returns {boolean} `true` if `searchString` is included in `referenceString`
 * case-insensitively, `false` otherwise.
 *
 * Note: Both `referenceString` and `searchString` should be non-null
 * and non-undefined.
 */
export function caseInsensitiveIncludes(referenceString, searchString) {
  if (referenceString === undefined || searchString === undefined) return false
  return referenceString.toLowerCase().includes(searchString.toLowerCase())
}

/**
 * Returns the color variant for a given set of view data.
 *
 * @param {object} viewData - The view data object containing status, notInUse, and missingData properties.
 * @param {string} viewData.status - The status of the item.
 * @param {boolean} viewData.notInUse - Whether the item is not in use.
 * @param {boolean} viewData.missingData - Whether data for the item is missing.
 * @return {string} The color variant for the given view data.
 */
export function getColorVariantFromViewData({ status, notInUse, missingData }) {
  if (notInUse) {
    return STATUSMAP.notInUse.variant
  }

  if (missingData && status !== 'active') {
    return STATUSMAP[status].variant
  }

  if (missingData && status === 'active') {
    return STATUSMAP.missingData.variant
  }

  return (STATUSMAP[status] || STATUSMAP.loading).variant
}

/**
 * Returns the highest status level in a given array of statuses.
 *
 * @param {Array<{viewData: {status: string}}>} statuses - An array of objects containing a status property.
 * @return {string} The highest status level found in the given array of statuses.
 */
function getHighestStatusLevel(statuses) {
  if (!statuses?.length) return 'active'

  const statusValue = statuses
    .map(({ viewData: { status } }) => STATUSMAP[status].value)
    .sort()
    .pop()

  const entryFromStatusMap = Object.entries(STATUSMAP).find(
    status => status[1].value === statusValue
  )

  return entryFromStatusMap?.length ? entryFromStatusMap[0] : 'active'
}

/**
 * Returns the nested zone object corresponding to the given path array.
 *
 * @param {object} zonesHierarchy - An object representing a hierarchical structure of zones.
 * @param {Array<string>} zonePathArray - An array of strings representing the path to the desired nested zone.
 * @return {object|null} The nested zone object corresponding to the given path array, or null if the path array is empty or the zone does not exist.
 */
function selectNestedZonesHierarchy(zonesHierarchy, zonePathArray) {
  if (zonePathArray.length) {
    return selectNestedZonesHierarchy(
      zonesHierarchy[zonePathArray[0]] ||
        zonesHierarchy.children[zonePathArray[0]],
      zonePathArray.slice(1)
    )
  }
  return zonesHierarchy
}

/**
 * Composes a view hierarchy from a next view hierarchy, statuses, and site offline devices.
 *
 * @param {object} options - An object containing the next view hierarchy, statuses, and site offline devices.
 * @param {object} options.nextViewHierarchy - An object representing the next view hierarchy.
 * @param {Array<object>} options.statuses - An array of status objects, each containing a sensorId, status, and timestamp.
 * @param {Array<object>} options.siteOfflineDevices - An array of offline device objects, each containing a deviceId and status.
 * @return {object} The composed view hierarchy.
 */
function composeViewHierarchy({
  nextViewHierarchy,
  statuses,
  siteOfflineDevices
}) {
  const hasChildren = Object.keys(nextViewHierarchy.children)
  const hasDevices = nextViewHierarchy.devices.length > 0

  const viewData = {
    status: 'active'
  }

  if (hasChildren) {
    nextViewHierarchy.children = Object.fromEntries(
      Object.entries(nextViewHierarchy.children).map(([k, v]) => [
        k,
        composeViewHierarchy({
          nextViewHierarchy: v,
          statuses,
          siteOfflineDevices
        })
      ])
    )

    nextViewHierarchy.viewData = nextViewHierarchy.viewData || viewData
  }

  if (hasDevices) {
    nextViewHierarchy.devices = nextViewHierarchy.devices.map(device => {
      const deviceViewData = {
        ...viewData,
        missingData: false,
        notInUse: false,
        sensorDashboardURL: null
      }

      const thresholdSensor = statuses.find(
        ({ sensorId }) => sensorId === device.id
      )

      if (thresholdSensor) {
        deviceViewData.status = thresholdSensor.status?.level || 'active'
        deviceViewData.thresholdTrigger = thresholdSensor.status?.boundType
        deviceViewData.thresholdValue =
          thresholdSensor.status?.thresholdValue.toPrecision(4)
        deviceViewData.lastValue = thresholdSensor.value.toPrecision(4)

        deviceViewData.lastSending = DateTime.fromMillis(
          thresholdSensor.sinceWhen
        ).toLocaleString(DateTime.DATETIME_FULL)

        deviceViewData.lastChecked = DateTime.fromMillis(
          thresholdSensor.lastChecked
        ).toLocaleString(DateTime.DATETIME_FULL)
      }

      const offlineDevices = siteOfflineDevices.filter(
        ({ deviceId }) => deviceId === device.id
      )

      if (offlineDevices.length) {
        offlineDevices.forEach(offlineDevice => {
          deviceViewData[offlineDevice.status] = true
          deviceViewData['reasons'] = offlineDevice.reasons
        })
      }

      deviceViewData.sensorDashboardURL =
        '/' +
        [
          'zones',
          ...device.zonePath.split('/').filter(Boolean),
          'sensor',
          device.sensorType,
          device.id
        ].join('/')

      return {
        ...device,
        viewData: deviceViewData
      }
    })

    nextViewHierarchy.viewData = nextViewHierarchy.viewData || viewData
    nextViewHierarchy.viewData.status = getHighestStatusLevel(
      nextViewHierarchy.devices
    )
  }

  return nextViewHierarchy
}

/**
 * Extracts an array of zone path components from a given payload zone string.
 *
 * @param {string} payloadZone - The payload zone string, in the format of "/zones/zone+/...".
 * @returns {Array<string>} - An array of zone path components, with empty or falsy elements removed.
 */

function extractZonePathArrayFromPayloadZone(payloadZone = ''){
  if(!payloadZone?.length){ return [] }

  const nextPayloadZoneString = payloadZone.replace(/\/sensor.*$/, '')
  const nextPayloadZoneArray = nextPayloadZoneString.split('/').filter(Boolean)
  return nextPayloadZoneArray
}

/**
 * Derives a view hierarchy from a payload containing a zones hierarchy and a zone path.
 *
 * @param {object} payload - An object containing a zones hierarchy and a zone path.
 * @param {object} payload.zonesHierarchy - An object representing a hierarchical structure of zones.
 * @param {string} payload.zone - A string representing the path to a specific zone, with each level separated by a forward slash ("/").
 * @param {Array<object>} payload.statuses - An array of status objects, each containing a sensorId, status, and timestamp.
 * @param {Array<object>} payload.siteOfflineDevices - An array of offline device objects, each containing a deviceId and status.
 * @return {object} The derived view hierarchy.
 */
function deriveViewHierarchyFromZonesHierarchy(payload) {
  const hasZonesHierarchy = Object.keys(payload.zonesHierarchy || {}).length > 0
  const hasZone = (payload.zone || '').length > 0

  if (!hasZonesHierarchy && !hasZone) return {}

  const zonePathArray = extractZonePathArrayFromPayloadZone(payload.zone)

  if(!payload.zonesHierarchy[zonePathArray[0]]) return {}

  const nestedZonesHierarchy = selectNestedZonesHierarchy(
    payload.zonesHierarchy,
    zonePathArray
  )

  const nextViewHierarchy = cloneDeep(nestedZonesHierarchy)

  const { statuses, siteOfflineDevices } = payload
  return composeViewHierarchy({
    nextViewHierarchy,
    statuses,
    siteOfflineDevices
  })
}

/**
 * Reduces an array of alerts by recursively processing groups of devices and aggregating the alerts.
 *
 * @param {Array<object>} alertsList - An array of alerts, each containing an array of device view data.
 * @param {object} group - An object representing a group of devices, with children and devices properties.
 * @return {Array<object>} The reduced array of alerts.
 */
function alertsReducer(alertsList, group) {
  const groupChildren = Object.values(group.children)

  if (groupChildren.length) {
    return groupChildren.reduce(alertsReducer, alertsList)
  }

  if (group.devices?.length) {
    alertsList.push(
      group.devices.filter(device => device.viewData.status !== 'active')
    )
  }

  return alertsList.flat()
}

/**
 * Extracts an array of alerts from a view hierarchy.
 *
 * @param {object} viewHierarchy - An object representing a view hierarchy.
 * @return {Array<object>} An array of alerts, each containing an array of device view data.
 */
function extractAlertsListFromViewHierarchy(viewHierarchy) {
  return [viewHierarchy].reduce(alertsReducer, [])
}

export const initialState = {
  viewHierarchy: {},
  searchValue: '',
  alertsList: [],
  selectedDeviceType: null,
  selectedStatus: null,
  header: {
    alertCount: 0,
    status: 'loading'
  }
}

/**
 * Reducer function for managing application state.
 *
 * @param {object} state - The current state object.
 * @param {object} action - An object containing a type and payload.
 * @param {string} action.type - The type of action to perform.
 * @param {object} action.payload - The data to use for the action.
 * @return {object} The updated state object.
 */
export function stateReducer(state, { type, payload }) {
  switch (type) {
    case 'CLEAR_SEARCH_VALUE':
      return {
        ...state,
        searchValue: ''
      }
    case 'SET_SEARCH_VALUE':
      return {
        ...state,
        searchValue: payload
      }
    case 'SET_HEADER':
      const alertCount = payload.length
      const status = getHighestStatusLevel(payload)
      return {
        ...state,
        header: {
          ...state.header,
          alertCount,
          status
        }
      }
    case 'SET_ALERTS_LIST':
      const alertsList = extractAlertsListFromViewHierarchy(payload)
      return {
        ...state,
        alertsList
      }
    case 'SET_VIEW_HIERARCHY':
      const viewHierarchy = deriveViewHierarchyFromZonesHierarchy(payload)
      return {
        ...state,
        viewHierarchy
      }
    case 'UPDATE_SELECTED_DEVICE_TYPE':
      return {
        ...state,
        selectedDeviceType: payload
      }
    case 'UPDATE_SELECTED_STATUS':
      return {
        ...state,
        selectedStatus: payload
      }
    default:
      return state
  }
}

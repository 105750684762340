import useDatasetData from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Hooks/useDatasetData'

import Line from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/Chart/Line'

export default function DatasetLine({ options, config, unit, ...props }) {
  const { datasetData, queryZones } = useDatasetData({
    measurements: [options.measurement],
    range: config.range,
    interval: config.interval
  })

  const data = datasetData?.[options?.measurement] ?? {}

  return (
    <Line
      {...props}
      data={data}
      unit={unit}
      config={config}
      queryZones={queryZones}
    />
  )
}

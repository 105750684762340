import { useState } from 'react'
import useIsRtl from 'use-is-rtl'
import { Button } from '../../../primitives'
import Strings from '../Strings'

const iconDictionary = {
  rtl: {
    first: 'last page',
    prev: 'chevron right',
    next: 'chevron left',
    last: 'first page'
  },
  ltr: {
    first: 'first page',
    prev: 'chevron left',
    next: 'chevron right',
    last: 'last page'
  }
}

const Arrow = ({ type = 'first', disabled = null }) => {
  const isRTL = useIsRtl()
  const strings = Strings()

  const [ariaLabel] = useState(
    `${strings.goto} ${strings[type]} ${strings.page}`
  )

  return (
    <Button
      iconBefore={iconDictionary[isRTL ? 'rtl' : 'ltr'][type] || 'first page'}
      className={'Arrow'}
      disabled={disabled}
      aria-label={ariaLabel}
      title={ariaLabel}
      tabIndex={0}
      data-action={type}
    />
  )
}

export default Arrow

import { useParams } from 'react-router-dom'
import { useDashboard } from '../../../context'

export default function useThresholdStatus(measurement) {
  const params = useParams()
  const { state } = useDashboard()

  const measurementStatus = state.dashboardStatuses?.[measurement] ?? null
  let thresholdStatus = null

  const currentLocation = `/${params.zone}`

  if (measurementStatus) {
    for (let thresholdPath in measurementStatus) {
      if (currentLocation.includes(thresholdPath)) {
        thresholdStatus = measurementStatus[thresholdPath]
      }
    }
  }

  return thresholdStatus
}

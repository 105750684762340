import { I18n } from 'aws-amplify'
import { object, string, date } from 'yup'

export const FIELDS = {
  id: 'id',
  organizationId: 'organizationId',
  siteId: 'siteId',
  zoneId: 'zoneId',
  harvestId: 'harvestId',
  produceId: 'produceId',
  varietyId: 'varietyId',
  packageDate: 'packageDate',
  customerId: 'customerId'
}

export const SCHEMA = () => {
  return object().shape({
    organizationId: string().required(
      I18n.get('An organization must be selected.')
    ),
    zoneId: string().required(I18n.get('A location must be selected.')),
    produceId: string().required(I18n.get('Produce is required.')),
    varietyId: string().required(I18n.get('Variety is required.')),
    harvestId: string().required(I18n.get('Harvest is required.')),
    packageDate: date()
      .typeError('A package date is required.')
      .max(
        new Date(),
        I18n.get('The package date cannot be set in the future.')
      )
      .required(I18n.get('A package date is required.')),
    customerId: string().required(I18n.get('Customer is required.'))
  })
}

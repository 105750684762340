import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { requestPackageUnitInventory } from '@/actions/operations/packageUnitInventory'

import {
  clearPackageUnitInventoryCalibrations,
  requestPackageUnitInventoryCalibrations
} from '@/actions/operations/packageUnitInventoryCalibration'

import {
  getCurrentUser,
  getOperationsPackageUnitInventory,
  getOperationsPackageUnitInventoryCalibrations,
  getOperationsPackageUnitInventoryCalibrationsCount,
  getOperationsLoading
} from '@/reducers/selectors'

import { Flex, Box, Table, Slot, Column, Row, Text, Loader } from '@/primitives'

import { Pagination } from '@/elements'

import HeaderV2 from '@/components/Operations/Shared/HeaderV2'
import TableRow from './TableRow'

import history from '../../../../../history'

import {
  hasEditPermissions,
  RESOURCE_TYPE_OPERATIONS,
  RESOURCE_TYPE_OPERATIONS_INVENTORY
} from '@/Util/PermissionUtils'

import Strings from '../Strings'

function PackageUnitInventoryCalibrationTable({
  state,
  dispatchState,
  modulePath,
  backPath,
  packageUnitInventoryId
}) {
  const dispatch = useDispatch()

  const currentUser = getCurrentUser()
  const packageUnitInventory = getOperationsPackageUnitInventory()
  const items = getOperationsPackageUnitInventoryCalibrations()
  const totalItems = getOperationsPackageUnitInventoryCalibrationsCount()
  const loading = getOperationsLoading()

  const strings = Strings()

  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(9)
  const [tableState, setTableState] = useState(state)
  const [canEdit, setCanEdit] = useState(false)

  useEffect(() => {
    return () => {
      dispatch(clearPackageUnitInventoryCalibrations())
    }
  }, [dispatch])

  useEffect(() => {
    if (currentUser.userName) {
      setCanEdit(
        hasEditPermissions(
          currentUser,
          RESOURCE_TYPE_OPERATIONS,
          RESOURCE_TYPE_OPERATIONS_INVENTORY
        )
      )
    }
  }, [currentUser])

  useEffect(() => {
    if (!packageUnitInventoryId) {
      history.push('/operations/inventory/packaging')
    } else {
      dispatch(
        requestPackageUnitInventory({
          packageUnitInventoryId
        })
      )
    }
  }, [packageUnitInventoryId])

  useEffect(() => {
    if (tableState.page !== state.page || items.length === 0) {
      setTableState({ ...state })
      fetchPackageUnitInventoryCalibrations(state.page)
    }
  }, [state.page])

  const fetchPackageUnitInventoryCalibrations = page => {
    dispatch(
      requestPackageUnitInventoryCalibrations({
        filter: {
          packageUnitInventoryId: [packageUnitInventoryId]
        },
        limit: pageSize,
        offset: page - 1
      })
    )
  }

  const getHeader = () => {
    if (packageUnitInventory.id) {
      return (
        <Box>
          {strings.managePackageUnitInventoryCalibrations}
          <span className='Operations__Header__Subheader'>
            {packageUnitInventory.storageFacility.name}&nbsp;-&nbsp;
            {packageUnitInventory.packageUnit.name} (
            {packageUnitInventory.packageUnit.weight}
            {strings.grams})
          </span>
        </Box>
      )
    }
    return `${strings.managePackageUnitInventoryCalibrations}`
  }

  const changePage = newPage => {
    dispatchState({
      page: newPage
    })
  }

  const onCreate = () => {
    history.push(`${modulePath}/create`)
  }

  return (
    <Flex axisGap={400} direction='column'>
      <HeaderV2
        title={getHeader()}
        buttonIcon={'add'}
        buttonText={strings.createPackageUnitInventoryCalibration}
        buttonCallback={onCreate}
        showButton={canEdit}
        backPath={backPath}
      />
      <Loader isLoading={loading}>
        {totalItems === 0 && (
          <Flex className={'Operations__Table__Empty'}>
            <Text>{strings.noPackageUnitInventoryCalibrations}</Text>
          </Flex>
        )}
        {totalItems > 0 && (
          <Table className='Operations__Table' aria-colcount='6'>
            <Slot name='head'>
              <Row>
                <Column>{strings.date}</Column>
                <Column>{strings.oldQuantity}</Column>
                <Column>{strings.newQuantity}</Column>
                <Column>{strings.reason}</Column>
              </Row>
            </Slot>
            <Slot name='body'>
              {items?.map(item => (
                <TableRow key={item.id} item={item} />
              ))}
            </Slot>
          </Table>
        )}
        {totalItems > pageSize && (
          <Box className='pagination-menu'>
            <Pagination
              type='simple'
              totalItems={totalItems}
              totalPages={totalPages}
              setTotalPages={setTotalPages}
              page={tableState.page}
              setPage={changePage}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          </Box>
        )}
      </Loader>
    </Flex>
  )
}

export default PackageUnitInventoryCalibrationTable

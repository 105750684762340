import { I18n } from 'aws-amplify'
import { useState } from 'react'

import Strings from '@/components/AdminPage/Strings'
import Select from '@/elements/Select'
import {
  Button,
  Cell,
  Column,
  Input,
  Row,
  Text,
  Slot,
  Table,
  FlexV2,
  Toggle,
  Symbol
} from '@/primitives'
import { getIsLoading } from '@/reducers/selectors'

import {
  getDeviceTypeMeasurementOptions,
  sanitizePositiveIntegerInput
} from '../utils'
import {
  TOGGLE_MODBUS_CONFIG_MULTI_READ,
  UPDATE_MODBUS_CONFIG_FIELD,
  UPDATE_MODBUS_CONFIG_MEASUREMENTS
} from '../state'

const READ_REGISTER_COUNT_OPTIONS = [
  { label: '2', value: 2 },
  { label: '4', value: 4 }
]

const REGISTER_RANGE = [0, 9999]

export default function MeasurementRegisterForm({ state, stateDispatch }) {
  const strings = Strings()
  const isLoading = getIsLoading()

  const isMulti = state?.device?.config?.read_register_count?.length > 0

  const deviceMeasurementOptions = getDeviceTypeMeasurementOptions(
    state.deviceTypeMeasurementsMap,
    state.device.config.read_measurementType
  )

  const [measurementTypeToAdd, setMeasurementTypeToAdd] = useState(null)

  const onSelectMeasurementType = selected => {
    setMeasurementTypeToAdd(selected.value)
  }

  const onSelectRegisterCount = (selected, meta) => {
    const index = Number(meta.name)
    let read_register_count = [...state.device.config.read_register_count]
    read_register_count[index] = Number(selected.value)
    stateDispatch({
      type: UPDATE_MODBUS_CONFIG_FIELD,
      payload: {
        field: 'read_register_count',
        value: read_register_count
      }
    })
  }

  const onChangeRegister = e => {
    const index = Number(e.target.name)
    let read_registers = [...state.device.config.read_registers]

    const value = sanitizePositiveIntegerInput(e.target.value)
    if (value == null) {
      return
    }
    if (value < REGISTER_RANGE[0] || value > REGISTER_RANGE[1]) {
      return null
    }

    read_registers[index] = value

    stateDispatch({
      type: UPDATE_MODBUS_CONFIG_FIELD,
      payload: {
        field: 'read_registers',
        value: read_registers
      }
    })
  }

  const onClickRemoveMeasurement = e => {
    const index = Number(e.target.name)

    // Don't allow the last measurement to be removed
    if (state.device.config.read_measurementType.length === 1) {
      return
    }

    stateDispatch({
      type: UPDATE_MODBUS_CONFIG_MEASUREMENTS,
      payload: {
        userAction: 'delete',
        index
      }
    })
  }

  const onClickAddMeasurement = () => {
    stateDispatch({
      type: UPDATE_MODBUS_CONFIG_MEASUREMENTS,
      payload: { userAction: 'add', measurementType: measurementTypeToAdd }
    })
  }

  const onToggleMultiRead = () => {
    stateDispatch({ type: TOGGLE_MODBUS_CONFIG_MULTI_READ })
  }
  const selectedMeasurements = state.device.config.read_measurementType

  return (
    <FlexV2 direction='column' axisGap={500} style={{ marginTop: '1.5em' }}>
      <FlexV2
        direction='row'
        alignMainAxis='space-between'
        alignCrossAxis='center'
        axisGap={200}
      >
        <FlexV2 direction='column' axisGap={200}>
          <Text variant='page' tone={900} size={300}>
            {strings.measurements}
          </Text>
          <Text variant='page' tone={900} size={100}>
            {I18n.get('Select the measurements to read from the device.')}
          </Text>
        </FlexV2>
      </FlexV2>
      <FlexV2 alignMainAxis='space-between'>
        <Text>{strings.multiReadRegisters}</Text>
        <Toggle
          checked={isMulti}
          onChange={onToggleMultiRead}
          indeterminate={false}
        />
      </FlexV2>

      <FlexV2 alignMainAxis='space-between'>
        <Select
          options={deviceMeasurementOptions}
          value={measurementTypeToAdd}
          onChange={onSelectMeasurementType}
          placeholder={I18n.get('Select a measurement')}
        />
        <Button
          variant='primary'
          size='small'
          iconBefore='add'
          disabled={
            selectedMeasurements.length ===
            Object.keys(state.deviceTypeMeasurementsMap).length
          }
          onClick={onClickAddMeasurement}
        >
          {strings.addMeasurement}
        </Button>
      </FlexV2>

      <Table style={{ marginBottom: '0' }}>
        <Slot name='head'>
          <Row>
            <Column>{strings.tableTypeColumn}</Column>
            <Column style={{ width: '100px', textAlign: 'center' }}>
              {strings.tableUnitColumn}
            </Column>
            <Column>{strings.tableRegisterColumn}</Column>
            {isMulti && (
              <Column style={{ width: '120px' }}>
                {strings.tableRegisterCountColumn}
              </Column>
            )}
            <Column>{strings.tableActionColumn}</Column>
          </Row>
        </Slot>

        <Slot name='body'>
          {selectedMeasurements?.map((measurementId, index) => {
            const { unit, label } =
              state.deviceTypeMeasurementsMap[measurementId]

            return (
              <Row key={`${measurementId}-${index}`}>
                <Cell columnName={`${strings.tableTypeColumn}: `}>
                  <Text size='200'>{label}</Text>
                </Cell>
                <Cell
                  columnName={`${strings.tableUnitColumn}: `}
                  style={{ textAlign: 'center' }}
                >
                  <Text size='200'>{unit}</Text>
                </Cell>
                <Cell columnName={`${strings.tableRegisterColumn}: `}>
                  <Input
                    style={{ width: '80px', textAlign: 'center' }}
                    name={index}
                    value={String(state.device.config.read_registers[index])}
                    onChange={onChangeRegister}
                    disabled={isLoading}
                    placeholder={`${REGISTER_RANGE[0]} - ${REGISTER_RANGE[1]}`}
                  />
                </Cell>

                {isMulti && (
                  <Cell columnName={`${strings.tableRegisterCountColumn}: `}>
                    <Select
                      value={state.device.config?.read_register_count?.[index]}
                      options={READ_REGISTER_COUNT_OPTIONS}
                      name={index}
                      onChange={onSelectRegisterCount}
                    />
                  </Cell>
                )}

                <Cell>
                  <Button
                    name={index}
                    onClick={onClickRemoveMeasurement}
                    size='small'
                    iconBefore='remove'
                    variant='error'
                  />
                </Cell>
              </Row>
            )
          })}
        </Slot>
      </Table>
      <FlexV2
        axisGap={300}
        alignCrossAxis='center'
        style={{ paddingTop: '0.5em', marginBottom: '1em' }}
      >
        <Symbol
          name='info'
          variant='page'
          tone={900}
          style={{ overflow: 'unset' }}
        />
        <Text variant='page' tone={900}>
          {I18n.get('The register value must be between 0 and 247.')}
        </Text>
      </FlexV2>
    </FlexV2>
  )
}

import { call, put, select, takeLatest } from 'redux-saga/effects'

import {
  createVariety,
  getVarieties,
  getVariety,
  updateVariety
} from '@/api/operations/variety'

import {
  REQUEST_ALL_VARIETIES,
  REQUEST_VARIETIES,
  REQUEST_VARIETY,
  CREATE_VARIETY,
  UPDATE_VARIETY,
  receiveAllVarieties,
  receieveVarieties,
  receiveVariety,
  varietyCreated,
  varietyUpdated
} from '@/actions/operations/variety'

import { getCurrentUserOrganizations, displayBanner } from '../utils'
import { getOperationsAllVarieties } from './utils'

import Strings from './Strings'

const strings = Strings()

function* sendCreateVariety(action) {
  const { data, error = null } = yield call(createVariety, action.params)
  yield put(displayBanner(error, strings.varietyCreated))
  yield put(varietyCreated(data, error))
}

export function* watchCreateVariety() {
  yield takeLatest(CREATE_VARIETY, sendCreateVariety)
}

function* sendUpdateVariety(action) {
  const { data, error = null } = yield call(updateVariety, action.params)
  yield put(displayBanner(error, strings.varietyUpdated))
  yield put(varietyUpdated(data, error))
}

export function* watchUpdateVariety() {
  yield takeLatest(UPDATE_VARIETY, sendUpdateVariety)
}

function* sendRequestVariety(action) {
  const { data: variety, error = null } = yield call(getVariety, action.params)
  yield put(receiveVariety(variety, error))
}

export function* watchRequestVariety() {
  yield takeLatest(REQUEST_VARIETY, sendRequestVariety)
}

function* sendRequestVarieties(action) {
  const { data, error = null } = yield call(getVarieties, action.params)
  const { varieties = [], count = 0 } = data
  yield put(receieveVarieties(varieties, count, error))
}

export function* watchRequestVarieties() {
  yield takeLatest(REQUEST_VARIETIES, sendRequestVarieties)
}

function* sendRequestAllVarieties(action) {
  const allVarieties = yield select(getOperationsAllVarieties)
  if (allVarieties.length > 0) {
    yield put(receiveAllVarieties(allVarieties, allVarieties.length, null))
  } else {
    const organizations = yield select(getCurrentUserOrganizations)
    const params = {
      filter: {
        organizationId: organizations.map(o => o.id)
      }
    }
    const { data, error = null } = yield call(getVarieties, params)
    const { varieties = [], count = 0 } = data
    yield put(receiveAllVarieties(varieties, count, error))
  }
}

export function* watchRequestAllVarieties() {
  yield takeLatest(REQUEST_ALL_VARIETIES, sendRequestAllVarieties)
}

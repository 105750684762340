import { Link } from 'react-router-dom'

import Strings from './Strings'

import {
  FlexV2,
  Table,
  Slot,
  Column,
  Row,
  Text,
  Loader,
  Cell,
  Button
} from '@/primitives'

import {
  getFactorySuppliers,
  getFactorySuppliersLoading
} from '@/reducers/selectors'

const SupplierTable = ({ onClickUsers }) => {
  const strings = Strings()

  const suppliers = getFactorySuppliers()
  const isLoading = getFactorySuppliersLoading()

  return (
    <FlexV2 axisGap={400} direction='column' style={{ flexGrow: 1 }}>
      <Loader isLoading={isLoading}>
        {suppliers?.length === 0 && !isLoading && (
          <FlexV2>
            <Text>{strings.noSuppliers}</Text>
          </FlexV2>
        )}
        {suppliers.length > 0 && (
          <Table aria-colcount='9'>
            <Slot name='head'>
              <Row>
                <Column>{strings.tableRowId}</Column>
                <Column>{strings.tableRowName}</Column>
                <Column>{strings.tableRowActions}</Column>
              </Row>
            </Slot>
            <Slot name='body'>
              {suppliers?.map(({ supplierId, name }) => (
                <Row key={supplierId}>
                  <Cell columnName={strings.tableRowId}>
                    <Text variant='page' tone={1000}>
                      {supplierId}
                    </Text>
                  </Cell>
                  <Cell columnName={strings.tableRowName}>
                    <Text variant='page' tone={1000}>
                      {name}
                    </Text>
                  </Cell>
                  <Cell columnName={strings.tableRowActions}>
                    <Button
                      size='small'
                      as={Link}
                      variant='primary'
                      to={{
                        pathname: `/admin/suppliers/${supplierId}/users`
                      }}
                      onClick={onClickUsers}
                    >
                      {strings.seeUsers}
                    </Button>
                  </Cell>
                </Row>
              ))}
            </Slot>
          </Table>
        )}
      </Loader>
    </FlexV2>
  )
}

export default SupplierTable

import { DateTime } from 'luxon'

import { Box, Text } from '@/primitives'
import ZoneUtils from '@/Util/ZoneUtils'
import { getZonesHierarchy } from '@/reducers/selectors'

import { useDataboard } from '../context'
import {
  COMPARISON_TYPES,
  DATE_RANGE_ID_FORMAT,
  INTERVAL_STEPS_CHART_LABEL
} from '../Utils/config'
import { getDateRangeFromId } from '../Utils/DataUtils'

export const formatDateLabel = (date, timezone) => {
  return DateTime.fromMillis(date.getTime(), { zone: timezone }).toFormat(
    DATE_RANGE_ID_FORMAT
  )
}

export const getSeriesLabel = (zoneId, zoneFilters, zonesHierarchy) => {
  for (let zoneFilter of zoneFilters) {
    if (zoneFilter.zoneId === zoneId) {
      const siteName = ZoneUtils.getZoneName(zonesHierarchy, zoneFilter.siteId)

      if (zoneFilter.siteId === zoneId) {
        return siteName
      }

      const zoneName = ZoneUtils.getZoneName(
        zonesHierarchy,
        zoneFilter.siteId,
        zoneId
      )
      return `${siteName} - ${zoneName}`
    }
  }
  return zoneId
}

const getXLabel = (x, state, timezone) => {
  if (state.comparisonType === COMPARISON_TYPES.DATE_RANGES) {
    const labels = INTERVAL_STEPS_CHART_LABEL()
    const selectedInterval = state.dateFilters[0]?.timeInterval
    return `${labels[selectedInterval]} ${x}`
  }

  return formatDateLabel(x, timezone)
}

export default function SliceTooltip({
  slice,
  measurementUnit,
  timezone,
  interval,
  comparisonType
}) {
  const { state } = useDataboard()
  const zonesHierarchy = getZonesHierarchy()
  const isDateRange = comparisonType === COMPARISON_TYPES.DATE_RANGES

  return (
    <Box
      style={{
        background: '#192133',
        padding: '9px 12px',
        border: '1px solid #394970'
      }}
    >
      {slice.points.map(point => {
        let pointDate = null
        if (isDateRange) {
          const { fromDate } = getDateRangeFromId(point.id)

          pointDate = DateTime.fromISO(fromDate).plus({
            [interval]: point.data.x - 1
          })
        }

        return (
          <Box
            key={point.id}
            style={{
              color: point.serieColor,
              padding: '3px 0'
            }}
          >
            <Text
              style={{
                display: 'block',
                fontSize: '0.7em'
              }}
            >
              {getSeriesLabel(point.serieId, state.zoneFilters, zonesHierarchy)}
            </Text>
            {getXLabel(point.data.x, state, timezone)}{' '}
            {isDateRange && `(${pointDate.toFormat(DATE_RANGE_ID_FORMAT)})`}
            {' - '}
            <Text fontWeight={600}>
              {point.data.yFormatted} {measurementUnit}
            </Text>
          </Box>
        )
      })}
    </Box>
  )
}

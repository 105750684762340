import { useRef } from 'react'
import { DateTime } from 'luxon'
import ReactDatePicker from 'react-datepicker'

import { Box, FlexV2, Symbol, Text } from '@/primitives'
import { DEFAULT_TIMEZONE } from '../../state'

const offsetJsDateTimezone = (date, timeZone) => {
  const timeZoned = DateTime.fromJSDate(date).setZone(timeZone)
  const offset = timeZoned.offset
  const adjustedTime = timeZoned.plus({ minutes: offset })
  return adjustedTime.toJSDate()
}

export default function DatePicker({
  type,
  startDate,
  endDate,
  onChange,
  selected,
  maxDate,
  label,
  isSidebar = false,
  timeZone = DEFAULT_TIMEZONE
}) {
  const ref = useRef(null)

  const onOpen = () => {
    if (ref.current) {
      ref.current.setFocus(true)
    }
  }

  return (
    <Box className='DataboardFilters__ComparisonRow__DatePicker'>
      {label && (
        <Text
          as={isSidebar ? 'span' : 'p'}
          variant='page'
          tone={600}
          className='DataboardFilters__ComparisonRow__TabletLabel'
        >
          {label}
        </Text>
      )}
      <FlexV2
        alignCrossAxis='center'
        alignMainAxis='space-between'
        className='DataboardFilters__ComparisonRow__DatePicker__Input'
        onClick={onOpen}
      >
        <ReactDatePicker
          ref={ref}
          showTimeSelect={true}
          timeFormat='HH:mm'
          timeIntervals={15}
          dateFormat='d MMM yyyy HH:mm'
          selected={selected}
          onChange={onChange}
          minDate={type === 'from' ? null : startDate}
          maxDate={offsetJsDateTimezone(maxDate.toJSDate(), timeZone)}
        />
        <Symbol name='calendar today' />
      </FlexV2>
    </Box>
  )
}

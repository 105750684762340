import { useReducer, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import DatePicker from 'react-datepicker'

import useDeviceSize from '@/hooks/useDeviceSize'

import { clearPackageUnitInventories } from '@/actions/operations/packageUnitInventory'

import { requestAllStorageFacilities } from '@/actions/operations/storageFacility'
import { requestAllPackageUnits } from '@/actions/operations/packageUnit'
import { requestAllCurrencies } from '@/actions/operations/currency'
import { requestSuppliers } from '@/actions/operations/supplier'

import {
  requestPackageUnitPurchase,
  createPackageUnitPurchase,
  updatePackageUnitPurchase
} from '@/actions/operations/packageUnitPurchase'

import {
  getCurrentUserOrganizations,
  getOperationsAllStorageFacilities,
  getOperationsAllPackageUnits,
  getOperationsAllCurrencies,
  getOperationsSuppliers,
  getOperationsPackageUnitPurchase,
  getOperationsLoading,
  getOperationsError
} from '@/reducers/selectors'

import { Box, Flex, Label, Select, Text, Input, Loader } from '@/primitives'

import InputError from '@/elements/InputError'
import HeaderV2 from '@/components/Operations/Shared/HeaderV2'
import OrganizationInput from '../../../Shared/Organization/Input'

import { getValidationErrorMap } from '@/Util/GeneralUtils'
import history from '../../../../../history'
import Strings from '../Strings'

import { initialState, reducer, UPDATE_INPUT, SET_STATE } from './state'

import { PACKAGE_UNIT_PURCHASE_STATUSES } from '../utils'
import { FIELDS, SCHEMA } from './config'

function PackageUnitPurchaseForm({ state, modulePath }) {
  const dispatch = useDispatch()
  const isMobile = useDeviceSize('mobile')
  const isLaptop = useDeviceSize()
  const { itemId } = useParams()

  const strings = Strings()

  const organizations = getCurrentUserOrganizations()
  const storageFacilities = getOperationsAllStorageFacilities()
  const packageUnits = getOperationsAllPackageUnits()
  const currencies = getOperationsAllCurrencies()
  const packageUnitPurchase = getOperationsPackageUnitPurchase()
  const suppliers = getOperationsSuppliers()
  const loading = getOperationsLoading()
  const error = getOperationsError()

  const statuses = PACKAGE_UNIT_PURCHASE_STATUSES(strings)

  const [formState, dispatchFormState] = useReducer(reducer, initialState)

  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)

  useEffect(() => {
    dispatch(requestAllStorageFacilities())
    dispatch(requestAllPackageUnits())
    dispatch(requestAllCurrencies())
  }, [dispatch])

  useEffect(() => {
    dispatch(
      requestSuppliers({
        organizations: organizations.map(o => o.id)
      })
    )
  }, [organizations])

  useEffect(() => {
    if (itemId && packageUnitPurchase.id !== itemId) {
      dispatch(
        requestPackageUnitPurchase({
          packageUnitPurchaseId: itemId
        })
      )
    }
  }, [itemId])

  useEffect(() => {
    if (packageUnitPurchase?.id && packageUnitPurchase.id === itemId) {
      dispatchFormState({
        type: SET_STATE,
        state: { ...packageUnitPurchase }
      })
    }
  }, [packageUnitPurchase])

  useEffect(() => {
    if (!itemId && state.organizations.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'organizationId',
        value: state.organizations[0]
      })
    }
  }, [state.organizations])

  useEffect(() => {
    if (!itemId && state.storageFacilities.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'storageFacilityId',
        value: state.storageFacilities[0]
      })
    }
  }, [state.storageFacilities])

  useEffect(() => {
    if (!itemId && state.packageUnits.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: 'packageUnitId',
        value: state.packageUnits[0]
      })
    }
  }, [state.packageUnits])

  useEffect(() => {
    if (waiting && !loading && !error) {
      history.replace(modulePath)
    }
  }, [loading])

  const onSubmit = async e => {
    try {
      const payload = await SCHEMA().validate(formState, { abortEarly: false })

      dispatch(clearPackageUnitInventories())
      setWaiting(true)

      if (itemId) {
        payload.packageUnitPurchaseId = itemId
        dispatch(updatePackageUnitPurchase(payload))
      } else {
        dispatch(createPackageUnitPurchase(payload))
      }
    } catch (error) {
      setErrors(getValidationErrorMap(error))
    }
  }

  const handleInput = e => {
    const { name, value } = e.currentTarget
    dispatchFormState({ type: UPDATE_INPUT, name, value })
  }

  const handleNumericInput = e => {
    const { name, value } = e.currentTarget
    const newValue = value.length > 0 ? Number(value) : ''
    dispatchFormState({ type: UPDATE_INPUT, name, value: newValue })
  }

  const handleDateInput = (value, name) => {
    dispatchFormState({ type: UPDATE_INPUT, name, value })
  }

  const handleOrganizationInput = organizationId => {
    dispatchFormState({
      type: SET_STATE,
      state: {
        ...formState,
        organizationId,
        customerId: '',
        currencyId: ''
      }
    })
  }

  const handleSupplierInput = e => {
    const { name, value } = e.currentTarget
    if (value && !formState.currencyId) {
      const supplierIndex = suppliers.findIndex(s => s.id === value)
      if (supplierIndex >= 0) {
        dispatchFormState({
          type: SET_STATE,
          state: {
            ...formState,
            supplierId: value,
            currencyId: suppliers[supplierIndex].currency.id
          }
        })
      }
    } else {
      dispatchFormState({ type: UPDATE_INPUT, name, value })
    }
  }

  const filterStorageFacilities = () => {
    return storageFacilities.filter(
      f => f.organizationId === formState.organizationId
    )
  }

  const filterPackageUnits = () => {
    return packageUnits.filter(
      p => p.organizationId === formState.organizationId
    )
  }

  const filterSuppliers = () => {
    return suppliers.filter(
      s =>
        s.organizationId === formState.organizationId &&
        (s.supplyTypes.includes('packaging') ||
          s.supplyTypes.includes('branding'))
    )
  }

  const filterCurrencies = () => {
    return currencies.filter(c => c.organizationId === formState.organizationId)
  }

  const getCurrencyCode = () => {
    if (formState.currencyId) {
      const currencyIndex = currencies.findIndex(
        c => c.id === formState.currencyId
      )
      if (currencyIndex >= 0) {
        return currencies[currencyIndex].code
      }
    }
    return null
  }

  const getHeader = () => {
    if (itemId) {
      return strings.updateFormHeader
    }
    return strings.createFormHeader
  }

  return (
    <Flex
      as='form'
      axisGap={400}
      direction='column'
      className='Operations__Form'
    >
      <HeaderV2
        title={getHeader()}
        backPath={modulePath}
        buttonIcon={'save'}
        buttonText={strings.savePackageUnitPurchase}
        buttonCallback={onSubmit}
      />
      <Loader isLoading={loading}>
        <Flex axisGap={800} direction={isLaptop ? 'column' : null}>
          <Flex
            axisGap={400}
            direction='column'
            className='Operations__Form__Fields'
          >
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.purchase}
              </Text>
              <OrganizationInput
                fieldName={FIELDS.organizationId}
                organizationId={formState.organizationId}
                handleInput={handleOrganizationInput}
                errors={errors}
              />
              <Flex
                axisGap={300}
                alignMainAxis='flex-start'
                direction={isMobile ? 'column' : 'row'}
                className='Operations__Fields'
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.orderDate}
                    </Text>
                    <DatePicker
                      maxDate={new Date()}
                      selected={formState.orderDate}
                      className='Operations__Select'
                      onChange={orderDate =>
                        handleDateInput(orderDate, FIELDS.orderDate)
                      }
                    />
                    <InputError error={errors?.orderDate} />
                  </Flex>
                </Label>
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.status}
                  </Text>
                  <Select
                    className='Operations__Select'
                    name={FIELDS.status}
                    value={formState.status}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {Object.entries(statuses).map(([id, name]) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.status} />
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                alignMainAxis='flex-start'
                direction={isMobile ? 'column' : 'row'}
                className='Operations__Fields'
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.receivedDate}
                    </Text>
                    <DatePicker
                      maxDate={new Date()}
                      selected={formState.receivedDate}
                      className='Operations__Select'
                      onChange={receivedDate =>
                        handleDateInput(receivedDate, FIELDS.receivedDate)
                      }
                    />
                    <InputError error={errors?.receivedDate} />
                  </Flex>
                </Label>
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.storageFacility}
                  </Text>
                  <Select
                    className='Operations__Select'
                    name={FIELDS.storageFacilityId}
                    value={formState.storageFacilityId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterStorageFacilities().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.storageFacilityId} />
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                alignMainAxis='flex-start'
                direction={isMobile ? 'column' : 'row'}
                className='Operations__Fields'
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.packageUnit}
                  </Text>
                  <Select
                    className='Operations__Select'
                    name={FIELDS.packageUnitId}
                    value={formState.packageUnitId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterPackageUnits().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.packageUnitId} />
                </Label>
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.quantity}
                  </Text>
                  <Input
                    className='Operations__Input'
                    type='number'
                    name={FIELDS.quantity}
                    value={formState.quantity}
                    onChange={handleNumericInput}
                  />
                  <InputError error={errors?.quantity} />
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                alignMainAxis='flex-start'
                direction={isMobile ? 'column' : 'row'}
                className='Operations__Fields'
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.supplier}
                  </Text>
                  <Select
                    className='Operations__Select'
                    name={FIELDS.supplierId}
                    value={formState.supplierId}
                    onChange={handleSupplierInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterSuppliers().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.supplierId} />
                </Label>
                <Label style={{ flex: '1 1 0' }}>
                  <Text variant='page' tone={700}>
                    {strings.currency}
                  </Text>
                  <Select
                    className='Operations__Select'
                    name={FIELDS.currencyId}
                    value={formState.currencyId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterCurrencies().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.currencyId} />
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                alignMainAxis='flex-start'
                direction={isMobile ? 'column' : 'row'}
                className='Operations__Fields'
              >
                <Label style={{ flex: '1 1 0' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.unitPrice}
                    </Text>
                    <Box className='Operations__Input__Container'>
                      <Input
                        className='Operations__Input'
                        type='number'
                        name={FIELDS.unitPrice}
                        value={formState.unitPrice}
                        onChange={handleNumericInput}
                      />
                      <span className='Operations__Input__Suffix'>
                        {getCurrencyCode()}
                      </span>
                    </Box>
                    <InputError error={errors?.unitPrice} />
                  </Flex>
                </Label>
                <Label style={{ flex: '1 1 0' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.tax}
                    </Text>
                    <Box className='Operations__Input__Container'>
                      <Input
                        className='Operations__Input'
                        type='number'
                        name={FIELDS.tax}
                        value={formState.tax}
                        onChange={handleNumericInput}
                      />
                      <span className='Operations__Input__Suffix'>%</span>
                    </Box>
                    <InputError error={errors?.tax} />
                  </Flex>
                </Label>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Loader>
    </Flex>
  )
}

export default PackageUnitPurchaseForm

import { Button, Cell, Row } from '../../../../../primitives'

import { PRODUCT_STATUSES } from '../utils'

import Strings from '../Strings'

function TableRow({ item, onEdit, canEdit }) {
  const strings = Strings()

  const statuses = PRODUCT_STATUSES(strings)

  const { id, name, code, status, weight, produce, variety, grade } = item

  const handleEdit = () => {
    onEdit(id)
  }

  return (
    <Row>
      <Cell columnName={strings.product}>
        {name}
        <span>{code}</span>
      </Cell>
      <Cell columnName={strings.produce}>
        {produce.name}
        <span>
          {variety.name} ({grade.name})
        </span>
      </Cell>
      <Cell columnName={strings.weight}>
        {weight}
        {strings.grams}
      </Cell>
      <Cell columnName={strings.status}>{statuses[status]}</Cell>
      <Cell className='Operations__Table__Column__Actions'>
        {canEdit && (
          <Button
            variant='info'
            size='small'
            iconBefore='edit'
            onClick={handleEdit}
          />
        )}
      </Cell>
    </Row>
  )
}

export default TableRow

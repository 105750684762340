export const SETTING_CELCIUS_TO_FAHRENHEIT = 'celcius_to_fahrenheit'

export function processSettings(
  settings,
  settingId,
  userId = null,
  organizationId = null
) {
  if (userId === null && organizationId === null) {
    return false
  }

  const userSettings = settings[userId] ?? null
  const organizationSettings = organizationId
    ? settings[organizationId] ?? null
    : null

  if (userSettings) {
    return (
      userSettings?.options?.find(({ id }) => id === settingId)?.value ?? false
    )
  }

  if (organizationSettings) {
    return (
      organizationSettings?.options?.find(({ id }) => id === settingId).value ??
      false
    )
  }

  return false
}

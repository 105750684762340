import { makeOperationsApiCall } from './utils'

export const createSaleShipment = async params => {
  try {
    const query = /* GraphQL */ `
      mutation createSaleShipment(
        $id: String!
        $saleId: String!
        $status: SaleShipmentStatus!
        $storageFacilityId: String!
        $shippingMethodId: String!
        $dueDate: GraphQLTimestamp!
        $deliveryDate: GraphQLTimestamp
        $price: Float!
        $discount: Float
        $tax: Float
        $items: [SaleShipmentItemInput]!
      ) {
        createSaleShipment(
          id: $id
          saleId: $saleId
          status: $status
          storageFacilityId: $storageFacilityId
          shippingMethodId: $shippingMethodId
          dueDate: $dueDate
          deliveryDate: $deliveryDate
          price: $price
          discount: $discount
          tax: $tax
          items: $items
        ) {
          id
          sale {
            id
            invoiceTotal
            invoiceTax
          }
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'createSaleShipment',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updateSaleShipment = async params => {
  try {
    const query = /* GraphQL */ `
      mutation updateSaleShipment(
        $saleShipmentId: String!
        $status: SaleShipmentStatus!
        $storageFacilityId: String!
        $shippingMethodId: String!
        $dueDate: GraphQLTimestamp!
        $deliveryDate: GraphQLTimestamp
        $price: Float!
        $discount: Float
        $tax: Float
        $items: [SaleShipmentItemInput]!
      ) {
        updateSaleShipment(
          saleShipmentId: $saleShipmentId
          status: $status
          storageFacilityId: $storageFacilityId
          shippingMethodId: $shippingMethodId
          dueDate: $dueDate
          deliveryDate: $deliveryDate
          price: $price
          discount: $discount
          tax: $tax
          items: $items
        ) {
          id
          sale {
            id
            invoiceTotal
            invoiceTax
          }
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'updateSaleShipment',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const deleteSaleShipment = async params => {
  try {
    const query = /* GraphQL */ `
      mutation deleteSaleShipment($saleShipmentId: String!) {
        deleteSaleShipment(saleShipmentId: $saleShipmentId) {
          id
          sale {
            id
            invoiceTotal
            invoiceTax
          }
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'deleteSaleShipment',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

import { I18n } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Select } from '@/primitives'

import { requestAllProduces } from '@/actions/operations/produce'

import { getOperationsAllProduces } from '@/reducers/selectors'

const ProducesFilter = ({ state, dispatchState, supportMultiOrg = false }) => {
  const dispatch = useDispatch()

  const produces = getOperationsAllProduces()

  const [produceFilter, setProduceFilter] = useState(
    state.produces.length === 1 ? state.produces[0] : 'all'
  )

  const [lastFilter, setLastFilter] = useState(produceFilter)

  useEffect(() => {
    dispatch(requestAllProduces())
  }, [dispatch])

  useEffect(() => {
    if (produceFilter === 'all') {
      if (state.produces.length === 0 && produces.length > 0) {
        dispatchChange()
      } else if (state.produces.length === 1 && produces.length > 0) {
        setLastFilter(state.produces[0])
        setProduceFilter(state.produces[0])
      }
    } else if (state.produces.length === 0) {
      setProduceFilter('all')
    }
  }, [state.produces, produces])

  useEffect(() => {
    if (produces.length > 0) {
      if (produceFilter !== lastFilter) {
        setLastFilter(produceFilter)
        dispatchChange()
      }
    }
  }, [produceFilter, produces])

  const getProduceFilter = () => {
    return produceFilter === 'all' ? produces.map(i => i.id) : [produceFilter]
  }

  const dispatchChange = () => {
    dispatchState({
      produces: getProduceFilter()
    })
  }

  const onChangeFilter = e => {
    setProduceFilter(e.currentTarget.value)
  }

  const filterProduces = () => {
    if (state.organizations.length === 1 || supportMultiOrg) {
      return produces.filter(produce => {
        for (let organization of state.organizations) {
          if (produce.organizationId === organization) {
            return true
          }
        }
        return false
      })
    }
    return []
  }

  const options = filterProduces()

  return (
    <Select
      className='Operations__Header__Select'
      name='produceId'
      value={produceFilter}
      onChange={onChangeFilter}
      disabled={options.length === 0}
    >
      <option default value='all'>
        {I18n.get('All Produce')}
      </option>
      {options.map(({ name, id }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </Select>
  )
}

export default ProducesFilter

import withEditableWidget from '@/components/DashboardPageV2/Desktop/Widgets/Shared/HOC/withEditableWidget'
import { MeasurementEdit } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Measurement'
import { getMeasurements } from '@/reducers/selectors'
import {
  DATASET_QUERY,
  LAST_7_DAYS,
  LINE_CHART,
  LAST_30_DAYS,
  DAILY
} from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'
import { ChartOption, QueryOption } from '../../Shared/Dynamic/config/utils'

import DatasetLine from './DatasetLine'

const lineChart = new ChartOption({
  value: LINE_CHART,
  range: [LAST_7_DAYS, LAST_30_DAYS],
  interval: [DAILY]
})

const datasetLineQuery = new QueryOption({
  value: DATASET_QUERY,
  options: [lineChart]
})

export const gdhQueryOptionsList = [datasetLineQuery]

function GrowingDegreeHoursView(props) {
  const { config, options } = props

  const measurement = options.measurement
  const measurements = getMeasurements()

  const unit = measurements.find(m => m.id === measurement)?.unit

  if (config.query === DATASET_QUERY) {
    return (
      <DatasetLine
        {...props}
        unit={unit}
        config={config}
        options={options}
        {...props}
      />
    )
  }
}

export const GrowingDegreeHoursWidget = withEditableWidget(
  GrowingDegreeHoursView,
  MeasurementEdit
)

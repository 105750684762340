import { useDispatch } from 'react-redux'
import { Button, Column, Flex, Row } from '@/primitives'

import Strings from '../../Strings'

import { setThreshold } from '@/slices/threshold'
import { getCurrentUser } from '@/reducers/selectors'
import { getMeasurementStrings } from '@/Util/MeasurementUtils'
import {
  hasEditPermissions,
  RESOURCE_TYPE_DEVICE,
  RESOURCE_TYPE_DEVICE_THRESHOLDS
} from '@/Util/PermissionUtils'
import history from '../../../../history'

const ThresholdTableRow = ({ basePath, threshold, onDeleteThreshold }) => {
  const dispatch = useDispatch()
  const coretexUser = getCurrentUser()
  const { deleteButton, editButton, viewButton } = Strings()
  const showEditOptions = hasEditPermissions(
    coretexUser,
    RESOURCE_TYPE_DEVICE,
    RESOURCE_TYPE_DEVICE_THRESHOLDS
  )

  function onSelectThreshold(e) {
    e.preventDefault()
    dispatch(setThreshold(threshold))
    history.push(`/admin${basePath}/thresholds/${threshold.measurementId}`)
  }

  function onSelectDeleteThreshold(e) {
    e.preventDefault()
    onDeleteThreshold(threshold)
  }

  return (
    <Row>
      <Column>
        {getMeasurementStrings(threshold?.measurement?.id)?.shortName ??
          threshold?.measurement?.shortName}
      </Column>
      <Column>{threshold?.message ?? '-'}</Column>
      <Column>
        <Flex
          direction='row'
          alignMainAxis='flex-start'
          alignCrossAxis='center'
          axisGap={300}
        >
          {showEditOptions && (
            <Button
              variant='error'
              size='small'
              onClick={onSelectDeleteThreshold}
            >
              {deleteButton}
            </Button>
          )}
          <Button variant='primary' size='small' onClick={onSelectThreshold}>
            {showEditOptions ? editButton : viewButton}
          </Button>
        </Flex>
      </Column>
    </Row>
  )
}

export default ThresholdTableRow

import { Select, Text } from '../../../../primitives'

const Navigator = ({
  options,
  value,
  defaultString,
  onChange,
  isEditable,
  isDisabled
}) => {
  if (!isEditable) {
    return <Text>{value}</Text>
  }

  const displayOptions = options.filter(z => z.status === 'active')

  if (displayOptions.length === 0) {
    return null
  }

  return (
    <Select value={value} onChange={onChange} disabled={isDisabled}>
      <option default value=''>
        {defaultString}
      </option>
      {displayOptions?.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </Select>
  )
}

export default Navigator

import { I18n } from 'aws-amplify'
import {
  datasetLineQuery,
  defaultAverageValueQuery,
  defaultLiveValueQuery
} from '../Shared/Dynamic/config/defaults'

import {
  DAILY,
  DATASET_QUERY,
  HOURLY,
  LAST_30_DAYS,
  LAST_7_DAYS,
  LINE_CHART,
  SCATTERPLOT_CHART
} from '../Shared/Dynamic/config'

import { SaturationVapourPressureWidget } from './SaturationVapourPressure'
import { VapourPressureDeficitWidget } from './VapourPressureDeficit'
import { AbsoluteHumidityWidget } from './AbsoluteHumidity'
import {
  DailyLightIntegralQueryOptionsList,
  DailyLightIntegralWidget
} from './DailyLightIntergral'
import {
  RadiationTemperatureRatioQueryOptionsList,
  RadiationTemperatureRatioWidget
} from './RadiationTemperatureRatio'

import { Strings } from './strings'

export function environmentTypes() {
  const strings = Strings()

  return {
    temperature: {
      widgetId: 'temperature',
      title: () => {
        return strings.temperature
      },
      icon: 'thermostat',
      measurement: 'temperature'
    },
    humidity: {
      widgetId: 'humidity',
      title: I18n.get('Humidity'),
      icon: 'water damage',
      measurement: 'humidity'
    },
    pressure: {
      widgetId: 'pressure',
      title: I18n.get('Pressure'),
      icon: 'tire repaire',
      measurement: 'pressure'
    },
    airflow: {
      widgetId: 'airflow',
      title: I18n.get('Airflow'),
      icon: 'air',
      measurement: 'airflow'
    },
    carbonDioxide: {
      widgetId: 'carbonDioxide',
      title: I18n.get('Carbon Dioxide'),
      icon: 'co2',
      measurement: 'carbon_dioxide'
    },
    uvIndex: {
      widgetId: 'uvIndex',
      title: I18n.get('UV Index'),
      icon: 'brightness 7',
      measurement: 'uv_index'
    },
    solarIrradiance: {
      widgetId: 'solarIrradiance',
      title: I18n.get('Solar Irradiance'),
      icon: 'local fire department',
      measurement: 'solar_irradiance',
      queryOptionsList: [defaultLiveValueQuery, defaultAverageValueQuery]
    },
    par: {
      widgetId: 'par',
      title: I18n.get('PAR'),
      icon: 'flare',
      measurement: 'par'
    },
    absoluteHumidity: {
      widgetId: 'absoluteHumidity',
      title: I18n.get('Absolute Humidity'),
      icon: 'water damage',
      measurement: 'absolute_humidity',
      component: AbsoluteHumidityWidget,
      queryOptionsList: [
        defaultLiveValueQuery,
        defaultAverageValueQuery,
        datasetLineQuery
      ]
    },
    dailyLightIntegral: {
      widgetId: 'dailyLightIntegral',
      title: I18n.get('DLI'),
      icon: 'wb twilight',
      measurement: 'daily_light_integral',
      component: DailyLightIntegralWidget,
      queryOptionsList: DailyLightIntegralQueryOptionsList,
      config: {
        query: DATASET_QUERY,
        chart: LINE_CHART,
        range: LAST_7_DAYS,
        interval: HOURLY
      }
    },
    radiationTemperatureRatio: {
      widgetId: 'radiationTemperatureRatio',
      title: I18n.get('RTR'),
      icon: 'solar power',
      measurement: 'radiation_temperature_ratio',
      component: RadiationTemperatureRatioWidget,
      config: {
        query: DATASET_QUERY,
        chart: SCATTERPLOT_CHART,
        range: LAST_30_DAYS,
        interval: DAILY
      },
      defaultPosition: {
        md: [0, 0, 6, 4, 5, 4]
      },
      queryOptionsList: RadiationTemperatureRatioQueryOptionsList
    },
    saturationVapourPressure: {
      widgetId: 'saturationVapourPressure',
      title: I18n.get('SVP'),
      icon: 'compress',
      measurement: 'saturation_vapour_pressure',
      component: SaturationVapourPressureWidget,
      queryOptionsList: [
        defaultLiveValueQuery,
        defaultAverageValueQuery,
        datasetLineQuery
      ]
    },
    vapourPressureDeficit: {
      widgetId: 'vapourPressureDeficit',
      title: I18n.get('VPD'),
      icon: 'water_damage',
      measurement: 'vapour_pressure_deficit',
      component: VapourPressureDeficitWidget,
      queryOptionsList: [defaultLiveValueQuery, datasetLineQuery]
    }
  }
}

import { useReducer, useState, useEffect, Fragment } from 'react'
import { I18n } from 'aws-amplify'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import ZoneMover from './ZoneMover'

import {
  Button,
  Flex,
  Form,
  Input,
  Label,
  Loader,
  Slot,
  Text
} from '@/primitives'
import { Dialog, LineSeparator, Select } from '@/elements'

import { fetchLocation } from '@/slices/location'
import { sendCreateZone, sendUpdateZone } from '@/slices/management/zone'
import { showBanner } from '@/slices/util'

import {
  getShowBanner,
  getZoneTree,
  getCurrentUser,
  getZone,
  getZoneError,
  getTargetZone,
  getTargetZoneLoading,
  getCurrentUserOrganizations,
  getZoneHierarchy,
  getZoneHierarchyLoading,
  getLocation
} from '@/reducers/selectors'

import AdminUtils, {
  isZoneSite,
  getOrgNameById,
  getZoneSubNameV2
} from '@/Util/AdminUtils'
import { getIsGodMode } from '@/Util/PermissionUtils'
import ZoneUtils from '@/Util/ZoneUtils'
import useOrgLabel from '@/hooks/useOrgLabel'
import timezones from '@/Util/timezones.json'

import Strings from '../../Strings'
import { getZoneModalStrings } from '../../utils'
import { getDisplayNamePath, getMovedParentPath } from '../Utils/Zone'

import './index.scss'

const defaultValues = {
  name: '',
  id: '',
  status: 'active',
  code: '',
  parentId: null,
  latitude: '0',
  longitude: '0',
  timeZone: '',
  parentPath: '',
  organizationId: null
}

const statusOptions = [
  {
    value: 'active',
    label: I18n.get('Active')
  },
  {
    value: 'inactive',
    label: I18n.get('Inactive')
  }
]

const timezoneOptions = timezones.map(name => ({
  label: name,
  value: name
}))

const getOrganizationOptions = ({
  organizations,
  organizationId,
  addNewZone,
  editOrganizationsField
}) => {
  let options = organizations.map(({ name, id }) => ({
    label: name,
    value: id
  }))

  if (!addNewZone && !editOrganizationsField) {
    options.push({
      value: organizationId,
      label: getOrgNameById(organizationId, organizations)
    })
  }

  return options
}

const { newZoneSchema } = AdminUtils

function reducer(state, action) {
  const { type, value } = action
  if (type === 'updateAll') {
    const code = value?.code ?? ''
    const longitude = String(value.longitude) || '0'
    const latitude = String(value.latitude) || '0'
    const zone = { ...value, longitude, latitude, code }
    return zone
  } else if (type === 'setNewZone') {
    return {
      ...state,
      parentId: value?.id ?? null,
      parentPath: value?.parentPath ?? ''
    }
  } else if (type === 'setLocation') {
    return {
      ...state,
      latitude: value?.latitude ?? '0',
      longitude: value?.longitude ?? '0'
    }
  } else {
    const updatedState = { ...state }
    updatedState[type] = value
    return updatedState
  }
}

const UpdateZoneModal = ({
  showModal,
  setShowModal,
  addNewZone,
  setBreadcrumbs = () => {}
}) => {
  const strings = Strings()
  const selectedZone = getZone()

  const {
    cancel,
    longitudeCoords,
    latitudeCoords,
    timezoneName,
    statusName,
    orgFieldName,
    orgFieldPlaceholder,
    selectTimezone,
    code,
    currentLocation
  } = strings

  const [error, setError] = useState('')
  const [showOrganizationsField, setShowOrganizationsField] = useState(false)
  const [editOrganizationsField, setEditOrganizationsField] = useState(false)
  const [upsertDispatched, setUpsertDispatched] = useState(false)
  const [showZoneMover, setShowZoneMover] = useState(false)
  const [selectedZoneToMove, setSelectedZoneToMove] = useState(null)
  const [siteHierarchy, setSiteHierarchy] = useState(null)
  const [state, dispatch] = useReducer(reducer, defaultValues)
  const [currentZoneInfo, setCurrentZoneInfo] = useState(false)
  const [displayPath, setDisplayPath] = useState(null)

  const reduxDispatch = useDispatch()
  const history = useHistory()
  const orgLabels = useOrgLabel(['site', 'facility', 'room', 'zone', 'subzone'])

  const organizations = getCurrentUserOrganizations()
  const currentUser = getCurrentUser()
  const isGodMode = getIsGodMode(currentUser)
  const banner = getShowBanner()
  const zonesOptions = getZoneTree()
  const zonesError = getZoneError()
  const targetZone = getTargetZone()
  const targetZoneLoading = getTargetZoneLoading()
  const zoneHierarchy = getZoneHierarchy()
  const loadingZoneHierarchy = getZoneHierarchyLoading()
  const location = getLocation()

  useEffect(() => {
    reduxDispatch(fetchLocation())
  }, [reduxDispatch])

  useEffect(() => {
    if (
      state.id &&
      Object.values(zoneHierarchy).length > 0 &&
      !displayPath &&
      selectedZone.id
    ) {
      const hierarchy =
        zoneHierarchy?.[state?.parentPath.split('/')?.[1]] ?? null
      setSiteHierarchy(hierarchy)
      const node = ZoneUtils.getZoneHierarchyValueByPath(
        hierarchy,
        state.parentPath
      )
      let currentZoneInfo = {
        level: node?.level,
        hasCoreDevice: state?.hasCoreDevice || false,
        hasDevices: ZoneUtils.zoneHasDevicesInBranch(node)
      }

      // if is facility check if at least one child has a coreDevice
      if (node?.level === 2) {
        currentZoneInfo.hasCoreDevice = Object.values(node?.children).some(
          zone => zone.hasCoreDevice
        )

        // if is zone or more go to room and check if it has a coreDevice
      } else if (node?.level >= 4) {
        const endSliceIndex = state.parentPath[0] === '/' ? 4 : 3
        const parentRoomPath = state.parentPath
          .split('/')
          .slice(0, endSliceIndex)
          .join('/')
        const node = ZoneUtils.getZoneHierarchyValueByPath(
          hierarchy,
          parentRoomPath
        )

        currentZoneInfo.hasCoreDevice = node?.hasCoreDevice
      }

      setCurrentZoneInfo(currentZoneInfo)
      setDisplayPath(
        getDisplayNamePath(hierarchy || null, state?.parentPath || null)
      )
    }
  }, [zoneHierarchy, state, displayPath])

  const zonesNames = zonesOptions?.zones
    ?.map(({ name }) => name)
    .filter(name => name !== selectedZone?.name)

  useEffect(() => {
    if (!addNewZone && selectedZone?.id) {
      dispatch({ type: 'updateAll', value: selectedZone })
    }

    if (addNewZone) {
      dispatch({ type: 'setNewZone', value: selectedZone })
      const site =
        zoneHierarchy?.[selectedZone?.parentPath?.split('/')?.[1]] ?? null

      dispatch({ type: 'timeZone', value: site?.timeZone })
    }
  }, [addNewZone, zoneHierarchy, selectedZone])

  useEffect(() => {
    if (addNewZone) {
      dispatch({
        type: 'setLocation',
        value: location
      })
    }
  }, [addNewZone, location])

  useEffect(() => {
    if (targetZone && !targetZoneLoading && upsertDispatched && !zonesError) {
      setShowModal(false)
    }
  }, [targetZone, upsertDispatched, targetZoneLoading])

  useEffect(() => {
    if (upsertDispatched && zonesError) {
      setUpsertDispatched(false)
    }
  }, [zonesError, upsertDispatched])

  useEffect(() => {
    if (currentUser?.hasOwnProperty('permissions')) {
      const userRolePermissions = currentUser.permissions.filter(
        ({ resourceId, resourceType }) =>
          resourceType === 'user' &&
          (resourceId === 'roles' || resourceId === null)
      )

      const canViewRoleOrganization = userRolePermissions.some(
        ({ operationType }) => operationType === 'read'
      )

      setShowOrganizationsField(canViewRoleOrganization && !state?.parentId)
    }
  }, [currentUser, state?.parentId])

  useEffect(() => {
    if (currentUser?.hasOwnProperty('permissions')) {
      const userRolePermissions = currentUser.permissions.filter(
        ({ resourceId, resourceType }) =>
          resourceType === 'user' &&
          (resourceId === 'roles' || resourceId === null)
      )

      const canEditRoleOrganization = userRolePermissions.some(
        ({ operationType }) => operationType === 'write'
      )

      if ((isGodMode && canEditRoleOrganization) || addNewZone) {
        setEditOrganizationsField(true)
      }
    }
  }, [currentUser, showOrganizationsField, addNewZone, isGodMode])

  const onChange = ({ target: { name, value } }) => {
    if (error === name) setError('')
    dispatch({ type: name, value })

    if (addNewZone && name === 'name') {
      const newId = value.trim().replaceAll(' ', '-')
      dispatch({ type: 'id', value: newId })
    }
  }

  const formatLongitudeLatitude = newValue => {
    if (typeof newValue !== 'string') {
      return parseFloat(String(newValue).replace(',', '.'))
    }
    return parseFloat(newValue.replace(',', '.'))
  }

  const onSubmitForm = async e => {
    const longitude = formatLongitudeLatitude(state.longitude)
    const latitude = formatLongitudeLatitude(state.latitude)
    const parentPath = addNewZone
      ? `${state.parentPath}/${state.id}`
      : state.parentPath
    const zoneDetails = {
      ...state,
      parentPath,
      longitude,
      latitude
    }

    try {
      await newZoneSchema(zonesNames, zoneDetails).validate(zoneDetails, {
        abortEarly: false
      })

      const isLocationMoved =
        state.parentId !== selectedZone?.parentId && !addNewZone

      addNewZone
        ? reduxDispatch(sendCreateZone({ zone: zoneDetails }))
        : reduxDispatch(sendUpdateZone({ zone: zoneDetails, isLocationMoved }))
      if (banner.show)
        reduxDispatch(showBanner({ show: false, message: '', type: null }))

      if (state.parentId !== selectedZone?.parentId && !addNewZone) {
        setSelectedZoneToMove(null)

        history.replace(`/admin/zones${state.parentPath}/details`)
        setShowModal(false)
      }

      setUpsertDispatched(true)
    } catch (err) {
      const { path, message } = err.inner[0]
      setError(path)
      reduxDispatch(showBanner({ show: true, message, type: 'error' }))
    }
  }

  const onClickClose = () => {
    if (banner.show) {
      reduxDispatch(showBanner({ show: false, message: '', type: null }))
    }
    setShowModal(false)
  }

  const { modalTitle, modalSubtitle, submitButtonText, name } =
    getZoneModalStrings(selectedZone, addNewZone, orgLabels)

  const onShowZoneMover = e => {
    e.preventDefault()
    setShowZoneMover(true)
  }

  const onCloseZoneMover = e => {
    e.preventDefault()
    setShowZoneMover(false)
  }

  const onConfirmZoneMover = () => {
    setShowZoneMover(false)
    const targetMove = {
      id: state.id,
      name: state.name,
      path: getMovedParentPath(state.id, selectedZoneToMove.parentPath)
    }
    dispatch({ type: 'parentId', value: selectedZoneToMove?.id })
    const displayPath = getDisplayNamePath(
      siteHierarchy,
      selectedZoneToMove.parentPath,
      targetMove
    )
    setDisplayPath(displayPath)
    dispatch({
      type: 'parentPath',
      value: targetMove.path
    })
  }

  const onHandleSelect = (selected, meta) => {
    onChange({
      target: {
        name: meta.name,
        value: selected.value
      }
    })
  }

  return (
    <Fragment>
      {showModal && (
        <Dialog
          open={showModal}
          onOpenChange={setShowModal}
          type='offcanvas'
          className='ZoneDetails__OffCanvas'
          style={{ zIndex: 3 }}
        >
          <Slot name='title'>
            <Text size={300} fontWeight={700}>
              {modalTitle}
            </Text>
          </Slot>
          <Slot name='content'>
            <Flex>
              <Form onSubmit={onSubmitForm} autoComplete='off'>
                <Text as='p'>
                  {addNewZone
                    ? modalSubtitle
                    : `${modalSubtitle} ${selectedZone?.id}`}
                </Text>
                <LineSeparator />
                <Label>
                  <Text variant={'page'} tone={700}>
                    {name}
                  </Text>
                  <Input name='name' value={state.name} onChange={onChange} />
                </Label>
                {/* <Label>
                  <Text variant={'page'} tone={700}>
                    {id}
                  </Text>
                  <Input name='id' value={state.id} disabled />
                </Label> */}
                <Label>
                  <Text variant='page' tone={700}>
                    {currentLocation}
                  </Text>
                  <Loader isLoading={loadingZoneHierarchy}>
                    <Flex axisGap={400} direction='column'>
                      <Input
                        value={displayPath || state.parentPath}
                        readOnly={true}
                      />
                      {state.parentId && !addNewZone && (
                        <Button
                          onClick={onShowZoneMover}
                          className='ZoneDetails__MoveButton'
                          iconBefore={
                            currentZoneInfo.hasCoreDevice ||
                            currentZoneInfo.hasDevices
                              ? 'lock'
                              : 'edit_location'
                          }
                          disabled={
                            currentZoneInfo.hasCoreDevice ||
                            currentZoneInfo.hasDevices
                          }
                        >
                          {currentZoneInfo.hasCoreDevice ||
                          currentZoneInfo.hasDevices
                            ? `${I18n.get('This')} ${getZoneSubNameV2(
                                currentZoneInfo.level - 1,
                                orgLabels
                              )} cannot be moved`
                            : I18n.get('Move location')}
                        </Button>
                      )}
                    </Flex>
                  </Loader>
                </Label>
                <Flex
                  direction='row'
                  alignMainAxis='space-between'
                  axisGap={300}
                >
                  <Label>
                    <Text variant={'page'} tone={700}>
                      {latitudeCoords}
                    </Text>
                    <Input
                      name='latitude'
                      value={state.latitude}
                      onChange={onChange}
                    />
                  </Label>
                  <Label style={{ marginBlockStart: 0 }}>
                    <Text variant={'page'} tone={700}>
                      {longitudeCoords}
                    </Text>
                    <Input
                      name='longitude'
                      value={state.longitude}
                      onChange={onChange}
                    />
                  </Label>
                </Flex>
                <Label>
                  <Text variant={'page'} tone={700}>
                    {timezoneName}
                  </Text>
                  <Select
                    name='timeZone'
                    value={state.timeZone}
                    onChange={onHandleSelect}
                    options={timezoneOptions}
                    isSearchable={true}
                    placeholder={selectTimezone}
                  />
                </Label>
                {showOrganizationsField && organizations?.length > 0 && (
                  <Label>
                    <Text variant={'page'} tone={700}>
                      {orgFieldName}
                    </Text>
                    <Select
                      name='organizationId'
                      value={state.organizationId}
                      onChange={onHandleSelect}
                      placeholder={orgFieldPlaceholder}
                      options={getOrganizationOptions({
                        organizations,
                        organizationId: state.organizationId,
                        addNewZone,
                        editOrganizationsField
                      })}
                      isSearchable={true}
                      disabled={!editOrganizationsField && !addNewZone}
                    />
                  </Label>
                )}
                <Label>
                  <Text variant={'page'} tone={700}>
                    {statusName}
                  </Text>
                  <Select
                    name='status'
                    value={state.status}
                    onChange={onHandleSelect}
                    options={statusOptions}
                  />
                </Label>
                <Label>
                  <Text variant={'page'} tone={700}>
                    {code}
                  </Text>
                  <Input name='code' value={state.code} onChange={onChange} />
                </Label>
              </Form>
            </Flex>
          </Slot>

          <Slot name='actions'>
            <Flex axisGap={400} alignMainAxis={'space-between'}>
              <Button onClick={onClickClose}>{cancel}</Button>
              <Button
                variant={'primary'}
                disabled={
                  !state.name ||
                  !state.id ||
                  !state.timeZone ||
                  (isZoneSite(state, addNewZone) && !state.organizationId)
                }
                onClick={onSubmitForm}
                loading={targetZoneLoading}
                style={{ width: '9rem' }}
              >
                {submitButtonText}
              </Button>
            </Flex>
          </Slot>
        </Dialog>
      )}

      {showZoneMover && siteHierarchy && (
        <Dialog
          open={showZoneMover}
          onOpenChange={setShowZoneMover}
          type='offcanvas'
          className='ZoneDetails__OffCanvas'
          style={{ zIndex: 3 }}
        >
          <Slot name='title'>
            <Text size={300} fontWeight={700}>
              {I18n.get('Move location')}
            </Text>
          </Slot>
          <Slot name='content'>
            <ZoneMover
              state={state}
              selectedZone={selectedZoneToMove}
              setSelectedZone={setSelectedZoneToMove}
              siteHierarchy={siteHierarchy}
            />
          </Slot>
          <Slot name='actions'>
            <Flex axisGap={400} alignMainAxis={'space-between'}>
              <Button onClick={onCloseZoneMover}>{cancel}</Button>
              <Button
                variant={'primary'}
                disabled={!state.name || !state.id || !state.timeZone}
                onClick={onConfirmZoneMover}
                loading={targetZoneLoading}
                style={{ width: '9rem' }}
              >
                {I18n.get('Confirm move')}
              </Button>
            </Flex>
          </Slot>
        </Dialog>
      )}
    </Fragment>
  )
}

export default UpdateZoneModal

import { useState, useEffect } from 'react'

import { getMeasurements } from '@/reducers/selectors'
import { nameLookup, unitLookup } from '@/Util/UnitUtils'

import { Flex, Row, Cell } from '@/primitives'

import {
  FAHRENHEIT_UNIT,
  convertCelciusToFahrenheit,
  convertFahrenheitToCelsius,
  KMH_UNIT,
  convertMSToKMH,
  convertKMHToMS
} from '@/Util/MeasurementUtils'

import BoundInputCell from './Input'

import Strings from '../../Strings'

import { UPDATE_MEASUREMENTS } from '../state'

const initialFormState = {
  key: null,
  bounds: []
}

function ProduceThresholdMeasurementForm({
  measurements,
  measurementKey,
  dispatchFormState,
  useFahrenheit
}) {
  const strings = Strings()
  const allMeasurements = getMeasurements()

  const [formState, setFormState] = useState(initialFormState)

  useEffect(() => {
    if (measurements?.length > 0) {
      for (let measurement of measurements) {
        if (measurement.key === measurementKey) {
          setFormState({
            ...measurement
          })
          break
        }
      }
    }
  }, [measurements, measurementKey])

  const boundFields = [
    [strings.fieldStress, 'boundsStressLower'],
    [strings.fieldBad, 'boundsBadLower'],
    [strings.fieldGood, 'boundsGoodLower'],
    [strings.fieldGood, 'boundsGoodUpper'],
    [strings.fieldBad, 'boundsBadUpper'],
    [strings.fieldStress, 'boundsStressUpper']
  ]

  const handleNumericInput = e => {
    const { name, value } = e.currentTarget
    let newValue = value.length > 0 ? Number(value) : ''

    if (newValue !== '') {
      if (useFahrenheit && measurementKey === 'temperature') {
        newValue = convertFahrenheitToCelsius(newValue)
      } else if (measurementKey === 'wind_speed') {
        newValue = Number(convertKMHToMS(newValue).toFixed(1))
      }
    }

    let newMeasurements = [...measurements]
    const boundIndex = boundFields.findIndex(b => b[1] === name)
    for (let i = 0; i < newMeasurements.length; i++) {
      if (newMeasurements[i].key === measurementKey) {
        newMeasurements[i].bounds[boundIndex] = newValue
        break
      }
    }

    dispatchFormState({
      type: UPDATE_MEASUREMENTS,
      measurements: newMeasurements
    })
  }

  const getUnit = measurementKey => {
    if (measurementKey === 'temperature') {
      if (useFahrenheit) {
        return FAHRENHEIT_UNIT
      }
    } else if (measurementKey === 'wind_speed') {
      return KMH_UNIT
    }
    return unitLookup(measurementKey, allMeasurements)
  }

  const getValue = (measurementKey, value) => {
    if (value || value === 0) {
      if (measurementKey === 'temperature') {
        if (useFahrenheit) {
          return convertCelciusToFahrenheit(value).toFixed(0)
        }
      } else if (measurementKey === 'wind_speed') {
        return convertMSToKMH(value).toFixed(0)
      }
    }
    return value
  }

  return (
    <Row className='Operations__Form__Table'>
      <Cell
        columnName={strings.fieldMeasurement}
        style={{ lineHeight: '2em', whiteSpace: 'nowrap' }}
      >
        <Flex axisGap={300} direction='column'>
          {nameLookup(measurementKey, allMeasurements)}
        </Flex>
      </Cell>
      {boundFields.map((bound, index) => (
        <BoundInputCell
          key={measurementKey + bound[1]}
          measurementKey={measurementKey}
          columnName={bound[0]}
          name={bound[1]}
          value={getValue(measurementKey, formState?.bounds?.[index])}
          handleInput={handleNumericInput}
        />
      ))}
      <Cell columnName={strings.fieldUnit} style={{ lineHeight: '2em' }}>
        <Flex axisGap={300} direction='column'>
          {getUnit(measurementKey)}
        </Flex>
      </Cell>
    </Row>
  )
}

export default ProduceThresholdMeasurementForm

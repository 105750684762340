import { updateList } from '../util'

import {
  RECEIVE_FERTILIZER_INVENTORY_CALIBRATIONS,
  REQUEST_FERTILIZER_INVENTORY_CALIBRATIONS,
  CLEAR_FERTILIZER_INVENTORY_CALIBRATIONS,
  CREATE_FERTILIZER_INVENTORY_CALIBRATION,
  FERTILIZER_INVENTORY_CALIBRATION_CREATED
} from '@/actions/operations/fertilizerInventoryCalibration'

export const fertilizerInventoryCalibrationInitialState = {
  fertilizerInventoryCalibrations: [],
  fertilizerInventoryCalibrationsCount: 0,
  loading: false,
  error: null
}

const handlers = {
  [CLEAR_FERTILIZER_INVENTORY_CALIBRATIONS]: (state, action) => ({
    ...state,
    fertilizerInventoryCalibrations: [],
    fertilizerInventoryCalibrationsCount: 0,
    error: null
  }),
  [REQUEST_FERTILIZER_INVENTORY_CALIBRATIONS]: (state, action) => ({
    ...state,
    fertilizerInventoryCalibrations: [],
    fertilizerInventoryCalibrationsCount: 0,
    error: null,
    loading: true
  }),
  [RECEIVE_FERTILIZER_INVENTORY_CALIBRATIONS]: (state, action) => ({
    ...state,
    fertilizerInventoryCalibrations: !action.error
      ? action.fertilizerInventoryCalibrations
      : [],
    fertilizerInventoryCalibrationsCount: !action.error ? action.count : 0,
    error: action.error ?? null,
    loading: false
  }),
  [CREATE_FERTILIZER_INVENTORY_CALIBRATION]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [FERTILIZER_INVENTORY_CALIBRATION_CREATED]: (state, action) => ({
    ...state,
    fertilizerInventoryCalibration: !action.error
      ? {}
      : state.fertilizerInventoryCalibration,
    fertilizerInventoryCalibrations: updateList(
      action.error,
      state.fertilizerInventoryCalibrations,
      action.fertilizerInventoryCalibration,
      true
    ),
    fertilizerInventoryCalibrationsCount: !action.error
      ? state.fertilizerInventoryCalibrationsCount + 1
      : state.fertilizerInventoryCalibrationsCount,
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

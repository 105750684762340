import Strings from './Strings'

export const PHOTO_SUBJECT = () => {
  const strings = Strings()
  return {
    plant: strings.subjectPlant,
    leaf: strings.subjectLeaf,
    fruit: strings.subjectFruit,
    substrate: strings.subjectSubstrate
  }
}

export const PHOTO_STATUS = () => {
  const strings = Strings()
  return {
    healthy: strings.statusHealthy,
    diseased: strings.statusDiseased,
    damaged: strings.statusDamaged,
    deformed: strings.statusDeformed
  }
}

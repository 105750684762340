import { I18n } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Select } from '@/primitives'

import { requestAllRegions } from '@/actions/operations/region'

import { getOperationsAllRegions } from '@/reducers/selectors'

const RegionsFilter = ({ state, dispatchState }) => {
  const dispatch = useDispatch()

  const regions = getOperationsAllRegions()

  const [regionFilter, setRegionFilter] = useState(
    state.regions.length === 1 ? state.regions[0] : 'all'
  )
  const [lastFilter, setLastFilter] = useState(regionFilter)

  useEffect(() => {
    dispatch(requestAllRegions())
  }, [dispatch])

  useEffect(() => {
    if (regionFilter === 'all') {
      if (state.regions.length === 0 && regions.length > 0) {
        dispatchChange()
      } else if (state.regions.length === 1 && regions.length > 0) {
        setLastFilter(state.regions[0])
        setRegionFilter(state.regions[0])
      }
    } else if (state.regions.length === 0) {
      setRegionFilter('all')
    }
  }, [state.regions, regions])

  useEffect(() => {
    if (regions.length > 0) {
      if (regionFilter !== lastFilter) {
        setLastFilter(regionFilter)
        dispatchChange()
      }
    }
  }, [regionFilter, regions])

  const getRegionFilter = () => {
    return regionFilter === 'all' ? regions.map(i => i.id) : [regionFilter]
  }

  const dispatchChange = () => {
    dispatchState({
      regions: getRegionFilter()
    })
  }

  const onChangeFilter = e => {
    setRegionFilter(e.currentTarget.value)
  }

  const filterRegions = () => {
    if (state.countries.length === 1) {
      return regions.filter(region => {
        for (let country of state.countries) {
          if (region.countryId === country) {
            return true
          }
        }
        return false
      })
    }
    return []
  }

  const options = filterRegions()

  return (
    <Select
      className='Operations__Header__Select'
      name='regionId'
      value={regionFilter}
      onChange={onChangeFilter}
      disabled={options.length === 0}
    >
      <option default value='all'>
        {I18n.get('All Regions')}
      </option>
      {options.map(({ name, id }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </Select>
  )
}

export default RegionsFilter

import { Button, FlexV2, Text } from '@/primitives'

import Strings from '../../Strings'

import SectionHeaderWrapper from '../Utils/SectionHeaderWrapper'

import { getCurrentUser } from '@/reducers/selectors'
import {
  hasEditPermissions,
  RESOURCE_TYPE_DEVICE,
  RESOURCE_TYPE_DEVICE_THRESHOLDS
} from '@/Util/PermissionUtils'

const Header = ({ selectedZone, onCreateThreshold }) => {
  const coretexUser = getCurrentUser()
  const showEditOptions = hasEditPermissions(
    coretexUser,
    RESOURCE_TYPE_DEVICE,
    RESOURCE_TYPE_DEVICE_THRESHOLDS
  )

  const { thresholdsHeading, thresholdsSubheading, addThresholdButton } =
    Strings()

  return (
    <SectionHeaderWrapper>
      <FlexV2 direction='column' axisGap={100}>
        <Text size={300} fontWeight={700}>
          {thresholdsHeading}
        </Text>
        <Text fontFamily='mono' variant='page' tone={900} size={200}>
          {thresholdsSubheading} {selectedZone?.name}.
        </Text>
      </FlexV2>
      {showEditOptions && (
        <Button variant='primary' size='small' onClick={onCreateThreshold}>
          {addThresholdButton}
        </Button>
      )}
    </SectionHeaderWrapper>
  )
}

export default Header

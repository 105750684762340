import { I18n } from 'aws-amplify'

const Strings = () => ({
  profileHeader: I18n.get('Profile'),
  profilePhoneNumber: I18n.get('Phone Number'),
  profilePhoneNumberPlaceholder: I18n.get('Enter phone number'),
  profilePhoneNumberHelp: I18n.get('Include country code'),
  profileWhatsAppNumber: I18n.get('WhatsApp Number'),
  profilePhoneSuccessFeedback: I18n.get('Phone number updated successfully.'),
  profilePhoneErrorFeedback: I18n.get('An error occurred while updating your phone number. Please try again.'),
  errorInvalidPhoneNumber: I18n.get('Please provide a valid phone number.'),
  profileUpdatePhoneNumber: I18n.get('Update phone number'),
  profileUpdateWhatsAppNumber: I18n.get('Update WhatsApp number'),
  profileWhatsAppSuccessFeedback: I18n.get('WhatsApp number updated successfully.'),
  profileWhatsAppErrorFeedback: I18n.get('An error occurred while updating your WhatsApp number. Please try again.'),
  profileWhatsAppDeleteSuccessFeedback: I18n.get('WhatsApp number deleted successfully.'),
  profileWhatsAppDeleteErrorFeedback: I18n.get('An error occurred while deleting your WhatsApp number. Please try again.'),
  profilePreferredLanguage: I18n.get('Preferred Language'),
  profilePreferredLanguageUpdate: I18n.get('Update language'),
  profilePreferredLanguageSuccessFeedback: I18n.get('Preferred language updated successfully.'),
  profilePreferredLanguageErrorFeedback: I18n.get('An error occurred while updating your preferred language. Please try again.'),
  mfaHeader: I18n.get('Multi-factor Authentication'),
  mfaSetup: I18n.get('Setup'),
  mfaSetupDisable: I18n.get('Disable'),
  mfaSetupSubmitCode: I18n.get('Submit code'),
  mfaSetupMessage: I18n.get('Open your Authenticator app and scan the QR code above. Enter the code from the app to complete the setup.'),
  mfaSetupCancel: I18n.get('Cancel'),
  mfaCodePlaceholder: I18n.get('Enter code'),
  mfaStatusNone: I18n.get('None'),
  mfaStatusTotp: I18n.get('TOTP'),
  mfaStatusSms: I18n.get('SMS'),
  mfaSuccessFeedback: I18n.get('Multi-factor authentication enabled successfully.'),
  mfaSuccessDisableFeedback: I18n.get('Multi-factor authentication disabled successfully.'),
  mfaErrorCodeFeedback: I18n.get('Code mismatch.'),
  mfaErrorFeedback: I18n.get('An error occurred while enabling multi-factor authentication. Please try again.'),
  resetDashboards: I18n.get('Reset dashboards'),
  resetDashboardsDescription: I18n.get('Reset your dashboards to defaults'),
  update: I18n.get('Update'),
  setUpMfa: I18n.get('Set up MFA'),
  settingsDescription: I18n.get('Update your profile settings'),
  remove: I18n.get('Remove'),
  whatsappNotVerified: I18n.get('By verifying your WhatsApp number you agree to receive automated notifications.'),
  whatsappVerified: I18n.get('You have agreed to receive automated notifications on this number.'),
  
  /* Color Scheme */
  light: I18n.get('Light'),
  dark: I18n.get('Dark'),
  system: I18n.get('System'),
  profilePreferredColorScheme: I18n.get('Preferred Color Scheme'),
  profilePreferredColorSchemeSuccessFeedback: I18n.get('Preferred color scheme updated successfully'),
  profilePreferredColorSchemeErrorFeedback: I18n.get('An error occured while trying to set preferred color scheme. Please try again.')
})

export default Strings
import { Fragment, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import Sections from './Sections'
import UpsertReleaseNotesForm from './UpsertReleaseNotesForm'
import TranslationsForm from './TranslationsForm'
import {
  Text,
  Button,
  Flex,
  Table,
  Slot,
  Row,
  Column,
  Cell,
  Loader
} from '@/primitives'
import { Select, Pagination } from '@/elements'

import { sendFetchReleaseNotesInit } from '@/slices/fyi/releaseNotes'
import {
  getReleaseNotes,
  getReleaseNotesInitFetched,
  getFyiTags,
  getReleaseNotesLoading
} from '@/reducers/selectors'
import { getTagOptions } from '../util'

import './index.scss'
import Strings from '../../Strings'

const strings = Strings()
const PAGE_SIZE = 10

const ReleaseNotes = () => {
  const [showUpsertForm, setShowUpsertForm] = useState(false)
  const [itemToEdit, setItemToEdit] = useState(null)
  const [showSectionsForm, setShowSectionsForm] = useState(false)
  const [showTranslationsForm, setShowTranslationsForm] = useState(false)
  const [selectedTags, setSelectedTags] = useState([])
  const [page, setPage] = useState(1)
  const [filteredReleaseNotes, setFilteredReleaseNotes] = useState([])

  const reduxDispatch = useDispatch()
  const fetched = getReleaseNotesInitFetched()
  const releaseNotes = getReleaseNotes()
  const tags = getFyiTags()
  const loading = getReleaseNotesLoading()

  if (!fetched) {
    reduxDispatch(sendFetchReleaseNotesInit())
  }

  useEffect(() => {
    if (releaseNotes?.length > 0) {
      setFilteredReleaseNotes(releaseNotes)
    }
  }, [releaseNotes, setFilteredReleaseNotes])

  const onClickSections = () => {
    setShowSectionsForm(true)
  }

  const hideSections = () => {
    setShowSectionsForm(false)
  }

  const setShowAddForm = () => setShowUpsertForm(true)
  const setHideForm = () => {
    if (showUpsertForm) setShowUpsertForm(false)
    if (showTranslationsForm) setShowTranslationsForm(false)
    if (itemToEdit) setItemToEdit(null)
  }

  function onChangeSelect(selectedItems) {
    const tagIds = selectedItems?.map(({ value }) => value)
    setSelectedTags(tagIds)

    if (tagIds?.length > 0) {
      const filteredByTags = releaseNotes.reduce((acc, releaseNote) => {
        const show = releaseNote.tags.some(tag => tagIds.includes(tag))
        if (show) {
          return [...acc, releaseNote]
        }
        return acc
      }, [])
      setFilteredReleaseNotes(filteredByTags)
    }

    if (tagIds?.length === 0) {
      setFilteredReleaseNotes(releaseNotes)
    }

    setPage(1)
  }

  function getPaginatedReleaseNotes() {
    const startIndex = page * PAGE_SIZE - PAGE_SIZE
    const endIndex = page * PAGE_SIZE
    const pageItems = filteredReleaseNotes.slice(startIndex, endIndex)
    return pageItems
  }

  function insertTableRow(releaseNote) {
    function onClickEdit() {
      setItemToEdit(releaseNote)
      setShowUpsertForm(true)
    }

    function onClickTranslations() {
      setItemToEdit(releaseNote)
      setShowTranslationsForm(true)
    }

    const { releaseNoteId, title } = releaseNote

    return (
      <Row key={releaseNoteId}>
        <Cell columnName={strings.defaultTextColHeader}>
          <Text as='h6'>{title}</Text>
        </Cell>
        <Cell columnName={strings.tableActionColumn}>
          <Flex axisGap={300}>
            <Button size='small' variant='primary' onClick={onClickEdit}>
              {strings.editButtonSentence}
            </Button>
            <Button
              size='small'
              variant='primary'
              onClick={onClickTranslations}
            >
              {strings.translations}
            </Button>
          </Flex>
        </Cell>
      </Row>
    )
  }

  return (
    <Fragment>
      <Flex alignMainAxis='space-between' alignCrossAxis='center'>
        <Text as='p'>{strings.manageReleaseNote}</Text>
        <Flex axisGap={300}>
          <Button
            size='small'
            variant='primary'
            onClick={setShowAddForm}
            iconBefore='add'
          >
            {strings.addReleaseNote}
          </Button>
          <Button size='small' variant='primary' onClick={onClickSections}>
            {strings.manageSections}
          </Button>
        </Flex>
      </Flex>
      <UpsertReleaseNotesForm
        itemToEdit={itemToEdit}
        showForm={showUpsertForm}
        setHideForm={setHideForm}
      />
      <TranslationsForm
        itemToEdit={itemToEdit}
        showForm={showTranslationsForm}
        setHideForm={setHideForm}
      />
      <Sections showForm={showSectionsForm} setHideForm={hideSections} />
      {tags?.length > 0 && (
        <Select
          name='tags'
          value={selectedTags}
          options={getTagOptions(tags)}
          placeholder={strings.filterByTags}
          controlStyles={{ marginTop: '1.5rem' }}
          onChange={onChangeSelect}
          isMulti={true}
        />
      )}
      <Loader isLoading={loading}>
        <Table style={{ marginTop: '1rem' }}>
          <Slot name='head'>
            <Row>
              <Column>{strings.title}</Column>
              <Column>{strings.tableActionColumn}</Column>
            </Row>
          </Slot>
          <Slot name='body'>
            {filteredReleaseNotes?.length > 0 &&
              getPaginatedReleaseNotes().map(releaseNote =>
                insertTableRow(releaseNote)
              )}
          </Slot>
        </Table>
      </Loader>
      {filteredReleaseNotes?.length > 0 && (
        <Pagination
          type='simple'
          totalItems={filteredReleaseNotes?.length}
          totalPages={Math.ceil(filteredReleaseNotes?.length / PAGE_SIZE)}
          setTotalPages={() => {}}
          page={page}
          setPage={setPage}
          pageSize={PAGE_SIZE}
        />
      )}
    </Fragment>
  )
}

export default ReleaseNotes

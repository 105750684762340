import { makeOperationsApiCall } from './utils'

export const getPackageUnitInventoryCalibrations = async ({
  cognitoUser,
  ...params
}) => {
  try {
    const query = /* GraphQL */ `
      query getPackageUnitInventoryCalibrations(
        $filter: GetPackageUnitInventoryCalibrationsFilter
        $order: OrderEnum
        $offset: Int
        $limit: Int
      ) {
        getPackageUnitInventoryCalibrations(
          filter: $filter
          order: $order
          offset: $offset
          limit: $limit
        ) {
          packageUnitInventoryCalibrations {
            id
            organizationId
            packageUnitInventory {
              id
              storageFacility {
                id
                name
              }
              packageUnit {
                id
                name
              }
            }
            oldQuantity
            newQuantity
            reason
            createdAt
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getPackageUnitInventoryCalibrations',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const createPackageUnitInventoryCalibration = async ({
  cognitoUser,
  ...params
}) => {
  try {
    const query = /* GraphQL */ `
      mutation createPackageUnitInventoryCalibration(
        $organizationId: String!
        $packageUnitInventoryId: String!
        $newQuantity: Int!
        $reason: String!
      ) {
        createPackageUnitInventoryCalibration(
          organizationId: $organizationId
          packageUnitInventoryId: $packageUnitInventoryId
          newQuantity: $newQuantity
          reason: $reason
        ) {
          id
          organizationId
          packageUnitInventory {
            id
            storageFacility {
              id
              name
            }
            packageUnit {
              id
              name
            }
          }
          oldQuantity
          newQuantity
          reason
          createdAt
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'createPackageUnitInventoryCalibration',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

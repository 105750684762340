import { FlexV2, Text, Label, Input } from '@/primitives'

import { DATABOARD_ACTIONS } from '../state'
import { useDataboard } from '../context'
import Strings from '../Strings'
import { COMPARISON_TYPES } from '../Utils/config'
import { getNavigationLocationSelector } from '@/reducers/selectors'

const getOptions = strings => {
  return [
    { label: strings.locationOption, value: COMPARISON_TYPES.LOCATION_RANGES },
    { label: strings.dateOption, value: COMPARISON_TYPES.DATE_RANGES }
  ]
}

export default function ComparisonSelector() {
  const { state, dispatchState } = useDataboard()

  const locationSelector = getNavigationLocationSelector()
  
  const strings = Strings()
  const options = getOptions(strings)

  const onChange = e => {
    const comparisonType = e.currentTarget.value

    dispatchState({
      type: DATABOARD_ACTIONS.UPDATE_COMPARISON_TYPE,
      params: { comparisonType, locationSelector }
    })
  }

  return (
    <FlexV2 direction='column'>
      <Text variant='page' tone={900} size={100} fontWeight={300}>
        {strings.comparisonOptions}
      </Text>
      {options.map((option, index) => (
        <Label key={index} className='Databoard__Label'>
          <Input
            type='radio'
            name='comparisonType'
            checked={option.value === state.comparisonType}
            value={option.value}
            onChange={onChange}
          />
          <Text variant='page' tone={900} size={100} fontWeight={300}>
            {option.label}
          </Text>
        </Label>
      ))}
    </FlexV2>
  )
}

import { makeActionCreator } from '@/Util/ReduxUtils'

export const REQUEST_ALL_REGIONS = 'REQUEST_ALL_REGIONS'
export const requestAllRegions = makeActionCreator(REQUEST_ALL_REGIONS)

export const RECEIVE_ALL_REGIONS = 'RECEIVE_ALL_REGIONS'
export const receiveAllRegions = makeActionCreator(
  RECEIVE_ALL_REGIONS,
  'regions',
  'count',
  'error'
)

export const REQUEST_REGIONS = 'REQUEST_REGIONS'
export const requestRegions = makeActionCreator(REQUEST_REGIONS, 'params')

export const RECEIVE_REGIONS = 'RECEIVE_REGIONS'
export const receiveRegions = makeActionCreator(
  RECEIVE_REGIONS,
  'regions',
  'count',
  'error'
)

export const CREATE_REGION = 'CREATE_REGION'
export const createRegion = makeActionCreator(CREATE_REGION, 'params')

export const REGION_CREATED = 'REGION_CREATED'
export const regionCreated = makeActionCreator(
  REGION_CREATED,
  'region',
  'error'
)

export const UPDATE_REGION = 'UPDATE_REGION'
export const updateRegion = makeActionCreator(UPDATE_REGION, 'params')

export const REGION_UPDATED = 'REGION_UPDATED'
export const regionUpdated = makeActionCreator(
  REGION_UPDATED,
  'region',
  'error'
)

export const REQUEST_REGION = 'REQUEST_REGION'
export const requestRegion = makeActionCreator(REQUEST_REGION, 'params')

export const RECEIVE_REGION = 'RECEIVE_REGION'
export const receiveRegion = makeActionCreator(
  RECEIVE_REGION,
  'region',
  'error',
  'isUpsert'
)

export const CLEAR_REGION = 'CLEAR_REGION'
export const clearRegion = makeActionCreator(CLEAR_REGION)

export const CLEAR_REGIONS = 'CLEAR_REGIONS'
export const clearRegions = makeActionCreator(CLEAR_REGIONS)

export const SET_REGION = 'SET_REGION'
export const setRegion = makeActionCreator(SET_REGION, 'region')

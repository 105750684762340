import { FlexV2 } from '@/primitives'
import './index.scss'

const Page = ({
  children,
  limitWidth = false,
  addXPadding = true,
  addYPadding = true,
  style = null
}) => {
  return (
    <FlexV2
      as={'main'}
      direction='column'
      className={['Page', { limitWidth, addXPadding, addYPadding }]}
      style={style}
    >
      {children}
    </FlexV2>
  )
}

export default Page

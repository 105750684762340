import { I18n } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { requestAllCountries } from '@/actions/operations/country'

import { getOperationsAllCountries } from '@/reducers/selectors'

import { Select } from '@/primitives'

const CountriesFilter = ({ state, dispatchState }) => {
  const dispatch = useDispatch()

  const countries = getOperationsAllCountries()

  const [countryFilter, setCountryFilter] = useState(
    state.countries.length === 1 ? state.countries[0] : 'all'
  )
  const [lastFilter, setLastFilter] = useState(countryFilter)

  useEffect(() => {
    dispatch(requestAllCountries())
  }, [dispatch])

  useEffect(() => {
    if (countryFilter === 'all') {
      if (state.countries.length === 0 && countries.length > 0) {
        dispatchChange()
      } else if (state.countries.length === 1 && countries.length > 0) {
        setLastFilter(state.countries[0])
        setCountryFilter(state.countries[0])
      }
    } else if (state.countries.length === 0) {
      setCountryFilter('all')
    }
  }, [state.countries, countries])

  useEffect(() => {
    if (countries.length > 0) {
      if (countryFilter !== lastFilter) {
        setLastFilter(countryFilter)
        dispatchChange()
      }
    }
  }, [countryFilter, countries])

  const getCountryFilter = () => {
    return countryFilter === 'all' ? countries.map(i => i.id) : [countryFilter]
  }

  const dispatchChange = () => {
    dispatchState({
      countries: getCountryFilter()
    })
  }

  const onChangeFilter = e => {
    setCountryFilter(e.currentTarget.value)
  }

  const filterCountries = () => {
    if (state.organizations?.length === 1) {
      return countries.filter(country => {
        for (let organization of state.organizations) {
          if (country.organizationId === organization) {
            return true
          }
        }
        return false
      })
    }
    return []
  }

  const options = filterCountries()

  return (
    <Select
      className='Operations__Header__Select'
      name='countryId'
      value={countryFilter}
      onChange={onChangeFilter}
      disabled={options.length === 0}
    >
      <option default value='all'>
        {I18n.get('All Countries')}
      </option>
      {options.map(({ name, id }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </Select>
  )
}

export default CountriesFilter

import { I18n } from 'aws-amplify'

const Strings = () => ({
  manageFertilizerInventory: I18n.get('Manage Fertilizer Inventory'),
  noFertilizerInventory: I18n.get('No fertilizer inventory'),
  transfers: I18n.get('Transfers'),
  purchases: I18n.get('Purchases'),
  storageFacility: I18n.get('Storage Facility'),
  fertilizer: I18n.get('Fertilizer'),
  totalWeight: I18n.get('Total Weight'),
  kg: I18n.get('kg')
})

export default Strings

import { Fragment, useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import useWebSocket, { ReadyState } from 'react-use-websocket'

import { reportGenerated } from '@/slices/report'
import { updateDeviceStatus } from '@/slices/pinger'
import { updateTranslations } from '@/slices/fyi/helpText'
import { updateLinkFromWebsocket } from '@/slices/management/whatsApp'
import { useAuth } from '@/contexts/auth-context'

const NochCenter = () => {
  const dispatch = useDispatch()
  const auth = useAuth()
  const interval = useRef(null)

  const authToken = auth.getCognitoAuthToken()
  const username = auth.getCognitoUsername()

  const getWebSocketUrl = useCallback(() => {
    return new Promise(resolve => {
      if (authToken) {
        const authUri = `authorization=${authToken}`
        const websocketUrl = `${window.ENV.WEB_SOCKET_URL}/noch?${authUri}&userId=${username}`
        resolve(websocketUrl)
      }
    })
  }, [authToken, username])

  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(
    getWebSocketUrl,
    {
      shouldReconnect: closeEvent => false // TODO: when starting to use this, we should change this to true
    }
  )

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      interval.current = setInterval(() => {
        sendJsonMessage({ action: '$default', message: 'ping' })
      }, 1000 * 60 * 5)
    }

    if (readyState === ReadyState.CLOSED) {
      clearInterval(interval.current)
      interval.current = null
    }
  }, [readyState, sendJsonMessage])

  useEffect(() => {
    if (lastMessage?.data) {
      const { action, data, userId } = JSON.parse(lastMessage?.data)

      if (action === 'reportResponse') {
        dispatch(reportGenerated(data))
      }

      if (action === 'pingResponse') {
        const parsedData = Array.isArray(data) ? data : [data]
        dispatch(updateDeviceStatus(parsedData))
      }

      if (action === 'pingVerificationResponse') {
        const devices = [...data.greengrass, ...data.directConnect]
        dispatch(updateDeviceStatus(devices))
      }

      if (action === 'helpTextTranslationsUpdated') {
        dispatch(updateTranslations(data))
      }

      if (action === 'whatsappVerification') {
        dispatch(updateLinkFromWebsocket({ userId, ...data }))
      }
    }
  }, [lastMessage, dispatch])

  return <Fragment />
}

export default NochCenter

export const DECIMAL_ACCURACY = 2
export const MAX_ZOOM = 20
export const MAX_ZOOM_ATTEMPTS = 5

export const ICON_TYPES = [
  'envirosense',
  'cellular_envirosense',
  'soilsense',
  'cellular_soilsense',
  'watersense',
  'cellular_watersense',
  'dissolved_oxygen',
  'energy_monitor'
]

export const STATUS_HIERARCHY = [
  'normal',
  'notInUse',
  'missingData',
  'notification',
  'caution',
  'warning'
]

export const sortDevicesByStatus = devices => {
  devices.sort(
    (a, b) =>
      STATUS_HIERARCHY.indexOf(b.status) - STATUS_HIERARCHY.indexOf(a.status)
  )
}

export const maxDeviceStatus = (currentStatus, newStatus) => {
  const currentStatusIndex = STATUS_HIERARCHY.indexOf(currentStatus)
  const newStatusIndex = STATUS_HIERARCHY.indexOf(newStatus)
  return STATUS_HIERARCHY[Math.max(currentStatusIndex, newStatusIndex)]
}

export const ICON_STATUS_COLORS = {
  normal: '#0974d3',
  notification: '#d3cc09',
  caution: '#d38909',
  warning: '#d32409',
  missingData: '#8909d3',
  notInUse: '#808080'
}

export const getIconColorByStatus = status => {
  if (ICON_STATUS_COLORS[status]) {
    return ICON_STATUS_COLORS[status]
  }
  return ICON_STATUS_COLORS.normal
}

export const WIDGET_STATUS_COLORS = {
  normal: '#ffffff',
  notification: 'var(--ctx-theme-contrast-warning-600)',
  caution: 'var(--ctx-theme-contrast-danger-700)',
  warning: 'var(--ctx-theme-contrast-error-600)',
  missingData: 'var(--ctx-theme-contrast-graph-600',
  notInUse: 'var(--ctx-theme-contrast-neutral-600'
}

export const getWidgetColorByStatus = status => {
  if (WIDGET_STATUS_COLORS[status]) {
    return WIDGET_STATUS_COLORS[status]
  }
  return WIDGET_STATUS_COLORS.normal
}

export const displayType = type => {
  return type.replace(/_/g, ' ')
}

export const STATUS_SYMBOL_COLOR = {
  notification: 'warning',
  caution: 'danger',
  warning: 'error',
  missingData: 'graph',
  notInUse: 'neutral'
}

export const applyConversion = (name, value, conversionLookup) => {
  const scaleFactor = conversionLookup[name]
  if (scaleFactor && scaleFactor !== 1) {
    return value * scaleFactor
  }
  return value
}

export const getMaxZoomAtLatLng = async latlng => {
  return await new Promise((resolve, reject) => {
    const maxZoomService = new window.google.maps.MaxZoomService()
    maxZoomService.getMaxZoomAtLatLng(latlng, result => {
      if (result.status !== 'OK') {
        reject()
      } else {
        resolve(result.zoom)
      }
    })
  })
}

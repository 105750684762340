import { useState, useEffect } from 'react'
import { Storage, I18n } from 'aws-amplify'
import { Button, Flex, Label, Text, Box, Loader } from '@/primitives'

const env = window.ENV.ENV
const logoTypes ={
  primary_logo: {
    width: 175,
    height: 50, // can potentially be higher?
    label: 'Primary logo (175px x 50px)'
  },
  secondary_logo: {
    width: 140,
    height: 40,
    label: 'Secondary logo (140px x 40px)'
  },
  logomark: {
    width: 32,
    height: 32,
    label: 'Logomark (32px x 32px)'
  }
}

async function uploadImage(orgId, file, fileSuffix = 'primary_logo') {
  const key = `${orgId}__${fileSuffix}.png`
  const options = {
    bucket: `coretex-management-${env}-organization-assets`,
    contentType: 'image/png'
  }

  try {
    await Storage.put(key, file, options)
  } catch (err) {
    // TODO add error handling
    // console.log('err', err)
  }
}

async function getImageURL(orgId, fileSuffix = 'primary_logo') {
  const key = `${orgId}__${fileSuffix}.png`
  const options = {
    validateObjectExistence: true,
    bucket: `coretex-management-${env}-organization-assets`,
    expires: 86400
  }

  try {
    const signedURL = await Storage.get(key, options)
    return { URL: signedURL, error: null }
  } catch (err) {
    return { URL: null, error: err?.message }
  }
}

async function deleteImage(orgId, fileSuffix = 'primary_logo') {
  const key = `${orgId}__${fileSuffix}.png`
  const options = {
    bucket: `coretex-management-${env}-organization-assets`
  }

  try {
    await Storage.remove(key, options)
    const { error } = getImageURL(orgId, fileSuffix)
    return error
  } catch (err) {
    return err
  }
}

function LogoUpsertField({ orgId, imgName }) {
  const [selectedFile, setSelectedFile] = useState(null)
  const [showImage, setShowImage] = useState(false)
  const [loading, setLoading] = useState(true)

  async function onSelectFile(e) {
    setSelectedFile(URL.createObjectURL(e.target.files[0]))
    // TODO: get size and type from file to confirm it's correct
    await uploadImage(orgId, e.target.files[0], imgName)
    // TODO: handle when upload has failed
    setShowImage(true)
  }

  async function checkForFile() {
    const { URL, error } = await getImageURL(orgId, imgName)
    if (URL && !error) {
      setSelectedFile(URL)
      setShowImage(true)
    }
    if (error) setShowImage(false)
    setLoading(false)
  }

  async function deleteFile() {
    const err = await deleteImage(orgId, imgName)
    if (!err) setSelectedFile(null)
    // TODO: if (err) dispatch banner to indicate failure to delete
  }

  useEffect(() => {
    if (orgId) checkForFile()
  }, [orgId])

  function onHasImgError(e) {
    if (showImage) setShowImage(false)
  }

  return (
    <Label className='Logo__Label'>
      <Loader isLoading={loading}>
        <Text variant='page' tone={700}>
          {logoTypes[imgName].label}
        </Text>
        <input
          type='file'
          name='label'
          onChange={onSelectFile}
          style={{ border: 'none', boxShadow: 'none', padding: '0.6rem 0.8rem' }}
        />
        <Box className='Logo__ImageContainer'>
          {(!selectedFile || !showImage) && (
            <Flex alignCrossAxis='center' alignMainAxis='center' style={{ height: '100%' }}>
              <Text variant='page' tone={600}>{I18n.get('Upload logo as PNG')}</Text>
            </Flex>
          )}
          {selectedFile && (
            <Box className='Logo__ImageContainer__Preview' style={{ opacity: showImage ? 1 : 0 }}>
              <Box>
                <img src={selectedFile} alt={logoTypes[imgName].label} onError={onHasImgError} />
              </Box>
              <Button onClick={deleteFile} size='small' iconBefore='close' variant='error' style={{ position: 'absolute', right: '0', padding: '5px', margin: '5px'}} />
            </Box>
          )}
        </Box>
      </Loader>
    </Label>
  )
}

export default LogoUpsertField
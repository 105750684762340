import { updateList } from '../util'

import {
  REQUEST_BRANCHES,
  RECEIVE_BRANCHES,
  REQUEST_BRANCH,
  RECEIVE_BRANCH,
  CREATE_BRANCH,
  BRANCH_CREATED,
  UPDATE_BRANCH,
  BRANCH_UPDATED
} from '@/actions/operations/branch'

export const branchInitialState = {
  branches: [],
  branchesCount: 0,
  branch: {},
  loading: false,
  error: null
}

const handlers = {
  [REQUEST_BRANCHES]: (state, action) => ({
    ...state,
    branches: [],
    branchesCount: 0,
    error: null,
    loading: true
  }),
  [RECEIVE_BRANCHES]: (state, action) => ({
    ...state,
    branches: !action.error ? action.branches : [],
    branchesCount: action?.count ?? 0,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_BRANCH]: state => ({
    ...state,
    branch: {},
    error: null,
    loading: true
  }),
  [RECEIVE_BRANCH]: (state, action) => ({
    ...state,
    branch: !action.error ? action.branch : state.branch,
    error: action.error ?? null,
    loading: false
  }),
  [CREATE_BRANCH]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [BRANCH_CREATED]: (state, action) => ({
    ...state,
    branch: !action.error ? {} : state.branch,
    branches: updateList(action.error, state.branches, action.branch, true),
    branchesCount: !action.error
      ? state.branchesCount + 1
      : state.branchesCount,
    error: action.error ?? null,
    loading: false
  }),
  [UPDATE_BRANCH]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [BRANCH_UPDATED]: (state, action) => ({
    ...state,
    branch: !action.error ? {} : state.branch,
    branches: updateList(action.error, state.branches, action.branch, false),
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

import { useReducer, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import DatePicker from 'react-datepicker'
import ReactSelect from 'react-select'

import useDeviceSize from '@/hooks/useDeviceSize'

import { requestAllCountries } from '@/actions/operations/country'
import { requestAllRegions } from '@/actions/operations/region'
import { requestAllCurrencies } from '@/actions/operations/currency'
import { requestAllPaymentTerms } from '@/actions/operations/paymentTerm'
import { requestAllPaymentMethods } from '@/actions/operations/paymentMethod'

import {
  createSupplier,
  requestSupplier,
  updateSupplier
} from '@/actions/operations/supplier'

import { cleanS3Key } from '@/Util/StorageUtils'

import {
  getOperationsAllCountries,
  getOperationsAllRegions,
  getOperationsAllCurrencies,
  getOperationsAllPaymentMethods,
  getOperationsAllPaymentTerms,
  getOperationsSupplier,
  getOperationsLoading,
  getOperationsError
} from '@/reducers/selectors'

import {
  Box,
  Flex,
  Label,
  Select,
  Text,
  Separator,
  Input,
  Button,
  Loader
} from '@/primitives'

import { getValidationErrorMap } from '@/Util/GeneralUtils'

import InputError from '@/elements/InputError'
import DocumentUploader from '@/elements/DocumentUploader'

import OrganizationInput from '../../../Shared/Organization/Input'
import HeaderV2 from '@/components/Operations/Shared/HeaderV2'

import Address from './Address'
import BankDetails from './BankDetails'

import history from '../../../../../history'
import Strings from '../Strings'

import { SUPPLIER_STATUSES, SUPPLIER_CATEGORIES, SUPPLY_TYPES } from '../utils'

import { FIELDS, supplierSchema } from './config'

import {
  initialState,
  reducer,
  UPDATE_INPUT,
  SET_STATE,
  DESELECT_FILE,
  SELECT_FILE,
  UPDATE_ADDRESS,
  UPDATE_BANK_DETAILS,
  RESET_SECTION
} from './state'

import './index.scss'

function SuppliersForm({ state, modulePath }) {
  const dispatch = useDispatch()
  const isMobile = useDeviceSize('mobile')
  const isLaptop = useDeviceSize()
  const { itemId } = useParams()

  const strings = Strings()

  const countries = getOperationsAllCountries()
  const regions = getOperationsAllRegions()
  const paymentMethods = getOperationsAllPaymentMethods()
  const paymentTerms = getOperationsAllPaymentTerms()
  const currencies = getOperationsAllCurrencies()
  const supplier = getOperationsSupplier()
  const loading = getOperationsLoading()
  const error = getOperationsError()

  const supplierCategories = SUPPLIER_CATEGORIES(strings)
  const supplierStatuses = SUPPLIER_STATUSES(strings)
  const supplyTypes = SUPPLY_TYPES(strings)

  const [formState, dispatchFormState] = useReducer(reducer, initialState)
  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)

  const [showAddress, setShowAddress] = useState(false)
  const [showBankDetails, setShowBankDetails] = useState(false)

  useEffect(() => {
    dispatch(requestAllCountries())
    dispatch(requestAllPaymentMethods())
    dispatch(requestAllPaymentTerms())
    dispatch(requestAllCurrencies())
    dispatch(requestAllRegions())
  }, [dispatch])

  useEffect(() => {
    if (itemId && supplier.id !== itemId) {
      dispatch(requestSupplier({ supplierId: itemId }))
    }
  }, [itemId])

  useEffect(() => {
    if (supplier?.id && supplier.id === itemId) {
      dispatchFormState({
        type: SET_STATE,
        state: { ...supplier }
      })
    }
  }, [supplier])

  useEffect(() => {
    if (!itemId && state.organizations.length === 1) {
      dispatchFormState({
        type: UPDATE_INPUT,
        name: FIELDS.organizationId,
        value: state.organizations[0]
      })
    }
  }, [state.organizations])

  useEffect(() => {
    if (!!formState.address.streetAddress || formState.addressDocument?.s3Key) {
      setShowAddress(true)
    }
  }, [formState.address, formState.addressDocument?.s3Key])

  useEffect(() => {
    if (
      !!formState.bankDetails.bankName ||
      formState.bankDetailsDocument?.s3Key
    ) {
      setShowBankDetails(true)
    }
  }, [formState.bankDetails, formState.bankDetailsDocument?.s3Key])

  useEffect(() => {
    if (waiting && !loading && !error) {
      history.replace(modulePath)
    }
  }, [loading])

  const onSubmit = async e => {
    try {
      const payload = await supplierSchema().validate(formState, {
        abortEarly: false
      })
      setWaiting(true)

      if (itemId) {
        payload.supplierId = itemId
        dispatch(updateSupplier(payload))
      } else {
        dispatch(createSupplier(payload))
      }
    } catch (error) {
      setErrors(getValidationErrorMap(error))
    }
  }

  const handleInput = e => {
    const { name, value } = e.currentTarget
    dispatchFormState({ type: UPDATE_INPUT, name, value })
  }

  const handleDateInput = (date, name) => {
    dispatchFormState({ type: UPDATE_INPUT, name, value: date })
  }

  const handleOrganizationInput = organizationId => {
    dispatchFormState({
      type: SET_STATE,
      state: {
        ...formState,
        organizationId,
        paymentMethodId: '',
        paymentTermId: '',
        countryId: '',
        regionId: '',
        cityId: '',
        currencyId: ''
      }
    })
  }

  const onSetFile = (name, file) => {
    dispatchFormState({ type: SELECT_FILE, name, file })
  }

  const onDeselectFile = name => {
    dispatchFormState({ type: DESELECT_FILE, name })
  }

  const onHandleAddress = e => {
    const { name, value } = e.currentTarget
    dispatchFormState({ type: UPDATE_ADDRESS, name, value })
  }

  const onHandleBankDetails = (e, isBranchAddress) => {
    const { name, value } = e.currentTarget
    dispatchFormState({
      type: UPDATE_BANK_DETAILS,
      name,
      value,
      isBranchAddress
    })
  }

  const handleSupplyTypesInput = options => {
    const newValues = options.map(o => o.value)
    dispatchFormState({
      type: UPDATE_INPUT,
      name: 'supplyTypes',
      value: newValues
    })
  }

  const getSupplyTypesValue = () => {
    return Object.entries(supplyTypes)
      .filter(s => formState.supplyTypes.indexOf(s[0]) >= 0)
      .map(s => ({
        value: s[0],
        label: s[1]
      }))
  }

  const handleSection = e => {
    const {
      name,
      dataset: { action }
    } = e.currentTarget
    const isShow = action === 'add'
    if (name === 'address') {
      setShowAddress(isShow)
    }
    if (name === 'bankDetails') {
      setShowBankDetails(isShow)
    }
    dispatchFormState({ type: RESET_SECTION, section: name })
  }

  const filterCountries = () => {
    return countries.filter(c => c.organizationId === formState.organizationId)
  }

  const filterRegions = () => {
    return regions.filter(r => r.countryId === formState.countryId)
  }

  const filterSupplyTypes = () => {
    return Object.entries(supplyTypes).map(s => ({
      value: s[0],
      label: s[1]
    }))
  }

  const filterPaymentMethods = () => {
    return paymentMethods.filter(
      p => p.organizationId === formState.organizationId
    )
  }

  const filterPaymentTerms = () => {
    return paymentTerms.filter(
      p => p.organizationId === formState.organizationId
    )
  }

  const filterCurrencies = () => {
    return currencies.filter(c => c.organizationId === formState.organizationId)
  }

  const getHeader = () => {
    if (itemId) {
      return strings.updateFormHeader
    }
    return strings.createFormHeader
  }

  const SectionButton = ({ show, name, addString, deleteString }) => {
    return (
      <Flex alignMainAxis='flex-end'>
        <Button
          className='SectionButton'
          onClick={handleSection}
          size='small'
          name={name}
          data-action={!show ? 'add' : 'delete'}
          type='button'
          variant={!show ? 'primary' : 'error'}
        >
          {!show ? addString : deleteString}
        </Button>
      </Flex>
    )
  }

  return (
    <Flex
      as='form'
      axisGap={400}
      direction='column'
      className='Operations__Form'
    >
      <HeaderV2
        title={getHeader()}
        backPath={modulePath}
        buttonIcon={'save'}
        buttonText={strings.saveSupplier}
        buttonCallback={onSubmit}
      />
      <Loader isLoading={loading}>
        <Flex axisGap={800} direction={isLaptop ? 'column' : null}>
          <Flex
            axisGap={400}
            direction='column'
            className='Operations__Form__Fields'
          >
            <Box>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.supplier}
              </Text>
              <OrganizationInput
                fieldName={FIELDS.organizationId}
                organizationId={formState.organizationId}
                handleInput={handleOrganizationInput}
                errors={errors}
              />
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: 3 }}>
                  <Text variant='page' tone={700}>
                    {strings.name}
                  </Text>
                  <Input
                    value={formState.name}
                    name={FIELDS.name}
                    onChange={handleInput}
                  />
                  <InputError error={errors?.name} />
                </Label>
                <Label style={{ flex: 1 }}>
                  <Text variant='page' tone={700}>
                    {strings.code}
                  </Text>
                  <Input
                    value={formState.code}
                    name={FIELDS.code}
                    onChange={handleInput}
                  />
                  <InputError error={errors?.code} />
                </Label>
              </Flex>
              <Flex axisGap={300} alignMainAxis='space-evenly'>
                <Label style={{ flex: 1 }}>
                  <Text variant='page' tone={700}>
                    {strings.category}
                  </Text>
                  <Select
                    className='Operations__Form__Select'
                    name={FIELDS.category}
                    value={formState.category}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {Object.entries(supplierCategories).map(([id, name]) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.category} />
                </Label>
                <Label style={{ flex: 1 }}>
                  <Text variant='page' tone={700}>
                    {strings.status}
                  </Text>
                  <Select
                    className='Operations__Form__Select'
                    name={FIELDS.status}
                    value={formState.status}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {Object.entries(supplierStatuses).map(([id, name]) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.status} />
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: '3 1 0' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.supplyTypes}
                    </Text>
                    <ReactSelect
                      className='Operations__Form__Select'
                      classNamePrefix={'ReactSelect__Multi'}
                      name={FIELDS.supplyTypes}
                      value={getSupplyTypesValue()}
                      onChange={handleSupplyTypesInput}
                      isMulti={true}
                      placeholder={strings.selectDefault}
                      options={filterSupplyTypes()}
                      isSearchable={true}
                    />
                    <InputError error={errors?.supplyTypes} />
                  </Flex>
                </Label>
              </Flex>
              <Flex axisGap={300} alignMainAxis='space-evenly'>
                <Label style={{ flex: '1 1 0' }}>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.onboardDate}
                    </Text>
                    <DatePicker
                      maxDate={new Date()}
                      selected={formState.onboardDate}
                      className='Operations__Form__Select'
                      onChange={date =>
                        handleDateInput(date, FIELDS.onboardDate)
                      }
                    />
                    <InputError error={errors?.onboardDate} />
                  </Flex>
                </Label>
                <Label>
                  <Text variant='page' fontWeight={500} tone={700}>
                    {strings.registrationNumber}
                  </Text>
                  <Input
                    value={formState.registrationNumber}
                    name={FIELDS.registrationNumber}
                    onChange={handleInput}
                  />
                  <InputError error={errors?.registrationNumber} />
                </Label>
              </Flex>
              <Flex axisGap={300} alignMainAxis='space-evenly'>
                <Label style={{ flex: 1 }}>
                  <Text variant='page' tone={700}>
                    {strings.country}
                  </Text>
                  <Select
                    className='Operations__Form__Select'
                    name={FIELDS.countryId}
                    value={formState.countryId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterCountries().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.countryId} />
                </Label>
                <Label style={{ flex: 1 }}>
                  <Text variant='page' tone={700}>
                    {strings.region}
                  </Text>
                  <Select
                    className='Operations__Form__Select'
                    name={FIELDS.regionId}
                    value={formState.regionId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.allRegions}
                    </option>
                    {filterRegions().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.regionId} />
                </Label>
              </Flex>
            </Box>
            <Box className='contact'>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.contact}
              </Text>
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: 1 }}>
                  <Text variant='page' tone={700}>
                    {strings.contactName}
                  </Text>
                  <Input
                    value={formState.contactName}
                    name={FIELDS.contactName}
                    onChange={handleInput}
                  />
                  <InputError error={errors?.contactName} />
                </Label>
                <Label style={{ flex: 1 }}>
                  <Text variant='page' tone={700}>
                    {strings.contactNumber}
                  </Text>
                  <Input
                    value={formState.contactNumber}
                    name={FIELDS.contactNumber}
                    onChange={handleInput}
                  />
                  <InputError error={errors?.contactNumber} />
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label style={{ flex: 1 }}>
                  <Text variant='page' tone={700}>
                    {strings.contactEmail}
                  </Text>
                  <Input
                    value={formState.contactEmail}
                    name={FIELDS.contactEmail}
                    onChange={handleInput}
                    type='email'
                  />
                  <InputError error={errors?.contactEmail} />
                </Label>
                <Label style={{ flex: 1 }}>
                  <Text variant='page' tone={700}>
                    {strings.website}
                  </Text>
                  <Input
                    value={formState.website}
                    name={FIELDS.website}
                    onChange={handleInput}
                    type='url'
                  />
                  <InputError error={errors?.website} />
                </Label>
              </Flex>
            </Box>
            <Box className='payment'>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.payment}
              </Text>
              <Flex axisGap={300} alignMainAxis='space-evenly'>
                <Label style={{ flex: 1 }}>
                  <Text variant='page' fontWeight={500} tone={700}>
                    {strings.taxNumber}
                  </Text>
                  <Input
                    value={formState.taxNumber}
                    name={FIELDS.taxNumber}
                    onChange={handleInput}
                  />
                  <InputError error={errors?.taxNumber} />
                </Label>
                <Label style={{ flex: 1 }}>
                  <Text variant='page' tone={700}>
                    {strings.paymentMethod}
                  </Text>
                  <Select
                    className='Operations__Form__Select'
                    name={FIELDS.paymentMethodId}
                    value={formState.paymentMethodId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterPaymentMethods().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.paymentMethodId} />
                </Label>
              </Flex>
              <Flex axisGap={300} alignMainAxis='space-evenly'>
                <Label style={{ flex: 1 }}>
                  <Text variant='page' tone={700}>
                    {strings.paymentTerm}
                  </Text>
                  <Select
                    className='Operations__Form__Select'
                    name={FIELDS.paymentTermId}
                    value={formState.paymentTermId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterPaymentTerms().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.paymentTermId} />
                </Label>
                <Label style={{ flex: 1 }}>
                  <Text variant='page' tone={700}>
                    {strings.currency}
                  </Text>
                  <Select
                    className='Operations__Form__Select'
                    name={FIELDS.currencyId}
                    value={formState.currencyId}
                    onChange={handleInput}
                  >
                    <option default value=''>
                      {strings.selectDefault}
                    </option>
                    {filterCurrencies().map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <InputError error={errors?.currencyId} />
                </Label>
              </Flex>
            </Box>
            <Separator />
            <Box className='contract'>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.contract}
              </Text>
              <Flex
                axisGap={300}
                direction='column'
                style={{ marginTop: '1em' }}
              >
                <Flex
                  axisGap={300}
                  alignMainAxis='space-evenly'
                  direction='column'
                >
                  <Box>
                    <Text variant='page' fontWeight={500} tone={700}>
                      {strings.contractDocument}
                    </Text>
                    <DocumentUploader
                      name='contractDocument'
                      file={formState.contractDocument.file}
                      fileUrl={formState.contractDocument.url}
                      fileName={cleanS3Key(formState.contractDocument.s3Key)}
                      onSetFile={onSetFile}
                      onDeselectFile={onDeselectFile}
                      setErrors={setErrors}
                    />
                    <InputError error={errors?.registrationDocument} />
                  </Box>
                </Flex>
              </Flex>
            </Box>
            <Separator />
            <Box className='address'>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.address}
              </Text>
              {showAddress && (
                <Flex
                  axisGap={400}
                  alignMainAxis='space-between'
                  direction='column'
                >
                  <Address
                    state={formState.address}
                    fields={FIELDS.address}
                    errors={errors?.address}
                    handleInput={onHandleAddress}
                  />
                </Flex>
              )}
              <SectionButton
                name='address'
                show={showAddress}
                addString={strings.addAddress}
                deleteString={strings.deleteAddress}
              />
            </Box>
            <Separator />
            <Box className='bankDetails'>
              <Text size={400} fontWeight={500} tone={600}>
                {strings.bankDetails}
              </Text>
              {showBankDetails && (
                <Flex direction='column'>
                  <BankDetails
                    state={formState.bankDetails}
                    fields={FIELDS.bankDetails}
                    errors={errors?.bankDetails}
                    handleInput={onHandleBankDetails}
                  />
                  <Box>
                    <Text variant='page' fontWeight={500} tone={700}>
                      {strings.bankDetailsDocument}
                    </Text>
                    <DocumentUploader
                      name='bankDetailsDocument'
                      file={formState.bankDetailsDocument.file}
                      fileUrl={formState.bankDetailsDocument.url}
                      fileName={cleanS3Key(formState.bankDetailsDocument.s3Key)}
                      onSetFile={onSetFile}
                      onDeselectFile={onDeselectFile}
                      setErrors={setErrors}
                    />
                    <InputError error={errors?.bankDetailsDocument} />
                  </Box>
                </Flex>
              )}
              <SectionButton
                name='bankDetails'
                show={showBankDetails}
                addString={strings.addBankDetails}
                deleteString={strings.deleteBankDetails}
              />
            </Box>
          </Flex>
        </Flex>
      </Loader>
    </Flex>
  )
}
export default SuppliersForm

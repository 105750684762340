import { createContext, useContext } from 'react'

export const FleetManagementContext = createContext({})

/**
 * Access the FleetManagementContext and return the state and dispatchState
 * Needs to be used within a FleetManagementProvider
 * @returns {{ state: object, dispatchState: function }}
 */
export function useFleetManagement() {
  const context = useContext(FleetManagementContext)

  if (context === undefined) {
    throw new Error(
      'useFleetManagement must be used within a FleetManagementProvider'
    )
  }

  return context
}

import {
  CLEAN_OPERATIONS_DASHBOARD_DATA,
  RECEIVE_OPERATIONS_DASHBOARD_DATA
} from '@/actions/operations/dashboard'

import { createReducer } from '@/Util/ReduxUtils'

const getFiltersHash = filters => {
  return `${filters.range}#${filters.interval || 'no-interval'}#${
    filters.produceId || 'no-produce-id'
  }`
}

const initialState = {
  data: {},
  error: null
}

const handlers = {
  [CLEAN_OPERATIONS_DASHBOARD_DATA]: (state, action) => ({
    data: {},
    error: null
  }),
  [RECEIVE_OPERATIONS_DASHBOARD_DATA]: (state, action) => {
    let dataCopy = { ...state.data }
    const {
      data,
      params: { segment, tileType, queryType, filters, queryZonesHash },
      error
    } = action

    if (!dataCopy[queryZonesHash]) {
      dataCopy[queryZonesHash] = {}
    }

    if (!dataCopy[queryZonesHash][segment]) {
      dataCopy[queryZonesHash][segment] = {}
    }

    if (!dataCopy[queryZonesHash][segment][queryType]) {
      dataCopy[queryZonesHash][segment][queryType] = {}
    }

    if (!dataCopy[queryZonesHash][segment][queryType][tileType]) {
      dataCopy[queryZonesHash][segment][queryType][tileType] = {}
    }
    const filterHash = getFiltersHash(filters)
    if (!dataCopy[queryZonesHash][segment][queryType][tileType][filterHash]) {
      dataCopy[queryZonesHash][segment][queryType][tileType][filterHash] = {}
    }

    dataCopy[queryZonesHash][segment][queryType][tileType][filterHash] = data

    return { ...state, data: dataCopy, error: error ?? null }
  }
}

export default createReducer(handlers, initialState)

import { updateList } from '../util'

import {
  REQUEST_CROPS,
  RECEIVE_CROPS,
  REQUEST_CROP,
  RECEIVE_CROP,
  CLEAR_ALL_CROPS,
  REQUEST_ALL_CROPS,
  RECEIVE_ALL_CROPS,
  CREATE_CROP,
  CROP_CREATED,
  UPDATE_CROP,
  CROP_UPDATED
} from '@/actions/operations/crop'

export const cropInitialState = {
  allCrops: [],
  crops: [],
  cropsCount: 0,
  crop: {},
  loading: false,
  error: null
}

const handlers = {
  [CLEAR_ALL_CROPS]: state => ({
    ...state,
    allCrops: []
  }),
  [REQUEST_ALL_CROPS]: (state, action) => ({
    ...state,
    allCrops: [],
    error: null,
    loading: true
  }),
  [RECEIVE_ALL_CROPS]: (state, action) => ({
    ...state,
    allCrops: !action.error ? action.crops : [],
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_CROPS]: (state, action) => ({
    ...state,
    crops: [],
    cropsCount: 0,
    error: null,
    loading: true
  }),
  [RECEIVE_CROPS]: (state, action) => ({
    ...state,
    crops: !action.error ? action.crops : [],
    cropsCount: action?.count ?? 0,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_CROP]: state => ({
    ...state,
    crop: {},
    error: null,
    loading: true
  }),
  [RECEIVE_CROP]: (state, action) => ({
    ...state,
    crop: !action.error ? action.crop : state.crop,
    error: action.error ?? null,
    loading: false
  }),
  [CREATE_CROP]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [CROP_CREATED]: (state, action) => ({
    ...state,
    crop: !action.error ? {} : state.crop,
    crops: updateList(action.error, state.crops, action.crop, true),
    cropsCount: !action.error ? state.cropsCount + 1 : state.cropsCount,
    error: action.error ?? null,
    loading: false
  }),
  [UPDATE_CROP]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [CROP_UPDATED]: (state, action) => ({
    ...state,
    crop: !action.error ? {} : state.crop,
    crops: updateList(action.error, state.crops, action.crop, false),
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

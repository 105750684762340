import Select from '@/elements/Select'
import classNames from 'classnames'
import { FlexV2, Symbol, Text } from '@/primitives'

import style from './SymbolOption.module.scss'

export const SymbolOption = ({ innerProps, data, symbol }) => {
  if (data?.value === 'header') {
    return (
      <FlexV2
        className={style.optionHeader}
        alignCrossAxis='center'
        axisGap={300}
        {...innerProps}
      >
        <Symbol name={data.symbol} size={300} variant='page' tone={900} />
        <Text
          variant='page'
          tone={900}
          size={200}
          style={{ fontSize: '0.9em' }}
        >
          {data.label}
        </Text>
      </FlexV2>
    )
  }

  return (
    <FlexV2
      className={classNames(style.option, data.isCurrent && style.current)}
      alignCrossAxis='center'
      axisGap={300}
      {...innerProps}
    >
      <Symbol
        name={symbol ?? data.symbol}
        size={300}
        variant='page'
        tone={700}
      />
      <Text variant='page' tone={700} size={200} style={{ fontSize: '0.9em' }}>
        {data.label}
      </Text>
    </FlexV2>
  )
}
export const withSymbol = symbol => WrappedComponent => props =>
  <WrappedComponent {...props} symbol={symbol} />

export default function LayoutSelect({
  minWidth,
  placeholder,
  options,
  value,
  onChange,
  optionSymbol = null
}) {
  const Option = withSymbol(optionSymbol)(SymbolOption)

  if (options.length === 0) {
    return null
  }

  return (
    <Select
      placeholder={placeholder}
      options={options}
      value={value}
      onChange={onChange}
      components={{
        Option
      }}
      controlStyles={{
        backgroundColor: 'var(--ctx-theme-color-page-300)',
        border: '1px solid var(--ctx-theme-color-page-300)',
        fontSize: '0.9rem',
        minHeight: 'min-content',
        height: 'min-content',
        minWidth: minWidth || '270px',
        padding: '2px 5px',
        ':hover': {
          borderColor: 'var(--ctx-theme-color-page-500)'
        }
      }}
      menuListStyles={{
        borderRadius: '5px',
        padding: '0.5em 0'
      }}
      menuStyles={{
        zIndex: 1,
        marginTop: '4px',
        backgroundColor: 'transparent',
        borderRadius: '5px'
      }}
      valueContainerStyles={{
        paddingLeft: '4px'
      }}
      dropdownIndicatorStyles={{
        padding: 0,
        paddingRight: '5px',
        color: 'var(--ctx-theme-color-page-600)'
      }}
    />
  )
}

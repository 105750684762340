import { makeOperationsApiCall } from './utils'

export const getPackageUnitInventories = async params => {
  try {
    const query = /* GraphQL */ `
      query getPackageUnitInventories(
        $filter: GetPackageUnitInventoriesFilter
        $order: OrderEnum
        $offset: Int
        $limit: Int
      ) {
        getPackageUnitInventories(
          filter: $filter
          order: $order
          offset: $offset
          limit: $limit
        ) {
          packageUnitInventories {
            id
            organizationId
            storageFacility {
              id
              name
            }
            packageUnit {
              id
              name
            }
            quantity
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getPackageUnitInventories',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getPackageUnitInventory = async params => {
  try {
    const query = /* GraphQL */ `
      query getPackageUnitInventory($packageUnitInventoryId: String!) {
        getPackageUnitInventory(
          packageUnitInventoryId: $packageUnitInventoryId
        ) {
          id
          organizationId
          storageFacility {
            id
            name
          }
          packageUnit {
            id
            name
          }
          quantity
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getPackageUnitInventory',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

import { getZonePathFromLocationSelector } from '../../../Utils'

export const sensor = ({ level, locationSelector, permissions, strings }) => {
  const zone = getZonePathFromLocationSelector({ level, locationSelector })
  const sensor = locationSelector.get('sensor')

  const routes = [
    {
      to: `/zones/${zone}/sensor/${sensor.type}/${sensor.id}`,
      text: strings.dashboard,
      icon: 'dashboard',
      activeOnly: true
    },
    {
      to: `/zones/${zone}/sensor/${sensor.type}/${sensor.id}/data`,
      text: strings.databoard,
      icon: 'insights',
      activeOnly: true
    },
    {
      to: `/zones/${zone}/sensor/${sensor.type}/${sensor.id}/calibration`,
      text: strings.calibration,
      icon: 'rule settings',
      visible: permissions.sensor_calibration,
      activeOnly: true
    }
  ]

  return {
    text: locationSelector.get(level).name,
    title: strings[level],
    icon: 'sensors',
    visible: permissions.sensor_management,
    routes: routes.filter(r => !r.activeOnly || sensor.status === 'active')
  }
}

import { I18n } from 'aws-amplify'
import { object, string, number } from 'yup'
import { useEffect, useState, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  createCurrency,
  requestCurrency,
  updateCurrency
} from '@/actions/operations/currency'

import {
  getOperationsCurrency,
  getOperationsLoading,
  getOperationsError
} from '@/reducers/selectors'

import { Flex, Box, Form, Input, Label, Text, Loader } from '@/primitives'

import InputError from '@/elements/InputError'

import HeaderV2 from '@/components/Operations/Shared/HeaderV2'
import OrganizationInput from '../../Shared/Organization/Input'

import { getValidationErrorMap } from '@/Util/GeneralUtils'
import history from '../../../../history'

import { formInitalState, formReducer } from './state'

const SCHEMA = object({
  organizationId: string().required(I18n.get('An organization is required.')),
  name: string().required(I18n.get('Name is required.')),
  code: string().required(I18n.get('Code is required.')),
  usdRate: number().required(I18n.get('USD Rate is required.'))
})

function CurrencyForm({ state, modulePath }) {
  const dispatch = useDispatch()
  const { itemId } = useParams()

  const currency = getOperationsCurrency()
  const loading = getOperationsLoading()
  const error = getOperationsError()

  const [formState, dispatchFormState] = useReducer(
    formReducer,
    formInitalState
  )

  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)

  useEffect(() => {
    if (itemId && currency.id !== itemId) {
      dispatch(requestCurrency({ currencyId: itemId }))
    }
  }, [dispatch, currency?.id, itemId])

  useEffect(() => {
    if (currency?.id && currency.id === itemId) {
      dispatchFormState({ type: 'set-state', state: currency })
    }
  }, [currency, itemId])

  useEffect(() => {
    if (!itemId && state.organizations.length === 1) {
      dispatchFormState({
        type: 'update',
        name: 'organizationId',
        value: state.organizations[0]
      })
    }
  }, [state.organizations, itemId])

  useEffect(() => {
    if (waiting && !loading && !error) {
      history.replace(modulePath)
    }
  }, [waiting, loading, error, modulePath])

  const onSubmit = async e => {
    e?.preventDefault()
    try {
      await SCHEMA.validate(formState, { abortEarly: false })
      setWaiting(true)

      if (itemId) {
        formState.currencyId = itemId
        dispatch(updateCurrency(formState))
      } else {
        dispatch(createCurrency(formState))
      }
    } catch (error) {
      setErrors(getValidationErrorMap(error))
    }
  }

  const handleOrganizationInput = organizationId => {
    dispatchFormState({
      type: 'update',
      name: 'organizationId',
      value: organizationId
    })
  }

  const onChange = e => {
    let { name, value } = e.currentTarget
    if (name === 'usdRate') {
      value = Number(value)
    }
    dispatchFormState({ type: 'update', name, value })
  }

  return (
    <Form className='Operations__Form'>
      <HeaderV2
        title={I18n.get('Manage Currency')}
        buttonIcon={'save'}
        buttonText={I18n.get('Save Currency')}
        buttonCallback={onSubmit}
        backPath={modulePath}
      />
      <Loader isLoading={loading}>
        <OrganizationInput
          fieldName={'organizationId'}
          organizationId={formState.organizationId}
          handleInput={handleOrganizationInput}
          errors={errors}
        />
        <Flex axisGap={300} className='Operations__Form__Fields'>
          <Label>
            <Text variant='page' tone={700}>
              {I18n.get('Name')}
            </Text>
            <Input value={formState.name} name='name' onChange={onChange} />
            <InputError error={errors?.name} />
          </Label>
          <Label>
            <Text variant='page' tone={700}>
              {I18n.get('Code')}
            </Text>
            <Input value={formState.code} name='code' onChange={onChange} />
            <InputError error={errors?.code} />
          </Label>
        </Flex>
        <Flex axisGap={300} className='Operations__Form__Fields'>
          <Label style={{ flexGrow: 0 }}>
            <Text variant='page' tone={700}>
              {I18n.get('USD Rate')}
            </Text>
            <Box className='Operations__Input__Container'>
              <Input
                value={formState.usdRate}
                name='usdRate'
                onChange={onChange}
              />
              <span className='Operations__Input__Suffix'>
                {I18n.get('USD')}
              </span>
            </Box>
            <InputError error={errors?.usdRate} />
          </Label>
        </Flex>
      </Loader>
    </Form>
  )
}

export default CurrencyForm

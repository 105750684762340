import { Fragment } from 'react'

import { Text, Icon } from '@/primitives'

export const getInputClassName = (fieldName, errors) => {
  const baseClass = 'Auth__Form__Input'
  if (errors.hasOwnProperty(fieldName)) {
    return `${baseClass}__Error`
  }

  return baseClass
}

export function insertError(error) {
  if (!error) return null

  return (
    <Fragment>
      <Icon
        className='Auth__Form__InputErrorIcon'
        name='error'
        variant='error'
        tone={500}
      />
      <Text className='SignIn__Form__InputError' variant='error'>
        {error}
      </Text>
    </Fragment>
  )
}

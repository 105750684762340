import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { getCognitoData, makeGraphqlRequest } from '../utils'

/*
 * Queries
 */

export const getAllMeasurements = async () => {
  try {
    const query = /* GraphQL */ `
      query getAllMeasurements {
        getAllMeasurements {
          id
          description
          shortName
          unit
          thresholds
          type
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, {})
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        measurements: null,
        error: `Measurements not fetched: ${error}`
      }
    }

    return { measurements: data?.getAllMeasurements, error: null }
  } catch (err) {
    return { measurements: null, error: err.message }
  }
}

export const createMeasurement = async params => {
  try {
    const query = /* GraphQL */ `
      mutation createMeasurement(
        $type: String!
        $id: String!
        $description: String!
        $unit: String!
        $thresholds: [Float]
        $shortName: String!
      ) {
        createMeasurement(
          type: $type
          id: $id
          description: $description
          unit: $unit
          thresholds: $thresholds
          shortName: $shortName
        ) {
          id
          description
          unit
          thresholds
          shortName
          type
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        measurement: null,
        error: `Measurement not created: ${error}`
      }
    }

    return { measurement: data?.createMeasurement, error: null }
  } catch (err) {
    return { measurement: null, error: err.message }
  }
}

export const updateMeasurement = async params => {
  try {
    const query = /* GraphQL */ `
      mutation updateMeasurement(
        $type: String!
        $id: String!
        $description: String!
        $unit: String!
        $thresholds: [Float]
        $shortName: String!
      ) {
        updateMeasurement(
          type: $type
          id: $id
          description: $description
          unit: $unit
          thresholds: $thresholds
          shortName: $shortName
        ) {
          id
          description
          unit
          thresholds
          shortName
          type
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        measurement: null,
        error: `Measurement not updated: ${error}`
      }
    }

    return { measurement: data?.updateMeasurement, error: null }
  } catch (err) {
    return { measurement: null, error: err.message }
  }
}

import { Fragment, useReducer, useEffect, Children, useState } from 'react'

import { Collapse, Slot, Text, FlexV2 } from '@/primitives'
import { Tabs } from '@/elements'

import EditRoleSectionItem from './EditRoleSectionItem'

import {
  getRolesError,
  getCurrentUser,
  getCurrentPermission
} from '@/reducers/selectors'
import {
  hasEditPermissions,
  RESOURCE_TYPE_USER,
  RESOURCE_TYPE_USER_ROLES
} from '@/Util/PermissionUtils'

import ControlTrigger from './ControlTrigger'
import Strings from '../Strings'

import './index.scss'

const getSectionStatus = permissions => {
  const enabled =
    permissions.filter(
      permissions => !permissions.resourceId && permissions.enabled
    ).length > 0
  return enabled ? 'enabled' : 'disabled'
}

const getStatus = (permissions, permissionType, generalIndex) => {
  if (Number.isInteger(generalIndex) && permissions[generalIndex]?.enabled)
    return 'enabled'

  const enabledSub = permissions.filter(
    ({ operationType, enabled, resourceId }) =>
      operationType === permissionType && enabled && resourceId
  )
  return enabledSub.length === 0 ? 'disabled' : 'indeterminate'
}

const sortByResource = updatedPermissions =>
  updatedPermissions.reduce(
    (permissionsList, permission, index) => {
      const { resourceId, operationType } = permission
      let permissionsIndexes = {}
      permissionsIndexes[operationType] = index

      const currentIndex = permissionsList.sub.findIndex(
        perm => perm.resourceId === resourceId
      )

      if (!resourceId) {
        permissionsList.general[operationType] = index
      } else if (currentIndex === -1) {
        permissionsList.sub.push({ resourceId, permissionsIndexes })
      } else {
        const permissionsNew = {
          ...permissionsList.sub[currentIndex].permissionsIndexes
        }
        permissionsNew[operationType] = index
        permissionsList.sub[currentIndex].permissionsIndexes = permissionsNew
      }

      return permissionsList
    },
    { general: {}, sub: [] }
  )

function reducer(state, action) {
  const newPermissionsState = [...state.permissions]

  if (action.type === 'sortByResourceId') {
    const indexesByResource = sortByResource(newPermissionsState)
    const { read, write } = indexesByResource?.general
    return {
      ...state,
      sectionState: getSectionStatus(newPermissionsState),
      readState: getStatus(newPermissionsState, 'read', read),
      writeState: getStatus(newPermissionsState, 'write', write),
      indexesByResource
    }
  }

  if (action.type === 'togglePermission') {
    const { permissionId, isEnabled } = action.params
    const index = newPermissionsState.findIndex(
      permission => permission.id === permissionId
    )

    if (index > -1) newPermissionsState[index].enabled = isEnabled
  }

  const newSectionState = getSectionStatus(newPermissionsState)
  const readState = getStatus(
    newPermissionsState,
    'read',
    state.indexesByResource.general.read
  )
  const writeState = getStatus(
    newPermissionsState,
    'write',
    state.indexesByResource.general.write
  )

  return {
    ...state,
    sectionState: newSectionState,
    readState,
    writeState,
    permissions: newPermissionsState
  }
}

const EditRoleSection = ({
  label,
  sectionName,
  allPermissions,
  enabledOrgs
}) => {
  const initialState = {
    sectionState: '',
    readState: '',
    writeState: '',
    indexesByResource: { general: {}, sub: [] },
    permissions: allPermissions
  }

  const strings = Strings()

  const error = getRolesError()
  const coretexUser = getCurrentUser()
  const permissionInitialState = getCurrentPermission()

  const showEditOptions = hasEditPermissions(
    coretexUser,
    RESOURCE_TYPE_USER,
    RESOURCE_TYPE_USER_ROLES
  )

  const [state, dispatch] = useReducer(reducer, initialState)

  const {
    indexesByResource,
    permissions,
    readState,
    writeState,
    sectionState
  } = state

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (error) {
      dispatch({
        type: 'togglePermission',
        params: {
          permissionId: permissionInitialState.id,
          isEnabled: permissionInitialState.enabled
        }
      })
    }
  }, [error, permissionInitialState.enabled, permissionInitialState.id])

  useEffect(() => {
    if (permissions?.length > 0) {
      dispatch({
        type: 'sortByResourceId'
      })
    }
  }, [permissions])

  const showZonesByOrg = orgId => {
    const zonesByOrg = allPermissions.filter(zonePermission => {
      return zonePermission?.organizationId === orgId
    })

    const indexesByResourceAndOrg = indexesByResource.sub.filter(zone => {
      const permissionsByOrg = zonesByOrg.find(
        ({ resourceId }) => resourceId === zone?.resourceId
      )

      return permissionsByOrg
    })

    return (
      <FlexV2 direction='column' axisGap={400}>
        {indexesByResourceAndOrg?.length > 0 &&
          indexesByResourceAndOrg?.map(
            ({ resourceId, permissionsIndexes }, index) => (
              <Fragment key={`${resourceId}-${index}`}>
                <EditRoleSectionItem
                  resourceId={resourceId}
                  permissionsIndexes={permissionsIndexes}
                  label={label}
                  sectionName={sectionName}
                  dispatch={dispatch}
                  state={state}
                />
              </Fragment>
            )
          )}
        {indexesByResourceAndOrg?.length === 0 && (
          <Text>{strings.noZonesInOrg}</Text>
        )}
      </FlexV2>
    )
  }

  const getTabConfigs = () => {
    return enabledOrgs.map(org => ({
      tabLabel: org.name,
      renderTabContent: () => showZonesByOrg(org.id)
    }))
  }

  const handleOpenChange = e => {
    if (e?.target?.role !== 'switch') {
      setOpen(!open)
    }
  }

  const controlTriggerParams = {
    sectionState,
    label,
    sectionName,
    readState,
    readId: permissions[indexesByResource.general?.read]?.id,
    writeId: permissions[indexesByResource.general?.write]?.id,
    dispatch,
    disabled: !showEditOptions || sectionName === 'organization',
    writeState,
    indexesByResource
  }

  if (permissions?.length > 0 && indexesByResource) {
    if (indexesByResource.sub.length === 0) {
      return <ControlTrigger {...controlTriggerParams} />
    }

    return (
      <Collapse iconBefore={true} onOpenChange={handleOpenChange} open={open}>
        <Slot name='trigger'>
          <ControlTrigger {...controlTriggerParams} />
        </Slot>
        <Slot name='content'>
          <FlexV2 direction='column' axisGap={400}>
            {sectionName === 'zone' && enabledOrgs?.length > 1 && (
              <Tabs tabConfigs={getTabConfigs()} />
            )}
            {(sectionName !== 'zone' || enabledOrgs?.length === 1) &&
              Children.toArray(
                indexesByResource.sub.map(
                  ({ resourceId, permissionsIndexes }, index) => (
                    <EditRoleSectionItem
                      resourceId={resourceId}
                      permissionsIndexes={permissionsIndexes}
                      sectionName={sectionName}
                      dispatch={dispatch}
                      state={state}
                    />
                  )
                )
              )}
          </FlexV2>
        </Slot>
      </Collapse>
    )
  }

  return null
}

export default EditRoleSection

import { makeActionCreator } from '@/Util/ReduxUtils'

export const CREATE_BRANCH = 'CREATE_BRANCH'
export const createBranch = makeActionCreator(CREATE_BRANCH, 'params')

export const BRANCH_CREATED = 'BRANCH_CREATED'
export const branchCreated = makeActionCreator(
  BRANCH_CREATED,
  'branch',
  'error'
)

export const UPDATE_BRANCH = 'UPDATE_BRANCH'
export const updateBranch = makeActionCreator(UPDATE_BRANCH, 'params')

export const BRANCH_UPDATED = 'BRANCH_UPDATED'
export const branchUpdated = makeActionCreator(
  BRANCH_UPDATED,
  'branch',
  'error'
)

export const REQUEST_BRANCHES = 'REQUEST_BRANCHES'
export const requestBranches = makeActionCreator(REQUEST_BRANCHES, 'params')

export const RECEIVE_BRANCHES = 'RECEIVE_BRANCHES'
export const receiveBranches = makeActionCreator(
  RECEIVE_BRANCHES,
  'branches',
  'count',
  'error'
)

export const REQUEST_BRANCH = 'REQUEST_BRANCH'
export const requestBranch = makeActionCreator(REQUEST_BRANCH, 'params')

export const RECEIVE_BRANCH = 'RECEIVE_BRANCH'
export const receiveBranch = makeActionCreator(
  RECEIVE_BRANCH,
  'branch',
  'error'
)

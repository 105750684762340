import { I18n } from 'aws-amplify'

const Strings = () => ({
  // generics/misc
  manageCustomers: I18n.get('Manage Customers'),
  createCustomer: I18n.get('Create Customer'),
  loading: I18n.get('Loading'),
  customer: I18n.get('Customer'),
  createFormHeader: I18n.get('Create New Customer'),
  updateFormHeader: I18n.get('Update Existing Customer'),
  saveCustomer: I18n.get('Save Customer'),
  selectDefault: I18n.get('Please Select'),
  // table
  company: I18n.get('Company'),
  location: I18n.get('Location'),
  noCustomers: I18n.get('No customers'),
  // Customer
  name: I18n.get('Name'),
  code: I18n.get('Code'),
  category: I18n.get('Category'),
  status: I18n.get('Status'),
  onboardDate: I18n.get('Onboarding Date'),
  // Contact
  contact: I18n.get('Contact'),
  contactName: I18n.get('Contact Name'),
  contactNumber: I18n.get('Contact Number'),
  contactEmail: I18n.get('Contact Email'),
  country: I18n.get('Country'),
  region: I18n.get('Region'),
  city: I18n.get('City'),
  // Payment
  payment: I18n.get('Payment'),
  paymentMethod: I18n.get('Payment Method'),
  paymentTerm: I18n.get('Payment Terms'),
  currency: I18n.get('Currency'),
  // Contract
  contract:  I18n.get('Contract'),
  contractDocument: I18n.get('Contract Document'),
  // Registration
  registration: I18n.get('Registration'),
  registrationNumber: I18n.get('Registration Number'),
  registrationDocument: I18n.get('Registration Document'),
  addRegistration: I18n.get('Add Registration'),
  deleteRegistration: I18n.get('Delete Registration'),
  // Tax Information
  taxInfo: I18n.get('Tax Information'),
  taxNumber: I18n.get('Tax Number'),
  taxDocument: I18n.get('Tax Document'),
  addTaxInfo: I18n.get('Add Tax Information'),
  deleteTaxInfo: I18n.get('Delete Tax Information'),
  // National Address
  nationalAddress: I18n.get('National Address'),
  nationalAddressDocument: I18n.get('National Address Document'),
  streetAddress: I18n.get('Street Address'),
  streetAddress2: I18n.get('Street Address 2'),
  addressRegion: I18n.get('Region'),
  zipCode: I18n.get('Zip Code'),
  addNationalAddress: I18n.get('Add National Address'),
  deleteNationalAddress: I18n.get('Delete National Address'),
  // Bank Details
  bankDetails: I18n.get('Bank Details'),
  bankDetailsDocument: I18n.get('Bank Details Document'),
  bankName: I18n.get('Bank Name'),
  branchName: I18n.get('Branch Name'),
  branchCode: I18n.get('Branch Code'),
  branchAddress:I18n.get('Branch Address'),
  branchAddress2: I18n.get('Branch Address 2'),
  swiftBic: I18n.get('Swift/Bic'),
  iban: I18n.get('IBAN'),
  addBankDetails: I18n.get('Add Bank Details'),
  deleteBankDetails: I18n.get('Delete Bank Details'),
  // Enums
  active: I18n.get('Active'),
  inactive: I18n.get('Inactive'),
  closed: I18n.get('Closed'),
  banned: I18n.get('Banned'),
  retail: I18n.get('Retail'),
  wholesale: I18n.get('Wholesale'),
  charity: I18n.get('Charity'),
  hospitality: I18n.get('Hospitality'),
  openMarket: I18n.get('Open Market')
})

export default Strings

import cloneDeep from 'lodash/cloneDeep'
import { Fragment } from 'react'
import { I18n } from 'aws-amplify'

import { Box, Flex, Text } from '@/primitives'

import { getRanges } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'
import ScatterPlot from '@/elements/Nivo/ScatterPlot'
import { graphSerieColors } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/utils'

const getSerieColor = (serieId, queryZones) => {
  const zoneStack = [...queryZones]
  let queryZoneIndex = zoneStack.findIndex(
    ({ zoneName }) => zoneName === serieId
  )
  if (queryZoneIndex < 0) {
    zoneStack.push(serieId)
    queryZoneIndex = zoneStack.length - 1
  }
  return graphSerieColors?.[queryZoneIndex] || graphSerieColors[0]
}

// https://codesandbox.io/s/m4ro13jjn8
const Line = ({ nodes, xScale, yScale }) => {
  if (nodes.length === 0) {
    return null
  }

  let minX = nodes.reduce(
    (min, next) => Math.min(min, next.data.x),
    nodes[0].data.x
  )
  let minY = nodes.reduce(
    (min, next) => Math.min(min, next.data.y),
    nodes[0].data.y
  )

  let maxX = nodes.reduce(
    (max, next) => Math.max(max, next.data.x),
    nodes[0].data.x
  )
  let maxY = nodes.reduce(
    (max, next) => Math.max(max, next.data.y),
    nodes[0].data.y
  )

  return (
    <Fragment>
      <path
        d={`M ${xScale(minX)} ${yScale(minY)} L ${xScale(maxX)} ${yScale(
          maxY
        )}`}
        fill='none'
        stroke={'#f47560'}
        style={{ pointerEvents: 'none' }}
      />
    </Fragment>
  )
}

export default function Scatterplot({
  config,
  options,
  data,
  unit,
  queryZones
}) {
  const clonedData = cloneDeep(data)
  const graphData = []

  for (let zoneName in clonedData) {
    const zoneData = {
      id: zoneName,
      data: []
    }
    clonedData[zoneName].sort((a, b) => a.x - b.x)

    for (let dataPoint of clonedData[zoneName]) {
      if (dataPoint.x) {
        zoneData.data.push({
          x: dataPoint.x,
          y: dataPoint.y
        })
      }
    }
    graphData.push(zoneData)
  }

  const hasData = graphData.some(({ data }) => data.length > 0)

  return (
    <Fragment>
      <Flex
        className={'MosaicV2__Tile__Content__SubTitle'}
        alignMainAxis='space-between'
        axisGap={400}
      >
        <Text fontFamily={'mono'} size={200}>
          {getRanges(config.range)?.label}
        </Text>
        <Text fontFamily={'mono'} size={200}>
          {unit || ''}
        </Text>
      </Flex>

      <Box className={'MosaicV2__Tile__Content__Graph'}>
        <Box className={'MosaicV2__Tile__Content__Chart'}>
          {graphData.length > 0 && hasData && (
            <ScatterPlot //wrapper around @nivo/scatterplot
              data={graphData}
              config={{
                xScale: { type: 'symlog' },
                layers: [
                  'grid',
                  'axes',
                  'nodes',
                  'markers',
                  Line,
                  'mesh',
                  'legends',
                  'annotations'
                ],
                colors: series => {
                  return getSerieColor(series.serieId, queryZones)
                }
              }}
            />
          )}
          {(graphData.length === 0 || !hasData) && (
            <Flex
              alignCrossAxis='center'
              alignMainAxis='center'
              style={{ flex: 1, height: '100%' }}
            >
              <Text
                fontFamily={'mono'}
                variant={'primary'}
                tone={500}
                size={600}
                fontWeight={700}
              >
                {I18n.get('No data')}
              </Text>
            </Flex>
          )}
        </Box>
      </Box>
    </Fragment>
  )
}

import { compile } from '../core'
import { useTheme } from '../../../contexts/theme-context'
import stylesync from './stylesync'

export default function create(styleObject) {
  const styles = compile(styleObject)
  // only update the stylesheet if we actually have one to update
  if (window && document.styleSheets && document.styleSheets.length) {
    let sheet = null

    try {
      const theme = useTheme()
      sheet = theme.sheet
    } catch (err) {
      sheet = document.styleSheets[document.styleSheets.length - 1]
    }

    if (sheet) {
      stylesync(sheet, styles.data)
    }
  }
  return styles.toClassList()
}

import { Button, Flex, Slot, Text } from '@/primitives'
import { Dialog } from '@/elements'

import {
  getThreshold,
  getZonesHierarchy,
  getThresholdSaving
} from '@/reducers/selectors'

import { getMeasurementStrings } from '@/Util/MeasurementUtils'
import { getZoneName } from '@/Util/ZoneUtils'

import Strings from '../../Strings'

const DeleteThresholdModal = ({
  showModal,
  onAcceptDeleteThreshold,
  onCancelDeleteThreshold
}) => {
  const strings = Strings()
  const threshold = getThreshold()
  const zonesHierarchy = getZonesHierarchy()
  const saving = getThresholdSaving()

  const { rootId, id } = threshold
  const zoneId = id === rootId ? null : id
  const zoneName = getZoneName(zonesHierarchy, rootId, zoneId)

  const {
    removeThresholdTextPart1,
    removeThresholdTextPart2,
    removeThresholdHeading,
    cancelDeleteThresholdButton,
    deleteThresholdButton
  } = strings

  const onClickAccept = e => {
    e.preventDefault()
    onAcceptDeleteThreshold(threshold)
  }

  const onClickClose = e => {
    e.preventDefault()
    onCancelDeleteThreshold()
  }

  return (
    <Dialog
      open={showModal}
      onOpenChange={saving ? null : onCancelDeleteThreshold}
    >
      <Slot name='title'>
        <Text size={300} fontWeight={700}>
          {removeThresholdHeading}
        </Text>
      </Slot>
      <Slot name='content'>
        <Text as='p'>{`${removeThresholdTextPart1} ${
          getMeasurementStrings(threshold?.measurement?.id)?.shortName ??
          threshold?.measurement?.shortName
        } ${removeThresholdTextPart2} ${zoneName}`}</Text>
      </Slot>
      <Slot name='actions'>
        <Flex axisGap={400} alignMainAxis={'space-between'}>
          <Button variant='page' onClick={onClickClose} disabled={saving}>
            {cancelDeleteThresholdButton}
          </Button>
          <Button
            variant='error'
            onClick={onClickAccept}
            loading={saving}
            style={{ minWidth: '12rem' }}
          >
            {deleteThresholdButton}
          </Button>
        </Flex>
      </Slot>
    </Dialog>
  )
}

export default DeleteThresholdModal

import { useEffect, useState } from 'react'
import { getCurrentUser } from '@/reducers/selectors'

import { Loader, Box } from '@/primitives'
import SecondSkyLogomark from './SecondSkyLogomark'
import SecondSkyLogo from './SecondSkyLogo'

import './index.scss'

const dimensions = {
  logoPrimary: {
    width: 175,
    height: 50
  },
  logoSecondary: {
    width: 140,
    height: 40
  },
  logoMark: {
    width: 32,
    height: 32
  }
}

export default function Logo({
  size = 200,
  brand = 'SecondSky',
  byline = 'Data',
  context = 'logoPrimary',
  useDefault = false
}) {
  const currentUser = getCurrentUser()
  const [customLogo, setCustomLogo] = useState(null)
  const [loading, setLoading] = useState(!useDefault)

  useEffect(() => {
    if (useDefault) {
      setLoading(false)
      return
    }

    if (Object.keys(currentUser).length === 0) {
      setLoading(false)
      return
    }

    if (currentUser?.organizations?.length === 0) {
      setLoading(false)
      return
    }

    if (currentUser?.organizationIds?.length > 1) {
      setLoading(false)
      return
    }

    // check if custom logo should be used
    const { organizationIds, organizations, featureFlags } = currentUser
    const orgId =
      organizationIds && organizationIds?.length > 0 ? organizationIds[0] : null
    const useCustomLogo =
      featureFlags?.some(({ featureFlagId, objectId }) => {
        return (featureFlagId =
          'enable_organization_customization' && objectId === orgId)
      }) ?? false

    if (useCustomLogo) {
      const userOrg = organizations?.find(({ id }) => id === orgId)
      if (userOrg?.assets) setCustomLogo(userOrg?.assets[context])
    }

    setLoading(false)
  }, [currentUser, currentUser?.organizationIds?.length, context])

  function onImgError() {
    setCustomLogo(null)
  }

  return (
    <Loader isLoading={loading} style={{ width: 'auto' }}>
      {customLogo && (
        <Box
          style={{
            width: `${dimensions[context].width}px`,
            height: `${dimensions[context].height}px`,
            overflow: 'hidden'
          }}
        >
          <img
            src={customLogo}
            alt={context}
            onError={onImgError}
            style={{ maxHeight: '100%', maxWidth: '100%', width: 'auto' }}
          />
        </Box>
      )}
      {!customLogo &&
        !loading &&
        (context === 'logoMark' ? (
          <SecondSkyLogomark />
        ) : (
          <SecondSkyLogo size={size} brand={brand} byline={byline} />
        ))}
    </Loader>
  )
}

import { makeOperationsApiCall } from './utils'

export const getPaymentTerms = async params => {
  try {
    const query = /* GraphQL */ `
      query getPaymentTerms(
        $filter: GetPaymentTermsFilter
        $order: OrderEnum
        $offset: Int
        $limit: Int
      ) {
        getPaymentTerms(
          filter: $filter
          order: $order
          offset: $offset
          limit: $limit
        ) {
          paymentTerms {
            id
            organizationId
            name
            termSheetS3Key
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getPaymentTerms',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getPaymentTerm = async params => {
  try {
    const query = /* GraphQL */ `
      query getPaymentTerm($paymentTermId: String!) {
        getPaymentTerm(paymentTermId: $paymentTermId) {
          id
          organizationId
          name
          termSheetS3Key
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getPaymentTerm',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const createPaymentTerm = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createPaymentTerm(
        $organizationId: String!
        $name: String!
        $termSheetS3Key: String
      ) {
        createPaymentTerm(
          organizationId: $organizationId
          name: $name
          termSheetS3Key: $termSheetS3Key
        ) {
          id
          organizationId
          name
          termSheetS3Key
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'createPaymentTerm',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updatePaymentTerm = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updatePaymentTerm(
        $paymentTermId: String!
        $organizationId: String!
        $name: String!
        $termSheetS3Key: String
      ) {
        updatePaymentTerm(
          paymentTermId: $paymentTermId
          organizationId: $organizationId
          name: $name
          termSheetS3Key: $termSheetS3Key
        ) {
          id
          organizationId
          name
          termSheetS3Key
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'updatePaymentTerm',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

import { Box, Text } from '@/primitives'

export default function FilterHeader({ title, isEmptySpace = false }) {
  if (isEmptySpace) {
    return <Box className='DataboardFilters__Grid__Header__EmptySpace'> </Box>
  }

  return (
    <Box className='DataboardFilters__Header__Title'>
      <Text
        as='p'
        variant='page'
        tone={500}
        className='DataboardFilters__Header__Title__Text'
      >
        {title}
      </Text>
    </Box>
  )
}

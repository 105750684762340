import { createContext, useContext } from 'react'

export const DataboardContext = createContext({})

export function useDataboard() {
  const context = useContext(DataboardContext)

  if (!context) {
    throw new Error('useDataboard must be used within DataboardContext')
  }
  return context
}

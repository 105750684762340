import { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { Symbol } from '@/primitives'
import { useMobileDashboard } from '../context'
import { SET_IS_CURRENT_ZONE_404 } from '../state'

export default function Breadcrumb({
  breadcrumb,
  isLastBreadcrumb,
  hasChildren
}) {
  const { dispatchState } = useMobileDashboard()
  const history = useHistory()

  const goToSite = () => {
    if (!isLastBreadcrumb) {
      dispatchState({
        type: SET_IS_CURRENT_ZONE_404,
        payload: { is404: false }
      })

      const path =
        breadcrumb.id === 'organization' ? '/zones' : `${breadcrumb.parentPath}`
      history.push(path)
    }
  }

  if (breadcrumb.id === 'home') return null

  return (
    <Fragment key={breadcrumb.id}>
      <span
        className={`MobileDashboardHeader__Breadcrumb ${
          isLastBreadcrumb && 'LastItem'
        }`}
        onClick={goToSite}
      >
        {breadcrumb.name}
      </span>
      {(!isLastBreadcrumb || hasChildren) && (
        <Symbol
          name='keyboard_arrow_right'
          variant='page'
          tone={900}
          size={300}
        />
      )}
    </Fragment>
  )
}

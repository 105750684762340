import { useDispatch } from 'react-redux'
import { Cell, Row, Button } from '../../../../../primitives'

import history from '../../../../../history'
import Strings from '../Strings'
import { clearFertilizerInventoryCalibrations } from '../../../../../actions/operations/fertilizerInventoryCalibration'

function TableRow({ item, dispatchState, modulePath, allFerilizersMap }) {
  const strings = Strings()
  const dispatch = useDispatch()

  const { id, storageFacility, fertilizerId, totalWeight } = item

  const onCalibration = () => {
    dispatch(clearFertilizerInventoryCalibrations())
    dispatchState({
      state: {
        fertilizerInventoryId: id
      }
    })
    history.push(`${modulePath}/calibration`)
  }

  return (
    <Row>
      <Cell columnName={strings.storageFacility}>{storageFacility.name}</Cell>
      <Cell columnName={strings.fertilizer}>
        {allFerilizersMap[fertilizerId].name}
      </Cell>
      <Cell columnName={strings.totalWeight}>
        {totalWeight} {strings.kg}
      </Cell>
      <Cell className='Operations__Table__Column__Actions'>
        <Button
          variant='info'
          size='small'
          iconBefore='scale'
          onClick={onCalibration}
        />
      </Cell>
    </Row>
  )
}

export default TableRow

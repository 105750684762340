import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { requestSales } from '@/actions/operations/sale'

import {
  getCurrentUser,
  getCurrentUserOrganizations,
  getOperationsSales,
  getOperationsSalesCount,
  getOperationsLoading
} from '@/reducers/selectors'

import {
  Flex,
  Box,
  Table,
  Slot,
  Column,
  Row,
  Text,
  Button,
  Loader,
  FlexV2
} from '@/primitives'

import { Pagination } from '@/elements'

import HeaderV2 from '@/components/Operations/Shared/HeaderV2'
import TableFilter from './TableFilter'
import TableRow from './TableRow'

import history from '../../../../../history'

import {
  hasEditPermissions,
  RESOURCE_TYPE_OPERATIONS,
  RESOURCE_TYPE_OPERATIONS_SALES
} from '@/Util/PermissionUtils'

import Strings from '../Strings'
import useMediaQuery from '@/hooks/useMediaQuery'

function SalesTable({ state, dispatchState, modulePath }) {
  const dispatch = useDispatch()

  const isAboveBreakpoint = useMediaQuery('min-width: 1025px')

  const currentUser = getCurrentUser()
  const organizations = getCurrentUserOrganizations()
  const items = getOperationsSales()
  const totalItems = getOperationsSalesCount()
  const loading = getOperationsLoading()

  const strings = Strings()

  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(9)
  const [tableState, setTableState] = useState(state)
  const [canEdit, setCanEdit] = useState(false)
  const [showOrganization, setShowOrganization] = useState(false)

  useEffect(() => {
    if (currentUser.userName) {
      setCanEdit(
        hasEditPermissions(
          currentUser,
          RESOURCE_TYPE_OPERATIONS,
          RESOURCE_TYPE_OPERATIONS_SALES
        )
      )
    }
  }, [currentUser])

  useEffect(() => {
    if (organizations.length > 1) {
      setShowOrganization(true)
    }
  }, [organizations])

  useEffect(() => {
    if (tableState.page !== state.page || items.length === 0) {
      setTableState({ ...state })
      fetchSales(state.page)
    }
  }, [state.page])

  useEffect(() => {
    if (
      tableState.organizations !== state.organizations ||
      tableState.customers !== state.customers ||
      tableState.orderDates !== state.orderDates
    ) {
      setTableState({ ...state })
      fetchSales(1)
      changePage(1)
    }
  }, [state.organizations, state.customers, state.orderDates])

  const fetchSales = page => {
    if (
      state.organizations.length > 0 &&
      state.customers.length > 0 &&
      state.orderDates.length > 0
    ) {
      dispatch(
        requestSales({
          filter: {
            organizationId: state.organizations,
            customerId: state.customers,
            orderDate: state.orderDates
          },
          limit: pageSize,
          offset: page - 1
        })
      )
    }
  }

  const changePage = newPage => {
    dispatchState({
      page: newPage
    })
  }

  const onTraceability = () => {
    history.push(`${modulePath}/traceability`)
  }

  const onCreate = () => {
    history.push(`${modulePath}/create`)
  }

  const onEdit = id => {
    history.push(`${modulePath}/update/${id}`)
  }

  return (
    <Flex axisGap={400} direction='column'>
      <HeaderV2
        title={strings.manageSales}
        buttonIcon={'add'}
        buttonText={strings.createSale}
        buttonCallback={onCreate}
        showButton={canEdit}
        breakpointWidth={1025}
      >
        <FlexV2
          alignCrossAxis={isAboveBreakpoint ? 'center' : null}
          direction={isAboveBreakpoint ? 'row' : 'column'}
          axisGap={300}
        >
          <TableFilter state={state} dispatchState={dispatchState} />
          <Button
            size='small'
            iconBefore={'inventory_2'}
            variant='primary'
            name={'traceabilityButton'}
            onClick={onTraceability}
          >
            <Text fontWeight={700} textTransform='uppercase'>
              {strings.traceability}
            </Text>
          </Button>
        </FlexV2>
      </HeaderV2>
      <Loader isLoading={loading}>
        {totalItems === 0 && (
          <Flex className={'Operations__Table__Empty'}>
            <Text>{strings.noSales}</Text>
          </Flex>
        )}
        {totalItems > 0 && (
          <Table className='Operations__Table' aria-colcount='5'>
            <Slot name='head'>
              <Row>
                {showOrganization && <Column>{strings.organization}</Column>}
                <Column>{strings.tableHeaderOrder}</Column>
                <Column>{strings.customer}</Column>
                <Column>{strings.status}</Column>
                <Column>{strings.tableHeaderInvoice}</Column>
                <Column>{strings.tableHeaderPayment}</Column>
                <Column />
              </Row>
            </Slot>
            <Slot name='body'>
              {items?.map(item => (
                <TableRow
                  key={item.id}
                  onEdit={onEdit}
                  canEdit={canEdit}
                  item={item}
                  showOrganization={showOrganization}
                />
              ))}
            </Slot>
          </Table>
        )}
        {totalItems > pageSize && (
          <Box className='pagination-menu'>
            <Pagination
              type='simple'
              totalItems={totalItems}
              totalPages={totalPages}
              setTotalPages={setTotalPages}
              page={tableState.page}
              setPage={changePage}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          </Box>
        )}
      </Loader>
    </Flex>
  )
}

export default SalesTable

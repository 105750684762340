import { fixDate } from '../../utils'

export const UPDATE_INPUT = 'UPDATE_INPUT'
export const SET_STATE = 'SET_STATE'
export const UPDATE_SITE_ID = 'UPDATE_SITE_ID'

export const initialState = {
  id: null,
  organizationId: '',
  siteId: '',
  zoneId: '',
  produceId: '',
  varietyId: '',
  status: '',
  plantingArea: '',
  growthMedium: [],
  hydrationMethod: '',
  roofCover: '',
  secondskyVariant: '',
  blockingRatio: '',
  netType: '',
  secondskyInformation: '',
  seedQuantity: '',
  estimatedSowDate: null,
  sowDate: null,
  estimatedTransplantDate: null,
  transplantDate: null,
  estimatedHarvestStartDate: null,
  harvestStartDate: null,
  estimatedHarvestEndDate: null,
  harvestEndDate: null,
  produceThresholdId: null
}

export function reducer(state, action) {
  const handlers = {
    [SET_STATE]: (state, action) => {
      const newState = {
        ...initialState,
        ...action.state
      }
      newState.estimatedSowDate = fixDate(newState.estimatedSowDate)
      newState.sowDate = fixDate(newState.sowDate)
      newState.estimatedTransplantDate = fixDate(
        newState.estimatedTransplantDate
      )
      newState.transplantDate = fixDate(newState.transplantDate)
      newState.estimatedHarvestStartDate = fixDate(
        newState.estimatedHarvestStartDate
      )
      newState.harvestStartDate = fixDate(newState.harvestStartDate)
      newState.estimatedHarvestEndDate = fixDate(
        newState.estimatedHarvestEndDate
      )
      newState.harvestEndDate = fixDate(newState.harvestEndDate)
      return {
        ...newState
      }
    },
    [UPDATE_INPUT]: (state, action) => ({
      ...state,
      [action.name]: action.value
    }),
    [UPDATE_SITE_ID]: (state, action) => ({
      ...state,
      siteId: action.siteId,
      zoneId: null
    })
  }
  return handlers[action.type](state, action) || state
}

import { updateList } from '../util'

import {
  CLEAR_SALE,
  REQUEST_SALES,
  RECEIVE_SALES,
  REQUEST_SALE,
  RECEIVE_SALE,
  CREATE_SALE,
  SALE_CREATED,
  UPDATE_SALE,
  SALE_UPDATED,
  DELETE_SALE_CHILDREN,
  SALE_CHILDREN_DELETED,
  UPDATE_SALE_INVOICE,
  DELETE_SALE_SHIPMENT,
  SALE_SHIPMENT_DELETED,
  RECEIVE_SALE_ITEMS,
  DELETE_SALE_ITEM,
  SALE_ITEM_DELETED
} from '@/actions/operations/sale'

export const saleInitialState = {
  sales: [],
  salesCount: 0,
  sale: {},
  saleItems: [],
  loading: false,
  error: null
}

const handlers = {
  [CLEAR_SALE]: state => ({
    ...state,
    sale: {}
  }),
  [REQUEST_SALES]: (state, action) => ({
    ...state,
    sales: [],
    salesCount: 0,
    error: null,
    loading: true
  }),
  [RECEIVE_SALES]: (state, action) => ({
    ...state,
    sales: !action.error ? action.sales : [],
    salesCount: action?.count ?? 0,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_SALE]: state => ({
    ...state,
    sale: {},
    error: null,
    loading: true
  }),
  [RECEIVE_SALE]: (state, action) => ({
    ...state,
    sale: !action.error ? action.sale : state.sale,
    error: action.error ?? null,
    loading: false
  }),
  [CREATE_SALE]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [SALE_CREATED]: (state, action) => ({
    ...state,
    sale: !action.error ? {} : state.sale,
    sales: updateList(action.error, state.sales, action.sale, true),
    salesCount: !action.error ? state.salesCount + 1 : state.salesCount,
    error: action.error ?? null,
    loading: false
  }),
  [UPDATE_SALE]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [SALE_UPDATED]: (state, action) => ({
    ...state,
    sale: !action.error ? {} : state.sale,
    sales: updateList(action.error, state.sales, action.sale, false),
    error: action.error ?? null,
    loading: false
  }),
  [DELETE_SALE_CHILDREN]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [SALE_CHILDREN_DELETED]: (state, action) => ({
    ...state,
    sale: !action.error ? {} : state.sale,
    sales: updateList(action.error, state.sales, action.sale, false),
    error: action.error ?? null,
    loading: false
  }),
  [UPDATE_SALE_INVOICE]: (state, action) => {
    const index = state.sales.findIndex(i => i.id === action.sale.id)
    if (index >= 0) {
      state.sales[index].invoiceTotal = action.sale.invoiceTotal
      state.sales[index].invoiceTax = action.sale.invoiceTax
    }
    return {
      ...state
    }
  },
  [DELETE_SALE_SHIPMENT]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [SALE_SHIPMENT_DELETED]: (state, action) => ({
    ...state,
    error: action.error ?? null,
    loading: false
  }),
  [DELETE_SALE_ITEM]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [SALE_ITEM_DELETED]: (state, action) => ({
    ...state,
    error: action.error ?? null,
    loading: false
  }),
  [RECEIVE_SALE_ITEMS]: (state, action) => ({
    ...state,
    saleItems: !action.error ? action.saleItems : [],
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

import { I18n } from 'aws-amplify'

const Strings = () => ({
  title: I18n.get('Suppliers'),
  subtitle: I18n.get('View factory suppliers'),
  noSuppliers: I18n.get('No suppliers found'),
  tableRowId: I18n.get('ID'),
  tableRowName: I18n.get('Name'),
  tableRowActions: I18n.get('Actions'),
  addSupplier: I18n.get('Add Supplier'),
  users: I18n.get('Admin Users'),
  seeUsers: I18n.get('See Users'),
  email: I18n.get('Email'),
  save: I18n.get('Save'),
  supplierCreated: I18n.get('Supplier created'),
  supplierUserCreated: I18n.get('User created'),
  supplierUserDeleted: I18n.get('User deleted'),
  delete: I18n.get('Delete')
})

export default Strings

import { useState, useLayoutEffect, useMemo } from 'react'

const breakpoints = {
  mobile: 430,
  tablet: 768,
  laptop: 1024,
  laptopLarge: 1439
}

// we default to tablet to simulate the way useIsMobile works
export default function useDeviceSize(breakpoint = 'tablet') {
  const initBpMatches = window.innerWidth <= breakpoints[breakpoint]
  const [breakpointMatches, setBreakpointMatches] = useState(initBpMatches)

  const handleMediaQueryChangeEvent = e => {
    setBreakpointMatches(e.matches)
  }

  const matchMedia = useMemo(
    () =>
      window.matchMedia(`screen and (max-width:${breakpoints[breakpoint]}px)`),
    [breakpoint]
  )

  useLayoutEffect(() => {
    if (!breakpoints[breakpoint]) {
      throw new Error(
        `\n'${breakpoint}' is not a registered breakpoint, \n\nValid options are: \n· ${Object.keys(
          breakpoints
        ).join('\n· ')}`
      )
    }

    if (breakpoints[breakpoint]) {
      // catch any events that happen before the hook gets initialised.
      setBreakpointMatches(window.innerWidth <= breakpoints[breakpoint])

      matchMedia.addEventListener('change', handleMediaQueryChangeEvent, {
        passive: true
      })
    }

    return () => {
      matchMedia &&
        matchMedia.removeEventListener('change', handleMediaQueryChangeEvent)
    }
  }, [breakpoint, matchMedia])

  return breakpointMatches
}

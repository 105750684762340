import { Children, Fragment, useState, useEffect } from 'react'
import { useLocation, matchPath, generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Select from 'react-select'

import { Flex, Text, Button, Separator, Box, Loader } from '@/primitives'

import {
  getNavigationLocationSelector,
  getNavigationOptionsRoute,
  getNavigationPanelLocationSelectorOpen,
  getNavigationPanelPrimaryLock
} from '@/reducers/selectors'

import {
  setLocationSelector,
  setSelectedLevel,
  togglePanelLocationSelectorOpen,
  togglePanelPrimaryOpen
} from '@/slices/navigation'

import LEVEL_NAMES from '../level_names.json'
import QUALIFIED_ROUTES from './qualified_routes.json'

import { byLabel } from '../../Utils'
import { generateSelectFieldsFromLocationSelector } from './utils'

import './index.scss'

function assembleURL({
  localLocationSelector,
  locationSelector,
  location: { pathname }
}) {
  const { path, params, url } = QUALIFIED_ROUTES.map(path =>
    matchPath(pathname, { path })
  ).filter(item => item)[0] || { path: null, params: null, url: null }

  if (path && params && url) {
    const isZonePath = localLocationSelector.size > 1
    const isOrgPath = !isZonePath
    const isSensorPath = localLocationSelector.get('sensor') !== undefined
    const isAdminPath = path.includes('admin')

    if (isZonePath) {
      const nextZone = Array.from(localLocationSelector)
        .filter(([key]) => key !== 'organization' && key !== 'sensor')
        .map(([, value]) => value.id)

      if (path && params && url && nextZone.length) {
        const zone = nextZone.join('/')

        let nextURL = decodeURIComponent(
          generatePath(path, { ...params, zone })
        )

        if (isSensorPath && !isAdminPath) {
          const { id, type } = localLocationSelector.get('sensor')

          let nextSensorPath = path.includes('sensor')
            ? path
            : path.replace(':zone+', ':zone+/sensor/:type/:id')

          if (nextSensorPath.includes('video-feeds')) {
            nextSensorPath = nextSensorPath.replace('/video-feeds', '')
          }

          nextURL = decodeURIComponent(
            generatePath(nextSensorPath, {
              ...params,
              zone,
              type,
              id
            })
          )
        } else if (!isSensorPath) {
          if (locationSelector.get('sensor') !== undefined) {
            let nextPath = path.includes('sensor')
              ? path.replace(':zone+/sensor/:type/:id', ':zone+')
              : path
            if (nextPath.includes('calibration')) {
              nextPath = nextPath.replace('/calibration', '')
            }
            nextURL = decodeURIComponent(
              generatePath(nextPath, {
                ...params,
                zone
              })
            )
          }
        }

        if (nextURL !== url) {
          return nextURL
        } else {
          return null
        }
      }
    }

    if (isOrgPath && localLocationSelector.get('organization')) {
      const zone = localLocationSelector.get('organization').id
      const nextURL = decodeURIComponent(
        generatePath(path, { ...params, zone })
      )

      //Dashboard is excluded for now, until we get org dashboard
      if (
        nextURL !== url &&
        path !== '/zones/:zone+' &&
        path !==
          '/admin/zones/:zone+/(details|devices|thresholds|managers|subzones|fleet-management)'
      ) {
        return nextURL
      } else {
        return null
      }
    }
  }

  return null
}

function compareLocationSelectors({ localLocationSelector, locationSelector }) {
  if (localLocationSelector.size !== locationSelector.size) {
    return false
  }

  let isEquals = true

  localLocationSelector.forEach((value, key) => {
    if (!locationSelector.has(key) || locationSelector.get(key) !== value) {
      isEquals = false
    }
  })

  return isEquals
}

const LocationSelectorPanel = ({ strings }) => {
  const dispatch = useDispatch()
  /* ------------------------------------------------------- Contextual State */
  const location = useLocation()
  const navigationPanelPrimaryLock = getNavigationPanelPrimaryLock()
  const navigationOptionsRoute = getNavigationOptionsRoute()
  const navigationPanelLocationSelectorOpen =
    getNavigationPanelLocationSelectorOpen()
  const locationSelector = getNavigationLocationSelector()

  /* ------------------------------------------------------------ Local State */
  const [localLocationSelector, setLocalLocationSelector] = useState(new Map())
  const [selectFields, setSelectFields] = useState([])
  const [nextPageState, setNextPageState] = useState(null)

  /* ----------------------------------------------------------- Side Effects */
  useEffect(() => {
    if (navigationOptionsRoute.length > 0) {
      // rebuild the fields in view

      setSelectFields(
        generateSelectFieldsFromLocationSelector({
          route: navigationOptionsRoute,
          localLocationSelector,
          strings
        })
      )

      // evaluate the button visbility and state
      const nextURL = assembleURL({
        localLocationSelector,
        locationSelector,
        location
      })
      const isMatchedLocation = compareLocationSelectors({
        localLocationSelector,
        locationSelector
      })

      if (nextURL) {
        setNextPageState(nextURL)
      }
      if (!nextURL && !isMatchedLocation) {
        setNextPageState(true)
      }
      if (!nextURL && isMatchedLocation) {
        setNextPageState(null)
      }
    }
  }, [
    localLocationSelector,
    navigationOptionsRoute?.length,
    location,
    locationSelector,
    navigationOptionsRoute,
    strings
  ])

  useEffect(() => {
    if (locationSelector.size > 0) {
      setLocalLocationSelector(locationSelector)
    }
  }, [locationSelector])

  /* --------------------------------------------------------- Event Handlers */

  const handleChange = (data, { action, removedValues }) => {
    const { value, level, label, type, status } = data || removedValues[0]
    const nextLocalLocationSelector = new Map(localLocationSelector.entries())

    // ROUTES
    if (level > -1) {
      LEVEL_NAMES.forEach((name, levelIndex) => {
        if (levelIndex === level) {
          if (action === 'select-option') {
            nextLocalLocationSelector.set(name, {
              name: label,
              id: value,
              status
            })
          }

          if (action === 'clear') {
            nextLocalLocationSelector.delete(name)
          }
        }

        if (levelIndex > level) {
          nextLocalLocationSelector.delete(name)
        }
      })
    }

    nextLocalLocationSelector.delete('sensor')

    // SENSOR
    if (level < 0) {
      if (action === 'select-option') {
        nextLocalLocationSelector.set('sensor', {
          id: value,
          name: label,
          type,
          status
        })
      }

      if (action === 'clear') {
        nextLocalLocationSelector.delete('sensor')
      }
    }

    setLocalLocationSelector(nextLocalLocationSelector)
  }

  const handleClick = ({
    target: {
      dataset: { action }
    }
  }) => {
    if (action === 'back') {
      dispatch(togglePanelLocationSelectorOpen())
    }

    if (action === 'update') {
      /*
        NextPageState has 3 states

        null - disable the button
        true - update the dispatch state
        string - update the dispatch state and the url
      */

      if (nextPageState) {
        dispatch(setLocationSelector(localLocationSelector))

        if (typeof nextPageState === 'string') {
          if (!navigationPanelPrimaryLock) {
            dispatch(togglePanelPrimaryOpen())
          }
        }

        dispatch(setSelectedLevel([...localLocationSelector.keys()].pop()))

        dispatch(togglePanelLocationSelectorOpen())

        // reset NextPageState
        setNextPageState(null)
      }
    }
  }

  /* --------------------------------------------------------------- Renderer */

  return (
    <Flex
      direction='column'
      axisGap={400}
      className={'LocationSelector__Panel'}
      open={navigationPanelLocationSelectorOpen}
      wrap='nowrap'
      onClick={handleClick}
    >
      <Box>
        <Button
          className={'Button__Back'}
          iconBefore='navigate before'
          variant='text'
          data-action='back'
        >
          {strings.back_to_menu}
        </Button>
        <Separator />
      </Box>

      <Flex direction={'column'} axisGap={400}>
        {selectFields.length ? (
          Children.toArray(
            selectFields.map((field, index) => {
              return (
                <Flex axisGap={200} direction='column'>
                  <Text size={100} textTransform={'uppercase'} fontWeight={700}>
                    {field.name}
                  </Text>
                  <Select
                    onChange={handleChange}
                    classNamePrefix={'ReactSelect'}
                    value={field.value}
                    options={(field.options || []).sort(byLabel)}
                    placeholder={strings.select_option}
                    isClearable={index !== 0}
                  />
                </Flex>
              )
            })
          )
        ) : (
          <Loader isLoading={true} />
        )}
      </Flex>

      <Separator />
      {typeof nextPageState === 'string' && (
        <Link
          as={typeof nextPageState === 'string' ? null : Box}
          to={nextPageState}
          role={'button'}
          data-action={'update'}
          className={'Button__UpdateLocation'}
        >
          {strings.update_location}
        </Link>
      )}

      {nextPageState === true && (
        <Box
          role={'button'}
          data-action={'update'}
          className={'Button__UpdateLocation'}
        >
          {strings.update_location}
        </Box>
      )}

      {nextPageState === null && (
        <Box
          role={'presentation'}
          data-action={'update'}
          className={'Button__UpdateLocation'}
        >
          {strings.update_location}
        </Box>
      )}
    </Flex>
  )
}

const LocationSelectorTrigger = ({ strings }) => {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(togglePanelLocationSelectorOpen())
  }

  return (
    <Button
      variant='text'
      className={'LocationSelector__Trigger'}
      onClick={handleClick}
      iconBefore='map'
      iconAfter='chevron right'
    >
      {strings.select_location}
    </Button>
  )
}

const LocationSelector = ({ strings }) => {
  return (
    <Fragment>
      <LocationSelectorTrigger strings={strings} />
      <Separator />
      <LocationSelectorPanel strings={strings} />
    </Fragment>
  )
}

export default LocationSelector

import { Flex, Box, Text } from '../'
import './index.scss'

/**
 * Loader Primitive
 * @param {Object} props
 * @param {React.ReactChildren} [props.children = null] Content to Hide or Reveal
 * @param {String | String[]} [props.className = null] Add a custom className
 * @param {Boolean} [props.isLoading = null] Is the Item / Page / Thing Loading?
 * @param { 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 } [props.size=200] What size should the loader be... Larger Number, Larger Size
 * @param { 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 } [props.speed=100] How fast should the Loader spin... Larger Number, faster spin
 * @param { String | String[] } [props.text=''] The text to display next to the loader circle (can be dynamic)
 * @param { 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 } [props.tone=700] = How "light" or "bright" is the color of the theme?
 * @param {Boolean} [props.vertical = false] Should the primitive be oriented horizontal or vertical ?
 * @param { 'page' | 'primary' | 'success' | 'graph'| 'info' | 'error' | 'warning' | 'danger' | 'neutral' } [props.variant = 'page'] The SecondSky Data Theme Variant to use for the loader
 */

const Loader = ({
  children = null,
  className = null,
  isLoading = null,
  size = 200,
  speed = 100,
  text = '',
  tone = 700,
  vertical = false,
  variant = 'page',
  ...rest
}) => {
  return (
    <Box
      className={[
        'Loader',
        {
          [`mod__size__${size}`]: size,
          [`mod__speed__${speed}`]: speed,
          [`mod__variant__${variant}__${tone}`]: variant
        },
        className
      ]}
      {...rest}
      aria-live='polite'
      aria-busy={isLoading}
    >
      {children && <Box className={'Loader__Content'}>{children}</Box>}
      <Flex
        className={'Loader__Animation'}
        alignCrossAxis={'center'}
        alignMainAxis={'center'}
      >
        <Flex
          className={'Loader__Animation__Graphic'}
          aria-busy={true}
          alignCrossAxis={'center'}
          direction={vertical ? 'column' : null}
          axisGap={300}
        >
          {text && (
            <Text variant={variant} tone={tone} fontWeight={500} size={size}>
              {text}
            </Text>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}

export default Loader

export const tableInitialState = {
  organizations: [],
  produces: [],
  page: 1
}

export const tableReducer = (state, action) => {
  if (action.state) {
    return {
      ...state,
      ...action.state
    }
  } else if (action.page) {
    return { ...state, page: action.page }
  } else if (action.produces) {
    return { ...state, produces: action.produces }
  } else if (action.organizations) {
    return {
      ...state,
      organizations: action.organizations,
      produces: []
    }
  }
  return state
}

export const formInitalState = {
  id: null,
  organizationId: '',
  produceId: '',
  name: '',
  code: ''
}

export const formReducer = (state, action) => {
  if (action.type === 'update') {
    return { ...state, [action.name]: action.value }
  }
  if (action.type === 'set-state') {
    return action.state
  }
  return state
}

import { Button, FlexV2, Text, Symbol } from '@/primitives'

import Strings from '../Strings'
import { COMPARISON_TYPES } from '../Utils/config'
import { useDataboard } from '../context'

const DataboardActions = ({
  duplicateIndices,
  comparisonType,
  canDownload,
  canFetch,
  onClickFetch,
  onClickDownload
}) => {
  const strings = Strings()
  const { state } = useDataboard()

  // TODO: Add message for when new data is available for fetching
  // TODO: Add message for when data is up to date
  let message = null
  let icon = null
  let messageType = 'info'

  if (duplicateIndices.length > 0) {
    messageType = 'error'
    icon = 'block'
    message =
      comparisonType === COMPARISON_TYPES.LOCATION_RANGES
        ? strings.duplicateZoneFilters
        : strings.duplicateDateFilters
  }
  if (
    state.comparisonType === COMPARISON_TYPES.LOCATION_RANGES &&
    state.availableMeasurements.length === 0 &&
    state.zoneFilters.length > 1
  ) {
    messageType = 'error'
    icon = 'block'
    message = strings.noCommonMeasurements
  }

  if (
    state.comparisonType === COMPARISON_TYPES.LOCATION_RANGES &&
    state.availableMeasurements.length === 0 &&
    state.zoneFilters.length > 1
  ) {
    messageType = 'error'
    icon = 'block'
    message = strings.noCommonMeasurements
  }

  return (
    <FlexV2 alignMainAxis='space-between' className='Databoard__Actions'>
      <FlexV2
        alignCrossAxis='center'
        alignMainAxis='center'
        axisGap={200}
        className='Databoard__Actions__Message'
      >
        {icon && <Symbol name={icon} variant={messageType} tone={500} />}
        {message && (
          <Text tone={500} variant={messageType}>
            {message}
          </Text>
        )}
      </FlexV2>

      {canFetch && (
        <FlexV2 axisGap={300} className='Databoard__Actions__Buttons'>
          <Button
            variant='info'
            size='small'
            onClick={onClickDownload}
            disabled={!canDownload}
            className='Databoard__Actions__Button'
          >
            {strings.downloadData}
          </Button>

          <Button
            variant='primary'
            size='small'
            onClick={onClickFetch}
            disabled={!canFetch}
            className='Databoard__Actions__Button'
          >
            {strings.fetchData}
          </Button>
        </FlexV2>
      )}
    </FlexV2>
  )
}

export default DataboardActions

import { Flex } from '../../../../primitives'

import OrganizationsFilter from '../../Shared/Filters/OrganizationsFilter'
import ProducesFilter from '../../Shared/Filters/ProducesFilter'

const TableFilter = ({ state, dispatchState }) => {
  return (
    <Flex direction='row' axisGap='300' style={{ whiteSpace: 'nowrap' }}>
      <OrganizationsFilter state={state} dispatchState={dispatchState} />
      <ProducesFilter state={state} dispatchState={dispatchState} />
    </Flex>
  )
}

export default TableFilter

import { useEffect, useState } from 'react'
import { Settings } from 'luxon'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { clearTimeframeGraphAverages } from '@/actions/ts/timeframe'

import {
  getZone,
  getRootZone,
  getZonesHierarchyLoading,
  getMeasurements,
  getTimeframeGraphAveragesError,
  getTimeframeGraphAveragesIsLoading,
  getCurrentUser,
  getZonesHierarchy,
  getNavigationLocationSelector
} from '@/reducers/selectors'

import { FlexV2, Loader } from '@/primitives'

import useMediaQuery from '@/hooks/useMediaQuery'

import {
  buildZoneFilterFromHierarchy,
  createZoneFilterObject
} from '../Utils/FiltersUtils'
import { COMPARISON_TYPES } from '../Utils/config'
import Strings from '../Strings'
import {
  DATABOARD_ACTIONS,
  DEFAULT_NEW_DATE_FILTER,
  initialState,
  reducer
} from '../state'
import { useDataboard } from '../context'

import ExportModal from './Export/ExportModal'
import ChartCard from './Cards/ChartCard'
import FetchErrorCard from './Cards/FetchErrorCard'

import './index.scss'

Settings.defaultZoneName = 'utc'

const DataboardPage = () => {
  const strings = Strings()
  const dispatch = useDispatch()
  const params = useParams()
  
  const locationSelector = getNavigationLocationSelector()

  const { state, dispatchState } = useDataboard(reducer, initialState)

  const zonesHierarchy = getZonesHierarchy()
  const zonesHierarchyLoading = getZonesHierarchyLoading()
  const zone = getZone()
  const rootZone = getRootZone()

  const allMeasurements = getMeasurements()
  const coretexUser = getCurrentUser()

  const timeframeGraphAveragesLoading = getTimeframeGraphAveragesIsLoading()
  const timeframeGraphAveragesError = getTimeframeGraphAveragesError()

  const [isLoading, setIsLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [exportData, setExportData] = useState(null)

  const [currentZoneId, setCurrentZoneId] = useState(null)

  const { id: organizationId } = locationSelector?.get('organization') ?? {}

  const isAboveCustomBreakpoint = useMediaQuery('min-width: 1280px')

  useEffect(() => {
    dispatch(clearTimeframeGraphAverages())
    dispatchState({
      type: DATABOARD_ACTIONS.SET_DATASET,
      dataset: null
    })
  }, [dispatch, dispatchState])

  useEffect(() => {
    if (
      rootZone?.id &&
      zone?.id &&
      allMeasurements.length > 0 &&
      !zonesHierarchyLoading
    ) {
      setIsLoading(false)
    } else if (organizationId) {
      setIsLoading(false)
    }
  }, [
    zone,
    rootZone,
    zonesHierarchyLoading,
    organizationId,
    allMeasurements?.length
  ])

  useEffect(() => {
    if (
      rootZone?.id &&
      zone?.id &&
      (rootZone.id === zone.id || rootZone.id === zone.parentId) &&
      currentZoneId !== zone.id &&
      !zonesHierarchyLoading
    ) {
      const zoneFilter = buildZoneFilterFromHierarchy(
        zonesHierarchy[rootZone.id],
        zone.id
      )
      const timeZone = zonesHierarchy[rootZone.id].timeZone
      dispatchState({
        type: DATABOARD_ACTIONS.RESET_FILTERS,
        zoneFilter,
        timeZone
      })

      setCurrentZoneId(zone.id)
    }
  }, [
    params?.zone,
    rootZone?.id,
    zone?.id,
    coretexUser?.userName,
    zonesHierarchy,
    zonesHierarchyLoading,
    currentZoneId,
    dispatchState,
    zone.parentId
  ])

  useEffect(() => {
    if (state.localStorageId) {
      const storedConfig = window.localStorage.getItem(state.localStorageId)

      if (storedConfig && storedConfig.length > 0) {
        const config = JSON.parse(storedConfig)
        if (!config.version || (config.version < 2 && !zonesHierarchyLoading)) {
          const mainZoneFilter = config.zoneFilters[0]
          const timeZone = zonesHierarchy[mainZoneFilter.siteId].timeZone
          const zoneFilters = config.zoneFilters
            .map(filter =>
              buildZoneFilterFromHierarchy(
                zonesHierarchy[filter.siteId],
                filter.zoneId
              )
            )
            .filter(filter => filter !== null)

          const convertedConfg = {
            version: 2,
            measurements: {
              [COMPARISON_TYPES.LOCATION_RANGES]: config.selectedMeasurements,
              [COMPARISON_TYPES.DATE_RANGES]: config.selectedMeasurements
            },
            comparisonType: COMPARISON_TYPES.LOCATION_RANGES,
            zoneFilters,
            dateFilters: [DEFAULT_NEW_DATE_FILTER(timeZone)],
            sidebarFilter: {
              [COMPARISON_TYPES.DATE_RANGES]: {
                type: 'date',
                dateRange: config.dateRange ?? 'customRange',
                fromDate: config.fromDate,
                toDate: config.toDate,
                timeInterval: config.timeInterval,
                timeZone
              },
              [COMPARISON_TYPES.LOCATION_RANGES]: zoneFilters[0]
            }
          }

          dispatchState({
            type: DATABOARD_ACTIONS.SET_STATE_FROM_CACHE,
            config: convertedConfg
          })
        } else {
          dispatchState({
            type: DATABOARD_ACTIONS.SET_STATE_FROM_CACHE,
            config
          })
        }
      }
    }
  }, [
    state.localStorageId,
    zonesHierarchyLoading,
    dispatchState,
    zonesHierarchy
  ])

  useEffect(() => {
    if (state.zoneFilters?.length === 0 && !zonesHierarchyLoading) {
      let zoneFilters = []
      if (rootZone?.id && zone?.id) {
        zoneFilters = [
          buildZoneFilterFromHierarchy(zonesHierarchy[rootZone.id], zone.id)
        ]
      } else if (organizationId && !zone?.id) {
        zoneFilters = [createZoneFilterObject({ organizationId })]
      }

      if (zoneFilters.length > 0) {
        dispatchState({
          type: DATABOARD_ACTIONS.SET_ZONE_FILTERS,
          zoneFilters,
          timeZone: rootZone?.timeZone
        })
      }
    }
  }, [
    state.zoneFilters,
    rootZone,
    zone,
    zonesHierarchyLoading,
    zonesHierarchy,
    dispatchState,
    organizationId
  ])

  const onExportChart = exportData => {
    setExportData(exportData)
    setShowModal(true)
  }

  return (
    <Loader isLoading={isLoading || zonesHierarchyLoading}>
      {showModal && (
        <ExportModal
          showModal={showModal}
          setShowModal={setShowModal}
          exportData={exportData}
        />
      )}
      <FlexV2 direction='column' axisGap={400}>
        {timeframeGraphAveragesLoading && (
          <Loader isLoading={true} text={strings.pleaseWait} />
        )}
        {timeframeGraphAveragesError && (
          <FetchErrorCard error={timeframeGraphAveragesError} />
        )}

        {!timeframeGraphAveragesLoading && !timeframeGraphAveragesError && (
          <ChartCard
            isAboveCustomBreakpoint={isAboveCustomBreakpoint}
            onExportChart={onExportChart}
          />
        )}
      </FlexV2>
    </Loader>
  )
}

export default DataboardPage

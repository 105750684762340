import { makeActionCreator } from '@/Util/ReduxUtils'

export const FETCH_CURRENT_AVE_MIN_MAX = 'FETCH_CURRENT_AVE_MIN_MAX'
export const CURRENT_AVE_MIN_MAX_FETCHED = 'CURRENT_AVE_MIN_MAX_FETCHED'
export const fetchCurrentAveMinMax = makeActionCreator(
  FETCH_CURRENT_AVE_MIN_MAX,
  'params'
)
export const currentAveMinMaxFetched = makeActionCreator(
  CURRENT_AVE_MIN_MAX_FETCHED,
  'currentAverageMinMax',
  'error'
)

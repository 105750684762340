const words = {
es: {
  'Only lowercase letters are allowed': 'Solo se permite el uso de minúsculas',
  'Email is a required field': 'Correo electrónico es un campo obligatorio',
  'First name is a required field': 'Nombre es un campo obligatorio',
  'Last name is a required field': 'Apellido es un campo obligatorio',
  'Username is a required field': 'Nombre de usuario es un campo obligatorio',
  'Please select a role': 'Seleccione un rol',
  'Please enter a role name': 'Introduzca un nombre de rol',
  'Role name must be unique': 'El nombre de rol debe ser único',
  'Spaces and special characters other than hyphen (-) and underscore (_) are not supported': 'No se admiten espacios ni caracteres especiales que no sean guiones (-) o guiones bajos (_)',
  'Please enter a location name': 'Introduzca un nombre de ubicación',
  'Hyphens (-) are the only special characters allowed in the name': 'Los guiones (-) son los únicos caracteres especiales permitidos en el nombre',
  'Please select a timezone': 'Seleccione una zona horaria',
  'Longitude can not be blank': 'La longitud no puede estar en blanco',
  'Latitude can not be blank': 'La latitud no puede estar en blanco',
  'Organization ID is required': 'Se requiere ID de organización',
  'Organization is required': 'Es necesario indicar la organización',
  'Longitude must be between -180 and 180': 'La longitud debe estar entre -180 y 180',
  'Latitude must be between -90 and 90': 'La latitud debe estar entre -90 y 90',
  'Please enter a number': 'Introduzca un número',
  'Please enter the vendor name': 'Introduzca el nombre del proveedor',
  'A vendor with this name already exists': 'Ya existe un proveedor con este nombre',
  'Please enter an ID': 'Introduzca un ID',
  'Only letters and underscores are allowed for ID': 'Solo se permiten letras y guiones bajos para el ID',
  'Please select a type': 'Por favor, seleccione un tipo',
  'A measurement with this ID already exists': 'Ya existe una medición con este ID',
  'Please enter a description': 'Introduzca una descripción',
  'Please enter a short name': 'Introduzca un nombre abreviado',
  'Please enter a unit': 'Introduzca una unidad',
  'Lower threshold can not be greater than upper threshold': 'El umbral inferior no puede ser mayor que el umbral superior',
  'Please add a lower threshold': 'Agregue un umbral inferior',
  'Please add an upper threshold': 'Agregue un umbral superior',
  'Please select a vendor': 'Seleccione un proveedor',
  'Please enter a name': 'Introduzca un nombre',
  'Type input must have a name': 'La entrada de tipo debe tener un nombre',
  'Type input must have a measurement': 'La entrada de tipo debe tener una medición',
  'Type inputs can not use the same measurement': 'Las entradas de tipo no pueden usar la misma medición',
  'Please select an organization': 'Seleccione una organización',
  'Port Address is a required field': 'Dirección de puerto es un campo obligatorio',
  'Slave Address is a required field': 'Dirección esclava es un campo obligatorio',
  'Baud Rate is a required field': 'Tasa de baudios es un campo obligatorio',
  'Bytesize is a required field': 'Tamaño de byte es un campo obligatorio',
  'Stop Bits is a required field': 'Bits de parada es un campo obligatorio',
  'Timeout is a required field': 'Tiempo de espera es un campo obligatorio',
  'Read Register count is a required field': 'Recuento de registros leídos es un campo obligatorio',
  'Read Register is a required field': 'Registro leído es un campo obligatorio',
  'Read Measurement Type is a required field': 'Tipo de medida leída es un campo obligatorio',
  'Read Measurement Unit is a required field': 'Unidad de medida leída es un campo obligatorio',
  'The selected ID is already in use': 'El ID seleccionado ya está en uso',
  'Please enter a version': 'Introduzca una versión',
  'The flash script version already exists': 'La versión de script flash ya existe',
  'Home': 'Inicio',
  'Environment': 'Entorno',
  'Temperature': 'Temperatura',
  'Absolute Humidity': 'Humedad absoluta',
  'Air Pressure': 'Presión atmosférica',
  'SVP': 'SVP',
  'Fan Speed': 'Velocidad del ventilador',
  'Airflow': 'Caudal de aire',
  'Carbon Dioxide': 'Dióxido de carbono',
  'UV Index': 'Índice UV',
  'DLI': 'DLI',
  'Solar Irradiance': 'Irradiación solar',
  'VPD': 'VPD',
  'Relative Humidity': 'Humedad relativa',
  'Fertigation': 'Fertirrigación',
  'Water Temperature': 'Temperatura del agua',
  'Conductivity': 'Conductividad',
  'pH': 'pH',
  'Dissolved Oxygen': 'Oxígeno disuelto',
  'Dissolved Oxygen Percentage': 'Porcentaje de oxígeno disuelto',
  'Salinity': 'Salinidad',
  'Liquid Flow': 'Caudal de líquido',
  'Soil Moisture': 'Humedad del suelo',
  'Soil Temperature': 'Temperatura del suelo',
  'Soil pH': 'pH del suelo',
  'Soil Conductivity': 'Conductividad del suelo',
  'Soil P Content': 'Contenido de P del suelo',
  'Soil K Content': 'Contenido de K del suelo',
  'Soil N Content': 'Contenido de N del suelo',
  'Plant Science': 'Fitotecnia',
  'Wet Bulb Temperature': 'Temperatura del bulbo húmedo',
  'Resources': 'Recursos',
  'Energy': 'Energía',
  'Photosynthetically Active Radiation': 'Radiación fotosintéticamente activa',
  'Power Consumed': 'Energía consumida',
  'Actuator State': 'Estado del actuador',
  'Ion Concentration': 'Concentración de iones',
  'Power Generation': 'Generación de energía',
  'Motor Speed': 'Velocidad del motor',
  'Power Consumption': 'Consumo de energía',
  'Line Voltage': 'Tensión de la línea',
  'Vapour Pressure Deficit': 'Déficit de presión de vapor',
  'Wind Speed': 'Velocidad del viento',
  'Energy (kW': 'Energía (kW',
  'RTR': 'RTR',
  'CO2 Flow Rate': 'Caudal de CO2',
  'TDS': 'TDS',
  'VFD': 'VFD',
  'UV-C Intensity': 'Intensidad UV-C',
  'Liquid Level': 'Nivel de líquido',
  'Growing Degree Hours': 'Horas de grado creciente',
  'Site': 'Sitios',
  'Sites': 'Sitios',
  'Facility': 'Instalación',
  'Facilities': 'Instalaciones',
  'Room': 'Habitación',
  'Rooms': 'Habitaciones',
  'Zone': 'Zona',
  'Zones': 'Zonas',
  'Subzone': 'Subzona',
  'Subzones': 'Subzonas',
  'Current (instantaneous': 'Actual (instantáneo)',
  'GDH': 'GDH',
  'Line Frequency': 'Frecuencia de línea',
  'Liquid Flow (L/m': 'Caudal de líquido (l/m)',
  'PAR': 'PAR',
  'PPFD': 'PPFD',
  'Power Consumption (instantaneous': 'Consumo de potencia (instantáneo)',
  'Power Generation (instantaneous': 'Generación de potencia (instantánea)',
  'Power Generated': 'Potencia generada',
  'Pump Speed': 'Velocidad de la bomba',
  'Soil N content': 'Contenido de N del suelo',
  'Soil K content': 'Contenido de K del suelo',
  'Soil P content': 'Contenido de P del suelo',
  'Wind Direction': 'Dirección del viento',
  'Actor': 'Actor',
  'Actor Type': 'Tipo de actor',
  'Date': 'Fecha',
  'Event': 'Evento',
  'Service': 'Servicio',
  'Context': 'Contexto',
  'Action': 'Acción',
  'Details': 'Detalles',
  'Filters': 'Filtros',
  'General': 'General',
  'Organization': 'Organización',
  'Inspect': 'Inspeccionar',
  'N/A': 'N/A',
  'Inspect Logs': 'Inspeccionar registros',
  'View details of the selected log': 'Ver detalles del registro seleccionado',
  'No audit logs found': 'No se encontraron registros de auditoría',
  'Updated Attributes': 'Atributos actualizados',
  'Audit Logs': 'Auditar registros',
  'View audit logs': 'Ver registros de auditoría',
  'Filter by actor type': 'Filtrar por tipo de actor',
  'Filter by organization': 'Filtrar por organización',
  'Filter by action': 'Filtrar por acción',
  'Filter by service': 'Filtrar por servicio',
  'Search context': 'Buscar contexto',
  'Filter by actor': 'Filtrar por actor',
  'Filter by': 'Filtrar por',
  'Reset all fields': 'Restablecer todos los campos',
  'Refresh data': 'Actualizar datos',
  'Location & Service': 'Ubicación y servicio',
  'Operations': 'Operaciones',
  'Management': 'Administración',
  'Thresholds': 'Umbrales',
  'Timestream Data': 'Datos de Timestream',
  'Device': 'Dispositivo',
  'Calibration': 'Calibración',
  'Video': 'Vídeo',
  'Weather': 'Clima',
  'Manager': 'Administrador',
  'Device Management': 'Administración de dispositivos',
  'Create': 'Crear',
  'Update': 'Actualizar',
  'Delete': 'Eliminar',
  'User': 'Usuario',
  'System': 'Sistema',
  'This cannot be changed later.': 'Esto no se puede cambiar posteriormente.',
  'Characters left: ': 'Caracteres restantes ',
  'Hardware': 'Hardware',
  'Calculation': 'Cálculo',
  'Software': 'Software',
  'Sensor': 'Sensor',
  'Camera': 'Cámara',
  'Motor': 'Motor',
  'Pump': 'Bomba',
  'Valve': 'Válvula',
  'Switch': 'Interruptor',
  'Core': 'Núcleo',
  'Pyranometer': 'Piranómetro',
  'Energy Monitor': 'Monitor de energía',
  'Weather Station': 'Estación meteorológica',
  'Envirosense': 'Envirosense',
  'Soilsense': 'Soilsense',
  'Envirosense Modbus': 'Envirosense Modbus',
  'Water Flow Meter': 'Caudalímetro de agua',
  'Watersense': 'Watersense',
  'Airflow Meter': 'Caudalímetro de aire',
  'Title is required': 'El título es obligatorio',
  'Help text is required': 'El texto de ayuda es obligatorio',
  'Upload logo as PNG': '',
  'Label': 'Etiqueta',
  'Replacement Label': 'Etiqueta de repuesto',
  'Replacement Label Plural': 'Plural de la etiqueta de repuesto',
  'Replacement Label Language': 'Idioma de la etiqueta de repuesto',
  'Edit': 'Editar',
  'Actions': 'Acciones',
  'Translations': 'Traducciones',
  'Language': 'Idioma',
  'Singular': 'Singular',
  'Plural': 'Plural',
  'Redo Translations': 'Rehacer traducciones',
  'Edit Label': 'Editar etiqueta',
  'Select a label to replace': 'Seleccione una etiqueta a reemplazar',
  'City, Section, Row, Seat, etc.': 'Ciudad, sección, fila, asiento, etc.',
  'Cities, Sections, Rows, Seats, etc.': 'Ciudades, secciones, filas, asientos, etc.',
  'After creating the label, we will automatically translate it into the other languages.': 'Después de crear la etiqueta, la traduciremos automáticamente a los demás idiomas.',
  'Cancel': 'Cancelar',
  'Update Label': 'Actualizar etiqueta',
  'Create Label': 'Crear etiqueta',
  'English': 'Inglés',
  'Spanish': 'Español',
  'Arabic': 'Árabe',
  'Label is a required field.': 'Etiqueta es un campo obligatorio.',
  'Replacement label is a required field.': 'Etiqueta de repuesto es un campo obligatorio.',
  'Select the language of the replacement label': 'Seleccione el idioma de la etiqueta de repuesto',
  'Replacement label plural is a required field.': 'Plural de la etiqueta de repuesto es un campo obligatorio.',
  'Label ID is required': 'Es necesario indicar el ID de la etiqueta',
  'Manage': 'Gestionar',
  'Oganization': 'Organización',
  'Labels': 'Etiquetas',
  'Add Label': 'Añadir etiqueta',
  'Options': 'Opciones',
  'Please enter an organization name': 'Introduzca un nombre de organización',
  'value': 'valor',
  'Add': 'Añadir',
  'Add new': 'Añadir nueva/o',
  'Enter a unique name and the relevant details for the': 'Introduzca un nombre único y los detalles relevantes para el',
  'name': 'nombre',
  'ID': 'ID',
  'Enter new details for': 'Introducir nuevos detalles para',
  'with ID': 'con ID',
  'No': 'No',
  'The following': 'El siguiente',
  'These are the details associated with the': 'Estos son los detalles asociados con el',
  'Data API': 'API de datos',
  'User Management': 'Administración de usuarios',
  'User Roles': 'Roles de usuario',
  'System Thresholds': 'Umbrales del sistema',
  'Control Range Management': 'Administración del rango de control',
  'No users were found': 'No se han encontrado usuarios',
  'Add User': 'Agregar usuario',
  'Role': 'Rol',
  'Username': 'Nombre de usuario',
  'Registration Date': 'Fecha de registro',
  'Status': 'Estado',
  'Last Seen': 'Visto por última vez',
  'Add Role': 'Agregar rol',
  'Organizations': 'Organizaciones',
  'Camera Access': 'Acceso a la cámara',
  'Sensor Control': 'Control del sensor',
  'Threshold Admin': 'Administrador de umbral',
  'View': 'Ver',
  'Remove User': 'Eliminar usuario',
  'Loading organizations': 'Cargando organizaciones',
  'Loading users': 'Cargando usuarios',
  'Loading roles': 'Cargando roles',
  'Loading thresholds': 'Cargando umbrales',
  'Search by user name': 'Buscar por nombre de usuario',
  'Search by role name': 'Buscar por nombre de rol',
  'Search Devices': 'Buscar dispositivos',
  'Search by name': 'Buscar por nombre',
  'Vendors': 'Proveedores',
  'View and create device vendors': 'Ver y crear proveedores de dispositivos',
  'Add vendor': 'Agregar proveedor',
  'Create a new device vendor': 'Crear un nuevo proveedor de dispositivos',
  'Save vendor': 'Guardar proveedor',
  'Vendor name': 'Nombre del proveedor',
  'Edit vendor': 'Editar proveedor',
  'Update vendor details': 'Actualizar detalles del proveedor',
  'Loading vendors': 'Cargando proveedores',
  'Vendor updated': 'Proveedor actualizado',
  'Vendor created': 'Proveedor creado',
  'Types': 'Tipos',
  'View and create device types': 'Ver y crear tipos de dispositivos',
  'Add type': 'Agregar tipo',
  'Create a new device type': 'Crear un nuevo tipo de dispositivo',
  'Save type': 'Guardar tipo',
  'Vendor': 'Proveedor',
  'Name': 'Nombre',
  'Model': 'Modelo',
  'Model version': 'Versión del modelo',
  'Firmware version': 'Versión del firmware',
  'Communications protocol (separate entries by comma': 'Protocolo de comunicaciones (entradas separadas por coma',
  'Edit type': 'Editar tipo',
  'Update type details': 'Actualizar detalles del tipo',
  'Type inputs': 'Escribir entradas',
  'Lower threshold in': 'Umbral inferior en',
  'Upper threshold in': 'Umbral superior en',
  'Remove': 'Eliminar',
  'Accept': 'Aceptar',
  'Select a measurement type': 'Seleccione un tipo de medición',
  'Loading types': 'Cargando tipos',
  'Type updated': 'Tipo actualizado',
  'Type created': 'Tipo creado',
  'Measurements': 'Mediciones',
  'View and create measurement types with custom threshold ranges': 'Ver y crear tipos de mediciones con rangos de umbrales personalizados',
  'Add measurement': 'Agregar medición',
  'Create a new device measurement': 'Crear una nueva medición de dispositivo',
  'Save measurement': 'Guardar medición',
  'Type': 'Tipo',
  'Select a type': 'Seleccionar un tipo',
  'Description': 'Descripción',
  'Short name': 'Nombre abreviado',
  'Unit of measurement': 'Unidad de medida',
  'Specify threshold range': 'Especifique el rango del umbral',
  'Upper threshold': 'Umbral superior',
  'Lower threshold': 'Umbral inferior',
  'Edit measurement': 'Editar medición',
  'Update measurement details': 'Actualizar los detalles de medición',
  'Measurement updated': 'Medición actualizada',
  'Measurement created': 'Medición creada',
  'Filter by type': 'Filtrar por tipo',
  'Flash Scripts': 'Flash Scripts',
  'View and create flash scripts': 'Ver y crear flash scripts',
  'No available flash scripts': 'No hay flash scripts disponibles',
  'Add flash script': 'Añadir flash script',
  'Edit flash script': 'Editar flash script',
  'Create new flash script': 'Crear nuevo flash script',
  'Update flash script details': 'Actualizar detalles del flash script ',
  'Device Type': 'Tipo de dispositivo',
  'Version': 'Versión',
  'Flash script debug file': 'Archivo de depuración de flash script ',
  'Flash script release file': 'Archivo de versión de flash script ',
  'Flash script release debug file': 'Archivo de depuración de versión de flash script',
  'Flash script created successfully.': 'Flash script creado correctamente.',
  'Flash script updated successfully.': 'Script flash actualizado correctamente.',
  'Device Types': 'Tipo de dispositivo(s)',
  'Device has firmware': 'El dispositivo tiene firmware',
  'Device supports Greengrass': 'Dispositivo compatible con Greengrass',
  'Add device type': 'Agregar tipo de dispositivo',
  'Edit device type': 'Editar tipo de dispositivo',
  'No available device types': 'No hay tipos de dispositivos disponibles',
  'Hardware Version': 'Versión de hardware ',
  'Firmware Versions': 'Versiones de firmware',
  'Flash Script': 'Flash Script',
  'Select versions': 'Seleccionar versiones',
  'Select version': 'Seleccionar versión',
  'Enter version': 'Introducir versión',
  'Add hardware version': 'Añadir versión de hardware',
  'No available firmware versions': 'No hay versiones de firmware disponibles',
  'Create new device type': 'Crear nuevo tipo de dispositivo',
  'Update device type details': 'Actualizar detalles del tipo de dispositivo',
  'Device type created successfully.': 'Tipo de dispositivo creado correctamente.',
  'Device type updated successfully.': 'Tipo de dispositivo actualizado correctamente.',
  'Add New User': 'Agregar nuevo usuario',
  'Create a new user and add them to the default zones.': 'Crear un nuevo usuario y agregarlo a las zonas predeterminadas.',
  'Edit User Profile': 'Editar perfil de usuario',
  'Edit user settings.': 'Editar ajustes de usuario. ',
  'View User Profile': 'Ver perfil de usuario',
  'View user settings.': 'Ver ajustes de usuario. ',
  'User Details': 'Detalles del usuario',
  'Assign Role': 'Asignar rol',
  'User Role': 'Rol de usuario',
  'First name': 'Nombre',
  'Last name': 'Apellido',
  'Email': 'Correo electrónico',
  'Phone Number': 'Número de teléfono',
  'Assign Defaults': 'Asignar valores predeterminados',
  'Save User Details': 'Guardar detalles del usuario',
  'Reset Fields': 'Restablecer campos',
  'Remove user': 'Eliminar usuario',
  'You are about to permanently remove a user.': 'Está a punto de eliminar permanentemente a un usuario.',
  'Delete user': 'Eliminar usuario',
  'All roles': 'Todos los roles',
  'All organizations': 'Todas las organizaciones',
  'Please complete all fields in the form.': 'Rellene todos los campos del formulario.',
  'Please provide a valid phone number.': 'Proporcione un número de teléfono válido.',
  'New user created.': 'Nuevo usuario creado.',
  'New user could not be created.': 'No se ha podido crear el nuevo usuario.',
  'User details updated.': 'Detalles del usuario actualizados.',
  'User could not updated.': 'No se ha podido actualizar el usuario.',
  'User deleted.': 'Usuario eliminado.',
  'User could not be deleted.': 'No se ha podido eliminar el usuario.',
  'New role created.': 'Nuevo rol creado.',
  'New role could not be created.': 'No se ha podido crear el nuevo rol.',
  'Role deleted.': 'Rol eliminado.',
  'Role could not be deleted.': 'No se ha podido eliminar el rol.',
  'Role permission added.': 'Permiso de rol agregado.',
  'Role permission not updated.': 'Permiso de rol no actualizado.',
  'Role permission removed.': 'Permiso de rol eliminado.',
  'Role permission could not be removed.': 'No se ha podido eliminar el permiso de rol.',
  'Role not fetched.': 'Rol no obtenido.',
  'Roles not fetched.': 'Roles no obtenidos.',
  'Role permissions not fetched.': 'Permisos de rol no obtenidos.',
  'Role users not fetched.': 'Usuarios del rol no obtenidos.',
  'Invitation sent.': 'Invitación enviada.',
  'Invitation could not be sent.': 'No se ha podido enviar la invitación.',
  'Permission Settings': 'Ajustes de permisos',
  'Manage the permissions granted to users with this role': 'Administrar los permisos concedidos a los usuarios con este rol',
  'Reset to previous state': 'Restablecer al estado anterior',
  'Loading permissions': 'Cargando permisos',
  'Use the main toggle to enable viewing the': 'Utilice el interruptor principal para habilitar la visualización de la/del',
  'Use the main toggle to enable editing the': 'Utilice el interruptor principal para habilitar la edición de la/del',
  'for all zones.': 'para todas las zonas.',
  'No zones found for organization': 'No se han encontrado zonas para la organización',
  'The page will refresh to get the latest zones per organization.': 'La página se actualizará para obtener las últimas zonas por organización.',
  'Add new role': 'Agregar nuevo rol',
  'Enter a unique name for the role, and select a base role to use as a template.': 'Introduzca un nombre único para el rol y seleccione un rol base para usarlo como plantilla.',
  'Role name': 'Nombre del rol',
  'Base role (inherit permissions from existing role': 'Rol base (heredar permisos del rol existente',
  'Close': 'Cerrar',
  'Create new role': 'Crear nuevo rol',
  'Select a base role': 'Seleccionar un rol base',
  'Latitude': 'Latitud',
  'Longitude': 'Longitud',
  'Timezone region': 'Región de la zona horaria',
  'Code': 'Código',
  'Data Ingestion Frequency': 'Frecuencia de ingesta de datos',
  'Enable AI Forecast': 'Habilitar pronóstico IA',
  'Enable Maintenance Mode': 'Habilitar modo mantenimiento',
  'Coordinates:': 'Coordenadas:',
  'Coordinates are undefined.': 'No se han definido coordenadas.',
  'Select a timezone': 'Seleccionar una zona horaria',
  'Select a status': 'Seleccionar un estado',
  'Save': 'Guardar',
  'Current location': 'Ubicación actual',
  'Select a new location': 'Seleccionar una nueva ubicación',
  'Show provisioning config': 'Mostrar configuración de aprovisionamiento',
  'Download Provision Installer': 'Descargar instalador de provisiones',
  'Copy to clipboard': 'Copiar en el portapapeles',
  'Generate': 'Generar',
  '1 - Download the Zip file and transfer it into the intended Greengrass device.': '1. Descargue el archivo Zip y transfiéralo al dispositivo Greengrass correspondiente.',
  '2 - On a terminal window in the device, navigate to the downloaded Zip file. Normally this is located at /home/user/downloads': '2 - En una ventana del terminal del dispositivo, acceda al archivo Zip descargado. Normalmente este se encuentra en /home/user/downloads',
  '3 - Unzip the Zip file using an unzip tool for Linux on the command line.': '3. Descomprima el archivo Zip usando una herramienta de descompresión para Linux en la línea de comandos.',
  '4 - Navigate to the Zip file and then execute the setup.sh script.': '4. Navegue hasta el archivo Zip y ejecute el script setup.sh.',
  '5 - Execute the following command: sudo /bin/bash setup.sh': '5. Ejecute el siguiente comando: sudo /bin/bash setup.sh',
  'NOTE: The setup.sh script will install all required third party tools and set up the Greengrass nucleus as a system service.': 'NOTA: El script setup.sh instalará todas las herramientas de terceros necesarias y configurará el núcleo de Greengrass como un servicio del sistema.',
  'Greengrass Core Device': 'Dispositivo central de Greengrass',
  'No core device set up yet.': 'Todavía no hay un dispositivo central configurado.',
  'No deployments for this core device.': 'No hay implementaciones para este dispositivo central.',
  'Create default deployment': 'Crear implementación predeterminada',
  'Show deployments': 'Mostrar implementaciones',
  'Deployments': 'Implementaciones',
  'Reason': 'Razón',
  'Refetch core device': 'Obtener dispositivo central de nuevo',
  'Provisioning Config': 'Configuración de aprovisionamiento',
  'Timezone': 'Zona horaria',
  'The following thresholds are part of': 'Los siguientes umbrales forman parte de',
  'Managers': 'Administradores',
  'The following users can manage the location': 'Los siguientes usuarios pueden administrar la ubicación',
  'Fleet Management': 'Gestión de flotas',
  'Core devices and sensor management for': 'Dispositivos centrales y gestión de sensores para',
  'OTA update by sensor': 'Actualizar OTA por sensor',
  'OTA update by core device': 'Actualizar OTA por dispositivo central',
  'Core device update': 'Actualización de dispositivo central',
  'Query NVS data': 'Solicitar datos NVS',
  'Query device and sensor status': 'Consultar estado de dispositivo y sensor',
  'Request SIM diagnostic data': '',
  'Request SIM diagnostic ping': '',
  'Select one more devices and/or sensors from the list below, followed by the action.': 'Seleccione un dispositivo y/o sensor más de la siguiente lista, seguido por la acción.',
  'Select one more core devices from the list below, followed by the action.': 'Seleccione un dispositivo central más de la siguiente lista, seguido por la acción.',
  'Select one more sensors from the list below, followed by the action.': 'Seleccione un sensor más de la siguiente lista, seguido por la acción.',
  'Command & Control': 'Comando y control',
  'Control pumps and valves with Greengrass in': 'Válvulas y bombas de control con Greengrass en',
  'Edit settings': 'Editar ajustes',
  'Ingestion frequency updated': 'Frecuencia de ingesta actualizada',
  'The selected values are already applied.': 'Los valores seleccionados ya están aplicados.',
  'Reset selection': 'Restablecer selección',
  'Devices Selected': 'Dispositivos seleccionados',
  'Sensors Selected': 'Sensores seleccionados',
  'Devices Active': 'Dispositivos activos',
  'Sensors Active': 'Sensores activos',
  'Perform Action': 'Realizar acción',
  'Running': 'Ejecutándose',
  'Unknown': 'Desconocido',
  'Add control device configuration': 'Agregar configuración de dispositivo de control',
  'Edit control device configuration': 'Editar configuración de dispositivo de control',
  'Specify control device specification': 'Indicar especificación de dispositivo de control',
  'Add configuration': 'Añadir configuración',
  'Edit configuration': 'Editar configuración',
  'Delete configuration': 'Borrar configuración',
  'View specification': 'Ver especificación',
  'No control devices configured.': 'No hay dispositivos de control configurados.',
  'Address': 'Dirección',
  'Slave address': 'Dirección del esclavo',
  'Baudrate': 'Velocidad de transmisión',
  'Relay configuration': 'Configuración relé',
  'Relay ID': 'ID relé',
  'Register number': 'Registrar número',
  'Select sensor': 'Seleccionar sensor',
  'Select relay by ID': 'Seleccionar relé por ID',
  'Measurement': 'Medición',
  'Select measurement': 'Seleccionar medida',
  'Configure conditions': 'Configurar condiciones',
  'Minimum': 'Mínimo',
  'Enter min value': 'Introduzca valor mínimo',
  'Maximum': 'Máximo',
  'Enter max value': 'Introduzca valor máximo',
  'Add specification': 'Añadir especificación',
  'Remove configuration from Greengrass core device': 'Eliminar la configuración del dispositivo central de Greengrass',
  '\'Are you sure you want to remove this configuration? It will remove any specification attached to it. This action cant be undone.\'': '\'¿Está seguro de que desea eliminar esta configuración? Eliminará cualquier especificación adjunta. Esta acción no se puede deshacer.\'',
  'Remove relay from device configuration': 'Eliminar el relé de la configuración del dispositivo',
  '\'Are you sure you want to remove this relay? It currently has a specification attached to it. This action cant be undone.\'': '\'¿Está seguro de que desea eliminar este relé? Actualmente tiene una especificación adjunta. Esta acción no se puede deshacer.\'',
  'Remove the': 'Eliminar el/la',
  'role': 'rol',
  'You are about to remove a role.': 'Está a punto de eliminar un rol.',
  'Select a fallback role for affected users.': 'Seleccione un rol alternativo para los usuarios afectados.',
  'Select a fallback role': 'Seleccione un rol alternativo',
  'Affected users': 'Usuarios afectados',
  'You can not delete a role that has users. Please assign a new role to each of the following users to continue.': 'No puede eliminar un rol que esté asignado a usuarios. Asigne un nuevo rol a cada uno de los siguientes usuarios para continuar.',
  'Delete role': 'Eliminar rol',
  'No users are currently assigned this role. This role can be safely deleted.': 'Actualmente no hay ningún usuario asignado a este rol. Este rol se puede eliminar de forma segura.',
  'No available devices.': 'No hay dispositivos disponibles.',
  'Location': 'Ubicación',
  'Tag': 'Etiqueta',
  'Created': 'Creado',
  'Firmware': 'Firmware',
  'In use': '',
  'Status last updated': 'Última actualización del estado',
  'Select an action': 'Seleccionar una acción',
  'Active': 'Activo',
  'Unreachable': 'Inaccesible',
  'Pending': 'Pendiente',
  'Modbus': 'Modbus',
  'IoT': 'IdC',
  'Release': 'Versión',
  'Release Debug': 'Versión debug',
  'Debug': 'Debug',
  'Greengrass': 'Greengrass',
  'Direct Connect': 'Direct Connect',
  'Enroll device': 'Inscribir dispositivo',
  'Update type': 'Tipo de actualización',
  'Regenerate firmware': 'Regenerar firmware',
  'Download firmware': 'Descargar firmware',
  'Configure Device': 'Configurar dispositivo',
  'Ionsense': 'Ionsense',
  'Solarsense': 'Solarsense',
  'Devices': 'Dispositivos',
  'The following devices are part of': 'Los siguientes dispositivos forman parte de',
  'Enroll new device': 'Inscribir nuevo dispositivo',
  'Add new device': 'Agregar nuevo dispositivo',
  'Add device': 'Agregar dispositivo',
  'Edit device': 'Editar dispositivo',
  'Refresh': 'Actualizar',
  'Fetch supplier device': 'Obtener dispositivo proveedor',
  'Create new device': 'Crear nuevo dispositivo',
  'Update device': 'Actualizar dispositivo',
  'Device Version': 'Versión del dispositivo',
  'Connection Method': 'Método de conexión',
  'Connection Type': 'Tipo de conexión',
  'Is IoT Sensor?': '¿Es sensor IdC?',
  'Hardware Version Name': 'Nombre de la versión de hardware',
  'Please select a version': 'Seleccione una versión',
  'Create a new device in': 'Crear un nuevo dispositivo en',
  'Edit existing device in': 'Editar dispositivo existente en',
  'Device created successfully.': 'Dispositivo creado correctamente.',
  'Device updated successfully.': 'Dispositivo actualizado correctamente.',
  'Device deleted successfully.': 'Dispositivo eliminado correctamente.',
  'Unenroll': 'Desinscribir',
  'Select a type of device': 'Seleccionar un tipo de dispositivo',
  'Display Configuration': 'Configuración de visualización',
  'Remove Device': 'Eliminar dispositivo',
  'Are you sure you want to remove this device?': '¿Está seguro de que desea eliminar este dispositivo?',
  'Unenroll Device': 'Desinscribir dispositivo',
  'Are you sure you want to unenroll this device? You will need to start the enrollment process again.': '¿Está seguro de que desea desinscribir este dispositivo? Deberá iniciar el proceso de inscripción nuevamente.',
  'Are you sure you want to update the type of this device?': '¿Está seguro de que desea actualizar el tipo de este dispositivo?',
  'Request NVS dump': 'Solicitar volcado NVS',
  'No timestamp': 'Sin marca de tiempo',
  'No tag set': 'Sin etiqueta establecida',
  'Get current coordinates': 'Obtener coordenadas actuales',
  'These coordinates are based on your current location. Stand as close to the device as possible to get the most accurate location coordinates.': 'Estas coordenadas se basan en su ubicación actual. Colóquese lo más cerca posible del dispositivo para obtener las coordenadas de ubicación más precisas.',
  'Refetch location': 'Volver a obtener ubicación',
  'Use coordinates': 'Usar coordenadas',
  'Unavailable': 'No disponible',
  'SecondSky Data does not have permission to access to your location. Update your browser settings to enable this feature.': '',
  'The device location has changed. Please update the coordinates to match the new location.': 'La ubicación del dispositivo ha cambiado. Actualice las coordenadas para que coincidan con la nueva ubicación.',
  'Filter by tags': 'Filtrar por etiquetas.',
  'Port Address': 'Dirección del puerto',
  'Slave Address': 'Dirección del esclavo',
  'Baud Rate': 'Tasa de baudios',
  'Byte Size': 'Tamaño de byte',
  'Stop Bits': 'Bits de parada',
  'Timeout': 'Tiempo de espera',
  'Multiple register reads': 'Múltiples lecturas de registro',
  'Select Type': 'Seleccionar tipo',
  'Read Registers': 'Leer registros',
  'Read Measurement Type': 'Leer tipo de medición',
  'Read Measurement Unit': 'Leer unidad de medición',
  'Register': 'Registro',
  'Count': 'Contador',
  'Unit': 'Unidad',
  'Add new manager': 'Agregar nuevo administrador',
  'Select a user and notification channels for a location': 'Seleccione un usuario y canales de notificación para una ubicación',
  'Edit manager': 'Editar administrador',
  'Edit notification channels for user in a location': 'Editar canales de notificación para el usuario en una ubicación',
  'Add manager': 'Agregar administrador',
  'Settings': 'Configuración',
  'No available managers.': 'No hay administradores disponibles.',
  'An error occurred': 'Se ha producido un error',
  'Push Notification': 'Notificación push',
  'SMS': 'SMS',
  'Web Push': 'Web push',
  'WhatsApp': 'WhatsApp',
  'Please select a user': 'Seleccione un usuario',
  'Create new manager': 'Crear nuevo administrador',
  'Update manager': 'Actualizar administrador',
  'Manager created successfully.': 'Administrador creado correctamente.',
  'Manager updated successfully.': 'Administrador actualizado correctamente.',
  'Manager deleted successfully.': 'Administrador eliminado correctamente.',
  'You are about to remove a manager.': 'Está a punto de eliminar un administrador.',
  'Delete manager': 'Eliminar administrador',
  'The selected settings are already applied.': 'Los ajustes seleccionados ya están aplicados.',
  'Add threshold': 'Agregar umbral',
  'Message': 'Mensaje',
  'No available thresholds.': 'No hay umbrales disponibles.',
  'Edit Threshold': 'Editar umbral',
  'Add New Threshold': 'Agregar nuevo umbral',
  'Save Threshold': 'Guardar umbral',
  'New threshold created.': 'Nuevo umbral creado.',
  'Threshold details updated.': 'Detalles del umbral actualizados.',
  'Threshold deleted.': 'Umbral eliminado.',
  'threshold from': 'umbral de',
  'You are about to remove a threshold.': 'Está a punto de eliminar un umbral.',
  'Delete threshold': 'Eliminar umbral',
  'When value is above': 'Cuando el valor está por encima',
  'When value is below': 'Cuando el valor está por debajo',
  'Upper Bound Conditions': 'Condiciones del límite superior',
  'Lower Bound Conditions': 'Condiciones del límite inferior',
  'Upper bound': 'Límite superior',
  'Lower bound': 'Límite inferior',
  'Conditions fall outside of the optimal range. You should keep an eye on this measurement and take action if it does not stabilise.': 'Las condiciones se encuentran fuera del rango óptimo. Debe estar atento a esta medición y tomar medidas si no se estabiliza.',
  'Conditions fall outside of the acceptable range. You should take action to prevent reaching a stage where damage occurs.': 'Las condiciones se encuentran fuera del rango aceptable. Debe tomar medidas para evitar llegar a una etapa en la que se produzcan daños.',
  'Conditions are adverse. You should take action as soon as possible to prevent irreversible damage.': 'Las condiciones son adversas. Debe tomar medidas lo antes posible para evitar daños irreversibles.',
  'Cameras': 'Cámaras',
  'Permissions': 'Permisos',
  'Data': 'Datos',
  'Users': 'Usuarios',
  'Groups': 'Grupos',
  'Docs': 'Documentos.',
  'Roles': 'Roles',
  'Systems': 'Sistemas',
  'Enroll Device': 'Inscribir dispositivo',
  'Search Device': 'Buscar dispositivo',
  'Download Firmware': 'Descargar firmware',
  'Firmware Downloaded': 'Firmware descargado',
  'Download Device Firmware': 'Descargar firmware del dispositivo',
  'Device Firmware Downloaded': 'Firmware del dispositivo descargado',
  'Enroll Ethernet Device': 'Inscribir dispositivo Ethernet',
  'Enroll Wifi Device': 'Inscribir dispositivo wifi',
  'Pair': 'Emparejar',
  'Paired': 'Emparejado',
  'Pair with Device': 'Emparejar con dispositivo',
  'Paired with Device': 'Emparejado con dispositivo',
  'Device Name Prefix': 'Prefijo del nombre del dispositivo',
  'Connect': 'Conectar',
  'Connected': 'Conectado',
  'Establish Secure Connection': 'Establecer conexión segura',
  'Established Secure Connection': 'Conexión segura establecida',
  'SSID': 'SSID',
  'Password': 'Contraseña',
  'Configure': 'Configurar',
  'Configured': 'Configurado',
  'Send Wifi Config': 'Enviar config. wifi',
  'Wifi Config Sent': 'Config. wifi enviada',
  'Connect Device': 'Conectar dispositivo',
  'Device Connected': 'Dispositivo conectado',
  'Connect Device to Wifi': 'Conectar dispositivo a wifi',
  'Device Connected to Wifi': 'Dispositivo conectado a wifi',
  'Set Location': 'Establecer ubicación',
  'Location Set': 'Ubicación establecida',
  'Set Device Location': 'Establecer ubicación del dispositivo',
  'Device Location Set': 'Ubicación del dispositivo establecida',
  'Set Tag': 'Establecer etiqueta',
  'Tag Set': 'Etiqueta establecida',
  'Set Device Tag': 'Establecer etiqueta de dispositivo',
  'Device Tag Set': 'Etiqueta de dispositivo establecida',
  'Max. 255 characters': 'Máx. 255 caracteres',
  'Disable device': '',
  'Successfully enrolled device': 'Dispositivo inscrito correctamente',
  'View Device': 'Ver dispositivo',
  'Enroll Another Device': 'Inscribir otro dispositivo',
  'An Error Occurred': 'Se ha producido un error',
  'All': 'Todo',
  'No organizations found': 'No se han encontrado organizaciones',
  'Create new organization': 'Crear nueva organización',
  'Add organization': 'Agregar organización',
  'Save organization': 'Guardar organización',
  'New organization created.': 'Nueva organización creada.',
  'Edit organization': 'Editar organización',
  'Organization updated.': 'Organización actualizada.',
  'Search by organization name': 'Buscar por nombre de organización',
  'Select an organization': 'Seleccione una organización',
  'Role organizations': 'Organizaciones rol',
  'Logs': 'Registros',
  'View activity logs for': 'Ver registros de actividad por',
  'Time': 'Tiempo',
  'Successful logins': 'Inicios de sesión correctos',
  'Loading logs': 'Cargando registros',
  'Unsuccessful logins': 'Inicios de sesión incorrectos',
  'Active sessions': 'Sesiones activas',
  'No logs to display': 'No hay registros que mostrar',
  'Fetch logs': 'Obtener registros',
  'Start date': 'Fecha de inicio',
  'End date': 'Fecha de fin',
  'Usage plan': 'Plan de uso',
  'There is no usage plan for this organization.': 'No existe un plan de uso para esta organización.',
  'Throttle': 'Acelerador',
  'Burst limit': 'Límite de rotura',
  'Rate limit': 'Límite de tasa',
  'Quota': 'Cuota',
  'Limit': 'Límite',
  'Offset': 'Compensación',
  'Period': 'Periodo',
  'Add usage plan': 'Añadir plan de uso',
  'View usage plan': 'Ver plan de uso',
  'Confirm new usage plan': 'Confirmar nuevo plan de uso',
  'Are you sure you want to add a usage plan for this organization?': '¿Está seguro de que desea añadir un plan de uso para esta organización?',
  'Confirm': 'Confirmar',
  'Save changes': 'Guardar cambios',
  'Edit usage plan': 'Editar plan de uso',
  'is required': 'es necesario',
  'must be a number': 'debe ser un número',
  'No values have been changed': 'No se ha modificado ningún valor',
  'Feature Flags': 'Indicadores de características',
  'Suppliers': 'Proveedores',
  'Go back': 'Volver',
  'FYI': 'Para su información',
  'Help text': 'Texto de ayuda',
  'Manage help text shown in tooltips': 'Administrar texto de ayuda mostrado en tooltip',
  'Release notes': 'Notas de la versión',
  'Tooltip ID': 'ID tooltip',
  'Default text (English': 'Texto predeterminado (inglés)',
  'Add new entry': 'Añadir nueva entrada',
  'Add Help Text': 'Añadir texto de ayuda',
  'Edit Help Text': 'Editar texto de ayuda',
  'Create a new help text entry.': 'Crear una nueva entrada de texto de ayuda.',
  'Update existing help text': 'Actualizar texto de ayuda existente',
  'Release note text': 'Texto de la nota de la versión',
  'Generate from prompt': 'Generar desde mensaje de solicitud',
  'Tags': 'Etiquetas',
  'Generate translations': 'Generar traducciones',
  'Generate Help Text': 'Generar texto de ayuda',
  'Use a prompt to generate suggested help text': 'Usar un mensaje de solicitud para generar texto de ayuda sugerido',
  'Prompt': 'Mensaje de solicitud',
  'Result': 'Resultado',
  'Generate text': 'Generar texto',
  'Use suggested text': 'Usar texto sugerido',
  'Select tags': 'Seleccionar etiquetas',
  'View and manage translations': 'Ver y administrar traducciones',
  'The following translations will be updated:': 'Se van a actualizar las siguientes traducciones:',
  'Title': 'Título',
  'No translation available': 'No hay traducciones disponibles',
  'Sections': 'Secciones',
  'Section': 'Sección',
  'Manage release notes sections': 'Administrar secciones de notas de la versión',
  'Add section': 'Añadir sección',
  'Manage release notes': 'Administrar notas de la versión',
  'Create a new release note content item': 'Crear un nuevo elemento de contenido de nota de la versión',
  'Update the selected release note content item': 'Actualizar el elemento de contenido de la nota de la versión seleccionado',
  'Text': 'Texto',
  'Select a section': 'Seccione una sección',
  'Add release note': 'Todavía no se ha añadido ningún contenido',
  'Create a new release note': 'Crear una nueva nota de la versión',
  'Content': 'Contenido',
  'No content added yet': 'Todavía no se ha añadido contenido',
  'Manage sections': 'Administrar secciones',
  'Add content item': 'Agregar elemento de contenido',
  'Edit content item': 'Editar elemento de contenido',
  'Publish date': 'Fecha de publicación',
  'Published': 'Publicada/o(s)',
  'Visible to users': 'Visible para los usuarios',
  'Edit release note': 'Editar nota de la versión',
  'Update the release note details': 'Actualizar los detalles de la nota de la versión',
  'Manage tags for help text and release notes': 'Administrar etiquetas para texto de ayuda y notas de la versión',
  'Add tag': 'Añadir etiqueta',
  'Create a new FYI tag': 'Crear una nueva etiqueta para su información',
  'Edit tag': 'Editar etiqueta',
  'Update the tag name': 'Actualizar el nombre de la etiqueta',
  'Manage labels': 'Administrar etiquetas',
  'Inactive': 'Inactivo',
  'Edit user': 'Editar usuario',
  'View logs': 'Ver registros',
  'Send invitation': 'Enviar invitación',
  'Feature flags': 'Indicadores de características',
  'Unconfirmed': 'Sin confirmar',
  'Confirmed': 'Confirmada/o(s)',
  'Archived': 'Archivada/o(s)',
  'Compromised': 'Comprometida/o(s)',
  'Reset required': 'Es necesario restablecer',
  'Invitation not used': 'Invitación no utilizada',
  'No invitation sent': 'No se ha enviado invitación',
  'docs': 'docs',
  'organization': 'organización',
  'Configuration': 'Configuración',
  'Unique ID': 'ID único',
  'Relays': 'Relés',
  'Register number is required.': 'Es necesario indicar el número de registro.',
  'Address is required': 'Es necesario indicar la dirección',
  'Slave address is required': 'Es necesario indicar la dirección esclava',
  'Baudrate is required': 'Es necesario indicar la velocidad de transmisión',
  'You must include at least one relay configuration.': 'Debe incluir al menos una configuración de relé.',
  'Please select a relay': 'Seleccione un relé',
  'Please select a sensor': 'Seleccione un sensor',
  'Please select a measurement': 'Seleccione una medición',
  'Mininum value is required': 'Es necesario indicar un valor mínimo',
  'Maximum value is required': 'Es necesario indicar un valor máximo',
  'You must include at least one specification.': 'Debe incluir al menos una especificación.',
  'Please provide a value': 'Proporcione un valor',
  'Only numbers are supported': 'Solo se admiten números',
  'Frequency can not be less than one': 'La frecuencia no puede ser inferior a uno',
  'Sleep Cycle (minutes': '',
  'Edit Modbus Configuration': 'Editar configuración Modbus',
  'Configure SIM': '',
  'Device is being moved': 'El dispositivo se está trasladando ',
  'The device': 'El dispositivo',
  'is being moved to another location and will take a few minutes to complete.': 'se está trasladando a otra ubicación y tardará unos minutos en completarse.',
  'You will be unable to move the device again for the next 15 minutes.': 'No podrá volver a trasladar el dispositivo durante los próximos 15 minutos.',
  'Make sure to physically move the device to the new location.': 'Asegúrese de trasladar físicamente el dispositivo a la nueva ubicación.',
  'Move device': 'Trasladar dispositivo',
  'Move device will be unlocked': 'Trasladar dispositivo se desbloqueará',
  'Select a new location to move the selected device': 'Seleccione una nueva ubicación para trasladar el dispositivo seleccionado',
  'in': 'en',
  'is being moved to': 'se está moviendo a',
  'Move Device': 'Trasladar dispositivo',
  'Confirm move': 'Confirmar movimiento',
  'Function Code': '',
  'Select the measurements to read from the device.': 'Seleccionar medidas a leer desde el dispositivo.',
  'Select a measurement': 'Seleccionar una medida',
  'The register value must be between 0 and 247.': 'El valor del registro debe estar entre 0 y 247.',
  'Edit the Modbus configuration for this device.': 'Editar la configuración Modbus para este dispositivo.',
  'Modbus Configuration JSON': 'JSON de configuración Modbus',
  'Save Changes': 'Guardar cambios',
  'Suspended': '',
  'Soracom': '',
  'SIM Configuration': '',
  'View and edit the SIM configuration for this cellular device.': '',
  'Error fetching SIM details. Our team has been notified.': '',
  'Provider': '',
  'Sim ID': '',
  'Select Status': 'Seleccionar estado',
  'Suspending a SIM is irreversible. Proceed with caution.': '',
  'Go Back': 'Volver',
  'There seems to be an issue with the device configuration and it cannot be updated anymore. Please contact support.': 'Parece que hay un problema con la configuración del dispositivo y ya no se puede actualizar. Póngase en contacto con el servicio de asistencia.',
  'is a required field.': 'es un campo obligatorio.',
  'Latitude must be a number between -90 and 90.': 'La latitud debe ser un número entre -90 y 90.',
  'Longitude must be a number between -180 and 180.': 'La longitud debe ser un número entre -180 y 180;',
  'Bytesize': 'Tamaño byte',
  'Stopbits': 'Bits de parada',
  'Function Code must be 3 or 4': '',
  'Ping Sensor SIM': '',
  'Success': 'Correcto',
  'RTT': '',
  'Yes': '',
  '\'Ping Sensors SIM\'': '',
  'No Greengrass Devices': 'Sin dispositivos Greengrass',
  'No Direct Connect Sensors': 'Sin sensores Direct Connect',
  'OTA update started.': 'Actualización OTA iniciada.',
  'OTA Device Update': 'Actualización dispositivo OTA',
  'Core Device Update': 'Actualización dispositivo central',
  'Destination': 'Destino',
  'Devices to be updated:': 'Dispositivos a actualizar:',
  'This action will update all Greengrass sensors within the device(s': 'Esta acción actualizará todos los sensores de Greengrass dentro del/de los dispositivo(s)',
  'Sensors to be updated:': 'Sensores a actualizar:',
  'Core Devices for update:': 'Dispositivos centrales para actualizar:',
  'Submit': 'Enviar',
  'OTA Update by Sensor': 'Actualizar OTA por sensor',
  'OTA Update by Core Device': 'Actualizar OTA por dispositivo central',
  'Request NVS Dump': 'Solicitar volcado NVS',
  'Request Device Status': 'Solicitar estado de dispositivo',
  'Request SIM Diagnostic Data': '',
  'Request SIM Diagnostic Ping': '',
  'Request Sensor Diagnostic Data': '',
  'Restart Sensor': '',
  'Communications Board': 'Tarjeta de comunicaciones',
  'Select all sensors': 'Seleccionar todos los sensores',
  'Select only the device': 'Seleccionar solo el dispositivo',
  'Select all sensors and device': 'Seleccionar todos los sensores y el dispositivo',
  'User is required': 'Se requiere usuario',
  'Selected zone to move': 'Zona seleccionada para moverla',
  'Select a new location to move the selected location and their children to.': 'Seleccionar una nueva ubicación a la que mover la ubicación seleccionada y sus hijos.',
  'This': 'Este',
  'Move location': 'Mover ubicación',
  'Loading': 'Cargando',
  'Search': 'Buscar',
  'Check your mailbox': 'Revise su buzón de correo',
  'A verification code was sent to the email address you provided. Enter the code into the field below to activate your account. Do not leave this page until you have completed this step.': 'Se ha enviado un código de verificación a la dirección de correo electrónico que ha proporcionado. Introduzca el código en el siguiente campo para activar su cuenta. No abandone esta página hasta que haya completado este paso.',
  'Verification code': 'Código de verificación',
  'Continue': 'Continuar',
  '\'By continuing you agree to SecondSky Datas \'': '',
  'Privacy Policy': 'Política de privacidad de Coretex',
  'The provided device ID is invalid.': 'El ID de dispositivo proporcionado no es válido.',
  'You need a valid device ID to create an account. Try scanning the QR code on your device again, or contact your supplier.': 'Necesita un ID de dispositivo válido para crear una cuenta. Intente escanear el código QR en su dispositivo de nuevo o póngase en contacto con su proveedor.',
  'Device is already enrolled.': 'Dispositivo ya inscrito.',
  'This device is already enrolled on SecondSky Data. You can sign in to see enrolled devices. If you do not have an account, ask a user from your organization to invite you to the platform.': '',
  'Sign in or create a new account to enroll your device.': 'Inicie sesión o cree una cuenta nueva para inscribir su dispositivo.',
  'Confirm password': 'Confirmar contraseña',
  'Phone number': 'Número de teléfono',
  'This will create a new organization. To join an existing organization, you must be invited by a user in that organization.': 'Esto creará una nueva organización. Para unirse a una organización existente, un usuario de esa organización le debe invitar.',
  'Sign in to SecondSky Data': '',
  'Username or email': 'Nombre de usuario o correo electrónico',
  'Sign In': 'Iniciar sesión',
  'An error ocurred': 'Se ha producido un error',
  'Forgot Password?': '¿Ha olvidado la contraseña?',
  'Forgot password': 'Contraseña olvidada',
  'New password': 'Nueva contraseña',
  'Re-enter new password': 'Vuelva a introducir la nueva contraseña',
  'Your password must contain at least eight characters including one symbol, one capital letter and one number.': 'Su contraseña debe contener al menos ocho caracteres, incluido un símbolo, una letra mayúscula y un número.',
  'Verify': 'Verificar',
  'Change password': 'Cambiar contraseña',
  'The email address associated with your account is not yet verified:': 'La dirección de correo electrónico asociada a su cuenta aún no está verificada:',
  'A verification code has been sent to your email address.': 'Se ha enviado un código de verificación a su dirección de correo electrónico.',
  'Password reset complete.': 'Restablecimiento de contraseña completado.',
  'Back to Sign In': 'Volver a iniciar sesión',
  'Skip': 'Omitir',
  'Verify your account': 'Verifique su cuenta',
  'Multi-factor authentication': 'Autenticación multifactor',
  'Open your authenticator app to view the authentication code, then enter the code into the field below.': 'Abra su aplicación Authenticator para ver el código de autenticación e introduzca el código en el siguiente campo.',
  'Passwords do not match': 'Las contraseñas no coinciden',
  'User not found': 'Usuario no encontrado;',
  'Please first use the temporary password you received email.': 'Utilice primero la contraseña temporal que ha recibido por correo electrónico.',
  'You have entered an invalid username/email or password': 'Ha introducido un nombre de usuario/correo electrónico no válidos',
  'Don\\t have an account?': '¿No tiene una cuenta?',
  'Register here.': 'Regístrese aquí.',
  'Create an account': 'Crear una cuenta',
  'Enter user details': 'Introducir detalles del usuario',
  'Verify email address': 'Verificar dirección de correo electrónico',
  'Calibration History': 'Historial de calibraciones',
  'History': 'Historial',
  'No Data': 'No hay datos',
  'Select columns': 'Seleccionar columnas',
  'Columns': 'Columnas',
  'Show Graph': 'Mostrar gráfico',
  'Show Table': 'Mostrar tabla',
  'Reset': 'Restablecer',
  'Search...': 'Buscar...',
  'Calibrate': 'Calibrar',
  'Successfully calibrated': 'Calibrado correctamente',
  'Enter': 'Introducir',
  'Using the reference': 'Usando la referencia',
  'Enter the value into the text box in the adjacent form.': 'Introduzca el valor en el cuadro de texto del formulario adyacente.',
  'Press Calibrate button.': 'Pulse el botón Calibrar.',
  'Calibration Success': 'Calibración correcta',
  'Calibration Procedure': 'Procedimiento de calibración',
  'Back': 'Atrás',
  'Min:': 'Mín.',
  'Max:': 'Máx:',
  'Humidity': 'Humedad',
  'EC': 'CE',
  'factor': 'factor',
  'reference value': 'valor de referencia',
  'Calibrate by factor': 'Calibrar por factor',
  'Calibrate by reference value': 'Calibrar por valor de referencia',
  'Refetch history': 'Volver a obtener historial',
  'No calibration data': 'No hay datos de calibración',
  'Cap Coefficients': 'Coeficientes cap',
  'User Coefficients': 'Coeficientes de usuario',
  'Enter Salinity value': 'Introduzca valor de salinidad',
  'Pressure': 'Presión',
  'Enter Pressure value': 'Introduzca valor de presión',
  'Enter coefficient': 'Introduzca coeficiente',
  '2 point calibration': 'Calibración de dos puntos',
  'low point': 'punto bajo',
  'high point': 'punto alto',
  'Calibrate ph1': 'Calibrar ph1',
  'Calibrate ph2': 'Calibrar ph2',
  '2 point source calibration': 'Calibración origen de dos puntos',
  'Factor source calibration': 'Calibración origen factor',
  'Use Previous Generation': 'Usar generación anterior',
  'Download Datasets': 'Descargar conjuntos de datos',
  'Download all dashboard line chart datasets as CSV': 'Descargar todos los conjuntos de datos de gráficos de líneas del panel como CSV',
  'Zipping data... Do not close or refresh the page.': 'Comprimiendo datos en formato zip No cierre ni actualice la página.',
  'You are about to download a zip file containing': 'Está a punto de descargar un archivo zip que contiene',
  'line chart datasets.': 'conjuntos de datos de gráficos de líneas.',
  'This may take a while depending on the number of datasets.': 'Esto puede tardar un tiempo dependiendo del número de conjuntos de datos.',
  'Download': 'Descargar',
  'Layout': 'Disposición',
  'Select layout': 'Seleccionar disposición',
  'Layout tools': 'Herramientas de disposición',
  'Location layouts': 'Disposición para ubicaciones',
  'Measurement layouts': 'Disposición para mediciones',
  'Reset to': 'Restablecer a',
  'default layout': 'disposición predeterminada',
  'Create layout from current': 'Crear disposición a partir de la actual',
  'Clear current layout': 'Borrar disposición actual',
  'Real-time': 'Tiempo real',
  'Average Value (Last 24 Hours - Hourly': 'Valor medio (últimas 24 horas, por hora)',
  'Dataset Line (Last 24 Hours - Hourly': 'Línea de conjunto de datos (últimas 24 horas, por hora)',
  'Dataset Line (Last 7 Days - Daily': 'Línea de conjunto de datos (últimos 7 días, por día)',
  'Dataset Line (Last 7 Days - Hourly': 'Línea de conjunto de datos (últimos 7 días, por hora)',
  'is above': 'está por encima',
  'is below': 'está por debajo',
  'Exceeded on': 'Excedido el',
  'threshold is': 'el umbral es',
  'Snooze Alert': 'Alerta de repetición',
  'Select Sensor': 'Seleccionar sensor',
  'someSelectedSensorsSnoozed ? Cancel : Snooze': '¿algunos sensores seleccionados con alerta de repetición? Cancelar: repetición',
  'is not sending data for:': 'no envía datos para:',
  'Delete Notification': 'Eliminar notificación',
  'Above on': 'Por encima en',
  'Below on': 'Por debajo en',
  'Delete notification for': 'Eliminar notificación para ',
  'This action cannot be undone.': 'Esta acción no se puede deshacer.',
  'is below threshold': 'está por debajo del umbral',
  'is above threshold': 'está por encima del umbral',
  'for': 'para',
  'notification': 'notificación',
  'caution': 'precaución',
  'warning': 'advertencia',
  'Snooze': 'Posponer',
  'Snoozed': 'Pospuesto',
  'min': 'min.',
  'mins': 'mins.',
  'Alert in': 'Alerta en',
  'Select a widget': 'Seleccionar un widget',
  'Miscellaneous': 'Varios',
  'Plan': 'Plan',
  'Harvest': 'Cosecha',
  'Live': 'En directo',
  'Total Dissolved Solids': 'Sólidos disueltos totales',
  'Soil Phosphorus Content': 'Contenido de fósforo del suelo',
  'Soil Potassium Content': 'Contenido de potasio del suelo',
  'Soil Nitrogen Content': 'Contenido de nitrógeno del suelo',
  'Battery SoC': 'Estado de carga de la batería ',
  'Select a time format': 'Seleccione un formato de hora',
  '24h': '24 h',
  '12h': '12 h',
  'Not applicable': '',
  'No crops': 'No hay cultivos',
  'Crops': 'Cultivos',
  'Loading produces': 'La carga produce',
  'Select a query is required': 'Es necesario seleccionar una consulta',
  'Select a chart is required': 'Es necesario seleccionar una tabla',
  'Select a range is required': 'Es necesario seleccionar un rango',
  'Select a produce is required': 'Es necesario seleccionar un producto',
  'Select an interval is required': 'Es necesario seleccionar un intervalo',
  'Select a value': 'Seleccionar un valor',
  'Kg': 'Kg',
  'Produce': 'Producto agrícola',
  'Total Harvested Produce': 'Total de producto agrícola cosechado',
  'Total Produce': 'Total de producto agrícola',
  'Top 10 Harvested Varieties': 'Las diez mejores variedades cosechadas',
  'Top 10 Varieties': 'Las diez mejores variedades',
  'Planned vs harvested varieties': 'Variedades planificadas frente a cosechadas',
  'Planned vs Harvested': 'Planificadas frente a cosechadas',
  'Current Crops': '',
  'Daily Energy Consumption': 'Consumo diario de energía',
  'Energy KW': 'Energía KW',
  'No data': 'No hay datos',
  'Subtitle': 'Subtítulo',
  '+1 hour': '+1 hora',
  'Average': 'Promedio',
  'Dataset': 'Conjunto de datos',
  'Value': 'Valor',
  'Line': 'Línea',
  'Scatterplot': 'Gráfico de dispersión',
  'Pie': 'Gráfico circular',
  'Bar': 'Barras',
  'Matrix': 'Matriz',
  'Last hour': 'Última hora',
  'Today': 'Hoy',
  'Last 24 hours': 'Últimas 24 horas',
  'Last 7 days': 'Últimos 7 días',
  'Last 30 days': 'Últimos 30 días',
  'Last 3 months': 'Últimos 3 meses',
  'Current year': 'Año actual',
  'Last year': 'Año anterior',
  'Use range interval': 'Utilizar intervalo de rango',
  'Minutely': 'Cada minuto',
  'Hourly': 'Cada hora',
  'Daily': 'Cada día',
  'Weekly': 'Semanalmente',
  'Monthly': 'Mensualmente',
  'Edit Widget': 'Editar Widget',
  'Configure the widget settings.': 'Configurar los ajustes del widget.',
  'Edit the widget settings': 'Editar los ajustes del widget.',
  'Select a query': 'Seleccionar una consulta',
  'Select a chart': 'Seleccionar un gráfico',
  'Select a range': 'Seleccionar un rango',
  'Select an interval': 'Seleccionar un intervalo',
  'Select a produce': 'Seleccionar un producto',
  '10cm': '',
  '20cm': '',
  '30cm': '',
  '40cm': '',
  '50cm': '',
  'Moisture': '',
  'ºF': '',
  'Next 7 days': 'Próximos 7 días',
  'The device you are looking for does not exist.': 'El dispositivo que busca no existe.',
  'The': 'El/La/Los/Las',
  'Toolbox': 'Toolbox',
  'Choose a toolbox item': 'Elegir un elemento de la caja de herramientas',
  'Add New Widget': 'Añadir nuevo widget',
  'Select a device': 'Seleccionar un dispositivo',
  'Now': 'Ahora',
  'Live data': '',
  'No data available': 'No hay datos disponibles',
  'Weather forecast': '',
  'This week': 'Esta semana',
  'Select a location': 'Seleccionar una ubicación',
  '24 hours': '24 horas',
  '7 days': '7 días',
  '30 days': '30 días',
  'Fetching graph data': 'Obtención de datos gráficos',
  'No data for:': 'Sin datos para:',
  'Current': 'Actual',
  'Sensors': 'Sensores',
  'Select': 'Seleccionar',
  'sensor': 'sensor',
  'Simple': '',
  'Custom': '',
  'Data API keys': 'Claves API de datos',
  'API key': 'Clave API',
  'Organization name': 'Nombre de la organización',
  'Organization ID': 'ID de la organización',
  'Please contact support': 'Póngase en contacto con el servicio de asistencia',
  'Loading...': 'Cargando...',
  'Data API Reference': 'Referencia API datos',
  'delete': 'eliminar',
  'Regenerate': 'Regenerar',
  'Regenerate API key': 'Regenerar clave API',
  'To proceed, enter': 'Para continuar, introduzca',
  'below, then click': 'a continuación y haga clic en',
  'Generate new API key': 'Generar nueva clave API',
  'Create a new API key for': 'Crear nueva clave API para',
  'Type the word': 'Escriba la palabra',
  'Warning': 'Advertencia',
  'Regenerating the API key will invalidate the old key. Any applications currently using the old key will break. This action cannot be undone.': 'La regeneración de la clave API invalidará la clave anterior. Cualquier aplicación que esté usando la clave anterior fallará. Esta acción no se puede deshacer.',
  'Check the API Reference for more information on how to use the Data API': 'Consulte la referencia de la API para obtener más información sobre cómo usar la API de datos',
  'Something went wrong. Please try again.': 'Se ha producido un error. Inténtelo de nuevo.',
  'Export Chart': 'Exportar gráfico',
  'Customize your graph export dimensions': 'Personalice las dimensiones de exportación de su gráfico',
  'Exporting chart... Do not close or refresh the page.': 'Exportando gráfico No cierre ni actualice la página.',
  'Width': 'Anchura',
  'Height': 'Altura',
  'Export': 'Exportar',
  'Width must be at least 512px': 'La anchura debe ser de al menos 512 px',
  'Width must be less than 1920px': 'La anchura debe ser inferior a 1920 px',
  'Height must be at least 288px': 'La altura debe ser de al menos 288 px',
  'Height must be less than 1080px': 'La altura debe ser inferior a 1080 px',
  'Date Range': 'Rango de fechas',
  'Add Date Filter': 'Añadir filtro de fecha',
  'Add Location Filter': 'Añadir filtro de ubicación',
  'Duplicate Last Location Filter': '',
  'Preset Time Range': 'Preestablecer rango de tiempo',
  'Start Date': 'Fecha de inicio',
  'End Date': 'Fecha de fin',
  'Graph Interval': 'Intervalo gráfico',
  'Select Organization': 'Seleccionar organización',
  'Time Range & Interval': 'Intervalo y rango de tiempo',
  'Location of Interest': 'Ubicación de interés',
  'Configure Databoard': 'Configurar tablero de datos',
  'Select Measurements': 'Seleccionar mediciones',
  'The date fields time values are set in the time zone specified above.': 'Los valores de hora de los campos de fecha se establecen en la zona horaria especificada anteriormente.',
  'Databoard': 'Tablero de datos',
  'Please select a time range, measurements and locations to view the associated data.': 'Seleccione un rango de tiempo, mediciones y ubicaciones para ver los datos asociados.',
  'Please wait while we fetch the requested data': 'Por favor, espere mientras obtenemos los datos solicitados',
  'Select Measurement(s': 'Seleccione Medida(s)',
  '1-4': '1-4',
  'Select measurements': 'Seleccionar mediciones',
  'Select a zone': 'Seleccionar una zona',
  'Download Data': 'Descargar datos',
  'Download graph': 'Descargar gráfico',
  'Fetch Data': 'Obtener datos',
  'Date range': 'Rango de fechas',
  'Historical Data': 'Datos históricos',
  'Custom Range': 'Rango personalizado',
  'Yesterday': 'Ayer',
  'Last month': 'Mes anterior',
  'This year': 'Este año',
  'Last 12 months': 'Últimos 12 meses',
  'Too much data to display': 'Demasiados datos para mostrar',
  'Compare data by:': 'Compare datos por:',
  'Date Ranges': 'Rangos de fecha',
  'Locations': 'Ubicaciones',
  'Add new date range': 'Añadir nuevo rango de fecha',
  'You cannot compare a location to itself, please remove the duplicate locations.': 'No puede comparar una ubicación consigo misma; elimine las ubicaciones duplicadas.',
  'You cannot compare a date range to itself, please remove the duplicate date ranges.': 'No puede comparar un rango de datos consigo mismo; elimine los rangos de datos duplicados.',
  'The selected locations do not have any common measurements.': 'Las ubicaciones seleccionadas no tienen mediciones comunes.',
  'Minute': '',
  'Hour': '',
  'Day': '',
  'Week': '',
  'Month': '',
  'Device with the provided ID does not exist or you do not have permission to view it. Please contact support.': 'El dispositivo con la identificación proporcionada no existe o no tiene permiso para verlo. Póngase en contacto con el servicio de asistencia.',
  'is enrolled': 'está inscrito',
  'The device must be connected and powered on for you to see data.': 'El dispositivo debe estar conectado y alimentado para que pueda ver los datos.',
  'Go to device dashboard': 'Ir al panel del dispositivo',
  'Update coordinates': 'Actualizar coordenadas',
  'Made a mistake when enrolling the device? Unenroll it and start the process again.': '¿Ha cometido un error al inscribir el dispositivo? Desinscríbalo y vuelva a iniciar el proceso.',
  'Unenroll device': 'Desinscribir dispositivo',
  'Create a new': 'Crear un nuevo',
  'Choose a': 'Elegir un',
  'This is the physical location of your operation, pinpointed by a unique geographic coordinate. This coordinate may represent an expansive area that includes a variety of areas and sub-areas.': 'Esta es la ubicación física de su operación, señalada por una coordenada geográfica única. Esta coordenada puede representar un área amplia que incluye una variedad de áreas y subáreas.',
  'Select an existing': 'Seleccione una existente',
  'You do not have permission to create': 'No tiene permiso para crear',
  'Please talk to your administrator.': 'Póngase en contacto con su administrador.',
  'Device ID': 'ID de dispositivo',
  'Display name': 'Mostrar nombre',
  'Back to room': 'Volver a habitación',
  'Enroll your device': 'Inscribir su dispositivo',
  'Confirm the details for your device below. Note that the device ID can not be edited. You can go back to the previous steps to edit the location details.': 'Confirme los detalles de su dispositivo a continuación. Tenga en cuenta que el ID del dispositivo no se puede editar. Puede volver a los pasos anteriores para editar los detalles de la ubicación.',
  'Your current coordinates': 'Sus coordenadas actuales',
  'Use current coordinates': 'Usar coordenadas actuales',
  'Saved coordinates': 'Coordenadas guardadas',
  'Update device coordinates': 'Actualizar coordenadas del dispositivo',
  'Use your current location to set updated coordinates for ': 'Use su ubicación actual para establecer las coordinadas actualizadas para  ',
  'Stand as close to the device as you can for the most accurate results.': 'Colóquese lo más cerca posible del dispositivo para obtener los resultados más precisos.',
  'This is a designated area inside of a': 'Esta es un área designada dentro de un/a',
  'You do not have permission to create zones. Please talk to your administrator.': 'No tiene permiso para crear zonas. Póngase en contacto con su administrador.',
  'Back to': 'Volver a',
  'Device ID is not valid. Try scanning the device again or contact your supplier.': 'El ID del dispositivo no es válido. Intente escanear el dispositivo de nuevo o póngase en contacto con su proveedor.',
  'Device not found': '',
  'Location not found': '',
  'Please enter a valid ID': '',
  'Find Dashboard': '',
  'Select either Device or Location and enter its ID': '',
  'Search Type': '',
  'Missing data': '',
  'Not in use': '',
  'Sorry, we couldnt find what you were looking for!': 'Lo sentimos, ¡no hemos encontrado lo que buscaba!',
  'A name is required.': '',
  'All measurements are required.': '',
  'All bounds are required.': '',
  'Manage Produce Threshold': '',
  'Save Produce Threshold': '',
  'Add Measurement': '',
  'No measurements': '',
  'Lower Bounds': '',
  'Upper Bounds': '',
  'Stress': '',
  'Bad': '',
  'Good': '',
  'Produce threshold created successfully.': '',
  'Produce threshold updated successfully.': '',
  'Please note that temperatures are stored in Celsius and may change when converted.': '',
  'Add Produce Threshold': '',
  'No entries': 'No hay entradas',
  'Priority is invalid.': '',
  'At least one condition is required.': '',
  'All condition fields are required.': '',
  'A message is required.': '',
  'Manage Produce Threshold Message': '',
  'Save Produce Threshold Message': '',
  'Priority': '',
  'Conditions': 'Condiciones',
  'Add Condition': '',
  'No conditions have been set': '',
  'Condition': '',
  'Expression': '',
  'Bound': '',
  'Please select': 'Seleccione',
  'Produce threshold message created successfully.': '',
  'Produce threshold message updated successfully.': '',
  'Produce threshold message deleted successfully.': '',
  'Add Produce Threshold Message': '',
  'No messages': '',
  'More than or equal to': '',
  'Less than or equal to': '',
  'Stress Lower': '',
  'Bad Lower': '',
  'Good Lower': '',
  'Good Upper': '',
  'Bad Upper': '',
  'Stress Upper': '',
  'High': '',
  'Medium': '',
  'Low': '',
  'Produce Thresholds': '',
  'Produce Threshold Messages': '',
  'An organization is required.': 'Es necesario indicar una organización',
  'Name is required.': 'Es necesario indicar el nombre.',
  'A country must be selected.': 'Se debe seleccionar un país.',
  'A region must be selected.': 'Se debe seleccionar una región.',
  'A city must be selected.': 'Se debe seleccionar una ciudad.',
  'Manage Area': 'Administrar área',
  'Save Area': 'Guardar área',
  'Country': 'País',
  'Region': 'Región',
  'City': 'Ciudad',
  'Manage Areas': 'Administrar áreas',
  'Add Area': 'Agregar área',
  'No regions.': 'No hay regiones.',
  'Area': 'Área',
  'Manage City': 'Administrar ciudad',
  'Save City': 'Guardar ciudad',
  'Manage Cities': 'Administrar ciudades',
  'Add City': 'Agregar ciudad',
  'No cities.': 'No hay ciudades.',
  'Areas': 'Áreas',
  'ISO Code a required field.': 'El código ISO es un campo obligatorio.',
  'Dialing Code a required field.': 'El código de marcación es un campo obligatorio.',
  'A Currency must be selected.': 'Se debe seleccionar una moneda.',
  'Manage Country': 'Administrar país',
  'Save Country': 'Guardar país',
  'Currency': 'Moneda',
  'Dialing Code': 'Código de marcación',
  'ISO Code': 'Código ISO',
  'Manage Countries': 'Administrar países',
  'Add Country': 'Agregar país',
  'Regions': 'Regiones',
  'Code is required.': 'Es necesario indicar el código.',
  'USD Rate is required.': 'Es necesario indicar la tasa de cambio respecto al dólar (USD).',
  'Manage Currency': 'Administrar moneda',
  'Save Currency': 'Guardar moneda',
  'USD Rate': 'Tasa de cambio respecto al dólar (USD)',
  'USD': 'USD',
  'Manage Currencies': 'Administrar monedas',
  'Add Currency': 'Agregar moneda',
  'Manage Grades': 'Administrar grados',
  'Save Grade': 'Guardar grado',
  'Add Grade': 'Agregar grado',
  'Grade': 'Grado',
  'A category is required.': 'Es necesario indicar una categoría.',
  'A status is required.': 'Es necesario indicar un estado.',
  'Manage Package Unit': 'Administrar unidad de empaquetado',
  'Save Package Unit': 'Guardar unidad de empaquetado',
  'Manage Package Units': 'Administrar unidades de empaquetado',
  'Add Package Unit': 'Agregar unidad de empaquetado',
  'No package units': 'No hay unidades de empaquetado',
  'Category': 'Categoría',
  'Please Select': 'Seleccione',
  'g': 'g',
  'Available': 'Disponible',
  'Box': 'Caja',
  'Bag': 'Bolsa',
  'Felin': 'Felin',
  'Clamshell': 'Caja de plástico',
  'Cup': 'Copa',
  'Tray': 'Bandeja',
  'Lid': 'Tapa',
  'Pack': 'Envase',
  'Carton': 'Cartón',
  'Sleeve': 'Sleeve',
  'Sticker': 'Adhesivo',
  'All Categories': 'Todas las categorías',
  'Any Status': 'Cualquier estado',
  'Manage Payment Method': 'Administrar método de pago',
  'Save Payment Method': 'Guardar método de pago',
  'Manage Payment Methods': 'Administrar métodos de pago',
  'Add Payment Method': 'Agregar método de pago',
  'Manage Payment Terms': 'Administrar condiciones de pago',
  'Save Payment Terms': 'Guardar condiciones de pago',
  'Termsheet': 'Hoja de condiciones',
  'Add Payment Terms': 'Agregar condiciones de pago',
  'Term Sheet': 'Hoja de condiciones',
  'No term sheet': 'No hay hoja de condiciones',
  'Please provide a code.': 'Proporcione un código.',
  'Manage Produce': 'Administrar producto agrícola',
  'Save Produce': 'Guardar producto agrícola',
  'Default Threshold Template': '',
  'Add Produce': 'Agregar producto agrícola',
  'Varieties': 'Variedades',
  'A Country must be selected.': 'Se debe seleccionar un país.',
  'Manage Region': 'Administrar región',
  'Save Region': 'Guardar región',
  'Manage Regions': 'Administrar regiones',
  'Add Region': 'Agregar región',
  'Cities': 'Ciudades',
  'An organization is required field.': 'El campo región es necesario',
  'A storage facility must be selected.': 'Se debe seleccionar una instalación de almacenamiento.',
  'Manage Shipping Method': 'Administrar método de envío',
  'Save Shipping Method': 'Guardar método de envío',
  'Storage Facility': 'Instalación de almacenamiento',
  'Manage Shipping Methods': 'Administrar métodos de envío',
  'Add Shipping Method': 'Agregar método de envío',
  'At least one store type is required.': 'Es necesario indicar al menos un tipo de tienda.',
  'Manage Storage Facility': 'Administrar instalación de almacenamiento',
  'Save Storage Facility': 'Guardar instalación de almacenamiento',
  'Store Types': 'Tipos de tiendas',
  'Cold Storage (Produce': 'Almacenamiento en frío (producto agrícola',
  'General Store (Packaging': 'Tienda general (empaquetado',
  'Chemical Store': 'Tienda de productos químicos',
  'Fertilizer Store': 'Tienda de fertilizantes',
  'Refrigerated Seed Store': 'Tienda de semillas refrigeradas',
  'Maintenance Supplies': 'Suministros de mantenimiento',
  'Flammable Caged Storage': 'Almacenamiento en jaula para materiales inflamables',
  'Manage Storage Facilities': 'Administrar instalaciones de almacenamiento',
  'Add Storage Facility': 'Agregar instalación de almacenamiento',
  'Shipping Methods': 'Métodos de envío',
  'A Produce must be selected.': 'Se debe seleccionar un producto agrícola.',
  'Manage Variety': 'Administrar variedad',
  'Save Variety': 'Guardar variedad',
  'Manage Varieties': 'Administrar variedades',
  'Add Variety': 'Agregar variedad',
  'Variety': 'Variedad',
  'Grades': 'Grados',
  'Countries': 'Países',
  'Currencies': 'Monedas',
  'The onboarding date cannot be set in the future.': 'La fecha de incorporación no puede corresponder a un momento futuro.',
  'An onboarding date is required.': 'Es necesario indicar una fecha de incorporación.',
  'Contact name is required.': 'Es necesario indicar el nombre de contacto.',
  'Contact number is required.': 'Es necesario indicar el número de contacto.',
  'Contact email is required.': 'Es necesario indicar el correo electrónico de contacto.',
  'A customer must be selected.': 'Se debe seleccionar un cliente.',
  'An area must be selected.': 'Se debe seleccionar un área.',
  'A currency must be selected.': 'Se debe seleccionar una moneda.',
  'A payment method must be selected.': 'Se debe seleccionar un método de pago.',
  'A payment term must be selected.': 'Se debe seleccionar una condición de pago.',
  'Manage Branches': 'Administrar sucursales',
  'Create Branch': 'Crear sucursal',
  'Create New Branch': 'Crear nueva sucursal',
  'Update Existing Branch': 'Actualizar sucursal existente',
  'Save Branch': 'Guardar sucursal',
  'Company': 'Compañía',
  'Contact': 'Contacto',
  'No branches': 'Sin sucursales',
  'Closed': 'Cerrada/o',
  'Banned': 'Prohibida/o',
  'Branch': 'Sucursal',
  'Customer': 'Cliente',
  'Onboarding Date': 'Fecha de incorporación',
  'Contact Name': 'Nombre de contacto',
  'Contact Number': 'Número de contacto',
  'Contact Email': 'Correo electrónico de contacto',
  'Payment': 'Pago',
  'Payment Method': 'Método de pago',
  'Payment Terms': 'Condiciones de pago',
  'Repeated': 'Repetido',
  'All Statuses': 'Todos los estados',
  'Fetch': 'Obtener',
  'GMT': 'GMT',
  'Produce is required.': 'Es necesario indicar el producto agrícola.',
  'A variety is required.': 'Es necesario indicar una variedad.',
  'Planting area must be at least 1m2.': 'El área de plantación debe ser de al menos 1 m².',
  'Seed quantity cannot be less than 1.': 'La cantidad de semillas no puede ser inferior a 1.',
  'Manage crops': 'Administrar cultivos',
  'Create crop': 'Crear cultivo',
  'Create New Crop': 'Crear nuevo cultivo',
  'Update Existing Crop': 'Actualizar cultivo existente',
  'Save crop': 'Guardar cultivo',
  'Crop': 'Cultivo',
  'Date Created': 'Fecha de creación',
  'Date Updated': 'Fecha de actualización',
  'Recommendations Template': '',
  'Volume': 'Volumen',
  'Planting Area': 'Área de plantación',
  'Planting Area (m2': 'Área de plantación (m²',
  'Growth Medium': 'Medio de crecimiento',
  'Hydration Method': 'Método de hidratación',
  'Quantity of Seeds': 'Cantidad de semillas',
  'seeds': 'semillas',
  'Estimated Sow Date': 'Fecha estimada de siembra',
  'Date Sowed': 'Fecha de siembra',
  'Estimated Transplant Date': 'Fecha estimada de trasplante',
  'Date Transplanted': 'Fecha de trasplante',
  'Estimated Harvest Start Date': 'Fecha estimada de inicio de la cosecha',
  'Harvest Start Date': 'Fecha de inicio de la cosecha',
  'Estimated Harvest End Date': 'Fecha estimada de fin de la cosecha',
  'Harvest End Date': 'Fecha de fin de la cosecha',
  'Schedule': 'Calendario',
  'Planned': 'Planificado',
  'Sowed': 'Sembrado',
  'Transplanted': 'Trasplantado',
  'Harvesting': 'Cosechando',
  'Harvested': 'Cosechado',
  'Failed': 'Fallido',
  'Canceled': 'Cancelado',
  'Perlite': 'Perlita',
  'Vermiculite': 'Vermiculita',
  'Volcanic Rock': 'Roca volcánica',
  'Rockwool/Stonewool': 'Lana de roca',
  'Soil': '',
  'Coconut Coir': 'Fibra de coco',
  'Clay Pellets': 'Pellets de arcilla',
  'Sand/Gravel/Sawdust': 'Arena/grava/serrín',
  'Peat Moss': 'Musgo de turba',
  'Deep Water Culture': 'Cultivo de aguas profundas',
  'Dutch Bucket': 'Cubo holandés',
  'Gutter System': 'Sistema de canalones',
  'Nutrient Film Technique': 'Técnica de película de nutrientes',
  'Aeroponics': 'Aeroponía',
  'Drip System': 'Sistema de goteo',
  'Wicking': 'Sistema mecha (Wick)',
  'Irrigation': 'Riego',
  'm2': 'm²',
  'Query data': 'Solicitar datos',
  'Roof Cover': '',
  'SecondSky': '',
  'Standard Plastic': '',
  'Open Field': '',
  'SecondSky Variant': '',
  'SecondSky PolyFilm': '',
  'SecondSky PolyCarbonate': '',
  'SecondSky Net': '',
  'Blocking Ratio': '',
  'Net Type': '',
  '10/20': '',
  '10/16': '',
  'Tricot': '',
  'Rashel': '',
  'SecondSky Additional Information': '',
  'Not Applicable': '',
  'An organization must be selected.': 'Se debe seleccionar una organización.',
  'Contact Name is required.': 'Es necesario indicar el nombre de contacto.',
  'Contact Number is required.': 'Es necesario indicar el número de contacto.',
  'Contact Email is required.': 'Es necesario indicar el correo electrónico de contacto.',
  'Manage Customers': 'Administrar clientes',
  'Create Customer': 'Crear cliente',
  'Create New Customer': 'Crear nuevo cliente',
  'Update Existing Customer': 'Actualizar cliente existente',
  'Save Customer': 'Guardar cliente',
  'No customers': 'No hay clientes',
  'Contract': 'Contrato',
  'Contract Document': 'Documento del contrato',
  'Registration': 'Registro',
  'Registration Number': 'Número de registro',
  'Registration Document': 'Documento de registro',
  'Add Registration': 'Agregar registro',
  'Delete Registration': 'Eliminar registro',
  'Tax Information': 'Información fiscal',
  'Tax Number': 'Número fiscal',
  'Tax Document': 'Documento fiscal',
  'Add Tax Information': 'Agregar información fiscal',
  'Delete Tax Information': 'Eliminar información fiscal',
  'National Address': 'Dirección nacional',
  'National Address Document': 'Documento de dirección nacional',
  'Street Address': 'Dirección',
  'Street Address 2': 'Dirección 2',
  'Zip Code': 'Código postal',
  'Add National Address': 'Agregar dirección nacional',
  'Delete National Address': 'Eliminar dirección nacional',
  'Bank Details': 'Datos bancarios',
  'Bank Details Document': 'Documento de datos bancarios',
  'Bank Name': 'Nombre del banco',
  'Branch Name': 'Nombre de la sucursal',
  'Branch Code': 'Código de la sucursal',
  'Branch Address': 'Dirección de la sucursal',
  'Branch Address 2': 'Dirección de la sucursal 2',
  'Swift/Bic': 'Swift/Bic',
  'IBAN': 'IBAN',
  'Add Bank Details': 'Agregar datos bancarios',
  'Delete Bank Details': 'Eliminar datos bancarios',
  'Retail': 'Venta al por menor',
  'Wholesale': 'Venta al por mayor',
  'Charity': 'Caridad',
  'Hospitality': 'Hospitalidad',
  'Open Market': 'Mercado abierto',
  'Manage Fertilizer Inventory': 'Gestionar el inventario de fertilizantes',
  'No fertilizer inventory': 'Sin inventario de fertilizantes',
  'Transfers': 'Transferencias',
  'Purchases': 'Compras',
  'Fertilizer': 'Fertilizante',
  'Total Weight': 'Peso total',
  'kg': 'kg',
  'Fertilizer inventory is required.': 'Es necesario indicar el inventario de fertilizantes.',
  'New total weight is required.': 'Es necesario indicar el nuevo peso total.',
  'New total weight cannot be less than zero.': 'El nuevo peso total no puede ser menor de cero.',
  'A reason is required.': 'Es necesario indicar una razón.',
  'Manage Fertilizer Inventory Calibrations': 'Administrar calibraciones de inventario de fertilizantes',
  'Create New Calibration': 'Crear nueva calibración',
  'No fertilizer inventory calibrations': 'No hay calibraciones de inventario de fertilizantes',
  'Save Calibration': 'Guardar calibración',
  'Inventory Calibration': 'Calibración de inventario',
  'Inventory': 'Inventario',
  '': '',
  'New Total Weight': 'Nuevo peso total',
  'Old Total Weight': 'Peso total anterior',
  'Current Total Weight': 'Peso total actual',
  'Change': 'Cambio',
  'Please select the origin inventory.': 'Seleccione el inventario de origen.',
  'Please select a fertilizer.': 'Seleccione un fertilizante.',
  'Please select an origin shipping method.': 'Seleccione un método de envío de origen.',
  'Please select a destination storage facility.': 'Seleccione una instalación de almacenamiento de destino.',
  'Please select a status.': 'Seleccione un estado.',
  'Total Weight is required.': 'Es necesario indicar el peso total.',
  'Total Weight cannot be less than one.': 'El peso total no puede ser inferior a uno.',
  'At least one weight is required.': 'Es necesario indicar al menos un peso.',
  'Shipped date is invalid.': 'La fecha de envío no es válida.',
  'The shipped date cannot be set in the future.': 'La fecha de envío no puede corresponder a un momento futuro.',
  'Delivered date is invalid.': 'La fecha de entrega no es válida.',
  'The delivered date cannot be set in the future.': 'La fecha de entrega no puede corresponder a un momento futuro.',
  'Manage Fertilizer Inventory Transfers': 'Administrar transferencias de inventario de fertilizantes',
  'Transfer': 'Transferencia',
  'Create New Transfer': 'Crear nueva transferencia',
  'Update Existing Transfer': 'Actualizar transferencia existente',
  'No fertilizer inventory transfers': 'Sin transferencias de inventario de fertilizantes',
  'Save Transfer': 'Guardar transferencia',
  'Inventory Transfer': 'Transferencia de inventario',
  'Origin': 'Origen',
  'Shipping Method': 'Método de envío',
  'Date Shipped': 'Fecha de envío',
  'Date Delivered': 'Fecha de entrega',
  'Shipped': 'Enviado',
  'Delivered': 'Entregado',
  'Add Weight': 'Agregar peso',
  'Units': 'Unidades',
  'A storage facility is required.': 'Es necesario indicar una instalación de almacenamiento.',
  'A fertilizer unit is required.': 'Es necesario indicar una unidad de fertilizante.',
  'A supplier is required.': 'Es necesario indicar un proveedor.',
  'A currency is required.': 'Es necesario indicar una moneda.',
  'A unit price is required.': 'Es necesario indicar el precio unitario.',
  'The unit price cannot be less than one.': 'El precio unitario no puede ser inferior a uno.',
  'A unit weight is required.': 'Es necesario indicar el peso unitario.',
  'The unit weight cannot be less than 0.1.': 'El peso unitario no puede ser inferior a 0,1.',
  'The tax cannot be less than zero.': 'El impuesto no puede ser inferior a cero.',
  'A partial quantity is not allowed.': 'No se permite una cantidad parcial.',
  'A purchase quantity is required.': 'Es necesario indicar una cantidad de compra.',
  'The quantity purchased cannot be less than one.': 'La cantidad comprada no puede ser inferior a uno.',
  'An purchase quantity is required.': 'Es necesario indicar una cantidad de compra.',
  'Order date is required.': 'Es necesario indicar la fecha del pedido.',
  'Order date cannot be set in the future.': 'La fecha del pedido no puede corresponder a un momento futuro.',
  'Date received is invalid.': 'La fecha de recepción no es válida.',
  'Date received cannot be set in the future.': 'La fecha de recepción no puede corresponder a un momento futuro.',
  'Status is required': 'Es necesario indicar el estado.',
  'Manage Fertilizer Purchases': 'Administrar compras de fertilizantes',
  'Add Purchase': 'Agregar compra',
  'Create Purchase': 'Crear compra',
  'Update Existing Purchase': 'Actualizar compra existente',
  'Save Purchase': 'Guardar compra',
  'No purchases': 'No hay compras',
  'Purchase': 'Compra',
  'Supplier': 'Proveedor',
  'Quantity': 'Cantidad',
  'Unit Price': 'Precio unitario',
  'Unit Weight': 'Peso unitario',
  'Price': 'Precio',
  'Order': 'Pedido',
  'Delivery': 'Entrega',
  'Tax': 'Impuesto',
  'tax': 'impuesto',
  'Date Ordered': 'Fecha de pedido',
  'Date Received': 'Fecha de recepción',
  'Ordered': 'Pedido',
  'Received': 'Recibido',
  'Results for date range': 'Resultados para rango de fechas',
  'A location must be selected.': 'Se debe seleccionar una ubicación.',
  'Variety is required.': 'Es necesario indicar la variedad.',
  'The harvest date cannot be set in the future.': 'La fecha de cosecha no puede corresponder a un momento futuro.',
  'A harvest date is required.': 'Es necesario indicar una fecha de cosecha.',
  'Manage harvests': 'Administrar cosechas',
  'Create harvest': 'Crear cosecha',
  'Plans': 'Planes',
  'Create New Harvest': 'Crear nueva cosecha',
  'Update Existing Harvest': 'Actualizar cosecha existente',
  'Save Harvest': 'Guardar cosecha',
  'Harvest Date': 'Fecha de cosecha',
  'Yield': 'Producción',
  'Weights': 'Pesos',
  'Weight': 'Peso',
  'Total Weight (kg': 'Peso total (kg',
  'Photos': 'Fotos',
  'No harvests': 'No hay cosechas',
  'Are you sure?': '¿Está seguro?',
  'Click continue to confirm.': 'Haga clic en continuar para confirmar.',
  'Deleting a harvest cannot be undone.': 'La eliminación de una cosecha no se puede deshacer.',
  'Manage Inventory': 'Administrar inventario',
  'No inventory': 'No hay inventario',
  'Please select the inventory.': 'Seleccione el inventario.',
  'New weight is required.': 'Es necesario indicar el peso nuevo.',
  'New weight cannot be less than zero.': 'El peso nuevo no puede ser menor de cero.',
  'Manage Inventory Calibrations': 'Administrar calibraciones de inventario',
  'No inventory calibrations': 'No hay calibraciones de inventario',
  'New Weight': 'Peso nuevo',
  'Old Weight': 'Peso anterior',
  'Current Weight': 'Peso actual',
  'Total weight cannot be less than 1kg.': 'El peso total no puede ser inferior a 1 kg.',
  'Total weight is required.': 'Es necesario indicar el peso total.',
  'Manage Inventory Transfers': 'Administrar transferencias de inventario',
  'No inventory transfers': 'No hay transferencias de inventario',
  'Manage Packaging Inventory': 'Administrar el inventario de empaquetado',
  'No packaging inventory': 'No hay inventario de empaquetado',
  'Package Unit': 'Unidad de empaquetado',
  'Package unit inventory is required.': 'Es necesario indicar un inventario de unidades de empaquetado.',
  'New quantity is required.': 'Es necesario indicar una nueva cantidad.',
  'New quantity cannot be less than zero.': 'La cantidad nueva no puede ser inferior a cero.',
  'Manage Packaging Inventory Calibrations': 'Administrar calibraciones de inventario de empaquetado',
  'No packaging inventory calibrations': 'No hay calibraciones de inventario de empaquetado',
  'New Quantity': 'Cantidad nueva',
  'Old Quantity': 'Cantidad anterior',
  'Current Quantity': 'Cantidad actual',
  'Quantity is required.': 'Es necesario indicar una cantidad.',
  'Quantity cannot be less than one.': 'La cantidad no puede ser inferior a uno.',
  'Manage Packaging Inventory Transfers': 'Administrar transferencias de inventario de empaquetado',
  'No packaging inventory transfers': 'No hay transferencias de inventario de empaquetado',
  'Packaging': 'Empaquetado',
  'A package unit is required.': 'Es necesario indicar una unidad de empaquetado.',
  'Manage Packaging Purchases': 'Administrar compras de empaquetado',
  'An estimated harvest start date is required.': 'Es necesario indicar una fecha estimada de inicio de la cosecha.',
  'An estimated harvest end date is required.': 'Es necesario indicar una fecha estimada de fin de la cosecha.',
  'At least one estimated weight is required.': 'Es necesario indicar al menos un peso estimado.',
  'Currency is required.': 'Es necesario indicar la moneda.',
  'The target price must be more than zero.': 'El precio de referencia debe ser superior a cero.',
  'A target price is required.': 'Es necesario indicar el precio de referencia.',
  'Manage Plans': 'Administrar planes',
  'Create plan': 'Crear plan',
  'No plans': 'No hay planes',
  'Create New Plan': 'Crear nuevo plan',
  'Update Existing Plan': 'Actualizar plan existente',
  'Save Plan': 'Guardar plan',
  'Target Price': 'Precio de referencia',
  'Estimated Weights': 'Pesos estimados',
  'A code is required.': 'Es necesario indicar un código.',
  'Weight must be at least 1g.': 'El peso debe ser de al menos 1 g.',
  'Weight is required.': 'Es necesario indicar el peso.',
  'A grade is required.': 'Es necesario indicar el grado.',
  'At least one package unit is required.': 'Es necesario indicar al menos una unidad de empaquetado.',
  'Manage Products': 'Administrar productos',
  'Create Product': 'Crear producto',
  'No products': 'No hay productos',
  'Create New Product': 'Crear nuevo producto',
  'Update Existing Product': 'Actualizar producto existente',
  'Save Product': 'Guardar producto',
  'Product': 'Producto',
  'Discontinued': 'Descatalogado',
  'A product must be selected.': 'Se debe seleccionar un producto.',
  'A returned quantity is required.': 'Es necesario indicar una cantidad devuelta.',
  'Returned quantity cannot be less than 1': 'La cantidad devuelta no puede ser inferior a uno.',
  'A quantity returned must be filled': 'Se debe rellenar una cantidad devuelta',
  'A return date must be selected.': 'Se debe seleccionar una fecha de devolución.',
  'A branch must be selected': 'Se debe seleccionar una sucursal',
  'A customer must be selected': 'Se debe seleccionar un cliente',
  'At least one return item is required': 'Es necesario indicar al menos un artículo de devolución',
  'Manage Returns': 'Administrar devoluciones',
  'Create Return': 'Crear devolución',
  'Create New Return': 'Crear nueva devolución',
  'Update Existing Return': 'Actualizar devolución existente',
  'Return': 'Devolución',
  'Return Date': 'Fecha de devolución',
  'Reference Number': 'Número de referencia',
  'No returns': 'No hay devoluciones',
  'No items': 'No hay artículos',
  'Save Return': 'Guardar devolución',
  'Add Return Item': 'Agregar artículo devuelto',
  'Items': 'Artículos',
  'Return Item': 'Artículo devuelto',
  'Amount': 'Cantidad',
  'Discard': 'Descartar',
  'Quantity Returned': 'Cantidad devuelta',
  'Unordered': 'No pedido',
  'Unsold': 'No vendido',
  'Damaged': 'Dañado',
  'Low Quality': 'Baja calidad',
  'No payments': 'No hay pagos',
  'The received date cannot be older than the order date.': 'La fecha de recepción no puede ser anterior a la fecha del pedido.',
  'The received date is required.': 'Es necesario indicar la fecha de recepción.',
  'The amount cannot be less than one.': 'La cantidad no puede ser inferior a uno.',
  'An amount is required.': 'Es necesario indicar la cantidad.',
  'Add Item': 'Agregar artículo',
  'Add New Item': 'Agregar nuevo artículo',
  'Update Item': 'Actualizar artículo',
  'Recommended': 'Recomendado',
  'Apply': 'Aplicar',
  'Discount': 'Descuento',
  'Quantity Ordered': 'Cantidad pedida',
  'Save Item': 'Guardar artículo',
  'Unable to remove item referenced by shipment.': 'No se puede eliminar el artículo referenciado por envío.',
  'Please select a product.': 'Seleccione un producto.',
  'An order quantity is required.': 'Es necesario indicar una cantidad para el pedido.',
  'The quantity ordered cannot be less than one.': 'La cantidad pedida no puede ser inferior a uno.',
  'The discount cannot be less than zero.': 'El descuento no puede ser inferior a cero.',
  'No Items': 'No hay artículos',
  'Sale Item': 'Artículo en oferta',
  'Please select a sale item.': 'Seleccione un artículo en oferta.',
  'The quantity cannot be less than one.': 'La cantidad no puede ser inferior a uno.',
  'An quantity is required.': 'Es necesario indicar una cantidad.',
  'Add Shipment': 'Agregar envío',
  'Add New Shipment': 'Agregar nuevo envío',
  'Update Shipment': 'Actualizar envío',
  'Due Date': 'Fecha de vencimiento',
  'No shipments': 'No hay envíos',
  'Due': 'Vencimiento',
  'Shipping From': 'Envío desde',
  'Save Shipment': 'Guardar envío',
  'Delayed': 'Retrasado',
  'Please select a storage facility.': 'Seleccione una instalación de almacenamiento.',
  'Please select a shipping method.': 'Seleccione un método de envío.',
  'The price cannot be less than zero.': 'El precio no puede ser inferior a cero.',
  'The due date cannot be older than the order date.': 'La fecha de vencimiento no puede ser anterior a la fecha del pedido.',
  'A due date must be selected.': 'Se debe seleccionar una fecha de vencimiento.',
  'The delivery date cannot be older than the order date.': 'La fecha de entrega no puede ser anterior a la fecha del pedido.',
  'You must include at least one item.': 'Debe incluir al menos un artículo.',
  'An order date must be selected.': 'Se debe seleccionar una fecha de pedido.',
  'A customer PO is required.': 'Se requiere una orden de compra del cliente.',
  'Please select a customer.': 'Seleccione un cliente',
  'Please select a branch.': 'Seleccione una sucursal.',
  'Please select a currency.': 'Seleccione una moneda',
  'Please select a payment method.': 'Seleccione un método de pago.',
  'Please select the payment terms.': 'Seleccione las condiciones de pago.',
  'At least one item is required.': 'Es necesario indicar al menos un artículo.',
  'Manage Sales': 'Administrar ventas',
  'Create Sale': 'Crear venta',
  'Create New Sale': 'Crear nueva venta',
  'Update Existing Sale': 'Actualizar venta existente',
  'Save Sale': 'Guardar venta',
  'Purchase Order': 'Orden de compra',
  'Invoice': 'Factura',
  'Sale': 'Venta',
  'Payments': 'Pagos',
  'Shipments': 'Envíos',
  'Order Date': 'Fecha del pedido',
  'Customer Purchase Order': 'Pedido del cliente',
  'Invoice Status': 'Estado de la factura',
  'Invoice Number': 'Número de factura',
  'Add Payment': 'Agregar pago',
  'No sales': 'No hay ventas',
  'In Processing': 'En procesamiento',
  'In Production': 'En producción',
  'In Transit': 'En tránsito',
  'Partially Delivered': 'Entregado parcialmente',
  'Issued': 'Emitido',
  'Submitted': 'Enviado',
  'Awaiting Approval': 'Esperando aprobación',
  'Awaiting Payment': 'Esperando pago',
  'Paid': 'Pagada/o',
  'inc.': 'inc.',
  'Traceability': 'Trazabilidad',
  'Changing the organization will delete all items and shipments. This cannot be undone.': 'Al cambiar la organización se eliminarán todos los artículos y envíos. Esta acción no se puede deshacer.',
  'At least one supply type is required.': 'Es necesario indicar al menos un tipo de suministro.',
  'Website is required.': 'Es necesario indicar un sitio web.',
  'Manage Suppliers': 'Administrar proveedores',
  'Create Supplier': 'Crear proveedor',
  'Create New Supplier': 'Crear nuevo proveedor',
  'Update Existing Supplier': 'Actualizar proveedor existente',
  'Save Supplier': 'Guardar proveedor',
  'No suppliers': 'No hay proveedores',
  'Supply Types': 'Tipos de suministros',
  'Contact Person': 'Persona de contacto',
  'Email Address': 'Dirección de correo electrónico',
  'Website': 'Sitio web',
  'All Regions': 'Todas las regiones',
  'Add Address': 'Agregar dirección',
  'Delete Address': 'Eliminar dirección',
  'Branding': 'Branding',
  'Seeds': 'Semillas',
  'Substrate': 'Sustrato',
  'Technology': 'Tecnología',
  'Construction': 'Construcción',
  'Telecommunications': 'Telecomunicaciones',
  'Package Date': 'Fecha empaquetado',
  'Generate Code': 'Generar código',
  'Batch#': 'N.º de lote',
  'Harvest is required.': 'Es necesario indicar una cosecha.',
  'The package date cannot be set in the future.': 'La fecha de empaquetado no puede corresponder a un momento futuro.',
  'A package date is required.': 'Es necesario indicar una fecha de empaquetado.',
  'Customer is required.': 'Es necesario indicar un cliente.',
  'Please select customer.': 'Seleccione cliente',
  'The selling price cannot be less than zero.': 'El precio de venta no puede ser inferior a cero.',
  'Manage Unit Prices': 'Administrar precios unitarios',
  'Create Unit Price': 'Crear precio unitario',
  'Create New Unit Price': 'Crear nuevo precio unitario',
  'Update Existing Unit Price': 'Actualizar precio unitario existente',
  'No Unit Prices': 'No hay precios unitarios',
  'Save Unit Price': 'Guardar precio unitario',
  'Article Code': 'Código del artículo',
  'Alternate Description': 'Descripción alternativa',
  '%': '%',
  'discount': 'descuento',
  'Selling Price': 'Precio de venta',
  'Article': 'Artículo',
  'A produce must be selected.': 'Se debe seleccionar un producto.',
  'A variety must be selected.': 'Se debe seleccionar una variedad.',
  'The waste date cannot be set in the future.': 'La fecha del residuo no puede corresponder a un momento futuro.',
  'A waste date is required.': 'Es necesario indicar una fecha para el residuo.',
  'Weights field must have at least 1 item.': 'El campo pesos debe tener al menos un artículo.',
  'Manage Waste': 'Administrar residuos',
  'Create Waste': 'Crear residuo',
  'Waste': 'Residuo',
  'Create New Waste': 'Crear nuevo residuo',
  'Update Existing Waste': 'Actualizar residuo existente',
  'Save Waste': 'Guardar residuo',
  'No waste': 'No hay residuos',
  'Expired': 'Caducado',
  'Customize your chart export dimensions': 'Personalice las dimensiones de exportación de su gráfico',
  'Width (px': 'Anchura (px)',
  'Enter a width': 'Introducir una anchura',
  'Crop status changes over time by location': 'Cambios en estado del cultivo a lo largo del tiempo por ubicación',
  'Width must be no more than 1920px': 'La anchura debe ser inferior a 1920 px',
  'Not yet planned': 'No se ha planificado todavía',
  'Harvest started': 'Cosecha iniciada',
  'Harvest ended': 'Cosecha finalizada',
  'Not planned': 'No se ha planificado',
  'Total weight': 'Peso total',
  'Harvest date': 'Fecha de cosecha',
  'Harvest data': 'Fecha de cosecha',
  'No data to display': 'No hay datos que mostrar',
  'Search for harvests': 'Buscar cosechas',
  'View weight by grade': 'Ver peso por grado',
  'No data to show for': 'No hay datos que mostrar para',
  'Crops data': 'Datos de cultivo',
  'Search for crops': 'Buscar cultivos',
  'Planting area m': 'Área de plantación en m',
  'Seed quantity': 'Cantidad de semillas',
  'Produce weight by grade and location': 'Peso del producto por grado y ubicación',
  'Produce weight by location': 'Peso del producto por ubicación',
  'Bar chart of produce by location': 'Gráfico de barras del producto por ubicación',
  'Table of harvest entries': 'Tabla de entradas de cosecha',
  'Crops status changes over time by location': 'Cambios en estado de cultivos a lo largo del tiempo por ubicación',
  'Crops timelines': 'Cronogramas de cultivos',
  'Table of crops entries': 'Tabla de entradas de cultivos',
  'Unplanned': 'Sin planificar',
  'All Cities': 'Todas las ciudades',
  'All Countries': 'Todos los países',
  'All Customers': 'Todos los clientes',
  'site': 'sitio',
  'defaultLabel': 'defaultLabel',
  'All Organizations': 'Todas las organizaciones',
  'All Package Units': 'Todas las unidades de empaquetado',
  'All Produce': 'Todos los productos agrícolas',
  'All Products': 'Todos los productos',
  'All Sites': 'Todos los sitios',
  'All Varieties': 'Todas las variedades',
  'No weights': 'No hay pesos',
  'Weight must be at least 0.1kg.': 'El peso debe ser de al menos 0,1 kg.',
  'Quantity must be at least 1.': 'La cantidad debe ser de al menos 1.',
  'Add Photo': 'Agregar foto',
  'New Photo': 'Nueva foto',
  'Subject': 'Asunto',
  'Retake': 'Volver a tomar',
  'Photo': 'Foto',
  'Take Photo': 'Tomar foto',
  'Please allow use of camera': 'Permita el uso de la cámara',
  'Select Subject': 'Seleccionar asunto',
  'None': 'Ninguna/o',
  'Plant': 'Planta',
  'Leaf': 'Hoja',
  'Fruit': 'Fruta',
  'Healthy': 'Sana',
  'Diseased': 'Enferma',
  'Deformed': 'Deformada',
  'Weight (kg': 'Peso (kg',
  'Select Grade': 'Seleccionar grado',
  'Weight must be at least 1kg.': 'El peso debe ser de al menos 1 kg.',
  'Package Units': 'Unidades de empaquetado',
  'Storage Facilities': 'Instalaciones de almacenamiento',
  'Returns': 'Devoluciones',
  'Payment Methods': 'Métodos de pago',
  'Sales': 'Ventas',
  'Products': 'Productos',
  'Customers': 'Clientes',
  'Admin': 'Admón.',
  'Last Updated:': 'Última actualización:',
  'Welcome to SecondSky Data, the agricultural data visualization platform. Your privacy is important to us. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information. This policy is designed to be compliant with the General Data Protection Regulation (GDPR) of the EU, relevant US federal and state privacy laws, and the Personal Data Protection Law of Saudi Arabia.': '',
  '1. Information Collection and Use': '1. Recopilación y uso de la información',
  'Personal Data: We collect personal data such as name, email address, and location when you register on SecondSky Data. This information is used to provide and improve our services.': '',
  'Agricultural Data: We collect agricultural data for visualization and analysis. This data may include geographic, environmental, crop, yield, and other operational information.': 'Datos agrícolas: recopilamos datos agrícolas para su visualización y análisis. Estos datos pueden incluir información geográfica, ambiental, de cultivos, de rendimiento y otra información operativa.',
  '2. Consent': '2. Consentimiento',
  'By using SecondSky Data, you consent to the collection and use of your information as outlined in this policy. For users in the EU, we comply with GDPR requirements for explicit consent.': '',
  '3. Data Sharing and Disclosure': '3. Intercambio y divulgación de datos',
  'We do not sell your personal data. We may share your information with third parties for the purposes of improving our services, complying with the law, or in the event of a business transfer.': 'No vendemos sus datos personales. Es posible que compartamos su información con terceros con el fin de mejorar nuestros servicios, cumplir con la ley, o en caso de transmisión de negocio.',
  'Cross-Border Data Transfers: Your data may be stored and processed in any country where we have operations or where we engage service providers.': 'Transferencias transfronterizas de datos: sus datos pueden almacenarse y procesarse en cualquier país en el que operemos o en el que contratemos proveedores de servicios.',
  '4. Data Security': '4. Seguridad de los datos',
  'We implement appropriate technical and organizational measures to protect your data from unauthorized access, disclosure, alteration, and destruction.': 'Implementamos las medidas técnicas y organizativas adecuadas para proteger sus datos contra accesos no autorizados, divulgación, alteración y destrucción.',
  '5. Your Rights': '5. Sus derechos',
  'EU Users: Under the GDPR, you have the right to access, correct, delete, or restrict the processing of your personal data.': 'Usuarios de la UE: según el RGPD, tiene derecho a acceder, rectificar, eliminar o restringir el procesamiento de sus datos personales.',
  'US Users: Depending on your state, you may have specific rights regarding access to and control of your personal data.': 'Usuarios de EE. UU.: dependiendo de su estado, es posible que tenga derechos específicos con respecto al acceso y control de sus datos personales.',
  'Saudi Arabian Users: In accordance with the Personal Data Protection Law, you have rights regarding the processing of your personal data.': 'Usuarios de Arabia Saudita: de acuerdo con la Ley de Protección de Datos Personales, tiene derechos con respecto al procesamiento de sus datos personales.',
  '6. Data Retention': '6. Conservación de datos',
  'We retain your personal data only as long as necessary to provide you with our services and for legitimate and essential business purposes.': 'Conservamos sus datos personales solo durante el tiempo que sea necesario para prestarle nuestros servicios y para fines comerciales legítimos y esenciales.',
  '7. Changes to This Policy': '7. Cambios a esta política',
  'We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new policy on this page.': 'Es posible que actualicemos nuestra Política de privacidad de vez en cuando. Le notificaremos cualquier cambio mediante la publicación de la nueva política en esta página.',
  '8. Contact Us': '8. Póngase en contacto con nosotros',
  'If you have any questions about this Privacy Policy, please contact us at support@secondsky.com': '',
  'WhatsApp number': '',
  'verified': '',
  'Message our chatbot at this number': '',
  'WhatsApp number not': '',
  'yet.': '',
  '\'Verify me\'': '',
  'to our number': '',
  'Profile': 'Perfil',
  'Enter phone number': 'Introducir número de teléfono',
  'Include country code': 'Incluir código de país',
  'WhatsApp Number': 'Número de WhatsApp',
  'Phone number updated successfully.': 'Número de teléfono actualizado correctamente.',
  'An error occurred while updating your phone number. Please try again.': 'Se ha producido un error al actualizar su número de teléfono. Inténtelo de nuevo.',
  'Update phone number': 'Actualizar número de teléfono',
  'Update WhatsApp number': 'Actualizar número de WhatsApp',
  'WhatsApp number updated successfully.': 'Número de WhatsApp actualizado correctamente.',
  'An error occurred while updating your WhatsApp number. Please try again.': 'Se ha producido un error al actualizar su número de WhatsApp. Inténtelo de nuevo.',
  'WhatsApp number deleted successfully.': '',
  'An error occurred while deleting your WhatsApp number. Please try again.': '',
  'Preferred Language': 'Idioma preferido',
  'Update language': 'Actualizar idioma',
  'Preferred language updated successfully.': 'Idioma preferido actualizado correctamente.',
  'An error occurred while updating your preferred language. Please try again.': 'Se ha producido un error al actualizar su idioma preferido. Inténtelo de nuevo.',
  'Multi-factor Authentication': 'Autenticación multifactor',
  'Setup': 'Configuración',
  'Disable': 'Desactivar',
  'Submit code': 'Enviar código',
  'Open your Authenticator app and scan the QR code above. Enter the code from the app to complete the setup.': 'Abra su aplicación Authenticator y escanee el código QR de arriba. Introduzca el código de la aplicación para completar la configuración.',
  'Enter code': 'Introducir código',
  'TOTP': 'TOTP',
  'Multi-factor authentication enabled successfully.': 'Autenticación multifactor activada correctamente.',
  'Multi-factor authentication disabled successfully.': 'Autenticación multifactor desactivada correctamente.',
  'Code mismatch.': 'Código no coincidente.',
  'An error occurred while enabling multi-factor authentication. Please try again.': 'Se ha producido un error al activar la autenticación multifactor. Inténtelo de nuevo.',
  'Reset dashboards': 'Restablecer paneles',
  'Reset your dashboards to defaults': 'Restablecer los paneles a los valores predeterminados',
  'Set up MFA': 'Configurar MFA',
  'Update your profile settings': 'Actualiza la configuración de su perfil',
  'By verifying your WhatsApp number you agree to receive automated notifications.': '',
  'You have agreed to receive automated notifications on this number.': '',
  'Light': 'Luz',
  'Dark': '',
  'Preferred Color Scheme': '',
  'Preferred color scheme updated successfully': '',
  'An error occured while trying to set preferred color scheme. Please try again.': '',
  'Reports': 'Informes',
  'Generate new report': 'Generar un nuevo informe',
  'Download report': 'Descargar informe',
  '30 days (daily': '30 días (por día)',
  '7 days (daily': '7 días (por día)',
  'Report ID': 'ID del informe',
  'Report date': 'Fecha del informe',
  'From date': 'De fecha',
  'To date': 'Hasta fecha',
  'No reports exist for the selected filters': 'No existen informes para los filtros seleccionados',
  'Please select a report start date': 'Seleccione una fecha de inicio del informe',
  'Fetching reports for selected start date...': 'Obteniendo informes para la fecha de inicio seleccionada...',
  'Error fetching reports': 'Error al obtener informes',
  'Report generation in progress': 'Generación de informe en curso',
  'The report generation process can take some time. You can now leave this page. An email will be sent to you once your report is available for download.': 'El proceso de generación de informes puede tardar algún tiempo. Ya puede salir de esta página. Se le enviará un correo electrónico cuando su informe esté disponible para su descarga.',
  'Select a': 'Seleccione un',
  'OK': 'OK',
  'Founding Partners': 'Socios fundadores',
  'Retrieving data failed with error:': 'La recuperación de datos ha fallado con el siguiente error:',
  'has': 'tiene',
  'alert': 'alerta',
  'alerts': 'alertas',
  'above': 'encima',
  'below': 'abajo',
  'threshold': 'umbral',
  'No Thresholds set': 'No se han establecido umbrales',
  'PH': 'PH',
  'UV A': 'UV A',
  'Envirosense PV Power': 'Energía fotovoltaica Envirosense',
  'PV Current': 'Corriente fotovoltaica',
  'PV Voltage': 'Tensión fotovoltaica',
  'PV Load Voltage': 'Tensión de carga fotovoltaica',
  'PV Load Current': 'Corriente de carga fotovoltaica',
  'PV VMP': 'VMP fotovoltaica',
  'PV Max Output Power': 'Potencia de salida máx. fotovoltaica',
  'Photo Fan Output Power': 'Potencia de salida del ventilador fot.',
  'Photo Fan Motor Speed': 'Velocidad del motor del ventilador fot.',
  'Photo Fan VFD Output Freq': 'Frecuencia de salida VFD del ventilador fot.',
  'A new version is available.': 'Hay una nueva versión disponible.',
  'Click here to update.': 'Haga clic aquí para actualizar.',
  'See the latest updates on SecondSky Data': '',
  'No results': '',
  'View alerts': '',
  'View overview': '',
  'Alerts': '',
  'Search by sensor name': '',
  'Cellular Envirosense': '',
  'Cellular Watersense': '',
  'Cellular Soilsense': '',
  'Notification': '',
  'Caution': '',
  'Offline': '',
  'Is in use': '',
  'Threshold': '',
  'Expand all': '',
  'Collapse all': '',
  'Expand': '',
  'Collapse': '',
  'Export CSV': '',
  'Reset all': '',
  'Zone ID': 'ID de la zona',
  'Sensor ID': '',
  'Sensor type': '',
  'Hardware version': '',
  'Threshold value': '',
  'Last value': '',
  'Sending data': '',
  'Threshold trigger': '',
  'Last sending': '',
  'Last checked': '',
  'Alert': '',
  'Go to sensor dashboard': '',
  'Select a device type': '',
  'Please enter an email': 'Introduzca un correo electrónico',
  'View factory suppliers': 'Ver proveedores de fábrica',
  'No suppliers found': 'No se han encontrado proveedores',
  'Add Supplier': 'Añadir proveedor',
  'Admin Users': 'Administrar usuarios',
  'See Users': 'Ver usuarios',
  'Supplier created': 'Proveedor creado',
  'User created': 'Usuario creado',
  'User deleted': 'Usuario eliminado',
  'User profile': '',
  'Support request successfully sent.': 'Solicitud de asistencia enviada correctamente.',
  'Email could not be sent.': 'No se ha podido enviar el correo electrónico.',
  '\'Cant find what youre looking for? Visit the \'': '\'¿No encuentra lo que está buscando? Visite el \'',
  'Help Centre': 'Centro de ayuda',
  'or': 'o',
  'Send us a Message': 'Envíenos un mensaje',
  'Recommended Articles': 'Artículos recomendados',
  'See': 'Ver',
  'more articles': 'más artículos',
  'Running into problems?': '¿Tiene algún problema?',
  'Visit our': 'Visite nuestro',
  'helpdesk': 'centro de ayuda',
  'or fill out the form to contact support.': 'o rellene el formulario para contactar con el servicio de asistencia.',
  'Enter your full name here': 'Introduzca su nombre completo aquí',
  'Enter your email address here': 'Introduzca su dirección de correo electrónico aquí',
  'Support Details': 'Detalles de la asistencia',
  'Support Type': 'Tipo de asistencia',
  'Select support type': 'Seleccione tipo de asistencia',
  'Title/Subject': 'Título/Asunto',
  'Provide a title or subject': 'Proporcione un título o asunto',
  'Describe your problem': 'Describa su problema',
  'Please describe your problem or difficulty here': 'Describa su problema o inconveniente aquí',
  'Submit Request': 'Enviar solicitud',
  'Please provide a name': 'Proporcione un nombre',
  'Name cannot be empty': 'El nombre no puede estar vacío',
  'Name cannot consist of only spaces': 'El nombre no puede constar solo de espacios',
  'Please provide a valid email address': 'Proporcione una dirección de correo electrónico válida',
  'Please provide a subject': 'Indique un asunto',
  'Description must be no more than 255 characters': 'La descripción no debe tener más de 255 caracteres',
  'Please provide a description': 'Proporcione una descripción',
  'Select location': 'Elegir ubicación',
  'Select option': 'Seleccionar opción',
  'Models': 'Modelos',
  'Recommendations': '',
  'Threshold Messages': '',
  'Back to menu': 'Volver al menú',
  'Update Location': 'Actualizar ubicación',
  'About': 'Acerca de',
  'Dashboard': 'Panel',
  'Map': '',
  'Video Feeds': 'Transmisiones de vídeo',
  'Blueprint': 'Plano',
  'Packaging Units': 'Unidades de empaquetado',
  'Branches': 'Sucursales',
  'Unit Prices': 'Precios unitarios',
  'Enroll a device': 'Inscribir un dispositivo',
  'User management': 'Administración de usuarios',
  'User roles': 'Roles de usuario',
  'User options': 'Opciones de usuario',
  'System thresholds': 'Umbrales del sistema',
  'Notifications': 'Notificaciones',
  'Sign in': 'Iniciar sesión',
  'Sign out': 'Cerrar sesión',
  'is': 'está',
  'status': 'estado',
  'Set Target Temperature': 'Establecer temperatura objetivo',
  'Calibrate dissolved oxygen': 'Calibrar oxígeno disuelto',
  'Calibrate electrical conductivity': 'Calibrar conductividad eléctrica',
  'Calibrate Ph': 'Calibrar Ph',
  'Calibrate PAR': 'Calibrar PAR',
  'Calibrate Humidity': 'Calibrar humedad',
  'Calibrate Temperature': 'Calibrar temperatura',
  'Live Data': 'Datos en vivo',
  'No Signal': 'Sin señal',
  'Start stream': 'Iniciar transmisión',
  'Stop stream': 'Detener transmisión',
  'Or': 'O',
  'Watch Archive footage': 'Ver imágenes de archivo',
  'Hide Archive footage': 'Ocultar imágenes de archivo',
  'View Video Feed': 'Ver transmisiones de vídeo',
  'Available Video Feeds': 'Transmisiones de vídeo disponibles',
  'No available feeds': 'No hay transmisiones disponibles',
  'Search footage': 'Buscar imágenes',
  'Heatmap': 'Mapa de calor',
  'Filter': 'Filtro',
  'Could not create user. Please try again.': 'No se ha podido crear el usuario. Inténtelo de nuevo.',
  'Toggle': '',
  'dark mode': '',
  'light mode': '',
  'system mode': '',
  'The document must be a pdf file.': 'El documento debe ser un archivo pdf.',
  'Drop the file here': 'Suelte el archivo aquí',
  '\'Click to chose a file or dragndrop it here\'': '«Haga clic para elegir un archivo o arrastrarlo aquí»',
  'go to': 'ir a',
  'next': 'siguiente',
  'last': 'último',
  'previous': 'anterior',
  'first': 'primero',
  'Pagination Navigation': 'Navegación de paginación',
  'of': 'de',
  'items': 'artículos',
  'pages': 'páginas',
  'page': 'página',
  'You do not have permission to view this page.': '',
  'Start creation of default deployment successfully.': 'Se ha iniciado correctamente la creación de la implementación predeterminada.',
  'Started core device updates successfully': 'Se han iniciado correctamente las actualizaciones del dispositivo central',
  'Error fetching core device provision installer': 'Error al obtener el instalador de aprovisionamiento del dispositivo central',
  'Area created successfully.': 'Área creada correctamente.',
  'Area updated successfully.': 'Área actualizada correctamente.',
  'Plan created successfully.': 'Plan creado correctamente.',
  'Plan updated successfully.': 'Plan actualizado correctamente.',
  'Harvest created successfully.': 'Cosecha creada correctamente.',
  'Harvest updated successfully.': 'Cosecha actualizada correctamente.',
  'Harvest deleted successfully.': 'Cosecha eliminada correctamente.',
  'Sale created successfully.': 'Venta creada correctamente.',
  'Sale updated successfully.': 'Venta actualizada correctamente.',
  'Sale item created successfully.': 'Artículo en venta creado correctamente.',
  'Sale item updated successfully.': 'Artículo en venta actualizado correctamente.',
  'Unable to remove item referenced by existing return.': 'No se puede eliminar el artículo referenciado por devolución existente.',
  'Sale Shipment created successfully.': 'Envío de venta creado correctamente.',
  'Sale Shipment successfully.': 'Envío de venta correcto.',
  'Unable to remove shipment referenced by existing return.': 'No se puede eliminar el envío referenciado por devolución existente.',
  'Waste created successfully.': 'Residuo creado correctamente.',
  'Waste updated successfully.': 'Residuo actualizado correctamente.',
  'Return created successfully.': 'Devolución creada correctamente.',
  'Return updated successfully.': 'Devolución actualizada correctamente.',
  'Customer created successfully.': 'Cliente creado correctamente.',
  'Customer updated successfully.': 'Cliente actualizado correctamente.',
  'Branch created successfully.': 'Sucursal creada correctamente.',
  'Branch updated successfully.': 'Sucursal actualizada correctamente.',
  'Unit price created successfully.': 'Precio unitario creado correctamente.',
  'Unit price updated successfully.': 'Precio unitario actualizado correctamente.',
  'Unit price deleted successfully.': 'Precio unitario eliminado correctamente.',
  'Unable to create duplicate unit price.': 'No es posible crear un precio unitario duplicado.',
  'Unable to save duplicate unit price.': 'No es posible guardar un precio unitario duplicado.',
  'City created successfully.': 'Ciudad creada correctamente.',
  'City updated successfully.': 'Ciudad actualizada correctamente.',
  'Country created successfully.': 'País creado correctamente.',
  'Country updated successfully.': 'País actualizado correctamente.',
  'Currency created successfully.': 'Moneda creada correctamente.',
  'Currency updated successfully.': 'Moneda actualizada correctamente.',
  'Grade created successfully.': 'Grado creado correctamente.',
  'Grade updated successfully.': 'Grado actualizado correctamente.',
  'Package unit created successfully.': 'Unidad de empaquetado creada correctamente.',
  'Package unit updated successfully.': 'Unidad de empaquetado actualizada correctamente.',
  'Payment method created successfully.': 'Método de pago creado correctamente.',
  'Payment method updated successfully.': 'Método de pago actualizado correctamente.',
  'Payment terms created successfully.': 'Condiciones de pago creadas correctamente.',
  'Payment terms updated successfully.': 'Condiciones de pago actualizadas correctamente.',
  'Produce created successfully.': 'Producto agrícola creado correctamente.',
  'Produce updated successfully.': 'Producto agrícola actualizado correctamente.',
  'Region created successfully.': 'Región creada correctamente.',
  'Region updated successfully.': 'Región actualizada correctamente.',
  'Shipping method created successfully.': 'Método de envío creado correctamente.',
  'Shipping method updated successfully.': 'Método de envío actualizado correctamente.',
  'Storage facility created successfully.': 'Instalación de almacenamiento creada correctamente.',
  'Storage facility updated successfully.': 'Instalación de almacenamiento actualizada correctamente.',
  'Variety created successfully.': 'Variedad creada correctamente.',
  'Variety updated successfully.': 'Variedad actualizada correctamente.',
  'Inventory calibration created successfully.': 'Calibración de inventario creada correctamente.',
  'Inventory transfer created successfully.': 'Transferencia de inventario creada correctamente.',
  'Inventory transfer updated successfully.': 'Transferencia de inventario actualizada correctamente.',
  'Package unit purchase created successfully.': 'Compra de unidad empaquetado creada correctamente.',
  'Package unit purchase updated successfully.': 'Compra de unidad empaquetado actualizada correctamente.',
  'Packaging inventory calibration created successfully.': 'Calibración de inventario de empaquetado creada correctamente.',
  'Packaging inventory transfer created successfully.': 'Transferencia de inventario de empaquetado creada correctamente.',
  'Packaging inventory transfer updated successfully.': 'Transferencia de inventario de empaquetado actualizada correctamente.',
  'Product created successfully.': 'Producto creado correctamente.',
  'Product updated successfully.': 'Producto actualizado correctamente.',
  'Crop created successfully.': 'Cultivo creado correctamente.',
  'Crop updated successfully.': 'Cultivo actualizado correctamente.',
  'Supplier created successfully.': 'Proveedor creado correctamente.',
  'Supplier updated successfully.': 'Proveedor actualizado correctamente.',
  'Fertilizer purchase created successfully.': 'Compra de fertilizante creada correctamente.',
  'Fertilizer purchase updated successfully.': 'Compra de fertilizante actualizada correctamente.',
  'Fertilizer inventory calibration created successfully.': 'Calibración de inventario de fertilizantes creada correctamente.',
  'Fertilizer inventory transfer created successfully.': 'Transferencia de inventario de fertilizantes creada correctamente.',
  'Fertilizer inventory transfer updated successfully.': 'Transferencia de inventario de fertilizantes actualizada correctamente.',
  'Harvest data not fetched': 'Datos de cosecha no obtenidos',
  'Crops data not fetched': 'Datos de cultivo no obtenidos',
  'Error fetching control device config': 'Error al obtener la configuración del dispositivo de control',
  'Control device config saved successfully': 'Configuración del dispositivo de control guardada correctamente',
  'Control device config updated successfully': 'Configuración del dispositivo de control actualizada correctamente',
  'Control device config deleted successfully': 'Configuración del dispositivo de control eliminada correctamente',
  'Control device spec saved successfully': 'Especificación del dispositivo de control guardada correctamente',
  'Control device spec updated successfully': 'Especificación del dispositivo de control actualizada correctamente',
  'Diagnostics request sent succesfully. You will receive an email with the results.': '',
  'Sensor restart has been scheduled for the next wake up cycle.': '',
  'SIM status updated successfully.': '',
  'Could not update the SIM status. Our team has been notified and will investigate.': '',
  'Some of the SIM pings failed. Our team has been notified and will investigate.': '',
  'Could not fetch help text entries': 'No se han podido recuperar las entradas del texto de ayuda',
  'Translations requested': 'Traducciones solicitadas',
  'Could not generate translations': 'No se han podido generar las traducciones',
  'Help text entry created': 'Entrada del texto de ayuda creada',
  'Could not create help text entry': 'No se ha podido crear la entrada del texto de ayuda',
  'Help text updated': 'Texto de ayuda actualizado',
  'Could not update help text': 'No se ha podido actualizar el texto de ayuda',
  'Text could not be generated from prompt': 'No se ha podido generar el texto solicitado',
  'Release note created': 'Nota de la versión creada',
  'Could not create release note': 'No se ha podido crear la nota de la versión',
  'Release note updated': 'Nota de la versión actualizada',
  'Could not update release note': 'No se ha podido actualizar la nota de la versión',
  'Release note section created': 'Sección de la nota de la versión creada',
  'Could not create release note section': 'No se ha podido crear la sección de la nota de la versión',
  'Release note section updated': 'Sección de la nota de la versión actualizada',
  'Could not update release note section': 'No se ha podido actualizar la sección de la nota de la versión',
  'Could not fetch all release notes data': 'No se han podido obtener todos los datos de las notas de la versión',
  'Could not fetch release notes': 'No se han podido obtener las notas de la versión',
  'Translations are being generated': 'Se están generando las traducciones',
  'Translations could not be generated': 'No se han podido generar las traducciones',
  'Could not fetch tags': 'No se han podido obtener etiquetas',
  'Tag created': 'Etiqueta creada',
  'Could not create tag': 'No se ha podido crear la etiqueta',
  'Tag updated': 'Etiqueta actualizada',
  'Could not update tag': 'No se ha podido actualizar la etiqueta',
  'Regeneration of device firmware started successfully.': 'La regeneración del firmware del dispositivo se ha iniciado correctamente.',
  'Organization label created successfully.': 'Etiqueta de organización creada correctamente.',
  'Error creating the label. Please try again later.': 'Error al crear la etiqueta. Inténtelo de nuevo más tarde.',
  'Organization label updated successfully.': 'Etiqueta de organización actualizada correctamente.',
  'Error updating the label. Please try again later.': 'Error al actualizar la etiqueta. Inténtelo de nuevo más tarde.',
  'Organization label translations updated successfully.': 'Traducciones de la etiqueta de la organización actualizadas correctamente.',
  'Error updating the label translations. Please try again later.': 'Error al actualizar las traducciones de la etiqueta. Inténtelo de nuevo más tarde.',
  'created successfully': 'creada/o correctamente.',
  'Error creating': 'Error al crear',
  'sucessfully updated!': 'actualizado correctamente.',
  'Error updating': 'Error al actualizar',
  'Error updating zone maintenance mode': 'Error al actualizar el modo de mantenimiento de la zona',
  'Device statuses fetched': 'Estados del dispositivo obtenidos',
  'Snooze not fetched': 'Repetición no obtenida',
  'Snoozes not fetched': 'Repeticiones no obtenidas',
  'Snooze not created': 'Repetición no creada',
  'Snooze not deleted': 'Repetición no eliminada',
  'Username/email is required': 'Es necesario indicar el nombre de usuario/correo electrónico',
  'Password is required': 'Es necesario indicar la contraseña',
  'Code is required': 'Es necesario indicar el código',
  'Confirm password is required': 'Es necesario confirmar la contraseña',
  'Organization name is required': 'Es necesario indicar el nombre de la organización',
  'Email format is incorrect': 'El formato del correo electrónico es incorrecto',
  'Email is required': 'Es necesario indicar el correo electrónico',
  'First name is required': 'Es necesario indicar el nombre',
  'Last name is required': 'Es necesario indicar el apellido',
  'Username is required': 'Es necesario indicar el nombre de usuario'
}}

export default words
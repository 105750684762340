import { call, race, take, delay as sagaDelay } from 'redux-saga/effects'
import { showBanner } from '@/slices/util'

export const displayBanner = (error, successMessage) => {
  if (error || successMessage) {
    return showBanner({
      type: error ? 'error' : 'success',
      message: error ? error : successMessage,
      show: true
    })
  }
}

export const getCurrentUserOrganizations = state =>
  state.rootReducer?.managementUserReducer?.currentUser?.organizations ?? []

export const getRootZone = state =>
  state.rootReducer?.managementZoneReducer?.rootZone

export const getDeviceTypes = state =>
  state?.rootReducer?.deviceManagementDeviceTypeReducer?.deviceTypes

/**
 * A utility function to combine actions over a debounce period.
 * @param {string} actionType - The type of action to listen for.
 * @param {number} debounceDelay - The debounce delay in milliseconds.
 * @param {Function} callback - The callback function to execute with the aggregated actions.
 */
export function* takeCombined(actionType, callback, debounceDelay = 500) {
  let paramsBuffer = []

  while (true) {
    // Capture the first action and start buffering
    const firstAction = yield take(actionType)
    paramsBuffer.push(firstAction)

    while (true) {
      // Wait for either a new action of the same type or the specified timeout
      const { action, timeout } = yield race({
        action: take(actionType),
        timeout: sagaDelay(debounceDelay)
      })

      // If timeout occurs, break the loop and proceed to callback
      if (timeout) break

      // If a new action is received, add it to the buffer
      paramsBuffer.push(action)
    }

    // Execute the callback with the aggregated actions
    yield call(callback, paramsBuffer)

    // Clear the buffer for the next cycle
    paramsBuffer = []
  }
}

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { sendSupportEmail } from '@/api/support'

export const clearEmail = createAsyncThunk('clearEmail', () => {
  return {}
})

export const sendEmail = createAsyncThunk('sendSupportEmail', async params => {
  const { result = {}, error } = await sendSupportEmail(params)
  return { result, error }
})

const supportSlice = createSlice({
  name: 'supportReducer',
  initialState: {
    emailSent: null,
    error: null,
    sending: false
  },
  extraReducers: builder => {
    builder
      .addCase(clearEmail.fulfilled, (state, action) => {
        state.emailSent = null
        state.error = null
      })
      .addCase(sendEmail.pending, (state, action) => {
        state.error = null
        state.sending = true
      })
      .addCase(sendEmail.fulfilled, (state, action) => {
        state.emailSent = action?.payload?.result ?? state.emailSent
        state.error = action?.payload?.error ?? null
        state.sending = false
      })
  }
})

export default supportSlice.reducer

import { FlexV2 } from '@/primitives'

import useMediaQuery from '@/hooks/useMediaQuery'

export default function SectionHeaderWrapper({ children }) {
  const isAboveCustomBreakpoint = useMediaQuery('min-width: 620px')
  return (
    <FlexV2
      direction={isAboveCustomBreakpoint ? 'row' : 'column'}
      alignCrossAxis={isAboveCustomBreakpoint ? 'center' : null}
      axisGap={400}
      alignMainAxis='space-between'
    >
      {children}
    </FlexV2>
  )
}

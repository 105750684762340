import { Fragment, useState, useCallback, useEffect } from 'react'
import classnames from 'classnames'

import { Button, Box } from '../../primitives'
import './index.scss'

/**
 * Tabs Element
 * @param {Object} props
 * @param {String | String[]} [props.className] Extra Class Name
 * @param {import('react').CSSProperties} [props.css]
 * @param {TabConfig[]} [props.tabConfigs=[]] An array of objects with info for each tab (tabLabel, renderTabContent and tabTextSize)
 *
 * @param {Number} [props.activeInitialIndex=0] Optional. Set the tab that is initally selected (e.g if the tab is tied to the current route, you can go directly to the tab based on location)
 *
 *
 * @example
 * ```jsx
 * <Tabs tabConfigs={tabConfigs} />
 * ```
 */

/**
 * TabConfig
 * @typedef {Object} TabConfig
 * @property {String} [tabLabel=''] The tab label that the user sees
 * @property {Function} [renderTabContent] Returns component to display when tab is selected
 * @property {'small' | 'medium' | 'large'} [tabTextSize='medium'] Set the text size for the tab label
 */

/**
 * Tab Object
 * @param {Object} props
 * @param {String} [props.tabLabel] The tab label that the user sees
 * @param {String} [props.index] Index of the tab item in the tabConfigs array; identifies the tab in the onClick function
 * @param {Number} [props.activeTab] Index of the currently-selected tab
 * @param {Function} [props.setActiveTab] Set the index of the currently-selected tab
 * @param {Function} [props.renderTabContent] Returns component to display when tab is selected
 * @param {'small' | 'medium' | 'large'} [props.size] Set the text size for the tab label
 *
 *
 * @example
 * ```jsx
 * {
    tabLabel: subzonesHeading,
    renderTabContent: () => (
      <ZoneSubzones
        selectedZone={selectedZone}
        onClickCard={onClickCard}
        onClickAddZone={onClickAddZone}
      />
    )
  }
 * ```
 */

const Tab = ({
  tabLabel,
  index,
  activeTab,
  setActiveTab,
  size,
  onSwitchTab
}) => {
  const onClickTab = useCallback(() => {
    setActiveTab(index)
    if (onSwitchTab) {
      onSwitchTab(index)
    }
  }, [index, onSwitchTab, setActiveTab])

  return (
    <Box
      as='li'
      role='tab'
      className={classnames({ active: index === activeTab })}
    >
      <Button onClick={onClickTab} variant='text' size={size}>
        {tabLabel}
      </Button>
    </Box>
  )
}

const Tabs = ({
  tabConfigs = [],
  activeIndex = 0,
  onSwitchTab = null,
  style
}) => {
  const [activeTab, setActiveTab] = useState(activeIndex)

  useEffect(() => {
    setActiveTab(activeIndex)
  }, [activeIndex])

  const getTabsList = () => {
    return tabConfigs.map(
      ({ tabLabel = '', tabTextSize = 'medium' }, index) => (
        <Tab
          tabLabel={tabLabel}
          index={index}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          size={tabTextSize}
          onSwitchTab={onSwitchTab}
          key={`tab-${tabLabel}`}
        />
      )
    )
  }

  return (
    <Fragment>
      <Box as='ul' className='Tabs' role='tablist' style={style}>
        {getTabsList()}
      </Box>
      <Box role='tabpanel'>{tabConfigs[activeTab].renderTabContent()}</Box>
    </Fragment>
  )
}

export default Tabs

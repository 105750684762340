import { updateWeight, removeWeight } from '../../../Shared/Weights/utils'

import { fixDate } from '../../utils'

export const UPDATE_INPUT = 'UPDATE_INPUT'
export const UPDATE_SITE_ID = 'UPDATE_SITE_ID'
export const SET_STATE = 'SET_STATE'

export const ADD_WEIGHT = 'ADD_WEIGHT'
export const REMOVE_WEIGHT = 'REMOVE_WEIGHT'
export const UPDATE_WEIGHT = 'UPDATE_WEIGHT'

export const UPDATE_PHOTO = 'UPDATE_PHOTO'
export const REMOVE_PHOTO = 'REMOVE_PHOTO'

export const initialState = {
  id: null,
  organizationId: '',
  siteId: '',
  zoneId: '',
  produceId: '',
  varietyId: '',
  storageFacilityId: '',
  harvestDate: null,
  weights: [],
  totalWeight: '',
  photos: []
}

export function reducer(state, action) {
  const handlers = {
    [SET_STATE]: (state, action) => {
      const newState = { ...action.state }
      newState.harvestDate = fixDate(newState.harvestDate)
      return {
        ...newState
      }
    },
    [UPDATE_INPUT]: (state, action) => ({
      ...state,
      [action.name]: action.value
    }),
    [UPDATE_SITE_ID]: (state, action) => ({
      ...state,
      siteId: action.siteId,
      zoneId: null
    }),
    [UPDATE_WEIGHT]: (state, action) => updateWeight(state, action),
    [REMOVE_WEIGHT]: (state, action) => removeWeight(state, action),
    [UPDATE_PHOTO]: (state, action) => {
      let photos = [...state.photos]
      const index = photos.findIndex(({ id }) => id === action.photo.id)
      if (index >= 0) {
        photos[index] = action.photo
      } else {
        photos.push(action.photo)
      }
      return {
        ...state,
        photos
      }
    },
    [REMOVE_PHOTO]: (state, action) => {
      let photos = [...state.photos]
      const index = photos.findIndex(({ id }) => id === action.photo.id)
      if (index >= 0) {
        photos.splice(index, 1)
      }
      return {
        ...state,
        photos
      }
    }
  }
  return handlers[action.type](state, action) || state
}

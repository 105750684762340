import { Box } from '../'
import { getSlottedChildren } from '../utils'
import './index.scss'

/**
 * Card Primitive
 * @param {Object} props
 * @param {String | String[]} [props.className]
 * @param {import('react').CSSProperties} [props.css]
 * @example
 * ```jsx
 * <Card>
 *   <Slot name='image'>Image Slot <img /></Slot>
 *   <Slot name='header'>Heading slot</Slot>
 *   <Slot name='body'>Body slot</Slot>
 *   <Slot name='footer'>Footer slot</Slot>
 * </Card>
 * ```
 *
 * @slot image   - The card's image.
 * @slot header  - The card's header.
 * @slot body    - The card's body.
 * @slot rest    - Unassigned element space.
 * @slot footer  - The card's footer.
 */
const Card = ({ children = null, className = '', ...rest }) => {
  const slots = getSlottedChildren(children, {
    header: null,
    body: null,
    footer: null,
    image: null,
    rest: null
  })

  return (
    <Box className={['Card', className]} {...rest}>
      {slots.image && <Box className='Card__image'>{slots.image}</Box>}
      {slots.header && <Box className='Card__header'>{slots.header}</Box>}
      {slots.body && <Box className='Card__body'>{slots.body}</Box>}
      {slots.rest && <Box className='Card__rest'>{slots.rest}</Box>}
      {slots.footer && <Box className='Card__footer'>{slots.footer}</Box>}
    </Box>
  )
}

export default Card

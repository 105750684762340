import { getZonesHierarchy } from '../../../../../reducers/selectors'

import { Button, Cell, Row } from '../../../../../primitives'

import { formatDate } from '../../utils'
import { PLAN_STATUS_TYPE } from '../utils'

import Strings from '../Strings'

import ZoneUtils from '../../../../../Util/ZoneUtils'

function TableRow({ item, onEdit, canEdit }) {
  const zonesHierarchy = getZonesHierarchy()
  const strings = Strings()

  const statusTypes = PLAN_STATUS_TYPE(strings)

  const {
    id,
    siteId,
    zoneId,
    produce,
    variety,
    currency,
    status,
    harvestStartDate,
    harvestEndDate,
    totalWeight,
    targetPrice
  } = item

  const getZoneName = (site, zone) => {
    return ZoneUtils.getZoneName(zonesHierarchy, site, zone)
  }

  const handleEdit = () => {
    onEdit(id)
  }

  const calculateTotalPrice = () => {
    return targetPrice * totalWeight
  }

  return (
    <Row>
      <Cell columnName={strings.date}>
        {formatDate(harvestStartDate)} - {formatDate(harvestEndDate)}
        <span>{statusTypes[status]}</span>
      </Cell>
      <Cell columnName={strings.zone}>
        {getZoneName(siteId)}
        <span>{getZoneName(siteId, zoneId)}</span>
      </Cell>
      <Cell columnName={strings.produce}>
        {produce.name}
        <span>{variety.name}</span>
      </Cell>
      <Cell columnName={strings.weight}>
        {totalWeight} {strings.kg}
        <span>
          {calculateTotalPrice()} {currency.code}
        </span>
      </Cell>
      <Cell className='Operations__Table__Column__Actions'>
        {canEdit && (
          <Button
            variant='info'
            size='small'
            iconBefore='edit'
            onClick={handleEdit}
          />
        )}
      </Cell>
    </Row>
  )
}

export default TableRow

import { useState, useEffect, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { Switch, Route, useParams } from 'react-router-dom'

import {
  clearPackageUnit,
  clearPackageUnits
} from '@/actions/operations/packageUnit'

import { tableReducer, tableInitialState } from './state'

import Table from './Table'
import Form from './Form'

const MODULE_PATH = '/operations/:zone+/inventory/packaging/package-units'

function PackageUnitManagement() {
  const dispatch = useDispatch()

  const params = useParams()

  const [state, dispatchState] = useReducer(tableReducer, tableInitialState)

  const [modulePath, setModulePath] = useState(MODULE_PATH)

  useEffect(() => {
    return () => {
      dispatch(clearPackageUnit())
      dispatch(clearPackageUnits())
    }
  }, [dispatch])

  useEffect(() => {
    if (params?.zone) {
      setModulePath(MODULE_PATH.replace(':zone+', params.zone))
    }
  }, [params?.zone])

  return (
    <Switch>
      <Route
        path={[`${MODULE_PATH}/create`, `${MODULE_PATH}/update/:itemId`]}
        exact={true}
      >
        <Form
          state={state}
          dispatchState={dispatchState}
          modulePath={modulePath}
        />
      </Route>
      <Route path={MODULE_PATH} exact={true}>
        <Table
          state={state}
          dispatchState={dispatchState}
          modulePath={modulePath}
        />
      </Route>
    </Switch>
  )
}

export default PackageUnitManagement

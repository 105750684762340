import { updateList } from '../util'

import {
  CLEAR_UNIT_PRICES,
  REQUEST_UNIT_PRICES,
  RECEIVE_UNIT_PRICES,
  REQUEST_UNIT_PRICE,
  RECEIVE_UNIT_PRICE,
  CREATE_UNIT_PRICE,
  UNIT_PRICE_CREATED,
  UPDATE_UNIT_PRICE,
  UNIT_PRICE_UPDATED,
  UNIT_PRICE_DELETED,
  DELETE_UNIT_PRICE
} from '@/actions/operations/unitPrice'

export const unitPriceInitialState = {
  unitPrices: [],
  unitPricesCount: 0,
  unitPrice: {},
  loading: false,
  error: null
}

const handlers = {
  [CLEAR_UNIT_PRICES]: state => ({
    ...state,
    unitPrices: [],
    unitPricesCount: 0,
    error: null
  }),
  [REQUEST_UNIT_PRICES]: (state, action) => ({
    ...state,
    unitPrices: [],
    unitPricesCount: 0,
    error: null,
    loading: true
  }),
  [RECEIVE_UNIT_PRICES]: (state, action) => ({
    ...state,
    unitPrices: !action.error ? action.unitPrices : [],
    unitPricesCount: action?.count ?? 0,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_UNIT_PRICE]: state => ({
    ...state,
    unitPrice: {},
    error: null,
    loading: true
  }),
  [RECEIVE_UNIT_PRICE]: (state, action) => ({
    ...state,
    unitPrice: !action.error ? action.unitPrice : state.unitPrice,
    error: action.error ?? null,
    loading: false
  }),
  [CREATE_UNIT_PRICE]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [UNIT_PRICE_CREATED]: (state, action) => ({
    ...state,
    unitPrice: !action.error ? {} : state.unitPrice,
    unitPrices: updateList(
      action.error,
      state.unitPrices,
      action.unitPrice,
      true
    ),
    unitPricesCount: !action.error
      ? state.unitPricesCount + 1
      : state.unitPricesCount,
    error: action.error ?? null,
    loading: false
  }),
  [UPDATE_UNIT_PRICE]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [UNIT_PRICE_UPDATED]: (state, action) => ({
    ...state,
    unitPrice: !action.error ? {} : state.unitPrice,
    unitPrices: updateList(
      action.error,
      state.unitPrices,
      action.unitPrice,
      false
    ),
    error: action.error ?? null,
    loading: false
  }),
  [DELETE_UNIT_PRICE]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [UNIT_PRICE_DELETED]: (state, action) => {
    if (!action?.error) {
      let unitPrices = state.unitPrices
      const unitPriceIndex = unitPrices.findIndex(
        unitPrice => unitPrice.id === action.unitPrice.id
      )
      if (unitPriceIndex >= 0) {
        unitPrices.splice(unitPriceIndex, 1)
      }
      return {
        ...state,
        unitPrices: unitPrices,
        unitPricesCount: state.unitPricesCount - 1,
        error: null,
        loading: false
      }
    } else {
      return {
        ...state,
        error: action.error,
        loading: false
      }
    }
  }
}

export default handlers

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {
  createProduceThreshold,
  updateProduceThreshold,
  getProduceThresholds,
  getProduceThreshold
} from '@/api/operations/produceThreshold'

export const sendCreateProduceThreshold = createAsyncThunk(
  'sendCreateProduceThreshold',
  async params => {
    const { produceThreshold, error } = await createProduceThreshold(params)
    return { produceThreshold, error }
  }
)

export const sendUpdateProduceThreshold = createAsyncThunk(
  'sendUpdateProduceThreshold',
  async params => {
    const { produceThreshold, error } = await updateProduceThreshold(params)
    return { produceThreshold, error }
  }
)

export const fetchProduceThresholds = createAsyncThunk(
  'fetchProduceThresholds',
  async params => {
    const { produceThresholds, error } = await getProduceThresholds(params)
    return { produceThresholds, error }
  }
)

export const fetchProduceThreshold = createAsyncThunk(
  'fetchProduceThreshold',
  async params => {
    const { produceThreshold, error } = await getProduceThreshold(params)
    return { produceThreshold, error }
  }
)

const operationsProduceThresholdSlice = createSlice({
  name: 'operationsProduceThresholdReducer',
  initialState: {
    produceThreshold: {},
    produceThresholds: [],
    produceThresholdsCount: 0,
    updatedProduceThreshold: {},
    error: null,
    loading: false
  },
  reducers: {
    clearProduceThreshold: state => {
      state.produceThreshold = {}
    },
    clearProduceThresholds: state => {
      state.produceThresholds = []
    },
    cleanUpdatedProduceThreshold: state => {
      state.updatedProduceThreshold = {}
    },
    setProduceThreshold: (state, action) => {
      state.produceThreshold = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(sendCreateProduceThreshold.pending, (state, action) => {
        state.error = null
        state.loading = true
      })
      .addCase(sendCreateProduceThreshold.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.loading = false
        state.produceThreshold = action?.payload?.produceThreshold ?? {}
      })
      .addCase(sendUpdateProduceThreshold.pending, (state, action) => {
        state.error = null
        state.loading = true
      })
      .addCase(sendUpdateProduceThreshold.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.loading = false
        state.produceThreshold = action?.payload?.produceThreshold ?? {}
      })
      .addCase(fetchProduceThresholds.pending, (state, action) => {
        state.error = null
        state.loading = true
      })
      .addCase(fetchProduceThresholds.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.loading = false
        state.produceThresholds = action?.payload?.produceThresholds ?? {}
        state.produceThresholdsCount =
          action?.payload?.produceThresholds?.length ?? 0
      })
      .addCase(fetchProduceThreshold.pending, (state, action) => {
        state.error = null
        state.loading = true
      })
      .addCase(fetchProduceThreshold.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.loading = false
        state.produceThreshold = action?.payload?.produceThreshold ?? {}
      })
  }
})

export const {
  clearProduceThreshold,
  clearProduceThresholds,
  cleanUpdatedProduceThreshold,
  setProduceThreshold
} = operationsProduceThresholdSlice.actions

export default operationsProduceThresholdSlice.reducer

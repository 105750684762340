import cloneDeep from 'lodash/cloneDeep'
import { useEffect, useState } from 'react'

import { useDashboard } from '@/components/DashboardPageV2/Desktop/context'
import { WIDGET_EDIT_RESIZE } from '@/components/DashboardPageV2/Desktop/state'

export function useEditResize(id, minEditingWidth = 3, minEditingHeight = 6.5) {
  const { state, dispatchState } = useDashboard()

  const [hasBeenEditResized, setHasBeenEditResized] = useState(false)
  const [oldPosition, setOldPosition] = useState(null)

  // This effect will resize the widget if it is too small to edit
  // Will also save the old widgets in state so that we can revert after saving or canceling
  // The old widgets will then be used to get the previous widget position and reapply it.
  useEffect(() => {
    if (!hasBeenEditResized && id) {
      const { props: currentWidget } = state.widgets.get(id)
      let position = cloneDeep(currentWidget.position)
      setOldPosition(cloneDeep(currentWidget.position))
      const [cX, cY, cW, cH] = position[state.currentBreakpoint]

      let nextPosition = [cX, cY, cW, cH, minEditingWidth, minEditingHeight]
      if (cW < minEditingWidth || cH < minEditingHeight) {
        nextPosition[2] = cW < minEditingWidth ? minEditingWidth : cW
        nextPosition[3] = cH < minEditingHeight ? minEditingHeight : cH

        position[state.currentBreakpoint] = nextPosition

        dispatchState({
          type: WIDGET_EDIT_RESIZE,
          payload: {
            id,
            position
          }
        })
        setHasBeenEditResized(true)
      }
    }
  }, [
    state.widgets,
    id,
    dispatchState,
    hasBeenEditResized,
    state.currentBreakpoint,
    minEditingWidth,
    minEditingHeight
  ])

  return { hasBeenEditResized, oldPosition }
}

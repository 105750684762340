import { call, put, takeLatest } from 'redux-saga/effects'

import {
  getReturns,
  getReturn,
  createReturn,
  updateReturn
} from '@/api/operations/return'

import {
  createReturnItem,
  updateReturnItem,
  deleteReturnItem
} from '@/api/operations/returnItem'

import {
  REQUEST_RETURNS,
  REQUEST_RETURN,
  CREATE_RETURN,
  UPDATE_RETURN,
  CREATE_RETURN_ITEM,
  UPDATE_RETURN_ITEM,
  DELETE_RETURN_ITEM,
  receiveReturns,
  receiveReturn,
  returnCreated,
  returnUpdated
} from '@/actions/operations/return'

import { displayBanner } from '../utils'

import Strings from './Strings'

const strings = Strings()

function* sendCreateReturn(action) {
  const { data, error = null } = yield call(createReturn, action.params)
  yield put(displayBanner(error, strings.returnCreated))
  yield put(returnCreated(data, error))
}

export function* watchCreateReturn() {
  yield takeLatest(CREATE_RETURN, sendCreateReturn)
}

function* sendUpdateReturn(action) {
  const { data, error = null } = yield call(updateReturn, action.params)
  yield put(displayBanner(error, strings.returnUpdated))
  yield put(returnUpdated(data, error))
}

export function* watchUpdateReturn() {
  yield takeLatest(UPDATE_RETURN, sendUpdateReturn)
}

function* sendRequestReturns(action) {
  const { data, error = null } = yield call(getReturns, action.params)
  const { returns = [], count = 0 } = data
  yield put(receiveReturns(returns, count, error))
}

export function* watchRequestReturns() {
  yield takeLatest(REQUEST_RETURNS, sendRequestReturns)
}

function* sendRequestReturn(action) {
  const { data, error = null } = yield call(getReturn, action.params)
  yield put(receiveReturn(data, error))
}

export function* watchRequestReturn() {
  yield takeLatest(REQUEST_RETURN, sendRequestReturn)
}

function* sendCreateReturnItem(action) {
  const { error = null } = yield call(createReturnItem, action.params)
  yield put(displayBanner(error))
}

export function* watchCreateReturnItem() {
  yield takeLatest(CREATE_RETURN_ITEM, sendCreateReturnItem)
}

function* sendUpdateReturnItem(action) {
  const { error = null } = yield call(updateReturnItem, action.params)
  yield put(displayBanner(error))
}

export function* watchUpdateReturnItem() {
  yield takeLatest(UPDATE_RETURN_ITEM, sendUpdateReturnItem)
}

function* sendDeleteReturnItem(action) {
  const { error = null } = yield call(deleteReturnItem, action.params)
  yield put(displayBanner(error))
}

export function* watchDeleteReturnItem() {
  yield takeLatest(DELETE_RETURN_ITEM, sendDeleteReturnItem)
}

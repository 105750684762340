import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { getCognitoData, makeGraphqlRequest } from '../utils'

export const getTags = async params => {
  try {
    const query = /* GraphQL */ `
      query getTags {
        getTags {
          tagId
          name
          translations {
            lang
            text
          }
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    return { tags: data?.getTags, error }
  } catch (err) {
    return { tags: null, error: err.message }
  }
}

export const createTag = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createTag($name: String!, $targetLangs: [String]) {
        createTag(name: $name, targetLangs: $targetLangs) {
          tagId
          name
          translations {
            text
            lang
          }
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    return { tag: data?.createTag, error }
  } catch (err) {
    return { tag: null, error: err.message }
  }
}

export const updateTag = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateTag($tagId: String!, $name: String!) {
        updateTag(tagId: $tagId, name: $name) {
          tagId
          name
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    return { tag: data?.updateTag, error }
  } catch (err) {
    return { tag: null, error: err.message }
  }
}

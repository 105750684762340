import { Fragment } from 'react'

import OrganizationsFilter from '../../../Shared/Filters/OrganizationsFilter'
import ProducesFilter from '../../../Shared/Filters/ProducesFilter'
import VarietiesFilter from '../../../Shared/Filters/VarietiesFilter'
import EntriesFilter from '../../../Shared/Filters/EntriesFilter'

import { PRODUCT_STATUSES } from '../utils'

import Strings from '../Strings'

const ProductFilter = ({ state, dispatchState }) => {
  const strings = Strings()
  const statuses = Object.entries(PRODUCT_STATUSES(strings))

  return (
    <Fragment>
      <OrganizationsFilter state={state} dispatchState={dispatchState} />
      <ProducesFilter state={state} dispatchState={dispatchState} />
      <VarietiesFilter state={state} dispatchState={dispatchState} />
      <EntriesFilter
        state={state}
        dispatchState={dispatchState}
        stateKey='statuses'
        entries={statuses}
        defaultValue='available'
        defaultText={strings.allStatuses}
      />
    </Fragment>
  )
}

export default ProductFilter

import { unparse } from 'papaparse'
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  Flex,
  Box,
  Table,
  Slot,
  Column,
  Row,
  Text,
  Loader,
  Button,
  FlexV2
} from '@/primitives'

import { Pagination } from '@/elements'

import HeaderV2 from '@/components/Operations/Shared/HeaderV2'

import TableFilter from './TableFilter'
import TableRow from './TableRow'

import {
  requestCrops,
  requestAllCrops,
  clearAllCrops
} from '@/actions/operations/crop'

import {
  getCurrentUser,
  getCurrentUserOrganizations,
  getZonesHierarchy,
  getOperationsCrops,
  getOperationsCropsCount,
  getOperationsAllCrops,
  getOperationsLoading
} from '@/reducers/selectors'

import { generateDownloadCsvLink } from '@/Util/GeneralUtils'
import { DOWNLOAD_HEADERS, DOWNLOAD_VALUES } from './config'

import history from '../../../../../history'

import useMediaQuery from '@/hooks/useMediaQuery'

import {
  hasEditPermissions,
  RESOURCE_TYPE_OPERATIONS,
  RESOURCE_TYPE_OPERATIONS_PLANS
} from '@/Util/PermissionUtils'

import Strings from '../Strings'

function CropTable({ state, dispatchState, modulePath }) {
  const dispatch = useDispatch()

  const currentUser = getCurrentUser()
  const organizations = getCurrentUserOrganizations()
  const zonesHierarchy = getZonesHierarchy()
  const items = getOperationsCrops()
  const allItems = getOperationsAllCrops()
  const totalItems = getOperationsCropsCount()
  const loading = getOperationsLoading()

  const strings = Strings()

  const isAboveBreakpoint = useMediaQuery('min-width: 1024px')

  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(9)
  const [tableState, setTableState] = useState(state)
  const [canEdit, setCanEdit] = useState(false)
  const [requestDownload, setRequestDownload] = useState(false)

  useEffect(() => {
    if (currentUser.userName) {
      setCanEdit(
        hasEditPermissions(
          currentUser,
          RESOURCE_TYPE_OPERATIONS,
          RESOURCE_TYPE_OPERATIONS_PLANS
        )
      )
    }
  }, [currentUser])

  useEffect(() => {
    if (tableState.page !== state.page || items.length === 0) {
      setTableState({ ...state })
      fetchCrops(state.page)
    }
  }, [state.page])

  useEffect(() => {
    if (
      tableState.organizations !== state.organizations ||
      tableState.sites !== state.sites ||
      tableState.produces !== state.produces ||
      tableState.varieties !== state.varieties ||
      tableState.statuses !== state.statuses
    ) {
      setTableState({ ...state })
      fetchCrops(1)
      changePage(1)
    }
  }, [
    state.organizations,
    state.sites,
    state.produces,
    state.varieties,
    state.statuses
  ])

  useEffect(() => {
    if (requestDownload) {
      dispatch(
        requestAllCrops({
          filter: {
            organizationId: state.organizations,
            siteId: state.sites,
            produceId: state.produces,
            varietyId: state.varieties,
            status: state.statuses
          }
        })
      )
    }
  }, [requestDownload])

  useEffect(() => {
    if (requestDownload && allItems.length > 0) {
      const headers = DOWNLOAD_HEADERS()
      const data = [headers]
      for (const item of allItems) {
        const values = DOWNLOAD_VALUES(item, organizations, zonesHierarchy)
        data.push(values)
      }
      const csv = unparse({ data })
      generateDownloadCsvLink(csv, 'crops.csv')
      dispatch(clearAllCrops())
      setRequestDownload(false)
    }
  }, [allItems])

  const fetchCrops = page => {
    if (
      state.organizations.length > 0 &&
      state.sites.length > 0 &&
      state.produces.length > 0 &&
      state.varieties.length > 0 &&
      state.statuses.length > 0
    ) {
      dispatch(
        requestCrops({
          filter: {
            organizationId: state.organizations,
            siteId: state.sites,
            produceId: state.produces,
            varietyId: state.varieties,
            status: state.statuses
          },
          limit: pageSize,
          offset: page - 1
        })
      )
    }
  }

  const changePage = newPage => {
    dispatchState({
      page: newPage
    })
  }

  const onCreate = () => {
    history.push(`${modulePath}/create`)
  }

  const onEdit = id => {
    history.push(`${modulePath}/update/${id}`)
  }

  const onDataboard = () => {
    history.push(`${modulePath}/data`)
  }

  const onDownload = () => {
    setRequestDownload(true)
  }

  return (
    <Flex axisGap={400} direction='column'>
      <HeaderV2
        title={strings.manageCrops}
        buttonText={strings.createCrop}
        buttonCallback={onCreate}
        showButton={canEdit}
        breakpointWidth={1024}
      >
        <TableFilter state={state} dispatchState={dispatchState} />
        <FlexV2 direction={isAboveBreakpoint ? 'row' : 'column'} axisGap={300}>
          <Button
            size='small'
            iconBefore={'download'}
            variant='info'
            name={'downloadButton'}
            onClick={onDownload}
            disabled={totalItems === 0}
          >
            {strings.download}
          </Button>
          <Button size='small' name='databoardButton' onClick={onDataboard}>
            {strings.queryData}
          </Button>
        </FlexV2>
      </HeaderV2>
      <Loader isLoading={loading}>
        {totalItems === 0 && (
          <Flex className={'Operations__Table__Empty'}>
            <Text>{strings.noCrops}</Text>
          </Flex>
        )}
        {totalItems > 0 && (
          <Table className='Operations__Table' aria-colcount='4'>
            <Slot name='head'>
              <Row>
                <Column>{strings.location}</Column>
                <Column>{strings.produce}</Column>
                <Column>{strings.volume}</Column>
                <Column>{strings.status}</Column>
                <Column />
              </Row>
            </Slot>
            <Slot name='body'>
              {items?.map(item => (
                <TableRow
                  key={item.id}
                  onEdit={onEdit}
                  canEdit={canEdit}
                  item={item}
                />
              ))}
            </Slot>
          </Table>
        )}
        {totalItems > pageSize && (
          <Box className='pagination-menu'>
            <Pagination
              type='simple'
              totalItems={totalItems}
              totalPages={totalPages}
              setTotalPages={setTotalPages}
              page={tableState.page}
              setPage={changePage}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          </Box>
        )}
      </Loader>
    </Flex>
  )
}

export default CropTable

import { Link } from 'react-router-dom'
import { I18n } from 'aws-amplify'

import { FlexV2, Icon, Text } from '@/primitives'
import ZoneUtils from '@/Util/ZoneUtils'

import { useDashboard } from '../../context'
import { STATUS_SYMBOL_COLOR } from '../utils'
import style from '../Card.module.scss'

const MeasurementThreshold = ({ measurement }) => {
  return (
    <FlexV2 axisGap={200} alignCrossAxis='center'>
      <Icon
        name='warning'
        size={300}
        style={{ flexShrink: 0 }}
        variant={
          STATUS_SYMBOL_COLOR?.[measurement.statusInfo.status.level] ??
          'primary'
        }
        tone={500}
      />
      <Text>{measurement.measurementName}</Text>
    </FlexV2>
  )
}

export default function SensorZoneCard({ path, item }) {
  const { state } = useDashboard()

  const displayNames = ZoneUtils.getZoneHierarchyDiplayNameMapByPath(
    state.hierarchy,
    path
  )

  const parts = path.split('/')
  let displayPath = ''
  for (let part of parts) {
    if (part === '') continue
    displayPath += displayNames[part] + '/'
  }
  displayPath = displayPath.slice(0, -1)

  let measurements = Object.values(item)

  measurements.sort((a, b) => {
    if (a.measurementName < b.measurementName) {
      return -1
    }
    if (a.measurementName > b.measurementName) {
      return 1
    }
    return 0
  })

  return (
    <FlexV2 direction='column' className={style.card}>
      <Text>
        {I18n.get('Alert in')}
        <Link to={`/zones${path}`}>{displayPath}</Link>
      </Text>
      <FlexV2 direction='column' className={style.listWithIcons} axisGap={100}>
        {measurements.map(measurement => {
          return (
            <MeasurementThreshold
              measurement={measurement}
              key={measurement.measurementId}
            />
          )
        })}
      </FlexV2>
    </FlexV2>
  )
}

import { I18n } from 'aws-amplify'

const Strings = () => ({
  manageHarvests: I18n.get('Manage harvests'),
  createHarvest: I18n.get('Create harvest'),
  harvest: I18n.get('Harvest'),
  plans: I18n.get('Plans'),
  createFormHeader: I18n.get('Create New Harvest'),
  updateFormHeader: I18n.get('Update Existing Harvest'),
  saveHarvest: I18n.get('Save Harvest'),
  harvestDate: I18n.get('Harvest Date'),
  organization: I18n.get('Organization'),
  location: I18n.get('Location'),
  site: I18n.get('Site'),
  zone: I18n.get('Zone'),
  yield: I18n.get('Yield'),
  produce: I18n.get('Produce'),
  varieties: I18n.get('Varieties'),
  variety: I18n.get('Variety'),
  storageFacility: I18n.get('Storage Facility'),
  weights: I18n.get('Weights'),
  weight: I18n.get('Weight'),
  grade: I18n.get('Grade'),
  totalWeight: I18n.get('Total Weight'),
  totalWeightKg: I18n.get('Total Weight (kg)'),
  addWeight: I18n.get('Add Weight'),
  photos: I18n.get('Photos'),
  selectDefault: I18n.get('Please Select'),
  kg: I18n.get('kg'),
  noHarvests: I18n.get('No harvests'),
  areYouSure: I18n.get('Are you sure?'),
  clickContinue: I18n.get('Click continue to confirm.'),
  deleteHarvestWarning: I18n.get('Deleting a harvest cannot be undone.'),
  cancel: I18n.get('Cancel'),
  continue: I18n.get('Continue'),
  createdAt: I18n.get('Date Created'),
  updatedAt: I18n.get('Date Updated'),
  download: I18n.get('Download'),
  queryData: I18n.get('Query data')
})

export default Strings

export const SET_STATE = 'RETURN_SET_STATE'
export const UPDATE_INPUT = 'RETURN_UPDATE_INPUT'
export const UPDATE_RETURN_ITEM = 'UPDATE_RETURN_ITEM'
export const REMOVE_RETURN_ITEM = 'DELETE_RETURN_ITEM'

export const initialState = {
  id: null,
  organizationId: '',
  returnDate: null,
  referenceNumber: '',
  customerId: '',
  branchId: '',
  returnItems: []
}

const fixDate = value => {
  if (value) {
    return new Date(value)
  }
  return null
}

export function reducer(state, action) {
  const handlers = {
    [SET_STATE]: (state, action) => {
      let newState = {
        ...initialState,
        ...action.state
      }
      newState.returnDate = fixDate(newState.returnDate)
      return newState
    },
    [UPDATE_INPUT]: (state, action) => ({
      ...state,
      [action.name]: action.value
    }),
    [REMOVE_RETURN_ITEM]: (state, action) => {
      let returnItems = state.returnItems
      const returnItemIndex = returnItems.findIndex(
        returnItem => returnItem.id === action.returnItem.id
      )
      if (returnItemIndex >= 0) {
        returnItems.splice(returnItemIndex, 1)
      }
      return {
        ...state,
        returnItems: returnItems
      }
    },
    [UPDATE_RETURN_ITEM]: (state, action) => {
      let returnItems = state.returnItems
      const returnItemIndex = returnItems.findIndex(
        returnItem => returnItem.id === action.returnItem.id
      )
      if (returnItemIndex >= 0) {
        returnItems[returnItemIndex] = action.returnItem
      } else {
        returnItems.push(action.returnItem)
      }
      return {
        ...state,
        returnItems: returnItems
      }
    }
  }

  if (handlers[action.type]) {
    return handlers[action.type](state, action)
  }

  return state
}

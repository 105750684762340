import { Button, Cell, Row } from '../../../../../primitives'

import { BRANCH_STATUS_TYPE } from '../utils'

import Strings from '../Strings'

function TableRow({ item, onEdit, canEdit }) {
  const strings = Strings()

  const statusTypes = BRANCH_STATUS_TYPE(strings)

  const {
    id,
    name,
    contactName,
    contactEmail,
    country,
    city,
    area,
    region,
    customer,
    status
  } = item

  const handleEdit = () => {
    onEdit(id)
  }

  return (
    <Row>
      <Cell columnName={strings.company}>
        {name}
        <span>{customer.name}</span>
      </Cell>
      <Cell columnName={strings.contact}>
        {contactName}
        <span>{contactEmail}</span>
      </Cell>
      <Cell columnName={strings.location}>
        {city.name} {area?.name ? `(${area.name})` : null}
        <span>
          {region.name} ({country.name})
        </span>
      </Cell>
      <Cell columnName={strings.status}>{statusTypes[status]}</Cell>
      <Cell className='Operations__Table__Column__Actions'>
        {canEdit && (
          <Button
            variant='info'
            size='small'
            iconBefore='edit'
            onClick={handleEdit}
          />
        )}
      </Cell>
    </Row>
  )
}

export default TableRow

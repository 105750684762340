import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { useDispatch } from 'react-redux'

import useMediaQuery from '@/hooks/useMediaQuery'

import { Flex, Logo, Button } from '@/primitives'

import { subscribeUser } from '../../../subscription'

import { UserControls } from './Assets'

import {
  getNavigationPanelPrimaryOpen,
  getNavigationPanelLocationSelectorOpen
} from '@/reducers/selectors'

import {
  togglePanelPrimaryLock,
  togglePanelPrimaryOpen,
  togglePanelLocationSelectorOpen
} from '@/slices/navigation'

import { useAuth } from '@/contexts/auth-context'

import './index.scss'

const Header = () => {
  const auth = useAuth()

  const dispatch = useDispatch()

  const navigationPanelPrimaryOpen = getNavigationPanelPrimaryOpen()

  const displayWordmark = useMediaQuery('min-width: 460px')
  const isSmallDesktop = useMediaQuery('min-width: 1440px')

  const navigationPanelLocationSelectorOpen =
    getNavigationPanelLocationSelectorOpen()

  const username = auth.getCognitoUsername()
  const idToken = auth.getCognitoIdToken()

  async function handleMenuClick() {
    dispatch(togglePanelPrimaryOpen())

    if (isSmallDesktop) {
      dispatch(togglePanelPrimaryLock())
    }
    if (navigationPanelLocationSelectorOpen) {
      dispatch(togglePanelLocationSelectorOpen())
    }

    const user = await Auth.currentAuthenticatedUser()
    await Auth.updateUserAttributes(user, {
      'custom:open_sidebar': !navigationPanelPrimaryOpen ? 'yes' : 'no'
    })
  }

  /* Notification Subscriber */
  useEffect(() => {
    if (idToken) {
      subscribeUser(idToken, username)
    }
  }, [username, idToken])

  return (
    <Flex className='Header' as='header' direction='column'>
      <Flex
        alignMainAxis='space-between'
        alignCrossAxis={'center'}
        className={'Header__Menu'}
      >
        <Flex alignCrossAxis='center' axisGap={100}>
          <Link to={'/'} className={'LogoLink'} role='button'>
            <Logo size={400} context={displayWordmark ? 'logoSecondary' : 'logoMark'} />
          </Link>
          {username && (
            <Button
              iconBefore={`menu ${navigationPanelPrimaryOpen && 'open'}`.trim()}
              variant='text'
              onClick={handleMenuClick}
              className={'Nav__Trigger'}
            />
          )}
        </Flex>
        <UserControls />
      </Flex>
    </Flex>
  )
}

export default Header

import { useState, useEffect, useCallback, Fragment } from 'react'
import { I18n } from 'aws-amplify'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { string, object } from 'yup'

import { Button, Flex, Input, Label, Text, Form, Slot } from '@/primitives'

import { Dialog } from '@/elements'

import { showBanner } from '@/slices/util'

import {
  getCurrentUser,
  getOrganization,
  getOrganizationsLoading,
  getUpdatedOrganization
} from '@/reducers/selectors'

import {
  sendCreateOrganization,
  sendUpdateOrganization,
  fetchOrganization,
  setOrganization
} from '@/slices/management/organization'

import {
  RESOURCE_TYPE_ORGANIZATION,
  hasReadPermissions
} from '@/Util/PermissionUtils'

import Strings from '../Strings'
import LogoUpsertField from './LogoUpsertField'

const organizationSchema = object().shape({
  name: string().trim().required(I18n.get('Please enter an organization name'))
})

const UpsertOrganizationForm = () => {
  const [name, setName] = useState('')
  const [selectedOrg, setSelectedOrg] = useState({})

  let { organizationId } = useParams()

  const history = useHistory()
  const dispatch = useDispatch()

  const coretexUser = getCurrentUser()
  const organization = getOrganization()
  const updatedOrganization = getUpdatedOrganization()
  const isLoading = getOrganizationsLoading()

  const showEditOptions = hasReadPermissions(
    coretexUser,
    RESOURCE_TYPE_ORGANIZATION
  )

  const strings = Strings()

  const {
    orgNameColumn,
    orgAddFormTitle,
    orgSave,
    orgEditFormTitle,
    orgIdColumn
  } = strings

  const isEditView = useCallback(
    () => history?.location?.pathname?.includes('edit'),
    [history?.location?.pathname]
  )

  const onClickClose = () => history.push('/admin/organizations')

  const onChangeInput = ({ target }) => {
    setName(target.value)
  }

  const onSubmitNewOrg = async e => {
    e.preventDefault()

    try {
      const response = await organizationSchema.validate(
        { name },
        { abortEarly: true }
      )
      dispatch(sendCreateOrganization({ name: response.name }))
    } catch (err) {
      dispatch(showBanner({ show: true, message: err.message, type: 'error' }))
    }
  }

  const onSubmitEditOrg = async e => {
    e.preventDefault()

    try {
      const response = await organizationSchema.validate(
        { name },
        { abortEarly: true }
      )
      dispatch(
        sendUpdateOrganization({
          payload: {
            name: response.name,
            id: organization.id
          },
          selectedOrg
        })
      )
    } catch (err) {
      dispatch(showBanner({ show: true, message: err.message, type: 'error' }))
    }
  }

  useEffect(() => {
    if (updatedOrganization?.id) {
      history.push('/admin/organizations', {
        action: isEditView() ? 'update' : 'create'
      })
    }
  }, [updatedOrganization?.id, history, isEditView])

  useEffect(() => {
    return () => dispatch(setOrganization({}))
  }, [dispatch])

  useEffect(() => {
    if (isEditView() && organizationId !== organization?.id && !isLoading) {
      dispatch(fetchOrganization({ organizationId }))
    }
  }, [dispatch, organizationId, isEditView, organization?.id, isLoading])

  useEffect(() => {
    if (organizationId === organization?.id) {
      setSelectedOrg(organization)
      setName(organization?.name)
    }
  }, [organization, organizationId])

  return (
    <Dialog type='modal' open={true} onOpenChange={onClickClose}>
      <Slot name='title'>
        <Text size='300' fontWeight={700}>
          {isEditView() ? orgEditFormTitle : orgAddFormTitle}
        </Text>
      </Slot>
      <Slot name='content'>
        <Form onSubmit={isEditView() ? onSubmitEditOrg : onSubmitNewOrg}>
          <Flex axisGap={200} direction='column' alignMainAxis='center'>
            {isEditView() && (
              <Label>
                <Text variant='page' tone={700}>
                  {orgIdColumn}
                </Text>
                <Input value={selectedOrg.id} disabled />
              </Label>
            )}
            <Fragment>
              <Label>
                <Text variant='page' tone={700}>
                  {orgNameColumn}
                </Text>
                <Input
                  required
                  value={name}
                  onChange={onChangeInput}
                  disabled={!showEditOptions}
                />
              </Label>
              <Text variant='page' tone={700} fontWeight={600} style={{ padding: '1.5rem 0 0.8rem'}}>
                {'Customizations'}
              </Text>
              <LogoUpsertField orgId={selectedOrg?.id} imgName='primary_logo'/>
              <LogoUpsertField orgId={selectedOrg?.id} imgName='secondary_logo'/>
              <LogoUpsertField orgId={selectedOrg?.id} imgName='logomark'/>
            </Fragment>
          </Flex>
          {showEditOptions && (
            <Flex alignMainAxis='flex-end'>
              <Button type='submit' variant='primary'>
                {orgSave}
              </Button>
            </Flex>
          )}
        </Form>
      </Slot>
    </Dialog>
  )
}

export default UpsertOrganizationForm

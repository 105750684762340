import { DateTime } from 'luxon'
import { useEffect, useState, Fragment } from 'react'
import { useDispatch } from 'react-redux'
import DatePicker from 'react-datepicker'

import useDeviceSize from '@/hooks/useDeviceSize'

import { requestAllProduces } from '@/actions/operations/produce'
import { requestAllVarieties } from '@/actions/operations/variety'
import { requestHarvests } from '@/actions/operations/harvest'
import { requestCustomers } from '@/actions/operations/customer'

import {
  getZoneHierarchy,
  getOperationsAllProduces,
  getOperationsAllVarieties,
  getOperationsHarvests,
  getOperationsCustomers,
  getOperationsLoading
} from '@/reducers/selectors'

import { Flex, Loader, Box, Text, Label, Select, Separator } from '@/primitives'

import InputError from '@/elements/InputError'
import HeaderV2 from '@/components/Operations/Shared/HeaderV2'
import OrganizationInput from '../../Shared/Organization/Input'
import ZoneNavigator from '../../Shared/ZoneNavigator'

import { getValidationErrorMap } from '@/Util/GeneralUtils'
import { FIELDS, SCHEMA } from './config'

import Strings from './Strings'

// const MODULE_PATH = '/operations/:zone+/sales/traceability'

const initialFormState = {
  organizationId: '',
  siteId: '',
  zoneId: '',
  produceId: '',
  varietyId: '',
  harvestId: '',
  packageDate: '',
  customerId: ''
}

function Traceability({ backPath }) {
  const dispatch = useDispatch()
  const isLaptop = useDeviceSize()
  const isMobile = useDeviceSize('mobile')

  const strings = Strings()

  const zoneHierarchy = getZoneHierarchy()
  const produces = getOperationsAllProduces()
  const varieties = getOperationsAllVarieties()
  const harvests = getOperationsHarvests()
  const customers = getOperationsCustomers()
  const loading = getOperationsLoading()

  const [formState, setFormState] = useState(initialFormState)
  const [traceabilityCode, setTraceabilityCode] = useState(null)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    dispatch(requestAllProduces())
    dispatch(requestAllVarieties())
  }, [dispatch])

  useEffect(() => {
    if (formState.organizationId.length > 0 && formState.siteId.length > 0) {
      dispatch(
        requestHarvests({
          filter: {
            organizationId: [formState.organizationId],
            siteId: [formState.siteId]
          }
        })
      )
    }
  }, [formState.organizationId, formState.siteId])

  useEffect(() => {
    if (formState.organizationId.length > 0) {
      dispatch(
        requestCustomers({
          filter: {
            organizationId: [formState.organizationId]
          }
        })
      )
    }
  }, [formState.organizationId])

  useEffect(() => {
    setTraceabilityCode(null)
  }, [formState])

  const getSiteCode = () => {
    return zoneHierarchy[formState.siteId].code
  }

  const getZoneCode = () => {
    return zoneHierarchy[formState.siteId].children[formState.zoneId].code
  }

  const getProduceCode = () => {
    for (let produce of produces) {
      if (produce.id === formState.produceId) {
        return produce.code
      }
    }
    return null
  }

  const getVarietyCode = () => {
    for (let variety of varieties) {
      if (variety.id === formState.varietyId) {
        return variety.code
      }
    }
    return null
  }

  const getHarvestCode = () => {
    for (let harvest of harvests) {
      if (harvest.id === formState.harvestId) {
        return DateTime.fromMillis(harvest.harvestDate).toFormat('LLdd')
      }
    }
    return null
  }

  const getPackageCode = () => {
    if (formState.packageDate) {
      return DateTime.fromJSDate(formState.packageDate).toFormat('LLdd')
    }
    return null
  }

  const getCustomerCode = () => {
    for (let customer of customers) {
      if (customer.id === formState.customerId) {
        return customer.code
      }
    }
    return null
  }

  const onGenerate = async () => {
    try {
      await SCHEMA().validate(formState, { abortEarly: false })
      const code = [
        getSiteCode(),
        getZoneCode(),
        getProduceCode(),
        getVarietyCode(),
        getHarvestCode(),
        getPackageCode(),
        getCustomerCode()
      ]
      setTraceabilityCode(code.join(''))
      setErrors({})
    } catch (error) {
      setErrors(getValidationErrorMap(error))
    }
  }

  const handleInput = e => {
    const { name, value } = e.currentTarget
    setFormState({
      ...formState,
      [name]: value
    })
  }

  const handleDateInput = (date, name) => {
    setFormState({
      ...formState,
      [name]: date
    })
  }

  const handleOrganizationInput = organizationId => {
    setFormState({
      ...initialFormState,
      organizationId,
      siteId: '',
      zoneId: '',
      produceId: '',
      varietyId: '',
      harvestId: '',
      customerId: ''
    })
  }

  const handleZoneInput = ({ id, parentId }) => {
    if (parentId) {
      setFormState({
        ...formState,
        zoneId: id,
        produceId: '',
        varietyId: '',
        harvestId: ''
      })
    } else {
      setFormState({
        ...formState,
        siteId: id,
        produceId: '',
        varietyId: '',
        harvestId: ''
      })
    }
  }

  const handleProduceInput = e => {
    setFormState({
      ...formState,
      produceId: e.currentTarget.value,
      varietyId: '',
      harvestId: ''
    })
  }

  const handleVarietyInput = e => {
    setFormState({
      ...formState,
      varietyId: e.currentTarget.value,
      harvestId: ''
    })
  }

  const formatDate = date => {
    return DateTime.fromMillis(date).toFormat('DDDD')
  }

  const filterProduces = () => {
    return produces.filter(produce => {
      for (let harvest of harvests) {
        if (harvest.produce.id === produce.id) {
          return true
        }
      }
      return false
    })
  }

  const filterVarieties = () => {
    return varieties.filter(variety => {
      for (let harvest of harvests) {
        if (
          harvest.produce.id === formState.produceId &&
          harvest.variety.id === variety.id
        ) {
          return true
        }
      }
      return false
    })
  }

  const filterHarvests = () => {
    return harvests.filter(
      harvest =>
        harvest.produce.id === formState.produceId &&
        harvest.variety.id === formState.varietyId
    )
  }

  return (
    <Flex
      as='form'
      axisGap={400}
      direction='column'
      className='Operations__Form'
    >
      <HeaderV2
        title={strings.traceability}
        backPath={backPath}
        buttonIcon={'save'}
        buttonText={strings.generateCode}
        buttonCallback={onGenerate}
      />
      <Loader isLoading={loading}>
        <Flex axisGap={800} direction={isLaptop ? 'column' : null}>
          <Flex
            axisGap={400}
            direction='column'
            className='Operations__Form__Fields'
          >
            {traceabilityCode && (
              <Fragment>
                <Box>
                  <Flex
                    axisGap={300}
                    className='Operations__Form__Fields'
                    alignMainAxis='space-between'
                    direction={isMobile ? 'column' : 'row'}
                    style={{ lineHeight: '2em' }}
                  >
                    <span style={{ fontSize: '1.5em' }}>{strings.batch}</span>
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontSize: '2em'
                      }}
                    >
                      {traceabilityCode}
                    </span>
                  </Flex>
                </Box>
                <Separator className='Separator__Line' />
              </Fragment>
            )}
            <Box>
              <OrganizationInput
                fieldName={FIELDS.organizationId}
                organizationId={formState.organizationId}
                handleInput={handleOrganizationInput}
                errors={errors}
              />
              <Flex
                axisGap={300}
                className='Operations__Form__Fields'
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label>
                  <Text variant='page' tone={700}>
                    {strings.zone}
                  </Text>
                  <ZoneNavigator
                    organizationId={formState.organizationId}
                    siteId={formState.siteId}
                    zoneId={formState.zoneId}
                    onSelectZone={handleZoneInput}
                  />
                  <InputError error={errors?.zoneId} />
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                className='Operations__Form__Fields'
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.produce}
                    </Text>
                    <Select
                      className='Operations__Form__Select'
                      name={FIELDS.produceId}
                      value={formState.produceId}
                      onChange={handleProduceInput}
                    >
                      <option default value=''>
                        {strings.selectDefault}
                      </option>
                      {filterProduces().map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </Select>
                    <InputError error={errors?.produceId} />
                  </Flex>
                </Label>
                <Label>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.variety}
                    </Text>
                    <Select
                      className='Operations__Form__Select'
                      name={FIELDS.varietyId}
                      value={formState.varietyId}
                      onChange={handleVarietyInput}
                    >
                      <option default value=''>
                        {strings.selectDefault}
                      </option>
                      {filterVarieties().map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </Select>
                    <InputError error={errors?.varietyId} />
                  </Flex>
                </Label>
                <Label>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.harvestDate}
                    </Text>
                    <Select
                      className='Operations__Select'
                      name={FIELDS.harvestId}
                      value={formState.harvestId}
                      onChange={handleInput}
                    >
                      <option default value=''>
                        {strings.selectDefault}
                      </option>
                      {filterHarvests().map(({ id, harvestDate }) => (
                        <option key={id} value={id}>
                          {formatDate(harvestDate)}
                        </option>
                      ))}
                    </Select>
                    <InputError error={errors?.harvestId} />
                  </Flex>
                </Label>
              </Flex>
              <Flex
                axisGap={300}
                className='Operations__Form__Fields'
                alignMainAxis='space-evenly'
                direction={isMobile ? 'column' : 'row'}
              >
                <Label>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.packageDate}
                    </Text>
                    <DatePicker
                      maxDate={new Date()}
                      selected={formState.packageDate}
                      className='Operations__Form__Select'
                      onChange={date =>
                        handleDateInput(date, FIELDS.packageDate)
                      }
                    />
                    <InputError error={errors?.packageDate} />
                  </Flex>
                </Label>
                <Label>
                  <Flex axisGap={300} direction='column'>
                    <Text variant='page' tone={700}>
                      {strings.customer}
                    </Text>
                    <Select
                      className='Operations__Select'
                      name={FIELDS.customerId}
                      value={formState.customerId}
                      onChange={handleInput}
                    >
                      <option default value=''>
                        {strings.selectDefault}
                      </option>
                      {customers.map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </Select>
                    <InputError error={errors?.customerId} />
                  </Flex>
                </Label>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Loader>
    </Flex>
  )
}

export default Traceability

import { updateList } from '../util'

import {
  RECEIVE_ALL_COUNTRIES,
  REQUEST_ALL_COUNTRIES,
  RECEIVE_COUNTRIES,
  REQUEST_COUNTRIES,
  CLEAR_COUNTRIES,
  CLEAR_COUNTRY,
  REQUEST_COUNTRY,
  RECEIVE_COUNTRY,
  CREATE_COUNTRY,
  COUNTRY_CREATED,
  UPDATE_COUNTRY,
  COUNTRY_UPDATED,
  SET_COUNTRY
} from '@/actions/operations/country'

export const countryInitialState = {
  allCountries: [],
  countries: [],
  country: {},
  countriesCount: 0,
  loading: false,
  error: null
}

const handlers = {
  [SET_COUNTRY]: (state, action) => ({
    ...state,
    country: action.country
  }),
  [CLEAR_COUNTRY]: state => ({
    ...state,
    country: {},
    error: null
  }),
  [CLEAR_COUNTRIES]: state => ({
    ...state,
    countries: [],
    error: null
  }),
  [REQUEST_ALL_COUNTRIES]: state => ({
    ...state,
    error: null,
    loading: true
  }),
  [RECEIVE_ALL_COUNTRIES]: (state, action) => ({
    ...state,
    allCountries: !action.error ? action.countries : [],
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_COUNTRIES]: state => ({
    ...state,
    countries: [],
    countriesCount: 0,
    error: null,
    loading: true
  }),
  [RECEIVE_COUNTRIES]: (state, action) => ({
    ...state,
    countries: !action.error ? action.countries : [],
    countriesCount: !action.error ? action.count : 0,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_COUNTRY]: state => ({
    ...state,
    country: {},
    error: null,
    loading: true
  }),
  [RECEIVE_COUNTRY]: (state, action) => ({
    ...state,
    country: !action.error ? action.country : state.country,
    error: action.error ?? null,
    loading: false
  }),
  [CREATE_COUNTRY]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [COUNTRY_CREATED]: (state, action) => ({
    ...state,
    country: !action.error ? {} : state.country,
    countries: updateList(action.error, state.countries, action.country, true),
    allCountries: updateList(
      action.error,
      state.allCountries,
      action.country,
      true
    ),
    countriesCount: !action.error
      ? state.countriesCount + 1
      : state.countriesCount,
    error: action.error ?? null,
    loading: false
  }),
  [UPDATE_COUNTRY]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [COUNTRY_UPDATED]: (state, action) => ({
    ...state,
    country: !action.error ? {} : state.country,
    countries: updateList(action.error, state.countries, action.country, false),
    allCountries: updateList(
      action.error,
      state.allCountries,
      action.country,
      false
    ),
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

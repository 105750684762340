import { useRef, useEffect } from 'react'
import { Box } from '../'

import './index.scss'

/**
 * Toggle Element
 * @param {Object} props
 * @param {String | String[]} [props.className] Extra class name(s)
 * @param {Boolean} [props.disabled = false] The toggle is disabled
 * @param {Boolean} [props.checked = false] The toggle is checked
 * @param {Boolean} [props.indeterminate = false] The toggle is partially checked (indeterminate state)
 * @param {String} [props.className] Name of input
 * @param {import('react').CSSProperties} [props.css]
 *
 * @example
 * ```jsx
 *  <Toggle
			checked={checked}
			disabled={disabled || !permissionId}
			indeterminate={indeterminate}
			onChange={onClickPermissionToggle}
		/>
 * ```
 */

const Toggle = ({
  className = '',
  disabled = false,
  checked = false,
  indeterminate = false,
  name = '',
  onChange
}) => {
  const checkboxRef = useRef(null)

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = indeterminate
    }
  }, [indeterminate])

  return (
    <Box
      as='input'
      name={name}
      className={className}
      type='checkbox'
      role='switch'
      ref={checkboxRef}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
  )
}

export default Toggle

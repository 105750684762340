import { Fragment } from 'react'

import OrganizationsFilter from '../../../Shared/Filters/OrganizationsFilter'
import StorageFacilitiesFilter from '../../../Shared/Filters/StorageFacilitiesFilter'
import FertilizersFilter from '../../../Shared/Filters/FertilizerFilter'

function FertilizerInventoryFilter({ state, dispatchState }) {
  return (
    <Fragment>
      <OrganizationsFilter state={state} dispatchState={dispatchState} />
      <StorageFacilitiesFilter state={state} dispatchState={dispatchState} />
      <FertilizersFilter state={state} dispatchState={dispatchState} />
    </Fragment>
  )
}

export default FertilizerInventoryFilter

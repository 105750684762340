import { I18n } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { requestAllCities } from '@/actions/operations/city'

import { getOperationsAllCities } from '@/reducers/selectors'

import { Select } from '@/primitives'

const CitiesFilter = ({ state, dispatchState }) => {
  const dispatch = useDispatch()

  const cities = getOperationsAllCities()

  const [cityFilter, setCityFilter] = useState(
    state.cities.length === 1 ? state.cities[0] : 'all'
  )
  const [lastFilter, setLastFilter] = useState(cityFilter)

  useEffect(() => {
    dispatch(requestAllCities())
  }, [dispatch])

  useEffect(() => {
    if (cityFilter === 'all') {
      if (state.cities.length === 0 && cities.length > 0) {
        dispatchChange()
      } else if (state.cities.length === 1 && cities.length > 0) {
        setLastFilter(state.cities[0])
        setCityFilter(state.cities[0])
      }
    } else if (state.cities.length === 0) {
      setCityFilter('all')
    }
  }, [state.cities, cities])

  useEffect(() => {
    if (cities.length > 0) {
      if (cityFilter !== lastFilter) {
        setLastFilter(cityFilter)
        dispatchChange()
      }
    }
  }, [cityFilter, cities])

  const getCityFilter = () => {
    return cityFilter === 'all' ? cities.map(i => i.id) : [cityFilter]
  }

  const dispatchChange = () => {
    dispatchState({
      cities: getCityFilter()
    })
  }

  const onChangeFilter = e => {
    setCityFilter(e.currentTarget.value)
  }

  const filterCities = () => {
    if (state.regions.length === 1) {
      return cities.filter(city => {
        for (let region of state.regions) {
          if (city.regionId === region) {
            return true
          }
        }
        return false
      })
    }
    return []
  }

  const options = filterCities()

  return (
    <Select
      className='Operations__Header__Select'
      name='cityId'
      value={cityFilter}
      onChange={onChangeFilter}
      disabled={options.length === 0}
    >
      <option default value='all'>
        {I18n.get('All Cities')}
      </option>
      {options.map(({ name, id }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </Select>
  )
}

export default CitiesFilter

import { Flex } from '../../../../../primitives'

import OrganizationsFilter from '../../../Shared/Filters/OrganizationsFilter'
import CustomersFilter from '../../../Shared/Filters/CustomersFilter'

const BranchFilter = ({ state, dispatchState }) => {
  return (
    <Flex direction='row' axisGap='300' style={{ whiteSpace: 'nowrap' }}>
      <OrganizationsFilter state={state} dispatchState={dispatchState} />
      <CustomersFilter state={state} dispatchState={dispatchState} />
    </Flex>
  )
}

export default BranchFilter

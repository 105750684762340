import { updateList } from '../util'

import {
  RECEIVE_INVENTORY_CALIBRATIONS,
  REQUEST_INVENTORY_CALIBRATIONS,
  CLEAR_INVENTORY_CALIBRATIONS,
  CREATE_INVENTORY_CALIBRATION,
  INVENTORY_CALIBRATION_CREATED
} from '@/actions/operations/inventoryCalibration'

export const inventoryCalibrationInitialState = {
  inventoryCalibrations: [],
  inventoryCalibrationsCount: 0,
  loading: false,
  error: null
}

const handlers = {
  [CLEAR_INVENTORY_CALIBRATIONS]: (state, action) => ({
    ...state,
    inventoryCalibrations: [],
    inventoryCalibrationsCount: 0,
    error: null
  }),
  [REQUEST_INVENTORY_CALIBRATIONS]: (state, action) => ({
    ...state,
    inventoryCalibrations: [],
    inventoryCalibrationsCount: 0,
    error: null,
    loading: true
  }),
  [RECEIVE_INVENTORY_CALIBRATIONS]: (state, action) => ({
    ...state,
    inventoryCalibrations: !action.error ? action.inventoryCalibrations : [],
    inventoryCalibrationsCount: !action.error ? action.count : 0,
    error: action.error ?? null,
    loading: false
  }),
  [CREATE_INVENTORY_CALIBRATION]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [INVENTORY_CALIBRATION_CREATED]: (state, action) => ({
    ...state,
    inventoryCalibration: !action.error ? {} : state.inventoryCalibration,
    inventoryCalibrations: updateList(
      action.error,
      state.inventoryCalibrations,
      action.inventoryCalibration,
      true
    ),
    inventoryCalibrationsCount: !action.error
      ? state.inventoryCalibrationsCount + 1
      : state.inventoryCalibrationsCount,
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

import { MarkerClusterer } from '@googlemaps/markerclusterer'

import { useMap } from '@vis.gl/react-google-maps'

import { useRef, useEffect } from 'react'

import ClusterRenderer from './Cluster/Renderer'
import Marker from './Marker'

import { MAX_ZOOM } from './utils'

let markers = []

const Sensors = ({
  devices,
  filteredDevices,
  currentDevice,
  setCurrentDevice,
  setCurrentCluster,
  maxZoom,
  setZoomingOut
}) => {
  const clustererRef = useRef()

  const map = useMap()

  useEffect(() => {
    markers = []
    return () => {
      markers = []
    }
  }, [])

  useEffect(() => {
    if (map) {
      if (!clustererRef.current) {
        clustererRef.current = new MarkerClusterer({
          map,
          onClusterClick: onClusterClick,
          algorithmOptions: { maxZoom: MAX_ZOOM },
          renderer: ClusterRenderer
        })
      }
    }
  }, [map])

  useEffect(() => {
    if (currentDevice) {
      const position = { lat: currentDevice.lat, lng: currentDevice.lng }
      map.setCenter(position)
      map.setZoom(maxZoom)
    } else if (filteredDevices.length > 0) {
      map.setZoom(0)
      const bounds = new window.google.maps.LatLngBounds()
      for (let device of filteredDevices) {
        bounds.extend({ lat: device.lat, lng: device.lng })
      }
      map.fitBounds(bounds)
    }
  }, [filteredDevices, currentDevice])

  const onClusterClick = (event, cluster) => {
    setZoomingOut(false)
    setCurrentCluster(cluster)
  }

  const addMarker = marker => {
    markers = markers.concat([marker])
    clustererRef.current?.clearMarkers()
    clustererRef.current?.addMarkers(markers)
  }

  return devices.map(device => (
    <Marker
      key={device.id}
      device={device}
      setDevice={setCurrentDevice}
      addMarker={addMarker}
    />
  ))
}

export default Sensors

import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'
import { I18n } from 'aws-amplify'

import FeatureFlags from '@/components/FeatureFlags'
import { LineSeparator, Pagination, SearchBarWithDebounce } from '@/elements'
import UserActions from './UserActions'

import {
  Box,
  Button,
  Cell,
  Column,
  FlexV2,
  Loader,
  Row,
  Select,
  Slot,
  Table,
  Text
} from '@/primitives'

import Strings from '../Strings'

import { fetchUsers, setUser } from '@/slices/management/user'
import { fetchRoles } from '@/slices/management/role'
import { showBanner } from '@/slices/util'

import {
  getUsers,
  getUsersLoading,
  getCurrentUser,
  getUserSliceError,
  getRoles,
  getUser,
  getUpdatedUser,
  getDeletedUser,
  getResendUser
} from '@/reducers/selectors'

import useMediaQuery from '@/hooks/useMediaQuery'

import { getIsGodMode } from '@/Util/PermissionUtils'

import {
  RESOURCE_TYPE_USER,
  RESOURCE_TYPE_USER_MANAGEMENT,
  RESOURCE_TYPE_USER_LOGS,
  hasEditPermissions,
  hasReadPermissions
} from '@/Util/PermissionUtils'

const statusStrings = {
  UNCONFIRMED: I18n.get('Unconfirmed'),
  CONFIRMED: I18n.get('Confirmed'),
  ARCHIVED: I18n.get('Archived'),
  COMPROMISED: I18n.get('Compromised'),
  UNKNOWN: I18n.get('Unknown'),
  RESET_REQUIRED: I18n.get('Reset required'),
  FORCE_CHANGE_PASSWORD: I18n.get('Invitation not used'),
  NO_STATUS: I18n.get('No invitation sent')
}

export default function UsersList({ location }) {
  const dispatch = useDispatch()

  const users = getUsers()
  const error = getUserSliceError()
  const strings = Strings()
  const isLoading = getUsersLoading()
  const coretexUser = getCurrentUser()
  const isGodMode = getIsGodMode(coretexUser)
  const roles = getRoles()
  const user = getUser()
  const updatedUser = getUpdatedUser()
  const deletedUser = getDeletedUser()
  const resendUser = getResendUser()

  const totalItems = users?.totalItems

  const [showLogsOption, setShowLogsOption] = useState(false)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalPages, setTotalPages] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [selectedRole, setSelectedRole] = useState(null)
  const [openFeatureFlagsSidebar, setOpenFeatureFlagsSidebar] = useState(false)
  const [userIdFeatureFlagsSelected, setUserIdFeatureFlagsSelected] =
    useState(null)

  const isAboveCustomBreakpoint = useMediaQuery('min-width: 620px')

  const showEditOptions = hasEditPermissions(
    coretexUser,
    RESOURCE_TYPE_USER,
    RESOURCE_TYPE_USER_MANAGEMENT
  )

  useEffect(() => {
    if (location?.state?.action === 'create' && user?.userName) {
      const successDetails = {
        show: true,
        message: strings.userCreated,
        type: 'success'
      }
      dispatch(showBanner(successDetails))
      dispatch(setUser({}))
    }

    if (location?.state?.action === 'update' && updatedUser?.userName) {
      const successDetails = {
        show: true,
        message: strings.userUpdated,
        type: 'success'
      }
      dispatch(showBanner(successDetails))
      dispatch(setUser({}))
    }

    if (location?.state?.action === 'delete' && deletedUser?.userName) {
      const successDetails = {
        show: true,
        message: strings.userDeleted,
        type: 'success'
      }
      dispatch(showBanner(successDetails))
      dispatch(setUser({}))
    }

    if (resendUser?.userName) {
      const successDetails = {
        show: true,
        message: strings.userInviteSent,
        type: 'success'
      }
      dispatch(showBanner(successDetails))
      dispatch(setUser({}))
    }
  }, [
    strings,
    location?.state?.action,
    dispatch,
    user,
    updatedUser,
    deletedUser,
    resendUser
  ])

  useEffect(() => {
    dispatch(fetchRoles())

    const hasLogsReadPermission = hasReadPermissions(
      coretexUser,
      RESOURCE_TYPE_USER,
      RESOURCE_TYPE_USER_LOGS
    )

    if (hasLogsReadPermission) setShowLogsOption(true)
  }, [dispatch, coretexUser])

  useEffect(() => {
    if (error) {
      const errorDetails = {
        show: true,
        message: error,
        type: 'error'
      }
      dispatch(showBanner(errorDetails))
    }
  }, [dispatch, error])

  useEffect(() => {
    dispatch(
      fetchUsers({
        page,
        pageSize,
        roleName: selectedRole,
        search: searchText
      })
    )
  }, [dispatch, page, pageSize, selectedRole, searchText])

  const onUpdateSearchText = useCallback(text => {
    setSearchText(text)
    setPage(1)
  }, [])

  function onSelectRole({ target }) {
    setSelectedRole(target.value === 'all' ? null : target.value)
  }

  function onOpenFeatureFlagSidebar(userId) {
    setOpenFeatureFlagsSidebar(true)
    setUserIdFeatureFlagsSelected(userId)
  }

  function onCloseFeatureFlagSidebar() {
    setOpenFeatureFlagsSidebar(false)
    setUserIdFeatureFlagsSelected(null)
  }

  return (
    <FlexV2 direction='column' className={'UsersList'}>
      <FlexV2
        className={'UsersList__Header'}
        alignCrossAxis='center'
        alignMainAxis='space-between'
      >
        <Text size='300' variant='page' tone='800' fontWeight='300'>
          {strings.userManagementTab}
        </Text>

        {showEditOptions && (
          <Button
            variant='primary'
            size='small'
            as={Link}
            to={{
              pathname: '/admin/user-management/add',
              state: { prevPath: location.pathname }
            }}
          >
            {strings.addUserButton}
          </Button>
        )}
      </FlexV2>

      <LineSeparator />

      <FlexV2 direction='column' axisGap={500}>
        <FlexV2
          axisGap={500}
          direction={isAboveCustomBreakpoint ? 'row' : 'column'}
          alignCrossAxis={isAboveCustomBreakpoint ? 'center' : null}
          alignMainAxis='space-between'
        >
          <FlexV2 axisGap={400} alignCrossAxis='center' css={{ flexGrow: 1 }}>
            <SearchBarWithDebounce
              isLoading={isLoading}
              onUpdateSearchText={onUpdateSearchText}
              placeholderText={strings.searchByUserName}
            />
          </FlexV2>
          <FlexV2 axisGap={400} alignCrossAxis='center'>
            <Select
              required
              value={selectedRole}
              onChange={onSelectRole}
              disabled={isLoading}
            >
              <option value={'all'}>{strings.selectAllRoles}</option>
              {roles.map(({ name }) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </Select>
          </FlexV2>
        </FlexV2>

        <Loader isLoading={isLoading} text={strings.loadingUsers}>
          <Table className='UsersList__Table'>
            <Slot name='head'>
              <Row>
                <Column />
                <Column>{strings.tableUserColumn}</Column>
                <Column>{strings.tableRoleColumn}</Column>
                <Column>{strings.tableUsernameColumn}</Column>
                <Column>{strings.tableRegistrationColumn}</Column>
                <Column>{strings.tableStatusColumn}</Column>
                <Column>{strings.tableActionsColumn}</Column>
              </Row>
            </Slot>

            <Slot name='body'>
              {!isLoading && users?.result?.length === 0 && (
                <Row>
                  <Cell colSpan='6'> {strings.noUsersFound}</Cell>
                </Row>
              )}
              {users?.result?.length > 0 &&
                users.result.map(user => {
                  const {
                    firstName,
                    lastName,
                    email,
                    userName,
                    role,
                    createdTimestamp,
                    cognitoStatus
                  } = user
                  return (
                    <Row key={userName}>
                      <Cell>
                        <Box className={'UsersList__TextAvatar'}>
                          {firstName.charAt(0).toUpperCase() +
                            lastName.charAt(0).toUpperCase() ||
                            userName.charAt(0).toUpperCase()}
                        </Box>
                      </Cell>

                      <Cell columnName={`${strings.tableUserColumn}: `}>
                        {`${firstName}  ${lastName}`}
                        <br />
                        <Text variant={'page'} tone={700} size={200}>
                          {email}
                        </Text>
                      </Cell>

                      <Cell columnName={`${strings.tableRoleColumn}: `}>
                        {role}
                      </Cell>
                      <Cell columnName={`${strings.tableUsernameColumn}: `}>
                        {userName}
                      </Cell>
                      <Cell columnName={`${strings.tableRegistrationColumn}: `}>
                        {DateTime.fromSeconds(
                          createdTimestamp
                        ).toLocaleString()}
                      </Cell>
                      <Cell columnName={`${strings.tableStatusColumn}: `}>
                        {statusStrings[cognitoStatus] ??
                          statusStrings.NO_STATUS}
                      </Cell>
                      <Cell>
                        <UserActions
                          user={user}
                          showEditOptions={showEditOptions}
                          showLogsOption={showLogsOption}
                          onOpenFeatureFlagSidebar={onOpenFeatureFlagSidebar}
                        />
                      </Cell>
                    </Row>
                  )
                })}
            </Slot>
          </Table>
        </Loader>
        {isGodMode && openFeatureFlagsSidebar && (
          <FeatureFlags
            type={'user'}
            objectId={userIdFeatureFlagsSelected}
            openSidebar={openFeatureFlagsSidebar}
            onCloseSidebar={onCloseFeatureFlagSidebar}
          />
        )}
      </FlexV2>
      {totalItems > 0 && (
        <Box className='pagination-menu'>
          <Pagination
            type={isAboveCustomBreakpoint ? 'simple' : 'compact'}
            showPageCount={isAboveCustomBreakpoint}
            totalItems={totalItems}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        </Box>
      )}
    </FlexV2>
  )
}

import { Fragment } from 'react'
import { isEmpty } from '@aws-amplify/core'
import { I18n } from 'aws-amplify'
import classNames from 'classnames'

import { Box, Flex, Icon, Label, Text } from '@/primitives'

import { getZoneSubNameV2 } from '@/Util/AdminUtils'
import ZoneUtils from '@/Util/ZoneUtils'
import useOrgLabel from '@/hooks/useOrgLabel'

import './ZoneMover.scss'

function HierarchyOption({
  hierarchyNode,
  onSelectZone,
  selectedZoneId,
  state
}) {
  const orgLabels = useOrgLabel(['site', 'facility', 'room', 'zone', 'subzone'])
  const hasChlidren = Object.keys(hierarchyNode?.children || {}).length > 0
  const isDisabled =
    state.id === hierarchyNode.id ||
    hierarchyNode.id === state.parentId ||
    hierarchyNode.parentPath.includes(state.id)

  const handleOnSelectZone = e => {
    e.preventDefault()
    if (!isDisabled) {
      onSelectZone(hierarchyNode)
    }
  }
  return (
    <Flex
      key={hierarchyNode.id}
      axisGap={300}
      onClick={handleOnSelectZone}
      className={classNames(
        'HierarchyOption',
        isDisabled && 'unselectable',
        selectedZoneId === hierarchyNode.id && 'selected-zone'
      )}
      disabled={isDisabled}
    >
      <Box style={{ width: (hierarchyNode.level - 1) * 20 }}></Box>
      <Icon
        name={hasChlidren > 0 ? 'subdirectory_arrow_right ' : 'arrow_right_alt'}
      />
      <Text>{hierarchyNode.name}</Text> (
      {getZoneSubNameV2(hierarchyNode.level - 1, orgLabels)})
      <Text>
        {state.id === hierarchyNode.id &&
          ' - ' + I18n.get('Selected zone to move')}
      </Text>
    </Flex>
  )
}

function HierarchyOptions({ hierarchy, onSelectZone, selectedZoneId, state }) {
  if (!hierarchy || isEmpty(hierarchy)) {
    return null
  }

  return (
    <Fragment>
      <HierarchyOption
        state={state}
        hierarchyNode={hierarchy}
        onSelectZone={onSelectZone}
        selectedZoneId={selectedZoneId}
      />
      {Object.keys(hierarchy.children).map(childId => (
        <HierarchyOptions
          key={childId}
          state={state}
          hierarchy={hierarchy.children[childId]}
          onSelectZone={onSelectZone}
          selectedZoneId={selectedZoneId}
        />
      ))}
    </Fragment>
  )
}

export default function ZoneMover({
  state,
  selectedZone,
  setSelectedZone,
  siteHierarchy
}) {
  const orgLabels = useOrgLabel(['site', 'facility', 'room', 'zone', 'subzone'])
  const stateDepth = ZoneUtils.getZoneHierarchyDepthByPath(
    siteHierarchy,
    state.parentPath
  )

  const onSelectZone = zone => {
    setSelectedZone(zone)
  }
  return (
    <Flex direction='column' className='HierarchyOptions' axisGap={400}>
      <Label>
        <Text variant='page' tone={700}>
          {I18n.get(
            'Select a new location to move the selected location and their children to.'
          )}
        </Text>
      </Label>
      {Object.keys(siteHierarchy).length > 0 && (
        <Flex direction='column' axisGap={200} className='list'>
          <Box className='overflow-container'>
            <HierarchyOptions
              state={{ ...state, depth: stateDepth }}
              onSelectZone={onSelectZone}
              hierarchy={siteHierarchy}
              selectedZoneId={selectedZone?.id}
            />
          </Box>
        </Flex>
      )}
      <Flex>
        <Text as='p' size={200}>
          <Text variant='primary' tone={500}>
            {state.name}
          </Text>{' '}
          ({getZoneSubNameV2(stateDepth, orgLabels)}){' '}
          {I18n.get('is being moved to')}{' '}
          <Text variant='primary' tone={500}>
            {selectedZone?.name ?? ''}
          </Text>{' '}
          {selectedZone?.level &&
            `(${getZoneSubNameV2(selectedZone.level - 1, orgLabels)})`}
        </Text>
      </Flex>
    </Flex>
  )
}

import { I18n } from 'aws-amplify'

const Strings = () => ({
  managePlans: I18n.get('Manage Plans'),
  createPlan: I18n.get('Create plan'),
  noPlans: I18n.get('No plans'),
  createFormHeader: I18n.get('Create New Plan'),
  updateFormHeader: I18n.get('Update Existing Plan'),
  plan: I18n.get('Plan'),
  savePlan: I18n.get('Save Plan'),
  location: I18n.get('Location'),
  varieties: I18n.get('Varieties'),
  date: I18n.get('Date'),
  organization: I18n.get('Organization'),
  site: I18n.get('Site'),
  zone: I18n.get('Zone'),
  produce: I18n.get('Produce'),
  variety: I18n.get('Variety'),
  currency: I18n.get('Currency'),
  weight: I18n.get('Weight'),
  weights: I18n.get('Weights'),
  totalWeightKg: I18n.get('Total Weight (kg)'),
  status: I18n.get('Status'),
  harvestStartDate: I18n.get('Harvest Start Date'),
  harvestEndDate: I18n.get('Harvest End Date'),
  targetPrice: I18n.get('Target Price'),
  estimatedWeights: I18n.get('Estimated Weights'),
  addWeight: I18n.get('Add Weight'),
  selectDefault: I18n.get('Please Select'),
  kg: I18n.get('kg'),
  planned: I18n.get('Planned'),
  canceled: I18n.get('Canceled'),
  harvested: I18n.get('Harvested'),
  createdAt: I18n.get('Date Created'),
  updatedAt: I18n.get('Date Updated'),
  download: I18n.get('Download')
})

export default Strings

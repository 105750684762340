import {
  AVAILABLE_FEATURE_FLAGS,
  ENABLE_MAPS,
  hasFeatureFlagEnabled
} from '@/Util/FeatureFlagsUtils'

import { byText } from '../../../Utils'

export const organization = ({
  level,
  locationSelector,
  permissions,
  strings,
  currentUser
}) => {
  const organization = locationSelector.get(level)

  const enableMaps = hasFeatureFlagEnabled(
    currentUser,
    AVAILABLE_FEATURE_FLAGS[ENABLE_MAPS],
    organization.id
  )

  return {
    text: locationSelector.get(level).name,
    title: strings[level],
    icon: 'corporate fare',
    visible: permissions.zone,
    routes: [
      {
        to: `/zones/${organization.id}/data`,
        text: strings.databoard,
        icon: 'insights'
      },
      {
        to: `/zones/${organization.id}/map`,
        text: strings.map,
        icon: 'travel_explore',
        visible: enableMaps
      },
      {
        to: `/zones/${organization.id}`,
        text: strings.dashboard,
        icon: 'dashboard',
        visible: false
      },
      {
        to: '/settings',
        text: strings.settings,
        icon: 'settings',
        visible: false
      },
      {
        text: strings.inventory,
        icon: 'inventory',
        to: `/operations/${organization.id}/inventory`,
        visible: permissions.operations_inventory,
        routes: [
          {
            to: `/operations/${organization.id}/inventory/transfers`,
            text: strings.transfers,
            icon: 'move up'
          },
          {
            to: `/operations/${organization.id}/inventory/packaging`,
            text: strings.packaging,
            icon: 'package',
            routes: [
              {
                to: `/operations/${organization.id}/inventory/packaging/package-units`,
                text: strings.packaging_units,
                icon: 'straighten'
              }
            ]
          },
          {
            to: `/operations/${organization.id}/inventory/fertilizer`,
            text: strings.fertilizer,
            icon: 'fertile',
            visible: permissions.operations_fertilizer
          },
          {
            to: `/operations/${organization.id}/inventory/storage-facilities`,
            text: strings.storage_facilities,
            icon: 'warehouse'
          },
          {
            to: `/operations/${organization.id}/inventory/storage-facilities/shipping-methods`,
            text: strings.shipping_methods,
            icon: 'local shipping',
            visible: false
          },
          {
            to: `/operations/${organization.id}/inventory/suppliers`,
            text: strings.suppliers,
            icon: 'forklift'
          }
        ].sort(byText)
      },
      {
        to: `/operations/${organization.id}/products`,
        text: strings.products,
        icon: 'conveyor belt',
        visible: permissions.operations_inventory
      },
      {
        to: `/operations/${organization.id}/customers`,
        text: strings.customers,
        icon: 'productivity',
        visible: permissions.operations_customers,
        routes: [
          {
            to: `/operations/${organization.id}/customers/branches`,
            text: strings.branches,
            icon: 'storefront'
          },
          {
            to: `/operations/${organization.id}/customers/unit-prices`,
            text: strings.unit_prices,
            icon: 'price check'
          }
        ]
      },
      {
        to: `/operations/${organization.id}/sales`,
        text: strings.sales,
        icon: 'shopping cart',
        visible: permissions.operations_sales,
        routes: [
          {
            to: `/operations/${organization.id}/sales/payment-methods`,
            text: strings.payment_methods,
            icon: 'credit card'
          },
          {
            to: `/operations/${organization.id}/sales/payment-terms`,
            text: strings.payment_terms,
            icon: 'assignment return'
          },
          {
            to: `/operations/${organization.id}/sales/returns`,
            text: strings.returns,
            icon: 'receipt',
            visible: permissions.operations_returns
          }
        ].sort(byText)
      },
      {
        to: `/operations/${organization.id}/waste`,
        text: strings.waste,
        icon: 'delete sweep',
        visible: permissions.operations_waste
      },
      {
        text: strings.management,
        icon: 'shield person',
        visible: permissions.operations_management,
        routes: [
          {
            to: `/operations/${organization.id}/management/produce`,
            text: strings.produce,
            icon: 'nutrition',
            routes: [
              {
                to: `/operations/${organization.id}/management/produce/varieties`,
                text: strings.varieties,
                icon: 'variables'
              }
            ]
          },
          {
            to: `/operations/${organization.id}/management/grades`,
            text: strings.grades,
            icon: 'grade'
          },
          {
            to: `/operations/${organization.id}/management/currencies`,
            text: strings.currencies,
            icon: 'payments'
          },
          {
            to: `/operations/${organization.id}/management/countries`,
            text: strings.countries,
            icon: 'public'
          }
        ].sort(byText)
      }
    ]
  }
}

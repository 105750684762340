import { I18n } from 'aws-amplify'
import { Fragment } from 'react'

import {
  getDataboardCrops,
  getDataboardSitesHierarchies
} from '@/reducers/selectors'

import { Box, Text } from '@/primitives'
import { getZoneName } from '@/Util/ZoneUtils'

const CropsBarTooltip = ({ id, indexValue }) => {
  const zonesHierarchy = getDataboardSitesHierarchies()
  const crops = getDataboardCrops()
  const currentCrop = crops.find(({ id }) => id === indexValue)
  const { siteId, zoneId } = currentCrop

  const statuses = {
    unplanned: null,
    planned: 'createdAt',
    sowing: 'sowDate',
    transplanting: 'transplantDate',
    harvesting: 'harvestStartDate',
    harvested: 'harvestEndDate'
  }

  const strings = {
    unplanned: I18n.get('Not yet planned'),
    planned: I18n.get('Planned'),
    sowing: I18n.get('Sowed'),
    transplanting: I18n.get('Transplanted'),
    harvesting: I18n.get('Harvest started'),
    harvested: I18n.get('Harvest ended')
  }

  return (
    <Box
      style={{
        backgroundColor: 'var(--ctx-theme-color-page-300)',
        padding: '1rem'
      }}
    >
      <Text variant='page' tone={800} size={100} fontWeight={500}>
        {getZoneName(zonesHierarchy, siteId, zoneId)}
      </Text>
      <Text
        as='p'
        fontFamily='sans'
        size={200}
        fontWeight={500}
      >{`${currentCrop.produce.name} - ${currentCrop.variety.name}`}</Text>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          marginTop: '1rem'
        }}
      >
        {Object.keys(statuses).map(key => (
          <Fragment key={key}>
            <Text size={100} fontWeight={id === key ? 700 : 200}>
              {strings[key]}
            </Text>
            <Text size={100} fontWeight={id === key ? 700 : 200}>
              {statuses[key] &&
                new Date(currentCrop[statuses[key]]).toDateString()}
            </Text>
          </Fragment>
        ))}
      </div>
    </Box>
  )
}

export default CropsBarTooltip

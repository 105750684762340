import cloneDeep from 'lodash/cloneDeep'
import { CHARTS, QUERIES } from './index'

const WIDTH_INDEX = 2
const HEIGHT_INDEX = 3

export class QueryOption {
  constructor({ value, options }) {
    this.value = value
    this.options = options
    this.label = QUERIES[value].label
    this.type = 'query'

    // check if id is valid
    if (!QUERIES[value]) {
      throw new Error(`QueryOption: ${value} is not a valid query id.`)
    }

    if (!this.options || options.length === 0) {
      throw new Error(
        'QueryOption: options is required and cannot be an empty array.'
      )
    }
  }
}

export class ChartOption {
  constructor({ value, range, interval }) {
    this.value = value
    this.label = CHARTS[value].label
    this.range = range || null
    this.interval = interval || null
    this.type = 'chart'

    // check if id is valid
    if (!CHARTS[value]) {
      throw new Error(`ChartOption: ${value} is not a valid chart id.`)
    }

    if (range && !Array.isArray(range)) {
      throw new Error('ChartOption: range must be an array.')
    }

    if (interval && !Array.isArray(interval)) {
      throw new Error('ChartOption: interval must be an array.')
    }
  }
}

export function getWidgetPositionByChart(
  widgetProps,
  currentBreakpoint,
  oldChart,
  hasBeenEditResized = false,
  oldPosition = null
) {
  let position = cloneDeep(widgetProps.position)

  const { chart } = widgetProps.config
  const chartSize =
    CHARTS?.[chart]?.minSize?.[currentBreakpoint] ||
    Object.values(CHARTS?.[chart]?.minSize || {})?.[0] // fallback to first breakpoint

  if (chartSize) {
    // if chart size is defined, set the widget size to the chart size
    // only if the widget size is smaller than minW or minH

    // c = current
    let [cX, cY, cW, cH] = position[currentBreakpoint]
    const { minW: chartMinW, minH: chartMinH } = chartSize
    let nextPosition = [cX, cY, cW, cH, chartMinW, chartMinH]

    if (cW < chartMinW) {
      nextPosition[WIDTH_INDEX] = chartMinW
    }

    if (cH < chartMinH) {
      nextPosition[HEIGHT_INDEX] = chartMinH
    }

    // if the widget has been resized because of the edit mode,
    // then set the widget size to the chart size regardless of the current size
    // need to check if chart has changed if so then set the size to the chart size
    if (hasBeenEditResized) {
      nextPosition[WIDTH_INDEX] =
        oldChart !== chart
          ? chartMinW
          : oldPosition?.[currentBreakpoint]?.[WIDTH_INDEX] || chartMinW
      nextPosition[HEIGHT_INDEX] =
        oldChart !== chart
          ? chartMinH
          : oldPosition?.[currentBreakpoint]?.[HEIGHT_INDEX] || chartMinH
    }

    position[currentBreakpoint] = [...nextPosition]
  }

  return position
}

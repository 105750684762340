import { I18n } from 'aws-amplify'
import { object, string } from 'yup'
import {
  DATASET_QUERY,
  LINE_CHART,
  PIE_CHART
} from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'

export function validateSchemaByType(queryType, chartType, widgetId) {
  let params = {
    validateProduceId: false,
    validateInterval: false
  }

  if (queryType === DATASET_QUERY) {
    switch (chartType) {
      case PIE_CHART:
        if (widgetId === 'topTenHarvestVariety') {
          params.validateProduceId = true
        }
        break
      case LINE_CHART:
        params.validateInterval = true
        if (widgetId === 'topTenHarvestVariety') {
          params.validateProduceId = true
        }
        break
      default:
        break
    }
  }
  return params
}

export const validateQueryOptions = ({
  validateProduceId = false,
  validateInterval = false
}) => {
  const shape = {
    query: string().required(I18n.get('Select a query is required')),
    chart: string().required(I18n.get('Select a chart is required')),
    range: string().required(I18n.get('Select a range is required'))
  }

  if (validateProduceId) {
    shape.produceId = string().required(
      I18n.get('Select a produce is required')
    )
  }
  if (validateInterval) {
    shape.interval = string().required(
      I18n.get('Select an interval is required')
    )
  }

  return object().shape(shape)
}

export const operationEditTypes = {
  setAll: 'setAll',
  setQuery: 'setQuery',
  setChart: 'setChart',
  setRange: 'setRange',
  setInterval: 'setInterval',
  setProduceId: 'setProduceId'
}

export const operationEditInitalState = {
  query: '',
  chart: '',
  range: '',
  interval: '',
  produceId: ''
}

export const operationEditReducer = (state, action) => {
  switch (action.type) {
    case 'setAll':
      return {
        ...state,
        query: action.payload.query || '',
        chart: action.payload.chart || '',
        range: action.payload.range || '',
        interval: action.payload.interval || '',
        produceId: action.payload.produceId || ''
      }
    case 'setQuery':
      return {
        ...state,
        query: action.payload,
        chart: '',
        range: '',
        interval: '',
        produceId: ''
      }
    case 'setChart':
      return { ...state, chart: action.payload, range: '', interval: '' }
    case 'setRange':
      return { ...state, range: action.payload, interval: '' }
    case 'setInterval':
      return { ...state, interval: action.payload }
    case 'setProduceId':
      return { ...state, produceId: action.payload }
    default:
      return state
  }
}

export function createProduceOptions(
  produces = [],
  defaultLable = I18n.get('Select a value')
) {
  return [
    {
      value: '',
      label: defaultLable
    },
    ...produces.map(produce => ({
      value: produce.id,
      label: produce.name
    }))
  ]
}

export function getHasProduceId(options, widgetId) {
  return widgetId === 'topTenHarvestVariety'
}

export function getOperationsCancelDisable(widgetProps) {
  const { widgetId } = widgetProps

  // Don't allow cancel if no produce is selected for topTenHarvestVariety
  return widgetId === 'topTenHarvestVariety' && !widgetProps.config.produceId
}

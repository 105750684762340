import useDatasetData from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Hooks/useDatasetData'
import Line from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/Chart/Line'
import {
  convertToSalinity,
  salinityMeasurements
} from '@/components/DashboardPageV2/Desktop/Widgets/Fertigation/Salinity/utils'

export default function DatasetLine({ unit, config, ...props }) {
  const { datasetData, queryZones } = useDatasetData({
    measurements: salinityMeasurements,
    range: config.range,
    interval: config.interval
  })

  let chartData = {}

  if (datasetData) {
    for (let { zoneName } of queryZones) {
      const waterTemperatureData =
        datasetData?.['water_temperature']?.[zoneName] || []
      const conductivityData = datasetData?.['conductivity']?.[zoneName] || []

      chartData[zoneName] = waterTemperatureData.reduce(
        (result, water_temperature, index) => {
          const conductivityDataPoint = conductivityData[index]
          if (
            conductivityDataPoint &&
            water_temperature.x === conductivityDataPoint.x
          ) {
            result.push({
              x: water_temperature.x,
              y: convertToSalinity({
                water_temperature: water_temperature.y,
                conductivity: conductivityDataPoint.y
              })
            })
          }

          return result
        },
        []
      )
    }
  }

  return (
    <Line
      {...props}
      data={chartData}
      unit={unit}
      config={config}
      queryZones={queryZones}
    />
  )
}

import { useEffect, useReducer, useState } from 'react'
import { I18n } from 'aws-amplify'
import { useDispatch } from 'react-redux'
import { string, object } from 'yup'

import { Button, Flex, Grid, Item, Slot, Text, Toggle } from '@/primitives'
import { Dialog, LineSeparator, Select } from '@/elements'

import { userIdToName } from './utils'
import Strings from '../../Strings'

import { sendCreateManager, sendUpdateManager } from '@/slices/manager'
import { showBanner } from '@/slices/util'
import {
  getManager,
  getManagers,
  getShowBanner,
  getZoneUsers,
  getCurrentUser,
  getManagerError,
  getManagerSaving,
  getZoneHierarchy
} from '@/reducers/selectors'

import {
  hasEditPermissions,
  RESOURCE_TYPE_USER,
  RESOURCE_TYPE_USER_MANAGERS
} from '@/Util/PermissionUtils'

import {
  AVAILABLE_FEATURE_FLAGS,
  ENABLE_WHATSAPP,
  hasFeatureFlagEnabled
} from '@/Util/FeatureFlagsUtils'

const managerSchema = object().shape({
  userId: string().required(I18n.get('User is required'))
})

const initialState = {
  rootId: '',
  userId: '',
  settings: {
    email: true,
    pn: true,
    sms: false,
    webPush: true,
    whatsApp: false
  }
}

function reducer(state, action) {
  switch (action.type) {
    case 'set':
      const { userId, settings } = action.value
      return { ...state, userId, settings }
    case 'reset':
      return initialState
    case 'userId':
      return { ...state, userId: action.value }
    case 'rootId':
      return { ...state, rootId: action.value }
    case 'settings':
      return { ...state, settings: action.value }
    default:
      return state
  }
}

function getIsIdentical(stateSettings, managerSettings) {
  for (const setting in stateSettings) {
    if (managerSettings[setting] !== stateSettings[setting]) {
      return false
    }
  }

  return true
}

const ManagerUpsertModal = ({
  showModal,
  zone,
  closeModal,
  isAdd,
  onUpsertSuccess
}) => {
  const reduxDispatch = useDispatch()
  const banner = getShowBanner()
  const manager = getManager()
  const isSaving = getManagerSaving()
  const managers = getManagers()
  const users = getZoneUsers()
  const error = getManagerError()
  const coretexUser = getCurrentUser()
  const zoneHierarchy = getZoneHierarchy()

  const [state, dispatch] = useReducer(reducer, initialState)

  const [submitted, setSubmitted] = useState(false)

  const showEditOptions = hasEditPermissions(
    coretexUser,
    RESOURCE_TYPE_USER,
    RESOURCE_TYPE_USER_MANAGERS
  )

  const enableWhatsApp = hasFeatureFlagEnabled(
    coretexUser,
    AVAILABLE_FEATURE_FLAGS[ENABLE_WHATSAPP],
    Object.keys(zoneHierarchy).length > 0 ? zoneHierarchy.organizationId : null
  )

  const {
    cancel,
    addManagerModal,
    editManagerModal,
    managerFormUserSelect,
    managerFormUserSelectPlaceholder,
    addManagerModalSubheading,
    editManagerModalSubheading,
    managerSettingEmail,
    managerSettingWhatsApp,
    managerSettingWebPush,
    createManagerButton,
    editManagerButton,
    settingsHaveNotChanged
  } = Strings()

  let NOTIFICATION_CHANNELS = [
    { value: 'email', label: managerSettingEmail },
    { value: 'webPush', label: managerSettingWebPush }
  ]

  if (enableWhatsApp) {
    NOTIFICATION_CHANNELS.push({
      value: 'whatsApp',
      label: managerSettingWhatsApp
    })
  }

  useEffect(() => {
    if (zone?.id) {
      dispatch({
        type: 'rootId',
        value: zone?.parentId ? zone.parentPath.split('/')[1] : zone.id
      })
    }
  }, [dispatch, zone])

  useEffect(() => {
    if (state.userId.length === 0) {
      if (!isAdd && manager?.userId) {
        dispatch({ type: 'set', value: manager })
      }

      if (isAdd) {
        const { userId, settings } = initialState
        dispatch({ type: 'set', value: { userId, settings } })
      }
    }
  }, [dispatch, manager, isAdd, state?.userId])

  useEffect(() => {
    if (manager?.userId && submitted) {
      if (isAdd) {
        onUpsertSuccess()
      }

      if (!isAdd) {
        const matches = getIsIdentical(state?.settings, manager?.settings)
        if (matches) {
          onUpsertSuccess()
        }
      }
    }
  }, [dispatch, manager, state, submitted, isAdd, onUpsertSuccess])

  useEffect(() => {
    if (error) {
      setSubmitted(false)
    }
  }, [dispatch, error, setSubmitted])

  function onChangeUser(option) {
    dispatch({ type: 'userId', value: option?.value })
  }

  function onChangeNotificationChannel(e) {
    e.preventDefault()
    const { name, checked } = e.target
    let settings = { ...state.settings }
    settings[name] = checked
    dispatch({ type: 'settings', value: settings })
  }

  function onCloseModal() {
    if (banner.show) {
      reduxDispatch(showBanner({ show: false, message: '', type: null }))
    }

    closeModal()
  }

  async function onSubmitForm(e) {
    e.preventDefault()
    if (showEditOptions) {
      try {
        await managerSchema.validate({ ...state }, { abortEarly: true })
        if (!isAdd) {
          const matchesCurrent = getIsIdentical(
            state?.settings,
            manager?.settings
          )
          if (matchesCurrent) throw Error(settingsHaveNotChanged)
        }
        const actionFuc = !isAdd ? sendUpdateManager : sendCreateManager
        reduxDispatch(actionFuc({ ...state, zoneId: zone.id }))
        setSubmitted(true)
      } catch (err) {
        reduxDispatch(
          showBanner({ show: true, message: err.message, type: 'error' })
        )
      }
    }
  }

  function filterUsers(users, managers) {
    return users.filter(({ userName }) => {
      return !managers.some(({ userId }) => userId === userName)
    })
  }

  return (
    <Dialog
      open={showModal}
      onOpenChange={isSaving ? null : onCloseModal}
      type='offcanvas'
      className='Managers__OffCanvas'
      style={{ zIndex: 3 }}
    >
      <Slot name='title'>
        <Text size={300} fontWeight={700}>
          {!isAdd ? editManagerModal : addManagerModal}
        </Text>
      </Slot>
      <Slot name='content'>
        <Text as='p'>
          {`${isAdd ? addManagerModalSubheading : editManagerModalSubheading} ${
            zone.name
          }`}
        </Text>
        <LineSeparator />
        <Flex direction='column' axisGap={300} style={{ marginBottom: '1em' }}>
          <Text variant='page' tone={900} size={100}>
            {managerFormUserSelect}
          </Text>
          {!isAdd ? (
            <Text fontWeight={700} size={300}>
              {userIdToName(state.userId, users)}
            </Text>
          ) : (
            <Select
              isSearchable={true}
              value={state.userId}
              placeholder={managerFormUserSelectPlaceholder}
              options={filterUsers(users, managers).map(user => {
                return {
                  label: userIdToName(user.userName, users),
                  value: user.userName
                }
              })}
              onChange={onChangeUser}
            />
          )}
          <Grid
            key={JSON.stringify(state.settings)}
            gap={400}
            templateColumns={'repeat(auto-fit, minmax(100px, 1fr))'}
            style={{ marginTop: '1em' }}
          >
            {NOTIFICATION_CHANNELS.map(({ value, label }) => (
              <Item key={value}>
                <Flex direction='column' axisGap={200}>
                  <Text
                    variant='page'
                    tone={900}
                    size={100}
                    style={{ marginBottom: '0.2em' }}
                  >
                    {label}
                  </Text>
                  <Toggle
                    name={value}
                    onChange={onChangeNotificationChannel}
                    checked={state.settings[value]}
                    disabled={!showEditOptions}
                  />
                </Flex>
              </Item>
            ))}
          </Grid>
        </Flex>
      </Slot>
      <Slot name='actions'>
        <Flex axisGap={400} alignMainAxis={'space-between'}>
          <Button variant='page' onClick={onCloseModal} disabled={isSaving}>
            {cancel}
          </Button>
          {showEditOptions && (
            <Button
              variant='primary'
              onClick={onSubmitForm}
              loading={isSaving}
              style={{ minWidth: '8rem' }}
            >
              {!isAdd ? editManagerButton : createManagerButton}
            </Button>
          )}
        </Flex>
      </Slot>
    </Dialog>
  )
}

export default ManagerUpsertModal

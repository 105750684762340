import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import ZonesList from './ZonesList'
import ZonePage from './Zone'

import { cleanRootZone, resetZoneData } from '@/slices/management/zone'
import { useBreadCrumbs } from '@/contexts/breadcrumb-context'

import { Loader } from '@/primitives'
import { getIsLoading } from '@/reducers/selectors'

export default function ZonesPage() {
  const { setBreadCrumbs } = useBreadCrumbs()
  const dispatch = useDispatch()
  const isLoading = getIsLoading()

  useEffect(() => {
    return () => {
      dispatch(resetZoneData())
      dispatch(cleanRootZone())
      setBreadCrumbs(null)
    }
  }, [dispatch, setBreadCrumbs])

  return (
    <Loader isLoading={isLoading}>
      <Switch>
        <Route
          exact={true}
          path='/admin/zones'
          render={routeProps => <ZonesList {...routeProps} />}
        />
        <Route exact={true} path='/admin/zones/:zone+' component={ZonePage} />
      </Switch>
    </Loader>
  )
}

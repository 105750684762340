const Icons = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      style={{ display: 'none', position: 'absolute' }}
    >
      <defs>
        <symbol id='ctx-icon-device-envirosense' viewBox='0 0 24 24'>
          <title>Envirosense</title>
          <path d='m3 21v-14c0-1.1.4-2 1.2-2.8s1.8-1.2 2.8-1.2h7c1.1 0 2 .4 2.8 1.2s1.2 1.8 1.2 2.8v1h-2v-1c0-.5-.2-1-.6-1.4s-.8-.6-1.4-.6h-7c-.5 0-1 .2-1.4.6s-.6.8-.6 1.4v12h11v-2h2v4zm7.5-5c1.1 0 2-.4 2.8-1.2s1.2-1.7 1.2-2.8-.4-2-1.2-2.8-1.7-1.2-2.8-1.2-2 .4-2.8 1.2-1.2 1.8-1.2 2.8.4 2 1.2 2.8 1.7 1.2 2.8 1.2zm0-2c-.6 0-1-.2-1.4-.6s-.6-.8-.6-1.4.2-1 .6-1.4.9-.6 1.4-.6 1 .2 1.4.6.6 1 .6 1.4-.2 1-.6 1.4-.8.6-1.4.6zm6.5-2v-2h1 .4s.2-.2.3-.3c.2-.5.6-1 1-1.1.3-.5.9-.6 1.4-.6h.9v2h-1s-.3 0-.4 0c0 0-.2.2-.3.3-.2.5-.6 1-1 1.1-.4.3-1 .4-1.5.4h-.8zm0 4v-2h1 .4s.2-.2.3-.3c.2-.5.6-1 1-1.1.4-.3 1-.4 1.5-.4h.8v2h-1s-.3 0-.4 0c0 0-.2.2-.3.3-.2.5-.6 1-1 1.1-.4.3-1 .4-1.5.4zm-1 3h-11z' />
        </symbol>
        <symbol id='ctx-icon-device-cellular_envirosense' viewBox='0 0 24 24'>
          <title>Cellular Envirosense</title>
          <path d='m3.2 21v-14c0-1.1.4-2 1.2-2.8s1.8-1.2 2.8-1.2h7c1.1 0 2 .4 2.8 1.2s1.2 1.8 1.2 2.8v1h-2v-1c0-.5-.2-1-.6-1.4s-.8-.6-1.4-.6h-7c-.5 0-1 .2-1.4.6s-.6.8-.6 1.4v12h11v-2h2v4zm7.5-5c1.1 0 2-.4 2.8-1.2s1.2-1.7 1.2-2.8-.4-2-1.2-2.8-1.7-1.2-2.8-1.2-2 .4-2.8 1.2-1.2 1.8-1.2 2.8.4 2 1.2 2.8 1.7 1.2 2.8 1.2zm0-2c-.6 0-1-.2-1.4-.6s-.6-.8-.6-1.4.2-1 .6-1.4.9-.6 1.4-.6 1 .2 1.4.6.6 1 .6 1.4-.2 1-.6 1.4-.8.6-1.4.6zm5.5 5h-11zm1.1-4-.9-.9c.2-.2.4-.5.6-.8s.2-.6.2-1-.1-.7-.2-1-.3-.6-.6-.8l.9-.9c.3.3.6.7.8 1.2.2.4.3.9.3 1.5 0 .5-.1 1-.3 1.4-.2.6-.5 1-.8 1.3zm.8.9c.4-.4.8-1 1-1.6s.4-1.2.4-1.8-.1-1.4-.4-1.8c-.2-.6-.6-1.1-1-1.5l.9-1c.6.6 1 1.2 1.2 2 .4.7.6 1.5.6 2.5.1.9-.2 1.7-.5 2.5-.3.7-.7 1.4-1.4 1.8z' />
        </symbol>
        <symbol id='ctx-icon-device-soilsense' viewBox='0 0 24 24'>
          <title>Soilsense</title>
          <path d='m12 22c-1.2-.1-2.4-.5-3.6-1s-2.3-1.2-3.2-2.2-1.7-2.2-2.3-3.6-.9-3.2-.9-5.2v-1h1c.9 0 1.7.1 2.6.3s1.7.5 2.5 1c.2-1.4.7-2.9 1.4-4.4s1.6-2.8 2.5-3.9c.9 1.1 1.8 2.4 2.5 3.9s1.2 3 1.4 4.4c.8-.4 1.6-.8 2.5-1s1.7-.3 2.6-.3h1v1c0 2-.3 3.8-.9 5.2s-1.3 2.7-2.3 3.6c-.9.9-2 1.7-3.2 2.2s-2.4.9-3.6 1zm-.1-2c-.2-2.8-1-4.9-2.5-6.3s-3.3-2.3-5.4-2.6c.2 2.8 1 5 2.5 6.4 1.6 1.3 3.4 2.2 5.4 2.5zm.1-6.4c.2-.4.6-.7.9-1.1.4-.4.7-.7 1-1 0-.9-.2-1.9-.6-3s-.8-2-1.4-3c-.6 1-1 2-1.4 3s-.5 2-.6 3c.3.3.7.6 1.1 1 .5.4.8.7 1 1.1zm1.9 5.9c.6-.2 1.3-.5 1.9-.9.7-.4 1.3-.9 1.9-1.6s1.1-1.5 1.5-2.5.7-2.2.7-3.5c-1.6.2-2.9.8-4.1 1.6s-2.1 1.8-2.7 3.1c.2.5.4 1.1.5 1.8.2.6.3 1.3.3 2z' />
        </symbol>
        <symbol id='ctx-icon-device-cellular_soilsense' viewBox='0 0 24 24'>
          <title>Cellular Soilsense</title>
          <path d='m12 22c-1.2-.1-2.4-.5-3.6-1s-2.3-1.2-3.2-2.2-1.7-2.2-2.3-3.6-.9-3.2-.9-5.2v-1h1c.9 0 1.7.1 2.6.3s1.7.5 2.5 1c.2-1.4.7-2.9 1.4-4.4s1.6-2.8 2.5-3.9c.9 1.1 1.8 2.4 2.5 3.9s1.2 3 1.4 4.4c.8-.4 1.6-.8 2.5-1s1.7-.3 2.6-.3h1v1c0 2-.3 3.8-.9 5.2s-1.3 2.7-2.3 3.6c-.9.9-2 1.7-3.2 2.2s-2.4.9-3.6 1zm-.1-2c-.2-2.8-1-4.9-2.5-6.3s-3.3-2.3-5.4-2.6c.2 2.8 1 5 2.5 6.4 1.6 1.3 3.4 2.2 5.4 2.5zm.1-6.4c.2-.4.6-.7.9-1.1.4-.4.7-.7 1-1 0-.9-.2-1.9-.6-3s-.8-2-1.4-3c-.6 1-1 2-1.4 3s-.5 2-.6 3c.3.3.7.6 1.1 1 .5.4.8.7 1 1.1zm1.9 5.9c.6-.2 1.3-.5 1.9-.9.7-.4 1.3-.9 1.9-1.6s1.1-1.5 1.5-2.5.7-2.2.7-3.5c-1.6.2-2.9.8-4.1 1.6s-2.1 1.8-2.7 3.1c.2.5.4 1.1.5 1.8.2.6.3 1.3.3 2z' />
        </symbol>
        <symbol id='ctx-icon-device-watersense' viewBox='0 0 24 24'>
          <title>Watersense</title>
          <path d='m15.5 11c-.4 0-.8 0-1-.4s-.5-.7-.5-1.1c0-.3 0-.6.2-1s.3-.6.5-.8.4-.4.5-.6l.3-.1.2.2c.2.2.3.4.5.6s.4.5.5.8.3.6.3.9c0 .4 0 .8-.4 1-.3.5-.7.5-1.1.5zm4-3c-.4 0-.8 0-1-.4-.5-.3-.5-.7-.5-1.1 0-.3 0-.6.2-1 .2-.3.3-.6.5-.8s.4-.4.5-.6l.3-.1.2.2c.2.2.3.4.5.6s.4.5.5.8c.3.3.3.6.3.9 0 .4 0 .8-.4 1-.3.4-.7.5-1.1.5zm0 6c-.4 0-.8 0-1-.4-.3-.3-.4-.6-.4-1 0-.3 0-.6.2-1s.3-.6.5-.8.4-.4.5-.6l.2-.2.2.2c.2.2.3.4.5.6s.4.5.5.8.2.6.2 1 0 .8-.4 1c-.2.3-.6.4-1 .4zm-10.5 7c-1.4 0-2.6-.5-3.5-1.5s-1.5-2.1-1.5-3.5c0-.8.2-1.5.5-2.2s.9-1.3 1.5-1.8v-6c0-.8.3-1.5 1-2s1.2-1 2-1 1.5.3 2 1c.7.5 1 1.2 1 2v6c.6.5 1 1 1.5 1.8.3.7.5 1.4.5 2.2 0 1.4-.5 2.6-1.5 3.5s-2.1 1.5-3.5 1.5zm-3-5h6c0-.5 0-1-.3-1.4-.2-.4-.5-.8-1-1l-.7-.6v-7c0-.3 0-.5-.3-.7-.2-.3-.4-.3-.7-.3s-.5 0-.7.3c-.3.2-.3.4-.3.7v7l-.8.6c-.4.3-.7.6-1 1-.2.4-.2.9-.2 1.4z' />
        </symbol>
        <symbol id='ctx-icon-device-cellular_watersense' viewBox='0 0 24 24'>
          <title>Cellular Watersense</title>
          <path d='m15.5 11c-.4 0-.8 0-1-.4s-.5-.7-.5-1.1c0-.3 0-.6.2-1s.3-.6.5-.8.4-.4.5-.6l.3-.1.2.2c.2.2.3.4.5.6s.4.5.5.8.3.6.3.9c0 .4 0 .8-.4 1-.3.5-.7.5-1.1.5zm4-3c-.4 0-.8 0-1-.4-.5-.3-.5-.7-.5-1.1 0-.3 0-.6.2-1 .2-.3.3-.6.5-.8s.4-.4.5-.6l.3-.1.2.2c.2.2.3.4.5.6s.4.5.5.8c.3.3.3.6.3.9 0 .4 0 .8-.4 1-.3.4-.7.5-1.1.5zm0 6c-.4 0-.8 0-1-.4-.3-.3-.4-.6-.4-1 0-.3 0-.6.2-1s.3-.6.5-.8.4-.4.5-.6l.2-.2.2.2c.2.2.3.4.5.6s.4.5.5.8.2.6.2 1 0 .8-.4 1c-.2.3-.6.4-1 .4zm-10.5 7c-1.4 0-2.6-.5-3.5-1.5s-1.5-2.1-1.5-3.5c0-.8.2-1.5.5-2.2s.9-1.3 1.5-1.8v-6c0-.8.3-1.5 1-2s1.2-1 2-1 1.5.3 2 1c.7.5 1 1.2 1 2v6c.6.5 1 1 1.5 1.8.3.7.5 1.4.5 2.2 0 1.4-.5 2.6-1.5 3.5s-2.1 1.5-3.5 1.5zm-3-5h6c0-.5 0-1-.3-1.4-.2-.4-.5-.8-1-1l-.7-.6v-7c0-.3 0-.5-.3-.7-.2-.3-.4-.3-.7-.3s-.5 0-.7.3c-.3.2-.3.4-.3.7v7l-.8.6c-.4.3-.7.6-1 1-.2.4-.2.9-.2 1.4z' />
        </symbol>
        <symbol id='ctx-icon-device-dissolved_oxygen' viewBox='0 0 24 24'>
          <title>Dissolvevd Oxygen</title>
          <path d='m12 20c-.3 0-.5-.1-.7-.3s-.3-.4-.3-.7v-4c0-.3.1-.5.3-.7s.4-.3.7-.3h2.5c.3 0 .5.1.7.3s.3.4.3.7v4c0 .3-.1.5-.3.7s-.4.3-.7.3zm.5-1.5h1.5v-3h-1.5zm4.5 3.5v-2.8c0-.3.1-.5.3-.7s.4-.3.7-.3h2v-.8h-3v-1.4h3.5c.3 0 .5.1.7.3s.3.4.3.7v1.8c0 .3-.1.5-.3.7s-.4.3-.7.3h-2v.8h3v1.4zm-8 0c-2-.2-3.7-1.1-5-2.6s-2-3.4-2-5.5c0-1.7.7-3.5 2-5.4s3.3-4.2 6-6.5c2.2 1.9 3.9 3.6 5.2 5.3s2.1 3.2 2.5 4.7h-2.1c-.4-1.1-1-2.2-2-3.4-.8-1.3-2.1-2.6-3.6-4-2 1.8-3.5 3.5-4.5 5s-1.5 3-1.5 4.2c0 1.6.5 3 1.4 4.1s2.1 1.8 3.6 2z' />
        </symbol>
        <symbol id='ctx-icon-device-energy_monitor' viewBox='0 0 24 24'>
          <title>Energy Monitor</title>
          <path d='m9 22v-2.5c-1.8-.6-3.2-1.7-4.3-3.2-1.1-1.6-1.7-3.4-1.7-5.4 0-1.2.2-2.4.7-3.5s1.1-2 1.9-2.8 1.8-1.5 2.8-1.9 2.3-.7 3.5-.7 2.4.2 3.5.7 2.1 1.1 2.9 1.9 1.5 1.8 1.9 2.8.8 2.3.8 3.5c0 2-.6 3.7-1.7 5.2s-2.6 2.6-4.3 3.2v2.7h-2v-2.1c-.2 0-.3.1-.5.1h-.5c-.2 0-.3 0-.5 0s-.3 0-.5 0v2zm3-4c1.9 0 3.6-.7 5-2.1s2-3 2-4.9-.7-3.6-2-5-3-2-5-2-3.6.7-4.9 2-2.1 3.1-2.1 5 .7 3.6 2.1 4.9 3 2.1 4.9 2.1zm-4-9h8v-2h-8zm3.2 8 3-3-1.2-1.2 1.2-1.2-1.4-1.6-3 3 1.2 1.2-1.2 1.2z' />
        </symbol>
        <symbol id='ctx-icon-device-generic' viewBox='0 0 24 24'>
          <title>Sensor</title>
          <path d='m5 19c-1-1-1.6-2-2.1-3.2s-1-2.4-1-3.8.3-2.7.8-4 1.2-2.2 2.2-3l1.4 1.4c-.8.7-1.4 1.5-1.8 2.5s-.6 2.1-.6 3.1c0 1.1.2 2.2.6 3.1.4 1 1 1.8 1.7 2.5zm2.8-2.8c-.5-.6-1-1.2-1.3-2-.3-.6-.5-1.4-.5-2.2 0-1 .2-1.6.5-2.4s.7-1.4 1.3-2l1.4 1.4c-.4.5-.7 1-1 1.4-.2.6-.3 1-.3 1.6s.1 1.1.3 1.6.5 1 1 1.3zm4.2-2.2c-.6 0-1-.2-1.4-.6s-.6-.8-.6-1.4.2-1 .6-1.4.8-.6 1.4-.6 1 .2 1.4.6.6.8.6 1.4-.2 1-.6 1.4-.8.6-1.4.6zm4.2 2.2-1.4-1.4c.4-.4.7-.8 1-1.3s.3-1 .3-1.6-.1-1.1-.3-1.6-.5-1-1-1.3l1.4-1.4c.5.5 1 1.2 1.3 2 .3.7.5 1.5.5 2.4 0 .8-.2 1.6-.5 2.3-.3.8-.7 1.4-1.3 2zm2.8 2.8-1.4-1.4c.7-.7 1.3-1.6 1.7-2.6s.6-2 .6-3-.2-2.2-.6-3c-.4-1-1-1.8-1.7-2.5l1.4-1.4c1 1 1.6 2 2 3.2.6 1.2.9 2.5 1 4s-.3 2.7-.8 4c-.5 1.1-1.2 2.2-2.2 3z' />
        </symbol>
      </defs>
    </svg>
  )
}

export default Icons

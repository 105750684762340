import { createContext, useContext } from 'react'

export const DashboardContext = createContext({})

/**
 * Access the MosaicContext and return the state and dispatch
 * Needs to be used within a MosaicProvider
 * @returns {{ state: object, dispatchState: function }}
 */
export function useDashboard() {
  const context = useContext(DashboardContext)

  if (context === undefined) {
    throw new Error('useDashboard must be used within a DashboardProvider')
  }
  return context
}

import { I18n } from 'aws-amplify'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  getSensorSimDetails,
  getSensorSimDiagnosticError
} from '@/reducers/selectors'
import { sendUpdateSensorSimStatus } from '@/slices/diagnostics/sensorSim'
import { Dialog, Select, LineSeparator } from '@/elements'

import { Slot, Text, FlexV2, Button, Symbol } from '@/primitives'

import { SET_SHOW_SIM_CONFIG_VIEW } from '../state'
import FormInput from '../FormInput'

const STATUS_OPTIONS = () => [
  { value: 'active', label: I18n.get('Active') },
  { value: 'inactive', label: I18n.get('Inactive') },
  { value: 'suspended', label: I18n.get('Suspended') }
]

const STATUS_ACTIONS_MAP = {
  active: 'activate',
  inactive: 'deactivate',
  suspended: 'suspend'
}

const Providers = () => ({
  soracom: I18n.get('Soracom')
})

export default function SensorSimConfig({ state, stateDispatch }) {
  const dispatch = useDispatch()
  const simDetails = getSensorSimDetails()
  const simError = getSensorSimDiagnosticError()
  const [status, setStatus] = useState(null)
  const [isStatusChanged, setIsStatusChanged] = useState(false)

  const providers = Providers()

  useEffect(() => {
    if (simDetails.status && !simError) {
      setStatus(simDetails.status)
    }
  }, [simDetails.status])

  function onShowSimConfigView(value) {
    stateDispatch({
      type: SET_SHOW_SIM_CONFIG_VIEW,
      payload: value
    })
  }

  function onGoBack() {
    onShowSimConfigView(false)
  }

  function updateStatus() {
    dispatch(
      sendUpdateSensorSimStatus({
        sensorId: state.device.id,
        action: STATUS_ACTIONS_MAP[status]
      })
    )
  }

  function onSubmit() {
    if (isStatusChanged) {
      updateStatus()
    }
    onShowSimConfigView(false)
  }

  function onSelectStatus(selected) {
    setStatus(selected.value)
    setIsStatusChanged(simDetails.status !== selected.value)
  }

  const isSimSuspendedOnSubmit = isStatusChanged && status === 'suspended'

  return (
    <Dialog
      open={state.showSimConfigView}
      onOpenChange={onShowSimConfigView}
      type='offcanvas'
      className='Devices__OffCanvas'
      style={{ zIndex: 3 }}
    >
      <Slot name='title'>
        <Text as={'h5'} size={300} fontWeight={700}>
          {I18n.get('SIM Configuration')}
        </Text>
        <Text as='p'>
          {I18n.get(
            'View and edit the SIM configuration for this cellular device.'
          )}
        </Text>
      </Slot>
      <Slot name='content'>
        <LineSeparator />
        {simError && (
          <FlexV2
            alignMainAxis='center'
            alignCrossAxis='center'
            direction='row'
            style={{ height: '100%' }}
            axisGap={300}
          >
            <Symbol name='error' size={500} tone={500} variant='error' />
            <Text variant='error' size={300} tone={500}>
              {I18n.get(
                'Error fetching SIM details. Our team has been notified.'
              )}
            </Text>
          </FlexV2>
        )}
        {!simError && simDetails?.simId && (
          <Fragment>
            <FormInput
              label={I18n.get('Provider')}
              name='provider'
              value={providers[simDetails.provider]}
              onChange={null}
              readOnly={true}
            />
            <FormInput
              label={I18n.get('Sim ID')}
              name='simId'
              value={simDetails.simId}
              onChange={null}
              readOnly={true}
            />
            <FlexV2 direction='column' axisGap={300}>
              <Text variant='page' tone={900} size={100}>
                {I18n.get('Status')}
              </Text>
              <Select
                value={status}
                placeholder={I18n.get('Select Status')}
                options={STATUS_OPTIONS()}
                onChange={onSelectStatus}
                disabled={simDetails.status === 'suspended'}
              />
              <FlexV2
                alignCrossAxis='center'
                axisGap={400}
                style={{
                  marginTop: '1em',
                  opacity: isSimSuspendedOnSubmit ? 1 : 0
                }}
              >
                <Symbol
                  name='warning'
                  size={500}
                  tone={500}
                  variant='warning'
                />
                <Text variant='warning' size={200} tone={500}>
                  {I18n.get(
                    'Suspending a SIM is irreversible. Proceed with caution.'
                  )}
                </Text>
              </FlexV2>
            </FlexV2>
          </Fragment>
        )}
      </Slot>
      <Slot name='actions'>
        {simError && (
          <FlexV2 axisGap={400} alignMainAxis={'space-between'}>
            <Button onClick={onGoBack}>{I18n.get('Go Back')}</Button>
          </FlexV2>
        )}
        {!simError && (
          <FlexV2 axisGap={400} alignMainAxis={'space-between'}>
            <Button onClick={onGoBack}>{I18n.get('Cancel')}</Button>
            <Button onClick={onSubmit} variant='primary'>
              {I18n.get('Save Changes')}
            </Button>
          </FlexV2>
        )}
      </Slot>
    </Dialog>
  )
}

import { I18n } from 'aws-amplify'

const Strings = () => ({
  manageCrops: I18n.get('Manage crops'),
  createCrop: I18n.get('Create crop'),
  noCrops: I18n.get('No crops'),
  createFormHeader: I18n.get('Create New Crop'),
  updateFormHeader: I18n.get('Update Existing Crop'),
  saveCrop: I18n.get('Save crop'),
  crop: I18n.get('Crop'),
  location: I18n.get('Location'),
  createdAt: I18n.get('Date Created'),
  updatedAt: I18n.get('Date Updated'),
  organization: I18n.get('Organization'),
  site: I18n.get('Site'),
  zone: I18n.get('Zone'),
  produce: I18n.get('Produce'),
  variety: I18n.get('Variety'),
  produceThreshold: I18n.get('Recommendations Template'),
  volume: I18n.get('Volume'),
  status: I18n.get('Status'),
  plantingArea: I18n.get('Planting Area'),
  plantingAreaM2: I18n.get('Planting Area (m2)'),
  growthMedium: I18n.get('Growth Medium'),
  hydrationMethod: I18n.get('Hydration Method'),
  seedQuantity: I18n.get('Quantity of Seeds'),
  seeds: I18n.get('seeds'),
  estimatedSowDate: I18n.get('Estimated Sow Date'),
  sowDate: I18n.get('Date Sowed'),
  estimatedTransplantDate: I18n.get('Estimated Transplant Date'),
  transplantDate: I18n.get('Date Transplanted'),
  estimatedHarvestStartDate: I18n.get('Estimated Harvest Start Date'),
  harvestStartDate: I18n.get('Harvest Start Date'),
  estimatedHarvestEndDate: I18n.get('Estimated Harvest End Date'),
  harvestEndDate: I18n.get('Harvest End Date'),
  selectDefault: I18n.get('Please Select'),
  schedule: I18n.get('Schedule'),
  conditions: I18n.get('Conditions'),
  planned: I18n.get('Planned'),
  sowed: I18n.get('Sowed'),
  transplanted: I18n.get('Transplanted'),
  harvesting: I18n.get('Harvesting'),
  harvested: I18n.get('Harvested'),
  failed: I18n.get('Failed'),
  canceled: I18n.get('Canceled'),
  allStatuses: I18n.get('Any Status'),
  perlite: I18n.get('Perlite'),
  vermiculite: I18n.get('Vermiculite'),
  volcanic: I18n.get('Volcanic Rock'),
  rockwool: I18n.get('Rockwool/Stonewool'),
  soil: I18n.get('Soil'),
  coconut: I18n.get('Coconut Coir'),
  clay: I18n.get('Clay Pellets'),
  sand: I18n.get('Sand/Gravel/Sawdust'),
  peat: I18n.get('Peat Moss'),
  dwc: I18n.get('Deep Water Culture'),
  dutch: I18n.get('Dutch Bucket'),
  gutter: I18n.get('Gutter System'),
  nft: I18n.get('Nutrient Film Technique'),
  aeroponics: I18n.get('Aeroponics'),
  drip: I18n.get('Drip System'),
  wicking: I18n.get('Wicking'),
  irrigation: I18n.get('Irrigation'),
  metersSquared: I18n.get('m2'),
  download: I18n.get('Download'),
  queryData: I18n.get('Query data'),
  roofCover: I18n.get('Roof Cover'),
  secondsky: I18n.get('SecondSky'),
  plastic: I18n.get('Standard Plastic'),
  open_field: I18n.get('Open Field'),
  secondskyVariant: I18n.get('SecondSky Variant'),
  polyfilm: I18n.get('SecondSky PolyFilm'),
  polycarbonate: I18n.get('SecondSky PolyCarbonate'),
  net: I18n.get('SecondSky Net'),
  blockingRatio: I18n.get('Blocking Ratio'),
  low: I18n.get('Low'),
  medium: I18n.get('Medium'),
  high: I18n.get('High'),
  netType: I18n.get('Net Type'),
  twenty: I18n.get('10/20'),
  sixteen: I18n.get('10/16'),
  tricot: I18n.get('Tricot'),
  rashel: I18n.get('Rashel'),
  secondskyInformation: I18n.get('SecondSky Additional Information'),
  notApplicable: I18n.get('Not Applicable')
})

export default Strings

import { useRef, useEffect } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import csx from 'classnames'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
// import debounce from 'lodash/debounce'

import {
  TILE_SIZE,
  TILE_MARGIN,
  COLUMN_BREAKPOINTS,
  CONTAINER_PADDING,
  COMPACT_TYPE,
  SCREEN_SIZE_BREAKPOINTS,
  COLUMNS
} from '../../config'

import Box from '@/primitives/Box'
import useIsRtl from '@/hooks/useIsRtl'

import { useDashboard } from '../../context'

import {
  SET_CURRENT_BREAKPOINT,
  TOGGLE_EDITABLE,
  UPDATE_LAYOUTS
} from '../../state'

import {
  widgetsToLayouts,
  getGridPosition,
  reverseXValuesHorizontally
} from '../../Widgets/utils'

import MosaicTile from '../Tile'

import 'react-grid-layout/css/styles.css'
import './index.scss'

const GridLayout = WidthProvider(Responsive)

/**
 * MosaicLayout is a wrapper around the ResponsiveGridLayout from react-grid-layout.
 * @returns {JSX.Element}
 */
export default function MosaicLayout() {
  const { state, dispatchState } = useDashboard()
  const isRtl = useIsRtl()

  const { currentBreakpoint, editable } = state
  let layouts = widgetsToLayouts(state.widgets)

  const containerRef = useRef()
  const prevLayout = useRef(layouts)
  const prevDashboardId = useRef(state?.currentDashboard?.id)

  const isSensorDashboard = state.currentDashboard.context === 'sensor'
  if (isRtl) {
    // Reverse the x values of the layouts so that the layout is RTL.
    for (const breakpoint in layouts) {
      layouts[breakpoint] = reverseXValuesHorizontally(
        layouts[breakpoint],
        COLUMNS[breakpoint]
      )
    }
  }

  const onBreakpointChange = breakpoint => {
    dispatchState({
      type: SET_CURRENT_BREAKPOINT,
      payload: { breakpoint }
    })
  }

  useEffect(() => {
    if (!isSensorDashboard && state.currentDashboard.id !== prevDashboardId) {
      prevLayout.current = layouts
      prevDashboardId.current = state.currentDashboard.id
    }
  }, [isSensorDashboard, layouts, state.currentDashboard.id])

  const handleLayoutChange = (newLayout, nextActionDeferCommit = null) => {
    if (!isSensorDashboard) {
      let clonedLayouts = newLayout
      if (isRtl) {
        // if isRTL means it's already horizontally reversed, so we need to reverse it back
        clonedLayouts = reverseXValuesHorizontally(
          cloneDeep(newLayout),
          COLUMNS[state.currentBreakpoint]
        )
      }

      // Check if new layout is different from previous layout
      if (!isEqual(clonedLayouts, prevLayout)) {
        dispatchState({
          type: UPDATE_LAYOUTS,
          payload: { layouts: clonedLayouts, nextActionDeferCommit }
        })
        prevLayout.current = clonedLayouts
      }
    }
  }

  const onToggleEditable = nextActionDeferCommit => {
    dispatchState({
      type: TOGGLE_EDITABLE,
      payload: { editable: !state.editable, nextActionDeferCommit }
    })
  }

  const onResizeStop = newLayout => {
    handleLayoutChange(newLayout, false)
    onToggleEditable()
  }

  const onDragStop = newLayout => {
    handleLayoutChange(newLayout, false)
    onToggleEditable()
  }

  // This solves a bug where the grid layout doesn't update when the parent container resizes.
  // It can happen when the sidebar is locked when you load up the dashboard and then you unlock it.
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      // Manually trigger window resize event.
      window.dispatchEvent(new Event('resize'))
    })

    if (containerRef.current) {
      // Start observing the parent container.
      resizeObserver.observe(containerRef.current)
    }

    const container = containerRef?.current

    // Clean up function.
    return () => {
      if (container) {
        // Stop observing the parent container.
        resizeObserver.unobserve(container)
      }
    }
  }, [])

  return (
    <Box
      ref={containerRef}
      className={csx([
        'MosaicContainer',
        editable ? 'MosaicContainer__Editable' : null
      ])}
    >
      <GridLayout
        autoSize={true}
        className={csx(['MosaicV2', editable ? 'MosaicV2__Editable' : null])}
        breakpoints={SCREEN_SIZE_BREAKPOINTS}
        cols={COLUMN_BREAKPOINTS}
        layouts={layouts}
        useCSSTransforms={false}
        onBreakpointChange={onBreakpointChange}
        onDragStart={onToggleEditable}
        onDragStop={onDragStop}
        onResizeStart={onToggleEditable}
        onResizeStop={onResizeStop}
        margin={[TILE_MARGIN, TILE_MARGIN]}
        containerPadding={CONTAINER_PADDING}
        rowHeight={TILE_SIZE}
        isDraggable={!isSensorDashboard}
        isResizable={!isSensorDashboard}
        compactType={COMPACT_TYPE}
        draggableHandle='.MosaicV2__Tile__Move'
      >
        {layouts[currentBreakpoint].map(({ i: id }) => {
          const {
            props: { position, widgetId }
          } = state.widgets.get(id)

          const dataGrid = getGridPosition(
            widgetId,
            id,
            position,
            state.currentBreakpoint
          )
          return (
            // For some reason the MosaicTile needs to be wrapped in a div for the resizeHandle to be attached.
            // This also removed the need for MosaicTile to use forwardRef & get all those props injected.
            <Box key={id} data-grid={dataGrid}>
              <MosaicTile id={id} widgetId={widgetId} dataGrid={dataGrid} />
            </Box>
          )
        })}
      </GridLayout>
    </Box>
  )
}

export const UPDATE_INPUT = 'UPDATE_INPUT'
export const RESET_STATE = 'RESET_STATE'
export const SET_STATE = 'SET_STATE'
export const UPDATE_PAYMENT = 'UPDATE_PAYMENT'
export const REMOVE_PAYMENT = 'REMOVE_PAYMENT'
export const UPDATE_SALE_ITEM = 'UPDATE_SALE_ITEM'
export const REMOVE_SALE_ITEM = 'REMOVE_SALE_ITEM'
export const UPDATE_SALE_SHIPMENT = 'UPDATE_SALE_SHIPMENT'
export const REMOVE_SALE_SHIPMENT = 'REMOVE_SALE_SHIPMENT'

export const initialState = {
  organizationId: '',
  userId: '',
  orderDate: '',
  purchaseOrder: '',
  customerId: '',
  branchId: '',
  status: '',
  currencyId: '',
  invoiceNumber: '',
  paymentMethodId: '',
  paymentTermId: '',
  invoiceStatus: '',
  dueDate: '',
  deliveryDate: '',
  payments: [],
  saleItems: [],
  saleShipments: []
}

const fixDate = value => {
  if (value) {
    return new Date(value)
  }
  return null
}

export function reducer(state, action) {
  const handlers = {
    [RESET_STATE]: (state, action) => ({
      ...initialState
    }),
    [SET_STATE]: (state, action) => {
      let newState = {
        ...initialState,
        ...action.state
      }
      newState.orderDate = fixDate(newState.orderDate)
      newState.saleShipments.forEach((shipment, index) => {
        newState.saleShipments[index].dueDate = fixDate(shipment.dueDate)
        newState.saleShipments[index].deliveryDate = fixDate(
          shipment.deliveryDate
        )
      })
      newState.payments.forEach((payment, index) => {
        newState.payments[index].dateReceived = fixDate(payment.dateReceived)
      })
      return newState
    },
    [UPDATE_INPUT]: (state, action) => ({
      ...state,
      [action.name]: action.value
    }),
    [REMOVE_SALE_ITEM]: (state, action) => {
      let saleItems = state.saleItems
      const saleItemIndex = saleItems.findIndex(
        saleItem => saleItem.id === action.saleItemId
      )
      if (saleItemIndex >= 0) {
        saleItems.splice(saleItemIndex, 1)
      }
      return {
        ...state,
        saleItems: saleItems
      }
    },
    [UPDATE_SALE_ITEM]: (state, action) => {
      let saleItems = state.saleItems
      const saleItemIndex = saleItems.findIndex(
        saleItem => saleItem.id === action.saleItem.id
      )
      if (saleItemIndex >= 0) {
        saleItems[saleItemIndex] = action.saleItem
      } else {
        saleItems.push(action.saleItem)
      }
      return {
        ...state,
        saleItems: saleItems
      }
    },
    [REMOVE_SALE_SHIPMENT]: (state, action) => {
      let saleShipments = state.saleShipments
      const saleShipmentIndex = saleShipments.findIndex(
        saleShipment => saleShipment.id === action.saleShipmentId
      )
      if (saleShipmentIndex >= 0) {
        saleShipments.splice(saleShipmentIndex, 1)
      }
      return {
        ...state,
        saleShipments: saleShipments
      }
    },
    [UPDATE_SALE_SHIPMENT]: (state, action) => {
      let saleShipments = state.saleShipments
      const saleShipmentIndex = saleShipments.findIndex(
        saleShipment => saleShipment.id === action.saleShipment.id
      )
      if (saleShipmentIndex >= 0) {
        saleShipments[saleShipmentIndex] = action.saleShipment
      } else {
        saleShipments.push(action.saleShipment)
      }
      return {
        ...state,
        saleShipments: saleShipments
      }
    },
    [UPDATE_PAYMENT]: (state, action) => {
      let payments = state.payments
      const paymentIndex = payments.findIndex(
        payment => payment.id === action.payment.id
      )
      if (paymentIndex >= 0) {
        payments[paymentIndex] = action.payment
      } else {
        payments.push(action.payment)
      }
      return {
        ...state,
        payments: payments
      }
    },
    [REMOVE_PAYMENT]: (state, action) => {
      let payments = state.payments
      const paymentIndex = payments.findIndex(
        payment => payment.id === action.payment.id
      )
      if (paymentIndex >= 0) {
        payments.splice(paymentIndex, 1)
      }
      return {
        ...state,
        payments: payments
      }
    }
  }

  if (handlers[action.type]) {
    return handlers[action.type](state, action)
  }

  return state
}

import { I18n } from 'aws-amplify'
import { object, string, number } from 'yup'

export const FIELDS = {
  id: 'id',
  organizationId: 'organizationId',
  inventoryId: 'inventoryId',
  newWeight: 'newWeight',
  reason: 'reason'
}

export const SCHEMA = () => {
  return object().shape({
    organizationId: string().required(I18n.get('An organization is required.')),
    inventoryId: string().required(I18n.get('Please select the inventory.')),
    newWeight: number()
      .typeError(I18n.get('New weight is required.'))
      .min(0, I18n.get('New weight cannot be less than zero.'))
      .required(I18n.get('New weight is required.')),
    reason: string().required(I18n.get('A reason is required.'))
  })
}

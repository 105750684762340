import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'

import FlexV2 from '@/primitives/FlexV2'
import {
  getCurrentUser,
  getMeasurements,
  getZonesHierarchy,
  getRootZone
} from '@/reducers/selectors'

import { useDataboard } from '../../context'
import { splitDataset } from '../../Utils/DataUtils'
import {
  onDownloadCSVComparison,
  thunkDownloadCsvDateComparison
} from '../../Utils/DownloadUtils'
import { COMPARISON_TYPES } from '../../Utils/config'

import Chart from '../Chart'
import NoDataCard from './NoDataCard'
import TooMuchDataCard from './TooMuchDataCard'

const MAX_DATA_POINTS = 15000

export default function ChartCard({ onExportChart }) {
  const { state } = useDataboard()
  const dispatch = useDispatch()
  const [prevDatasetId, setPrevDatasetId] = useState(state.datasetId)
  const [chartStateClone, setChartStateClone] = useState(cloneDeep(state))

  const allMeasurements = getMeasurements()
  const zonesHierarchy = getZonesHierarchy()
  const currentUser = getCurrentUser()
  const rootZone = getRootZone()

  const updateDatasetAndState = useCallback(() => {
    setPrevDatasetId(state.datasetId)
    setChartStateClone(cloneDeep(state))
  }, [state])

  useEffect(() => {
    if (prevDatasetId !== state.datasetId) {
      updateDatasetAndState()
    }
  }, [state.datasetId, prevDatasetId, updateDatasetAndState])

  const canShowChart = state => {
    let totalPoints = 0
    const dataset = state.dataset || []

    for (const set of dataset) {
      for (const serie of set.series) {
        totalPoints += serie.data.length
      }
    }
    return totalPoints <= MAX_DATA_POINTS
  }

  const showChart = canShowChart(chartStateClone)
  const splitData = splitDataset(chartStateClone)
  const isDatasetEmpty =
    !chartStateClone?.dataset || chartStateClone?.dataset?.length === 0

  const onClickDownload = e => {
    e.preventDefault()
    if (state.comparisonType === COMPARISON_TYPES.DATE_RANGES) {
      dispatch(
        thunkDownloadCsvDateComparison({
          state: chartStateClone,
          allMeasurements,
          zonesHierarchy,
          user: currentUser,
          site: rootZone
        })
      )
    }
    if (state.comparisonType === COMPARISON_TYPES.LOCATION_RANGES) {
      onDownloadCSVComparison(
        state,
        allMeasurements,
        zonesHierarchy,
        currentUser,
        rootZone
      )
    }
  }

  return (
    <FlexV2 direction={'column'} alignMainAxis='stretch' axisGap={400}>
      {!isDatasetEmpty &&
        showChart &&
        chartStateClone.measurements[chartStateClone.comparisonType].map(
          (measurement, index) => {
            return (
              <Chart
                key={index}
                measurementId={measurement}
                data={splitData[index]}
                onExportChart={onExportChart}
                chartState={chartStateClone}
              />
            )
          }
        )}
      {!isDatasetEmpty && !showChart && (
        <TooMuchDataCard onClickDownload={onClickDownload} />
      )}
      {isDatasetEmpty && <NoDataCard />}
    </FlexV2>
  )
}

import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { fetchCurrentMobileData } from '@/actions/ts/dashboard'
import { useMobileDashboard } from '@/components/DashboardPageV2/Mobile/context'
import { getChildIds } from '../../utils'

export function useLiveData({ measurements }) {
  const [dataRequested, setDataRequested] = useState(null)
  if (!measurements) {
    throw new Error('useLiveData requires a measurements array')
  }

  const dispatch = useDispatch()
  const { state } = useMobileDashboard()

  const { currentZone, siteId, hierarchy } = state

  useEffect(() => {
    // Fetches current data for the measurement
    // Backend decides if it uses cached data or not
    if (currentZone.id && siteId && hierarchy && measurements?.length > 0 && (currentZone?.id !== dataRequested)) {
      const childIds = getChildIds(currentZone?.children)
      const zoneIds = [...childIds, currentZone.id]

      const params = {
        table: siteId,
        zoneIds,
        organizationId: hierarchy.organizationId,
        measurements,
        devices: currentZone?.devices
      }
      dispatch(fetchCurrentMobileData(params))
      setDataRequested(currentZone?.id)
    }
  }, [
    currentZone.id,
    currentZone?.children,
    siteId,
    hierarchy,
    dispatch,
    measurements,
    currentZone?.devices
  ])
}

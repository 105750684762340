import { I18n } from 'aws-amplify'
import Dialog from '@/elements/Dialog'

import { Icon, FlexV2, Slot, Text, Button } from '@/primitives'
import { getDeviceLoading } from '@/reducers/selectors'

import Strings from '../../../../Strings'
import { SET_SHOW_DEVICE_MOVER_CONFIRMATION_MODAL } from '../state'

export default function ConfirmationModal({ state, stateDispatch, onSubmit }) {
  const strings = Strings()
  const isLoading = getDeviceLoading()

  function setShowDeviceMoverConfimation(value) {
    stateDispatch({
      type: SET_SHOW_DEVICE_MOVER_CONFIRMATION_MODAL,
      payload: value
    })
  }

  function onAcknowledgeDeviceMover() {
    setShowDeviceMoverConfimation(false)
    onSubmit(true)
  }

  function onCancelAcknowledgeDeviceMover() {
    setShowDeviceMoverConfimation(false)
  }

  return (
    <Dialog
      variant='warning'
      open={state.showDeviceMoverConfirmationModal}
      onOpenChange={setShowDeviceMoverConfimation}
      type='modal'
    >
      <Slot name='title'>
        <FlexV2 alignCrossAxis='center' axisGap={400}>
          <Icon name='warning' size={20} variant='warning' tone={600} />
          <Text size={300} fontWeight={700} variant='warning' tone={600}>
            {I18n.get('Device is being moved')}
          </Text>
        </FlexV2>
      </Slot>
      <Slot name='content'>
        <Text as='p'>
          {I18n.get('The device')}
          <Text name='warning' variant='warning' tone={600}>
            {` "${state.device.name}" `}
          </Text>
          {I18n.get(
            'is being moved to another location and will take a few minutes to complete.'
          )}{' '}
          {I18n.get(
            'You will be unable to move the device again for the next 15 minutes.'
          )}
        </Text>
        <Text as='p' fontWeight={600}>
          {I18n.get(
            'Make sure to physically move the device to the new location.'
          )}
        </Text>
      </Slot>
      <Slot name='actions'>
        <Slot name='actions'>
          <FlexV2 axisGap={400} alignMainAxis={'space-between'}>
            <Button onClick={onCancelAcknowledgeDeviceMover}>
              {strings.cancel}
            </Button>
            <Button
              variant='warning'
              onClick={onAcknowledgeDeviceMover}
              loading={isLoading}
              iconBefore='edit_location'
            >
              {I18n.get('Move device')}
            </Button>
          </FlexV2>
        </Slot>
      </Slot>
    </Dialog>
  )
}

import ZoneUtils from '@/Util/ZoneUtils'

export function redirectToDetails(history, url, params) {
  const basePath = ZoneUtils.getZoneBasePath(params)
  const newPath = url.replace('/fleet-management', '/details')
  const basePathWithoutFleetManagement = basePath.replace(
    '/fleet-management',
    ''
  )
  const shouldReplacePath = url.includes(
    `${basePathWithoutFleetManagement}/fleet-management`
  )

  if (shouldReplacePath) {
    history.replace(newPath)
  }
}

/**
 * Remove paths from s3key
 * @param {string} s3Key
 * @returns cleanFileName
 */
export const cleanS3Key = s3Key => {
  if (!s3Key) return null
  const key = s3Key.split('/').pop()
  return key ?? null
}

import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { fetchCurrentSimpleDashboardData } from '@/actions/ts/dashboard'

export function useLiveDataSimplified({ measurements, state }) {
  const [dataRequested, setDataRequested] = useState(null)

  if (!measurements) {
    throw new Error('useLiveData requires a measurements array')
  }

  const dispatch = useDispatch()

  const { zoneId, siteId, hierarchy, sensorId } = state
  const currentLocationId = sensorId ?? zoneId

  useEffect(() => {
    if (zoneId && siteId && hierarchy && measurements?.length > 0 && (currentLocationId !== dataRequested)) {

      const params = {
        table: siteId,
        id: currentLocationId,
        organizationId: hierarchy.organizationId,
        measurements
      }
      dispatch(fetchCurrentSimpleDashboardData(params))
      setDataRequested(currentLocationId)
    }
  }, [
    zoneId,
    siteId,
    hierarchy,
    dispatch,
    measurements,
    dataRequested
  ])
}

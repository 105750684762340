import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { getReports, generateReport, downloadReport } from '@/api/report'

export const fetchReports = createAsyncThunk('fetchReports', async params => {
  const { reports, error } = await getReports(params)
  return { reports, error }
})

export const sendGenerateReport = createAsyncThunk(
  'sendGenerateReport',
  async params => {
    const { reportId, error } = await generateReport(params)
    return { reportId, error }
  }
)

export const sendDownloadReport = createAsyncThunk(
  'sendDownloadReport',
  async params => {
    const { report, error } = await downloadReport(params)
    return { report, error }
  }
)

const reportSlice = createSlice({
  name: 'reportReducer',
  initialState: {
    reports: [],
    error: null,
    loading: false,
    downloading: false
  },
  reducers: {
    cleanReports: state => {
      state.reports = []
    },
    reportGenerated: (state, action) => {
      state.reports = [action.payload]
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchReports.pending, (state, action) => {
        state.error = null
        state.loading = true
        state.reports = []
      })
      .addCase(fetchReports.fulfilled, (state, action) => {
        if (!action.payload.error) {
          state.reports = action?.payload?.reports
        }

        state.error = action?.payload?.error ?? null
        state.loading = false
      })
      .addCase(sendDownloadReport.pending, (state, action) => {
        state.error = null
        state.downloading = true
      })
      .addCase(sendDownloadReport.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.downloading = false
      })
  }
})

const { actions, reducer } = reportSlice

export const { reportGenerated, cleanReports } = actions

export default reducer

import { Children, Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'

import Strings from './Strings'
import UserManagementPage from './UserManagement'
import UserRolesPage from './UserRoles'
import ZonesPage from './Zones'
import OrganizationsPage from './Organizations'
import DeviceManagementPage from './DeviceManagement'
import DataApiPage from '../DataApiPage'
import SupplierPage from '../Suppliers'
import AuditPage from './Audit'
import FyiPage from './Fyi'

import { resetZoneData } from '@/slices/management/zone'

import { getCurrentUser } from '@/reducers/selectors'

import { getAdminDefaultUrl } from '@/Util/AdminUtils'
import {
  RESOURCE_TYPE_DATA_API,
  RESOURCE_TYPE_DATA_API_MANAGEMENT,
  RESOURCE_TYPE_DEVICE,
  RESOURCE_TYPE_ORGANIZATION,
  RESOURCE_TYPE_USER,
  RESOURCE_TYPE_USER_MANAGEMENT,
  RESOURCE_TYPE_USER_ROLES,
  RESOURCE_TYPE_ZONE,
  RESOURCE_TYPE_DEVICE_MANAGEMENT,
  hasReadPermissions,
  RESOURCE_TYPE_USER_LOGS,
  getIsGodMode
} from '@/Util/PermissionUtils'

import { generateRoutes } from './utils'

import history from '../../history'

import './index.scss'

export default function AdminPage() {
  const dispatch = useDispatch()
  //const { pathname } = useLocation()
  const strings = Strings()
  const coretexUser = getCurrentUser()
  const adminUrl = getAdminDefaultUrl(coretexUser)
  const isGodMode = getIsGodMode(coretexUser)

  const showUsersList = hasReadPermissions(
    coretexUser,
    RESOURCE_TYPE_USER,
    RESOURCE_TYPE_USER_MANAGEMENT
  )
  const showUserRolesList = hasReadPermissions(
    coretexUser,
    RESOURCE_TYPE_USER,
    RESOURCE_TYPE_USER_ROLES
  )
  const showZonesList = hasReadPermissions(coretexUser, RESOURCE_TYPE_ZONE)
  const showOrganizations = hasReadPermissions(
    coretexUser,
    RESOURCE_TYPE_ORGANIZATION
  )

  const showDeviceManagement = hasReadPermissions(
    coretexUser,
    RESOURCE_TYPE_DEVICE,
    RESOURCE_TYPE_DEVICE_MANAGEMENT
  )

  const showDataApiPage = hasReadPermissions(
    coretexUser,
    RESOURCE_TYPE_DATA_API,
    RESOURCE_TYPE_DATA_API_MANAGEMENT
  )

  const showAuditTab = hasReadPermissions(
    coretexUser,
    RESOURCE_TYPE_USER,
    RESOURCE_TYPE_USER_LOGS
  )

  const adminRoutes = generateRoutes([
    {
      route: 'user-management',
      text: strings.userManagementTab,
      component: UserManagementPage,
      visible: showUsersList
    },
    {
      route: 'user-roles',
      text: strings.userRolesTab,
      component: UserRolesPage,
      visible: showUserRolesList
    },
    {
      route: 'zones',
      component: ZonesPage,
      text: strings.sitesTab,
      visible: showZonesList,
      onClick: onClickZones
    },
    {
      route: 'data-api',
      text: strings.dataApiTab,
      component: DataApiPage,
      visible: showDataApiPage
    },
    {
      route: 'organizations',
      text: strings.orgsTab,
      component: OrganizationsPage,
      visible: showOrganizations && isGodMode
    },
    {
      route: 'device-management',
      text: strings.deviceManagementHeading,
      component: DeviceManagementPage,
      visible: showDeviceManagement && isGodMode
    },
    {
      route: 'audit',
      text: strings.auditTab,
      component: AuditPage,
      visible: showAuditTab
    },
    {
      route: 'suppliers',
      text: strings.suppliersTab,
      component: SupplierPage,
      visible: isGodMode
    },
    {
      route: 'fyi',
      text: strings.fyiPageHeader,
      component: FyiPage,
      visible: isGodMode
    }
  ]).filter(item => item.visible)

  function onClickZones(e) {
    e.preventDefault()
    dispatch(resetZoneData())
    history.push('/admin/zones')
  }

  return (
    <Fragment>
      {coretexUser.hasOwnProperty('userName') && adminRoutes?.length > 0 && (
        <Fragment>
          {adminUrl ? (
            <Switch>
              {Children.toArray(
                adminRoutes?.map(({ to, component }) => (
                  <Route path={to} component={component} />
                ))
              )}
            </Switch>
          ) : (
            <Redirect to='/' />
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

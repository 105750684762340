import { Box } from '../'
import './index.scss'

const Label = ({ children = null, className = '', ...rest }) => {
  return (
    <Box as='label' className={['Label', className]} {...rest}>
      {children}
    </Box>
  )
}

export default Label

import { I18n } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import history from '@/history'
import { Select, LineSeparator } from '@/elements'
import { Box, Button, FlexV2, Input, Text, Loader } from '@/primitives'
import { fetchDevice } from '@/slices/management/device'
import { fetchZone } from '@/slices/management/zone'
import {
  getDevice,
  getDeviceLoading,
  getZone,
  getZoneLoading
} from '@/reducers/selectors'

import styles from './index.module.scss'

const PARAMS_PRECEDENCE = ['deviceId', 'locationId']

const Strings = () => ({
  deviceId: I18n.get('Device'),
  locationId: I18n.get('Location')
})

const SEARCH_OPTIONS = () => {
  const strings = Strings()
  return [
    {
      value: 'deviceId',
      label: strings.deviceId
    },
    {
      value: 'locationId',
      label: strings.locationId
    }
  ]
}

export default function FindDashboard() {
  const dispatch = useDispatch()
  const strings = Strings()
  const [type, setType] = useState('deviceId')
  const [value, setValue] = useState('')
  const [error, setError] = useState('')
  const [fetched, setFetched] = useState(false)
  const location = useLocation()

  const device = getDevice()
  const zone = getZone()

  const deviceIsLoading = getDeviceLoading()
  const zoneIsLoading = getZoneLoading()

  const isLoading = deviceIsLoading || zoneIsLoading

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)

    for (const param of PARAMS_PRECEDENCE) {
      const value = searchParams.get(param)
      if (value) {
        setValue(value)
        setType(param)
        fetchDeviceOrZone(param, value)
        break
      }
    }
  }, [location])

  useEffect(() => {
    if (fetched) {
      if (type === 'deviceId') {
        if (device?.id) {
          history.push(
            `zones${device.zonePath}/sensor/${device.sensorType}/${device.id}`
          )
        } else if (device && typeof device === 'string') {
          setError(`${I18n.get('Device not found')}: ${value}`)
        }
      } else if (type === 'locationId') {
        if (zone?.id) {
          history.push(`zones${zone.parentPath}`)
        } else if (zone && zone?.id === null) {
          setError(I18n.get('Location not found'))
        }
      }
    }
  }, [type, device, zone, fetched])

  function onChangeType(selected) {
    setError('')
    setFetched(false)
    setType(selected.value)
  }

  function onChangeValue(e) {
    setError('')
    setFetched(false)
    setValue(e.target.value)
  }

  function fetchDeviceOrZone(type, value) {
    setError('')
    if (type === 'deviceId') {
      dispatch(fetchDevice({ deviceId: value }))
    } else if (type === 'locationId') {
      dispatch(fetchZone({ id: value }))
    }
    setFetched(true)
  }

  function onSubmit(e) {
    e.preventDefault()

    if (!value) {
      setError(I18n.get('Please enter a valid ID'))
      return
    }
    fetchDeviceOrZone(type, value)
  }

  return (
    <FlexV2
      direction='column'
      axisGap={300}
      styles={{ flexGrow: 1 }}
      alignCrossAxis='center'
      alignMainAxis='center'
    >
      <FlexV2 className={styles.container} direction='column' axisGap={300}>
        <Text tone={900} size={400}>
          {I18n.get('Find Dashboard')}
        </Text>

        <Text variant='page' tone={900} size={200}>
          {I18n.get('Select either Device or Location and enter its ID')}
        </Text>

        <LineSeparator />
        <Loader isLoading={isLoading}>
          <FlexV2 direction='column' axisGap={400}>
            <FlexV2 axisGap={300}>
              <FlexV2 direction='column' axisGap={300}>
                <Text variant='page' tone={700} size={100}>
                  {strings[type]} {I18n.get('ID')}
                </Text>
                <Input name={type} value={value} onChange={onChangeValue} />
              </FlexV2>
              <FlexV2 direction='column' axisGap={300}>
                <Text variant='page' tone={700} size={100}>
                  {I18n.get('Search Type')}
                </Text>
                <Select
                  className={styles.select}
                  name='type'
                  value={type}
                  options={SEARCH_OPTIONS()}
                  onChange={onChangeType}
                />
              </FlexV2>
            </FlexV2>
            <Button variant='primary' onClick={onSubmit}>
              {I18n.get('Find Dashboard')}
            </Button>
          </FlexV2>
        </Loader>
        {error && (
          <Box className={styles.errorContainer}>
            <Text tone={500} size={200}>
              {error}
            </Text>
          </Box>
        )}
      </FlexV2>
    </FlexV2>
  )
}

import { I18n } from 'aws-amplify'

import { widgetRegistry } from '@/components/DashboardPageV2/Desktop/Widgets'
import { TOOLBOX_TYPES, ToolboxItem } from './utils'

/*
  SECTION
*/

export const environmentSection = () =>
  new ToolboxItem({
    id: 'environmentSection',
    type: TOOLBOX_TYPES.section,
    title: I18n.get('Environment'),
    icon: 'air',
    items: [
      widgetRegistry.get('temperature'),
      widgetRegistry.get('humidity'),
      widgetRegistry.get('pressure'),
      widgetRegistry.get('carbonDioxide'),
      widgetRegistry.get('airflow'),
      widgetRegistry.get('uvIndex'),
      widgetRegistry.get('absoluteHumidity'),
      widgetRegistry.get('par'),
      widgetRegistry.get('solarIrradiance'),
      widgetRegistry.get('dailyLightIntegral'),
      widgetRegistry.get('saturationVapourPressure'),
      widgetRegistry.get('radiationTemperatureRatio'),
      widgetRegistry.get('vapourPressureDeficit'),
      widgetRegistry.get('weather')
    ]
  })

import React, { useEffect } from 'react'

import { Collapse, Slot, Text, FlexV2, Label } from '@/primitives'
import Select from '@/elements/Select'

import {
  getZonesHierarchy,
  getMeasurements,
  getDeviceTypes,
  getZonesHierarchyLoading,
  getNavigationLocationSelector
} from '@/reducers/selectors'

import { useDataboard } from '../context'
import { DATABOARD_ACTIONS } from '../state'
import { COMPARISON_TYPES } from '../Utils/config'

import { getDataboardAvailableMeasurements, Strings } from './utils'
import ComparisonSelector from './ComparisonSelector'

export default function CollapseGeneral({ isAboveBreakpoint = true }) {
  const strings = Strings()
  const { state, dispatchState } = useDataboard()
  
  const locationSelector = getNavigationLocationSelector()

  const { id: organizationId } = locationSelector?.get('organization') ?? {}

  const zonesHierarchy = getZonesHierarchy()
  const zonesHierarchyLoading = getZonesHierarchyLoading()
  const allMeasurements = getMeasurements()
  const allDeviceTypes = getDeviceTypes()

  useEffect(() => {
    let zoneFilters = state.zoneFilters

    let interval =
      state.comparisonType === COMPARISON_TYPES.LOCATION_RANGES
        ? state.sidebarFilter.dateRanges.timeInterval
        : state.dateFilters[0].timeInterval

    if (state.comparisonType === COMPARISON_TYPES.DATE_RANGES) {
      zoneFilters = [state.sidebarFilter[COMPARISON_TYPES.LOCATION_RANGES]]
    }

    const sortedAvailableMeasurements = getDataboardAvailableMeasurements(
      zoneFilters,
      zonesHierarchy,
      allDeviceTypes,
      allMeasurements,
      organizationId,
      interval
    )

    dispatchState({
      type: DATABOARD_ACTIONS.SET_AVAILABLE_MEASUREMENTS,
      availableMeasurements: sortedAvailableMeasurements
    })
  }, [
    zonesHierarchy,
    allMeasurements,
    state.comparisonType,
    organizationId,
    zonesHierarchyLoading,
    allDeviceTypes,
    dispatchState,
    state.dateFilters,
    state.sidebarFilter,
    state.zoneFilters
  ])

  const onSelectMeasurement = measurements => {
    if (measurements.length < 5) {
      const selectedMeasurements = measurements.map(({ value }) => value)
      dispatchState({
        type: DATABOARD_ACTIONS.SET_MEASUREMENTS,
        measurements: selectedMeasurements
      })
    }
  }

  return (
    <Collapse defaultOpen={isAboveBreakpoint}>
      <Slot name='trigger'>
        <Text variant={'page'} tone={900} fontWeight={400}>
          {strings.general}
        </Text>
      </Slot>
      <Slot name='content'>
        <FlexV2
          direction={'column'}
          className={'Databoard__Sidebar__Content'}
          wrap='nowrap'
          axisGap={400}
        >
          <ComparisonSelector />
          <Label>
            <Text variant={'page'} tone={900} size={100} fontWeight={300}>
              {strings.measurements}
            </Text>
            <Select
              isMulti={true}
              isSearchable={true}
              value={state?.measurements[state.comparisonType]}
              options={state.availableMeasurements.map(({ id, shortName }) => ({
                value: id,
                label: shortName
              }))}
              onChange={onSelectMeasurement}
              placeholder={strings.selectMeasurements}
            />
          </Label>
        </FlexV2>
      </Slot>
    </Collapse>
  )
}

export function addDeviceToHierarchy(device, hierarchy) {
  const zoneIds = Object.keys(hierarchy)
  const zonePath = device.zonePath
  const hasParentPathIndex = zoneIds.findIndex(
    zoneId => hierarchy[zoneId]?.parentPath === zonePath
  )
  const includesParentPathIndex = zoneIds.findIndex(zoneId =>
    zonePath.includes(hierarchy[zoneId]?.parentPath)
  )

  if (hasParentPathIndex > -1) {
    const zoneId = zoneIds[hasParentPathIndex]
    const deviceIndex = hierarchy[zoneId].devices.findIndex(
      ({ id }) => id === device.id
    )
    if (deviceIndex === -1) {
      hierarchy[zoneId].devices.push(device)
    }
  } else if (includesParentPathIndex > -1) {
    const zoneId = zoneIds[includesParentPathIndex]
    hierarchy[zoneId].children = addDeviceToHierarchy(
      device,
      hierarchy[zoneId]?.children
    )
  }

  return hierarchy
}

// this adds the device to the hierarchy in-place. I still use return to collapse the stack
export const moverAddDeviceToHierarchy = (
  device,
  hierarchy,
  devicePath,
  idx = 0
) => {
  if (!devicePath) {
    hierarchy.devices.push(device)
    return hierarchy
  }

  const [currentSegment, ...remainingPath] =
    devicePath[0] === '/'
      ? devicePath.split('/').slice(1)
      : devicePath.split('/')

  const nextNode =
    idx === 0 ? hierarchy[currentSegment] : hierarchy.children[currentSegment]

  return moverAddDeviceToHierarchy(
    device,
    nextNode,
    remainingPath.join('/'),
    idx + 1
  )
}

export const moverRemoveDeviceFromHierarchy = (
  device,
  hierarchy,
  devicePath,
  idx = 0
) => {
  if (!devicePath) {
    hierarchy.devices = hierarchy.devices.filter(d => d.id !== device.id)
    return hierarchy
  }

  const [currentSegment, ...remainingPath] =
    devicePath[0] === '/'
      ? devicePath.split('/').slice(1)
      : devicePath.split('/')

  const nextNode =
    idx === 0 ? hierarchy[currentSegment] : hierarchy.children[currentSegment]

  return moverRemoveDeviceFromHierarchy(
    device,
    nextNode,
    remainingPath.join('/'),
    idx + 1
  )
}

export function stackHierarchyPaths(
  hierarchy,
  path,
  zone,
  allowInactive = false
) {
  if (allowInactive || zone.status === 'active') {
    if (path.length === 1 && !hierarchy[path[0]]) {
      hierarchy[path[0]] = { ...zone, children: {}, devices: [] }
    }
    if (hierarchy[path[0]]) {
      let children = hierarchy[path[0]].children
      Object.keys(children).forEach(zoneId => {
        if (!allowInactive && children[zoneId].status !== 'active') {
          delete children[zoneId]
        }
      })
      const nextPath = path.slice(1)
      if (nextPath.length > 0) {
        hierarchy[path[0]].children = stackHierarchyPaths(
          children,
          nextPath,
          zone,
          allowInactive
        )
      }
    }
  }
  return hierarchy
}

export function findIndexDashboardInArray(dashboard, dashboards) {
  return dashboards.findIndex(d => d.dashboardId === dashboard.dashboardId)
}

import { makeOperationsApiCall } from './utils'

export const getFertilizerPurchases = async params => {
  try {
    const query = /* GraphQL */ `
      query getFertilizerPurchases(
        $filter: GetFertilizerPurchasesFilter
        $order: OrderEnum
        $offset: Int
        $limit: Int
      ) {
        getFertilizerPurchases(
          filter: $filter
          order: $order
          offset: $offset
          limit: $limit
        ) {
          fertilizerPurchases {
            id
            organizationId
            storageFacility {
              id
              name
            }
            fertilizerId
            supplier {
              id
              name
            }
            currency {
              id
              code
            }
            quantity
            unitWeight
            unitPrice
            tax
            orderDate
            receivedDate
            status
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getFertilizerPurchases',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getFertilizerPurchase = async params => {
  try {
    const query = /* GraphQL */ `
      query getFertilizerPurchase($fertilizerPurchaseId: String!) {
        getFertilizerPurchase(fertilizerPurchaseId: $fertilizerPurchaseId) {
          id
          organizationId
          storageFacilityId
          fertilizerId
          supplierId
          currencyId
          unitPrice
          unitWeight
          tax
          quantity
          orderDate
          receivedDate
          status
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getFertilizerPurchase',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const createFertilizerPurchase = async params => {
  try {
    const query = /* GraphQL */ `
      mutation createFertilizerPurchase(
        $organizationId: String!
        $storageFacilityId: String!
        $fertilizerId: String!
        $supplierId: String!
        $currencyId: String!
        $unitPrice: Float!
        $unitWeight: Float!
        $tax: Float
        $quantity: Int!
        $orderDate: GraphQLTimestamp!
        $receivedDate: GraphQLTimestamp
        $status: FertilizerPurchaseStatus!
      ) {
        createFertilizerPurchase(
          organizationId: $organizationId
          storageFacilityId: $storageFacilityId
          fertilizerId: $fertilizerId
          supplierId: $supplierId
          currencyId: $currencyId
          unitWeight: $unitWeight
          unitPrice: $unitPrice
          tax: $tax
          quantity: $quantity
          orderDate: $orderDate
          receivedDate: $receivedDate
          status: $status
        ) {
          id
          organizationId
          storageFacility {
            id
            name
          }
          fertilizerId
          supplier {
            id
            name
          }
          currency {
            id
            code
          }
          quantity
          unitWeight
          unitPrice
          tax
          orderDate
          receivedDate
          status
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'createFertilizerPurchase',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updateFertilizerPurchase = async params => {
  try {
    const query = /* GraphQL */ `
      mutation updateFertilizerPurchase(
        $fertilizerPurchaseId: String!
        $organizationId: String!
        $storageFacilityId: String!
        $fertilizerId: String!
        $supplierId: String!
        $currencyId: String!
        $unitPrice: Float!
        $unitWeight: Float!
        $tax: Float
        $quantity: Int!
        $orderDate: GraphQLTimestamp!
        $receivedDate: GraphQLTimestamp
        $status: FertilizerPurchaseStatus!
      ) {
        updateFertilizerPurchase(
          fertilizerPurchaseId: $fertilizerPurchaseId
          organizationId: $organizationId
          storageFacilityId: $storageFacilityId
          fertilizerId: $fertilizerId
          unitWeight: $unitWeight
          supplierId: $supplierId
          currencyId: $currencyId
          unitPrice: $unitPrice
          tax: $tax
          quantity: $quantity
          orderDate: $orderDate
          receivedDate: $receivedDate
          status: $status
        ) {
          id
          organizationId
          storageFacility {
            id
            name
          }
          fertilizerId
          supplier {
            id
            name
          }
          currency {
            id
            code
          }
          quantity
          unitWeight
          unitPrice
          tax
          orderDate
          receivedDate
          status
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'updateFertilizerPurchase',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

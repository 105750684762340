import { nanoid } from 'nanoid'
import { all, call, put, takeLatest } from 'redux-saga/effects'

import {
  getSuppliers,
  getSupplier,
  createSupplier,
  updateSupplier
} from '@/api/operations/supplier'

import {
  REQUEST_SUPPLIERS,
  REQUEST_SUPPLIER,
  CREATE_SUPPLIER,
  UPDATE_SUPPLIER,
  receiveSuppliers,
  receiveSupplier,
  supplierCreated,
  supplierUpdated
} from '@/actions/operations/supplier'

import { displayBanner } from '../utils'

import Strings from './Strings'

const strings = Strings()

function* sendCreateSupplier(action) {
  const {
    uploadedKeys,
    params,
    error: uploadError
  } = yield uploadDocuments(action.params)

  let supplierData = {}
  let error = uploadError ?? null

  if (!uploadError) {
    const { data, error: apiError = null } = yield call(createSupplier, params)
    error = apiError ?? null
    supplierData = data ?? {}
  }

  if (error && uploadedKeys.length > 0) {
    yield deleteDocuments(uploadedKeys)
  }

  yield put(displayBanner(error, strings.supplierCreated))
  yield put(supplierCreated(supplierData, error))
}

export function* watchCreateSupplier() {
  yield takeLatest(CREATE_SUPPLIER, sendCreateSupplier)
}

function* sendUpdateSupplier(action) {
  const {
    uploadedKeys,
    params,
    error: uploadError
  } = yield uploadDocuments(action.params)

  let supplierData = {}
  let error = uploadError ?? null

  if (!uploadError) {
    const { data, error: apiError } = yield call(updateSupplier, params)
    if (!apiError && params.s3KeysToDelete.length > 0) {
      yield deleteDocuments(params.s3KeysToDelete)
    }
    error = apiError ?? null
    supplierData = data ?? {}
  }

  if (error && uploadedKeys.length > 0) {
    yield deleteDocuments(uploadedKeys)
  }

  yield put(displayBanner(error, strings.supplierUpdated))
  yield put(supplierUpdated(supplierData, error))
}

export function* watchUpdateSupplier() {
  yield takeLatest(UPDATE_SUPPLIER, sendUpdateSupplier)
}

function* sendRequestSuppliers(action) {
  const { data, error = null } = yield call(getSuppliers, action.params)
  const { suppliers = [], count = 0 } = data
  yield put(receiveSuppliers(suppliers, count, error))
}

export function* watchRequestSuppliers() {
  yield takeLatest(REQUEST_SUPPLIERS, sendRequestSuppliers)
}

function* sendRequestSupplier(action) {
  let { data, error = null } = yield call(getSupplier, action.params)

  data.bankDetailsDocument = yield createFileObject(data.bankDetailsS3Key)
  delete data.bankDetailsS3Key

  data.contractDocument = yield createFileObject(data.contractS3Key)
  delete data.contractS3Key

  yield put(receiveSupplier(data, error))
}

export function* watchRequestSupplier() {
  yield takeLatest(REQUEST_SUPPLIER, sendRequestSupplier)
}

const createFileObject = async s3Key => {
  return {
    file: {},
    url: s3Key ? await Storage.get(s3Key) : '',
    s3Key: s3Key ?? ''
  }
}

const uploadDocument = async (documentName, document, supplierName) => {
  let docCopy = { ...document }
  if (docCopy?.file?.name) {
    docCopy.s3Key = generateS3Key(documentName, supplierName)
    await Storage.put(docCopy.s3Key, docCopy.file, {
      contentType: 'application/pdf'
    })
  }
  return docCopy.s3Key ?? ''
}

const uploadDocuments = async params => {
  let uploadedKeys = []
  try {
    let paramsCopy = { ...params }
    let { bankDetailsDocument, contractDocument } = paramsCopy
    // Bank Details Document
    paramsCopy.bankDetailsS3Key = await uploadDocument(
      'bankDetails',
      bankDetailsDocument,
      paramsCopy.name
    )
    if (paramsCopy.bankDetailsS3Key && bankDetailsDocument?.file?.name) {
      uploadedKeys.push(paramsCopy.bankDetailsS3Key)
    }
    // Contract Document
    paramsCopy.contractS3Key = await uploadDocument(
      'contract',
      contractDocument,
      paramsCopy.name
    )
    if (paramsCopy.contractS3Key && contractDocument?.file?.name) {
      uploadedKeys.push(paramsCopy.contractS3Key)
    }

    return { uploadedKeys, params: paramsCopy, error: null }
  } catch (error) {
    return { uploadedKeys, params, error: null }
  }
}

const generateS3Key = (type, name) => {
  if (!type || !name) {
    throw Error('generateS3Key needs both type & name')
  }
  const cleanName = name.replace(/\s+/g, '-').toLowerCase()
  return `supplier/${cleanName}_${type}_${nanoid()}.pdf`
}

const deleteDocuments = keys =>
  all(keys.map(key => call(async () => await Storage.remove(key))))

import { useLocation, Route, Switch } from 'react-router-dom'

import { Flex } from '../../../primitives'

import routes from './routes'

function OperationsAdmin() {
  const location = useLocation()

  return (
    <Flex direction='column'>
      <Switch location={location}>
        {routes.map(({ path, Component }) => (
          <Route key={path} path={path}>
            <Component />
          </Route>
        ))}
      </Switch>
    </Flex>
  )
}

export default OperationsAdmin

import {
  createSensorThresholdUserAck,
  getSensorThresholdUserAck,
  getSensorThresholdUserAcks
} from '@/api/threshold/sensorThresholdUserAck'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const fetchSensorThresholdUserAck = createAsyncThunk(
  'fetchSensorThresholdUserAck',
  async params => {
    const { rootId, isZoneView, ...payload } = params

    const acknowledgement = await getSensorThresholdUserAck(payload)
    if (!acknowledgement && isZoneView) {
      payload.rootId = rootId
      const response = await createSensorThresholdUserAck(payload)
      return response
    }

    return acknowledgement
  }
)

export const fetchSensorThresholdUserAcks = createAsyncThunk(
  'fetchSensorThresholdUserAcks',
  async params => {
    return await getSensorThresholdUserAcks(params)
  }
)

const sensorThresholdUserAck = createSlice({
  name: 'sensorThresholdUserAckReducer',
  initialState: {
    notificationAcks: [],
    isLoading: false,
    error: null
  },
  reducers: {
    cleanSensorThresholdUserAck: (state, action) => ({
      ...state,
      notificationAcks: [],
      error: null
    })
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSensorThresholdUserAck.pending, (state, action) => ({
        ...state,
        notificationAcks: state.notificationAcks,
        isLoading: true,
        error: null
      }))
      .addCase(fetchSensorThresholdUserAck.fulfilled, (state, action) => {
        const hasAcknowledgement = !action.error && action.payload
        state.notificationAcks = hasAcknowledgement
          ? [...state.notificationAcks, action.payload]
          : state.notificationAcks
        state.isLoading = false
      })
      .addCase(fetchSensorThresholdUserAck.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error
      })
      .addCase(fetchSensorThresholdUserAcks.pending, (state, action) => ({
        ...state,
        notificationAcks: [],
        isLoading: true,
        error: null
      }))
      .addCase(fetchSensorThresholdUserAcks.fulfilled, (state, action) => {
        state.notificationAcks = action.payload
        state.isLoading = false
      })
      .addCase(fetchSensorThresholdUserAcks.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error
      })
  }
})

export const { cleanSensorThresholdUserAck } = sensorThresholdUserAck.actions
export default sensorThresholdUserAck.reducer

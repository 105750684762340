import { makeOperationsApiCall } from './utils'

export const getUnitPrices = async params => {
  try {
    const query = /* GraphQL */ `
      query getUnitPrices(
        $filter: GetUnitPricesFilter
        $order: OrderEnum
        $offset: Int
        $limit: Int
      ) {
        getUnitPrices(
          filter: $filter
          order: $order
          offset: $offset
          limit: $limit
        ) {
          unitPrices {
            id
            organizationId
            customer {
              name
            }
            product {
              id
              name
              weight
            }
            currency {
              code
            }
            unitPrice
            discount
            sellingPrice
            articleCode
            description
            alternateDescription
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getUnitPrices',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getUnitPrice = async params => {
  try {
    const query = /* GraphQL */ `
      query getUnitPrice($unitPriceId: String!) {
        getUnitPrice(unitPriceId: $unitPriceId) {
          id
          organizationId
          customerId
          productId
          currencyId
          unitPrice
          discount
          sellingPrice
          articleCode
          description
          alternateDescription
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getUnitPrice',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const createUnitPrice = async params => {
  try {
    const query = /* GraphQL */ `
      mutation createUnitPrice(
        $organizationId: String!
        $customerId: String!
        $productId: String!
        $currencyId: String!
        $unitPrice: Float!
        $discount: Float!
        $sellingPrice: Float!
        $articleCode: String!
        $description: String!
        $alternateDescription: String!
      ) {
        createUnitPrice(
          organizationId: $organizationId
          customerId: $customerId
          productId: $productId
          currencyId: $currencyId
          unitPrice: $unitPrice
          discount: $discount
          sellingPrice: $sellingPrice
          articleCode: $articleCode
          description: $description
          alternateDescription: $alternateDescription
        ) {
          id
          organizationId
          customer {
            name
          }
          product {
            id
            name
            weight
          }
          currency {
            code
          }
          unitPrice
          discount
          sellingPrice
          articleCode
          description
          alternateDescription
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'createUnitPrice',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updateUnitPrice = async params => {
  try {
    const query = /* GraphQL */ `
      mutation updateUnitPrice(
        $unitPriceId: String!
        $organizationId: String!
        $customerId: String!
        $productId: String!
        $currencyId: String!
        $unitPrice: Float!
        $discount: Float!
        $sellingPrice: Float!
        $articleCode: String!
        $description: String!
        $alternateDescription: String!
      ) {
        updateUnitPrice(
          unitPriceId: $unitPriceId
          organizationId: $organizationId
          customerId: $customerId
          productId: $productId
          currencyId: $currencyId
          unitPrice: $unitPrice
          discount: $discount
          sellingPrice: $sellingPrice
          articleCode: $articleCode
          description: $description
          alternateDescription: $alternateDescription
        ) {
          id
          organizationId
          customer {
            name
          }
          product {
            id
            name
            weight
          }
          currency {
            code
          }
          unitPrice
          discount
          sellingPrice
          articleCode
          description
          alternateDescription
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'updateUnitPrice',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const deleteUnitPrice = async params => {
  try {
    const query = /* GraphQL */ `
      mutation deleteUnitPrice($unitPriceId: String!) {
        deleteUnitPrice(unitPriceId: $unitPriceId) {
          id
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'deleteUnitPrice',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

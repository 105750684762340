import { I18n } from 'aws-amplify'

const Strings = () => ({
  signInTitle: I18n.get('Sign in to SecondSky Data'),
  usernamePlaceholder: I18n.get('Username or email'),
  passwordPlaceholder: I18n.get('Password'),
  signInBtn: I18n.get('Sign In'),
  errorHeader: I18n.get('An error ocurred'),
  forgotPasswordLink: I18n.get('Forgot Password?'),
  forgotPasswordTitle: I18n.get('Forgot password'),
  username: I18n.get('Username or email'),
  code: I18n.get('Code'),
  newPassword: I18n.get('New password'),
  reEnterNewPassword: I18n.get('Re-enter new password'),
  passwordPolicy: I18n.get('Your password must contain at least eight characters including one symbol, one capital letter and one number.'),
  submitBtn: I18n.get('Submit'),
  verifyBtn: I18n.get('Verify'),
  changePasswordBtn: I18n.get('Change password'),
  verifyEmail: I18n.get('The email address associated with your account is not yet verified:'),
  verificationCodeMsg: I18n.get('A verification code has been sent to your email address.'),
  passwordResetComplete: I18n.get('Password reset complete.'),
  backSignIn: I18n.get('Back to Sign In'),
  skipVerification: I18n.get('Skip'),
  requireNewPasswordTitle: I18n.get('Change password'),
  verifyAccountTitle: I18n.get('Verify your account'),
  confirmSignInTitle: I18n.get('Multi-factor authentication'),
  confirmSignInMessage: I18n.get('Open your authenticator app to view the authentication code, then enter the code into the field below.'),
  passwordsNoMatch: I18n.get('Passwords do not match'),
  usernameNotFound: I18n.get('User not found'),
  usernameTempPasswordError: I18n.get('Please first use the temporary password you received email.'),
  incorrectLogin: I18n.get('You have entered an invalid username/email or password'),
  signUpIntro: I18n.get('Don\'t have an account?'),
  signUpLink: I18n.get('Register here.'),
  signUpTitle: I18n.get('Create an account')
})

export default Strings

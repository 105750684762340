import { makeActionCreator } from '@/Util/ReduxUtils'

export const CLEAR_INVENTORIES = 'CLEAR_INVENTORIES'
export const clearInventories = makeActionCreator(CLEAR_INVENTORIES)

export const CLEAR_INVENTORY = 'CLEAR_INVENTORY'
export const clearInventory = makeActionCreator(CLEAR_INVENTORY)

export const REQUEST_INVENTORIES = 'REQUEST_INVENTORIES'
export const requestInventories = makeActionCreator(
  REQUEST_INVENTORIES,
  'params'
)

export const RECEIVE_INVENTORIES = 'RECEIVE_INVENTORIES'
export const receiveInventories = makeActionCreator(
  RECEIVE_INVENTORIES,
  'inventories',
  'count',
  'error'
)

export const REQUEST_INVENTORY = 'REQUEST_INVENTORY'
export const requestInventory = makeActionCreator(REQUEST_INVENTORY, 'params')

export const RECEIVE_INVENTORY = 'RECEIVE_INVENTORY'
export const receiveInventory = makeActionCreator(
  RECEIVE_INVENTORY,
  'inventory',
  'error'
)

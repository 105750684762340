import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { I18n } from 'aws-amplify'

import { showBanner } from '../util'
import {
  getFyiTooltips,
  getTextFromPrompt,
  createHelpText,
  updateHelpText,
  getHelpTextsByLang,
  redoHelpTextTranslations
} from '@/api/fyi/helpText'

const feedbackStrings = {
  fetchFyiTooltips: {
    error: I18n.get('Could not fetch help text entries')
  },
  generateHelpTextTranslations: {
    success: I18n.get('Translations requested'),
    error: I18n.get('Could not generate translations')
  },
  createTooltipHelpText: {
    success: I18n.get('Help text entry created'),
    error: I18n.get('Could not create help text entry')
  },
  updateTooltipHelpText: {
    success: I18n.get('Help text updated'),
    error: I18n.get('Could not update help text')
  },
  generateTextFromPrompt: {
    error: I18n.get('Text could not be generated from prompt')
  }
}

function dispatchFeedback(dispatch, error, context) {
  const strings = feedbackStrings[context]
  const type = error ? 'error' : 'success'
  const message = error ? strings.error : strings.success

  dispatch(
    showBanner({
      type,
      show: true,
      message
    })
  )
}

export const fetchFyiTooltips = createAsyncThunk(
  'fetchFyiTooltips',
  async (params, { dispatch }) => {
    const response = await getFyiTooltips()
    if (response?.error) {
      dispatchFeedback(dispatch, response.error, 'fetchFyiTooltips')
    }
    return response
  }
)

export const fetchHelpTextsByLang = createAsyncThunk(
  'fetchHelpTextsByLang',
  async params => {
    return await getHelpTextsByLang(params)
  }
)

export const generateHelpTextTranslations = createAsyncThunk(
  'generateHelpTextTranslations',
  async (params, { dispatch }) => {
    const response = await redoHelpTextTranslations(params)
    dispatchFeedback(dispatch, response?.error, 'generateHelpTextTranslations')
    return response
  }
)

export const createTooltipHelpText = createAsyncThunk(
  'createTooltipHelpText',
  async (params, { dispatch }) => {
    const response = await createHelpText(params)
    dispatchFeedback(dispatch, response?.error, 'createTooltipHelpText')
    return response
  }
)

export const updateTooltipHelpText = createAsyncThunk(
  'updateTooltipHelpText',
  async (params, { dispatch }) => {
    const response = await updateHelpText(params)
    dispatchFeedback(dispatch, response?.error, 'updateTooltipHelpText')
    return response
  }
)

export const generateTextFromPrompt = createAsyncThunk(
  'generateTextFromPrompt',
  async (params, { dispatch }) => {
    const response = await getTextFromPrompt(params)
    if (response?.error) {
      dispatchFeedback(dispatch, response?.error, 'generateTextFromPrompt')
    }
    return response
  }
)

const fyiSlice = createSlice({
  name: 'fyiHelpTextReducer',
  initialState: {
    tooltipsHelpText: [],
    tooltips: [],
    tooltipUpserted: {},
    tooltipsRequested: false,
    generatedText: '',
    loadingGeneratedText: false,
    error: null,
    loading: false,
    translationRequested: null
  },
  reducers: {
    cleanGeneratedText: (state, action) => {
      state.generatedText = ''
      state.loadingGeneratedText = false
    },
    cleanHelpText: (state, action) => {
      state.error = null
      state.tooltipUpserted = {}
      state.translationRequested = null
    },
    updateTranslations: (state, action) => {
      const {
        helpTextId,
        titleTranslations = [],
        textTranslations = []
      } = action?.payload
      const tooltips = [...state.tooltips]
      const itemIndex = tooltips.findIndex(
        tooltip => tooltip.helpTextId === helpTextId
      )

      tooltips[itemIndex]['titleTranslations'] = titleTranslations
      tooltips[itemIndex]['textTranslations'] = textTranslations
      state.tooltips = tooltips
    }
  },
  extraReducers: builder => {
    builder
      .addCase(createTooltipHelpText.pending, state => {
        state.error = null
        state.loading = true
      })
      .addCase(createTooltipHelpText.fulfilled, (state, action) => {
        if (!action?.payload?.error) {
          state.tooltips = [...state.tooltips, action?.payload?.helpText]
          state.tooltipUpserted = action?.payload?.helpText
        }
        state.error = action?.payload?.error ?? null
        state.loading = false
      })
      .addCase(updateTooltipHelpText.pending, state => {
        state.error = null
        state.loading = true
        // TODO: show banner when new translation is received
      })
      .addCase(updateTooltipHelpText.fulfilled, (state, action) => {
        if (!action?.payload?.error) {
          const { helpText } = action?.payload
          const tooltips = [...state.tooltips]
          const itemIndex = tooltips.findIndex(
            ({ helpTextId }) => helpTextId === helpText?.helpTextId
          )
          tooltips[itemIndex] = { ...tooltips[itemIndex], ...helpText }
          state.tooltips = tooltips
          state.tooltipUpserted = helpText
        }
        state.error = action?.payload?.error ?? null
        state.loading = false
      })
      .addCase(fetchFyiTooltips.pending, state => {
        state.error = null
        state.loading = true
        state.tooltipsRequested = true
      })
      .addCase(fetchFyiTooltips.fulfilled, (state, action) => {
        state.tooltips = action?.payload?.fyiTooltips
        state.error = action?.payload?.error ?? null
        state.loading = false
      })
      .addCase(fetchHelpTextsByLang.pending, state => {
        state.error = null
      })
      .addCase(fetchHelpTextsByLang.fulfilled, (state, action) => {
        state.tooltipsHelpText = action?.payload?.helpTextsByLang
        state.error = action?.payload?.error ?? null
      })
      .addCase(generateTextFromPrompt.pending, state => {
        state.error = null
        state.loadingGeneratedText = true
      })
      .addCase(generateTextFromPrompt.fulfilled, (state, action) => {
        const generatedText = action?.payload?.generatedText?.outputText
          ?.replace('\n', '')
          .trim()
        state.generatedText = generatedText
        state.error = action?.payload?.error ?? null
        state.loadingGeneratedText = false
      })
      .addCase(generateHelpTextTranslations.pending, state => {
        state.error = null
      })
      .addCase(generateHelpTextTranslations.fulfilled, (state, action) => {
        if (!action?.payload?.error) {
          const { helpTextTranslations = {} } = action?.payload
          const tooltips = [...state.tooltips]
          const itemIndex = tooltips.findIndex(
            ({ helpTextId }) => helpTextId === helpTextTranslations?.helpTextId
          )
          tooltips[itemIndex] = {
            ...tooltips[itemIndex],
            ...helpTextTranslations
          }
          state.tooltips = tooltips
          state.translationRequested = helpTextTranslations?.helpTextId
        }
        state.error = action?.payload?.error ?? null
        state.loading = false
      })
  }
})

const { actions, reducer } = fyiSlice

export const { cleanGeneratedText, cleanHelpText, updateTranslations } = actions

export default reducer

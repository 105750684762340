import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Slot, Text, Box, Label, Flex, Input, Button } from '@/primitives'
import { Dialog, LineSeparator } from '@/elements'

import { sendCreateTag, sendUpdateTag } from '@/slices/fyi/tags'
import { getFyiTagsLoading } from '@/reducers/selectors'

import Strings from '../../Strings'

const strings = Strings()

const TagUpsertForm = ({ showModal, setHideForm, tagToEdit }) => {
  const [name, setName] = useState('')
  const dispatch = useDispatch()
  const loading = getFyiTagsLoading()

  const isEditView = tagToEdit?.hasOwnProperty('tagId')

  useEffect(() => {
    if (tagToEdit?.name?.length > 0) {
      setName(tagToEdit?.name)
    }
  }, [tagToEdit?.name])

  useEffect(() => {
    if (!showModal) setName('')
  }, [showModal])

  function onChangeInput({ target }) {
    const { value } = target
    setName(value)
  }

  function onSubmit() {
    // TODO validate name
    if (!isEditView) dispatch(sendCreateTag({ name }))
    if (isEditView) dispatch(sendUpdateTag({ tagId: tagToEdit?.tagId, name }))
  }

  return (
    <Dialog open={showModal} onOpenChange={setHideForm}>
      <Slot name='title'>
        <Text size={300} fontWeight={600}>
          {isEditView ? strings.editTag : strings.addTag}
        </Text>
      </Slot>
      <Slot name='content'>
        <Text size={200} as='p' variant='page'>
          {isEditView
            ? strings.editTagModalSubheading
            : strings.addTagModalSubheading}
        </Text>
        <Box>
          <LineSeparator />
        </Box>
        <Label>
          <Text>{strings.name}</Text>
          <Input name='name' onChange={onChangeInput} value={name} />
        </Label>
      </Slot>
      <Slot name='actions'>
        <Flex alignMainAxis='space-between'>
          <Button size='small' variant='error' onClick={setHideForm}>
            {strings.cancel}
          </Button>
          <Button
            size='small'
            variant='primary'
            onClick={onSubmit}
            disabled={loading}
          >
            {strings.submit}
          </Button>
        </Flex>
      </Slot>
    </Dialog>
  )
}

export default TagUpsertForm

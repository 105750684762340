import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { makeGraphqlRequestV2 } from '../utils'
/*
 * Queries
 */

export const getLinkdUserIdWhatsApp = async params => {
  try {
    const query = /* GraphQL */ `
      query getLinkdUserIdWhatsApp($waId: String!) {
        getLinkdUserIdWhatsApp(waId: $waId) {
          waId
          userId
          verified
        }
      }
    `

    const operation = graphqlOperation(query, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.getLinkdUserIdWhatsApp
  } catch (err) {
    throw err.message
  }
}

/*
 * Mutations
 */

export const linkUserIdToWhatsApp = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation linkUserIdToWhatsApp($previousWaId: String!, $newWaId: String!) {
        linkUserIdToWhatsApp(previousWaId: $previousWaId, newWaId: $newWaId) {
          waId
          userId
          verified
        }
      }
    `

    const operation = graphqlOperation(mutation, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.linkUserIdToWhatsApp
  } catch (err) {
    throw err.message
  }
}

export const unlinkUserIdFromWhatsApp = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation unlinkUserIdFromWhatsApp($waId: String!) {
        unlinkUserIdFromWhatsApp(waId: $waId) {
          waId
          userId
          verified
        }
      }
    `

    const operation = graphqlOperation(mutation, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.unlinkUserIdFromWhatsApp
  } catch (err) {
    throw err.message
  }
}

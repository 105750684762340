import { DateTime } from 'luxon'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { requestAllShippingMethods } from '@/actions/operations/shippingMethod'
import { requestAllStorageFacilities } from '@/actions/operations/storageFacility'

import {
  getOperationsAllCurrencies,
  getOperationsAllStorageFacilities,
  getOperationsAllShippingMethods
} from '@/reducers/selectors'

import { Text, Table, Column, Row, Cell, Slot, Button } from '@/primitives'

import { SHIPMENT_STATUSES } from './utils'

import Strings from './Strings'

function SaleShipmentFormTable({
  sale,
  saleShipments,
  handleEdit,
  handleRemove
}) {
  const dispatch = useDispatch()

  const strings = Strings()

  const currencies = getOperationsAllCurrencies()
  const storageFacilities = getOperationsAllStorageFacilities()
  const shippingMethods = getOperationsAllShippingMethods()

  const shipmentStatuses = SHIPMENT_STATUSES(strings)

  useEffect(() => {
    dispatch(requestAllStorageFacilities())
    dispatch(requestAllShippingMethods())
  }, [dispatch])

  const getFieldValue = (id, items, field = 'name') => {
    const itemIndex = items.findIndex(i => i.id === id)
    if (itemIndex >= 0) {
      return items[itemIndex][field]
    }
    return null
  }

  const calculateValue = shipment => {
    return ((shipment.price / 100) * (100 - shipment.discount)).toFixed(2)
  }

  if (!saleShipments?.length) {
    return (
      <Text className={'Operations__Form__Empty'}>
        {strings.tableNoEntries}
      </Text>
    )
  }

  return (
    <Table className='Operations__Table' aria-colcount='4'>
      <Slot name='head'>
        <Row>
          <Column>{strings.tableHeaderDue}</Column>
          <Column>{strings.tableHeaderShipping}</Column>
          <Column>{strings.tableHeaderValue}</Column>
          <Column />
        </Row>
      </Slot>
      <Slot name='body'>
        {saleShipments?.map(saleShipment => (
          <Row key={saleShipment.id}>
            <Cell columnName={strings.tableHeaderDue}>
              {saleShipment.dueDate &&
                DateTime.fromJSDate(saleShipment.dueDate).toFormat('DD')}
              <span>{shipmentStatuses[saleShipment.status]}</span>
            </Cell>
            <Cell columnName={strings.tableHeaderShipping}>
              {getFieldValue(saleShipment.storageFacilityId, storageFacilities)}
              <span>
                {getFieldValue(saleShipment.shippingMethodId, shippingMethods)}
              </span>
            </Cell>
            <Cell columnName={strings.tableHeaderPrice}>
              {calculateValue(saleShipment)}{' '}
              {getFieldValue(sale.currencyId, currencies, 'code')}
              <span>+{saleShipment.tax}% tax</span>
            </Cell>
            <Cell className={'Operations__Table__Column__Actions'}>
              <Button
                variant='info'
                size='small'
                iconBefore='edit'
                onClick={() => handleEdit(saleShipment)}
              />
              <Button
                variant='error'
                size='small'
                iconBefore='delete'
                onClick={() => handleRemove(saleShipment)}
              />
            </Cell>
          </Row>
        ))}
      </Slot>
    </Table>
  )
}

export default SaleShipmentFormTable

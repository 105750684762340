import { Flex, Input } from '@/primitives'

const SearchBar = ({ searchText, isLoading, onChangeSearchText }) => {
  return (
    <Flex style={{ marginBottom: '1em' }}>
      <Input
        disabled={isLoading}
        name='searchManager'
        value={searchText}
        onChange={onChangeSearchText}
      />
    </Flex>
  )
}

export default SearchBar

import useDatasetData from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Hooks/useDatasetData'

import { useDashboard } from '../../../context'
import Scatterplot from '../../Shared/Dynamic/Chart/Scatterplot'
import { convertParToSumRadiation } from './utils'

export default function DatasetScatterplot({ unit, options, config }) {
  const { state } = useDashboard()
  const requiredMeasurements = ['par', 'temperature']

  const { datasetData, queryZones } = useDatasetData({
    measurements: requiredMeasurements,
    range: config.range,
    interval: config.interval,
    queryZones: [state.currentZone]
  })

  let chartData = {}

  if (datasetData) {
    for (let { zoneName } of queryZones) {
      const parData = datasetData?.['par']?.[zoneName] || []
      const temperatureData = datasetData?.['temperature']?.[zoneName] || []

      chartData[zoneName] = parData.reduce((result, parDataPoint) => {
        const sumRadiation = convertParToSumRadiation({ par: parDataPoint.y })

        if (sumRadiation > 0) {
          const temperatureDataPoint = temperatureData.find(
            tempDataPoint => tempDataPoint.x === parDataPoint.x
          )

          if (temperatureDataPoint) {
            result.push({
              x: Math.round(sumRadiation),
              y: temperatureDataPoint.y
            })
          }
        }

        return result
      }, [])
    }
  }

  return (
    <Scatterplot
      data={chartData}
      unit={unit}
      config={config}
      queryZones={queryZones}
    />
  )
}

import { Box, Text, Flex, Card, Slot } from '@/primitives'

function sortedByReleaseNoteSections(items) {
  return items.reduce((acc, { text, sectionId, section }) => {
    const sectionIndex = acc.findIndex(
      section => section.sectionId === sectionId
    )
    if (sectionIndex < 0) {
      acc.push({ items: [text], sectionName: section.name, sectionId })
    } else {
      acc[sectionIndex].items.push(text)
    }

    return acc
  }, [])
}

const ReleaseNoteCard = ({ releaseNote }) => {
  const { title, items, publishDate, tags } = releaseNote
  const sortedItems = sortedByReleaseNoteSections(items)
  const date = new Date(publishDate * 1000)

  return (
    <Card
      style={{
        marginTop: '1.5rem',
        maxWidth: '40rem',
        width: '100%',
        margin: '2rem auto'
      }}
    >
      <Slot name='header'>
        <Box>
          <Text as='h4' style={{ marginBottom: '0.2rem' }}>
            {title}
          </Text>
          <Text variant='page' tone={900} style={{ fontSize: '0.8rem' }}>
            {date.toDateString()}
          </Text>
        </Box>
        <Flex axisGap={200} style={{ marginTop: '0.5rem' }}>
          {tags.map(tag => (
            <Box
              key={tag}
              style={{
                backgroundColor: 'var(--ctx-theme-color-info-500)',
                borderRadius: '10px',
                padding: '0 0.8rem 0.2rem',
                lineHeight: '1rem'
              }}
            >
              <Text size={100}>{tag}</Text>
            </Box>
          ))}
        </Flex>
      </Slot>
      <Slot name='body'>
        <Flex direction='column' axisGap={500}>
          {sortedItems.map(({ sectionName, sectionId, items }) => (
            <Box key={sectionId}>
              <Text as='h6' style={{ margin: '0 0 0.7rem' }}>
                {sectionName}
              </Text>
              <ul style={{ margin: '0' }}>
                {items.map((text, index) => (
                  <li
                    style={{ listStyleType: 'disc' }}
                    key={`${sectionId}-text-${index}`}
                  >
                    <Text variant='page' tone={900}>
                      {text}
                    </Text>
                  </li>
                ))}
              </ul>
            </Box>
          ))}
        </Flex>
      </Slot>
    </Card>
  )
}

export default ReleaseNoteCard

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { getDeviceIsVerified } from '@/api/supplier/enrollment'

export const fetchIsDeviceVerified = createAsyncThunk(
  'fetchIsDeviceVerified',
  async params => {
    const response = await getDeviceIsVerified(params)
    return response.data
  }
)

const supplierEnrollmentSlice = createSlice({
  name: 'supplierEnrollmentReducer',
  initialState: {
    verified: false,
    enrolled: false,
    fetched: false,
    error: null
  },
  reducers: {
    deviceEnrolled(state, action) {
      state.verified = action.payload.verified
      state.enrolled = action.payload.enrolled
      state.fetched = true
      state.error = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchIsDeviceVerified.pending, (state, action) => {
        state.error = null
      })
      .addCase(fetchIsDeviceVerified.fulfilled, (state, action) => {
        state.verified = action?.payload?.verified ?? false
        state.enrolled = action?.payload?.enrolled ?? false
        state.fetched = true
        state.error = action.payload.error ?? null
      })
  }
})

const { actions, reducer } = supplierEnrollmentSlice

export const { deviceEnrolled } = actions

export default reducer

import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { getCognitoData, makeGraphqlRequest } from '../utils'

// Queries

export const timestreamWarmUp = async params => {
  try {
    const query = /* GraphQL */ `
      query warmUp($table: String!, $sensorId: String!) {
        warmUp(table: $table, sensorId: $sensorId) {
          rows
          columns
          types
        }
      }
    `

    const { id, idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken,
      id
    )

    if (error) {
      return {
        warmUp: null,
        error: `Failed to warm up Timestream: ${error}`
      }
    }

    return {
      warmUp: data?.warmUp,
      error: null
    }
  } catch (err) {
    return { warmUp: null, error: err.message }
  }
}

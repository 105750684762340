import { updateList } from '../util'

import {
  REQUEST_SUPPLIERS,
  RECEIVE_SUPPLIERS,
  REQUEST_SUPPLIER,
  RECEIVE_SUPPLIER,
  CREATE_SUPPLIER,
  SUPPLIER_CREATED,
  UPDATE_SUPPLIER,
  SUPPLIER_UPDATED
} from '@/actions/operations/supplier'

export const supplierInitialState = {
  suppliers: [],
  suppliersCount: 0,
  supplier: {},
  loading: false,
  error: null
}

const handlers = {
  [REQUEST_SUPPLIERS]: (state, action) => ({
    ...state,
    suppliers: [],
    suppliersCount: 0,
    error: null,
    loading: true
  }),
  [RECEIVE_SUPPLIERS]: (state, action) => ({
    ...state,
    suppliers: !action.error ? action.suppliers : [],
    suppliersCount: action?.count ?? 0,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_SUPPLIER]: state => ({
    ...state,
    supplier: {},
    error: null,
    loading: true
  }),
  [RECEIVE_SUPPLIER]: (state, action) => ({
    ...state,
    supplier: !action.error ? action.supplier : state.supplier,
    error: action.error ?? null,
    loading: false
  }),
  [CREATE_SUPPLIER]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [SUPPLIER_CREATED]: (state, action) => ({
    ...state,
    supplier: !action.error ? {} : state.supplier,
    suppliers: updateList(action.error, state.suppliers, action.supplier, true),
    suppliersCount: !action.error
      ? state.suppliersCount + 1
      : state.suppliersCount,
    error: action.error ?? null,
    loading: false
  }),
  [UPDATE_SUPPLIER]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [SUPPLIER_UPDATED]: (state, action) => ({
    ...state,
    supplier: !action.error ? {} : state.supplier,
    suppliers: updateList(
      action.error,
      state.suppliers,
      action.supplier,
      false
    ),
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

// https://medium.com/better-programming/write-cleaner-reducers-1c433a94a166
export function createReducer(handlers, initialState = {}) {
  return function (state = initialState, action) {
    const handler = handlers[action.type]
    if (!handler) {
      return state
    }

    const nextState = handler(state, action)
    return nextState
  }
}

// https://github.com/reduxjs/redux/blob/master/docs/recipes/ReducingBoilerplate.md
export function makeActionCreator(type, ...argNames) {
  return function (...args) {
    const action = { type }
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index]
    })

    return action
  }
}

import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import {
  getCognitoData,
  makeGraphqlRequest,
  makeGraphqlRequestV2
} from '../utils'

/*
 * Mutations
 */

export const startGreengrassCoreDeviceDefaultDeployment = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation startGreengrassCoreDeviceDefaultDeployment($id: String!) {
        startGreengrassCoreDeviceDefaultDeployment(id: $id) {
          message
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data } = await makeGraphqlRequest(GRAPHQL_URL, operation, idToken)

    if (!data?.startGreengrassCoreDeviceDefaultDeployment) {
      return {
        message: null,
        error: 'Unable to start default deployment for core device'
      }
    }

    return {
      message: data?.startGreengrassCoreDeviceDefaultDeployment,
      error: null
    }
  } catch (err) {
    return { message: null, error: err.message }
  }
}

export const startCoreDeviceUpdates = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation startCoreDeviceUpdates($ids: [String]) {
        startCoreDeviceUpdates(ids: $ids) {
          message
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data } = await makeGraphqlRequest(GRAPHQL_URL, operation, idToken)

    if (!data?.startCoreDeviceUpdates) {
      return {
        message: null,
        error: 'Unable to start core device updates'
      }
    }

    return {
      message: data?.startCoreDeviceUpdates,
      error: null
    }
  } catch (err) {
    return { message: null, error: err.message }
  }
}

export const buildProvisionInstaller = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation buildProvisionInstaller($zoneId: String!) {
        buildProvisionInstaller(zoneId: $zoneId) {
          fileName
          fileUrl
        }
      }
    `
    const operation = graphqlOperation(mutation, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.buildProvisionInstaller
  } catch (error) {
    throw error.message
  }
}

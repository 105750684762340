import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { fetchSensorThresholdUserAcks } from '@/slices/threshold/sensorThresholdUserAck'
import { fetchSensorThresholdUserSnoozes } from '@/slices/threshold/sensorThresholdUserSnooze'
import {
  getSensorThresholdStatuses,
  getMeasurements,
  getCurrentUser,
  getSensorThresholdUserAcks,
  getZoneHierarchyLoading
} from '@/reducers/selectors'
import ZoneUtils from '@/Util/ZoneUtils'
import { Flex } from '@/primitives'

import { useDashboard } from '../context'
import {
  CLEAR_DETAILED_SENSOR_STATUSES,
  SET_DASHBOARD_STATUSES
} from '../state'

import { generateKey, getFilteredStatuses, getStatusesEntries } from './utils'
import SensorZoneCard from './Zone/SensorZoneCard'
import NoDataCard from './Sensor/NoDataCard'
import MeasurementsCard from './Sensor/MeasurementsCard'
import { DetailedCard } from './Sensor/DetailedCard'

const Notifications = () => {
  const statuses = getSensorThresholdStatuses()
  const currentUser = getCurrentUser()
  const notificationAcks = getSensorThresholdUserAcks()
  const { state, dispatchState } = useDashboard()
  const zoneHierarchyLoading = getZoneHierarchyLoading()

  const params = useParams()
  const dispatch = useDispatch()
  const measurements = getMeasurements()

  const [statusState, setStatusState] = useState({
    noDataStatuses: null,
    sensorStatuses: null,
    parentStatuses: null,
    detailedSensorStatuses: null
  })
  const [hierarchyDevices, setHierarchyDevices] = useState(null)

  const stringifiedHierarchy = JSON.stringify(state.hierarchy)

  useEffect(() => {
    const stateHierarchy = JSON.parse(stringifiedHierarchy)
    if (stateHierarchy?.id) {
      const devices = ZoneUtils.getAllDevicesInHierarchy(stateHierarchy)
      setHierarchyDevices(devices)
    }
  }, [stringifiedHierarchy])

  useEffect(() => {
    if (
      state.hierarchy &&
      !zoneHierarchyLoading &&
      statuses?.length > 0 &&
      hierarchyDevices &&
      currentUser?.userName
    ) {
      dispatchState({
        type: SET_DASHBOARD_STATUSES,
        payload: {}
      })
      dispatchState({ type: CLEAR_DETAILED_SENSOR_STATUSES })
      const nextStatusState = getFilteredStatuses({
        statuses,
        hierarchy: state.hierarchy,
        hierarchyDevices,
        params,
        measurements
      })

      setStatusState(nextStatusState)

      let statusesEntries = []

      if (nextStatusState.parentStatuses) {
        statusesEntries = getStatusesEntries(
          nextStatusState.parentStatuses ?? {}
        )
      } else if (nextStatusState.sensorStatuses) {
        statusesEntries = getStatusesEntries(nextStatusState.sensorStatuses)
      } else if (nextStatusState.detailedSensorStatuses) {
        statusesEntries = getStatusesEntries(
          nextStatusState.detailedSensorStatuses
        )
      }

      const userAcks = []
      const dashboardStatuses = {}

      for (let entry of statusesEntries) {
        const statuses = Object.values(entry[1] ?? {})
        for (let displayStatus of statuses) {
          const { thresholdZonePath, sensorId, measurementId, zoneId } =
            displayStatus

          if (!dashboardStatuses[measurementId]) {
            dashboardStatuses[measurementId] = {}
          }

          if (!dashboardStatuses[measurementId][thresholdZonePath]) {
            dashboardStatuses[measurementId][thresholdZonePath] = displayStatus
          }

          const isManager = currentUser?.managers.some(manager =>
            thresholdZonePath?.includes(manager.zoneId)
          )

          if (isManager) {
            const userAckRequest = {
              userId: currentUser?.userName,
              sensorId: sensorId,
              measurementId,
              zoneId
            }
            userAcks.push(userAckRequest)
          }
        }
      }

      dispatchState({
        type: SET_DASHBOARD_STATUSES,
        payload: dashboardStatuses
      })

      if (userAcks.length > 0) {
        dispatch(
          fetchSensorThresholdUserAcks({
            userAcks,
            rootId: params.zone.split('/').at(0),
            upsertAck: true
          })
        )
      }
    }
  }, [
    zoneHierarchyLoading,
    hierarchyDevices,
    state.hierarchy,
    statuses,
    currentUser,
    dispatch,
    params
  ])

  useEffect(() => {
    if (notificationAcks?.length > 0 && hierarchyDevices) {
      const filteredAcks = []

      for (let ack of notificationAcks) {
        const device = hierarchyDevices[ack.sensorId]
        const isAlertInSensorZonePath = device.zonePath === `/${params.zone}`

        if (isAlertInSensorZonePath) {
          filteredAcks.push({
            userId: ack.userId,
            sensorId: ack.sensorId,
            measurementId: ack.measurementId,
            zoneId: ack.zoneId
          })
        }
      }
      if (filteredAcks.length > 0) {
        dispatch(fetchSensorThresholdUserSnoozes({ userSnoozes: filteredAcks }))
      }
    }
  }, [notificationAcks?.length, dispatch, notificationAcks, hierarchyDevices])

  const hasSomeStatuses = Object.keys(statusState).some(
    key => Object.values(statusState?.[key] ?? {}).length > 0
  )

  return (
    <Flex
      direction='row'
      axisGap='300'
      wrap='wrap'
      alignCrossAxis='flex-start'
      style={
        hasSomeStatuses ? { padding: '1em 0', paddingBottom: '0.5em' } : null
      }
    >
      {getStatusesEntries(statusState.noDataStatuses).map(
        ([_sensorId, item]) => {
          const sensor = Object.values(item)[0]

          const sensorUrl = `${sensor.sensorZonePath}/sensor/${sensor.sensorType}/${sensor.sensorId}`
          const paramsUrl = `/${params.zone}/sensor/${params.sensorType}/${params.sensorId}`

          const measurementIds = Object.keys(item).join('-')
          const key = `${_sensorId}-${measurementIds}`

          // if we are in sensor dashboard
          if (params.sensorType) {
            // only render if item is in its own sensor dashboard
            if (sensorUrl === paramsUrl) {
              return <NoDataCard item={item} key={key} />
            }
            return null
          }
          // if we are in zone dashboard
          return <NoDataCard item={item} key={key} />
        }
      )}
      {getStatusesEntries(statusState.parentStatuses).map(([path, item]) => {
        const displayStatus = !item?.zoneId ? Object.values(item)[0] : item
        return (
          <SensorZoneCard
            item={item}
            path={path}
            key={generateKey(displayStatus)}
          />
        )
      })}
      {getStatusesEntries(statusState.sensorStatuses).map(
        ([measurementId, item]) => {
          const sensorIds = Object.keys(item).join('-')
          const key = `${measurementId}-${sensorIds}`

          if (params?.sensorType) {
            return null
          }

          return (
            <MeasurementsCard
              key={key}
              item={item}
              measurementId={measurementId}
            />
          )
        }
      )}
      {params.sensorType &&
        getStatusesEntries(statusState.detailedSensorStatuses).map(
          ([measurementId, item]) => {
            if (params?.sensorType) {
              const sensorIds = Object.keys(item).join('-')
              const key = `${measurementId}-${sensorIds}`
              // console.log('item', Object.values(item))
              return Object.values(item).map((status, index) => {
                return (
                  <DetailedCard
                    key={key}
                    item={status}
                    measurementId={measurementId}
                  />
                )
              })
            }

            return null
          }
        )}
    </Flex>
  )
}

export default Notifications

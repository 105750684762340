import { I18n } from 'aws-amplify'

const Strings = () => ({
  manageWaste: I18n.get('Manage Waste'),
  createWaste: I18n.get('Create Waste'),
  waste: I18n.get('Waste'),
  storageFacility: I18n.get('Storage Facility'),
  createFormHeader: I18n.get('Create New Waste'),
  updateFormHeader: I18n.get('Update Existing Waste'),
  saveWaste: I18n.get('Save Waste'),
  wasteDate: I18n.get('Date'),
  location: I18n.get('Location'),
  zone: I18n.get('Zone'),
  yield: I18n.get('Yield'),
  produce: I18n.get('Produce'),
  variety: I18n.get('Variety'),
  plan: I18n.get('Plan'),
  weights: I18n.get('Weights'),
  weight: I18n.get('Weight'),
  addWeight: I18n.get('Add Weight'),
  selectDefault: I18n.get('Please Select'),
  kg: I18n.get('kg'),
  noWaste: I18n.get('No waste'),
  reason: I18n.get('Reason'),
  expired: I18n.get('Expired'),
  damaged: I18n.get('Damaged'),
  quality: I18n.get('Low Quality')
})

export default Strings

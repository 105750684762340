import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'

import OrgApiCard from './OrgApiCard'

import { Box, Text, Grid, Item, Button, Icon, FlexV2 } from '@/primitives'

import { LineSeparator } from '@/elements'
import useMediaQuery from '@/hooks/useMediaQuery'

import { fetchApiKeys } from '@/slices/timestream/usagePlan'

import {
  getCurrentUser,
  getCurrentUserOrganizations
} from '@/reducers/selectors'

import Strings from './Strings'
import {
  hasReadPermissions,
  hasEditPermissions,
  RESOURCE_TYPE_DATA_API,
  RESOURCE_TYPE_DATA_API_MANAGEMENT
} from '@/Util/PermissionUtils'

import './index.scss'

const getUserOrgIds = (allOrgs, userOrgs) => {
  if (userOrgs?.length === 0) {
    return allOrgs.map(({ id }) => id)
  }
  return userOrgs
}

const TOP_LEVEL_DOMAINS = {
  dev: 'uk',
  prod: 'ag'
}

const ENVIRONMENT = window.ENV.ENV
const API_DOCS_URL = `https://data-api.coretex.${TOP_LEVEL_DOMAINS[ENVIRONMENT]}/`

const DataApiPage = () => {
  const strings = Strings()

  const coretexUser = getCurrentUser()
  const allOrgs = getCurrentUserOrganizations()

  const dispatch = useDispatch()

  let userHasReadPermissions = false
  let userHasWritePermissions = false

  const isAboveBreakpoint = useMediaQuery('min-width: 600px')

  const [userOrgIds, setUserOrgIds] = useState([])

  useEffect(() => {
    if (userHasReadPermissions && allOrgs?.length > 0) {
      const organizationIds = getUserOrgIds(
        allOrgs,
        coretexUser?.organizationIds
      )
      setUserOrgIds(organizationIds)
      dispatch(fetchApiKeys({ organizationIds }))
    }
  }, [coretexUser.organizationIds, allOrgs, userHasReadPermissions, dispatch])

  if (coretexUser?.hasOwnProperty('permissions')) {
    userHasReadPermissions = hasReadPermissions(
      coretexUser,
      RESOURCE_TYPE_DATA_API,
      RESOURCE_TYPE_DATA_API_MANAGEMENT
    )

    if (!userHasReadPermissions) {
      return <Redirect to='/zones' />
    }

    userHasWritePermissions = hasEditPermissions(
      coretexUser,
      RESOURCE_TYPE_DATA_API,
      RESOURCE_TYPE_DATA_API_MANAGEMENT
    )
  }

  if (!userHasReadPermissions) {
    return null
  }

  return (
    <Box>
      <FlexV2
        direction={isAboveBreakpoint ? 'row' : 'column'}
        axisGap={400}
        alignCrossAxis={isAboveBreakpoint ? 'center' : 'stretch'}
        alignMainAxis={isAboveBreakpoint ? 'space-between' : null}
      >
        <Text size='300' variant='page' tone='800' fontWeight='300'>
          {strings.dataApiKeys}
        </Text>
        <Button
          as={Link}
          href={API_DOCS_URL}
          variant='primary'
          size='small'
          rel='noreferrer'
          target='_blank'
          aria-label={strings.buttonApiDescription}
        >
          <Text style={{ lineHeight: '2.5rem' }}>{strings.apiDocsLink}</Text>
          <Icon
            name='open_in_new'
            size={200}
            style={{
              marginInlineStart: '0.4rem',
              paddingTop: '0.5rem',
              lineHeight: '0.7rem'
            }}
          />
        </Button>
      </FlexV2>
      <LineSeparator />
      <Grid gap={400} className='DataAPI_Cards'>
        {userOrgIds?.length > 0 &&
          userOrgIds?.map(id => (
            <Item key={id}>
              <OrgApiCard
                id={id}
                key={id}
                writePermissions={userHasWritePermissions}
              />
            </Item>
          ))}
      </Grid>
    </Box>
  )
}

export default DataApiPage

export const convertToWetBulbTemperature = ({ temperature, humidity }) => {
  return (
    temperature * Math.atan(0.151977 * Math.pow(humidity + 8.313659, 1 / 2)) +
    Math.atan(temperature + humidity) -
    Math.atan(humidity - 1.676331) +
    0.00391838 * Math.pow(humidity, 3 / 2) * Math.atan(0.023101 * humidity) -
    4.686035
  )
}
export const wetBulbTemperatureMeasurements = ['temperature', 'humidity']

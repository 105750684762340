import { I18n } from 'aws-amplify'

import { widgetRegistry } from '@/components/DashboardPageV2/Desktop/Widgets'

import { TOOLBOX_TYPES, ToolboxItem } from './utils'

/*
  SECTION
*/

export const plantScienceSection = () =>
  new ToolboxItem({
    id: 'plantScienceSection',
    type: TOOLBOX_TYPES.section,
    title: I18n.get('Plant Science'),
    icon: 'local_florist meter',
    items: [
      widgetRegistry.get('wetBulbTemperature'),
      widgetRegistry.get('growingDegreeHours')
    ]
  })

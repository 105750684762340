import { I18n } from 'aws-amplify'
import cloneDeep from 'lodash/cloneDeep'
import { Symbol } from '@/primitives'
import { conversionLookup, fixedLookup } from '../../../Util/UnitUtils'
import clone from 'lodash/clone'
import ZoneUtils from '@/Util/ZoneUtils'

export function buildDashboardBreadcrumbs(
  organization,
  hierarchy,
  params,
  device
) {
  let ids = params?.zone?.split('/')

  const traverseHierarchy = (node, ids, breadcrumbs = []) => {
    if (ids?.length === 0) {
      return breadcrumbs
    }

    const currentId = ids?.shift()
    const currentZone = node?.children?.[currentId] || node?.[currentId]

    if (currentZone) {
      if (params?.sensorId && device?.zoneId === currentZone?.id) {
        const deviceZone = {
          id: device.id,
          name: device.name,
          parentPath: `/zones${currentZone?.parentPath}/sensor/${device.sensorType}/${device.id}`,
          isDevice: true
        }
        currentZone.children = {
          [device.id]: deviceZone
        }
        ids.push(device.id)
      }

      breadcrumbs.push({
        id: currentZone.id,
        name: currentZone.name,
        parentPath: '/zones' + currentZone?.parentPath,
        isDevice: !!currentZone?.isDevice,
        noSuffix: false
      })

      return traverseHierarchy(currentZone, ids, breadcrumbs)
    }

    return breadcrumbs
  }

  const initialBreadcrumbs = [
    {
      id: 'home',
      name: I18n.get('Home'),
      parentPath: '/',
      isDevice: false,
      noSuffix: true
    },
    {
      id: 'organization',
      name: organization?.name,
      parentPath: '/zones',
      isDevice: false,
      noSuffix: true
    }
  ]

  if (!ids || ids.length === 0 || !hierarchy) {
    return initialBreadcrumbs
  }

  const breadcrumbs = traverseHierarchy(
    { [hierarchy.id]: cloneDeep(hierarchy) },
    ids,
    initialBreadcrumbs
  )

  return breadcrumbs
}

const iconConfigs = [
  {
    min: 200,
    max: 233,
    icon: 'thunderstorm',
    variant: 'warning',
    tone: 900
  },
  {
    min: 300,
    max: 321,
    icon: 'rainy_light',
    variant: 'info',
    tone: 600
  },
  {
    min: 500,
    max: 531,
    icon: 'rainy_heavy',
    variant: 'info',
    tone: 600
  },
  {
    min: 600,
    max: 602,
    icon: 'weather_snowy',
    variant: 'info',
    tone: 900
  },
  {
    min: 610,
    max: 623,
    icon: 'rainy_snow',
    variant: 'info',
    tone: 900
  },
  {
    min: 700,
    max: 781,
    icon: 'foggy',
    variant: 'page',
    tone: 900
  },
  {
    min: 800,
    max: 800,
    icon: 'sunny',
    variant: 'danger',
    tone: 700,
    alt: {
      icon: 'clear_night',
      variant: 'warning',
      tone: 800
    }
  },
  {
    min: 801,
    max: 803,
    icon: 'partly_cloudy_day',
    variant: 'warning',
    tone: 500,
    alt: {
      icon: 'partly_cloudy_night',
      variant: 'warning',
      tone: 500
    }
  },
  {
    min: 804,
    max: 804,
    icon: 'cloudy',
    variant: 'page',
    tone: 900
  }
]

export const getWeatherIcon = (code, forecastTime, sunriseTime, sunsetTime) => {
  const iconDetails = iconConfigs.find(({ min, max }) => {
    return code >= min && code <= max
  })

  if (!iconDetails) {
    return (
      <Symbol name='horizontal_rule' variant='neutral' tone={600} size={400} />
    )
  }

  const isNight = forecastTime > sunsetTime || forecastTime < sunriseTime
  const hasAltIcon = iconDetails?.alt?.hasOwnProperty('icon')
  const { alt, ...rest } = iconDetails
  const iconVersion = isNight && hasAltIcon ? alt : rest
  return (
    <Symbol
      name={iconVersion?.icon}
      variant={iconVersion?.variant}
      tone={iconVersion?.tone}
      size={400}
    />
  )
}

export const getTileHeader = (
  timestamp,
  isHourly,
  lang,
  timeZone,
  short = true
) => {
  const date = new Date(timestamp)
  if (isHourly) {
    const hour = date.toLocaleString([lang, 'en-GB'], {
      timeZone,
      hour: '2-digit',
      hour12: false
    })
    return short ? `${hour}H` : `${hour}H00`
  }

  const day = date.toLocaleString([lang, 'en-GB'], {
    timeZone,
    weekday: short ? 'short' : 'long'
  })
  return day.toUpperCase()
}

/*
The following license information applies only to the schemeDark2 constant below which was extracted from the d3-scale-chromatic package.

Copyright 2010-2021 Mike Bostock

Permission to use, copy, modify, and/or distribute this software for any purpose
with or without fee is hereby granted, provided that the above copyright notice
and this permission notice appear in all copies.

THE SOFTWARE IS PROVIDED "AS IS" AND THE AUTHOR DISCLAIMS ALL WARRANTIES WITH
REGARD TO THIS SOFTWARE INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY AND
FITNESS. IN NO EVENT SHALL THE AUTHOR BE LIABLE FOR ANY SPECIAL, DIRECT,
INDIRECT, OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS
OF USE, DATA OR PROFITS, WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER
TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF
THIS SOFTWARE.

Apache-Style Software License for ColorBrewer software and ColorBrewer Color Schemes

Copyright 2002 Cynthia Brewer, Mark Harrower, and The Pennsylvania State University

Licensed under the Apache License, Version 2.0 (the "License"); you may not use
this file except in compliance with the License. You may obtain a copy of the
License at

http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed
under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
CONDITIONS OF ANY KIND, either express or implied. See the License for the
specific language governing permissions and limitations under the License.
*/
const schemeDark2 = [
  '#1b9e77',
  '#d95f02',
  '#7570b3',
  '#e7298a',
  '#66a61e',
  '#e6ab02',
  '#a6761d',
  '#666666'
]
// End of license information for schemeDark2

//TODO: this is a copy/paste of src/components/DashboardPageV2/Desktop/Widgets/Shared/utils.js
// We should remove the mentioned file and use this one instead
// Check how to pass fixed and scale factors to this file

export const reactSelectStyles = {
  backgroundColor: 'var(--ctx-theme-color-page-200)',
  border: '1px solid var(--ctx-theme-color-page-400)',
  borderRadius: '2px',
  boxShadow: '0px 3px 6px var(--ctx-theme-color-info-700)'
}

export const schemeNivo = [
  '#ff7f0e',
  '#2ca02c',
  '#d62728',
  '#9467bd',
  '#8c564b',
  '#e377c2',
  '#7f7f7f',
  '#bcbd22',
  '#17becf'
]

export const graphSerieColors = schemeNivo.concat(schemeDark2)

const applyConversion = (name, value) => {
  const scaleFactor = conversionLookup[name]
  if (scaleFactor && scaleFactor !== 1) {
    return value * scaleFactor
  }
  return value
}

const applyFixed = (name, value) => {
  const fixedFactor = fixedLookup[name]
  if (fixedFactor && fixedFactor !== 1) {
    return value.toFixed(fixedFactor)
  }
  return value.toFixed(1)
}

export function normalizeNaN(value) {
  return isNaN(value) ? null : value
}

export function parseValue(value, measurement) {
  if (value === null || value === undefined) {
    return null
  }
  const name = measurement.toLowerCase()
  return normalizeNaN(applyFixed(name, applyConversion(name, value)))
}

// Mobile & simplified dashboard

export const getMeasurementUnit = (measurements, id) => {
  const selectedItem = measurements.find(measurement => measurement.id === id)
  return selectedItem?.unit
}

const FORCE_SORT_ENVIROSENSE_MEASUREMENTS = [
  'temperature',
  'humidity',
  'par',
  'pressure',
  'uv_index',
  'carbon_dioxide'
]

// Also include dissolved oxygen
const FORCE_SORT_WATERSENSE_MEASUREMENTS = [
  'water_temperature',
  'ph',
  'conductivity',
  'dissolved_oxygen',
  'dissolved_oxygen_percentage'
]

const FORCE_SORT_SOILSENSE_MEASUREMENTS = [
  'soil_temperature',
  'soil_ph',
  'soil_moisture',
  'soil_ec',
  'soil_nitrogen',
  'soil_potassium'
]

const FORCE_SORT_WEATHER_STATION_MEASUREMENTS = ['wind_speed', 'wind_direction']

function getHardcodedDeviceTypes(deviceTypes) {
  return deviceTypes.reduce((acc, deviceType) => {
    if (deviceType.id === 'envirosense')
      acc['envirosenseDeviceType'] = deviceType
    if (deviceType.id === 'watersense') acc['watersenseDeviceType'] = deviceType
    if (deviceType.id === 'soilsense') acc['soilsenseDeviceType'] = deviceType
    if (deviceType.id === 'weather_station')
      acc['weatherStationDeviceType'] = deviceType
    return acc
  }, {})
}

function getMeasurementsByDeviceTypes(measurementIds, hardcodedDeviceTypes) {
  const {
    envirosenseDeviceType,
    watersenseDeviceType,
    soilsenseDeviceType,
    weatherStationDeviceType
  } = hardcodedDeviceTypes

  return measurementIds.reduce(
    (acc, measurementId) => {
      if (envirosenseDeviceType.measurements.includes(measurementId)) {
        acc['envirosenseMeasurements'].push(measurementId)
      }

      if (watersenseDeviceType.measurements.includes(measurementId)) {
        acc['watersenseMeasurements'].push(measurementId)
      }

      if (soilsenseDeviceType.measurements.includes(measurementId)) {
        acc['soilsenseMeasurements'].push(measurementId)
      }

      if (weatherStationDeviceType.measurements.includes(measurementId)) {
        acc['weatherStationMeasurements'].push(measurementId)
      }

      if (
        !envirosenseDeviceType.measurements.includes(measurementId) &&
        !watersenseDeviceType.measurements.includes(measurementId) &&
        !soilsenseDeviceType.measurements.includes(measurementId) &&
        !weatherStationDeviceType.measurements.includes(measurementId)
      ) {
        acc['restOfMeasurements'].push(measurementId)
      }

      return acc
    },
    {
      envirosenseMeasurements: [],
      watersenseMeasurements: [],
      soilsenseMeasurements: [],
      weatherStationMeasurements: [],
      restOfMeasurements: []
    }
  )
}

function getSortedUnsortedMeasurementsForcedOrder(measurements, forcedOrder) {
  const sortedMeasurements = forcedOrder.filter(measurementId => {
    return measurements.includes(measurementId)
  })

  const unsortedMeasurements = measurements.filter(measurementId => {
    return !sortedMeasurements.includes(measurementId)
  })

  return [sortedMeasurements, unsortedMeasurements]
}

export function sortMeasurementsByDeviceTypes(measurementIds, deviceTypes) {
  const hardcodedDeviceTypes = getHardcodedDeviceTypes(deviceTypes)

  const {
    envirosenseMeasurements,
    watersenseMeasurements,
    soilsenseMeasurements,
    weatherStationMeasurements,
    restOfMeasurements
  } = getMeasurementsByDeviceTypes(measurementIds, hardcodedDeviceTypes)

  const [sortedEnvirosenseMeasurements, unsortedEnvirosenseMeausrements] =
    getSortedUnsortedMeasurementsForcedOrder(
      envirosenseMeasurements,
      FORCE_SORT_ENVIROSENSE_MEASUREMENTS
    )

  const [sortedWatersenseMeasurements, unsortedWatersenseMeausrements] =
    getSortedUnsortedMeasurementsForcedOrder(
      watersenseMeasurements,
      FORCE_SORT_WATERSENSE_MEASUREMENTS
    )

  const [sortedSoilsenseMeasurements, unsortedSoilsenseMeasurements] =
    getSortedUnsortedMeasurementsForcedOrder(
      soilsenseMeasurements,
      FORCE_SORT_SOILSENSE_MEASUREMENTS
    )

  const [sortedWeatherStationMeasurements, unsortedWeatherStationMeasurements] =
    getSortedUnsortedMeasurementsForcedOrder(
      weatherStationMeasurements,
      FORCE_SORT_WEATHER_STATION_MEASUREMENTS
    )

  const sortedMeasurements = [
    ...sortedEnvirosenseMeasurements,
    ...unsortedEnvirosenseMeausrements,
    ...sortedWatersenseMeasurements,
    ...unsortedWatersenseMeausrements,
    ...sortedSoilsenseMeasurements,
    ...unsortedSoilsenseMeasurements,
    ...sortedWeatherStationMeasurements,
    ...unsortedWeatherStationMeasurements,
    ...restOfMeasurements
  ]

  return sortedMeasurements
}

/**
 * Gets the site id from a zone path
 * @param {string} zonePath
 * @returns
 */
export const getSiteIdFromZonePath = zonePath => {
  return zonePath.split('/')[0]
}

/**
 * Clones a zone and keeps the children property but not children's children
 * @param {Object} zone
 * @returns
 */
export const cloneFlatZone = zone => {
  let clonedZone = clone(zone)
  return clonedZone
}

export const getZoneLevelName = currentZone => {
  if (!currentZone.hasOwnProperty('parentPath')) {
    return {
      currentZoneLevelName: null,
      childZoneLevelName: null
    }
  }

  const currentZoneLevel = currentZone.parentPath.split('/').length - 2
  const currentZoneLevelName =
    ZoneUtils.getZoneDepthStringFromDepth(currentZoneLevel)
  const childZoneLevelName = ZoneUtils.getZoneDepthStringFromDepth(
    currentZoneLevel + 1
  )

  return { currentZoneLevelName, childZoneLevelName }
}

export const getChildIds = children => {
  return Object.keys(children).filter(id => {
    const { status } = children[id]
    return status === 'active'
  })
}

import InputError from '@/elements/InputError'
import { Text, Label, Input, FlexV2 } from '@/primitives'

import useDeviceSize from '@/hooks/useDeviceSize'

import Strings from '../Strings'
import Address from './Address'

function BankDetails({ state, handleInput, errors, fields }) {
  const isMobile = useDeviceSize('mobile')

  const strings = Strings()

  const onHandleInput = e => {
    handleInput(e, false)
  }

  const onHandleBranchAddress = e => {
    handleInput(e, true)
  }

  return (
    <FlexV2 axisGap={400} alignMainAxis='space-between' direction='column'>
      <FlexV2
        className='inputGroup'
        axisGap={300}
        direction={isMobile ? 'column' : 'row'}
      >
        <Label>
          <Text variant='page' fontWeight={500} tone={700}>
            {strings.bankName}
          </Text>
          <Input
            value={state.bankName}
            name={fields.bankName}
            onChange={onHandleInput}
          />
          <InputError error={errors?.bankName} />
        </Label>
        <Label>
          <Text variant='page' fontWeight={500} tone={700}>
            {strings.branchName}
          </Text>
          <Input
            value={state.branchName}
            name={fields.branchName}
            onChange={onHandleInput}
          />
          <InputError error={errors?.branchName} />
        </Label>
        <Label>
          <Text variant='page' fontWeight={500} tone={700}>
            {strings.branchCode}
          </Text>
          <Input
            value={state.branchCode}
            name={fields.branchCode}
            onChange={onHandleInput}
          />
          <InputError error={errors?.branchCode} />
        </Label>
      </FlexV2>
      <FlexV2
        className='InputGroup'
        axisGap={300}
        direction={isMobile ? 'column' : 'row'}
      >
        <Label style={{ flex: '3 1 auto' }}>
          <Text variant='page' fontWeight={500} tone={700}>
            {strings.iban}
          </Text>
          <Input
            value={state.iban}
            name={fields.iban}
            onChange={onHandleInput}
          />
          <InputError error={errors?.iban} />
        </Label>
        <Label style={{ flex: '1 1 auto' }}>
          <Text variant='page' fontWeight={500} tone={700}>
            {strings.swiftBic}
          </Text>
          <Input
            value={state.swiftBic}
            name={fields.swiftBic}
            onChange={onHandleInput}
          />
          <InputError error={errors?.swiftBic} />
        </Label>
      </FlexV2>
      <Address
        className='BankDetails__Address'
        state={state.branchAddress}
        fields={fields.branchAddress}
        errors={errors}
        handleInput={onHandleBranchAddress}
        stringType='bank'
      />
    </FlexV2>
  )
}

export default BankDetails

import { Fragment } from 'react'

import { Button } from '@/primitives'

const TableActions = ({ id, canEdit, onEdit, onDelete }) => {
  const handleOnEdit = () => onEdit(id)
  const handleOnDelete = () => onDelete(id)

  if (canEdit) {
    return (
      <Fragment>
        <Button
          variant='info'
          size='small'
          iconBefore='edit'
          onClick={handleOnEdit}
        />
        <Button
          variant='error'
          size='small'
          iconBefore='cancel'
          onClick={handleOnDelete}
        />
      </Fragment>
    )
  }

  return null
}

export default TableActions

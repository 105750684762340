import { WidgetRegistry } from './Shared/utils/WidgetRegistry'
import { TimezoneClockDefinition } from './Miscellaneous/TimezoneClock'
import { BatterySocDefinition } from './Miscellaneous/BatterySoc'
import { FertigationWidgetDefinitions } from './Fertigation'
import { EnvironmentWidgetDefinitions } from './Environment'
import { PlantScienceWidgetDefinitions } from './PlantScience'
import { OperationWidgetDefinitions } from './Operations'
import { ResourcesWidgetDefinitions } from './Resources'
import { WeatherDefinition } from './Weather'
import { TubularSoilsenseWidgetDefinitions } from './TubularSoilsense'

let widgets = []
try {
  widgets = [
    // Add widgets here

    // Miscellaneous
    TimezoneClockDefinition,
    BatterySocDefinition,
    WeatherDefinition,

    // Measurements
    EnvironmentWidgetDefinitions,
    FertigationWidgetDefinitions,
    PlantScienceWidgetDefinitions,
    ResourcesWidgetDefinitions,
    OperationWidgetDefinitions,
    TubularSoilsenseWidgetDefinitions
  ]
} catch (error) {
  throw Error(
    `Are you sure you imported the widget definition and not the widget itself? ${error}`
  )
}

export const widgetRegistry = new WidgetRegistry(widgets)

import { useState, useRef, useEffect, useCallback } from 'react'
import { ResponsiveBar } from '@nivo/bar'

import Tooltip from './Tooltip'
import HarvestCustomBar from './HarvestCustomBar'

import useMediaQuery from '@/hooks/useMediaQuery'
import { Box, Text, Card, Slot, Flex, Button } from '@/primitives'

import {
  BAR_GRAPH_THEME,
  createDownloadableChart,
  getStringsByContext
} from '../utils'
import Strings from '../Strings'

const getDefaultBarMaxValue = (data, indexBy) => {
  const locationAggregates = data.map(locationData => {
    return Object.keys(locationData).reduce((acc, produceName) => {
      if (produceName !== indexBy) acc += locationData[produceName]
      return acc
    }, 0)
  })

  return locationAggregates.sort((a, b) => b - a)[0]
}

const DataboardChart = ({ data, keys, config, gradesData = null }) => {
  const {
    indexBy,
    header,
    bottomLabel,
    enableCustomBar,
    section = 'harvest',
    noData = []
  } = config

  const isAboveCustomBreakpoint = useMediaQuery('min-width: 769px')
  const [maxValue, setMaxValue] = useState(0)
  const [useCustomBar, setUseCustomBar] = useState(false)
  const chartRef = useRef(null)

  const toggleSplitByGrade = useCallback(() => {
    if (useCustomBar) {
      const largestAggregate = getDefaultBarMaxValue(data, indexBy)
      setMaxValue(largestAggregate)
    } else {
      setMaxValue(1)
    }

    setUseCustomBar(!useCustomBar)
  }, [data, indexBy, useCustomBar])

  useEffect(() => {
    const largestAggregate = getDefaultBarMaxValue(data, indexBy)
    setMaxValue(largestAggregate)
  }, [data, indexBy])

  useEffect(() => {
    if (!isAboveCustomBreakpoint && useCustomBar) {
      toggleSplitByGrade()
    }
  }, [isAboveCustomBreakpoint, toggleSplitByGrade, useCustomBar])

  const characters = maxValue.toString().length - 1
  const ratioToUse = Math.pow(1000, Math.floor(characters / 3))
  const unitRatio = ratioToUse === 0 ? 1 : ratioToUse

  const strings = Strings()

  const customTooltip = data => <Tooltip data={data} keys={keys} />

  const { customBarText, downloadDocTitle, yAxisLabel, yAxisUnit } =
    getStringsByContext(section, useCustomBar)

  const barPositions = {}

  const emptyBars = gradesData.map(locationData => {
    const gradeTotals = Object.keys(locationData).reduce((acc, produceName) => {
      const produceData = locationData[produceName]
      Object.keys(produceData).forEach(gradeName => {
        if (!acc.hasOwnProperty(gradeName)) acc[gradeName] = 0
        acc[gradeName] += produceData[gradeName]
      })

      return acc
    }, {})

    return Object.keys(gradeTotals).filter(grade => gradeTotals[grade] === 0)
  })

  const insertCustomBarComponent = data => {
    switch (section) {
      case 'harvest':
        return (
          <HarvestCustomBar
            data={data}
            grades={gradesData}
            barPositions={barPositions}
            setMaxValue={setMaxValue}
            maxValue={maxValue}
            emptyBars={emptyBars}
            unitRatio={unitRatio}
          />
        )
      default:
        return undefined
    }
  }

  const onDownloadHandler = async e => {
    e.preventDefault()
    const dates = header.split(': ')[1]
    const fileName = `${downloadDocTitle}_${dates}`
      .toLowerCase()
      .replaceAll(' ', '_')
    const title = { main: downloadDocTitle, sub: dates }
    await createDownloadableChart(chartRef, fileName, title, yAxisUnit)
  }

  const legendSpaceRequired = 20 * keys.length

  return (
    <Box style={{ marginBottom: '3rem' }}>
      {data?.length > 0 && (
        <Card>
          <Slot name='body'>
            <Box
              ref={chartRef}
              style={{ height: '30rem', width: '99%', margin: '0 0 2rem 0' }}
            >
              <Flex alignMainAxis='space-between' wrap='nowrap' axisGap={300}>
                <Text variant='page' tone={900} size={200}>
                  {header}
                </Text>
                <Flex alignMainAxis='flex-end' axisGap={300} wrap='nowrap'>
                  {enableCustomBar && isAboveCustomBreakpoint && (
                    <Button
                      onClick={toggleSplitByGrade}
                      variant={useCustomBar ? 'info' : ''}
                      size='small'
                    >
                      {customBarText}
                    </Button>
                  )}
                  <Button
                    onClick={onDownloadHandler}
                    iconBefore='download'
                    variant='info'
                    size='small'
                  />
                </Flex>
              </Flex>
              <ResponsiveBar
                data={data}
                keys={keys}
                indexBy={indexBy}
                tooltip={customTooltip}
                barComponent={
                  useCustomBar ? insertCustomBarComponent : undefined
                }
                maxValue={maxValue}
                margin={{
                  top: 50,
                  right: isAboveCustomBreakpoint ? 250 : 0,
                  bottom: isAboveCustomBreakpoint
                    ? 70
                    : 100 + legendSpaceRequired,
                  left: 60
                }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'nivo' }}
                theme={BAR_GRAPH_THEME}
                axisBottom={{
                  legend: bottomLabel,
                  legendPosition: 'middle',
                  legendOffset: useCustomBar ? 60 : 45,
                  tickPadding: useCustomBar ? 25 : 0
                }}
                axisLeft={{
                  legend: `${yAxisLabel} (${unitRatio.toLocaleString(
                    'en-US'
                  )} ${yAxisUnit})`,
                  legendPosition: 'middle',
                  legendOffset: -50,
                  format: value => value / unitRatio
                }}
                label={d => parseFloat((d.value / unitRatio).toFixed(2))}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                  from: 'color',
                  modifiers: [['darker', 1.6]]
                }}
                legends={[
                  {
                    dataFrom: 'keys',
                    anchor: isAboveCustomBreakpoint
                      ? 'bottom-right'
                      : 'bottom-left',
                    direction: 'column',
                    justify: false,
                    translateX: isAboveCustomBreakpoint ? 120 : -60,
                    translateY: isAboveCustomBreakpoint ? 0 : 200,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemOpacity: 1
                        }
                      }
                    ]
                  }
                ]}
              />
            </Box>
            {noData?.length > 0 && (
              <Box style={{ marginTop: '3rem' }}>
                <Text variant='page' tone={800} size={100}>
                  {`${strings.noDataToShow}:`}
                </Text>
                {noData.map((zone, index) => {
                  const isLastitem = index === noData.length - 1
                  const displayString = ` ${zone}${isLastitem ? '.' : ','}`
                  return (
                    <Text variant='page' tone={800} size={100} key={zone}>
                      {displayString}
                    </Text>
                  )
                })}
              </Box>
            )}
          </Slot>
        </Card>
      )}
    </Box>
  )
}

export default DataboardChart

import Pin from '../Marker/Pin'

const CustomPin = ({ device, setDevice, style }) => {
  const handleClick = e => {
    e.stopPropagation()
    setDevice(device)
  }

  return <Pin device={device} style={style} onClick={handleClick} />
}

export default CustomPin

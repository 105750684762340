import { useEffect, useRef } from 'react'
import { I18n } from 'aws-amplify'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { FlexV2, Text } from '@/primitives'
import {
  getDashboards,
  getDeviceTypes,
  getMeasurements,
  getZonesHierarchy
} from '@/reducers/selectors'
import {
  sendCopyDashboard,
  sendUpsertDashboardConfig,
  sendResetDashboard
} from '@/slices/management/dashboard'

import ZoneUtils from '@/Util/ZoneUtils'

import { useDashboard } from '../../context'
import {
  CLEAR_DOWNLOADABLE_DATASETS,
  DEFER_COMMIT,
  SET_CURRENT_DASHBOARD
} from '../../state'
import { getAvailableMeasurements } from '../../utils/measurements'

import LayoutSelect from './LayoutSelect'

import generateMeasurementLayout from './layouts/measurements'

import { getLayoutOptions, getLayoutId, getLayoutTools } from './utils'
import { dashboardConfigToWidgetMapper } from '../../utils'

const getDashboardPath = zone => zone.parentPath ?? `/${zone.id}`

const getZoneDepth = params => params.zone.split('/').length - 1

export default function LayoutManager() {
  const params = useParams()
  const dispatch = useDispatch()

  const { state, dispatchState } = useDashboard()
  const dashboards = getDashboards()
  const allDeviceTypes = getDeviceTypes()
  const allMeasurements = getMeasurements()
  const zonesHierarchy = getZonesHierarchy()
  const dispatchedDashboardsToFix = useRef(new Set())

  const availableMeasurements = getAvailableMeasurements(
    state.hierarchy,
    state?.currentZone,
    state?.siteId,
    allDeviceTypes,
    allMeasurements,
    params,
    true
  )

  const { layoutOptions, dashboardsToFix } = getLayoutOptions({
    dashboards,
    zonesHierarchy,
    siteHierarchy: state.hierarchy,
    zoneDepth: getZoneDepth(params),
    currentDashboardId: state.currentDashboard.dashboardId
  })

  useEffect(() => {
    for (let dashboard of dashboardsToFix) {
      if (dispatchedDashboardsToFix.current.has(dashboard.dashboardId)) {
        continue
      }

      const segments = dashboard.zonePath.split('/').slice(1)
      const siteId = segments.at(0)
      const zoneId = segments.at(-1)

      if (!zonesHierarchy?.[siteId]) {
        dispatchedDashboardsToFix.current.add(dashboard.dashboardId)
        continue
      }

      const zone = ZoneUtils.getZoneFromHierarchy(
        { [siteId]: zonesHierarchy[siteId] },
        zoneId
      )
      if (!zone) {
        // If we reach this point, one of the dashboards has an invalid zonePath, probably because of moving zones around.
        continue
      }

      const nextZonePath = getDashboardPath(zone)

      if (nextZonePath === dashboard.zonePath) {
        dispatchedDashboardsToFix.current.add(dashboard.dashboardId)
        continue
      }

      dispatch(
        sendUpsertDashboardConfig({
          dashboardId: dashboard.dashboardId,
          zonePath: getDashboardPath(zone),
          config: dashboard.config,
          isFixingZonePath: true
        })
      )
      dispatchState({ type: DEFER_COMMIT })
      dispatchState({ type: CLEAR_DOWNLOADABLE_DATASETS })
      dispatchedDashboardsToFix.current.add(dashboard.dashboardId)
    }
  }, [dashboardsToFix])

  const upsertDashboardConfig = config => {
    const { zonePath } = state.currentDashboard
    dispatch(
      sendUpsertDashboardConfig({
        dashboardId: state.currentDashboard.dashboardId,
        zonePath: zonePath ?? getDashboardPath(state.currentZone),
        config
      })
    )
  }

  const handleLayoutToolsChange = selected => {
    const { dashboardId, zonePath, isDefault, context } = state.currentDashboard
    if (selected.value === 'create-layout-from-current') {
      dispatch(
        sendCopyDashboard({
          originDashboardId: dashboardId,
          dashboardId,
          zonePath: zonePath ?? getDashboardPath(state.currentZone)
        })
      )
    } else if (selected.value === 'clear-current-layout') {
      upsertDashboardConfig([])
    } else if (selected.value === 'reset-zone-default') {
      dispatch(
        sendResetDashboard({ dashboardId, zonePath, context, isDefault })
      )
    }
    dispatchState({ type: DEFER_COMMIT })
  }

  const handleLayoutChange = selected => {
    const [type, id] = selected.value.split('#')

    if (type === 'config') {
      let {
        config,
        context,
        dashboardId,
        isDefault,
        userId,
        version,
        zonePath
      } = dashboards.find(({ dashboardId }) => dashboardId === id)
      if (!config) {
        throw new Error(`Dashboard ${id} has no config`)
      }

      config = JSON.parse(JSON.stringify(config))

      let payload = {
        currentDashboard: {
          config,
          context,
          dashboardId,
          isDefault,
          userId,
          version,
          zonePath
        },
        widgets: dashboardConfigToWidgetMapper(config)
      }

      dispatchState({ type: SET_CURRENT_DASHBOARD, payload })

      upsertDashboardConfig(config)
    } else {
      const config = generateMeasurementLayout(
        selected.value,
        availableMeasurements,
        state.currentBreakpoint
      )

      let payload = {
        currentDashboard: {
          config: config,
          context: state.currentDashboard.context,
          dashboardId: selected.value,
          isDefault: false,
          isPreset: true,
          userId: state.currentDashboard.userId,
          version: state.currentDashboard.version
        },
        widgets: dashboardConfigToWidgetMapper(config)
      }

      dispatchState({ type: SET_CURRENT_DASHBOARD, payload })

      //upsertDashboardConfig(config)
    }
    dispatchState({ type: DEFER_COMMIT })
    dispatchState({ type: CLEAR_DOWNLOADABLE_DATASETS })
  }

  let allowCreateDashboard = true

  for (let i = 0; i < dashboards.length; i++) {
    if (dashboards[i].zonePath === state.currentZone.parentPath) {
      allowCreateDashboard = false
      break
    }
  }

  return (
    <FlexV2 alignCrossAxis='center' axisGap={400}>
      <Text
        variant='page'
        tone={600}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        }}
      >
        {I18n.get('Layout')}
      </Text>
      <LayoutSelect
        minWidth='350px'
        placeholder={I18n.get('Select layout')}
        options={layoutOptions}
        value={getLayoutId(
          state.currentDashboard.isDefault,
          state.currentDashboard.dashboardId,
          state.currentDashboard.isPreset
        )}
        onChange={handleLayoutChange}
        optionSymbol='dashboard'
      />
      <LayoutSelect
        placeholder={I18n.get('Layout tools')}
        options={getLayoutTools(
          state.currentDashboard,
          getZoneDepth(params),
          allowCreateDashboard
        )}
        value={null}
        onChange={handleLayoutToolsChange}
      />
    </FlexV2>
  )
}

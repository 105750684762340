import { useReducer, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { I18n } from 'aws-amplify'
import { unparse } from 'papaparse'

import './index.scss'
import OperationsDataboard from '@/components/Operations/Shared/Databoard'

import {
  getDataboardHarvests,
  getCurrentUserOrganizations,
  getOperationsAllGrades,
  getDataboardHarvestArgs,
  getDataboardSitesHierarchies,
  getDataboardLoading
} from '@/reducers/selectors'
import { requestDataboardHarvests } from '@/actions/operations/databoard'
import { requestAllGrades } from '@/actions/operations/grade'

import { Flex, Button, Box } from '@/primitives'

import DateFilter from '../../../Shared/Filters/DateFilter'
import MultiRowSelector from './MultiRowSelector'
import ProducesFilter from '../../../Shared/Filters/ProducesFilter'
import VarietiesFilter from '../../../Shared/Filters/VarietiesFilter'

import useMediaQuery from '@/hooks/useMediaQuery'
import ZoneUtils, { getZoneName } from '@/Util/ZoneUtils'
import { generateDownloadCsvLink } from '@/Util/GeneralUtils'
import {
  getZoneIdsFromPath,
  getColumnNames,
  getOrganizationName,
  generateZoneIds,
  generateDate,
  getChartData
} from './utils'
import { hasDataboardWarnings } from '../../utils'
import { dataReducer, dataItemInitialState } from '../state'

const HarvestDataBoard = () => {
  const isAboveMobileBreakpoint = useMediaQuery('min-width: 376px')
  const reduxDispatch = useDispatch()
  const databoardHarvests = getDataboardHarvests()
  const organizations = getCurrentUserOrganizations()
  const zonesHierarchy = getDataboardSitesHierarchies()
  const grades = getOperationsAllGrades()
  const harvestArgs = getDataboardHarvestArgs()
  const loading = getDataboardLoading()

  const [state, dispatchState] = useReducer(dataReducer, {
    ...dataItemInitialState
  })

  useEffect(() => {
    if (grades?.length === 0) reduxDispatch(requestAllGrades())
  }, [])

  const onClickFetch = () => {
    const selectedZones = []
    const baseParams = {
      produceId: state.produces,
      varietyId: state.varieties,
      harvestDate: state.harvestDate
    }
    const filterParams = state.locations.map(location => {
      const { organizations, sites, zonePath } = location
      const params = {
        organizationId: organizations,
        siteId: sites,
        ...baseParams
      }

      const zoneIds = getZoneIdsFromPath(zonePath)
      if (zoneIds?.length === 0) {
        if (!selectedZones.includes(sites[0]))
          selectedZones.push({
            name: getZoneName(zonesHierarchy, sites[0]),
            zonePath
          })
      }

      if (zoneIds?.length > 0) {
        const depth = zoneIds.length - 1
        const selectedZoneId = zoneIds[depth]
        selectedZones.push({
          name: getZoneName(zonesHierarchy, params.siteId, selectedZoneId),
          zonePath
        })

        const allZoneIds = []
        const zoneDetails = ZoneUtils.getZoneFromHierarchy(
          zonesHierarchy,
          selectedZoneId
        )
        generateZoneIds(allZoneIds, zoneDetails)
        params['zoneId'] = allZoneIds
      }

      return params
    })

    reduxDispatch(
      requestDataboardHarvests({
        filterRef: { zones: selectedZones, dates: state.harvestDate },
        filters: filterParams
      })
    )
  }

  const tableKeys = [
    'organization',
    'site',
    'zone',
    'harvestDate',
    'produce',
    'variety',
    'totalWeight'
  ]

  const tableRows = databoardHarvests?.map(harvest => {
    const {
      organizationId,
      siteId,
      harvestDate,
      zoneId,
      produce,
      totalWeight,
      variety
    } = harvest

    return {
      organization: getOrganizationName(organizations, organizationId),
      site: getZoneName(zonesHierarchy, siteId),
      zone: zoneId ? getZoneName(zonesHierarchy, siteId, zoneId) : null,
      harvestDate: new Date(harvestDate).toDateString(),
      produce: produce.name,
      variety: variety.name,
      totalWeight
    }
  })

  const onDownloadCSV = () => {
    if (databoardHarvests.length > 0) {
      const rows = tableRows.map((row, index) => {
        const { createdAt, storageFacility, weights } = databoardHarvests[index]
        const { harvestDate, ...rest } = row

        const harvestData = {
          storageFacility: storageFacility.name,
          createdAt: new Date(createdAt).toISOString(),
          harvestDate: new Date(harvestDate).toISOString(),
          ...rest
        }

        grades.forEach(({ id, name }) => {
          const weight = weights.find(({ gradeId }) => gradeId === id)
          const gradeCol = `weightGrade-${name}`
          harvestData[gradeCol] = weight ? weight?.totalKg : 0
        })

        return { ...harvestData }
      })

      const keys = Object.keys(rows[0])

      const rowValues = rows.map((row, index) => {
        return keys.map(key => {
          return row[key]
        })
      })

      const data = [getColumnNames(keys), ...rowValues]
      const csv = unparse({ data })
      const startDate = state.harvestDate[0].toISOString().split('T')[0]
      const endDate = state.harvestDate[1].toISOString().split('T')[0]
      generateDownloadCsvLink(csv, `harvests-data-${startDate}-${endDate}.csv`)
    }
  }

  const { graphKeys, graphData, weightsByGrade } = getChartData(
    harvestArgs,
    databoardHarvests,
    zonesHierarchy,
    grades
  )

  const indexBy = 'zone'

  const bottomLabel = I18n.get('Location')
  const dateRange = I18n.get('Results for date range')

  const { dates = [] } = harvestArgs
  const startDate = generateDate(dates[0])
  const endDate = generateDate(dates[1])

  const noHarvests = harvestArgs?.zones?.reduce((acc, { name }) => {
    const hasData = graphData.some(({ zone }) => zone === name)
    if (!hasData) acc.push(name)
    return acc
  }, [])

  const graphConfig = {
    bottomLabel,
    header: `${dateRange}: ${startDate} - ${endDate}`,
    indexBy,
    enableCustomBar: true,
    noData: noHarvests
  }

  return (
    <OperationsDataboard
      section='harvest'
      keys={tableKeys}
      data={tableRows}
      graphData={graphData}
      graphKeys={graphKeys}
      graphConfig={graphConfig}
      gradesData={weightsByGrade}
      onDownloadCSV={onDownloadCSV}
    >
      <Flex
        key='main'
        direction={isAboveMobileBreakpoint ? 'row' : 'column'}
        axisGap='300'
        alignMainAxis='stretch'
      >
        <Box style={{ flexGrow: '1' }}>
          <ProducesFilter
            state={state}
            dispatchState={dispatchState}
            supportMultiOrg={true}
          />
        </Box>
        <Box style={{ flexGrow: '1' }}>
          <VarietiesFilter state={state} dispatchState={dispatchState} />
        </Box>
        <Box style={{ flexGrow: '1' }}>
          <DateFilter
            state={state}
            dispatchState={dispatchState}
            stateKey='harvestDate'
            isDataboard={true}
          />
        </Box>
        <Button
          onClick={onClickFetch}
          variant='primary'
          size='small'
          disabled={hasDataboardWarnings(state.warnings) || loading}
          loading={loading}
          style={{ width: '6rem' }}
        >
          {I18n.get('Fetch')}
        </Button>
      </Flex>
      <MultiRowSelector state={state} dispatchState={dispatchState} key='sub' />
    </OperationsDataboard>
  )
}

export default HarvestDataBoard

import { useSyncExternalStore, useCallback, useEffect } from 'react'

/* https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event */
const STORAGE_EVENTNAME = 'storage'

function dispatchStorageEvent(key, newValue) {
  window.dispatchEvent(new StorageEvent(STORAGE_EVENTNAME, { key, newValue }))
}

function getLocalStorageItem(key = '') {
  return window.localStorage.getItem(key)
}

function setLocalStorageItem(key = '', value = null) {
  const stringifiedValue = JSON.stringify(value)
  window.localStorage.setItem(key, stringifiedValue)
  dispatchStorageEvent(key, stringifiedValue)
  return true
}

function removeLocalStorageItem(key = '') {
  window.localStorage.removeItem(key)
  dispatchStorageEvent(key, null)
  return true
}

function useLocalStorageSubscribe(callback) {
  if (callback) {
    window.addEventListener(STORAGE_EVENTNAME, callback, { passive: true })
    return () => window.removeEventListener(STORAGE_EVENTNAME, callback)
  }
  return undefined
}

export default function useLocalStorage(key = '', initialValue = null) {
  const getSnapshot = () => getLocalStorageItem(key)

  const store = useSyncExternalStore(useLocalStorageSubscribe, getSnapshot)

  const setState = useCallback(
    (value) => {
      try {
        const nextState =
          typeof value === 'function' ? value(JSON.parse(store)) : value

        if (nextState === undefined || nextState === null) {
          removeLocalStorageItem(key)
        } else {
          setLocalStorageItem(key, nextState)
        }
      } catch (error) {
        throw new Error(error)
      }
    },
    [key, store]
  )

  useEffect(() => {
    if (
      getLocalStorageItem(key) === null &&
      typeof initialValue !== 'undefined'
    ) {
      setLocalStorageItem(key, initialValue)
    }
  }, [key, initialValue])

  return [store ? JSON.parse(store) : initialValue, setState]
}
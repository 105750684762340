import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

export function useDashboardParams() {
  const params = useParams()

  return useMemo(() => {
    if (params.zone && params.zone.includes('set')) {
      const [zoneSegment, measurementSegment] = params.zone.split('/set/')
      return {
        ...params,
        zone: zoneSegment,
        measurement: measurementSegment
      }
    }

    if (params.zone && params.zone.includes('video-feeds')) {
      const [zoneSegment] = params.zone.split('/video-feeds')
      return {
        ...params,
        zone: zoneSegment,
        videoFeeds: 'video-feeds'
      }
    }

    if (params.zone && params.zone.includes('sensor')) {
      const [zoneSegment, sensorSegments] = params.zone.split('/sensor/')
      const [sensorType, sensorId] = sensorSegments?.split('/')

      if (params.sensorId && params.sensorId.includes('data')) {
        const [sensorIdSegment, dataSegments] = params.sensorId.split('/data')
        return {
          ...params,
          zone: zoneSegment,
          sensorType,
          sensorId: sensorIdSegment,
          data: dataSegments
        }
      }

      if (params.sensorId && params.sensorId.includes('calibration')) {
        const [sensorIdSegment, calibrationSegments] =
          params.sensorId.split('/calibration/')
        const [calibrationType, calibrationMeasurement] =
          calibrationSegments?.split('/')
        return {
          ...params,
          zone: zoneSegment,
          sensorType,
          sensorId: sensorIdSegment,
          calibrationType,
          calibrationMeasurement
        }
      }

      return {
        ...params,
        zone: zoneSegment,
        sensorType,
        sensorId
      }
    }

    return params
  }, [params])
}

import { graphqlOperation } from 'aws-amplify'
import { makeGraphqlRequestV2 } from '../utils'
import { GRAPHQL_URL } from './config'

/*
 * Queries
 */
export async function getAllOrganizationLabels(params) {
  try {
    const query = /* GraphQL */ `
      query getAllOrganizationLabels(
        $organizationIds: [String]!
        $lang: String
      ) {
        getAllOrganizationLabels(
          organizationIds: $organizationIds
          lang: $lang
        ) {
          labels
          organizationId
        }
      }
    `

    const operation = graphqlOperation(query, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.getAllOrganizationLabels
  } catch (err) {
    return err.message
  }
}

export async function getOrganizationLabels(params) {
  try {
    const query = /* GraphQL */ `
      query getOrganizationLabels($organizationId: String!) {
        getOrganizationLabels(organizationId: $organizationId) {
          organizationId
          labelId
          label
          replacementLabel
          replacementLabelPlural
          replacementLabelLang
          translations {
            lang
            text
            textPlural
          }
        }
      }
    `

    const operation = graphqlOperation(query, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.getOrganizationLabels
  } catch (err) {
    return err.message
  }
}

export async function getOrganizationLabel(params) {
  try {
    const query = /* GraphQL */ `
      query getOrganizationLabel($organizationId: String!, $labelId: String!) {
        getOrganizationLabel(
          organizationId: $organizationId
          labelId: $labelId
        ) {
          organizationId
          labelId
          label
          replacementLabel
          replacementLabelPlural
          replacementLabelLang
          translations {
            lang
            text
            textPlural
          }
        }
      }
    `

    const operation = graphqlOperation(query, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.getOrganizationLabel
  } catch (err) {
    return err.message
  }
}

/*
 * Mutations
 */

export async function createOrganizationLabel(params) {
  try {
    const mutation = /* GraphQL */ `
      mutation createOrganizationLabel(
        $organizationId: String!
        $label: String!
        $replacementLabel: String!
        $replacementLabelPlural: String
        $replacementLabelLang: String
      ) {
        createOrganizationLabel(
          organizationId: $organizationId
          label: $label
          replacementLabel: $replacementLabel
          replacementLabelPlural: $replacementLabelPlural
          replacementLabelLang: $replacementLabelLang
        ) {
          organizationId
          labelId
          label
          replacementLabel
          replacementLabelPlural
          replacementLabelLang
          translations {
            lang
            text
            textPlural
          }
        }
      }
    `

    const operation = graphqlOperation(mutation, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.createOrganizationLabel
  } catch (err) {
    return err.message
  }
}

export async function updateOrganizationLabel(params) {
  try {
    const mutation = /* GraphQL */ `
      mutation updateOrganizationLabel(
        $organizationId: String!
        $labelId: String!
        $label: String
        $replacementLabel: String
        $replacementLabelPlural: String
        $replacementLabelLang: String
      ) {
        updateOrganizationLabel(
          organizationId: $organizationId
          labelId: $labelId
          label: $label
          replacementLabel: $replacementLabel
          replacementLabelPlural: $replacementLabelPlural
          replacementLabelLang: $replacementLabelLang
        ) {
          organizationId
          labelId
          label
          replacementLabel
          replacementLabelPlural
          replacementLabelLang
          translations {
            lang
            text
            textPlural
          }
        }
      }
    `

    const operation = graphqlOperation(mutation, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.updateOrganizationLabel
  } catch (err) {
    return err.message
  }
}

export async function updateOrganizationLabelTranslations(params) {
  try {
    const mutation = /* GraphQL */ `
      mutation updateOrganizationLabelTranslations(
        $organizationId: String!
        $labelId: String!
      ) {
        updateOrganizationLabelTranslations(
          organizationId: $organizationId
          labelId: $labelId
        ) {
          organizationId
          labelId
          label
          replacementLabel
          replacementLabelPlural
          replacementLabelLang
          translations {
            lang
            text
            textPlural
          }
        }
      }
    `

    const operation = graphqlOperation(mutation, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.updateOrganizationLabelTranslations
  } catch (err) {
    return err.message
  }
}

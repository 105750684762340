import { call, put, takeLatest } from 'redux-saga/effects'

import {
  REQUEST_PACKAGE_UNIT_INVENTORIES,
  REQUEST_PACKAGE_UNIT_INVENTORY,
  receivePackageUnitInventories,
  receivePackageUnitInventory
} from '@/actions/operations/packageUnitInventory'

import {
  getPackageUnitInventories,
  getPackageUnitInventory
} from '@/api/operations/packageUnitInventory'

function* sendRequestPackageUnitInventories(action) {
  const { data, error = null } = yield call(
    getPackageUnitInventories,
    action.params
  )
  const { packageUnitInventories = [], count = 0 } = data
  yield put(receivePackageUnitInventories(packageUnitInventories, count, error))
}

export function* watchRequestPackageUnitInventories() {
  yield takeLatest(
    REQUEST_PACKAGE_UNIT_INVENTORIES,
    sendRequestPackageUnitInventories
  )
}

function* sendRequestPackageUnitInventory(action) {
  const { data: packageUnitInventory, error = null } = yield call(
    getPackageUnitInventory,
    action.params
  )
  yield put(receivePackageUnitInventory(packageUnitInventory, error))
}

export function* watchRequestPackageUnitInventory() {
  yield takeLatest(
    REQUEST_PACKAGE_UNIT_INVENTORY,
    sendRequestPackageUnitInventory
  )
}

import { call, put, takeLatest } from 'redux-saga/effects'

import {
  getWastes,
  getWaste,
  createWaste,
  updateWaste
} from '@/api/operations/waste'

import {
  REQUEST_WASTES,
  REQUEST_WASTE,
  CREATE_WASTE,
  UPDATE_WASTE,
  receiveWastes,
  receiveWaste,
  wasteCreated,
  wasteUpdated
} from '@/actions/operations/waste'

import { displayBanner } from '../utils'

import Strings from './Strings'

const strings = Strings()

function* sendCreateWaste(action) {
  const { data, error = null } = yield call(createWaste, action.params)
  yield put(displayBanner(error, strings.wasteCreated))
  yield put(wasteCreated(data, error))
}

export function* watchCreateWaste() {
  yield takeLatest(CREATE_WASTE, sendCreateWaste)
}

function* sendUpdateWaste(action) {
  const { data, error = null } = yield call(updateWaste, action.params)
  yield put(displayBanner(error, strings.wasteUpdated))
  yield put(wasteUpdated(data, error))
}

export function* watchUpdateWaste() {
  yield takeLatest(UPDATE_WASTE, sendUpdateWaste)
}

function* sendRequestWastes(action) {
  const { data, error = null } = yield call(getWastes, action.params)
  const { wastes = [], count = 0 } = data
  yield put(receiveWastes(wastes, count, error))
}

export function* watchRequestWastes() {
  yield takeLatest(REQUEST_WASTES, sendRequestWastes)
}

function* sendRequestWaste(action) {
  const { data, error = null } = yield call(getWaste, action.params)
  yield put(receiveWaste(data, error))
}

export function* watchRequestWaste() {
  yield takeLatest(REQUEST_WASTE, sendRequestWaste)
}

import { getZonesHierarchy } from '../../../../../reducers/selectors'

import { Button, Cell, Row } from '../../../../../primitives'

import { formatDate, formatNumber } from '../../utils'
import { CROP_STATUSES, getCropStatusDate } from '../utils'

import ZoneUtils from '../../../../../Util/ZoneUtils'

import Strings from '../Strings'

function TableRow({ item, onEdit, canEdit }) {
  const zonesHierarchy = getZonesHierarchy()
  const strings = Strings()

  const statuses = CROP_STATUSES(strings)

  const {
    id,
    siteId,
    zoneId,
    status,
    produce,
    variety,
    plantingArea,
    seedQuantity,
    ...fields
  } = item

  const getZoneName = (site, zone) => {
    return ZoneUtils.getZoneName(zonesHierarchy, site, zone)
  }

  const handleEdit = () => {
    onEdit(id)
  }

  return (
    <Row>
      <Cell columnName={strings.location}>
        {getZoneName(siteId)}
        <span>{getZoneName(siteId, zoneId)}</span>
      </Cell>
      <Cell columnName={strings.produce}>
        {produce.name}
        <span>{variety.name}</span>
      </Cell>
      <Cell columnName={strings.volume}>
        {formatNumber(seedQuantity)} {strings.seeds}
        <span>
          {formatNumber(plantingArea)}
          {strings.metersSquared}
        </span>
      </Cell>
      <Cell columnName={strings.status}>
        {statuses[status]}
        <span>{formatDate(getCropStatusDate(status, fields))}</span>
      </Cell>
      <Cell className='Operations__Table__Column__Actions'>
        {canEdit && (
          <Button
            variant='info'
            size='small'
            iconBefore='edit'
            onClick={handleEdit}
          />
        )}
      </Cell>
    </Row>
  )
}

export default TableRow

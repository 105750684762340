import { I18n } from 'aws-amplify'

const Strings = () => ({
  otaSuccessMessage: I18n.get('OTA update started.'),
  otaFormTitle: I18n.get('OTA Device Update'),
  coreFormTitle: I18n.get('Core Device Update'),
  destination: I18n.get('Destination'),
  devicesForUpdate: I18n.get('Devices to be updated:'),
  devicesUpdateNote: I18n.get('This action will update all Greengrass sensors within the device(s) listed above.'),
  sensorsForUpdate: I18n.get('Sensors to be updated:'),
  coreDevicesForUpdate: I18n.get('Core Devices for update:'),
  close: I18n.get('Close'),
  submit: I18n.get('Submit')
})

export default Strings

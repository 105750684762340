import { useMemo } from 'react'

import { Button, FlexV2, Text } from '@/primitives'

import { getZoneDepth } from '@/Util/AdminUtils'
import useOrgLabel from '@/hooks/useOrgLabel'

import SectionHeaderWrapper from '../Utils/SectionHeaderWrapper'
import { getZoneSubzonesStrings } from '../../utils'

const SubzoneHeader = ({ buttonAction, selectedZone, permissions }) => {
  const orgLabels = useOrgLabel(['site', 'facility', 'room', 'zone', 'subzone'])
  const { heading, subheading, buttonText } = getZoneSubzonesStrings(
    selectedZone,
    orgLabels
  )

  /*
    Check if parentpath on selectedZone is of a maximum depth
    We don't want to display anything higher than first Subzone (4)
  */
  const enableChildZoneAddition = useMemo(
    () => getZoneDepth(selectedZone) < 5,
    [selectedZone]
  )

  return (
    <SectionHeaderWrapper>
      <FlexV2 direction='column' axisGap={100}>
        <Text size={300} fontWeight={700}>
          {heading}
        </Text>
        <Text fontFamily='mono' variant='page' tone={900} size={200}>
          {`${subheading} ${selectedZone?.name}.`}
        </Text>
      </FlexV2>

      {permissions && enableChildZoneAddition && (
        <Button variant='primary' size='small' onClick={buttonAction}>
          {buttonText}
        </Button>
      )}
    </SectionHeaderWrapper>
  )
}

export default SubzoneHeader

import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import OTAModal from '../Modals/OTA'
import CoreDeviceUpdateModal from '../Modals/CoreDeviceUpdate'

import { Text, FlexV2, Button } from '@/primitives'
import { LineSeparator, Select } from '@/elements'
import {
  AVAILABLE_FEATURE_FLAGS,
  ENABLE_DIAGNOSTICS,
  hasFeatureFlagEnabled
} from '@/Util/FeatureFlagsUtils'
import {
  startPingDeviceQueryDirectConnect,
  startPingDeviceQueryGreengrass,
  clearFeedback
} from '@/slices/pinger'
import { sendCreateBatchNvsDumpRequest } from '@/slices/calibration/nvs'
import { showBanner } from '@/slices/util'

import {
  getCurrentUser,
  getIsLoading,
  getDeviceStatusesError,
  getDeviceStatusesSuccess,
  getZone
} from '@/reducers/selectors'

import { useFleetManagement } from '../context'

import { BULK_ACTION, CLEAR_SELECTION } from '../state'

import { useAuth } from '@/contexts/auth-context'
import { fetchSimDiagnosticInfo } from '@/slices/diagnostics/sensorSim'

import {
  fetchSensorsDiagnosticInfo,
  sendRestartSensors
} from '@/slices/diagnostics/sensor'

import {
  countSensors,
  getGreengrassPayload,
  getDirectConnectPayload,
  getSelectedSensors
} from '../utils'
import { BULK_ACTION_OPTIONS, DIAGNOSTICS_ACTIONS } from '../config'

import Strings from '../../../Strings'
import useDeviceSize from '@/hooks/useDeviceSize'
import SimPingModal from '../Modals/SimPingModal'

const countInitialState = {
  devices: 0,
  sensors: 0
}

function getSelectedDevicesCount(sensorsByZone, tabId) {
  return sensorsByZone.reduce(
    (acc, zone) => {
      const { sensors, type, selected } = zone

      if (tabId === 'greengrass' && tabId === type) {
        if (selected) acc.devices++
        sensors.forEach(({ selected }) => {
          if (selected) acc.sensors++
        })
      }

      if (tabId === 'direct' && tabId === type) {
        sensors.forEach(({ selected }) => {
          if (selected) acc.sensors++
        })
      }

      return acc
    },
    { ...countInitialState }
  )
}

function getDisableButton(devicesCount, sensorsCount, bulkActionValue) {
  if (bulkActionValue === 'core') {
    return devicesCount === 0
  }

  return devicesCount === 0 && sensorsCount === 0
}

const ActionBar = () => {
  const dispatch = useDispatch()
  const auth = useAuth()

  const userId = auth.getCognitoId()
  const coretexUser = getCurrentUser()
  const isLoading = getIsLoading()
  const errorMsg = getDeviceStatusesError()
  const successMsg = getDeviceStatusesSuccess()

  const isTablet = useDeviceSize()

  const {
    state: {
      siteId,
      availableSensors,
      tabId,
      bulkAction,
      count,
      sensorsByZone
    },
    dispatchState
  } = useFleetManagement()

  const [otaModalOpen, setOtaModalOpen] = useState(false)
  const [coreDeviceModalOpen, setCoreDeviceModalOpen] = useState(false)
  const [simPingModalOpen, setSimPingModalOpen] = useState(false)
  const site = getZone()
  const [filteredOptions, setFilteredOptions] = useState([])

  const {
    fleetManagementResetSelection,
    fleetManagementDevicesSelected,
    fleetManagementSensorsSelected,
    fleetManagementPerformActionBtn
  } = Strings()

  const sensorCounts = countSensors(availableSensors)

  const enableDiagnostics = hasFeatureFlagEnabled(
    coretexUser,
    AVAILABLE_FEATURE_FLAGS[ENABLE_DIAGNOSTICS],
    site?.organizationId ?? null
  )

  function toggleOtaModal(e) {
    e?.preventDefault()
    setOtaModalOpen(!otaModalOpen)
  }
  function toggleCoreDeviceModal(e) {
    e?.preventDefault()
    setCoreDeviceModalOpen(!coreDeviceModalOpen)
  }

  function onChangeBulkAction(option) {
    dispatchState({ type: BULK_ACTION, payload: { bulkAction: option } })
  }

  function onResetSelection() {
    dispatchState({
      type: CLEAR_SELECTION
    })
  }

  function onSubmitBulkAction() {
    const selectedSensors = getSelectedSensors(sensorsByZone)
    const sensorIds = selectedSensors.map(({ id }) => id)

    switch (bulkAction.value) {
      case 'ota_sensor':
        toggleOtaModal()
        break
      case 'ota_core':
        toggleOtaModal()
        break
      case 'core':
        toggleCoreDeviceModal()
        break
      case 'nvsdump':
        const devices = sensorsByZone.reduce((acc, zone) => {
          const sensorsArgs = zone.sensors
            .filter(({ selected }) => selected)
            .map(({ id, isGreengrass, sensorType }) => ({
              userId,
              sensorId: id,
              isGreengrass,
              sensorType
            }))
          return [...acc, ...sensorsArgs]
        }, [])
        dispatch(sendCreateBatchNvsDumpRequest({ devices }))
        break
      case 'ping':
        if (tabId === 'greengrass') {
          const params = getGreengrassPayload(
            siteId,
            coretexUser?.userName,
            sensorsByZone
          )
          dispatch(startPingDeviceQueryGreengrass(params))
        }

        if (tabId === 'direct') {
          const params = getDirectConnectPayload(
            siteId,
            coretexUser?.userName,
            sensorsByZone
          )
          dispatch(startPingDeviceQueryDirectConnect(params))
        }
        break
      case 'sim_diagnostic_info':
        dispatch(fetchSimDiagnosticInfo({ sensorIds }))
        break
      case 'sim_diagnostic_ping':
        setSimPingModalOpen(true)
        break
      case 'sensor_diagnostic_info':
        dispatch(fetchSensorsDiagnosticInfo({ sensorIds }))
        break
      case 'sensor_restart':
        dispatch(sendRestartSensors({ sensorIds }))
        break
      default:
        break
    }
  }

  useEffect(() => {
    let options = BULK_ACTION_OPTIONS
    if (tabId === 'direct') {
      options = options.filter(
        ({ value }) => value !== 'core' && value !== 'ota_core'
      )
      if (enableDiagnostics) {
        options = options.concat(DIAGNOSTICS_ACTIONS)
      }
    }
    setFilteredOptions(options)
  }, [tabId, dispatchState, enableDiagnostics])

  useEffect(() => {
    dispatchState({
      type: CLEAR_SELECTION
    })
  }, [sensorCounts.totalSensors, dispatchState, dispatch])

  useEffect(() => {
    if (errorMsg?.length > 0) {
      dispatch(
        showBanner({
          show: true,
          message: errorMsg,
          type: 'error'
        })
      )
      dispatch(clearFeedback())
    }

    if (successMsg?.length > 0) {
      dispatch(
        showBanner({
          show: true,
          message: successMsg,
          type: 'success'
        })
      )
      dispatch(clearFeedback())
      dispatchState({
        type: CLEAR_SELECTION
      })
    }
  }, [errorMsg, successMsg, dispatchState, dispatch])

  useEffect(() => {
    if (errorMsg?.length > 0) {
      dispatch(
        showBanner({
          show: true,
          message: errorMsg,
          type: 'error'
        })
      )
      dispatch(clearFeedback())
    }

    if (successMsg?.length > 0) {
      dispatch(
        showBanner({
          show: true,
          message: successMsg,
          type: 'success'
        })
      )
      dispatch(clearFeedback())
      dispatchState({
        type: CLEAR_SELECTION
      })
    }
  }, [errorMsg, successMsg, dispatchState, dispatch])

  const selectedCount = getSelectedDevicesCount(sensorsByZone, tabId)

  return (
    <FlexV2 direction={'column'} axisGap={300}>
      {otaModalOpen && (
        <OTAModal showModal={otaModalOpen} toggleModal={setOtaModalOpen} />
      )}
      {coreDeviceModalOpen && (
        <CoreDeviceUpdateModal
          showModal={coreDeviceModalOpen}
          toggleModal={setCoreDeviceModalOpen}
        />
      )}
      {simPingModalOpen && (
        <SimPingModal
          showModal={simPingModalOpen}
          toggleModal={setSimPingModalOpen}
        />
      )}

      <LineSeparator />
      <FlexV2
        alignCrossAxis='flex-start'
        axisGap={500}
        direction={isTablet ? 'column' : 'row'}
      >
        <FlexV2 axisGap={500} style={{ flex: 1 }}>
          <Button size='small' variant='error' onClick={onResetSelection}>
            {fleetManagementResetSelection}
          </Button>
        </FlexV2>

        <FlexV2
          alignCrossAxis={isTablet ? 'flex-start' : 'center'}
          alignMainAxis='flex-end'
          axisGap={500}
          direction={isTablet ? 'column' : 'row'}
          style={{ flexGrow: '1' }}
        >
          <FlexV2 axisGap={500} direction='row'>
            {tabId === 'greengrass' && (
              <Text
                variant='page'
                fontWeight={400}
                tone={700}
                size={200}
                fontFamily='mono'
              >
                {`${fleetManagementDevicesSelected}: ${selectedCount.devices}/${count?.greengrassDevices}`}
              </Text>
            )}
            <Text
              style={{ marginInlineStart: '1rem' }}
              variant='page'
              fontWeight={400}
              tone={700}
              size={200}
              fontFamily='mono'
            >
              {`${fleetManagementSensorsSelected}: ${selectedCount.sensors}/${tabId === 'greengrass'
                ? count?.greengrassSensors
                : count?.directConnectSensors
                }`}
            </Text>
          </FlexV2>
          <FlexV2
            axisGap={500}
            direction='row'
            style={{ flexGrow: '1', maxWidth: '27rem' }}
          >
            <Select
              className='Device__Actions__Select'
              classNamePrefix='Device__Actions__Select'
              value={bulkAction.value}
              options={filteredOptions}
              onChange={onChangeBulkAction}
            />
            <Button
              loading={isLoading}
              size='small'
              variant='primary'
              onClick={onSubmitBulkAction}
              disabled={getDisableButton(
                selectedCount.devices,
                selectedCount.sensors,
                bulkAction.value
              )}
            >
              {fleetManagementPerformActionBtn}
            </Button>
          </FlexV2>
        </FlexV2>
      </FlexV2>
      <LineSeparator />
    </FlexV2>
  )
}

export default ActionBar

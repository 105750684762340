import { makeActionCreator } from '@/Util/ReduxUtils'

export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS'
export const requestProducts = makeActionCreator(REQUEST_PRODUCTS, 'params')

export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS'
export const receiveProducts = makeActionCreator(
  RECEIVE_PRODUCTS,
  'products',
  'count',
  'error'
)

export const CREATE_PRODUCT = 'CREATE_PRODUCT'
export const createProduct = makeActionCreator(CREATE_PRODUCT, 'params')

export const PRODUCT_CREATED = 'PRODUCT_CREATED'
export const productCreated = makeActionCreator(
  PRODUCT_CREATED,
  'product',
  'error'
)

export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const updateProduct = makeActionCreator(UPDATE_PRODUCT, 'params')

export const PRODUCT_UPDATED = 'PRODUCT_UPDATED'
export const productUpdated = makeActionCreator(
  PRODUCT_UPDATED,
  'product',
  'error'
)

export const REQUEST_PRODUCT = 'REQUEST_PRODUCT'
export const requestProduct = makeActionCreator(REQUEST_PRODUCT, 'params')

export const RECEIVE_PRODUCT = 'RECEIVE_PRODUCT'
export const receiveProduct = makeActionCreator(
  RECEIVE_PRODUCT,
  'product',
  'error'
)

export const CLEAR_PRODUCT = 'CLEAR_PRODUCT'
export const clearProduct = makeActionCreator(CLEAR_PRODUCT)

export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS'
export const clearProducts = makeActionCreator(CLEAR_PRODUCTS)

export const SET_PRODUCT = 'SET_PRODUCT'
export const setProduct = makeActionCreator(SET_PRODUCT, 'product')

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { I18n } from 'aws-amplify'

import { createTag, getTags, updateTag } from '@/api/fyi/tags'
import { showBanner } from '../util'

const feedbackStrings = {
  getTags: {
    error: I18n.get('Could not fetch tags')
  },
  createTag: {
    success: I18n.get('Tag created'),
    error: I18n.get('Could not create tag')
  },
  updateTag: {
    success: I18n.get('Tag updated'),
    error: I18n.get('Could not update tag')
  }
}

function dispatchFeedback(dispatch, error, context) {
  const strings = feedbackStrings[context]
  const type = error ? 'error' : 'success'
  const message = error ? strings.error : strings.success

  dispatch(
    showBanner({
      type,
      show: true,
      message
    })
  )
}

export const fetchTags = createAsyncThunk(
  'fetchTags',
  async (params, { dispatch }) => {
    const response = await getTags()
    if (response.error) dispatchFeedback(dispatch, response.error, 'getTags')
    return response
  }
)

export const sendCreateTag = createAsyncThunk(
  'sendCreateTag',
  async (params, { dispatch }) => {
    const response = await createTag(params)
    dispatchFeedback(dispatch, response.error, 'createTag')
    return response
  }
)

export const sendUpdateTag = createAsyncThunk(
  'sendUpdateTag',
  async (params, { dispatch }) => {
    const response = await updateTag(params)
    dispatchFeedback(dispatch, response.error, 'updateTag')
    return response
  }
)

const fyiSlice = createSlice({
  name: 'fyiTagsReducer',
  initialState: {
    tags: [],
    tagUpserted: {},
    tagsRequested: false,
    error: null,
    loading: false
  },
  reducers: {
    cleanUpsertedTag: (state, action) => {
      state.tagUpserted = {}
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTags.pending, state => {
        state.error = null
        state.loading = true
        state.tagsRequested = true
      })
      .addCase(fetchTags.fulfilled, (state, action) => {
        if (!action?.payload?.error) {
          state.tags = action?.payload?.tags
        }
        state.error = action?.payload?.error ?? null
        state.loading = false
      })
      .addCase(sendCreateTag.pending, state => {
        state.error = null
        state.loading = true
      })
      .addCase(sendCreateTag.fulfilled, (state, action) => {
        if (!action?.payload?.error) {
          state.tags = [...state.tags, action?.payload?.tag]
          state.tagUpserted = action?.payload?.tag
        }
        state.error = action?.payload?.error ?? null
        state.loading = false
      })
      .addCase(sendUpdateTag.pending, state => {
        state.error = null
        state.loading = true
      })
      .addCase(sendUpdateTag.fulfilled, (state, action) => {
        if (!action?.payload?.error) {
          const tags = [...state.tags]
          const updatedTag = action?.payload?.tag
          const index = tags.findIndex(
            ({ tagId }) => tagId === updatedTag.tagId
          )
          tags[index] = updatedTag
          state.tags = tags
          state.tagUpserted = updatedTag
        }
        state.error = action?.payload?.error ?? null
        state.loading = false
      })
  }
})

const { actions, reducer } = fyiSlice

export const { cleanUpsertedTag } = actions

export default reducer

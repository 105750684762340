import { updateList } from '../util'

import {
  REQUEST_PRODUCTS,
  RECEIVE_PRODUCTS,
  CLEAR_PRODUCTS,
  CLEAR_PRODUCT,
  RECEIVE_PRODUCT,
  REQUEST_PRODUCT,
  CREATE_PRODUCT,
  PRODUCT_CREATED,
  UPDATE_PRODUCT,
  PRODUCT_UPDATED,
  SET_PRODUCT
} from '@/actions/operations/product'

export const productInitialState = {
  products: [],
  product: {},
  productsCount: 0,
  loading: false,
  error: null
}

const handlers = {
  [SET_PRODUCT]: (state, action) => ({
    ...state,
    product: action.product
  }),
  [CLEAR_PRODUCT]: (state, action) => ({
    ...state,
    product: {},
    error: null
  }),
  [CLEAR_PRODUCTS]: (state, action) => ({
    ...state,
    products: [],
    productsCount: 0,
    error: null
  }),
  [REQUEST_PRODUCT]: (state, action) => ({
    ...state,
    product: {},
    error: null,
    loading: true
  }),
  [RECEIVE_PRODUCT]: (state, action) => ({
    ...state,
    product: !action.error ? action.product : state.product,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_PRODUCTS]: (state, action) => ({
    ...state,
    products: [],
    productsCount: 0,
    error: null,
    loading: true
  }),
  [RECEIVE_PRODUCTS]: (state, action) => ({
    ...state,
    products: !action.error ? action.products : [],
    productsCount: !action.error ? action.count : 0,
    error: action.error ?? null,
    loading: false
  }),
  [CREATE_PRODUCT]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [PRODUCT_CREATED]: (state, action) => ({
    ...state,
    product: !action.error ? {} : state.product,
    products: updateList(action.error, state.products, action.product, true),
    productsCount: !action.error
      ? state.productsCount + 1
      : state.productsCount,
    error: action.error ?? null,
    loading: false
  }),
  [UPDATE_PRODUCT]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [PRODUCT_UPDATED]: (state, action) => ({
    ...state,
    product: !action.error ? {} : state.product,
    products: updateList(action.error, state.products, action.product, false),
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

import { I18n } from 'aws-amplify'

import useThresholdStatus from '../../Shared/Hooks/useThresholdStatus'
import { useLiveData } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Hooks/useLiveData'
import { parseValue } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/utils'

import Value from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/Chart/Value'
import { convertToVPD } from '@/Util/MeasurementUtils'

export default function LiveValue({ unit, options }) {
  const data = useLiveData({
    measurements: ['temperature', 'humidity']
  })
  const thresholdStatus = useThresholdStatus(options.measurements)

  const parseOptions = (temperature, humidity) => ({
    fixedFactor: options.fixedFactor,
    scaleFactor: options.scaleFactor,
    value: convertToVPD({ temperature, humidity })
  })

  return (
    <Value
      thresholdStatus={thresholdStatus}
      data={{
        value: parseValue(
          parseOptions(data?.temperature?.value, data?.humidity?.value)
        ),
        min: parseValue(
          parseOptions(data?.temperature?.min, data?.humidity?.min)
        ),
        max: parseValue(
          parseOptions(data?.temperature?.max, data?.humidity?.max)
        ),
        unit,
        status: data?.temperature?.status || data?.humidity?.status,
        subtitle: I18n.get('Live')
      }}
    />
  )
}

import { graphqlOperation } from 'aws-amplify'

import { makeGraphqlRequestV2 } from '../utils'
import { GRAPHQL_URL } from './config'

/*
 * Queries
 */

export const getSensorThresholdUserSnooze = async params => {
  try {
    const query = /* GraphQL */ `
      query getSensorThresholdUserSnooze(
        $userId: String!
        $sensorId: String!
        $measurementId: String!
        $zoneId: String!
      ) {
        getSensorThresholdUserSnooze(
          userId: $userId
          sensorId: $sensorId
          measurementId: $measurementId
          zoneId: $zoneId
        ) {
          userId
          sensorId
          zoneId
          measurementId
          createdAt
          expiresAt
        }
      }
    `

    const operation = graphqlOperation(query, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.getSensorThresholdUserSnooze
  } catch (err) {
    return err
  }
}

export const getSensorThresholdUserSnoozes = async params => {
  try {
    const query = /* GraphQL */ `
      query getSensorThresholdUserSnoozes($userSnoozes: [UserSnoozeRequest!]) {
        getSensorThresholdUserSnoozes(userSnoozes: $userSnoozes) {
          userId
          sensorId
          zoneId
          measurementId
          createdAt
          expiresAt
        }
      }
    `

    const operation = graphqlOperation(query, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)
    return data?.getSensorThresholdUserSnoozes
  } catch (err) {
    throw err.message
  }
}

/*
 * Mutations
 */

export const createSensorThresholdUserSnooze = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createSensorThresholdUserSnooze(
        $userId: String!
        $sensorId: String!
        $measurementId: String!
        $zoneId: String!
        $expiresAt: Float!
        $rootId: String!
      ) {
        createSensorThresholdUserSnooze(
          userId: $userId
          sensorId: $sensorId
          zoneId: $zoneId
          measurementId: $measurementId
          expiresAt: $expiresAt
          rootId: $rootId
        ) {
          userId
          sensorId
          zoneId
          measurementId
          expiresAt
          createdAt
        }
      }
    `

    const operation = graphqlOperation(mutation, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.createSensorThresholdUserSnooze
  } catch (err) {
    throw err
  }
}

export const deleteSensorThresholdUserSnooze = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation deleteSensorThresholdUserSnooze(
        $userId: String!
        $sensorId: String!
        $measurementId: String!
        $zoneId: String!
        $rootId: String!
      ) {
        deleteSensorThresholdUserSnooze(
          userId: $userId
          sensorId: $sensorId
          zoneId: $zoneId
          measurementId: $measurementId
          rootId: $rootId
        ) {
          userId
          sensorId
          zoneId
          measurementId
          createdAt
          expiresAt
        }
      }
    `

    const operation = graphqlOperation(mutation, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.deleteSensorThresholdUserSnooze
  } catch (err) {
    throw err
  }
}

import { updateList } from '../util'

import {
  RECEIVE_ALL_SHIPPING_METHODS,
  REQUEST_ALL_SHIPPING_METHODS,
  RECEIVE_SHIPPING_METHODS,
  REQUEST_SHIPPING_METHODS,
  CLEAR_SHIPPING_METHODS,
  CLEAR_SHIPPING_METHOD,
  REQUEST_SHIPPING_METHOD,
  RECEIVE_SHIPPING_METHOD,
  CREATE_SHIPPING_METHOD,
  SHIPPING_METHOD_CREATED,
  UPDATE_SHIPPING_METHOD,
  SHIPPING_METHOD_UPDATED,
  SET_SHIPPING_METHOD
} from '@/actions/operations/shippingMethod'

export const shippingMethodInitialState = {
  allShippingMethods: [],
  shippingMethods: [],
  shippingMethod: {},
  shippingMethodsCount: 0,
  loading: false,
  error: null
}

const handlers = {
  [CLEAR_SHIPPING_METHOD]: (state, action) => ({
    ...state,
    shippingMethod: {},
    error: null
  }),
  [CLEAR_SHIPPING_METHODS]: (state, action) => ({
    ...state,
    shippingMethods: [],
    error: null
  }),
  [REQUEST_ALL_SHIPPING_METHODS]: state => ({
    ...state,
    error: null,
    loading: true
  }),
  [RECEIVE_ALL_SHIPPING_METHODS]: (state, action) => ({
    ...state,
    allShippingMethods: !action.error ? action.shippingMethods : [],
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_SHIPPING_METHOD]: (state, action) => ({
    ...state,
    shippingMethod: {},
    error: null,
    loading: true
  }),
  [RECEIVE_SHIPPING_METHOD]: (state, action) => ({
    ...state,
    shippingMethod: !action.error
      ? action.shippingMethod
      : state.shippingMethod,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_SHIPPING_METHODS]: (state, action) => ({
    ...state,
    shippingMethods: [],
    error: null,
    loading: true
  }),
  [RECEIVE_SHIPPING_METHODS]: (state, action) => ({
    ...state,
    shippingMethods: !action.error ? action.shippingMethods : [],
    shippingMethodsCount: !action.error ? action.count : 0,
    error: action.error ?? null,
    loading: false
  }),
  [SET_SHIPPING_METHOD]: (state, action) => ({
    ...state,
    shippingMethod: action.shippingMethod
  }),
  [CREATE_SHIPPING_METHOD]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [SHIPPING_METHOD_CREATED]: (state, action) => ({
    ...state,
    shippingMethod: !action.error ? {} : state.shippingMethod,
    shippingMethods: updateList(
      action.error,
      state.shippingMethods,
      action.shippingMethod,
      true
    ),
    allShippingMethods: updateList(
      action.error,
      state.allShippingMethods,
      action.shippingMethod,
      true
    ),
    shippingMethodsCount: !action.error
      ? state.shippingMethodsCount + 1
      : state.shippingMethodsCount,
    error: action.error ?? null,
    loading: false
  }),
  [UPDATE_SHIPPING_METHOD]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [SHIPPING_METHOD_UPDATED]: (state, action) => ({
    ...state,
    shippingMethod: !action.error ? {} : state.shippingMethod,
    shippingMethods: updateList(
      action.error,
      state.shippingMethods,
      action.shippingMethod,
      false
    ),
    allShippingMethods: updateList(
      action.error,
      state.allShippingMethods,
      action.shippingMethod,
      false
    ),
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

import { Fragment, useEffect, useReducer, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'

import { Box, Loader, FlexV2 } from '@/primitives'
import {
  getRootZone,
  getCurrentUser,
  getDashboards,
  getDashboardResetting,
  getDashboardReInit,
  getDevice,
  getZoneHierarchy,
  getZoneHierarchyLoading,
  getDeviceTypes,
  getDeviceLoading,
  getLang
} from '@/reducers/selectors'
import { cleanReInit, sendUpdateDashboard } from '@/slices/management/dashboard'
import { fetchDevice } from '@/slices/management/device'
import { fetchZoneHierarchy } from '@/slices/management/hierarchy'
import { fetchRootZone } from '@/slices/management/zone'
import { setLocationSelector } from '@/slices/navigation'
import { cleanSensorThresholdNotificationSnoozes } from '@/slices/threshold/sensorThresholdUserSnooze'
import { cleanSensorThresholdUserAck } from '@/slices/threshold/sensorThresholdUserAck'

import ZoneUtils from '@/Util/ZoneUtils'
import { buildNavigationSelector } from '@/Util/NavigationUtils'

import { DashboardContext } from './context'
import {
  dashboardInitialState,
  dashboardReducer,
  INITIALIZE_DASHBOARD,
  SET_CURRENT_DASHBOARD,
  SET_CURRENT_ZONE,
  SET_HIERARCHY,
  SET_IS_404,
  SET_SITE_ID
} from './state'

import { getBreakpointFromScreenSize } from './config'
import {
  cloneFlatZone,
  dashboardConfigToWidgetMapper,
  getSiteIdFromZonePath,
  widgetMapToDashboardConfig,
  createSensorDashboard,
  isSome404
} from './utils'
import { useLiveDataFeed } from './utils/useLiveDataFeed'
import { useDashboardParams } from '../utils/useDashboardParams'

import MosaicLayout from './Mosaic/Layout'
import Header from './Header'
import Zone404 from './Zone404'

import Toolbox from './Toolbox'
import Notifications from './Notifications'
import { widgetRegistry } from './Widgets'


import './index.scss'

const getDepth = zonePath => zonePath?.split('/')?.length - 1

function findCurrentDashboardFromArray(params, dashboards, hierarchy) {
  const zone = ZoneUtils.getZoneHierarchyValueByPath(hierarchy, params.zone)

  const currentContext = ZoneUtils.getZoneDepthIdentifierFromDepth(
    getDepth(params.zone)
  )
  let currentDashboard = {}
  if (zone && !params.sensorId) {
    // Find first by zonePath
    currentDashboard = dashboards.find(({ zonePath, context }) => {
      const sameContext = context === currentContext
      const isSite = zone.parentPath === null

      if (!sameContext) {
        return false
      }

      return isSite ? zonePath === `/${zone.id}` : zonePath === zone.parentPath
    })

    if (!currentDashboard) {
      // If not found, find by context
      currentDashboard = dashboards.find(
        dashboard => dashboard.context === currentContext && dashboard.isDefault
      )
    }
  }

  return currentDashboard
}

function initializeDashboardState(
  params,
  dashboards,
  hierarchy,
  dispatchState
) {
  const currentDashboard = findCurrentDashboardFromArray(
    params,
    dashboards,
    hierarchy
  )

  const config = !params.sensorId ? cloneDeep(currentDashboard?.config) : []

  if (config) {
    dispatchState({
      type: INITIALIZE_DASHBOARD,
      payload: {
        currentDashboard,
        widgets: dashboardConfigToWidgetMapper(config),
        currentBreakpoint: getBreakpointFromScreenSize(window.innerWidth)
      }
    })
  }
}

function setCurrentZoneState(
  params,
  dashboards,
  stateHierarchy,
  currentBreakpoint,
  device,
  deviceTypes,
  currentUser
) {
  const zone = ZoneUtils.getZoneHierarchyValueByPath(
    stateHierarchy,
    params.zone
  )

  if (!zone) {
    return { is404: 'zone' }
  }

  const clonedZone = cloneFlatZone(zone)
  const currentDashboard = findCurrentDashboardFromArray(
    params,
    dashboards,
    stateHierarchy
  )
  const currentDashboardConfig = currentDashboard?.config || []
  const config = !params.sensorId ? cloneDeep(currentDashboardConfig) : []

  let payload = {
    currentDashboard,
    widgets: dashboardConfigToWidgetMapper(config)
  }

  if (params.sensorId && device?.id && deviceTypes?.length > 0 && clonedZone) {
    payload = createSensorDashboard(
      currentUser.userName,
      device,
      deviceTypes,
      currentBreakpoint
    )
  }

  return {
    is404: params.sensorId && !device?.id ? 'device' : false,
    zone: clonedZone,
    payload
  }
}

export default function DesktopDashboard() {
  const dispatch = useDispatch()
  const zoneHierarchy = getZoneHierarchy()
  const zoneHierarchyLoading = getZoneHierarchyLoading()
  const dashboards = getDashboards()
  const dashboardResetting = getDashboardResetting()
  const dashboardReInit = getDashboardReInit()
  const params = useDashboardParams()
  const deviceTypes = getDeviceTypes()
  const device = getDevice()
  const deviceLoading = getDeviceLoading()
  const currentUser = getCurrentUser()
  const lang = getLang()
  const rootZone = getRootZone()

  const [state, dispatchState] = useReducer(
    dashboardReducer,
    dashboardInitialState
  )

  useLiveDataFeed(state)

  useEffect(() => {
    widgetRegistry.reinstantiate()
  }, [lang])

  useEffect(() => {
    if (state.currentZone?.id && state.hierarchy) {
      const zoneOrg = currentUser.organizations.find(
        org => org.id === state.hierarchy?.organizationId
      )

      const navSelector = buildNavigationSelector(
        state.hierarchy,
        state.currentZone.parentPath,
        zoneOrg
      )

      dispatch(setLocationSelector(navSelector))
    }
  }, [
    state.currentZone,
    currentUser.organizations,
    state.hierarchy,
    dispatch
  ])

  useEffect(() => {
    return () => {
      dispatch(cleanSensorThresholdNotificationSnoozes())
      dispatch(cleanSensorThresholdUserAck())
    }
  }, [dispatch])

  // fetches device on params.sensorId change
  useEffect(() => {
    if (params.sensorId) {
      dispatch(fetchDevice({ deviceId: params.sensorId }))
    }
  }, [params.sensorId, dispatch])

  // Initializes dashboard state once dashboards have been fetched
  useEffect(() => {
    if (dashboards.length > 0 && (!state.initialized || dashboardReInit)) {
      initializeDashboardState(
        params,
        dashboards,
        state.hierarchy,
        dispatchState
      )
      dispatch(cleanReInit())
    }
  }, [
    params,
    state.initialized,
    dashboards,
    dashboardReInit,
    dispatch,
    state.hierarchy
  ])

  const setIs404 = (type, is404) => {
    dispatchState({
      type: SET_IS_404,
      payload: { type, is404 }
    })
  }

  // Fetches zoneHierachy from url params & sets siteId
  useEffect(() => {
    if (params.zone) {
      const siteId = getSiteIdFromZonePath(params.zone)
      if (!state.siteId || state.siteId !== siteId) {
        dispatchState({
          type: SET_SITE_ID,
          payload: { siteId }
        })

        if (!zoneHierarchy.hasOwnProperty(siteId)) {
          dispatch(
            fetchZoneHierarchy({
              zoneId: siteId
            })
          )
        }
      }
    }
  }, [params.zone, state.siteId, dispatch, zoneHierarchy])

  // Sets Hierarchy to state once zoneHierachy has been fetched
  useEffect(() => {
    if (!zoneHierarchyLoading && state.siteId && !state.hierarchy) {
      if (!isEmpty(zoneHierarchy)) {
        setIs404('site', false)
        dispatchState({
          type: SET_HIERARCHY,
          payload: { hierarchy: zoneHierarchy[state.siteId] }
        })
      } else if (!state.is404.site) {
        setIs404('site', true)
      }
    }
  }, [
    zoneHierarchyLoading,
    state.siteId,
    state.hierarchy,
    zoneHierarchy,
    state.is404.site
  ])

  useEffect(() => {
    if (state?.siteId && !rootZone?.id) {
      dispatch(fetchRootZone({ zoneId: state.siteId }))
    }
  }, [state.siteId, rootZone?.id])

  // Sets current zone to state once hierarchy has been set
  const prevZoneParams = useRef(null)
  const [prevDashboardId, setPrevDashboardId] = useState(null)

  useEffect(() => {
    if (
      params.zone &&
      state.siteId &&
      state.initialized &&
      prevZoneParams.current !== params.zone &&
      state.hierarchy?.id
    ) {
      const { is404, zone, payload } = setCurrentZoneState(
        params,
        dashboards,
        state.hierarchy,
        state.currentBreakpoint,
        device,
        deviceTypes,
        currentUser
      )

      const zoneArr = params.zone.split('/')
      const zoneId =
        zoneArr[zoneArr.length - 1] === '' ? zoneArr.at(-2) : zoneArr.at(-1)
      if (!is404 && zone && zone.id === zoneId) {
        setIs404('zone', false)
        setIs404('device', false)
        dispatchState({ type: SET_CURRENT_ZONE, payload: { zone } })
        dispatchState({ type: SET_CURRENT_DASHBOARD, payload })
        setPrevDashboardId(payload.id)
        prevZoneParams.current = params.zone
      } else {
        setIs404('zone', true)
      }
    }
  }, [
    dashboards,
    params,
    state.siteId,
    state.initialized,
    state.hierarchy,
    device,
    deviceTypes,
    currentUser,
    state.currentBreakpoint,
    prevDashboardId,
    state.currentDashboard
  ])

  useEffect(() => {
    if (
      state.currentDashboard &&
      state.currentDashboard?.context !== 'sensor' &&
      !isSome404(state.is404) &&
      state.hierarchy &&
      state.currentZone &&
      !state.nextActionDeferCommit
    ) {
      const newDashboardConfig = {
        ...state.currentDashboard,
        config: widgetMapToDashboardConfig(state.widgets)
      }
      dispatch(sendUpdateDashboard(newDashboardConfig))
    }
  }, [
    state.widgetsStateId,
    dispatch,
    state.currentDashboard,
    state.currentZone,
    state.hierarchy,
    state.is404,
    state.nextActionDeferCommit,
    state.widgets
  ])

  // Dashboard context
  const dashboardContext = {
    state,
    dispatchState
  }

  const has404 = isSome404(state.is404)
  const isLoading = deviceLoading || zoneHierarchyLoading || dashboardResetting

  return (
    <Box className='DesktopDashboard'>
      <DashboardContext.Provider value={dashboardContext}>
        <FlexV2 direction='column' axisGap={400}>
          <Header />
          <Loader isLoading={isLoading}>
            <FlexV2
              wrap='nowrap'
              direction='column'
              axisGap={200}
              style={{ flexGrow: 1, height: '100%', width: '100%' }}
            >
              {!isLoading && has404 && <Zone404 />}
              {!isLoading &&
                !has404 &&
                state.currentZone &&
                state.currentDashboard && (
                  <Fragment>
                    <Notifications zoneId={state.currentZone.id} />
                    <MosaicLayout />
                  </Fragment>
                )}
            </FlexV2>
          </Loader>

          {state.isToolboxOpen && state.currentDashboard && <Toolbox />}
        </FlexV2>
      </DashboardContext.Provider>
    </Box>
  )
}

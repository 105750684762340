import { I18n } from 'aws-amplify'

const Strings = () => ({
  manageFertilizerInventoryCalibrations: I18n.get('Manage Fertilizer Inventory Calibrations'),
  createFertilizerInventoryCalibration: I18n.get('Calibrate'),
  createFormHeader: I18n.get('Create New Calibration'),
  noFertilizerInventoryCalibrations: I18n.get('No fertilizer inventory calibrations'),
  saveFertilizerInventoryCalibration: I18n.get('Save Calibration'),
  selectDefault: I18n.get('Please Select'),
  fertilizerInventoryCalibration: I18n.get('Inventory Calibration'),
  calibration: I18n.get('Calibration'),
  inventory: I18n.get('Inventory'),
  fertilizer: I18n.get(''),
  newTotalWeight: I18n.get('New Total Weight'),
  oldTotalWeight: I18n.get('Old Total Weight'),
  currentTotalWeight: I18n.get('Current Total Weight'),
  totalWeight: I18n.get('Total Weight'),
  change: I18n.get('Change'),
  reason: I18n.get('Reason'),
  date: I18n.get('Date'),
  kg: I18n.get('kg')
})

export default Strings

import { sortMeasurementsForDashboard } from '@/Util/MeasurementUtils'
import {
  getAllReadMeasurements,
  getAllReadMeasurementsForDevice
} from '@/Util/ZoneUtils'

export const BASE_MEASUREMENTS = [
  'temperature',
  'pressure',
  'humidity',
  'carbon_dioxide',
  'par',
  'uv_index',
  'conductivity',
  'ph',
  'water_temperature',
  'dissolved_oxygen'
]

const ENVIROSENSE_MEASUREMENTS_ORDER = [
  'temperature',
  'humidity',
  'par',
  'pressure',
  'carbon_dioxide'
]

const WATERSENSE_MEASUREMENTS_ORDER = [
  'water_temperature',
  'ph',
  'conductivity',
  'dissolved_oxygen',
  'dissolved_oxygen_percentage'
]

const SOILSENSE_MEASUREMENTS_ORDER = [
  'soil_temperature',
  'soil_ph',
  'soil_moisture',
  'soil_ec',
  'soil_nitrogen',
  'soil_potassium'
]

const WEATHER_STATION_MEASUREMENTS_ORDER = ['wind_speed', 'wind_direction']

const COMBINED_MEASUREMENTS_ORDER = [
  ...ENVIROSENSE_MEASUREMENTS_ORDER,
  ...WATERSENSE_MEASUREMENTS_ORDER,
  ...SOILSENSE_MEASUREMENTS_ORDER,
  ...WEATHER_STATION_MEASUREMENTS_ORDER
]

const ORDER_MAP = new Map(
  COMBINED_MEASUREMENTS_ORDER.map((id, index) => [id, index])
)

const COMPUTED_MEASUREMENTS = [
  {
    id: 'absolute_humidity',
    measurements: ['temperature', 'humidity']
  },
  {
    id: 'daily_energy_consumption',
    measurements: ['energy_kw']
  },
  {
    id: 'daily_light_integral',
    measurements: ['par']
  },
  {
    id: 'growing_degree_hours',
    measurements: ['temperature']
  },
  {
    id: 'radiation_temperature_ratio',
    measurements: ['temperature', 'par']
  },
  {
    id: 'salinity',
    measurements: ['water_temperature', 'conductivity']
  },
  {
    id: 'saturation_vapour_pressure',
    measurements: ['temperature', 'humidity']
  },
  {
    id: 'total_dissolved_solids',
    measurements: ['conductivity']
  },
  {
    id: 'vapour_pressure_deficit',
    measurements: ['temperature', 'humidity']
  },
  {
    id: 'wet_bulb_temperature',
    measurements: ['temperature', 'humidity']
  }
]

export const getAvailableMeasurements = (
  hierarchy,
  currentZone,
  siteId,
  allDeviceTypes,
  allMeasurements,
  params,
  isLayout = false
) => {
  let allReadMeasurements = []

  const sensorId = params?.sensorId ?? null
  const zoneHierarchy = { [siteId]: hierarchy }

  if (hierarchy?.id && sensorId) {
    const deviceMeasurements = getAllReadMeasurementsForDevice(
      zoneHierarchy,
      siteId,
      sensorId,
      allDeviceTypes
    )
    allReadMeasurements = [...deviceMeasurements]
  } else if (hierarchy?.id && currentZone?.id) {
    const zoneMeasurements = getAllReadMeasurements(
      zoneHierarchy,
      siteId,
      currentZone?.id,
      allDeviceTypes
    )
    allReadMeasurements = [...zoneMeasurements]
  }

  if (allReadMeasurements?.length > 0) {
    COMPUTED_MEASUREMENTS.forEach(({ id, measurements }) => {
      if (
        measurements.every(measurement =>
          allReadMeasurements.includes(measurement)
        )
      ) {
        allReadMeasurements.push(id)
      }
    })

    if (isLayout) {
      allReadMeasurements.sort((a, b) => ORDER_MAP.get(a) - ORDER_MAP.get(b))
      return allReadMeasurements
    }

    return sortMeasurementsForDashboard(
      allMeasurements,
      allReadMeasurements
    ).map(measurement => measurement.id)
  }

  return isLayout ? COMBINED_MEASUREMENTS_ORDER : BASE_MEASUREMENTS
}

import { makeActionCreator } from '@/Util/ReduxUtils'

export const REQUEST_ALL_STORAGE_FACILITIES = 'REQUEST_ALL_STORAGE_FACILITIES'
export const requestAllStorageFacilities = makeActionCreator(
  REQUEST_ALL_STORAGE_FACILITIES
)

export const RECEIVE_ALL_STORAGE_FACILITIES = 'RECEIVE_ALL_STORAGE_FACILITIES'
export const receiveAllStorageFacilities = makeActionCreator(
  RECEIVE_ALL_STORAGE_FACILITIES,
  'storageFacilities',
  'count',
  'error'
)

export const REQUEST_STORAGE_FACILITIES = 'REQUEST_STORAGE_FACILITIES'
export const requestStorageFacilities = makeActionCreator(
  REQUEST_STORAGE_FACILITIES,
  'params'
)

export const RECEIVE_STORAGE_FACILITIES = 'RECEIVE_STORAGE_FACILITIES'
export const receiveStorageFacilities = makeActionCreator(
  RECEIVE_STORAGE_FACILITIES,
  'storageFacilities',
  'count',
  'error'
)

export const CREATE_STORAGE_FACILITY = 'CREATE_STORAGE_FACILITY'
export const createStorageFacility = makeActionCreator(
  CREATE_STORAGE_FACILITY,
  'params'
)

export const STORAGE_FACILITY_CREATED = 'STORAGE_FACILITY_CREATED'
export const storageFacilityCreated = makeActionCreator(
  STORAGE_FACILITY_CREATED,
  'storageFacility',
  'error'
)

export const UPDATE_STORAGE_FACILITY = 'UPDATE_STORAGE_FACILITY'
export const updateStorageFacility = makeActionCreator(
  UPDATE_STORAGE_FACILITY,
  'params'
)

export const STORAGE_FACILITY_UPDATED = 'STORAGE_FACILITY_UPDATED'
export const storageFacilityUpdated = makeActionCreator(
  STORAGE_FACILITY_UPDATED,
  'storageFacility',
  'error'
)

export const REQUEST_STORAGE_FACILITY = 'REQUEST_STORAGE_FACILITY'
export const requestStorageFacility = makeActionCreator(
  REQUEST_STORAGE_FACILITY,
  'params'
)

export const RECEIVE_STORAGE_FACILITY = 'RECEIVE_STORAGE_FACILITY'
export const receiveStorageFacility = makeActionCreator(
  RECEIVE_STORAGE_FACILITY,
  'storageFacility',
  'error'
)

export const CLEAR_STORAGE_FACILITY = 'CLEAR_STORAGE_FACILITY'
export const clearStorageFacility = makeActionCreator(CLEAR_STORAGE_FACILITY)

export const CLEAR_STORAGE_FACILITIES = 'CLEAR_STORAGE_FACILITIES'
export const clearStorageFacilities = makeActionCreator(
  CLEAR_STORAGE_FACILITIES
)

export const SET_STORAGE_FACILITY = 'SET_STORAGE_FACILITY'
export const setStorageFacility = makeActionCreator(
  SET_STORAGE_FACILITY,
  'storageFacility'
)

import { I18n } from 'aws-amplify'

const Strings = () => ({
  manageInventory: I18n.get('Manage Inventory'),
  noInventory: I18n.get('No inventory'),
  transfers: I18n.get('Transfers'),
  storageFacility: I18n.get('Storage Facility'),
  produce: I18n.get('Produce'),
  weight: I18n.get('Weight'),
  kg: I18n.get('kg')
})

export default Strings

import { useState } from 'react'

import DevicesListItem from './DevicesListItem'
import SensorRow from './SensorRow'
import {
  Button,
  Column,
  Cell,
  FlexV2,
  Input,
  Row,
  Separator,
  Slot,
  Table,
  Text
} from '@/primitives'
import { Select } from '@/elements'

import { getZonesHierarchy } from '@/reducers/selectors'

import { useFleetManagement } from '../context'

import { getActions } from '../config'
import {
  TOGGLE_CORE_DEVICE,
  SELECT_ZONE_SENSORS,
  SELECT_ALL_IN_ZONE
} from '../state'
import { getStatusIcon, getReadableDateTime } from '../utils'
import { getZoneName } from '@/Util/ZoneUtils'

import Strings from '../../../Strings'

const DeviceSensorsTable = ({ zoneIndex }) => {
  const {
    state: { sensorsByZone, tabId, siteId, bulkAction },
    dispatchState
  } = useFleetManagement()

  const device = sensorsByZone[zoneIndex]

  const { id, sensors, type } = device

  const {
    tableHeaderDeviceName,
    tableHeaderDeviceType,
    tableHeaderDeviceHardwareVersion,
    tableHeaderDeviceStatus,
    tableHeaderDeviceActions,
    tableHeaderDeviceLastUpdated,
    placeholderDeviceActionsSelect
  } = Strings()

  const zonesHierarchy = getZonesHierarchy()
  const [groupCollapsed, setGroupCollapsed] = useState(true)
  const [subgroupCollapsed, setSubgroupCollapsed] = useState(false)

  const coreDeviceSelected = sensorsByZone[zoneIndex].selected

  const toggleGroup = () => {
    setGroupCollapsed(!groupCollapsed)
  }

  const toggleSubGroup = () => {
    setSubgroupCollapsed(!subgroupCollapsed)
  }

  function onChangeGroupOption(option) {
    if (option.value === 'sensors') {
      dispatchState({
        type: SELECT_ZONE_SENSORS,
        payload: {
          zoneIndex
        }
      })
    }

    if (option.value === 'device') {
      dispatchState({
        type: TOGGLE_CORE_DEVICE,
        payload: {
          zoneIndex,
          setSelected: true,
          clearSensors: true
        }
      })
    }

    if (option.value === 'all') {
      dispatchState({
        type: SELECT_ALL_IN_ZONE,
        payload: {
          zoneIndex
        }
      })
    }
  }

  const onSelectCoreDevice = () => {
    dispatchState({
      type: TOGGLE_CORE_DEVICE,
      payload: {
        zoneIndex
      }
    })
  }

  const renderZoneSensors = () => {
    if (groupCollapsed) {
      return null
    }

    return sensors.map(({ id }, index) => (
      <SensorRow key={id} zoneIndex={zoneIndex} sensorIndex={index} />
    ))
  }

  const zoneName = getZoneName(zonesHierarchy, siteId, id)

  if (tabId !== type) return null
  const isGreengrass = tabId === 'greengrass'
  const cellClassname = isGreengrass ? '' : 'Empty'

  return (
    <FlexV2 direction='column'>
      <DevicesListItem
        zoneIndex={zoneIndex}
        groupCollapsed={groupCollapsed}
        toggleGroup={toggleGroup}
      />
      <Separator />
      {!groupCollapsed && (
        <Table className='FleetManagement__Table'>
          <Slot name='head'>
            <Row>
              <Column width='20%'>{tableHeaderDeviceName}</Column>
              <Column width='15%'>{tableHeaderDeviceType}</Column>
              <Column width='15%'>{tableHeaderDeviceHardwareVersion}</Column>
              <Column width='15%'>{tableHeaderDeviceStatus}</Column>
              <Column width='15%'>{tableHeaderDeviceLastUpdated}</Column>
              <Column width='20%'>{tableHeaderDeviceActions}</Column>
            </Row>
          </Slot>
          <Slot name='body'>
            <Row>
              <Cell>
                <FlexV2 alignCrossAxis='center' axisGap={300}>
                  {isGreengrass && (
                    <Input
                      type='checkbox'
                      style={{ marginLeft: '0' }}
                      checked={coreDeviceSelected}
                      onChange={onSelectCoreDevice}
                      disabled={
                        bulkAction.value === 'ota_sensor' ||
                        bulkAction.value === 'nvsdump'
                      }
                    />
                  )}
                  <Text fontWeight={500}>
                    {`${zoneName} (${id})`}
                  </Text>
                </FlexV2>
              </Cell>
              <Cell className='Empty'></Cell>
              <Cell className='Empty'></Cell>
              <Cell
                className={cellClassname}
                columnName={tableHeaderDeviceStatus}
              >
                {isGreengrass && getStatusIcon(device.status)}
              </Cell>
              <Cell
                className={cellClassname}
                columnName={tableHeaderDeviceLastUpdated}
              >
                {isGreengrass && getReadableDateTime(device.updatedAt)}
              </Cell>
              <Cell columnName={tableHeaderDeviceActions}>
                <FlexV2 axisGap={400} alignMainAxis='space-between'>
                  <Select
                    className='Device__Actions__Select'
                    classNamePrefix='Device__Actions__Select'
                    placeholder={placeholderDeviceActionsSelect}
                    options={getActions(isGreengrass, bulkAction.value)}
                    onChange={onChangeGroupOption}
                  />
                  <Button
                    className='CoreDevice__Expand__Button'
                    iconBefore={
                      subgroupCollapsed ? 'expand more' : 'expand less'
                    }
                    onClick={toggleSubGroup}
                  />
                </FlexV2>
              </Cell>
            </Row>
            {!subgroupCollapsed && renderZoneSensors()}
          </Slot>
        </Table>
      )}
    </FlexV2>
  )
}

export default DeviceSensorsTable

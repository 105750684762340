import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Switch, Route } from 'react-router-dom'

import RolesList from './RolesList'
import EditRole from './EditRole'

import { fetchRoles } from '@/slices/management/role'
import { fetchPermissions } from '@/slices/management/permission'
import { showBanner } from '@/slices/util'
import { fetchZonesList } from '@/slices/management/zone'

import { getPermissionsError } from '@/reducers/selectors'

export default function UserRolesPage() {
  const dispatch = useDispatch()
  const permissionsError = getPermissionsError()

  useEffect(() => {
    dispatch(fetchRoles())
    dispatch(fetchPermissions())
    dispatch(fetchZonesList())
  }, [dispatch])

  useEffect(() => {
    if (permissionsError) {
      const errorDetails = {
        show: true,
        message: permissionsError,
        type: 'error'
      }
      dispatch(showBanner(errorDetails))
    }
  }, [permissionsError, dispatch])

  return (
    <Switch>
      <Route
        exact
        path='/admin/user-roles'
        render={routeProps => <RolesList {...routeProps} />}
      />
      <Route
        exact
        path='/admin/user-roles/:id'
        render={routeProps => <EditRole {...routeProps} />}
      />
    </Switch>
  )
}

export const CUSTOMER_STATUSES = strings => {
  return {
    active: strings.active,
    inactive: strings.inactive,
    closed: strings.closed,
    banned: strings.banned
  }
}

export const CUSTOMER_CATEGORIES = strings => {
  return {
    retail: strings.retail,
    wholesale: strings.wholesale,
    charity: strings.charity,
    hospitality: strings.hospitality,
    open: strings.openMarket
  }
}

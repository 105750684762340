import { useReducer, useState, useEffect } from 'react'

import {
  getZonesHierarchy,
  getSensorThresholdStatuses,
  getOfflineDevices,
  getNavigationLocationSelector
} from '@/reducers/selectors'

import {
  getZoneFromHierarchy,
  filterHierarchyByOrganization
} from '@/Util/ZoneUtils'

import useDeviceSize from '@/hooks/useDeviceSize'

import reducer, {
  SET_DEVICES,
  SET_ALERTS,
  SET_OFFLINE_DEVICES,
  SET_CURRENT_CLUSTER,
  SET_CURRENT_DEVICE,
  RESET_ALL,
  initialState
} from './reducer'

import { maxDeviceStatus } from './utils'

import GoogleMap from './Map'

import './index.scss'

const Map = () => {
  const isMobile = useDeviceSize()
  
  const locationSelector = getNavigationLocationSelector()

  const zonesHierarchy = getZonesHierarchy()
  const rootStatuses = getSensorThresholdStatuses()
  const offlineDevices = getOfflineDevices()

  const [state, dispatch] = useReducer(reducer, initialState)

  const [zoneHierarchy, setZoneHierarchy] = useState(null)

  const getDevices = zone => {
    let newDevices = []
    if (zone.devices.length > 0) {
      zone.devices.forEach(device => {
        if (device.latitude && device.longitude) {
          newDevices.push({
            id: device.id,
            status: 'normal',
            name: device.name,
            tag: device.tag,
            type: device.sensorType,
            lat: device.latitude,
            lng: device.longitude
          })
        }
      })
    }
    if (zone.children) {
      Object.values(zone.children).forEach(childZone => {
        newDevices = newDevices.concat(getDevices(childZone))
      })
    }
    return newDevices
  }

  const resetAll = () => {
    dispatch({
      type: RESET_ALL
    })
  }

  useEffect(() => {
    return () => {
      resetAll()
    }
  }, [])

  useEffect(() => {
    if (locationSelector.size === 1) {
      const organization = locationSelector.get('organization')
      const hierarchy = filterHierarchyByOrganization(
        zonesHierarchy,
        organization.id
      )
      setZoneHierarchy(hierarchy)
    } else if (locationSelector.size === 2) {
      const site = locationSelector.get('site')
      if (site) {
        const hierarchy = {
          [site.id]: getZoneFromHierarchy(zonesHierarchy, site.id)
        }
        setZoneHierarchy(hierarchy)
      }
    }
  }, [zonesHierarchy, locationSelector])

  useEffect(() => {
    resetAll()
    if (zoneHierarchy) {
      let newDevices = []
      Object.values(zoneHierarchy).forEach(site => {
        newDevices = newDevices.concat(getDevices(site))
      })
      dispatch({
        type: SET_DEVICES,
        devices: newDevices
      })
    }
  }, [zoneHierarchy])

  // @todo handle level priority properly
  useEffect(() => {
    if (state.devices.length > 0 && Object.keys(state.alerts).length === 0) {
      if (rootStatuses?.length > 0) {
        const newAlerts = {}
        for (let status of rootStatuses) {
          newAlerts[status.sensorId] = maxDeviceStatus(
            newAlerts[status.sensorId],
            status.status.level
          )
        }
        dispatch({
          type: SET_ALERTS,
          alerts: newAlerts
        })
      }
    }
  }, [rootStatuses, state.devices])

  // missingData/notInUse
  useEffect(() => {
    if (
      state.devices.length > 0 &&
      Object.keys(state.offlineDevices).length === 0
    ) {
      if (Object.keys(offlineDevices).length > 0) {
        const newOfflineDevices = {}
        for (let siteId in offlineDevices) {
          for (let device of offlineDevices[siteId]) {
            newOfflineDevices[device.deviceId] = {
              status: device.status,
              reasons: device.reasons
            }
          }
        }
        dispatch({
          type: SET_OFFLINE_DEVICES,
          offlineDevices: newOfflineDevices
        })
      }
    }
  }, [offlineDevices, state.devices])

  const setCurrentCluster = cluster => {
    if (cluster?.markers) {
      dispatch({
        type: SET_CURRENT_CLUSTER,
        cluster: cluster.markers.map(cluster => cluster.id)
      })
    } else {
      dispatch({
        type: SET_CURRENT_CLUSTER,
        cluster: null
      })
    }
  }

  const setCurrentDevice = device => {
    dispatch({
      type: SET_CURRENT_DEVICE,
      device: device
    })
  }

  return (
    <GoogleMap
      devices={state.devices}
      filteredDevices={state.filteredDevices}
      currentCluster={state.currentCluster}
      setCurrentCluster={setCurrentCluster}
      currentDevice={state.currentDevice}
      setCurrentDevice={setCurrentDevice}
      isMobile={isMobile}
    />
  )
}

export default Map

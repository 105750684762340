import { I18n } from 'aws-amplify'
import { number, object, string, array } from 'yup'

export const relayConfigSchema = () => {
  return object().shape({
    registerNumber: number()
      .typeError('Only numbers are allowed.')
      .required(I18n.get('Register number is required.'))
  })
}

export const controlDeviceConfigSchema = object().shape({
  address: string().required(I18n.get('Address is required')),
  slaveAddress: number()
    .typeError('Slave address must be a number.')
    .required(I18n.get('Slave address is required')),
  baudrate: number()
    .typeError('Baudrate must be a number.')
    .required(I18n.get('Baudrate is required')),
  relayConfiguration: array()
    .of(relayConfigSchema())
    .min(1, I18n.get('You must include at least one relay configuration.'))
})

import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { I18n } from 'aws-amplify'

import { Box, Button, FlexV2, Loader, Text } from '@/primitives'

import { schemeDark2 } from '@/Util/SchemeUtils'

import { useDataboard } from '../../context'
import { DATABOARD_ACTIONS } from '../../state'

import {
  getSites,
  getFacilities,
  getRooms,
  getZones,
  getSubzones,
  getSensors,
  getCombinedActiveColumns,
  createEmptySpaces,
  getActiveColumns
} from '../Utils/ZoneFilterUtils'
import FilterSelect from '../Shared/FilterSelect'
import RemoveButton from '../Shared/RemoveButton'
import SensorFilterOption from './SensorFilterOption'

import { getSidebarZoneFilterFromLocationSelector } from '../../Utils/FiltersUtils'
import {
  getNavigationLocationSelector,
  getZonesHierarchy,
  getZonesHierarchyLoading
} from '@/reducers/selectors'
import useOrgLabel from '@/hooks/useOrgLabel'
import { COMPARISON_TYPES } from '../../Utils/config'

const SelectString = label => `${I18n.get('Select')} ${label}`

export default function ZoneFilters({
  isBelowBreakpoint,
  isMultiRow,
  index,
  organizations,
  organizationZonesHierarchy,
  isDuplicateFilter,
  isSidebar,
  activeColumns = {},
  hasNoCommonMeasurements = false
}) {
  const zonesHierarchy = getZonesHierarchy()
  const zonesHierarchyLoading = getZonesHierarchyLoading()

  const locationSelector = getNavigationLocationSelector()
  
  const { state, dispatchState } = useDataboard()

  const zoneFilter = isSidebar
    ? state.sidebarFilter[COMPARISON_TYPES.LOCATION_RANGES]
    : state.zoneFilters[index]

  const [isExpanded, setIsExpanded] = useState(true)
  const [isOptionsLoading, setIsOptionsLoading] = useState(true)

  const [siteOptions, setSiteOptions] = useState([])
  const [facilityOptions, setFacilityOptions] = useState([])
  const [roomOptions, setRoomOptions] = useState([])
  const [zoneOptions, setZoneOptions] = useState([])
  const [subzoneOptions, setSubzoneOptions] = useState([])
  const [sensorOptions, setSensorOptions] = useState([])

  const { site, facility, room, zone, subzone } = useOrgLabel([
    'site',
    'facility',
    'room',
    'zone',
    'subzone'
  ])

  useEffect(() => {
    const isZoneFilter = !isSidebar && index === 0
    const isUpdateTimeZone = isSidebar || isZoneFilter

    if (isUpdateTimeZone && zoneFilter.siteId && !zonesHierarchyLoading) {
      const site = zonesHierarchy[zoneFilter.siteId]
      const timeZone = site?.timeZone ?? 'UTC'

      if (isSidebar) {
        dispatchState({
          type: DATABOARD_ACTIONS.UPDATE_DATE_FILTERS_TIMEZONE,
          timeZone
        })
      } else {
        dispatchState({
          type: DATABOARD_ACTIONS.UPDATE_SIDEBAR_FILTER_VALUES,
          params: { timeZone }
        })
      }
    }
  }, [
    index,
    zonesHierarchy,
    isSidebar,
    zonesHierarchyLoading,
    zoneFilter.siteId,
    dispatchState,
    zoneFilter
  ])

  useEffect(() => {
    setIsOptionsLoading(true)
    const siteOptions = getSites(zoneFilter, organizationZonesHierarchy)
    const facilityOptions = getFacilities(
      zoneFilter,
      organizationZonesHierarchy
    )
    const roomOptions = getRooms(zoneFilter, organizationZonesHierarchy)
    const zoneOptions = getZones(zoneFilter, organizationZonesHierarchy)
    const subzoneOptions = getSubzones(zoneFilter, organizationZonesHierarchy)
    const sensorOptions = getSensors(zoneFilter, organizationZonesHierarchy)

    setSiteOptions(siteOptions)
    setFacilityOptions(facilityOptions)
    setRoomOptions(roomOptions)
    setZoneOptions(zoneOptions)
    setSubzoneOptions(subzoneOptions)
    setSensorOptions(sensorOptions)
    if (!isSidebar) {
      dispatchState({
        type: DATABOARD_ACTIONS.UPDATE_ACTIVE_COLUMNS,
        index,
        activeColumns: getActiveColumns({
          siteOptions,
          facilityOptions,
          roomOptions,
          zoneOptions,
          subzoneOptions,
          sensorOptions
        })
      })
    }
    setIsOptionsLoading(false)
  }, [isSidebar, zoneFilter, index, organizationZonesHierarchy, dispatchState])

  const onExpand = e => {
    e.preventDefault()
    setIsExpanded(s => !s)
  }

  const onRemoveLocation = e => {
    e.preventDefault()
    if (!isSidebar) {
      dispatchState({
        type: DATABOARD_ACTIONS.DELETE_COLUMN,
        index
      })
    }
    if (index === 0 && state.zoneFilters.length === 1) {
      const zoneFilter =
        getSidebarZoneFilterFromLocationSelector(locationSelector)
      const timeZone = zonesHierarchy[zoneFilter.siteId].timeZone
      dispatchState({
        type: DATABOARD_ACTIONS.RESET_FILTERS,
        zoneFilter,
        timeZone
      })
    } else {
      dispatchState({
        type: DATABOARD_ACTIONS.REMOVE_ZONE_FILTER,
        params: { index }
      })
    }
  }

  const onSelectValue = (selectedItem, meta) => {
    if (zoneFilter[meta.name] === selectedItem?.value) {
      return null
    }

    if (isSidebar) {
      dispatchState({
        type: DATABOARD_ACTIONS.UPDATE_SIDEBAR_FILTER_VALUES,
        params: { [meta.name]: selectedItem?.value ?? null }
      })
    } else {
      dispatchState({
        type: DATABOARD_ACTIONS.UPDATE_ZONE_FILTER_VALUE,
        params: { index, value: selectedItem?.value ?? null, key: meta.name }
      })
    }
  }

  const showSelectLabel = isBelowBreakpoint || isSidebar || isMultiRow

  let combinedActiveColumns = null
  if (!isSidebar) {
    combinedActiveColumns = getCombinedActiveColumns(activeColumns, true)
  }

  const isSiteActive = combinedActiveColumns?.has('siteId')
  const isFacilityActive = combinedActiveColumns?.has('facilityId')
  const isRoomActive = combinedActiveColumns?.has('roomId')
  const isZoneActive = combinedActiveColumns?.has('zoneId')
  const isSubzoneActive = combinedActiveColumns?.has('subzoneId')
  const isSensorActive = combinedActiveColumns?.has('sensorId')

  const inlineInactiveColumns = [
    isSiteActive && siteOptions.length === 0,
    isFacilityActive && facilityOptions.length === 0,
    isRoomActive && roomOptions.length === 0,
    isZoneActive && zoneOptions.length === 0,
    isSubzoneActive && subzoneOptions.length === 0,
    isSensorActive && sensorOptions.length === 0
  ].filter(Boolean)

  const activeColumnsCount = activeColumns[index]?.size ?? 0
  const inactiveColumnsCount =
    6 - activeColumnsCount - inlineInactiveColumns.length

  const hasError = isDuplicateFilter || hasNoCommonMeasurements

  return (
    <Loader
      isLoading={
        isOptionsLoading ||
        Object.keys(organizationZonesHierarchy).length === 0 ||
        zonesHierarchyLoading
      }
    >
      <Box
        className={classNames(
          'DataboardFilters__ComparisonRow',
          hasError && 'DataboardFilters__ComparisonRow--Error',
          isSidebar && 'DataboardFilters__ComparisonRow--Sidebar',
          isMultiRow && 'MultiRow'
        )}
      >
        {!isSidebar && (
          <Box className='DataboardFilters__ComparisonRow__LegendBox'>
            {isBelowBreakpoint && state.zoneFilters.length > 1 && (
              <RemoveButton onClick={onRemoveLocation} />
            )}
            <FlexV2 axisGap={400} alignCrossAxis='center'>
              {!isSidebar && (
                <Box
                  className='DataboardFilters__ComparisonRow__LegendBox__Color'
                  style={{ backgroundColor: schemeDark2[index] }}
                />
              )}
              {isBelowBreakpoint && (
                <Text as='p' size={150} variant='page' tone={600}>
                  {I18n.get('Location')} #{index + 1}
                </Text>
              )}
            </FlexV2>

            {isBelowBreakpoint && (
              <Button
                variant='text'
                iconBefore={
                  isBelowBreakpoint && isExpanded
                    ? 'expand_less'
                    : 'expand_more'
                }
                className='DataboardFilters__ComparisonRow__LegendBox__ExpandButton'
                onClick={onExpand}
              />
            )}
          </Box>
        )}

        {zoneFilter && (
          <Box
            className={
              isSidebar
                ? 'DataboardFilters__ComparisonRow__Content--Sidebar'
                : 'DataboardFilters__ComparisonRow__Content--Location'
            }
            style={!isBelowBreakpoint || isExpanded ? {} : { display: 'none' }}
          >
            <FilterSelect
              options={organizations}
              onChange={onSelectValue}
              name='organizationId'
              value={zoneFilter.organizationId}
              placeholder={I18n.get('Select Organization')}
              label={showSelectLabel && I18n.get('Organization')}
              isSidebar={isSidebar}
              isZoneFilter={true}
              isActive={true}
            />
            <FilterSelect
              options={siteOptions}
              onChange={onSelectValue}
              name={'siteId'}
              value={zoneFilter.siteId}
              placeholder={`${SelectString(site.text)}`}
              label={showSelectLabel && site.text}
              isSidebar={isSidebar}
              isZoneFilter={true}
              isActive={isSiteActive}
              isBelowBreakpoint={isBelowBreakpoint}
            />
            <FilterSelect
              options={facilityOptions}
              onChange={onSelectValue}
              name={'facilityId'}
              value={zoneFilter.facilityId}
              placeholder={`${SelectString(facility.text)}`}
              label={showSelectLabel && facility.text}
              isSidebar={isSidebar}
              isZoneFilter={true}
            />
            <FilterSelect
              options={roomOptions}
              onChange={onSelectValue}
              name={'roomId'}
              value={zoneFilter.roomId}
              placeholder={`${SelectString(room.text)}`}
              label={showSelectLabel && room.text}
              isSidebar={isSidebar}
              isZoneFilter={true}
              isActive={isRoomActive}
              isBelowBreakpoint={isBelowBreakpoint}
            />
            <FilterSelect
              options={zoneOptions}
              onChange={onSelectValue}
              name={'zoneId'}
              value={zoneFilter.zoneId}
              placeholder={`${SelectString(zone.text)}`}
              label={showSelectLabel && zone.text}
              isSidebar={isSidebar}
              isZoneFilter={true}
              isActive={isZoneActive}
              isBelowBreakpoint={isBelowBreakpoint}
            />
            <FilterSelect
              options={subzoneOptions}
              onChange={onSelectValue}
              name={'subzoneId'}
              value={zoneFilter.subzoneId}
              placeholder={`${SelectString(subzone.text)}`}
              label={showSelectLabel && subzone.text}
              isSidebar={isSidebar}
              isZoneFilter={true}
              isActive={isSubzoneActive}
              isBelowBreakpoint={isBelowBreakpoint}
            />

            <FilterSelect
              options={sensorOptions}
              onChange={onSelectValue}
              name='sensorId'
              value={zoneFilter.sensorId}
              placeholder={I18n.get('Select Sensor')}
              label={showSelectLabel && I18n.get('Sensor')}
              components={{ Option: SensorFilterOption }}
              isSidebar={isSidebar}
              isZoneFilter={true}
              isActive={isSensorActive}
              isBelowBreakpoint={isBelowBreakpoint}
            />
            {!isBelowBreakpoint &&
              !isSidebar &&
              createEmptySpaces(inactiveColumnsCount)}
          </Box>
        )}
        {!isBelowBreakpoint && !isSidebar && state.zoneFilters.length > 1 && (
          <RemoveButton onClick={onRemoveLocation} />
        )}
      </Box>
    </Loader>
  )
}

import { Button, Flex, Input, Text } from '@/primitives'
import Strings from '../../../Strings'
import { createRandomDeviceName } from './utils'

export default function NameInput({
  value,
  onChange,
  canRefreshName,
  error,
  disabled
}) {
  const strings = Strings()

  function handleRefreshName() {
    onChange({
      target: {
        name: 'name',
        value: createRandomDeviceName()
      }
    })
  }

  return (
    <Flex direction='column' axisGap={300}>
      <Text variant='page' tone={900} size={100}>
        {strings.deviceFormLabelName}
      </Text>
      <Flex
        direction='row'
        alignMainAxis='flex-start'
        axisGap={300}
        alignCrossAxis='center'
      >
        <Input
          name='name'
          value={value}
          style={{ width: 'auto', flexGrow: 1 }}
          onChange={onChange}
          disabled={disabled}
        />

        {!canRefreshName && (
          <Button
            variant='primary'
            iconBefore='refresh'
            size='small'
            onClick={handleRefreshName}
          >
            {strings.deviceRefreshNameBtn}
          </Button>
        )}
      </Flex>
      <Text
        className='FormInput__Error'
        variant='error'
        size={200}
        tone={500}
        style={{ height: '1.5rem' }}
        as='p'
      >
        {error || ''}
      </Text>
    </Flex>
  )
}

import { I18n } from 'aws-amplify'

const Strings = () => ({
  managePackageUnitInventory: I18n.get('Manage Packaging Inventory'),
  noPackageUnitInventory: I18n.get('No packaging inventory'),
  transfers: I18n.get('Transfers'),
  purchases: I18n.get('Purchases'),
  storageFacility: I18n.get('Storage Facility'),
  packageUnit: I18n.get('Package Unit'),
  quantity: I18n.get('Quantity'),
  grams: I18n.get('g')
})

export default Strings

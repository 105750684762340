import { Box } from '../..'

/* -----------------------------------------------------------------------------
 * Row
 * ---------------------------------------------------------------------------*/
/**
 * Row Primitive
 * @param {Object} props
 * @param {import('react').Children} [props.children=null] Cell or Column Children
 * @param {import('react').CSSProperties} [props.css] IonCSS Styles
 *
 * @example
 * ```jsx
 * <Table>
 *   <Slot name='body'>
 *    <Row>
 *      <Cell>Cell 1</Cell>
 *      <Cell>Cell 2</Cell>
 *    </Row>
 *  </Slot>
 * </Table>
 * ```
 */

const Row = ({ children, ...rest }) => (
  <Box as='tr' {...rest}>
    {children}
  </Box>
)

export default Row

import {
  FETCH_TIMEFRAME_GRAPH_AVERAGES,
  TIMEFRAME_GRAPH_AVERAGES_FETCHED,
  CLEAR_TIMEFRAME_GRAPH_AVERAGES
} from '@/actions/ts/timeframe'

import { createReducer } from '@/Util/ReduxUtils'

import { PARAMS_TYPE, PARSE_DATA_BY_TYPE, getErrorId } from './utils'

export const initialState = {
  timeframeGraphAveragesIsLoading: false,
  timeframeGraphAverages: [],
  timeframeGraphAveragesError: null
}

const handlers = {
  [CLEAR_TIMEFRAME_GRAPH_AVERAGES]: state => ({
    ...state,
    timeframeGraphAveragesIsLoading: false,
    timeframeGraphAverages: [],
    timeframeGraphAveragesError: null
  }),
  [FETCH_TIMEFRAME_GRAPH_AVERAGES]: (state, action) => ({
    ...state,
    timeframeGraphAveragesIsLoading: true,
    timeframeGraphAverages: [],
    timeframeGraphAveragesError: null
  }),
  [TIMEFRAME_GRAPH_AVERAGES_FETCHED]: (
    state,
    { timeframeGraphAverages, params }
  ) => {
    const { type } = params

    const errors = params?.[PARAMS_TYPE[type]]?.reduce((errorsArr, param) => {
      const id = getErrorId(param, type)
      const error = timeframeGraphAverages[id].error
      if (error) return [...errorsArr, error]
      return errorsArr
    }, [])

    const parsedAverages = PARSE_DATA_BY_TYPE[type](
      params,
      timeframeGraphAverages
    )
    return {
      ...state,
      timeframeGraphAveragesIsLoading: false,
      timeframeGraphAverages: errors.length > 0 ? [] : parsedAverages,
      timeframeGraphAveragesError: errors.length > 0 ? errors[0] : null
    }
  }
}

export default createReducer(handlers, initialState)

import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { makeGraphqlRequestV2 } from '../utils'

/*
 * Queries
 */

export const getSensorThresholdStatusesByRootIds = async params => {
  try {
    const query = /* GraphQL */ `
      query getSensorThresholdStatusesByRootIds($rootIds: [String!]) {
        getSensorThresholdStatusesByRootIds(rootIds: $rootIds) {
          sensorId
          rootId
          measurementIdAndZoneId
          value
          lastChecked
          sendingData
          sinceWhen
          lowerMessage {
            caution
            notification
            warning
          }
          upperMessage {
            caution
            notification
            warning
          }
          status {
            level
            boundType
            thresholdValue
          }
        }
      }
    `

    const operation = graphqlOperation(query, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)

    return data?.getSensorThresholdStatusesByRootIds
  } catch (err) {
    throw err
  }
}

/*
 * Mutations
 */

export const deleteSensorThresholdStatus = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation deleteSensorThresholdStatus(
        $sensorId: String!
        $zoneId: String!
        $measurementId: String!
      ) {
        deleteSensorThresholdStatus(
          sensorId: $sensorId
          zoneId: $zoneId
          measurementId: $measurementId
        ) {
          sensorId
          measurementIdAndZoneId
        }
      }
    `

    const operation = graphqlOperation(mutation, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)
    return data?.deleteSensorThresholdStatus
  } catch (err) {
    return err.message
  }
}

import { makeActionCreator } from '@/Util/ReduxUtils'

export const REQUEST_ALL_PAYMENT_METHODS = 'REQUEST_ALL_PAYMENT_METHODS'
export const requestAllPaymentMethods = makeActionCreator(
  REQUEST_ALL_PAYMENT_METHODS
)

export const RECEIVE_ALL_PAYMENT_METHODS = 'RECEIVE_ALL_PAYMENT_METHODS'
export const receiveAllPaymentMethods = makeActionCreator(
  RECEIVE_ALL_PAYMENT_METHODS,
  'paymentMethods',
  'count',
  'error'
)

export const REQUEST_PAYMENT_METHODS = 'REQUEST_PAYMENT_METHODS'
export const requestPaymentMethods = makeActionCreator(
  REQUEST_PAYMENT_METHODS,
  'params'
)

export const RECEIVE_PAYMENT_METHODS = 'RECEIVE_PAYMENT_METHODS'
export const receivePaymentMethods = makeActionCreator(
  RECEIVE_PAYMENT_METHODS,
  'paymentMethods',
  'count',
  'error'
)

export const CREATE_PAYMENT_METHOD = 'CREATE_PAYMENT_METHOD'
export const createPaymentMethod = makeActionCreator(
  CREATE_PAYMENT_METHOD,
  'params'
)

export const PAYMENT_METHOD_CREATED = 'PAYMENT_METHOD_CREATED'
export const paymentMethodCreated = makeActionCreator(
  PAYMENT_METHOD_CREATED,
  'paymentMethod',
  'error'
)

export const UPDATE_PAYMENT_METHOD = 'UPDATE_PAYMENT_METHOD'
export const updatePaymentMethod = makeActionCreator(
  UPDATE_PAYMENT_METHOD,
  'params'
)

export const PAYMENT_METHOD_UPDATED = 'PAYMENT_METHOD_UPDATED'
export const paymentMethodUpdated = makeActionCreator(
  PAYMENT_METHOD_UPDATED,
  'paymentMethod',
  'error'
)

export const REQUEST_PAYMENT_METHOD = 'REQUEST_PAYMENT_METHOD'
export const requestPaymentMethod = makeActionCreator(
  REQUEST_PAYMENT_METHOD,
  'params'
)

export const RECEIVE_PAYMENT_METHOD = 'RECEIVE_PAYMENT_METHOD'
export const receivePaymentMethod = makeActionCreator(
  RECEIVE_PAYMENT_METHOD,
  'paymentMethod',
  'error'
)

export const CLEAR_PAYMENT_METHOD = 'CLEAR_PAYMENT_METHOD'
export const clearPaymentMethod = makeActionCreator(CLEAR_PAYMENT_METHOD)

export const CLEAR_PAYMENT_METHODS = 'CLEAR_PAYMENT_METHODS'
export const clearPaymentMethods = makeActionCreator(CLEAR_PAYMENT_METHODS)

export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD'
export const setPaymentMethod = makeActionCreator(
  SET_PAYMENT_METHOD,
  'paymentMethod'
)

import { nanoid } from 'nanoid'
import { useEffect, useState, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import DatePicker from 'react-datepicker'

import {
  createSaleShipment,
  updateSaleShipment
} from '../../../../../../actions/operations/sale'

import {
  getOperationsAllShippingMethods,
  getOperationsAllStorageFacilities
} from '../../../../../../reducers/selectors'

import {
  Box,
  Button,
  Flex,
  Label,
  Select,
  Text,
  Input,
  Separator
} from '../../../../../../primitives'

import useDeviceSize from '../../../../../../hooks/useDeviceSize'
import { getValidationErrorMap } from '../../../../../../Util/GeneralUtils'
import InputError from '../../../../../../elements/InputError'
import Items from './Items'

import { UPDATE_SALE_SHIPMENT } from '../state'
import {
  initialState,
  reducer,
  SET_STATE,
  UPDATE_INPUT,
  UPDATE_ITEM
} from './state'

import { SHIPMENT_STATUSES } from './utils'
import { FIELDS, SCHEMA } from './config'

import Strings from './Strings'

function SaleShipmentForm({
  sale,
  saleShipment,
  closeForm,
  dispatchFormState
}) {
  const dispatch = useDispatch()
  const isMobile = useDeviceSize('mobile')

  const strings = Strings()

  const shippingMethods = getOperationsAllShippingMethods()
  const storageFacilities = getOperationsAllStorageFacilities()

  const [formState, formStateDispatch] = useReducer(reducer, initialState)

  const [orderDate, setOrderDate] = useState(null)
  const [errors, setErrors] = useState({})

  const shipmentStatuses = SHIPMENT_STATUSES(strings)

  useEffect(() => {
    formStateDispatch({
      type: SET_STATE,
      state: {
        ...saleShipment
      }
    })
  }, [saleShipment])

  useEffect(() => {
    setOrderDate(sale.orderDate ? new Date(sale.orderDate) : new Date())
  }, [sale.orderDate])

  useEffect(() => {
    if (formState.storageFacilityId) {
      formStateDispatch({
        type: SET_STATE,
        state: {
          ...formState,
          storageFacilityId: '',
          shippingMethodId: ''
        }
      })
    }
  }, [sale.organizationId])

  const isNewShipment = () => {
    let currentIndex = sale.saleShipments.findIndex(
      shipment => shipment.id === saleShipment.id
    )
    return currentIndex < 0
  }

  const saveSaleShipment = async () => {
    try {
      const validFormState = await SCHEMA(orderDate).validate(formState, {
        abortEarly: false
      })
      if (sale?.id) {
        if (isNewShipment()) {
          dispatch(
            createSaleShipment({
              ...validFormState,
              saleId: sale.id
            })
          )
        } else {
          dispatch(
            updateSaleShipment({
              ...validFormState,
              saleShipmentId: saleShipment.id,
              saleId: sale.id
            })
          )
        }
      }
      dispatchFormState({
        type: UPDATE_SALE_SHIPMENT,
        saleShipment: validFormState
      })
      closeForm()
    } catch (error) {
      setErrors(getValidationErrorMap(error))
    }
  }

  const handleInput = e => {
    const { name, value } = e.currentTarget
    formStateDispatch({ type: UPDATE_INPUT, name, value })
  }

  const handleNumericInput = e => {
    const { name, value } = e.currentTarget
    const newValue = value.length > 0 ? Number(value) : ''
    formStateDispatch({ type: UPDATE_INPUT, name, value: newValue })
  }

  const handleDateInput = (value, name) => {
    formStateDispatch({ type: UPDATE_INPUT, name, value })
  }

  const handleStorageFacility = e => {
    formStateDispatch({
      type: SET_STATE,
      state: {
        ...formState,
        storageFacilityId: e.currentTarget.value,
        shippingMethodId: ''
      }
    })
  }

  const addItem = () => {
    formStateDispatch({
      type: UPDATE_ITEM,
      item: {
        id: nanoid(),
        saleItemId: '',
        quantity: ''
      }
    })
  }

  const filterStorageFacilities = () => {
    return storageFacilities.filter(
      s => s.organizationId === sale.organizationId
    )
  }

  const filterShippingMethods = () => {
    return shippingMethods.filter(
      s => s.storageFacility.id === formState.storageFacilityId
    )
  }

  const getHeader = () => {
    if (isNewShipment()) {
      return strings.createFormHeader
    }
    return strings.updateFormHeader
  }

  return (
    <Box>
      <Separator className='Separator__Line' />
      <Text size={400} fontWeight={500} tone={600}>
        {getHeader()}
      </Text>
      <Flex axisGap={300} direction='column' style={{ marginTop: '1em' }}>
        <Label>
          <Text variant='page' tone={700}>
            {strings.items}
          </Text>
          <Items
            sale={sale}
            saleShipment={formState}
            errors={errors}
            dispatchFormState={formStateDispatch}
          />
          <InputError error={errors?.items} />
          {sale.saleItems.length > 0 && (
            <Flex alignMainAxis={'flex-end'}>
              <Button
                onClick={addItem}
                size='small'
                name='add'
                variant='primary'
              >
                {strings.addItem}
              </Button>
            </Flex>
          )}
        </Label>
      </Flex>
      <Separator className='Separator__Line' />
      <Flex
        axisGap={300}
        alignMainAxis='flex-start'
        direction={isMobile ? 'column' : 'row'}
        className='Operations__Fields'
      >
        <Label>
          <Flex axisGap={300} direction='column'>
            <Text variant='page' tone={700}>
              {strings.fieldStorageFacility}
            </Text>
            <Select
              className='Operations__Select'
              name={FIELDS.storageFacilityId}
              value={formState.storageFacilityId}
              onChange={handleStorageFacility}
            >
              <option default value=''>
                {strings.selectInputDefault}
              </option>
              {filterStorageFacilities().map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Select>
            <InputError error={errors?.storageFacilityId} />
          </Flex>
        </Label>
        <Label>
          <Flex axisGap={300} direction='column'>
            <Text variant='page' tone={700}>
              {strings.fieldShippingMethod}
            </Text>
            <Select
              className='Operations__Select'
              name={FIELDS.shippingMethodId}
              value={formState.shippingMethodId}
              onChange={handleInput}
            >
              <option default value=''>
                {strings.selectInputDefault}
              </option>
              {filterShippingMethods().map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Select>
            <InputError error={errors?.shippingMethodId} />
          </Flex>
        </Label>
        <Label>
          <Flex axisGap={300} direction='column'>
            <Text variant='page' tone={700}>
              {strings.fieldDueDate}
            </Text>
            <DatePicker
              minDate={orderDate}
              selected={formState.dueDate}
              className='Operations__Select'
              onChange={dueDate => handleDateInput(dueDate, FIELDS.dueDate)}
            />
            <InputError error={errors?.dueDate} />
          </Flex>
        </Label>
      </Flex>
      <Flex
        axisGap={300}
        alignMainAxis='flex-start'
        direction={isMobile ? 'column' : 'row'}
        className='Operations__Fields'
      >
        <Label>
          <Flex axisGap={300} direction='column'>
            <Text variant='page' tone={700}>
              {strings.fieldStatus}
            </Text>
            <Select
              className='Operations__Select'
              name={FIELDS.status}
              value={formState.status}
              onChange={handleInput}
            >
              <option default value=''>
                {strings.selectInputDefault}
              </option>
              {Object.entries(shipmentStatuses).map(([id, name]) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Select>
            <InputError error={errors?.status} />
          </Flex>
        </Label>
        <Label>
          <Flex axisGap={300} direction='column'>
            <Text variant='page' tone={700}>
              {strings.fieldDeliveryDate}
            </Text>
            <DatePicker
              minDate={orderDate}
              selected={formState.deliveryDate}
              className='Operations__Select'
              onChange={deliveryDate =>
                handleDateInput(deliveryDate, FIELDS.deliveryDate)
              }
            />
            <InputError error={errors?.deliveryDate} />
          </Flex>
        </Label>
      </Flex>
      <Flex
        axisGap={300}
        alignMainAxis='flex-start'
        direction={isMobile ? 'column' : 'row'}
        className='Operations__Fields'
      >
        <Label>
          <Flex axisGap={300} direction='column'>
            <Text variant='page' tone={700}>
              {strings.fieldPrice}
            </Text>
            <Input
              className='Operations__Input'
              type='number'
              name={FIELDS.price}
              value={formState.price}
              onChange={handleNumericInput}
            />
            <InputError error={errors?.price} />
          </Flex>
        </Label>
        <Label>
          <Flex axisGap={300} direction='column'>
            <Text variant='page' tone={700}>
              {strings.fieldDiscount}
            </Text>
            <Box className='Operations__Input__Container'>
              <Input
                className='Operations__Input'
                type='number'
                name={FIELDS.discount}
                value={formState.discount}
                onChange={handleNumericInput}
              />
              <span className='Operations__Input__Suffix'>%</span>
            </Box>
            <InputError error={errors?.discount} />
          </Flex>
        </Label>
        <Label>
          <Flex axisGap={300} direction='column'>
            <Text variant='page' tone={700}>
              {strings.fieldTax}
            </Text>
            <Box className='Operations__Input__Container'>
              <Input
                className='Operations__Input'
                type='number'
                name={FIELDS.tax}
                value={formState.tax}
                onChange={handleNumericInput}
              />
              <span className='Operations__Input__Suffix'>%</span>
            </Box>
            <InputError error={errors?.tax} />
          </Flex>
        </Label>
      </Flex>
      <Flex alignMainAxis={'flex-end'} style={{ marginTop: '20px' }}>
        <Button
          variant='warning'
          size='small'
          iconBefore='cancel'
          onClick={closeForm}
          style={{ marginRight: '10px' }}
        >
          <Text fontWeight={700} textTransform='uppercase'>
            {strings.formCancelBtn}
          </Text>
        </Button>
        <Button
          variant='info'
          size='small'
          iconBefore='edit'
          onClick={saveSaleShipment}
        >
          <Text fontWeight={700} textTransform='uppercase'>
            {strings.formSaveBtn}
          </Text>
        </Button>
      </Flex>
    </Box>
  )
}

export default SaleShipmentForm

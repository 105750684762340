import { useLocation, Route, Switch } from 'react-router-dom'

import useDeviceSize from '../../hooks/useDeviceSize'

import { getCurrentUser } from '../../reducers/selectors'

import { Flex } from '../../primitives'

import {
  hasReadPermissions,
  RESOURCE_TYPE_OPERATIONS
} from '../../Util/PermissionUtils'

import routes from './routes'

import './index.scss'

function Operations() {
  const location = useLocation()
  const isMobile = useDeviceSize('mobile')

  const currentUser = getCurrentUser()

  const getClassName = () => {
    if (isMobile) {
      return 'Operations Operations__Mobile'
    }
    return 'Operations'
  }

  const filterRoutes = () => {
    return routes.filter(route => {
      return hasReadPermissions(
        currentUser,
        RESOURCE_TYPE_OPERATIONS,
        route.resource
      )
    })
  }

  const ROUTES = filterRoutes()

  return (
    <Flex className={getClassName()} direction='column'>
      <Switch location={location}>
        {ROUTES.map(({ path, Component }) => (
          <Route key={path} path={path}>
            <Component />
          </Route>
        ))}
      </Switch>
    </Flex>
  )
}

export default Operations

import {
  FETCH_CURRENT_AVE_MIN_MAX,
  CURRENT_AVE_MIN_MAX_FETCHED
} from '@/actions/ts/current'

import { createReducer } from '@/Util/ReduxUtils'

export const initialState = {
  currentAverageMinMax: {},
  currentAverageMinMaxError: null
}

const handlers = {
  [FETCH_CURRENT_AVE_MIN_MAX]: state => ({
    ...state,
    currentAverageMinMax: {},
    currentAverageMinMaxError: null
  }),
  [CURRENT_AVE_MIN_MAX_FETCHED]: (state, { error, currentAverageMinMax }) => ({
    ...state,
    currentAverageMinMax: error ? {} : currentAverageMinMax,
    currentAverageMinMaxError: error ?? null
  })
}

export default createReducer(handlers, initialState)

import { createSlice } from '@reduxjs/toolkit'

const managementDeviceEnrollmentSlice = createSlice({
  name: 'managementDeviceEnrollment',
  initialState: {
    enrolledDevice: {},
    error: null,
    loading: false
  }
})

export default managementDeviceEnrollmentSlice.reducer

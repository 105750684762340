import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {
  getThresholdsById,
  getThreshold,
  createThreshold,
  updateThreshold,
  deleteThreshold
} from '@/api/threshold'

export const cleanThresholds = createAsyncThunk('cleanThresholds', () => {
  return []
})

export const cleanThreshold = createAsyncThunk('cleanThreshold', () => {
  return {}
})

export const cleanThresholdCreated = createAsyncThunk(
  'cleanThresholdCreated',
  () => {
    return {}
  }
)

export const cleanThresholdUpdated = createAsyncThunk(
  'cleanThresholdUpdated',
  () => {
    return {}
  }
)

export const cleanThresholdDeleted = createAsyncThunk(
  'cleanThresholdDeleted',
  () => {
    return {}
  }
)

export const setThreshold = createAsyncThunk('setThreshold', threshold => {
  return { threshold }
})

export const fetchThresholdsByZoneId = createAsyncThunk(
  'fetchThresholdsByZoneId',
  async params => {
    const { thresholds, error } = await getThresholdsById(params)
    return { thresholds, error }
  }
)

export const fetchThreshold = createAsyncThunk(
  'fetchThreshold',
  async params => {
    const { threshold, error } = await getThreshold(params)
    return { threshold, error }
  }
)

export const sendCreateThreshold = createAsyncThunk(
  'sendCreateThreshold',
  async params => {
    const { threshold, error } = await createThreshold(params)
    return { threshold, error }
  }
)

export const sendUpdateThreshold = createAsyncThunk(
  'sendUpdateThreshold',
  async params => {
    const { threshold, error } = await updateThreshold(params)
    return { threshold, error }
  }
)

export const sendDeleteThreshold = createAsyncThunk(
  'sendDeleteThreshold',
  async params => {
    const { threshold, error } = await deleteThreshold(params)
    return { threshold, error }
  }
)

const thresholdSlice = createSlice({
  name: 'thresholdReducer',
  initialState: {
    thresholds: [],
    threshold: {},
    createdThreshold: {},
    updatedThreshold: {},
    deletedThreshold: {},
    error: null,
    loading: false,
    saving: false,
    setting: false
  },
  extraReducers: builder => {
    builder
      .addCase(cleanThresholds.fulfilled, (state, action) => {
        state.error = null
        state.thresholds = []
      })
      .addCase(cleanThreshold.fulfilled, (state, action) => {
        state.error = null
        state.threshold = {}
        state.setting = true
      })
      .addCase(cleanThresholdCreated.fulfilled, (state, action) => {
        state.createdThreshold = {}
      })
      .addCase(cleanThresholdUpdated.fulfilled, (state, action) => {
        state.updatedThreshold = {}
      })
      .addCase(cleanThresholdDeleted.fulfilled, (state, action) => {
        state.deletedThreshold = {}
      })
      .addCase(setThreshold.fulfilled, (state, action) => {
        let threshold = { ...action?.payload?.threshold }
        let { lower, upper } = threshold
        lower = cleanupBounds({ ...lower })
        upper = cleanupBounds({ ...upper })
        threshold.lower = lower
        threshold.upper = upper
        state.error = null
        state.threshold = threshold
        state.setting = false
      })
      .addCase(fetchThresholdsByZoneId.pending, (state, action) => {
        state.error = null
        state.loading = true
        state.thresholds = []
      })
      .addCase(fetchThresholdsByZoneId.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.loading = false
        state.thresholds = action?.payload?.thresholds
      })
      .addCase(fetchThreshold.pending, (state, action) => {
        state.error = null
        state.loading = true
        state.threshold = {}
      })
      .addCase(fetchThreshold.fulfilled, (state, action) => {
        if (!action?.payload?.error) {
          let threshold = { ...action?.payload?.threshold }
          let { lower, upper } = threshold
          lower = cleanupBounds({ ...lower })
          upper = cleanupBounds({ ...upper })
          threshold.lower = lower
          threshold.upper = upper
          state.threshold = threshold
        }

        state.error = action?.payload?.error ?? null
        state.loading = false
      })
      .addCase(sendCreateThreshold.pending, (state, action) => {
        state.error = null
        state.saving = true
      })
      .addCase(sendCreateThreshold.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.createdThreshold = action?.payload?.threshold ?? {}
        state.thresholds = [...state.thresholds, action?.payload?.threshold]
        state.saving = false
      })
      .addCase(sendUpdateThreshold.pending, (state, action) => {
        state.error = null
        state.saving = true
      })
      .addCase(sendUpdateThreshold.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.updatedThreshold = action?.payload?.threshold ?? {}
        state.thresholds = state.thresholds.map(t => {
          if (t.measurementId === action?.payload?.threshold.measurementId) {
            return action?.payload?.threshold
          }
          return t
        })
        state.saving = false
      })
      .addCase(sendDeleteThreshold.pending, (state, action) => {
        state.error = null
        state.saving = true
      })
      .addCase(sendDeleteThreshold.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.deletedThreshold = action?.payload?.threshold ?? {}
        state.thresholds = action?.payload?.error
          ? state.thresholds
          : state.thresholds.filter(
              t => t.measurementId !== action?.payload?.threshold.measurementId
            )
        state.saving = false
      })
  }
})

function cleanupBounds(bound) {
  let { notification, caution, warning } = bound
  notification = notification === 0 ? '0' : `${notification}`
  caution = caution === 0 ? '0.0' : `${caution}`
  warning = warning === 0 ? '0.0' : `${warning}`
  bound.notification = notification
  bound.caution = caution
  bound.warning = warning
  return bound
}

export default thresholdSlice.reducer

import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { getCognitoData, makeGraphqlRequest } from '../utils'

/*
 * Queries
 */

export const getUserManagers = async () => {
  try {
    const query = /* GraphQL */ `
      query getUserManagers {
        getUserManagers {
          zoneId
          rootId
        }
      }
    `

    const { id, idToken } = await getCognitoData()
    const operation = graphqlOperation(query, {})
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken,
      id
    )
    if (error) {
      return {
        managers: null,
        error: `Managers not fetched: ${error}`
      }
    }

    return { managers: data?.getUserManagers, error: null }
  } catch (err) {
    return { managers: null, error: err.message }
  }
}

export const getManagersByZoneId = async params => {
  try {
    const query = /* GraphQL */ `
      query getManagersByZoneId($zoneId: String!) {
        getManagersByZoneId(zoneId: $zoneId) {
          zoneId
          rootId
          userId
          settings {
            email
            pn
            sms
            webPush
            whatsApp
          }
        }
      }
    `

    const { id, idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken,
      id
    )
    if (error) {
      return {
        managers: null,
        error: `Managers not fetched: ${error}`
      }
    }

    return { managers: data?.getManagersByZoneId, error: null }
  } catch (err) {
    return { managers: null, error: err.message }
  }
}

/*
 * Mutations
 */

export const createManager = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createManager(
        $zoneId: String!
        $rootId: String!
        $userId: String!
        $settings: ManagerSettingsInput
      ) {
        createManager(
          zoneId: $zoneId
          rootId: $rootId
          userId: $userId
          settings: $settings
        ) {
          zoneId
          rootId
          userId
          settings {
            email
            pn
            sms
            webPush
            whatsApp
          }
        }
      }
    `

    const { id, idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken,
      id
    )

    if (error) {
      return {
        manager: null,
        error: `Manager not created: ${error}`
      }
    }

    return { manager: data?.createManager, error: null }
  } catch (err) {
    return { manager: null, error: err.message }
  }
}

export const updateManager = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateManager(
        $zoneId: String!
        $rootId: String!
        $userId: String!
        $settings: ManagerSettingsInput
      ) {
        updateManager(
          zoneId: $zoneId
          rootId: $rootId
          userId: $userId
          settings: $settings
        ) {
          zoneId
          rootId
          userId
          settings {
            email
            pn
            sms
            webPush
            whatsApp
          }
        }
      }
    `

    const { id, idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken,
      id
    )

    if (error) {
      return {
        manager: null,
        error: `Manager not updated: ${error}`
      }
    }

    return { manager: data?.updateManager, error: null }
  } catch (err) {
    return { manager: null, error: err.message }
  }
}

export const deleteManager = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation deleteManager($zoneId: String!, $userId: String!) {
        deleteManager(zoneId: $zoneId, userId: $userId) {
          zoneId
          rootId
          userId
          settings {
            email
            pn
            sms
            webPush
            whatsApp
          }
        }
      }
    `

    const { id, idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken,
      id
    )

    if (error) {
      return {
        manager: null,
        error: `Manager not deleted: ${error}`
      }
    }

    return { manager: data?.deleteManager, error: null }
  } catch (err) {
    return { manager: null, error: err.message }
  }
}

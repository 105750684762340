import { I18n } from 'aws-amplify'
import { useEffect, useState } from 'react'

import { getNavigationLocationSelector, getZonesHierarchy } from '../../../../reducers/selectors'

import ZoneUtils from '../../../../Util/ZoneUtils'
import { Select } from '@/elements'

const SitesFilter = ({ dispatchState, stateSites = [], disabled = false }) => {
  const siteId = stateSites[0] ?? null

  const zonesHierarchy = getZonesHierarchy()
  
  const locationSelector = getNavigationLocationSelector()
  
  const [sites, setSites] = useState([])
  const [siteFilter, setSiteFilter] = useState(siteId)

  const siteString = I18n.get('site')

  useEffect(() => {
    if (locationSelector.get('site')) {
      const siteIdFromPath = locationSelector.get('site').id
      setSiteFilter(siteIdFromPath)
      dispatchState({
        sites: [siteIdFromPath]
      })
    }
  }, [locationSelector.get('site')])

  useEffect(() => {
    setSiteFilter(siteId)
  }, [siteId])

  useEffect(() => {
    if (Object.keys(zonesHierarchy)?.length > 0 && siteId) {
      let activeSites = { ...zonesHierarchy }
      for (const siteId in zonesHierarchy) {
        const siteInfo = zonesHierarchy[siteId]
        if (siteInfo.status === 'inactive') delete activeSites[siteInfo.id]
      }

      const site = ZoneUtils.getZoneFromHierarchy(activeSites, siteId)

      if (site?.organizationId) {
        const allSites = ZoneUtils.getRootZonesFromHierarchy(activeSites)
        const sitesList = allSites.filter(
          ({ organizationId }) => organizationId === site.organizationId
        )
        setSites(sitesList)
      }
    }
  }, [JSON.stringify(zonesHierarchy), siteId])

  const dispatchChange = newSite => {
    dispatchState({
      sites: [newSite],
      zonePath: `/${newSite}`
    })
  }

  const onChangeFilter = e => {
    const newSite = e?.value ?? null
    setSiteFilter(newSite)
    dispatchChange(newSite)
  }

  const options = sites.map(({ id, name }) => ({
    value: id,
    label: name
  }))

  return (
    <Select
      value={siteFilter}
      placeholder={`${I18n.get('Select')} ${siteString}`}
      options={options}
      onChange={onChangeFilter}
      isDisabled={disabled}
      className='Operations__Databoard__Filter'
      classNamePrefix='Operations__Databoard__Filter'
    />
  )
}

export default SitesFilter

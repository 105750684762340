import classnames from 'classnames'

import { useState } from 'react'

import { FlexV2, Box, Icon } from '@/primitives'

import Device from './Device'
import Widget from './Widget'

const Aside = ({
  devices,
  currentCluster,
  setCurrentCluster,
  currentDevice,
  setCurrentDevice,
  setZoomingOut,
  isMobile
}) => {
  const [mobileIsOpen, setMobileIsOpen] = useState(false)

  const handleBack = () => {
    if (currentDevice) {
      setCurrentDevice(null)
    } else if (currentCluster) {
      if (isMobile) {
        setCurrentCluster(currentCluster)
      } else {
        setZoomingOut(true)
        setCurrentCluster(null)
      }
    }
  }

  const toggleOpen = () => {
    setMobileIsOpen(!mobileIsOpen)
  }

  if (isMobile && !mobileIsOpen) {
    return (
      <div className={classnames('map-mobile-lapel')} onClick={toggleOpen}>
        <Icon
          size={600}
          tone={500}
          variant={'page'}
          name={
            mobileIsOpen
              ? 'keyboard_double_arrow_right'
              : 'keyboard_double_arrow_left'
          }
        />
      </div>
    )
  }

  return (
    <div className='map-sidebar'>
      {isMobile && (
        <div className='map-mobile-lapel open' onClick={toggleOpen}>
          <Icon
            size={600}
            tone={500}
            variant={'page'}
            name={
              mobileIsOpen
                ? 'keyboard_double_arrow_right'
                : 'keyboard_double_arrow_left'
            }
          />
        </div>
      )}
      {(currentCluster || currentDevice) && (
        <div
          className={classnames('map-lapel', { mobile: isMobile })}
          onClick={handleBack}
        >
          <Icon
            size={600}
            tone={500}
            variant={'page'}
            name={'zoom_out'}
          />
        </div>
      )}
      <aside className='map-aside'>
        <FlexV2 direction='column' wrap='nowrap'>
          <Box className='container'>
            {currentDevice && <Device deviceId={currentDevice.id} />}
            {!currentDevice && (
              <div>
                {devices?.map(device => (
                  <Widget
                    key={device.id}
                    device={device}
                    setCurrentDevice={setCurrentDevice}
                  />
                ))}
              </div>
            )}
          </Box>
        </FlexV2>
      </aside>
    </div>
  )
}

export default Aside

import { updateList } from '../util'

import {
  RECEIVE_ALL_PAYMENT_METHODS,
  REQUEST_ALL_PAYMENT_METHODS,
  RECEIVE_PAYMENT_METHODS,
  REQUEST_PAYMENT_METHODS,
  CLEAR_PAYMENT_METHODS,
  CLEAR_PAYMENT_METHOD,
  REQUEST_PAYMENT_METHOD,
  RECEIVE_PAYMENT_METHOD,
  CREATE_PAYMENT_METHOD,
  PAYMENT_METHOD_CREATED,
  UPDATE_PAYMENT_METHOD,
  PAYMENT_METHOD_UPDATED,
  SET_PAYMENT_METHOD
} from '@/actions/operations/paymentMethod'

export const paymentMethodInitialState = {
  allPaymentMethods: [],
  paymentMethods: [],
  paymentMethod: {},
  paymentMethodsCount: 0,
  loading: false,
  error: null
}

const handlers = {
  [SET_PAYMENT_METHOD]: (state, action) => ({
    ...state,
    paymentMethod: action.paymentMethod
  }),
  [CLEAR_PAYMENT_METHOD]: (state, action) => ({
    ...state,
    paymentMethod: {},
    error: null
  }),
  [CLEAR_PAYMENT_METHODS]: (state, action) => ({
    ...state,
    paymentMethods: [],
    error: null
  }),
  [REQUEST_ALL_PAYMENT_METHODS]: state => ({
    ...state,
    error: null,
    loading: true
  }),
  [RECEIVE_ALL_PAYMENT_METHODS]: (state, action) => ({
    ...state,
    allPaymentMethods: !action.error ? action.paymentMethods : [],
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_PAYMENT_METHOD]: (state, action) => ({
    ...state,
    paymentMethod: {},
    error: null,
    loading: true
  }),
  [RECEIVE_PAYMENT_METHOD]: (state, action) => ({
    ...state,
    paymentMethod: !action.error ? action.paymentMethod : state.paymentMethod,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_PAYMENT_METHODS]: (state, action) => ({
    ...state,
    paymentMethods: [],
    error: null,
    loading: true
  }),
  [RECEIVE_PAYMENT_METHODS]: (state, action) => ({
    ...state,
    paymentMethods: !action.error ? action.paymentMethods : [],
    paymentMethodsCount: !action.error ? action.count : 0,
    error: action.error ?? null,
    loading: false
  }),
  [CREATE_PAYMENT_METHOD]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [PAYMENT_METHOD_CREATED]: (state, action) => ({
    ...state,
    paymentMethod: !action.error ? {} : state.paymentMethod,
    paymentMethods: updateList(
      action.error,
      state.paymentMethods,
      action.paymentMethod,
      true
    ),
    allPaymentMethods: updateList(
      action.error,
      state.allPaymentMethods,
      action.paymentMethod,
      true
    ),
    paymentMethodsCount: !action.error
      ? state.paymentMethodsCount + 1
      : state.paymentMethodsCount,
    error: action.error ?? null,
    loading: false
  }),
  [UPDATE_PAYMENT_METHOD]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [PAYMENT_METHOD_UPDATED]: (state, action) => ({
    ...state,
    paymentMethod: !action.error ? {} : state.paymentMethod,
    paymentMethods: updateList(
      action.error,
      state.paymentMethods,
      action.paymentMethod,
      false
    ),
    allPaymentMethods: updateList(
      action.error,
      state.allPaymentMethods,
      action.paymentMethod,
      false
    ),
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

import { useEffect, useReducer, useState } from 'react'

import { Text, Flex } from '@/primitives'
import Select from '@/elements/Select'

import {
  UPDATE_WIDGET,
  CANCEL_WIDGET_UPDATE
} from '@/components/DashboardPageV2/Desktop/state'
import { useDashboard } from '@/components/DashboardPageV2/Desktop/context'
import { getWidgetPositionByChart } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config/utils'
import { useEditResize } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/utils/useEditResize'
import EditLayout from '@/components/DashboardPageV2/Desktop/Widgets/Shared/EditLayout'
import { getValidationErrorMap } from '@/Util/GeneralUtils'
import { LIVE_QUERY } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'
import {
  createRangeOptions,
  getHasInterval,
  getHasRange,
  hashOptions,
  prepareOptions
} from '../../Shared/Dynamic/DefaultDynamicEdit/utils'
import { reactSelectStyles } from '@/components/DashboardPageV2/utils'
import {
  createProduceOptions,
  getHasProduceId,
  getOperationsCancelDisable,
  operationEditInitalState,
  operationEditReducer,
  operationEditTypes,
  validateQueryOptions,
  validateSchemaByType
} from './utils'
import { createIntervalOptions } from '../../Shared/Dynamic/DefaultDynamicEdit/utils'
import { Strings } from '@/components/DashboardPageV2/Desktop/Widgets/Strings'

const SelectLabel = ({ label, error }) => {
  const text = error ? error : label
  const variant = error ? 'error' : 'page'
  const tone = error ? 500 : 800

  return (
    <Text variant={variant} tone={tone}>
      {text}
    </Text>
  )
}

/**
 * Default edit component for Measurement widgets
 * @param {Array} options.queryOptionsList [queryOptionsList] - list of query options
 * @param {Array} options.produces [produces] - list of produces
 * @param {Object} config - widget config
 * @param {String} config.chart - chart config
 * @param {String} config.query - query config
 * @param {String} config.range - range config
 * @param {String} config.interval - interval config
 * @param {String} config.produceId - produceId config
 * @param {Function} setWidgetMode - widget mode dispatcher
 * @param {Object} validationSchema - validation schema
 * @param {String} id - id
 * @param {String} widgetId - widgetId
 */
export function OperationEdit({
  setWidgetMode,
  validationSchema = null,
  options,
  config,
  ...props
}) {
  const { state, dispatchState } = useDashboard()
  const [reducerInit, setReducerInit] = useState(false)
  const [errors, setErrors] = useState({})

  const [formState, dispatchLocalState] = useReducer(
    operationEditReducer,
    operationEditInitalState
  )
  const { hasBeenEditResized, oldPosition } = useEditResize(props.id)

  const stringifiedConfig = JSON.stringify(config)

  useEffect(() => {
    const config = JSON.parse(stringifiedConfig)
    if (config && !reducerInit) {
      dispatchLocalState({
        type: operationEditTypes.setAll,
        payload: {
          query: config.query,
          chart: config.chart,
          range: config.range,
          interval: config.interval,
          produceId: config.produceId
        }
      })
      setReducerInit(true)
    }
  }, [props, stringifiedConfig, reducerInit])

  const handleSave = async () => {
    try {
      const { props: widgetProps } = state.widgets.get(props.id)

      const newConfig = {
        ...widgetProps.config,
        ...formState
      }

      const nextProps = {
        ...widgetProps,
        config: newConfig
      }

      if (validationSchema) {
        await validationSchema.validate(newConfig, { abortEarly: false })
      } else {
        const params = validateSchemaByType(
          newConfig.query,
          newConfig?.chart,
          props.widgetId
        )

        await validateQueryOptions(params).validate(newConfig, {
          abortEarly: false
        })
      }

      dispatchState({
        type: UPDATE_WIDGET,
        payload: {
          ...nextProps,
          position: getWidgetPositionByChart(
            nextProps,
            state.currentBreakpoint,
            widgetProps.config.chart,
            hasBeenEditResized,
            oldPosition
          )
        }
      })
      setWidgetMode('view')
    } catch (error) {
      setErrors(getValidationErrorMap(error))
    }
  }

  const handleCancel = () => {
    const { props: widgetProps } = state.widgets.get(props.id)

    dispatchState({
      type: CANCEL_WIDGET_UPDATE,
      payload: {
        id: props.id,
        position: getWidgetPositionByChart(
          widgetProps,
          state.currentBreakpoint,
          hasBeenEditResized,
          oldPosition
        )
      }
    })

    setWidgetMode('view')
  }

  const onSelectQuery = option => {
    dispatchLocalState({
      type: operationEditTypes.setQuery,
      payload: option.value
    })
  }

  const onSelectChart = option => {
    dispatchLocalState({
      type: operationEditTypes.setChart,
      payload: option.value
    })
  }

  const onSelectRange = option => {
    dispatchLocalState({
      type: operationEditTypes.setRange,
      payload: option.value
    })
  }

  const onSelectProduceId = option => {
    dispatchLocalState({
      type: operationEditTypes.setProduceId,
      payload: option.value
    })
  }

  const onSelectInterval = option => {
    dispatchLocalState({
      type: operationEditTypes.setInterval,
      payload: option.value
    })
  }

  const strings = Strings()

  const hashedOptions = hashOptions(options.queryOptionsList)
  const queryOptions = prepareOptions(hashedOptions, strings.selectQuery)
  const chartOptions = prepareOptions(
    hashedOptions[formState?.query]?.options,
    strings.selectChart
  )

  const hasRange = getHasRange(hashedOptions, formState)
  const hasInterval = getHasInterval(hashedOptions, formState)

  const hasProduceId = getHasProduceId(options, props.widgetId)

  let rangeOptions = []

  if (hasRange && formState.chart) {
    rangeOptions = createRangeOptions(
      hashedOptions[formState.query]?.options[formState.chart]?.range,
      strings.selectRange
    )
  }

  let producesOptions = []
  if (hasProduceId) {
    producesOptions = createProduceOptions(
      options?.produces || [],
      strings.selectProduce
    )
  }

  let intervalOptions = []
  if (hasRange && hasInterval && formState.range) {
    intervalOptions = createIntervalOptions(
      hashedOptions[formState.query]?.options[formState.chart]?.interval,
      formState.range,
      strings.selectInterval
    )
  }

  const handleSelectQuery = option => {
    onSelectQuery(option)

    if (errors?.query || errors?.chart || errors?.range || errors?.interval) {
      setErrors({ ...errors, query: '', chart: '', range: '', interval: '' })
    }
  }

  const handleSelectChart = option => {
    onSelectChart(option)
    if (errors?.chart || errors?.range) {
      setErrors({ ...errors, chart: '', range: '', interval: '' })
    }
  }

  const handleSelectRange = option => {
    onSelectRange(option)
    if (errors?.range || errors?.interval) {
      setErrors({ ...errors, range: '', interval: '' })
    }
  }

  const handleSelectProduceId = option => {
    onSelectProduceId(option)
    if (errors?.produceId) {
      setErrors({ ...errors, produceId: '' })
    }
  }

  const handleSelectInterval = option => {
    onSelectInterval(option)
    if (errors?.interval) {
      setErrors({ ...errors, interval: '' })
    }
  }

  return (
    <EditLayout
      id={props.id}
      onSave={handleSave}
      onCancel={handleCancel}
      disableCancel={getOperationsCancelDisable({ options, config, ...props })}
    >
      <Flex
        className='Dynamic_DefaultEdit'
        direction='column'
        alignCrossAxis='flex-start'
        alignMainAxis='space-around'
        wrap='nowrap'
        axisGap={300}
      >
        <Flex direction='column' axisGap={200}>
          <SelectLabel label={strings.selectQuery} error={errors?.query} />
          <Select
            className='Dynamic_DefaultEdit__Select'
            style={{ width: '100%' }}
            options={queryOptions}
            onChange={handleSelectQuery}
            value={formState.query}
            controlStyles={reactSelectStyles}
            menuListStyles={reactSelectStyles}
          />
        </Flex>

        {formState.query && formState.query !== LIVE_QUERY && (
          <Flex direction='column' axisGap={200}>
            <SelectLabel label={strings.selectChart} error={errors?.chart} />
            <Select
              className='Dynamic_DefaultEdit__Select'
              style={{ width: '100%' }}
              options={chartOptions}
              onChange={handleSelectChart}
              value={formState.chart}
              controlStyles={reactSelectStyles}
              menuListStyles={reactSelectStyles}
            />
          </Flex>
        )}
        {formState.chart && hasRange && (
          <Flex direction='column' axisGap={200}>
            <SelectLabel label={strings.selectRange} error={errors?.range} />
            <Select
              className='Dynamic_DefaultEdit__Select'
              style={{ width: '100%' }}
              options={rangeOptions}
              onChange={handleSelectRange}
              value={formState.range}
              controlStyles={reactSelectStyles}
              menuListStyles={reactSelectStyles}
            />
          </Flex>
        )}

        {formState.range && hasRange && hasInterval && (
          <Flex direction='column' axisGap={200}>
            <SelectLabel
              label={strings.selectInterval}
              error={errors?.interval}
            />
            <Select
              className='Dynamic_DefaultEdit__Select'
              style={{ width: '100%' }}
              options={intervalOptions}
              onChange={handleSelectInterval}
              value={formState.interval}
              controlStyles={reactSelectStyles}
              menuListStyles={reactSelectStyles}
            />
          </Flex>
        )}

        {hasProduceId && (
          <Flex direction='column' axisGap={200}>
            <SelectLabel
              label={strings.selectProduce}
              error={errors?.produceId}
            />
            <Select
              className='Dynamic_DefaultEdit__Select'
              style={{ width: '100%' }}
              options={producesOptions}
              onChange={handleSelectProduceId}
              value={formState.produceId}
              controlStyles={reactSelectStyles}
              menuListStyles={reactSelectStyles}
            />
          </Flex>
        )}
      </Flex>
    </EditLayout>
  )
}

import { I18n } from 'aws-amplify'

export const DATE_RANGE_ID_FORMAT = 'yyyy MMM dd HH:mm'
export const COMPARISON_TYPES = {
  DATE_RANGES: 'dateRanges',
  LOCATION_RANGES: 'locationRanges'
}

export const INTERVALS = ['minute', 'hour', 'day', 'week', 'month']

const day = 24 * 60 * 60 * 1000

export const INTERVAL_LIMITS = {
  minute: 7 * day, // 7 days
  hour: 31 * day, // 1 month
  day: 5 * 366 * day, // 5 years
  week: 10 * 366 * day, // 10 years
  month: 10 * 366 * day // 10 years
}

export const INTERVAL_MAX_LIMITS = {
  minute: 31 * day, // 30 days
  hour: 6 * 31 * day, // 6 months
  day: 10 * 366 * day, // 10 years
  week: 10 * 366 * day, // 10 years
  month: 10 * 366 * day // 10 years
}

export const RANGE_FORMATS = {
  lasthour: 'HH:mm',
  today: 'HH:mm',
  last24hours: 'ha',
  yesterday: 'ha',
  last7days: 'MM/dd',
  last30days: 'MM/dd',
  lastmonth: 'MM/dd',
  thisyear: 'MM/dd/yyyy',
  last12months: 'yyyy'
}

export const RANGE_FORMAT_LIMITS = {
  'HH:mm': day,
  'MM/dd': 6 * 30 * day,
  'MM/dd/yyyy': 2 * 366 * day,
  yyyy: 10 * 366 * day
}

export const DATE_RANGES = [
  'customRange',
  'lasthour',
  'today',
  'last24hours',
  'yesterday',
  'last7days',
  'last30days',
  'lastmonth',
  'thisyear',
  'last12months'
]

export const INTERVAL_WEIGHT = {
  minute: 0,
  hour: 1,
  day: 2,
  week: 3,
  month: 4
}

export const RANGE_WEIGHTS = {
  lasthour: 0,
  today: 1,
  last24hours: 2,
  yesterday: 3,
  last7days: 4,
  last30days: 5,
  lastmonth: 6,
  thisyear: 7,
  last12months: 8,
  customRange: 9
}

export const INTERVAL_TICKS = {
  minute: 8,
  hour: 8,
  day: 7,
  week: 6,
  month: 6
}

export const INTERVAL_PRECISION = {
  week: 'day'
}

export const INTERVAL_TIMESTREAM_MAP = {
  minute: '1m',
  hour: '1h',
  day: '1d',
  week: '7d',
  month: '30d'
}

export const INTERVAL_STEPS = {
  minute: 'minutes',
  hour: 'hours',
  day: 'days',
  week: 'weeks',
  month: 'months'
}

export const INTERVAL_STEPS_CHART_LABEL = () => ({
  minute: I18n.get('Minute'),
  hour: I18n.get('Hour'),
  day: I18n.get('Day'),
  week: I18n.get('Week'),
  month: I18n.get('Month')
})

export const LINE_GRAPH_THEME = {
  background: 'transparent',
  fontFamily: 'IBM Plex Mono, monospace',
  fontSize: 11,
  textColor: 'var(--ctx-theme-contrast-page-200)',
  axis: {
    domain: {
      line: {
        stroke: 'transparent',
        strokeWidth: 1
      }
    },
    ticks: {
      line: {
        stroke: '#A0A0A0',
        strokeWidth: 1
      },
      text: {}
    },
    legend: {
      text: {
        fontSize: 12,
        color: 'var(--ctx-theme-contrast-page-200)'
      }
    }
  },
  grid: {
    line: {
      stroke: '#A0A0A0',
      strokeWidth: 1
    }
  },
  legends: {
    text: {
      fill: 'var(--ctx-theme-contrast-page-200)'
    },
    textColor: 'var(--ctx-theme-contrast-page-200)'
  },
  labels: {
    text: {
      color: 'var(--ctx-theme-contrast-page-200)'
    }
  },
  markers: {
    lineColor: '#000000',
    lineStrokeWidth: 1,
    text: {}
  },
  dots: {
    text: {}
  },
  tooltip: {
    container: {
      background: '1F2940',
      color: 'var(--ctx-theme-contrast-page-200)',
      fontSize: 'inherit',
      borderRadius: '2px',
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
      padding: '5px 9px'
    },
    basic: {
      whiteSpace: 'pre',
      display: 'flex',
      alignItems: 'center'
    },
    table: {},
    tableCell: {
      padding: '3px 5px'
    }
  },
  crosshair: {
    line: {
      stroke: 'var(--ctx-theme-contrast-page-200)',
      strokeWidth: 1,
      strokeOpacity: 0.75,
      strokeDasharray: '6 6'
    }
  },
  annotations: {
    text: {
      fontSize: 13,
      outlineWidth: 2,
      outlineColor: 'var(--ctx-theme-contrast-page-200)'
    },
    link: {
      stroke: '#000000',
      strokeWidth: 1,
      outlineWidth: 2,
      outlineColor: 'var(--ctx-theme-contrast-page-200)'
    },
    outline: {
      fill: 'none',
      stroke: '#000000',
      strokeWidth: 2,
      outlineWidth: 2,
      outlineColor: 'var(--ctx-theme-contrast-page-200)'
    },
    symbol: {
      fill: '#000000',
      outlineWidth: 2,
      outlineColor: 'var(--ctx-theme-contrast-page-200)'
    }
  }
}

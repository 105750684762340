import UsersList from './UsersList'
import AddUser from './AddUser'
import EditUser from './EditUser'
import AuditCognitoLogs from './Audit'
import { Switch, Route } from 'react-router-dom'

export default function UserManagementPage() {
  return (
    <Switch>
      <Route
        exact
        path='/admin/user-management'
        render={routeProps => <UsersList {...routeProps} />}
      />
      <Route
        exact
        path='/admin/user-management/add'
        render={routeProps => <AddUser {...routeProps} />}
      />
      <Route
        exact
        path='/admin/user-management/:id'
        render={routeProps => <EditUser {...routeProps} />}
      />
      <Route
        exact
        path='/admin/user-management/:id/audit'
        render={routeProps => <AuditCognitoLogs {...routeProps} />}
      />
    </Switch>
  )
}

import { useParams } from 'react-router-dom'

import SubzonesContainer from '../Subzones/Container'

import ZoneUtils from '@/Util/ZoneUtils'

export default function ZoneDetails({ selectedZone }) {
  const params = useParams()
  const basePath = ZoneUtils.getZoneBasePath(params)
  return <SubzonesContainer basePath={basePath} selectedZone={selectedZone} />
}

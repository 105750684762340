import { Children } from 'react'
import { Link } from 'react-router-dom'

import { Button, FlexV2, Text, Separator } from '@/primitives'

import useMediaQuery from '@/hooks/useMediaQuery'

import './index.scss'

function HeaderV2({
  title,
  backPath,
  buttonText,
  buttonIcon,
  buttonCallback,
  showButton = true,
  children,
  breakpointWidth = 640
}) {
  const isAboveBreakpoint = useMediaQuery(`min-width: ${breakpointWidth}px`)

  return (
    <FlexV2 className='Operations__Header' direction='column' axisGap={400}>
      <FlexV2
        axisGap={400}
        alignMainAxis='space-between'
        direction={isAboveBreakpoint ? 'row' : 'column'}
        alignCrossAxis={isAboveBreakpoint ? 'center' : null}
      >
        <FlexV2 axisGap={400} alignCrossAxis='center'>
          {backPath && (
            <Button
              as={Link}
              size='small'
              to={backPath}
              iconBefore='chevron_left'
            />
          )}
          <Text fontFamily='mono' tone={700} variant='page'>
            {title}
          </Text>
        </FlexV2>

        <FlexV2
          axisGap={Children.toArray(children).length > 0 ? 400 : null}
          direction={isAboveBreakpoint ? 'row' : 'column'}
          alignCrossAxis={isAboveBreakpoint ? 'center' : null}
        >
          <FlexV2
            style={{ flexGrow: 1 }}
            direction={isAboveBreakpoint ? 'row' : 'column'}
            axisGap={400}
          >
            {children}
          </FlexV2>

          {showButton && buttonCallback && (
            <Button
              size='small'
              iconBefore={buttonIcon}
              variant='primary'
              name={'submitButton'}
              onClick={buttonCallback}
            >
              <Text fontWeight={600}>{buttonText}</Text>
            </Button>
          )}
        </FlexV2>
      </FlexV2>
      <Separator />
    </FlexV2>
  )
}

export default HeaderV2

import { makeActionCreator } from '@/Util/ReduxUtils'

export const REQUEST_INVENTORY_TRANSFERS = 'REQUEST_INVENTORY_TRANSFERS'
export const requestInventoryTransfers = makeActionCreator(
  REQUEST_INVENTORY_TRANSFERS,
  'params'
)

export const RECEIVE_INVENTORY_TRANSFERS = 'RECEIVE_INVENTORY_TRANSFERS'
export const receiveInventoryTransfers = makeActionCreator(
  RECEIVE_INVENTORY_TRANSFERS,
  'inventoryTransfers',
  'count',
  'error'
)

export const CREATE_INVENTORY_TRANSFER = 'CREATE_INVENTORY_TRANSFER'
export const createInventoryTransfer = makeActionCreator(
  CREATE_INVENTORY_TRANSFER,
  'params'
)

export const INVENTORY_TRANSFER_CREATED = 'INVENTORY_TRANSFER_CREATED'
export const inventoryTransferCreated = makeActionCreator(
  INVENTORY_TRANSFER_CREATED,
  'inventoryTransfer',
  'error'
)

export const UPDATE_INVENTORY_TRANSFER = 'UPDATE_INVENTORY_TRANSFER'
export const updateInventoryTransfer = makeActionCreator(
  UPDATE_INVENTORY_TRANSFER,
  'params'
)

export const INVENTORY_TRANSFER_UPDATED = 'INVENTORY_TRANSFER_UPDATED'
export const inventoryTransferUpdated = makeActionCreator(
  INVENTORY_TRANSFER_UPDATED,
  'inventoryTransfer',
  'error'
)

export const REQUEST_INVENTORY_TRANSFER = 'REQUEST_INVENTORY_TRANSFER'
export const requestInventoryTransfer = makeActionCreator(
  REQUEST_INVENTORY_TRANSFER,
  'params'
)

export const RECEIVE_INVENTORY_TRANSFER = 'RECEIVE_INVENTORY_TRANSFER'
export const receiveInventoryTransfer = makeActionCreator(
  RECEIVE_INVENTORY_TRANSFER,
  'inventoryTransfer',
  'error'
)

export const CLEAR_INVENTORY_TRANSFER = 'CLEAR_INVENTORY_TRANSFER'
export const clearInventoryTransfer = makeActionCreator(
  CLEAR_INVENTORY_TRANSFER
)

export const CLEAR_INVENTORY_TRANSFERS = 'CLEAR_INVENTORY_TRANSFERS'
export const clearInventoryTransfers = makeActionCreator(
  CLEAR_INVENTORY_TRANSFERS
)

export const SET_INVENTORY_TRANSFER = 'SET_INVENTORY_TRANSFER'
export const setInventoryTransfer = makeActionCreator(
  SET_INVENTORY_TRANSFER,
  'inventoryTransfer'
)

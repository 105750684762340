import useOperationsData from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Hooks/useOperationsData'
import Pie from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/Chart/Pie'

export default function DatasetPie({ config, options }) {
  const { segmentId, unit } = options

  const { produceId } = config

  const { datasetData } = useOperationsData({
    range: config.range,
    segmentId,
    filters: {
      interval: config.interval,
      produceId,
      range: config.range
    },
    queryType: config.query,
    tileType: config.chart,
    opsParams: { produceId }
  })

  let data = []

  if (datasetData?.length) {
    data = datasetData.map(item => {
      return {
        id: item.label,
        value: item.value,
        label: item.label
      }
    })
  }

  return <Pie data={data} config={config} unit={unit} />
}

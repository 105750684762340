import Line from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/Chart/Line'
import Matrix from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/Chart/Matrix'
import { BAR_CHART } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'
import { handleMeasurementConversion } from '@/components/DashboardPageV2/Desktop/Widgets/Shared/utils'

import { getCurrentUser, getRootZone } from '@/reducers/selectors'

import {
  LINE_CHART,
  MATRIX_CHART
} from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Dynamic/config'
import useDatasetData from '@/components/DashboardPageV2/Desktop/Widgets/Shared/Hooks/useDatasetData'
import BarChart from '../../Chart/Bar'
import { parseValue } from '../../../utils'

export default function Dataset({ options, config, unit, ...props }) {
  const { datasetData, queryZones } = useDatasetData({
    measurements: [options.measurement],
    range: config.range,
    interval: config.interval
  })

  const currentUser = getCurrentUser()
  const rootZone = getRootZone()

  let data = datasetData?.[options?.measurement] ?? {}

  const conversion = handleMeasurementConversion({
    data,
    measurement: options?.measurement,
    unit,
    queryType: config.query,
    settings: currentUser?.settings,
    userId: currentUser?.userName,
    organizationId: rootZone?.organizationId
  })

  if (conversion) {
    data = conversion.data
    unit = conversion.unit
  }

  const graphData = JSON.parse(JSON.stringify(data))

  for (let zone in graphData) {
    for (let datapoint of graphData[zone]) {
      const parsedValue = parseFloat(
        parseValue({
          value: datapoint.y,
          fixedFactor: options.fixedFactor,
          scaleFactor: options.scaleFactor
        })
      )

      datapoint.y = parsedValue
    }
  }

  if (config.chart === LINE_CHART) {
    return (
      <Line
        {...props}
        data={graphData}
        options={options}
        config={config}
        unit={unit}
        queryZones={queryZones}
      />
    )
  }

  if (config.chart === MATRIX_CHART) {
    return (
      <Matrix
        {...props}
        config={config}
        data={graphData}
        options={options}
        unit={unit}
        queryZones={queryZones}
      />
    )
  }

  if (config.chart === BAR_CHART) {
    return (
      <BarChart
        {...props}
        config={config}
        data={graphData}
        options={options}
        unit={unit}
      />
    )
  }

  return null
}

import { useState, useEffect, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import ReactSelect from 'react-select'

import {
  requestUnitPrices,
  clearUnitPrices
} from '@/actions/operations/unitPrice'
import { requestProducts } from '@/actions/operations/product'

import { createSaleItem, updateSaleItem } from '@/actions/operations/sale'

import {
  getCurrentUserOrganizations,
  getOperationsAllCurrencies,
  getOperationsUnitPrices,
  getOperationsProducts
} from '@/reducers/selectors'

import { Box, Button, Flex, Label, Text, Input, Separator } from '@/primitives'

import useDeviceSize from '@/hooks/useDeviceSize'
import { getValidationErrorMap } from '@/Util/GeneralUtils'
import InputError from '@/elements/InputError'

import { formatFloat } from '../../../utils'

import { UPDATE_SALE_ITEM } from '../state'
import { initialState, reducer, SET_STATE, UPDATE_INPUT } from './state'

import { FIELDS, SCHEMA } from './config'
import Strings from './Strings'

function SaleItemForm({ sale, saleItem, closeForm, dispatchFormState }) {
  const dispatch = useDispatch()
  const isMobile = useDeviceSize('mobile')

  const strings = Strings()

  const organizations = getCurrentUserOrganizations()
  const currencies = getOperationsAllCurrencies()
  const unitPrices = getOperationsUnitPrices()
  const products = getOperationsProducts()

  const [errors, setErrors] = useState({})
  const [formState, formStateDispatch] = useReducer(reducer, initialState)
  const [organizationId, setOrganizationId] = useState(sale.organizationId)
  const [unitPrice, setUnitPrice] = useState({})

  useEffect(() => {
    return () => {
      dispatch(clearUnitPrices())
    }
  }, [])

  useEffect(() => {
    if (organizations.length > 0) {
      dispatch(
        requestProducts({
          organizationId: organizations.map(o => o.id)
        })
      )
    }
  }, [dispatch, organizations])

  useEffect(() => {
    if (saleItem.id) {
      formStateDispatch({
        type: SET_STATE,
        state: {
          ...saleItem
        }
      })
    }
  }, [saleItem])

  useEffect(() => {
    if (saleItem.product?.id) {
      formStateDispatch({
        type: UPDATE_INPUT,
        name: 'productId',
        value: saleItem.product.id
      })
    }
  }, [saleItem.product])

  useEffect(() => {
    if (sale.organizationId !== organizationId) {
      formStateDispatch({
        type: UPDATE_INPUT,
        name: 'productId',
        value: ''
      })
      setOrganizationId(sale.organizationId)
    }
  }, [sale.organizationId])

  useEffect(() => {
    if (sale.customerId && formState.productId) {
      dispatch(
        requestUnitPrices({
          filter: {
            customerId: [sale.customerId],
            productId: [formState.productId]
          }
        })
      )
    } else {
      setUnitPrice({})
    }
  }, [sale.customerId, formState.productId])

  useEffect(() => {
    if (sale.customerId && formState.productId && unitPrices.length > 0) {
      setUnitPrice(unitPrices[0])
    }
  }, [unitPrices])

  const isNewItem = () => {
    let currentIndex = sale.saleItems.findIndex(item => item.id === saleItem.id)
    return currentIndex < 0
  }

  const updateSale = item => {
    const productIndex = products.findIndex(p => p.id === item.productId)
    dispatchFormState({
      type: UPDATE_SALE_ITEM,
      saleItem: {
        product: products[productIndex],
        ...item
      }
    })
  }

  const saveSaleItem = async () => {
    try {
      const validFormState = await SCHEMA.validate(formState, {
        abortEarly: false
      })
      if (sale?.id) {
        if (isNewItem()) {
          dispatch(
            createSaleItem({
              ...validFormState,
              saleId: sale.id
            })
          )
        } else {
          dispatch(
            updateSaleItem({
              ...validFormState,
              saleItemId: saleItem.id,
              saleId: sale.id
            })
          )
        }
      }
      updateSale(validFormState)
      closeForm()
    } catch (error) {
      setErrors(getValidationErrorMap(error))
    }
  }

  const handleProductInput = option => {
    formStateDispatch({
      type: UPDATE_INPUT,
      name: 'productId',
      value: option.value
    })
  }

  const handleNumericInput = e => {
    const { name, value } = e.currentTarget
    const newValue = value.length > 0 ? Number(value) : ''
    formStateDispatch({ type: UPDATE_INPUT, name, value: newValue })
  }

  const applyUnitPrice = () => {
    formStateDispatch({
      type: SET_STATE,
      state: {
        ...formState,
        unitPrice: unitPrice.unitPrice,
        discount: unitPrice.discount
      }
    })
  }

  const getCurrencyCode = () => {
    if (sale.currencyId) {
      const currencyIndex = currencies.findIndex(c => c.id === sale.currencyId)
      if (currencyIndex >= 0) {
        return currencies[currencyIndex].code
      }
    }
    return null
  }

  const getRecommendedPrice = () => {
    if (unitPrice.id) {
      return formatFloat(
        (unitPrice.unitPrice / 100) * (100 - unitPrice.discount)
      )
    }
    return null
  }

  const getProductValue = () => {
    if (formState.productId) {
      const productIndex = products.findIndex(p => p.id === formState.productId)
      if (productIndex >= 0) {
        const { id, name, weight } = products[productIndex]
        const label = `${name} (${weight}${strings.grams})`
        return {
          value: id,
          label: label
        }
      }
    }
    return {
      label: strings.selectDefault,
      value: ''
    }
  }

  const filterProducts = () => {
    return products
      .filter(p => p.organizationId === sale.organizationId)
      .map(p => ({
        value: p.id,
        label: `${p.name} (${p.weight}${strings.grams})`
      }))
  }

  const getHeader = () => {
    if (isNewItem()) {
      return strings.createFormHeader
    }
    return strings.updateFormHeader
  }

  return (
    <Box>
      <Separator className='Separator__Line' />
      <Text size={400} fontWeight={500} tone={600}>
        {getHeader()}
      </Text>
      <Flex
        axisGap={300}
        alignMainAxis='flex-start'
        direction={isMobile ? 'column' : 'row'}
        className='Operations__Fields'
      >
        <Label style={{ flex: '3 1 0' }}>
          <Flex axisGap={300} direction='column'>
            <Text variant='page' tone={700}>
              {strings.product}
            </Text>
            <ReactSelect
              className='Operations__Form__Select'
              classNamePrefix={'ReactSelect__Single'}
              name={FIELDS.productId}
              value={getProductValue()}
              onChange={handleProductInput}
              isMulti={false}
              placeholder={strings.selectDefault}
              options={filterProducts()}
              isSearchable={true}
            />
            <InputError error={errors?.productId} />
          </Flex>
        </Label>
        <Label style={{ flex: '1 1 0' }}>
          <Flex axisGap={300} direction='column'>
            <Text variant='page' tone={700}>
              {strings.quantityOrdered}
            </Text>
            <Input
              className='Operations__Input'
              type='number'
              name={FIELDS.quantityOrdered}
              value={formState.quantityOrdered}
              onChange={handleNumericInput}
            />
            <InputError error={errors?.quantityOrdered} />
          </Flex>
        </Label>
      </Flex>
      <Flex
        axisGap={300}
        alignMainAxis='flex-start'
        direction={isMobile ? 'column' : 'row'}
        className='Operations__Fields'
      >
        <Label style={{ flex: '1 1 0' }}>
          <Flex axisGap={300} direction='column'>
            <Text variant='page' tone={700}>
              {strings.unitPrice}
            </Text>
            <Box className='Operations__Input__Container'>
              <Input
                className='Operations__Input'
                type='number'
                name={FIELDS.unitPrice}
                value={formState.unitPrice}
                onChange={handleNumericInput}
              />
              <span className='Operations__Input__Suffix'>
                {getCurrencyCode()}
              </span>
            </Box>
            <InputError error={errors?.unitPrice} />
            {unitPrice.id && (
              <Box className='Operations__Input__Hint'>
                <Text>
                  {strings.recommended}:&nbsp;
                  {getRecommendedPrice()} {unitPrice.currency.code}
                </Text>
                <Button
                  variant='text'
                  size='small'
                  onClick={applyUnitPrice}
                  className='Operations__Input__Hint__Button'
                >
                  <Text fontWeight={400}>({strings.apply})</Text>
                </Button>
              </Box>
            )}
          </Flex>
        </Label>
        <Label style={{ flex: '1 1 0' }}>
          <Flex axisGap={300} direction='column'>
            <Text variant='page' tone={700}>
              {strings.discount}
            </Text>
            <Box className='Operations__Input__Container'>
              <Input
                className='Operations__Input'
                type='number'
                name={FIELDS.discount}
                value={formState.discount}
                onChange={handleNumericInput}
              />
              <span className='Operations__Input__Suffix'>%</span>
            </Box>
            <InputError error={errors?.discount} />
          </Flex>
        </Label>
        <Label style={{ flex: '1 1 0' }}>
          <Flex axisGap={300} direction='column'>
            <Text variant='page' tone={700}>
              {strings.tax}
            </Text>
            <Box className='Operations__Input__Container'>
              <Input
                className='Operations__Input'
                type='number'
                name={FIELDS.tax}
                value={formState.tax}
                onChange={handleNumericInput}
              />
              <span className='Operations__Input__Suffix'>%</span>
            </Box>
            <InputError error={errors?.tax} />
          </Flex>
        </Label>
      </Flex>
      <Flex alignMainAxis={'flex-end'} style={{ marginTop: '20px' }}>
        <Button
          variant='warning'
          size='small'
          iconBefore='cancel'
          onClick={closeForm}
          style={{ marginRight: '10px' }}
        >
          <Text fontWeight={700} textTransform='uppercase'>
            {strings.formCancelBtn}
          </Text>
        </Button>
        <Button
          variant='info'
          size='small'
          iconBefore='edit'
          onClick={saveSaleItem}
        >
          <Text fontWeight={700} textTransform='uppercase'>
            {strings.formSaveBtn}
          </Text>
        </Button>
      </Flex>
    </Box>
  )
}

export default SaleItemForm

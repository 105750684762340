import { updateList } from '../util'

import {
  REQUEST_ALL_VARIETIES,
  RECEIVE_ALL_VARIETIES,
  REQUEST_VARIETIES,
  RECEIVE_VARIETIES,
  CLEAR_VARIETIES,
  CLEAR_VARIETY,
  REQUEST_VARIETY,
  RECEIVE_VARIETY,
  CREATE_VARIETY,
  VARIETY_CREATED,
  UPDATE_VARIETY,
  VARIETY_UPDATED,
  SET_VARIETY
} from '@/actions/operations/variety'

export const varietyInitialState = {
  allVarieties: [],
  varieties: [],
  variety: {},
  varietiesCount: 0,
  loading: false,
  error: null
}

const handlers = {
  [SET_VARIETY]: (state, action) => ({
    ...state,
    variety: action.variety
  }),
  [CLEAR_VARIETY]: (state, action) => ({
    ...state,
    variety: {},
    error: null
  }),
  [CLEAR_VARIETIES]: (state, action) => ({
    ...state,
    varieties: [],
    error: null
  }),
  [REQUEST_ALL_VARIETIES]: state => ({
    ...state,
    error: null,
    loading: true
  }),
  [RECEIVE_ALL_VARIETIES]: (state, action) => ({
    ...state,
    allVarieties: !action.error ? action.varieties : [],
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_VARIETY]: (state, action) => ({
    ...state,
    variety: {},
    error: null,
    loading: true
  }),
  [RECEIVE_VARIETY]: (state, action) => ({
    ...state,
    variety: !action.error ? action.variety : state.variety,
    error: action.error ?? null,
    loading: false
  }),
  [REQUEST_VARIETIES]: (state, action) => ({
    ...state,
    varieties: [],
    error: null,
    loading: true
  }),
  [RECEIVE_VARIETIES]: (state, action) => ({
    ...state,
    varieties: !action.error ? action.varieties : [],
    varietiesCount: !action.error ? action.count : 0,
    error: action.error ?? null,
    loading: false
  }),
  [CREATE_VARIETY]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [VARIETY_CREATED]: (state, action) => ({
    ...state,
    variety: !action.error ? {} : state.country,
    varieties: updateList(action.error, state.varieties, action.variety, true),
    allVarieties: updateList(
      action.error,
      state.allVarieties,
      action.variety,
      true
    ),
    varietiesCount: !action.error
      ? state.varietiesCount + 1
      : state.varietiesCount,
    error: action.error ?? null,
    loading: false
  }),
  [UPDATE_VARIETY]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [VARIETY_UPDATED]: (state, action) => ({
    ...state,
    variety: !action.error ? {} : state.country,
    varieties: updateList(action.error, state.varieties, action.variety, false),
    allVarieties: updateList(
      action.error,
      state.allVarieties,
      action.variety,
      false
    ),
    error: action.error ?? null,
    loading: false
  })
}

export default handlers

import { call, put, takeLatest } from 'redux-saga/effects'

import {
  getCrops,
  getCrop,
  createCrop,
  updateCrop
} from '@/api/operations/crop'

import {
  REQUEST_CROPS,
  REQUEST_CROP,
  REQUEST_ALL_CROPS,
  CREATE_CROP,
  UPDATE_CROP,
  receiveCrops,
  receiveCrop,
  receiveAllCrops,
  cropCreated,
  cropUpdated
} from '@/actions/operations/crop'

import { displayBanner } from '../utils'

import Strings from './Strings'

const strings = Strings()

function* sendCreateCrop(action) {
  const { data, error = null } = yield call(createCrop, action.params)
  yield put(displayBanner(error, strings.cropCreated))
  yield put(cropCreated(data, error))
}

export function* watchCreateCrop() {
  yield takeLatest(CREATE_CROP, sendCreateCrop)
}

function* sendUpdateCrop(action) {
  const { data, error = null } = yield call(updateCrop, action.params)
  yield put(displayBanner(error, strings.cropUpdated))
  yield put(cropUpdated(data, error))
}

export function* watchUpdateCrop() {
  yield takeLatest(UPDATE_CROP, sendUpdateCrop)
}

function* sendRequestCrops(action) {
  const { data, error = null } = yield call(getCrops, action.params)
  const { crops = [], count = 0 } = data
  yield put(receiveCrops(crops, count, error))
}

export function* watchRequestCrops() {
  yield takeLatest(REQUEST_CROPS, sendRequestCrops)
}

function* sendRequestCrop(action) {
  const { data, error = null } = yield call(getCrop, action.params)
  yield put(receiveCrop(data, error))
}

export function* watchRequestCrop() {
  yield takeLatest(REQUEST_CROP, sendRequestCrop)
}

function* sendRequestAllCrops(action) {
  const { data, error = null } = yield call(getCrops, action.params)
  const { crops = [], count = 0 } = data
  yield put(receiveAllCrops(crops, count, error))
}

export function* watchRequestAllCrops() {
  yield takeLatest(REQUEST_ALL_CROPS, sendRequestAllCrops)
}

import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { makeGraphqlRequestV2 } from '../utils'

export const updateSensorSimStatus = async params => {
  try {
    const query = /* GraphQL */ `
      mutation updateSensorSimStatus(
        $sensorId: String!
        $action: SimStatusAction!
      ) {
        updateSensorSimStatus(sensorId: $sensorId, action: $action) {
          sensorId
          simId
          providerId
          status
        }
      }
    `

    const operation = graphqlOperation(query, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)
    return data?.updateSensorSimStatus
  } catch (err) {
    throw err
  }
}

export const getSensorSimDetails = async params => {
  try {
    const query = /* GraphQL */ `
      query getSensorSimDetails($sensorId: String!) {
        getSensorSimDetails(sensorId: $sensorId) {
          simId
          provider
          status
        }
      }
    `

    const operation = graphqlOperation(query, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)
    return data?.getSensorSimDetails
  } catch (err) {
    throw err
  }
}

export const getSimDiagnosticInfo = async params => {
  try {
    const query = /* GraphQL */ `
      query getSimDiagnosticInfo($sensorIds: [String]!) {
        getSimDiagnosticInfo(sensorIds: $sensorIds) {
          simId
          sensorId
          providerId
          status
        }
      }
    `

    const operation = graphqlOperation(query, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)
    return data?.getSimDiagnosticInfo
  } catch (err) {
    throw err
  }
}

export const getSimDiagnosticPing = async params => {
  try {
    const query = /* GraphQL */ `
      query getSimDiagnosticPing($sensorIds: [String]!) {
        getSimDiagnosticPing(sensorIds: $sensorIds) {
          sensorId
          success
          simId
          providerId
          status
          rtt
        }
      }
    `

    const operation = graphqlOperation(query, params)
    const data = await makeGraphqlRequestV2(GRAPHQL_URL, operation)
    return data?.getSimDiagnosticPing
  } catch (err) {
    throw err
  }
}

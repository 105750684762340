import { I18n } from 'aws-amplify'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  getZone,
  getZonesData,
  getZonesList,
  getZoneUsers,
  getRootZone,
  createZone,
  updateZone,
  updateZoneMaintenanceMode
} from '@/api/management/zones'
import {
  getRolesByOrganizationId,
  getRolePermissions,
  removeRolePermission
} from '@/api/management/userRoles'

import { showBanner } from '@/slices/util'

import ArrayUtils from '@/Util/ArrayUtils'
import { delay } from '@/Util/GeneralUtils'
import { getZoneDepth, getZoneSubNameV2 } from '@/Util/AdminUtils'
import { getOrgLabels } from '@/Util/OrganizationLabels'

import { fetchZoneHierarchy } from './hierarchy'
import { fetchZoneDevices } from './device'

export const fetchRootZone = createAsyncThunk(
  'fetchRootZone',
  async (params, { dispatch }) => {
    try {
      return await getRootZone(params)
    } catch (error) {
      throw error
    }
  }
)
export const fetchZoneData = createAsyncThunk(
  'fetchZoneData',
  async (params, { dispatch }) => {
    try {
      return await getZonesData(params)
    } catch (error) {
      throw error
    }
  }
)

export const fetchZonesList = createAsyncThunk(
  'fetchZonesList',
  async (params, { dispatch }) => {
    try {
      return await getZonesList(params)
    } catch (error) {
      throw error
    }
  }
)

export const fetchZoneManagementData = createAsyncThunk(
  'fetchZoneManagementData',
  async (params, { dispatch }) => {
    try {
      const zonesList = await getZonesList({ parentId: params.zoneId })
      const zoneUsers = await getZoneUsers(params)
      // usually we would await fetchZoneDevices but tested it so far and it works with the dispatch
      dispatch(fetchZoneDevices({ zoneId: params.zoneId }))

      return {
        zonesList,
        zoneUsers
      }
    } catch (error) {
      throw error
    }
  }
)

export const fetchZoneTree = createAsyncThunk(
  'fetchZoneTree',
  async (params, { dispatch }) => {
    try {
      const zonesList = await getZonesList({ parentId: params.grandparentId })
      let subZonesList = []
      if (params.parentId) {
        subZonesList = await getZonesList({ parentId: params.parentId })
      }
      return {
        zones: zonesList,
        subzones: subZonesList
      }
    } catch (error) {
      throw error
    }
  }
)

export const fetchZone = createAsyncThunk(
  'fetchZone',
  async (params, { dispatch }) => {
    try {
      return await getZone(params)
    } catch (error) {
      throw error
    } finally {
    }
  }
)

export const sendCreateZone = createAsyncThunk(
  'sendCreateZone',
  async (params, { dispatch, getState }) => {
    let locationType = ''
    let zone = null
    try {
      const { managementOrganizationLabelsReducer } = getState().rootReducer

      const { allOrganizationLabels } = managementOrganizationLabelsReducer

      const orgLabels = getOrgLabels(allOrganizationLabels, [
        'site',
        'facility',
        'room',
        'zone',
        'subzone'
      ])

      zone = await createZone(params)
      locationType = getZoneSubNameV2(getZoneDepth(zone) - 1, orgLabels)
      const successStr = I18n.get('created successfully')
      dispatch(
        showBanner({
          type: 'success',
          show: true,
          message: `${locationType} "${zone?.name}" ${successStr}`
        })
      )

      dispatch(
        fetchZoneHierarchy({
          zoneId:
            locationType !== 'Site' ? zone.parentPath.split('/')[1] : zone.id
        })
      )
      return { zone }
    } catch (error) {
      const errStr = I18n.get('Error creating')
      dispatch(
        showBanner({
          type: 'error',
          show: true,
          message: `${errStr} ${locationType.toLowerCase()}: "${zone?.name}"`
        })
      )
      throw error
    }
  }
)

const removePermissionsFromOldOrgRoles = async ({ organizationId, id }) => {
  try {
    const { roles, error: rolesError } = await getRolesByOrganizationId({
      organizationId
    })

    if (rolesError) throw Error(rolesError)

    for (const role of roles) {
      const { permissions, error } = await getRolePermissions({
        roleName: role.name
      })

      if (error) throw Error(error)

      const currentZonePermissions = permissions.filter(
        ({ resourceType, resourceId }) => {
          return resourceType === 'zone' && resourceId === id
        }
      )

      for (const permission of currentZonePermissions) {
        const { error } = await removeRolePermission({
          role: { roleName: role.name },
          permission
        })

        if (error) throw Error(error)
      }
    }
    return 'ok'
  } catch (error) {
    throw error
  }
}

export const sendUpdateZone = createAsyncThunk(
  'sendUpdateZone',
  async (params, { dispatch, getState }) => {
    let locationType = ''
    try {
      const refetch = params?.refetch ?? false
      delete params['refetch']

      const { managementZoneReducer, managementOrganizationLabelsReducer } =
        getState().rootReducer
      const zone = managementZoneReducer.zone

      await updateZone(params)

      const { organizationId, id } = params.zone
      if (
        zone.organizationId !== null &&
        zone.organizationId !== organizationId
      ) {
        await removePermissionsFromOldOrgRoles({
          organizationId: zone.organizationId,
          id
        })
      }

      const { allOrganizationLabels } = managementOrganizationLabelsReducer
      const orgLabels = getOrgLabels(allOrganizationLabels, [
        'site',
        'facility',
        'room',
        'zone',
        'subzone'
      ])

      const updatedZone = await getZone({ ...params, id: params.zone.id })
      locationType = getZoneSubNameV2(getZoneDepth(updatedZone) - 1, orgLabels)
      const successStr = I18n.get('sucessfully updated!')
      const zoneName = params.zone?.name ?? params.zone?.id
      dispatch(
        showBanner({
          type: 'success',
          show: true,
          message: `${locationType} "${zoneName}" ${successStr}`
        })
      )

      if (params.isLocationMoved) {
        dispatch(
          fetchZoneHierarchy({ zoneId: params.zone.parentPath.split('/')[1] })
        )
        dispatch(fetchZoneTree({ parentId: params.zone.parentId }))
      }

      if (refetch) {
        await delay()
        const zoneParams = { id: params.zone.id }
        const result = await getZone(zoneParams)
        dispatch(fetchZone(result.zone, result.error))
      }

      return { zone: updatedZone }
    } catch (error) {
      const errStr = I18n.get('Error updating')
      const zoneName = params.zone?.name ?? params.zone?.id
      dispatch(
        showBanner({
          type: 'error',
          show: true,
          message: `${errStr} ${locationType.toLowerCase()} "${zoneName}"`
        })
      )
      throw error
    }
  }
)

export const sendUpdateZoneMaintenanceMode = createAsyncThunk(
  'sendUpdateZoneMaintenanceMode',
  async (params, { dispatch }) => {
    try {
      const res = await updateZoneMaintenanceMode(params)
      return res
    } catch (error) {
      dispatch(
        showBanner({
          type: 'error',
          show: true,
          message: I18n.get('Error updating zone maintenance mode')
        })
      )
      throw error
    }
  }
)

const managementZoneSlice = createSlice({
  name: 'managementZoneReducer',
  initialState: {
    cameras: [],
    sensors: [],
    innerZones: [],
    rootZones: [],
    rootZone: {},
    rootZoneLoading: false,
    rootZoneRequested: null,
    zonesList: [],
    zonesListLoading: false,
    zone: {},
    zoneLoading: false,
    targetZone: {},
    targetZoneLoading: false,
    zoneTree: { zones: [], subzones: [] },
    zoneTreeLoading: false,
    liveData: [],
    users: [],
    zoneManagementDataLoading: false,
    loading: false,
    error: null
  },
  reducers: {
    cleanZonesList: (state, action) => {
      state.zonesList = []
    },
    cleanZone: (state, action) => {
      state.zone = {}
    },
    setZone: (state, action) => {
      state.zone = action.payload
    },
    cleanRootZone: (state, action) => {
      state.rootZone = {}
      state.rootZoneLoading = false
      state.rootZoneRequested = null
    },
    resetZoneData: (state, action) => {
      state.zone = {}
      state.cameras = []
      state.sensors = []
      state.innerZones = []
      state.loading = false
    },
    resetZoneDataStatusMenu: (state, action) => {
      state.innerZones = []
    },
    setRootZone: (state, action) => {
      state.rootZone = action.payload
      state.rootZoneLoading = false
    },
    setZoneLiveData: (state, action) => {
      state.liveData = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(sendCreateZone.pending, (state, action) => {
        state.targetZone = {}
        state.targetZoneLoading = true
        state.error = null
      })
      .addCase(sendCreateZone.fulfilled, (state, action) => {
        state.targetZone = action.payload.zone
        state.targetZoneLoading = false
        state.zonesList = [...state.zonesList, action.payload.zone]
        state.error = null
      })
      .addCase(sendCreateZone.rejected, (state, action) => {
        state.targetZone = {}
        state.targetZoneLoading = false
        state.error = action.error
      })
      .addCase(sendUpdateZone.pending, (state, action) => {
        state.targetZone = {}
        state.targetZoneLoading = true
        state.error = null
      })
      .addCase(sendUpdateZone.fulfilled, (state, action) => {
        state.zone = action.payload.zone
        state.targetZone = action.payload.zone
        state.targetZoneLoading = false
        state.error = null
      })
      .addCase(sendUpdateZone.rejected, (state, action) => {
        state.targetZone = {}
        state.targetZoneLoading = false
        state.error = action.error
      })
      .addCase(sendUpdateZoneMaintenanceMode.pending, (state, action) => {
        state.targetZone = {}
        state.targetZoneLoading = true
        state.error = null
      })
      .addCase(sendUpdateZoneMaintenanceMode.fulfilled, (state, action) => {
        if (state.zone.id === action.payload.id) {
          state.zone = { ...state.zone, ...action.payload }
        }
        state.targetZone = action.payload
        state.targetZoneLoading = false
        state.error = null
      })
      .addCase(sendUpdateZoneMaintenanceMode.rejected, (state, action) => {
        state.targetZone = {}
        state.targetZoneLoading = false
        state.error = action.error
      })
      .addCase(fetchZone.fulfilled, (state, action) => {
        state.zone = action.payload
        state.error = null
        state.zoneLoading = false
      })
      .addCase(fetchZone.rejected, (state, action) => {
        state.zone = {}
        state.error = action.error
        state.zoneLoading = false
      })
      .addCase(fetchRootZone.pending, (state, action) => {
        state.rootZone = {}
        state.rootZoneLoading = true
        state.rootZoneRequested = action.meta.arg.zoneId
      })
      .addCase(fetchRootZone.fulfilled, (state, action) => {
        state.rootZone = action.payload
        state.rootZoneLoading = false
      })
      .addCase(fetchRootZone.rejected, (state, action) => {
        state.rootZone = {}
        state.error = action.error
        state.rootZoneLoading = false
      })
      .addCase(fetchZoneData.fulfilled, (state, action) => {
        const cameras = action.payload.sensors
          ? action.payload.sensors.filter(
              ({ thingTypeName }) => thingTypeName === 'camera'
            )
          : []
        const sensors = action.payload.sensors
          ? ArrayUtils.sortArrayByObjectKey(
              action.payload.sensors.filter(
                ({ thingTypeName }) => thingTypeName !== 'camera'
              ),
              'thingName'
            )
          : []
        const sortedZones = action.payload.zones
          ? ArrayUtils.sortArrayByObjectKey(action.payload.zones, 'id')
          : []
        state.cameras = cameras
        state.sensors = sensors
        state.innerZones = sortedZones
        state.loading = false
      })
      .addCase(fetchZoneData.rejected, (state, action) => {
        state.cameras = []
        state.sensors = []
        state.innerZones = []
        state.loading = false
        state.error = action.error
      })
      .addCase(fetchZonesList.pending, (state, action) => {
        state.zonesListLoading = true
      })
      .addCase(fetchZonesList.fulfilled, (state, action) => {
        const nextZonesList = action.payload
        const sortedZones = ArrayUtils.sortArrayByObjectKey(
          nextZonesList,
          'name'
        )
        state.zonesList = sortedZones
        state.zonesListLoading = false
        state.error = null
      })
      .addCase(fetchZonesList.rejected, (state, action) => {
        state.zonesList = []
        state.error = action.error
        state.zonesListLoading = false
      })
      .addCase(fetchZoneManagementData.pending, (state, action) => {
        state.zoneManagementDataLoading = true
      })
      .addCase(fetchZoneManagementData.fulfilled, (state, action) => {
        state.zonesList = action.payload.zonesList
        state.users = action.payload.zoneUsers
        state.error = null
        state.zoneManagementDataLoading = false
      })
      .addCase(fetchZoneManagementData.rejected, (state, action) => {
        state.zonesList = []
        state.error = action.error
        state.zoneManagementDataLoading = false
      })
      .addCase(fetchZoneTree.pending, (state, action) => {
        state.zoneTreeLoading = true
      })
      .addCase(fetchZoneTree.fulfilled, (state, action) => {
        state.zoneTreeLoading = false
        state.zoneTree = {
          zones: action.payload.zones,
          subzones: action.payload.subzones
        }
        state.error = null
      })
      .addCase(fetchZoneTree.rejected, (state, action) => {
        state.zoneTreeLoading = false
        state.zoneTree = { zones: [], subzones: [] }
        state.error = action.error
      })
  }
})

export const {
  cleanZonesList,
  cleanZone,
  cleanRootZone,
  resetZoneData,
  resetZoneDataStatusMenu,
  setRootZone,
  setZoneLiveData,
  setZone
} = managementZoneSlice.actions

export default managementZoneSlice.reducer

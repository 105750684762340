import { makeOperationsApiCall } from './utils'

/*
QUERIES
 */
export const getWastes = async params => {
  try {
    const query = /* GraphQL */ `
      query getWastes(
        $filter: GetWastesFilter
        $order: OrderEnum
        $offset: Int
        $limit: Int
      ) {
        getWastes(
          filter: $filter
          order: $order
          offset: $offset
          limit: $limit
        ) {
          wastes {
            id
            storageFacility {
              name
            }
            produce {
              name
            }
            variety {
              name
            }
            wasteDate
            totalWeight
            reason
          }
          count
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getWastes',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error || null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const getWaste = async params => {
  try {
    const query = /* GraphQL */ `
      query getWaste($wasteId: String!) {
        getWaste(wasteId: $wasteId) {
          id
          organizationId
          storageFacilityId
          produceId
          varietyId
          wasteDate
          weights {
            id
            totalKg
            gradeId
          }
          totalWeight
          reason
        }
      }
    `
    const { data, error } = await makeOperationsApiCall({
      query,
      params,
      queryKey: 'getWaste',
      resultKey: 'data'
    })

    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

/*
MUTATIONS
*/
export const createWaste = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createWaste(
        $organizationId: String!
        $storageFacilityId: String!
        $produceId: String!
        $varietyId: String!
        $wasteDate: GraphQLTimestamp!
        $weights: [WeightInput]!
        $totalWeight: Float!
        $reason: WasteReason!
      ) {
        createWaste(
          organizationId: $organizationId
          storageFacilityId: $storageFacilityId
          produceId: $produceId
          varietyId: $varietyId
          wasteDate: $wasteDate
          weights: $weights
          totalWeight: $totalWeight
          reason: $reason
        ) {
          id
          storageFacility {
            name
          }
          produce {
            name
          }
          variety {
            name
          }
          wasteDate
          totalWeight
          reason
        }
      }
    `
    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'createWaste',
      resultKey: 'data'
    })
    return { data: data || {}, error: null ?? error }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

export const updateWaste = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateWaste(
        $wasteId: String!
        $organizationId: String!
        $storageFacilityId: String!
        $produceId: String!
        $varietyId: String!
        $wasteDate: GraphQLTimestamp!
        $weights: [WeightInput]!
        $totalWeight: Float!
        $reason: WasteReason!
      ) {
        updateWaste(
          wasteId: $wasteId
          organizationId: $organizationId
          storageFacilityId: $storageFacilityId
          produceId: $produceId
          varietyId: $varietyId
          wasteDate: $wasteDate
          weights: $weights
          totalWeight: $totalWeight
          reason: $reason
        ) {
          id
          storageFacility {
            name
          }
          produce {
            name
          }
          variety {
            name
          }
          wasteDate
          totalWeight
          reason
        }
      }
    `

    const { data, error } = await makeOperationsApiCall({
      mutation,
      params,
      queryKey: 'updateWaste',
      resultKey: 'data'
    })
    return { data: data ?? {}, error: error ?? null }
  } catch (err) {
    return { data: {}, error: err.message }
  }
}

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { attachPolicyToUser } from '@/api/control/policy'
import { attachCalibrationPolicyToUser } from '@/api/management/devices'
import { getAllOrganizations } from '@/api/management/organization'
import {
  getAllUsers,
  getCurrentUser,
  getUsers,
  getUser,
  createUser,
  updateUser,
  deleteUser,
  sendUserInvite
} from '@/api/management/user'
import { getUserManagers } from '@/api/manager'

export const cleanCurrentUser = createAsyncThunk('cleanCurrentUser', () => {
  return {}
})

export const setUser = createAsyncThunk('setUser', user => {
  return { user }
})

export const fetchCurrentUser = createAsyncThunk(
  'fetchCurrentUser',
  async params => {
    const userResponse = await getCurrentUser()

    const [managersResponse] = await Promise.allSettled([
      getUserManagers(),
      attachPolicyToUser(),
      attachCalibrationPolicyToUser()
    ])

    const organizationsResponse = await getAllOrganizations()

    let currentUser = userResponse?.currentUser ?? {}
    currentUser['managers'] = managersResponse?.value?.managers ?? []
    currentUser['organizations'] = organizationsResponse?.organizations ?? []

    return {
      currentUser,
      error:
        userResponse.error ??
        managersResponse.error ??
        organizationsResponse.error ??
        null
    }
  }
)

export const fetchUsers = createAsyncThunk('fetchUsers', async params => {
  const { users, error } = await getUsers(params)
  return { users, error }
})

export const fetchAllUsers = createAsyncThunk('fetchAllUsers', async () => {
  const { users, error } = await getAllUsers()
  return { users: users?.result ?? [], error }
})

export const fetchUser = createAsyncThunk('fetchUser', async params => {
  const { user, error } = await getUser(params)
  return { user, error }
})

export const sendCreateUser = createAsyncThunk(
  'sendCreateUser',
  async params => {
    const { user, error } = await createUser(params)
    return { user, error }
  }
)

export const sendUpdateUser = createAsyncThunk(
  'sendUpdateUser',
  async params => {
    const { user, error } = await updateUser(params)
    return { user, error }
  }
)

export const sendDeleteUser = createAsyncThunk(
  'sendDeleteUser',
  async params => {
    const { error } = await deleteUser(params)
    return { user: params, error }
  }
)

export const resendUserInvite = createAsyncThunk(
  'resendUserInvite',
  async params => {
    const { user, error } = await sendUserInvite(params)
    return { user, error }
  }
)

function processCurrentUser(currentUser) {
  if (!currentUser.settings) return currentUser

  const settings = [...currentUser?.settings].reduce((acc, setting) => {
    if (!acc[setting.objectId]) {
      acc[setting.objectId] = {}
    }

    acc[setting.objectId]['type'] = setting.type
    acc[setting.objectId]['options'] = setting.settings
    return acc
  }, {})

  currentUser['settings'] = settings
  return currentUser
}

const managementUserSlice = createSlice({
  name: 'managementUserReducer',
  initialState: {
    currentUser: {},
    currentUserLoading: true,
    user: {},
    updatedUser: {},
    deletedUser: {},
    resendUser: {},
    users: [],
    allUsers: [],
    error: null,
    loading: false
  },
  extraReducers: builder => {
    builder
      .addCase(cleanCurrentUser.fulfilled, (state, action) => {
        state.error = null
        state.currentUser = {}
        state.currentUserLoading = true
      })
      .addCase(setUser.fulfilled, (state, action) => {
        state.user = action?.payload?.user ?? {}
        state.updatedUser = {}
        state.deletedUser = {}
        state.resendUser = {}
      })
      .addCase(fetchCurrentUser.pending, (state, action) => {
        state.error = null
        if (!action?.meta?.arg?.avoidReload) {
          state.currentUserLoading = true
          state.currentUser = {}
        }
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.currentUserLoading = false
        state.currentUser = processCurrentUser(
          action?.payload?.currentUser ?? {}
        )
      })
      .addCase(fetchUsers.pending, (state, action) => {
        state.error = null
        state.loading = true
        state.users = []
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.loading = false
        state.users = action?.payload?.users ?? []
      })
      .addCase(fetchAllUsers.pending, (state, action) => {
        state.error = null
        state.loading = true
        state.allUsers = []
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.loading = false
        state.allUsers = action?.payload?.users ?? []
      })
      .addCase(fetchUser.pending, (state, action) => {
        state.error = null
        state.loading = true
        state.user = {}
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.loading = false
        state.user = action?.payload?.user ?? {}
      })
      .addCase(sendCreateUser.pending, (state, action) => {
        state.error = null
        state.loading = true
      })
      .addCase(sendCreateUser.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.loading = false
        state.user = action?.payload?.user ?? {}
      })
      .addCase(sendUpdateUser.pending, (state, action) => {
        state.error = null
        state.loading = true
      })
      .addCase(sendUpdateUser.fulfilled, (state, action) => {
        let users = { ...state.users }
        if (action?.payload?.user?.userName) {
          const updatedUser = action?.payload?.user
          const userIndex = users?.result?.findIndex(({ userName }) => {
            return updatedUser.userName === userName
          })

          if (userIndex > -1) {
            users.result[userIndex] = updatedUser
          } else {
            users?.result?.push(updatedUser)
          }
        }

        state.error = action?.payload?.error ?? null
        state.loading = false
        state.users = users
        // state.user = {}
        state.updatedUser = action?.payload?.user ?? {}
      })
      .addCase(sendDeleteUser.pending, (state, action) => {
        state.error = null
        state.loading = true
      })
      .addCase(sendDeleteUser.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.loading = false
        state.deletedUser = action?.payload?.user ?? {}
      })
      .addCase(resendUserInvite.pending, (state, action) => {
        state.error = null
        state.loading = true
      })
      .addCase(resendUserInvite.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.loading = false
        state.resendUser = action?.payload?.user ?? {}
      })
  }
})

export default managementUserSlice.reducer

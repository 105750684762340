import { Button, Column, Flex, Row } from '@/primitives'

import { userIdToName, userIdEmail } from './utils'
import Strings from '../../Strings'

import { getCurrentUser, getZoneUsers } from '@/reducers/selectors'

import {
  hasEditPermissions,
  RESOURCE_TYPE_USER,
  RESOURCE_TYPE_USER_MANAGERS
} from '@/Util/PermissionUtils'

function parseManagerSettings(settings) {
  return Object.keys(settings)
    .reduce((acc, key) => {
      if (settings[key] && key !== 'pn') {
        acc += `${settingToName(key)}, `
      }
      return acc
    }, '')
    .slice(0, -2)
}

function settingToName(setting) {
  const settings = {
    email: Strings().managerSettingEmail,
    pn: Strings().managerSettingPn,
    sms: Strings().managerSettingSms,
    webPush: Strings().managerSettingWebPush,
    whatsApp: Strings().managerSettingWhatsApp
  }

  return settings[setting] ?? ''
}

const ManagerTableRow = ({
  manager,
  isLoading,
  onEditManager,
  onDeleteManager
}) => {
  const coretexUser = getCurrentUser()
  const zoneUsers = getZoneUsers()
  const { deleteButton, editButton, viewButton } = Strings()

  const showEditOptions = hasEditPermissions(
    coretexUser,
    RESOURCE_TYPE_USER,
    RESOURCE_TYPE_USER_MANAGERS
  )

  function onSelectManager(e) {
    e.preventDefault()
    onEditManager(manager)
  }

  function onSelectDeleteManager(e) {
    e.preventDefault()
    onDeleteManager(manager)
  }

  return (
    <Row>
      <Column>{userIdToName(manager.userId, zoneUsers)}</Column>
      <Column>{userIdEmail(manager.userId, zoneUsers)}</Column>
      <Column>{parseManagerSettings(manager.settings)}</Column>
      <Column>
        <Flex
          direction='row'
          alignMainAxis='flex-start'
          alignCrossAxis='center'
          axisGap={300}
        >
          {showEditOptions && (
            <Button
              variant='error'
              size='small'
              disabled={isLoading}
              onClick={onSelectDeleteManager}
            >
              {deleteButton}
            </Button>
          )}
          <Button
            variant='primary'
            size='small'
            onClick={onSelectManager}
            disabled={isLoading}
          >
            {showEditOptions ? editButton : viewButton}
          </Button>
        </Flex>
      </Column>
    </Row>
  )
}

export default ManagerTableRow

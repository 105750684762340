import { all, fork } from 'redux-saga/effects'

// Management

import {
  watchStartGreengrassCoreDeviceDefaultDeployment,
  watchSendStartCoreDeviceUpdates,
  watchFetchCoreDeviceProvisionInstaller
} from './management/greengrass'

// Operations

import {
  watchRequestAllProduces,
  watchRequestProduces,
  watchCreateProduce,
  watchRequestProduce,
  watchUpdateProduce
} from './operations/produce'

import {
  watchRequestAllVarieties,
  watchRequestVarieties,
  watchCreateVariety,
  watchUpdateVariety,
  watchRequestVariety
} from './operations/variety'

import {
  watchRequestAllGrades,
  watchRequestGrades,
  watchRequestGrade,
  watchCreateGrade,
  watchUpdateGrade
} from './operations/grade'

import {
  watchRequestAllCurrencies,
  watchRequestCurrencies,
  watchCreateCurrency,
  watchRequestCurrency,
  watchUpdateCurrency
} from './operations/currency'

import {
  watchRequestAllCountries,
  watchRequestCountries,
  watchCreateCountry,
  watchRequestCountry,
  watchUpdateCountry
} from './operations/country'

import {
  watchRequestAllRegions,
  watchRequestRegions,
  watchCreateRegion,
  watchRequestRegion,
  watchUpdateRegion
} from './operations/region'

import {
  watchRequestAllCities,
  watchRequestCities,
  watchRequestCity,
  watchCreateCity,
  watchUpdateCity
} from './operations/city'

import {
  watchRequestAllAreas,
  watchRequestAreas,
  watchRequestArea,
  watchCreateArea,
  watchUpdateArea
} from './operations/area'

import {
  watchRequestAllPackageUnits,
  watchRequestPackageUnits,
  watchCreatePackageUnit,
  watchRequestPackageUnit,
  watchUpdatePackageUnit
} from './operations/packageUnit'

import {
  watchRequestAllPaymentMethods,
  watchRequestPaymentMethods,
  watchCreatePaymentMethod,
  watchRequestPaymentMethod,
  watchUpdatePaymentMethod
} from './operations/paymentMethod'

import {
  watchRequestAllPaymentTerms,
  watchRequestPaymentTerms,
  watchCreatePaymentTerm,
  watchRequestPaymentTerm,
  watchUpdatePaymentTerm
} from './operations/paymentTerm'

import {
  watchRequestAllShippingMethods,
  watchRequestShippingMethods,
  watchCreateShippingMethod,
  watchRequestShippingMethod,
  watchUpdateShippingMethod
} from './operations/shippingMethod'

import {
  watchRequestAllStorageFacilities,
  watchRequestStorageFacilities,
  watchRequestStorageFacility,
  watchCreateStorageFacility,
  watchUpdateStorageFacility
} from './operations/storageFacility'

import {
  watchRequestAllPlans,
  watchRequestPlans,
  watchRequestPlan,
  watchCreatePlan,
  watchUpdatePlan,
  watchRequestPlanVsHarvest
} from './operations/plan'

import {
  watchRequestAllHarvests,
  watchRequestHarvests,
  watchRequestHarvest,
  watchCreateHarvest,
  watchUpdateHarvest,
  watchDeleteHarvest,
  watchRequestTotalHarvestProduce,
  watchRequestTopTenHarvestVariety,
  watchRequestGetAllVarietiesByHarvestDateAndProduce,
  watchRequestGetAllProducesByHarvestDate
} from './operations/harvest'

import {
  watchRequestDataboardHarvests,
  watchRequestDataboardCrops,
  watchRequestDataboardSiteHierarchy
} from './operations/databoard'

import {
  watchRequestSales,
  watchRequestSale,
  watchCreateSale,
  watchUpdateSale,
  watchDeleteSaleChildren,
  watchCreateSaleShipment,
  watchUpdateSaleShipment,
  watchDeleteSaleShipment,
  watchCreateSaleItem,
  watchUpdateSaleItem,
  watchDeleteSaleItem,
  watchRequestSaleItems
} from './operations/sale'

import {
  watchCreateCustomer,
  watchRequestCustomer,
  watchRequestCustomers,
  watchUpdateCustomer
} from './operations/customer'

import {
  watchRequestBranches,
  watchRequestBranch,
  watchCreateBranch,
  watchUpdateBranch
} from './operations/branch'

import {
  watchRequestWastes,
  watchRequestWaste,
  watchCreateWaste,
  watchUpdateWaste
} from './operations/waste'

import {
  watchRequestReturn,
  watchRequestReturns,
  watchCreateReturn,
  watchUpdateReturn,
  watchCreateReturnItem,
  watchUpdateReturnItem,
  watchDeleteReturnItem
} from './operations/return'

import {
  watchRequestInventory,
  watchRequestInventories
} from './operations/inventory'

import {
  watchRequestInventoryCalibrations,
  watchCreateInventoryCalibration
} from './operations/inventoryCalibration'

import {
  watchRequestInventoryTransfers,
  watchRequestInventoryTransfer,
  watchCreateInventoryTransfer,
  watchUpdateInventoryTransfer
} from './operations/inventoryTransfer'

import {
  watchRequestUnitPrices,
  watchRequestUnitPrice,
  watchCreateUnitPrice,
  watchUpdateUnitPrice,
  watchDeleteUnitPrice
} from './operations/unitPrice'

import {
  watchRequestPackageUnitInventories,
  watchRequestPackageUnitInventory
} from './operations/packageUnitInventory'

import {
  watchRequestPackageUnitInventoryCalibrations,
  watchCreatePackageUnitInventoryCalibration
} from './operations/packageUnitInventoryCalibration'

import {
  watchRequestPackageUnitInventoryTransfers,
  watchRequestPackageUnitInventoryTransfer,
  watchCreatePackageUnitInventoryTransfer,
  watchUpdatePackageUnitInventoryTransfer
} from './operations/packageUnitInventoryTransfer'

import {
  watchRequestPackageUnitPurchases,
  watchRequestPackageUnitPurchase,
  watchCreatePackageUnitPurchase,
  watchUpdatePackageUnitPurchase
} from './operations/packageUnitPurchase'

import {
  watchRequestProducts,
  watchRequestProduct,
  watchCreateProduct,
  watchUpdateProduct
} from './operations/product'

import {
  watchRequestCrops,
  watchRequestCrop,
  watchRequestAllCrops,
  watchCreateCrop,
  watchUpdateCrop
} from './operations/crop'

import {
  watchRequestSuppliers,
  watchRequestSupplier,
  watchCreateSupplier,
  watchUpdateSupplier
} from './operations/supplier'

import {
  watchRequestFertilizerInventories,
  watchRequestFertilizerInventory
} from './operations/fertilizerInventory'

import {
  watchCreateFertilizerInventoryCalibration,
  watchRequestFertilizerInventoryCalibrations
} from './operations/fertilizerInventoryCalibration'

import {
  watchRequestFertilizerInventoryTransfers,
  watchRequestFertilizerInventoryTransfer,
  watchCreateFertilizerInventoryTransfer,
  watchUpdateFertilizerInventoryTransfer
} from './operations/fertilizerInventoryTransfer'

import {
  watchRequestFertilizerPurchases,
  watchRequestFertilizerPurchase,
  watchCreateFertilizerPurchase,
  watchUpdateFertilizerPurchase
} from './operations/fertilizerPurchase'

// Timestream
import { watchSendFetchCurrentAveMinMax } from './ts/current'

import {
  watchSendFetchCurrentData,
  watchSendFetchCurrentMobileData,
  watchSendFetchAverageData,
  watchSendFetchDataset,
  watchSendFetchCurrentSimpleData
} from './ts/dashboard'

import { watchSendFetchTimeframeGraphAverages } from './ts/timeframe'

import { watchTimestreamWarmUp } from './ts/utils'

export default function* rootSaga() {
  yield all([
    fork(watchSendFetchCurrentAveMinMax),
    fork(watchSendFetchTimeframeGraphAverages),
    fork(watchTimestreamWarmUp),
    fork(watchStartGreengrassCoreDeviceDefaultDeployment),
    fork(watchSendStartCoreDeviceUpdates),
    fork(watchSendFetchCurrentData),
    fork(watchSendFetchCurrentMobileData),
    fork(watchSendFetchCurrentSimpleData),
    fork(watchSendFetchAverageData),
    fork(watchSendFetchDataset),
    fork(watchRequestAllGrades),
    fork(watchRequestGrades),
    fork(watchRequestGrade),
    fork(watchCreateGrade),
    fork(watchUpdateGrade),
    fork(watchRequestAllProduces),
    fork(watchRequestProduces),
    fork(watchCreateProduce),
    fork(watchRequestProduce),
    fork(watchUpdateProduce),
    fork(watchRequestAllVarieties),
    fork(watchRequestVarieties),
    fork(watchCreateVariety),
    fork(watchUpdateVariety),
    fork(watchRequestVariety),
    fork(watchRequestAllHarvests),
    fork(watchRequestHarvests),
    fork(watchRequestHarvest),
    fork(watchCreateHarvest),
    fork(watchUpdateHarvest),
    fork(watchDeleteHarvest),
    fork(watchRequestAllPlans),
    fork(watchRequestPlans),
    fork(watchRequestPlan),
    fork(watchCreatePlan),
    fork(watchUpdatePlan),
    fork(watchRequestAllCurrencies),
    fork(watchRequestCurrencies),
    fork(watchCreateCurrency),
    fork(watchRequestCurrency),
    fork(watchUpdateCurrency),
    fork(watchRequestAllCountries),
    fork(watchRequestCountries),
    fork(watchCreateCountry),
    fork(watchRequestCountry),
    fork(watchUpdateCountry),
    fork(watchRequestAllRegions),
    fork(watchRequestRegions),
    fork(watchCreateRegion),
    fork(watchRequestRegion),
    fork(watchUpdateRegion),
    fork(watchCreateCity),
    fork(watchRequestAllCities),
    fork(watchRequestCities),
    fork(watchRequestCity),
    fork(watchUpdateCity),
    fork(watchRequestAllAreas),
    fork(watchRequestAreas),
    fork(watchRequestArea),
    fork(watchCreateArea),
    fork(watchUpdateArea),
    fork(watchRequestAllPackageUnits),
    fork(watchRequestPackageUnits),
    fork(watchCreatePackageUnit),
    fork(watchRequestPackageUnit),
    fork(watchUpdatePackageUnit),
    fork(watchRequestAllPaymentMethods),
    fork(watchRequestPaymentMethods),
    fork(watchCreatePaymentMethod),
    fork(watchRequestPaymentMethod),
    fork(watchUpdatePaymentMethod),
    fork(watchRequestAllPaymentTerms),
    fork(watchRequestPaymentTerms),
    fork(watchCreatePaymentTerm),
    fork(watchRequestPaymentTerm),
    fork(watchUpdatePaymentTerm),
    fork(watchRequestAllShippingMethods),
    fork(watchRequestShippingMethods),
    fork(watchCreateShippingMethod),
    fork(watchRequestShippingMethod),
    fork(watchUpdateShippingMethod),
    fork(watchRequestSales),
    fork(watchRequestSale),
    fork(watchCreateSale),
    fork(watchUpdateSale),
    fork(watchDeleteSaleChildren),
    fork(watchCreateSaleShipment),
    fork(watchUpdateSaleShipment),
    fork(watchDeleteSaleShipment),
    fork(watchCreateSaleItem),
    fork(watchUpdateSaleItem),
    fork(watchDeleteSaleItem),
    fork(watchRequestAllStorageFacilities),
    fork(watchRequestStorageFacilities),
    fork(watchRequestStorageFacility),
    fork(watchCreateStorageFacility),
    fork(watchUpdateStorageFacility),
    fork(watchRequestBranches),
    fork(watchRequestBranch),
    fork(watchCreateBranch),
    fork(watchUpdateBranch),
    fork(watchCreateCustomer),
    fork(watchRequestCustomer),
    fork(watchRequestCustomers),
    fork(watchUpdateCustomer),
    fork(watchRequestWastes),
    fork(watchRequestWaste),
    fork(watchCreateWaste),
    fork(watchUpdateWaste),
    fork(watchRequestInventory),
    fork(watchRequestInventories),
    fork(watchRequestInventoryCalibrations),
    fork(watchCreateInventoryCalibration),
    fork(watchRequestInventoryTransfers),
    fork(watchRequestInventoryTransfer),
    fork(watchCreateInventoryTransfer),
    fork(watchUpdateInventoryTransfer),
    fork(watchRequestPackageUnitInventories),
    fork(watchRequestPackageUnitInventory),
    fork(watchRequestPackageUnitInventoryCalibrations),
    fork(watchCreatePackageUnitInventoryCalibration),
    fork(watchRequestPackageUnitInventoryTransfers),
    fork(watchRequestPackageUnitInventoryTransfer),
    fork(watchCreatePackageUnitInventoryTransfer),
    fork(watchUpdatePackageUnitInventoryTransfer),
    fork(watchRequestPackageUnitPurchases),
    fork(watchRequestPackageUnitPurchase),
    fork(watchCreatePackageUnitPurchase),
    fork(watchUpdatePackageUnitPurchase),
    fork(watchRequestSaleItems),
    fork(watchRequestReturns),
    fork(watchRequestReturn),
    fork(watchCreateReturn),
    fork(watchUpdateReturn),
    fork(watchCreateReturnItem),
    fork(watchUpdateReturnItem),
    fork(watchDeleteReturnItem),
    fork(watchRequestUnitPrices),
    fork(watchRequestUnitPrice),
    fork(watchCreateUnitPrice),
    fork(watchUpdateUnitPrice),
    fork(watchDeleteUnitPrice),
    fork(watchRequestProducts),
    fork(watchRequestProduct),
    fork(watchCreateProduct),
    fork(watchUpdateProduct),
    fork(watchRequestCrops),
    fork(watchRequestCrop),
    fork(watchRequestAllCrops),
    fork(watchCreateCrop),
    fork(watchUpdateCrop),
    fork(watchRequestSuppliers),
    fork(watchRequestSupplier),
    fork(watchCreateSupplier),
    fork(watchUpdateSupplier),
    fork(watchRequestFertilizerInventories),
    fork(watchRequestFertilizerInventory),
    fork(watchCreateFertilizerInventoryCalibration),
    fork(watchRequestFertilizerInventoryCalibrations),
    fork(watchRequestFertilizerInventoryTransfers),
    fork(watchRequestFertilizerInventoryTransfer),
    fork(watchCreateFertilizerInventoryTransfer),
    fork(watchUpdateFertilizerInventoryTransfer),
    fork(watchRequestFertilizerPurchases),
    fork(watchRequestFertilizerPurchase),
    fork(watchCreateFertilizerPurchase),
    fork(watchUpdateFertilizerPurchase),
    fork(watchRequestTotalHarvestProduce),
    fork(watchRequestTopTenHarvestVariety),
    fork(watchRequestPlanVsHarvest),
    fork(watchFetchCoreDeviceProvisionInstaller),
    fork(watchRequestGetAllProducesByHarvestDate),
    fork(watchRequestGetAllVarietiesByHarvestDateAndProduce),
    fork(watchRequestDataboardHarvests),
    fork(watchRequestDataboardCrops),
    fork(watchRequestDataboardSiteHierarchy)
  ])
}

import { call, put, takeLatest } from 'redux-saga/effects'

import {
  getBranches,
  getBranch,
  createBranch,
  updateBranch
} from '@/api/operations/branch'

import {
  REQUEST_BRANCHES,
  REQUEST_BRANCH,
  CREATE_BRANCH,
  UPDATE_BRANCH,
  receiveBranches,
  receiveBranch,
  branchCreated,
  branchUpdated
} from '@/actions/operations/branch'

import { displayBanner } from '../utils'

import Strings from './Strings'

const strings = Strings()

function* sendCreateBranch(action) {
  const { data, error = null } = yield call(createBranch, action.params)
  yield put(displayBanner(error, strings.branchCreated))
  yield put(branchCreated(data, error))
}

export function* watchCreateBranch() {
  yield takeLatest(CREATE_BRANCH, sendCreateBranch)
}

function* sendUpdateBranch(action) {
  const { data, error = null } = yield call(updateBranch, action.params)
  yield put(displayBanner(error, strings.branchUpdated))
  yield put(branchUpdated(data, error))
}

export function* watchUpdateBranch() {
  yield takeLatest(UPDATE_BRANCH, sendUpdateBranch)
}

function* sendRequestBranches(action) {
  const { data, error = null } = yield call(getBranches, action.params)
  const { branches = [], count = 0 } = data
  yield put(receiveBranches(branches, count, error))
}

export function* watchRequestBranches() {
  yield takeLatest(REQUEST_BRANCHES, sendRequestBranches)
}

function* sendRequestBranch(action) {
  const { data, error = null } = yield call(getBranch, action.params)
  yield put(receiveBranch(data, error))
}

export function* watchRequestBranch() {
  yield takeLatest(REQUEST_BRANCH, sendRequestBranch)
}

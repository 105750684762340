import { I18n } from 'aws-amplify'
import { useEffect, useState, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { object, string, array } from 'yup'

import {
  requestStorageFacility,
  createStorageFacility,
  updateStorageFacility
} from '@/actions/operations/storageFacility'

import { requestAllCountries } from '@/actions/operations/country'
import { requestAllRegions } from '@/actions/operations/region'
import { requestAllCities } from '@/actions/operations/city'

import {
  getOperationsStorageFacility,
  getOperationsAllCountries,
  getOperationsAllRegions,
  getOperationsAllCities,
  getOperationsLoading,
  getOperationsError
} from '@/reducers/selectors'

import { Flex, Form, Input, Label, Select, Text, Loader } from '@/primitives'

import InputError from '@/elements/InputError'

import HeaderV2 from '@/components/Operations/Shared/HeaderV2'
import OrganizationInput from '../../Shared/Organization/Input'

import { getValidationErrorMap } from '@/Util/GeneralUtils'
import { STORE_TYPES } from './utils'

import { formInitalState, formReducer } from './state'

import history from '../../../../history'

const SCHEMA = object({
  organizationId: string().required(I18n.get('An organization is required.')),
  name: string().required(I18n.get('Name is required.')),
  countryId: string().required(I18n.get('A country must be selected.')),
  regionId: string().required(I18n.get('A region must be selected.')),
  cityId: string().required(I18n.get('A city must be selected.')),
  storeTypes: array().min(1, I18n.get('At least one store type is required.'))
})

function StorageFacilityForm({ state, modulePath }) {
  const dispatch = useDispatch()
  const { itemId } = useParams()

  const storageFacility = getOperationsStorageFacility()
  const countries = getOperationsAllCountries()
  const regions = getOperationsAllRegions()
  const cities = getOperationsAllCities()
  const loading = getOperationsLoading()
  const error = getOperationsError()

  const [formState, dispatchFormState] = useReducer(
    formReducer,
    formInitalState
  )

  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)
  const [fetchCalled, setFetchCalled] = useState(false)

  useEffect(() => {
    dispatch(requestAllCountries())
    dispatch(requestAllRegions())
    dispatch(requestAllCities())
  }, [dispatch])

  useEffect(() => {
    if (itemId && storageFacility.id !== itemId && !fetchCalled) {
      dispatch(requestStorageFacility({ storageFacilityId: itemId }))
      setFetchCalled(true)
    }
  }, [dispatch, storageFacility?.id, itemId, fetchCalled])

  useEffect(() => {
    if (storageFacility?.id && storageFacility.id === itemId) {
      dispatchFormState({ type: 'set-state', state: storageFacility })
    }
  }, [storageFacility, itemId])

  useEffect(() => {
    if (!itemId && state.organizations.length === 1) {
      dispatchFormState({
        type: 'update',
        name: 'organizationId',
        value: state.organizations[0]
      })
    }
  }, [state.organizations, itemId])

  useEffect(() => {
    if (waiting && !loading && !error) {
      history.replace(modulePath)
    }
  }, [waiting, loading, error, modulePath])

  const onSubmit = async e => {
    e?.preventDefault()
    try {
      await SCHEMA.validate(formState, { abortEarly: false })
      setWaiting(true)

      if (itemId) {
        formState.storageFacilityId = itemId
        dispatch(updateStorageFacility(formState))
      } else {
        dispatch(createStorageFacility(formState))
      }
    } catch (err) {
      setErrors(getValidationErrorMap(err))
    }
  }

  const onChange = e => {
    const { name, value } = e.currentTarget
    dispatchFormState({ type: 'update', name, value })
  }

  const onChangeStoreTypes = e => {
    const { name } = e.currentTarget
    const newValues = [...e.currentTarget.options]
      .filter(o => o.selected)
      .map(o => o.value)
    dispatchFormState({ type: 'update', name, value: newValues })
  }

  const onChangeCountry = e => {
    dispatchFormState({
      type: 'set-state',
      state: {
        ...formState,
        countryId: e.currentTarget.value,
        regionId: '',
        cityId: ''
      }
    })
  }

  const onChangeRegion = e => {
    dispatchFormState({
      type: 'set-state',
      state: {
        ...formState,
        regionId: e.currentTarget.value,
        cityId: ''
      }
    })
  }

  const handleOrganizationInput = organizationId => {
    dispatchFormState({
      type: 'set-state',
      state: {
        ...formState,
        organizationId,
        countryId: '',
        regionId: '',
        cityId: ''
      }
    })
  }

  const filterCountries = () => {
    return countries.filter(c => c.organizationId === formState.organizationId)
  }

  const filterRegions = () => {
    return regions.filter(r => r.countryId === formState.countryId)
  }

  const filterCities = () => {
    return cities.filter(c => c.regionId === formState.regionId)
  }

  return (
    <Form className='Operations__Form'>
      <HeaderV2
        title={I18n.get('Manage Storage Facility')}
        backPath={modulePath}
        buttonText={I18n.get('Save Storage Facility')}
        buttonCallback={onSubmit}
        buttonIcon={'save'}
      />
      <Loader isLoading={loading}>
        <OrganizationInput
          fieldName={'organizationId'}
          organizationId={formState.organizationId}
          handleInput={handleOrganizationInput}
          errors={errors}
        />
        <Flex axisGap={300} className='Operations__Form__Fields'>
          <Label>
            <Text variant='page' tone={700}>
              {I18n.get('Name')}
            </Text>
            <Input value={formState.name} name='name' onChange={onChange} />
            <InputError error={errors?.name} />
          </Label>
        </Flex>
        <Flex axisGap={300} className='Operations__Form__Fields'>
          <Label>
            <Text variant='page' tone={700}>
              {I18n.get('Country')}
            </Text>
            <Select
              className='Operations__Form__Select'
              name='countryId'
              value={formState.countryId}
              onChange={onChangeCountry}
            >
              <option default value=''>
                {I18n.get('Please select')}
              </option>
              {filterCountries().map(({ name, id }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Select>
            <InputError error={errors?.countryId} />
          </Label>
          <Label>
            <Text variant='page' tone={700}>
              {I18n.get('Region')}
            </Text>
            <Select
              className='Operations__Form__Select'
              name='regionId'
              value={formState.regionId}
              onChange={onChangeRegion}
            >
              <option default value=''>
                {I18n.get('Please select')}
              </option>
              {filterRegions().map(({ name, id }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Select>
            <InputError error={errors?.regionId} />
          </Label>
          <Label>
            <Text variant='page' tone={700}>
              {I18n.get('City')}
            </Text>
            <Select
              className='Operations__Form__Select'
              name='cityId'
              value={formState.cityId}
              onChange={onChange}
            >
              <option default value=''>
                {I18n.get('Please select')}
              </option>
              {filterCities().map(({ name, id }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Select>
            <InputError error={errors?.cityId} />
          </Label>
        </Flex>
        <Flex axisGap={300} className='Operations__Form__Fields'>
          <Label>
            <Text variant='page' tone={700}>
              {I18n.get('Store Types')}
            </Text>
            <Select
              className='Operations__Form__Select'
              name='storeTypes'
              value={formState.storeTypes}
              onChange={onChangeStoreTypes}
              multiple
            >
              {STORE_TYPES.map(([id, name]) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Select>
            <InputError error={errors?.storeTypes} />
          </Label>
        </Flex>
      </Loader>
    </Form>
  )
}

export default StorageFacilityForm

import {
  AVAILABLE_FEATURE_FLAGS,
  ENABLE_RECOMMENDATIONS_MANAGEMENT,
  hasFeatureFlagEnabled
} from '@/Util/FeatureFlagsUtils'

export const admin = ({ permissions, strings, currentUser }) => {
  const enableRecommendationsManagement = hasFeatureFlagEnabled(
    currentUser,
    AVAILABLE_FEATURE_FLAGS[ENABLE_RECOMMENDATIONS_MANAGEMENT],
    null
  )

  const routes = {
    text: strings.admin,
    icon: 'admin panel settings',
    routes: [
      {
        to: '/admin/user-management',
        text: strings.user_management,
        icon: 'group',
        visible: permissions.user_management
      },
      {
        to: '/admin/user-roles',
        text: strings.user_roles,
        icon: 'badge',
        visible: permissions.user_roles
      },
      {
        to: '/admin/zones',
        text: strings.sites,
        icon: 'explore',
        visible: permissions.zone
      },
      {
        to: '/admin/data-api',
        text: strings.data_api,
        icon: 'key',
        visible: permissions.data_api || permissions.data_api_management
      },
      {
        to: '/admin/organizations',
        text: strings.organizations,
        icon: 'domain',
        visible: permissions.organization
      },
      {
        to: '/admin/device-management/measurements',
        text: strings.measurements,
        icon: 'analytics',
        visible:
          permissions.sensor &&
          permissions.sensor_management &&
          permissions.godMode
      },
      {
        text: strings.recommendations,
        icon: 'psychology',
        visible: permissions.godMode || enableRecommendationsManagement,
        routes: [
          {
            to: '/operations/admin/produce-threshold',
            text: strings.produceThresholds,
            icon: 'compost'
          },
          {
            to: '/operations/admin/produce-threshold-message',
            text: strings.produceThresholdMessages,
            icon: 'reviews'
          }
        ]
      },
      {
        to: '/admin/device-management/device-types',
        text: strings.device_types,
        icon: 'dvr',
        visible:
          permissions.sensor &&
          permissions.sensor_management &&
          permissions.godMode
      },
      {
        to: '/admin/device-management/flash-scripts',
        text: strings.flash_scripts,
        icon: 'terminal',
        visible:
          permissions.sensor &&
          permissions.sensor_management &&
          permissions.godMode
      },
      {
        to: '/admin/audit',
        text: strings.audit,
        icon: 'history',
        visible:
          permissions.user || permissions.user_logs || permissions.godMode
      },
      {
        to: '/admin/suppliers',
        text: strings.admin_suppliers,
        icon: 'forklift',
        visible: permissions.godMode
      },
      {
        to: '/admin/fyi',
        text: strings.fyi,
        icon: 'info',
        visible: permissions.godMode
      }
    ]
  }

  return routes
}

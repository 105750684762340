import { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Flex, Text } from '@/primitives'
import { LineSeparator } from '@/elements'

import ManagersHeader from '../Managers/Header'
import ManagersTable from './Table'
import ManagerUpsertModal from '../Managers/UpsertModal'
import ManagerDeleteModal from './DeleteModal'
import SearchBar from './SearchBar'

import { setManager } from '@/slices/manager'
import { showBanner } from '@/slices/util'
import {
  getManagers,
  getManagerError,
  getManagerLoading
} from '@/reducers/selectors'

import Strings from '../../Strings'

import './index.scss'

const Container = ({ selectedZone }) => {
  const dispatch = useDispatch()
  const managers = getManagers()
  const managerError = getManagerError()
  const isLoading = getManagerLoading()

  const {
    tableNoManagers,
    managerDeletedSuccessfully,
    managerCreatedSuccessfully,
    managerUpdatedSuccessfully
  } = Strings()

  const [searchText, setSearchText] = useState('')
  const [showUpsertModal, setShowUpsertModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [isAddModal, setIsAddModal] = useState(true)

  useEffect(() => {
    if (managerError) {
      dispatch(
        showBanner({
          show: true,
          message: managerError,
          type: 'error'
        })
      )
    }
  }, [dispatch, managerError])

  function closeUpsertModal() {
    setShowUpsertModal(false)
    dispatch(setManager({}))
  }

  function onChangeSearchText(e) {
    setSearchText(e.target.value)
  }

  function onCreateManager(e) {
    e.preventDefault()
    dispatch(setManager({}))
    setIsAddModal(true)
    setShowUpsertModal(true)
  }

  function onDeleteManager(manager) {
    dispatch(setManager(manager))
    setShowDeleteModal(true)
  }

  function onEditManager(manager) {
    dispatch(setManager(manager))
    setIsAddModal(false)
    setShowUpsertModal(true)
  }

  function onManagerDeleted() {
    dispatch(
      showBanner({
        show: true,
        message: managerDeletedSuccessfully,
        type: 'success'
      })
    )
    setShowDeleteModal(false)
    dispatch(setManager({}))
  }

  function onCancelDeleteManager() {
    if (managerError) {
      dispatch(
        showBanner({
          show: false,
          message: '',
          type: ''
        })
      )
    }
    setShowDeleteModal(false)
    dispatch(setManager({}))
  }

  const onUpsertSuccess = () => {
    dispatch(
      showBanner({
        show: true,
        message: isAddModal
          ? managerCreatedSuccessfully
          : managerUpdatedSuccessfully,
        type: 'success'
      })
    )
    closeUpsertModal()
  }

  return (
    <Fragment>
      <ManagersHeader
        loading={isLoading}
        selectedZone={selectedZone}
        onCreateManager={onCreateManager}
      />
      <LineSeparator />
      {managers.length === 0 ? (
        <Flex alignMainAxis='center' style={{ margin: '0.5em 0' }}>
          <Text as='p'>{`${tableNoManagers}`}</Text>
        </Flex>
      ) : (
        <Fragment>
          <SearchBar
            isLoading={isLoading}
            searchText={searchText}
            onChangeSearchText={onChangeSearchText}
          />
          <ManagersTable
            managers={managers}
            isLoading={isLoading}
            searchText={searchText}
            onEditManager={onEditManager}
            onDeleteManager={onDeleteManager}
          />
        </Fragment>
      )}

      {showUpsertModal && (
        <ManagerUpsertModal
          showModal={showUpsertModal}
          zone={selectedZone}
          closeModal={closeUpsertModal}
          isAdd={isAddModal}
          onUpsertSuccess={onUpsertSuccess}
        />
      )}

      {showDeleteModal && (
        <ManagerDeleteModal
          showModal={showDeleteModal}
          onManagerDeleted={onManagerDeleted}
          onCancelDeleteManager={onCancelDeleteManager}
        />
      )}
    </Fragment>
  )
}

export default Container

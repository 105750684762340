import { useEffect, useState, Fragment, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import OrganizationsListitem from './OrganizationsListItem'

import { Cell, Column, Row, Slot, Table, Loader, Box } from '@/primitives'

import { Pagination, SearchBarWithDebounce } from '@/elements'

import { showBanner } from '@/slices/util'

import {
  getOrganizations,
  getUpdatedOrganization,
  getOrganizationsLoading
} from '@/reducers/selectors'

import {
  cleanUpdatedOrganization,
  fetchOrganizations
} from '@/slices/management/organization'

import Strings from '../Strings'

const OrganizationsList = () => {
  const [totalPages, setTotalPages] = useState(1)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')

  const dispatch = useDispatch()
  const location = useLocation()

  const organizations = getOrganizations()
  const updatedOrganization = getUpdatedOrganization()
  const isLoading = getOrganizationsLoading()

  const { result, totalItems } = organizations

  const strings = Strings()
  const {
    orgNameColumn,
    noOrgsFound,
    orgIdColumn,
    orgSearch,
    loadingOrganizations
  } = strings

  const onUpdateSearchText = useCallback(text => {
    setSearchText(text)
    setPage(1)
  }, [])

  useEffect(() => {
    if (location?.state?.action === 'create' && updatedOrganization?.id) {
      const successDetails = {
        show: true,
        message: strings.orgCreated,
        type: 'success'
      }
      dispatch(showBanner(successDetails))
      dispatch(cleanUpdatedOrganization())
    }

    if (location?.state?.action === 'update' && updatedOrganization?.id) {
      const successDetails = {
        show: true,
        message: strings.orgUpdated,
        type: 'success'
      }
      dispatch(showBanner(successDetails))
      dispatch(cleanUpdatedOrganization())
    }
  }, [strings, location?.state?.action, dispatch, updatedOrganization])

  useEffect(() => {
    dispatch(fetchOrganizations({ page, pageSize, search: searchText }))
  }, [dispatch, page, pageSize, searchText])

  return (
    <Fragment>
      <SearchBarWithDebounce
        isLoading={isLoading}
        onUpdateSearchText={onUpdateSearchText}
        placeholderText={orgSearch}
      />
      <Loader isLoading={isLoading} text={loadingOrganizations}>
        <Table style={{ marginTop: '1rem' }}>
          <Slot name='head'>
            <Row>
              <Column>{orgIdColumn}</Column>
              <Column>{orgNameColumn}</Column>
              <Column>{strings.tableActionsColumn}</Column>
            </Row>
          </Slot>

          <Slot name='body'>
            {!isLoading && result?.length === 0 && (
              <Row>
                <Cell colSpan='6'> {noOrgsFound}</Cell>
              </Row>
            )}
            {result?.map(({ name, id }) => (
              <OrganizationsListitem name={name} id={id} key={id} />
            ))}
          </Slot>
        </Table>
        {totalItems > pageSize && (
          <Box className='pagination-menu'>
            <Pagination
              type='simple'
              totalItems={totalItems}
              totalPages={totalPages}
              setTotalPages={setTotalPages}
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          </Box>
        )}
      </Loader>
    </Fragment>
  )
}

export default OrganizationsList

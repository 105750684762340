import { useState, useEffect } from 'react'

import Card from './Card'

import { byStatusLevel, caseInsensitiveIncludes } from '../../utils'

/**
 * Renders a list of alerts in the StatusPage component.
 *
 * @param {Object} props - The props to pass to the component.
 * @param {Array.<Object>} props.data - The data of the alerts to display.
 * @param {string} props.searchValue - The search value to filter the alerts.
 * @param {boolean} props.expandAll - Whether all alerts should be expanded or not.
 * @returns {React.ReactElement} A React component that displays a list of alerts in the StatusPage component.
 */
export default function AlertsList({ data, searchValue, expandAll }) {
  const [show, setShow] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 250)
  }, [])

  return (
    <div className={'StatusPage__Content__Alerts'} data-show={show}>
      {data
        .sort(byStatusLevel)
        .filter(device => caseInsensitiveIncludes(device.name, searchValue))
        .map(child => (
          <Card {...child} expandAll={expandAll} key={child.id} />
        ))}
    </div>
  )
}

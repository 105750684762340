import { Text, FlexV2, Button } from '@/primitives'
import { useParams } from 'react-router-dom'

import { getNavigationLocationSelector, getRootZone, getZone, getZonesHierarchy } from '@/reducers/selectors'

import { useDataboard } from './context'
import { DATABOARD_ACTIONS } from './state'
import Strings from './Strings'
import {
  buildZoneFilterFromHierarchy,
  createZoneFilterObject
} from './Utils/FiltersUtils'

const DataboardHeader = () => {
  const strings = Strings()
  const { dispatchState } = useDataboard()
  const locationSelector = getNavigationLocationSelector()
  
  const params = useParams()

  const zonesHierarchy = getZonesHierarchy()
  const rootZone = getRootZone()
  const zone = getZone()

  const { name: orgName } = locationSelector?.get('organization') ?? {}

  const onReset = () => {
    let zoneId = zone?.id ?? rootZone.id
    const zoneFilter = buildZoneFilterFromHierarchy(
      zonesHierarchy[rootZone.id],
      zoneId
    )
    dispatchState({
      type: DATABOARD_ACTIONS.RESET_FILTERS,
      zoneFilter: createZoneFilterObject({
        ...zoneFilter,
        sensorId: params.sensorId ?? null
      })
    })
  }

  return (
    <FlexV2 className='Databoard__Header' axisGap={500}>
      <FlexV2 direction='column' axisGap={300}>
        <Text size={300} fontWeight={600} fontFamily='sans'>
          {orgName}
          {' - '}
          {strings.databoardTitle}
        </Text>
        <Text variant='page' tone={900} fontWeight={400}>
          {strings.databoardSubtitle}
        </Text>
      </FlexV2>

      <Button variant='error' size='small' onClick={onReset}>
        {strings.resetFields}
      </Button>
    </FlexV2>
  )
}

export default DataboardHeader

import { Collapse, FlexV2, Slot, Symbol, Text } from '@/primitives'

import { useDataboard } from '../context'
import { COMPARISON_TYPES } from '../Utils/config'
import FiltersContainer from '../Filters/FilterContainer'

import { Strings } from './utils'

export default function CollapseFilter({ isAboveBreakpoint = true }) {
  const strings = Strings()
  const { state } = useDataboard()

  const isLocationRange =
    state.comparisonType === COMPARISON_TYPES.LOCATION_RANGES

  const timeZone = isLocationRange
    ? state.sidebarFilter[COMPARISON_TYPES.DATE_RANGES].timeZone
    : state.dateFilters[0].timeZone

  return (
    <Collapse defaultOpen={isAboveBreakpoint}>
      <Slot name='trigger'>
        <FlexV2 direction='column' axisGap={100}>
          <Text variant={'page'} tone={900} fontWeight={500}>
            {isLocationRange && strings.dateRangeFilters}
            {!isLocationRange && strings.locationFilters}
          </Text>
          <Text ariant={'page'} tone={900} size={100} fontWeight={300}>
            ({timeZone})
          </Text>
        </FlexV2>
      </Slot>
      <Slot name='content'>
        <FiltersContainer isSidebar={true} />
        <FlexV2
          axisGap={500}
          alignCrossAxis='center'
          style={{ paddingTop: '0.5em' }}
        >
          <Symbol
            name='info'
            variant='page'
            tone={900}
            style={{ overflow: 'unset' }}
          />
          <Text variant='page' tone={900}>
            {strings.timeZoneFetchInfo}
          </Text>
        </FlexV2>
      </Slot>
    </Collapse>
  )
}

import axios from 'axios'

import { VERIFY_URL } from './config'

export const getDeviceIsVerified = async deviceId => {
  try {
    const response = await axios({
      url: VERIFY_URL,
      method: 'post',
      data: { deviceId }
    })

    return { data: response.data, error: null }
  } catch (err) {
    if (err.response && err.response.data) {
      return { error: err.response.data.errMessage, url: null }
    }
    return { error: err.message, url: null }
  }
}

import { DateTime } from 'luxon'
import Strings from '../Strings'
import { getZoneLevelName } from '../../utils'
import { Flex, Icon, Text, FlexV2 } from '@/primitives'
import { getSensorTypeIcon } from '@/Util/DeviceUtils'

export const getMeasurementUnit = (measurements, id) => {
  const selectedItem = measurements.find(measurement => measurement.id === id)
  return selectedItem?.unit
}

export const selectedMeasurements = [
  'conductivity',
  'ph',
  'water_temperature',
  'dissolved_oxygen',
  'temperature',
  'humidity',
  'carbon_dioxide',
  'pressure',
  'uv_index',
  'par'
]

export const getRanges = () => {
  const strings = Strings()

  return {
    today: {
      name: strings['today'],
      interval: '1h',
      intervalId: 'hour'
    },
    twentyFourHours: {
      name: strings['24hours'],
      interval: '1h',
      intervalId: 'hour'
    },
    sevenDays: {
      name: strings['7days'],
      interval: '6h',
      intervalId: 'quarterDay'
    },
    thirtyDays: {
      name: strings['30days'],
      interval: '2d',
      intervalId: 'alternateDays'
    }
  }
}

export const graphTimeframeOptions = () => {
  const ranges = getRanges()
  return Object.keys(ranges).map(rangeId => {
    const { name } = ranges[rangeId]
    return {
      value: rangeId,
      label: name
    }
  })
}

export const getIntervalValueFromRange = rangeId => {
  return getRanges()[rangeId]?.interval
}

export const getIntervalIdFromRange = rangeId => {
  return getRanges()[rangeId]?.intervalId
}

export const getDatesFromRange = (key, timeZone) => {
  const now = DateTime.utc().setZone(timeZone).startOf('minute')
  const today = now.startOf('day')
  const options = {
    today: [today, now],
    twentyFourHours: [now.minus({ hours: 23 }), now],
    sevenDays: [today.minus({ days: 6 }), today],
    thirtyDays: [today.minus({ days: 29 }), today]
  }
  return options[key]
}

export const splitDataset = (mobileDataset, measurements) => {
  if (mobileDataset) {
    return measurements.map(measurement => {
      return mobileDataset.reduce((result, data) => {
        if (data.measurement === measurement) {
          return result.concat(data)
        }
        return result
      }, [])
    })
  }
  return []
}

export const getLiveDataOptions = (currentZone, params, device) => {
  const strings = Strings()

  const { currentZoneLevelName, childZoneLevelName } =
    getZoneLevelName(currentZone)

  const currentZoneOption = {
    value: currentZone.id,
    label: currentZone.name
  }

  let groupedOptions = [
    {
      label: `${strings.selectedZoneText} ${currentZoneLevelName}`,
      options: [currentZoneOption]
    }
  ]

  if (Object.keys(currentZone?.children).length > 0) {
    let zoneOptions = []
    Object.keys(currentZone?.children).forEach(id => {
      const zone = currentZone?.children[id]
      const { name, status } = zone
      if (status === 'active') {
        zoneOptions.push({
          value: id,
          label: name
        })
      }
    })

    const childrenGroup = {
      label: childZoneLevelName,
      options: zoneOptions
    }

    groupedOptions.push(childrenGroup)
  }

  if (currentZone?.devices?.length > 0) {
    const deviceOptions = getDeviceOptions(currentZone?.devices)

    const sensorsGroup = {
      label: strings.sensorsText,
      options: deviceOptions
    }

    groupedOptions.push(sensorsGroup)
  }

  if (params?.sensorId && params?.sensorId === device?.id) {
    groupedOptions = [
      {
        label: strings.sensorsText,
        options: getDeviceOptions([device])
      }
    ]
  }

  return groupedOptions
}

export function getDeviceOptions(devices) {
  return devices.map(device => {
    const { sensorType, name, tag = null, id } = device

    return {
      value: id,
      label: (
        <Flex wrap='nowrap' direction='row' axisGap={300}>
          <Icon
            name={getSensorTypeIcon(sensorType)}
            style={{ overflow: 'visible' }}
          />
          <FlexV2
            className='SensorDetails'
            wrap='wrap'
            axisGap={200}
            alignCrossAxis='center'
          >
            <Text className='SensorDetails__Name'>{name}</Text>
            {tag && (
              <Text
                className='SensorDetails__Tag'
                tone='100'
                variant='page'
                size={100}
                fontWeight={600}
              >
                {tag}
              </Text>
            )}
          </FlexV2>
        </Flex>
      )
    }
  })
}

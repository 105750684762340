import { useEffect, useState, Fragment } from 'react'
import { useDispatch } from 'react-redux'

import IngestionFrequency from './IngestionFrequency'
import InfoTooltip from '../../../Shared/InfoTooltip'
import { Flex, Text, Button, Slot, Toggle } from '@/primitives'
import { Dialog } from '@/elements'

import { sendUpdateZoneMaintenanceMode } from '@/slices/management/zone'
import { sendUpdateSiteTrackingStatus } from '@/slices/ml/siteTracker'
import { getCurrentUser, getSiteTrackerStatus } from '@/reducers/selectors'

import Strings from '../../Strings'
import {
  getIsGodMode,
  findPermissionById,
  RESOURCE_TYPE_ZONE,
  getPermissionsList
} from '@/Util/PermissionUtils'
import { isZoneSite } from '@/Util/AdminUtils'
import ZoneUtils from '@/Util/ZoneUtils'

const SettingsModal = ({ selectedZone }) => {
  const strings = Strings()

  const dispatch = useDispatch()

  const coretexUser = getCurrentUser()
  const siteTrackerStatus = getSiteTrackerStatus()
  const isGodMode = getIsGodMode(coretexUser)

  const [showModal, setShowModal] = useState(false)
  const [isAiForecastEnabled, setIsAiForecastEnabled] = useState(false)
  const [isMaintenanceModeEnabled, setIsMaintenanceModeEnabled] =
    useState(false)

  const isSite = selectedZone?.id ? isZoneSite(selectedZone) : false

  useEffect(() => {
    setIsMaintenanceModeEnabled(selectedZone?.maintenanceMode)
  }, [selectedZone?.maintenanceMode])

  useEffect(() => {
    setIsAiForecastEnabled(siteTrackerStatus?.tracking)
  }, [siteTrackerStatus])

  const permissionsList = getPermissionsList(
    coretexUser,
    RESOURCE_TYPE_ZONE,
    'write'
  )

  const hasZoneEditPermission =
    findPermissionById(
      permissionsList,
      ZoneUtils.getPrimaryZoneFromParentPath(selectedZone?.parentPath)
    ) ||
    findPermissionById(permissionsList) ||
    isGodMode

  const onToggleModal = () => {
    setShowModal(!showModal)
  }

  function onClickAiForecastToggle() {
    setIsAiForecastEnabled(!isAiForecastEnabled)
    dispatch(
      sendUpdateSiteTrackingStatus({
        siteId: selectedZone.id,
        tracking: !isAiForecastEnabled
      })
    )
  }

  function onClickMaintenanceModaToggle() {
    const newMode = !isMaintenanceModeEnabled
    setIsMaintenanceModeEnabled(newMode)
    dispatch(
      sendUpdateZoneMaintenanceMode({
        id: selectedZone.id,
        maintenanceMode: newMode
      })
    )
  }

  const { enableAiForecast, enableMaintenanceMode, editSettings } = strings
  const showButton = hasZoneEditPermission || (isSite && isGodMode)

  return (
    <Fragment>
      {showButton && (
        <Button size='small' variant='primary' onClick={onToggleModal}>
          {editSettings}
        </Button>
      )}
      {showModal && (
        <Dialog
          open={showModal}
          onOpenChange={setShowModal}
          type='offcanvas'
          className='ZoneDetails__OffCanvas'
          style={{ zIndex: 3 }}
        >
          <Slot name='title'>
            <Text size={300} fontWeight={700}>
              {editSettings}
            </Text>
          </Slot>
          <Slot name='content'>
            <Flex
              direction='column'
              axisGap={500}
              alignMainAxis='flex-start'
              style={{ marginTop: '2em' }}
            >
              {hasZoneEditPermission && (
                <Flex
                  direction='column'
                  alignMainAxis='flex-start'
                  axisGap={300}
                >
                  <Text as='p' size={300} fontWeight='700'>
                    {enableMaintenanceMode}
                  </Text>
                  <Toggle
                    checked={isMaintenanceModeEnabled}
                    disabled={!hasZoneEditPermission}
                    indeterminate={false}
                    onChange={onClickMaintenanceModaToggle}
                  />
                </Flex>
              )}
              {isSite && isGodMode && (
                <Flex
                  direction='column'
                  alignMainAxis='flex-start'
                  axisGap={300}
                >
                  <InfoTooltip tipId='lno7vf7d4d'>
                    {/* This tooltip has been added as an example only */}
                    <Text as='p' size={300} fontWeight='700'>
                      {enableAiForecast}
                    </Text>
                  </InfoTooltip>
                  <Toggle
                    checked={isAiForecastEnabled}
                    disabled={!isSite || !isGodMode}
                    indeterminate={false}
                    onChange={onClickAiForecastToggle}
                  />
                </Flex>
              )}
            </Flex>
            {isSite && isGodMode && <IngestionFrequency />}
          </Slot>
        </Dialog>
      )}
    </Fragment>
  )
}

export default SettingsModal

import { Box, Text } from '@/primitives'

import { useDataboard } from '../../context'
import Strings from '../../Strings'

export default function NoDataCard() {
  const { state } = useDataboard()
  const strings = Strings()
  return (
    <Box className='Historic__Chart__Card' style={{ marginTop: '2em' }}>
      <Text as='h3' fontWeight={700}>
        {state?.measurements[state.comparisonType]?.length > 0 &&
          state?.dataset?.length === 0 &&
          strings.noData}
      </Text>
    </Box>
  )
}

import { I18n } from 'aws-amplify'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Select } from '@/primitives'

import { requestAllStorageFacilities } from '@/actions/operations/storageFacility'

import { getOperationsAllStorageFacilities } from '@/reducers/selectors'

const StorageFacilitiesFilter = ({
  state,
  dispatchState,
  stateKey = 'storageFacilities',
  defaultLabel = 'All Storage Facilities'
}) => {
  const dispatch = useDispatch()

  const storageFacilities = getOperationsAllStorageFacilities()

  const [storageFacilityFilter, setStorageFacilityFilter] = useState(
    state[stateKey].length === 1 ? state[stateKey][0] : 'all'
  )
  const [lastFilter, setLastFilter] = useState(storageFacilityFilter)

  const getStorageFacilityFilter = useCallback(() => {
    return storageFacilityFilter === 'all'
      ? storageFacilities.map(i => i.id)
      : [storageFacilityFilter]
  }, [storageFacilityFilter, storageFacilities])

  const dispatchChange = useCallback(() => {
    dispatchState({
      [stateKey]: getStorageFacilityFilter()
    })
  }, [dispatchState, stateKey, getStorageFacilityFilter])

  useEffect(() => {
    dispatch(requestAllStorageFacilities())
  }, [dispatch])

  useEffect(() => {
    if (
      storageFacilityFilter === 'all' &&
      state[stateKey].length === 0 &&
      storageFacilities.length > 0
    ) {
      dispatchChange()
    }
  }, [
    state,
    stateKey,
    storageFacilities,
    storageFacilityFilter,
    dispatchChange
  ])

  useEffect(() => {
    if (storageFacilities.length > 0) {
      if (storageFacilityFilter !== lastFilter) {
        setLastFilter(storageFacilityFilter)
        dispatchChange()
      }
    }
  }, [storageFacilityFilter, storageFacilities, lastFilter, dispatchChange])

  const onChangeFilter = e => {
    setStorageFacilityFilter(e.currentTarget.value)
  }

  const filterStorageFacilities = () => {
    if (state.organizations.length === 1) {
      return storageFacilities.filter(facility => {
        for (let organization of state.organizations) {
          if (facility.organizationId === organization) {
            return true
          }
        }
        return false
      })
    }
    return []
  }

  const options = filterStorageFacilities()

  return (
    <Select
      className='Operations__Header__Select'
      name={stateKey}
      value={storageFacilityFilter}
      onChange={onChangeFilter}
      disabled={options.length === 0}
    >
      <option default value='all'>
        {I18n.get(defaultLabel)}
      </option>
      {options.map(({ name, id }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </Select>
  )
}

export default StorageFacilitiesFilter

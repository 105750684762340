import {
  createElement,
  useCallback,
  useMemo,
  useEffect,
  Fragment,
  useState
} from 'react'

import { useTheme } from '@nivo/core'
import { useTooltip } from '@nivo/tooltip'

const getLatestYOffset = (locationBarPositions, gradeName) => {
  const arr = Object.keys(locationBarPositions)
    .reduce((acc, produce) => {
      const barPos = { ...locationBarPositions[produce] }
      if (barPos) {
        if (!barPos[gradeName] || barPos[gradeName] === 0) {
          return acc
        }
        return [...acc, barPos[gradeName]]
      }

      return acc
    }, [])
    .sort()

  return arr[0]
}

const HarvestCustomBar = props => {
  const { grades, barPositions, setMaxValue, maxValue, emptyBars, unitRatio } =
    props

  const {
    bar: { data, ...bar },

    style: { borderColor, labelColor },

    borderRadius,
    borderWidth,
    shouldRenderLabel,
    isInteractive,
    onMouseEnter,
    onMouseLeave,
    tooltip,
    isFocusable,
    ariaLabel,
    ariaLabelledBy,
    ariaDescribedBy
  } = props.data
  const currentGrades = grades[data.index][data.id]
  const { indexValue } = data
  const locationBarPositions = barPositions[indexValue] ?? {}
  const totalWeightValue = data.value
  const ratioOfValueToHeight =
    bar.height > 0 ? totalWeightValue / bar.height : null

  const [isInitial, setIsInitial] = useState(true)

  const theme = useTheme()
  const { showTooltipFromEvent, hideTooltip } = useTooltip()

  const renderTooltip = useMemo(
    e => e =>
      createElement(tooltip, {
        ...bar,
        ...data,
        grade: e?.target?.id,
        grades
      }),
    [tooltip, bar, data]
  )

  const handleTooltip = useCallback(event => {
    return (
      showTooltipFromEvent(renderTooltip(event), event),
      [showTooltipFromEvent, renderTooltip]
    )
  })

  const handleMouseEnter = useCallback(
    event => {
      onMouseEnter?.(data, event)
      showTooltipFromEvent(renderTooltip(), event)
    },
    [data, onMouseEnter, showTooltipFromEvent, renderTooltip]
  )

  const handleMouseLeave = useCallback(
    event => {
      onMouseLeave?.(data, event)
      hideTooltip()
    },
    [data, hideTooltip, onMouseLeave]
  )

  const updateBarPositions = (
    latestPos,
    barHeight,
    gradeName,
    ratioOfValueToHeight
  ) => {
    if (!barPositions.hasOwnProperty(indexValue)) {
      barPositions[indexValue] = {}
    }

    if (!barPositions[indexValue].hasOwnProperty(data.id)) {
      barPositions[indexValue][data.id] = {}
    }

    if (latestPos > 0) {
      barPositions[indexValue][data.id][gradeName] = latestPos - barHeight
    } else {
      if (bar.index === 0) barPositions['yBase'] = bar.y + bar.height
      const newValue = barPositions['yBase'] - barHeight
      barPositions[indexValue][data.id][gradeName] = newValue
    }

    if (!barPositions.ratio) barPositions['ratio'] = ratioOfValueToHeight
  }

  const currentFilteredGrades = Object.keys(currentGrades).filter(
    grade => !emptyBars[data.index].includes(grade)
  )

  //TODO this needs to be changed to it's own component due to this warning:
  //~/coretex.webapp/src/components/Operations/Shared/Databoard/HarvestChart/HarvestCustomBar.jsx:139:5
  //139:5 warning React Hook "useEffect" cannot be called inside a callback.
  //React Hooks must be called in a React function component or a custom React Hook function. react-hooks/rules-of-hooks
  const bars = currentFilteredGrades.map((gradeName, index) => {
    const gradeValue = currentGrades[gradeName]
    const barWidth = bar.width / Object.keys(currentGrades).length
    const locationXOffset = (emptyBars[data.index].length * barWidth) / 2
    const barHeight = (bar.height / totalWeightValue) * gradeValue
    const latestPos = getLatestYOffset(locationBarPositions, gradeName)

    if (isInitial) {
      updateBarPositions(latestPos, barHeight, gradeName, ratioOfValueToHeight)
    }

    const gradeBarPosition = barPositions[indexValue][data.id][gradeName]

    useEffect(() => {
      const offset = barPositions.yBase - gradeBarPosition
      const newMaxValue = Math.ceil(offset * barPositions.ratio)
      if (newMaxValue > maxValue) {
        let value = (barPositions.yBase - gradeBarPosition) * barPositions.ratio
        setMaxValue(Math.ceil(value))
      }
    }, [])

    return (
      <Fragment key={`${indexValue}-${data.id}-${gradeName}`}>
        <g
          transform={`translate(${
            bar.x + barWidth * index + locationXOffset
          }, ${gradeBarPosition})`}
          key={index}
        >
          <rect
            width={barWidth - 5}
            height={barHeight}
            rx={borderRadius}
            ry={borderRadius}
            id={gradeName}
            key={gradeName}
            fill={data.fill ?? bar.color}
            strokeWidth={borderWidth}
            stroke={borderColor}
            focusable={isFocusable}
            tabIndex={isFocusable ? 0 : undefined}
            aria-label={ariaLabel ? ariaLabel(data) : undefined}
            aria-labelledby={ariaLabelledBy ? ariaLabelledBy(data) : undefined}
            aria-describedby={
              ariaDescribedBy ? ariaDescribedBy(data) : undefined
            }
            onMouseEnter={isInteractive ? handleMouseEnter : undefined}
            onMouseMove={isInteractive ? handleTooltip : undefined}
            onMouseLeave={isInteractive ? handleMouseLeave : undefined}
          />
          {shouldRenderLabel && barHeight > 0 && (
            <text
              x={barWidth / 2}
              y={barHeight / 2}
              textAnchor='middle'
              dominantBaseline='central'
              style={{
                ...theme.labels.text,
                pointerEvents: 'none',
                fill: labelColor?.animation?.to ?? 'rgb(55, 116, 106)'
              }}
            >
              {parseFloat((gradeValue / unitRatio).toFixed(2))}
            </text>
          )}
          {gradeBarPosition + barHeight === barPositions.yBase && (
            <text
              x={barWidth / 2}
              y={-gradeBarPosition + barPositions.yBase + 20}
              textAnchor='middle'
              style={{
                ...theme.labels.text
              }}
            >
              {gradeName}
            </text>
          )}
        </g>
      </Fragment>
    )
  })

  if (isInitial) setIsInitial(false)

  return bars
}

export default HarvestCustomBar

import { makeActionCreator } from '@/Util/ReduxUtils'

export const REQUEST_ALL_PAYMENT_TERMS = 'REQUEST_ALL_PAYMENT_TERMS'
export const requestAllPaymentTerms = makeActionCreator(
  REQUEST_ALL_PAYMENT_TERMS
)

export const RECEIVE_ALL_PAYMENT_TERMS = 'RECEIVE_ALL_PAYMENT_TERMS'
export const receiveAllPaymentTerms = makeActionCreator(
  RECEIVE_ALL_PAYMENT_TERMS,
  'paymentTerms',
  'count',
  'error'
)

export const REQUEST_PAYMENT_TERMS = 'REQUEST_PAYMENT_TERMS'
export const requestPaymentTerms = makeActionCreator(
  REQUEST_PAYMENT_TERMS,
  'params'
)

export const RECEIVE_PAYMENT_TERMS = 'RECEIVE_PAYMENT_TERMS'
export const receivePaymentTerms = makeActionCreator(
  RECEIVE_PAYMENT_TERMS,
  'paymentTerms',
  'count',
  'error'
)

export const CREATE_PAYMENT_TERM = 'CREATE_PAYMENT_TERM'
export const createPaymentTerm = makeActionCreator(
  CREATE_PAYMENT_TERM,
  'params'
)

export const PAYMENT_TERM_CREATED = 'PAYMENT_TERM_CREATED'
export const paymentTermCreated = makeActionCreator(
  PAYMENT_TERM_CREATED,
  'paymentTerm',
  'error'
)

export const UPDATE_PAYMENT_TERM = 'UPDATE_PAYMENT_TERM'
export const updatePaymentTerm = makeActionCreator(
  UPDATE_PAYMENT_TERM,
  'params'
)

export const PAYMENT_TERM_UPDATED = 'PAYMENT_TERM_UPDATED'
export const paymentTermUpdated = makeActionCreator(
  PAYMENT_TERM_UPDATED,
  'paymentTerm',
  'error'
)

export const REQUEST_PAYMENT_TERM = 'REQUEST_PAYMENT_TERM'
export const requestPaymentTerm = makeActionCreator(
  REQUEST_PAYMENT_TERM,
  'params'
)

export const RECEIVE_PAYMENT_TERM = 'RECEIVE_PAYMENT_TERM'
export const receivePaymentTerm = makeActionCreator(
  RECEIVE_PAYMENT_TERM,
  'paymentTerm',
  'error'
)

export const CLEAR_PAYMENT_TERM = 'CLEAR_PAYMENT_TERM'
export const clearPaymentTerm = makeActionCreator(CLEAR_PAYMENT_TERM)

export const CLEAR_PAYMENT_TERMS = 'CLEAR_PAYMENT_TERMS'
export const clearPaymentTerms = makeActionCreator(CLEAR_PAYMENT_TERMS)

export const SET_PAYMENT_TERM = 'SET_PAYMENT_TERM'
export const setPaymentTerm = makeActionCreator(SET_PAYMENT_TERM, 'paymentTerm')

import { Fragment } from 'react'

import OrganizationsFilter from '../../../Shared/Filters/OrganizationsFilter'
import StorageFacilitiesFilter from '../../../Shared/Filters/StorageFacilitiesFilter'

const TableFilter = ({ state, dispatchState }) => {
  return (
    <Fragment>
      <OrganizationsFilter state={state} dispatchState={dispatchState} />
      <StorageFacilitiesFilter
        state={state}
        dispatchState={dispatchState}
        stateKey={'fromStorageFacilities'}
        defaultLabel='All Origins'
      />
      <StorageFacilitiesFilter
        state={state}
        dispatchState={dispatchState}
        stateKey={'storageFacilities'}
        defaultLabel='All Destinations'
      />
    </Fragment>
  )
}

export default TableFilter

import { call, put, takeLatest } from 'redux-saga/effects'

import {
  createFertilizerInventoryTransfer,
  getFertilizerInventoryTransfer,
  getFertilizerInventoryTransfers,
  updateFertilizerInventoryTransfer
} from '@/api/operations/fertilizerInventoryTransfer'

import {
  REQUEST_FERTILIZER_INVENTORY_TRANSFERS,
  REQUEST_FERTILIZER_INVENTORY_TRANSFER,
  CREATE_FERTILIZER_INVENTORY_TRANSFER,
  UPDATE_FERTILIZER_INVENTORY_TRANSFER,
  receiveFertilizerInventoryTransfers,
  receiveFertilizerInventoryTransfer,
  fertilizerInventoryTransferCreated,
  fertilizerInventoryTransferUpdated
} from '@/actions/operations/fertilizerInventoryTransfer'

import { displayBanner } from '../utils'

import Strings from './Strings'

const strings = Strings()

function* sendCreateFertilizerInventoryTransfer(action) {
  const { data, error = null } = yield call(
    createFertilizerInventoryTransfer,
    action.params
  )
  yield put(displayBanner(error, strings.fertilizerInventoryTransferCreated))
  yield put(fertilizerInventoryTransferCreated(data, error))
}

export function* watchCreateFertilizerInventoryTransfer() {
  yield takeLatest(
    CREATE_FERTILIZER_INVENTORY_TRANSFER,
    sendCreateFertilizerInventoryTransfer
  )
}

function* sendUpdateFertilizerInventoryTransfer(action) {
  const { data, error = null } = yield call(
    updateFertilizerInventoryTransfer,
    action.params
  )
  yield put(displayBanner(error, strings.fertilizerInventoryTransferUpdated))
  yield put(fertilizerInventoryTransferUpdated(data, error))
}

export function* watchUpdateFertilizerInventoryTransfer() {
  yield takeLatest(
    UPDATE_FERTILIZER_INVENTORY_TRANSFER,
    sendUpdateFertilizerInventoryTransfer
  )
}

function* sendRequestFertilizerInventoryTransfers(action) {
  const { data, error = null } = yield call(
    getFertilizerInventoryTransfers,
    action.params
  )
  const { fertilizerInventoryTransfers = [], count = 0 } = data
  yield put(
    receiveFertilizerInventoryTransfers(
      fertilizerInventoryTransfers,
      count,
      error
    )
  )
}

export function* watchRequestFertilizerInventoryTransfers() {
  yield takeLatest(
    REQUEST_FERTILIZER_INVENTORY_TRANSFERS,
    sendRequestFertilizerInventoryTransfers
  )
}

function* sendRequestFertilizerInventoryTransfer(action) {
  const { data: fertilizerInventoryTransfer, error = null } = yield call(
    getFertilizerInventoryTransfer,
    action.params
  )
  yield put(
    receiveFertilizerInventoryTransfer(fertilizerInventoryTransfer, error)
  )
}

export function* watchRequestFertilizerInventoryTransfer() {
  yield takeLatest(
    REQUEST_FERTILIZER_INVENTORY_TRANSFER,
    sendRequestFertilizerInventoryTransfer
  )
}

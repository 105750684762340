import { useState, useReducer, useEffect, Fragment } from 'react'
import { useDispatch } from 'react-redux'

import { Dialog, LineSeparator } from '@/elements'
import { Slot, Text, Flex, Input, Button, Box } from '@/primitives'

import {
  sendCreateReleaseNotesSection,
  sendUpdateReleaseNotesSection,
  cleanSectionCreated
} from '@/slices/fyi/releaseNotes'
import {
  getReleaseNotesSections,
  getReleaseNotesSectionCreated
} from '@/reducers/selectors'

import Strings from '../../Strings'
const strings = Strings()

const initialState = {
  sections: [],
  newSectionName: '',
  updatedSectionName: ''
}

function reducer(state, action) {
  if (action.type === 'updateField') {
    return { ...state, [action.name]: action.value }
  }
}

const Sections = ({ showForm, setHideForm }) => {
  const [showAddField, setShowAddField] = useState(false)
  const [sectionToEdit, setSectionToEdit] = useState('')
  const [state, dispatch] = useReducer(reducer, initialState)

  const reduxDispatch = useDispatch()
  const sections = getReleaseNotesSections()
  const sectionCreated = getReleaseNotesSectionCreated()

  useEffect(() => {
    if (sectionCreated?.hasOwnProperty('sectionId')) {
      reduxDispatch(cleanSectionCreated())
      if (showAddField) {
        setShowAddField(false)
        dispatch({ type: 'updateField', value: '', name: 'newSectionName' })
      }

      if (sectionToEdit?.length > 0) {
        setSectionToEdit('')
        dispatch({ type: 'updateField', name: 'updatedSectionName', value: '' })
      }
    }
  }, [sectionCreated, reduxDispatch, sectionToEdit?.length, showAddField])

  function onChangeInput({ target }) {
    const { value, name } = target
    dispatch({ type: 'updateField', value, name })
  }

  function onCreateSection() {
    if (state?.newSectionName?.length > 0) {
      reduxDispatch(
        sendCreateReleaseNotesSection({ name: state.newSectionName })
      )
    }
  }

  function onClickCancel() {
    setShowAddField(false)
    dispatch({ type: 'updateField', value: '', name: 'newSectionName' })
  }

  function onClickAdd() {
    setShowAddField(true)
  }

  function onClickCloseUpdate() {
    setSectionToEdit('')
    dispatch({ type: 'updateField', name: 'updatedSectionName', value: '' })
  }

  function onClickApplyUpdate() {
    reduxDispatch(
      sendUpdateReleaseNotesSection({
        sectionId: sectionToEdit,
        name: state.updatedSectionName
      })
    )
  }

  return (
    <Dialog
      className='ZoneDetails__OffCanvas'
      type='offcanvas'
      open={showForm}
      onOpenChange={setHideForm}
    >
      <Slot name='title'>
        <Text size={400} fontWeight={700}>
          {strings.releaseNotesSections}
        </Text>
      </Slot>
      <Slot name='content'>
        <Text size={200} as='p' variant='page'>
          {strings.manageReleaseNotesSections}
        </Text>
        <Box>
          <LineSeparator />
        </Box>
        <ul>
          {sections?.map(({ name, sectionId }) => {
            function onClickEdit() {
              setSectionToEdit(sectionId)
              dispatch({
                type: 'updateField',
                name: 'updatedSectionName',
                value: name
              })
            }

            return (
              <li key={sectionId}>
                <Flex
                  alignMainAxis='space-between'
                  alignCrossAxis='center'
                  wrap='nowrap'
                  axisGap={200}
                >
                  {sectionToEdit === sectionId && (
                    <Fragment>
                      <Input
                        name='updatedSectionName'
                        onChange={onChangeInput}
                        value={state.updatedSectionName}
                      />
                      <Button
                        style={{ padding: '0.5rem' }}
                        iconBefore='check'
                        variant='primary'
                        size='small'
                        onClick={onClickApplyUpdate}
                      />
                      <Button
                        style={{ padding: '0.5rem' }}
                        iconBefore='close'
                        variant='error'
                        size='small'
                        onClick={onClickCloseUpdate}
                      />
                    </Fragment>
                  )}
                  {sectionToEdit !== sectionId && (
                    <Fragment>
                      <Text size={200} variant='page'>
                        {name}
                      </Text>
                      <Button
                        style={{ padding: '0.5rem' }}
                        iconBefore='edit'
                        variant='text'
                        size='small'
                        onClick={onClickEdit}
                      />
                    </Fragment>
                  )}
                </Flex>
              </li>
            )
          })}
        </ul>
        {!showAddField && (
          <Flex alignMainAxis='space-between' style={{ margin: '1rem 0' }}>
            <Button
              iconBefore='add'
              variant='primary'
              size='small'
              onClick={onClickAdd}
            >
              <Text>{strings.addSection}</Text>
            </Button>
          </Flex>
        )}
        {showAddField && (
          <Flex
            axisGap={200}
            wrap='nowrap'
            alignMainAxis='space-between'
            style={{ margin: '1rem 0' }}
          >
            <Input
              name='newSectionName'
              onChange={onChangeInput}
              value={state.newSectionName}
            />
            <Button
              style={{ padding: '0.5rem' }}
              iconBefore='check'
              variant='primary'
              size='small'
              onClick={onCreateSection}
            />
            <Button
              style={{ padding: '0.5rem' }}
              iconBefore='close'
              variant='error'
              size='small'
              onClick={onClickCancel}
            />
          </Flex>
        )}
      </Slot>
    </Dialog>
  )
}

export default Sections

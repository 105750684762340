import { Box } from '..'
import './index.scss'

const Separator = ({ text, className = '', children = null, ...rest }) => {
  return (
    <Box
      as='hr'
      data-text={text}
      className={['Separator', className]}
      {...rest}
    />
  )
}

export default Separator

import { makeActionCreator } from '@/Util/ReduxUtils'

export const CREATE_PLAN = 'CREATE_PLAN'
export const createPlan = makeActionCreator(CREATE_PLAN, 'params')

export const PLAN_CREATED = 'PLAN_CREATED'
export const planCreated = makeActionCreator(PLAN_CREATED, 'plan', 'error')

export const UPDATE_PLAN = 'UPDATE_PLAN'
export const updatePlan = makeActionCreator(UPDATE_PLAN, 'params')

export const PLAN_UPDATED = 'PLAN_UPDATED'
export const planUpdated = makeActionCreator(PLAN_UPDATED, 'plan', 'error')

export const REQUEST_PLANS = 'REQUEST_PLANS'
export const requestPlans = makeActionCreator(REQUEST_PLANS, 'params')

export const RECEIVE_PLANS = 'RECEIVE_PLANS'
export const receivePlans = makeActionCreator(
  RECEIVE_PLANS,
  'plans',
  'count',
  'error'
)

export const REQUEST_PLAN = 'REQUEST_PLAN'
export const requestPlan = makeActionCreator(REQUEST_PLAN, 'params')

export const RECEIVE_PLAN = 'RECEIVE_PLAN'
export const receivePlan = makeActionCreator(RECEIVE_PLAN, 'plan', 'error')

export const CLEAR_ALL_PLANS = 'CLEAR_ALL_PLANS'
export const clearAllPlans = makeActionCreator(CLEAR_ALL_PLANS)

export const REQUEST_ALL_PLANS = 'REQUEST_ALL_PLANS'
export const requestAllPlans = makeActionCreator(REQUEST_ALL_PLANS, 'params')

export const RECEIVE_ALL_PLANS = 'RECEIVE_ALL_PLANS'
export const receiveAllPlans = makeActionCreator(
  RECEIVE_ALL_PLANS,
  'plans',
  'count',
  'error'
)

import { I18n } from 'aws-amplify'
import {
  datasetLineQuery,
  defaultLiveValueQuery
} from '../Shared/Dynamic/config/defaults'
import { WetBulbTemperatureWidget } from './WetBulbTemperature'
import {
  GrowingDegreeHoursWidget,
  gdhQueryOptionsList
} from './GrowingDegreeHours'
import {
  DAILY,
  DATASET_QUERY,
  LAST_30_DAYS,
  LINE_CHART
} from '../Shared/Dynamic/config'

export const plantScienceTypes = {
  wet_bulb_temperature: {
    widgetId: 'wetBulbTemperature',
    title: I18n.get('Wet Bulb Temperature'),
    icon: 'spa',
    measurement: 'wet_bulb_temperature',
    queryOptionsList: [defaultLiveValueQuery, datasetLineQuery],
    component: WetBulbTemperatureWidget
  },
  growing_degree_hours: {
    widgetId: 'growingDegreeHours',
    title: I18n.get('Growing Degree Hours'),
    icon: 'spa',
    measurement: 'growing_degree_hours',
    queryOptionsList: gdhQueryOptionsList,
    component: GrowingDegreeHoursWidget,
    config: {
      query: DATASET_QUERY,
      chart: LINE_CHART,
      range: LAST_30_DAYS,
      interval: DAILY
    }
  }
}

import { I18n } from 'aws-amplify'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { requestCustomers } from '@/actions/operations/customer'

import {
  getCurrentUserOrganizations,
  getOperationsCustomers
} from '@/reducers/selectors'

import { Select } from '@/primitives'

const CustomersFilter = ({ state, dispatchState }) => {
  const dispatch = useDispatch()

  const organizations = getCurrentUserOrganizations()
  const customers = getOperationsCustomers()

  const [customerFilter, setCustomerFilter] = useState(
    state.customers.length === 1 ? state.customers[0] : 'all'
  )
  const [lastFilter, setLastFilter] = useState(customerFilter)

  const getCustomerFilter = useCallback(() => {
    return customerFilter === 'all'
      ? customers.map(i => i.id)
      : [customerFilter]
  }, [customerFilter, customers])

  const dispatchChange = useCallback(() => {
    dispatchState({
      customers: getCustomerFilter()
    })
  }, [dispatchState, getCustomerFilter])

  useEffect(() => {
    if (organizations.length > 0) {
      dispatch(
        requestCustomers({
          filter: {
            organizationId: organizations.map(o => o.id)
          }
        })
      )
    }
  }, [dispatch, organizations])

  useEffect(() => {
    if (
      customerFilter === 'all' &&
      state.customers.length === 0 &&
      customers.length > 0
    ) {
      dispatchChange()
    }
  }, [state.customers, customers, dispatchChange, customerFilter])

  useEffect(() => {
    if (customers.length > 0) {
      if (customerFilter !== lastFilter) {
        setLastFilter(customerFilter)
        dispatchChange()
      }
    }
  }, [customerFilter, customers, dispatchChange, lastFilter])

  const onChangeFilter = e => {
    setCustomerFilter(e.currentTarget.value)
  }

  const filterCustomers = () => {
    if (state.organizations.length === 1) {
      return customers.filter(customer =>
        state.organizations.includes(customer.organizationId)
      )
    }
    return []
  }

  const options = filterCustomers()

  return (
    <Select
      className='Operations__Header__Select'
      name='customerId'
      value={customerFilter}
      onChange={onChangeFilter}
      disabled={options.length === 0}
    >
      <option default value='all'>
        {I18n.get('All Customers')}
      </option>
      {options.map(({ name, id }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </Select>
  )
}

export default CustomersFilter

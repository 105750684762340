import { makeActionCreator } from '@/Util/ReduxUtils'

export const CREATE_FERTILIZER_PURCHASE = 'CREATE_FERTILIZER_PURCHASE'
export const createFertilizerPurchase = makeActionCreator(
  CREATE_FERTILIZER_PURCHASE,
  'params'
)

export const FERTILIZER_PURCHASE_CREATED = 'FERTILIZER_PURCHASE_CREATED'
export const fertilizerPurchaseCreated = makeActionCreator(
  FERTILIZER_PURCHASE_CREATED,
  'fertilizerPurchase',
  'error'
)

export const UPDATE_FERTILIZER_PURCHASE = 'UPDATE_FERTILIZER_PURCHASE'
export const updateFertilizerPurchase = makeActionCreator(
  UPDATE_FERTILIZER_PURCHASE,
  'params'
)

export const FERTILIZER_PURCHASE_UPDATED = 'FERTILIZER_PURCHASE_UPDATED'
export const fertilizerPurchaseUpdated = makeActionCreator(
  FERTILIZER_PURCHASE_UPDATED,
  'fertilizerPurchase',
  'error'
)

export const REQUEST_FERTILIZER_PURCHASES = 'REQUEST_FERTILIZER_PURCHASES'
export const requestFertilizerPurchases = makeActionCreator(
  REQUEST_FERTILIZER_PURCHASES,
  'params'
)

export const RECEIVE_FERTILIZER_PURCHASES = 'RECEIVE_FERTILIZER_PURCHASES'
export const receiveFertilizerPurchases = makeActionCreator(
  RECEIVE_FERTILIZER_PURCHASES,
  'fertilizerPurchases',
  'count',
  'error'
)

export const REQUEST_FERTILIZER_PURCHASE = 'REQUEST_FERTILIZER_PURCHASE'
export const requestFertilizerPurchase = makeActionCreator(
  REQUEST_FERTILIZER_PURCHASE,
  'params'
)

export const RECEIVE_FERTILIZER_PURCHASE = 'RECEIVE_FERTILIZER_PURCHASE'
export const receiveFertilizerPurchase = makeActionCreator(
  RECEIVE_FERTILIZER_PURCHASE,
  'fertilizerPurchase',
  'error'
)

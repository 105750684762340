import { graphqlOperation } from 'aws-amplify'

import { GRAPHQL_URL } from './config'
import { getCognitoData, makeGraphqlRequest } from '../utils'

export const getFyiTooltips = async params => {
  try {
    const query = /* GraphQL */ `
      query getHelpTexts {
        getHelpTexts {
          helpTextId
          title
          titleTranslations {
            lang
            text
          }
          text
          textTranslations {
            lang
            text
          }
          tags
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    return { fyiTooltips: data?.getHelpTexts, error }
  } catch (err) {
    return { fyiTooltips: null, error: err.message }
  }
}

export const getTextFromPrompt = async params => {
  try {
    const query = /* GraphQL */ `
      query getTextFromPrompt($prompt: String!) {
        getTextFromPrompt(prompt: $prompt) {
          outputText
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    if (error) {
      return {
        organization: null,
        error: `Text could not be generated: ${error}`
      }
    }

    return { generatedText: data?.getTextFromPrompt, error }
  } catch (err) {
    return { generatedText: null, error: err.message }
  }
}

export const createHelpText = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation createHelpText(
        $title: String!
        $text: String!
        $tags: [String]
        $targetLangs: [String]
      ) {
        createHelpText(
          title: $title
          text: $text
          tags: $tags
          targetLangs: $targetLangs
        ) {
          helpTextId
          title
          text
          tags
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    return { helpText: data?.createHelpText, error }
  } catch (err) {
    return { helpText: null, error: err.message }
  }
}

export const updateHelpText = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation updateHelpText(
        $helpTextId: String!
        $title: String!
        $text: String!
        $tags: [String]
      ) {
        updateHelpText(
          helpTextId: $helpTextId
          title: $title
          text: $text
          tags: $tags
        ) {
          helpTextId
          title
          text
          tags
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    return { helpText: data?.updateHelpText, error }
  } catch (err) {
    return { helpText: null, error: err.message }
  }
}

export const getHelpTextsByLang = async params => {
  try {
    const query = /* GraphQL */ `
      query getHelpTextsByLang($lang: String!) {
        getHelpTextsByLang(lang: $lang) {
          helpTextId
          title
          text
          tags
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(query, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    return { helpTextsByLang: data?.getHelpTextsByLang, error }
  } catch (err) {
    return { helpTextsByLang: null, error: err.message }
  }
}

export const redoHelpTextTranslations = async params => {
  try {
    const mutation = /* GraphQL */ `
      mutation redoHelpTextTranslations(
        $helpTextId: String!
        $targetLangs: [String]
      ) {
        redoHelpTextTranslations(
          helpTextId: $helpTextId
          targetLangs: $targetLangs
        ) {
          helpTextId
        }
      }
    `

    const { idToken } = await getCognitoData()
    const operation = graphqlOperation(mutation, params)
    const { data, error } = await makeGraphqlRequest(
      GRAPHQL_URL,
      operation,
      idToken
    )

    return { helpTextTranslations: data?.redoHelpTextTranslations, error }
  } catch (err) {
    return { helpTextTranslations: null, error: err.message }
  }
}

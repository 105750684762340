import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { I18n } from 'aws-amplify'

import { Flex, Text } from '@/primitives'

import {
  fetchNextHourPrediction,
  cleanNextHourPrediction
} from '@/slices/ml/prediction'

import {
  getNextHourPrediction,
  getMobileDashboardData,
  getMeasurements,
  getCurrentUser,
  getRootZone
} from '@/reducers/selectors'

import { getMeasurementUnit } from '../../../utils'
import { parseValue } from '../../../utils'

import { useMobileDashboard } from '../../context'

import MeasurementStrings from '@/Util/Strings/measurements'
import {
  SETTING_CELCIUS_TO_FAHRENHEIT,
  processSettings
} from '@/Util/SettingsUtils'
import {
  convertCelciusToFahrenheit,
  FAHRENHEIT_UNIT,
  CONVERSION_TEMPERATURE_MEASUREMENTS,
  convertToVPD
} from '@/Util/MeasurementUtils'

import './index.scss'

import useMediaQuery from '@/hooks/useMediaQuery'

const VALID_FORECAST_MEASUREMENTS = [
  'temperature',
  'humidity',
  'absolute_humidity',
  'pressure',
  'par'
]

const LiveDataTile = ({ measurementId, zoneId, isRoom }) => {
  const { state } = useMobileDashboard()
  const dispatch = useDispatch()

  const data = getMobileDashboardData()
  const measurements = getMeasurements()
  const currentUser = getCurrentUser()
  const rootZone = getRootZone()

  const measurementStrings = MeasurementStrings()

  const nextHourPrediction = getNextHourPrediction(measurementId)

  const isAboveBreakpoint = useMediaQuery('min-width: 200px')

  useEffect(() => {
    if (isRoom && VALID_FORECAST_MEASUREMENTS.includes(measurementId)) {
      dispatch(cleanNextHourPrediction())
      const params = {
        siteId: state.siteId,
        zoneId,
        measurementId,
        valueType: 'avg'
      }

      dispatch(fetchNextHourPrediction(params))
    }
  }, [zoneId, measurementId, isRoom, dispatch, state?.siteId])

  let displayForecast = false
  let prediction = null
  if (
    nextHourPrediction?.siteId === state.siteId &&
    nextHourPrediction?.zoneId === zoneId &&
    nextHourPrediction?.measurementId === measurementId &&
    state?.currentDashboard?.context !== 'sensor'
  ) {
    displayForecast = true
    prediction =
      nextHourPrediction?.predictions[
        nextHourPrediction?.predictions.length - 1
      ]
  }

  const enableFahrenheit = processSettings(
    currentUser?.settings,
    SETTING_CELCIUS_TO_FAHRENHEIT,
    currentUser.userName,
    rootZone?.organizationId
  )

  const useFahrenheit =
    CONVERSION_TEMPERATURE_MEASUREMENTS.includes(measurementId) &&
    enableFahrenheit

    const isVPD = measurementId === 'vapour_pressure_deficit'
    const currentValue = isVPD ? getVPDValue(data[zoneId]) : data[zoneId]?.[measurementId]?.current?.value

  return (
    <Flex className='LiveDataWidget__Tile'>
      <Text as='p' className='LiveDataWidget__Tile__Header'>
        {measurementStrings[measurementId] ??
          getDisplayName(measurementId, measurements)}
      </Text>
      <Flex
        direction={prediction && isAboveBreakpoint ? 'column' : 'column'}
        alignMainAxis={prediction && isAboveBreakpoint ? 'center' : 'center'}
        axisGap={prediction && isAboveBreakpoint ? '400' : '400'}
      >
        <Flex direction='column'>
          <Text className='LiveDataWidget__Tile__ValueDescription'>
            {I18n.get('Now')}
          </Text>
          <span className='LiveDataWidget__Tile__Value'>
            {getDisplayValue(
              currentValue,
              measurements,
              measurementId,
              false,
              useFahrenheit,
              FAHRENHEIT_UNIT
            )}
          </span>
        </Flex>
        {displayForecast && prediction?.value && (
          <Flex direction='column'>
            <Text className='LiveDataWidget__Tile__ValueDescription_Prediction'>
              {I18n.get('+1 hour')}
            </Text>
            <span className='LiveDataWidget__Tile__Value_Prediction'>
              {getDisplayValue(
                prediction?.value,
                measurements,
                measurementId,
                true,
                useFahrenheit
              )}
            </span>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

function getTempFahrenheit(tempCelsius) {
  const temperature = convertCelciusToFahrenheit(tempCelsius)
  return Math.round(temperature)
}

function getVPDValue(locationData) {
  const temperature = locationData?.temperature?.current?.value
  const humidity = locationData?.humidity?.current?.value
  return convertToVPD({ temperature, humidity })
}

const getDisplayValue = (
  value,
  measurements,
  measurementId,
  isPrediction = false,
  useFahrenheit = false
) => {
  if (!isNaN(value)) {
    const parsedValue = parseValue(value, measurementId)

    if (useFahrenheit) {
      const temp = getTempFahrenheit(parsedValue)
      return (
        <span>
          {temp}{' '}
          <span
            className={`LiveDataWidget__Tile__Unit${
              isPrediction ? '_Prediction' : ''
            }`}
          >
            {FAHRENHEIT_UNIT}
          </span>
        </span>
      )
    }

    const unit = getMeasurementUnit(measurements, measurementId)
    return (
      <span>
        {parsedValue}{' '}
        <span
          className={`LiveDataWidget__Tile__Unit${
            isPrediction ? '_Prediction' : ''
          }`}
        >
          {unit}
        </span>
      </span>
    )
  }

  return <span>{I18n.get('No data')}</span>
}

const getDisplayName = (id, measurements) => {
  if (!id) return null
  const { shortName } = measurements?.find(
    measurement => measurement?.id === id
  )
  return <span>{shortName ?? id}</span>
}

export default LiveDataTile

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { getUserCognitoLogs } from '@/api/audit/cognito'

import { sortCognitoLogs } from './utils'

export const cleanCognitoLogs = createAsyncThunk('cleanCognitoLogs', () => {
  return {}
})

export const fetchUserCognitoLogs = createAsyncThunk(
  'fetchUserCognitoLogs',
  async params => {
    const { logs, error } = await getUserCognitoLogs(params)
    return { logs, error }
  }
)

const auditCognitoSlice = createSlice({
  name: 'auditCognitoReducer',
  initialState: {
    logs: [],
    error: null,
    loading: false
  },
  extraReducers: builder => {
    builder
      .addCase(cleanCognitoLogs.fulfilled, (state, action) => {
        state.error = null
        state.logs = []
      })
      .addCase(fetchUserCognitoLogs.pending, (state, action) => {
        state.error = null
        state.loading = true
        state.logs = []
      })
      .addCase(fetchUserCognitoLogs.fulfilled, (state, action) => {
        state.error = action?.payload?.error ?? null
        state.loading = false
        state.logs = sortCognitoLogs(action?.payload?.logs ?? [])
      })
  }
})

export default auditCognitoSlice.reducer
